import { PfaInvestmentCard } from '../pfa-investment-card/pfa-investment-card.model';
import { ClimatePlus } from '@pfa/gen';
export interface PfaClimateProfile extends PfaInvestmentCard {
  type: ClimatePlus;
  climatePlusPercentage: number;
}

export function getClimatePlusType(percentage: number): ClimatePlus {
  switch (percentage) {
    case 0:
    case null:
    case undefined:
      return 'CLIMATE_PLUS_NONE';
    case 15:
      return 'CLIMATE_PLUS_LOW';
    case 30:
      return 'CLIMATE_PLUS_MIDDLE';
    default:
      return 'CLIMATE_PLUS_HIGH';
  }
}

export function getClimatePlusPercentage(type: ClimatePlus): number {
  switch (type) {
    case 'CLIMATE_PLUS_NONE':
      return 0;
    case 'CLIMATE_PLUS_LOW':
      return 15;
    case 'CLIMATE_PLUS_MIDDLE':
      return 30;
    default:
      return null;
  }
}
