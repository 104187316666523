import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Forsikringsoversigt, Skattekode } from '@pfa/gen';
import {
  InputConfigLife,
  InsuranceAdjustmentType,
  TaxCodeUpdateResponse
} from '@mitpfa/shared';

@Injectable({
  providedIn: 'root'
})
export class InsuranceAdjustmentUpdateService {
  private readonly lifeUpdate = new Subject<number>();
  private readonly lifeTaxCodeUpdate = new Subject<TaxCodeUpdateResponse>();
  private readonly lifeCprUpdate = new Subject<InputConfigLife>();
  private readonly locLumpSumpUpdate = new Subject<Skattekode>();
  private readonly advisorInfoDeleted = new Subject<InsuranceAdjustmentType>();
  private readonly ciUpdate = new Subject<Forsikringsoversigt>();
  readonly lifeUpdateAnnounced$ = this.lifeUpdate.asObservable();
  readonly lifeTaxCodeUpdateAnnounced$ = this.lifeTaxCodeUpdate.asObservable();
  readonly lifeCprUpdateAnnounced$ = this.lifeCprUpdate.asObservable();
  readonly locLumpSumUpdateAnnounced$ = this.locLumpSumpUpdate.asObservable();
  readonly advisorInfoDeleteAnnounced$ = this.advisorInfoDeleted.asObservable();
  readonly ciUpdateAnnounced$ = this.ciUpdate.asObservable();

  lifeNotify(grossPercent: number): void {
    this.lifeUpdate.next(grossPercent);
  }

  locNotifyLumpSumpUpdated(taxCode: Skattekode | undefined): void {
    if (taxCode) {
      this.locLumpSumpUpdate?.next(taxCode);
    }
  }

  notifyAdvisorInfoDeleted(type: InsuranceAdjustmentType): void {
    this.advisorInfoDeleted.next(type);
  }

  lifeTaxCodeUpdated(taxCodeUpdateResponse: TaxCodeUpdateResponse): void {
    this.lifeTaxCodeUpdate.next(taxCodeUpdateResponse);
  }

  lifeCprUpdated(inputConfigLife: InputConfigLife): void {
    this.lifeCprUpdate.next(inputConfigLife);
  }

  ciNotify(insuranceOverview: Forsikringsoversigt): void {
    this.ciUpdate.next(insuranceOverview);
  }
}
