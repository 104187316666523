import { Component, OnInit } from '@angular/core';
import {
  ContentService,
  SessionTimeoutService,
  SessionTimeoutExtenderService
} from '@pfa/core';
import { ActivatedRoute } from '@angular/router';
import { LoginMethod, PinCodeLoginStep } from './logon.model';
import { LoggedOutInactiveService } from './logged-out-inactive/logged-out-inactive.service';
import { ErrorHandlerApiService } from '@pfa/api';
import { MobileBridgeService } from '../mobile-bridge/mobile-bridge.service';
import { LanguageService, LoginService } from '@mitpfa/shared';

@Component({
  selector: 'mitpfa-logon',
  templateUrl: './logon.component.html',
  styleUrls: ['./logon.component.scss']
})
export class LogonComponent implements OnInit {
  isPinStep = true;
  isOtpStep = false;
  showDoorman: boolean;
  mitidError: string;
  mitidErrorDescription: string;

  LoginMethod = LoginMethod;
  displayedLoginMethod: LoginMethod = LoginMethod.MIT_ID;
  displayedLoginMethodTestId: string;

  constructor(
    readonly mobileBridge: MobileBridgeService,
    private readonly loginService: LoginService,
    private readonly contentService: ContentService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly loggedOutInactiveService: LoggedOutInactiveService,
    private readonly errorHandlerApiService: ErrorHandlerApiService,
    private readonly sessionTimeoutService: SessionTimeoutService,
    private readonly sessionTimeoutExtenderService: SessionTimeoutExtenderService,
    private readonly languageService: LanguageService
  ) {
    this.activatedRoute.queryParams.subscribe(params => {
      this.mitidError = params['error'];
      this.mitidErrorDescription = params['error_description'];
    });
  }

  get isDanish() {
    return this.contentService.getLanguage() === 'da';
  }

  setLanguage(lang: 'da' | 'en') {
    this.languageService.setLanguage(lang);
  }

  ngOnInit(): void {
    this.activatedRoute.data.subscribe((data: { rateLimit: any }) => {
      this.showDoorman = data.rateLimit.rateLimit;
    });
    this.errorHandlerApiService.notifySuppressErrorNavigation(false);
    this.loggedOutInactiveService.handleTimeoutExplanationPopup();
    this.loginService.loggedIn$.subscribe(isLoggedIn => {
      if (!isLoggedIn) {
        // make sure that the frontend session timers stop when you land at the login page without being logged in
        this.sessionTimeoutExtenderService.stopTimer();
        this.sessionTimeoutService.stop();
      }
    });
  }

  switchLanguage() {
    const language = this.isDanish ? 'en' : 'da';
    this.contentService.setLanguage(language);
    this.languageService.changeCookieBotLanguage(language);
  }

  switchTab(loginMethod: LoginMethod) {
    this.displayedLoginMethod = loginMethod;
    this.displayedLoginMethodTestId = {
      [LoginMethod.MIT_ID]: 'LI01-mitid',
      [LoginMethod.PIN_CODE]: 'LI01-pincode'
    }[loginMethod];
  }

  updatePinCodeLoginStep(step: PinCodeLoginStep) {
    this.isPinStep = step === PinCodeLoginStep.PIN;
    this.isOtpStep = !this.isPinStep;
  }
}
