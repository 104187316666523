<svg
  width="1440"
  height="231"
  viewBox="0 0 1440 231"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <g opacity="0.4">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M157.689 145.371H0V339.602H157.689V145.371Z"
      fill="#E5E5E5"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M41.7652 129.688H24.6309V159.847H41.7652V129.688Z"
      fill="#E5E5E5"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M44.9772 127.576H22.2207V133.005H44.9772V127.576Z"
      fill="#E5E5E5"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M41.7652 133.006H24.6309V135.72H41.7652V133.006Z"
      fill="#E5E5E5"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M24.6309 135.721V159.849L41.7652 135.721H24.6309Z"
      fill="#E5E5E5"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M155.548 338.998L1502.73 336.887V205.39L1468.2 131.498H1463.91H1379.85L1353.88 183.976H1341.83V35.8904L1270.62 37.0968H1213.86V58.2088H1183.07L1184.14 212.93L1115.07 109.481V47.6528L1074.38 0L1041.18 43.732H1026.72V109.481H993.255L924.718 182.468V214.438H896.071L896.339 131.799H871.441V118.83H854.306V131.799H738.382V217.454L701.168 217.152L701.436 113.703L632.631 114.005L588.189 47.3512L530.093 47.6528L527.148 133.006L530.093 47.6528L481.635 119.434L450.579 117.624L448.17 34.0808H395.964V49.4624H369.191V215.041L314.576 129.688H199.187L155.548 207.802V338.998Z"
      fill="#E5E5E5"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1411.71 115.814H1401.8V141.149H1411.71V115.814Z"
      fill="#E5E5E5"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1415.19 114.307H1398.32V120.64H1415.19V114.307Z"
      fill="#E5E5E5"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M806.383 140.245H751.768V96.2109H806.383V140.245Z"
      fill="#E5E5E5"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M673.593 98.623H653.514V118.529H673.593V98.623Z"
      fill="#E5E5E5"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M680.286 97.416H646.553V102.543H680.286V97.416Z"
      fill="#E5E5E5"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M302.795 104.654H292.89V129.989H302.795V104.654Z"
      fill="#E5E5E5"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M306.008 103.146H289.409V109.48H306.008V103.146Z"
      fill="#E5E5E5"
    />
  </g>
</svg>
