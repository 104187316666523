import { AdvantageInsuranceComponent } from './advantages/advantage-insurance/advantage-insurance.component';
import { NgModule } from '@angular/core';
import {
  RouterModule,
  Routes,
  UrlMatchResult,
  UrlSegment
} from '@angular/router';
import {
  OnboardingGuard,
  CombineInternalGuard,
  DynamicadvisingGuard,
  PensioncheckGuard,
  UserGuard,
  CustomerGuard
} from '@pfa/handler';
import { DashboardComponent } from './dashboard/dashboard.component';
import { MitPfaMessagingWrapperComponent } from './messaging/mit-pfa-messaging-wrapper.component';
import { Role } from '@pfa/models';
import { DocumentsComponent } from '@pfa/feature/documents';
import { AdvantageVipComponent } from './advantages/advantage-vip/advantage-vip.component';
import { AdvantageHousingComponent } from './advantages/advantage-housing/advantage-housing.component';
import { PensionCheckComponent } from './pension-check/pension-check.component';
import { OnboardingComponent } from './onboarding/onboarding.component';
import { DynamicCounselingComponent } from './dynamic-counseling/dynamic-counseling.component';
import { ContactDetailsComponent } from './contact-details/contact-details.component';
import { LogonComponent } from './logon/logon.component';
import { OrderPincodeComponent } from './order-pincode/order-pincode.component';
import { ForbiddenComponent } from './forbidden/forbidden.component';
import { LogoutComponent } from './logout/logout.component';
import { BookingPortalComponent } from './booking-portal/booking-portal.component';
import { MitidLoggedInComponent } from './logon/mitid-logged-in.component';
import { MitidLegitimationComponent } from './logon/mitid-legitimation.component';
import { AdvisorTimeoutComponent } from './advisor-timeout/advisor-timeout.component';
import {
  DashboardGuard,
  InvestmentResolver,
  LightGuard,
  PensionCustomerBrokerResolver,
  PensionCustomerResolver,
  RateLimitResolver,
  ReceivedAgreementStatusResolver
} from '@mitpfa/shared';
import { ClimateFootprintComponent } from './climate-footprint/climate-footprint.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from '@pfaform';

const appRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'mitoverblik'
  },
  {
    path: 'logind',
    component: LogonComponent,
    data: { hideHeader: true, hideFooter: true, hideChat: true },
    resolve: { rateLimit: RateLimitResolver }
  },
  {
    path: 'mitid-logged-in',
    component: MitidLoggedInComponent
  },
  {
    path: 'mitid-legitimation',
    canActivate: [UserGuard],
    data: {
      roles: [Role.Customer.valueOf()]
    },
    component: MitidLegitimationComponent
  },
  {
    path: 'raadgiver-timeout',
    component: AdvisorTimeoutComponent,
    data: {
      hideHeader: true,
      hideFooter: true,
      hideChat: true,
      hideAdvisorMenu: true
    },
    loadChildren: () =>
      import('./advisor-timeout/advisor-timeout.module').then(
        m => m.AdvisorTimeoutModule
      )
  },
  {
    path: 'logout',
    canActivate: [UserGuard],
    data: {
      hideHeader: true,
      hideFooter: true,
      hideChat: true,
      roles: [
        Role.Advisor.valueOf(),
        Role.Customer.valueOf(),
        Role.PfaUser.valueOf()
      ]
    },
    resolve: { pensionCustomer: PensionCustomerResolver },
    component: LogoutComponent
  },
  {
    path: 'pinkode',
    component: OrderPincodeComponent,
    loadChildren: () =>
      import('./pincode/pincode.module').then(m => m.PincodeModule)
  },
  {
    path: 'forbidden',
    component: ForbiddenComponent,
    loadChildren: () =>
      import('./forbidden/forbidden.module').then(m => m.ForbiddenModule)
  },
  {
    path: 'mitoverblik',
    children: [
      {
        path: '',
        component: DashboardComponent
      },
      {
        path: '',
        component: HeaderComponent,
        outlet: 'header'
      },
      {
        path: '',
        component: FooterComponent,
        outlet: 'footer'
      }
    ],
    canActivate: [UserGuard, CustomerGuard, LightGuard, DashboardGuard],
    data: {
      roles: [
        Role.Advisor.valueOf(),
        Role.Customer.valueOf(),
        Role.PfaUser.valueOf()
      ]
    }
  },
  {
    path: 'beskeder',
    component: MitPfaMessagingWrapperComponent,
    canActivate: [UserGuard, LightGuard],
    data: {
      roles: [
        Role.Advisor.valueOf(),
        Role.Customer.valueOf(),
        Role.PfaUser.valueOf()
      ]
    }
  },
  {
    path: 'minedokumenter',
    component: DocumentsComponent,
    canActivate: [UserGuard, LightGuard],
    data: {
      roles: [
        Role.Advisor.valueOf(),
        Role.Customer.valueOf(),
        Role.PfaUser.valueOf()
      ]
    }
  },
  {
    path: 'kontakt',
    component: ContactDetailsComponent,
    canActivate: [UserGuard, LightGuard],
    data: {
      roles: [
        Role.Advisor.valueOf(),
        Role.Customer.valueOf(),
        Role.PfaUser.valueOf()
      ]
    }
  },
  {
    path: 'kundekapital',
    canActivate: [UserGuard, LightGuard],
    data: {
      roles: [
        Role.Advisor.valueOf(),
        Role.Customer.valueOf(),
        Role.PfaUser.valueOf()
      ]
    },
    loadChildren: () =>
      import('./customer-capital/customer-capital.module').then(
        m => m.CustomerCapitalModule
      )
  },
  {
    path: 'forsikringspartner',
    canActivate: [UserGuard, LightGuard],
    data: {
      roles: [
        Role.Advisor.valueOf(),
        Role.Customer.valueOf(),
        Role.PfaUser.valueOf()
      ]
    },
    component: AdvantageInsuranceComponent
  },
  {
    path: 'pfaboliger',
    canActivate: [UserGuard, LightGuard],
    data: {
      roles: [
        Role.Advisor.valueOf(),
        Role.Customer.valueOf(),
        Role.PfaUser.valueOf()
      ]
    },
    component: AdvantageHousingComponent
  },
  {
    path: 'handlingscenter',
    canActivate: [UserGuard, LightGuard, PensioncheckGuard],
    data: {
      hideFooter: true,
      roles: [
        Role.Advisor.valueOf(),
        Role.Customer.valueOf(),
        Role.PfaUser.valueOf()
      ]
    },
    component: PensionCheckComponent
  },
  {
    path: 'raadgiveroverblik',
    canActivate: [UserGuard, LightGuard, DynamicadvisingGuard],
    data: {
      roles: [Role.Advisor.valueOf()]
    },
    component: DynamicCounselingComponent
  },
  {
    path: 'personligeradgivere',
    canActivate: [UserGuard, LightGuard],
    data: {
      roles: [
        Role.Advisor.valueOf(),
        Role.Customer.valueOf(),
        Role.PfaUser.valueOf()
      ]
    },
    component: AdvantageVipComponent
  },
  {
    path: 'pensionsplan',
    canActivate: [UserGuard, LightGuard, PensioncheckGuard],
    data: {
      roles: [
        Role.Advisor.valueOf(),
        Role.Customer.valueOf(),
        Role.PfaUser.valueOf()
      ]
    },
    loadChildren: () =>
      import('./pension-plan/pension-plan.module').then(
        m => m.PensionPlanModule
      )
  },
  {
    path: 'duinvesterer/intro',
    redirectTo: 'mineinvesteringer/intro'
  },
  {
    path: 'mineinvesteringer',
    canActivate: [UserGuard, LightGuard],
    data: {
      roles: [
        Role.Advisor.valueOf(),
        Role.Customer.valueOf(),
        Role.PfaUser.valueOf()
      ]
    },
    resolve: {
      pensionCustomer: PensionCustomerResolver,
      investments: InvestmentResolver
    },
    loadChildren: () =>
      import('./investment/investment.module').then(m => m.InvestmentModule)
  },
  {
    path: 'mineforsikringer',
    canActivate: [UserGuard, LightGuard],
    data: {
      roles: [
        Role.Advisor.valueOf(),
        Role.Customer.valueOf(),
        Role.PfaUser.valueOf()
      ]
    },
    loadChildren: () =>
      import('./insurance/insurance.module').then(m => m.InsuranceModule)
  },
  {
    path: 'mineforsikringer/arvogbegunstigelse',
    canActivate: [UserGuard, LightGuard],
    data: {
      roles: [
        Role.Advisor.valueOf(),
        Role.Customer.valueOf(),
        Role.PfaUser.valueOf()
      ]
    },
    loadChildren: () =>
      import(
        './inheritanceandbeneficiary/inheritanceandbeneficiary.module'
      ).then(m => m.InheritanceandbeneficiaryModule)
  },
  {
    matcher: beneficiaryMather,
    canActivate: [UserGuard, LightGuard],
    data: {
      roles: [
        Role.Advisor.valueOf(),
        Role.Customer.valueOf(),
        Role.PfaUser.valueOf()
      ]
    },
    loadChildren: () =>
      import(
        '../../../../libs/feature/beneficiary/src/lib/beneficiary.module'
      ).then(m => m.BeneficiaryModule)
  },
  {
    path: 'mineforsikringer/begunstigelsesguide',
    canActivate: [UserGuard, LightGuard],
    data: {
      roles: [
        Role.Advisor.valueOf(),
        Role.Customer.valueOf(),
        Role.PfaUser.valueOf()
      ]
    },
    loadChildren: () =>
      import('./beneficiary-guide/beneficiary-guide.module').then(
        m => m.BeneficiaryGuideModule
      )
  },
  {
    path: 'senesteindbetaling',
    canActivate: [UserGuard, LightGuard],
    data: {
      roles: [
        Role.Advisor.valueOf(),
        Role.Customer.valueOf(),
        Role.PfaUser.valueOf()
      ]
    },
    loadChildren: () =>
      import('./deposit/deposit.module').then(m => m.DepositModule)
  },
  {
    path: 'minopsparing',
    canActivate: [UserGuard, LightGuard],
    data: {
      roles: [
        Role.Advisor.valueOf(),
        Role.Customer.valueOf(),
        Role.PfaUser.valueOf()
      ]
    },
    loadChildren: () =>
      import('./savings/savings.module').then(m => m.SavingsModule)
  },
  {
    path: 'mineforsikringer/sagsoversigt',
    canActivate: [UserGuard, LightGuard],
    data: {
      roles: [
        Role.Advisor.valueOf(),
        Role.Customer.valueOf(),
        Role.PfaUser.valueOf()
      ]
    },
    loadChildren: () =>
      import(
        '../../../../libs/feature/track-your-case/src/lib/track-your-case.module'
      ).then(m => m.TrackYourCaseModule)
  },
  {
    path: 'mine-oplysninger',
    canActivate: [UserGuard, LightGuard],
    data: { roles: [Role.Advisor.valueOf(), Role.Customer.valueOf()] },
    runGuardsAndResolvers: 'always',
    resolve: {
      pensionCustomer: PensionCustomerResolver
    },
    loadChildren: () =>
      import('./your-information/your-information.module').then(
        m => m.YourInformationModule
      )
  },
  {
    path: 'velkomst',
    canActivate: [UserGuard, OnboardingGuard],
    data: {
      hideHeader: true,
      hideFooter: true,
      hideChat: true,
      roles: [
        Role.Advisor.valueOf(),
        Role.Customer.valueOf(),
        Role.PfaUser.valueOf()
      ]
    },
    resolve: {
      pensionCustomer: PensionCustomerResolver
    },
    component: OnboardingComponent
  },
  {
    path: 'booking',
    canActivate: [UserGuard, LightGuard],
    data: {
      hideHeader: true,
      hideFooter: true,
      roles: [Role.Customer.valueOf(), Role.PfaUser.valueOf()]
    },
    component: BookingPortalComponent
  },
  {
    path: 'overfoersel',
    canActivate: [UserGuard, LightGuard],
    data: {
      roles: [
        Role.Advisor.valueOf(),
        Role.Customer.valueOf(),
        Role.PfaUser.valueOf()
      ]
    },
    resolve: {
      pensionCustomer: PensionCustomerResolver,
      broker: PensionCustomerBrokerResolver,
      receivedAgreementStatus: ReceivedAgreementStatusResolver
    },
    loadChildren: () =>
      import('./combine-savings/combine-savings.module').then(
        m => m.CombineSavingsModule
      )
  },
  {
    path: 'konvertertilpfaplus',
    canActivate: [UserGuard, LightGuard, CombineInternalGuard],
    data: {
      roles: [
        Role.Advisor.valueOf(),
        Role.Customer.valueOf(),
        Role.PfaUser.valueOf()
      ]
    },
    loadChildren: () =>
      import('./combine-savings-internal/combine-savings-internal.module').then(
        m => m.CombineSavingsInternalModule
      )
  },
  {
    path: 'privatindskud',
    canActivate: [UserGuard, LightGuard],
    data: {
      roles: [
        Role.Advisor.valueOf(),
        Role.Customer.valueOf(),
        Role.PfaUser.valueOf()
      ]
    },
    loadChildren: () =>
      import('./single-payment/single-payment.module').then(
        m => m.SinglePaymentModule
      )
  },
  {
    path: 'sparmereop',
    canActivate: [UserGuard, LightGuard],
    data: {
      roles: [
        Role.Advisor.valueOf(),
        Role.Customer.valueOf(),
        Role.PfaUser.valueOf()
      ]
    },
    loadChildren: () =>
      import('./save-more/save-more.module').then(m => m.SaveMoreModule)
  },
  {
    path: 'pensionsplan',
    canActivate: [UserGuard, LightGuard, PensioncheckGuard],
    data: {
      roles: [
        Role.Advisor.valueOf(),
        Role.Customer.valueOf(),
        Role.PfaUser.valueOf()
      ]
    },
    loadChildren: () =>
      import('./wealth-overview/wealth-overview.module').then(
        m => m.WealthOverviewModule
      )
  },
  {
    path: 'mitafkast',
    canActivate: [UserGuard, LightGuard],
    data: {
      roles: [
        Role.Advisor.valueOf(),
        Role.Customer.valueOf(),
        Role.PfaUser.valueOf()
      ]
    },
    loadChildren: () =>
      import('./savings-return/savings-return.module').then(
        m => m.SavingsReturnModule
      )
  },
  {
    path: 'prisliste',
    canActivate: [UserGuard, LightGuard],
    data: {
      roles: [
        Role.Advisor.valueOf(),
        Role.Customer.valueOf(),
        Role.PfaUser.valueOf()
      ]
    },
    loadChildren: () =>
      import('./price-list/price-list.module').then(m => m.PriceListModule)
  },
  {
    path: 'mineaendringer',
    canActivate: [UserGuard, LightGuard],
    data: {
      roles: [
        Role.Advisor.valueOf(),
        Role.Customer.valueOf(),
        Role.PfaUser.valueOf()
      ]
    },
    loadChildren: () =>
      import('./basket/basket.module').then(m => m.BasketModule)
  },
  {
    path: 'eksternoverfoersel',
    canActivate: [UserGuard, LightGuard],
    data: {
      roles: [
        Role.Advisor.valueOf(),
        Role.Customer.valueOf(),
        Role.PfaUser.valueOf()
      ]
    },
    loadChildren: () =>
      import('./combine-savings-advisor/combine-savings-advisor.module').then(
        m => m.CombineSavingsAdvisorModule
      )
  },
  {
    path: 'pensionstal',
    canActivate: [UserGuard, LightGuard, PensioncheckGuard],
    data: {
      roles: [
        Role.Advisor.valueOf(),
        Role.Customer.valueOf(),
        Role.PfaUser.valueOf()
      ]
    },
    loadChildren: () =>
      import('./pension-estimate/pension-estimate.module').then(
        m => m.PensionEstimateModule
      )
  },
  {
    path: 'forbehold',
    canActivate: [UserGuard, LightGuard],
    data: {
      roles: [
        Role.Advisor.valueOf(),
        Role.Customer.valueOf(),
        Role.PfaUser.valueOf()
      ]
    },
    loadChildren: () =>
      import('./prerequisites/prerequisites.module').then(
        m => m.PrerequisitesModule
      )
  },
  {
    path: 'klimaaftryk',
    canActivate: [UserGuard, LightGuard],
    data: {
      roles: [
        Role.Advisor.valueOf(),
        Role.Customer.valueOf(),
        Role.PfaUser.valueOf()
      ]
    },
    component: ClimateFootprintComponent
  },
  { path: '**', redirectTo: '/mitoverblik' }
];

export function beneficiaryMather(url: UrlSegment[]): UrlMatchResult | null {
  if (
    url.length === 2 &&
    url[0].path === 'mineforsikringer' &&
    url[1].path === 'begunstigelse'
  ) {
    return { consumed: url };
  }
  if (
    url.length === 3 &&
    url[0].path === 'mineforsikringer' &&
    url[1].path === 'begunstigelse' &&
    url[2].path === 'letpension'
  ) {
    return { consumed: url };
  }
  return null;
}

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      enableTracing: false,
      scrollPositionRestoration: 'enabled'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
