import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  Aendring,
  BestiltAendringer,
  EngagementKontrakt,
  GodkendSvar,
  MineAendringer,
  MineAendringerUdloeb,
  SignatureIframeResponse,
  SignereRequestObj,
  SimulatorTileEnum,
  SuccessResponse
} from '@pfa/gen';

export enum BusinessDomain {
  CustomerCapital = 'KUNDEKAPITAL',
  Savings = 'OPSPARING',
  SavingsForm = 'OPSPARINGSFORM',
  Investment = 'INVESTERING',
  Insurance = 'FORSIKRING'
}

@Injectable({
  providedIn: 'root'
})
export class BasketApiService {
  constructor(private http: HttpClient) {}

  getBasket(language: string): Observable<MineAendringer> {
    const url = '/ds/api/mineaendringer';
    return this.http.get<MineAendringer>(url, {
      params: { sprog: language }
    });
  }

  getAll(): Observable<MineAendringer> {
    const url = '/ds/api/mineaendringer';
    return this.http.get<MineAendringer>(url);
  }

  get(businessDomain: BusinessDomain, policyId): Observable<BestiltAendringer> {
    const url = `/ds/api/mineaendringer/bestiltAendringer/${businessDomain}/${policyId}`;
    return this.http.get<BestiltAendringer>(url);
  }

  buildContract(
    language: string,
    simulatorTileEnum: SimulatorTileEnum
  ): Observable<EngagementKontrakt> {
    return this.http.post<EngagementKontrakt>(
      `/ds/api/mineaendringer/bygkontrakt`,
      this.generateSignereRequestObj(language, simulatorTileEnum)
    );
  }

  addToBasket(): Observable<SuccessResponse> {
    return this.http.post<SuccessResponse>(
      '/ds/api/mineaendringer/tilfoejTilKurv',
      {}
    );
  }

  clearBasket(): Observable<SuccessResponse> {
    return this.http.post<SuccessResponse>(
      '/ds/api/mineaendringer/kasserAendring',
      {}
    );
  }

  accumulatedChanges(language: string): Observable<MineAendringer> {
    return this.http.get<MineAendringer>(
      '/ds/api/mineaendringer/akkumuleredeaendringer',
      {
        params: { sprog: language }
      }
    );
  }

  sign(
    signereRequestObj: SignereRequestObj
  ): Observable<SignatureIframeResponse> {
    return this.http.post<SignatureIframeResponse>(
      '/ds/api/mineaendringer/sign',
      signereRequestObj
    );
  }

  signed(signereRequestObj: SignereRequestObj): Observable<GodkendSvar> {
    return this.http.put<GodkendSvar>(
      '/ds/api/mineaendringer/sign/1',
      signereRequestObj
    );
  }

  getSigningRequest(
    tile: SimulatorTileEnum,
    language: string
  ): SignereRequestObj {
    return {
      sprog: language,
      tile: tile,
      revisionKey: '',
      mitIdSig: ''
    };
  }

  private generateSignereRequestObj(
    language: string,
    simulatorTileEnum: SimulatorTileEnum
  ): SignereRequestObj {
    return {
      revisionKey: '',
      sprog: language,
      tile: simulatorTileEnum,
      mitIdSig: ''
    };
  }

  changeExpirationDate(
    request: { udloebsdato: string },
    language: string
  ): Observable<MineAendringerUdloeb> {
    return this.http.post<MineAendringerUdloeb>(
      '/ds/api/mineaendringer/opdaterUdloebsdatoen',
      request,
      {
        params: { sprog: language }
      }
    );
  }

  deleteAll(): Observable<MineAendringer> {
    return this.http.post<MineAendringer>(
      '/ds/api/mineaendringer/sletAlleMineAendringer',
      {}
    );
  }

  save(): Observable<SuccessResponse> {
    return this.http.post<SuccessResponse>(
      '/ds/api/mineaendringer/gemKurv',
      {}
    );
  }

  deleteCustomerCapital(change: Aendring): Observable<MineAendringer> {
    return this.http.post<MineAendringer>(
      '/ds/api/mineaendringer/sletKundekapital',
      change
    );
  }

  deleteSavings(change: Aendring): Observable<MineAendringer> {
    return this.http.post<MineAendringer>(
      '/ds/api/mineaendringer/sletOpsparing',
      change
    );
  }

  deleteInvestments(change: Aendring): Observable<MineAendringer> {
    return this.http.post<MineAendringer>(
      '/ds/api/mineaendringer/sletInvestering',
      change
    );
  }

  deleteInsurances(change: Aendring): Observable<MineAendringer> {
    return this.http.post<MineAendringer>(
      '/ds/api/mineaendringer/sletForsikring',
      change
    );
  }
}
