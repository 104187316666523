/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const UdbetalingsartTypeSchema = JoiObj.string().valid(
        'LIVSPENSION_OPHOERENDE'
        ,
        'LIVSPENSION_LIVSVARIG'
        ,
        'KAPITALPENSION'
        ,
        'RATEPENSION'
        ,
        'OVERFOERSEL'
        ,
        'DELVIS_OVERFOERSEL'
        ,
        'DELVIS_TILBAGEKOEB'
        ,
        'TILBAGEKOEB'
        ,
        'INVALIDEPENSION'
        ,
        'AEGTEFAELLEDAEKNING'
        ,
        'BOERNEPENSION'
        ,
        'ALDERSOPSPARING_OPHOERENDE'
        ,
        'ALDERSOPSPARING_ENGANG'
        
    )
.id('UdbetalingsartTypeSchema')

export type UdbetalingsartType = 'LIVSPENSION_OPHOERENDE' | 'LIVSPENSION_LIVSVARIG' | 'KAPITALPENSION' | 'RATEPENSION' | 'OVERFOERSEL' | 'DELVIS_OVERFOERSEL' | 'DELVIS_TILBAGEKOEB' | 'TILBAGEKOEB' | 'INVALIDEPENSION' | 'AEGTEFAELLEDAEKNING' | 'BOERNEPENSION' | 'ALDERSOPSPARING_OPHOERENDE' | 'ALDERSOPSPARING_ENGANG';

export const UdbetalingsartType = {
    LivspensionOphoerende: 'LIVSPENSION_OPHOERENDE' as UdbetalingsartType,
    LivspensionLivsvarig: 'LIVSPENSION_LIVSVARIG' as UdbetalingsartType,
    Kapitalpension: 'KAPITALPENSION' as UdbetalingsartType,
    Ratepension: 'RATEPENSION' as UdbetalingsartType,
    Overfoersel: 'OVERFOERSEL' as UdbetalingsartType,
    DelvisOverfoersel: 'DELVIS_OVERFOERSEL' as UdbetalingsartType,
    DelvisTilbagekoeb: 'DELVIS_TILBAGEKOEB' as UdbetalingsartType,
    Tilbagekoeb: 'TILBAGEKOEB' as UdbetalingsartType,
    Invalidepension: 'INVALIDEPENSION' as UdbetalingsartType,
    Aegtefaelledaekning: 'AEGTEFAELLEDAEKNING' as UdbetalingsartType,
    Boernepension: 'BOERNEPENSION' as UdbetalingsartType,
    AldersopsparingOphoerende: 'ALDERSOPSPARING_OPHOERENDE' as UdbetalingsartType,
    AldersopsparingEngang: 'ALDERSOPSPARING_ENGANG' as UdbetalingsartType
};

