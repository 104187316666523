import { Component, Input } from '@angular/core';
import { ContentUtilService } from '../content/content-util.service';
import { ForbeholdModalService } from './forbehold-modal.service';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'co-modal-content-forbehold',
  templateUrl: './forbehold-modal.component.html'
})
// FIXME make local pro component for now
export class ForbeholdModalComponent {
  @Input() selectedMenu: string;

  flags = {
    paragraf39: false
  };
  pensionsplanForbehold: any;
  forbeholdInitialized = false;
  nettorenter: any = {};
  popHistory = [];
  tableHeaders: any;
  restoreState: any;
  taeAllInclusive: any;
  isPfaPro = true; // TODO what is this

  constructor(
    public popup: MatDialogRef<ForbeholdModalComponent>,
    private contentUtil: ContentUtilService,
    private forbeholdModalService: ForbeholdModalService
  ) {
    // Headers for resposive tables:
    this.tableHeaders = {
      pension_2019: contentUtil.getContent('DL.FF01.C255'),
      pension_2019T2028: contentUtil.getContent('DL.FF01.C152'),
      pension_2020T2028: contentUtil.getContent('DL.FF01.C256'),
      pension_2029: contentUtil.getContent('DL.FF01.C257'),

      mobil_samfund: contentUtil.getContent('DL.FF01.C451'),
      mobil_omkostninger: contentUtil.getContent('DL.FF01.C452'),
      mobil_skat: contentUtil.getContent('DL.FF01.C453'),
      mobil_inflation: contentUtil.getContent('DL.FF01.C454'),
      mobil_nettorente: contentUtil.getContent('DL.FF01.C455'),

      loebendepension_med: contentUtil.getContent('DL.FF01.C242'),
      loebendepension_uden: contentUtil.getContent('DL.FF01.C243')
    };

    this.forbeholdModalService.hentPensionsplanForbehold().subscribe(res => {
      this.pensionsplanForbehold = res;
      this.forbeholdInitialized = true;

      this.nettorenter.aktier = ForbeholdModalComponent.findNettoRente(
        this.pensionsplanForbehold.aktierSamfundsforudsaetning,
        this.pensionsplanForbehold.aktierOmkostning,
        this.pensionsplanForbehold.aktierSkat,
        this.pensionsplanForbehold.aktierInflation
      );

      this.nettorenter.obligationer = ForbeholdModalComponent.findNettoRente(
        this.pensionsplanForbehold.obligationerSamfundsforudsaetning,
        this.pensionsplanForbehold.obligationerOmkostning,
        this.pensionsplanForbehold.obligationerSkat,
        this.pensionsplanForbehold.obligationerInflation
      );

      this.nettorenter.pengemarked = ForbeholdModalComponent.findNettoRente(
        this.pensionsplanForbehold.kontanterSamfundsforudsaetning,
        this.pensionsplanForbehold.kontanterOmkostning,
        this.pensionsplanForbehold.kontanterSkat,
        this.pensionsplanForbehold.kontanterInflation
      );

      this.nettorenter.fastejendom = ForbeholdModalComponent.findNettoRente(
        this.pensionsplanForbehold.boligSamfundsforudsaetning,
        this.pensionsplanForbehold.boligOmkostning,
        this.pensionsplanForbehold.boligSkat,
        this.pensionsplanForbehold.boligInflation
      );

      this.flags.paragraf39 = this.pensionsplanForbehold.paragraf39;
    });

    if (!this.isPfaPro) {
      this.forbeholdModalService.harTAEAllInclusive().subscribe(res => {
        this.taeAllInclusive = res;
      });
    }

    // FIXME remove or use
    this.restoreState = {
      stateName: 'stateName',
      params: {},
      title: 'title'
    };
  }

  static findNettoRente(
    samfundsforudsaetninger: number,
    omkostninger: number,
    skatter: number,
    inflationer: number
  ) {
    const samfundsforudsaetning = samfundsforudsaetninger / 100;
    const omkostning = omkostninger / 100;
    const skat = skatter / 100;
    const inflation = inflationer / 100;
    const result =
      ((1 + (samfundsforudsaetning - omkostning) * (1 - skat)) /
        (1 + inflation) -
        1) *
      100;
    // rund af til 2 decimaler
    return Math.round((result + 0.00001) * 100) / 100;
  }

  setSelected(key: string) {
    const isForbehold = key.indexOf('forbehold.') !== -1;
    if (isForbehold) {
      // TODO $scope.popHistory.push($scope.selectedMenu);
      this.selectedMenu = key.replace('forbehold.', '');
      this.selectedMenu = this.selectedMenu.replace('mitoverblik.', '');
      return; // Break
    }
  }
}
