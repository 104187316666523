/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { IdAndName } from './idAndName';
import { IdAndNameSchema } from './idAndName';
import * as JoiObj from "joi";


    export const IndtastetdaekningSchema = JoiObj.object({
        beskatning:
            
        JoiObj.link('#IdAndNameSchema')


.required()


        ,
        pensionsalder:
            
                    JoiObj.number()
                .integer()


.required()


        ,
        periode:
            
                    JoiObj.number()
                .integer()

.allow(null)



        ,
        slutdato:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        type:
            
        JoiObj.link('#IdAndNameSchema')


.required()


        ,
        udbetaling:
            
                    JoiObj.number()
                


.required()


        
    })
                .shared(IdAndNameSchema)
                .shared(IdAndNameSchema)
.id('IndtastetdaekningSchema')

export interface Indtastetdaekning { 
    beskatning: IdAndName;
    pensionsalder: number;
    periode?: number;
    slutdato?: string;
    type: IdAndName;
    udbetaling: number;
}

