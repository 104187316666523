<div mat-dialog-title><h3 coContent="DL.FO01.C405"></h3></div>
<mat-dialog-content class="conent">
  <p [coContent]="'DL.FO01.C406'"></p>
  <div [coContent]="'DL.FO01.C407'"></div>
  <p
    *ngIf="errorSaving"
    [coContent]="'DL.MD01.C165'"
    class="text--color-primary"
  ></p>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="modal-buttons">
    <button
      mat-raised-button
      (click)="save()"
      [coContent]="'DL.FO01.C409'"
    ></button>
    <button
      class="button-secondary"
      mat-raised-button
      (click)="close()"
      [coContent]="'DL.FO01.C408'"
    ></button>
  </div>
</mat-dialog-actions>
