<svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g id="Canvas">
    <circle
      id="Large_circle"
      data-name="Large circle"
      cx="16"
      cy="16"
      r="16"
      fill="none"
    />
    <circle
      id="Small_circle"
      data-name="Small circle"
      cx="16"
      cy="16"
      r="12"
      fill="none"
    />
  </g>
  <g id="Icon">
    <path
      d="M18 10.5C18 11.6046 17.1045 12.5 16 12.5C14.8954 12.5 14 11.6046 14 10.5C14 9.39543 14.8954 8.5 16 8.5C17.1045 8.5 18 9.39543 18 10.5Z"
      fill="#C51414"
      stroke="black"
    />
    <path
      d="M18 16C18 17.1046 17.1045 18 16 18C14.8954 18 14 17.1046 14 16C14 14.8954 14.8954 14 16 14C17.1045 14 18 14.8954 18 16Z"
      fill="#FFAA00"
      stroke="black"
    />
    <path
      d="M18 21.5C18 22.6046 17.1045 23.5 16 23.5C14.8954 23.5 14 22.6046 14 21.5C14 20.3954 14.8954 19.5 16 19.5C17.1045 19.5 18 20.3954 18 21.5Z"
      fill="#418241"
      stroke="black"
    />
    <line x1="21.5" y1="8" x2="21.5" y2="24" stroke="black" />
    <line x1="10.5" y1="8" x2="10.5" y2="24" stroke="black" />
  </g>
</svg>
