<div class="guide-has-debt-container">
  <co-card-animated-stack [activeIndex]="activeIndex">
    <co-card-animated-stack-item [scrollToTopOnActivate]="true">
      <co-card-question
        (agreeClick)="displayDetails()"
        (declineClick)="declined()"
      >
        <div class="align--right guide-help-container--question hide-on-mobile">
          <mitpfa-investment-guide-help [broker]="broker">
          </mitpfa-investment-guide-help>
        </div>
        <div
          class="align--center text--manchet title--question"
          coContent="DL.INVGU01.C653"
        ></div>
        <p
          class="text--info align--center text--question"
          coContent="DL.INVGU01.C319"
        ></p>
        <div class="show-on-mobile">
          <mitpfa-investment-guide-help [broker]="broker">
          </mitpfa-investment-guide-help>
        </div>
        <div
          under-button-mobile
          class="question-previously-selected align--center text--note"
          *ngIf="investmentGuide.recommendationBasis?.debt?.hasDebt !== null"
          coContent="DL.INVGU01.C434"
          [coContentSubst]="
            '<strong>' +
            ((investmentGuide.recommendationBasis?.debt?.hasDebt
              ? 'DL.MD01.C29'
              : 'DL.MD01.C30'
            ) | content) +
            '</strong>'
          "
        ></div>
      </co-card-question>
    </co-card-animated-stack-item>

    <co-card-animated-stack-item [scrollToTopOnActivate]="true" maxWidth="100%">
      <co-card>
        <co-icon-times
          class="close-icon"
          (click)="closeDetails()"
        ></co-icon-times>
        <form [formGroup]="form">
          <div class="spacing-unit--under">
            <h3 class="align--center" coContent="DL.INVGU01.C385"></h3>
            <p class="text--info align--center" coContent="DL.INVGU01.C462"></p>
          </div>

          <div class="spacing-unit--double-over">
            <co-slider-form
              [label]="'DL.INVGU01.C194'"
              [sliderOptions]="sliderOptions"
              ngDefaultControl
              formControlName="currentDebt"
              type="currency"
            ></co-slider-form>
          </div>

          <div class="align--center spacing-unit--double-over">
            <button
              class="button-secondary"
              mat-raised-button
              (click)="goToNextPage()"
              [disabled]="!form.valid"
            >
              <span [coContent]="'DL.INVGU01.C621'"></span>
            </button>
          </div>
        </form>
      </co-card>
    </co-card-animated-stack-item>
  </co-card-animated-stack>
</div>

<div class="back-button-container--question" *ngIf="activeIndex === 0">
  <mitpfa-investment-guide-back-button
    (back)="goBack()"
  ></mitpfa-investment-guide-back-button>
</div>
