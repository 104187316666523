<iframe
  *ngIf="mitidIframeUrlTrusted"
  [attr.data-test-id]="'signing-mitid'"
  id="piFrame"
  title="Signing Iframe"
  #signingIframe
  [src]="mitidIframeUrlTrusted"
  height="740"
  width="100%"
  class="mitid--iframe"
>
</iframe>
<div class="mitid--error" *ngIf="signereFejlTekst">
  <strong [coContent]="'DL.GO01.C25'">DL.GO01.C25</strong>
  <p>{{ signereFejlTekst }}</p>
</div>
