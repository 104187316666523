import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges
} from '@angular/core';
import * as moment from 'moment';
import { Subscription } from 'rxjs';

@Component({
  selector: 'co-edit-field-date',
  templateUrl: './edit-field-date.component.html',
  styleUrls: ['./edit-field-date.component.scss']
})
export class EditFieldDateComponent implements OnChanges, OnDestroy {
  @Input() formGroup: UntypedFormGroup = new UntypedFormGroup({});
  @Input() minDate: Date;
  @Input() maxDate: Date;
  @Input() selectedDate: number | Date = new Date().getDate();
  @Input() isDisabled: boolean;
  @Input() required: boolean;
  @Input() withoutDays = false;
  @Input() alwaysFirstDayInMonth = false;
  @Output() closeEdit = new EventEmitter<number>();
  @Output() changeValue = new EventEmitter<number>();

  public editMode = false;
  public formControl = new UntypedFormControl();

  private subscribtion: Subscription;

  ngOnChanges(changes: SimpleChanges) {
    if (
      this.editMode &&
      changes.selectedDate?.currentValue &&
      !isNaN(changes.selectedDate?.currentValue)
    ) {
      /**
       * when date from input the same, don't set new value to avoid
       * infinity loop - datepicker will convert Date into moment object
       **/
      if (this.isFormControlDateTheSame(changes.selectedDate.currentValue)) {
        return;
      }

      this.formControl.setValue(new Date(changes.selectedDate.currentValue));
    }

    if (this.isDisabled) {
      this.formControl.disable();
    } else {
      this.formControl.enable();
    }
  }

  ngOnDestroy(): void {
    this.subscribtion?.unsubscribe();
  }

  private isFormControlDateTheSame(newDate): boolean {
    return (
      newDate instanceof Date &&
      newDate.getTime() === this.getFormControlTimestamp()
    );
  }

  public startEditDate(): void {
    this.formControl.setValue(new Date(this.selectedDate));
    if (!this.formGroup.controls.selectedDate) {
      this.formGroup.addControl('selectedDate', this.formControl);
    }

    this.subscribtion = this.formControl.valueChanges.subscribe(() => {
      if (this.isFormControlDateTheSame(this.selectedDate)) {
        return;
      }

      if (this.formControl.valid) {
        this.changeValue?.emit(this.getFormControlTimestamp());
        this.formGroup.markAsDirty();
      }
    });

    this.editMode = true;
  }

  public endEditDate(): void {
    this.subscribtion?.unsubscribe();
    this.closeEdit?.emit(this.getFormControlTimestamp());
    this.formGroup.markAsDirty();
    this.editMode = false;
  }

  private getFormControlTimestamp(): number {
    // moment object is in formControl when date is choice by datepicker, without choice is still Date object
    return moment.isMoment(this.formControl.value)
      ? this.formControl.value.toDate().getTime()
      : this.formControl.value.getTime();
  }

  public chosenMonthHandler(newDate, datepicker) {
    if (!this.withoutDays) {
      return;
    }

    newDate.set('date', 1);
    this.formControl.setValue(newDate);
    datepicker.close();
  }

  /**
   * If user type different day in input, set it always to first day
   */
  public valueChanged() {
    if (!this.alwaysFirstDayInMonth) {
      return;
    }

    const date = this.formControl.value;
    if (moment.isMoment(date) && date.date() !== 1) {
      date.set('date', 1);
      this.formControl.setValue(date);
    }
  }
}
