import { Injectable } from '@angular/core';
import { TrackingService } from './tracking.service';
import { TrackingName } from './misc-tracking.service';

export type OrderCategory =
  | 'investment'
  | 'payout'
  | 'customer-capital'
  | 'insurance'
  | 'beneficiary'
  | 'combine-savings'
  | 'basket'
  | 'pension-estimate';

export interface OrderItem {
  category: OrderCategory;
  name: string;
}

@Injectable()
export class OrderTrackingService extends TrackingService {
  public trackOrderCompleted(
    items: OrderItem[],
    confirmPlacement?: TrackingName
  ): void {
    if (items?.length > 0) {
      this.dispatchCustomEventTracking('track-order-completed', {
        orderItems: items,
        confirmPlacement
      });
    }
  }

  public trackApproveOrder(items: OrderItem[], orderPlacement?: string): void {
    this.trackOrder('approve', items, orderPlacement);
  }

  public trackAddOrder(items: OrderItem[]): void {
    this.trackOrder('add order', items);
  }

  public trackAddOrderWithPlacement(
    items: OrderItem[],
    confirmPlacement: string
  ): void {
    this.trackOrder('add order', items, confirmPlacement);
  }

  public trackGoToOrders(items: OrderItem[]): void {
    this.trackOrder('go to orders', items);
  }

  public trackRemoveOrder(items: OrderItem[]): void {
    this.trackOrder('remove order', items);
  }

  public trackRemoveAllOrders(items: OrderItem[]): void {
    this.trackOrder('remove all orders', items);
  }

  public trackGoToApproval(items: OrderItem[]): void {
    this.trackOrder('go to approval', items);
  }

  public trackSaveAndPublish(items: OrderItem[]): void {
    this.trackOrder('save and publish', items);
  }

  public trackShowReport(items: OrderItem[]): void {
    this.trackOrder('show report', items);
  }

  private trackOrder(orderAction, orderItems, orderPlacement?: string): void {
    if (orderItems?.length > 0) {
      this.dispatchCustomEventTracking('track-order-action', {
        orderAction,
        orderPlacement,
        orderItems
      });
    }
  }

  public trackOrderSignatureInitiated(
    items: OrderItem[],
    confirmPlacement?: TrackingName
  ): void {
    this.trackOrderSignature('initiated', items, confirmPlacement);
  }

  public trackOrderSignatureFailed(
    items: OrderItem[],
    confirmPlacement?: TrackingName
  ): void {
    this.trackOrderSignature('failed', items, confirmPlacement);
  }

  public trackOrderSignatureCompleted(
    items: OrderItem[],
    confirmPlacement?: TrackingName
  ): void {
    this.trackOrderSignature('completed', items, confirmPlacement);
  }

  private trackOrderSignature(
    orderSignatureAction,
    orderItems,
    confirmPlacement?: TrackingName
  ) {
    if (orderItems?.length > 0) {
      this.dispatchCustomEventTracking('track-order-signature', {
        orderSignatureAction,
        confirmPlacement,
        orderItems
      });
    }
  }

  public trackCallMeAction(items: OrderItem[]): void {
    this.trackOrder('please call me', items);
  }
}
