/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OriginOfMoney } from './originOfMoney';
import { OriginOfMoneySchema } from './originOfMoney';
import { PurposeOfNewPolicyList } from './purposeOfNewPolicyList';
import { PurposeOfNewPolicyListSchema } from './purposeOfNewPolicyList';
import { QuotationElement } from './quotationElement';
import { QuotationElementSchema } from './quotationElement';
import { PrepareOffer } from './prepareOffer';
import { PrepareOfferSchema } from './prepareOffer';
import { BusinessContactElement } from './businessContactElement';
import { BusinessContactElementSchema } from './businessContactElement';
import * as JoiObj from "joi";


    export const PrepareOfferResponseSchema = JoiObj.object({
        askForOriginOfMoney:
            
                    JoiObj.boolean()
                


.required()


        ,
        askForPurposeOfNewPolicy:
            
                    JoiObj.boolean()
                


.required()


        ,
        businessContactList:
                JoiObj.array().items(
        
        JoiObj.link('#BusinessContactElementSchema')

        )
        

.required()


        ,
        originOfMoneyList:
                JoiObj.array().items(
        
        JoiObj.link('#OriginOfMoneySchema')

        )
        

.required()


        ,
        purposeOfNewPolicyList:
            
        JoiObj.link('#PurposeOfNewPolicyListSchema')


.required()


        ,
        activeQuotation:
            
        JoiObj.link('#QuotationElementSchema')


.required()


        ,
        success:
            
                    JoiObj.boolean()
                


.required()


        
    })
                .shared(BusinessContactElementSchema)
                .shared(OriginOfMoneySchema)
                .shared(PurposeOfNewPolicyListSchema)
                .shared(QuotationElementSchema)
.id('PrepareOfferResponseSchema')

export interface PrepareOfferResponse extends PrepareOffer { 
    activeQuotation: QuotationElement;
    success: boolean;
}

