<ng-container *ngIf="!recommendation; else recommend">
  <div
    class="recommend-concept text--note text--bold"
    [coContent]="detail.current.conceptName"
  ></div>

  <div class="recommend-profile">
    <co-primary-number
      *ngIf="detail.current.hasPfaInvest && !detail.current.hasYouInvest"
      [amount]="detail.current.riskText"
      [unit]="detail.current.profileText"
      class="size-sm"
    ></co-primary-number>

    <co-primary-number
      *ngIf="detail.current.hasPfaOptional && !detail.current.hasYouInvest"
      [unit]="detail.current.riskText"
      [unitSubst]="
        (detail.current.highRisk | number: '1.2-2') +
        '|' +
        (detail.current.lowRisk | number: '1.2-2')
      "
      class="size-sm"
    ></co-primary-number>

    <co-primary-number
      *ngIf="
        detail.current.hasYouInvest &&
        (detail.current.hasPfaInvest || detail.current.hasPfaOptional)
      "
      [unit]="detail.current.riskText"
      [unitSubst]="
        (detail.current.youInvestPercent | number: '1.2-2') +
        '|' +
        (detail.current.pfaInvestPercent | number: '1.2-2') +
        '|' +
        (detail.current.profileText | content)
      "
      class="size-sm"
    ></co-primary-number>

    <co-primary-number
      *ngIf="detail.current.onlyYouInvest"
      [unit]="detail.current.riskText"
      [unitSubst]="
        (detail.current.allocation.bonds | number: '1.2-2') +
        '|' +
        (detail.current.allocation.shares | number: '1.2-2') +
        '|' +
        (detail.current.allocation.mixed | number: '1.2-2')
      "
      class="size-sm"
    ></co-primary-number>

    <co-primary-number
      *ngIf="detail.unboundSource"
      [amount]="detail.unboundSource.riskText"
      [unit]="detail.unboundSource.profileText"
      class="size-sm"
    ></co-primary-number>
  </div>

  <div class="recommend-risk text--mark">
    <span
      *ngIf="detail.current.hasPfaInvest && !detail.current.hasYouInvest"
      coContent="DL.INV.C655"
      [coContentSubst]="
        (detail.current.highRisk | number: '1.2-2') +
        '|' +
        (detail.current.lowRisk | number: '1.2-2')
      "
    ></span>

    <span
      *ngIf="detail.current.hasPfaOptional && !detail.current.hasYouInvest"
      coContent="DL.INV.C673"
    ></span>

    <span *ngIf="detail.current.onlyYouInvest" coContent="DL.INV.C674"></span>

    <span
      *ngIf="
        detail.current.hasYouInvest &&
        (detail.current.hasPfaInvest || detail.current.hasPfaOptional)
      "
      coContent="DL.INV.C675"
    ></span>
  </div>
</ng-container>

<ng-template #recommend>
  <div
    class="recommend-concept text--note text--bold"
    [coContent]="detail.recommendation.conceptName"
  ></div>

  <div class="recommend-profile">
    <co-primary-number
      *ngIf="!detail.recommendation.recommendYouInvest"
      [amount]="detail.recommendation.riskText"
      [unit]="detail.recommendation.profileText"
      class="size-sm"
    ></co-primary-number>

    <co-primary-number
      *ngIf="detail.recommendation.recommendYouInvest"
      [amount]="detail.recommendation.riskText"
      [unit]="''"
      class="size-sm"
    ></co-primary-number>
  </div>

  <div class="recommend-risk text--mark">
    <span
      *ngIf="!detail.recommendation.recommendYouInvest"
      coContent="DL.INV.C657"
      [coContentSubst]="
        (detail.recommendation.highRisk | number: '1.2-2') +
        '|' +
        (detail.recommendation.lowRisk | number: '1.2-2')
      "
    ></span>

    <span
      *ngIf="detail.recommendation.recommendYouInvest"
      coContent="DL.INV.C667"
      [coContentSubst]="
        (detail.recommendation.allocation.bonds | number: '1.2-2') +
        '|' +
        (detail.recommendation.allocation.shares | number: '1.2-2')
      "
    ></span>

    <div>
      <a class="link" coContent="DL.INV.C329" (click)="openReadMore()"></a>
    </div>
  </div>

  <co-alert
    *ngIf="
      detail.current.hasYouInvest &&
      (detail.current.hasPfaInvest || detail.current.hasPfaOptional) &&
      !detail.recommendation.recommendYouInvest
    "
    class="alert-info spacing-unit--half-over"
  >
    <span *ngIf="detail.current.hasPfaInvest" coContent="DL.INV.C694"></span>
    <span *ngIf="detail.current.hasPfaOptional" coContent="DL.INV.C703"></span>
  </co-alert>

  <co-alert
    *ngIf="
      detail.recommendation.recommendYouInvest &&
      (!detail.recommendation.isOptionalSelectable ||
        !detail.recommendation.isYouInvestSelectable)
    "
    class="spacing-unit--half-over"
  >
    <span
      *ngIf="
        !detail.recommendation.isOptionalSelectable &&
        !detail.recommendation.isYouInvestSelectable
      "
      coContent="DL.INV.C704"
    ></span>
    <span
      *ngIf="
        !detail.recommendation.isOptionalSelectable &&
        detail.recommendation.isYouInvestSelectable
      "
      coContent="DL.INV.C705"
    ></span>
    <span
      *ngIf="
        detail.recommendation.isOptionalSelectable &&
        !detail.recommendation.isYouInvestSelectable
      "
      coContent="DL.INV.C708"
    ></span>
  </co-alert>
</ng-template>
