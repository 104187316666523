<svg
  id="Layer_1"
  data-name="Layer 1"
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 68 176"
>
  <g>
    <rect
      class="cls-6"
      x="12.35"
      y="164.76"
      width="42.64"
      height="1.28"
      rx=".63"
      ry=".63"
    />
    <path
      class="cls-9"
      d="M50.8,143.13h5.35c1.15,0,2.07-.93,2.07-2.07h0c0-1.15-.93-2.07-2.07-2.07h-5.35c-.31,0-.56.25-.56.56v3.04c0,.31.25.56.56.56Z"
    />
    <path
      class="cls-9"
      d="M16.92,143.13h-5.35c-1.15,0-2.07-.93-2.07-2.07h0c0-1.15.93-2.07,2.07-2.07h5.35c.31,0,.56.25.56.56v3.04c0,.31-.25.56-.56.56Z"
    />
    <g class="cls-14">
      <path
        class="cls-5"
        d="M16.92,141.79h-5.35c-.91,0-1.68-.59-1.96-1.4-.07.21-.11.44-.11.67h0c0,1.15.93,2.07,2.07,2.07h5.35c.31,0,.56-.25.56-.56v-1.34c0,.31-.25.56-.56.56Z"
      />
    </g>
    <g class="cls-14">
      <path
        class="cls-5"
        d="M50.8,141.79h5.35c.91,0,1.68-.59,1.96-1.4.07.21.11.44.11.67h0c0,1.15-.93,2.07-2.07,2.07h-5.35c-.31,0-.56-.25-.56-.56v-1.34c0,.31.25.56.56.56Z"
      />
    </g>
    <path
      class="cls-9"
      d="M53.16,144.13H14.58l2.24-13.09c.21-1.53,1.67-2.68,3.4-2.68h27.3c1.74,0,3.2,1.15,3.4,2.68l2.24,13.09Z"
    />
    <path
      class="cls-12"
      d="M50.24,143.2H17.48l1.5-10.28c.18-1.2,1.42-2.1,2.89-2.1h23.97c1.47,0,2.71.9,2.89,2.1l1.5,10.28Z"
    />
    <rect
      class="cls-2"
      x="13.86"
      y="156.2"
      width="6.62"
      height="9.36"
      rx="1.43"
      ry="1.43"
    />
    <rect
      class="cls-2"
      x="46.93"
      y="156.2"
      width="6.62"
      height="9.36"
      rx="1.43"
      ry="1.43"
    />
    <rect
      class="cls-9"
      x="12.35"
      y="143.13"
      width="42.83"
      height="16.45"
      rx="3.09"
      ry="3.09"
    />
    <path
      class="cls-10"
      d="M52.09,143.13H15.44c-1.71,0-3.09,1.38-3.09,3.09v2.98c0-1.71,1.38-3.09,3.09-3.09h36.65c1.71,0,3.09,1.38,3.09,3.09v-2.98c0-1.71-1.38-3.09-3.09-3.09Z"
    />
    <g class="cls-14">
      <path
        class="cls-5"
        d="M52.32,155.24H15.21c-1.58,0-2.86-1.28-2.86-2.86v4.35c0,1.58,1.28,2.86,2.86,2.86h37.12c1.58,0,2.86-1.28,2.86-2.86v-4.35c0,1.58-1.28,2.86-2.86,2.86Z"
      />
    </g>
    <rect
      class="cls-1"
      x="24.06"
      y="147.34"
      width="19.42"
      height="4.02"
      rx="1.63"
      ry="1.63"
    />
    <rect
      class="cls-2"
      x="33.32"
      y="141.86"
      width="15.31"
      height="1.28"
      rx=".42"
      ry=".42"
    />
    <rect
      class="cls-6"
      x="13.86"
      y="147.34"
      width="8.88"
      height="4.02"
      rx="1.1"
      ry="1.1"
    />
    <circle class="cls-11" cx="16.34" cy="149.35" r="1.44" />
    <circle class="cls-11" cx="20.07" cy="149.35" r="1.24" />
    <rect
      class="cls-6"
      x="44.8"
      y="147.34"
      width="8.88"
      height="4.02"
      rx="1.1"
      ry="1.1"
    />
    <circle class="cls-11" cx="51.2" cy="149.35" r="1.44" />
    <circle class="cls-11" cx="47.47" cy="149.35" r="1.24" />
    <path
      class="cls-8"
      d="M17.79,142v-.02c5.67-3.72,15.17-7.25,30.63-9.9-.49-.76-1.47-1.27-2.57-1.27h-23.97c-1.47,0-2.71.9-2.89,2.1l-1.5,10.28h1.95c-.93,0-1.68-.54-1.65-1.2Z"
    />
    <rect
      class="cls-2"
      x="16.64"
      y="141.86"
      width="15.31"
      height="1.28"
      rx=".42"
      ry=".42"
    />
  </g>
  <g>
    <g id="House-3">
      <path
        class="cls-4"
        d="M18.38,79.02h29.1c2.23,0,4.03,1.81,4.03,4.03v18.58c0,2.23-1.81,4.03-4.03,4.03H14.43c-.05,0-.09-.04-.09-.09v-22.52c0-2.23,1.81-4.03,4.03-4.03h0Z"
      />
    </g>
    <g id="Door">
      <rect
        class="cls-2"
        x="20.36"
        y="88.63"
        width="9.16"
        height="17.03"
        rx="1.91"
        ry="1.91"
      />
    </g>
    <g id="Window">
      <rect
        class="cls-7"
        x="34.96"
        y="88.63"
        width="9.16"
        height="9.31"
        rx="1.91"
        ry="1.91"
      />
    </g>
    <g id="Door_window" data-name="Door window">
      <rect
        class="cls-7"
        x="22.49"
        y="90.79"
        width="4.9"
        height="4.98"
        rx="1.02"
        ry="1.02"
      />
    </g>
    <g id="Cornice">
      <path
        class="cls-3"
        d="M47.47,78.77h-29.1c-2.23,0-4.03,1.81-4.03,4.03v4.84h37.16v-4.84c0-2.23-1.81-4.03-4.03-4.03Z"
      />
    </g>
    <g id="Roof">
      <path
        class="cls-5"
        d="M51.69,85.99H14.16c-2.58,0-4.5-1.95-3.94-3.98l3.39-12.45c.6-1.63,2.21-2.67,3.94-2.57h30.73c1.74-.11,3.34.94,3.94,2.57l3.4,12.45c.55,2.05-1.36,3.98-3.95,3.98Z"
      />
    </g>
    <g id="Attic">
      <path
        class="cls-4"
        d="M37.39,81.68h-8.95c-.43,0-.79-.35-.79-.79h0v-6.12c0-.27.13-.51.36-.66l4.48-2.92c.26-.17.6-.17.86,0l4.47,2.92c.22.15.36.39.36.66v6.13c0,.43-.36.78-.79.78Z"
      />
    </g>
    <g id="Top_window" data-name="Top window">
      <rect
        class="cls-7"
        x="30.04"
        y="74.67"
        width="5.75"
        height="5.84"
        rx="1.2"
        ry="1.2"
      />
    </g>
    <g id="Bush">
      <path
        class="cls-13"
        d="M58.22,100.84c.01-3.8-3.06-6.89-6.87-6.91-2.42,0-4.67,1.26-5.92,3.34-3.42-.67-6.73,1.55-7.41,4.97-.23,1.18-.12,2.4.32,3.52h17.79c1.33-1.29,2.08-3.07,2.08-4.93Z"
      />
    </g>
    <g id="Chimney">
      <rect
        class="cls-4"
        x="20.42"
        y="62.45"
        width="5.91"
        height="6.85"
        rx="1.72"
        ry="1.72"
      />
    </g>
  </g>
  <g>
    <path
      class="cls-9"
      d="M10.28,30.9l5.61-2.24c1-.4,2.11-.41,3.08-.02l2.9,1.14,9.08-3.63,7.75-3.1,9.53-3.81c2.01-.8,4.08-.47,5.19,1.27l1.39-.55c1.27-.51,2.73.12,3.24,1.39h0c.51,1.27-.12,2.73-1.39,3.24l-.42.17-4.22,1.69-.79.32-5.32,2.13-4.2,1.68-7.75,3.1-.43.17c-6.04,2.42-13.7,4.06-14.61,1.79l-.14-.36c-.08-.21-.16-.4-.23-.59-1.31-.52-6.37-2.55-8.27-3.31-.2-.08-.19-.36.02-.45Z"
    />
    <g class="cls-15">
      <path
        class="cls-5"
        d="M58.08,21.43c.44,1.25-.18,2.64-1.42,3.14l-.42.17-4.22,1.69-.79.32-5.32,2.13-4.2,1.68-7.75,3.1-.43.17c-4.78,1.91-10.58,3.34-13.24,2.73l37.79-15.12Z"
      />
    </g>
    <path
      class="cls-11"
      d="M53.45,20.49l-2.85,1.14c-.3.12-.64-.03-.76-.32s.03-.64.32-.76l2.42-.97c.3.22.64.53.86.91"
    />
    <rect
      class="cls-11"
      x="44.83"
      y="22.46"
      width="1.81"
      height="1.16"
      rx=".58"
      ry=".58"
      transform="translate(-5.29 18.64) rotate(-21.81)"
    />
    <rect
      class="cls-11"
      x="42.43"
      y="23.42"
      width="1.81"
      height="1.16"
      rx=".58"
      ry=".58"
      transform="translate(-5.82 17.82) rotate(-21.81)"
    />
    <rect
      class="cls-11"
      x="40.03"
      y="24.38"
      width="1.81"
      height="1.16"
      rx=".58"
      ry=".58"
      transform="translate(-6.34 17) rotate(-21.81)"
    />
    <rect
      class="cls-11"
      x="37.63"
      y="25.34"
      width="1.81"
      height="1.16"
      rx=".58"
      ry=".58"
      transform="translate(-6.87 16.18) rotate(-21.81)"
    />
    <rect
      class="cls-11"
      x="35.23"
      y="26.3"
      width="1.81"
      height="1.16"
      rx=".58"
      ry=".58"
      transform="translate(-7.4 15.35) rotate(-21.81)"
    />
    <rect
      class="cls-11"
      x="32.83"
      y="27.26"
      width="1.81"
      height="1.16"
      rx=".58"
      ry=".58"
      transform="translate(-7.93 14.53) rotate(-21.81)"
    />
    <rect
      class="cls-11"
      x="30.44"
      y="28.22"
      width="1.81"
      height="1.16"
      rx=".58"
      ry=".58"
      transform="translate(-8.46 13.71) rotate(-21.81)"
    />
    <path
      class="cls-9"
      d="M28.89,43.73c1.17-2.78,5.17-12.19,5.69-13.42.04-.09.1-.15.19-.19l9.78-3.91c.28-.11.56.17.44.45h0s-4.91,11.47-4.91,11.47c-.58,1.36-1.68,2.43-3.05,2.98l-7.69,3.08c-.29.11-.57-.17-.45-.46Z"
    />
    <path
      class="cls-12"
      d="M23.26,17.15c0-.07-.01-.13-.01-.2,0-1.18.97-2.15,2.15-2.15s2.11.93,2.14,2.08h2.46c1.35,0,2.45,1.1,2.45,2.45v.17c0,1.35-1.1,2.45-2.45,2.45h-5.64c-1.35,0-2.45-1.1-2.45-2.45v-.17c0-.95.55-1.78,1.35-2.18Z"
    />
    <path
      class="cls-12"
      d="M47.8,35.53h.5c.13-.71.75-1.25,1.49-1.25s1.36.54,1.49,1.25h.5c.95,0,1.74.78,1.74,1.74v.12c0,.95-.78,1.74-1.74,1.74h-4c-.95,0-1.74-.78-1.74-1.74v-.12c0-.95.78-1.74,1.74-1.74Z"
    />
  </g>
</svg>
