import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy
} from '@angular/core';
import { OekonomiDetaljer, PensionsKundeGenerelleData } from '@pfa/gen';
import {
  Validators,
  UntypedFormGroup,
  UntypedFormControl
} from '@angular/forms';

@Component({
  selector: 'mitpfa-financial-situation-salary',
  templateUrl: './financial-situation-salary.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FinancialSituationSalaryComponent implements OnInit {
  @Input() financialSituation: OekonomiDetaljer;
  @Input() pensionCustomer: PensionsKundeGenerelleData;
  @Input() isInInsuranceGuide = false;
  @Input() form: UntypedFormGroup;

  salaryForm: UntypedFormGroup;
  showSalaryForm: boolean;

  ngOnInit(): void {
    this.initializeForm();
  }

  private initializeForm(): void {
    this.showSalaryForm =
      this.pensionCustomer.isRaadgiver ||
      !this.isInInsuranceGuide ||
      (this.isInInsuranceGuide &&
        (!this.financialSituation.registreretLoen ||
          !!this.financialSituation.beregneAndenLoen));

    if (!this.showSalaryForm) {
      return;
    }

    const presentAnnualSalaryValidators = [Validators.maxLength(8)];
    if (!this.financialSituation.registreretLoen) {
      presentAnnualSalaryValidators.push(Validators.required);
    }

    this.salaryForm = new UntypedFormGroup({
      recordedAnnualSalary: new UntypedFormControl(
        {
          value: Math.round(this.financialSituation.registreretLoen || 0),
          disabled: true
        },
        Validators.maxLength(8)
      ),
      presentAnnualSalary: new UntypedFormControl(
        Math.round(this.financialSituation.beregneAndenLoen),
        presentAnnualSalaryValidators
      )
    });

    if (this.form) {
      this.form.addControl('salary', this.salaryForm);
    }
  }
}
