/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { QuestionSummary } from './questionSummary';
import { QuestionSummarySchema } from './questionSummary';
import * as JoiObj from "joi";


    export const QuestionnaireSummarySchema = JoiObj.object({
        headline:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        id:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        locale:
            
                    JoiObj.object()
                


.required()


        ,
        majorVersion:
            
                    JoiObj.number()
                


.required()


        ,
        minorVersion:
            
                    JoiObj.number()
                


.required()


        ,
        questionSummary:
                JoiObj.array().items(
        
        JoiObj.link('#QuestionSummarySchema')

        )
        

.required()


        
    })
                .shared(QuestionSummarySchema)
.id('QuestionnaireSummarySchema')

export interface QuestionnaireSummary { 
    headline: string;
    id: string;
    locale: object;
    majorVersion: number;
    minorVersion: number;
    questionSummary: Array<QuestionSummary>;
}

