/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ServiceInfo } from './serviceInfo';
import { ServiceInfoSchema } from './serviceInfo';
import { TransactionType } from './transactionType';
import { TransactionTypeSchema } from './transactionType';
import { QuotationPolicy } from './quotationPolicy';
import { QuotationPolicySchema } from './quotationPolicy';
import { AttachedFile } from './attachedFile';
import { AttachedFileSchema } from './attachedFile';
import { ResourceModel } from './resourceModel';
import { ResourceModelSchema } from './resourceModel';
import { MaeglerInfo } from './maeglerInfo';
import { MaeglerInfoSchema } from './maeglerInfo';
import { SalesOrder } from './salesOrder';
import { SalesOrderSchema } from './salesOrder';
import * as JoiObj from "joi";


    export const PrivatIndskudSchema = JoiObj.object({
        serviceInfo:
            
        JoiObj.link('#ServiceInfoSchema')

.allow(null)



        ,
        accumulatedDeposit:
            
                    JoiObj.number()
                


.required()


        ,
        alder:
            
                    JoiObj.number()
                


.required()


        ,
        amount:
            
                    JoiObj.number()
                


.required()


        ,
        amountReceived:
            
                    JoiObj.number()
                


.required()


        ,
        attachedFiles:
                JoiObj.array().items(
        
        JoiObj.link('#AttachedFileSchema')

        )
        

.required()


        ,
        comment:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        currencyCode:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        description:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        history:
                JoiObj.array().items(
        
        JoiObj.link('#SalesOrderSchema')

        )
        

.required()


        ,
        maeglerBetjent:
            
                    JoiObj.boolean()
                


.required()


        ,
        maeglerInfo:
            
        JoiObj.link('#MaeglerInfoSchema')


.required()


        ,
        mlDocExpectedDate:
            
                    JoiObj.object()
                


.required()


        ,
        mlDocMissingComment:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        moneyLaunderingDocLimit:
            
                    JoiObj.number()
                


.required()


        ,
        originOfMoneyComment:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        originOfMoneySelectedList:
                JoiObj.array().items(
        
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

        )
        

.required()


        ,
        paymentID:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        policyDescription:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        policyId:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        policyList:
                JoiObj.array().items(
        
        JoiObj.link('#QuotationPolicySchema')

        )
        

.required()


        ,
        quotationGUID:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        quotationID:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        remainingBankDays:
            
                    JoiObj.number()
                


.required()


        ,
        retrieveQuotationFailed:
            
                    JoiObj.boolean()
                


.required()


        ,
        retrieveSalesOrderListFailed:
            
                    JoiObj.boolean()
                


.required()


        ,
        salesOrderGUID:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        salesOrderID:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        status:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        transactionType:
            
        JoiObj.link('#TransactionTypeSchema')


.required()


        
    })
                .shared(ServiceInfoSchema)
                .shared(AttachedFileSchema)
                .shared(SalesOrderSchema)
                .shared(MaeglerInfoSchema)
                .shared(QuotationPolicySchema)
                .shared(TransactionTypeSchema)
.id('PrivatIndskudSchema')

export interface PrivatIndskud extends ResourceModel { 
    accumulatedDeposit: number;
    alder: number;
    amount: number;
    amountReceived: number;
    attachedFiles: Array<AttachedFile>;
    comment: string;
    currencyCode: string;
    description: string;
    history: Array<SalesOrder>;
    maeglerBetjent: boolean;
    maeglerInfo: MaeglerInfo;
    mlDocExpectedDate: object;
    mlDocMissingComment: string;
    moneyLaunderingDocLimit: number;
    originOfMoneyComment: string;
    originOfMoneySelectedList: Array<string>;
    paymentID: string;
    policyDescription: string;
    policyId: string;
    policyList: Array<QuotationPolicy>;
    quotationGUID: string;
    quotationID: string;
    remainingBankDays: number;
    retrieveQuotationFailed: boolean;
    retrieveSalesOrderListFailed: boolean;
    salesOrderGUID: string;
    salesOrderID: string;
    status: string;
    transactionType: TransactionType;
}
export namespace PrivatIndskud {
}


