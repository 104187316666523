<!--
  Prompt the user to go through the investment guide if the climate questions are not completed.
  If the general questions (investmentGuideInfo.isGuideCompleted) are not completed
  another info box will be shown in the outer container
-->
<co-bodytext>
  <co-card
    *ngIf="investmentGuideInfo.isGuideCompleted && !isClimateQuestionsAnswered"
    class="investment-guide-info"
  >
    <p [coContent]="'DL.INV.C472'"></p>
    <div class="align--center">
      <button
        [coContent]="'DL.INV.C361'"
        [routerLink]="'/mineinvesteringer/guide'"
        mat-raised-button
      >
        Tag guiden
      </button>
    </div>
  </co-card>
</co-bodytext>

<div class="pfa-climate-profiles">
  <div *ngFor="let profile of climateProfiles; let i = index" class="card">
    <div
      *ngIf="!showPercentageChooser && !responsiveMobile"
      class="card--animation"
      [@slideInBottom]="{
        value: '',
        params: { duration: 300, delay: i * 300 + 300 }
      }"
      [@slideOutBottom]="{ value: '', params: { delay: i * 300 } }"
    >
      <mitpfa-pfa-investment-card
        [card]="profile"
        [showCheckmark]="showCheckmark"
      >
        <div footer>
          <button
            [disabled]="
              profile.isSelected && profile.type !== 'CLIMATE_PLUS_HIGH'
            "
            *ngIf="profile.isCurrent"
            [coContent]="'DL.INV.C474'"
            class="button-secondary--level3"
            mat-raised-button
            (click)="profileClicked(profile)"
          >
            Aktiv nu
          </button>
          <button
            [disabled]="profile.isSelected || profile.isDisabled"
            [coContent]="'DL.INV.C491'"
            [coContentSubst]="profile.climatePlusPercentage"
            *ngIf="!profile.isCurrent && profile.type === 'CLIMATE_PLUS_NONE'"
            class="button-secondary"
            mat-raised-button
            (click)="profileClicked(profile)"
          >
            Vælg PFA plus
          </button>
          <button
            [disabled]="profile.isSelected || profile.isDisabled"
            [coContent]="'DL.INV.C475'"
            [coContentSubst]="profile.climatePlusPercentage"
            *ngIf="
              !profile.isCurrent &&
              (profile.type === 'CLIMATE_PLUS_LOW' ||
                profile.type === 'CLIMATE_PLUS_MIDDLE')
            "
            class="button-secondary"
            mat-raised-button
            (click)="profileClicked(profile)"
          >
            Vælg klima plus
          </button>
          <button
            [disabled]="profile.isDisabled"
            [coContent]="'DL.INV.C476'"
            *ngIf="!profile.isCurrent && profile.type === 'CLIMATE_PLUS_HIGH'"
            class="button-secondary"
            mat-raised-button
            (click)="profileClicked(profile)"
          >
            Vælg klima plus flex
          </button>
        </div>
      </mitpfa-pfa-investment-card>
    </div>
    <div
      *ngIf="
        (!showPercentageChooser && responsiveMobile) ||
        (showPercentageChooser &&
          responsiveMobile &&
          profile.type !== 'CLIMATE_PLUS_HIGH')
      "
      [@slideInRightWithDelay]="{ value: '', params: { delay: 400 } }"
      [@slideOutRightWithDelay]="{ value: '', params: { delay: 0 } }"
    >
      <mitpfa-pfa-investment-card
        [card]="profile"
        [showCheckmark]="showCheckmark"
      >
        <div footer>
          <button
            [disabled]="
              (profile.isSelected && profile.type !== 'CLIMATE_PLUS_HIGH') ||
              profile.isDisabled
            "
            *ngIf="profile.isCurrent"
            [coContent]="'DL.INV.C474'"
            class="button-secondary--level3"
            mat-raised-button
            (click)="profileClicked(profile)"
          >
            Aktiv nu
          </button>
          <button
            [disabled]="profile.isSelected || profile.isDisabled"
            [coContent]="'DL.INV.C491'"
            [coContentSubst]="profile.climatePlusPercentage"
            *ngIf="!profile.isCurrent && profile.type === 'CLIMATE_PLUS_NONE'"
            class="button-secondary"
            mat-raised-button
            (click)="profileClicked(profile)"
          >
            Vælg PFA plus
          </button>
          <button
            [disabled]="profile.isSelected || profile.isDisabled"
            [coContent]="'DL.INV.C475'"
            [coContentSubst]="profile.climatePlusPercentage"
            *ngIf="
              !profile.isCurrent &&
              (profile.type === 'CLIMATE_PLUS_LOW' ||
                profile.type === 'CLIMATE_PLUS_MIDDLE')
            "
            class="button-secondary"
            mat-raised-button
            (click)="profileClicked(profile)"
          >
            Vælg klima plus
          </button>
          <button
            [disabled]="profile.isDisabled"
            [coContent]="'DL.INV.C476'"
            *ngIf="!profile.isCurrent && profile.type === 'CLIMATE_PLUS_HIGH'"
            class="button-secondary"
            mat-raised-button
            (click)="profileClicked(profile)"
          >
            Vælg klima plus flex
          </button>
        </div>
      </mitpfa-pfa-investment-card>
    </div>
  </div>
</div>
<mitpfa-climate-percentage-chooser
  class="pfa-climate-profiles"
  *ngIf="showPercentageChooser && !responsiveMobile"
  [pfaClimateProfile]="selecedProfile"
  [@slideInBottom]="{ value: '', params: { duration: 300, delay: 1200 } }"
  [@slideOutBottom]="{ value: '', params: { delay: 0 } }"
  (profileSelected)="percentageChosen($event)"
>
</mitpfa-climate-percentage-chooser>
<div class="pfa-climate-chooser-mobile">
  <mitpfa-climate-percentage-chooser
    class="pfa-climate-profiles mobile"
    *ngIf="showPercentageChooser && responsiveMobile"
    [pfaClimateProfile]="selecedProfile"
    [@slideInRightWithDelay]="{ value: '', params: { delay: 400 } }"
    [@slideOutRightWithDelay]="{ value: '', params: { delay: 0 } }"
    (profileSelected)="percentageChosen($event)"
  >
  </mitpfa-climate-percentage-chooser>
</div>

<!--confirm choice -->
<co-confirm-choice
  [smallCard]="true"
  [confirmedDate]="confirmClimateDate"
  [error]="confirmClimateError"
  [isDisabled]="isDisabled()"
  (confirmed)="confirmProfile()"
  [success]="confirmClimateSuccess"
  [texts]="confirmChoiceComponentTexts"
>
</co-confirm-choice>
