import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { JsErrorMessage } from '@pfa/gen';
import { Subject } from 'rxjs';
import { ValidationError } from 'joi';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerApiService {
  private static readonly ERROR_CAUSE_JAVASCRIPT =
    'JAVASCRIPT_ERROR from frontend';
  private static readonly ERROR_CAUSE_SCHEMA_VALIDATION =
    'VALIDATION_ERROR from frontend';

  private readonly suppressErrorNavigation = new Subject<boolean>();
  readonly suppressErrorNavigationAnnounced$ =
    this.suppressErrorNavigation.asObservable();

  constructor(private readonly http: HttpClient) {}

  notifySuppressErrorNavigation(suppress: boolean): void {
    this.suppressErrorNavigation.next(suppress);
  }

  saveErrorToLog(error: JsErrorMessage) {
    const requestOptions = {
      headers: new HttpHeaders().set('Content-Type', 'application/json')
    };
    return this.http
      .post<JsErrorMessage>('/ds/open/log/jserror', error, requestOptions)
      .subscribe({ error: () => {} });
  }

  saveValidationOrJavascriptErrorToLog(error: any): void {
    const category =
      error instanceof ValidationError
        ? ErrorHandlerApiService.ERROR_CAUSE_SCHEMA_VALIDATION
        : ErrorHandlerApiService.ERROR_CAUSE_JAVASCRIPT;

    const errorJson = {
      cause: `${category}: ${error.status}`,
      errorCode: undefined,
      errorDescription: error.message,
      errorUrl: error.url,
      page: undefined,
      stackTrace: error.stack
    } as JsErrorMessage;

    this.saveErrorToLog(errorJson);
  }

  saveSigningErrorToLog(errorMessage: string, errorUrl: string): void {
    this.saveErrorToLog({
      errorCode: '',
      page: undefined,
      errorDescription: errorMessage,
      errorUrl,
      stackTrace: [],
      cause: `SIGNING_ERROR using MitId`
    });
  }
}
