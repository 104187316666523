/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Skattekode } from './skattekode';
import { SkattekodeSchema } from './skattekode';
import { Valuta } from './valuta';
import { ValutaSchema } from './valuta';
import * as JoiObj from "joi";


    export const ForsikringDaekningerUnderUdbetalingGruppeSchema = JoiObj.object({
        daekningsId:
            
                    JoiObj.number()
                

.allow(null)



        ,
        pensionsAftaleId:
            
                    JoiObj.number()
                


.required()


        ,
        skattekode:
            
        JoiObj.link('#SkattekodeSchema')


.required()


        ,
        udbetalingOphoerDato:
            
                    JoiObj.object()
                

.allow(null)



        ,
        udbetalingStartDato:
            
                    JoiObj.object()
                

.allow(null)



        ,
        valuta:
            
        JoiObj.link('#ValutaSchema')


.required()


        ,
        ydelseBeloeb:
            
                    JoiObj.number()
                


.required()


        
    })
                .shared(SkattekodeSchema)
                .shared(ValutaSchema)
.id('ForsikringDaekningerUnderUdbetalingGruppeSchema')

export interface ForsikringDaekningerUnderUdbetalingGruppe { 
    daekningsId?: number;
    pensionsAftaleId: number;
    skattekode: Skattekode;
    udbetalingOphoerDato?: object;
    udbetalingStartDato?: object;
    valuta: Valuta;
    ydelseBeloeb: number;
}
export namespace ForsikringDaekningerUnderUdbetalingGruppe {
}


