import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { OekonomiDetaljer, PensionsKundeGenerelleData } from '@pfa/gen';
import { InvestmentGuideService } from '@mitpfa/shared';
import { GuideStepType, GuideTrackingService, GuidePlacement } from '@pfa/core';
import {
  InvestmentGuideName,
  InvestmentGuideTrackName
} from '../investment-guide-configuration.service';

@Component({
  selector: 'mitpfa-investment-guide-intro',
  templateUrl: './investment-guide-intro.component.html',
  styleUrls: ['./investment-guide-intro.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InvestmentGuideIntroComponent implements OnInit, AfterViewInit {
  @Input() pensionCustomer: PensionsKundeGenerelleData;
  @Input() financialSituation: OekonomiDetaljer;
  @Input() isOnboardingFlow: boolean;
  @Output() startGuide = new EventEmitter<void>();

  public advisorShortFlow = false;
  public isShortFlowVisible = false;
  public dataValidForShortFlow: boolean;

  constructor(
    readonly investmentGuideService: InvestmentGuideService,
    readonly trackingService: GuideTrackingService
  ) {}

  public ngOnInit(): void {
    this.isShortFlowVisible =
      this.pensionCustomer.isRaadgiver &&
      this.investmentGuideService.isAgeExceeded(this.pensionCustomer.alder);
    if (this.isShortFlowVisible) {
      this.dataValidForShortFlow = this.validateDataForShortFlow();
    }
  }

  public ngAfterViewInit(): void {
    window.scroll(0, 0);
  }

  public startInvestmentGuide(): void {
    if (this.isShortFlowVisible) {
      this.investmentGuideService.markFlowAsShort(this.advisorShortFlow);
    }

    if (this.advisorShortFlow) {
      this.trackingService.trackGuideSkip(
        InvestmentGuideName.NAME,
        InvestmentGuideTrackName.WELCOME,
        GuideStepType.OPTIONAL,
        this.isOnboardingFlow
          ? GuidePlacement.ONBOARDING
          : GuidePlacement.INVESTMENT
      );
    }

    this.trackingService.trackGuideStart(
      InvestmentGuideName.NAME,
      InvestmentGuideTrackName.WELCOME,
      GuideStepType.MANDATORY,
      this.isOnboardingFlow
        ? GuidePlacement.ONBOARDING
        : GuidePlacement.INVESTMENT
    );

    this.startGuide.emit();
  }

  private validateDataForShortFlow(): boolean {
    if (this.financialSituation.frieMidler.boligValgt) {
      for (const property of this.financialSituation.frieMidler.boliger) {
        if (!property.ejendomNavn) {
          return false;
        }
      }
    }

    if (this.financialSituation.frieMidler.opsparingIBank === null) {
      return false;
    }

    if (this.financialSituation.frieMidler.gaeldIBankIdag === null) {
      return false;
    }

    return true;
  }
}
