/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const KontaktFordelTypeSchema = JoiObj.string().valid(
        'Pension'
        ,
        'Direktion'
        ,
        'Investering'
        ,
        'Jura'
        ,
        'Sundhed'
        ,
        'Privatlaege'
        
    )
.id('KontaktFordelTypeSchema')

export type KontaktFordelType = 'Pension' | 'Direktion' | 'Investering' | 'Jura' | 'Sundhed' | 'Privatlaege';

export const KontaktFordelType = {
    Pension: 'Pension' as KontaktFordelType,
    Direktion: 'Direktion' as KontaktFordelType,
    Investering: 'Investering' as KontaktFordelType,
    Jura: 'Jura' as KontaktFordelType,
    Sundhed: 'Sundhed' as KontaktFordelType,
    Privatlaege: 'Privatlaege' as KontaktFordelType
};

