/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const OnlineServiceSchema = JoiObj.string().valid(
        'PHYSIOTHERAPIST'
        ,
        'PSYCHOLOGIST'
        ,
        'COACH'
        ,
        'DOCTOR'
        ,
        'FAMILYTHERAPIST'
        ,
        'UNKNOWN'
        
    )
.id('OnlineServiceSchema')

export type OnlineService = 'PHYSIOTHERAPIST' | 'PSYCHOLOGIST' | 'COACH' | 'DOCTOR' | 'FAMILYTHERAPIST' | 'UNKNOWN';

export const OnlineService = {
    Physiotherapist: 'PHYSIOTHERAPIST' as OnlineService,
    Psychologist: 'PSYCHOLOGIST' as OnlineService,
    Coach: 'COACH' as OnlineService,
    Doctor: 'DOCTOR' as OnlineService,
    Familytherapist: 'FAMILYTHERAPIST' as OnlineService,
    Unknown: 'UNKNOWN' as OnlineService
};

