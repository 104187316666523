/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ServiceInfo } from './serviceInfo';
import { ServiceInfoSchema } from './serviceInfo';
import { AttachedFile } from './attachedFile';
import { AttachedFileSchema } from './attachedFile';
import { ResourceModel } from './resourceModel';
import { ResourceModelSchema } from './resourceModel';
import * as JoiObj from "joi";


    export const CreateBankTransferSalesOrderResponseSchema = JoiObj.object({
        serviceInfo:
            
        JoiObj.link('#ServiceInfoSchema')

.allow(null)



        ,
        attachedFiles:
                JoiObj.array().items(
        
        JoiObj.link('#AttachedFileSchema')

        )
        

.required()


        ,
        createBankTransferSalesOrderFailed:
            
                    JoiObj.boolean()
                


.required()


        
    })
                .shared(ServiceInfoSchema)
                .shared(AttachedFileSchema)
.id('CreateBankTransferSalesOrderResponseSchema')

export interface CreateBankTransferSalesOrderResponse extends ResourceModel { 
    attachedFiles: Array<AttachedFile>;
    createBankTransferSalesOrderFailed: boolean;
}

