<mat-dialog-content>
  <strong [coContent]="'DL.TF01.C95'"></strong>
  <p [coContent]="'DL.TF01.C96'"></p>
  <div coContent="DL.TF01.C206">
    Disse ændringer følger ikke PFAs anbefalinger:
  </div>
  <ul>
    <li *ngIf="reasonLoc" coContent="DL.TF01.C203">PFA Erhvervsevne</li>
    <li *ngIf="reasonCi" coContent="DL.TF01.C204">PFA Kritisk sygdom</li>
    <li *ngIf="reasonLife" coContent="DL.TF01.C205">PFA Liv</li>
  </ul>
  <br />
  <p [coContent]="'DL.TF01.C97'"></p>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="modal-buttons">
    <button
      mat-raised-button
      class="capitalize"
      (click)="accept()"
      [coContent]="'Global.ja'"
    ></button>
    <button
      mat-raised-button
      class="capitalize"
      (click)="cancel()"
      [coContent]="'Global.nej'"
    ></button>
  </div>
</mat-dialog-actions>
