import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
  OnInit
} from '@angular/core';
import { PensionsKundeGenerelleData } from '@pfa/gen';
import { ContentUtilService, PageTrackingService } from '@pfa/core';
import { FOOTER_NAVIGATION_TRACKING } from '../../footer.model';

@Component({
  selector: 'co-footer-contact-standard',
  templateUrl: './footer-contact-standard.component.html',
  styleUrls: ['./footer-contact-standard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterContactStandardComponent implements OnInit {
  @Input() isLight: boolean;
  @Input() isLetPension: boolean;
  @Input() user: PensionsKundeGenerelleData;

  private readonly contentUtilService: ContentUtilService =
    inject(ContentUtilService);
  private readonly pageTrackingService: PageTrackingService =
    inject(PageTrackingService);

  contactPhone: string;

  ngOnInit(): void {
    this.contactPhone = this.contentUtilService.getContent(
      this.user?.specialMessagingRules && !this.isLight
        ? 'DL.KO01.C318'
        : 'DL.KO01.C317'
    );
  }

  phoneClicked(): void {
    this.pageTrackingService.trackNavigation(
      FOOTER_NAVIGATION_TRACKING.name,
      FOOTER_NAVIGATION_TRACKING.action.callPfa,
      ''
    );
  }

  writeClicked(): void {
    this.pageTrackingService.trackNavigation(
      FOOTER_NAVIGATION_TRACKING.name,
      FOOTER_NAVIGATION_TRACKING.action.writePfa,
      FOOTER_NAVIGATION_TRACKING.destination.messaging
    );
  }

  contactClicked(): void {
    this.pageTrackingService.trackNavigation(
      FOOTER_NAVIGATION_TRACKING.name,
      FOOTER_NAVIGATION_TRACKING.action.contact,
      FOOTER_NAVIGATION_TRACKING.destination.contact
    );
  }
}
