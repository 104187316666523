import {
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {
  BestiltAendringer,
  InForceSelection,
  InvesteringPolice,
  InvestmentQuestionaire,
  InvestmentRecommendation,
  PensionsKundeGenerelleData,
  PoliceType
} from '@pfa/gen';
import { RecommendationDetail } from './investment-recommendation.model';
import { InvestmentRecommendationService } from './investment-recommendation.service';
import { InvestmentGuideInfo } from '@pfa/models';
import { Router } from '@angular/router';
import {
  GuidePlacement,
  GuideRecommendationStepNameType,
  GuideTrackingService,
  MiscTrackingService,
  TrackingName
} from '@pfa/core';

@Component({
  selector: 'mitpfa-investment-recommendation',
  templateUrl: './investment-recommendation.component.html',
  styleUrls: ['./investment-recommendation.component.scss']
})
export class InvestmentRecommendationComponent implements OnInit, OnChanges {
  private readonly elementRef: ElementRef = inject(ElementRef);
  private readonly investmentRecommendationService: InvestmentRecommendationService =
    inject(InvestmentRecommendationService);

  private readonly guideTrackingService: GuideTrackingService =
    inject(GuideTrackingService);
  private readonly router: Router = inject(Router);
  private readonly miscTrackingService: MiscTrackingService =
    inject(MiscTrackingService);

  @Output() changes = new EventEmitter<unknown>();
  @Output() navigateConcept = new EventEmitter<void>();
  @Output() exit = new EventEmitter();
  @Input() policy: InvesteringPolice;
  @Input() investmentGuideInfo: InvestmentGuideInfo;
  @Input() basket: BestiltAendringer;
  @Input() inForceSelections: InForceSelection[];
  @Input() isOnboardingFlow: boolean;

  public activeCardIndex = 0;
  public recommendationDetail: RecommendationDetail | null;
  public inForceClimate: InForceSelection;
  public orgPolicy: InvesteringPolice;
  public profileExpanded: boolean;
  public climateExpanded: boolean;
  public isProfileChanged: boolean;
  public isClimateChanged: boolean;
  public recommendationComplete: boolean;

  public overviewData: {
    recommendation: InvestmentRecommendation;
    recommendationBasis: InvestmentQuestionaire;
  };

  public ngOnInit(): void {
    this.recommendationInit();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.policy) {
      this.recommendationInit();
    }
  }

  public nextCard(event: boolean, cardType?: string): void {
    if (cardType === 'profile') {
      this.profileExpanded = false;
      this.isProfileChanged = event;
    } else if (cardType === 'climate') {
      this.climateExpanded = false;
      this.isClimateChanged = event;
    }

    this.changes.emit({
      isProfileChanged: this.isProfileChanged,
      isClimateChanged: this.isClimateChanged
    });

    this.activeCardIndex++;
    this.trackAdobeByCardIndexId(this.activeCardIndex);
  }

  public previousCard(): void {
    if (this.profileExpanded || this.climateExpanded) {
      window.scrollTo(0, this.elementRef.nativeElement.offsetTop);
    }

    this.profileExpanded = false;
    this.climateExpanded = false;
    this.recommendationDetail.manualClimatePercent = null;
    this.activeCardIndex--;

    this.trackAdobeByCardIndexId(this.activeCardIndex);
  }

  public setExpanded(bool: boolean, accordion: string): void {
    if (accordion === 'profile') {
      this.profileExpanded = bool;
    } else if (accordion === 'climate') {
      this.climateExpanded = bool;
    }
  }

  shouldTakeOrRetakeInvestmentGuide(): boolean {
    return (
      !this.investmentGuideInfo.isGuideCompleted ||
      (this.investmentGuideInfo.isGuideCompleted &&
        this.investmentGuideInfo.isGuideMoreThan3YearOld)
    );
  }

  takeInvestmentGuide(): void {
    const destUrl = '/mineinvesteringer/guide';
    this.miscTrackingService.trackNavigationCard(
      'investment-guide',
      'take guide',
      destUrl
    );
    this.router.navigateByUrl(destUrl);
  }

  private recommendationInit(): void {
    this.recommendationComplete = false;
    this.orgPolicy = JSON.parse(JSON.stringify(this.policy));

    this.recommendationDetail =
      (this.policy.type === PoliceType.Gennemsnit && !this.policy.harProfilG) ||
      !this.investmentGuideInfo.recommendation
        ? null
        : this.investmentRecommendationService.buildDetail(
            this.policy,
            this.investmentGuideInfo.recommendation
          );

    this.overviewData = {
      recommendation: this.investmentGuideInfo.recommendation,
      recommendationBasis: this.investmentGuideInfo.recommendationBasis
    };

    if (this.shouldTakeOrRetakeInvestmentGuide()) {
      this.guideTrackingService.trackGuideRecommendationImpression(
        TrackingName.INVESTMENT_GUIDE,
        this.isOnboardingFlow
          ? GuidePlacement.ONBOARDING
          : GuidePlacement.INVESTMENT,
        'IG REC take guide'
      );
    } else {
      this.guideTrackingService.trackGuideRecommendationImpression(
        TrackingName.INVESTMENT_GUIDE,
        this.isOnboardingFlow
          ? GuidePlacement.ONBOARDING
          : GuidePlacement.INVESTMENT,
        'IG REC see recommendation'
      );
    }
  }

  private trackAdobeByCardIndexId(activeCardIndex: number): void {
    if (activeCardIndex === 0) {
      //on intro -> nothing to track yest
    }

    if (activeCardIndex === 1) {
      //on profile -> send start event
      this.guideTrackingService.trackGuideRecommendationStart(
        TrackingName.INVESTMENT_GUIDE,
        this.buildAdobeTrackingProfileStepText(),
        this.isOnboardingFlow
          ? GuidePlacement.ONBOARDING
          : GuidePlacement.INVESTMENT
      );
    }

    if (activeCardIndex === 2) {
      //on climate -> send climate event
      this.sendTrackingOnClimate();
    }

    if (activeCardIndex === 3) {
      //on summary
      this.sendTrackingOnSummury();
    }
  }

  private sendTrackingOnClimate(): void {
    let trackingStepName;
    if (
      this.recommendationDetail &&
      !this.recommendationDetail.current.onlyYouInvest
    ) {
      // pfa invest -> climate step IG REC pfainvest climate
      trackingStepName = 'IG REC pfainvest climate';
    } else if (
      this.recommendationDetail &&
      this.recommendationDetail.current.onlyYouInvest
    ) {
      // youinvest ontly -> climate step  IG REC climate
      trackingStepName = 'IG REC climate';
    } else {
      //default
      trackingStepName = 'IG REC climate';
    }

    this.guideTrackingService.trackGuideRecommendationStep(
      TrackingName.INVESTMENT_GUIDE,
      trackingStepName,
      this.isOnboardingFlow
        ? GuidePlacement.ONBOARDING
        : GuidePlacement.INVESTMENT
    );
  }

  private sendTrackingOnSummury(): void {
    if (!this.isProfileChanged && !this.isClimateChanged) {
      this.guideTrackingService.trackGuideRecommendationComplete(
        TrackingName.INVESTMENT_GUIDE,
        'IG REC guide complete - keep',
        this.isOnboardingFlow
          ? GuidePlacement.ONBOARDING
          : GuidePlacement.INVESTMENT
      );
    } else {
      this.guideTrackingService.trackGuideRecommendationStep(
        TrackingName.INVESTMENT_GUIDE,
        'IG REC guide result',
        this.isOnboardingFlow
          ? GuidePlacement.ONBOARDING
          : GuidePlacement.INVESTMENT
      );
    }
  }

  private buildAdobeTrackingProfileStepText(): GuideRecommendationStepNameType {
    //current
    const stepTrackingCurrentPart =
      this.recommendationDetail.current.adobeTrackingStepName;

    //recommended you
    const stepTrackingRecommendedPart =
      this.recommendationDetail.recommendation.adobeTrackingStepName;

    const areEmpty = !stepTrackingCurrentPart || !stepTrackingRecommendedPart;

    if (stepTrackingCurrentPart === stepTrackingRecommendedPart || areEmpty) {
      return `IG REC ${stepTrackingCurrentPart}` as GuideRecommendationStepNameType;
    } else {
      return `IG REC ${stepTrackingCurrentPart} to ${stepTrackingRecommendedPart}` as GuideRecommendationStepNameType;
    }
  }

  public skip(): void {
    this.exit.emit();
  }

  public complete(): void {
    if (this.isOnboardingFlow) {
      this.exit.emit();
    } else {
      this.recommendationComplete = !this.recommendationComplete;
    }
  }
}
