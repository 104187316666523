import { Injectable } from '@angular/core';
import { ContentService } from './content.service';
import { HttpClient } from '@angular/common/http';
import { Utils } from '@pfa/utils';

@Injectable({
  providedIn: 'root'
})
export class ContentUtilService {
  constructor(
    private contentService: ContentService,
    private httpClient: HttpClient
  ) {}

  getContent(contentId: string, substitutionValues?: Array<string> | string) {
    if (contentId) {
      return this.getContentLocal(contentId, substitutionValues, false);
    }
    return '';
  }

  parseContentString(contentId: string) {
    return this.getContentLocal(contentId, undefined, false);
  }

  processContent(
    contentString: string,
    contentId: string,
    substitutionValues: string | Array<string> | undefined,
    noPropagate?: boolean
  ): string {
    contentString = this.attachLinksToContent(
      contentString,
      contentId,
      noPropagate
    );
    contentString = this.substituteValues(contentString, substitutionValues);

    return contentString;
  }

  // @TODO: it seems noPropagate is always set to false so maybe drop this?
  /**
   * Gets the content for the supplied contentId
   *
   * @param contentId           Id of the content
   * @param substitutionValues  Array of values that should be merged into the text
   * @param noPropagate  if true, click event on external link wouldn't be propagated
   */
  private getContentLocal(
    contentId: string,
    substitutionValues: string | Array<string> | undefined,
    noPropagate?: boolean
  ) {
    if (!this.contentService.getContent()) {
      return contentId;
    }
    const contentString: string = this.processContent(
      this.contentService.getContent()[contentId],
      contentId,
      substitutionValues,
      noPropagate
    );

    if (contentString) {
      return contentString;
    } else {
      return contentString === '' ? '' : contentId;
    }
  }

  private substituteValues(
    contentString: string,
    substitutionValues?: Array<string> | string
  ): string {
    // handle substitution values if contentString contains and subst values
    if (
      contentString &&
      contentString.indexOf('{') > -1 &&
      substitutionValues
    ) {
      if (Array.isArray(substitutionValues)) {
        substitutionValues.forEach(function (substValue, index) {
          contentString = contentString.replace('{' + index + '}', substValue);
        });
      } else {
        contentString = contentString.replace('{0}', substitutionValues);
      }
    }

    return contentString;
  }

  private attachLinksToContent(
    contentString: string,
    contentId: string,
    noPropagate: boolean = false
  ) {
    let emailEmne;

    if (contentString && contentString.indexOf('{LINK!') > -1) {
      const linkRegex = /\{LINK!/g;
      const numberOfLinks = contentString?.match(linkRegex)?.length ?? 0;
      let linkStartPos, linkEndPos, linkObject, linkParts, link;
      for (let i = 0; i < numberOfLinks; i++) {
        linkStartPos = contentString.indexOf('{LINK!');
        linkEndPos = contentString.indexOf('}', linkStartPos) + 1;
        linkObject = contentString.substring(linkStartPos, linkEndPos);
        linkParts = linkObject.replace('{', '').replace('}', '').split('!');
        if (linkParts[1] === 'LE') {
          // external link
          if (noPropagate) {
            link = `<a class="link link-external" href="${linkParts[2]}" target="_blank" onclick="Utils.noPropagate($event)">${linkParts[3]}</a>`;
          } else {
            link = `<a class="link link-external" href="${linkParts[2]}" target="_blank">${linkParts[3]}</a>`;
          }
          contentString = contentString.replace(linkObject, link);
        } else if (linkParts[1] === 'LESSO') {
          // external link with SSO token

          // get sso type of format: [typename]
          let ssoType = linkParts[2].match(/\[.*?\]/);

          if (ssoType !== null) {
            ssoType = ssoType[0]; // regex.match returns an array ...

            switch (ssoType) {
              case '[forebyggersso]':
                link =
                  '<a class="link forebygger-link link-external" href="' +
                  linkParts[3] +
                  '" target="_blank">' +
                  linkParts[4] +
                  '</a>';
                contentString = contentString.replace(linkObject, link);

                this.httpClient
                  .get('/ds/api/forebygger/token')
                  .toPromise()
                  .then(function (res) {
                    if (!Utils.isEmpty(res)) {
                      const token = res;
                      const url = linkParts[2].replace(
                        '[forebyggersso]',
                        token
                      );
                      Array.from(
                        document.getElementsByClassName('forebygger-link')
                      ).forEach(element => {
                        element.setAttribute('href', url);
                      });
                    }
                  });
                break;
            }
          }
        } else if (linkParts[1] === 'LI') {
          if (
            window.location.hostname.includes('localhost') ||
            window.location.hostname.includes('dev') ||
            window.location.hostname.includes('test') ||
            window.location.hostname.includes('uat') ||
            window.location.hostname.includes('webauth')
          ) {
            contentString =
              '<co-warning class="text--bold"><span>ERROR - LI used in ' +
              contentId +
              ' not converted to LIU</span></co-warning>';
          } else {
            contentString = ' ';
          }
        } else if (linkParts[1] === 'LIU') {
          // internal link URL-edition
          link =
            '<a class="link" (click)="navigateToUrl(\'' +
            linkParts[2] +
            '\')">' +
            linkParts[3] +
            '</a>';
          contentString = contentString.replace(linkObject, link);
        } else if (linkParts[1] === 'TLF') {
          // clickable telephone number
          link =
            '<a href="tel:' +
            linkParts[2] +
            '" class="link link-telephone">' +
            linkParts[3] +
            '</a>';
          contentString = contentString.replace(linkObject, link);
        } else if (linkParts[1] === 'TOPIC') {
          // internal link til emner
          link =
            '<a class="link" (click)="popupTopicFromContent(\'' +
            linkParts[2] +
            '\')">' +
            linkParts[3] +
            '</a>';
          contentString = contentString.replace(linkObject, link);
        } else if (linkParts[1] === 'MAIL') {
          // clickable email link
          emailEmne = linkParts[4] ? '?subject=' + linkParts[4] : '';
          link =
            '<a href="mailto:' +
            linkParts[2] +
            emailEmne +
            '" class="link link-email">' +
            linkParts[3] +
            '</a>';
          contentString = contentString.replace(linkObject, link);
        } else {
          break; // avoid never ending loop if syntax error in link (ie unknown link type)
        }
      }
    }

    return contentString;
  }
}
