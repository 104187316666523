<!-- v5 -->
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
  <g id="Canvas">
    <circle
      id="Large_circle"
      data-name="Large circle"
      cx="16"
      cy="16"
      r="16"
      fill="none"
    />
    <circle
      id="Small_circle"
      data-name="Small circle"
      cx="16"
      cy="16"
      r="12"
      fill="none"
    />
  </g>
  <g id="Icon">
    <path
      id="o"
      d="M22.72,20.78a2.73,2.73,0,0,1-.15.92,2,2,0,0,1-1.16,1.2,2.65,2.65,0,0,1-.92.16,2.43,2.43,0,0,1-.92-.17,2,2,0,0,1-.7-.47,1.91,1.91,0,0,1-.45-.72,2.69,2.69,0,0,1-.16-.92,2.75,2.75,0,0,1,.16-.93,1.91,1.91,0,0,1,.45-.72,1.86,1.86,0,0,1,.7-.46,2.57,2.57,0,0,1,1.84,0,2,2,0,0,1,1.16,1.19A2.69,2.69,0,0,1,22.72,20.78Zm-1.12,0a1.7,1.7,0,0,0-.3-1.07,1,1,0,0,0-.81-.38,1,1,0,0,0-.58.17,1.08,1.08,0,0,0-.39.51,2.35,2.35,0,0,0,0,1.54,1.15,1.15,0,0,0,.38.5,1,1,0,0,0,.59.18,1,1,0,0,0,.81-.39A1.67,1.67,0,0,0,21.6,20.78Z"
      transform="translate(0)"
    />
    <path
      id="r"
      d="M16.41,21.44v.94a.73.73,0,0,1-.16.51.55.55,0,0,1-.41.17.51.51,0,0,1-.39-.17.73.73,0,0,1-.16-.51V19.26c0-.5.18-.76.55-.76a.45.45,0,0,1,.4.18,1,1,0,0,1,.13.52,1.94,1.94,0,0,1,.42-.52.82.82,0,0,1,.56-.18,1.41,1.41,0,0,1,.68.18.52.52,0,0,1,.34.47.43.43,0,0,1-.15.34.41.41,0,0,1-.3.13,1.07,1.07,0,0,1-.3-.08,1.31,1.31,0,0,0-.41-.07.61.61,0,0,0-.41.13.83.83,0,0,0-.24.38,3,3,0,0,0-.12.61C16.42,20.82,16.41,21.11,16.41,21.44Z"
      transform="translate(0)"
    />
    <path
      id="P"
      d="M12.23,20.61H11.12v1.71a.82.82,0,0,1-.17.55.6.6,0,0,1-.44.19.55.55,0,0,1-.44-.19.8.8,0,0,1-.17-.55V17.69a.79.79,0,0,1,.18-.58.88.88,0,0,1,.59-.17h1.56a4.11,4.11,0,0,1,1.07.11,1.58,1.58,0,0,1,.64.34,1.61,1.61,0,0,1,.41.58,2.13,2.13,0,0,1,.14.78,1.71,1.71,0,0,1-.57,1.39A2.56,2.56,0,0,1,12.23,20.61Zm-.29-2.75h-.82v1.83h.82a2.43,2.43,0,0,0,.72-.09.82.82,0,0,0,.44-.29.91.91,0,0,0,.15-.54,1,1,0,0,0-.23-.65A1.63,1.63,0,0,0,11.94,17.86Z"
      transform="translate(0)"
    />
    <path
      id="Diagram_right_part"
      data-name="Diagram right part"
      d="M20.4,14a4.71,4.71,0,0,1,.1,1,5.08,5.08,0,0,1-.34,1.83"
      transform="translate(0)"
      fill="none"
      stroke="#000"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.2"
    />
    <path
      id="Diagram_left_part"
      data-name="Diagram left part"
      d="M10.5,15a5,5,0,0,1,5-5,4.71,4.71,0,0,1,1,.1"
      transform="translate(0)"
      fill="none"
      stroke="#000"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.2"
    />
    <line
      id="Third_diagonal"
      data-name="Third diagonal"
      x1="19"
      y1="14"
      x2="21.81"
      y2="11.19"
      stroke-width="1.2"
      stroke="#000"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
    />
    <line
      id="Second_diagonal"
      data-name="Second diagonal"
      x1="16.5"
      y1="14"
      x2="20.74"
      y2="9.76"
      stroke-width="1.2"
      stroke="#000"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
    />
    <line
      id="First_diagonal"
      data-name="First diagonal"
      x1="16.5"
      y1="11.5"
      x2="19.31"
      y2="8.69"
      stroke-width="1.2"
      stroke="#000"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
    />
    <path
      id="Part"
      d="M22.5,14a6,6,0,0,0-6-6v6Z"
      transform="translate(0)"
      fill="none"
      stroke="#000"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.2"
    />
  </g>
</svg>
