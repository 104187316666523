/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HentInternOverfoerselResponse } from './hentInternOverfoerselResponse';
import { HentInternOverfoerselResponseSchema } from './hentInternOverfoerselResponse';
import { SimulatorTileEnum } from './simulatorTileEnum';
import { SimulatorTileEnumSchema } from './simulatorTileEnum';
import * as JoiObj from "joi";


    export const InternOverfoerselSignereRequestObjSchema = JoiObj.object({
        mitIdSig:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        revisionKey:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        sprog:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        tile:
            
        JoiObj.link('#SimulatorTileEnumSchema')


.required()


        ,
        internoverfoersel:
            
        JoiObj.link('#HentInternOverfoerselResponseSchema')


.required()


        
    })
                .shared(SimulatorTileEnumSchema)
                .shared(HentInternOverfoerselResponseSchema)
.id('InternOverfoerselSignereRequestObjSchema')

export interface InternOverfoerselSignereRequestObj { 
    mitIdSig: string;
    revisionKey: string;
    sprog: string;
    tile: SimulatorTileEnum;
    internoverfoersel: HentInternOverfoerselResponse;
}
export namespace InternOverfoerselSignereRequestObj {
}


