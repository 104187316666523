import { Component, Input, Output, EventEmitter } from '@angular/core';

export interface Step {
  id?: string;
  label: string;
  link?: string;
  progress: number;
}

@Component({
  selector: 'co-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss']
})
export class StepperComponent {
  @Input() steps: Step[];
  @Input() selectedStepIndex = 0;
  @Input() isNavigationEnabled = true;
  @Input() showNavigation: boolean;
  @Output() stepSelected: EventEmitter<Step> = new EventEmitter<Step>();

  selectStep(index: number): void {
    this.stepSelected.emit(this.steps[index]);
  }

  handleSelectStep(index: number): void {
    if (this.isNavigationEnabled) {
      this.selectStep(index);
    }
  }

  trackByFn(index, item) {
    return item.label;
  }

  handleBackButton() {
    this.selectStep(
      this.selectedStepIndex > 0
        ? this.selectedStepIndex - 1
        : this.selectedStepIndex
    );
  }

  handleNextButton() {
    const lastIndex = this.steps.length - 1;
    this.selectStep(
      this.selectedStepIndex < lastIndex
        ? this.selectedStepIndex + 1
        : lastIndex
    );
  }
}
