<!-- v4 -->
<svg
  id="Layer_1"
  data-name="Layer 1"
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 32 32"
>
  <g id="Canvas">
    <circle
      id="Large_circle"
      data-name="Large circle"
      cx="16"
      cy="16"
      r="16"
      fill="none"
    />
    <circle
      id="Small_circle"
      data-name="Small circle"
      cx="16"
      cy="16"
      r="12"
      fill="none"
    />
  </g>
  <g id="Icon">
    <path
      id="Phone"
      d="M13.81,14.37a16.51,16.51,0,0,0,1.75,2.07c1.76,1.75,3.54,2.82,4,2.38l.64-.63a1.35,1.35,0,0,1,1.91,0h0l1.27,1.27a1.35,1.35,0,0,1,0,1.91h0l-.64.63c-1.75,1.76-6,.34-9.54-3.18S8.24,11,10,9.28l.63-.64a1.35,1.35,0,0,1,1.91,0h0l1.27,1.27a1.35,1.35,0,0,1,0,1.91h0l-.63.64"
      fill="none"
      stroke="#000"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.2"
    />
  </g>
</svg>
