<header class="overview-header">
  <h5 [coContent]="texts.intro.title"></h5>
  <ng-container
    *ngIf="data.recommendation.calculatedInvestmentConcept === 'YOU_INVEST'"
  >
    <p *ngFor="let text of texts.intro.youInvest" [coContent]="text"></p>
  </ng-container>
  <p [coContent]="texts.intro.content"></p>
</header>
<div *ngIf="content" class="overview-content">
  <ng-container *ngIf="!showClimate; else climate">
    <mitpfa-investment-recommendation-overview-section
      [content]="content.involvement"
      [accordionName]="'IG recommendation - involvement'"
    >
      <co-icon-slider icon></co-icon-slider>
    </mitpfa-investment-recommendation-overview-section>

    <mitpfa-investment-recommendation-overview-section
      [content]="content.finances"
      [accordionName]="'IG recommendation - finances'"
    >
      <co-icon-coin-stack icon></co-icon-coin-stack>
    </mitpfa-investment-recommendation-overview-section>

    <mitpfa-investment-recommendation-overview-section
      [content]="content.risk"
      [accordionName]="'IG recommendation - risk'"
    >
      <co-icon-savings-bag icon></co-icon-savings-bag>
    </mitpfa-investment-recommendation-overview-section>
  </ng-container>

  <ng-template #climate>
    <mitpfa-investment-recommendation-overview-section
      [content]="content.climate"
      [accordionName]="'IG recommendation - climate'"
    >
      <co-icon-climate icon></co-icon-climate>
    </mitpfa-investment-recommendation-overview-section>
  </ng-template>
</div>
