/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BeneficiarySimple } from './beneficiarySimple';
import { BeneficiarySimpleSchema } from './beneficiarySimple';
import * as JoiObj from "joi";


    export const BeneficiarySignRequestSchema = JoiObj.object({
        beneficiaryData:
            
        JoiObj.link('#BeneficiarySimpleSchema')


.required()


        ,
        language:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        
    })
                .shared(BeneficiarySimpleSchema)
.id('BeneficiarySignRequestSchema')

export interface BeneficiarySignRequest { 
    beneficiaryData: BeneficiarySimple;
    language: string;
}

