/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OversigtForsikring } from './oversigtForsikring';
import { OversigtForsikringSchema } from './oversigtForsikring';
import * as JoiObj from "joi";


    export const ForebyggerForsikringSchema = JoiObj.object({
        forebyggerForsikringer:
                JoiObj.array().items(
        
        JoiObj.link('#OversigtForsikringSchema')

        )
        

.required()


        
    })
                .shared(OversigtForsikringSchema)
.id('ForebyggerForsikringSchema')

export interface ForebyggerForsikring { 
    forebyggerForsikringer: Array<OversigtForsikring>;
}

