import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import {RestCacheEntry} from "./RestCacheEntry";
import {GenericStore} from "./GenericStore";
import * as JoiObj from "joi";

import {
        EksternOverfoerselAnbefaling,
        EksternOverfoerselAnbefalingSchema,
        EksternOverfoerselSignereRequestObj,
        EksternOverfoerselSignereRequestObjSchema,
        EksternOverfoerselSignereResponseObj,
        EksternOverfoerselSignereResponseObjSchema,
        EksternOverfoerselUnderskriftRequestObj,
        EksternOverfoerselUnderskriftRequestObjSchema,
        GodkendSvar,
        GodkendSvarSchema,
        Lead,
        LeadSchema,
        ReceivingAgreementStatus,
        ReceivingAgreementStatusSchema,
        RecommendationShortInfo,
        RecommendationShortInfoSchema,
        SuccessResponse,
        SuccessResponseSchema
} from '../model/models';

import { ExternaltransferService } from './externaltransfer.service';


@Injectable({
    providedIn: 'root'
})
export class ExternaltransferStore extends GenericStore {

            private externaltransferIndexCache: Map<string, RestCacheEntry<EksternOverfoerselAnbefaling>> = new Map<string, RestCacheEntry<EksternOverfoerselAnbefaling>>();
            private externaltransferreceivingAgreementStatusIndexCache: Map<string, RestCacheEntry<ReceivingAgreementStatus>> = new Map<string, RestCacheEntry<ReceivingAgreementStatus>>();
            private externaltransferrecommendationShortInfoIndexCache: Map<string, RestCacheEntry<RecommendationShortInfo>> = new Map<string, RestCacheEntry<RecommendationShortInfo>>();

    constructor(private externaltransferService: ExternaltransferService) {
        super();
    }

    invalidateAll(): void {
                
                
                
                
                
                this.invalidateexternaltransferIndex();
                
                
                
                
                
                this.invalidateexternaltransferreceivingAgreementStatusIndex();
                
                this.invalidateexternaltransferrecommendationShortInfoIndex();
                
                
                
                
    }




                    public externaltransferCalculatePost(eksternOverfoerselAnbefaling?: EksternOverfoerselAnbefaling) : Observable<EksternOverfoerselAnbefaling> {
                        return this.externaltransferService.externaltransferCalculatePost(eksternOverfoerselAnbefaling).pipe(
                            map(value => {
                                
                                
                                        this.throwOrHandleValidationError(EksternOverfoerselAnbefalingSchema, value);
                                return value;
                            })
                        );
                    }






                    public externaltransferConfirmchoicePost() : Observable<SuccessResponse> {
                        return this.externaltransferService.externaltransferConfirmchoicePost().pipe(
                            map(value => {
                                
                                
                                        this.throwOrHandleValidationError(SuccessResponseSchema, value);
                                return value;
                            })
                        );
                    }




                public externaltransferGet() : Observable<EksternOverfoerselAnbefaling> {
                    let hash = "0";
                    if (this.externaltransferIndexCache.has(hash)) {
                        return this.externaltransferIndexCache.get(hash)!.observable;
                    } else {
                        let externaltransferIndexSubject: BehaviorSubject<0> = new BehaviorSubject(0);
                        let externaltransferIndex = this.initiateStream(externaltransferIndexSubject, this.externaltransferService.externaltransferGet(), EksternOverfoerselAnbefalingSchema);
                        this.externaltransferIndexCache.set(hash, new RestCacheEntry<EksternOverfoerselAnbefaling>(externaltransferIndex, externaltransferIndexSubject));
                        return externaltransferIndex;
                    }
                }

                public invalidateexternaltransferIndex() {
                    this.externaltransferIndexCache.forEach((cacheEntry) => {
                        cacheEntry.subject.next(0);
                    })
                }








                    public externaltransferLeadPost(lead?: Lead) : Observable<any> {
                        return this.externaltransferService.externaltransferLeadPost(lead).pipe(
                            map(value => {
                                
                                
                                return value;
                            })
                        );
                    }






                    public externaltransferPost(eksternOverfoerselAnbefaling?: EksternOverfoerselAnbefaling) : Observable<EksternOverfoerselAnbefaling> {
                        return this.externaltransferService.externaltransferPost(eksternOverfoerselAnbefaling).pipe(
                            map(value => {
                                
                                this.invalidateexternaltransferIndex();
                                        this.throwOrHandleValidationError(EksternOverfoerselAnbefalingSchema, value);
                                return value;
                            })
                        );
                    }




                public externaltransferReceivingAgreementStatusGet() : Observable<ReceivingAgreementStatus> {
                    let hash = "0";
                    if (this.externaltransferreceivingAgreementStatusIndexCache.has(hash)) {
                        return this.externaltransferreceivingAgreementStatusIndexCache.get(hash)!.observable;
                    } else {
                        let externaltransferreceivingAgreementStatusIndexSubject: BehaviorSubject<0> = new BehaviorSubject(0);
                        let externaltransferreceivingAgreementStatusIndex = this.initiateStream(externaltransferreceivingAgreementStatusIndexSubject, this.externaltransferService.externaltransferReceivingAgreementStatusGet(), ReceivingAgreementStatusSchema);
                        this.externaltransferreceivingAgreementStatusIndexCache.set(hash, new RestCacheEntry<ReceivingAgreementStatus>(externaltransferreceivingAgreementStatusIndex, externaltransferreceivingAgreementStatusIndexSubject));
                        return externaltransferreceivingAgreementStatusIndex;
                    }
                }

                public invalidateexternaltransferreceivingAgreementStatusIndex() {
                    this.externaltransferreceivingAgreementStatusIndexCache.forEach((cacheEntry) => {
                        cacheEntry.subject.next(0);
                    })
                }






                public externaltransferRecommendationShortInfoGet() : Observable<RecommendationShortInfo> {
                    let hash = "0";
                    if (this.externaltransferrecommendationShortInfoIndexCache.has(hash)) {
                        return this.externaltransferrecommendationShortInfoIndexCache.get(hash)!.observable;
                    } else {
                        let externaltransferrecommendationShortInfoIndexSubject: BehaviorSubject<0> = new BehaviorSubject(0);
                        let externaltransferrecommendationShortInfoIndex = this.initiateStream(externaltransferrecommendationShortInfoIndexSubject, this.externaltransferService.externaltransferRecommendationShortInfoGet(), RecommendationShortInfoSchema);
                        this.externaltransferrecommendationShortInfoIndexCache.set(hash, new RestCacheEntry<RecommendationShortInfo>(externaltransferrecommendationShortInfoIndex, externaltransferrecommendationShortInfoIndexSubject));
                        return externaltransferrecommendationShortInfoIndex;
                    }
                }

                public invalidateexternaltransferrecommendationShortInfoIndex() {
                    this.externaltransferrecommendationShortInfoIndexCache.forEach((cacheEntry) => {
                        cacheEntry.subject.next(0);
                    })
                }








                    public externaltransferSignPost(eksternOverfoerselSignereRequestObj?: EksternOverfoerselSignereRequestObj) : Observable<EksternOverfoerselSignereResponseObj> {
                        return this.externaltransferService.externaltransferSignPost(eksternOverfoerselSignereRequestObj).pipe(
                            map(value => {
                                
                                
                                        this.throwOrHandleValidationError(EksternOverfoerselSignereResponseObjSchema, value);
                                return value;
                            })
                        );
                    }







                    public externaltransferSignedUuidPut(uuid: string,eksternOverfoerselUnderskriftRequestObj?: EksternOverfoerselUnderskriftRequestObj) : Observable<GodkendSvar> {
                        return this.externaltransferService.externaltransferSignedUuidPut(uuid,eksternOverfoerselUnderskriftRequestObj).pipe(
                            map(value => {
                                
                                
                                        this.throwOrHandleValidationError(GodkendSvarSchema, value);
                                return value;
                            })
                        );
                    }



}
