<svg
  width="32"
  height="32"
  viewBox="0 0 32 32"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <g clip-path="url(#clip0_68_1070)">
    <path
      d="M12.5 19.51C14.7091 19.51 16.5 18.6146 16.5 17.51C16.5 16.4054 14.7091 15.51 12.5 15.51C10.2909 15.51 8.5 16.4054 8.5 17.51C8.5 18.6146 10.2909 19.51 12.5 19.51Z"
      stroke="black"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8.5 17.51V21.51C8.5 22.62 10.29 23.51 12.5 23.51C14.71 23.51 16.5 22.61 16.5 21.51V17.51"
      stroke="black"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16.5098 19.51C16.5098 20.62 14.7198 21.51 12.5098 21.51C10.2998 21.51 8.50977 20.62 8.50977 19.51"
      stroke="black"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M18.51 18.51H22.51C22.7752 18.51 23.0296 18.4046 23.2171 18.2171C23.4046 18.0296 23.51 17.7752 23.51 17.51V9.50999C23.51 9.24478 23.4046 8.99042 23.2171 8.80289C23.0296 8.61535 22.7752 8.50999 22.51 8.50999H9.5C9.23478 8.50999 8.98043 8.61535 8.79289 8.80289C8.60536 8.99042 8.5 9.24478 8.5 9.50999V14.51"
      stroke="black"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M14 13.51C13.9994 13.176 14.0824 12.8473 14.2415 12.5536C14.4006 12.26 14.6306 12.0109 14.9107 11.829C15.1908 11.6471 15.512 11.5382 15.8449 11.5123C16.1779 11.4864 16.512 11.5443 16.8169 11.6807C17.1217 11.8171 17.3875 12.0277 17.5901 12.2932C17.7926 12.5587 17.9255 12.8707 17.9765 13.2008C18.0275 13.5308 17.9951 13.8683 17.8822 14.1826C17.7692 14.4969 17.5794 14.7779 17.33 15"
      stroke="black"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11 10.75C11.0494 10.75 11.0978 10.7647 11.1389 10.7921C11.18 10.8196 11.212 10.8586 11.231 10.9043C11.2499 10.95 11.2548 11.0003 11.2452 11.0488C11.2356 11.0973 11.2117 11.1418 11.1768 11.1768C11.1418 11.2117 11.0973 11.2356 11.0488 11.2452C11.0003 11.2548 10.95 11.2499 10.9043 11.231C10.8586 11.212 10.8196 11.18 10.7921 11.1389C10.7647 11.0978 10.75 11.0494 10.75 11C10.75 10.9337 10.7763 10.8701 10.8232 10.8232C10.8701 10.7763 10.9337 10.75 11 10.75Z"
      stroke="black"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M21 15.76C21.0663 15.76 21.1299 15.7863 21.1768 15.8332C21.2237 15.8801 21.25 15.9437 21.25 16.01C21.25 16.0763 21.2237 16.1399 21.1768 16.1868C21.1299 16.2337 21.0663 16.26 21 16.26C20.9337 16.26 20.8701 16.2337 20.8232 16.1868C20.7763 16.1399 20.75 16.0763 20.75 16.01C20.75 15.9437 20.7763 15.8801 20.8232 15.8332C20.8701 15.7863 20.9337 15.76 21 15.76"
      stroke="black"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </g>
  <defs>
    <clipPath id="clip0_68_1070">
      <rect width="32" height="32" fill="white" />
    </clipPath>
  </defs>
</svg>
