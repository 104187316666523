/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PurposeOfNewPolicyElement } from './purposeOfNewPolicyElement';
import { PurposeOfNewPolicyElementSchema } from './purposeOfNewPolicyElement';
import * as JoiObj from "joi";


    export const PurposeOfNewPolicyListSchema = JoiObj.object({
        purposeOfNewPolicyList:
                JoiObj.array().items(
        
        JoiObj.link('#PurposeOfNewPolicyElementSchema')

        )
        

.required()


        
    })
                .shared(PurposeOfNewPolicyElementSchema)
.id('PurposeOfNewPolicyListSchema')

export interface PurposeOfNewPolicyList { 
    purposeOfNewPolicyList: Array<PurposeOfNewPolicyElement>;
}

