/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ServiceInfo } from './serviceInfo';
import { ServiceInfoSchema } from './serviceInfo';
import { ResourceModel } from './resourceModel';
import { ResourceModelSchema } from './resourceModel';
import * as JoiObj from "joi";


    export const OptimeringRequestSchema = JoiObj.object({
        serviceInfo:
            
        JoiObj.link('#ServiceInfoSchema')

.allow(null)



        ,
        erSupportrolle:
            
                    JoiObj.boolean()
                


.required()


        ,
        ignorereKunPensionsalderFejl:
            
                    JoiObj.boolean()
                


.required()


        ,
        pensionsalder:
            
                    JoiObj.number()
                


.required()


        ,
        sprog:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        
    })
                .shared(ServiceInfoSchema)
.id('OptimeringRequestSchema')

export interface OptimeringRequest extends ResourceModel { 
    erSupportrolle: boolean;
    ignorereKunPensionsalderFejl: boolean;
    pensionsalder: number;
    sprog: string;
}

