/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { MinFrivilligIndbetaling } from './minFrivilligIndbetaling';
import { MinFrivilligIndbetalingSchema } from './minFrivilligIndbetaling';
import { MinOpsparingsplan } from './minOpsparingsplan';
import { MinOpsparingsplanSchema } from './minOpsparingsplan';
import * as JoiObj from "joi";


    export const MinOpPlanSchema = JoiObj.object({
        fastLaast:
            
                    JoiObj.boolean()
                


.required()


        ,
        maanedsloenEfterSkatNuvaerende:
            
                    JoiObj.number()
                


.required()


        ,
        maanedsloenEfterSkatVedPension:
            
                    JoiObj.number()
                


.required()


        ,
        maanedsloenEfterSkatVedPensionNuvaerende:
            
                    JoiObj.number()
                


.required()


        ,
        minFrivilligIndbetaling:
            
        JoiObj.link('#MinFrivilligIndbetalingSchema')


.required()


        ,
        minOpsparingsplan:
            
        JoiObj.link('#MinOpsparingsplanSchema')


.required()


        ,
        orgMinFrivilligIndbetaling:
            
        JoiObj.link('#MinFrivilligIndbetalingSchema')


.required()


        ,
        pensionstal:
            
                    JoiObj.number()
                .integer()


.required()


        ,
        pensionstalNuvaerende:
            
                    JoiObj.number()
                .integer()


.required()


        ,
        portalSimuleret:
            
                    JoiObj.boolean()
                


.required()


        ,
        selectedAgeEarlierThanEarliestPayoutAge:
            
                    JoiObj.boolean()
                


.required()


        ,
        simuleringFejler:
            
                    JoiObj.boolean()
                


.required()


        
    })
                .shared(MinFrivilligIndbetalingSchema)
                .shared(MinOpsparingsplanSchema)
                .shared(MinFrivilligIndbetalingSchema)
.id('MinOpPlanSchema')

export interface MinOpPlan { 
    fastLaast: boolean;
    maanedsloenEfterSkatNuvaerende: number;
    maanedsloenEfterSkatVedPension: number;
    maanedsloenEfterSkatVedPensionNuvaerende: number;
    minFrivilligIndbetaling: MinFrivilligIndbetaling;
    minOpsparingsplan: MinOpsparingsplan;
    orgMinFrivilligIndbetaling: MinFrivilligIndbetaling;
    pensionstal: number;
    pensionstalNuvaerende: number;
    portalSimuleret: boolean;
    selectedAgeEarlierThanEarliestPayoutAge: boolean;
    simuleringFejler: boolean;
}

