<div [ngSwitch]="daekning.nuvaerende.type">
  <div *ngSwitchCase="'BELOEB'">
    <div>
      {{ daekning.nuvaerende.beloeb | numberFormat }}
      <span [coContent]="'Global.kroner'"></span>
    </div>
    <div
      *ngIf="daekning.nuvaerende.skattekode === 'SK5'"
      class="text--note"
      [coContent]="'DL.FG01.C54'"
    ></div>
  </div>
  <div *ngSwitchCase="'PROCENT'">
    {{ daekning.nuvaerende.procent | numberFormat }} %
  </div>
  <div *ngSwitchCase="'TJEK'"><co-icon-checkmark></co-icon-checkmark></div>
  <div *ngSwitchCase="'KRYDS'"><co-icon-times></co-icon-times></div>
</div>
