import {
  style,
  animate,
  AnimationBuilder,
  AnimationPlayer
} from '@angular/animations';
import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  ViewChild
} from '@angular/core';

@Component({
  selector: 'co-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements AfterViewInit {
  @Input()
  public set observedValue(v: unknown) {
    this._observedValue = v;

    if (this.player) {
      this.player.reset();
      this.player.play();
    }
  }

  public get observedValue(): unknown {
    return this._observedValue;
  }

  @Input()
  public readonly duration = 5000;

  @ViewChild('body')
  public element: ElementRef;

  private player: AnimationPlayer;
  private _observedValue: unknown;

  constructor(private readonly _builder: AnimationBuilder) {}

  ngAfterViewInit(): void {
    const animation = this._builder.build([
      style({
        width: '0'
      }),
      animate(`${this.duration}ms 0ms ease-out`),
      style({
        width: '100%'
      })
    ]);

    this.player = animation.create(this.element.nativeElement);
    this.player.play();
  }
}
