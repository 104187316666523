<table
  class="pfa-table align-left--column-1 align-left--column-2 hidden-mobile"
>
  <caption hidden [coContent]="'DL.MA01.C442'"></caption>
  <thead>
    <tr>
      <th
        [coContent]="'DL.MA01.C08'"
        scope="col"
        class="basket-other-item__cell"
      ></th>
      <th scope="col"></th>
      <th
        [coContent]="'DL.MA01.C09'"
        scope="col"
        class="basket-other-item__cell"
      ></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let item of items; trackBy: trackByFn">
      <ng-container *ngIf="!item.skjulLinie">
        <td>
          <span
            [coContent]="item.nuvaerendeCTekst"
            [coContentSubst]="item.nuvaerendeFletteParameter"
          ></span>
          <div
            *ngIf="
              item.aendringsdatatype === 'TAE_AUTOMATISK_TILPASNING' &&
              locNumberItem
            "
            [coContent]="locNumberItem.nuvaerendeCTekst"
            [coContentSubst]="locNumberItem.nuvaerendeFletteParameter"
          ></div>
        </td>
        <td>
          <co-icon-arrow-right></co-icon-arrow-right>
        </td>
        <td>
          <span
            [coContent]="item.oenskeCTekst"
            [coContentSubst]="item.oenskeFletteParameter"
          ></span>
          <div
            *ngIf="
              item.aendringsdatatype === 'TAE_AUTOMATISK_TILPASNING' &&
              locNumberItem
            "
            [coContent]="locNumberItem.oenskeCTekst"
            [coContentSubst]="locNumberItem.oenskeFletteParameter"
          ></div>
        </td>
      </ng-container>
    </tr>
  </tbody>
</table>

<div class="hidden-desktop">
  <div
    *ngFor="let index = index; let item; of: items; trackBy: trackByFn"
    class="mobile-row"
    [ngClass]="{ 'mobile-row--divider': index < items.length - 1 }"
  >
    <ng-container *ngIf="!item.skjulLinie">
      <div class="text--bold" [coContent]="'DL.MA01.C08'"></div>
      <span
        [coContent]="item.nuvaerendeCTekst"
        [coContentSubst]="item.nuvaerendeFletteParameter"
      ></span>
      <div
        *ngIf="
          item.aendringsdatatype === 'TAE_AUTOMATISK_TILPASNING' &&
          locNumberItem
        "
        [coContent]="locNumberItem.nuvaerendeCTekst"
        [coContentSubst]="locNumberItem.nuvaerendeFletteParameter"
      ></div>
      <div>
        <co-icon-arrow-down></co-icon-arrow-down>
      </div>
      <div class="text--bold" [coContent]="'DL.MA01.C09'"></div>
      <span
        [coContent]="item.oenskeCTekst"
        [coContentSubst]="item.oenskeFletteParameter"
      ></span>
      <div
        *ngIf="
          item.aendringsdatatype === 'TAE_AUTOMATISK_TILPASNING' &&
          locNumberItem
        "
        [coContent]="locNumberItem.oenskeCTekst"
        [coContentSubst]="locNumberItem.oenskeFletteParameter"
      ></div>
    </ng-container>
  </div>
</div>
