<form novalidate [formGroup]="form">
  <co-card-split-horizontal
    [simple]="true"
    [middle]="true"
    [noBottom]="!inputConfig.child"
    class="header-fill-all body-fill-all"
  >
    <div header class="top-section">
      <div class="first">
        <div class="simple-coverage">
          <div class="simple-coverage-top">
            <p class="text--bold" coContent="DL.TF01.C179"></p>
            <div [ngClass]="{ today: inputConfig.self.unit === '%' }">
              <co-primary-number
                [amount]="inputConfig.self.amountOriginal"
                [unit]="inputConfig.self.unit | content"
              ></co-primary-number>
              <div>
                <div
                  [coContent]="inputConfig.self.amountNote2ContentId"
                  class="text--note"
                ></div>
                <div
                  [coContent]="inputConfig.self.amountNote1ContentIdOriginal"
                  *ngIf="inputConfig.self.amountNote1ContentIdOriginal"
                  class="text--note"
                ></div>
                <div
                  [coContent]="inputConfig.self.amountPriceContentIdOriginal"
                  *ngIf="inputConfig.self.amountPriceContentIdOriginal"
                  class="text--bold"
                ></div>
              </div>
            </div>
          </div>
          <co-confirm-choice
            *ngIf="!hideActions"
            [simple]="true"
            [confirmedDate]="confirmChoice?.ci.confirmedDate"
            [error]="confirmChoice.life.error"
            (confirmed)="onConfirmChoice()"
            [success]="confirmChoice?.ci.success"
            [texts]="confirmChoiceComponentTexts"
          >
          </co-confirm-choice>
        </div>
      </div>

      <div>
        <p coContent="DL.TF01.C191" class="text--bold color--secondary"></p>
        <div class="simple-coverage">
          <div class="simple-coverage-top">
            <div
              *ngIf="
                insuranceGuideChecks.guideTooOld || insuranceGuideChecks.noGuide
              "
            >
              <co-icon-with-text>
                <co-icon-doubt icon></co-icon-doubt>
                <div body coContent="DL.FG01.C1466"></div>
              </co-icon-with-text>
            </div>
            <ng-container
              *ngIf="
                !insuranceGuideChecks.guideTooOld &&
                !insuranceGuideChecks.noGuide
              "
            >
              <div
                *ngIf="
                  overrideRecommendationKeepCurrent ||
                  (inputConfig.self.amountOriginal | numberFormat) ===
                    (inputConfig.recommendation | numberFormat)
                "
                coContent="DL.TF01.C197"
                [coContentSubst]="
                  (inputConfig.self.amountOriginal | numberFormat) +
                  '|' +
                  ('Global.kroner' | content)
                "
              ></div>
              <div
                *ngIf="
                  !overrideRecommendationKeepCurrent &&
                  (inputConfig.self.amountOriginal | numberFormat) !==
                    (inputConfig.recommendation | numberFormat)
                "
                coContent="DL.TF01.C198"
                [coContentSubst]="
                  (inputConfig.recommendation | numberFormat) +
                  '|' +
                  ('Global.kroner' | content)
                "
              ></div>
            </ng-container>
          </div>

          <div *ngIf="!pricesUpdated">
            <button
              *ngIf="!hideActions && inputConfig.showRecommendationButton"
              mat-raised-button
              class="button-secondary"
              coContent="DL.TF01.C153"
              (click)="
                setRecommendation(inputConfig.self.type, form.controls.self)
              "
            ></button>
          </div>
          <div *ngIf="pricesUpdated && !inputConfig.self.updateFailed">
            <co-alert class="spacing-unit--over">
              <span
                coContent="DL.TF01.C211"
                *ngIf="!inputConfig.followsRecommendation"
              ></span>
              <span
                coContent="DL.TF01.C210"
                *ngIf="inputConfig.followsRecommendation"
              ></span>
            </co-alert>
          </div>
        </div>
      </div>
      <div>
        <ng-container
          *ngIf="
            !insuranceGuideChecks.guideTooOld && !insuranceGuideChecks.noGuide
          "
        >
          <div class="spacing-unit--half-under">
            <span
              coContent="DL.TF01.C183"
              class="text--bold color--primary"
            ></span>
          </div>
          <div class="new-coverage">
            <div class="new-coverage-top">
              <mat-form-field color="accent">
                <mat-label
                  [coContent]="inputConfig.self.labelContentId"
                ></mat-label>
                <input
                  class="amount-input"
                  matInput
                  coAmount
                  formControlName="self"
                  [coAmountUnit]="inputConfig.self.unit | content"
                  (keyup)="
                    handleZeroCiSelf(
                      inputConfig.self.type,
                      form.controls.self.value
                    )
                  "
                  (blur)="
                    checkForEmpty(
                      form.controls.self,
                      inputConfig.self.amountMin
                    )
                  "
                />
                <mat-hint
                  align="end"
                  [coContent]="inputConfig.self.noteContentId"
                  [coContentSubst]="
                    (inputConfig.self.amountMin | numberFormat) +
                    '|' +
                    (inputConfig.self.amountMax | numberFormat) +
                    '|' +
                    (inputConfig.self.amount2Max | numberFormat)
                  "
                  *ngIf="inputConfig.self.noteContentId"
                  class="note"
                ></mat-hint>
                <mat-error
                  *ngIf="form.controls.self.errors?.numberFormat"
                  [coContent]="'DL.MD01.C13'"
                  align="end"
                ></mat-error>
                <mat-error
                  *ngIf="
                    !form.controls.self.errors?.numberFormat &&
                    form.controls.self.errors?.min
                  "
                  [coContent]="'DL.MD01.C21a'"
                  [coContentSubst]="
                    (inputConfig.self.amountMin | numberFormat) +
                    '|' +
                    (inputConfig.self.unit | content)
                  "
                  align="end"
                ></mat-error>
                <mat-error
                  *ngIf="
                    !form.controls.self.errors?.numberFormat &&
                    form.controls.self.errors?.max
                  "
                  [coContent]="'DL.MD01.C19a'"
                  [coContentSubst]="
                    (inputConfig.self.amountMax | numberFormat) +
                    '|' +
                    (inputConfig.self.unit | content)
                  "
                  align="end"
                ></mat-error>
              </mat-form-field>

              <div *ngIf="pricesUpdated">
                <div
                  [coContent]="inputConfig.self.amountNote1ContentId"
                  *ngIf="inputConfig.self.amountNote1ContentId"
                  class="text--note"
                ></div>
                <div
                  [coContent]="inputConfig.self.amountPriceContentId"
                  *ngIf="inputConfig.self.amountPriceContentId"
                  class="text--bold"
                ></div>
              </div>
            </div>
            <div class="new-coverage-bottom">
              <button
                mat-raised-button
                [disabled]="
                  isDisabled('self') || showNoCiSelfButCiChildError.CI
                "
                (click)="update(inputConfig.self.type, form.controls.self)"
                coContent="DL.TF01.C188"
              ></button>
              <co-warning *ngIf="inputConfig.self.updateFailed">
                <span [coContent]="'DL.TF01.C37'"></span>
              </co-warning>
              <co-warning *ngIf="showNoCiSelfButCiChildError.CI">
                <span [coContent]="'DL.TF01.C36'"></span>
              </co-warning>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <div middle class="middle-section">
      <div
        (click)="expandedDetails = !expandedDetails"
        class="accordion-header"
      >
        <div class="text--bold" coContent="DL.FG02.C324"></div>
        <div>
          <span *ngIf="!expandedDetails; else arrowUp" class="float--right">
            <co-icon-arrow-down
              class="icon-fill__small--grey"
            ></co-icon-arrow-down>
          </span>
          <ng-template #arrowUp>
            <span class="float--right">
              <co-icon-arrow-up
                class="icon-fill__small--grey"
              ></co-icon-arrow-up>
            </span>
          </ng-template>
        </div>
      </div>
      <div *ngIf="expandedDetails" [@openClose]>
        <co-insurance-details-coverages-detail
          [coverages]="coverageDetailsSelf"
          [noHeader]="true"
          [noAccordion]="true"
          [noAccordionShadow]="true"
        >
        </co-insurance-details-coverages-detail>
      </div>
    </div>
    <div body class="bottom-section" *ngIf="inputConfig.child">
      <div (click)="expandedChild = !expandedChild" class="accordion-header">
        <div class="text--bold" coContent="DL.TF01.C199"></div>
        <div>
          <span *ngIf="!expandedChild; else arrowUp" class="float--right">
            <co-icon-arrow-down
              class="icon-fill__small--grey"
            ></co-icon-arrow-down>
          </span>
          <ng-template #arrowUp>
            <span class="float--right">
              <co-icon-arrow-up
                class="icon-fill__small--grey"
              ></co-icon-arrow-up>
            </span>
          </ng-template>
        </div>
      </div>
      <div *ngIf="expandedChild" [@openClose]>
        <div class="row spacing-unit--over">
          <div class="col-12 col-lg-4 col-xl-6">
            <span
              class="color--black text--bold"
              [coContent]="inputConfig.child.textContentId"
            ></span>
            <co-tooltip
              *ngIf="inputConfig.child.tooltipContentId"
              [text]="inputConfig.child.tooltipContentId"
            ></co-tooltip>
            <div
              [coContent]="inputConfig.child.noteContentId"
              [coContentSubst]="
                (inputConfig.child.amountMin | numberFormat) +
                '|' +
                (inputConfig.child.amountMax | numberFormat)
              "
            ></div>
            <co-warning *ngIf="inputConfig.child.updateFailed">
              <span [coContent]="'DL.TF01.C37'"></span>
            </co-warning>
            <co-warning *ngIf="showNoCiSelfButCiChildError.CI_CHILD">
              <span [coContent]="'DL.TF01.C52'"></span>
            </co-warning>
          </div>
          <div class="col-12 col-lg-8 col-xl-6 column-2">
            <button
              mat-raised-button
              class="button-primary--level3 update-button"
              [style.visibility]="
                isDisabled('child') || showNoCiSelfButCiChildError.CI_CHILD
                  ? 'hidden'
                  : 'visible'
              "
              [disabled]="
                isDisabled('child') || showNoCiSelfButCiChildError.CI_CHILD
              "
              (click)="update(inputConfig.child.type, form.controls.child)"
            >
              <span [coContent]="'Global.Opdater'"></span>
              <co-icon-refresh class="icon"></co-icon-refresh>
            </button>

            <mat-form-field color="accent">
              <mat-label
                [coContent]="inputConfig.child.labelContentId"
              ></mat-label>
              <input
                class="amount-input"
                matInput
                coAmount
                formControlName="child"
                [coAmountUnit]="inputConfig.child.unit | content"
                (keyup)="
                  handleZeroCiSelf(
                    inputConfig.child.type,
                    form.controls.child.value
                  )
                "
                (blur)="
                  checkForEmpty(
                    form.controls.child,
                    inputConfig.child.amountMin
                  )
                "
              />
              <mat-hint align="end">
                <p
                  [coContent]="inputConfig.child.amountNote1ContentId"
                  *ngIf="inputConfig.child.amountNote1ContentId"
                  class="note"
                ></p>
                <p
                  [coContent]="inputConfig.child.amountPriceContentIdOriginal"
                  *ngIf="
                    !pricesChildUpdated &&
                    inputConfig.child.amountPriceContentIdOriginal &&
                    inputConfig.child.amountOriginal
                  "
                  class="text--bold"
                ></p>
                <p
                  [coContent]="inputConfig.child.amountPriceContentId"
                  *ngIf="
                    pricesChildUpdated &&
                    inputConfig.child.amountPriceContentId &&
                    inputConfig.child.amount
                  "
                  class="text--bold"
                ></p>
              </mat-hint>
              <mat-error
                *ngIf="form.controls.child.errors?.numberFormat"
                [coContent]="'DL.MD01.C13'"
                align="end"
              ></mat-error>
              <mat-error
                *ngIf="
                  !form.controls.child.errors?.numberFormat &&
                  form.controls.child.errors?.min
                "
                [coContent]="'DL.MD01.C21a'"
                [coContentSubst]="
                  (inputConfig.child.amountMin | numberFormat) +
                  '|' +
                  (inputConfig.child.unit | content)
                "
                align="end"
              ></mat-error>
              <mat-error
                *ngIf="
                  !form.controls.child.errors?.numberFormat &&
                  form.controls.child.errors?.max
                "
                [coContent]="'DL.MD01.C19a'"
                [coContentSubst]="
                  (inputConfig.child.amountMax | numberFormat) +
                  '|' +
                  (inputConfig.child.unit | content)
                "
                align="end"
              ></mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
  </co-card-split-horizontal>
</form>
