<co-icon-times
  id="closeviewer"
  class="close"
  (click)="closeViewer()"
></co-icon-times>

<div class="wrapper">
  <mitpfa-pension-check-your-information
    *ngIf="showInformationCard"
    [selectedCardId]="selectedCardId"
    [selectedCardIdList]="selectedCardIdList"
    (scrollToTop)="scrollToTop()"
    (complete)="closeViewer()"
  >
  </mitpfa-pension-check-your-information>
</div>
