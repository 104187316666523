import { TrackingService } from './tracking.service';
import * as moment from 'moment';
import * as forge from 'node-forge';
import { ExtendedWindow } from '../models/extended-window';
import { Injectable, inject } from '@angular/core';
import { ScriptLoaderService } from '../script-loader/script-loader.service';
import { filter, switchMap } from 'rxjs/operators';
import {
  PensionsKundeGenerelleData,
  ErrorPageInfo,
  PensionskundeStore
} from '@pfa/gen';

export type PlatformType = 'native-app' | 'web-app';

@Injectable({
  providedIn: 'root'
})
export class PageTrackingService extends TrackingService {
  private readonly scriptLoaderService: ScriptLoaderService =
    inject(ScriptLoaderService);
  private readonly pensionskundeStore: PensionskundeStore =
    inject(PensionskundeStore);

  private readonly trackPageViewFunction = (
    pageId: string,
    customer: PensionsKundeGenerelleData
  ): void => {
    if (this.updateDataLayerPage(pageId, '', customer)) {
      this.dispatchCustomEventTracking('track-pageview', {});
    }
  };

  public trackErrorPage(pageId: string, errorPageInfo: ErrorPageInfo): void {
    const trackPage = this.trackErrorPageHelper(errorPageInfo);
    this.trackPageHelper(pageId, trackPage);
  }

  public trackErrorPageAnonymous(
    pageId: string,
    errorPageInfo: ErrorPageInfo
  ): void {
    const trackPage = this.trackErrorPageHelper(errorPageInfo);
    this.trackPageAnonymousHelper(pageId, trackPage);
  }

  public trackContentLoaded(): void {
    this.dispatchCustomEventTracking('track-content-loaded', {});
  }

  private trackErrorPageHelper(
    errorPageInfo: ErrorPageInfo
  ): (pageId: string, customer: PensionsKundeGenerelleData) => void {
    if (errorPageInfo) {
      // Backend or frontend error
      return (pageId: string, customer: PensionsKundeGenerelleData) => {
        if (this.updateDataLayerPage(pageId, '', customer)) {
          this.dispatchCustomEventTracking('track-error', errorPageInfo);
        }
      };
    } else {
      // Direct navigation to error page
      return (pageId: string, customer: PensionsKundeGenerelleData): void => {
        if (this.updateDataLayerPage(pageId, '', customer)) {
          this.dispatchCustomEventTracking('track-user-error', {});
        }
      };
    }
  }

  public trackPage(pageId: string): void {
    this.trackPageHelper(pageId, this.trackPageViewFunction);
  }

  private trackPageHelper(
    pageId: string,
    trackPage: (pageId: string, customer: PensionsKundeGenerelleData) => void
  ): void {
    this.scriptLoaderService
      .loadTrackingScriptEnvironmentSpecific()
      .pipe(
        filter(scriptResult => scriptResult.loaded),
        switchMap(() => this.pensionskundeStore.pensionskundeGet())
      )
      .subscribe({
        next: customer => trackPage(pageId, customer),
        error: () => {}
      });
  }

  /**
   * Page tracking before login
   */
  public trackPageAnonymous(pageId: string): void {
    this.trackPageAnonymousHelper(pageId, this.trackPageViewFunction);
  }

  private trackPageAnonymousHelper(
    pageId: string,
    trackPage: (
      pageId: string,
      customer: PensionsKundeGenerelleData | undefined
    ) => void
  ): void {
    this.scriptLoaderService
      .loadTrackingScriptEnvironmentSpecific()
      .pipe(filter(scriptResult => scriptResult.loaded))
      .subscribe({
        next: scriptResult => trackPage(pageId, undefined),
        error: () => {}
      });
  }
  public trackNavigation(
    navigationName,
    navigationAction,
    navigationDestination
  ): void {
    // navigationName = Main Navigation (or something like that)
    // navigationAction = Forsikring > Anmeld skade (or something like that)
    // navigationDestination = url (destination url - leave empty if unknown)
    const event = new CustomEvent('track-navigation', {
      detail: {
        navigationName,
        navigationAction,
        navigationDestination
      }
    });
    document.getElementById('mitpfa')?.dispatchEvent(event);
    document.getElementById('mitpfaLight')?.dispatchEvent(event);
  }

  updateDataLayerPage(
    pageId: string,
    customPageUrl: string,
    customer: PensionsKundeGenerelleData
  ): boolean {
    if (!this.scriptLoaderService.canLoadTrackingScript()) {
      return false;
    } else {
      const extendedWindow = window as unknown as ExtendedWindow;
      extendedWindow.digitalData.customPageURL = customPageUrl;
      if (pageId) {
        extendedWindow.digitalData.previousPageId =
          extendedWindow.digitalData.pageId;
        extendedWindow.digitalData.pageId = pageId;
      }
      if (customer) {
        if (customer.isRaadgiver) {
          extendedWindow.digitalData.advisorId = this.forge_sha256(
            `${
              customer.raadgiverRekvirentNummer
            }${customer.raadgiverBrugerId?.toLowerCase()}`
          );
        }
        extendedWindow.digitalData.age = customer.alder;
        extendedWindow.digitalData.consentsGiven =
          this.generateCustomerConsentsGiven(customer);
        extendedWindow.digitalData.gender = customer.koen;
        extendedWindow.digitalData.geographicalRegion =
          this.generateCustomerGeographicalRegion(customer);
        extendedWindow.digitalData.languageCode = customer.sprogValg;
        extendedWindow.digitalData.lastLogin = moment(
          customer.sidsteBesoeg,
          'YYYY-MM-DDTHH:mm:ss.sssZ'
        ); // iso8601
        extendedWindow.digitalData.loginMethod = customer.loginMethod;
        extendedWindow.digitalData.segment = customer.serviceProgram;
        extendedWindow.digitalData.customerId = this.forge_sha256(
          customer.cpr + customer.globalId
        ); // anonymous customerId for adobe analytics
        extendedWindow.digitalData.livnetPolicyCount = this.replaceZero(
          customer.livnetPolicyCount
        );
        extendedWindow.digitalData.platform = this.generatePlatform();
      }
      return true;
    }
  }

  private generateCustomerGeographicalRegion(
    customer: PensionsKundeGenerelleData
  ): string {
    if (customer.harUdenlandskAdresse) {
      return 'Udenlandsk adresse';
    } else if (customer.postNr) {
      const customerZipCode = parseInt(customer.postNr, 10);
      if (customerZipCode >= 1000 && customerZipCode <= 3699) {
        return 'København og Nordsjælland';
      } else if (customerZipCode >= 3700 && customerZipCode <= 4999) {
        return 'Sjælland og øerne';
      } else if (customerZipCode >= 5000 && customerZipCode <= 5999) {
        return 'Fyn og øerne';
      } else if (customerZipCode >= 6000 && customerZipCode <= 9999) {
        return 'Jylland';
      } else {
        return 'Øvrige';
      }
    } else {
      return 'Postnummer mangler';
    }
  }

  private generateCustomerConsentsGiven(
    customer: PensionsKundeGenerelleData
  ): string {
    let consentGivenList = '';
    if (customer.consents) {
      customer.consents.forEach(function (consent) {
        if (consent.state && consent.state === 'GIVEN') {
          if (consentGivenList === '') {
            consentGivenList = consent.type;
          } else {
            consentGivenList = consentGivenList
              .concat(';')
              .concat(consent.type);
          }
        }
      });
    }
    return consentGivenList;
  }

  // Adobe analytics cannot handle '0' in eVar
  private replaceZero(value: number): number | string {
    if (value === 0) {
      return 'zero';
    }
    return value;
  }

  private forge_sha256(value: string) {
    const md = forge.md.sha256.create();
    md.update(value);
    return md.digest().toHex();
  }

  private generatePlatform(): PlatformType {
    const ua = navigator.userAgent;
    if (
      (ua.indexOf('iPhone') !== -1 || ua.indexOf('iPad') !== -1) &&
      ua.indexOf('Safari') === -1
    ) {
      return 'native-app';
    }
    if (ua.indexOf('Android') !== -1 && ua.indexOf('; wv') !== -1) {
      return 'native-app';
    }
    return 'web-app';
  }
}
