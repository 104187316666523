export class Utils {
  static paletteColors =
    '#990033,#333333,#5C5C5C,#B0B0B0,#E2E2E2,#ECECEC,#424242,#999999,#CCCCCC,#E6E6E6,#EFEFEF';

  static noPropagate = function (event: MouseEvent): void {
    if (event.stopPropagation) {
      event.stopPropagation();
    } else if (window?.event) {
      window.event.cancelBubble = true;
    }
  };

  static isNullOrUndefined(value): boolean {
    return value === null || value === undefined;
  }

  static isEmpty(value): boolean {
    return Utils.isNullOrUndefined(value) || value.length === 0;
  }

  static isEmptyOrZero = function (value) {
    return Utils.isEmpty(value) || value === 0 || value === '0';
  };

  static formatNumber(value?: number, decimals: number = 0): string {
    if (this.isEmpty(value)) {
      return '';
    }

    let formattedValue = value?.toFixed(decimals);

    // switch decimal signs (javascript decimal sign is a dot - we want a comma
    formattedValue = formattedValue?.replace('.', ',');

    // handle thousand seperator
    if (
      formattedValue !== null &&
      formattedValue !== undefined &&
      formattedValue !== ''
    ) {
      const parts = formattedValue.split(','); // avoids thousand seperator in decimals
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
      formattedValue = parts.join(',');
    } else {
      formattedValue = '';
    }
    return formattedValue;
  }

  static sumAllObjectValues(obj: { [key: string]: number | string }): number {
    return (
      Object.values(obj)
        .map(item =>
          typeof item !== 'string'
            ? (item as number)
            : item
              ? parseFloat(item as string) || 0
              : 0
        )
        .reduce((a, b) => a + b) || 0
    );
  }

  //E.g.: value = 456,78 and scale = 100 will return 400
  static roundDownToNearestScaleAndFormatNumber(
    value: number,
    scale: number,
    decimals: number = 0
  ): string {
    if (this.isEmpty(value)) {
      return '';
    }

    const valueRoundedDown = Math.trunc(value / scale) * scale;

    return this.formatNumber(valueRoundedDown, decimals);
  }

  //E.g.: value = 405,21 and scale = 100 will return 500
  static roundUpToNearestScaleAndFormatNumber(
    value: number,
    scale: number,
    decimals: number = 0
  ): string {
    if (this.isEmpty(value)) {
      return '';
    }

    const valueRoundedUp = Math.ceil(value / scale) * scale;

    return this.formatNumber(valueRoundedUp, decimals);
  }

  //E.g.: value = 616,57 and decimals = 1 will return 616,5
  //E.g.: value = 616,57, decimals = 1 and scaleFactor = 100 will return 6,1
  static roundDownToNumberOfDecimalsAndFormatNumber(
    value: number,
    decimals: number,
    scaleFactor: number = 1
  ): string {
    if (this.isEmpty(value)) {
      return '';
    }

    const valueRoundedDown =
      Math.trunc((value / scaleFactor) * Math.pow(10, decimals)) /
      Math.pow(10, decimals);

    return this.formatNumber(valueRoundedDown, decimals);
  }

  static scrollTo(
    target: HTMLElement | undefined,
    duration: number,
    delay = 0,
    offset = 0
  ): void {
    if (!target) {
      return;
    }
    const framerate = 120;
    const intervalDuration = 1000 / framerate;
    let targetScrollPosition;

    setTimeout(() => {
      const startScrollPosition = window.scrollY;
      targetScrollPosition =
        startScrollPosition + target.getBoundingClientRect().top + offset;
      let time = 0;
      const interval = setInterval(() => {
        time += intervalDuration;
        const newScrollPosition = this.lerp(
          startScrollPosition,
          targetScrollPosition,
          time / duration
        );
        window.scroll(0, newScrollPosition);
        if (time >= duration) {
          clearInterval(interval);
        }
      }, intervalDuration);
    }, delay);
  }

  private static lerp(value1, value2, amount) {
    amount = amount < 0 ? 0 : amount;
    amount = amount > 1 ? 1 : amount;
    return value1 + (value2 - value1) * amount;
  }

  static jsonDeepClone(obj: any): any | null {
    if (obj === null) {
      return null;
    }
    if (Array.isArray(obj)) {
      return obj.map(value => this.jsonDeepClone(value));
    }
    if (typeof obj === 'object') {
      const result = {} as any;
      Object.keys(obj).forEach(key => {
        result[key] = this.jsonDeepClone(obj[key]);
      });
      return result;
    }
    return obj;
  }
}
