/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Sats } from './sats';
import { SatsSchema } from './sats';
import * as JoiObj from "joi";


    export const SkatStatSatserSchema = JoiObj.object({
        activeNow:
            
                    JoiObj.boolean()
                


.required()


        ,
        name:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        updatedAt:
            
            JoiObj.date()


.required()


        ,
        updatedBy:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        uuid:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        validFrom:
            
            JoiObj.date()

.allow(null)



        ,
        validFromFormatted:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        year:
            
                    JoiObj.number()
                


.required()


        ,
        aldersopsparingindbetalingMax:
            
                    JoiObj.number()
                


.required()


        ,
        aldersopsparingindbetalingMax5AarIndenFP:
            
                    JoiObj.number()
                


.required()


        ,
        arbejdmarkedbidragPct:
            
                    JoiObj.number()
                


.required()


        ,
        beskaeftigelsesfradragMax:
            
                    JoiObj.number()
                


.required()


        ,
        beskaeftigelsesfradragPct:
            
                    JoiObj.number()
                


.required()


        ,
        bundskatPct:
            
                    JoiObj.number()
                


.required()


        ,
        ekstraPensionsIndbetalingFradrag:
            
                    JoiObj.number()
                


.required()


        ,
        gaeldendeAar:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        gennemsnitkirkeskatPct:
            
                    JoiObj.number()
                


.required()


        ,
        gennemsnitkommuneskatPct:
            
                    JoiObj.number()
                


.required()


        ,
        kapitalpensionindbetalingMax:
            
                    JoiObj.number()
                


.required()


        ,
        kapitalpensionsafgiftPct:
            
                    JoiObj.number()
                


.required()


        ,
        personfradragOver18:
            
                    JoiObj.number()
                


.required()


        ,
        personfradragUnder18:
            
                    JoiObj.number()
                


.required()


        ,
        ratepensionindbetalingMax:
            
                    JoiObj.number()
                


.required()


        ,
        saerligpensionPct:
            
                    JoiObj.number()
                


.required()


        ,
        skatteloftPct:
            
                    JoiObj.number()
                


.required()


        ,
        sundhedsbidragPct:
            
                    JoiObj.number()
                


.required()


        ,
        topskatGraense:
            
                    JoiObj.number()
                


.required()


        ,
        topskatPct:
            
                    JoiObj.number()
                


.required()


        ,
        validFra:
            
                    JoiObj.object()
                

.allow(null)



        
    })
.id('SkatStatSatserSchema')

export interface SkatStatSatser extends Sats { 
    aldersopsparingindbetalingMax: number;
    aldersopsparingindbetalingMax5AarIndenFP: number;
    arbejdmarkedbidragPct: number;
    beskaeftigelsesfradragMax: number;
    beskaeftigelsesfradragPct: number;
    bundskatPct: number;
    ekstraPensionsIndbetalingFradrag: number;
    gaeldendeAar?: string;
    gennemsnitkirkeskatPct: number;
    gennemsnitkommuneskatPct: number;
    kapitalpensionindbetalingMax: number;
    kapitalpensionsafgiftPct: number;
    personfradragOver18: number;
    personfradragUnder18: number;
    ratepensionindbetalingMax: number;
    saerligpensionPct: number;
    skatteloftPct: number;
    sundhedsbidragPct: number;
    topskatGraense: number;
    topskatPct: number;
    validFra?: object;
}

