import { Injectable } from '@angular/core';
import {
  InvesteringPolice,
  InvestmentConcept,
  InvestmentRecommendation,
  Kilde
} from '@pfa/gen';
import {
  CurrentRecommendationProfile,
  NewRecommendationProfile,
  RecommendationDetail,
  RecommendationProfile
} from './investment-recommendation.model';

const pfaInvestTextId = 'DL.INV.C676';

@Injectable()
export class InvestmentRecommendationService {
  public buildDetail(
    policy: InvesteringPolice,
    recommendation: InvestmentRecommendation
  ): RecommendationDetail {
    const currentDetail = this.buildCurrent(policy);
    const recommendDetail = this.buildRecommend(policy, recommendation);
    const undboundDetail = this.buildUnbound(policy);

    let detail: RecommendationDetail = {
      policyId: policy.id,
      current: currentDetail,
      recommendation: recommendDetail
    };

    if (undboundDetail) {
      detail = { ...detail, unboundSource: undboundDetail };
    }

    return detail;
  }

  private buildCurrent(
    policy: InvesteringPolice
  ): CurrentRecommendationProfile {
    const current = {
      allocation: {}
    } as CurrentRecommendationProfile;

    current.profile = policy.pfaInvesterer
      ? policy.pfaInvesterer.profiler.find(p => p.valgt)
      : undefined;
    current.profileText = current.profile
      ? this.getProfileText(current.profile.id)
      : '';
    current.climatePercent = policy.klimaPlusAndel;
    current.lowRisk = policy.pfaInvesterer
      ? policy.pfaInvesterer.lavFaktiskTotalFordelingProcent
      : 0;
    current.highRisk = policy.pfaInvesterer
      ? policy.pfaInvesterer.hoejFaktiskTotalFordelingProcent
      : 0;
    current.hasPfaInvest = policy.pfaInvesterer
      ? policy.pfaInvesterer.harPfaInvesterer
      : false;
    current.hasPfaOptional = policy.pfaValgfri
      ? policy.pfaValgfri.harPfaValgfri
      : false;
    current.hasYouInvest = policy.duInvesterer
      ? policy.duInvesterer.harDuInvesterer
      : false;
    current.onlyYouInvest =
      policy.duInvesterer?.harDuInvesterer &&
      !policy.pfaInvesterer?.harPfaInvesterer &&
      !policy.pfaValgfri?.harPfaValgfri;

    if (policy.duInvesterer?.fordeling) {
      current.allocation.bonds = policy.duInvesterer.fordeling.aktier;
      current.allocation.shares = policy.duInvesterer.fordeling.obligationer;
      current.allocation.mixed = policy.duInvesterer.fordeling.oevrige;
    } else {
      current.allocation.bonds = 0;
      current.allocation.shares = 0;
      current.allocation.mixed = 0;
    }

    if (policy.duInvesterer?.simpelOpsparing) {
      current.youInvestPercent =
        Math.round(policy.duInvesterer.simpelOpsparing.duInvesterer * 100) /
        100;
      current.pfaInvestPercent =
        Math.round(policy.duInvesterer.simpelOpsparing.pfaInvesterer * 100) /
        100;

      if (policy.duInvesterer.simpelOpsparing.duInvesterer === 100) {
        current.onlyYouInvest = true;
        current.hasPfaInvest = false;
        current.hasPfaOptional = false;
      }
    } else {
      current.youInvestPercent = 0;
    }

    this.configCurrentTexts(current);

    return current;
  }

  private buildRecommend(
    policy: InvesteringPolice,
    recommendation: InvestmentRecommendation
  ): NewRecommendationProfile {
    const recommend = {
      allocation: {}
    } as NewRecommendationProfile;
    recommend.profile = policy.pfaInvesterer
      ? policy.pfaInvesterer.profiler.find(
          p => p.id === recommendation.investmentProfile
        )
      : undefined;

    recommend.profileText = this.getProfileText(
      recommendation.investmentProfile
    );
    recommend.riskText = this.getRiskText(recommendation.investmentProfile);
    recommend.climatePercent = recommendation.recommendedClimatePercent;
    recommend.lowRisk = recommend.profile
      ? recommend.profile.fordeling.obligationer.procent
      : 0;
    recommend.highRisk = recommend.profile
      ? recommend.profile.fordeling.aktier.procent
      : 0;
    recommend.conceptName = this.getConceptName(
      recommendation.calculatedInvestmentConcept
    );
    recommend.recommendYouInvest =
      recommendation.calculatedInvestmentConcept ===
      InvestmentConcept.YouInvest;
    recommend.allocation.bonds = policy.anbefalingAktier;
    recommend.allocation.shares = policy.anbefalingObligationer;
    recommend.allocation.mixed = 0;
    recommend.isPfaInvestSelectable = policy.pfaInvesterer
      ? policy.pfaInvesterer.muligt
      : false;
    recommend.isOptionalSelectable = policy.pfaValgfri
      ? policy.pfaValgfri.muligt
      : false;
    recommend.isYouInvestSelectable = policy.duInvesterer
      ? policy.duInvesterer.muligt
      : false;

    if (recommend.recommendYouInvest) {
      recommend.profile = undefined;
      recommend.adobeTrackingStepName = 'youinvest';
      recommend.adobeConceptTrackingValue = `youinvest`;
    } else {
      recommend.adobeTrackingStepName = 'pfainvest';
      recommend.adobeConceptTrackingValue = `pfainvest: profile ${recommend.profile?.id}`;
    }

    return recommend;
  }

  private buildUnbound(
    policy: InvesteringPolice
  ): RecommendationProfile | undefined {
    const unboundSource = policy.pfaInvesterer
      ? policy.pfaInvesterer.profiler.find(p => p.kilde === Kilde.Frie)
      : undefined;

    if (unboundSource) {
      return {
        profile: unboundSource,
        profileText: this.getProfileText(unboundSource.id),
        riskText: this.getRiskText(unboundSource.id)
      } as RecommendationProfile;
    } else {
      return undefined;
    }
  }

  private getConceptName(concept: InvestmentConcept): string {
    const concepts: { [key in InvestmentConcept]: string } = {
      YOU_INVEST: 'DL.INV.C681',
      PFA_INVEST: pfaInvestTextId
    };

    return concepts[concept];
  }

  private getProfileText(profile: string): string {
    return (
      {
        A: 'DL.INV.C685',
        B: 'DL.INV.C686',
        C: 'DL.INV.C687',
        D: 'DL.INV.C688',
        G: 'DL.INV.C689'
      }[profile] ?? ''
    );
  }

  private getRiskText(profile: string): string {
    return (
      {
        A: 'DL.INVGU01.C662',
        B: 'DL.INVGU01.C663',
        C: 'DL.INVGU01.C664',
        D: 'DL.INVGU01.C665',
        G: 'DL.INVGU01.C663'
      }[profile] ?? ''
    );
  }

  private configCurrentTexts(current: CurrentRecommendationProfile): void {
    if (current.hasPfaInvest && !current.hasYouInvest) {
      current.conceptName = pfaInvestTextId;
      current.riskText = current.profile
        ? this.getRiskText(current.profile.id)
        : '';
      current.adobeTrackingStepName = 'pfainvest';
      current.adobeConceptTrackingValue = `pfainvest: profile ${current.profile.id}`;
    } else if (current.hasPfaInvest && current.hasYouInvest) {
      current.conceptName = 'DL.INV.C679';
      current.riskText = 'DL.INV.C668';
      current.adobeTrackingStepName = 'pfainvest/youinvest';
      current.adobeConceptTrackingValue = 'pfainvest/youinvest';
    } else if (current.hasPfaOptional && !current.hasYouInvest) {
      current.conceptName = 'DL.INV.C678';
      current.riskText = 'DL.INV.C672';
      current.adobeTrackingStepName = 'pfaoptional';
      current.adobeConceptTrackingValue = 'pfaoptional';
    } else if (current.hasPfaOptional && current.hasYouInvest) {
      current.conceptName = 'DL.INV.C680';
      current.riskText = 'DL.INV.C684';
      current.adobeTrackingStepName = 'youinvest/pfaoptional';
      current.adobeConceptTrackingValue = 'youinvest/pfaoptional';
    } else if (current.onlyYouInvest) {
      current.profile = undefined;
      current.conceptName = 'DL.INV.C677';
      current.riskText = 'DL.INV.C666';
      current.adobeTrackingStepName = 'youinvest';
      current.adobeConceptTrackingValue = 'youinvest';
    }
  }
}
