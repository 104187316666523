import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {
  OekonomiDetaljer,
  PensionsInfoOrdninger,
  ForsikringDetaljer,
  StamdataDetaljer,
  RaadgiverAnbefaling
} from '@pfa/gen';
import { InsuranceGuideRecommendationCardComponent } from '../card/insurance-guide-recommendation-card.component';
import {
  RECOMMENDATION_DIRECTION,
  RECOMMENDATION_TYPE,
  RecommendationDescription
} from '../insurance-guide.recommendation.model';
import { InsuranceUtilService } from '@pfaform';
import { InsuranceGuideConfigurationService } from '../../insurance-guide-configuration.service';
import { InsuranceGuideService } from '../../insurance-guide.service';
import { ConfirmChoice } from '@mitpfa/shared';

@Component({
  selector: 'mitpfa-insurance-guide-recommendation-life',
  templateUrl: './insurance-guide-recommendation-life.component.html',
  styleUrls: ['./insurance-guide-recommendation-life.component.scss']
})
export class InsuranceGuideRecommendationLifeComponent
  implements OnInit, OnChanges
{
  @Input() pensionInfo: PensionsInfoOrdninger;
  @Input() financialSituation: OekonomiDetaljer;
  @Input() insuranceDetails: ForsikringDetaljer;
  @Input() familyInformation: StamdataDetaljer;
  @Input() salary: number;
  @Input() advisorData: RaadgiverAnbefaling;
  @Input() recommendationHeaderTooltip: string;
  @Input() showFollowOurRecommendationButton: boolean;
  @Input() confirmChoice: ConfirmChoice;
  @Input() hideActions: boolean;

  @Output() confirmChoiceEmitter: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild(InsuranceGuideRecommendationCardComponent)
  recommendationCard: InsuranceGuideRecommendationCardComponent;

  private readonly insuranceUtilService = inject(InsuranceUtilService);
  private readonly insuranceGuideConfigurationService = inject(
    InsuranceGuideConfigurationService
  );
  private readonly insuranceGuideService = inject(InsuranceGuideService);

  overrideRecommendationKeepCurrent: boolean;

  public recommendedMultiplier: number;
  public recommendedSalary: number;
  public currentMultiplier: number;
  public totalFreeFunds = 0;
  public hasPartner: boolean;
  public recommendation: RECOMMENDATION_DIRECTION;
  public recommendationDescription: RecommendationDescription | undefined;
  isDisabled: boolean;
  showChildPensionRecommendationText: boolean;

  ngOnInit(): void {
    this.showChildPensionRecommendationText =
      this.insuranceDetails.originalTilpasForsikringstal.anbefalOpretBoernepension;

    this.insuranceGuideService.trackRecommendation([
      {
        name: this.insuranceGuideService.getRecommendationTrackingTextLife(
          this.pensionInfo
        ),
        index: 2
      }
    ]);
  }

  ngOnChanges(): void {
    this.pensionInfo.forsikring.livsTal = this.pensionInfo.forsikring.livsTal
      ? this.pensionInfo.forsikring.livsTal
      : 0;

    this.recommendedMultiplier =
      this.insuranceUtilService.getRecommendedMultiplier(this.insuranceDetails);
    this.recommendedSalary = this.recommendedMultiplier * this.salary;

    this.currentMultiplier = this.insuranceUtilService.getCurrentMultiplier(
      this.pensionInfo
    );

    this.totalFreeFunds =
      this.financialSituation.frieMidler.aktierSamletVaerdi +
      this.financialSituation.frieMidler.obligationerSamletVaerdi +
      this.financialSituation.frieMidler.kontanterSamletVaerdi;

    this.hasPartner =
      this.familyInformation.samlevende ||
      this.familyInformation.civilstand === 'Gift';

    this.recommendation = this.insuranceGuideService.getRecommendationLife(
      this.pensionInfo
    );
    this.recommendationDescription =
      InsuranceGuideService.getRecommendationDescription(
        this.recommendation,
        RECOMMENDATION_TYPE.LIFE,
        undefined
      );

    this.isDisabled =
      this.insuranceGuideConfigurationService.getInsuranceGuideDisabled(
        this.insuranceDetails
      ).life;

    this.setShouldKeepRecommendation();
  }

  hasAdvisorRecommendation(): boolean {
    return this.advisorData?.raadgiverAnbefaling > 0;
  }

  private setShouldKeepRecommendation(): void {
    this.overrideRecommendationKeepCurrent =
      InsuranceGuideService.calculateShouldKeepRecommendation(
        this.pensionInfo.forsikring.livsTal,
        this.pensionInfo.forsikring.pfaAnbefalerLivs.procent,
        InsuranceUtilService.LIFE_VARIATION
      );
  }

  onConfirmChoice() {
    this.confirmChoiceEmitter.emit();
  }
}
