import { Component, Input, OnInit } from '@angular/core';
import { TilbudOverblik, ViderefoerelseSammenligning } from '@pfa/gen';

@Component({
  selector: 'co-continuation-comparison',
  templateUrl: './continuation-comparison.component.html',
  styleUrls: ['../new-business-offer-overview.component.scss']
})
export class ContinuationComparisonComponent implements OnInit {
  @Input() overview: TilbudOverblik;
  comparison: ViderefoerelseSammenligning;

  ngOnInit(): void {
    this.comparison = this.overview.viderefoerelseSammenligning;
  }
}
