import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { BasketApiService } from '@pfa/api';
import { combineLatest, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ContentService, OrderItem } from '@pfa/core';
import {
  GodkendSvar,
  MineAendringer,
  PensionsKundeGenerelleData,
  PensionskundeStore,
  SignatureIframeResponse
} from '@pfa/gen';
import { GlobalWorkingService } from '@pfaform';
import { SigningService } from '@mitpfa/shared';

@Component({
  selector: 'mitpfa-insurance-adjustment-health-information',
  templateUrl: './insurance-adjustment-health-information.component.html',
  styleUrls: ['./insurance-adjustment-health-information.component.scss']
})
export class InsuranceAdjustmentHealthInformationComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @Input() orderItems: OrderItem[];
  @Input() signingFunction: () => Observable<SignatureIframeResponse>;
  @Output() back = new EventEmitter();
  @Output() signingCompleted = new EventEmitter<GodkendSvar>();
  healthInformationInitialized: boolean;
  accumulatedChanges: MineAendringer;
  pensionCustomer: PensionsKundeGenerelleData;
  errorUpdating: boolean;

  private readonly unsubscribe = new Subject<void>();

  constructor(
    private readonly pensionskundeStore: PensionskundeStore,
    private readonly basketApiService: BasketApiService,
    private readonly contentService: ContentService,
    private readonly globalWorkingService: GlobalWorkingService,
    private readonly signingService: SigningService
  ) {}

  ngAfterViewInit(): void {
    window.scroll(0, 0);
  }

  ngOnInit(): void {
    combineLatest({
      pensionCustomer: this.pensionskundeStore.pensionskundeGet(),
      accumulatedChanges: this.basketApiService.accumulatedChanges(
        this.contentService.getLanguage().toUpperCase()
      )
    })
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(responses => {
        this.pensionCustomer = responses.pensionCustomer;
        this.accumulatedChanges = responses.accumulatedChanges;
        this.healthInformationInitialized = true;
      });
  }

  cancel(): void {
    this.back.emit();
  }

  accept(): void {
    this.globalWorkingService.show();
    this.errorUpdating = false;
    this.basketApiService
      .buildContract(this.contentService.getLanguage(), 'FORSIKRING')
      .subscribe(resp => {
        if (resp.fejl) {
          this.errorUpdating = true;
          this.globalWorkingService.hide();
        } else {
          this.signingService
            .showSigningPopup(this.signingFunction, this.orderItems)
            .subscribe({
              next: signingPopupResponse => {
                this.signingService
                  .handleSigningResponse(
                    signingPopupResponse,
                    this.orderItems,
                    'FORSIKRING'
                  )
                  .subscribe({
                    next: godkendSvar => {
                      this.onSigningCompleted(godkendSvar);
                      this.globalWorkingService.hide();
                    },
                    error: () => {
                      this.signingService.showSigningError();
                    }
                  });
              },
              error: () => {
                this.signingService.showSigningError();
                this.globalWorkingService.hide();
              }
            });
        }
      });
  }

  onSigningCompleted(godkendSvar: GodkendSvar): void {
    this.signingCompleted.emit(godkendSvar);
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
