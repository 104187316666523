/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ForsikringDaekningPris } from './forsikringDaekningPris';
import { ForsikringDaekningPrisSchema } from './forsikringDaekningPris';
import { GennemsnitsrenteOmkostning } from './gennemsnitsrenteOmkostning';
import { GennemsnitsrenteOmkostningSchema } from './gennemsnitsrenteOmkostning';
import { InvesteringOmkostning } from './investeringOmkostning';
import { InvesteringOmkostningSchema } from './investeringOmkostning';
import { VederlagOmkostning } from './vederlagOmkostning';
import { VederlagOmkostningSchema } from './vederlagOmkostning';
import { PensionDaekningPris } from './pensionDaekningPris';
import { PensionDaekningPrisSchema } from './pensionDaekningPris';
import { TransaktionOmkostning } from './transaktionOmkostning';
import { TransaktionOmkostningSchema } from './transaktionOmkostning';
import { HelbredssikringDaekningPris } from './helbredssikringDaekningPris';
import { HelbredssikringDaekningPrisSchema } from './helbredssikringDaekningPris';
import { PrisGruppe } from './prisGruppe';
import { PrisGruppeSchema } from './prisGruppe';
import * as JoiObj from "joi";


    export const PrislisteSchema = JoiObj.object({
        fejlet:
            
                    JoiObj.boolean()
                


.required()


        ,
        fripolice:
            
                    JoiObj.boolean()
                


.required()


        ,
        gennemsnitsrenteOmkostningListe:
                JoiObj.array().items(
        
        JoiObj.link('#GennemsnitsrenteOmkostningSchema')

        )
        

.required()


        ,
        grundPakkePris:
            
        JoiObj.link('#PrisGruppeSchema')


.required()


        ,
        gyldigDato:
            
            JoiObj.date()


.required()


        ,
        helbredssikringDaekningPris:
            
        JoiObj.link('#HelbredssikringDaekningPrisSchema')

.allow(null)



        ,
        investeringOmkostning:
            
        JoiObj.link('#InvesteringOmkostningSchema')

.allow(null)



        ,
        samletPakkePris:
            
        JoiObj.link('#PrisGruppeSchema')

.allow(null)



        ,
        tilvalgPakkePrisListe:
                JoiObj.array().items(
        
        JoiObj.link('#PrisGruppeSchema')

        )
        

.required()


        ,
        transaktionOmkostningListe:
                JoiObj.array().items(
        
        JoiObj.link('#TransaktionOmkostningSchema')

        )
        

.required()


        ,
        valgtForsikringDaekningListe:
                JoiObj.array().items(
        
        JoiObj.link('#ForsikringDaekningPrisSchema')

        )
        
.allow(null)



        ,
        valgtPensionDaekningListe:
                JoiObj.array().items(
        
        JoiObj.link('#PensionDaekningPrisSchema')

        )
        
.allow(null)



        ,
        valgtTillaegForsikringDaekningListe:
                JoiObj.array().items(
        
        JoiObj.link('#ForsikringDaekningPrisSchema')

        )
        
.allow(null)



        ,
        vederlagOmkostning:
            
        JoiObj.link('#VederlagOmkostningSchema')

.allow(null)



        
    })
                .shared(GennemsnitsrenteOmkostningSchema)
                .shared(PrisGruppeSchema)
                .shared(HelbredssikringDaekningPrisSchema)
                .shared(InvesteringOmkostningSchema)
                .shared(PrisGruppeSchema)
                .shared(PrisGruppeSchema)
                .shared(TransaktionOmkostningSchema)
                .shared(ForsikringDaekningPrisSchema)
                .shared(PensionDaekningPrisSchema)
                .shared(ForsikringDaekningPrisSchema)
                .shared(VederlagOmkostningSchema)
.id('PrislisteSchema')

export interface Prisliste { 
    fejlet: boolean;
    fripolice: boolean;
    gennemsnitsrenteOmkostningListe: Array<GennemsnitsrenteOmkostning>;
    grundPakkePris: PrisGruppe;
    gyldigDato: Date;
    helbredssikringDaekningPris?: HelbredssikringDaekningPris;
    investeringOmkostning?: InvesteringOmkostning;
    samletPakkePris?: PrisGruppe;
    tilvalgPakkePrisListe: Array<PrisGruppe>;
    transaktionOmkostningListe: Array<TransaktionOmkostning>;
    valgtForsikringDaekningListe?: Array<ForsikringDaekningPris>;
    valgtPensionDaekningListe?: Array<PensionDaekningPris>;
    valgtTillaegForsikringDaekningListe?: Array<ForsikringDaekningPris>;
    vederlagOmkostning?: VederlagOmkostning;
}

