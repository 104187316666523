export class HentFormularResponse {
  tekster: Map<string, string>;
  datetimepickerTexts: Map<string, string>;
  attachmentTexts: Map<string, string>;
  dynamicFormConfig: DynamicForm;
  fejlet: boolean;
}
export class DynamicForm {
  FormTemplateVersionId: string;
  FormFields: Array<FormField>;
  AttachmentOptions: AttachmentType;
  MinAttachmentsNumber: number;
  MaxAttachmentsNumber: number;
}

// according to spec in ds backend project, the 'key' is required
// \ds\dsjsontypes\src\main\resources\schema\DynamicFormConfig.json
export class FormField {
  readonly model?: any;
  Key: string;
  Type?: string;
  HideExpression?: string;
  TemplateOptions?: TemplateOptions;
  Hide: boolean;
  Value?: string;
  Id?: string;
  ParentKey?: string;
  Children: Array<string>;
}

export enum AttachmentType {
  Optional = 0,
  Required = 1,
  NotAllowed = 2
}

export type SprogType = 'DA' | 'EN';

export interface ContactData {
  firstName: string;
  lastName: string;
  languageCode: SprogType;
  phoneCountryCode: string;
  phoneNumber: string;
  email: string;
}

export class TemplateOptions {
  Label?: string;
  hidden?: boolean;
  Max?: number;
  Min?: number;
  MinLength?: number;
  MaxLength?: number;
  ShowDecimals?: boolean;
  ShowCurrency?: boolean;
  AddField?: boolean;
  MaxAdd: number;
  Options?: any[];
  Placeholder?: string;
  Required?: boolean;
  Description?: string;
  Pattern?: string | RegExp;
  [additionalProperties: string]: any;
}
