/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const DynamicAdvisingOfferidTypeSchema = JoiObj.string().valid(
        'TAKE_INSURANCE_GUIDE'
        ,
        'TAKE_INVESTMENT_GUIDE'
        ,
        'RETRIEVE_PENSION_INFO_REPORT'
        ,
        'ENTER_WEALTH'
        ,
        'ENTER_DEBT'
        ,
        'ENTER_DEPENDENTS'
        ,
        'ENTER_MARITAL_STATUS'
        ,
        'ENTER_PARTNER_SALARY'
        ,
        'DATA_COLLECTION_ALL_OK'
        ,
        'BENEFICIARY_FILL_OUT_BENEFICIARY_DECLARATION'
        ,
        'BENEFICIARY_ALL_OK'
        ,
        'EXTERNAL_FUNDS_TRANSFER'
        ,
        'EXTERNAL_FUNDS_ALL_OK'
        ,
        'INVESTMENT_PROFILE_INCREASE_RISK'
        ,
        'INVESTMENT_PROFILE_LOWER_RISK'
        ,
        'INVESTMENT_CLIMATE_PLUS_INCREASE_SHARE'
        ,
        'INVESTMENT_CLIMATE_PLUS_LOWER_SHARE'
        ,
        'INVESTMENT_CONCEPT_CHANGE_CONCEPT_PFA_INVEST'
        ,
        'INVESTMENT_CONCEPT_CHANGE_CONCEPT_YOU_INVEST'
        ,
        'INVESTMENT_PROFILE_ALL_OK'
        ,
        'GIPP_TO_PLUS_ALL_OK'
        ,
        'GIPP_TO_PLUS_TRANSFER_DEPOSIT'
        ,
        'LIFE_INSURANCE_INCREASE_COVERAGE'
        ,
        'LIFE_INSURANCE_LOWER_COVERAGE'
        ,
        'LIFE_INSURANCE_ALL_OK'
        ,
        'LOC_INSURANCE_INCREASE_COVERAGE'
        ,
        'LOC_INSURANCE_LOWER_COVERAGE'
        ,
        'LOC_AUTOMATIC_ADJUSTMENT_INCREASE_TO_80'
        ,
        'LOC_AUTOMATIC_ADJUSTMENT_LOWER_TO_80'
        ,
        'LOC_AUTOMATIC_ADJUSTMENT_INCREASE_TO_100'
        ,
        'LOC_AUTOMATIC_ADJUSTMENT_LOWER_TO_100'
        ,
        'LOC_AUTOMATIC_ADJUSTMENT_OUT_INCREASE'
        ,
        'LOC_AUTOMATIC_ADJUSTMENT_OUT_LOWER'
        ,
        'LOC_INSURANCE_ALL_OK'
        ,
        'MERGE_POLICIES_MERGE'
        ,
        'MERGE_POLICIES_ALL_OK'
        ,
        'PENSION_ESTIMATE_INCREASE_VOLUNTARY_DEPOSIT'
        ,
        'PENSION_ESTIMATE_ALL_OK'
        
    )
.id('DynamicAdvisingOfferidTypeSchema')

export type DynamicAdvisingOfferidType = 'TAKE_INSURANCE_GUIDE' | 'TAKE_INVESTMENT_GUIDE' | 'RETRIEVE_PENSION_INFO_REPORT' | 'ENTER_WEALTH' | 'ENTER_DEBT' | 'ENTER_DEPENDENTS' | 'ENTER_MARITAL_STATUS' | 'ENTER_PARTNER_SALARY' | 'DATA_COLLECTION_ALL_OK' | 'BENEFICIARY_FILL_OUT_BENEFICIARY_DECLARATION' | 'BENEFICIARY_ALL_OK' | 'EXTERNAL_FUNDS_TRANSFER' | 'EXTERNAL_FUNDS_ALL_OK' | 'INVESTMENT_PROFILE_INCREASE_RISK' | 'INVESTMENT_PROFILE_LOWER_RISK' | 'INVESTMENT_CLIMATE_PLUS_INCREASE_SHARE' | 'INVESTMENT_CLIMATE_PLUS_LOWER_SHARE' | 'INVESTMENT_CONCEPT_CHANGE_CONCEPT_PFA_INVEST' | 'INVESTMENT_CONCEPT_CHANGE_CONCEPT_YOU_INVEST' | 'INVESTMENT_PROFILE_ALL_OK' | 'GIPP_TO_PLUS_ALL_OK' | 'GIPP_TO_PLUS_TRANSFER_DEPOSIT' | 'LIFE_INSURANCE_INCREASE_COVERAGE' | 'LIFE_INSURANCE_LOWER_COVERAGE' | 'LIFE_INSURANCE_ALL_OK' | 'LOC_INSURANCE_INCREASE_COVERAGE' | 'LOC_INSURANCE_LOWER_COVERAGE' | 'LOC_AUTOMATIC_ADJUSTMENT_INCREASE_TO_80' | 'LOC_AUTOMATIC_ADJUSTMENT_LOWER_TO_80' | 'LOC_AUTOMATIC_ADJUSTMENT_INCREASE_TO_100' | 'LOC_AUTOMATIC_ADJUSTMENT_LOWER_TO_100' | 'LOC_AUTOMATIC_ADJUSTMENT_OUT_INCREASE' | 'LOC_AUTOMATIC_ADJUSTMENT_OUT_LOWER' | 'LOC_INSURANCE_ALL_OK' | 'MERGE_POLICIES_MERGE' | 'MERGE_POLICIES_ALL_OK' | 'PENSION_ESTIMATE_INCREASE_VOLUNTARY_DEPOSIT' | 'PENSION_ESTIMATE_ALL_OK';

export const DynamicAdvisingOfferidType = {
    TakeInsuranceGuide: 'TAKE_INSURANCE_GUIDE' as DynamicAdvisingOfferidType,
    TakeInvestmentGuide: 'TAKE_INVESTMENT_GUIDE' as DynamicAdvisingOfferidType,
    RetrievePensionInfoReport: 'RETRIEVE_PENSION_INFO_REPORT' as DynamicAdvisingOfferidType,
    EnterWealth: 'ENTER_WEALTH' as DynamicAdvisingOfferidType,
    EnterDebt: 'ENTER_DEBT' as DynamicAdvisingOfferidType,
    EnterDependents: 'ENTER_DEPENDENTS' as DynamicAdvisingOfferidType,
    EnterMaritalStatus: 'ENTER_MARITAL_STATUS' as DynamicAdvisingOfferidType,
    EnterPartnerSalary: 'ENTER_PARTNER_SALARY' as DynamicAdvisingOfferidType,
    DataCollectionAllOk: 'DATA_COLLECTION_ALL_OK' as DynamicAdvisingOfferidType,
    BeneficiaryFillOutBeneficiaryDeclaration: 'BENEFICIARY_FILL_OUT_BENEFICIARY_DECLARATION' as DynamicAdvisingOfferidType,
    BeneficiaryAllOk: 'BENEFICIARY_ALL_OK' as DynamicAdvisingOfferidType,
    ExternalFundsTransfer: 'EXTERNAL_FUNDS_TRANSFER' as DynamicAdvisingOfferidType,
    ExternalFundsAllOk: 'EXTERNAL_FUNDS_ALL_OK' as DynamicAdvisingOfferidType,
    InvestmentProfileIncreaseRisk: 'INVESTMENT_PROFILE_INCREASE_RISK' as DynamicAdvisingOfferidType,
    InvestmentProfileLowerRisk: 'INVESTMENT_PROFILE_LOWER_RISK' as DynamicAdvisingOfferidType,
    InvestmentClimatePlusIncreaseShare: 'INVESTMENT_CLIMATE_PLUS_INCREASE_SHARE' as DynamicAdvisingOfferidType,
    InvestmentClimatePlusLowerShare: 'INVESTMENT_CLIMATE_PLUS_LOWER_SHARE' as DynamicAdvisingOfferidType,
    InvestmentConceptChangeConceptPfaInvest: 'INVESTMENT_CONCEPT_CHANGE_CONCEPT_PFA_INVEST' as DynamicAdvisingOfferidType,
    InvestmentConceptChangeConceptYouInvest: 'INVESTMENT_CONCEPT_CHANGE_CONCEPT_YOU_INVEST' as DynamicAdvisingOfferidType,
    InvestmentProfileAllOk: 'INVESTMENT_PROFILE_ALL_OK' as DynamicAdvisingOfferidType,
    GippToPlusAllOk: 'GIPP_TO_PLUS_ALL_OK' as DynamicAdvisingOfferidType,
    GippToPlusTransferDeposit: 'GIPP_TO_PLUS_TRANSFER_DEPOSIT' as DynamicAdvisingOfferidType,
    LifeInsuranceIncreaseCoverage: 'LIFE_INSURANCE_INCREASE_COVERAGE' as DynamicAdvisingOfferidType,
    LifeInsuranceLowerCoverage: 'LIFE_INSURANCE_LOWER_COVERAGE' as DynamicAdvisingOfferidType,
    LifeInsuranceAllOk: 'LIFE_INSURANCE_ALL_OK' as DynamicAdvisingOfferidType,
    LocInsuranceIncreaseCoverage: 'LOC_INSURANCE_INCREASE_COVERAGE' as DynamicAdvisingOfferidType,
    LocInsuranceLowerCoverage: 'LOC_INSURANCE_LOWER_COVERAGE' as DynamicAdvisingOfferidType,
    LocAutomaticAdjustmentIncreaseTo80: 'LOC_AUTOMATIC_ADJUSTMENT_INCREASE_TO_80' as DynamicAdvisingOfferidType,
    LocAutomaticAdjustmentLowerTo80: 'LOC_AUTOMATIC_ADJUSTMENT_LOWER_TO_80' as DynamicAdvisingOfferidType,
    LocAutomaticAdjustmentIncreaseTo100: 'LOC_AUTOMATIC_ADJUSTMENT_INCREASE_TO_100' as DynamicAdvisingOfferidType,
    LocAutomaticAdjustmentLowerTo100: 'LOC_AUTOMATIC_ADJUSTMENT_LOWER_TO_100' as DynamicAdvisingOfferidType,
    LocAutomaticAdjustmentOutIncrease: 'LOC_AUTOMATIC_ADJUSTMENT_OUT_INCREASE' as DynamicAdvisingOfferidType,
    LocAutomaticAdjustmentOutLower: 'LOC_AUTOMATIC_ADJUSTMENT_OUT_LOWER' as DynamicAdvisingOfferidType,
    LocInsuranceAllOk: 'LOC_INSURANCE_ALL_OK' as DynamicAdvisingOfferidType,
    MergePoliciesMerge: 'MERGE_POLICIES_MERGE' as DynamicAdvisingOfferidType,
    MergePoliciesAllOk: 'MERGE_POLICIES_ALL_OK' as DynamicAdvisingOfferidType,
    PensionEstimateIncreaseVoluntaryDeposit: 'PENSION_ESTIMATE_INCREASE_VOLUNTARY_DEPOSIT' as DynamicAdvisingOfferidType,
    PensionEstimateAllOk: 'PENSION_ESTIMATE_ALL_OK' as DynamicAdvisingOfferidType
};

