<p [coContent]="'DL.TR01.C366'" [coContentSubst]="overview.policeNr"></p>
<p [coContent]="'DL.TR01.C367'"></p>
<p [coContent]="'DL.TR01.C376'"></p>
<p [coContent]="'DL.TR01.C368'"></p>
<p [coContent]="'DL.TR01.C190'"></p>

<table
  class="new-business-advisory-table align-right--column-2 align-right--column-3"
>
  <colgroup>
    <col style="width: 64%" />
    <col style="width: 18%" />
    <col style="width: 18%" />
  </colgroup>
  <thead>
    <tr>
      <th colspan="2" [coContent]="'DL.TR01.C369'"></th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td></td>
      <td class="text--bold" [coContent]="'DL.TR01.C371'"></td>
      <td class="text--bold" [coContent]="'DL.TR01.C372'"></td>
    </tr>
    <tr>
      <td [coContent]="'DL.TR01.C370'"></td>
      <td>{{ comparison.nuvaerendeOrdningDatoStreng }}</td>
      <td>{{ comparison.efterAendringOrdningDatoStreng }}</td>
    </tr>

    <!-- Erhvervsevne -->
    <tr
      *ngIf="
        comparison.tae.length > 0 ||
        comparison.resourceforloeb.length > 0 ||
        comparison.taeEngang.length > 0 ||
        comparison.indbetalingssikring.length > 0
      "
      class="no-border-row"
    >
      <td colspan="3" class="text--bold" [coContent]="'DL.TR01.C76'"></td>
    </tr>

    <tr
      *ngFor="let taeDaekning of comparison.tae; last as isLast"
      class="no-border-row"
    >
      <td>
        <div
          [coContent]="'DL.FG01.C56'"
          [coContentSubst]="taeDaekning.alder"
        ></div>
        <div class="text--note" [ngSwitch]="taeDaekning.daekningId">
          <div
            *ngSwitchCase="801"
            class="text--note"
            [coContent]="'DL.TR01.C194'"
          ></div>
          <div
            *ngSwitchCase="802"
            class="text--note"
            [coContent]="'DL.TR01.C195'"
          ></div>
          <div
            *ngSwitchCase="807"
            class="text--note"
            [coContent]="'DL.TR01.C192'"
          ></div>
          <div
            *ngSwitchCase="808"
            class="text--note"
            [coContent]="'DL.TR01.C290'"
          ></div>
          <div
            *ngSwitchCase="811"
            class="text--note"
            [coContent]="'DL.TR01.C399'"
          ></div>
          <div
            *ngSwitchCase="812"
            class="text--note"
            [coContent]="'DL.TR01.C386'"
          ></div>
          <div
            *ngSwitchCase="813"
            class="text--note"
            [coContent]="'DL.TR01.C387'"
          ></div>
          <div
            *ngSwitchCase="814"
            class="text--note"
            [coContent]="'DL.TR01.C406'"
          ></div>
          <div
            *ngSwitchCase="815"
            class="text--note"
            [coContent]="'DL.TR01.C388'"
          ></div>
          <div
            *ngSwitchCase="816"
            class="text--note"
            [coContent]="'DL.TR01.C398'"
          ></div>
        </div>
      </td>
      <td>
        <co-daekning-current [daekning]="taeDaekning"></co-daekning-current>
      </td>
      <td>
        <co-daekning-after [daekning]="taeDaekning"></co-daekning-after>
      </td>
    </tr>

    <!-- Resourceforloeb -->
    <tr
      *ngFor="let forloeb of comparison.resourceforloeb; last as isLast"
      class="no-border-row"
    >
      <td>
        <div
          [coContent]="'DL.FG01.C1030'"
          [coContentSubst]="forloeb.alder"
        ></div>

        <div class="text--note" [coContent]="'DL.TR01.C196'"></div>
      </td>
      <td>
        <co-daekning-current [daekning]="forloeb"></co-daekning-current>
      </td>
      <td>
        <co-daekning-after [daekning]="forloeb"></co-daekning-after>
      </td>
    </tr>

    <!-- Erhvervsevne Boerne -->
    <tr
      *ngFor="let boernePension of comparison.taeBoerne; last as isLast"
      class="no-border-row"
    >
      <td>
        <div
          [coContent]="'DL.FG01.C58'"
          [coContentSubst]="boernePension.alder"
        ></div>
      </td>
      <td>
        <co-daekning-current [daekning]="boernePension"></co-daekning-current>
      </td>
      <td>
        <co-daekning-after [daekning]="boernePension"></co-daekning-after>
      </td>
    </tr>

    <!-- Erhvervsevne engang -->
    <tr
      *ngFor="let engang of comparison.taeEngang; last as isLast"
      class="no-border-row"
    >
      <td>
        <div [coContent]="'DL.FG01.C57'" [coContentSubst]="engang.alder"></div>

        <div class="text--note" [coContent]="'DL.TR01.C197'"></div>
      </td>
      <td>
        <co-daekning-current [daekning]="engang"></co-daekning-current>
      </td>
      <td>
        <co-daekning-after [daekning]="engang"></co-daekning-after>
      </td>
    </tr>

    <!-- Indbetalingssikring -->
    <tr
      *ngFor="let sikring of comparison.indbetalingssikring; last as isLast"
      class="no-border-row"
    >
      <td>
        <div
          [coContent]="'DL.TR01.C373'"
          [coContentSubst]="sikring.alder"
        ></div>
      </td>
      <td>
        <co-daekning-current [daekning]="sikring"></co-daekning-current>
      </td>
      <td>
        <co-daekning-after [daekning]="sikring"></co-daekning-after>
      </td>
    </tr>

    <!-- Indbetalingssikring Nedsat Halv Hel -->
    <tr
      *ngFor="
        let sikring of comparison.indbetalingssikringNedsatHalv_Hel;
        last as isLast
      "
      class="no-border-row"
    >
      <td>
        <div [coContent]="'DL.TR01.C204'"></div>
      </td>
      <td>
        <co-daekning-current [daekning]="sikring"></co-daekning-current>
      </td>
      <td>
        <co-daekning-after [daekning]="sikring"></co-daekning-after>
      </td>
    </tr>

    <!-- Indbetalingssikring Nedsat 2/3 -->
    <tr
      *ngFor="
        let sikring of comparison.indbetalingssikringNedsat2_3;
        last as isLast
      "
      class="no-border-row"
    >
      <td>
        <div [coContent]="'DL.TR01.C451'"></div>
      </td>
      <td>
        <co-daekning-current [daekning]="sikring"></co-daekning-current>
      </td>
      <td>
        <co-daekning-after [daekning]="sikring"></co-daekning-after>
      </td>
    </tr>

    <!-- Indbetalingssikring Nedsat 1/2 -->
    <tr
      *ngFor="
        let sikring of comparison.indbetalingssikringNedsatHalv;
        last as isLast
      "
      class="no-border-row"
    >
      <td>
        <div class="text--note" [coContent]="'DL.TR01.C452'"></div>
      </td>
      <td>
        <co-daekning-current [daekning]="sikring"></co-daekning-current>
      </td>
      <td>
        <co-daekning-after [daekning]="sikring"></co-daekning-after>
      </td>
    </tr>

    <!-- Kritisk sygdom -->
    <tr
      *ngIf="comparison.ks.length > 0 || comparison.ksBoerne.length > 0"
      class="no-border-row border-top"
    >
      <td colspan="3" class="text--bold" [coContent]="'DL.TR01.C92'"></td>
    </tr>

    <tr *ngFor="let ks of comparison.ks; last as isLast" class="no-border-row">
      <td>
        <div [coContent]="'DL.FG01.C53'" [coContentSubst]="ks.alder"></div>
      </td>
      <td>
        <co-daekning-current [daekning]="ks"></co-daekning-current>
      </td>
      <td>
        <co-daekning-after [daekning]="ks"></co-daekning-after>
      </td>
    </tr>

    <!-- Kritisk sygdom boern -->
    <tr
      *ngFor="let ksBoern of comparison.ksBoerne; last as isLast"
      class="no-border-row"
    >
      <td>
        <div
          [coContent]="'DL.FG01.C279'"
          [coContentSubst]="ksBoern.alder"
        ></div>
      </td>
      <td>
        <co-daekning-current [daekning]="ksBoern"></co-daekning-current>
      </td>
      <td>
        <co-daekning-after [daekning]="ksBoern"></co-daekning-after>
      </td>
    </tr>

    <!-- Helbredssikring -->
    <tr
      *ngIf="comparison.hs.length > 0 || comparison.hsBoerne.length > 0"
      class="no-border-row border-top"
    >
      <td colspan="3" class="text--bold" [coContent]="'DL.TR01.C93'"></td>
    </tr>

    <tr *ngFor="let hs of comparison.hs; last as isLast" class="no-border-row">
      <td>
        <div [coContent]="'DL.FG01.C855'"></div>
      </td>
      <td>
        <co-daekning-current [daekning]="hs"></co-daekning-current>
      </td>
      <td>
        <co-daekning-after [daekning]="hs"></co-daekning-after>
      </td>
    </tr>

    <!-- Helbredssikring boern -->
    <tr
      *ngFor="let hsBoern of comparison.hsBoerne; last as isLast"
      class="no-border-row"
    >
      <td>
        <div
          [coContent]="'DL.FG01.C856'"
          [coContentSubst]="hsBoern.alder"
        ></div>
      </td>
      <td>
        <co-daekning-current [daekning]="hsBoern"></co-daekning-current>
      </td>
      <td>
        <co-daekning-after [daekning]="hsBoern"></co-daekning-after>
      </td>
    </tr>

    <!-- PFA forebygger -->
    <tr
      *ngIf="comparison.forebygger.length > 0"
      class="no-border-row border-top"
    >
      <td colspan="3" class="text--bold" [coContent]="'DL.TR01.C97'"></td>
    </tr>

    <tr
      *ngFor="let forebygger of comparison.forebygger; last as isLast"
      class="no-border-row"
    >
      <td>
        <div [coContent]="'DL.FG01.C48'"></div>
      </td>
      <td>
        <co-daekning-current [daekning]="forebygger"></co-daekning-current>
      </td>
      <td>
        <co-daekning-after [daekning]="forebygger"></co-daekning-after>
      </td>
    </tr>

    <!-- PFA diagnose -->
    <tr *ngIf="comparison.diagnose.length > 0" class="no-border-row border-top">
      <td colspan="3" class="text--bold" [coContent]="'DL.TR01.C98'"></td>
    </tr>

    <tr
      *ngFor="let diagnose of comparison.diagnose; last as isLast"
      class="no-border-row"
    >
      <td>
        <div [coContent]="'DL.FG01.C228'"></div>
      </td>
      <td>
        <co-daekning-current [daekning]="diagnose"></co-daekning-current>
      </td>
      <td>
        <co-daekning-after [daekning]="diagnose"></co-daekning-after>
      </td>
    </tr>

    <!-- PFA Early care -->
    <tr
      *ngIf="comparison.earlycare.length > 0"
      class="no-border-row border-top"
    >
      <td colspan="3" class="text--bold" [coContent]="'DL.TR01.C285'"></td>
    </tr>

    <tr
      *ngFor="let earlycare of comparison.earlycare; last as isLast"
      class="no-border-row"
    >
      <td>
        <div [coContent]="'DL.TR01.C286'"></div>
      </td>
      <td>
        <co-daekning-current [daekning]="earlycare"></co-daekning-current>
      </td>
      <td>
        <co-daekning-after [daekning]="earlycare"></co-daekning-after>
      </td>
    </tr>

    <!-- PFA sundhedslinje -->
    <tr
      *ngIf="comparison.sundhedslinje.length > 0"
      class="no-border-row border-top"
    >
      <td colspan="3" class="text--bold" [coContent]="'DL.FG01.C1605'"></td>
    </tr>

    <tr
      *ngFor="let sundhedslinje of comparison.sundhedslinje; last as isLast"
      class="no-border-row"
    >
      <td>
        <div [coContent]="'DL.FG01.C1606'"></div>
      </td>
      <td>
        <co-daekning-current [daekning]="sundhedslinje"></co-daekning-current>
      </td>
      <td>
        <co-daekning-after [daekning]="sundhedslinje"></co-daekning-after>
      </td>
    </tr>

    <!-- PFA liv -->
    <tr
      *ngIf="
        comparison.liv.length > 0 ||
        comparison.livEngang.length > 0 ||
        comparison.livDepotsikring.length > 0 ||
        comparison.livBoernePension.length > 0
      "
      class="no-border-row border-top"
    >
      <td colspan="3" class="text--bold" [coContent]="'DL.TR01.C86'"></td>
    </tr>

    <tr
      *ngFor="let livRate of comparison.liv; last as isLast"
      class="no-border-row"
    >
      <td>
        <div [coContent]="'DL.FG01.C61'" [coContentSubst]="livRate.alder"></div>
        <div class="text--note" [coContent]="'DL.TR01.C233'"></div>
      </td>
      <td>
        <co-daekning-current [daekning]="livRate"></co-daekning-current>
      </td>
      <td>
        <co-daekning-after [daekning]="livRate"></co-daekning-after>
      </td>
    </tr>

    <tr
      *ngFor="let livEngang of comparison.livEngang; last as isLast"
      class="no-border-row"
    >
      <td>
        <div [coContent]="'DL.FG01.C62'"></div>
      </td>
      <td>
        <co-daekning-current [daekning]="livEngang"></co-daekning-current>
      </td>
      <td>
        <co-daekning-after [daekning]="livEngang"></co-daekning-after>
      </td>
    </tr>

    <tr
      *ngFor="let livDepotsikring of comparison.livDepotsikring; last as isLast"
      class="no-border-row"
    >
      <td>
        <div [coContent]="'DL.FG01.C284'"></div>
        <div class="text--note" [coContent]="'DL.TR01.C374'"></div>
      </td>
      <td>
        <co-daekning-current [daekning]="livDepotsikring"></co-daekning-current>
      </td>
      <td>
        <div [ngSwitch]="livDepotsikring.efterviderefoerelse.type">
          <div *ngSwitchCase="'BELOEB'">
            <div>
              {{ livDepotsikring.efterviderefoerelse.beloeb | numberFormat }}
              <span [coContent]="'Global.kroner'"></span>
            </div>
            <div
              *ngIf="livDepotsikring.skattekode === 'SK5'"
              class="text--note"
              [coContent]="'DL.FG01.C54'"
            ></div>
            <div class="text--note" [coContent]="'DL.TR01.C375'"></div>
          </div>
          <div *ngSwitchCase="'PROCENT'">
            {{ livDepotsikring.efterviderefoerelse.procent | numberFormat }} %
          </div>
          <div *ngSwitchCase="'TJEK'">
            <co-icon-checkmark></co-icon-checkmark>
          </div>
          <div *ngSwitchCase="'KRYDS'"><co-icon-times></co-icon-times></div>
        </div>
      </td>
    </tr>

    <tr
      *ngFor="
        let livBoernepension of comparison.livBoernePension;
        last as isLast
      "
      class="no-border-row"
    >
      <td>
        <div
          [coContent]="'DL.FG01.C69'"
          [coContentSubst]="livBoernepension.alder"
        ></div>
      </td>
      <td>
        <co-daekning-current
          [daekning]="livBoernepension"
        ></co-daekning-current>
      </td>
      <td>
        <co-daekning-after [daekning]="livBoernepension"></co-daekning-after>
      </td>
    </tr>
  </tbody>
</table>
