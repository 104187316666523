import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  AdvisorOptions,
  IndbetalingSikring,
  OpsparingFormFordeling,
  ProcentMinMax,
  TilbudOverblik
} from '@pfa/gen';
import {
  FormControl,
  FormGroup,
  UntypedFormControl,
  UntypedFormGroup
} from '@angular/forms';
import { DecimalNumberParser, decimalNumberValidator } from '@pfa/utils';
import { ContentUtilService } from '@pfa/core';
import { formatNumber } from '@angular/common';

@Component({
  selector: 'co-agreement-and-payment',
  templateUrl: './agreement-and-payment.component.html',
  styleUrls: ['../new-business-offer-overview.component.scss']
})
export class AgreementAndPaymentComponent implements OnInit {
  @Input() overview: TilbudOverblik;
  @Input() advisorOptions: AdvisorOptions;
  @Input() savePaymentsFail: boolean;
  @Output() savePayments = new EventEmitter<{
    employer: number;
    employee: number;
  }>();
  @Output() saveVoluntaryPayment = new EventEmitter<number>();

  @Input() saveIndbetalingSikringFail: boolean;
  @Output() saveIndbetalingSikring = new EventEmitter<IndbetalingSikring>();

  employerPercentRange: string;
  employeePercentRange: string;
  mandatoryPaymentsForm: UntypedFormGroup;

  get employerPercent(): UntypedFormControl {
    return this.mandatoryPaymentsForm.get(
      'employerPercent'
    ) as UntypedFormControl;
  }

  get employeePercent(): UntypedFormControl {
    return this.mandatoryPaymentsForm.get(
      'employeePercent'
    ) as UntypedFormControl;
  }

  voluntaryPaymentsForm: UntypedFormGroup;

  get voluntaryAmount(): UntypedFormControl {
    return this.voluntaryPaymentsForm.get(
      'voluntaryAmount'
    ) as UntypedFormControl;
  }

  indbetalingSikringForm: UntypedFormGroup;

  get indbetalingSikring(): UntypedFormControl {
    return this.indbetalingSikringForm.get(
      'indbetalingSikring'
    ) as UntypedFormControl;
  }

  constructor(private readonly cu: ContentUtilService) {}

  private static setPaymentValidators(
    fld: UntypedFormControl,
    pmm: ProcentMinMax
  ) {
    fld.setValidators([decimalNumberValidator(2, pmm.min, pmm.max)]);
  }

  ngOnInit(): void {
    this.mandatoryPaymentsForm = new UntypedFormGroup({
      employerPercent: new UntypedFormControl(
        formatNumber(this.overview.arbejdsgiverObligatoriskIndbetalingPct, 'da')
      ),
      employeePercent: new UntypedFormControl(
        formatNumber(this.overview.medarbejderObligatoriskIndbetalingPct, 'da')
      )
    });

    const indbetaling = this.overview.minObligatoriskIndbetaling;

    if (indbetaling.arbejdesgiverIndbetalingEffektueringMuligt) {
      AgreementAndPaymentComponent.setPaymentValidators(
        this.employerPercent,
        indbetaling.arbejdsgiverProcentMinMax
      );
      this.employerPercentRange = this.getPaymentRange(
        indbetaling.arbejdsgiverProcentMinMax
      );
    }

    if (indbetaling.medarbejderIndbetalingEffektueringMuligt) {
      AgreementAndPaymentComponent.setPaymentValidators(
        this.employeePercent,
        indbetaling.medarbejderProcentMinMax
      );
      this.employeePercentRange = this.getPaymentRange(
        indbetaling.medarbejderProcentMinMax
      );
    }

    this.voluntaryPaymentsForm = new FormGroup({
      voluntaryAmount: new FormControl(
        this.overview.frivilligIndbetalingBeloeb.toString()
      )
    });

    this.indbetalingSikringForm = new FormGroup({
      indbetalingSikring: new FormControl(this.overview.indbetalingSikring)
    });

    this.indbetalingSikring.valueChanges.subscribe(newValue => {
      this.saveIndbetalingSikring.emit(newValue);
    });
  }

  parseNumber(number: string): number {
    return DecimalNumberParser.parse(number);
  }

  private getPaymentRange(pmm: ProcentMinMax) {
    return this.cu.getContent('DL.TR01.C347', ['' + pmm.min, '' + pmm.max]);
  }

  indbetalingSikringText(indbetalingSikring: IndbetalingSikring): string {
    switch (indbetalingSikring) {
      case 'Ja':
        return 'DL.TR01.C34';
      case 'Nej':
        return 'DL.TR01.C35';
      case 'Ingen':
        return 'DL.TR01.C36';
    }
  }

  opsparingFormFordelingText(
    indbetalingSikring: OpsparingFormFordeling
  ): string {
    switch (indbetalingSikring) {
      case 'KapitalRateLivsPension':
        return '???';
      case 'RateKapitalLivsPension':
        return '???';
      case 'RateLivsPension':
        return 'DL.TR01.C40';
      case 'LivsPension':
        return 'DL.TR01.C44';
      case 'EjFradragsberettigt':
        return '???';
      case 'AldersRateLivsPension':
        return 'DL.TR01.C41';
      case 'RateAldersLivsPension':
        return 'DL.TR01.C42';
      case 'AldersLivsPension':
        return 'DL.TR01.C43';
    }
  }

  hasPremium(): boolean {
    return (
      !!this.overview.totalIndbetalingPct ||
      !!this.overview.totalIndbetalingBeloeb
    );
  }

  checkMinIndbetalingTilOpsparing() {
    return !(
      this.advisorOptions &&
      this.overview.policyType === 'CONTINUATION' &&
      this.overview.variantCode === 1 &&
      this.overview.indbetalingTilOpsparing <
        this.advisorOptions.minIndbetalingTilOpsparing
    );
  }
}
