/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BeneficiarySimple } from './beneficiarySimple';
import { BeneficiarySimpleSchema } from './beneficiarySimple';
import { BeneficiaryPolicyRule } from './beneficiaryPolicyRule';
import { BeneficiaryPolicyRuleSchema } from './beneficiaryPolicyRule';
import { BeneficiaryType } from './beneficiaryType';
import { BeneficiaryTypeSchema } from './beneficiaryType';
import { BeneficiaryPerson } from './beneficiaryPerson';
import { BeneficiaryPersonSchema } from './beneficiaryPerson';
import * as JoiObj from "joi";


    export const BeneficiaryDraftSchema = JoiObj.object({
        advisor:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        draftId:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        persons:
                JoiObj.array().items(
        
        JoiObj.link('#BeneficiaryPersonSchema')

        )
        

.required()


        ,
        policies:
                JoiObj.array().items(
        
        JoiObj.link('#BeneficiaryPolicyRuleSchema')

        )
        

.required()


        ,
        type:
            
        JoiObj.link('#BeneficiaryTypeSchema')


.required()


        ,
        objectType:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        validFrom:
            
            JoiObj.date()


.required()


        ,
        validTo:
            
            JoiObj.date()


.required()


        ,
        validateOk:
            
                    JoiObj.boolean()
                


.required()


        
    })
                .shared(BeneficiaryPersonSchema)
                .shared(BeneficiaryPolicyRuleSchema)
                .shared(BeneficiaryTypeSchema)
.id('BeneficiaryDraftSchema')

export interface BeneficiaryDraft extends BeneficiarySimple { 
    validFrom: Date;
    validTo: Date;
    validateOk: boolean;
    objectType?: string;
}
export namespace BeneficiaryDraft {
}


