import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { InvestmentApiService } from '@pfa/api';
import { Observable } from 'rxjs';
import { InvesteringDetaljerSvar } from '@pfa/gen';

@Injectable({
  providedIn: 'root'
})
export class InvestmentResolver implements Resolve<InvesteringDetaljerSvar> {
  constructor(private investmentApiService: InvestmentApiService) {}

  resolve(): InvesteringDetaljerSvar | Observable<InvesteringDetaljerSvar> {
    return this.investmentApiService.get();
  }
}
