import { Step } from '@pfaform';
import { GuideStepType, GuideTrackingId } from '@pfa/core';

export class OnboardingFlow {
  private _levels: OnboardingLevel[] = OnboardingFlow.setupLevels();
  private _progressSteps: { [key: string]: Step };

  private static setupLevels(): OnboardingLevel[] {
    const onboardingDetails: OnboardingLevel[] = [];
    onboardingDetails.push({
      level: 'WELCOME',
      showStepper: false,
      stepperProgress: 0,
      showLogo: true,
      showBack: false,
      showJumpover: false,
      showClose: true,
      note: undefined,
      showLanguage: true,
      nextLevel: 'INTRODUCTION',
      previousLevel: 'WELCOME',
      introductionPage: true,
      stepperLevel: OnboardingStepperLevel.CONTACTS,
      trackingName: OnboardingStepTrackingName.INTRODUCTION,
      guideStepType: GuideStepType.MANDATORY,
      guideStepName: OnboardingGuideName.ONBOARDING_GUIDE,
      isChecked: false,
      isCombineSavings: false
    });
    onboardingDetails.push({
      level: 'INTRODUCTION',
      showStepper: false,
      stepperProgress: 0,
      showLogo: true,
      showBack: false,
      showJumpover: false,
      showClose: true,
      note: undefined,
      showLanguage: true,
      nextLevel: 'STEP_CONTACT',
      previousLevel: 'WELCOME',
      introductionPage: false,
      stepperLevel: OnboardingStepperLevel.CONTACTS,
      trackingName: OnboardingStepTrackingName.INTRODUCTION_CAROUSEL,
      guideStepType: GuideStepType.MANDATORY,
      guideStepName: OnboardingGuideName.ONBOARDING_GUIDE,
      isChecked: false,
      isCombineSavings: false
    });
    onboardingDetails.push({
      level: 'STEP_CONTACT',
      showStepper: false,
      stepperProgress: 0,
      showLogo: true,
      showBack: false,
      showJumpover: false,
      showClose: true,
      note: undefined,
      showLanguage: false,
      nextLevel: 'CONTACT_DATA',
      previousLevel: 'INTRODUCTION',
      introductionPage: true,
      stepperLevel: OnboardingStepperLevel.CONTACTS,
      trackingName: OnboardingStepTrackingName.STEP_1,
      guideStepType: GuideStepType.MANDATORY,
      guideStepName: OnboardingGuideName.ONBOARDING_GUIDE,
      isChecked: false,
      isCombineSavings: false
    });
    onboardingDetails.push({
      level: 'CONTACT_DATA',
      showStepper: true,
      stepperProgress: 50,
      showLogo: false,
      showBack: false,
      showJumpover: false,
      showClose: true,
      note: undefined,
      showLanguage: false,
      nextLevel: 'CONSENT',
      previousLevel: 'STEP_CONTACT',
      introductionPage: false,
      stepperLevel: OnboardingStepperLevel.CONTACTS,
      trackingName: OnboardingStepTrackingName.CONTACT_DATA,
      guideStepType: GuideStepType.MANDATORY,
      guideStepName: OnboardingGuideName.REGISTRATION_GUIDE,
      isChecked: false,
      isCombineSavings: false
    });
    onboardingDetails.push({
      level: 'CONSENT',
      showStepper: true,
      stepperProgress: 50,
      showLogo: false,
      showBack: true,
      showJumpover: true,
      showClose: true,
      note: undefined,
      showLanguage: false,
      nextLevel: 'STEP_FINANCES',
      previousLevel: 'CONTACT_DATA',
      introductionPage: false,
      stepperLevel: OnboardingStepperLevel.CONTACTS,
      trackingName: OnboardingStepTrackingName.CONSENT,
      guideStepType: GuideStepType.MANDATORY,
      guideStepName: OnboardingGuideName.REGISTRATION_GUIDE,
      isChecked: false,
      isCombineSavings: false
    });
    onboardingDetails.push({
      level: 'STEP_FINANCES',
      showStepper: false,
      stepperProgress: 0,
      showLogo: true,
      showBack: false,
      showJumpover: false,
      showClose: true,
      note: undefined,
      showLanguage: false,
      nextLevel: 'PI_DATA',
      previousLevel: 'CONSENT',
      introductionPage: true,
      stepperLevel: OnboardingStepperLevel.FINANCES,
      trackingName: OnboardingStepTrackingName.STEP_2,
      guideStepType: GuideStepType.MANDATORY,
      guideStepName: OnboardingGuideName.ONBOARDING_GUIDE,
      isChecked: false,
      isCombineSavings: false
    });
    onboardingDetails.push({
      level: 'PI_DATA',
      showStepper: true,
      stepperProgress: 25,
      showLogo: false,
      showBack: false,
      showJumpover: false,
      showClose: true,
      note: undefined,
      showLanguage: false,
      nextLevel: 'EXTERNAL_TRANSFER',
      previousLevel: 'STEP_FINANCES',
      introductionPage: false,
      stepperLevel: OnboardingStepperLevel.FINANCES,
      trackingName: OnboardingStepTrackingName.PI_DATA,
      guideStepType: GuideStepType.MANDATORY,
      guideStepName: OnboardingGuideName.REGISTRATION_GUIDE,
      isChecked: false,
      isCombineSavings: false
    });
    onboardingDetails.push({
      level: 'EXTERNAL_TRANSFER',
      showStepper: true,
      stepperProgress: 0,
      showLogo: false,
      showBack: false,
      showJumpover: false,
      showClose: true,
      note: '',
      showLanguage: false,
      nextLevel: 'EXTERNAL_TRANSFER_INTRO',
      previousLevel: 'PI_DATA',
      introductionPage: false,
      stepperLevel: OnboardingStepperLevel.FINANCES,
      trackingName: OnboardingStepTrackingName.EXTERNAL_TRANSFER,
      guideStepType: GuideStepType.MANDATORY,
      guideStepName: OnboardingGuideName.REGISTRATION_GUIDE,
      isChecked: false,
      isCombineSavings: true
    });
    onboardingDetails.push({
      level: 'EXTERNAL_TRANSFER_INTRO',
      showStepper: true,
      stepperProgress: 15,
      showLogo: false,
      showBack: false,
      showJumpover: false,
      showClose: true,
      note: '',
      showLanguage: false,
      nextLevel: 'EXTERNAL_TRANSFER_INTRO',
      previousLevel: 'EXTERNAL_TRANSFER',
      introductionPage: true,
      stepperLevel: OnboardingStepperLevel.FINANCES,
      trackingName: OnboardingStepTrackingName.EXTERNAL_TRANSFER_INTRO,
      guideStepType: GuideStepType.MANDATORY,
      guideStepName: OnboardingGuideName.REGISTRATION_GUIDE,
      isChecked: false,
      isCombineSavings: true
    });
    onboardingDetails.push({
      level: 'EXTERNAL_TRANSFER_ACCEPT',
      showStepper: true,
      stepperProgress: 25,
      showLogo: false,
      showBack: true,
      showJumpover: false,
      showClose: true,
      note: 'DL.VK01.C54',
      showLanguage: false,
      nextLevel: 'EXTERNAL_TRANSFER_ACCEPT',
      previousLevel: 'EXTERNAL_TRANSFER_INTRO',
      introductionPage: true,
      stepperLevel: OnboardingStepperLevel.FINANCES,
      trackingName: OnboardingStepTrackingName.EXTERNAL_TRANSFER_SELECT_SAVINGS,
      guideStepType: GuideStepType.MANDATORY,
      guideStepName: OnboardingGuideName.REGISTRATION_GUIDE,
      isChecked: false,
      isCombineSavings: true
    });
    onboardingDetails.push({
      level: 'EXTERNAL_TRANSFER_LEAD',
      showStepper: true,
      stepperProgress: 25,
      showLogo: false,
      showBack: true,
      showJumpover: false,
      showClose: true,
      note: '',
      showLanguage: false,
      nextLevel: 'EXTERNAL_TRANSFER_LEAD',
      previousLevel: 'EXTERNAL_TRANSFER_INTRO',
      introductionPage: true,
      stepperLevel: OnboardingStepperLevel.FINANCES,
      trackingName: OnboardingStepTrackingName.EXTERNAL_TRANSFER_SELECT_LEAD,
      guideStepType: GuideStepType.MANDATORY,
      guideStepName: OnboardingGuideName.REGISTRATION_GUIDE,
      isChecked: false,
      isCombineSavings: true
    });
    onboardingDetails.push({
      level: 'EXTERNAL_TRANSFER_CONSENT',
      showStepper: true,
      stepperProgress: 0,
      showLogo: false,
      showBack: true,
      showJumpover: true,
      showClose: true,
      note: '',
      showLanguage: false,
      nextLevel: 'EXTERNAL_TRANSFER_CONSENT',
      previousLevel: 'EXTERNAL_TRANSFER_ACCEPT',
      introductionPage: true,
      stepperLevel: OnboardingStepperLevel.FINANCES,
      trackingName: OnboardingStepTrackingName.EXTERNAL_TRANSFER_SELECT_CONSENT,
      guideStepType: GuideStepType.MANDATORY,
      guideStepName: OnboardingGuideName.REGISTRATION_GUIDE,
      isChecked: false,
      isCombineSavings: true
    });
    onboardingDetails.push({
      level: 'EXTERNAL_TRANSFER_SUMMARY',
      showStepper: true,
      stepperProgress: 35,
      showLogo: false,
      showBack: false,
      showJumpover: false,
      showClose: true,
      note: '',
      showLanguage: false,
      nextLevel: 'STEP_INVESTMENT',
      previousLevel: 'EXTERNAL_TRANSFER_ACCEPT',
      introductionPage: true,
      stepperLevel: OnboardingStepperLevel.FINANCES,
      trackingName: OnboardingStepTrackingName.EXTERNAL_TRANSFER_RECEIPT,
      guideStepType: GuideStepType.MANDATORY,
      guideStepName: OnboardingGuideName.REGISTRATION_GUIDE,
      isChecked: false,
      isCombineSavings: true
    });
    onboardingDetails.push({
      level: 'STEP_INVESTMENT',
      showStepper: false,
      stepperProgress: 0,
      showLogo: true,
      showBack: false,
      showJumpover: false,
      showClose: true,
      note: undefined,
      showLanguage: false,
      nextLevel: 'INVESTMENT',
      previousLevel: 'EXTERNAL_TRANSFER_SUMMARY',
      introductionPage: true,
      stepperLevel: OnboardingStepperLevel.INVESTMENT,
      trackingName: OnboardingStepTrackingName.STEP_3,
      guideStepType: GuideStepType.MANDATORY,
      guideStepName: OnboardingGuideName.ONBOARDING_GUIDE,
      isChecked: false,
      isCombineSavings: false
    });
    onboardingDetails.push({
      level: 'INVESTMENT',
      showStepper: true,
      stepperProgress: 100,
      showLogo: false,
      showBack: false,
      showJumpover: false,
      showClose: true,
      note: undefined,
      showLanguage: false,
      nextLevel: 'STEP_INSURANCE',
      previousLevel: 'STEP_INVESTMENT',
      introductionPage: true,
      stepperLevel: OnboardingStepperLevel.INVESTMENT,
      trackingName: OnboardingStepTrackingName.INVESTMENT,
      guideStepType: GuideStepType.MANDATORY,
      guideStepName: OnboardingGuideName.ONBOARDING_GUIDE,
      isChecked: false,
      isCombineSavings: false
    });
    onboardingDetails.push({
      level: 'STEP_INSURANCE',
      showStepper: false,
      stepperProgress: 0,
      showLogo: true,
      showBack: false,
      showJumpover: false,
      showClose: true,
      note: undefined,
      showLanguage: false,
      nextLevel: 'INSURANCE',
      previousLevel: 'INVESTMENT',
      introductionPage: true,
      stepperLevel: OnboardingStepperLevel.INSURANCE,
      trackingName: OnboardingStepTrackingName.STEP_4,
      guideStepType: GuideStepType.MANDATORY,
      guideStepName: OnboardingGuideName.ONBOARDING_GUIDE,
      isChecked: false,
      isCombineSavings: false
    });
    onboardingDetails.push({
      level: 'INSURANCE',
      showStepper: true,
      stepperProgress: 100,
      showLogo: false,
      showBack: false,
      showJumpover: false,
      showClose: true,
      note: undefined,
      showLanguage: false,
      nextLevel: 'STEP_END',
      previousLevel: 'STEP_INSURANCE',
      introductionPage: true,
      stepperLevel: OnboardingStepperLevel.INSURANCE,
      trackingName: OnboardingStepTrackingName.INSURANCE,
      guideStepType: GuideStepType.MANDATORY,
      guideStepName: OnboardingGuideName.ONBOARDING_GUIDE,
      isChecked: false,
      isCombineSavings: false
    });
    onboardingDetails.push({
      level: 'STEP_END',
      showStepper: false,
      stepperProgress: 0,
      showLogo: true,
      showBack: false,
      showJumpover: false,
      showClose: true,
      note: undefined,
      showLanguage: false,
      nextLevel: 'DONE',
      previousLevel: 'INSURANCE',
      introductionPage: true,
      stepperLevel: OnboardingStepperLevel.CONTACTS,
      trackingName: OnboardingStepTrackingName.STEP_END,
      guideStepType: GuideStepType.MANDATORY,
      guideStepName: OnboardingGuideName.ONBOARDING_GUIDE,
      isChecked: false,
      isCombineSavings: false
    });
    onboardingDetails.push({
      level: 'DONE',
      showStepper: false,
      stepperProgress: 0,
      showLogo: false,
      showBack: false,
      showJumpover: false,
      showClose: true,
      note: undefined,
      showLanguage: false,
      nextLevel: 'PI_DATA',
      previousLevel: 'CONTACT_DATA',
      introductionPage: true,
      stepperLevel: OnboardingStepperLevel.CONTACTS,
      trackingName: OnboardingStepTrackingName.DONE,
      guideStepType: GuideStepType.MANDATORY,
      guideStepName: OnboardingGuideName.ONBOARDING_GUIDE,
      isChecked: false,
      isCombineSavings: false
    });
    return onboardingDetails;
  }

  public setupSteps(showInsurance: boolean, showInvestment: boolean): void {
    this._progressSteps = {
      contacts: {
        label: 'DL.VK01.C01',
        link: '',
        id: OnboardingStepperLevel.CONTACTS,
        progress: 0
      },
      finances: {
        label: 'DL.VK01.C02',
        link: '',
        id: OnboardingStepperLevel.FINANCES,
        progress: 0
      }
    };
    if (showInvestment) {
      this._progressSteps = Object.assign(this._progressSteps, {
        investment: {
          label: 'DL.VK01.C04',
          link: '',
          id: OnboardingStepperLevel.INVESTMENT,
          progress: 0
        }
      });
    }
    if (showInsurance) {
      this._progressSteps = Object.assign(this._progressSteps, {
        insurance: {
          label: 'DL.VK01.C03',
          link: '',
          id: OnboardingStepperLevel.INSURANCE,
          progress: 0
        }
      });
    }
  }

  get levels(): OnboardingLevel[] {
    return this._levels;
  }

  get progressSteps(): { [p: string]: Step } {
    return this._progressSteps;
  }
}

export enum OnboardingStepperLevel {
  CONTACTS = 'contacts',
  FINANCES = 'finances',
  INVESTMENT = 'investment',
  INSURANCE = 'insurance'
}

export enum OnboardingGuideName {
  ONBOARDING_GUIDE = 'onboarding guide',
  REGISTRATION_GUIDE = 'registration guide'
}

export type OnboardingLevelStep =
  | 'WELCOME'
  | 'INTRODUCTION'
  | 'STEP_CONTACT'
  | 'CONSENT'
  | 'CONTACT_DATA'
  | 'STEP_FINANCES'
  | 'STEP_INVESTMENT'
  | 'STEP_INSURANCE'
  | 'STEP_END'
  | 'PI_DATA'
  | 'EXTERNAL_TRANSFER_INTRO'
  | 'EXTERNAL_TRANSFER'
  | 'EXTERNAL_TRANSFER_ACCEPT'
  | 'EXTERNAL_TRANSFER_CONSENT'
  | 'EXTERNAL_TRANSFER_LEAD'
  | 'EXTERNAL_TRANSFER_SUMMARY'
  | 'INSURANCE'
  | 'INVESTMENT'
  | 'DONE';

export interface OnboardingLevel {
  stepperLevel: OnboardingStepperLevel;
  stepperProgress: number;
  level: OnboardingLevelStep;
  showStepper: boolean;
  showLogo: boolean;
  showBack: boolean;
  showJumpover: boolean;
  showClose: boolean;
  note: string;
  showLanguage: boolean;
  introductionPage: boolean;
  nextLevel: OnboardingLevelStep;
  previousLevel: OnboardingLevelStep;
  trackingName: OnboardingStepTrackingName;
  guideStepType: GuideStepType;
  guideStepName: GuideTrackingId;
  isChecked: boolean;
  isCombineSavings: boolean;
}

export enum OnboardingWelcomeTrackingName {
  WELCOME = 'ONB - Welcome',
  RESUME = 'ONB - Resume'
}

export enum OnboardingStepTrackingName {
  INTRODUCTION = 'ONB start',
  INTRODUCTION_CAROUSEL = 'ONB introduction',
  STEP_1 = 'ONB step 1: RG',
  CONTACT_DATA = 'RG contact information',
  CONSENT = 'RG consent',
  STEP_2 = 'ONB step 2: RG CYS',
  STEP_3 = 'ONB step 3: IG',
  STEP_3_IG = 'ONB step 3: IG ONLY',
  STEP_3_ISG = 'ONB step 3: ISG ONLY',
  STEP_4 = 'ONB step 4: ISG',
  STEP_END = 'ONB step end',
  PI_DATA = 'RG pensionsinfo',
  EXTERNAL_TRANSFER_INTRO = 'RG CYS introduction',
  EXTERNAL_TRANSFER = 'RG CYS',
  EXTERNAL_TRANSFER_SELECT_SAVINGS = 'RG CYS select savings',
  EXTERNAL_TRANSFER_SELECT_LEAD = 'RG CYS lead',
  EXTERNAL_TRANSFER_SELECT_CONSENT = 'RG CYS accept consent',
  EXTERNAL_TRANSFER_RECEIPT = 'RG CYS receipt',
  INVESTMENT = 'ONB investment',
  INSURANCE = 'ONB insurance',
  DONE = 'ONB guide completed'
}
