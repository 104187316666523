/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PhoneDetails } from './phoneDetails';
import { PhoneDetailsSchema } from './phoneDetails';
import { DigitalAccessOption } from './digitalAccessOption';
import { DigitalAccessOptionSchema } from './digitalAccessOption';
import * as JoiObj from "joi";


    export const DigitalAccessOptionsSchema = JoiObj.object({
        email:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        emailValid:
            
                    JoiObj.boolean()
                

.allow(null)



        ,
        phoneDetails:
            
        JoiObj.link('#PhoneDetailsSchema')

.allow(null)



        ,
        options:
                JoiObj.array().items(
        
        JoiObj.link('#DigitalAccessOptionSchema')

        )
        

.required()


        
    })
                .shared(PhoneDetailsSchema)
                .shared(DigitalAccessOptionSchema)
.id('DigitalAccessOptionsSchema')

export interface DigitalAccessOptions { 
    email?: string;
    emailValid?: boolean;
    phoneDetails?: PhoneDetails;
    options: Array<DigitalAccessOption>;
}

