<form novalidate [formGroup]="form" *ngIf="initialized">
  <co-card-split-horizontal
    [simple]="true"
    [middle]="true"
    class="header-fill-all body-fill-all"
  >
    <div header class="top-section">
      <div class="first">
        <div class="simple-coverage">
          <div class="simple-coverage-top">
            <p class="text--bold" coContent="DL.TF01.C179"></p>
            <div class="today" [ngClass]="{ 'without-number': !isManual }">
              <co-primary-number
                *ngIf="isManual"
                [amount]="
                  inputConfig.regularPayout.amountOriginal | numberFormat
                "
                [unit]="inputConfig.regularPayout.unit | content"
              ></co-primary-number>
              <ng-container *ngIf="!isManual">
                <h4 coContent="DL.TF01.C178" class="tag-header"></h4>
                <div
                  *ngFor="let tag of tags"
                  [ngClass]="{ 'tag-tooltip, tag-tooltip--tight': tag.tooltip }"
                >
                  <span [coContent]="tag.text"></span>
                  <co-icon-info
                    *ngIf="tag.tooltip"
                    matTooltip="{{ tag.tooltip | content }}"
                  ></co-icon-info>
                </div>
              </ng-container>
              <div>
                <div
                  *ngIf="!isManual"
                  class="text--note spacing-unit--half-over"
                  [coContent]="'DL.TF01.C177'"
                  [coContentSubst]="
                    inputConfig.regularPayout.amountOriginal | numberFormat
                  "
                ></div>
                <div
                  *ngIf="inputConfig.regularPayout.noteBeforeTaxOriginal"
                  class="text--note"
                >
                  {{ inputConfig.regularPayout.noteBeforeTaxOriginal }}
                </div>
                <div
                  *ngIf="inputConfig.regularPayout.noteAfterTaxOriginal"
                  class="text--note"
                >
                  {{ inputConfig.regularPayout.noteAfterTaxOriginal }}
                </div>
                <div
                  *ngIf="
                    inputConfig.regularPayout.noteAfterTaxOriginal ||
                    inputConfig.regularPayout.noteBeforeTaxOriginal
                  "
                  class="text--note"
                  [coContent]="'DL.TF01.C196'"
                  [coContentSubst]="inputConfig.regularPayout.payoutStopAge"
                ></div>
                <div
                  *ngIf="inputConfig.regularPayout.notePriceOriginal"
                  class="text--bold"
                >
                  {{ inputConfig.regularPayout.notePriceOriginal }}
                </div>
                <div
                  class="tag tag-secondary"
                  *ngIf="
                    isManual && !inputConfig.regularPayout.special && locAllowed
                  "
                  coContent="DL.TF01.C180"
                ></div>
              </div>
            </div>
          </div>
          <co-confirm-choice
            *ngIf="!hideActions"
            [simple]="true"
            [confirmedDate]="confirmChoice?.loc.confirmedDate"
            [error]="confirmChoice.loc.error"
            (confirmed)="onConfirmChoice()"
            [success]="confirmChoice?.loc.success"
            [texts]="confirmChoiceComponentTexts"
          >
          </co-confirm-choice>
        </div>
      </div>

      <div>
        <div
          *ngIf="
            insuranceGuideChecks.guideTooOld || insuranceGuideChecks.noGuide
          "
        >
          <p coContent="DL.TF01.C191" class="text--bold color--secondary"></p>
          <co-icon-with-text>
            <co-icon-doubt icon></co-icon-doubt>
            <div body coContent="DL.FG01.C1466"></div>
          </co-icon-with-text>
        </div>
        <ng-container
          *ngIf="
            !insuranceGuideChecks.guideTooOld && !insuranceGuideChecks.noGuide
          "
        >
          <ng-container
            *ngIf="!inputConfig.regularPayout.special && !locAllowed"
          >
            <div class="simple-coverage">
              <div class="simple-coverage-top">
                <p
                  coContent="DL.TF01.C191"
                  class="text--bold color--secondary"
                ></p>
                <div
                  *ngIf="
                    overrideRecommendationKeepCurrent ||
                    (inputConfig.regularPayout.amountOriginal
                      | numberFormat) ===
                      (inputConfig.recommendation | numberFormat)
                  "
                  coContent="DL.TF01.C193"
                  [coContentSubst]="
                    inputConfig.regularPayout.amountOriginal | numberFormat
                  "
                ></div>
                <div
                  *ngIf="
                    !overrideRecommendationKeepCurrent &&
                    (inputConfig.regularPayout.amountOriginal
                      | numberFormat) !==
                      (inputConfig.recommendation | numberFormat)
                  "
                  coContent="DL.TF01.C190"
                  [coContentSubst]="inputConfig.recommendation | numberFormat"
                ></div>
              </div>
              <div class="simple-coverage-bottom">
                <button
                  *ngIf="
                    !hideActions &&
                    inputConfig.showRecommendationButton &&
                    !pricesUpdated
                  "
                  mat-raised-button
                  class="button-secondary"
                  coContent="DL.TF01.C153"
                  (click)="
                    setRecommendation(
                      inputConfig.regularPayout.type,
                      form.get('regularPayout')
                    )
                  "
                ></button>

                <div
                  class="simple-coverage-bottom"
                  *ngIf="
                    pricesUpdated &&
                    !inputConfig.regularPayout.updateFailed &&
                    ((specialSelected &&
                      specialCalculatedFor === specialSelectedValue) ||
                      (!specialSelected &&
                        locCalculatedFor === selectedCoverageOption?.value))
                  "
                >
                  <co-alert class="spacing-unit--over">
                    <span
                      coContent="DL.TF01.C211"
                      *ngIf="!inputConfig.followsRecommendation"
                    ></span>
                    <span
                      coContent="DL.TF01.C210"
                      *ngIf="inputConfig.followsRecommendation"
                    ></span>
                  </co-alert>
                </div>
              </div>
            </div>
          </ng-container>
          <div class="simple-coverage">
            <div class="simple-coverage-top">
              <div
                *ngIf="inputConfig.regularPayout.special || locAllowed"
                coContent="DL.TF01.C182"
                class="text--bold color--primary"
              ></div>

              <mat-radio-group
                class="tae-selector"
                formControlName="coverage"
                *ngIf="inputConfig.regularPayout.special"
              >
                <span
                  [ngClass]="{
                    'recommended-coverage': inputConfig.recommendation === 80
                  }"
                >
                  <div
                    *ngIf="inputConfig.recommendation === 80"
                    class="recommended-coverage-text"
                    coContent="DL.TF01.C186"
                  ></div>
                  <mat-radio-button
                    [value]="inputConfig.regularPayout.amountMin"
                    (change)="
                      updateRadio(
                        inputConfig.regularPayout.amountMin,
                        form.get('coverage')
                      )
                    "
                    [disabled]="
                      inputConfig.regularPayout.amountMin ===
                      inputConfig.regularPayout.amountOriginal
                    "
                  >
                    <span
                      [coContent]="'DL.TF01.C92'"
                      [coContentSubst]="inputConfig.regularPayout.amountMin"
                      >Vælg x%</span
                    >
                  </mat-radio-button>
                  <div
                    *ngIf="
                      inputConfig.regularPayout.amountMin ===
                      inputConfig.regularPayout.amountOriginal
                    "
                    class="current-choice"
                    coContent="DL.TF01.C192"
                  >
                    Dit valg i dag
                  </div>
                </span>
                <span
                  [ngClass]="{
                    'recommended-coverage': inputConfig.recommendation === 87
                  }"
                >
                  <div
                    *ngIf="inputConfig.recommendation === 87"
                    class="recommended-coverage-text"
                    coContent="DL.TF01.C186"
                  ></div>
                  <mat-radio-button
                    [value]="inputConfig.regularPayout.amountMax"
                    (change)="
                      updateRadio(
                        inputConfig.regularPayout.amountMax,
                        form.get('coverage')
                      )
                    "
                    [disabled]="
                      inputConfig.regularPayout.amountMax ===
                      inputConfig.regularPayout.amountOriginal
                    "
                  >
                    <span
                      [coContent]="'DL.TF01.C92'"
                      [coContentSubst]="inputConfig.regularPayout.amountMax"
                      >Vælg x%</span
                    >
                  </mat-radio-button>
                  <div
                    *ngIf="
                      inputConfig.regularPayout.amountMax ===
                      inputConfig.regularPayout.amountOriginal
                    "
                    class="current-choice"
                    coContent="DL.TF01.C192"
                  >
                    Dit valg i dag
                  </div>
                </span>
              </mat-radio-group>

              <mat-radio-group
                [formControlName]="'coverage'"
                class="tae-selector"
                *ngIf="!inputConfig.regularPayout.special && locAllowed"
              >
                <span
                  *ngIf="coverageOptions.LOC_80.show"
                  [ngClass]="{
                    'recommended-coverage': coverageOptions.LOC_80.recommended
                  }"
                >
                  <div
                    *ngIf="coverageOptions.LOC_80.recommended"
                    class="recommended-coverage-text"
                    coContent="DL.TF01.C186"
                  ></div>
                  <mat-radio-button
                    [value]="coverageOptions.LOC_80.value"
                    (click)="!isLoC80 && choseCoverage(coverageOptions.LOC_80)"
                    [disabled]="isLoC80"
                  >
                    <span
                      coContent="DL.TF01.C184"
                      [coContentSubst]="'80'"
                    ></span>
                  </mat-radio-button>
                  <div
                    *ngIf="isLoC80"
                    class="current-choice"
                    coContent="DL.TF01.C192"
                  >
                    Dit valg i dag
                  </div>
                </span>
                <span
                  [ngClass]="{
                    'recommended-coverage': coverageOptions.LOC_100.recommended
                  }"
                  *ngIf="coverageOptions.LOC_100.show"
                >
                  <div
                    *ngIf="coverageOptions.LOC_100.recommended"
                    class="recommended-coverage-text"
                    coContent="DL.TF01.C186"
                  ></div>
                  <mat-radio-button
                    [value]="coverageOptions.LOC_100.value"
                    (click)="
                      !isLoC100 && choseCoverage(coverageOptions.LOC_100)
                    "
                    [disabled]="isLoC100"
                  >
                    <span
                      coContent="DL.TF01.C184"
                      [coContentSubst]="'100'"
                    ></span>
                  </mat-radio-button>
                  <div
                    *ngIf="isLoC100"
                    class="current-choice"
                    coContent="DL.TF01.C192"
                  >
                    Dit valg i dag
                  </div>
                </span>
                <span
                  [ngClass]="{
                    'recommended-coverage': coverageOptions.OPT_OUT.recommended
                  }"
                  *ngIf="coverageOptions.OPT_OUT.show"
                >
                  <div
                    *ngIf="coverageOptions.OPT_OUT.recommended"
                    class="recommended-coverage-text"
                  >
                    <span coContent="DL.TF01.C186"></span>
                    <span
                      *ngIf="!overrideRecommendationKeepCurrent"
                      coContent="DL.TF01.C189"
                      [coContentSubst]="inputConfig.recommendation"
                    ></span>
                  </div>
                  <mat-radio-button
                    [value]="coverageOptions.OPT_OUT.value"
                    (click)="choseCoverage(coverageOptions.OPT_OUT)"
                  >
                    <span
                      *ngIf="
                        inputConfig.regularPayout.locScaleInformation
                          .current !== 'MANUAL'
                      "
                      coContent="DL.TF01.C185"
                    ></span>
                    <span
                      *ngIf="
                        inputConfig.regularPayout.locScaleInformation
                          .current === 'MANUAL'
                      "
                      coContent="DL.TF01.C194"
                    ></span>
                  </mat-radio-button>
                  <div
                    *ngIf="isManual"
                    class="current-choice"
                    coContent="DL.TF01.C192"
                  >
                    Dit valg i dag
                  </div>
                </span>
              </mat-radio-group>
            </div>
            <div *ngIf="!pricesUpdated">
              <button
                *ngIf="
                  inputConfig.regularPayout.special &&
                  !locAllowed &&
                  !hideActions &&
                  inputConfig.showRecommendationButton
                "
                mat-raised-button
                class="button-secondary"
                coContent="DL.TF01.C153"
                (click)="
                  setRecommendationSpecial(
                    inputConfig.regularPayout.type,
                    form.get('regularPayout')
                  )
                "
              ></button>
              <button
                *ngIf="
                  !inputConfig.regularPayout.special &&
                  locAllowed &&
                  !hideActions &&
                  inputConfig.showRecommendationButton
                "
                mat-raised-button
                class="button-secondary"
                coContent="DL.TF01.C153"
                (click)="
                  setRecommendationAT(
                    inputConfig.regularPayout.type,
                    form.get('regularPayout')
                  )
                "
              ></button>
            </div>

            <div
              *ngIf="
                (inputConfig.regularPayout.special || locAllowed) &&
                pricesUpdated &&
                !inputConfig.regularPayout.updateFailed &&
                ((specialSelected &&
                  specialCalculatedFor === specialSelectedValue) ||
                  (!specialSelected &&
                    locCalculatedFor === selectedCoverageOption?.value))
              "
            >
              <co-alert class="spacing-unit--over">
                <span
                  coContent="DL.TF01.C211"
                  *ngIf="!inputConfig.followsRecommendation"
                ></span>
                <span
                  coContent="DL.TF01.C210"
                  *ngIf="inputConfig.followsRecommendation"
                ></span>
              </co-alert>
            </div>
          </div>
        </ng-container>
      </div>

      <div>
        <ng-container
          *ngIf="
            !insuranceGuideChecks.guideTooOld && !insuranceGuideChecks.noGuide
          "
        >
          <div class="spacing-unit--half-under">
            <span
              coContent="DL.TF01.C183"
              class="text--bold color--primary"
            ></span>
            <co-tooltip
              class="header-tooltip"
              *ngIf="
                selectedCoverageOption?.value === 'MANUAL' &&
                inputConfig.regularPayout.tooltipContentId
              "
              [text]="inputConfig.regularPayout.tooltipContentId"
            ></co-tooltip>
          </div>
          <div class="new-coverage">
            <div class="new-coverage-top">
              <div
                coContent="DL.TF01.C187"
                *ngIf="!selectedCoverageOption && !specialSelected"
              ></div>

              <ng-container *ngIf="selectedCoverageOption || specialSelected">
                <ng-container *ngIf="specialSelected">
                  <co-primary-number
                    [amount]="specialSelectedValue"
                    [unit]="inputConfig.regularPayout.unit | content"
                  ></co-primary-number>
                </ng-container>
                <ng-container
                  *ngIf="
                    !specialSelected &&
                    selectedCoverageOption.value === 'MANUAL'
                  "
                >
                  <mat-form-field
                    *ngIf="!inputConfig.regularPayout.special"
                    color="accent"
                  >
                    <mat-label
                      [coContent]="inputConfig.regularPayout.labelContentId"
                    ></mat-label>
                    <input
                      class="amount-input"
                      matInput
                      coAmount
                      formControlName="regularPayout"
                      [coAmountUnit]="inputConfig.regularPayout.unit | content"
                      (blur)="
                        checkForEmpty(
                          form.get('regularPayout'),
                          inputConfig.regularPayout.amountMin
                        )
                      "
                    />
                    <mat-hint
                      align="end"
                      *ngIf="
                        !inputConfig.regularPayout.special &&
                        inputConfig.regularPayout.noteContentId
                      "
                    >
                      <div
                        [coContent]="inputConfig.regularPayout.noteContentId"
                        [coContentSubst]="
                          (inputConfig.regularPayout.amountMin | numberFormat) +
                          '|' +
                          (inputConfig.regularPayout.amountMax | numberFormat)
                        "
                      ></div>
                    </mat-hint>
                    <mat-error
                      *ngIf="form.get('regularPayout').errors?.numberFormat"
                      [coContent]="'DL.MD01.C13'"
                      align="end"
                    ></mat-error>
                    <mat-error
                      *ngIf="
                        !form.get('regularPayout').errors?.numberFormat &&
                        form.get('regularPayout').errors?.min
                      "
                      [coContent]="'DL.MD01.C21a'"
                      [coContentSubst]="
                        (inputConfig.regularPayout.amountMin | numberFormat) +
                        '|' +
                        (inputConfig.regularPayout.unit | content)
                      "
                      align="end"
                    ></mat-error>
                    <mat-error
                      *ngIf="
                        !form.get('regularPayout').errors?.numberFormat &&
                        form.get('regularPayout').errors?.max
                      "
                      [coContent]="'DL.MD01.C19a'"
                      [coContentSubst]="
                        (inputConfig.regularPayout.amountMax | numberFormat) +
                        '|' +
                        (inputConfig.regularPayout.unit | content)
                      "
                      align="end"
                    ></mat-error>
                  </mat-form-field>
                </ng-container>
                <ng-container
                  *ngIf="
                    !specialSelected &&
                    selectedCoverageOption.value !== 'MANUAL'
                  "
                >
                  <h4 coContent="DL.TF01.C178" class="tag-header"></h4>
                  <div class="tag-tooltip tag-tooltip--tight">
                    <span [coContent]="selectedCoverageTagText"></span>
                    <co-icon-info
                      *ngIf="selectedCoverageTagTooltip"
                      matTooltip="{{ selectedCoverageTagTooltip }}"
                    ></co-icon-info>
                  </div>
                </ng-container>

                <ng-container
                  *ngIf="
                    pricesUpdated &&
                    !inputConfig.regularPayout.updateFailed &&
                    ((specialSelected &&
                      specialCalculatedFor === specialSelectedValue) ||
                      (!specialSelected &&
                        locCalculatedFor === selectedCoverageOption.value))
                  "
                >
                  <div
                    *ngIf="locCalculatedFor && locCalculatedFor !== 'MANUAL'"
                    class="text--note spacing-unit--half-over"
                    [coContent]="'DL.TF01.C177'"
                    [coContentSubst]="
                      inputConfig.regularPayout.amount | numberFormat
                    "
                  ></div>
                  <div
                    *ngIf="inputConfig.regularPayout.noteBeforeTax"
                    class="text--note"
                  >
                    {{ inputConfig.regularPayout.noteBeforeTax }}
                  </div>
                  <div
                    *ngIf="inputConfig.regularPayout.noteAfterTax"
                    class="text--note"
                  >
                    {{ inputConfig.regularPayout.noteAfterTax }}
                  </div>
                  <div
                    *ngIf="
                      inputConfig.regularPayout.noteAfterTax ||
                      inputConfig.regularPayout.noteBeforeTax
                    "
                    class="text--note"
                    [coContent]="'DL.TF01.C196'"
                    [coContentSubst]="inputConfig.regularPayout.payoutStopAge"
                  ></div>

                  <div
                    *ngIf="inputConfig.regularPayout.notePrice"
                    class="text--bold"
                  >
                    {{ inputConfig.regularPayout.notePrice }}
                  </div>
                </ng-container>
              </ng-container>
            </div>
            <div class="new-coverage-bottom">
              <button
                mat-raised-button
                [disabled]="isDisabled('regularPayout')"
                (click)="
                  update(
                    inputConfig.regularPayout.type,
                    form.get('regularPayout')
                  )
                "
                coContent="DL.TF01.C188"
              ></button>
              <co-warning *ngIf="inputConfig.regularPayout.updateFailed">
                <span [coContent]="'DL.TF01.C37'"></span>
              </co-warning>
            </div>
          </div>
        </ng-container>
      </div>
    </div>

    <div middle class="middle-section" *ngIf="showWarningSection">
      <co-info
        class="spacing-unit--over"
        *ngIf="inputConfig.regularPayout.warningSpecialCoverage"
      >
        <span>{{ inputConfig.regularPayout.warningSpecialCoverage }}</span>
      </co-info>
      <co-info
        class="spacing-unit--over"
        *ngIf="inputConfig.regularPayout.warning80Percent"
      >
        <span>{{ inputConfig.regularPayout.warning80Percent }}</span>
      </co-info>
      <co-info
        class="spacing-unit--over"
        *ngIf="inputConfig.regularPayout.warningRecommendLow"
      >
        <span>{{ inputConfig.regularPayout.warningRecommendLow }}</span>
      </co-info>
      <co-info
        class="spacing-unit--over"
        *ngIf="
          inputConfig.regularPayout.special &&
          inputConfig.regularPayout.amount !==
            inputConfig.regularPayout.amountMin &&
          inputConfig.regularPayout.amount !==
            inputConfig.regularPayout.amountMax
        "
      >
        <span [coContent]="'DL.TF01.C94'"></span>
      </co-info>
      <div
        class="spacing-unit--over"
        *ngIf="inputConfig.regularPayout.special"
        [coContent]="'DL.TF01.C93'"
      ></div>
      <div class="spacing-unit--over"></div>
    </div>

    <div body class="bottom-section">
      <ng-container *ngIf="inputConfig.lumpSum">
        <div (click)="expanded = !expanded" class="accordion-header">
          <div class="text--bold" coContent="DL.TF01.C200">
            Tilføj engangsudbetaling
          </div>
          <div>
            <span *ngIf="!expanded; else arrowUp" class="float--right">
              <co-icon-arrow-down
                class="icon-fill__small--grey"
              ></co-icon-arrow-down>
            </span>
            <ng-template #arrowUp>
              <span class="float--right">
                <co-icon-arrow-up
                  class="icon-fill__small--grey"
                ></co-icon-arrow-up>
              </span>
            </ng-template>
          </div>
        </div>
        <div *ngIf="expanded" [@openClose]>
          <div class="row spacing-unit--over">
            <div class="col-12 col-md-12 col-lg-4 col-xl-6">
              <span
                class="color--black text--bold"
                [coContent]="inputConfig.lumpSum.textContentId"
              ></span>
              <co-tooltip
                *ngIf="inputConfig.lumpSum.tooltipContentId"
                [text]="inputConfig.lumpSum.tooltipContentId"
              ></co-tooltip>
              <div
                [coContent]="inputConfig.lumpSum.noteContentId"
                [coContentSubst]="
                  (inputConfig.lumpSum.amountMin | numberFormat) +
                  '|' +
                  (inputConfig.lumpSum.amountMax | numberFormat)
                "
              ></div>
              <co-warning *ngIf="inputConfig.lumpSum.updateFailed">
                <span [coContent]="'DL.TF01.C37'"></span>
              </co-warning>
            </div>
            <div
              class="col-12 col-md-12 col-lg-8 col-xl-6 column-2 column-2-normal"
            >
              <button
                mat-raised-button
                class="button-primary--level3 update-button"
                [style.visibility]="
                  isDisabled('lumpSum') ? 'hidden' : 'visible'
                "
                [disabled]="isDisabled('lumpSum')"
                (click)="update(inputConfig.lumpSum.type, form.get('lumpSum'))"
              >
                <span [coContent]="'Global.Opdater'"></span>
                <co-icon-refresh class="icon"></co-icon-refresh>
              </button>

              <mat-form-field color="accent">
                <mat-label
                  [coContent]="inputConfig.lumpSum.labelContentId"
                ></mat-label>
                <input
                  class="amount-input"
                  matInput
                  coAmount
                  formControlName="lumpSum"
                  [coAmountUnit]="inputConfig.lumpSum.unit | content"
                  (blur)="
                    checkForEmpty(
                      form.get('lumpSum'),
                      inputConfig.lumpSum.amountMin
                    )
                  "
                />
                <mat-hint align="end">
                  <div
                    [coContent]="inputConfig.lumpSum.notePercentAsAmount"
                    *ngIf="inputConfig.lumpSum.notePercentAsAmount"
                    class="note"
                  ></div>
                  <div
                    [coContent]="inputConfig.lumpSum.notePrice"
                    *ngIf="
                      !inputConfig.lumpSum.updateFailed &&
                      inputConfig.lumpSum.notePrice
                    "
                    class="note"
                  ></div>
                </mat-hint>
                <mat-error
                  *ngIf="form.get('lumpSum').errors?.numberFormat"
                  [coContent]="'DL.MD01.C13'"
                  align="end"
                ></mat-error>
                <mat-error
                  *ngIf="
                    !form.get('lumpSum').errors?.numberFormat &&
                    form.get('lumpSum').errors?.min
                  "
                  [coContent]="'DL.MD01.C21a'"
                  [coContentSubst]="
                    (inputConfig.lumpSum.amountMin | numberFormat) +
                    '|' +
                    (inputConfig.lumpSum.unit | content)
                  "
                  align="end"
                ></mat-error>
                <mat-error
                  *ngIf="
                    !form.get('lumpSum').errors?.numberFormat &&
                    form.get('lumpSum').errors?.max
                  "
                  [coContent]="'DL.MD01.C19a'"
                  [coContentSubst]="
                    (inputConfig.lumpSum.amountMax | numberFormat) +
                    '|' +
                    (inputConfig.lumpSum.unit | content)
                  "
                  align="end"
                ></mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>

        <div
          (click)="expandedHealthWarning = !expandedHealthWarning"
          class="accordion-header"
          [ngClass]="{ 'second-accordion': inputConfig.lumpSum }"
        >
          <div class="text--bold" coContent="DL.TF01.C98"></div>
          <div>
            <span
              *ngIf="!expandedHealthWarning; else arrowUp"
              class="float--right"
            >
              <co-icon-arrow-down
                class="icon-fill__small--grey"
              ></co-icon-arrow-down>
            </span>
            <ng-template #arrowUp>
              <span class="float--right">
                <co-icon-arrow-up
                  class="icon-fill__small--grey"
                ></co-icon-arrow-up>
              </span>
            </ng-template>
          </div>
        </div>
        <div *ngIf="expandedHealthWarning" [@openClose]>
          <p [coContent]="'DL.TF01.C99'"></p>
          <p [coContent]="'DL.TF01.C100'"></p>
          <div *ngIf="showAuaConditions">
            <div [coContent]="'DL.TF01.C113'" class="text--bold"></div>
            <p [coContent]="'DL.TF01.C111'"></p>
            <ul class="bullet-list bullet-list-with-tooltip">
              <li>
                <span [coContent]="'DL.TF01.C101'"></span>
                <co-tooltip [text]="'DL.TF01.C102'"></co-tooltip>
              </li>
              <li>
                <span [coContent]="'DL.TF01.C103'"></span>
                <co-tooltip [text]="'DL.TF01.C110'"></co-tooltip>
              </li>
              <li>
                <span [coContent]="'DL.TF01.C104'"></span>
                <co-tooltip [text]="'DL.TF01.C105'"></co-tooltip>
              </li>
              <li>
                <span [coContent]="'DL.TF01.C106'"></span>
                <span class="dummy-icon--vertical-only"></span>
              </li>
              <li>
                <span [coContent]="'DL.TF01.C107'"></span>
                <span class="dummy-icon--vertical-only"></span>
              </li>
              <li>
                <span [coContent]="'DL.TF01.C108'"></span>
                <span class="dummy-icon--vertical-only"></span>
              </li>
              <li>
                <span [coContent]="'DL.TF01.C109'"></span>
                <span class="dummy-icon--vertical-only"></span>
              </li>
            </ul>
          </div>
        </div>
      </ng-container>
    </div>
  </co-card-split-horizontal>
</form>
