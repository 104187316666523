/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DeviceType } from './deviceType';
import { DeviceTypeSchema } from './deviceType';
import { FoundAllYouWanted } from './foundAllYouWanted';
import { FoundAllYouWantedSchema } from './foundAllYouWanted';
import * as JoiObj from "joi";


    export const CustomerSatisfactionSchema = JoiObj.object({
        browser:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        design:
            
                    JoiObj.number()
                .integer()


.required()


        ,
        device:
            
        JoiObj.link('#DeviceTypeSchema')


.required()


        ,
        foundAllYouWanted:
            
        JoiObj.link('#FoundAllYouWantedSchema')


.required()


        ,
        missingInfo:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        navigation:
            
                    JoiObj.number()
                .integer()


.required()


        ,
        purposeForVisitFulfilled:
            
                    JoiObj.boolean()
                


.required()


        ,
        satisfactionLevel:
            
                    JoiObj.number()
                .integer()


.required()


        ,
        smoothness:
            
                    JoiObj.number()
                .integer()


.required()


        ,
        suggestionForImprovement:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        understandable:
            
                    JoiObj.number()
                .integer()


.required()


        
    })
                .shared(DeviceTypeSchema)
                .shared(FoundAllYouWantedSchema)
.id('CustomerSatisfactionSchema')

export interface CustomerSatisfaction { 
    browser: string;
    design: number;
    device: DeviceType;
    foundAllYouWanted: FoundAllYouWanted;
    missingInfo: string;
    navigation: number;
    purposeForVisitFulfilled: boolean;
    satisfactionLevel: number;
    smoothness: number;
    suggestionForImprovement: string;
    understandable: number;
}
export namespace CustomerSatisfaction {
}


