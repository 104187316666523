import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import {
  MatSlideToggle,
  MatSlideToggleModule
} from '@angular/material/slide-toggle';
import { RouterModule } from '@angular/router';
import { CoreModule } from '@pfa/core';
import { PfaFormModule } from '@pfaform';
import { IconModule } from '@pfa/icons';
import { PensionInfoModule } from '@mitpfa/shared/pension-info/pension-info.module';
import { BookingPortalComponent } from './booking-portal.component';

@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    CoreModule,
    IconModule,
    MatButtonModule,
    MatProgressBarModule,
    MatSlideToggleModule,
    MatDialogModule,
    PensionInfoModule,
    PfaFormModule
  ],
  declarations: [BookingPortalComponent],
  exports: [BookingPortalComponent],
  providers: [MatSlideToggle]
})
export class BookingPortalModule {}
