/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { JuridiskType } from './juridiskType';
import { JuridiskTypeSchema } from './juridiskType';
import * as JoiObj from "joi";


    export const JuridiskEnhedsIdSchema = JoiObj.object({
        id:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        type:
            
        JoiObj.link('#JuridiskTypeSchema')


.required()


        
    })
                .shared(JuridiskTypeSchema)
.id('JuridiskEnhedsIdSchema')

export interface JuridiskEnhedsId { 
    id: string;
    type: JuridiskType;
}
export namespace JuridiskEnhedsId {
}


