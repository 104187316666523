import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChatComponent } from './chat.component';
import { IconModule } from '@pfa/icons';

@NgModule({
  imports: [CommonModule, IconModule],
  declarations: [ChatComponent],
  exports: [ChatComponent]
})
export class ChatModule {}
