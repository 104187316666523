import { Injectable } from '@angular/core';
import { CookieService } from '@pfaform';
import { SaveDialogService } from './save-dialog.service';
import { catchError, filter, first, map, switchMap, tap } from 'rxjs/operators';
import { LoginService } from '../login.service';
import { Observable, of } from 'rxjs';
import { ErrorHandlerApiService } from '@pfa/api';
import { CustomerSurveySharedService } from './customer-survey-shared.service';
import { LogoutService } from '@pfa/services';

@Injectable({
  providedIn: 'root'
})
export class LogoutMitpfaService {
  constructor(
    private readonly cookieService: CookieService,
    private readonly customerSurveySharedService: CustomerSurveySharedService,
    private readonly saveDialog: SaveDialogService,
    private readonly loginService: LoginService,
    private readonly logoutService: LogoutService,
    private readonly errorHandlerApiService: ErrorHandlerApiService
  ) {}

  readyToLogout(isAdvisor: boolean, surveyUuid: string): Observable<boolean> {
    const saveDialogPending$: Observable<boolean> = this.saveDialog
      .saveDialogPending
      ? this.saveDialog.saveDialogPending$.pipe(
          filter(pending => pending === true)
        )
      : of(false);

    return saveDialogPending$.pipe(
      switchMap(() => {
        const yesToSurvey = this.cookieService.getCookie('yesToSurvey');
        const noToSurvey = this.cookieService.getCookie('noToSurvey');
        if (!noToSurvey && yesToSurvey && !isAdvisor) {
          return this.customerSurveySharedService
            .openSurveyDialog(surveyUuid)
            .afterClosed()
            .pipe(map(() => true));
        } else {
          this.errorHandlerApiService.notifySuppressErrorNavigation(true);
          return of(true);
        }
      }),
      catchError(() => of(true))
    );
  }

  logout(): Observable<Object> {
    return this.logoutService.logout().pipe(
      tap(() => this.cleanupCookiesOnLogout()),
      first()
    );
  }

  private cleanupCookiesOnLogout() {
    this.cookieService.getAllCookieNames().forEach((cookieName: string) => {
      if (
        cookieName !== 'okToCookies' &&
        cookieName !== 'lastLoginMethod' &&
        cookieName !== 'visIkkeEksternoverfoerselInfo' &&
        cookieName !== 'noToSurvey' &&
        cookieName !== 'CookieConsent'
      ) {
        this.cookieService.removeCookie(cookieName);
      }
    });
  }
}
