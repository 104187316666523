/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const AarsagTilBrugSchema = JoiObj.string().valid(
        'MOEDE'
        ,
        'WEB'
        ,
        'SUPPORT'
        ,
        'TELEFON'
        ,
        'SELVBETJENING'
        
    )
.id('AarsagTilBrugSchema')

export type AarsagTilBrug = 'MOEDE' | 'WEB' | 'SUPPORT' | 'TELEFON' | 'SELVBETJENING';

export const AarsagTilBrug = {
    Moede: 'MOEDE' as AarsagTilBrug,
    Web: 'WEB' as AarsagTilBrug,
    Support: 'SUPPORT' as AarsagTilBrug,
    Telefon: 'TELEFON' as AarsagTilBrug,
    Selvbetjening: 'SELVBETJENING' as AarsagTilBrug
};

