/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DigitalSalgType } from './digitalSalgType';
import { DigitalSalgTypeSchema } from './digitalSalgType';
import * as JoiObj from "joi";


    export const DigitalSalgContextSchema = JoiObj.object({
        digitalSalgPoliceNr:
            
                    JoiObj.number()
                


.required()


        ,
        digitalSalgRaadgivning:
            
                    JoiObj.boolean()
                


.required()


        ,
        digitalSalgType:
            
        JoiObj.link('#DigitalSalgTypeSchema')


.required()


        ,
        quotationGuid:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        salesOrderGuid:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        
    })
                .shared(DigitalSalgTypeSchema)
.id('DigitalSalgContextSchema')

export interface DigitalSalgContext { 
    digitalSalgPoliceNr: number;
    digitalSalgRaadgivning: boolean;
    digitalSalgType: DigitalSalgType;
    quotationGuid: string;
    salesOrderGuid: string;
}
export namespace DigitalSalgContext {
}


