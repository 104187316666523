import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import {RestCacheEntry} from "./RestCacheEntry";
import {GenericStore} from "./GenericStore";
import * as JoiObj from "joi";

import {
} from '../model/models';

import { OnlineaccountService } from './onlineaccount.service';


@Injectable({
    providedIn: 'root'
})
export class OnlineaccountStore extends GenericStore {


    constructor(private onlineaccountService: OnlineaccountService) {
        super();
    }

    invalidateAll(): void {
                
                
    }






                    public onlineAccountAccessTokensDelete() : Observable<any> {
                        return this.onlineaccountService.onlineAccountAccessTokensDelete().pipe(
                            map(value => {
                                
                                
                                return value;
                            })
                        );
                    }


}
