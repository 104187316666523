/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Skattekode } from './skattekode';
import { SkattekodeSchema } from './skattekode';
import { ViderefoerelseSammenligningRaekkeVaerdiType } from './viderefoerelseSammenligningRaekkeVaerdiType';
import { ViderefoerelseSammenligningRaekkeVaerdiTypeSchema } from './viderefoerelseSammenligningRaekkeVaerdiType';
import * as JoiObj from "joi";


    export const ViderefoerelseSammenligningRaekkeVaerdiSchema = JoiObj.object({
        beloeb:
            
                    JoiObj.number()
                


.required()


        ,
        procent:
            
                    JoiObj.number()
                


.required()


        ,
        risikoForhoejet:
            
                    JoiObj.boolean()
                


.required()


        ,
        skattekode:
            
        JoiObj.link('#SkattekodeSchema')


.required()


        ,
        type:
            
        JoiObj.link('#ViderefoerelseSammenligningRaekkeVaerdiTypeSchema')


.required()


        
    })
                .shared(SkattekodeSchema)
                .shared(ViderefoerelseSammenligningRaekkeVaerdiTypeSchema)
.id('ViderefoerelseSammenligningRaekkeVaerdiSchema')

export interface ViderefoerelseSammenligningRaekkeVaerdi { 
    beloeb: number;
    procent: number;
    risikoForhoejet: boolean;
    skattekode: Skattekode;
    type: ViderefoerelseSammenligningRaekkeVaerdiType;
}
export namespace ViderefoerelseSammenligningRaekkeVaerdi {
}


