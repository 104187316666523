<div mat-dialog-title>
  <h3 coContent="DL.PP01.C621"></h3>
  <co-icon-times (click)="handleClose()"></co-icon-times>
</div>

<div mat-dialog-content>
  <mitpfa-pension-estimate-form-of-savings
    [depositsFormOfSavings]="depositOverview"
    [savingsPlanDetails]="savingsPlanDetails"
  >
  </mitpfa-pension-estimate-form-of-savings>
</div>
