/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DokumentType } from './dokumentType';
import { DokumentTypeSchema } from './dokumentType';
import * as JoiObj from "joi";


    export const DokumentMetaSchema = JoiObj.object({
        arkiveringsdato:
            
                    JoiObj.object()
                


.required()


        ,
        cprnr:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        dato:
            
                    JoiObj.object()
                


.required()


        ,
        dokumentType:
            
        JoiObj.link('#DokumentTypeSchema')


.required()


        ,
        fremsendtPrPost:
            
                    JoiObj.boolean()
                


.required()


        ,
        gaeldendeFraDato:
            
                    JoiObj.object()
                


.required()


        ,
        id:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        krDokument:
            
                    JoiObj.boolean()
                


.required()


        ,
        policenr:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        sprogkode:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        titel:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        token:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        
    })
                .shared(DokumentTypeSchema)
.id('DokumentMetaSchema')

export interface DokumentMeta { 
    arkiveringsdato: object;
    cprnr: string;
    dato: object;
    dokumentType: DokumentType;
    fremsendtPrPost: boolean;
    gaeldendeFraDato: object;
    id: string;
    krDokument: boolean;
    policenr: string;
    sprogkode: string;
    titel: string;
    token: string;
}
export namespace DokumentMeta {
}


