/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TransaktionType } from './transaktionType';
import { TransaktionTypeSchema } from './transaktionType';
import * as JoiObj from "joi";


    export const TransaktionOmkostningSchema = JoiObj.object({
        beloeb:
            
                    JoiObj.number()
                


.required()


        ,
        procent:
            
                    JoiObj.number()
                


.required()


        ,
        type:
            
        JoiObj.link('#TransaktionTypeSchema')


.required()


        
    })
                .shared(TransaktionTypeSchema)
.id('TransaktionOmkostningSchema')

export interface TransaktionOmkostning { 
    beloeb: number;
    procent: number;
    type: TransaktionType;
}
export namespace TransaktionOmkostning {
}


