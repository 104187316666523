<svg
  id="Layer_1"
  data-name="Layer 1"
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 32 32"
>
  <g id="Canvas">
    <circle
      id="Large_circle"
      data-name="Large circle"
      cx="16"
      cy="16"
      r="16"
      fill="none"
    />
    <circle
      id="Small_circle"
      data-name="Small circle"
      cx="16"
      cy="16"
      r="12"
      fill="none"
    />
  </g>
  <g id="Icon">
    <path
      id="back_doc"
      data-name="back doc"
      d="M20.61,22v.77a1.3,1.3,0,0,1-1.3,1.3H10.68a1.31,1.31,0,0,1-1.31-1.3V11a1.31,1.31,0,0,1,1.31-1.3h.45"
      transform="translate(0 0)"
      fill="none"
      stroke="#000"
      stroke-miterlimit="10"
      stroke-width="1.2"
    />
    <path
      id="front_doc"
      data-name="front doc"
      d="M22.63,9.93v8.43"
      transform="translate(0 0)"
      fill="none"
      stroke="#000"
      stroke-linecap="round"
      stroke-miterlimit="10"
      stroke-width="1.2"
    />
    <path
      id="front_doc-2"
      data-name="front doc"
      d="M22.63,18.36v2.39a1.31,1.31,0,0,1-1.31,1.31H12.69a1.31,1.31,0,0,1-1.31-1.31V9.08a1.31,1.31,0,0,1,1.31-1.3h7.37"
      transform="translate(0 0)"
      fill="none"
      stroke="#000"
      stroke-linecap="round"
      stroke-miterlimit="10"
      stroke-width="1.2"
    />
    <line
      id="corner"
      x1="20.06"
      y1="7.78"
      x2="22.63"
      y2="9.93"
      fill="none"
      stroke="#000"
      stroke-linecap="round"
      stroke-miterlimit="10"
      stroke-width="1.2"
    />
    <line
      id="line_2"
      data-name="line 2"
      x1="13.86"
      y1="12.84"
      x2="18.27"
      y2="12.84"
      fill="none"
      stroke="#000"
      stroke-linecap="round"
      stroke-miterlimit="10"
      stroke-width="1.2"
    />
    <line
      id="line_3"
      data-name="line 3"
      x1="13.85"
      y1="14.97"
      x2="20.15"
      y2="14.97"
      fill="none"
      stroke="#000"
      stroke-linecap="round"
      stroke-miterlimit="10"
      stroke-width="1.2"
    />
    <line
      id="line_4"
      data-name="line 4"
      x1="13.85"
      y1="17.1"
      x2="20.15"
      y2="17.1"
      fill="none"
      stroke="#000"
      stroke-linecap="round"
      stroke-miterlimit="10"
      stroke-width="1.2"
    />
    <line
      id="line_4-2"
      data-name="line 4"
      x1="13.85"
      y1="19.22"
      x2="20.15"
      y2="19.22"
      fill="none"
      stroke="#000"
      stroke-linecap="round"
      stroke-miterlimit="10"
      stroke-width="1.2"
    />
    <line
      x1="19.45"
      y1="7.78"
      x2="19.45"
      y2="10.56"
      fill="none"
      stroke="#000"
      stroke-linecap="round"
      stroke-miterlimit="10"
      stroke-width="1.2"
    />
    <line
      x1="22.63"
      y1="10.56"
      x2="19.45"
      y2="10.56"
      fill="none"
      stroke="#000"
      stroke-linecap="round"
      stroke-miterlimit="10"
      stroke-width="1.2"
    />
  </g>
</svg>
