/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PrognosePeriode } from './prognosePeriode';
import { PrognosePeriodeSchema } from './prognosePeriode';
import * as JoiObj from "joi";


    export const PrognoseSchema = JoiObj.object({
        prognosePerioder:
            
        JoiObj.object().pattern(/./,         JoiObj.array().items(
        
        JoiObj.link('#PrognosePeriodeSchema')

        )
        
.allow(null)


)


.required()


        ,
        prognoser:
            
        JoiObj.object().pattern(/./,     
                    JoiObj.number()
                

.allow(null)


)


.required()


        
    })
.id('PrognoseSchema')

export interface Prognose { 
    prognosePerioder: { [key: string]: Array<PrognosePeriode>; };
    prognoser: { [key: string]: number; };
}

