import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  ResolveFn,
  RouterStateSnapshot
} from '@angular/router';
import { BasketApiService } from '@pfa/api';
import { SuccessResponse } from '@pfa/gen';

export const clearBasketResolver: ResolveFn<SuccessResponse> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => inject(BasketApiService).clearBasket();
