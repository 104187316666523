<svg
  id="Layer_1"
  data-name="Layer 1"
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 32 32"
>
  <g id="Canvas">
    <circle
      id="Large_circle"
      data-name="Large circle"
      cx="16"
      cy="16"
      r="16"
      fill="none"
    />
    <circle
      id="Small_circle"
      data-name="Small circle"
      cx="16"
      cy="16"
      r="12"
      fill="none"
    />
  </g>
  <g id="Icon">
    <path
      id="eraser"
      d="M20.41,10.88l-.55-.55.42-.4c.13-.14.66.39.53.53Z"
      fill="none"
      stroke="#000"
      stroke-linecap="round"
      stroke-miterlimit="10"
      stroke-width="1.2"
    />
    <path
      id="pen"
      d="M18.51,12.78l-6,6a2.9,2.9,0,0,1-1,.41,2.92,2.92,0,0,1,.42-1l6-6Z"
      fill="none"
      stroke="#000"
      stroke-linecap="round"
      stroke-miterlimit="10"
      stroke-width="1.2"
    />
    <line
      x1="10"
      y1="22.09"
      x2="22"
      y2="22.09"
      fill="none"
      stroke="#000"
      stroke-linecap="round"
      stroke-miterlimit="10"
      stroke-width="1.5"
    />
  </g>
</svg>
