<div class="limit-screen-width">
  <co-introbox [title]="'DL.HE01.C106'" [description]="'DL.OS01.C28a'">
    <p [coContent]="'DL.OS01.C29'" class="bullet-list"></p>
    <div [coContent]="'DL.OS01.C30'" class="text--bold"></div>
    <p [coContent]="'DL.OS01.C33'"></p>
  </co-introbox>

  <co-loading [show]="!initialized"></co-loading>
</div>

<div *ngIf="initialized">
  <div class="limit-screen-width">
    <button
      mat-raised-button
      class="button-primary--level3"
      (click)="goBack()"
      *ngIf="savingsOverview.policer.length > 1"
    >
      <co-icon-arrow-left class="icon icon--arrow-left"></co-icon-arrow-left>
      <span [coContent]="'Global.Tilbage'"></span>
    </button>

    <h2>
      <span [coContent]="'DL.AFT01.C01'"></span> {{ policyId }} -
      <span [coContent]="policy.typeTekstId"></span>
    </h2>

    <div class="year--container">
      <mat-form-field class="form-field-light yearpicker" color="accent">
        <mat-label [coContent]="'DL.OS01.C428'" class="label"
          >Indbetalingsperiode
        </mat-label>
        <mat-select [value]="chosenYear" (selectionChange)="selectYear($event)">
          <mat-option *ngFor="let year of years" [value]="year">
            <span>{{ year }}</span>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div class="savings--container">
    <div class="limit-screen-width">
      <div class="year-savings">
        <div>
          <div
            class="headline-title"
            [coContent]="'DL.OS01.C12'"
            [coContentSubst]="savingsPolicyDetails.tilDato"
          ></div>
          <co-primary-number
            class="size-lg"
            data-test-id="savings-policy-details-returnsThisYear"
            [amount]="savingsPolicyDetails.opsparing"
          ></co-primary-number>
          <div
            class="year-savings-note"
            [coContent]="'DL.OS01.C351'"
            [coContentSubst]="policyId"
          ></div>
        </div>

        <div>
          <button
            mat-raised-button
            class="button-secondary"
            (click)="gotoDetils(policyId)"
          >
            <span [coContent]="'DL.OS01.C270'"></span>
            <co-icon-arrow-right
              class="icon icon--arrow-left"
            ></co-icon-arrow-right>
          </button>
        </div>
      </div>

      <div *ngIf="productAndConceptData">
        <mitpfa-savings-policy-per-product
          *ngIf="policy?.type !== 'Gennemsnit'"
          [savingsPolicyDetails]="savingsPolicyDetails"
          [productAndConceptData]="productAndConceptData"
        ></mitpfa-savings-policy-per-product>

        <mitpfa-savings-policy-per-concept
          [savingsPolicyDetails]="savingsPolicyDetails"
          [productAndConceptData]="productAndConceptData"
        ></mitpfa-savings-policy-per-concept>
      </div>

      <mitpfa-savings-policy-key-numbers
        [savingsPolicyDetails]="savingsPolicyDetails"
      >
      </mitpfa-savings-policy-key-numbers>

      <mitpfa-savings-policy-costs
        [savingsPolicyDetails]="savingsPolicyDetails"
        [policy]="policy"
      >
      </mitpfa-savings-policy-costs>

      <a
        *ngIf="policy.livnetAftale"
        [routerLink]="'/prisliste'"
        class="link price-link"
      >
        <co-icon-coin-stack></co-icon-coin-stack>
        <span [coContent]="'DL.OS01.C346'"></span>
      </a>
    </div>
  </div>
</div>
