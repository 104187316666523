/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HandelStatusType } from './handelStatusType';
import { HandelStatusTypeSchema } from './handelStatusType';
import { HandelDetaljer } from './handelDetaljer';
import { HandelDetaljerSchema } from './handelDetaljer';
import { BrugerRolle } from './brugerRolle';
import { BrugerRolleSchema } from './brugerRolle';
import * as JoiObj from "joi";


    export const HandelSchema = JoiObj.object({
        fondKoebTotalBeloeb:
            
                    JoiObj.number()
                


.required()


        ,
        fondOmkostningerTotalBeloeb:
            
                    JoiObj.number()
                


.required()


        ,
        fondSalgTotalBeloeb:
            
                    JoiObj.number()
                


.required()


        ,
        forventetHandelsIndregnetDato:
            
                    JoiObj.object()
                


.required()


        ,
        haendelseId:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        haendelseNavn:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        handelAfventende:
            
                    JoiObj.boolean()
                


.required()


        ,
        handelAnnulleret:
            
                    JoiObj.boolean()
                


.required()


        ,
        handelDetaljer:
                JoiObj.array().items(
        
        JoiObj.link('#HandelDetaljerSchema')

        )
        

.required()


        ,
        handelsIndregnetDato:
            
                    JoiObj.object()
                


.required()


        ,
        handelsStatus:
            
        JoiObj.link('#HandelStatusTypeSchema')


.required()


        ,
        kanal:
            
        JoiObj.link('#BrugerRolleSchema')


.required()


        ,
        opretHaendelsesDato:
            
                    JoiObj.object()
                


.required()


        ,
        transaktionOmkostningerBeloeb:
            
                    JoiObj.number()
                


.required()


        ,
        unitLinkHaendelseOmkostningerTotalBeloeb:
            
                    JoiObj.number()
                


.required()


        
    })
                .shared(HandelDetaljerSchema)
                .shared(HandelStatusTypeSchema)
                .shared(BrugerRolleSchema)
.id('HandelSchema')

export interface Handel { 
    fondKoebTotalBeloeb: number;
    fondOmkostningerTotalBeloeb: number;
    fondSalgTotalBeloeb: number;
    forventetHandelsIndregnetDato: object;
    haendelseId: string;
    haendelseNavn: string;
    handelAfventende: boolean;
    handelAnnulleret: boolean;
    handelDetaljer: Array<HandelDetaljer>;
    handelsIndregnetDato: object;
    handelsStatus: HandelStatusType;
    kanal: BrugerRolle;
    opretHaendelsesDato: object;
    transaktionOmkostningerBeloeb: number;
    unitLinkHaendelseOmkostningerTotalBeloeb: number;
}
export namespace Handel {
}


