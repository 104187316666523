import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'mitpfa-onboarding-insurance',
  templateUrl: './insurance.component.html',
  styleUrls: ['./insurance.component.scss']
})
export class InsuranceComponent {
  @Output() goTo = new EventEmitter<void>();
  @Output() percentCompleted = new EventEmitter<number>();
  @Output() updateFooter = new EventEmitter<void>();

  public error = false;
  public adjustment = false;

  public updateStepper(percent: number): void {
    this.percentCompleted.emit(percent);
  }

  public guideDone(): void {
    this.goTo.emit();
  }

  public goToAdjustment(): void {
    this.adjustment = true;
    this.updateFooter.emit();
  }

  public guideFailed(): void {
    this.error = true;
  }
}
