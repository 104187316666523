/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const TransferableCategoryTypeSchema = JoiObj.string().valid(
        'NOT_TRANSFERABLE'
        ,
        'TRANSFERABLE_BY_ADVISOR'
        ,
        'TRANSFERABLE_BY_CUSTOMER'
        
    )
.id('TransferableCategoryTypeSchema')

export type TransferableCategoryType = 'NOT_TRANSFERABLE' | 'TRANSFERABLE_BY_ADVISOR' | 'TRANSFERABLE_BY_CUSTOMER';

export const TransferableCategoryType = {
    NotTransferable: 'NOT_TRANSFERABLE' as TransferableCategoryType,
    TransferableByAdvisor: 'TRANSFERABLE_BY_ADVISOR' as TransferableCategoryType,
    TransferableByCustomer: 'TRANSFERABLE_BY_CUSTOMER' as TransferableCategoryType
};

