import { formatDate } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  Prerequisites_pensionForecasts_average,
  Prerequisites_pensionForecasts_individual,
  Prerequisites_pensionForecasts_pfa,
  Prerequisites_pensionForecasts_you,
  Prerequisites_pensionplan
} from '@pfa/models';
import { ContentUtilService } from '../content/content-util.service';
import { Subscription } from 'rxjs';
import { PreconditionsService } from './preconditions-service';
import { Utils } from '@pfa/utils';
import { RatesMarketRate } from '@pfa/gen';

@Component({
  selector: 'co-preconditions',
  templateUrl: './preconditions.component.html',
  styleUrls: ['./preconditions.component.scss']
})
export class PreconditionsComponent implements OnInit, OnDestroy {
  private subs: Subscription = new Subscription();
  @Input() selectedMenu = 'forudsaetning';
  @Input() showModal = false;
  forbeholdInitialized = false;

  hasTAEAllInclusive = false;

  //Headers for resposive tables:
  tableHeaders = {
    pension_2019: this.contentUtilService.getContent('DL.FF01.C255'),
    pension_2019T2028: this.contentUtilService.getContent('DL.FF01.C152'),
    pension_2020T2028: this.contentUtilService.getContent('DL.FF01.C256'),
    pension_2029: this.contentUtilService.getContent('DL.FF01.C257'),

    mobil_samfund: this.contentUtilService.getContent('DL.FF01.C451'),
    mobil_omkostninger: this.contentUtilService.getContent('DL.FF01.C452'),
    mobil_skat: this.contentUtilService.getContent('DL.FF01.C453'),
    mobil_inflation: this.contentUtilService.getContent('DL.FF01.C454'),
    mobil_nettorente: this.contentUtilService.getContent('DL.FF01.C455'),

    loebendepension_med: this.contentUtilService.getContent('DL.FF01.C242'),
    loebendepension_uden: this.contentUtilService.getContent('DL.FF01.C243')
  };

  visPensionsplan = false;
  pensionsplanForbehold: Prerequisites_pensionplan;
  nettorenter = {
    aktier: 0,
    obligationer: 0,
    pengemarked: 0,
    fastejendom: 0
  };
  flags = {
    paragraf39: false
  };

  pensionForecastAverageLoaded = false;
  pensionForecastAverage: Prerequisites_pensionForecasts_average;

  pensionForecastPfaLoaded = false;
  pensionForecastPfa: Prerequisites_pensionForecasts_pfa;

  pensionForecastYouLoaded = false;
  pensionForecastYou: Prerequisites_pensionForecasts_you;

  pensionForecastIndividualLoaded = false;
  pensionForecastIndividual: Prerequisites_pensionForecasts_individual;

  currentDate = formatDate(new Date(), 'dd MMM yyyy', 'da');
  currentTime = formatDate(new Date(), 'hh:mm', 'da');
  ratesMarketRate: RatesMarketRate;

  constructor(
    private readonly preconditionsService: PreconditionsService,
    private readonly contentUtilService: ContentUtilService
  ) {}

  ngOnInit(): void {
    this.subs.add(
      this.preconditionsService
        .getPensionPlanPrerequisites()
        .subscribe(result => {
          this.pensionsplanForbehold = result;
          this.forbeholdInitialized = true;
          this.nettorenter.aktier = this.findNettoRente(
            this.pensionsplanForbehold.aktierSamfundsforudsaetning,
            this.pensionsplanForbehold.aktierOmkostning,
            this.pensionsplanForbehold.aktierSkat,
            this.pensionsplanForbehold.aktierInflation
          );
          this.nettorenter.obligationer = this.findNettoRente(
            this.pensionsplanForbehold.obligationerSamfundsforudsaetning,
            this.pensionsplanForbehold.obligationerOmkostning,
            this.pensionsplanForbehold.obligationerSkat,
            this.pensionsplanForbehold.obligationerInflation
          );
          this.nettorenter.pengemarked = this.findNettoRente(
            this.pensionsplanForbehold.kontanterSamfundsforudsaetning,
            this.pensionsplanForbehold.kontanterOmkostning,
            this.pensionsplanForbehold.kontanterSkat,
            this.pensionsplanForbehold.kontanterInflation
          );
          this.nettorenter.fastejendom = this.findNettoRente(
            this.pensionsplanForbehold.boligSamfundsforudsaetning,
            this.pensionsplanForbehold.boligOmkostning,
            this.pensionsplanForbehold.boligSkat,
            this.pensionsplanForbehold.boligInflation
          );
          this.flags.paragraf39 = this.pensionsplanForbehold.paragraf39;
        })
    );

    this.visPensionsplan = true;

    this.subs.add(
      this.preconditionsService
        .getPensionFOrecastsAveragePlanPrerequisites()
        .subscribe(result => {
          this.pensionForecastAverage = result;
          this.pensionForecastAverageLoaded = true;
        })
    );

    this.subs.add(
      this.preconditionsService
        .getPensionFOrecastsPfaPlanPrerequisites()
        .subscribe(result => {
          this.pensionForecastPfa = result;
          this.pensionForecastPfaLoaded = true;
        })
    );

    this.subs.add(
      this.preconditionsService
        .getPensionFOrecastsYouPlanPrerequisites()
        .subscribe(result => {
          this.pensionForecastYou = result;
          this.pensionForecastYouLoaded = true;
        })
    );

    this.subs.add(
      this.preconditionsService
        .getPensionFOrecastsIndividualPlanPrerequisites()
        .subscribe(result => {
          this.pensionForecastIndividual = result;
          this.pensionForecastIndividualLoaded = true;
        })
    );

    this.subs.add(
      this.preconditionsService
        .getTeaAllInclusivePrerequisites()
        .subscribe(result => {
          this.hasTAEAllInclusive = result;
        })
    );

    this.subs.add(
      this.preconditionsService.getRatesMarketRate().subscribe(result => {
        this.ratesMarketRate = result;
      })
    );
  }

  findNettoRente(samfundsforudsaetninger, omkostninger, skatter, inflationer) {
    const samfundsforudsaetning = samfundsforudsaetninger / 100;
    const omkostning = omkostninger / 100;
    const skat = skatter / 100;
    const inflation = inflationer / 100;
    const result =
      ((1 + (samfundsforudsaetning - omkostning) * (1 - skat)) /
        (1 + inflation) -
        1) *
      100;
    // rund af til 2 decimaler
    return Math.round((result + 0.00001) * 100) / 100;
  }

  safeFormatNumber(value) {
    let newValue = value;
    if (Number(newValue)) {
      newValue = Utils.formatNumber(parseFloat(value), 2);
    }
    return newValue === 'NaN' ? value : newValue;
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
