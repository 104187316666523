/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CorrespondenceType } from './correspondenceType';
import { CorrespondenceTypeSchema } from './correspondenceType';
import { CorrespondenceImportance } from './correspondenceImportance';
import { CorrespondenceImportanceSchema } from './correspondenceImportance';
import * as JoiObj from "joi";


    export const CorrespondenceListDataSchema = JoiObj.object({
        correspondenceImportance:
            
        JoiObj.link('#CorrespondenceImportanceSchema')


.required()


        ,
        correspondenceType:
            
        JoiObj.link('#CorrespondenceTypeSchema')


.required()


        ,
        hasAttachements:
            
                    JoiObj.boolean()
                


.required()


        ,
        headline:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        id:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        modificationDate:
            
                    JoiObj.object()
                


.required()


        ,
        noReply:
            
                    JoiObj.boolean()
                


.required()


        ,
        notificationEmailAdress:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        regardingPartyCprnr:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        taskId:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        title:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        unread:
            
                    JoiObj.boolean()
                


.required()


        ,
        urgent:
            
                    JoiObj.boolean()
                


.required()


        
    })
                .shared(CorrespondenceImportanceSchema)
                .shared(CorrespondenceTypeSchema)
.id('CorrespondenceListDataSchema')

export interface CorrespondenceListData { 
    correspondenceImportance: CorrespondenceImportance;
    correspondenceType: CorrespondenceType;
    hasAttachements: boolean;
    headline: string;
    id: string;
    modificationDate: object;
    noReply: boolean;
    notificationEmailAdress: string;
    regardingPartyCprnr: string;
    taskId: string;
    title: string;
    unread: boolean;
    urgent: boolean;
}
export namespace CorrespondenceListData {
}


