import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import {RestCacheEntry} from "./RestCacheEntry";
import {GenericStore} from "./GenericStore";
import * as JoiObj from "joi";

import {
        OpsparingOversigt,
        OpsparingOversigtSchema,
        OpsparingPoliceDetaljer,
        OpsparingPoliceDetaljerSchema,
        OverfoerselDetaljer,
        OverfoerselDetaljerSchema
} from '../model/models';

import { SavingsService } from './savings.service';


@Injectable({
    providedIn: 'root'
})
export class SavingsStore extends GenericStore {

            private opsparingIndexCache: Map<string, RestCacheEntry<OpsparingOversigt>> = new Map<string, RestCacheEntry<OpsparingOversigt>>();
            private opsparingoverfoerselvalgtOverfoertAftaleIdvalgtAaroverfoertAftaleTegningsDatoShowCache: Map<string, RestCacheEntry<OverfoerselDetaljer>> = new Map<string, RestCacheEntry<OverfoerselDetaljer>>();
            private opsparingvalgtPolicenummervalgtAarShowCache: Map<string, RestCacheEntry<OpsparingPoliceDetaljer>> = new Map<string, RestCacheEntry<OpsparingPoliceDetaljer>>();

    constructor(private savingsService: SavingsService) {
        super();
    }

    invalidateAll(): void {
                
                this.invalidateopsparingIndex();
                this.invalidateopsparingoverfoerselvalgtOverfoertAftaleIdvalgtAaroverfoertAftaleTegningsDatoShow();
                
                this.invalidateopsparingvalgtPolicenummervalgtAarShow();
                
    }


                public opsparingGet() : Observable<OpsparingOversigt> {
                    let hash = "0";
                    if (this.opsparingIndexCache.has(hash)) {
                        return this.opsparingIndexCache.get(hash)!.observable;
                    } else {
                        let opsparingIndexSubject: BehaviorSubject<0> = new BehaviorSubject(0);
                        let opsparingIndex = this.initiateStream(opsparingIndexSubject, this.savingsService.opsparingGet(), OpsparingOversigtSchema);
                        this.opsparingIndexCache.set(hash, new RestCacheEntry<OpsparingOversigt>(opsparingIndex, opsparingIndexSubject));
                        return opsparingIndex;
                    }
                }

                public invalidateopsparingIndex() {
                    this.opsparingIndexCache.forEach((cacheEntry) => {
                        cacheEntry.subject.next(0);
                    })
                }





                public opsparingOverfoerselValgtOverfoertAftaleIdValgtAarOverfoertAftaleTegningsDatoGet(valgtOverfoertAftaleId: string,valgtAar: number,overfoertAftaleTegningsDato: string) : Observable<OverfoerselDetaljer> {
                    let hash = this.hashKey((valgtOverfoertAftaleId.toString() || "0") + (valgtAar.toString() || "0") + (overfoertAftaleTegningsDato.toString() || "0"));
                    if (this.opsparingoverfoerselvalgtOverfoertAftaleIdvalgtAaroverfoertAftaleTegningsDatoShowCache.has(hash)) {
                        return this.opsparingoverfoerselvalgtOverfoertAftaleIdvalgtAaroverfoertAftaleTegningsDatoShowCache.get(hash)!.observable;
                    } else {
                        let opsparingoverfoerselvalgtOverfoertAftaleIdvalgtAaroverfoertAftaleTegningsDatoShowSubject: BehaviorSubject<0> = new BehaviorSubject(0);
                        let opsparingoverfoerselvalgtOverfoertAftaleIdvalgtAaroverfoertAftaleTegningsDatoShow = this.initiateStream(opsparingoverfoerselvalgtOverfoertAftaleIdvalgtAaroverfoertAftaleTegningsDatoShowSubject, this.savingsService.opsparingOverfoerselValgtOverfoertAftaleIdValgtAarOverfoertAftaleTegningsDatoGet(valgtOverfoertAftaleId,valgtAar,overfoertAftaleTegningsDato), OverfoerselDetaljerSchema);
                        this.opsparingoverfoerselvalgtOverfoertAftaleIdvalgtAaroverfoertAftaleTegningsDatoShowCache.set(hash, new RestCacheEntry<OverfoerselDetaljer>(opsparingoverfoerselvalgtOverfoertAftaleIdvalgtAaroverfoertAftaleTegningsDatoShow, opsparingoverfoerselvalgtOverfoertAftaleIdvalgtAaroverfoertAftaleTegningsDatoShowSubject));
                        return opsparingoverfoerselvalgtOverfoertAftaleIdvalgtAaroverfoertAftaleTegningsDatoShow;
                    }
                }

                public invalidateopsparingoverfoerselvalgtOverfoertAftaleIdvalgtAaroverfoertAftaleTegningsDatoShowById(valgtOverfoertAftaleId: string,valgtAar: number,overfoertAftaleTegningsDato: string) {
                    let hash = this.hashKey((valgtOverfoertAftaleId.toString() || "0") + (valgtAar.toString() || "0") + (overfoertAftaleTegningsDato.toString() || "0"));
                    if (this.opsparingoverfoerselvalgtOverfoertAftaleIdvalgtAaroverfoertAftaleTegningsDatoShowCache.has(hash)) {
                        this.opsparingoverfoerselvalgtOverfoertAftaleIdvalgtAaroverfoertAftaleTegningsDatoShowCache.get(hash)!.subject.next(0);
                    }
                }

                public invalidateopsparingoverfoerselvalgtOverfoertAftaleIdvalgtAaroverfoertAftaleTegningsDatoShow() {
                    this.opsparingoverfoerselvalgtOverfoertAftaleIdvalgtAaroverfoertAftaleTegningsDatoShowCache.forEach((cacheEntry) => {
                        cacheEntry.subject.next(0);
                    })
                }






                public opsparingValgtPolicenummerValgtAarGet(valgtPolicenummer: string,valgtAar: number) : Observable<OpsparingPoliceDetaljer> {
                    let hash = this.hashKey((valgtPolicenummer.toString() || "0") + (valgtAar.toString() || "0"));
                    if (this.opsparingvalgtPolicenummervalgtAarShowCache.has(hash)) {
                        return this.opsparingvalgtPolicenummervalgtAarShowCache.get(hash)!.observable;
                    } else {
                        let opsparingvalgtPolicenummervalgtAarShowSubject: BehaviorSubject<0> = new BehaviorSubject(0);
                        let opsparingvalgtPolicenummervalgtAarShow = this.initiateStream(opsparingvalgtPolicenummervalgtAarShowSubject, this.savingsService.opsparingValgtPolicenummerValgtAarGet(valgtPolicenummer,valgtAar), OpsparingPoliceDetaljerSchema);
                        this.opsparingvalgtPolicenummervalgtAarShowCache.set(hash, new RestCacheEntry<OpsparingPoliceDetaljer>(opsparingvalgtPolicenummervalgtAarShow, opsparingvalgtPolicenummervalgtAarShowSubject));
                        return opsparingvalgtPolicenummervalgtAarShow;
                    }
                }

                public invalidateopsparingvalgtPolicenummervalgtAarShowById(valgtPolicenummer: string,valgtAar: number) {
                    let hash = this.hashKey((valgtPolicenummer.toString() || "0") + (valgtAar.toString() || "0"));
                    if (this.opsparingvalgtPolicenummervalgtAarShowCache.has(hash)) {
                        this.opsparingvalgtPolicenummervalgtAarShowCache.get(hash)!.subject.next(0);
                    }
                }

                public invalidateopsparingvalgtPolicenummervalgtAarShow() {
                    this.opsparingvalgtPolicenummervalgtAarShowCache.forEach((cacheEntry) => {
                        cacheEntry.subject.next(0);
                    })
                }







}
