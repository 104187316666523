/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ServiceInfo } from './serviceInfo';
import { ServiceInfoSchema } from './serviceInfo';
import { ResourceModel } from './resourceModel';
import { ResourceModelSchema } from './resourceModel';
import * as JoiObj from "joi";


    export const RolleDataSchema = JoiObj.object({
        serviceInfo:
            
        JoiObj.link('#ServiceInfoSchema')

.allow(null)



        ,
        isAdministrator:
            
                    JoiObj.boolean()
                


.required()


        ,
        isMaegler:
            
                    JoiObj.boolean()
                


.required()


        ,
        isPfaUser:
            
                    JoiObj.boolean()
                


.required()


        ,
        isPrivat:
            
                    JoiObj.boolean()
                


.required()


        ,
        isRaadgiver:
            
                    JoiObj.boolean()
                


.required()


        ,
        isSkadesanmeldelseAdmin:
            
                    JoiObj.boolean()
                


.required()


        ,
        isSomPrivat:
            
                    JoiObj.boolean()
                


.required()


        ,
        isVirksomhedMedarbejde:
            
                    JoiObj.boolean()
                


.required()


        
    })
                .shared(ServiceInfoSchema)
.id('RolleDataSchema')

export interface RolleData extends ResourceModel { 
    isAdministrator: boolean;
    isMaegler: boolean;
    isPfaUser: boolean;
    isPrivat: boolean;
    isRaadgiver: boolean;
    isSkadesanmeldelseAdmin: boolean;
    isSomPrivat: boolean;
    isVirksomhedMedarbejde: boolean;
}

