import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { interval, Subscription, timer } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { ProgressBarMode } from '@angular/material/progress-bar';

/**
 * Creates
 * Default version without any inputs, will make a bar that animates forever, like a gif.
 * Variants:
 *  - headerText: will add a h4 header above the bar (string og contentId)
 *  - bodyText: will add a text above the bar / below header (string og contentId)
 *  - determinateMode=true: will increment the bar with 2% pr 100ms, stopping at 100%
 *  - runTime=x where x is run time in seconds: will evenly increment the bar every 100ms over x seconds, stopping at 100%
 *    note: it takes a little time (about half a second) to initialize the component and get started!
 *  - runTimeLoop=true: when the run time is up - restart the timer after 1 second
 */
@Component({
  selector: 'mitpfa-wait',
  templateUrl: './wait.component.html',
  styleUrls: ['./wait.component.scss']
})
export class WaitComponent implements OnInit, AfterViewInit, OnDestroy {
  @Output() finished = new EventEmitter<void>();
  @Input() bodyText: string;
  @Input() headerText: string;
  @Input() determinateMode: boolean;
  @Input() runTime: number;
  @Input() runTimeLoop: boolean;

  public progressValue = 0;
  public mode: ProgressBarMode = 'indeterminate';

  private runTimeSubscription: Subscription;

  constructor(private readonly cdRef: ChangeDetectorRef) {}

  ngOnInit() {
    if (this.runTime) {
      this.timedProgress();
    } else if (this.determinateMode) {
      this.mode = 'determinate';
      interval(100)
        .pipe(takeWhile(() => this.progressValue <= 100))
        .subscribe(() => {
          this.progressValue += 2;
          if (this.progressValue >= 100) {
            this.finished.emit();
          }

          this.cdRef.detectChanges();
        });
    }
  }

  private timedProgress(): void {
    this.mode = 'determinate';
    let currrentTic = 0;
    const timer$ = timer(0, 100);
    const runTimeInTenths = this.runTime * 10;
    this.runTimeSubscription = timer$.subscribe((tic: number) => {
      this.progressValue = tic * (100 / runTimeInTenths);
      currrentTic = tic;
      this.cdRef.detectChanges();
      if (currrentTic === runTimeInTenths) {
        if (this.runTimeLoop) {
          this.runTimeSubscription.add(
            timer(500).subscribe(() => {
              this.progressValue = 0;
              this.cdRef.detectChanges();
              this.runTimeSubscription.add(
                timer(500).subscribe(() => {
                  this.runTimeSubscription.unsubscribe();
                  this.timedProgress();
                })
              );
            })
          );
        } else {
          this.runTimeSubscription.unsubscribe();
        }
      }
    });
  }

  ngAfterViewInit(): void {
    window.scroll(0, 0);
  }

  ngOnDestroy(): void {
    this.runTimeSubscription?.unsubscribe();
  }
}
