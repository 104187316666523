import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'mitpfa-logged-out-inactive',
  templateUrl: './logged-out-inactive.component.html'
})
export class LoggedOutInactiveComponent implements OnInit {
  isAdvisor: boolean;

  constructor(
    private readonly dialogRef: MatDialogRef<LoggedOutInactiveComponent>,
    @Inject(MAT_DIALOG_DATA) private readonly data
  ) {}

  ngOnInit(): void {
    this.isAdvisor = this.data.isAdvisor;
  }

  closePopup() {
    this.dialogRef.close();
  }
}
