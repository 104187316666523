/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ServiceInfo } from './serviceInfo';
import { ServiceInfoSchema } from './serviceInfo';
import { ResourceModel } from './resourceModel';
import { ResourceModelSchema } from './resourceModel';
import { ContentRelease } from './contentRelease';
import { ContentReleaseSchema } from './contentRelease';
import { ContentCategory } from './contentCategory';
import { ContentCategorySchema } from './contentCategory';
import * as JoiObj from "joi";


    export const ContentMasterSchema = JoiObj.object({
        serviceInfo:
            
        JoiObj.link('#ServiceInfoSchema')

.allow(null)



        ,
        compareContent:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        content:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        contentCategory:
            
        JoiObj.link('#ContentCategorySchema')


.required()


        ,
        contentColor:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        contentCommentary:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        contentReady:
            
                    JoiObj.boolean()
                


.required()


        ,
        contentRelease:
            
        JoiObj.link('#ContentReleaseSchema')


.required()


        ,
        contentSubId:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        contentSubIdTopicEn:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        createTime:
            
                    JoiObj.object()
                


.required()


        ,
        createdBy:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        daContent:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        daContentReady:
            
                    JoiObj.boolean()
                


.required()


        ,
        dynamiskIdentificator:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        enContent:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        enContentReady:
            
                    JoiObj.boolean()
                


.required()


        ,
        fletteParameter:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        fletteParameterTopicEn:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        language:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        mailEmne:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        pensionsinfoSelskabsId:
            
                    JoiObj.number()
                


.required()


        ,
        updateTime:
            
                    JoiObj.object()
                


.required()


        ,
        updatedBy:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        url:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        urlDefault:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        wfSideId:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        
    })
                .shared(ServiceInfoSchema)
                .shared(ContentCategorySchema)
                .shared(ContentReleaseSchema)
.id('ContentMasterSchema')

export interface ContentMaster extends ResourceModel { 
    compareContent: string;
    content: string;
    contentCategory: ContentCategory;
    contentColor: string;
    contentCommentary: string;
    contentReady: boolean;
    contentRelease: ContentRelease;
    contentSubId: string;
    contentSubIdTopicEn: string;
    createTime: object;
    createdBy: string;
    daContent: string;
    daContentReady: boolean;
    dynamiskIdentificator: string;
    enContent: string;
    enContentReady: boolean;
    fletteParameter: string;
    fletteParameterTopicEn: string;
    language: string;
    mailEmne: string;
    pensionsinfoSelskabsId: number;
    updateTime: object;
    updatedBy: string;
    url: string;
    urlDefault: string;
    wfSideId: string;
}

