import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { Afkastoversigt, SavingsreturnStore } from '@pfa/gen';

@Injectable({
  providedIn: 'root'
})
export class ReturnsResolver implements Resolve<Afkastoversigt> {
  constructor(private savingsreturnStore: SavingsreturnStore) {}

  resolve():
    | Afkastoversigt
    | Observable<Afkastoversigt>
    | Promise<Afkastoversigt> {
    return this.savingsreturnStore.afkastGet();
  }
}
