/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const RetirementAgeSchema = JoiObj.object({
        maxAge:
            
                    JoiObj.number()
                


.required()


        ,
        minAge:
            
                    JoiObj.number()
                


.required()


        ,
        minSpouseAge:
            
                    JoiObj.number()
                


.required()


        ,
        minPartnerExpectedAge:
            
                    JoiObj.number()
                


.required()


        ,
        maxPartnerExpectedAge:
            
                    JoiObj.number()
                


.required()


        
    })
.id('RetirementAgeSchema')

export interface RetirementAge { 
    maxAge: number;
    minAge: number;
    minSpouseAge: number;
    minPartnerExpectedAge: number;
    maxPartnerExpectedAge: number;
}

