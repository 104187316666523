import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MaeglerInfo, PensionsKundeGenerelleData } from '@pfa/gen';
import { InvestmentGuideInfo } from '@pfa/models';
import { GuideTrackingService } from '@pfa/core';
import { InvestmentGuideQuestion } from '../../../investment-guide-configuration.service';
import { InvestmentGuideService } from '@mitpfa/shared';
import { EconomicImpactChoice } from '../../investment-guide-economy-step.model';

@Component({
  selector: 'mitpfa-investment-guide-economical-impact',
  templateUrl: './investment-guide-economical-impact.component.html',
  styleUrls: ['./investment-guide-economical-impact.component.scss']
})
export class InvestmentGuideEconomicalImpactComponent
  implements OnInit, AfterViewInit
{
  @Input() investmentGuide: InvestmentGuideInfo;
  @Input() broker: MaeglerInfo;
  @Input() pensionCustomer: PensionsKundeGenerelleData;

  answer: UntypedFormControl;
  choices: EconomicImpactChoice[] = [
    {
      text: 'DL.INVGU01.C164',
      value: 'VERY_LARGE'
    },
    {
      text: 'DL.INVGU01.C165',
      value: 'LARGE'
    },
    {
      text: 'DL.INVGU01.C166',
      value: 'MINOR'
    }
  ];

  constructor(
    readonly investmentGuideService: InvestmentGuideService,
    readonly trackingService: GuideTrackingService
  ) {}

  ngOnInit(): void {
    this.answer = new UntypedFormControl(
      this.investmentGuide.recommendationBasis.economicImpact,
      Validators.required
    );
  }

  ngAfterViewInit(): void {
    window.scroll(0, 0);
  }

  choose(): void {
    this.investmentGuide.recommendationBasis.economicImpact = this.answer.value;
    this.investmentGuideService.updateCurrentQuestion({
      value: InvestmentGuideQuestion.riskWillingness,
      update: true
    });
  }

  goBack(): void {
    this.investmentGuideService.updateCurrentQuestion({
      value: this.investmentGuideService.getQuestionBeforeEconomicalImpact(
        this.investmentGuide.recommendationBasis.conceptChoice,
        this.pensionCustomer
      ),
      update: false,
      back: true
    });
  }
}
