export interface PensionPlanCard {
  main: PensionPlanCardMainContent;
  additional?: PensionPlanCardAdditionalContent;
  showDetailsText?: string;
  hideDetailsText?: string;
  details?: PensionPlanCardDetails[];
  event?: PensionPlanCardEvent;
}

export interface PensionPlanCardMainContent {
  context?: string; //Information about the content.
  contextTooltip?: string;
  contentId?: string; //Emphasized content id. Expected to be a shorter line of text.
  content?: string; //Emphasized content. Expected to be a shorter line of text.
  contentTooltip?: string;
  text?: string; //Used if the main-content should just contain plain text. Could be more lines.
}

export interface PensionPlanCardAdditionalContent {
  context: string; //Information about the content
  content: PensionPlanCardAdditionalContentElement[]; //Expected to be short lines/single words/numbers. Should be preformatted. Shown as a list
}

export interface PensionPlanCardAdditionalContentElement {
  text: string;
  subText?: string;
}

export interface PensionPlanCardDetails {
  key: string;
  keyParam?: string;
  value: number;
  decimals?: number;
  unit?: string;
}

export interface PensionPlanCardEvent {
  enabled: boolean;
  id?: string;
  text?: string;
}

export enum PolicyType {
  Average = 'Gennemsnit',
  Market = 'Marked',
  MarketAverage = 'MarkedGennemsnit',
  Insurance = 'Forsikring'
}

export enum PolicyContext {
  Investment = 'investering',
  CustomerCapital = 'customer-capital',
  Deposit = 'deposit',
  Savings = 'savings'
}
