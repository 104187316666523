/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { FamilieRelationBarn } from './familieRelationBarn';
import { FamilieRelationBarnSchema } from './familieRelationBarn';
import { OenskerTilRaadgivning } from './oenskerTilRaadgivning';
import { OenskerTilRaadgivningSchema } from './oenskerTilRaadgivning';
import { ServiceInfo } from './serviceInfo';
import { ServiceInfoSchema } from './serviceInfo';
import { SapLoen } from './sapLoen';
import { SapLoenSchema } from './sapLoen';
import { RaadgiverInfo } from './raadgiverInfo';
import { RaadgiverInfoSchema } from './raadgiverInfo';
import { ResourceModel } from './resourceModel';
import { ResourceModelSchema } from './resourceModel';
import { Civilstand } from './civilstand';
import { CivilstandSchema } from './civilstand';
import * as JoiObj from "joi";


    export const StamdataDetaljerSchema = JoiObj.object({
        serviceInfo:
            
        JoiObj.link('#ServiceInfoSchema')

.allow(null)



        ,
        sapLoen:
            
                    JoiObj.boolean()
                


.required()


        ,
        civilstand:
            
        JoiObj.link('#CivilstandSchema')


.required()


        ,
        egenindtastningerFejlede:
            
                    JoiObj.boolean()
                


.required()


        ,
        familieRelationBoern:
                JoiObj.array().items(
        
        JoiObj.link('#FamilieRelationBarnSchema')

        )
        

.required()


        ,
        forsoergerpligt:
            
                    JoiObj.boolean()
                


.required()


        ,
        oenskerTilRaadgivning:
            
        JoiObj.link('#OenskerTilRaadgivningSchema')


.required()


        ,
        partnerAarsloen:
            
                    JoiObj.number()
                


.required()


        ,
        partnerArveandel:
            
                    JoiObj.number()
                


.required()


        ,
        partnerEfternavn:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        partnerFoedselsDato:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        partnerFornavn:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        partnerPensionsalder:
            
                    JoiObj.number()
                


.required()


        ,
        partnerPensionsindkomst:
            
                    JoiObj.number()
                


.required()


        ,
        partnersGaeldDaekkes:
            
                    JoiObj.number()
                


.required()


        ,
        partnersGaeldSikres:
            
                    JoiObj.boolean()
                


.required()


        ,
        raadgiverInfo:
            
        JoiObj.link('#RaadgiverInfoSchema')


.required()


        ,
        samlevende:
            
                    JoiObj.boolean()
                


.required()


        ,
        samlevendeOver2aar:
            
                    JoiObj.boolean()
                


.required()


        
    })
                .shared(ServiceInfoSchema)
                .shared(CivilstandSchema)
                .shared(FamilieRelationBarnSchema)
                .shared(OenskerTilRaadgivningSchema)
                .shared(RaadgiverInfoSchema)
.id('StamdataDetaljerSchema')

export interface StamdataDetaljer extends ResourceModel, SapLoen { 
    civilstand: Civilstand;
    egenindtastningerFejlede: boolean;
    familieRelationBoern: Array<FamilieRelationBarn>;
    forsoergerpligt: boolean;
    oenskerTilRaadgivning: OenskerTilRaadgivning;
    partnerAarsloen: number;
    partnerArveandel: number;
    partnerEfternavn: string;
    partnerFoedselsDato: string;
    partnerFornavn: string;
    partnerPensionsalder: number;
    partnerPensionsindkomst: number;
    partnersGaeldDaekkes: number;
    partnersGaeldSikres: boolean;
    raadgiverInfo: RaadgiverInfo;
    samlevende: boolean;
    samlevendeOver2aar: boolean;
}
export namespace StamdataDetaljer {
}


