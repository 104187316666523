/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Advarselssregler } from './advarselssregler';
import { AdvarselssreglerSchema } from './advarselssregler';
import { Udvaelgelsesregler } from './udvaelgelsesregler';
import { UdvaelgelsesreglerSchema } from './udvaelgelsesregler';
import { Sats } from './sats';
import { SatsSchema } from './sats';
import { Rentgruppe3og4 } from './rentgruppe3og4';
import { Rentgruppe3og4Schema } from './rentgruppe3og4';
import * as JoiObj from "joi";


    export const OevrigeOverfoerselSatserSchema = JoiObj.object({
        activeNow:
            
                    JoiObj.boolean()
                


.required()


        ,
        name:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        updatedAt:
            
            JoiObj.date()


.required()


        ,
        updatedBy:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        uuid:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        validFrom:
            
            JoiObj.date()

.allow(null)



        ,
        validFromFormatted:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        year:
            
                    JoiObj.number()
                


.required()


        ,
        advarselssregler:
            
        JoiObj.link('#AdvarselssreglerSchema')


.required()


        ,
        lukoverfoersel:
            
                    JoiObj.boolean()
                


.required()


        ,
        notifikationmail:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        rentgruppe3og4:
            
        JoiObj.link('#Rentgruppe3og4Schema')


.required()


        ,
        udvaelgelsesregler:
            
        JoiObj.link('#UdvaelgelsesreglerSchema')


.required()


        
    })
                .shared(AdvarselssreglerSchema)
                .shared(Rentgruppe3og4Schema)
                .shared(UdvaelgelsesreglerSchema)
.id('OevrigeOverfoerselSatserSchema')

export interface OevrigeOverfoerselSatser extends Sats { 
    advarselssregler: Advarselssregler;
    lukoverfoersel: boolean;
    notifikationmail: string;
    rentgruppe3og4: Rentgruppe3og4;
    udvaelgelsesregler: Udvaelgelsesregler;
}

