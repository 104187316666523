/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { IdName } from './idName';
import { IdNameSchema } from './idName';
import { Preselect } from './preselect';
import { PreselectSchema } from './preselect';
import * as JoiObj from "joi";


    export const KsSchema = JoiObj.object({
        diagnose:
            
        JoiObj.link('#IdNameSchema')


.required()


        ,
        diagnoseDato:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        diagnoseDatoContentIdHeader:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        tidligere:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        tidligereContentIdHeader:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        tidligereDiagnose:
            
        JoiObj.link('#IdNameSchema')

.allow(null)



        ,
        tidligereDiagnoseContentIdHeader:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        tidligereDiagnoseDato:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        tidligereDiagnoseDatoContentIdHeader:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        valgtSygdomsKategori:
            
        JoiObj.link('#PreselectSchema')


.required()


        
    })
                .shared(IdNameSchema)
                .shared(IdNameSchema)
                .shared(PreselectSchema)
.id('KsSchema')

export interface Ks { 
    diagnose: IdName;
    diagnoseDato: string;
    diagnoseDatoContentIdHeader: string;
    tidligere: string;
    tidligereContentIdHeader: string;
    tidligereDiagnose?: IdName;
    tidligereDiagnoseContentIdHeader: string;
    tidligereDiagnoseDato?: string;
    tidligereDiagnoseDatoContentIdHeader?: string;
    valgtSygdomsKategori: Preselect;
}

