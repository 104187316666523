import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output
} from '@angular/core';

@Component({
  selector: 'mitpfa-onboarding-step-contact',
  templateUrl: './step-contact.component.html',
  styleUrls: ['./step-contact.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StepContactComponent {
  @Output() goTo = new EventEmitter();

  public next(): void {
    this.goTo.emit();
  }
}
