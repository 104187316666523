import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PfaFormModule } from '@pfaform';
import { CoreModule } from '@pfa/core';
import { SinglePolicyReturnTotalComponent } from './single-policy-return-total/single-policy-return-total.component';
import { SinglePolicyReturnTableComponent } from './single-policy-return-table/single-policy-return-table.component';
import { IconModule } from '@pfa/icons';

@NgModule({
  imports: [CommonModule, PfaFormModule, CoreModule, IconModule],
  declarations: [
    SinglePolicyReturnTotalComponent,
    SinglePolicyReturnTableComponent
  ],
  exports: [SinglePolicyReturnTotalComponent, SinglePolicyReturnTableComponent]
})
export class SinglePolicyReturnModule {}
