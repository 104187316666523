import { Subscription, Observable, Subject, timer } from 'rxjs';
import { Router } from '@angular/router';
import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BrugerStore } from '@pfa/gen';

@Injectable({
  providedIn: 'root'
})
export class SessionTimeoutService implements OnDestroy {
  private static readonly TIMEOUT_OFFSET_HARD: number = 1;
  private static readonly TIMEOUT_DEFAULT_MINUTES: number = 20;
  private secondsBeforeTimeoutISAM: number;
  private sessionTimeout: Subscription;
  private timeoutSubject: Subject<boolean>;
  private numberOfTimersStarted = 0;

  constructor(
    private router: Router,
    private readonly http: HttpClient,
    private readonly userStore: BrugerStore
  ) {
    this.timeoutSubject = new Subject();
  }

  public getSecondsBeforeTimeoutISAM(): number {
    return this.secondsBeforeTimeoutISAM;
  }

  public setSecondsBeforeTimeoutISAM(value: number): void {
    this.secondsBeforeTimeoutISAM = value;
  }

  public onTimeout(): Observable<boolean> {
    return this.timeoutSubject.asObservable();
  }

  public start(): void {
    this.startTimer();
  }

  public resetTimeout(): void {
    if (this.numberOfTimersStarted <= 1) {
      this.stopTimer();
      this.startTimer();
    }
  }

  private startTimer(): void {
    if (this.numberOfTimersStarted < 1) {
      this.numberOfTimersStarted++;
      this.sessionTimeout = timer(this.getMillisBeforeTimeout()).subscribe(
        () => {
          if (this.timeoutSubject && !this.timeoutSubject.closed) {
            this.timeoutSubject.next(true);
          }
        }
      );
    }
  }

  private getMillisBeforeTimeout(): number {
    return (
      this.calcTimeoutInMinutes(
        SessionTimeoutService.TIMEOUT_OFFSET_HARD,
        SessionTimeoutService.TIMEOUT_DEFAULT_MINUTES
      ) *
      1000 *
      60
    );
  }

  public stop(): void {
    this.cleanUp();
  }

  private cleanUp(): void {
    if (this.sessionTimeout) {
      this.sessionTimeout.unsubscribe();
    }
  }

  private stopTimer(): void {
    if (this.numberOfTimersStarted === 1) {
      this.sessionTimeout.unsubscribe();
      this.numberOfTimersStarted--;
    }
  }

  public calcTimeoutInMinutes(
    offsetMinutes: number = 5,
    defaultMinutes: number = 15
  ): number {
    return this.getSecondsBeforeTimeoutISAM() &&
      this.getSecondsBeforeTimeoutISAM() / 60 > offsetMinutes
      ? this.getSecondsBeforeTimeoutISAM() / 60 - offsetMinutes
      : defaultMinutes;
  }

  ngOnDestroy(): void {
    if (this.timeoutSubject) {
      this.timeoutSubject.unsubscribe();
    }
    this.cleanUp();
  }
}
