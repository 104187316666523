/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ContactPersonName } from './contactPersonName';
import { ContactPersonNameSchema } from './contactPersonName';
import { ContactPersonPhone } from './contactPersonPhone';
import { ContactPersonPhoneSchema } from './contactPersonPhone';
import * as JoiObj from "joi";


    export const BusinessContactElementSchema = JoiObj.object({
        contactPersonEmail:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        contactPersonName:
            
        JoiObj.link('#ContactPersonNameSchema')


.required()


        ,
        contactPersonPhone:
            
        JoiObj.link('#ContactPersonPhoneSchema')


.required()


        ,
        partnerNo:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        
    })
                .shared(ContactPersonNameSchema)
                .shared(ContactPersonPhoneSchema)
.id('BusinessContactElementSchema')

export interface BusinessContactElement { 
    contactPersonEmail: string;
    contactPersonName: ContactPersonName;
    contactPersonPhone: ContactPersonPhone;
    partnerNo: string;
}

