import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface ConfirmationModalConfiguration {
  title: string;
  contents: string[];
  confirmButtonLabel: string;
  cancelButtonLabel: string;
}

@Component({
  selector: 'co-modal-confirm',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmationModalComponent {
  title: string;
  contents: string[];
  confirmButtonLabel: string;
  cancelButtonLabel: string;

  constructor(
    private readonly modal: MatDialogRef<ConfirmationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmationModalConfiguration
  ) {
    this.title = data.title;
    this.contents = data.contents;
    this.confirmButtonLabel = data.confirmButtonLabel;
    this.cancelButtonLabel = data.cancelButtonLabel;
  }

  public handleClose(value: boolean) {
    this.modal.close(value);
  }
}
