<div mat-dialog-title>
  <h3 [coContent]="'DL.TT01.C01'"></h3>
  <co-icon-times *ngIf="!isAdvisor" (click)="closePopup()"></co-icon-times>
</div>

<mat-dialog-content>
  <div *ngIf="!isAdvisor">
    <p [coContent]="'DL.TT01.C02'">Du blev logget ud pga. inaktivitet...</p>
    <p [coContent]="'DL.TT01.C03'">Du kan logge på igen ....</p>
  </div>
  <div *ngIf="isAdvisor">
    <p [coContent]="'DL.TT01.C04'">Du blev logget ud pga. inaktivitet...</p>
    <div [coContent]="'DL.TT01.C05'" class="text--bold">
      Hvordan kommer jeg videre?
    </div>
    <ol class="list--numbered">
      <li [coContent]="'DL.TT01.C06'">Luk denne tab</li>
      <li [coContent]="'DL.TT01.C07'">
        I PFA Pro: Søg kunden frem igen med CPR
      </li>
      <li [coContent]="'DL.TT01.C08'">I PFA Pro: Åbn mitpfa igen via link</li>
    </ol>
  </div>
</mat-dialog-content>
