import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { FamilyInformationApiService } from '@pfa/api';
import { StamdataDetaljer } from '@pfa/gen';

@Injectable({
  providedIn: 'root'
})
export class FamilyInformationResolver implements Resolve<StamdataDetaljer> {
  constructor(
    private familyInformationApiService: FamilyInformationApiService
  ) {}

  resolve():
    | StamdataDetaljer
    | Observable<StamdataDetaljer>
    | Promise<StamdataDetaljer> {
    return this.familyInformationApiService.getFamilyInformation();
  }
}
