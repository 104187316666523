/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const NextOfKinTypeSchema = JoiObj.string().valid(
        'NEXT_OF_KIN_BEFORE_2008'
        ,
        'NEXT_OF_KIN_AFTER_2008'
        
    )
.id('NextOfKinTypeSchema')

export type NextOfKinType = 'NEXT_OF_KIN_BEFORE_2008' | 'NEXT_OF_KIN_AFTER_2008';

export const NextOfKinType = {
    Before2008: 'NEXT_OF_KIN_BEFORE_2008' as NextOfKinType,
    After2008: 'NEXT_OF_KIN_AFTER_2008' as NextOfKinType
};

