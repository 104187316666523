/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const ForsikringstalAendringSchema = JoiObj.object({
        forsikringPrAar:
            
                    JoiObj.number()
                

.allow(null)



        ,
        maanedligPris:
            
                    JoiObj.number()
                

.allow(null)



        ,
        oegetNiveauOensket:
            
                    JoiObj.boolean()
                


.required()


        ,
        pct:
            
                    JoiObj.number()
                

.allow(null)



        ,
        pctAfLoen:
            
                    JoiObj.number()
                

.allow(null)



        
    })
.id('ForsikringstalAendringSchema')

export interface ForsikringstalAendring { 
    forsikringPrAar?: number;
    maanedligPris?: number;
    oegetNiveauOensket: boolean;
    pct?: number;
    pctAfLoen?: number;
}

