/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { NytegningSatser } from './nytegningSatser';
import { NytegningSatserSchema } from './nytegningSatser';
import { PartialScheme } from './partialScheme';
import { PartialSchemeSchema } from './partialScheme';
import * as JoiObj from "joi";


    export const AdvisorOptionsSchema = JoiObj.object({
        minIndbetalingTilOpsparing:
            
                    JoiObj.number()
                


.required()


        ,
        nytegningSatser:
            
        JoiObj.link('#NytegningSatserSchema')


.required()


        ,
        partialSchemes:
                JoiObj.array().items(
        
        JoiObj.link('#PartialSchemeSchema')

        )
        

.required()


        
    })
                .shared(NytegningSatserSchema)
                .shared(PartialSchemeSchema)
.id('AdvisorOptionsSchema')

export interface AdvisorOptions { 
    minIndbetalingTilOpsparing: number;
    nytegningSatser: NytegningSatser;
    partialSchemes: Array<PartialScheme>;
}

