import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { LoginService } from '../login.service';

@Injectable({
  providedIn: 'root'
})
export class RateLimitResolver implements Resolve<any> {
  constructor(private readonly loginService: LoginService) {}

  resolve(): Observable<any> {
    return this.loginService.getRateLimit();
  }
}
