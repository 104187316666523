import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DS_API_VERSION } from '@pfa/gen';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DsApiMediaTypeInterceptor implements HttpInterceptor {
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Determine default Accept header based on responseType
    let acceptHeader = 'application/json'; // Default media type
    switch (req.responseType) {
      case 'arraybuffer':
        acceptHeader = 'application/octet-stream';
        break;
      case 'blob':
        acceptHeader = 'application/octet-stream';
        break;
      case 'text':
        acceptHeader = 'text/plain';
        break;
    }

    // If an 'Accept' header is explicitly provided, use it instead of default
    const existingAcceptHeader = req.headers.get('Accept') || acceptHeader;

    // Append the version to the Accept header without altering existing content types
    const versionedAcceptHeader = `${existingAcceptHeader};ds-api-version=${DS_API_VERSION}`;

    // Clone the request with the updated Accept header
    const modifiedReq = req.clone({
      setHeaders: {
        Accept: versionedAcceptHeader
      }
    });

    // Pass the cloned request instead of the original request to the next handle
    return next.handle(modifiedReq);
  }
}
