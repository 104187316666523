import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  Directive,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
  ViewContainerRef
} from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup
} from '@angular/forms';
import { CheckboxItem } from './checkbox-group-content-component';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[labelHost]'
})
export class CheckboxGroupContentDirective {
  constructor(public viewContainerRef: ViewContainerRef) {}
}

@Component({
  selector: 'co-checkbox-group',
  templateUrl: './checkbox-group.component.html'
})
export class CheckboxGroupComponent
  implements OnInit, OnChanges, AfterViewInit
{
  @Input() options: CheckboxItem[] = [];
  @Input() selectedValues: string[] = [];
  @Output() toggle = new EventEmitter<string[]>();

  form = new UntypedFormGroup({
    items: new UntypedFormArray([])
  });

  get items(): UntypedFormArray {
    return this.form.get('items') as UntypedFormArray;
  }
  constructor(
    private readonly componentFactoryResolver: ComponentFactoryResolver,
    private readonly cd: ChangeDetectorRef
  ) {}

  @ViewChildren(CheckboxGroupContentDirective)
  contentHosts!: QueryList<CheckboxGroupContentDirective>;

  ngOnInit(): void {
    this.form.valueChanges.subscribe(newValues => {
      const optionsChecked = new Array<string>();
      for (let index = 0; index < newValues.items.length; index++) {
        if (newValues.items[index]) {
          const currentOptionValue = this.options[index].value;
          optionsChecked.push(currentOptionValue);
        }
      }
      this.toggle.emit(optionsChecked);
    });
    this.ngOnChanges();
  }

  ngOnChanges() {
    if (this.items.length === 0) {
      this.options.forEach(() => {
        this.items.push(new UntypedFormControl(false));
      });
    }
    this.selectedValues.forEach(value => {
      const index: number = this.options.findIndex(opt => opt.value === value);
      if (index >= 0) {
        this.items.get(index.toString()).setValue(true);
      }
    });
  }

  ngAfterViewInit(): void {
    this.contentHosts.forEach((contentHost, i) => {
      const option = this.options[i];

      const componentFactory =
        this.componentFactoryResolver.resolveComponentFactory(option.component);

      const viewContainerRef = contentHost.viewContainerRef;
      viewContainerRef.clear();

      const componentRef = viewContainerRef.createComponent(componentFactory);
      const instance = componentRef.instance;
      instance.data = option.data;
      instance.self = componentRef;
    });
    this.cd.detectChanges();
  }
}
