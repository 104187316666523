import {
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { CustomerProfile } from '@pfa/gen';
import {
  ContentService,
  ContentUtilService,
  ExtendedWindow,
  PageTrackingService
} from '@pfa/core';
import { BehaviorSubject } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'co-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  @Input() customerProfile: CustomerProfile;
  @Input() isLight = false;
  @Output() languageChange: EventEmitter<'da' | 'en'> = new EventEmitter<
    'da' | 'en'
  >();

  private readonly destroyRef: DestroyRef = inject(DestroyRef);
  private readonly contentUtilService: ContentUtilService =
    inject(ContentUtilService);
  private readonly contentService: ContentService = inject(ContentService);
  private readonly pageTrackingService: PageTrackingService =
    inject(PageTrackingService);

  public isDanish: boolean;
  public cookiesUrl: string;
  public personalDataUrl: string;
  public pfaUrl: string;
  public isLetPension: boolean;

  public isLanguageChangeDisabled: BehaviorSubject<boolean>;

  ngOnInit() {
    this.isLanguageChangeDisabled =
      this.contentService.isLanguageChangeDisabled;
    this.isDanish = this.contentService.getLanguage() === 'da';
    this.isLetPension =
      this.customerProfile?.personDetail.pureLetpensionCustomer;
    this.contentService.languageState
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(language => {
        this.cookiesUrl = this.contentUtilService.getContent('DL.FO01.C417');
        this.personalDataUrl =
          this.contentUtilService.getContent('DL.FO01.C419');
        this.pfaUrl = this.contentUtilService.getContent('DL.FO01.C421');
        this.isDanish = language === 'da';
      });
  }

  handleExternalLinkClick(linkText: string) {
    this.pageTrackingService.trackPage(
      this.contentUtilService.getContent(linkText)
    );
  }

  openCookiePopup() {
    const extendedWindow = window as unknown as ExtendedWindow;
    const cookieBot = extendedWindow.Cookiebot;
    cookieBot?.show();
  }

  setLanguage(lang: 'da' | 'en') {
    if (this.isLanguageChangeDisabled.value) {
      return;
    }

    this.isDanish = lang === 'da';
    this.languageChange.emit(lang);
  }
}
