import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  ViewChild
} from '@angular/core';
import { collapse, opacity } from '@pfa/animations';
import { CardAnimatedStackItem } from '../card-animated-stack/card-animated-stack-item/card-animated-stack-item.interface';

@Component({
  selector: 'co-card-split-horizontal',
  templateUrl: 'card-split-horizontal.component.html',
  styleUrls: ['./card-split-horizontal.component.scss'],
  animations: [opacity, collapse]
})
export class CardSplitHorizontalComponent implements CardAnimatedStackItem {
  @Input() bottomWarning: boolean;
  @Input() invert: boolean;
  @Input() emphasized?: boolean;
  @Input() simple?: boolean; // no background color on either half
  @Input() noBottom?: boolean; // don't show the bottom half
  @Input() middle?: boolean; // show the middle panel
  @Input() interactiveCardIndicator?: boolean; // show a navigation arrow
  @Input() public set label(value) {
    this._label = value || ' ';
  }
  public get label() {
    return this._label;
  }
  private _label: string;

  @ViewChild('content') contentContainer: ElementRef;

  public collapseState:
    | 'expand'
    | 'expandAnimated'
    | 'collapse'
    | 'collapseAnimated' = 'expand';
  public opacityState: 'show' | 'hide' = 'show';
  public minHeight = null;

  constructor(private readonly cdRef: ChangeDetectorRef) {}

  hideContent() {
    this.opacityState = 'hide';
    this.cdRef.detectChanges();
  }

  showContent() {
    this.opacityState = 'show';
    this.cdRef.detectChanges();
  }

  collapseContent(withAnimation = true) {
    this.collapseState = withAnimation ? 'collapseAnimated' : 'collapse';
    this.cdRef.detectChanges();
  }

  expandContent(withAnimation = true) {
    this.collapseState = withAnimation ? 'expandAnimated' : 'expand';
    this.cdRef.detectChanges();
  }

  setMinHeight(value: number) {
    this.minHeight = value !== null && value >= 0 ? value + 'px' : null;
    this.cdRef.detectChanges();
  }

  getContentHeight(): number {
    return this.contentContainer.nativeElement.scrollHeight;
  }
}
