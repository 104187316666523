/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const StatusOfferSchema = JoiObj.string().valid(
        'SIMULATION'
        ,
        'OFFER'
        ,
        'ACCEPTED'
        ,
        'REJECTED'
        ,
        'CANCELLED'
        ,
        'EXPIRED'
        ,
        'COMPLETED'
        
    )
.id('StatusOfferSchema')

export type StatusOffer = 'SIMULATION' | 'OFFER' | 'ACCEPTED' | 'REJECTED' | 'CANCELLED' | 'EXPIRED' | 'COMPLETED';

export const StatusOffer = {
    Simulation: 'SIMULATION' as StatusOffer,
    Offer: 'OFFER' as StatusOffer,
    Accepted: 'ACCEPTED' as StatusOffer,
    Rejected: 'REJECTED' as StatusOffer,
    Cancelled: 'CANCELLED' as StatusOffer,
    Expired: 'EXPIRED' as StatusOffer,
    Completed: 'COMPLETED' as StatusOffer
};

