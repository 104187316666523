/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const BeneficiaryTypeSchema = JoiObj.string().valid(
        'NEXT_OF_KIN'
        ,
        'HEIR_OF_THE_BODY'
        ,
        'ESTATE'
        ,
        'NAMED'
        ,
        'RELATIVE_DISTRIBUTION_OTHERS'
        ,
        'PRIMARY_BENEFICIARY_HEIR_OF_THE_BODY'
        ,
        'GRAND_CHILDREN_AND_DESCENDANTS'
        ,
        'CONVERTED'
        ,
        'LIGHT'
        ,
        'ALL_ENTITLED_CHILDREN'
        ,
        'OTHER'
        
    )
.id('BeneficiaryTypeSchema')

export type BeneficiaryType = 'NEXT_OF_KIN' | 'HEIR_OF_THE_BODY' | 'ESTATE' | 'NAMED' | 'RELATIVE_DISTRIBUTION_OTHERS' | 'PRIMARY_BENEFICIARY_HEIR_OF_THE_BODY' | 'GRAND_CHILDREN_AND_DESCENDANTS' | 'CONVERTED' | 'LIGHT' | 'ALL_ENTITLED_CHILDREN' | 'OTHER';

export const BeneficiaryType = {
    NextOfKin: 'NEXT_OF_KIN' as BeneficiaryType,
    HeirOfTheBody: 'HEIR_OF_THE_BODY' as BeneficiaryType,
    Estate: 'ESTATE' as BeneficiaryType,
    Named: 'NAMED' as BeneficiaryType,
    RelativeDistributionOthers: 'RELATIVE_DISTRIBUTION_OTHERS' as BeneficiaryType,
    PrimaryBeneficiaryHeirOfTheBody: 'PRIMARY_BENEFICIARY_HEIR_OF_THE_BODY' as BeneficiaryType,
    GrandChildrenAndDescendants: 'GRAND_CHILDREN_AND_DESCENDANTS' as BeneficiaryType,
    Converted: 'CONVERTED' as BeneficiaryType,
    Light: 'LIGHT' as BeneficiaryType,
    AllEntitledChildren: 'ALL_ENTITLED_CHILDREN' as BeneficiaryType,
    Other: 'OTHER' as BeneficiaryType
};

