import { Component, Input, OnInit } from '@angular/core';
import { FamilyInformationApiService } from '@pfa/api';
import {
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { GlobalWorkingService } from '@pfaform';
import { PersonNameValidator } from '@pfa/utils';
import * as moment from 'moment';
import { merge, Observable, throwError } from 'rxjs';
import { catchError, finalize, map, startWith, tap } from 'rxjs/operators';
import {
  FamilieRelationBarn,
  PartnerDateOfBirthConsistencyCheck,
  PensionsKundeGenerelleData,
  StamdataDetaljer
} from '@pfa/gen';

@Component({
  selector: 'mitpfa-family-info',
  templateUrl: './family-info.component.html'
})
export class FamilyInfoComponent implements OnInit {
  @Input() familyInformation: StamdataDetaljer;
  @Input() pensionCustomer: PensionsKundeGenerelleData;

  partnerInfoForm: UntypedFormGroup;
  hasSpouse: boolean;
  showError: boolean;
  childForms: UntypedFormGroup;
  private readonly dateFormat = 'DD.MM.YYYY';

  partnerDateOfBirthConsistencyCheck: PartnerDateOfBirthConsistencyCheck;

  constructor(
    readonly activatedRoute: ActivatedRoute,
    readonly globalWorking: GlobalWorkingService,
    private familyInformationApiService: FamilyInformationApiService
  ) {}

  ngOnInit(): void {
    this.hasSpouse = this.familyInformation.civilstand === 'Gift';
    this.buildPartnerInfoForm();
    this.buildChildForms();
  }

  private buildPartnerInfoForm(): void {
    const isMarried = this.hasSpouse;

    this.partnerInfoForm = new UntypedFormGroup({
      hasPartner: new UntypedFormControl(this.familyInformation.samlevende, {
        validators: !isMarried ? Validators.required : null
      }),
      hasPartner2Years: new UntypedFormControl(
        this.familyInformation.samlevendeOver2aar,
        {
          validators: !isMarried ? Validators.required : null
        }
      ),
      partnerName: new UntypedFormControl(
        this.getFullName(
          this.familyInformation.partnerFornavn,
          this.familyInformation.partnerEfternavn
        ),
        {
          validators: [Validators.required, PersonNameValidator]
        }
      ),
      partnerLastName: new UntypedFormControl(
        this.familyInformation.partnerEfternavn
      ),
      partnerBirthday: new UntypedFormControl(
        moment(this.familyInformation.partnerFoedselsDato, this.dateFormat)
      ),
      partnerSalary: new UntypedFormControl(
        this.familyInformation.partnerAarsloen,
        {
          validators: [Validators.maxLength(10)]
        }
      )
    });
  }

  private buildChildForms(): void {
    const childs = new UntypedFormArray([]);
    if (this.familyInformation.familieRelationBoern) {
      this.familyInformation.familieRelationBoern.forEach(child => {
        childs.push(
          new UntypedFormGroup({
            familyRelationId: new UntypedFormControl(child.familieRelationId),
            childName: new UntypedFormControl(
              this.getFullName(child.fornavn, child.efternavn),
              {
                validators: [Validators.required, PersonNameValidator]
              }
            ),
            birthDate: new UntypedFormControl(
              moment(child.foedselsDato, this.dateFormat),
              {
                validators: Validators.required
              }
            ),
            relationType: new UntypedFormControl(child.barnType, {
              validators: Validators.required
            })
          })
        );
      });
    }
    this.childForms = new UntypedFormGroup({
      isLegalProvider: new UntypedFormControl(
        this.familyInformation.forsoergerpligt,
        {
          validators: Validators.required
        }
      ),
      childs: childs
    });
  }

  private getFullName(firstName: string, lastName: string): string {
    return (firstName ? firstName : '') + (lastName ? ' ' + lastName : '');
  }

  isInputValid(): boolean {
    return (
      (this.partnerInfoForm.valid ||
        this.partnerInfoForm.get('hasPartner').value === false) &&
      this.childForms.valid
    );
  }

  isFormValid(): Observable<boolean> {
    return merge(
      this.partnerInfoForm.valueChanges,
      this.childForms.valueChanges
    ).pipe(
      map(val => this.isInputValid()),
      startWith(this.isInputValid())
    );
  }

  save(): Observable<any> {
    console.log('familyinfo save!');
    this.globalWorking.show();
    this.showError = false;
    this.keepFamilyDataState();

    return this.familyInformationApiService
      .updateFamilyInformation(this.familyInformation)
      .pipe(
        tap(familyInformation => {
          this.familyInformation = familyInformation;
          this.childForms.markAsPristine();
          this.partnerInfoForm.markAsPristine();
        }),
        finalize(() => this.globalWorking.hide()),
        catchError(err => {
          this.showError = true;
          return throwError(err);
        })
      );
  }

  private keepFamilyDataState() {
    this.familyInformation.samlevende =
      this.partnerInfoForm.get('hasPartner').value;
    this.familyInformation.partnerFornavn = this.getFirstNameFromFullName(
      this.partnerInfoForm.get('partnerName').value
    );
    this.familyInformation.partnerEfternavn = this.getLastNameFromFullName(
      this.partnerInfoForm.get('partnerName').value
    );
    this.familyInformation.samlevendeOver2aar =
      this.partnerInfoForm.get('hasPartner2Years').value;
    this.familyInformation.partnerFoedselsDato = moment(
      this.partnerInfoForm.get('partnerBirthday').value
    ).format('DD.MM.YYYY');
    this.familyInformation.partnerAarsloen =
      this.partnerInfoForm.get('partnerSalary').value;
    this.familyInformation.samlevendeOver2aar =
      this.partnerInfoForm.get('hasPartner2Years').value;
    this.familyInformation.forsoergerpligt =
      this.childForms.get('isLegalProvider').value;
    this.familyInformation.familieRelationBoern = this.toFamilyRelationArray();
  }

  private getFirstNameFromFullName(fullName: string): string {
    if (fullName) {
      const split = fullName.split(' ');
      if (split.length > 1) {
        split.pop();
      }
      return split.join(' ');
    } else {
      return null;
    }
  }

  getLastNameFromFullName(fullName: string) {
    if (fullName) {
      const split = fullName.split(' ');
      return split.length > 1 ? split.pop() : null;
    } else {
      return null;
    }
  }

  private toFamilyRelationArray(): FamilieRelationBarn[] {
    const childArray: FamilieRelationBarn[] = [];
    (this.childForms.get('childs') as UntypedFormArray).controls.forEach(
      childForm => {
        childArray.push({
          familieRelationId: childForm.get('familyRelationId').value,
          fornavn: this.getFirstNameFromFullName(
            childForm.get('childName').value
          ),
          efternavn: this.getLastNameFromFullName(
            childForm.get('childName').value
          ),
          foedselsDato: moment(childForm.get('birthDate').value).format(
            'DD.MM.YYYY'
          ),
          barnType: childForm.get('relationType').value,
          arveandel: null,
          serviceInfo: null
        });
      }
    );
    return childArray;
  }
}
