import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconModule } from '@pfa/icons';
import { CoreModule } from '@pfa/core';
import { PensionCheckCardViewerComponent } from './pension-check-card-viewer.component';
import { InvestmentGuideService, SharedModule } from '@mitpfa/shared';
import { PensionCheckYourInformationComponent } from './pension-check-your-information/pension-check-your-information.component';
import { PensionCheckPensionsinfoComponent } from './pension-check-your-information/pension-check-pensionsinfo/pension-check-pensionsinfo.component';
import { PensionInfoModule } from '@mitpfa/shared/pension-info/pension-info.module';
import { PensionCheckDebtComponent } from './pension-check-your-information/pension-check-debt/pension-check-debt.component';
import { PensionCheckWealthComponent } from './pension-check-your-information/pension-check-wealth/pension-check-wealth.component';
import { PensionCheckDoneComponent } from './pension-check-your-information/pension-check-done/pension-check-done.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { PensionCheckKidsComponent } from './pension-check-your-information/pension-check-kids/pension-check-kids.component';
import { PensionCheckPartnerComponent } from './pension-check-your-information/pension-check-partner/pension-check-partner.component';
import { PensionCheckHeaderSectionComponent } from './pension-check-header-section/pension-check-header-section.component';

@NgModule({
  imports: [
    IconModule,
    CommonModule,
    SharedModule,
    MatProgressBarModule,
    CoreModule,
    PensionInfoModule
  ],
  declarations: [
    PensionCheckCardViewerComponent,
    PensionCheckYourInformationComponent,
    PensionCheckPensionsinfoComponent,
    PensionCheckDebtComponent,
    PensionCheckWealthComponent,
    PensionCheckDoneComponent,
    PensionCheckKidsComponent,
    PensionCheckPartnerComponent,
    PensionCheckHeaderSectionComponent
  ],
  exports: [PensionCheckCardViewerComponent],
  providers: [InvestmentGuideService]
})
export class PensionCheckCardViewerModule {}
