import { Component, Input, OnInit } from '@angular/core';
import { Aendringslinje } from '@pfa/gen';

@Component({
  selector: 'mitpfa-basket-other-item',
  templateUrl: './basket-other-item.component.html',
  styleUrls: ['./basket-other-item.component.scss']
})
export class BasketOtherItemComponent implements OnInit {
  @Input() items: Aendringslinje[] = [];

  locNumberItem: Aendringslinje;

  ngOnInit(): void {
    const hasLocAT = this.items.some(
      item => item.aendringsdatatype === 'TAE_AUTOMATISK_TILPASNING'
    );
    if (hasLocAT) {
      const locItem = this.items.find(
        item => item.aendringsdatatype === 'TAE_LOEBENDE'
      );
      const locItemIndex = this.items.indexOf(locItem);
      if (locItemIndex > -1) {
        this.locNumberItem = this.items.splice(locItemIndex, 1)[0];
      }
    }
  }

  trackByFn(index: number): number {
    return index;
  }
}
