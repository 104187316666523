<ng-container *ngIf="isInitialized">
  <mitpfa-investment-guide-intro
    *ngIf="!displayStepper; else stepper"
    [pensionCustomer]="pensionCustomer"
    [financialSituation]="financialSituation"
    [isOnboardingFlow]="isOnboardingFlow"
    (startGuide)="onStartGuide()"
  ></mitpfa-investment-guide-intro>

  <ng-template #stepper>
    <mitpfa-wait
      *ngIf="showWaitForCalculation"
      [headerText]="'DL.INVGU01.C677'"
      [bodyText]="'DL.INVGU01.C678'"
    >
    </mitpfa-wait>

    <ng-container *ngIf="!showWaitForCalculation">
      <co-stepper
        *ngIf="!hideStepperOnAllSteps"
        [steps]="steps"
        [selectedStepIndex]="currentStepIndex"
        [isNavigationEnabled]="false"
        (stepSelected)="stepChanged($event)"
        class="stepper stepper-guide spacing-unit--under"
      ></co-stepper>

      <mitpfa-investment-guide-involvement-step
        *ngIf="currentStepIndex === 0"
        [investmentGuide]="investmentGuide"
        [broker]="broker"
        [pensionInfoSavedValues]="pensionInfoSavedValues"
        [pensionCustomer]="pensionCustomer"
      >
      </mitpfa-investment-guide-involvement-step>

      <mitpfa-investment-guide-economy-step
        *ngIf="currentStepIndex === 1"
        [investmentGuide]="investmentGuide"
        [broker]="broker"
        [pensionInfoSavedValues]="pensionInfoSavedValues"
        [pensionCustomer]="pensionCustomer"
      ></mitpfa-investment-guide-economy-step>

      <mitpfa-investment-guide-risk-step
        *ngIf="currentStepIndex === 2"
        [investmentGuide]="investmentGuide"
        [broker]="broker"
      ></mitpfa-investment-guide-risk-step>

      <mitpfa-investment-guide-climate-step
        *ngIf="currentStepIndex === 3"
        [investmentGuide]="investmentGuide"
        [broker]="broker"
        (climateComplete)="guideCompleted()"
      ></mitpfa-investment-guide-climate-step>

      <co-alert *ngIf="stepUpdateError">
        <span [coContent]="'DL.INVGU01.C435'"></span>
      </co-alert>
    </ng-container>
  </ng-template>
</ng-container>
