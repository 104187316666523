import { Component, Input } from '@angular/core';
import { ContentService, ContentUtilService } from '@pfa/core';
import { MitIdLoginErrorMap } from './mitid-login.model';
import { LoginRedirectService } from '@pfa/handler';
import { LoginService } from '@mitpfa/shared';
import { MobileBridgeService } from '../../mobile-bridge/mobile-bridge.service';

@Component({
  selector: 'mitpfa-mitid-login',
  templateUrl: './mitid-login.component.html',
  styleUrls: ['./mitid-login.component.scss']
})
export class MitIDLoginComponent {
  @Input() set mitidError(value: string) {
    this.error = value ? value.toLowerCase() : this.error;
    this.resolveErrorText();
  }

  @Input() set mitidErrorDescription(value: string) {
    this.errorDesc = value ? value.toLowerCase() : this.errorDesc;
    this.resolveErrorText();
  }

  private error: string;
  private errorDesc: string;
  public mitidErrorText: string;

  constructor(
    private readonly mobileBridgeService: MobileBridgeService,
    private readonly contentUtil: ContentUtilService,
    private readonly loginService: LoginService,
    private readonly contentService: ContentService,
    private readonly loginRedirectService: LoginRedirectService
  ) {}

  private resolveErrorText(): void {
    if (this.error === 'access_denied' && this.errorDesc) {
      this.mitidErrorText = this.messageFromErrorCode(this.errorDesc);
    } else if (this.error === 'internal_error') {
      this.mitidErrorText = this.messageFromErrorCode(this.error);
    } else {
      this.mitidErrorText = '';
    }
  }

  private messageFromErrorCode(errorCode: string): string {
    const cTextCode = MitIdLoginErrorMap[errorCode];
    const errorMessage = this.contentUtil.getContent(cTextCode);
    if (!cTextCode || !errorMessage || errorMessage === cTextCode) {
      // there is no specific c-text for this particular cTextCode
      // return default values
      return 'DL.LI01.C15';
    } else {
      // return dedicated c-text
      return cTextCode;
    }
  }

  public goToMitID() {
    const mitIdRedirectParams: string[] = [];
    if (this.contentService.isContentEnglish()) {
      mitIdRedirectParams.push('language=en');
    } else {
      mitIdRedirectParams.push('language=da');
    }

    if (this.loginRedirectService.loggedInSuccessRoute?.url) {
      mitIdRedirectParams.push(
        'return_url=' +
          encodeURIComponent(this.loginRedirectService.loggedInSuccessRoute.url)
      );
    }

    const returnUrlParams = this.formatSuccessRouteReturnUrlParams();
    if (returnUrlParams) {
      mitIdRedirectParams.push(
        'return_url_params=' + encodeURIComponent(returnUrlParams)
      );
    }

    let mitIdRedirectParamsString = '';
    if (mitIdRedirectParams.length > 0) {
      mitIdRedirectParamsString += '?' + mitIdRedirectParams.join('&');
    }
    window.location.href =
      this.loginService.mitIdUrl$.value + mitIdRedirectParamsString;
  }

  private formatSuccessRouteReturnUrlParams(): string | null {
    if (this.loginRedirectService.loggedInSuccessRoute?.params) {
      const returnUrlParams: string[] = [];
      for (const [paramName, paramValue] of Object.entries(
        this.loginRedirectService.loggedInSuccessRoute.params
      )) {
        returnUrlParams.push(`${paramName}=${paramValue}`);
      }
      if (returnUrlParams.length > 0) {
        return returnUrlParams.join('&');
      }
    }
    return null;
  }
}
