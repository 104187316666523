/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Bemaerkning } from './bemaerkning';
import { BemaerkningSchema } from './bemaerkning';
import { Pension } from './pension';
import { PensionSchema } from './pension';
import { AftaleType } from './aftaleType';
import { AftaleTypeSchema } from './aftaleType';
import { UseExternalPolicyType } from './useExternalPolicyType';
import { UseExternalPolicyTypeSchema } from './useExternalPolicyType';
import { Forsikring } from './forsikring';
import { ForsikringSchema } from './forsikring';
import { Delaftale } from './delaftale';
import { DelaftaleSchema } from './delaftale';
import { Loen } from './loen';
import { LoenSchema } from './loen';
import { PensionsAftale } from './pensionsAftale';
import { PensionsAftaleSchema } from './pensionsAftale';
import { JuridiskEnhedsId } from './juridiskEnhedsId';
import { JuridiskEnhedsIdSchema } from './juridiskEnhedsId';
import { PensionsAftaleId } from './pensionsAftaleId';
import { PensionsAftaleIdSchema } from './pensionsAftaleId';
import { ServicekaldFejl } from './servicekaldFejl';
import { ServicekaldFejlSchema } from './servicekaldFejl';
import * as JoiObj from "joi";


    export const PensionsInfoAftaleSchema = JoiObj.object({
        aarligeIndbetalingerFoerAMB:
            
                    JoiObj.number()
                


.required()


        ,
        aftaleType:
            
        JoiObj.link('#AftaleTypeSchema')


.required()


        ,
        delaftale:
            
        JoiObj.link('#DelaftaleSchema')


.required()


        ,
        forretningPartnerNavn:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        forretningPartnerNr:
            
        JoiObj.link('#JuridiskEnhedsIdSchema')


.required()


        ,
        forsikring:
            
        JoiObj.link('#ForsikringSchema')


.required()


        ,
        forventetPensionsalder:
            
                    JoiObj.number()
                .integer()


.required()


        ,
        id:
            
        JoiObj.link('#PensionsAftaleIdSchema')


.required()


        ,
        indbetaltIndevaerendeAar:
            
                    JoiObj.number()
                


.required()


        ,
        loen:
            
        JoiObj.link('#LoenSchema')


.required()


        ,
        maeglerBetjent:
            
                    JoiObj.boolean()
                


.required()


        ,
        opgoerelsesdato:
            
                    JoiObj.object()
                


.required()


        ,
        opsparetVaerdi:
            
                    JoiObj.number()
                


.required()


        ,
        pension:
            
        JoiObj.link('#PensionSchema')


.required()


        ,
        pensionsInfoSelskabsNr:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        pensionsInfoSelskabsNrCTekst:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        pfaagreement:
            
                    JoiObj.boolean()
                


.required()


        ,
        rateperiode:
            
                    JoiObj.number()
                .integer()


.required()


        ,
        servicekaldFejl:
                JoiObj.array().items(
        
        JoiObj.link('#ServicekaldFejlSchema')

        )
        

.required()


        ,
        systeminfoAftale:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        udenOpsparing:
            
                    JoiObj.boolean()
                


.required()


        ,
        bemaerkninger:
                JoiObj.array().items(
        
        JoiObj.link('#BemaerkningSchema')

        )
        

.required()


        ,
        leveranceStatus:
            
                    JoiObj.number()
                .integer()


.required()


        ,
        leveranceStatusAftale:
            
                    JoiObj.number()
                .integer()


.required()


        ,
        returKode:
            
                    JoiObj.number()
                .integer()


.required()


        ,
        tjenestemandspension:
            
                    JoiObj.boolean()
                


.required()


        ,
        useExternalPolicy:
            
        JoiObj.link('#UseExternalPolicyTypeSchema')


.required()


        ,
        ydelseOver10000UdenDepot:
            
                    JoiObj.boolean()
                


.required()


        
    })
                .shared(AftaleTypeSchema)
                .shared(DelaftaleSchema)
                .shared(JuridiskEnhedsIdSchema)
                .shared(ForsikringSchema)
                .shared(PensionsAftaleIdSchema)
                .shared(LoenSchema)
                .shared(PensionSchema)
                .shared(ServicekaldFejlSchema)
                .shared(BemaerkningSchema)
                .shared(UseExternalPolicyTypeSchema)
.id('PensionsInfoAftaleSchema')

export interface PensionsInfoAftale extends PensionsAftale { 
    bemaerkninger: Array<Bemaerkning>;
    leveranceStatus: number;
    leveranceStatusAftale: number;
    returKode: number;
    tjenestemandspension: boolean;
    useExternalPolicy: UseExternalPolicyType;
    ydelseOver10000UdenDepot: boolean;
}
export namespace PensionsInfoAftale {
}


