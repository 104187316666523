/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const MinOpsparingsplanSchema = JoiObj.object({
        aktierLoebendeOpsparing:
            
                    JoiObj.number()
                

.allow(null)



        ,
        atp:
            
                    JoiObj.boolean()
                


.required()


        ,
        efterloen:
            
                    JoiObj.boolean()
                


.required()


        ,
        efterloenFraAlder:
            
                    JoiObj.number()
                .integer()


.required()


        ,
        efterloenTilAlder:
            
                    JoiObj.number()
                .integer()


.required()


        ,
        efterloensudbetaling:
            
                    JoiObj.number()
                


.required()


        ,
        ekstraordinaerIndtaegtBeloeb:
            
                    JoiObj.number()
                


.required()


        ,
        folkepension:
            
                    JoiObj.boolean()
                


.required()


        ,
        folkepensionsalder:
            
                    JoiObj.number()
                .integer()


.required()


        ,
        kontanterLoebendeOpsparing:
            
                    JoiObj.number()
                

.allow(null)



        ,
        obligationerLoebendeOpsparing:
            
                    JoiObj.number()
                

.allow(null)



        ,
        partnerIndkomstLaast:
            
                    JoiObj.boolean()
                


.required()


        ,
        partnerIndkomstVedPensionering:
            
                    JoiObj.number()
                


.required()


        ,
        partnerIndkomstVises:
            
                    JoiObj.boolean()
                


.required()


        ,
        pensionsalder:
            
                    JoiObj.number()
                .integer()


.required()


        
    })
.id('MinOpsparingsplanSchema')

export interface MinOpsparingsplan { 
    aktierLoebendeOpsparing?: number;
    atp: boolean;
    efterloen: boolean;
    efterloenFraAlder: number;
    efterloenTilAlder: number;
    efterloensudbetaling: number;
    ekstraordinaerIndtaegtBeloeb: number;
    folkepension: boolean;
    folkepensionsalder: number;
    kontanterLoebendeOpsparing?: number;
    obligationerLoebendeOpsparing?: number;
    partnerIndkomstLaast: boolean;
    partnerIndkomstVedPensionering: number;
    partnerIndkomstVises: boolean;
    pensionsalder: number;
}

