export enum CustomerSurveyStep {
  Intro,
  Questions,
  Summary
}

export enum FoundAllYouWantedOptions {
  Yes = 'YES',
  Partially = 'PARTIALLY',
  No = 'NO',
  DidntSearchAnythingSpecific = 'DIDNT_SEARCH_ANYTHING_SPECIFIC'
}
