import { inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class DomainService {
  private readonly document: Document = inject(DOCUMENT);

  public isExternalDomain(): boolean {
    const currentDomain = this.document.location.hostname;
    return (
      currentDomain.endsWith('pfa.dk') || currentDomain.endsWith('pfademo.dk')
    );
  }
}
