import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import {RestCacheEntry} from "./RestCacheEntry";
import {GenericStore} from "./GenericStore";
import * as JoiObj from "joi";

import {
        AdminRow,
        AdminRowSchema,
        LanguageCode,
        LanguageCodeSchema,
        SpoergeskemaStatus,
        SpoergeskemaStatusSchema,
        Survey,
        SurveySchema,
        UrlForLanguage,
        UrlForLanguageSchema
} from '../model/models';

import { SpoergeskemaService } from './spoergeskema.service';


@Injectable({
    providedIn: 'root'
})
export class SpoergeskemaStore extends GenericStore {

            private spoergeskemasitecoreurlIndexCache: Map<string, RestCacheEntry<UrlForLanguage>> = new Map<string, RestCacheEntry<UrlForLanguage>>();
            private spoergeskemaIndexCache: Map<string, RestCacheEntry<Array<AdminRow>>> = new Map<string, RestCacheEntry<Array<AdminRow>>>();
            private spoergeskematemplateuuidShowCache: Map<string, RestCacheEntry<Survey>> = new Map<string, RestCacheEntry<Survey>>();

    constructor(private spoergeskemaService: SpoergeskemaService) {
        super();
    }

    invalidateAll(): void {
                
                this.invalidatespoergeskemaIndex();
                
                this.invalidatespoergeskemasitecoreurlIndex();
                
                
                
                
                
                
                this.invalidatespoergeskematemplateuuidShow();
                
                
                
    }


                public spoergeskemaGet() : Observable<Array<AdminRow>> {
                    let hash = "0";
                    if (this.spoergeskemaIndexCache.has(hash)) {
                        return this.spoergeskemaIndexCache.get(hash)!.observable;
                    } else {
                        let spoergeskemaIndexSubject: BehaviorSubject<0> = new BehaviorSubject(0);
                        let spoergeskemaIndex = this.initiateStream(spoergeskemaIndexSubject, this.spoergeskemaService.spoergeskemaGet(), AdminRowSchema);
                        this.spoergeskemaIndexCache.set(hash, new RestCacheEntry<Array<AdminRow>>(spoergeskemaIndex, spoergeskemaIndexSubject));
                        return spoergeskemaIndex;
                    }
                }

                public invalidatespoergeskemaIndex() {
                    this.spoergeskemaIndexCache.forEach((cacheEntry) => {
                        cacheEntry.subject.next(0);
                    })
                }






                public spoergeskemaSitecoreUrlGet() : Observable<UrlForLanguage> {
                    let hash = "0";
                    if (this.spoergeskemasitecoreurlIndexCache.has(hash)) {
                        return this.spoergeskemasitecoreurlIndexCache.get(hash)!.observable;
                    } else {
                        let spoergeskemasitecoreurlIndexSubject: BehaviorSubject<0> = new BehaviorSubject(0);
                        let spoergeskemasitecoreurlIndex = this.initiateStream(spoergeskemasitecoreurlIndexSubject, this.spoergeskemaService.spoergeskemaSitecoreUrlGet(), UrlForLanguageSchema);
                        this.spoergeskemasitecoreurlIndexCache.set(hash, new RestCacheEntry<UrlForLanguage>(spoergeskemasitecoreurlIndex, spoergeskemasitecoreurlIndexSubject));
                        return spoergeskemasitecoreurlIndex;
                    }
                }

                public invalidatespoergeskemasitecoreurlIndex() {
                    this.spoergeskemasitecoreurlIndexCache.forEach((cacheEntry) => {
                        cacheEntry.subject.next(0);
                    })
                }








                    public spoergeskemaTemplateHsPost(sprog: LanguageCode,contentType: string,body?: string) : Observable<any> {
                        return this.spoergeskemaService.spoergeskemaTemplateHsPost(sprog,contentType,body).pipe(
                            map(value => {
                                
                                
                                return value;
                            })
                        );
                    }






                    public spoergeskemaTemplateKsPost(sprog: LanguageCode,contentType: string,body?: string) : Observable<any> {
                        return this.spoergeskemaService.spoergeskemaTemplateKsPost(sprog,contentType,body).pipe(
                            map(value => {
                                
                                
                                return value;
                            })
                        );
                    }






                    public spoergeskemaTemplateTaePost(sprog: LanguageCode,contentType: string,body?: string) : Observable<any> {
                        return this.spoergeskemaService.spoergeskemaTemplateTaePost(sprog,contentType,body).pipe(
                            map(value => {
                                
                                
                                return value;
                            })
                        );
                    }



                public spoergeskemaTemplateUuidGet(uuid: string) : Observable<Survey> {
                    let hash = this.hashKey((uuid.toString() || "0"));
                    if (this.spoergeskematemplateuuidShowCache.has(hash)) {
                        return this.spoergeskematemplateuuidShowCache.get(hash)!.observable;
                    } else {
                        let spoergeskematemplateuuidShowSubject: BehaviorSubject<0> = new BehaviorSubject(0);
                        let spoergeskematemplateuuidShow = this.initiateStream(spoergeskematemplateuuidShowSubject, this.spoergeskemaService.spoergeskemaTemplateUuidGet(uuid), SurveySchema);
                        this.spoergeskematemplateuuidShowCache.set(hash, new RestCacheEntry<Survey>(spoergeskematemplateuuidShow, spoergeskematemplateuuidShowSubject));
                        return spoergeskematemplateuuidShow;
                    }
                }

                public invalidatespoergeskematemplateuuidShowById(uuid: string) {
                    let hash = this.hashKey((uuid.toString() || "0"));
                    if (this.spoergeskematemplateuuidShowCache.has(hash)) {
                        this.spoergeskematemplateuuidShowCache.get(hash)!.subject.next(0);
                    }
                }

                public invalidatespoergeskematemplateuuidShow() {
                    this.spoergeskematemplateuuidShowCache.forEach((cacheEntry) => {
                        cacheEntry.subject.next(0);
                    })
                }










                    public spoergeskemaTemplateUuidPut(uuid: string,contentType: string,body?: string) : Observable<any> {
                        return this.spoergeskemaService.spoergeskemaTemplateUuidPut(uuid,contentType,body).pipe(
                            map(value => {
                                this.invalidatespoergeskematemplateuuidShow();
                                
                                return value;
                            })
                        );
                    }



}
