import { Injectable } from '@angular/core';
import { TrackingService } from './tracking.service';

export type GuideTrackingId =
  | 'onboarding guide'
  | 'registration guide'
  | 'investment guide'
  | 'beneficiary guide'
  | 'insurance guide'
  | 'insurance guide onboarding'
  | 'single payment'
  | 'Internoverførsel'
  | 'combine your savings'
  | 'Booking'
  | 'claim - health insurance'
  | 'claim - critical illness'
  | 'claim - occupational capacity,';

export type GuideTrackingImpressionType =
  | 'IG REC take guide'
  | 'IG REC see recommendation';

export type GuideTrackType =
  | 'track-guide-impression'
  | 'track-guide-start'
  | 'track-guide-step'
  | 'track-guide-resume'
  | 'track-guide-cta'
  | 'track-guide-exit'
  | 'track-guide-skip'
  | 'track-guide-recommendation'
  | 'track-guide-recommendation-cta'
  | 'track-guide-complete'
  | 'track-guide-signature'
  | 'track-guide-recommendation-impression'
  | 'track-guide-recommendation-start'
  | 'track-guide-recommendation-complete'
  | 'track-guide-recommendation-step';

export interface GuideTrackingRecommendation {
  name: string;
  index: number;
}

export type GuideRecommendationStepNameType =
  | 'IG REC pfainvest'
  | 'IG REC pfainvest to youinvest'
  | 'IG REC pfainvest/youinvest to pfainvest'
  | 'IG REC pfainvest/youinvest to youinvest'
  | 'IG REC youinvest'
  | 'IG REC youinvest to pfainvest'
  | 'IG REC youinvest/pfaoptional to pfainvest'
  | 'IG REC youinvest/pfaoptional to youinvest'
  | 'IG REC pfaoptional to pfainvest'
  | 'IG REC pfaoptional to youinvest'
  | 'IG REC pfainvest climate'
  | 'IG REC climate'
  | 'IG REC guide complete - keep'
  | 'IG REC guide complete - order'
  | 'IG REC guide result'
  | 'IG REC order overview';

export enum GuideStepType {
  MANDATORY = 'mandatory',
  OPTIONAL = 'optional'
}

export enum GuideStepOther {
  PHYSICAL = 'other1',
  PSYCHICAL = 'other2',
  ABUSE_DIETITIAN = 'other3',
  COMBINE_SAVINGS_TRANSFER_INITIATED = 'transfer requested',
  COMBINE_SAVINGS_TRANSFER_DECLINED = 'transfer declined',
  COMBINE_SAVINGS_UNDER_TRANSFER = 'under transfer',
  COMBINE_SAVINGS_ADVISORY = 'advisory requested',
  COMBINE_SAVINGS_ADVISORY_DECLINED = 'advisory declined',
  COMBINE_SAVINGS_TRANSFER_NOT_POSSIBLE = 'transfer not possible',
  UNDEFINED = 'undefined'
}

export enum GuideExitAction {
  INITIATED = 'initiated',
  CANCELLED = 'cancelled',
  COMPLETED = 'completed'
}

export enum GuideSignatureAction {
  INITIATED = 'initiated',
  FAILED = 'failed',
  COMPLETED = 'completed'
}

export enum GuidePlacement {
  INVESTMENT = 'investment',
  INVESTMENT_GUIDE = 'investment guide',
  ONBOARDING = 'onboarding',
  INSURANCE = 'insurance'
}

interface GuideTrackingOptions {
  actionName?: string;
  guideName: GuideTrackingId;
  guideStepName?: string;
  guideStepType?: GuideStepType;
  guideStepOther?: GuideStepOther;
  guidePlacement?: string;
  guideImpressionType?: string;
  guideStepHelp?: 1;
  guideExitAction?: GuideExitAction;
  recommendationViewed?: 1;
  allRecommendationsViewed?: 1;
  recommendations?: GuideTrackingRecommendation[];
  guideSignatureAction?: GuideSignatureAction;
  guideRecommendationStepName?: GuideRecommendationStepNameType;
}

@Injectable()
export class GuideTrackingService extends TrackingService {
  trackFlowWelcome(
    guideName: GuideTrackingId,
    guideImpressionType: string,
    guidePlacement: string
  ): void {
    this.trackGuide('track-guide-impression', {
      guideName,
      guideImpressionType,
      guidePlacement
    });
  }

  trackGuideStart(
    guideName: GuideTrackingId,
    guideStepName: string,
    guideStepType?: GuideStepType,
    guidePlacement?: GuidePlacement
  ): void {
    this.trackGuide('track-guide-start', {
      guideName,
      guideStepName,
      guideStepType,
      guidePlacement
    });
  }

  trackGuideStep(
    guideName: GuideTrackingId,
    guideStepName: string,
    guideStepType?: GuideStepType,
    guideStepOther?: GuideStepOther,
    guidePlacement?: GuidePlacement
  ): void {
    this.trackGuide('track-guide-step', {
      guideName,
      guideStepName,
      guideStepType,
      guideStepOther,
      guidePlacement
    });
  }

  trackGuideComplete(
    guideName: GuideTrackingId,
    guideStepName: string,
    guideStepType?: GuideStepType,
    guideStepOther?: GuideStepOther,
    guidePlacement?: GuidePlacement
  ): void {
    this.trackGuide('track-guide-complete', {
      guideName,
      guideStepName,
      guideStepType,
      guideStepOther,
      guidePlacement
    });
  }

  trackGuideHelp(
    guideName: GuideTrackingId,
    guideStepName: string,
    guidePlacement?: GuidePlacement
  ): void {
    this.trackGuide('track-guide-step', {
      guideName,
      guideStepName,
      guideStepHelp: 1,
      guidePlacement
    });
  }

  trackGuideResume(
    guideName: GuideTrackingId,
    guideStepName: string,
    guideStepType?: GuideStepType,
    guidePlacement?: GuidePlacement
  ): void {
    this.trackGuide('track-guide-resume', {
      guideName,
      guideStepName,
      guideStepType,
      guidePlacement
    });
  }

  trackGuideCallToAction(guideName: GuideTrackingId, actionName: string): void {
    this.trackGuide('track-guide-cta', {
      guideName,
      actionName
    });
  }

  trackGuideExit(
    guideName: GuideTrackingId,
    guideStepName: string,
    guideStepType: GuideStepType | undefined,
    guideExitAction: GuideExitAction,
    guidePlacement?: GuidePlacement
  ): void {
    this.trackGuide('track-guide-exit', {
      guideName,
      guideStepName,
      guideStepType,
      guideExitAction,
      guidePlacement
    });
  }

  trackGuideWithPlacementExit(
    guideName: GuideTrackingId,
    guideStepName: string,
    guideStepType: GuideStepType | undefined,
    guideExitAction: GuideExitAction,
    guidePlacement: GuidePlacement
  ): void {
    this.trackGuideExit(
      guideName,
      guideStepName,
      guideStepType,
      guideExitAction,
      guidePlacement
    );
  }

  trackGuideSkip(
    guideName: GuideTrackingId,
    guideStepName: string,
    guideStepType: GuideStepType,
    guidePlacement: GuidePlacement
  ): void {
    this.trackGuide('track-guide-skip', {
      guideName,
      guideStepName,
      guideStepType,
      guidePlacement
    });
  }

  trackGuideRecommendation(
    guideName: GuideTrackingId,
    recommendationName: string
  ): void {
    this.trackGuide('track-guide-recommendation', {
      guideName,
      recommendationViewed: 1,
      allRecommendationsViewed: 1,
      recommendations: [
        {
          name: recommendationName,
          index: 1
        }
      ]
    });
  }

  trackGuideRecommendationPartial(
    guideName: GuideTrackingId,
    guideTrackingRecommendations: GuideTrackingRecommendation[],
    allRecommendationsViewed?: boolean
  ): void {
    this.trackGuide('track-guide-recommendation', {
      guideName,
      recommendationViewed: 1,
      allRecommendationsViewed: allRecommendationsViewed ? 1 : undefined,
      recommendations: guideTrackingRecommendations
    });
  }

  trackGuideRecommendationCallToAction(
    guideName: GuideTrackingId,
    actionName: string
  ): void {
    this.trackGuide('track-guide-recommendation-cta', {
      guideName,
      actionName
    });
  }

  trackGuideRecommendationComplete(
    guideName: GuideTrackingId,
    guideRecommendationStepName: GuideRecommendationStepNameType,
    guidePlacement?: GuidePlacement
  ): void {
    this.trackGuide('track-guide-recommendation-complete', {
      guideName,
      guidePlacement,
      guideRecommendationStepName
    });
  }

  trackGuideRecommendationImpression(
    guideName: GuideTrackingId,
    guidePlacement: GuidePlacement,
    guideImpressionType: GuideTrackingImpressionType
  ): void {
    this.trackGuide('track-guide-recommendation-impression', {
      guideName,
      guidePlacement,
      guideImpressionType
    });
  }

  trackGuideRecommendationStart(
    guideName: GuideTrackingId,
    guideRecommendationStepName: GuideRecommendationStepNameType,
    guidePlacement?: GuidePlacement
  ): void {
    this.trackGuide('track-guide-recommendation-start', {
      guideName,
      guidePlacement,
      guideRecommendationStepName
    });
  }

  trackGuideRecommendationStep(
    guideName: GuideTrackingId,
    guideRecommendationStepName: GuideRecommendationStepNameType,
    guidePlacement?: GuidePlacement
  ): void {
    this.trackGuide('track-guide-recommendation-step', {
      guideName,
      guidePlacement,
      guideRecommendationStepName
    });
  }

  trackGuideSignature(
    guideName: GuideTrackingId,
    actionName: string,
    guideStepType: GuideStepType,
    guideSignatureAction: GuideSignatureAction,
    guideStepOther?: GuideStepOther
  ): void {
    this.trackGuide('track-guide-signature', {
      guideName,
      actionName,
      guideStepType,
      guideSignatureAction,
      guideStepOther
    });
  }

  private trackGuide(
    trackType: GuideTrackType,
    options: GuideTrackingOptions
  ): void {
    this.dispatchCustomEventTracking(trackType, options);
  }
}
