import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GodkendSvar, PensionskundeStore } from '@pfa/gen';
import { Component, Inject, inject, OnInit } from '@angular/core';
import { GuidePlacement, GuideTrackingService, TrackingName } from '@pfa/core';

@Component({
  templateUrl: './signing-completed-popup.component.html',
  styleUrls: ['./signing-completed-popup.component.scss']
})
export class SigningCompletedPopupComponent implements OnInit {
  godkendSvar: GodkendSvar;
  isAdvisor: boolean;
  isHealthQuestionnareGenerated: boolean;
  private trackingName: TrackingName;

  // INJECTIONS
  private readonly pensionskundeStore: PensionskundeStore =
    inject(PensionskundeStore);
  private readonly guideTrackingService: GuideTrackingService =
    inject(GuideTrackingService);

  constructor(
    private dialogRef: MatDialogRef<SigningCompletedPopupComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.godkendSvar = data.godkendSvar;
    this.isHealthQuestionnareGenerated = this.godkendSvar.spoergeskema;

    this.pensionskundeStore.pensionskundeGet().subscribe({
      next: pensionCustomer => {
        this.isAdvisor = pensionCustomer.isRaadgiver;
      }
    });

    this.trackingName = data.trackingName;
  }

  public ngOnInit(): void {
    if (this.trackingName === TrackingName.INVESTMENT_GUIDE) {
      this.guideTrackingService.trackGuideRecommendationComplete(
        TrackingName.INVESTMENT_GUIDE,
        'IG REC guide complete - order',
        GuidePlacement.INVESTMENT
      );
    }
  }

  public closePopup(fillQuestionnare: boolean): void {
    this.dialogRef.close(fillQuestionnare);
  }
}
