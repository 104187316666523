/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BeneficiaryAdvisoryRecommended } from './beneficiaryAdvisoryRecommended';
import { BeneficiaryAdvisoryRecommendedSchema } from './beneficiaryAdvisoryRecommended';
import { BeneficiaryAdvisoryBeneficiaries } from './beneficiaryAdvisoryBeneficiaries';
import { BeneficiaryAdvisoryBeneficiariesSchema } from './beneficiaryAdvisoryBeneficiaries';
import { BeneficiaryAdvisoryQuestion } from './beneficiaryAdvisoryQuestion';
import { BeneficiaryAdvisoryQuestionSchema } from './beneficiaryAdvisoryQuestion';
import * as JoiObj from "joi";


    export const BeneficiaryAdvisoryRecommendationSchema = JoiObj.object({
        beneficiaries:
            
        JoiObj.link('#BeneficiaryAdvisoryBeneficiariesSchema')

.allow(null)



        ,
        policyNumbers:
                JoiObj.array().items(
        
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

        )
        

.required()


        ,
        questions:
                JoiObj.array().items(
        
        JoiObj.link('#BeneficiaryAdvisoryQuestionSchema')

        )
        

.required()


        ,
        recommendation:
            
        JoiObj.link('#BeneficiaryAdvisoryRecommendedSchema')

.allow(null)



        ,
        recommendationDate:
            
                    JoiObj.object()
                

.allow(null)



        
    })
                .shared(BeneficiaryAdvisoryBeneficiariesSchema)
                .shared(BeneficiaryAdvisoryQuestionSchema)
                .shared(BeneficiaryAdvisoryRecommendedSchema)
.id('BeneficiaryAdvisoryRecommendationSchema')

export interface BeneficiaryAdvisoryRecommendation { 
    beneficiaries?: BeneficiaryAdvisoryBeneficiaries;
    policyNumbers: Array<string>;
    questions: Array<BeneficiaryAdvisoryQuestion>;
    recommendation?: BeneficiaryAdvisoryRecommended;
    recommendationDate?: object;
}
export namespace BeneficiaryAdvisoryRecommendation {
}


