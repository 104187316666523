/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { InvestmentQuestionaire } from './investmentQuestionaire';
import { InvestmentQuestionaireSchema } from './investmentQuestionaire';
import { ClimatePlus } from './climatePlus';
import { ClimatePlusSchema } from './climatePlus';
import { RecommendedAssetAllocation } from './recommendedAssetAllocation';
import { RecommendedAssetAllocationSchema } from './recommendedAssetAllocation';
import { InvestmentConcept } from './investmentConcept';
import { InvestmentConceptSchema } from './investmentConcept';
import { LossAbility } from './lossAbility';
import { LossAbilitySchema } from './lossAbility';
import { RiskWillingness } from './riskWillingness';
import { RiskWillingnessSchema } from './riskWillingness';
import { Profile } from './profile';
import { ProfileSchema } from './profile';
import * as JoiObj from "joi";


    export const InvestmentRecommendationSchema = JoiObj.object({
        assetAllocation:
            
        JoiObj.link('#RecommendedAssetAllocationSchema')


.required()


        ,
        calculatedClimatePlus:
            
        JoiObj.link('#ClimatePlusSchema')


.required()


        ,
        calculatedInvestmentConcept:
            
        JoiObj.link('#InvestmentConceptSchema')


.required()


        ,
        calculatedLossAbility:
            
        JoiObj.link('#LossAbilitySchema')


.required()


        ,
        calculatedRiskWilingness:
            
        JoiObj.link('#RiskWillingnessSchema')


.required()


        ,
        created:
            
                    JoiObj.number()
                


.required()


        ,
        investmentHorizont:
            
                    JoiObj.number()
                


.required()


        ,
        investmentProfile:
            
        JoiObj.link('#ProfileSchema')


.required()


        ,
        investmentQuestionaire:
            
        JoiObj.link('#InvestmentQuestionaireSchema')


.required()


        ,
        recommendedClimatePercent:
            
                    JoiObj.number()
                


.required()


        
    })
                .shared(RecommendedAssetAllocationSchema)
                .shared(ClimatePlusSchema)
                .shared(InvestmentConceptSchema)
                .shared(LossAbilitySchema)
                .shared(RiskWillingnessSchema)
                .shared(ProfileSchema)
                .shared(InvestmentQuestionaireSchema)
.id('InvestmentRecommendationSchema')

export interface InvestmentRecommendation { 
    assetAllocation: RecommendedAssetAllocation;
    calculatedClimatePlus: ClimatePlus;
    calculatedInvestmentConcept: InvestmentConcept;
    calculatedLossAbility: LossAbility;
    calculatedRiskWilingness: RiskWillingness;
    created: number;
    investmentHorizont: number;
    investmentProfile: Profile;
    investmentQuestionaire: InvestmentQuestionaire;
    recommendedClimatePercent: number;
}
export namespace InvestmentRecommendation {
}


