/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ServiceInfo } from './serviceInfo';
import { ServiceInfoSchema } from './serviceInfo';
import { SigneringSvar } from './signeringSvar';
import { SigneringSvarSchema } from './signeringSvar';
import { MitIdSigningIframe } from './mitIdSigningIframe';
import { MitIdSigningIframeSchema } from './mitIdSigningIframe';
import * as JoiObj from "joi";


    export const SigningIframeResultSchema = JoiObj.object({
        serviceInfo:
            
        JoiObj.link('#ServiceInfoSchema')

.allow(null)



        ,
        fejl:
            
                    JoiObj.boolean()
                


.required()


        ,
        fejlTekst:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        fejlkode:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        mitIdSigningIframe:
            
        JoiObj.link('#MitIdSigningIframeSchema')


.required()


        
    })
                .shared(ServiceInfoSchema)
                .shared(MitIdSigningIframeSchema)
.id('SigningIframeResultSchema')

export interface SigningIframeResult extends SigneringSvar { 
    mitIdSigningIframe: MitIdSigningIframe;
}

