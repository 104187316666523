import { NavigationEnd, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  forkJoin,
  Observable,
  Subject,
  Subscription,
  timer
} from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { SessionTimeoutService } from './session-timeout.service';
import { MatDialogRef } from '@angular/material/dialog';
import { SessionTimeoutModalComponent } from './session-timeout-modal.component';

@Injectable()
export class SessionTimeoutExtenderService {
  private static readonly TIMEOUT_OFFSET_SOFT: number = 6;
  private extendTimer: Subscription;
  private unsubscribe = new Subject<void>();
  private extenderDialog:
    | MatDialogRef<SessionTimeoutModalComponent>
    | undefined;

  private openDialogSubject: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );

  constructor(
    private readonly http: HttpClient,
    private readonly router: Router,
    private readonly sessionTimeoutService: SessionTimeoutService
  ) {}

  public getOpenDialog(): Observable<boolean> {
    return this.openDialogSubject.asObservable();
  }

  public startTimer(): void {
    this.extendTimer = this.createTimer(this.getMillisBeforeModalPopup());
    this.router.events.subscribe(ev => {
      if (ev instanceof NavigationEnd) {
        this.reset();
      }
    });
  }

  public stopTimer(): void {
    if (this.extendTimer) {
      this.extendTimer.unsubscribe();
    }
  }

  public extendTimeout(): void {
    this.sessionTimeoutService.resetTimeout(); //reset hard timeout
    this.reset();

    const time = Date.now().toString(10);
    forkJoin([
      this.http.get(`/ds/api/admin/ping?dsTime=${time}`),
      this.http.get(`/sk/api/ping?skTime=${time}`)
    ]).subscribe({
      next: () => {
        this.openDialogSubject.next(false);
      }
    });
  }

  private createTimer(timeInMilliseconds: number): Subscription {
    return timer(timeInMilliseconds, timeInMilliseconds)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
        this.openDialogSubject.next(true);
      });
  }

  private getMillisBeforeModalPopup(): number {
    return this.toMilliseconds(
      this.sessionTimeoutService.calcTimeoutInMinutes(
        SessionTimeoutExtenderService.TIMEOUT_OFFSET_SOFT
      )
    );
  }

  private toMilliseconds(minutes: number): number {
    return minutes * 1000 * 60;
  }

  private reset(): void {
    this.stopTimer();
    this.extendTimer = this.createTimer(this.getMillisBeforeModalPopup());
  }

  public getExtenderDialog():
    | MatDialogRef<SessionTimeoutModalComponent>
    | undefined {
    return this.extenderDialog;
  }

  public setExtenderDialog(
    extenderDialog: MatDialogRef<SessionTimeoutModalComponent> | undefined
  ) {
    this.extenderDialog = extenderDialog;
  }
}
