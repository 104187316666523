import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'co-change-indicator',
  templateUrl: './change-indicator.component.html',
  styleUrls: ['./change-indicator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChangeIndicatorComponent {
  @Input()
  public readonly unit: string;

  @Input()
  public readonly value: number;

  @Input()
  public readonly locale: string = 'da-DK';

  public get direction(): string {
    if (this.value > 0) {
      return 'up';
    } else if (this.value < 0) {
      return 'down';
    }
    return 'zero';
  }
}
