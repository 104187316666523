import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
  OnInit
} from '@angular/core';
import { BehaviorSubject, take } from 'rxjs';
import { Choice } from './insurance-guide-risk-step.model';
import { FinancialSituationApiService, InsuranceApiService } from '@pfa/api';
import {
  InsuranceGuideConfigurationService,
  InsuranceGuideQuestion,
  InsuranceGuideQuestionValue
} from '../../insurance-guide-configuration.service';
import { GlobalWorkingService } from '@pfaform';
import { switchMap } from 'rxjs/operators';
import { InsuranceGuideService } from '../../insurance-guide.service';
import {
  ForsikringDetaljer,
  ForsikringStore,
  OekonomiDetaljer
} from '@pfa/gen';

@Component({
  selector: 'mitpfa-insurance-guide-risk-step',
  templateUrl: './insurance-guide-risk-step.component.html',
  styleUrls: ['./insurance-guide-risk-step.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InsuranceGuideRiskStepComponent implements OnInit {
  @Input() financialSituation: OekonomiDetaljer;
  @Input() currentQuestion: BehaviorSubject<InsuranceGuideQuestionValue>;
  @Input() insuranceDetails: ForsikringDetaljer;

  private readonly forsikringStore: ForsikringStore = inject(ForsikringStore);

  readonly financialSituationApiService: FinancialSituationApiService = inject(
    FinancialSituationApiService
  );
  readonly insuranceServise: InsuranceApiService = inject(InsuranceApiService);
  readonly globalWorkingService: GlobalWorkingService =
    inject(GlobalWorkingService);
  readonly insuranceGuideService: InsuranceGuideService = inject(
    InsuranceGuideService
  );
  private readonly insuranceGuideConfigurationService: InsuranceGuideConfigurationService =
    inject(InsuranceGuideConfigurationService);

  public choices: Choice[] = [
    new Choice('HOEJ', 'DL.FG01.C1867', 'DL.FG01.C1868'),
    new Choice('MELLEM', 'DL.FG01.C1869', 'DL.FG01.C1870'),
    new Choice('LAV', 'DL.FG01.C1871', 'DL.FG01.C1872')
  ];

  ngOnInit(): void {
    this.updatePreviouslySelected();
  }

  public choose(choice: Choice): void {
    this.globalWorkingService.show();
    this.financialSituation.taeNytte = choice.value;
    this.financialSituationApiService
      .updateFinancialSituation(this.financialSituation)
      .pipe(
        switchMap(() => {
          this.forsikringStore.invalidateforsikringdetaljerIndex();
          return this.insuranceServise.getInsuranceDetails();
        }),
        take(1)
      )
      .subscribe(insuranceDetails => {
        this.insuranceDetails = insuranceDetails;
        if (
          this.insuranceGuideService.shouldShowRiskLocQuestion(
            this.financialSituation,
            this.insuranceDetails
          )
        ) {
          this.currentQuestion.next({ value: InsuranceGuideQuestion.loc });
        } else {
          this.currentQuestion.next({
            value: this.insuranceGuideConfigurationService.showDebtStep
              ? InsuranceGuideQuestion.debt
              : InsuranceGuideQuestion.recommendation
          });
        }

        window.scroll(0, 0);
      });
  }

  public goToPreviousPage(): void {
    this.currentQuestion.next({
      value: this.insuranceGuideService.isShortFlowRunning()
        ? InsuranceGuideQuestion.intro
        : InsuranceGuideQuestion.assets,
      back: true
    });
  }

  private updatePreviouslySelected(): void {
    if (!this.financialSituation?.taeNytte) {
      return;
    }

    this.choices.forEach(choice => {
      choice.previouslySelected =
        choice.value === this.financialSituation?.taeNytte;
    });
  }
}
