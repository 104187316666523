import { Injectable } from '@angular/core';
import {
  MiscTrackingService,
  NbaOfferClickTrackingItem,
  NbaOfferTrackingItem,
  TrackingName
} from '@pfa/core';
import { Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { DynamicAdvisingOfferExtended } from '../../app/pension-check/pension-check.service';
import {
  DynamicAdvisingCalculation,
  DynamicAdvisingOffer,
  DynamicAdvisingOfferGroup,
  DynamicadvisingStore
} from '@pfa/gen';
import { DynamicAdvisingFilterService } from '@mitpfa/shared/services/dynamic-advising-filter.service';

@Injectable({
  providedIn: 'root'
})
export class NextBestActionService {
  private readonly pensionCheckServiceSubject$: ReplaySubject<
    Array<DynamicAdvisingOfferGroup>
  > = new ReplaySubject<Array<DynamicAdvisingOfferGroup>>(1);

  constructor(
    private readonly trackingService: MiscTrackingService,
    private readonly dynamicadvisingStore: DynamicadvisingStore,
    private readonly dynamicadvisingFilterService: DynamicAdvisingFilterService
  ) {
    this.getAllSortedNbaCards()
      .pipe(map(res => res.offerGroups))
      .subscribe({
        next: (res: Array<DynamicAdvisingOfferGroup>) => {
          this.pensionCheckServiceSubject$.next(res);
        },
        error: () => {
          // Die quietly
        }
      });
  }

  public getPensionCheck$(): Observable<Array<DynamicAdvisingOfferGroup>> {
    return this.pensionCheckServiceSubject$.asObservable();
  }

  public getAllSortedNbaCards(
    offerIdsToFilterBy?: string[]
  ): Observable<DynamicAdvisingCalculation> {
    return this.dynamicadvisingStore
      .dynamicadvisingGet()
      .pipe(
        map((dynamicCalculation: DynamicAdvisingCalculation) =>
          this.dynamicadvisingFilterService.filterAndSortOffers(
            offerIdsToFilterBy,
            dynamicCalculation
          )
        )
      );
  }

  public getDynamicAdvisingOfferWithHighestRating(
    dynamicAdvisingOffers: DynamicAdvisingOffer[]
  ): DynamicAdvisingOffer {
    let res: DynamicAdvisingOffer;
    dynamicAdvisingOffers.forEach(
      (dynamicAdvisingOffer: DynamicAdvisingOffer) => {
        if (
          (!res && dynamicAdvisingOffer.offerNbaValue > 0) ||
          dynamicAdvisingOffer.offerNbaValue > res.offerNbaValue
        ) {
          res = dynamicAdvisingOffer;
        }
      }
    );
    return res;
  }

  public trackNbaOfferClick(
    trackingName: TrackingName,
    nbaId: string,
    offerName: string,
    offerHash: string,
    offerIndex: number,
    offerSeverity: number
  ) {
    this.trackingService.trackNbaOfferClick(
      trackingName,
      nbaId,
      this.buildNbaOfferClickTracking(
        offerName,
        offerHash,
        offerIndex,
        offerSeverity
      )
    );
  }

  public ensureOnlyOneDataOffer(
    sigmaOffers: DynamicAdvisingOffer[]
  ): DynamicAdvisingOfferExtended[] {
    const offers: DynamicAdvisingOfferExtended[] = [];
    let useDataOffer = true;
    let pushOffer: boolean;
    sigmaOffers.forEach((sigmaOffer, index) => {
      pushOffer = true;
      if (this.isDataOffer(sigmaOffer)) {
        if (!useDataOffer) {
          pushOffer = false;
        }
        useDataOffer = false;
      }
      if (pushOffer) {
        const dynamicAdvisingOfferExtended =
          sigmaOffer as DynamicAdvisingOfferExtended;
        dynamicAdvisingOfferExtended.offerTrackingIndex = index;
        offers.push(dynamicAdvisingOfferExtended);
      }
    });
    return offers;
  }

  private buildNbaOfferClickTracking(
    offerName: string,
    offerHash: string,
    offerIndex: number,
    offerSeverity: number
  ): NbaOfferClickTrackingItem[] {
    const nbaOfferClickTrackingItemList: NbaOfferClickTrackingItem[] = [];
    const nbaOfferClickTrackingItem: NbaOfferClickTrackingItem = {
      offerName: offerName,
      offerHash: offerHash,
      offerIndex: offerIndex + 1,
      offerSeverity: offerSeverity,
      offerClickedAction: 'click'
    };
    nbaOfferClickTrackingItemList.push(nbaOfferClickTrackingItem);

    return nbaOfferClickTrackingItemList;
  }

  public isDataOffer(sigmaOffer: DynamicAdvisingOffer): boolean {
    return (
      sigmaOffer.offerId === 'ENTER_MARITAL_STATUS' ||
      sigmaOffer.offerId === 'ENTER_DEPENDENTS' ||
      sigmaOffer.offerId === 'ENTER_WEALTH' ||
      sigmaOffer.offerId === 'ENTER_DEBT' ||
      sigmaOffer.offerId === 'ENTER_PARTNER_SALARY' ||
      sigmaOffer.offerId === 'RETRIEVE_PENSION_INFO_REPORT'
    );
  }

  public buildNbaOfferTrackingList(
    offers: DynamicAdvisingOffer[]
  ): NbaOfferTrackingItem[] {
    const nbaOfferTrackingItemList: NbaOfferTrackingItem[] = [];
    offers.forEach((offer, index) => {
      const nbaOfferTrackingItem: NbaOfferTrackingItem = {
        offerName: offer.offerId,
        offerHash: offer.offerHash,
        offerIndex: index + 1,
        offerSeverity: offer.offerSeverity
      };
      nbaOfferTrackingItemList.push(nbaOfferTrackingItem);
    });
    return nbaOfferTrackingItemList;
  }
}
