import { Injectable } from '@angular/core';
import { InvestmentApiService } from '@pfa/api';
import {
  ConfirmChoiceType,
  MiscTrackingService,
  TrackingName
} from '@pfa/core';
import {
  InForceSelection,
  InForceSelectionClimate,
  InForceSelectionProfile,
  Profil
} from '@pfa/gen';
import { Observable, tap } from 'rxjs';

@Injectable()
export class InvestmentService {
  constructor(
    private readonly investmentApiService: InvestmentApiService,
    private readonly trackingService: MiscTrackingService
  ) {}

  public getInForceInvestmentPayload(
    profile: Profil | undefined,
    recommendationProfile: Profil | undefined,
    policyId: string
  ): InForceSelectionProfile {
    return {
      inForceSelectionType: 'Investment',
      timestamp: new Date(),
      policyId,
      passiveAcceptance: false,
      selection: profile?.id,
      recommendation: recommendationProfile?.id,
      employeeNumber: ''
    };
  }

  public getInForceClimatePayload(
    policyId: string,
    percent: number,
    recommendationPercent: number
  ): InForceSelectionClimate {
    return {
      inForceSelectionType: 'Climate',
      timestamp: new Date(),
      policyId,
      passiveAcceptance: false,
      selection: percent,
      defaultSelection: percent,
      recommendation: recommendationPercent,
      employeeNumber: ''
    };
  }

  public getInForcePayload(
    inForceType: string,
    policyId: string
  ): InForceSelection {
    return {
      inForceSelectionType: inForceType,
      timestamp: new Date(),
      policyId,
      passiveAcceptance: false,
      employeeNumber: ''
    };
  }

  public postInvestmentConfirmChoice(
    profile: Profil | undefined,
    recommendationProfile: Profil | undefined,
    policyId: string,
    adobeConceptTrackingRecommendedValue: string,
    confirmChoiceType: ConfirmChoiceType,
    isOnboarding: boolean
  ): Observable<unknown> {
    const payload = this.getInForceInvestmentPayload(
      profile,
      recommendationProfile,
      policyId
    );

    return this.investmentApiService.saveDefaultProfileChoice(payload).pipe(
      tap(() => {
        this.trackingService.trackConfirmChoiceClick(
          isOnboarding
            ? TrackingName.INVESTMENT_ONBOARDING
            : TrackingName.INVESTMENT,
          [
            {
              itemConfirmName: `pfainvest: profile ${profile?.id}`,
              itemRecommendedName: adobeConceptTrackingRecommendedValue,
              itemConfirmType: confirmChoiceType
            }
          ]
        );
      })
    );
  }

  public postOptionalConfirmChoice(
    inForceType: string,
    policyId: string,
    adobeConceptTrackingRecommendedValue: string,
    confirmChoiceType: ConfirmChoiceType,
    isOnboarding: boolean
  ): Observable<unknown> {
    const payload = this.getInForcePayload(inForceType, policyId);

    return this.investmentApiService.saveOptionalProfileChoice(payload).pipe(
      tap(() => {
        this.trackingService.trackConfirmChoiceClick(
          isOnboarding
            ? TrackingName.INVESTMENT_GUIDE_ONBOARDING
            : TrackingName.INVESTMENT_GUIDE,
          [
            {
              itemConfirmName: 'pfaoptional',
              itemRecommendedName: adobeConceptTrackingRecommendedValue,
              itemConfirmType: confirmChoiceType
            }
          ]
        );
      })
    );
  }

  public postYouInvestConfirmChoice(
    inForceType: string,
    policyId: string,
    adobeConceptTrackingRecommendedValue: string,
    itemConfirmType: ConfirmChoiceType,
    isOnboarding: boolean
  ): Observable<unknown> {
    const youInvestConfirmItemName = 'youinvest';
    const payload = this.getInForcePayload(inForceType, policyId);

    return this.investmentApiService.saveYouInvestChoice(payload).pipe(
      tap(() => {
        this.trackingService.trackConfirmChoiceClick(
          isOnboarding
            ? TrackingName.INVESTMENT_GUIDE_ONBOARDING
            : TrackingName.INVESTMENT_GUIDE,
          [
            {
              itemConfirmName: youInvestConfirmItemName,
              itemRecommendedName: adobeConceptTrackingRecommendedValue,
              itemConfirmType: itemConfirmType
            }
          ]
        );
      })
    );
  }

  public postClimateConfirmChoice(
    policyId: string,
    percent: number,
    recommendationPercent: number,
    confirmChoiceType: ConfirmChoiceType,
    isOnboarding: boolean
  ): Observable<unknown> {
    const payload = this.getInForceClimatePayload(
      policyId,
      percent,
      recommendationPercent
    );

    return this.investmentApiService
      .saveDefaultClimateProfileChoice(payload)
      .pipe(
        tap(() => {
          this.trackingService.trackConfirmChoiceClick(
            isOnboarding
              ? TrackingName.INVESTMENT_GUIDE_ONBOARDING
              : TrackingName.INVESTMENT_GUIDE,
            [
              {
                itemConfirmName: `pfainvest: climate ${percent}`,
                itemRecommendedName: `pfainvest: climate ${recommendationPercent}`,
                itemConfirmType: confirmChoiceType
              }
            ]
          );
        })
      );
  }
}
