import { inject, Injectable } from '@angular/core';
import {
  BroadcastMessageType,
  BroadcastService,
  GlobalWorkingService,
  NotificationService,
  NotificationType,
  SigningPopupComponent,
  SigningPopupResponse
} from '@pfaform';
import { PopupStyle } from '@pfa/models';
import { MatDialog } from '@angular/material/dialog';
import { Observable, of, switchMap } from 'rxjs';
import {
  GodkendSvar,
  IndividualcustomerdossierStore,
  SignatureIframeResponse,
  SignereRequestObj,
  SimulatorTileEnum
} from '@pfa/gen';
import {
  AutomaticHealthAcceptanceApiService,
  BasketApiService,
  ErrorHandlerApiService
} from '@pfa/api';
import { Router } from '@angular/router';
import {
  ContentService,
  ContentUtilService,
  OrderItem,
  OrderTrackingService,
  TrackingName
} from '@pfa/core';
import { SigningCompletedPopupComponent } from '../../app/signing-completed-popup/signing-completed-popup.component';

@Injectable({
  providedIn: 'root'
})
export class SigningService {
  // INJECTIONS
  private readonly router: Router = inject(Router);
  private readonly dialog: MatDialog = inject(MatDialog);
  private readonly globalWorkingService: GlobalWorkingService =
    inject(GlobalWorkingService);
  private readonly errorHandlerApiService: ErrorHandlerApiService = inject(
    ErrorHandlerApiService
  );
  private readonly orderTrackingService: OrderTrackingService =
    inject(OrderTrackingService);
  private readonly basketApiService: BasketApiService =
    inject(BasketApiService);
  private readonly contentService: ContentService = inject(ContentService);
  private readonly contentUtilService: ContentUtilService =
    inject(ContentUtilService);
  private readonly broadcastService: BroadcastService =
    inject(BroadcastService);
  private readonly automaticHealthAcceptanceApiService: AutomaticHealthAcceptanceApiService =
    inject(AutomaticHealthAcceptanceApiService);
  private readonly notificationService: NotificationService =
    inject(NotificationService);
  private readonly individualcustomerdossierStore: IndividualcustomerdossierStore =
    inject(IndividualcustomerdossierStore);

  performBasketSigning(
    orderItems: OrderItem[],
    simulatorTileEnum: SimulatorTileEnum
  ): Observable<GodkendSvar> {
    return this.showSigningPopup(
      this.getBasketSigningFunction(simulatorTileEnum),
      orderItems
    ).pipe(
      switchMap(signingPopupResponse => {
        if (signingPopupResponse.error) {
          return of();
        }
        return this.handleSigningResponse(
          signingPopupResponse,
          orderItems,
          simulatorTileEnum
        );
      })
    );
  }

  showSigningPopup(
    signingFunction: () => Observable<SignatureIframeResponse>,
    orderItems: OrderItem[],
    confirmPlacement?: TrackingName
  ): Observable<SigningPopupResponse> {
    this.orderTrackingService.trackOrderSignatureInitiated(
      orderItems,
      confirmPlacement
    );

    return new Observable<SigningPopupResponse>(observer => {
      this.dialog
        .open(SigningPopupComponent, {
          data: {
            signingIframeObservable: signingFunction()
          },
          panelClass: PopupStyle.PopupExtraLargeMax
        })
        .afterClosed()
        .subscribe((signingPopupResponse: SigningPopupResponse) => {
          if (
            signingPopupResponse?.hasSignatureIframeError ||
            signingPopupResponse?.error
          ) {
            observer.error(signingPopupResponse);
            this.errorHandlerApiService.saveSigningErrorToLog(
              signingPopupResponse.error,
              this.router.url
            );
            this.orderTrackingService.trackOrderSignatureFailed(
              orderItems,
              confirmPlacement
            );
          } else if (signingPopupResponse?.success) {
            observer.next(signingPopupResponse);
          }
        });
    });
  }

  getBasketSigningFunction(
    simulatorTileEnum: SimulatorTileEnum
  ): () => Observable<SignatureIframeResponse> {
    return (): Observable<SignatureIframeResponse> => {
      const signingRequest: SignereRequestObj =
        this.basketApiService.getSigningRequest(
          simulatorTileEnum,
          this.contentService.getLanguage().toUpperCase()
        );
      return this.basketApiService.sign(signingRequest);
    };
  }

  handleSigningResponse(
    signingPopupResponse: SigningPopupResponse,
    orderItems: OrderItem[],
    simulatorTileEnum: SimulatorTileEnum,
    confirmPlacement?: TrackingName
  ): Observable<GodkendSvar> {
    this.globalWorkingService.show();
    const signRequest: SignereRequestObj =
      this.basketApiService.getSigningRequest(
        simulatorTileEnum,
        this.contentService.getLanguage().toUpperCase()
      );
    signRequest.revisionKey = signingPopupResponse.revisionKey;
    signRequest.mitIdSig = signingPopupResponse.content;

    return new Observable<GodkendSvar>(observer => {
      this.basketApiService.signed(signRequest).subscribe({
        next: (response: GodkendSvar) => {
          if (!response.cancelled) {
            if (response && !response.fejl) {
              this.broadcastService.broadcastMessage(
                BroadcastMessageType.APPROVED_CHANGES
              );
              this.orderTrackingService.trackOrderSignatureCompleted(
                orderItems,
                confirmPlacement
              );
              if (response.spoergeskema) {
                this.automaticHealthAcceptanceApiService
                  .getQuestionnare(
                    this.contentService.getLanguage().toUpperCase()
                  )
                  .subscribe(() => {
                    this.globalWorkingService.hide();
                    observer.next(response);
                    this.individualcustomerdossierStore.invalidateAll();
                  });
              } else {
                observer.next(response);
              }
            } else {
              this.orderTrackingService.trackOrderSignatureFailed(
                orderItems,
                confirmPlacement
              );
              observer.error();
            }
          }
          this.globalWorkingService.hide();
        },
        error: () => {
          this.orderTrackingService.trackOrderSignatureFailed(
            orderItems,
            confirmPlacement
          );
          observer.error();
        }
      });
    });
  }

  showSigningError(): void {
    this.notificationService.showNotification({
      message: `${this.contentUtilService.getContent(
        'DL.GO01.C25'
      )}. ${this.contentUtilService.getContent('DL.LI01.C29')}`,
      type: NotificationType.ERROR,
      duration: -1,
      showCloseButton: true
    });
  }

  showSigningCompleted(
    godkendSvar: GodkendSvar,
    trackingName?: TrackingName
  ): Observable<boolean> {
    return this.dialog
      .open(SigningCompletedPopupComponent, {
        data: {
          godkendSvar,
          trackingName
        }
      })
      .afterClosed();
  }

  public mapToOrderItem(
    orderName: string,
    simulatortile: SimulatorTileEnum
  ): OrderItem {
    let orderItem: OrderItem;
    switch (simulatortile) {
      case SimulatorTileEnum.PpUdbetalingsplan:
        orderItem = {
          category: 'payout',
          name: 'voluntary monthly payment'
        };
        break;
      case SimulatorTileEnum.PpOpsparingsplan:
        orderItem = {
          category: 'pension-estimate',
          name: orderName
        };
        break;
      case SimulatorTileEnum.Investering:
        orderItem = {
          category: 'investment',
          name: orderName
        };
        break;
      case SimulatorTileEnum.KundeKapital:
        orderItem = {
          category: 'customer-capital',
          name: orderName
        };
        break;
      default:
        break;
    }
    return orderItem;
  }
}
