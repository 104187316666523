/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const SkattekodeSchema = JoiObj.string().valid(
        'BESKATTES'
        ,
        'Skattefri'
        ,
        'SK1'
        ,
        'SK2'
        ,
        'SK3'
        ,
        'SK4'
        ,
        'SK5'
        ,
        'SK5A'
        ,
        'SK6'
        ,
        'SK7'
        ,
        'SK9'
        ,
        'SK33'
        ,
        'Ingen'
        ,
        'Folkepension'
        ,
        'Efterloen'
        ,
        'Loen'
        
    )
.id('SkattekodeSchema')

export type Skattekode = 'BESKATTES' | 'Skattefri' | 'SK1' | 'SK2' | 'SK3' | 'SK4' | 'SK5' | 'SK5A' | 'SK6' | 'SK7' | 'SK9' | 'SK33' | 'Ingen' | 'Folkepension' | 'Efterloen' | 'Loen';

export const Skattekode = {
    Beskattes: 'BESKATTES' as Skattekode,
    Skattefri: 'Skattefri' as Skattekode,
    Sk1: 'SK1' as Skattekode,
    Sk2: 'SK2' as Skattekode,
    Sk3: 'SK3' as Skattekode,
    Sk4: 'SK4' as Skattekode,
    Sk5: 'SK5' as Skattekode,
    Sk5A: 'SK5A' as Skattekode,
    Sk6: 'SK6' as Skattekode,
    Sk7: 'SK7' as Skattekode,
    Sk9: 'SK9' as Skattekode,
    Sk33: 'SK33' as Skattekode,
    Ingen: 'Ingen' as Skattekode,
    Folkepension: 'Folkepension' as Skattekode,
    Efterloen: 'Efterloen' as Skattekode,
    Loen: 'Loen' as Skattekode
};

