import { animate, style, transition, trigger } from '@angular/animations';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit
} from '@angular/core';
import { of } from 'rxjs';
import { delay, tap } from 'rxjs/operators';

const enterAnimation = trigger('enterAnimation', [
  transition('* => visible', [
    style({
      strokeDashoffset: 38
    }),
    animate('{{ duration }}s'),
    style({
      strokeDashoffset: 0
    })
  ]),
  transition('* => reset', [
    style({
      strokeDashoffset: 38
    }),
    animate('{{ duration }}s'),
    style({
      strokeDashoffset: 0
    })
  ])
]);

@Component({
  animations: [enterAnimation],
  selector: 'co-countdown',
  templateUrl: './countdown.component.html',
  styleUrls: ['./countdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CountdownComponent implements OnInit {
  @Input()
  public readonly duration = 4;
  @Input()
  public set observedValue(v: unknown) {
    this.state = this.state === 'visible' ? 'reset' : 'visible';
  }

  public state: 'invisible' | 'visible' | 'reset' = 'invisible';

  constructor(private readonly changeDetectorRef: ChangeDetectorRef) {}

  public ngOnInit(): void {
    of({})
      .pipe(
        delay(0),
        tap(() => {
          this.state = 'visible';
          this.changeDetectorRef.detectChanges();
        })
      )
      .subscribe();
  }
}
