import {
  HealthCase,
  QuestionnaireView,
  AnswerView,
  SignResult,
  SignedRequest,
  EvaluationResult,
  GodkendSvar
} from '@pfa/gen';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AutomaticHealthAcceptanceApiService {
  constructor(private http: HttpClient) {}

  public getHealthAcceptanceCases(): Observable<Array<HealthCase>> {
    return this.http.get<Array<HealthCase>>('/ds/api/aua/healthacceptancecase');
  }

  public getQuestionnare(language: string): Observable<QuestionnaireView> {
    return this.http.get<QuestionnaireView>('/ds/api/aua/questionnaire', {
      params: { sprog: language }
    });
  }

  public updateQuestionnaire(answerView: AnswerView): Observable<void> {
    return this.http.post<void>('/ds/api/aua/questionnaire', answerView);
  }

  public getSignatureIframeTags(
    language: string,
    questionnaireId: string
  ): Observable<SignResult> {
    return this.http.post<SignResult>(
      `/ds/api/aua/questionnaire/${questionnaireId}/sign`,
      {},
      {
        params: { sprog: language }
      }
    );
  }

  public getConsentSignatureIframeTags(
    language: string,
    questionnaireId: string
  ): Observable<SignResult> {
    return this.http.post<SignResult>(
      `/ds/api/aua/questionnaire/${questionnaireId}/consent/sign`,
      {},
      {
        params: { sprog: language }
      }
    );
  }

  public approveAndVerifySignature(
    language: string,
    questionnaireId: string,
    signedRequest: SignedRequest,
    isNewBusinessFlow: boolean
  ): Observable<EvaluationResult> {
    const baseUrl = isNewBusinessFlow
      ? '/ds/api/digitalsalg/aua/questionnaire/'
      : '/ds/api/aua/questionnaire/';
    return this.http.put<EvaluationResult>(
      `${baseUrl}${questionnaireId}/sign/1`,
      signedRequest,
      {
        params: { sprog: language }
      }
    );
  }

  public approveAndVerifyConsentSignature(
    language: string,
    questionnaireId: string,
    signedRequest: SignedRequest
  ): Observable<GodkendSvar> {
    return this.http.put<GodkendSvar>(
      `/ds/api/aua/questionnaire/${questionnaireId}/consent/sign/1`,
      signedRequest,
      { params: { sprog: language } }
    );
  }
}
