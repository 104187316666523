/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UdvaelgelsesreglerDaekning } from './udvaelgelsesreglerDaekning';
import { UdvaelgelsesreglerDaekningSchema } from './udvaelgelsesreglerDaekning';
import * as JoiObj from "joi";


    export const UdvaelgelsesreglerSchema = JoiObj.object({
        ao_reg02_02:
            
        JoiObj.link('#UdvaelgelsesreglerDaekningSchema')


.required()


        ,
        ao_reg02_02_Gipp:
            
        JoiObj.link('#UdvaelgelsesreglerDaekningSchema')


.required()


        ,
        ao_reg02_03:
            
        JoiObj.link('#UdvaelgelsesreglerDaekningSchema')


.required()


        ,
        ao_reg02_03_Gipp:
            
        JoiObj.link('#UdvaelgelsesreglerDaekningSchema')


.required()


        ,
        ao_reg02_04:
            
        JoiObj.link('#UdvaelgelsesreglerDaekningSchema')


.required()


        ,
        ao_reg02_04_Gipp:
            
        JoiObj.link('#UdvaelgelsesreglerDaekningSchema')


.required()


        ,
        ao_reg02_05:
            
        JoiObj.link('#UdvaelgelsesreglerDaekningSchema')


.required()


        ,
        ao_reg02_05_Gipp:
            
        JoiObj.link('#UdvaelgelsesreglerDaekningSchema')


.required()


        ,
        beregningsalder:
            
                    JoiObj.number()
                


.required()


        ,
        masteraftaler:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        selskabskode:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        
    })
                .shared(UdvaelgelsesreglerDaekningSchema)
                .shared(UdvaelgelsesreglerDaekningSchema)
                .shared(UdvaelgelsesreglerDaekningSchema)
                .shared(UdvaelgelsesreglerDaekningSchema)
                .shared(UdvaelgelsesreglerDaekningSchema)
                .shared(UdvaelgelsesreglerDaekningSchema)
                .shared(UdvaelgelsesreglerDaekningSchema)
                .shared(UdvaelgelsesreglerDaekningSchema)
.id('UdvaelgelsesreglerSchema')

export interface Udvaelgelsesregler { 
    ao_reg02_02: UdvaelgelsesreglerDaekning;
    ao_reg02_02_Gipp: UdvaelgelsesreglerDaekning;
    ao_reg02_03: UdvaelgelsesreglerDaekning;
    ao_reg02_03_Gipp: UdvaelgelsesreglerDaekning;
    ao_reg02_04: UdvaelgelsesreglerDaekning;
    ao_reg02_04_Gipp: UdvaelgelsesreglerDaekning;
    ao_reg02_05: UdvaelgelsesreglerDaekning;
    ao_reg02_05_Gipp: UdvaelgelsesreglerDaekning;
    beregningsalder: number;
    masteraftaler: string;
    selskabskode: string;
}

