import {
  ChangeDetectorRef,
  OnDestroy,
  Pipe,
  PipeTransform
} from '@angular/core';
import { Subscription } from 'rxjs';
import { ContentUtilService } from './content-util.service';
import { ContentService } from './content.service';

@Pipe({
  name: 'content',
  pure: false
})
export class ContentPipe implements PipeTransform, OnDestroy {
  private readonly subscription: Subscription;

  constructor(
    private readonly contentUtilService: ContentUtilService,
    private readonly contentService: ContentService,
    private readonly changeDetectorRef: ChangeDetectorRef
  ) {
    this.subscription = this.contentService.languageState.subscribe(() =>
      this.changeDetectorRef.markForCheck()
    );
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  transform(value: any, ...args: any[]) {
    //allow to put array with variables into getContent by pipe
    if (args?.length === 1 && Array.isArray(args[0])) {
      args = args[0];
    }

    return this.contentUtilService.getContent(value, args);
  }
}
