import { AdvantageInsuranceCollageComponent } from './advantage-insurance/advantage-insurance-collage/advantage-insurance-collage.component';
import { AdvantageInsuranceComponent } from './advantage-insurance/advantage-insurance.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '@pfa/core';
import { MatButtonModule } from '@angular/material/button';
import { AdvantageHeaderComponent } from './shared/advantage-header/advantage-header.component';
import { AdvantagesRoutingModule } from './advantages-routing.module';
import { AdvantageVipComponent } from './advantage-vip/advantage-vip.component';
import { PfaFormModule } from '@pfaform';
import { ContactCardComponent } from './advantage-vip/advantage-vip-contact/contact-card.component';
import { IconModule } from '@pfa/icons';
import { AdvantageHousingComponent } from './advantage-housing/advantage-housing.component';

@NgModule({
  declarations: [
    AdvantageVipComponent,
    AdvantageInsuranceComponent,
    AdvantageHousingComponent,
    AdvantageInsuranceCollageComponent,
    AdvantageHeaderComponent,
    ContactCardComponent
  ],
  imports: [
    AdvantagesRoutingModule,
    CommonModule,
    CoreModule,
    MatButtonModule,
    PfaFormModule,
    IconModule
  ],
  exports: [],
  providers: []
})
export class AdvantagesModule {}
