/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const AnswerTypeSchema = JoiObj.string().valid(
        'TEXT'
        ,
        'SINGLE'
        ,
        'MULTIPLE'
        ,
        'INTEGER'
        
    )
.id('AnswerTypeSchema')

export type AnswerType = 'TEXT' | 'SINGLE' | 'MULTIPLE' | 'INTEGER';

export const AnswerType = {
    Text: 'TEXT' as AnswerType,
    Single: 'SINGLE' as AnswerType,
    Multiple: 'MULTIPLE' as AnswerType,
    Integer: 'INTEGER' as AnswerType
};

