import { Component, Input } from '@angular/core';

@Component({
  selector: 'co-level-bar',
  templateUrl: './level-bar.component.html',
  styleUrls: ['./level-bar.component.scss']
})
export class LevelBarComponent {
  @Input()
  public set value(v: number) {
    this._width = `${Math.max(0, Math.min(100, v))}%`;
  }

  public get width(): string {
    return this._width;
  }

  @Input()
  public labelMin = 'min';

  @Input()
  public labelMax = 'max';

  private _width: string;
}
