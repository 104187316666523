<div class="footer-left">
  <a
    [coContent]="isDanish ? 'DL.FO01.C400' : 'DL.FO01.C401'"
    (click)="switchedLanguage()"
    [attr.data-test-id]="isDanish ? 'FO01-language-EN' : 'FO01-language-DA'"
  ></a>
</div>
<div class="footer-right">
  <a
    coContent="DL.FO01.C416"
    [href]="'DL.FO01.C417' | content"
    data-test-id="FO01-cookies"
    target="_blank"
  ></a>
  <a coContent="DL.FO01.C413" (click)="openCookiePopup()"></a>
  <a
    coContent="DL.FO01.C418"
    [href]="'DL.FO01.C419' | content"
    data-test-id="FO01-pfasund.dk"
    target="_blank"
  ></a>
</div>
