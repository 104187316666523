/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const TradeTypeSchema = JoiObj.string().valid(
        'INVESTMENTCERTIFICATES'
        ,
        'SHARES'
        ,
        'BONDS'
        ,
        'OTHERS'
        
    )
.id('TradeTypeSchema')

export type TradeType = 'INVESTMENTCERTIFICATES' | 'SHARES' | 'BONDS' | 'OTHERS';

export const TradeType = {
    Investmentcertificates: 'INVESTMENTCERTIFICATES' as TradeType,
    Shares: 'SHARES' as TradeType,
    Bonds: 'BONDS' as TradeType,
    Others: 'OTHERS' as TradeType
};

