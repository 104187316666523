import { Injectable } from '@angular/core';
import { DataPoint, LineChartArea } from '@pfaform';

@Injectable()
export class IncestmentGuideRiskStepRiskProfileDataService {
  lowRisk: DataPoint[] = [
    {
      y: 0,
      x: 0
    },
    {
      y: -200,
      x: 1
    },
    {
      y: 50,
      x: 2
    },
    {
      y: 200,
      x: 3
    },
    {
      y: -100,
      x: 4
    },
    {
      y: 300,
      x: 5
    },
    {
      y: 500,
      x: 6
    },
    {
      y: 300,
      x: 7
    },
    {
      y: 600,
      x: 8
    },
    {
      y: 800,
      x: 9
    },
    {
      y: 750,
      x: 10
    }
  ];

  mediumRisk: DataPoint[] = [
    {
      y: 0,
      x: 0
    },
    {
      y: -50,
      x: 1
    },
    {
      y: 100,
      x: 2
    },
    {
      y: -100,
      x: 3
    },
    {
      y: 400,
      x: 4
    },
    {
      y: 200,
      x: 5
    },
    {
      y: -100,
      x: 6
    },
    {
      y: 800,
      x: 7
    },
    {
      y: 300,
      x: 8
    },
    {
      y: 1200,
      x: 9
    },
    {
      y: 1300,
      x: 10
    }
  ];

  highRisk: DataPoint[] = [
    {
      y: 0,
      x: 0
    },
    {
      y: -100,
      x: 1
    },
    {
      y: 200,
      x: 2
    },
    {
      y: -200,
      x: 3
    },
    {
      y: 400,
      x: 4
    },
    {
      y: -300,
      x: 5
    },
    {
      y: 900,
      x: 6
    },
    {
      y: 500,
      x: 7
    },
    {
      y: 1700,
      x: 8
    },
    {
      y: 1000,
      x: 9
    },
    {
      y: 1600,
      x: 10
    }
  ];

  lowRiskArea: LineChartArea = {
    bottomBorder: [
      {
        y: 0,
        x: 0
      },
      {
        y: -350,
        x: 1
      },
      {
        y: -330,
        x: 2
      },
      {
        y: -310,
        x: 3
      },
      {
        y: -290,
        x: 4
      },
      {
        y: -260,
        x: 5
      },
      {
        y: -220,
        x: 6
      },
      {
        y: -190,
        x: 7
      },
      {
        y: -160,
        x: 8
      },
      {
        y: -130,
        x: 9
      },
      {
        y: -100,
        x: 10
      }
    ],
    topBorder: [
      {
        y: 0,
        x: 0
      },
      {
        y: 150,
        x: 1
      },
      {
        y: 301,
        x: 2
      },
      {
        y: 454,
        x: 3
      },
      {
        y: 610,
        x: 4
      },
      {
        y: 770,
        x: 5
      },
      {
        y: 935,
        x: 6
      },
      {
        y: 1106,
        x: 7
      },
      {
        y: 1284,
        x: 8
      },
      {
        y: 1469,
        x: 9
      },
      {
        y: 1663,
        x: 10
      }
    ]
  };

  mediumRiskArea: LineChartArea = {
    bottomBorder: [
      {
        y: 0,
        x: 0
      },
      {
        y: -200,
        x: 1
      },
      {
        y: -220,
        x: 2
      },
      {
        y: -250,
        x: 3
      },
      {
        y: -250,
        x: 4
      },
      {
        y: -250,
        x: 5
      },
      {
        y: -250,
        x: 6
      },
      {
        y: -250,
        x: 7
      },
      {
        y: -250,
        x: 8
      },
      {
        y: -250,
        x: 9
      },
      {
        y: -250,
        x: 10
      }
    ],
    topBorder: [
      {
        y: 0,
        x: 0
      },
      {
        y: 300,
        x: 1
      },
      {
        y: 500,
        x: 2
      },
      {
        y: 701,
        x: 3
      },
      {
        y: 904,
        x: 4
      },
      {
        y: 1110,
        x: 5
      },
      {
        y: 1320,
        x: 6
      },
      {
        y: 1535,
        x: 7
      },
      {
        y: 1756,
        x: 8
      },
      {
        y: 2024,
        x: 9
      },
      {
        y: 2300,
        x: 10
      }
    ]
  };

  highRiskArea: LineChartArea = {
    bottomBorder: [
      {
        y: 0,
        x: 0
      },
      {
        y: -300,
        x: 1
      },
      {
        y: -400,
        x: 2
      },
      {
        y: -500,
        x: 3
      },
      {
        y: -510,
        x: 4
      },
      {
        y: -510,
        x: 5
      },
      {
        y: -530,
        x: 6
      },
      {
        y: -540,
        x: 7
      },
      {
        y: -550,
        x: 8
      },
      {
        y: -560,
        x: 9
      },
      {
        y: -570,
        x: 10
      }
    ],
    topBorder: [
      {
        y: 0,
        x: 0
      },
      {
        y: 350,
        x: 1
      },
      {
        y: 600,
        x: 2
      },
      {
        y: 851,
        x: 3
      },
      {
        y: 1104,
        x: 4
      },
      {
        y: 1360,
        x: 5
      },
      {
        y: 1620,
        x: 6
      },
      {
        y: 1885,
        x: 7
      },
      {
        y: 2156,
        x: 8
      },
      {
        y: 2474,
        x: 9
      },
      {
        y: 2800,
        x: 10
      }
    ]
  };
}
