import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  Input,
  OnChanges,
  QueryList
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CardQuestionComponent } from '../card-question/card-question.component';
import {
  CardAnimatedStackItemComponent,
  StackItem
} from './card-animated-stack-item/card-animated-stack-item.component';

@Component({
  selector: 'co-card-animated-stack',
  templateUrl: 'card-animated-stack.component.html',
  styleUrls: ['./card-animated-stack.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardAnimatedStackComponent implements OnChanges, AfterContentInit {
  @ContentChildren(CardAnimatedStackItemComponent)
  stackItemComponents: QueryList<CardAnimatedStackItemComponent>;

  @Input() activeIndex = 0;

  public stackContainerStyles = new BehaviorSubject<{
    [style: string]: string;
  }>({ opacity: '0' });
  public items: StackItem[];
  private readonly animationDuration = 300;

  ngAfterContentInit(): void {
    setTimeout(() => this.init(), 400);
  }

  ngOnChanges(obj): void {
    this.updateAnimatedCards(
      obj.activeIndex.currentValue,
      obj.activeIndex.previousValue
    );
  }

  private init(): void {
    this.items = [];
    this.stackItemComponents.forEach((component, index) => {
      const item = component.stackItem;
      item.zIndex = this.stackItemComponents.toArray().length - index;
      item.style.next({
        maxWidth:
          item.maxWidth && index === this.activeIndex ? item.maxWidth : null,
        zIndex: item.zIndex.toString()
      });

      this.items.push(item);

      item.contentHeight = item.component.getContentHeight();
    });

    this.items.forEach((item, index) => {
      if (
        !(item.component instanceof CardQuestionComponent) &&
        index !== this.activeIndex &&
        !(this.items[index + 1]?.component instanceof CardQuestionComponent)
      ) {
        item.component.hideContent();
        item.component.collapseContent(false);

        if (this.activeIndex > index) {
          item.style.next({ display: 'none', zIndex: item.zIndex.toString() });
        }
      }

      if (
        !(item.component instanceof CardQuestionComponent) &&
        index > this.activeIndex &&
        this.items[index + 1]?.component instanceof CardQuestionComponent
      ) {
        item.component.hideContent();
        item.component.collapseContent(false);
      }

      if (
        this.activeIndex > index &&
        item.component instanceof CardQuestionComponent &&
        !(this.items[index + 1]?.component instanceof CardQuestionComponent)
      ) {
        item.style.next({ display: 'none' });
        item.component.hideContent();
      }
    });

    this.partiallyHideContentForInactiveCardQuestion();

    this.stackContainerStyles.next({
      'margin-bottom': `${8 * (this.items.length - 1)}px`,
      opacity: '0'
    });

    this.initializeCardAnimation();
  }

  private initializeCardAnimation(): void {
    this.items.forEach((item, index) => {
      const isRotate =
        this.activeIndex > index &&
        this.items[index + 1]?.component instanceof CardQuestionComponent;

      // hide rotate animation on init
      if (isRotate) {
        item.style.next({ opacity: '0', zIndex: item.zIndex.toString() });
        setTimeout(
          () => item.style.next({ ...item.style.getValue(), opacity: null }),
          this.animationDuration
        );
      }

      item.class.next({
        inactive: this.activeIndex < index,
        active: this.activeIndex === index,
        removed: this.activeIndex > index,
        rotate: isRotate
      });

      if (this.activeIndex < index) {
        setTimeout(() => item.setAsInactive(index - this.activeIndex), 150);
      }
    });

    setTimeout(() => {
      this.stackContainerStyles.next({
        'margin-bottom': this.stackContainerStyles.getValue()['margin-bottom'],
        opacity: null
      });
    }, 150);
  }

  private partiallyHideContentForInactiveCardQuestion(): void {
    this.items.forEach((item, index) => {
      if (
        this.activeIndex <= index &&
        this.items[index + 1]?.component instanceof CardQuestionComponent &&
        item.contentHeight < this.items[index + 1]?.contentHeight
      ) {
        (this.items[index + 1].component as CardQuestionComponent).setMaxHeight(
          item.contentHeight
        );
      }
    });
  }

  private updateAnimatedCards(current: number, previous: number): void {
    if (!this.items) {
      return;
    }

    if (current > previous) {
      this.nextCard(current, previous);
    } else if (current < previous) {
      this.previousCard(current, previous);
    }
  }

  private previousCard(current: number, previous: number): void {
    const inactiveItems = this.items.filter((item, index) => current < index);
    const currentItem = this.items[current];
    const previousItem = this.items[previous];

    if (previousItem.component instanceof CardQuestionComponent) {
      // rotate animation
      currentItem.class.next({ active: true });
      currentItem.style.next({ zIndex: currentItem.zIndex.toString() });
      this.partiallyHideContentForInactiveCardQuestion();
      inactiveItems.forEach(item =>
        item.setAsInactive(this.items.indexOf(item) - current)
      );
    } else {
      // resize animation
      const previousContentHeight = previousItem.component.getContentHeight();
      previousItem.component.hideContent();
      const toBiggerCardQuestion =
        currentItem.component instanceof CardQuestionComponent &&
        !(previousItem.component instanceof CardQuestionComponent) &&
        previousItem.contentHeight < currentItem.contentHeight;

      if (toBiggerCardQuestion) {
        (currentItem.component as CardQuestionComponent).setMaxHeight(
          previousContentHeight
        );
      } else {
        currentItem.component.setMinHeight(previousContentHeight);
      }

      setTimeout(() => {
        inactiveItems.forEach(item =>
          item.setAsInactive(this.items.indexOf(item) - current)
        );

        currentItem.class.next({ active: true });
        currentItem.style.next({
          maxWidth: previousItem.maxWidth ?? null,
          zIndex: currentItem.zIndex.toString()
        });

        if (!(previousItem.component instanceof CardQuestionComponent)) {
          previousItem.component.collapseContent(false);
        }

        setTimeout(() => {
          if (toBiggerCardQuestion) {
            (currentItem.component as CardQuestionComponent).setMaxHeight(
              currentItem.contentHeight
            );
          } else {
            currentItem.component.setMinHeight(currentItem.contentHeight);
          }

          if (!(currentItem.component instanceof CardQuestionComponent)) {
            currentItem.component.expandContent(
              currentItem.contentHeight > previousContentHeight
            );
          }

          currentItem.style.next({
            maxWidth: currentItem.maxWidth ?? null,
            zIndex: currentItem.zIndex.toString()
          });

          setTimeout(() => {
            currentItem.component.showContent();
            if (toBiggerCardQuestion) {
              (currentItem.component as CardQuestionComponent).setMaxHeight(
                null
              );
            } else {
              currentItem.component.setMinHeight(null);
            }
            previousItem.component.setMinHeight(null);
          }, this.animationDuration);
        }, 100);
      }, this.animationDuration);
    }
  }

  private nextCard(current: number, previous: number): void {
    const inactiveItems = this.items.filter((item, index) => current < index);
    const currentItem = this.items[current];
    const previousItem = this.items[previous];

    currentItem.style.next({ zIndex: currentItem.zIndex.toString() });
    inactiveItems.forEach(item =>
      item.setAsInactive(this.items.indexOf(item) - current)
    );

    if (currentItem.component instanceof CardQuestionComponent) {
      // rotate animation
      previousItem.contentHeight = previousItem.component.getContentHeight();
      previousItem.class.next({
        removed: true,
        rotate: true
      });
      currentItem.class.next({ active: true });

      // for bigger content
      if (currentItem.component.maxHeight) {
        setTimeout(() => {
          (currentItem.component as CardQuestionComponent).setMaxHeight(
            currentItem.contentHeight
          );
          setTimeout(
            () =>
              (currentItem.component as CardQuestionComponent).setMaxHeight(
                null
              ),
            this.animationDuration
          );
        }, this.animationDuration);
      }
    } else {
      // resize animation
      const currentContentHeight = currentItem.component.getContentHeight();
      previousItem.contentHeight = previousItem.component.getContentHeight();

      const toBiggerHeight = previousItem.contentHeight < currentContentHeight;

      if (toBiggerHeight) {
        previousItem.component.setMinHeight(previousItem.contentHeight);
      } else {
        currentItem.component.setMinHeight(previousItem.contentHeight);
      }

      if (previousItem.maxWidth) {
        previousItem.style.next({ zIndex: previousItem.zIndex.toString() });
      }

      // no width animation when previous and current card has the same max width animation
      if (
        previousItem.maxWidth === currentItem.maxWidth &&
        currentItem.maxWidth
      ) {
        previousItem.style.next({
          maxWidth: previousItem.maxWidth,
          zIndex: previousItem.zIndex.toString()
        });

        currentItem.style.next({
          maxWidth: currentItem.maxWidth,
          zIndex: currentItem.zIndex.toString()
        });
      }

      previousItem.component.hideContent();

      setTimeout(() => {
        if (!(previousItem.component instanceof CardQuestionComponent)) {
          previousItem.component.collapseContent();
          previousItem.component.setMinHeight(0);
        }

        previousItem.class.next({ removed: true });
        previousItem.style.next({
          display: 'none',
          zIndex: previousItem.zIndex.toString()
        });

        currentItem.class.next({ active: true });
        if (toBiggerHeight) {
          currentItem.component.setMinHeight(previousItem.contentHeight);

          if (!(currentItem.component instanceof CardQuestionComponent)) {
            currentItem.component.expandContent();
          }
        } else {
          currentItem.component.setMinHeight(currentContentHeight);

          if (!(currentItem.component instanceof CardQuestionComponent)) {
            currentItem.component.expandContent();
          }
          // no width animation when previous and current card has the same max width animation
          if (
            previousItem.maxWidth === currentItem.maxWidth &&
            currentItem.maxWidth
          ) {
            previousItem.style.next({
              display: 'none',
              maxWidth: previousItem.maxWidth,
              zIndex: previousItem.zIndex.toString()
            });

            currentItem.style.next({
              maxWidth: currentItem.maxWidth,
              zIndex: currentItem.zIndex.toString()
            });
          }
        }

        if (currentItem.maxWidth) {
          currentItem.style.next({
            maxWidth: currentItem.maxWidth,
            zIndex: currentItem.zIndex.toString()
          });
        }

        setTimeout(() => {
          currentItem.component.showContent();
          if (toBiggerHeight) {
            currentItem.component.setMinHeight(0);
          }
        }, this.animationDuration);
      }, this.animationDuration);
    }
  }
}
