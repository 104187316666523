/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ServiceInfo } from './serviceInfo';
import { ServiceInfoSchema } from './serviceInfo';
import { ResourceModel } from './resourceModel';
import { ResourceModelSchema } from './resourceModel';
import * as JoiObj from "joi";


    export const ContentReleaseSchema = JoiObj.object({
        serviceInfo:
            
        JoiObj.link('#ServiceInfoSchema')

.allow(null)



        ,
        commentary:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        createTime:
            
            JoiObj.date()


.required()


        ,
        createdBy:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        description:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        name:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        releaseDate:
            
            JoiObj.date()


.required()


        ,
        sequenceId:
            
                    JoiObj.number()
                


.required()


        ,
        sequenceIdPrevious:
            
                    JoiObj.number()
                


.required()


        
    })
                .shared(ServiceInfoSchema)
.id('ContentReleaseSchema')

export interface ContentRelease extends ResourceModel { 
    commentary: string;
    createTime: Date;
    createdBy: string;
    description: string;
    name: string;
    releaseDate: Date;
    sequenceId: number;
    sequenceIdPrevious: number;
}

