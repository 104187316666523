<div
  *ngIf="broker && !broker.maeglerBetjent"
  class="text--manchet header spacing-unit--double-under"
  coContent="DL.VK01.C76"
  data-test-id="EO01-transfer-lead-header"
></div>
<div
  *ngIf="broker && broker.maeglerBetjent"
  class="text--manchet header spacing-unit--double-under"
  coContent="DL.VK01.C98"
  data-test-id="EO01-transfer-lead-header"
></div>

<div *ngIf="broker" class="card-container spacing-unit--double-over">
  <co-card>
    <ng-container *ngIf="!broker.maeglerBetjent">
      <p class="text--bold spacing-unit--under" [coContent]="'DL.VK01.C77'"></p>
      <p [coContent]="'DL.VK01.C78'"></p>
      <p [coContent]="'DL.VK01.C79'"></p>
    </ng-container>
    <ng-container *ngIf="broker.maeglerBetjent">
      <p class="text--bold spacing-unit--under" [coContent]="'DL.VK01.C97'"></p>
      <p
        [coContent]="'DL.VK01.C91'"
        [coContentSubst]="broker.navn ? broker.navn : ' '"
      ></p>
    </ng-container>
    <div class="navigation header-bottom">
      <button
        *ngIf="!broker.maeglerBetjent"
        mat-raised-button
        class="mat-focus-indicator mat-button-bas"
      >
        <a [href]="'tel:' + 70807503" class="phone">
          <co-icon-phone class="icon"></co-icon-phone>
          <span coContent="DL.VK01.C80"></span>
        </a>
      </button>
      <button
        *ngIf="!broker.maeglerBetjent"
        mat-raised-button
        class="mat-focus-indicator button-secondary mat-raised-button mat-button-bas contact-me"
        (click)="registerExternalTransferLead()"
        [coContent]="'DL.VK01.C81'"
        data-test-id="EO01-register-transfer-lead-btn"
      ></button>
      <button
        *ngIf="!broker.maeglerBetjent"
        mat-raised-button
        class="button-primary--level3 jumpover"
        (click)="declineCreateLead()"
      >
        <span>{{ 'DL.EO01.C654' | content }}</span>
        <co-icon-arrow-right class="icon"></co-icon-arrow-right>
      </button>
      <button
        *ngIf="broker.maeglerBetjent"
        mat-raised-button
        class="broker-button button-secondary move-on"
        [coContent]="'DL.VK01.C90'"
        (click)="goToNextPage()"
      ></button>
    </div>
  </co-card>
</div>
<div>
  <mitpfa-combine-savings-back-button
    *ngIf="!hideBackButton"
    (back)="goBack()"
  ></mitpfa-combine-savings-back-button>
</div>
