/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ServiceInfo } from './serviceInfo';
import { ServiceInfoSchema } from './serviceInfo';
import { OptimeringFejlKode } from './optimeringFejlKode';
import { OptimeringFejlKodeSchema } from './optimeringFejlKode';
import { ResourceModel } from './resourceModel';
import { ResourceModelSchema } from './resourceModel';
import * as JoiObj from "joi";


    export const OptimeringSvarSchema = JoiObj.object({
        serviceInfo:
            
        JoiObj.link('#ServiceInfoSchema')

.allow(null)



        ,
        kunPensionsalderFejl:
            
                    JoiObj.boolean()
                


.required()


        ,
        lssFejlKode:
            
                    JoiObj.number()
                


.required()


        ,
        lssUrl:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        optimeringFejlKoder:
                JoiObj.array().items(
        
        JoiObj.link('#OptimeringFejlKodeSchema')

        )
        

.required()


        ,
        skattefriLoebendeUdbetalingPolicer:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        udbetalingsDato:
            
                    JoiObj.object()
                


.required()


        
    })
                .shared(ServiceInfoSchema)
                .shared(OptimeringFejlKodeSchema)
.id('OptimeringSvarSchema')

export interface OptimeringSvar extends ResourceModel { 
    kunPensionsalderFejl: boolean;
    lssFejlKode: number;
    lssUrl: string;
    optimeringFejlKoder: Array<OptimeringFejlKode>;
    skattefriLoebendeUdbetalingPolicer: string;
    udbetalingsDato: object;
}

