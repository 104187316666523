/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const ClimatePrioritySchema = JoiObj.string().valid(
        'HIGH_RETURN'
        ,
        'EQUALLY'
        ,
        'CLIMATE_FRIENDLY'
        
    )
.id('ClimatePrioritySchema')

export type ClimatePriority = 'HIGH_RETURN' | 'EQUALLY' | 'CLIMATE_FRIENDLY';

export const ClimatePriority = {
    HighReturn: 'HIGH_RETURN' as ClimatePriority,
    Equally: 'EQUALLY' as ClimatePriority,
    ClimateFriendly: 'CLIMATE_FRIENDLY' as ClimatePriority
};

