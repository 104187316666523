/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SurveyCategory } from './surveyCategory';
import { SurveyCategorySchema } from './surveyCategory';
import { Gui } from './gui';
import { GuiSchema } from './gui';
import * as JoiObj from "joi";


    export const SurveySchema = JoiObj.object({
        categories:
                JoiObj.array().items(
        
        JoiObj.link('#SurveyCategorySchema')

        )
        
.allow(null)



        ,
        gui:
            
        JoiObj.link('#GuiSchema')


.required()


        ,
        id:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        introText:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        title:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        instanceId:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        languageCode:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        
    })
                .shared(SurveyCategorySchema)
                .shared(GuiSchema)
.id('SurveySchema')

export interface Survey { 
    categories?: Array<SurveyCategory>;
    gui: Gui;
    id?: string;
    introText?: string;
    title?: string;
    instanceId?: string;
    languageCode?: string;
}

