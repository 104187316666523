import { Injectable } from '@angular/core';
import { OrderItem, OrderTrackingService } from './order-tracking.service';
import { TrackingService } from './tracking.service';

export const payoutPlanOrderItem: OrderItem = {
  category: 'payout',
  name: 'voluntary monthly payment'
};

@Injectable()
export class PayoutPlanTrackingService extends TrackingService {
  private readonly trackPayoutPlanAction = 'track-payout-plan-action';

  public trackChangeRetirementAge(): void {
    this.dispatchCustomEventTracking(this.trackPayoutPlanAction, {
      payoutPlanAction: 'change retirement age'
    });
  }

  public trackChangeMonthlyAmount(): void {
    this.dispatchCustomEventTracking(this.trackPayoutPlanAction, {
      payoutPlanAction: 'change monthly amount'
    });
  }

  public trackUpdate(): void {
    this.dispatchCustomEventTracking(this.trackPayoutPlanAction, {
      payoutPlanAction: 'update'
    });
  }

  public trackSaveChanges(): void {
    this.dispatchCustomEventTracking(this.trackPayoutPlanAction, {
      payoutPlanAction: 'save changes'
    });
  }

  public trackToggleCurrentPayment(): void {
    this.dispatchCustomEventTracking(this.trackPayoutPlanAction, {
      payoutPlanAction: 'toggle - current payment'
    });
  }

  public trackToggleAdjustedPayment(): void {
    this.dispatchCustomEventTracking(this.trackPayoutPlanAction, {
      payoutPlanAction: 'toggle - adjusted payment'
    });
  }

  public trackGraphShowDetails(): void {
    this.dispatchCustomEventTracking(this.trackPayoutPlanAction, {
      payoutPlanAction: 'graph - show details'
    });
  }
}
