import { Component, inject, Input } from '@angular/core';
import { RecommendationDetail } from '../../investment-recommendation.model';
import { MatDialog } from '@angular/material/dialog';
import { InvestmentReadMoreComponent } from '@mitpfa/shared/investment/read-more/read-more.component';
import { SideBarStyle } from '@pfa/models';
import { InvestmentConcept } from '@pfa/gen';
import { PopupTrackingService } from '@pfa/core';

@Component({
  selector: 'mitpfa-investment-recommendation-profile-detail',
  templateUrl: './recommendation-profile-detail.component.html',
  styleUrls: ['../../investment-recommendation.component.scss']
})
export class InvestmentRecommendationProfileDetailComponent {
  private readonly dialog: MatDialog = inject(MatDialog);
  private readonly popupTrackingService: PopupTrackingService =
    inject(PopupTrackingService);

  @Input() detail: RecommendationDetail;
  @Input() recommendation: boolean;

  public openReadMore(): void {
    const popupName = 'IG recommendation profile explained';
    const type = this.detail.recommendation.recommendYouInvest
      ? InvestmentConcept.YouInvest
      : InvestmentConcept.PfaInvest;

    const matDialogRef = this.dialog.open(InvestmentReadMoreComponent, {
      ...SideBarStyle,
      data: type
    });

    matDialogRef.afterOpened().subscribe(() => {
      this.popupTrackingService.trackPopupViewed(popupName);
    });

    matDialogRef.afterClosed().subscribe(() => {
      this.popupTrackingService.trackPopupClosed(popupName);
    });
  }
}
