import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit
} from '@angular/core';

@Component({
  selector: 'co-sticky-bar',
  templateUrl: './sticky-bar.component.html',
  styleUrls: ['./sticky-bar.component.scss']
})
export class StickyBarComponent implements OnInit {
  // on or below this screenwitdh the bar will no longer be sticky
  @Input() nonSticyBreakpoint: number;

  constructor(private readonly htmlElement: ElementRef) {}

  ngOnInit(): void {
    this.checkForNonSticky();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.checkForNonSticky();
  }

  private checkForNonSticky() {
    if (this.nonSticyBreakpoint) {
      this.htmlElement.nativeElement.style.position =
        this.nonSticyBreakpoint >= window.innerWidth ? 'static' : 'sticky';
    }
  }
}
