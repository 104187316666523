<co-introbox [title]="title" [description]="description">
  <h2 [coContent]="'DL.DO01.C34'"></h2>
  <p [coContent]="'DL.DO01.C35'"></p>

  <h2 [coContent]="'DL.DO01.C36'"></h2>
  <p [coContent]="'DL.DO01.C37'"></p>

  <h2 [coContent]="'DL.DO01.C38'"></h2>
  <p [coContent]="'DL.DO01.C39'"></p>

  <h2 [coContent]="'DL.DO01.C42'"></h2>
  <p [coContent]="'DL.DO01.C43'"></p>

  <h2 [coContent]="'DL.DO01.C66'"></h2>
  <p [coContent]="'DL.DO01.C67'"></p>

  <h2 [coContent]="'DL.DO01.C85'"></h2>
  <p [coContent]="'DL.DO01.C86'"></p>
</co-introbox>

<ng-template #loading> <co-loading [show]="true"></co-loading> </ng-template>

<ng-container *ngIf="documentData$ | async as documentCacheItem; else loading">
  <mat-form-field class="form-field-light inline-block" color="accent">
    <mat-label [coContent]="'Global.vis'">Vis</mat-label>
    <mat-select [formControl]="documentTypeControl">
      <mat-option
        *ngFor="let documentType of documentTypes"
        [value]="documentType"
      >
        {{ documentType.text }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <!--NOTE: this is the documents table for DESKTOP/LARGE SCREENS-->
  <table class="pfa-table thead-align--left desktop-only--table">
    <thead>
      <tr>
        <th>
          <span
            class="cursor-pointer"
            [coContent]="'DL.DO01.C04'"
            (click)="sortBy('text', documentCacheItem)"
          ></span>
          <co-tooltip [text]="'DL.DO01.I04'"></co-tooltip>
          <div
            class="sort-arrow"
            [ngClass]="{
              'sort-arrow--desc': sortedBy === 'text-desc',
              'sort-arrow--asc': sortedBy === 'text-asc'
            }"
          ></div>
        </th>
        <th>
          <span
            class="cursor-pointer"
            [coContent]="'DL.DO01.C05'"
            (click)="sortBy('policenr', documentCacheItem)"
          ></span>
          <co-tooltip [text]="'DL.DO01.I05'"></co-tooltip>
          <div
            class="sort-arrow"
            [ngClass]="{
              'sort-arrow--desc': sortedBy === 'policenr-desc',
              'sort-arrow--asc': sortedBy === 'policenr-asc'
            }"
          ></div>
        </th>
        <th>
          <span
            class="cursor-pointer"
            [coContent]="'DL.DO01.C06'"
            (click)="sortBy('dato', documentCacheItem)"
          ></span>
          <co-tooltip [text]="'DL.DO01.I06'"></co-tooltip>
          <div
            class="sort-arrow"
            [ngClass]="{
              'sort-arrow--desc': sortedBy === 'dato-desc',
              'sort-arrow--asc': sortedBy === 'dato-asc'
            }"
          ></div>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        *ngFor="let document of documentCacheItem.documents; let index = index"
      >
        <td>
          <a
            class="link link-external"
            (click)="document.showPdf()"
            *ngIf="!document.fremsendtPrPost"
            [attr.data-dokument-id]="document.id"
            [attr.data-test-id]="'DO01-' + index + '-hent-pdf'"
          >
            {{ document.text }}
          </a>
          <span *ngIf="document.fremsendtPrPost">{{ document.text }}</span>
          <co-tooltip
            *ngIf="document.krDokument"
            [text]="document.toolTipText"
          ></co-tooltip>
          <co-tooltip
            *ngIf="document.dokumentType === 'AftalePensionsordning'"
            [text]="'DL.DO01.I10'"
          ></co-tooltip>
          <co-tooltip
            *ngIf="document.dokumentType === 'Pensionsvilkaar'"
            [text]="'DL.DO01.C70'"
          ></co-tooltip>
          <co-tooltip
            *ngIf="document.dokumentType === 'Pensionsrapport'"
            [text]="'DL.DO01.C72'"
          ></co-tooltip>
          <co-tooltip
            *ngIf="document.dokumentType === 'UdbetalingsplanRapport'"
            [text]="'DL.DO01.C72'"
          ></co-tooltip>
          <co-tooltip
            *ngIf="document.dokumentType === 'Pensionsoversigt'"
            [text]="'DL.DO01.C68'"
          ></co-tooltip>
          <co-tooltip
            *ngIf="document.dokumentType === 'Pensionsbevis'"
            [text]="'DL.DO01.C69'"
          ></co-tooltip>
          <co-tooltip
            *ngIf="document.dokumentType === 'Kvittering'"
            [text]="'DL.DO01.C71'"
          ></co-tooltip>
          <co-tooltip
            *ngIf="document.dokumentType === 'Pensionsoversigter'"
            [text]="'DL.DO01.C68'"
          ></co-tooltip>
          <co-tooltip
            *ngIf="document.dokumentType === 'Oevrige'"
            [text]="'DL.DO01.I11'"
          ></co-tooltip>
          <co-tooltip
            *ngIf="document.dokumentType === 'Pensionsoversigter30Sep'"
            [text]="'DL.DO01.C68'"
          ></co-tooltip>
        </td>
        <td [attr.data-test-id]="'DO01-' + index + '-police'">
          {{ document.policenr }}
        </td>
        <td [attr.data-test-id]="'DO01-' + index + '-dato'">
          {{ document.datoFormateret }}
        </td>
      </tr>
    </tbody>
  </table>

  <!--NOTE: this is the documents table for MOBILE/SMALL SCREENS-->
  <table class="pfa-table pfa-table--super-compact mobile-only--table">
    <tbody>
      <ng-container
        *ngFor="let document of documentCacheItem.documents; let index = index"
      >
        <tr>
          <td>
            <span
              class="text--bold cursor-pointer"
              [coContent]="'DL.DO01.C04'"
              (click)="sortBy('text', documentCacheItem)"
            ></span>
            <co-tooltip [text]="'DL.DO01.I04'"></co-tooltip>
            <div
              class="sort-arrow"
              [ngClass]="{
                'sort-arrow--desc': sortedBy === 'text-desc',
                'sort-arrow--asc': sortedBy === 'text-asc'
              }"
            ></div>
          </td>
          <td>
            <a
              class="text--bold link link-external"
              (click)="document.showPdf()"
              *ngIf="!document.fremsendtPrPost"
              [attr.data-dokument-id]="document.id"
              [attr.data-test-id]="'DO01-' + index + '-hent-pdf'"
            >
              {{ document.text }}
            </a>
            <span *ngIf="document.fremsendtPrPost">{{ document.text }}</span>
            <co-tooltip
              *ngIf="document.krDokument"
              [text]="document.toolTipText"
            ></co-tooltip>
            <co-tooltip
              *ngIf="document.dokumentType === 'AftalePensionsordning'"
              [text]="'DL.DO01.I10'"
            ></co-tooltip>
            <co-tooltip
              *ngIf="document.dokumentType === 'Pensionsvilkaar'"
              [text]="'DL.DO01.C70'"
            ></co-tooltip>
            <co-tooltip
              *ngIf="document.dokumentType === 'Pensionsrapport'"
              [text]="'DL.DO01.C72'"
            ></co-tooltip>
            <co-tooltip
              *ngIf="document.dokumentType === 'UdbetalingsplanRapport'"
              [text]="'DL.DO01.C72'"
            ></co-tooltip>
            <co-tooltip
              *ngIf="document.dokumentType === 'Pensionsoversigt'"
              [text]="'DL.DO01.C68'"
            ></co-tooltip>
            <co-tooltip
              *ngIf="document.dokumentType === 'Pensionsbevis'"
              [text]="'DL.DO01.C69'"
            ></co-tooltip>
            <co-tooltip
              *ngIf="document.dokumentType === 'Kvittering'"
              [text]="'DL.DO01.C71'"
            ></co-tooltip>
            <co-tooltip
              *ngIf="document.dokumentType === 'Pensionsoversigter'"
              [text]="'DL.DO01.C68'"
            ></co-tooltip>
            <co-tooltip
              *ngIf="document.dokumentType === 'Oevrige'"
              [text]="'DL.DO01.I11'"
            ></co-tooltip>
            <co-tooltip
              *ngIf="document.dokumentType === 'Pensionsoversigter30Sep'"
              [text]="'DL.DO01.C68'"
            ></co-tooltip>
          </td>
        </tr>
        <tr>
          <td>
            <span
              class="text--bold cursor-pointer"
              [coContent]="'DL.DO01.C05'"
              (click)="sortBy('policenr', documentCacheItem)"
            ></span>
            <co-tooltip [text]="'DL.DO01.I05'"></co-tooltip>
            <div
              class="sort-arrow"
              [ngClass]="{
                'sort-arrow--desc': sortedBy === 'policenr-desc',
                'sort-arrow--asc': sortedBy === 'policenr-asc'
              }"
            ></div>
          </td>
          <td [attr.data-test-id]="'DO01-' + index + '-police'">
            {{ document.policenr }}
          </td>
        </tr>
        <tr class="fake-row-end">
          <td>
            <span
              class="text--bold cursor-pointer"
              [coContent]="'DL.DO01.C06'"
              (click)="sortBy('dato', documentCacheItem)"
            ></span>
            <co-tooltip [text]="'DL.DO01.I06'"></co-tooltip>
            <div
              class="sort-arrow"
              [ngClass]="{
                'sort-arrow--desc': sortedBy === 'dato-desc',
                'sort-arrow--asc': sortedBy === 'dato-asc'
              }"
            ></div>
          </td>
          <td [attr.data-test-id]="'DO01-' + index + '-dato'">
            {{ document.datoFormateret }}
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</ng-container>
