import {
  Component,
  Inject,
  ViewChild,
  ElementRef,
  ChangeDetectorRef
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LegitimationApiService } from '@pfa/api';
import {
  FileLikeObjectExtended,
  GlobalWorkingService,
  FileType
} from '@pfaform';
import { LegitimationStatus } from '../proof-of-identity.model';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { Legitimation } from '@pfa/gen';
import { PopupTrackingService, PopupId } from '@pfa/core';

export type ProofOfIdentityPopupId =
  | 'proof of identity - login'
  | 'proof of identity - pep paragraph 53';

export interface ProofOfIdentityModalData {
  legitimation: Legitimation;
  popupId: ProofOfIdentityPopupId;
}

@Component({
  selector: 'mitpfa-proof-of-identity-modal',
  templateUrl: './proof-of-identity-modal.component.html',
  styleUrls: ['./proof-of-identity-modal.component.scss']
})
export class ProofOfIdentityModalComponent {
  @ViewChild('conent') content: ElementRef;

  legitimation: Legitimation;
  popupId: PopupId;
  isIdentified: boolean;
  isValid = false;
  isVirusError = false;
  isUploadError = false;
  isUploadComplete = false;
  supportedFiles = [
    FileType.JPG,
    FileType.JPEG,
    FileType.PNG,
    FileType.GIF,
    FileType.BMP,
    FileType.TIFF,
    FileType.PDF
  ];

  LegitimationStatus = LegitimationStatus;

  public selectedFiles: FileLikeObjectExtended[] = [];

  constructor(
    private dialog: MatDialogRef<ProofOfIdentityModalComponent>,
    private legitimationApiService: LegitimationApiService,
    private globalWorkingService: GlobalWorkingService,
    private trackingService: PopupTrackingService,
    private cd: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: ProofOfIdentityModalData
  ) {
    this.legitimation = data.legitimation;
    this.popupId = data.popupId;
    this.isIdentified = Object.keys(this.legitimation)
      .filter(key => key !== 'status')
      .some(key => this.legitimation[key]);
  }

  public handleAttachmentsChange(files: FileLikeObjectExtended[]): void {
    this.selectedFiles = files;
    this.isValid = files.length > 0 && files.length <= 5;
    this.cd.detectChanges();
    this.content.nativeElement.scrollTop =
      this.content.nativeElement.scrollHeight;
  }

  public upload(): void {
    const files = this.selectedFiles.map(
      file => file.rawFile as unknown as File
    );
    this.globalWorkingService.show();
    this.legitimationApiService
      .uploadLegitimationFiles(files)
      .pipe(
        catchError(err => {
          this.isVirusError = err.status === 400;
          return of(err);
        })
      )
      .subscribe(response => {
        this.trackingService.trackPopupCallToAction(this.popupId);
        if (response && !response.success) {
          this.isUploadError = true;
        } else if (response.success) {
          this.isUploadComplete = true;
        }
        this.globalWorkingService.hide();
      });
  }

  public cancel(): void {
    this.dialog.close();
  }
}
