import { Input, Component } from '@angular/core';
import { OverviewContentSection } from '../recommendation-overview.model';

@Component({
  selector: 'mitpfa-investment-recommendation-overview-section',
  templateUrl: './recommendation-overview-section.component.html',
  styleUrls: ['./recommendation-overview-section.component.scss']
})
export class InvestmentRecommendationOverviewSectionComponent {
  @Input() content: OverviewContentSection;
  @Input() accordionName: string;
}
