import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

export interface NBACardExpaned {
  name: string;
  expanded: boolean;
}

@Injectable()
export class CardSplitVerticalService {
  private readonly accordionStatus = new ReplaySubject<NBACardExpaned>(1);

  setAccordionStatus(expanded: boolean, name: string) {
    this.accordionStatus.next({ expanded, name });
  }

  getObservable() {
    return this.accordionStatus.asObservable();
  }
}
