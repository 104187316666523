import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import {
  MiscTrackingService,
  TrackingFollowingRecommendation,
  TrackingName,
  TrackingNotSet
} from '@pfa/core';
import { of } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { ExternaltransferStore, MaeglerInfo } from '@pfa/gen';
import { LeadType } from '@pfa/models';
import {
  CombineSavingsNavigationEvent,
  CombineSavingsStep
} from '@mitpfa/shared/combine-savings/combine-savings.model';
import { GlobalWorkingService } from '@pfaform';

@Component({
  selector: 'mitpfa-combine-savings-transfer-lead',
  templateUrl: './combine-savings-transfer-lead.component.html',
  styleUrls: ['./combine-savings-transfer-lead.component.scss']
})
export class CombineSavingsTransferLeadComponent {
  private readonly globalWorkingService: GlobalWorkingService =
    inject(GlobalWorkingService);
  private readonly miscTrackingService: MiscTrackingService =
    inject(MiscTrackingService);
  private readonly externaltransferStore: ExternaltransferStore = inject(
    ExternaltransferStore
  );

  @Input() broker: MaeglerInfo;
  @Input() hideBackButton: boolean;
  @Input() isOnboarding: boolean;
  @Input() externalTransferLeadType: LeadType;

  @Output() next = new EventEmitter<CombineSavingsNavigationEvent>();
  @Output() prev = new EventEmitter<CombineSavingsNavigationEvent>();

  public registerExternalTransferLead(): void {
    this.globalWorkingService.show();
    this.externaltransferStore
      .externaltransferLeadPost({ leadType: this.externalTransferLeadType })
      .pipe(
        switchMap(() =>
          this.externaltransferStore
            .externaltransferConfirmchoicePost()
            .pipe(catchError(() => of({})))
        ),
        tap(() => {
          this.miscTrackingService.trackConfirmChoice(
            TrackingName.EXTERNAL_TRANSFER,
            TrackingNotSet.NOT_SET,
            TrackingNotSet.NOT_SET,
            TrackingNotSet.NOT_SET,
            TrackingNotSet.NOT_SET,
            TrackingFollowingRecommendation.YES
          );
        })
      )
      .subscribe({
        next: () => {
          this.globalWorkingService.hide();
          this.goToNextPage();
        },
        error: () => {
          this.globalWorkingService.hide();
          this.next.emit({
            from: CombineSavingsStep.TransferLead,
            showErrorOnSummary: true
          });
        }
      });
  }

  public goToNextPage(): void {
    this.next.emit({ from: CombineSavingsStep.TransferLead });
  }

  public declineCreateLead(): void {
    this.globalWorkingService.show();
    this.externaltransferStore
      .externaltransferConfirmchoicePost()
      .pipe(
        catchError(() => of({})),
        switchMap(() => {
          this.miscTrackingService.trackConfirmChoiceClick(
            this.isOnboarding
              ? TrackingName.ONBOARDING
              : TrackingName.EXTERNAL_TRANSFER,
            [
              {
                itemConfirmName: 'no transfer',
                itemRecommendedName: 'external transfer'
              }
            ]
          );
          return of({});
        }),
        tap(() => {
          this.globalWorkingService.hide();
          this.next.emit({
            from: CombineSavingsStep.TransferLead,
            transferredPoliciesUids: [],
            declineCreateLead: true
          });
        })
      )
      .subscribe();
  }

  public goBack(): void {
    this.prev.emit({ from: CombineSavingsStep.TransferLead });
  }
}
