<div class="row">
  <div class="col-md-6 offset-md-3">
    <div class="description spacing-unit--double-under">
      <h3 class="align--center" coContent="DL.FG01.C1866"></h3>
    </div>
  </div>
  <div
    class="col-md-3 spacing-unit--double-under d-flex justify-content-end pl-3"
  ></div>
</div>

<div class="row">
  <!-- looc -->
  <co-card-split-horizontal
    class="card--regular flat col-lg"
    [ngClass]="{
      disabled: insuranceGuideDisabled?.looc,
      'small-header-bottom-padding': locScaleText
    }"
  >
    <co-insurance-card-header
      [disabled]="insuranceGuideDisabled?.looc"
      header
      [headerContent]="'DL.FG01.C1388'"
      [headerNoteContent]="locScaleText ? locScaleText : ''"
      [headerNoteContentTooltip]="locScaleText ? locScaleTooltipText : ''"
    >
    </co-insurance-card-header>

    <div class="custom-body" body>
      <p coContent="DL.FG01.C1977"></p>

      <a
        *ngIf="!loocExpanded"
        (click)="loocExpanded = true"
        class="info-link"
        coContent="DL.FG01.C1980"
      >
      </a>

      <div *ngIf="loocExpanded">
        <p coContent="DL.FG01.C1982"></p>

        <div *ngIf="insuranceDetails.helbredsTal > 0">
          <p
            class="text--note"
            coContent="DL.FG01.C1828"
            [coContentSubst]="
              (pensionInfo.forsikring.helbredsTalBrutto | numberFormat) +
              '|' +
              (pensionInfo.forsikring.ialtTAE | numberFormat)
            "
          ></p>
          <ng-container *ngTemplateOutlet="coverageMore"></ng-container>
        </div>
        <p
          *ngIf="insuranceGuideDisabled?.looc"
          class="coverage-disabled align--center"
        >
          <strong coContent="DL.FG01.C1613"></strong>
        </p>
      </div>

      <a
        *ngIf="loocExpanded"
        (click)="loocExpanded = false"
        class="info-link"
        coContent="DL.FG01.C1981"
      >
      </a>
    </div>
  </co-card-split-horizontal>

  <!-- ci -->
  <co-card-split-horizontal
    class="card--regular flat col-lg"
    [ngClass]="{
      disabled: insuranceGuideDisabled?.ci
    }"
  >
    <co-insurance-card-header
      [disabled]="insuranceGuideDisabled?.ci"
      header
      [headerContent]="'DL.FG01.C1415'"
    ></co-insurance-card-header>

    <div class="custom-body" body>
      <p coContent="DL.FG01.C1978"></p>
      <a
        *ngIf="!ciExpanded"
        class="info-link"
        (click)="ciExpanded = true"
        coContent="DL.FG01.C1980"
      ></a>
      <div *ngIf="ciExpanded">
        <p coContent="DL.FG01.C1984"></p>
        <div
          *ngIf="
            insuranceDetails.originalTilpasForsikringstal
              .daekningAendringKritiskSygdomSelv.daekningsBeloeb > 0
          "
        >
          <p
            class="text--note"
            coContent="DL.FG01.C1830"
            [coContentSubst]="coverageAmount | numberFormat"
          ></p>
          <ng-container *ngTemplateOutlet="coverageMore"></ng-container>
        </div>
        <p
          *ngIf="insuranceGuideDisabled?.ci"
          class="coverage-disabled align--center"
        >
          <strong coContent="DL.FG01.C1615"></strong>
        </p>
      </div>

      <a
        *ngIf="ciExpanded"
        class="info-link"
        (click)="ciExpanded = false"
        coContent="DL.FG01.C1981"
      ></a>
    </div>
  </co-card-split-horizontal>

  <!-- life -->
  <co-card-split-horizontal
    class="card--regular flat col-lg collapse"
    [ngClass]="{
      disabled: insuranceGuideDisabled?.life
    }"
  >
    <co-insurance-card-header
      [disabled]="insuranceGuideDisabled?.life"
      header
      [headerContent]="'DL.FG01.C1390'"
    ></co-insurance-card-header>

    <div class="custom-body" body>
      <p coContent="DL.FG01.C1979"></p>

      <a
        class="info-link"
        *ngIf="!lifeExpanded"
        (click)="lifeExpanded = true"
        class="info-link"
        coContent="DL.FG01.C1980"
      ></a>

      <div *ngIf="lifeExpanded">
        <p coContent="DL.FG01.C1983"></p>
        <div *ngIf="insuranceDetails.livsTal > 0">
          <p
            class="text--note"
            coContent="DL.FG01.C1829"
            [coContentSubst]="
              (multiplier | number: '1.2-2' : 'da-DK') +
              '|' +
              (pensionInfo.forsikring.ialtLivsforsikring | numberFormat)
            "
          ></p>
          <ng-container *ngTemplateOutlet="coverageMore"></ng-container>
        </div>
        <p
          *ngIf="insuranceGuideDisabled?.life"
          class="coverage-disabled align--center"
        >
          <strong coContent="DL.FG01.C1614"></strong>
        </p>
      </div>

      <a
        *ngIf="lifeExpanded"
        (click)="lifeExpanded = false"
        class="info-link"
        coContent="DL.FG01.C1981"
      >
      </a>
    </div>
  </co-card-split-horizontal>

  <ng-template #coverageMore>
    <div class="text--note" coContent="DL.FG01.C1604"></div>
  </ng-template>
</div>
<div class="spacing-unit--double-over align--center">
  <button
    class="button-secondary"
    mat-raised-button
    (click)="goNext()"
    coContent="DL.FG01.C1393"
    data-test-id="insurance-guide-insurance-cover-step-btn"
  ></button>

  <div class="spacing-unit--half-over">
    <mitpfa-insurance-guide-back-button
      (back)="goBack()"
    ></mitpfa-insurance-guide-back-button>
  </div>
</div>
