<mitpfa-insurance-guide-recommendation-card
  [recommendationDirection]="recommendation"
  [title]="recommendationDescription ? recommendationDescription.text : ''"
  [recommendationDescription]="recommendationDescription"
  [hasAdvisorRecommendation]="hasAdvisorRecommendation()"
  [recommendationHeaderTooltip]="recommendationHeaderTooltip"
  [showNoCoverageWarning]="isDisabled"
  [showFollowOurRecommendationButton]="showFollowOurRecommendationButton"
  [hideActions]="hideActions"
  [confirmChoiceData]="confirmChoice.life"
  (confirmChoiceEmitter)="onConfirmChoice()"
  accordionTrackingName="ISG recommendation – pfalife"
>
  <co-primary-number
    advisorValue
    class="size-sm"
    [amount]="advisorData?.raadgiverAnbefaling / 100"
    [showDecimals]="true"
    [unit]="'DL.FG01.C1835'"
    [unitSubst]="advisorData?.raadgiverAnbefaling | numberFormat"
  ></co-primary-number>
  <div advisorDescription>
    <div class="advisor-note">
      <p coContent="DL.TF01.C134">Read more here</p>
      <co-tooltip
        [text]="advisorData?.raadgiverKommentar ?? ''"
        class="advisor-note-tooltip"
      ></co-tooltip>
    </div>
    <p
      coContent="DL.TF01.C131"
      [coContentSubst]="advisorData?.raadgiverAnbefalingOprettet ?? ''"
    ></p>
  </div>
  <co-primary-number
    recommendedValue
    [ngClass]="{ 'color--black-60': hasAdvisorRecommendation() }"
    class="size-sm"
    [amount]="
      overrideRecommendationKeepCurrent
        ? currentMultiplier
        : recommendedMultiplier
    "
    [showDecimals]="
      overrideRecommendationKeepCurrent
        ? currentMultiplier > 0
        : recommendedMultiplier > 0
    "
    [unit]="'DL.FG01.C1835'"
    [unitSubst]="
      overrideRecommendationKeepCurrent
        ? (pensionInfo.forsikring.livsTal | numberFormat)
        : (pensionInfo.forsikring.pfaAnbefalerLivs.procent | numberFormat)
    "
  ></co-primary-number>
  <span
    *ngIf="!this.hasAdvisorRecommendation()"
    recommendedDescription
    [coContent]="recommendationDescription.description"
    [coContentSubst]="recommendedSalary | numberFormat"
  ></span>

  <co-primary-number
    currentValue
    class="size-sm"
    [amount]="currentMultiplier"
    [showDecimals]="currentMultiplier > 0"
    [unit]="'DL.FG01.C1835'"
    [unitSubst]="pensionInfo.forsikring.livsTal | numberFormat"
  ></co-primary-number>

  <span
    currentDescription
    coContent="DL.FG01.C1839"
    [coContentSubst]="pensionInfo.forsikring.ialtLivsforsikring | numberFormat"
  ></span>

  <ng-container readMoreList>
    <ul class="list">
      <li
        *ngIf="
          !insuranceDetails.pfaAnbefalerLivs.giftSamlevende &&
          !insuranceDetails.pfaAnbefalerLivs.harBoern
        "
      >
        <span coContent="DL.FG01.C1479"></span>
      </li>

      <li
        *ngIf="
          !insuranceDetails.pfaAnbefalerLivs.giftSamlevende &&
          insuranceDetails.pfaAnbefalerLivs.harBoern
        "
      >
        <span coContent="DL.FG01.C1429"></span>
      </li>

      <li
        *ngIf="
          insuranceDetails.pfaAnbefalerLivs.giftSamlevende &&
          insuranceDetails.pfaAnbefalerLivs.forsoergerAndel < 60
        "
      >
        <span coContent="DL.FG01.C1483"></span>
      </li>

      <li
        *ngIf="
          insuranceDetails.pfaAnbefalerLivs.giftSamlevende &&
          insuranceDetails.pfaAnbefalerLivs.forsoergerAndel >= 60 &&
          insuranceDetails.pfaAnbefalerLivs.forsoergerAndel < 70
        "
      >
        <span coContent="DL.FG01.C1484"></span>
      </li>

      <li
        *ngIf="
          insuranceDetails.pfaAnbefalerLivs.giftSamlevende &&
          insuranceDetails.pfaAnbefalerLivs.forsoergerAndel >= 70 &&
          insuranceDetails.pfaAnbefalerLivs.forsoergerAndel < 80
        "
      >
        <span coContent="DL.FG01.C1485"></span>
      </li>

      <li
        *ngIf="
          insuranceDetails.pfaAnbefalerLivs.giftSamlevende &&
          insuranceDetails.pfaAnbefalerLivs.forsoergerAndel >= 80 &&
          insuranceDetails.pfaAnbefalerLivs.forsoergerAndel < 90
        "
      >
        <span coContent="DL.FG01.C1486"></span>
      </li>

      <li
        *ngIf="
          insuranceDetails.pfaAnbefalerLivs.giftSamlevende &&
          insuranceDetails.pfaAnbefalerLivs.forsoergerAndel >= 90
        "
      >
        <span coContent="DL.FG01.C1487"></span>
      </li>
      <li *ngIf="totalFreeFunds">
        <span
          coContent="DL.FG01.C1489"
          [coContentSubst]="totalFreeFunds | numberFormat"
        ></span>
      </li>

      <li *ngIf="financialSituation.frieMidler.gaeldSikres">
        <span
          coContent="DL.FG01.C1490"
          [coContentSubst]="
            financialSituation.frieMidler.gaeldDaekkes | numberFormat
          "
        ></span>
      </li>

      <li *ngIf="hasPartner && familyInformation.partnersGaeldSikres">
        <span
          coContent="DL.FG01.C1491"
          [coContentSubst]="
            familyInformation.partnersGaeldDaekkes | numberFormat
          "
        ></span>
      </li>

      <li>
        <span
          coContent="DL.FG01.C1441"
          [coContentSubst]="salary | numberFormat"
        ></span>
      </li>

      <li *ngIf="insuranceDetails.pfaAnbefalerLivs.fraDepot">
        <span
          coContent="DL.FG01.C1492"
          [coContentSubst]="
            insuranceDetails.pfaAnbefalerLivs.depotSikring | numberFormat
          "
        ></span>
      </li>

      <li *ngIf="showChildPensionRecommendationText">
        <span coContent="DL.FG01.C1511"></span>
      </li>

      <li
        *ngIf="
          insuranceDetails.pfaAnbefalerLivs.recommendationAdjustedToMinimum
        "
        [coContent]="'DL.FG01.C1929'"
      ></li>
    </ul>
  </ng-container>
</mitpfa-insurance-guide-recommendation-card>
