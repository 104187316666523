/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const CustomerSelectionTypeSchema = JoiObj.string().valid(
        'YES'
        ,
        'NO'
        ,
        'NOT_POSSIBLE'
        
    )
.id('CustomerSelectionTypeSchema')

export type CustomerSelectionType = 'YES' | 'NO' | 'NOT_POSSIBLE';

export const CustomerSelectionType = {
    Yes: 'YES' as CustomerSelectionType,
    No: 'NO' as CustomerSelectionType,
    NotPossible: 'NOT_POSSIBLE' as CustomerSelectionType
};

