import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import {
  AmountLimits,
  CancelPrivatIndskud,
  CreateBankTransferSalesOrderResponse,
  PrivatIndskud,
  PrivatIndskudApproveResponseObj,
  PrivatIndskudSignRequestObj,
  PrivatIndskudSignResponseObj,
  QuotationUpdate,
  ReminderEmail,
  SalesOrder,
  SuccessResponse
} from '@pfa/gen';
import { HttpClient } from '@angular/common/http';
import { map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SinglePaymentApiService {
  constructor(private readonly http: HttpClient) {}

  // we reverse because history comes from oldest to newest,
  // but createdOn precision is days, so we assume if createdOn is equal, higher index is younger payment
  sortHistoryByCreatedDate = (singlePayment: PrivatIndskud) =>
    singlePayment.history
      .slice()
      .reverse()
      .sort(
        (a: SalesOrder, b: SalesOrder) =>
          (b.createdOn as unknown as number) -
          (a.createdOn as unknown as number)
      );

  getSinglePaymentData(): Observable<PrivatIndskud> {
    return this.http.get<PrivatIndskud>('/ds/api/privatindskud').pipe(
      switchMap(response => {
        if (response.retrieveQuotationFailed) {
          throw new Error('privatindskud::retrieveQuotationFailed.');
        } else if (response.retrieveSalesOrderListFailed) {
          throw new Error('privatindskud::retrieveSalesOrderListFailed.');
        }
        return of(response);
      }),
      map((singlePayment: PrivatIndskud) => ({
        ...singlePayment,
        ...{ history: this.sortHistoryByCreatedDate(singlePayment) }
      }))
    );
  }

  postSinglePaymentData(
    request: QuotationUpdate
  ): Observable<{ success: boolean }> {
    return this.http.post<{ success: boolean }>(
      '/ds/api/privatindskud',
      request
    );
  }

  enrichSinglePaymentData(request: PrivatIndskud): Observable<PrivatIndskud> {
    return this.http
      .post<PrivatIndskud>('/ds/api/privatindskud/enrich', request)
      .pipe(
        switchMap(response => {
          if (response.retrieveQuotationFailed) {
            throw new Error('privatindskud::retrieveQuotationFailed.');
          }
          return of(response);
        }),
        map((singlePayment: PrivatIndskud) => ({
          ...singlePayment,
          ...{ history: this.sortHistoryByCreatedDate(singlePayment) }
        }))
      );
  }

  cancel(request: CancelPrivatIndskud): Observable<SuccessResponse> {
    return this.http.post<SuccessResponse>(
      '/ds/api/privatindskud/cancel',
      request
    );
  }

  getAmountLimits(): Observable<AmountLimits> {
    return this.http.get<AmountLimits>('/ds/api/privatindskud/amount-limits');
  }

  sign(
    requestBody: PrivatIndskudSignRequestObj
  ): Observable<PrivatIndskudSignResponseObj> {
    return this.http.post<PrivatIndskudSignResponseObj>(
      '/ds/api/privatindskud/sign',
      requestBody
    );
  }

  signed(salesOrderSigned: {
    mitIdSig: string;
    revisionKey: string;
  }): Observable<PrivatIndskudApproveResponseObj> {
    return this.http.put<PrivatIndskudApproveResponseObj>(
      '/ds/api/privatindskud/sign/1',
      salesOrderSigned
    );
  }

  bankTransfer({ salesOrderGUID, moneyLaunderingDocLimit, sprog }) {
    return this.http
      .post<CreateBankTransferSalesOrderResponse>(
        '/ds/api/privatindskud/banktransfer',
        {
          salesOrderGUID,
          moneyLaunderingDocLimit,
          sprog
        }
      )
      .pipe(
        map((res: { createBankTransferSalesOrderFailed; attachedFiles }) => {
          if (res.createBankTransferSalesOrderFailed) {
            throw new Error('privatindskud::retrieveSalesOrderFailed.');
          }
          return res.attachedFiles;
        })
      );
  }

  sendReminder(reminder: ReminderEmail): Observable<SuccessResponse> {
    return this.http.post<SuccessResponse>(
      '/ds/api/privatindskud/reminder-email',
      reminder
    );
  }
}
