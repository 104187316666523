<nav>
  <div class="footer">
    <co-footer-contact
      [isLight]="isLight"
      [isLetPension]="isLetPension"
    ></co-footer-contact>
    <div class="footer__bottom">
      <div class="language">
        <a
          *ngIf="isDanish; else englishLink"
          class="footer-link"
          [class.disabled]="isLanguageChangeDisabled | async"
          coContent="DL.FO01.C400"
          (click)="setLanguage('en')"
        ></a>

        <ng-template #englishLink>
          <a
            class="footer-link"
            [class.disabled]="isLanguageChangeDisabled | async"
            coContent="DL.FO01.C401"
            (click)="setLanguage('da')"
          ></a>
        </ng-template>
      </div>
      <div class="other">
        <a
          *ngIf="!isLetPension"
          coContent="DL.FO01.C415"
          class="footer-link"
          [routerLink]="'/forbehold'"
        ></a>

        <a
          [href]="cookiesUrl"
          target="_blank"
          class="footer-link"
          coContent="DL.FO01.C416"
          (click)="handleExternalLinkClick('Cookies')"
        ></a>

        <a
          coContent="DL.FO01.C413"
          class="footer-link"
          (click)="openCookiePopup()"
        ></a>

        <a
          [href]="personalDataUrl"
          target="_blank"
          class="footer-link"
          coContent="DL.FO01.C418"
          (click)="handleExternalLinkClick('PFA Personoplysninger')"
        ></a>

        <a
          *ngIf="!isLetPension"
          [href]="pfaUrl"
          target="_blank"
          class="footer-link"
          coContent="DL.FO01.C420"
          (click)="handleExternalLinkClick('PFA Personoplysninger')"
        ></a>
      </div>
    </div>
  </div>
</nav>
