import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CustomerSurveyStep } from '../customer-survey.enum';
import { CustomerSurveyNavigationEvent } from '../customer-survey.model';
import { SurveyAction, SurveyTrackingService } from '@pfa/core';

@Component({
  selector: 'mitpfa-customer-survey-intro',
  templateUrl: './customer-survey-intro.component.html',
  styleUrls: ['./customer-survey-intro.component.scss']
})
export class CustomerSurveyIntroComponent {
  @Input() surveyUuid: string;
  @Output()
  next: EventEmitter<CustomerSurveyNavigationEvent> = new EventEmitter();

  @Output()
  close: EventEmitter<boolean> = new EventEmitter();

  constructor(private readonly surveyTrackingService: SurveyTrackingService) {}

  goToNextPage() {
    this.surveyTrackingService.trackSurvey(this.surveyUuid, SurveyAction.START);
    this.next.emit({ from: CustomerSurveyStep.Intro });
  }

  delay() {
    this.close.emit(true);
  }
}
