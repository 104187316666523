import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  EventEmitter,
  OnInit,
  Output,
  inject
} from '@angular/core';
import { interval } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { MenuService } from '../../header/menu/menu.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'mitpfa-onboarding-done',
  templateUrl: './done.component.html',
  styleUrls: ['./done.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DoneComponent implements OnInit {
  private readonly menuService: MenuService = inject(MenuService);
  private readonly destroyRef: DestroyRef = inject(DestroyRef);

  public progressValue = 0;
  public isDoneEnabled = false;

  @Output() goTo: EventEmitter<void> = new EventEmitter();

  public ngOnInit(): void {
    this.menuService.hideSubMenuItem('PROFILE', 'AKTIVER_DIN_ANBEFALING');
    interval(100)
      .pipe(
        takeWhile(() => this.progressValue <= 100),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(() => {
        this.progressValue = this.progressValue + 2;
        if (this.progressValue === 100) {
          this.isDoneEnabled = true;
        }
      });
  }

  public done(): void {
    this.goTo.emit();
  }
}
