import { Component, Input } from '@angular/core';
import { CheckboxGroupContentComponent } from '../checkbox-group-content-component';

@Component({
  selector: 'co-checkbox-group-label',
  template: '<span>{{data}}</span>'
})
export class CheckboxGroupLabelComponent
  implements CheckboxGroupContentComponent<CheckboxGroupLabelComponent>
{
  @Input() data: string;
}
