<div mat-dialog-title>
  <h3 class="consent-header" [coContent]="'DL.TH01.C62'"></h3>
  <co-icon-times (click)="dismiss()"></co-icon-times>
</div>
<mat-dialog-content class="consent-content">
  <p [coContent]="'DL.TH01.C60'"></p>
  <p [coContent]="'DL.TH01.C61'"></p>
</mat-dialog-content>
<mat-dialog-actions class="consent-actions">
  <button
    mat-raised-button
    (click)="accept()"
    class="consent-accept"
    [coContent]="'DL.TH01.C04'"
  ></button>
  <button
    mat-raised-button
    class="button-secondary"
    (click)="decline()"
    [coContent]="'DL.TH01.C05'"
  ></button>
</mat-dialog-actions>
