import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import {
  Prerequisites_pensionForecasts_average,
  Prerequisites_pensionForecasts_individual,
  Prerequisites_pensionForecasts_pfa,
  Prerequisites_pensionForecasts_you,
  Prerequisites_pensionplan
} from '@pfa/models';
import { Observable } from 'rxjs';
import { RatesMarketRate } from '@pfa/gen';

@Injectable({
  providedIn: 'root'
})
export class PreconditionsService {
  constructor(private http: HttpClient) {}

  getPensionPlanPrerequisites(): Observable<Prerequisites_pensionplan> {
    const requestUrl = '/ds/api/forbehold/pensionsplan';
    return this.http.get<Prerequisites_pensionplan>(requestUrl);
  }

  getPensionFOrecastsAveragePlanPrerequisites(): Observable<Prerequisites_pensionForecasts_average> {
    const requestUrl = '/ds/api/forbehold/pension-forecasts/average';
    return this.http.get<Prerequisites_pensionForecasts_average>(requestUrl);
  }

  getPensionFOrecastsPfaPlanPrerequisites(): Observable<Prerequisites_pensionForecasts_pfa> {
    const requestUrl = '/ds/api/forbehold/pension-forecasts/pfa';
    return this.http.get<Prerequisites_pensionForecasts_pfa>(requestUrl);
  }

  getPensionFOrecastsYouPlanPrerequisites(): Observable<Prerequisites_pensionForecasts_you> {
    const requestUrl = '/ds/api/forbehold/pension-forecasts/you';
    return this.http.get<Prerequisites_pensionForecasts_you>(requestUrl);
  }

  getPensionFOrecastsIndividualPlanPrerequisites(): Observable<Prerequisites_pensionForecasts_individual> {
    const requestUrl = '/ds/api/forbehold/pension-forecasts/individual';
    return this.http.get<Prerequisites_pensionForecasts_individual>(requestUrl);
  }

  getTeaAllInclusivePrerequisites(): Observable<boolean> {
    const requestUrl = '/ds/api/pensionskunde/taeAllInclusive';
    return this.http.get<boolean>(requestUrl);
  }

  getRatesMarketRate(): Observable<RatesMarketRate> {
    const requestUrl = 'ds/api/forbehold/marketrateinterestrates';
    return this.http.get<RatesMarketRate>(requestUrl);
  }
}
