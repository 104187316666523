import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HentFormularResponse } from './dynamic-form.model';

@Injectable()
export class DynamicFormService {
  private channel: string;
  private endpointHost: string;

  constructor(private readonly http: HttpClient) {}

  setChannel(channel) {
    this.channel = channel;
  }

  getChannel() {
    return this.channel;
  }

  setEndpointHost(endpointHost) {
    this.endpointHost = endpointHost;
  }

  getEndpointHost() {
    return this.endpointHost;
  }

  stripHtml(text) {
    return text ? String(text).replace(/<[^>]+>/gm, '') : '';
  }

  sendDynamicForm(
    dynamicForm,
    attachments,
    formId,
    title,
    category,
    contactInfo
  ) {
    return this.sendMessageToUrl(
      `${this.getFormsHost()}/ds/${this.getFormsApiRoot()}/messaging/${
        this.channel
      }/form`,
      dynamicForm,
      attachments,
      formId,
      title,
      category,
      contactInfo
    );
  }

  getForm(
    formId: string,
    language: string,
    category: string
  ): Observable<HentFormularResponse> {
    let url;
    if (this.channel === 'pfadk') {
      url = `${this.getFormsHost()}/ds/${this.getFormsApiRoot()}/messaging/${
        this.channel
      }/form/${category}?language=${language}`;
    } else {
      url = `${this.getFormsHost()}/ds/${this.getFormsApiRoot()}/messaging/${
        this.channel
      }/form/${formId}?language=${language}`;
    }
    return this.http.get<HentFormularResponse>(url);
  }

  private getFormsApiRoot() {
    return this.channel === 'pfadk' ? 'open' : 'api';
  }
  private getFormsHost() {
    return this.endpointHost ? this.endpointHost : '';
  }

  private sendMessageToUrl(
    url,
    thread,
    attachments?,
    formId?,
    title?,
    category?,
    contactInfo?
  ) {
    if (thread.message) {
      thread.message.body = this.stripHtml(thread.message.body);
    }
    if (thread.title) {
      thread.title = this.stripHtml(thread.title);
    }
    const formData = new FormData();
    attachments.forEach(attachment => {
      formData.append('files', attachment.rawFile, attachment.rawFile.name);
    });
    if (formId) {
      formData.append('formId', this.stripHtml(formId));
    }
    if (title) {
      formData.append('title', this.stripHtml(title));
    }
    if (category) {
      formData.append('category', this.stripHtml(category));
    }
    if (contactInfo) {
      formData.append('contactInfo', JSON.stringify(contactInfo));
    }
    formData.append('sendMessage', JSON.stringify(thread));
    return this.http.post<any>(url, formData);
  }
}
