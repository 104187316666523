<co-card
  *ngFor="let pensionPlanCard of pensionPlanCardList; let i = index"
  data-test-id="pensionPlanCard"
>
  <!-- MAIN CONTENT -->
  <div class="row">
    <div class="col-12 col-md-6">
      <div
        *ngIf="pensionPlanCard.main.context"
        class="context"
        data-test-id="pensionPlanCardMainContext"
      >
        {{ pensionPlanCard.main.context | content }}
        <co-tooltip
          *ngIf="pensionPlanCard.main.contextTooltip"
          [text]="pensionPlanCard.main.contextTooltip"
        ></co-tooltip>
      </div>

      <div
        *ngIf="pensionPlanCard.main.content"
        class="policy-type info-text"
        data-test-id="pensionPlanCardMainContent"
      >
        {{ pensionPlanCard.main.contentId }}
        {{ pensionPlanCard.main.content | content }}
        <co-tooltip
          *ngIf="pensionPlanCard.main.contentTooltip"
          [text]="pensionPlanCard.main.contentTooltip"
        ></co-tooltip>
      </div>

      <co-info
        *ngIf="pensionPlanCard.main.text"
        class="main-text"
        data-test-id="pensionPlanCardMainText"
      >
        <span [coContent]="pensionPlanCard.main.text"></span>
      </co-info>
    </div>

    <!-- ADDITIONAL CONTENT -->
    <div
      *ngIf="pensionPlanCard.additional"
      class="col-12 col-md-6 align--right"
    >
      <div class="context" data-test-id="pensionPlanCardAdditionalContext">
        {{ pensionPlanCard.additional.context | content }}
      </div>

      <div *ngFor="let contentItem of pensionPlanCard.additional.content">
        <div class="policy-text info-text">
          {{ contentItem.text | content }}
        </div>

        <div class="policy-text" *ngIf="contentItem.subText">
          {{ contentItem.subText | content }}
        </div>
      </div>
    </div>
  </div>

  <!-- DETAILS -->
  <div *ngIf="pensionPlanCard.details && showDetails[i]" [@openClose]>
    <div class="details">
      <div
        *ngFor="let pensionPlanCardDetail of pensionPlanCard.details"
        class="row align-items-center details-row"
        attr.data-test-id="{{
          'pensionPlanCardDetail-' + pensionPlanCardDetail.key
        }}"
      >
        <div class="col-md-6">
          <span
            data-test-id="pensionPlanCardDetailKey"
            [coContent]="pensionPlanCardDetail.key"
            [coContentSubst]="pensionPlanCardDetail.keyParam"
          >
          </span>
        </div>

        <div class="col-md-6 align--right">
          <span
            data-test-id="pensionPlanCardDetailValue"
            *ngIf="pensionPlanCardDetail.value !== null"
            >{{
              pensionPlanCardDetail.value
                | numberFormat: pensionPlanCardDetail.decimals
            }}
            {{ pensionPlanCardDetail.unit | content }}
          </span>

          <span
            *ngIf="pensionPlanCardDetail.value === null"
            [coContent]="'DL.AFT01.C07'"
          ></span>
        </div>
      </div>
    </div>
  </div>

  <!-- ACTIONS -->
  <div class="row align-items-center action-row">
    <div class="col-12">
      <!-- ACTION, DETAILS -->
      <button
        mat-raised-button
        class="button-primary--level3"
        *ngIf="pensionPlanCard.details"
        (click)="showDetails[i] = !showDetails[i]"
      >
        <span
          [coContent]="
            !showDetails[i]
              ? pensionPlanCard.showDetailsText
              : pensionPlanCard.hideDetailsText
          "
        ></span
        ><co-icon-maximize
          *ngIf="!showDetails[i]"
          class="icon"
          data-test-id="pensionPlanCardShowDetails"
        ></co-icon-maximize>
        <co-icon-minimize
          *ngIf="showDetails[i]"
          class="icon"
          data-test-id="pensionPlanCardHideDetails"
        ></co-icon-minimize>
      </button>

      <!-- ACTION, EVENT -->
      <button
        *ngIf="pensionPlanCard.event"
        (click)="pensionPlanCardSelected(pensionPlanCard.event.id)"
        mat-raised-button
        data-test-id="pensionPlanCardAction"
      >
        <span>{{ pensionPlanCard.event.text | content }}</span>
        <co-icon-arrow-right
          class="icon icon--arrow-right"
        ></co-icon-arrow-right>
      </button>
    </div>
  </div>
</co-card>
