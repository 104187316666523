<!-- v7 -->
<svg
  id="Layer_1"
  data-name="Layer 1"
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 32 32"
>
  <g id="Canvas">
    <circle
      id="Large_circle"
      data-name="Large circle"
      cx="16"
      cy="16"
      r="16"
      fill="none"
    />
    <circle
      id="Small_circle"
      data-name="Small circle"
      cx="16"
      cy="16"
      r="12"
      fill="none"
    />
  </g>
  <g id="Icon">
    <rect
      id="Body"
      x="8"
      y="10"
      width="16"
      height="12"
      rx="2"
      stroke-width="1.2"
      stroke="#000"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
    />
    <line
      id="Right_diagonal"
      data-name="Right diagonal"
      x1="23.5"
      y1="21.25"
      x2="19.17"
      y2="17.5"
      stroke-width="1.2"
      stroke="#000"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
    />
    <line
      id="Left_diagonal"
      data-name="Left diagonal"
      x1="12.83"
      y1="17.5"
      x2="8.5"
      y2="21.25"
      stroke-width="1.2"
      stroke="#000"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
    />
    <polyline
      id="Flap"
      points="8.5 10.75 16 17.25 23.5 10.75"
      fill="none"
      stroke="#000"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.2"
    />
  </g>
</svg>
