/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const RenteFormSchema = JoiObj.string().valid(
        'RENTETILPASNING_KORT'
        ,
        'RENTETILPASNING_LANG'
        ,
        'FAST_RENTE'
        ,
        'EN_KOMBINATION'
        ,
        'VED_IKKE'
        
    )
.id('RenteFormSchema')

export type RenteForm = 'RENTETILPASNING_KORT' | 'RENTETILPASNING_LANG' | 'FAST_RENTE' | 'EN_KOMBINATION' | 'VED_IKKE';

export const RenteForm = {
    RentetilpasningKort: 'RENTETILPASNING_KORT' as RenteForm,
    RentetilpasningLang: 'RENTETILPASNING_LANG' as RenteForm,
    FastRente: 'FAST_RENTE' as RenteForm,
    EnKombination: 'EN_KOMBINATION' as RenteForm,
    VedIkke: 'VED_IKKE' as RenteForm
};

