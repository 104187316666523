<mitpfa-insurance-guide-risk-step
  *ngIf="(currentQuestion | async)?.value === 'RISK'"
  [financialSituation]="financialSituation"
  [insuranceDetails]="insuranceDetails"
  [currentQuestion]="currentQuestion"
  data-test-id="insurance-guide-risk-step"
>
</mitpfa-insurance-guide-risk-step>

<mitpfa-insurance-guide-loc-step
  *ngIf="(currentQuestion | async)?.value === 'LOC'"
  [financialSituation]="financialSituation"
  [currentQuestion]="currentQuestion"
  data-test-id="insurance-guide-loc-step"
>
</mitpfa-insurance-guide-loc-step>

<mitpfa-insurance-guide-debt-step
  *ngIf="(currentQuestion | async)?.value === 'DEBT'"
  [insuranceDetails]="insuranceDetails"
  [financialSituation]="financialSituation"
  [familyInformation]="familyInformation"
  [currentQuestion]="currentQuestion"
  data-test-id="insurance-guide-debt-step"
>
</mitpfa-insurance-guide-debt-step>
