import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
interface LoginRedirectRoute {
  url: string;
  params: Params;
}
@Injectable({
  providedIn: 'root'
})
export class LoginRedirectService {
  private _loggedInSuccessRoute: LoginRedirectRoute;
  get loggedInSuccessRoute(): LoginRedirectRoute {
    return this._loggedInSuccessRoute;
  }
  set loggedInSuccessRoute(value: LoginRedirectRoute) {
    this._loggedInSuccessRoute = value;
  }
}
