/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AlderInvesteringsprofil } from './alderInvesteringsprofil';
import { AlderInvesteringsprofilSchema } from './alderInvesteringsprofil';
import { SatserFonde } from './satserFonde';
import { SatserFondeSchema } from './satserFonde';
import { Sats } from './sats';
import { SatsSchema } from './sats';
import * as JoiObj from "joi";


    export const OmkostningssatserSchema = JoiObj.object({
        activeNow:
            
                    JoiObj.boolean()
                


.required()


        ,
        name:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        updatedAt:
            
            JoiObj.date()


.required()


        ,
        updatedBy:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        uuid:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        validFrom:
            
            JoiObj.date()

.allow(null)



        ,
        validFromFormatted:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        year:
            
                    JoiObj.number()
                


.required()


        ,
        aaop:
                JoiObj.array().items(
        
        JoiObj.link('#AlderInvesteringsprofilSchema')

        )
        

.required()


        ,
        aaopklima:
                JoiObj.array().items(
        
        JoiObj.link('#AlderInvesteringsprofilSchema')

        )
        

.required()


        ,
        aktieejendom:
                JoiObj.array().items(
        
        JoiObj.link('#AlderInvesteringsprofilSchema')

        )
        

.required()


        ,
        aktierOgEjendomme:
            
                    JoiObj.number()
                


.required()


        ,
        andreOmkPfa:
            
                    JoiObj.number()
                


.required()


        ,
        andreOmkStd:
            
                    JoiObj.number()
                


.required()


        ,
        dFripoliseGebyr:
            
                    JoiObj.number()
                


.required()


        ,
        dKnaek1:
            
                    JoiObj.number()
                


.required()


        ,
        dKnaek2:
            
                    JoiObj.number()
                


.required()


        ,
        dOmkAfDepot:
            
                    JoiObj.number()
                


.required()


        ,
        dOmkAfPrm:
            
                    JoiObj.number()
                


.required()


        ,
        depotRente:
            
                    JoiObj.number()
                


.required()


        ,
        fonde:
                JoiObj.array().items(
        
        JoiObj.link('#SatserFondeSchema')

        )
        

.required()


        ,
        gFOmkAfPrm1:
            
                    JoiObj.number()
                


.required()


        ,
        gFOmkAfPrm2:
            
                    JoiObj.number()
                


.required()


        ,
        gFOmkAfPrm3:
            
                    JoiObj.number()
                


.required()


        ,
        gFOmkAfPrm4:
            
                    JoiObj.number()
                


.required()


        ,
        gKnaek1:
            
                    JoiObj.number()
                


.required()


        ,
        gKnaek2:
            
                    JoiObj.number()
                


.required()


        ,
        gKnaek3:
            
                    JoiObj.number()
                


.required()


        ,
        gPOmkAfPrm1:
            
                    JoiObj.number()
                


.required()


        ,
        gPOmkAfPrm2:
            
                    JoiObj.number()
                


.required()


        ,
        gPOmkAfPrm3:
            
                    JoiObj.number()
                


.required()


        ,
        gPOmkAfPrm4:
            
                    JoiObj.number()
                


.required()


        ,
        gebyrProcent:
            
                    JoiObj.number()
                


.required()


        ,
        invOmk:
            
                    JoiObj.number()
                


.required()


        ,
        kkRente:
            
                    JoiObj.number()
                


.required()


        ,
        kundekapitalAaop:
            
                    JoiObj.number()
                


.required()


        ,
        maxGebyr:
            
                    JoiObj.number()
                


.required()


        ,
        minGebyr:
            
                    JoiObj.number()
                


.required()


        ,
        obligationer:
            
                    JoiObj.number()
                


.required()


        ,
        pFripoliseGebyr:
            
                    JoiObj.number()
                


.required()


        ,
        pKnaek1:
            
                    JoiObj.number()
                


.required()


        ,
        pKnaek2:
            
                    JoiObj.number()
                


.required()


        ,
        pOmkAfDepot:
            
                    JoiObj.number()
                


.required()


        ,
        pOmkAfPrm:
            
                    JoiObj.number()
                


.required()


        ,
        palSats:
            
                    JoiObj.number()
                


.required()


        
    })
                .shared(AlderInvesteringsprofilSchema)
                .shared(AlderInvesteringsprofilSchema)
                .shared(AlderInvesteringsprofilSchema)
                .shared(SatserFondeSchema)
.id('OmkostningssatserSchema')

export interface Omkostningssatser extends Sats { 
    aaop: Array<AlderInvesteringsprofil>;
    aaopklima: Array<AlderInvesteringsprofil>;
    aktieejendom: Array<AlderInvesteringsprofil>;
    aktierOgEjendomme: number;
    andreOmkPfa: number;
    andreOmkStd: number;
    dFripoliseGebyr: number;
    dKnaek1: number;
    dKnaek2: number;
    dOmkAfDepot: number;
    dOmkAfPrm: number;
    depotRente: number;
    fonde: Array<SatserFonde>;
    gFOmkAfPrm1: number;
    gFOmkAfPrm2: number;
    gFOmkAfPrm3: number;
    gFOmkAfPrm4: number;
    gKnaek1: number;
    gKnaek2: number;
    gKnaek3: number;
    gPOmkAfPrm1: number;
    gPOmkAfPrm2: number;
    gPOmkAfPrm3: number;
    gPOmkAfPrm4: number;
    gebyrProcent: number;
    invOmk: number;
    kkRente: number;
    kundekapitalAaop: number;
    maxGebyr: number;
    minGebyr: number;
    obligationer: number;
    pFripoliseGebyr: number;
    pKnaek1: number;
    pKnaek2: number;
    pOmkAfDepot: number;
    pOmkAfPrm: number;
    palSats: number;
}

