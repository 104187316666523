/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ServiceInfo } from './serviceInfo';
import { ServiceInfoSchema } from './serviceInfo';
import { ResourceModel } from './resourceModel';
import { ResourceModelSchema } from './resourceModel';
import { IndbetalingsaftaleDetaljer } from './indbetalingsaftaleDetaljer';
import { IndbetalingsaftaleDetaljerSchema } from './indbetalingsaftaleDetaljer';
import * as JoiObj from "joi";


    export const HentIndbetalingsaftaleResponseSchema = JoiObj.object({
        serviceInfo:
            
        JoiObj.link('#ServiceInfoSchema')

.allow(null)



        ,
        cprMatchFejl:
            
                    JoiObj.boolean()
                


.required()


        ,
        cvrMatchFejl:
            
                    JoiObj.boolean()
                


.required()


        ,
        hentIndbetalingsaftaleDetaljerFejlede:
            
                    JoiObj.boolean()
                

.allow(null)



        ,
        indbetalingsaftaleDetaljer:
            
        JoiObj.link('#IndbetalingsaftaleDetaljerSchema')


.required()


        ,
        inputFejl:
            
                    JoiObj.boolean()
                


.required()


        
    })
                .shared(ServiceInfoSchema)
                .shared(IndbetalingsaftaleDetaljerSchema)
.id('HentIndbetalingsaftaleResponseSchema')

export interface HentIndbetalingsaftaleResponse extends ResourceModel { 
    cprMatchFejl: boolean;
    cvrMatchFejl: boolean;
    hentIndbetalingsaftaleDetaljerFejlede?: boolean;
    indbetalingsaftaleDetaljer: IndbetalingsaftaleDetaljer;
    inputFejl: boolean;
}

