import { inject, Injectable } from '@angular/core';
import { GenericStore } from "./GenericStore";

        import { BeneficiaryStore } from './beneficiary.serviceStore';
        import { BrugerStore } from './bruger.serviceStore';
        import { BrugerundersoegelseStore } from './brugerundersoegelse.serviceStore';
        import { ClimatefootprintStore } from './climatefootprint.serviceStore';
        import { CustomerdigitalidentityStore } from './customerdigitalidentity.serviceStore';
        import { CustomerprofileStore } from './customerprofile.serviceStore';
        import { DigitalsalgStore } from './digitalsalg.serviceStore';
        import { DynamicadvisingStore } from './dynamicadvising.serviceStore';
        import { ExternaltransferStore } from './externaltransfer.serviceStore';
        import { FordeleStore } from './fordele.serviceStore';
        import { ForsikringStore } from './forsikring.serviceStore';
        import { ForsikringsguideStore } from './forsikringsguide.serviceStore';
        import { ForsikringsoversigtStore } from './forsikringsoversigt.serviceStore';
        import { IndbetalingStore } from './indbetaling.serviceStore';
        import { IndbetalingsaftalerStore } from './indbetalingsaftaler.serviceStore';
        import { IndividualcustomerdossierStore } from './individualcustomerdossier.serviceStore';
        import { IndividualcustomerprofileStore } from './individualcustomerprofile.serviceStore';
        import { InvesteringStore } from './investering.serviceStore';
        import { KundekapitalStore } from './kundekapital.serviceStore';
        import { LogoutStore } from './logout.serviceStore';
        import { MicroappconfigurationStore } from './microappconfiguration.serviceStore';
        import { MineaendringerStore } from './mineaendringer.serviceStore';
        import { MobilesigningStore } from './mobilesigning.serviceStore';
        import { OnboardingStore } from './onboarding.serviceStore';
        import { OnlineaccountStore } from './onlineaccount.serviceStore';
        import { OpenforbusinessStore } from './openforbusiness.serviceStore';
        import { OverfoerselStore } from './overfoersel.serviceStore';
        import { PensioninfoStore } from './pensioninfo.serviceStore';
        import { PensionskundeStore } from './pensionskunde.serviceStore';
        import { PensionsplanStore } from './pensionsplan.serviceStore';
        import { PensionsrapportStore } from './pensionsrapport.serviceStore';
        import { PrislisteStore } from './prisliste.serviceStore';
        import { SavingsStore } from './savings.serviceStore';
        import { SavingsPlanStore } from './savingsPlan.serviceStore';
        import { SavingsreturnStore } from './savingsreturn.serviceStore';
        import { SkadesanmeldelseStore } from './skadesanmeldelse.serviceStore';
        import { SpoergeskemaStore } from './spoergeskema.serviceStore';
        import { SystemadministrationStore } from './systemadministration.serviceStore';
        import { UdbetalingsplanStore } from './udbetalingsplan.serviceStore';
        import { UploadskadesanmeldelsedocumentStore } from './uploadskadesanmeldelsedocument.serviceStore';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private storeList : GenericStore[] = [
            inject(BeneficiaryStore),
            inject(BrugerStore),
            inject(BrugerundersoegelseStore),
            inject(ClimatefootprintStore),
            inject(CustomerdigitalidentityStore),
            inject(CustomerprofileStore),
            inject(DigitalsalgStore),
            inject(DynamicadvisingStore),
            inject(ExternaltransferStore),
            inject(FordeleStore),
            inject(ForsikringStore),
            inject(ForsikringsguideStore),
            inject(ForsikringsoversigtStore),
            inject(IndbetalingStore),
            inject(IndbetalingsaftalerStore),
            inject(IndividualcustomerdossierStore),
            inject(IndividualcustomerprofileStore),
            inject(InvesteringStore),
            inject(KundekapitalStore),
            inject(LogoutStore),
            inject(MicroappconfigurationStore),
            inject(MineaendringerStore),
            inject(MobilesigningStore),
            inject(OnboardingStore),
            inject(OnlineaccountStore),
            inject(OpenforbusinessStore),
            inject(OverfoerselStore),
            inject(PensioninfoStore),
            inject(PensionskundeStore),
            inject(PensionsplanStore),
            inject(PensionsrapportStore),
            inject(PrislisteStore),
            inject(SavingsStore),
            inject(SavingsPlanStore),
            inject(SavingsreturnStore),
            inject(SkadesanmeldelseStore),
            inject(SpoergeskemaStore),
            inject(SystemadministrationStore),
            inject(UdbetalingsplanStore),
            inject(UploadskadesanmeldelsedocumentStore)
  ];

  public invalidateAll() : void {
    this.storeList.forEach((store : GenericStore) => {
      store.invalidateAll();
    });
  }
}
