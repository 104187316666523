import { MatDialog } from '@angular/material/dialog';
import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { AdvisorCorrectionsApiService } from '@pfa/api';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import * as moment from 'moment';
import { AdvisorCorrectionComponent } from '../advisor-correction/advisor-correction.component';
import { PensionsInfoOrdninger, PensionsKundeGenerelleData } from '@pfa/gen';

@Component({
  selector: 'co-customer-advisor-corrections',
  templateUrl: './customer-advisor-corrections.component.html'
})
export class CustomerAdvisorCorrectionsComponent implements OnInit, OnChanges {
  @Input() pensionPlan: PensionsInfoOrdninger;
  @Input() pensionCustomer: PensionsKundeGenerelleData;
  @Output() updateParent = new EventEmitter();
  correctionDateFormatted: string;
  advisorCorrectionsForm: UntypedFormGroup;
  saveCorrectionsFailed: boolean;

  constructor(
    private readonly dialog: MatDialog,
    private readonly advisorCorrectionsApiService: AdvisorCorrectionsApiService
  ) {}

  ngOnInit() {
    if (this.pensionCustomer.isRaadgiver) {
      this.initAdvisorCorrections();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.pensionPlan &&
      changes.pensionPlan.previousValue &&
      changes.pensionPlan.currentValue
    ) {
      this.updateCorrectionDate();
    }
  }

  initAdvisorCorrections(): void {
    if (this.pensionPlan.advisorCorrectionsDetails) {
      this.advisorCorrectionsForm = new UntypedFormGroup({
        correctionDataUsed: new UntypedFormControl(false)
      });
      if (this.pensionPlan.advisorCorrectionsDetails.aktor) {
        this.updateCorrectionDate();
      }
    }
  }

  showCorrectionsModal(): void {
    this.dialog
      .open(AdvisorCorrectionComponent, {
        autoFocus: false,
        data: {
          advisorCorrections: this.pensionPlan.advisorCorrectionsDetails,
          isPartner: false,
          retirementAge: this.pensionCustomer.retirementAge,
          pensionCustomer: this.pensionCustomer
        },
        minWidth: '90vw',
        height: '90vh'
      })
      .afterClosed()
      .subscribe((saved: boolean) => {
        if (saved) {
          this.correctionsSaved(true);
        }
      });
  }

  correctionsSaved(useCorrections: boolean): void {
    this.saveCorrectionsFailed = false;
    this.advisorCorrectionsApiService
      .applyCorrections(useCorrections)
      .subscribe({
        next: () => {
          this.updateParent.emit();
        },
        error: () => {
          this.saveCorrectionsFailed = true;
        }
      });
  }

  updateCorrectionDate(): void {
    this.correctionDateFormatted = moment(
      new Date(this.pensionPlan.advisorCorrectionsDetails.changeAt),
      'DD.MM.YYYY'
    ).format('DD.MM.YYYY HH:mm');
    if (this.pensionPlan.advisorCorrectionsDetails.aktor) {
      const correctionDataUsed =
        this.pensionPlan.advisorCorrectionsDetails.anvendt;
      this.advisorCorrectionsForm.controls['correctionDataUsed'].setValue(
        correctionDataUsed
      );
    }
  }
}
