<co-accordion [title]="'DL.OS01.C375'">
  <div [coContent]="'DL.OS01.C422'" class="intro">her kan du se...</div>
  <table
    class="pfa-table table-responsive align-left--column-1 align-right--column-2"
    [attr.aria-label]="'DL.OS01.C422' | content"
  >
    <thead class="hide--palm">
      <tr>
        <th scope="col" [coContent]="'DL.OS01.C424'">koncept</th>
        <th scope="col" [coContent]="'DL.OS01.C426'">beløb</th>
      </tr>
    </thead>
    <tbody>
      <!-- PFA INVEST -->
      <tr *ngIf="concepts.pfaInvesterer">
        <td>
          <co-table-accordion-control
            [contentId]="'DL.OS01.C376'"
            (expandedChange)="
              toggleExpanded($event, conceptDetailsType().PFA_INVEST)
            "
          ></co-table-accordion-control>
        </td>
        <td [attr.data-label]="'DL.OS01.C426' | content" class="text--bold">
          {{ concepts.pfaInvesterer | numberFormat: 2 }}
          <span [coContent]="'Global.kroner'"></span>
        </td>
      </tr>
      <ng-container *ngIf="expanded.pfaInvest">
        <tr>
          <td class="emphasized" [coContent]="'DL.OS01.C402'"></td>
          <td class="emphasized">
            {{ savingsPolicyDetails.varighedsFonde | numberFormat: 2 }}
            <span [coContent]="'Global.kroner'"></span>
          </td>
        </tr>
        <tr>
          <td class="emphasized" [coContent]="'DL.OS01.C403'"></td>
          <td class="emphasized">
            {{
              savingsPolicyDetails.vaerdiAfOpsparingUltimo.opsparingFordeling
                .koncepter.pfaInvesterer - savingsPolicyDetails.varighedsFonde
                | numberFormat: 2
            }}
            <span [coContent]="'Global.kroner'"></span>
          </td>
        </tr>
      </ng-container>

      <!-- PFA CHOISE -->
      <tr *ngIf="concepts.pfaValgfri">
        <td
          [attr.data-label]="'DL.OS01.C424' | content"
          [coContent]="'DL.OS01.C397'"
        ></td>
        <td [attr.data-label]="'DL.OS01.C426' | content" class="text--bold">
          {{ concepts.pfaValgfri | numberFormat: 2 }}
          <span [coContent]="'Global.kroner'"></span>
        </td>
      </tr>

      <!-- YOU INVEST -->
      <tr *ngIf="concepts.duInvesterer">
        <td
          [attr.data-label]="'DL.OS01.C424' | content"
          [coContent]="'DL.OS01.C377'"
        ></td>
        <td [attr.data-label]="'beløb'" class="text--bold">
          {{ concepts.duInvesterer | numberFormat: 2 }}
          <span [coContent]="'Global.kroner'"></span>
        </td>
      </tr>

      <!-- AVERAGE RATE -->
      <tr *ngIf="concepts.gennemsnitsrente?.total">
        <td>
          <co-table-accordion-control
            [contentId]="'DL.OS01.C378'"
            (expandedChange)="
              toggleExpanded($event, conceptDetailsType().AVERAGE_RATE)
            "
          ></co-table-accordion-control>
        </td>
        <td [attr.data-label]="'DL.OS01.C426' | content" class="text--bold">
          {{ concepts.gennemsnitsrente.total | numberFormat: 2 }}
          <span [coContent]="'Global.kroner'"></span>
        </td>
      </tr>
      <ng-container *ngIf="expanded.averageRate">
        <tr
          *ngFor="let ordning of concepts.gennemsnitsrente.tidligereOrdninger"
        >
          <td
            class="emphasized"
            [coContent]="ordning.contentId"
            [coContentSubst]="ordning.contentParameter"
          ></td>
          <td class="emphasized">
            {{ ordning.value | numberFormat: 2 }}
            <span *ngIf="ordning.value" [coContent]="'Global.kroner'"></span>
          </td>
        </tr>
      </ng-container>

      <!-- CUSTOMER CAPITAL -->
      <tr *ngIf="concepts.kundekapital?.total">
        <td
          [attr.data-label]="
            showCustomerCapitalDetails ? '' : ('DL.OS01.C424' | content)
          "
        >
          <co-table-accordion-control
            [contentId]="'DL.OS01.C383'"
            [show]="showCustomerCapitalDetails"
            (expandedChange)="
              toggleExpanded($event, conceptDetailsType().CUSTOMER_CAPITAL)
            "
          ></co-table-accordion-control>
        </td>
        <td [attr.data-label]="'DL.OS01.C426' | content" class="text--bold">
          {{ concepts.kundekapital.total | numberFormat: 2 }}
          <span [coContent]="'Global.kroner'"></span>
        </td>
      </tr>
      <ng-container *ngIf="expanded.customerCapital">
        <tr *ngIf="concepts.kundekapital.markedsrente">
          <td class="emphasized" [coContent]="'DL.OS01.C381'"></td>
          <td class="emphasized">
            {{ concepts.kundekapital.markedsrente | numberFormat: 2 }}
            <span [coContent]="'Global.kroner'"></span>
          </td>
        </tr>
        <tr *ngIf="concepts.kundekapital.gennemsnitsrente">
          <td class="emphasized" [coContent]="'DL.OS01.C378'"></td>
          <td class="emphasized">
            {{ concepts.kundekapital.gennemsnitsrente | numberFormat: 2 }}
            <span [coContent]="'Global.kroner'"></span>
          </td>
        </tr>
      </ng-container>

      <tr class="border-top--thick">
        <td
          [attr.data-label]="'DL.OS01.C424' | content"
          [coContent]="'DL.OS01.C209'"
        >
          Ialt
        </td>
        <td [attr.data-label]="'DL.OS01.C426' | content">
          <co-primary-number
            [amount]="productAndConceptData.value"
            [showDecimals]="true"
            class="size-sm"
          ></co-primary-number>
        </td>
      </tr>
    </tbody>
  </table>
</co-accordion>
