/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const ClimateWillingnessSchema = JoiObj.string().valid(
        'NEUTRAL'
        ,
        'MINOR'
        ,
        'LIMIT'
        
    )
.id('ClimateWillingnessSchema')

export type ClimateWillingness = 'NEUTRAL' | 'MINOR' | 'LIMIT';

export const ClimateWillingness = {
    Neutral: 'NEUTRAL' as ClimateWillingness,
    Minor: 'MINOR' as ClimateWillingness,
    Limit: 'LIMIT' as ClimateWillingness
};

