import { Injectable } from '@angular/core';
import { ContentUtilService } from '@pfa/core';
import { Step } from '@pfaform';
import { ForsikringDetaljer, TaeNytte } from '@pfa/gen';

export type InsuranceGuideStepId = 'intro' | 'pensionInfo' | 'risk' | 'summary';

export enum InsuranceGuideQuestion {
  intro = 'INTRO',
  pensionInfo = 'PENSION_INFO',
  loc = 'LOC',
  family = 'FAMILY',
  assets = 'ASSETS',
  risk = 'RISK',
  debt = 'DEBT',
  recommendation = 'RECOMMENDATION'
}

export interface InsuranceGuideQuestionValue {
  value: InsuranceGuideQuestion;
  back?: boolean;
}

export interface InsuranceGuideState {
  looc: boolean;
  life: boolean;
  ci: boolean;
}

export const QuestionTrackingMap = new Map<InsuranceGuideQuestion, string>([
  [InsuranceGuideQuestion.intro, 'introduction'],
  [InsuranceGuideQuestion.pensionInfo, 'pensionsinfo'],
  [InsuranceGuideQuestion.family, 'family'],
  [InsuranceGuideQuestion.assets, 'economic wealth'],
  [InsuranceGuideQuestion.risk, 'coverage importance'],
  [InsuranceGuideQuestion.debt, 'extra coverage'],
  [InsuranceGuideQuestion.loc, 'high coverage'],
  [InsuranceGuideQuestion.recommendation, 'recommendation']
]);

export const QuestionIndexMap = new Map<InsuranceGuideQuestion, number>([
  [InsuranceGuideQuestion.intro, 0],
  [InsuranceGuideQuestion.pensionInfo, 1],
  [InsuranceGuideQuestion.family, 2],
  [InsuranceGuideQuestion.assets, 3],
  [InsuranceGuideQuestion.risk, 4],
  [InsuranceGuideQuestion.debt, 5],
  [InsuranceGuideQuestion.loc, 6],
  [InsuranceGuideQuestion.recommendation, 7]
]);

@Injectable()
export class InsuranceGuideConfigurationService {
  private static questions: {
    [id in InsuranceGuideStepId]: InsuranceGuideQuestion[];
  } = {
    intro: [InsuranceGuideQuestion.intro],
    pensionInfo: [
      InsuranceGuideQuestion.pensionInfo,
      InsuranceGuideQuestion.family,
      InsuranceGuideQuestion.assets
    ],
    risk: [
      InsuranceGuideQuestion.risk,
      InsuranceGuideQuestion.loc,
      InsuranceGuideQuestion.debt
    ],
    summary: [InsuranceGuideQuestion.recommendation]
  };

  /**
   * This is the number of questions that are treated as actual questions when
   * the guide is shown in the welcome flow. These are:
   * <ul>
   *   <li>InsuranceGuideQuestion.pensionInfo</li>
   *   <li>InsuranceGuideQuestion.family</li>
   *   <li>InsuranceGuideQuestion.assets</li>
   *   <li>InsuranceGuideQuestion.risk</li>
   *   <li>InsuranceGuideQuestion.loc (SITUATIONAL)</li>
   *   <li>InsuranceGuideQuestion.debt (SITUATIONAL)</li>
   * </ul>
   */
  numberOfQuestions = 4;
  showDebtStep: boolean;

  public steps: Step[] = [
    {
      label: this.contentUtil.getContent('DL.FG01.C1384'),
      id: 'intro',
      progress: 0
    },
    {
      label: this.contentUtil.getContent('DL.FG01.C1394'),
      id: 'pensionInfo',
      progress: 0
    },
    {
      label: this.contentUtil.getContent('DL.FG01.C1403'),
      id: 'risk',
      progress: 0
    },
    {
      label: this.contentUtil.getContent('DL.FG01.C1413'),
      id: 'summary',
      progress: 0
    }
  ];

  constructor(readonly contentUtil: ContentUtilService) {}

  setShowDebtStep(show: boolean): void {
    if (show && !this.showDebtStep) {
      this.numberOfQuestions++;
    }
    this.showDebtStep = show;
  }

  getQuestions(stepId: string): InsuranceGuideQuestion[] {
    let questions: Array<InsuranceGuideQuestion> =
      InsuranceGuideConfigurationService.questions[stepId];
    if (!this.showDebtStep && stepId === 'risk') {
      questions = questions.filter(
        question => question !== InsuranceGuideQuestion.debt
      );
    }
    return questions;
  }

  public findQuestionStepId(
    question: InsuranceGuideQuestion
  ): InsuranceGuideStepId | undefined {
    for (const id in InsuranceGuideConfigurationService.questions) {
      if (
        InsuranceGuideConfigurationService.questions[id].indexOf(question) > -1
      ) {
        return id as InsuranceGuideStepId;
      }
    }
  }

  public getRecommendationRiskText(riskValue: TaeNytte): string {
    let contentId = '';
    switch (riskValue) {
      case 'HOEJ':
        contentId = 'DL.FG01.C1406';
        break;
      case 'MELLEM':
        contentId = 'DL.FG01.C1407';
        break;
      case 'LAV':
        contentId = 'DL.FG01.C1408';
        break;
    }
    return contentId;
  }

  getInsuranceGuideDisabled(
    insuranceDetails: ForsikringDetaljer
  ): InsuranceGuideState {
    return {
      looc:
        insuranceDetails.tilpasForsikringstal.sliderTAEloebende.locked &&
        !(
          insuranceDetails.tilpasForsikringstal.autoAdjustment &&
          insuranceDetails.tilpasForsikringstal.autoAdjustment.type === 'TAE'
        ),
      life: insuranceDetails.tilpasForsikringstal.sliderLiv.locked,
      ci: !insuranceDetails.tilpasForsikringstal
        .daekningAendringKritiskSygdomSelv.skalVises
    } as InsuranceGuideState;
  }
}
