/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { MaeglerLokation } from './maeglerLokation';
import { MaeglerLokationSchema } from './maeglerLokation';
import { DaekningGruppe } from './daekningGruppe';
import { DaekningGruppeSchema } from './daekningGruppe';
import { ProcentInterval } from './procentInterval';
import { ProcentIntervalSchema } from './procentInterval';
import { TransaktionPris } from './transaktionPris';
import { TransaktionPrisSchema } from './transaktionPris';
import * as JoiObj from "joi";


    export const VederlagOmkostningSchema = JoiObj.object({
        agenturNr:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        erVederlagLoebendeSaerskiltForIndskud:
            
                    JoiObj.boolean()
                


.required()


        ,
        vederlagLoebendeUdvalgteDaekningsgrupperProcentSats:
            
                    JoiObj.number()
                

.allow(null)



        ,
        vederlagLoebendeUdvalgteDaekningsgrupperListe:
                JoiObj.array().items(
        
        JoiObj.link('#DaekningGruppeSchema')

        )
        
.allow(null)



        ,
        etableringOmkostningDepotKnaekListe:
                JoiObj.array().items(
        
        JoiObj.link('#ProcentIntervalSchema')

        )
        

.required()


        ,
        etableringOmkostningFastBeloeb:
            
                    JoiObj.number()
                


.required()


        ,
        etableringOmkostningKnaekListe:
                JoiObj.array().items(
        
        JoiObj.link('#ProcentIntervalSchema')

        )
        

.required()


        ,
        etableringOmkostningOphoerDato:
            
            JoiObj.date()

.allow(null)



        ,
        loebendeOmkostningDepotKnaekListe:
                JoiObj.array().items(
        
        JoiObj.link('#ProcentIntervalSchema')

        )
        

.required()


        ,
        loebendeOmkostningFastBeloeb:
            
                    JoiObj.number()
                


.required()


        ,
        loebendeOmkostningIndskudKnaekListe:
                JoiObj.array().items(
        
        JoiObj.link('#ProcentIntervalSchema')

        )
        

.required()


        ,
        loebendeOmkostningKnaekListe:
                JoiObj.array().items(
        
        JoiObj.link('#ProcentIntervalSchema')

        )
        

.required()


        ,
        maeglerLokation:
            
        JoiObj.link('#MaeglerLokationSchema')

.allow(null)



        ,
        transaktionPrisListe:
                JoiObj.array().items(
        
        JoiObj.link('#TransaktionPrisSchema')

        )
        

.required()


        
    })
                .shared(DaekningGruppeSchema)
                .shared(ProcentIntervalSchema)
                .shared(ProcentIntervalSchema)
                .shared(ProcentIntervalSchema)
                .shared(ProcentIntervalSchema)
                .shared(ProcentIntervalSchema)
                .shared(MaeglerLokationSchema)
                .shared(TransaktionPrisSchema)
.id('VederlagOmkostningSchema')

export interface VederlagOmkostning { 
    agenturNr: string;
    erVederlagLoebendeSaerskiltForIndskud: boolean;
    vederlagLoebendeUdvalgteDaekningsgrupperProcentSats?: number;
    vederlagLoebendeUdvalgteDaekningsgrupperListe?: Array<DaekningGruppe>;
    etableringOmkostningDepotKnaekListe: Array<ProcentInterval>;
    etableringOmkostningFastBeloeb: number;
    etableringOmkostningKnaekListe: Array<ProcentInterval>;
    etableringOmkostningOphoerDato?: Date;
    loebendeOmkostningDepotKnaekListe: Array<ProcentInterval>;
    loebendeOmkostningFastBeloeb: number;
    loebendeOmkostningIndskudKnaekListe: Array<ProcentInterval>;
    loebendeOmkostningKnaekListe: Array<ProcentInterval>;
    maeglerLokation?: MaeglerLokation;
    transaktionPrisListe: Array<TransaktionPris>;
}

