/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Daekning } from './daekning';
import { DaekningSchema } from './daekning';
import * as JoiObj from "joi";


    export const ForsikringSchema = JoiObj.object({
        aegtefaellepensionerOphoerendeRisiko:
                JoiObj.array().items(
        
        JoiObj.link('#DaekningSchema')

        )
        

.required()


        ,
        boernepensionTilForaeldreloese:
                JoiObj.array().items(
        
        JoiObj.link('#DaekningSchema')

        )
        

.required()


        ,
        boernepensioner:
                JoiObj.array().items(
        
        JoiObj.link('#DaekningSchema')

        )
        

.required()


        ,
        boernepensionerMedRisikoophoer:
                JoiObj.array().items(
        
        JoiObj.link('#DaekningSchema')

        )
        

.required()


        ,
        boernepensionerVedInvaliditetpension:
                JoiObj.array().items(
        
        JoiObj.link('#DaekningSchema')

        )
        

.required()


        ,
        boernepensionerVedInvaliditetpensionMedRisikoophoer:
                JoiObj.array().items(
        
        JoiObj.link('#DaekningSchema')

        )
        

.required()


        ,
        depotsikringer:
                JoiObj.array().items(
        
        JoiObj.link('#DaekningSchema')

        )
        

.required()


        ,
        engangbeloebTilUgifte:
                JoiObj.array().items(
        
        JoiObj.link('#DaekningSchema')

        )
        

.required()


        ,
        engangsBeloebBoern:
                JoiObj.array().items(
        
        JoiObj.link('#DaekningSchema')

        )
        

.required()


        ,
        engangsBeloebBoernMedRisikoOphoer:
                JoiObj.array().items(
        
        JoiObj.link('#DaekningSchema')

        )
        

.required()


        ,
        engangsBeloeber:
                JoiObj.array().items(
        
        JoiObj.link('#DaekningSchema')

        )
        

.required()


        ,
        engangsBeloeberUdenRisikoophoer:
                JoiObj.array().items(
        
        JoiObj.link('#DaekningSchema')

        )
        

.required()


        ,
        engangsbeloebTilUgifteRater:
                JoiObj.array().items(
        
        JoiObj.link('#DaekningSchema')

        )
        

.required()


        ,
        forebygger:
                JoiObj.array().items(
        
        JoiObj.link('#DaekningSchema')

        )
        

.required()


        ,
        garantiForLaengsteLiv:
                JoiObj.array().items(
        
        JoiObj.link('#DaekningSchema')

        )
        

.required()


        ,
        garantiForLivRatepensioner:
                JoiObj.array().items(
        
        JoiObj.link('#DaekningSchema')

        )
        

.required()


        ,
        garantiForUdbetalinger:
                JoiObj.array().items(
        
        JoiObj.link('#DaekningSchema')

        )
        

.required()


        ,
        helbredssikringer:
                JoiObj.array().items(
        
        JoiObj.link('#DaekningSchema')

        )
        

.required()


        ,
        helbredssikringerBoern:
                JoiObj.array().items(
        
        JoiObj.link('#DaekningSchema')

        )
        

.required()


        ,
        ikkeVisteHelbredSikringer:
                JoiObj.array().items(
        
        JoiObj.link('#DaekningSchema')

        )
        

.required()


        ,
        ikkeVisteHelbreder:
                JoiObj.array().items(
        
        JoiObj.link('#DaekningSchema')

        )
        

.required()


        ,
        ikkeVisteKritiskSygdomme:
                JoiObj.array().items(
        
        JoiObj.link('#DaekningSchema')

        )
        

.required()


        ,
        ikkeVisteLivforsikringer:
                JoiObj.array().items(
        
        JoiObj.link('#DaekningSchema')

        )
        

.required()


        ,
        ikkeVisteLivforsikringerAegtefaellepensioner:
                JoiObj.array().items(
        
        JoiObj.link('#DaekningSchema')

        )
        

.required()


        ,
        indbetalingssikringer:
                JoiObj.array().items(
        
        JoiObj.link('#DaekningSchema')

        )
        

.required()


        ,
        invalidepensioner:
                JoiObj.array().items(
        
        JoiObj.link('#DaekningSchema')

        )
        

.required()


        ,
        invalidesummer:
                JoiObj.array().items(
        
        JoiObj.link('#DaekningSchema')

        )
        

.required()


        ,
        kritiskSygdomme:
                JoiObj.array().items(
        
        JoiObj.link('#DaekningSchema')

        )
        

.required()


        ,
        kritiskSygdommeBoern:
                JoiObj.array().items(
        
        JoiObj.link('#DaekningSchema')

        )
        

.required()


        ,
        livsvarigAegtefaellepensioner:
                JoiObj.array().items(
        
        JoiObj.link('#DaekningSchema')

        )
        

.required()


        ,
        ophoerendeAegtefaellepensioner:
                JoiObj.array().items(
        
        JoiObj.link('#DaekningSchema')

        )
        

.required()


        ,
        opsatAegtefaellepensioner:
                JoiObj.array().items(
        
        JoiObj.link('#DaekningSchema')

        )
        

.required()


        ,
        pfaDiagnoser:
                JoiObj.array().items(
        
        JoiObj.link('#DaekningSchema')

        )
        

.required()


        ,
        pfaHelbredssikringer:
                JoiObj.array().items(
        
        JoiObj.link('#DaekningSchema')

        )
        

.required()


        ,
        pfaHelbredssikringerBoern:
                JoiObj.array().items(
        
        JoiObj.link('#DaekningSchema')

        )
        

.required()


        ,
        ratepensionerLiv:
                JoiObj.array().items(
        
        JoiObj.link('#DaekningSchema')

        )
        

.required()


        ,
        resourceforloeb898:
                JoiObj.array().items(
        
        JoiObj.link('#DaekningSchema')

        )
        

.required()


        ,
        resourceforloeb899:
                JoiObj.array().items(
        
        JoiObj.link('#DaekningSchema')

        )
        

.required()


        ,
        sikringAfEfterladte:
                JoiObj.array().items(
        
        JoiObj.link('#DaekningSchema')

        )
        

.required()


        
    })
                .shared(DaekningSchema)
                .shared(DaekningSchema)
                .shared(DaekningSchema)
                .shared(DaekningSchema)
                .shared(DaekningSchema)
                .shared(DaekningSchema)
                .shared(DaekningSchema)
                .shared(DaekningSchema)
                .shared(DaekningSchema)
                .shared(DaekningSchema)
                .shared(DaekningSchema)
                .shared(DaekningSchema)
                .shared(DaekningSchema)
                .shared(DaekningSchema)
                .shared(DaekningSchema)
                .shared(DaekningSchema)
                .shared(DaekningSchema)
                .shared(DaekningSchema)
                .shared(DaekningSchema)
                .shared(DaekningSchema)
                .shared(DaekningSchema)
                .shared(DaekningSchema)
                .shared(DaekningSchema)
                .shared(DaekningSchema)
                .shared(DaekningSchema)
                .shared(DaekningSchema)
                .shared(DaekningSchema)
                .shared(DaekningSchema)
                .shared(DaekningSchema)
                .shared(DaekningSchema)
                .shared(DaekningSchema)
                .shared(DaekningSchema)
                .shared(DaekningSchema)
                .shared(DaekningSchema)
                .shared(DaekningSchema)
                .shared(DaekningSchema)
                .shared(DaekningSchema)
                .shared(DaekningSchema)
                .shared(DaekningSchema)
.id('ForsikringSchema')

export interface Forsikring { 
    aegtefaellepensionerOphoerendeRisiko: Array<Daekning>;
    boernepensionTilForaeldreloese: Array<Daekning>;
    boernepensioner: Array<Daekning>;
    boernepensionerMedRisikoophoer: Array<Daekning>;
    boernepensionerVedInvaliditetpension: Array<Daekning>;
    boernepensionerVedInvaliditetpensionMedRisikoophoer: Array<Daekning>;
    depotsikringer: Array<Daekning>;
    engangbeloebTilUgifte: Array<Daekning>;
    engangsBeloebBoern: Array<Daekning>;
    engangsBeloebBoernMedRisikoOphoer: Array<Daekning>;
    engangsBeloeber: Array<Daekning>;
    engangsBeloeberUdenRisikoophoer: Array<Daekning>;
    engangsbeloebTilUgifteRater: Array<Daekning>;
    forebygger: Array<Daekning>;
    garantiForLaengsteLiv: Array<Daekning>;
    garantiForLivRatepensioner: Array<Daekning>;
    garantiForUdbetalinger: Array<Daekning>;
    helbredssikringer: Array<Daekning>;
    helbredssikringerBoern: Array<Daekning>;
    ikkeVisteHelbredSikringer: Array<Daekning>;
    ikkeVisteHelbreder: Array<Daekning>;
    ikkeVisteKritiskSygdomme: Array<Daekning>;
    ikkeVisteLivforsikringer: Array<Daekning>;
    ikkeVisteLivforsikringerAegtefaellepensioner: Array<Daekning>;
    indbetalingssikringer: Array<Daekning>;
    invalidepensioner: Array<Daekning>;
    invalidesummer: Array<Daekning>;
    kritiskSygdomme: Array<Daekning>;
    kritiskSygdommeBoern: Array<Daekning>;
    livsvarigAegtefaellepensioner: Array<Daekning>;
    ophoerendeAegtefaellepensioner: Array<Daekning>;
    opsatAegtefaellepensioner: Array<Daekning>;
    pfaDiagnoser: Array<Daekning>;
    pfaHelbredssikringer: Array<Daekning>;
    pfaHelbredssikringerBoern: Array<Daekning>;
    ratepensionerLiv: Array<Daekning>;
    resourceforloeb898: Array<Daekning>;
    resourceforloeb899: Array<Daekning>;
    sikringAfEfterladte: Array<Daekning>;
}

