import { WarningResponse } from './pension-estimate-form-of-savings-warning.model';
import { MatDialogRef } from '@angular/material/dialog';
import { Component } from '@angular/core';

@Component({
  templateUrl: './pension-estimate-form-of-savings-warning.component.html',
  styleUrls: ['./pension-estimate-form-of-savings-warning.component.scss']
})
export class PensionEstimateFormOfSavingsWarningComponent {
  constructor(
    private readonly dialogRef: MatDialogRef<PensionEstimateFormOfSavingsWarningComponent>
  ) {
    dialogRef.disableClose = true;
  }

  accept() {
    const response: WarningResponse = { result: 'ACCEPT' };
    this.dialogRef.close(response);
  }

  cancel() {
    const response: WarningResponse = { result: 'CANCEL' };
    this.dialogRef.close(response);
  }
}
