/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ServiceInfo } from './serviceInfo';
import { ServiceInfoSchema } from './serviceInfo';
import { ResourceModel } from './resourceModel';
import { ResourceModelSchema } from './resourceModel';
import { SavingsReturnPolice } from './savingsReturnPolice';
import { SavingsReturnPoliceSchema } from './savingsReturnPolice';
import * as JoiObj from "joi";


    export const AfkastoversigtSchema = JoiObj.object({
        serviceInfo:
            
        JoiObj.link('#ServiceInfoSchema')

.allow(null)



        ,
        kunForsikringer:
            
                    JoiObj.boolean()
                


.required()


        ,
        opgoerelsesdatoOpsparingKoloneTooltipContentId:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        policer:
                JoiObj.array().items(
        
        JoiObj.link('#SavingsReturnPoliceSchema')

        )
        

.required()


        ,
        totalAfkast:
            
                    JoiObj.number()
                


.required()


        ,
        udbetalingDoedId:
                JoiObj.array().items(
        
                    JoiObj.number()
                .integer()

        )
        

.required()


        
    })
                .shared(ServiceInfoSchema)
                .shared(SavingsReturnPoliceSchema)
.id('AfkastoversigtSchema')

export interface Afkastoversigt extends ResourceModel { 
    kunForsikringer: boolean;
    opgoerelsesdatoOpsparingKoloneTooltipContentId: string;
    policer: Array<SavingsReturnPolice>;
    totalAfkast: number;
    udbetalingDoedId: Array<number>;
}

