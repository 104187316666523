import { Injectable } from '@angular/core';
import {
  InsuranceGuideQuestion,
  QuestionTrackingMap
} from './insurance-guide-configuration.service';
import {
  GuideExitAction,
  GuidePlacement,
  GuideStepType,
  GuideTrackingRecommendation,
  GuideTrackingService
} from '@pfa/core';
import {
  RECOMMENDATION_DIRECTION,
  RECOMMENDATION_TYPE,
  RecommendationDescription,
  recommendationDescriptions,
  recommendationDescriptionsForScale
} from './recommendation-step/insurance-guide.recommendation.model';
import {
  ForsikringDetaljer,
  LocScaleInformation,
  OekonomiDetaljer,
  PensionsInfoOrdninger
} from '@pfa/gen';
import { InsuranceUtilService } from '@pfaform';

@Injectable({
  providedIn: 'root'
})
export class InsuranceGuideService {
  constructor(private readonly guideTrackingService: GuideTrackingService) {}

  private advisorShortFlow: boolean;

  private static checkStepType(
    insuranceGuideQuestion: InsuranceGuideQuestion
  ): GuideStepType | undefined {
    switch (insuranceGuideQuestion) {
      case InsuranceGuideQuestion.intro:
      case InsuranceGuideQuestion.assets:
      case InsuranceGuideQuestion.risk:
        return GuideStepType.MANDATORY;
      case InsuranceGuideQuestion.loc:
      case InsuranceGuideQuestion.pensionInfo:
      case InsuranceGuideQuestion.family:
      case InsuranceGuideQuestion.debt:
        return GuideStepType.OPTIONAL;
    }
  }

  static calculateShouldKeepRecommendation(
    current: number,
    recommended: number,
    variation: number
  ): boolean {
    const diff = Math.abs(recommended - current);
    return diff <= variation;
  }

  private static getRecommendationTrackingText(
    recommendationDirection: RECOMMENDATION_DIRECTION,
    type: string,
    recommendationDescription?: RecommendationDescription
  ): string {
    let taeATText = '';
    switch (recommendationDescription?.recommendation) {
      case 'LOC_80':
        taeATText = 'automaticadjustment80';
        break;
      case 'LOC_100':
        taeATText = 'automaticadjustment100';
        break;
    }
    let actionText = '';
    switch (recommendationDirection) {
      case RECOMMENDATION_DIRECTION.KEEP:
        if (taeATText) {
          actionText = `keep ${taeATText}`;
        } else {
          actionText = 'keep current coverage';
        }
        break;
      case RECOMMENDATION_DIRECTION.INCREASE:
        if (taeATText) {
          actionText = `raise to ${taeATText}`;
        } else {
          actionText = 'raise current coverage';
        }
        break;
      case RECOMMENDATION_DIRECTION.DECREASE:
        if (taeATText) {
          actionText = `lower to ${taeATText}`;
        } else {
          actionText = 'lower current coverage';
        }
        break;
    }
    return `${type}: ${actionText}`;
  }

  static getRecommendationDescription(
    direction: RECOMMENDATION_DIRECTION,
    type: RECOMMENDATION_TYPE,
    locScaleInformation: LocScaleInformation | undefined
  ): RecommendationDescription {
    let recommendationDescription: RecommendationDescription;

    if (this.noAt(locScaleInformation)) {
      recommendationDescription = recommendationDescriptions.find(
        t => t.direction === direction && t.type === type
      );
    } else {
      recommendationDescription = this.getRecommendationDescriptionsForScale(
        direction,
        locScaleInformation
      );

      recommendationDescription.direction = direction;
      recommendationDescription.isAutoChange =
        recommendationDescription.current !==
        recommendationDescription.recommendation;

      if (
        direction === RECOMMENDATION_DIRECTION.KEEP &&
        recommendationDescription.isAutoChange
      ) {
        recommendationDescription =
          this.getRecommendationDescriptionsForScaleSimple(locScaleInformation);

        if (!recommendationDescription) {
          recommendationDescription = recommendationDescriptions.find(
            t => t.direction === direction && t.type === type
          );
        } else {
          recommendationDescription.direction = direction;
        }

        recommendationDescription.isAutoChange =
          recommendationDescription.current !==
          recommendationDescription.recommendation;
        recommendationDescription.isAuto =
          recommendationDescription.current &&
          recommendationDescription.recommendation !== 'MANUAL';
      } else {
        recommendationDescription.isAuto =
          locScaleInformation?.recommendation !== 'MANUAL' ||
          locScaleInformation?.current !== 'MANUAL';
      }
      recommendationDescription.isCurrentAuto =
        recommendationDescription.current &&
        recommendationDescription.current !== 'MANUAL';
    }

    return recommendationDescription;
  }

  private static getRecommendationDescriptionsForScaleSimple(
    locScaleInformation: LocScaleInformation | undefined
  ): RecommendationDescription {
    return recommendationDescriptionsForScale.find(
      t =>
        t.recommendation === locScaleInformation?.current &&
        t.current === locScaleInformation?.current &&
        t.scale80allowed ===
          locScaleInformation?.allowed.some(
            logScaleCoverage => logScaleCoverage === 'LOC_80'
          )
    );
  }

  private static noAt(
    locScaleInformation: LocScaleInformation | undefined
  ): boolean {
    const locScaleInformationUndefined =
      locScaleInformation?.current === 'UNDEFINED' &&
      locScaleInformation?.recommendation === 'UNDEFINED';
    const locScaleInformationManualToManual =
      locScaleInformation?.current === 'MANUAL' &&
      locScaleInformation?.recommendation === 'MANUAL';
    return (
      !locScaleInformation ||
      locScaleInformationUndefined ||
      locScaleInformationManualToManual
    );
  }

  private static getRecommendationDescriptionsForScale(
    direction: RECOMMENDATION_DIRECTION,
    locScaleInformation: LocScaleInformation | undefined
  ): RecommendationDescription {
    const useDirectionCheck = locScaleInformation?.recommendation === 'MANUAL';
    return recommendationDescriptionsForScale.find(t => {
      let directionOk = true;
      if (useDirectionCheck) {
        directionOk = t.direction === direction;
      }
      const scale80allowed = locScaleInformation?.allowed.some(
        logScaleCoverage => logScaleCoverage === 'LOC_80'
      );
      return (
        t.recommendation === locScaleInformation?.recommendation &&
        t.current === locScaleInformation?.current &&
        t.scale80allowed === scale80allowed &&
        directionOk
      );
    });
  }

  isShortFlowRunning(): boolean {
    return this.advisorShortFlow;
  }

  markFlowAsShort(shortFlowFlag: boolean) {
    this.advisorShortFlow = shortFlowFlag;
  }

  clearShortFlowState() {
    this.advisorShortFlow = false;
  }

  trackStart(isOnboarding: boolean) {
    this.guideTrackingService.trackGuideStart(
      'insurance guide',
      'ISG introduction',
      GuideStepType.MANDATORY,
      isOnboarding ? GuidePlacement.ONBOARDING : GuidePlacement.INSURANCE
    );
  }

  trackStep(currentQuestion: InsuranceGuideQuestion, isOnboarding: boolean) {
    this.guideTrackingService.trackGuideStep(
      'insurance guide',
      'ISG ' + QuestionTrackingMap.get(currentQuestion),
      InsuranceGuideService.checkStepType(currentQuestion),
      undefined,
      isOnboarding ? GuidePlacement.ONBOARDING : GuidePlacement.INSURANCE
    );
  }

  trackRecommendation(
    guideTrackingRecommendations: GuideTrackingRecommendation[],
    allRecommendationsViewed?: boolean
  ) {
    this.guideTrackingService.trackGuideRecommendationPartial(
      'insurance guide',
      guideTrackingRecommendations,
      allRecommendationsViewed
    );
  }

  trackExit(
    currentQuestion: InsuranceGuideQuestion,
    guideExitAction: GuideExitAction,
    isOnboarding: boolean
  ) {
    this.guideTrackingService.trackGuideExit(
      'insurance guide',
      'ISG ' + QuestionTrackingMap.get(currentQuestion),
      InsuranceGuideService.checkStepType(currentQuestion),
      guideExitAction,
      isOnboarding ? GuidePlacement.ONBOARDING : GuidePlacement.INSURANCE
    );
  }

  getRecommendationLoc(
    pensionInfo: PensionsInfoOrdninger,
    locScaleInformation: LocScaleInformation
  ): RECOMMENDATION_DIRECTION {
    const changeInvolvingATRecommended =
      (locScaleInformation.recommendation === 'MANUAL' &&
        locScaleInformation.current !== 'MANUAL') ||
      (locScaleInformation.recommendation !== 'MANUAL' &&
        locScaleInformation.current === 'MANUAL');

    const shouldKeep = InsuranceGuideService.calculateShouldKeepRecommendation(
      pensionInfo.forsikring.helbredsTalBrutto,
      pensionInfo.forsikring.pfaAnbefalerHelbred.procent,
      InsuranceUtilService.HEALTH_VARIATION
    );

    if (!changeInvolvingATRecommended && shouldKeep) {
      return RECOMMENDATION_DIRECTION.KEEP;
    }

    return pensionInfo.forsikring.helbredsTalBrutto -
      pensionInfo.forsikring.pfaAnbefalerHelbred.procent <
      0
      ? RECOMMENDATION_DIRECTION.INCREASE
      : RECOMMENDATION_DIRECTION.DECREASE;
  }

  getRecommendationTrackingTextLoc(
    pensionInfo: PensionsInfoOrdninger,
    recommendationDescription: RecommendationDescription,
    locScaleInformation: LocScaleInformation
  ): string {
    return InsuranceGuideService.getRecommendationTrackingText(
      this.getRecommendationLoc(pensionInfo, locScaleInformation),
      'pfaoccupationalcapacity',
      recommendationDescription
    );
  }

  getRecommendationLife(
    pensionInfo: PensionsInfoOrdninger
  ): RECOMMENDATION_DIRECTION {
    const shouldKeep = InsuranceGuideService.calculateShouldKeepRecommendation(
      pensionInfo.forsikring.livsTal,
      pensionInfo.forsikring.pfaAnbefalerLivs.procent,
      InsuranceUtilService.LIFE_VARIATION
    );

    if (shouldKeep) {
      return RECOMMENDATION_DIRECTION.KEEP;
    }

    return pensionInfo.forsikring.livsTal -
      pensionInfo.forsikring.pfaAnbefalerLivs.procent <
      0
      ? RECOMMENDATION_DIRECTION.INCREASE
      : RECOMMENDATION_DIRECTION.DECREASE;
  }

  getRecommendationTrackingTextLife(
    pensionInfo: PensionsInfoOrdninger
  ): string {
    return InsuranceGuideService.getRecommendationTrackingText(
      this.getRecommendationLife(pensionInfo),
      'pfalife'
    );
  }

  getRecommendationCi(
    insuranceDetails: ForsikringDetaljer
  ): RECOMMENDATION_DIRECTION {
    const actual =
      insuranceDetails.originalTilpasForsikringstal
        .daekningAendringKritiskSygdomSelv.bruttobeloeb +
      insuranceDetails.originalTilpasForsikringstal
        .daekningAendringKritiskSygdomSelv.externalCoverageAmount;
    const diff = actual - insuranceDetails.pfaAnbefalerKS.value;

    const bagatelleLimit = 40_000;
    if (Math.abs(diff) < bagatelleLimit) {
      return RECOMMENDATION_DIRECTION.KEEP;
    }

    return diff < 0
      ? RECOMMENDATION_DIRECTION.INCREASE
      : RECOMMENDATION_DIRECTION.DECREASE;
  }

  getRecommendationTrackingTextCi(
    insuranceDetails: ForsikringDetaljer
  ): string {
    return InsuranceGuideService.getRecommendationTrackingText(
      this.getRecommendationCi(insuranceDetails),
      'pfacriticalillness'
    );
  }

  shouldShowRiskLocQuestion(
    financialSituation: OekonomiDetaljer,
    insuranceDetails: ForsikringDetaljer
  ): boolean {
    return (
      insuranceDetails?.showRiskLocStep &&
      financialSituation.taeNytte === 'HOEJ'
    );
  }
}
