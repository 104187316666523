import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';

@Component({
  selector: 'co-threadlist',
  templateUrl: './threadlist.component.html',
  styleUrls: ['./threadlist.component.scss']
})
export class ThreadlistComponent implements OnInit {
  @Input() threads: any[];
  @Input() globalId: string;
  @Input() isRaadgiver: boolean;
  @Output() openThread = new EventEmitter<any>();
  @Output() closeThread = new EventEmitter<any>();
  @Input() threadOpen: boolean;
  @Input() hasUrgent: boolean;
  @Input() hasHighImportance: boolean;
  @Input() searched: boolean;

  filterText: string;
  priorityClassRed: boolean;

  ngOnInit() {
    if (this.hasUrgent || this.hasHighImportance) {
      if (this.globalId) {
        const lastDigit = parseInt(
          this.globalId.slice(this.globalId.length - 1),
          10
        );
        const isEven = lastDigit % 2 === 0;
        this.priorityClassRed = isEven;
      }
    }
  }

  public delegateOpenThread(event) {
    this.openThread.emit(event);
  }
}
