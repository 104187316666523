<div class="wrapper">
  <div class="text--container spacing-unit--double-under">
    <p
      class="text--manchet header"
      coContent="DL.VK01.C13"
      [coContentSubst]="carousels.length"
    ></p>
  </div>
  <div class="container">
    <div class="row center">
      <div
        class="introduction-cards col-6 col-md-6 col-lg-6 col-xl-3"
        *ngFor="let carousel of carousels"
      >
        <mitpfa-onboarding-introduction-card
          [ngClass]="{
            'introduction-cards-spacing':
              carousel.number === 3 || carousel.number === 4
          }"
          [image]="carousel.image"
          [number]="carousel.number"
          [headerText]="carousel.headerText"
          [bodyText]="carousel.bodyText"
        >
        </mitpfa-onboarding-introduction-card>
      </div>
    </div>
  </div>
  <co-carousel
    class="onboarding"
    [autoplay]="false"
    [slideScale]="true"
    [centerItems]="true"
    [slidePercentageWidth]="95"
  >
    <ng-container *ngFor="let carousel of carousels">
      <ng-container *pfaCarouselItem>
        <mitpfa-onboarding-introduction-card
          [image]="carousel.image"
          [number]="carousel.number"
          [headerText]="carousel.headerText"
          [bodyText]="carousel.bodyText"
        >
        </mitpfa-onboarding-introduction-card>
      </ng-container>
    </ng-container>
  </co-carousel>
  <div class="button-container spacing-unit--double-under">
    <button
      mat-raised-button
      class="mat-focus-indicator button-secondary mat-raised-button mat-button-bas spacing-unit--over"
      (click)="next()"
      [coContent]="'DL.VK01.C22'"
    ></button>
  </div>
</div>
