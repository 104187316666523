/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SammesaetningVaegtObject } from './sammesaetningVaegtObject';
import { SammesaetningVaegtObjectSchema } from './sammesaetningVaegtObject';
import * as JoiObj from "joi";


    export const SammeSaetningSchema = JoiObj.object({
        hoejrisiko:
                JoiObj.array().items(
        
        JoiObj.link('#SammesaetningVaegtObjectSchema')

        )
        

.required()


        ,
        lavrisiko:
                JoiObj.array().items(
        
        JoiObj.link('#SammesaetningVaegtObjectSchema')

        )
        

.required()


        ,
        varighedsfond:
                JoiObj.array().items(
        
        JoiObj.link('#SammesaetningVaegtObjectSchema')

        )
        

.required()


        
    })
                .shared(SammesaetningVaegtObjectSchema)
                .shared(SammesaetningVaegtObjectSchema)
                .shared(SammesaetningVaegtObjectSchema)
.id('SammeSaetningSchema')

export interface SammeSaetning { 
    hoejrisiko: Array<SammesaetningVaegtObject>;
    lavrisiko: Array<SammesaetningVaegtObject>;
    varighedsfond: Array<SammesaetningVaegtObject>;
}

