/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Samtykke } from './samtykke';
import { SamtykkeSchema } from './samtykke';
import * as JoiObj from "joi";


    export const ESkatSamtykkeSchema = JoiObj.object({
        completed:
            
        JoiObj.link('#SamtykkeSchema')


.required()


        ,
        requested:
            
        JoiObj.link('#SamtykkeSchema')


.required()


        
    })
                .shared(SamtykkeSchema)
                .shared(SamtykkeSchema)
.id('ESkatSamtykkeSchema')

export interface ESkatSamtykke { 
    completed: Samtykke;
    requested: Samtykke;
}

