/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DigitalSalgPrisListeRaekke } from './digitalSalgPrisListeRaekke';
import { DigitalSalgPrisListeRaekkeSchema } from './digitalSalgPrisListeRaekke';
import { DigitalSalgServicePrisRaekke } from './digitalSalgServicePrisRaekke';
import { DigitalSalgServicePrisRaekkeSchema } from './digitalSalgServicePrisRaekke';
import * as JoiObj from "joi";


    export const DigitalSalgPrisListeSchema = JoiObj.object({
        diagnose:
                JoiObj.array().items(
        
        JoiObj.link('#DigitalSalgPrisListeRaekkeSchema')

        )
        

.required()


        ,
        earlycare:
                JoiObj.array().items(
        
        JoiObj.link('#DigitalSalgPrisListeRaekkeSchema')

        )
        

.required()


        ,
        forebygger:
                JoiObj.array().items(
        
        JoiObj.link('#DigitalSalgPrisListeRaekkeSchema')

        )
        

.required()


        ,
        hs:
                JoiObj.array().items(
        
        JoiObj.link('#DigitalSalgPrisListeRaekkeSchema')

        )
        

.required()


        ,
        hsBoerne:
                JoiObj.array().items(
        
        JoiObj.link('#DigitalSalgPrisListeRaekkeSchema')

        )
        

.required()


        ,
        indbetalingssikring:
                JoiObj.array().items(
        
        JoiObj.link('#DigitalSalgPrisListeRaekkeSchema')

        )
        

.required()


        ,
        ks:
                JoiObj.array().items(
        
        JoiObj.link('#DigitalSalgPrisListeRaekkeSchema')

        )
        

.required()


        ,
        ksBoerne:
                JoiObj.array().items(
        
        JoiObj.link('#DigitalSalgPrisListeRaekkeSchema')

        )
        

.required()


        ,
        liv:
                JoiObj.array().items(
        
        JoiObj.link('#DigitalSalgPrisListeRaekkeSchema')

        )
        

.required()


        ,
        livBoernePension:
                JoiObj.array().items(
        
        JoiObj.link('#DigitalSalgPrisListeRaekkeSchema')

        )
        

.required()


        ,
        livEngang:
                JoiObj.array().items(
        
        JoiObj.link('#DigitalSalgPrisListeRaekkeSchema')

        )
        

.required()


        ,
        livEngangHskode_4_5:
                JoiObj.array().items(
        
        JoiObj.link('#DigitalSalgPrisListeRaekkeSchema')

        )
        

.required()


        ,
        ordningDatoStreng:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        resourceforloeb:
                JoiObj.array().items(
        
        JoiObj.link('#DigitalSalgPrisListeRaekkeSchema')

        )
        

.required()


        ,
        serviceOgRaadgivning:
                JoiObj.array().items(
        
        JoiObj.link('#DigitalSalgServicePrisRaekkeSchema')

        )
        

.required()


        ,
        sundhedslinje:
                JoiObj.array().items(
        
        JoiObj.link('#DigitalSalgPrisListeRaekkeSchema')

        )
        

.required()


        ,
        tae:
                JoiObj.array().items(
        
        JoiObj.link('#DigitalSalgPrisListeRaekkeSchema')

        )
        

.required()


        ,
        taeBoerne:
                JoiObj.array().items(
        
        JoiObj.link('#DigitalSalgPrisListeRaekkeSchema')

        )
        

.required()


        ,
        taeEngang:
                JoiObj.array().items(
        
        JoiObj.link('#DigitalSalgPrisListeRaekkeSchema')

        )
        

.required()


        
    })
                .shared(DigitalSalgPrisListeRaekkeSchema)
                .shared(DigitalSalgPrisListeRaekkeSchema)
                .shared(DigitalSalgPrisListeRaekkeSchema)
                .shared(DigitalSalgPrisListeRaekkeSchema)
                .shared(DigitalSalgPrisListeRaekkeSchema)
                .shared(DigitalSalgPrisListeRaekkeSchema)
                .shared(DigitalSalgPrisListeRaekkeSchema)
                .shared(DigitalSalgPrisListeRaekkeSchema)
                .shared(DigitalSalgPrisListeRaekkeSchema)
                .shared(DigitalSalgPrisListeRaekkeSchema)
                .shared(DigitalSalgPrisListeRaekkeSchema)
                .shared(DigitalSalgPrisListeRaekkeSchema)
                .shared(DigitalSalgPrisListeRaekkeSchema)
                .shared(DigitalSalgServicePrisRaekkeSchema)
                .shared(DigitalSalgPrisListeRaekkeSchema)
                .shared(DigitalSalgPrisListeRaekkeSchema)
                .shared(DigitalSalgPrisListeRaekkeSchema)
                .shared(DigitalSalgPrisListeRaekkeSchema)
.id('DigitalSalgPrisListeSchema')

export interface DigitalSalgPrisListe { 
    diagnose: Array<DigitalSalgPrisListeRaekke>;
    earlycare: Array<DigitalSalgPrisListeRaekke>;
    forebygger: Array<DigitalSalgPrisListeRaekke>;
    hs: Array<DigitalSalgPrisListeRaekke>;
    hsBoerne: Array<DigitalSalgPrisListeRaekke>;
    indbetalingssikring: Array<DigitalSalgPrisListeRaekke>;
    ks: Array<DigitalSalgPrisListeRaekke>;
    ksBoerne: Array<DigitalSalgPrisListeRaekke>;
    liv: Array<DigitalSalgPrisListeRaekke>;
    livBoernePension: Array<DigitalSalgPrisListeRaekke>;
    livEngang: Array<DigitalSalgPrisListeRaekke>;
    livEngangHskode_4_5: Array<DigitalSalgPrisListeRaekke>;
    ordningDatoStreng: string;
    resourceforloeb: Array<DigitalSalgPrisListeRaekke>;
    serviceOgRaadgivning: Array<DigitalSalgServicePrisRaekke>;
    sundhedslinje: Array<DigitalSalgPrisListeRaekke>;
    tae: Array<DigitalSalgPrisListeRaekke>;
    taeBoerne: Array<DigitalSalgPrisListeRaekke>;
    taeEngang: Array<DigitalSalgPrisListeRaekke>;
}

