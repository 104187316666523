<svg
  id="Layer_1"
  data-name="Layer 1"
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 48 44"
>
  <g id="Coin-2">
    <circle cx="10.55" cy="33.7" r="4.91" fill="#900" stroke-width="0" />
  </g>
  <path
    d="m10.55,39.03c-2.94,0-5.34-2.39-5.34-5.34s2.39-5.34,5.34-5.34,5.34,2.39,5.34,5.34-2.39,5.34-5.34,5.34Zm0-9.82c-2.47,0-4.48,2.01-4.48,4.48s2.01,4.48,4.48,4.48,4.48-2.01,4.48-4.48-2.01-4.48-4.48-4.48Z"
    fill="#d69999"
    stroke-width="0"
  />
  <path
    d="m8.63,32.51v.95l1.08-1.13c.05-.05.1-.09.13-.12.04-.03.08-.04.15-.04.08,0,.14.02.19.07.05.05.07.1.07.17,0,.08-.04.16-.12.24l-.67.63.77,1.1c.05.08.1.14.12.2.03.06.04.11.04.17,0,.06-.02.11-.07.16s-.11.07-.19.07c-.08,0-.14-.02-.19-.05s-.09-.08-.12-.13c-.03-.05-.06-.1-.09-.15l-.65-1-.45.43v.56c0,.11-.03.2-.08.25-.05.05-.12.08-.2.08-.05,0-.09-.01-.14-.04-.04-.02-.08-.06-.1-.1-.02-.03-.03-.07-.03-.12,0-.05,0-.11,0-.2v-2.02c0-.11.02-.19.07-.25.05-.06.11-.08.2-.08s.15.03.2.08c.05.05.08.14.08.25Z"
    fill="#d69999"
    stroke-width="0"
  />
  <path
    d="m11.57,33.77h-.19v.87c0,.11-.03.2-.08.25s-.12.08-.2.08c-.09,0-.16-.03-.2-.09s-.07-.14-.07-.25v-2.09c0-.12.03-.2.08-.26s.14-.08.26-.08h.89c.12,0,.23,0,.32.02s.17.03.24.06c.09.04.16.09.23.15s.12.14.15.23c.03.09.05.18.05.28,0,.2-.06.36-.17.49-.11.12-.29.21-.52.26.1.05.19.13.28.23s.17.21.24.32c.07.11.12.22.16.31.04.09.06.16.06.19s-.01.07-.03.11c-.02.04-.05.06-.09.08-.04.02-.09.03-.14.03-.06,0-.11-.01-.16-.04-.04-.03-.08-.07-.11-.11s-.07-.11-.12-.2l-.22-.37c-.08-.13-.15-.24-.21-.31-.06-.07-.13-.12-.19-.14-.06-.03-.14-.04-.24-.04Zm.32-1.14h-.51v.75h.49c.13,0,.24-.01.33-.03s.16-.06.21-.12c.05-.06.07-.13.07-.23,0-.08-.02-.14-.06-.2-.04-.06-.09-.1-.16-.13-.06-.03-.19-.04-.38-.04Z"
    fill="#d69999"
    stroke-width="0"
  />
  <path
    d="m29.4,12.63l2.41-4.24c.47-.83-.27-1.83-1.2-1.62l-1.11.25c-.14.03-.29.04-.43.01l-4.53-.78c-.12-.02-.25-.02-.37,0l-4.53.78c-.14.02-.29.02-.43-.01l-1.11-.25c-.93-.21-1.67.79-1.2,1.62l2.41,4.24c.38.67-.03,1.52-.79,1.63-6.21.89-5.98,6.81-5.96,7.19,0,.02,0,.03,0,.04v16.64c0,.61.49,1.1,1.1,1.1h21.39c.61,0,1.1-.49,1.1-1.1v-16.64s0-.03,0-.04c.02-.38.25-6.3-5.96-7.19-.76-.11-1.17-.96-.79-1.63Z"
    fill="#c8c8c8"
    stroke-width="0"
  />
  <g opacity=".2">
    <path
      d="m24.35,39.23h10.69c.61,0,1.1-.49,1.1-1.1v-16.64s0-.03,0-.04c.02-.38.25-6.3-5.96-7.19-.76-.11-1.17-.96-.79-1.63l2.41-4.24c.47-.83-.27-1.83-1.2-1.62l-1.11.25c-.14.03-.29.04-.43.01l-2.17-.37-2.67,6.7.12,25.87Z"
      stroke-width="0"
    />
  </g>
  <rect
    x="18.22"
    y="12.34"
    width="12.27"
    height="1.92"
    rx=".96"
    ry=".96"
    fill="#333"
    stroke-width="0"
  />
  <path
    d="m24.46,21.64c-2.74,0-4.96,2.22-4.96,4.96s2.22,4.96,4.96,4.96,4.96-2.22,4.96-4.96-2.22-4.96-4.96-4.96Z"
    fill="#900"
    stroke-width="0"
  />
  <path
    d="m22.12,25.32v1.16l1.32-1.37c.06-.07.12-.12.16-.15.04-.03.1-.05.18-.05.1,0,.17.03.23.08.06.06.09.13.09.21,0,.1-.05.19-.15.29l-.81.77.94,1.35c.07.09.12.17.15.24.03.07.05.14.05.2,0,.07-.03.14-.09.19-.06.05-.14.08-.24.08-.09,0-.17-.02-.23-.06-.06-.04-.11-.09-.15-.16-.04-.07-.08-.13-.11-.18l-.79-1.22-.55.53v.68c0,.14-.03.24-.09.31-.06.07-.14.1-.24.1-.06,0-.11-.02-.16-.05-.05-.03-.09-.07-.12-.12-.02-.04-.03-.09-.04-.15,0-.06,0-.14,0-.24v-2.46c0-.14.03-.24.09-.3.06-.07.14-.1.24-.1s.18.03.24.1c.06.07.09.17.09.31Z"
    fill="#fff"
    stroke-width="0"
  />
  <path
    d="m25.7,26.87h-.23v1.06c0,.14-.03.24-.09.31-.06.07-.14.1-.24.1-.11,0-.19-.03-.25-.1-.06-.07-.09-.17-.09-.3v-2.55c0-.14.03-.25.1-.31s.17-.1.31-.1h1.09c.15,0,.28,0,.39.02.11.01.2.04.29.08.1.04.2.11.28.19s.14.18.18.28c.04.11.06.22.06.34,0,.25-.07.44-.21.59-.14.15-.35.25-.63.31.12.06.23.16.34.28.11.12.2.25.29.39.08.14.15.26.2.38.05.11.07.19.07.23s-.01.09-.04.13c-.03.04-.07.08-.11.1s-.1.04-.17.04c-.08,0-.14-.02-.19-.05-.05-.04-.1-.08-.13-.13-.04-.05-.09-.13-.15-.24l-.27-.45c-.1-.16-.18-.29-.26-.37-.08-.09-.15-.14-.23-.18s-.18-.05-.3-.05Zm.38-1.39h-.62v.91h.6c.16,0,.3-.01.41-.04.11-.03.19-.08.25-.14.06-.07.09-.16.09-.28,0-.09-.02-.17-.07-.24s-.11-.12-.19-.16c-.08-.03-.23-.05-.46-.05Z"
    fill="#fff"
    stroke-width="0"
  />
  <rect
    x="19.63"
    y="12.29"
    width="12.3"
    height="1.92"
    rx=".96"
    ry=".96"
    fill="#333"
    stroke-width="0"
  />
  <path
    d="m34.33,35.12c-.52,0-.95.36-.95.8v.13c0,.44.43.8.95.8h6.53v-1.73h-6.53Z"
    fill="#900"
    stroke-width="0"
  />
  <path
    d="m34.33,37.51c-.52,0-.95.36-.95.8v.13c0,.44.43.8.95.8h6.53v-1.73h-6.53Z"
    fill="#900"
    stroke-width="0"
  />
  <path
    d="m42.99,35.12h-2.13v1.73h2.13c.52,0,.95-.36.95-.8v-.13c0-.44-.43-.8-.95-.8Z"
    fill="#d69999"
    stroke-width="0"
  />
  <path
    d="m42.99,37.51h-2.13v1.73h2.13c.52,0,.95-.36.95-.8v-.13c0-.44-.43-.8-.95-.8Z"
    fill="#d69999"
    stroke-width="0"
  />
  <path
    d="m34.33,30.28c-.52,0-.95.36-.95.8v.13c0,.44.43.8.95.8h6.53v-1.73h-6.53Z"
    fill="#900"
    stroke-width="0"
  />
  <path
    d="m34.33,32.66c-.52,0-.95.36-.95.8v.13c0,.44.43.8.95.8h6.53v-1.73h-6.53Z"
    fill="#900"
    stroke-width="0"
  />
  <path
    d="m42.99,30.28h-2.13v1.73h2.13c.52,0,.95-.36.95-.8v-.13c0-.44-.43-.8-.95-.8Z"
    fill="#d69999"
    stroke-width="0"
  />
  <path
    d="m42.99,32.66h-2.13v1.73h2.13c.52,0,.95-.36.95-.8v-.13c0-.44-.43-.8-.95-.8Z"
    fill="#d69999"
    stroke-width="0"
  />
</svg>
