/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const ProductSchema = JoiObj.string().valid(
        'KR_CASES'
        ,
        'LETPENSION'
        ,
        'PFA_PLUS'
        
    )
.id('ProductSchema')

export type Product = 'KR_CASES' | 'LETPENSION' | 'PFA_PLUS';

export const Product = {
    KrCases: 'KR_CASES' as Product,
    Letpension: 'LETPENSION' as Product,
    PfaPlus: 'PFA_PLUS' as Product
};

