import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { InsuranceAdjustmentUpdateService } from '../insurance-adjustment-update.service';
import { takeUntil } from 'rxjs/operators';
import { ContentUtilService, NumberFormatPipe } from '@pfa/core';
import { cprValidator, Utils } from '@pfa/utils';
import { Aegtefaellepension } from '@pfa/gen';
import { NullableNumber, PensionPlanExtend } from '@pfa/models';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import {
  ConfirmChoice,
  InputConfigLife,
  InputNotification,
  INSURANCE_SCROLL_TIMERS,
  InsuranceAdjustmentInputEmitter,
  InsuranceAdjustmentLifeService,
  InsuranceAdjustmentType,
  InsuranceGuideChecks,
  ResetDataLife
} from '@mitpfa/shared';
import { InsuranceGuideService } from '@mitpfa/shared/insurance-guide/insurance-guide.service';
import {
  ConfirmChoiceComponentTexts,
  GlobalWorkingService,
  InsuranceUtilService
} from '@pfaform';
import { InsuranceAdjustmentConfirmChoiceConfigurationService } from '../insurance-adjustment-confirm-choice-configuration.service';

@Component({
  selector: 'mitpfa-insurance-adjustment-input-life',
  templateUrl: './insurance-adjustment-input-life.component.html',
  styleUrls: ['./insurance-adjustment-input-life.component.scss']
})
export class InsuranceAdjustmentInputLifeComponent
  implements OnInit, OnDestroy, OnChanges
{
  @Input() confirmChoice: ConfirmChoice;
  @Input() insuranceGuideChecks: InsuranceGuideChecks;
  @Input() pensionPlanPolice: PensionPlanExtend;
  @Input() inputConfig: InputConfigLife;
  @Input() inputNotification: InputNotification;
  @Input() lifeUpdateNote: string;
  @Input() reset: Observable<ResetDataLife>;
  @Input() disabled: boolean;
  @Input() showAuaConditions: boolean;
  @Input() hideActions: boolean;

  @Output() changes = new EventEmitter<InsuranceAdjustmentInputEmitter>();
  @Output() confirmChoiceEmitter = new EventEmitter();

  form: UntypedFormGroup;
  initialized: boolean;

  overrideRecommendationKeepCurrent: boolean;
  pricesUpdated: boolean;
  pricesChildUpdated: boolean;
  spousePensionSelected: boolean;
  spousePensionAdded: boolean;
  spousePensionRemoved: boolean;
  spousePensionOriginal: Aegtefaellepension;
  showSpousePensionExtra: boolean | undefined;
  spouseExtraIsAmount: boolean;

  unitWithAmount: string;
  unitWithAmountOriginal: string;
  unitWithRecommendation: string;

  private readonly unsubscribe = new Subject<void>();

  private readonly contentUtilService: ContentUtilService =
    inject(ContentUtilService);
  private readonly numberFormat: NumberFormatPipe = inject(NumberFormatPipe);
  private readonly globalWorkingService: GlobalWorkingService =
    inject(GlobalWorkingService);
  private readonly insuranceAdjustmentUpdateService: InsuranceAdjustmentUpdateService =
    inject(InsuranceAdjustmentUpdateService);
  private readonly insuranceAdjustmentLifeService: InsuranceAdjustmentLifeService =
    inject(InsuranceAdjustmentLifeService);
  private readonly insuranceAdjustmentConfirmChoiceConfigurationService: InsuranceAdjustmentConfirmChoiceConfigurationService =
    inject(InsuranceAdjustmentConfirmChoiceConfigurationService);

  public confirmChoiceComponentTexts: ConfirmChoiceComponentTexts =
    this.insuranceAdjustmentConfirmChoiceConfigurationService
      .confirmChoiceComponentTexts;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.inputConfig?.currentValue) {
      this.overrideRecommendation();
      this.handleUnitTexts();
      const inputConfigUpdate = changes.inputConfig
        .currentValue as InputConfigLife;
      if (
        inputConfigUpdate.self.amount !== inputConfigUpdate.self.amountOriginal
      ) {
        this.pricesUpdated = true;
      }
      if (
        inputConfigUpdate.child &&
        inputConfigUpdate.child.amount !==
          inputConfigUpdate.child.amountOriginal
      ) {
        this.pricesChildUpdated = true;
      }
    }
  }

  ngOnInit(): void {
    this.overrideRecommendation();

    this.form = new UntypedFormGroup({});
    this.form.addControl(
      'self',
      new UntypedFormControl({ disabled: this.disabled }, [
        Validators.min(this.inputConfig.self.amountMin ?? 0),
        Validators.max(this.inputConfig.self.amountMax ?? 0)
      ])
    );
    if (this.inputConfig.child) {
      this.form.addControl(
        'child',
        new UntypedFormControl(
          {
            value: this.inputConfig.child.amount,
            disabled: this.disabled
          },
          [
            Validators.min(this.inputConfig.child.amountMin ?? 0),
            Validators.max(this.inputConfig.child.amountMax ?? 0)
          ]
        )
      );
    }

    if (this.inputConfig.showSpousePension) {
      this.spousePensionOriginal = { ...this.inputConfig.spousePension };
      this.form.addControl(
        'spouseCpr',
        new UntypedFormControl(
          {
            value: this.inputConfig.spousePension?.cprnummer,
            disabled: this.disabled
          },
          [Validators.required, cprValidator()]
        )
      );
      this.form.addControl(
        'spouseExtraAmount',
        new UntypedFormControl(
          {
            value: this.inputConfig.spousePension?.yderligereBeloeb,
            disabled: this.disabled
          },
          [
            Validators.required,
            Validators.min(this.inputConfig.spousePension?.yderligereMin ?? 0),
            Validators.max(this.inputConfig.spousePension?.yderligereMax ?? 0)
          ]
        )
      );
      this.initSpousePension();
    }

    this.subscribeToReset();
    this.subscribeToFollowOurRecommendation();
    this.subscribeToInputUpdated();
    this.subscribeToTaxCodeUpdated();
    this.subscribeToCprUpdated();
    this.handleUnitTexts();
    this.initialized = true;
  }

  private overrideRecommendation() {
    this.overrideRecommendationKeepCurrent =
      InsuranceGuideService.calculateShouldKeepRecommendation(
        this.inputConfig.self.amountOriginal,
        this.inputConfig.recommendation,
        InsuranceUtilService.LIFE_VARIATION
      );
  }

  private handleUnitTexts(): void {
    this.unitWithAmount = this.getUnitText(this.inputConfig.self.amount);
    this.unitWithAmountOriginal = this.getUnitText(
      this.inputConfig.self.amountOriginal,
      true
    );
    this.unitWithRecommendation = this.getUnitText(
      this.inputConfig.recommendation
    );
  }

  private getUnitText(
    value: number | undefined,
    newLine: boolean = false
  ): string {
    const middleText = newLine ? '<br>' : ' ';
    return `${this.contentUtilService.getContent(
      'DL.TF01.C08'
    )}${middleText}(${this.numberFormat.transform(value, 0)} %)`;
  }

  private initSpousePension(): void {
    this.spousePensionSelected = this.inputConfig.spousePension?.livsvarigMuligt
      ? this.inputConfig.spousePension.livsvarigValgt
      : this.inputConfig.spousePension?.ophoerendeValgt;
    this.setShowSpousePensionExtra();
    this.spouseExtraIsAmount =
      this.inputConfig.spousePension?.yderligereType === 'Beloeb';
  }

  private setShowSpousePensionExtra() {
    this.showSpousePensionExtra =
      this.spousePensionSelected &&
      !this.inputConfig.spousePension?.cprnummerFiktivt &&
      this.inputConfig.spousePension?.yderligereBeloeb !== null;
  }

  update(
    type: InsuranceAdjustmentType,
    formControl: AbstractControl<NullableNumber, NullableNumber> | null
  ): void {
    this.globalWorkingService.show();
    this.mapChanges(type);
    this.changes.emit({
      inputConfig: this.inputConfig,
      type
    });
    formControl?.markAsPristine();
  }

  mapChanges(type: InsuranceAdjustmentType): void {
    if (type === InsuranceAdjustmentType.LIFE) {
      this.inputConfig.self.amount = this.form.value.self;
    } else if (
      type === InsuranceAdjustmentType.LIFE_CHILD &&
      this.inputConfig.child
    ) {
      this.inputConfig.child.amount = this.form.value.child;
    } else if (type === InsuranceAdjustmentType.LIFE_SPOUSE) {
      if (this.inputConfig.spousePension) {
        this.inputConfig.spousePension.ophoerendeValgt = this.inputConfig
          .spousePension.ophoerendeMuligt
          ? this.spousePensionSelected
          : this.inputConfig.spousePension.ophoerendeValgt;
        this.inputConfig.spousePension.livsvarigValgt = this.inputConfig
          .spousePension.livsvarigMuligt
          ? this.spousePensionSelected
          : this.inputConfig.spousePension.livsvarigValgt;
        this.inputConfig.spousePension.cprnummer = this.form.value.spouseCpr;
      }
    } else if (
      type === InsuranceAdjustmentType.LIFE_SPOUSE_EXTRA &&
      this.inputConfig.spousePension
    ) {
      this.inputConfig.spousePension.yderligereBeloeb =
        this.form.value.spouseExtraAmount;
    }
  }

  checkForEmpty(formControl: AbstractControl, defaultValue: number) {
    if (Utils.isEmpty(formControl.value)) {
      formControl.setValue(defaultValue);
    }
  }

  isDisabled(fieldName: string): boolean {
    return (
      this.disabled ||
      this.form.get(fieldName)?.pristine ||
      this.form.get(fieldName)?.invalid ||
      (this.form.get(fieldName)?.value ===
        this.inputConfig[fieldName].amountOriginal &&
        this.inputConfig[fieldName].amountOriginal ===
          this.inputConfig[fieldName].amount)
    );
  }

  setRecommendation(
    type: InsuranceAdjustmentType,
    formControl: AbstractControl<NullableNumber, NullableNumber> | null
  ): void {
    this.form.controls['self'].setValue(this.inputConfig.recommendation);
    this.update(type, formControl);
  }

  toggleSpousePension(
    event: MatSlideToggleChange,
    formControl: AbstractControl
  ): void {
    this.spousePensionSelected = event.checked;
    if (event.checked) {
      if (this.inputConfig?.spousePension?.cprnummer) {
        this.updateSpouseCpr(formControl);
      }
    } else {
      // remove spose pension
      if (
        (this.spousePensionOriginal.livsvarigValgt &&
          !this.spousePensionSelected) ||
        (this.spousePensionOriginal.ophoerendeValgt &&
          !this.spousePensionSelected)
      ) {
        this.updateSpouseCpr(formControl);
        this.spousePensionRemoved = true;
      }
    }
  }

  isDisabledSpouse(fieldName: string): boolean {
    return (
      (this.disabled ?? false) ||
      this.form.get(fieldName)?.pristine ||
      (this.form.get(fieldName)?.invalid ?? false)
    );
  }

  updateSpouseCpr(formControl: AbstractControl): void {
    if (
      (!this.spousePensionOriginal.livsvarigValgt &&
        this.spousePensionSelected) ||
      (!this.spousePensionOriginal.ophoerendeValgt &&
        this.spousePensionSelected)
    ) {
      this.spousePensionAdded = true;
    }
    this.update(InsuranceAdjustmentType.LIFE_SPOUSE, formControl);
  }

  updateSpouseExtraAmount(formControl: AbstractControl): void {
    this.update(InsuranceAdjustmentType.LIFE_SPOUSE_EXTRA, formControl);
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private subscribeToReset(): void {
    this.reset.pipe(takeUntil(this.unsubscribe)).subscribe(data => {
      this.form.patchValue({ self: '' });
      this.pricesUpdated = false;
      this.pricesChildUpdated = false;
      if (this.inputConfig.child && data.inputConfig.child) {
        this.form.patchValue({ child: data.inputConfig.child.amountOriginal });
      }
      if (this.inputConfig.showSpousePension) {
        this.inputConfig.spousePension = this.spousePensionOriginal;
        this.form.patchValue({
          spouseCpr: data.inputConfig?.spousePension?.cprnummer,
          spouseExtraAmount: data.inputConfig?.spousePension?.yderligereBeloeb
        });
        this.initSpousePension();
      }
      this.subscribeToFollowOurRecommendation();
    });
  }

  private subscribeToFollowOurRecommendation(): void {
    this.insuranceAdjustmentLifeService.followOurRecommendation.subscribe({
      next: () => {
        setTimeout(() => {
          this.setRecommendation(
            InsuranceAdjustmentType.LIFE,
            this.form.get('self')
          );
        }, INSURANCE_SCROLL_TIMERS.recommendationCalculationDelay);
      }
    });
  }

  private subscribeToInputUpdated(): void {
    this.insuranceAdjustmentUpdateService.lifeUpdateAnnounced$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(grossPercent => {
        this.form.patchValue({ self: grossPercent });
        if (this.form.get('self') != null) {
          this.update(this.inputConfig.self.type, this.form.get('self'));
        }
      });
  }

  private subscribeToCprUpdated(): void {
    this.insuranceAdjustmentUpdateService.lifeCprUpdateAnnounced$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(inputConfigLife => {
        this.inputConfig = inputConfigLife;
        this.setShowSpousePensionExtra();
      });
  }

  private subscribeToTaxCodeUpdated(): void {
    this.insuranceAdjustmentUpdateService.lifeTaxCodeUpdateAnnounced$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(taxCodeUpdateResponse => {
        this.form.patchValue({ self: taxCodeUpdateResponse.amount });
        this.inputConfig.self.amountMin = taxCodeUpdateResponse.amountMin;
        this.inputConfig.self.amountMax = taxCodeUpdateResponse.amountMax;
        if (taxCodeUpdateResponse.showBeforeTax) {
          this.inputConfig.self.noteBeforeTax =
            this.contentUtilService.getContent(
              'DL.TF01.C149',
              this.numberFormat.transform(taxCodeUpdateResponse.beforeTax, 0)
            );
        } else {
          this.inputConfig.self.noteBeforeTax = undefined;
        }
        this.inputConfig.self.noteAfterTax = this.contentUtilService.getContent(
          'DL.TF01.C150',
          this.numberFormat.transform(taxCodeUpdateResponse.afterTax, 0)
        );
        this.form.get('self')?.clearValidators();
        this.form
          .get('self')
          ?.setValidators([
            Validators.min(taxCodeUpdateResponse.amountMin ?? 0),
            Validators.max(taxCodeUpdateResponse.amountMax ?? 0)
          ]);
      });
  }

  onConfirmChoice() {
    this.confirmChoiceEmitter.emit();
  }
}
