/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const FPTModregningStatusSchema = JoiObj.string().valid(
        'UNDER_LAV_GRAENSE'
        ,
        'INDEN_GRAENSE'
        ,
        'OVER_HOEJ_GRAENSE'
        
    )
.id('FPTModregningStatusSchema')

export type FPTModregningStatus = 'UNDER_LAV_GRAENSE' | 'INDEN_GRAENSE' | 'OVER_HOEJ_GRAENSE';

export const FPTModregningStatus = {
    UnderLavGraense: 'UNDER_LAV_GRAENSE' as FPTModregningStatus,
    IndenGraense: 'INDEN_GRAENSE' as FPTModregningStatus,
    OverHoejGraense: 'OVER_HOEJ_GRAENSE' as FPTModregningStatus
};

