/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BeneficiaryRelation } from './beneficiaryRelation';
import { BeneficiaryRelationSchema } from './beneficiaryRelation';
import * as JoiObj from "joi";


    export const BeneficiaryPersonViewSchema = JoiObj.object({
        cpr:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        equalShare:
            
                    JoiObj.boolean()
                


.required()


        ,
        firstName:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        name:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        relation:
            
        JoiObj.link('#BeneficiaryRelationSchema')


.required()


        ,
        sharePercent:
            
                    JoiObj.number()
                

.allow(null)



        ,
        surName:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        
    })
                .shared(BeneficiaryRelationSchema)
.id('BeneficiaryPersonViewSchema')

export interface BeneficiaryPersonView { 
    /**
     * This field is required in our frontend, but NOT in the system where beneficiary data is kept. For that reason this field is not marked as required.
     */
    cpr?: string;
    equalShare: boolean;
    firstName: string;
    name: string;
    relation: BeneficiaryRelation;
    sharePercent?: number;
    surName: string;
}
export namespace BeneficiaryPersonView {
}


