<div
  class="recommend-concept text--note text--bold"
  coContent="DL.INV.C661"
></div>

<div class="recommend-profile">
  <co-primary-number
    [amount]="
      !recommendation
        ? detail.current.climatePercent
        : detail.recommendation.climatePercent
    "
    [unit]="'%'"
    class="size-sm"
  ></co-primary-number>
</div>

<div class="recommend-risk text--mark">
  <span [coContent]="!recommendation ? 'DL.INV.C662' : 'DL.INV.C663'"></span>
  <div *ngIf="recommendation && !detail.current.onlyYouInvest">
    <a class="link" coContent="DL.INV.C329" (click)="openReadMore()"></a>
  </div>
</div>

<co-alert
  *ngIf="detail.current.onlyYouInvest"
  class="alert-info alert-inline alert-center spacing-unit--over"
>
  <span coContent="DL.INV.C702"></span>
</co-alert>
