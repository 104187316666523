/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ServiceInfo } from './serviceInfo';
import { ServiceInfoSchema } from './serviceInfo';
import { AfkastLinie } from './afkastLinie';
import { AfkastLinieSchema } from './afkastLinie';
import { PfaInvestererAfkast } from './pfaInvestererAfkast';
import { PfaInvestererAfkastSchema } from './pfaInvestererAfkast';
import { ResourceModel } from './resourceModel';
import { ResourceModelSchema } from './resourceModel';
import * as JoiObj from "joi";


    export const AfkastDetaljerSchema = JoiObj.object({
        serviceInfo:
            
        JoiObj.link('#ServiceInfoSchema')

.allow(null)



        ,
        afkastLinier:
                JoiObj.array().items(
        
        JoiObj.link('#AfkastLinieSchema')

        )
        

.required()


        ,
        detaljerSlutDato:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        detaljerStartDato:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        fejlet:
            
                    JoiObj.boolean()
                


.required()


        ,
        kundekapitalModel:
            
                    JoiObj.number()
                .integer()

.allow(null)



        ,
        opsparingsDato:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        pfaInvesterer:
            
        JoiObj.link('#PfaInvestererAfkastSchema')

.allow(null)



        ,
        tegningsDato:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        totalAfkastForAar:
            
                    JoiObj.number()
                

.allow(null)



        ,
        totalAfkastProcentForAar:
            
                    JoiObj.number()
                

.allow(null)



        ,
        udbetalingDoedId:
                JoiObj.array().items(
        
                    JoiObj.number()
                .integer()

        )
        

.required()


        
    })
                .shared(ServiceInfoSchema)
                .shared(AfkastLinieSchema)
                .shared(PfaInvestererAfkastSchema)
.id('AfkastDetaljerSchema')

export interface AfkastDetaljer extends ResourceModel { 
    afkastLinier: Array<AfkastLinie>;
    detaljerSlutDato?: string;
    detaljerStartDato?: string;
    fejlet: boolean;
    kundekapitalModel?: number;
    opsparingsDato?: string;
    pfaInvesterer?: PfaInvestererAfkast;
    tegningsDato?: string;
    totalAfkastForAar?: number;
    totalAfkastProcentForAar?: number;
    udbetalingDoedId: Array<number>;
}

