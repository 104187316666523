import { CanActivate, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { OverfoerselStore } from '@pfa/gen';

@Injectable()
export class CombineInternalGuard implements CanActivate {
  constructor(
    private readonly overfoerselStore: OverfoerselStore,
    private readonly router: Router
  ) {}

  canActivate(): Observable<boolean | UrlTree> | boolean {
    return this.overfoerselStore
      .overfoerselCheckVisibilityGet()
      .pipe(
        map(response => (response.visible ? true : this.router.parseUrl('/')))
      );
  }
}
