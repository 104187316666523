import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
  OnInit
} from '@angular/core';
import { GuideExitAction } from '@pfa/core';
import { WarningComponent } from '../warning/warning.component';
import { OnboardingService } from '../onboarding.service';
import { OnboardingTrackingService } from '../onboarding-tracking.service';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Step } from '@pfaform';
import { Observable } from 'rxjs';
import { OnboardingLevel } from '../onboarding.model';
import { map } from 'rxjs/operators';

@Component({
  selector: 'mitpfa-onboarding-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent implements OnInit {
  @Input() levelReached: OnboardingLevel;
  public currentStepIndex = 0;
  public stepReached$: Observable<Step[]>;

  private readonly onboardingService: OnboardingService =
    inject(OnboardingService);
  private readonly onboardingTrackingService: OnboardingTrackingService =
    inject(OnboardingTrackingService);
  private readonly dialog: MatDialog = inject(MatDialog);
  private readonly router: Router = inject(Router);

  public ngOnInit(): void {
    this.stepReached$ = this.onboardingService.getStepReached().pipe(
      map((steps: Step[]) => {
        this.currentStepIndex = steps.findIndex(
          x => x.id === this.levelReached.stepperLevel
        );
        return steps;
      })
    );
  }

  public closeOnboarding(): void {
    this.onboardingTrackingService.trackFlowExit(
      this.levelReached,
      GuideExitAction.INITIATED
    );
    this.dialog
      .open(WarningComponent)
      .afterClosed()
      .subscribe((accept: boolean) => {
        if (accept) {
          this.onboardingTrackingService.trackFlowExit(
            this.levelReached,
            GuideExitAction.COMPLETED
          );
          this.router.navigateByUrl('/mitoverblik');
        } else {
          this.onboardingTrackingService.trackFlowExit(
            this.levelReached,
            GuideExitAction.CANCELLED
          );
        }
      });
  }
}
