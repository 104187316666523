<div
  class="card"
  [ngClass]="{
    flat: flat,
    'not-interactive': notInteractive,
    'card-red': cardRed,
    'card-green': cardGreen,
    'rounded-corners': rounded,
    'allow-overflow': allowOverflow
  }"
>
  <div
    *ngIf="alertColor"
    class="alert--color"
    [ngClass]="{
      'alert--color-top': alertPosition === 'TOP',
      ok: alertColor === AlertColor.GREEN,
      warning: alertColor === AlertColor.YELLOW,
      error: alertColor === AlertColor.RED,
      neutral: alertColor === AlertColor.GREY
    }"
  ></div>

  <div class="card-content">
    <div
      [@collapse]="collapseState"
      [@opacity]="opacityState"
      [style.minHeight]="minHeight"
      class="content-container"
      [ngClass]="{ 'content-container--with-error': showErrorMessage }"
      #content
    >
      <ng-content></ng-content>
      <div *ngIf="showErrorMessage && !hideErrorBlocks">
        <span class="error double"></span>
        <span class="error single"></span>
        <span class="error single"></span>
      </div>
    </div>
  </div>

  <ng-content select="[card-additional]"></ng-content>
</div>
