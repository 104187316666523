import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy
} from '@angular/core';
import { OekonomiDetaljer, PensionsKundeGenerelleData } from '@pfa/gen';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators
} from '@angular/forms';
import { Options } from 'ngx-slider-v2';
import { numberAsStringValidator } from '@pfa/utils';
import { generateSliderStepsArray } from '@pfaform';
import { FinancialSituationConfigurationService } from '../services/financial-situation-configuration.service';
import { MatRadioChange } from '@angular/material/radio';

@Component({
  selector: 'mitpfa-financial-situation-debt',
  templateUrl: './financial-situation-debt.component.html',
  styleUrls: ['./financial-situation-debt.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FinancialSituationDebtComponent implements OnInit {
  @Input() financialSituation: OekonomiDetaljer;
  @Input() pensionCustomer: PensionsKundeGenerelleData;
  @Input() form: UntypedFormGroup;
  @Input() withOlderCustomerSection = true;
  @Input() isInInsuranceGuide = false;

  public debtForm: UntypedFormGroup;
  public sliderOptions: Options = {
    stepsArray: [
      ...generateSliderStepsArray(0, 300000, 10000),
      ...generateSliderStepsArray(350000, 1000000, 50000)
    ],
    ceil: 1000000
  };

  constructor(
    private readonly financialSituationConfigurationService: FinancialSituationConfigurationService
  ) {}

  ngOnInit(): void {
    this.initializeForm();
  }

  private initializeForm(): void {
    const currencyValidators = [
      Validators.min(0),
      Validators.maxLength(9),
      Validators.required,
      numberAsStringValidator()
    ];

    const formGroup: { [key: string]: UntypedFormControl } = {
      isBankDebt: new UntypedFormControl(
        this.financialSituation.frieMidler.gaeldIBankIdag ||
          this.financialSituation.frieMidler.gaeldIBank,
        Validators.required
      ),
      totalBankDebt: new UntypedFormControl(
        Math.round(this.financialSituation.frieMidler.bankgaeldIdag || 0),
        currencyValidators
      )
    };

    if (this.showBankDeptOnPensionControls()) {
      formGroup.isBankDebtOnPension = new UntypedFormControl(
        this.financialSituation.frieMidler.gaeldIBank || false,
        Validators.required
      );
      formGroup.bankDebtOnPension = new UntypedFormControl(
        Math.round(this.financialSituation.frieMidler.bankgaeld || 0),
        currencyValidators
      );
    }

    this.debtForm = new UntypedFormGroup(formGroup);

    if (this.form) {
      this.form.addControl('debt', this.debtForm);
    }
  }

  hasBankDebtChange(event: MatRadioChange): void {
    if (!event.value) {
      this.debtForm.controls.totalBankDebt.setValue(0);
      this.debtForm.controls.totalBankDebt.markAsDirty();
      if (this.showBankDeptOnPensionControls()) {
        this.debtForm.controls.isBankDebtOnPension.setValue(false);
        this.debtForm.controls.isBankDebtOnPension.markAsDirty();
        this.debtForm.controls.bankDebtOnPension.setValue(0);
        this.debtForm.controls.bankDebtOnPension.markAsDirty();
      }
    }
  }

  private showBankDeptOnPensionControls(): boolean {
    return (
      !this.isInInsuranceGuide &&
      (this.pensionCustomer.alder >=
        this.financialSituationConfigurationService.age ||
        this.withOlderCustomerSection)
    );
  }
}
