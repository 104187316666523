/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const DigitalAccessOptionTypeSchema = JoiObj.string().valid(
        'LOCK_DIGITAL_ACCESS'
        ,
        'UNLOCK_DIGITAL_ACCESS'
        ,
        'CREATE_ONLINE_ACCOUNT'
        ,
        'LOCK_ONLINE_ACCOUNT'
        ,
        'UNLOCK_ONLINE_ACCOUNT'
        ,
        'LOCK_ONLINE_PASSWORD'
        ,
        'UNLOCK_ONLINE_PASSWORD'
        ,
        'RESET_ONLINE_PASSWORD'
        
    )
.id('DigitalAccessOptionTypeSchema')

export type DigitalAccessOptionType = 'LOCK_DIGITAL_ACCESS' | 'UNLOCK_DIGITAL_ACCESS' | 'CREATE_ONLINE_ACCOUNT' | 'LOCK_ONLINE_ACCOUNT' | 'UNLOCK_ONLINE_ACCOUNT' | 'LOCK_ONLINE_PASSWORD' | 'UNLOCK_ONLINE_PASSWORD' | 'RESET_ONLINE_PASSWORD';

export const DigitalAccessOptionType = {
    LockDigitalAccess: 'LOCK_DIGITAL_ACCESS' as DigitalAccessOptionType,
    UnlockDigitalAccess: 'UNLOCK_DIGITAL_ACCESS' as DigitalAccessOptionType,
    CreateOnlineAccount: 'CREATE_ONLINE_ACCOUNT' as DigitalAccessOptionType,
    LockOnlineAccount: 'LOCK_ONLINE_ACCOUNT' as DigitalAccessOptionType,
    UnlockOnlineAccount: 'UNLOCK_ONLINE_ACCOUNT' as DigitalAccessOptionType,
    LockOnlinePassword: 'LOCK_ONLINE_PASSWORD' as DigitalAccessOptionType,
    UnlockOnlinePassword: 'UNLOCK_ONLINE_PASSWORD' as DigitalAccessOptionType,
    ResetOnlinePassword: 'RESET_ONLINE_PASSWORD' as DigitalAccessOptionType
};

