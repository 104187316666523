/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const CategorySkedifySchema = JoiObj.string().valid(
        'HOME_INSURANCE'
        
    )
.id('CategorySkedifySchema')

export type CategorySkedify = 'HOME_INSURANCE';

export const CategorySkedify = {
    HomeInsurance: 'HOME_INSURANCE' as CategorySkedify
};

