<svg
  width="258"
  height="258"
  viewBox="0 0 258 258"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <circle cx="129" cy="129" r="129" fill="#E5E5E5" />
  <mask
    id="mask0_5682_13996"
    style="mask-type: alpha"
    maskUnits="userSpaceOnUse"
    x="0"
    y="0"
    width="258"
    height="258"
  >
    <circle cx="129" cy="129" r="129" fill="#E5E5E5" />
  </mask>
  <g mask="url(#mask0_5682_13996)">
    <path d="M144.845 211.971H114.58V267.036H144.845V211.971Z" fill="#333333" />
    <path
      d="M162.538 37.7539H96.8881C93.9123 37.7539 91.5 40.1662 91.5 43.142V210.21C91.5 213.186 93.9123 215.598 96.8881 215.598H162.538C165.514 215.598 167.926 213.186 167.926 210.21V43.142C167.926 40.1662 165.514 37.7539 162.538 37.7539Z"
      fill="#666666"
    />
    <path
      d="M129.713 92.7068C142.502 92.7068 152.87 82.6127 152.87 70.161C152.87 57.7093 142.502 47.6152 129.713 47.6152C116.923 47.6152 106.556 57.7093 106.556 70.161C106.556 82.6127 116.923 92.7068 129.713 92.7068Z"
      fill="#333333"
    />
    <path
      d="M129.713 60.7988C134.736 60.7988 138.808 58.8313 138.808 56.4043C138.808 53.9773 134.736 52.0098 129.713 52.0098C124.69 52.0098 120.618 53.9773 120.618 56.4043C120.618 58.8313 124.69 60.7988 129.713 60.7988Z"
      fill="#666666"
    />
    <path
      d="M129.713 149.144C142.502 149.144 152.87 139.05 152.87 126.599C152.87 114.147 142.502 104.053 129.713 104.053C116.923 104.053 106.556 114.147 106.556 126.599C106.556 139.05 116.923 149.144 129.713 149.144Z"
      fill="#FFAA00"
    />
    <path
      opacity="0.8"
      d="M129.713 117.242C134.736 117.242 138.808 115.275 138.808 112.848C138.808 110.421 134.736 108.453 129.713 108.453C124.69 108.453 120.618 110.421 120.618 112.848C120.618 115.275 124.69 117.242 129.713 117.242Z"
      fill="white"
    />
    <path
      d="M129.713 205.588C142.502 205.588 152.87 195.494 152.87 183.042C152.87 170.59 142.502 160.496 129.713 160.496C116.923 160.496 106.556 170.59 106.556 183.042C106.556 195.494 116.923 205.588 129.713 205.588Z"
      fill="#333333"
    />
    <g opacity="0.8">
      <path
        d="M129.713 173.639C134.736 173.639 138.808 171.671 138.808 169.244C138.808 166.817 134.736 164.85 129.713 164.85C124.69 164.85 120.618 166.817 120.618 169.244C120.618 171.671 124.69 173.639 129.713 173.639Z"
        fill="#666666"
      />
    </g>
    <path
      d="M137.012 219.342H136.935C135.859 219.342 134.986 220.214 134.986 221.291V260.306C134.986 261.383 135.859 262.255 136.935 262.255H137.012C138.088 262.255 138.96 261.383 138.96 260.306V221.291C138.96 220.214 138.088 219.342 137.012 219.342Z"
      fill="#666666"
    />
  </g>
</svg>
