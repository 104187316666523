/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const CustomerStatusSchema = JoiObj.string().valid(
        'Active'
        ,
        'Passive'
        
    )
.id('CustomerStatusSchema')

export type CustomerStatus = 'Active' | 'Passive';

export const CustomerStatus = {
    Active: 'Active' as CustomerStatus,
    Passive: 'Passive' as CustomerStatus
};

