import { AfterViewInit, Component, Input } from '@angular/core';
import { InvestmentGuideInfo, PensionPlanExtend } from '@pfa/models';
import { PensionsKundeGenerelleData } from '@pfa/gen';
import { InvestmentGuideQuestion } from '../../../investment-guide-configuration.service';
import { InvestmentGuideService } from '@mitpfa/shared';

@Component({
  selector: 'mitpfa-investment-guide-pension-info',
  templateUrl: './investment-guide-pension-info.component.html',
  styleUrls: ['./investment-guide-pension-info.component.scss']
})
export class InvestmentGuidePensionInfoComponent implements AfterViewInit {
  @Input() pensionCustomer: PensionsKundeGenerelleData;
  @Input() investmentGuide: InvestmentGuideInfo;

  pensionPlan: PensionPlanExtend;

  constructor(readonly investmentGuideService: InvestmentGuideService) {}

  ngAfterViewInit(): void {
    window.scroll(0, 0);
  }

  goBack() {
    this.investmentGuideService.updateCurrentQuestion({
      value: this.investmentGuideService.getQuestionBeforePensionInfo(
        this.investmentGuide.recommendationBasis.conceptChoice
      ),
      back: true
    });
  }

  goToNextPage() {
    this.investmentGuideService.updateCurrentQuestion({
      value: InvestmentGuideQuestion.otherSavings
    });
  }
}
