/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HSSikring } from './hSSikring';
import { HSSikringSchema } from './hSSikring';
import * as JoiObj from "joi";


    export const HelbredssikringOptionSchema = JoiObj.object({
        content:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        sikring:
            
        JoiObj.link('#HSSikringSchema')


.required()


        
    })
                .shared(HSSikringSchema)
.id('HelbredssikringOptionSchema')

export interface HelbredssikringOption { 
    content: string;
    sikring: HSSikring;
}
export namespace HelbredssikringOption {
}


