/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const IndbetalingsaftaleFejlSchema = JoiObj.string().valid(
        'UdefineretFejl'
        ,
        'SAPTokenNotFound'
        ,
        'SAPTokenNotValid'
        ,
        'SAPTokenExpired'
        ,
        'KSDNotFound'
        
    )
.id('IndbetalingsaftaleFejlSchema')

export type IndbetalingsaftaleFejl = 'UdefineretFejl' | 'SAPTokenNotFound' | 'SAPTokenNotValid' | 'SAPTokenExpired' | 'KSDNotFound';

export const IndbetalingsaftaleFejl = {
    UdefineretFejl: 'UdefineretFejl' as IndbetalingsaftaleFejl,
    SapTokenNotFound: 'SAPTokenNotFound' as IndbetalingsaftaleFejl,
    SapTokenNotValid: 'SAPTokenNotValid' as IndbetalingsaftaleFejl,
    SapTokenExpired: 'SAPTokenExpired' as IndbetalingsaftaleFejl,
    KsdNotFound: 'KSDNotFound' as IndbetalingsaftaleFejl
};

