import { decimalNumberValidator, Utils } from '@pfa/utils';
import { ContentUtilService, ContentService } from '@pfa/core';
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef
} from '@angular/material/dialog';
import { AdvisorCorrectionsApiService } from '@pfa/api';
import { Component, OnInit, Input, Inject } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormControl,
  AbstractControl,
  Validators,
  UntypedFormArray,
  ValidatorFn
} from '@angular/forms';
import * as moment from 'moment';
import {
  AdvisorCorrectionsDetails,
  AdvisorCorrectionsRequest,
  Indtastetaftale,
  Indtastetdaekning,
  Indtastetforsikring,
  PensionsKundeGenerelleData,
  RetirementAge
} from '@pfa/gen';

@Component({
  selector: 'co-advisor-correction',
  templateUrl: './advisor-correction.component.html'
})
export class AdvisorCorrectionComponent implements OnInit {
  pensionInfoPlansForm: UntypedFormArray;
  insuranceForm: UntypedFormArray;
  investeringsformer: KeyValueInput[] = [];
  beskatningstyper: KeyValueInput[] = [];
  banktyper: KeyValueInput[] = [];
  pensionstyper: KeyValueInput[] = [];
  selskaber: KeyValueInput[] = [];
  saveCorrectionsFailed: boolean;
  partnerExpectedPensionAge: number;
  paragraph41TransferForm: UntypedFormGroup = new UntypedFormGroup({});
  retirementAge: RetirementAge;
  @Input() paragraf41Form: UntypedFormGroup;
  @Input() advisorCorrections: AdvisorCorrectionsDetails;
  @Input() isPartner: boolean;
  @Input() disableParagraf41: boolean;
  @Input() pensionCustomer: PensionsKundeGenerelleData;
  readonly paragraph41MaxAmount = 999999999;

  constructor(
    private readonly dialog: MatDialog,
    private readonly contentUtil: ContentUtilService,
    private readonly contentService: ContentService,
    private readonly advisorCorrectionsApiService: AdvisorCorrectionsApiService,
    @Inject(MAT_DIALOG_DATA) data,
    private readonly dialogRef: MatDialogRef<boolean>
  ) {
    if (data.advisorCorrections) {
      this.advisorCorrections = data.advisorCorrections;
      this.isPartner = data.isPartner;
      this.partnerExpectedPensionAge = data.partnerExpectedPensionAge;
    }
    this.pensionCustomer = data.pensionCustomer;
    this.retirementAge = data.retirementAge;
  }

  ngOnInit() {
    this.buildFormGroups();
    this.buildCorrectionSettings();
    this.advisorCorrections.policer.forEach(police => {
      this.checkParagraph41PrimaryPolice(police.pensionsordning);
    });
  }

  buildFormGroups(): void {
    this.pensionInfoPlansForm = new UntypedFormArray([]);
    this.advisorCorrections.policer.forEach(pensionPlan => {
      this.pensionInfoPlansForm.push(
        new UntypedFormGroup({
          selected: new UntypedFormControl(pensionPlan.valgt),
          pensionPlan: new UntypedFormControl(pensionPlan)
        })
      );
    });

    const manualPensionPlans = new UntypedFormArray([]);
    this.advisorCorrections.manuelPolicer.forEach(pensionPlan => {
      manualPensionPlans.push(this.buildPensionPlanFormGroup(pensionPlan));
    });

    const manualInsurances = new UntypedFormArray([]);
    this.advisorCorrections.manuelForsikringer?.forEach(insurance => {
      manualInsurances.push(this.buildInsuranceFormGroup(insurance));
    });

    this.paragraf41Form = new UntypedFormGroup({
      kommentar: new UntypedFormControl(this.advisorCorrections.kommentar),
      aftaler: manualPensionPlans,
      forsikringer: manualInsurances
    });
    if (!this.isPartner) {
      this.paragraph41TransferForm = new UntypedFormGroup({
        kapitalpension: new UntypedFormControl(
          {
            value: this.advisorCorrections.paragraf41?.kapitalpension ?? 0,
            disabled: !this.advisorCorrections.paragraf41?.kapitalpensionMulige
          },
          decimalNumberValidator(0, 0, this.paragraph41MaxAmount)
        ),
        aldersopsparing: new UntypedFormControl(
          {
            value: this.advisorCorrections.paragraf41?.aldersopsparing ?? 0,
            disabled: !this.advisorCorrections.paragraf41?.aldersopsparingMulige
          },
          decimalNumberValidator(0, 0, this.paragraph41MaxAmount)
        ),
        ratepension: new UntypedFormControl(
          {
            value: this.advisorCorrections.paragraf41?.ratepension ?? 0,
            disabled: !this.advisorCorrections.paragraf41?.ratepensionMulige
          },
          decimalNumberValidator(0, 0, this.paragraph41MaxAmount)
        ),
        tidsbegraensetLivspension: new UntypedFormControl(
          {
            value:
              this.advisorCorrections.paragraf41?.tidsbegraensetLivspension ??
              0,
            disabled:
              !this.advisorCorrections.paragraf41
                ?.tidsbegraensetLivspensionMulige
          },
          decimalNumberValidator(0, 0, this.paragraph41MaxAmount)
        ),
        livsvarigLivrente: new UntypedFormControl(
          {
            value: this.advisorCorrections.paragraf41?.livsvarigLivrente ?? 0,
            disabled:
              !this.advisorCorrections.paragraf41?.livsvarigLivrenteMulige
          },
          decimalNumberValidator(0, 0, this.paragraph41MaxAmount)
        ),
        indskudArbejdsgiver: new UntypedFormControl(
          {
            value: this.advisorCorrections.paragraf41?.indskudArbejdsgiver ?? 0,
            disabled:
              !this.advisorCorrections.paragraf41?.indskudArbejdsgiverMulige
          },
          decimalNumberValidator(0, 0, this.paragraph41MaxAmount)
        )
      });
    }
  }

  displaySelskab(id: string): string {
    return this.contentUtil.getContent(id);
  }

  filterSelskaber(coverageGroup: UntypedFormGroup) {
    const companies = this.selskaber.filter(selskab => {
      if (!coverageGroup.get('selskab').value) {
        return this.selskaber;
      }
      return (
        selskab.name
          .toLowerCase()
          .indexOf(coverageGroup.get('selskab').value.toLowerCase()) > -1
      );
    });
    return companies.filter(company => company.name);
  }

  get aftaler() {
    return this.paragraf41Form.get('aftaler') as UntypedFormArray;
  }

  get forsikringer() {
    return this.paragraf41Form.get('forsikringer') as UntypedFormArray;
  }

  closePopup() {
    this.dialog.closeAll();
  }

  isEmpty(val: string) {
    return Utils.isEmpty(val);
  }

  buildSelskaber(): KeyValueInput[] {
    const selskaber = [];
    const content = this.contentService.getContent();
    Object.keys(content)
      .filter(contentId => contentId.startsWith('DL.SEL'))
      .forEach(contentId => {
        selskaber.push({ id: contentId, name: content[contentId] });
      });
    return selskaber;
  }

  showInput(id: string, field: string, type: string): boolean {
    return (
      (type === 'C785' ? this.banktyper : this.pensionstyper).filter(
        elm => elm.id === id && elm.inputs.indexOf(field) > -1
      ).length > 0
    );
  }

  addManualPensionPlan(): void {
    this.aftaler.push(this.buildPensionPlanFormGroup(null));
  }

  addManualInsurance(): void {
    this.forsikringer.push(this.buildInsuranceFormGroup(null));
  }

  removeManualPensionPlan(index: number): void {
    this.paragraf41Form.markAsDirty();
    this.aftaler.removeAt(index);
  }

  removeManualInsurance(index: number): void {
    this.paragraf41Form.markAsDirty();
    this.forsikringer.removeAt(index);
  }

  addManualCoverage(pensionPlanForm: UntypedFormGroup) {
    const coverageFormArray = pensionPlanForm.get(
      'daekninger'
    ) as UntypedFormArray;
    coverageFormArray.push(
      this.buildCoverage(null, pensionPlanForm.get('investeringsform'))
    );
  }

  removeManualCoverage(pensionPlanForm: UntypedFormGroup, index: number) {
    this.paragraf41Form.markAsDirty();
    const coverageFormArray = pensionPlanForm.get(
      'daekninger'
    ) as UntypedFormArray;
    coverageFormArray.removeAt(index);
  }

  buildPensionPlanFormGroup(pensionPlan: Indtastetaftale): UntypedFormGroup {
    const pensionPlanForm = new UntypedFormGroup({
      selskab: new UntypedFormControl(
        pensionPlan ? pensionPlan.selskab.id : null,
        {
          validators: [Validators.required, this.companyValidator()]
        }
      ),
      id: new UntypedFormControl(pensionPlan ? pensionPlan.id : null, {
        validators: [Validators.required, Validators.minLength(4)]
      }),
      investeringsform: new UntypedFormControl(
        pensionPlan ? pensionPlan.investeringsform.id : null,
        Validators.required
      )
    });

    pensionPlanForm.addControl(
      'daekninger',
      this.buildCoverageFormArray(
        pensionPlan,
        pensionPlanForm.get('investeringsform')
      )
    );
    return pensionPlanForm;
  }

  buildInsuranceFormGroup(insurance: Indtastetforsikring): UntypedFormGroup {
    return new UntypedFormGroup({
      selskab: new UntypedFormControl(insurance ? insurance.selskab.id : null, {
        validators: [Validators.required, this.companyValidator()]
      }),
      id: new UntypedFormControl(insurance ? insurance.id : null, {
        validators: [Validators.required, Validators.minLength(4)]
      }),
      beskatningsform: new UntypedFormControl(
        insurance && insurance.beskatningsform
          ? insurance.beskatningsform.id
          : null,
        {
          validators: [Validators.required]
        }
      ),
      livssikringsbeloeb: new UntypedFormControl(
        insurance && insurance.livssikringsbeloeb
          ? insurance.livssikringsbeloeb
          : null,
        {
          validators: [Validators.required]
        }
      )
    });
  }

  buildCoverageFormArray(
    pensionPlan: Indtastetaftale,
    investeringsform: AbstractControl
  ): UntypedFormArray {
    const daekninger = new UntypedFormArray([]);
    if (pensionPlan) {
      pensionPlan.daekninger.forEach(coverage => {
        daekninger.push(this.buildCoverage(coverage, investeringsform));
      });
    }
    return daekninger;
  }

  buildCoverage(
    coverage: Indtastetdaekning,
    investeringsform: AbstractControl
  ): UntypedFormGroup {
    const typeControl = new UntypedFormControl(
      coverage ? coverage.type.id : null,
      Validators.required
    );
    return new UntypedFormGroup({
      type: typeControl,
      pensionsalder: new UntypedFormControl(
        coverage
          ? coverage.pensionsalder
          : this.partnerExpectedPensionAge
            ? this.partnerExpectedPensionAge
            : null,
        {
          validators: [
            this.addValidatorIfEnabledField(
              'pensionsalder',
              investeringsform,
              Validators.required
            ),
            this.addValidatorIfEnabledField(
              'pensionsalder',
              investeringsform,
              Validators.min(
                this.isPartner
                  ? this.retirementAge.minSpouseAge
                  : this.retirementAge.minAge
              )
            ),
            this.addValidatorIfEnabledField(
              'pensionsalder',
              investeringsform,
              Validators.max(this.retirementAge.maxAge)
            ),
            this.addValidatorIfEnabledField(
              'pensionsalder',
              investeringsform,
              Validators.pattern(/^[0-9]\d*$/)
            )
          ]
        }
      ),
      //The input from the parent control must set partnerExpectedPensionAge
      partnerExpectedPensionAge: new UntypedFormControl(
        coverage ? coverage.pensionsalder : this.partnerExpectedPensionAge,
        {
          validators: this.isPartner
            ? [
                this.addValidatorIfEnabledField(
                  'pensionsalder',
                  investeringsform,
                  Validators.required
                )
              ]
            : null
        }
      ),
      beskatning: new UntypedFormControl(
        coverage ? coverage.beskatning.id : null,
        Validators.required
      ),
      periode: new UntypedFormControl(coverage ? coverage.periode : null, {
        validators: [
          this.addValidatorIfEnabledField(
            'periode',
            investeringsform,
            Validators.required
          ),
          this.addValidatorIfEnabledField(
            'periode',
            investeringsform,
            Validators.pattern(/^[0-9]\d*$/)
          ),
          this.addValidatorIfEnabledField(
            'periode',
            investeringsform,
            this.periodeValidator(typeControl)
          )
        ]
      }),
      slutdato: new UntypedFormControl(
        coverage?.slutdato ? moment(coverage.slutdato, 'DD.MM.YYYY') : null,
        this.addValidatorIfEnabledField(
          'slutdato',
          investeringsform,
          Validators.required
        )
      ),
      udbetaling: new UntypedFormControl(
        coverage ? coverage.udbetaling : null,
        Validators.required
      )
    });
  }

  validate(daekning: UntypedFormGroup) {
    Object.keys(daekning.controls).forEach(key => {
      daekning.controls[key].updateValueAndValidity();
    });
  }

  getManuallyEnteredPensionPlans(): Indtastetaftale[] {
    const manuallyEnteredPensionPlans: Indtastetaftale[] = [];
    this.aftaler.controls.forEach(manualPlan => {
      manuallyEnteredPensionPlans.push({
        daekninger: manualPlan.get('daekninger')?.value,
        id: manualPlan.get('id')?.value,
        investeringsform: {
          id: manualPlan.get('investeringsform')?.value,
          name: null
        },
        selskab: {
          id: manualPlan.get('selskab').value,
          name: this.contentUtil.getContent(manualPlan.get('selskab').value)
        }
      });
      manuallyEnteredPensionPlans.forEach(manuallyEnteredPlan => {
        manuallyEnteredPlan?.daekninger?.forEach(coverage => {
          if (coverage.slutdato) {
            coverage.slutdato = moment(coverage.slutdato).format('DD.MM.YYYY');
          }
        });
      });
    });
    return manuallyEnteredPensionPlans;
  }

  getManuallyEnteredInsurances(): Indtastetforsikring[] {
    const manuallyEnteredInsurances: Indtastetforsikring[] = [];
    this.forsikringer.controls.forEach((manualInsurance: AbstractControl) => {
      manuallyEnteredInsurances.push({
        id: manualInsurance.get('id')?.value,
        selskab: {
          id: manualInsurance.get('selskab').value,
          name: this.contentUtil.getContent(
            manualInsurance.get('selskab').value
          )
        },
        beskatningsform: manualInsurance.get('beskatningsform').value,
        livssikringsbeloeb: manualInsurance.get('livssikringsbeloeb').value
      });
    });
    return manuallyEnteredInsurances;
  }

  saveCorrections(): void {
    const advisorCorrectionsRequest: AdvisorCorrectionsRequest = {
      paragraf41: this.isPartner
        ? this.advisorCorrections.paragraf41
        : this.getParagraph41TransferForm(),
      manuelPolicer: this.getManuallyEnteredPensionPlans(),
      manuelForsikringer: this.getManuallyEnteredInsurances(),
      kommentar: this.paragraf41Form.get('kommentar').value,
      fravalgtePIPolicer: this.pensionInfoPlansForm.controls
        .filter(
          pensionPlan =>
            !pensionPlan.get('selected').value &&
            pensionPlan.get('pensionPlan').value.selskab !== 'DL.SEL.C10'
        )
        .map(obj => obj.get('pensionPlan').value.pensionsordning),
      fravalgtePFAPrognosePolicer: this.pensionInfoPlansForm.controls
        .filter(
          pensionPlan =>
            !pensionPlan.get('selected').value &&
            pensionPlan.get('pensionPlan').value.selskab === 'DL.SEL.C10'
        )
        .map(obj => obj.get('pensionPlan').value.pensionsordning),
      serviceInfo: null
    };

    (this.isPartner
      ? this.advisorCorrectionsApiService.saveCorrectionsPartner(
          advisorCorrectionsRequest
        )
      : this.advisorCorrectionsApiService.saveCorrections(
          advisorCorrectionsRequest
        )
    ).subscribe({
      next: response => {
        if (response && response.success) {
          this.saveCorrectionsFailed = false;
          this.dialogRef.close(true);
        } else {
          this.saveCorrectionsFailed = true;
        }
      },
      error: () => {
        this.saveCorrectionsFailed = true;
      }
    });
  }

  getParagraph41TransferForm() {
    const paragraph41Form = this.paragraph41TransferForm.value;
    paragraph41Form.primaerPolicenr =
      this.advisorCorrections.paragraf41?.primaerPolicenr;
    return paragraph41Form;
  }

  buildCorrectionSettings(): void {
    this.selskaber = this.buildSelskaber();
    this.pensionstyper = [
      {
        id: 'C466',
        name: 'DL.PP01.C466',
        inputs: ['pensionsalder', 'beskatning', 'udbetaling']
      },
      {
        id: 'C467',
        name: 'DL.PP01.C467',
        inputs: ['pensionsalder', 'beskatning', 'udbetaling']
      },
      {
        id: 'C468',
        name: 'DL.PP01.C468',
        inputs: ['beskatning', 'udbetaling']
      },
      {
        id: 'C469',
        name: 'DL.PP01.C469',
        inputs: ['pensionsalder', 'beskatning', 'periode', 'udbetaling']
      },
      {
        id: 'C470',
        name: 'DL.PP01.C470',
        inputs: ['beskatning', 'slutdato', 'udbetaling']
      },
      {
        id: 'C471',
        name: 'DL.PP01.C471',
        inputs: ['pensionsalder', 'beskatning', 'periode', 'udbetaling']
      },
      {
        id: 'C472',
        name: 'DL.PP01.C472',
        inputs: ['beskatning', 'slutdato', 'udbetaling']
      }
    ];

    this.banktyper = [
      {
        id: 'C466',
        name: 'DL.PP01.C466',
        inputs: ['pensionsalder', 'beskatning', 'udbetaling']
      },
      {
        id: 'C471',
        name: 'DL.PP01.C471',
        inputs: ['pensionsalder', 'beskatning', 'periode', 'udbetaling']
      },
      {
        id: 'C472',
        name: 'DL.PP01.C472',
        inputs: ['beskatning', 'slutdato', 'udbetaling']
      }
    ];

    this.beskatningstyper = [
      { id: 'C473', name: 'DL.PP01.C473' },
      { id: 'C474', name: 'DL.PP01.C474' }
    ];

    this.investeringsformer = [
      { id: 'C722', name: 'DL.PP01.C722' },
      { id: 'C723', name: 'DL.PP01.C723' },
      { id: 'C785', name: 'DL.PP01.C785' }
    ];
  }

  addValidatorIfEnabledField(
    field: string,
    investeringsform: AbstractControl,
    validator: ValidatorFn
  ): ValidatorFn {
    return (control: UntypedFormControl) => {
      const coverageType =
        control && control.parent ? control.parent.get('type') : null;
      if (
        coverageType &&
        investeringsform &&
        this.showInput(coverageType.value, field, investeringsform.value)
      ) {
        return validator(control);
      } else {
        return null;
      }
    };
  }

  periodeValidator(typeControl: UntypedFormControl): ValidatorFn {
    return (thisControl: AbstractControl): { [key: string]: string } | null => {
      const minPeriode = 10;
      let maxPeriode;
      if (typeControl.value === 'C469') {
        maxPeriode = 90 - this.partnerExpectedPensionAge;
      } else {
        maxPeriode = 87 - this.partnerExpectedPensionAge;
      }
      const errors = {};
      if (
        (thisControl.value && thisControl.value < minPeriode) ||
        (thisControl.value && thisControl.value > maxPeriode)
      ) {
        errors['min'] = minPeriode;
        errors['max'] = maxPeriode;
      }
      return errors;
    };
  }

  companyValidator(): ValidatorFn {
    return (thisControl: AbstractControl): { [key: string]: string } | null => {
      const errors = {};
      if (
        thisControl.value &&
        this.selskaber &&
        this.selskaber.filter(selskab => selskab.id === thisControl.value)
          .length === 0
      ) {
        errors['required'] = 'invalid company name';
      }
      return errors;
    };
  }

  checkParagraph41PrimaryPolice(policeId: string) {
    if (policeId === this.advisorCorrections.paragraf41?.primaerPolicenr) {
      const primaryPoliceForm = this.pensionInfoPlansForm.controls.filter(
        form => policeId === form.get('pensionPlan').value.pensionsordning
      );
      if (
        primaryPoliceForm?.length > 0 &&
        !primaryPoliceForm[0].get('selected')?.value
      ) {
        Object.keys(this.paragraph41TransferForm.controls).forEach(key => {
          this.paragraph41TransferForm.controls[key].setValue(0);
        });
        this.paragraph41TransferForm.disable();
      } else {
        this.paragraph41TransferForm.enable();
      }
    }
  }
}

interface KeyValueInput {
  id: string;
  name: string;
  inputs?: string[];
}
