<co-loading [show]="!initialized"></co-loading>

<div *ngIf="payoutsData && (serviceError || allPoliciesWithError)">
  <mitpfa-dashboard-savings-header
    class="spacing-unit--half-under"
    [content]="'DL.FS03.C010'"
    [hideArrow]="true"
  ></mitpfa-dashboard-savings-header>

  <div class="blocking-error">
    <span class="error-grey-area"></span>
    <co-error>
      <span [coContent]="'DL.FS03.C47'"></span>
    </co-error>
  </div>
</div>

<div
  class="payouts-container"
  *ngIf="payoutsData && initialized && !serviceError && !allPoliciesWithError"
  (click)="goToPensionPlan()"
  data-test-id="frontpage-savings-payouts"
>
  <mitpfa-dashboard-savings-header
    class="spacing-unit--half-under"
    [content]="'DL.FS03.C01'"
    [contentSubst]="plannedPensionAge"
  ></mitpfa-dashboard-savings-header>

  <div class="payouts-amount-container">
    <div class="text--emphasized payouts-amount-value">
      {{ pensionEstimate | number: '1.0-0' : 'da-DK' }}
    </div>
    <div class="payouts-amount-period">
      <span
        [coContent]="'DL.FS03.C03'"
        [coContentSubst]="monthlyPayoutFormatted"
      ></span>
      <span class="fake-link" [coContent]="'DL.FS03.C02'"></span>
    </div>
  </div>

  <div class="policiesWithError" *ngIf="policiesWithError">
    <co-error>
      <span [coContent]="'DL.FS03.C41'"
        >Dele af din udbetaling kan ikke vises<br />Kontakt PFA for at f[ det
        fulde billede</span
      >
    </co-error>
  </div>

  <co-info
    *ngIf="
      !pensionCustomer?.harPensionsinfo ||
      pensionCustomer?.pensionInfoDataInvalid
    "
  >
    <span [coContent]="'DL.PI01.C156'"></span>
    <span>
      <a
        (click)="stopPropagation($event)"
        class="link"
        [href]="'/mine-oplysninger/pensionsinfo'"
        [coContent]="'DL.PI01.C155'"
      >
      </a>
    </span>
  </co-info>
</div>

<co-card
  [showErrorMessage]="policiesWithError"
  [hideErrorBlocks]="true"
  routerLink="/pensionstal"
  class="card--small cursor-pointer"
  *ngIf="!payoutsData && initialized"
  data-test-id="frontpage-savings-payouts"
>
  <mitpfa-dashboard-savings-header
    [content]="payoutsStarted ? 'DL.FS03.C56' : 'DL.FS03.C010'"
  ></mitpfa-dashboard-savings-header>
  <div [coContent]="payoutsStarted ? 'DL.FS03.C57' : 'DL.FS03.C37'"></div>

  <div *ngIf="policiesWithError" card-additional class="error-overlay">
    <co-icon-error></co-icon-error>
    <span [coContent]="'DL.FS03.C41' | content"></span>
  </div>
</co-card>
