<co-loading [show]="!initialized"></co-loading>

<ng-container *ngIf="initialized">
  <co-alert class="alert-info spacing-unit--under">
    <div class="banner">
      <div
        [coContent]="
          broker.maeglerBetjent && broker.livnetAftale
            ? 'DL.BE02.C1065'
            : 'DL.BE02.C1064'
        "
      ></div>
      <a [href]="'tel:' + phoneNumber" class="mat-mdc-raised-button">
        <co-icon-phone class="icon"></co-icon-phone>
        <span>{{ phoneNumber }}</span>
      </a>
    </div>
  </co-alert>

  <h1 coContent="DL.BE02.C01"></h1>

  <co-messaging [channel]="channel" [isRaadgiver]="isRaadgiver"></co-messaging>
</ng-container>
