import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MaeglerInfo } from '@pfa/gen';
import { InvestmentGuideInfo } from '@pfa/models';
import { RiskWillingnessChoice } from '../../investment-guide-risk-step.model';
import { InvestmentGuideService } from '@mitpfa/shared';

@Component({
  selector: 'mitpfa-investment-guide-risk-willingness',
  templateUrl: './investment-guide-risk-step-risk-willingness.component.html',
  styleUrls: ['./investment-guide-risk-step-risk-willingness.component.scss']
})
export class InvestmentGuideRiskWillingnessComponent implements OnInit {
  @Input() investmentGuide: InvestmentGuideInfo;
  @Input() broker: MaeglerInfo;

  constructor(
    private readonly investmentGuideService: InvestmentGuideService
  ) {}

  answer: UntypedFormControl;
  choices: RiskWillingnessChoice[] = [
    {
      text: 'DL.INVGU01.C655',
      value: 'LOW'
    },
    {
      text: 'DL.INVGU01.C656',
      value: 'AVERAGE'
    },
    {
      text: 'DL.INVGU01.C657',
      value: 'HIGH'
    }
  ];

  ngOnInit(): void {
    this.answer = new UntypedFormControl(
      this.investmentGuide.recommendationBasis.riskWillingness,
      Validators.required
    );
  }

  choose(): void {
    this.investmentGuide.recommendationBasis.riskWillingness =
      this.answer.value;
    this.investmentGuideService.updateCurrentQuestion({
      value: 'RISK_PROFILE',
      update: true
    });
  }
}
