/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DynamicAdvisingInvestmentConceptShareType } from './dynamicAdvisingInvestmentConceptShareType';
import { DynamicAdvisingInvestmentConceptShareTypeSchema } from './dynamicAdvisingInvestmentConceptShareType';
import * as JoiObj from "joi";


    export const DynamicAdvisingInvestmentConceptShareSchema = JoiObj.object({
        investmentConceptShareType:
            
        JoiObj.link('#DynamicAdvisingInvestmentConceptShareTypeSchema')


.required()


        ,
        investmentConceptShareValue:
            
                    JoiObj.number()
                .integer()


.required()


        
    })
                .shared(DynamicAdvisingInvestmentConceptShareTypeSchema)
.id('DynamicAdvisingInvestmentConceptShareSchema')

export interface DynamicAdvisingInvestmentConceptShare { 
    investmentConceptShareType: DynamicAdvisingInvestmentConceptShareType;
    investmentConceptShareValue: number;
}
export namespace DynamicAdvisingInvestmentConceptShare {
}


