/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const OpsparingTypeSchema = JoiObj.string().valid(
        'MARKEDSRENTE'
        ,
        'GENNEMSNITSRENTE'
        ,
        'MARKEDSRENTE_GENNEMSNITSRENTE'
        ,
        'BANKOPSPARING'
        ,
        'IKKE_OPLYST'
        
    )
.id('OpsparingTypeSchema')

export type OpsparingType = 'MARKEDSRENTE' | 'GENNEMSNITSRENTE' | 'MARKEDSRENTE_GENNEMSNITSRENTE' | 'BANKOPSPARING' | 'IKKE_OPLYST';

export const OpsparingType = {
    Markedsrente: 'MARKEDSRENTE' as OpsparingType,
    Gennemsnitsrente: 'GENNEMSNITSRENTE' as OpsparingType,
    MarkedsrenteGennemsnitsrente: 'MARKEDSRENTE_GENNEMSNITSRENTE' as OpsparingType,
    Bankopsparing: 'BANKOPSPARING' as OpsparingType,
    IkkeOplyst: 'IKKE_OPLYST' as OpsparingType
};

