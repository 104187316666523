/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { LinieElement } from './linieElement';
import { LinieElementSchema } from './linieElement';
import * as JoiObj from "joi";


    export const IndbetalingPensionsordningUdenFradragsretSchema = JoiObj.object({
        linieElementer:
                JoiObj.array().items(
        
        JoiObj.link('#LinieElementSchema')

        )
        

.required()


        ,
        linieElementerOpsparingsForm:
                JoiObj.array().items(
        
        JoiObj.link('#LinieElementSchema')

        )
        

.required()


        
    })
                .shared(LinieElementSchema)
                .shared(LinieElementSchema)
.id('IndbetalingPensionsordningUdenFradragsretSchema')

export interface IndbetalingPensionsordningUdenFradragsret { 
    linieElementer: Array<LinieElement>;
    linieElementerOpsparingsForm: Array<LinieElement>;
}

