import {ErrorHandler, inject, Injectable } from '@angular/core';
import {BehaviorSubject, map, Observable, switchMap} from 'rxjs';
import {AnySchema, ValidationResult} from 'joi';
import {shareReplay} from "rxjs/operators";

import {Configuration} from '../configuration';

@Injectable({
  providedIn: 'root'
})
export abstract class GenericStore {

  protected errorHandler = inject(ErrorHandler);
  protected configuration = inject(Configuration, { optional: true });

  protected constructor() {
    if (!this.configuration) {
      this.configuration = new Configuration();
    }
  }

  protected initiateStream<T>(subject: BehaviorSubject<0>, httpObservable: Observable<T>, schema: AnySchema<T>): Observable<T> {
    return subject.asObservable().pipe(
      switchMap(
        _ => httpObservable
      ),
      map(value => {
        this.throwOrHandleValidationError(schema, value);
        return value;
      }),
      shareReplay(1)
    );
  }

  protected throwOrHandleValidationError<T>(schema: AnySchema<T>, value: any) {
    const error = this.validate(schema, value);
    if(error) {
        error.message = `In ${schema._flags['id']} (found through ${this.constructor.name}) -> ${error.message}`;
        if (this.configuration?.throwOnValidationError) {
            // Remember that in such case information about validation error might lost if error is caught and handler in "subscribe()"
            throw error;
        } else {
            this.errorHandler.handleError(error);
        }
    }
  }


  protected validate(schema: AnySchema, value: any) {
    let validation: ValidationResult<any>;
    if (Array.isArray(value)) {
      validation = value
        .map(value => schema.validate(value, { abortEarly: false }))
        .reduce((acc, val) => (acc.error ? acc : val), { error: undefined, value: {} } as ValidationResult<any>);
    } else {
      validation = schema.validate(value, { abortEarly: false });
    }

    return validation.error;
  }

  protected hashKey(key: string) : string {
    var hash = 0, i, chr;
    if (key.length === 0) return hash.toString();
    for (i = 0; i < key.length; i++) {
      chr = key.charCodeAt(i);
      hash = ((hash << 5) - hash) + chr;
      hash |= 0;
    }
    return hash.toString();
  }

  abstract invalidateAll() : void;
}