import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MyCommonModule } from '@pfa/common';
import { CoreModule } from '@pfa/core';
import { PfaFormModule } from '@pfaform';
import { IconModule } from '@pfa/icons';
import { ErrorComponent } from './error.component';
import { ErrorRoutingModule } from './error-routing.module';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  imports: [
    CommonModule,
    MyCommonModule,
    CoreModule,
    PfaFormModule,
    IconModule,
    MatButtonModule,
    ErrorRoutingModule
  ],
  declarations: [ErrorComponent]
})
export class ErrorModule {}
