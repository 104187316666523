/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SignedDocumentFormat } from './signedDocumentFormat';
import { SignedDocumentFormatSchema } from './signedDocumentFormat';
import { DokumentFilFormat } from './dokumentFilFormat';
import { DokumentFilFormatSchema } from './dokumentFilFormat';
import * as JoiObj from "joi";


    export const SignatureMetaSchema = JoiObj.object({
        associatedSignature:
            
                    JoiObj.object()
                


.required()


        ,
        associatedSignatureFileFormat:
            
        JoiObj.link('#DokumentFilFormatSchema')


.required()


        ,
        inlineSignature:
            
                    JoiObj.boolean()
                


.required()


        ,
        signatureVersion:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        signedDocumentFormat:
            
        JoiObj.link('#SignedDocumentFormatSchema')


.required()


        
    })
                .shared(DokumentFilFormatSchema)
                .shared(SignedDocumentFormatSchema)
.id('SignatureMetaSchema')

export interface SignatureMeta { 
    associatedSignature: object;
    associatedSignatureFileFormat: DokumentFilFormat;
    inlineSignature: boolean;
    signatureVersion: string;
    signedDocumentFormat: SignedDocumentFormat;
}
export namespace SignatureMeta {
}


