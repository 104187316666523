import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {
  InsuranceGuideQuestion,
  InsuranceGuideQuestionValue
} from '../../insurance-guide-configuration.service';
import { PensionsKundeGenerelleData } from '@pfa/gen';
import { GuideTrackingService } from '@pfa/core';

@Component({
  selector: 'mitpfa-insurance-guide-pension-plan',
  templateUrl: './insurance-guide-pension-plan.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InsuranceGuidePensionPlanComponent {
  @Input() currentQuestion: BehaviorSubject<InsuranceGuideQuestionValue>;
  @Input() pensionCustomer: PensionsKundeGenerelleData;
  @Output() emitInformationStep = new EventEmitter<void>();

  constructor(readonly trackingService: GuideTrackingService) {}

  public goNext(): void {
    this.currentQuestion.next({ value: InsuranceGuideQuestion.family });
    window.scroll(0, 0);
  }

  public goBack(): void {
    this.currentQuestion.next({
      value: InsuranceGuideQuestion.intro,
      back: true
    });
  }
}
