import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { OpsparingPolice, OpsparingPoliceDetaljer } from '@pfa/gen';
import { ContentService, ContentUtilService } from '@pfa/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'mitpfa-savings-policy-costs',
  templateUrl: './savings-policy-costs.component.html',
  styleUrls: ['./savings-policy-costs.component.scss']
})
export class SavingsPolicyCostsComponent implements OnInit, OnDestroy {
  @Input() savingsPolicyDetails: OpsparingPoliceDetaljer;
  @Input() policy: OpsparingPolice;

  expandedDetailsAdmin: boolean;
  expandedDetails: boolean;
  showExpandedDetails: boolean;
  public indirectExpenses: string;

  private readonly unsubscribe = new Subject<void>();

  constructor(
    private readonly contentUtil: ContentUtilService,
    private readonly contentService: ContentService
  ) {}

  ngOnInit(): void {
    this.showExpandedDetails =
      !!this.savingsPolicyDetails.aaopAaok
        .indirekteDetaljerInvesteringsomkostninger ||
      !!this.savingsPolicyDetails.aaopAaok
        .indirekteDetaljerDriftsherretillaeg ||
      !!this.savingsPolicyDetails.aaopAaok
        .indirekteDetaljerOmkostningsrisikoresultat;

    this.setIndirectExpensesText();

    this.contentService.languageState
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
        this.setIndirectExpensesText();
      });
  }

  public isEstimatedIndirectExpenses(): boolean {
    if (!this.savingsPolicyDetails?.fraDato) {
      return false;
    }

    return parseInt(this.savingsPolicyDetails.fraDato?.slice(-4), 10) > 2021;
  }

  private setIndirectExpensesText(): void {
    let text: string;
    if (this.savingsPolicyDetails.markedgennemsnitsrente) {
      text = 'DL.OS01.C442';
    } else {
      text = this.isEstimatedIndirectExpenses()
        ? 'DL.OS01.C434'
        : 'DL.OS01.C344';
    }
    this.indirectExpenses = this.contentUtil.getContent(text);
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
