import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import {RestCacheEntry} from "./RestCacheEntry";
import {GenericStore} from "./GenericStore";
import * as JoiObj from "joi";

import {
        BeneficiaryCreateRequest,
        BeneficiaryCreateRequestSchema,
        BeneficiaryDraft,
        BeneficiaryDraftSchema,
        BeneficiaryDraftRequest,
        BeneficiaryDraftRequestSchema,
        BeneficiaryExistingResponse,
        BeneficiaryExistingResponseSchema,
        BeneficiaryPolicies,
        BeneficiaryPoliciesSchema,
        BeneficiaryRules,
        BeneficiaryRulesSchema,
        BeneficiarySignRequest,
        BeneficiarySignRequestSchema,
        GodkendSvar,
        GodkendSvarSchema,
        SuccessResponse,
        SuccessResponseSchema
} from '../model/models';

import { BeneficiaryService } from './beneficiary.service';


@Injectable({
    providedIn: 'root'
})
export class BeneficiaryStore extends GenericStore {

            private beneficiaryletpensionrulesIndexCache: Map<string, RestCacheEntry<BeneficiaryRules>> = new Map<string, RestCacheEntry<BeneficiaryRules>>();
            private beneficiaryrulesIndexCache: Map<string, RestCacheEntry<BeneficiaryRules>> = new Map<string, RestCacheEntry<BeneficiaryRules>>();
            private beneficiarydraftuuidShowCache: Map<string, RestCacheEntry<BeneficiaryDraft>> = new Map<string, RestCacheEntry<BeneficiaryDraft>>();
            private beneficiaryIndexCache: Map<string, RestCacheEntry<BeneficiaryExistingResponse>> = new Map<string, RestCacheEntry<BeneficiaryExistingResponse>>();
            private beneficiaryletpensionIndexCache: Map<string, RestCacheEntry<BeneficiaryExistingResponse>> = new Map<string, RestCacheEntry<BeneficiaryExistingResponse>>();

    constructor(private beneficiaryService: BeneficiaryService) {
        super();
    }

    invalidateAll(): void {
                
                
                
                
                this.invalidatebeneficiarydraftuuidShow();
                
                
                this.invalidatebeneficiaryIndex();
                
                
                
                this.invalidatebeneficiaryletpensionIndex();
                
                this.invalidatebeneficiaryletpensionrulesIndex();
                
                
                
                
                
                
                
                this.invalidatebeneficiaryrulesIndex();
                
                
                
                
    }




                    public beneficiaryDraftPost(beneficiaryDraftRequest?: BeneficiaryDraftRequest) : Observable<any> {
                        return this.beneficiaryService.beneficiaryDraftPost(beneficiaryDraftRequest).pipe(
                            map(value => {
                                
                                
                                return value;
                            })
                        );
                    }








                    public beneficiaryDraftUuidDelete(uuid: string) : Observable<any> {
                        return this.beneficiaryService.beneficiaryDraftUuidDelete(uuid).pipe(
                            map(value => {
                                this.invalidatebeneficiarydraftuuidShow();
                                
                                return value;
                            })
                        );
                    }

                public beneficiaryDraftUuidGet(uuid: string) : Observable<BeneficiaryDraft> {
                    let hash = this.hashKey((uuid.toString() || "0"));
                    if (this.beneficiarydraftuuidShowCache.has(hash)) {
                        return this.beneficiarydraftuuidShowCache.get(hash)!.observable;
                    } else {
                        let beneficiarydraftuuidShowSubject: BehaviorSubject<0> = new BehaviorSubject(0);
                        let beneficiarydraftuuidShow = this.initiateStream(beneficiarydraftuuidShowSubject, this.beneficiaryService.beneficiaryDraftUuidGet(uuid), BeneficiaryDraftSchema);
                        this.beneficiarydraftuuidShowCache.set(hash, new RestCacheEntry<BeneficiaryDraft>(beneficiarydraftuuidShow, beneficiarydraftuuidShowSubject));
                        return beneficiarydraftuuidShow;
                    }
                }

                public invalidatebeneficiarydraftuuidShowById(uuid: string) {
                    let hash = this.hashKey((uuid.toString() || "0"));
                    if (this.beneficiarydraftuuidShowCache.has(hash)) {
                        this.beneficiarydraftuuidShowCache.get(hash)!.subject.next(0);
                    }
                }

                public invalidatebeneficiarydraftuuidShow() {
                    this.beneficiarydraftuuidShowCache.forEach((cacheEntry) => {
                        cacheEntry.subject.next(0);
                    })
                }







                public beneficiaryGet() : Observable<BeneficiaryExistingResponse> {
                    let hash = "0";
                    if (this.beneficiaryIndexCache.has(hash)) {
                        return this.beneficiaryIndexCache.get(hash)!.observable;
                    } else {
                        let beneficiaryIndexSubject: BehaviorSubject<0> = new BehaviorSubject(0);
                        let beneficiaryIndex = this.initiateStream(beneficiaryIndexSubject, this.beneficiaryService.beneficiaryGet(), BeneficiaryExistingResponseSchema);
                        this.beneficiaryIndexCache.set(hash, new RestCacheEntry<BeneficiaryExistingResponse>(beneficiaryIndex, beneficiaryIndexSubject));
                        return beneficiaryIndex;
                    }
                }

                public invalidatebeneficiaryIndex() {
                    this.beneficiaryIndexCache.forEach((cacheEntry) => {
                        cacheEntry.subject.next(0);
                    })
                }








                    public beneficiaryLetpensionDraftPost(beneficiaryDraftRequest?: BeneficiaryDraftRequest) : Observable<any> {
                        return this.beneficiaryService.beneficiaryLetpensionDraftPost(beneficiaryDraftRequest).pipe(
                            map(value => {
                                
                                
                                return value;
                            })
                        );
                    }




                public beneficiaryLetpensionGet() : Observable<BeneficiaryExistingResponse> {
                    let hash = "0";
                    if (this.beneficiaryletpensionIndexCache.has(hash)) {
                        return this.beneficiaryletpensionIndexCache.get(hash)!.observable;
                    } else {
                        let beneficiaryletpensionIndexSubject: BehaviorSubject<0> = new BehaviorSubject(0);
                        let beneficiaryletpensionIndex = this.initiateStream(beneficiaryletpensionIndexSubject, this.beneficiaryService.beneficiaryLetpensionGet(), BeneficiaryExistingResponseSchema);
                        this.beneficiaryletpensionIndexCache.set(hash, new RestCacheEntry<BeneficiaryExistingResponse>(beneficiaryletpensionIndex, beneficiaryletpensionIndexSubject));
                        return beneficiaryletpensionIndex;
                    }
                }

                public invalidatebeneficiaryletpensionIndex() {
                    this.beneficiaryletpensionIndexCache.forEach((cacheEntry) => {
                        cacheEntry.subject.next(0);
                    })
                }






                public beneficiaryLetpensionRulesGet() : Observable<BeneficiaryRules> {
                    let hash = "0";
                    if (this.beneficiaryletpensionrulesIndexCache.has(hash)) {
                        return this.beneficiaryletpensionrulesIndexCache.get(hash)!.observable;
                    } else {
                        let beneficiaryletpensionrulesIndexSubject: BehaviorSubject<0> = new BehaviorSubject(0);
                        let beneficiaryletpensionrulesIndex = this.initiateStream(beneficiaryletpensionrulesIndexSubject, this.beneficiaryService.beneficiaryLetpensionRulesGet(), BeneficiaryRulesSchema);
                        this.beneficiaryletpensionrulesIndexCache.set(hash, new RestCacheEntry<BeneficiaryRules>(beneficiaryletpensionrulesIndex, beneficiaryletpensionrulesIndexSubject));
                        return beneficiaryletpensionrulesIndex;
                    }
                }

                public invalidatebeneficiaryletpensionrulesIndex() {
                    this.beneficiaryletpensionrulesIndexCache.forEach((cacheEntry) => {
                        cacheEntry.subject.next(0);
                    })
                }









                    public beneficiaryLetpensionSign1Put(beneficiaryCreateRequest?: BeneficiaryCreateRequest) : Observable<GodkendSvar> {
                        return this.beneficiaryService.beneficiaryLetpensionSign1Put(beneficiaryCreateRequest).pipe(
                            map(value => {
                                
                                
                                        this.throwOrHandleValidationError(GodkendSvarSchema, value);
                                return value;
                            })
                        );
                    }





                    public beneficiaryLetpensionSignPost(beneficiarySignRequest?: BeneficiarySignRequest) : Observable<any> {
                        return this.beneficiaryService.beneficiaryLetpensionSignPost(beneficiarySignRequest).pipe(
                            map(value => {
                                
                                
                                return value;
                            })
                        );
                    }






                    public beneficiaryLogbeneficiaryselectionPost(beneficiaryPolicies?: BeneficiaryPolicies) : Observable<SuccessResponse> {
                        return this.beneficiaryService.beneficiaryLogbeneficiaryselectionPost(beneficiaryPolicies).pipe(
                            map(value => {
                                
                                
                                        this.throwOrHandleValidationError(SuccessResponseSchema, value);
                                return value;
                            })
                        );
                    }




                public beneficiaryRulesGet() : Observable<BeneficiaryRules> {
                    let hash = "0";
                    if (this.beneficiaryrulesIndexCache.has(hash)) {
                        return this.beneficiaryrulesIndexCache.get(hash)!.observable;
                    } else {
                        let beneficiaryrulesIndexSubject: BehaviorSubject<0> = new BehaviorSubject(0);
                        let beneficiaryrulesIndex = this.initiateStream(beneficiaryrulesIndexSubject, this.beneficiaryService.beneficiaryRulesGet(), BeneficiaryRulesSchema);
                        this.beneficiaryrulesIndexCache.set(hash, new RestCacheEntry<BeneficiaryRules>(beneficiaryrulesIndex, beneficiaryrulesIndexSubject));
                        return beneficiaryrulesIndex;
                    }
                }

                public invalidatebeneficiaryrulesIndex() {
                    this.beneficiaryrulesIndexCache.forEach((cacheEntry) => {
                        cacheEntry.subject.next(0);
                    })
                }









                    public beneficiarySign1Put(beneficiaryCreateRequest?: BeneficiaryCreateRequest) : Observable<GodkendSvar> {
                        return this.beneficiaryService.beneficiarySign1Put(beneficiaryCreateRequest).pipe(
                            map(value => {
                                
                                
                                        this.throwOrHandleValidationError(GodkendSvarSchema, value);
                                return value;
                            })
                        );
                    }





                    public beneficiarySignPost(beneficiarySignRequest?: BeneficiarySignRequest) : Observable<any> {
                        return this.beneficiaryService.beneficiarySignPost(beneficiarySignRequest).pipe(
                            map(value => {
                                
                                
                                return value;
                            })
                        );
                    }




}
