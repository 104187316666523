/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';


// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration, BasePaths }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class UploadskadesanmeldelsedocumentService {

    public basePath = 'http://localhost/ds/api/temp';
    public pathConfigurations?: BasePaths;
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (configuration?.basePathOverride) {
            this.pathConfigurations = configuration.basePathOverride('uploadskadesanmeldelsedocument')
        }
        else if (typeof this.configuration.basePath !== 'string') {
            if (Array.isArray(basePath) && basePath.length > 0) {
                basePath = basePath[0];
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
            this.basePath = basePath;
        } else {
            this.basePath = this.configuration.basePath
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }

    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * @param id Survey uuid
     * @param category Document category
     * @param metadata Document category id
     * @param documents 
     * @param charset 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public uploadSkadesanmeldelseHsDocumentPost(id: string, category: string, metadata: string, documents: Blob, charset?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any>;
    public uploadSkadesanmeldelseHsDocumentPost(id: string, category: string, metadata: string, documents: Blob, charset?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpResponse<any>>;
    public uploadSkadesanmeldelseHsDocumentPost(id: string, category: string, metadata: string, documents: Blob, charset?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpEvent<any>>;
    public uploadSkadesanmeldelseHsDocumentPost(id: string, category: string, metadata: string, documents: Blob, charset?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling uploadSkadesanmeldelseHsDocumentPost.');
        }
        if (category === null || category === undefined) {
            throw new Error('Required parameter category was null or undefined when calling uploadSkadesanmeldelseHsDocumentPost.');
        }
        if (metadata === null || metadata === undefined) {
            throw new Error('Required parameter metadata was null or undefined when calling uploadSkadesanmeldelseHsDocumentPost.');
        }
        if (documents === null || documents === undefined) {
            throw new Error('Required parameter documents was null or undefined when calling uploadSkadesanmeldelseHsDocumentPost.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (id !== undefined && id !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>id, 'id');
        }
        if (category !== undefined && category !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>category, 'category');
        }
        if (metadata !== undefined && metadata !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>metadata, 'metadata');
        }

        let localVarHeaders = this.defaultHeaders;
        if (charset !== undefined && charset !== null) {
            localVarHeaders = localVarHeaders.set('charset', String(charset));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let localVarFormParams: { append(param: string, value: any): any; };
        let localVarUseForm = false;
        let localVarConvertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        localVarUseForm = canConsumeForm;
        if (localVarUseForm) {
            localVarFormParams = new FormData();
        } else {
            localVarFormParams = new HttpParams({encoder: this.encoder});
        }

        if (documents !== undefined) {
            localVarFormParams = localVarFormParams.append('documents', <any>documents) as any || localVarFormParams;
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/upload/skadesanmeldelse/hs/document`;
        let path = this.basePath;
        if (this.pathConfigurations) {
            path = this.pathConfigurations.exceptions?.get('uploadSkadesanmeldelseHsDocumentPost') || this.pathConfigurations.base;
        }
        return this.httpClient.request<any>('post', `${path}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: localVarConvertFormParamsToString ? localVarFormParams.toString() : localVarFormParams,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param id Survey uuid
     * @param category Document category
     * @param metadata Document category id
     * @param documents 
     * @param charset 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public uploadSkadesanmeldelseKsDocumentPost(id: string, category: string, metadata: string, documents: Blob, charset?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any>;
    public uploadSkadesanmeldelseKsDocumentPost(id: string, category: string, metadata: string, documents: Blob, charset?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpResponse<any>>;
    public uploadSkadesanmeldelseKsDocumentPost(id: string, category: string, metadata: string, documents: Blob, charset?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpEvent<any>>;
    public uploadSkadesanmeldelseKsDocumentPost(id: string, category: string, metadata: string, documents: Blob, charset?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling uploadSkadesanmeldelseKsDocumentPost.');
        }
        if (category === null || category === undefined) {
            throw new Error('Required parameter category was null or undefined when calling uploadSkadesanmeldelseKsDocumentPost.');
        }
        if (metadata === null || metadata === undefined) {
            throw new Error('Required parameter metadata was null or undefined when calling uploadSkadesanmeldelseKsDocumentPost.');
        }
        if (documents === null || documents === undefined) {
            throw new Error('Required parameter documents was null or undefined when calling uploadSkadesanmeldelseKsDocumentPost.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (id !== undefined && id !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>id, 'id');
        }
        if (category !== undefined && category !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>category, 'category');
        }
        if (metadata !== undefined && metadata !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>metadata, 'metadata');
        }

        let localVarHeaders = this.defaultHeaders;
        if (charset !== undefined && charset !== null) {
            localVarHeaders = localVarHeaders.set('charset', String(charset));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let localVarFormParams: { append(param: string, value: any): any; };
        let localVarUseForm = false;
        let localVarConvertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        localVarUseForm = canConsumeForm;
        if (localVarUseForm) {
            localVarFormParams = new FormData();
        } else {
            localVarFormParams = new HttpParams({encoder: this.encoder});
        }

        if (documents !== undefined) {
            localVarFormParams = localVarFormParams.append('documents', <any>documents) as any || localVarFormParams;
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/upload/skadesanmeldelse/ks/document`;
        let path = this.basePath;
        if (this.pathConfigurations) {
            path = this.pathConfigurations.exceptions?.get('uploadSkadesanmeldelseKsDocumentPost') || this.pathConfigurations.base;
        }
        return this.httpClient.request<any>('post', `${path}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: localVarConvertFormParamsToString ? localVarFormParams.toString() : localVarFormParams,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param id Survey uuid
     * @param category Document category
     * @param metadata Document category id
     * @param documents 
     * @param charset 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public uploadSkadesanmeldelseTaeDocumentPost(id: string, category: string, metadata: string, documents: Blob, charset?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any>;
    public uploadSkadesanmeldelseTaeDocumentPost(id: string, category: string, metadata: string, documents: Blob, charset?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpResponse<any>>;
    public uploadSkadesanmeldelseTaeDocumentPost(id: string, category: string, metadata: string, documents: Blob, charset?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpEvent<any>>;
    public uploadSkadesanmeldelseTaeDocumentPost(id: string, category: string, metadata: string, documents: Blob, charset?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling uploadSkadesanmeldelseTaeDocumentPost.');
        }
        if (category === null || category === undefined) {
            throw new Error('Required parameter category was null or undefined when calling uploadSkadesanmeldelseTaeDocumentPost.');
        }
        if (metadata === null || metadata === undefined) {
            throw new Error('Required parameter metadata was null or undefined when calling uploadSkadesanmeldelseTaeDocumentPost.');
        }
        if (documents === null || documents === undefined) {
            throw new Error('Required parameter documents was null or undefined when calling uploadSkadesanmeldelseTaeDocumentPost.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (id !== undefined && id !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>id, 'id');
        }
        if (category !== undefined && category !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>category, 'category');
        }
        if (metadata !== undefined && metadata !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>metadata, 'metadata');
        }

        let localVarHeaders = this.defaultHeaders;
        if (charset !== undefined && charset !== null) {
            localVarHeaders = localVarHeaders.set('charset', String(charset));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let localVarFormParams: { append(param: string, value: any): any; };
        let localVarUseForm = false;
        let localVarConvertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        localVarUseForm = canConsumeForm;
        if (localVarUseForm) {
            localVarFormParams = new FormData();
        } else {
            localVarFormParams = new HttpParams({encoder: this.encoder});
        }

        if (documents !== undefined) {
            localVarFormParams = localVarFormParams.append('documents', <any>documents) as any || localVarFormParams;
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/upload/skadesanmeldelse/tae/document`;
        let path = this.basePath;
        if (this.pathConfigurations) {
            path = this.pathConfigurations.exceptions?.get('uploadSkadesanmeldelseTaeDocumentPost') || this.pathConfigurations.base;
        }
        return this.httpClient.request<any>('post', `${path}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: localVarConvertFormParamsToString ? localVarFormParams.toString() : localVarFormParams,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
