/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { InForceSelectionNumbers } from './inForceSelectionNumbers';
import { InForceSelectionNumbersSchema } from './inForceSelectionNumbers';
import * as JoiObj from "joi";


    export const InForceSelectionForsikringSchema = JoiObj.object({
        selection:
            
                    JoiObj.number()
                

.allow(null)



        ,
        defaultSelection:
            
                    JoiObj.number()
                

.allow(null)



        ,
        employeeNumber:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        inForceSelectionType:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        passiveAcceptance:
            
                    JoiObj.boolean()
                


.required()


        ,
        policyId:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        timestamp:
            
            JoiObj.date()

.allow(null)



        ,
        recommendation:
            
                    JoiObj.number()
                

.allow(null)



        ,
        accepted:
            
                    JoiObj.boolean()
                

.allow(null)



        
    })
.id('InForceSelectionForsikringSchema')

export interface InForceSelectionForsikring extends InForceSelectionNumbers { 
    accepted?: boolean;
    selection?: number;
    defaultSelection?: number;
    recommendation?: number;
}

