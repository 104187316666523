import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'co-primary-number',
  templateUrl: './primary-number.component.html',
  styleUrls: ['./primary-number.component.scss']
})
export class PrimaryNumberComponent implements OnInit {
  @Input() amount: number | string;
  @Input() unit: string;
  @Input() unitSubst: number | string;
  @Input() testId: string;
  @Input() showDecimals: boolean;
  @Input() formatString: string;
  @Input() disabled?: boolean = false;

  format: string;

  ngOnInit(): void {
    this.unit = this.unit !== undefined ? this.unit : 'Global.kroner';
    if (this.showDecimals || this.formatString) {
      if (this.formatString) {
        this.format = this.formatString;
      } else {
        this.format = '1.2-2';
      }
    } else {
      this.format = '1.0-0';
    }
  }

  isNumeric(amount: number | string): boolean {
    return typeof amount !== 'string';
  }
}
