import { Component, Inject, OnInit, inject } from '@angular/core';
import {
  MAT_SNACK_BAR_DATA,
  MatSnackBarRef
} from '@angular/material/snack-bar';
import { filter } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';
import { NotificationType } from '../../services/notification.service';

interface SnackBarData {
  message: string;
  type: string;
  showCloseButton?: boolean;
}

@Component({
  selector: 'co-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss']
})
export class SnackBarComponent implements OnInit {
  snackBarData: SnackBarData;
  snackBarRef = inject(MatSnackBarRef);
  NotificationType = NotificationType;

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) data: SnackBarData,
    private readonly router: Router
  ) {
    this.snackBarData = data;
  }

  ngOnInit() {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((state: NavigationEnd) => {
        this.snackBarRef.dismiss();
      });
  }
}
