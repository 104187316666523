import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { CoreModule } from '@pfa/core';
import { PfaFormModule } from '@pfaform';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { IconModule } from '@pfa/icons';
import { SavingsComponent } from './savings.component';
import { SavingsRoutingModule } from './savings-routing.module';
import { SavingsGuard } from '@pfa/handler';
import { SavingsPolicyComponent } from './savings-policy/savings-policy.component';
import { SavingsPolicyDetailsComponent } from './savings-policy/savings-policy-details/savings-policy-details.component';
import { SavingsPolicyKeyNumbersComponent } from './savings-policy/savings-policy-key-numbers/savings-policy-key-numbers.component';
import { SavingsPolicyCostsComponent } from './savings-policy/savings-policy-costs/savings-policy-costs.component';
import { SavingsPolicyPerProductComponent } from './savings-policy/savings-policy-per-product/savings-policy-per-product.component';
import { SavingsPolicyPerConceptComponent } from './savings-policy/savings-policy-per-concept/savings-policy-per-concept.component';
import { SavingsPolicyDetailsLineElementComponent } from './savings-policy/savings-policy-details/savings-policy-details-line-element/savings-policy-details-line-element.component';
import { SavingsPolicyDetailsSimpleComponent } from './savings-policy/savings-policy-details/savings-policy-details-simple/savings-policy-details-simple.component';
import { SavingsPolicyDetailsAveragerateComponent } from './savings-policy/savings-policy-details/savings-policy-details-averagerate/savings-policy-details-averagerate.component';
import { SavingsPolicyDetailsMiscComponent } from './savings-policy/savings-policy-details/savings-policy-details-misc/savings-policy-details-misc.component';
import { SavingsPolicyDetailsMarketrateComponent } from './savings-policy/savings-policy-details/savings-policy-details-marketrate/savings-policy-details-marketrate.component';
import { MarketrateAdmComponent } from './savings-policy/savings-policy-details/savings-policy-details-marketrate/marketrate-adm/marketrate-adm.component';
import { MarketrateSubheaderBlockComponent } from './savings-policy/savings-policy-details/savings-policy-details-marketrate/marketrate-subheader-block/marketrate-subheader-block.component';
import { MarketrateMiscComponent } from './savings-policy/savings-policy-details/savings-policy-details-marketrate/marketrate-misc/marketrate-misc.component';
import { SavingsPolicyDetailsTransferBackComponent } from './savings-policy/savings-policy-details/savings-policy-details-transfer-back/savings-policy-details-transfer-back.component';
import { MarketrateTransfersComponent } from './savings-policy/savings-policy-details/savings-policy-details-marketrate/marketrate-transfers/marketrate-transfers.component';
import { MarketrateTransfersPopupComponent } from './savings-policy/savings-policy-details/savings-policy-details-marketrate/marketrate-transfers-popup/marketrate-transfers-popup.component';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    MatButtonModule,
    SavingsRoutingModule,
    PfaFormModule,
    MatSelectModule,
    IconModule,
    MatFormFieldModule,
    MatDialogModule
  ],
  declarations: [
    SavingsComponent,
    SavingsPolicyComponent,
    SavingsPolicyDetailsComponent,
    SavingsPolicyKeyNumbersComponent,
    SavingsPolicyCostsComponent,
    SavingsPolicyPerProductComponent,
    SavingsPolicyPerConceptComponent,
    SavingsPolicyDetailsLineElementComponent,
    SavingsPolicyDetailsSimpleComponent,
    SavingsPolicyDetailsAveragerateComponent,
    SavingsPolicyDetailsMiscComponent,
    SavingsPolicyDetailsMarketrateComponent,
    MarketrateAdmComponent,
    MarketrateSubheaderBlockComponent,
    MarketrateMiscComponent,
    SavingsPolicyDetailsTransferBackComponent,
    MarketrateTransfersComponent,
    MarketrateTransfersPopupComponent
  ],
  providers: [SavingsGuard],
  exports: [SavingsComponent]
})
export class SavingsModule {}
