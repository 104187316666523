/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { GenerelEksternOverfoerselAdvarsel } from './generelEksternOverfoerselAdvarsel';
import { GenerelEksternOverfoerselAdvarselSchema } from './generelEksternOverfoerselAdvarsel';
import { Rekvirentnummer } from './rekvirentnummer';
import { RekvirentnummerSchema } from './rekvirentnummer';
import { AftaleAnbefaling } from './aftaleAnbefaling';
import { AftaleAnbefalingSchema } from './aftaleAnbefaling';
import { BooleanType } from './booleanType';
import { BooleanTypeSchema } from './booleanType';
import * as JoiObj from "joi";


    export const EksternOverfoerselAnbefalingSchema = JoiObj.object({
        advarsler:
                JoiObj.array().items(
        
        JoiObj.link('#GenerelEksternOverfoerselAdvarselSchema')

        )
        

.required()


        ,
        bestillingsUdloebsDato:
            
            JoiObj.date()


.required()


        ,
        maeglerBetjent:
            
                    JoiObj.boolean()
                


.required()


        ,
        modtagendeAftaleFirma:
            
                    JoiObj.boolean()
                


.required()


        ,
        modtagendeAftaleId:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        modtagendeAftaleObligatorisk:
            
                    JoiObj.boolean()
                


.required()


        ,
        modtagendeAftalegyldig:
            
                    JoiObj.boolean()
                


.required()


        ,
        policer:
                JoiObj.array().items(
        
        JoiObj.link('#AftaleAnbefalingSchema')

        )
        

.required()


        ,
        rekvirentnummer:
            
        JoiObj.link('#RekvirentnummerSchema')

.allow(null)



        ,
        selskabIds:
                JoiObj.array().items(
        
                    JoiObj.number()
                .integer()

        )
        

.required()


        ,
        syg:
            
        JoiObj.link('#BooleanTypeSchema')

.allow(null)



        ,
        visAldersAdvarsel:
            
                    JoiObj.boolean()
                


.required()


        
    })
                .shared(GenerelEksternOverfoerselAdvarselSchema)
                .shared(AftaleAnbefalingSchema)
                .shared(RekvirentnummerSchema)
                .shared(BooleanTypeSchema)
.id('EksternOverfoerselAnbefalingSchema')

export interface EksternOverfoerselAnbefaling { 
    advarsler: Array<GenerelEksternOverfoerselAdvarsel>;
    bestillingsUdloebsDato: Date;
    maeglerBetjent: boolean;
    modtagendeAftaleFirma: boolean;
    modtagendeAftaleId: string;
    modtagendeAftaleObligatorisk: boolean;
    modtagendeAftalegyldig: boolean;
    policer: Array<AftaleAnbefaling>;
    rekvirentnummer?: Rekvirentnummer;
    selskabIds: Array<number>;
    syg?: BooleanType;
    visAldersAdvarsel: boolean;
}

