import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MyCommonModule } from '@pfa/common';
import { CoreModule } from '@pfa/core';
import { PrerequisitesComponent } from './prerequisites.component';
import { PrerequisitesRoutingModule } from './prerequisites-routing.module';
import { PfaFormModule } from '@pfaform';
import { IconModule } from '@pfa/icons';

@NgModule({
  imports: [
    CommonModule,
    MyCommonModule,
    CoreModule,
    PfaFormModule,
    IconModule,
    PrerequisitesRoutingModule
  ],
  declarations: [PrerequisitesComponent]
})
export class PrerequisitesModule {}
