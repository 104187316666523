import { UntypedFormControl } from '@angular/forms';
import {
  IndbetalingKonceptFordeling,
  OpsparingKonceptFordeling,
  IndbetalingFondFordeling,
  Fond,
  OpsparingFondFordeling,
  FondFordeling,
  KonceptFordeling,
  DuInvesterer,
  InvesteringPolice,
  PoliceMulighederSvar
} from '@pfa/gen';

export enum AllocationType {
  DEPOSIT = 'deposit',
  SAVING = 'saving'
}
export interface Fund extends Fond {
  linkUrl?: string;
  expanded?: boolean;
  add?: boolean;
  order?: number;
}

export interface FundAllocation extends FondFordeling {
  aktieFonde: Fund[];
  obligationFonde: Fund[];
  profilFondeOgBlandetKategoriFonde: Fund[];
  oevrigeFonde: Fund[];
}

export interface YouInvestSimpleAllocation {
  simpleDepositYouInvestPercentage?: number;
  simpleSavingsYouInvestPercentage?: number;
}

export interface ConceptText {
  id: string;
  substId?: number;
}

export interface DepositAllocation extends IndbetalingKonceptFordeling {
  type?: AllocationType;
  disabled?: boolean;
  texts?: ConceptText[];
}

export interface SavingAllocation extends OpsparingKonceptFordeling {
  type?: AllocationType;
  disabled?: boolean;
  texts?: ConceptText[];
}

export interface SimpleDistribution {
  simpelIndbetaling: DepositAllocation;
  simpelOpsparing: SavingAllocation;
}

export interface ExtendedConcepts {
  arbejdsgiverObligatoriskIndbetaling: DepositAllocation;
  medarbejderObligatoriskIndbetaling: DepositAllocation;
  medarbejderFrivilligIndbetaling: DepositAllocation;
  blandetIndbetaling: DepositAllocation;
  arbejdsgiverObligatoriskOpsparing: SavingAllocation;
  medarbejderObligatoriskOpsparing: SavingAllocation;
  medarbejderFrivilligOpsparing: SavingAllocation;
  blandetOpsparing: SavingAllocation;
}

export type YouInvest = SimpleDistribution & ExtendedConcepts & DuInvesterer;

export interface InvestmentPolice extends InvesteringPolice {
  duInvesterer: YouInvest;
}

export interface PolicePossiblesAnswer extends PoliceMulighederSvar {
  police: InvestmentPolice;
}

export interface DepositFundAllocation
  extends IndbetalingFondFordeling,
    FundAllocationExtras {
  mulige: FundAllocation;
  fordeling: FundAllocation;
  origManuelInvestering?: boolean;
}

export interface SavingFundAllocation
  extends OpsparingFondFordeling,
    FundAllocationExtras {
  muligeFordeling: FundAllocation;
  muligeKoebOgSalg: FundAllocation;
  fordeling: FundAllocation;
  manuelInvestering?: boolean;
}
export interface FundAllocationExtras {
  type?: AllocationType;
  totalSavings?: number; //totalOpsparing in legacy
  totalShare?: number; //totalAndel in legacy
  newTotalSharePercentage?: number;
  newTotalBuySharePercentage?: number;
  newTotalSellSharePercentage?: number;
  buyAndSell?: boolean; //youInvest.opsparingFondFordeling.koebSalg in legacy
  disabled?: boolean; //indbetalingFondFordeling.disabled and opsparingFondFordeling.disabled in legacy
  fundsValid?: boolean; //youInvest.opsparingFondFordeling.fondeValid in legacy
  hasSelectedAnyFunds?: boolean;
  hasFundChange?: boolean;
  hasClosedFunds?: boolean;
  hasRestrictedFunds?: boolean;
  buyAndSellDisabled?: boolean;
  buyAndSellValid?: boolean;
  hasMixedFunds?: boolean;
  hasMissingFundInput?: boolean;
}

export interface FundArrayTypeAllocation {
  funds?: Fund[];
  type?: 'SHARES' | 'BONDS' | 'PROFILE_MIX' | 'OTHERS';
  headerText: string;
}

export interface ConceptAllocation extends KonceptFordeling {
  procent: number;
  beloeb: number;
}

export interface ConceptInfo {
  item: DepositAllocation | SavingAllocation;
  youInvest: number;
  pfaInvest: number;
  percent: UntypedFormControl;
  amount?: UntypedFormControl;
}
