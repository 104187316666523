<co-card>
  <div class="row">
    <!-- ILLUSTRATION -->
    <div class="col-12 col-sm-4 align--center">
      <div
        *ngIf="!allowedAccess.changeBeneficiary && !allowedAccess.viewDetails"
      >
        <img
          class="beneficiary-current-image"
          src="/assets/images/begunstigelse_kontakt.png"
          alt="image - current type"
        />
        <div class="beneficiary-type beneficiary-type__small">
          <div class="inline-block">
            <div
              *ngIf="!beneficiaryData.broker"
              class="text--note"
              [coContent]="'DL.AB01.C26'"
            >
              PFA rådgivningscenter
            </div>
            <div *ngIf="beneficiaryData.broker" class="text--note">
              {{ beneficiaryData.broker.name }}
            </div>
            {{ fallbackNumber }}
          </div>
        </div>
      </div>
      <div *ngIf="allowedAccess.changeBeneficiary || allowedAccess.viewDetails">
        <div
          *ngIf="
            beneficiaryData &&
            beneficiaryData.beneficiaries &&
            beneficiaryData.beneficiaries.length === 1
          "
        >
          <co-beneficiary-icon
            [beneficiary]="beneficiaryData.beneficiaries[0]"
          ></co-beneficiary-icon>
        </div>
        <div
          *ngIf="
            beneficiaryData &&
            beneficiaryData.beneficiaries &&
            beneficiaryData.beneficiaries.length > 1
          "
        >
          <co-beneficiary-icon
            *ngFor="let beneficiary of beneficiaryData.beneficiaries"
            [beneficiary]="beneficiary"
            class="beneficiary-current-image--multiple"
          ></co-beneficiary-icon>
        </div>
        <div
          class="beneficiary-type beneficiary-type__small"
          *ngIf="
            beneficiaryData &&
            beneficiaryData.beneficiaries &&
            beneficiaryData.beneficiaries.length === 1
          "
        >
          <div class="inline-block">
            <div class="text--note" [coContent]="'DL.AB01.C22'">
              Dit nuværende valg
            </div>
            <div
              *ngIf="
                beneficiaryData.beneficiaries[0].converted &&
                beneficiaryData.beneficiaries[0].type === 'OTHER'
              "
            >
              -
            </div>
            <div
              *ngIf="
                !(
                  beneficiaryData.beneficiaries[0].converted &&
                  beneficiaryData.beneficiaries[0].type === 'OTHER'
                )
              "
            >
              {{ getBeneficiaryTypeText(beneficiaryData.beneficiaries[0]) }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- TEXT-DESCRIPTION -->
    <div class="col-12 col-sm-8">
      <div *ngIf="allowedAccess.changeBeneficiary || allowedAccess.viewDetails">
        <p
          class="beneficiary-type"
          *ngIf="
            beneficiaryData &&
            beneficiaryData.beneficiaries &&
            beneficiaryData.beneficiaries.length > 1
          "
          [coContent]="'DL.AB01.C21'"
        ></p>
        <p
          [coContent]="isLetpension ? 'DL.AB01.C30' : 'DL.AB01.C04'"
          *ngIf="allowedAccess.changeBeneficiary"
        ></p>
        <p
          [coContent]="'DL.AB01.C17'"
          *ngIf="!allowedAccess.changeBeneficiary"
        ></p>
        <p *ngIf="!isLetpension" coContent="DL.AB01.C29"></p>
        <div [coContent]="'DL.AB01.C19'" *ngIf="!isLetpension"></div>
        <div [coContent]="'DL.AB01.C33'" *ngIf="isLetpension"></div>
      </div>

      <div
        *ngIf="!allowedAccess.changeBeneficiary && !allowedAccess.viewDetails"
      >
        <p [coContent]="'DL.AB01.C23'">
          Vi kan desværre ikke vise din begunstigelse på nuværende tidspunkt.
        </p>
        <p [coContent]="'DL.AB01.C24'">
          Det kan skyldes, at du har særlige ordninger hos PFA eller policer,
          som ikke kan begunstiges.
        </p>
        <p [coContent]="'DL.AB01.C25'">
          Nedenfor finder du både link til PFA.dk, hvor du kan læse mere om
          begunstigelse samt telefonnummer til vores rådgivningscenter, som vil
          kunne fortælle mere om din ordning og evt. eksisterende begunstigelse
        </p>
        <div [coContent]="'DL.AB01.C19'" *ngIf="!isLetpension"></div>
        <div [coContent]="'DL.AB01.C33'" *ngIf="isLetpension"></div>
      </div>
    </div>
  </div>

  <button
    mat-raised-button
    class="float--right"
    (click)="gotoBeneficiary()"
    *ngIf="allowedAccess.changeBeneficiary || allowedAccess.viewDetails"
  >
    <span [coContent]="'DL.AB01.C15'" *ngIf="allowedAccess.changeBeneficiary"
      >change</span
    >
    <span [coContent]="'DL.AB01.C14'" *ngIf="!allowedAccess.changeBeneficiary"
      >view</span
    >
    <co-icon-arrow-right class="icon icon--arrow-right"></co-icon-arrow-right>
  </button>
  <a
    class="mat-raised-button float--right"
    href="tel:{{ fallbackNumber }}"
    *ngIf="!allowedAccess.changeBeneficiary && !allowedAccess.viewDetails"
  >
    <co-icon-phone class="icon icon--arrow-right"></co-icon-phone>
    <span>{{ fallbackNumber }}</span>
  </a>
  <div class="cf"></div>
</co-card>

<div
  class="beneficiary-excluded"
  *ngIf="
    (allowedAccess.changeBeneficiary || allowedAccess.viewDetails) &&
    allowedAccess.policiesWithoutData &&
    allowedAccess.policiesWithoutData.length > 0
  "
>
  <span [coContent]="'DL.BG01.C166'"
    >Følgende policer er ikke omfattet af ovenstående begunstigelse:</span
  >
  <span *ngFor="let policyId of allowedAccess.policiesWithoutData; index as i"
    ><span *ngIf="i > 0">,</span>&nbsp;{{ policyId }}</span
  >
</div>
