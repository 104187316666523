/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PaymentPriceGroup } from './paymentPriceGroup';
import { PaymentPriceGroupSchema } from './paymentPriceGroup';
import * as JoiObj from "joi";


    export const GennemsnitsrenteOmkostningSchema = JoiObj.object({
        beloeb:
            
                    JoiObj.number()
                


.required()


        ,
        krPolicenummer:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        paymentPriceGroup:
                JoiObj.array().items(
        
        JoiObj.link('#PaymentPriceGroupSchema')

        )
        

.required()


        
    })
                .shared(PaymentPriceGroupSchema)
.id('GennemsnitsrenteOmkostningSchema')

export interface GennemsnitsrenteOmkostning { 
    beloeb: number;
    krPolicenummer?: string;
    paymentPriceGroup: Array<PaymentPriceGroup>;
}

