<co-loading class="loader" [show]="!payoutsDataLoaded || !data"></co-loading>

<a
  *ngIf="payoutsDataLoaded && data"
  class="savings-card-link"
  (click)="trackReturn()"
  [routerLink]="(!(errorFetchingData || allPoliciesWithError) && data)?.link"
  data-test-id="frontpage-savings-savingsReturn"
>
  <co-card
    [showErrorMessage]="errorFetchingData || (payoutsData && policiesWithError)"
    [hideErrorBlocks]="!(errorFetchingData || allPoliciesWithError)"
    class="savings-card card--small"
    [ngClass]="{
      'cursor-pointer': !(errorFetchingData || allPoliciesWithError)
    }"
    *ngIf="payoutsData"
  >
    <co-progress-bar
      class="progress-bar"
      [duration]="animationInterval * 1000 + 300"
      *ngIf="animate && data && !errorFetchingData"
      [observedValue]="showIndex$ | async"
    ></co-progress-bar>
    <ng-template
      *ngTemplateOutlet="savingsReturn; context: { $implicit: payoutsData }"
    ></ng-template>

    <div
      *ngIf="errorFetchingData || (payoutsData && policiesWithError)"
      card-additional
      class="error-overlay"
    >
      <co-icon-error></co-icon-error>
      <span
        [coContent]="
          errorFetchingData || allPoliciesWithError
            ? 'DL.FS03.C39'
            : ('DL.FS03.C42' | content)
        "
      ></span>
    </div>
  </co-card>
  <div class="savings-card" *ngIf="!payoutsData">
    <ng-template
      *ngTemplateOutlet="savingsReturn; context: { $implicit: payoutsData }"
    ></ng-template>
    <co-countdown
      class="countdown"
      [duration]="animationInterval"
      *ngIf="animate && data"
      [observedValue]="showIndex$ | async"
    ></co-countdown>

    <div
      class="blocking-error"
      *ngIf="errorFetchingData || allPoliciesWithError"
    >
      <span class="error-grey-area double"></span>
      <co-error>
        <span [coContent]="'DL.FS03.C39'"></span>
      </co-error>
    </div>

    <div
      class="policiesWithError"
      *ngIf="policiesWithError && !(errorFetchingData || allPoliciesWithError)"
    >
      <co-error>
        <span [coContent]="'DL.FS03.C42'"></span>
      </co-error>
    </div>
  </div>
</a>

<ng-template #savingsReturn let-payoutsData>
  <div class="card-body" *ngIf="data">
    <mitpfa-dashboard-savings-return-card
      class="card-body__content"
      [headingText]="getHeadingText(showIndex$ | async)"
      [hideHeaderArrow]="errorFetchingData || allPoliciesWithError"
      @enterLeave
    >
      <section
        *ngIf="
          !data.onlyInsurances && !(errorFetchingData || allPoliciesWithError);
          else onlyInsurances
        "
      >
        <div *ngIf="(showIndex$ | async) === 0">
          <co-primary-number
            [ngClass]="{ 'size-sm': payoutsData, 'size-lg': !payoutsData }"
            [amount]="data.returns.returnsThisYear"
            [testId]="'frontpage-savings-savingsReturn-returnsThisYear'"
          ></co-primary-number>
          <co-change-indicator
            [ngClass]="{
              'change-indicator': true,
              'change-indicator--zero':
                data.returns.percentageChangeThisYear === 0
            }"
            *ngIf="data.returns.percentageChangeThisYear !== null"
            [value]="data.returns.percentageChangeThisYear"
            unit="%"
          ></co-change-indicator>
          <p
            class="customer-capital-note"
            [ngClass]="{
              'customer-capital-note--with-indicator':
                data.returns.percentageChangeThisYear !== null
            }"
            *ngIf="data.customerCapital.enabled"
            coContent="DL.FS03.C18"
          ></p>
          <p
            *ngIf="data.customerCapital.enabledRecently"
            coContent="DL.FS03.C16"
            [coContentSubst]="
              data.customerCapital.payoutYear +
              '|' +
              (data.customerCapital.toBeDistributed | currencyFormat) +
              '|' +
              (data.customerCapital.payoutYear - 1)
            "
          ></p>
          <co-info *ngIf="hasInvalidPensionInfo">
            <span [coContent]="'DL.PI01.C156'"></span>
            <span>
              <a
                (click)="stopPropagation($event)"
                class="link"
                [href]="'/mine-oplysninger/pensionsinfo'"
                [coContent]="'DL.PI01.C155'"
              >
              </a>
            </span>
          </co-info>
        </div>
        <div *ngIf="(showIndex$ | async) === 1">
          <div>
            <co-primary-number
              [ngClass]="{ 'size-sm': payoutsData, 'size-lg': !payoutsData }"
              [amount]="data.returns.accumulatedReturns"
            ></co-primary-number>
          </div>
          <div coContent="DL.FS03.C18" class="very-nescessary-spacer"></div>
          <p
            *ngIf="data.customerCapital.enabledRecently"
            coContent="DL.FS03.C16"
            [coContentSubst]="
              data.customerCapital.payoutYear +
              '|' +
              (data.customerCapital.toBeDistributed | currencyFormat) +
              '|' +
              (data.customerCapital.payoutYear - 1)
            "
          ></p>
        </div>
      </section>
    </mitpfa-dashboard-savings-return-card>
  </div>
</ng-template>
<ng-template #onlyInsurances *ngIf="!errorFetchingData">
  <p coContent="DL.FS03.C17"></p>
</ng-template>
