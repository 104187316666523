import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localeDa from '@angular/common/locales/da';
import localeEn from '@angular/common/locales/en';
import {
  APP_INITIALIZER,
  ErrorHandler,
  LOCALE_ID,
  NgModule
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { ApiModule } from '@pfa/api';
import { ContentService, CoreModule, NumberFormatPipe } from '@pfa/core';
import { PfaFormModule, GlobalWorkingService } from '@pfaform';
import { DocumentsModule } from '@pfa/feature/documents';
import { MessagingModule } from '@pfa/feature/messaging';
import { SharedErrorHandler, ErrorModule } from '@pfa/feature/error';
import { DepositsMonthlyModule } from '@pfa/feature/deposits-monthly';
import { SinglePolicyReturnModule } from '@pfa/feature/single-policy-return';
import {
  OnboardingGuard,
  CombineInternalGuard,
  DynamicadvisingGuard,
  PensioncheckGuard,
  UnsavedChangesGuard,
  UserGuard
} from '@pfa/handler';
import { IconModule } from '@pfa/icons';
import { FusionChartsModule } from 'angular-fusioncharts';
import * as FusionCharts from 'fusioncharts';
import * as Charts from 'fusioncharts/fusioncharts.charts';
import * as Theme from 'libs/pfa-form/src/lib/fusioncharts.theme.pfa.js';
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';
import { DynamicadvisingStore, ApiModule as GenApiModule } from '@pfa/gen';
import { OnboardingModule } from './onboarding/onboarding.module';
import { AdvantagesModule } from './advantages/advantages.module';
import { AdvisorMenuModule } from './advisor-menu/advisor-menu.module';
import { AdvisorWarningModalComponent } from './advisor-warning/advisor-warning-modal/advisor-warning-modal.component';
import {
  mineaendringerServiceProvider,
  opsparingServiceProvider,
  tjeklisteServiceProvider
} from './ajs-upgraded-providers';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ChatModule } from './chat/chat.module';
import { ContactDetailsComponent } from './contact-details/contact-details.component';
import { CustomerSurveyModule } from './customer-survey/customer-survey.module';
import { CustomerSurveyService } from './customer-survey/customer-survey.service';
import { DashboardModule } from './dashboard/dashboard.module';
import { HeaderModule } from './header/header.module';
import { LogonModule } from './logon/logon.module';
import { MitPfaMessagingWrapperComponent } from './messaging/mit-pfa-messaging-wrapper.component';
import { MobileBridgeModule } from './mobile-bridge/mobile-bridge.module';
import { NewBusinessAdvisoryModule } from './new-business-advisory/new-business-advisory.module';
import { OperationStatusModule } from './operation-status/operation-status.module';
import { OrderPincodeModule } from './order-pincode/order-pincode.module';
import { PincodeModule } from './pincode/pincode.module';
import { PrerequisitesModule } from './prerequisites/prerequisites.module';
import { ProofOfIdentityModule } from './proof-of-identity/proof-of-identity.module';
import { SavingsModule } from './savings/savings.module';
import { LogoutModule } from './logout/logout.module';
import { BookingPortalModule } from './booking-portal/booking-portal.module';
import {
  CacheBusterInterceptorService,
  DashboardGuard,
  DynamicAdvisingInterceptorService,
  LightGuard,
  MitPFARouteReuseStragety,
  MitPFARouteReuseStrategyService,
  RoutingService
} from '@mitpfa/shared';
import { ClimateFootprintModule } from './climate-footprint/climate-footprint.module';
import { DsApiMediaTypeInterceptor, dsApiConfigFactory } from '@pfa/services';
import { environment } from '../environments/environment';
import { LegitimationComponent } from './legitimation/legitimation.component';
import { AdvisorWarningComponent } from './advisor-warning/advisor-warning.component';
import { TrackingComponent } from './tracking/tracking.component';
import { LoggingComponent } from './logging/logging.component';

registerLocaleData(localeDa, 'da');
registerLocaleData(localeEn, 'en');

export function init_app(appLoadService: ContentService) {
  return () => appLoadService.loadAllContent();
}

Charts(FusionCharts);
FusionChartsModule.fcRoot(FusionCharts, Theme);
FusionCharts.options['creditLabel'] = false;

export function customerSurveyServiceFactory(
  customerSurveyService: CustomerSurveyService
): Function {
  return () => customerSurveyService.initConsentDialog();
}

const disableAnimations =
  !('animate' in document.documentElement) ||
  (navigator &&
    /(iPhone OS|iPad; CPU OS) (8|9|10|11|12|13)_/.test(navigator.userAgent));

@NgModule({
  declarations: [
    AppComponent,
    MitPfaMessagingWrapperComponent,
    AdvisorWarningModalComponent,
    ContactDetailsComponent,
    LegitimationComponent,
    AdvisorWarningComponent,
    TrackingComponent,
    LoggingComponent
  ],
  imports: [
    MobileBridgeModule,
    BrowserModule,
    BrowserAnimationsModule.withConfig({ disableAnimations }),
    HttpClientModule,
    CoreModule,
    ApiModule,
    HeaderModule,
    AdvisorMenuModule,
    DashboardModule,
    OperationStatusModule,
    MessagingModule,
    DocumentsModule,
    DepositsMonthlyModule,
    SinglePolicyReturnModule,
    ChatModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    IconModule,
    PfaFormModule,
    MatMomentDateModule,
    NewBusinessAdvisoryModule,
    MatDialogModule,
    MatButtonModule,
    MatTooltipModule,
    MatRadioModule,
    MatSelectModule,
    FusionChartsModule,
    NgxPageScrollCoreModule,
    AdvantagesModule,
    ProofOfIdentityModule,
    PincodeModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    OnboardingModule,
    CustomerSurveyModule,
    SavingsModule,
    PrerequisitesModule,
    LogonModule,
    LogoutModule,
    OrderPincodeModule,
    BookingPortalModule,
    ClimateFootprintModule,
    ErrorModule,
    GenApiModule.forRoot(dsApiConfigFactory),

    // should be imported last, in order not to override urls from other modules
    AppRoutingModule
  ],
  providers: [
    NumberFormatPipe,
    UserGuard,
    CombineInternalGuard,
    PensioncheckGuard,
    DynamicadvisingGuard,
    OnboardingGuard,
    UnsavedChangesGuard,
    LightGuard,
    DashboardGuard,
    GlobalWorkingService,
    {
      provide: APP_INITIALIZER,
      useFactory: init_app,
      deps: [ContentService],
      multi: true
    },
    // not needed until we have .ts making http calls
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CacheBusterInterceptorService,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DynamicAdvisingInterceptorService,
      multi: true,
      deps: [DynamicadvisingStore]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DsApiMediaTypeInterceptor,
      multi: true
    },
    {
      provide: ErrorHandler,
      useClass: SharedErrorHandler
    },
    {
      provide: SharedErrorHandler,
      useExisting: ErrorHandler
    },
    opsparingServiceProvider,
    tjeklisteServiceProvider,
    mineaendringerServiceProvider,
    {
      provide: LOCALE_ID,
      useValue: 'da-DK'
    },
    {
      provide: RouteReuseStrategy,
      useClass: MitPFARouteReuseStragety
    },
    MitPFARouteReuseStrategyService,
    {
      provide: APP_INITIALIZER,
      useFactory: customerSurveyServiceFactory,
      deps: [CustomerSurveyService],
      multi: true
    },
    {
      provide: 'environment',
      useValue: environment
    },
    {
      provide: MatDialogRef,
      useValue: { close: () => {} }
    },
    RoutingService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
