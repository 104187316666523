<table class="align-right--column-2 new-business-advisory-table">
  <thead>
    <tr>
      <th
        colspan="2"
        [coContent]="'DL.TR01.C15'"
        [coContentSubst]="
          overview.partialSchemeType +
          '|' +
          (overview.partialSchemeCompanyName
            ? overview.partialSchemeCompanyName
            : '')
        "
      >
        >
      </th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td [coContent]="'DL.TR01.C16'"></td>
      <td
        class="text--bold"
        [coContent]="'DL.TR01.C17'"
        [coContentSubst]="overview.tidligstIkafttraedelseDato"
      ></td>
    </tr>
    <tr>
      <td [coContent]="'DL.TR01.C18'"></td>
      <td
        class="text--bold"
        [coContent]="'DL.TR01.C19'"
        [coContentSubst]="
          overview.aftaltPensionsAlder + '|' + overview.aftaltPensionsDato
        "
      ></td>
    </tr>

    <tr *ngIf="!overview.privateIndbetaling">
      <td [coContent]="'DL.TR01.C20'"></td>
      <td
        class="text--bold"
        [coContent]="'DL.TR01.C21'"
        [coContentSubst]="overview.arbejdsgiverCvr"
      ></td>
    </tr>
    <tr *ngIf="overview.privateIndbetaling">
      <td colspan="2" [coContent]="'DL.TR01.C22'"></td>
    </tr>

    <tr *ngIf="overview.loen">
      <td [coContent]="'DL.TR01.C23'"></td>
      <td
        class="text--bold"
        [coContent]="'DL.TR01.C24'"
        [coContentSubst]="overview.loen | number: '1.0-0'"
      ></td>
    </tr>

    <ng-container *ngIf="overview.totalIndbetalingPct && hasPremium()">
      <tr
        class="double-row"
        *ngIf="overview.arbejdsgiverObligatoriskIndbetalingPct"
      >
        <td [coContent]="'DL.TR01.C25'"></td>
        <td
          class="text--bold"
          [coContent]="'DL.TR01.C26'"
          [coContentSubst]="
            overview.arbejdsgiverObligatoriskIndbetalingPct | number: '1.0-2'
          "
        ></td>
      </tr>
      <tr *ngIf="overview.arbejdsgiverObligatoriskIndbetalingBeloeb">
        <td class="text--note" [coContent]="'DL.TR01.C27'"></td>
        <td
          class="text--note"
          [coContent]="'DL.TR01.C28'"
          [coContentSubst]="
            overview.arbejdsgiverObligatoriskIndbetalingBeloeb | number: '1.0-0'
          "
        ></td>
      </tr>
      <tr
        class="double-row"
        *ngIf="overview.medarbejderObligatoriskIndbetalingPct"
      >
        <td [coContent]="'DL.TR01.C349'"></td>
        <td
          class="text--bold"
          [coContent]="'DL.TR01.C26'"
          [coContentSubst]="
            overview.medarbejderObligatoriskIndbetalingPct | number: '1.0-2'
          "
        ></td>
      </tr>
      <tr *ngIf="overview.medarbejderObligatoriskIndbetalingBeloeb">
        <td class="text--note" [coContent]="'DL.TR01.C27'"></td>
        <td
          class="text--note"
          [coContent]="'DL.TR01.C28'"
          [coContentSubst]="
            overview.medarbejderObligatoriskIndbetalingBeloeb | number: '1.0-0'
          "
        ></td>
      </tr>
      <tr class="double-row" *ngIf="overview.frivilligIndbetalingPct">
        <td [coContent]="'DL.TR01.C29'"></td>
        <td
          class="text--bold"
          [coContent]="'DL.TR01.C26'"
          [coContentSubst]="overview.frivilligIndbetalingPct | number: '1.0-2'"
        ></td>
      </tr>
      <tr *ngIf="overview.frivilligIndbetalingBeloeb">
        <td class="text--note" [coContent]="'DL.TR01.C27'"></td>
        <td
          class="text--note"
          [coContent]="'DL.TR01.C28'"
          [coContentSubst]="
            overview.frivilligIndbetalingBeloeb | number: '1.0-0'
          "
        ></td>
      </tr>
      <tr class="double-row" *ngIf="overview.totalIndbetalingPct">
        <td class="text--bold" [coContent]="'DL.TR01.C30'"></td>
        <td
          class="text--bold"
          [coContent]="'DL.TR01.C26'"
          [coContentSubst]="overview.totalIndbetalingPct | number: '1.0-2'"
        ></td>
      </tr>
      <tr *ngIf="overview.totalIndbetalingBeloeb">
        <td class="text--note" [coContent]="'DL.TR01.C27'"></td>
        <td
          class="text--note"
          [coContent]="'DL.TR01.C28'"
          [coContentSubst]="overview.totalIndbetalingBeloeb | number: '1.0-0'"
        ></td>
      </tr>
    </ng-container>
    <ng-container *ngIf="!overview.totalIndbetalingPct && hasPremium()">
      <tr *ngIf="overview.arbejdsgiverObligatoriskIndbetalingBeloeb">
        <td [coContent]="'DL.TR01.C25'"></td>
        <td
          class="text--bold"
          [coContent]="'DL.TR01.C31'"
          [coContentSubst]="
            overview.arbejdsgiverObligatoriskIndbetalingBeloeb | number: '1.0-0'
          "
        ></td>
      </tr>
      <tr *ngIf="overview.medarbejderObligatoriskIndbetalingBeloeb">
        <td [coContent]="'DL.TR01.C349'"></td>
        <td
          class="text--bold"
          [coContent]="'DL.TR01.C31'"
          [coContentSubst]="
            overview.medarbejderObligatoriskIndbetalingBeloeb | number: '1.0-0'
          "
        ></td>
      </tr>
      <tr *ngIf="overview.frivilligIndbetalingBeloeb">
        <td [coContent]="'DL.TR01.C29'"></td>
        <td
          class="text--bold"
          [coContent]="'DL.TR01.C31'"
          [coContentSubst]="
            overview.frivilligIndbetalingBeloeb | number: '1.0-0'
          "
        ></td>
      </tr>
      <tr *ngIf="overview.totalIndbetalingBeloeb">
        <td class="text--bold" [coContent]="'DL.TR01.C30'"></td>
        <td
          class="text--bold"
          [coContent]="'DL.TR01.C32'"
          [coContentSubst]="overview.totalIndbetalingBeloeb | number: '1.0-0'"
        ></td>
      </tr>
    </ng-container>
    <ng-container
      *ngIf="
        overview.policyType === 'CONTINUATION' && overview.variantCode === 1
      "
    >
      <tr [ngClass]="{ 'no-border-row': !checkMinIndbetalingTilOpsparing() }">
        <td [coContent]="'DL.TR01.C380'"></td>
        <td
          [ngClass]="{
            'text--bold': true,
            'text--error': !checkMinIndbetalingTilOpsparing()
          }"
          [coContent]="'DL.TR01.C381'"
          [coContentSubst]="overview.indbetalingTilOpsparing | number: '1.0-0'"
        ></td>
      </tr>
      <tr *ngIf="!checkMinIndbetalingTilOpsparing()">
        <td></td>
        <td>
          <co-info class="text--note">
            <div
              [coContent]="'DL.TR01.C382'"
              [coContentSubst]="
                advisorOptions.minIndbetalingTilOpsparing | number: '1.0-0'
              "
            ></div>
          </co-info>
        </td>
      </tr>
    </ng-container>
    <ng-container *ngIf="!hasPremium()">
      <tr>
        <td colspan="2" [coContent]="'DL.TR01.C356'"></td>
      </tr>
    </ng-container>

    <ng-container *ngIf="advisorOptions">
      <ng-container
        *ngIf="
          overview.minObligatoriskIndbetaling
            .arbejdesgiverIndbetalingEffektueringMuligt ||
          overview.minObligatoriskIndbetaling
            .medarbejderIndbetalingEffektueringMuligt
        "
      >
        <tr>
          <td colspan="2">
            <co-accordion [title]="'DL.TR01.C344'">
              <form [formGroup]="mandatoryPaymentsForm">
                <div
                  *ngIf="
                    overview.minObligatoriskIndbetaling
                      .arbejdesgiverIndbetalingEffektueringMuligt
                  "
                >
                  <mat-form-field color="accent">
                    <mat-label [coContent]="'DL.TR01.C345'"></mat-label>
                    <input matInput [formControl]="employerPercent" required />
                    <mat-hint [coContent]="'DL.TR01.C348'"></mat-hint>
                    <mat-hint align="end">{{ employeePercentRange }}</mat-hint>
                    <mat-error
                      *ngIf="employerPercent.errors?.required"
                      [coContent]="'DL.TR01.C352'"
                    ></mat-error>
                    <mat-error
                      *ngIf="
                        employerPercent.errors &&
                        !employerPercent.errors.required
                      "
                      [coContent]="'DL.TR01.C353'"
                    ></mat-error>
                  </mat-form-field>
                </div>
                <div
                  *ngIf="
                    overview.minObligatoriskIndbetaling
                      .medarbejderIndbetalingEffektueringMuligt
                  "
                >
                  <mat-form-field color="accent">
                    <mat-label [coContent]="'DL.TR01.C346'"></mat-label>
                    <input matInput [formControl]="employeePercent" required />
                    <mat-hint [coContent]="'DL.TR01.C348'"></mat-hint>
                    <mat-hint align="end">{{ employerPercentRange }}</mat-hint>
                    <mat-error
                      *ngIf="employeePercent.errors?.required"
                      [coContent]="'DL.TR01.C352'"
                    ></mat-error>
                    <mat-error
                      *ngIf="
                        employeePercent.errors &&
                        !employeePercent.errors.required
                      "
                      [coContent]="'DL.TR01.C353'"
                    ></mat-error>
                  </mat-form-field>
                </div>
                <div class="align--right">
                  <button
                    mat-raised-button
                    [disabled]="
                      mandatoryPaymentsForm.invalid ||
                      mandatoryPaymentsForm.pristine
                    "
                    (click)="
                      savePayments.emit({
                        employer: parseNumber(employerPercent.value),
                        employee: parseNumber(employeePercent.value)
                      })
                    "
                    [coContent]="'Global.Gem'"
                  ></button>
                </div>
                <co-warning *ngIf="savePaymentsFail">
                  <span [coContent]="'DL.TR01.C354'"></span>
                </co-warning>
              </form>
            </co-accordion>
          </td>
        </tr>
      </ng-container>
      <ng-container
        *ngIf="
          !overview.procentOrdning && overview.policeStatus !== 'Fripolice'
        "
      >
        <tr>
          <td colspan="2">
            <co-accordion [title]="'DL.TR01.C485'">
              <form [formGroup]="voluntaryPaymentsForm">
                <div>
                  <mat-form-field color="accent">
                    <mat-label [coContent]="'DL.TR01.C486'"></mat-label>
                    <input matInput coAmount [formControl]="voluntaryAmount" />
                    <mat-hint [coContent]="'DL.TR01.C487'"></mat-hint>
                    <mat-error
                      *ngIf="voluntaryAmount.errors"
                      [coContent]="'DL.TR01.C353'"
                    ></mat-error>
                  </mat-form-field>
                </div>
                <div class="align--right">
                  <button
                    mat-raised-button
                    [disabled]="
                      voluntaryPaymentsForm.invalid ||
                      voluntaryPaymentsForm.pristine
                    "
                    (click)="saveVoluntaryPayment.emit(voluntaryAmount.value)"
                    [coContent]="'Global.Gem'"
                  ></button>
                </div>
                <co-warning
                  *ngIf="savePaymentsFail"
                  class="warning-justify-end"
                >
                  <span [coContent]="'DL.TR01.C354'"></span>
                </co-warning>
              </form>
            </co-accordion>
          </td>
        </tr>
      </ng-container>
    </ng-container>

    <tr>
      <td>
        <div [coContent]="'DL.TR01.C33'"></div>
        <div class="text--note" [coContent]="'DL.TR01.C37'"></div>
      </td>
      <td
        class="text--bold"
        [coContent]="indbetalingSikringText(overview.indbetalingSikring)"
      ></td>
    </tr>
    <tr *ngIf="advisorOptions && overview.indbetalingSikringMulige.length > 1">
      <td colspan="2">
        <form [formGroup]="indbetalingSikringForm">
          <mat-form-field color="accent">
            <mat-label coContent="DL.TR01.C515"></mat-label>
            <mat-select [formControl]="indbetalingSikring">
              <mat-option
                *ngFor="
                  let indbetalingSikring of overview.indbetalingSikringMulige
                "
                [value]="indbetalingSikring"
              >
                {{ indbetalingSikringText(indbetalingSikring) | content }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <co-warning
            *ngIf="saveIndbetalingSikringFail"
            class="warning-justify-end"
          >
            <span [coContent]="'DL.TR01.C516'"></span>
          </co-warning>
        </form>
      </td>
    </tr>
  </tbody>
</table>

<table class="align-right--column-2 new-business-advisory-table">
  <thead>
    <tr>
      <th colspan="2" [coContent]="'DL.TR01.C38'"></th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td [coContent]="'DL.TR01.C39'"></td>
      <td
        class="text--bold"
        [coContent]="
          opsparingFormFordelingText(overview.opsparingFormFordeling)
        "
      ></td>
    </tr>
    <tr *ngIf="overview.ratepension > 0">
      <td
        colspan="2"
        [coContent]="'DL.TR01.C45'"
        [coContentSubst]="overview.ratepension | number: '1.0-0'"
      ></td>
    </tr>
    <tr *ngIf="overview.ratepensionFast > 0">
      <td
        colspan="2"
        [coContent]="'DL.TR01.C46'"
        [coContentSubst]="overview.ratepensionFast | number: '1.0-0'"
      ></td>
    </tr>
    <tr *ngIf="overview.alderopsparing > 0">
      <td
        colspan="2"
        [coContent]="'DL.TR01.C47'"
        [coContentSubst]="overview.alderopsparing | number: '1.0-0'"
      ></td>
    </tr>
    <tr *ngIf="overview.alderopsparingFast > 0">
      <td
        colspan="2"
        [coContent]="'DL.TR01.C48'"
        [coContentSubst]="overview.alderopsparingFast | number: '1.0-0'"
      ></td>
    </tr>
  </tbody>
</table>
