import { Injectable } from '@angular/core';
import { TrackingService } from './tracking.service';

export enum TrackingName {
  FRONTPAGE = 'Frontpage status',
  INSURANCE = 'insurance',
  ONBOARDING = 'onboarding',
  INSURANCE_CONFIGURATION = 'insurance configuration',
  INSURANCE_CONFIGURATION_ONBOARDING = 'insurance configuration onboarding',
  INSURANCE_RECOMMENDATION = 'insurance recommendation',
  INSURANCE_RECOMMENDATION_ONBOARDING = 'insurance recommendation onboarding',
  INSURANCE_ONBOARDING = 'insurance onboarding',
  PENSION_CHECK = 'Customer Center',
  DYNAMIC_COUNSELING = 'Advisory Center',
  INVESTMENT = 'investment',
  INVESTMENT_ONBOARDING = 'investment onboarding',
  INVESTMENT_GUIDE = 'investment guide',
  INVESTMENT_GUIDE_ONBOARDING = 'investment guide onboarding',
  BENEFICIARY = 'beneficiary',
  EXTERNAL_TRANSFER = 'External Transfer',
  INTERNAL_TRANSFER = 'Internal Transfer',
  PENSION_ESTIMATE = 'pension Estimate'
}

export enum TrackingCategory {
  LIFE = 'pfalife',
  CRITICAL_ILLNESS = 'pfacriticalillness',
  LOSS_OF_OCCUPATIONAL_CAPACITY = 'pfaoccupationalcapacity',
  PFA_INVEST = 'PFA Invest',
  PFA_OPTIONAL = 'PFA Optional',
  YOU_INVEST = 'You Invest',
  CLIMATE = 'Climate',
  PENSION_ESTIMATE = 'Pension Estimate',
  BENEFICIARY_NEXT_OF_KIN = 'next of kin ',
  BENEFICIARY_HEIR = 'heirs',
  BENEFICIARY_ESTATE = 'no beneficiaries (the estate)',
  BENEFICIARY_NAMED = 'named beneficiary',
  BENEFICIARY_OTHER = 'other'
}

export enum TrackingFollowingRecommendation {
  YES = 'Yes',
  NO = 'No'
}

export enum TrackingNotSet {
  NOT_SET = 'not set'
}

export enum ConfirmChoiceFollowingRecommendation {
  FOLLOWS = 'follows recommendation',
  DEVIATES = 'deviates from recommendation',
  NONE = 'recommendation not given'
}

export enum ConfirmChoiceStatus {
  CONFIRMED = 'confirmed',
  WAITING = 'waiting for confirmation',
  DISABLED = 'disabled',
  TAKE_GUIDE = 'take guide'
}

export enum ConfirmChoiceType {
  FIRST = 'FIRST',
  RECONFIRM = 'RECONFIRM'
}

export class ConfirmChoiceTrackingItem {
  itemRecommendedName: ConfirmChoiceFollowingRecommendation | string;
  itemConfirmName: TrackingCategory | string;
  itemStatus?: ConfirmChoiceStatus;
  itemConfirmType?: ConfirmChoiceType;
}

export interface NbaOfferTrackingItem {
  offerName: string;
  offerHash: string;
  offerIndex: number;
  offerSeverity: number;
}

export interface NbaOfferClickTrackingItem extends NbaOfferTrackingItem {
  offerClickedAction: string;
}

@Injectable()
export class MiscTrackingService extends TrackingService {
  trackMyPercentageChange(myPercentage: number, eventName: string): void {
    this.dispatchCustomEventTracking('track-mitpfa-percent-completed', {
      mitpfaPercentCompleted: myPercentage,
      mitpfaPercentCompletedEvent: eventName
    });
  }

  trackMyLogin(percentage: number): void {
    this.dispatchCustomEventTracking('track-pageview', {
      loginSuccess: 1,
      mitpfaPercentCompleted: percentage,
      mitpfaPercentCompletedEvent: 'Login'
    });
  }

  trackNavigationCard(
    navigationName: string,
    navigationAction: string,
    navigationDestination: string
  ): void {
    const options = {
      navigationName,
      navigationAction,
      navigationDestination
    };
    this.dispatchCustomEventTracking('track-navigation', options);
  }

  trackNbaOffersList(
    recommendationCenterName: string,
    nbaId: string,
    nbaOfferTrackingItems: NbaOfferTrackingItem[]
  ): void {
    this.dispatchCustomEventTracking('track-recommendation-center-status', {
      recommendationCenterPlacement: recommendationCenterName,
      nbaId: nbaId,
      offerItems: nbaOfferTrackingItems
    });
  }

  trackNbaOfferClick(
    recommendationCenterName: string,
    nbaId: string,
    nbaOfferClickTrackingItems: NbaOfferClickTrackingItem[]
  ): void {
    this.dispatchCustomEventTracking('track-recommendation-center-clicked', {
      recommendationCenterPlacement: recommendationCenterName,
      nbaId: nbaId,
      offerItems: nbaOfferClickTrackingItems
    });
  }

  // TODO delete this when all "confirm choice" trackings use trackConfirmChoiceStatus/trackConfirmChoiceClick
  trackConfirmChoice(
    selectionType: TrackingName,
    recommendedCategory: TrackingCategory | TrackingNotSet,
    recommendedValue: string | TrackingNotSet,
    currentCategory: TrackingCategory | TrackingNotSet,
    currentValue: string | TrackingNotSet,
    followingRecommendation: TrackingFollowingRecommendation | TrackingNotSet
  ): void {
    this.dispatchCustomEventTracking('track-order', {
      orderAction: 'confirmChoice',
      selectionType: selectionType,
      selection: {
        recommendedCategory: recommendedCategory,
        recommendedValue: recommendedValue,
        currentCategory: currentCategory,
        currentValue: currentValue,
        selectedCategory: TrackingNotSet.NOT_SET,
        selectedValue: TrackingNotSet.NOT_SET,
        changeCurrentSelection: 'OK',
        changeRecommendationSelection: 'OK',
        followingRecommendation: followingRecommendation
      }
    });
  }

  trackConfirmChoiceStatus(
    confirmPlacement: TrackingName,
    confirmChoiceTrackingItems: ConfirmChoiceTrackingItem[]
  ): void {
    this.trackConfirmChoiceGeneric(
      'track-confirm-status',
      confirmPlacement,
      confirmChoiceTrackingItems
    );
  }

  trackConfirmChoiceClick(
    confirmPlacement: TrackingName,
    confirmChoiceTrackingItems: ConfirmChoiceTrackingItem[]
  ): void {
    this.trackConfirmChoiceGeneric(
      'track-confirm-clicked',
      confirmPlacement,
      confirmChoiceTrackingItems
    );
  }

  private trackConfirmChoiceGeneric(
    trackingType: string,
    confirmPlacement: TrackingName,
    confirmChoiceTrackingItem: ConfirmChoiceTrackingItem[]
  ): void {
    this.dispatchCustomEventTracking(trackingType, {
      confirmPlacement,
      confirmItems: confirmChoiceTrackingItem
    });
  }
}
