import { Injectable, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PopupTrackingService } from '@pfa/core';
import { Legitimation } from '@pfa/gen';
import { Subscription } from 'rxjs';
import {
  ProofOfIdentityModalComponent,
  ProofOfIdentityModalData,
  ProofOfIdentityPopupId
} from './proof-of-identity-modal/proof-of-identity-modal.component';

@Injectable({
  providedIn: 'root'
})
export class ProofOfIdentityService {
  constructor(
    private dialog: MatDialog,
    private trackingService: PopupTrackingService
  ) {}

  openDialog(legitimation: Legitimation, popupId: ProofOfIdentityPopupId) {
    const dialogRef = this.dialog.open(ProofOfIdentityModalComponent, {
      data: { legitimation, popupId } as ProofOfIdentityModalData,
      disableClose: true,
      width: '80vw'
    });
    dialogRef.afterOpened().subscribe(() => {
      this.trackingService.trackPopupViewed(popupId);
    });
    dialogRef.afterClosed().subscribe(() => {
      this.trackingService.trackPopupClosed(popupId);
    });
  }
}
