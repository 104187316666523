/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SpecialAgreement } from './specialAgreement';
import { SpecialAgreementSchema } from './specialAgreement';
import { CustomerStatus } from './customerStatus';
import { CustomerStatusSchema } from './customerStatus';
import { SalesOrganizationName } from './salesOrganizationName';
import { SalesOrganizationNameSchema } from './salesOrganizationName';
import * as JoiObj from "joi";


    export const SalesOrganizationSchema = JoiObj.object({
        customerStatus:
            
        JoiObj.link('#CustomerStatusSchema')


.required()


        ,
        salesOrganizationName:
            
        JoiObj.link('#SalesOrganizationNameSchema')


.required()


        ,
        specialAgreements:
                JoiObj.array().items(
        
        JoiObj.link('#SpecialAgreementSchema')

        )
        

.required()


        
    })
                .shared(CustomerStatusSchema)
                .shared(SalesOrganizationNameSchema)
                .shared(SpecialAgreementSchema)
.id('SalesOrganizationSchema')

export interface SalesOrganization { 
    customerStatus: CustomerStatus;
    salesOrganizationName: SalesOrganizationName;
    specialAgreements: Array<SpecialAgreement>;
}
export namespace SalesOrganization {
}


