/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const AlternativePolicyChoiceDeclinedSchema = JoiObj.string().valid(
        'SavingsOnly'
        ,
        'CancelOffer'
        
    )
.id('AlternativePolicyChoiceDeclinedSchema')

export type AlternativePolicyChoiceDeclined = 'SavingsOnly' | 'CancelOffer';

export const AlternativePolicyChoiceDeclined = {
    SavingsOnly: 'SavingsOnly' as AlternativePolicyChoiceDeclined,
    CancelOffer: 'CancelOffer' as AlternativePolicyChoiceDeclined
};

