/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Skattekode } from './skattekode';
import { SkattekodeSchema } from './skattekode';
import { ViderefoerelseSammenligningRaekkeVaerdi } from './viderefoerelseSammenligningRaekkeVaerdi';
import { ViderefoerelseSammenligningRaekkeVaerdiSchema } from './viderefoerelseSammenligningRaekkeVaerdi';
import * as JoiObj from "joi";


    export const ViderefoerelseSammenligningRaekkeSchema = JoiObj.object({
        alder:
            
                    JoiObj.number()
                


.required()


        ,
        daekningId:
            
                    JoiObj.number()
                


.required()


        ,
        efterviderefoerelse:
            
        JoiObj.link('#ViderefoerelseSammenligningRaekkeVaerdiSchema')


.required()


        ,
        nuvaerende:
            
        JoiObj.link('#ViderefoerelseSammenligningRaekkeVaerdiSchema')


.required()


        ,
        skattekode:
            
        JoiObj.link('#SkattekodeSchema')


.required()


        
    })
                .shared(ViderefoerelseSammenligningRaekkeVaerdiSchema)
                .shared(ViderefoerelseSammenligningRaekkeVaerdiSchema)
                .shared(SkattekodeSchema)
.id('ViderefoerelseSammenligningRaekkeSchema')

export interface ViderefoerelseSammenligningRaekke { 
    alder: number;
    daekningId: number;
    efterviderefoerelse: ViderefoerelseSammenligningRaekkeVaerdi;
    nuvaerende: ViderefoerelseSammenligningRaekkeVaerdi;
    skattekode: Skattekode;
}
export namespace ViderefoerelseSammenligningRaekke {
}


