import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import {
  ConfirmChoiceComponentTexts,
  CoverageDetail,
  GlobalWorkingService,
  InsuranceUtilService
} from '@pfaform';
import { Observable, Subject } from 'rxjs';
import { Utils } from '@pfa/utils';
import { Forsikringsoversigt } from '@pfa/gen';
import { takeUntil } from 'rxjs/operators';
import { openClose } from '@pfa/animations';
import {
  ConfirmChoice,
  InputConfigCi,
  INSURANCE_SCROLL_TIMERS,
  InsuranceAdjustmentCiService,
  InsuranceAdjustmentInputEmitter,
  InsuranceAdjustmentType,
  InsuranceGuideChecks,
  ResetDataCi
} from '@mitpfa/shared';
import { InsuranceGuideService } from '@mitpfa/shared/insurance-guide/insurance-guide.service';
import { NullableNumber } from '@pfa/models';
import { InsuranceAdjustmentConfirmChoiceConfigurationService } from '../insurance-adjustment-confirm-choice-configuration.service';

@Component({
  selector: 'mitpfa-insurance-adjustment-input-ci',
  templateUrl: './insurance-adjustment-input-ci.component.html',
  styleUrls: ['./insurance-adjustment-input-ci.component.scss'],
  animations: [openClose]
})
export class InsuranceAdjustmentInputCiComponent
  implements OnInit, OnDestroy, OnChanges
{
  @Input() inputConfig: InputConfigCi;
  @Input() reset: Observable<ResetDataCi>;
  @Input() insuranceOverview: Forsikringsoversigt;
  @Input() updated: Observable<Forsikringsoversigt>;
  @Input() disabled: boolean;
  @Input() confirmChoice: ConfirmChoice;
  @Input() insuranceGuideChecks: InsuranceGuideChecks;
  @Input() hideActions: boolean;

  @Output() changes = new EventEmitter<InsuranceAdjustmentInputEmitter>();
  @Output() confirmChoiceEmitter = new EventEmitter();

  overrideRecommendationKeepCurrent: boolean;
  pricesUpdated: boolean;
  pricesChildUpdated: boolean;
  expandedDetails: boolean;
  expandedChild: boolean;
  form = new FormGroup({
    self: new FormControl<number | undefined>(undefined),
    child: new FormControl<number | undefined>(undefined)
  });
  coverageDetailsSelf: CoverageDetail;
  showNoCiSelfButCiChildError: {
    CI: boolean;
    CI_CHILD: boolean;
  };
  private readonly unsubscribe = new Subject<void>();

  private readonly insuranceUtilService: InsuranceUtilService =
    inject(InsuranceUtilService);
  private readonly globalWorkingService: GlobalWorkingService =
    inject(GlobalWorkingService);
  private readonly insuranceAdjustmentCiService: InsuranceAdjustmentCiService =
    inject(InsuranceAdjustmentCiService);
  private readonly insuranceAdjustmentConfirmChoiceConfigurationService: InsuranceAdjustmentConfirmChoiceConfigurationService =
    inject(InsuranceAdjustmentConfirmChoiceConfigurationService);

  public confirmChoiceComponentTexts: ConfirmChoiceComponentTexts =
    this.insuranceAdjustmentConfirmChoiceConfigurationService
      .confirmChoiceComponentTexts;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.inputConfig?.currentValue) {
      const inputConfigUpdate = changes.inputConfig
        .currentValue as InputConfigCi;
      if (
        inputConfigUpdate.self.amount !== inputConfigUpdate.self.amountOriginal
      ) {
        this.pricesUpdated = true;
      }
      if (
        inputConfigUpdate.child &&
        inputConfigUpdate.child.amount !==
          inputConfigUpdate.child.amountOriginal
      ) {
        this.pricesChildUpdated = true;
      }
    }
  }

  ngOnInit(): void {
    this.overrideRecommendationKeepCurrent =
      InsuranceGuideService.calculateShouldKeepRecommendation(
        this.inputConfig.self.amountOriginal,
        this.inputConfig.recommendation,
        InsuranceUtilService.CI_VARIATION
      );

    this.coverageDetailsSelf = this.insuranceUtilService.getCoverageDetailsSelf(
      this.insuranceOverview
    );
    this.showNoCiSelfButCiChildError = {
      CI: false,
      CI_CHILD: false
    };

    if (this.disabled || this.inputConfig.self.disableEditing) {
      this.form.controls.self.disable();
    }
    this.form.controls.self.addValidators([
      Validators.min(this.inputConfig.self.amountMin ?? 0),
      Validators.max(this.inputConfig.self.amountMax ?? 0)
    ]);

    if (this.inputConfig.child) {
      if (this.disabled) {
        this.form.controls.child.disable();
      }
      this.form.controls.child.setValue(this.inputConfig.child.amount);
      this.form.controls.child.addValidators([
        Validators.min(this.inputConfig.child.amountMin ?? 0),
        Validators.max(this.inputConfig.child.amountMax ?? 0)
      ]);
    }

    this.subscribeToUpdate();
    this.subscribeToReset();
    this.subscribeToFollowOurRecommendation();
  }

  handleZeroCiSelf(type: InsuranceAdjustmentType, amount: number): void {
    if (!this.inputConfig.child) {
      return;
    }
    if (type === InsuranceAdjustmentType.CI) {
      this.showNoCiSelfButCiChildError.CI =
        amount === 0 && (this.inputConfig?.child?.amount ?? 0) > 0;
    } else if (type === InsuranceAdjustmentType.CI_CHILD) {
      this.showNoCiSelfButCiChildError.CI_CHILD =
        amount > 0 && this.inputConfig.self.amount === 0;
    }
  }

  update(
    type: InsuranceAdjustmentType,
    formControl: AbstractControl<NullableNumber, NullableNumber> | null
  ): void {
    this.globalWorkingService.show();
    this.mapChanges(type);
    this.changes.emit({
      inputConfig: this.inputConfig,
      type
    });
    formControl?.markAsPristine();
  }

  mapChanges(type: InsuranceAdjustmentType): void {
    if (
      type === InsuranceAdjustmentType.CI &&
      this.form.value.self !== undefined &&
      this.form.value.self !== null
    ) {
      this.inputConfig.self.amount = this.form.value.self;
    } else if (
      type === InsuranceAdjustmentType.CI_CHILD &&
      this.form.value.child !== undefined &&
      this.form.value.child !== null
    ) {
      if (
        this.inputConfig.child !== undefined &&
        this.inputConfig.child !== null
      ) {
        this.inputConfig.child.amount = this.form.value.child;
      }
    }
  }

  checkForEmpty(formControl: AbstractControl, defaultValue: number) {
    if (Utils.isEmpty(formControl.value)) {
      formControl.setValue(defaultValue);
    }
  }

  isDisabled(fieldName: string): boolean {
    return (
      this.disabled ||
      this.inputConfig[fieldName].disableEditing ||
      this.form.get(fieldName)?.pristine ||
      this.form.get(fieldName)?.invalid ||
      (this.form.get(fieldName)?.value ===
        this.inputConfig[fieldName].amountOriginal &&
        this.inputConfig[fieldName].amountOriginal ===
          this.inputConfig[fieldName].amount)
    );
  }

  setRecommendation(
    type: InsuranceAdjustmentType,
    formControl: AbstractControl<NullableNumber, NullableNumber> | null
  ): void {
    this.form.controls.self.setValue(this.inputConfig.recommendation);
    this.update(type, formControl);
  }

  private subscribeToUpdate(): void {
    this.updated
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(insuranceOverview => {
        this.coverageDetailsSelf =
          this.insuranceUtilService.getCoverageDetailsSelf(insuranceOverview);
      });
  }

  private subscribeToReset(): void {
    this.reset.pipe(takeUntil(this.unsubscribe)).subscribe(data => {
      this.form.controls.self?.reset();
      this.pricesUpdated = false;
      this.pricesChildUpdated = false;
      if (this.inputConfig.child) {
        this.form.patchValue({
          child: data.inputConfig?.child?.amountOriginal
        });
      }
      this.subscribeToFollowOurRecommendation();
    });
  }

  private subscribeToFollowOurRecommendation(): void {
    this.insuranceAdjustmentCiService.followOurRecommendation.subscribe({
      next: () => {
        setTimeout(() => {
          this.setRecommendation(
            InsuranceAdjustmentType.CI,
            this.form.get('self')
          );
        }, INSURANCE_SCROLL_TIMERS.recommendationCalculationDelay);
      }
    });
  }

  onConfirmChoice() {
    this.confirmChoiceEmitter.emit();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
