/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const RekvirentnummerSchema = JoiObj.object({
        rekvirentnummer:
            
                    JoiObj.number()
                .integer()


.required()


        
    })
.id('RekvirentnummerSchema')

export interface Rekvirentnummer { 
    rekvirentnummer: number;
}

