import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { openClose } from '@pfa/animations';
import { AccordionTrackingService } from '@pfa/core';

@Component({
  selector: 'co-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
  animations: [openClose]
})
export class AccordionComponent implements OnInit {
  @Input() title?: string;
  @Input() titleOpen?: string;
  @Input() titleNote?: string;
  @Input() titleNoteOpen?: string;
  @Input() titleRight?: string;
  @Input() titleRightOpen?: string;
  @Input() initExpanded: boolean;
  @Input() hideControls: boolean;
  @Input() expanded: boolean;
  @Input() accordionName: string;
  @Input() disabled: boolean;

  @Output() emitExpand = new EventEmitter<boolean>();

  constructor(private readonly trackingService: AccordionTrackingService) {}

  ngOnInit(): void {
    this.expanded = this.initExpanded;
  }

  accordionAction(): void {
    if (this.disabled) {
      return;
    }

    this.expanded = !this.expanded;
    this.emitExpand.emit(this.expanded);

    if (this.expanded && !this.initExpanded && this.accordionName) {
      this.trackingService.trackAccordion(this.accordionName);
    }
  }
}
