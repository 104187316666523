/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const ChecklistTypeItemSchema = JoiObj.string().valid(
        'HEALTH_CASE'
        ,
        'PAYMENT_AGREEMENT'
        
    )
.id('ChecklistTypeItemSchema')

export type ChecklistTypeItem = 'HEALTH_CASE' | 'PAYMENT_AGREEMENT';

export const ChecklistTypeItem = {
    HealthCase: 'HEALTH_CASE' as ChecklistTypeItem,
    PaymentAgreement: 'PAYMENT_AGREEMENT' as ChecklistTypeItem
};

