/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BaseVaegtObject } from './baseVaegtObject';
import { BaseVaegtObjectSchema } from './baseVaegtObject';
import * as JoiObj from "joi";


    export const ForvaltningSchema = JoiObj.object({
        hoejrisiko:
                JoiObj.array().items(
        
        JoiObj.link('#BaseVaegtObjectSchema')

        )
        

.required()


        ,
        lavrisiko:
                JoiObj.array().items(
        
        JoiObj.link('#BaseVaegtObjectSchema')

        )
        

.required()


        
    })
                .shared(BaseVaegtObjectSchema)
                .shared(BaseVaegtObjectSchema)
.id('ForvaltningSchema')

export interface Forvaltning { 
    hoejrisiko: Array<BaseVaegtObject>;
    lavrisiko: Array<BaseVaegtObject>;
}

