/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const ClimateExtendedFocusSchema = JoiObj.string().valid(
        'MINOR'
        ,
        'AVERAGE'
        ,
        'HIGH'
        
    )
.id('ClimateExtendedFocusSchema')

export type ClimateExtendedFocus = 'MINOR' | 'AVERAGE' | 'HIGH';

export const ClimateExtendedFocus = {
    Minor: 'MINOR' as ClimateExtendedFocus,
    Average: 'AVERAGE' as ClimateExtendedFocus,
    High: 'HIGH' as ClimateExtendedFocus
};

