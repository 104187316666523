import { Component, Input, OnInit } from '@angular/core';
import { BeneficiaryView } from '@pfa/gen';

@Component({
  selector: 'co-beneficiary-icon',
  templateUrl: './beneficiary-icon.component.html',
  styleUrls: ['./beneficiary-icon.component.scss']
})
export class BeneficiaryIconComponent implements OnInit {
  @Input() beneficiary: BeneficiaryView;
  imageUrl: string;

  ngOnInit() {
    if (
      this.beneficiary.specialBeneficiary ||
      (this.beneficiary.converted && this.beneficiary.type === 'OTHER')
    ) {
      this.imageUrl = 'icon_andet.png';
    } else {
      switch (this.beneficiary.type) {
        case 'NEXT_OF_KIN':
          this.imageUrl = 'icon_naermestepaaroerende.png';
          break;
        case 'HEIR_OF_THE_BODY':
          this.imageUrl = 'icon_livsarvinge.png';
          break;
        case 'ESTATE':
          this.imageUrl = 'icon_boet.png';
          break;
        case 'NAMED':
          this.imageUrl = 'icon_navngiven.png';
          break;
        default:
          this.imageUrl = 'icon_andet.png';
      }
    }
  }
}
