import { DokumentMeta } from '@pfa/gen';

export interface DocumentMetaExtender extends DokumentMeta {
  text: string;
  datoFormateret: string;
  showPdf: () => void;
  toolTipText: string;
}

export interface DocumentType {
  key: string;
  text: string;
}

export class DocumentCacheItem {
  documents: DocumentMetaExtender[] = null;
  krLinkExists = false;
  plusLinkExists = false;
  sorting = { text: 0, policenr: 0, dato: 0 };
  dropDownElementer: string[];
  sortBy(field: string): string {
    this.sorting[field]++;
    return field + '-' + ['asc', 'desc'][this.sorting[field] % 2];
  }
  clearCache(): void {
    this.documents = null;
    this.krLinkExists = this.plusLinkExists = false;
  }
  startOrder(): DocumentMetaExtender[] {
    return this.documents.sort((a, b) => {
      if (a.dato === b.dato) {
        return a.policenr > b.policenr ? 1 : -1;
      }
      return a.dato.valueOf() < b.dato.valueOf() ? 1 : -1;
    });
  }
}

export class DocumentCache {
  seneste: DocumentCacheItem = new DocumentCacheItem();
  alle: DocumentCacheItem = new DocumentCacheItem();
  andre: DocumentCacheItem = new DocumentCacheItem();
  oevrige: DocumentCacheItem = new DocumentCacheItem();
}
