/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const ServicekaldFejlSchema = JoiObj.string().valid(
        'HENT_KONTAKT_OPLYSNINGER'
        ,
        'HENTAFTALE_NUMMERLISTE'
        ,
        'POLICE_DETALJER'
        ,
        'AFKAST_LIVNET'
        ,
        'DEPOT_LIVNET'
        ,
        'INDBETALING_LIVNET'
        ,
        'PRISGEBYR_LIVNET'
        ,
        'DAEKNINGER_LIVNET'
        ,
        'UDBETALING'
        ,
        'MULIGE_HAENDELSER'
        ,
        'START_SESSION'
        ,
        'GEMLUK_SESSION'
        ,
        'INDREGN'
        ,
        'BEREGN_FOERSTEAARS_YDELSE'
        ,
        'BEREGN_UDBETALINGS_FORLOEB'
        ,
        'PROGNOSE_LIVNET'
        ,
        'PROGNOSE_PARAMETRE_LIVNET'
        ,
        'PROGNOSE_LIVNET_OPTIMERE'
        ,
        'HENT_KONTAKT_FORDEL'
        ,
        'HENT_MAEGLERHUS_AGENTUR_NUMMERLISTE'
        ,
        'HENT_MAEGLERLOKATION'
        ,
        'HENT_GENNEMSNITSRENTE_ENHEDER'
        ,
        'HENT_AKTIV_FORDELING'
        
    )
.id('ServicekaldFejlSchema')

export type ServicekaldFejl = 'HENT_KONTAKT_OPLYSNINGER' | 'HENTAFTALE_NUMMERLISTE' | 'POLICE_DETALJER' | 'AFKAST_LIVNET' | 'DEPOT_LIVNET' | 'INDBETALING_LIVNET' | 'PRISGEBYR_LIVNET' | 'DAEKNINGER_LIVNET' | 'UDBETALING' | 'MULIGE_HAENDELSER' | 'START_SESSION' | 'GEMLUK_SESSION' | 'INDREGN' | 'BEREGN_FOERSTEAARS_YDELSE' | 'BEREGN_UDBETALINGS_FORLOEB' | 'PROGNOSE_LIVNET' | 'PROGNOSE_PARAMETRE_LIVNET' | 'PROGNOSE_LIVNET_OPTIMERE' | 'HENT_KONTAKT_FORDEL' | 'HENT_MAEGLERHUS_AGENTUR_NUMMERLISTE' | 'HENT_MAEGLERLOKATION' | 'HENT_GENNEMSNITSRENTE_ENHEDER' | 'HENT_AKTIV_FORDELING';

export const ServicekaldFejl = {
    HentKontaktOplysninger: 'HENT_KONTAKT_OPLYSNINGER' as ServicekaldFejl,
    HentaftaleNummerliste: 'HENTAFTALE_NUMMERLISTE' as ServicekaldFejl,
    PoliceDetaljer: 'POLICE_DETALJER' as ServicekaldFejl,
    AfkastLivnet: 'AFKAST_LIVNET' as ServicekaldFejl,
    DepotLivnet: 'DEPOT_LIVNET' as ServicekaldFejl,
    IndbetalingLivnet: 'INDBETALING_LIVNET' as ServicekaldFejl,
    PrisgebyrLivnet: 'PRISGEBYR_LIVNET' as ServicekaldFejl,
    DaekningerLivnet: 'DAEKNINGER_LIVNET' as ServicekaldFejl,
    Udbetaling: 'UDBETALING' as ServicekaldFejl,
    MuligeHaendelser: 'MULIGE_HAENDELSER' as ServicekaldFejl,
    StartSession: 'START_SESSION' as ServicekaldFejl,
    GemlukSession: 'GEMLUK_SESSION' as ServicekaldFejl,
    Indregn: 'INDREGN' as ServicekaldFejl,
    BeregnFoersteaarsYdelse: 'BEREGN_FOERSTEAARS_YDELSE' as ServicekaldFejl,
    BeregnUdbetalingsForloeb: 'BEREGN_UDBETALINGS_FORLOEB' as ServicekaldFejl,
    PrognoseLivnet: 'PROGNOSE_LIVNET' as ServicekaldFejl,
    PrognoseParametreLivnet: 'PROGNOSE_PARAMETRE_LIVNET' as ServicekaldFejl,
    PrognoseLivnetOptimere: 'PROGNOSE_LIVNET_OPTIMERE' as ServicekaldFejl,
    HentKontaktFordel: 'HENT_KONTAKT_FORDEL' as ServicekaldFejl,
    HentMaeglerhusAgenturNummerliste: 'HENT_MAEGLERHUS_AGENTUR_NUMMERLISTE' as ServicekaldFejl,
    HentMaeglerlokation: 'HENT_MAEGLERLOKATION' as ServicekaldFejl,
    HentGennemsnitsrenteEnheder: 'HENT_GENNEMSNITSRENTE_ENHEDER' as ServicekaldFejl,
    HentAktivFordeling: 'HENT_AKTIV_FORDELING' as ServicekaldFejl
};

