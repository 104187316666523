import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { MaeglerInfo, PensionskundeStore } from '@pfa/gen';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PensionCustomerBrokerResolver implements Resolve<MaeglerInfo> {
  constructor(private pensionskundeStore: PensionskundeStore) {}

  resolve(): MaeglerInfo | Observable<MaeglerInfo> | Promise<MaeglerInfo> {
    return this.pensionskundeStore.pensionskundeMaeglerinfoGet();
  }
}
