import {
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  TemplateRef
} from '@angular/core';

@Component({
  selector: 'mitpfa-onboarding-step-animation',
  templateUrl: './step-animation.component.html',
  styleUrls: ['./step-animation.component.scss']
})
export class StepAnimationComponent implements OnInit {
  @Input() firstCard: TemplateRef<any>;
  @Input() secondCard: TemplateRef<any>;
  @Input() extraCards = 0;
  @Input() doneCards = 0;
  @Output() goTo = new EventEmitter();

  public activeCard = 0;
  public initialized = false;
  public width: string;
  public backgroundCards: number[];
  public backgroundDoneCards: number[];
  private height: string;

  private readonly elementRef: ElementRef = inject(ElementRef);

  public next(): void {
    this.goTo.emit();
  }

  public ngOnInit(): void {
    this.backgroundCards = Array(this.extraCards);
    this.backgroundDoneCards = Array(this.doneCards);
    setTimeout(() => {
      this.activeCard = 1;
    }, 3800);

    setTimeout(() => {
      this.width =
        this.elementRef.nativeElement.querySelector('co-card').offsetWidth +
        'px';
      this.height =
        this.elementRef.nativeElement.querySelector('co-card').offsetHeight +
        'px';
      this.elementRef.nativeElement.querySelector(
        '.overlay-static'
      ).style.height =
        this.elementRef.nativeElement.querySelector('co-card').offsetHeight +
        'px';
      this.elementRef.nativeElement.querySelector('.container').style.height =
        this.elementRef.nativeElement.querySelector('.container').offsetHeight +
        'px';
      this.elementRef.nativeElement.querySelector(
        '.overlay-static'
      ).style.width = this.width;
      this.initialized = true;
    }, 1000);
  }
}
