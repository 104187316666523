import {
  NgbDateParserFormatter,
  NgbDateStruct
} from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { Injectable } from '@angular/core';
@Injectable()
export class NgbMomentDateParserFormatter extends NgbDateParserFormatter {
  readonly DT_FORMAT = 'DD-MM-YYYY';

  parse(value: string): NgbDateStruct {
    if (value) {
      value = value.trim();
      const mdt = moment(value, this.DT_FORMAT);

      return {
        day: mdt.date(),
        month: mdt.month() + 1,
        year: mdt.year()
      };
    }

    return null;
  }

  format(date: NgbDateStruct): string {
    return this.toFormat(date, this.DT_FORMAT);
  }

  toFormat(date: NgbDateStruct, format: string): string {
    if (!date) {
      return '';
    }

    const mdt = moment([date.year, date.month - 1, date.day]);

    if (!mdt.isValid()) {
      return '';
    }

    return mdt.format(format);
  }
}
