import { Injectable } from '@angular/core';
import { OrderItem } from '@pfa/core';
import { InputConfigs, UnsavedChangesTypes } from '@mitpfa/shared';
import { LocScale } from '@pfa/gen';

@Injectable({
  providedIn: 'root'
})
export class InsuranceAdjustmentTrackingService {
  private readonly TRACKING_CHANGE_NAME_LOC = 'pfaoccupationalcapacity';
  private readonly TRACKING_CHANGE_NAME_LIFE = 'pfalife';
  private readonly TRACKING_CHANGE_NAME_CI = 'pfacriticalillness';
  private readonly TRACKING_CHANGE_DIRECTION_RAISE = 'raise coverage';
  private readonly TRACKING_CHANGE_DIRECTION_LOWER = 'lower coverage';

  getInsuranceDeviationTrackingName(
    loc: boolean,
    life: boolean,
    ci: boolean
  ): string {
    let result = 'insurance deviation -';
    if (loc) {
      result += ` ${this.TRACKING_CHANGE_NAME_LOC}`;
    }
    if (life) {
      result += ` ${this.TRACKING_CHANGE_NAME_LIFE}`;
    }
    if (ci) {
      result += ` ${this.TRACKING_CHANGE_NAME_CI}`;
    }
    return result;
  }

  private getOrderItem(
    wantedAmount: number,
    originalAmount: number,
    trackingName: string,
    locScale?: LocScale
  ): OrderItem {
    const direction =
      wantedAmount > originalAmount
        ? this.TRACKING_CHANGE_DIRECTION_RAISE
        : this.TRACKING_CHANGE_DIRECTION_LOWER;
    let taeATText = '';
    if (locScale === 'LOC_80' || locScale === 'LOC_100') {
      const directionText =
        direction === 'lower coverage' ? 'lower to' : 'raise to';
      switch (locScale) {
        case 'LOC_80':
          taeATText = `${directionText} automaticadjustment80`;
          break;
        case 'LOC_100':
          taeATText = `${directionText} automaticadjustment100`;
          break;
      }
    }
    return {
      category: 'insurance',
      name: `${trackingName} - ${taeATText ? taeATText : direction}`
    } as OrderItem;
  }

  getOrderItems(
    unsavedChangesTypes: UnsavedChangesTypes,
    inputConfigs: InputConfigs
  ): OrderItem[] {
    const result = new Array<OrderItem>();
    if (unsavedChangesTypes.loc) {
      result.push(
        this.getOrderItem(
          inputConfigs.loc.regularPayout.amount ?? 0,
          inputConfigs.loc.regularPayout.amountOriginal,
          this.TRACKING_CHANGE_NAME_LOC,
          inputConfigs.loc.regularPayout.locScale
        )
      );
    }
    if (unsavedChangesTypes.ci) {
      result.push(
        this.getOrderItem(
          inputConfigs.ci.self.amount ?? 0,
          inputConfigs.ci.self.amountOriginal,
          this.TRACKING_CHANGE_NAME_CI
        )
      );
    }
    if (unsavedChangesTypes.life) {
      result.push(
        this.getOrderItem(
          inputConfigs.life.self.amount ?? 0,
          inputConfigs.life.self.amountOriginal,
          this.TRACKING_CHANGE_NAME_LIFE
        )
      );
    }
    return result;
  }
}
