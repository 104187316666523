import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import {RestCacheEntry} from "./RestCacheEntry";
import {GenericStore} from "./GenericStore";
import * as JoiObj from "joi";

import {
        InForceSelectionPensionEstimate,
        InForceSelectionPensionEstimateSchema,
        IndbetalingOversigtPolice,
        IndbetalingOversigtPoliceSchema,
        MinOpPlan,
        MinOpPlanSchema,
        MinOpsparingsplan,
        MinOpsparingsplanSchema,
        OpsparingOptimeringSvar,
        OpsparingOptimeringSvarSchema,
        OpsparingsplanDetaljer,
        OpsparingsplanDetaljerSchema,
        SuccessResponse,
        SuccessResponseSchema
} from '../model/models';

import { SavingsPlanService } from './savingsPlan.service';


@Injectable({
    providedIn: 'root'
})
export class SavingsPlanStore extends GenericStore {

            private opsparingsplanIndexCache: Map<string, RestCacheEntry<OpsparingsplanDetaljer>> = new Map<string, RestCacheEntry<OpsparingsplanDetaljer>>();

    constructor(private savingsPlanService: SavingsPlanService) {
        super();
    }

    invalidateAll(): void {
                
                
                
                
                
                this.invalidateopsparingsplanIndex();
                
                
                
                
                
                
                
                
    }




                    public opsparingsplanConfirmpensionestimatePost(inForceSelectionPensionEstimate?: InForceSelectionPensionEstimate) : Observable<any> {
                        return this.savingsPlanService.opsparingsplanConfirmpensionestimatePost(inForceSelectionPensionEstimate).pipe(
                            map(value => {
                                
                                
                                return value;
                            })
                        );
                    }






                    public opsparingsplanFrivilligindbetalingPost(minOpPlan?: MinOpPlan) : Observable<MinOpPlan> {
                        return this.savingsPlanService.opsparingsplanFrivilligindbetalingPost(minOpPlan).pipe(
                            map(value => {
                                
                                
                                        this.throwOrHandleValidationError(MinOpPlanSchema, value);
                                return value;
                            })
                        );
                    }




                public opsparingsplanGet() : Observable<OpsparingsplanDetaljer> {
                    let hash = "0";
                    if (this.opsparingsplanIndexCache.has(hash)) {
                        return this.opsparingsplanIndexCache.get(hash)!.observable;
                    } else {
                        let opsparingsplanIndexSubject: BehaviorSubject<0> = new BehaviorSubject(0);
                        let opsparingsplanIndex = this.initiateStream(opsparingsplanIndexSubject, this.savingsPlanService.opsparingsplanGet(), OpsparingsplanDetaljerSchema);
                        this.opsparingsplanIndexCache.set(hash, new RestCacheEntry<OpsparingsplanDetaljer>(opsparingsplanIndex, opsparingsplanIndexSubject));
                        return opsparingsplanIndex;
                    }
                }

                public invalidateopsparingsplanIndex() {
                    this.opsparingsplanIndexCache.forEach((cacheEntry) => {
                        cacheEntry.subject.next(0);
                    })
                }








                    public opsparingsplanMinopsparingsplanPost(minOpsparingsplan?: MinOpsparingsplan) : Observable<SuccessResponse> {
                        return this.savingsPlanService.opsparingsplanMinopsparingsplanPost(minOpsparingsplan).pipe(
                            map(value => {
                                
                                
                                        this.throwOrHandleValidationError(SuccessResponseSchema, value);
                                return value;
                            })
                        );
                    }






                    public opsparingsplanOpdateropsparingsformPost(indbetalingOversigtPolice?: IndbetalingOversigtPolice) : Observable<SuccessResponse> {
                        return this.savingsPlanService.opsparingsplanOpdateropsparingsformPost(indbetalingOversigtPolice).pipe(
                            map(value => {
                                
                                
                                        this.throwOrHandleValidationError(SuccessResponseSchema, value);
                                return value;
                            })
                        );
                    }






                    public opsparingsplanOpsparingsformOptimerPost(minOpPlan?: MinOpPlan) : Observable<OpsparingOptimeringSvar> {
                        return this.savingsPlanService.opsparingsplanOpsparingsformOptimerPost(minOpPlan).pipe(
                            map(value => {
                                
                                
                                        this.throwOrHandleValidationError(OpsparingOptimeringSvarSchema, value);
                                return value;
                            })
                        );
                    }






                    public opsparingsplanSimulerePost(minOpPlan?: MinOpPlan) : Observable<MinOpPlan> {
                        return this.savingsPlanService.opsparingsplanSimulerePost(minOpPlan).pipe(
                            map(value => {
                                
                                
                                        this.throwOrHandleValidationError(MinOpPlanSchema, value);
                                return value;
                            })
                        );
                    }




}
