/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const BaseVaegtObjectSchema = JoiObj.object({
        da:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        en:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        vaegt:
            
                    JoiObj.number()
                


.required()


        
    })
.id('BaseVaegtObjectSchema')

export interface BaseVaegtObject { 
    da: string;
    en: string;
    vaegt: number;
}

