/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BeloebSkattefrihed } from './beloebSkattefrihed';
import { BeloebSkattefrihedSchema } from './beloebSkattefrihed';
import * as JoiObj from "joi";


    export const PensionsplanPoliceSchema = JoiObj.object({
        aarligIndbetaling:
            
                    JoiObj.number()
                

.allow(null)



        ,
        begunstigelse:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        begunstigelseITekst:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        beloebSkattefrihed:
                JoiObj.array().items(
        
        JoiObj.link('#BeloebSkattefrihedSchema')

        )
        
.allow(null)



        ,
        egenIndbetaling:
            
                    JoiObj.number()
                


.required()


        ,
        oprettet:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        opsparing:
            
                    JoiObj.number()
                


.required()


        ,
        orlov:
            
                    JoiObj.boolean()
                


.required()


        ,
        orlovSlutDato:
            
            JoiObj.date()

.allow(null)



        ,
        pensionsordning:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        primaerPolice:
            
                    JoiObj.boolean()
                


.required()


        ,
        selskab:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        skattefrihedUkendt:
            
                    JoiObj.boolean()
                


.required()


        ,
        taxFreeAmount:
            
                    JoiObj.number()
                

.allow(null)



        ,
        valgt:
            
                    JoiObj.boolean()
                


.required()


        ,
        valgtOpsparingsformContentId:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        
    })
                .shared(BeloebSkattefrihedSchema)
.id('PensionsplanPoliceSchema')

export interface PensionsplanPolice { 
    aarligIndbetaling?: number;
    begunstigelse?: string;
    begunstigelseITekst?: string;
    beloebSkattefrihed?: Array<BeloebSkattefrihed>;
    egenIndbetaling: number;
    oprettet: string;
    opsparing: number;
    orlov: boolean;
    orlovSlutDato?: Date;
    pensionsordning: string;
    primaerPolice: boolean;
    selskab: string;
    skattefrihedUkendt: boolean;
    taxFreeAmount?: number;
    valgt: boolean;
    valgtOpsparingsformContentId?: string;
}

