/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const BeneficiarySalesOrganizationSchema = JoiObj.string().valid(
        'PFA'
        ,
        'LETPENSION'
        
    )
.id('BeneficiarySalesOrganizationSchema')

export type BeneficiarySalesOrganization = 'PFA' | 'LETPENSION';

export const BeneficiarySalesOrganization = {
    Pfa: 'PFA' as BeneficiarySalesOrganization,
    Letpension: 'LETPENSION' as BeneficiarySalesOrganization
};

