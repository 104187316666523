import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import {RestCacheEntry} from "./RestCacheEntry";
import {GenericStore} from "./GenericStore";
import * as JoiObj from "joi";

import {
        HentIndbetalingsaftaleRequest,
        HentIndbetalingsaftaleRequestSchema,
        HentIndbetalingsaftaleResponse,
        HentIndbetalingsaftaleResponseSchema
} from '../model/models';

import { IndbetalingsaftalerService } from './indbetalingsaftaler.service';


@Injectable({
    providedIn: 'root'
})
export class IndbetalingsaftalerStore extends GenericStore {

            private indbetalingsaftalerkvitteringIndexCache: Map<string, RestCacheEntry<Blob>> = new Map<string, RestCacheEntry<Blob>>();

    constructor(private indbetalingsaftalerService: IndbetalingsaftalerService) {
        super();
    }

    invalidateAll(): void {
                
                
                
                this.invalidateindbetalingsaftalerkvitteringIndex();
    }




                    public indbetalingsaftalerHentIndbetalingsaftalePost(hentIndbetalingsaftaleRequest?: HentIndbetalingsaftaleRequest) : Observable<HentIndbetalingsaftaleResponse> {
                        return this.indbetalingsaftalerService.indbetalingsaftalerHentIndbetalingsaftalePost(hentIndbetalingsaftaleRequest).pipe(
                            map(value => {
                                
                                
                                        this.throwOrHandleValidationError(HentIndbetalingsaftaleResponseSchema, value);
                                return value;
                            })
                        );
                    }




                public indbetalingsaftalerKvitteringGet(token?: string,cpr?: string) : Observable<Blob> {
                    let hash = this.hashKey((token?.toString() || "0") + (cpr?.toString() || "0"));
                    if (this.indbetalingsaftalerkvitteringIndexCache.has(hash)) {
                        return this.indbetalingsaftalerkvitteringIndexCache.get(hash)!.observable;
                    } else {
                        let indbetalingsaftalerkvitteringIndexSubject: BehaviorSubject<0> = new BehaviorSubject(0);
                        let indbetalingsaftalerkvitteringIndex = this.initiateStream(indbetalingsaftalerkvitteringIndexSubject, this.indbetalingsaftalerService.indbetalingsaftalerKvitteringGet(token,cpr), JoiObj.any());
                        this.indbetalingsaftalerkvitteringIndexCache.set(hash, new RestCacheEntry<Blob>(indbetalingsaftalerkvitteringIndex, indbetalingsaftalerkvitteringIndexSubject));
                        return indbetalingsaftalerkvitteringIndex;
                    }
                }

                public invalidateindbetalingsaftalerkvitteringIndex() {
                    this.indbetalingsaftalerkvitteringIndexCache.forEach((cacheEntry) => {
                        cacheEntry.subject.next(0);
                    })
                }






}
