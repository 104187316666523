import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { InvestmentApiService } from '@pfa/api';
import { ClimateFootprintPolicyData, ClimatefootprintStore } from '@pfa/gen';
import { PopupStyle } from '@pfa/models';
import { forkJoin, of } from 'rxjs';
import { finalize, switchMap } from 'rxjs/operators';
import { ClimateFootprintDisclaimerComponent } from './climate-footprint-disclaimer/climate-footprint-disclaimer.component';
import {
  ClimateFootprintService,
  TrackingEventNames
} from './climate-footprint.service';
import { PageTrackingService, PopupTrackingService } from '@pfa/core';
import { Router } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

interface ClimateFootprint {
  totalSavings: number;
  totalKm: number;
  totalCo2Emission: number;
  totalCo2PerYear: number;
  totalElectricity: number;
}

@Component({
  selector: 'mitpfa-climate-footprint',
  templateUrl: './climate-footprint.component.html',
  styleUrls: ['./climate-footprint.component.scss']
})
export class ClimateFootprintComponent implements OnInit {
  private readonly investmentApiService: InvestmentApiService =
    inject(InvestmentApiService);
  private readonly climatefootprintStore: ClimatefootprintStore = inject(
    ClimatefootprintStore
  );
  private readonly climateFootprintService: ClimateFootprintService = inject(
    ClimateFootprintService
  );
  private readonly dialog: MatDialog = inject(MatDialog);
  private readonly popupTrackingService: PopupTrackingService =
    inject(PopupTrackingService);
  private readonly router: Router = inject(Router);
  private readonly pageTrackingService: PageTrackingService =
    inject(PageTrackingService);
  private readonly destroyRef: DestroyRef = inject(DestroyRef);

  public dataLoaded: boolean;
  public showError: boolean;
  public footprint: ClimateFootprint;

  public ngOnInit(): void {
    this.footprint = {
      totalSavings: 0,
      totalKm: 0,
      totalCo2Emission: 0,
      totalCo2PerYear: 0,
      totalElectricity: 0
    };

    this.dataLoaded = false;
    this.investmentApiService
      .get()
      .pipe(
        switchMap(investments => {
          if (investments.fejl) {
            this.showError = true;
            return of([]);
          }

          const policies = this.climateFootprintService.filterPolicies(
            investments.detaljer.policer
          );

          if (policies.length) {
            return forkJoin(
              policies.map(policy => {
                const inputValue =
                  this.climateFootprintService.checkInputValues(
                    policy.pfaInvesterer,
                    policy.pfaValgfri
                  );

                this.footprint.totalSavings += inputValue.savings;

                return this.climatefootprintStore.climatefootprintPost({
                  policyId: parseInt(policy.id, 10),
                  pfaPlusHigh: inputValue.pfaPlusHigh,
                  pfaPlusLow: inputValue.pfaPlusLow,
                  pfaKlimaPlus: policy.klimaPlusAndel,
                  totalSavingsAmount: inputValue.savings
                } as ClimateFootprintPolicyData);
              })
            );
          } else {
            return of([]);
          }
        }),
        finalize(() => {
          this.dataLoaded = true;
          this.pageTrackingService.trackContentLoaded();
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe({
        next: response => {
          if (!response.length) {
            this.showError = true;
            return;
          }

          response.forEach(item => {
            this.footprint.totalKm += item.carKmDrivenPerYear;
            this.footprint.totalCo2Emission +=
              item.co2EmissionReductionTonsPerYear;
            this.footprint.totalCo2PerYear += item.co2UsagePercentPerYear;
            this.footprint.totalElectricity +=
              item.electricityUsageReductionTonsPerYear;

            if (item.calculationError) {
              this.showError = true;
            }
          });
        },
        error: () => {
          this.showError = true;
        }
      });
  }

  public openDisclaimer(): void {
    const matDialogRef = this.dialog.open(ClimateFootprintDisclaimerComponent, {
      disableClose: true,
      autoFocus: false,
      panelClass: PopupStyle.PopupLarge
    });

    matDialogRef
      .afterOpened()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() =>
        this.popupTrackingService.trackPopupViewed(
          TrackingEventNames.CARBON_FOOTPRINT
        )
      );
    matDialogRef
      .afterClosed()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() =>
        this.popupTrackingService.trackPopupClosed(
          TrackingEventNames.CARBON_FOOTPRINT
        )
      );
  }

  public openGuide(): void {
    this.pageTrackingService.trackNavigation(
      TrackingEventNames.CARBON_FOOTPRINT,
      'investment guide',
      '/mineinvesteringer/guide'
    );
    this.router.navigate(['/mineinvesteringer/guide']);
  }
}
