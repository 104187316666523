<div
  class="card--container"
  [ngClass]="{
    profile__selected: card.isSelected,
    profile__disabled: card.isDisabled,
    profile__not__selected: !card.isSelected
  }"
>
  <div
    *ngIf="card.isSelected && showCheckmark"
    class="icon-checkmark-container"
    [@slideInBottom]="{ value: '', params: { duration: 300, delay: 0 } }"
  >
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="8 8 16 16">
      <g id="Icon">
        <polyline
          id="Checkmark"
          points="22.36 11.76 13.88 20.24 9.64 16"
          fill="none"
          stroke="#FFF"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="3"
        />
      </g>
    </svg>
  </div>
  <div class="card">
    <div
      *ngIf="card.isRecommended"
      [coContent]="'DL.INV.C473'"
      class="recommendation"
    >
      PFA anbefaler dig
    </div>
    <div class="title card-title">
      <span class="text--bold" [coContent]="card.title"></span>
      <co-tooltip
        *ngIf="card.titleToolTip"
        class="tooltip"
        [text]="card.titleToolTip"
      ></co-tooltip>
    </div>
    <div class="card-header"><ng-content select="[header]"></ng-content></div>
    <div class="card-content">
      <div *ngFor="let benefitItem of card.benefitItems" class="benefit-item">
        <div
          [ngClass]="{
            'benefit-item--checkmark': !card.hideBenefitCheckmark,
            'benefit-item--black': benefitItem.bulletColor === 'BLACK'
          }"
        ></div>
        <div [ngClass]="{ 'benefit-item--text': !card.hideBenefitCheckmark }">
          <p [coContent]="benefitItem.text"></p>
        </div>
      </div>
      <ng-content select="[content]"></ng-content>
    </div>
    <div class="card-footer align--center">
      <ng-content select="[footer]"></ng-content>
    </div>
  </div>
</div>
