import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class DateTimeService {
  getDateFormatted(dateTime?: Date | object): string {
    if (dateTime) {
      const timestamp = moment(dateTime);
      return timestamp.format('DD.MM.YYYY');
    } else {
      return '';
    }
  }

  getTimeFormatted(dateTime?: Date | object): string {
    if (dateTime) {
      const timestamp = moment(dateTime);
      return timestamp.format('HH.mm');
    } else {
      return '';
    }
  }
}
