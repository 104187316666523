import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { CardQuestionComponent } from '../../card-question/card-question.component';
import { CardSplitHorizontalComponent } from '../../card-split-horizontal/card-split-horizontal.component';
import { CardComponent } from '../../card/card.component';

export class StackItem {
  style = new BehaviorSubject<{ [key: string]: string }>({});
  class = new BehaviorSubject<{ [key: string]: boolean }>({});
  zIndex: number;
  component:
    | CardComponent
    | CardQuestionComponent
    | CardSplitHorizontalComponent;
  contentHeight: number;
  maxWidth?: string;

  public setAsInactive(inactivesAmount: number) {
    this.class.next({ inactive: true });
    this.style.next({
      zIndex: this.zIndex.toString(),
      opacity: inactivesAmount > 2 ? '0' : null,
      transform: `scaleX(${(20 - inactivesAmount) / 20})
        translate3d(0, ${8 * inactivesAmount}px, 0)`
    });
  }
}

@Component({
  selector: 'co-card-animated-stack-item',
  templateUrl: 'card-animated-stack-item.component.html',
  styleUrls: ['./card-animated-stack-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardAnimatedStackItemComponent implements OnInit, OnDestroy {
  @Input() maxWidth?: string;
  @Input() scrollToTopOnActivate?: boolean;

  private subscriptions: { [subscribtionKey: string]: Subscription } = {};
  public stackItem = new StackItem();

  @ContentChild(CardQuestionComponent, { static: false }) set cardQuestion(
    component: CardQuestionComponent
  ) {
    if (component) {
      this.stackItem.component = component;
    }
  }
  @ContentChild(CardComponent, { static: false }) set card(
    component: CardComponent
  ) {
    if (component) {
      this.stackItem.component = component;
    }
  }
  @ContentChild(CardSplitHorizontalComponent, { static: false })
  set cardSplitHorizontal(component: CardSplitHorizontalComponent) {
    if (component) {
      this.stackItem.component = component;
    }
  }

  constructor(
    readonly cdRef: ChangeDetectorRef,
    readonly elementRef: ElementRef
  ) {}

  ngOnInit(): void {
    this.stackItem.maxWidth = this.maxWidth;

    this.subscriptions.style = this.stackItem.style.subscribe(value => {
      this.elementRef.nativeElement.style.opacity = value.opacity ?? null;
      this.elementRef.nativeElement.style.display = value.display ?? null;
      this.elementRef.nativeElement.style.transform = value.transform ?? null;
      this.elementRef.nativeElement.style.zIndex = value.zIndex ?? null;
      this.elementRef.nativeElement.style.maxWidth = value.maxWidth ?? null;
    });

    this.subscriptions.class = this.stackItem.class.subscribe(value => {
      if (value.active) {
        this.elementRef.nativeElement.classList.add('active');
        if (this.scrollToTopOnActivate) {
          window.scroll(0, 0);
        }
      } else {
        this.elementRef.nativeElement.classList.remove('active');
      }

      if (value.inactive) {
        this.elementRef.nativeElement.classList.add('inactive');
      } else {
        this.elementRef.nativeElement.classList.remove('inactive');
      }

      if (value.removed) {
        this.elementRef.nativeElement.classList.add('removed');
      } else {
        this.elementRef.nativeElement.classList.remove('removed');
      }

      if (value.rotate) {
        this.elementRef.nativeElement.classList.add('rotate');
      } else {
        this.elementRef.nativeElement.classList.remove('rotate');
      }
    });
  }

  ngOnDestroy(): void {
    Object.keys(this.subscriptions).forEach(
      key => this.subscriptions[key]?.unsubscribe()
    );
  }
}
