import { PreconditionsModalComponent } from './preconditions-modal/preconditions-modal.component';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ForbeholdModalComponent } from './forbehold/forbehold-modal.component';
import { PopupUtilService } from '@pfa/core';
import { ArticleModalComponent } from './articles/article-modal/article-modal.component';

@Injectable()
export class PopupUtilServiceImpl implements PopupUtilService {
  constructor(public dialog: MatDialog) {}

  public popupForbehold(selected: string): void {
    const modalRef = this.dialog.open(ForbeholdModalComponent, {});
    modalRef.componentInstance.setSelected(selected);
  }

  public popupPreconditions(selected: string): void {
    if (selected === '/forbehold') {
      selected = 'forudsaetning';
    } else {
      selected = selected.slice(
        selected.indexOf('/forbehold/') + '/forbehold/'.length
      );
    }
    const modalRef = this.dialog.open(PreconditionsModalComponent, {});
    modalRef.componentInstance.selected = selected;
  }

  public popupArticle(selected: string): void {
    const modalRef = this.dialog.open(ArticleModalComponent, {
      disableClose: true,
      autoFocus: false
    });
    modalRef.componentInstance.selected = selected;
  }
}
