<svg
  id="Layer_1"
  data-name="Layer 1"
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 68 176"
>
  <g id="Layer_2" data-name="Layer 2">
    <g id="Motiver">
      <g id="housing_sign-up" data-name="housing sign-up">
        <rect id="Canvas" width="68" height="176" fill="none" />
        <g id="House">
          <g id="Card">
            <path
              id="Background"
              d="M9.9,75.78H49.7a2.81,2.81,0,0,1,2.82,2.81h0v37.65H7.08V78.6A2.82,2.82,0,0,1,9.9,75.78Z"
              fill="#c16666"
            />
            <path
              id="Text_area"
              data-name="Text area"
              d="M7.08,116.25H52.52v7.8a2.83,2.83,0,0,1-2.82,2.83H9.91a2.83,2.83,0,0,1-2.83-2.83v-7.8Z"
              fill="#ccc"
            />
            <line
              id="Top_line"
              data-name="Top line"
              x1="11.47"
              y1="119.98"
              x2="48.21"
              y2="119.98"
              fill="none"
              stroke="#f2f2f2"
              stroke-linecap="round"
              stroke-miterlimit="10"
              stroke-width="1.25"
            />
            <line
              id="Bottom_line"
              data-name="Bottom line"
              x1="11.47"
              y1="122.98"
              x2="35.72"
              y2="122.98"
              fill="none"
              stroke="#f2f2f2"
              stroke-linecap="round"
              stroke-miterlimit="10"
              stroke-width="1.25"
            />
          </g>
          <g id="House-2">
            <path
              id="House-3"
              d="M20,99.84H38a2.49,2.49,0,0,1,2.49,2.49v11.46A2.5,2.5,0,0,1,38,116.28H17.57a.06.06,0,0,1-.06-.06V102.33A2.48,2.48,0,0,1,20,99.84Z"
              fill="#fff"
            />
            <rect
              id="Door"
              x="21.22"
              y="105.77"
              width="5.65"
              height="10.5"
              rx="1.18"
              fill="#333"
            />
            <rect
              id="Window"
              x="30.23"
              y="105.77"
              width="5.65"
              height="5.74"
              rx="1.18"
              fill="#c16666"
            />
            <rect
              id="Door_window"
              data-name="Door window"
              x="22.54"
              y="107.1"
              width="3.02"
              height="3.07"
              rx="0.63"
              fill="#c16666"
            />
            <path
              id="Cornice"
              d="M38,99.69H20a2.49,2.49,0,0,0-2.49,2.48v3H40.44v-3A2.49,2.49,0,0,0,38,99.69Z"
              fill="#ccc"
            />
            <path
              id="Roof"
              d="M40.55,104.14H17.4c-1.59,0-2.78-1.2-2.43-2.46L17.06,94a2.42,2.42,0,0,1,2.43-1.58h19A2.42,2.42,0,0,1,40.88,94l2.1,7.68C43.32,102.94,42.14,104.14,40.55,104.14Z"
            />
            <path
              id="Attic"
              d="M31.73,101.48H26.21a.49.49,0,0,1-.48-.48h0V97.22a.48.48,0,0,1,.22-.41L28.71,95a.51.51,0,0,1,.53,0L32,96.81a.48.48,0,0,1,.22.41V101A.48.48,0,0,1,31.73,101.48Z"
              fill="#fff"
            />
            <rect
              id="Top_window"
              data-name="Top window"
              x="27.19"
              y="97.16"
              width="3.55"
              height="3.6"
              rx="0.74"
              fill="#c16666"
            />
            <path
              id="Bush"
              d="M44.58,113.3a4.25,4.25,0,0,0-7.89-2.2,3.9,3.9,0,0,0-4.38,5.24h11A4.24,4.24,0,0,0,44.58,113.3Z"
              fill="#066"
            />
            <rect
              id="Chimney"
              x="21.26"
              y="89.62"
              width="3.65"
              height="4.22"
              rx="1.06"
              fill="#fff"
            />
          </g>
          <g id="Pin">
            <path
              id="Pin-2"
              d="M35.13,75.38a5.27,5.27,0,0,0-10.53,0A5.34,5.34,0,0,0,25,77.48l4.39,7a.48.48,0,0,0,.66.22.4.4,0,0,0,.22-.22l4.34-7A5.23,5.23,0,0,0,35.13,75.38Z"
              fill="#900"
            />
            <circle id="Circle" cx="29.86" cy="75.35" r="2.82" fill="#fff" />
          </g>
        </g>
        <g id="Apartments">
          <g id="Card-2">
            <path
              id="Background-2"
              d="M9.9,12.13H49.7A2.82,2.82,0,0,1,52.52,15V52.6H7.08V15A2.82,2.82,0,0,1,9.9,12.13Z"
              fill="#c16666"
            />
            <path
              id="Text_area-2"
              data-name="Text area-2"
              d="M7.08,52.6H52.52v7.68a2.82,2.82,0,0,1-2.82,2.83H9.91a2.83,2.83,0,0,1-2.83-2.82h0V52.6Z"
              fill="#ccc"
            />
            <line
              id="Top_line-2"
              data-name="Top line-2"
              x1="11.47"
              y1="56.34"
              x2="48.21"
              y2="56.34"
              fill="none"
              stroke="#f2f2f2"
              stroke-linecap="round"
              stroke-miterlimit="10"
              stroke-width="1.25"
            />
            <line
              id="Bottom_line-2"
              data-name="Bottom line-2"
              x1="11.47"
              y1="59.22"
              x2="35.72"
              y2="59.22"
              fill="none"
              stroke="#f2f2f2"
              stroke-linecap="round"
              stroke-miterlimit="10"
              stroke-width="1.25"
            />
          </g>
          <g id="Apartments-2">
            <path
              id="Building"
              d="M45,52.62H15.52c-2,0-2.76-1.52-2.76-3.4V30.05c0-1.88.7-3.4,2.76-3.4H45c2.06,0,2,1.52,2,3.4V49.22C47,51.1,47,52.62,45,52.62Z"
              fill="#fff"
            />
            <g id="Building_windows" data-name="Building windows">
              <rect
                id="Window_7_bottom_right"
                data-name="Window 7 bottom right"
                x="39.27"
                y="43.95"
                width="4.66"
                height="4.74"
                rx="0.97"
                fill="#c16666"
              />
              <rect
                id="Window_6"
                data-name="Window 6"
                x="31.41"
                y="43.95"
                width="4.66"
                height="4.74"
                rx="0.97"
                fill="#c16666"
              />
              <rect
                id="Window_5"
                data-name="Window 5"
                x="15.39"
                y="43.95"
                width="4.66"
                height="4.74"
                rx="0.97"
                fill="#c16666"
              />
              <rect
                id="Window_4"
                data-name="Window 4"
                x="39.27"
                y="36.69"
                width="4.66"
                height="4.74"
                rx="0.97"
                fill="#c16666"
              />
              <rect
                id="Window_3"
                data-name="Window 3"
                x="31.41"
                y="36.69"
                width="4.66"
                height="4.74"
                rx="0.97"
                fill="#c16666"
              />
              <rect
                id="Window_2"
                data-name="Window 2"
                x="23.5"
                y="36.69"
                width="4.66"
                height="4.74"
                rx="0.97"
                fill="#c16666"
              />
              <rect
                id="Window_1_top_left"
                data-name="Window 1 top left"
                x="15.39"
                y="36.69"
                width="4.66"
                height="4.74"
                rx="0.97"
                fill="#c16666"
              />
            </g>
            <g id="Door-2">
              <rect
                id="Door-3"
                x="23.39"
                y="43.95"
                width="4.66"
                height="8.67"
                rx="0.97"
                fill="#333"
              />
              <rect
                id="Door_window-2"
                data-name="Door window-2"
                x="24.47"
                y="45.05"
                width="2.49"
                height="2.53"
                rx="0.52"
                fill="#c16666"
              />
            </g>
            <path
              id="Cornice-2"
              d="M36.61,30.75H15c-1.78,0-2.26.92-2.26,2v2.47H47V32.8C47,31.67,38.39,30.75,36.61,30.75Z"
              fill="#ccc"
            />
            <path
              id="Roof-2"
              d="M47,34H12.72c-1.32,0-2.3-1-2-2l1.73-6.34a2,2,0,0,1,2-1.31H45.26a2,2,0,0,1,2,1.31L49,32C49.29,33.06,48.31,34,47,34Z"
            />
            <path
              id="Left_bush"
              data-name="Left bush"
              d="M9.3,50.12a3.5,3.5,0,0,1,6.51-1.81,3.21,3.21,0,0,1,3.61,4.32H10.36A3.49,3.49,0,0,1,9.3,50.12Z"
              fill="#066"
            />
            <path
              id="Right_bush"
              data-name="Right bush"
              d="M49.58,50.6a2.83,2.83,0,0,0-2.82-2.83,2.79,2.79,0,0,0-2.43,1.37,2.59,2.59,0,0,0-3,2,2.66,2.66,0,0,0,.13,1.45h7.31A2.85,2.85,0,0,0,49.58,50.6Z"
              fill="#066"
            />
            <rect
              id="Chimney-2"
              x="18.91"
              y="21.97"
              width="3.01"
              height="3.49"
              rx="0.88"
              fill="#fff"
            />
            <g id="Roof_windows" data-name="Roof windows">
              <rect
                id="Window_1_left"
                data-name="Window 1 left"
                x="16.6"
                y="27.61"
                width="2.42"
                height="3.4"
                rx="0.7"
                fill="#c16666"
              />
              <rect
                id="Window_2-2"
                data-name="Window 2-2"
                x="22.38"
                y="27.61"
                width="2.42"
                height="3.4"
                rx="0.7"
                fill="#c16666"
              />
              <rect
                id="Window_3-2"
                data-name="Window 3-2"
                x="28.61"
                y="27.61"
                width="2.42"
                height="3.4"
                rx="0.7"
                fill="#c16666"
              />
              <rect
                id="Window_4-2"
                data-name="Window 4-2"
                x="34.4"
                y="27.61"
                width="2.42"
                height="3.4"
                rx="0.7"
                fill="#c16666"
              />
              <rect
                id="Window_5_right"
                data-name="Window 5 right"
                x="40.22"
                y="27.61"
                width="2.42"
                height="3.4"
                rx="0.7"
                fill="#c16666"
              />
            </g>
          </g>
          <g id="Pin-3">
            <path
              id="Pin-4"
              d="M35.13,11.73a5.27,5.27,0,1,0-10.53,0A5.38,5.38,0,0,0,25,13.84l4.39,7a.49.49,0,0,0,.66.22.47.47,0,0,0,.22-.22l4.34-7A5.24,5.24,0,0,0,35.13,11.73Z"
              fill="#900"
            />
            <circle id="Circle-2" cx="29.86" cy="11.71" r="2.82" fill="#fff" />
          </g>
        </g>
        <g id="Button">
          <polyline
            id="Pointer"
            points="55.81 171.28 57.86 169.89 52.39 162.01 58.69 159.69 42.17 150.94 42.16 150.95 44.24 169.53 48.7 164.52 54.02 172.5 56.08 171.1"
          />
          <path
            id="Button-2"
            d="M40.87,149.05h0l6.44,3.42a3.2,3.2,0,0,0,.93-2.26v-7.44A3.22,3.22,0,0,0,45,139.54H14.79a3.22,3.22,0,0,0-3.22,3.22h0v7.44a3.22,3.22,0,0,0,3.22,3.22H41.36Z"
            fill="#900"
          />
          <text
            transform="translate(17.77 148.16)"
            font-size="5.13"
            fill="#fff"
            font-family="ArialRoundedMTBold, Arial Rounded MT Bold"
            letter-spacing="-0.02em"
            style="isolation: isolate"
          >
            SKRIV OP
          </text>
        </g>
        <g id="Unfilled_checkmark" data-name="Unfilled checkmark">
          <rect
            id="Frame"
            x="56.54"
            y="96.12"
            width="10.4"
            height="10.4"
            rx="2.57"
            fill="#f2f2f2"
            stroke="#666"
            stroke-miterlimit="10"
            stroke-width="1.23"
          />
        </g>
        <g id="Filled_checkmark" data-name="Filled checkmark">
          <rect
            id="Frame-2"
            x="56.54"
            y="32.42"
            width="10.4"
            height="10.4"
            rx="2.57"
            fill="#f2f2f2"
            stroke="#666"
            stroke-miterlimit="10"
            stroke-width="1.23"
          />
          <polyline
            id="Checkmark"
            points="59.08 36.9 61.71 40.28 65.99 31.55"
            fill="none"
            stroke="#338484"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.38"
          />
        </g>
      </g>
    </g>
  </g>
</svg>
