/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const HandelDetaljerSchema = JoiObj.object({
        fondKode:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        fondNavn:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        fondOmkostningerBeloeb:
            
                    JoiObj.number()
                


.required()


        ,
        fondkoeb:
            
                    JoiObj.boolean()
                


.required()


        ,
        fondsalg:
            
                    JoiObj.boolean()
                


.required()


        ,
        koebKurs:
            
                    JoiObj.object()
                


.required()


        ,
        koebUnits:
            
                    JoiObj.object()
                


.required()


        ,
        koebVaerdiBeloeb:
            
                    JoiObj.number()
                


.required()


        ,
        kursDato:
            
                    JoiObj.object()
                


.required()


        ,
        salgKurs:
            
                    JoiObj.object()
                


.required()


        ,
        salgUnits:
            
                    JoiObj.object()
                


.required()


        ,
        salgVaerdiBeloeb:
            
                    JoiObj.number()
                


.required()


        
    })
.id('HandelDetaljerSchema')

export interface HandelDetaljer { 
    fondKode: string;
    fondNavn: string;
    fondOmkostningerBeloeb: number;
    fondkoeb: boolean;
    fondsalg: boolean;
    koebKurs: object;
    koebUnits: object;
    koebVaerdiBeloeb: number;
    kursDato: object;
    salgKurs: object;
    salgUnits: object;
    salgVaerdiBeloeb: number;
}

