/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OversigtForsikring } from './oversigtForsikring';
import { OversigtForsikringSchema } from './oversigtForsikring';
import { HelbredsSikring } from './helbredsSikring';
import { HelbredsSikringSchema } from './helbredsSikring';
import * as JoiObj from "joi";


    export const SundhedsForsikringSchema = JoiObj.object({
        diagnoseSikringer:
                JoiObj.array().items(
        
        JoiObj.link('#HelbredsSikringSchema')

        )
        

.required()


        ,
        helbredsSikringer:
                JoiObj.array().items(
        
        JoiObj.link('#HelbredsSikringSchema')

        )
        

.required()


        ,
        sundhedsForsikringer:
                JoiObj.array().items(
        
        JoiObj.link('#OversigtForsikringSchema')

        )
        

.required()


        ,
        visBasisDaekning:
            
                    JoiObj.boolean()
                


.required()


        ,
        visDiagnose:
            
                    JoiObj.boolean()
                


.required()


        ,
        visDiagnoseArbejde:
            
                    JoiObj.boolean()
                


.required()


        ,
        visDiagnoseFritid:
            
                    JoiObj.boolean()
                


.required()


        ,
        visEkstra:
            
                    JoiObj.boolean()
                


.required()


        ,
        visHelbredSikring:
            
                    JoiObj.boolean()
                


.required()


        ,
        visIkkeDaekket:
            
                    JoiObj.boolean()
                


.required()


        ,
        visKieropraktor:
            
                    JoiObj.boolean()
                


.required()


        ,
        visUdvidet:
            
                    JoiObj.boolean()
                


.required()


        
    })
                .shared(HelbredsSikringSchema)
                .shared(HelbredsSikringSchema)
                .shared(OversigtForsikringSchema)
.id('SundhedsForsikringSchema')

export interface SundhedsForsikring { 
    diagnoseSikringer: Array<HelbredsSikring>;
    helbredsSikringer: Array<HelbredsSikring>;
    sundhedsForsikringer: Array<OversigtForsikring>;
    visBasisDaekning: boolean;
    visDiagnose: boolean;
    visDiagnoseArbejde: boolean;
    visDiagnoseFritid: boolean;
    visEkstra: boolean;
    visHelbredSikring: boolean;
    visIkkeDaekket: boolean;
    visKieropraktor: boolean;
    visUdvidet: boolean;
}

