/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const AdvancedPayoutWarningsSchema = JoiObj.object({
        addressAbroadWarning:
            
                    JoiObj.boolean()
                


.required()


        ,
        averageRatePolicyWarning:
            
                    JoiObj.boolean()
                


.required()


        ,
        paragraph53aWarning:
            
                    JoiObj.boolean()
                


.required()


        ,
        paragraph39aWarning:
            
                    JoiObj.boolean()
                


.required()


        ,
        profileGWarning:
            
                    JoiObj.boolean()
                


.required()


        ,
        insuranceCoverageWarning:
            
                    JoiObj.boolean()
                


.required()


        ,
        payFromDepotWarning:
            
                    JoiObj.boolean()
                


.required()


        ,
        lowAccountSecuredTotalMarketRatePercentWarning:
            
                    JoiObj.boolean()
                


.required()


        
    })
.id('AdvancedPayoutWarningsSchema')

export interface AdvancedPayoutWarnings { 
    addressAbroadWarning: boolean;
    averageRatePolicyWarning: boolean;
    paragraph53aWarning: boolean;
    paragraph39aWarning: boolean;
    profileGWarning: boolean;
    insuranceCoverageWarning: boolean;
    payFromDepotWarning: boolean;
    lowAccountSecuredTotalMarketRatePercentWarning: boolean;
}

