/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Broker } from './broker';
import { BrokerSchema } from './broker';
import { BeneficiaryView } from './beneficiaryView';
import { BeneficiaryViewSchema } from './beneficiaryView';
import { BeneficiarySalesOrganization } from './beneficiarySalesOrganization';
import { BeneficiarySalesOrganizationSchema } from './beneficiarySalesOrganization';
import * as JoiObj from "joi";


    export const BeneficiaryExistingResponseSchema = JoiObj.object({
        beneficiaries:
                JoiObj.array().items(
        
        JoiObj.link('#BeneficiaryViewSchema')

        )
        

.required()


        ,
        broker:
            
        JoiObj.link('#BrokerSchema')

.allow(null)



        ,
        draftId:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        organization:
            
        JoiObj.link('#BeneficiarySalesOrganizationSchema')


.required()


        ,
        will:
            
                    JoiObj.boolean()
                


.required()


        
    })
                .shared(BeneficiaryViewSchema)
                .shared(BrokerSchema)
                .shared(BeneficiarySalesOrganizationSchema)
.id('BeneficiaryExistingResponseSchema')

export interface BeneficiaryExistingResponse { 
    beneficiaries: Array<BeneficiaryView>;
    broker?: Broker;
    draftId?: string;
    organization: BeneficiarySalesOrganization;
    will: boolean;
}
export namespace BeneficiaryExistingResponse {
}


