<mat-form-field class="form-field-light inline-block" color="accent">
  <mat-label [coContent]="'Global.vis'"></mat-label>
  <mat-select
    [value]="includeArchiveData"
    (selectionChange)="selectIncludeArchiveData($event)"
  >
    <mat-option [value]="false">
      <div [coContent]="'DL.BE02.C1043'"></div>
    </mat-option>
    <mat-option [value]="true">
      <div [coContent]="'DL.BE02.C1044'"></div>
    </mat-option>
  </mat-select>
</mat-form-field>

<co-messaging-header
  [ngClass]="{ 'no-bottom-padding': toomany }"
  (composeMessage)="composeMessage()"
  [unreadCount]="unreadCount"
  [threadOpen]="threadOpen"
  [isRaadgiver]="isRaadgiver"
  [isNonCustomer]="nonCustomer"
  (refresh)="refresh()"
></co-messaging-header>

<co-loading [show]="!threads"></co-loading>

<div class="filter-threads" *ngIf="toomany">
  <div *ngIf="toomany" class="paginate-controls">
    <div *ngIf="toomany" class="paginate-text">{{ showingText }}</div>
    <co-icon-arrow-left
      (click)="prev50()"
      class="cursor-pointer"
    ></co-icon-arrow-left>
    <co-icon-arrow-right
      (click)="next50()"
      class="cursor-pointer"
    ></co-icon-arrow-right>
  </div>
</div>

<div class="messaging">
  <co-threadlist
    *ngIf="!getThreadsFailed && threadsLoaded"
    [ngClass]="{ 'thread-open': threadOpen }"
    [threads]="threads"
    (openThread)="openThread($event)"
    (closeThread)="closeThread()"
    [threadOpen]="threadOpen"
    [hasUrgent]="hasUrgent"
    [hasHighImportance]="hasHighImportance"
    [searched]="filterText !== null"
  >
  </co-threadlist>

  <co-thread
    *ngIf="threadOpen"
    [@slideInRight]
    [@slideOutRight]
    [thread]="selectedThread"
    (closeThread)="closeThread()"
  >
  </co-thread>
</div>
