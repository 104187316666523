<div class="row">
  <div class="col-md-12 d-flex justify-content-end">
    <mitpfa-investment-guide-help [broker]="broker" [isInsideCard]="true">
    </mitpfa-investment-guide-help>
  </div>
</div>

<div class="row">
  <div class="col-md-8 offset-md-2">
    <div class="description spacing-unit--double-under">
      <h3 class="title" coContent="DL.INVGU01.C433"></h3>
      <p class="text--info" coContent="DL.INVGU01.C465"></p>
    </div>
    <div class="options">
      <div
        class="option"
        [ngClass]="{ 'option--active': activeProfileIndex === 0 }"
        (click)="selectProfile(0)"
        [coContent]="choices[0].label"
      ></div>
      <div
        class="option"
        [ngClass]="{ 'option--active': activeProfileIndex === 1 }"
        [coContent]="choices[1].label"
        (click)="selectProfile(1)"
      ></div>
      <div
        class="option"
        [ngClass]="{ 'option--active': activeProfileIndex === 2 }"
        [coContent]="choices[2].label"
        (click)="selectProfile(2)"
      ></div>
    </div>
    <div class="legend">
      <div class="legend-item">
        <div class="legend-item__color color-double"></div>
        <div
          class="legend-item__label text--note"
          coContent="DL.INVGU01.C501"
        ></div>
      </div>
      <div class="legend-item">
        <div class="legend-item__color color-background"></div>
        <div
          class="legend-item__label text--note"
          coContent="DL.INVGU01.C499"
        ></div>
      </div>
    </div>
    <co-linechart
      class="chart"
      [data]="riskProfilesData[activeProfileIndex]"
      [background]="riskProfilesAreaData[activeProfileIndex]"
      [minValue]="-600"
      [maxValue]="2800"
      [margin]="0"
    ></co-linechart>
    <div class="characteristic">
      <b coContent="DL.INVGU01.C464"></b>
      <p [coContent]="choices[activeProfileIndex].text"></p>
    </div>
    <div class="centered">
      <button
        class="button-secondary next-button"
        mat-raised-button
        coContent="DL.INVGU01.C622"
        [coContentSubst]="activeProfileLabel.toLowerCase()"
        (click)="choose()"
      ></button>
      <div
        *ngIf="previouslySelectedIndex !== -1"
        class="previously-selected text--note text--bold"
      >
        <span coContent="DL.INVGU01.C623"></span>&nbsp;<span
          [coContent]="choices[previouslySelectedIndex].label"
        ></span>
      </div>
    </div>
  </div>
  <div
    class="col-md-2 spacing-unit--double-under d-flex justify-content-end pl-2"
  ></div>
</div>
