<ng-container *ngIf="!editMode; else editDateMode">
  <div class="start-payout" (click)="!isDisabled && startEditDate()">
    <strong>
      {{ selectedDate | date: 'dd.MM.yyyy' }}
    </strong>
    <co-icon-edit
      *ngIf="!isDisabled"
      [ngClass]="{ active: !isDisabled }"
      class="icon edit"
    ></co-icon-edit>
  </div>
</ng-container>
<ng-template #editDateMode>
  <div class="edit-date--wrapper">
    <mat-form-field class="start-date-edit" color="accent">
      <mat-label [coContent]="'DL.PP01.C1126'"></mat-label>
      <input
        matInput
        [required]="required"
        [matDatepicker]="startDate"
        [min]="minDate"
        [max]="maxDate"
        [formControl]="formControl"
        (dateChange)="valueChanged()"
      />
      <mat-datepicker-toggle
        matSuffix
        [for]="startDate"
      ></mat-datepicker-toggle>
      <mat-datepicker
        #startDate
        [startView]="withoutDays ? 'multi-year' : undefined"
        (monthSelected)="chosenMonthHandler($event, startDate)"
      ></mat-datepicker>
      <mat-error
        *ngIf="formGroup.controls.selectedDate.errors?.matDatepickerParse"
        [coContent]="'DL.PP01.C1127'"
      ></mat-error>
      <mat-error
        *ngIf="required && formGroup.controls.selectedDate.errors?.required"
        [coContent]="'DL.PP01.C1128'"
      >
      </mat-error>
      <mat-error
        *ngIf="formGroup.controls.selectedDate.errors?.matDatepickerMin"
        [coContent]="'DL.PP01.C1129'"
      ></mat-error>
      <mat-error
        *ngIf="formGroup.controls.selectedDate.errors?.matDatepickerMax"
        [coContent]="'DL.PP01.C1175'"
      ></mat-error>
    </mat-form-field>
    <div>
      <co-icon-circle-complete
        (click)="formGroup.valid && endEditDate()"
        [ngClass]="{ active: formGroup.valid }"
        class="icon accept"
      >
      </co-icon-circle-complete>
    </div>
  </div>
</ng-template>
