/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Kursvaern } from './kursvaern';
import { KursvaernSchema } from './kursvaern';
import * as JoiObj from "joi";


    export const SelskabSchema = JoiObj.object({
        id:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        kursvaern:
                JoiObj.array().items(
        
        JoiObj.link('#KursvaernSchema')

        )
        

.required()


        ,
        kursvaernMulig:
            
                    JoiObj.boolean()
                


.required()


        ,
        saerligeKrav:
            
                    JoiObj.boolean()
                


.required()


        ,
        tilladOverfoer:
            
                    JoiObj.boolean()
                


.required()


        
    })
                .shared(KursvaernSchema)
.id('SelskabSchema')

export interface Selskab { 
    id: string;
    kursvaern: Array<Kursvaern>;
    kursvaernMulig: boolean;
    saerligeKrav: boolean;
    tilladOverfoer: boolean;
}

