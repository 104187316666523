<div
  class="menu"
  [ngClass]="{ 'selected--menu': isMenuOpen }"
  (click)="openClose('MOBILE')"
>
  <co-icon-menu *ngIf="!isMenuOpen"></co-icon-menu>
  <co-icon-times *ngIf="isMenuOpen"></co-icon-times>
  <co-notification
    *ngIf="!isMenuOpen"
    class="header--mobile--notification"
    [amount]="notifications"
  >
  </co-notification>
</div>
<div
  class="menu--container"
  (click)="openClose(undefined)"
  *ngIf="isMenuOpen"
  [@openClose]
>
  <div (click)="stopPropagation($event)" class="submenu--container">
    <div *ngFor="let menuItem of menuItems">
      <div
        *ngIf="!menuItem.advisorOnly || isAdvisor"
        class="menu-item"
        (click)="showHideMenu(menuItem)"
      >
        <co-icon-savings *ngIf="menuItem.name === 'SAVINGS'" class="icons">
        </co-icon-savings>
        <co-icon-insurance *ngIf="menuItem.name === 'INSURANCE'" class="icons">
        </co-icon-insurance>
        <co-icon-advantage *ngIf="menuItem.name === 'ADVANTAGE'" class="icons">
        </co-icon-advantage>
        <co-icon-info *ngIf="menuItem.name === 'ADVISORY_TOOLS'" class="icons">
        </co-icon-info>
        <co-icon-envelope *ngIf="menuItem.name === 'MESSAGES'" class="icons">
        </co-icon-envelope>
        <co-icon-profile *ngIf="menuItem.name === 'PROFILE'" class="icons">
        </co-icon-profile>
        <span [coContent]="menuItem.mobileContentId"></span>
        <co-notification
          class="notification"
          *ngIf="notifications && menuItem.name === 'MESSAGES'"
          [amount]="notifications"
        ></co-notification>
        <co-icon-arrow-down
          *ngIf="selectedMenuItem?.name !== menuItem.name"
          class="menu-item--arrow"
        ></co-icon-arrow-down>
        <co-icon-arrow-up
          *ngIf="selectedMenuItem?.name === menuItem.name"
          class="menu-item--arrow"
        ></co-icon-arrow-up>
      </div>
      <div
        *ngIf="menuItem.name === selectedMenuItem?.name"
        class="menu-item--container"
        [@openClose]
      >
        <div *ngFor="let categoryMenuitem of menuItem.categories">
          <div *ngIf="!categoryMenuitem.dontShow">
            <div
              class="header--text"
              [coContent]="categoryMenuitem.contentId"
            ></div>
            <div
              class="submenu"
              *ngFor="let subMenuItem of categoryMenuitem.items"
            >
              <co-notification
                class="notification"
                *ngIf="subMenuItem.notification"
                [amount]="subMenuItem.notification"
              ></co-notification>
              <a
                *ngIf="!subMenuItem.dontShow"
                class="display-inline-block"
                [ngClass]="{
                  selected: selectedSubMenuName === subMenuItem.name,
                  lastLogin: subMenuItem.name === 'SIDSTE_LOGIN',
                  logout: subMenuItem.name === 'LOGOUT'
                }"
                [routerLink]="subMenuItem.url"
                routerLinkActive="router-link-active"
                (click)="select(subMenuItem)"
              >
                <span [coContent]="subMenuItem.contentId"></span>
                <div *ngIf="subMenuItem.name === 'SIDSTE_LOGIN' && lastVisit">
                  {{ lastVisit | date: 'dd.MM.yyyy kl. HH:mm' }}
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom--container">
      <co-icon-locked class="lockout" (click)="logout()"></co-icon-locked>
      <div *ngIf="isDashboard()" style="display: flex; margin-left: auto">
        <div
          style="display: flex; cursor: pointer"
          *ngIf="isDanish"
          [coContent]="'DL.FO01.C400'"
          (click)="setLanguage('en')"
        ></div>
        <div
          style="display: flex; cursor: pointer"
          *ngIf="!isDanish"
          [coContent]="'DL.FO01.C401'"
          (click)="setLanguage('da')"
        ></div>
      </div>
    </div>
  </div>
</div>
