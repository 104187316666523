/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ServiceInfo } from './serviceInfo';
import { ServiceInfoSchema } from './serviceInfo';
import { Type } from './type';
import { TypeSchema } from './type';
import { AfkastDetaljer } from './afkastDetaljer';
import { AfkastDetaljerSchema } from './afkastDetaljer';
import { Tilstand } from './tilstand';
import { TilstandSchema } from './tilstand';
import { Police } from './police';
import { PoliceSchema } from './police';
import * as JoiObj from "joi";


    export const SavingsReturnPoliceSchema = JoiObj.object({
        serviceInfo:
            
        JoiObj.link('#ServiceInfoSchema')

.allow(null)



        ,
        detaljeMulig:
            
                    JoiObj.boolean()
                


.required()


        ,
        harProfilG:
            
                    JoiObj.boolean()
                


.required()


        ,
        id:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        livnetAftale:
            
                    JoiObj.boolean()
                


.required()


        ,
        opsparing:
            
                    JoiObj.number()
                

.allow(null)



        ,
        orlovSlutDato:
            
            JoiObj.date()

.allow(null)



        ,
        tilstand:
            
        JoiObj.link('#TilstandSchema')


.required()


        ,
        tooltipContentId:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        type:
            
        JoiObj.link('#TypeSchema')


.required()


        ,
        typeTekstId:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        aarSpaend:
                JoiObj.array().items(
        
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

        )
        

.required()


        ,
        afkast:
            
                    JoiObj.number()
                

.allow(null)



        ,
        afkastDetaljer:
            
        JoiObj.link('#AfkastDetaljerSchema')

.allow(null)



        ,
        afkastProcent:
            
                    JoiObj.number()
                

.allow(null)



        ,
        fejlet:
            
                    JoiObj.boolean()
                


.required()


        
    })
                .shared(ServiceInfoSchema)
                .shared(TilstandSchema)
                .shared(TypeSchema)
                .shared(AfkastDetaljerSchema)
.id('SavingsReturnPoliceSchema')

export interface SavingsReturnPolice extends Police { 
    aarSpaend: Array<string>;
    afkast?: number;
    afkastDetaljer?: AfkastDetaljer;
    afkastProcent?: number;
    fejlet: boolean;
}
export namespace SavingsReturnPolice {
}


