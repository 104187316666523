<co-loading [show]="!initialized"></co-loading>

<ng-container *ngIf="!isAdvisor && showPensionCheckCard && !sigmaFailed">
  <co-card
    class="spacing-unit--double-under position--relative card--small"
    [ngClass]="{ 'card-red': pensionCheckStatusHigh }"
    [cardRed]="pensionCheckStatusHigh"
    [cardGreen]="pensionCheckStatusAllOk"
    [@fadeInOut]=""
  >
    <a
      (click)="closePensionCheckCard()"
      class="close-button"
      *ngIf="pensionCheckInitialized"
    >
      <co-icon-times></co-icon-times>
    </a>

    <div
      class="content-wrapper"
      [ngClass]="{ loading: !pensionCheckInitialized }"
    >
      <co-loading [show]="!pensionCheckInitialized"></co-loading>
      <ng-container *ngIf="pensionCheckInitialized">
        <img
          *ngIf="pensionCheckStatusHigh"
          src="/assets/images/traffic_light_red.svg"
          class="traffic-light"
          alt="decorative image - traffic_light_red.svg"
          aria-hidden="true"
        />
        <img
          *ngIf="!pensionCheckStatusHigh && pensionCheckStatusMedium"
          src="/assets/images/traffic_light_yellow.svg"
          class="traffic-light"
          alt="decorative image - traffic_light_yellow.svg"
          aria-hidden="true"
        />
        <div class="content-wrapper__left">
          <ng-container *ngIf="pensionCheckStatusAllOk">
            <div class="header">
              <co-icon-circle-complete></co-icon-circle-complete>
              <h4 class="text--bold" coContent="DL.FS03.C62"></h4>
            </div>
            <div class="all-ok-text" coContent="DL.FS03.C63"></div>
          </ng-container>
          <div
            *ngIf="!pensionCheckStatusAllOk"
            class="all-not-ok-text text--manchet"
            [ngClass]="{ 'white-text': pensionCheckStatusHigh }"
            coContent="DL.FS03.C60"
          ></div>
        </div>
        <button
          *ngIf="!pensionCheckStatusAllOk"
          [ngClass]="{
            'button-traffic-light-stop--inverted': pensionCheckStatusHigh
          }"
          mat-raised-button
          (click)="goToPensionCheck()"
          coContent="DL.FS03.C61"
        ></button>
      </ng-container>
    </div>
  </co-card>
</ng-container>

<div class="header-row">
  <div class="header-block">
    <h1 [coContent]="'DL.FS03.C09'">Din opsparing</h1>
  </div>
  <div class="body-block" *ngIf="initialized">
    <div>
      <mitpfa-dashboard-savings-pension-in
        (contentLoaded)="pensionInLoaded()"
      ></mitpfa-dashboard-savings-pension-in>
    </div>
    <div>
      <mitpfa-dashboard-savings-payouts
        *ngIf="payoutsData && !locPayoutInProgress"
        [pensionCustomer]="pensionCustomer"
        (contentLoaded)="pensionInLoaded()"
      ></mitpfa-dashboard-savings-payouts>
      <mitpfa-dashboard-savings-return
        *ngIf="!payoutsData"
        (contentLoaded)="returnLoaded()"
      ></mitpfa-dashboard-savings-return>
    </div>
  </div>
</div>

<ng-container *ngIf="initialized">
  <div class="row spacing-section--over spacing-unit--double-under no-carousel">
    <div
      class="col-sm-3"
      *ngIf="(!payoutsData && !locPayoutInProgress) || payoutsData"
      [ngClass]="{
        'order-1': payoutsData,
        'order-3': !payoutsData && !payoutsStarted,
        'order-2': !payoutsData && payoutsStarted
      }"
    >
      <mitpfa-dashboard-savings-return
        *ngIf="payoutsData"
        (contentLoaded)="returnLoaded()"
      ></mitpfa-dashboard-savings-return>
      <mitpfa-dashboard-savings-payouts
        *ngIf="!payoutsData && !locPayoutInProgress"
        [pensionCustomer]="pensionCustomer"
        [payoutsStarted]="payoutsStarted"
        (contentLoaded)="payoutsLoaded()"
      ></mitpfa-dashboard-savings-payouts>
    </div>
    <div
      class="col-sm-3"
      [ngClass]="{ 'order-2': payoutsData, 'order-1': !payoutsData }"
    >
      <mitpfa-dashboard-savings-payments
        [payoutsStarted]="payoutsStarted"
        [coverages]="coverages"
        (contentLoaded)="paymentsLoaded()"
      ></mitpfa-dashboard-savings-payments>
    </div>
    <div
      class="col-sm-3"
      [ngClass]="{
        'order-3': payoutsData || (!payoutsData && payoutsStarted),
        'order-2': !payoutsData && !payoutsStarted
      }"
    >
      <mitpfa-dashboard-savings-investment
        (contentLoaded)="investmentLoaded()"
      ></mitpfa-dashboard-savings-investment>
    </div>
    <div
      class="col-sm-3"
      [ngClass]="{
        'order-3': locPayoutInProgress,
        'order-4': !locPayoutInProgress
      }"
    >
      <mitpfa-dashboard-savings-costs></mitpfa-dashboard-savings-costs>
    </div>
  </div>

  <div class="spacing-section--over spacing-unit--double-under carousel">
    <co-carousel [slideScale]="true" [slidePercentageWidth]="80">
      <ng-container *pfaCarouselItem>
        <mitpfa-dashboard-savings-return
          *ngIf="payoutsData"
          (contentLoaded)="returnLoaded()"
        ></mitpfa-dashboard-savings-return>
        <mitpfa-dashboard-savings-payouts
          *ngIf="!payoutsData && !locPayoutInProgress"
          [pensionCustomer]="pensionCustomer"
          [payoutsStarted]="payoutsStarted"
          (contentLoaded)="payoutsLoaded()"
        ></mitpfa-dashboard-savings-payouts>
      </ng-container>
      <ng-container *pfaCarouselItem>
        <mitpfa-dashboard-savings-payments
          [payoutsStarted]="payoutsStarted"
          [coverages]="coverages"
          (contentLoaded)="paymentsLoaded()"
        ></mitpfa-dashboard-savings-payments>
      </ng-container>
      <ng-container *pfaCarouselItem>
        <mitpfa-dashboard-savings-investment
          (contentLoaded)="investmentLoaded()"
        ></mitpfa-dashboard-savings-investment>
      </ng-container>
      <ng-container *pfaCarouselItem>
        <mitpfa-dashboard-savings-costs></mitpfa-dashboard-savings-costs>
      </ng-container>
    </co-carousel>
  </div>
</ng-container>
