import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy
} from '@angular/core';
import { OekonomiDetaljer } from '@pfa/gen';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators
} from '@angular/forms';
import { pairwise } from 'rxjs/operators';
import { Options } from 'ngx-slider-v2';
import { generateSliderStepsArray } from '@pfaform';

export enum ShareOfWealthType {
  OneHoundred,
  Zero,
  Custom
}

@Component({
  selector: 'mitpfa-financial-situation-savings',
  templateUrl: './financial-situation-savings.component.html',
  styleUrls: ['./financial-situation-savings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FinancialSituationSavingsComponent implements OnInit {
  @Input() financialSituation: OekonomiDetaljer;
  @Input() annualSavings = true;
  @Input() shareOfWealth = true;
  @Input() form: UntypedFormGroup;
  @Input() isInInsuranceGuide = false;

  public savingsForm: UntypedFormGroup;
  public shareOfWealthInputVisible: boolean;
  public totalValueSliderOptions: Options = {
    stepsArray: [
      ...generateSliderStepsArray(0, 200000, 10000),
      ...generateSliderStepsArray(250000, 1000000, 50000)
    ],
    ceil: 1000000
  };
  public annualSavingsSliderOptions: Options = {
    stepsArray: [...generateSliderStepsArray(0, 200000, 1000)],
    ceil: 200000
  };

  public shareOfWealthOptionValue: ShareOfWealthType;

  ngOnInit(): void {
    this.initializeForm();
  }

  showShareWealthInput(): void {
    this.shareOfWealthInputVisible = true;
    this.savingsForm.controls.shareOfWealth.setValue(0);
  }

  hideShareWealthInput(choosen: ShareOfWealthType): void {
    this.shareOfWealthInputVisible = false;
    switch (choosen) {
      case ShareOfWealthType.OneHoundred:
        this.savingsForm.controls.shareOfWealth.setValue(100);
        break;
      case ShareOfWealthType.Zero:
        this.savingsForm.controls.shareOfWealth.setValue(0);
        break;
    }
  }

  private initializeForm(): void {
    const percentageValidators = [
      Validators.required,
      Validators.min(0),
      Validators.max(100),
      Validators.pattern('^[0-9]*$')
    ];

    this.savingsForm = new UntypedFormGroup({
      bankSavings: new UntypedFormControl(
        this.financialSituation.frieMidler.opsparingIBank,
        Validators.required
      ),
      regularAnnualSavings: new UntypedFormControl(
        this.financialSituation.frieMidler.aktierLoebendeOpsparing > 0 ||
          this.financialSituation.frieMidler.obligationerLoebendeOpsparing >
            0 ||
          this.financialSituation.frieMidler.kontanterLoebendeOpsparing > 0
      ),
      shareOfWealth: new UntypedFormControl(
        this.financialSituation.frieMidler.andelFormue || 0,
        percentageValidators
      ),
      shareOfWealthOption: new UntypedFormControl(
        this.shareOfWealthOptionValue || ShareOfWealthType.OneHoundred
      )
    });

    this.savingsForm.controls.shareOfWealthOption.valueChanges
      .pipe(pairwise())
      .subscribe(([, next]: [number, number]) => {
        if (next === 0 || next === 1) {
          this.hideShareWealthInput(next);
        } else if (next === 2) {
          this.showShareWealthInput();
        }
      });

    if (this.financialSituation.frieMidler.andelFormue === 100) {
      this.shareOfWealthOptionValue = ShareOfWealthType.OneHoundred;
      this.savingsForm.controls.shareOfWealthOption.setValue(
        this.shareOfWealthOptionValue
      );
      this.shareOfWealthInputVisible = false;
    } else if (this.financialSituation.frieMidler.andelFormue === 0) {
      this.shareOfWealthOptionValue = ShareOfWealthType.Zero;
      this.savingsForm.controls.shareOfWealthOption.setValue(
        this.shareOfWealthOptionValue
      );
      this.shareOfWealthInputVisible = false;
    } else {
      this.shareOfWealthOptionValue = ShareOfWealthType.Custom;
      this.savingsForm.controls.shareOfWealthOption.setValue(
        this.shareOfWealthOptionValue
      );
      this.shareOfWealthInputVisible = true;
    }

    if (this.form) {
      this.form.addControl('savings', this.savingsForm);
    }
  }
}
