/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ServiceInfo } from './serviceInfo';
import { ServiceInfoSchema } from './serviceInfo';
import { ResourceModel } from './resourceModel';
import { ResourceModelSchema } from './resourceModel';
import * as JoiObj from "joi";


    export const PayoutTableRowTextSchema = JoiObj.object({
        serviceInfo:
            
        JoiObj.link('#ServiceInfoSchema')

.allow(null)



        ,
        entertedText:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        gipp:
            
                    JoiObj.boolean()
                


.required()


        ,
        hasCustomerCapital:
            
                    JoiObj.boolean()
                


.required()


        ,
        installmentPeriodChanged:
            
                    JoiObj.boolean()
                


.required()


        ,
        longText:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        onlyFirstYearForecastCalculated:
            
                    JoiObj.boolean()
                


.required()


        ,
        pensionsAgeMovedLater:
            
                    JoiObj.boolean()
                


.required()


        ,
        shortText:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        shortTextVariables:
                JoiObj.array().items(
        
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

        )
        

.required()


        ,
        tariffText:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        taxfreeText:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        textVariables:
                JoiObj.array().items(
        
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

        )
        

.required()


        ,
        underPayoutText:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        unmarried:
            
                    JoiObj.boolean()
                


.required()


        
    })
                .shared(ServiceInfoSchema)
.id('PayoutTableRowTextSchema')

export interface PayoutTableRowText extends ResourceModel { 
    entertedText: string;
    gipp: boolean;
    hasCustomerCapital: boolean;
    installmentPeriodChanged: boolean;
    longText: string;
    onlyFirstYearForecastCalculated: boolean;
    pensionsAgeMovedLater: boolean;
    shortText: string;
    shortTextVariables: Array<string>;
    tariffText: string;
    taxfreeText: string;
    textVariables: Array<string>;
    underPayoutText: string;
    unmarried: boolean;
}

