import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {
  ForsikringDetaljer,
  Forsikringsoversigt,
  MicroappconfigurationStore,
  OekonomiDetaljer
} from '@pfa/gen';
import * as moment from 'moment';
import { InsuranceGuideRecommendationCardComponent } from '../card/insurance-guide-recommendation-card.component';
import {
  RECOMMENDATION_DIRECTION,
  RECOMMENDATION_TYPE,
  RecommendationDescription
} from '../insurance-guide.recommendation.model';
import { InsuranceUtilService } from '@pfaform';
import { InsuranceGuideConfigurationService } from '../../insurance-guide-configuration.service';
import { InsuranceGuideService } from '../../insurance-guide.service';
import { ConfirmChoice } from '@mitpfa/shared';

@Component({
  selector: 'mitpfa-insurance-guide-recommendation-critical-illness',
  templateUrl:
    './insurance-guide-recommendation-critical-illness.component.html',
  styleUrls: [
    './insurance-guide-recommendation-critical-illness.component.scss'
  ]
})
export class InsuranceGuideRecommendationCriticalIllnessComponent
  implements OnInit
{
  @Input() insuranceDetails: ForsikringDetaljer;
  @Input() insuranceOverview: Forsikringsoversigt;
  @Input() financialSituation: OekonomiDetaljer;
  @Input() salary: number;
  @Input() showFollowOurRecommendationButton: boolean;
  @Input() confirmChoice: ConfirmChoice;
  @Input() hideActions: boolean;

  @Output() confirmChoiceEmitter: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild(InsuranceGuideRecommendationCardComponent)
  recommendationCard: InsuranceGuideRecommendationCardComponent;

  public belowTopTaxLimit: boolean;
  public recommendation: RECOMMENDATION_DIRECTION;
  public recommendationDescription: RecommendationDescription | undefined;
  isDisabled: boolean;
  totalCiAmount: number;
  overrideRecommendationKeepCurrent: boolean;

  constructor(
    private readonly insuranceGuideConfigurationService: InsuranceGuideConfigurationService,
    private readonly insuranceUtilService: InsuranceUtilService,
    private readonly insuranceGuideService: InsuranceGuideService,
    private readonly microappconfigurationStore: MicroappconfigurationStore
  ) {}

  ngOnInit(): void {
    this.setShouldKeepRecommendation();
    this.microappconfigurationStore
      .microappconfigurationGet('skat', 'statsatser', moment().year())
      .subscribe(taxData => {
        this.belowTopTaxLimit = this.salary < taxData.topskatGraense;

        this.recommendation = this.insuranceGuideService.getRecommendationCi(
          this.insuranceDetails
        );
        this.recommendationDescription =
          InsuranceGuideService.getRecommendationDescription(
            this.recommendation,
            RECOMMENDATION_TYPE.CRITICAL_ILLNESS,
            undefined
          );
        this.isDisabled =
          this.insuranceGuideConfigurationService.getInsuranceGuideDisabled(
            this.insuranceDetails
          ).ci;
        this.totalCiAmount =
          this.insuranceUtilService.criticalIllnessTotalAmount(
            this.insuranceOverview
          ) ?? 0;
        this.setShouldKeepRecommendation();

        this.insuranceGuideService.trackRecommendation(
          [
            {
              name: this.insuranceGuideService.getRecommendationTrackingTextCi(
                this.insuranceDetails
              ),
              index: 3
            }
          ],
          true
        );
      });
  }

  private setShouldKeepRecommendation(): void {
    this.overrideRecommendationKeepCurrent =
      InsuranceGuideService.calculateShouldKeepRecommendation(
        this.totalCiAmount,
        this.insuranceDetails.pfaAnbefalerKS.value,
        InsuranceUtilService.CI_VARIATION
      );
  }

  onConfirmChoice() {
    this.confirmChoiceEmitter.emit();
  }
}
