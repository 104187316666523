/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { QuestionSummary } from './questionSummary';
import { QuestionSummarySchema } from './questionSummary';
import * as JoiObj from "joi";


    export const AnswerSummarySchema = JoiObj.object({
        answer:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        questionSummary:
                JoiObj.array().items(
        
        JoiObj.link('#QuestionSummarySchema')

        )
        

.required()


        
    })
.id('AnswerSummarySchema')

export interface AnswerSummary { 
    answer: string;
    questionSummary: Array<QuestionSummary>;
}

