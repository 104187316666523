/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const SpoergeskemaStatusSchema = JoiObj.string().valid(
        'CLOSED'
        ,
        'DRAFT'
        ,
        'OPEN'
        ,
        'SOFT_CLOSED'
        
    )
.id('SpoergeskemaStatusSchema')

export type SpoergeskemaStatus = 'CLOSED' | 'DRAFT' | 'OPEN' | 'SOFT_CLOSED';

export const SpoergeskemaStatus = {
    Closed: 'CLOSED' as SpoergeskemaStatus,
    Draft: 'DRAFT' as SpoergeskemaStatus,
    Open: 'OPEN' as SpoergeskemaStatus,
    SoftClosed: 'SOFT_CLOSED' as SpoergeskemaStatus
};

