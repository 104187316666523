<div class="wrapper">
  <div class="top--container">
    <co-icon-logo class="header--logo"></co-icon-logo>
    <co-icon-times class="close" (click)="close()"> </co-icon-times>
  </div>

  <co-loading [show]="!dataLoaded"></co-loading>

  <div class="container" *ngIf="dataLoaded">
    <h1 class="spacing-unit--one--half-under" coContent="DL.MD01.C460"></h1>

    <co-bodytext alignCenter="true">
      <p
        class="body--text text--manchet spacing-unit--one--half-under"
        coContent="DL.MD01.C461"
      ></p>
    </co-bodytext>

    <ng-container *ngIf="showError">
      <co-alert class="spacing-unit--over"
        ><span coContent="DL.MD01.C463"></span
      ></co-alert>

      <ng-container *ngTemplateOutlet="closeButton"></ng-container>
    </ng-container>

    <ng-container *ngIf="!showError">
      <co-customer-contact-details
        #contactDetailsComponent
        [light]="true"
        [phoneRequired]="true"
        [emailRequired]="true"
        [pensionCustomer]="pensionCustomer"
        (form)="checkFormChange($event)"
        (contactDetailsUpdate)="setSkedifyLink()"
        class="spacing-unit--over align--center show-form-only"
      ></co-customer-contact-details>

      <div class="spacing-unit--over">
        <button
          mat-raised-button
          class="book-button button-secondary"
          (click)="contactDetailsComponent.saveContactDetails()"
          [disabled]="contactDetailsComponent.contactDetailsForm?.invalid"
        >
          <span coContent="DL.MD01.C166"></span>
        </button>

        <button
          mat-raised-button
          class="book-button"
          (click)="redirectToSkedify()"
          [disabled]="disableSkedifyRedirect"
        >
          <span coContent="DL.MD01.C462"></span>
          <co-icon-arrow-right
            class="icon icon--arrow-right"
          ></co-icon-arrow-right>
        </button>
      </div>

      <div
        *ngIf="showPopupBlockNote"
        class="spacing-unit--one--half-over align--center"
      >
        <co-alert class="alert-info">
          <span coContent="DL.MD01.C465"></span>
        </co-alert>

        <ng-container *ngTemplateOutlet="closeButton"></ng-container>
      </div>
    </ng-container>
  </div>

  <div class="language">
    <div
      [coContent]="'DL.FO01.C401'"
      class="lang-select text--bold"
      [ngClass]="{ selected: !slideToggleLanguageChecked }"
    ></div>
    <mat-slide-toggle
      [color]="'accent'"
      [checked]="slideToggleLanguageChecked"
      (change)="changeLanguage($event)"
    >
    </mat-slide-toggle>
    <div
      [coContent]="'DL.FO01.C400'"
      class="lang-select text--bold"
      [ngClass]="{ selected: slideToggleLanguageChecked }"
    ></div>
  </div>
</div>

<ng-template #closeButton>
  <button
    mat-raised-button
    class="close-button button-primary--level3 spacing-unit--half-over"
    (click)="close()"
    coContent="DL.MD01.C466"
  ></button>
</ng-template>
