/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AarligAfkast } from './aarligAfkast';
import { AarligAfkastSchema } from './aarligAfkast';
import * as JoiObj from "joi";


    export const AarligAfkasterSchema = JoiObj.object({
        afkast:
                JoiObj.array().items(
        
        JoiObj.link('#AarligAfkastSchema')

        )
        

.required()


        
    })
                .shared(AarligAfkastSchema)
.id('AarligAfkasterSchema')

export interface AarligAfkaster { 
    afkast: Array<AarligAfkast>;
}

