/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const BeneficiaryAdvisoryRecommendedSchema = JoiObj.string().valid(
        'KEEP'
        ,
        'DECIDE'
        
    )
.id('BeneficiaryAdvisoryRecommendedSchema')

export type BeneficiaryAdvisoryRecommended = 'KEEP' | 'DECIDE';

export const BeneficiaryAdvisoryRecommended = {
    Keep: 'KEEP' as BeneficiaryAdvisoryRecommended,
    Decide: 'DECIDE' as BeneficiaryAdvisoryRecommended
};

