/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const HierarchicalDiagnosisModelSchema = JoiObj.object({
        id:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        kropsdele:
                JoiObj.array().items(
        
        JoiObj.link('#HierarchicalDiagnosisModelSchema')

        )
        
.allow(null)



        ,
        diagnoser:
                JoiObj.array().items(
        
        JoiObj.link('#HierarchicalDiagnosisModelSchema')

        )
        
.allow(null)



        ,
        diagnosisElaboration:
                JoiObj.array().items(
        
        JoiObj.link('#HierarchicalDiagnosisModelSchema')

        )
        
.allow(null)



        ,
        level:
            
                    JoiObj.number()
                

.allow(null)



        ,
        modelList:
                JoiObj.array().items(
        
        JoiObj.link('#HierarchicalDiagnosisModelSchema')

        )
        
.allow(null)



        ,
        name:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        parentId:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        
    })
.id('HierarchicalDiagnosisModelSchema')

export interface HierarchicalDiagnosisModel { 
    id: string;
    kropsdele?: Array<HierarchicalDiagnosisModel>;
    diagnoser?: Array<HierarchicalDiagnosisModel>;
    diagnosisElaboration?: Array<HierarchicalDiagnosisModel>;
    level?: number;
    modelList?: Array<HierarchicalDiagnosisModel>;
    name: string;
    parentId?: string;
}

