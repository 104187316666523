import { Component, Inject } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { openClose } from '@pfa/animations';

@Component({
  selector: 'mitpfa-investment-climate-modal',
  templateUrl: './climate-modal.component.html',
  styleUrls: ['./climate-modal.component.scss'],
  animations: [openClose]
})
export class InvestmentClimateModalComponent {
  public form: UntypedFormGroup;
  public thumb: number;
  public recommendationPercent: string;

  constructor(@Inject(MAT_DIALOG_DATA) data) {
    this.thumb = data.current.climatePercent;
    this.recommendationPercent = data.recommendation.climatePercent.toString();

    this.form = new UntypedFormGroup({
      percent: new UntypedFormControl(data.current.climatePercent, [
        Validators.min(0),
        Validators.max(100)
      ])
    });
  }

  public setThumb(): void {
    this.thumb = this.form.controls.percent.value;

    if (
      this.form.controls.percent.value === '' ||
      this.form.controls.percent.value < 0 ||
      this.form.controls.percent.value > 100 ||
      this.form.controls.percent.errors
    ) {
      this.thumb = 0;
    }
  }

  public setValue(): void {
    if (this.form.controls.percent.value === '') {
      this.form.controls.percent.setValue(0);
    }
  }
}
