<svg
  width="136"
  height="55"
  viewBox="0 0 136 55"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M27.4529 29.3438C27.4529 29.3438 24.8656 29.3438 24.7934 29.3438V20.339C24.8946 20.339 27.4674 20.339 27.4674 20.339C30.7484 20.339 34.2608 21.2785 34.2608 24.9353C34.2608 28.5922 30.7629 29.3438 27.4529 29.3438ZM28.8694 17.1157H20.8474C14.69 17.1157 11.9004 19.1971 11.9004 24.574V37.1201C11.9004 37.4814 12.2184 37.785 12.6231 37.785H24.0851C24.4898 37.785 24.7934 37.4959 24.7934 37.1201V32.5671H28.8549C40.8229 32.5671 47.7608 31.006 47.7608 24.8631C47.7608 18.7201 40.8229 17.1157 28.8694 17.1157Z"
    fill="#990000"
  />
  <path
    d="M62.2144 17.1157C56.1726 17.1157 53.3252 19.2405 53.3252 24.5306V37.1056C53.3252 37.4814 53.6432 37.7705 54.0334 37.7705H65.7123C66.1315 37.7705 66.4205 37.4814 66.4205 37.1056V30.8326H80.1952C80.5999 30.8326 80.889 30.5435 80.889 30.1677V27.9273C80.889 27.5804 80.571 27.2624 80.1952 27.2624H66.4205V20.8738H82.4934C82.8981 20.8738 83.2016 20.5702 83.2016 20.2089V17.7806C83.2016 17.3903 82.9126 17.1157 82.4934 17.1157H62.2144Z"
    fill="#990000"
  />
  <path
    d="M94.2448 30.0664L99.7084 21.2061L105.172 30.0664H94.2448ZM124.945 36.9321L114.365 21.1772C112.095 17.8528 111.069 17 105.476 17H103.221C97.468 17 96.2828 19.0669 95.112 20.7725L84.286 36.961C84.0547 37.3079 84.1414 37.756 84.6907 37.756H88.8534C89.4316 37.756 89.5761 37.597 89.8652 37.1344L92.3079 33.174H107.08L109.436 36.9899C109.812 37.597 110.101 37.756 110.983 37.756H124.526C125.032 37.756 125.176 37.2645 124.945 36.9321Z"
    fill="#990000"
  />
</svg>
