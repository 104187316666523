import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ContentService } from '@pfa/core';

@Component({
  selector: 'co-modal-save-language',
  templateUrl: './save-language-modal.component.html',
  styleUrls: ['save-language-modal.component.scss']
})
export class SaveLanguageModalComponent {
  public errorSaving = false;

  constructor(
    private readonly dialogRef: MatDialogRef<SaveLanguageModalComponent>,
    private readonly contentService: ContentService
  ) {}

  public save() {
    this.contentService.saveLanguage().subscribe({
      next: () => {
        this.errorSaving = false;
        this.dialogRef.close();
      },
      error: () => {
        this.errorSaving = true;
      }
    });
  }

  public close() {
    this.dialogRef.close();
  }
}
