import { Injectable } from '@angular/core';
import { PensionsKundeGenerelleData } from '@pfa/gen';

@Injectable({
  providedIn: 'root'
})
export class PensionInfoRetrieveInfoService {
  checkPIText = (user: PensionsKundeGenerelleData) => {
    if (!user.harPensionsinfo) {
      return 'DL.PI01.C21';
    }

    if (user.pensionInfoDataInvalid && user.invalidPensionReport) {
      return user.invalidPensionReportVersion
        ? // The customer has an expired old report
          'DL.PI01.C157'
        : // The customer has an expired new report
          'DL.PI01.C137';
    }

    if (user.pensionInfoDataInvalid && !user.invalidPensionReport) {
      return user.invalidPensionReportVersion
        ? // The customer has an valid old report
          'DL.PI01.C157'
        : // The customer has a valid new report
          'DL.PI01.C23';
    }

    if (
      user.pensionInfoExcludedPolicies &&
      !user.pensionInfoCustomerAnswerMissing
    ) {
      //PI is retrieved within three months and has excluded policies
      return 'DL.PI01.C138';
    }

    return user.invalidPensionReportVersion
      ? // The customer has an valid old report
        'DL.PI01.C157'
      : // The customer has a valid new report
        'DL.PI01.C23';
  };
}
