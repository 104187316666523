import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { SigningIframeResponse, SigningPopupResponse } from '@pfaform';
import {
  NemLogin3Response,
  NemLogin3ResponseBody,
  NemLogin3ResponseBodyType
} from './signing-mitid.model';

@Component({
  selector: 'co-signing-mitid',
  templateUrl: './signing-mitid.component.html',
  styleUrls: ['./signing-mitid.component.scss']
})
export class SigningMitIDComponent implements OnInit, OnDestroy {
  @Input() mitidSignObject: SigningIframeResponse;
  @Output() signingCompleted = new EventEmitter<SigningPopupResponse>();

  mitidIframeUrlTrusted: SafeResourceUrl;
  signereFejlTekst: string;

  @ViewChild('signingIframe') iframe: ElementRef<HTMLIFrameElement>;

  private onMessage?: (this: Window, ev: MessageEvent) => any;

  constructor(private readonly sanitizer: DomSanitizer) {}

  ngOnInit() {
    this.mitidIframeUrlTrusted = this.sanitizer.bypassSecurityTrustResourceUrl(
      this.mitidSignObject.iframesrc
    );

    if (this.mitidIframeUrlTrusted && this.mitidSignObject.script) {
      const pureJS = this.mitidSignObject.script.match(/>([\s\S]*)</m)[1];
      this.onMessage = new Function(`${pureJS} return onMessage;`)();
    }
    this.attachCallback();
  }

  ngOnDestroy() {
    this.cleanupSigningListener();
  }

  attachCallback() {
    if (!this.mitidIframeUrlTrusted) {
      return;
    }
    (window as any).mitIdSigningCallback = m => {
      this.cleanupSigningListener();

      const deserializeBody = (
        bodyTypeName: NemLogin3ResponseBodyType,
        body: string
      ) => {
        if (bodyTypeName === 'Frame.Nl3.Nl3SignFlowResult') {
          return null;
        } else {
          return JSON.parse(body);
        }
      };

      const parsedResponse: NemLogin3Response = JSON.parse(atob(m));
      const body: NemLogin3ResponseBody | null = parsedResponse.BodySerialized
        ? deserializeBody(
            parsedResponse.BodyTypeName,
            parsedResponse.BodySerialized
          )
        : null;
      const result: SigningPopupResponse = {
        content: m,
        revisionKey: this.mitidSignObject.revisionKey,
        success: !!parsedResponse.Signature
      };

      if (!result.success) {
        result.error = body?.Message;
      }

      this.signingCompleted.emit(result);
    };
  }

  cleanupSigningListener() {
    window.removeEventListener('message', this.onMessage);
  }
}
