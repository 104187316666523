import { inject, Injectable } from '@angular/core';
import { catchError, filter, switchMap, take, tap } from 'rxjs/operators';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ContentService, ExtendedWindow } from '@pfa/core';
import { EMPTY } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { PopupStyle } from '@pfa/models';
import { LoginService } from '../login.service';
import {
  BroadcastMessageType,
  BroadcastService,
  SaveLanguageModalComponent
} from '@pfaform';
@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  private router: Router = inject(Router);
  private activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  private contentService: ContentService = inject(ContentService);
  private broadcastService: BroadcastService = inject(BroadcastService);
  private dialog: MatDialog = inject(MatDialog);
  private loginService: LoginService = inject(LoginService);

  constructor() {
    this.handleLanguageParam();
  }

  setLanguage(lang: 'da' | 'en') {
    this.contentService.setLanguage(lang);
    this.contentService
      .loadLanguage()
      .pipe(catchError(() => EMPTY))
      .subscribe(res => {
        if (res !== lang.toString().toUpperCase()) {
          this.dialog
            .open(SaveLanguageModalComponent, {
              disableClose: true,
              panelClass: PopupStyle.PopupSmall
            })
            .afterClosed()
            .subscribe(() => {
              if (
                window.location.pathname ===
                  '/mineforsikringer/pfahelbredssikring' ||
                window.location.pathname === '/mineforsikringer/kritisksygdom'
              ) {
                window.location.reload();
              }
            });
        }
      });
    const broadcastMessage =
      lang === 'da'
        ? BroadcastMessageType.LANGUAGE_DA
        : BroadcastMessageType.LANGUAGE_EN;
    this.broadcastService.broadcastMessage(broadcastMessage);
    this.changeCookieBotLanguage(lang);
  }

  changeCookieBotLanguage(lang: 'da' | 'en'): void {
    const LANGUAGE_ATTRIBUTE = 'data-culture';
    const cookieBotScript = document.querySelector('#Cookiebot');
    if (cookieBotScript?.attributes[LANGUAGE_ATTRIBUTE]) {
      cookieBotScript.attributes[LANGUAGE_ATTRIBUTE].value = lang;
    } else {
      cookieBotScript?.setAttribute(LANGUAGE_ATTRIBUTE, lang);
    }
    const cookieBot = (window as unknown as ExtendedWindow).Cookiebot;
    if (cookieBot && !cookieBot.hasResponse && cookieBot.renew) {
      cookieBot.renew();
    }
  }

  private handleLanguageParam() {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        take(1),
        switchMap(() => {
          const lang = this.activatedRoute.snapshot.queryParams.lang;
          if (lang === 'da' || lang === 'en') {
            this.setLanguage(lang as 'da' | 'en');
            return EMPTY;
          } else {
            return this.loginService.loggedIn$.pipe(
              filter(loggedIn => loggedIn),
              take(1),
              switchMap(() => this.contentService.loadLanguage()),
              tap(res => {
                let language: 'da' | 'en' = 'da';
                if (res === 'EN') {
                  language = 'en';
                }
                this.changeCookieBotLanguage(language);
                this.contentService.setLanguage(language);
              })
            );
          }
        })
      )
      .subscribe();
  }
}
