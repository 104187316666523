/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ServiceInfo } from './serviceInfo';
import { ServiceInfoSchema } from './serviceInfo';
import { PensionsplanPolice } from './pensionsplanPolice';
import { PensionsplanPoliceSchema } from './pensionsplanPolice';
import { ResourceModel } from './resourceModel';
import { ResourceModelSchema } from './resourceModel';
import * as JoiObj from "joi";


    export const WealthSummarySchema = JoiObj.object({
        serviceInfo:
            
        JoiObj.link('#ServiceInfoSchema')

.allow(null)



        ,
        policies:
                JoiObj.array().items(
        
        JoiObj.link('#PensionsplanPoliceSchema')

        )
        

.required()


        ,
        savings:
            
                    JoiObj.number()
                


.required()


        ,
        savingsNetAmount:
            
                    JoiObj.number()
                


.required()


        ,
        taxAmount:
            
                    JoiObj.number()
                


.required()


        ,
        taxableSavings:
            
                    JoiObj.number()
                


.required()


        
    })
                .shared(ServiceInfoSchema)
                .shared(PensionsplanPoliceSchema)
.id('WealthSummarySchema')

export interface WealthSummary extends ResourceModel { 
    policies: Array<PensionsplanPolice>;
    savings: number;
    savingsNetAmount: number;
    taxAmount: number;
    taxableSavings: number;
}

