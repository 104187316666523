import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { RecommendationDetail } from '../investment-recommendation.model';
import {
  GlobalWorkingService,
  NotificationService,
  NotificationType
} from '@pfaform';
import { InForceSelection, PensionskundeStore } from '@pfa/gen';
import { InvestmentService } from '../../investment.service';
import { MatDialog } from '@angular/material/dialog';
import { InvestmentClimateModalComponent } from '../../climate-modal/climate-modal.component';
import { SideBarStyle } from '@pfa/models';
import {
  ConfirmChoiceType,
  GuideTrackingService,
  PopupTrackingService,
  TrackingName
} from '@pfa/core';

@Component({
  selector: 'mitpfa-investment-recommendation-climate',
  templateUrl: './recommendation-climate.component.html',
  styleUrls: [
    './recommendation-climate.component.scss',
    '../investment-recommendation.component.scss'
  ]
})
export class InvestmentRecommendationClimateComponent implements OnInit {
  private readonly pensionskundeStore: PensionskundeStore =
    inject(PensionskundeStore);
  private readonly investmentService: InvestmentService =
    inject(InvestmentService);
  private readonly notificationService: NotificationService =
    inject(NotificationService);
  private readonly globalWorkingService: GlobalWorkingService =
    inject(GlobalWorkingService);
  private readonly dialog: MatDialog = inject(MatDialog);
  private readonly guideTrackingService: GuideTrackingService =
    inject(GuideTrackingService);
  private readonly popupTrackingService: PopupTrackingService =
    inject(PopupTrackingService);

  @Output() emitNextCard = new EventEmitter<boolean>();
  @Input() detail: RecommendationDetail;
  @Input() inForceSelections: InForceSelection[];
  @Input() isOnboardingFlow: boolean;
  public confirmedDate: Date;

  public ngOnInit(): void {
    this.confirmedDate = this.inForceSelections.find(
      selection =>
        selection.inForceSelectionType === 'Climate' &&
        selection.policyId === this.detail.policyId
    )?.timestamp;
  }

  public keepClimate(): void {
    const profileChanged = false;
    this.detail.manualClimatePercent = null;
    this.confirmChoice('DL.INV.C692', profileChanged);
  }

  public followRecommendation(): void {
    this.guideTrackingService.trackGuideRecommendationCallToAction(
      TrackingName.INVESTMENT_GUIDE,
      `follow recommendation: pfainvest climate ${this.detail.recommendation.climatePercent}`
    );
    const profileChanged = true;
    this.detail.manualClimatePercent = null;
    this.complete('DL.INV.C693', profileChanged);
  }

  public changeClimate(): void {
    const dialogMatRef = this.dialog.open(InvestmentClimateModalComponent, {
      ...SideBarStyle,
      data: this.detail
    });

    dialogMatRef.afterOpened().subscribe(() => {
      this.popupTrackingService.trackSidebarOpen('pfaclimateplus');
    });

    dialogMatRef.afterClosed().subscribe(value => {
      if (value !== null) {
        if (value === this.detail.current.climatePercent) {
          this.keepClimate();
        } else {
          const profileChanged = true;
          this.detail.manualClimatePercent = value;
          this.popupTrackingService.trackSidebarCTA(
            'pfaclimateplus',
            `climate ${value}`
          );
          this.complete('DL.INV.C693', profileChanged);
        }
      }
    });
  }

  private confirmChoice(completeText: string, profileChanged: boolean): void {
    this.globalWorkingService.show();

    const observable$ = this.investmentService.postClimateConfirmChoice(
      this.detail.policyId,
      this.detail.current.climatePercent ?? 0,
      this.detail.recommendation.climatePercent ?? 0,
      this.confirmedDate
        ? ConfirmChoiceType.RECONFIRM
        : ConfirmChoiceType.FIRST,
      this.isOnboardingFlow
    );

    observable$.subscribe({
      next: () => {
        this.globalWorkingService.hide();
        this.complete(completeText, profileChanged);
        this.pensionskundeStore.invalidatepensionskundeIndex();
      },
      error: () => {
        this.globalWorkingService.hide();
        this.notificationService.showNotification({
          message: 'Global.error.confirmchoice',
          type: NotificationType.ERROR
        });
      }
    });
  }

  private complete(text: string, profileChanged: boolean): void {
    this.notificationService.showNotification({
      message: text,
      type: NotificationType.SUCCESS
    });

    this.emitNextCard.emit(profileChanged);
  }
}
