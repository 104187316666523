import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OnboardingComponent } from './onboarding.component';
import { IconModule } from '@pfa/icons';
import { WelcomeComponent } from './welcome/welcome.component';
import { MatButtonModule } from '@angular/material/button';
import { CoreModule } from '@pfa/core';
import { ContactComponent } from './contact/contact.component';
import { ConsentComponent } from './consent/consent.component';
import { PensionsinfoComponent } from './pensionsinfo/pensionsinfo.component';
import { DoneComponent } from './done/done.component';
import { ExternalTransferComponent } from './external-transfer/external-transfer.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { PensionInfoModule } from '@mitpfa/shared/pension-info/pension-info.module';
import {
  MatSlideToggle,
  MatSlideToggleModule
} from '@angular/material/slide-toggle';
import { RouterModule } from '@angular/router';
import { MatDialogModule } from '@angular/material/dialog';
import { PfaFormModule } from '@pfaform';
import { WarningComponent } from './warning/warning.component';
import { InvestmentGuideService, SharedModule } from '@mitpfa/shared';
import { PensionCheckModule } from '../pension-check/pension-check.module';
import { NbaLoadingAnimationComponent } from './nba-loading-animation/nba-loading-animation.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { IntroductionCarouselComponent } from './introduction-carousel/introduction-carousel.component';
import { IntroductionCardComponent } from './introduction-carousel/introduction-card/introduction-card.component';
import { StepAnimationComponent } from './step-animation/step-animation.component';
import { TrafficLightRedComponent } from './welcome/traffic-light-red/traffic-lights-red.component';
import { OnboardingTrackingService } from './onboarding-tracking.service';
import { CombineSavingsService } from '../combine-savings/combine-savings.service';
import { InvestmentGuideModule } from '@mitpfa/shared/investment/guide/investment-guide.module';
import { StepContactComponent } from './step-contact/step-contact.component';
import { StepInvestmentComponent } from './step-investment/step-investment.component';
import { StepFinancesComponent } from './step-finances/step-finances.component';
import { StepEndComponent } from './step-end/step-end.component';
import { InsuranceComponent } from './insurance/insurance.component';
import { InvestmentComponent } from './investment/investment.component';
import { StepInsuranceComponent } from './step-insurance/step-insurance.component';
import { InvestmentGuideConfigurationService } from '@mitpfa/shared/investment/guide/investment-guide-configuration.service';

@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    SharedModule,
    CoreModule,
    IconModule,
    MatButtonModule,
    MatProgressBarModule,
    MatSlideToggleModule,
    MatDialogModule,
    PensionInfoModule,
    PfaFormModule,
    PensionCheckModule,
    InvestmentGuideModule
  ],
  declarations: [
    OnboardingComponent,
    WelcomeComponent,
    ContactComponent,
    ConsentComponent,
    PensionsinfoComponent,
    ExternalTransferComponent,
    DoneComponent,
    WarningComponent,
    NbaLoadingAnimationComponent,
    HeaderComponent,
    FooterComponent,
    IntroductionCarouselComponent,
    IntroductionCardComponent,
    StepContactComponent,
    StepFinancesComponent,
    StepAnimationComponent,
    TrafficLightRedComponent,
    StepInvestmentComponent,
    StepInsuranceComponent,
    InsuranceComponent,
    InvestmentComponent,
    StepEndComponent
  ],
  exports: [OnboardingComponent],
  providers: [
    MatSlideToggle,
    OnboardingTrackingService,
    CombineSavingsService,
    InvestmentGuideConfigurationService,
    InvestmentGuideService
  ]
})
export class OnboardingModule {}
