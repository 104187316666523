/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const PraemieFritagelseTilvalgSchema = JoiObj.string().valid(
        'HeleIndbetalingen'
        ,
        'KunPaaObligatoriskDel'
        ,
        'IngenPraemiefritagelse'
        
    )
.id('PraemieFritagelseTilvalgSchema')

export type PraemieFritagelseTilvalg = 'HeleIndbetalingen' | 'KunPaaObligatoriskDel' | 'IngenPraemiefritagelse';

export const PraemieFritagelseTilvalg = {
    HeleIndbetalingen: 'HeleIndbetalingen' as PraemieFritagelseTilvalg,
    KunPaaObligatoriskDel: 'KunPaaObligatoriskDel' as PraemieFritagelseTilvalg,
    IngenPraemiefritagelse: 'IngenPraemiefritagelse' as PraemieFritagelseTilvalg
};

