import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { Debt, MaeglerInfo } from '@pfa/gen';
import { InvestmentGuideInfo } from '@pfa/models';
import { generateSliderStepsArray } from '@pfaform';
import { Options } from 'ngx-slider-v2';
import { InvestmentGuideQuestion } from '../../../investment-guide-configuration.service';
import { InvestmentGuideHasDebtService } from './investment-guide-has-debt.service';
import { GuideTrackingService } from '@pfa/core';
import { InvestmentGuideService } from '@mitpfa/shared';

@Component({
  selector: 'mitpfa-investment-guide-has-debt',
  templateUrl: './investment-guide-has-debt.component.html',
  styleUrls: ['./investment-guide-has-debt.component.scss']
})
export class InvestmentGuideHasDebtComponent implements OnInit, AfterViewInit {
  @Input() investmentGuide: InvestmentGuideInfo;
  @Input() broker: MaeglerInfo;

  form = new UntypedFormGroup({});
  question = InvestmentGuideQuestion;
  debt: Debt;
  hasDebt = false;
  activeIndex = 0;
  sliderOptions: Options = {
    stepsArray: [
      ...generateSliderStepsArray(0, 300000, 10000),
      ...generateSliderStepsArray(350000, 10000000, 50000)
    ],
    ceil: 10000000
  };

  constructor(
    readonly investmentGuideHasDebtService: InvestmentGuideHasDebtService,
    readonly trackingService: GuideTrackingService,
    private readonly investmentGuideService: InvestmentGuideService
  ) {}

  ngOnInit() {
    if (this.investmentGuide?.recommendationBasis?.debt) {
      this.debt = this.investmentGuide.recommendationBasis.debt;
    } else {
      this.debt = {} as Debt;
    }

    this.initializeForm();
  }

  ngAfterViewInit(): void {
    window.scroll(0, 0);
  }

  declined() {
    this.investmentGuideHasDebtService.clearDebt(this.form);
    this.goToNextPage();
  }

  goBack() {
    this.investmentGuideService.updateCurrentQuestion({
      value: this.question.secondaryResidence,
      update: false,
      back: true
    });
  }

  goToNextPage() {
    this.investmentGuide.recommendationBasis.debt =
      this.investmentGuideHasDebtService.setDebt(this.hasDebt, this.form);
    this.investmentGuideService.updateCurrentQuestion({
      value: this.question.economicalImpact,
      update: true
    });
  }

  displayDetails() {
    this.hasDebt = true;
    this.activeIndex = 1;
  }

  closeDetails() {
    this.activeIndex = 0;
  }

  private initializeForm() {
    this.form = new UntypedFormGroup({
      currentDebt: new UntypedFormControl(this.debt.currentDebt || 0, {
        validators: [Validators.required]
      })
    });
  }
}
