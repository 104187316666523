/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const BetalingFrekvensSchema = JoiObj.string().valid(
        'Maanedlig'
        ,
        'Kvartaarlig'
        ,
        'Halvaarlig'
        ,
        'Aarlig'
        
    )
.id('BetalingFrekvensSchema')

export type BetalingFrekvens = 'Maanedlig' | 'Kvartaarlig' | 'Halvaarlig' | 'Aarlig';

export const BetalingFrekvens = {
    Maanedlig: 'Maanedlig' as BetalingFrekvens,
    Kvartaarlig: 'Kvartaarlig' as BetalingFrekvens,
    Halvaarlig: 'Halvaarlig' as BetalingFrekvens,
    Aarlig: 'Aarlig' as BetalingFrekvens
};

