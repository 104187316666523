import { Injectable } from '@angular/core';
import {
  ConfirmChoiceComponentTexts,
  ConfirmChoiceTextsConfiguration
} from '@pfaform';

@Injectable({
  providedIn: 'root'
})
export class InsuranceAdjustmentConfirmChoiceConfigurationService {
  private confirmChoiceTextConfiguration: ConfirmChoiceTextsConfiguration = {
    confirm: {
      headline: undefined,
      body: undefined,
      button: 'DL.TF01.C181'
    },
    reconfirm: {
      headline: 'DL.INV.reconfirmheadline',
      body: undefined,
      button: 'DL.INV.textonreconfirmbuttton'
    },
    thankYou: {
      headline: 'DL.INV.thankyou',
      body: undefined,
      button: undefined
    }
  };

  public confirmChoiceComponentTexts: ConfirmChoiceComponentTexts = {
    bodyContentId: this.confirmChoiceTextConfiguration.confirm.body,
    headlineContentId: this.confirmChoiceTextConfiguration.confirm.headline,
    buttonContentId: this.confirmChoiceTextConfiguration.confirm.button
  };
}
