/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { EngangsUdbetaling } from './engangsUdbetaling';
import { EngangsUdbetalingSchema } from './engangsUdbetaling';
import { ServiceInfo } from './serviceInfo';
import { ServiceInfoSchema } from './serviceInfo';
import { AnnualPayments } from './annualPayments';
import { AnnualPaymentsSchema } from './annualPayments';
import { UsikkerhedPolice } from './usikkerhedPolice';
import { UsikkerhedPoliceSchema } from './usikkerhedPolice';
import { FrivilligIndbetaling } from './frivilligIndbetaling';
import { FrivilligIndbetalingSchema } from './frivilligIndbetaling';
import { PayoutTable } from './payoutTable';
import { PayoutTableSchema } from './payoutTable';
import { ResourceModel } from './resourceModel';
import { ResourceModelSchema } from './resourceModel';
import { AdvisorCorrectionsDetails } from './advisorCorrectionsDetails';
import { AdvisorCorrectionsDetailsSchema } from './advisorCorrectionsDetails';
import { PoliceIkkeMedtagesIBeregning } from './policeIkkeMedtagesIBeregning';
import { PoliceIkkeMedtagesIBeregningSchema } from './policeIkkeMedtagesIBeregning';
import * as JoiObj from "joi";


    export const UdbetalingsplanDetaljerSchema = JoiObj.object({
        serviceInfo:
            
        JoiObj.link('#ServiceInfoSchema')

.allow(null)



        ,
        advisorCorrectionsPlan:
            
        JoiObj.link('#AdvisorCorrectionsDetailsSchema')


.required()


        ,
        aendringerTilAntagelse:
            
                    JoiObj.boolean()
                


.required()


        ,
        annualPayments:
                JoiObj.array().items(
        
        JoiObj.link('#AnnualPaymentsSchema')

        )
        

.required()


        ,
        fastlaast:
            
                    JoiObj.boolean()
                


.required()


        ,
        fejledeAftaler:
                JoiObj.array().items(
        
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

        )
        

.required()


        ,
        foedselsdag:
            
                    JoiObj.object()
                


.required()


        ,
        forventetPensionsDatoString:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        forventetPensionsalder:
            
                    JoiObj.number()
                


.required()


        ,
        frivilligIndbetaling:
            
        JoiObj.link('#FrivilligIndbetalingSchema')


.required()


        ,
        grafEngangsbeloeb:
                JoiObj.array().items(
        
        JoiObj.link('#EngangsUdbetalingSchema')

        )
        

.required()


        ,
        harAndelSomErUdbetalingsSikret:
            
                    JoiObj.boolean()
                


.required()


        ,
        harBolig:
            
                    JoiObj.boolean()
                


.required()


        ,
        harFormue:
            
                    JoiObj.boolean()
                


.required()


        ,
        harHistoriskeUdbetalinger:
            
                    JoiObj.boolean()
                


.required()


        ,
        harKapital:
            
                    JoiObj.boolean()
                


.required()


        ,
        harNaesteAarsUdbetaling:
            
                    JoiObj.boolean()
                


.required()


        ,
        harPensionsalderFremrykket:
            
                    JoiObj.boolean()
                


.required()


        ,
        harPrognoseFraLivnet:
            
                    JoiObj.boolean()
                


.required()


        ,
        harPrognoseFraPIEllerManuelle:
            
                    JoiObj.boolean()
                


.required()


        ,
        harPrognoseKunFraMarketsrente:
            
                    JoiObj.boolean()
                


.required()


        ,
        harRate:
            
                    JoiObj.boolean()
                


.required()


        ,
        harRateperiodeAendret:
            
                    JoiObj.boolean()
                


.required()


        ,
        harTarifudbetalingMedGennemsnitsrente:
            
                    JoiObj.boolean()
                


.required()


        ,
        ikkeMuligt:
            
                    JoiObj.boolean()
                


.required()


        ,
        lavestePensionsalder:
            
                    JoiObj.number()
                


.required()


        ,
        loen:
            
                    JoiObj.number()
                


.required()


        ,
        loenEfterFrivilligIndbetaling:
            
                    JoiObj.number()
                


.required()


        ,
        loenEfterSkat:
            
                    JoiObj.number()
                


.required()


        ,
        maxRatePeriode:
            
                    JoiObj.number()
                


.required()


        ,
        merindbetalingMedregnet:
            
                    JoiObj.boolean()
                


.required()


        ,
        minRatePeriode:
            
                    JoiObj.number()
                


.required()


        ,
        negativeFreeAssets:
            
                    JoiObj.boolean()
                


.required()


        ,
        negativeProperty:
            
                    JoiObj.boolean()
                


.required()


        ,
        nuvaerendeAlder:
            
                    JoiObj.number()
                


.required()


        ,
        nuvaerendeCalendarAar:
            
                    JoiObj.number()
                


.required()


        ,
        payoutStartDateMissing:
            
                    JoiObj.boolean()
                


.required()


        ,
        payoutTable:
            
        JoiObj.link('#PayoutTableSchema')


.required()


        ,
        policeIkkeMedtagesIBeregnings:
                JoiObj.array().items(
        
        JoiObj.link('#PoliceIkkeMedtagesIBeregningSchema')

        )
        

.required()


        ,
        policiesPayinAfterPayoutAge:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        policiesUnderPayout:
                JoiObj.array().items(
        
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

        )
        

.required()


        ,
        raterForskellige:
            
                    JoiObj.boolean()
                


.required()


        ,
        selectedAgeEarlierThanEarliestPayoutage:
            
                    JoiObj.boolean()
                


.required()


        ,
        selectedAgeLaterThanLatestPayoutage:
            
                    JoiObj.boolean()
                


.required()


        ,
        senesteAlderRate:
            
                    JoiObj.number()
                


.required()


        ,
        skatAfLoen:
            
                    JoiObj.number()
                


.required()


        ,
        startAlderRate:
            
                    JoiObj.number()
                


.required()


        ,
        udbetalingDoedId:
                JoiObj.array().items(
        
                    JoiObj.number()
                

        )
        

.required()


        ,
        udbetalingFjernetAdvarselVises:
            
                    JoiObj.boolean()
                


.required()


        ,
        usikkerhedPolicer:
                JoiObj.array().items(
        
        JoiObj.link('#UsikkerhedPoliceSchema')

        )
        

.required()


        ,
        visPensionsalderAdvarsel:
            
                    JoiObj.boolean()
                


.required()


        
    })
                .shared(ServiceInfoSchema)
                .shared(AdvisorCorrectionsDetailsSchema)
                .shared(AnnualPaymentsSchema)
                .shared(FrivilligIndbetalingSchema)
                .shared(EngangsUdbetalingSchema)
                .shared(PayoutTableSchema)
                .shared(PoliceIkkeMedtagesIBeregningSchema)
                .shared(UsikkerhedPoliceSchema)
.id('UdbetalingsplanDetaljerSchema')

export interface UdbetalingsplanDetaljer extends ResourceModel { 
    advisorCorrectionsPlan: AdvisorCorrectionsDetails;
    aendringerTilAntagelse: boolean;
    annualPayments: Array<AnnualPayments>;
    fastlaast: boolean;
    fejledeAftaler: Array<string>;
    foedselsdag: object;
    forventetPensionsDatoString: string;
    forventetPensionsalder: number;
    frivilligIndbetaling: FrivilligIndbetaling;
    grafEngangsbeloeb: Array<EngangsUdbetaling>;
    harAndelSomErUdbetalingsSikret: boolean;
    harBolig: boolean;
    harFormue: boolean;
    harHistoriskeUdbetalinger: boolean;
    harKapital: boolean;
    harNaesteAarsUdbetaling: boolean;
    harPensionsalderFremrykket: boolean;
    harPrognoseFraLivnet: boolean;
    harPrognoseFraPIEllerManuelle: boolean;
    harPrognoseKunFraMarketsrente: boolean;
    harRate: boolean;
    harRateperiodeAendret: boolean;
    harTarifudbetalingMedGennemsnitsrente: boolean;
    ikkeMuligt: boolean;
    lavestePensionsalder: number;
    loen: number;
    loenEfterFrivilligIndbetaling: number;
    loenEfterSkat: number;
    maxRatePeriode: number;
    merindbetalingMedregnet: boolean;
    minRatePeriode: number;
    negativeFreeAssets: boolean;
    negativeProperty: boolean;
    nuvaerendeAlder: number;
    nuvaerendeCalendarAar: number;
    payoutStartDateMissing: boolean;
    payoutTable: PayoutTable;
    policeIkkeMedtagesIBeregnings: Array<PoliceIkkeMedtagesIBeregning>;
    policiesPayinAfterPayoutAge: string;
    policiesUnderPayout: Array<string>;
    raterForskellige: boolean;
    selectedAgeEarlierThanEarliestPayoutage: boolean;
    selectedAgeLaterThanLatestPayoutage: boolean;
    senesteAlderRate: number;
    skatAfLoen: number;
    startAlderRate: number;
    udbetalingDoedId: Array<number>;
    udbetalingFjernetAdvarselVises: boolean;
    usikkerhedPolicer: Array<UsikkerhedPolice>;
    visPensionsalderAdvarsel: boolean;
}

