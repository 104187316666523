import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DynamicadvisingStore } from '@pfa/gen';

/**
 * Interceptor will flush cache for dynamic advising:
 * The response is ok.
 * The request is a POST, PUT or DELETE.
 * The url is not on the ignore list.
 */

@Injectable()
export class DynamicAdvisingInterceptorService implements HttpInterceptor {
  constructor(private readonly dynamicadvisingStore: DynamicadvisingStore) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(event => {
        const methods = ['POST', 'PUT', 'DELETE'];
        if (
          methods.includes(request.method) &&
          event instanceof HttpResponse &&
          !this.ignoreStartWithUrlCall(request.url) &&
          !this.ignoreExactUrlCall(request.url) &&
          event.ok
        ) {
          this.dynamicadvisingStore.invalidatedynamicadvisingIndex();
        }
      })
    );
  }

  private ignoreStartWithUrlCall(url: string): boolean {
    const ignoreList = [
      '/ds/api/investering/pfainvesterer',
      '/ds/api/investering/duinvesterer',
      '/ds/api/investering/pfavalgfri',
      '/ds/api/investering/klimaplus',
      '/ds/api/temp/externaltransfer/sign',
      '/sk/api/api/temp/skadesanmeldelse',
      '/sk/api/api/temp/upload/skadesanmeldelse'
    ];
    return ignoreList.find(element => url.includes(element)) !== undefined;
  }

  private ignoreExactUrlCall(url: string): boolean {
    const ignoreList = [
      '/ds/api/mineaendringer/kasserAendring',
      '/ds/open/log/jserror',
      '/ds/api/temp/opsparingsplan/simulere',
      '/ds/api/temp/opsparingsplan/frivilligindbetaling',
      '/ds/api/mineaendringer/tilfoejTilKurv',
      '/ds/api/mineaendringer/bygkontrakt',
      '/ds/api/udbetalingsplan/simulere',
      '/ds/api/udbetalingsplan/frivilligindbetaling',
      '/ds/api/tilpasforsikringstal/opdatertaeloebende',
      '/ds/api/tilpasforsikringstal/opdatertaeengang',
      '/ds/api/tilpasforsikringstal/opdaterkritisksygdom',
      '/ds/api/tilpasforsikringstal/opdaterkritisksygdomboern',
      '/ds/api/tilpasforsikringstal/opdaterliv',
      '/ds/api/tilpasforsikringstal/opdaterboernepension',
      '/ds/api/tilpasforsikringstal/dynamicadvising/opdatertaeloebende',
      '/ds/api/tilpasforsikringstal/dynamicadvising/opdaterliv',
      '/ds/api/pensionskunde/opdaterMobilnrOgEmail',
      '/ds/api/pensionskunde/saveConsent',
      '/ds/api/privatindskud/cancel',
      '/ds/api/temp/onboarding/registerCompletedStep',
      '/ds/api/temp/externaltransfer',
      '/ds/api/temp/externaltransfer/lead',
      '/ds/api/temp/externaltransfer/calculate',
      '/ds/api/temp/overfoersel/hentinternoverfoersel'
    ];
    return ignoreList.find(element => url === element) !== undefined;
  }
}
