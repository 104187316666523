import { Component, EventEmitter, Input, Output } from '@angular/core';

import {
  CombineSavingsNavigationEvent,
  CombineSavingsStep
} from '@mitpfa/shared/combine-savings/combine-savings.model';

@Component({
  selector: 'mitpfa-combine-savings-intro',
  templateUrl: './combine-savings-intro.component.html',
  styleUrls: ['./combine-savings-intro.component.scss']
})
export class CombineSavingsIntroComponent {
  @Output()
  readonly next = new EventEmitter<CombineSavingsNavigationEvent>();
  @Input() welcomeflow: boolean;

  public goToNextPage(): void {
    this.next.emit({ from: CombineSavingsStep.Intro });
  }
}
