<co-card-animated-stack [activeIndex]="currentCard">
  <co-card-animated-stack-item>
    <co-card-question (agreeClick)="clickedYes()" (declineClick)="clickedNo()">
      <div
        class="question-title spacing-unit--under"
        coContent="DL.FG01.C1877"
      ></div>
      <p
        class="text--info"
        coContent="DL.FG01.C1878"
        [coContentSubst]="
          (multiplier | number: format : 'da-DK') +
          '|' +
          (recommendedSalary | numberFormat)
        "
      ></p>
      <p class="text--info" coContent="DL.FG01.C1879"></p>
      <p
        class="text--info"
        coContent="DL.FG01.C1880"
        [coContentSubst]="currentDept | numberFormat"
      ></p>
    </co-card-question>
  </co-card-animated-stack-item>

  <co-card-animated-stack-item>
    <co-card-split-horizontal invert="true">
      <div body>
        <button
          class="close-icon"
          mat-icon-button
          (click)="previousCardOnStack()"
        >
          <co-icon-times></co-icon-times>
        </button>
        <div
          class="question-title spacing-unit--under"
          coContent="DL.FG01.C1817"
        ></div>
        <p
          class="text--info"
          coContent="DL.FG01.C1878"
          [coContentSubst]="
            (multiplier | number: format : 'da-DK') +
            '|' +
            (recommendedSalary | numberFormat)
          "
        ></p>
        <p class="text--info" coContent="DL.FG01.C1879"></p>
        <p
          class="text--info"
          coContent="DL.FG01.C1880"
          [coContentSubst]="currentDept | numberFormat"
        ></p>
      </div>
      <div header>
        <form [formGroup]="caseForm" class="row">
          <div class="col">
            <mat-form-field color="accent">
              <mat-label>{{ 'DL.FG01.C1819' | content }}</mat-label>
              <input
                formControlName="myExtraCoverage"
                ngDefaultControl
                coAmount
                class="small-input"
                matInput
              />
              <mat-error
                *ngIf="caseForm.controls['myExtraCoverage'].errors?.required"
                [coContent]="'DL.MD01.C12'"
              ></mat-error>
              <mat-error
                *ngIf="
                  caseForm.controls['myExtraCoverage'].invalid &&
                  !caseForm.controls['myExtraCoverage'].errors?.required
                "
                [coContent]="'DL.MD01.C121'"
              ></mat-error>
            </mat-form-field>
          </div>
          <div class="col">
            <mat-form-field
              *ngIf="caseForm.controls.partnerExtraCoverage"
              color="accent"
            >
              <mat-label>{{ 'DL.FG01.C1821' | content }}</mat-label>
              <input
                formControlName="partnerExtraCoverage"
                ngDefaultControl
                coAmount
                class="small-input"
                matInput
              />
              <mat-error
                *ngIf="
                  caseForm.controls['partnerExtraCoverage'].errors?.required
                "
                [coContent]="'DL.MD01.C12'"
              ></mat-error>
              <mat-error
                *ngIf="
                  caseForm.controls['partnerExtraCoverage'].invalid &&
                  !caseForm.controls['partnerExtraCoverage'].errors?.required
                "
                [coContent]="'DL.MD01.C121'"
              ></mat-error>
            </mat-form-field>
          </div>
        </form>
        <div class="row align--center">
          <div class="col-sm-12">
            <button
              [disabled]="!caseForm.valid"
              class="button-secondary"
              (click)="saveChangesAndGoNext()"
              mat-raised-button
              coContent="DL.FG01.C1875"
            ></button>
          </div>
        </div>
      </div>
    </co-card-split-horizontal>
  </co-card-animated-stack-item>
</co-card-animated-stack>
<div class="align--center spacing-unit--over" *ngIf="currentCard === 0">
  <mitpfa-insurance-guide-back-button
    (back)="goBack()"
  ></mitpfa-insurance-guide-back-button>
</div>
