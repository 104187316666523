/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ServiceInfo } from './serviceInfo';
import { ServiceInfoSchema } from './serviceInfo';
import { Skattekode } from './skattekode';
import { SkattekodeSchema } from './skattekode';
import { ResourceModel } from './resourceModel';
import { ResourceModelSchema } from './resourceModel';
import { DaekningsType } from './daekningsType';
import { DaekningsTypeSchema } from './daekningsType';
import { DaekningMedUdbetalingssikringMedIndfasning } from './daekningMedUdbetalingssikringMedIndfasning';
import { DaekningMedUdbetalingssikringMedIndfasningSchema } from './daekningMedUdbetalingssikringMedIndfasning';
import * as JoiObj from "joi";


    export const DaekningsDetaljerSchema = JoiObj.object({
        serviceInfo:
            
        JoiObj.link('#ServiceInfoSchema')

.allow(null)



        ,
        skattekode:
            
        JoiObj.link('#SkattekodeSchema')


.required()


        ,
        udbetalingsforloebId:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        udbetalingssikringsYdelse:
            
                    JoiObj.number()
                

.allow(null)



        ,
        forventetSikretAndel:
            
                    JoiObj.number()
                

.allow(null)



        ,
        udbetalingStartDato:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        aarSpaend:
                JoiObj.array().items(
        
                    JoiObj.number()
                

        )
        

.required()


        ,
        aftaleNummer:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        antalUdbetalingsAar:
            
                    JoiObj.number()
                


.required()


        ,
        beloeb:
            
                    JoiObj.number()
                


.required()


        ,
        betingelseParameter:
            
                    JoiObj.number()
                


.required()


        ,
        daekningsType:
            
        JoiObj.link('#DaekningsTypeSchema')


.required()


        ,
        futurePrognosis:
            
                    JoiObj.number()
                


.required()


        ,
        hasCustomerCapital:
            
                    JoiObj.boolean()
                


.required()


        ,
        indfasningstrin:
            
                    JoiObj.number()
                

.allow(null)



        ,
        laengestTilUdbetalingAlder:
            
                    JoiObj.number()
                


.required()


        ,
        laengestTilUdbetalingDato:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        livnetAftale:
            
                    JoiObj.boolean()
                


.required()


        ,
        maxIndfasningstrin:
            
                    JoiObj.number()
                

.allow(null)



        ,
        pensionsselskabNr:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        risikoOphoerAlder:
            
                    JoiObj.number()
                


.required()


        ,
        tarif:
            
                    JoiObj.boolean()
                


.required()


        ,
        tariffPayout:
            
                    JoiObj.number()
                

.allow(null)



        ,
        typeContentId:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        udbetalingBeskatning51:
            
                    JoiObj.boolean()
                


.required()


        ,
        udbetalingSlutAlder:
            
                    JoiObj.number()
                


.required()


        ,
        udbetalingSlutDato:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        udbetalingStartAlder:
            
                    JoiObj.number()
                

.allow(null)



        ,
        udbetalingssikring:
            
                    JoiObj.boolean()
                


.required()


        ,
        underUdbetaling:
            
                    JoiObj.boolean()
                


.required()


        
    })
                .shared(ServiceInfoSchema)
                .shared(SkattekodeSchema)
                .shared(DaekningsTypeSchema)
.id('DaekningsDetaljerSchema')

export interface DaekningsDetaljer extends ResourceModel, DaekningMedUdbetalingssikringMedIndfasning { 
    aarSpaend: Array<number>;
    aftaleNummer: string;
    antalUdbetalingsAar: number;
    beloeb: number;
    betingelseParameter: number;
    daekningsType: DaekningsType;
    futurePrognosis: number;
    hasCustomerCapital: boolean;
    indfasningstrin?: number;
    laengestTilUdbetalingAlder: number;
    laengestTilUdbetalingDato: string;
    livnetAftale: boolean;
    maxIndfasningstrin?: number;
    pensionsselskabNr: string;
    risikoOphoerAlder: number;
    tarif: boolean;
    tariffPayout?: number;
    typeContentId: string;
    udbetalingBeskatning51: boolean;
    udbetalingSlutAlder: number;
    udbetalingSlutDato: string;
    udbetalingStartAlder?: number | null;
    udbetalingssikring: boolean;
    underUdbetaling: boolean;
}
export namespace DaekningsDetaljer {
}


