<div [ngSwitch]="coverage.udbetalingtype">
  <div *ngSwitchCase="'BELOEB'">
    <div>
      {{ coverage.udbetaling | numberFormat }}
      <span [coContent]="'Global.kroner'"></span>
    </div>
    <div
      *ngIf="coverage.skattekode === 'SK5'"
      class="text--note"
      [coContent]="'DL.FG01.C54'"
    ></div>
  </div>
  <div *ngSwitchCase="'TJEK'"><co-icon-checkmark></co-icon-checkmark></div>
  <div *ngSwitchCase="'TEKST'">
    <co-icon-info
      *ngIf="coverage.udbetalingTekstId === 'DL.TR01.C423'"
    ></co-icon-info>
    <span class="text--note" [coContent]="coverage.udbetalingTekstId"></span>
  </div>
</div>
