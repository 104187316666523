import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PensionsKundeGenerelleData, StamdataDetaljer } from '@pfa/gen';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { PersonNameValidator } from '@pfa/utils';
import * as moment from 'moment';

@Component({
  selector: 'mitpfa-pension-check-partner',
  templateUrl: './pension-check-partner.component.html',
  styleUrls: ['./pension-check-partner.component.scss']
})
export class PensionCheckPartnerComponent implements OnInit {
  @Output() close = new EventEmitter<void>();
  @Input() familyInformation: StamdataDetaljer;
  @Input() pensionCustomer: PensionsKundeGenerelleData;

  public partnerInfoForm: UntypedFormGroup;
  public hasSpouse: boolean;

  ngOnInit(): void {
    this.hasSpouse = this.familyInformation.civilstand === 'Gift';
    this.buildPartnerInfoForm();
  }

  private buildPartnerInfoForm(): void {
    const isMarried = this.familyInformation.civilstand === 'Gift';

    this.partnerInfoForm = new UntypedFormGroup({
      hasPartner: new UntypedFormControl(this.familyInformation.samlevende, {
        validators: !isMarried ? Validators.required : null
      }),
      hasPartner2Years: new UntypedFormControl(
        this.familyInformation.samlevendeOver2aar,
        {
          validators: !isMarried ? Validators.required : null
        }
      ),
      partnerName: new UntypedFormControl(
        this.getFullName(
          this.familyInformation.partnerFornavn,
          this.familyInformation.partnerEfternavn
        ),
        {
          validators: [Validators.required, PersonNameValidator]
        }
      ),
      partnerLastName: new UntypedFormControl(
        this.familyInformation.partnerEfternavn
      ),
      partnerBirthday: new UntypedFormControl(
        moment(this.familyInformation.partnerFoedselsDato, 'DD.MM.YYYY')
      ),
      partnerSalary: new UntypedFormControl(
        this.familyInformation.partnerAarsloen,
        {
          validators: [Validators.maxLength(10)]
        }
      )
    });
  }

  private keepFamilyDataState() {
    this.familyInformation.samlevende =
      this.partnerInfoForm.get('hasPartner').value;
    this.familyInformation.partnerFornavn = this.getFirstNameFromFullName(
      this.partnerInfoForm.get('partnerName').value
    );
    this.familyInformation.partnerEfternavn = this.getLastNameFromFullName(
      this.partnerInfoForm.get('partnerName').value
    );
    this.familyInformation.samlevendeOver2aar =
      this.partnerInfoForm.get('hasPartner2Years').value;
    this.familyInformation.partnerFoedselsDato = moment(
      this.partnerInfoForm.get('partnerBirthday').value
    ).format('DD.MM.YYYY');
    this.familyInformation.partnerAarsloen =
      this.partnerInfoForm.get('partnerSalary').value;
    this.familyInformation.samlevendeOver2aar =
      this.partnerInfoForm.get('hasPartner2Years').value;
  }

  private getFirstNameFromFullName(fullName: string): string {
    if (fullName) {
      const split = fullName.split(' ');
      if (split.length > 1) {
        split.pop();
      }
      return split.join(' ');
    } else {
      return null;
    }
  }

  private getLastNameFromFullName(fullName: string) {
    if (fullName) {
      const split = fullName.split(' ');
      return split.length > 1 ? split.pop() : null;
    } else {
      return null;
    }
  }

  private getFullName(firstName: string, lastName: string): string {
    return (firstName ? firstName : '') + (lastName ? ' ' + lastName : '');
  }

  public complete() {
    this.keepFamilyDataState();
    this.close.emit();
  }
}
