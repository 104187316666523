<div mat-dialog-title>
  <div><h3 [coContent]="'Global.advarsel'"></h3></div>
</div>

<mat-dialog-content>
  <p [coContent]="'DL.FG01.C909'"></p>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="modal-buttons">
    <button
      mat-raised-button
      class="capitalize"
      (click)="accept()"
      [coContent]="'Global.ja'"
    ></button>
    <button
      mat-raised-button
      class="capitalize"
      (click)="cancel()"
      [coContent]="'Global.nej'"
    ></button>
  </div>
</mat-dialog-actions>
