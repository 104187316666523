export enum BottomSheetStyle {
  PfaSheet = 'pfa-sheet'
}

export enum LeadType {
  ActivateRecommendationExternalTransferLeadType = 'ADP_EKSTOVERFOERSEL',
  ExternalTransferLeadType = 'EKSTOVERFOERSEL'
}

export enum PopupStyle {
  PopupSmall = 'popup--small',
  PopupMedium = 'popup--medium',
  PopupLarge = 'popup--large',
  PopupFullContent = 'popup--full-content',
  PopupExtraLarge = 'popup--extra-large',
  PopupExtraLargeMax = 'popup--extra-large-max'
}

export const SideBarStyle = {
  disableClose: true,
  panelClass: 'popup--sidebar',
  position: { right: '0' }
};

export enum AlertColor {
  GREEN = 'GREEN',
  YELLOW = 'YELLOW',
  RED = 'RED',
  GREY = 'GREY'
}

export type NullableNumber = number | null | undefined;
