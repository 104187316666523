<mitpfa-pension-check-header-section
  [headline]="'DL.NB01.C301'"
  [explanation]="'DL.NB01.C302'"
>
</mitpfa-pension-check-header-section>

<mitpfa-pension-info
  class="spacing-section--over"
  [pensionCustomer]="pensionCustomer"
  [mitIDReauthenticateMethod]="'OpenLoginWindow'"
  (updatePI)="closePensionInfo()"
></mitpfa-pension-info>

<div class="next-button">
  <button
    mat-raised-button
    (click)="closePensionInfo()"
    [coContent]="'DL.NB01.C316'"
  ></button>
</div>
