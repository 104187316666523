import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import {
  BrugerStore,
  NavnOgStatusData,
  PensionsKundeGenerelleData,
  PensionskundeStore
} from '@pfa/gen';
import { AdvisorWarningModalComponent } from './advisor-warning-modal/advisor-warning-modal.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialog } from '@angular/material/dialog';
import { combineLatest } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  selector: 'mitpfa-advisor-warning',
  template: ''
})
export class AdvisorWarningComponent implements OnInit {
  private readonly pensionskundeStore: PensionskundeStore =
    inject(PensionskundeStore);
  private readonly brugerStore: BrugerStore = inject(BrugerStore);
  private readonly dialog: MatDialog = inject(MatDialog);
  private readonly destroyRef: DestroyRef = inject(DestroyRef);

  ngOnInit(): void {
    combineLatest({
      pensionCustomer: this.pensionskundeStore.pensionskundeGet(),
      user: this.brugerStore.brugerGet()
    })
      .pipe(takeUntilDestroyed(this.destroyRef), take(1))
      .subscribe({
        next: data => {
          this.showAdvisorWarningDialog(data);
        },
        error: () => {}
      });
  }

  private showAdvisorWarningDialog(data: {
    pensionCustomer: PensionsKundeGenerelleData;
    user: NavnOgStatusData;
  }): void {
    // only for advisors from mitpfa pro when login as customer
    if (
      !data.pensionCustomer.isRaadgiver &&
      !data.user.isSomPrivat &&
      !data.pensionCustomer.isPfaUser
    ) {
      return;
    }
    // when cause for use is defined, skip opening dialog
    if (data.pensionCustomer.aarsagTilBrug) {
      return;
    }

    this.dialog
      .open(AdvisorWarningModalComponent, {
        data: data.pensionCustomer,
        disableClose: true
      })
      .afterClosed()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe();
  }
}
