/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const InvestmentFundChangeSchema = JoiObj.object({
        fundName:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        course:
            
                    JoiObj.number()
                


.required()


        ,
        quantity:
            
                    JoiObj.number()
                


.required()


        ,
        share:
            
                    JoiObj.number()
                


.required()


        ,
        value:
            
                    JoiObj.number()
                


.required()


        ,
        price:
            
                    JoiObj.number()
                


.required()


        
    })
.id('InvestmentFundChangeSchema')

export interface InvestmentFundChange { 
    fundName: string;
    course: number;
    quantity: number;
    share: number;
    value: number;
    price: number;
}

