/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PFAPensionProductOfferHealthCaseElement } from './pFAPensionProductOfferHealthCaseElement';
import { PFAPensionProductOfferHealthCaseElementSchema } from './pFAPensionProductOfferHealthCaseElement';
import { PFAPensionProductOfferOrderElement } from './pFAPensionProductOfferOrderElement';
import { PFAPensionProductOfferOrderElementSchema } from './pFAPensionProductOfferOrderElement';
import { PolicyTypeOffer } from './policyTypeOffer';
import { PolicyTypeOfferSchema } from './policyTypeOffer';
import { CompanyOrPrivate } from './companyOrPrivate';
import { CompanyOrPrivateSchema } from './companyOrPrivate';
import { PFAPensionProductOfferOfferElement } from './pFAPensionProductOfferOfferElement';
import { PFAPensionProductOfferOfferElementSchema } from './pFAPensionProductOfferOfferElement';
import { SalesOrganizationName } from './salesOrganizationName';
import { SalesOrganizationNameSchema } from './salesOrganizationName';
import { PFAPensionProductOfferCheckList } from './pFAPensionProductOfferCheckList';
import { PFAPensionProductOfferCheckListSchema } from './pFAPensionProductOfferCheckList';
import * as JoiObj from "joi";


    export const PFAPensionProductOfferSchema = JoiObj.object({
        active:
            
                    JoiObj.boolean()
                


.required()


        ,
        checkList:
            
        JoiObj.link('#PFAPensionProductOfferCheckListSchema')

.allow(null)



        ,
        companyOrPrivate:
            
        JoiObj.link('#CompanyOrPrivateSchema')

.allow(null)



        ,
        expired:
            
                    JoiObj.boolean()
                


.required()


        ,
        healthCaseElement:
            
        JoiObj.link('#PFAPensionProductOfferHealthCaseElementSchema')

.allow(null)



        ,
        offerElement:
            
        JoiObj.link('#PFAPensionProductOfferOfferElementSchema')


.required()


        ,
        orderElement:
            
        JoiObj.link('#PFAPensionProductOfferOrderElementSchema')

.allow(null)



        ,
        policyNumber:
            
                    JoiObj.number()
                


.required()


        ,
        policyTypeOffer:
            
        JoiObj.link('#PolicyTypeOfferSchema')


.required()


        ,
        salesOrganizationName:
            
        JoiObj.link('#SalesOrganizationNameSchema')


.required()


        ,
        specialAgreementName:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        
    })
                .shared(PFAPensionProductOfferCheckListSchema)
                .shared(CompanyOrPrivateSchema)
                .shared(PFAPensionProductOfferHealthCaseElementSchema)
                .shared(PFAPensionProductOfferOfferElementSchema)
                .shared(PFAPensionProductOfferOrderElementSchema)
                .shared(PolicyTypeOfferSchema)
                .shared(SalesOrganizationNameSchema)
.id('PFAPensionProductOfferSchema')

export interface PFAPensionProductOffer { 
    active: boolean;
    checkList?: PFAPensionProductOfferCheckList;
    companyOrPrivate?: CompanyOrPrivate;
    expired: boolean;
    healthCaseElement?: PFAPensionProductOfferHealthCaseElement;
    offerElement: PFAPensionProductOfferOfferElement;
    orderElement?: PFAPensionProductOfferOrderElement;
    policyNumber: number;
    policyTypeOffer: PolicyTypeOffer;
    salesOrganizationName: SalesOrganizationName;
    specialAgreementName?: string;
}
export namespace PFAPensionProductOffer {
}


