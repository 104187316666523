import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { combineLatest, take } from 'rxjs';
import {
  CustomerOptions,
  CustomerProfile,
  CustomerprofileStore,
  IndividualcustomerprofileStore,
  PensionsKundeGenerelleData,
  PensionskundeStore
} from '@pfa/gen';
import {
  ContactRemindComponent,
  NotificationService,
  NotificationType
} from '@pfaform';
import { ContentUtilService, PageTrackingService } from '@pfa/core';
import { MatDialog } from '@angular/material/dialog';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { PopupStyle } from '@pfa/models';

@Component({
  selector: 'mitpfa-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  private readonly pensionskundeStore: PensionskundeStore =
    inject(PensionskundeStore);
  private readonly individualcustomerprofileStore: IndividualcustomerprofileStore =
    inject(IndividualcustomerprofileStore);
  private readonly customerprofileStore: CustomerprofileStore =
    inject(CustomerprofileStore);
  private readonly notificationService: NotificationService =
    inject(NotificationService);
  private readonly contentUtil: ContentUtilService = inject(ContentUtilService);
  private readonly pageTrackingService: PageTrackingService =
    inject(PageTrackingService);
  private readonly dialog: MatDialog = inject(MatDialog);
  private readonly destroyRef: DestroyRef = inject(DestroyRef);

  public pensionCustomer: PensionsKundeGenerelleData;
  public customerOptions: CustomerOptions;
  public customerProfile: CustomerProfile;
  public initialized: boolean;
  private savingsContentLoaded: boolean;
  private insuranceContentLoaded: boolean;

  constructor() {
    this.savingsContentLoaded = false;
    this.insuranceContentLoaded = false;
  }

  public ngOnInit(): void {
    combineLatest({
      pensionCustomer: this.pensionskundeStore.pensionskundeGet(),
      customerOptions:
        this.individualcustomerprofileStore.individualcustomerprofileCustomeroptionsGet(),
      customerProfile: this.customerprofileStore.customerprofileGet()
    })
      .pipe(take(1), takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: data => {
          this.initialized = true;
          this.pensionCustomer = data.pensionCustomer;
          this.customerOptions = data.customerOptions;
          this.customerProfile = data.customerProfile;
          this.initPayoutDeath();
          this.remindContact();
        }
      });
  }

  public savingsLoaded(): void {
    this.savingsContentLoaded = true;
    this.trackContentLoaded();
  }

  public insuranceLoaded(): void {
    this.insuranceContentLoaded = true;
    this.trackContentLoaded();
  }

  private trackContentLoaded(): void {
    if (this.savingsContentLoaded && this.insuranceContentLoaded) {
      this.pageTrackingService.trackContentLoaded();
    }
  }

  private initPayoutDeath(): void {
    const hasPayoutDeath =
      this.customerProfile.productPortfolio.pfaPensionProducts.some(
        product => product.policyStatus === 'UDBETALINGSPOLICE_DOED'
      );
    if (
      hasPayoutDeath &&
      sessionStorage.getItem('payoutDeathMessageShown') !== 'true'
    ) {
      sessionStorage.setItem('payoutDeathMessageShown', 'true');

      const policiesWithPayouDeath =
        this.customerProfile.productPortfolio.pfaPensionProducts
          .filter(product => product.policyStatus === 'UDBETALINGSPOLICE_DOED')
          .map(product => product.policyNumber)
          .join(', ');

      this.notificationService.showNotification({
        message: this.contentUtil.getContent(
          'DL.FS03.C58',
          policiesWithPayouDeath
        ),
        type: NotificationType.INFO,
        duration: -1,
        showCloseButton: true
      });
    }
  }

  private remindContact(): void {
    if (
      !this.pensionCustomer.isRaadgiver &&
      (!this.customerProfile.contactData?.emailValid ||
        !this.customerProfile.contactData?.phoneNumberValid ||
        this.customerProfile.contactData?.verifyContactInformation)
    ) {
      this.dialog.open(ContactRemindComponent, {
        disableClose: true,
        panelClass: PopupStyle.PopupSmall,
        data: this.pensionCustomer
      });
    }
  }
}
