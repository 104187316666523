import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { DepositsApiService } from '@pfa/api';
import { IndbetalingPolice } from '@pfa/gen';

@Injectable({
  providedIn: 'root'
})
export class DepositPolicyCurrentyearResolver
  implements Resolve<IndbetalingPolice>
{
  constructor(private readonly depositsApiService: DepositsApiService) {}

  resolve(
    route: ActivatedRouteSnapshot
  ):
    | IndbetalingPolice
    | Observable<IndbetalingPolice>
    | Promise<IndbetalingPolice> {
    return this.depositsApiService.getDepositsPolicyYear(
      route.params.policyId,
      '' + new Date().getFullYear()
    );
  }
}
