/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BeneficiaryAdvisoryQuestionType } from './beneficiaryAdvisoryQuestionType';
import { BeneficiaryAdvisoryQuestionTypeSchema } from './beneficiaryAdvisoryQuestionType';
import * as JoiObj from "joi";


    export const BeneficiaryAdvisoryQuestionSchema = JoiObj.object({
        answer:
            
                    JoiObj.boolean()
                


.required()


        ,
        questionPhrase:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        questionType:
            
        JoiObj.link('#BeneficiaryAdvisoryQuestionTypeSchema')

.allow(null)



        
    })
                .shared(BeneficiaryAdvisoryQuestionTypeSchema)
.id('BeneficiaryAdvisoryQuestionSchema')

export interface BeneficiaryAdvisoryQuestion { 
    answer: boolean;
    questionPhrase: string;
    questionType?: BeneficiaryAdvisoryQuestionType;
}
export namespace BeneficiaryAdvisoryQuestion {
}


