import { NgModule, ModuleWithProviders, SkipSelf, Optional, Provider, ErrorHandler } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { BeneficiaryService } from './api/beneficiary.service';
import { BrugerService } from './api/bruger.service';
import { BrugerundersoegelseService } from './api/brugerundersoegelse.service';
import { ClimatefootprintService } from './api/climatefootprint.service';
import { CustomerdigitalidentityService } from './api/customerdigitalidentity.service';
import { CustomerprofileService } from './api/customerprofile.service';
import { DigitalsalgService } from './api/digitalsalg.service';
import { DynamicadvisingService } from './api/dynamicadvising.service';
import { ExternaltransferService } from './api/externaltransfer.service';
import { FordeleService } from './api/fordele.service';
import { ForsikringService } from './api/forsikring.service';
import { ForsikringsguideService } from './api/forsikringsguide.service';
import { ForsikringsoversigtService } from './api/forsikringsoversigt.service';
import { IndbetalingService } from './api/indbetaling.service';
import { IndbetalingsaftalerService } from './api/indbetalingsaftaler.service';
import { IndividualcustomerdossierService } from './api/individualcustomerdossier.service';
import { IndividualcustomerprofileService } from './api/individualcustomerprofile.service';
import { InvesteringService } from './api/investering.service';
import { KundekapitalService } from './api/kundekapital.service';
import { LogoutService } from './api/logout.service';
import { MicroappconfigurationService } from './api/microappconfiguration.service';
import { MineaendringerService } from './api/mineaendringer.service';
import { MobilesigningService } from './api/mobilesigning.service';
import { OnboardingService } from './api/onboarding.service';
import { OnlineaccountService } from './api/onlineaccount.service';
import { OpenforbusinessService } from './api/openforbusiness.service';
import { OverfoerselService } from './api/overfoersel.service';
import { PensioninfoService } from './api/pensioninfo.service';
import { PensionskundeService } from './api/pensionskunde.service';
import { PensionsplanService } from './api/pensionsplan.service';
import { PensionsrapportService } from './api/pensionsrapport.service';
import { PrislisteService } from './api/prisliste.service';
import { SavingsService } from './api/savings.service';
import { SavingsPlanService } from './api/savingsPlan.service';
import { SavingsreturnService } from './api/savingsreturn.service';
import { SkadesanmeldelseService } from './api/skadesanmeldelse.service';
import { SpoergeskemaService } from './api/spoergeskema.service';
import { SystemadministrationService } from './api/systemadministration.service';
import { UdbetalingsplanService } from './api/udbetalingsplan.service';
import { UploadskadesanmeldelsedocumentService } from './api/uploadskadesanmeldelsedocument.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: []
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration, errorHandlerProvider?: Provider): ModuleWithProviders<ApiModule> {
        let providers: [Provider] = [ { provide: Configuration, useFactory: configurationFactory } ];

        if(typeof errorHandlerProvider !== 'undefined') {
            providers.push({ provide: ErrorHandler, useExisting: errorHandlerProvider })
        }

        return {
            ngModule: ApiModule,
            providers: providers
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
