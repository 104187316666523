/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OptimeringsMetode } from './optimeringsMetode';
import { OptimeringsMetodeSchema } from './optimeringsMetode';
import { Sats } from './sats';
import { SatsSchema } from './sats';
import * as JoiObj from "joi";


    export const OptimeringSatserSchema = JoiObj.object({
        activeNow:
            
                    JoiObj.boolean()
                


.required()


        ,
        name:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        updatedAt:
            
            JoiObj.date()


.required()


        ,
        updatedBy:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        uuid:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        validFrom:
            
            JoiObj.date()

.allow(null)



        ,
        validFromFormatted:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        year:
            
                    JoiObj.number()
                


.required()


        ,
        absolutBehov:
            
                    JoiObj.boolean()
                


.required()


        ,
        aegtefaelleBank:
            
                    JoiObj.number()
                


.required()


        ,
        aegtefaelleGennemsnitsRent:
            
                    JoiObj.number()
                


.required()


        ,
        aegtefaelleMarkedsRent:
            
                    JoiObj.number()
                


.required()


        ,
        behovIAndenPeriodeKr:
            
                    JoiObj.number()
                


.required()


        ,
        behovIAndenPeriodePct:
            
                    JoiObj.number()
                


.required()


        ,
        behovIFoerstePeriodeKr:
            
                    JoiObj.number()
                


.required()


        ,
        behovIFoerstePeriodePct:
            
                    JoiObj.number()
                


.required()


        ,
        beregningsSlutAlder:
            
                    JoiObj.number()
                


.required()


        ,
        bruttorenterAktier:
            
                    JoiObj.number()
                


.required()


        ,
        bruttorenterBoligLaan:
            
                    JoiObj.number()
                


.required()


        ,
        bruttorenterFastEjendom:
            
                    JoiObj.number()
                


.required()


        ,
        bruttorenterKontanter:
            
                    JoiObj.number()
                


.required()


        ,
        bruttorenterNedsparingslaan:
            
                    JoiObj.number()
                


.required()


        ,
        bruttorenterObligationer:
            
                    JoiObj.number()
                


.required()


        ,
        bufferOpsparing:
            
                    JoiObj.number()
                


.required()


        ,
        foerstePeriodeLaengdeIMndr:
            
                    JoiObj.number()
                


.required()


        ,
        formuefordelingAktierPct:
            
                    JoiObj.number()
                


.required()


        ,
        formuefordelingKontantPct:
            
                    JoiObj.number()
                


.required()


        ,
        formuefordelingObligationerPct:
            
                    JoiObj.number()
                


.required()


        ,
        inflationAarRow1:
            
                    JoiObj.number()
                


.required()


        ,
        inflationAarRow2:
            
                    JoiObj.number()
                


.required()


        ,
        inflationAarRow3:
            
                    JoiObj.number()
                


.required()


        ,
        inflationPctRow1:
            
                    JoiObj.number()
                


.required()


        ,
        inflationPctRow2:
            
                    JoiObj.number()
                


.required()


        ,
        inflationPctRow3:
            
                    JoiObj.number()
                


.required()


        ,
        optimeringsMetode:
            
        JoiObj.link('#OptimeringsMetodeSchema')


.required()


        ,
        pensionsinfoBank:
            
                    JoiObj.number()
                


.required()


        ,
        pensionsinfoIkkeMarkedsRent:
            
                    JoiObj.number()
                


.required()


        ,
        pensionsinfoMarkedsRent:
            
                    JoiObj.number()
                


.required()


        ,
        pfaGennemsnitsRent:
            
                    JoiObj.number()
                


.required()


        ,
        pfaManuelGennemsnitsRent:
            
                    JoiObj.number()
                


.required()


        ,
        pfaManuelMarkedsRent:
            
                    JoiObj.number()
                


.required()


        ,
        pfaMarkedsRent:
            
                    JoiObj.number()
                


.required()


        
    })
                .shared(OptimeringsMetodeSchema)
.id('OptimeringSatserSchema')

export interface OptimeringSatser extends Sats { 
    absolutBehov: boolean;
    aegtefaelleBank: number;
    aegtefaelleGennemsnitsRent: number;
    aegtefaelleMarkedsRent: number;
    behovIAndenPeriodeKr: number;
    behovIAndenPeriodePct: number;
    behovIFoerstePeriodeKr: number;
    behovIFoerstePeriodePct: number;
    beregningsSlutAlder: number;
    bruttorenterAktier: number;
    bruttorenterBoligLaan: number;
    bruttorenterFastEjendom: number;
    bruttorenterKontanter: number;
    bruttorenterNedsparingslaan: number;
    bruttorenterObligationer: number;
    bufferOpsparing: number;
    foerstePeriodeLaengdeIMndr: number;
    formuefordelingAktierPct: number;
    formuefordelingKontantPct: number;
    formuefordelingObligationerPct: number;
    inflationAarRow1: number;
    inflationAarRow2: number;
    inflationAarRow3: number;
    inflationPctRow1: number;
    inflationPctRow2: number;
    inflationPctRow3: number;
    optimeringsMetode: OptimeringsMetode;
    pensionsinfoBank: number;
    pensionsinfoIkkeMarkedsRent: number;
    pensionsinfoMarkedsRent: number;
    pfaGennemsnitsRent: number;
    pfaManuelGennemsnitsRent: number;
    pfaManuelMarkedsRent: number;
    pfaMarkedsRent: number;
}
export namespace OptimeringSatser {
}


