import { AfterViewInit, Component, ElementRef, inject } from '@angular/core';

@Component({
  selector: 'co-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements AfterViewInit {
  private readonly elementRef: ElementRef = inject(ElementRef);
  /*
    DO NOT USE @INPUT() BUT USE CLASSES
    Classes:
      default: orange border and background and takes a full width block
      full-page: max width 800 use to show error page and chunk error
      alert-info: TODO! 3rd color not approved! Get status from UX! use to show info messages on page
      alert-inline: up to max width 500px and use to show an alert that shouldn't be a full width block
      alert-center: centers the alert component with margin, if there's a max width
  */

  ngAfterViewInit(): void {
    const element = this.elementRef.nativeElement;

    setTimeout(() => {
      if (
        !element.classList.contains('full-page') &&
        element.offsetHeight > 50
      ) {
        element.querySelector('.alert-wrap').style.cssText =
          'padding-bottom: 12px';
      }
    });
  }
}
