/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AktivSammensaetningFordeling } from './aktivSammensaetningFordeling';
import { AktivSammensaetningFordelingSchema } from './aktivSammensaetningFordeling';
import * as JoiObj from "joi";


    export const AarTilPensionFordelingSchema = JoiObj.object({
        aar:
            
                    JoiObj.number()
                


.required()


        ,
        fondsfordeling:
                JoiObj.array().items(
        
        JoiObj.link('#AktivSammensaetningFordelingSchema')

        )
        

.required()


        
    })
                .shared(AktivSammensaetningFordelingSchema)
.id('AarTilPensionFordelingSchema')

export interface AarTilPensionFordeling { 
    aar: number;
    fondsfordeling: Array<AktivSammensaetningFordeling>;
}

