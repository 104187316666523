import { Component, OnInit, inject } from '@angular/core';
import { ContentUtilService, PageTrackingService } from '@pfa/core';

@Component({
  selector: 'mitpfa-advantage-insurance',
  templateUrl: './advantage-insurance.component.html',
  styleUrls: ['./advantage-insurance.component.scss']
})
export class AdvantageInsuranceComponent implements OnInit {
  private readonly pageTrackingService: PageTrackingService =
    inject(PageTrackingService);
  private readonly contentUtilService: ContentUtilService =
    inject(ContentUtilService);

  public readonly advantages: string[] = [
    'DL.LB01.C20',
    'DL.LB01.C21',
    'DL.LB01.C22',
    'DL.LB01.C23',
    'DL.LB01.C24',
    'DL.LB01.C25',
    'DL.LB01.C26',
    'DL.LB01.C27',
    'DL.LB01.C28',
    'DL.LB01.C29',
    'DL.LB01.C30',
    'DL.LB01.C31'
  ];

  public ngOnInit(): void {
    this.pageTrackingService.trackContentLoaded();
  }

  public trackReadMoreAboutInsurance(): void {
    this.pageTrackingService.trackNavigation(
      'insurance partner',
      'read more',
      this.contentUtilService.getContent('DL.LB01.C13')
    );
  }
}
