<div mat-dialog-title>
  <h3 coContent="DL.INV.C596"></h3>
  <a (click)="close()">
    <co-icon-times></co-icon-times>
  </a>
</div>

<mat-dialog-content>
  <p coContent="DL.INV.C597"></p>

  <table
    class="pfa-table table-responsive align-right--column-2 align-right--column-3 thead-align--center"
  >
    <thead>
      <tr>
        <th></th>
        <th coContent="DL.INV.C598"></th>
        <th coContent="DL.INV.C599"></th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td coContent="DL.INV.C600"></td>
        <td>
          <div coContent="DL.INV.C598" class="td-label"></div>
          <div>11,3</div>
        </td>
        <td>
          <div coContent="DL.INV.C599" class="td-label"></div>
          <div>11,3</div>
        </td>
      </tr>
      <tr>
        <td coContent="DL.INV.C601"></td>
        <td>
          <div coContent="DL.INV.C598" class="td-label"></div>
          <div>2,9</div>
        </td>
        <td>
          <div coContent="DL.INV.C599" class="td-label"></div>
          <div>5,4</div>
        </td>
      </tr>
      <tr>
        <td coContent="DL.INV.C602"></td>
        <td>
          <div coContent="DL.INV.C598" class="td-label"></div>
          <div>8,4</div>
        </td>
        <td>
          <div coContent="DL.INV.C599" class="td-label"></div>
          <div>5,9</div>
        </td>
      </tr>
      <tr>
        <td coContent="DL.INV.C603"></td>
        <td>
          <div coContent="DL.INV.C598" class="td-label"></div>
          <div>74,0 %</div>
        </td>
        <td>
          <div coContent="DL.INV.C599" class="td-label"></div>
          <div>52,0 %</div>
        </td>
      </tr>
    </tbody>
  </table>

  <co-info class="text--note spacing-unit--under">
    <span coContent="DL.INV.C604"></span>
  </co-info>

  <p coContent="DL.INV.C605"></p>

  <div class="spacing-unit--over">
    <p coContent="DL.INV.C606"></p>
  </div>

  <div class="spacing-unit--over">
    <h4 coContent="DL.INV.C607"></h4>
  </div>

  <table
    class="pfa-table table-responsive align-right--column-2 align-right--column-3 align-right--column-4 thead-align--center"
  >
    <thead>
      <tr>
        <th coContent="DL.INV.C608"></th>
        <th coContent="DL.INV.C609"></th>
        <th coContent="DL.INV.C610"></th>
        <th coContent="DL.INV.C611"></th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>
          <div coContent="DL.INV.C608" class="td-label"></div>
          <div coContent="DL.INV.C612"></div>
        </td>
        <td>
          <div coContent="DL.INV.C609" class="td-label"></div>
          <div>8,5</div>
        </td>
        <td>
          <div coContent="DL.INV.C610" class="td-label"></div>
          <div>4,5</div>
        </td>
        <td>
          <div coContent="DL.INV.C611" class="td-label"></div>
          <div>3,6</div>
        </td>
      </tr>
      <tr>
        <td>
          <div coContent="DL.INV.C608" class="td-label"></div>
          <div coContent="DL.INV.C613"></div>
        </td>
        <td>
          <div coContent="DL.INV.C609" class="td-label"></div>
          <div>2,2</div>
        </td>
        <td>
          <div coContent="DL.INV.C610" class="td-label"></div>
          <div>1,2</div>
        </td>
        <td>
          <div coContent="DL.INV.C611" class="td-label"></div>
          <div>0,9</div>
        </td>
      </tr>
      <tr>
        <td>
          <div coContent="DL.INV.C608" class="td-label"></div>
          <div coContent="DL.INV.C614"></div>
        </td>
        <td>
          <div coContent="DL.INV.C609" class="td-label"></div>
          <div>6,3</div>
        </td>
        <td>
          <div coContent="DL.INV.C610" class="td-label"></div>
          <div>3,4</div>
        </td>
        <td>
          <div coContent="DL.INV.C611" class="td-label"></div>
          <div>2,7</div>
        </td>
      </tr>
      <tr>
        <td>
          <div coContent="DL.INV.C608" class="td-label"></div>
          <div coContent="DL.INV.C615"></div>
        </td>
        <td>
          <div coContent="DL.INV.C609" class="td-label"></div>
          <div>74,0 %</div>
        </td>
        <td>
          <div coContent="DL.INV.C610" class="td-label"></div>
          <div>74,0 %</div>
        </td>
        <td>
          <div coContent="DL.INV.C611" class="td-label"></div>
          <div>74,0 %</div>
        </td>
      </tr>
    </tbody>
  </table>

  <div class="spacing-unit--over">
    <p coContent="DL.INV.C616"></p>
    <p coContent="DL.INV.C617"></p>
    <p coContent="DL.INV.C618"></p>
    <p coContent="DL.INV.C619"></p>
    <p coContent="DL.INV.C620"></p>
  </div>
</mat-dialog-content>
