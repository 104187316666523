import { UnsavedChangesWarningResponse } from './unsaved-changes-warning.model';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject } from '@angular/core';
import { ContentUtilService } from '@pfa/core';

@Component({
  selector: 'co-unsaved-changes-warning',
  templateUrl: './unsaved-changes-warning.component.html',
  styleUrls: ['./unsaved-changes-warning.component.scss']
})
export class UnsavedChangesWarningComponent {
  header: string;
  body1: string;
  body2: string;
  body3: string;
  dismissBtn: boolean | undefined;

  constructor(
    private readonly dialogRef: MatDialogRef<UnsavedChangesWarningComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private readonly contentUtilService: ContentUtilService
  ) {
    if (data) {
      this.header = data.header ? data.header : undefined;
      this.body1 = data.body1 ? data.body1 : undefined;
      this.body2 = data.body2 ? data.body2 : undefined;
      this.body3 = data.body3 ? data.body3 : undefined;
      this.dismissBtn = data.dismissBtn;
    } else {
      // DEFAULTS
      this.header = contentUtilService.getContent('Global.ikkegemt.overskrift');
      this.body1 = contentUtilService.getContent('Global.ikkegemt.tekst1');
      this.body2 = contentUtilService.getContent('Global.ikkegemt.tekst2');
    }
  }

  accept() {
    const response: UnsavedChangesWarningResponse = { result: 'ACCEPT' };
    this.dialogRef.close(response);
  }

  cancel() {
    const response: UnsavedChangesWarningResponse = { result: 'CANCEL' };
    this.dialogRef.close(response);
  }

  dismiss() {
    this.dialogRef.close();
  }
}
