import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { MaeglerInfo } from '@pfa/gen';
import { InvestmentGuideInfo } from '@pfa/models';
import { Subscription } from 'rxjs';
import { InvestmentGuideQuestion } from '../investment-guide-configuration.service';
import { InvestmentGuideService } from '@mitpfa/shared';

@Component({
  selector: 'mitpfa-investment-guide-climate-step',
  templateUrl: './investment-guide-climate-step.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InvestmentGuideClimateStepComponent implements OnInit, OnDestroy {
  @Input() investmentGuide: InvestmentGuideInfo;
  @Input() broker: MaeglerInfo;
  @Output() climateComplete = new EventEmitter();

  public question = InvestmentGuideQuestion;

  private subs: Subscription = new Subscription();

  public activeQuestionIndex = 0;

  constructor(
    private readonly investmentGuideService: InvestmentGuideService
  ) {}

  public ngOnInit(): void {
    const sub = this.investmentGuideService
      .getCurrentQuestion$()
      .subscribe(currentQuestion => {
        switch (currentQuestion.value) {
          case InvestmentGuideQuestion.climateRelevance:
            this.activeQuestionIndex = 0;
            break;
          case InvestmentGuideQuestion.climateExtendedFocus:
            this.activeQuestionIndex = 1;
            break;
          case InvestmentGuideQuestion.climateWillingness:
            this.activeQuestionIndex = 2;
            break;
          case InvestmentGuideQuestion.climatePriority:
            this.activeQuestionIndex = 3;
            break;
        }
      });
    this.subs.add(sub);
  }

  public ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  public goBack() {
    switch (this.investmentGuideService.getQuestionReached()) {
      case InvestmentGuideQuestion.climateRelevance:
        this.investmentGuideService.updateCurrentQuestion({
          value: InvestmentGuideQuestion.lossability,
          update: false,
          back: true
        });
        break;
      case InvestmentGuideQuestion.climateExtendedFocus:
        this.investmentGuideService.updateCurrentQuestion({
          value: InvestmentGuideQuestion.climateRelevance,
          update: false,
          back: true
        });
        break;
      case InvestmentGuideQuestion.climateWillingness:
        this.investmentGuideService.updateCurrentQuestion({
          value: InvestmentGuideQuestion.climateExtendedFocus,
          update: false,
          back: true
        });
        break;
      case InvestmentGuideQuestion.climatePriority:
        this.investmentGuideService.updateCurrentQuestion({
          value: InvestmentGuideQuestion.climateWillingness,
          update: false,
          back: true
        });
        break;
    }
  }
}
