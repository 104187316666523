/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const PartialSchemeSchema = JoiObj.object({
        addHealthReqAcceptanceTag:
            
                    JoiObj.boolean()
                


.required()


        ,
        company:
            
                    JoiObj.boolean()
                


.required()


        ,
        healthInsuranceProduct:
            
                    JoiObj.number()
                


.required()


        ,
        healthRequirementCode:
            
                    JoiObj.number()
                


.required()


        ,
        minimumYearlyPremiumAmount:
            
                    JoiObj.number()
                


.required()


        ,
        partialSchemeCompanyName:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        partialSchemeEndDate:
            
                    JoiObj.object()
                


.required()


        ,
        partialSchemeID:
            
                    JoiObj.number()
                


.required()


        ,
        partialSchemeLabelText:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        partialSchemeStartDate:
            
                    JoiObj.object()
                


.required()


        ,
        partialSchemeType:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        privatePayment:
            
                    JoiObj.boolean()
                


.required()


        ,
        sieblingGroupName:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        variantCode:
            
                    JoiObj.number()
                


.required()


        ,
        reuseOfExistingPolicyAllowed:
            
                    JoiObj.boolean()
                


.required()


        
    })
.id('PartialSchemeSchema')

export interface PartialScheme { 
    addHealthReqAcceptanceTag: boolean;
    company: boolean;
    healthInsuranceProduct: number;
    healthRequirementCode: number;
    minimumYearlyPremiumAmount: number;
    partialSchemeCompanyName: string;
    partialSchemeEndDate: object;
    partialSchemeID: number;
    partialSchemeLabelText: string;
    partialSchemeStartDate: object;
    partialSchemeType: string;
    privatePayment: boolean;
    sieblingGroupName: string;
    variantCode: number;
    reuseOfExistingPolicyAllowed: boolean;
}

