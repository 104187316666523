/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const StatusSchema = JoiObj.string().valid(
        'OK'
        ,
        'FEJL'
        
    )
.id('StatusSchema')

export type Status = 'OK' | 'FEJL';

export const Status = {
    Ok: 'OK' as Status,
    Fejl: 'FEJL' as Status
};

