/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const MuligOverfoerselPoliceSchema = JoiObj.object({
        anbefalinger:
                JoiObj.array().items(
        
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

        )
        

.required()


        ,
        ewisopsparing:
            
                    JoiObj.number()
                


.required()


        ,
        opsparing:
            
                    JoiObj.number()
                


.required()


        ,
        overfoerselstillaeg:
            
                    JoiObj.number()
                


.required()


        ,
        policenummer:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        valg:
            
                    JoiObj.boolean()
                


.required()


        
    })
.id('MuligOverfoerselPoliceSchema')

export interface MuligOverfoerselPolice { 
    anbefalinger: Array<string>;
    ewisopsparing: number;
    opsparing: number;
    overfoerselstillaeg: number;
    policenummer: string;
    valg: boolean;
}

