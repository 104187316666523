<co-icon-info
  *ngIf="!type; else warn"
  #tooltip="matTooltip"
  matTooltip="{{ text | content: (subst ? subst : '') }}"
  (click)="tooltip.toggle()"
></co-icon-info>

<ng-template #warn>
  <co-icon-doubt
    class="warning"
    #tooltip="matTooltip"
    matTooltip="{{ text | content: (subst ? subst : '') }}"
    (click)="tooltip.toggle()"
  ></co-icon-doubt>
</ng-template>
