/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AutoAdjustmentType } from './autoAdjustmentType';
import { AutoAdjustmentTypeSchema } from './autoAdjustmentType';
import { CustomerSelectionType } from './customerSelectionType';
import { CustomerSelectionTypeSchema } from './customerSelectionType';
import * as JoiObj from "joi";


    export const AutoAdjustmentSchema = JoiObj.object({
        customerSelection:
            
        JoiObj.link('#CustomerSelectionTypeSchema')


.required()


        ,
        noHealthRequiredUntil:
            
            JoiObj.date()

.allow(null)



        ,
        type:
            
        JoiObj.link('#AutoAdjustmentTypeSchema')


.required()


        
    })
                .shared(CustomerSelectionTypeSchema)
                .shared(AutoAdjustmentTypeSchema)
.id('AutoAdjustmentSchema')

export interface AutoAdjustment { 
    customerSelection: CustomerSelectionType;
    noHealthRequiredUntil?: Date;
    type: AutoAdjustmentType;
}
export namespace AutoAdjustment {
}


