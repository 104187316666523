import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Residence, Residences } from '@pfa/gen';

@Injectable()
export class InvestmentGuideRealEstateService {
  shouldPickDetails(residences: Residences): boolean {
    const result = residences.realEstateAnswer;
    return result;
  }

  initializeRealEstates(residences: Residences) {
    if (!residences.realEstates || residences.realEstates.length === 0) {
      residences.realEstates = [];
      residences.realEstates.push({} as Residence);
    }
  }

  setResidences(
    residences: Residences,
    hasRealEstate: boolean,
    formGroup: UntypedFormGroup
  ): Residences {
    if (!residences) {
      residences = { realEstates: [] } as Residences;
    }

    residences.realEstateAnswer = hasRealEstate;
    if (!hasRealEstate) {
      residences.realEstates = [];
      return residences;
    }

    if (residences.realEstates?.length < 2) {
      const realEstate = {
        realEstateName: formGroup.value['realEstateName'],
        currentMarketValue: formGroup.value['currentMarketValue'],
        estateDebt: formGroup.value['estateDebt'],
        percentageOwned: formGroup.value['percentageOwned']
      } as Residence;
      residences.realEstates = [realEstate];
    }

    return residences;
  }

  clearResidences(formGroup: UntypedFormGroup) {
    formGroup.value['realEstateName'] = '';
    formGroup.value['currentMarketValue'] = 0;
    formGroup.value['estateDebt'] = 0;
    formGroup.value['percentageOwned'] = 0;
  }
}
