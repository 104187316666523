<div mat-dialog-title>
  <h3 coContent="DL.AZ01.C02"></h3>

  <a mat-dialog-close class="popup-close-button"></a>
</div>

<mat-dialog-content>
  <ng-container *ngIf="article">
    <p *ngFor="let text of article.cTexts" [coContent]="text"></p>
  </ng-container>
</mat-dialog-content>
