<form [formGroup]="wealthPickerForm">
  <ng-container formGroupName="wealthFormGroup">
    <div class="spacing-unit--over">
      <co-slider-form
        [label]="equitiesLabel"
        [sliderOptions]="sliderOptions"
        ngDefaultControl
        formControlName="equities"
        type="currency"
        data-test-id="wealth-picker-equities"
      ></co-slider-form>
    </div>

    <div class="spacing-unit--over">
      <co-slider-form
        [label]="bondsLabel"
        [sliderOptions]="sliderOptions"
        ngDefaultControl
        formControlName="bonds"
        type="currency"
        data-test-id="wealth-picker-bonds"
      ></co-slider-form>
    </div>

    <div class="spacing-unit--over">
      <co-slider-form
        [label]="cashLabel"
        [sliderOptions]="sliderOptions"
        ngDefaultControl
        formControlName="cash"
        type="currency"
        data-test-id="wealth-picker-cash"
      ></co-slider-form>
    </div>

    <p class="text--info align--right sum-information">
      {{ totalLabel | content }}
      {{ totalSavings$ | async | currencyFormat }}
    </p>
  </ng-container>
</form>
