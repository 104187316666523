import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  CustomerOptions,
  PensionsKundeGenerelleData,
  CustomerProfile
} from '@pfa/gen';
import { MiscTrackingService } from '@pfa/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

@Component({
  selector: 'mitpfa-dashboard-advantages',
  templateUrl: './dashboard-advantages.component.html',
  styleUrls: ['./dashboard-advantages.component.scss']
})
export class DashboardAdvantagesComponent implements OnInit, OnDestroy {
  @Input() pensionCustomer: PensionsKundeGenerelleData;
  @Input() customerOptions: CustomerOptions;
  @Input() customerProfile: CustomerProfile;

  private readonly unsubscribe = new Subject<void>();

  public showAdvantageLBInsurance: boolean;

  constructor(
    private trackingService: MiscTrackingService,
    private router: Router
  ) {}

  ngOnInit(): void {
    if (this.customerOptions.lbInsuranceAllowed) {
      this.showAdvantageLBInsurance = true;
    }
  }

  goToTrackingUrl(navigationAction, navigationUrl) {
    this.trackingService.trackNavigationCard(
      'Advantages section',
      navigationAction,
      navigationUrl
    );
    this.router.navigate([navigationUrl]);
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
