import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'mitpfa-show-agreement',
  templateUrl: './release-receipt-dialog.component.html'
})
export class ReleaseReceiptDialogComponent {
  constructor(public dialogRef: MatDialogRef<ReleaseReceiptDialogComponent>) {}
}
