import { Injectable } from '@angular/core';
import { InsuranceApiService } from '@pfa/api';
import { DefaultChoice, InputConfigs } from '@mitpfa/shared';
import {
  InForceSelectionForsikring,
  InsuranceRecommendation,
  PensionsKundeGenerelleData
} from '@pfa/gen';
@Injectable({
  providedIn: 'root'
})
export class InsuranceAdjustmentDefaultChoiceService {
  constructor(private readonly insuranceApiService: InsuranceApiService) {}

  createDefaultChoice(
    pensionCustomer: PensionsKundeGenerelleData
  ): DefaultChoice {
    const defaultChoice: DefaultChoice = {} as DefaultChoice;
    const inForceSelections = pensionCustomer.inForceSelections || [];
    defaultChoice.loggedLife = inForceSelections.some(
      ifc => ifc.inForceSelectionType === 'LifeInsurance'
    );
    defaultChoice.loggedLoc = inForceSelections.some(
      ifc => ifc.inForceSelectionType === 'LossOfOccupationalCapacity'
    );
    defaultChoice.loggedCi = inForceSelections.some(
      ifc => ifc.inForceSelectionType === 'CriticalIllness'
    );
    return defaultChoice;
  }

  updateDefaultChoice(
    insuranceRecommendation: InsuranceRecommendation,
    inputConfigs: InputConfigs,
    defaultChoice: DefaultChoice,
    delayLogProfileSelection: boolean
  ): void {
    defaultChoice.logNowLoc =
      inputConfigs.loc.regularPayout.amount !==
      inputConfigs.loc.regularPayout.amountOriginal;
    defaultChoice.logNowLife =
      inputConfigs.life.self.amount !== inputConfigs.life.self.amountOriginal;
    defaultChoice.logNowCi =
      inputConfigs.ci.self.amount !== inputConfigs.ci.self.amountOriginal;

    this.saveDefaultChoice(
      insuranceRecommendation,
      inputConfigs,
      defaultChoice,
      false,
      delayLogProfileSelection
    );
  }

  private saveDefaultChoice(
    insuranceRecommendation: InsuranceRecommendation,
    inputConfigs: InputConfigs,
    defaultChoice: DefaultChoice,
    passiveAcceptance: boolean,
    delayLogProfileSelection: boolean
  ): void {
    const selectionProfiles: InForceSelectionForsikring[] = [];
    if (!defaultChoice.loggedLoc && defaultChoice.logNowLoc) {
      defaultChoice.loggedLoc = true;
      selectionProfiles.push({
        inForceSelectionType: 'LossOfOccupationalCapacity',
        passiveAcceptance,
        selection: inputConfigs.loc.regularPayout.amount,
        defaultSelection: 80,
        recommendation: insuranceRecommendation.loc
      });
    }

    if (!defaultChoice.loggedLife && defaultChoice.logNowLife) {
      defaultChoice.loggedLife = true;
      selectionProfiles.push({
        inForceSelectionType: 'LifeInsurance',
        passiveAcceptance,
        selection: inputConfigs.life.self.amount,
        defaultSelection: inputConfigs.life.self.amountOriginal,
        recommendation: insuranceRecommendation.life
      });
    }

    if (!defaultChoice.loggedCi && defaultChoice.logNowCi) {
      defaultChoice.loggedCi = true;
      selectionProfiles.push({
        inForceSelectionType: 'CriticalIllness',
        passiveAcceptance,
        selection: inputConfigs.ci.self.amount,
        defaultSelection: inputConfigs.ci.self.amountOriginal,
        recommendation: insuranceRecommendation.ci
      });
    }

    if (selectionProfiles.length > 0) {
      this.insuranceApiService
        .logProfileSelection(selectionProfiles, delayLogProfileSelection)
        .subscribe(res => {
          defaultChoice.errorLogging = !res.success;
        });
    }
  }
}
