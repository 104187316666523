<!-- v4 -->
<svg
  id="Layer_1"
  data-name="Layer 1"
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 32 32"
>
  <g id="Canvas">
    <circle
      id="Large_circle"
      data-name="Large circle"
      cx="16"
      cy="16"
      r="16"
      fill="none"
    />
    <circle
      id="Small_circle"
      data-name="Small circle"
      cx="16"
      cy="16"
      r="12"
      fill="none"
    />
  </g>
  <g id="Icon">
    <path
      id="Box"
      d="M21.75,15.29v4.25a3.12,3.12,0,0,1-3.11,3.12H12.11A3.36,3.36,0,0,1,9,19.54V13a3.13,3.13,0,0,1,3.11-3.1h4.5"
      fill="none"
      stroke="#000"
      stroke-linecap="round"
      stroke-miterlimit="10"
      stroke-width="1.2"
    />
    <path
      id="eraser"
      d="M23.35,9l-.55-.55.42-.4c.13-.14.66.39.53.53Z"
      fill="none"
      stroke="#000"
      stroke-linecap="round"
      stroke-miterlimit="10"
      stroke-width="1.2"
    />
    <path
      id="pen"
      d="M21.45,10.9l-6,6a2.75,2.75,0,0,1-1,.41,3,3,0,0,1,.42-1l6-6Z"
      fill="none"
      stroke="#000"
      stroke-linecap="round"
      stroke-miterlimit="10"
      stroke-width="1.2"
    />
  </g>
</svg>
