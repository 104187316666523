<div class="carousel-wrapper">
  <ul
    [class]="{ 'carousel-inner': true, 'center-items': centerItems }"
    (panright)="handleTouch($event)"
    (panleft)="handleTouch($event)"
    (panend)="handleTouch($event)"
    [ngStyle]="{ width: items.length * 100 + '%' }"
  >
    <li
      *ngFor="let item of items; let index = index; trackBy: trackByFn"
      class="carousel-item"
      [class.active]="index === currentSlide"
      [ngStyle]="{ width: slideWidth + 'px' }"
    >
      <ng-container [ngTemplateOutlet]="item.templateRef"></ng-container>
    </li>
  </ul>
</div>

<ul class="carousel-dots">
  <li
    *ngFor="let item of items; let index = index; trackBy: trackByFn"
    [class.active]="index === currentSlide"
    (click)="setActiveSlide(index)"
  >
    <div></div>
  </li>
</ul>
