/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ServiceInfo } from './serviceInfo';
import { ServiceInfoSchema } from './serviceInfo';
import { ResourceModel } from './resourceModel';
import { ResourceModelSchema } from './resourceModel';
import { FordeleKontakt } from './fordeleKontakt';
import { FordeleKontaktSchema } from './fordeleKontakt';
import * as JoiObj from "joi";


    export const FordeleSchema = JoiObj.object({
        serviceInfo:
            
        JoiObj.link('#ServiceInfoSchema')

.allow(null)



        ,
        error:
            
                    JoiObj.boolean()
                


.required()


        ,
        kontakter:
                JoiObj.array().items(
        
        JoiObj.link('#FordeleKontaktSchema')

        )
        

.required()


        ,
        noDataFound:
            
                    JoiObj.boolean()
                


.required()


        ,
        programId:
            
                    JoiObj.number()
                .integer()

.allow(null)



        
    })
                .shared(ServiceInfoSchema)
                .shared(FordeleKontaktSchema)
.id('FordeleSchema')

export interface Fordele extends ResourceModel { 
    error: boolean;
    kontakter: Array<FordeleKontakt>;
    noDataFound: boolean;
    programId?: number;
}

