/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const RaadgiverAnbefalingSchema = JoiObj.object({
        aktuelForsikringstal:
            
                    JoiObj.number()
                

.allow(null)



        ,
        pfaAnbefaling:
            
                    JoiObj.number()
                

.allow(null)



        ,
        raadgiverAnbefaling:
            
                    JoiObj.number()
                

.allow(null)



        ,
        raadgiverAnbefalingOprettet:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        raadgiverKommentar:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        validAdviserRecommendation:
            
                    JoiObj.boolean()
                

.allow(null)



        
    })
.id('RaadgiverAnbefalingSchema')

export interface RaadgiverAnbefaling { 
    aktuelForsikringstal?: number;
    pfaAnbefaling?: number;
    raadgiverAnbefaling?: number;
    raadgiverAnbefalingOprettet?: string;
    raadgiverKommentar?: string;
    validAdviserRecommendation?: boolean;
}

