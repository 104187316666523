/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ChecklistTypeItem } from './checklistTypeItem';
import { ChecklistTypeItemSchema } from './checklistTypeItem';
import { ChecklistStatusItem } from './checklistStatusItem';
import { ChecklistStatusItemSchema } from './checklistStatusItem';
import * as JoiObj from "joi";


    export const CheckListItemSchema = JoiObj.object({
        itemType:
            
        JoiObj.link('#ChecklistTypeItemSchema')


.required()


        ,
        statusDate:
            
            JoiObj.date()


.required()


        ,
        statusItem:
            
        JoiObj.link('#ChecklistStatusItemSchema')


.required()


        
    })
                .shared(ChecklistTypeItemSchema)
                .shared(ChecklistStatusItemSchema)
.id('CheckListItemSchema')

export interface CheckListItem { 
    itemType: ChecklistTypeItem;
    statusDate: Date;
    statusItem: ChecklistStatusItem;
}
export namespace CheckListItem {
}


