import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'mitpfa-dashboard-savings-header',
  templateUrl: './dashboard-savings-header.component.html',
  styleUrls: ['./dashboard-savings-header.component.scss']
})
export class DashboardSavingsHeaderComponent implements OnInit {
  @Input() content: string;
  @Input() contentSubst: string;
  @Input() hideArrow: boolean;
  initialized: boolean;

  ngOnInit(): void {
    this.initialized = true;
  }
}
