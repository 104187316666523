import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// not used yet

const routes: Routes = [];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AdvantagesRoutingModule {}
