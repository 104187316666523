<mitpfa-insurance-guide-recommendation-card
  [title]="recommendationDescription ? recommendationDescription.text : ''"
  [recommendationDescription]="recommendationDescription"
  [recommendationDirection]="recommendation"
  [showNoCoverageWarning]="isDisabled"
  [showFollowOurRecommendationButton]="showFollowOurRecommendationButton"
  accordionTrackingName="ISG recommendation – pfacriticalillness"
  [hideActions]="hideActions"
  [confirmChoiceData]="confirmChoice.ci"
  (confirmChoiceEmitter)="onConfirmChoice()"
>
  <co-primary-number
    recommendedValue
    class="size-sm"
    [amount]="
      overrideRecommendationKeepCurrent
        ? totalCiAmount
        : insuranceDetails.pfaAnbefalerKS.value
    "
  ></co-primary-number>
  <span
    recommendedDescription
    [coContent]="
      recommendationDescription ? recommendationDescription.description : ''
    "
  ></span>

  <co-primary-number
    currentValue
    class="size-sm"
    [amount]="totalCiAmount"
  ></co-primary-number>
  <span currentDescription coContent="DL.FG01.C1862"></span>

  <ng-container readMoreList>
    <ul class="list">
      <li>
        <span
          [coContent]="belowTopTaxLimit ? 'DL.FG01.C1496' : 'DL.FG01.C1495'"
          [coContentSubst]="salary | numberFormat"
        ></span>
      </li>
      <li
        *ngIf="insuranceDetails.pfaAnbefalerKS.recommendationAdjustedToMinimum"
        [coContent]="'DL.FG01.C1929'"
      ></li>
    </ul>
  </ng-container>
</mitpfa-insurance-guide-recommendation-card>
