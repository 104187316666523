/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const LoginMethodSchema = JoiObj.string().valid(
        'NemID'
        ,
        'Password'
        ,
        'SingleSignOn'
        ,
        'TwoFactor'
        ,
        'Biometric'
        ,
        'MitID'
        
    )
.id('LoginMethodSchema')

export type LoginMethod = 'NemID' | 'Password' | 'SingleSignOn' | 'TwoFactor' | 'Biometric' | 'MitID';

export const LoginMethod = {
    NemId: 'NemID' as LoginMethod,
    Password: 'Password' as LoginMethod,
    SingleSignOn: 'SingleSignOn' as LoginMethod,
    TwoFactor: 'TwoFactor' as LoginMethod,
    Biometric: 'Biometric' as LoginMethod,
    MitId: 'MitID' as LoginMethod
};

