<div mat-dialog-title><h3 [coContent]="'DL.TT01.C09'"></h3></div>

<mat-dialog-content>
  <p [coContent]="'DL.TT01.C10'">
    Du bliver snart logget ud grundet inaktivitet ....
  </p>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-raised-button (click)="extendTimeout()">
    <span [coContent]="'DL.TT01.C11'"></span>
  </button>
</mat-dialog-actions>
