/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ServiceInfo } from './serviceInfo';
import { ServiceInfoSchema } from './serviceInfo';
import { FondStatus } from './fondStatus';
import { FondStatusSchema } from './fondStatus';
import { KursAendring } from './kursAendring';
import { KursAendringSchema } from './kursAendring';
import { ResourceModel } from './resourceModel';
import { ResourceModelSchema } from './resourceModel';
import { FondType } from './fondType';
import { FondTypeSchema } from './fondType';
import * as JoiObj from "joi";


    export const FondSchema = JoiObj.object({
        serviceInfo:
            
        JoiObj.link('#ServiceInfoSchema')

.allow(null)



        ,
        aktieType:
            
                    JoiObj.boolean()
                


.required()


        ,
        andel:
            
                    JoiObj.number()
                


.required()


        ,
        andelMax:
            
                    JoiObj.number()
                


.required()


        ,
        andelMin:
            
                    JoiObj.number()
                


.required()


        ,
        antal:
            
                    JoiObj.number()
                


.required()


        ,
        blandet:
            
                    JoiObj.boolean()
                


.required()


        ,
        blandetKategoriFond:
            
                    JoiObj.boolean()
                


.required()


        ,
        eksternUdbyderFondsId:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        fondKurs:
            
                    JoiObj.number()
                


.required()


        ,
        id:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        intern:
            
                    JoiObj.boolean()
                


.required()


        ,
        kanKoebes:
            
                    JoiObj.boolean()
                


.required()


        ,
        koebKurs:
            
                    JoiObj.number()
                


.required()


        ,
        koebsAndel:
            
                    JoiObj.number()
                


.required()


        ,
        kontantFond:
            
                    JoiObj.boolean()
                


.required()


        ,
        kursAendring:
            
        JoiObj.link('#KursAendringSchema')


.required()


        ,
        kursDato:
            
                    JoiObj.object()
                


.required()


        ,
        navn:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        obligationType:
            
                    JoiObj.boolean()
                


.required()


        ,
        oevrigeType:
            
                    JoiObj.boolean()
                


.required()


        ,
        opsparing:
            
                    JoiObj.number()
                


.required()


        ,
        origAndel:
            
                    JoiObj.number()
                


.required()


        ,
        profilFond:
            
                    JoiObj.boolean()
                


.required()


        ,
        risikoklasse:
            
                    JoiObj.number()
                


.required()


        ,
        salgKurs:
            
                    JoiObj.number()
                


.required()


        ,
        salgsAndel:
            
                    JoiObj.number()
                


.required()


        ,
        showWarningOnFond:
            
                    JoiObj.boolean()
                


.required()


        ,
        status:
            
        JoiObj.link('#FondStatusSchema')


.required()


        ,
        type:
            
        JoiObj.link('#FondTypeSchema')


.required()


        
    })
                .shared(ServiceInfoSchema)
                .shared(KursAendringSchema)
                .shared(FondStatusSchema)
                .shared(FondTypeSchema)
.id('FondSchema')

export interface Fond extends ResourceModel { 
    aktieType: boolean;
    andel: number;
    andelMax: number;
    andelMin: number;
    antal: number;
    blandet: boolean;
    blandetKategoriFond: boolean;
    eksternUdbyderFondsId: string;
    fondKurs: number;
    id: string;
    intern: boolean;
    kanKoebes: boolean;
    koebKurs: number;
    koebsAndel: number;
    kontantFond: boolean;
    kursAendring: KursAendring;
    kursDato: object;
    navn: string;
    obligationType: boolean;
    oevrigeType: boolean;
    opsparing: number;
    origAndel: number;
    profilFond: boolean;
    risikoklasse: number;
    salgKurs: number;
    salgsAndel: number;
    showWarningOnFond: boolean;
    status: FondStatus;
    type: FondType;
}
export namespace Fond {
}


