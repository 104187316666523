/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const FormOfPaymentSchema = JoiObj.string().valid(
        'LUMP_SUM_PAYMENT'
        ,
        'LUMP_SUM_PAYMENT_CHILD'
        ,
        'REGULAR_PAYMENT'
        ,
        'UNKNOWN'
        
    )
.id('FormOfPaymentSchema')

export type FormOfPayment = 'LUMP_SUM_PAYMENT' | 'LUMP_SUM_PAYMENT_CHILD' | 'REGULAR_PAYMENT' | 'UNKNOWN';

export const FormOfPayment = {
    LumpSumPayment: 'LUMP_SUM_PAYMENT' as FormOfPayment,
    LumpSumPaymentChild: 'LUMP_SUM_PAYMENT_CHILD' as FormOfPayment,
    RegularPayment: 'REGULAR_PAYMENT' as FormOfPayment,
    Unknown: 'UNKNOWN' as FormOfPayment
};

