import { FormField } from './dynamic-form.model';

export class DynamicFormUtilsComponent {
  public getKeyNameWithoutId(formField: FormField): string {
    const latestFieldKeyIdIndex = formField.Key.indexOf(formField.Id ?? '');
    return formField.Key.substring(0, latestFieldKeyIdIndex);
  }

  public getLatestFormField(formFields: FormField[]): FormField | undefined {
    if (formFields.length === 0) {
      return undefined;
    }

    let latestKeyId: number;
    let latestFormFieldByKeyId: FormField | undefined;
    formFields.forEach((formField: FormField) => {
      if (formField.Id) {
        const id = Number.parseInt(formField.Id, 10);
        if (!latestKeyId || id > latestKeyId) {
          latestKeyId = id;
          latestFormFieldByKeyId = formField;
        }
      } else if (!latestKeyId) {
        latestFormFieldByKeyId = formField;
      }
    });

    return latestFormFieldByKeyId;
  }

  public isSupportedType(formFieldType?: string): boolean {
    return (
      formFieldType === 'cpr' ||
      formFieldType === 'cvr' ||
      formFieldType === 'numeric' ||
      formFieldType === 'input' ||
      formFieldType === 'amount'
    );
  }
}
