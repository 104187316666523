import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { CardAnimatedStackItem } from '../card-animated-stack/card-animated-stack-item/card-animated-stack-item.interface';

@Component({
  selector: 'co-card-question',
  templateUrl: 'card-question.component.html',
  styleUrls: ['./card-question.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardQuestionComponent implements CardAnimatedStackItem {
  @Input() agreeText = 'DL.MD01.C29';
  @Input() declineText = 'DL.MD01.C30';
  @Output() agreeClick = new EventEmitter<void>();
  @Output() declineClick = new EventEmitter<void>();

  @ViewChild('content') contentContainer: ElementRef;

  isHideContent = false;
  minHeight = null;
  maxHeight = null;

  constructor(
    readonly elementRef: ElementRef,
    readonly cdRef: ChangeDetectorRef
  ) {}

  hideContent() {
    this.isHideContent = true;
    this.cdRef.detectChanges();
  }

  showContent() {
    this.isHideContent = false;
    this.cdRef.detectChanges();
  }

  getContentHeight(): number {
    return this.contentContainer.nativeElement.scrollHeight;
  }

  setMinHeight(value: number) {
    this.minHeight = value !== null && value >= 0 ? value + 'px' : null;
    this.cdRef.detectChanges();
  }

  setMaxHeight(maxHeight: number) {
    this.maxHeight =
      maxHeight !== null && maxHeight >= 0 ? maxHeight + 'px' : null;
    this.cdRef.detectChanges();
  }
}
