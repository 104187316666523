<form [formGroup]="debtForm">
  <div class="row spacing-unit--double-over">
    <div class="col-sm-5">
      <p class="text--manchet" coContent="DL.MD01.C398"></p>
      <p
        *ngIf="!isInInsuranceGuide"
        class="text--info"
        coContent="DL.MD01.C394"
      ></p>
    </div>

    <div class="col-sm">
      <mat-radio-group
        ngDefaultControl
        formControlName="isBankDebt"
        (change)="hasBankDebtChange($event)"
      >
        <mat-radio-button
          data-test-id="financial-situation-debt-selected"
          [value]="true"
        >
          {{ 'DL.MD01.C29' | content }}
        </mat-radio-button>
        <mat-radio-button
          data-test-id="financial-situation-debt-not-selected"
          [value]="false"
        >
          {{ 'DL.MD01.C30' | content }}
        </mat-radio-button>
      </mat-radio-group>
    </div>
  </div>

  <co-card
    *ngIf="
      debtForm.controls?.isBankDebt?.value ||
      debtForm.controls?.isBankDebtOnPension?.value
    "
  >
    <div class="row">
      <div class="col-xl-4 col-lg-8">
        <p class="text--manchet" coContent="DL.MD01.C399"></p>
        <p class="text--info" coContent="DL.MD01.C400"></p>
        <p class="text--info" coContent="DL.MD01.C405"></p>
      </div>

      <div class="col-xl-8">
        <div class="spacing-unit--over">
          <co-slider-form
            label="DL.MD01.C406"
            [sliderOptions]="sliderOptions"
            ngDefaultControl
            formControlName="totalBankDebt"
            type="currency"
            data-test-id="financial-situation-total-bank-debt"
          ></co-slider-form>
        </div>
      </div>
    </div>

    <div
      class="row spacing-unit--over"
      *ngIf="debtForm.controls.isBankDebtOnPension"
    >
      <div class="col-sm-6">
        <p class="text--manchet" coContent="DL.MD01.C401"></p>
        <p class="text--info" coContent="DL.MD01.C402"></p>
      </div>

      <div class="col-sm-6">
        <mat-radio-group ngDefaultControl formControlName="isBankDebtOnPension">
          <mat-radio-button [value]="true">
            {{ 'DL.MD01.C29' | content }}
          </mat-radio-button>
          <mat-radio-button [value]="false">
            {{ 'DL.MD01.C30' | content }}
          </mat-radio-button>
        </mat-radio-group>
      </div>
    </div>

    <ng-container
      *ngIf="
        debtForm.controls.bankDebtOnPension &&
        debtForm.controls?.isBankDebtOnPension.value
      "
    >
      <div class="row spacing-unit--over">
        <div class="col-sm-6">
          <p class="text--manchet" coContent="DL.MD01.C403"></p>
          <p class="text--info" coContent="DL.MD01.C404"></p>
          <p class="text--info" coContent="DL.MD01.C405"></p>
        </div>
      </div>

      <div class="spacing-unit--over">
        <co-slider-form
          label="DL.MD01.C407"
          [sliderOptions]="sliderOptions"
          ngDefaultControl
          formControlName="bankDebtOnPension"
          type="currency"
        ></co-slider-form>
      </div>
    </ng-container>
  </co-card>
</form>
