import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  ResolveFn,
  RouterStateSnapshot
} from '@angular/router';
import { AdvancedPayoutChoice, SystemadministrationStore } from '@pfa/gen';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { PayoutPlanService } from '../services/payout-plan.service';

export const digitisationBasketResolver: ResolveFn<AdvancedPayoutChoice> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const payoutPlanService = inject(PayoutPlanService);
  const systemadministrationStore = inject(SystemadministrationStore);

  return systemadministrationStore
    .systemadministrationHentkillswitchesadminGet()
    .pipe(
      switchMap(res => {
        if (res?.killswitchDigitisation?.open) {
          return payoutPlanService.getBasketChanges();
        } else {
          return of(null);
        }
      })
    );
};
