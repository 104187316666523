/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const HelbredssikringDaekningPrisSchema = JoiObj.object({
        prisForHSArbejde:
            
                    JoiObj.number()
                


.required()


        ,
        prisForHSFritid:
            
                    JoiObj.number()
                

.allow(null)



        ,
        samletPrisForHS:
            
                    JoiObj.number()
                


.required()


        ,
        samletPrisForHSBarn:
            
                    JoiObj.number()
                

.allow(null)



        ,
        samletPrisForHSVoksen:
            
                    JoiObj.number()
                

.allow(null)



        ,
        skatteopdelt:
            
                    JoiObj.boolean()
                


.required()


        
    })
.id('HelbredssikringDaekningPrisSchema')

export interface HelbredssikringDaekningPris { 
    prisForHSArbejde: number;
    prisForHSFritid?: number;
    samletPrisForHS: number;
    samletPrisForHSBarn?: number;
    samletPrisForHSVoksen?: number;
    skatteopdelt: boolean;
}

