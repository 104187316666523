/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const CategorySchema = JoiObj.object({
        dynamicFormId:
            
        JoiObj.object().pattern(/./,     
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)


)


.required()


        ,
        id:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        text:
            
        JoiObj.object().pattern(/./,     
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)


)


.required()


        ,
        topics:
                JoiObj.array().items(
        
        JoiObj.link('#CategorySchema')

        )
        

.required()


        
    })
.id('CategorySchema')

export interface Category { 
    dynamicFormId: { [key: string]: string; };
    id: string;
    text: { [key: string]: string; };
    topics: Array<Category>;
}

