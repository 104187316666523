/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DigitalAccessOptionType } from './digitalAccessOptionType';
import { DigitalAccessOptionTypeSchema } from './digitalAccessOptionType';
import { PhoneDetails } from './phoneDetails';
import { PhoneDetailsSchema } from './phoneDetails';
import * as JoiObj from "joi";


    export const UpdateDigitalAccessRequestSchema = JoiObj.object({
        optionType:
            
        JoiObj.link('#DigitalAccessOptionTypeSchema')


.required()


        ,
        phoneDetails:
            
        JoiObj.link('#PhoneDetailsSchema')

.allow(null)



        ,
        email:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        comment:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        
    })
                .shared(DigitalAccessOptionTypeSchema)
                .shared(PhoneDetailsSchema)
.id('UpdateDigitalAccessRequestSchema')

export interface UpdateDigitalAccessRequest { 
    optionType: DigitalAccessOptionType;
    phoneDetails?: PhoneDetails;
    email?: string;
    comment?: string;
}
export namespace UpdateDigitalAccessRequest {
}


