import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { OpsparingOversigt, SavingsStore } from '@pfa/gen';

@Injectable({
  providedIn: 'root'
})
export class SavingsResolver implements Resolve<OpsparingOversigt> {
  constructor(private readonly savingsStore: SavingsStore) {}

  resolve():
    | OpsparingOversigt
    | Observable<OpsparingOversigt>
    | Promise<OpsparingOversigt> {
    return this.savingsStore.opsparingGet();
  }
}
