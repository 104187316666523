/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { LanguageCode } from './languageCode';
import { LanguageCodeSchema } from './languageCode';
import { Spoergeskema } from './spoergeskema';
import { SpoergeskemaSchema } from './spoergeskema';
import * as JoiObj from "joi";


    export const SpoergeskemaMedInstansIdSchema = JoiObj.object({
        instansId:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        languageCode:
            
        JoiObj.link('#LanguageCodeSchema')


.required()


        ,
        spoergeskema:
            
        JoiObj.link('#SpoergeskemaSchema')


.required()


        
    })
                .shared(LanguageCodeSchema)
                .shared(SpoergeskemaSchema)
.id('SpoergeskemaMedInstansIdSchema')

export interface SpoergeskemaMedInstansId { 
    instansId: string;
    languageCode: LanguageCode;
    spoergeskema: Spoergeskema;
}
export namespace SpoergeskemaMedInstansId {
}


