import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import {RestCacheEntry} from "./RestCacheEntry";
import {GenericStore} from "./GenericStore";
import * as JoiObj from "joi";

import {
        HierarchicalDiagnosisModel,
        HierarchicalDiagnosisModelSchema,
        LanguageCode,
        LanguageCodeSchema,
        SignResponse,
        SignResponseSchema,
        SignedRequest,
        SignedRequestSchema,
        SignedResponse,
        SignedResponseSchema,
        SkadesAnmeldelseConfig,
        SkadesAnmeldelseConfigSchema,
        SpoergeskemaMedInstansId,
        SpoergeskemaMedInstansIdSchema,
        Survey,
        SurveySchema,
        TreatmentType,
        TreatmentTypeSchema,
        UploadedFile,
        UploadedFileSchema
} from '../model/models';

import { SkadesanmeldelseService } from './skadesanmeldelse.service';


@Injectable({
    providedIn: 'root'
})
export class SkadesanmeldelseStore extends GenericStore {

            private skadesanmeldelsekstidligereDiagnoserIndexCache: Map<string, RestCacheEntry<Array<HierarchicalDiagnosisModel>>> = new Map<string, RestCacheEntry<Array<HierarchicalDiagnosisModel>>>();
            private skadesanmeldelseconfigIndexCache: Map<string, RestCacheEntry<SkadesAnmeldelseConfig>> = new Map<string, RestCacheEntry<SkadesAnmeldelseConfig>>();
            private skadesanmeldelsehsinstanceUuiddocumentsShowCache: Map<string, RestCacheEntry<Array<UploadedFile>>> = new Map<string, RestCacheEntry<Array<UploadedFile>>>();
            private skadesanmeldelsehsuuidShowCache: Map<string, RestCacheEntry<SpoergeskemaMedInstansId>> = new Map<string, RestCacheEntry<SpoergeskemaMedInstansId>>();
            private skadesanmeldelsehsIndexCache: Map<string, RestCacheEntry<Array<Survey>>> = new Map<string, RestCacheEntry<Array<Survey>>>();
            private skadesanmeldelseksdiagnoserIndexCache: Map<string, RestCacheEntry<Array<HierarchicalDiagnosisModel>>> = new Map<string, RestCacheEntry<Array<HierarchicalDiagnosisModel>>>();
            private skadesanmeldelsetaediagnoserIndexCache: Map<string, RestCacheEntry<Array<HierarchicalDiagnosisModel>>> = new Map<string, RestCacheEntry<Array<HierarchicalDiagnosisModel>>>();
            private skadesanmeldelsetaejobtyperIndexCache: Map<string, RestCacheEntry<Array<HierarchicalDiagnosisModel>>> = new Map<string, RestCacheEntry<Array<HierarchicalDiagnosisModel>>>();
            private skadesanmeldelsehsdiagnoserIndexCache: Map<string, RestCacheEntry<Array<HierarchicalDiagnosisModel>>> = new Map<string, RestCacheEntry<Array<HierarchicalDiagnosisModel>>>();
            private skadesanmeldelseksuuidShowCache: Map<string, RestCacheEntry<SpoergeskemaMedInstansId>> = new Map<string, RestCacheEntry<SpoergeskemaMedInstansId>>();
            private skadesanmeldelsetaeIndexCache: Map<string, RestCacheEntry<Array<Survey>>> = new Map<string, RestCacheEntry<Array<Survey>>>();
            private skadesanmeldelsehsbehandlingsformerIndexCache: Map<string, RestCacheEntry<Array<TreatmentType>>> = new Map<string, RestCacheEntry<Array<TreatmentType>>>();
            private skadesanmeldelseksIndexCache: Map<string, RestCacheEntry<Array<Survey>>> = new Map<string, RestCacheEntry<Array<Survey>>>();
            private skadesanmeldelsetaeuuidShowCache: Map<string, RestCacheEntry<SpoergeskemaMedInstansId>> = new Map<string, RestCacheEntry<SpoergeskemaMedInstansId>>();
            private skadesanmeldelseksinstanceUuiddocumentsShowCache: Map<string, RestCacheEntry<Array<UploadedFile>>> = new Map<string, RestCacheEntry<Array<UploadedFile>>>();
            private skadesanmeldelsetaeinstanceUuiddocumentsShowCache: Map<string, RestCacheEntry<Array<UploadedFile>>> = new Map<string, RestCacheEntry<Array<UploadedFile>>>();

    constructor(private skadesanmeldelseService: SkadesanmeldelseService) {
        super();
    }

    invalidateAll(): void {
                
                this.invalidateskadesanmeldelseconfigIndex();
                
                this.invalidateskadesanmeldelsehsbehandlingsformerIndex();
                
                this.invalidateskadesanmeldelsehsdiagnoserIndex();
                
                this.invalidateskadesanmeldelsehsIndex();
                this.invalidateskadesanmeldelsehsinstanceUuiddocumentsShow();
                
                
                
                
                
                
                
                
                
                this.invalidateskadesanmeldelsehsuuidShow();
                
                
                
                
                
                
                
                
                
                
                this.invalidateskadesanmeldelseksdiagnoserIndex();
                
                this.invalidateskadesanmeldelseksIndex();
                this.invalidateskadesanmeldelseksinstanceUuiddocumentsShow();
                
                
                
                
                this.invalidateskadesanmeldelsekstidligereDiagnoserIndex();
                
                
                
                
                
                
                this.invalidateskadesanmeldelseksuuidShow();
                
                
                
                
                
                
                
                
                this.invalidateskadesanmeldelsetaediagnoserIndex();
                
                this.invalidateskadesanmeldelsetaeIndex();
                this.invalidateskadesanmeldelsetaeinstanceUuiddocumentsShow();
                
                
                this.invalidateskadesanmeldelsetaejobtyperIndex();
                
                
                
                
                
                
                
                
                this.invalidateskadesanmeldelsetaeuuidShow();
                
                
                
                
                
                
                
    }


                public skadesanmeldelseConfigGet() : Observable<SkadesAnmeldelseConfig> {
                    let hash = "0";
                    if (this.skadesanmeldelseconfigIndexCache.has(hash)) {
                        return this.skadesanmeldelseconfigIndexCache.get(hash)!.observable;
                    } else {
                        let skadesanmeldelseconfigIndexSubject: BehaviorSubject<0> = new BehaviorSubject(0);
                        let skadesanmeldelseconfigIndex = this.initiateStream(skadesanmeldelseconfigIndexSubject, this.skadesanmeldelseService.skadesanmeldelseConfigGet(), SkadesAnmeldelseConfigSchema);
                        this.skadesanmeldelseconfigIndexCache.set(hash, new RestCacheEntry<SkadesAnmeldelseConfig>(skadesanmeldelseconfigIndex, skadesanmeldelseconfigIndexSubject));
                        return skadesanmeldelseconfigIndex;
                    }
                }

                public invalidateskadesanmeldelseconfigIndex() {
                    this.skadesanmeldelseconfigIndexCache.forEach((cacheEntry) => {
                        cacheEntry.subject.next(0);
                    })
                }






                public skadesanmeldelseHsBehandlingsformerGet(sprog: LanguageCode) : Observable<Array<TreatmentType>> {
                    let hash = this.hashKey((sprog.toString() || "0"));
                    if (this.skadesanmeldelsehsbehandlingsformerIndexCache.has(hash)) {
                        return this.skadesanmeldelsehsbehandlingsformerIndexCache.get(hash)!.observable;
                    } else {
                        let skadesanmeldelsehsbehandlingsformerIndexSubject: BehaviorSubject<0> = new BehaviorSubject(0);
                        let skadesanmeldelsehsbehandlingsformerIndex = this.initiateStream(skadesanmeldelsehsbehandlingsformerIndexSubject, this.skadesanmeldelseService.skadesanmeldelseHsBehandlingsformerGet(sprog), TreatmentTypeSchema);
                        this.skadesanmeldelsehsbehandlingsformerIndexCache.set(hash, new RestCacheEntry<Array<TreatmentType>>(skadesanmeldelsehsbehandlingsformerIndex, skadesanmeldelsehsbehandlingsformerIndexSubject));
                        return skadesanmeldelsehsbehandlingsformerIndex;
                    }
                }

                public invalidateskadesanmeldelsehsbehandlingsformerIndex() {
                    this.skadesanmeldelsehsbehandlingsformerIndexCache.forEach((cacheEntry) => {
                        cacheEntry.subject.next(0);
                    })
                }






                public skadesanmeldelseHsDiagnoserGet(sprog: LanguageCode) : Observable<Array<HierarchicalDiagnosisModel>> {
                    let hash = this.hashKey((sprog.toString() || "0"));
                    if (this.skadesanmeldelsehsdiagnoserIndexCache.has(hash)) {
                        return this.skadesanmeldelsehsdiagnoserIndexCache.get(hash)!.observable;
                    } else {
                        let skadesanmeldelsehsdiagnoserIndexSubject: BehaviorSubject<0> = new BehaviorSubject(0);
                        let skadesanmeldelsehsdiagnoserIndex = this.initiateStream(skadesanmeldelsehsdiagnoserIndexSubject, this.skadesanmeldelseService.skadesanmeldelseHsDiagnoserGet(sprog), HierarchicalDiagnosisModelSchema);
                        this.skadesanmeldelsehsdiagnoserIndexCache.set(hash, new RestCacheEntry<Array<HierarchicalDiagnosisModel>>(skadesanmeldelsehsdiagnoserIndex, skadesanmeldelsehsdiagnoserIndexSubject));
                        return skadesanmeldelsehsdiagnoserIndex;
                    }
                }

                public invalidateskadesanmeldelsehsdiagnoserIndex() {
                    this.skadesanmeldelsehsdiagnoserIndexCache.forEach((cacheEntry) => {
                        cacheEntry.subject.next(0);
                    })
                }






                public skadesanmeldelseHsGet() : Observable<Array<Survey>> {
                    let hash = "0";
                    if (this.skadesanmeldelsehsIndexCache.has(hash)) {
                        return this.skadesanmeldelsehsIndexCache.get(hash)!.observable;
                    } else {
                        let skadesanmeldelsehsIndexSubject: BehaviorSubject<0> = new BehaviorSubject(0);
                        let skadesanmeldelsehsIndex = this.initiateStream(skadesanmeldelsehsIndexSubject, this.skadesanmeldelseService.skadesanmeldelseHsGet(), SurveySchema);
                        this.skadesanmeldelsehsIndexCache.set(hash, new RestCacheEntry<Array<Survey>>(skadesanmeldelsehsIndex, skadesanmeldelsehsIndexSubject));
                        return skadesanmeldelsehsIndex;
                    }
                }

                public invalidateskadesanmeldelsehsIndex() {
                    this.skadesanmeldelsehsIndexCache.forEach((cacheEntry) => {
                        cacheEntry.subject.next(0);
                    })
                }





                public skadesanmeldelseHsInstanceUuidDocumentsGet(instanceUuid: string) : Observable<Array<UploadedFile>> {
                    let hash = this.hashKey((instanceUuid.toString() || "0"));
                    if (this.skadesanmeldelsehsinstanceUuiddocumentsShowCache.has(hash)) {
                        return this.skadesanmeldelsehsinstanceUuiddocumentsShowCache.get(hash)!.observable;
                    } else {
                        let skadesanmeldelsehsinstanceUuiddocumentsShowSubject: BehaviorSubject<0> = new BehaviorSubject(0);
                        let skadesanmeldelsehsinstanceUuiddocumentsShow = this.initiateStream(skadesanmeldelsehsinstanceUuiddocumentsShowSubject, this.skadesanmeldelseService.skadesanmeldelseHsInstanceUuidDocumentsGet(instanceUuid), UploadedFileSchema);
                        this.skadesanmeldelsehsinstanceUuiddocumentsShowCache.set(hash, new RestCacheEntry<Array<UploadedFile>>(skadesanmeldelsehsinstanceUuiddocumentsShow, skadesanmeldelsehsinstanceUuiddocumentsShowSubject));
                        return skadesanmeldelsehsinstanceUuiddocumentsShow;
                    }
                }

                public invalidateskadesanmeldelsehsinstanceUuiddocumentsShowById(instanceUuid: string) {
                    let hash = this.hashKey((instanceUuid.toString() || "0"));
                    if (this.skadesanmeldelsehsinstanceUuiddocumentsShowCache.has(hash)) {
                        this.skadesanmeldelsehsinstanceUuiddocumentsShowCache.get(hash)!.subject.next(0);
                    }
                }

                public invalidateskadesanmeldelsehsinstanceUuiddocumentsShow() {
                    this.skadesanmeldelsehsinstanceUuiddocumentsShowCache.forEach((cacheEntry) => {
                        cacheEntry.subject.next(0);
                    })
                }









                    public skadesanmeldelseHsPost(sprog: LanguageCode) : Observable<Survey> {
                        return this.skadesanmeldelseService.skadesanmeldelseHsPost(sprog).pipe(
                            map(value => {
                                
                                this.invalidateskadesanmeldelsehsIndex();
                                        this.throwOrHandleValidationError(SurveySchema, value);
                                return value;
                            })
                        );
                    }







                    public skadesanmeldelseHsUuidConsentSign1Put(uuid: string,sprog: LanguageCode,signedRequest?: SignedRequest) : Observable<SignedResponse> {
                        return this.skadesanmeldelseService.skadesanmeldelseHsUuidConsentSign1Put(uuid,sprog,signedRequest).pipe(
                            map(value => {
                                
                                
                                        this.throwOrHandleValidationError(SignedResponseSchema, value);
                                return value;
                            })
                        );
                    }





                    public skadesanmeldelseHsUuidConsentSignPost(uuid: string,sprog: LanguageCode) : Observable<SignResponse> {
                        return this.skadesanmeldelseService.skadesanmeldelseHsUuidConsentSignPost(uuid,sprog).pipe(
                            map(value => {
                                
                                
                                        this.throwOrHandleValidationError(SignResponseSchema, value);
                                return value;
                            })
                        );
                    }








                    public skadesanmeldelseHsUuidDokumentDocidDelete(uuid: string,docid: string) : Observable<any> {
                        return this.skadesanmeldelseService.skadesanmeldelseHsUuidDokumentDocidDelete(uuid,docid).pipe(
                            map(value => {
                                
                                
                                return value;
                            })
                        );
                    }

                public skadesanmeldelseHsUuidGet(uuid: string) : Observable<SpoergeskemaMedInstansId> {
                    let hash = this.hashKey((uuid.toString() || "0"));
                    if (this.skadesanmeldelsehsuuidShowCache.has(hash)) {
                        return this.skadesanmeldelsehsuuidShowCache.get(hash)!.observable;
                    } else {
                        let skadesanmeldelsehsuuidShowSubject: BehaviorSubject<0> = new BehaviorSubject(0);
                        let skadesanmeldelsehsuuidShow = this.initiateStream(skadesanmeldelsehsuuidShowSubject, this.skadesanmeldelseService.skadesanmeldelseHsUuidGet(uuid), SpoergeskemaMedInstansIdSchema);
                        this.skadesanmeldelsehsuuidShowCache.set(hash, new RestCacheEntry<SpoergeskemaMedInstansId>(skadesanmeldelsehsuuidShow, skadesanmeldelsehsuuidShowSubject));
                        return skadesanmeldelsehsuuidShow;
                    }
                }

                public invalidateskadesanmeldelsehsuuidShowById(uuid: string) {
                    let hash = this.hashKey((uuid.toString() || "0"));
                    if (this.skadesanmeldelsehsuuidShowCache.has(hash)) {
                        this.skadesanmeldelsehsuuidShowCache.get(hash)!.subject.next(0);
                    }
                }

                public invalidateskadesanmeldelsehsuuidShow() {
                    this.skadesanmeldelsehsuuidShowCache.forEach((cacheEntry) => {
                        cacheEntry.subject.next(0);
                    })
                }









                    public skadesanmeldelseHsUuidPost(uuid: string,sprog: LanguageCode,body?: string) : Observable<any> {
                        return this.skadesanmeldelseService.skadesanmeldelseHsUuidPost(uuid,sprog,body).pipe(
                            map(value => {
                                this.invalidateskadesanmeldelsehsuuidShow();
                                
                                return value;
                            })
                        );
                    }







                    public skadesanmeldelseHsUuidPut(uuid: string,body?: string) : Observable<any> {
                        return this.skadesanmeldelseService.skadesanmeldelseHsUuidPut(uuid,body).pipe(
                            map(value => {
                                this.invalidateskadesanmeldelsehsuuidShow();
                                
                                return value;
                            })
                        );
                    }






                    public skadesanmeldelseHsUuidSign1Put(uuid: string,sprog: LanguageCode,signedRequest?: SignedRequest) : Observable<SignedResponse> {
                        return this.skadesanmeldelseService.skadesanmeldelseHsUuidSign1Put(uuid,sprog,signedRequest).pipe(
                            map(value => {
                                
                                
                                        this.throwOrHandleValidationError(SignedResponseSchema, value);
                                return value;
                            })
                        );
                    }





                    public skadesanmeldelseHsUuidSignPost(uuid: string,sprog: LanguageCode) : Observable<SignResponse> {
                        return this.skadesanmeldelseService.skadesanmeldelseHsUuidSignPost(uuid,sprog).pipe(
                            map(value => {
                                
                                
                                        this.throwOrHandleValidationError(SignResponseSchema, value);
                                return value;
                            })
                        );
                    }




                public skadesanmeldelseKsDiagnoserGet(sprog: LanguageCode) : Observable<Array<HierarchicalDiagnosisModel>> {
                    let hash = this.hashKey((sprog.toString() || "0"));
                    if (this.skadesanmeldelseksdiagnoserIndexCache.has(hash)) {
                        return this.skadesanmeldelseksdiagnoserIndexCache.get(hash)!.observable;
                    } else {
                        let skadesanmeldelseksdiagnoserIndexSubject: BehaviorSubject<0> = new BehaviorSubject(0);
                        let skadesanmeldelseksdiagnoserIndex = this.initiateStream(skadesanmeldelseksdiagnoserIndexSubject, this.skadesanmeldelseService.skadesanmeldelseKsDiagnoserGet(sprog), HierarchicalDiagnosisModelSchema);
                        this.skadesanmeldelseksdiagnoserIndexCache.set(hash, new RestCacheEntry<Array<HierarchicalDiagnosisModel>>(skadesanmeldelseksdiagnoserIndex, skadesanmeldelseksdiagnoserIndexSubject));
                        return skadesanmeldelseksdiagnoserIndex;
                    }
                }

                public invalidateskadesanmeldelseksdiagnoserIndex() {
                    this.skadesanmeldelseksdiagnoserIndexCache.forEach((cacheEntry) => {
                        cacheEntry.subject.next(0);
                    })
                }






                public skadesanmeldelseKsGet() : Observable<Array<Survey>> {
                    let hash = "0";
                    if (this.skadesanmeldelseksIndexCache.has(hash)) {
                        return this.skadesanmeldelseksIndexCache.get(hash)!.observable;
                    } else {
                        let skadesanmeldelseksIndexSubject: BehaviorSubject<0> = new BehaviorSubject(0);
                        let skadesanmeldelseksIndex = this.initiateStream(skadesanmeldelseksIndexSubject, this.skadesanmeldelseService.skadesanmeldelseKsGet(), SurveySchema);
                        this.skadesanmeldelseksIndexCache.set(hash, new RestCacheEntry<Array<Survey>>(skadesanmeldelseksIndex, skadesanmeldelseksIndexSubject));
                        return skadesanmeldelseksIndex;
                    }
                }

                public invalidateskadesanmeldelseksIndex() {
                    this.skadesanmeldelseksIndexCache.forEach((cacheEntry) => {
                        cacheEntry.subject.next(0);
                    })
                }





                public skadesanmeldelseKsInstanceUuidDocumentsGet(instanceUuid: string) : Observable<Array<UploadedFile>> {
                    let hash = this.hashKey((instanceUuid.toString() || "0"));
                    if (this.skadesanmeldelseksinstanceUuiddocumentsShowCache.has(hash)) {
                        return this.skadesanmeldelseksinstanceUuiddocumentsShowCache.get(hash)!.observable;
                    } else {
                        let skadesanmeldelseksinstanceUuiddocumentsShowSubject: BehaviorSubject<0> = new BehaviorSubject(0);
                        let skadesanmeldelseksinstanceUuiddocumentsShow = this.initiateStream(skadesanmeldelseksinstanceUuiddocumentsShowSubject, this.skadesanmeldelseService.skadesanmeldelseKsInstanceUuidDocumentsGet(instanceUuid), UploadedFileSchema);
                        this.skadesanmeldelseksinstanceUuiddocumentsShowCache.set(hash, new RestCacheEntry<Array<UploadedFile>>(skadesanmeldelseksinstanceUuiddocumentsShow, skadesanmeldelseksinstanceUuiddocumentsShowSubject));
                        return skadesanmeldelseksinstanceUuiddocumentsShow;
                    }
                }

                public invalidateskadesanmeldelseksinstanceUuiddocumentsShowById(instanceUuid: string) {
                    let hash = this.hashKey((instanceUuid.toString() || "0"));
                    if (this.skadesanmeldelseksinstanceUuiddocumentsShowCache.has(hash)) {
                        this.skadesanmeldelseksinstanceUuiddocumentsShowCache.get(hash)!.subject.next(0);
                    }
                }

                public invalidateskadesanmeldelseksinstanceUuiddocumentsShow() {
                    this.skadesanmeldelseksinstanceUuiddocumentsShowCache.forEach((cacheEntry) => {
                        cacheEntry.subject.next(0);
                    })
                }









                    public skadesanmeldelseKsPost(sprog: LanguageCode) : Observable<Survey> {
                        return this.skadesanmeldelseService.skadesanmeldelseKsPost(sprog).pipe(
                            map(value => {
                                
                                this.invalidateskadesanmeldelseksIndex();
                                        this.throwOrHandleValidationError(SurveySchema, value);
                                return value;
                            })
                        );
                    }




                public skadesanmeldelseKsTidligereDiagnoserGet(sprog: LanguageCode) : Observable<Array<HierarchicalDiagnosisModel>> {
                    let hash = this.hashKey((sprog.toString() || "0"));
                    if (this.skadesanmeldelsekstidligereDiagnoserIndexCache.has(hash)) {
                        return this.skadesanmeldelsekstidligereDiagnoserIndexCache.get(hash)!.observable;
                    } else {
                        let skadesanmeldelsekstidligereDiagnoserIndexSubject: BehaviorSubject<0> = new BehaviorSubject(0);
                        let skadesanmeldelsekstidligereDiagnoserIndex = this.initiateStream(skadesanmeldelsekstidligereDiagnoserIndexSubject, this.skadesanmeldelseService.skadesanmeldelseKsTidligereDiagnoserGet(sprog), HierarchicalDiagnosisModelSchema);
                        this.skadesanmeldelsekstidligereDiagnoserIndexCache.set(hash, new RestCacheEntry<Array<HierarchicalDiagnosisModel>>(skadesanmeldelsekstidligereDiagnoserIndex, skadesanmeldelsekstidligereDiagnoserIndexSubject));
                        return skadesanmeldelsekstidligereDiagnoserIndex;
                    }
                }

                public invalidateskadesanmeldelsekstidligereDiagnoserIndex() {
                    this.skadesanmeldelsekstidligereDiagnoserIndexCache.forEach((cacheEntry) => {
                        cacheEntry.subject.next(0);
                    })
                }









                    public skadesanmeldelseKsUuidConsentSign1Put(uuid: string,sprog: LanguageCode,signedRequest?: SignedRequest) : Observable<SignedResponse> {
                        return this.skadesanmeldelseService.skadesanmeldelseKsUuidConsentSign1Put(uuid,sprog,signedRequest).pipe(
                            map(value => {
                                
                                
                                        this.throwOrHandleValidationError(SignedResponseSchema, value);
                                return value;
                            })
                        );
                    }





                    public skadesanmeldelseKsUuidConsentSignPost(uuid: string,sprog: LanguageCode) : Observable<SignResponse> {
                        return this.skadesanmeldelseService.skadesanmeldelseKsUuidConsentSignPost(uuid,sprog).pipe(
                            map(value => {
                                
                                
                                        this.throwOrHandleValidationError(SignResponseSchema, value);
                                return value;
                            })
                        );
                    }








                    public skadesanmeldelseKsUuidDokumentDocidDelete(uuid: string,docid: string) : Observable<any> {
                        return this.skadesanmeldelseService.skadesanmeldelseKsUuidDokumentDocidDelete(uuid,docid).pipe(
                            map(value => {
                                
                                
                                return value;
                            })
                        );
                    }

                public skadesanmeldelseKsUuidGet(uuid: string) : Observable<SpoergeskemaMedInstansId> {
                    let hash = this.hashKey((uuid.toString() || "0"));
                    if (this.skadesanmeldelseksuuidShowCache.has(hash)) {
                        return this.skadesanmeldelseksuuidShowCache.get(hash)!.observable;
                    } else {
                        let skadesanmeldelseksuuidShowSubject: BehaviorSubject<0> = new BehaviorSubject(0);
                        let skadesanmeldelseksuuidShow = this.initiateStream(skadesanmeldelseksuuidShowSubject, this.skadesanmeldelseService.skadesanmeldelseKsUuidGet(uuid), SpoergeskemaMedInstansIdSchema);
                        this.skadesanmeldelseksuuidShowCache.set(hash, new RestCacheEntry<SpoergeskemaMedInstansId>(skadesanmeldelseksuuidShow, skadesanmeldelseksuuidShowSubject));
                        return skadesanmeldelseksuuidShow;
                    }
                }

                public invalidateskadesanmeldelseksuuidShowById(uuid: string) {
                    let hash = this.hashKey((uuid.toString() || "0"));
                    if (this.skadesanmeldelseksuuidShowCache.has(hash)) {
                        this.skadesanmeldelseksuuidShowCache.get(hash)!.subject.next(0);
                    }
                }

                public invalidateskadesanmeldelseksuuidShow() {
                    this.skadesanmeldelseksuuidShowCache.forEach((cacheEntry) => {
                        cacheEntry.subject.next(0);
                    })
                }










                    public skadesanmeldelseKsUuidPut(uuid: string,body?: string) : Observable<any> {
                        return this.skadesanmeldelseService.skadesanmeldelseKsUuidPut(uuid,body).pipe(
                            map(value => {
                                this.invalidateskadesanmeldelseksuuidShow();
                                
                                return value;
                            })
                        );
                    }






                    public skadesanmeldelseKsUuidSign1Put(uuid: string,sprog: LanguageCode,signedRequest?: SignedRequest) : Observable<SignedResponse> {
                        return this.skadesanmeldelseService.skadesanmeldelseKsUuidSign1Put(uuid,sprog,signedRequest).pipe(
                            map(value => {
                                
                                
                                        this.throwOrHandleValidationError(SignedResponseSchema, value);
                                return value;
                            })
                        );
                    }





                    public skadesanmeldelseKsUuidSignPost(uuid: string,sprog: LanguageCode) : Observable<SignResponse> {
                        return this.skadesanmeldelseService.skadesanmeldelseKsUuidSignPost(uuid,sprog).pipe(
                            map(value => {
                                
                                
                                        this.throwOrHandleValidationError(SignResponseSchema, value);
                                return value;
                            })
                        );
                    }




                public skadesanmeldelseTaeDiagnoserGet(sprog: LanguageCode) : Observable<Array<HierarchicalDiagnosisModel>> {
                    let hash = this.hashKey((sprog.toString() || "0"));
                    if (this.skadesanmeldelsetaediagnoserIndexCache.has(hash)) {
                        return this.skadesanmeldelsetaediagnoserIndexCache.get(hash)!.observable;
                    } else {
                        let skadesanmeldelsetaediagnoserIndexSubject: BehaviorSubject<0> = new BehaviorSubject(0);
                        let skadesanmeldelsetaediagnoserIndex = this.initiateStream(skadesanmeldelsetaediagnoserIndexSubject, this.skadesanmeldelseService.skadesanmeldelseTaeDiagnoserGet(sprog), HierarchicalDiagnosisModelSchema);
                        this.skadesanmeldelsetaediagnoserIndexCache.set(hash, new RestCacheEntry<Array<HierarchicalDiagnosisModel>>(skadesanmeldelsetaediagnoserIndex, skadesanmeldelsetaediagnoserIndexSubject));
                        return skadesanmeldelsetaediagnoserIndex;
                    }
                }

                public invalidateskadesanmeldelsetaediagnoserIndex() {
                    this.skadesanmeldelsetaediagnoserIndexCache.forEach((cacheEntry) => {
                        cacheEntry.subject.next(0);
                    })
                }






                public skadesanmeldelseTaeGet() : Observable<Array<Survey>> {
                    let hash = "0";
                    if (this.skadesanmeldelsetaeIndexCache.has(hash)) {
                        return this.skadesanmeldelsetaeIndexCache.get(hash)!.observable;
                    } else {
                        let skadesanmeldelsetaeIndexSubject: BehaviorSubject<0> = new BehaviorSubject(0);
                        let skadesanmeldelsetaeIndex = this.initiateStream(skadesanmeldelsetaeIndexSubject, this.skadesanmeldelseService.skadesanmeldelseTaeGet(), SurveySchema);
                        this.skadesanmeldelsetaeIndexCache.set(hash, new RestCacheEntry<Array<Survey>>(skadesanmeldelsetaeIndex, skadesanmeldelsetaeIndexSubject));
                        return skadesanmeldelsetaeIndex;
                    }
                }

                public invalidateskadesanmeldelsetaeIndex() {
                    this.skadesanmeldelsetaeIndexCache.forEach((cacheEntry) => {
                        cacheEntry.subject.next(0);
                    })
                }





                public skadesanmeldelseTaeInstanceUuidDocumentsGet(instanceUuid: string) : Observable<Array<UploadedFile>> {
                    let hash = this.hashKey((instanceUuid.toString() || "0"));
                    if (this.skadesanmeldelsetaeinstanceUuiddocumentsShowCache.has(hash)) {
                        return this.skadesanmeldelsetaeinstanceUuiddocumentsShowCache.get(hash)!.observable;
                    } else {
                        let skadesanmeldelsetaeinstanceUuiddocumentsShowSubject: BehaviorSubject<0> = new BehaviorSubject(0);
                        let skadesanmeldelsetaeinstanceUuiddocumentsShow = this.initiateStream(skadesanmeldelsetaeinstanceUuiddocumentsShowSubject, this.skadesanmeldelseService.skadesanmeldelseTaeInstanceUuidDocumentsGet(instanceUuid), UploadedFileSchema);
                        this.skadesanmeldelsetaeinstanceUuiddocumentsShowCache.set(hash, new RestCacheEntry<Array<UploadedFile>>(skadesanmeldelsetaeinstanceUuiddocumentsShow, skadesanmeldelsetaeinstanceUuiddocumentsShowSubject));
                        return skadesanmeldelsetaeinstanceUuiddocumentsShow;
                    }
                }

                public invalidateskadesanmeldelsetaeinstanceUuiddocumentsShowById(instanceUuid: string) {
                    let hash = this.hashKey((instanceUuid.toString() || "0"));
                    if (this.skadesanmeldelsetaeinstanceUuiddocumentsShowCache.has(hash)) {
                        this.skadesanmeldelsetaeinstanceUuiddocumentsShowCache.get(hash)!.subject.next(0);
                    }
                }

                public invalidateskadesanmeldelsetaeinstanceUuiddocumentsShow() {
                    this.skadesanmeldelsetaeinstanceUuiddocumentsShowCache.forEach((cacheEntry) => {
                        cacheEntry.subject.next(0);
                    })
                }







                public skadesanmeldelseTaeJobtyperGet(sprog: LanguageCode) : Observable<Array<HierarchicalDiagnosisModel>> {
                    let hash = this.hashKey((sprog.toString() || "0"));
                    if (this.skadesanmeldelsetaejobtyperIndexCache.has(hash)) {
                        return this.skadesanmeldelsetaejobtyperIndexCache.get(hash)!.observable;
                    } else {
                        let skadesanmeldelsetaejobtyperIndexSubject: BehaviorSubject<0> = new BehaviorSubject(0);
                        let skadesanmeldelsetaejobtyperIndex = this.initiateStream(skadesanmeldelsetaejobtyperIndexSubject, this.skadesanmeldelseService.skadesanmeldelseTaeJobtyperGet(sprog), HierarchicalDiagnosisModelSchema);
                        this.skadesanmeldelsetaejobtyperIndexCache.set(hash, new RestCacheEntry<Array<HierarchicalDiagnosisModel>>(skadesanmeldelsetaejobtyperIndex, skadesanmeldelsetaejobtyperIndexSubject));
                        return skadesanmeldelsetaejobtyperIndex;
                    }
                }

                public invalidateskadesanmeldelsetaejobtyperIndex() {
                    this.skadesanmeldelsetaejobtyperIndexCache.forEach((cacheEntry) => {
                        cacheEntry.subject.next(0);
                    })
                }








                    public skadesanmeldelseTaePost(sprog: LanguageCode) : Observable<Survey> {
                        return this.skadesanmeldelseService.skadesanmeldelseTaePost(sprog).pipe(
                            map(value => {
                                
                                this.invalidateskadesanmeldelsetaeIndex();
                                        this.throwOrHandleValidationError(SurveySchema, value);
                                return value;
                            })
                        );
                    }







                    public skadesanmeldelseTaeUuidConsentSign1Put(uuid: string,sprog: LanguageCode,signedRequest?: SignedRequest) : Observable<SignedResponse> {
                        return this.skadesanmeldelseService.skadesanmeldelseTaeUuidConsentSign1Put(uuid,sprog,signedRequest).pipe(
                            map(value => {
                                
                                
                                        this.throwOrHandleValidationError(SignedResponseSchema, value);
                                return value;
                            })
                        );
                    }





                    public skadesanmeldelseTaeUuidConsentSignPost(uuid: string,sprog: LanguageCode) : Observable<SignResponse> {
                        return this.skadesanmeldelseService.skadesanmeldelseTaeUuidConsentSignPost(uuid,sprog).pipe(
                            map(value => {
                                
                                
                                        this.throwOrHandleValidationError(SignResponseSchema, value);
                                return value;
                            })
                        );
                    }








                    public skadesanmeldelseTaeUuidDokumentDocidDelete(uuid: string,docid: string) : Observable<any> {
                        return this.skadesanmeldelseService.skadesanmeldelseTaeUuidDokumentDocidDelete(uuid,docid).pipe(
                            map(value => {
                                
                                
                                return value;
                            })
                        );
                    }

                public skadesanmeldelseTaeUuidGet(uuid: string) : Observable<SpoergeskemaMedInstansId> {
                    let hash = this.hashKey((uuid.toString() || "0"));
                    if (this.skadesanmeldelsetaeuuidShowCache.has(hash)) {
                        return this.skadesanmeldelsetaeuuidShowCache.get(hash)!.observable;
                    } else {
                        let skadesanmeldelsetaeuuidShowSubject: BehaviorSubject<0> = new BehaviorSubject(0);
                        let skadesanmeldelsetaeuuidShow = this.initiateStream(skadesanmeldelsetaeuuidShowSubject, this.skadesanmeldelseService.skadesanmeldelseTaeUuidGet(uuid), SpoergeskemaMedInstansIdSchema);
                        this.skadesanmeldelsetaeuuidShowCache.set(hash, new RestCacheEntry<SpoergeskemaMedInstansId>(skadesanmeldelsetaeuuidShow, skadesanmeldelsetaeuuidShowSubject));
                        return skadesanmeldelsetaeuuidShow;
                    }
                }

                public invalidateskadesanmeldelsetaeuuidShowById(uuid: string) {
                    let hash = this.hashKey((uuid.toString() || "0"));
                    if (this.skadesanmeldelsetaeuuidShowCache.has(hash)) {
                        this.skadesanmeldelsetaeuuidShowCache.get(hash)!.subject.next(0);
                    }
                }

                public invalidateskadesanmeldelsetaeuuidShow() {
                    this.skadesanmeldelsetaeuuidShowCache.forEach((cacheEntry) => {
                        cacheEntry.subject.next(0);
                    })
                }










                    public skadesanmeldelseTaeUuidPut(uuid: string,body?: string) : Observable<any> {
                        return this.skadesanmeldelseService.skadesanmeldelseTaeUuidPut(uuid,body).pipe(
                            map(value => {
                                this.invalidateskadesanmeldelsetaeuuidShow();
                                
                                return value;
                            })
                        );
                    }






                    public skadesanmeldelseTaeUuidSign1Put(uuid: string,sprog: LanguageCode,signedRequest?: SignedRequest) : Observable<SignedResponse> {
                        return this.skadesanmeldelseService.skadesanmeldelseTaeUuidSign1Put(uuid,sprog,signedRequest).pipe(
                            map(value => {
                                
                                
                                        this.throwOrHandleValidationError(SignedResponseSchema, value);
                                return value;
                            })
                        );
                    }





                    public skadesanmeldelseTaeUuidSignPost(uuid: string,sprog: LanguageCode) : Observable<SignResponse> {
                        return this.skadesanmeldelseService.skadesanmeldelseTaeUuidSignPost(uuid,sprog).pipe(
                            map(value => {
                                
                                
                                        this.throwOrHandleValidationError(SignResponseSchema, value);
                                return value;
                            })
                        );
                    }




}
