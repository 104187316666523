import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'mitpfa-customer-survey-consent',
  templateUrl: './customer-survey-consent.component.html',
  styleUrls: ['./customer-survey-consent.component.scss']
})
export class CustomerSurveyConsentComponent {
  constructor(
    public readonly dialogRef: MatDialogRef<CustomerSurveyConsentComponent>
  ) {}

  accept() {
    this.dialogRef.close(true);
  }

  decline() {
    this.dialogRef.close(false);
  }

  dismiss() {
    this.dialogRef.close();
  }
}
