import { Component, Input } from '@angular/core';
import { DigitalSalgPrisListeRaekke } from '@pfa/gen';

@Component({
  selector: 'co-coverage-payout',
  templateUrl: './coverage-payout.component.html'
})
export class CoveragePayoutComponent {
  @Input() coverage: DigitalSalgPrisListeRaekke;
}
