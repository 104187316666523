import { DestroyRef, inject, Injectable } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { CustomerSatisfaction } from '@pfa/gen';
import { ContentUtilService } from '@pfa/core';
import { CustomerSurveyQuestionsFields } from './customer-survey-questions-fields';
import { tap } from 'rxjs/operators';
import { FoundAllYouWantedOptions } from '../customer-survey.enum';
import { ScrollToService } from '@pfaform';
import { DeviceService, PlatformName } from '@mitpfa/shared';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Injectable()
export class CustomerSurveyQuestionsFormService {
  form: UntypedFormGroup = new UntypedFormGroup({});

  private readonly contentUtilService: ContentUtilService =
    inject(ContentUtilService);
  private readonly deviceService: DeviceService = inject(DeviceService);
  private readonly scrollTo: ScrollToService = inject(ScrollToService);
  private readonly destroyRef: DestroyRef = inject(DestroyRef);

  initializeForm() {
    this.form = new UntypedFormGroup({
      navigation: new UntypedFormControl(undefined, [Validators.required]),
      understandable: new UntypedFormControl(undefined, [Validators.required]),
      design: new UntypedFormControl(undefined, [Validators.required]),
      smoothness: new UntypedFormControl(undefined, [Validators.required]),
      satisfactionLevel: new UntypedFormControl(undefined, [
        Validators.required
      ]),
      purposeForVisitFulfilled: new UntypedFormControl(undefined, [
        Validators.required
      ]),
      foundAllYouWanted: new UntypedFormControl(undefined, [
        Validators.required
      ]),
      missingInfo: new UntypedFormControl(undefined),
      suggestionForImprovement: new UntypedFormControl(undefined)
    });
    this.scrollOnChange();
  }

  initializeSelectedValues() {
    return [
      {
        value: 10,
        text: this.contentUtilService.getContent('DL.TH01.C41')
      },
      {
        value: 9,
        text: this.contentUtilService.getContent('DL.TH01.C42')
      },
      {
        value: 8,
        text: this.contentUtilService.getContent('DL.TH01.C43')
      },
      {
        value: 7,
        text: this.contentUtilService.getContent('DL.TH01.C44')
      },
      {
        value: 6,
        text: this.contentUtilService.getContent('DL.TH01.C45')
      },
      {
        value: 5,
        text: this.contentUtilService.getContent('DL.TH01.C46')
      },
      {
        value: 4,
        text: this.contentUtilService.getContent('DL.TH01.C47')
      },
      {
        value: 3,
        text: this.contentUtilService.getContent('DL.TH01.C48')
      },
      {
        value: 2,
        text: this.contentUtilService.getContent('DL.TH01.C49')
      },
      {
        value: 1,
        text: this.contentUtilService.getContent('DL.TH01.C50')
      }
    ];
  }

  applyFormValuesToModel(): CustomerSatisfaction {
    const f = CustomerSurveyQuestionsFields;
    return {
      satisfactionLevel: this.form.get(f.satisfactionLevel)?.value,
      navigation: this.form.get(f.navigation)?.value,
      understandable: this.form.get(f.understandable)?.value,
      design: this.form.get(f.design)?.value,
      smoothness: this.form.get(f.smoothness)?.value,
      purposeForVisitFulfilled: this.form.get(f.purposeForVisitFulfilled)
        ?.value,
      suggestionForImprovement: this.form.get(f.suggestionForImprovement)
        ?.value,
      foundAllYouWanted: this.form.get(f.foundAllYouWanted)?.value,
      missingInfo: this.form.get(f.missingInfo)?.value,
      browser: this.browserDetect(),
      device: this.deviceService.getDeviceType()
    };
  }

  private browserDetect(): string {
    let browserName = 'No browser detection';

    if (this.deviceService.isChrome()) {
      browserName = PlatformName.CHROME;
    } else if (this.deviceService.isFirefox()) {
      browserName = PlatformName.FIREFOX;
    } else if (this.deviceService.isSafari()) {
      browserName = PlatformName.SAFARI;
    } else if (this.deviceService.isOpera()) {
      browserName = PlatformName.OPERA;
    } else if (this.deviceService.isEdge()) {
      browserName = PlatformName.EDGE;
    }

    return browserName;
  }

  private userIsMissingInfo(
    foundAllYouWantedValue: CustomerSurveyQuestionsFields
  ): boolean {
    return [
      FoundAllYouWantedOptions.Partially,
      FoundAllYouWantedOptions.No
    ].some(option => foundAllYouWantedValue === option);
  }

  private scrollOnChange() {
    const formFields: { [key: string]: boolean } = {
      [CustomerSurveyQuestionsFields.navigation]: true,
      [CustomerSurveyQuestionsFields.understandable]: true,
      [CustomerSurveyQuestionsFields.design]: true,
      [CustomerSurveyQuestionsFields.smoothness]: true,
      [CustomerSurveyQuestionsFields.satisfactionLevel]: true,
      [CustomerSurveyQuestionsFields.purposeForVisitFulfilled]: true,
      [CustomerSurveyQuestionsFields.foundAllYouWanted]: true,
      [CustomerSurveyQuestionsFields.missingInfo]: false,
      [CustomerSurveyQuestionsFields.suggestionForImprovement]: false
    };

    const scrollToNextQuestion = (
      fieldKeys: string[],
      justChangedIndex: number
    ) => {
      // scroll to next
      let nextQuestionKey = fieldKeys[justChangedIndex + 1];
      // if missing info is not visible, scroll to suggestionForImprovement
      if (
        nextQuestionKey === CustomerSurveyQuestionsFields.missingInfo &&
        !this.userIsMissingInfo(
          this.form.get(CustomerSurveyQuestionsFields.foundAllYouWanted)?.value
        )
      ) {
        nextQuestionKey =
          CustomerSurveyQuestionsFields.suggestionForImprovement;
      }
      const formContainer = document.querySelector('.mat-mdc-dialog-content');
      this.scrollTo.scrollToSmooth(
        `#TH01-${nextQuestionKey}`,
        formContainer as HTMLElement,
        200
      );
    };

    this.form.valueChanges
      .pipe(
        tap(() => {
          const fieldKeys = Object.keys(formFields);
          const justChangedIndex = fieldKeys.findIndex(
            (key: string) => this.form.controls[key].dirty
          );
          if (
            justChangedIndex >= 0 &&
            formFields[fieldKeys[justChangedIndex]]
          ) {
            scrollToNextQuestion(fieldKeys, justChangedIndex);
          }

          this.form.markAsPristine();
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }
}
