import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { InternalTransferVisibilityResponse, OverfoerselStore } from '@pfa/gen';

@Injectable({
  providedIn: 'root'
})
export class CombineSavingsInternalVisibilityService {
  visible$: Observable<boolean>;
  private visible: Subject<boolean> = new Subject<boolean>();

  constructor(private readonly overfoerselStore: OverfoerselStore) {
    this.visible$ = this.visible.asObservable();
    this.checkVisibility();
  }

  checkVisibility() {
    this.overfoerselStore
      .overfoerselCheckVisibilityGet()
      .pipe(
        catchError(() => of({ visibility: { visible: false } })),
        map(
          (visibility: InternalTransferVisibilityResponse) =>
            visibility?.visible
        ),
        tap((visible: boolean) => {
          this.visible.next(visible);
        })
      )
      .subscribe();
  }
}
