import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynamicCounselingComponent } from './dynamic-counseling.component';
import { YourInvestmentsComponent } from './your-investments/your-investments.component';
import { TopicComponent } from './topic/topic.component';
import { IconModule } from '@pfa/icons';
import { MyCommonModule } from '@pfa/common';
import { CoreModule } from '@pfa/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { PfaFormModule } from '@pfaform';

@NgModule({
  imports: [
    CommonModule,
    MyCommonModule,
    IconModule,
    CoreModule,
    RouterModule,
    MatButtonModule,
    PfaFormModule
  ],
  declarations: [
    DynamicCounselingComponent,
    YourInvestmentsComponent,
    TopicComponent
  ],
  exports: [DynamicCounselingComponent],
  providers: []
})
export class DynamicCounselingModule {}
