<div class="wrapper">
  <co-loading [show]="!dataLoaded"></co-loading>

  <ng-container *ngIf="dataLoaded">
    <h1 coContent="DL.INV.C582" class="mobile-center"></h1>

    <div class="row">
      <div class="col-xl-6">
        <div class="main-footprint">
          <mitpfa-picto-baloon class="main-picto"></mitpfa-picto-baloon>

          <div class="main-footprint-numbers">
            <div class="footprint-number">
              <div
                class="footprint-label text--bold"
                coContent="DL.INV.C584"
              ></div>
              <co-primary-number
                class="size-lg"
                [amount]="footprint.totalCo2Emission"
                [unit]="'DL.INV.C585'"
                [showDecimals]="true"
              ></co-primary-number>
              <div
                class="footprint-label text--bold"
                coContent="DL.INV.C586"
              ></div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-6">
        <h4 coContent="DL.INV.C588" class="mobile-center"></h4>

        <div
          class="row align-items-center justify-content-center footprint-examples"
        >
          <div class="col-xl-6 col-md-4 col-sm-5 example-col">
            <div class="example">
              <div
                class="example-text"
                coContent="DL.INV.C589"
                [coContentSubst]="footprint.totalElectricity | numberFormat"
              ></div>
              <mitpfa-picto-family class="example-picto"></mitpfa-picto-family>
            </div>

            <div class="example">
              <div
                class="example-text"
                coContent="DL.INV.C591"
                [coContentSubst]="
                  footprint.totalCo2PerYear | number: '1.1-1' : 'da-DK'
                "
              ></div>
              <mitpfa-picto-person class="example-picto"></mitpfa-picto-person>
            </div>
          </div>

          <div class="col-xl-6 col-md-4 col-sm-5 example-col">
            <div class="example">
              <div
                class="example-text"
                coContent="DL.INV.C590"
                [coContentSubst]="footprint.totalKm | numberFormat"
              ></div>
              <mitpfa-picto-car class="example-picto"></mitpfa-picto-car>
            </div>
          </div>
        </div>

        <div class="align--center spacing-unit--double-over">
          <button
            mat-raised-button
            coContent="DL.INV.C592"
            (click)="openGuide()"
          ></button>
        </div>
      </div>
    </div>

    <div class="spacing-unit--over mobile-center">
      <p coContent="DL.INV.C583"></p>
      <button
        mat-raised-button
        class="button-primary--level3"
        (click)="openDisclaimer()"
      >
        <span coContent="DL.INV.C587"></span>
        <co-icon-system></co-icon-system>
      </button>
    </div>
  </ng-container>
</div>

<co-disable-overlay *ngIf="showError">
  <h2 coContent="DL.INV.C593"></h2>
  <div coContent="DL.INV.C594" class="text--manchet"></div>

  <div class="spacing-unit--double-over">
    <button
      mat-raised-button
      coContent="DL.INV.C595"
      routerLink="/mitoverblik"
    ></button>
  </div>
</co-disable-overlay>
