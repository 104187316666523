import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MyCommonModule } from '@pfa/common';
import { CoreModule } from '@pfa/core';
import { PfaFormModule } from '@pfaform';
import { IconModule } from '@pfa/icons';
import { MenuComponent } from './menu.component';
import { SubmenuComponent } from '../submenu/submenu.component';
import { PercentFilledComponent } from '../percent-filled/percent-filled.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DynamicCounselingModule } from '../../dynamic-counseling/dynamic-counseling.module';
import { PensionCheckModule } from '../../pension-check/pension-check.module';
import { RouterModule } from '@angular/router';
import { MenuItemComponent } from './menu-item/menu-item.component';
import { MenuItemMobileComponent } from './menu-item-mobile/menu-item-mobile.component';
import { SelectedMenuItemComponent } from './menu-item/selected-menu-item/selected-menu-item.component';
import { MessagingService } from '@pfa/feature/messaging';

@NgModule({
  imports: [
    CommonModule,
    MyCommonModule,
    CoreModule,
    PfaFormModule,
    IconModule,
    DynamicCounselingModule,
    PensionCheckModule,
    MatTooltipModule,
    RouterModule
  ],
  declarations: [
    MenuComponent,
    PercentFilledComponent,
    SubmenuComponent,
    MenuItemComponent,
    SelectedMenuItemComponent,
    MenuItemMobileComponent
  ],
  providers: [MessagingService],
  exports: [MenuComponent, PercentFilledComponent]
})
export class MenuModule {}
