import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Savings } from '@pfa/gen';

@Injectable()
export class InvestmentGuideSavingsService {
  shouldPickWealth(savings: Savings): boolean {
    const result =
      savings.shares !== 0 || savings.bonds !== 0 || savings.cash !== 0;

    return result;
  }

  setSavings(
    showQuestion: boolean,
    wealthFormGroup: UntypedFormGroup
  ): Savings {
    const savings = {} as Savings;
    savings.otherSavingsThanPension = !showQuestion;
    if (showQuestion) {
      savings.shares = 0;
      savings.bonds = 0;
      savings.cash = 0;
      return savings;
    }

    savings.shares = wealthFormGroup['equities'];
    savings.bonds = wealthFormGroup['bonds'];
    savings.cash = wealthFormGroup['cash'];
    return savings;
  }

  clearSavings(wealthFormGroup: UntypedFormGroup) {
    wealthFormGroup['equities'] = 0;
    wealthFormGroup['bonds'] = 0;
    wealthFormGroup['cash'] = 0;
  }
}
