<!-- v1 -->
<svg
  id="Layer_1"
  data-name="Layer 1"
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 32 32"
>
  <g id="Canvas">
    <circle
      id="Large_circle"
      data-name="Large circle"
      cx="16"
      cy="16"
      r="16"
      fill="none"
    />
    <circle
      id="Small_circle"
      data-name="Small circle"
      cx="16"
      cy="16"
      r="12"
      fill="none"
    />
  </g>
  <g id="Icon">
    <path
      d="M25,14.37a2.26,2.26,0,0,1-2.27,2.26"
      fill="none"
      stroke="#000"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.2"
    />
    <path
      d="M12,11.57A6.28,6.28,0,0,0,9.4,14.93H8v4.54H9.88a6.52,6.52,0,0,0,1.52,1.79v2.17A.56.56,0,0,0,12,24H13.1a.57.57,0,0,0,.57-.57V22.5a7.16,7.16,0,0,0,4.53,0v.93a.56.56,0,0,0,.57.57H19.9a.57.57,0,0,0,.57-.57V21.27a6,6,0,0,0,2.26-4.64,6,6,0,0,0-2.84-5.06"
      fill="none"
      stroke="#000"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.2"
    />
    <path
      d="M13.67,12.1a5.08,5.08,0,0,1,4.53,0"
      fill="none"
      stroke="#000"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.2"
    />
    <circle
      cx="15.93"
      cy="9.27"
      r="2.27"
      fill="none"
      stroke="#000"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.2"
    />
  </g>
</svg>
