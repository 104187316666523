/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DynamicAdvisingOfferGroupType } from './dynamicAdvisingOfferGroupType';
import { DynamicAdvisingOfferGroupTypeSchema } from './dynamicAdvisingOfferGroupType';
import { DynamicAdvisingOffer } from './dynamicAdvisingOffer';
import { DynamicAdvisingOfferSchema } from './dynamicAdvisingOffer';
import * as JoiObj from "joi";


    export const DynamicAdvisingOfferGroupSchema = JoiObj.object({
        groupType:
            
        JoiObj.link('#DynamicAdvisingOfferGroupTypeSchema')


.required()


        ,
        highestNbaValue:
            
                    JoiObj.number()
                

.allow(null)



        ,
        offers:
                JoiObj.array().items(
        
        JoiObj.link('#DynamicAdvisingOfferSchema')

        )
        

.required()


        
    })
                .shared(DynamicAdvisingOfferGroupTypeSchema)
                .shared(DynamicAdvisingOfferSchema)
.id('DynamicAdvisingOfferGroupSchema')

export interface DynamicAdvisingOfferGroup { 
    groupType: DynamicAdvisingOfferGroupType;
    highestNbaValue?: number;
    offers: Array<DynamicAdvisingOffer>;
}
export namespace DynamicAdvisingOfferGroup {
}


