/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RatesMarketRateValue } from './ratesMarketRateValue';
import { RatesMarketRateValueSchema } from './ratesMarketRateValue';
import { Sats } from './sats';
import { SatsSchema } from './sats';
import * as JoiObj from "joi";


    export const RatesMarketRateSchema = JoiObj.object({
        activeNow:
            
                    JoiObj.boolean()
                


.required()


        ,
        name:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        updatedAt:
            
            JoiObj.date()


.required()


        ,
        updatedBy:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        uuid:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        validFrom:
            
            JoiObj.date()

.allow(null)



        ,
        validFromFormatted:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        year:
            
                    JoiObj.number()
                


.required()


        ,
        profileA:
            
        JoiObj.link('#RatesMarketRateValueSchema')


.required()


        ,
        profileB:
            
        JoiObj.link('#RatesMarketRateValueSchema')


.required()


        ,
        profileC:
            
        JoiObj.link('#RatesMarketRateValueSchema')


.required()


        ,
        profileD:
            
        JoiObj.link('#RatesMarketRateValueSchema')


.required()


        
    })
                .shared(RatesMarketRateValueSchema)
                .shared(RatesMarketRateValueSchema)
                .shared(RatesMarketRateValueSchema)
                .shared(RatesMarketRateValueSchema)
.id('RatesMarketRateSchema')

export interface RatesMarketRate extends Sats { 
    profileA: RatesMarketRateValue;
    profileB: RatesMarketRateValue;
    profileC: RatesMarketRateValue;
    profileD: RatesMarketRateValue;
}

