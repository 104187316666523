<div mat-dialog-title>
  <h3 [coContent]="'DL.KM01.C06'"></h3>
  <co-icon-times (click)="closePopup()"></co-icon-times>
</div>
<mat-dialog-content>
  <form [formGroup]="contactForm">
    <div>
      <h4 [coContent]="'DL.KM01.C22'" *ngIf="currentMobileNumber"></h4>
      <p
        [coContent]="'DL.KM01.C10'"
        [coContentSubst]="currentMobileNumber"
        *ngIf="currentMobileNumber"
      ></p>

      <div
        class="text--bold"
        [coContent]="'DL.KM01.C19'"
        *ngIf="currentMobileNumber"
      ></div>
      <p
        [coContent]="'DL.KM01.C20'"
        *ngIf="currentMobileNumber && !pensionCustomer.specialMessagingRules"
      ></p>
      <p
        [coContent]="'DL.KM01.C25'"
        *ngIf="currentMobileNumber && pensionCustomer.specialMessagingRules"
      ></p>

      <co-accordion
        [title]="currentMobileNumber ? 'DL.KM01.C21' : ''"
        *ngIf="currentMobileNumber"
      >
        <ng-container *ngTemplateOutlet="gnu"></ng-container>
      </co-accordion>

      <div *ngIf="!currentMobileNumber">
        <ng-container *ngTemplateOutlet="gnu"></ng-container>
      </div>

      <ng-template #gnu>
        <p [coContent]="'DL.KM01.C11'" *ngIf="currentMobileNumber"></p>
        <p [coContent]="'DL.KM01.C14'" *ngIf="!currentMobileNumber"></p>

        <div class="input-section">
          <mat-form-field class="country-code" color="accent">
            <mat-label>{{ 'DL.KM01.C08' | content }}</mat-label>
            <input
              matInput
              type="text"
              [formControlName]="'countryCode'"
              name="countryCode"
              id="countryCode"
              [maxlength]="4"
              data-test-id="ST01-input-landekode"
            />
          </mat-form-field>

          <mat-form-field class="mobile-number" color="accent">
            <mat-label>{{ 'DL.KM01.C04' | content }}</mat-label>
            <input
              matInput
              type="text"
              [formControlName]="'mobileNumber'"
              name="mobileNumber"
              id="mobileNumber"
              [maxlength]="
                contactForm.controls['countryCode']?.value === '+45' ? 8 : 30
              "
              data-test-id="ST01-input-mobil"
            />
          </mat-form-field>

          <button
            mat-raised-button
            *ngIf="currentMobileNumber"
            [coContent]="'DL.KM01.C12'"
            [disabled]="contactForm.invalid"
            (click)="updateMobile()"
          ></button>
          <button
            mat-raised-button
            *ngIf="!currentMobileNumber"
            [coContent]="'DL.KM01.C15'"
            [disabled]="contactForm.invalid"
            (click)="updateMobile()"
          ></button>
        </div>

        <div *ngIf="!contactForm.pristine">
          <div
            *ngIf="
              contactForm.controls['countryCode'].errors?.invalidCountryCode
            "
            class="warning"
          >
            <span [coContent]="'DL.ST01.C83'"></span>
          </div>

          <div
            *ngIf="contactForm.controls['countryCode'].errors?.required"
            class="warning"
          >
            <span [coContent]="'DL.ST01.C84'"></span>
          </div>

          <div
            *ngIf="contactForm.controls['mobileNumber'].errors"
            class="warning"
          >
            <span [coContent]="'DL.KM01.C07'"></span>
          </div>
        </div>
      </ng-template>
    </div>

    <div class="warning" *ngIf="saveOperationFailed">
      <co-icon-error></co-icon-error>
      <p [coContent]="'DL.KM01.C05'"></p>
    </div>
  </form>
</mat-dialog-content>
