/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const LocScaleGroupSchema = JoiObj.string().valid(
        'STANDARD'
        ,
        'HIGH'
        ,
        'LOW'
        ,
        'UNDEFINED'
        
    )
.id('LocScaleGroupSchema')

export type LocScaleGroup = 'STANDARD' | 'HIGH' | 'LOW' | 'UNDEFINED';

export const LocScaleGroup = {
    Standard: 'STANDARD' as LocScaleGroup,
    High: 'HIGH' as LocScaleGroup,
    Low: 'LOW' as LocScaleGroup,
    Undefined: 'UNDEFINED' as LocScaleGroup
};

