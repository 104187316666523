import { Component, Input, OnInit } from '@angular/core';
import {
  DigitalSalgPrisListe,
  TilbudOverblik,
  DigitalSalgPrisListeRaekke
} from '@pfa/gen';

@Component({
  selector: 'co-prices-new-offer',
  templateUrl: './prices-new-offer.component.html',
  styleUrls: ['../new-business-offer-overview.component.scss']
})
export class PricesNewOfferComponent implements OnInit {
  @Input() overview: TilbudOverblik;
  prices: DigitalSalgPrisListe;
  showCoveragePriceNotFoundText: boolean;
  showServicePricesHeader: boolean;

  ngOnInit(): void {
    this.prices = this.overview.digitalSalgPrisListe;
    const coverages: DigitalSalgPrisListeRaekke[] = [].concat(
      this.prices.tae,
      this.prices.resourceforloeb,
      this.prices.taeBoerne,
      this.prices.taeEngang,
      this.prices.indbetalingssikring,
      this.prices.ks,
      this.prices.ksBoerne,
      this.prices.hs,
      this.prices.hsBoerne,
      this.prices.forebygger,
      this.prices.diagnose,
      this.prices.earlycare,
      this.prices.sundhedslinje,
      this.prices.liv,
      this.prices.livEngang,
      this.prices.livEngangHskode_4_5,
      this.prices.livBoernePension
    );

    // !! coerce to boolean
    this.showCoveragePriceNotFoundText = !!coverages.find(
      coverage => coverage.maanedligPrisTekstId === 'DL.TR01.C423'
    );

    this.showServicePricesHeader =
      this.overview.policyType === 'NEW' ||
      this.overview.partialSchemeType === 'PFA Plus Privat' ||
      this.overview.partialSchemeType === 'Lederpension';
  }
}
