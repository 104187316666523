import { Component, Inject } from '@angular/core';
import {
  MAT_BOTTOM_SHEET_DATA,
  MatBottomSheetRef
} from '@angular/material/bottom-sheet';

@Component({
  selector: 'co-show-pdf-sheet',
  templateUrl: 'show-pdf-sheet.component.html',
  styleUrls: ['show-pdf-sheet.component.scss']
})
export class ShowPdfSheetComponent {
  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA)
    public readonly data: {
      documentUrl: string;
      headline: string;
      documentName?: string;
    },
    private readonly _bottomSheetRef: MatBottomSheetRef<ShowPdfSheetComponent>
  ) {}

  close() {
    this._bottomSheetRef.dismiss();
  }
}
