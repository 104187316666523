/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const CompanyOrPrivateSchema = JoiObj.string().valid(
        'Firma'
        ,
        'Privat'
        
    )
.id('CompanyOrPrivateSchema')

export type CompanyOrPrivate = 'Firma' | 'Privat';

export const CompanyOrPrivate = {
    Firma: 'Firma' as CompanyOrPrivate,
    Privat: 'Privat' as CompanyOrPrivate
};

