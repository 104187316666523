<div class="row">
  <div class="col-md-6 col-image">
    <div class="image"></div>
  </div>

  <div class="col-md-6">
    <p class="text--color-primary text--bold" coContent="DL.MN01.C37"></p>
    <h1 coContent="DL.INVGU01.C610"></h1>
    <p
      class="spacing-unit--over text--manchet text--info"
      coContent="DL.INVGU01.C605"
    ></p>
    <ul class="spacing-unit--double-under text--info">
      <li>
        <div>
          <co-icon-circle-checkmark color="white"></co-icon-circle-checkmark>
        </div>
        {{ 'DL.INVGU01.C606' | content }}
      </li>
      <li>
        <div>
          <co-icon-circle-checkmark color="white"></co-icon-circle-checkmark>
        </div>
        {{ 'DL.INVGU01.C607' | content }}
      </li>
    </ul>

    <co-advisor-box *ngIf="isShortFlowVisible" class="small">
      <div class="advisor-section">
        {{
          (dataValidForShortFlow ? 'DL.INVGU01.C666' : 'DL.INVGU01.C667')
            | content
        }}
        <mat-radio-group
          class="inline"
          [(ngModel)]="advisorShortFlow"
          [disabled]="!dataValidForShortFlow"
        >
          <mat-radio-button [value]="true">{{
            'DL.MD01.C29' | content
          }}</mat-radio-button>
          <mat-radio-button [value]="false">{{
            'DL.MD01.C30' | content
          }}</mat-radio-button>
        </mat-radio-group>
      </div>
    </co-advisor-box>

    <div class="button-section">
      <div class="col-button">
        <button
          class="button-secondary"
          mat-raised-button
          coContent="DL.INVGU01.C609"
          (click)="startInvestmentGuide()"
        ></button>
      </div>
      <div class="text--bold col-info" coContent="DL.INVGU01.C147"></div>
    </div>
  </div>
</div>
