<div class="shadow" [@fadeHalfInOut]></div>
<div
  class="pension-check--container"
  [@slideInBottom]="{ value: '', params: { duration: 300, delay: 0 } }"
  [@slideOutBottomWhole]="{
    value: '',
    params: { delay: 0, duration: 400 }
  }"
>
  <div class="fade--box"></div>
  <div class="fade--box--light"></div>

  <div
    class="pension-check--wrapper"
    [ngClass]="{ 'pension-check--background': !sigmaFailed }"
  >
    <div class="icons--wrapper">
      <co-icon-times
        class="close icon-fill__large--greys-10 pressable"
        (click)="closePensionCheck()"
        data-test-id="closeYourRecommendations"
      ></co-icon-times>
    </div>
    <div *ngIf="sigmaFailed" class="text--headline--wrapper">
      <div>
        <h1 [coContent]="'DL.NB01.C145'"></h1>
        <span class="intro-text" [coContent]="'DL.NB01.C146'"></span>
      </div>
    </div>
    <div *ngIf="!sigmaFailed" class="text--headline--wrapper">
      <h1
        class="spacing-unit--one--half-under"
        [coContent]="'DL.DR01.C65'"
      ></h1>
      <span
        class="intro-text spacing-unit--one--half-under"
        [coContent]="introTextContentId"
        [coContentSubst]="numberOfNotOkCards"
      ></span>
      <div class="pfa-advising-link">
        <p [coContent]="'DL.NB01.C262'"></p>
      </div>

      <div *ngIf="myChanges$ | async; let myChanges">
        <div *ngIf="myChanges?.antalAendredeOmraaderIalt > 0">
          <span
            class="intro-text spacing-unit--one--half-under"
            [coContent]="'DL.DR01.C67'"
          ></span>
          <a routerLink="/mineaendringer" class="link">
            <span
              class="intro-text text--color-primary spacing-unit--one--half-under"
              [coContent]="
                myChanges.antalAendredeOmraaderIalt > 1
                  ? 'DL.DR01.C68'
                  : 'DL.DR01.C70'
              "
              [coContentSubst]="' ' + myChanges.antalAendredeOmraaderIalt"
            >
            </span>
          </a>
          <span
            class="intro-text spacing-unit--one--half-under"
            [coContent]="'DL.DR01.C69'"
          ></span>
        </div>
      </div>
    </div>

    <div class="nba-card--wrapper">
      <div *ngFor="let nbaCardGroup of nbaCardGroups">
        <div
          *ngIf="nbaCardGroup.nbaCards?.length > 0"
          class="spacing-unit--double-under"
        >
          <div class="nba-header">
            <co-icon-savings
              class="icon--nba"
              *ngIf="nbaCardGroup.groupType === 'INVESTMENT'"
            ></co-icon-savings>
            <co-icon-insurance
              class="icon--nba"
              *ngIf="nbaCardGroup.groupType === 'INSURANCE'"
            ></co-icon-insurance>
            <co-icon-profile
              class="icon--nba"
              *ngIf="nbaCardGroup.groupType === 'MISSING_DATA'"
            ></co-icon-profile>
            <h4 [coContent]="nbaCardGroup.groupHeader"></h4>
          </div>
          <div
            *ngFor="let nbaCard of nbaCardGroup.nbaCards; index as i"
            [@.disabled]="i !== 0"
            class="nba-card-spacing"
          >
            <co-card
              [alertColor]="nbaCard.alertColor()"
              [alertPosition]="'TOP'"
              class="card--small"
            >
              <p
                class="text--bold color--black"
                [coContent]="nbaCard.headline"
              ></p>
              <div class="content-wrapper text--note">
                <div class="inline-block" [coContent]="nbaCard.bodyText"></div>
                <button
                  mat-raised-button
                  class="button-secondary float--right"
                  (click)="goTo(nbaCard)"
                  [coContent]="nbaCard.gotoText"
                ></button>
              </div>
            </co-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<mitpfa-pension-check-card-viewer
  [@slideInRight]
  [@slideOutRight]
  class="pension-check--container card--viewer"
  *ngIf="showCardViewer"
  [selectedCardId]="selectedCard.id"
  [selectedCardIdList]="selectedCard.relevantOfferIds"
  (close)="closeViewer()"
>
</mitpfa-pension-check-card-viewer>
