/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ServiceInfo } from './serviceInfo';
import { ServiceInfoSchema } from './serviceInfo';
import { ProcentMinMax } from './procentMinMax';
import { ProcentMinMaxSchema } from './procentMinMax';
import { ResourceModel } from './resourceModel';
import { ResourceModelSchema } from './resourceModel';
import * as JoiObj from "joi";


    export const MinObligatoriskIndbetalingSchema = JoiObj.object({
        serviceInfo:
            
        JoiObj.link('#ServiceInfoSchema')

.allow(null)



        ,
        arbejdesgiverIndbetalingEffektueringMuligt:
            
                    JoiObj.boolean()
                


.required()


        ,
        arbejdsgiverProcentMinMax:
            
        JoiObj.link('#ProcentMinMaxSchema')


.required()


        ,
        medarbejderIndbetalingEffektueringMuligt:
            
                    JoiObj.boolean()
                


.required()


        ,
        medarbejderProcentMinMax:
            
        JoiObj.link('#ProcentMinMaxSchema')


.required()


        ,
        procentOrdning:
            
                    JoiObj.boolean()
                


.required()


        
    })
                .shared(ServiceInfoSchema)
                .shared(ProcentMinMaxSchema)
                .shared(ProcentMinMaxSchema)
.id('MinObligatoriskIndbetalingSchema')

export interface MinObligatoriskIndbetaling extends ResourceModel { 
    arbejdesgiverIndbetalingEffektueringMuligt: boolean;
    arbejdsgiverProcentMinMax: ProcentMinMax;
    medarbejderIndbetalingEffektueringMuligt: boolean;
    medarbejderProcentMinMax: ProcentMinMax;
    procentOrdning: boolean;
}

