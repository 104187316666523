import { PensionPlanCard } from './pensionplan-card.interface';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { openClose } from '@pfa/animations';

@Component({
  selector: 'co-pensionplan-card-list',
  templateUrl: './pensionplan-card-list.component.html',
  styleUrls: ['./pensionplan-card-list.component.scss'],
  animations: [openClose]
})
export class PensionPlanCardListComponent {
  @Input() set pensionPlanCardList(value: PensionPlanCard[]) {
    if (value) {
      this._pensionPlanCardList = value;
      this.initializeShowDetailsStatusToFalse();
    }
  }
  get pensionPlanCardList(): PensionPlanCard[] {
    return this._pensionPlanCardList;
  }
  @Output() pensionPlanCardSelectedEvent = new EventEmitter();

  showDetails: boolean[] = [];

  _pensionPlanCardList: PensionPlanCard[];

  pensionPlanCardSelected(emittedId: string) {
    this.pensionPlanCardSelectedEvent.emit(emittedId);
  }

  private initializeShowDetailsStatusToFalse() {
    this.pensionPlanCardList.forEach((item, index) => {
      this.showDetails[index] = false;
    });
  }
}
