import { StamdataDetaljer } from '@pfa/gen';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class FamilyInformationApiService {
  constructor(private http: HttpClient) {}

  getFamilyInformation(): Observable<StamdataDetaljer> {
    const url = '/ds/api/stamdata';
    return this.http.get<StamdataDetaljer>(url);
  }

  updateFamilyInformation(
    stamdataDetaljer: StamdataDetaljer
  ): Observable<StamdataDetaljer> {
    return this.http.post<StamdataDetaljer>(
      '/ds/api/stamdata',
      stamdataDetaljer
    );
  }
}
