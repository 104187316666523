/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ServiceInfo } from './serviceInfo';
import { ServiceInfoSchema } from './serviceInfo';
import { ResourceModel } from './resourceModel';
import { ResourceModelSchema } from './resourceModel';
import * as JoiObj from "joi";


    export const GippDataSchema = JoiObj.object({
        serviceInfo:
            
        JoiObj.link('#ServiceInfoSchema')

.allow(null)



        ,
        enhedId:
            
                    JoiObj.number()
                


.required()


        ,
        harKontoregnskab:
            
                    JoiObj.boolean()
                


.required()


        ,
        omkostningsGruppeId:
            
                    JoiObj.number()
                


.required()


        ,
        omkostningsGruppeTekstId:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        opsparing:
            
                    JoiObj.number()
                


.required()


        ,
        renteGruppeId:
            
                    JoiObj.number()
                


.required()


        ,
        renteGruppeTekstId:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        risikoGruppeId:
            
                    JoiObj.number()
                


.required()


        ,
        risikoGruppeTekstId:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        
    })
                .shared(ServiceInfoSchema)
.id('GippDataSchema')

export interface GippData extends ResourceModel { 
    enhedId: number;
    harKontoregnskab: boolean;
    omkostningsGruppeId: number;
    omkostningsGruppeTekstId: string;
    opsparing: number;
    renteGruppeId: number;
    renteGruppeTekstId: string;
    risikoGruppeId: number;
    risikoGruppeTekstId: string;
}

