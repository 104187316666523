/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BeneficiaryType } from './beneficiaryType';
import { BeneficiaryTypeSchema } from './beneficiaryType';
import { BeneficiaryPolicyView } from './beneficiaryPolicyView';
import { BeneficiaryPolicyViewSchema } from './beneficiaryPolicyView';
import { NextOfKinType } from './nextOfKinType';
import { NextOfKinTypeSchema } from './nextOfKinType';
import { BeneficiaryDocumentView } from './beneficiaryDocumentView';
import { BeneficiaryDocumentViewSchema } from './beneficiaryDocumentView';
import { IrrevocabilityType } from './irrevocabilityType';
import { IrrevocabilityTypeSchema } from './irrevocabilityType';
import * as JoiObj from "joi";


    export const BeneficiaryViewSchema = JoiObj.object({
        converted:
            
                    JoiObj.boolean()
                


.required()


        ,
        documents:
                JoiObj.array().items(
        
        JoiObj.link('#BeneficiaryDocumentViewSchema')

        )
        

.required()


        ,
        irrevocabilityType:
            
        JoiObj.link('#IrrevocabilityTypeSchema')

.allow(null)



        ,
        level2Type:
            
        JoiObj.link('#BeneficiaryTypeSchema')

.allow(null)



        ,
        nextOfKinType:
            
        JoiObj.link('#NextOfKinTypeSchema')

.allow(null)



        ,
        policies:
                JoiObj.array().items(
        
        JoiObj.link('#BeneficiaryPolicyViewSchema')

        )
        

.required()


        ,
        specialBeneficiary:
            
                    JoiObj.boolean()
                


.required()


        ,
        type:
            
        JoiObj.link('#BeneficiaryTypeSchema')

.allow(null)



        
    })
                .shared(BeneficiaryDocumentViewSchema)
                .shared(IrrevocabilityTypeSchema)
                .shared(BeneficiaryTypeSchema)
                .shared(NextOfKinTypeSchema)
                .shared(BeneficiaryPolicyViewSchema)
                .shared(BeneficiaryTypeSchema)
.id('BeneficiaryViewSchema')

export interface BeneficiaryView { 
    converted: boolean;
    documents: Array<BeneficiaryDocumentView>;
    irrevocabilityType?: IrrevocabilityType;
    level2Type?: BeneficiaryType;
    nextOfKinType?: NextOfKinType;
    policies: Array<BeneficiaryPolicyView>;
    specialBeneficiary: boolean;
    type?: BeneficiaryType;
}
export namespace BeneficiaryView {
}


