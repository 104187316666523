<p
  class="text--manchet header spacing-unit--one--half-under"
  coContent="DL.VK01.C29"
></p>
<co-card>
  <co-customer-contact-details
    [pensionCustomer]="pensionCustomer"
    (contactDetailsUpdate)="handleContactDetailsUpdate()"
    (form)="setForm($event)"
    [phoneRequired]="true"
    [emailRequired]="true"
  ></co-customer-contact-details>
  <co-alert class="alert-warning" *ngIf="isUpdateError">
    <p [coContent]="'DL.VK01.C44'"></p>
  </co-alert>
</co-card>
