/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ServiceInfo } from './serviceInfo';
import { ServiceInfoSchema } from './serviceInfo';
import { ResourceModel } from './resourceModel';
import { ResourceModelSchema } from './resourceModel';
import * as JoiObj from "joi";


    export const AfkastLinieSchema = JoiObj.object({
        serviceInfo:
            
        JoiObj.link('#ServiceInfoSchema')

.allow(null)



        ,
        afkastBeloeb:
            
                    JoiObj.number()
                


.required()


        ,
        colorCode:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        contentId:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        detalje:
            
                    JoiObj.boolean()
                


.required()


        ,
        kundekapitalAar:
            
                    JoiObj.number()
                .integer()

.allow(null)



        ,
        opsparing:
            
                    JoiObj.number()
                

.allow(null)



        ,
        subpolicenr:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        toolTip:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        
    })
                .shared(ServiceInfoSchema)
.id('AfkastLinieSchema')

export interface AfkastLinie extends ResourceModel { 
    afkastBeloeb: number;
    colorCode?: string;
    contentId: string;
    detalje: boolean;
    kundekapitalAar?: number;
    opsparing?: number;
    subpolicenr?: string;
    toolTip?: string;
}

