<co-card-animated-stack [activeIndex]="activeQuestionIndex">
  <co-card-animated-stack-item [scrollToTopOnActivate]="true" maxWidth="1024px">
    <co-card>
      <mitpfa-investment-guide-risk-willingness
        [investmentGuide]="investmentGuide"
        [broker]="broker"
      ></mitpfa-investment-guide-risk-willingness>
    </co-card>
  </co-card-animated-stack-item>
  <co-card-animated-stack-item [scrollToTopOnActivate]="true" maxWidth="1024px">
    <co-card>
      <mitpfa-investment-guide-risk-profile
        [investmentGuide]="investmentGuide"
        [broker]="broker"
      ></mitpfa-investment-guide-risk-profile>
    </co-card>
  </co-card-animated-stack-item>
  <co-card-animated-stack-item [scrollToTopOnActivate]="true" maxWidth="1024px">
    <co-card>
      <mitpfa-investment-guide-lossability
        [investmentGuide]="investmentGuide"
        [broker]="broker"
      ></mitpfa-investment-guide-lossability>
    </co-card>
  </co-card-animated-stack-item>
</co-card-animated-stack>
<mitpfa-investment-guide-back-button
  (back)="goBack()"
></mitpfa-investment-guide-back-button>
