/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const ErrorPageInfoSchema = JoiObj.object({
        errorType:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        errorCode:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        errorURL:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        errorDescription:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        errorStackTrace:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        
    })
.id('ErrorPageInfoSchema')

export interface ErrorPageInfo { 
    errorType: ErrorPageInfo.ErrorTypeEnum;
    errorCode?: string;
    errorURL?: string;
    errorDescription?: string;
    errorStackTrace?: string;
}
export namespace ErrorPageInfo {
    export type ErrorTypeEnum = 'frontend' | 'backend';
    export const ErrorTypeEnum = {
        Frontend: 'frontend' as ErrorTypeEnum,
        Backend: 'backend' as ErrorTypeEnum
    };
}


