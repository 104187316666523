import {
  Component,
  DestroyRef,
  inject,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { SubMenuItem } from '../../menu.model';
import { ContentService, ContentUtilService } from '@pfa/core';
import { MenuService } from '../../menu.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'mitpfa-selected-menu-item',
  templateUrl: './selected-menu-item.component.html',
  styleUrls: ['./selected-menu-item.component.scss']
})
export class SelectedMenuItemComponent implements OnInit, OnChanges {
  @Input() selectedSubMenu: SubMenuItem;

  private contentUtilService: ContentUtilService = inject(ContentUtilService);
  private contentService: ContentService = inject(ContentService);
  private readonly menuService: MenuService = inject(MenuService);
  private readonly destroyRef: DestroyRef = inject(DestroyRef);

  public content: string;

  ngOnInit() {
    this.contentService.languageState
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.setContent(this.selectedSubMenu);
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.setContent(changes.selectedSubMenu.currentValue);
  }

  private setContent(selectedSubMenu: SubMenuItem): void {
    if (
      selectedSubMenu.dontShow &&
      selectedSubMenu.name === 'ONLINE_SUNDHEDSHJAELP'
    ) {
      this.content = this.contentUtilService.getContent(
        this.menuService.getInsurance().contentId
      );
      return;
    }
    this.content = this.contentUtilService.getContent(
      selectedSubMenu.contentId
    );
  }
}
