<tr
  [ngClass]="{
    'forced-thick-border': resultLine,
    'forced-thin-border': !resultLine
  }"
>
  <td
    [attr.data-label]="'DL.OS01.C425' | content"
    class="responsiv-top-padding"
  >
    <span
      class="text--bold"
      [coContent]="headerContentId"
      [coContentSubst]="headerSubst"
    ></span>
    <co-tooltip *ngIf="tooltipContentId" [text]="tooltipContentId"></co-tooltip>
  </td>
  <td class="text--bold" [attr.data-label]="'DL.OS01.C426' | content">
    <div *ngIf="!resultLine">
      {{ total | numberFormat: 2 }}
      <span *ngIf="total" [coContent]="'Global.kroner'"></span>
    </div>
    <co-primary-number
      *ngIf="resultLine"
      [amount]="total"
      [showDecimals]="true"
      class="size-sm"
    ></co-primary-number>
  </td>
</tr>
