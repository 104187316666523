<co-accordion [title]="'DL.OS01.C270'" [initExpanded]="true">
  <co-loading [show]="!initialized"></co-loading>
  <table
    *ngIf="initialized"
    class="pfa-table pfa-table--compact table-responsive align-left--column-1 align-right--column-2"
  >
    <thead class="hide--palm">
      <tr>
        <th scope="col" [coContent]="'DL.OS01.C425'">produkt</th>
        <th scope="col" [coContent]="'DL.OS01.C426'">beløb</th>
      </tr>
    </thead>
    <tbody>
      <!-- SAMLET VAERDI - PRIMO -->
      <mitpfa-savings-policy-details-line-element
        *ngIf="
          savingsPolicyDetails.vaerdiAfOpsparingPrimo?.linieElementer?.length >
          0
        "
        [headerContentId]="'DL.OS01.C203'"
        [headerSubst]="savingsPolicyDetails.fraDato"
        [tooltipContentId]="'DL.OS01.I203'"
        [total]="savingsPolicyDetails.vaerdiAfOpsparingPrimo.total"
        [lineElements]="
          savingsPolicyDetails.vaerdiAfOpsparingPrimo.linieElementer
        "
      >
      </mitpfa-savings-policy-details-line-element>

      <!-- INDBETALINGER -->
      <mitpfa-savings-policy-details-line-element
        *ngIf="savingsPolicyDetails.indbetalinger.linieElementer.length > 0"
        [headerContentId]="'DL.OS01.C210'"
        [headerSubst]="savingsPolicyDetails.fraDato"
        [tooltipContentId]="paymentTooltip"
        [total]="savingsPolicyDetails.indbetalinger.total"
        [lineElements]="savingsPolicyDetails.indbetalinger.linieElementer"
      >
      </mitpfa-savings-policy-details-line-element>

      <!-- INDBETALINGSSIKRING -->
      <mitpfa-savings-policy-details-simple
        *ngIf="savingsPolicyDetails.indbetalingViaIndbetalingsSikring"
        [headerContentId]="'DL.OS01.C211'"
        [tooltipContentId]="'DL.OS01.I211'"
        [total]="savingsPolicyDetails.indbetalingViaIndbetalingsSikring"
      >
      </mitpfa-savings-policy-details-simple>

      <!-- ARBEJDSMARKEDSBIDRAG -->
      <mitpfa-savings-policy-details-simple
        *ngIf="savingsPolicyDetails.arbejdsmarkedsbidrag"
        [headerContentId]="'DL.OS01.C212'"
        [tooltipContentId]="'DL.OS01.I212'"
        [total]="savingsPolicyDetails.arbejdsmarkedsbidrag"
      >
      </mitpfa-savings-policy-details-simple>

      <!-- AcountAskat -->
      <mitpfa-savings-policy-details-simple
        *ngIf="savingsPolicyDetails.acontoAskat"
        [headerContentId]="'DL.OS01.C409'"
        [tooltipContentId]="'DL.OS01.I410'"
        [total]="savingsPolicyDetails.acontoAskat"
      >
      </mitpfa-savings-policy-details-simple>

      <mitpfa-savings-policy-details-marketrate-transfers
        *ngIf="savingsPolicyDetails.overfoersler?.length > 0"
        [savingsPolicyDetails]="savingsPolicyDetails"
        [chosenYear]="chosenYear"
      >
      </mitpfa-savings-policy-details-marketrate-transfers>

      <!-- UDBETALING -->
      <mitpfa-savings-policy-details-simple
        *ngIf="savingsPolicyDetails.udbetaling"
        [headerContentId]="'DL.OS01.C213'"
        [tooltipContentId]="'DL.OS01.I213'"
        [total]="savingsPolicyDetails.udbetaling"
      >
      </mitpfa-savings-policy-details-simple>

      <!-- FREMRYKKET AFGIFT AF KAPITALPENSION -->
      <mitpfa-savings-policy-details-simple
        *ngIf="savingsPolicyDetails.fremrykketAfgiftAfKapitalpension"
        [headerContentId]="'DL.OS01.C214'"
        [tooltipContentId]="'DL.OS01.I214'"
        [total]="savingsPolicyDetails.fremrykketAfgiftAfKapitalpension"
      >
      </mitpfa-savings-policy-details-simple>

      <!-- UDBETALING TIL AEGTEFAELLE ELLER SAMLEVER -->
      <mitpfa-savings-policy-details-simple
        *ngIf="savingsPolicyDetails.udbetalingAegtfaelleEllerSamlever"
        [headerContentId]="'DL.OS01.C215'"
        [tooltipContentId]="'DL.OS01.I215'"
        [total]="savingsPolicyDetails.udbetalingAegtfaelleEllerSamlever"
      >
      </mitpfa-savings-policy-details-simple>

      <!-- BETALING FOR FORSIKRINGER-->
      <mitpfa-savings-policy-details-line-element
        *ngIf="
          savingsPolicyDetails.betalingForsikringer.linieElementer.length > 0
        "
        [headerContentId]="'DL.OS01.C216'"
        [total]="savingsPolicyDetails.betalingForsikringer.total"
        [lineElements]="
          savingsPolicyDetails.betalingForsikringer.linieElementer
        "
      >
      </mitpfa-savings-policy-details-line-element>

      <!-- BETALING FOR ADM + SERVICE -->
      <mitpfa-savings-policy-details-marketrate-adm
        *ngIf="showAdm"
        [savingsPolicyDetails]="savingsPolicyDetails"
        [isCommissionAndRemuneration]="isCommissionAndRemuneration"
      >
      </mitpfa-savings-policy-details-marketrate-adm>

      <!-- ØVRIGE POSTER -->
      <mitpfa-savings-policy-details-marketrate-misc
        *ngIf="
          savingsPolicyDetails.oevrigePoster
            ?.posteringerDerIkkeSkalBetalesPalSkatAf?.length > 0 ||
          savingsPolicyDetails.oevrigePoster?.posteringerDerSkalBetalesPalSkatAf
            ?.length > 0
        "
        [savingsPolicyDetails]="savingsPolicyDetails"
      >
      </mitpfa-savings-policy-details-marketrate-misc>

      <!-- AFKAST-->
      <mitpfa-savings-policy-details-simple
        *ngIf="savingsPolicyDetails.afkast"
        [headerContentId]="'DL.OS01.C257'"
        [tooltipContentId]="'DL.OS01.I257'"
        [total]="savingsPolicyDetails.afkast"
      >
      </mitpfa-savings-policy-details-simple>

      <!-- AFREGNET AFKAST-->
      <mitpfa-savings-policy-details-line-element
        *ngIf="
          savingsPolicyDetails.afregnetPensionsafkastskat.linieElementer
            .length > 0
        "
        [headerContentId]="
          savingsPolicyDetails.afregnetPensionsafkastskat.groenland
            ? 'DL.OS01.C407'
            : 'DL.OS01.C258'
        "
        [tooltipContentId]="unpaidPALTooltip"
        [total]="savingsPolicyDetails.afregnetPensionsafkastskat.total"
        [lineElements]="
          savingsPolicyDetails.afregnetPensionsafkastskat.linieElementer
        "
      >
      </mitpfa-savings-policy-details-line-element>

      <!-- RESERVERING VED INVALIDITET -->
      <mitpfa-savings-policy-details-simple
        *ngIf="savingsPolicyDetails.reservespringVedInvaliditet !== 0"
        [headerContentId]="'DL.OS01.C411'"
        [tooltipContentId]="'DL.OS01.I411'"
        [total]="savingsPolicyDetails.reservespringVedInvaliditet"
      >
      </mitpfa-savings-policy-details-simple>

      <!-- RESERVERING VED REAKTIVERING -->
      <mitpfa-savings-policy-details-simple
        *ngIf="savingsPolicyDetails.reservespringVedReaktivering !== 0"
        [headerContentId]="'DL.OS01.C412'"
        [tooltipContentId]="'DL.OS01.I412'"
        [total]="savingsPolicyDetails.reservespringVedReaktivering"
      >
      </mitpfa-savings-policy-details-simple>

      <!-- SAMLET VÆRDI ULTIMO -->
      <mitpfa-savings-policy-details-line-element
        *ngIf="
          savingsPolicyDetails.vaerdiAfOpsparingUltimo.linieElementer.length > 0
        "
        [headerContentId]="'DL.OS01.C203'"
        [headerSubst]="savingsPolicyDetails.tilDato"
        [tooltipContentId]="'DL.OS01.I204'"
        [total]="savingsPolicyDetails.vaerdiAfOpsparingUltimo.total"
        [lineElements]="
          savingsPolicyDetails.vaerdiAfOpsparingUltimo.linieElementer
        "
        [resultLine]="true"
      >
      </mitpfa-savings-policy-details-line-element>

      <!-- TILBAGEFØRSEL -->
      <mitpfa-savings-policy-details-transfer-back
        *ngIf="
          savingsPolicyDetails.tilbageFoerselInvalidedepotVedOphoerAfInvaliditet &&
          savingsPolicyDetails.tilbageFoerselInvalidedepotVedOphoerAfInvaliditet !==
            0
        "
        [savingsPolicyDetails]="savingsPolicyDetails"
      >
      </mitpfa-savings-policy-details-transfer-back>

      <!-- IKKE AFREGNET PENSIONSAFKAST-->
      <mitpfa-savings-policy-details-simple
        *ngIf="savingsPolicyDetails.ikkeAfregnetPensionsafkast"
        [headerContentId]="'DL.OS01.C261'"
        [tooltipContentId]="'DL.OS01.I261'"
        [total]="savingsPolicyDetails.ikkeAfregnetPensionsafkast"
      >
      </mitpfa-savings-policy-details-simple>

      <!-- IKKE AFREGNET REFUSION -->
      <mitpfa-savings-policy-details-simple
        *ngIf="
          savingsPolicyDetails.aaopAaok
            ?.ikkeTilskrevetRabatIndirekteFondOmkostningerBeloeb
        "
        [headerContentId]="'DL.OS01.C445'"
        [tooltipContentId]="'DL.OS01.C446'"
        [total]="
          savingsPolicyDetails.aaopAaok
            .ikkeTilskrevetRabatIndirekteFondOmkostningerBeloeb
        "
      >
      </mitpfa-savings-policy-details-simple>
    </tbody>
  </table>
</co-accordion>

<co-bodytext>
  <div *ngIf="savingsPolicyDetails.vaerdi83 > 0" class="spacing-unit--over">
    <div class="text--bold" [coContent]="'DL.OS01.C386'"></div>
    <div
      [coContent]="'DL.OS01.C384'"
      [coContentSubst]="savingsPolicyDetails.vaerdi83 | numberFormat: 2"
    ></div>
  </div>

  <!-- Kundekapital-OPSPARING -->
  <div
    *ngIf="savingsPolicyDetails.kundekapitalOpsparing > 0"
    class="spacing-unit--over"
  >
    <div class="text--bold" [coContent]="'DL.OS01.C418'"></div>
    <div
      [coContent]="'DL.OS01.C419'"
      [coContentSubst]="
        savingsPolicyDetails.tilDato +
        '|' +
        (savingsPolicyDetails.vaerdiAfOpsparingUltimo.total | numberFormat: 2) +
        '|' +
        (savingsPolicyDetails.kundekapitalOpsparing | numberFormat: 2)
      "
    ></div>
  </div>

  <!-- Eksterne leverandører: Mølholm, Willis, ... -->
  <div
    *ngIf="savingsPolicyDetails.eksternDaekninger?.length > 0"
    class="spacing-unit--over"
  >
    <div class="text--bold" [coContent]="'DL.OS01.C361'"></div>
    <ng-container
      *ngFor="let coverage of savingsPolicyDetails.eksternDaekninger"
    >
      <div class="row coverage-row">
        <div
          class="col-9"
          [coContent]="
            coverage.eksternLeverandoerNavn.includes('Willis')
              ? 'DL.OS01.C404'
              : 'DL.OS01.C400'
          "
          [coContentSubst]="coverage.eksternLeverandoerNavn"
        ></div>
        <div class="col-3 align--right">
          {{ coverage.eksternDaekningPris | numberFormat: 2 }}
          <span [coContent]="'Global.kroner'"></span>
        </div>
      </div>
      <div class="row" *ngIf="coverage.eksternDaekningTilskud > 0">
        <div
          class="col-9"
          [coContent]="
            coverage.eksternLeverandoerNavn.includes('Willis')
              ? 'DL.OS01.C406'
              : 'DL.OS01.C401'
          "
          [coContentSubst]="coverage.eksternLeverandoerNavn"
        ></div>
        <div class="col-3 align--right">
          {{ coverage.eksternDaekningTilskud | numberFormat: 2 }}
          <span [coContent]="'Global.kroner'"></span>
        </div>
      </div>
    </ng-container>
  </div>
</co-bodytext>
