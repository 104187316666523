<div *ngIf="showOperationStatus" class="operationstatus">
  <div class="icon--operationstatus">
    <img src="/assets/images/advarselstrekant.png" />
  </div>
  <div class="operationstatus--inner">
    <span class="text">{{ operationStatusText }}</span>
  </div>
  <div
    class="icon--operationstatus--close"
    data-test-id="hide-operation-status"
  >
    <div class="cursor-pointer" (click)="hideOperationStatusText()">
      <co-icon-times></co-icon-times>
    </div>
  </div>
</div>
