import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NewBusinessAdvisoryComponent } from './new-business-advisory.component';
import { CoreModule } from '@pfa/core';
import { PfaFormModule } from '@pfaform';
import { MatButtonModule } from '@angular/material/button';
import { IconModule } from '@pfa/icons';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { ReactiveFormsModule } from '@angular/forms';
import { ReleaseOfferComponent } from './release-offer/release-offer.component';
import { LabelInputComponent } from './release-offer/label-input/label-input.component';
import { ShowAgreementDialogComponent } from './release-offer/show-agreement/show-agreement-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { ReleaseReceiptDialogComponent } from './release-offer/release-receipt-dialog/release-receipt-dialog.component';

@NgModule({
  declarations: [
    NewBusinessAdvisoryComponent,
    ReleaseOfferComponent,
    LabelInputComponent,
    ShowAgreementDialogComponent,
    ReleaseReceiptDialogComponent
  ],
  imports: [
    RouterModule.forChild([
      {
        path: 'advisor/new-business/overview',
        component: NewBusinessAdvisoryComponent
      },
      {
        path: 'advisor/new-business/release-offer',
        component: ReleaseOfferComponent
      }
    ]),
    CommonModule,
    CoreModule,
    PfaFormModule,
    MatButtonModule,
    IconModule,
    MatInputModule,
    MatCheckboxModule,
    MatRadioModule,
    ReactiveFormsModule,
    MatDialogModule
  ]
})
export class NewBusinessAdvisoryModule {}
