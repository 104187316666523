import { Injectable, OnDestroy, inject } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  Validators
} from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { ContentService } from '@pfa/core';
import {
  accountNumberStrictMatchValidator,
  bankNumberValidator
} from '@pfa/utils';
import {
  AftaleAnbefaling,
  EksternOverfoerselAdvarsel,
  EksternOverfoerselSignereRequestObj,
  EksternOverfoerselSignereResponseObj,
  ExternaltransferStore
} from '@pfa/gen';
import {
  bankPensionType,
  chooseEntireAmount
} from '@mitpfa/shared/combine-savings/combine-savings.model';
import { clearIdBanks } from '../../../app/combine-savings-advisor/combine-savings-advisor.model';

@Injectable()
export class CombineSavingsAcceptTransferFormService implements OnDestroy {
  private readonly contentService: ContentService = inject(ContentService);
  private readonly externaltransferStore: ExternaltransferStore = inject(
    ExternaltransferStore
  );

  public form: UntypedFormGroup = new UntypedFormGroup({});
  private formSubscription: Subscription = new Subscription();

  public ngOnDestroy(): void {
    this.formSubscription.unsubscribe();
  }

  public initializeForm(transferablePolicies: AftaleAnbefaling[]): void {
    this.form = new UntypedFormGroup({
      policies: new UntypedFormGroup({}, (group): ValidationErrors | null => {
        const g = group as UntypedFormGroup;
        const atLeastOneTransferSelected = Object.values(g.controls).some(
          (f: UntypedFormGroup) =>
            f.value.transfer &&
            (f.value.suppressWarning ||
              !Object.prototype.hasOwnProperty.call(f.value, 'suppressWarning'))
        );
        return atLeastOneTransferSelected
          ? null
          : { atLeastOneTransfer: false };
      })
    });

    transferablePolicies.forEach(policy => {
      const transferFormControl = new UntypedFormControl(true);

      const showSuppressWarningCheckbox = policy.advarsler.includes(
        EksternOverfoerselAdvarsel.HarIndbetaling
      );

      const policies = this.form.get('policies') as UntypedFormGroup;

      policies.addControl(
        policy.uid ?? '',
        new UntypedFormGroup({
          transfer: transferFormControl
        })
      );

      if (showSuppressWarningCheckbox && policy.uid) {
        const suppressWarningFormControl = new UntypedFormControl(false, [
          Validators.requiredTrue
        ]);

        if (policy.overfoerselBestilt) {
          suppressWarningFormControl.disable();
        }

        suppressWarningFormControl.setValue(
          policy.advarsler.includes(
            EksternOverfoerselAdvarsel.HarIndbetalingUndertrykt
          )
        );

        suppressWarningFormControl.valueChanges.subscribe(val => {
          this.applyFormValuesToModel(transferablePolicies);
        });

        const policyGroup = policies.get(policy.uid) as UntypedFormGroup;
        if (policyGroup) {
          policyGroup.addControl('suppressWarning', suppressWarningFormControl);
        }
      }

      if (policy.overfoerselBestilt) {
        transferFormControl.setValue(false);
        transferFormControl.disable();
      }

      if (
        policy.produkttype === bankPensionType &&
        !policy.overfoerselBestilt
      ) {
        const isClearId = clearIdBanks.includes(policy.selskab);
        const strippedPensionsordning = this.removeNonNumericCharacters(
          policy.pensionsordning
        );

        const prefilledBankNumber = !isClearId
          ? strippedPensionsordning?.substring(0, 4)
          : undefined;
        const prefilledAccountNumber =
          strippedPensionsordning?.substring(4) || '';

        const bankNumberFormControl = new UntypedFormControl(
          prefilledBankNumber,
          {
            validators: bankNumberValidator()
          }
        );
        bankNumberFormControl.markAsDirty();

        const accountNumberFormControl = new UntypedFormControl(
          prefilledAccountNumber,
          {
            validators: accountNumberStrictMatchValidator()
          }
        );
        accountNumberFormControl.markAsDirty();
        (
          this.form.get(`policies.${policy.uid}`) as UntypedFormGroup
        ).addControl('bankNumber', bankNumberFormControl);
        (
          this.form.get(`policies.${policy.uid}`) as UntypedFormGroup
        ).addControl('accountNumber', accountNumberFormControl);

        this.formSubscription.add(
          transferFormControl.valueChanges.subscribe(value => {
            if (value) {
              bankNumberFormControl.enable();
              accountNumberFormControl.enable();
            } else {
              bankNumberFormControl.disable();
              accountNumberFormControl.disable();
            }
          })
        );
      }
    });
  }

  public applyFormValuesToModel(
    policies: AftaleAnbefaling[]
  ): AftaleAnbefaling[] {
    // update data with bank numbers and account number
    return policies.map((policy: AftaleAnbefaling) => {
      if (policy.uid && !this.form.value.policies[policy.uid]?.transfer) {
        return policy;
      }

      if (policy.produkttype !== bankPensionType) {
        return Object.assign(policy, {
          undertrykAdvarsel: policy.uid
            ? this.form.value.policies[policy.uid].suppressWarning
            : false,
          valgt: true
        });
      }

      return Object.assign(policy, {
        valgt: true,
        undertrykAdvarsel: policy.uid
          ? this.form.value.policies[policy.uid].suppressWarning
          : false,
        bankoplysninger: {
          kontonr: policy.uid
            ? this.form.value.policies[policy.uid].accountNumber
            : '',
          regnr: policy.uid
            ? this.form.value.policies[policy.uid].bankNumber
            : '',
          valg: chooseEntireAmount
        }
      });
    });
  }

  public setDefaultAnswers(policies: AftaleAnbefaling[]): AftaleAnbefaling[] {
    return policies.map((policy: AftaleAnbefaling) => {
      let jobSkifte = policy.jobSkifte;
      if (policy.visJobskifte && policy.jobSkifte === null) {
        jobSkifte = 'MINDRE_END_3_AAR';
      }

      let leverandoerSkifte = policy.leverandoerSkifte;
      if (policy.visLeverandoerskifte && policy.leverandoerSkifte === null) {
        leverandoerSkifte = { booleanValue: false };
      }

      let fravalgAfForsikring = policy.fravalgAfForsikring;
      if (
        policy.visFravalgAfForsikring &&
        policy.fravalgAfForsikring === null
      ) {
        fravalgAfForsikring = { booleanValue: true };
      }

      return Object.assign(policy, {
        jobSkifte,
        leverandoerSkifte,
        fravalgAfForsikring
      });
    });
  }

  public getPoliciesToBeTransferred(): string[] {
    return Object.keys(this.form.value.policies).filter(
      (key: string) => this.form.value.policies[key].transfer
    );
  }

  public requestCombineSavings(): Observable<EksternOverfoerselSignereResponseObj> {
    const request = {
      sprog: this.contentService.getLanguage().toUpperCase(),
      overfoeres: this.getPoliciesToBeTransferred()
    } as EksternOverfoerselSignereRequestObj;
    return this.externaltransferStore.externaltransferSignPost(request);
  }

  private removeNonNumericCharacters(text: string): string {
    return text?.replace(/[^0-9]/g, '');
  }
}
