import { Component, OnInit, DestroyRef, inject } from '@angular/core';
import {
  DigitalSalgContext,
  PensionsKundeGenerelleData,
  PensionskundeStore
} from '@pfa/gen';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { AdvisorMenuProgressService } from './advisor-menu-progress.service';
import {
  AdvisorMenu,
  AdvisorMenuItem,
  AdvisorMenuSection
} from './advisor-menu.model';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NewBusinessAdvisoryService } from '@pfa/services';
import { AdvisorMenuService } from './advisor-menu.service';
import { map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'mitpfa-advisormenu',
  templateUrl: './advisor-menu.component.html',
  styleUrls: ['./advisor-menu.component.scss']
})
export class AdvisorMenuComponent implements OnInit {
  public menu: AdvisorMenu;
  public isAdvisor: boolean;

  private readonly router: Router = inject(Router);
  private readonly activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  private readonly advisorMenuProgressService: AdvisorMenuProgressService =
    inject(AdvisorMenuProgressService);
  private readonly pensionskundeStore: PensionskundeStore =
    inject(PensionskundeStore);
  private readonly destroyRef: DestroyRef = inject(DestroyRef);
  private readonly advisingService: NewBusinessAdvisoryService = inject(
    NewBusinessAdvisoryService
  );
  private readonly advisorMenuService: AdvisorMenuService =
    inject(AdvisorMenuService);

  ngOnInit(): void {
    // advisingService.getNewBusinessAdvisory() must be called
    this.advisingService
      .getNewBusinessAdvisory()
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        map(
          (digitalSalgContext: DigitalSalgContext) =>
            !!digitalSalgContext.digitalSalgRaadgivning
        ),
        switchMap((isDigitalRaadgivning: boolean) => {
          if (isDigitalRaadgivning) {
            return this.advisorMenuService.newBusinessMenu$;
          } else {
            return this.advisorMenuService.advisorMenu$;
          }
        }),
        switchMap((menu: AdvisorMenu) => {
          this.setupMenu(menu);
          return this.pensionskundeStore.pensionskundeGet();
        })
      )
      .subscribe({
        next: (pensionsCustomer: PensionsKundeGenerelleData) => {
          this.hideOpsparingsPlan(pensionsCustomer);
          this.handleRoute({
            url: this.router.url,
            purgeProgress:
              !!this.activatedRoute.snapshot.queryParams.startAdvice
          });
          this.isAdvisor = pensionsCustomer.isRaadgiver;
        },
        error: () => {}
      });

    this.router.events
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(ev => {
        if (ev instanceof NavigationEnd) {
          if (this.isAdvisor) {
            this.handleRoute({ url: this.router.url });
          }
        }
      });
  }

  private hideOpsparingsPlan(
    pensionsCustomer: PensionsKundeGenerelleData
  ): void {
    if (pensionsCustomer.alder < 65) {
      this.menu.sections.forEach(section => {
        if (section.name === 'Opsparing') {
          section.children.forEach(child => {
            if (child.name === 'Opsparingsplan') {
              child.hide = false;
            }
          });
        }
      });
    }
  }

  private setupMenu(value: AdvisorMenu): void {
    const sections = this.advisorMenuProgressService.setMenuItemsProgress(
      value.sections
    );
    this.menu = value;
    this.menu.sections = sections;
  }

  public handleRoute({
    url,
    purgeProgress = false
  }: {
    url: string;
    purgeProgress?: boolean;
  }) {
    if (purgeProgress) {
      this.advisorMenuProgressService.purgeProgress();
    }

    const menuItemWithUrl = this.findMenuItemWithUrl(this.menu.sections, url);
    if (menuItemWithUrl) {
      this.setSelected(url);
      this.setVisitedUrls(menuItemWithUrl.url);
    }
  }

  public toggleMenu(): void {
    this.menu.open = !this.menu.open;
  }

  public refreshCustomer(): void {
    window.location.reload();
  }

  public setCurrentSection(currentUrl: string): void {
    this.menu.sections.forEach(section => {
      section.open = section.children.some((child: AdvisorMenuItem) =>
        currentUrl.startsWith(child.url)
      );
    });
  }

  private setVisitedUrls(url: string): void {
    this.advisorMenuProgressService.addProgress(url);
    this.menu.sections = this.advisorMenuProgressService.setMenuItemsProgress(
      this.menu.sections
    );
  }

  public setSelected(currentUrl: string): void {
    this.menu.sections.forEach((section: AdvisorMenuSection) => {
      section.children.forEach((child: AdvisorMenuItem) => {
        child.selected = false;
      });
    });
    this.findMenuItemWithUrl(this.menu.sections, currentUrl).selected = true;
  }

  public onSelectSection(menuSection: AdvisorMenuSection): void {
    const isOpen = menuSection.open;

    this.menu.sections.forEach((section: AdvisorMenuSection) => {
      section.open = false;
    });
    menuSection.open = !isOpen;
  }

  public navigate(menuItem: AdvisorMenuItem): void {
    if (menuItem.url) {
      this.router.navigate([menuItem.url]);
    }
  }

  public navigateToPro(): void {
    window.location.href = this.menu.proLink;
  }

  private findMenuItemWithUrl(
    menuSections: AdvisorMenuSection[],
    url: string
  ): AdvisorMenuItem | undefined {
    let result;
    for (const menuSection of menuSections) {
      for (const child of menuSection.children) {
        if (child.url === url) {
          result = child;
          break;
        }
      }
    }
    return result;
  }
}
