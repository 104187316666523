import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CombineSavingsSummaryService } from '../../shared/combine-savings/summary/combine-savings-summary.service';
import {
  EksternOverfoerselAnbefaling,
  ExternaltransferStore,
  InForceSelection,
  MaeglerInfo,
  PensionsKundeGenerelleData,
  PensionskundeStore,
  RecommendationShortInfo
} from '@pfa/gen';
import {
  CombineSavingsAbility,
  combineSavingsGuideName,
  CombineSavingsNavigationEvent,
  CombineSavingsSummary,
  CombineSavingsSummaryType,
  TrackingStepType
} from '@mitpfa/shared/combine-savings/combine-savings.model';
import { CombineSavingsNavigationService } from '@mitpfa/shared/combine-savings/combine-savings-navigation.service';
import { GuideStepOther, GuideStepType, GuideTrackingService } from '@pfa/core';

@Injectable()
export class CombineSavingsService {
  get isReceivedAgreementValid(): boolean {
    return this._isReceivedAgreementValid;
  }

  get broker(): MaeglerInfo {
    return this._broker;
  }

  get guideCompletedDate(): Date | null {
    return this._guideCompletedDate;
  }

  get externalTransferData(): EksternOverfoerselAnbefaling | null {
    return this._externalTransferData;
  }

  get combineSavingsSummary(): CombineSavingsSummary {
    return this._combineSavingsSummary;
  }

  get recommendationShortInfo(): RecommendationShortInfo {
    return this._recommendationShortInfo;
  }

  get pensionCustomer(): PensionsKundeGenerelleData {
    return this._pensionCustomer;
  }

  private _isReceivedAgreementValid: boolean;
  private _broker: MaeglerInfo;
  private _pensionCustomer: PensionsKundeGenerelleData;
  private _recommendationShortInfo: RecommendationShortInfo;
  private _combineSavingsSummary: CombineSavingsSummary;
  private _externalTransferData: EksternOverfoerselAnbefaling | null;
  private _guideCompletedDate: Date | null;

  constructor(
    private readonly navigationService: CombineSavingsNavigationService,
    private readonly pensionskundeStore: PensionskundeStore,
    private readonly combineSavingsSummaryService: CombineSavingsSummaryService,
    private readonly trackingService: GuideTrackingService,
    private readonly externaltransferStore: ExternaltransferStore
  ) {}

  init({
    pensionCustomer,
    broker,
    receivedAgreementStatus
  }: {
    pensionCustomer: PensionsKundeGenerelleData;
    broker: MaeglerInfo;
    receivedAgreementStatus: { modtagendeAftalegyldig: boolean };
  }) {
    this.applyPensionCustomerData(pensionCustomer);
    this._broker = broker;
    this._isReceivedAgreementValid =
      receivedAgreementStatus?.modtagendeAftalegyldig;
  }

  fetchPensionsCustomer(): Observable<PensionsKundeGenerelleData> {
    return this.pensionskundeStore.pensionskundeGet(true).pipe(
      tap((pensionCustomer: PensionsKundeGenerelleData) => {
        this.applyPensionCustomerData(pensionCustomer);
      })
    );
  }

  fetchRecommendationShortInfo(): Observable<RecommendationShortInfo> {
    return this.externaltransferStore
      .externaltransferRecommendationShortInfoGet()
      .pipe(
        tap((recommendedShortInfo: RecommendationShortInfo) => {
          this._recommendationShortInfo = recommendedShortInfo;
          this._combineSavingsSummary =
            this.combineSavingsSummaryService.getCombineSavingsSummary({
              overallCategory: this._recommendationShortInfo.overallCategory,
              transferredPoliciesUids: [],
              showErrorOnSummary: false,
              declineCreateLead: false
            });
          this.navigationService.setCombineSavingsPath(
            recommendedShortInfo.overallCategory
          );
        })
      );
  }

  fetchExternalTransferData(
    recommendationShortInfo: RecommendationShortInfo
  ): Observable<EksternOverfoerselAnbefaling> {
    this._externalTransferData = null;
    return this.externaltransferStore.externaltransferGet().pipe(
      tap((externalTransferData: EksternOverfoerselAnbefaling) => {
        this._externalTransferData = externalTransferData;

        const path = this.allowCustomerPath(externalTransferData)
          ? 'TRANSFERABLE_BY_CUSTOMER'
          : recommendationShortInfo.overallCategory;

        this.navigationService.setCombineSavingsPath(path);
      })
    );
  }

  allowCustomerPath(
    externalTransferData: EksternOverfoerselAnbefaling
  ): boolean {
    const yellowPolicies = externalTransferData.policer.filter(
      policy =>
        policy.transferableCategory === 'TRANSFERABLE_BY_ADVISOR' &&
        !policy.advarsler.includes('HAR_INDBETALING')
    );

    const redPolicies = externalTransferData.policer.filter(
      policy => policy.transferableCategory === 'NOT_TRANSFERABLE'
    );

    let blockedForCustomer = true;

    if (this.pensionCustomer.isRaadgiver) {
      blockedForCustomer = redPolicies.length > 0;
    } else {
      blockedForCustomer = redPolicies.length > 0 || yellowPolicies.length > 0;
    }

    return !blockedForCustomer;
  }

  updateSummary($event: CombineSavingsNavigationEvent): void {
    this._combineSavingsSummary =
      this.combineSavingsSummaryService.getCombineSavingsSummary({
        overallCategory: this._recommendationShortInfo?.overallCategory,
        transferredPoliciesUids: $event.transferredPoliciesUids ?? [],
        showErrorOnSummary: $event.showErrorOnSummary ?? false,
        declineCreateLead: $event.declineCreateLead ?? false
      });
  }

  private applyPensionCustomerData(
    pensionCustomer: PensionsKundeGenerelleData
  ) {
    this._pensionCustomer = pensionCustomer;
    const inForceSelection: InForceSelection | undefined =
      pensionCustomer?.inForceSelections?.find(
        (selection: { inForceSelectionType: string }) =>
          selection.inForceSelectionType === 'ExternalTransfer'
      );
    this._guideCompletedDate = inForceSelection
      ? new Date(inForceSelection.timestamp ?? '')
      : null;
  }

  trackGuide() {
    const trackingStepType =
      this.navigationService.getCurrentTrackingStepType();
    const trackingStepName =
      this.navigationService.getCurrentTrackingStepName();
    switch (trackingStepType) {
      case TrackingStepType.Start:
        this.trackingService.trackGuideStart(
          combineSavingsGuideName,
          trackingStepName,
          GuideStepType.MANDATORY
        );
        break;
      case TrackingStepType.StepAcceptTransfer:
        this.trackingService.trackGuideStep(
          combineSavingsGuideName,
          trackingStepName,
          GuideStepType.OPTIONAL,
          this.findGuideStepAcceptTransferOther()
        );
        break;
      case TrackingStepType.StepTransferLead:
      case TrackingStepType.Consent:
        this.trackingService.trackGuideStep(
          combineSavingsGuideName,
          trackingStepName,
          GuideStepType.OPTIONAL
        );
        break;
      case TrackingStepType.Complete:
        if (
          this._combineSavingsSummary.combineSavingsResult !==
          CombineSavingsSummaryType.errorOccurred
        ) {
          this.trackingService.trackGuideComplete(
            combineSavingsGuideName,
            trackingStepName,
            GuideStepType.MANDATORY,
            this.findGuideCompleteOther()
          );
        } else {
          this.trackingService.trackGuideStep(
            combineSavingsGuideName,
            'CYS error',
            GuideStepType.OPTIONAL
          );
        }
        break;
    }
  }

  private findGuideStepAcceptTransferOther(): GuideStepOther {
    const transferablePolicies = this._externalTransferData?.policer?.filter(
      policy => policy.transferableCategory === 'TRANSFERABLE_BY_CUSTOMER'
    );
    if (transferablePolicies?.length === 0) {
      return GuideStepOther.COMBINE_SAVINGS_UNDER_TRANSFER;
    } else {
      return GuideStepOther.UNDEFINED;
    }
  }

  private findGuideCompleteOther(): GuideStepOther {
    switch (this._combineSavingsSummary.combineSavingsResult) {
      case CombineSavingsSummaryType.policiesCombined:
        return GuideStepOther.COMBINE_SAVINGS_TRANSFER_INITIATED;
      case CombineSavingsSummaryType.policiesNotCombined:
        if (
          this._recommendationShortInfo.overallCategory ===
          CombineSavingsAbility.TransferableByCustomer
        ) {
          return GuideStepOther.COMBINE_SAVINGS_TRANSFER_DECLINED;
        } else {
          return GuideStepOther.COMBINE_SAVINGS_ADVISORY_DECLINED;
        }
      case CombineSavingsSummaryType.policiesTransferableByAdvisor:
        return GuideStepOther.COMBINE_SAVINGS_ADVISORY;
      case CombineSavingsSummaryType.policiesNotTransferable:
        return GuideStepOther.COMBINE_SAVINGS_TRANSFER_NOT_POSSIBLE;
      default:
        return GuideStepOther.UNDEFINED;
    }
  }
}
