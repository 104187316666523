<div class="row">
  <div class="col-lg-8 col-md-12">
    <h1 [coContent]="title" class="title"></h1>
    <div class="text--manchet" [coContent]="description"></div>
    <div *ngIf="isContentInline" class="inline-content">
      <ng-container *ngTemplateOutlet="content"> </ng-container>
    </div>

    <div *ngIf="expanded && !isContentInline" [@openClose] class="expanded">
      <div *ngTemplateOutlet="content"></div>
    </div>

    <div
      *ngIf="!isContentInline"
      class="read-more"
      (click)="expanded = !expanded"
    >
      <span *ngIf="!expanded">
        <co-icon-maximize class="icon-fill__small--grey"></co-icon-maximize>
        <span [coContent]="'DL.BE01.C62'"></span>
      </span>

      <span *ngIf="expanded">
        <co-icon-minimize class="icon-fill__small--grey"></co-icon-minimize>
        <span [coContent]="'DL.BE01.C63'"></span>
      </span>
    </div>
  </div>
</div>
<ng-template #content><ng-content></ng-content></ng-template>
