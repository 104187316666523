<svg
  version="1.1"
  id="Layer_1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  x="0px"
  y="0px"
  viewBox="0 0 32 32"
  style="enable-background: new 0 0 32 32"
  xml:space="preserve"
  [attr.data-fill]="fill"
  [attr.data-color]="color"
  [attr.data-border]="border"
>
  <g id="Canvas">
    <circle id="Large_circle" class="st0" cx="16" cy="16" r="16" />
    <circle id="Small_circle" class="st0" cx="16" cy="16" r="12" />
  </g>
  <g id="Icon">
    <circle id="Small_circle-2" class="st1" cx="16" cy="16" r="10" />
    <polyline
      id="Checkmark"
      class="st2"
      points="20.1,13.2 14.6,18.8 11.9,16 	"
    />
  </g>
</svg>
