<ng-container [ngSwitch]="modalType">
  <mitpfa-investment-read-more-pfa-invest
    *ngSwitchCase="'PFA_INVEST'"
  ></mitpfa-investment-read-more-pfa-invest>
  <mitpfa-investment-read-more-you-invest
    *ngSwitchCase="'YOU_INVEST'"
  ></mitpfa-investment-read-more-you-invest>
  <mitpfa-investment-read-more-climate
    *ngSwitchCase="'CLIMATE'"
  ></mitpfa-investment-read-more-climate>
</ng-container>
