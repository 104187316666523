<div class="headline" [ngClass]="{ active: active }">
  <co-icon-circle-checkmark
    *ngIf="isComplete && !toggleable"
    class="indicator-icon"
    [ngClass]="{ visible: isComplete }"
    color="white"
  ></co-icon-circle-checkmark>
  <co-icon-doubt
    *ngIf="!isComplete"
    class="indicator-icon warning"
    [ngClass]="{ visible: isDirty }"
  ></co-icon-doubt>
  <mat-slide-toggle
    *ngIf="toggleable"
    [color]="'accent'"
    [checked]="isToggled"
    (change)="enableSelect.emit($event)"
  ></mat-slide-toggle>
  <div class="headline-title">
    <div class="text--emphasized">
      <span *ngIf="subHeadline" class="sub-title"> {{ subHeadline }}: </span>
      {{ headline | content }}
    </div>
    <div *ngIf="additionalInfo" class="text--mark">{{ additionalInfo }}</div>
  </div>
  <co-icon-arrow-down
    *ngIf="toggleable && !active"
    (click)="active = !active"
    class="icon-fill__small--grey"
  ></co-icon-arrow-down>
  <co-icon-arrow-up
    *ngIf="toggleable && active"
    (click)="active = !active"
    class="icon-fill__small--grey"
  ></co-icon-arrow-up>
  <button
    *ngIf="isActionVisible"
    mat-raised-button
    class="button-primary--level3 edit-button"
    (click)="handleEdit()"
  >
    <span [coContent]="actionLabel"></span>
  </button>
</div>
<div
  *ngIf="active"
  class="accordion-content"
  [ngClass]="{ active: active }"
  [@openClose]="{
    value: '',
    params: {
      delay: OPEN_CLOSE_ANIMATION_DELAY
    }
  }"
>
  <div class="question-wrapper">
    <ng-content></ng-content>
  </div>
</div>
