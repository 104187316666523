<div class="limit-screen-width">
  <mitpfa-advantage-header
    title="DL.BO01.C160"
    subTitle="DL.BO01.C169"
    text="DL.BO01.C170"
    imgUrl="https://pfa.dk/-/media/mitpfa-banner/fordelssider_pfabolig800x439.jpg"
  >
  </mitpfa-advantage-header>

  <h3 class="sub-title" coContent="DL.BO01.C171"></h3>

  <div class="row">
    <div class="col-md-3 col-sm-12 housing-img housing-img1"></div>
    <div class="col-md-3 col-sm-12 housing-txt">
      <p coContent="DL.BO01.C172"></p>
      <ul class="advantages-list">
        <li>
          <a
            class="link"
            coContent="DL.BO01.C173"
            href="https://pfa.dk/privat/kundefordele/pfa-boliger/"
            target="_blank"
            data-test-id="advantage-housing-pfa-boliger-link"
          ></a
          >&nbsp;
          <span coContent="DL.BO01.C174"></span>
        </li>
        <li>
          <a
            class="link"
            coContent="DL.BO01.C175"
            href="https://pfa.dk/privat/kundefordele/pfa-seniorboliger/"
            target="_blank"
            data-test-id="advantage-housing-pfa-seniorboliger-link"
          ></a
          >&nbsp;
          <span coContent="DL.BO01.C176"></span>
        </li>
        <li>
          <a
            class="link"
            coContent="DL.BO01.C177"
            href="https://pfa.dk/privat/kundefordele/pfa-kollegier/"
            target="_blank"
            data-test-id="advantage-housing-pfa-kollegier-link"
          ></a
          >&nbsp;
          <span coContent="DL.BO01.C178"></span>
        </li>
      </ul>
    </div>
    <div class="col-md-3 col-sm-12 housing-img housing-img2"></div>
    <div class="col-md-3 col-sm-12 housing-txt" coContent="DL.BO01.C179"></div>
  </div>

  <h3 class="sub-title" coContent="DL.BO01.C180"></h3>

  <co-accordion>
    <div coContent="DL.BO01.C168" class="accordion-header" customHeader></div>
    <div class="accordion-content">
      <p coContent="DL.BO01.C161"></p>
      <p class="text--bold" coContent="DL.BO01.C162"></p>
      <p coContent="DL.BO01.C163"></p>
      <p coContent="DL.BO01.C164"></p>
      <p class="text--bold" coContent="DL.BO01.C165"></p>
      <p coContent="DL.BO01.C166"></p>
      <p coContent="DL.BO01.C167"></p>
    </div>
  </co-accordion>

  <h3 class="sub-title align--center" coContent="DL.BO01.C181"></h3>
</div>

<div class="cta-cards">
  <div class="limit-screen-width">
    <div class="row">
      <div class="col-md-6">
        <div class="cta-card">
          <div class="cta-title text--bold" coContent="DL.BO01.C182"></div>
          <p class="cta-content text--manchet" coContent="DL.BO01.C183"></p>
          <a
            href="{{ isAdvisor ? '' : '/kollegier.html?persontype=MIG' }}"
            target="{{ isAdvisor ? '_self' : '_blank' }}"
            (click)="!isAdvisor && trackSignUp('sign up')"
            [ngClass]="{ 'cursor-default': isAdvisor }"
          >
            <button
              mat-raised-button
              class="button-primary"
              [disabled]="isAdvisor"
              coContent="DL.BO01.C187"
              data-test-id="advantage-housing-enlist-here-btn"
            ></button>
          </a>
          <div class="cta-action" coContent="DL.BO01.C184"></div>
        </div>
      </div>

      <div class="col-md-6">
        <div class="cta-card">
          <div class="cta-title text--bold" coContent="DL.BO01.C185"></div>
          <p class="cta-content text--manchet" coContent="DL.BO01.C186"></p>
          <a
            href="{{ isAdvisor ? '' : '/kollegier.html?persontype=FAMILIE' }}"
            target="{{ isAdvisor ? '_self' : '_blank' }}"
            (click)="!isAdvisor && trackSignUp('sign up child/grandchild')"
            [ngClass]="{ 'cursor-default': isAdvisor }"
          >
            <button
              mat-raised-button
              class="button-primary"
              [disabled]="isAdvisor"
              coContent="DL.BO01.C188"
              data-test-id="advantage-housing-enlist-children-or-grandchildren-here-btn"
            ></button
          ></a>
          <div class="cta-action" coContent="DL.BO01.C184"></div>
        </div>
      </div>
    </div>
  </div>
</div>
