/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const AnbefalingsomraadeSchema = JoiObj.string().valid(
        'FORSIKRING'
        ,
        'INVESTERING'
        ,
        'OPSPARING'
        
    )
.id('AnbefalingsomraadeSchema')

export type Anbefalingsomraade = 'FORSIKRING' | 'INVESTERING' | 'OPSPARING';

export const Anbefalingsomraade = {
    Forsikring: 'FORSIKRING' as Anbefalingsomraade,
    Investering: 'INVESTERING' as Anbefalingsomraade,
    Opsparing: 'OPSPARING' as Anbefalingsomraade
};

