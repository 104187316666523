<div mat-dialog-title>
  <h3 [coContent]="'DL.FF01.C01'"></h3>
  <a
    (click)="popup.close()"
    data-test-id="ST01-consentPopup-close"
    class="popup-close-button"
  ></a>
</div>

<mat-dialog-content>
  <div
    [hidden]="!(selectedMenu === 'forudsaetning')"
    [ngClass]="{ 'forbehold--selected-content-empty': selectedMenu === '' }"
  >
    <span id="forudsaetning_overskrift"></span>
    <h4 [coContent]="'DL.FF01.C05'"></h4>
    <p [coContent]="'DL.FF01.C08'"></p>
    <strong [coContent]="'DL.FF01.C09'"></strong>
    <p [coContent]="'DL.FF01.C10'" *ngIf="!isPfaPro"></p>
    <p [coContent]="'DL.FF01.C474'" *ngIf="isPfaPro"></p>
    <strong [coContent]="'DL.FF01.C11'"></strong>
    <p [coContent]="'DL.FF01.C12'"></p>
    <strong [coContent]="'DL.FF01.C13'"></strong>
    <p [coContent]="'DL.FF01.C14'"></p>
    <strong [coContent]="'DL.FF01.C17'"></strong>
    <p [coContent]="'DL.FF01.C18'"></p>
    <strong [coContent]="'DL.FF01.C19'"></strong>
    <p [coContent]="'DL.FF01.C20'"></p>
    <strong [coContent]="'DL.FF01.C21'"></strong>
    <p [coContent]="'DL.FF01.C22'"></p>
    <strong [coContent]="'DL.FF01.C23'"></strong>
    <p [coContent]="'DL.FF01.C24'"></p>
    <strong [coContent]="'DL.FF01.C25'"></strong>
    <p [coContent]="'DL.FF01.C26'"></p>
    <strong [coContent]="'DL.FF01.C249'"></strong>
    <p [coContent]="'DL.FF01.C281'"></p>
    <p [coContent]="'DL.FF01.C282'"></p>
  </div>

  <div [hidden]="!(selectedMenu === 'prognose')">
    <span id="prognose_overskrift"></span>
    <h4 [coContent]="'DL.FF01.C49'"></h4>
    <p [coContent]="'DL.FF01.C50'"></p>
    <strong [coContent]="'DL.FF01.C51'"></strong>
    <p [coContent]="'DL.FF01.C52'"></p>
    <strong [coContent]="'DL.FF01.C53'"></strong>
    <p [coContent]="'DL.FF01.C54'"></p>
    <strong [coContent]="'DL.FF01.C55'"></strong>
    <p><strong [coContent]="'DL.FF01.C570'"></strong></p>
    <p [coContent]="'DL.FF01.C56'"></p>

    <p [coContent]="'DL.FF01.C57'"></p>
    <strong [coContent]="'DL.FF01.C58'"></strong>
    <p [coContent]="'DL.FF01.C59'"></p>

    <strong [coContent]="'DL.FF01.C571'"></strong>
    <p [coContent]="'DL.FF01.C573'"></p>

    <table class="table--listing table--responsive">
      <thead class="hide--palm">
        <tr>
          <th class="first" [coContent]="'DL.FF01.C192'"></th>
          <th [coContent]="'DL.FF01.C197'" class="number-cell"></th>
          <th [coContent]="'DL.FF01.C257'" class="number-cell"></th>
        </tr>
      </thead>
      <tbody>
        <tr class="odd">
          <td [coContent]="'DL.FF01.C199'"></td>
          <td
            data-label="tableHeaders.pension_2019T2028"
            [coContent]="'DL.FF01.C200'"
            class="number-cell"
          ></td>
          <td
            data-label="tableHeaders.pension_2029"
            [coContent]="'DL.FF01.C201'"
            class="number-cell"
          ></td>
        </tr>
        <tr>
          <td [coContent]="'DL.FF01.C206'"></td>
          <td
            data-label="tableHeaders.pension_2019T2028"
            [coContent]="'DL.FF01.C207'"
            class="number-cell"
          ></td>
          <td
            data-label="tableHeaders.pension_2029"
            [coContent]="'DL.FF01.C208'"
            class="number-cell"
          ></td>
        </tr>
        <tr class="odd">
          <td [coContent]="'DL.FF01.C213'"></td>
          <td
            data-label="tableHeaders.pension_2019T2028"
            [coContent]="'DL.FF01.C214'"
            class="number-cell"
          ></td>
          <td
            data-label="tableHeaders.pension_2029"
            [coContent]="'DL.FF01.C215'"
            class="number-cell"
          ></td>
        </tr>
        <tr>
          <td [coContent]="'DL.FF01.C220'"></td>
          <td
            data-label="tableHeaders.pension_2019T2028"
            [coContent]="'DL.FF01.C221'"
            class="number-cell"
          ></td>
          <td
            data-label="tableHeaders.pension_2029"
            [coContent]="'DL.FF01.C222'"
            class="number-cell"
          ></td>
        </tr>
        <tr>
          <td [coContent]="'DL.FF01.C185'"></td>
          <td
            data-label="tableHeaders.pension_2019T2028"
            [coContent]="'DL.FF01.C186'"
            class="number-cell"
          ></td>
          <td
            data-label="tableHeaders.pension_2029"
            [coContent]="'DL.FF01.C187'"
            class="number-cell"
          ></td>
        </tr>
      </tbody>
    </table>
    <p [coContent]="'DL.FF01.C60'"></p>

    <strong [coContent]="'DL.FF01.C572'"></strong>
    <p [coContent]="'DL.FF01.C574'"></p>

    <strong [coContent]="'DL.FF01.C250'"></strong>
    <p [coContent]="'DL.FF01.C251'"></p>

    <table class="table--listing table--responsive">
      <thead class="hide--palm">
        <tr>
          <th class="first" [coContent]="'DL.FF01.C252'"></th>
          <th [coContent]="'DL.FF01.C255'" class="number-cell"></th>
          <th [coContent]="'DL.FF01.C256'" class="number-cell"></th>
          <th [coContent]="'DL.FF01.C257'" class="number-cell"></th>
        </tr>
      </thead>
      <tbody>
        <tr class="odd">
          <td [coContent]="'DL.FF01.C253'"></td>
          <td
            data-label="tableHeaders.pension_2019"
            [coContent]="'DL.FF01.C261'"
            class="number-cell"
          ></td>
          <td
            data-label="tableHeaders.pension_2020T2028"
            [coContent]="'DL.FF01.C262'"
            class="number-cell"
          ></td>
          <td
            data-label="tableHeaders.pension_2029"
            [coContent]="'DL.FF01.C263'"
            class="number-cell"
          ></td>
        </tr>
      </tbody>
    </table>

    <div>&nbsp;</div>
    <strong [coContent]="'DL.FF01.C61'"></strong>
    <p [coContent]="'DL.FF01.C62'"></p>

    <table class="table--listing table--responsive">
      <thead class="hide--palm">
        <tr>
          <th class="first" [coContent]="'DL.FF01.C227'"></th>
          <th [coContent]="'DL.FF01.C228'" class="number-cell"></th>
          <th [coContent]="'DL.FF01.C229'" class="number-cell"></th>
          <th [coContent]="'DL.FF01.C230'" class="number-cell"></th>
        </tr>
      </thead>
      <tbody>
        <tr class="odd">
          <td [coContent]="'DL.FF01.C234'"></td>
          <td
            data-label="tableHeaders.pension_2019"
            [coContent]="'DL.FF01.C235'"
            class="number-cell"
          ></td>
          <td
            data-label="tableHeaders.pension_2020T2028"
            [coContent]="'DL.FF01.C236'"
            class="number-cell"
          ></td>
          <td
            data-label="tableHeaders.pension_2029"
            [coContent]="'DL.FF01.C237'"
            class="number-cell"
          ></td>
        </tr>
      </tbody>
    </table>
    <p [coContent]="'DL.FF01.C63'"></p>
    <div [coContent]="'DL.FF01.C283'"></div>
    <ul class="list--unnumbered">
      <li [coContent]="'DL.FF01.C285'"></li>
    </ul>

    <div>&nbsp;</div>
    <strong [coContent]="'DL.FF01.C64'"></strong>
    <p [coContent]="'DL.FF01.C65'"></p>
  </div>

  <div [hidden]="!(selectedMenu === 'forsikring')">
    <span id="forsikring_overskrift"></span>
    <h4 [coContent]="'DL.FF01.C66'"></h4>
    <p [coContent]="'DL.FF01.C67'"></p>
    <strong [coContent]="'DL.FF01.C68'"></strong>
    <p [coContent]="'DL.FF01.C69'"></p>
    <strong [coContent]="'DL.FF01.C70'"></strong>
    <p [coContent]="'DL.FF01.C71'"></p>
    <strong [coContent]="'DL.FF01.C72'"></strong>
    <p [coContent]="'DL.FF01.C73'"></p>
  </div>

  <div [hidden]="!(selectedMenu === 'loebende')">
    <span id="loebende_overskrift"></span>
    <h4 [coContent]="'DL.FF01.C74'"></h4>
    <p [coContent]="'DL.FF01.C75'"></p>

    <table class="table--listing table--responsive">
      <thead class="hide--palm">
        <tr>
          <th class="first" [coContent]="'DL.FF01.C241'"></th>
          <th [coContent]="'DL.FF01.C242'" class="number-cell"></th>
          <th [coContent]="'DL.FF01.C243'" class="number-cell"></th>
        </tr>
      </thead>
      <tbody>
        <tr class="odd">
          <td [coContent]="'DL.FF01.C244'"></td>
          <td
            data-label="tableHeaders.loebendepension_med"
            [coContent]="'DL.FF01.C245'"
            class="number-cell"
          ></td>
          <td
            data-label="tableHeaders.loebendepension_uden"
            [coContent]="'DL.FF01.C246'"
            class="number-cell"
          ></td>
        </tr>
      </tbody>
    </table>
    <p [coContent]="'DL.FF01.C76'"></p>
    <strong [coContent]="'DL.FF01.C77'"></strong>
    <p [coContent]="'DL.FF01.C78'"></p>
    <strong [coContent]="'DL.FF01.C79'"></strong>
    <p [coContent]="'DL.FF01.C80'"></p>
    <strong [coContent]="'DL.FF01.C81'"></strong>
    <p [coContent]="'DL.FF01.C82'"></p>
    <strong [coContent]="'DL.FF01.C83'"></strong>
    <p [coContent]="'DL.FF01.C84'"></p>
    <strong [coContent]="'DL.FF01.C85'"></strong>
    <p [coContent]="'DL.FF01.C86'"></p>
  </div>

  <div [hidden]="!(selectedMenu === 'pensionstal')">
    <span id="pensionstal_overskrift"></span>
    <h4 [coContent]="'DL.FF01.C247'"></h4>
    <p [coContent]="'DL.FF01.C267'"></p>
    <p [coContent]="'DL.FF01.C268'"></p>
    <p [coContent]="'DL.FF01.C269'"></p>
    <p [coContent]="'DL.FF01.C270'"></p>
    <p [coContent]="'DL.FF01.C271'"></p>
    <p [coContent]="'DL.FF01.C272'"></p>

    <strong [coContent]="'DL.FF01.C273'"></strong>
    <p [coContent]="'DL.FF01.C289'"></p>

    <strong [coContent]="'DL.FF01.C290'"></strong>
    <div [coContent]="'DL.FF01.C291'"></div>
    <ul class="list--unnumbered">
      <li [coContent]="'DL.FF01.C292'"></li>
      <li [coContent]="'DL.FF01.C293'"></li>
      <li [coContent]="'DL.FF01.C294'"></li>
      <li [coContent]="'DL.FF01.C295'"></li>
      <li [coContent]="'DL.FF01.C296'"></li>
    </ul>

    <strong [coContent]="'DL.FF01.C297'"></strong>
    <p [coContent]="'DL.FF01.C298'"></p>
    <div [coContent]="'DL.FF01.C299'"></div>
    <ul class="list--unnumbered">
      <li [coContent]="'DL.FF01.C300'"></li>
      <li [coContent]="'DL.FF01.C301'"></li>
      <li [coContent]="'DL.FF01.C302'"></li>
      <li [coContent]="'DL.FF01.C303'"></li>
      <li [coContent]="'DL.FF01.C304'"></li>
      <li [coContent]="'DL.FF01.C305'"></li>
    </ul>
    <p [coContent]="'DL.FF01.C306'"></p>

    <strong [coContent]="'DL.FF01.C307'"></strong>
    <p [coContent]="'DL.FF01.C308'"></p>
    <div [coContent]="'DL.FF01.C309'"></div>
    <ul class="list--unnumbered">
      <li [coContent]="'DL.FF01.C310'"></li>
      <li [coContent]="'DL.FF01.C311'"></li>
      <li [coContent]="'DL.FF01.C312'"></li>
      <li [coContent]="'DL.FF01.C313'"></li>
      <li [coContent]="'DL.FF01.C314'"></li>
      <li [coContent]="'DL.FF01.C315'"></li>
      <li [coContent]="'DL.FF01.C316'"></li>
      <li [coContent]="'DL.FF01.C317'"></li>
      <li [coContent]="'DL.FF01.C318'"></li>
      <li [coContent]="'DL.FF01.C319'"></li>
      <li [coContent]="'DL.FF01.C320'"></li>
      <li [coContent]="'DL.FF01.C321'"></li>
      <li [coContent]="'DL.FF01.C322'"></li>
      <li [coContent]="'DL.FF01.C323'"></li>
    </ul>
  </div>

  <div [hidden]="!(selectedMenu === 'forsikringsguide')">
    <span id="forsikringsguide_overskrift"></span>
    <h4 [coContent]="'DL.FF01.C248'"></h4>
    <p [coContent]="'DL.FF01.C274'"></p>
    <p [coContent]="'DL.FF01.C549'"></p>
    <p [coContent]="'DL.FF01.C553'"></p>
    <p [coContent]="'DL.FF01.C275'"></p>
    <div [coContent]="'DL.FF01.C276'"></div>
    <ul class="list--unnumbered">
      <li [coContent]="'DL.FF01.C277'"></li>
      <li [coContent]="'DL.FF01.C278'"></li>
      <li [coContent]="'DL.FF01.C279'"></li>
      <li [coContent]="'DL.FF01.C280'"></li>
      <li [coContent]="'DL.FF01.C326'"></li>
      <li [coContent]="'DL.FF01.C327'"></li>
      <li [coContent]="'DL.FF01.C545'"></li>
      <li [coContent]="'DL.FF01.C546'"></li>
      <li [coContent]="'DL.FF01.C547'"></li>
    </ul>
    <p [coContent]="'DL.FF01.C551'"></p>
    <p [coContent]="'DL.FF01.C473'"></p>
    <p [coContent]="'DL.FF01.C329'"></p>
    <p [coContent]="'DL.FF01.C331'" *ngIf="!taeAllInclusive"></p>
    <p [coContent]="'DL.FF01.C575'" *ngIf="taeAllInclusive"></p>
    <p [coContent]="'DL.FF01.C332'"></p>
    <p [coContent]="'DL.FF01.C552'"></p>
    <p [coContent]="'DL.FF01.C550'"></p>
    <p [coContent]="'DL.FF01.C554'"></p>

    <h4 [coContent]="'DL.FF01.C548'" class="spacing-unit--over"></h4>

    <strong [coContent]="'DL.FF01.C333'"></strong>
    <p [coContent]="'DL.FF01.C334'"></p>
    <p [coContent]="'DL.FF01.C335'"></p>
    <p [coContent]="'DL.FF01.C336'"></p>
    <p [coContent]="'DL.FF01.C337'"></p>
    <div [coContent]="'DL.FF01.C338'"></div>
    <ul class="list--unnumbered">
      <li [coContent]="'DL.FF01.C339'"></li>
      <li [coContent]="'DL.FF01.C341'"></li>
    </ul>
    <p [coContent]="'DL.FF01.C342'"></p>

    <strong [coContent]="'DL.FF01.C343'"></strong>
    <p [coContent]="'DL.FF01.C344'"></p>
    <p [coContent]="'DL.FF01.C345'"></p>
    <p [coContent]="'DL.FF01.C346'"></p>
    <p [coContent]="'DL.FF01.C347'"></p>
    <p [coContent]="'DL.FF01.C348'"></p>
    <p [coContent]="'DL.FF01.C349'"></p>
    <div [coContent]="'DL.FF01.C350'"></div>
    <ul class="list--unnumbered">
      <li [coContent]="'DL.FF01.C351'"></li>
      <li [coContent]="'DL.FF01.C352'"></li>
      <li [coContent]="'DL.FF01.C353'"></li>
      <li [coContent]="'DL.FF01.C354'"></li>
      <li [coContent]="'DL.FF01.C355'"></li>
    </ul>
    <p [coContent]="'DL.FF01.C356'"></p>
  </div>

  <div [hidden]="!(selectedMenu === 'investering')">
    <span id="investering_overskrift"></span>
    <h4 [coContent]="'DL.FF01.C357'"></h4>

    <strong class="text--uppercase" [coContent]="'DL.FF01.C358'"></strong>
    <p [coContent]="'DL.FF01.C359'"></p>
    <strong [coContent]="'DL.FF01.C360'"></strong>
    <p [coContent]="'DL.FF01.C361'"></p>
    <strong [coContent]="'DL.FF01.C362'"></strong>
    <p [coContent]="'DL.FF01.C363'"></p>
    <p [coContent]="'DL.FF01.C364'"></p>
    <strong [coContent]="'DL.FF01.C365'"></strong>
    <p [coContent]="'DL.FF01.C366'"></p>
    <strong [coContent]="'DL.FF01.C367'"></strong>
    <img
      src="/assets/images/eksempel_paa_aendring.png"
      style="width: 100%; max-width: 475px"
    />
    <br />
    <strong [coContent]="'DL.FF01.C368'"></strong>
    <p [coContent]="'DL.FF01.C369'"></p>
    <strong class="text--uppercase" [coContent]="'DL.FF01.C370'"></strong>
    <p [coContent]="'DL.FF01.C371'"></p>
    <strong [coContent]="'DL.FF01.C372'"></strong>
    <p [coContent]="'DL.FF01.C373'"></p>
    <p [coContent]="'DL.FF01.C374'"></p>
    <p [coContent]="'DL.FF01.C375'"></p>
    <p [coContent]="'DL.FF01.C376'"></p>
    <p [coContent]="'DL.FF01.C377'"></p>
    <strong [coContent]="'DL.FF01.C378'"></strong>
    <p [coContent]="'DL.FF01.C379'"></p>
    <p [coContent]="'DL.FF01.C380'"></p>
    <p [coContent]="'DL.FF01.C381'"></p>
    <p [coContent]="'DL.FF01.C382'"></p>
    <p [coContent]="'DL.FF01.C383'"></p>
  </div>

  <div [hidden]="!(selectedMenu === 'genkoeb')">
    <span id="genkoeb_overskrift"></span>
    <h4 [coContent]="'DL.FF01.C384'"></h4>
    <strong [coContent]="'DL.FF01.C390'"></strong>
    <p [coContent]="'DL.FF01.C391'"></p>
    <strong [coContent]="'DL.FF01.C394'"></strong>
    <p [coContent]="'DL.FF01.C385'"></p>
    <strong [coContent]="'DL.FF01.C386'"></strong>
    <p *ngIf="flags && !flags.paragraf39" [coContent]="'DL.FF01.C387'"></p>
    <p *ngIf="flags && flags.paragraf39" [coContent]="'DL.FF01.C555'"></p>
    <strong [coContent]="'DL.FF01.C388'"></strong>
    <p [coContent]="'DL.FF01.C389'"></p>
    <strong [coContent]="'DL.FF01.C392'"></strong>
    <p [coContent]="'DL.FF01.C393'"></p>
  </div>

  <div
    [hidden]="!(selectedMenu === 'pensionsplan')"
    *ngIf="forbeholdInitialized && pensionsplanForbehold"
  >
    <span id="pensionsplan_overskrift"></span>
    <h4 [coContent]="'DL.FF01.C395'"></h4>
    <p [coContent]="'DL.FF01.C476'"></p>
    <p [coContent]="'DL.FF01.C477'"></p>
    <p [coContent]="'DL.FF01.C478'"></p>
    <p [coContent]="'DL.FF01.C479'"></p>
    <p [coContent]="'DL.FF01.C480'"></p>
    <br />
    <strong class="text--uppercase" [coContent]="'DL.FF01.C481'"></strong>
    <p [coContent]="'DL.FF01.C482'"></p>
    <p [coContent]="'DL.FF01.C483'"></p>
    <p [coContent]="'DL.FF01.C484'"></p>

    <strong [coContent]="'DL.FF01.C485'"></strong>
    <p [coContent]="'DL.FF01.C486'"></p>

    <strong [coContent]="'DL.FF01.C487'"></strong>
    <p [coContent]="'DL.FF01.C488'"></p>
    <ul class="list--unnumbered">
      <li [coContent]="'DL.FF01.C489'"></li>
      <li [coContent]="'DL.FF01.C490'"></li>
      <li [coContent]="'DL.FF01.C491'"></li>
      <li [coContent]="'DL.FF01.C492'"></li>
      <li [coContent]="'DL.FF01.C493'"></li>
      <li [coContent]="'DL.FF01.C494'"></li>
      <li [coContent]="'DL.FF01.C495'"></li>
      <li [coContent]="'DL.FF01.C496'"></li>
    </ul>

    <strong [coContent]="'DL.FF01.C497'"></strong>
    <p [coContent]="'DL.FF01.C498'"></p>
    <p [coContent]="'DL.FF01.C499'"></p>

    <ul class="list--unnumbered">
      <li [coContent]="'DL.FF01.C500'"></li>
      <li [coContent]="'DL.FF01.C501'"></li>
      <li
        [coContent]="'DL.FF01.C426'"
        [coContentSubst]="pensionsplanForbehold.beskatningAfAfkast | number"
      ></li>
      <li [coContent]="'DL.FF01.C502'"></li>
      <li
        [coContent]="'DL.FF01.C428'"
        [coContentSubst]="pensionsplanForbehold.afkastKundekapital | number"
      ></li>
      <li
        [coContent]="'DL.FF01.C429'"
        [coContentSubst]="pensionsplanForbehold.arbejdsmarkedsbidrag | number"
      ></li>
      <li
        [coContent]="'DL.FF01.C430'"
        [coContentSubst]="pensionsplanForbehold.inflation | number"
      ></li>
      <li [coContent]="'DL.FF01.C431'"></li>
      <li [coContent]="'DL.FF01.C432'"></li>
      <li [coContent]="'DL.FF01.C504'"></li>
      <li [coContent]="'DL.FF01.C505'"></li>
      <li [coContent]="'DL.FF01.C506'"></li>
      <li [coContent]="'DL.FF01.C507'"></li>
      <li [coContent]="'DL.FF01.C508'"></li>
      <li [coContent]="'DL.FF01.C509'"></li>
    </ul>
    <p [coContent]="'DL.FF01.C510'"></p>

    <strong class="text--uppercase" [coContent]="'DL.FF01.C511'"></strong>
    <p [coContent]="'DL.FF01.C512'"></p>
    <p [coContent]="'DL.FF01.C513'"></p>
    <p [coContent]="'DL.FF01.C516'"></p>
    <p [coContent]="'DL.FF01.C517'"></p>
    <p [coContent]="'DL.FF01.C518'"></p>
    <p [coContent]="'DL.FF01.C519'"></p>
    <p [coContent]="'DL.FF01.C520'"></p>
    <p [coContent]="'DL.FF01.C521'"></p>

    <strong [coContent]="'DL.FF01.C522'"></strong>
    <p [coContent]="'DL.FF01.C523'"></p>

    <strong class="text--uppercase" [coContent]="'DL.FF01.C524'"></strong>
    <br />
    <strong [coContent]="'DL.FF01.C525'"></strong>
    <p [coContent]="'DL.FF01.C526'"></p>

    <ul class="list--unnumbered">
      <li [coContent]="'DL.FF01.C527'"></li>
      <li [coContent]="'DL.FF01.C528'"></li>
      <li [coContent]="'DL.FF01.C529'"></li>
      <li [coContent]="'DL.FF01.C530'"></li>
      <li [coContent]="'DL.FF01.C531'"></li>
      <li [coContent]="'DL.FF01.C532'"></li>
      <li [coContent]="'DL.FF01.C533'"></li>
    </ul>

    <strong [coContent]="'DL.FF01.C534'"></strong>
    <p [coContent]="'DL.FF01.C535'"></p>
    <p [coContent]="'DL.FF01.C536'"></p>
    <p [coContent]="'DL.FF01.C537'"></p>
    <p [coContent]="'DL.FF01.C538'"></p>
    <p [coContent]="'DL.FF01.C539'"></p>
    <p [coContent]="'DL.FF01.C540'"></p>
    <p [coContent]="'DL.FF01.C541'"></p>

    <table class="table--listing table--responsive">
      <thead class="hide--palm">
        <tr>
          <th class="first" [coContent]=""></th>
          <th [coContent]="'DL.FF01.C451'" class="number-cell"></th>
          <th [coContent]="'DL.FF01.C452'" class="number-cell"></th>
          <th [coContent]="'DL.FF01.C453'" class="number-cell"></th>
          <th [coContent]="'DL.FF01.C454'" class="number-cell"></th>
          <th [coContent]="'DL.FF01.C455'" class="number-cell"></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td [coContent]="'DL.FF01.C456'"></td>
          <td data-label="tableHeaders.mobil_samfund" class="number-cell">
            {{
              pensionsplanForbehold.aktierSamfundsforudsaetning
                | number: '1.2-2'
            }}
          </td>
          <td data-label="tableHeaders.mobil_omkostninger" class="number-cell">
            {{ pensionsplanForbehold.aktierOmkostning | number: '1.2-2' }}
          </td>
          <td data-label="tableHeaders.mobil_skat" class="number-cell">
            {{ pensionsplanForbehold.aktierSkat | number: '1.2-2' }}
          </td>
          <td data-label="tableHeaders.mobil_inflation" class="number-cell">
            {{ pensionsplanForbehold.aktierInflation | number: '1.2-2' }}
          </td>
          <td data-label="tableHeaders.mobil_nettorente" class="number-cell">
            {{ nettorenter.aktier | number: '1.2-2' }}
          </td>
        </tr>
        <tr class="odd" *ngIf="pensionsplanForbehold">
          <td [coContent]="'DL.FF01.C457'"></td>
          <td data-label="tableHeaders.mobil_samfund" class="number-cell">
            {{
              pensionsplanForbehold.obligationerSamfundsforudsaetning
                | number: '1.2-2'
            }}
          </td>
          <td data-label="tableHeaders.mobil_omkostninger" class="number-cell">
            {{ pensionsplanForbehold.obligationerOmkostning | number: '1.2-2' }}
          </td>
          <td data-label="tableHeaders.mobil_skat" class="number-cell">
            {{ pensionsplanForbehold.obligationerSkat | number: '1.2-2' }}
          </td>
          <td data-label="tableHeaders.mobil_inflation" class="number-cell">
            {{ pensionsplanForbehold.obligationerInflation | number: '1.2-2' }}
          </td>
          <td data-label="tableHeaders.mobil_nettorente" class="number-cell">
            {{ nettorenter.obligationer | number: '1.2-2' }}
          </td>
        </tr>
        <tr *ngIf="pensionsplanForbehold">
          <td [coContent]="'DL.FF01.C458'"></td>
          <td data-label="tableHeaders.mobil_samfund" class="number-cell">
            {{
              pensionsplanForbehold.kontanterSamfundsforudsaetning
                | number: '1.2-2'
            }}
          </td>
          <td data-label="tableHeaders.mobil_omkostninger" class="number-cell">
            {{ pensionsplanForbehold.kontanterOmkostning | number: '1.2-2' }}
          </td>
          <td data-label="tableHeaders.mobil_skat" class="number-cell">
            {{ pensionsplanForbehold.kontanterSkat | number: '1.2-2' }}
          </td>
          <td data-label="tableHeaders.mobil_inflation" class="number-cell">
            {{ pensionsplanForbehold.kontanterInflation | number: '1.2-2' }}
          </td>
          <td data-label="tableHeaders.mobil_nettorente" class="number-cell">
            {{ nettorenter.pengemarked | number: '1.2-2' }}
          </td>
        </tr>
        <tr class="odd" *ngIf="pensionsplanForbehold">
          <td [coContent]="'DL.FF01.C459'"></td>
          <td data-label="tableHeaders.mobil_samfund" class="number-cell">
            <span>(*)</span>
            {{
              pensionsplanForbehold.boligSamfundsforudsaetning | number: '1.2-2'
            }}
          </td>
          <td data-label="tableHeaders.mobil_omkostninger" class="number-cell">
            {{ pensionsplanForbehold.boligOmkostning | number: '1.2-2' }}
          </td>
          <td data-label="tableHeaders.mobil_skat" class="number-cell">
            {{ pensionsplanForbehold.boligSkat | number: '1.2-2' }}
          </td>
          <td data-label="tableHeaders.mobil_inflation" class="number-cell">
            {{ pensionsplanForbehold.boligInflation | number: '1.2-2' }}
          </td>
          <td data-label="tableHeaders.mobil_nettorente" class="number-cell">
            {{ nettorenter.fastejendom | number: '1.2-2' }}
          </td>
        </tr>
      </tbody>
    </table>
    <p [coContent]="'DL.FF01.C460'"></p>
  </div>
</mat-dialog-content>
