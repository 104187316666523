/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const HandelStatusTypeSchema = JoiObj.string().valid(
        'Indregnet'
        ,
        'Afventende'
        ,
        'Annulleret'
        
    )
.id('HandelStatusTypeSchema')

export type HandelStatusType = 'Indregnet' | 'Afventende' | 'Annulleret';

export const HandelStatusType = {
    Indregnet: 'Indregnet' as HandelStatusType,
    Afventende: 'Afventende' as HandelStatusType,
    Annulleret: 'Annulleret' as HandelStatusType
};

