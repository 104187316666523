/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HealthCaseSource } from './healthCaseSource';
import { HealthCaseSourceSchema } from './healthCaseSource';
import { HealthCaseId } from './healthCaseId';
import { HealthCaseIdSchema } from './healthCaseId';
import { Product } from './product';
import { ProductSchema } from './product';
import { HealthCaseStatus } from './healthCaseStatus';
import { HealthCaseStatusSchema } from './healthCaseStatus';
import * as JoiObj from "joi";


    export const HealthCaseSchema = JoiObj.object({
        cartItemIds:
                JoiObj.array().items(
        
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

        )
        

.required()


        ,
        createdAt:
            
                    JoiObj.object()
                


.required()


        ,
        healthCaseId:
            
        JoiObj.link('#HealthCaseIdSchema')


.required()


        ,
        healthCaseSource:
            
        JoiObj.link('#HealthCaseSourceSchema')


.required()


        ,
        healthCaseStatus:
            
        JoiObj.link('#HealthCaseStatusSchema')


.required()


        ,
        product:
            
        JoiObj.link('#ProductSchema')


.required()


        ,
        questionnaireExpiryDate:
            
                    JoiObj.object()
                


.required()


        
    })
                .shared(HealthCaseIdSchema)
                .shared(HealthCaseSourceSchema)
                .shared(HealthCaseStatusSchema)
                .shared(ProductSchema)
.id('HealthCaseSchema')

export interface HealthCase { 
    cartItemIds: Array<string>;
    createdAt: object;
    healthCaseId: HealthCaseId;
    healthCaseSource: HealthCaseSource;
    healthCaseStatus: HealthCaseStatus;
    product: Product;
    questionnaireExpiryDate: object;
}
export namespace HealthCase {
}


