import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PensionsinfoUtilService {
  public isAllowedPensionsinfoURL(url: string): boolean {
    return (
      url &&
      (url.startsWith('https://pensionsinfo.dk') ||
        url.startsWith('https://pensionsinfo-v3-test.dk') ||
        url.startsWith('https://pfandptl04.nonprod.pfaintern.dk:7073'))
    );
  }
}
