import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PfaFormModule } from '@pfaform';
import { CoreModule } from '@pfa/core';
import { DepositsMonthlyComponent } from './deposits-monthly.component';

@NgModule({
  imports: [CommonModule, PfaFormModule, CoreModule],
  declarations: [DepositsMonthlyComponent],
  exports: [DepositsMonthlyComponent]
})
export class DepositsMonthlyModule {}
