/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { LookupFieldType } from './lookupFieldType';
import { LookupFieldTypeSchema } from './lookupFieldType';
import * as JoiObj from "joi";


    export const LookupFieldSchema = JoiObj.object({
        lookupFieldType:
            
        JoiObj.link('#LookupFieldTypeSchema')


.required()


        ,
        value:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        
    })
                .shared(LookupFieldTypeSchema)
.id('LookupFieldSchema')

export interface LookupField { 
    lookupFieldType: LookupFieldType;
    value: string;
}
export namespace LookupField {
}


