<mitpfa-pension-check-header-section
  [headline]="'DL.NB01.C305'"
  [explanation]="'DL.NB01.C306'"
>
</mitpfa-pension-check-header-section>

<mitpfa-financial-situation-debt
  [financialSituation]="financialSituation"
  [pensionCustomer]="pensionCustomer"
  [form]="financialSituationForm"
></mitpfa-financial-situation-debt>

<div class="next-button">
  <button
    [disabled]="financialSituationForm.invalid"
    mat-raised-button
    (click)="closeDebt()"
    [coContent]="'DL.NB01.C316'"
  ></button>
</div>
