/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { IndbetalingForsikringsordningUdenFradragsret } from './indbetalingForsikringsordningUdenFradragsret';
import { IndbetalingForsikringsordningUdenFradragsretSchema } from './indbetalingForsikringsordningUdenFradragsret';
import { IndbetalingAldersopsparing } from './indbetalingAldersopsparing';
import { IndbetalingAldersopsparingSchema } from './indbetalingAldersopsparing';
import { IndbetalingRatepension } from './indbetalingRatepension';
import { IndbetalingRatepensionSchema } from './indbetalingRatepension';
import { EnhedType } from './enhedType';
import { EnhedTypeSchema } from './enhedType';
import { IndbetalingForsikringsordningUdenSkattekode } from './indbetalingForsikringsordningUdenSkattekode';
import { IndbetalingForsikringsordningUdenSkattekodeSchema } from './indbetalingForsikringsordningUdenSkattekode';
import { OpsparingFormFordeling } from './opsparingFormFordeling';
import { OpsparingFormFordelingSchema } from './opsparingFormFordeling';
import { BetalingFrekvens } from './betalingFrekvens';
import { BetalingFrekvensSchema } from './betalingFrekvens';
import { IndbetalingPensionsordningUdenFradragsret } from './indbetalingPensionsordningUdenFradragsret';
import { IndbetalingPensionsordningUdenFradragsretSchema } from './indbetalingPensionsordningUdenFradragsret';
import { IndbetalingLivsvarigLivspension } from './indbetalingLivsvarigLivspension';
import { IndbetalingLivsvarigLivspensionSchema } from './indbetalingLivsvarigLivspension';
import { EksternLeverandoerPraemie } from './eksternLeverandoerPraemie';
import { EksternLeverandoerPraemieSchema } from './eksternLeverandoerPraemie';
import { IndbetalingTidsbegraensetLivspension } from './indbetalingTidsbegraensetLivspension';
import { IndbetalingTidsbegraensetLivspensionSchema } from './indbetalingTidsbegraensetLivspension';
import * as JoiObj from "joi";


    export const IndbetalingDetaljerSchema = JoiObj.object({
        betalingsFrekvens:
            
        JoiObj.link('#BetalingFrekvensSchema')

.allow(null)



        ,
        eksternLeverandoererPraemie:
                JoiObj.array().items(
        
        JoiObj.link('#EksternLeverandoerPraemieSchema')

        )
        
.allow(null)



        ,
        forsikring:
            
                    JoiObj.number()
                

.allow(null)



        ,
        forsikringTillaeg:
            
                    JoiObj.number()
                

.allow(null)



        ,
        forsikringsOrdning:
            
                    JoiObj.boolean()
                


.required()


        ,
        fratraadt:
            
                    JoiObj.boolean()
                


.required()


        ,
        frivillegEnhedType:
            
        JoiObj.link('#EnhedTypeSchema')

.allow(null)



        ,
        frivilligKroner:
            
                    JoiObj.number()
                

.allow(null)



        ,
        frivilligProcent:
            
                    JoiObj.number()
                

.allow(null)



        ,
        gipp:
            
                    JoiObj.boolean()
                


.required()


        ,
        indbetalingAldersopsparing:
            
        JoiObj.link('#IndbetalingAldersopsparingSchema')

.allow(null)



        ,
        indbetalingForsikringsordningUdenFradragsret:
            
        JoiObj.link('#IndbetalingForsikringsordningUdenFradragsretSchema')

.allow(null)



        ,
        indbetalingForsikringsordningUdenSkattekode:
            
        JoiObj.link('#IndbetalingForsikringsordningUdenSkattekodeSchema')

.allow(null)



        ,
        indbetalingLivsvarigLivspension:
            
        JoiObj.link('#IndbetalingLivsvarigLivspensionSchema')

.allow(null)



        ,
        indbetalingRatepension:
            
        JoiObj.link('#IndbetalingRatepensionSchema')

.allow(null)



        ,
        indbetalingTidsbegraensetLivspension:
            
        JoiObj.link('#IndbetalingTidsbegraensetLivspensionSchema')

.allow(null)



        ,
        indbetalingsSikring:
            
                    JoiObj.boolean()
                


.required()


        ,
        ingenIndbetalingAdvarsel:
            
                    JoiObj.boolean()
                


.required()


        ,
        ingenIndbetalinger:
            
                    JoiObj.boolean()
                


.required()


        ,
        loen:
            
                    JoiObj.number()
                

.allow(null)



        ,
        loenFraArbejdsgiver:
            
                    JoiObj.boolean()
                

.allow(null)



        ,
        obligatoriskFraArbejdsgiverEnhedType:
            
        JoiObj.link('#EnhedTypeSchema')

.allow(null)



        ,
        obligatoriskFraArbejdsgiverKroner:
            
                    JoiObj.number()
                

.allow(null)



        ,
        obligatoriskFraArbejdsgiverProcent:
            
                    JoiObj.number()
                

.allow(null)



        ,
        obligatoriskFraMedarbejderEnhedType:
            
        JoiObj.link('#EnhedTypeSchema')

.allow(null)



        ,
        obligatoriskFraMedarbejderKroner:
            
                    JoiObj.number()
                

.allow(null)



        ,
        obligatoriskFraMedarbejderProcent:
            
                    JoiObj.number()
                

.allow(null)



        ,
        obligatoriskeKrProcent:
            
                    JoiObj.number()
                

.allow(null)



        ,
        opsparingsformVisible:
            
                    JoiObj.boolean()
                


.required()


        ,
        orlov:
            
                    JoiObj.boolean()
                


.required()


        ,
        orlovSlutDato:
            
            JoiObj.date()

.allow(null)



        ,
        paragraf53A:
            
                    JoiObj.boolean()
                


.required()


        ,
        pensionsordningUdenFradragsret:
            
        JoiObj.link('#IndbetalingPensionsordningUdenFradragsretSchema')

.allow(null)



        ,
        udenOpsparing:
            
                    JoiObj.boolean()
                


.required()


        ,
        valgtOpsparingFormFordeling:
            
        JoiObj.link('#OpsparingFormFordelingSchema')

.allow(null)



        ,
        valgtOpsparingsformContentId:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        
    })
                .shared(BetalingFrekvensSchema)
                .shared(EksternLeverandoerPraemieSchema)
                .shared(EnhedTypeSchema)
                .shared(IndbetalingAldersopsparingSchema)
                .shared(IndbetalingForsikringsordningUdenFradragsretSchema)
                .shared(IndbetalingForsikringsordningUdenSkattekodeSchema)
                .shared(IndbetalingLivsvarigLivspensionSchema)
                .shared(IndbetalingRatepensionSchema)
                .shared(IndbetalingTidsbegraensetLivspensionSchema)
                .shared(EnhedTypeSchema)
                .shared(EnhedTypeSchema)
                .shared(IndbetalingPensionsordningUdenFradragsretSchema)
                .shared(OpsparingFormFordelingSchema)
.id('IndbetalingDetaljerSchema')

export interface IndbetalingDetaljer { 
    betalingsFrekvens?: BetalingFrekvens;
    eksternLeverandoererPraemie?: Array<EksternLeverandoerPraemie>;
    forsikring?: number;
    forsikringTillaeg?: number;
    forsikringsOrdning: boolean;
    fratraadt: boolean;
    frivillegEnhedType?: EnhedType;
    frivilligKroner?: number;
    frivilligProcent?: number;
    gipp: boolean;
    indbetalingAldersopsparing?: IndbetalingAldersopsparing;
    indbetalingForsikringsordningUdenFradragsret?: IndbetalingForsikringsordningUdenFradragsret;
    indbetalingForsikringsordningUdenSkattekode?: IndbetalingForsikringsordningUdenSkattekode;
    indbetalingLivsvarigLivspension?: IndbetalingLivsvarigLivspension;
    indbetalingRatepension?: IndbetalingRatepension;
    indbetalingTidsbegraensetLivspension?: IndbetalingTidsbegraensetLivspension;
    indbetalingsSikring: boolean;
    ingenIndbetalingAdvarsel: boolean;
    ingenIndbetalinger: boolean;
    loen?: number;
    loenFraArbejdsgiver?: boolean;
    obligatoriskFraArbejdsgiverEnhedType?: EnhedType;
    obligatoriskFraArbejdsgiverKroner?: number;
    obligatoriskFraArbejdsgiverProcent?: number;
    obligatoriskFraMedarbejderEnhedType?: EnhedType;
    obligatoriskFraMedarbejderKroner?: number;
    obligatoriskFraMedarbejderProcent?: number;
    obligatoriskeKrProcent?: number;
    opsparingsformVisible: boolean;
    orlov: boolean;
    orlovSlutDato?: Date;
    paragraf53A: boolean;
    pensionsordningUdenFradragsret?: IndbetalingPensionsordningUdenFradragsret;
    udenOpsparing: boolean;
    valgtOpsparingFormFordeling?: OpsparingFormFordeling;
    valgtOpsparingsformContentId?: string;
}
export namespace IndbetalingDetaljer {
}


