<co-advisor-box>
  <co-accordion
    [hideControls]="false"
    [initExpanded]="false"
    [title]="'DL.TF01.C114'"
    [titleOpen]="'DL.TF01.C114'"
    class="accordion--black-07"
  >
    <form novalidate [formGroup]="recommendationForm" #formDirective="ngForm">
      <div class="row">
        <div class="col col-4">
          <p class="text--bold" coContent="DL.TF01.C115"></p>
          <mat-form-field class="recommendation" color="accent">
            <mat-label [coContent]="'DL.TF01.C119'"></mat-label>
            <input
              matInput
              coAmount
              coAmountUnit="%"
              [coAmountAllowNull]="true"
              [maxlength]="maxRecommendationLength"
              formControlName="recommendation"
            />
            <mat-hint
              align="end"
              *ngIf="
                adjustmentAdvisorInfo.advisorData.raadgiverAnbefalingOprettet
              "
            >
              <div
                [coContent]="'DL.TF01.C131'"
                [coContentSubst]="
                  adjustmentAdvisorInfo.advisorData.raadgiverAnbefalingOprettet
                "
              >
                Updated date
              </div>
            </mat-hint>
            <mat-error
              *ngIf="recommendationForm.get('recommendation')?.errors?.required"
              [coContent]="'DL.MD01.C12'"
            ></mat-error>
            <mat-error
              *ngIf="
                recommendationForm.get('recommendation')?.errors?.numberFormat
              "
              [coContent]="'DL.MD01.C13'"
              align="end"
            ></mat-error>
            <mat-error
              *ngIf="
                !recommendationForm.get('recommendation')?.errors
                  ?.numberFormat &&
                recommendationForm.get('recommendation')?.errors?.max
              "
              [coContent]="'DL.MD01.C301'"
              [coContentSubst]="99"
              align="end"
            ></mat-error>
          </mat-form-field>
        </div>
        <div class="col col-8">
          <p class="text--bold" [coContent]="'DL.TF01.C116'"></p>
          <mat-form-field color="accent">
            <textarea
              matInput
              formControlName="reason"
              rows="3"
              [maxlength]="TEXT_AREA_SIZE"
              (keyup)="calcCount()"
            ></textarea>
            <mat-hint>
              <p [coContent]="'DL.TF01.C132'" [coContentSubst]="count">
                Chars left
              </p></mat-hint
            >
            <mat-error
              *ngIf="recommendationForm.get('reason')?.errors?.required"
              [coContent]="'DL.MD01.C12'"
            ></mat-error>
            <mat-error
              *ngIf="recommendationForm.get('reason')?.errors?.pattern"
              [coContent]="'DL.MD01.C121'"
            ></mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <button
            mat-raised-button
            [disabled]="
              recommendationForm.pristine ||
              recommendationForm.get('recommendation')?.invalid ||
              recommendationForm.get('reason')?.invalid
            "
            (click)="onUpdate()"
          >
            <co-icon-save class="icon"></co-icon-save>
            <span [coContent]="'Global.Gem'"></span>
          </button>
          <button
            mat-raised-button
            class="button-secondary"
            [disabled]="!adjustmentAdvisorInfo.advisorData.raadgiverAnbefaling"
            (click)="onDelete()"
          >
            <co-icon-delete class="icon"></co-icon-delete>
            <span [coContent]="'Global.Slet'"></span>
          </button>
        </div>
      </div>
    </form>

    <form novalidate [formGroup]="netCoverageForm" *ngIf="isLife()">
      <co-spacer-section></co-spacer-section>
      <p [coContent]="'DL.TF01.C128'" class="text--bold"></p>
      <div class="row" *ngIf="allowNetAmountUpdate()">
        <div class="col-12">
          <p
            [coContent]="'DL.TF01.C125'"
            [coContentSubst]="
              (netAmountMin | numberFormat) +
              '|' +
              (netAmountMax | numberFormat)
            "
          ></p>
        </div>
        <div class="col-12 col-md-8">
          <mat-form-field class="netAmount" color="accent">
            <mat-label [coContent]="'DL.TF01.C124'"></mat-label>
            <input
              matInput
              coAmount
              [coAmountUnit]="'Global.Kroner' | content"
              [coAmountAllowNull]="true"
              formControlName="netAmount"
            />
          </mat-form-field>
          <mat-error
            *ngIf="netCoverageForm.get('netAmount').errors?.required"
            [coContent]="'DL.MD01.C12'"
          ></mat-error>
          <mat-error
            *ngIf="netCoverageForm.get('netAmount').errors?.numberFormat"
            [coContent]="'DL.MD01.C13'"
            align="end"
          ></mat-error>
          <mat-error
            *ngIf="
              !netCoverageForm.get('netAmount').errors?.numberFormat &&
              (netCoverageForm.get('netAmount').errors?.min ||
                netCoverageForm.get('netAmount').errors?.max)
            "
            [coContent]="'DL.TF01.C73'"
            [coContentSubst]="
              (netAmountMin | numberFormat) +
              '|' +
              (netAmountMax | numberFormat)
            "
          ></mat-error>
        </div>
        <div class="col-12 col-md-4">
          <button
            mat-raised-button
            class="button-primary--level3 update-button"
            [style.visibility]="'visible'"
            [disabled]="
              netCoverageForm.pristine ||
              netCoverageForm.get('netAmount').invalid
            "
            (click)="onUpdateNetAmount()"
          >
            <span [coContent]="'DL.TF01.C135'"></span>
            <co-icon-refresh class="icon"></co-icon-refresh>
          </button>
        </div>
      </div>
      <co-info *ngIf="!allowNetAmountUpdate()">
        <span coContent="DL.TF01.C126"></span>
      </co-info>
    </form>

    <form novalidate [formGroup]="taxForm" *ngIf="taxCodeSectionVisible">
      <co-spacer-section></co-spacer-section>
      <div class="row">
        <div class="col-12">
          <p class="text--bold" [coContent]="'DL.TF01.C120'"></p>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-8">
          <mat-radio-group [formControlName]="'taxCode'" class="tax-code-group">
            <mat-radio-button
              [value]="'SK2'"
              [disabled]="disableTaxCodeChoiceSk2"
            >
              <span
                class="text--bold tax-code-left"
                [coContent]="'DL.TF01.C121'"
              ></span>
            </mat-radio-button>
            <mat-radio-button
              [value]="'SK5'"
              [disabled]="disableTaxCodeChoiceSk5"
            >
              <span class="text--bold" [coContent]="'DL.TF01.C122'"></span>
            </mat-radio-button>
          </mat-radio-group>
        </div>
        <div class="col-12 col-md-4">
          <button
            mat-raised-button
            class="button-primary--level3 update-button"
            [style.visibility]="'visible'"
            [disabled]="isUpdateTaxCodeDisabled()"
            (click)="onUpdateTaxCode()"
          >
            <span [coContent]="'DL.TF01.C130'"></span>
            <co-icon-refresh class="icon"></co-icon-refresh>
          </button>
        </div>
      </div>
      <div class="row" *ngIf="taxCodeInfo">
        <div class="col-6">
          <co-info>
            <span [coContent]="taxCodeInfo"></span>
          </co-info>
        </div>
      </div>
      <div *ngIf="error" class="row">
        <co-warning class="col-6 spacing-unit--half-over">
          <span [coContent]="'DL.FG01.C910'"></span>
        </co-warning>
      </div>

      <hr class="hr--light spacing-unit--over spacing-unit--under" />
    </form>
    <form novalidate [formGroup]="correctionsForm">
      <co-spacer-section></co-spacer-section>
      <div class="manuel-corrections">
        <button
          mat-raised-button
          class="button-secondary spacing-under"
          (click)="onShowManuelCorrections()"
        >
          <span [coContent]="'DL.PP01.C776'"></span>
        </button>
        <p
          [coContent]="'DL.PP01.C773'"
          *ngIf="!insuranceState.pensionInfo.advisorCorrectionsDetails.changeAt"
        ></p>
        <p
          [coContent]="'DL.PP01.C774'"
          *ngIf="
            insuranceState.pensionInfo.advisorCorrectionsDetails.changeAt &&
            !insuranceState.pensionInfo.advisorCorrectionsDetails
              .kanStraksAndvendes
          "
        ></p>
        <mat-checkbox
          [formControlName]="'useCorrections'"
          [value]="correctionsForm.value.useCorrections"
          (change)="toggleCorrections()"
          *ngIf="
            insuranceState.pensionInfo.advisorCorrectionsDetails.changeAt &&
            insuranceState.pensionInfo.advisorCorrectionsDetails
              .kanStraksAndvendes
          "
          ><p
            [coContent]="'DL.PP01.C775'"
            [coContentSubst]="
              insuranceState.pensionInfo.advisorCorrectionsDetails.changeAt
                | date: 'dd.MM.yyyy'
            "
          ></p
        ></mat-checkbox>
      </div>
    </form>
  </co-accordion>
</co-advisor-box>
