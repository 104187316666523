import { Injectable } from '@angular/core';
import { Subject, Observable, merge } from 'rxjs';
import {
  Router,
  NavigationEnd,
  NavigationCancel,
  GuardsCheckEnd,
  GuardsCheckStart
} from '@angular/router';
import { filter, map, debounceTime, startWith } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GlobalWorkingService {
  private readonly loaderSubject = new Subject<boolean>();
  private readonly myObservable = this.loaderSubject.asObservable();
  loaderState: Observable<boolean>;

  constructor(private readonly router: Router) {
    const routeChange$: Observable<boolean> = this.router.events.pipe(
      filter(
        e =>
          e instanceof NavigationEnd ||
          e instanceof NavigationCancel ||
          e instanceof GuardsCheckStart ||
          e instanceof GuardsCheckEnd
      ),
      map(event => {
        if (event instanceof GuardsCheckEnd) {
          return false;
        } else {
          return true;
        }
      }),
      startWith(true),
      // waiting some time to start spinner to avoid flickering
      // awaiting UX to set a better time limit.
      debounceTime(150)
    );
    this.loaderState = merge(this.myObservable, routeChange$);
  }

  show() {
    this.loaderSubject.next(false);
  }

  hide() {
    this.loaderSubject.next(true);
  }
}
