/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Blanket } from './blanket';
import { BlanketSchema } from './blanket';
import { BlanketStatus } from './blanketStatus';
import { BlanketStatusSchema } from './blanketStatus';
import * as JoiObj from "joi";


    export const BlanketMedPoliceNrSchema = JoiObj.object({
        aendretAf:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        blanketDokumentId:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        blanketstatus:
            
        JoiObj.link('#BlanketStatusSchema')


.required()


        ,
        cprNr:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        documentHandle:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        globalId:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        modtagelsesDato:
            
                    JoiObj.object()
                


.required()


        ,
        nyPolice:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        policenr:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        
    })
                .shared(BlanketStatusSchema)
.id('BlanketMedPoliceNrSchema')

export interface BlanketMedPoliceNr extends Blanket { 
    policenr: string;
}
export namespace BlanketMedPoliceNr {
}


