/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ServiceInfo } from './serviceInfo';
import { ServiceInfoSchema } from './serviceInfo';
import { DaekningsDetaljer } from './daekningsDetaljer';
import { DaekningsDetaljerSchema } from './daekningsDetaljer';
import { ResourceModel } from './resourceModel';
import { ResourceModelSchema } from './resourceModel';
import { DaekningsDetaljerLivnetNaesteAar } from './daekningsDetaljerLivnetNaesteAar';
import { DaekningsDetaljerLivnetNaesteAarSchema } from './daekningsDetaljerLivnetNaesteAar';
import * as JoiObj from "joi";


    export const PfaPlanTabelDataSchema = JoiObj.object({
        serviceInfo:
            
        JoiObj.link('#ServiceInfoSchema')

.allow(null)



        ,
        advarsel:
            
                    JoiObj.boolean()
                


.required()


        ,
        alleUnderUdbetaling:
            
                    JoiObj.boolean()
                


.required()


        ,
        daekningerDerIkkeErVist:
                JoiObj.array().items(
        
        JoiObj.link('#DaekningsDetaljerSchema')

        )
        

.required()


        ,
        daekningerMedEngangsUdbetaling:
                JoiObj.array().items(
        
        JoiObj.link('#DaekningsDetaljerSchema')

        )
        

.required()


        ,
        daekningerMedLivsvarigPension:
                JoiObj.array().items(
        
        JoiObj.link('#DaekningsDetaljerSchema')

        )
        

.required()


        ,
        daekningerMedOphoerendePension:
                JoiObj.array().items(
        
        JoiObj.link('#DaekningsDetaljerSchema')

        )
        

.required()


        ,
        daekningerNaesteAar:
                JoiObj.array().items(
        
        JoiObj.link('#DaekningsDetaljerLivnetNaesteAarSchema')

        )
        

.required()


        ,
        daekningerUdenUdbetaling:
                JoiObj.array().items(
        
        JoiObj.link('#DaekningsDetaljerSchema')

        )
        
.allow(null)



        ,
        fejledePolicer:
                JoiObj.array().items(
        
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

        )
        

.required()


        ,
        gippPolicer:
                JoiObj.array().items(
        
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

        )
        

.required()


        ,
        ingenUnderUdbetaling:
            
                    JoiObj.boolean()
                


.required()


        ,
        kunForsikringer:
            
                    JoiObj.boolean()
                


.required()


        ,
        loenAendret:
            
                    JoiObj.boolean()
                


.required()


        ,
        slutAlder:
            
                    JoiObj.number()
                


.required()


        ,
        startAlder:
            
                    JoiObj.number()
                


.required()


        ,
        udbetalingssikret:
            
                    JoiObj.boolean()
                


.required()


        
    })
                .shared(ServiceInfoSchema)
                .shared(DaekningsDetaljerSchema)
                .shared(DaekningsDetaljerSchema)
                .shared(DaekningsDetaljerSchema)
                .shared(DaekningsDetaljerSchema)
                .shared(DaekningsDetaljerLivnetNaesteAarSchema)
                .shared(DaekningsDetaljerSchema)
.id('PfaPlanTabelDataSchema')

export interface PfaPlanTabelData extends ResourceModel { 
    advarsel: boolean;
    alleUnderUdbetaling: boolean;
    daekningerDerIkkeErVist: Array<DaekningsDetaljer>;
    daekningerMedEngangsUdbetaling: Array<DaekningsDetaljer>;
    daekningerMedLivsvarigPension: Array<DaekningsDetaljer>;
    daekningerMedOphoerendePension: Array<DaekningsDetaljer>;
    daekningerNaesteAar: Array<DaekningsDetaljerLivnetNaesteAar>;
    daekningerUdenUdbetaling?: Array<DaekningsDetaljer>;
    fejledePolicer: Array<string>;
    gippPolicer: Array<string>;
    ingenUnderUdbetaling: boolean;
    kunForsikringer: boolean;
    loenAendret: boolean;
    slutAlder: number;
    startAlder: number;
    udbetalingssikret: boolean;
}

