export class CustomerSurveyQuestionsFields {
  static satisfactionLevel = 'satisfactionLevel';
  static navigation = 'navigation';
  static understandable = 'understandable';
  static design = 'design';
  static smoothness = 'smoothness';
  static purposeForVisitFulfilled = 'purposeForVisitFulfilled';
  static suggestionForImprovement = 'suggestionForImprovement';
  static foundAllYouWanted = 'foundAllYouWanted';
  static missingInfo = 'missingInfo';
}
