import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Role } from '@pfa/models';
import { UserGuard } from '@pfa/handler';
import { PrerequisitesComponent } from './prerequisites.component';

const routes: Routes = [
  {
    path: 'forbehold',
    canActivate: [UserGuard],
    data: {
      roles: [
        Role.Advisor.valueOf(),
        Role.Customer.valueOf(),
        Role.PfaUser.valueOf()
      ]
    },
    component: PrerequisitesComponent
  },
  {
    path: 'forbehold/:section',
    canActivate: [UserGuard],
    data: {
      roles: [
        Role.Advisor.valueOf(),
        Role.Customer.valueOf(),
        Role.PfaUser.valueOf()
      ]
    },
    component: PrerequisitesComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PrerequisitesRoutingModule {}
