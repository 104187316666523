/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const ExtendedContractCategorySchema = JoiObj.string().valid(
        'CASH_PENSION'
        ,
        'GROUP_LIFE'
        ,
        'INVEST'
        ,
        'LET_RISIKO'
        ,
        'NYT_PRODUKT'
        ,
        'SOLIDARIC'
        ,
        'UNKNOWN'
        
    )
.id('ExtendedContractCategorySchema')

export type ExtendedContractCategory = 'CASH_PENSION' | 'GROUP_LIFE' | 'INVEST' | 'LET_RISIKO' | 'NYT_PRODUKT' | 'SOLIDARIC' | 'UNKNOWN';

export const ExtendedContractCategory = {
    CashPension: 'CASH_PENSION' as ExtendedContractCategory,
    GroupLife: 'GROUP_LIFE' as ExtendedContractCategory,
    Invest: 'INVEST' as ExtendedContractCategory,
    LetRisiko: 'LET_RISIKO' as ExtendedContractCategory,
    NytProdukt: 'NYT_PRODUKT' as ExtendedContractCategory,
    Solidaric: 'SOLIDARIC' as ExtendedContractCategory,
    Unknown: 'UNKNOWN' as ExtendedContractCategory
};

