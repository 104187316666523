/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ServiceInfo } from './serviceInfo';
import { ServiceInfoSchema } from './serviceInfo';
import { Profil } from './profil';
import { ProfilSchema } from './profil';
import { InvesteringSvar } from './investeringSvar';
import { InvesteringSvarSchema } from './investeringSvar';
import * as JoiObj from "joi";


    export const ProfilMulighederSvarSchema = JoiObj.object({
        serviceInfo:
            
        JoiObj.link('#ServiceInfoSchema')

.allow(null)



        ,
        fejl:
            
                    JoiObj.boolean()
                


.required()


        ,
        fejlTekst:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        fejlkode:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        profiler:
                JoiObj.array().items(
        
        JoiObj.link('#ProfilSchema')

        )
        

.required()


        
    })
                .shared(ServiceInfoSchema)
                .shared(ProfilSchema)
.id('ProfilMulighederSvarSchema')

export interface ProfilMulighederSvar extends InvesteringSvar { 
    profiler: Array<Profil>;
}

