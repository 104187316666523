/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const EksternOverfoerselsTypeSchema = JoiObj.string().valid(
        'HELE_BELOEBET'
        ,
        'DELVIS'
        ,
        'KONTANT'
        
    )
.id('EksternOverfoerselsTypeSchema')

export type EksternOverfoerselsType = 'HELE_BELOEBET' | 'DELVIS' | 'KONTANT';

export const EksternOverfoerselsType = {
    HeleBeloebet: 'HELE_BELOEBET' as EksternOverfoerselsType,
    Delvis: 'DELVIS' as EksternOverfoerselsType,
    Kontant: 'KONTANT' as EksternOverfoerselsType
};

