/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const AfdragSchema = JoiObj.string().valid(
        'JA'
        ,
        'NEJ'
        ,
        'DELVIST'
        ,
        'VED_IKKE'
        
    )
.id('AfdragSchema')

export type Afdrag = 'JA' | 'NEJ' | 'DELVIST' | 'VED_IKKE';

export const Afdrag = {
    Ja: 'JA' as Afdrag,
    Nej: 'NEJ' as Afdrag,
    Delvist: 'DELVIST' as Afdrag,
    VedIkke: 'VED_IKKE' as Afdrag
};

