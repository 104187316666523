import {
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Output
} from '@angular/core';
import { IncludeExternalPolicies, PensioninfoStore } from '@pfa/gen';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'mitpfa-pension-info-external-transfers',
  templateUrl: './pension-info-external-transfers.component.html',
  styleUrls: ['./pension-info-external-transfers.component.scss']
})
export class PensionInfoExternalTransfersComponent {
  @Output() saveIncludeExternalPoliciesStart: EventEmitter<void> =
    new EventEmitter();
  @Output() saveIncludeExternalPoliciesFinish: EventEmitter<boolean> =
    new EventEmitter();

  private readonly pensioninfoStore: PensioninfoStore =
    inject(PensioninfoStore);
  private readonly destroyRef: DestroyRef = inject(DestroyRef);

  public saveError: boolean;

  public saveIncludeExternalPolicies(useIncludeExternalPolicies: boolean) {
    this.saveIncludeExternalPoliciesStart.emit();
    const includeExternalPolicies: IncludeExternalPolicies = {
      includePolicies: useIncludeExternalPolicies
    };
    this.pensioninfoStore
      .pensioninfoSaveIncludeExternalPoliciesPost(includeExternalPolicies)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: () => {
          this.saveError = false;
          this.saveIncludeExternalPoliciesFinish.emit(true);
        },
        error: () => {
          this.saveError = true;
          this.saveIncludeExternalPoliciesFinish.emit(false);
        }
      });
  }
}
