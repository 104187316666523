/**
 * Define popups which can be called from generic (i.e. in libs/core) components.
 */
import { InjectionToken } from '@angular/core';

export interface PopupUtilService {
  popupForbehold(selected: string): void;
  popupPreconditions(selected: string): void;
  popupArticle(selected: string): void;
}

export const POPUP_UTIL_SERVICE: InjectionToken<PopupUtilService> =
  new InjectionToken<PopupUtilService>('POPUP_UTIL_SERVICE');
