/**
 * Confirm-choice
 * @Input error If update fails then set error to true
 * @Input smallCard Sets padding to small
 * @Input bodyContentId Sets message to the user can be contentId or plain text
 * @Input confirmedContentId Sets text on the button can be contentId or plain text
 * @Input confirmedDate The date when the user confirmed his/her choice
 * @Output confirmed Sends a emit when confirm button is pressed
 *
 * EXAMPLE
 *  <co-confirm-choice
 *    [bodyContentId]="text"
 *    [error]="error"
 *    [confirmedContentId]="'Bekræft valg'"
 *    (confirmed)="update()"
 *    [confirmedDate]="confirmedDate"
 *    [isDisabled]="disabled">
 *  </co-confirm-choice>
 */
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { fadeInOut } from '@pfa/animations';
import { ConfirmChoiceComponentTexts } from '@pfaform';

@Component({
  selector: 'co-confirm-choice',
  templateUrl: 'confirm-choice.component.html',
  styleUrls: ['./confirm-choice.component.scss'],
  animations: [fadeInOut]
})
export class ConfirmChoiceComponent {
  @Input() error?: boolean;
  @Input() smallCard?: boolean;
  @Input() simple?: boolean;
  @Input() confirmedDate?: Date | number;
  @Input() isDisabled?: boolean;
  @Input() success: boolean;
  @Input() texts?: ConfirmChoiceComponentTexts;
  @Output() confirmed = new EventEmitter();

  public confirm(): void {
    this.confirmed.emit();
  }
}
