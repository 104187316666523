FusionCharts.register('theme', {
  name: 'pfaTheme',
  theme: {
    base: {
      chart: {
        paletteColors: PFAColors.paletteColors,
        labelDisplay: 'auto',
        baseFontColor: PFAColors.grey_80,
        baseFont: 'Lato, Arial, Verdana',
        captionFontSize: '14',
        subcaptionFontSize: '14',
        subcaptionFontBold: '0',
        showBorder: '0',
        bgAlpha: '0',
        showShadow: '0',

        animation: '1',

        canvasBgAlpha: '0',
        showCanvasBorder: '1',
        canvasBorderThickness: '1',
        canvasBorderColor: PFAColors.grey_20,

        useplotgradientcolor: '0',
        useRoundEdges: '0',
        showPlotBorder: '0',
        plotFillAlpha: '100',
        showAlternateHGridColor: '0',
        showAlternateVGridColor: '0',

        toolTipColor: PFAColors.white,
        toolTipBorderThickness: '0',
        toolTipBgColor: PFAColors.black,
        toolTipBgAlpha: '80',
        toolTipBorderRadius: '1',
        toolTipPadding: '5',

        showLegend: '0',
        legendBgAlpha: '0',
        legendBorderAlpha: '0',
        legendShadow: '0',
        legendItemFontSize: '10',
        legendItemFontColor: PFAColors.grey_60,
        legendCaptionFontSize: '9',

        divlineAlpha: '100',
        divlineColor: PFAColors.grey_20,
        divlineThickness: '1',
        divLineIsDashed: '0',

        scrollheight: '10',
        flatScrollBars: '1',
        scrollShowButtons: '0',
        scrollColor: PFAColors.grey_20,
        showHoverEffect: '0',
        valueFontSize: '12',
        valueFontColor: PFAColors.black,
        placeValuesInside: '0',
        showXAxisLine: '0',
        xAxisLineThickness: '1',
        xAxisLineColor: PFAColors.grey_40,

        enableRotation: '0',

        formatNumberScale: '0',
        numberScaleUnit: ' , ',
        decimalSeparator: ',',
        thousandSeparator: '.',
        decimals: '0'
      },
      dataset: [{}],
      trendlines: [{}]
    },
    pie2d: {
      chart: {
        use3dlighting: '0',
        valueFontColor: PFAColors.grey_80,
        slicingDistance: 0,
        showLabels: '0',
        showValues: '0',
        enableSmartLabels: '0',
        startingAngle: '90',
        captionPadding: '15',

        showPlotBorder: '1',
        drawFullAreaBorder: '1',
        plotBorderThickness: '2',
        plotBorderColor: PFAColors.white
      },
      data: function (index, dataItem, dataLength) {
        var math = window.Math;
        return {
          alpha:
            100 -
            (50 < dataLength
              ? math.round(100 / math.ceil(dataLength / 10))
              : 20) *
              math.floor(index / 10)
        };
      }
    },
    doughnut2d: {
      chart: {
        use3dlighting: '0',
        paletteColors: PFAColors.primary_100 + ', ' + PFAColors.grey_80,
        startingAngle: '90',
        valueFontColor: PFAColors.grey_80,
        centerLabelFont: 'LucidaGrandeFace, Tahoma, Arial, Verdana, sans-serif',
        centerLabelBold: '1',
        centerLabelItalic: '0',
        centerLabelFontColor: PFAColors.grey_80,
        enableSmartLabels: '0',
        showValues: '0',
        showLabels: '0',
        slicingDistance: 0,
        captionPadding: '15',
        chartLeftMargin: 0,
        chartTopMargin: 0,
        chartRightMargin: 0,
        chartBottomMargin: 0
      },
      data: function (c, a, b) {
        a = window.Math;
        return {
          alpha:
            100 -
            (50 < b ? a.round(100 / a.ceil(b / 10)) : 20) * a.floor(c / 10)
        };
      }
    },
    line: {
      chart: {
        lineThickness: '2'
      }
    },
    column2d: {
      chart: {
        rotateValues: '0',
        maxColWidth: '100'
        // TODO Below should NOT be default - default is normal color-scheme - this can ONLY be done in themes! can be overridden when pushing data objects
      },
      data: function (index, dataItem, dataLength) {
        // Columns with negative values are red
        if (dataItem.value < 0) {
          return {
            color: PFAColors.primary_80
          };
        }

        // all other columns are dark grey
        return {
          color: PFAColors.grey_60
        };
      }
    },
    bar2d: {
      chart: {
        rotateValues: '0',
        maxColWidth: '100'
      },
      data: function (index, dataItem, dataLength) {
        // bars with negative values are red
        if (dataItem.value < 0) {
          return {
            color: PFAColors.primary_100
          };
        }

        // all other bars are black
        return {
          color: PFAColors.black
        };
      }
    },
    area2d: {
      chart: {
        valueBgColor: PFAColors.grey_7,
        valueBgAlpha: '90',
        valueBorderPadding: '-2',
        valueBorderRadius: '2'
      }
    },
    stackedcolumn2d: {
      chart: {
        showValues: '1',
        maxColWidth: '100',
        formatNumberScale: '0'
      }
    },
    stackedarea2d: {
      chart: {
        showValues: '1',
        placeValuesInside: '1',
        canvasPadding: '0',
        valueFontColor: PFAColors.black,
        valueBgAlpha: '90',
        valueBorderPadding: '-2',
        valueBorderRadius: '2',
        valueFontBold: '1',
        anchorAlpha: '100',
        anchorBorderThickness: '2',
        anchorBgColor: PFAColors.white,
        anchorBorderColor: PFAColors.grey_20,
        yAxisValuesPadding: '35',
        //        "numberSuffix": " kr.",
        labelPadding: '10'
      }
    }
  }
});
