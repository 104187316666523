<div class="contact">
  <h4
    class="title"
    [coContent]="isLetPension ? 'DL.KO01.C333' : 'DL.KO01.C03'"
  ></h4>
  <a
    [href]="'tel:' + contactPhone"
    class="action phone"
    (click)="phoneClicked()"
  >
    <co-icon-phone class="icon"></co-icon-phone>
    <span>{{ contactPhone }}</span>
  </a>
  <a class="action" [routerLink]="'/beskeder'" (click)="writeClicked()">
    <co-icon-envelope class="icon"></co-icon-envelope>
    <span coContent="DL.KO01.C319"></span>
  </a>
  <a
    *ngIf="!isLight"
    [routerLink]="'/kontakt'"
    coContent="DL.MF.C26"
    class="action link"
    (click)="contactClicked()"
  ></a>
</div>
<div class="opening-hours">
  <h4 class="title" coContent="DL.KO01.C320"></h4>
  <div class="opening-hours__row">
    <span coContent="DL.KO01.C321"></span>
    <span coContent="DL.KO01.C324"></span>
  </div>
  <div class="opening-hours__row">
    <span coContent="DL.KO01.C322"></span>
    <span coContent="DL.KO01.C325"></span>
  </div>
  <div class="opening-hours__row">
    <span coContent="DL.KO01.C323"></span>
    <span coContent="DL.KO01.C326"></span>
  </div>
</div>
<div class="address">
  <h4 class="title" coContent="DL.KO01.C327"></h4>
  <p coContent="DL.KO01.C328"></p>
</div>
