/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Savings } from './savings';
import { SavingsSchema } from './savings';
import { ClimateRelevance } from './climateRelevance';
import { ClimateRelevanceSchema } from './climateRelevance';
import { LossAbility } from './lossAbility';
import { LossAbilitySchema } from './lossAbility';
import { ClimateWillingness } from './climateWillingness';
import { ClimateWillingnessSchema } from './climateWillingness';
import { RiskWillingness } from './riskWillingness';
import { RiskWillingnessSchema } from './riskWillingness';
import { TradeType } from './tradeType';
import { TradeTypeSchema } from './tradeType';
import { Residences } from './residences';
import { ResidencesSchema } from './residences';
import { Debt } from './debt';
import { DebtSchema } from './debt';
import { ClimateExtendedFocus } from './climateExtendedFocus';
import { ClimateExtendedFocusSchema } from './climateExtendedFocus';
import { InvestmentConcept } from './investmentConcept';
import { InvestmentConceptSchema } from './investmentConcept';
import { ClimatePriority } from './climatePriority';
import { ClimatePrioritySchema } from './climatePriority';
import { RiskProfile } from './riskProfile';
import { RiskProfileSchema } from './riskProfile';
import { EconomicImpact } from './economicImpact';
import { EconomicImpactSchema } from './economicImpact';
import * as JoiObj from "joi";


    export const InvestmentQuestionaireSchema = JoiObj.object({
        age:
            
                    JoiObj.number()
                


.required()


        ,
        chosenRiskProfile:
            
        JoiObj.link('#RiskProfileSchema')


.required()


        ,
        climateExtendedFocus:
            
        JoiObj.link('#ClimateExtendedFocusSchema')


.required()


        ,
        climatePriority:
            
        JoiObj.link('#ClimatePrioritySchema')


.required()


        ,
        climateRelevance:
            
        JoiObj.link('#ClimateRelevanceSchema')


.required()


        ,
        climateWillingness:
            
        JoiObj.link('#ClimateWillingnessSchema')


.required()


        ,
        commercialResidences:
            
        JoiObj.link('#ResidencesSchema')


.required()


        ,
        conceptChoice:
                JoiObj.array().items(
        
        JoiObj.link('#InvestmentConceptSchema')

        )
        

.required()


        ,
        debt:
            
        JoiObj.link('#DebtSchema')


.required()


        ,
        economicImpact:
            
        JoiObj.link('#EconomicImpactSchema')


.required()


        ,
        investmentKnowledge:
            
                    JoiObj.boolean()
                


.required()


        ,
        lossAbility:
            
        JoiObj.link('#LossAbilitySchema')


.required()


        ,
        moreThan10Trades:
            
                    JoiObj.boolean()
                


.required()


        ,
        primaryResidences:
            
        JoiObj.link('#ResidencesSchema')


.required()


        ,
        retirementAge:
            
                    JoiObj.number()
                


.required()


        ,
        riskWillingness:
            
        JoiObj.link('#RiskWillingnessSchema')


.required()


        ,
        savings:
            
        JoiObj.link('#SavingsSchema')


.required()


        ,
        secondaryResidences:
            
        JoiObj.link('#ResidencesSchema')


.required()


        ,
        tradingExperience:
                JoiObj.array().items(
        
        JoiObj.link('#TradeTypeSchema')

        )
        

.required()


        
    })
                .shared(RiskProfileSchema)
                .shared(ClimateExtendedFocusSchema)
                .shared(ClimatePrioritySchema)
                .shared(ClimateRelevanceSchema)
                .shared(ClimateWillingnessSchema)
                .shared(ResidencesSchema)
                .shared(InvestmentConceptSchema)
                .shared(DebtSchema)
                .shared(EconomicImpactSchema)
                .shared(LossAbilitySchema)
                .shared(ResidencesSchema)
                .shared(RiskWillingnessSchema)
                .shared(SavingsSchema)
                .shared(ResidencesSchema)
                .shared(TradeTypeSchema)
.id('InvestmentQuestionaireSchema')

export interface InvestmentQuestionaire { 
    age: number;
    chosenRiskProfile: RiskProfile;
    climateExtendedFocus: ClimateExtendedFocus;
    climatePriority: ClimatePriority;
    climateRelevance: ClimateRelevance;
    climateWillingness: ClimateWillingness;
    commercialResidences: Residences;
    conceptChoice: Array<InvestmentConcept>;
    debt: Debt;
    economicImpact: EconomicImpact;
    investmentKnowledge: boolean;
    lossAbility: LossAbility;
    moreThan10Trades: boolean;
    primaryResidences: Residences;
    retirementAge: number;
    riskWillingness: RiskWillingness;
    savings: Savings;
    secondaryResidences: Residences;
    tradingExperience: Array<TradeType>;
}
export namespace InvestmentQuestionaire {
}


