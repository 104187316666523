<!-- v8 -->
<svg
  id="Layer_1"
  data-name="Layer 1"
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 32 32"
>
  <g id="Canvas">
    <circle
      id="Large_circle"
      data-name="Large circle"
      cx="16"
      cy="16"
      r="16"
      fill="none"
    />
    <circle
      id="Small_circle"
      data-name="Small circle"
      cx="16"
      cy="16"
      r="12"
      fill="none"
    />
  </g>
  <g id="Icon">
    <line
      id="Third_line"
      data-name="Third line"
      x1="13"
      y1="21"
      x2="24"
      y2="21"
      stroke-width="1.2"
      stroke="#000"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
    />
    <polyline
      id="Third_checkmark"
      data-name="Third checkmark"
      points="10.59 19.44 8.47 21.56 7.41 20.5"
      fill="none"
      stroke="#000"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.2"
    />
    <line
      id="Second_line"
      data-name="Second line"
      x1="13"
      y1="16"
      x2="23"
      y2="16"
      stroke-width="1.2"
      stroke="#000"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
    />
    <polyline
      id="Second_checkmark"
      data-name="Second checkmark"
      points="10.59 14.44 8.47 16.56 7.41 15.5"
      fill="none"
      stroke="#000"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.2"
    />
    <line
      id="First_line"
      data-name="First line"
      x1="13"
      y1="11"
      x2="25"
      y2="11"
      stroke-width="1.2"
      stroke="#000"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
    />
    <polyline
      id="First_checkmark"
      data-name="First checkmark"
      points="10.59 9.44 8.47 11.56 7.41 10.5"
      fill="none"
      stroke="#000"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.2"
    />
  </g>
</svg>
