/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { InjuryRelation } from './injuryRelation';
import { InjuryRelationSchema } from './injuryRelation';
import * as JoiObj from "joi";


    export const HelbredsSikringSchema = JoiObj.object({
        agreementId:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        daekningsBeskrivelseContentId:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        daekningsContentId:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        injuryRelation:
            
        JoiObj.link('#InjuryRelationSchema')

.allow(null)



        
    })
                .shared(InjuryRelationSchema)
.id('HelbredsSikringSchema')

export interface HelbredsSikring { 
    agreementId?: string;
    daekningsBeskrivelseContentId: string;
    daekningsContentId: string;
    injuryRelation?: InjuryRelation;
}
export namespace HelbredsSikring {
}


