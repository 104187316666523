import { Injectable } from '@angular/core';
import { TrackingService } from './tracking.service';

@Injectable()
export class SavingsReturnTrackingService extends TrackingService {
  public trackGoToPolicyDetails(): void {
    const options = {
      detail: {
        savingsReturnPage: 'Afkast',
        savingsReturnAction: 'Vælg police'
      }
    };
    this.dispatchCustomEventTracking('track-savings-return', options);
  }
}
