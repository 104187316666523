/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DaekningValue } from './daekningValue';
import { DaekningValueSchema } from './daekningValue';
import * as JoiObj from "joi";


    export const IndregningDetaljerSchema = JoiObj.object({
        boernepensionFrivillig:
            
        JoiObj.link('#DaekningValueSchema')

.allow(null)



        ,
        boernepensionObligatorisk:
            
        JoiObj.link('#DaekningValueSchema')

.allow(null)



        ,
        detaljer:
            
        JoiObj.object().pattern(/./,     
        JoiObj.link('#DaekningValueSchema')

.allow(null)


)


.required()


        ,
        kritiskSygdomBoernFrivillig:
            
        JoiObj.link('#DaekningValueSchema')

.allow(null)



        ,
        kritiskSygdomBoernObligatorisk:
            
        JoiObj.link('#DaekningValueSchema')

.allow(null)



        ,
        kritiskSygdomSelv:
            
        JoiObj.link('#DaekningValueSchema')

.allow(null)



        ,
        livforsikring:
            
        JoiObj.link('#DaekningValueSchema')

.allow(null)



        ,
        taeengangsUdbetaling:
            
        JoiObj.link('#DaekningValueSchema')

.allow(null)



        ,
        taeloebendeUdbetaling:
            
        JoiObj.link('#DaekningValueSchema')

.allow(null)



        
    })
                .shared(DaekningValueSchema)
                .shared(DaekningValueSchema)
                .shared(DaekningValueSchema)
                .shared(DaekningValueSchema)
                .shared(DaekningValueSchema)
                .shared(DaekningValueSchema)
                .shared(DaekningValueSchema)
                .shared(DaekningValueSchema)
                .shared(DaekningValueSchema)
.id('IndregningDetaljerSchema')

export interface IndregningDetaljer { 
    boernepensionFrivillig?: DaekningValue;
    boernepensionObligatorisk?: DaekningValue;
    detaljer: { [key: string]: DaekningValue; };
    kritiskSygdomBoernFrivillig?: DaekningValue;
    kritiskSygdomBoernObligatorisk?: DaekningValue;
    kritiskSygdomSelv?: DaekningValue;
    livforsikring?: DaekningValue;
    taeengangsUdbetaling?: DaekningValue;
    taeloebendeUdbetaling?: DaekningValue;
}

