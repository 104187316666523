import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AdvisorOptions, PartialScheme, TilbudOverblik } from '@pfa/gen';
import { ContentUtilService } from '@pfa/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'co-insurance',
  templateUrl: './insurance.component.html',
  styleUrls: ['../new-business-offer-overview.component.scss']
})
export class InsuranceComponent implements OnInit {
  private static readonly healthInsuranceTexts = new Map([
    [1, 'DL.MP01.C364'],
    [2, 'DL.MP01.C365'],
    [3, 'DL.MP01.C366'],
    [4, 'DL.MP01.C367'],
    [5, 'DL.MP01.C369 ']
  ]);

  siblings: PartialScheme[] = [];

  @Input() overview: TilbudOverblik;
  @Input() advisorOptions: AdvisorOptions;
  @Input() switchHsFail: boolean;
  @Output() partialSchemeSelected = new EventEmitter<number>();

  siblingsForm: UntypedFormGroup;

  get sibling(): UntypedFormControl {
    return this.siblingsForm.get('sibling') as UntypedFormControl;
  }

  constructor(private readonly cu: ContentUtilService) {}

  ngOnInit(): void {
    this.siblingsForm = new UntypedFormGroup({
      sibling: new UntypedFormControl(this.overview.partialSchemeId)
    });

    if (this.advisorOptions && this.overview.sieblingGroupName) {
      this.siblings = this.advisorOptions.partialSchemes.filter(
        partialScheme =>
          partialScheme.partialSchemeType === this.overview.partialSchemeType &&
          partialScheme.privatePayment === this.overview.privateIndbetaling &&
          partialScheme.partialSchemeCompanyName ===
            this.overview.partialSchemeCompanyName &&
          partialScheme.sieblingGroupName === this.overview.sieblingGroupName
      );
    }

    this.sibling.valueChanges.subscribe(newValue => {
      this.partialSchemeSelected.emit(newValue);
    });
  }

  partialSchemeLabel(partialScheme: PartialScheme): string {
    const texts = InsuranceComponent.healthInsuranceTexts;
    const healthInsuranceProduct = partialScheme.healthInsuranceProduct;
    return texts.has(healthInsuranceProduct)
      ? this.cu.getContent(texts.get(healthInsuranceProduct))
      : 'Unknown health insurance product: ' + healthInsuranceProduct;
  }
}
