/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ProcentMinMax } from './procentMinMax';
import { ProcentMinMaxSchema } from './procentMinMax';
import * as JoiObj from "joi";


    export const MinFrivilligIndbetalingSchema = JoiObj.object({
        andenloen:
            
                    JoiObj.number()
                


.required()


        ,
        forskelligBruttoLoenPensionsgivende:
            
                    JoiObj.number()
                

.allow(null)



        ,
        frivilligIndbetalingAarligt:
            
                    JoiObj.number()
                

.allow(null)



        ,
        frivilligIndbetalingOgIKR:
            
                    JoiObj.boolean()
                

.allow(null)



        ,
        gippPrimaryPolicy:
            
                    JoiObj.boolean()
                


.required()


        ,
        indbetalingssikringBeloeb:
            
                    JoiObj.number()
                .integer()


.required()


        ,
        indbetalingssikringIForvejen:
            
                    JoiObj.boolean()
                


.required()


        ,
        indbetalingssikringMulig:
            
                    JoiObj.boolean()
                


.required()


        ,
        indbetalingssikringValgt:
            
                    JoiObj.boolean()
                


.required()


        ,
        laast:
            
                    JoiObj.boolean()
                


.required()


        ,
        maanedsFrivilligIndbetalingEfterSkat:
            
                    JoiObj.number()
                

.allow(null)



        ,
        maanedsFrivilligIndbetalingFoerSkat:
            
                    JoiObj.number()
                

.allow(null)



        ,
        merindbetalingEffektueringMulig:
            
                    JoiObj.boolean()
                


.required()


        ,
        merindbetalingIForvejen:
            
                    JoiObj.boolean()
                


.required()


        ,
        pensionsgivendeloen:
            
                    JoiObj.number()
                


.required()


        ,
        procentMinMax:
            
        JoiObj.link('#ProcentMinMaxSchema')


.required()


        ,
        procentOrdning:
            
                    JoiObj.boolean()
                


.required()


        
    })
                .shared(ProcentMinMaxSchema)
.id('MinFrivilligIndbetalingSchema')

export interface MinFrivilligIndbetaling { 
    andenloen: number;
    forskelligBruttoLoenPensionsgivende?: number;
    frivilligIndbetalingAarligt?: number;
    frivilligIndbetalingOgIKR?: boolean;
    gippPrimaryPolicy: boolean;
    indbetalingssikringBeloeb: number;
    indbetalingssikringIForvejen: boolean;
    indbetalingssikringMulig: boolean;
    indbetalingssikringValgt: boolean;
    laast: boolean;
    maanedsFrivilligIndbetalingEfterSkat?: number;
    maanedsFrivilligIndbetalingFoerSkat?: number;
    merindbetalingEffektueringMulig: boolean;
    merindbetalingIForvejen: boolean;
    pensionsgivendeloen: number;
    procentMinMax: ProcentMinMax;
    procentOrdning: boolean;
}

