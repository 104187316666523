import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { SigningIframeResponse, SigningPopupResponse } from '@pfaform';
import { NemLogin3Response } from '../signing-mitid/signing-mitid.model';
import { Subscription } from 'rxjs';
import { MobilesigningStore, Signature } from '@pfa/gen';

@Component({
  selector: 'co-signing-mitid-mobile',
  templateUrl: './signing-mitid-mobile.component.html'
})
export class SigningMitIDMobileComponent implements OnInit, OnDestroy {
  @Input() mitidSignObject: SigningIframeResponse;
  @Output() signingCompleted = new EventEmitter<SigningPopupResponse>();
  private subscription: Subscription;
  url: string;
  error?: string;

  constructor(private readonly signingService: MobilesigningStore) {}

  ngOnInit(): void {
    this.url = '/signering.html?uuid=' + this.mitidSignObject.revisionKey;
    window.location.href = this.url;
  }

  @HostListener('window:signing-completed', ['$event'])
  handleSigningCompleted(e: CustomEvent) {
    const revisionKey: string = e.detail.revisionKey;
    // TODO Revision key of the event should match the originating value.

    this.subscription = this.signingService
      .mobilesigningSignaturesUuidGet(revisionKey)
      .subscribe({
        next: r => this.handleSigningResult(revisionKey, r),
        error: err => {
          this.error = JSON.stringify(err);
        }
      });
  }

  private handleSigningResult(revisionKey: string, signatureObj: Signature) {
    const resp = NemLogin3Response.parse(signatureObj.signature);

    const result: SigningPopupResponse = {
      content: signatureObj.signature,
      revisionKey,
      success: !!resp.Signature,
      error: resp.Body?.Message
    };

    this.signingCompleted.emit(result);
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
