/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Sats } from './sats';
import { SatsSchema } from './sats';
import * as JoiObj from "joi";


    export const AarsbilagSatserSchema = JoiObj.object({
        activeNow:
            
                    JoiObj.boolean()
                


.required()


        ,
        name:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        updatedAt:
            
            JoiObj.date()


.required()


        ,
        updatedBy:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        uuid:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        validFrom:
            
            JoiObj.date()

.allow(null)



        ,
        validFromFormatted:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        year:
            
                    JoiObj.number()
                


.required()


        ,
        folderUrl:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        fraAfkastTilDepotrenteNavn:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        fraAfkastTilDepotrenteNavnEngelsk:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        fraAfkastTilDepotrenteNovoNavn:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        fraAfkastTilDepotrenteNovoNavnEngelsk:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        fraAfkastTilDepotrenteNovoUrl:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        fraAfkastTilDepotrenteNovoUrlEngelsk:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        fraAfkastTilDepotrenteUrl:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        fraAfkastTilDepotrenteUrlEngelsk:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        
    })
.id('AarsbilagSatserSchema')

export interface AarsbilagSatser extends Sats { 
    folderUrl: string;
    fraAfkastTilDepotrenteNavn: string;
    fraAfkastTilDepotrenteNavnEngelsk: string;
    fraAfkastTilDepotrenteNovoNavn: string;
    fraAfkastTilDepotrenteNovoNavnEngelsk: string;
    fraAfkastTilDepotrenteNovoUrl: string;
    fraAfkastTilDepotrenteNovoUrlEngelsk: string;
    fraAfkastTilDepotrenteUrl: string;
    fraAfkastTilDepotrenteUrlEngelsk: string;
}

