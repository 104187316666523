/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ServiceInfo } from './serviceInfo';
import { ServiceInfoSchema } from './serviceInfo';
import { SigneringSvar } from './signeringSvar';
import { SigneringSvarSchema } from './signeringSvar';
import * as JoiObj from "joi";


    export const ByggeKmdlHtmlKontraktSvarSchema = JoiObj.object({
        serviceInfo:
            
        JoiObj.link('#ServiceInfoSchema')

.allow(null)



        ,
        fejl:
            
                    JoiObj.boolean()
                


.required()


        ,
        fejlTekst:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        fejlkode:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        html:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        
    })
                .shared(ServiceInfoSchema)
.id('ByggeKmdlHtmlKontraktSvarSchema')

export interface ByggeKmdlHtmlKontraktSvar extends SigneringSvar { 
    html: string;
}

