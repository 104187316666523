/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Type } from './type';
import { TypeSchema } from './type';
import { Tilstand } from './tilstand';
import { TilstandSchema } from './tilstand';
import { IndbetalingDetaljer } from './indbetalingDetaljer';
import { IndbetalingDetaljerSchema } from './indbetalingDetaljer';
import * as JoiObj from "joi";


    export const IndbetalingOversigtPoliceSchema = JoiObj.object({
        detaljeMulig:
            
                    JoiObj.boolean()
                


.required()


        ,
        harProfilG:
            
                    JoiObj.boolean()
                


.required()


        ,
        id:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        livnetAftale:
            
                    JoiObj.boolean()
                


.required()


        ,
        opsparing:
            
                    JoiObj.number()
                

.allow(null)



        ,
        orlovSlutDato:
            
            JoiObj.date()

.allow(null)



        ,
        tilstand:
            
        JoiObj.link('#TilstandSchema')


.required()


        ,
        tooltipContentId:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        type:
            
        JoiObj.link('#TypeSchema')


.required()


        ,
        typeTekstId:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        aarSpaend:
                JoiObj.array().items(
        
                    JoiObj.number()
                .integer()

        )
        

.required()


        ,
        aftaltIndbetalingForAar:
            
                    JoiObj.number()
                


.required()


        ,
        detaljer:
            
        JoiObj.link('#IndbetalingDetaljerSchema')


.required()


        ,
        totalIndbetalingForAar:
            
                    JoiObj.number()
                


.required()


        
    })
                .shared(TilstandSchema)
                .shared(TypeSchema)
                .shared(IndbetalingDetaljerSchema)
.id('IndbetalingOversigtPoliceSchema')

export interface IndbetalingOversigtPolice { 
    detaljeMulig: boolean;
    harProfilG: boolean;
    id: string;
    livnetAftale: boolean;
    opsparing?: number;
    orlovSlutDato?: Date;
    tilstand: Tilstand;
    tooltipContentId?: string;
    type: Type;
    typeTekstId: string;
    aarSpaend: Array<number>;
    aftaltIndbetalingForAar: number;
    detaljer: IndbetalingDetaljer;
    totalIndbetalingForAar: number;
}
export namespace IndbetalingOversigtPolice {
}


