/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const FondTypeSchema = JoiObj.string().valid(
        'Aktier'
        ,
        'Obligationer'
        ,
        'PengeMarked'
        ,
        'Kundekapital'
        ,
        'Garanti'
        ,
        'AndreInvestering'
        ,
        'TimingRisiko'
        ,
        'Gennemsnitsrente'
        ,
        'Varighedsfond0Aar'
        ,
        'Varighedsfond5Aar'
        ,
        'Varighedsfond10Aar'
        ,
        'Varighedsfond20Aar'
        ,
        'StatsOgRealkreditobligationer'
        ,
        'InvestmentGradeObligationer'
        ,
        'HighYieldObligationer'
        ,
        'EmergingMarketsStatsobligationer'
        ,
        'GlobaleAktier'
        ,
        'EmergingMarketsAktier'
        ,
        'PrivateEquity'
        ,
        'Infrastruktur'
        ,
        'Ejendomme'
        ,
        'Hedgefonde'
        
    )
.id('FondTypeSchema')

export type FondType = 'Aktier' | 'Obligationer' | 'PengeMarked' | 'Kundekapital' | 'Garanti' | 'AndreInvestering' | 'TimingRisiko' | 'Gennemsnitsrente' | 'Varighedsfond0Aar' | 'Varighedsfond5Aar' | 'Varighedsfond10Aar' | 'Varighedsfond20Aar' | 'StatsOgRealkreditobligationer' | 'InvestmentGradeObligationer' | 'HighYieldObligationer' | 'EmergingMarketsStatsobligationer' | 'GlobaleAktier' | 'EmergingMarketsAktier' | 'PrivateEquity' | 'Infrastruktur' | 'Ejendomme' | 'Hedgefonde';

export const FondType = {
    Aktier: 'Aktier' as FondType,
    Obligationer: 'Obligationer' as FondType,
    PengeMarked: 'PengeMarked' as FondType,
    Kundekapital: 'Kundekapital' as FondType,
    Garanti: 'Garanti' as FondType,
    AndreInvestering: 'AndreInvestering' as FondType,
    TimingRisiko: 'TimingRisiko' as FondType,
    Gennemsnitsrente: 'Gennemsnitsrente' as FondType,
    Varighedsfond0Aar: 'Varighedsfond0Aar' as FondType,
    Varighedsfond5Aar: 'Varighedsfond5Aar' as FondType,
    Varighedsfond10Aar: 'Varighedsfond10Aar' as FondType,
    Varighedsfond20Aar: 'Varighedsfond20Aar' as FondType,
    StatsOgRealkreditobligationer: 'StatsOgRealkreditobligationer' as FondType,
    InvestmentGradeObligationer: 'InvestmentGradeObligationer' as FondType,
    HighYieldObligationer: 'HighYieldObligationer' as FondType,
    EmergingMarketsStatsobligationer: 'EmergingMarketsStatsobligationer' as FondType,
    GlobaleAktier: 'GlobaleAktier' as FondType,
    EmergingMarketsAktier: 'EmergingMarketsAktier' as FondType,
    PrivateEquity: 'PrivateEquity' as FondType,
    Infrastruktur: 'Infrastruktur' as FondType,
    Ejendomme: 'Ejendomme' as FondType,
    Hedgefonde: 'Hedgefonde' as FondType
};

