/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ESkatData } from './eSkatData';
import { ESkatDataSchema } from './eSkatData';
import { ESkatSamtykke } from './eSkatSamtykke';
import { ESkatSamtykkeSchema } from './eSkatSamtykke';
import * as JoiObj from "joi";


    export const ESkatSchema = JoiObj.object({
        data:
            
        JoiObj.link('#ESkatDataSchema')


.required()


        ,
        harGammeltESkat:
            
                    JoiObj.boolean()
                


.required()


        ,
        samtykke:
            
        JoiObj.link('#ESkatSamtykkeSchema')


.required()


        
    })
                .shared(ESkatDataSchema)
                .shared(ESkatSamtykkeSchema)
.id('ESkatSchema')

export interface ESkat { 
    data: ESkatData;
    harGammeltESkat: boolean;
    samtykke: ESkatSamtykke;
}

