/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const IndbetalingSikringSchema = JoiObj.string().valid(
        'Ja'
        ,
        'Nej'
        ,
        'Ingen'
        
    )
.id('IndbetalingSikringSchema')

export type IndbetalingSikring = 'Ja' | 'Nej' | 'Ingen';

export const IndbetalingSikring = {
    Ja: 'Ja' as IndbetalingSikring,
    Nej: 'Nej' as IndbetalingSikring,
    Ingen: 'Ingen' as IndbetalingSikring
};

