import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'mitpfa-climate-footprint-disclaimer',
  templateUrl: './climate-footprint-disclaimer.component.html',
  styleUrls: ['./climate-footprint-disclaimer.component.scss']
})
export class ClimateFootprintDisclaimerComponent {
  constructor(
    private readonly dialogRef: MatDialogRef<ClimateFootprintDisclaimerComponent>
  ) {}

  close(): void {
    this.dialogRef.close();
  }
}
