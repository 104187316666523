<mat-form-field
  [hintLabel]="data.hint"
  (mousedown)="$event.stopPropagation()"
  (click)="$event.preventDefault()"
  color="accent"
>
  <mat-label>{{ data.label }}</mat-label>
  <input matInput #input [formControl]="comment" maxlength="50" />
  <mat-hint align="end">{{ input.value?.length || 0 }}/50</mat-hint>
</mat-form-field>
