import { Injectable } from '@angular/core';
import { GuideStepType } from '@pfa/core';
import {
  InvestmentConcept,
  OpsparetVaerdi,
  PensionsKundeGenerelleData,
  QuestionReached
} from '@pfa/gen';
import { InvestmentGuideQuestion } from './investment-guide-configuration.service';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  CurrentQuestionSubject,
  CurrentQuestionSubjectValue
} from './investment-guide.component';

@Injectable()
export class InvestmentGuideService {
  private advisorShortFlow: boolean = null;
  private climateRelevance: boolean;
  private currentQuestion$: CurrentQuestionSubject;
  private _showWaitForCalculation = false;

  public initCurrentQuestion(
    isAdvisor: boolean,
    currentQuestion: QuestionReached
  ): void {
    this.currentQuestion$ = new BehaviorSubject<CurrentQuestionSubjectValue>({
      value: isAdvisor ? 'WELCOME' : currentQuestion
    });
  }

  public getQuestionReached(): QuestionReached {
    return this.currentQuestion$.getValue().value;
  }

  public getCurrentQuestion$(): Observable<CurrentQuestionSubjectValue> {
    return this.currentQuestion$.asObservable();
  }

  public updateCurrentQuestion(
    currentQuestionSubjectValue: CurrentQuestionSubjectValue
  ): void {
    this.currentQuestion$.next(currentQuestionSubjectValue);
  }

  get showWaitForCalculation(): boolean {
    return this._showWaitForCalculation;
  }

  set showWaitForCalculation(value: boolean) {
    this._showWaitForCalculation = value;
  }

  isShortFlowRunning(): boolean {
    return this.advisorShortFlow;
  }

  markFlowAsShort(shortFlowFlag: boolean) {
    this.advisorShortFlow = shortFlowFlag;
  }

  getFirstEconomyQuestion(
    pensionCustomer: PensionsKundeGenerelleData,
    pensionInfoSavedValues: OpsparetVaerdi,
    now: Date
  ): InvestmentGuideQuestion | undefined {
    if (pensionCustomer.isRaadgiver && this.isShortFlowRunning()) {
      return InvestmentGuideQuestion.economicalImpact;
    }

    if (this.isAgeExceeded(pensionCustomer.alder)) {
      if (
        pensionInfoSavedValues &&
        pensionInfoSavedValues.registreretDato < this.getExpirationDate(now)
      ) {
        return InvestmentGuideQuestion.retrievePensioninfo;
      } else {
        return InvestmentGuideQuestion.otherSavings;
      }
    }

    return InvestmentGuideQuestion.economicalImpact;
  }

  getQuestionBeforePensionInfo(
    conceptChoice: InvestmentConcept[]
  ): InvestmentGuideQuestion | undefined {
    if (conceptChoice.indexOf('YOU_INVEST') === -1) {
      return InvestmentGuideQuestion.involvement;
    }

    return InvestmentGuideQuestion.numberOfTrades;
  }

  getQuestionBeforeOtherSavings(
    conceptChoice: InvestmentConcept[],
    pensionCustomer: PensionsKundeGenerelleData,
    pensionInfoSavedValues: OpsparetVaerdi,
    now: Date
  ): InvestmentGuideQuestion | undefined {
    if (
      this.isAgeExceeded(pensionCustomer.alder) &&
      pensionInfoSavedValues &&
      pensionInfoSavedValues.registreretDato < this.getExpirationDate(now)
    ) {
      return InvestmentGuideQuestion.retrievePensioninfo;
    }

    if (conceptChoice.indexOf('YOU_INVEST') === -1) {
      return InvestmentGuideQuestion.involvement;
    }

    return InvestmentGuideQuestion.numberOfTrades;
  }

  getQuestionBeforeEconomicalImpact(
    conceptChoice: InvestmentConcept[],
    pensionCustomer: PensionsKundeGenerelleData
  ): InvestmentGuideQuestion | undefined {
    if (pensionCustomer.isRaadgiver && this.isShortFlowRunning()) {
      if (conceptChoice.indexOf('YOU_INVEST') === -1) {
        return InvestmentGuideQuestion.involvement;
      }

      return InvestmentGuideQuestion.numberOfTrades;
    }

    if (this.isAgeExceeded(pensionCustomer.alder)) {
      return InvestmentGuideQuestion.hasDebt;
    }

    if (conceptChoice.indexOf('YOU_INVEST') === -1) {
      return InvestmentGuideQuestion.involvement;
    }

    return InvestmentGuideQuestion.numberOfTrades;
  }

  setClimateRelevance(relevance: boolean) {
    this.climateRelevance = relevance;
  }

  getClimateRelevance() {
    return this.climateRelevance;
  }

  checkStepType(question: QuestionReached) {
    switch (question) {
      case InvestmentGuideQuestion.involvement:
      case InvestmentGuideQuestion.economicalImpact:
      case InvestmentGuideQuestion.riskWillingness:
      case InvestmentGuideQuestion.riskProfile:
      case InvestmentGuideQuestion.lossability:
      case InvestmentGuideQuestion.climateRelevance:
        return GuideStepType.MANDATORY;
      case InvestmentGuideQuestion.investmentKnowledge:
      case InvestmentGuideQuestion.tradingExperience:
      case InvestmentGuideQuestion.numberOfTrades:
      case InvestmentGuideQuestion.retrievePensioninfo:
      case InvestmentGuideQuestion.otherSavings:
      case InvestmentGuideQuestion.realEstate:
      case InvestmentGuideQuestion.secondaryResidence:
      case InvestmentGuideQuestion.hasDebt:
      case InvestmentGuideQuestion.climateExtendedFocus:
      case InvestmentGuideQuestion.climateWillingness:
      case InvestmentGuideQuestion.climatePriority:
        return GuideStepType.OPTIONAL;
    }
  }

  isAgeExceeded(age: number): boolean {
    return age >= 57;
  }

  private getExpirationDate(now: Date): Date {
    return new Date(now.setDate(now.getDate() - 30));
  }
}
