import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CustomerOptionsApiService } from '@pfa/api';
import {
  ContentService,
  ContentUtilService,
  NumberFormatPipe
} from '@pfa/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  CustomerOptions,
  IndividualcustomerprofileStore,
  OpsparingPoliceDetaljer
} from '@pfa/gen';

@Component({
  selector: 'mitpfa-savings-policy-details-marketrate',
  templateUrl: './savings-policy-details-marketrate.component.html',
  styleUrls: ['./savings-policy-details-marketrate.component.scss']
})
export class SavingsPolicyDetailsMarketrateComponent
  implements OnInit, OnDestroy
{
  @Input() savingsPolicyDetails: OpsparingPoliceDetaljer;
  @Input() unpaidPALTooltip: string;
  @Input() chosenYear: number;

  paymentTooltip: string;
  showAdm: boolean;
  isCommissionAndRemuneration: boolean;
  initialized: boolean;

  private readonly unsubscribe = new Subject<void>();

  constructor(
    private readonly contentUtilService: ContentUtilService,
    private readonly contentService: ContentService,
    private readonly numberFormat: NumberFormatPipe,
    private readonly individualcustomerprofileStore: IndividualcustomerprofileStore
  ) {}

  ngOnInit(): void {
    this.individualcustomerprofileStore
      .individualcustomerprofileCustomeroptionsGet()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((data: CustomerOptions) => {
        this.isCommissionAndRemuneration = data.commissionAndRemuneration;

        const admData =
          this.savingsPolicyDetails.betalingForAdministrationOgService;
        this.showAdm =
          admData.administration.length > 0 ||
          admData.omkostningerTilMaegler.length > 0 ||
          admData.prisForRaadgivning.length > 0 ||
          admData.omkostningerVedInvestering.length > 0 ||
          admData.aendringerPensionsbevis?.aendringerForPensionsBevis?.length >
            0 ||
          admData.aendringerPensionsbevis?.linieElementer?.length > 0;
        this.handleTexts();

        this.initialized = true;
      });

    this.contentService.languageState
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => this.handleTexts());
  }

  handleTexts(): void {
    this.paymentTooltip = this.savingsPolicyDetails.risikoOverskudFraKR
      ? this.contentUtilService.getContent('DL.OS01.I210b', [
          this.numberFormat.transform(
            this.savingsPolicyDetails.risikoOverskudFraKR,
            2
          )
        ])
      : this.contentUtilService.getContent('DL.OS01.I210a');
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
