/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const ExtentTypeSchema = JoiObj.string().valid(
        'WHOLE_POLICY'
        ,
        'DEDUCTIBLE'
        ,
        'NON_DEDUCTIBLE'
        ,
        'MIXED'
        
    )
.id('ExtentTypeSchema')

export type ExtentType = 'WHOLE_POLICY' | 'DEDUCTIBLE' | 'NON_DEDUCTIBLE' | 'MIXED';

export const ExtentType = {
    WholePolicy: 'WHOLE_POLICY' as ExtentType,
    Deductible: 'DEDUCTIBLE' as ExtentType,
    NonDeductible: 'NON_DEDUCTIBLE' as ExtentType,
    Mixed: 'MIXED' as ExtentType
};

