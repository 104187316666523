import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { ForsikringDetaljer, PensionsInfoOrdninger } from '@pfa/gen';
import { BehaviorSubject } from 'rxjs';
import {
  InsuranceGuideConfigurationService,
  InsuranceGuideState,
  InsuranceGuideQuestion,
  InsuranceGuideQuestionValue
} from '../insurance-guide-configuration.service';
import { InsuranceGuideService } from '../insurance-guide.service';
import { InsuranceUtilService } from '@pfaform';

@Component({
  selector: 'mitpfa-insurance-guide-insurance-cover-step',
  templateUrl: './insurance-guide-insurance-cover-step.component.html',
  styleUrls: ['./insurance-guide-insurance-cover-step.component.scss']
})
export class InsuranceGuideInsuranceCoverStepComponent
  implements OnInit, AfterViewInit
{
  @Input() currentQuestion: BehaviorSubject<
    InsuranceGuideQuestionValue | undefined
  >;
  @Input() pensionInfo: PensionsInfoOrdninger;
  @Input() insuranceDetails: ForsikringDetaljer;

  public multiplier: number;
  insuranceGuideDisabled: InsuranceGuideState;
  locScaleText: string;
  locScaleTooltipText: string;

  expanded: InsuranceGuideState;

  public get loocExpanded(): boolean {
    return this.expanded?.looc;
  }

  public set loocExpanded(value: boolean) {
    this.expanded.looc = value;
    if (!this.isSingleColumn()) {
      this.expanded.ci = value;
      this.expanded.life = value;
    }
  }

  public get ciExpanded(): boolean {
    return this.expanded?.ci;
  }

  public set ciExpanded(value: boolean) {
    this.expanded.ci = value;

    if (!this.isSingleColumn()) {
      this.expanded.looc = value;
      this.expanded.life = value;
    }
  }

  public get lifeExpanded(): boolean {
    return this.expanded?.life;
  }

  public set lifeExpanded(value: boolean) {
    this.expanded.life = value;
    if (!this.isSingleColumn()) {
      this.expanded.ci = value;
      this.expanded.looc = value;
    }
  }

  private isSingleColumn() {
    return window?.innerWidth < 992;
  }

  public get coverageAmount(): number {
    return this.insuranceDetails.originalTilpasForsikringstal
      .daekningAendringKritiskSygdomSelv.bruttobeloeb
      ? this.insuranceDetails.originalTilpasForsikringstal
          .daekningAendringKritiskSygdomSelv.bruttobeloeb
      : 0;
  }

  public goNext(): void {
    if (this.insuranceGuideService.isShortFlowRunning()) {
      this.currentQuestion.next({ value: InsuranceGuideQuestion.risk });
    } else {
      this.currentQuestion.next({ value: InsuranceGuideQuestion.pensionInfo });
    }
    window.scroll(0, 0);
  }

  public goBack(): void {
    this.currentQuestion.next(undefined);
  }

  constructor(
    private readonly insuranceUtilService: InsuranceUtilService,
    private readonly insuranceGuideService: InsuranceGuideService,
    private readonly insuranceGuideConfigurationService: InsuranceGuideConfigurationService
  ) {}

  ngOnInit(): void {
    this.multiplier = this.insuranceUtilService.getCurrentMultiplier(
      this.pensionInfo
    );

    this.locScaleText = this.insuranceUtilService.getLocScaleTag(
      this.insuranceDetails.locScaleInformation
    );
    this.locScaleTooltipText = this.insuranceUtilService.getLocScaleTooltip(
      this.insuranceDetails.locScaleInformation.current === 'LOC_100'
    );

    this.insuranceGuideDisabled =
      this.insuranceGuideConfigurationService.getInsuranceGuideDisabled(
        this.insuranceDetails
      );

    this.expanded = {
      looc: false,
      life: false,
      ci: false
    };
  }

  ngAfterViewInit(): void {
    window.scroll(0, 0);
  }
}
