<h4
  [coContent]="'DL.OS01.C421'"
  [coContentSubst]="
    savingsPolicyDetails.fraDato + '|' + savingsPolicyDetails.tilDato
  "
></h4>
<co-card-split-horizontal [noBottom]="true" class="header-fill-all">
  <div header>
    <ng-container *ngFor="let opsparing of savingsPolicyDetails.opsparinger">
      <div
        class="detail-line"
        *ngIf="opsparing.contentId === 'DL.OS01.C104' || opsparing.value !== 0"
      >
        <div class="text--bold" [coContent]="opsparing.contentId"></div>
        <div class="nowrap">
          {{ opsparing.value | numberFormat }}
          <span [coContent]="'Global.kroner'"></span>
        </div>
      </div>
    </ng-container>
  </div>
</co-card-split-horizontal>
