/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PolicyType } from './policyType';
import { PolicyTypeSchema } from './policyType';
import { AmountCurrency } from './amountCurrency';
import { AmountCurrencySchema } from './amountCurrency';
import { PolicyStatus } from './policyStatus';
import { PolicyStatusSchema } from './policyStatus';
import { ExtendedContractCategory } from './extendedContractCategory';
import { ExtendedContractCategorySchema } from './extendedContractCategory';
import { SalesOrganizationName } from './salesOrganizationName';
import { SalesOrganizationNameSchema } from './salesOrganizationName';
import * as JoiObj from "joi";


    export const PFAPensionProductSchema = JoiObj.object({
        benefitSalary:
            
        JoiObj.link('#AmountCurrencySchema')

.allow(null)



        ,
        canRaiseContribution:
            
                    JoiObj.boolean()
                


.required()


        ,
        departureExtensionDate:
            
            JoiObj.date()

.allow(null)



        ,
        extendedContractCategory:
            
        JoiObj.link('#ExtendedContractCategorySchema')


.required()


        ,
        headerScheme:
            
                    JoiObj.number()
                


.required()


        ,
        noSavings:
            
                    JoiObj.boolean()
                


.required()


        ,
        partialScheme:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        payerId:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        policyActive:
            
                    JoiObj.boolean()
                


.required()


        ,
        policyNumber:
            
                    JoiObj.number()
                


.required()


        ,
        policyRanking:
            
                    JoiObj.number()
                


.required()


        ,
        policyStatus:
            
        JoiObj.link('#PolicyStatusSchema')


.required()


        ,
        policyType:
            
        JoiObj.link('#PolicyTypeSchema')


.required()


        ,
        premiumAllowed:
            
                    JoiObj.boolean()
                


.required()


        ,
        premiumAmount:
            
        JoiObj.link('#AmountCurrencySchema')


.required()


        ,
        premiumSalary:
            
        JoiObj.link('#AmountCurrencySchema')


.required()


        ,
        reserveTransferAllowed:
            
                    JoiObj.boolean()
                


.required()


        ,
        salaryRelatedProduct:
            
                    JoiObj.boolean()
                


.required()


        ,
        salesOrganizationName:
            
        JoiObj.link('#SalesOrganizationNameSchema')


.required()


        ,
        selfService:
            
                    JoiObj.boolean()
                


.required()


        ,
        singlePremiumAllowed:
            
                    JoiObj.boolean()
                


.required()


        ,
        specialAgreementName:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        validFromDate:
            
            JoiObj.date()


.required()


        
    })
                .shared(AmountCurrencySchema)
                .shared(ExtendedContractCategorySchema)
                .shared(PolicyStatusSchema)
                .shared(PolicyTypeSchema)
                .shared(AmountCurrencySchema)
                .shared(AmountCurrencySchema)
                .shared(SalesOrganizationNameSchema)
.id('PFAPensionProductSchema')

export interface PFAPensionProduct { 
    benefitSalary?: AmountCurrency;
    canRaiseContribution: boolean;
    departureExtensionDate?: Date;
    extendedContractCategory: ExtendedContractCategory;
    headerScheme: number;
    noSavings: boolean;
    partialScheme?: string;
    payerId?: string;
    policyActive: boolean;
    policyNumber: number;
    policyRanking: number;
    policyStatus: PolicyStatus;
    policyType: PolicyType;
    premiumAllowed: boolean;
    premiumAmount: AmountCurrency;
    premiumSalary: AmountCurrency;
    reserveTransferAllowed: boolean;
    salaryRelatedProduct: boolean;
    salesOrganizationName: SalesOrganizationName;
    selfService: boolean;
    singlePremiumAllowed: boolean;
    specialAgreementName?: string;
    validFromDate: Date;
}
export namespace PFAPensionProduct {
}


