<div class="header">
  <h5 class="headline" [coContent]="data.headline"></h5>
  <co-icon-times class="document-close" (click)="close()"></co-icon-times>
</div>
<div class="document-body">
  <div class="document-headline">
    <co-icon-document></co-icon-document>
    <h3 class="document-name" [coContent]="data.documentName"></h3>
  </div>
  <ng2-pdfjs-viewer
    class="document-view"
    [pdfSrc]="data.documentUrl"
    [viewBookmark]="false"
    pagemode="none"
  ></ng2-pdfjs-viewer>
</div>
