<div>
  <div class="row spacing-section--over">
    <div class="col-sm-4">
      <p [coContent]="'DL.MD01.C344'" class="text--manchet"></p>
      <p [coContent]="'DL.MD01.C345'"></p>
    </div>
    <div class="col-sm">
      <form [formGroup]="childForms">
        <mat-radio-group [formControlName]="'isLegalProvider'">
          <mat-radio-button
            data-test-id="legal-provider-children-selected"
            [value]="true"
            (click)="hasChildsSelected()"
          >
            {{ 'DL.MD01.C330' | content }}
          </mat-radio-button>
          <mat-radio-button
            data-test-id="legal-provider-children-not-selected"
            [value]="false"
            (click)="hasNotChildrenSelected()"
          >
            {{ 'DL.MD01.C331' | content }}
          </mat-radio-button>
        </mat-radio-group>
      </form>
    </div>
  </div>
  <div
    *ngIf="childForms.controls['isLegalProvider'].value"
    class="section-form spacing-unit--over"
  >
    <div class="button-add align--right">
      <button
        *ngIf="childForms.controls['isLegalProvider'].value"
        mat-raised-button
        class="button-primary--level3"
        (click)="addChild()"
        data-test-id="legal-provider-add-child-btn"
      >
        <co-icon-add class="icon"></co-icon-add>
        <span [coContent]="'DL.INV.C160'"></span>
      </button>
    </div>
    <div
      *ngFor="let childForm of childFormArray.controls; let index = index"
      class="child-form"
    >
      <co-accordion
        [title]="childForm.get('childName')?.value"
        [initExpanded]="childForm.invalid && childForm.pristine"
        #childAccordion
      >
        <form [formGroup]="childForm">
          <div class="row">
            <div class="col-sm-6">
              <p [coContent]="'DL.MD01.C348'" class="text--manchet"></p>
              <p [coContent]="'DL.MD01.C349'" class="text--info"></p>
            </div>
            <div class="col-sm-3 offset-sm-1">
              <mat-form-field color="accent">
                <mat-label>{{ 'DL.MD01.C337' | content }}</mat-label>
                <input
                  matInput
                  [required]="true"
                  type="text"
                  [formControlName]="'childName'"
                  [placeholder]="'DL.MD01.C337' | content"
                  name="childName"
                  id="childName"
                />
                <mat-error
                  *ngIf="childForm.get('childName').errors?.required"
                  [coContent]="'DL.MD01.C12'"
                ></mat-error>
                <mat-error
                  *ngIf="childForm.get('childName').errors?.pattern"
                  [coContent]="'DL.MD01.C121'"
                ></mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row spacing-unit--double-over">
            <div class="col-sm-6">
              <p [coContent]="'DL.MD01.C350'" class="text--manchet"></p>
              <p [coContent]="'DL.MD01.C351'" class="text--info"></p>
            </div>
            <div class="col-sm-3 offset-sm-1">
              <div class="datepicker-wrap">
                <mat-form-field color="accent">
                  <mat-label>{{ 'DL.MD01.C352' | content }}</mat-label>
                  <input
                    matInput
                    [min]="minDate"
                    [max]="currentDate"
                    [required]="true"
                    [matDatepicker]="birthDate"
                    [formControlName]="'birthDate'"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="birthDate"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #birthDate></mat-datepicker>
                  <mat-error
                    *ngIf="childForm.get('birthDate')?.invalid"
                    [coContent]="'DL.MD01.C18'"
                  ></mat-error>
                </mat-form-field>
                <div
                  class="datepicker-overlay"
                  (click)="birthDate.open()"
                  (mouseover)="datepickerHover(true)"
                  (mouseout)="datepickerHover(false)"
                ></div>
              </div>
            </div>
          </div>
          <div class="row spacing-unit--double-over">
            <div class="col-sm-6">
              <p [coContent]="'DL.MD01.C353'" class="text--manchet"></p>
              <p [coContent]="'DL.MD01.C354'" class="text--info"></p>
            </div>
            <div class="col-sm-3 offset-sm-1">
              <mat-form-field color="accent">
                <mat-label>{{ 'DL.MD01.C355' | content }}</mat-label>
                <mat-select
                  [required]="true"
                  [formControlName]="'relationType'"
                >
                  <mat-option
                    *ngFor="let option of childRelationOptions"
                    [value]="option.value"
                  >
                    {{ option.label | content }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="spacing-unit--double-over align--center">
            <button
              mat-raised-button
              (click)="childAccordion.expanded = false"
              [disabled]="childForm.invalid"
              [coContent]="'DL.MD01.C447'"
              class="button-secondary"
            >
              Add
            </button>
          </div>
        </form>
      </co-accordion>
      <div class="button-remove align--right">
        <button
          mat-raised-button
          class="button-primary--level3"
          (click)="removeChild(index)"
        >
          <co-icon-delete class="icon"></co-icon-delete>
          <span [coContent]="'DL.MD01.C148'"></span>
        </button>
      </div>
    </div>
  </div>
</div>
