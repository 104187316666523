<tr class="forced-thin-border">
  <td
    [attr.data-label]="'DL.OS01.C425' | content"
    class="responsiv-top-padding"
  >
    <co-table-accordion-control
      [contentId]="'DL.OS01.C322'"
      (expandedChange)="toggleExpanded($event)"
    ></co-table-accordion-control>
  </td>
  <td
    class="align--right text--bold"
    [attr.data-label]="'DL.OS01.C426' | content"
  >
    <co-primary-number
      [amount]="
        savingsPolicyDetails.samletVaerdiOpsparingInklusivTilbageFoerselInvalidedepotVedOphoerAfInvaliditet
      "
      [showDecimals]="true"
      class="size-sm"
    ></co-primary-number>
  </td>
</tr>
<ng-container *ngIf="expanded">
  <tr class="forced-thin-border">
    <td [attr.data-label]="'DL.OS01.C425' | content">
      <span [coContent]="'DL.OS01.C321'"></span>
      <co-tooltip [text]="'DL.OS01.I321'"></co-tooltip>
    </td>
    <td class="align--right" [attr.data-label]="'DL.OS01.C426' | content">
      {{
        savingsPolicyDetails.tilbageFoerselInvalidedepotVedOphoerAfInvaliditet
          | numberFormat: 2
      }}
      <span [coContent]="'Global.kroner'"></span>
    </td>
  </tr>
</ng-container>
