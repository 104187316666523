import { Component, Input } from '@angular/core';
import { ViderefoerelseSammenligningRaekke } from '@pfa/gen';

@Component({
  selector: 'co-daekning-current',
  templateUrl: './daekning-current.component.html'
})
export class DaekningCurrentComponent {
  @Input() daekning: ViderefoerelseSammenligningRaekke;
}
