<div
  *ngIf="isAdvisor"
  class="menu-container"
  [ngClass]="menu.open ? 'menu-container--open' : 'menu-container--closed'"
>
  <nav class="menu" aria-label="advisor navigation">
    <header class="menu-header">
      <co-icon-arrow-left
        (click)="toggleMenu()"
        class="menu-header-icon menu-close"
        role="button"
      ></co-icon-arrow-left>
      <div>
        <a
          (click)="navigateToPro()"
          title="Pro"
          *ngIf="menu.displayHeaderLinks"
          role="button"
        >
          <co-icon-pro class="menu-header-icon"></co-icon-pro>
        </a>
        <co-icon-refresh-customer
          (click)="refreshCustomer()"
          class="menu-header-icon"
          [title]="'DL.MN01.C67' | content"
          role="button"
        ></co-icon-refresh-customer>
        <a href="/system" title="System" *ngIf="menu.displayHeaderLinks">
          <co-icon-system class="menu-header-icon"></co-icon-system>
        </a>
        <a
          class="pfa-360"
          [routerLink]="'/raadgiveroverblik'"
          [title]="'DL.RM01.C08' | content"
          *ngIf="menu.show360 && menu.displayHeaderLinks"
        >
          <span
            [ngClass]="
              'advice-severity advice-severity--' + menu.adviceSeverity
            "
          ></span>
          <span>PFA 360°</span>
        </a>
      </div>
    </header>
    <div
      *ngFor="let menuSection of menu.sections"
      class="menu-item"
      [ngClass]="{
        'menu-item--open': menuSection.open
      }"
    >
      <div
        (click)="onSelectSection(menuSection)"
        class="menu-item-content"
        role="button"
      >
        <span [coContent]="menuSection.contentId"></span>
        <co-icon-circle-checkmark
          *ngIf="menuSection.visited"
          [border]="false"
          class="menu-item-check"
        ></co-icon-circle-checkmark>
      </div>
      <div
        class="menu-item-children"
        [ngClass]="{
          'menu-item-children--open': menuSection.open,
          'menu-item-children--closed': !menuSection.open
        }"
      >
        <div
          *ngFor="let subMenuItem of menuSection.children"
          class="menu-item sub-menu-item"
        >
          <div
            class="menu-item-selected"
            *ngIf="subMenuItem.selected || !subMenuItem.hide"
          ></div>
          <div
            *ngIf="!subMenuItem.hide"
            class="menu-item-content"
            (click)="navigate(subMenuItem)"
            role="button"
          >
            <span [coContent]="subMenuItem.contentId"></span
            ><co-icon-circle-checkmark
              *ngIf="subMenuItem.visited"
              [border]="false"
              class="menu-item-check"
            ></co-icon-circle-checkmark>
          </div>
        </div>
      </div>
    </div>
  </nav>
</div>
<div
  class="toggle"
  *ngIf="isAdvisor"
  (click)="toggleMenu()"
  [ngClass]="menu.open ? 'toggle--hidden' : 'toggle--shown'"
  aria-label="open advisor navigation"
  role="button"
>
  <co-icon-eventlog class="toggle-icon"></co-icon-eventlog>
</div>
