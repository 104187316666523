<co-loading [show]="!initialized"></co-loading>
<div *ngIf="initialized">
  <div class="row">
    <div class="col">
      <mitpfa-dashboard-savings
        [pensionCustomer]="pensionCustomer"
        (contentLoaded)="savingsLoaded()"
      ></mitpfa-dashboard-savings>
    </div>
  </div>
  <div class="spacing-section--over">
    <h1 [coContent]="'DL.MN01.C40'"></h1>
    <mitpfa-dashboard-insurance
      (contentLoaded)="insuranceLoaded()"
    ></mitpfa-dashboard-insurance>
  </div>

  <div class="spacing-section--over">
    <h1 [coContent]="'DL.MF.C01'"></h1>
    <co-loading [show]="!(pensionCustomer && customerOptions)"></co-loading>
    <mitpfa-dashboard-advantages
      *ngIf="pensionCustomer && customerOptions && customerProfile"
      [pensionCustomer]="pensionCustomer"
      [customerOptions]="customerOptions"
      [customerProfile]="customerProfile"
    ></mitpfa-dashboard-advantages>
  </div>
</div>
