import { SignatureIframeResponse } from '@pfa/gen';

export enum InjuryClaimSigningType {
  SIGNATURE,
  CONSENT
}

export interface InjuryClaimSigningChildData {
  isChild: boolean;
  childName: string | undefined;
  childCpr: string | undefined;
}

export const defaultSignatureIframeResponse: SignatureIframeResponse = {
  fejl: false,
  fejlTekst: '',
  fejlkode: '',
  iframesrc: '',
  revisionKey: '',
  script: '',
  serviceInfo: undefined
};
