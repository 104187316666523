/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AttachedFileElement } from './attachedFileElement';
import { AttachedFileElementSchema } from './attachedFileElement';
import * as JoiObj from "joi";


    export const PolicyOfferAttachedFileListSchema = JoiObj.object({
        attachedFileElements:
                JoiObj.array().items(
        
        JoiObj.link('#AttachedFileElementSchema')

        )
        

.required()


        
    })
                .shared(AttachedFileElementSchema)
.id('PolicyOfferAttachedFileListSchema')

export interface PolicyOfferAttachedFileList { 
    attachedFileElements: Array<AttachedFileElement>;
}

