import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  ResolveFn,
  RouterStateSnapshot
} from '@angular/router';
import { EksternOverfoerselAnbefaling, ExternaltransferStore } from '@pfa/gen';

export const ExternalTransferResolver: ResolveFn<
  EksternOverfoerselAnbefaling
> = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
  inject(ExternaltransferStore).externaltransferGet();
