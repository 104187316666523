/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HealthCaseDecision } from './healthCaseDecision';
import { HealthCaseDecisionSchema } from './healthCaseDecision';
import { StatusHealthCase } from './statusHealthCase';
import { StatusHealthCaseSchema } from './statusHealthCase';
import * as JoiObj from "joi";


    export const PFAPensionProductOfferHealthCaseElementSchema = JoiObj.object({
        decision:
            
        JoiObj.link('#HealthCaseDecisionSchema')

.allow(null)



        ,
        dueDate:
            
            JoiObj.date()


.required()


        ,
        expired:
            
                    JoiObj.boolean()
                


.required()


        ,
        guid:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        statusDate:
            
            JoiObj.date()


.required()


        ,
        statusHealthCase:
            
        JoiObj.link('#StatusHealthCaseSchema')


.required()


        
    })
                .shared(HealthCaseDecisionSchema)
                .shared(StatusHealthCaseSchema)
.id('PFAPensionProductOfferHealthCaseElementSchema')

export interface PFAPensionProductOfferHealthCaseElement { 
    decision?: HealthCaseDecision;
    dueDate: Date;
    expired: boolean;
    guid: string;
    statusDate: Date;
    statusHealthCase: StatusHealthCase;
}
export namespace PFAPensionProductOfferHealthCaseElement {
}


