/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const UdbetalingsformTypeSchema = JoiObj.string().valid(
        'ENGANGSUDBETALING'
        ,
        'LOEBENDE_UDBETALING'
        ,
        'LIVSVARIG_UDBETALING'
        
    )
.id('UdbetalingsformTypeSchema')

export type UdbetalingsformType = 'ENGANGSUDBETALING' | 'LOEBENDE_UDBETALING' | 'LIVSVARIG_UDBETALING';

export const UdbetalingsformType = {
    Engangsudbetaling: 'ENGANGSUDBETALING' as UdbetalingsformType,
    LoebendeUdbetaling: 'LOEBENDE_UDBETALING' as UdbetalingsformType,
    LivsvarigUdbetaling: 'LIVSVARIG_UDBETALING' as UdbetalingsformType
};

