<h3 coContent="DL.FG01.C1397"></h3>

<ng-container *ngIf="hasSpouse">
  <p class="text--manchet" [coContent]="'DL.MD01.C327'"></p>
  <p [coContent]="'DL.MD01.C328'"></p>
</ng-container>

<mitpfa-family-partner-info
  [partnerInfoForm]="partnerInfoForm"
  [pensionCustomer]="pensionCustomer"
  [hasSpouse]="hasSpouse"
></mitpfa-family-partner-info>

<mitpfa-family-children [childForms]="childForms"></mitpfa-family-children>

<co-warning
  *ngIf="familyInformation.egenindtastningerFejlede || showError"
  class="spacing-unit--over"
>
  <span [coContent]="'DL.MD01.C118'"></span>
</co-warning>
<co-warning
  *ngIf="!isInputValid() && (partnerInfoForm?.touched || childForms?.touched)"
  class="spacing-unit--over"
>
  <span [coContent]="'DL.MD01.C206'"></span>
</co-warning>

<div class="spacing-unit--over align--center">
  <button
    class="button-secondary"
    mat-raised-button
    [disabled]="!isInputValid()"
    (click)="goNext()"
    coContent="Global.GemFortsaet"
    data-test-id="insurance-guide-your-family-btn"
  ></button>

  <div class="spacing-unit--half-over">
    <mitpfa-insurance-guide-back-button
      (back)="goBack()"
    ></mitpfa-insurance-guide-back-button>
  </div>
</div>
