/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { MaanedligOverfoerselTillaeg } from './maanedligOverfoerselTillaeg';
import { MaanedligOverfoerselTillaegSchema } from './maanedligOverfoerselTillaeg';
import { Sats } from './sats';
import { SatsSchema } from './sats';
import * as JoiObj from "joi";


    export const OverfoerselTillaegSatserSchema = JoiObj.object({
        activeNow:
            
                    JoiObj.boolean()
                


.required()


        ,
        name:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        updatedAt:
            
            JoiObj.date()


.required()


        ,
        updatedBy:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        uuid:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        validFrom:
            
            JoiObj.date()

.allow(null)



        ,
        validFromFormatted:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        year:
            
                    JoiObj.number()
                


.required()


        ,
        maaneder:
                JoiObj.array().items(
        
        JoiObj.link('#MaanedligOverfoerselTillaegSchema')

        )
        

.required()


        ,
        rentegruppe1:
            
                    JoiObj.boolean()
                


.required()


        ,
        rentegruppe2:
            
                    JoiObj.boolean()
                


.required()


        ,
        rentegruppe3:
            
                    JoiObj.boolean()
                


.required()


        ,
        rentegruppe4:
            
                    JoiObj.boolean()
                


.required()


        
    })
                .shared(MaanedligOverfoerselTillaegSchema)
.id('OverfoerselTillaegSatserSchema')

export interface OverfoerselTillaegSatser extends Sats { 
    maaneder: Array<MaanedligOverfoerselTillaeg>;
    rentegruppe1: boolean;
    rentegruppe2: boolean;
    rentegruppe3: boolean;
    rentegruppe4: boolean;
}

