<div
  *ngIf="pensionCustomer"
  class="pension-info-card"
  data-test-id="PI01-pension-info"
>
  <co-card [ngClass]="{ 'card--slim': slim }" [flat]="slim">
    <co-loading [show]="!piLoaded" class="loading"></co-loading>

    <div *ngIf="piLoaded && !piClosed">
      <div *ngIf="isShowingPIQuestionsDialog">
        <mitpfa-pension-info-external-transfers
          (saveIncludeExternalPoliciesStart)="
            saveIncludeExternalPoliciesStart()
          "
          (saveIncludeExternalPoliciesFinish)="
            saveIncludeExternalPoliciesFinish($event)
          "
        >
        </mitpfa-pension-info-external-transfers>
      </div>

      <div
        *ngIf="
          !isShowingPIQuestionsDialog &&
          (!pensionCustomer.harPensionsinfo ||
            pensionCustomer.pensionsinfoIncomplete ||
            pensionCustomer.pensionInfoDataInvalid ||
            !pensionCustomer.pensionInfoCustomerAnswerMissing)
        "
      >
        <mitpfa-pension-info-retrieve-info [pensionCustomer]="pensionCustomer">
        </mitpfa-pension-info-retrieve-info>
      </div>

      <div *ngIf="pensionCustomer.harPensionsinfo" class="info-box-link">
        <a
          [coContent]="'DL.PI01.C68'"
          class="link color--primary"
          (click)="openDocument()"
        >
        </a>
      </div>

      <div
        *ngIf="
          isPartnerFlow &&
          pensionCustomer.isRaadgiver &&
          pensionCustomer.harPensionsinfoPartner
        "
        class="info-box-link"
      >
        <a
          [coContent]="'DL.PI01.C116'"
          class="link color--primary"
          (click)="openDocument(true)"
        >
        </a>
      </div>

      <div
        *ngIf="isMitIDLogin && isPartnerFlow"
        [coContent]="'DL.PI01.C135'"
        class="spacing-unit--half-over"
      ></div>

      <div
        *ngIf="
          isMitIDLogin &&
          !pensionCustomer.pensionsinfoLukket &&
          !pensionCustomer.isRaadgiver
        "
      >
        <div class="info-box-button">
          <button
            mat-raised-button
            [ngClass]="{
              'button-primary--level3':
                pensionCustomer.pensionInfoCustomerAnswerMissing
            }"
            (click)="openPensionInfo()"
            data-test-id="requestInformationFromPensionsInfo"
          >
            <co-icon-add
              *ngIf="pensionCustomer.pensionInfoCustomerAnswerMissing"
            ></co-icon-add>
            <span [coContent]="'DL.PI01.C26'"></span>
          </button>
        </div>
      </div>

      <div *ngIf="!isMitIDLogin && !pensionCustomer.isRaadgiver">
        <div class="info-box-button">
          <button
            (click)="goToMitID()"
            mat-raised-button
            [coContent]="'DL.MI01.C08'"
          ></button>
        </div>
      </div>

      <co-warning *ngIf="fetchError" class="warning">
        <span [coContent]="'DL.PI01.C134'"></span>
      </co-warning>

      <co-info *ngIf="pensionCustomer.isRaadgiver" class="info-note note-doubt">
        <span [coContent]="'DL.PI01.C67'"></span>
      </co-info>

      <co-info
        *ngIf="!isMitIDLogin && !pensionCustomer.isRaadgiver"
        class="info-note note-doubt"
      >
        <div [coContent]="'DL.PI01.C139'"></div>
      </co-info>

      <co-info *ngIf="hasExternalTransfer" class="info-note note-doubt">
        <div [coContent]="'DL.PI01.C77'"></div>
      </co-info>

      <co-info
        *ngIf="pensionCustomer.pensionsinfoIncomplete"
        class="info-note note-doubt"
      >
        <span coContent="DL.PI01.C101"></span>
      </co-info>

      <co-info
        *ngIf="
          !isMitIDLogin &&
          pensionCustomer.isRaadgiver &&
          !pensionCustomer.harPensionsinfo
        "
        class="info-note note-doubt"
      >
        <span coContent="DL.PI01.C69"></span>
      </co-info>

      <co-loading
        [show]="showSaveIncludeExternalPoliciesSpinner"
        class="loading"
      ></co-loading>
    </div>

    <div *ngIf="piLoaded && piClosed">
      <div
        [coContent]="'DL.EO01.C442'"
        class="info-box-header text--manchet"
      ></div>

      <co-info class="info-note note-doubt">
        <span coContent="DL.PI01.C133"></span>
      </co-info>

      <co-loading [show]="true" class="loading"></co-loading>
    </div>
  </co-card>
</div>
