/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Speciality } from './speciality';
import { SpecialitySchema } from './speciality';
import * as JoiObj from "joi";


    export const ApplyForSchema = JoiObj.object({
        id:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        label:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        specialities:
                JoiObj.array().items(
        
        JoiObj.link('#SpecialitySchema')

        )
        

.required()


        
    })
                .shared(SpecialitySchema)
.id('ApplyForSchema')

export interface ApplyFor { 
    id: string;
    label: string;
    specialities: Array<Speciality>;
}

