import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { InvestmentGuideApiService } from '@pfa/api';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { InvestmentGuideInfo } from '@pfa/models';
import { CustomerprofileStore } from '@pfa/gen';

@Injectable({
  providedIn: 'root'
})
export class InvestmentGuideResolver implements Resolve<InvestmentGuideInfo> {
  constructor(
    private readonly investmentGuideService: InvestmentGuideApiService,
    private readonly customerprofileStore: CustomerprofileStore
  ) {}

  resolve(): InvestmentGuideInfo | Observable<InvestmentGuideInfo> {
    return this.customerprofileStore
      .customerprofileGet()
      .pipe(
        switchMap(customerProfile =>
          this.investmentGuideService.get(customerProfile.personDetail.globalId)
        )
      );
  }
}
