<co-alert class="full-page alert-center">
  <h3 coContent="DL.OE01.C16"></h3>
  <p class="text--manchet" coContent="DL.OE01.C17"></p>

  <p coContent="DL.OE01.C26"></p>

  <div class="button-box">
    <button
      coContent="DL.OE01.C25"
      (click)="refresh()"
      mat-raised-button
      class="button-secondary"
    ></button>
    <button
      (click)="logout()"
      mat-raised-button
      class="button-primary--level3 button-with-icon"
    >
      <co-icon-locked class="icon"></co-icon-locked>
      <span class="button-with-icon-text" coContent="DL.HE01.C19"></span>
    </button>
  </div>

  <ng-container *ngIf="showErrorDump">
    <ng-container *ngIf="this.errorDump.errorType">
      <div class="text--bold">Error Type</div>
      <pre>{{ this.errorDump.errorType }}</pre>
    </ng-container>

    <ng-container *ngIf="this.errorDump.errorCode">
      <div class="text--bold">Error Code</div>
      <pre>{{ this.errorDump.errorCode }}</pre>
    </ng-container>

    <ng-container *ngIf="this.errorDump.errorURL">
      <div class="text--bold">Error URL</div>
      <pre>{{ this.errorDump.errorURL }}</pre>
    </ng-container>

    <ng-container *ngIf="this.errorDump.errorDescription">
      <div class="text--bold">Error Description</div>
      <pre>{{ this.errorDump.errorDescription }}</pre>
    </ng-container>

    <ng-container *ngIf="this.errorDump.errorStackTrace">
      <div class="text--bold">Error Stack Trace</div>
      <pre>{{ this.errorDump.errorStackTrace }}</pre>
    </ng-container>
  </ng-container>

  <div class="time-of-error text--note">{{ dateTime }}</div>
  <div class="cf"></div>
</co-alert>
