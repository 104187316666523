import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FamilieRelationBarn, StamdataDetaljer } from '@pfa/gen';
import {
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { PersonNameValidator } from '@pfa/utils';
import * as moment from 'moment';

@Component({
  selector: 'mitpfa-pension-check-kids',
  templateUrl: './pension-check-kids.component.html',
  styleUrls: ['./pension-check-kids.component.scss']
})
export class PensionCheckKidsComponent implements OnInit {
  @Output() close = new EventEmitter<void>();
  @Input() familyInformation: StamdataDetaljer;

  public childForms: UntypedFormGroup;

  ngOnInit(): void {
    this.buildChildForms();
  }

  private buildChildForms(): void {
    const childs = new UntypedFormArray([]);
    if (this.familyInformation.familieRelationBoern) {
      this.familyInformation.familieRelationBoern.forEach(child => {
        childs.push(
          new UntypedFormGroup({
            familyRelationId: new UntypedFormControl(child.familieRelationId),
            childName: new UntypedFormControl(
              this.getFullName(child.fornavn, child.efternavn),
              {
                validators: [Validators.required, PersonNameValidator]
              }
            ),
            birthDate: new UntypedFormControl(
              moment(child.foedselsDato, 'DD.MM.YYYY'),
              {
                validators: Validators.required
              }
            ),
            relationType: new UntypedFormControl(child.barnType, {
              validators: Validators.required
            })
          })
        );
      });
    }
    this.childForms = new UntypedFormGroup({
      isLegalProvider: new UntypedFormControl(
        this.familyInformation.forsoergerpligt,
        {
          validators: Validators.required
        }
      ),
      childs: childs
    });
  }

  private toFamilyRelationArray(): FamilieRelationBarn[] {
    const childArray: FamilieRelationBarn[] = [];
    (this.childForms.get('childs') as UntypedFormArray).controls.forEach(
      childForm => {
        childArray.push({
          familieRelationId: childForm.get('familyRelationId').value,
          fornavn: this.getFirstNameFromFullName(
            childForm.get('childName').value
          ),
          efternavn: this.getLastNameFromFullName(
            childForm.get('childName').value
          ),
          foedselsDato: moment(childForm.get('birthDate').value).format(
            'DD.MM.YYYY'
          ),
          barnType: childForm.get('relationType').value,
          arveandel: null,
          serviceInfo: null
        });
      }
    );
    return childArray;
  }

  private getFirstNameFromFullName(fullName: string): string {
    if (fullName) {
      const split = fullName.split(' ');
      if (split.length > 1) {
        split.pop();
      }
      return split.join(' ');
    } else {
      return null;
    }
  }

  private getLastNameFromFullName(fullName: string) {
    if (fullName) {
      const split = fullName.split(' ');
      return split.length > 1 ? split.pop() : null;
    } else {
      return null;
    }
  }

  private getFullName(firstName: string, lastName: string): string {
    return (firstName ? firstName : '') + (lastName ? ' ' + lastName : '');
  }

  public complete() {
    this.familyInformation.forsoergerpligt =
      this.childForms.get('isLegalProvider').value;
    this.familyInformation.familieRelationBoern = this.toFamilyRelationArray();
    this.close.emit();
  }
}
