<!-- v6 -->
<svg
  id="Layer_1"
  data-name="Layer 1"
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 32 32"
>
  <g id="Canvas">
    <circle
      id="Large_circle"
      data-name="Large circle"
      cx="16"
      cy="16"
      r="16"
      fill="none"
    />
    <circle
      id="Small_circle"
      data-name="Small circle"
      cx="16"
      cy="16"
      r="12"
      fill="none"
    />
  </g>
  <g id="Icon">
    <g id="Triangle">
      <path
        d="M19,22.09H10c-1.65,0-2.32-1.17-1.5-2.6l1.5-2.6,3-5.19,1.5-2.6c.83-1.43,2.18-1.43,3,0L19,11.7l3,5.19,1.5,2.6c.83,1.43.15,2.6-1.5,2.6Z"
        fill="none"
        stroke="#000"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="0.7"
      />
    </g>
    <path
      id="Line"
      d="M16,17.1a.91.91,0,0,1-.9-.84l-.3-3.1a1.13,1.13,0,0,1,.29-.86,1.28,1.28,0,0,1,1.8,0l0,0a1.13,1.13,0,0,1,.29.86l-.3,3.1A.91.91,0,0,1,16,17.1Z"
    />
    <circle id="Dot" cx="16" cy="19" r="1.1" />
  </g>
</svg>
