import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { DigitalSalgContext } from '@pfa/gen';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { NewBusinessAdvisoryService } from '@pfa/services';

@Injectable({
  providedIn: 'root'
})
export class NewBusinessAdvisoryResolver
  implements Resolve<DigitalSalgContext>
{
  constructor(private newBusinessAdvisoryService: NewBusinessAdvisoryService) {}

  resolve(): DigitalSalgContext | Observable<DigitalSalgContext> {
    return this.newBusinessAdvisoryService.newBusinessAdvisory$.pipe(first());
  }
}
