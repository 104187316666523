/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ServiceInfo } from './serviceInfo';
import { ServiceInfoSchema } from './serviceInfo';
import { RenteForm } from './renteForm';
import { RenteFormSchema } from './renteForm';
import { EjendomArt } from './ejendomArt';
import { EjendomArtSchema } from './ejendomArt';
import { ResourceModel } from './resourceModel';
import { ResourceModelSchema } from './resourceModel';
import { Afdrag } from './afdrag';
import { AfdragSchema } from './afdrag';
import * as JoiObj from "joi";


    export const BoligSchema = JoiObj.object({
        serviceInfo:
            
        JoiObj.link('#ServiceInfoSchema')

.allow(null)



        ,
        afdrag:
            
        JoiObj.link('#AfdragSchema')


.required()


        ,
        andelFrivaerdi:
            
                    JoiObj.number()
                


.required()


        ,
        ejendomArt:
            
        JoiObj.link('#EjendomArtSchema')


.required()


        ,
        ejendomNavn:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        ejerAndel:
            
                    JoiObj.number()
                


.required()


        ,
        gaeldIdag:
            
                    JoiObj.number()
                


.required()


        ,
        gaeldVedPension:
            
                    JoiObj.number()
                


.required()


        ,
        renteForm:
            
        JoiObj.link('#RenteFormSchema')


.required()


        ,
        salgsvaerdi:
            
                    JoiObj.number()
                


.required()


        
    })
                .shared(ServiceInfoSchema)
                .shared(AfdragSchema)
                .shared(EjendomArtSchema)
                .shared(RenteFormSchema)
.id('BoligSchema')

export interface Bolig extends ResourceModel { 
    afdrag: Afdrag;
    andelFrivaerdi: number;
    ejendomArt: EjendomArt;
    ejendomNavn: string;
    ejerAndel: number;
    gaeldIdag: number;
    gaeldVedPension: number;
    renteForm: RenteForm;
    salgsvaerdi: number;
}
export namespace Bolig {
}


