/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const FrigivelseBeskedSchema = JoiObj.object({
        aendretAf:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        cprNr:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        foraeldelsesdato:
            
                    JoiObj.object()
                


.required()


        ,
        frigivelsesdato:
            
                    JoiObj.object()
                


.required()


        ,
        policetype:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        slet:
            
                    JoiObj.boolean()
                


.required()


        ,
        uuid:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        
    })
.id('FrigivelseBeskedSchema')

export interface FrigivelseBesked { 
    aendretAf: string;
    cprNr: string;
    foraeldelsesdato: object;
    frigivelsesdato: object;
    policetype: string;
    slet: boolean;
    uuid: string;
}

