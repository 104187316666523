export type MenuItemType =
  | 'SAVINGS'
  | 'ADVANTAGE'
  | 'INSURANCE'
  | 'PROFILE'
  | 'MESSAGES'
  | 'NEW_BUSINESS_ADVISORY'
  | 'MOBILE'
  | 'UNDEFINED';

export type CategoryMenuItemName =
  | 'TOTALE_FORMUE'
  | 'OPSPARINGSPOSTER'
  | 'PLANLÆG_PENSION'
  | 'INVESTERING'
  | 'FORDELE_I_PFA'
  | 'PARTNERSKAB'
  | 'VÆRKTØJ'
  | 'FORSIKRING'
  | 'MINE_DATA'
  | 'INSTILLINGER'
  | 'SERVICE_OG_VILKÅR'
  | 'ADGANGE'
  | 'MESSAGES'
  | 'ADVANTAGES';

export type SubMenuItemName =
  | 'OVERBLIK'
  | 'SAMLE_ANDRE_OPSPARINGER'
  | 'KONVERTER_TIL_PFA_PLUS'
  | 'REGNSKAB'
  | 'INDBETALINGER'
  | 'AFKAST'
  | 'KUNDEKAPITAL'
  | 'OMKOSTNINGER'
  | 'EKSTRA_INDSKUD'
  | 'SPAR_MERE_OP'
  | 'INTRO'
  | 'OPSPARINGSPLAN'
  | 'UDBETALINGSPLAN'
  | 'UDBETALINGER'
  | 'ÆNDRE_INVESTERING'
  | 'FIND_INVESTERINGSPLAN'
  | 'INVESTERINGSMÅDER'
  | 'KLIMA_PLUS_INVESTERINGSOVERSIGT'
  | 'PFA_BOLIGER'
  | 'LB_FORSIKRING'
  | 'MINE_FORSIKRINGER'
  | 'ONLINE_SUNDHEDSHJAELP'
  | 'ÆNDRE_FORSIKRINGER'
  | 'FIND_FORSKIRINGSBEHOV'
  | 'PRISER'
  | 'BEGUNSTIGELSE'
  | 'FAMILIE'
  | 'ORDNINGER'
  | 'ØKONOMI'
  | 'KONTAKTOPLYSNINGER'
  | 'SAMTYKKER'
  | 'BEKRÆFT_INDENTITET'
  | 'AKTIVER_DIN_ANBEFALING'
  | 'PASSWORD'
  | 'SIDSTE_LOGIN'
  | 'LOGOUT'
  | 'BESKEDER'
  | 'BESTILLINGER'
  | 'NOTIFIKATIONER'
  | 'DOKUMENTER'
  | 'FØLG_MIN_SAG'
  | 'PERSONAL_ADVISORS'
  | 'KLIMAAFTRYK'
  | 'UNDEFINED';

//These enums are case-sensitive as they are names that are sent directly to Adobe Tracking
export type AdobeTrackingMenuName =
  | 'Saving Menu'
  | 'Insurance Menu'
  | 'Advantage Menu'
  | 'Message Menu'
  | 'Profile Menu'
  | 'New Business Advisory Menu';

//These enums are-case sensitive as they are names that are sent directly to Adobe Tracking
export type AdobeTrackingSubMenuName =
  | 'Wealth overview'
  | 'Combine your savings'
  | 'Combine savings internal'
  | 'Savings overview'
  | 'Payments'
  | 'Return of investment'
  | 'Customer capital'
  | 'Expenses'
  | 'Single payments'
  | 'save more'
  | 'Pension plan'
  | 'Savings plan'
  | 'Payout plan'
  | 'Payouts'
  | 'Investment overview'
  | 'Investment Guide'
  | 'Investment concepts'
  | 'Adjust customer capital'
  | 'PFA Climate Plus'
  | 'Insurance overview'
  | 'Online health services'
  | 'Adjust insurance'
  | 'Insurance Guide'
  | 'Prices'
  | 'Inheritance and beneficiary'
  | 'PFA rental housing'
  | 'LB insurance'
  | 'Family'
  | 'Pension plans'
  | 'Financial situation'
  | 'Contact information'
  | 'Consent'
  | 'Proof of identity'
  | 'Pin code'
  | 'onboarding guide'
  | 'Latest login'
  | 'Logout'
  | 'Notifications'
  | 'Orders'
  | 'Service messages'
  | 'Documents'
  | 'Keep track of your case'
  | 'New Business Advisory latest login'
  | 'New Business Advisory logout'
  | 'Personal advisors'
  | '360 Indicator'
  | 'Climate footprint';

export class MenuItem {
  private readonly _name: MenuItemType;
  private _contentId: string;
  private readonly _advisorOnly: boolean;
  private readonly _categories: CategoryMenuItem[];
  private readonly _mobileContentId: string;
  private readonly _adobeTrackingMenuName: AdobeTrackingMenuName;
  private readonly _dataTestId: string;

  constructor(
    name: MenuItemType,
    contentId: string,
    mobileContentId: string,
    advisorOnly: boolean,
    categories: CategoryMenuItem[],
    adobeTrackingMenuName: AdobeTrackingMenuName,
    dataTestId: string
  ) {
    this._name = name;
    this._contentId = contentId;
    this._mobileContentId = mobileContentId;
    this._advisorOnly = advisorOnly;
    this._categories = categories;
    this._adobeTrackingMenuName = adobeTrackingMenuName;
    this._dataTestId = dataTestId;
  }

  get name(): MenuItemType {
    return this._name;
  }

  get contentId(): string {
    return this._contentId;
  }

  set contentId(value: string) {
    this._contentId = value;
  }

  get mobileContentId(): string {
    return this._mobileContentId;
  }

  get advisorOnly(): boolean {
    return this._advisorOnly;
  }

  get categories(): CategoryMenuItem[] {
    return this._categories;
  }

  get adobeTrackingMenuName(): AdobeTrackingMenuName {
    return this._adobeTrackingMenuName;
  }

  get dataTestId(): string {
    return this._dataTestId;
  }
}

export class CategoryMenuItem {
  private readonly _name: CategoryMenuItemName;
  private readonly _contentId: string;
  private readonly _items: SubMenuItem[];
  private _dontShow: boolean;

  constructor(
    name: CategoryMenuItemName,
    contentId: string,
    items: SubMenuItem[],
    dontShow: boolean
  ) {
    this._name = name;
    this._contentId = contentId;
    this._items = items;
    this._dontShow = dontShow;
  }

  get name(): CategoryMenuItemName {
    return this._name;
  }

  get contentId(): string {
    return this._contentId;
  }

  get items(): SubMenuItem[] {
    return this._items;
  }

  get dontShow(): boolean {
    return this._dontShow;
  }

  set dontShow(value: boolean) {
    this._dontShow = value;
  }
}

export class SubMenuItem {
  private readonly _name: SubMenuItemName;
  private _contentId: string;
  private _url: string;
  private readonly _alias: string[] | undefined;
  private _selectedMenuItem: string | undefined;
  private _dontShow: boolean;
  private _notification: number;
  private readonly _adobeTrackingSubMenuName: AdobeTrackingSubMenuName;
  private readonly _dataTestId: string;

  constructor(
    name: SubMenuItemName,
    contentId: string,
    url: string,
    dontShow: boolean,
    adobeTrackingSubMenuName: AdobeTrackingSubMenuName,
    dataTestId: string,
    alias?: string[]
  ) {
    this._name = name;
    this._contentId = contentId;
    this._url = url;
    this._dontShow = dontShow;
    this._adobeTrackingSubMenuName = adobeTrackingSubMenuName;
    this._dataTestId = dataTestId;
    this._alias = alias;
  }

  get name(): SubMenuItemName {
    return this._name;
  }

  get contentId(): string {
    return this._contentId;
  }

  set contentId(value: string) {
    this._contentId = value;
  }

  get url(): string {
    return this._url;
  }

  set url(value: string) {
    this._url = value;
  }

  get alias(): string[] | undefined {
    return this._alias;
  }

  get selectedMenuItem(): string | undefined {
    return this._selectedMenuItem;
  }

  set selectedMenuItem(value: string | undefined) {
    this._selectedMenuItem = value;
  }

  get dontShow(): boolean {
    return this._dontShow;
  }

  set dontShow(value: boolean) {
    this._dontShow = value;
  }

  get notification(): number {
    return this._notification;
  }

  set notification(value: number) {
    this._notification = value;
  }

  get adobeTrackingSubMenuName(): AdobeTrackingSubMenuName {
    return this._adobeTrackingSubMenuName;
  }

  get dataTestId(): string {
    return this._dataTestId;
  }
}

export class Menu {
  private _selectedMenuItem: MenuItemType | undefined;
  private _selectedSubMenuItem: SubMenuItem | undefined;
  private _menuItemOpen = 'UNDEFINED' as MenuItemType;
  private _nothingFound: boolean;
  private _allMenuItems = [] as MenuItem[];
  private savingsMenuItem = this.setupSavings();
  private advantagesMenuItem = this.setupAdvantages();
  private insuranceMenuItem = this.setupInsurances();
  private profileMenuItem = this.setupProfile();
  private messageMenuItem = this.setupMessages();
  private newBusinessAdvisoryMenuItem = this.setupNewBusinessAdvisory();
  private mobileMenuItem = this.setupMobileMenu();

  constructor() {
    this._allMenuItems.push(this.getSavings());
    this._allMenuItems.push(this.getAdvantages());
    this._allMenuItems.push(this.getInsurance());
    this._allMenuItems.push(this.getProfile());
    this._allMenuItems.push(this.getMessages());
    this._allMenuItems.push(this.getNewBusinessAdvisory());
  }

  get allMenuItems(): MenuItem[] {
    return this._allMenuItems;
  }

  get selectedMenuItem(): MenuItemType | undefined {
    return this._selectedMenuItem;
  }

  set selectedMenuItem(value: MenuItemType | undefined) {
    this._selectedMenuItem = value;
  }

  get selectedSubMenuItem(): SubMenuItem | undefined {
    return this._selectedSubMenuItem;
  }

  set selectedSubMenuItem(value: SubMenuItem | undefined) {
    this._selectedSubMenuItem = value;
  }

  get menuItemOpen(): MenuItemType {
    return this._menuItemOpen;
  }

  set menuItemOpen(value: MenuItemType) {
    this._menuItemOpen = value;
  }

  setMenuItemOpen(name: MenuItemType) {
    this._menuItemOpen = name;
  }

  getMenuItemOpen(): MenuItemType {
    return this._menuItemOpen;
  }

  closeMenuItems() {
    this._menuItemOpen = 'UNDEFINED';
  }

  public openCloseMenu(type: MenuItemType) {
    if (this.getMenuItemOpen() === type) {
      this.setMenuItemOpen('UNDEFINED');
    } else {
      this.setMenuItemOpen(type);
    }
  }

  public getSavings(): MenuItem {
    return this.savingsMenuItem;
  }

  public getAdvantages(): MenuItem {
    return this.advantagesMenuItem;
  }

  public getInsurance(): MenuItem {
    return this.insuranceMenuItem;
  }

  public getProfile(): MenuItem {
    return this.profileMenuItem;
  }

  public getMessages(): MenuItem {
    return this.messageMenuItem;
  }

  public getNewBusinessAdvisory(): MenuItem {
    return this.newBusinessAdvisoryMenuItem;
  }

  public getMobileMenu(): MenuItem[] {
    return this.mobileMenuItem;
  }

  private setupSavings(): MenuItem {
    const categoryMenuItems = [] as CategoryMenuItem[];
    const menuItem = new MenuItem(
      'SAVINGS',
      'DL.MN01.C20',
      'DL.MN01.C20',
      false,
      categoryMenuItems,
      'Saving Menu',
      'savingsMenu'
    );

    const overviewSubMenuItems = [] as SubMenuItem[];
    overviewSubMenuItems.push(
      new SubMenuItem(
        'OVERBLIK',
        'DL.MN01.C22',
        '/pensionsplan/formueoverblik',
        false,
        'Wealth overview',
        'overblikMenu'
      )
    );
    overviewSubMenuItems.push(
      new SubMenuItem(
        'SAMLE_ANDRE_OPSPARINGER',
        'DL.MN01.C23',
        '/overfoersel',
        false,
        'Combine your savings',
        'MN01-combine-savings'
      ),
      new SubMenuItem(
        'KONVERTER_TIL_PFA_PLUS',
        'DL.MN01.C87',
        '/konvertertilpfaplus',
        true,
        'Combine savings internal',
        'MN01-combine-savings-internal'
      )
    );

    const overviewMenuItem = this.addCategoryMenuItem(
      'TOTALE_FORMUE',
      'DL.MN01.C21',
      overviewSubMenuItems,
      false
    );
    menuItem.categories.push(overviewMenuItem);

    const savingsSubMenuItems = [] as SubMenuItem[];
    savingsSubMenuItems.push(
      new SubMenuItem(
        'REGNSKAB',
        'DL.MN01.C26',
        '/minopsparing',
        false,
        'Savings overview',
        'regnskabMenu'
      )
    );
    savingsSubMenuItems.push(
      new SubMenuItem(
        'INDBETALINGER',
        'DL.MN01.C27',
        '/senesteindbetaling',
        false,
        'Payments',
        'indbetalingerMenu'
      )
    );
    savingsSubMenuItems.push(
      new SubMenuItem(
        'AFKAST',
        'DL.MN01.C28',
        '/mitafkast',
        false,
        'Return of investment',
        'afkastMenu'
      )
    );
    savingsSubMenuItems.push(
      new SubMenuItem(
        'KUNDEKAPITAL',
        'DL.MN01.C29',
        '/kundekapital',
        false,
        'Customer capital',
        'kundekapitalMenu'
      )
    );
    savingsSubMenuItems.push(
      new SubMenuItem(
        'OMKOSTNINGER',
        'DL.MN01.C30',
        '/prisliste',
        false,
        'Expenses',
        'priceListMenu'
      )
    );
    savingsSubMenuItems.push(
      new SubMenuItem(
        'EKSTRA_INDSKUD',
        'DL.MN01.C33',
        '/privatindskud',
        false,
        'Single payments',
        'singlePaymentMenu'
      )
    );
    savingsSubMenuItems.push(
      new SubMenuItem(
        'SPAR_MERE_OP',
        'DL.MN01.C90',
        '/sparmereop',
        false,
        'save more',
        'saveMoreMenu'
      )
    );
    const savingsMenuItem = this.addCategoryMenuItem(
      'OPSPARINGSPOSTER',
      'DL.MN01.C25',
      savingsSubMenuItems,
      false
    );
    menuItem.categories.push(savingsMenuItem);

    const planSubMenuItems = [] as SubMenuItem[];
    planSubMenuItems.push(
      new SubMenuItem(
        'OPSPARINGSPLAN',
        'DL.MN01.C32',
        '/pensionstal',
        true,
        'Savings plan',
        'pensionEstimate'
      )
    );
    planSubMenuItems.push(
      new SubMenuItem(
        'UDBETALINGSPLAN',
        'DL.MN01.C34',
        '/pensionsplan/minudbetalingsplan',
        false,
        'Payout plan',
        'payoutPlan'
      )
    );
    planSubMenuItems.push(
      new SubMenuItem(
        'UDBETALINGER',
        'DL.MN01.C89',
        '/pensionsplan/udbetalinger',
        true,
        'Payouts',
        'payouts'
      )
    );
    const planMenuItem = this.addCategoryMenuItem(
      'PLANLÆG_PENSION',
      'DL.MN01.C31',
      planSubMenuItems,
      false
    );
    menuItem.categories.push(planMenuItem);

    const investmentSubMenuItems = [] as SubMenuItem[];
    investmentSubMenuItems.push(
      new SubMenuItem(
        'ÆNDRE_INVESTERING',
        'DL.MN01.C36',
        '/mineinvesteringer',
        false,
        'Investment overview',
        'mineInvesteringer'
      )
    );
    investmentSubMenuItems.push(
      new SubMenuItem(
        'FIND_INVESTERINGSPLAN',
        'DL.MN01.C37',
        '/mineinvesteringer/guide',
        false,
        'Investment Guide',
        'investmentGuide'
      )
    );
    investmentSubMenuItems.push(
      new SubMenuItem(
        'INVESTERINGSMÅDER',
        'DL.MN01.C38',
        '/duinvesterer/intro',
        false,
        'Investment concepts',
        'investmentConcepts'
      )
    );
    investmentSubMenuItems.push(
      new SubMenuItem(
        'KLIMAAFTRYK',
        'DL.MN01.C88',
        '/klimaaftryk',
        false,
        'Climate footprint',
        'climateFootprint'
      )
    );
    const investmentMenuItem = this.addCategoryMenuItem(
      'INVESTERING',
      'DL.MN01.C35',
      investmentSubMenuItems,
      false
    );
    menuItem.categories.push(investmentMenuItem);
    return menuItem;
  }

  private setupAdvantages(): MenuItem {
    const categoryMenuItems = [] as CategoryMenuItem[];
    const menuItem = new MenuItem(
      'ADVANTAGE',
      'DL.MN01.C46',
      'DL.MN01.C46',
      false,
      categoryMenuItems,
      'Advantage Menu',
      'advantageMenu'
    );
    const advantagesSubMenuItems = [] as SubMenuItem[];

    advantagesSubMenuItems.push(
      new SubMenuItem(
        'PFA_BOLIGER',
        'DL.MN01.C48',
        '/pfaboliger',
        false,
        'PFA rental housing',
        'pfaBoligerMenu'
      )
    );

    advantagesSubMenuItems.push(
      new SubMenuItem(
        'PERSONAL_ADVISORS',
        'DL.MF.C27',
        '/personligeradgivere',
        true,
        'Personal advisors',
        'personalAdvisorsMenu'
      )
    );

    const advantagesMenuItem = this.addCategoryMenuItem(
      'FORDELE_I_PFA',
      'DL.MN01.C81',
      advantagesSubMenuItems,
      false
    );
    menuItem.categories.push(advantagesMenuItem);

    const lbInsuranceSubMenuItems = [] as SubMenuItem[];
    lbInsuranceSubMenuItems.push(
      new SubMenuItem(
        'LB_FORSIKRING',
        'DL.MN01.C74',
        '/forsikringspartner',
        true,
        'LB insurance',
        'LBInsurance'
      )
    );

    const lbInsuranceMenuItem = this.addCategoryMenuItem(
      'PARTNERSKAB',
      'DL.MN01.C82',
      lbInsuranceSubMenuItems,
      true
    );
    menuItem.categories.push(lbInsuranceMenuItem);

    return menuItem;
  }

  private setupInsurances(): MenuItem {
    const categoryMenuItems = [] as CategoryMenuItem[];
    const menuItem = new MenuItem(
      'INSURANCE',
      'DL.MN01.C40',
      'DL.MN01.C40',
      false,
      categoryMenuItems,
      'Insurance Menu',
      'insuranceMenu'
    );
    const myInsuranceSubMenuItems = [] as SubMenuItem[];
    myInsuranceSubMenuItems.push(
      new SubMenuItem(
        'MINE_FORSIKRINGER',
        'DL.MN01.C41',
        '/mineforsikringer',
        false,
        'Insurance overview',
        'mineForsikringerMenu'
      )
    );
    myInsuranceSubMenuItems.push(
      new SubMenuItem(
        'ONLINE_SUNDHEDSHJAELP',
        'DL.FG02.C375',
        '/mineforsikringer/onlinesundhed',
        true,
        'Online health services',
        'onlinesundhedMenu'
      )
    );
    myInsuranceSubMenuItems.push(
      new SubMenuItem(
        'FIND_FORSKIRINGSBEHOV',
        'DL.MN01.C43',
        '/mineforsikringer/forsikringsguide',
        false,
        'Insurance Guide',
        'insuranceGuide'
      )
    );
    myInsuranceSubMenuItems.push(
      new SubMenuItem(
        'ÆNDRE_FORSIKRINGER',
        'DL.MN01.C44',
        '/mineforsikringer/tilpas',
        false,
        'Adjust insurance',
        'aendreForsikringMenu'
      )
    );
    myInsuranceSubMenuItems.push(
      new SubMenuItem(
        'PRISER',
        'DL.MN01.C42',
        '/prisliste',
        false,
        'Prices',
        'insurancePricelist'
      )
    );

    const myInsuranceMenuItem = this.addCategoryMenuItem(
      'FORSIKRING',
      'DL.MN01.C84',
      myInsuranceSubMenuItems,
      false
    );
    menuItem.categories.push(myInsuranceMenuItem);

    const beneficiarySubMenuItems = [] as SubMenuItem[];

    beneficiarySubMenuItems.push(
      new SubMenuItem(
        'BEGUNSTIGELSE',
        'DL.HE01.C139',
        '/mineforsikringer/arvogbegunstigelse',
        false,
        'Inheritance and beneficiary',
        'inheritanceAndBeneficiary',
        [
          '/mineforsikringer/begunstigelse',
          '/mineforsikringer/begunstigelse/letpension'
        ]
      )
    );

    const beneficiaryMenuItem = this.addCategoryMenuItem(
      'VÆRKTØJ',
      'DL.MN01.C83',
      beneficiarySubMenuItems,
      false
    );

    beneficiarySubMenuItems.push(
      new SubMenuItem(
        'FØLG_MIN_SAG',
        'DL.MN01.C18',
        '/mineforsikringer/sagsoversigt',
        false,
        'Keep track of your case',
        'keepTrackOfYourCase'
      )
    );
    menuItem.categories.push(beneficiaryMenuItem);

    return menuItem;
  }

  private setupProfile(): MenuItem {
    const categoryMenuItems = [] as CategoryMenuItem[];
    const menuItem = new MenuItem(
      'PROFILE',
      '',
      'DL.MD01.C20',
      false,
      categoryMenuItems,
      'Profile Menu',
      'profileMenu'
    );
    const myDataSubMenuItems = [] as SubMenuItem[];
    myDataSubMenuItems.push(
      new SubMenuItem(
        'FAMILIE',
        'DL.MN01.C03',
        '/mine-oplysninger/familie',
        false,
        'Family',
        'familyMenuItem'
      )
    );
    myDataSubMenuItems.push(
      new SubMenuItem(
        'ORDNINGER',
        'DL.MN01.C05',
        '/mine-oplysninger/pensionsinfo',
        false,
        'Pension plans',
        'pensionInfoMenuItem'
      )
    );
    myDataSubMenuItems.push(
      new SubMenuItem(
        'ØKONOMI',
        'DL.MN01.C04',
        '/mine-oplysninger/oekonomi',
        false,
        'Financial situation',
        'economyMenuItem'
      )
    );
    myDataSubMenuItems.push(
      new SubMenuItem(
        'KONTAKTOPLYSNINGER',
        'DL.MN01.C07',
        '/mine-oplysninger',
        false,
        'Contact information',
        'contactMenuItem'
      )
    );

    const myDataMenuItem = this.addCategoryMenuItem(
      'MINE_DATA',
      'DL.MN01.C02',
      myDataSubMenuItems,
      false
    );
    menuItem.categories.push(myDataMenuItem);

    const consentSubMenuItems = [] as SubMenuItem[];
    consentSubMenuItems.push(
      new SubMenuItem(
        'SAMTYKKER',
        'DL.MN01.C08',
        '/mine-oplysninger/samtykker',
        false,
        'Consent',
        'consentMenuItem'
      )
    );

    consentSubMenuItems.push(
      new SubMenuItem(
        'BEKRÆFT_INDENTITET',
        'DL.MN01.C010',
        '/mine-oplysninger/legitimering',
        false,
        'Proof of identity',
        'legitimationMenuItem'
      )
    );

    consentSubMenuItems.push(
      new SubMenuItem(
        'AKTIVER_DIN_ANBEFALING',
        'DL.MN01.C86',
        '/velkomst',
        true,
        'onboarding guide',
        'activateRecommendationMenuItem'
      )
    );

    const consentMenuItem = this.addCategoryMenuItem(
      'INSTILLINGER',
      'DL.MN01.C80',
      consentSubMenuItems,
      false
    );

    menuItem.categories.push(consentMenuItem);

    const logoutSubMenuItems = [] as SubMenuItem[];
    logoutSubMenuItems.push(
      new SubMenuItem(
        'PASSWORD',
        'DL.MN01.C75',
        '/mine-oplysninger/pinkode',
        false,
        'Pin code',
        'pinCodeMenuItem'
      )
    );

    logoutSubMenuItems.push(
      new SubMenuItem(
        'LOGOUT',
        'DL.MN01.C78',
        'logout',
        false,
        'Logout',
        'logoutMenu'
      )
    );

    logoutSubMenuItems.push(
      new SubMenuItem(
        'SIDSTE_LOGIN',
        'DL.MN01.C19',
        '',
        false,
        'Latest login',
        ''
      )
    );

    const logoutMenuItems = this.addCategoryMenuItem(
      'ADGANGE',
      'DL.MN01.C77',
      logoutSubMenuItems,
      false
    );
    menuItem.categories.push(logoutMenuItems);

    return menuItem;
  }

  private setupMessages(): MenuItem {
    const categoryMenuItems = [] as CategoryMenuItem[];
    const menuItem = new MenuItem(
      'MESSAGES',
      '',
      'DL.MN01.C79',
      false,
      categoryMenuItems,
      'Message Menu',
      'messageMenu'
    );
    const messageSubMenuItems = [] as SubMenuItem[];
    messageSubMenuItems.push(
      new SubMenuItem(
        'BESKEDER',
        'DL.MN01.C12',
        '/beskeder',
        false,
        'Notifications',
        'MN01-messages'
      )
    );

    const messageMenuItem = this.addCategoryMenuItem(
      'MESSAGES',
      'DL.MN01.C11',
      messageSubMenuItems,
      false
    );
    menuItem.categories.push(messageMenuItem);

    const documentsSubMenuItems = [] as SubMenuItem[];

    documentsSubMenuItems.push(
      new SubMenuItem(
        'BESTILLINGER',
        'DL.MN01.C16',
        '/mineaendringer',
        false,
        'Orders',
        ''
      )
    );

    documentsSubMenuItems.push(
      new SubMenuItem(
        'DOKUMENTER',
        'DL.MN01.C17',
        '/minedokumenter',
        false,
        'Documents',
        'dokumenterMenu'
      )
    );

    const documentsMenuItems = this.addCategoryMenuItem(
      'SERVICE_OG_VILKÅR',
      'DL.MN01.C85',
      documentsSubMenuItems,
      false
    );
    menuItem.categories.push(documentsMenuItems);

    return menuItem;
  }

  private setupNewBusinessAdvisory(): MenuItem {
    const categoryMenuItems = [] as CategoryMenuItem[];
    const menuItem = new MenuItem(
      'NEW_BUSINESS_ADVISORY',
      '',
      '',
      false,
      categoryMenuItems,
      'New Business Advisory Menu',
      ''
    );
    const logoutSubMenuItems = [] as SubMenuItem[];

    logoutSubMenuItems.push(
      new SubMenuItem(
        'LOGOUT',
        'DL.MN01.C78',
        'logout',
        false,
        'New Business Advisory logout',
        ''
      )
    );

    logoutSubMenuItems.push(
      new SubMenuItem(
        'SIDSTE_LOGIN',
        'DL.MN01.C19',
        '',
        false,
        'New Business Advisory latest login',
        ''
      )
    );

    const logoutMenuItems = this.addCategoryMenuItem(
      'ADGANGE',
      'DL.MN01.C77',
      logoutSubMenuItems,
      false
    );
    menuItem.categories.push(logoutMenuItems);
    return menuItem;
  }

  private setupMobileMenu(): MenuItem[] {
    const mobileMenuItems = [] as MenuItem[];
    mobileMenuItems.push(this.getSavings());
    mobileMenuItems.push(this.getInsurance());
    mobileMenuItems.push(this.getAdvantages());
    mobileMenuItems.push(this.getMessages());
    mobileMenuItems.push(this.getProfile());
    return mobileMenuItems;
  }

  public setSelectedMenuAndSubMenuByUrl(url: string) {
    this._nothingFound = true;
    this.setSelectedMenuAndSubmenu(url);
    if (this._nothingFound) {
      this._selectedSubMenuItem = undefined;
      this._selectedMenuItem = undefined;
    }
  }

  public setQuantityOnName(
    messagesMenuItem: MenuItem,
    name: SubMenuItemName,
    quantity: number
  ) {
    const submenu = this.findSubMenuItem(messagesMenuItem, name);
    if (submenu) {
      submenu.notification = quantity;
    }
  }

  public showMenuItem(menuItem: MenuItem, name: CategoryMenuItemName) {
    const categoryMenuItem = this.findCategoryMenuItem(menuItem, name);
    if (categoryMenuItem) {
      categoryMenuItem.dontShow = false;
    }
  }

  public showSubMenuItem(menuItem: MenuItem, name: SubMenuItemName) {
    const categorySubMenuItem = this.findSubMenuItem(menuItem, name);
    if (categorySubMenuItem) {
      categorySubMenuItem.dontShow = false;
    }
  }

  public hideSubMenuItem(menuItemType: MenuItemType, name: SubMenuItemName) {
    this.allMenuItems.forEach(menuItem => {
      if (menuItem.name === menuItemType) {
        const categorySubMenuItem = this.findSubMenuItem(menuItem, name);
        if (categorySubMenuItem) {
          categorySubMenuItem.dontShow = true;
        }
      }
    });
  }

  private findCategoryMenuItem(
    menuItem: MenuItem,
    name: CategoryMenuItemName
  ): CategoryMenuItem | undefined {
    let foundCategoryMenuItem: CategoryMenuItem | undefined;
    menuItem.categories.forEach(category => {
      if (category.name === name) {
        foundCategoryMenuItem = category;
      }
    });
    return foundCategoryMenuItem;
  }

  private findSubMenuItem(
    menuItem: MenuItem,
    name: SubMenuItemName
  ): SubMenuItem | undefined {
    let foundSubMenuItem: SubMenuItem | undefined;
    menuItem.categories.forEach(category => {
      category.items.forEach(subMenuItem => {
        if (subMenuItem.name === name) {
          foundSubMenuItem = subMenuItem;
        }
      });
    });
    return foundSubMenuItem;
  }

  private setSelectedMenuAndSubmenu(url: string) {
    this._allMenuItems.forEach((menuItem: MenuItem) => {
      menuItem.categories.forEach((category: CategoryMenuItem) => {
        category.items.forEach((subMenuItem: SubMenuItem) => {
          if (
            (subMenuItem.url === url ||
              subMenuItem.alias?.some(alias => alias === url)) &&
            subMenuItem.name !== 'KLIMA_PLUS_INVESTERINGSOVERSIGT'
          ) {
            this._nothingFound = false;
            this._selectedSubMenuItem = subMenuItem;
            this._selectedMenuItem = menuItem.name;
          }
        });
      });
      if (this._nothingFound) {
        if (url.split('/').length > 2) {
          const chopUrl = url.slice(0, url.lastIndexOf('/'));
          this.setSelectedMenuAndSubmenu(chopUrl);
        }
      }
    });
  }

  private addCategoryMenuItem(
    name: CategoryMenuItemName,
    contentId: string,
    items: SubMenuItem[],
    dontShow: boolean
  ): CategoryMenuItem {
    return new CategoryMenuItem(name, contentId, items, dontShow);
  }
}

export interface Notifications {
  antalAendringer: number;
  unreadNotifications: number;
  unreadMessages: number;
}

export interface SelectedMenuArg {
  menu: SubMenuItem | string;
  topMenuName: string;
}
