import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SaveDialogService {
  get saveDialogPending$(): Observable<boolean> {
    return this._saveDialogPending$.asObservable();
  }

  get saveDialogPending(): boolean {
    return this._saveDialogPending$.getValue();
  }

  private readonly _saveDialogPending$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  markSaveDialogPending() {
    this._saveDialogPending$.next(true);
  }

  unmarkSaveDialogPending() {
    this._saveDialogPending$.next(false);
  }
}
