import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OekonomiDetaljer } from '@pfa/gen';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class EconomyApiService {
  constructor(private http: HttpClient) {}

  getEconomyDetails(): Observable<OekonomiDetaljer> {
    return this.http.get<OekonomiDetaljer>('/ds/api/oekonomi');
  }
}
