/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const AttachedFileElementSchema = JoiObj.object({
        attachmentGuid:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        attachmentMetadata:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        documentClassificationCategory:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        documentClassificationType:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        fileName:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        hash:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        
    })
.id('AttachedFileElementSchema')

export interface AttachedFileElement { 
    attachmentGuid: string;
    attachmentMetadata: string;
    documentClassificationCategory: string;
    documentClassificationType: string;
    fileName: string;
    hash: string;
}

