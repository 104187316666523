/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ServiceInfo } from './serviceInfo';
import { ServiceInfoSchema } from './serviceInfo';
import { Type } from './type';
import { TypeSchema } from './type';
import { Tilstand } from './tilstand';
import { TilstandSchema } from './tilstand';
import { Police } from './police';
import { PoliceSchema } from './police';
import * as JoiObj from "joi";


    export const OpsparingPoliceSchema = JoiObj.object({
        serviceInfo:
            
        JoiObj.link('#ServiceInfoSchema')

.allow(null)



        ,
        detaljeMulig:
            
                    JoiObj.boolean()
                


.required()


        ,
        harProfilG:
            
                    JoiObj.boolean()
                


.required()


        ,
        id:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        livnetAftale:
            
                    JoiObj.boolean()
                


.required()


        ,
        opsparing:
            
                    JoiObj.number()
                

.allow(null)



        ,
        orlovSlutDato:
            
            JoiObj.date()

.allow(null)



        ,
        tilstand:
            
        JoiObj.link('#TilstandSchema')


.required()


        ,
        tooltipContentId:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        type:
            
        JoiObj.link('#TypeSchema')


.required()


        ,
        typeTekstId:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        aarSpaend:
                JoiObj.array().items(
        
                    JoiObj.number()
                .integer()

        )
        

.required()


        ,
        afkast:
            
                    JoiObj.number()
                


.required()


        ,
        liveLinkDocId:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        liveLinkDocTokenId:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        omkostninger:
            
                    JoiObj.number()
                


.required()


        ,
        slutDato:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        startDato:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        
    })
                .shared(ServiceInfoSchema)
                .shared(TilstandSchema)
                .shared(TypeSchema)
.id('OpsparingPoliceSchema')

export interface OpsparingPolice extends Police { 
    aarSpaend: Array<number>;
    afkast: number;
    liveLinkDocId?: string;
    liveLinkDocTokenId?: string;
    omkostninger: number;
    slutDato?: string;
    startDato?: string;
}
export namespace OpsparingPolice {
}


