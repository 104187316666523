/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AnswerSummary } from './answerSummary';
import { AnswerSummarySchema } from './answerSummary';
import { AnswerType } from './answerType';
import { AnswerTypeSchema } from './answerType';
import * as JoiObj from "joi";


    export const QuestionSummarySchema = JoiObj.object({
        answerSummary:
                JoiObj.array().items(
        
        JoiObj.link('#AnswerSummarySchema')

        )
        

.required()


        ,
        answerType:
            
        JoiObj.link('#AnswerTypeSchema')


.required()


        ,
        headline:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        id:
            
                    JoiObj.number()
                


.required()


        ,
        text:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        
    })
                .shared(AnswerTypeSchema)
.id('QuestionSummarySchema')

export interface QuestionSummary { 
    answerSummary: Array<AnswerSummary>;
    answerType: AnswerType;
    headline: string;
    id: number;
    text: string;
}
export namespace QuestionSummary {
}


