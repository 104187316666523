<div id="adobe-analytics-id" class="app-wrapper">
  <co-global-working></co-global-working>
  <div *ngIf="(loggedIn$ | async) && !appInitialized" class="loading">
    <co-loading [show]="true"></co-loading>
  </div>
  <mitpfa-operation-status
    class="operationstatus--wrapper"
  ></mitpfa-operation-status>
  <mitpfa-header
    *ngIf="!hideHeader && appInitialized && userAllowedInMitPFA"
  ></mitpfa-header>
  <co-session-timeout-extender
    *ngIf="appInitialized"
  ></co-session-timeout-extender>
  <mitpfa-legitimation *ngIf="appInitialized"></mitpfa-legitimation>
  <mitpfa-advisor-warning
    *ngIf="appInitialized && userAllowedInMitPFA"
  ></mitpfa-advisor-warning>
  <mitpfa-advisormenu
    *ngIf="appInitialized && userAllowedInMitPFA"
    class="left-menu"
  ></mitpfa-advisormenu>
  <div class="body-content">
    <div class="content">
      <div *ngIf="isOldOsVersion" class="alert">
        <div class="logo-wrap">
          <co-icon-logo class="header--logo"></co-icon-logo>
        </div>
        <co-alert class="full-page alert-center">
          <p class="text--manchet" coContent="DL.LI01.C139"></p>
        </co-alert>
      </div>
      <router-outlet *ngIf="!isOldOsVersion" id="mitpfa"></router-outlet>
    </div>
    <co-footer
      (languageChange)="setLanguage($event)"
      *ngIf="!hideFooter && userAllowedInMitPFA"
    ></co-footer>
  </div>
  <mitpfa-chat *ngIf="!hideChat && userAllowedInMitPFA"></mitpfa-chat>
  <mitpfa-tracking></mitpfa-tracking>
  <mitpfa-logging></mitpfa-logging>
</div>
