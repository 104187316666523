<div mat-dialog-title><h3 [coContent]="title"></h3></div>
<mat-dialog-content class="conent">
  <p *ngFor="let content of contents" [coContent]="content"></p>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="modal-buttons">
    <button mat-raised-button (click)="handleClose(true)">
      {{ confirmButtonLabel | content }}
    </button>
    <button mat-raised-button (click)="handleClose(false)">
      {{ cancelButtonLabel | content }}
    </button>
  </div>
</mat-dialog-actions>
