<co-loading [show]="!initialized"></co-loading>
<ng-container *ngIf="initialized">
  <co-stepper
    *ngIf="!isOnboarding && displayStepper"
    [steps]="steps"
    [selectedStepIndex]="currentStepIndex"
    [isNavigationEnabled]="false"
    (stepSelected)="stepChanged($event)"
    class="stepper spacing-unit--under"
  ></co-stepper>

  <ng-container [ngSwitch]="currentStepIndex">
    <mitpfa-insurance-guide-intro
      *ngSwitchCase="-1"
      (startGuide)="onStartGuide($event)"
      [pensionCustomer]="pensionCustomer"
      [financialSituation]="financialSituation"
      [familyInformation]="familyInformation"
      [shortFlowRunning]="isCurrentFlowShort"
    ></mitpfa-insurance-guide-intro>

    <mitpfa-insurance-guide-insurance-cover-step
      *ngSwitchCase="0"
      [currentQuestion]="currentQuestion$"
      [pensionInfo]="pensionInfo"
      [insuranceDetails]="insuranceDetails"
    >
    </mitpfa-insurance-guide-insurance-cover-step>

    <mitpfa-insurance-guide-my-information-step
      *ngSwitchCase="1"
      [financialSituation]="financialSituation"
      [pensionInfo]="pensionInfo"
      [pensionCustomer]="pensionCustomer"
      [familyInformation]="familyInformation"
      [currentQuestion]="currentQuestion$"
      (emitUpdatePI)="getUpdatePI()"
    >
    </mitpfa-insurance-guide-my-information-step>

    <mitpfa-insurance-guide-risk-group-step
      *ngSwitchCase="2"
      [financialSituation]="financialSituation"
      [familyInformation]="familyInformation"
      [pensionCustomer]="pensionCustomer"
      [insuranceDetails]="insuranceDetails"
      [currentQuestion]="currentQuestion$"
    >
    </mitpfa-insurance-guide-risk-group-step>

    <div *ngSwitchCase="3" class="recommendation">
      <div class="recommendation-inner">
        <h4 coContent="DL.FG01.C1975"></h4>
        <div class="recommendation-text" coContent="DL.FG01.C1976"></div>
        <mitpfa-wait
          [runTime]="LOADING_ANIMATION_TIME"
          [runTimeLoop]="true"
        ></mitpfa-wait>
      </div>
    </div>
  </ng-container>
</ng-container>
