import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import {RestCacheEntry} from "./RestCacheEntry";
import {GenericStore} from "./GenericStore";
import * as JoiObj from "joi";

import {
        KundekapitalOversigt,
        KundekapitalOversigtSchema
} from '../model/models';

import { KundekapitalService } from './kundekapital.service';


@Injectable({
    providedIn: 'root'
})
export class KundekapitalStore extends GenericStore {

            private kundekapitalIndexCache: Map<string, RestCacheEntry<KundekapitalOversigt>> = new Map<string, RestCacheEntry<KundekapitalOversigt>>();

    constructor(private kundekapitalService: KundekapitalService) {
        super();
    }

    invalidateAll(): void {
                
                this.invalidatekundekapitalIndex();
    }


                public kundekapitalGet() : Observable<KundekapitalOversigt> {
                    let hash = "0";
                    if (this.kundekapitalIndexCache.has(hash)) {
                        return this.kundekapitalIndexCache.get(hash)!.observable;
                    } else {
                        let kundekapitalIndexSubject: BehaviorSubject<0> = new BehaviorSubject(0);
                        let kundekapitalIndex = this.initiateStream(kundekapitalIndexSubject, this.kundekapitalService.kundekapitalGet(), KundekapitalOversigtSchema);
                        this.kundekapitalIndexCache.set(hash, new RestCacheEntry<KundekapitalOversigt>(kundekapitalIndex, kundekapitalIndexSubject));
                        return kundekapitalIndex;
                    }
                }

                public invalidatekundekapitalIndex() {
                    this.kundekapitalIndexCache.forEach((cacheEntry) => {
                        cacheEntry.subject.next(0);
                    })
                }






}
