/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { LanguageCode } from './languageCode';
import { LanguageCodeSchema } from './languageCode';
import { Survey } from './survey';
import { SurveySchema } from './survey';
import * as JoiObj from "joi";


    export const SurveyWithInstanceIdSchema = JoiObj.object({
        instanceId:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        languageCode:
            
        JoiObj.link('#LanguageCodeSchema')


.required()


        ,
        survey:
            
        JoiObj.link('#SurveySchema')


.required()


        
    })
                .shared(LanguageCodeSchema)
                .shared(SurveySchema)
.id('SurveyWithInstanceIdSchema')

export interface SurveyWithInstanceId { 
    instanceId: string;
    languageCode: LanguageCode;
    survey: Survey;
}
export namespace SurveyWithInstanceId {
}


