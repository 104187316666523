import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'mitpfa-onboarding-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterComponent {
  @Input() showBack: boolean;
  @Input() showJumpover: boolean;
  @Input() note: string;
  @Input() form: UntypedFormGroup;
  @Output() back = new EventEmitter();
  @Output() jumpover = new EventEmitter();
}
