import { Injectable } from '@angular/core';
import {
  DynamicAdvisingCalculation,
  DynamicAdvisingOffer,
  DynamicAdvisingOfferGroup,
  DynamicAdvisingOfferGroupType
} from '@pfa/gen';

@Injectable({
  providedIn: 'root'
})
export class DynamicAdvisingFilterService {
  public filterAndSortOffers(
    offerIdsToFilterBy: string[],
    dynamicCalculation: DynamicAdvisingCalculation
  ): DynamicAdvisingCalculation {
    const copyDynamicCalculation = JSON.parse(
      JSON.stringify(dynamicCalculation)
    );
    copyDynamicCalculation.offerGroups.forEach(
      (offerGroup: DynamicAdvisingOfferGroup) => {
        if (offerGroup.offers) {
          offerGroup.offers = this.removeNotRelevantOffers(
            offerGroup.offers,
            offerIdsToFilterBy
          );
          this.sortSigmaOffers(offerGroup.offers);
        }
      }
    );
    return copyDynamicCalculation;
  }

  private removeNotRelevantOffers(
    offers: DynamicAdvisingOffer[],
    relevantOffers?: string[]
  ): DynamicAdvisingOffer[] {
    return relevantOffers
      ? this.filterByRelevantOfferIdsCards(offers, relevantOffers)
      : offers;
  }

  private filterByRelevantOfferIdsCards(
    offers: DynamicAdvisingOffer[],
    relevantOffers: string[]
  ): DynamicAdvisingOffer[] {
    const offersMap = new Map();
    offers.forEach((offer: DynamicAdvisingOffer) => {
      if (relevantOffers.includes(offer.offerId)) {
        offersMap.set(offer.offerId, offer);
      }
    });
    return Array.from(offersMap.values());
  }

  // The first in the order is the nbaValue longest from 0
  private sortSigmaOffers(
    sigmaOffers: DynamicAdvisingOffer[]
  ): DynamicAdvisingOffer[] {
    sigmaOffers.sort((a, b) => {
      let sigmaValue = a.offerNbaValue;
      if (sigmaValue < 0) {
        sigmaValue = 0 - a.offerNbaValue;
      }
      let foundTopicValue = b.offerNbaValue;
      if (foundTopicValue < 0) {
        foundTopicValue = 0 - b.offerNbaValue;
      }
      if (sigmaValue > foundTopicValue) {
        return -1;
      } else {
        return 1;
      }
    });
    return sigmaOffers;
  }

  public filterOfferGroupsByType(
    offerGroups: Array<DynamicAdvisingOfferGroup>,
    groupType: DynamicAdvisingOfferGroupType
  ): DynamicAdvisingOfferGroup {
    return offerGroups.filter(
      (offerGroup: DynamicAdvisingOfferGroup) =>
        groupType === offerGroup.groupType
    )[0];
  }

  public getAllOffers(
    offerGroups: Array<DynamicAdvisingOfferGroup>
  ): DynamicAdvisingOffer[] {
    let offers: DynamicAdvisingOffer[] = new Array<DynamicAdvisingOffer>();
    offerGroups.forEach((offerGroup: DynamicAdvisingOfferGroup) => {
      if (this.showOfferGroup(offerGroup)) {
        offers = offers.concat(offerGroup.offers);
      }
    });
    return offers;
  }

  public showOfferGroup(offerGroup: DynamicAdvisingOfferGroup): boolean {
    const offerGroupTypes: DynamicAdvisingOfferGroupType[] = [
      'INSURANCE',
      'INVESTMENT',
      'MISSING_DATA'
    ];
    return offerGroupTypes.includes(offerGroup.groupType);
  }
}
