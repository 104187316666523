import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject } from '@angular/core';
import { InsuranceAdjustmentSuperRiskResponse } from './insurance-adjustment-super-risk-popup.model';

@Component({
  selector: 'mitpfa-insurance-adjustment-super-risk-popup',
  templateUrl: './insurance-adjustment-super-risk-popup.component.html',
  styleUrls: ['./insurance-adjustment-super-risk-popup.component.scss']
})
export class InsuranceAdjustmentSuperRiskPopupComponent {
  header: string;
  body1: string;
  body2: string;
  body3: string;
  finishButton: boolean | undefined;

  constructor(
    private readonly dialogRef: MatDialogRef<InsuranceAdjustmentSuperRiskPopupComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.header = data.header;
    this.body1 = data.body1;
    this.body2 = data.body2;
    this.body3 = data.body3;
    this.finishButton = data.finishButton;
  }

  accept(): void {
    const response: InsuranceAdjustmentSuperRiskResponse = { result: 'ACCEPT' };
    this.dialogRef.close(response);
  }

  cancel(): void {
    const response: InsuranceAdjustmentSuperRiskResponse = { result: 'CANCEL' };
    this.dialogRef.close(response);
  }

  finish(): void {
    this.dialogRef.close();
  }
}
