<div class="guide-real-estate-container">
  <co-card-animated-stack [activeIndex]="activeIndex">
    <co-card-animated-stack-item [scrollToTopOnActivate]="true">
      <co-card-question
        (agreeClick)="displayDetails()"
        (declineClick)="declined()"
      >
        <div class="align--right guide-help-container--question hide-on-mobile">
          <mitpfa-investment-guide-help [broker]="broker">
          </mitpfa-investment-guide-help>
        </div>
        <div
          class="align--center text--manchet title--question"
          coContent="DL.INVGU01.C651"
        ></div>
        <p
          class="text--info align--center text--question"
          coContent="DL.INVGU01.C403"
        ></p>
        <div class="show-on-mobile">
          <mitpfa-investment-guide-help [broker]="broker">
          </mitpfa-investment-guide-help>
        </div>
        <div
          under-button-mobile
          class="question-previously-selected align--center text--note"
          *ngIf="
            investmentGuide.recommendationBasis?.primaryResidences
              ?.realEstateAnswer !== null
          "
          coContent="DL.INVGU01.C434"
          [coContentSubst]="
            '<strong>' +
            ((investmentGuide.recommendationBasis?.primaryResidences
              ?.realEstateAnswer
              ? 'DL.MD01.C29'
              : 'DL.MD01.C30'
            ) | content) +
            '</strong>'
          "
        ></div>
      </co-card-question>
    </co-card-animated-stack-item>

    <co-card-animated-stack-item [scrollToTopOnActivate]="true" maxWidth="100%">
      <co-card>
        <co-icon-times
          class="close-icon"
          (click)="closeDetails()"
        ></co-icon-times>

        <form [formGroup]="form" class="form--details">
          <ng-container
            *ngIf="
              primaryResidences.realEstates?.length === 1;
              else readonlyView
            "
          >
            <div class="spacing-unit--under">
              <h3 class="align--center" coContent="DL.INVGU01.C372"></h3>
              <p
                class="text--info align--center"
                coContent="DL.INVGU01.C460"
              ></p>
            </div>

            <div class="form-row">
              <label
                for="realEstateName"
                [coContent]="'DL.MD01.C289'"
                class="text--bold"
              ></label>
              <div class="form-row_field">
                <mat-form-field color="accent">
                  <input
                    matInput
                    [required]="true"
                    type="text"
                    [formControlName]="'realEstateName'"
                    [placeholder]="'DL.MD01.C337' | content"
                    name="realEstateName"
                    id="realEstateName"
                  />
                  <mat-error
                    *ngIf="form.get('realEstateName').errors?.required"
                    [coContent]="'DL.MD01.C121'"
                  ></mat-error>
                </mat-form-field>
              </div>
            </div>

            <div>
              <co-slider-form
                [label]="'DL.INVGU01.C195'"
                [sliderOptions]="sliderOptions"
                ngDefaultControl
                formControlName="currentMarketValue"
                type="currency"
              ></co-slider-form>
            </div>

            <div class="spacing-unit--over">
              <co-slider-form
                [label]="'DL.INVGU01.C196'"
                [sliderOptions]="sliderOptions"
                ngDefaultControl
                formControlName="estateDebt"
                type="currency"
              ></co-slider-form>
            </div>

            <div class="form-row">
              <label
                for="percentageOwned"
                [coContent]="'DL.INVGU01.C197'"
                class="text--bold"
              ></label>
              <div class="form-row_field">
                <mat-form-field class="small-input" color="accent">
                  <mat-label>{{ 'DL.MD01.C419' | content }}</mat-label>
                  <input
                    id="percentageOwned"
                    name="percentageOwned"
                    matInput
                    ngDefaultControl
                    [formControl]="form.controls.percentageOwned"
                  />
                  <mat-error
                    *ngIf="
                      form.controls.percentageOwned.errors?.required;
                      else notEmpty
                    "
                    coContent="DL.MD01.C121"
                  ></mat-error>

                  <ng-template #notEmpty>
                    <mat-error
                      *ngIf="
                        form.controls.percentageOwned.errors?.notNumber;
                        else correctFormat
                      "
                      coContent="DL.MD01.C13"
                    ></mat-error>

                    <ng-template #correctFormat>
                      <mat-error
                        *ngIf="form.controls.percentageOwned.errors?.max"
                        coContent="DL.MD01.C14"
                        [coContentSubst]="100"
                      ></mat-error>

                      <mat-error
                        *ngIf="form.controls.percentageOwned.errors?.min"
                        coContent="DL.MD01.C21a"
                        coContentSubst="0|%"
                      ></mat-error>
                    </ng-template>
                  </ng-template>
                </mat-form-field>
              </div>
            </div>
          </ng-container>

          <ng-template #readonlyView>
            <div class="spacing-unit--under">
              <h3 class="align--center" coContent="DL.INVGU01.C372"></h3>
            </div>
            <div
              *ngFor="let realEstate of primaryResidences.realEstates"
              class="spacing-unit--over"
            >
              <div class="form-row--underline">
                <label [coContent]="'DL.MD01.C289'" class="text--bold"></label>
                <div class="form-row_field">
                  {{ realEstate.realEstateName }}
                </div>
              </div>
              <div class="form-row--underline">
                <label
                  [coContent]="'DL.INVGU01.C195'"
                  class="text--bold"
                ></label>
                <div class="form-row_field">
                  {{ realEstate.currentMarketValue | currencyFormat }}
                </div>
              </div>
              <div class="form-row--underline">
                <label
                  [coContent]="'DL.INVGU01.C196'"
                  class="text--bold"
                ></label>
                <div class="form-row_field">
                  {{ realEstate.estateDebt | currencyFormat }}
                </div>
              </div>
              <div class="form-row--underline">
                <label
                  [coContent]="'DL.INVGU01.C197'"
                  class="text--bold"
                ></label>
                <div class="form-row_field">
                  {{ realEstate.percentageOwned | numberFormat: decimals }} %
                </div>
              </div>
            </div>
          </ng-template>

          <div class="align--center spacing-unit--double-over">
            <button
              class="button-secondary"
              mat-raised-button
              (click)="goToNextPage()"
              [disabled]="!form.valid"
            >
              <span [coContent]="'DL.INVGU01.C621'"></span>
            </button>
          </div>
        </form>
      </co-card>
    </co-card-animated-stack-item>
  </co-card-animated-stack>
</div>

<div class="back-button-container--question" *ngIf="activeIndex === 0">
  <mitpfa-investment-guide-back-button
    (back)="goBack()"
  ></mitpfa-investment-guide-back-button>
</div>
