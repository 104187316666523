<div class="wrapper">
  <div class="text--container">
    <mitpfa-svg-traffic-light-green
      class="traffic-light"
    ></mitpfa-svg-traffic-light-green>
    <ng-container>
      <div class="spacing-unit--one--half-under align--center">
        <span class="text--manchet" [coContent]="'DL.VK01.C140'"></span>
        <span>&nbsp;</span>
        <span
          class="text--manchet focus--text"
          [coContent]="'DL.VK01.C141'"
        ></span>
      </div>
      <div class="align--center">
        <button
          mat-raised-button
          class="mat-focus-indicator button-secondary mat-raised-button mat-button-bas"
          (click)="done()"
          [coContent]="'DL.VK01.C142'"
        ></button>
      </div>
    </ng-container>
  </div>
  <mitpfa-svg-city class="city"></mitpfa-svg-city>
</div>
