/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ServiceInfo } from './serviceInfo';
import { ServiceInfoSchema } from './serviceInfo';
import { ResourceModel } from './resourceModel';
import { ResourceModelSchema } from './resourceModel';
import * as JoiObj from "joi";


    export const EngangsUdbetalingSchema = JoiObj.object({
        serviceInfo:
            
        JoiObj.link('#ServiceInfoSchema')

.allow(null)



        ,
        aftaleId:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        alder:
            
                    JoiObj.number()
                


.required()


        ,
        beloeb:
            
                    JoiObj.number()
                


.required()


        ,
        indtastet:
            
                    JoiObj.boolean()
                


.required()


        ,
        selvskabsCTekst:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        skattefri:
            
                    JoiObj.boolean()
                


.required()


        ,
        tarif:
            
                    JoiObj.boolean()
                


.required()


        ,
        typeTekst:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        udbetalingStartDato:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        unmarried:
            
                    JoiObj.boolean()
                


.required()


        
    })
                .shared(ServiceInfoSchema)
.id('EngangsUdbetalingSchema')

export interface EngangsUdbetaling extends ResourceModel { 
    aftaleId: string;
    alder: number;
    beloeb: number;
    indtastet: boolean;
    selvskabsCTekst: string;
    skattefri: boolean;
    tarif: boolean;
    typeTekst: string;
    udbetalingStartDato: string;
    unmarried: boolean;
}

