/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OnboardingActivatePensionStep } from './onboardingActivatePensionStep';
import { OnboardingActivatePensionStepSchema } from './onboardingActivatePensionStep';
import * as JoiObj from "joi";


    export const OnboardingActivatePensionStepsSchema = JoiObj.object({
        items:
                JoiObj.array().items(
        
        JoiObj.link('#OnboardingActivatePensionStepSchema')

        )
        

.required()


        
    })
                .shared(OnboardingActivatePensionStepSchema)
.id('OnboardingActivatePensionStepsSchema')

export interface OnboardingActivatePensionSteps { 
    items: Array<OnboardingActivatePensionStep>;
}

