/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const PlanSchema = JoiObj.object({
        planType:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        daekningsgivendeLoen:
            
                    JoiObj.number()
                


.required()


        ,
        forsoergerAndel:
            
                    JoiObj.number()
                


.required()


        ,
        giftSamlevende:
            
                    JoiObj.boolean()
                


.required()


        ,
        harBoern:
            
                    JoiObj.boolean()
                


.required()


        ,
        procent:
            
                    JoiObj.number()
                


.required()


        ,
        recommendationAdjustedToMinimum:
            
                    JoiObj.boolean()
                


.required()


        ,
        saerligAnbefaling:
            
                    JoiObj.boolean()
                


.required()


        
    })
.id('PlanSchema')

export interface Plan { 
    planType: string;
    daekningsgivendeLoen: number;
    forsoergerAndel: number;
    giftSamlevende: boolean;
    harBoern: boolean;
    procent: number;
    recommendationAdjustedToMinimum: boolean;
    saerligAnbefaling: boolean;
}

