<svg
  id="Layer_1"
  data-name="Layer 1"
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 32 32"
>
  <g id="Canvas">
    <circle
      id="Large_circle"
      data-name="Large circle"
      cx="16"
      cy="16"
      r="16"
      fill="none"
    />
    <circle
      id="Small_circle"
      data-name="Small circle"
      cx="16"
      cy="16"
      r="12"
      fill="none"
    />
  </g>
  <g id="Icon">
    <path
      d="M17.54,18.93a.46.46,0,0,0-.47.44v3.4a.44.44,0,0,0,.46.43h6a.45.45,0,0,0,.47-.43v-3.3a.56.56,0,0,0-.58-.54Z"
      fill="none"
      stroke="#000"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.35"
    />
    <path
      d="M20.53,20.8a.27.27,0,0,1,.26.27.26.26,0,0,1-.27.26.26.26,0,0,1-.25-.26.27.27,0,0,1,.26-.27h0"
      fill="none"
      stroke="#000"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.35"
    />
    <path
      d="M17.07,21A5.2,5.2,0,0,1,12.8,23.2c-2.41,0-4.8-1.79-4.8-4s1.89-4.59,3.35-5.6l-1-2.22A1.06,1.06,0,0,1,10.84,10a1,1,0,0,1,.45-.1h3.64"
      fill="none"
      stroke="#000"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.35"
    />
    <path
      d="M15.86,15.13a7.18,7.18,0,0,1,1.73,3.8"
      fill="none"
      stroke="#000"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.35"
    />
    <path
      d="M11.61,20.24a1.59,1.59,0,0,0,1.32.63c.81,0,1.47-.5,1.47-1.1s-.66-1.1-1.47-1.1a1.32,1.32,0,0,1-1.46-1.1c0-.61.65-1.1,1.46-1.1a1.57,1.57,0,0,1,1.32.62"
      fill="none"
      stroke="#000"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.35"
    />
    <line
      x1="12.93"
      y1="20.87"
      x2="12.93"
      y2="21.6"
      fill="none"
      stroke="#000"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.35"
    />
    <line
      x1="12.93"
      y1="15.73"
      x2="12.93"
      y2="16.47"
      fill="none"
      stroke="#000"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.35"
    />
    <polyline
      points="24 11.31 24 8.8 21.49 8.8"
      fill="none"
      stroke="#000"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.35"
    />
    <path
      d="M24,8.8l-2.31,2.31a1,1,0,0,1-1,.26l-2.61-.7a1.05,1.05,0,0,0-1,.27L14.4,13.6"
      fill="none"
      stroke="#000"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.35"
    />
  </g>
</svg>
