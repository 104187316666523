import { Email, StamdataDetaljer, SuccessResponse } from '@pfa/gen';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

export interface UpdateContactInformationDTO {
  kundeEmail: string;
  mobil: string;
  landekode: string;
}

@Injectable({
  providedIn: 'root'
})
export class CustomerContactApiService {
  constructor(private http: HttpClient) {}

  public updateEmail(data: Email): Observable<SuccessResponse> {
    return this.http.post<SuccessResponse>(
      '/ds/api/pensionskunde/opdaterEmail',
      data
    );
  }

  public updateContactInformation(
    data: UpdateContactInformationDTO
  ): Observable<StamdataDetaljer> {
    return this.http.post<StamdataDetaljer>(
      '/ds/api/pensionskunde/opdaterMobilnrOgEmail',
      data
    );
  }
}
