import { Component, Input } from '@angular/core';
import { OpsparingPoliceDetaljer } from '@pfa/gen';

@Component({
  selector: 'mitpfa-savings-policy-details-averagerate',
  templateUrl: './savings-policy-details-averagerate.component.html',
  styleUrls: ['./savings-policy-details-averagerate.component.scss']
})
export class SavingsPolicyDetailsAveragerateComponent {
  @Input() savingsPolicyDetails: OpsparingPoliceDetaljer;
  @Input() palTooltip: string;
  @Input() unpaidPALTooltip: string;

  expandedPrepaid: boolean;
  expandedTransferBack: boolean;

  toggleExpandedPrepaid(expanded: boolean): void {
    this.expandedPrepaid = expanded;
  }

  toggleExpandedTransferBack(expanded: boolean): void {
    this.expandedTransferBack = expanded;
  }
}
