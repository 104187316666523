/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Tilstand } from './tilstand';
import { TilstandSchema } from './tilstand';
import * as JoiObj from "joi";


    export const PolicyReusableForOfferSchema = JoiObj.object({
        id:
            
                    JoiObj.number()
                


.required()


        ,
        tilstand:
            
        JoiObj.link('#TilstandSchema')


.required()


        
    })
                .shared(TilstandSchema)
.id('PolicyReusableForOfferSchema')

export interface PolicyReusableForOffer { 
    id: number;
    tilstand: Tilstand;
}
export namespace PolicyReusableForOffer {
}


