import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import {
  PPUdbetalingsplanDetaljer,
  MinUdbetalingsplan,
  MinUdbPlan,
  PfaPlanTabelData,
  RisikoTabelData,
  TarifUdbetalinger,
  OptimeringRequest,
  OptimeringSvar,
  UdbetalingDetaljer,
  DaekningsDetaljer,
  DaekningsType,
  AdvancedPayoutChoiceLivnetPolicyCoverage,
  AdvancedPayoutChoiceLivnetPolicy,
  IndbetalingOversigtPolice,
  AdvancedPayoutChoice,
  SignatureIframeResponse,
  GodkendSvar,
  PensionPayoutConstraints,
  UdbetalingsplanStore
} from '@pfa/gen';

@Injectable({
  providedIn: 'root'
})
export class PayoutPlanService {
  constructor(
    private http: HttpClient,
    private pfaPlanStore: UdbetalingsplanStore
  ) {}

  getPayoutPlan(): Observable<PPUdbetalingsplanDetaljer> {
    const requestUrl = '/ds/api/udbetalingsplan';
    return this.http.get<PPUdbetalingsplanDetaljer>(requestUrl);
  }

  savePayoutPlan(
    minUdbetalingsplan: MinUdbetalingsplan
  ): Observable<PPUdbetalingsplanDetaljer> {
    const requestUrl = '/ds/api/udbetalingsplan/minudbetalingsplan';
    return this.http.post<PPUdbetalingsplanDetaljer>(
      requestUrl,
      minUdbetalingsplan
    );
  }

  addChangesToBasket(policies: AdvancedPayoutChoice) {
    const requestUrl = '/ds/api/payoutplan/basket';
    return this.http.post<AdvancedPayoutChoice>(requestUrl, policies);
  }

  getBasketChanges(): Observable<AdvancedPayoutChoice> {
    const requestUrl = '/ds/api/payoutplan/basket';
    return this.http.get<AdvancedPayoutChoice>(requestUrl);
  }

  removeBasketChanges(): Observable<AdvancedPayoutChoice> {
    const requestUrl = '/ds/api/payoutplan/basket';
    return this.http.delete<AdvancedPayoutChoice>(requestUrl);
  }

  buildDocumentForSigning(
    advancedPayoutChoice: AdvancedPayoutChoice,
    language: string
  ): Observable<SignatureIframeResponse> {
    const requestUrl = '/ds/api/payoutplan/basket/sign';
    return this.http.post<SignatureIframeResponse>(requestUrl, {
      advancedPayoutChoice,
      language
    });
  }

  signBasketChanges(
    revisionKey: string,
    mitIdSig: string
  ): Observable<GodkendSvar> {
    const requestUrl = '/ds/api/payoutplan/basket/sign/1';
    return this.http.post<GodkendSvar>(requestUrl, {
      revisionKey,
      mitIdSig
    });
  }

  voluntaryPayment(
    minUdbPlan: MinUdbPlan
  ): Observable<PPUdbetalingsplanDetaljer> {
    const requestUrl = '/ds/api/udbetalingsplan/frivilligindbetaling';
    return this.http.post<PPUdbetalingsplanDetaljer>(requestUrl, minUdbPlan);
  }

  simulate(minUdbPlan: MinUdbPlan): Observable<PPUdbetalingsplanDetaljer> {
    const requestUrl = '/ds/api/udbetalingsplan/simulere';
    return this.http.post<PPUdbetalingsplanDetaljer>(requestUrl, minUdbPlan);
  }

  public pfaplan(): Observable<PfaPlanTabelData> {
    return this.pfaPlanStore.udbetalingsplanPfaplanGet();
  }

  public coveragesWithPayoutsStartedIncludingCoveragesThatPayoutsAreEnded(
    pfaPlan: PfaPlanTabelData
  ): DaekningsDetaljer[] {
    const coverages = pfaPlan.daekningerUdenUdbetaling.concat(
      pfaPlan.daekningerMedOphoerendePension
    );
    const matchingCoverages =
      this.coveragesWithPayoutsStartedSubOrWithEndedPayouts(coverages, [
        'RATEPENSION',
        'TIDSBEGRÆNSET_LIVSPENSION',
        'OPHOERENDELIVSPENSION'
      ]);
    matchingCoverages.push(
      ...this.coveragesWithPayoutsStartedSubOrWithEndedPayouts(
        pfaPlan.daekningerMedLivsvarigPension,
        ['LIVSVARIG_LIVSPENSION']
      )
    );
    return matchingCoverages;
  }

  private coveragesWithPayoutsStartedSubOrWithEndedPayouts(
    coverages: DaekningsDetaljer[],
    coverageTypes: DaekningsType[]
  ): DaekningsDetaljer[] {
    let matchingCoverages = new Array<DaekningsDetaljer>();
    if (coverages.length > 0) {
      matchingCoverages = coverages.filter(
        coverage =>
          (coverage.underUdbetaling &&
            coverage.pensionsselskabNr === 'DL.SEL.C10' &&
            coverageTypes.includes(coverage.daekningsType)) ||
          (coverage.aarSpaend.length > 0 && !coverage.underUdbetaling)
      );
    }
    return matchingCoverages;
  }

  riskTable(policeId: String): Observable<RisikoTabelData> {
    const requestUrl = '/ds/api/udbetalingsplan/risikotabel/' + policeId;
    return this.http.get<RisikoTabelData>(requestUrl);
  }

  tariffPayout(policeId: String): Observable<TarifUdbetalinger> {
    const requestUrl = '/ds/api/udbetalingsplan/tarifudbetaling/' + policeId;
    return this.http.get<TarifUdbetalinger>(requestUrl);
  }

  optimize(optimizeRequest: OptimeringRequest): Observable<OptimeringSvar> {
    const requestUrl = '/ds/api/udbetalingsplan/optimer';
    return this.http.post<OptimeringSvar>(requestUrl, optimizeRequest);
  }

  getHistoricalPayout(
    policeId: string,
    year: string
  ): Observable<UdbetalingDetaljer> {
    const requestUrl =
      '/ds/api/pensionsinfo/hentUdbetalingDetaljer/' + policeId + '/' + year;
    return this.http.get<UdbetalingDetaljer>(requestUrl);
  }

  getPensionPayoutConstraints(): Observable<PensionPayoutConstraints> {
    return this.http.get<PensionPayoutConstraints>(
      '/ds/api/payoutplan/basket/payoutconstraints'
    );
  }

  public getEarliestPayoutDate(
    policyCoverages: AdvancedPayoutChoiceLivnetPolicyCoverage[]
  ): Date {
    let earliestPayoutDate: Date;
    policyCoverages.forEach(
      (policyCoverage: AdvancedPayoutChoiceLivnetPolicyCoverage) => {
        //get earliest maxDate based on payoutDate
        if (
          earliestPayoutDate === undefined ||
          policyCoverage.payoutDate < earliestPayoutDate
        ) {
          earliestPayoutDate = policyCoverage.payoutDate;
        }
      }
    );
    return earliestPayoutDate;
  }

  public getPolicy(
    currentChoices: AdvancedPayoutChoiceLivnetPolicy[],
    policyId: string
  ): AdvancedPayoutChoiceLivnetPolicy {
    let currentPolicy: AdvancedPayoutChoiceLivnetPolicy;
    currentChoices.forEach((policy: AdvancedPayoutChoiceLivnetPolicy) => {
      if (policy.policyId === policyId) {
        currentPolicy = policy;
      }
    });
    return currentPolicy;
  }

  public hasPolicyContinuousDeposits(
    policy: AdvancedPayoutChoiceLivnetPolicy,
    savingsOverviewPolicies: IndbetalingOversigtPolice[]
  ): boolean {
    return savingsOverviewPolicies.some(
      (depositOverviewPolicy: IndbetalingOversigtPolice) =>
        depositOverviewPolicy.id === policy.policyId &&
        this.hasContinuousDeposits(depositOverviewPolicy)
    );
  }

  private hasContinuousDeposits(
    depositOverviewPolicy: IndbetalingOversigtPolice
  ): boolean {
    return depositOverviewPolicy.tilstand === 'LoebendeIndbetaling';
  }
}
