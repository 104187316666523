import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as moment from 'moment';
import { InvestmentGuide } from '@pfa/gen';
import { InvestmentGuideInfo } from '@pfa/models';

@Injectable({
  providedIn: 'root'
})
export class InvestmentGuideApiService {
  constructor(private http: HttpClient) {}

  public get(globalId: string): Observable<InvestmentGuideInfo> {
    const url = `/investment-recommendation/${globalId}`;
    return this.http.get<InvestmentGuideInfo>(url).pipe(
      map(investmentGuideInfo => {
        investmentGuideInfo.isGuideCompleted = false;
        if (
          investmentGuideInfo.recommendation &&
          investmentGuideInfo.recommendation.calculatedInvestmentConcept
        ) {
          investmentGuideInfo.isGuideCompleted = true;
          const guideCompletedDate = moment(
            investmentGuideInfo.recommendation.created
          );
          investmentGuideInfo.isGuideMoreThan1YearOld =
            guideCompletedDate.isBefore(moment().subtract(1, 'years'));
          investmentGuideInfo.isGuideMoreThan3YearOld =
            guideCompletedDate.isBefore(moment().subtract(3, 'years'));
          investmentGuideInfo.recommendedClimatePercent =
            investmentGuideInfo.recommendation.recommendedClimatePercent;
        }
        investmentGuideInfo.showWarningPfaInv =
          !investmentGuideInfo.isGuideCompleted ||
          investmentGuideInfo.isGuideMoreThan1YearOld;

        return investmentGuideInfo;
      })
    );
  }

  public update(
    globalId: string,
    data: InvestmentGuide,
    createRecommendation = false
  ): Observable<InvestmentGuideInfo> {
    const url = `/investment-recommendation/${globalId}`;
    if (createRecommendation) {
      return this.http.post<InvestmentGuideInfo>(url, data);
    }
    return this.http.put<InvestmentGuideInfo>(url, data);
  }
}
