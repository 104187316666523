import {
  Component,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  inject
} from '@angular/core';
import { openClose, OPEN_CLOSE_ANIMATION_DELAY } from '@pfa/animations';

@Component({
  selector: 'co-questionnaire-accordion',
  templateUrl: './questionnaire-accordion.component.html',
  styleUrls: ['./questionnaire-accordion.component.scss'],
  animations: [openClose]
})
export class QuestionnaireAccordionComponent {
  public readonly elementRef: ElementRef = inject(ElementRef);

  @Input() isComplete: boolean;
  @Input() isDirty: boolean;
  @Input() editable = true;
  @Input() headline: string;
  @Input() subHeadline: string;
  @Input() additionalInfo: string;
  @Input() toggleable: boolean;
  @Input() isToggled: boolean;
  @Input() active: boolean;
  @Input() isActionDisabled: boolean;
  @Input() overrideActionEnabled = false;

  @Output() edit = new EventEmitter<HTMLElement>();
  @Output() enableSelect = new EventEmitter<boolean>();

  public OPEN_CLOSE_ANIMATION_DELAY = OPEN_CLOSE_ANIMATION_DELAY;
  public actionLabel = this.editable ? 'DL.HV01.C37' : 'DL.HV01.C40';

  public get isActionVisible() {
    if (this.isActionDisabled) {
      return false;
    }

    return (
      (!this.active &&
        (this.isComplete ||
          this.isDirty ||
          (!this.editable && this.isComplete))) ||
      this.overrideActionEnabled
    );
  }

  handleEdit() {
    this.edit.emit(this.elementRef.nativeElement);
  }
}
