import {
  UntypedFormGroup,
  Validators,
  UntypedFormControl
} from '@angular/forms';
import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { ContactData } from '@pfa/gen';

@Component({
  selector: 'co-contact-info',
  templateUrl: './contact-info.component.html',
  styleUrls: ['./contact-info.component.scss']
})
export class ContactInfoComponent implements OnInit {
  @Output() contactInfoRef = new EventEmitter<UntypedFormGroup>();
  @Input() enableAllValidationErrors: boolean;
  @Input() contactData: ContactData;

  public contactInfoForm: UntypedFormGroup;

  ngOnInit() {
    if (this.contactData) {
      this.createContactInfoForm(this.contactData);
    } else {
      this.createContactInfoForm(null);
    }
  }
  createContactInfoForm(contactData: ContactData) {
    this.contactInfoForm = new UntypedFormGroup({
      phoneCountryCode: new UntypedFormControl(
        contactData ? contactData.phoneCountryCode : '',
        {
          validators: [Validators.pattern('[+]?(1( )?)?[0-9]{1,3}')],
          updateOn: 'blur'
        }
      ),
      phoneNumber: new UntypedFormControl(
        contactData ? contactData.phoneNumber : '',
        {
          validators: [Validators.pattern('^[0-9]{6,30}$')],
          updateOn: 'blur'
        }
      ),
      email: new UntypedFormControl(contactData ? contactData.email : '', {
        validators: [Validators.required, Validators.email],
        updateOn: 'blur'
      })
    });
    this.contactInfoRef.emit(this.contactInfoForm);
  }
}
