/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ServiceInfo } from './serviceInfo';
import { ServiceInfoSchema } from './serviceInfo';
import { ClimateRecommendationSpan } from './climateRecommendationSpan';
import { ClimateRecommendationSpanSchema } from './climateRecommendationSpan';
import { AktivFordeling } from './aktivFordeling';
import { AktivFordelingSchema } from './aktivFordeling';
import { ResourceModel } from './resourceModel';
import { ResourceModelSchema } from './resourceModel';
import { InvesteringPolice } from './investeringPolice';
import { InvesteringPoliceSchema } from './investeringPolice';
import * as JoiObj from "joi";


    export const InvesteringDetaljerSchema = JoiObj.object({
        serviceInfo:
            
        JoiObj.link('#ServiceInfoSchema')

.allow(null)



        ,
        climateRecommendationSpans:
                JoiObj.array().items(
        
        JoiObj.link('#ClimateRecommendationSpanSchema')

        )
        

.required()


        ,
        fravalgtKundekapitalPolicer:
                JoiObj.array().items(
        
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

        )
        

.required()


        ,
        harGipp:
            
                    JoiObj.boolean()
                


.required()


        ,
        harMindstEnIkkeFejletPoliceMedOpsparing:
            
                    JoiObj.boolean()
                


.required()


        ,
        opgoerelsesdatoOpsparingKoloneTooltipContentId:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        opgoerelsesdatoPfaKundekapitalKoloneTooltipContentId:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        policer:
                JoiObj.array().items(
        
        JoiObj.link('#InvesteringPoliceSchema')

        )
        

.required()


        ,
        primaerInvesteringsPolice:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        primaerPolice:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        primaerUnderudbetaling:
            
                    JoiObj.boolean()
                


.required()


        ,
        savingsInMoneyMarketFund:
            
                    JoiObj.boolean()
                


.required()


        ,
        totalFordeling:
            
        JoiObj.link('#AktivFordelingSchema')


.required()


        ,
        totalOpsparing:
            
                    JoiObj.number()
                


.required()


        ,
        udbetalingDoedId:
                JoiObj.array().items(
        
                    JoiObj.number()
                

        )
        

.required()


        ,
        udestaaendeHandler:
            
                    JoiObj.boolean()
                


.required()


        
    })
                .shared(ServiceInfoSchema)
                .shared(ClimateRecommendationSpanSchema)
                .shared(InvesteringPoliceSchema)
                .shared(AktivFordelingSchema)
.id('InvesteringDetaljerSchema')

export interface InvesteringDetaljer extends ResourceModel { 
    climateRecommendationSpans: Array<ClimateRecommendationSpan>;
    fravalgtKundekapitalPolicer: Array<string>;
    harGipp: boolean;
    harMindstEnIkkeFejletPoliceMedOpsparing: boolean;
    opgoerelsesdatoOpsparingKoloneTooltipContentId: string;
    opgoerelsesdatoPfaKundekapitalKoloneTooltipContentId: string;
    policer: Array<InvesteringPolice>;
    primaerInvesteringsPolice: string;
    primaerPolice: string;
    primaerUnderudbetaling: boolean;
    savingsInMoneyMarketFund: boolean;
    totalFordeling: AktivFordeling;
    totalOpsparing: number;
    udbetalingDoedId: Array<number>;
    udestaaendeHandler: boolean;
}

