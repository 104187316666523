<!-- v1 -->
<svg
  id="Layer_1"
  data-name="Layer 1"
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 32 32"
>
  <g id="Canvas">
    <circle
      id="Large_circle"
      data-name="Large circle"
      cx="16"
      cy="16"
      r="16"
      fill="none"
    />
    <circle
      id="Small_circle"
      data-name="Small circle"
      cx="16"
      cy="16"
      r="12"
      fill="none"
    />
  </g>
  <g id="Icon">
    <line
      x1="16"
      y1="9.06"
      x2="16"
      y2="8"
      fill="none"
      stroke="#000"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.2"
    />
    <path
      d="M16,16v7a1.07,1.07,0,1,1-2.13,0"
      fill="none"
      stroke="#000"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.2"
    />
    <path
      d="M23.59,19.27a1.36,1.36,0,0,0-1.91-.05l0,.05-.84.86L20,19.27a1.36,1.36,0,0,0-1.91-.05l0,.05a1.44,1.44,0,0,0,0,2l2.49,2.58a.4.4,0,0,0,.58,0l0,0,2.49-2.58A1.46,1.46,0,0,0,23.59,19.27Z"
      fill="none"
      stroke="#000"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.2"
    />
    <path
      d="M24,17.06a8,8,0,0,0-16,0l.58-.48a3.35,3.35,0,0,1,4.71.43l0,0a3.77,3.77,0,0,1,5.33,0h0a3.35,3.35,0,0,1,4.7-.52l.05,0Z"
      fill="none"
      stroke="#000"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.2"
    />
  </g>
</svg>
