import { Injectable } from '@angular/core';
import { TransferableCategoryType } from '@pfa/gen';
import { Observable, of } from 'rxjs';
import {
  CombineSavingsAbility,
  CombineSavingsStep,
  stepsData,
  TrackingStepType
} from './combine-savings.model';

const CombineSavingsPaths = {
  [CombineSavingsAbility.TransferableByCustomer]: [
    CombineSavingsStep.Intro,
    CombineSavingsStep.PensionInfo,
    CombineSavingsStep.AcceptTransfer,
    CombineSavingsStep.Consent,
    CombineSavingsStep.Summary
  ],
  [CombineSavingsAbility.TransferableByAdvisor]: [
    CombineSavingsStep.Intro,
    CombineSavingsStep.PensionInfo,
    CombineSavingsStep.TransferLead,
    CombineSavingsStep.Summary
  ],
  [CombineSavingsAbility.NotTransferable]: [
    CombineSavingsStep.Intro,
    CombineSavingsStep.PensionInfo,
    CombineSavingsStep.Summary
  ]
};

@Injectable()
export class CombineSavingsNavigationService {
  public steps: CombineSavingsStep[] =
    CombineSavingsPaths[CombineSavingsAbility.TransferableByCustomer];
  public currentStep: CombineSavingsStep = this.steps[0];

  public setCombineSavingsPath(
    recommendationOverallCategory: TransferableCategoryType
  ): void {
    if (!recommendationOverallCategory) {
      recommendationOverallCategory = CombineSavingsAbility.NotTransferable;
    }
    this.steps = CombineSavingsPaths[recommendationOverallCategory];
  }

  public navigateToIntro(): Observable<string> {
    this.currentStep = CombineSavingsStep.Intro;
    const stepName = this.getCurrentStepName();
    return of(stepName);
  }

  public navigateNext({
    from
  }: {
    from: CombineSavingsStep;
  }): Observable<string> {
    const currentIndex = this.findStepIndex(from);
    this.currentStep = this.steps[currentIndex + 1];
    const stepName = this.getCurrentStepName();
    return of(stepName);
  }

  public navigatePrev({
    from
  }: {
    from: CombineSavingsStep;
  }): Observable<string> {
    const currentIndex = this.findStepIndex(from);
    this.currentStep = this.steps[currentIndex - 1];
    const stepName = this.getCurrentStepName();
    return of(stepName);
  }

  public navigateDirectly(to: CombineSavingsStep): void {
    this.currentStep = to;
  }

  public getStepName(step: CombineSavingsStep): string {
    const stepData = stepsData.find(x => x.step === step);
    if (stepData) {
      return stepData.name;
    }
    return '';
  }

  public getCurrentTrackingStepType(): TrackingStepType {
    const stepData = stepsData.find(x => x.step === this.currentStep);
    if (stepData) {
      return stepData.trackingStepType;
    }
    return TrackingStepType.None;
  }

  public getCurrentTrackingStepName(): string {
    const stepData = stepsData.find(x => x.step === this.currentStep);
    if (stepData) {
      return stepData.trackingName;
    }
    return '';
  }

  private getCurrentStepName(): string {
    const stepData = stepsData.find(x => x.step === this.currentStep);
    if (stepData) {
      return stepData.name;
    }
    return '';
  }

  private findStepIndex(step: CombineSavingsStep): number {
    const currentIndex = this.steps.indexOf(step);
    if (currentIndex === -1) {
      throw new Error('Combine Savings step not found');
    }
    return currentIndex;
  }
}
