<svg
  id="Layer_1"
  data-name="Layer 1"
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 32 32"
>
  <g id="Canvas">
    <circle
      id="Large_circle"
      data-name="Large circle"
      cx="16"
      cy="16"
      r="16"
      fill="none"
    />
    <circle
      id="Small_circle"
      data-name="Small circle"
      cx="16"
      cy="16"
      r="12"
      fill="none"
    />
  </g>
  <g id="Icon">
    <path
      d="M9.09,24.5A1.59,1.59,0,0,1,7.5,22.91V9.09A1.59,1.59,0,0,1,9.09,7.5H22.91A1.59,1.59,0,0,1,24.5,9.09V22.91a1.59,1.59,0,0,1-1.59,1.59Zm0-15.94a.53.53,0,0,0-.53.53V22.91a.53.53,0,0,0,.53.53H22.91a.53.53,0,0,0,.53-.53V9.09a.53.53,0,0,0-.53-.53Z"
    />
    <path
      d="M14.94,20.78a2.14,2.14,0,0,1-2.06-1.59H11.22a.53.53,0,0,1-.53-.53.53.53,0,0,1,.53-.53h1.66a2.12,2.12,0,0,1,4.11,0h3.79a.53.53,0,0,1,.53.53.53.53,0,0,1-.53.53H17A2.13,2.13,0,0,1,14.94,20.78Zm0-3.19A1.07,1.07,0,1,0,16,18.66a1.06,1.06,0,0,0-1.06-1.07v0Z"
    />
    <path
      d="M19.19,15.47a2.14,2.14,0,0,1-2.06-1.6H11.22a.53.53,0,0,1-.53-.53.53.53,0,0,1,.53-.53h5.91a2.12,2.12,0,1,1,2.06,2.66Zm0-3.19a1.07,1.07,0,1,0,1.06,1.06A1.07,1.07,0,0,0,19.19,12.28Z"
    />
  </g>
</svg>
