<co-alert *ngIf="error" class="full-page alert-center">
  <h3 [coContent]="'DL.AP02.C19'"></h3>
  <p [coContent]="'DL.AP02.C20'"></p>
  <button
    [routerLink]="'/'"
    coContent="DL.VK01.C45"
    mat-raised-button
    class="button-secondary spacing-unit--over"
  ></button>
</co-alert>
