import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MaeglerInfo } from '@pfa/gen';
import { InvestmentGuideInfo } from '@pfa/models';
import { InvestmentGuideQuestion } from '../../../investment-guide-configuration.service';
import { ClimateExtendedFocusChoice } from '../../investment-guide-climate-step.model';
import { InvestmentGuideService } from '@mitpfa/shared';

@Component({
  selector: 'mitpfa-investment-guide-climate-extended-focus',
  templateUrl: './investment-guide-climate-step-extended-focus.component.html',
  styleUrls: ['./investment-guide-climate-step-extended-focus.component.scss']
})
export class InvestmentGuideClimateExtendedFocusComponent implements OnInit {
  @Input() investmentGuide: InvestmentGuideInfo;
  @Input() broker: MaeglerInfo;

  answer: UntypedFormControl;
  choices: ClimateExtendedFocusChoice[] = [
    {
      text: 'DL.INVGU01.C564',
      value: 'MINOR'
    },
    {
      text: 'DL.INVGU01.C565',
      value: 'AVERAGE'
    },
    {
      text: 'DL.INVGU01.C566',
      value: 'HIGH'
    }
  ];

  constructor(
    private readonly investmentGuideService: InvestmentGuideService
  ) {}

  ngOnInit(): void {
    this.answer = new UntypedFormControl(
      this.investmentGuide.recommendationBasis.climateExtendedFocus,
      Validators.required
    );
  }

  choose(): void {
    this.investmentGuide.recommendationBasis.climateExtendedFocus =
      this.answer.value;
    this.investmentGuideService.updateCurrentQuestion({
      value: InvestmentGuideQuestion.climateWillingness,
      update: true
    });
  }
}
