<div class="section">
  <div class="section-icon">
    <ng-content select="[icon]"></ng-content>
  </div>

  <div class="section-body spacing-unit--over">
    <div class="section-name text--bold" [coContent]="content.title"></div>
    <div class="section-description" [coContent]="content.description"></div>

    <div class="indicator" indicator *ngIf="content.indicator?.display">
      <div
        [coContent]="content.indicator?.label"
        class="indicator-label text--bold"
      ></div>

      <co-level-bar
        [value]="content.indicator?.level"
        [labelMin]="content.indicator?.labelMin"
        [labelMax]="content.indicator?.labelMax"
      ></co-level-bar>
    </div>

    <co-accordion
      title="DL.INVGU01.C630"
      [accordionName]="accordionName"
      class="accordion--flat accordion--contrast accordion--slim"
    >
      <div class="question" *ngFor="let question of content.questions">
        <span class="question-content" [coContent]="question.question"></span>

        <div class="response text--bold">
          <div
            *ngFor="let answer of question.answer"
            [coContent]="answer"
          ></div>
        </div>
      </div>
    </co-accordion>
  </div>
</div>
