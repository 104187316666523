/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const CustomerOptionsSchema = JoiObj.object({
        commissionAndRemuneration:
            
                    JoiObj.boolean()
                


.required()


        ,
        financePlusCustomerAllowed:
            
                    JoiObj.boolean()
                


.required()


        ,
        generateInheritanceAndTestamentLead:
            
                    JoiObj.boolean()
                


.required()


        ,
        lbInsuranceAllowed:
            
                    JoiObj.boolean()
                


.required()


        ,
        onboardingEnabled:
            
                    JoiObj.boolean()
                


.required()


        ,
        pfaPensionProductOfferAllowed:
            
                    JoiObj.boolean()
                


.required()


        ,
        pfaRentalHousingAllowed:
            
                    JoiObj.boolean()
                


.required()


        ,
        privateDepositAllowed:
            
                    JoiObj.boolean()
                


.required()


        
    })
.id('CustomerOptionsSchema')

export interface CustomerOptions { 
    commissionAndRemuneration: boolean;
    financePlusCustomerAllowed: boolean;
    generateInheritanceAndTestamentLead: boolean;
    lbInsuranceAllowed: boolean;
    onboardingEnabled: boolean;
    pfaPensionProductOfferAllowed: boolean;
    pfaRentalHousingAllowed: boolean;
    privateDepositAllowed: boolean;
}

