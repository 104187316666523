/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ServiceInfo } from './serviceInfo';
import { ServiceInfoSchema } from './serviceInfo';
import { CustomerCapitalPolice } from './customerCapitalPolice';
import { CustomerCapitalPoliceSchema } from './customerCapitalPolice';
import { KundekapitalAarligHistorik } from './kundekapitalAarligHistorik';
import { KundekapitalAarligHistorikSchema } from './kundekapitalAarligHistorik';
import { ResourceModel } from './resourceModel';
import { ResourceModelSchema } from './resourceModel';
import * as JoiObj from "joi";


    export const KundekapitalOversigtSchema = JoiObj.object({
        serviceInfo:
            
        JoiObj.link('#ServiceInfoSchema')

.allow(null)



        ,
        appetizerSkalVises:
            
                    JoiObj.boolean()
                


.required()


        ,
        fejledeAftaler:
                JoiObj.array().items(
        
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

        )
        

.required()


        ,
        forrentningSidsteAar:
            
                    JoiObj.number()
                


.required()


        ,
        harGipp:
            
                    JoiObj.boolean()
                


.required()


        ,
        harKundekapital:
            
                    JoiObj.boolean()
                


.required()


        ,
        harKundekapitalAfkast:
            
                    JoiObj.boolean()
                


.required()


        ,
        harKundekapitalOpsparing:
            
                    JoiObj.boolean()
                


.required()


        ,
        history:
                JoiObj.array().items(
        
        JoiObj.link('#KundekapitalAarligHistorikSchema')

        )
        

.required()


        ,
        kunForsikringer:
            
                    JoiObj.boolean()
                


.required()


        ,
        kundekapitalFravalgt:
                JoiObj.array().items(
        
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

        )
        

.required()


        ,
        kundekapitalModelCurrent:
            
                    JoiObj.number()
                .integer()


.required()


        ,
        kundekapitalModelPrevious:
            
                    JoiObj.number()
                .integer()


.required()


        ,
        kundekapitalMulig:
            
                    JoiObj.boolean()
                


.required()


        ,
        opgoerelsesdatoAfkastKoloneTooltipContentId:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        opgoerelsesdatoKundekapitalKoloneTooltipContentId:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        opgoerelsesdatoOpsparingKoloneTooltipContentId:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        optjentIAar:
            
                    JoiObj.number()
                


.required()


        ,
        optjentIAarUdbetalingMaaned:
            
                    JoiObj.number()
                .integer()

.allow(null)



        ,
        optjentSidsteAarUdbetalingMaaned:
            
                    JoiObj.number()
                .integer()

.allow(null)



        ,
        policer:
                JoiObj.array().items(
        
        JoiObj.link('#CustomerCapitalPoliceSchema')

        )
        

.required()


        ,
        renteIAar:
            
                    JoiObj.number()
                


.required()


        ,
        renteSidsteAar:
            
                    JoiObj.number()
                


.required()


        ,
        slutDato:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        startDato:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        udbetalingDoedId:
                JoiObj.array().items(
        
                    JoiObj.number()
                .integer()

        )
        

.required()


        ,
        udbetaltIAar:
            
                    JoiObj.number()
                


.required()


        
    })
                .shared(ServiceInfoSchema)
                .shared(KundekapitalAarligHistorikSchema)
                .shared(CustomerCapitalPoliceSchema)
.id('KundekapitalOversigtSchema')

export interface KundekapitalOversigt extends ResourceModel { 
    appetizerSkalVises: boolean;
    fejledeAftaler: Array<string>;
    forrentningSidsteAar: number;
    harGipp: boolean;
    harKundekapital: boolean;
    harKundekapitalAfkast: boolean;
    harKundekapitalOpsparing: boolean;
    history: Array<KundekapitalAarligHistorik>;
    kunForsikringer: boolean;
    kundekapitalFravalgt: Array<string>;
    kundekapitalModelCurrent: number;
    kundekapitalModelPrevious: number;
    kundekapitalMulig: boolean;
    opgoerelsesdatoAfkastKoloneTooltipContentId: string;
    opgoerelsesdatoKundekapitalKoloneTooltipContentId: string;
    opgoerelsesdatoOpsparingKoloneTooltipContentId: string;
    optjentIAar: number;
    optjentIAarUdbetalingMaaned?: number;
    optjentSidsteAarUdbetalingMaaned?: number;
    policer: Array<CustomerCapitalPolice>;
    renteIAar: number;
    renteSidsteAar: number;
    slutDato?: string;
    startDato?: string;
    udbetalingDoedId: Array<number>;
    udbetaltIAar: number;
}

