import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { InsuranceAdjustmentAtypicalChoiceResponse } from './insurance-adjustment-atypical-choice-popup.model';
import { InsuranceAdjustmentType } from '@mitpfa/shared';

@Component({
  selector: 'mitpfa-insurance-adjustment-atypical-choice-popup',
  templateUrl: './insurance-adjustment-atypical-choice-popup.component.html',
  styleUrls: ['./insurance-adjustment-atypical-choice-popup.component.scss']
})
export class InsuranceAdjustmentAtypicalChoicePopupComponent {
  reasonLoc: boolean;
  reasonCi: boolean;
  reasonLife: boolean;

  constructor(
    private readonly dialogRef: MatDialogRef<InsuranceAdjustmentAtypicalChoicePopupComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    const reasons: Array<InsuranceAdjustmentType> = data.reasons;
    this.reasonLoc = reasons.some(
      reason => reason === InsuranceAdjustmentType.LOC
    );
    this.reasonCi = reasons.some(
      reason => reason === InsuranceAdjustmentType.CI
    );
    this.reasonLife = reasons.some(
      reason => reason === InsuranceAdjustmentType.LIFE
    );
  }

  accept(): void {
    const response: InsuranceAdjustmentAtypicalChoiceResponse = {
      result: 'ACCEPT'
    };
    this.dialogRef.close(response);
  }

  cancel(): void {
    const response: InsuranceAdjustmentAtypicalChoiceResponse = {
      result: 'CANCEL'
    };
    this.dialogRef.close(response);
  }

  finish(): void {
    this.dialogRef.close();
  }
}
