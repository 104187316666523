<div mat-dialog-title>
  <h3 co-content="DL.DO01.C74"></h3>
  <co-icon-times
    (click)="closePopup()"
    data-test-id="ST01-consentPopup-close"
  ></co-icon-times>
</div>

<mat-dialog-content class="pdf--dialog pdf-dialog-wrapper">
  <ng2-pdfjs-viewer
    [pdfSrc]="pdfLink"
    [viewBookmark]="false"
    pagemode="none"
  ></ng2-pdfjs-viewer>
</mat-dialog-content>
