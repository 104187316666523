import {
  PensionPlanCard,
  PensionPlanCardAdditionalContent,
  PensionPlanCardDetails,
  PensionPlanCardEvent,
  PensionPlanCardMainContent,
  PolicyContext,
  PolicyType
} from '@pfaform';
import { ContentUtilService } from '@pfa/core';
import {
  CustomerCapitalPolice,
  IndbetalingOversigtPolice,
  InvesteringPolice,
  OpsparingPolice,
  Police,
  Profil
} from '@pfa/gen';
import {
  TypedCustomerCapitalPolicy,
  TypedDepositPolicy,
  TypedInvesteringPolicy,
  TypedPolicy,
  TypedSavingsPolicy
} from '@pfa/models';
import * as moment from 'moment';
import { formatNumber } from '@angular/common';
import { YouInvestService } from '@mitpfa/shared';

export class PensionPlanCardListBuilder {
  constructor(
    private readonly contentUtil: ContentUtilService,
    private readonly youInvestService: YouInvestService
  ) {}

  /**
   * Loops through all the pensionPlans(policies), and creates a card for them,
   * according to their type, or if they have failed.
   * @param policies
   */
  createPensionPlanCardList(policies: TypedPolicy[]): PensionPlanCard[] {
    return policies.map(policy => {
      const card = new PensionPlanCardBuilder();

      this.buildMainContent(card, policy);

      if (policy.context === PolicyContext.Investment) {
        const hasAdditional =
          policy.fejlet ||
          policy.type === PolicyType.Market ||
          policy.type === PolicyType.Average;
        this.buildDetailsForInvestmentContext(card, policy);
        this.buildActionsForInvestmentContext(card, policy);
        if (hasAdditional) {
          this.buildAdditionalContentForInvestmentContext(card, policy);
        }
      } else if (policy.context === PolicyContext.CustomerCapital) {
        this.buildDetailsForCustomerCapitalContext(card, policy);
        this.buildActionsForCustomerCapitalContext(card, policy);
        if (this.hasAdditional(policy)) {
          this.buildAdditionalContentForCustomerCapitalContext(card, policy);
        }
      } else if (policy.context === PolicyContext.Deposit) {
        this.buildDetailsForDepositContext(card, policy);
        this.buildActionsForDepositContext(card, policy);
        if (this.hasAdditional(policy)) {
          this.buildAdditionalContentForDepositContext(card, policy);
        }
      } else if (policy.context === PolicyContext.Savings) {
        this.buildDetailsForSavingsContext(card, policy);
        this.buildActionsForSavingsContext(card, policy);
        if (this.hasAdditional(policy)) {
          this.buildAdditionalContentForSavingsContext(card, policy);
        }
      }
      card.showDetailsText = 'DL.AFT01.C48';
      card.hideDetailsText = 'DL.AFT01.C49';
      return card;
    });
  }

  private hasAdditional(policy: Police): boolean {
    return (
      policy.type === PolicyType.Market ||
      policy.type === PolicyType.Average ||
      policy.type === PolicyType.Insurance
    );
  }

  private buildActionsForInvestmentContext(
    card: PensionPlanCard,
    policy: TypedInvesteringPolicy
  ) {
    if (!policy.fejlet) {
      if (policy.type === PolicyType.Market) {
        card.event = {
          enabled: true,
          id: policy.id,
          text: 'DL.AFT01.C46'
        };
      } else if (policy.type === PolicyType.Average) {
        card.event = {
          enabled: true,
          id: policy.id,
          text: 'DL.AFT01.C47'
        };
      }
    }
  }

  private buildActionsForCustomerCapitalContext(
    card: PensionPlanCard,
    policy: TypedCustomerCapitalPolicy
  ) {
    if (
      policy.detaljeMulig &&
      policy.tilstand !== 'Udbetalingspolicedoed' &&
      (policy.type === PolicyType.Market || policy.type === PolicyType.Average)
    ) {
      card.event = {
        enabled: true,
        id: policy.id,
        text: 'DL.AFT01.C46'
      };
    }
  }

  private buildActionsForDepositContext(
    card: PensionPlanCard,
    policy: TypedDepositPolicy
  ) {
    if (
      policy.detaljeMulig &&
      (policy.type === PolicyType.Market || policy.type === PolicyType.Average)
    ) {
      card.event = {
        enabled: true,
        id: policy.id,
        text: 'DL.AFT01.C47'
      };
    }
  }

  private buildActionsForSavingsContext(
    card: PensionPlanCard,
    policy: TypedSavingsPolicy
  ) {
    if (policy.detaljeMulig) {
      card.event = {
        enabled: true,
        id: policy.id,
        text: 'DL.AFT01.C47'
      };
    } else {
      card.event = {
        enabled: false,
        id: policy.id,
        text: this.contentUtil.getContent(policy.tooltipContentId)
      };
    }
  }

  private buildDetailsForInvestmentContext(
    card: PensionPlanCardBuilder,
    policy: TypedInvesteringPolicy
  ) {
    if (policy.type !== PolicyType.Insurance) {
      this.addSavingsDetails(policy, card);
      this.addCustomerCapitalDetails(policy, card);
    }
  }

  private buildDetailsForCustomerCapitalContext(
    card: PensionPlanCardBuilder,
    policy: TypedCustomerCapitalPolicy
  ) {
    if (!policy.udbetaltIAar && policy.forrentningSidsteAar) {
      this.addExpectedReturnLastYearDetails(policy, card);
    } else if (policy.udbetaltIAar && !policy.forrentningSidsteAar) {
      this.addInterestPaidLastYearDetails(policy, card);
    }
    if (policy.type !== PolicyType.Insurance) {
      this.addCustomerCapitalDetails(policy, card);
    }
  }

  private buildDetailsForDepositContext(
    card: PensionPlanCardBuilder,
    policy: TypedDepositPolicy
  ) {
    if (policy.aftaltIndbetalingForAar !== undefined) {
      this.addDepositedYearDetails(policy, card);
    }
    if (policy.opsparing !== undefined) {
      this.addDepositedSavingsDetails(policy, card);
    }
  }

  private buildDetailsForSavingsContext(
    card: PensionPlanCardBuilder,
    policy: TypedSavingsPolicy
  ) {
    card.patchDetails({
      key: 'DL.AFT01.C11',
      value: policy.afkast,
      unit: 'Global.kroner'
    });

    card.patchDetails({
      key: 'DL.AFT01.C12',
      value: policy.omkostninger,
      unit: 'Global.kroner'
    });
  }

  private buildMainContent(card: PensionPlanCardBuilder, policy: TypedPolicy) {
    card.patchMainContent({
      contentId: policy.id + ' ',
      content: policy.typeTekstId,
      context: 'DL.AFT01.C01'
    });

    if (policy.context === PolicyContext.Investment && policy.fejlet) {
      card.patchMainContent({
        text: 'DL.AFT01.C35'
      });
    } else if (policy.type === PolicyType.Insurance) {
      if (policy.context === PolicyContext.Investment) {
        card.patchMainContent({
          text: 'DL.AFT01.C36'
        });
      }
    }
    if (policy.context === PolicyContext.CustomerCapital) {
      card.patchMainContent({
        text: policy.tooltipContentId
      });
    }
  }

  private buildAdditionalContentForInvestmentContext(
    card: PensionPlanCardBuilder,
    policy: InvesteringPolice
  ) {
    card.patchAdditionalContent({
      context: 'DL.AFT01.C18',
      content: []
    });

    if (policy.fejlet) {
      card.patchAdditionalContent({
        content: [
          {
            text: 'DL.AFT01.C07'
          }
        ]
      });
    } else if (policy.type === PolicyType.Market) {
      if (policy.duInvesterer.harDuInvesterer) {
        card.patchAdditionalContent({
          content: [{ text: 'DL.AFT01.C26' }]
        });
      }

      if (
        policy.pfaValgfri.harPfaValgfri &&
        !this.youInvestService.check100YouInvest(policy)
      ) {
        card.patchAdditionalContent({
          content: [{ text: 'DL.INV.C377' }]
        });
      }

      if (policy.pfaInvesterer?.harPfaInvesterer) {
        const profiler = this.getPfaInvestererProfiler(policy);
        const withUdbetalingsSikring =
          this.getPfaInvestererUdbetalingsSikring(policy);
        this.addPfaInvestererConcept(card, withUdbetalingsSikring, profiler);
      }
    } else if (policy.type === PolicyType.Average) {
      card.patchAdditionalContent({
        content: [{ text: this.contentUtil.getContent('DL.AFT01.C24') }]
      });
    }
  }

  private buildAdditionalContentForCustomerCapitalContext(
    card: PensionPlanCardBuilder,
    policy: CustomerCapitalPolice
  ) {
    const hasValue =
      policy.type !== PolicyType.Insurance &&
      (policy.optjentIAar > 0 ||
        (policy.optjentIAar === 0 && policy.harKundekapital));
    card.patchAdditionalContent({
      context: 'DL.AFT01.C53',
      content: [
        {
          text: hasValue
            ? `${formatNumber(
                Math.round(policy.optjentIAar),
                'da-DK'
              )} ${this.contentUtil.getContent('Global.kroner')}`
            : '-'
        }
      ]
    });
  }

  private buildAdditionalContentForDepositContext(
    card: PensionPlanCardBuilder,
    policy: IndbetalingOversigtPolice
  ) {
    const hasValue = policy.totalIndbetalingForAar > 0;
    card.patchAdditionalContent({
      context: 'DL.AFT01.C17',
      content: [
        {
          text: hasValue
            ? `${formatNumber(
                Math.round(policy.totalIndbetalingForAar),
                'da-DK'
              )} ${this.contentUtil.getContent('Global.kroner')}`
            : '-'
        }
      ]
    });
  }

  private buildAdditionalContentForSavingsContext(
    card: PensionPlanCardBuilder,
    policy: OpsparingPolice
  ) {
    const hasValue = policy.opsparing !== null;
    card.patchAdditionalContent({
      context: 'DL.AFT01.C03',
      content: [
        {
          text: hasValue
            ? `${formatNumber(
                Math.round(policy.opsparing),
                'da-DK'
              )} ${this.contentUtil.getContent('Global.kroner')}`
            : this.contentUtil.getContent('DL.AFT01.C07')
        }
      ]
    });
  }

  /**
   * Adds the Pension savings to the details-part on the pensionplancard.
   * @param policy
   * @param pensionPlanCard
   */
  private addSavingsDetails(
    policy: TypedInvesteringPolicy,
    pensionPlanCard: PensionPlanCardBuilder
  ) {
    pensionPlanCard.patchDetails({
      key: 'DL.AFT01.C03',
      value: policy.opsparing,
      unit: 'Global.kroner'
    });
  }

  private addExpectedReturnLastYearDetails(
    policy: TypedCustomerCapitalPolicy,
    pensionPlanCard: PensionPlanCardBuilder
  ) {
    pensionPlanCard.patchDetails({
      key: 'DL.AFT01.C52',
      value: policy.forrentningSidsteAar,
      unit: 'Global.kroner'
    });
  }

  private addInterestPaidLastYearDetails(
    policy: TypedCustomerCapitalPolicy,
    pensionPlanCard: PensionPlanCardBuilder
  ) {
    pensionPlanCard.patchDetails({
      key: 'DL.AFT01.C51',
      keyParam: moment().subtract(1, 'years').format('YYYY'),
      value: policy.udbetaltIAar,
      unit: 'Global.kroner'
    });
  }

  /**
   * Adds the savings in customer capital to the details-part on the pensionplancard.
   * @param policy
   * @param pensionPlanCard
   */
  private addCustomerCapitalDetails(
    policy: TypedPolicy,
    pensionPlanCard: PensionPlanCardBuilder
  ) {
    let value;
    if (policy.context === PolicyContext.Investment) {
      value = policy.kundekapitalBeloeb;
    } else if (policy.context === PolicyContext.CustomerCapital) {
      if (policy.type !== PolicyType.Insurance) {
        value = policy.kundeKapitalKronerUltimoOpsparing;
      } else {
        value = '-';
      }
    }
    pensionPlanCard.patchDetails({
      key: 'DL.AFT01.C20',
      value,
      unit: 'Global.kroner'
    });
  }

  /**
   * Adds the deposited amount for year in deposit overview to the details-part on the pensionplancard.
   * @param policy
   * @param pensionPlanCard
   */
  private addDepositedYearDetails(
    policy: TypedDepositPolicy,
    pensionPlanCard: PensionPlanCardBuilder
  ) {
    pensionPlanCard.patchDetails({
      key: 'DL.AFT01.C16',
      value: policy.aftaltIndbetalingForAar,
      unit: 'Global.kroner'
    });
  }

  /**
   * Adds the total savings for year in deposit overview to the details-part on the pensionplancard.
   * @param policy
   * @param pensionPlanCard
   */
  private addDepositedSavingsDetails(
    policy: TypedDepositPolicy,
    pensionPlanCard: PensionPlanCardBuilder
  ) {
    pensionPlanCard.patchDetails({
      key: 'DL.AFT01.C03',
      value: policy.opsparing,
      unit: 'Global.kroner'
    });
  }

  /**
   * Utility-function for splitting up profiles.
   * @param policy
   */
  private getPfaInvestererFrieOgAlleKilder(
    policy: InvesteringPolice
  ): [Profil[], Profil[]] {
    const profilerAlleKilder: Profil[] = [];
    const profilerFrieKilder: Profil[] = [];

    policy.pfaInvesterer?.profiler.forEach(profil => {
      if (profil.id === 'G') {
        //skip
      } else if (profil.kilde === 'Alle') {
        profilerAlleKilder.push(profil);
      } else {
        profilerFrieKilder.push(profil);
      }
    });

    return [profilerFrieKilder, profilerAlleKilder];
  }

  /**
   * Loops through all possible profiles, finds one chosen by the customer, and returns the profile-Ids.
   * @param policy
   */
  private getPfaInvestererProfiler(policy: InvesteringPolice): string[] {
    const pfaInvestererFrieOgAlleProfiler =
      this.getPfaInvestererFrieOgAlleKilder(policy);
    const profilerFrieKilder: Profil[] = pfaInvestererFrieOgAlleProfiler[0];
    const profilerAlleKilder: Profil[] = pfaInvestererFrieOgAlleProfiler[1];
    const profileIds: string[] = [];
    this.addSelectedProfile(profilerFrieKilder, profileIds);
    this.addSelectedProfile(profilerAlleKilder, profileIds);
    return profileIds;
  }

  private addSelectedProfile(profiles: Profil[], profileIds: string[]) {
    const found: Profil = profiles.find(profile => profile.valgt);
    if (found) {
      profileIds.push(found.id);
    }
  }

  /**
   * Loops through all possible profiles, finds the chosen one and returns whether it has Udbetalingssikring.
   * @param policy
   */
  private getPfaInvestererUdbetalingsSikring(
    policy: InvesteringPolice
  ): boolean {
    const pfaInvestererFrieOgAlleProfiler =
      this.getPfaInvestererFrieOgAlleKilder(policy);
    const profilerAlleKilder: Profil[] = pfaInvestererFrieOgAlleProfiler[1];

    return profilerAlleKilder.find(profile => profile.valgt)
      ?.udbetalingssikring;
  }

  /**
   * Adds "Pfa investerer" investmentconcept, as part of the additional content on the pensionplancard.
   * @param pensionPlanCard
   * @param withPaymentSecurity
   * @param profiler should be an array of single letters (eg. 'A', 'B', C'. 'D')
   */
  private addPfaInvestererConcept(
    pensionPlanCard: PensionPlanCardBuilder,
    withPaymentSecurity: boolean,
    profiler: string[]
  ) {
    const profilesAsSpaceSeparatedList = profiler.join(' + ');

    const conceptAndProfile = this.contentUtil.getContent(
      'DL.AFT01.C25',
      profilesAsSpaceSeparatedList
    );

    if (withPaymentSecurity) {
      pensionPlanCard.patchAdditionalContent({
        content: [
          {
            text: conceptAndProfile,
            subText: 'DL.AFT01.C44'
          }
        ]
      });
    } else {
      pensionPlanCard.patchAdditionalContent({
        content: [{ text: conceptAndProfile }]
      });
    }
  }
}

class PensionPlanCardBuilder implements PensionPlanCard {
  main: PensionPlanCardMainContent;
  additional?: PensionPlanCardAdditionalContent;
  showDetailsText?: string;
  hideDetailsText?: string;
  details?: PensionPlanCardDetails[];
  event?: PensionPlanCardEvent;

  /**
   * updates main properties
   * @param mainContent
   */
  patchMainContent(mainContent: Partial<PensionPlanCardMainContent>) {
    this.main = { ...this.main, ...mainContent };
  }

  /**
   * updates details
   * @param details
   */
  patchDetails(details: PensionPlanCardDetails) {
    this.details = [...(this.details || []), details];
  }

  /**
   * updates additional properties, content is concatenated instead of overwritten
   * @param additionalContent
   */
  patchAdditionalContent(
    additionalContent: Partial<PensionPlanCardAdditionalContent>
  ) {
    this.additional = {
      ...this.additional,
      ...additionalContent,
      content: [
        ...(this.additional?.content || []),
        ...(additionalContent.content || [])
      ]
    };
  }
}
