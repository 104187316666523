<div class="row spacing-unit--double-over">
  <!-- PFA Housing -->
  <div class="col-12 col-md-6">
    <co-card
      (click)="goToTrackingUrl('PFA Housing', '/pfaboliger')"
      class="background-cloud"
    >
      <div class="row align-items-center">
        <div class="col-3 col-md-4">
          <co-icon-advantage-housing
            class="illustration"
          ></co-icon-advantage-housing>
        </div>

        <div class="col-9 col-md-8">
          <div
            class="color--primary text--bold"
            [coContent]="'DL.MN01.C48'"
          ></div>
          <div
            class="color--black text--manchet text--bold"
            [coContent]="'DL.FS02.C28'"
          ></div>
          <div [coContent]="'DL.FS02.C83'"></div>
        </div>
      </div>
    </co-card>
  </div>

  <!-- LB Insurance -->
  <div *ngIf="showAdvantageLBInsurance" class="col-12 col-md-6">
    <co-card
      (click)="goToTrackingUrl('Insurance partner', '/forsikringspartner')"
      class="background-cloud"
    >
      <div class="row align-items-center">
        <div class="col-3 col-md-4">
          <co-icon-advantage-lb-insurance
            class="illustration"
          ></co-icon-advantage-lb-insurance>
        </div>

        <div class="col-9 col-md-8">
          <div
            class="color--primary text--bold"
            [coContent]="'DL.MN01.C74'"
          ></div>
          <div
            class="color--black text--manchet text--bold"
            [coContent]="'DL.FS02.C172'"
          ></div>
          <div [coContent]="'DL.FS02.C173'"></div>
        </div>
      </div>
    </co-card>
  </div>
</div>
