/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const DaekningsTypeSchema = JoiObj.string().valid(
        'KAPITALPENSION'
        ,
        'KAPITALPENSION_UNMARRIED'
        ,
        'ALDERSOPSPARING'
        ,
        'SELVPENSIONERING'
        ,
        'RATEPENSION'
        ,
        'RATEPENSION_UNMARRIED'
        ,
        'LIVSVARIG_LIVSPENSION'
        ,
        'LIVSVARIG_LIVSPENSION_UNMARRIED'
        ,
        'TIDSBEGRÆNSET_LIVSPENSION'
        ,
        'OPHOERENDELIVSPENSION'
        ,
        'PENSION_IKKE_GODKENDT'
        ,
        'ENGANGSBELOEB_OEVRIGE'
        ,
        'PROGNOSE_KAN_IKKE_BEREGNES'
        ,
        'INVALIDEPENSION'
        ,
        'INVALIDESUM'
        ,
        'BOERNEPENSION_VED_INVALIDITETPENSION'
        ,
        'BOERNEPENSION_VED_INVALIDITETPENSION_MED_RISIKOOPHOER'
        ,
        'HELBRED_IKKE_GODKENDT'
        ,
        'INDBETALINGSSIKRING'
        ,
        'BANKPENSION'
        ,
        'HELBREDSSIKRING'
        ,
        'HELBREDSSIKRING_BOERN'
        ,
        'HELBREDSSIKRING_IKKE_GODKENDT'
        ,
        'PFAHELBREDSSIKRING'
        ,
        'PFAHELBREDSSIKRING_BOERN'
        ,
        'PFAHELBREDSSIKRING_MODUL'
        ,
        'PFADIAGNOSE'
        ,
        'PFAFOREBYGGER'
        ,
        'KRITISKSYGDOM'
        ,
        'KRITISKSYGDOM_BOERN'
        ,
        'KRITISKSYGDOM_BOERN_PI'
        ,
        'KRITISKSYGDOM_IKKE_GODKENDT'
        ,
        'BOERNEPENSION'
        ,
        'BOERNEPENSION_MED_RISIKOOPHOER'
        ,
        'BOERNEPENSION_TIL_FORAELDRELOESE'
        ,
        'ENGANGBELOEB_TIL_UGIFTE'
        ,
        'ENGANGSBELOEB_TIL_UGIFTE_RATER'
        ,
        'ENGANGSBELOEB'
        ,
        'ENGANGSBELOEB_BOERN'
        ,
        'ENGANGSBELOEB_BOERN_MED_RISIKOOPHOER'
        ,
        'ENGANGSBELOEB_UDENRISIKOOPHOER'
        ,
        'ENGANGSBELOEB_FORSIKRING_MANUEL_KORREKTION'
        ,
        'GARANTI_FOR_UDBETALING'
        ,
        'OPSAT_LIVSVARIG_AEGTEFAELLEPENSION'
        ,
        'OPSAT_OPHOERENDE_AEGTEFAELLEPENSION'
        ,
        'AEGTEFAELLEPENSION_OPHOERENDE_RISIKO'
        ,
        'GARANTI_FOR_LAENGSTE_LIV_SK1'
        ,
        'GARANTI_FOR_LAENGSTE_LIV_SK9'
        ,
        'GARANTI_FOR_LIV_RATEPENSION'
        ,
        'LIVSVARIG_AEGTEFAELLLEPENSION'
        ,
        'OPHOERENDE_AEGTEFAELLEPENSION'
        ,
        'ARVERENTE'
        ,
        'LIV_RATEPENSION'
        ,
        'LIVFORSIKRING_IKKE_GODKENDT'
        ,
        'FORSIKRING_IKKE_GODKENDT'
        ,
        'DEPOTSIKRING'
        ,
        'LIVSVARIG_AEGTEFAELLE_UNITLINK'
        ,
        'LIVSVARIG_AEGTEFAELLE_FORSIKRING'
        ,
        'OPHOERENDE_AEGTEFAELLE_UNITLINK'
        ,
        'OPHOERENDE_AEGTEFAELLE_FORSIKRING'
        ,
        'RESOURCEFORLOEB'
        ,
        'EARLY_CARE'
        ,
        'GIPP_KUNDE_KAPITAL'
        
    )
.id('DaekningsTypeSchema')

export type DaekningsType = 'KAPITALPENSION' | 'KAPITALPENSION_UNMARRIED' | 'ALDERSOPSPARING' | 'SELVPENSIONERING' | 'RATEPENSION' | 'RATEPENSION_UNMARRIED' | 'LIVSVARIG_LIVSPENSION' | 'LIVSVARIG_LIVSPENSION_UNMARRIED' | 'TIDSBEGRÆNSET_LIVSPENSION' | 'OPHOERENDELIVSPENSION' | 'PENSION_IKKE_GODKENDT' | 'ENGANGSBELOEB_OEVRIGE' | 'PROGNOSE_KAN_IKKE_BEREGNES' | 'INVALIDEPENSION' | 'INVALIDESUM' | 'BOERNEPENSION_VED_INVALIDITETPENSION' | 'BOERNEPENSION_VED_INVALIDITETPENSION_MED_RISIKOOPHOER' | 'HELBRED_IKKE_GODKENDT' | 'INDBETALINGSSIKRING' | 'BANKPENSION' | 'HELBREDSSIKRING' | 'HELBREDSSIKRING_BOERN' | 'HELBREDSSIKRING_IKKE_GODKENDT' | 'PFAHELBREDSSIKRING' | 'PFAHELBREDSSIKRING_BOERN' | 'PFAHELBREDSSIKRING_MODUL' | 'PFADIAGNOSE' | 'PFAFOREBYGGER' | 'KRITISKSYGDOM' | 'KRITISKSYGDOM_BOERN' | 'KRITISKSYGDOM_BOERN_PI' | 'KRITISKSYGDOM_IKKE_GODKENDT' | 'BOERNEPENSION' | 'BOERNEPENSION_MED_RISIKOOPHOER' | 'BOERNEPENSION_TIL_FORAELDRELOESE' | 'ENGANGBELOEB_TIL_UGIFTE' | 'ENGANGSBELOEB_TIL_UGIFTE_RATER' | 'ENGANGSBELOEB' | 'ENGANGSBELOEB_BOERN' | 'ENGANGSBELOEB_BOERN_MED_RISIKOOPHOER' | 'ENGANGSBELOEB_UDENRISIKOOPHOER' | 'ENGANGSBELOEB_FORSIKRING_MANUEL_KORREKTION' | 'GARANTI_FOR_UDBETALING' | 'OPSAT_LIVSVARIG_AEGTEFAELLEPENSION' | 'OPSAT_OPHOERENDE_AEGTEFAELLEPENSION' | 'AEGTEFAELLEPENSION_OPHOERENDE_RISIKO' | 'GARANTI_FOR_LAENGSTE_LIV_SK1' | 'GARANTI_FOR_LAENGSTE_LIV_SK9' | 'GARANTI_FOR_LIV_RATEPENSION' | 'LIVSVARIG_AEGTEFAELLLEPENSION' | 'OPHOERENDE_AEGTEFAELLEPENSION' | 'ARVERENTE' | 'LIV_RATEPENSION' | 'LIVFORSIKRING_IKKE_GODKENDT' | 'FORSIKRING_IKKE_GODKENDT' | 'DEPOTSIKRING' | 'LIVSVARIG_AEGTEFAELLE_UNITLINK' | 'LIVSVARIG_AEGTEFAELLE_FORSIKRING' | 'OPHOERENDE_AEGTEFAELLE_UNITLINK' | 'OPHOERENDE_AEGTEFAELLE_FORSIKRING' | 'RESOURCEFORLOEB' | 'EARLY_CARE' | 'GIPP_KUNDE_KAPITAL';

export const DaekningsType = {
    Kapitalpension: 'KAPITALPENSION' as DaekningsType,
    KapitalpensionUnmarried: 'KAPITALPENSION_UNMARRIED' as DaekningsType,
    Aldersopsparing: 'ALDERSOPSPARING' as DaekningsType,
    Selvpensionering: 'SELVPENSIONERING' as DaekningsType,
    Ratepension: 'RATEPENSION' as DaekningsType,
    RatepensionUnmarried: 'RATEPENSION_UNMARRIED' as DaekningsType,
    LivsvarigLivspension: 'LIVSVARIG_LIVSPENSION' as DaekningsType,
    LivsvarigLivspensionUnmarried: 'LIVSVARIG_LIVSPENSION_UNMARRIED' as DaekningsType,
    TidsbegrnsetLivspension: 'TIDSBEGRÆNSET_LIVSPENSION' as DaekningsType,
    Ophoerendelivspension: 'OPHOERENDELIVSPENSION' as DaekningsType,
    PensionIkkeGodkendt: 'PENSION_IKKE_GODKENDT' as DaekningsType,
    EngangsbeloebOevrige: 'ENGANGSBELOEB_OEVRIGE' as DaekningsType,
    PrognoseKanIkkeBeregnes: 'PROGNOSE_KAN_IKKE_BEREGNES' as DaekningsType,
    Invalidepension: 'INVALIDEPENSION' as DaekningsType,
    Invalidesum: 'INVALIDESUM' as DaekningsType,
    BoernepensionVedInvaliditetpension: 'BOERNEPENSION_VED_INVALIDITETPENSION' as DaekningsType,
    BoernepensionVedInvaliditetpensionMedRisikoophoer: 'BOERNEPENSION_VED_INVALIDITETPENSION_MED_RISIKOOPHOER' as DaekningsType,
    HelbredIkkeGodkendt: 'HELBRED_IKKE_GODKENDT' as DaekningsType,
    Indbetalingssikring: 'INDBETALINGSSIKRING' as DaekningsType,
    Bankpension: 'BANKPENSION' as DaekningsType,
    Helbredssikring: 'HELBREDSSIKRING' as DaekningsType,
    HelbredssikringBoern: 'HELBREDSSIKRING_BOERN' as DaekningsType,
    HelbredssikringIkkeGodkendt: 'HELBREDSSIKRING_IKKE_GODKENDT' as DaekningsType,
    Pfahelbredssikring: 'PFAHELBREDSSIKRING' as DaekningsType,
    PfahelbredssikringBoern: 'PFAHELBREDSSIKRING_BOERN' as DaekningsType,
    PfahelbredssikringModul: 'PFAHELBREDSSIKRING_MODUL' as DaekningsType,
    Pfadiagnose: 'PFADIAGNOSE' as DaekningsType,
    Pfaforebygger: 'PFAFOREBYGGER' as DaekningsType,
    Kritisksygdom: 'KRITISKSYGDOM' as DaekningsType,
    KritisksygdomBoern: 'KRITISKSYGDOM_BOERN' as DaekningsType,
    KritisksygdomBoernPi: 'KRITISKSYGDOM_BOERN_PI' as DaekningsType,
    KritisksygdomIkkeGodkendt: 'KRITISKSYGDOM_IKKE_GODKENDT' as DaekningsType,
    Boernepension: 'BOERNEPENSION' as DaekningsType,
    BoernepensionMedRisikoophoer: 'BOERNEPENSION_MED_RISIKOOPHOER' as DaekningsType,
    BoernepensionTilForaeldreloese: 'BOERNEPENSION_TIL_FORAELDRELOESE' as DaekningsType,
    EngangbeloebTilUgifte: 'ENGANGBELOEB_TIL_UGIFTE' as DaekningsType,
    EngangsbeloebTilUgifteRater: 'ENGANGSBELOEB_TIL_UGIFTE_RATER' as DaekningsType,
    Engangsbeloeb: 'ENGANGSBELOEB' as DaekningsType,
    EngangsbeloebBoern: 'ENGANGSBELOEB_BOERN' as DaekningsType,
    EngangsbeloebBoernMedRisikoophoer: 'ENGANGSBELOEB_BOERN_MED_RISIKOOPHOER' as DaekningsType,
    EngangsbeloebUdenrisikoophoer: 'ENGANGSBELOEB_UDENRISIKOOPHOER' as DaekningsType,
    EngangsbeloebForsikringManuelKorrektion: 'ENGANGSBELOEB_FORSIKRING_MANUEL_KORREKTION' as DaekningsType,
    GarantiForUdbetaling: 'GARANTI_FOR_UDBETALING' as DaekningsType,
    OpsatLivsvarigAegtefaellepension: 'OPSAT_LIVSVARIG_AEGTEFAELLEPENSION' as DaekningsType,
    OpsatOphoerendeAegtefaellepension: 'OPSAT_OPHOERENDE_AEGTEFAELLEPENSION' as DaekningsType,
    AegtefaellepensionOphoerendeRisiko: 'AEGTEFAELLEPENSION_OPHOERENDE_RISIKO' as DaekningsType,
    GarantiForLaengsteLivSk1: 'GARANTI_FOR_LAENGSTE_LIV_SK1' as DaekningsType,
    GarantiForLaengsteLivSk9: 'GARANTI_FOR_LAENGSTE_LIV_SK9' as DaekningsType,
    GarantiForLivRatepension: 'GARANTI_FOR_LIV_RATEPENSION' as DaekningsType,
    LivsvarigAegtefaelllepension: 'LIVSVARIG_AEGTEFAELLLEPENSION' as DaekningsType,
    OphoerendeAegtefaellepension: 'OPHOERENDE_AEGTEFAELLEPENSION' as DaekningsType,
    Arverente: 'ARVERENTE' as DaekningsType,
    LivRatepension: 'LIV_RATEPENSION' as DaekningsType,
    LivforsikringIkkeGodkendt: 'LIVFORSIKRING_IKKE_GODKENDT' as DaekningsType,
    ForsikringIkkeGodkendt: 'FORSIKRING_IKKE_GODKENDT' as DaekningsType,
    Depotsikring: 'DEPOTSIKRING' as DaekningsType,
    LivsvarigAegtefaelleUnitlink: 'LIVSVARIG_AEGTEFAELLE_UNITLINK' as DaekningsType,
    LivsvarigAegtefaelleForsikring: 'LIVSVARIG_AEGTEFAELLE_FORSIKRING' as DaekningsType,
    OphoerendeAegtefaelleUnitlink: 'OPHOERENDE_AEGTEFAELLE_UNITLINK' as DaekningsType,
    OphoerendeAegtefaelleForsikring: 'OPHOERENDE_AEGTEFAELLE_FORSIKRING' as DaekningsType,
    Resourceforloeb: 'RESOURCEFORLOEB' as DaekningsType,
    EarlyCare: 'EARLY_CARE' as DaekningsType,
    GippKundeKapital: 'GIPP_KUNDE_KAPITAL' as DaekningsType
};

