import { HentHandelslisteSvar, TradingStatus } from '@pfa/gen';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Cacheable } from 'ts-cacheable';
import { GlobalShouldCacheDecider } from '../cache/GlobalShouldCacheDecider';

@Injectable({
  providedIn: 'root'
})
export class TradeListApiService {
  constructor(private http: HttpClient) {}

  @Cacheable({
    shouldCacheDecider: GlobalShouldCacheDecider
  })
  getTradeList(policyId): Observable<HentHandelslisteSvar> {
    const url = `/ds/api/investering/handelsliste/${policyId}`;
    return this.http.get<HentHandelslisteSvar>(url);
  }

  getTradingStatus(): Observable<TradingStatus> {
    return this.http.get<TradingStatus>(`/ds/api/investering/tradingstatus`);
  }
}
