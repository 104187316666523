<div class="limit-screen-width">
  <mitpfa-advantage-header
    title="DL.MF.C28"
    subTitle="DL.MF.C29"
    text="DL.MF.C30"
    imgUrl="https://pfa.dk/-/media/mitpfa-banner/fordelssider_raadgivning800x439.jpg"
  >
  </mitpfa-advantage-header>
</div>

<div class="contacts-section" *ngIf="advantagesData?.kontakter.length > 0">
  <div class="limit-screen-width">
    <div class="row contact-cards">
      <mitpfa-contact-card
        *ngFor="let contact of advantagesData?.kontakter"
        [contactDetails]="contact"
        class="col-lg-6"
      ></mitpfa-contact-card>
    </div>
  </div>
</div>
