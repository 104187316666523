import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  UrlTree,
  RouterStateSnapshot
} from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SavingsStore } from '@pfa/gen';

@Injectable()
export class SavingsGuard implements CanActivate {
  constructor(
    private readonly router: Router,
    private readonly savingsStore: SavingsStore
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    return this.savingsStore.opsparingGet().pipe(
      map(data => {
        if (data.policer.length === 1) {
          if (data.policer[0].detaljeMulig === false) {
            return true;
          } else {
            return this.router.parseUrl('minopsparing/' + data.policer[0].id);
          }
        } else {
          return true;
        }
      })
    );
  }
}
