export class ConceptDetailsExpanded {
  pfaInvest: boolean;
  averageRate: boolean;
  customerCapital: boolean;
}

export enum CONCEPT_DETAILS_TYPE {
  PFA_INVEST,
  AVERAGE_RATE,
  CUSTOMER_CAPITAL
}
