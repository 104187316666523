/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ServiceInfo } from './serviceInfo';
import { ServiceInfoSchema } from './serviceInfo';
import { EjendomArt } from './ejendomArt';
import { EjendomArtSchema } from './ejendomArt';
import { ResourceModel } from './resourceModel';
import { ResourceModelSchema } from './resourceModel';
import * as JoiObj from "joi";


    export const WealthResidenceSchema = JoiObj.object({
        serviceInfo:
            
        JoiObj.link('#ServiceInfoSchema')

.allow(null)



        ,
        debt:
            
                    JoiObj.number()
                


.required()


        ,
        name:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        ownerShare:
            
                    JoiObj.number()
                


.required()


        ,
        residenceType:
            
        JoiObj.link('#EjendomArtSchema')


.required()


        ,
        salesValue:
            
                    JoiObj.number()
                


.required()


        ,
        totalShareValue:
            
                    JoiObj.number()
                


.required()


        ,
        totalValue:
            
                    JoiObj.number()
                


.required()


        
    })
                .shared(ServiceInfoSchema)
                .shared(EjendomArtSchema)
.id('WealthResidenceSchema')

export interface WealthResidence extends ResourceModel { 
    debt: number;
    name: string;
    ownerShare: number;
    residenceType: EjendomArt;
    salesValue: number;
    totalShareValue: number;
    totalValue: number;
}
export namespace WealthResidence {
}


