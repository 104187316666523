<!-- v13 -->
<svg
  id="Layer_1"
  data-name="Layer 1"
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 32 32"
>
  <g id="Canvas">
    <circle
      id="Large_circle"
      data-name="Large circle"
      cx="16"
      cy="16"
      r="16"
      fill="none"
    />
    <circle
      id="Small_circle"
      data-name="Small circle"
      cx="16"
      cy="16"
      r="12"
      fill="none"
    />
  </g>
  <g id="Icon">
    <line
      id="Dash"
      x1="17"
      y1="23.75"
      x2="18"
      y2="23.75"
      stroke-width="1.2"
      stroke="#000"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
    />
    <path
      id="Body"
      d="M20,23.75h1a1,1,0,0,0,1-1v-3a2,2,0,0,0-2-2h-.5c-1.5,0-2,.5-3.5.5s-2-.5-3.5-.5H12a2,2,0,0,0-2,2v3a1,1,0,0,0,1,1h4"
      fill="none"
      stroke="#000"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.2"
    />
    <path
      id="Neckline"
      d="M17.5,20.06a6.09,6.09,0,0,1-3,0"
      fill="none"
      stroke="#000"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.2"
    />
    <path
      id="Head"
      d="M16,7.25h0a3.5,3.5,0,0,1,3.5,3.5v2a3.5,3.5,0,0,1-3.5,3.5h0a3.5,3.5,0,0,1-3.5-3.5v-2A3.5,3.5,0,0,1,16,7.25Z"
      fill="none"
      stroke="#000"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.2"
    />
  </g>
</svg>
