import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { OpsparingPoliceDetaljer, SavingsStore } from '@pfa/gen';

@Injectable({
  providedIn: 'root'
})
export class SavingsPolicyCurrentyearResolver
  implements Resolve<OpsparingPoliceDetaljer>
{
  constructor(private readonly savingsStore: SavingsStore) {}

  resolve(
    route: ActivatedRouteSnapshot
  ):
    | OpsparingPoliceDetaljer
    | Observable<OpsparingPoliceDetaljer>
    | Promise<OpsparingPoliceDetaljer> {
    return this.savingsStore.opsparingValgtPolicenummerValgtAarGet(
      route.params.policyId,
      new Date().getFullYear()
    );
  }
}
