/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ServiceInfo } from './serviceInfo';
import { ServiceInfoSchema } from './serviceInfo';
import { LoginMethod } from './loginMethod';
import { LoginMethodSchema } from './loginMethod';
import { ResourceModel } from './resourceModel';
import { ResourceModelSchema } from './resourceModel';
import { SystemType } from './systemType';
import { SystemTypeSchema } from './systemType';
import { CustomerActivityRole } from './customerActivityRole';
import { CustomerActivityRoleSchema } from './customerActivityRole';
import * as JoiObj from "joi";


    export const CustomerActivitySchema = JoiObj.object({
        serviceInfo:
            
        JoiObj.link('#ServiceInfoSchema')

.allow(null)



        ,
        userSessionId:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        accessTime:
            
                    JoiObj.object()
                


.required()


        ,
        loginMethod:
            
        JoiObj.link('#LoginMethodSchema')


.required()


        ,
        customerId:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        username:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        rekvnr:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        roles:
                JoiObj.array().items(
        
        JoiObj.link('#CustomerActivityRoleSchema')

        )
        

.required()


        ,
        remoteAddress:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        system:
            
        JoiObj.link('#SystemTypeSchema')


.required()


        
    })
                .shared(ServiceInfoSchema)
                .shared(LoginMethodSchema)
                .shared(CustomerActivityRoleSchema)
                .shared(SystemTypeSchema)
.id('CustomerActivitySchema')

export interface CustomerActivity extends ResourceModel { 
    userSessionId: string;
    accessTime: object;
    loginMethod: LoginMethod;
    customerId: string;
    username: string;
    rekvnr: string;
    roles: Array<CustomerActivityRole>;
    remoteAddress: string;
    system: SystemType;
}
export namespace CustomerActivity {
}


