/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BeneficiaryBoolean } from './beneficiaryBoolean';
import { BeneficiaryBooleanSchema } from './beneficiaryBoolean';
import * as JoiObj from "joi";


    export const BeneficiaryPolicyRuleSchema = JoiObj.object({
        beneficiaryAllowed:
            
        JoiObj.link('#BeneficiaryBooleanSchema')


.required()


        ,
        id:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        jointLife:
            
                    JoiObj.boolean()
                


.required()


        ,
        pawned:
            
                    JoiObj.boolean()
                


.required()


        ,
        policyStatusKnown:
            
                    JoiObj.boolean()
                


.required()


        ,
        eligibleDepositSecurityCollective:
            
                    JoiObj.boolean()
                


.required()


        
    })
                .shared(BeneficiaryBooleanSchema)
.id('BeneficiaryPolicyRuleSchema')

export interface BeneficiaryPolicyRule { 
    beneficiaryAllowed: BeneficiaryBoolean;
    id: string;
    jointLife: boolean;
    pawned: boolean;
    policyStatusKnown: boolean;
    eligibleDepositSecurityCollective: boolean;
}
export namespace BeneficiaryPolicyRule {
}


