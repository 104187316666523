<div mat-dialog-title></div>

<mat-dialog-content>
  <p [coContent]="'DL.TR01.C142'"></p>
  <p [coContent]="'DL.TR01.C143'"></p>
  <p [coContent]="'DL.TR01.C144'"></p>
</mat-dialog-content>

<mat-dialog-actions class="justify-content-end">
  <button
    mat-raised-button
    (click)="dialogRef.close('ok')"
    [coContent]="'DL.TR01.C136'"
  ></button>
</mat-dialog-actions>
