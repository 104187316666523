import { Injectable } from '@angular/core';
import { TrackingService } from './tracking.service';

type FormAction = 'started' | 'completed';

@Injectable()
export class FormTrackingService extends TrackingService {
  trackFormStart(formName: string): void {
    this.trackForm(formName, 'started');
  }

  trackFormComplete(formName: string): void {
    this.trackForm(formName, 'completed');
  }

  private trackForm(formName: string, formAction: FormAction): void {
    const options = { formName, formAction };
    this.dispatchCustomEventTracking('track-form', options);
  }
}
