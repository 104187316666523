import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { RecommendationDetail } from '../investment-recommendation.model';
import { BasketApiService, InvestmentApiService } from '@pfa/api';
import { combineLatest, Observable, switchMap } from 'rxjs';
import {
  GlobalWorkingService,
  NotificationService,
  NotificationType
} from '@pfaform';
import {
  ContentService,
  GuidePlacement,
  GuideTrackingService,
  OrderItem,
  OrderTrackingService,
  TrackingName
} from '@pfa/core';
import { Router } from '@angular/router';
import { GodkendSvar, InvesteringPolice, SimulatorTileEnum } from '@pfa/gen';
import { SigningService } from '@mitpfa/shared';

@Component({
  selector: 'mitpfa-investment-recommendation-approve',
  templateUrl: './recommendation-approve.component.html',
  styleUrls: [
    './recommendation-approve.component.scss',
    '../investment-recommendation.component.scss'
  ]
})
export class InvestmentRecommendationApproveComponent {
  private readonly investmentApiService: InvestmentApiService =
    inject(InvestmentApiService);
  private readonly basketApiService: BasketApiService =
    inject(BasketApiService);
  private readonly contentService: ContentService = inject(ContentService);
  private readonly globalWorkingService: GlobalWorkingService =
    inject(GlobalWorkingService);
  private readonly notificationService: NotificationService =
    inject(NotificationService);
  private readonly router: Router = inject(Router);
  private readonly guideTrackingService: GuideTrackingService =
    inject(GuideTrackingService);
  private readonly signingService: SigningService = inject(SigningService);
  private readonly orderTrackingService: OrderTrackingService =
    inject(OrderTrackingService);
  private readonly orderName: string;

  @Input() orgPolicy: InvesteringPolice;
  @Input() detail: RecommendationDetail;
  @Input() isProfileChanged: boolean;
  @Input() isClimateChanged: boolean;
  @Input() isOnboardingFlow: boolean;

  @Output() complete = new EventEmitter<boolean>();

  public approveChanges(): void {
    this.globalWorkingService.show();
    this.guideTrackingService.trackGuideCallToAction(
      TrackingName.INVESTMENT_GUIDE,
      'IG guide result approve'
    );

    combineLatest(this.observables())
      .pipe(
        switchMap(() =>
          this.basketApiService.buildContract(
            this.contentService.getLanguage(),
            'INVESTERING'
          )
        )
      )
      .subscribe({
        next: res => {
          if (!res.fejl) {
            this.guideTrackingService.trackGuideRecommendationStep(
              TrackingName.INVESTMENT_GUIDE,
              'IG REC order overview',
              GuidePlacement.INVESTMENT
            );
            if (this.isOnboardingFlow) {
              this.confirm();
            } else {
              this.router.navigate(['/mineinvesteringer/aendringer'], {
                state: {
                  engagementContract: res,
                  orderName: `pfainvest: ${this.orderItemName()}`,
                  orgPolicy: this.orgPolicy,
                  trackingProcessType: TrackingName.INVESTMENT_GUIDE
                }
              });
            }
          } else {
            this.error();
          }

          this.globalWorkingService.hide();
        },
        error: () => {
          this.error();
          this.globalWorkingService.hide();
        }
      });
  }

  private observables(): Observable<unknown>[] {
    const observables: Observable<unknown>[] = [];

    if (this.isProfileChanged && this.detail.recommendation.profile) {
      observables.push(
        this.investmentApiService.calculateInvestmentProfile(
          this.detail.policyId,
          [this.detail.recommendation.profile]
        )
      );
    }

    if (this.isClimateChanged) {
      const percent =
        this.detail.manualClimatePercent === null
          ? this.detail.recommendation.climatePercent
          : this.detail.manualClimatePercent;

      observables.push(
        this.investmentApiService.calculateClimatePlus(this.detail.policyId, {
          andel: percent ?? 0
        })
      );
    }

    return observables;
  }

  private orderItemName(): string {
    const profile = `profile ${this.detail.recommendation.profile?.id}`;
    const climate = `climate ${this.detail.recommendation.climatePercent ?? 0}`;

    if (this.isProfileChanged && !this.isClimateChanged) {
      return profile;
    }

    if (!this.isProfileChanged && this.isClimateChanged) {
      return climate;
    }

    if (this.isProfileChanged && this.isClimateChanged) {
      return profile + `, ${climate}`;
    }

    return '';
  }

  private error(): void {
    this.notificationService.showNotification({
      message: 'DL.MD01.C161',
      type: NotificationType.ERROR
    });
  }

  completeAndExit(): void {
    this.complete.emit();
  }

  getOrderItems(): OrderItem[] {
    return [
      {
        category: 'investment',
        name: this.orderName
      }
    ];
  }

  public confirm(): void {
    this.trackConfirmByFlowType();

    this.signingService
      .performBasketSigning(this.getOrderItems(), SimulatorTileEnum.Investering)
      .subscribe({
        next: godkendSvar => {
          if (!godkendSvar) {
            return;
          }
          this.completeSigning(godkendSvar);
        },
        error: _ => {
          this.signingService.showSigningError();
        }
      });
  }

  private trackConfirmByFlowType(): void {
    this.orderTrackingService.trackApproveOrder(
      this.getOrderItems(),
      'investment'
    );
  }

  public completeSigning(godkendSvar: GodkendSvar): void {
    this.signingService
      .showSigningCompleted(godkendSvar, TrackingName.INVESTMENT_GUIDE)
      .subscribe(_ => {
        this.complete.emit(false);
      });
  }
}
