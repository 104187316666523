/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SignatureMeta } from './signatureMeta';
import { SignatureMetaSchema } from './signatureMeta';
import * as JoiObj from "joi";


    export const AttachedFileSchema = JoiObj.object({
        attachmentGUID:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        documentClassificationCategory:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        documentClassificationType:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        fileContent:
            
                    JoiObj.object()
                


.required()


        ,
        fileName:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        getFileFailed:
            
                    JoiObj.boolean()
                


.required()


        ,
        hash:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        signatureMeta:
            
        JoiObj.link('#SignatureMetaSchema')


.required()


        
    })
                .shared(SignatureMetaSchema)
.id('AttachedFileSchema')

export interface AttachedFile { 
    attachmentGUID: string;
    documentClassificationCategory: string;
    documentClassificationType: string;
    fileContent: object;
    fileName: string;
    getFileFailed: boolean;
    hash: string;
    signatureMeta: SignatureMeta;
}

