/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const BeneficiaryAdvisoryQuestionTypeSchema = JoiObj.string().valid(
        'SPOUSE_100'
        ,
        'RELATIONSHIP'
        ,
        'RELATIONSHIP_2_YEAR'
        ,
        'RELATIONSHIP_CHILDREN'
        ,
        'PARTNER_100'
        ,
        'CHILDREN_100'
        
    )
.id('BeneficiaryAdvisoryQuestionTypeSchema')

export type BeneficiaryAdvisoryQuestionType = 'SPOUSE_100' | 'RELATIONSHIP' | 'RELATIONSHIP_2_YEAR' | 'RELATIONSHIP_CHILDREN' | 'PARTNER_100' | 'CHILDREN_100';

export const BeneficiaryAdvisoryQuestionType = {
    Spouse100: 'SPOUSE_100' as BeneficiaryAdvisoryQuestionType,
    Relationship: 'RELATIONSHIP' as BeneficiaryAdvisoryQuestionType,
    Relationship2Year: 'RELATIONSHIP_2_YEAR' as BeneficiaryAdvisoryQuestionType,
    RelationshipChildren: 'RELATIONSHIP_CHILDREN' as BeneficiaryAdvisoryQuestionType,
    Partner100: 'PARTNER_100' as BeneficiaryAdvisoryQuestionType,
    Children100: 'CHILDREN_100' as BeneficiaryAdvisoryQuestionType
};

