<div class="signing-completed--component">
  <div mat-dialog-title>
    <h3 [coContent]="'Global.advarsel'"></h3>
    <co-icon-times (click)="closePopup(false)"></co-icon-times>
  </div>
  <mat-dialog-content>
    <strong class="text--uppercase" coContent="DL.GO01.C100"></strong>
    <p [coContent]="'DL.GO01.C101'" *ngIf="!godkendSvar.gemkvitteringfejl"></p>
    <p [coContent]="'DL.GO01.C308'" *ngIf="!godkendSvar.byggepdffejl"></p>
    <p [coContent]="'DL.GO01.C107'" *ngIf="godkendSvar.gemkvitteringfejl"></p>
    <p [coContent]="'DL.GO01.C355'" *ngIf="godkendSvar.byggepdffejl"></p>

    <ng-container *ngIf="isHealthQuestionnareGenerated">
      <strong class="text--uppercase" [coContent]="'DL.GO01.C441'"></strong>
      <p [coContent]="'DL.GO01.C442'"></p>
      <p [coContent]="'DL.GO01.C443'"></p>
      <p [coContent]="'DL.GO01.C444'"></p>
    </ng-container>
  </mat-dialog-content>
  <mat-dialog-actions>
    <div
      class="modal-buttons--health-questionnare-generated"
      *ngIf="isHealthQuestionnareGenerated"
    >
      <div>
        <button
          mat-raised-button
          (click)="closePopup(false)"
          [coContent]="'DL.GO01.C445'"
        ></button>
        <div [coContent]="'DL.GO01.C447'" class="spacing-unit--over"></div>
      </div>
      <div class="align--right">
        <button
          mat-raised-button
          [disabled]="isAdvisor"
          (click)="closePopup(true)"
          [coContent]="'DL.GO01.C446'"
        ></button>
        <co-warning *ngIf="isAdvisor" class="spacing-unit--over">
          <span [coContent]="'DL.GO01.C448'"></span>
        </co-warning>
      </div>
    </div>

    <div class="modal-buttons--regular" *ngIf="!isHealthQuestionnareGenerated">
      <button
        mat-raised-button
        (click)="closePopup(false)"
        [coContent]="'DL.GO01.C409'"
      ></button>
    </div>
  </mat-dialog-actions>
</div>
