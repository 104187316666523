/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Indtastetdaekning } from './indtastetdaekning';
import { IndtastetdaekningSchema } from './indtastetdaekning';
import { IdAndName } from './idAndName';
import { IdAndNameSchema } from './idAndName';
import * as JoiObj from "joi";


    export const IndtastetaftaleSchema = JoiObj.object({
        daekninger:
                JoiObj.array().items(
        
        JoiObj.link('#IndtastetdaekningSchema')

        )
        

.required()


        ,
        id:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        investeringsform:
            
        JoiObj.link('#IdAndNameSchema')


.required()


        ,
        selskab:
            
        JoiObj.link('#IdAndNameSchema')


.required()


        
    })
                .shared(IndtastetdaekningSchema)
                .shared(IdAndNameSchema)
                .shared(IdAndNameSchema)
.id('IndtastetaftaleSchema')

export interface Indtastetaftale { 
    daekninger: Array<Indtastetdaekning>;
    id: string;
    investeringsform: IdAndName;
    selskab: IdAndName;
}

