<mitpfa-insurance-guide-recommendation-card
  [recommendationDirection]="recommendationDirection"
  [title]="recommendationDescription ? recommendationDescription.text : ''"
  [recommendationDescription]="recommendationDescription"
  [hasAdvisorRecommendation]="hasAdvisorRecommendation()"
  [recommendationHeaderTooltip]="recommendationHeaderTooltip"
  [showNoCoverageWarning]="isDisabled"
  [showFollowOurRecommendationButton]="showFollowOurRecommendationButton"
  [hideActions]="hideActions"
  [confirmChoiceData]="confirmChoice.loc"
  (confirmChoiceEmitter)="onConfirmChoice()"
  accordionTrackingName="ISG recommendation – pfaoccupationalcapacity"
>
  <co-primary-number
    advisorValue
    class="size-sm"
    [amount]="advisorData?.raadgiverAnbefaling | numberFormat"
    [unit]="'%'"
  ></co-primary-number>
  <div advisorDescription>
    <div class="advisor-note">
      <p coContent="DL.TF01.C134">Read more here</p>
      <co-tooltip
        [text]="advisorData?.raadgiverKommentar ?? ''"
        class="advisor-note-tooltip"
      ></co-tooltip>
    </div>
    <p
      coContent="DL.TF01.C131"
      [coContentSubst]="advisorData?.raadgiverAnbefalingOprettet ?? ''"
    ></p>
  </div>

  <ng-container recommendedValue>
    <co-primary-number
      *ngIf="
        !recommendationDescription?.isAuto ||
        recommendationDescription?.recommendation === 'MANUAL'
      "
      [ngClass]="{ 'color--black-60': hasAdvisorRecommendation() }"
      class="size-sm"
      [amount]="
        overrideRecommendationKeepCurrent
          ? (pensionInfo.forsikring.helbredsTalBrutto | numberFormat)
          : (pensionInfo.forsikring.pfaAnbefalerHelbred.procent | numberFormat)
      "
      [unit]="'%'"
    ></co-primary-number>
    <div
      *ngIf="
        recommendationDescription?.isAuto &&
        recommendationDescription?.recommendation !== 'MANUAL'
      "
    >
      <h4 coContent="DL.TF01.C178" class="tag-header"></h4>
      <div class="tag-tooltip tag-tooltip--tight">
        <span [coContent]="recommendedCoverageTagText"></span>
        <co-icon-info
          *ngIf="recommendedCoverageTagTooltip"
          matTooltip="{{ recommendedCoverageTagTooltip }}"
        ></co-icon-info>
      </div>
    </div>
  </ng-container>

  <span
    *ngIf="
      !this.hasAdvisorRecommendation() &&
      (!recommendationDescription?.isAuto || reccomendOptOut) &&
      recommendationDescription?.direction !== 'KEEP'
    "
    recommendedDescription
    [coContent]="recommendationDescription?.description"
    [coContentSubst]="
      pensionInfo.forsikring.pfaAnbefalerHelbred.procent | numberFormat
    "
  ></span>

  <span
    *ngIf="
      !this.hasAdvisorRecommendation() &&
      !recommendationDescription?.isAuto &&
      recommendationDescription?.direction === 'KEEP'
    "
    recommendedDescription
    [coContent]="taxFree ? 'DL.FG01.C1927' : 'DL.FG01.C1854'"
    [coContentSubst]="pensionInfo.forsikring.ialtTAE | numberFormat"
  ></span>

  <ng-container supplementaryRecommendation>
    <div *ngIf="supplementaryRecommendation">
      <div
        class="right-header text--bold color--primary"
        [coContent]="'DL.FG01.C1955'"
      ></div>
      <h4 coContent="DL.TF01.C178" class="tag-header"></h4>
      <div class="tag-tooltip tag-tooltip--tight">
        <span [coContent]="recommendedCoverageTagTextSupplementary"></span>
        <co-icon-info
          *ngIf="recommendedCoverageTagTooltipSupplementary"
          matTooltip="{{ recommendedCoverageTagTooltipSupplementary }}"
        ></co-icon-info>
      </div>
      <div>
        <span class="right-description" [coContent]="'DL.FG01.C1956'"></span>
        <co-tooltip [text]="'DL.FG02.C352'" [subst]="'80'"> </co-tooltip>
      </div>
    </div>

    <div *ngIf="manualSupplementaryRecommendation">
      <div
        class="right-header text--bold color--primary"
        [coContent]="'DL.FG01.C1955'"
      ></div>
      <co-primary-number
        class="size-sm"
        [amount]="manualSupplementaryRecommendation"
        [unit]="'%'"
      ></co-primary-number>
      <div class="right-description" [coContent]="'DL.FG01.C1957'"></div>
    </div>
  </ng-container>

  <ng-container currentValue>
    <co-primary-number
      *ngIf="!recommendationDescription?.isCurrentAuto"
      class="size-sm"
      [amount]="pensionInfo.forsikring.helbredsTal | numberFormat"
      [unit]="'%'"
    ></co-primary-number>
    <ng-container *ngIf="recommendationDescription?.isCurrentAuto">
      <h4 coContent="DL.TF01.C178" class="tag-header"></h4>
      <div
        *ngFor="let tag of tags"
        [ngClass]="{ 'tag-tooltip tag-tooltip--tight': tag.tooltip }"
      >
        <span [coContent]="tag.text"></span>
        <co-icon-info
          *ngIf="tag.tooltip"
          matTooltip="{{ tag.tooltip | content }}"
        ></co-icon-info>
      </div>
    </ng-container>
  </ng-container>

  <ng-container currentDescription>
    <span
      *ngIf="!recommendationDescription?.isCurrentAuto"
      [coContent]="taxFree ? 'DL.FG01.C1927' : 'DL.FG01.C1854'"
      [coContentSubst]="pensionInfo.forsikring.ialtTAE | numberFormat"
    ></span>
  </ng-container>

  <ng-container readMoreList>
    <ul class="list">
      <li
        *ngIf="
          !insuranceDetails.pfaAnbefalerHelbred.giftSamlevende &&
          !insuranceDetails.pfaAnbefalerHelbred.harBoern
        "
      >
        <span coContent="DL.FG01.C1479"></span>
      </li>

      <li
        *ngIf="
          !insuranceDetails.pfaAnbefalerHelbred.giftSamlevende &&
          insuranceDetails.pfaAnbefalerHelbred.harBoern
        "
      >
        ><span coContent="DL.FG01.C1429"></span>
      </li>

      <li
        *ngIf="
          insuranceDetails.pfaAnbefalerHelbred.giftSamlevende &&
          insuranceDetails.pfaAnbefalerHelbred.forsoergerAndel >= 60
        "
      >
        <span coContent="DL.FG01.C1430"></span>
      </li>

      <li
        *ngIf="
          insuranceDetails.pfaAnbefalerHelbred.giftSamlevende &&
          insuranceDetails.pfaAnbefalerHelbred.forsoergerAndel < 60
        "
      >
        <span coContent="DL.FG01.C1440"></span>
      </li>

      <li *ngIf="insuranceDetails.pfaAnbefalerHelbred.storFormue">
        <span coContent="DL.FG01.C1480"></span>
      </li>

      <li *ngIf="insuranceDetails.pfaAnbefalerHelbred.gaeld">
        <span coContent="DL.FG01.C1481"></span>
      </li>

      <li>
        <span
          coContent="DL.FG01.C1441"
          [coContentSubst]="salary | numberFormat"
        ></span>
      </li>

      <li>
        <span
          coContent="DL.FG01.C1442"
          [coContentSubst]="riskText | content"
        ></span>
      </li>

      <li
        *ngIf="
          insuranceDetails.pfaAnbefalerHelbred.recommendationAdjustedToMinimum
        "
        [coContent]="'DL.FG01.C1929'"
      ></li>
      <li>
        <span coContent="DL.FG01.C1985"></span>
      </li>
    </ul>
  </ng-container>
</mitpfa-insurance-guide-recommendation-card>
