/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SprogType } from './sprogType';
import { SprogTypeSchema } from './sprogType';
import * as JoiObj from "joi";


    export const ContactDataSchema = JoiObj.object({
        email:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        emailValid:
            
                    JoiObj.boolean()
                

.allow(null)



        ,
        firstName:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        languageCode:
            
        JoiObj.link('#SprogTypeSchema')


.required()


        ,
        lastName:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        phoneCountryCode:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        phoneNumber:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        phoneNumberValid:
            
                    JoiObj.boolean()
                

.allow(null)



        ,
        verifyContactInformation:
            
                    JoiObj.boolean()
                


.required()


        
    })
                .shared(SprogTypeSchema)
.id('ContactDataSchema')

export interface ContactData { 
    email?: string;
    emailValid?: boolean;
    firstName?: string;
    languageCode: SprogType;
    lastName?: string;
    phoneCountryCode?: string;
    phoneNumber?: string;
    phoneNumberValid?: boolean;
    verifyContactInformation: boolean;
}
export namespace ContactData {
}


