import { Injectable } from '@angular/core';
import { TrackingService } from './tracking.service';

export type PopupId =
  | 'proof of identity - login'
  | 'proof of identity - pep paragraph 53';

const eventName = 'track-popup';

export type ModalTrackingName = 'pfaclimateplus';

@Injectable({
  providedIn: 'root'
})
export class PopupTrackingService extends TrackingService {
  trackPopupViewed(popupName: string): void {
    this.dispatchCustomEventTracking(eventName, {
      popupName,
      popupAction: 'viewed'
    });
  }

  trackPopupClosed(popupName: string): void {
    this.dispatchCustomEventTracking(eventName, {
      popupName,
      popupAction: 'closed'
    });
  }

  trackPopupCallToAction(popupName: string): void {
    this.dispatchCustomEventTracking(eventName, {
      popupName,
      popupAction: 'cta clicked'
    });
  }

  trackNamedPopupCallToAction(popupName: string, popupCta: string): void {
    this.dispatchCustomEventTracking(eventName, {
      popupName,
      popupAction: 'cta clicked',
      popupCta
    });
  }

  public trackSidebarOpen(modalName: ModalTrackingName): void {
    this.dispatchCustomEventTracking('track-configuration-modal-viewed', {
      modalName
    });
  }

  public trackSidebarCTA(modalName: ModalTrackingName, modalCTA: string): void {
    this.dispatchCustomEventTracking('track-configuration-modal-viewed', {
      modalName,
      modalCTA
    });
  }
}
