/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const ServiceProgramSchema = JoiObj.string().valid(
        'Formuekunde'
        ,
        'Raadgivningskunde'
        ,
        'Servicekunde'
        
    )
.id('ServiceProgramSchema')

export type ServiceProgram = 'Formuekunde' | 'Raadgivningskunde' | 'Servicekunde';

export const ServiceProgram = {
    Formuekunde: 'Formuekunde' as ServiceProgram,
    Raadgivningskunde: 'Raadgivningskunde' as ServiceProgram,
    Servicekunde: 'Servicekunde' as ServiceProgram
};

