<div class="text--container spacing-unit--double-under">
  <div class="header text--manchet">
    <div [coContent]="'DL.VK01.C50'"></div>
  </div>
</div>
<form
  [formGroup]="form"
  class="spacing-unit--double-over"
  *ngIf="transferablePolicies?.length > 0; else noPolicies"
  (ngSubmit)="submitExternalTransferForm()"
>
  <div class="wrapper">
    <p coContent="DL.VK01.C51" class="header subheader"></p>
    <co-card class="combine-savings-accept-transfer-card max-width">
      <p coContent="DL.VK01.C52" class="text--bold color--black"></p>
      <ng-container
        *ngFor="let policy of transferablePolicies"
        formGroupName="policies"
      >
        <div class="container" [formGroupName]="policy.uid">
          <div class="policy-row">
            <div class="spacing">
              <co-primary-number
                class="size-sm"
                [amount]="policy.opsparing"
              ></co-primary-number>
              <div class="text--bold spacing-unit--half-over">
                {{ policy | policyCompanyName }}
              </div>
              <div>
                {{ policy.pensionsordning }}
              </div>
            </div>
            <mat-slide-toggle
              class="policy-toggle"
              formControlName="transfer"
              [color]="'accent'"
            >
            </mat-slide-toggle>
          </div>
          <div
            *ngIf="
              getPolicyFormGroup(policy.uid)?.value &&
              showSuppressWarningCheckbox(policy)
            "
            class="spacing-unit--over"
          >
            <mat-checkbox formControlName="suppressWarning">
              <span [coContent]="'DL.EO01.C679'"></span>
            </mat-checkbox>
          </div>
          <div *ngIf="policy.overfoerselBestilt" class="warning">
            <co-alert class="alert-info">
              <span [coContent]="'DL.EO01.C664'"></span>
            </co-alert>
          </div>
          <div
            class="spacing-unit--over"
            *ngIf="
              policy.produkttype === bankPensionType &&
              !policy.overfoerselBestilt
            "
          >
            <p
              *ngIf="getPolicyFormGroup(policy.uid).get('bankNumber')"
              class="policy-bank-number-label"
              [coContent]="'DL.EO01.C653'"
            ></p>
            <div class="bank--fields">
              <div *ngIf="getPolicyFormGroup(policy.uid).get('bankNumber')">
                <mat-form-field
                  class="policy-bank policy-bank-number"
                  color="accent"
                >
                  <mat-label [coContent]="'DL.EO01.C44'"></mat-label>
                  <input
                    class="policy-bank-number-input"
                    matInput
                    formControlName="bankNumber"
                    [id]="'bankNumber' + policy.uid"
                    required
                  />
                  <mat-error
                    *ngIf="
                      getPolicyFormGroup(policy.uid).get('bankNumber').errors
                        ?.required
                    "
                    [coContent]="'DL.EO01.C655'"
                  ></mat-error>
                  <mat-error
                    *ngIf="
                      getPolicyFormGroup(policy.uid).get('bankNumber').errors
                        ?.pattern
                    "
                    [coContent]="'DL.EO01.C656'"
                  ></mat-error>
                </mat-form-field>
              </div>
              <div *ngIf="getPolicyFormGroup(policy.uid).get('accountNumber')">
                <mat-form-field class="policy-bank" color="accent">
                  <mat-label [coContent]="'DL.EO01.C661'"></mat-label>
                  <input
                    class="policy-bank-number-input"
                    matInput
                    formControlName="accountNumber"
                    [id]="'accountNumber' + policy.uid"
                    required
                  />
                  <mat-error
                    *ngIf="
                      getPolicyFormGroup(policy.uid).get('accountNumber').errors
                        ?.required
                    "
                    [coContent]="'DL.EO01.C655'"
                  ></mat-error>
                  <mat-error
                    *ngIf="
                      getPolicyFormGroup(policy.uid).get('accountNumber').errors
                        ?.pattern
                    "
                    [coContent]="'DL.EO01.C660'"
                  ></mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <div class="button-container">
        <button
          *ngIf="combineSavingsFlowType !== 'EXTERNAL_TRANSFER_FLOW'"
          mat-raised-button
          class="mat-focus-indicator button-secondary mat-raised-button mat-button-bas"
          [coContent]="'DL.EO01.C654'"
          (click)="showAreYouSure(); $event.preventDefault()"
        ></button>
        <button
          mat-raised-button
          type="submit"
          [coContent]="'DL.EO01.C609'"
          [disabled]="form.invalid || allPoliciesAlreadyOrdered"
          data-test-id="EO01-combine-savings-transfer-btn"
          class="combine-savings-accept-transfer continue"
        ></button>
      </div>
    </co-card>
  </div>
</form>

<ng-template #noPolicies>
  <div class="spacing-unit--double-over">
    <co-card class="combine-savings-accept-transfer-card">
      <co-warning>
        <span [coContent]="'DL.EO01.C636'"></span>
      </co-warning>
    </co-card>
  </div>
</ng-template>

<div class="spacing-unit--double-over">
  <div
    *ngIf="combineSavingsFlowType === 'EXTERNAL_TRANSFER_FLOW'"
    class="navigation"
  >
    <mitpfa-combine-savings-back-button
      (back)="goBack()"
    ></mitpfa-combine-savings-back-button>
    <button
      (click)="jumpover()"
      *ngIf="!showDeclineTransfer"
      mat-raised-button
      class="button-primary--level3 with-icon icon__on-primary--stroke"
    >
      <span [coContent]="'DL.EO01.C17'"></span>
      <co-icon-arrow-right></co-icon-arrow-right>
    </button>
    <button
      (click)="declineTransfer()"
      *ngIf="showDeclineTransfer"
      mat-raised-button
      class="button-primary--level3 with-icon icon__on-primary--stroke"
    >
      <span [coContent]="'DL.EO01.C654'"></span>
      <co-icon-arrow-right></co-icon-arrow-right>
    </button>
  </div>
</div>
