import { Injectable } from '@angular/core';

export interface Article {
  title: ArticleTitle;
  cTexts: string[];
}

enum ArticleTitle {
  ACTION_CENTER_NBA = 'pensionstjek-nba',
  CONSENTS_ADVISING_PFA_BANK = 'raadgivning-pfa-bank-af-pfa-pension',
  CONSENTS_FINANCE_PLUS = 'betingelser-pfa-kundeprogram-finansplus',
  CONSENTS_PFA_BANK = 'dataudveksling-marketing-pension-og-bank',
  CONSENTS_PFA_FOCUS = 'betingelser-pfa-kundeprogram',
  CONTACT = 'kontaktoplysninger-mit-pfa',
  PAYOUT_INSURANCE = 'udbetalingssikring',
  TAX_RULES_INSURANCE = 'skatteregler-forsikring',
  TRANSACTIONS = 'handler'
}

@Injectable({
  providedIn: 'root'
})
export class ArticlesService {
  private readonly articles: Article[];

  constructor() {
    this.articles = [
      {
        title: ArticleTitle.ACTION_CENTER_NBA,
        cTexts: ['DL.NB01.C331', 'DL.NB01.C332']
      },
      {
        title: ArticleTitle.CONSENTS_ADVISING_PFA_BANK,
        cTexts: [
          'DL.ST01.C219',
          'DL.ST01.C220',
          'DL.ST01.C231',
          'DL.ST01.C232',
          'DL.ST01.C233',
          'DL.ST01.C221'
        ]
      },
      {
        title: ArticleTitle.CONSENTS_FINANCE_PLUS,
        cTexts: [
          'DL.ST01.C223',
          'DL.ST01.C224',
          'DL.ST01.C225',
          'DL.ST01.C226',
          'DL.ST01.C227',
          'DL.ST01.C228',
          'DL.ST01.C229'
        ]
      },
      {
        title: ArticleTitle.CONSENTS_PFA_BANK,
        cTexts: ['DL.ST01.C216', 'DL.ST01.C217', 'DL.ST01.C218']
      },
      {
        title: ArticleTitle.CONSENTS_PFA_FOCUS,
        cTexts: [
          'DL.ST01.C209',
          'DL.ST01.C210',
          'DL.ST01.C211',
          'DL.ST01.C212',
          'DL.ST01.C213',
          'DL.ST01.C214',
          'DL.ST01.C215'
        ]
      },
      {
        title: ArticleTitle.CONTACT,
        cTexts: ['DL.ST01.C222']
      },
      {
        title: ArticleTitle.PAYOUT_INSURANCE,
        cTexts: [
          'DL.FF01.C596',
          'DL.FF01.C597',
          'DL.FF01.C598',
          'DL.FF01.C599',
          'DL.FF01.C600',
          'DL.FF01.C601',
          'DL.FF01.C602'
        ]
      },
      {
        title: ArticleTitle.TAX_RULES_INSURANCE,
        cTexts: ['DL.FG02.C353', 'DL.FG02.C354', 'DL.FG02.C355']
      },
      {
        title: ArticleTitle.TRANSACTIONS,
        cTexts: [
          'DL.INV.C626',
          'DL.INV.C627',
          'DL.INV.C628',
          'DL.INV.C629',
          'DL.INV.C630'
        ]
      }
    ];
  }

  public getArticle(selected: string) {
    return this.articles.find(article => article.title === selected);
  }
}
