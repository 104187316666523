/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ServiceInfo } from './serviceInfo';
import { ServiceInfoSchema } from './serviceInfo';
import { BlanketMedPoliceNr } from './blanketMedPoliceNr';
import { BlanketMedPoliceNrSchema } from './blanketMedPoliceNr';
import { ResourceModel } from './resourceModel';
import { ResourceModelSchema } from './resourceModel';
import * as JoiObj from "joi";


    export const HentOverfoerselsBlanketterResponseSchema = JoiObj.object({
        serviceInfo:
            
        JoiObj.link('#ServiceInfoSchema')

.allow(null)



        ,
        blanketter:
                JoiObj.array().items(
        
        JoiObj.link('#BlanketMedPoliceNrSchema')

        )
        

.required()


        
    })
                .shared(ServiceInfoSchema)
                .shared(BlanketMedPoliceNrSchema)
.id('HentOverfoerselsBlanketterResponseSchema')

export interface HentOverfoerselsBlanketterResponse extends ResourceModel { 
    blanketter: Array<BlanketMedPoliceNr>;
}

