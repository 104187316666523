// Date format (pt used for material datepicker
export const DATE_FORMAT = {
  parse: {
    dateInput: 'DD.MM.YYYY' // TODO this is probably wrong
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'DD.MM.YYYY',
    monthYearA11yLabel: 'DD.MM.YYYY'
  }
};
