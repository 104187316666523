import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { RecommendationDetail } from '../investment-recommendation.model';
import {
  GlobalWorkingService,
  NotificationService,
  NotificationType
} from '@pfaform';
import { InForceSelection, PensionskundeStore } from '@pfa/gen';
import { combineLatest, Observable, of } from 'rxjs';
import { InvestmentService } from '../../investment.service';
import { InvestmentApiService } from '@pfa/api';
import {
  ConfirmChoiceType,
  ContentUtilService,
  GuideTrackingService,
  TrackingName
} from '@pfa/core';

@Component({
  selector: 'mitpfa-investment-recommendation-profile',
  templateUrl: './recommendation-profile.component.html',
  styleUrls: ['../investment-recommendation.component.scss']
})
export class InvestmentRecommendationProfileComponent implements OnInit {
  private readonly pensionskundeStore: PensionskundeStore =
    inject(PensionskundeStore);
  private readonly investmentService: InvestmentService =
    inject(InvestmentService);
  private readonly investmentApiService: InvestmentApiService =
    inject(InvestmentApiService);
  private readonly notificationService: NotificationService =
    inject(NotificationService);
  private readonly globalWorkingService: GlobalWorkingService =
    inject(GlobalWorkingService);
  private readonly contentUtil: ContentUtilService = inject(ContentUtilService);

  private readonly guideTrackingService: GuideTrackingService =
    inject(GuideTrackingService);

  @Output() emitNextCard = new EventEmitter<boolean>();
  @Output() notDecided = new EventEmitter();
  @Output() navigateConcept = new EventEmitter<void>();
  @Input() detail: RecommendationDetail;
  @Input() inForceSelections: InForceSelection[];
  @Input() isOnboardingFlow: boolean;
  public confirmedDate: Date;

  public ngOnInit(): void {
    this.confirmedDate = this.inForceSelections.find(
      selection =>
        selection.inForceSelectionType === 'Investment' &&
        selection.policyId === this.detail.policyId
    )?.timestamp;
  }

  public keepProfile(): void {
    const profileChanged = false;
    this.confirmChoice('DL.INV.C690', profileChanged);
  }

  public followRecommendation(): void {
    const errorText = this.contentUtil.getContent('DL.INV.C50', ['DL.INV.C02']);

    if (this.detail.recommendation.profile) {
      const profileChanged = true;

      this.globalWorkingService.show();
      this.detail.recommendation.profile.valgt = true;
      this.guideTrackingService.trackGuideRecommendationCallToAction(
        TrackingName.INVESTMENT_GUIDE,
        'follow recommendation: pfainvest profile ' +
          this.detail.recommendation.profile.id
      );
      this.investmentApiService
        .updateInvestmentProfile(this.detail.policyId, [
          this.detail.recommendation.profile
        ])
        .subscribe({
          next: response => {
            if (!response.fejl) {
              this.detail.recommendation.profile = response.profiler.find(
                profile => profile.id === this.detail.recommendation.profile?.id
              );

              this.complete('DL.INV.C691', profileChanged);
            } else {
              this.error(errorText);
            }

            this.globalWorkingService.hide();
          },
          error: () => {
            this.error(errorText);
            this.globalWorkingService.hide();
          }
        });
    } else {
      this.error(errorText);
    }
  }

  private confirmChoice(completeText: string, profileChanged: boolean): void {
    this.globalWorkingService.show();

    let observables$: Observable<unknown>[] = [];

    observables$ = this.investmentConfirmChoice(
      observables$,
      this.isOnboardingFlow
    );
    observables$ = this.optionalConfirmChoice(
      observables$,
      this.isOnboardingFlow
    );
    observables$ = this.youInvestConfirmChoice(
      observables$,
      this.isOnboardingFlow
    );
    observables$ = observables$.length ? observables$ : [of(null)];

    combineLatest(observables$).subscribe({
      next: () => {
        this.globalWorkingService.hide();
        this.complete(completeText, profileChanged);
        this.pensionskundeStore.invalidatepensionskundeIndex();
      },
      error: () => {
        this.globalWorkingService.hide();
        this.error('Global.error.confirmchoice');
      }
    });
  }

  private investmentConfirmChoice(
    observables: Observable<unknown>[],
    isOnboarding: boolean
  ): Observable<unknown>[] {
    if (this.detail.current.hasPfaInvest) {
      observables.push(
        this.investmentService.postInvestmentConfirmChoice(
          this.detail.current.profile,
          this.detail.recommendation.profile,
          this.detail.policyId,
          this.detail.recommendation.adobeConceptTrackingValue,
          this.confirmedDate
            ? ConfirmChoiceType.RECONFIRM
            : ConfirmChoiceType.FIRST,
          isOnboarding
        )
      );
    }

    return observables;
  }

  private optionalConfirmChoice(
    observables: Observable<unknown>[],
    isOnboarding: boolean
  ): Observable<unknown>[] {
    const type = 'PfaOptionel';

    if (this.detail.current.hasPfaOptional) {
      observables.push(
        this.investmentService.postOptionalConfirmChoice(
          type,
          this.detail.policyId,
          this.detail.recommendation.adobeConceptTrackingValue,
          this.confirmedDate
            ? ConfirmChoiceType.RECONFIRM
            : ConfirmChoiceType.FIRST,
          isOnboarding
        )
      );
    }

    return observables;
  }

  private youInvestConfirmChoice(
    observables: Observable<unknown>[],
    isOnboarding: boolean
  ): Observable<unknown>[] {
    const type = 'YouInvest';

    if (this.detail.current.hasYouInvest) {
      observables.push(
        this.investmentService.postYouInvestConfirmChoice(
          type,
          this.detail.policyId,
          this.detail.recommendation.adobeConceptTrackingValue,
          this.confirmedDate
            ? ConfirmChoiceType.RECONFIRM
            : ConfirmChoiceType.FIRST,
          isOnboarding
        )
      );
    }

    return observables;
  }

  private complete(text: string, profileChanged: boolean): void {
    this.notificationService.showNotification({
      message: text,
      type: NotificationType.SUCCESS
    });

    this.emitNextCard.emit(profileChanged);
  }

  private error(text: string): void {
    this.notificationService.showNotification({
      message: text,
      type: NotificationType.ERROR
    });
  }

  public navigateToConceptAdjust(): void {
    if (this.detail.recommendation.recommendYouInvest) {
      this.guideTrackingService.trackGuideRecommendationCallToAction(
        TrackingName.INVESTMENT_GUIDE,
        'configure: youinvest'
      );
    } else {
      this.guideTrackingService.trackGuideRecommendationCallToAction(
        TrackingName.INVESTMENT_GUIDE,
        'configure: pfainvest profile'
      );
    }
    this.navigateConcept.emit();
  }

  public navigateToConceptWithChooseYourself(): void {
    this.guideTrackingService.trackGuideRecommendationCallToAction(
      TrackingName.INVESTMENT_GUIDE,
      'configure: investment concept'
    );
    this.navigateConcept.emit();
  }

  public nextCard(): void {
    this.guideTrackingService.trackGuideRecommendationCallToAction(
      TrackingName.INVESTMENT_GUIDE,
      'configure: investment concept not decided'
    );
    this.notDecided.emit();
  }
}
