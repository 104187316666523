/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { LocPayScaleEntry } from './locPayScaleEntry';
import { LocPayScaleEntrySchema } from './locPayScaleEntry';
import * as JoiObj from "joi";


    export const LocPayScaleResponseSchema = JoiObj.object({
        activeFrom:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        createdAt:
            
                    JoiObj.number()
                


.required()


        ,
        createdBy:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        entryList:
                JoiObj.array().items(
        
        JoiObj.link('#LocPayScaleEntrySchema')

        )
        

.required()


        ,
        scale:
            
                    JoiObj.number()
                


.required()


        
    })
                .shared(LocPayScaleEntrySchema)
.id('LocPayScaleResponseSchema')

export interface LocPayScaleResponse { 
    activeFrom: string;
    createdAt: number;
    createdBy: string;
    entryList: Array<LocPayScaleEntry>;
    scale: number;
}

