/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { IndbetalingsaftaleStatus } from './indbetalingsaftaleStatus';
import { IndbetalingsaftaleStatusSchema } from './indbetalingsaftaleStatus';
import { KundeInformationer } from './kundeInformationer';
import { KundeInformationerSchema } from './kundeInformationer';
import { IndbetalingsaftaleFejl } from './indbetalingsaftaleFejl';
import { IndbetalingsaftaleFejlSchema } from './indbetalingsaftaleFejl';
import * as JoiObj from "joi";


    export const IndbetalingsaftaleDetaljerSchema = JoiObj.object({
        attachmentGUID:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        cprNummer:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        cvrNummer:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        healthInsuranceProduct:
            
                    JoiObj.number()
                .integer()


.required()


        ,
        healthRequirementCode:
            
                    JoiObj.number()
                .integer()


.required()


        ,
        indbetalingsaftaleFejl:
            
        JoiObj.link('#IndbetalingsaftaleFejlSchema')

.allow(null)



        ,
        indbetalingsaftaleStatus:
            
        JoiObj.link('#IndbetalingsaftaleStatusSchema')


.required()


        ,
        kundeInformationer:
            
        JoiObj.link('#KundeInformationerSchema')


.required()


        ,
        monthlyPaymentAmount:
            
                    JoiObj.number()
                

.allow(null)



        ,
        monthlyPaymentPercentage:
            
                    JoiObj.number()
                

.allow(null)



        ,
        startDato:
            
            JoiObj.date()


.required()


        ,
        variantCode:
            
                    JoiObj.number()
                .integer()


.required()


        
    })
                .shared(IndbetalingsaftaleFejlSchema)
                .shared(IndbetalingsaftaleStatusSchema)
                .shared(KundeInformationerSchema)
.id('IndbetalingsaftaleDetaljerSchema')

export interface IndbetalingsaftaleDetaljer { 
    attachmentGUID?: string;
    cprNummer: string;
    cvrNummer: string;
    healthInsuranceProduct: number;
    healthRequirementCode: number;
    indbetalingsaftaleFejl?: IndbetalingsaftaleFejl;
    indbetalingsaftaleStatus: IndbetalingsaftaleStatus;
    kundeInformationer: KundeInformationer;
    monthlyPaymentAmount?: number;
    monthlyPaymentPercentage?: number;
    startDato: Date;
    variantCode: number;
}
export namespace IndbetalingsaftaleDetaljer {
}


