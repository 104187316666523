<svg
  id="Layer_1"
  data-name="Layer 1"
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 103.42 94"
>
  <rect
    x="7.71"
    y="84.84"
    width="87.66"
    height="2.63"
    rx="1.29"
    ry="1.29"
    fill="#c8c8c8"
  />
  <path
    d="M86.74,40.37h10.99c2.35,0,4.26-1.91,4.26-4.26h0c0-2.35-1.91-4.26-4.26-4.26h-10.99c-.63,0-1.14,.51-1.14,1.14v6.24c0,.63,.51,1.14,1.14,1.14Z"
    fill="#900"
  />
  <path
    d="M17.11,40.37H6.11c-2.35,0-4.26-1.91-4.26-4.26h0c0-2.35,1.91-4.26,4.26-4.26h10.99c.63,0,1.14,.51,1.14,1.14v6.24c0,.63-.51,1.14-1.14,1.14Z"
    fill="#900"
  />
  <g opacity=".2">
    <path
      d="M17.11,37.61H6.11c-1.87,0-3.46-1.21-4.03-2.89-.15,.43-.23,.9-.23,1.38h0c0,2.36,1.91,4.26,4.26,4.26h10.99c.63,0,1.14-.51,1.14-1.14v-2.76c0,.63-.51,1.14-1.14,1.14Z"
    />
  </g>
  <g opacity=".2">
    <path
      d="M86.74,37.61h10.99c1.87,0,3.46-1.21,4.03-2.89,.15,.43,.23,.9,.23,1.38h0c0,2.36-1.91,4.26-4.26,4.26h-10.99c-.63,0-1.14-.51-1.14-1.14v-2.76c0,.63,.51,1.14,1.14,1.14Z"
    />
  </g>
  <path
    d="M91.6,42.41H12.29l4.6-26.9c.42-3.14,3.43-5.5,7-5.5h56.12c3.57,0,6.57,2.36,7,5.5l4.6,26.9Z"
    fill="#900"
  />
  <path
    d="M85.6,40.5H18.25l3.09-21.12c.36-2.47,2.91-4.32,5.94-4.32h49.29c3.03,0,5.58,1.86,5.94,4.32l3.09,21.12Z"
    fill="#5a949f"
  />
  <rect
    x="10.81"
    y="67.23"
    width="13.61"
    height="19.24"
    rx="2.94"
    ry="2.94"
    fill="#333"
  />
  <rect
    x="78.8"
    y="67.23"
    width="13.61"
    height="19.24"
    rx="2.94"
    ry="2.94"
    fill="#333"
  />
  <rect
    x="7.71"
    y="40.37"
    width="88.05"
    height="33.82"
    rx="6.35"
    ry="6.35"
    fill="#900"
  />
  <path
    d="M89.41,40.37H14.05c-3.51,0-6.35,2.84-6.35,6.35v6.12c0-3.51,2.84-6.35,6.35-6.35H89.41c3.51,0,6.35,2.84,6.35,6.35v-6.12c0-3.51-2.84-6.35-6.35-6.35Z"
    fill="#b55"
  />
  <g opacity=".2">
    <path
      d="M89.88,65.25H13.58c-3.24,0-5.87-2.63-5.87-5.87v8.94c0,3.24,2.63,5.87,5.87,5.87H89.88c3.24,0,5.87-2.63,5.87-5.87v-8.94c0,3.24-2.63,5.87-5.87,5.87Z"
    />
  </g>
  <rect
    x="31.79"
    y="49.01"
    width="39.92"
    height="8.27"
    rx="3.35"
    ry="3.35"
    fill="#666"
  />
  <rect
    x="50.81"
    y="37.74"
    width="31.48"
    height="2.63"
    rx=".86"
    ry=".86"
    fill="#333"
  />
  <rect
    x="10.81"
    y="49.01"
    width="18.25"
    height="8.27"
    rx="2.26"
    ry="2.26"
    fill="#c8c8c8"
  />
  <circle cx="15.91" cy="53.15" r="2.97" fill="#fff" />
  <circle cx="23.57" cy="53.15" r="2.55" fill="#fff" />
  <rect
    x="74.42"
    y="49.01"
    width="18.25"
    height="8.27"
    rx="2.26"
    ry="2.26"
    fill="#c8c8c8"
  />
  <circle cx="87.57" cy="53.15" r="2.97" fill="#fff" />
  <circle cx="79.91" cy="53.15" r="2.55" fill="#fff" />
  <path
    d="M18.88,38.03v-.03c11.65-7.65,31.19-14.91,62.97-20.34-1.01-1.56-3.01-2.61-5.29-2.61H27.28c-3.03,0-5.58,1.86-5.94,4.32l-3.09,21.12h4.02c-1.92,0-3.45-1.12-3.38-2.46Z"
    fill="#007782"
  />
  <rect
    x="16.52"
    y="37.74"
    width="31.48"
    height="2.63"
    rx=".86"
    ry=".86"
    fill="#333"
  />
</svg>
