/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TransferableCategoryType } from './transferableCategoryType';
import { TransferableCategoryTypeSchema } from './transferableCategoryType';
import * as JoiObj from "joi";


    export const RecommendationShortInfoSchema = JoiObj.object({
        overallCategory:
            
        JoiObj.link('#TransferableCategoryTypeSchema')


.required()


        ,
        validForProcessing:
            
                    JoiObj.boolean()
                


.required()


        
    })
                .shared(TransferableCategoryTypeSchema)
.id('RecommendationShortInfoSchema')

export interface RecommendationShortInfo { 
    overallCategory: TransferableCategoryType;
    validForProcessing: boolean;
}
export namespace RecommendationShortInfo {
}


