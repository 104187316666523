/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const TAEPakkeTilkendelseskriterieSchema = JoiObj.string().valid(
        'HEL_VED_TO_TREDJEDELE_BASIS'
        ,
        'HEL_VED_TO_TREDJEDELE_GRUND'
        ,
        'HEL_VED_HALV_50_PROCENT_SELVST'
        ,
        'HEL_VED_HALV_30_PROCENT_MELLEM'
        ,
        'HEL_VED_HALV_10_PROCENT_FULD'
        ,
        'HEL_VED_HALV_10_PROCENT_TOP'
        ,
        'HEL_VED_HALV_50_PROCENT'
        ,
        'HEL_VED_HALV_25_PROCENT'
        ,
        'HEL_VED_TO_TREDJEDELE'
        ,
        'HALV_VED_HALV_OG_HEL_VED_TO_TREDJEDELE'
        ,
        'HALV_VED_HALV_MED_HEL_PRAEMIEFRITAGELSE'
        ,
        'INGEN'
        
    )
.id('TAEPakkeTilkendelseskriterieSchema')

export type TAEPakkeTilkendelseskriterie = 'HEL_VED_TO_TREDJEDELE_BASIS' | 'HEL_VED_TO_TREDJEDELE_GRUND' | 'HEL_VED_HALV_50_PROCENT_SELVST' | 'HEL_VED_HALV_30_PROCENT_MELLEM' | 'HEL_VED_HALV_10_PROCENT_FULD' | 'HEL_VED_HALV_10_PROCENT_TOP' | 'HEL_VED_HALV_50_PROCENT' | 'HEL_VED_HALV_25_PROCENT' | 'HEL_VED_TO_TREDJEDELE' | 'HALV_VED_HALV_OG_HEL_VED_TO_TREDJEDELE' | 'HALV_VED_HALV_MED_HEL_PRAEMIEFRITAGELSE' | 'INGEN';

export const TAEPakkeTilkendelseskriterie = {
    HelVedToTredjedeleBasis: 'HEL_VED_TO_TREDJEDELE_BASIS' as TAEPakkeTilkendelseskriterie,
    HelVedToTredjedeleGrund: 'HEL_VED_TO_TREDJEDELE_GRUND' as TAEPakkeTilkendelseskriterie,
    HelVedHalv50ProcentSelvst: 'HEL_VED_HALV_50_PROCENT_SELVST' as TAEPakkeTilkendelseskriterie,
    HelVedHalv30ProcentMellem: 'HEL_VED_HALV_30_PROCENT_MELLEM' as TAEPakkeTilkendelseskriterie,
    HelVedHalv10ProcentFuld: 'HEL_VED_HALV_10_PROCENT_FULD' as TAEPakkeTilkendelseskriterie,
    HelVedHalv10ProcentTop: 'HEL_VED_HALV_10_PROCENT_TOP' as TAEPakkeTilkendelseskriterie,
    HelVedHalv50Procent: 'HEL_VED_HALV_50_PROCENT' as TAEPakkeTilkendelseskriterie,
    HelVedHalv25Procent: 'HEL_VED_HALV_25_PROCENT' as TAEPakkeTilkendelseskriterie,
    HelVedToTredjedele: 'HEL_VED_TO_TREDJEDELE' as TAEPakkeTilkendelseskriterie,
    HalvVedHalvOgHelVedToTredjedele: 'HALV_VED_HALV_OG_HEL_VED_TO_TREDJEDELE' as TAEPakkeTilkendelseskriterie,
    HalvVedHalvMedHelPraemiefritagelse: 'HALV_VED_HALV_MED_HEL_PRAEMIEFRITAGELSE' as TAEPakkeTilkendelseskriterie,
    Ingen: 'INGEN' as TAEPakkeTilkendelseskriterie
};

