/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ServiceInfo } from './serviceInfo';
import { ServiceInfoSchema } from './serviceInfo';
import { InvesteringSvar } from './investeringSvar';
import { InvesteringSvarSchema } from './investeringSvar';
import { PolicespecifikKontrakt } from './policespecifikKontrakt';
import { PolicespecifikKontraktSchema } from './policespecifikKontrakt';
import * as JoiObj from "joi";


    export const EngagementKontraktSchema = JoiObj.object({
        serviceInfo:
            
        JoiObj.link('#ServiceInfoSchema')

.allow(null)



        ,
        fejl:
            
                    JoiObj.boolean()
                


.required()


        ,
        fejlTekst:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        fejlkode:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        changeLimitReached:
            
                    JoiObj.boolean()
                


.required()


        ,
        duInvestererAendringer:
            
                    JoiObj.boolean()
                


.required()


        ,
        friviligHelbredsTekst:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        ingenAendringer:
            
                    JoiObj.boolean()
                


.required()


        ,
        kundensEmail:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        policespecifikkeKontrakter:
                JoiObj.array().items(
        
        JoiObj.link('#PolicespecifikKontraktSchema')

        )
        

.required()


        ,
        showEmailBox:
            
                    JoiObj.boolean()
                


.required()


        ,
        showPensionsUdbetalingPopup:
            
                    JoiObj.boolean()
                


.required()


        ,
        visHandelvindueLukketAdvarsel:
            
                    JoiObj.boolean()
                


.required()


        
    })
                .shared(ServiceInfoSchema)
                .shared(PolicespecifikKontraktSchema)
.id('EngagementKontraktSchema')

export interface EngagementKontrakt extends InvesteringSvar { 
    changeLimitReached: boolean;
    duInvestererAendringer: boolean;
    friviligHelbredsTekst: string;
    ingenAendringer: boolean;
    kundensEmail: string;
    policespecifikkeKontrakter: Array<PolicespecifikKontrakt>;
    showEmailBox: boolean;
    showPensionsUdbetalingPopup: boolean;
    visHandelvindueLukketAdvarsel: boolean;
}

