import { Injectable } from '@angular/core';
import { NbaCard } from './nba-card.model';
import { DynamicAdvisingOfferExtended } from './pension-check.service';
import { NbaContentService } from './nba-content.service';
import { NextBestActionService } from '@mitpfa/shared';
import {
  DynamicAdvisingBasisValues,
  DynamicAdvisingCalculation,
  DynamicAdvisingOffer,
  DynamicAdvisingOfferidType,
  LocScale,
  TilpasForsikringstal
} from '@pfa/gen';
import { DynamicAdvisingFilterService } from '@mitpfa/shared/services/dynamic-advising-filter.service';

@Injectable({
  providedIn: 'root'
})
export class NbaCardService {
  private readonly INSURANCE_ADJUSTMENT_URL = '/mineforsikringer/tilpas';

  constructor(
    private readonly nbaContentService: NbaContentService,
    private readonly nextBestActionService: NextBestActionService,
    private readonly dynamicAdvisingFilterService: DynamicAdvisingFilterService
  ) {}

  public buildNbaCard(offers: DynamicAdvisingOfferExtended[]): NbaCard[] {
    const nbaCard: NbaCard[] = [];
    offers.forEach((offer: DynamicAdvisingOfferExtended) => {
      nbaCard.push(
        new NbaCard(
          offer.offerTrackingIndex,
          offer.offerId,
          offer.offerRecommendationCategory,
          offer.offerHash,
          offer.offerSeverity
        )
      );
    });
    return nbaCard;
  }

  public fillContentInCards(
    nbaCard: NbaCard,
    dynamicAdvisingCalculation: DynamicAdvisingCalculation
  ): NbaCard {
    return this.getCardContent(
      nbaCard,
      this.dynamicAdvisingFilterService.getAllOffers(
        dynamicAdvisingCalculation.offerGroups
      ),
      dynamicAdvisingCalculation.basisValues
    );
  }

  private getCardContent(
    card: NbaCard,
    offers: DynamicAdvisingOffer[],
    values: DynamicAdvisingBasisValues
  ): NbaCard {
    switch (card.id) {
      case 'DATA_COLLECTION_ALL_OK':
        this.nbaContentService.setDataCollectionAllOkTexts(card);
        card.gotoUrl = '/mine-oplysninger';
        break;
      case 'EXTERNAL_FUNDS_ALL_OK':
        this.nbaContentService.setExternalFundsAllOkTexts(card);
        card.gotoUrl = '/overfoersel';
        break;
      case 'GIPP_TO_PLUS_ALL_OK':
        this.nbaContentService.setKrToPlusAllOkTexts(card);
        card.gotoUrl = '/konvertertilpfaplus';
        break;
      case 'LIFE_INSURANCE_ALL_OK':
        this.nbaContentService.setLifeInsuranceAllOkTexts(card);
        card.gotoUrl = this.INSURANCE_ADJUSTMENT_URL;
        break;
      case 'LOC_INSURANCE_ALL_OK':
        this.nbaContentService.setLOCInsuranceEstimateAllOkTexts(card);
        card.gotoUrl = this.INSURANCE_ADJUSTMENT_URL;
        break;
      case 'PENSION_ESTIMATE_ALL_OK':
        this.nbaContentService.setPensionEstimateAllOkTexts(
          card,
          values.confirmedPensionEstimate
        );
        card.gotoUrl = '/pensionstal';
        break;
      case 'BENEFICIARY_ALL_OK':
        this.nbaContentService.setBeneficiaryAllOkTexts(card);
        card.gotoUrl = '/mineforsikringer/begunstigelse';
        break;
      case 'BENEFICIARY_FILL_OUT_BENEFICIARY_DECLARATION':
        this.nbaContentService.setBeneficiaryFillOutBeneficiaryDeclarationTexts(
          card
        );
        card.gotoUrl = '/mineforsikringer/begunstigelse';
        break;
      case 'EXTERNAL_FUNDS_TRANSFER':
        this.nbaContentService.setExternalFundsTransferTexts(card);
        card.gotoUrl = '/overfoersel';
        break;
      case 'INVESTMENT_PROFILE_INCREASE_RISK':
        this.nbaContentService.setInvestmentProfileIncreaseRiskTexts(
          card,
          values.recommendationInvestmentProfile
        );
        card.gotoUrl = '/mineinvesteringer/' + values.primaryPolicy;
        break;
      case 'INVESTMENT_PROFILE_LOWER_RISK':
        this.nbaContentService.setInvestmentProfileLowerRiskTexts(
          card,
          values.recommendationInvestmentProfile
        );
        card.gotoUrl = '/mineinvesteringer/' + values.primaryPolicy;
        break;
      case 'TAKE_INVESTMENT_GUIDE':
        this.nbaContentService.setInvestmentProfileTakeInvestmentGuideTexts(
          card
        );
        card.gotoUrl = '/mineinvesteringer/guide';
        break;
      case 'INVESTMENT_CLIMATE_PLUS_INCREASE_SHARE':
        this.nbaContentService.setInvestmentClimatePlusIncreaseShareTexts(card);
        card.gotoUrl = '/mineinvesteringer/' + values.primaryPolicy;
        break;
      case 'INVESTMENT_CLIMATE_PLUS_LOWER_SHARE':
        this.nbaContentService.setInvestmentClimatePlusLowerShareTexts(card);
        card.gotoUrl = '/mineinvesteringer/' + values.primaryPolicy;
        break;
      case 'INVESTMENT_CONCEPT_CHANGE_CONCEPT_PFA_INVEST':
        this.nbaContentService.setInvestmentConceptChangePfaInvestTexts(card);
        card.gotoUrl = '/mineinvesteringer/' + values.primaryPolicy;
        break;
      case 'INVESTMENT_CONCEPT_CHANGE_CONCEPT_YOU_INVEST':
        this.nbaContentService.setInvestmentConceptChangeYouInvestTexts(card);
        card.gotoUrl = '/mineinvesteringer/' + values.primaryPolicy;
        break;
      case 'INVESTMENT_PROFILE_ALL_OK':
        this.nbaContentService.setInvestmentProfileAllOkTexts(card);
        card.gotoUrl = '/mineinvesteringer/' + values.primaryPolicy;
        break;
      case 'GIPP_TO_PLUS_TRANSFER_DEPOSIT': {
        this.nbaContentService.setKrToPlusTransferDepositTexts(card);
        card.gotoUrl = '/konvertertilpfaplus';
        break;
      }
      case 'LIFE_INSURANCE_INCREASE_COVERAGE':
        this.nbaContentService.setLifeInsuranceIncreaseCoverageTexts(card);
        card.gotoUrl = this.INSURANCE_ADJUSTMENT_URL;
        break;
      case 'LIFE_INSURANCE_LOWER_COVERAGE':
        this.nbaContentService.setLifeInsuranceLowerCoverageTexts(card);
        card.gotoUrl = this.INSURANCE_ADJUSTMENT_URL;
        break;
      case 'ENTER_MARITAL_STATUS':
      case 'ENTER_DEPENDENTS':
      case 'ENTER_WEALTH':
      case 'ENTER_DEBT':
      case 'ENTER_PARTNER_SALARY':
      case 'RETRIEVE_PENSION_INFO_REPORT':
        this.nbaContentService.setFillDataTexts(
          card,
          this.getDataOfferIds(offers)
        );
        break;
      case 'TAKE_INSURANCE_GUIDE':
        this.nbaContentService.setTakeInsuranceGuideTexts(card);
        card.gotoUrl = '/mineforsikringer/forsikringsguide';
        break;
      case 'LOC_AUTOMATIC_ADJUSTMENT_LOWER_TO_80':
        this.nbaContentService.setLOCInsuranceAutomatic80DecreaseTexts(card);
        card.gotoUrl = this.INSURANCE_ADJUSTMENT_URL;
        break;
      case 'LOC_AUTOMATIC_ADJUSTMENT_INCREASE_TO_80':
        this.nbaContentService.setLOCInsuranceAutomatic80IncreaseTexts(card);
        card.gotoUrl = this.INSURANCE_ADJUSTMENT_URL;
        break;
      case 'LOC_AUTOMATIC_ADJUSTMENT_LOWER_TO_100':
        this.nbaContentService.setLOCInsuranceAutomatic100DecreaseTexts(card);
        card.gotoUrl = this.INSURANCE_ADJUSTMENT_URL;
        break;
      case 'LOC_AUTOMATIC_ADJUSTMENT_INCREASE_TO_100':
        this.nbaContentService.setLOCInsuranceAutomatic100IncreaseTexts(card);
        card.gotoUrl = this.INSURANCE_ADJUSTMENT_URL;
        break;
      case 'LOC_AUTOMATIC_ADJUSTMENT_OUT_LOWER':
        this.nbaContentService.setLOCInsuranceAutomaticOutLowerTexts(card);
        card.gotoUrl = this.INSURANCE_ADJUSTMENT_URL;
        break;
      case 'LOC_AUTOMATIC_ADJUSTMENT_OUT_INCREASE':
        this.nbaContentService.setLOCInsuranceAutomaticOutIncreaseTexts(card);
        card.gotoUrl = this.INSURANCE_ADJUSTMENT_URL;
        break;
      case 'LOC_INSURANCE_INCREASE_COVERAGE':
        this.nbaContentService.setLOCInsuranceIncreaseCoverageTexts(card);
        card.gotoUrl = this.INSURANCE_ADJUSTMENT_URL;
        break;
      case 'LOC_INSURANCE_LOWER_COVERAGE':
        this.nbaContentService.setLOCInsuranceLowerCoverageTexts(card);
        card.gotoUrl = this.INSURANCE_ADJUSTMENT_URL;
        break;
      case 'PENSION_ESTIMATE_INCREASE_VOLUNTARY_DEPOSIT':
        this.nbaContentService.setPensionEstimateIncreaseVoluntaryDepositCardTexts(
          card
        );
        card.gotoUrl = '/pensionstal';
        break;
      default:
        throw new Error('unknown offerId');
    }
    return card;
  }

  private getDataOfferIds(
    sigmaOffers: DynamicAdvisingOffer[]
  ): DynamicAdvisingOfferidType[] {
    const offerIds: DynamicAdvisingOfferidType[] = [];
    sigmaOffers.forEach(sigmaOffer => {
      if (this.nextBestActionService.isDataOffer(sigmaOffer)) {
        offerIds.push(sigmaOffer.offerId);
      }
    });
    return offerIds;
  }

  private prepareRequestBodyAdjustTAEInsuranceEstimate(
    adjustInsuranceEstimate: TilpasForsikringstal,
    recommendedInsuranceEstimateTAE: number,
    locScale: LocScale
  ) {
    adjustInsuranceEstimate.sliderTAEloebende.locScale = locScale;
    adjustInsuranceEstimate.currentForsikringstalTAE =
      recommendedInsuranceEstimateTAE;
    adjustInsuranceEstimate.currentForsikringstalTAEBrutto =
      recommendedInsuranceEstimateTAE;
    adjustInsuranceEstimate.sliderTAEloebende.currentForsikringstal =
      recommendedInsuranceEstimateTAE;
    adjustInsuranceEstimate.sliderTAEloebende.currentForsikringstalTAEBrutto =
      recommendedInsuranceEstimateTAE;
    return adjustInsuranceEstimate;
  }

  private prepareRequestBodyAdjustLifeInsuranceEstimate(
    adjustLifeInsuranceEstimate: TilpasForsikringstal,
    pfaRecommendsLifeEstimate: number
  ): TilpasForsikringstal {
    adjustLifeInsuranceEstimate.sliderLiv.currentForsikringstal =
      pfaRecommendsLifeEstimate;
    adjustLifeInsuranceEstimate.sliderLiv.frivilligdaekning =
      adjustLifeInsuranceEstimate.sliderLiv.currentForsikringstal -
      adjustLifeInsuranceEstimate.sliderLiv.minimumsdaekning;

    return adjustLifeInsuranceEstimate;
  }
}
