<div class="spacing-unit--double-under">
  <div *ngIf="error">
    <co-alert class="full-page alert-center">
      <h3 [coContent]="'DL.VK01.C134'"></h3>
      <p [coContent]="'DL.VK01.C135'"></p>
      <button
        (click)="guideDone()"
        coContent="DL.VK01.C136"
        mat-raised-button
        class="button-secondary spacing-unit--over"
      ></button>
    </co-alert>
  </div>
  <mitpfa-insurance-guide
    *ngIf="!error && !adjustment"
    [isOnboarding]="true"
    (percentFinished)="updateStepper($event)"
    (error)="guideFailed()"
    (finished)="goToAdjustment()"
  >
  </mitpfa-insurance-guide>
  <mitpfa-insurance-adjustment
    *ngIf="!error && adjustment"
    [isOnboarding]="true"
    (exit)="guideDone()"
  >
  </mitpfa-insurance-adjustment>
</div>
