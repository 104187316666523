import { Injectable } from '@angular/core';
import { ContentUtilService, NumberFormatPipe } from '@pfa/core';
import {
  InsuranceAdjustmentState,
  InsuranceAdjustmentType,
  InsuranceAdjustmentValues,
  InsuranceGuideChecks
} from '../models/insurance-adjustment.model';
import { DaekningsType, TilpasForsikringstal } from '@pfa/gen';
import { InsuranceUnit } from '@pfa/models';
import { InsuranceUtilService } from '@pfaform';
import { Subject } from 'rxjs';
import {
  InputConfigItemLife,
  InputConfigLife
} from '@mitpfa/shared/models/insurance-adjustment-input-life.model';
import { InsuranceAdjustmentAdvisorInfo } from '@mitpfa/shared/models/insurance-adjustment-advisor-info.model';

@Injectable({
  providedIn: 'root'
})
export class InsuranceAdjustmentLifeService {
  followOurRecommendation: Subject<void>;
  adjustInsurance: Subject<void>;

  constructor(
    private readonly contentUtilService: ContentUtilService,
    private readonly numberFormat: NumberFormatPipe
  ) {
    this.followOurRecommendation = new Subject<void>();
    this.adjustInsurance = new Subject<void>();
  }

  resetAdjustInsuranceSubject(): void {
    this.adjustInsurance.complete();
    this.adjustInsurance = new Subject<void>();
  }

  resetFollowOurRecommendationSubject(): void {
    this.followOurRecommendation.complete();
    this.followOurRecommendation = new Subject<void>();
  }

  createInputConfig(
    insuranceState: InsuranceAdjustmentState,
    insuranceAdjustmentChangesOriginal: InsuranceAdjustmentValues,
    insuranceGuideChecks: InsuranceGuideChecks
  ): InputConfigLife {
    const lifeDataChild = insuranceState.insurance.tilpasForsikringstal;
    const tooltipContent = this.contentUtilService.getContent(
      lifeDataChild.sliderLiv.infoTekst,
      this.getInfoTextValues(lifeDataChild)
    );
    const result = {
      headerContentId: 'DL.TF01.C28',
      self: this.createInputConfigItemSelf(
        insuranceState,
        InsuranceAdjustmentType.LIFE,
        'DL.TF01.C31',
        insuranceAdjustmentChangesOriginal.lifeSelfAmount,
        tooltipContent
      )
    } as InputConfigLife;

    if (
      insuranceState.insurance.originalTilpasForsikringstal
        .daekningAendringBoernepension.skalVises
    ) {
      result.child = this.createInputConfigItemChild(
        lifeDataChild,
        InsuranceAdjustmentType.LIFE_CHILD,
        'DL.TF01.C112',
        insuranceAdjustmentChangesOriginal.lifeChildAmount,
        this.inputConfigChildTooltip(insuranceState)
      );
    }

    result.recommendation = insuranceState.insurance.pfaAnbefalerLivs.procent;
    const recommendationDiff = Math.abs(
      result.recommendation -
        insuranceState.insurance.tilpasForsikringstal.sliderLiv
          .currentForsikringstal
    );
    const followingRecommendation =
      recommendationDiff <= InsuranceUtilService.LIFE_VARIATION;
    result.showRecommendationButton =
      !insuranceGuideChecks.noGuide &&
      !insuranceGuideChecks.guideTooOld &&
      result.recommendation >= (result.self.amountMin ?? 0) &&
      result.recommendation <= (result.self.amountMax ?? 0) &&
      !followingRecommendation;

    result.followsRecommendation = followingRecommendation;

    result.showSpousePension =
      insuranceState.insurance.tilpasForsikringstal.skalAegtefaelleVises &&
      !!insuranceState.insurance.tilpasForsikringstal.aegtefaelle;
    result.canChangeSpousePension =
      result.showSpousePension && insuranceState.pensionCustomer.isRaadgiver;
    if (insuranceState.insurance.tilpasForsikringstal.aegtefaelle) {
      result.spousePension =
        insuranceState.insurance.tilpasForsikringstal.aegtefaelle;
    }

    return result;
  }

  private getInfoTextValues(data: TilpasForsikringstal): Array<string> {
    const lifeSalary =
      data.sliderLiv.infoTekst === 'DL.FG01.I609' ||
      data.sliderLiv.infoTekst === 'DL.FG01.I610'
        ? this.numberFormat.transform(data.loenFraMineOplysninger)
        : this.numberFormat.transform(data.aarsloen);
    return [
      this.numberFormat.transform(data.sliderLiv.minimumsdaekning),
      this.numberFormat.transform(data.sliderLiv.forsikringstalMax),
      lifeSalary,
      this.numberFormat.transform(data.sliderLiv.tillaegsDaekningBeloeb)
    ];
  }

  private inputConfigChildTooltip(
    insuranceState: InsuranceAdjustmentState
  ): string {
    const childCoverages: DaekningsType[] = [
      'BOERNEPENSION',
      'BOERNEPENSION_MED_RISIKOOPHOER'
    ];

    const originalTilpasForsikringstal =
      insuranceState.insurance.originalTilpasForsikringstal;
    const childExpiresAge =
      insuranceState.insuranceOverview.livsForsikring.livsForsikringer
        .filter(
          insurance =>
            insurance.aftaleNummer ===
              originalTilpasForsikringstal.policenummer &&
            childCoverages.includes(insurance.daekningsType) &&
            insurance.boerneUdloebAlder > 0
        )
        .map(agreement => agreement.boerneUdloebAlder)[0];
    if (childExpiresAge) {
      return this.contentUtilService.getContent(
        'DL.TF01.I112C',
        childExpiresAge.toString(10)
      );
    }
    return 'DL.TF01.I112A';
  }

  lifeAdjustmentAdvisorInfo(
    insuranceState: InsuranceAdjustmentState
  ): InsuranceAdjustmentAdvisorInfo {
    return {
      type: InsuranceAdjustmentType.LIFE,
      taxCode:
        insuranceState.insurance.tilpasForsikringstal.sliderLiv.valgtSkattekode,
      advisorData: {
        raadgiverAnbefaling:
          insuranceState.insurance.livRaadgiverAnbefaling?.raadgiverAnbefaling,
        raadgiverKommentar:
          insuranceState.insurance.livRaadgiverAnbefaling?.raadgiverKommentar,
        raadgiverAnbefalingOprettet:
          insuranceState.insurance.livRaadgiverAnbefaling
            ?.raadgiverAnbefalingOprettet,
        aktuelForsikringstal:
          insuranceState.insurance.originalTilpasForsikringstal
            .currentForsikringstalLiv,
        pfaAnbefaling:
          insuranceState.insurance.originalTilpasForsikringstal.pfaAnbefalerLiv,
        validAdviserRecommendation: true
      }
    };
  }

  private createInputConfigItemSelf(
    insuranceState: InsuranceAdjustmentState,
    type: InsuranceAdjustmentType,
    headerContentId: string,
    amountOriginal: number,
    tooltipContentId?: string
  ): InputConfigItemLife {
    const coverageChange =
      insuranceState.insurance.tilpasForsikringstal.sliderLiv;
    const coverageChangeOriginal =
      insuranceState.insurance.originalTilpasForsikringstal.sliderLiv;

    const noteAfterTax = this.contentUtilService.getContent(
      'DL.TF01.C150',
      this.numberFormat.transform(coverageChange.forsikringstalNettobeloeb, 0)
    );
    const noteAfterTaxOriginal = this.contentUtilService.getContent(
      'DL.TF01.C150',
      this.numberFormat.transform(
        coverageChangeOriginal.forsikringstalNettobeloeb,
        0
      )
    );
    let noteBeforeTax, noteBeforeTaxOriginal;
    if (!insuranceState.insurance.tilpasForsikringstal.bruttoTalLivIkkeVises) {
      noteBeforeTax = this.contentUtilService.getContent(
        'DL.TF01.C149',
        this.numberFormat.transform(
          coverageChange.forsikringstalBruttobeloeb,
          0
        )
      );
      noteBeforeTaxOriginal = this.contentUtilService.getContent(
        'DL.TF01.C149',
        this.numberFormat.transform(
          coverageChangeOriginal.forsikringstalBruttobeloeb,
          0
        )
      );
    }
    const notePrice = this.contentUtilService.getContent(
      'DL.TF01.C32',
      this.numberFormat.transform(coverageChange.pris / 12, 0)
    );
    const notePriceOriginal = this.contentUtilService.getContent(
      'DL.TF01.C32',
      this.numberFormat.transform(coverageChangeOriginal.pris / 12, 0)
    );

    return {
      type,
      amountOriginal,
      textContentId: headerContentId,
      tooltipContentId: tooltipContentId ? tooltipContentId : undefined,
      noteContentId: 'DL.TF01.C35',
      amountMin: Math.round(coverageChange.minimumsdaekning),
      amountMax: Math.round(coverageChange.forsikringstalMax),
      amount: coverageChange.currentForsikringstal,
      labelContentId: 'DL.TF01.C31',
      unit: InsuranceUnit.PERCENT,
      noteBeforeTax,
      noteBeforeTaxOriginal,
      noteAfterTaxOriginal,
      noteAfterTax,
      notePrice,
      notePriceOriginal
    } as InputConfigItemLife;
  }

  private createInputConfigItemChild(
    coverageChange: TilpasForsikringstal,
    type: InsuranceAdjustmentType,
    headerContentId: string,
    amountOriginal: number,
    tooltipContentId?: string
  ): InputConfigItemLife {
    const isPercent =
      coverageChange.daekningAendringBoernepension.enhedType === 'Procent';

    let noteBeforeTax;
    if (isPercent) {
      noteBeforeTax = this.contentUtilService.getContent(
        'DL.TF01.C149',
        this.numberFormat.transform(
          coverageChange.daekningAendringBoernepension.bruttobeloeb,
          0
        )
      );
    }

    const notePrice = this.contentUtilService.getContent(
      'DL.TF01.C32',
      this.numberFormat.transform(
        coverageChange.daekningAendringBoernepension.pris / 12,
        0
      )
    );

    return {
      type,
      amountOriginal,
      textContentId: headerContentId,
      tooltipContentId: tooltipContentId ? tooltipContentId : undefined,
      noteContentId: isPercent ? 'DL.TF01.C35' : 'DL.TF01.C30',
      amountMin: this.childMin(coverageChange),
      amountMax: this.childMax(coverageChange),
      amount: coverageChange.daekningAendringBoernepension.daekningsBeloeb,
      decimals: isPercent ? 1 : 0,
      labelContentId: 'DL.TF01.C31',
      unit: isPercent ? InsuranceUnit.PERCENT : InsuranceUnit.KR,
      noteBeforeTax,
      notePrice,
      recommendCreate: coverageChange.anbefalOpretBoernepension,
      recommendRemove: coverageChange.anbefalFravaelgBoernepension
    } as InputConfigItemLife;
  }

  private childMin(coverageChange: TilpasForsikringstal): number {
    if (coverageChange.daekningAendringBoernepension.enhedType === 'Beloeb') {
      return Math.ceil(
        coverageChange.daekningAendringBoernepension.daekningsspaend.startValue
      );
    }
    return this.roundToOneDecimal(
      coverageChange.daekningAendringBoernepension.daekningsspaend.startValue
    );
  }

  private childMax(coverageChange: TilpasForsikringstal): number {
    if (coverageChange.daekningAendringBoernepension.enhedType === 'Beloeb') {
      return Math.floor(
        coverageChange.daekningAendringBoernepension.daekningsspaend.endValue ??
          coverageChange.daekningAendringBoernepension.daekningsspaend
            .startValue
      );
    }
    return this.roundToOneDecimal(
      coverageChange.daekningAendringBoernepension.daekningsspaend.endValue ??
        coverageChange.daekningAendringBoernepension.daekningsspaend.startValue
    );
  }

  private roundToOneDecimal(value: number): number {
    return Math.round(10 * value) / 10;
  }
}
