/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Answer } from './answer';
import { AnswerSchema } from './answer';
import * as JoiObj from "joi";


    export const AnswerViewSchema = JoiObj.object({
        answers:
                JoiObj.array().items(
        
        JoiObj.link('#AnswerSchema')

        )
        

.required()


        ,
        questionnaireId:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        
    })
                .shared(AnswerSchema)
.id('AnswerViewSchema')

export interface AnswerView { 
    answers: Array<Answer>;
    questionnaireId: string;
}

