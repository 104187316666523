import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  Event,
  NavigationError,
  NavigationStart,
  Router
} from '@angular/router';
import { SharedErrorHandler } from '@pfa/feature/error';

@Component({
  selector: 'mitpfa-logging',
  template: ''
})
export class LoggingComponent implements OnInit {
  private readonly router: Router = inject(Router);
  private readonly sharedErrorHandler: SharedErrorHandler =
    inject(SharedErrorHandler);

  private readonly destroyRef: DestroyRef = inject(DestroyRef);

  ngOnInit(): void {
    this.handleRouterEvents();
  }

  private handleRouterEvents(): void {
    this.router.events
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(event => {
        this.handleNavigationStart(event);
        this.handleNavigationError(event);
      });
  }

  private handleNavigationStart(event: Event): void {
    if (
      event instanceof NavigationStart &&
      event.url === '/error' &&
      !this.router.getCurrentNavigation().extras.state
    ) {
      this.sharedErrorHandler.handleError(event);
    }
  }

  private handleNavigationError(event: Event): void {
    if (event instanceof NavigationError) {
      this.sharedErrorHandler.handleError(event);
    }
  }
}
