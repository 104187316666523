import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'mitpfa-investment-read-more',
  templateUrl: './read-more.component.html'
})
export class InvestmentReadMoreComponent {
  public modalType: string;

  constructor(@Inject(MAT_DIALOG_DATA) data) {
    this.modalType = data;
  }
}
