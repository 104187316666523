/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PossibleAnswer } from './possibleAnswer';
import { PossibleAnswerSchema } from './possibleAnswer';
import { IntegerInterval } from './integerInterval';
import { IntegerIntervalSchema } from './integerInterval';
import { AnswerType } from './answerType';
import { AnswerTypeSchema } from './answerType';
import * as JoiObj from "joi";


    export const QuestionSchema = JoiObj.object({
        answers:
                JoiObj.array().items(
        
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

        )
        

.required()


        ,
        headline:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        hint:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        id:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        integerInterval:
            
        JoiObj.link('#IntegerIntervalSchema')


.required()


        ,
        positiveSubQuestionAnswerRequired:
            
                    JoiObj.boolean()
                


.required()


        ,
        possibleAnswers:
                JoiObj.array().items(
        
        JoiObj.link('#PossibleAnswerSchema')

        )
        

.required()


        ,
        text:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        type:
            
        JoiObj.link('#AnswerTypeSchema')


.required()


        
    })
                .shared(IntegerIntervalSchema)
                .shared(AnswerTypeSchema)
.id('QuestionSchema')

export interface Question { 
    answers: Array<string>;
    headline: string;
    hint: string;
    id: string;
    integerInterval: IntegerInterval;
    positiveSubQuestionAnswerRequired: boolean;
    possibleAnswers: Array<PossibleAnswer>;
    text: string;
    type: AnswerType;
}
export namespace Question {
}


