/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AktivSammensaetningProfil } from './aktivSammensaetningProfil';
import { AktivSammensaetningProfilSchema } from './aktivSammensaetningProfil';
import { BaseVaegtObject } from './baseVaegtObject';
import { BaseVaegtObjectSchema } from './baseVaegtObject';
import { Forvaltning } from './forvaltning';
import { ForvaltningSchema } from './forvaltning';
import { SammeSaetning } from './sammeSaetning';
import { SammeSaetningSchema } from './sammeSaetning';
import * as JoiObj from "joi";


    export const AktivSammesaetningSchema = JoiObj.object({
        aar:
            
                    JoiObj.number()
                


.required()


        ,
        forvaltning:
            
        JoiObj.link('#ForvaltningSchema')


.required()


        ,
        forvaltning_klima_plus:
            
        JoiObj.link('#ForvaltningSchema')


.required()


        ,
        loadtid:
            
                    JoiObj.object()
                


.required()


        ,
        opdateredato:
            
                    JoiObj.object()
                


.required()


        ,
        profiler:
                JoiObj.array().items(
        
        JoiObj.link('#AktivSammensaetningProfilSchema')

        )
        

.required()


        ,
        profiler_klima_plus:
                JoiObj.array().items(
        
        JoiObj.link('#AktivSammensaetningProfilSchema')

        )
        

.required()


        ,
        regioner:
                JoiObj.array().items(
        
        JoiObj.link('#BaseVaegtObjectSchema')

        )
        

.required()


        ,
        regioner_klima_plus:
                JoiObj.array().items(
        
        JoiObj.link('#BaseVaegtObjectSchema')

        )
        

.required()


        ,
        restloebetid:
                JoiObj.array().items(
        
        JoiObj.link('#BaseVaegtObjectSchema')

        )
        

.required()


        ,
        restloebetid_klima_plus:
                JoiObj.array().items(
        
        JoiObj.link('#BaseVaegtObjectSchema')

        )
        

.required()


        ,
        sammesaetning:
            
        JoiObj.link('#SammeSaetningSchema')


.required()


        ,
        sammesaetning_klima_plus:
            
        JoiObj.link('#SammeSaetningSchema')


.required()


        ,
        sammesaetning_strategisk:
            
        JoiObj.link('#SammeSaetningSchema')


.required()


        ,
        sammesaetning_strategisk_klima_plus:
            
        JoiObj.link('#SammeSaetningSchema')


.required()


        ,
        sektorer:
                JoiObj.array().items(
        
        JoiObj.link('#BaseVaegtObjectSchema')

        )
        

.required()


        ,
        sektorer_klima_plus:
                JoiObj.array().items(
        
        JoiObj.link('#BaseVaegtObjectSchema')

        )
        

.required()


        
    })
                .shared(ForvaltningSchema)
                .shared(ForvaltningSchema)
                .shared(AktivSammensaetningProfilSchema)
                .shared(AktivSammensaetningProfilSchema)
                .shared(BaseVaegtObjectSchema)
                .shared(BaseVaegtObjectSchema)
                .shared(BaseVaegtObjectSchema)
                .shared(BaseVaegtObjectSchema)
                .shared(SammeSaetningSchema)
                .shared(SammeSaetningSchema)
                .shared(SammeSaetningSchema)
                .shared(SammeSaetningSchema)
                .shared(BaseVaegtObjectSchema)
                .shared(BaseVaegtObjectSchema)
.id('AktivSammesaetningSchema')

export interface AktivSammesaetning { 
    aar: number;
    forvaltning: Forvaltning;
    forvaltning_klima_plus: Forvaltning;
    loadtid: object;
    opdateredato: object;
    profiler: Array<AktivSammensaetningProfil>;
    profiler_klima_plus: Array<AktivSammensaetningProfil>;
    regioner: Array<BaseVaegtObject>;
    regioner_klima_plus: Array<BaseVaegtObject>;
    restloebetid: Array<BaseVaegtObject>;
    restloebetid_klima_plus: Array<BaseVaegtObject>;
    sammesaetning: SammeSaetning;
    sammesaetning_klima_plus: SammeSaetning;
    sammesaetning_strategisk: SammeSaetning;
    sammesaetning_strategisk_klima_plus: SammeSaetning;
    sektorer: Array<BaseVaegtObject>;
    sektorer_klima_plus: Array<BaseVaegtObject>;
}

