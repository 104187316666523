/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PfaInvesterer } from './pfaInvesterer';
import { PfaInvestererSchema } from './pfaInvesterer';
import { ServiceInfo } from './serviceInfo';
import { ServiceInfoSchema } from './serviceInfo';
import { Type } from './type';
import { TypeSchema } from './type';
import { PfaValgfri } from './pfaValgfri';
import { PfaValgfriSchema } from './pfaValgfri';
import { GippData } from './gippData';
import { GippDataSchema } from './gippData';
import { Tilstand } from './tilstand';
import { TilstandSchema } from './tilstand';
import { AktivFordeling } from './aktivFordeling';
import { AktivFordelingSchema } from './aktivFordeling';
import { Police } from './police';
import { PoliceSchema } from './police';
import { DuInvesterer } from './duInvesterer';
import { DuInvestererSchema } from './duInvesterer';
import * as JoiObj from "joi";


    export const InvesteringPoliceSchema = JoiObj.object({
        serviceInfo:
            
        JoiObj.link('#ServiceInfoSchema')

.allow(null)



        ,
        detaljeMulig:
            
                    JoiObj.boolean()
                


.required()


        ,
        harProfilG:
            
                    JoiObj.boolean()
                


.required()


        ,
        id:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        livnetAftale:
            
                    JoiObj.boolean()
                


.required()


        ,
        opsparing:
            
                    JoiObj.number()
                

.allow(null)



        ,
        orlovSlutDato:
            
            JoiObj.date()

.allow(null)



        ,
        tilstand:
            
        JoiObj.link('#TilstandSchema')


.required()


        ,
        tooltipContentId:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        type:
            
        JoiObj.link('#TypeSchema')


.required()


        ,
        typeTekstId:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        anbefalingAktier:
            
                    JoiObj.number()
                


.required()


        ,
        anbefalingDuInvesterer:
            
                    JoiObj.number()
                


.required()


        ,
        anbefalingObligationer:
            
                    JoiObj.number()
                


.required()


        ,
        anbefalingPfaInvesterer:
            
                    JoiObj.number()
                


.required()


        ,
        duInvesterer:
            
        JoiObj.link('#DuInvestererSchema')


.required()


        ,
        fejlet:
            
                    JoiObj.boolean()
                


.required()


        ,
        fordeling:
            
        JoiObj.link('#AktivFordelingSchema')


.required()


        ,
        fraAfkastTilDepotrenteUrl:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        fraAfkastTilDepotrenteUrlEngelsk:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        gippData:
                JoiObj.array().items(
        
        JoiObj.link('#GippDataSchema')

        )
        

.required()


        ,
        klimaPlusAndel:
            
                    JoiObj.number()
                


.required()


        ,
        klimaPlusMuligt:
            
                    JoiObj.boolean()
                


.required()


        ,
        kundekapitalBeloeb:
            
                    JoiObj.number()
                


.required()


        ,
        kursvaern:
            
                    JoiObj.boolean()
                


.required()


        ,
        kursvaernProcent:
            
                    JoiObj.number()
                


.required()


        ,
        maegler:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        maeglerTLF:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        maeglerbetjentLivnetVises:
            
                    JoiObj.boolean()
                


.required()


        ,
        omkostningsGruppe:
            
                    JoiObj.number()
                


.required()


        ,
        pfaInvesterer:
            
        JoiObj.link('#PfaInvestererSchema')


.required()


        ,
        pfaValgfri:
            
        JoiObj.link('#PfaValgfriSchema')


.required()


        ,
        renteGruppe:
            
                    JoiObj.number()
                


.required()


        ,
        risikoGruppe:
            
                    JoiObj.number()
                


.required()


        ,
        udestaaendeHandler:
            
                    JoiObj.boolean()
                


.required()


        ,
        underIndbetaling:
            
                    JoiObj.boolean()
                


.required()


        ,
        underUdbetaling:
            
                    JoiObj.boolean()
                


.required()


        
    })
                .shared(ServiceInfoSchema)
                .shared(TilstandSchema)
                .shared(TypeSchema)
                .shared(DuInvestererSchema)
                .shared(AktivFordelingSchema)
                .shared(GippDataSchema)
                .shared(PfaInvestererSchema)
                .shared(PfaValgfriSchema)
.id('InvesteringPoliceSchema')

export interface InvesteringPolice extends Police { 
    anbefalingAktier: number;
    anbefalingDuInvesterer: number;
    anbefalingObligationer: number;
    anbefalingPfaInvesterer: number;
    duInvesterer: DuInvesterer;
    fejlet: boolean;
    fordeling: AktivFordeling;
    fraAfkastTilDepotrenteUrl: string;
    fraAfkastTilDepotrenteUrlEngelsk: string;
    gippData: Array<GippData>;
    klimaPlusAndel: number;
    klimaPlusMuligt: boolean;
    kundekapitalBeloeb: number;
    kursvaern: boolean;
    kursvaernProcent: number;
    maegler: string;
    maeglerTLF: string;
    maeglerbetjentLivnetVises: boolean;
    omkostningsGruppe: number;
    pfaInvesterer: PfaInvesterer;
    pfaValgfri: PfaValgfri;
    renteGruppe: number;
    risikoGruppe: number;
    udestaaendeHandler: boolean;
    underIndbetaling: boolean;
    underUdbetaling: boolean;
}
export namespace InvesteringPolice {
}


