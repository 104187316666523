<svg
  id="Layer_1"
  data-name="Layer 1"
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 44 27"
>
  <g>
    <g>
      <g>
        <rect
          x="5.42"
          y=".68"
          width="14.42"
          height="20.55"
          rx=".49"
          ry=".49"
          fill="#c8c8c8"
          stroke-width="0"
        />
        <rect
          x="5.42"
          y=".68"
          width="14.42"
          height="20.55"
          rx=".49"
          ry=".49"
          fill="#c8c8c8"
          stroke-width="0"
        />
      </g>
      <g>
        <rect
          x="7.26"
          y="12.91"
          width="10.78"
          height=".62"
          rx=".29"
          ry=".29"
          fill="#c8c8c8"
          stroke-width="0"
        />
        <rect
          x="7.26"
          y="12.91"
          width="10.78"
          height=".62"
          rx=".29"
          ry=".29"
          fill="#c8c8c8"
          stroke-width="0"
        />
      </g>
      <g>
        <rect
          x="7.26"
          y="14.71"
          width="10.78"
          height=".62"
          rx=".29"
          ry=".29"
          fill="#c8c8c8"
          stroke-width="0"
        />
        <rect
          x="7.26"
          y="14.71"
          width="10.78"
          height=".62"
          rx=".29"
          ry=".29"
          fill="#c8c8c8"
          stroke-width="0"
        />
      </g>
      <g>
        <rect
          x="7.18"
          y="16.48"
          width="6.89"
          height=".62"
          rx=".29"
          ry=".29"
          fill="#c8c8c8"
          stroke-width="0"
        />
        <rect
          x="7.18"
          y="16.48"
          width="6.89"
          height=".62"
          rx=".29"
          ry=".29"
          fill="#c8c8c8"
          stroke-width="0"
        />
      </g>
      <g>
        <rect
          x="7.26"
          y="7.41"
          width="10.78"
          height=".62"
          rx=".29"
          ry=".29"
          fill="#c8c8c8"
          stroke-width="0"
        />
        <rect
          x="7.26"
          y="7.41"
          width="10.78"
          height=".62"
          rx=".29"
          ry=".29"
          fill="#c8c8c8"
          stroke-width="0"
        />
      </g>
      <g>
        <rect
          x="7.26"
          y="9.21"
          width="10.78"
          height=".62"
          rx=".29"
          ry=".29"
          fill="#c8c8c8"
          stroke-width="0"
        />
        <rect
          x="7.26"
          y="9.21"
          width="10.78"
          height=".62"
          rx=".29"
          ry=".29"
          fill="#c8c8c8"
          stroke-width="0"
        />
      </g>
      <g>
        <rect
          x="7.25"
          y="10.98"
          width="10.78"
          height=".62"
          rx=".29"
          ry=".29"
          fill="#c8c8c8"
          stroke-width="0"
        />
        <rect
          x="7.25"
          y="10.98"
          width="10.78"
          height=".62"
          rx=".29"
          ry=".29"
          fill="#c8c8c8"
          stroke-width="0"
        />
      </g>
      <g>
        <g>
          <rect
            x=".58"
            y="3.07"
            width="14.42"
            height="20.55"
            rx=".49"
            ry=".49"
            fill="#c8c8c8"
            stroke-width="0"
          />
          <path
            d="M14.51,23.26H2.12c-.24,0-.73.1-.95,0-.4-.17-.23-.77-.23-1.18V5.78c0-.54-.21-1.61,0-2.12.17-.41.89-.24,1.31-.24h8.61c1.08,0,2.21-.1,3.29,0,.53.05.52.13.52.66v17.21c0,.35.24,1.85-.23,1.98-.44.12-.25.8.19.68.85-.23.75-.94.75-1.64V5.08c0-.51.09-1.11-.01-1.62-.19-.92-1.17-.75-1.88-.75h-4.25c-2.64,0-5.37-.2-8,0-.73.06-.96.48-.98,1.14-.03.93,0,1.87,0,2.79v9.72c0,2.19-.19,4.47,0,6.65.07.78.59.94,1.26.94h13.03c.45,0,.45-.71,0-.71Z"
            fill="#c8c8c8"
            stroke-width="0"
          />
        </g>
        <g>
          <path
            d="M14.52,23.68H1.06c-.3,0-.55-.25-.55-.56V3.56c0-.31.25-.56.55-.56h13.46c.3,0,.55.25.55.56v19.56c0,.31-.25.56-.55.56ZM1.06,3.13c-.23,0-.42.19-.42.43v19.56c0,.23.19.43.42.43h13.46c.23,0,.42-.19.42-.43V3.56c0-.23-.19-.43-.42-.43H1.06Z"
            fill="#c8c8c8"
            stroke-width="0"
          />
          <g>
            <path
              d="M14.52,23.33H2.13c-.26,0-.72.09-.97,0-.47-.17-.3-.83-.3-1.26V5.67c0-.57-.18-1.46,0-2.01.16-.51.94-.31,1.39-.31h8.71c1.03,0,2.13-.1,3.16,0,.56.06.59.19.59.74v17.24c0,.36.26,1.9-.29,2.01-.44.09-.26.77.19.68.87-.18.81-.94.81-1.63V5.11c0-.52.08-1.12-.01-1.64-.18-.96-1.11-.82-1.85-.82h-4.24c-2.69,0-5.43-.17-8.12,0C-.39,2.74.16,5.46.16,6.54v9.75C.16,18.52,0,20.8.16,23.02c.05.78.56,1.01,1.26,1.02,1.16.02,2.32,0,3.47,0h9.63c.45,0,.45-.71,0-.71Z"
              fill="#c8c8c8"
              stroke-width="0"
            />
            <path
              d="M.97,2.79c-.78.22-.68.92-.68,1.56v17.2c0,.51-.09,1.1,0,1.61.17.91,1.12.73,1.82.73h4.26c2.64,0,5.37.21,8.01,0,.69-.05.89-.45.91-1.08.03-.92,0-1.84,0-2.76v-9.73c0-2.19.18-4.47,0-6.65-.06-.73-.52-.89-1.15-.9-1.19,0-2.38,0-3.57,0H1.06c-.45,0-.45.71,0,.71h12.41c.19,0,.82-.11.99,0,.25.17.13.79.13,1.1v17.69c0,.18.08.58,0,.74-.16.33-.86.17-1.24.17h-4.04c-2.6,0-5.27.19-7.86,0-.52-.04-.45-.1-.45-.63V5.36c0-.28-.2-1.79.16-1.89.44-.12.25-.8-.19-.68Z"
              fill="#c8c8c8"
              stroke-width="0"
            />
          </g>
        </g>
      </g>
      <g>
        <rect
          x="2.41"
          y="15.3"
          width="10.78"
          height=".62"
          rx=".29"
          ry=".29"
          fill="#c8c8c8"
          stroke-width="0"
        />
        <rect
          x="2.41"
          y="15.3"
          width="10.78"
          height=".62"
          rx=".29"
          ry=".29"
          fill="#c8c8c8"
          stroke-width="0"
        />
      </g>
      <g>
        <rect
          x="2.41"
          y="17.09"
          width="10.78"
          height=".62"
          rx=".29"
          ry=".29"
          fill="#c8c8c8"
          stroke-width="0"
        />
        <rect
          x="2.41"
          y="17.09"
          width="10.78"
          height=".62"
          rx=".29"
          ry=".29"
          fill="#c8c8c8"
          stroke-width="0"
        />
      </g>
      <g>
        <rect
          x="2.32"
          y="18.86"
          width="6.89"
          height=".62"
          rx=".29"
          ry=".29"
          fill="#c8c8c8"
          stroke-width="0"
        />
        <rect
          x="2.32"
          y="18.86"
          width="6.89"
          height=".62"
          rx=".29"
          ry=".29"
          fill="#c8c8c8"
          stroke-width="0"
        />
      </g>
      <g>
        <rect
          x="2.34"
          y="6.85"
          width="5.8"
          height="1.28"
          rx=".63"
          ry=".63"
          fill="#c8c8c8"
          stroke-width="0"
        />
        <rect
          x="2.34"
          y="6.85"
          width="5.8"
          height="1.28"
          rx=".63"
          ry=".63"
          fill="#c8c8c8"
          stroke-width="0"
        />
      </g>
      <g>
        <rect
          x="2.41"
          y="9.8"
          width="10.78"
          height=".62"
          rx=".29"
          ry=".29"
          fill="#c8c8c8"
          stroke-width="0"
        />
        <rect
          x="2.41"
          y="9.8"
          width="10.78"
          height=".62"
          rx=".29"
          ry=".29"
          fill="#c8c8c8"
          stroke-width="0"
        />
      </g>
      <g>
        <rect
          x="2.41"
          y="11.59"
          width="10.78"
          height=".62"
          rx=".29"
          ry=".29"
          fill="#c8c8c8"
          stroke-width="0"
        />
        <rect
          x="2.41"
          y="11.59"
          width="10.78"
          height=".62"
          rx=".29"
          ry=".29"
          fill="#c8c8c8"
          stroke-width="0"
        />
      </g>
      <g>
        <rect
          x="2.39"
          y="13.36"
          width="10.78"
          height=".62"
          rx=".29"
          ry=".29"
          fill="#c8c8c8"
          stroke-width="0"
        />
        <rect
          x="2.39"
          y="13.36"
          width="10.78"
          height=".62"
          rx=".29"
          ry=".29"
          fill="#c8c8c8"
          stroke-width="0"
        />
      </g>
    </g>
    <g>
      <rect
        x="5.42"
        y=".68"
        width="14.42"
        height="20.55"
        rx=".49"
        ry=".49"
        fill="#e6e6e6"
        stroke-width="0"
      />
      <rect
        x="7.26"
        y="12.91"
        width="10.78"
        height=".62"
        rx=".29"
        ry=".29"
        fill="#666"
        stroke-width="0"
      />
      <rect
        x="7.26"
        y="14.71"
        width="10.78"
        height=".62"
        rx=".29"
        ry=".29"
        fill="#666"
        stroke-width="0"
      />
      <rect
        x="7.18"
        y="16.48"
        width="6.89"
        height=".62"
        rx=".29"
        ry=".29"
        fill="#c8c8c8"
        stroke-width="0"
      />
      <rect
        x="7.26"
        y="7.41"
        width="10.78"
        height=".62"
        rx=".29"
        ry=".29"
        fill="#666"
        stroke-width="0"
      />
      <rect
        x="7.26"
        y="9.21"
        width="10.78"
        height=".62"
        rx=".29"
        ry=".29"
        fill="#666"
        stroke-width="0"
      />
      <rect
        x="7.25"
        y="10.98"
        width="10.78"
        height=".62"
        rx=".29"
        ry=".29"
        fill="#666"
        stroke-width="0"
      />
      <g>
        <g>
          <rect
            x=".58"
            y="3.07"
            width="14.42"
            height="20.55"
            rx=".49"
            ry=".49"
            fill="#fff"
            stroke-width="0"
          />
          <path
            d="M14.51,23.26H2.12c-.24,0-.73.1-.95,0-.4-.17-.23-.77-.23-1.18V5.78c0-.54-.21-1.61,0-2.12.17-.41.89-.24,1.31-.24h8.61c1.08,0,2.21-.1,3.29,0,.53.05.52.13.52.66v17.21c0,.35.24,1.85-.23,1.98-.44.12-.25.8.19.68.85-.23.75-.94.75-1.64V5.08c0-.51.09-1.11-.01-1.62-.19-.92-1.17-.75-1.88-.75h-4.25c-2.64,0-5.37-.2-8,0-.73.06-.96.48-.98,1.14-.03.93,0,1.87,0,2.79v9.72c0,2.19-.19,4.47,0,6.65.07.78.59.94,1.26.94h13.03c.45,0,.45-.71,0-.71Z"
            fill="#ccc"
            stroke-width="0"
          />
        </g>
        <g>
          <path
            d="M14.52,23.68H1.06c-.3,0-.55-.25-.55-.56V3.56c0-.31.25-.56.55-.56h13.46c.3,0,.55.25.55.56v19.56c0,.31-.25.56-.55.56ZM1.06,3.13c-.23,0-.42.19-.42.43v19.56c0,.23.19.43.42.43h13.46c.23,0,.42-.19.42-.43V3.56c0-.23-.19-.43-.42-.43H1.06Z"
            fill="#fff"
            stroke-width="0"
          />
          <g>
            <path
              d="M14.52,23.33H2.13c-.26,0-.72.09-.97,0-.47-.17-.3-.83-.3-1.26V5.67c0-.57-.18-1.46,0-2.01.16-.51.94-.31,1.39-.31h8.71c1.03,0,2.13-.1,3.16,0,.56.06.59.19.59.74v17.24c0,.36.26,1.9-.29,2.01-.44.09-.26.77.19.68.87-.18.81-.94.81-1.63V5.11c0-.52.08-1.12-.01-1.64-.18-.96-1.11-.82-1.85-.82h-4.24c-2.69,0-5.43-.17-8.12,0C-.39,2.74.16,5.46.16,6.54v9.75C.16,18.52,0,20.8.16,23.02c.05.78.56,1.01,1.26,1.02,1.16.02,2.32,0,3.47,0h9.63c.45,0,.45-.71,0-.71Z"
              fill="#ccc"
              stroke-width="0"
            />
            <path
              d="M.97,2.79c-.78.22-.68.92-.68,1.56v17.2c0,.51-.09,1.1,0,1.61.17.91,1.12.73,1.82.73h4.26c2.64,0,5.37.21,8.01,0,.69-.05.89-.45.91-1.08.03-.92,0-1.84,0-2.76v-9.73c0-2.19.18-4.47,0-6.65-.06-.73-.52-.89-1.15-.9-1.19,0-2.38,0-3.57,0H1.06c-.45,0-.45.71,0,.71h12.41c.19,0,.82-.11.99,0,.25.17.13.79.13,1.1v17.69c0,.18.08.58,0,.74-.16.33-.86.17-1.24.17h-4.04c-2.6,0-5.27.19-7.86,0-.52-.04-.45-.1-.45-.63V5.36c0-.28-.2-1.79.16-1.89.44-.12.25-.8-.19-.68Z"
              fill="#ccc"
              stroke-width="0"
            />
          </g>
        </g>
      </g>
      <rect
        x="2.41"
        y="15.3"
        width="10.78"
        height=".62"
        rx=".29"
        ry=".29"
        fill="#c8c8c8"
        stroke-width="0"
      />
      <rect
        x="2.41"
        y="17.09"
        width="10.78"
        height=".62"
        rx=".29"
        ry=".29"
        fill="#c8c8c8"
        stroke-width="0"
      />
      <rect
        x="2.32"
        y="18.86"
        width="6.89"
        height=".62"
        rx=".29"
        ry=".29"
        fill="#c8c8c8"
        stroke-width="0"
      />
      <rect
        x="2.34"
        y="6.85"
        width="5.8"
        height="1.28"
        rx=".63"
        ry=".63"
        fill="#900"
        stroke-width="0"
      />
      <rect
        x="2.41"
        y="9.8"
        width="10.78"
        height=".62"
        rx=".29"
        ry=".29"
        fill="#c8c8c8"
        stroke-width="0"
      />
      <rect
        x="2.41"
        y="11.59"
        width="10.78"
        height=".62"
        rx=".29"
        ry=".29"
        fill="#c8c8c8"
        stroke-width="0"
      />
      <rect
        x="2.39"
        y="13.36"
        width="10.78"
        height=".62"
        rx=".29"
        ry=".29"
        fill="#c8c8c8"
        stroke-width="0"
      />
    </g>
  </g>
  <g>
    <rect
      x="11.91"
      y="8.51"
      width="28.04"
      height="18.51"
      rx=".78"
      ry=".78"
      fill="#c8c8c8"
      stroke-width="0"
    />
    <path
      d="M38.94,27l-26.17.02c-.48,0-.86-.39-.86-.86V9.76c0-.33.36-.53.64-.35l12.45,9.59c.56.41,1.48.46,2,0l.82-.8c.11-.13.3-.16.44-.06l10.89,8.22c.27.21.13.64-.21.64Z"
      fill="#afafaf"
      stroke-width="0"
    />
    <path
      d="M38.76,25.23l-10-7.58,10.06-7.63c.17-.13.2-.37.07-.54-.13-.17-.37-.2-.54-.07l-12.46,9.45-12.46-9.45c-.17-.13-.41-.09-.54.07-.13.17-.09.41.07.54l10.04,7.61-10.02,7.6c-.17.13-.2.37-.07.54h0c.13.17.37.2.54.07l10.19-7.73,2.01,1.53c.05.04.12.06.18.07,0,0,0,0,.01,0,.01,0,.03,0,.04,0,.01,0,.03,0,.04,0,0,0,0,0,.01,0,.06,0,.13-.03.18-.07l1.99-1.51,10.17,7.71c.17.13.41.09.54-.07.13-.17.09-.41-.07-.54Z"
      fill="#666"
      stroke-width="0"
    />
    <circle cx="38.98" cy="8.58" r="4.83" fill="#008685" stroke-width="0" />
    <path
      d="M39.31,5.7s0,0-.01,0c0,0,0,0-.01,0h-.63c-.23,0-.43.19-.43.42s.19.42.43.42h.24v4.51c0,.23.19.42.43.42s.43-.19.43-.42v-4.93c0-.23-.19-.42-.43-.42Z"
      fill="#fff"
      stroke-width="0"
    />
  </g>
</svg>
