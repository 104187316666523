/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SurveyQuestion } from './surveyQuestion';
import { SurveyQuestionSchema } from './surveyQuestion';
import * as JoiObj from "joi";


    export const GroupSchema = JoiObj.object({
        hideFromTargetGroups:
                JoiObj.array().items(
        
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

        )
        
.allow(null)



        ,
        id:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        introText:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        questions:
                JoiObj.array().items(
        
        JoiObj.link('#SurveyQuestionSchema')

        )
        
.allow(null)



        ,
        showToTargetGroups:
                JoiObj.array().items(
        
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

        )
        
.allow(null)



        ,
        title:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        
    })
                .shared(SurveyQuestionSchema)
.id('GroupSchema')

export interface Group { 
    hideFromTargetGroups?: Array<string>;
    id: string;
    introText?: string;
    questions?: Array<SurveyQuestion>;
    showToTargetGroups?: Array<string>;
    title: string;
}

