<div *ngIf="!isLoading" class="contact-info" [ngSwitch]="footerContactType">
  <co-footer-contact-standard
    *ngSwitchCase="'standard'"
    [isLight]="isLight"
    [isLetPension]="isLetPension"
    [user]="user"
  ></co-footer-contact-standard>
  <co-footer-contact-advisor
    *ngSwitchCase="'advisor'"
    [advantages]="advantages"
  ></co-footer-contact-advisor>
  <co-footer-contact-broker
    *ngSwitchCase="'broker'"
    [brokerInfo]="brokerInfo"
  ></co-footer-contact-broker>
</div>
