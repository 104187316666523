/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { EksternOverfoerselsType } from './eksternOverfoerselsType';
import { EksternOverfoerselsTypeSchema } from './eksternOverfoerselsType';
import * as JoiObj from "joi";


    export const DelOverfoerselSchema = JoiObj.object({
        kontant:
            
                    JoiObj.number()
                

.allow(null)



        ,
        kontonr:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        opsparing:
            
                    JoiObj.number()
                

.allow(null)



        ,
        regnr:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        valg:
            
        JoiObj.link('#EksternOverfoerselsTypeSchema')

.allow(null)



        
    })
                .shared(EksternOverfoerselsTypeSchema)
.id('DelOverfoerselSchema')

export interface DelOverfoersel { 
    kontant?: number;
    kontonr: string;
    opsparing?: number;
    regnr: string;
    valg?: EksternOverfoerselsType;
}
export namespace DelOverfoersel {
}


