import { Component, Input } from '@angular/core';
import { DigitalSalgPrisListeRaekke } from '@pfa/gen';

@Component({
  selector: 'co-coverage-price',
  templateUrl: './coverage-price.component.html'
})
export class CoveragePriceComponent {
  @Input() coverage: DigitalSalgPrisListeRaekke;
  @Input() digits = 0;
}
