import { Injectable } from '@angular/core';
import { ContentUtilService } from '@pfa/core';
import { NbaCard } from './nba-card.model';
import { DynamicAdvisingOfferidType } from '@pfa/gen';

@Injectable({
  providedIn: 'root'
})
export class NbaContentService {
  constructor(private readonly contentUtilService: ContentUtilService) {}

  public setBeneficiaryFillOutBeneficiaryDeclarationTexts(card: NbaCard): void {
    card.headline = this.getContent('DL.NB01.C337');
    card.bodyText = this.getContent('DL.NB01.C338');
    card.gotoText = 'DL.NB01.C339';
  }

  public setLOCInsuranceEstimateAllOkTexts(card: NbaCard): void {
    card.headline = this.getContent('DL.NB01.C394');
    card.bodyText = this.getContent('DL.NB01.C395');
    card.gotoText = 'DL.NB01.C396';
  }

  public setLOCInsuranceIncreaseCoverageTexts(card: NbaCard): void {
    card.headline = this.getContent('DL.NB01.C401');
    card.bodyText = this.getContent('DL.NB01.C402');
    card.gotoText = 'DL.NB01.C403';
  }

  public setLOCInsuranceLowerCoverageTexts(card: NbaCard): void {
    card.headline = this.getContent('DL.NB01.C404');
    card.bodyText = this.getContent('DL.NB01.C405');
    card.gotoText = 'DL.NB01.C406';
  }

  public setLOCInsuranceAutomatic80IncreaseTexts(card: NbaCard): void {
    card.headline = this.getContent('DL.NB01.C410');
    card.bodyText = this.getContent('DL.NB01.C411');
    card.gotoText = 'DL.NB01.C412';
  }

  public setLOCInsuranceAutomatic80DecreaseTexts(card: NbaCard): void {
    card.headline = this.getContent('DL.NB01.C413');
    card.bodyText = this.getContent('DL.NB01.C414');
    card.gotoText = 'DL.NB01.C415';
  }

  public setLOCInsuranceAutomatic100IncreaseTexts(card: NbaCard): void {
    card.headline = this.getContent('DL.NB01.C416');
    card.bodyText = this.getContent('DL.NB01.C417');
    card.gotoText = 'DL.NB01.C418';
  }

  public setLOCInsuranceAutomatic100DecreaseTexts(card: NbaCard): void {
    card.headline = this.getContent('DL.NB01.C419');
    card.bodyText = this.getContent('DL.NB01.C420');
    card.gotoText = 'DL.NB01.C421';
  }

  public setLOCInsuranceAutomaticOutIncreaseTexts(card: NbaCard): void {
    card.headline = this.getContent('DL.NB01.C422');
    card.bodyText = this.getContent('DL.NB01.C423');
    card.gotoText = 'DL.NB01.C424';
  }

  public setLOCInsuranceAutomaticOutLowerTexts(card: NbaCard): void {
    card.headline = this.getContent('DL.NB01.C425');
    card.bodyText = this.getContent('DL.NB01.C426');
    card.gotoText = 'DL.NB01.C427';
  }

  setPensionEstimateAllOkTexts(
    card: NbaCard,
    hasConfirmedPensionEstimate: boolean
  ): void {
    if (hasConfirmedPensionEstimate) {
      card.headline = this.getContent('DL.NB01.C385');
      card.bodyText = this.getContent('DL.NB01.C386');
      card.gotoText = 'DL.NB01.C387';
    } else {
      card.headline = this.getContent('DL.NB01.C443');
      card.bodyText = this.getContent('DL.NB01.C444');
      card.gotoText = 'DL.NB01.C445';
    }
  }

  public setBeneficiaryAllOkTexts(card: NbaCard): void {
    card.headline = this.getContent('DL.NB01.C397');
    card.bodyText = this.getContent('DL.NB01.C398');
    card.gotoText = 'DL.NB01.C399';
  }

  public setKrToPlusAllOkTexts(card: NbaCard): void {
    card.headline = this.getContent('DL.NB01.C428');
    card.bodyText = this.getContent('DL.NB01.C429');
    card.gotoText = 'DL.NB01.C430';
  }

  public setInvestmentProfileIncreaseRiskTexts(
    card: NbaCard,
    recommendationInvestmentProfile: string
  ): void {
    card.headline = this.getContent('DL.NB01.C352');
    card.bodyText = this.getContent('DL.NB01.C353', [
      recommendationInvestmentProfile
    ]);
    card.gotoText = 'DL.NB01.C354';
  }

  public setInvestmentProfileTakeInvestmentGuideTexts(card: NbaCard): void {
    card.headline = this.getContent('DL.NB01.C346');
    card.bodyText = this.getContent('DL.NB01.C347');
    card.gotoText = 'DL.NB01.C348';
  }

  public setInvestmentProfileLowerRiskTexts(
    card: NbaCard,
    recommendationInvestmentProfile: string
  ): void {
    card.headline = this.getContent('DL.NB01.C349');
    card.bodyText = this.getContent('DL.NB01.C350', [
      recommendationInvestmentProfile
    ]);
    card.gotoText = 'DL.NB01.C351';
  }

  public setInvestmentClimatePlusIncreaseShareTexts(card: NbaCard): void {
    card.headline = this.getContent('DL.NB01.C373');
    card.bodyText = this.getContent('DL.NB01.C374');
    card.gotoText = 'DL.NB01.C375';
  }

  public setInvestmentClimatePlusLowerShareTexts(card: NbaCard): void {
    card.headline = this.getContent('DL.NB01.C376');
    card.bodyText = this.getContent('DL.NB01.C377');
    card.gotoText = 'DL.NB01.C378';
  }

  public setInvestmentConceptChangePfaInvestTexts(card: NbaCard): void {
    card.headline = this.getContent('DL.NB01.C379');
    card.bodyText = this.getContent('DL.NB01.C380');
    card.gotoText = 'DL.NB01.C381';
  }

  public setInvestmentConceptChangeYouInvestTexts(card: NbaCard): void {
    card.headline = this.getContent('DL.NB01.C382');
    card.bodyText = this.getContent('DL.NB01.C383');
    card.gotoText = 'DL.NB01.C384';
  }

  public setInvestmentProfileAllOkTexts(card: NbaCard): void {
    card.headline = this.getContent('DL.NB01.C388');
    card.bodyText = this.getContent('DL.NB01.C389');
    card.gotoText = 'DL.NB01.C390';
  }

  public setPensionEstimateIncreaseVoluntaryDepositCardTexts(
    card: NbaCard
  ): void {
    card.headline = this.getContent('DL.NB01.C367');
    card.bodyText = this.getContent('DL.NB01.C368');
    card.gotoText = 'DL.NB01.C369';
  }

  public setLifeInsuranceIncreaseCoverageTexts(card: NbaCard): void {
    card.headline = this.getContent('DL.NB01.C358');
    card.bodyText = this.getContent('DL.NB01.C359');
    card.gotoText = 'DL.NB01.C360';
  }

  public setLifeInsuranceLowerCoverageTexts(card: NbaCard): void {
    card.headline = this.getContent('DL.NB01.C361');
    card.bodyText = this.getContent('DL.NB01.C362');
    card.gotoText = 'DL.NB01.C363';
  }

  public setTakeInsuranceGuideTexts(card: NbaCard): void {
    card.headline = this.getContent('DL.NB01.C364');
    card.bodyText = this.getContent('DL.NB01.C365');
    card.gotoText = 'DL.NB01.C366';
  }

  public setLifeInsuranceAllOkTexts(card: NbaCard): void {
    card.headline = this.getContent('DL.NB01.C391');
    card.bodyText = this.getContent('DL.NB01.C392');
    card.gotoText = 'DL.NB01.C393';
  }

  public setExternalFundsAllOkTexts(card: NbaCard): void {
    card.headline = this.getContent('DL.NB01.C431');
    card.bodyText = this.getContent('DL.NB01.C432');
    card.gotoText = 'DL.NB01.C433';
  }

  public setKrToPlusTransferDepositTexts(card: NbaCard): void {
    card.headline = this.getContent('DL.NB01.C434');
    card.bodyText = this.getContent('DL.NB01.C435');
    card.gotoText = 'DL.NB01.C436';
  }

  public setFillDataTexts(
    card: NbaCard,
    relevantDataOfferIdsForFillData: DynamicAdvisingOfferidType[]
  ): void {
    card.headline = this.getContent('DL.NB01.C437');
    card.bodyText = this.getContent('DL.NB01.C438');
    card.gotoText = 'DL.NB01.C439';
    card.relevantOfferIds = structuredClone(relevantDataOfferIdsForFillData);
  }

  public setExternalFundsTransferTexts(card: NbaCard): void {
    card.headline = this.getContent('DL.NB01.C340');
    card.bodyText = this.getContent('DL.NB01.C341');
    card.gotoText = 'DL.NB01.C342';
  }

  public setDataCollectionAllOkTexts(card: NbaCard): void {
    card.headline = this.getContent('DL.NB01.C446');
    card.bodyText = this.getContent('DL.NB01.C447');
    card.gotoText = 'DL.NB01.C448';
  }

  private getContent(textId: string, values?: string[]): string {
    return this.contentUtilService.getContent(textId, values);
  }
}
