/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Residence } from './residence';
import { ResidenceSchema } from './residence';
import * as JoiObj from "joi";


    export const ResidencesSchema = JoiObj.object({
        realEstateAnswer:
            
                    JoiObj.boolean()
                


.required()


        ,
        realEstates:
                JoiObj.array().items(
        
        JoiObj.link('#ResidenceSchema')

        )
        

.required()


        
    })
                .shared(ResidenceSchema)
.id('ResidencesSchema')

export interface Residences { 
    realEstateAnswer: boolean;
    realEstates: Array<Residence>;
}

