import { Injectable } from '@angular/core';
import {
  CanDeactivate,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Observable } from 'rxjs';

@Injectable()
export class UnsavedChangesGuard
  implements CanDeactivate<ComponentCanDeactivate>
{
  canDeactivate(
    component: ComponentCanDeactivate,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState: RouterStateSnapshot
  ): boolean | Observable<boolean> {
    return (
      nextState.url.startsWith('/error') ||
      nextState.url.startsWith('/logout') ||
      component.canDeactivate(nextState)
    );
  }
}

export interface ComponentCanDeactivate {
  canDeactivate: (
    nextState: RouterStateSnapshot
  ) => boolean | Observable<boolean>;
}
