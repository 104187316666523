/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ServiceInfo } from './serviceInfo';
import { ServiceInfoSchema } from './serviceInfo';
import { PayoutTableRow } from './payoutTableRow';
import { PayoutTableRowSchema } from './payoutTableRow';
import { ResourceModel } from './resourceModel';
import { ResourceModelSchema } from './resourceModel';
import { PayoutTableColumn } from './payoutTableColumn';
import { PayoutTableColumnSchema } from './payoutTableColumn';
import * as JoiObj from "joi";


    export const PayoutTableSchema = JoiObj.object({
        serviceInfo:
            
        JoiObj.link('#ServiceInfoSchema')

.allow(null)



        ,
        columns:
                JoiObj.array().items(
        
        JoiObj.link('#PayoutTableColumnSchema')

        )
        

.required()


        ,
        rows:
                JoiObj.array().items(
        
        JoiObj.link('#PayoutTableRowSchema')

        )
        

.required()


        ,
        wealthRows:
                JoiObj.array().items(
        
        JoiObj.link('#PayoutTableRowSchema')

        )
        

.required()


        ,
        withoutPayoutRows:
                JoiObj.array().items(
        
        JoiObj.link('#PayoutTableRowSchema')

        )
        

.required()


        
    })
                .shared(ServiceInfoSchema)
                .shared(PayoutTableColumnSchema)
                .shared(PayoutTableRowSchema)
                .shared(PayoutTableRowSchema)
                .shared(PayoutTableRowSchema)
.id('PayoutTableSchema')

export interface PayoutTable extends ResourceModel { 
    columns: Array<PayoutTableColumn>;
    rows: Array<PayoutTableRow>;
    wealthRows: Array<PayoutTableRow>;
    withoutPayoutRows: Array<PayoutTableRow>;
}

