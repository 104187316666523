/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BeneficiarySimple } from './beneficiarySimple';
import { BeneficiarySimpleSchema } from './beneficiarySimple';
import { BeneficiaryType } from './beneficiaryType';
import { BeneficiaryTypeSchema } from './beneficiaryType';
import { BeneficiaryPerson } from './beneficiaryPerson';
import { BeneficiaryPersonSchema } from './beneficiaryPerson';
import * as JoiObj from "joi";


    export const BeneficiaryDraftRequestSchema = JoiObj.object({
        beneficiaryData:
            
        JoiObj.link('#BeneficiarySimpleSchema')


.required()


        ,
        beneficiaryType:
            
        JoiObj.link('#BeneficiaryTypeSchema')


.required()


        ,
        persons:
                JoiObj.array().items(
        
        JoiObj.link('#BeneficiaryPersonSchema')

        )
        

.required()


        
    })
                .shared(BeneficiarySimpleSchema)
                .shared(BeneficiaryTypeSchema)
                .shared(BeneficiaryPersonSchema)
.id('BeneficiaryDraftRequestSchema')

export interface BeneficiaryDraftRequest { 
    beneficiaryData: BeneficiarySimple;
    beneficiaryType: BeneficiaryType;
    persons: Array<BeneficiaryPerson>;
}
export namespace BeneficiaryDraftRequest {
}


