<mat-dialog-content class="dialog-desktop">
  <button
    mat-raised-button
    class="no"
    (click)="cancel()"
    [coContent]="'DL.PP01.C191'"
  ></button>
  <div class="text--wrapper">
    <p class="text--manchet" [coContent]="'DL.PP01.C571'"></p>
    <div [coContent]="'DL.PP01.C572'"></div>
    <div class="text--additional" [coContent]="'DL.PP01.C573'"></div>
  </div>
  <button
    mat-raised-button
    class="yes"
    (click)="accept()"
    [coContent]="'DL.PP01.C190'"
  ></button>
</mat-dialog-content>
<mat-dialog-content class="dialog-device">
  <div class="text--wrapper">
    <p class="text--manchet" [coContent]="'DL.PP01.C571'"></p>
    <div [coContent]="'DL.PP01.C572'"></div>
    <div class="text--additional" [coContent]="'DL.PP01.C573'"></div>
  </div>
  <div class="button--wrapper">
    <button
      mat-raised-button
      class="no"
      (click)="cancel()"
      [coContent]="'DL.PP01.C191'"
    ></button>
    <button
      mat-raised-button
      class="yes"
      (click)="accept()"
      [coContent]="'DL.PP01.C190'"
    ></button>
  </div>
</mat-dialog-content>
