/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const CustomerActivityRoleSchema = JoiObj.string().valid(
        'pensionskunde'
        ,
        'somPensionskunde'
        ,
        'maegler'
        ,
        'raadgiver'
        ,
        'administrator'
        ,
        'boligkontaktperson'
        ,
        'anonymous'
        ,
        'pfaboligadmin'
        ,
        'skadesanmeldelseadmin'
        ,
        'virksomhedmedarbejde'
        ,
        'antagelsesspecialist'
        ,
        'pensionsmedarbejder'
        
    )
.id('CustomerActivityRoleSchema')

export type CustomerActivityRole = 'pensionskunde' | 'somPensionskunde' | 'maegler' | 'raadgiver' | 'administrator' | 'boligkontaktperson' | 'anonymous' | 'pfaboligadmin' | 'skadesanmeldelseadmin' | 'virksomhedmedarbejde' | 'antagelsesspecialist' | 'pensionsmedarbejder';

export const CustomerActivityRole = {
    Pensionskunde: 'pensionskunde' as CustomerActivityRole,
    SomPensionskunde: 'somPensionskunde' as CustomerActivityRole,
    Maegler: 'maegler' as CustomerActivityRole,
    Raadgiver: 'raadgiver' as CustomerActivityRole,
    Administrator: 'administrator' as CustomerActivityRole,
    Boligkontaktperson: 'boligkontaktperson' as CustomerActivityRole,
    Anonymous: 'anonymous' as CustomerActivityRole,
    Pfaboligadmin: 'pfaboligadmin' as CustomerActivityRole,
    Skadesanmeldelseadmin: 'skadesanmeldelseadmin' as CustomerActivityRole,
    Virksomhedmedarbejde: 'virksomhedmedarbejde' as CustomerActivityRole,
    Antagelsesspecialist: 'antagelsesspecialist' as CustomerActivityRole,
    Pensionsmedarbejder: 'pensionsmedarbejder' as CustomerActivityRole
};

