import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import {RestCacheEntry} from "./RestCacheEntry";
import {GenericStore} from "./GenericStore";
import * as JoiObj from "joi";

import {
        CreateDigitalIdentityRequest,
        CreateDigitalIdentityRequestSchema,
        DigitalAccessOptions,
        DigitalAccessOptionsSchema,
        UpdateDigitalAccessRequest,
        UpdateDigitalAccessRequestSchema
} from '../model/models';

import { CustomerdigitalidentityService } from './customerdigitalidentity.service';


@Injectable({
    providedIn: 'root'
})
export class CustomerdigitalidentityStore extends GenericStore {

            private customerdigitalidentityoptionsIndexCache: Map<string, RestCacheEntry<DigitalAccessOptions>> = new Map<string, RestCacheEntry<DigitalAccessOptions>>();

    constructor(private customerdigitalidentityService: CustomerdigitalidentityService) {
        super();
    }

    invalidateAll(): void {
                
                
                
                this.invalidatecustomerdigitalidentityoptionsIndex();
                
                
    }





                    public customerdigitalidentityAccessPut(updateDigitalAccessRequest?: UpdateDigitalAccessRequest) : Observable<any> {
                        return this.customerdigitalidentityService.customerdigitalidentityAccessPut(updateDigitalAccessRequest).pipe(
                            map(value => {
                                
                                
                                return value;
                            })
                        );
                    }



                public customerdigitalidentityOptionsGet() : Observable<DigitalAccessOptions> {
                    let hash = "0";
                    if (this.customerdigitalidentityoptionsIndexCache.has(hash)) {
                        return this.customerdigitalidentityoptionsIndexCache.get(hash)!.observable;
                    } else {
                        let customerdigitalidentityoptionsIndexSubject: BehaviorSubject<0> = new BehaviorSubject(0);
                        let customerdigitalidentityoptionsIndex = this.initiateStream(customerdigitalidentityoptionsIndexSubject, this.customerdigitalidentityService.customerdigitalidentityOptionsGet(), DigitalAccessOptionsSchema);
                        this.customerdigitalidentityoptionsIndexCache.set(hash, new RestCacheEntry<DigitalAccessOptions>(customerdigitalidentityoptionsIndex, customerdigitalidentityoptionsIndexSubject));
                        return customerdigitalidentityoptionsIndex;
                    }
                }

                public invalidatecustomerdigitalidentityoptionsIndex() {
                    this.customerdigitalidentityoptionsIndexCache.forEach((cacheEntry) => {
                        cacheEntry.subject.next(0);
                    })
                }








                    public customerdigitalidentityPost(createDigitalIdentityRequest?: CreateDigitalIdentityRequest) : Observable<any> {
                        return this.customerdigitalidentityService.customerdigitalidentityPost(createDigitalIdentityRequest).pipe(
                            map(value => {
                                
                                
                                return value;
                            })
                        );
                    }




}
