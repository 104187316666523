import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { OnboardingStore, SystemadministrationStore } from '@pfa/gen';

@Injectable()
export class OnboardingGuard implements CanActivate {
  constructor(
    private systemadministrationStore: SystemadministrationStore,
    private readonly onboardingStore: OnboardingStore,
    private router: Router
  ) {}

  canActivate(): Observable<boolean | UrlTree> {
    return combineLatest({
      ks: this.systemadministrationStore.systemadministrationHentkillswitchesadminGet(),
      apl: this.onboardingStore.onboardingGet()
    }).pipe(
      map(all => {
        if (all.ks.killswitchDetailsActivateYourRecommendation.open) {
          let allButExternalTransferDone = true;
          all.apl.items.forEach(item => {
            if (!item.checked && item.name !== 'EXTERNAL_TRANSFER') {
              allButExternalTransferDone = false;
            }
          });
          if (!allButExternalTransferDone) {
            return true;
          } else {
            return this.router.parseUrl('/');
          }
        } else {
          return this.router.parseUrl('/');
        }
      })
    );
  }
}
