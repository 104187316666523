import { BrugerStore } from '@pfa/gen';
import { Injectable, inject } from '@angular/core';
import { CanActivateFn, Router, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

// The only purpose of this guard is to detect situation when advisor is trying to get to MitPFA
// without having customer chosen. It might be session timeout or logout from MitPFA.
@Injectable({
  providedIn: 'root'
})
class CustomerGuardService {
  constructor(
    private readonly brugerStore: BrugerStore,
    private readonly router: Router
  ) {}

  canActivate(): Observable<boolean | UrlTree> {
    return this.brugerStore.brugerGet().pipe(
      map(navnOgStatusData => {
        if (navnOgStatusData.pensionskundeIkkeValgt) {
          if (navnOgStatusData.isRaadgiver) {
            window.location.href = '/pro';
            return false;
          } else {
            return this.router.parseUrl('/forbidden');
          }
        }
        return true;
      }),
      // if there was an error while getting user data then ignore it.
      catchError(() => of(true))
    );
  }
}

// CanActivateFn is used because CanActivate is deprecated
export const CustomerGuard: CanActivateFn = (): Observable<boolean | UrlTree> =>
  inject(CustomerGuardService).canActivate();
