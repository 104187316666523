import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { InvestmentApiService } from '@pfa/api';
import { Observable } from 'rxjs';
import { PoliceMulighederSvar } from '@pfa/gen';

@Injectable({
  providedIn: 'root'
})
export class InvestmentPolicyResolver implements Resolve<PoliceMulighederSvar> {
  constructor(private investmentApiService: InvestmentApiService) {}

  resolve(
    route: ActivatedRouteSnapshot
  ): PoliceMulighederSvar | Observable<PoliceMulighederSvar> {
    const policyId = route.paramMap.get('policyId');
    return this.investmentApiService.getPolicy(policyId);
  }
}
