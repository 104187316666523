import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'mitpfa-customer-survey-summary',
  templateUrl: './customer-survey-summary.component.html',
  styleUrls: ['./customer-survey-summary.component.scss']
})
export class CustomerSurveySummaryComponent {
  @Output()
  close: EventEmitter<void> = new EventEmitter();
}
