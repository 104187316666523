/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SimulatorTileEnum } from './simulatorTileEnum';
import { SimulatorTileEnumSchema } from './simulatorTileEnum';
import * as JoiObj from "joi";


    export const SignereRequestObjSchema = JoiObj.object({
        mitIdSig:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        revisionKey:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        sprog:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        tile:
            
        JoiObj.link('#SimulatorTileEnumSchema')


.required()


        
    })
                .shared(SimulatorTileEnumSchema)
.id('SignereRequestObjSchema')

export interface SignereRequestObj { 
    mitIdSig: string;
    revisionKey: string;
    sprog: string;
    tile: SimulatorTileEnum;
}
export namespace SignereRequestObj {
}


