/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const TegningsmaadeSchema = JoiObj.string().valid(
        'FIRMA'
        ,
        'PRIVAT'
        ,
        'PRIVAT_FIRMA'
        
    )
.id('TegningsmaadeSchema')

export type Tegningsmaade = 'FIRMA' | 'PRIVAT' | 'PRIVAT_FIRMA';

export const Tegningsmaade = {
    Firma: 'FIRMA' as Tegningsmaade,
    Privat: 'PRIVAT' as Tegningsmaade,
    PrivatFirma: 'PRIVAT_FIRMA' as Tegningsmaade
};

