/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { LinieElement } from './linieElement';
import { LinieElementSchema } from './linieElement';
import * as JoiObj from "joi";


    export const OpsparingsformPoliceSchema = JoiObj.object({
        forventetIndbetalingGTzero:
            
                    JoiObj.boolean()
                


.required()


        ,
        linieElementList:
                JoiObj.array().items(
        
        JoiObj.link('#LinieElementSchema')

        )
        

.required()


        ,
        pensionsordning:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        
    })
                .shared(LinieElementSchema)
.id('OpsparingsformPoliceSchema')

export interface OpsparingsformPolice { 
    forventetIndbetalingGTzero: boolean;
    linieElementList: Array<LinieElement>;
    pensionsordning: string;
}

