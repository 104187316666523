import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import {RestCacheEntry} from "./RestCacheEntry";
import {GenericStore} from "./GenericStore";
import * as JoiObj from "joi";

import {
        CustomerSatisfaction,
        CustomerSatisfactionSchema
} from '../model/models';

import { BrugerundersoegelseService } from './brugerundersoegelse.service';


@Injectable({
    providedIn: 'root'
})
export class BrugerundersoegelseStore extends GenericStore {


    constructor(private brugerundersoegelseService: BrugerundersoegelseService) {
        super();
    }

    invalidateAll(): void {
                
                
    }




                    public brugerundersoegelseSvarPost(customerSatisfaction?: CustomerSatisfaction) : Observable<any> {
                        return this.brugerundersoegelseService.brugerundersoegelseSvarPost(customerSatisfaction).pipe(
                            map(value => {
                                
                                
                                return value;
                            })
                        );
                    }




}
