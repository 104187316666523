<div mat-dialog-title>
  <h3 coContent="DL.OS01.C262"></h3>
  <co-icon-times (click)="closePopup()"></co-icon-times>
</div>

<mat-dialog-content>
  <co-loading [show]="!initialized"></co-loading>

  <ng-container *ngIf="initialized">
    <div coContent="DL.OS01.C263"></div>

    <mat-form-field class="form-field-light yearpicker" color="accent">
      <mat-label [coContent]="'DL.OS01.C430'" class="label"></mat-label>
      <mat-select [value]="chosenYear" (selectionChange)="selectYear($event)">
        <mat-option
          *ngFor="let year of savingsTransferData.transfer.aar"
          [value]="year"
        >
          <span>{{ year }}</span>
        </mat-option>
      </mat-select>
    </mat-form-field>

    <div class="spacing-unit--under">
      <co-alert *ngIf="noTransferDetails" class="alert-warning">
        <span [coContent]="'DL.OS01.C448'"></span>
      </co-alert>
    </div>

    <div
      *ngIf="!noTransferDetails"
      class="spacing-unit--under"
      [coContent]="'DL.OS01.C263a'"
      [coContentSubst]="
        (savingsTransferData.transfer.total | numberFormat: 2) +
        ' ' +
        ('Global.kroner' | content) +
        '|' +
        savingsTransferData.transfer.policenummer
      "
    ></div>

    <table
      *ngIf="!noTransferDetails"
      class="pfa-table pfa-table--compact table-responsive align-left--column-1 align-right--column-2"
    >
      <tr>
        <td
          class="text--bold"
          coContent="DL.OS01.C203"
          [coContentSubst]="transferDetails.samletVaerdiPrimoTotalDato"
        ></td>
        <td class="text--bold">
          {{ transferDetails.samletVaerdiPrimoTotal | numberFormat: 2 }}
          <span [coContent]="'Global.kroner'"></span>
        </td>
      </tr>
      <tr *ngIf="transferDetails.samletIndbetaling !== 0">
        <td [coContent]="'DL.OS01.C210'"></td>
        <td>
          {{ transferDetails.samletIndbetaling | numberFormat: 2 }}
          <span [coContent]="'Global.kroner'"></span>
        </td>
      </tr>
      <tr *ngIf="transferDetails.indbetalingViaIndbetalingsSikring !== 0">
        <td [coContent]="'DL.OS01.C211'"></td>
        <td>
          {{
            transferDetails.indbetalingViaIndbetalingsSikring | numberFormat: 2
          }}
          <span [coContent]="'Global.kroner'"></span>
        </td>
      </tr>
      <tr *ngIf="transferDetails.arbejdsMarkedsBidrag !== 0">
        <td [coContent]="'DL.OS01.C212'"></td>
        <td>
          {{ transferDetails.arbejdsMarkedsBidrag | numberFormat: 2 }}
          <span [coContent]="'Global.kroner'"></span>
        </td>
      </tr>
      <tr *ngIf="transferDetails.udbetaling !== 0">
        <td [coContent]="'DL.OS01.C213'"></td>
        <td>
          {{ transferDetails.udbetaling | numberFormat: 2 }}
          <span [coContent]="'Global.kroner'"></span>
        </td>
      </tr>
      <tr *ngIf="transferDetails.fremrykketAfgiftKapitalPension !== 0">
        <td [coContent]="'DL.OS01.C214'"></td>
        <td>
          {{ transferDetails.fremrykketAfgiftKapitalPension | numberFormat: 2 }}
          <span [coContent]="'Global.kroner'"></span>
        </td>
      </tr>
      <tr *ngIf="transferDetails.udbetalingTilAegtefaelleSamlever !== 0">
        <td [coContent]="'DL.OS01.C215'"></td>
        <td>
          {{
            transferDetails.udbetalingTilAegtefaelleSamlever | numberFormat: 2
          }}
          <span [coContent]="'Global.kroner'"></span>
        </td>
      </tr>
      <tr *ngIf="transferDetails.forsikringsPris !== 0">
        <td [coContent]="'DL.OS01.C216'"></td>
        <td>
          {{ transferDetails.forsikringsPris | numberFormat: 2 }}
          <span [coContent]="'Global.kroner'"></span>
        </td>
      </tr>
      <tr *ngIf="transferDetails.administrationsPris !== 0">
        <td [coContent]="'DL.OS01.C221'"></td>
        <td>
          {{ transferDetails.administrationsPris | numberFormat: 2 }}
          <span [coContent]="'Global.kroner'"></span>
        </td>
      </tr>
      <tr *ngIf="transferDetails.oevrigePoster !== 0">
        <td [coContent]="'DL.OS01.C241'"></td>
        <td>
          {{ transferDetails.oevrigePoster | numberFormat: 2 }}
          <span [coContent]="'Global.kroner'"></span>
        </td>
      </tr>
      <tr *ngIf="transferDetails.afkast !== 0">
        <td [coContent]="'DL.OS01.C257'"></td>
        <td>
          {{ transferDetails.afkast | numberFormat: 2 }}
          <span [coContent]="'Global.kroner'"></span>
        </td>
      </tr>
      <tr *ngIf="transferDetails.afregnetPensionsAfkastSkat !== 0">
        <td [coContent]="'DL.OS01.C258'"></td>
        <td>
          {{ transferDetails.afregnetPensionsAfkastSkat | numberFormat: 2 }}
          <span [coContent]="'Global.kroner'"></span>
        </td>
      </tr>
      <tr class="border-top--thick">
        <td
          class="text--bold"
          [coContent]="'DL.OS01.C203'"
          [coContentSubst]="transferDetails.samletVaerdiUltimoTotalDato"
        ></td>
        <td class="text--bold">
          {{ transferDetails.samletVaerdiUltimoTotal | numberFormat: 2 }}
          <span [coContent]="'Global.kroner'"></span>
        </td>
      </tr>
    </table>
  </ng-container>
</mat-dialog-content>
