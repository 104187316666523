<div
  *ngIf="
    recommendationDetail &&
    investmentGuideInfo.isGuideCompleted &&
    !investmentGuideInfo.isGuideMoreThan3YearOld &&
    !recommendationComplete
  "
  class="wrapper"
  [ngClass]="{ onboarding: isOnboardingFlow }"
>
  <co-card-animated-stack [activeIndex]="activeCardIndex">
    <co-card-animated-stack-item>
      <co-card class="card--small">
        <mitpfa-investment-recommendation-intro
          [investmentGuideInfo]="investmentGuideInfo"
          [basket]="basket"
          (emitNextCard)="nextCard($event)"
        ></mitpfa-investment-recommendation-intro>
      </co-card>
    </co-card-animated-stack-item>
    <co-card-animated-stack-item>
      <co-card class="card--slim">
        <mitpfa-investment-recommendation-profile
          [detail]="recommendationDetail"
          [inForceSelections]="inForceSelections"
          [isOnboardingFlow]="isOnboardingFlow"
          (emitNextCard)="nextCard($event, 'profile')"
          (navigateConcept)="navigateConcept.emit()"
          (notDecided)="skip()"
        ></mitpfa-investment-recommendation-profile>

        <co-accordion
          card-additional
          class="stick-bottom"
          [title]="'DL.INVGU01.C629'"
          [expanded]="profileExpanded"
          accordionName="IG recommendation"
          (emitExpand)="setExpanded($event, 'profile')"
        >
          <mitpfa-investment-recommendation-overview
            [data]="overviewData"
          ></mitpfa-investment-recommendation-overview>
        </co-accordion>
      </co-card>
    </co-card-animated-stack-item>
    <co-card-animated-stack-item>
      <co-card class="card--slim">
        <mitpfa-investment-recommendation-climate
          [isOnboardingFlow]="isOnboardingFlow"
          [detail]="recommendationDetail"
          [inForceSelections]="inForceSelections"
          (emitNextCard)="nextCard($event, 'climate')"
        ></mitpfa-investment-recommendation-climate>

        <co-accordion
          card-additional
          class="stick-bottom"
          [title]="'DL.INVGU01.C629'"
          [expanded]="climateExpanded"
          accordionName="IG recommendation - climate"
          (emitExpand)="setExpanded($event, 'climate')"
        >
          <mitpfa-investment-recommendation-overview
            [data]="overviewData"
            [showClimate]="true"
          ></mitpfa-investment-recommendation-overview>
        </co-accordion>
      </co-card>
    </co-card-animated-stack-item>
    <co-card-animated-stack-item>
      <co-card class="card--small">
        <mitpfa-investment-recommendation-approve
          [orgPolicy]="orgPolicy"
          [detail]="recommendationDetail"
          [isProfileChanged]="isProfileChanged"
          [isClimateChanged]="isClimateChanged"
          [isOnboardingFlow]="isOnboardingFlow"
          (complete)="complete()"
        ></mitpfa-investment-recommendation-approve>
      </co-card>
    </co-card-animated-stack-item>
  </co-card-animated-stack>

  <div class="align--center">
    <div class="card-progress">{{ activeCardIndex + 1 }}/4</div>

    <div *ngIf="activeCardIndex > 0" class="spacing-unit--quarter-over">
      <button
        mat-raised-button
        class="button-primary--level3"
        (click)="previousCard()"
      >
        <co-icon-arrow-left class="icon icon--arrow-left"></co-icon-arrow-left>
        <span coContent="Global.Tilbage"></span>
      </button>
    </div>
  </div>
</div>

<div
  *ngIf="!recommendationDetail && investmentGuideInfo.isGuideCompleted"
  class="spacing-unit--under"
>
  <co-card class="card--inline align--center">
    <h4 coContent="DL.INV.C682"></h4>
    <div coContent="DL.INV.C683"></div>
  </co-card>
</div>

<co-alert
  *ngIf="
    (policy.type !== 'Gennemsnit' ||
      (policy.type === 'Gennemsnit' && policy.harProfilG)) &&
    (!investmentGuideInfo.isGuideCompleted ||
      (investmentGuideInfo.isGuideCompleted &&
        investmentGuideInfo.isGuideMoreThan3YearOld))
  "
  class="spacing-unit--under"
>
  <p
    class="text--bold"
    [coContent]="
      investmentGuideInfo.isGuideCompleted ? 'DL.INV.C706' : 'DL.INV.C707'
    "
  ></p>
  <div
    [coContent]="
      investmentGuideInfo.isGuideCompleted ? 'DL.INV.C442' : 'DL.INV.C358'
    "
  ></div>
  <div
    [coContent]="
      investmentGuideInfo.isGuideCompleted ? 'DL.INV.C359' : 'DL.INV.C359'
    "
  ></div>

  <div class="spacing-unit--over">
    <button
      mat-raised-button
      (click)="takeInvestmentGuide()"
      coContent="DL.INV.C361"
    ></button>
  </div>
</co-alert>
