/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const BlanketStatusSchema = JoiObj.string().valid(
        'MANUEL'
        ,
        'SENDT_TIL_EKSPEDITION'
        ,
        'EKSPEDERET'
        ,
        'SLETTET'
        
    )
.id('BlanketStatusSchema')

export type BlanketStatus = 'MANUEL' | 'SENDT_TIL_EKSPEDITION' | 'EKSPEDERET' | 'SLETTET';

export const BlanketStatus = {
    Manuel: 'MANUEL' as BlanketStatus,
    SendtTilEkspedition: 'SENDT_TIL_EKSPEDITION' as BlanketStatus,
    Ekspederet: 'EKSPEDERET' as BlanketStatus,
    Slettet: 'SLETTET' as BlanketStatus
};

