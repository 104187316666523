/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { InvestmentFundChange } from './investmentFundChange';
import { InvestmentFundChangeSchema } from './investmentFundChange';
import * as JoiObj from "joi";


    export const InvestmentChangesPreviewSchema = JoiObj.object({
        priceForChange:
            
                    JoiObj.number()
                


.required()


        ,
        fundsToSell:
                JoiObj.array().items(
        
        JoiObj.link('#InvestmentFundChangeSchema')

        )
        

.required()


        ,
        fundsToBuy:
                JoiObj.array().items(
        
        JoiObj.link('#InvestmentFundChangeSchema')

        )
        

.required()


        
    })
                .shared(InvestmentFundChangeSchema)
                .shared(InvestmentFundChangeSchema)
.id('InvestmentChangesPreviewSchema')

export interface InvestmentChangesPreview { 
    priceForChange: number;
    fundsToSell: Array<InvestmentFundChange>;
    fundsToBuy: Array<InvestmentFundChange>;
}

