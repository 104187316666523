/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const RiskWillingnessSchema = JoiObj.string().valid(
        'LOW'
        ,
        'AVERAGE'
        ,
        'HIGH'
        
    )
.id('RiskWillingnessSchema')

export type RiskWillingness = 'LOW' | 'AVERAGE' | 'HIGH';

export const RiskWillingness = {
    Low: 'LOW' as RiskWillingness,
    Average: 'AVERAGE' as RiskWillingness,
    High: 'HIGH' as RiskWillingness
};

