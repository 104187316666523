import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HentKategoriListSvar, HentKorrespondenceListSvar } from '@pfa/gen';
import { ContentUtilService } from '@pfa/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class MessagingService {
  private nonCustomer: boolean;
  private channel: string;
  private endpointHost: string;
  private showContactInputEnabled: boolean;
  private attachmentAccess = {};
  private readonly MAX_ATTACHMENT_ACESSS = 5;
  constructor(
    private http: HttpClient,
    private contentUtil: ContentUtilService
  ) {}

  setNonCustomer(nonCustomer: boolean): void {
    this.nonCustomer = nonCustomer;
  }

  isNonCustomer(): boolean {
    return this.nonCustomer;
  }

  setChannel(channel) {
    this.channel = channel;
  }

  getChannel() {
    return this.channel;
  }

  setEndpointHost(endpointHost) {
    this.endpointHost = endpointHost;
  }

  getEndpointHost() {
    return this.endpointHost;
  }

  setShowContactInputEnabled(showContactInputEnabled: boolean) {
    return (this.showContactInputEnabled = showContactInputEnabled);
  }

  isShowContactInputEnabled() {
    return this.showContactInputEnabled;
  }

  handleThread(unread) {
    return res => {
      if (!res.fejlet && unread) {
        const thread = res.thread;
        this.notify(thread.id).subscribe(() => {});
      }
      return this.parseMessages(res);
    };
  }

  notify(id) {
    return this.http.post(
      '/ds/api/messaging/mitpfa/threads/' + id + '/notify',
      {}
    );
  }
  parseMessages(res) {
    if (!res.fejlet) {
      const thread = res.thread;
      thread.messages?.forEach(message => {
        this.parseMessage(message);
        this.checkAttachmentAccess(message.attachments);
      });
      return thread;
    } else {
      return null;
    }
  }

  parseMessage(message) {
    let m = message.body.replace(/target="(?!_mitpfa)[^<]*?"/gm, '');
    m = m.replace(/(<a[^<]*)(?!target)([^<]*?>)/gm, '$1 target="_blank" $2');
    m = m.replace(/target="_mitpfa"/gm, 'target="_self"');
    m = this.contentUtil.parseContentString(m);
    message.formattedBody = m;
    return message;
  }

  stripHtml(text) {
    return text ? String(text).replace(/<[^>]+>/gm, '') : '';
  }

  getHeightMinusMenu($window) {
    const innerHeight = $window.innerHeight;
    const innerWidth = $window.innerWidth;
    let menuHeight = 268;

    if (this.channel === 'mitpfa') {
      if (innerWidth <= 519) {
        //palm
        return innerHeight - 168;
      }
      return innerHeight - 268;
    }

    if (innerWidth <= 667) {
      // lappalm
      menuHeight = 168;
    }
    if (innerWidth <= 519) {
      //palm
      menuHeight = 148;
    }

    if (innerHeight <= 777) {
      menuHeight = 258;
      if (innerWidth <= 667) {
        // lappalm
        menuHeight = 158;
      }
      if (innerWidth <= 519) {
        //palm
        menuHeight = 138;
      }
    }
    if (innerHeight <= 666) {
      menuHeight = 248;
      if (innerWidth <= 667) {
        // lappalm
        menuHeight = 148;
      }
      if (innerWidth <= 519) {
        //palm
        menuHeight = 128;
      }
    }
    return innerHeight - menuHeight;
  }

  getThreads(includeArchiveData: boolean) {
    return this.http.get<HentKorrespondenceListSvar>(
      '/ds/api/messaging/' +
        this.channel +
        '/threads/list/' +
        includeArchiveData
    );
  }

  getThreadsForChannel(channel: string, includeArchiveData: boolean) {
    return this.http.get<HentKorrespondenceListSvar>(
      '/ds/api/messaging/' + channel + '/threads/list/' + includeArchiveData
    );
  }

  getThread(id, unread?) {
    return this.http
      .get<any>('/ds/api/messaging/' + this.channel + '/threads/' + id)
      .pipe(map(this.handleThread(unread)));
  }

  private sendMessageToUrl(
    url,
    thread,
    attachments?,
    formId?,
    title?,
    category?,
    contactInfo?
  ) {
    if (thread.message) {
      thread.message.body = this.stripHtml(thread.message.body);
    }
    if (thread.title) {
      thread.title = this.stripHtml(thread.title);
    }
    const formData = new FormData();
    attachments.forEach(attachment => {
      formData.append('files', attachment.rawFile, attachment.rawFile.name);
    });
    if (formId) {
      formData.append('formId', this.stripHtml(formId));
    }
    if (title) {
      formData.append('title', this.stripHtml(title));
    }
    if (category) {
      formData.append('category', this.stripHtml(category));
    }
    if (contactInfo) {
      formData.append('contactInfo', JSON.stringify(contactInfo));
    }
    formData.append('sendMessage', JSON.stringify(thread));
    return this.http.post<any>(url, formData);
  }

  replyMessage(id, message, attachments) {
    return this.sendMessageToUrl(
      '/ds/api/messaging/' + this.channel + '/threads/' + id,
      {
        message: message
      },
      attachments
    );
  }

  sendMessage(thread, attachments, contactInfo) {
    return this.sendMessageToUrl(
      '/ds/api/messaging/' + this.channel + '/threads',
      thread,
      attachments,
      null,
      null,
      null,
      contactInfo
    );
  }

  getCategories(): Observable<HentKategoriListSvar> {
    return this.http.get<HentKategoriListSvar>(
      '/ds/api/messaging/' + this.channel + '/categories'
    );
  }

  getNonCustomerCategories(): Observable<HentKategoriListSvar> {
    return this.http.get<HentKategoriListSvar>(
      '/ds/api/messaging/' + this.channel + '/non_customer/categories'
    );
  }

  registerAttachmentAccess(attachment) {
    const id = attachment.externalAttachmentId || attachment.id;
    if (this.attachmentAccess[id] > 0) {
      this.attachmentAccess[id] += 1;
    } else {
      this.attachmentAccess[id] = 1;
    }
    return this.attachmentAccess[id] >= this.MAX_ATTACHMENT_ACESSS;
  }

  checkAttachmentAccess(attachments) {
    if (attachments) {
      attachments.forEach(attachment => {
        const id = attachment.externalAttachmentId || attachment.id;
        if (this.attachmentAccess[id] >= this.MAX_ATTACHMENT_ACESSS) {
          attachment.limitExceeded = true;
        }
      });
    }
  }
}
