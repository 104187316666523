<div>
  <h1 [coContent]="'Global.advarsel'"></h1>

  <h3 [coContent]="'DL.GO01.C100'"></h3>
  <p [coContent]="'DL.GO01.C101'" *ngIf="!godkendSvar.gemkvitteringfejl"></p>
  <p [coContent]="'DL.GO01.C308'" *ngIf="!godkendSvar.byggepdffejl"></p>
  <p [coContent]="'DL.GO01.C107'" *ngIf="godkendSvar.gemkvitteringfejl"></p>
  <p [coContent]="'DL.GO01.C355'" *ngIf="godkendSvar.byggepdffejl"></p>

  <div *ngIf="healthQuestionnaire">
    <h3 [coContent]="'DL.GO01.C441'"></h3>
    <p [coContent]="'DL.GO01.C442'"></p>
    <p [coContent]="'DL.GO01.C443'"></p>
    <p [coContent]="'DL.GO01.C444'"></p>
    <p *ngIf="isOnboarding" coContent="DL.VK01.C138"></p>
    <div class="buttons">
      <div class="button-with-text">
        <button
          mat-raised-button
          [coContent]="isOnboarding ? 'DL.VK01.C139' : 'DL.GO01.C445'"
          (click)="close()"
          class="button-secondary"
        ></button>
        <div
          *ngIf="!isOnboarding"
          class="button-note"
          coContent="DL.GO01.C447"
        ></div>
      </div>
      <div>
        <button
          *ngIf="!isOnboarding"
          mat-raised-button
          [coContent]="'DL.GO01.C446'"
          (click)="fillIn()"
          [disabled]="adviser"
        ></button>
        <div
          class="button-note"
          *ngIf="adviser"
          [coContent]="'DL.GO01.C448'"
        ></div>
      </div>
    </div>
  </div>

  <button
    *ngIf="!healthQuestionnaire"
    mat-raised-button
    (click)="close()"
    class="button-secondary"
  >
    <span [coContent]="'DL.GO01.C409'"></span>
  </button>
</div>
