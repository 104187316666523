<ng-container *ngFor="let transfer of savingsPolicyDetails?.overfoersler">
  <tr class="forced-thin-border">
    <td
      [attr.data-label]="'DL.OS01.C425' | content"
      class="responsiv-top-padding"
    >
      <span class="text--bold" [coContent]="'DL.OS01.C262'"></span>
      <button
        mat-raised-button
        class="button-primary--level3"
        (click)="showDetails(transfer)"
        [coContent]="'DL.OS01.C431'"
      ></button>
    </td>
    <td
      class="align--right text--bold"
      [attr.data-label]="'DL.OS01.C426' | content"
    >
      {{ transfer.total | numberFormat: 2 }}
      <span *ngIf="transfer.total" [coContent]="'Global.kroner'"></span>
    </td>
  </tr>
</ng-container>
