/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ServiceInfo } from './serviceInfo';
import { ServiceInfoSchema } from './serviceInfo';
import { ResourceModel } from './resourceModel';
import { ResourceModelSchema } from './resourceModel';
import * as JoiObj from "joi";


    export const PfaValgfriSchema = JoiObj.object({
        serviceInfo:
            
        JoiObj.link('#ServiceInfoSchema')

.allow(null)



        ,
        defaultHoejFordelingProcent:
            
                    JoiObj.number()
                


.required()


        ,
        defaultLavFordelingProcent:
            
                    JoiObj.number()
                


.required()


        ,
        harPfaValgfri:
            
                    JoiObj.boolean()
                


.required()


        ,
        hoejFaktiskTotalFordelingProcent:
            
                    JoiObj.number()
                


.required()


        ,
        hoejFordelingProcent:
            
                    JoiObj.number()
                


.required()


        ,
        indmeldtDirekte:
            
                    JoiObj.boolean()
                


.required()


        ,
        lavFaktiskTotalFordelingProcent:
            
                    JoiObj.number()
                


.required()


        ,
        lavFordelingProcent:
            
                    JoiObj.number()
                


.required()


        ,
        maxHoejFordelingProcent:
            
                    JoiObj.number()
                


.required()


        ,
        minHoejFordelingProcent:
            
                    JoiObj.number()
                


.required()


        ,
        muligt:
            
                    JoiObj.boolean()
                


.required()


        ,
        opsparing:
            
                    JoiObj.number()
                


.required()


        
    })
                .shared(ServiceInfoSchema)
.id('PfaValgfriSchema')

export interface PfaValgfri extends ResourceModel { 
    defaultHoejFordelingProcent: number;
    defaultLavFordelingProcent: number;
    harPfaValgfri: boolean;
    hoejFaktiskTotalFordelingProcent: number;
    hoejFordelingProcent: number;
    indmeldtDirekte: boolean;
    lavFaktiskTotalFordelingProcent: number;
    lavFordelingProcent: number;
    maxHoejFordelingProcent: number;
    minHoejFordelingProcent: number;
    muligt: boolean;
    opsparing: number;
}

