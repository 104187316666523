export class Content {
  da: any;
  en: any;
  language: 'da' | 'en';

  constructor() {
    this.language = 'da';
  }

  public upperCaseLanguage() {
    return this.language === 'en' ? 'EN' : 'DA';
  }
}
