/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const BrugerRolleSchema = JoiObj.string().valid(
        'Selvbetjening'
        ,
        'Administrator'
        ,
        'Maegler'
        ,
        'Pfaer'
        ,
        'Maskinel'
        
    )
.id('BrugerRolleSchema')

export type BrugerRolle = 'Selvbetjening' | 'Administrator' | 'Maegler' | 'Pfaer' | 'Maskinel';

export const BrugerRolle = {
    Selvbetjening: 'Selvbetjening' as BrugerRolle,
    Administrator: 'Administrator' as BrugerRolle,
    Maegler: 'Maegler' as BrugerRolle,
    Pfaer: 'Pfaer' as BrugerRolle,
    Maskinel: 'Maskinel' as BrugerRolle
};

