import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { CoreModule } from '@pfa/core';
import { PfaFormModule } from '@pfaform';
import { IconModule } from '@pfa/icons';
import { ProofOfIdentityModalComponent } from './proof-of-identity-modal/proof-of-identity-modal.component';
import { ProofOfIdentityComponent } from './proof-of-identity.component';

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    PfaFormModule,
    IconModule,
    MatButtonModule,
    MatDialogModule
  ],
  declarations: [ProofOfIdentityComponent, ProofOfIdentityModalComponent],
  exports: []
})
export class ProofOfIdentityModule {}
