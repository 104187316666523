import { PhoneCountryCodeValidator, PhoneNumberValidator } from '@pfa/utils';
import { GlobalWorkingService } from '../global-working/global-working.service';
import { ContactMeResponse } from './contact-me.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators
} from '@angular/forms';
import { Component, OnInit, Inject } from '@angular/core';
import { PensionsKundeGenerelleData, PensionskundeStore } from '@pfa/gen';
import { finalize } from 'rxjs';

@Component({
  selector: 'co-contact-me',
  templateUrl: './contact-me.component.html',
  styleUrls: ['./contact-me.component.scss']
})
export class ContactMeComponent implements OnInit {
  contactForm: UntypedFormGroup;
  pensionCustomer: PensionsKundeGenerelleData;
  currentMobileNumber: string;
  saveOperationFailed: boolean;

  constructor(
    private readonly globalWorkingService: GlobalWorkingService,
    private readonly dialogRef: MatDialogRef<ContactMeResponse>,
    private readonly pensionskundeStore: PensionskundeStore,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.pensionCustomer = data.pensionCustomer;
    this.currentMobileNumber = this.pensionCustomer.mobil;
  }

  ngOnInit() {
    this.contactForm = new UntypedFormGroup({
      countryCode: new UntypedFormControl(this.pensionCustomer.landekode, {
        validators: [Validators.required, PhoneCountryCodeValidator]
      }),
      mobileNumber: new UntypedFormControl(this.pensionCustomer.mobil, {
        validators: [Validators.required, PhoneNumberValidator]
      })
    });
  }

  closePopup() {
    const response: ContactMeResponse = { result: 'Cancel' };
    this.dialogRef.close(response);
  }

  updateMobile() {
    this.globalWorkingService.show();
    this.saveOperationFailed = false;
    this.pensionskundeStore
      .pensionskundeOpdaterMobilnrPut({
        mobilNr: String(this.contactForm.value.mobileNumber),
        landekode: String(this.contactForm.value.countryCode)
      })
      .pipe(
        finalize(() => {
          this.globalWorkingService.hide();
        })
      )
      .subscribe({
        next: () => {
          const response: ContactMeResponse = {
            result: this.currentMobileNumber
              ? 'UpdatedContactInfo'
              : 'CreatedContactInfo'
          };
          this.dialogRef.close(response);
        },
        error: () => {
          this.saveOperationFailed = true;
          this.globalWorkingService.hide();
        },
        complete: () => {
          this.pensionskundeStore.invalidatepensionskundeIndex();
          this.globalWorkingService.hide();
        }
      });
  }
}
