<div
  class="pension-check--outer-container"
  [ngClass]="{ 'no-counter': !amount || amount === 0 }"
>
  <co-icon-traffic-light></co-icon-traffic-light>
  <div class="pension-check--container">
    <div class="text--bold" coContent="DL.NB01.C400"></div>
    <co-notification
      percentfilled
      class="header--notification"
      [amount]="amount"
    >
    </co-notification>
  </div>
</div>
