/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const OnboardingActivatePensionStepTypeSchema = JoiObj.string().valid(
        'INTRODUCTION'
        ,
        'CONSENT'
        ,
        'CONTACT_DATA'
        ,
        'PI_DATA'
        ,
        'EXTERNAL_TRANSFER'
        
    )
.id('OnboardingActivatePensionStepTypeSchema')

export type OnboardingActivatePensionStepType = 'INTRODUCTION' | 'CONSENT' | 'CONTACT_DATA' | 'PI_DATA' | 'EXTERNAL_TRANSFER';

export const OnboardingActivatePensionStepType = {
    Introduction: 'INTRODUCTION' as OnboardingActivatePensionStepType,
    Consent: 'CONSENT' as OnboardingActivatePensionStepType,
    ContactData: 'CONTACT_DATA' as OnboardingActivatePensionStepType,
    PiData: 'PI_DATA' as OnboardingActivatePensionStepType,
    ExternalTransfer: 'EXTERNAL_TRANSFER' as OnboardingActivatePensionStepType
};

