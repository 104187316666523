import { NgModule } from '@angular/core';
import { CustomerSurveyConsentComponent } from './customer-survey-consent/customer-survey-consent.component';
import { CustomerSurveyService } from './customer-survey.service';
import { CustomerSurveyComponent } from './customer-survey/customer-survey.component';
import { CustomerSurveyIntroComponent } from './customer-survey/customer-survey-intro/customer-survey-intro.component';
import { CustomerSurveyQuestionsComponent } from './customer-survey/customer-survey-questions/customer-survey-questions.component';
import { CustomerSurveySummaryComponent } from './customer-survey/customer-survey-summary/customer-survey-summary.component';
import { SharedModule } from '@mitpfa/shared';
import { CustomerSurveyNavigationService } from './customer-survey/customer-survey-navigation.service';
import { CustomerSurveyMissingInfoVisibilityPipe } from './customer-survey/pipes/customer-survey-missing-info-visibility.pipe';

@NgModule({
  declarations: [
    CustomerSurveyConsentComponent,
    CustomerSurveyComponent,
    CustomerSurveyIntroComponent,
    CustomerSurveyQuestionsComponent,
    CustomerSurveySummaryComponent,
    CustomerSurveyMissingInfoVisibilityPipe
  ],
  imports: [SharedModule],
  exports: [CustomerSurveyConsentComponent],
  providers: [CustomerSurveyService, CustomerSurveyNavigationService]
})
export class CustomerSurveyModule {}
