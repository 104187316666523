<div mat-dialog-title *ngIf="navigationService.currentStep === step.Intro">
  <h3 class="consent-header" [coContent]="'DL.TH01.C88'"></h3>
</div>
<mat-dialog-content>
  <mitpfa-customer-survey-intro
    [surveyUuid]="surveyUuid"
    (next)="next($event)"
    (close)="close($event)"
    *ngIf="navigationService.currentStep === step.Intro"
  ></mitpfa-customer-survey-intro>
  <mitpfa-customer-survey-questions
    [surveyUuid]="surveyUuid"
    (next)="next($event)"
    *ngIf="navigationService.currentStep === step.Questions"
  ></mitpfa-customer-survey-questions>
  <mitpfa-customer-survey-summary
    (close)="close()"
    *ngIf="navigationService.currentStep === step.Summary"
  ></mitpfa-customer-survey-summary>
</mat-dialog-content>
