<div
  class="text--manchet header spacing-unit--double-under"
  coContent="DL.VK01.C92"
></div>
<co-card>
  <div
    class="text--bold spacing-unit--half-under"
    coContent="DL.VK01.C93"
  ></div>
  <div *ngFor="let policy of policies">
    <div class="policy-row">
      <div class="policy-container">
        <co-primary-number
          class="size-sm"
          [amount]="policy.opsparing"
        ></co-primary-number>
        <div class="note--text">
          {{ policy | policyCompanyName }}
        </div>
        <div class="note--text">
          {{ policy.pensionsordning }}
        </div>
      </div>
      <co-icon-circle-checkmark
        class="headline--icons icon"
        [color]="'white'"
      ></co-icon-circle-checkmark>
    </div>
  </div>
  <div class="spacing-unit--double-over align--center">
    <button
      mat-raised-button
      class="button-secondary move-on spacing-unit--under"
      coContent="DL.VK01.C94"
      (click)="nextPage()"
      data-test-id="combineSavingsNextStep"
    ></button>
    <div class="note--text text--bold" coContent="DL.VK01.C95"></div>
    <div class="note--text" coContent="DL.VK01.C96"></div>
  </div>
</co-card>
