<div class="wrapper">
  <div mat-dialog-title>
    <div><h3 [coContent]="'DL.AP02.C40'"></h3></div>
  </div>

  <mat-dialog-content>
    <div [coContent]="'DL.AP02.C41'" class="modal-content"></div>
  </mat-dialog-content>

  <mat-dialog-actions>
    <div class="modal-buttons">
      <button
        mat-raised-button
        (click)="accept()"
        [coContent]="'DL.AP02.C42'"
        data-test-id="confirmCloseActivateYourRecommendationFlow"
      ></button>
      <button
        mat-raised-button
        (click)="cancel()"
        [coContent]="'DL.AP02.C43'"
        data-test-id="continueActivateYourRecommendationFlow"
      ></button>
    </div>
  </mat-dialog-actions>
</div>
