<div mat-dialog-title>
  <co-icon-times (click)="dialogRef.close('cancel')"></co-icon-times>
</div>

<mat-dialog-content class="agreement-dialog-content">
  <div [innerHTML]="srcdoc" class="backend-html"></div>
  <div class="text--bold spacing-over" [coContent]="'DL.TR01.C137'"></div>
  <div class="text--note" [coContent]="'DL.TR01.C138'"></div>
</mat-dialog-content>

<mat-dialog-actions class="justify-content-end">
  <button
    mat-raised-button
    class="button-primary--level3"
    (click)="dialogRef.close('cancel')"
    [coContent]="'DL.TR01.C287'"
  ></button>
  <button
    mat-raised-button
    (click)="dialogRef.close('ok')"
    [coContent]="'DL.TR01.C135'"
  ></button>
</mat-dialog-actions>
