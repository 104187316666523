import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ResetDataCi, ResetDataLife, ResetDataLoc } from '@mitpfa/shared';

@Injectable({
  providedIn: 'root'
})
export class InsuranceAdjustmentResetService {
  private readonly ciReset = new Subject<ResetDataCi | undefined>();
  private readonly lifeReset = new Subject<ResetDataLife | undefined>();
  private readonly locReset = new Subject<ResetDataLoc | undefined>();
  readonly ciResetAnnounced$ = this.ciReset.asObservable();
  readonly lifeResetAnnounced$ = this.lifeReset.asObservable();
  readonly locResetAnnounced$ = this.locReset.asObservable();

  ciNotify(resetDataCi: ResetDataCi | undefined): void {
    this.ciReset.next(resetDataCi);
  }

  lifeNotify(resetDataLife: ResetDataLife | undefined): void {
    this.lifeReset.next(resetDataLife);
  }

  locNotify(resetDataLoc: ResetDataLoc | undefined): void {
    this.locReset.next(resetDataLoc);
  }
}
