/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { IdName } from './idName';
import { IdNameSchema } from './idName';
import { Preselect } from './preselect';
import { PreselectSchema } from './preselect';
import * as JoiObj from "joi";


    export const HsSchema = JoiObj.object({
        barnsCpr:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        barnsCprContentIdHeader:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        barnsNavn:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        barnsNavnContentIdHeader:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        diagnose:
            
        JoiObj.link('#IdNameSchema')

.allow(null)



        ,
        diagnosisElaboration:
            
        JoiObj.link('#IdNameSchema')

.allow(null)



        ,
        forBarn:
            
        JoiObj.link('#PreselectSchema')

.allow(null)



        ,
        kropsdel:
            
        JoiObj.link('#IdNameSchema')

.allow(null)



        ,
        omraade:
            
        JoiObj.link('#IdNameSchema')

.allow(null)



        ,
        valgtSygdomsKategori:
            
        JoiObj.link('#PreselectSchema')


.required()


        
    })
                .shared(IdNameSchema)
                .shared(IdNameSchema)
                .shared(PreselectSchema)
                .shared(IdNameSchema)
                .shared(IdNameSchema)
                .shared(PreselectSchema)
.id('HsSchema')

export interface Hs { 
    barnsCpr?: string;
    barnsCprContentIdHeader?: string;
    barnsNavn?: string;
    barnsNavnContentIdHeader?: string;
    diagnose?: IdName;
    diagnosisElaboration?: IdName;
    forBarn?: Preselect;
    kropsdel?: IdName;
    omraade?: IdName;
    valgtSygdomsKategori: Preselect;
}

