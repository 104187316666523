import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ContentService, PageTrackingService } from '@pfa/core';
import { openClose } from '@pfa/animations';
import {
  MenuItem,
  MenuItemType,
  SubMenuItem,
  SubMenuItemName
} from '../menu.model';
import { PopupStyle } from '@pfa/models';
import {
  BroadcastMessageType,
  BroadcastService,
  SaveLanguageModalComponent
} from '@pfaform';

@Component({
  selector: 'mitpfa-menu-item-mobile',
  templateUrl: './menu-item-mobile.component.html',
  styleUrls: ['./menu-item-mobile.component.scss'],
  animations: [openClose]
})
export class MenuItemMobileComponent implements OnInit {
  @Input() menuItems: MenuItem[];
  @Input() isAdvisor: boolean;
  @Input() lastVisit: Date;
  @Input() selectedSubMenuName: SubMenuItemName;
  @Input() isMenuOpen: boolean;
  @Input() notifications: number;
  @Output() openCloseMenu = new EventEmitter<MenuItemType>();

  public selectedMenuItem: MenuItem | undefined;
  public isDanish: boolean;

  constructor(
    private readonly contentService: ContentService,
    private readonly dialog: MatDialog,
    private readonly broadcastService: BroadcastService,
    private readonly router: Router,
    private readonly pageTrackingService: PageTrackingService
  ) {}

  ngOnInit() {
    this.isDanish = this.contentService.getLanguage() === 'da' ? true : false;
  }

  stopPropagation(event: MouseEvent) {
    event.stopPropagation();
  }

  openClose(menuItemType: MenuItemType) {
    if (menuItemType !== undefined && menuItemType !== 'UNDEFINED') {
      this.pageTrackingService.trackNavigation(
        'Mobile',
        this.isMenuOpen ? 'Close' : 'Open',
        ''
      );
    }
    this.openCloseMenu.emit(menuItemType);
  }

  select(subMenuItem: SubMenuItem) {
    this.pageTrackingService.trackNavigation(
      this.selectedMenuItem?.adobeTrackingMenuName,
      subMenuItem.adobeTrackingSubMenuName,
      subMenuItem.url
    );

    if (subMenuItem.name !== 'SIDSTE_LOGIN') {
      this.openClose('UNDEFINED');
    }
  }

  showHideMenu(menuItem: MenuItem) {
    if (menuItem.name === this.selectedMenuItem?.name) {
      this.pageTrackingService.trackNavigation(menuItem.name, 'Close', '');
      this.selectedMenuItem = undefined;
    } else {
      this.pageTrackingService.trackNavigation(menuItem.name, 'Open', '');
      this.selectedMenuItem = menuItem;
    }
  }

  logout() {
    this.router.navigate(['logout']);
  }

  setLanguage(lang: 'da' | 'en') {
    this.isDanish = lang === 'da' ? true : false;
    this.contentService.setLanguage(lang);
    this.contentService.loadLanguage().subscribe(res => {
      if (res !== lang.toString().toUpperCase()) {
        this.dialog.open(SaveLanguageModalComponent, {
          disableClose: true,
          panelClass: PopupStyle.PopupSmall
        });
      }
    });
    const broadcastMessage = this.isDanish
      ? BroadcastMessageType.LANGUAGE_DA
      : BroadcastMessageType.LANGUAGE_EN;
    this.broadcastService.broadcastMessage(broadcastMessage);
  }

  isDashboard() {
    return this.router.url === '/mitoverblik';
  }
}
