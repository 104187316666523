/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const RiskProfileSchema = JoiObj.string().valid(
        'LOW'
        ,
        'AVERAGE_LOW'
        ,
        'AVERAGE'
        
    )
.id('RiskProfileSchema')

export type RiskProfile = 'LOW' | 'AVERAGE_LOW' | 'AVERAGE';

export const RiskProfile = {
    Low: 'LOW' as RiskProfile,
    AverageLow: 'AVERAGE_LOW' as RiskProfile,
    Average: 'AVERAGE' as RiskProfile
};

