<div class="card-head text--bold" coContent="DL.INV.C653"></div>

<div class="recommend">
  <div class="recommend-current">
    <div class="recommend-body">
      <div class="recommend-head text--bold" coContent="DL.INV.C654"></div>

      <mitpfa-investment-recommendation-profile-detail
        [detail]="detail"
      ></mitpfa-investment-recommendation-profile-detail>
    </div>

    <div class="recommend-btn">
      <div
        *ngIf="confirmedDate"
        class="align--center text--note spacing-unit--half-under"
      >
        {{ 'DL.INV.C718' | content }} {{ confirmedDate | date: 'dd.MM.yyyy' }}
      </div>
      <button
        mat-raised-button
        class="button-secondary"
        coContent="DL.INV.C658"
        (click)="keepProfile()"
      ></button>
    </div>
  </div>

  <div class="recommend-new">
    <div class="recommend-body">
      <div class="recommend-head text--bold" coContent="DL.INV.C656"></div>

      <mitpfa-investment-recommendation-profile-detail
        [detail]="detail"
        [recommendation]="true"
      ></mitpfa-investment-recommendation-profile-detail>
    </div>

    <div class="recommend-btn">
      <button
        *ngIf="
          !detail.unboundSource &&
          !detail.current.onlyYouInvest &&
          !detail.recommendation.recommendYouInvest
        "
        mat-raised-button
        coContent="DL.INV.C659"
        [disabled]="
          detail.current.profile?.id === detail.recommendation.profile?.id
        "
        (click)="followRecommendation()"
      ></button>

      <button
        *ngIf="
          !isOnboardingFlow &&
          (detail.unboundSource ||
            detail.recommendation.recommendYouInvest ||
            (detail.current.onlyYouInvest &&
              !detail.recommendation.recommendYouInvest))
        "
        mat-raised-button
        coContent="DL.INVGU01.C471"
        (click)="navigateToConceptAdjust()"
      ></button>
      <co-alert
        *ngIf="
          isOnboardingFlow &&
          (detail.unboundSource ||
            detail.recommendation.recommendYouInvest ||
            (detail.current.onlyYouInvest &&
              !detail.recommendation.recommendYouInvest))
        "
      >
        <span coContent="DL.VK01.C137"></span>
      </co-alert>
    </div>
  </div>
</div>

<div class="btn-choose align--center">
  <button
    mat-raised-button
    class="button-primary--level3"
    (click)="
      isOnboardingFlow ? nextCard() : navigateToConceptWithChooseYourself()
    "
  >
    <co-icon-slider class="icon"></co-icon-slider>
    <span
      [coContent]="isOnboardingFlow ? 'DL.VK01.C128' : 'DL.INV.C660'"
    ></span>
  </button>
</div>
