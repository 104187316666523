/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ServiceInfo } from './serviceInfo';
import { ServiceInfoSchema } from './serviceInfo';
import { FondFordeling } from './fondFordeling';
import { FondFordelingSchema } from './fondFordeling';
import { ResourceModel } from './resourceModel';
import { ResourceModelSchema } from './resourceModel';
import { Fond } from './fond';
import { FondSchema } from './fond';
import * as JoiObj from "joi";


    export const IndbetalingFondFordelingSchema = JoiObj.object({
        serviceInfo:
            
        JoiObj.link('#ServiceInfoSchema')

.allow(null)



        ,
        fordeling:
            
        JoiObj.link('#FondFordelingSchema')


.required()


        ,
        manuelInvestering:
            
                    JoiObj.boolean()
                


.required()


        ,
        manuelInvesteringFondId:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        mulige:
            
        JoiObj.link('#FondFordelingSchema')


.required()


        ,
        pengemarked:
            
        JoiObj.link('#FondSchema')


.required()


        
    })
                .shared(ServiceInfoSchema)
                .shared(FondFordelingSchema)
                .shared(FondFordelingSchema)
                .shared(FondSchema)
.id('IndbetalingFondFordelingSchema')

export interface IndbetalingFondFordeling extends ResourceModel { 
    fordeling: FondFordeling;
    manuelInvestering: boolean;
    manuelInvesteringFondId: string;
    mulige: FondFordeling;
    pengemarked: Fond;
}

