/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AlternativePolicyChoicePartiallyAccepted } from './alternativePolicyChoicePartiallyAccepted';
import { AlternativePolicyChoicePartiallyAcceptedSchema } from './alternativePolicyChoicePartiallyAccepted';
import { OriginOfMoneySelectedList } from './originOfMoneySelectedList';
import { OriginOfMoneySelectedListSchema } from './originOfMoneySelectedList';
import { PurposeOfPolicySelectedList } from './purposeOfPolicySelectedList';
import { PurposeOfPolicySelectedListSchema } from './purposeOfPolicySelectedList';
import { AlternativePolicyChoiceDeclined } from './alternativePolicyChoiceDeclined';
import { AlternativePolicyChoiceDeclinedSchema } from './alternativePolicyChoiceDeclined';
import { AlternativePolicyChoice } from './alternativePolicyChoice';
import { AlternativePolicyChoiceSchema } from './alternativePolicyChoice';
import { BusinessContactElement } from './businessContactElement';
import { BusinessContactElementSchema } from './businessContactElement';
import * as JoiObj from "joi";


    export const CreateOfferReqSchema = JoiObj.object({
        alternativePolicyChoice:
            
        JoiObj.link('#AlternativePolicyChoiceSchema')


.required()


        ,
        alternativePolicyChoiceDeclined:
            
        JoiObj.link('#AlternativePolicyChoiceDeclinedSchema')


.required()


        ,
        alternativePolicyChoicePartiallyAccepted:
            
        JoiObj.link('#AlternativePolicyChoicePartiallyAcceptedSchema')


.required()


        ,
        businessContactElement:
            
        JoiObj.link('#BusinessContactElementSchema')


.required()


        ,
        monthlyPaymentAmount:
            
                    JoiObj.number()
                


.required()


        ,
        monthlyPaymentPercentage:
            
                    JoiObj.number()
                


.required()


        ,
        originOfMoneyComment:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        originOfMoneySelectedList:
            
        JoiObj.link('#OriginOfMoneySelectedListSchema')


.required()


        ,
        purposeOfPolicyComment:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        purposeOfPolicySelectedList:
            
        JoiObj.link('#PurposeOfPolicySelectedListSchema')


.required()


        ,
        quotationGuid:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        
    })
                .shared(AlternativePolicyChoiceSchema)
                .shared(AlternativePolicyChoiceDeclinedSchema)
                .shared(AlternativePolicyChoicePartiallyAcceptedSchema)
                .shared(BusinessContactElementSchema)
                .shared(OriginOfMoneySelectedListSchema)
                .shared(PurposeOfPolicySelectedListSchema)
.id('CreateOfferReqSchema')

export interface CreateOfferReq { 
    alternativePolicyChoice: AlternativePolicyChoice;
    alternativePolicyChoiceDeclined: AlternativePolicyChoiceDeclined;
    alternativePolicyChoicePartiallyAccepted: AlternativePolicyChoicePartiallyAccepted;
    businessContactElement: BusinessContactElement;
    monthlyPaymentAmount: number;
    monthlyPaymentPercentage: number;
    originOfMoneyComment: string;
    originOfMoneySelectedList: OriginOfMoneySelectedList;
    purposeOfPolicyComment: string;
    purposeOfPolicySelectedList: PurposeOfPolicySelectedList;
    quotationGuid: string;
}
export namespace CreateOfferReq {
}


