import {
  Component,
  Input,
  ChangeDetectionStrategy,
  OnInit
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { FinancialSituationConfigurationService } from '../../services/financial-situation-configuration.service';

@Component({
  selector: 'mitpfa-financial-situation-real-estate-advisor',
  templateUrl: './financial-situation-real-estate-advisor.component.html',
  styleUrls: ['./financial-situation-real-estate-advisor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FinancialSituationRealEstateAdvisorComponent implements OnInit {
  @Input() form: UntypedFormGroup;

  public interestFormTypes: { value: string; translation: string }[];
  public loanRepaymentsTypes: { value: string; translation: string }[];
  public displayForm$;

  constructor(
    readonly financialSituationConfigurationService: FinancialSituationConfigurationService
  ) {
    this.interestFormTypes =
      this.financialSituationConfigurationService.interestFormTypes;
    this.loanRepaymentsTypes =
      this.financialSituationConfigurationService.loanRepaymentsTypes;
  }

  ngOnInit(): void {
    this.displayForm$ = this.form.controls.saleValueToday.valueChanges.pipe(
      map(value => value > 0),
      startWith(this.form.controls.saleValueToday.value > 0)
    );
  }
}
