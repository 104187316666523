/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PFAPensionProductOffer } from './pFAPensionProductOffer';
import { PFAPensionProductOfferSchema } from './pFAPensionProductOffer';
import { PFAPensionProduct } from './pFAPensionProduct';
import { PFAPensionProductSchema } from './pFAPensionProduct';
import * as JoiObj from "joi";


    export const ProductPortfolioSchema = JoiObj.object({
        pfaPensionProducts:
                JoiObj.array().items(
        
        JoiObj.link('#PFAPensionProductSchema')

        )
        

.required()


        ,
        pfaPensionProductsOffer:
                JoiObj.array().items(
        
        JoiObj.link('#PFAPensionProductOfferSchema')

        )
        

.required()


        
    })
                .shared(PFAPensionProductSchema)
                .shared(PFAPensionProductOfferSchema)
.id('ProductPortfolioSchema')

export interface ProductPortfolio { 
    pfaPensionProducts: Array<PFAPensionProduct>;
    pfaPensionProductsOffer: Array<PFAPensionProductOffer>;
}

