/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CustomerClassification } from './customerClassification';
import { CustomerClassificationSchema } from './customerClassification';
import { PersonStatus } from './personStatus';
import { PersonStatusSchema } from './personStatus';
import * as JoiObj from "joi";


    export const PersonDetailSchema = JoiObj.object({
        businessPartnerId:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        customerClassification:
            
        JoiObj.link('#CustomerClassificationSchema')


.required()


        ,
        employee:
            
                    JoiObj.boolean()
                


.required()


        ,
        externalCustomerId:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        globalId:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        letpensionCustomer:
            
                    JoiObj.boolean()
                


.required()


        ,
        personStatus:
            
        JoiObj.link('#PersonStatusSchema')


.required()


        ,
        pureLetpensionCustomer:
            
                    JoiObj.boolean()
                


.required()


        ,
        onboarding:
            
                    JoiObj.boolean()
                


.required()


        
    })
                .shared(CustomerClassificationSchema)
                .shared(PersonStatusSchema)
.id('PersonDetailSchema')

export interface PersonDetail { 
    businessPartnerId?: string;
    customerClassification: CustomerClassification;
    employee: boolean;
    externalCustomerId?: string;
    globalId?: string;
    letpensionCustomer: boolean;
    personStatus: PersonStatus;
    pureLetpensionCustomer: boolean;
    onboarding: boolean;
}
export namespace PersonDetail {
}


