import { Pipe, PipeTransform } from '@angular/core';
import { ContentPipe } from './content/content.pipe';
import { NumberFormatPipe } from './number-format.pipe';

@Pipe({
  name: 'currencyFormat'
})
export class CurrencyFormatPipe implements PipeTransform {
  private currencyTranslationCode = 'Global.kroner';

  constructor(
    readonly numberFormatPipe: NumberFormatPipe,
    readonly contentPipe: ContentPipe
  ) {}

  transform(value: any, decimals?: number): string {
    return `${
      value && !isNaN(value)
        ? this.numberFormatPipe.transform(value, decimals)
        : 0
    } ${this.contentPipe.transform(this.currencyTranslationCode)}`;
  }
}
