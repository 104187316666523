import { Component, Input } from '@angular/core';

@Component({
  selector: 'co-bodytext',
  templateUrl: './bodytext.component.html',
  styleUrls: ['./bodytext.component.scss']
})
export class BodytextComponent {
  @Input() alignCenter: boolean;
}
