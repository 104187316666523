/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const RateLimitSchema = JoiObj.object({
        enabled:
            
                    JoiObj.boolean()
                


.required()


        ,
        sessionMax:
            
                    JoiObj.number()
                


.required()


        ,
        updated:
            
                    JoiObj.object()
                


.required()


        ,
        userId:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        
    })
.id('RateLimitSchema')

export interface RateLimit { 
    enabled: boolean;
    sessionMax: number;
    updated: object;
    userId: string;
}

