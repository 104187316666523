import { CustomerProfile } from '@pfa/gen';

export class LightUtils {
  public static isLightProfile(profile: CustomerProfile): boolean {
    return (
      !this.isLetPensionNewBusiness(profile) &&
      ((profile && profile.personDetail.pureLetpensionCustomer) ||
        (profile &&
          profile.mitPfaLightVersion &&
          !profile.newBusinessAdvisory &&
          !profile.overrideLight))
    );
  }

  private static isLetPensionNewBusiness(profile: CustomerProfile): boolean {
    return (
      profile &&
      profile.personDetail.letpensionCustomer &&
      profile.personDetail.pureLetpensionCustomer &&
      !profile.mitPfaLightVersion &&
      !profile.mitPfaLightVersionNonCustomer &&
      profile.newBusinessAdvisory &&
      profile.newBusinessOpen &&
      !profile.overrideLight
    );
  }
}
