<div
  [ngClass]="{ 'accordion--disabled': disabled, expanded: expanded }"
  class="accordion"
>
  <div class="header" (click)="accordionAction()" *ngIf="!hideControls">
    <div class="title custom-header-container" #ref>
      <ng-content select="[customHeader]"></ng-content>
    </div>
    <ng-container *ngIf="!ref.innerHTML.trim()">
      <div *ngIf="!expanded; else expandedTitle" class="title">
        <div>
          <div [coContent]="title" class="text--bold month"></div>
          <div
            *ngIf="titleNote"
            [coContent]="titleNote"
            class="text--note"
          ></div>
        </div>
        <div
          *ngIf="titleRight"
          [coContent]="titleRight"
          class="title-right text--bold"
        ></div>
      </div>
      <ng-template #expandedTitle>
        <div *ngIf="expanded" class="title">
          <div>
            <div
              [coContent]="titleOpen ? titleOpen : title"
              class="text--bold month"
            ></div>
            <div
              *ngIf="titleNote"
              [coContent]="titleNoteOpen ? titleNoteOpen : titleNote"
              class="text--note"
            ></div>
          </div>
          <div
            *ngIf="titleRight"
            [coContent]="titleRightOpen ? titleRightOpen : titleRight"
            class="title-right text--bold"
          ></div>
        </div>
      </ng-template>
    </ng-container>
    <div>
      <span *ngIf="!expanded; else arrowUp" class="float--right">
        <co-icon-arrow-down class="icon-fill__small--grey"></co-icon-arrow-down>
      </span>
      <ng-template #arrowUp>
        <span class="float--right">
          <co-icon-arrow-up class="icon-fill__small--grey"></co-icon-arrow-up>
        </span>
      </ng-template>
    </div>
  </div>

  <div
    *ngIf="expanded"
    [@.disabled]="!!hideControls"
    [@openClose]
    class="body-wrapper"
  >
    <div class="body">
      <ng-content></ng-content>
    </div>
  </div>
</div>
