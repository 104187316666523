/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OnboardingActivatePensionStepType } from './onboardingActivatePensionStepType';
import { OnboardingActivatePensionStepTypeSchema } from './onboardingActivatePensionStepType';
import * as JoiObj from "joi";


    export const OnboardingActivatePensionStepSchema = JoiObj.object({
        checked:
            
                    JoiObj.boolean()
                


.required()


        ,
        name:
            
        JoiObj.link('#OnboardingActivatePensionStepTypeSchema')


.required()


        
    })
                .shared(OnboardingActivatePensionStepTypeSchema)
.id('OnboardingActivatePensionStepSchema')

export interface OnboardingActivatePensionStep { 
    checked: boolean;
    name: OnboardingActivatePensionStepType;
}
export namespace OnboardingActivatePensionStep {
}


