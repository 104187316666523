import { Component, Input } from '@angular/core';

@Component({
  selector: 'co-icon-eventlog',
  templateUrl: './icon-eventlog.component.html',
  styleUrls: ['../icon-base.scss', './icon-eventlog.component.scss']
})
export class IconEventlogComponent {
  @Input() unreadCount: number;
  @Input() selected: boolean;
}
