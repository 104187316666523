<mitpfa-pension-check-header-section
  [headline]="'DL.NB01.C307'"
  [explanation]="'DL.NB01.C308'"
>
</mitpfa-pension-check-header-section>

<mat-progress-bar
  color="primary"
  mode="determinate"
  value="{{ progressValue }}"
></mat-progress-bar>
