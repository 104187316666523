import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MenuItem, MenuItemType, SubMenuItem } from '../menu.model';
import { openClose } from '@pfa/animations';
import { PageTrackingService } from '@pfa/core';
import { Router } from '@angular/router';

@Component({
  selector: 'mitpfa-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss'],
  animations: [openClose]
})
export class MenuItemComponent {
  @Input() menuItem: MenuItem;
  @Input() isAdvisor: boolean;
  @Input() specialMenuItem: boolean;
  @Input() isMenuSelected: boolean;
  @Input() lastVisit: Date;
  @Input() selectedSubMenu: SubMenuItem;
  @Input() menuOpen: MenuItemType;
  @Input() dataTestId: string;
  @Output() openCloseMenu = new EventEmitter<MenuItemType>();

  constructor(
    private readonly pageTrackingService: PageTrackingService,
    private readonly router: Router
  ) {}

  stopPropagation(event: MouseEvent) {
    event.stopPropagation();
  }

  openClose(menuItem: MenuItem) {
    if (menuItem !== undefined) {
      this.pageTrackingService.trackNavigation(
        this.menuItem.adobeTrackingMenuName,
        this.menuOpen === menuItem.name ? 'Close' : 'Open',
        ''
      );
      this.openCloseMenu.emit(menuItem.name);
    } else {
      this.openCloseMenu.emit('UNDEFINED');
    }
  }

  select(subMenuItem: SubMenuItem) {
    this.pageTrackingService.trackNavigation(
      this.menuItem.adobeTrackingMenuName,
      subMenuItem.adobeTrackingSubMenuName,
      subMenuItem.url
    );

    this.router.navigateByUrl(subMenuItem.url);
  }
}
