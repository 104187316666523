import { Injectable } from '@angular/core';
import { QuestionReached } from '@pfa/gen';
import { Step } from '@pfaform';

export enum InvestmentGuideName {
  NAME = 'investment guide'
}

export type InvestmentGuideStepId =
  | 'involvement'
  | 'financialSituation'
  | 'risk'
  | 'climate';

export enum InvestmentGuideQuestion {
  involvement = 'INVOLVEMENT',
  investmentKnowledge = 'INVESTMENT_KNOWLEDGE',
  tradingExperience = 'TRADING_EXPERIENCE',
  numberOfTrades = 'NUMBER_OF_TRADES',
  retrievePensioninfo = 'RETRIEVE_PENSIONINFO',
  otherSavings = 'OTHER_SAVINGS',
  realEstate = 'REAL_ESTATE',
  secondaryResidence = 'SECONDARY_RESIDENCE',
  hasDebt = 'HAS_DEBT',
  economicalImpact = 'ECONOMICAL_IMPACT',
  riskWillingness = 'RISK_WILINGNESS',
  riskProfile = 'RISK_PROFILE',
  lossability = 'LOSSABILITY',
  climateRelevance = 'CLIMATE_RELEVANCE',
  climateExtendedFocus = 'CLIMATE_EXTENDED_FOCUS',
  climateWillingness = 'CLIMATE_WILINGNESS',
  climatePriority = 'CLIMATE_PRIORITY'
}

export enum InvestmentGuideTrackName {
  WELCOME = 'IG introduction',
  INVOLVEMENT = 'IG involvement',
  INVESTMENT_KNOWLEDGE = 'IG investment knowledge',
  TRADING_EXPERIENCE = 'IG trading experience',
  NUMBER_OF_TRADES = 'IG number of trades',
  RETRIEVE_PENSIONINFO = 'IG pensionsinfo',
  OTHER_SAVINGS = 'IG other savings',
  REAL_ESTATE = 'IG real estate',
  SECONDARY_RESIDENCE = 'IG secondary residence',
  HAS_DEBT = 'IG has debt',
  ECONOMICAL_IMPACT = 'IG economical impact',
  RISK_WILINGNESS = 'IG risk willingness',
  RISK_PROFILE = 'IG risk profile',
  LOSSABILITY = 'IG lossability',
  CLIMATE_PRIORITY = 'IG climate priority',
  CLIMATE_WILINGNESS = 'IG climate willingness',
  CLIMATE_EXTENDED_FOCUS = 'IG climate extended focus',
  CLIMATE_RELEVANCE = 'IG climate relevance',
  LOADING_RECOMMENDATION = 'IG loading recommendation'
}

export enum InvestmentGuideTradingExperience {
  investmentFunds = 'INVESTMENTCERTIFICATES',
  equities = 'SHARES',
  bonds = 'BONDS',
  others = 'OTHERS'
}

@Injectable()
export class InvestmentGuideConfigurationService {
  public steps: Step[] = [
    {
      label: 'DL.INVGU01.C542',
      id: 'involvement',
      progress: 0
    },
    {
      label: 'DL.INVGU01.C543',
      id: 'financialSituation',
      progress: 0
    },
    {
      label: 'DL.INVGU01.C544',
      id: 'risk',
      progress: 0
    },
    {
      label: 'DL.INVGU01.C584',
      id: 'climate',
      progress: 0
    }
  ];

  public questions: { [id in InvestmentGuideStepId]: QuestionReached[] } = {
    involvement: [
      InvestmentGuideQuestion.involvement,
      InvestmentGuideQuestion.investmentKnowledge,
      InvestmentGuideQuestion.tradingExperience,
      InvestmentGuideQuestion.numberOfTrades
    ],
    financialSituation: [
      InvestmentGuideQuestion.retrievePensioninfo,
      InvestmentGuideQuestion.otherSavings,
      InvestmentGuideQuestion.realEstate,
      InvestmentGuideQuestion.secondaryResidence,
      InvestmentGuideQuestion.hasDebt,
      InvestmentGuideQuestion.economicalImpact
    ],
    risk: [
      InvestmentGuideQuestion.riskWillingness,
      InvestmentGuideQuestion.riskProfile,
      InvestmentGuideQuestion.lossability
    ],
    climate: [
      InvestmentGuideQuestion.climateRelevance,
      InvestmentGuideQuestion.climateExtendedFocus,
      InvestmentGuideQuestion.climateWillingness,
      InvestmentGuideQuestion.climatePriority
    ]
  };

  public findQuestionStepId(question: QuestionReached): InvestmentGuideStepId {
    for (const id in this.questions) {
      if (this.questions[id].indexOf(question) > -1) {
        return id as InvestmentGuideStepId;
      }
    }
  }
}
