/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const NuvaerendeIndbetalingerSchema = JoiObj.object({
        arbejdsgiverBetalingForsikringer:
            
                    JoiObj.number()
                

.allow(null)



        ,
        kroneBeloeb:
            
                    JoiObj.boolean()
                


.required()


        ,
        kronerFrivilligMedarbejder:
            
                    JoiObj.number()
                

.allow(null)



        ,
        kronerIAlt:
            
                    JoiObj.number()
                

.allow(null)



        ,
        kronerObligatoriskArbejdsgiver:
            
                    JoiObj.number()
                

.allow(null)



        ,
        kronerObligatoriskMedarbejder:
            
                    JoiObj.number()
                

.allow(null)



        ,
        nuvaerendeIndbetalingerTilstede:
            
                    JoiObj.boolean()
                


.required()


        ,
        policeId:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        procentFrivilligMedarbejder:
            
                    JoiObj.number()
                

.allow(null)



        ,
        procentIAlt:
            
                    JoiObj.number()
                

.allow(null)



        ,
        procentObligatoriskArbejdsgiver:
            
                    JoiObj.number()
                

.allow(null)



        ,
        procentObligatoriskMedarbejder:
            
                    JoiObj.number()
                

.allow(null)



        ,
        tillaegspraemie:
            
                    JoiObj.number()
                

.allow(null)



        ,
        visNuvaerendeIndbetalinger:
            
                    JoiObj.boolean()
                


.required()


        
    })
.id('NuvaerendeIndbetalingerSchema')

export interface NuvaerendeIndbetalinger { 
    arbejdsgiverBetalingForsikringer?: number;
    kroneBeloeb: boolean;
    kronerFrivilligMedarbejder?: number;
    kronerIAlt?: number;
    kronerObligatoriskArbejdsgiver?: number;
    kronerObligatoriskMedarbejder?: number;
    nuvaerendeIndbetalingerTilstede: boolean;
    policeId?: string;
    procentFrivilligMedarbejder?: number;
    procentIAlt?: number;
    procentObligatoriskArbejdsgiver?: number;
    procentObligatoriskMedarbejder?: number;
    tillaegspraemie?: number;
    visNuvaerendeIndbetalinger: boolean;
}

