/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ServiceInfo } from './serviceInfo';
import { ServiceInfoSchema } from './serviceInfo';
import { PensionsplanPolice } from './pensionsplanPolice';
import { PensionsplanPoliceSchema } from './pensionsplanPolice';
import { ResourceModel } from './resourceModel';
import { ResourceModelSchema } from './resourceModel';
import * as JoiObj from "joi";


    export const PensionsplanPensionSchema = JoiObj.object({
        serviceInfo:
            
        JoiObj.link('#ServiceInfoSchema')

.allow(null)



        ,
        atp:
            
                    JoiObj.boolean()
                


.required()


        ,
        beregneAndenLoen:
            
                    JoiObj.number()
                


.required()


        ,
        efterloen:
            
                    JoiObj.boolean()
                


.required()


        ,
        efterloenFraAlder:
            
                    JoiObj.number()
                


.required()


        ,
        efterloenTilAlder:
            
                    JoiObj.number()
                


.required()


        ,
        efterloensudbetaling:
            
                    JoiObj.number()
                


.required()


        ,
        folkepension:
            
                    JoiObj.boolean()
                


.required()


        ,
        indbetalingPensionProcent:
            
                    JoiObj.number()
                


.required()


        ,
        loenTilIndbetaling:
            
                    JoiObj.number()
                


.required()


        ,
        policer:
                JoiObj.array().items(
        
        JoiObj.link('#PensionsplanPoliceSchema')

        )
        

.required()


        ,
        policerUdenIndbetalingsInfo:
                JoiObj.array().items(
        
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

        )
        

.required()


        ,
        registreretLoen:
            
                    JoiObj.number()
                


.required()


        ,
        samletOpsparing:
            
                    JoiObj.number()
                


.required()


        
    })
                .shared(ServiceInfoSchema)
                .shared(PensionsplanPoliceSchema)
.id('PensionsplanPensionSchema')

export interface PensionsplanPension extends ResourceModel { 
    atp: boolean;
    beregneAndenLoen: number;
    efterloen: boolean;
    efterloenFraAlder: number;
    efterloenTilAlder: number;
    efterloensudbetaling: number;
    folkepension: boolean;
    indbetalingPensionProcent: number;
    loenTilIndbetaling: number;
    policer: Array<PensionsplanPolice>;
    policerUdenIndbetalingsInfo: Array<string>;
    registreretLoen: number;
    samletOpsparing: number;
}

