<ng-container [ngSwitch]="combineSavingsSummary?.combineSavingsResult">
  <mitpfa-combine-savings-summary-combined
    *ngSwitchCase="summaryType.policiesCombined"
    [policies]="policies"
    (goToNextPage)="goToNextPage.emit()"
  ></mitpfa-combine-savings-summary-combined>
  <mitpfa-combine-savings-summary-not-combined
    *ngSwitchCase="summaryType.policiesNotCombined"
    (goToNextPage)="goToNextPage.emit()"
  >
  </mitpfa-combine-savings-summary-not-combined>
  <mitpfa-combine-savings-summary-transferable-by-advisor
    *ngSwitchCase="summaryType.policiesTransferableByAdvisor"
    (goToNextPage)="goToNextPage.emit()"
  ></mitpfa-combine-savings-summary-transferable-by-advisor>
  <mitpfa-combine-savings-summary-not-transferable
    *ngSwitchCase="summaryType.policiesNotTransferable"
    (goToNextPage)="goToNextPage.emit()"
  ></mitpfa-combine-savings-summary-not-transferable>
  <mitpfa-combine-savings-summary-error-occurred
    *ngSwitchCase="summaryType.errorOccurred"
    (goToNextPage)="goToNextPage.emit()"
  ></mitpfa-combine-savings-summary-error-occurred>
</ng-container>
