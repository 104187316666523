import { Component, Input } from '@angular/core';

@Component({
  selector: 'co-insurance-card-header',
  templateUrl: 'insurance-card-header.component.html',
  styleUrls: ['./insurance-card-header.component.scss']
})
export class InsuranceCardHeaderComponent {
  @Input() headerContent: string;
  @Input() headerNoteContent: string;
  @Input() headerNoteContentTooltip: string;
  @Input() noteContent: string;
  @Input() iconForward?: boolean;
  @Input() ongoingCase?: boolean;
  @Input() ongoingClaim?: boolean;
  @Input() disabled = false;
}
