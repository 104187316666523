/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SprogType } from './sprogType';
import { SprogTypeSchema } from './sprogType';
import { OnlineService } from './onlineService';
import { OnlineServiceSchema } from './onlineService';
import { VariantId } from './variantId';
import { VariantIdSchema } from './variantId';
import * as JoiObj from "joi";


    export const VariantDetailSchema = JoiObj.object({
        variantId:
            
        JoiObj.link('#VariantIdSchema')


.required()


        ,
        language:
            
        JoiObj.link('#SprogTypeSchema')


.required()


        ,
        name:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        details:
                JoiObj.array().items(
        
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

        )
        

.required()


        ,
        onlineService:
            
        JoiObj.link('#OnlineServiceSchema')

.allow(null)



        
    })
                .shared(VariantIdSchema)
                .shared(SprogTypeSchema)
                .shared(OnlineServiceSchema)
.id('VariantDetailSchema')

export interface VariantDetail { 
    variantId: VariantId;
    language: SprogType;
    name: string;
    details: Array<string>;
    onlineService?: OnlineService;
}
export namespace VariantDetail {
}


