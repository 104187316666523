<div *ngIf="!isOpen" class="text--container spacing-unit--double-under">
  <div class="header text--manchet">
    <div [coContent]="'DL.VK01.C58'"></div>
  </div>
</div>
<div *ngIf="!isOpen" class="wrapper">
  <div class="container">
    <co-card-split-horizontal class="card--small width-100">
      <div class="card-header bullet--text" header>
        <co-icon-gift></co-icon-gift>
        <div class="headline--card" coContent="DL.VK01.C59"></div>
      </div>
      <div class="card--small body--container" body>
        <div>
          <div class="bullet--text">
            <co-icon-circle-checkmark
              class="headline--icons icon"
              [color]="'white'"
            ></co-icon-circle-checkmark>
            <div class="headline--text" coContent="DL.VK01.C60"></div>
          </div>
          <div class="bullet--text">
            <co-icon-circle-checkmark
              class="headline--icons icon"
              [color]="'white'"
            ></co-icon-circle-checkmark>
            <div class="headline--text" coContent="DL.VK01.C73"></div>
          </div>
        </div>
        <div class="bullet--text">
          <co-icon-circle-checkmark
            class="headline--icons icon"
            [color]="'white'"
          ></co-icon-circle-checkmark>
          <div class="headline--text" coContent="DL.VK01.C61"></div>
        </div>
      </div>
    </co-card-split-horizontal>
    <div class="sub-headline" coContent="DL.VK01.C62"></div>
    <co-card-split-horizontal class="card--small width-100">
      <div class="card-header bullet--text" header>
        <co-icon-document></co-icon-document>
        <div class="second--headline--card" coContent="DL.VK01.C63"></div>
      </div>
      <div class="card--small" body>
        <p coContent="DL.VK01.C64"></p>
        <div>
          <a class="link" coContent="DL.VK01.C65" (click)="openOverlay('')"></a>
        </div>
      </div>
    </co-card-split-horizontal>
    <co-card-split-horizontal class="card--small width-100 spacing-unit--over">
      <div class="card-header bullet--text" header>
        <co-icon-insurance></co-icon-insurance>
        <div class="second--headline--card" coContent="DL.VK01.C66"></div>
      </div>
      <div class="card--small" body>
        <p coContent="DL.VK01.C67"></p>
        <p coContent="DL.VK01.C68"></p>
        <div>
          <a
            class="link"
            coContent="DL.VK01.C65"
            (click)="openOverlay('insurance')"
          ></a>
        </div>
      </div>
    </co-card-split-horizontal>
    <co-card-split-horizontal class="card--small width-100 spacing-unit--over">
      <div class="card-header bullet--text" header>
        <co-icon-cash></co-icon-cash>
        <div class="second--headline--card" coContent="DL.VK01.C70"></div>
      </div>
      <div class="card--small" body>
        <p coContent="DL.VK01.C71"></p>
        <p coContent="DL.VK01.C72"></p>
        <div>
          <a
            class="link"
            coContent="DL.VK01.C65"
            (click)="openOverlay('costs')"
          ></a>
        </div>
      </div>
    </co-card-split-horizontal>
  </div>
  <div class="button-panel">
    <button
      mat-raised-button
      coContent="DL.VK01.C75"
      [disabled]="submitPending"
      (click)="approve()"
    ></button>
  </div>
</div>

<div
  class="spacing-unit--double-over"
  *ngIf="combineSavingsFlowType === 'EXTERNAL_TRANSFER_FLOW'"
>
  <mitpfa-combine-savings-back-button
    (back)="goBack()"
  ></mitpfa-combine-savings-back-button>
</div>

<div
  *ngIf="isOpen"
  class="details--container"
  [@slideInBottom]="{ value: '', params: { duration: 300, delay: 0 } }"
  [@slideOutBottomWhole]="{
    value: '',
    params: { delay: 0, duration: 400 }
  }"
>
  <div class="details--header"></div>
  <div class="details--wrapper">
    <div class="content">
      <co-icon-times
        class="close icon-fill__large--greys-10 grey_background"
        (click)="closeOverlay()"
      >
      </co-icon-times>
      <p coContent="DL.AP02.C48"></p>
      <p coContent="DL.AP02.C49"></p>
      <p coContent="DL.EO01.C627"></p>
      <p id="insurance" coContent="DL.AP02.C50"></p>
      <p *ngIf="!broker?.maeglerBetjent" coContent="DL.AP02.C51"></p>
      <div *ngIf="broker?.maeglerBetjent" class="combine-savings-jura-text">
        <p
          coContent="DL.AP02.C54"
          [coContentSubst]="broker?.navn + '|' + broker?.telefon"
        ></p>
      </div>
      <p id="costs" coContent="DL.AP02.C52"></p>
      <p coContent="DL.AP02.C53"></p>
    </div>
  </div>
</div>
