import { Pipe, PipeTransform } from '@angular/core';
import { AftaleAnbefaling } from '@pfa/gen';
import { ContentUtilService } from '@pfa/core';

@Pipe({
  name: 'policyCompanyName'
})
export class PolicyCompanyNamePipe implements PipeTransform {
  constructor(readonly contentUtilService: ContentUtilService) {}

  transform(policy: AftaleAnbefaling): string {
    return this.contentUtilService.getContent(`DL.SEL.C${policy.selskab}`);
  }
}
