/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const KildeSchema = JoiObj.string().valid(
        'Alle'
        ,
        'Frie'
        
    )
.id('KildeSchema')

export type Kilde = 'Alle' | 'Frie';

export const Kilde = {
    Alle: 'Alle' as Kilde,
    Frie: 'Frie' as Kilde
};

