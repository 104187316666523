<ng-container [formGroup]="formGroup">
  <div class="row">
    <div class="col-sm-6">
      <p class="text--manchet" coContent="DL.MD01.C408"></p>
      <p class="text--info" coContent="DL.MD01.C409"></p>
    </div>
    <div class="col-sm-3 offset-sm-1">
      <mat-radio-group
        class="inline"
        ngDefaultControl
        formControlName="typeOfProperty"
      >
        <mat-radio-button
          *ngFor="let property of propertyTypes"
          [value]="property.value"
        >
          {{ property.translation | content }}
        </mat-radio-button>
      </mat-radio-group>

      <mat-form-field
        data-test-id="financial-situation-real-estate-house-name"
        color="accent"
      >
        <mat-label>{{ 'DL.MD01.C337' | content }}</mat-label>
        <input required matInput ngDefaultControl formControlName="name" />
        <mat-error
          *ngIf="formGroup.get('name').errors?.required; else isNotEmpty"
          coContent="DL.MD01.C12"
        ></mat-error>
        <ng-template #isNotEmpty>
          <mat-error
            *ngIf="formGroup.get('name').errors?.maxlength"
            coContent="DL.BE02.C1012"
            [coContentSubst]="50"
          ></mat-error>
        </ng-template>
      </mat-form-field>
    </div>
  </div>

  <div class="row spacing-unit--over">
    <div class="col-sm-6">
      <p class="text--manchet" coContent="DL.MD01.C311"></p>
      <p class="text--info" coContent="DL.MD01.C312"></p>
    </div>
    <div [ngClass]="isAdvisor ? 'col-sm-3 offset-sm-1' : 'col-sm-12'">
      <mat-form-field
        *ngIf="isAdvisor; else saleValueTodaySlider"
        color="accent"
      >
        <input
          matInput
          coAmount
          ngDefaultControl
          formControlName="saleValueToday"
        />
      </mat-form-field>

      <ng-template #saleValueTodaySlider>
        <div class="spacing-unit--over">
          <co-slider-form
            label="DL.INVGU01.C548"
            [sliderOptions]="sliderOptions"
            ngDefaultControl
            formControlName="saleValueToday"
            type="currency"
            data-test-id="financial-situation-real-estate-salevalue-today"
          ></co-slider-form>
        </div>
      </ng-template>
    </div>
  </div>

  <div class="row spacing-unit--double-over">
    <div class="col-sm-6">
      <div class="text--manchet" coContent="DL.MD01.C313"></div>
      <p class="text--info" coContent="DL.MD01.C314"></p>
    </div>
    <div [ngClass]="isAdvisor ? 'col-sm-3 offset-sm-1' : 'col-sm-12'">
      <ng-template #presentDebtSlider>
        <mat-form-field color="accent">
          <input
            matInput
            coAmount
            ngDefaultControl
            formControlName="presentDebt"
          />
        </mat-form-field>
        <p class="text--note" coContent="DL.MD01.C314"></p>
      </ng-template>

      <div
        class="spacing-unit--over"
        *ngIf="!isAdvisor; else presentDebtSlider"
      >
        <co-slider-form
          label="DL.INVGU01.C196"
          [sliderOptions]="sliderOptions"
          ngDefaultControl
          formControlName="presentDebt"
          type="currency"
        ></co-slider-form>
      </div>
    </div>
  </div>

  <div
    class="row spacing-unit--double-over"
    *ngIf="formGroup.controls.debtAtTimeOfRetirement"
  >
    <div class="col-sm-6">
      <div class="text--manchet" coContent="DL.MD01.C317"></div>
      <p class="text--info" coContent="DL.MD01.C318"></p>
    </div>
    <div [ngClass]="isAdvisor ? 'col-sm-3 offse-sm-1' : 'col-sm-12'">
      <mat-form-field
        *ngIf="isAdvisor; else debtAtTimeOfRetirementSlider"
        color="accent"
      >
        <input
          matInput
          coAmount
          ngDefaultControl
          formControlName="debtAtTimeOfRetirement"
        />
      </mat-form-field>

      <ng-template #debtAtTimeOfRetirementSlider>
        <div class="spacing-unit--over">
          <co-slider-form
            label="DL.INVGU01.C196"
            [sliderOptions]="sliderOptions"
            ngDefaultControl
            formControlName="debtAtTimeOfRetirement"
            type="currency"
          ></co-slider-form>
        </div>
      </ng-template>
    </div>
  </div>

  <div class="ownership-section" [class.details]="displayOwnershipDetailsForm">
    <div class="row spacing-unit--double-over general-section">
      <div class="col-sm-6">
        <div class="text--manchet" coContent="DL.MD01.C315"></div>
        <p class="text--info" coContent="DL.MD01.C316"></p>
      </div>

      <div class="col-sm-6 ownership-value">
        <div class="flex">
          <div class="text--bold label">{{ 'DL.MD01.C438' | content }}:</div>
          <div class="text--manchet text--bold value">
            {{ formGroup.value.yourShareOfResidence }}%
          </div>
          <button
            mat-button
            color="primary"
            (click)="toggleDisplayOwnershipDetailsForm()"
          >
            <co-icon-edit class="icon"></co-icon-edit>
            <span coContent="DL.MD01.C439"></span>
          </button>
        </div>
      </div>
    </div>

    <ng-container *ngIf="ownershipForm" [formGroup]="ownershipForm">
      <div class="row spacing-unit--double-over details-section">
        <div class="col-lg-6">
          <div class="text--manchet" coContent="DL.MD01.C315"></div>
          <p class="text--info" coContent="DL.MD01.C316"></p>
        </div>
        <div class="col-lg-6 ownership-radio-group">
          <mat-radio-group
            class="inline"
            ngDefaultControl
            formControlName="type"
          >
            <mat-radio-button
              *ngFor="let ownership of ownershipTypes"
              [value]="ownership.value"
            >
              {{ ownership.translation | content }}
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>

      <ng-container *ngIf="displaySharingSection">
        <div class="row spacing-unit--double-over details-section">
          <div class="col-sm-6">
            <div class="text--manchet" coContent="DL.MD01.C414"></div>
          </div>
          <div class="col-sm-6 is-equally-divided">
            <div class="flex">
              <mat-radio-group
                class="inline"
                ngDefaultControl
                formControlName="isEquallyDivided"
              >
                <mat-radio-button [value]="true">
                  {{ 'DL.MD01.C29' | content }}
                </mat-radio-button>
                <mat-radio-button [value]="false">
                  {{ 'DL.MD01.C30' | content }}
                </mat-radio-button>
              </mat-radio-group>
              <div class="percentage-value text--note">
                {{
                  ('DL.MD01.C415' | content) +
                    ' ' +
                    (formGroup.controls.yourShareOfResidence.valid
                      ? formGroup.value.yourShareOfResidence
                      : 0)
                }}%
              </div>
            </div>
          </div>
        </div>

        <div class="row spacing-unit--double-over" *ngIf="displayCoOwnersForm">
          <div class="col-sm-6">
            <div class="text--manchet" coContent="DL.MD01.C420"></div>
          </div>
          <div class="col-sm-3 offset-sm-1">
            <mat-form-field appearance="fill" color="accent">
              <mat-select formControlName="coOwners">
                <mat-option
                  *ngFor="let coOwnerType of coOwnerTypes"
                  [value]="coOwnerType.value"
                >
                  {{ coOwnerType.translation | content }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div
          class="row spacing-unit--double-over details-section"
          *ngIf="!ownershipForm.value.isEquallyDivided"
        >
          <div class="col-sm-6">
            <div class="text--manchet" coContent="DL.MD01.C418"></div>
          </div>
          <div class="col-sm-3 offset-sm-1">
            <mat-form-field class="small-input" color="accent">
              <mat-label>{{ 'DL.MD01.C419' | content }}</mat-label>
              <input
                matInput
                ngDefaultControl
                [formControl]="formGroup.controls.yourShareOfResidence"
              />
              <mat-error
                *ngIf="
                  formGroup.controls.yourShareOfResidence.errors?.required;
                  else notEmpty
                "
                coContent="DL.MD01.C12"
              ></mat-error>

              <ng-template #notEmpty>
                <mat-error
                  *ngIf="
                    formGroup.controls.yourShareOfResidence.errors?.notNumber;
                    else correctFormat
                  "
                  coContent="DL.MD01.C13"
                ></mat-error>

                <ng-template #correctFormat>
                  <mat-error
                    *ngIf="formGroup.controls.yourShareOfResidence.errors?.max"
                    coContent="DL.MD01.C14"
                    [coContentSubst]="100"
                  ></mat-error>

                  <mat-error
                    *ngIf="formGroup.controls.yourShareOfResidence.errors?.min"
                    coContent="DL.MD01.C21a"
                    coContentSubst="0|%"
                  ></mat-error>
                </ng-template>
              </ng-template>
            </mat-form-field>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>

  <div
    class="row spacing-unit--double-over"
    *ngIf="formGroup.controls.includedInRetirement"
  >
    <div class="col-sm-6">
      <div class="text--manchet" coContent="DL.MD01.C319"></div>
      <p class="text--info" coContent="DL.MD01.C320"></p>
    </div>
    <div class="col-sm-6">
      <mat-radio-group ngDefaultControl formControlName="includedInRetirement">
        <mat-radio-button [value]="100">
          {{ 'DL.MD01.C431' | content }}
          <div class="text--note" coContent="DL.MD01.C432"></div>
        </mat-radio-button>
        <mat-radio-button [value]="60">
          {{ 'DL.MD01.C433' | content }}
          <div class="text--note" coContent="DL.MD01.C434"></div>
        </mat-radio-button>
        <mat-radio-button [value]="0">
          {{ 'DL.MD01.C435' | content }}
        </mat-radio-button>
        <!-- not supported
        <mat-radio-button [value]="-1">
          {{ 'DL.MD01.C436' | content }}
          <div class="text--note" coContent="DL.MD01.C437"></div>
        </mat-radio-button>
      -->
      </mat-radio-group>
    </div>
  </div>

  <mitpfa-financial-situation-real-estate-advisor
    *ngIf="formGroup.controls.advisor.enabled && withAdvisorMenu"
    [form]="formGroup"
  ></mitpfa-financial-situation-real-estate-advisor>
</ng-container>
