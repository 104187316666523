<co-icon-increase
  *ngIf="direction === 'up'"
  class="icon icon--up"
></co-icon-increase>
<co-icon-decrease
  *ngIf="direction === 'down'"
  class="icon icon--down"
></co-icon-decrease>

<span [ngClass]="'text text--' + direction"
  >{{ value | number: '1.0-2' : locale }} {{ unit }}</span
>
