<mitpfa-pension-check-header-section
  [headline]="'DL.NB01.C303'"
  [explanation]="'DL.NB01.C304'"
>
</mitpfa-pension-check-header-section>

<mitpfa-financial-situation-savings
  [financialSituation]="financialSituation"
  [form]="financialSituationForm"
></mitpfa-financial-situation-savings>

<mitpfa-financial-situation-real-estate
  [financialSituation]="financialSituation"
  [pensionCustomer]="pensionCustomer"
  [form]="financialSituationForm"
></mitpfa-financial-situation-real-estate>

<div class="next-button">
  <button
    [disabled]="!financialSituationForm.valid"
    mat-raised-button
    (click)="closePensionInfo()"
    [coContent]="'DL.NB01.C316'"
  ></button>
</div>
