/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const SpoergeskemaTypeNameSchema = JoiObj.string().valid(
        'CRITICAL_ILLNESS'
        ,
        'HEALTH_INSURANCE'
        ,
        'LOSS_OF_OCCUPATIONAL_CAPACITY'
        
    )
.id('SpoergeskemaTypeNameSchema')

export type SpoergeskemaTypeName = 'CRITICAL_ILLNESS' | 'HEALTH_INSURANCE' | 'LOSS_OF_OCCUPATIONAL_CAPACITY';

export const SpoergeskemaTypeName = {
    CriticalIllness: 'CRITICAL_ILLNESS' as SpoergeskemaTypeName,
    HealthInsurance: 'HEALTH_INSURANCE' as SpoergeskemaTypeName,
    LossOfOccupationalCapacity: 'LOSS_OF_OCCUPATIONAL_CAPACITY' as SpoergeskemaTypeName
};

