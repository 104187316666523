import { DestroyRef, inject, Injectable } from '@angular/core';
import { timer } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { PageScrollOptions, PageScrollService } from 'ngx-page-scroll-core';

@Injectable()
export class ScrollToService {
  private readonly pageScrollService: PageScrollService =
    inject(PageScrollService);
  private readonly destroyRef: DestroyRef = inject(DestroyRef);

  scrollTo(scrollToElement: string, elementWithScroll?) {
    const el: HTMLElement = document.querySelector(scrollToElement);
    const scrollEl = elementWithScroll
      ? document.querySelector(elementWithScroll)
      : window;
    if (el) {
      const offsetTop = el.offsetTop;
      scrollEl.scrollTop = offsetTop;
    }
  }

  public scrollToSmooth(
    target: string,
    scrollView: HTMLElement | undefined = undefined,
    offset = 0
  ): void {
    const options: PageScrollOptions = {
      document: document,
      scrollTarget: target,
      duration: 350,
      scrollOffset: offset
    };

    if (scrollView) {
      options.scrollViews = [scrollView];
    }

    timer(0)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.pageScrollService.scroll(options);
      });
  }
}
