import {
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { BrugerundersoegelseStore } from '@pfa/gen';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CustomerSurveyService } from '../../customer-survey.service';
import {
  CustomerSurveyStep,
  FoundAllYouWantedOptions
} from '../customer-survey.enum';
import { CustomerSurveyNavigationEvent } from '../customer-survey.model';
import { CustomerSurveyQuestionsFields } from './customer-survey-questions-fields';
import { CustomerSurveyQuestionsFormService } from './customer-survey-questions-form.service';
import { SurveyTrackingService } from '@pfa/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'mitpfa-customer-survey-questions',
  templateUrl: './customer-survey-questions.component.html',
  styleUrls: ['./customer-survey-questions.component.scss'],
  providers: [CustomerSurveyQuestionsFormService]
})
export class CustomerSurveyQuestionsComponent implements OnInit {
  @Input() surveyUuid: string;
  @Output()
  next: EventEmitter<CustomerSurveyNavigationEvent> = new EventEmitter();

  private readonly formService: CustomerSurveyQuestionsFormService = inject(
    CustomerSurveyQuestionsFormService
  );
  private readonly brugerundersoegelsesStore: BrugerundersoegelseStore = inject(
    BrugerundersoegelseStore
  );
  private readonly customerSurveyService: CustomerSurveyService = inject(
    CustomerSurveyService
  );
  private readonly surveyTrackingService: SurveyTrackingService = inject(
    SurveyTrackingService
  );
  private readonly destroyRef: DestroyRef = inject(DestroyRef);

  selectValues: { value: number; text: string }[] = [];
  foundAllYouWanted = FoundAllYouWantedOptions;
  fields = CustomerSurveyQuestionsFields;

  get form(): UntypedFormGroup {
    return this.formService.form;
  }

  ngOnInit() {
    this.formService.initializeForm();
    this.selectValues = this.formService.initializeSelectedValues();
  }

  submitCustomerSurveyForm() {
    const request = this.formService.applyFormValuesToModel();

    this.brugerundersoegelsesStore
      .brugerundersoegelseSvarPost(request)
      .pipe(
        catchError(() => of({})),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(() => {
        this.customerSurveyService.setPostSurveyCookie();
        this.surveyTrackingService.trackSurveyAnswers(this.surveyUuid, request);
        this.next.emit({ from: CustomerSurveyStep.Questions });
      });
  }
}
