import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ExtendedWindow } from '../models/extended-window';
import {
  AdobeScriptStore,
  adobeScriptDevelopment,
  adobeScriptStaging,
  adobeScriptProd
} from './adobe-script-loader.const';
import { LoadScriptStatus } from './script-loader.enum';
import { LoadScript, LoadScriptResult } from './script-loader.model';

declare let document: any;

@Injectable()
export class ScriptLoaderService {
  private scripts: Map<string, LoadScript> = new Map<string, LoadScript>();

  constructor() {
    AdobeScriptStore.forEach(script => {
      this.scripts[script.name] = {
        loaded: false,
        src: script.src
      };
    });
  }

  loadTrackingScriptEnvironmentSpecific(): Observable<LoadScriptResult> {
    if (!this.canLoadTrackingScript()) {
      return of({
        loaded: false,
        status: LoadScriptStatus.Forbidden
      } as LoadScriptResult);
    }

    if (
      window.location.hostname.indexOf('localhost') !== -1 ||
      window.location.hostname.indexOf('dev') !== -1 ||
      window.location.hostname.indexOf('test') !== -1
    ) {
      return this.loadScript(adobeScriptDevelopment);
    } else if (window.location.hostname.indexOf('uat') !== -1) {
      return this.loadScript(adobeScriptStaging);
    } else if (
      window.location.hostname.indexOf('mitpfa') !== -1 ||
      window.location.hostname.indexOf('mit.pfa') !== -1 ||
      window.location.hostname.indexOf('portal.pfa') !== -1 ||
      window.location.hostname.indexOf('intern') !== -1
    ) {
      return this.loadScript(adobeScriptProd);
    } else {
      return this.loadScript(adobeScriptDevelopment);
    }
  }

  canLoadTrackingScript(): boolean {
    return (window as unknown as ExtendedWindow).Cookiebot?.consent?.statistics;
  }

  private loadScript(name: string): Observable<LoadScriptResult> {
    return new Observable(subscriber => {
      if (!this.scripts[name].loaded) {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = this.scripts[name].src;
        if (script.readyState) {
          //IE
          script.onreadystatechange = () => {
            if (
              script.readyState === 'loaded' ||
              script.readyState === 'complete'
            ) {
              script.onreadystatechange = null;
              this.scripts[name].loaded = true;
              subscriber.next({
                script: name,
                loaded: true,
                status: LoadScriptStatus.Loaded
              } as LoadScriptResult);
            }
          };
        } else {
          //Others
          script.onload = () => {
            this.scripts[name].loaded = true;
            subscriber.next({
              script: name,
              loaded: true,
              status: LoadScriptStatus.Loaded
            } as LoadScriptResult);
          };
        }
        script.onerror = () =>
          of({
            script: name,
            loaded: false,
            status: LoadScriptStatus.Loaded
          } as LoadScriptResult);
        document.getElementsByTagName('head')[0].appendChild(script);
      } else {
        subscriber.next({
          script: name,
          loaded: true,
          status: LoadScriptStatus.AlreadyLoaded
        } as LoadScriptResult);
      }
    });
  }
}
