import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LegitimationApiService } from '@pfa/api';

@Component({
  selector: 'mitpfa-mitid-legitimation',
  template: '<co-loading [show]="true"></co-loading>'
})
export class MitidLegitimationComponent {
  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly legitimationApiService: LegitimationApiService
  ) {
    // example: /mitid-legitimation?returnUrl=%2Fmitoverblik%3Flang%3Den
    // redirects to returnUrl: /mitid-logged-in?returnUrl=%2Fmitoverblik%3Flang%3Den

    this.legitimationApiService.saveLegitimationWithMitId().subscribe(() => {
      this.router.navigate(['mitid-logged-in'], {
        queryParams: this.activatedRoute.snapshot.queryParams
      });
    });
  }
}
