<div class="pfa-climate-profiles">
  <div class="profile--container">
    <div class="profile">
      <div class="profile-title card-title">
        <span class="text--bold" [coContent]="'DL.INV.C492'"></span>
        <div class="close--button" (click)="closePercentageChooser(false)">
          <co-icon-times></co-icon-times>
        </div>
      </div>
      <div class="profile-content">
        <p [coContent]="'DL.INV.C493'"></p>
        <h2>{{ sliderSelectedValue }}%</h2>
        <co-slider
          [(model)]="sliderSelectedValue"
          [options]="sliderOptions"
          (modelChange)="sliderChangeEnd($event)"
          [enableGreenColor]="true"
        ></co-slider>
      </div>
      <div class="profile-footer">
        <div class="footer-container">
          <button
            [coContent]="'DL.INV.C494'"
            class="button-primary--level3"
            mat-raised-button
            (click)="showHidePercentInput()"
          ></button>
          <input
            [@expansion]
            *ngIf="showInputPercentage"
            [(ngModel)]="sliderSelectedValue"
            matInput
            autofocus
            [max]="100"
            [min]="0"
            type="number"
            class="footer-input"
          />
          <button
            class="footer-chose"
            (click)="closePercentageChooser(true)"
            mat-raised-button
          >
            {{ choose }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
