/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Skattekode } from './skattekode';
import { SkattekodeSchema } from './skattekode';
import { DaekningGruppe } from './daekningGruppe';
import { DaekningGruppeSchema } from './daekningGruppe';
import { YdelsesType } from './ydelsesType';
import { YdelsesTypeSchema } from './ydelsesType';
import { DaekningPrisInterface } from './daekningPrisInterface';
import { DaekningPrisInterfaceSchema } from './daekningPrisInterface';
import * as JoiObj from "joi";


    export const ForsikringDaekningPrisSchema = JoiObj.object({
        alder:
            
                    JoiObj.number()
                .integer()


.required()


        ,
        daekningGruppe:
            
        JoiObj.link('#DaekningGruppeSchema')

.allow(null)



        ,
        daekningId:
            
                    JoiObj.number()
                .integer()


.required()


        ,
        maanedligPrisBeloeb:
            
                    JoiObj.number()
                


.required()


        ,
        maanedligPrisUdenAdministrationBeloeb:
            
                    JoiObj.number()
                


.required()


        ,
        skattekode:
            
        JoiObj.link('#SkattekodeSchema')


.required()


        ,
        ydelseBeloeb:
            
                    JoiObj.number()
                


.required()


        ,
        ydelsesType:
            
        JoiObj.link('#YdelsesTypeSchema')

.allow(null)



        ,
        udbetalingsperiode:
            
                    JoiObj.number()
                .integer()

.allow(null)



        
    })
                .shared(DaekningGruppeSchema)
                .shared(SkattekodeSchema)
                .shared(YdelsesTypeSchema)
.id('ForsikringDaekningPrisSchema')

export interface ForsikringDaekningPris extends DaekningPrisInterface { 
    udbetalingsperiode?: number;
}
export namespace ForsikringDaekningPris {
}


