<mitpfa-pension-check-header-section
  [headline]="'DL.NB01.C311'"
  [explanation]="'DL.NB01.C312'"
>
</mitpfa-pension-check-header-section>

<ng-container *ngIf="hasSpouse">
  <p class="text--manchet" [coContent]="'DL.MD01.C327'"></p>
  <p [coContent]="'DL.MD01.C328'"></p>
</ng-container>
<mitpfa-family-partner-info
  [partnerInfoForm]="partnerInfoForm"
  [pensionCustomer]="pensionCustomer"
  [hasSpouse]="hasSpouse"
></mitpfa-family-partner-info>

<div class="next-button">
  <button
    [disabled]="
      !(
        partnerInfoForm.valid ||
        partnerInfoForm.get('hasPartner').value === false
      )
    "
    mat-raised-button
    (click)="complete()"
    [coContent]="'DL.NB01.C316'"
  ></button>
</div>
