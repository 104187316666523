<co-card
  class="card--small"
  [ngClass]="{ 'cursor-pointer': !errorFetchingData && !primaryPolicy?.fejlet }"
  data-test-id="frontpage-savings-investment"
  (click)="goToInvestment()"
  [showErrorMessage]="
    errorFetchingData || (initialised && primaryPolicy?.fejlet)
  "
>
  <co-loading [show]="!initialised"></co-loading>
  <div *ngIf="initialised">
    <mitpfa-dashboard-savings-header
      *ngIf="errorFetchingData || primaryPolicy?.fejlet"
      [content]="'DL.FS03.C45' | content"
      [hideArrow]="true"
    ></mitpfa-dashboard-savings-header>

    <div *ngIf="!errorFetchingData && !primaryPolicy?.fejlet">
      <mitpfa-dashboard-savings-header
        [content]="noInvestments ? ' ' : insuranceInfo.header"
        [hideArrow]="
          noInvestments || errorFetchingData || primaryPolicy?.fejlet
        "
      ></mitpfa-dashboard-savings-header>

      <div [ngClass]="{ 'profile text--emphasized': !hasPaidUpPolicesOnly }">
        {{ insuranceInfo.mainText }}
      </div>

      <ng-container *ngIf="!noInvestments && !hasPaidUpPolicesOnly">
        <div
          *ngIf="
            primaryPolicy?.livnetAftale && primaryPolicy?.type !== 'Gennemsnit'
          "
          [coContent]="'DL.FS03.C26'"
          class="spacer"
        >
          Markedsrente
        </div>

        <div
          *ngIf="
            primaryPolicy?.klimaPlusAndel > 0 &&
            (primaryPolicy?.pfaInvesterer?.harPfaInvesterer ||
              primaryPolicy?.pfaValgfri?.harPfaValgfri) &&
            !youInvestService.check100YouInvest(primaryPolicy)
          "
          class="climate-plus spacer"
          [coContent]="'DL.FS03.C29'"
          [coContentSubst]="primaryPolicy?.klimaPlusAndel"
        >
          Med XX% i PFA Klima Plus
        </div>

        <div *ngIf="hasMoreThanOneInvestmentType || hasMoreThanOnePolicy">
          <div
            *ngIf="
              hasMoreThanOneInvestmentType &&
              primaryPolicy?.duInvesterer?.harDuInvesterer &&
              !youInvestService.check100YouInvest(primaryPolicy)
            "
            class="spacer"
          >
            + <span class="text--emphasized" [coContent]="'DL.FS03.C30'"></span>
          </div>
          <div [coContent]="'DL.FS03.C31'" class="spacer"></div>
        </div>

        <div
          *ngIf="hasSavingsInMoneyMarketFund"
          [coContent]="'DL.FS03.C38'"
          class="spacer"
        ></div>

        <div *ngIf="hasPendingChanges" class="notification spacer">
          <co-icon-info></co-icon-info>
          <span [coContent]="'DL.FS03.C44'"></span>
        </div>

        <div
          *ngIf="
            !noInvestments &&
            primaryPolicy?.type === 'Gennemsnit' &&
            showPrimaryKrAndLivnet
          "
          [coContent]="'DL.FS03.C34'"
        ></div>
      </ng-container>
    </div>
  </div>

  <div
    *ngIf="errorFetchingData || (initialised && primaryPolicy?.fejlet)"
    card-additional
    class="error-overlay"
  >
    <co-icon-error></co-icon-error>
    <span [coContent]="'DL.FS03.C36' | content"></span>
  </div>
</co-card>
