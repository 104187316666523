import { Component, Input } from '@angular/core';
import { LinieElementOpsparing, OpsparingPoliceDetaljer } from '@pfa/gen';

@Component({
  selector: 'mitpfa-savings-policy-per-product',
  templateUrl: './savings-policy-per-product.component.html',
  styleUrls: ['./savings-policy-per-product.component.scss']
})
export class SavingsPolicyPerProductComponent {
  @Input() savingsPolicyDetails: OpsparingPoliceDetaljer;
  @Input() productAndConceptData: LinieElementOpsparing;
}
