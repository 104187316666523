import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { BasketApiService } from '@pfa/api';
import { ContentService } from '@pfa/core';
import { MineAendringer } from '@pfa/gen';

@Injectable({
  providedIn: 'root'
})
export class BasketResolver implements Resolve<MineAendringer> {
  constructor(
    private readonly basketApiService: BasketApiService,
    private readonly contentService: ContentService
  ) {}

  resolve():
    | MineAendringer
    | Observable<MineAendringer>
    | Promise<MineAendringer> {
    return this.basketApiService.getBasket(
      this.contentService.getLanguage().toUpperCase()
    );
  }
}
