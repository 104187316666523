/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ProfilEnum } from './profilEnum';
import { ProfilEnumSchema } from './profilEnum';
import { AarTilPensionFordeling } from './aarTilPensionFordeling';
import { AarTilPensionFordelingSchema } from './aarTilPensionFordeling';
import * as JoiObj from "joi";


    export const AktivSammensaetningProfilSchema = JoiObj.object({
        aartilpension:
                JoiObj.array().items(
        
        JoiObj.link('#AarTilPensionFordelingSchema')

        )
        

.required()


        ,
        profil:
            
        JoiObj.link('#ProfilEnumSchema')


.required()


        
    })
                .shared(AarTilPensionFordelingSchema)
                .shared(ProfilEnumSchema)
.id('AktivSammensaetningProfilSchema')

export interface AktivSammensaetningProfil { 
    aartilpension: Array<AarTilPensionFordeling>;
    profil: ProfilEnum;
}
export namespace AktivSammensaetningProfil {
}


