<div
  class="text--manchet header spacing-unit--double-under"
  coContent="DL.VK01.C99"
></div>
<co-card class="combine-savings-summary-card">
  <co-alert>
    <span class="text--bold" coContent="DL.VK01.C100"></span>
    <span>&nbsp;</span>
    <span coContent="DL.VK01.C102"></span>
  </co-alert>
  <div class="align--center spacing-unit--double-over">
    <button
      mat-raised-button
      class="button-secondary move-on"
      coContent="DL.VK01.C101"
      (click)="goToNextPage.emit()"
    ></button>
  </div>
</co-card>
