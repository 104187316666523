/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PeriodForecast } from './periodForecast';
import { PeriodForecastSchema } from './periodForecast';
import * as JoiObj from "joi";


    export const DataRowSchema = JoiObj.object({
        assetClass:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        forecasts:
                JoiObj.array().items(
        
        JoiObj.link('#PeriodForecastSchema')

        )
        

.required()


        ,
        fundName:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        
    })
                .shared(PeriodForecastSchema)
.id('DataRowSchema')

export interface DataRow { 
    assetClass: string;
    forecasts: Array<PeriodForecast>;
    fundName: string;
}

