import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { PensionPlanApiService } from '@pfa/api';
import { Observable } from 'rxjs';
import { Wealth } from '@pfa/gen';

@Injectable({ providedIn: 'root' })
export class WealthOverviewResolver implements Resolve<Wealth> {
  constructor(private readonly pensionPlanApiService: PensionPlanApiService) {}

  resolve(): Wealth | Observable<Wealth> | Promise<Wealth> {
    return this.pensionPlanApiService.wealth();
  }
}
