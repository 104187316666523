import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { PensionsKundeGenerelleData, PensionskundeStore } from '@pfa/gen';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PensionCustomerResolver
  implements Resolve<PensionsKundeGenerelleData>
{
  constructor(private pensionskundeStore: PensionskundeStore) {}

  resolve():
    | PensionsKundeGenerelleData
    | Observable<PensionsKundeGenerelleData>
    | Promise<PensionsKundeGenerelleData> {
    return this.pensionskundeStore.pensionskundeGet();
  }
}
