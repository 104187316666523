<p class="text--manchet form-title">{{ 'DL.ST01.C119' | content }}</p>
<form *ngIf="contactDetailsForm" [formGroup]="contactDetailsForm">
  <div class="phone-control">
    <mat-form-field
      class="form-country-code"
      [ngClass]="{ 'form-field-light': light }"
      color="accent"
    >
      <mat-label>{{ 'DL.ST01.C82' | content }}</mat-label>
      <co-country-code-select
        [formControl]="contactDetailsForm.controls.landekode"
      ></co-country-code-select>
    </mat-form-field>

    <mat-form-field
      class="form-mobile"
      [ngClass]="{ 'form-field-light': light }"
      color="accent"
    >
      <mat-label>{{ 'DL.ST01.C05' | content }}</mat-label>
      <input matInput [formControl]="contactDetailsForm.controls.mobil" />
      <mat-error
        *ngIf="contactDetailsForm.controls.mobil.errors?.required"
        [coContent]="'DL.MD01.C12'"
      ></mat-error>
      <mat-error
        *ngIf="contactDetailsForm.controls.mobil.errors?.invalidPhoneNumber"
        [coContent]="'DL.ST01.C12'"
      ></mat-error>
    </mat-form-field>
  </div>

  <div class="email-control">
    <mat-form-field [ngClass]="{ 'form-field-light': light }" color="accent">
      <mat-label>{{ 'DL.ST01.C06' | content }}</mat-label>
      <input matInput [formControl]="contactDetailsForm.controls.kundeEmail" />
      <mat-error
        *ngIf="contactDetailsForm.controls.kundeEmail.errors?.required"
        [coContent]="'DL.MD01.C12'"
      ></mat-error>
      <mat-error
        *ngIf="contactDetailsForm.controls.kundeEmail.errors?.email"
        [coContent]="'DL.OV01.C503'"
      ></mat-error>
    </mat-form-field>
  </div>
</form>
<ng-container *ngIf="pensionCustomer">
  <co-alert
    *ngIf="!pensionCustomer.customerMobilePhoneValid"
    [ngClass]="{ 'spacing-unit--under': !pensionCustomer.customerEmailValid }"
    class="alert-inline"
  >
    <span [coContent]="'DL.ST01.C208'"></span>
  </co-alert>
  <co-alert
    *ngIf="!pensionCustomer.customerEmailValid"
    class="alert-inline spacing-unit--under"
  >
    <span [coContent]="'DL.ST01.C207'"></span>
  </co-alert>
</ng-container>
<div class="button--container">
  <div [coContent]="'DL.AP02.C46'"></div>
  <button
    mat-raised-button
    class="save-button"
    [disabled]="contactDetailsForm?.invalid"
    (click)="saveContactDetails()"
    data-test-id="acceptAndSaveContactDetails"
  >
    {{ 'DL.MD01.C166' | content }}
  </button>
</div>
