/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { QuotationElement } from './quotationElement';
import { QuotationElementSchema } from './quotationElement';
import * as JoiObj from "joi";


    export const PrepareSimulationResponseSchema = JoiObj.object({
        indregningFejl:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        quotationElement:
            
        JoiObj.link('#QuotationElementSchema')


.required()


        
    })
                .shared(QuotationElementSchema)
.id('PrepareSimulationResponseSchema')

export interface PrepareSimulationResponse { 
    indregningFejl: string;
    quotationElement: QuotationElement;
}

