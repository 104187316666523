/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DynamicAdvisingInvestmentConceptType } from './dynamicAdvisingInvestmentConceptType';
import { DynamicAdvisingInvestmentConceptTypeSchema } from './dynamicAdvisingInvestmentConceptType';
import { DynamicAdvisingAutoAdjustmentRecommendation } from './dynamicAdvisingAutoAdjustmentRecommendation';
import { DynamicAdvisingAutoAdjustmentRecommendationSchema } from './dynamicAdvisingAutoAdjustmentRecommendation';
import { DynamicAdvisingInvestmentConceptShare } from './dynamicAdvisingInvestmentConceptShare';
import { DynamicAdvisingInvestmentConceptShareSchema } from './dynamicAdvisingInvestmentConceptShare';
import { DynamicAdvisingAutoAdjustmentStatus } from './dynamicAdvisingAutoAdjustmentStatus';
import { DynamicAdvisingAutoAdjustmentStatusSchema } from './dynamicAdvisingAutoAdjustmentStatus';
import { DynamicAdvisingInvestmentProfile } from './dynamicAdvisingInvestmentProfile';
import { DynamicAdvisingInvestmentProfileSchema } from './dynamicAdvisingInvestmentProfile';
import { DynamicAdvisingAutoAdjustmentAllowed } from './dynamicAdvisingAutoAdjustmentAllowed';
import { DynamicAdvisingAutoAdjustmentAllowedSchema } from './dynamicAdvisingAutoAdjustmentAllowed';
import * as JoiObj from "joi";


    export const DynamicAdvisingBasisValuesSchema = JoiObj.object({
        automaticAdjustmentAllowed:
                JoiObj.array().items(
        
        JoiObj.link('#DynamicAdvisingAutoAdjustmentAllowedSchema')

        )
        
.allow(null)



        ,
        automaticAdjustmentRecommendation:
            
        JoiObj.link('#DynamicAdvisingAutoAdjustmentRecommendationSchema')

.allow(null)



        ,
        automaticAdjustmentStatus:
            
        JoiObj.link('#DynamicAdvisingAutoAdjustmentStatusSchema')

.allow(null)



        ,
        climatePercent:
            
                    JoiObj.number()
                .integer()


.required()


        ,
        confirmedBeneficiary:
            
                    JoiObj.boolean()
                

.allow(null)



        ,
        confirmedBeneficiaryDate:
            
            JoiObj.date()

.allow(null)



        ,
        confirmedClimatePercent:
            
                    JoiObj.boolean()
                

.allow(null)



        ,
        confirmedClimatePercentDate:
            
            JoiObj.date()

.allow(null)



        ,
        confirmedExternalPensionFunds:
            
                    JoiObj.boolean()
                

.allow(null)



        ,
        confirmedExternalPensionFundsDate:
            
            JoiObj.date()

.allow(null)



        ,
        confirmedInternalPensionFunds:
            
                    JoiObj.boolean()
                

.allow(null)



        ,
        confirmedInternalPensionFundsDate:
            
            JoiObj.date()

.allow(null)



        ,
        confirmedInvestmentConceptPfaOptional:
            
                    JoiObj.boolean()
                

.allow(null)



        ,
        confirmedInvestmentConceptPfaOptionalDate:
            
            JoiObj.date()

.allow(null)



        ,
        confirmedInvestmentConceptYouInvest:
            
                    JoiObj.boolean()
                

.allow(null)



        ,
        confirmedInvestmentConceptYouInvestDate:
            
            JoiObj.date()

.allow(null)



        ,
        confirmedInvestmentProfile:
            
                    JoiObj.boolean()
                

.allow(null)



        ,
        confirmedInvestmentProfileDate:
            
            JoiObj.date()

.allow(null)



        ,
        confirmedLifeInsurance:
            
                    JoiObj.boolean()
                

.allow(null)



        ,
        confirmedLifeInsuranceDate:
            
            JoiObj.date()

.allow(null)



        ,
        confirmedPensionEstimate:
            
                    JoiObj.boolean()
                

.allow(null)



        ,
        confirmedPensionEstimateDate:
            
            JoiObj.date()

.allow(null)



        ,
        confirmedTae:
            
                    JoiObj.boolean()
                

.allow(null)



        ,
        confirmedTaeDate:
            
            JoiObj.date()

.allow(null)



        ,
        customerAge:
            
                    JoiObj.number()
                .integer()

.allow(null)



        ,
        externalPensionFundsSum:
            
                    JoiObj.number()
                

.allow(null)



        ,
        externalPensionFundsTransferPossibleSum:
            
                    JoiObj.number()
                

.allow(null)



        ,
        hasNextOfKinBeneficiary:
            
                    JoiObj.boolean()
                

.allow(null)



        ,
        investmentConceptShares:
                JoiObj.array().items(
        
        JoiObj.link('#DynamicAdvisingInvestmentConceptShareSchema')

        )
        

.required()


        ,
        investmentConceptType:
            
        JoiObj.link('#DynamicAdvisingInvestmentConceptTypeSchema')


.required()


        ,
        investmentProfile:
            
        JoiObj.link('#DynamicAdvisingInvestmentProfileSchema')


.required()


        ,
        lifeInsuranceEstimateValue:
            
                    JoiObj.number()
                

.allow(null)



        ,
        pensionEstimateValue:
            
                    JoiObj.number()
                

.allow(null)



        ,
        plannedPensionAge:
            
                    JoiObj.number()
                .integer()

.allow(null)



        ,
        primaryPolicy:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        recommendationClimatePercent:
            
                    JoiObj.number()
                .integer()

.allow(null)



        ,
        recommendationInvestmentConceptType:
            
        JoiObj.link('#DynamicAdvisingInvestmentConceptTypeSchema')

.allow(null)



        ,
        recommendationInvestmentProfile:
            
        JoiObj.link('#DynamicAdvisingInvestmentProfileSchema')

.allow(null)



        ,
        recommendationLifeInsurance:
            
                    JoiObj.number()
                .integer()

.allow(null)



        ,
        recommendationTae:
            
                    JoiObj.number()
                .integer()

.allow(null)



        ,
        taeEstimateValue:
            
                    JoiObj.number()
                

.allow(null)



        ,
        totalPfaDeposits:
            
                    JoiObj.number()
                

.allow(null)



        
    })
                .shared(DynamicAdvisingAutoAdjustmentAllowedSchema)
                .shared(DynamicAdvisingAutoAdjustmentRecommendationSchema)
                .shared(DynamicAdvisingAutoAdjustmentStatusSchema)
                .shared(DynamicAdvisingInvestmentConceptShareSchema)
                .shared(DynamicAdvisingInvestmentConceptTypeSchema)
                .shared(DynamicAdvisingInvestmentProfileSchema)
                .shared(DynamicAdvisingInvestmentConceptTypeSchema)
                .shared(DynamicAdvisingInvestmentProfileSchema)
.id('DynamicAdvisingBasisValuesSchema')

export interface DynamicAdvisingBasisValues { 
    automaticAdjustmentAllowed?: Array<DynamicAdvisingAutoAdjustmentAllowed>;
    automaticAdjustmentRecommendation?: DynamicAdvisingAutoAdjustmentRecommendation;
    automaticAdjustmentStatus?: DynamicAdvisingAutoAdjustmentStatus;
    climatePercent: number;
    confirmedBeneficiary?: boolean;
    confirmedBeneficiaryDate?: Date;
    confirmedClimatePercent?: boolean;
    confirmedClimatePercentDate?: Date;
    confirmedExternalPensionFunds?: boolean;
    confirmedExternalPensionFundsDate?: Date;
    confirmedInternalPensionFunds?: boolean;
    confirmedInternalPensionFundsDate?: Date;
    confirmedInvestmentConceptPfaOptional?: boolean;
    confirmedInvestmentConceptPfaOptionalDate?: Date;
    confirmedInvestmentConceptYouInvest?: boolean;
    confirmedInvestmentConceptYouInvestDate?: Date;
    confirmedInvestmentProfile?: boolean;
    confirmedInvestmentProfileDate?: Date;
    confirmedLifeInsurance?: boolean;
    confirmedLifeInsuranceDate?: Date;
    confirmedPensionEstimate?: boolean;
    confirmedPensionEstimateDate?: Date;
    confirmedTae?: boolean;
    confirmedTaeDate?: Date;
    customerAge?: number;
    externalPensionFundsSum?: number;
    externalPensionFundsTransferPossibleSum?: number;
    hasNextOfKinBeneficiary?: boolean;
    investmentConceptShares: Array<DynamicAdvisingInvestmentConceptShare>;
    investmentConceptType: DynamicAdvisingInvestmentConceptType;
    investmentProfile: DynamicAdvisingInvestmentProfile;
    lifeInsuranceEstimateValue?: number;
    pensionEstimateValue?: number;
    plannedPensionAge?: number;
    primaryPolicy?: string;
    recommendationClimatePercent?: number;
    recommendationInvestmentConceptType?: DynamicAdvisingInvestmentConceptType;
    recommendationInvestmentProfile?: DynamicAdvisingInvestmentProfile;
    recommendationLifeInsurance?: number;
    recommendationTae?: number;
    taeEstimateValue?: number;
    totalPfaDeposits?: number;
}
export namespace DynamicAdvisingBasisValues {
}


