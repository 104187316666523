/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const ClimateRelevanceSchema = JoiObj.string().valid(
        'HIGH'
        ,
        'MEDIUM'
        ,
        'LOW'
        
    )
.id('ClimateRelevanceSchema')

export type ClimateRelevance = 'HIGH' | 'MEDIUM' | 'LOW';

export const ClimateRelevance = {
    High: 'HIGH' as ClimateRelevance,
    Medium: 'MEDIUM' as ClimateRelevance,
    Low: 'LOW' as ClimateRelevance
};

