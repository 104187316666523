import { MatDialogRef } from '@angular/material/dialog';
import { Component } from '@angular/core';
import { InsuranceAdjustmentDeleteAdvisorRecommendationResponse } from './insurance-adjustment-delete-advisor-rec-popup.model';

@Component({
  selector: 'mitpfa-insurance-adjustment-delete-advisor-rec-popup',
  templateUrl: './insurance-adjustment-delete-advisor-rec-popup.component.html',
  styleUrls: ['./insurance-adjustment-delete-advisor-rec-popup.component.scss']
})
export class InsuranceAdjustmentDeleteAdvisorRecPopupComponent {
  constructor(
    private readonly dialogRef: MatDialogRef<InsuranceAdjustmentDeleteAdvisorRecPopupComponent>
  ) {}

  accept(): void {
    const response: InsuranceAdjustmentDeleteAdvisorRecommendationResponse = {
      result: 'ACCEPT'
    };
    this.dialogRef.close(response);
  }

  cancel(): void {
    const response: InsuranceAdjustmentDeleteAdvisorRecommendationResponse = {
      result: 'CANCEL'
    };
    this.dialogRef.close(response);
  }
}
