import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  inject,
  DestroyRef,
  OnInit
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CustomerContactApiService } from '@pfa/api';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { PhoneNumberValidator } from '@pfa/utils';
import { GlobalWorkingService } from '../global-working/global-working.service';
import {
  CustomerprofileStore,
  PensionsKundeGenerelleData,
  PensionskundeStore
} from '@pfa/gen';

import {
  NotificationService,
  NotificationType
} from '../../services/notification.service';

@Component({
  selector: 'co-customer-contact-details',
  templateUrl: './customer-contact-details.component.html',
  styleUrls: ['./customer-contact-details.component.scss']
})
export class CustomerContactDetailsComponent implements OnInit, OnChanges {
  private readonly customerContactApiService: CustomerContactApiService =
    inject(CustomerContactApiService);
  private readonly globalWorkingService: GlobalWorkingService =
    inject(GlobalWorkingService);
  private readonly notificationService: NotificationService =
    inject(NotificationService);
  private readonly pensionskundeStore: PensionskundeStore =
    inject(PensionskundeStore);
  private readonly destroyRef: DestroyRef = inject(DestroyRef);
  private readonly customerprofileStore: CustomerprofileStore =
    inject(CustomerprofileStore);

  @Input() pensionCustomer: PensionsKundeGenerelleData;
  @Input() phoneRequired = false;
  @Input() emailRequired = false;
  @Input() light: boolean;

  @Output() contactDetailsUpdate: EventEmitter<void> = new EventEmitter<void>();
  @Output() progressChange: EventEmitter<number> = new EventEmitter<number>();
  @Output() form: EventEmitter<UntypedFormGroup> =
    new EventEmitter<UntypedFormGroup>();

  public contactDetailsForm: UntypedFormGroup;

  private readonly defaultDialCode = '+45';

  public ngOnInit(): void {
    const mobileControl = this.contactDetailsForm.get('mobil');
    const emailControl = this.contactDetailsForm.get('kundeEmail');

    mobileControl.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        if (!mobileControl.errors) {
          this.pensionCustomer.customerMobilePhoneValid = true;
        }
      });

    emailControl.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        if (!emailControl.errors) {
          this.pensionCustomer.customerEmailValid = true;
        }
      });
  }

  public ngOnChanges(): void {
    this.initializeContactDetailsForm(this.pensionCustomer);
  }

  public saveContactDetails(): void {
    this.globalWorkingService.show();
    this.customerContactApiService
      .updateContactInformation(this.contactDetailsForm.value)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: () => {
          this.globalWorkingService.hide();
          this.pensionskundeStore.invalidatepensionskundeIndex();
          this.customerprofileStore.invalidatecustomerprofileIndex();
          this.contactDetailsForm.reset(this.contactDetailsForm.value);
          this.contactDetailsUpdate.emit();
          this.setStepProgress();

          this.notificationService.showNotification({
            message: 'DL.MD01.C464',
            type: NotificationType.SUCCESS
          });
        },
        error: () => {
          this.globalWorkingService.hide();
          this.notificationService.showNotification({
            message: undefined,
            type: NotificationType.ERROR
          });
        }
      });
  }

  private setStepProgress(): void {
    if (
      this.pensionCustomer?.customerEmailValid &&
      this.pensionCustomer?.customerMobilePhoneValid
    ) {
      this.progressChange.emit(
        [
          this.contactDetailsForm.value.mobil,
          this.contactDetailsForm.value.kundeEmail
        ].reduce((acc, curr) => (curr ? ++acc : acc), 0) * 50
      );
    }
  }

  private initializeContactDetailsForm(data: PensionsKundeGenerelleData): void {
    this.contactDetailsForm = new UntypedFormGroup({
      landekode: new UntypedFormControl(
        data?.landekode || this.defaultDialCode
      ),
      mobil: new UntypedFormControl(data?.mobil, [
        PhoneNumberValidator,
        ...(data?.mobil || this.phoneRequired ? [Validators.required] : [])
      ]),
      kundeEmail: new UntypedFormControl(data?.kundeEmail, [
        Validators.email,
        ...(this.emailRequired || data?.kundeEmail ? [Validators.required] : [])
      ])
    });
    this.form.emit(this.contactDetailsForm);
    this.setStepProgress();
  }
}
