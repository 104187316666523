import { Component, EventEmitter, inject, OnInit, Output } from '@angular/core';
import { OnboardingService } from '../onboarding.service';

@Component({
  selector: 'mitpfa-onboarding-step-end',
  templateUrl: './step-end.component.html',
  styleUrls: ['./step-end.component.scss']
})
export class StepEndComponent implements OnInit {
  @Output() goTo = new EventEmitter();
  public changeHeadline = false;
  public showInsurance = false;
  public showInvestment = false;
  public doneCards = 3;
  public stepNumber = 4;
  private readonly onboardingService: OnboardingService =
    inject(OnboardingService);

  public ngOnInit(): void {
    this.showInvestment = this.onboardingService.getShowInvestment();
    this.showInsurance = this.onboardingService.getShowInsurance();

    if (!this.showInsurance && !this.showInvestment) {
      this.stepNumber = 2;
      this.doneCards = 1;
    } else if (
      (this.showInvestment && !this.showInsurance) ||
      (!this.showInvestment && this.showInsurance)
    ) {
      this.stepNumber = 3;
      this.doneCards = 2;
    }

    setTimeout(() => {
      this.changeHeadline = true;
    }, 4000);

    setTimeout(() => {
      this.goTo.emit();
    }, 6000);
  }
}
