/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Question } from './question';
import { QuestionSchema } from './question';
import * as JoiObj from "joi";


    export const QuestionnaireViewSchema = JoiObj.object({
        headline:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        healthCaseId:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        id:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        locale:
            
                    JoiObj.object()
                


.required()


        ,
        questions:
                JoiObj.array().items(
        
        JoiObj.link('#QuestionSchema')

        )
        

.required()


        
    })
                .shared(QuestionSchema)
.id('QuestionnaireViewSchema')

export interface QuestionnaireView { 
    headline: string;
    healthCaseId: string;
    id: string;
    locale: object;
    questions: Array<Question>;
}

