/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const AaopAaokSchema = JoiObj.object({
        aaokaaopDifferenceSumKroner:
            
                    JoiObj.number()
                


.required()


        ,
        aaokaaopDifferenceSumProcent:
            
                    JoiObj.number()
                


.required()


        ,
        aaokaaopEndeligSumKroner:
            
                    JoiObj.number()
                


.required()


        ,
        aaokaaopEndeligSumProcent:
            
                    JoiObj.number()
                


.required()


        ,
        aaokaaopForeloebligSumKroner:
            
                    JoiObj.number()
                


.required()


        ,
        aaokaaopForeloebligSumProcent:
            
                    JoiObj.number()
                


.required()


        ,
        adminDetaljerAdminOmkostninger:
            
                    JoiObj.number()
                

.allow(null)



        ,
        adminDetaljerForsikringsOmkostninger:
            
                    JoiObj.number()
                

.allow(null)



        ,
        adminOmkostningerKroner:
            
                    JoiObj.number()
                


.required()


        ,
        adminOmkostningerProcent:
            
                    JoiObj.number()
                


.required()


        ,
        indirekteDetaljerDriftsherretillaeg:
            
                    JoiObj.number()
                

.allow(null)



        ,
        indirekteDetaljerInvesteringsomkostninger:
            
                    JoiObj.number()
                

.allow(null)



        ,
        indirekteDetaljerOmkostningsrisikoresultat:
            
                    JoiObj.number()
                

.allow(null)



        ,
        indirekteForeloebligOmkostningerKroner:
            
                    JoiObj.number()
                


.required()


        ,
        indirekteForeloebligOmkostningerProcent:
            
                    JoiObj.number()
                


.required()


        ,
        ikkeTilskrevetRabatIndirekteFondOmkostningerBeloeb:
            
                    JoiObj.number()
                

.allow(null)



        ,
        samletRabatOgTilskudBeloeb:
            
                    JoiObj.number()
                

.allow(null)



        
    })
.id('AaopAaokSchema')

export interface AaopAaok { 
    aaokaaopDifferenceSumKroner: number;
    aaokaaopDifferenceSumProcent: number;
    aaokaaopEndeligSumKroner: number;
    aaokaaopEndeligSumProcent: number;
    aaokaaopForeloebligSumKroner: number;
    aaokaaopForeloebligSumProcent: number;
    adminDetaljerAdminOmkostninger?: number;
    adminDetaljerForsikringsOmkostninger?: number;
    adminOmkostningerKroner: number;
    adminOmkostningerProcent: number;
    indirekteDetaljerDriftsherretillaeg?: number;
    indirekteDetaljerInvesteringsomkostninger?: number;
    indirekteDetaljerOmkostningsrisikoresultat?: number;
    indirekteForeloebligOmkostningerKroner: number;
    indirekteForeloebligOmkostningerProcent: number;
    ikkeTilskrevetRabatIndirekteFondOmkostningerBeloeb?: number;
    samletRabatOgTilskudBeloeb?: number;
}

