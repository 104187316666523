import { MatInputModule } from '@angular/material/input';
import { AmountDirective } from './content/amount.directive';
import { SessionTimeoutService } from './session-timeout/session-timeout.service';
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ContentDirective } from './content/content.directive';
import { ContentPipe } from './content/content.pipe';
import { POPUP_UTIL_SERVICE } from './popup-util-service';
import { ContentUtilService } from './content/content-util.service';
import { ForbeholdModalComponent } from './forbehold/forbehold-modal.component';
import { ForbeholdModalService } from './forbehold/forbehold-modal.service';
import { PopupUtilServiceImpl } from './popup-util-service-impl';
import { DataTestIdDirective } from './datatestid/data-test-id.directive';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { NumberFormatPipe } from './number-format.pipe';
import { SessionTimeoutComponent } from './session-timeout/session-timeout.component';
import { SessionTimeoutExtenderService } from './session-timeout/session-timeout-extender.service';
import { SessionTimeoutModalComponent } from './session-timeout/session-timeout-modal.component';
import { CurrencyFormatPipe } from './currency-format.pipe';
import { GuideTrackingService } from './analytics/guide-tracking.service';
import { FormTrackingService } from './analytics/form-tracking.service';
import { MiscTrackingService } from './analytics/misc-tracking.service';
import { ConsentTrackingService } from './analytics/consent-tracking.service';
import { ScriptLoaderService } from './script-loader/script-loader.service';
import { DigitalDataTrackingService } from './analytics/digitaldata-tracking.service';
import { DateMonthStringFormatPipe } from './date-month-format.pipe';
import { StripHtmlPipe } from './strip-html.pipe';
import { PreconditionsModalComponent } from './preconditions-modal/preconditions-modal.component';
import { PreconditionsComponent } from './preconditions/preconditions.component';
import { RouterModule } from '@angular/router';
import { PayoutPlanTrackingService } from './analytics/payout-plan-tracking.service';
import { AccordionTrackingService } from './analytics/accordion-tracking.service';
import { OrderTrackingService } from './analytics/order-tracking.service';
import { ArticleModalComponent } from './articles/article-modal/article-modal.component';
import { SurveyTrackingService } from './analytics/survey-tracking.service';
import { PensionEstimateTrackingService } from './analytics/pension-estimate-tracking.service';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatInputModule,
    RouterModule
  ],
  declarations: [
    AmountDirective,
    ContentDirective,
    ContentPipe,
    ForbeholdModalComponent,
    DataTestIdDirective,
    NumberFormatPipe,
    CurrencyFormatPipe,
    StripHtmlPipe,
    SessionTimeoutComponent,
    SessionTimeoutModalComponent,
    DateMonthStringFormatPipe,
    PreconditionsModalComponent,
    PreconditionsComponent,
    ArticleModalComponent
  ],
  exports: [
    AmountDirective,
    ContentDirective,
    ContentPipe,
    ForbeholdModalComponent,
    DataTestIdDirective,
    NumberFormatPipe,
    CurrencyFormatPipe,
    StripHtmlPipe,
    SessionTimeoutComponent,
    DateMonthStringFormatPipe,
    PreconditionsComponent
  ],
  providers: [
    { provide: POPUP_UTIL_SERVICE, useClass: PopupUtilServiceImpl },
    ContentUtilService,
    ForbeholdModalService,
    SessionTimeoutExtenderService,
    SessionTimeoutService,
    ForbeholdModalService,
    GuideTrackingService,
    FormTrackingService,
    MiscTrackingService,
    ConsentTrackingService,
    PayoutPlanTrackingService,
    PensionEstimateTrackingService,
    OrderTrackingService,
    ContentPipe,
    NumberFormatPipe,
    CurrencyFormatPipe,
    StripHtmlPipe,
    ScriptLoaderService,
    DigitalDataTrackingService,
    AccordionTrackingService,
    SurveyTrackingService
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CoreModule {}
