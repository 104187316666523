/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const YdelsesTypeSchema = JoiObj.string().valid(
        'Grafik'
        ,
        'FastText'
        ,
        'Beloeb'
        
    )
.id('YdelsesTypeSchema')

export type YdelsesType = 'Grafik' | 'FastText' | 'Beloeb';

export const YdelsesType = {
    Grafik: 'Grafik' as YdelsesType,
    FastText: 'FastText' as YdelsesType,
    Beloeb: 'Beloeb' as YdelsesType
};

