/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const DropRegelSchema = JoiObj.string().valid(
        'CPR'
        ,
        'LIVNET_DELAFTALE'
        ,
        'AFTALE'
        ,
        'SAERLIG_ORDNING'
        
    )
.id('DropRegelSchema')

export type DropRegel = 'CPR' | 'LIVNET_DELAFTALE' | 'AFTALE' | 'SAERLIG_ORDNING';

export const DropRegel = {
    Cpr: 'CPR' as DropRegel,
    LivnetDelaftale: 'LIVNET_DELAFTALE' as DropRegel,
    Aftale: 'AFTALE' as DropRegel,
    SaerligOrdning: 'SAERLIG_ORDNING' as DropRegel
};

