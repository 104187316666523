import { Pipe, PipeTransform } from '@angular/core';
import { Utils } from '@pfa/utils';

@Pipe({
  name: 'numberFormat'
})
export class NumberFormatPipe implements PipeTransform {
  transform(value: any, decimals?: number): string {
    return value || value === 0
      ? Utils.formatNumber(
          typeof value === 'string' ? Number(value) : value,
          decimals
        )
      : '';
  }
}
