/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const SavingsSchema = JoiObj.object({
        bonds:
            
                    JoiObj.number()
                


.required()


        ,
        cash:
            
                    JoiObj.number()
                


.required()


        ,
        otherSavingsThanPension:
            
                    JoiObj.boolean()
                


.required()


        ,
        retirement:
            
                    JoiObj.number()
                


.required()


        ,
        shares:
            
                    JoiObj.number()
                


.required()


        
    })
.id('SavingsSchema')

export interface Savings { 
    bonds: number;
    cash: number;
    otherSavingsThanPension: boolean;
    retirement: number;
    shares: number;
}

