import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import {RestCacheEntry} from "./RestCacheEntry";
import {GenericStore} from "./GenericStore";
import * as JoiObj from "joi";

import {
        AfkastDetaljer,
        AfkastDetaljerSchema,
        Afkastoversigt,
        AfkastoversigtSchema,
        AkkAfkast,
        AkkAfkastSchema,
        AkkAfkastDetaljer,
        AkkAfkastDetaljerSchema
} from '../model/models';

import { SavingsreturnService } from './savingsreturn.service';


@Injectable({
    providedIn: 'root'
})
export class SavingsreturnStore extends GenericStore {

            private afkastvalgtPolicenummervalgtAarShowCache: Map<string, RestCacheEntry<AfkastDetaljer>> = new Map<string, RestCacheEntry<AfkastDetaljer>>();
            private akkafkastvalgtPolicenummervalgtPeriodeShowCache: Map<string, RestCacheEntry<AkkAfkastDetaljer>> = new Map<string, RestCacheEntry<AkkAfkastDetaljer>>();
            private afkastIndexCache: Map<string, RestCacheEntry<Afkastoversigt>> = new Map<string, RestCacheEntry<Afkastoversigt>>();
            private akkafkastafkastfraordningensstartIndexCache: Map<string, RestCacheEntry<AkkAfkast>> = new Map<string, RestCacheEntry<AkkAfkast>>();
            private afkastvalgtPolicenummerstartDatoslutDatoShowCache: Map<string, RestCacheEntry<AfkastDetaljer>> = new Map<string, RestCacheEntry<AfkastDetaljer>>();

    constructor(private savingsreturnService: SavingsreturnService) {
        super();
    }

    invalidateAll(): void {
                
                this.invalidateafkastIndex();
                this.invalidateafkastvalgtPolicenummerstartDatoslutDatoShow();
                
                this.invalidateafkastvalgtPolicenummervalgtAarShow();
                
                
                this.invalidateakkafkastafkastfraordningensstartIndex();
                this.invalidateakkafkastvalgtPolicenummervalgtPeriodeShow();
                
    }


                public afkastGet() : Observable<Afkastoversigt> {
                    let hash = "0";
                    if (this.afkastIndexCache.has(hash)) {
                        return this.afkastIndexCache.get(hash)!.observable;
                    } else {
                        let afkastIndexSubject: BehaviorSubject<0> = new BehaviorSubject(0);
                        let afkastIndex = this.initiateStream(afkastIndexSubject, this.savingsreturnService.afkastGet(), AfkastoversigtSchema);
                        this.afkastIndexCache.set(hash, new RestCacheEntry<Afkastoversigt>(afkastIndex, afkastIndexSubject));
                        return afkastIndex;
                    }
                }

                public invalidateafkastIndex() {
                    this.afkastIndexCache.forEach((cacheEntry) => {
                        cacheEntry.subject.next(0);
                    })
                }





                public afkastValgtPolicenummerStartDatoSlutDatoGet(valgtPolicenummer: string,startDato: string,slutDato: string) : Observable<AfkastDetaljer> {
                    let hash = this.hashKey((valgtPolicenummer.toString() || "0") + (startDato.toString() || "0") + (slutDato.toString() || "0"));
                    if (this.afkastvalgtPolicenummerstartDatoslutDatoShowCache.has(hash)) {
                        return this.afkastvalgtPolicenummerstartDatoslutDatoShowCache.get(hash)!.observable;
                    } else {
                        let afkastvalgtPolicenummerstartDatoslutDatoShowSubject: BehaviorSubject<0> = new BehaviorSubject(0);
                        let afkastvalgtPolicenummerstartDatoslutDatoShow = this.initiateStream(afkastvalgtPolicenummerstartDatoslutDatoShowSubject, this.savingsreturnService.afkastValgtPolicenummerStartDatoSlutDatoGet(valgtPolicenummer,startDato,slutDato), AfkastDetaljerSchema);
                        this.afkastvalgtPolicenummerstartDatoslutDatoShowCache.set(hash, new RestCacheEntry<AfkastDetaljer>(afkastvalgtPolicenummerstartDatoslutDatoShow, afkastvalgtPolicenummerstartDatoslutDatoShowSubject));
                        return afkastvalgtPolicenummerstartDatoslutDatoShow;
                    }
                }

                public invalidateafkastvalgtPolicenummerstartDatoslutDatoShowById(valgtPolicenummer: string,startDato: string,slutDato: string) {
                    let hash = this.hashKey((valgtPolicenummer.toString() || "0") + (startDato.toString() || "0") + (slutDato.toString() || "0"));
                    if (this.afkastvalgtPolicenummerstartDatoslutDatoShowCache.has(hash)) {
                        this.afkastvalgtPolicenummerstartDatoslutDatoShowCache.get(hash)!.subject.next(0);
                    }
                }

                public invalidateafkastvalgtPolicenummerstartDatoslutDatoShow() {
                    this.afkastvalgtPolicenummerstartDatoslutDatoShowCache.forEach((cacheEntry) => {
                        cacheEntry.subject.next(0);
                    })
                }






                public afkastValgtPolicenummerValgtAarGet(valgtPolicenummer: string,valgtAar: number) : Observable<AfkastDetaljer> {
                    let hash = this.hashKey((valgtPolicenummer.toString() || "0") + (valgtAar.toString() || "0"));
                    if (this.afkastvalgtPolicenummervalgtAarShowCache.has(hash)) {
                        return this.afkastvalgtPolicenummervalgtAarShowCache.get(hash)!.observable;
                    } else {
                        let afkastvalgtPolicenummervalgtAarShowSubject: BehaviorSubject<0> = new BehaviorSubject(0);
                        let afkastvalgtPolicenummervalgtAarShow = this.initiateStream(afkastvalgtPolicenummervalgtAarShowSubject, this.savingsreturnService.afkastValgtPolicenummerValgtAarGet(valgtPolicenummer,valgtAar), AfkastDetaljerSchema);
                        this.afkastvalgtPolicenummervalgtAarShowCache.set(hash, new RestCacheEntry<AfkastDetaljer>(afkastvalgtPolicenummervalgtAarShow, afkastvalgtPolicenummervalgtAarShowSubject));
                        return afkastvalgtPolicenummervalgtAarShow;
                    }
                }

                public invalidateafkastvalgtPolicenummervalgtAarShowById(valgtPolicenummer: string,valgtAar: number) {
                    let hash = this.hashKey((valgtPolicenummer.toString() || "0") + (valgtAar.toString() || "0"));
                    if (this.afkastvalgtPolicenummervalgtAarShowCache.has(hash)) {
                        this.afkastvalgtPolicenummervalgtAarShowCache.get(hash)!.subject.next(0);
                    }
                }

                public invalidateafkastvalgtPolicenummervalgtAarShow() {
                    this.afkastvalgtPolicenummervalgtAarShowCache.forEach((cacheEntry) => {
                        cacheEntry.subject.next(0);
                    })
                }







                public akkafkastAfkastfraordningensstartGet() : Observable<AkkAfkast> {
                    let hash = "0";
                    if (this.akkafkastafkastfraordningensstartIndexCache.has(hash)) {
                        return this.akkafkastafkastfraordningensstartIndexCache.get(hash)!.observable;
                    } else {
                        let akkafkastafkastfraordningensstartIndexSubject: BehaviorSubject<0> = new BehaviorSubject(0);
                        let akkafkastafkastfraordningensstartIndex = this.initiateStream(akkafkastafkastfraordningensstartIndexSubject, this.savingsreturnService.akkafkastAfkastfraordningensstartGet(), AkkAfkastSchema);
                        this.akkafkastafkastfraordningensstartIndexCache.set(hash, new RestCacheEntry<AkkAfkast>(akkafkastafkastfraordningensstartIndex, akkafkastafkastfraordningensstartIndexSubject));
                        return akkafkastafkastfraordningensstartIndex;
                    }
                }

                public invalidateakkafkastafkastfraordningensstartIndex() {
                    this.akkafkastafkastfraordningensstartIndexCache.forEach((cacheEntry) => {
                        cacheEntry.subject.next(0);
                    })
                }





                public akkafkastValgtPolicenummerValgtPeriodeGet(valgtPolicenummer: string,valgtPeriode: string) : Observable<AkkAfkastDetaljer> {
                    let hash = this.hashKey((valgtPolicenummer.toString() || "0") + (valgtPeriode.toString() || "0"));
                    if (this.akkafkastvalgtPolicenummervalgtPeriodeShowCache.has(hash)) {
                        return this.akkafkastvalgtPolicenummervalgtPeriodeShowCache.get(hash)!.observable;
                    } else {
                        let akkafkastvalgtPolicenummervalgtPeriodeShowSubject: BehaviorSubject<0> = new BehaviorSubject(0);
                        let akkafkastvalgtPolicenummervalgtPeriodeShow = this.initiateStream(akkafkastvalgtPolicenummervalgtPeriodeShowSubject, this.savingsreturnService.akkafkastValgtPolicenummerValgtPeriodeGet(valgtPolicenummer,valgtPeriode), AkkAfkastDetaljerSchema);
                        this.akkafkastvalgtPolicenummervalgtPeriodeShowCache.set(hash, new RestCacheEntry<AkkAfkastDetaljer>(akkafkastvalgtPolicenummervalgtPeriodeShow, akkafkastvalgtPolicenummervalgtPeriodeShowSubject));
                        return akkafkastvalgtPolicenummervalgtPeriodeShow;
                    }
                }

                public invalidateakkafkastvalgtPolicenummervalgtPeriodeShowById(valgtPolicenummer: string,valgtPeriode: string) {
                    let hash = this.hashKey((valgtPolicenummer.toString() || "0") + (valgtPeriode.toString() || "0"));
                    if (this.akkafkastvalgtPolicenummervalgtPeriodeShowCache.has(hash)) {
                        this.akkafkastvalgtPolicenummervalgtPeriodeShowCache.get(hash)!.subject.next(0);
                    }
                }

                public invalidateakkafkastvalgtPolicenummervalgtPeriodeShow() {
                    this.akkafkastvalgtPolicenummervalgtPeriodeShowCache.forEach((cacheEntry) => {
                        cacheEntry.subject.next(0);
                    })
                }







}
