import { PersonNameValidator } from '@pfa/utils';
import {
  UntypedFormGroup,
  UntypedFormArray,
  UntypedFormControl,
  Validators
} from '@angular/forms';
import { Component, OnInit, Input, ElementRef, inject } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'mitpfa-family-children',
  templateUrl: './children.component.html',
  styleUrls: ['./children.component.scss']
})
export class ChildrenComponent implements OnInit {
  private readonly elementRef: ElementRef = inject(ElementRef);

  @Input() childForms: UntypedFormGroup;

  public childFormArray: UntypedFormArray;
  public childRelationOptions = [
    { value: 'Barn', label: 'DL.MD01.C356' },
    { value: 'PartnersBarn', label: 'DL.MD01.C357' },
    { value: 'Ukendt', label: 'DL.MD01.C358' }
  ];

  public minDate: Date;
  public currentDate = moment.utc().toDate();

  public ngOnInit(): void {
    const minYear = moment.utc().subtract(99, 'years');
    this.minDate = minYear.startOf('year').toDate();
    this.childFormArray = this.childForms.controls[
      'childs'
    ] as UntypedFormArray;
  }

  public hasChildsSelected(): void {
    if (this.childFormArray.length === 0) {
      this.addChild();
    }
  }

  public hasNotChildrenSelected(): void {
    if (this.childFormArray.length !== 0) {
      this.childFormArray.clear();
    }
  }

  public addChild(): void {
    this.childFormArray.push(
      new UntypedFormGroup({
        familyRelationId: new UntypedFormControl(null),
        childName: new UntypedFormControl('', {
          validators: [Validators.required, PersonNameValidator]
        }),
        birthDate: new UntypedFormControl('', {
          validators: Validators.required
        }),
        relationType: new UntypedFormControl('', {
          validators: Validators.required
        })
      })
    );
  }

  public removeChild(index: number): void {
    this.childForms.markAsDirty();
    this.childFormArray.removeAt(index);
  }

  public datepickerHover(hover: boolean): void {
    const element = this.elementRef.nativeElement.querySelector(
      '.datepicker-wrap mat-form-field'
    );

    if (hover) {
      element.classList.add('mat-focused');
    } else {
      element.classList.remove('mat-focused');
    }
  }
}
