<!-- v4 -->
<svg
  id="Layer_1"
  data-name="Layer 1"
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 32 32"
>
  <g id="Canvas">
    <circle
      id="Large_circle"
      data-name="Large circle"
      cx="16"
      cy="16"
      r="16"
      fill="none"
    />
    <circle
      id="Small_circle"
      data-name="Small circle"
      cx="16"
      cy="16"
      r="12"
      fill="none"
    />
  </g>
  <g id="Icon">
    <path
      id="Clip_bottom"
      data-name="Clip bottom"
      d="M20.5,15.28v4.14a3.59,3.59,0,0,1-3.43,3.73h-.42a3.41,3.41,0,0,1-2.87-1.52,3.85,3.85,0,0,1-.66-2.21V14.15"
      fill="none"
      stroke="#000"
      stroke-linecap="round"
      stroke-miterlimit="10"
      stroke-width="1.2"
    />
    <path
      id="Clip_top"
      data-name="Clip top"
      d="M13.12,12V10.29a3.32,3.32,0,0,1,1.2-2.84A2.62,2.62,0,0,1,15.81,7h.11c1.7,0,2.68,1.13,2.68,3.27V19c0,1.21-.6,2.19-1.8,2.19S15,20.22,15,19V10.9"
      fill="none"
      stroke="#000"
      stroke-linecap="round"
      stroke-miterlimit="10"
      stroke-width="1.2"
    />
  </g>
</svg>
