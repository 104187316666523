<div class="attachment-container">
  <div
    class="drop-box"
    [ngClass]="{ dragover: hasBaseDropZoneOver }"
    (fileOver)="fileOver($event)"
    ng2FileDrop
    [uploader]="uploader"
    (click)="fileInput.click()"
  >
    <div>{{ text['DL.BE02.C17'] }}</div>
    <div>{{ text['DL.BE02.C18'] }}</div>
    <div [coContent]="note">(træk fil hertil eller klik hér)</div>
    <input
      type="file"
      style="display: none"
      [disabled]="addedAttachments.length === maxAttachmentsNumber"
      [accept]="formattedAcceptFiles"
      multiple
      ng2FileSelect
      #fileInput
      [uploader]="uploader"
    />
  </div>
</div>

<div
  [hidden]="addedAttachments.length === 0 && fileInvalidTexts.length === 0"
  *ngIf="showFiles"
  class="attachments"
>
  <div class="file-area">
    <div *ngIf="fileInvalidTexts.length > 0">
      <div class="text--error" *ngFor="let invalidText of fileInvalidTexts">
        <span>{{ invalidText }}</span>
      </div>
    </div>
    <div [hidden]="addedAttachments.length === 0">
      <div
        class="attachment"
        *ngFor="let item of addedAttachments"
        [@slideInRight]
        [@slideOutLeft]
      >
        <a
          class="link--no-line attachment-delete-container"
          (click)="emitRemoveAttachment(item)"
        >
          <co-icon-delete class="attachment-delete"></co-icon-delete>
          <span>{{ text['DL.BE02.C07'] }}</span>
        </a>
        <span class="filename">{{ item.name }}</span>
      </div>
    </div>
  </div>
</div>
<div *ngIf="minAttachmentsNumber > 0">
  {{ text['DL.BE02.C1046'] }}
</div>
<div *ngIf="maxAttachmentsNumber > 0">
  {{ text['DL.BE02.C1047'] }}
</div>
