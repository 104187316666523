import { Injectable } from '@angular/core';
import { MitPFARouteReuseStrategyService } from './mitpfa-route-reuse-strategy-service';
import {
  ActivatedRouteSnapshot,
  DetachedRouteHandle,
  RouteReuseStrategy
} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class MitPFARouteReuseStragety implements RouteReuseStrategy {
  constructor(
    private mitPFARouteReuseStrategyService: MitPFARouteReuseStrategyService
  ) {}

  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return route.data?.shouldReuseRoute;
  }

  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
    this.mitPFARouteReuseStrategyService.setDetachedRouteHandle(
      this.getUrlFromRoot(route),
      handle
    );
  }

  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    return (
      !!route.routeConfig &&
      !!this.mitPFARouteReuseStrategyService.getDetachedRouteHandle(
        this.getUrlFromRoot(route)
      )
    );
  }

  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
    return this.mitPFARouteReuseStrategyService.getDetachedRouteHandle(
      this.getUrlFromRoot(route)
    );
  }

  shouldReuseRoute(
    future: ActivatedRouteSnapshot,
    curr: ActivatedRouteSnapshot
  ): boolean {
    return future.routeConfig === curr.routeConfig;
  }

  getUrlFromRoot(route: ActivatedRouteSnapshot): string {
    return (
      '/' +
      route.pathFromRoot
        .filter(config => config.routeConfig)
        .map(config => config.url.toString())
        .join('/')
    );
  }
}
