const overviewTexts = {
  intro: {
    title: 'DL.INVGU01.C379',
    content: 'DL.INVGU01.C380',
    youInvest: ['DL.INVGU01.C493', 'DL.INVGU01.C518', 'DL.INVGU01.C494']
  },
  involvement: {
    title: 'DL.INVGU01.C378',
    description: {
      pfaInvest: 'DL.INVGU01.C392',
      youInvest: 'DL.INVGU01.C393'
    },
    conceptChoice: {
      question: 'DL.INVGU01.C495',
      answers: {
        both: 'DL.INVGU01.C394',
        pfaInvest: 'DL.INVGU01.C337',
        youInvest: 'DL.INVGU01.C333'
      }
    },
    investmentKnowledge: {
      question: 'DL.INVGU01.C173'
    },
    tradingExperience: {
      question: 'DL.INVGU01.C84',
      answers: {
        noExperience: 'DL.INVGU01.C184',
        bonds: 'DL.INVGU01.C205',
        shares: 'DL.INVGU01.C203',
        investmentCertificates: 'DL.INVGU01.C312',
        others: 'DL.INVGU01.C340'
      }
    },
    pastTrades: {
      question: 'DL.INVGU01.C212'
    }
  },
  finances: {
    title: 'DL.INVGU01.C376',
    description: {
      minimalLoss: 'DL.INVGU01.C432',
      acceptLoss: 'DL.INVGU01.C387',
      avoidLoss: 'DL.INVGU01.C387'
    },
    indicator: {
      label: 'DL.INVGU01.C631',
      labelMin: 'DL.INVGU01.C632',
      labelMax: 'DL.INVGU01.C633'
    },
    questions: {
      otherSavings: {
        question: 'DL.INVGU01.C168',
        answers: {
          shares: 'DL.INVGU01.C203',
          bonds: 'DL.INVGU01.C191',
          cash: 'DL.INVGU01.C192'
        }
      },
      allYearResidence: {
        question: 'DL.INVGU01.C170',
        answers: {
          salesValue: 'DL.INVGU01.C195',
          debt: 'DL.INVGU01.C196',
          ownersShare: 'DL.INVGU01.C197'
        }
      },
      holidayHouse: {
        question: 'DL.INVGU01.C171'
      },
      loans: {
        question: 'DL.INVGU01.C169',
        answers: {
          currentDebt: 'DL.INVGU01.C194'
        }
      },
      economicImpact: {
        question: 'DL.INVGU01.C163',
        answers: {
          minor: 'DL.INVGU01.C166',
          large: 'DL.INVGU01.C165',
          veryLarge: 'DL.INVGU01.C164'
        }
      }
    }
  },
  risk: {
    title: 'DL.INVGU01.C377',
    description: {
      low: 'DL.INVGU01.C389',
      average: 'DL.INVGU01.C390',
      high: 'DL.INVGU01.C391'
    },
    indicator: {
      label: 'DL.INVGU01.C377',
      labelMin: 'DL.INVGU01.C632',
      labelMax: 'DL.INVGU01.C633'
    },
    questions: {
      willingness: {
        question: 'DL.INVGU01.C288',
        answers: {
          high: 'DL.INVGU01.C294',
          average: 'DL.INVGU01.C292',
          low: 'DL.INVGU01.C291'
        }
      },
      profile: {
        question: 'DL.INVGU01.C433',
        answers: {
          lowRisk: 'DL.INVGU01.C410',
          mediumRisk: 'DL.INVGU01.C230',
          highRisk: 'DL.INVGU01.C409'
        }
      },
      lossAbility: {
        question: 'DL.INVGU01.C160',
        answers: {
          accept: 'DL.INVGU01.C343',
          minimal: 'DL.INVGU01.C342',
          avoid: 'DL.INVGU01.C341'
        }
      }
    }
  },
  climate: {
    title: 'DL.INVGU01.C584',
    description: {
      none: 'DL.INVGU01.C585',
      low: 'DL.INVGU01.C586',
      middle: 'DL.INVGU01.C587',
      high: 'DL.INVGU01.C588'
    },
    questions: {
      relevance: {
        question: 'DL.INVGU01.C669',
        answers: {
          low: 'DL.INVGU01.C671',
          medium: 'DL.INVGU01.C672',
          high: 'DL.INVGU01.C673'
        }
      },
      extendedFocus: {
        question: 'DL.INVGU01.C561',
        answers: {
          minor: 'DL.INVGU01.C564',
          average: 'DL.INVGU01.C565',
          high: 'DL.INVGU01.C566'
        }
      },
      willingness: {
        question: 'DL.INVGU01.C556',
        answers: {
          limit: 'DL.INVGU01.C558',
          minor: 'DL.INVGU01.C559',
          neutral: 'DL.INVGU01.C560'
        }
      },
      priority: {
        question: 'DL.INVGU01.C551',
        answers: {
          highReturn: 'DL.INVGU01.C553',
          climateFriendly: 'DL.INVGU01.C554',
          equally: 'DL.INVGU01.C555'
        }
      }
    }
  },
  yes: 'DL.INVGU01.C68',
  no: 'DL.INVGU01.C69'
};

export { overviewTexts };
