import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TilbudOverblik, AdvisorOptions, IndbetalingSikring } from '@pfa/gen';

@Component({
  selector: 'co-new-business-offer-overview',
  templateUrl: './new-business-offer-overview.component.html',
  styleUrls: ['./new-business-offer-overview.component.scss']
})
export class NewBusinessOfferOverviewComponent {
  @Input() overview: TilbudOverblik;

  @Input() savePaymentsFail: boolean;
  @Output() savePayments = new EventEmitter<{
    employer: number;
    employee: number;
  }>();
  @Output() saveVoluntaryPayment = new EventEmitter<number>();

  @Input() advisorOptions: AdvisorOptions;
  @Input() switchHsFail: boolean;
  @Output() partialSchemeSelected = new EventEmitter<number>();

  @Input() saveIndbetalingSikringFail: boolean;
  @Output() saveIndbetalingSikring = new EventEmitter<IndbetalingSikring>();
}
