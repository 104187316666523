/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const AfkastFileContentTypeSchema = JoiObj.string().valid(
        'PLUS_MED_KK'
        ,
        'PLUS_UDEN_KK'
        ,
        'KLIMA_MED_KK'
        ,
        'KLIMA_UDEN_KK'
        
    )
.id('AfkastFileContentTypeSchema')

export type AfkastFileContentType = 'PLUS_MED_KK' | 'PLUS_UDEN_KK' | 'KLIMA_MED_KK' | 'KLIMA_UDEN_KK';

export const AfkastFileContentType = {
    PlusMedKk: 'PLUS_MED_KK' as AfkastFileContentType,
    PlusUdenKk: 'PLUS_UDEN_KK' as AfkastFileContentType,
    KlimaMedKk: 'KLIMA_MED_KK' as AfkastFileContentType,
    KlimaUdenKk: 'KLIMA_UDEN_KK' as AfkastFileContentType
};

