<div *ngIf="hasSpouse" class="spacing-section--over">
  <p class="text--manchet" [coContent]="'DL.MD01.C327'"></p>
  <p [coContent]="'DL.MD01.C328'"></p>
</div>

<mitpfa-family-partner-info
  [partnerInfoForm]="partnerInfoForm"
  [pensionCustomer]="pensionCustomer"
  [hasSpouse]="hasSpouse"
></mitpfa-family-partner-info>

<mitpfa-family-children [childForms]="childForms"></mitpfa-family-children>

<co-warning
  *ngIf="familyInformation.egenindtastningerFejlede || showError"
  class="spacing-unit--over"
>
  <span [coContent]="'DL.MD01.C118'"></span>
</co-warning>
<co-warning
  *ngIf="!isInputValid() && (partnerInfoForm?.touched || childForms?.touched)"
  class="spacing-unit--over"
>
  <span [coContent]="'DL.MD01.C206'"></span>
</co-warning>
