/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ServiceInfo } from './serviceInfo';
import { ServiceInfoSchema } from './serviceInfo';
import { Udbetaling } from './udbetaling';
import { UdbetalingSchema } from './udbetaling';
import { ResourceModel } from './resourceModel';
import { ResourceModelSchema } from './resourceModel';
import * as JoiObj from "joi";


    export const UdbetalingDetaljerSchema = JoiObj.object({
        serviceInfo:
            
        JoiObj.link('#ServiceInfoSchema')

.allow(null)



        ,
        bruttoTotalBeloeb:
            
                    JoiObj.number()
                


.required()


        ,
        detaljerMulig:
            
                    JoiObj.boolean()
                


.required()


        ,
        morarenterBeloeb:
            
                    JoiObj.number()
                


.required()


        ,
        senesteUdbetaling:
            
                    JoiObj.number()
                


.required()


        ,
        udbetalinger:
                JoiObj.array().items(
        
        JoiObj.link('#UdbetalingSchema')

        )
        

.required()


        
    })
                .shared(ServiceInfoSchema)
                .shared(UdbetalingSchema)
.id('UdbetalingDetaljerSchema')

export interface UdbetalingDetaljer extends ResourceModel { 
    bruttoTotalBeloeb: number;
    detaljerMulig: boolean;
    morarenterBeloeb: number;
    senesteUdbetaling: number;
    udbetalinger: Array<Udbetaling>;
}

