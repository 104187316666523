import { ComponentRef, Type } from '@angular/core';
import { CheckboxGroupLabelComponent } from './label/checkbox-group-label.component';

export interface CheckboxGroupContentComponent<T> {
  data: any;
  self?: ComponentRef<CheckboxGroupContentComponent<T>>;
}

export class CheckboxItem {
  value: string;
  checked: boolean;
  component: Type<any>;
  data: any;

  constructor(value: string, component: Type<any>, data: any, checked = false) {
    this.value = value;
    this.component = component;
    this.data = data;
    this.checked = checked;
  }

  static factory(value: string, label: string, checked = false): CheckboxItem {
    return new CheckboxItem(value, CheckboxGroupLabelComponent, label, checked);
  }
}
