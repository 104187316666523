import { Component, Input } from '@angular/core';
import { InvesteringKilde, TilbudOverblik } from '@pfa/gen';
import { ContentUtilService } from '@pfa/core';

@Component({
  selector: 'co-investment',
  templateUrl: './investment.component.html',
  styleUrls: ['../new-business-offer-overview.component.scss']
})
export class InvestmentComponent {
  @Input() overview: TilbudOverblik;

  constructor(private readonly cu: ContentUtilService) {}

  profileSources(sources: InvesteringKilde[]): string {
    const subst = [];
    for (const source of sources) {
      switch (source) {
        case 'ArbejdsgiverObligatorisk':
          subst.push(this.cu.getContent('DL.TR01.C56'));
          break;
        case 'MedarbejderObligatorisk':
          subst.push(this.cu.getContent('DL.TR01.C57'));
          break;
        case 'MedarbejderFrivillig':
          subst.push(this.cu.getContent('DL.TR01.C58'));
          break;
        case 'Blandet':
          subst.push(this.cu.getContent('DL.TR01.C59'));
          break;
      }
    }

    const c =
      sources.length === 1
        ? 'DL.TR01.C53'
        : sources.length === 2
          ? 'DL.TR01.C54'
          : 'DL.TR01.C55';
    return this.cu.getContent(c, subst);
  }
}
