/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AttachedFile } from './attachedFile';
import { AttachedFileSchema } from './attachedFile';
import * as JoiObj from "joi";


    export const AttachedFileListSchema = JoiObj.object({
        attachedFileList:
                JoiObj.array().items(
        
        JoiObj.link('#AttachedFileSchema')

        )
        

.required()


        ,
        fileListOperationFailed:
            
                    JoiObj.boolean()
                


.required()


        
    })
                .shared(AttachedFileSchema)
.id('AttachedFileListSchema')

export interface AttachedFileList { 
    attachedFileList: Array<AttachedFile>;
    fileListOperationFailed: boolean;
}

