import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { GlobalWorkingService } from '@pfaform';
import { ActivatedRoute, Router } from '@angular/router';
import { PincodeMethod } from './order-pincode.model';
import { LoginService } from '@mitpfa/shared';

@Component({
  selector: 'mitpfa-order-pincode',
  templateUrl: './order-pincode.component.html',
  styleUrls: ['./order-pincode.component.scss']
})
export class OrderPincodeComponent implements OnInit {
  public errorMessage: string | undefined;
  public model = {};
  public orderPincodeForm: UntypedFormGroup;
  private pincodeMethoed: PincodeMethod;
  private c: string;
  private t: string;
  private validTo: string;

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly loginService: LoginService,
    private readonly globalWorkingService: GlobalWorkingService,
    private readonly route: ActivatedRoute,
    private readonly router: Router
  ) {}
  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.t = params.t;
      this.c = params.c;
      this.validTo = params.validto;
    });
    this.orderPincodeForm = this.formBuilder.group(
      {
        cpr: ['', [Validators.required, Validators.pattern('^[0-9]{10,10}$')]],
        pincode: [
          '',
          [Validators.required, Validators.pattern('^[0-9]{4,4}$')]
        ],
        repeatPincode: [
          '',
          [Validators.required, Validators.pattern('^[0-9]{4,4}$')]
        ]
      },
      {
        validator: [
          this.pincodeMatch('pincode', 'repeatPincode'),
          this.patternMatch('repeatPincode'),
          this.patternMatch('pincode')
        ]
      }
    );

    this.model = {
      pinkode: '',
      cpr: '',
      validto: '$location.search().validto',
      t: '$location.search().t',
      c: '$location.search().c'
    };
  }

  private pincodeMatch(controlName: string, matchingControlName: string) {
    return (formGroup: UntypedFormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        // return if another validator is in error
        return;
      }

      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ pincodeMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }

  private patternMatch(controlName: string) {
    return (formGroup: UntypedFormGroup) => {
      const control = formGroup.controls[controlName];

      if (control.errors) {
        // return if another validator is in error
        return;
      }
      const regex = /(\d)\1\1/;
      if (control.value && control.value > 0 && regex.test(control.value)) {
        control.setErrors({ patternMatch: true });
      } else {
        control.setErrors(null);
      }
    };
  }

  public submit() {
    this.errorMessage = undefined;
    this.pincodeMethoed = {
      cpr: this.orderPincodeForm.value.cpr,
      pinkode: this.orderPincodeForm.value.pincode,
      validto: this.validTo,
      c: this.c,
      t: this.t
    };
    this.globalWorkingService.show();
    this.loginService.createPinCode(this.pincodeMethoed).subscribe(
      () => {
        this.globalWorkingService.hide();
        this.router.navigate(['/']);
      },
      error => {
        this.globalWorkingService.hide();
        if (error?.error.errorCode === 8) {
          // Token expired
          this.errorMessage = 'DL.LI01.C118';
        } else if (error?.error.errorCode === 9) {
          // Token already used OR CPR mismatch
          this.errorMessage = 'DL.LI01.C123';
        } else if (error?.error.errorCode === 10) {
          // Too many tries
          this.errorMessage = 'DL.LI01.C122';
        } else {
          // Something went wrong - contact PFA
          this.errorMessage = 'DL.LI01.C120';
        }
      }
    );
  }
}
