/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const PhoneDetailsSchema = JoiObj.object({
        number:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        countryCode:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        phoneNumberValid:
            
                    JoiObj.boolean()
                

.allow(null)



        
    })
.id('PhoneDetailsSchema')

export interface PhoneDetails { 
    number: string;
    countryCode: string;
    phoneNumberValid?: boolean;
}

