import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ContentUtilService } from '@pfa/core';
import { SnackBarComponent } from '../components/snackbar/snackbar.component';

export enum NotificationType {
  ERROR = 'error',
  SUCCESS = 'success',
  INFO = 'info'
}

export interface NotificationConfig {
  message?: string;
  type: NotificationType;
  duration?: number;
  showCloseButton?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  DEFAULT_DURATION = 5000;

  constructor(
    private readonly contentUtil: ContentUtilService,
    private readonly snackBar: MatSnackBar
  ) {}

  showNotification(notificationConfig: NotificationConfig) {
    const text =
      !notificationConfig.message &&
      notificationConfig.type === NotificationType.ERROR
        ? this.contentUtil.getContent('Global.advarsel-generel')
        : this.contentUtil.getContent(notificationConfig.message);

    let className = '';
    switch (notificationConfig.type) {
      case NotificationType.SUCCESS:
        className = 'snack-bar-success';
        break;
      case NotificationType.ERROR:
        className = 'snack-bar-error';
        break;
      case NotificationType.INFO:
        className = 'snack-bar-info';
        break;
    }

    this.snackBar.openFromComponent(SnackBarComponent, {
      duration: notificationConfig.duration
        ? notificationConfig.duration
        : this.DEFAULT_DURATION,
      panelClass: className,
      data: {
        message: text,
        type: notificationConfig.type,
        showCloseButton: notificationConfig.showCloseButton
      }
    });
  }

  dismissNotification() {
    this.snackBar.dismiss();
  }
}
