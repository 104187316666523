/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Selskab } from './selskab';
import { SelskabSchema } from './selskab';
import { Sats } from './sats';
import { SatsSchema } from './sats';
import * as JoiObj from "joi";


    export const EksternOverfoerselSatserSchema = JoiObj.object({
        activeNow:
            
                    JoiObj.boolean()
                


.required()


        ,
        name:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        updatedAt:
            
            JoiObj.date()


.required()


        ,
        updatedBy:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        uuid:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        validFrom:
            
            JoiObj.date()

.allow(null)



        ,
        validFromFormatted:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        year:
            
                    JoiObj.number()
                


.required()


        ,
        aarligYdelseLivsvarigPension:
            
                    JoiObj.number()
                


.required()


        ,
        aarligYdelseTae:
            
                    JoiObj.number()
                


.required()


        ,
        amountOfBankPension:
            
                    JoiObj.number()
                


.required()


        ,
        amountOfMarketRatePension:
            
                    JoiObj.number()
                


.required()


        ,
        contentIdPrefix:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        gennemsnitsrenteAlder:
            
                    JoiObj.number()
                


.required()


        ,
        gennemsnitsrenteOverAlder:
            
                    JoiObj.number()
                


.required()


        ,
        lilleFripolice:
            
                    JoiObj.number()
                


.required()


        ,
        omkostningsfritBeloeb:
            
                    JoiObj.number()
                


.required()


        ,
        opsparingGennemsnitsrente:
            
                    JoiObj.number()
                


.required()


        ,
        selskaber:
                JoiObj.array().items(
        
        JoiObj.link('#SelskabSchema')

        )
        

.required()


        
    })
                .shared(SelskabSchema)
.id('EksternOverfoerselSatserSchema')

export interface EksternOverfoerselSatser extends Sats { 
    aarligYdelseLivsvarigPension: number;
    aarligYdelseTae: number;
    amountOfBankPension: number;
    amountOfMarketRatePension: number;
    contentIdPrefix: string;
    gennemsnitsrenteAlder: number;
    gennemsnitsrenteOverAlder: number;
    lilleFripolice: number;
    omkostningsfritBeloeb: number;
    opsparingGennemsnitsrente: number;
    selskaber: Array<Selskab>;
}

