import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  SuccessResponse,
  InvesteringPolice,
  PfaValgfri,
  KlimaPlusAndel,
  InvesteringDetaljerSvar,
  ProfilMulighederSvar,
  PoliceMulighederSvar,
  Profil,
  DuInvesterer,
  InvesteringSvar,
  InForceSelection,
  InForceSelectionProfile,
  InForceSelectionClimate,
  TilbudSvar
} from '@pfa/gen';
import { Cacheable } from 'ts-cacheable';
import { GlobalShouldCacheDecider } from '../cache/GlobalShouldCacheDecider';

@Injectable({
  providedIn: 'root'
})
export class InvestmentApiService {
  constructor(private http: HttpClient) {}

  @Cacheable({})
  get(): Observable<InvesteringDetaljerSvar> {
    const url = '/ds/api/investering';
    return this.http.get<InvesteringDetaljerSvar>(url);
  }

  @Cacheable({
    maxCacheCount: 5,
    shouldCacheDecider: GlobalShouldCacheDecider
  })
  getPolicy(policyId: string): Observable<PoliceMulighederSvar> {
    const url = `/ds/api/investering/${policyId}`;
    return this.http.get<PoliceMulighederSvar>(url);
  }

  updateInvestmentProfile(
    policyId: string,
    profiles: Profil[]
  ): Observable<ProfilMulighederSvar> {
    return this.http.post<ProfilMulighederSvar>(
      `/ds/api/investering/pfainvesterer/${policyId}`,
      profiles
    );
  }

  calculateInvestmentProfile(policyId: string, profiles: Profil[]) {
    return this.http.post(
      `/ds/api/investering/pfainvesterer/${policyId}/indregn`,
      profiles
    );
  }

  calculateClimatePlus(
    policyId: string,
    climatePlusPercentage: KlimaPlusAndel
  ) {
    return this.http.post(
      `/ds/api/investering/klimaplus/${policyId}/indregn`,
      climatePlusPercentage
    );
  }

  calculatePFAOptional(policyId: string, pfaOptional: PfaValgfri) {
    return this.http.post(
      `/ds/api/investering/pfavalgfri/${policyId}/indregn`,
      pfaOptional
    );
  }

  calculateYouInvest(
    policyId: string,
    youInvest: DuInvesterer
  ): Observable<InvesteringSvar> {
    return this.http.post<InvesteringSvar>(
      `/ds/api/investering/duinvesterer/${policyId}/indregn`,
      youInvest
    );
  }

  saveDefaultProfileChoice(profileSelection: InForceSelectionProfile) {
    return this.http.post(
      '/ds/api/investering/pfainvesterer/confirmprofileselection',
      profileSelection
    );
  }

  saveDefaultClimateProfileChoice(
    profileSelection: InForceSelectionClimate
  ): Observable<SuccessResponse> {
    return this.http.post<SuccessResponse>(
      '/ds/api/investering/pfainvesterer/confirmclimateprofileselection',
      profileSelection
    );
  }

  saveOptionalProfileChoice(
    profileSelection: InForceSelection
  ): Observable<SuccessResponse> {
    return this.http.post<SuccessResponse>(
      '/ds/api/investering/pfainvesterer/confirmpfaoptionalselection',
      profileSelection
    );
  }

  saveYouInvestChoice(
    inForceSelection: InForceSelection
  ): Observable<SuccessResponse> {
    return this.http.post<SuccessResponse>(
      '/ds/api/investering/pfainvesterer/confirmyouinvestselection',
      inForceSelection
    );
  }

  contactMe(investeringPolice: InvesteringPolice): Observable<SuccessResponse> {
    return this.http.post<SuccessResponse>(
      '/ds/api/investering/bestilaendring',
      investeringPolice
    );
  }
}
