/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DeclineStatus } from './declineStatus';
import { DeclineStatusSchema } from './declineStatus';
import * as JoiObj from "joi";


    export const DeclineOfferRequestSchema = JoiObj.object({
        declineStatus:
            
        JoiObj.link('#DeclineStatusSchema')


.required()


        ,
        quotationGuid:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        reason:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        
    })
                .shared(DeclineStatusSchema)
.id('DeclineOfferRequestSchema')

export interface DeclineOfferRequest { 
    declineStatus: DeclineStatus;
    quotationGuid: string;
    reason: string;
}
export namespace DeclineOfferRequest {
}


