/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { MaanedligAfkaster } from './maanedligAfkaster';
import { MaanedligAfkasterSchema } from './maanedligAfkaster';
import * as JoiObj from "joi";


    export const MaanedligAarligAfkasterSchema = JoiObj.object({
        afkast:
                JoiObj.array().items(
        
        JoiObj.link('#MaanedligAfkasterSchema')

        )
        

.required()


        ,
        opdateredato:
            
                    JoiObj.object()
                


.required()


        
    })
                .shared(MaanedligAfkasterSchema)
.id('MaanedligAarligAfkasterSchema')

export interface MaanedligAarligAfkaster { 
    afkast: Array<MaanedligAfkaster>;
    opdateredato: object;
}

