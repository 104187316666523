/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const ValutaSchema = JoiObj.string().valid(
        'DKK'
        ,
        'EUR'
        ,
        'AUD'
        ,
        'BGN'
        ,
        'CAD'
        ,
        'CYP'
        ,
        'EEK'
        ,
        'HKD'
        ,
        'ISK'
        ,
        'JPY'
        ,
        'CNY'
        ,
        'HRK'
        ,
        'LVL'
        ,
        'LTL'
        ,
        'MTL'
        ,
        'NZD'
        ,
        'NOK'
        ,
        'TRY'
        ,
        'PLN'
        ,
        'GBP'
        ,
        'RUB'
        ,
        'CHF'
        ,
        'SGD'
        ,
        'SKK'
        ,
        'SEK'
        ,
        'ZAR'
        ,
        'THB'
        ,
        'CZK'
        ,
        'USD'
        ,
        'HUF'
        
    )
.id('ValutaSchema')

export type Valuta = 'DKK' | 'EUR' | 'AUD' | 'BGN' | 'CAD' | 'CYP' | 'EEK' | 'HKD' | 'ISK' | 'JPY' | 'CNY' | 'HRK' | 'LVL' | 'LTL' | 'MTL' | 'NZD' | 'NOK' | 'TRY' | 'PLN' | 'GBP' | 'RUB' | 'CHF' | 'SGD' | 'SKK' | 'SEK' | 'ZAR' | 'THB' | 'CZK' | 'USD' | 'HUF';

export const Valuta = {
    Dkk: 'DKK' as Valuta,
    Eur: 'EUR' as Valuta,
    Aud: 'AUD' as Valuta,
    Bgn: 'BGN' as Valuta,
    Cad: 'CAD' as Valuta,
    Cyp: 'CYP' as Valuta,
    Eek: 'EEK' as Valuta,
    Hkd: 'HKD' as Valuta,
    Isk: 'ISK' as Valuta,
    Jpy: 'JPY' as Valuta,
    Cny: 'CNY' as Valuta,
    Hrk: 'HRK' as Valuta,
    Lvl: 'LVL' as Valuta,
    Ltl: 'LTL' as Valuta,
    Mtl: 'MTL' as Valuta,
    Nzd: 'NZD' as Valuta,
    Nok: 'NOK' as Valuta,
    Try: 'TRY' as Valuta,
    Pln: 'PLN' as Valuta,
    Gbp: 'GBP' as Valuta,
    Rub: 'RUB' as Valuta,
    Chf: 'CHF' as Valuta,
    Sgd: 'SGD' as Valuta,
    Skk: 'SKK' as Valuta,
    Sek: 'SEK' as Valuta,
    Zar: 'ZAR' as Valuta,
    Thb: 'THB' as Valuta,
    Czk: 'CZK' as Valuta,
    Usd: 'USD' as Valuta,
    Huf: 'HUF' as Valuta
};

