<div class="wrapper spacing-unit--half-under">
  <div class="header--container">
    <co-icon-logo [ngClass]="{ hidden: !levelReached.showLogo }" class="logo">
    </co-icon-logo>
    <co-stepper
      *ngIf="levelReached.showStepper"
      [steps]="stepReached$ | async"
      [selectedStepIndex]="currentStepIndex"
      [isNavigationEnabled]="false"
      class="stepper onboarding"
    ></co-stepper>
    <co-icon-times
      *ngIf="levelReached.showClose"
      data-test-id="closeOnboarding"
      class="close pressable icon-fill__large--greys-10 grey_background"
      [ngClass]="{ right: !levelReached.showStepper }"
      (click)="closeOnboarding()"
    >
    </co-icon-times>
  </div>
</div>
