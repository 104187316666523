import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { InsuranceGuideQuestionValue } from '../insurance-guide-configuration.service';
import { BehaviorSubject } from 'rxjs';
import {
  OekonomiDetaljer,
  PensionsInfoOrdninger,
  StamdataDetaljer,
  PensionsKundeGenerelleData
} from '@pfa/gen';
@Component({
  selector: 'mitpfa-insurance-guide-my-information-step',
  templateUrl: './insurance-guide-my-information-step.component.html',
  styleUrls: ['./insurance-guide-my-information-step.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InsuranceGuideMyInformationStepComponent {
  @Input() currentQuestion: BehaviorSubject<InsuranceGuideQuestionValue>;
  @Input() financialSituation: OekonomiDetaljer;
  @Input() pensionInfo: PensionsInfoOrdninger;
  @Input() pensionCustomer: PensionsKundeGenerelleData;
  @Output() emitUpdatePI = new EventEmitter<void>();
  @Input() familyInformation: StamdataDetaljer;
}
