/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { FormOfPayment } from './formOfPayment';
import { FormOfPaymentSchema } from './formOfPayment';
import { DaekningsType } from './daekningsType';
import { DaekningsTypeSchema } from './daekningsType';
import * as JoiObj from "joi";


    export const AftaleDaekningSchema = JoiObj.object({
        aftalenummer:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        beloeb:
            
                    JoiObj.number()
                

.allow(null)



        ,
        beloebPerMaaned:
            
                    JoiObj.number()
                

.allow(null)



        ,
        daekningExtraTextId:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        daekningsType:
            
        JoiObj.link('#DaekningsTypeSchema')


.required()


        ,
        formOfPayment:
            
        JoiObj.link('#FormOfPaymentSchema')

.allow(null)



        ,
        forsikringsTal:
            
                    JoiObj.number()
                


.required()


        ,
        forsikringsTalBrutto:
            
                    JoiObj.number()
                


.required()


        ,
        gennemsnitsrentePolicenr:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        hideAmountDetails:
            
                    JoiObj.boolean()
                


.required()


        ,
        nettoBeloeb:
            
                    JoiObj.number()
                

.allow(null)



        ,
        nettoBeloebTextId:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        omregningLoebendePension:
            
                    JoiObj.boolean()
                


.required()


        ,
        pawned:
            
                    JoiObj.boolean()
                


.required()


        ,
        primaerAftale:
            
                    JoiObj.boolean()
                


.required()


        ,
        selskabsNr:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        selvskabsnavn:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        skattefritBeloeb:
            
                    JoiObj.boolean()
                


.required()


        ,
        textId:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        textIdValues:
                JoiObj.array().items(
        
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

        )
        

.required()


        ,
        valgt:
            
                    JoiObj.boolean()
                


.required()


        
    })
                .shared(DaekningsTypeSchema)
                .shared(FormOfPaymentSchema)
.id('AftaleDaekningSchema')

export interface AftaleDaekning { 
    aftalenummer: string;
    beloeb?: number;
    beloebPerMaaned?: number;
    daekningExtraTextId?: string;
    daekningsType: DaekningsType;
    formOfPayment?: FormOfPayment;
    forsikringsTal: number;
    forsikringsTalBrutto: number;
    gennemsnitsrentePolicenr?: string;
    hideAmountDetails: boolean;
    nettoBeloeb?: number;
    nettoBeloebTextId?: string;
    omregningLoebendePension: boolean;
    pawned: boolean;
    primaerAftale: boolean;
    selskabsNr: string;
    selvskabsnavn?: string;
    skattefritBeloeb: boolean;
    textId: string;
    textIdValues: Array<string>;
    valgt: boolean;
}
export namespace AftaleDaekning {
}


