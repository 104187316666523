import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SkedifyCreateBookingResponse } from '@pfa/gen';

@Injectable({
  providedIn: 'root'
})
export class BookingApiService {
  constructor(private http: HttpClient) {}

  requestBooking(): Observable<Partial<SkedifyCreateBookingResponse>> {
    return this.http.post('/ds/api/skedify/booking', {});
  }
}
