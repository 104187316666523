<table class="align-right--column-2 new-business-advisory-table">
  <thead>
    <tr>
      <th colspan="2" [coContent]="'DL.TR01.C76'"></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngIf="overview.taeForsikringstal">
      <td>
        <div [coContent]="'DL.TR01.C77'"></div>
        <div class="text--note" [coContent]="'DL.TR01.C79'"></div>
      </td>
      <td
        class="text--bold"
        [coContent]="'DL.TR01.C78'"
        [coContentSubst]="overview.taeForsikringstal | number: '1.0-0'"
      ></td>
    </tr>

    <tr *ngFor="let taeLoebende of overview.taeLoebender">
      <td [coContent]="'DL.FG01.C56'" [coContentSubst]="taeLoebende.alder"></td>
      <td>
        <span
          class="text--bold"
          *ngIf="taeLoebende.procent"
          [coContent]="'DL.TR01.C80'"
          [coContentSubst]="taeLoebende.procent | number: '1.0-0'"
        ></span>
        <span
          class="text--bold"
          *ngIf="taeLoebende.beloeb"
          [coContent]="'DL.TR01.C81'"
          [coContentSubst]="taeLoebende.beloeb | number: '1.0-0'"
        ></span>
      </td>
    </tr>

    <tr>
      <td
        colspan="2"
        *ngIf="overview.forsikringsGuideTaget"
        [coContent]="'DL.TR01.C82'"
        [coContentSubst]="
          (overview.anbefaletTaePct | number: '1.0-0') +
          '|' +
          overview.forsikringsGuideDato
        "
      ></td>
      <td
        colspan="2"
        *ngIf="!overview.forsikringsGuideTaget"
        [coContent]="'DL.TR01.C83'"
      ></td>
    </tr>
    <tr
      *ngIf="
        overview.forsikringsGuideTaget && overview.taeLoebendeIkkeAnbefalet
      "
    >
      <td colspan="2" [coContent]="'DL.TR01.C84'"></td>
    </tr>

    <tr *ngFor="let taeEngang of overview.taeEnganger">
      <td [coContent]="'DL.FG01.C57'" [coContentSubst]="taeEngang.alder"></td>
      <td>
        <div
          class="text--bold"
          *ngIf="taeEngang.procent"
          [coContent]="'DL.TR01.C80'"
          [coContentSubst]="taeEngang.procent | number: '1.0-0'"
        ></div>
        <div
          class="text--bold"
          *ngIf="taeEngang.beloeb"
          [coContent]="'DL.TR01.C81'"
          [coContentSubst]="taeEngang.beloeb | number: '1.0-0'"
        ></div>
        <div
          class="text--note"
          *ngIf="taeEngang.skattekode === 'SK5'"
          [coContent]="'DL.FG01.C54'"
        ></div>
      </td>
    </tr>

    <tr *ngIf="overview.alder898">
      <td
        [coContent]="'DL.FG01.C1030'"
        [coContentSubst]="overview.alder898"
      ></td>
      <td><co-icon-checkmark></co-icon-checkmark></td>
    </tr>
    <tr *ngIf="overview.alder899">
      <td
        [coContent]="'DL.FG01.C1031'"
        [coContentSubst]="overview.alder899"
      ></td>
      <td><co-icon-checkmark></co-icon-checkmark></td>
    </tr>

    <tr *ngIf="overview.indbetalingssikringBeloeb">
      <td
        [coContent]="'DL.TR01.C100'"
        [coContentSubst]="overview.indbetalingssikringOphoerAlder"
      ></td>
      <td
        class="text--bold"
        [coContent]="'DL.TR01.C85'"
        [coContentSubst]="overview.indbetalingssikringBeloeb | number: '1.0-0'"
      ></td>
    </tr>
  </tbody>
</table>

<table class="align-right--column-2 new-business-advisory-table">
  <thead>
    <tr>
      <th colspan="2" [coContent]="'DL.TR01.C86'"></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngIf="overview.livForsikringstal">
      <td>
        <div [coContent]="'DL.TR01.C87'"></div>
        <div class="text--note" [coContent]="'DL.TR01.C89'"></div>
      </td>
      <td
        class="text--bold"
        [coContent]="'DL.TR01.C88'"
        [coContentSubst]="overview.livForsikringstal | number: '1.0-0'"
      ></td>
    </tr>
    <tr *ngFor="let livEngang of overview.livEnganger">
      <td [coContent]="'DL.FG01.C61'" [coContentSubst]="livEngang.alder"></td>
      <td>
        <div
          class="text--bold"
          *ngIf="livEngang.procent"
          [coContent]="'DL.TR01.C80'"
          [coContentSubst]="livEngang.procent | number: '1.0-0'"
        ></div>
        <div
          class="text--bold"
          *ngIf="livEngang.beloeb"
          [coContent]="'DL.TR01.C81'"
          [coContentSubst]="livEngang.beloeb | number: '1.0-0'"
        ></div>
        <div
          class="text--note"
          *ngIf="livEngang.skattekode === 'SK5'"
          [coContent]="'DL.FG01.C54'"
        ></div>
      </td>
    </tr>

    <tr
      *ngFor="
        let livEngangUdenRisikoOphoer of overview.livEngangUdenRisikoOphoer
      "
    >
      <td [coContent]="'DL.FG01.C62'"></td>
      <td>
        <div
          class="text--bold"
          [coContent]="'DL.TR01.C81'"
          [coContentSubst]="livEngangUdenRisikoOphoer.beloeb | number: '1.0-0'"
        ></div>
        <div
          class="text--note"
          *ngIf="livEngangUdenRisikoOphoer.skattekode === 'SK5'"
          [coContent]="'DL.FG01.C54'"
        ></div>
      </td>
    </tr>

    <tr
      *ngIf="
        overview.healthInsuranceProduct === 4 ||
        overview.healthInsuranceProduct === 5
      "
    >
      <td [coContent]="'DL.TR01.C364'"></td>
      <td>
        <div
          class="text--bold"
          [coContent]="'DL.TR01.C81'"
          [coContentSubst]="10000 | number: '1.0-0'"
        ></div>
      </td>
    </tr>

    <tr>
      <td
        colspan="2"
        *ngIf="overview.forsikringsGuideTaget"
        [coContent]="'DL.TR01.C90'"
        [coContentSubst]="
          (overview.anbefaletLivPct | number: '1.0-0') +
          '|' +
          overview.forsikringsGuideDato
        "
      ></td>
      <td
        colspan="2"
        *ngIf="!overview.forsikringsGuideTaget"
        [coContent]="'DL.TR01.C83'"
      ></td>
    </tr>
    <tr *ngIf="overview.forsikringsGuideTaget && overview.livIkkeAnbefalet">
      <td colspan="2" [coContent]="'DL.TR01.C91'"></td>
    </tr>

    <tr *ngFor="let boernepension of overview.boernepension">
      <td
        [coContent]="'DL.FG01.C69'"
        [coContentSubst]="boernepension.alder"
      ></td>
      <td>
        <div
          class="text--bold"
          *ngIf="boernepension.procent"
          [coContent]="'DL.TR01.C80'"
          [coContentSubst]="boernepension.procent | number: '1.0-0'"
        ></div>
        <div
          class="text--bold"
          *ngIf="boernepension.beloeb"
          [coContent]="'DL.TR01.C81'"
          [coContentSubst]="boernepension.beloeb | number: '1.0-0'"
        ></div>
        <div
          class="text--note"
          *ngIf="boernepension.skattekode === 'SK5'"
          [coContent]="'DL.FG01.C54'"
        ></div>
      </td>
    </tr>
  </tbody>
</table>

<table
  class="align-right--column-2 new-business-advisory-table"
  *ngIf="overview.kritisksygdom.length || overview.kritisksygdomBoerne.length"
>
  <thead>
    <tr>
      <th colspan="2" [coContent]="'DL.TR01.C92'"></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let kritisksygdom of overview.kritisksygdom">
      <td
        [coContent]="'DL.FG01.C53'"
        [coContentSubst]="kritisksygdom.alder"
      ></td>
      <td>
        <div
          class="text--bold"
          *ngIf="kritisksygdom.procent"
          [coContent]="'DL.TR01.C80'"
          [coContentSubst]="kritisksygdom.procent | number: '1.0-0'"
        ></div>
        <div
          class="text--bold"
          *ngIf="kritisksygdom.beloeb"
          [coContent]="'DL.TR01.C81'"
          [coContentSubst]="kritisksygdom.beloeb | number: '1.0-0'"
        ></div>
        <div
          class="text--note"
          *ngIf="kritisksygdom.skattekode === 'SK5'"
          [coContent]="'DL.FG01.C54'"
        ></div>
      </td>
    </tr>
    <tr *ngFor="let kritisksygdomBoerne of overview.kritisksygdomBoerne">
      <td
        [coContent]="'DL.FG01.C279'"
        [coContentSubst]="kritisksygdomBoerne.alder"
      ></td>
      <td>
        <div
          class="text--bold"
          *ngIf="kritisksygdomBoerne.procent"
          [coContent]="'DL.TR01.C80'"
          [coContentSubst]="kritisksygdomBoerne.procent | number: '1.0-0'"
        ></div>
        <div
          class="text--bold"
          *ngIf="kritisksygdomBoerne.beloeb"
          [coContent]="'DL.TR01.C81'"
          [coContentSubst]="kritisksygdomBoerne.beloeb | number: '1.0-0'"
        ></div>
        <div
          class="text--note"
          *ngIf="kritisksygdomBoerne.skattekode === 'SK5'"
          [coContent]="'DL.FG01.C54'"
        ></div>
      </td>
    </tr>
  </tbody>
</table>

<table
  class="align-right--column-2 new-business-advisory-table"
  *ngIf="overview.helbredssirking || overview.helbredssirkingBoerne"
>
  <thead>
    <tr>
      <th colspan="2" [coContent]="'DL.TR01.C93'"></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngIf="overview.helbredssirking">
      <td>
        <div [coContent]="'DL.FG01.C855'"></div>
        <div [coContent]="'DL.TR01.C94'"></div>
      </td>
      <td><co-icon-checkmark></co-icon-checkmark></td>
    </tr>
    <tr *ngIf="overview.helbredssirkingBoerne">
      <td>
        <div
          [coContent]="'DL.FG01.C856'"
          [coContentSubst]="overview.helbredssirkingBoerneAlder"
        ></div>
        <div [coContent]="'DL.TR01.C94'"></div>
      </td>
      <td><co-icon-checkmark></co-icon-checkmark></td>
    </tr>
  </tbody>
</table>

<table
  class="align-right--column-2 new-business-advisory-table"
  *ngIf="siblings.length > 1"
>
  <thead>
    <tr>
      <th [coContent]="'DL.TR01.C95'"></th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td [formGroup]="siblingsForm">
        <mat-form-field color="accent">
          <mat-label [coContent]="'DL.TR01.C96'"></mat-label>
          <mat-select [formControl]="sibling">
            <mat-option
              *ngFor="let sibling of siblings"
              [value]="sibling.partialSchemeID"
            >
              {{ partialSchemeLabel(sibling) }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <co-warning *ngIf="switchHsFail">
          <span [coContent]="'DL.TR01.C355'"></span>
        </co-warning>
      </td>
    </tr>
  </tbody>
</table>

<table class="new-business-advisory-table" *ngIf="overview.pfaforebygger">
  <thead>
    <tr>
      <th [coContent]="'DL.TR01.C97'"></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngIf="overview.pfaforebygger">
      <td>
        <div [coContent]="'DL.FG01.C48'"></div>
        <div class="text--note" [coContent]="'DL.TR01.C94'"></div>
      </td>
    </tr>
  </tbody>
</table>

<table class="new-business-advisory-table" *ngIf="overview.pfadiagnose">
  <thead>
    <tr>
      <th [coContent]="'DL.TR01.C98'"></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngIf="overview.pfadiagnose">
      <td>
        <div [coContent]="'DL.FG01.C228'"></div>
        <div class="text--note" [coContent]="'DL.TR01.C94'"></div>
      </td>
    </tr>
  </tbody>
</table>

<table class="new-business-advisory-table" *ngIf="overview.pfaearlycare">
  <thead>
    <tr>
      <th [coContent]="'DL.FG01.C1033'"></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngIf="overview.pfaearlycare">
      <td [coContent]="'DL.FG01.C1034'"></td>
    </tr>
  </tbody>
</table>

<table class="new-business-advisory-table" *ngIf="overview.pfasundhedslinje">
  <thead>
    <tr>
      <th [coContent]="'DL.FG01.C1605'"></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngIf="overview.pfasundhedslinje">
      <td [coContent]="'DL.FG01.C1606'"></td>
    </tr>
  </tbody>
</table>
