/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const DynamicAdvisingInvestmentConceptTypeSchema = JoiObj.string().valid(
        'PFA_INVEST'
        ,
        'PFA_OPTIONAL'
        ,
        'YOU_INVEST'
        ,
        'PFA_INVEST_AND_YOU_INVEST'
        ,
        'PFA_OPTIONAL_AND_YOU_INVEST'
        
    )
.id('DynamicAdvisingInvestmentConceptTypeSchema')

export type DynamicAdvisingInvestmentConceptType = 'PFA_INVEST' | 'PFA_OPTIONAL' | 'YOU_INVEST' | 'PFA_INVEST_AND_YOU_INVEST' | 'PFA_OPTIONAL_AND_YOU_INVEST';

export const DynamicAdvisingInvestmentConceptType = {
    PfaInvest: 'PFA_INVEST' as DynamicAdvisingInvestmentConceptType,
    PfaOptional: 'PFA_OPTIONAL' as DynamicAdvisingInvestmentConceptType,
    YouInvest: 'YOU_INVEST' as DynamicAdvisingInvestmentConceptType,
    PfaInvestAndYouInvest: 'PFA_INVEST_AND_YOU_INVEST' as DynamicAdvisingInvestmentConceptType,
    PfaOptionalAndYouInvest: 'PFA_OPTIONAL_AND_YOU_INVEST' as DynamicAdvisingInvestmentConceptType
};

