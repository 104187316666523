import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import {
  GuideSignatureAction,
  GuideStepType,
  GuideTrackingId,
  GuideTrackingService
} from '@pfa/core';

import {
  EksternOverfoerselAnbefaling,
  EksternOverfoerselSignereResponseObj,
  ExternaltransferService,
  ExternaltransferStore,
  GodkendSvar,
  MaeglerInfo
} from '@pfa/gen';
import {
  CombineSavingsFlowType,
  CombineSavingsNavigationEvent,
  CombineSavingsStep
} from '@mitpfa/shared/combine-savings/combine-savings.model';
import {
  GlobalWorkingService,
  NotificationService,
  NotificationType,
  SigningIframeResponse,
  SigningPopupComponent,
  SigningPopupResponse
} from '@pfaform';
import { slideInBottom, slideOutBottomWhole } from '@pfa/animations';
import { CombineSavingsAcceptTransferService } from '@mitpfa/shared/combine-savings/accept-transfer/combine-savings-accept-transfer.service';
import { CombineSavingsAcceptTransferFormService } from '@mitpfa/shared/combine-savings/accept-transfer/combine-savings-accept-transfer-form.service';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ErrorHandlerApiService } from '@pfa/api';
import { Observable, of } from 'rxjs';
import {
  catchError,
  filter,
  finalize,
  map,
  switchMap,
  tap
} from 'rxjs/operators';
import { PopupStyle } from '@pfa/models';

@Component({
  selector: 'mitpfa-combine-savings-consent',
  templateUrl: './combine-savings-consent.component.html',
  styleUrls: ['./combine-savings-consent.component.scss'],
  animations: [slideInBottom, slideOutBottomWhole]
})
export class CombineSavingsConsentComponent {
  private readonly trackingService: GuideTrackingService =
    inject(GuideTrackingService);
  private readonly globalWorkingService: GlobalWorkingService =
    inject(GlobalWorkingService);
  private readonly combineSavingsAcceptTransferService: CombineSavingsAcceptTransferService =
    inject(CombineSavingsAcceptTransferService);
  private readonly formService: CombineSavingsAcceptTransferFormService =
    inject(CombineSavingsAcceptTransferFormService);
  private readonly notificationService: NotificationService =
    inject(NotificationService);
  private readonly externaltransferStore: ExternaltransferStore = inject(
    ExternaltransferStore
  );
  private readonly errorHandlerApiService: ErrorHandlerApiService = inject(
    ErrorHandlerApiService
  );
  private readonly router: Router = inject(Router);
  private readonly dialog: MatDialog = inject(MatDialog);
  private readonly externaltransferService: ExternaltransferService = inject(
    ExternaltransferService
  );

  @Output() next = new EventEmitter<CombineSavingsNavigationEvent>();
  @Output() prev = new EventEmitter<CombineSavingsNavigationEvent>();

  @Input() combineSavingsFlowType: CombineSavingsFlowType;
  @Input() externalTransferData: EksternOverfoerselAnbefaling;
  @Input() broker: MaeglerInfo;
  @Input() trackingGuideName: GuideTrackingId | null;
  @Input() trackingActionName: string | null;

  public isOpen: boolean;
  public submitPending = false;
  private contractId: string;

  public goToNextPage(): void {
    this.next.emit({ from: CombineSavingsStep.Consent });
  }

  public goBack(): void {
    this.prev.emit({ from: CombineSavingsStep.Consent });
  }

  public openOverlay(id: string): void {
    window.scroll(0, 0);
    this.isOpen = true;
    (document.querySelector('.app-wrapper') as HTMLElement).style.overflow =
      'hidden';
    if (id) {
      setTimeout(() => {
        this.scrollIntoId(id);
      }, 500);
    }
  }

  public closeOverlay(): void {
    this.isOpen = false;
    (document.querySelector('.app-wrapper') as HTMLElement).style.overflow =
      'unset';
  }

  public approve(): void {
    this.submitPending = true;
    this.trackSignature(GuideSignatureAction.INITIATED);

    this.externalTransferData.policer = this.formService.applyFormValuesToModel(
      this.externalTransferData.policer
    );

    this.externalTransferData.policer = this.formService.setDefaultAnswers(
      this.externalTransferData.policer
    );

    this.externaltransferService
      .externaltransferPost(this.externalTransferData)
      .subscribe({
        next: () => {
          this.showSigningPopup();
        },
        error: () => {
          this.trackSignature(GuideSignatureAction.FAILED);
          this.notificationService.showNotification({
            message: 'DL.EO01.C657',
            type: NotificationType.ERROR
          });
        }
      });
  }

  private showSigningPopup(): void {
    const iframeObservable = this.formService.requestCombineSavings().pipe(
      map((iframeResponse: EksternOverfoerselSignereResponseObj) => {
        this.contractId = iframeResponse.uid;
        return iframeResponse;
      }),
      map((iframeResponse: EksternOverfoerselSignereResponseObj) =>
        this.combineSavingsAcceptTransferService.getSignaturIframeSvar(
          iframeResponse
        )
      )
    );

    this.openPopup(iframeObservable)
      .pipe(
        switchMap((result: SigningPopupResponse) =>
          this.onSigningCompleted({ ...result })
        )
      )
      .subscribe(() => {
        this.submitPending = false;
      });
  }

  private onSigningCompleted({
    content,
    cancelled,
    hasSignatureIframeError,
    error
  }: SigningPopupResponse) {
    if (cancelled) {
      return of(false);
    }

    if (hasSignatureIframeError || error) {
      this.trackSignature(GuideSignatureAction.FAILED);
      this.showGeneralError();
      return of(false);
    }

    this.globalWorkingService.show();
    return this.externaltransferStore
      .externaltransferSignedUuidPut(this.contractId, {
        mitIdSig: content ?? ''
      })
      .pipe(
        catchError(() => {
          this.trackSignature(GuideSignatureAction.FAILED);
          this.showGeneralError();
          return of({ cancelled: true } as GodkendSvar);
        }),
        filter(result => !result.cancelled),
        switchMap(() =>
          this.externaltransferStore
            .externaltransferConfirmchoicePost()
            .pipe(catchError(() => of({})))
        ),
        switchMap(() => {
          this.trackSignature(GuideSignatureAction.COMPLETED);
          return of({});
        }),
        finalize(() => {
          this.globalWorkingService.hide();
          this.submitPending = false;
        }),
        tap(() => this.goNext())
      );
  }

  private goNext(): void {
    this.next.emit({
      from: CombineSavingsStep.Consent,
      transferredPoliciesUids: this.formService.getPoliciesToBeTransferred()
    });
  }

  private openPopup(
    iframeObservable: Observable<SigningIframeResponse>
  ): Observable<SigningPopupResponse> {
    this.globalWorkingService.show();
    return this.dialog
      .open(SigningPopupComponent, {
        data: {
          signingIframeObservable: iframeObservable
        },
        panelClass: PopupStyle.PopupExtraLargeMax
      })
      .afterClosed()
      .pipe(
        tap((signingPopupResponse: SigningPopupResponse) => {
          if (
            signingPopupResponse?.hasSignatureIframeError ||
            signingPopupResponse?.error
          ) {
            this.errorHandlerApiService.saveSigningErrorToLog(
              signingPopupResponse.error,
              this.router.url
            );
            this.showGeneralError();
          }
        })
      );
  }

  private trackSignature(guideSignatureAction: GuideSignatureAction): void {
    if (this.trackingGuideName && this.trackingActionName) {
      this.trackingService.trackGuideSignature(
        this.trackingGuideName,
        this.trackingActionName,
        GuideStepType.OPTIONAL,
        guideSignatureAction
      );
    }
  }

  private showGeneralError(): void {
    this.globalWorkingService.hide();
    this.notificationService.showNotification({
      message: 'DL.LI01.C29',
      type: NotificationType.ERROR
    });
  }

  private scrollIntoId(id: string): void {
    document.getElementById(id).scrollIntoView({
      behavior: 'smooth'
    });
  }
}
