<tr class="forced-thin-border">
  <td
    [attr.data-label]="'DL.OS01.C425' | content"
    class="responsiv-top-padding"
  >
    <co-table-accordion-control
      [contentId]="'DL.OS01.C221'"
      (expandedChange)="toggleExpanded($event)"
    ></co-table-accordion-control>
  </td>
  <td
    class="align--right text--bold"
    [attr.data-label]="'DL.OS01.C426' | content"
  >
    <div>
      {{
        savingsPolicyDetails.betalingForAdministrationOgService.total
          | numberFormat: 2
      }}
      <span [coContent]="'Global.kroner'"></span>
    </div>
  </td>
</tr>

<ng-container *ngIf="expanded">
  <!-- admin -->
  <mitpfa-savings-policy-details-marketrate-subheader-block
    *ngIf="
      savingsPolicyDetails.betalingForAdministrationOgService.administration
        .length > 0
    "
    [savingsPolicyDetails]="savingsPolicyDetails"
    [headerContentId]="'DL.OS01.C222'"
    [isCommissionAndRemuneration]="isCommissionAndRemuneration"
    [lineElements]="
      savingsPolicyDetails.betalingForAdministrationOgService.administration
    "
  >
  </mitpfa-savings-policy-details-marketrate-subheader-block>

  <!-- mægler -->
  <mitpfa-savings-policy-details-marketrate-subheader-block
    *ngIf="
      savingsPolicyDetails.betalingForAdministrationOgService
        .omkostningerTilMaegler.length > 0
    "
    [savingsPolicyDetails]="savingsPolicyDetails"
    [headerContentId]="'DL.OS01.C231'"
    [lineElements]="
      savingsPolicyDetails.betalingForAdministrationOgService
        .omkostningerTilMaegler
    "
  >
  </mitpfa-savings-policy-details-marketrate-subheader-block>

  <!-- rådgivning -->
  <mitpfa-savings-policy-details-marketrate-subheader-block
    *ngIf="
      savingsPolicyDetails.betalingForAdministrationOgService.prisForRaadgivning
        .length > 0
    "
    [savingsPolicyDetails]="savingsPolicyDetails"
    [headerContentId]="'DL.OS01.C236'"
    [feeLineElements]="
      savingsPolicyDetails.betalingForAdministrationOgService.prisForRaadgivning
    "
  >
  </mitpfa-savings-policy-details-marketrate-subheader-block>

  <!-- ændringer -->
  <mitpfa-savings-policy-details-marketrate-subheader-block
    *ngIf="
      savingsPolicyDetails.betalingForAdministrationOgService
        .aendringerPensionsbevis?.linieElementer.length > 0 ||
      savingsPolicyDetails.betalingForAdministrationOgService
        .aendringerPensionsbevis?.aendringerForPensionsBevis.length > 0
    "
    [savingsPolicyDetails]="savingsPolicyDetails"
    [headerContentId]="'DL.OS01.C237'"
    [lineElements]="
      savingsPolicyDetails.betalingForAdministrationOgService
        .aendringerPensionsbevis.linieElementer
    "
    [feeLineElements]="
      savingsPolicyDetails.betalingForAdministrationOgService
        .aendringerPensionsbevis.aendringerForPensionsBevis
    "
  >
  </mitpfa-savings-policy-details-marketrate-subheader-block>

  <!-- investering -->
  <mitpfa-savings-policy-details-marketrate-subheader-block
    *ngIf="
      savingsPolicyDetails.betalingForAdministrationOgService
        .omkostningerVedInvestering?.length > 0
    "
    [savingsPolicyDetails]="savingsPolicyDetails"
    [headerContentId]="'DL.OS01.C239'"
    [lineElements]="
      savingsPolicyDetails.betalingForAdministrationOgService
        .omkostningerVedInvestering
    "
  >
  </mitpfa-savings-policy-details-marketrate-subheader-block>
</ng-container>
