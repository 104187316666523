<div mat-dialog-title>
  <div><h3 [coContent]="'Global.advarsel'"></h3></div>
  <co-icon-times
    *ngIf="dismissBtn"
    (click)="dismiss()"
    class="dialog-close-button"
  ></co-icon-times>
</div>

<mat-dialog-content>
  <strong [coContent]="header" *ngIf="header"></strong>
  <p [coContent]="body1" *ngIf="body1"></p>
  <p [coContent]="body2" *ngIf="body2"></p>
  <p [coContent]="body3" *ngIf="body3"></p>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="modal-buttons">
    <button
      mat-raised-button
      class="capitalize"
      (click)="accept()"
      [coContent]="'Global.ja'"
      data-test-id="unsaved-changes-warning-accept"
    ></button>
    <button
      mat-raised-button
      class="capitalize"
      (click)="cancel()"
      [coContent]="'Global.nej'"
      data-test-id="unsaved-changes-warning-cancel"
    ></button>
  </div>
</mat-dialog-actions>
