import { Injectable } from '@angular/core';
import { AdvisorMenuSection, AdvisorMenuItem } from './advisor-menu.model';

const sessionStorageId = 'advisorMenu';
@Injectable()
export class AdvisorMenuProgressService {
  public progress: Set<string> = this.getProgressFromSession();

  setMenuItemsProgress(
    menuSections: AdvisorMenuSection[]
  ): AdvisorMenuSection[] {
    return menuSections.map((section: AdvisorMenuSection) => {
      section.children = section.children.map((item: AdvisorMenuItem) => ({
        ...item,
        ...{ visited: this.progress && this.progress.has(item.url) }
      }));
      section.visited = section.children.every(child => child.visited);

      return section;
    });
  }

  addProgress(url: string) {
    this.progress.add(url);
    this.saveProgressToSession();
  }

  purgeProgress() {
    this.progress = new Set();
    this.saveProgressToSession();
  }

  private getProgressFromSession(): Set<string> {
    return new Set(JSON.parse(sessionStorage.getItem(sessionStorageId)) || []);
  }

  private saveProgressToSession() {
    sessionStorage.setItem(
      sessionStorageId,
      JSON.stringify(Array.from(this.progress))
    );
  }
}
