import {
  Output,
  EventEmitter,
  Component,
  ChangeDetectionStrategy
} from '@angular/core';

@Component({
  selector: 'mitpfa-combine-savings-summary-not-combined',
  templateUrl: './combine-savings-summary-not-combined.component.html',
  styleUrls: ['./combine-savings-summary-not-combined.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CombineSavingsSummaryNotCombinedComponent {
  @Output() goToNextPage = new EventEmitter<void>();
}
