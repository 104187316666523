/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const FactSheetDocSchema = JoiObj.object({
        daekningId:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        dokument:
            
                    JoiObj.object()
                


.required()


        ,
        id:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        navn:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        pdfaCompliant:
            
                    JoiObj.boolean()
                


.required()


        ,
        privat:
            
                    JoiObj.boolean()
                


.required()


        ,
        sprog:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        
    })
.id('FactSheetDocSchema')

export interface FactSheetDoc { 
    daekningId: string;
    dokument: object;
    id: string;
    navn: string;
    pdfaCompliant: boolean;
    privat: boolean;
    sprog: string;
}

