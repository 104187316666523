export enum LoginMethod {
  PIN_CODE = 1,
  MIT_ID = 2
}

export enum PinCodeLoginStep {
  PIN,
  OTP
}

export enum MitIDConst {
  CloseTabAndReturnToCallerWindow = 'CloseTabAndReturnToCallerWindow',
  RefreshPensionsInfoPageAction = 'RefreshPensionsInfoPage',
  CloseReAuthWindowAction = 'CloseReAuthWindow'
}
