/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PhoneDetails } from './phoneDetails';
import { PhoneDetailsSchema } from './phoneDetails';
import * as JoiObj from "joi";


    export const CreateDigitalIdentityRequestSchema = JoiObj.object({
        phoneDetails:
            
        JoiObj.link('#PhoneDetailsSchema')


.required()


        ,
        email:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        comment:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        
    })
                .shared(PhoneDetailsSchema)
.id('CreateDigitalIdentityRequestSchema')

export interface CreateDigitalIdentityRequest { 
    phoneDetails: PhoneDetails;
    email: string;
    comment?: string;
}

