<div class="datepicker-wrapper">
  <mat-form-field color="accent" (click)="datepicker.open()">
    <mat-label *ngIf="dateLabel">{{ dateLabel }}</mat-label>
    <input
      matInput
      [matDatepicker]="datepicker"
      [(ngModel)]="dateValue"
      [required]="required"
    />
    <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
    <mat-datepicker #datepicker></mat-datepicker>
    <mat-hint *ngIf="description"> {{ description }} </mat-hint>
  </mat-form-field>
</div>

<div class="timepicker-wrapper">
  <mat-form-field color="accent">
    <mat-label *ngIf="hourLabel">{{ hourLabel }}</mat-label>
    <!-- TEXT INPUT / CPR -->
    <input
      matInput
      [required]="required"
      [(ngModel)]="hoursValue"
      [min]="0"
      [max]="23"
      type="number"
      (change)="timepickerChanged()"
    />
  </mat-form-field>
  <mat-form-field color="accent">
    <mat-label *ngIf="minuteLabel">{{ minuteLabel }}</mat-label>
    <!-- TEXT INPUT / CPR -->
    <input
      matInput
      [required]="required"
      [(ngModel)]="minutesValue"
      [min]="0"
      [max]="59"
      type="number"
      (change)="timepickerChanged()"
    />
  </mat-form-field>
</div>
