/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AdvancedPayoutChoicePensionsInfoPolicy } from './advancedPayoutChoicePensionsInfoPolicy';
import { AdvancedPayoutChoicePensionsInfoPolicySchema } from './advancedPayoutChoicePensionsInfoPolicy';
import { AdvancedPayoutChoiceLivnetPolicy } from './advancedPayoutChoiceLivnetPolicy';
import { AdvancedPayoutChoiceLivnetPolicySchema } from './advancedPayoutChoiceLivnetPolicy';
import * as JoiObj from "joi";


    export const AdvancedPayoutChoiceSchema = JoiObj.object({
        advancedChoices:
            
                    JoiObj.boolean()
                


.required()


        ,
        hasCoverageWithPayoutApproved:
            
                    JoiObj.boolean()
                

.allow(null)



        ,
        livnetPolicies:
                JoiObj.array().items(
        
        JoiObj.link('#AdvancedPayoutChoiceLivnetPolicySchema')

        )
        

.required()


        ,
        pensionsInfoPolicies:
                JoiObj.array().items(
        
        JoiObj.link('#AdvancedPayoutChoicePensionsInfoPolicySchema')

        )
        

.required()


        ,
        realEstateAge:
            
                    JoiObj.number()
                


.required()


        ,
        realEstatePeriod:
            
                    JoiObj.number()
                


.required()


        ,
        taxCard:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        wealthAge:
            
                    JoiObj.number()
                


.required()


        ,
        wealthPeriod:
            
                    JoiObj.number()
                


.required()


        ,
        expirationDate:
            
            JoiObj.date()

.allow(null)



        
    })
                .shared(AdvancedPayoutChoiceLivnetPolicySchema)
                .shared(AdvancedPayoutChoicePensionsInfoPolicySchema)
.id('AdvancedPayoutChoiceSchema')

export interface AdvancedPayoutChoice { 
    advancedChoices: boolean;
    hasCoverageWithPayoutApproved?: boolean;
    livnetPolicies: Array<AdvancedPayoutChoiceLivnetPolicy>;
    pensionsInfoPolicies: Array<AdvancedPayoutChoicePensionsInfoPolicy>;
    realEstateAge: number;
    realEstatePeriod: number;
    taxCard: string;
    wealthAge: number;
    wealthPeriod: number;
    expirationDate?: Date;
}

