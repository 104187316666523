<div class="limit-screen-width">
  <h1 [coContent]="'DL.OS01.C427'"></h1>

  <h2>
    <span [coContent]="'DL.AFT01.C01'"></span> {{ policyId }} -
    <span [coContent]="savingsPolicy.typeTekstId"></span>
  </h2>

  <co-loading [show]="!initialized"></co-loading>

  <button mat-raised-button class="button-primary--level3" (click)="goBack()">
    <co-icon-arrow-left class="icon icon--arrow-left"></co-icon-arrow-left>
    <span [coContent]="'Global.Tilbage'"></span>
  </button>
</div>

<div *ngIf="initialized">
  <div class="limit-screen-width">
    <div class="year--container">
      <mat-form-field class="form-field-light yearpicker" color="accent">
        <mat-label [coContent]="'DL.OS01.C429'" class="label"></mat-label>
        <mat-select [value]="chosenYear" (selectionChange)="selectYear($event)">
          <mat-option *ngFor="let year of years" [value]="year">
            <span>{{ year }}</span>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div class="savings--container">
    <div class="limit-screen-width">
      <div class="year-savings">
        <div
          class="headline-title"
          [coContent]="'DL.OS01.C12'"
          [coContentSubst]="savingsPolicyDetails.tilDato"
        ></div>
        <co-primary-number
          class="size-lg"
          [amount]="savingsPolicyDetails.opsparing"
        ></co-primary-number>
        <div
          class="year-savings-note"
          [coContent]="'DL.OS01.C351'"
          [coContentSubst]="policyId"
        ></div>
        <co-info
          *ngIf="savingsPolicyDetails.tabAfErhvervsevneAdvarsel"
          class="spacing-unit--over"
        >
          <span
            [coContent]="'DL.OS01.C362'"
            [coContentSubst]="savingsPolicyDetails.udbetlingOphoerAlder"
          ></span>
        </co-info>
      </div>

      <!-- MARKET RATE -->
      <mitpfa-savings-policy-details-marketrate
        *ngIf="savingsPolicy.livnetAftale"
        [savingsPolicyDetails]="savingsPolicyDetails"
        [unpaidPALTooltip]="unpaidPALTooltip"
        [chosenYear]="chosenYear"
      >
      </mitpfa-savings-policy-details-marketrate>

      <!-- AVERAGE RATE -->
      <mitpfa-savings-policy-details-averagerate
        *ngIf="!savingsPolicy.livnetAftale"
        [savingsPolicyDetails]="savingsPolicyDetails"
        [palTooltip]="palTooltip"
        [unpaidPALTooltip]="unpaidPALTooltip"
      >
      </mitpfa-savings-policy-details-averagerate>
    </div>
  </div>

  <div class="limit-screen-width">
    <!-- MARKET RATE, MICS -->
    <co-bodytext class="misc--container" *ngIf="savingsPolicy.livnetAftale">
      <div class="text--bold" [coContent]="'DL.OS01.C358'"></div>
      <p [coContent]="'DL.OS01.C359'"></p>

      <mitpfa-savings-policy-details-misc
        *ngIf="
          savingsPolicy.type === 'Gennemsnit' ||
          savingsPolicy.type === 'MarkedGennemsnit'
        "
        [savingsPolicyDetails]="savingsPolicyDetails"
        [language]="language"
      >
      </mitpfa-savings-policy-details-misc>
    </co-bodytext>

    <!-- AVERAGE RATE, MICS -->
    <co-bodytext class="misc--container" *ngIf="!savingsPolicy.livnetAftale">
      <!-- FORBEHOLD -->
      <div class="text--bold" [coContent]="'DL.OS01.C353'"></div>
      <p [coContent]="'DL.OS01.C354'"></p>
      <p [coContent]="'DL.OS01.C355'" *ngIf="showDisclaimerDeath"></p>
      <p [coContent]="'DL.OS01.C356'" *ngIf="showDisclaimerSubsidy"></p>

      <!-- UDDYBENDE BESKRIVELSER -->
      <mitpfa-savings-policy-details-misc
        [savingsPolicyDetails]="savingsPolicyDetails"
        [language]="language"
      >
      </mitpfa-savings-policy-details-misc>
    </co-bodytext>
  </div>
</div>
