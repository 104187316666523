<div class="wrapper">
  <co-icon-circle-checkmark
    *ngIf="!ongoingCase && !disabled"
    color="white"
    border="false"
  ></co-icon-circle-checkmark>
  <co-icon-circle-checkmark
    *ngIf="ongoingCase && !disabled"
    fill="white"
    color="primary"
    border="false"
  ></co-icon-circle-checkmark>
  <co-icon-doubt *ngIf="disabled"> </co-icon-doubt>
  <span class="header">
    <div
      [coContent]="headerContent"
      class="header-text text--bold"
      [ngClass]="{ 'header-text--light': ongoingCase }"
    ></div>
    <span
      [coContent]="headerNoteContent"
      [ngClass]="[ongoingCase || ongoingClaim ? 'grey-note' : 'header-note']"
    ></span>
    <co-tooltip
      *ngIf="headerNoteContentTooltip"
      [text]="headerNoteContentTooltip"
    ></co-tooltip>
  </span>
  <span *ngIf="noteContent" [coContent]="noteContent" class="note"></span>
  <span *ngIf="iconForward">
    <co-icon-arrow-right></co-icon-arrow-right>
  </span>
</div>
