/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ServiceInfo } from './serviceInfo';
import { ServiceInfoSchema } from './serviceInfo';
import { LinieElement } from './linieElement';
import { LinieElementSchema } from './linieElement';
import { OpsparingPolice } from './opsparingPolice';
import { OpsparingPoliceSchema } from './opsparingPolice';
import { ResourceModel } from './resourceModel';
import { ResourceModelSchema } from './resourceModel';
import * as JoiObj from "joi";


    export const OpsparingOversigtSchema = JoiObj.object({
        serviceInfo:
            
        JoiObj.link('#ServiceInfoSchema')

.allow(null)



        ,
        fejledeAftaler:
                JoiObj.array().items(
        
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

        )
        
.allow(null)



        ,
        grafDatoProblem:
            
                    JoiObj.boolean()
                


.required()


        ,
        harKundekapitalOpsparing:
            
                    JoiObj.boolean()
                


.required()


        ,
        opgoerelsesdatoAfkastKoloneTooltipContentId:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        opgoerelsesdatoOmkostningerKoloneTooltipContentId:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        opgoerelsesdatoOpsparingKoloneTooltipContentId:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        opsparinger:
                JoiObj.array().items(
        
        JoiObj.link('#LinieElementSchema')

        )
        

.required()


        ,
        policer:
                JoiObj.array().items(
        
        JoiObj.link('#OpsparingPoliceSchema')

        )
        

.required()


        ,
        slutDato:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        startDato:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        totalOpsparing:
            
                    JoiObj.number()
                


.required()


        ,
        udbetalingDoedId:
                JoiObj.array().items(
        
                    JoiObj.number()
                .integer()

        )
        

.required()


        
    })
                .shared(ServiceInfoSchema)
                .shared(LinieElementSchema)
                .shared(OpsparingPoliceSchema)
.id('OpsparingOversigtSchema')

export interface OpsparingOversigt extends ResourceModel { 
    fejledeAftaler?: Array<string>;
    grafDatoProblem: boolean;
    harKundekapitalOpsparing: boolean;
    opgoerelsesdatoAfkastKoloneTooltipContentId: string;
    opgoerelsesdatoOmkostningerKoloneTooltipContentId: string;
    opgoerelsesdatoOpsparingKoloneTooltipContentId: string;
    opsparinger: Array<LinieElement>;
    policer: Array<OpsparingPolice>;
    slutDato: string;
    startDato: string;
    totalOpsparing: number;
    udbetalingDoedId: Array<number>;
}

