import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Legitimation, SuccessResponse } from '@pfa/gen';

@Injectable({
  providedIn: 'root'
})
export class LegitimationApiService {
  constructor(private http: HttpClient) {}

  getLegitimation(): Observable<Legitimation> {
    const url = `/ds/api/legitimation`;
    return this.http.get<Legitimation>(url);
  }

  uploadLegitimationFiles(documents: File[]): Observable<Legitimation> {
    const url = `/ds/api/legitimation/uploadLegitimation`;
    const formData = new FormData();
    documents.forEach(document => {
      formData.append(`documents`, document);
    });
    return this.http.post<Legitimation>(url, formData);
  }

  saveLegitimationWithMitId(): Observable<SuccessResponse> {
    const url = `/ds/api/legitimation/mitid`;
    return this.http.post<SuccessResponse>(url, { legitimation: 'true' });
  }
}
