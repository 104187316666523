<div class="card">
  <div
    class="image"
    [ngClass]="{
      information: image === 'INFORMATION',
      insurance: image === 'INSURANCE',
      'investment-guide': image === 'INVESTMENT_GUIDE',
      pension: image === 'PENSION'
    }"
  ></div>
  <mitpfa-svg-information
    *ngIf="image === 'INFORMATION'"
    class="image information"
  ></mitpfa-svg-information>
  <mitpfa-svg-insurance
    *ngIf="image === 'INSURANCE'"
    class="image insurance"
  ></mitpfa-svg-insurance>
  <mitpfa-svg-investmentguide
    *ngIf="image === 'INVESTMENT_GUIDE'"
    class="image investment-guide"
  ></mitpfa-svg-investmentguide>
  <mitpfa-svg-savings
    *ngIf="image === 'PENSION'"
    class="image"
  ></mitpfa-svg-savings>
  <div class="container">
    <div class="text--bold header-text" [coContent]="headerText"></div>
    <div class="number--icon">{{ number }}</div>
  </div>
  <p [coContent]="bodyText" class="text--note body-text"></p>
</div>
