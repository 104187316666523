import { Component, inject, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IndbetalingOversigt, OpsparingsplanDetaljer } from '@pfa/gen';
import { PensionEstimateFormOfSavingsComponent } from '@mitpfa/shared/pension-estimate/pension-estimate-form-of-savings/pension-estimate-form-of-savings.component';
import { PensionEstimateFormOfSavingsPopupData } from '@mitpfa/shared/models/pension-estimate.model';
import { NavigationStart, Router } from '@angular/router';

@Component({
  selector: 'mitpfa-pension-estimate-form-of-savings-popup',
  templateUrl: './pension-estimate-form-of-savings-popup.component.html'
})
export class PensionEstimateFormOfSavingsPopupComponent {
  savingsPlanDetails: OpsparingsplanDetaljer;
  depositOverview: IndbetalingOversigt;

  private readonly dialogRef: MatDialogRef<PensionEstimateFormOfSavingsComponent> =
    inject(MatDialogRef<PensionEstimateFormOfSavingsComponent>);
  private readonly router: Router = inject(Router);

  constructor(
    @Inject(MAT_DIALOG_DATA) data: PensionEstimateFormOfSavingsPopupData
  ) {
    if (data) {
      this.savingsPlanDetails = data.savingsPlanDetails;
      this.depositOverview = data.depositOverview;
    }
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.handleClose();
      }
    });
  }

  handleClose(): void {
    this.dialogRef.close();
  }
}
