import { Component, DestroyRef, inject, Input, OnInit } from '@angular/core';
import { SigningService } from '@mitpfa/shared';
import { BasketApiService, BusinessDomain } from '@pfa/api';
import {
  SavingsFormType,
  EditablePensionType,
  PensionType,
  DepositPensionType,
  UnmodifiableDepositPensionType,
  ApproveType
} from './pension-estimate-form-of-savings.model';
import { PensionEstimateFormOfSavingsWarningComponent } from '@mitpfa/shared/pension-estimate/pension-estimate-form-of-savings-warning/pension-estimate-form-of-savings-warning.component';
import { WarningResponse } from '@mitpfa/shared/pension-estimate/pension-estimate-form-of-savings-warning/pension-estimate-form-of-savings-warning.model';
import {
  BroadcastMessageType,
  BroadcastService,
  GlobalWorkingService
} from '@pfaform';
import { MatSelectChange } from '@angular/material/select';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import {
  GodkendSvar,
  IndbetalingAldersopsparing,
  IndbetalingOversigt,
  IndbetalingOversigtPolice,
  IndbetalingRatepension,
  OpsparingFormFordeling,
  OpsparingsplanDetaljer,
  SavingsPlanStore,
  SimulatorTileEnum
} from '@pfa/gen';
import { NewBusinessAdvisoryService } from '@pfa/services';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Utils } from '@pfa/utils';

@Component({
  selector: 'mitpfa-pension-estimate-form-of-savings',
  templateUrl: './pension-estimate-form-of-savings.component.html',
  styleUrls: ['./pension-estimate-form-of-savings.component.scss']
})
export class PensionEstimateFormOfSavingsComponent implements OnInit {
  @Input() savingsPlanDetails: OpsparingsplanDetaljer;
  @Input() depositsFormOfSavings: IndbetalingOversigt;

  public originDepositsFormOfSavings: IndbetalingOversigt;
  public selectedPolicy: IndbetalingOversigtPolice;
  public selectedPolicyChanged = true;
  public selectedPolicyDataChanged: boolean;
  public originSelectedPolicy: IndbetalingOversigtPolice;
  public savingsFormTypes: SavingsFormType[] = [];
  public editablePensionTypes: EditablePensionType[] = [];
  public unmodifiableDepositPensionTypes: UnmodifiableDepositPensionType[] = [];
  public addedToBasket: boolean;
  public addedToBasketFailed: boolean;
  public approveNowFailed: boolean;
  public persistedContentInBasket: boolean;
  public nonPersistedContentInBasket: boolean;
  public isNewBusinessAdvisory: boolean;

  private readonly savingsplanStore: SavingsPlanStore =
    inject(SavingsPlanStore);
  private readonly basketApiService: BasketApiService =
    inject(BasketApiService);
  private readonly broadcastService: BroadcastService =
    inject(BroadcastService);
  private readonly newBusinessAdvisoryService: NewBusinessAdvisoryService =
    inject(NewBusinessAdvisoryService);
  private readonly router: Router = inject(Router);
  private readonly dialog: MatDialog = inject(MatDialog);
  private readonly globalWorking: GlobalWorkingService =
    inject(GlobalWorkingService);
  private readonly signingService: SigningService = inject(SigningService);
  private readonly destroyRef: DestroyRef = inject(DestroyRef);

  ngOnInit(): void {
    this.originDepositsFormOfSavings = JSON.parse(
      JSON.stringify(this.depositsFormOfSavings)
    );
    this.initSavingsFormTypes();
    this.initEditablePensionTypes();
    this.initUnmodifiableDepositPensionType();
    this.initDepositsFormOfSavings();
    this.setPersistedContentInBasket();
    this.setInitialValues();

    this.newBusinessAdvisoryService.isNewBusinessAdvisory$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(isNewBusinessAdvisoryData => {
        this.isNewBusinessAdvisory = isNewBusinessAdvisoryData;
      });
  }

  private initSavingsFormTypes(): void {
    this.savingsFormTypes = [];

    this.savingsFormTypes.push(
      this.createSavingsFormType(
        'RateLivsPension',
        'DL.PP01.C540',
        ['Ratepension', 'Livspension'],
        false
      )
    );

    this.savingsFormTypes.push(
      this.createSavingsFormType(
        'AldersRateLivsPension',
        'DL.PP01.C541',
        ['Ratepension', 'Aldersopsparing', 'Livspension'],
        false
      )
    );

    this.savingsFormTypes.push(
      this.createSavingsFormType(
        'RateAldersLivsPension',
        'DL.PP01.C542',
        ['Ratepension', 'Aldersopsparing', 'Livspension'],
        false
      )
    );

    this.savingsFormTypes.push(
      this.createSavingsFormType(
        'AldersLivsPension',
        'DL.PP01.C543',
        ['Aldersopsparing', 'Livspension'],
        false
      )
    );

    this.savingsFormTypes.push(
      this.createSavingsFormType(
        'LivsPension',
        'DL.PP01.C544',
        ['Livspension'],
        false
      )
    );

    this.savingsFormTypes.push(
      this.createSavingsFormType(
        'KapitalRateLivsPension',
        'DL.PP01.C561',
        [],
        true
      )
    );

    this.savingsFormTypes.push(
      this.createSavingsFormType(
        'RateKapitalLivsPension',
        'DL.PP01.C562',
        [],
        true
      )
    );
  }

  private createSavingsFormType(
    type: OpsparingFormFordeling,
    contentId: string,
    pensionTypes: PensionType[],
    disabled: boolean
  ): SavingsFormType {
    const savingsFormType = new SavingsFormType();
    savingsFormType.type = type;
    savingsFormType.contentId = contentId;
    savingsFormType.pensionTypes = pensionTypes;
    savingsFormType.disabled = disabled;
    return savingsFormType;
  }

  private initEditablePensionTypes(): void {
    this.editablePensionTypes = [];

    this.editablePensionTypes.push(
      this.createEditablePensionType('Ratepension', 'DL.PP01.C546')
    );

    this.editablePensionTypes.push(
      this.createEditablePensionType('Aldersopsparing', 'DL.PP01.C554')
    );
  }

  private createEditablePensionType(
    pensionType: PensionType,
    contentId: string
  ): EditablePensionType {
    const editablePensionType = new EditablePensionType();
    editablePensionType.pensionType = pensionType;
    editablePensionType.contentId = contentId;
    return editablePensionType;
  }

  private initUnmodifiableDepositPensionType(): void {
    this.unmodifiableDepositPensionTypes = [];

    this.unmodifiableDepositPensionTypes.push(
      this.createUnmodifiableDepositPensionType(
        'indbetalingLivsvarigLivspension',
        'DL.PP01.C555'
      )
    );
  }

  private createUnmodifiableDepositPensionType(
    depositPensionType: DepositPensionType,
    contentId: string
  ): UnmodifiableDepositPensionType {
    const unmodifiableDepositPensionType = new UnmodifiableDepositPensionType();
    unmodifiableDepositPensionType.depositPensionType = depositPensionType;
    unmodifiableDepositPensionType.contentId = contentId;
    return unmodifiableDepositPensionType;
  }

  private initDepositsFormOfSavings(): void {
    if (this.depositsFormOfSavings.policer.length > 0) {
      const depositPolicies = this.depositsFormOfSavings.policer.filter(
        depositPolicy =>
          depositPolicy.id === this.depositsFormOfSavings.primaerAftaleId
      );
      if (depositPolicies.length === 1) {
        this.selectedPolicy = depositPolicies[0];
      } else {
        this.selectedPolicy = this.depositsFormOfSavings.policer[0];
      }

      this.originSelectedPolicy = this.findOriginselectedPolicy(
        this.selectedPolicy.id
      );
    }
  }

  private findOriginselectedPolicy(
    depositPolicyId: string
  ): IndbetalingOversigtPolice {
    return this.originDepositsFormOfSavings.policer.filter(
      depositPolicy => depositPolicy.id === depositPolicyId
    )[0];
  }

  private setPersistedContentInBasket(): void {
    this.basketApiService
      .get(BusinessDomain.SavingsForm, this.selectedPolicy.id)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(result => {
        this.nonPersistedContentInBasket = result.harIkkePersisteretIndhold;
        this.persistedContentInBasket = result.harPersisteretIndhold;
      });
  }

  private setInitialValues(): void {
    this.depositsFormOfSavings.policer.forEach(
      (policy: IndbetalingOversigtPolice) => {
        if (
          policy.detaljer?.indbetalingRatepension?.indbetalingLoftMax &&
          Utils.isNullOrUndefined(
            policy.detaljer?.indbetalingRatepension?.oensketIndbetaling
          )
        ) {
          policy.detaljer.indbetalingRatepension.oensketIndbetaling =
            policy.detaljer.indbetalingRatepension.indbetalingLoftMax;
        }

        if (
          policy.detaljer?.indbetalingAldersopsparing?.indbetalingLoftMax &&
          Utils.isNullOrUndefined(
            policy.detaljer?.indbetalingAldersopsparing?.oensketIndbetaling
          )
        ) {
          policy.detaljer.indbetalingAldersopsparing.oensketIndbetaling =
            policy.detaljer.indbetalingAldersopsparing.indbetalingLoftMax;
        }
      }
    );
  }

  public selectPolicy(event: MatSelectChange): void {
    this.selectedPolicyChanged = false;

    this.selectedPolicy = this.depositsFormOfSavings.policer.filter(
      depositPolicy => depositPolicy.id === event.value
    )[0];

    this.originSelectedPolicy = this.findOriginselectedPolicy(
      this.selectedPolicy.id
    );

    this.addedToBasket = false;
    this.addedToBasketFailed = false;

    this.selectedPolicyDataChanged = this.isPolicyDataChanged(
      this.originSelectedPolicy,
      this.selectedPolicy
    );

    this.setPersistedContentInBasket();

    setTimeout(() => {
      this.selectedPolicyChanged = true;
    }, 100);
  }

  public isSavingsFormKnown(): boolean {
    return (
      this.savingsFormTypes.filter(
        savingsFormType =>
          savingsFormType.type ===
          this.selectedPolicy.detaljer.valgtOpsparingFormFordeling
      ).length > 0
    );
  }

  public isEditable(): boolean {
    return (
      !this.selectedPolicy.detaljer.paragraf53A &&
      !this.selectedPolicy.detaljer.forsikringsOrdning &&
      !this.persistedContentInBasket &&
      !this.isBlockedPolicy(this.selectedPolicy)
    );
  }

  isBlockedPolicy(policy: IndbetalingOversigtPolice): boolean {
    return policy.type === 'Gennemsnit';
  }

  public isPensionTypeEditable(paramPensionType: PensionType): boolean {
    const savingsFormType: SavingsFormType = this.savingsFormTypes.filter(
      savingsForm =>
        savingsForm.type ===
        this.selectedPolicy.detaljer.valgtOpsparingFormFordeling
    )[0];
    return (
      savingsFormType?.pensionTypes.filter(
        pensionType => pensionType === paramPensionType
      ).length > 0
    );
  }

  public updatePolicyDataChanged(): void {
    this.selectedPolicyDataChanged = this.isPolicyDataChanged(
      this.originSelectedPolicy,
      this.selectedPolicy
    );
  }

  public updateSavingsDepoistOther(
    depositPension: IndbetalingRatepension | IndbetalingAldersopsparing,
    depositAmountOther: number
  ): void {
    depositPension.indbetalingAndreOrdninger = depositAmountOther;
    this.selectedPolicyDataChanged = this.isPolicyDataChanged(
      this.originSelectedPolicy,
      this.selectedPolicy
    );
  }

  public updateSavingsDepoist(
    depositPension: IndbetalingRatepension | IndbetalingAldersopsparing,
    depositAmount: number
  ): void {
    depositPension.oensketIndbetaling = depositAmount;
    this.selectedPolicyDataChanged = this.isPolicyDataChanged(
      this.originSelectedPolicy,
      this.selectedPolicy
    );
  }

  private isPolicyDataChanged(
    originPolicy: IndbetalingOversigtPolice,
    newPolicy: IndbetalingOversigtPolice
  ): boolean {
    if (
      originPolicy.detaljer?.valgtOpsparingFormFordeling !==
      newPolicy.detaljer?.valgtOpsparingFormFordeling
    ) {
      return true;
    }

    const newRatePension = newPolicy.detaljer?.indbetalingRatepension;
    const originRatePension = originPolicy.detaljer?.indbetalingRatepension;
    if (newRatePension?.fordelingValg === 'MestMuligt') {
      if (
        originRatePension?.fordelingValg !== newRatePension?.fordelingValg ||
        originRatePension?.indbetalingAndreOrdninger !==
          newRatePension?.indbetalingAndreOrdninger
      ) {
        return true;
      }
    } else {
      if (
        originRatePension?.oensketIndbetaling !==
        newRatePension?.oensketIndbetaling
      ) {
        return true;
      }
    }

    const newOldAgeSavings = newPolicy.detaljer?.indbetalingAldersopsparing;
    const originOldAgeSavings =
      originPolicy.detaljer?.indbetalingAldersopsparing;
    if (newOldAgeSavings?.fordelingValg === 'MestMuligt') {
      if (
        originOldAgeSavings?.fordelingValg !==
          newOldAgeSavings?.fordelingValg ||
        originOldAgeSavings?.indbetalingAndreOrdninger !==
          newOldAgeSavings?.indbetalingAndreOrdninger
      ) {
        return true;
      }
    } else {
      if (
        originOldAgeSavings?.oensketIndbetaling !==
        newOldAgeSavings?.oensketIndbetaling
      ) {
        return true;
      }
    }

    return false;
  }

  private setOriginPolicyChangedValues(): void {
    this.originSelectedPolicy.detaljer.valgtOpsparingFormFordeling =
      this.selectedPolicy.detaljer.valgtOpsparingFormFordeling;

    if (
      this.selectedPolicy.detaljer?.indbetalingRatepension &&
      this.originSelectedPolicy.detaljer?.indbetalingRatepension
    ) {
      this.originSelectedPolicy.detaljer.indbetalingRatepension.fordelingValg =
        this.selectedPolicy.detaljer.indbetalingRatepension.fordelingValg;
      this.originSelectedPolicy.detaljer.indbetalingRatepension.indbetalingAndreOrdninger =
        this.selectedPolicy.detaljer.indbetalingRatepension.indbetalingAndreOrdninger;
      this.originSelectedPolicy.detaljer.indbetalingRatepension.oensketIndbetaling =
        this.selectedPolicy.detaljer.indbetalingRatepension.oensketIndbetaling;
    }

    if (
      this.selectedPolicy.detaljer.indbetalingAldersopsparing &&
      this.originSelectedPolicy.detaljer.indbetalingAldersopsparing
    ) {
      this.originSelectedPolicy.detaljer.indbetalingAldersopsparing.fordelingValg =
        this.selectedPolicy.detaljer.indbetalingAldersopsparing.fordelingValg;
      this.originSelectedPolicy.detaljer.indbetalingAldersopsparing.indbetalingAndreOrdninger =
        this.selectedPolicy.detaljer.indbetalingAldersopsparing.indbetalingAndreOrdninger;
      this.originSelectedPolicy.detaljer.indbetalingAldersopsparing.oensketIndbetaling =
        this.selectedPolicy.detaljer.indbetalingAldersopsparing.oensketIndbetaling;
    }
  }

  public approvePrepare(approvetype: ApproveType): void {
    if (
      this.selectedPolicy.detaljer.valgtOpsparingFormFordeling === 'LivsPension'
    ) {
      this.dialog
        .open(PensionEstimateFormOfSavingsWarningComponent)
        .afterClosed()
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe((result: WarningResponse) => {
          if (result && result.result === 'ACCEPT') {
            this.approve(approvetype);
          }
        });
    } else {
      this.approve(approvetype);
    }
  }

  private approve(approvetype: ApproveType): void {
    this.globalWorking.show();
    this.savingsplanStore
      .opsparingsplanOpdateropsparingsformPost(this.selectedPolicy)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: saveDepositOverviewPolicyResult => {
          if (saveDepositOverviewPolicyResult.success) {
            if (approvetype === 'Basket') {
              this.addToBasket(approvetype);
            } else {
              this.sign();
            }
            this.savingsplanStore.invalidateAll();
          } else {
            this.approveFailed(approvetype);
          }
        },
        error: () => {
          this.approveFailed(approvetype);
        }
      });
  }

  private addToBasket(approvetype: ApproveType): void {
    this.basketApiService
      .addToBasket()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: addToBasketResult => {
          if (addToBasketResult.success) {
            this.addedToBasket = true;
            this.addedToBasketFailed = false;
            this.broadcastService.broadcastMessage(
              BroadcastMessageType.ADD_TO_BASKET
            );
            this.setOriginPolicyChangedValues();
            this.selectedPolicyDataChanged = false;
            this.globalWorking.hide();
          } else {
            this.approveFailed(approvetype);
          }
        },
        error: () => {
          this.approveFailed(approvetype);
        }
      });
  }

  public sign(): void {
    this.signingService
      .performBasketSigning([], SimulatorTileEnum.PpOpsparingsplan)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (response: GodkendSvar) => {
          if (response) {
            this.complete(response);
          } else {
            this.signingService.showSigningError();
          }
          this.globalWorking.hide();
        },
        error: _ => {
          this.signingService.showSigningError();
          this.globalWorking.hide();
        }
      });
  }

  complete(godkendSvar: GodkendSvar): void {
    this.signingService
      .showSigningCompleted(godkendSvar)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(_ => {
        this.savingsplanStore.invalidateAll();
        this.navigateToFrontpage();
      });
  }

  private navigateToFrontpage(): void {
    this.router.navigateByUrl('/mitoverblik');
  }

  private approveFailed(approvetype: ApproveType): void {
    if (approvetype === 'Basket') {
      this.addedToBasket = false;
      this.addedToBasketFailed = true;
    } else {
      this.approveNowFailed = true;
    }
    this.globalWorking.hide();
  }
}
