import { Injectable } from '@angular/core';
import { isUndefined } from 'lodash';
import {
  DaekningPrisInterface,
  ForsikringDaekningPris,
  GennemsnitsrenteOmkostning,
  PrisGruppe,
  Skattekode,
  VederlagOmkostning
} from '@pfa/gen';
import { InterestCost, PrisInterval, PrisOmkostnning } from '@pfa/models';
import { ContentUtilService } from '@pfa/core';
import { DecimalPipe } from '@angular/common';

// FIXME models in their own files
export class PrisElement {
  order: number;
  tekst: string;
  kr?: number;
  procent?: number;
  toolTip?: string;
}

export class PrisMaeglerOmkostninger {
  ophoerDato: Date;
  loebende: PrisElement[];
  etablering: PrisElement[];
  gebyrer: CostsFee[];
}

export interface InsuranceRow {
  group: string;
  order: number;
  treatmentQuestion: string;
  treatmentReason: string;
  payment: number;
  withoutPrice: number;
  withPrice: number;
  coverageId: number;
}

interface CostsFee {
  type: string;
  beloeb: number;
}
interface InsuranceText {
  order: number;
  treatmentQuestion?: string;
  treatmentReason?: string;
  subst: boolean;
}

@Injectable()
export class PriserService {
  constructor(private readonly contentUtilService: ContentUtilService) {}

  private readonly indbetalingTekster = [
    'DL.PL01.C35',
    'DL.PL01.C36',
    'DL.PL01.C37',
    'DL.PL01.C38'
  ];
  private readonly opsparingTekster = [
    'DL.PL01.C39',
    'DL.PL01.C40',
    'DL.PL01.C41',
    'DL.PL01.C42'
  ];
  private readonly engangsTekster = [
    'DL.PL01.C55',
    'DL.PL01.C56',
    'DL.PL01.C57',
    'DL.PL01.C58'
  ];

  private readonly insuranceTexts = {
    _401: {
      order: 1,
      treatmentQuestion: 'DL.PL01.C72',
      treatmentReason: 'DL.PL01.C73'
    },
    _402: {
      order: 2,
      treatmentQuestion: 'DL.PL01.C75',
      treatmentReason: 'DL.PL01.C76'
    },
    _403: {
      order: 3,
      treatmentQuestion: 'DL.PL01.C77',
      treatmentReason: 'DL.PL01.C78'
    },

    _301: {
      order: 10,
      treatmentQuestion: 'DL.PL01.C80',
      treatmentReason: 'DL.PL01.C81'
    },
    _303: {
      order: 11,
      treatmentQuestion: 'DL.PL01.C82',
      treatmentReason: 'DL.PL01.C83'
    },
    _311: {
      order: 12,
      treatmentQuestion: 'DL.PL01.C84',
      treatmentReason: 'DL.PL01.C85'
    },
    _312: {
      order: 13,
      treatmentQuestion: 'DL.PL01.C86',
      treatmentReason: 'DL.PL01.C87'
    },
    _313: {
      order: 14,
      treatmentQuestion: 'DL.PL01.C88',
      treatmentReason: 'DL.PL01.C89'
    },
    _314: {
      order: 15,
      treatmentQuestion: 'DL.PL01.C90',
      treatmentReason: 'DL.PL01.C91'
    },
    _304: {
      order: 16,
      treatmentQuestion: 'DL.PL01.C94',
      treatmentReason: 'DL.PL01.C95'
    },
    _305: {
      order: 17,
      treatmentQuestion: 'DL.PL01.C96',
      treatmentReason: 'DL.PL01.C97'
    },
    _302: {
      order: 18,
      treatmentQuestion: 'DL.PL01.C120',
      treatmentReason: 'DL.PL01.C121'
    },

    _201: { order: 21, treatmentQuestion: 'DL.PL01.C99' },
    _202: { order: 22, treatmentQuestion: 'DL.PL01.C100', subst: true },
    _203: { order: 23, treatmentQuestion: 'DL.PL01.C101', subst: true },
    _204: { order: 24, treatmentQuestion: 'DL.PL01.C99' },
    _205: { order: 25, treatmentQuestion: 'DL.PL01.C100', subst: true },

    _801: { order: 31, treatmentQuestion: 'DL.PL01.C103' },
    _814: { order: 32, treatmentQuestion: 'DL.PL01.C103' },
    _815: { order: 33, treatmentQuestion: 'DL.PL01.C103' },
    _816: { order: 34, treatmentQuestion: 'DL.PL01.C103' },
    _817: { order: 35, treatmentQuestion: 'DL.PL01.C103' },
    _802: { order: 36, treatmentQuestion: 'DL.PL01.C104' },
    _811: { order: 37, treatmentQuestion: 'DL.PL01.C104' },
    _818: { order: 37, treatmentQuestion: 'DL.PL01.C104' },
    _812: { order: 38, treatmentQuestion: 'DL.PL01.C104' },
    _813: { order: 39, treatmentQuestion: 'DL.PL01.C104' },
    _807: { order: 40, treatmentQuestion: 'DL.PL01.C105' },
    _808: { order: 41, treatmentQuestion: 'DL.PL01.C106' },
    _804: { order: 42, treatmentQuestion: 'DL.PL01.C107' },
    _805: { order: 43, treatmentQuestion: 'DL.PL01.C108', subst: true },
    _898: { order: 44, treatmentQuestion: 'DL.PL01.C179', subst: true },
    _899: { order: 45, treatmentQuestion: 'DL.PL01.C180', subst: true },

    _601_SK3: { order: 50, treatmentQuestion: 'DL.PL01.C109', subst: true },
    _602_SK2: { order: 51, treatmentQuestion: 'DL.PL01.C110', subst: true },
    _605_SK9: { order: 52, treatmentQuestion: 'DL.PL01.C111', subst: true },
    _606_SK1: { order: 53, treatmentQuestion: 'DL.PL01.C112', subst: true },
    _607_SK33: { order: 54, treatmentQuestion: 'DL.PL01.C113', subst: true },
    _601_SK7: { order: 55, treatmentQuestion: 'DL.PL01.C114', subst: true },

    _502: { order: 60, treatmentQuestion: 'DL.PL01.C115' },
    _707: { order: 61, treatmentQuestion: 'DL.PL01.C150', subst: true },
    _708: { order: 62, treatmentQuestion: 'DL.PL01.C151', subst: true },
    _504: { order: 63, treatmentQuestion: 'DL.PL01.C116', subst: true },
    _505: { order: 64, treatmentQuestion: 'DL.PL01.C117', subst: true },
    _506: { order: 65, treatmentQuestion: 'DL.PL01.C200', subst: true },
    _507: { order: 66, treatmentQuestion: 'DL.PL01.C200', subst: true },
    _509: { order: 67, treatmentQuestion: 'DL.PL01.C118' },

    group: id => {
      if (id === 401) {
        return 'DL.PL01.C71';
      }
      if (id >= 402 && id <= 403) {
        return 'DL.PL01.C74';
      }
      if (id >= 200 && id <= 299) {
        return 'DL.PL01.C98';
      }
      if (id >= 300 && id <= 399) {
        return 'DL.PL01.C79';
      }
      if ((id >= 500 && id <= 599) || id === 707 || id === 708) {
        return 'DL.PL01.C146';
      }
      if (id >= 600 && id <= 699) {
        return 'DL.PL01.C102';
      }
      if (id >= 800 && id <= 899) {
        return 'DL.PL01.C102';
      }
    }
  };

  private static formatNumber(n: number): string {
    return new DecimalPipe('da').transform(n);
  }

  getListeByPrisGruppeListe(prisgruppeListe: PrisGruppe[]): PrisElement[] {
    if (prisgruppeListe) {
      const liste = new Array(0);
      let prisGruppeliste = new Array(0);
      for (const prisgruppe of prisGruppeliste) {
        prisGruppeliste = this.getListeByPrisGruppe(prisgruppe);
        if (prisGruppeliste && prisGruppeliste.length > 0) {
          liste.push({
            headline: this.getPakkeTekstByType(prisgruppe.pakkeNavnType),
            liste: prisGruppeliste
          });
        }
      }
      return liste;
    }
    return [];
  }

  getListeByPrisGruppe(prisgruppe: PrisGruppe): PrisElement[] {
    const fastpris = new Array(0);
    if (prisgruppe) {
      const indbetaling = this.getIntervalListe(
        prisgruppe.indbetalingListe,
        this.indbetalingTekster,
        0
      );
      const opsparing = this.getIntervalListe(
        prisgruppe.opsparingListe,
        this.opsparingTekster,
        20000000000
      );
      if (prisgruppe.fastPris && prisgruppe.fastPris !== 0) {
        const pe = new PrisElement();
        pe.order = 30000000000;
        pe.tekst = this.contentUtilService.getContent('DL.PL01.C43');
        pe.kr = prisgruppe.fastPris;
        fastpris.push(pe);
      }
      return indbetaling.concat(opsparing, fastpris);
    }
    return [];
  }

  private getIntervalListe(
    prisIntervalListe: PrisInterval[],
    tekstListe: string[],
    uberOrder: number
  ): PrisElement[] {
    const liste = [];
    for (const prisInterval of prisIntervalListe) {
      if (prisInterval.procent) {
        const fra = prisInterval.beloebFra;
        const til = prisInterval.beloebTil;
        const procentValue = prisInterval.procent;
        // Tjek for y>0 - (null)
        if (fra > 0 && til === null) {
          liste.push({
            order: 1000000000 + uberOrder,
            tekst: this.contentUtilService.getContent(tekstListe[2], [
              PriserService.formatNumber(fra)
            ]),
            procent: procentValue
          });
        } else if (fra === 0 && til === null) {
          // Tjek for 0 - x>0
          liste.push({
            order: 2000000000 + uberOrder,
            tekst: this.contentUtilService.getContent(tekstListe[3]),
            procent: procentValue
          });
        } else if (fra === 0 && til > 0) {
          // Tjek for 0 - x>0
          liste.push({
            order: 0 + uberOrder,
            tekst: this.contentUtilService.getContent(tekstListe[0], [
              PriserService.formatNumber(til)
            ]),
            procent: procentValue
          });
        } else if (fra > 0 && til > 0) {
          // Tjek for y>0 - x>0
          liste.push({
            order: til + uberOrder,
            tekst: this.contentUtilService.getContent(tekstListe[1], [
              PriserService.formatNumber(fra),
              PriserService.formatNumber(til)
            ]),
            procent: procentValue
          });
        }
      }
    }
    return liste;
  }

  /**
   * Finder teksten på pakkenavn
   */
  private getPakkeTekstByType(pakkeNavnType: string): string {
    const pakkeTekster = {
      PersonligRaadgivning: this.contentUtilService.getContent('DL.PL01.C48'),
      SpecialRaadgivning: this.contentUtilService.getContent('DL.PL01.C47'),
      TelefonRaadgivning: this.contentUtilService.getContent('DL.PL01.C46'),
      VirksomhedRaadgivning: this.contentUtilService.getContent('DL.PL01.C49')
    };
    const tekst = pakkeTekster[pakkeNavnType];
    if (tekst) {
      return tekst;
    }
    return this.contentUtilService.getContent('DL.PL01.C50');
  }

  getTransaktionOmkostningListe(
    transaktionOmkostningListe: PrisOmkostnning[],
    fripolice?: boolean
  ): PrisOmkostnning[] {
    if (transaktionOmkostningListe) {
      const liste = [];
      for (const transaktionOmkostning of transaktionOmkostningListe) {
        if (transaktionOmkostning.beloeb && transaktionOmkostning.beloeb > 0) {
          if (fripolice && transaktionOmkostning.type === 'AendrOpsProfilPFA') {
            liste.push({
              type:
                this.contentUtilService.getContent(
                  'TransaktionsGebyr.' + transaktionOmkostning.type
                ) +
                ' ' +
                this.contentUtilService.getContent('DL.PL01.C165'),
              beloeb: transaktionOmkostning.beloeb
            });
          } else {
            liste.push({
              type: this.contentUtilService.getContent(
                'TransaktionsGebyr.' + transaktionOmkostning.type
              ),
              beloeb: transaktionOmkostning.beloeb
            });
          }
        }
      }
      return liste;
    }
    return [];
  }

  getMaeglerOmkostninger(
    vederlagOmkostning: VederlagOmkostning
  ): PrisMaeglerOmkostninger {
    if (vederlagOmkostning) {
      const maeglerOmkostninger: PrisMaeglerOmkostninger =
        new PrisMaeglerOmkostninger();

      maeglerOmkostninger.ophoerDato =
        vederlagOmkostning.etableringOmkostningOphoerDato;

      const loebendeIndbetaling = this.getIntervalListe(
        vederlagOmkostning.loebendeOmkostningKnaekListe,
        this.indbetalingTekster,
        0
      );

      const loebendeOpsparing = this.getIntervalListe(
        vederlagOmkostning.loebendeOmkostningDepotKnaekListe,
        this.opsparingTekster,
        20000000000
      );

      const loebendeFastpris = this.buildFixedPriceLoebende(vederlagOmkostning);

      this.buildOngoingCosts(
        vederlagOmkostning,
        maeglerOmkostninger,
        loebendeIndbetaling,
        loebendeOpsparing,
        loebendeFastpris
      );

      const vederlagIndbetaling = this.getIntervalListe(
        vederlagOmkostning.etableringOmkostningKnaekListe,
        this.indbetalingTekster,
        0
      );

      const vederlagOpsparing = this.getIntervalListe(
        vederlagOmkostning.etableringOmkostningDepotKnaekListe,
        this.opsparingTekster,
        20000000000
      );

      const vederlagFastpris = this.buildFixedPriceVederlag(
        vederlagOmkostning,
        loebendeIndbetaling,
        loebendeOpsparing
      );

      maeglerOmkostninger.etablering = vederlagIndbetaling.concat(
        vederlagOpsparing,
        vederlagFastpris
      );
      maeglerOmkostninger.gebyrer = this.buildCostsFee(vederlagOmkostning);

      if (
        maeglerOmkostninger.loebende.length > 0 ||
        maeglerOmkostninger.etablering.length > 0 ||
        maeglerOmkostninger.gebyrer.length > 0
      ) {
        return maeglerOmkostninger;
      }
    }

    return null;
  }

  private buildFixedPriceLoebende(
    vederlagOmkostning: VederlagOmkostning
  ): PrisElement[] {
    const array: PrisElement[] = [];
    const orderNumber = 30000000000;

    if (vederlagOmkostning.loebendeOmkostningFastBeloeb) {
      array.push({
        order: orderNumber,
        tekst: this.contentUtilService.getContent('DL.PL01.C43'),
        kr: vederlagOmkostning.loebendeOmkostningFastBeloeb
      });
    }

    return array;
  }

  private buildFixedPriceVederlag(
    vederlagOmkostning: VederlagOmkostning,
    indbetaling: PrisElement[],
    opsparing: PrisElement[]
  ): PrisElement[] {
    const array: PrisElement[] = [];
    const orderNumber = 30000000000;

    if (vederlagOmkostning.etableringOmkostningFastBeloeb) {
      let tekst;
      if (indbetaling.length > 0 || opsparing.length > 0) {
        tekst = this.contentUtilService.getContent('DL.PL01.C162');
      } else {
        tekst = this.contentUtilService.getContent('DL.PL01.C163');
      }

      array.push({
        order: orderNumber,
        tekst: tekst,
        kr: vederlagOmkostning.etableringOmkostningFastBeloeb,
        toolTip: 'tooltipI59'
      });
    }

    return array;
  }

  private buildOngoingCosts(
    vederlagOmkostning: VederlagOmkostning,
    maeglerOmkostninger: PrisMaeglerOmkostninger,
    indbetaling: PrisElement[],
    opsparing: PrisElement[],
    fastpris: PrisElement[]
  ): void {
    const engangsbetaling = this.getIntervalListe(
      vederlagOmkostning.loebendeOmkostningIndskudKnaekListe,
      this.engangsTekster,
      10000000000
    );

    const gruppepris = [];
    if (
      vederlagOmkostning.vederlagLoebendeUdvalgteDaekningsgrupperProcentSats
    ) {
      gruppepris.push({
        order: 0,
        tekst: this.contentUtilService.getContent('DL.PL01.C208'),
        procent:
          vederlagOmkostning.vederlagLoebendeUdvalgteDaekningsgrupperProcentSats
      });
    }

    maeglerOmkostninger.loebende = indbetaling.concat(
      engangsbetaling,
      opsparing,
      gruppepris,
      fastpris
    );
  }

  private buildCostsFee(vederlagOmkostning: VederlagOmkostning): CostsFee[] {
    const fee: CostsFee[] = [];

    if (vederlagOmkostning.transaktionPrisListe) {
      vederlagOmkostning.transaktionPrisListe.forEach(pris => {
        if (pris.beloeb > 0) {
          fee.push({
            type: pris.transaktionsText,
            beloeb: pris.beloeb
          });
        }
      });
    }

    return fee;
  }

  getInvesteringsOmkostninger(investeringOmkostning: any): PrisOmkostnning[] {
    if (investeringOmkostning) {
      const liste = [];
      const admin = investeringOmkostning.administrationUdbetalingSikring;
      if (!isUndefined(admin)) {
        const adminProcentValue = !isUndefined(admin.value)
          ? admin.value
          : admin;
        if (adminProcentValue > 0) {
          liste.push({
            type: this.contentUtilService.getContent('DL.PL01.C145'),
            procent: adminProcentValue
          });
        }
      }
      const basis = investeringOmkostning.basisKapitalPfaInvertere;
      if (!isUndefined(basis)) {
        const basisProcentValue = !isUndefined(basis.value)
          ? basis.value
          : basis;
        if (basisProcentValue >= 0) {
          liste.push({
            type: this.contentUtilService.getContent('DL.PL01.C93'),
            procent: basisProcentValue
          });
        }
      }
      return liste;
    }
    return null;
  }

  getAverageInterestCosts(
    averageInterestCostList: GennemsnitsrenteOmkostning[]
  ): InterestCost[] {
    const ret = [];

    if (!averageInterestCostList) {
      return ret;
    }

    averageInterestCostList.forEach(cost => {
      if (cost.beloeb > 0) {
        if (cost.krPolicenummer) {
          ret.push({
            text: this.contentUtilService.getContent(
              'DL.PL01.C184',
              cost.krPolicenummer
            ),
            amount: cost.beloeb
          });
        } else {
          ret.push({
            text: this.contentUtilService.getContent('DL.PL01.C43'),
            amount: cost.beloeb,
            percent: -1
          });
        }
      } else if (cost.paymentPriceGroup) {
        const percentageList = cost.paymentPriceGroup;
        percentageList.forEach(percentageEntry => {
          let text;
          if (
            (percentageEntry.amountFrom === null ||
              percentageEntry.amountFrom === 0) &&
            (percentageEntry.amountTo === null ||
              percentageEntry.amountTo === 0)
          ) {
            text = this.contentUtilService.getContent('DL.PL01.C205');
          } else if (
            percentageEntry.amountFrom === null ||
            percentageEntry.amountFrom === 0
          ) {
            text = this.contentUtilService.getContent('DL.PL01.C202', [
              PriserService.formatNumber(percentageEntry.amountTo)
            ]);
          } else if (
            percentageEntry.amountTo === null ||
            percentageEntry.amountTo === 0
          ) {
            text = this.contentUtilService.getContent('DL.PL01.C204', [
              PriserService.formatNumber(percentageEntry.amountFrom)
            ]);
          } else {
            text = this.contentUtilService.getContent('DL.PL01.C203', [
              PriserService.formatNumber(percentageEntry.amountFrom),
              PriserService.formatNumber(percentageEntry.amountTo)
            ]);
          }
          ret.push({
            text: text,
            amount: -1,
            percent: percentageEntry.percent
          });
        });
      }
    });
    return ret;
  }

  private getInsuranceRow(
    text: InsuranceText,
    coverage: DaekningPrisInterface,
    fastGroupText?
  ): InsuranceRow {
    let textSub;

    if (coverage.daekningId === 708) {
      textSub = [
        coverage.alder,
        (coverage as ForsikringDaekningPris).udbetalingsperiode
      ];
    } else if (text.subst) {
      textSub = [coverage.alder];
    }
    return {
      group: fastGroupText
        ? fastGroupText
        : this.contentUtilService.getContent(
            this.insuranceTexts.group(coverage.daekningId)
          ),
      order: text.order,
      treatmentQuestion: this.contentUtilService.getContent(
        text.treatmentQuestion,
        textSub
      ),
      treatmentReason:
        coverage.ydelsesType !== 'Beloeb'
          ? this.contentUtilService.getContent(text.treatmentReason)
          : undefined,
      payment:
        coverage.ydelsesType === 'Beloeb' ? coverage.ydelseBeloeb : undefined,
      withoutPrice: coverage.maanedligPrisUdenAdministrationBeloeb,
      withPrice: coverage.maanedligPrisBeloeb,
      coverageId: coverage.daekningId
    };
  }

  private getIdKey(id: number, taxCode: Skattekode): string {
    let key = '_' + id;
    if (id >= 601 && id <= 607) {
      key = key + '_' + taxCode;
    }
    return key;
  }

  getInsurance(coverages: DaekningPrisInterface[] | undefined): InsuranceRow[] {
    const insurances = [];

    if (coverages) {
      coverages.forEach(coverage => {
        const text =
          this.insuranceTexts[
            this.getIdKey(coverage.daekningId, coverage.skattekode)
          ];
        if (text) {
          insurances.push(this.getInsuranceRow(text, coverage));
        }
      });
    }

    return insurances;
  }
}
