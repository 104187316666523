<h3 coContent="DL.FG01.C1399"></h3>
<p class="text--manchet" coContent="DL.FG01.C1968"></p>

<mitpfa-financial-situation-salary
  [financialSituation]="financialSituation"
  [pensionCustomer]="pensionCustomer"
  [form]="financialSituationForm"
  [isInInsuranceGuide]="true"
></mitpfa-financial-situation-salary>

<mitpfa-financial-situation-savings
  [financialSituation]="financialSituation"
  [form]="financialSituationForm"
  [shareOfWealth]="false"
  [annualSavings]="false"
  [isInInsuranceGuide]="true"
></mitpfa-financial-situation-savings>

<mitpfa-financial-situation-real-estate
  [financialSituation]="financialSituation"
  [pensionCustomer]="pensionCustomer"
  [form]="financialSituationForm"
  [withAdvisorMenu]="false"
  [withOlderCustomerSection]="false"
  [isInInsuranceGuide]="true"
></mitpfa-financial-situation-real-estate>

<mitpfa-financial-situation-debt
  [financialSituation]="financialSituation"
  [pensionCustomer]="pensionCustomer"
  [form]="financialSituationForm"
  [withOlderCustomerSection]="false"
  [isInInsuranceGuide]="true"
></mitpfa-financial-situation-debt>

<co-warning *ngIf="updateError" class="spacing-unit--over">
  <span coContent="Global.fejlsenere"></span>
</co-warning>
<co-warning *ngIf="financialSituationForm.invalid" class="spacing-unit--over">
  <span coContent="DL.MD01.C206"></span>
</co-warning>

<div class="spacing-unit--double-over align--center">
  <button
    class="button-secondary"
    mat-raised-button
    (click)="goNext()"
    [disabled]="!financialSituationForm.valid"
    coContent="Global.GemFortsaet"
    data-test-id="insurance-guide-financial-situation-btn"
  ></button>

  <div class="spacing-unit--half-over">
    <mitpfa-insurance-guide-back-button
      (back)="goBack()"
    ></mitpfa-insurance-guide-back-button>
  </div>
</div>
