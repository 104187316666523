/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const TypeSchema = JoiObj.string().valid(
        'Gennemsnit'
        ,
        'Marked'
        ,
        'MarkedGennemsnit'
        ,
        'Forsikring'
        
    )
.id('TypeSchema')

export type Type = 'Gennemsnit' | 'Marked' | 'MarkedGennemsnit' | 'Forsikring';

export const Type = {
    Gennemsnit: 'Gennemsnit' as Type,
    Marked: 'Marked' as Type,
    MarkedGennemsnit: 'MarkedGennemsnit' as Type,
    Forsikring: 'Forsikring' as Type
};

