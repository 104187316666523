import { UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { catchError, map, switchMap } from 'rxjs/operators';
import { UserAppProfileService } from '../services/user-app-profile.service';
import { BrugerStore } from '@pfa/gen';

@Injectable()
export class LightGuard {
  constructor(
    private readonly brugerStore: BrugerStore,
    private readonly userAppProfileService: UserAppProfileService
  ) {}

  canActivate(): Observable<boolean | UrlTree> {
    return this.brugerStore.brugerGet().pipe(
      switchMap(user => {
        // This is protection against race condition between guards.
        // CustomerGuard is not always blocking this guard from being trigerred.
        if (!user.isPensionskundeIkkeValgt) {
          return this.getProfileDirection();
        } else {
          return of(true);
        }
      }),
      catchError(() => of(true))
    );
  }

  private getProfileDirection() {
    return this.userAppProfileService.isLightProfile().pipe(
      map(isLightProfile => {
        if (isLightProfile) {
          window.location.href = '/light';
          return false;
        }
        return !isLightProfile;
      })
    );
  }
}
