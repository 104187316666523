import { Component, Input } from '@angular/core';
import {
  MaeglerInfo,
  OpsparetVaerdi,
  PensionsKundeGenerelleData
} from '@pfa/gen';
import { InvestmentGuideInfo } from '@pfa/models';
import { InvestmentGuideQuestion } from '../investment-guide-configuration.service';
import { InvestmentGuideService } from '@mitpfa/shared';
import { CurrentQuestionSubjectValue } from '../investment-guide.component';
import { Observable } from 'rxjs';

@Component({
  selector: 'mitpfa-investment-guide-economy-step',
  templateUrl: './investment-guide-economy-step.component.html'
})
export class InvestmentGuideEconomyStepComponent {
  @Input() investmentGuide: InvestmentGuideInfo;
  @Input() broker: MaeglerInfo;
  @Input() pensionInfoSavedValues: OpsparetVaerdi;
  @Input() pensionCustomer: PensionsKundeGenerelleData;

  public question = InvestmentGuideQuestion;
  public currentQuestion$: Observable<CurrentQuestionSubjectValue>;

  constructor(private readonly investmentGuideService: InvestmentGuideService) {
    this.currentQuestion$ = investmentGuideService.getCurrentQuestion$();
  }
}
