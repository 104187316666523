import { Component, OnInit } from '@angular/core';
import {
  MaeglerInfo,
  PensionsKundeGenerelleData,
  PensionskundeStore
} from '@pfa/gen';

@Component({
  selector: 'mitpfa-contact-details',
  templateUrl: './contact-details.component.html',
  styleUrls: ['./contact-details.component.scss']
})
export class ContactDetailsComponent implements OnInit {
  pensionCustomer: PensionsKundeGenerelleData;

  constructor(private readonly pensionskundeStore: PensionskundeStore) {}

  brokerInfo: MaeglerInfo;
  phoneLink: string;

  showStandard = true;
  showBroker: boolean;

  ngOnInit(): void {
    this.pensionskundeStore
      .pensionskundeGet()
      .subscribe((pensionCustomer: PensionsKundeGenerelleData) => {
        this.pensionCustomer = pensionCustomer;
      });

    this.pensionskundeStore
      .pensionskundeMaeglerinfoGet()
      .subscribe((brokerInfo: MaeglerInfo) => {
        this.brokerInfo = brokerInfo;
        this.initContactDetails();
      });
  }

  private initContactDetails() {
    const phoneNumber = this.brokerInfo.telefon;
    const danishCountryCode = '+45';
    if (phoneNumber) {
      this.phoneLink = phoneNumber.replace(/\s/g, '');
      if (this.phoneLink.indexOf(danishCountryCode) < 0) {
        this.phoneLink = danishCountryCode.concat(this.phoneLink);
      }
    }
  }
}
