<div class="wrapper">
  <div class="text--container">
    <mitpfa-traffic-light-red
      class="traffic-light"
      *ngIf="trafficlight === 1"
    ></mitpfa-traffic-light-red>
    <mitpfa-svg-traffic-light-yellow
      class="traffic-light"
      *ngIf="trafficlight === 2"
    ></mitpfa-svg-traffic-light-yellow>
    <mitpfa-svg-traffic-light-green
      class="traffic-light green"
      *ngIf="trafficlight === 3"
    ></mitpfa-svg-traffic-light-green>
    <ng-container *ngIf="showText">
      <h1
        class="spacing-unit--one--half-under align--center"
        [coContent]="resumeFlow ? 'DL.VK01.C11' : 'DL.VK01.C07'"
        [coContentSubst]="firstname"
      ></h1>
      <div class="spacing-unit--one--half-under align--center">
        <span
          class="text--manchet"
          [coContent]="resumeFlow ? 'DL.VK01.C12' : 'DL.VK01.C08'"
        ></span>
        <span>&nbsp;</span>
        <span
          *ngIf="!resumeFlow"
          class="text--manchet focus--text text--bold"
          [coContent]="'DL.VK01.C09'"
        >
        </span>
      </div>
      <div class="align--center">
        <button
          mat-raised-button
          class="mat-focus-indicator button-secondary mat-raised-button mat-button-bas"
          (click)="begin()"
          [coContent]="'DL.VK01.C10'"
          data-test-id="startActivateYourRecommendationFlow"
        ></button>
      </div>
      <co-alert class="alert-warning spacing-unit--over" *ngIf="isUpdateError">
        <p [coContent]="'DL.VK01.C44'"></p>
      </co-alert>
    </ng-container>
  </div>
  <mitpfa-svg-city class="city"></mitpfa-svg-city>
</div>
