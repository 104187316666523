import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { DepositsApiService } from '@pfa/api';
import { IndbetalingOversigt } from '@pfa/gen';

@Injectable({
  providedIn: 'root'
})
export class DepositResolver implements Resolve<IndbetalingOversigt> {
  constructor(private readonly depositsApiService: DepositsApiService) {}

  resolve():
    | IndbetalingOversigt
    | Observable<IndbetalingOversigt>
    | Promise<IndbetalingOversigt> {
    return this.depositsApiService.getDeposits();
  }
}
