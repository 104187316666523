import { Injectable } from '@angular/core';
import {
  CombineSavingsSummary,
  CombineSavingsSummaryType,
  CombineSavingsSummaryTypeArgs
} from '@mitpfa/shared/combine-savings/combine-savings.model';

@Injectable()
export class CombineSavingsSummaryService {
  public getCombineSavingsSummary(
    args: CombineSavingsSummaryTypeArgs
  ): CombineSavingsSummary {
    return {
      combineSavingsResult: this.getCombineSavingsSummaryType(args),
      transferredPoliciesUids: args.transferredPoliciesUids
    };
  }

  private getCombineSavingsSummaryType({
    overallCategory,
    transferredPoliciesUids,
    showErrorOnSummary,
    declineCreateLead
  }): CombineSavingsSummaryType {
    if (showErrorOnSummary) {
      return CombineSavingsSummaryType.errorOccurred;
    }
    if (declineCreateLead) {
      return CombineSavingsSummaryType.policiesNotCombined;
    }
    if (
      overallCategory === 'TRANSFERABLE_BY_CUSTOMER' &&
      transferredPoliciesUids?.length > 0
    ) {
      return CombineSavingsSummaryType.policiesCombined;
    }
    if (
      overallCategory === 'TRANSFERABLE_BY_CUSTOMER' &&
      transferredPoliciesUids?.length === 0
    ) {
      return CombineSavingsSummaryType.policiesNotCombined;
    }
    if (
      overallCategory === 'TRANSFERABLE_BY_ADVISOR' &&
      transferredPoliciesUids?.length > 0
    ) {
      return CombineSavingsSummaryType.policiesCombined;
    }
    if (
      overallCategory === 'TRANSFERABLE_BY_ADVISOR' &&
      transferredPoliciesUids?.length === 0
    ) {
      return CombineSavingsSummaryType.policiesTransferableByAdvisor;
    }
    if (overallCategory === 'NOT_TRANSFERABLE') {
      return CombineSavingsSummaryType.policiesNotTransferable;
    }
    return CombineSavingsSummaryType.policiesNotTransferable;
  }
}
