import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { SessionTimeoutExtenderService } from './session-timeout-extender.service';

@Component({
  selector: 'co-session-timeout-modal',
  templateUrl: './session-timeout-modal.component.html'
})
export class SessionTimeoutModalComponent {
  constructor(
    public popup: MatDialogRef<SessionTimeoutModalComponent>,
    public timeoutExtenderService: SessionTimeoutExtenderService
  ) {}

  extendTimeout() {
    this.timeoutExtenderService.extendTimeout();
    this.popup.close();
  }
}
