import { Component, OnInit } from '@angular/core';
import { Article, ArticlesService } from '../articles.service';

@Component({
  selector: 'co-article-modal',
  templateUrl: './article-modal.component.html'
})
export class ArticleModalComponent implements OnInit {
  public selected: string;
  public article: Article | undefined;

  constructor(private readonly articlesService: ArticlesService) {}

  ngOnInit() {
    this.article = this.articlesService.getArticle(this.selected);
  }
}
