import {
  ChangeDetectorRef,
  Component,
  DestroyRef,
  EventEmitter,
  HostListener,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { Router } from '@angular/router';
import { DepositsApiService, PensionPlanApiService } from '@pfa/api';
import {
  GlobalWorkingService,
  NotificationService,
  NotificationType,
  PdfPopupService
} from '@pfaform';
import {
  ApiService,
  ExternaltransferStore,
  Indbetaling,
  LoginMethod,
  PensionsKundeGenerelleData,
  PensionskundeStore
} from '@pfa/gen';
import { forkJoin, of, Subject, Subscription, timer } from 'rxjs';
import { switchMap, takeWhile } from 'rxjs/operators';
import { globalCacheBusterNotifier } from 'ts-cacheable';
import {
  ContentService,
  PensionInfoPlacement,
  PensionInfoTrackingService
} from '@pfa/core';
import { MitIDConst } from '../../app/logon/logon.model';
import { PensionsinfoUtilService, pensionInfoUrl } from '@pfa/services';
import { PensionInfoService } from './pension-info.service';
import { HttpErrorResponse } from '@angular/common/http';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'mitpfa-pension-info',
  templateUrl: './pension-info.component.html',
  styleUrls: ['./pension-info.component.scss']
})
export class PensionInfoComponent implements OnInit, OnDestroy {
  @Input() pensionCustomer: PensionsKundeGenerelleData;
  @Input() isSeniorAdvisory: boolean;
  @Input() slim: boolean;
  @Input() mitIDReauthenticateMethod: 'Redirect' | 'OpenLoginWindow';
  @Input() redirectStepName: string;
  @Input() placement: PensionInfoPlacement;
  @Input() isOnboarding: boolean;

  @Output() updatePI: EventEmitter<void> = new EventEmitter();
  @Output() updatePIError: EventEmitter<void> = new EventEmitter<void>();
  @Output() openedPopup: EventEmitter<void> = new EventEmitter();

  public piClosed: boolean;
  public isPartnerFlow: boolean;
  public isMitIDLogin: boolean;
  public fetchError: boolean;
  public hasExternalTransfer: boolean;
  public piLoaded: boolean;
  public showSaveIncludeExternalPoliciesSpinner: boolean;
  public isShowingPIQuestionsDialog: boolean;
  private readonly age: number = 50;
  private timerSubscription: Subscription;
  private loginWindow: Window;

  private readonly pensionPlanApiService: PensionPlanApiService = inject(
    PensionPlanApiService
  );
  private readonly pensionskundeStore: PensionskundeStore =
    inject(PensionskundeStore);
  private readonly apiService: ApiService = inject(ApiService);
  private readonly pdfPopupService: PdfPopupService = inject(PdfPopupService);
  private readonly notificationService: NotificationService =
    inject(NotificationService);
  private readonly contentService: ContentService = inject(ContentService);
  private readonly depositsApiService: DepositsApiService =
    inject(DepositsApiService);
  private readonly cdr: ChangeDetectorRef = inject(ChangeDetectorRef);
  private readonly router: Router = inject(Router);
  private readonly pensionInfoTrackingService: PensionInfoTrackingService =
    inject(PensionInfoTrackingService);
  private readonly externaltransferStore: ExternaltransferStore = inject(
    ExternaltransferStore
  );
  private readonly pensionInfoService: PensionInfoService =
    inject(PensionInfoService);
  private readonly pensionsinfoUtilService: PensionsinfoUtilService = inject(
    PensionsinfoUtilService
  );
  private readonly globalWorkingService: GlobalWorkingService =
    inject(GlobalWorkingService);
  private readonly destroyRef: DestroyRef = inject(DestroyRef);

  ngOnInit() {
    this.piClosed = false;
    this.fetchError = false;
    this.isMitIDLogin = this.pensionCustomer.loginMethod === LoginMethod.MitId;
    this.checkPIFlow(this.pensionCustomer);

    this.isShowingPIQuestionsDialog = this.evalIsShowingPIQuestionsDialog();
  }

  ngOnDestroy() {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
  }

  checkPIFlow(user: PensionsKundeGenerelleData) {
    this.isPartnerFlow =
      (user.alder >= this.age && user.serviceProgram === 'Formuekunde') ||
      this.isSeniorAdvisory;

    if (user.harPensionsinfo && !user.pensionsinfoLukket) {
      this.depositsApiService
        .getDeposits()
        .pipe(
          switchMap(data => {
            const agreementIds = data.senesteIndbetalinger.map(
              deposit => deposit.policeId
            );

            const policies = user.pensionsinfoDato
              ? agreementIds.map(id =>
                  this.depositsApiService.getDepositsPolicyFromDate(
                    id,
                    user.pensionsinfoDato ?? ''
                  )
                )
              : [];

            return policies.length ? forkJoin([...policies]) : of([]);
          }),
          takeUntilDestroyed(this.destroyRef)
        )
        .subscribe({
          next: responses => {
            const payments = responses
              .map(response => response.indbetalinger as Indbetaling[])
              .reduce((prev, current) => prev.concat(current));

            const transfers = payments.filter(
              payment => payment.eksternoverfoersel
            );

            this.hasExternalTransfer = !!transfers.length;
            this.piLoaded = true;
            // The component is sometimes used within parent with onpush change detection strategy
            // so it's necessary to manulay trigger change detection for this case
            this.cdr.detectChanges();
          },
          error: () => {
            this.piLoaded = true;
            // The component is sometimes used within parent with onpush change detection strategy
            // so it's necessary to manulay trigger change detection for this case
            this.cdr.detectChanges();
          }
        });
    } else {
      this.piLoaded = true;
    }
  }

  @HostListener('window:message', ['$event'])
  receiveMessage(event) {
    const key = event.message ? 'message' : 'data';
    const data = event[key];

    // MitID window
    if (data.action === MitIDConst.RefreshPensionsInfoPageAction) {
      this.pensionskundeStore.invalidatepensionskundeIndex();
      this.pensionskundeStore
        .pensionskundeGet()
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(customer => {
          this.isMitIDLogin = customer.loginMethod === LoginMethod.MitId;
          this.globalWorkingService.hide();
        });

      // send conformation to login window that pensionsinfo page has been refreshed and login window can be closed
      this.loginWindow.postMessage(
        {
          action: MitIDConst.CloseReAuthWindowAction
        },
        '*'
      );

      return;
    }

    // pensionsinfo window
    if (!this.pensionsinfoUtilService.isAllowedPensionsinfoURL(event.origin)) {
      return;
    }

    if (data.action === 'Close' || data.action === 'ClosePopUp') {
      this.pollyUpdateTimer();
    }
  }

  /**
   * The solution with Subject and two setTimeout() calls is workaround for iOS issue:
   * iOS prevents opening new window (window.open()) from async code so such code:
   *
   * this.pensionInfoService().subscribe(link => this.openPensionInfoWithMitIdSso(link));
   *
   * works on desktop but does not work on iPhone.
   *
   * It is insane but routing through Subject and two setTimeout() calls somehow helps.
   *
   * Previous version, when PensioninfoStore.pensioninfoTicketGet() was used, also worked
   * on iPhone so I suppose the boilerplate related to genarated '*Store' classes helps as well.
   */
  openPensionInfo() {
    const linkSubject: Subject<string> = new Subject();
    linkSubject.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(link => {
      setTimeout(() => this.openPensionInfoWithMitIdSso(link));
    });

    this.pensionInfoService
      .getRedirectLink(this.pensionCustomer, this.isPartnerFlow)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: link => {
          setTimeout(() => linkSubject.next(link));
        },
        error: err => {
          if (
            err instanceof HttpErrorResponse &&
            err.status === 401 &&
            this.isMitIDLogin
          ) {
            throw new Error(
              'PensionInfoService.getRedirectLink() returned code 401 when logged with MitID. Seems like you are logged with MitID STUB whilst PensionInfoService.getRedirectLink() requires real MitID.'
            );
          }
          throw err;
        }
      });
  }

  private openPensionInfoWithMitIdSso(link: string) {
    if (this.contentService.isContentEnglish()) {
      link += '&lang=en';
    }

    const width = 600;
    const height = 500;
    const left = screen.width / 2 - width / 2;
    const top = screen.height / 2 - height / 2;
    const pinfoWindow = window.open(
      link,
      'pinfo',
      `width=${width}, height=${height}, top=${top}, left=${left}`
    );
    //this.piWindow could be undefined if the pop up was blocked
    if (pinfoWindow) {
      pinfoWindow.focus();
    }
    if (this.openedPopup) {
      this.pensionInfoTrackingService.trackInitiated(this.placement);
      this.openedPopup.emit();
    }
  }

  goToMitID() {
    this.redirectToMitIdOrOpenInNewWindow();
  }

  openDocument(partner?) {
    if (!partner) {
      this.pdfPopupService.showPdfPopup(
        encodeURIComponent(pensionInfoUrl() + '/hentPDFDokument')
      );
    } else {
      this.pdfPopupService.showPdfPopup(
        encodeURIComponent(pensionInfoUrl() + '/hentPDFPartnerDokument')
      );
    }
  }

  saveIncludeExternalPoliciesStart() {
    this.showSaveIncludeExternalPoliciesSpinner = true;
  }

  saveIncludeExternalPoliciesFinish(saveSuccess: boolean) {
    if (saveSuccess) {
      if (!this.isOnboarding) {
        this.apiService.invalidateAll();
      }
      this.pensionskundeStore
        .pensionskundeGet()
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(pensionCustomer => {
          this.pensionCustomer = pensionCustomer;
          this.showSaveIncludeExternalPoliciesSpinner = false;
          this.isShowingPIQuestionsDialog =
            this.evalIsShowingPIQuestionsDialog();
        });

      this.updatePI.emit();
    } else {
      this.showSaveIncludeExternalPoliciesSpinner = false;
    }
  }

  private pollyUpdateTimer(): void {
    let count = 0;
    const updateTimer = timer(500, 5000);
    this.piClosed = true;

    this.timerSubscription = updateTimer
      .pipe(
        switchMap(() => this.pensionPlanApiService.getPIUpdate()),
        switchMap(() => {
          this.pensionskundeStore.invalidatepensionskundeIndex();
          return this.pensionskundeStore.pensionskundeGet();
        }),
        takeWhile(customer => {
          if (customer.pensionsinfoUpdated) {
            globalCacheBusterNotifier.next();
            this.pensionInfoTrackingService.trackCompleted(this.placement);
            if (!this.isOnboarding) {
              this.apiService.invalidateAll();
            } else {
              this.externaltransferStore.invalidateexternaltransferIndex();
              this.externaltransferStore.invalidateexternaltransferrecommendationShortInfoIndex();
            }
            this.piClosed = false;
            this.fetchError = false;
            this.pensionCustomer = customer;
            this.checkPIFlow(this.pensionCustomer);
            this.notificationService.showNotification({
              message: 'DL.PI01.C14',
              type: NotificationType.SUCCESS
            });
            this.updatePI.emit();
          }

          return !customer.pensionsinfoUpdated;
        })
      )
      .subscribe(() => {
        count++;

        if (count === 20) {
          this.piClosed = false;
          this.fetchError = true;
          this.pensionInfoTrackingService.trackFailed(this.placement);
          this.updatePIError.emit();
          this.timerSubscription.unsubscribe();
        }
      });
  }

  private redirectToMitIdOrOpenInNewWindow() {
    // Since customer is not logged with MitID we know that this.pensionInfoService.getRedirectLink() will
    // fail with code 401 and will return MitID login URL
    this.pensionInfoService
      .getRedirectLink(this.pensionCustomer, this.isPartnerFlow)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: () => {
          // should not happen
        },
        error: error => {
          if (error.status === 401) {
            // handle step-up mitId Authentication
            const mitIdLoginUrl =
              error.error?.mitIdUrl + this.getRedirectParamsString();

            this.loginWindow = {} as Window;
            if (this.mitIDReauthenticateMethod === 'OpenLoginWindow') {
              window.name = 'mitPFAPensionInfo';
              this.loginWindow = window.open(
                mitIdLoginUrl,
                'mitPFAMitIDLoginforPensionInfo'
              );
              if (this.loginWindow) {
                this.loginWindow.focus();
              }
            } else {
              // Redirect or empty
              window.location.href = mitIdLoginUrl;
            }
            this.globalWorkingService.show();
          }
        }
      });
  }

  private getRedirectParamsString(): string {
    const mitIdRedirectParams: string[] = [];
    if (this.contentService.isContentEnglish()) {
      mitIdRedirectParams.push('language=en');
    } else {
      mitIdRedirectParams.push('language=da');
    }

    if (this.mitIDReauthenticateMethod === 'OpenLoginWindow') {
      mitIdRedirectParams.push(
        'return_url=' +
          encodeURIComponent(MitIDConst.CloseTabAndReturnToCallerWindow)
      );
    } else {
      // Redirect or empty
      mitIdRedirectParams.push(
        'return_url=' + encodeURIComponent(this.router.url)
      );
    }

    if (
      this.mitIDReauthenticateMethod === 'Redirect' &&
      this.redirectStepName
    ) {
      mitIdRedirectParams.push(
        'return_url_params=' +
          encodeURIComponent('step=' + this.redirectStepName)
      );
    }

    let mitIdRedirectParamsString = '';
    if (mitIdRedirectParams.length > 0) {
      mitIdRedirectParamsString += '?' + mitIdRedirectParams.join('&');
    }

    return mitIdRedirectParamsString;
  }

  private evalIsShowingPIQuestionsDialog(): boolean {
    return (
      this.pensionCustomer.harPensionsinfo &&
      !this.pensionCustomer.pensionsinfoIncomplete &&
      !this.pensionCustomer.pensionInfoDataInvalid &&
      this.pensionCustomer.pensionInfoCustomerAnswerMissing
    );
  }
}
