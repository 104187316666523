/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const OptimeringsMetodeSchema = JoiObj.string().valid(
        'MESTMULIGTFRASTART'
        ,
        'JUSTERBEHOVTILFORMUE'
        ,
        'DEFAULT_'
        
    )
.id('OptimeringsMetodeSchema')

export type OptimeringsMetode = 'MESTMULIGTFRASTART' | 'JUSTERBEHOVTILFORMUE' | 'DEFAULT_';

export const OptimeringsMetode = {
    Mestmuligtfrastart: 'MESTMULIGTFRASTART' as OptimeringsMetode,
    Justerbehovtilformue: 'JUSTERBEHOVTILFORMUE' as OptimeringsMetode,
    Default: 'DEFAULT_' as OptimeringsMetode
};

