import { Platform } from '@angular/cdk/platform';
import { Injectable } from '@angular/core';
import { DeviceType } from '@pfa/gen';
import { mobileDevice, pcDevice } from '../consts/device.const';

export enum PlatformName {
  CHROME = 'chrome',
  SAFARI = 'safari',
  FIREFOX = 'firefox',
  OPERA = 'opera',
  EDGE = 'edge',
  ANDROID = 'android',
  IOS = 'ios'
}

@Injectable({
  providedIn: 'root'
})
export class DeviceService {
  private readonly userAgent = window.navigator.userAgent;

  constructor(private readonly platform: Platform) {}

  getDeviceType(): DeviceType {
    if (this.isIOS() || this.isAndroid()) {
      return mobileDevice;
    } else {
      return pcDevice;
    }
  }

  isChrome() {
    return (
      this.userAgent.match(/chrome|chromium|crios/i) &&
      !this.userAgent.match(/edg/i)
    );
  }

  isSafari() {
    return this.userAgent.match(/safari/i) && !this.userAgent.match(/edg/i);
  }

  isFirefox() {
    return this.userAgent.match(/firefox|fxios/i);
  }

  isOpera() {
    return this.userAgent.match(/opr\//i);
  }

  isEdge() {
    return this.userAgent.match(/edg/i);
  }

  isIOS() {
    return this.platform.IOS;
  }

  isAndroid() {
    return this.platform.ANDROID;
  }

  isNative(): boolean {
    if (this.isIOS() && this.userAgent.match(/MitPFA-iOS/i)) {
      return true;
    }
    if (this.isAndroid() && this.userAgent.match(/wv/i)) {
      return true;
    }

    return false;
  }
}
