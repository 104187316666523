<co-card-animated-stack [activeIndex]="0">
  <co-card-animated-stack-item>
    <co-card-question
      (agreeClick)="choose(true)"
      (declineClick)="choose(false)"
    >
      <div
        class="question-title spacing-unit--under"
        coContent="DL.FG02.C343"
      ></div>
    </co-card-question>
  </co-card-animated-stack-item>
</co-card-animated-stack>

<div class="align--center spacing-unit--over">
  <mitpfa-insurance-guide-back-button
    (back)="goBack()"
  ></mitpfa-insurance-guide-back-button>
</div>
