import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Afkastoversigt, AkkAfkast, KundekapitalOversigt } from '@pfa/gen';

export interface SavingsReturnCardData {
  link: string;
  onlyInsurances: boolean;
  returns: {
    accumulatedReturns: number;
    showAccumulatedReturns: boolean;
    returnsThisYear: number;
    percentageChangeThisYear: number | null;
    policiesWithError: boolean;
    allPoliciesWithError: boolean;
  };
  customerCapital: {
    enabled: boolean;
    enabledRecently: boolean;
    toBeDistributed: number;
    payoutYear: number;
    policiesWithError: boolean;
    allPoliciesWithError: boolean;
  };
}

@Injectable()
export class DashboardSavingsReturnService {
  private savingsReturnLink = '/mitafkast';

  public parseCustomerCapital(customerCapital: KundekapitalOversigt): {
    enabled: boolean;
    enabledRecently: boolean;
    toBeDistributed: number;
    payoutYear: number;
    policiesWithError: boolean;
    allPoliciesWithError: boolean;
  } {
    const thisYear = moment().year();
    const nextYear = moment().year() + 1;

    const returnLastYear = customerCapital.forrentningSidsteAar || 0;
    const returnEarnedThisYear = customerCapital.optjentIAar || 0;

    return {
      enabled: customerCapital.harKundekapital,
      enabledRecently:
        !!returnLastYear ||
        !!returnEarnedThisYear ||
        customerCapital.harKundekapital,
      toBeDistributed: returnLastYear ? returnLastYear : returnEarnedThisYear,
      payoutYear: returnLastYear ? thisYear : nextYear,
      policiesWithError: customerCapital.fejledeAftaler.length > 0,
      allPoliciesWithError:
        customerCapital.policer.length !== 0 &&
        customerCapital.policer.length === customerCapital.fejledeAftaler.length
    };
  }

  public parseReturns(
    returnsOverview: Afkastoversigt,
    accumulatedReturns: AkkAfkast
  ): {
    accumulatedReturns: number;
    returnsThisYear: number;
    percentageChangeThisYear: number | null;
    showAccumulatedReturns: boolean;
    policiesWithError: boolean;
    allPoliciesWithError: boolean;
  } {
    return {
      accumulatedReturns: accumulatedReturns.akkumuleretAfkast,
      percentageChangeThisYear: this.extractChangePercentage(returnsOverview),
      returnsThisYear: returnsOverview.totalAfkast,
      showAccumulatedReturns: accumulatedReturns.vises,
      policiesWithError: returnsOverview.policer.some(p => p.fejlet),
      allPoliciesWithError:
        returnsOverview.policer.length !== 0 &&
        returnsOverview.policer.length ===
          returnsOverview.policer.filter(p => p.fejlet).length
    };
  }

  public buildLink(returnsOverview: Afkastoversigt): string {
    return !!returnsOverview.policer &&
      returnsOverview.policer.length === 1 &&
      !returnsOverview.kunForsikringer
      ? `${this.savingsReturnLink}/${returnsOverview.policer[0].id}`
      : this.savingsReturnLink;
  }

  private extractChangePercentage(
    returnsOverview: Afkastoversigt
  ): number | null {
    if (returnsOverview.policer.length !== 1) {
      return null;
    }

    return returnsOverview.policer[0].livnetAftale
      ? returnsOverview.policer[0].afkastProcent
      : null;
  }
}
