<div class="row">
  <div class="col-md-8 offset-md-2">
    <div class="description spacing-unit--double-under">
      <h3 class="align--center" coContent="DL.FG01.C1874"></h3>
    </div>
  </div>
  <div
    class="col-md-2 spacing-unit--double-under d-flex justify-content-end pl-2"
  ></div>
</div>

<div class="row choice-row">
  <div class="col-md-4" *ngFor="let choice of choices">
    <co-card>
      <div class="choice-content">
        <div class="choice-title" [coContent]="choice.header"></div>
        <div
          class="description spacing-unit--under spacing-unit--over text--info"
          [coContent]="choice.description"
        ></div>
        <button
          class="button-secondary button-choice"
          mat-raised-button
          coContent="DL.INVGU01.C418"
          (click)="choose(choice)"
          data-test-id="insurance-guide-risk-step-btn"
        ></button>

        <div class="previously-selected align--center text--note text--bold">
          {{
            choice.previouslySelected ? ('DL.FG01.C1873' | content) : '&nbsp;'
          }}
        </div>
      </div>
    </co-card>
  </div>
</div>

<co-carousel slidePercentageWidth="80" slideScale="true">
  <ng-container *ngFor="let choice of choices">
    <ng-container *pfaCarouselItem>
      <co-card>
        <div class="choice-content">
          <div class="choice-title" [coContent]="choice.header"></div>
          <div
            class="description spacing-unit--under spacing-unit--over text--info"
            [coContent]="choice.description"
          ></div>
          <button
            class="button-secondary button-choice"
            mat-raised-button
            coContent="DL.INVGU01.C418"
            (click)="choose(choice)"
          ></button>

          <div class="previously-selected align--center text--note text--bold">
            {{
              choice.previouslySelected ? ('DL.FG01.C1873' | content) : '&nbsp;'
            }}
          </div>
        </div>
      </co-card>
      <ng-template *ngTemplateOutlet="cardChoice"></ng-template>
    </ng-container>
  </ng-container>
</co-carousel>

<div class="align--center spacing-unit--double-over">
  <mitpfa-insurance-guide-back-button
    (back)="goToPreviousPage()"
  ></mitpfa-insurance-guide-back-button>
</div>
