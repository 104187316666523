/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CustomerStatus } from './customerStatus';
import { CustomerStatusSchema } from './customerStatus';
import { SalesOrganization } from './salesOrganization';
import { SalesOrganizationSchema } from './salesOrganization';
import * as JoiObj from "joi";


    export const CustomerRelationsSchema = JoiObj.object({
        customerStatus:
            
        JoiObj.link('#CustomerStatusSchema')


.required()


        ,
        salesOrganizations:
                JoiObj.array().items(
        
        JoiObj.link('#SalesOrganizationSchema')

        )
        

.required()


        
    })
                .shared(CustomerStatusSchema)
                .shared(SalesOrganizationSchema)
.id('CustomerRelationsSchema')

export interface CustomerRelations { 
    customerStatus: CustomerStatus;
    salesOrganizations: Array<SalesOrganization>;
}
export namespace CustomerRelations {
}


