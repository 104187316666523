/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Ks } from './ks';
import { KsSchema } from './ks';
import { Tae } from './tae';
import { TaeSchema } from './tae';
import { Hs } from './hs';
import { HsSchema } from './hs';
import * as JoiObj from "joi";


    export const GuiSchema = JoiObj.object({
        activeCategoryId:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        activeGroupId:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        hs:
            
        JoiObj.link('#HsSchema')

.allow(null)



        ,
        ks:
            
        JoiObj.link('#KsSchema')

.allow(null)



        ,
        tae:
            
        JoiObj.link('#TaeSchema')

.allow(null)



        
    })
                .shared(HsSchema)
                .shared(KsSchema)
                .shared(TaeSchema)
.id('GuiSchema')

export interface Gui { 
    activeCategoryId?: string;
    activeGroupId?: string;
    hs?: Hs;
    ks?: Ks;
    tae?: Tae;
}

