/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AftaleType } from './aftaleType';
import { AftaleTypeSchema } from './aftaleType';
import { OpsparingFormFordeling } from './opsparingFormFordeling';
import { OpsparingFormFordelingSchema } from './opsparingFormFordeling';
import { Tilstand } from './tilstand';
import { TilstandSchema } from './tilstand';
import { PoliceType } from './policeType';
import { PoliceTypeSchema } from './policeType';
import * as JoiObj from "joi";


    export const QuotationPolicySchema = JoiObj.object({
        aftaleType:
            
        JoiObj.link('#AftaleTypeSchema')


.required()


        ,
        defaultPolicy:
            
                    JoiObj.boolean()
                


.required()


        ,
        hentPoliceDetaljerFailed:
            
                    JoiObj.boolean()
                


.required()


        ,
        minimumDepositAmount:
            
                    JoiObj.number()
                


.required()


        ,
        opsparingFormFordeling:
            
        JoiObj.link('#OpsparingFormFordelingSchema')


.required()


        ,
        policeType:
            
        JoiObj.link('#PoliceTypeSchema')


.required()


        ,
        policyId:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        tilstand:
            
        JoiObj.link('#TilstandSchema')


.required()


        ,
        typeTekstId:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        
    })
                .shared(AftaleTypeSchema)
                .shared(OpsparingFormFordelingSchema)
                .shared(PoliceTypeSchema)
                .shared(TilstandSchema)
.id('QuotationPolicySchema')

export interface QuotationPolicy { 
    aftaleType: AftaleType;
    defaultPolicy: boolean;
    hentPoliceDetaljerFailed: boolean;
    minimumDepositAmount: number;
    opsparingFormFordeling: OpsparingFormFordeling;
    policeType: PoliceType;
    policyId: string;
    tilstand: Tilstand;
    typeTekstId: string;
}
export namespace QuotationPolicy {
}


