<form *ngIf="partnerInfoForm" [formGroup]="partnerInfoForm">
  <ng-container *ngIf="!hasSpouse">
    <div class="row spacing-section--over">
      <div class="col-sm-4">
        <p class="text--manchet" [coContent]="'DL.MD01.C329'"></p>
        <p [coContent]="'DL.MD01.C332'"></p>
      </div>
      <div class="col-sm">
        <mat-radio-group [formControlName]="'hasPartner'">
          <mat-radio-button [value]="true">
            {{ 'DL.MD01.C330' | content }}
          </mat-radio-button>
          <mat-radio-button [value]="false">
            {{ 'DL.MD01.C331' | content }}
          </mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
  </ng-container>
  <co-card *ngIf="hasSpouse || partnerInfoForm.controls['hasPartner']?.value">
    <ng-container *ngIf="!hasSpouse">
      <div class="row" *ngIf="partnerInfoForm.controls['hasPartner']?.value">
        <div class="col-sm-6">
          <p [coContent]="'DL.MD01.C333'" class="text--manchet"></p>
          <p [coContent]="'DL.MD01.C334'" class="text--info"></p>
        </div>
        <div class="col-sm-3 offset-sm-1">
          <mat-radio-group [formControlName]="'hasPartner2Years'">
            <mat-radio-button [value]="true">
              {{ 'DL.MD01.C330' | content }}
            </mat-radio-button>
            <mat-radio-button [value]="false">
              {{ 'DL.MD01.C331' | content }}
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
    </ng-container>
    <div
      class="row"
      [ngClass]="{
        'spacing-unit--double-over':
          partnerInfoForm.controls['hasPartner']?.value
      }"
    >
      <div class="col-sm-6">
        <p [coContent]="'DL.MD01.C335'" class="text--manchet"></p>
        <p [coContent]="'DL.MD01.C336'" class="text--info"></p>
      </div>
      <div class="col-sm-3 offset-sm-1">
        <mat-form-field color="accent">
          <mat-label>{{ 'DL.MD01.C337' | content }}</mat-label>
          <input
            [required]="true"
            matInput
            [formControlName]="'partnerName'"
            [placeholder]="'DL.MD01.C337' | content"
            data-test-id="your-family-partner-name"
          />
          <mat-error
            *ngIf="partnerInfoForm.controls['partnerName'].errors?.required"
            [coContent]="'DL.MD01.C12'"
          ></mat-error>
          <mat-error
            *ngIf="partnerInfoForm.controls['partnerName'].errors?.pattern"
            [coContent]="'DL.MD01.C121'"
          ></mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row spacing-unit--double-over">
      <div class="col-sm-6">
        <p [coContent]="'DL.MD01.C361'" class="text--manchet"></p>
        <p [coContent]="'DL.MD01.C362'" class="text--info"></p>
      </div>
      <div class="col-sm-3 offset-sm-1">
        <div class="datepicker-wrap">
          <mat-form-field color="accent">
            <mat-label>{{ 'DL.MD01.C139' | content }}</mat-label>
            <input
              matInput
              [min]="minDate"
              [max]="currentDate"
              [required]="true"
              [matDatepicker]="partnerBirthdayPicker"
              [formControlName]="'partnerBirthday'"
              data-test-id="your-family-partner-birthday"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="partnerBirthdayPicker"
            ></mat-datepicker-toggle>
            <mat-datepicker #partnerBirthdayPicker></mat-datepicker>
            <mat-error
              *ngIf="partnerInfoForm.controls['partnerBirthday']?.invalid"
              [coContent]="'DL.MD01.C18'"
            ></mat-error>
          </mat-form-field>
          <div
            class="datepicker-overlay"
            (click)="partnerBirthdayPicker.open()"
            (mouseover)="datepickerHover(true)"
            (mouseout)="datepickerHover(false)"
          ></div>
        </div>
      </div>
    </div>
    <div class="row spacing-unit--double-over">
      <div class="col-sm-6">
        <p [coContent]="'DL.MD01.C338'" class="text--manchet"></p>
        <p [coContent]="'DL.MD01.C339'" class="text-info"></p>
      </div>
      <div class="col-sm-3 offset-sm-1">
        <mat-form-field color="accent">
          <mat-label>{{ 'DL.MD01.C340' | content }}</mat-label>
          <input
            [required]="true"
            class="align--right"
            matInput
            coAmount
            [formControlName]="'partnerSalary'"
            [placeholder]="'DL.MD01.C340' | content"
            data-test-id="your-family-partner-salary"
          />
          <mat-error
            *ngIf="partnerInfoForm.controls['partnerSalary'].errors?.required"
            [coContent]="'DL.MD01.C12'"
          ></mat-error>
          <mat-error
            *ngIf="
              partnerInfoForm.controls['partnerSalary'].invalid &&
              !partnerInfoForm.controls['partnerSalary'].errors?.required
            "
            [coContent]="'DL.MD01.C121'"
          ></mat-error>
        </mat-form-field>
      </div>
    </div>
  </co-card>
</form>
