import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  Input,
  OnInit
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import {
  FamilyInformationApiService,
  FinancialSituationApiService
} from '@pfa/api';
import {
  InsuranceGuideQuestion,
  InsuranceGuideQuestionValue
} from '../../insurance-guide-configuration.service';
import {
  InsuranceUtilService,
  NotificationService,
  NotificationType,
  GlobalWorkingService
} from '@pfaform';
import { BehaviorSubject, forkJoin } from 'rxjs';
import { FinancialSituationConfigurationService } from '@mitpfa/shared';
import {
  ForsikringDetaljer,
  OekonomiDetaljer,
  StamdataDetaljer
} from '@pfa/gen';
import { GuideStepType, GuideTrackingService } from '@pfa/core';
import { InsuranceGuideService } from '../../insurance-guide.service';

@Component({
  selector: 'mitpfa-insurance-guide-debt-step',
  templateUrl: './insurance-guide-debt-step.component.html',
  styleUrls: ['./insurance-guide-debt-step.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InsuranceGuideDebtStepComponent implements OnInit {
  @Input() financialSituation: OekonomiDetaljer;
  @Input() familyInformation: StamdataDetaljer;
  @Input() currentQuestion: BehaviorSubject<InsuranceGuideQuestionValue>;
  @Input() insuranceDetails: ForsikringDetaljer;

  private readonly globalWorkingService = inject(GlobalWorkingService);
  private readonly financialSituationApiService = inject(
    FinancialSituationApiService
  );
  private readonly financialSituationConfigurationService = inject(
    FinancialSituationConfigurationService
  );
  private readonly familyInformationApiService = inject(
    FamilyInformationApiService
  );
  private readonly cdRef = inject(ChangeDetectorRef);
  private readonly insuranceUtilService = inject(InsuranceUtilService);
  private readonly guideTrackingService = inject(GuideTrackingService);
  private readonly insuranceGuideService = inject(InsuranceGuideService);
  private readonly notificationService = inject(NotificationService);

  public caseForm: UntypedFormGroup;
  public extraCoverageFormVisible: boolean;
  public multiplier: number;
  public currentDept: number;
  public currentCard: number;
  public hasPartner: boolean;
  public format: string;
  public recommendedSalary: number;

  ngOnInit(): void {
    this.currentCard = 0;
    this.multiplier = this.insuranceUtilService.getRecommendedMultiplier(
      this.insuranceDetails
    );

    this.format = this.multiplier > 0 ? '1.2-2' : '1.0-0';
    this.recommendedSalary =
      this.multiplier *
      this.insuranceUtilService.getSalary(this.financialSituation);
    this.currentDept = this.calcCurrentDept();
    this.hasPartner =
      this.familyInformation.samlevende ||
      this.familyInformation.civilstand === 'Gift';

    this.caseForm = new UntypedFormGroup({
      myExtraCoverage: new UntypedFormControl(
        this.financialSituation.frieMidler.gaeldDaekkes ?? 0,
        [Validators.required, Validators.maxLength(10)]
      )
    });

    if (this.hasPartner) {
      this.caseForm.addControl(
        'partnerExtraCoverage',
        new UntypedFormControl(
          this.familyInformation.partnersGaeldDaekkes ?? 0,
          [Validators.required, Validators.maxLength(10)]
        )
      );
    }
    this.globalWorkingService.hide();
  }

  private calcCurrentDept() {
    let dept = 0;

    if (this.financialSituation && this.financialSituation.frieMidler) {
      if (
        this.financialSituation.frieMidler.boligValgt &&
        this.financialSituation.frieMidler.boliger.length > 0
      ) {
        this.financialSituation.frieMidler.boliger.forEach(housing => {
          dept += housing.gaeldIdag * (housing.ejerAndel / 100);
        });
      }

      if (this.financialSituation.frieMidler.gaeldIBankIdag) {
        dept += this.financialSituation.frieMidler.bankgaeldIdag;
      }
    }

    return dept;
  }

  public previousCardOnStack() {
    if (this.currentCard === 1) {
      this.currentCard--;
    }
  }

  public goBack(): void {
    if (
      this.insuranceGuideService.shouldShowRiskLocQuestion(
        this.financialSituation,
        this.insuranceDetails
      )
    ) {
      this.currentQuestion.next({
        value: InsuranceGuideQuestion.loc,
        back: true
      });
    } else {
      this.currentQuestion.next({
        value: InsuranceGuideQuestion.risk,
        back: true
      });
    }
  }

  public goNext(): void {
    this.currentQuestion.next({
      value: InsuranceGuideQuestion.recommendation
    });
    window.scroll(0, 0);
  }

  public clickedYes(): void {
    this.guideTrackingService.trackGuideStep(
      'insurance guide',
      'ISG extra coverage - selection',
      GuideStepType.OPTIONAL
    );
    this.extraCoverageFormVisible = true;

    if (this.currentCard === 0) {
      this.currentCard++;
    }

    this.cdRef.detectChanges();
    window.scroll(0, 0);
  }

  public clickedNo(): void {
    this.extraCoverageFormVisible = false;
    this.saveChangesAndGoNext();
  }

  public saveChangesAndGoNext(): void {
    this.globalWorkingService.show();

    const financialSituation =
      this.financialSituationConfigurationService.getMappedFormsToUpdateFormat(
        this.caseForm,
        this.financialSituation
      );

    if (!this.currentCard) {
      financialSituation.frieMidler.gaeldSikres = false;
      this.financialSituation.frieMidler.gaeldSikres = false;
      financialSituation.frieMidler.gaeldDaekkes = 0;
      this.financialSituation.frieMidler.gaeldDaekkes = 0;
      this.familyInformation.partnersGaeldSikres = false;
      this.familyInformation.partnersGaeldDaekkes = 0;
    } else {
      this.familyInformation.partnersGaeldSikres = false;

      if (this.hasPartner) {
        this.familyInformation.partnersGaeldDaekkes = parseInt(
          this.caseForm.controls['partnerExtraCoverage'].value,
          10
        );

        if (this.familyInformation.partnersGaeldDaekkes > 0) {
          this.familyInformation.partnersGaeldSikres = true;
        }
      }
    }

    forkJoin([
      this.familyInformationApiService.updateFamilyInformation(
        this.familyInformation
      ),
      this.financialSituationApiService.updateFinancialSituation(
        financialSituation
      )
    ]).subscribe(() => {
      Object.keys(financialSituation).forEach(
        key => (this.financialSituation[key] = financialSituation[key])
      );
      this.caseForm.markAsPristine();
      this.globalWorkingService.hide();
      this.notificationService.showNotification({
        message: 'DL.FG01.C1970',
        type: NotificationType.SUCCESS
      });
      this.goNext();
    });
  }
}
