<div class="description">
  <h3 class="align--center" coContent="DL.INVGU01.C321"></h3>
  <p class="text--info" coContent="DL.INVGU01.C322"></p>
</div>

<div class="row spacing-section--over">
  <div class="col-md-8 offset-md-2">
    <mitpfa-pension-info
      class="pension-info-box"
      *ngIf="pensionCustomer"
      [pensionCustomer]="pensionCustomer"
      [mitIDReauthenticateMethod]="'OpenLoginWindow'"
      [placement]="'investment guide'"
    >
    </mitpfa-pension-info>
    <div class="description">
      <p class="additional-information" coContent="DL.INVGU01.C517"></p>
    </div>
  </div>
</div>

<div class="align--center spacing-unit--over">
  <button mat-raised-button (click)="goBack()" class="button-primary--level3">
    <co-icon-arrow-left class="icon icon--arrow-left"></co-icon-arrow-left>
    <span [coContent]="'DL.INVGU01.C287'"></span>
  </button>
  <button
    mat-raised-button
    (click)="goToNextPage()"
    class="button-primary--level3"
  >
    <span [coContent]="'DL.INVGU01.C186'"></span>
    <co-icon-arrow-right class="icon icon--arrow-right"></co-icon-arrow-right>
  </button>
</div>
