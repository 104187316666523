<svg
  id="Layer_1"
  data-name="Layer 1"
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 32 32"
>
  <g id="Canvas">
    <circle
      id="Large_circle"
      data-name="Large circle"
      cx="16"
      cy="16"
      r="16"
      fill="none"
    />
    <circle
      id="Small_circle"
      data-name="Small circle"
      cx="16"
      cy="16"
      r="12"
      fill="none"
    />
  </g>
  <g id="Icon">
    <path
      id="typeit"
      d="M9,12.5H21A1.5,1.5,0,0,1,22.5,14v4A1.5,1.5,0,0,1,21,19.5H9A1.5,1.5,0,0,1,7.5,18V14A1.5,1.5,0,0,1,9,12.5Zm9.5,8v-9m-3,12c1.66,0,3-1.8,3-3.46,0,1.66,1.34,3.46,3,3.46m-6-15c1.66,0,3,1.78,3,3.44,0-1.66,1.34-3.44,3-3.44"
      fill="none"
      stroke="#000"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.2"
    />
  </g>
</svg>
