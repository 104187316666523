import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import {RestCacheEntry} from "./RestCacheEntry";
import {GenericStore} from "./GenericStore";
import * as JoiObj from "joi";

import {
        InvesteringKundekapitalPolicyIdIndregnPostRequest,
        InvesteringKundekapitalPolicyIdIndregnPostRequestSchema,
        TilbudSvar,
        TilbudSvarSchema
} from '../model/models';

import { InvesteringService } from './investering.service';


@Injectable({
    providedIn: 'root'
})
export class InvesteringStore extends GenericStore {


    constructor(private investeringService: InvesteringService) {
        super();
    }

    invalidateAll(): void {
                
                
    }




                    public investeringKundekapitalPolicyIdIndregnPost(policyId: string,investeringKundekapitalPolicyIdIndregnPostRequest?: InvesteringKundekapitalPolicyIdIndregnPostRequest) : Observable<TilbudSvar> {
                        return this.investeringService.investeringKundekapitalPolicyIdIndregnPost(policyId,investeringKundekapitalPolicyIdIndregnPostRequest).pipe(
                            map(value => {
                                
                                
                                        this.throwOrHandleValidationError(TilbudSvarSchema, value);
                                return value;
                            })
                        );
                    }




}
