import { Component, Input } from '@angular/core';

@Component({
  selector: 'co-icon-circle-checkmark',
  templateUrl: 'icon-circle-checkmark.component.html',
  styleUrls: ['../icon-base.scss', './icon-circle-checkmark.component.scss']
})
export class IconCircleCheckmarkComponent {
  @Input()
  public fill: 'grey' | 'secondary' | 'secondary40' | 'transparent' | 'white';

  @Input()
  public color: 'black' | 'white' | 'primary';

  @Input()
  public border: boolean;
}
