/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const PolicyTypeSchema = JoiObj.string().valid(
        'NO_SAVINGS'
        ,
        'SAVINGS'
        
    )
.id('PolicyTypeSchema')

export type PolicyType = 'NO_SAVINGS' | 'SAVINGS';

export const PolicyType = {
    NoSavings: 'NO_SAVINGS' as PolicyType,
    Savings: 'SAVINGS' as PolicyType
};

