/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const CorrespondenceImportanceSchema = JoiObj.string().valid(
        'LOW'
        ,
        'HIGH'
        
    )
.id('CorrespondenceImportanceSchema')

export type CorrespondenceImportance = 'LOW' | 'HIGH';

export const CorrespondenceImportance = {
    Low: 'LOW' as CorrespondenceImportance,
    High: 'HIGH' as CorrespondenceImportance
};

