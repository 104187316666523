<co-advisor-box
  *ngIf="otherFinancialConditionsForm"
  [formGroup]="otherFinancialConditionsForm"
  class="spacing-section--over"
>
  <p class="text--manchet text--bold" coContent="DL.FG01.C555"></p>
  <p class="text--bold" coContent="DL.PP01.C94"></p>
  <p class="text--info" coContent="DL.PP01.C127"></p>

  <section>
    <mat-checkbox formControlName="investmentProperties">
      {{ 'DL.PP01.C95' | content }}
    </mat-checkbox>
    <mat-checkbox formControlName="ownBusiness">
      {{ 'DL.PP01.C96' | content }}
    </mat-checkbox>
  </section>
</co-advisor-box>
