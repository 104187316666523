import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PfaClimateProfile } from '../pfa-climate-profile.model';
import { Options } from 'ngx-slider-v2';
import { expansion } from '@pfa/animations';
import { ContentUtilService } from '@pfa/core';

@Component({
  selector: 'mitpfa-climate-percentage-chooser',
  templateUrl: './climate-percentage-chooser.component.html',
  styleUrls: ['./climate-percentage-chooser.component.scss'],
  animations: [expansion]
})
export class ClimatePercentageChooserComponent implements OnInit {
  @Input() pfaClimateProfile: PfaClimateProfile;
  @Output() profileSelected = new EventEmitter<boolean>();
  // Default value if none other value is selected
  public sliderSelectedValue = 75;
  public sliderOptions: Options;
  public showInputPercentage = false;
  public choose: string;

  public constructor(private contentUtilService: ContentUtilService) {}

  ngOnInit() {
    this.sliderOptions = {
      ceil: 100,
      floor: 0
    };

    if (!this.pfaClimateProfile) {
      throw new Error('There must be a pfaClimateProfile');
    }
    if (this.pfaClimateProfile.climatePlusPercentage) {
      this.sliderSelectedValue = this.pfaClimateProfile.climatePlusPercentage;
    }
    this.sliderChangeEnd(this.sliderSelectedValue.toString());
  }

  showHidePercentInput(): void {
    this.showInputPercentage = !this.showInputPercentage;
  }

  closePercentageChooser(selected: boolean) {
    if (selected) {
      this.pfaClimateProfile.climatePlusPercentage = this.sliderSelectedValue;
    }
    this.profileSelected.emit(selected);
  }

  sliderChangeEnd($event: string) {
    this.choose = this.contentUtilService.getContent('DL.INV.C495', $event);
  }
}
