/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AendringerPensionsbevis } from './aendringerPensionsbevis';
import { AendringerPensionsbevisSchema } from './aendringerPensionsbevis';
import { ServiceInfo } from './serviceInfo';
import { ServiceInfoSchema } from './serviceInfo';
import { LinieElement } from './linieElement';
import { LinieElementSchema } from './linieElement';
import { ResourceModel } from './resourceModel';
import { ResourceModelSchema } from './resourceModel';
import { GebyrLinieElement } from './gebyrLinieElement';
import { GebyrLinieElementSchema } from './gebyrLinieElement';
import * as JoiObj from "joi";


    export const BetalingForAdministrationOgServiceSchema = JoiObj.object({
        serviceInfo:
            
        JoiObj.link('#ServiceInfoSchema')

.allow(null)



        ,
        administration:
                JoiObj.array().items(
        
        JoiObj.link('#LinieElementSchema')

        )
        

.required()


        ,
        aendringerPensionsbevis:
            
        JoiObj.link('#AendringerPensionsbevisSchema')


.required()


        ,
        omkostningerTilMaegler:
                JoiObj.array().items(
        
        JoiObj.link('#LinieElementSchema')

        )
        

.required()


        ,
        omkostningerVedInvestering:
                JoiObj.array().items(
        
        JoiObj.link('#LinieElementSchema')

        )
        

.required()


        ,
        prisForRaadgivning:
                JoiObj.array().items(
        
        JoiObj.link('#GebyrLinieElementSchema')

        )
        

.required()


        ,
        total:
            
                    JoiObj.number()
                


.required()


        
    })
                .shared(ServiceInfoSchema)
                .shared(LinieElementSchema)
                .shared(AendringerPensionsbevisSchema)
                .shared(LinieElementSchema)
                .shared(LinieElementSchema)
                .shared(GebyrLinieElementSchema)
.id('BetalingForAdministrationOgServiceSchema')

export interface BetalingForAdministrationOgService extends ResourceModel { 
    administration: Array<LinieElement>;
    aendringerPensionsbevis: AendringerPensionsbevis;
    omkostningerTilMaegler: Array<LinieElement>;
    omkostningerVedInvestering: Array<LinieElement>;
    prisForRaadgivning: Array<GebyrLinieElement>;
    total: number;
}

