import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconProfileComponent } from './icon-profile/icon-profile.component';
import { IconEnvelopeComponent } from './icon-envelope/icon-envelope.component';
import { IconEnvelopeReadComponent } from './icon-envelope-read/icon-envelope-read.component';
import { IconEventlogComponent } from './icon-eventlog/icon-eventlog.component';
import { IconMenuComponent } from './icon-menu/icon-menu.component';
import { IconTimesComponent } from './icon-times/icon-times.component';
import { IconArrowDownComponent } from './icon-arrow-down/icon-arrow-down.component';
import { IconArrowUpComponent } from './icon-arrow-up/icon-arrow-up.component';
import { IconCheckmarkComponent } from './icon-checkmark/icon-checkmark.component';
import { IconInfoComponent } from './icon-info/icon-info.component';
import { IconArrowRightComponent } from './icon-arrow-right/icon-arrow-right.component';
import { IconArrowLeftComponent } from './icon-arrow-left/icon-arrow-left.component';
import { IconExternalLinkComponent } from './icon-external-link/icon-external-link.component';
import { IconMaximizeComponent } from './icon-maximize/icon-maximize.component';
import { IconMinimizeComponent } from './icon-minimize/icon-minimize.component';
import { IconNotificationComponent } from './icon-notification/icon-notification.component';
import { IconLockedComponent } from './icon-locked/icon-locked.component';
import { IconRecommendationComponent } from './icon-recommendation/icon-recommendation.component';
import { IconRefreshCustomerComponent } from './icon-refresh-customer/icon-refresh-customer.component';
import { IconRefreshComponent } from './icon-refresh/icon-refresh.component';
import { IconPaperclipComponent } from './icon-paperclip/icon-paperclip.component';
import { IconDeleteComponent } from './icon-delete/icon-delete.component';
import { IconEditComponent } from './icon-edit/icon-edit.component';
import { IconNewMessageComponent } from './icon-new-message/icon-new-message.component';
import { IconErrorComponent } from './icon-error/icon-error.component';
import { IconPhoneComponent } from './icon-phone/icon-phone.component';
import { IconLogoComponent } from './icon-logo/icon-logo.component';
import { IconProComponent } from './icon-pro/icon-pro.component';
import { IconSystemComponent } from './icon-system/icon-system.component';
import { IconChatComponent } from './icon-chat/icon-chat.component';

import { CoreModule } from '@pfa/core';
import { IconOrdersComponent } from './icon-orders/icon-orders.component';
import { IconCompletedComponent } from './icon-completed/icon-completed.component';
import { IconDocumentComponent } from './icon-document/icon-document.component';
import { IconHeartGlowingComponent } from './icon-heart-glowing/icon-heart-glowing.component';
import { IconTypeItComponent } from './icon-type-it/icon-type-it.component';
import { IconCompassComponent } from './icon-compass/icon-compass.component';
import { IconBeneficiaryComponent } from './icon-beneficiary/icon-beneficiary.component';
import { IconCaseComponent } from './icon-case/icon-case.component';
import { IconRejectComponent } from './icon-reject/icon-reject.component';
import { IconDoubtDownComponent } from './icon-doubt/icon-doubt.component';
import { IconAddComponent } from './icon-add/icon-add.component';
import { IconCircleCompleteComponent } from './icon-circle-complete/icon-circle-complete.component';
import { IconSavingsComponent } from './icon-savings/icon-savings.component';
import { IconInsuranceComponent } from './icon-insurance/icon-insurance.component';
import { IconAdvantageComponent } from './icon-advantage/icon-advantage.component';
import { IconCircleCheckmarkComponent } from './icon-circle-checkmark/icon-circle-checkmark.component';
import { IconClimateComponent } from './icon-climate/icon-climate.component';
import { IconSavingsBagComponent } from './icon-savings-bag/icon-savings-bag.component';
import { IconCoinStackComponent } from './icon-coin-stack/icon-coin-stack.component';
import { IconSliderComponent } from './icon-slider/icon-slider.component';
import { IconAdvantageHousingComponent } from './icon-advantage-housing/icon-advantage-housing.component';
import { IconAdvantageLBInsuraneComponent } from './icon-advantage-lb-insurance/icon-advantage-lb-insurance.component';
import { IconAdvantageOnboardingComponent } from './icon-advantage-onboarding/icon-advantage-onboarding.component';
import { IconCrossComponent } from './icon-cross/icon-cross.component';
import { IconPensionMiniComponent } from './icon-pension-mini/icon-pension-mini.component';
import { IconIncreaseComponent } from './icon-increase/icon-increase.component';
import { IconDecreaseComponent } from './icon-decrease/icon-decrease.component';
import { IconChangeViewComponent } from './icon-change-view/icon-change-view.component';
import { IconClock45minComponent } from './icon-clock-45min/icon-clock-45min.component';
import { IconPauseComponent } from './icon-pause/icon-pause.component';
import { IconHelpComponent } from './icon-help/icon-help.component';
import { IconSaveComponent } from './icon-save/icon-save.component';
import { IconDocumentDownloadComponent } from './icon-document-download/icon-document-download.component';
import { IconHousingComponent } from './icon-housing/icon-housing.component';
import { IconCashComponent } from './icon-cash/icon-cash.component';
import { IconTrafficLightComponent } from './icon-traffic-light/icon-traffic-light.component';
import { IconDocumentPdfComponent } from './icon-document-pdf/icon-document-pdf.component';

@NgModule({
  imports: [CommonModule, CoreModule],
  declarations: [
    IconCashComponent,
    IconCrossComponent,
    IconDocumentDownloadComponent,
    IconDocumentPdfComponent,
    IconPensionMiniComponent,
    IconProfileComponent,
    IconEnvelopeComponent,
    IconEnvelopeReadComponent,
    IconEventlogComponent,
    IconMenuComponent,
    IconTimesComponent,
    IconArrowDownComponent,
    IconCheckmarkComponent,
    IconCircleCheckmarkComponent,
    IconCompassComponent,
    IconBeneficiaryComponent,
    IconCaseComponent,
    IconArrowUpComponent,
    IconArrowRightComponent,
    IconArrowLeftComponent,
    IconExternalLinkComponent,
    IconInfoComponent,
    IconMaximizeComponent,
    IconMinimizeComponent,
    IconNotificationComponent,
    IconLockedComponent,
    IconRecommendationComponent,
    IconRefreshCustomerComponent,
    IconRefreshComponent,
    IconPaperclipComponent,
    IconDeleteComponent,
    IconEditComponent,
    IconNewMessageComponent,
    IconErrorComponent,
    IconPhoneComponent,
    IconLogoComponent,
    IconDocumentComponent,
    IconProComponent,
    IconSystemComponent,
    IconChatComponent,
    IconOrdersComponent,
    IconCompletedComponent,
    IconHeartGlowingComponent,
    IconRejectComponent,
    IconDoubtDownComponent,
    IconAddComponent,
    IconTypeItComponent,
    IconCircleCompleteComponent,
    IconSavingsComponent,
    IconInsuranceComponent,
    IconAdvantageComponent,
    IconClimateComponent,
    IconSavingsBagComponent,
    IconCoinStackComponent,
    IconSliderComponent,
    IconAdvantageHousingComponent,
    IconAdvantageLBInsuraneComponent,
    IconAdvantageOnboardingComponent,
    IconIncreaseComponent,
    IconDecreaseComponent,
    IconChangeViewComponent,
    IconClock45minComponent,
    IconPauseComponent,
    IconHelpComponent,
    IconSaveComponent,
    IconHousingComponent,
    IconTrafficLightComponent
  ],
  exports: [
    IconCashComponent,
    IconPensionMiniComponent,
    IconCrossComponent,
    IconProfileComponent,
    IconEnvelopeComponent,
    IconEnvelopeReadComponent,
    IconEventlogComponent,
    IconMenuComponent,
    IconTimesComponent,
    IconArrowDownComponent,
    IconCheckmarkComponent,
    IconCircleCheckmarkComponent,
    IconCompassComponent,
    IconBeneficiaryComponent,
    IconCaseComponent,
    IconArrowUpComponent,
    IconArrowRightComponent,
    IconArrowLeftComponent,
    IconExternalLinkComponent,
    IconInfoComponent,
    IconMaximizeComponent,
    IconMinimizeComponent,
    IconNotificationComponent,
    IconLockedComponent,
    IconRecommendationComponent,
    IconRefreshCustomerComponent,
    IconRefreshComponent,
    IconPaperclipComponent,
    IconDeleteComponent,
    IconEditComponent,
    IconNewMessageComponent,
    IconErrorComponent,
    IconPhoneComponent,
    IconCompletedComponent,
    IconLogoComponent,
    IconDocumentComponent,
    IconProComponent,
    IconSystemComponent,
    IconChatComponent,
    IconOrdersComponent,
    IconHeartGlowingComponent,
    IconTypeItComponent,
    IconRejectComponent,
    IconDoubtDownComponent,
    IconAddComponent,
    IconCircleCompleteComponent,
    IconSavingsComponent,
    IconInsuranceComponent,
    IconAdvantageComponent,
    IconClimateComponent,
    IconSavingsBagComponent,
    IconCoinStackComponent,
    IconSliderComponent,
    IconAdvantageHousingComponent,
    IconAdvantageLBInsuraneComponent,
    IconAdvantageOnboardingComponent,
    IconIncreaseComponent,
    IconDecreaseComponent,
    IconChangeViewComponent,
    IconClock45minComponent,
    IconPauseComponent,
    IconHelpComponent,
    IconSaveComponent,
    IconDocumentDownloadComponent,
    IconDocumentPdfComponent,
    IconHousingComponent,
    IconTrafficLightComponent
  ]
})
export class IconModule {}
