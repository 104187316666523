/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ServiceInfo } from './serviceInfo';
import { ServiceInfoSchema } from './serviceInfo';
import { ResourceModel } from './resourceModel';
import { ResourceModelSchema } from './resourceModel';
import * as JoiObj from "joi";


    export const ExcludedPolicySchema = JoiObj.object({
        serviceInfo:
            
        JoiObj.link('#ServiceInfoSchema')

.allow(null)



        ,
        annualPayment:
            
                    JoiObj.number()
                


.required()


        ,
        compagnyNumber:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        policyNumber:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        savings:
            
                    JoiObj.number()
                


.required()


        
    })
                .shared(ServiceInfoSchema)
.id('ExcludedPolicySchema')

export interface ExcludedPolicy extends ResourceModel { 
    annualPayment: number;
    compagnyNumber: string;
    policyNumber: string;
    savings: number;
}

