/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const InsuranceRecommendationSchema = JoiObj.object({
        ci:
            
                    JoiObj.number()
                


.required()


        ,
        life:
            
                    JoiObj.number()
                


.required()


        ,
        loc:
            
                    JoiObj.number()
                


.required()


        ,
        timeAsMs:
            
                    JoiObj.number()
                


.required()


        
    })
.id('InsuranceRecommendationSchema')

export interface InsuranceRecommendation { 
    ci: number;
    life: number;
    loc: number;
    timeAsMs: number;
}

