<span class="bar-content">
  <co-icon-error
    *ngIf="snackBarData.type === NotificationType.ERROR"
  ></co-icon-error>
  <co-icon-circle-complete
    *ngIf="snackBarData.type === NotificationType.SUCCESS"
  ></co-icon-circle-complete>
  <co-icon-info
    *ngIf="snackBarData.type === NotificationType.INFO"
  ></co-icon-info>
  <span>{{ snackBarData.message }}</span>
  <a
    (click)="snackBarRef.dismissWithAction()"
    class="close-button"
    *ngIf="snackBarData.showCloseButton"
  >
    <co-icon-times></co-icon-times>
  </a>
</span>
