import { animate, AnimationBuilder, style } from '@angular/animations';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  TemplateRef
} from '@angular/core';
import { Subject, Subscription, timer } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import * as Hammer from 'hammerjs';

@Directive({
  selector: '[pfaCarouselItem]'
})
export class CarouselItemDirective {
  constructor(public templateRef: TemplateRef<unknown>) {}
}

@Component({
  selector: 'co-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CarouselComponent
  implements OnInit, OnDestroy, OnChanges, AfterViewInit
{
  @Input() slidePercentageWidth: number | string = 100;
  @Input() slideScale = false;
  @Input() autoplay = false;
  @Input() delay = 3000;
  @Input() centerItems = false;
  @Output() slideChange = new EventEmitter<number>();
  private readonly resize$ = new Subject<void>();
  private autoplay$: Subscription;
  public currentSlide = 0;
  public slideWidth: number;

  @ContentChildren(CarouselItemDirective)
  items: QueryList<CarouselItemDirective>;
  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.resize$.next();
  }

  constructor(
    private readonly builder: AnimationBuilder,
    private readonly elementRef: ElementRef,
    private readonly cdRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.resize$.pipe(debounceTime(300)).subscribe(() => this.updateSlides());
  }

  ngOnChanges(): void {
    if (this.autoplay) {
      this.autoplay$ = timer(this.delay, this.delay).subscribe(() => {
        if (this.currentSlide + 1 === this.items.length) {
          this.setActiveSlide(0);
        } else {
          this.setActiveSlide(this.currentSlide + 1);
        }
      });
    } else if (!this.autoplay && this.autoplay$) {
      this.autoplay$.unsubscribe();
    }
  }

  ngOnDestroy(): void {
    this.resize$.unsubscribe();
    if (this.autoplay$) {
      this.autoplay$.unsubscribe();
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.updateSlides());
  }

  public nextSlide(): void {
    if (this.currentSlide + 1 === this.items.length) {
      return;
    }

    this.setActiveSlide((this.currentSlide + 1) % this.items.length);
  }

  public previousSlide(): void {
    if (this.currentSlide === 0) {
      return;
    }

    this.setActiveSlide(
      (this.currentSlide - 1 + this.items.length) % this.items.length
    );
  }

  public setActiveSlide(index: number): void {
    this.currentSlide = index < 0 ? 0 : index;
    if (index > this.items.length - 1) {
      this.currentSlide = index - 1;
    }
    this.updateSlides();
    this.slideChange.emit(this.currentSlide);
  }

  private updateSlides(): void {
    this.updateSlidesWidth();
    this.setContainerOffset(this.getCurrentSlideOffset(), true);

    if (!this.slideScale) {
      return;
    }

    setTimeout(() => {
      this.elementRef.nativeElement
        .querySelectorAll('.carousel-item')
        .forEach((item, index) => {
          this.builder
            .build([
              animate(
                '100ms ease-in',
                style({
                  transform: `scale(${index === this.currentSlide ? 1 : 0.9})`
                })
              )
            ])
            .create(item)
            .play();
        });
    }, 250);
  }

  private setContainerOffset(offset: number, animation = false): void {
    this.builder
      .build([
        animate(
          `${animation ? 250 : 0}ms ease-in`,
          style({
            transform: `translate3d(${offset}px, 0, 0)`
          })
        )
      ])
      .create(this.elementRef.nativeElement.querySelector('.carousel-inner'))
      .play();
  }

  private getCarouselWrapperWidth(): number {
    return this.elementRef.nativeElement
      .querySelector('.carousel-wrapper')
      .getBoundingClientRect().width;
  }

  private updateSlidesWidth(): void {
    this.slideWidth = Math.round(
      (this.getCarouselWrapperWidth() *
        (typeof this.slidePercentageWidth === 'string'
          ? parseInt(this.slidePercentageWidth, 10)
          : this.slidePercentageWidth)) /
        100
    );
    this.cdRef.detectChanges();
  }

  private getCurrentSlideOffset(): number {
    return (
      this.currentSlide * -this.slideWidth +
      (this.getCarouselWrapperWidth() - this.slideWidth) / 2
    );
  }

  public trackByFn(index: number): number {
    return index;
  }

  public handleTouch(event): void {
    switch (event.type) {
      case 'panright':
      case 'panleft':
        this.setContainerOffset(
          this.getCurrentSlideOffset() +
            ((this.currentSlide === 0 &&
              event.direction === Hammer.DIRECTION_RIGHT) ||
            (this.currentSlide === this.items.length - 1 &&
              event.direction === Hammer.DIRECTION_LEFT)
              ? event.deltaX * 0.4
              : event.deltaX)
        );
        break;

      case 'panend':
        // lintfixme: fix error and enable rule
        // eslint-disable-next-line no-case-declarations
        const slideToSet =
          this.currentSlide - Math.round(event.deltaX / this.slideWidth);
        this.setActiveSlide(
          this.currentSlide === slideToSet
            ? event.deltaX <= -100 && slideToSet + 1 <= this.items.length
              ? slideToSet + 1
              : event.deltaX > 100 && slideToSet - 1 >= 0
                ? slideToSet - 1
                : slideToSet
            : slideToSet
        );
        break;
    }
  }
}
