<co-card
  (click)="goToPricelist()"
  class="card--small cursor-pointer"
  data-test-id="frontpage-savings-costs"
>
  <mitpfa-dashboard-savings-header
    [content]="'DL.FS03.C13'"
  ></mitpfa-dashboard-savings-header>
  <div class="savings-cost-spacer" [coContent]="'DL.FS03.C14'"></div>
</co-card>
