<div [ngClass]="{ 'confirm-box': !simple }">
  <ng-container *ngIf="!simple">
    <h4
      [coContent]="texts?.headlineContentId"
      class="text--color-primary align--center"
    ></h4>

    <div [coContent]="texts?.bodyContentId" class="align--center"></div>
  </ng-container>

  <div [ngClass]="{ 'spacing-unit--over': !simple }" class="align--center">
    <div
      *ngIf="confirmedDate"
      [ngClass]="{ 'spacing-unit--half-under': !success }"
      class="text--note"
    >
      {{ 'DL.INV.C718' | content }} {{ confirmedDate | date: 'dd.MM.yyyy' }}
    </div>

    <button
      *ngIf="!success"
      mat-raised-button
      (click)="confirm()"
      [coContent]="texts?.buttonContentId"
      [disabled]="isDisabled"
      class="button-secondary"
    ></button>

    <co-icon-circle-checkmark
      class="icon-checkmark"
      color="white"
      *ngIf="success"
    ></co-icon-circle-checkmark>
  </div>
</div>
