import { Component, DestroyRef, inject, Input, OnInit } from '@angular/core';
import { combineLatest } from 'rxjs';
import { FooterContactType } from '../footer.model';
import {
  Fordele,
  FordeleStore,
  MaeglerInfo,
  PensionsKundeGenerelleData,
  PensionskundeStore
} from '@pfa/gen';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'co-footer-contact',
  templateUrl: './footer-contact.component.html',
  styleUrls: ['./footer-contact.component.scss']
})
export class FooterContactComponent implements OnInit {
  @Input() isLight: boolean;
  @Input() isLetPension: boolean;

  private readonly fordeleStore: FordeleStore = inject(FordeleStore);
  private readonly pensionskundeStore: PensionskundeStore =
    inject(PensionskundeStore);
  private readonly destroyRef: DestroyRef = inject(DestroyRef);

  public isLoading: boolean;
  public footerContactType: FooterContactType;
  public brokerInfo: MaeglerInfo;
  public advantages: Fordele;
  public user: PensionsKundeGenerelleData;

  ngOnInit(): void {
    if (this.isLight) {
      this.initLight();
    } else {
      this.init();
    }
  }

  private init(): void {
    this.isLoading = true;
    combineLatest([
      this.pensionskundeStore.pensionskundeMaeglerinfoGet(),
      this.fordeleStore.fordeleGet(),
      this.pensionskundeStore.pensionskundeGet()
    ])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: ([brokerInfo, advantages, user]) => {
          this.brokerInfo = brokerInfo;
          this.advantages = advantages;
          this.user = user;
          this.determineContactType(brokerInfo, advantages);
          this.isLoading = false;
        },
        error: () => {
          this.isLoading = false;
        }
      });
  }

  private initLight(): void {
    this.footerContactType = 'standard';
  }

  private determineContactType(brokerInfo: MaeglerInfo, advantages: Fordele) {
    if (brokerInfo.maeglerBetjent && brokerInfo.livnetAftale) {
      this.footerContactType = 'broker';
    } else if (
      advantages.programId !== undefined &&
      advantages.programId !== null &&
      advantages.kontakter &&
      advantages.kontakter.length > 0
    ) {
      this.footerContactType = 'advisor';
    } else {
      this.footerContactType = 'standard';
    }
  }
}
