import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { SinglePaymentApiService } from '@pfa/api';
import { Observable } from 'rxjs';
import { PrivatIndskud } from '@pfa/gen';

@Injectable({
  providedIn: 'root'
})
export class SinglePaymentResolver implements Resolve<PrivatIndskud> {
  constructor(private readonly singlePaymentService: SinglePaymentApiService) {}

  resolve():
    | PrivatIndskud
    | Observable<PrivatIndskud>
    | Promise<PrivatIndskud> {
    return this.singlePaymentService.getSinglePaymentData();
  }
}
