import { Component, OnInit } from '@angular/core';
import { LoggedOutInactiveComponent } from '../logon/logged-out-inactive/logged-out-inactive.component';
import { PopupStyle } from '@pfa/models';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'mitpfa-advisor-timeout',
  templateUrl: './advisor-timeout.component.html'
})
export class AdvisorTimeoutComponent implements OnInit {
  constructor(private readonly dialog: MatDialog) {}

  ngOnInit(): void {
    const popup = this.dialog.open(LoggedOutInactiveComponent, {
      data: {
        isAdvisor: true
      },
      disableClose: true,
      panelClass: PopupStyle.PopupSmall
    });
    popup.afterClosed().subscribe(() => {
      sessionStorage.removeItem('userTimedOut');
      sessionStorage.removeItem('userTimedOutRaadgiver');
    });
  }
}
