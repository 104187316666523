import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DashboardMessageService {
  private readonly payoutsData: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  private hidePensionCheckCard: boolean;

  announcePayoutsData(data: boolean): void {
    this.payoutsData.next(data);
  }

  getPayoutsData(): BehaviorSubject<boolean> {
    return this.payoutsData;
  }

  getHidePensionCheckCard(): boolean {
    return this.hidePensionCheckCard;
  }

  setHidePensionCheckCard() {
    this.hidePensionCheckCard = true;
  }
}
