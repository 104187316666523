/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SpoergeskemaStatus } from './spoergeskemaStatus';
import { SpoergeskemaStatusSchema } from './spoergeskemaStatus';
import * as JoiObj from "joi";


    export const StatusDatoSchema = JoiObj.object({
        dato:
            
            JoiObj.date()


.required()


        ,
        status:
            
        JoiObj.link('#SpoergeskemaStatusSchema')


.required()


        
    })
                .shared(SpoergeskemaStatusSchema)
.id('StatusDatoSchema')

export interface StatusDato { 
    dato: Date;
    status: SpoergeskemaStatus;
}
export namespace StatusDato {
}


