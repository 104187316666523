/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const OpsparingFormFordelingSchema = JoiObj.string().valid(
        'KapitalRateLivsPension'
        ,
        'RateKapitalLivsPension'
        ,
        'RateLivsPension'
        ,
        'LivsPension'
        ,
        'EjFradragsberettigt'
        ,
        'AldersRateLivsPension'
        ,
        'RateAldersLivsPension'
        ,
        'AldersLivsPension'
        
    )
.id('OpsparingFormFordelingSchema')

export type OpsparingFormFordeling = 'KapitalRateLivsPension' | 'RateKapitalLivsPension' | 'RateLivsPension' | 'LivsPension' | 'EjFradragsberettigt' | 'AldersRateLivsPension' | 'RateAldersLivsPension' | 'AldersLivsPension';

export const OpsparingFormFordeling = {
    KapitalRateLivsPension: 'KapitalRateLivsPension' as OpsparingFormFordeling,
    RateKapitalLivsPension: 'RateKapitalLivsPension' as OpsparingFormFordeling,
    RateLivsPension: 'RateLivsPension' as OpsparingFormFordeling,
    LivsPension: 'LivsPension' as OpsparingFormFordeling,
    EjFradragsberettigt: 'EjFradragsberettigt' as OpsparingFormFordeling,
    AldersRateLivsPension: 'AldersRateLivsPension' as OpsparingFormFordeling,
    RateAldersLivsPension: 'RateAldersLivsPension' as OpsparingFormFordeling,
    AldersLivsPension: 'AldersLivsPension' as OpsparingFormFordeling
};

