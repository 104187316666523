<co-loading [show]="sendingMessage"></co-loading>
<div mat-dialog-title>
  <ng-container [ngSwitch]="currentStep">
    <h3 *ngSwitchCase="step.category" [coContent]="'DL.BE02.C1030'">
      Vælg kategori
    </h3>
    <h3 *ngSwitchCase="step.subject" [coContent]="'DL.BE02.C1031'">
      Vælg emne
    </h3>
    <h3 *ngSwitchCase="step.newMessage" [coContent]="'DL.BE02.C12'">
      Ny Besked
    </h3>
  </ng-container>
  <co-icon-times class="icons" (click)="closePopup()"></co-icon-times>
</div>

<mat-dialog-content>
  <div class="message-new">
    <div *ngIf="showDynamicForm" class="message-write" [@slideInRight]>
      <co-dynamic-form
        [category]="selectedTopic.id"
        [title]="selectedTopic.text[lang]"
        [formId]="selectedTopic.dynamicFormId[lang]"
        [contactData]="contactData$ | async"
        [hideSendButton]="true"
        [language]="lang"
        [channel]="'mitpfa'"
      >
      </co-dynamic-form>
    </div>
    <form name="newmessageForm" #newmessageForm="ngForm">
      <div *ngIf="showNew" class="message-write" [@slideInRight]>
        <div class="message-topic">
          <div class="message-topic-text">{{ selectedTopic.text[lang] }}</div>
        </div>
        <div class="cf"></div>
        <div class="row">
          <div class="col-12">
            <mat-form-field color="accent">
              <mat-label [coContent]="'DL.BE02.C16'">Emne</mat-label>
              <input
                matInput
                [formControl]="title"
                [(ngModel)]="thread.title"
              />
            </mat-form-field>
          </div>
        </div>

        <mat-form-field color="accent">
          <mat-label [coContent]="'DL.BE02.C1036'"></mat-label>
          <textarea
            matInput
            #messagebody
            [formControl]="body"
            [(ngModel)]="thread.message.body"
            maxlength="2000"
            rows="8"
            ondrop="return false;"
            name="messagebody"
          ></textarea>
          <mat-hint align="end"
            >{{ messagebody.value?.length || 0 }}/2000</mat-hint
          >
        </mat-form-field>

        <co-contact-info
          *ngIf="showContactInput"
          (contactInfoRef)="contactInfoFormInit($event)"
          [enableAllValidationErrors]="enableAllValidationErrors"
          [contactData]="contactData"
        ></co-contact-info>
        <co-attachment
          class="attachment--static"
          [attachments]="attachments"
          (addAttachment)="addAttachment($event)"
          (removeAttachment)="removeAttachment($event)"
          [channel]="channel"
          [limitSize]="20 * 1024 * 1024"
        >
        </co-attachment>
        <div class="attachment-rules--static-text">
          <co-info>
            <span [coContent]="'DL.BE02.C1049'"></span>
          </co-info>
        </div>
      </div>
      <div [@no-initial-animation]>
        <div
          class="message-category-holder"
          *ngIf="!showNew && !showDynamicForm"
          [@slideInLeft]
        >
          <co-loading [show]="!categories"></co-loading>
          <co-warning *ngIf="getCategoriesFailed">
            <span [coContent]="'DL.BE02.C20'"></span>
          </co-warning>
          <div
            class="message-categories"
            *ngIf="!selectedCategory && !getCategoriesFailed"
            [@slideInLeft]
          >
            <div class="message-category-header" [coContent]="'DL.BE02.C13'">
              Hjælp os med at hjælpe dig, vælg en kategori som passer bedst til
              din besked
            </div>

            <div class="row">
              <div
                class="col-6 col-sm-4 message-block"
                *ngFor="let category of categories"
                (click)="showTopics(category)"
              >
                <div class="message-category--outer">
                  <img [src]="category.imageUrl" />
                  <a class="message-category-link link">
                    <div class="message-category-text">
                      {{ category.text[lang] }}
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            class="message-single-category"
            *ngIf="selectedCategory"
            [@slideInRight]
          >
            <div class="row">
              <div class="col-4" (click)="showCategories()">
                <div class="message-category">
                  <img [src]="selectedCategory.imageUrl" />
                  <a class="message-category-link link">
                    <div class="message-category-text">
                      {{ selectedCategory.text[lang] }}
                    </div>
                  </a>
                </div>
              </div>
              <div *ngIf="selectedCategory" class="col-8">
                <div class="message-topic-header" [coContent]="'DL.BE02.C14'">
                  Vælg det emne der bedst beskriver din besked
                </div>
                <ul>
                  <li
                    class="message-topic"
                    *ngFor="let topic of selectedCategory.topics"
                    [ngClass]="{ selected: topic.selected }"
                    (click)="selectTopic(topic)"
                  >
                    <div class="message-topic-text">{{ topic.text[lang] }}</div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="attachment-scroll-id"></div>
      <div id="send-message-failed"></div>
      <co-warning
        *ngIf="sendMessageFailed && showNew"
        class="spacing-unit--half-over"
      >
        <div data-test-id="BE02-attachment-error">
          <span *ngIf="!virusDetected" [coContent]="'DL.BE02.C09'">FEJL!</span>
          <span
            *ngIf="virusDetected && attachments.length === 1"
            [coContent]="'Global.virusifilenadvar'"
            >VIRUS!</span
          >
          <span
            *ngIf="virusDetected && attachments.length > 1"
            [coContent]="'Global.virusifilenadvar1'"
            >VIRUS!</span
          >
        </div>
      </co-warning>
    </form>
    <div id="send-message-failed-dynamic-form">&nbsp;</div>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="back-button" *ngIf="currentStep === step.subject">
    <button
      mat-raised-button
      (click)="showCategories()"
      class="icon__on-primary--stroke"
    >
      <co-icon-arrow-left class="icon icon--arrow-left"></co-icon-arrow-left>
      <span [coContent]="'DL.BE02.C19'">Tilbage</span>
    </button>
  </div>
  <div class="back-button" *ngIf="showNew">
    <button
      mat-raised-button
      (click)="showNew = false; currentStep = currentStep - 1"
      class="icon__on-primary--stroke"
    >
      <co-icon-arrow-left class="icon icon--arrow-left"></co-icon-arrow-left>
      <span [coContent]="'DL.BE02.C19'">Tilbage</span>
    </button>
  </div>
  <div class="back-button" *ngIf="showDynamicForm">
    <button
      mat-raised-button
      (click)="dynamicFormBackClick()"
      class="icon__on-primary--stroke"
    >
      <co-icon-arrow-left class="icon icon--arrow-left"></co-icon-arrow-left>
      <span [coContent]="'DL.BE02.C19'">Tilbage</span>
    </button>
  </div>
  <div class="send-button" *ngIf="showDynamicForm">
    <button mat-raised-button (click)="submitDynamicForm()">
      <span [coContent]="'DL.BE02.C06'">Send</span>
    </button>
  </div>
  <div class="send-button" *ngIf="showNew">
    <button
      mat-raised-button
      [disabled]="
        !newmessageForm.form.valid ||
        !thread.message.body ||
        thread.message.body.trim() === '' ||
        !thread.title ||
        thread.title.trim() === '' ||
        sendingMessage
      "
      (click)="sendMessage()"
      data-test-id="BE02-send-btn"
    >
      <span [coContent]="'DL.BE02.C06'">Send</span>
    </button>
  </div>
</mat-dialog-actions>
