<co-advisor-box
  *ngIf="displayForm$ | async"
  [formGroup]="form.controls.advisor"
>
  <p class="text--manchet text--bold" coContent="DL.FG01.C555"></p>

  <mat-form-field appearance="fill" color="accent">
    <mat-label>{{ 'DL.PP01.C83' | content }}</mat-label>
    <mat-select formControlName="interestForm">
      <mat-option
        *ngFor="let interestFormType of interestFormTypes"
        [value]="interestFormType.value"
      >
        {{ interestFormType.translation | content }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <label coContent="DL.PP01.C86"></label>
  <mat-radio-group ngDefaultControl formControlName="loanRepayments">
    <mat-radio-button
      *ngFor="let loanRepaymentsType of loanRepaymentsTypes"
      [value]="loanRepaymentsType.value"
    >
      {{ loanRepaymentsType.translation | content }}
    </mat-radio-button>
  </mat-radio-group>
</co-advisor-box>
