import { Component, OnInit, inject } from '@angular/core';
import { PageTrackingService } from '@pfa/core';
import { BrugerStore } from '@pfa/gen';

@Component({
  selector: 'mitpfa-advantage-housing',
  templateUrl: './advantage-housing.component.html',
  styleUrls: ['./advantage-housing.component.scss']
})
export class AdvantageHousingComponent implements OnInit {
  private readonly brugerStore: BrugerStore = inject(BrugerStore);
  private readonly pageTrackingService: PageTrackingService =
    inject(PageTrackingService);
  public isAdvisor: boolean;

  public ngOnInit(): void {
    this.brugerStore.brugerGet().subscribe(user => {
      this.isAdvisor = user.isRaadgiver;
      this.pageTrackingService.trackContentLoaded();
    });
  }

  public trackSignUp(signUpAction: string): void {
    this.pageTrackingService.trackNavigation(
      'pfahousing',
      signUpAction,
      'findbolig.nu'
    );
  }
}
