/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TransactionType } from './transactionType';
import { TransactionTypeSchema } from './transactionType';
import { AttachedFile } from './attachedFile';
import { AttachedFileSchema } from './attachedFile';
import { StatusSalesOrder } from './statusSalesOrder';
import { StatusSalesOrderSchema } from './statusSalesOrder';
import * as JoiObj from "joi";


    export const SalesOrderSchema = JoiObj.object({
        advisor:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        amount:
            
                    JoiObj.number()
                


.required()


        ,
        amountReceived:
            
                    JoiObj.number()
                


.required()


        ,
        attachedFiles:
                JoiObj.array().items(
        
        JoiObj.link('#AttachedFileSchema')

        )
        

.required()


        ,
        comment:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        createdOn:
            
                    JoiObj.object()
                


.required()


        ,
        currencyCode:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        description:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        endDate:
            
                    JoiObj.object()
                


.required()


        ,
        exitReasonComment:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        mlDocExpectedDate:
            
                    JoiObj.object()
                


.required()


        ,
        mlDocMissingComment:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        originOfMoneyComment:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        originOfMoneySelectedList:
                JoiObj.array().items(
        
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

        )
        

.required()


        ,
        paymentID:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        policyDescription:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        policyId:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        salesOrderGUID:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        salesOrderID:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        status:
            
        JoiObj.link('#StatusSalesOrderSchema')


.required()


        ,
        statusDate:
            
                    JoiObj.object()
                


.required()


        ,
        transactionType:
            
        JoiObj.link('#TransactionTypeSchema')


.required()


        ,
        valueDate:
            
                    JoiObj.object()
                


.required()


        
    })
                .shared(AttachedFileSchema)
                .shared(StatusSalesOrderSchema)
                .shared(TransactionTypeSchema)
.id('SalesOrderSchema')

export interface SalesOrder { 
    advisor: string;
    amount: number;
    amountReceived: number;
    attachedFiles: Array<AttachedFile>;
    comment: string;
    createdOn: object;
    currencyCode: string;
    description: string;
    endDate: object;
    exitReasonComment: string;
    mlDocExpectedDate: object;
    mlDocMissingComment: string;
    originOfMoneyComment: string;
    originOfMoneySelectedList: Array<string>;
    paymentID: string;
    policyDescription: string;
    policyId: string;
    salesOrderGUID: string;
    salesOrderID: string;
    status: StatusSalesOrder;
    statusDate: object;
    transactionType: TransactionType;
    valueDate: object;
}
export namespace SalesOrder {
}


