<div
  #tooltip="matTooltip"
  [matTooltip]="tooltipText$ | async"
  *ngIf="tooltipText$ | async"
  class="d-flex flex-nowrap align-items-center"
  [ngClass]="{ 'margins--inside-card-placement': isInsideCard }"
  (mouseenter)="handleTooltipShow()"
  (click)="tooltip.toggle()"
>
  <co-icon-info class="icon-info guide-help-icon"></co-icon-info>
  <span coContent="DL.INVGU01.C613"></span>
</div>
