import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { InsuranceRecommendation } from '@pfa/gen';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class InsuranceGuideApiService {
  constructor(private http: HttpClient) {}

  getInsuranceGuideData(): Observable<InsuranceRecommendation> {
    return this.http.get<InsuranceRecommendation>(
      '/ds/api/forsikringsguide/anbefaling'
    );
  }
}
