import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { PrisGebyrOversigt, PrislisteStore } from '@pfa/gen';

@Injectable({
  providedIn: 'root'
})
export class PriceListResolver implements Resolve<PrisGebyrOversigt> {
  constructor(private prislisteStore: PrislisteStore) {}

  resolve():
    | PrisGebyrOversigt
    | Observable<PrisGebyrOversigt>
    | Promise<PrisGebyrOversigt> {
    return this.prislisteStore.prislisteGet();
  }
}
