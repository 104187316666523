<!-- v2 -->
<svg
  id="Layer_1"
  data-name="Layer 1"
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 32 32"
>
  <g id="Canvas">
    <circle
      id="Large_circle"
      data-name="Large circle"
      cx="16"
      cy="16"
      r="16"
      fill="none"
    />
    <path d="M16,4A12,12,0,1,0,28,16,12,12,0,0,0,16,4Z" fill="none" />
  </g>
  <g id="Icon">
    <path
      id="in_front"
      data-name="in front"
      d="M11,21.19,7.7,23.3l-.2-2.7a3.68,3.68,0,0,1-2.4-3.2c0-2.2,2.6-3.9,5.9-3.9s5.83,1.46,5.83,3.84c0,1.57-1.78,2.8-3,3.33A5.74,5.74,0,0,1,11,21.19Z"
      fill="none"
      stroke="#000"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.2"
    />
    <path
      id="behind"
      d="M15.2,12.3c.5-1.93,2.94-3.1,5.8-3.2,3.54-.12,5.9,1.8,5.9,3.9,0,1.4-.9,2.5-2.5,3.2l-.1,2.7L21,17a7.84,7.84,0,0,1-2.2-.3"
      fill="none"
      stroke="#000"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.2"
    />
  </g>
</svg>
