/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const LoenSchema = JoiObj.object({
        copy:
            
        JoiObj.link('#LoenSchema')


.required()


        ,
        daekningsgivendeLoen:
            
                    JoiObj.number()
                


.required()


        ,
        pensionsgivendeLoen:
            
                    JoiObj.number()
                


.required()


        
    })
.id('LoenSchema')

export interface Loen { 
    copy: Loen;
    daekningsgivendeLoen: number;
    pensionsgivendeLoen: number;
}

