/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ServiceInfo } from './serviceInfo';
import { ServiceInfoSchema } from './serviceInfo';
import { ContentDetails } from './contentDetails';
import { ContentDetailsSchema } from './contentDetails';
import { ResourceModel } from './resourceModel';
import { ResourceModelSchema } from './resourceModel';
import * as JoiObj from "joi";


    export const HentGlobalsResponseSchema = JoiObj.object({
        serviceInfo:
            
        JoiObj.link('#ServiceInfoSchema')

.allow(null)



        ,
        globals:
                JoiObj.array().items(
        
        JoiObj.link('#ContentDetailsSchema')

        )
        

.required()


        
    })
                .shared(ServiceInfoSchema)
                .shared(ContentDetailsSchema)
.id('HentGlobalsResponseSchema')

export interface HentGlobalsResponse extends ResourceModel { 
    globals: Array<ContentDetails>;
}

