import {
  Component,
  EventEmitter,
  Input,
  Output,
  OnInit,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { MessagingService } from '../messaging.service';
import { ScrollToService, PdfPopupService } from '@pfaform';

@Component({
  selector: 'co-thread',
  templateUrl: './thread.component.html',
  styleUrls: ['./thread.component.scss']
})
export class ThreadComponent implements OnInit, OnChanges {
  @Input() thread: any;
  @Output() closeThread = new EventEmitter<any>();

  attachments: any[];
  messageBody: string;
  showAttachmentArea: boolean;
  showWarnings: boolean;
  threadInitialized = false;
  channel: string;
  sendingMessage: boolean;

  constructor(
    private service: MessagingService,
    private scrollToService: ScrollToService,
    private pdfPopupService: PdfPopupService
  ) {}

  ngOnInit() {
    this.channel = this.service.getChannel();
    this.attachments = [];
  }

  sendMessage() {
    if (this.messageBody && !this.sendingMessage) {
      this.sendingMessage = true;
      const message = {
        fromCustomer: true,
        body: this.messageBody,
        formattedBody: this.messageBody,
        sending: true
      };
      this.thread.messages?.push(message);

      this.scrollToService.scrollTo('thread-end');
      setTimeout(() => {
        this.scrollToEnd();
      }, 0);
      this.service
        .replyMessage(this.thread.id, message, this.attachments)
        .subscribe(
          data => {
            if (!data.fejlet) {
              this.service.getThread(this.thread.id).subscribe(
                thread => {
                  this.thread = thread;
                  setTimeout(() => {
                    this.scrollToEnd();
                  }, 0);
                  this.messageBody = '';
                  this.attachments = [];
                  this.showAttachmentArea = false;
                  this.sendingMessage = false;
                },
                () => {
                  this.sendingMessage = false;
                }
              );
            } else {
              this.handleError(message);
              this.sendingMessage = false;
            }
          },
          () => {
            this.sendingMessage = false;
            this.handleError(message);
          }
        );
    }
  }

  handleError(message) {
    message.replyFailed = true;
    message.sending = false;
    setTimeout(() => {
      this.scrollToEnd();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.thread.currentValue) {
      // only clear when it is not a refresh
      // if no previousValue then it is not a refresh
      if (!changes.thread.previousValue) {
        this.messageBody = '';
        this.attachments = [];
      }
      if (!this.threadInitialized) {
        // vent lige lidt
        this.threadInitialized = true;
        setTimeout(() => {
          this.scrollToEnd();
        }, 10);
      } else {
        setTimeout(() => {
          this.scrollToEnd();
        }, 0);
      }
    }
  }

  scrollToEnd() {
    this.scrollToService.scrollTo('#thread-end', '.messaging-thread');
  }

  toggleAttachmentArea() {
    this.showAttachmentArea = !this.showAttachmentArea;
  }

  openAttachment(attachment) {
    if (attachment.disabled || attachment.limitExceeded) {
      return;
    }
    // attachment disabled for 20 seconds or until thread opened again
    attachment.disabled = true;
    setTimeout(() => {
      attachment.disabled = false;
    }, 20000);
    attachment.limitExceeded =
      this.service.registerAttachmentAccess(attachment);
    let documentUrl;
    if (attachment.externalAttachmentId) {
      documentUrl =
        '/ds/api/messaging/attachments/' +
        attachment.externalStorageSystem +
        '/' +
        attachment.externalAttachmentId +
        '?filename=' +
        attachment.name +
        '&mimetype=' +
        attachment.mimetype;
    } else {
      documentUrl =
        '/ds/api/messaging/' + this.channel + '/attachments/' + attachment.id;
    }

    if (attachment.mimetype === 'application/pdf') {
      this.pdfPopupService.showPdfPopup(documentUrl);
    } else {
      // open file dialog in new window to indicate loading
      window.open(documentUrl, '_blank');
    }
  }
  addAttachment(file) {
    this.attachments.push(file);
  }
  setShowWarnings(warnings: string[]) {
    this.showWarnings = warnings.length > 0;
  }
  removeAttachment(file) {
    this.attachments.splice(this.attachments.indexOf(file), 1);
  }

  emitCloseThread() {
    this.closeThread.emit();
  }
}
