import { Injectable } from '@angular/core';
import { CustomerProfile, PensionsKundeGenerelleData } from '@pfa/gen';

@Injectable()
export class DigitalDataTrackingService {
  trackCustomerType(profile: CustomerProfile) {
    if (profile.personDetail.employee) {
      (window as any).digitalData.customerType = 'PFA Employee';
    } else if (profile.personDetail.pureLetpensionCustomer) {
      (window as any).digitalData.customerType = 'Letpension only';
    } else if (profile.personDetail.letpensionCustomer) {
      (window as any).digitalData.customerType = 'Letpension double customer';
    } else if (profile.personDetail.onboarding) {
      (window as any).digitalData.customerType = 'Onboarding';
    } else {
      (window as any).digitalData.customerType = 'Customer';
    }
  }

  trackLoginType(pensionCustomer: PensionsKundeGenerelleData) {
    (window as any).digitalData.loginMethod = pensionCustomer.loginMethod;
  }
}
