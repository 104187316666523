<co-accordion *ngIf="useWrapper">
  <div class="header" customHeader>
    <div class="title" [coContent]="consentConfig?.typeTextId"></div>
    <div *ngIf="isGiven" class="status" coContent="DL.ST01.C124"></div>
  </div>
  <ng-container *ngTemplateOutlet="consentContent"></ng-container>
</co-accordion>
<ng-container *ngIf="!useWrapper">
  <ng-container *ngTemplateOutlet="consentContent"></ng-container>
</ng-container>
<ng-template #consentContent>
  <ng-container *ngIf="consent">
    <div class="retired" *ngIf="consent.retiredConsent">
      <p coContent="DL.ST01.C230"></p>
      <div>
        <a
          class="link"
          coContent="DL.ST01.C122"
          (click)="showRetiredConsentPdf()"
        ></a>
      </div>
      <div>
        <a
          class="link"
          *ngIf="isRetiredConsentGiven"
          [coContent]="consentConfig?.signOutRetiredTextId"
          (click)="revoke(true)"
        ></a>
      </div>
    </div>
    <p *ngIf="isEnglish" [coContent]="consentConfig?.englishOnlyTextId"></p>
    <h4 [coContent]="consentConfig?.detailsHeaderTextId"></h4>
    <p class="bullet-list" [coContent]="consentConfig?.detailsTextId"></p>
    <div class="separator"></div>
    <div class="main-consent">
      <button
        [disabled]="!isSaveButtonActive"
        (click)="save()"
        mat-raised-button
        class="save-button"
      >
        {{ saveButtonTextId | content }}
      </button>
      <div
        class="main-consent__label"
        [coContent]="consentConfig?.signUpTextId"
      ></div>
    </div>
    <a
      *ngIf="isGiven"
      class="link"
      (click)="revoke()"
      [coContent]="consentConfig?.signOutTextId"
    ></a>
    <co-info *ngIf="!consent.requirementsMet">
      <span [coContent]="consentConfig?.requiresTextId"></span>
    </co-info>
  </ng-container>
</ng-template>
