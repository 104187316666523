/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AarligAfkaster } from './aarligAfkaster';
import { AarligAfkasterSchema } from './aarligAfkaster';
import { MaanedligAarligAfkaster } from './maanedligAarligAfkaster';
import { MaanedligAarligAfkasterSchema } from './maanedligAarligAfkaster';
import * as JoiObj from "joi";


    export const PfaAfkastSchema = JoiObj.object({
        afkast_aar:
            
        JoiObj.link('#AarligAfkasterSchema')


.required()


        ,
        afkast_aar_klima_plus:
            
        JoiObj.link('#AarligAfkasterSchema')


.required()


        ,
        afkast_eks_kundekap_aar:
            
        JoiObj.link('#AarligAfkasterSchema')


.required()


        ,
        afkast_eks_kundekap_aar_klima_plus:
            
        JoiObj.link('#AarligAfkasterSchema')


.required()


        ,
        afkast_eks_kundekap_maaned:
            
        JoiObj.link('#MaanedligAarligAfkasterSchema')


.required()


        ,
        afkast_eks_kundekap_maaned_klima_plus:
            
        JoiObj.link('#MaanedligAarligAfkasterSchema')


.required()


        ,
        afkast_maaned:
            
        JoiObj.link('#MaanedligAarligAfkasterSchema')


.required()


        ,
        afkast_maaned_klima_plus:
            
        JoiObj.link('#MaanedligAarligAfkasterSchema')


.required()


        ,
        loadtid:
            
                    JoiObj.object()
                


.required()


        
    })
                .shared(AarligAfkasterSchema)
                .shared(AarligAfkasterSchema)
                .shared(AarligAfkasterSchema)
                .shared(AarligAfkasterSchema)
                .shared(MaanedligAarligAfkasterSchema)
                .shared(MaanedligAarligAfkasterSchema)
                .shared(MaanedligAarligAfkasterSchema)
                .shared(MaanedligAarligAfkasterSchema)
.id('PfaAfkastSchema')

export interface PfaAfkast { 
    afkast_aar: AarligAfkaster;
    afkast_aar_klima_plus: AarligAfkaster;
    afkast_eks_kundekap_aar: AarligAfkaster;
    afkast_eks_kundekap_aar_klima_plus: AarligAfkaster;
    afkast_eks_kundekap_maaned: MaanedligAarligAfkaster;
    afkast_eks_kundekap_maaned_klima_plus: MaanedligAarligAfkaster;
    afkast_maaned: MaanedligAarligAfkaster;
    afkast_maaned_klima_plus: MaanedligAarligAfkaster;
    loadtid: object;
}

