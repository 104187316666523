/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const PolicyTypeOfferSchema = JoiObj.string().valid(
        'NEW'
        ,
        'CONTINUATION'
        ,
        'RESUME'
        
    )
.id('PolicyTypeOfferSchema')

export type PolicyTypeOffer = 'NEW' | 'CONTINUATION' | 'RESUME';

export const PolicyTypeOffer = {
    New: 'NEW' as PolicyTypeOffer,
    Continuation: 'CONTINUATION' as PolicyTypeOffer,
    Resume: 'RESUME' as PolicyTypeOffer
};

