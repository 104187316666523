/**
 * Card
 *   @Input alertColor shows a indicator left of the card or top if in mobile
 *   @Input greenCard when you want the card to have a green background
 *   @Input showErrorMessage if true errormessage is shown
 *   @Input errorMessage if true the error text
 *
 * Accordion component with card-accordion CSS class move it to the bottom of card as integral part of whole card component
 */
import {
  animate,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  ViewChild
} from '@angular/core';
import { collapse } from '@pfa/animations';
import { CardAnimatedStackItem } from '../card-animated-stack/card-animated-stack-item/card-animated-stack-item.interface';
import { AlertColor } from '@pfa/models';

const opacity = trigger('opacity', [
  state('hide', style({ opacity: '0' })),
  state('show', style({ opacity: '1' })),
  transition('* => hide', animate('300ms ease-out', style({ opacity: '0' }))),
  transition('* => show', animate('300ms ease-out', style({ opacity: '1' })))
]);

@Component({
  selector: 'co-card',
  templateUrl: 'card.component.html',
  styleUrls: ['./card.component.scss'],
  animations: [collapse, opacity]
})
export class CardComponent implements CardAnimatedStackItem {
  @Input() flat: boolean;
  @Input() notInteractive: boolean; // shadow, but no hover effect
  @Input() cardRed: boolean;
  @Input() cardGreen: boolean;
  @Input() alertColor: AlertColor;
  @Input() alertPosition: 'LEFT' | 'TOP';
  @Input() rounded = true;
  @Input() showErrorMessage: boolean;
  @Input() hideErrorBlocks: boolean;
  @Input() allowOverflow = false;

  @ViewChild('content') contentContainer: ElementRef;

  protected readonly AlertColor = AlertColor;

  collapseState: 'expand' | 'expandAnimated' | 'collapse' | 'collapseAnimated' =
    'expand';
  opacityState: 'show' | 'hide' = 'show';
  minHeight = null;

  constructor(
    private readonly cdRef: ChangeDetectorRef,
    private readonly elementRef: ElementRef
  ) {}

  hideContent() {
    this.opacityState = 'hide';
    this.cdRef.detectChanges();
  }

  showContent() {
    this.opacityState = 'show';
    this.cdRef.detectChanges();
  }

  collapseContent(withAnimation = true) {
    this.collapseState = withAnimation ? 'collapseAnimated' : 'collapse';
    this.cdRef.detectChanges();
  }

  expandContent(withAnimation = true) {
    this.collapseState = withAnimation ? 'expandAnimated' : 'expand';
    this.cdRef.detectChanges();
  }

  setMinHeight(value: number) {
    this.minHeight = value !== null && value >= 0 ? value + 'px' : null;
    this.cdRef.detectChanges();
  }

  getContentHeight(): number {
    return this.contentContainer.nativeElement.scrollHeight;
  }
}
