import {
  Directive,
  ElementRef,
  HostListener,
  Inject,
  Input,
  OnInit,
  OnDestroy,
  Renderer2,
  OnChanges
} from '@angular/core';
import { ContentUtilService } from '../content/content-util.service';
import { POPUP_UTIL_SERVICE, PopupUtilService } from '../popup-util-service';
import { ContentService } from './content.service';
import { Router } from '@angular/router';
import { isObservable, Subscription } from 'rxjs';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[coContent]'
})
export class ContentDirective implements OnInit, OnDestroy, OnChanges {
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input() coContent: string;
  @Input() coContentSubst: string | number;

  private hasTileLink: boolean;
  private hasTopicLink: boolean;
  private substitutionValues: string[];
  private subscription: Subscription;

  private static getState(click: string, startPos: number) {
    const endPos: number = click.lastIndexOf("')");
    return click.slice(startPos, endPos);
  }

  constructor(
    private renderer: Renderer2,
    private el: ElementRef,
    private contentUtilService: ContentUtilService,
    private contentService: ContentService,
    @Inject(POPUP_UTIL_SERVICE) private popupUtil: PopupUtilService,
    private router: Router
  ) {}

  ngOnInit() {
    this.contentService.languageState.subscribe(() => {
      this.updateContent();
    });
  }

  ngOnChanges() {
    this.updateContent();
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  private updateContent() {
    let content: string;
    if (this.coContentSubst) {
      this.substitutionValues = this.coContentSubst.toString().split('|');
      content = this.contentUtilService.getContent(
        this.coContent,
        this.substitutionValues
      );
    } else {
      content = this.contentUtilService.getContent(this.coContent);
    }
    this.hasTileLink = content?.includes("navigateToUrl('");
    this.hasTopicLink = content?.includes("popupTopicFromContent('");

    // for inspecting in the browser
    this.renderer.setAttribute(
      this.el.nativeElement,
      'content-id',
      this.coContent
    );

    this.el.nativeElement.innerHTML = content;
  }

  @HostListener('click', ['$event.target'])
  onClick(currentTarget: { attributes: { [x: string]: any } }) {
    if (this.hasTileLink || this.hasTopicLink) {
      const node = currentTarget.attributes['(click)'];
      if (node) {
        if (this.hasTileLink) {
          this.navigateToUrl(node.nodeValue);
        }
        if (this.hasTopicLink) {
          this.popupTopicFromContent(node.nodeValue);
        }
      }
    }
  }

  private navigateToUrl(click: string) {
    const startPos: number =
      click.indexOf("navigateToUrl('") + "navigateToUrl('".length;
    const endPos: number = click.lastIndexOf("')");
    const target: string = click.slice(startPos, endPos);

    if (target.indexOf('forbehold.') !== -1) {
      // der skal poppes naer der linkes direkte til en forbehold underside
      return this.popupUtil.popupForbehold(target);
    } else if (target.indexOf('/forbehold') !== -1) {
      return this.popupUtil.popupPreconditions(target);
    }
    this.router.navigate([target]);
  }

  private popupTopicFromContent(click: string) {
    const startPos: number =
      click.indexOf("popupTopicFromContent('") +
      "popupTopicFromContent('".length;
    const state = ContentDirective.getState(click, startPos);

    return this.popupUtil.popupArticle(state);
  }
}
