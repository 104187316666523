<h3 [coContent]="'DL.TR01.C01'">Tilbudsoverblik</h3>
<ng-container *ngIf="overview$ | async as overview; else loading">
  <ng-container *ngIf="advisorOptions$ | async as advisorOptions; else loading">
    <co-new-business-offer-overview
      [overview]="overview"
      [advisorOptions]="advisorOptions"
      [savePaymentsFail]="savePaymentsFail"
      (savePayments)="onSavePayments(overview, $event)"
      (saveVoluntaryPayment)="onSaveVoluntaryPayment($event)"
      [switchHsFail]="switchHsFail"
      (partialSchemeSelected)="onPartialSchemeSelected($event)"
      [saveIndbetalingSikringFail]="saveIndbetalingSikringFail"
      (saveIndbetalingSikring)="onIndbetalingSikringSelected($event)"
    ></co-new-business-offer-overview>
    <div>
      <button
        mat-raised-button
        class="float--left button-primary--level3"
        (click)="close()"
        [disabled]="!!overview.indregningFejl"
        [coContent]="'DL.TR01.C105'"
      ></button>
      <button
        mat-raised-button
        class="float--right"
        (click)="navigateToReleaseOffer(overview)"
        [disabled]="
          overview.henstand ||
          overview.harHealthCase ||
          !!overview.indregningFejl ||
          !checkMinIndbetalingTilOpsparing(overview, advisorOptions)
        "
        [coContent]="'DL.TR01.C99'"
      ></button>
      <div class="cf"></div>
    </div>
    <co-warning *ngIf="overview.henstand && overview.policyType === 'NEW'">
      <span [coContent]="'DL.TR01.C102'"></span>
    </co-warning>
    <co-warning *ngIf="overview.harHealthCase">
      <span [coContent]="'DL.TR01.C343'"></span>
    </co-warning>
    <co-warning
      *ngIf="!checkMinIndbetalingTilOpsparing(overview, advisorOptions)"
    >
      <span [coContent]="'DL.TR01.C383'"></span>
    </co-warning>
    <co-warning *ngIf="overview.indregningFejl">
      <span
        [coContent]="'DL.TR01.C512'"
        [coContentSubst]="overview.indregningFejl"
      ></span>
    </co-warning>
    <co-warning *ngIf="errorMessage">
      <span>{{ errorMessage }}</span>
    </co-warning>
  </ng-container>
</ng-container>
<ng-template #loading>
  <co-loading
    [show]="true"
    class="loading--fixed-height loading--left-aligned"
  ></co-loading>
</ng-template>
