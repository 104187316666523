import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { FinancialSituationApiService } from '@pfa/api';
import { OekonomiDetaljer } from '@pfa/gen';

@Injectable({
  providedIn: 'root'
})
export class FinancialSituationResolver implements Resolve<OekonomiDetaljer> {
  constructor(
    private financialSituationApiService: FinancialSituationApiService
  ) {}

  resolve():
    | OekonomiDetaljer
    | Observable<OekonomiDetaljer>
    | Promise<OekonomiDetaljer> {
    return this.financialSituationApiService.get();
  }
}
