<svg
  id="Layer_1"
  data-name="Layer 1"
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 98.42 94"
>
  <g id="House-3">
    <path
      d="M13.97,48.44H58.65c3.42,0,6.19,2.77,6.19,6.19v28.54c0,3.42-2.77,6.19-6.19,6.19H7.91c-.08,0-.14-.06-.14-.14V54.63c0-3.42,2.77-6.19,6.19-6.19h0Z"
      fill="#fff"
    />
  </g>
  <g id="Door">
    <rect
      x="17.02"
      y="63.2"
      width="14.06"
      height="26.15"
      rx="2.93"
      ry="2.93"
      fill="#333"
    />
  </g>
  <g id="Window">
    <rect
      x="39.44"
      y="63.2"
      width="14.06"
      height="14.29"
      rx="2.93"
      ry="2.93"
      fill="#c26666"
    />
  </g>
  <g id="Door_window" data-name="Door window">
    <rect
      x="20.29"
      y="66.52"
      width="7.53"
      height="7.65"
      rx="1.57"
      ry="1.57"
      fill="#c26666"
    />
  </g>
  <g id="Cornice">
    <path
      d="M58.65,48.06H13.97c-3.42,0-6.19,2.77-6.19,6.19v7.44h57.06v-7.44c0-3.42-2.77-6.19-6.19-6.19Z"
      fill="#ccc"
    />
  </g>
  <g id="Roof">
    <path
      d="M65.12,59.14H7.5c-3.97,0-6.91-2.99-6.06-6.12l5.21-19.12c.93-2.51,3.39-4.11,6.06-3.94H59.9c2.67-.16,5.13,1.44,6.05,3.94l5.23,19.12c.85,3.14-2.1,6.12-6.06,6.12Z"
    />
  </g>
  <g id="Attic">
    <path
      d="M43.17,52.53h-13.74c-.67,0-1.21-.54-1.21-1.21h0v-9.41c0-.41,.21-.79,.55-1.01l6.88-4.48c.4-.26,.92-.26,1.32,0l6.87,4.48c.34,.22,.55,.6,.55,1.01v9.42c0,.67-.55,1.2-1.22,1.19Z"
      fill="#fff"
    />
  </g>
  <g id="Top_window" data-name="Top window">
    <rect
      x="31.87"
      y="41.76"
      width="8.83"
      height="8.97"
      rx="1.84"
      ry="1.84"
      fill="#c26666"
    />
  </g>
  <g id="Bush">
    <path
      d="M75.15,81.95c.02-5.84-4.71-10.59-10.55-10.6-3.72-.01-7.18,1.94-9.09,5.13-5.25-1.03-10.34,2.39-11.37,7.64-.36,1.81-.19,3.69,.49,5.41h27.33c2.04-1.99,3.2-4.72,3.2-7.57Z"
      fill="#348186"
    />
  </g>
  <g id="Chimney">
    <rect
      x="17.1"
      y="23"
      width="9.08"
      height="10.52"
      rx="2.64"
      ry="2.64"
      fill="#fff"
    />
  </g>
  <path
    d="M96.65,24.43h-18.01c-.71,0-1.29-.58-1.29-1.29V4.41c0-2.75-1.08-3.03-2.4-.61l-8.33,15.27-9.7,17.78c-.57,1.04-.06,1.88,1.12,1.88h18.01c.71,0,1.29,.58,1.29,1.29v18.73c0,2.75,1.08,3.03,2.4,.61l8.33-15.26,9.7-17.78c.56-1.04,.06-1.88-1.12-1.88h0Z"
    fill="#ad3333"
  />
</svg>
