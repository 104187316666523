/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const StatusHealthCaseSchema = JoiObj.string().valid(
        'AFSLUTTET'
        ,
        'ANNULLERET'
        ,
        'AUTO_BEHANDLING'
        ,
        'MANUEL_BEHANDLING'
        ,
        'SYSTEMFEJL'
        ,
        'UDKAST_MITPFA'
        
    )
.id('StatusHealthCaseSchema')

export type StatusHealthCase = 'AFSLUTTET' | 'ANNULLERET' | 'AUTO_BEHANDLING' | 'MANUEL_BEHANDLING' | 'SYSTEMFEJL' | 'UDKAST_MITPFA';

export const StatusHealthCase = {
    Afsluttet: 'AFSLUTTET' as StatusHealthCase,
    Annulleret: 'ANNULLERET' as StatusHealthCase,
    AutoBehandling: 'AUTO_BEHANDLING' as StatusHealthCase,
    ManuelBehandling: 'MANUEL_BEHANDLING' as StatusHealthCase,
    Systemfejl: 'SYSTEMFEJL' as StatusHealthCase,
    UdkastMitpfa: 'UDKAST_MITPFA' as StatusHealthCase
};

