<svg
  id="Layer_1"
  data-name="Layer 1"
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 69 54"
>
  <g id="Tail">
    <rect
      x="46.22"
      y="21.03"
      width="3.47"
      height="5.12"
      rx="1.74"
      ry="1.74"
      fill="#d69999"
      stroke-width="0"
    />
  </g>
  <g id="Snout">
    <rect
      x="2.22"
      y="24.19"
      width="7.56"
      height="10"
      rx="2.14"
      ry="2.14"
      fill="#c26666"
      stroke-width="0"
    />
  </g>
  <g id="Front_left_leg" data-name="Front left leg">
    <path
      d="m15.12,40.78l-2.23,6.31c-.14.41.07.85.48.99.08.03.17.04.26.04h5.3c.25,0,.49-.12.64-.33l4.31-6.12c.25-.35.17-.83-.17-1.08-.13-.09-.28-.14-.44-.15l-7.38-.19c-.34-.01-.65.2-.76.52Z"
      fill="#900"
      stroke-width="0"
    />
  </g>
  <g id="Back_left_leg" data-name="Back left leg">
    <path
      d="m38.86,41.22l2.1,5.96c.13.38-.07.8-.45.94-.08.03-.16.04-.24.04h-4.99c-.24,0-.46-.12-.6-.31l-4.06-5.78c-.23-.33-.15-.79.18-1.02.12-.08.26-.13.4-.13l6.96-.18c.32,0,.61.19.71.49Z"
      fill="#900"
      stroke-width="0"
    />
  </g>
  <g id="Body">
    <ellipse
      cx="27.29"
      cy="28.75"
      rx="21"
      ry="17.72"
      fill="#c26666"
      stroke-width="0"
    />
  </g>
  <g id="Ear">
    <path
      d="m16.93,13.35s-1.83,7.68-4.92,5.41c-1.61-1.18-4.27-6.63-4.02-7.72s5.5-1.43,8.94,2.31Z"
      fill="#d69999"
      stroke-width="0"
    />
  </g>
  <g id="Eye">
    <circle cx="12.79" cy="23.21" r="1.63" stroke-width="0" />
  </g>
  <g id="Back_right_leg" data-name="Back right leg">
    <path
      d="m32.81,42.18c-.08-.14-.11-.29-.11-.45h0c0-1.21,2.17-2.19,4.84-2.19,2.11,0,3.9.61,4.56,1.45.07.08.13.16.16.26.04.07.06.15.08.23l2.33,6.61c.15.44-.08.91-.51,1.07-.09.03-.18.05-.28.05h-5.71c-.27,0-.53-.14-.69-.36l-4.44-6.32c-.1-.11-.18-.23-.25-.36Z"
      fill="#d69999"
      stroke-width="0"
    />
  </g>
  <g id="Front_right_leg" data-name="Front right leg">
    <path
      d="m26.28,42.18c.08-.14.11-.29.11-.45h0c0-1.21-2.17-2.19-4.84-2.19-2.11,0-3.9.6-4.56,1.45-.07.08-.13.16-.17.26-.04.07-.06.15-.08.23l-2.33,6.61c-.15.44.07.91.51,1.07.09.03.18.05.28.05h5.71c.27,0,.53-.13.68-.35l4.45-6.31c.1-.11.19-.23.25-.36Z"
      fill="#d69999"
      stroke-width="0"
    />
  </g>
  <path
    d="m32.85,14.39c.3,0,.58-.2.66-.51.1-.36-.12-.74-.48-.84-3.59-.97-7.39-.97-10.99,0-.37.1-.58.47-.48.84.1.36.47.58.84.48,3.37-.9,6.92-.9,10.28,0,.06.02.12.02.18.02Z"
    fill="#900"
    stroke-width="0"
  />
  <g id="Highlight">
    <ellipse
      cx="27.31"
      cy="16.6"
      rx="3.57"
      ry="1.39"
      fill="#d69999"
      stroke-width="0"
    />
  </g>
  <g id="Coin-6">
    <circle cx="26.54" cy="8.43" r="5.84" fill="#e3e3e3" stroke-width="0" />
  </g>
  <path
    d="m26.54,14.77c-3.5,0-6.34-2.85-6.34-6.34s2.85-6.34,6.34-6.34,6.34,2.85,6.34,6.34-2.85,6.34-6.34,6.34Zm0-11.67c-2.94,0-5.33,2.39-5.33,5.33s2.39,5.33,5.33,5.33,5.33-2.39,5.33-5.33-2.39-5.33-5.33-5.33Z"
    fill="#999"
    stroke-width="0"
  />
  <path
    d="m24.51,6.63l-.23,1.11,1.53-1.05c.07-.05.13-.09.18-.11.05-.02.11-.02.18,0,.09.02.16.06.21.13.05.07.06.14.04.22-.02.09-.09.18-.2.25l-.93.57.63,1.47c.04.1.08.19.09.26.02.07.02.14,0,.2-.02.07-.05.13-.12.17-.07.04-.15.05-.24.03-.09-.02-.16-.05-.21-.1s-.08-.11-.11-.18c-.03-.07-.05-.14-.07-.19l-.51-1.32-.63.39-.14.65c-.03.13-.08.22-.15.28-.07.05-.16.07-.25.05-.05-.01-.1-.04-.15-.08-.04-.04-.07-.09-.09-.14-.01-.04-.01-.09,0-.15,0-.05.02-.13.04-.23l.49-2.35c.03-.13.08-.22.15-.27.07-.05.15-.07.25-.05.1.02.17.07.21.15.05.08.06.18.03.31Z"
    fill="#666"
    stroke-width="0"
  />
  <path
    d="m27.62,8.82l-.22-.05-.21,1.01c-.03.13-.08.23-.15.28-.07.05-.16.07-.25.05-.1-.02-.17-.07-.22-.15-.04-.08-.05-.18-.02-.31l.51-2.43c.03-.14.08-.23.16-.28.07-.05.18-.06.32-.03l1.04.22c.14.03.26.06.36.1.1.03.19.08.26.13.09.06.17.14.23.23.06.09.1.2.12.31.02.11.01.22,0,.34-.05.24-.16.41-.32.52-.16.11-.39.17-.67.17.1.08.19.2.27.34.08.14.14.28.2.43.05.15.09.28.11.4.02.12.03.19.02.24,0,.04-.03.08-.07.12-.04.04-.08.06-.13.07-.05.01-.11.01-.17,0-.07-.02-.13-.04-.17-.09-.04-.04-.07-.1-.1-.15s-.06-.15-.1-.26l-.17-.48c-.06-.18-.12-.31-.17-.41-.06-.1-.12-.17-.19-.21-.07-.05-.16-.08-.27-.1Zm.65-1.25l-.59-.12-.18.87.57.12c.15.03.29.05.4.04.11,0,.2-.03.27-.09.07-.05.11-.13.14-.25.02-.09.01-.17-.02-.25-.03-.08-.08-.14-.15-.19-.07-.05-.21-.09-.43-.14Z"
    fill="#666"
    stroke-width="0"
  />
  <path
    d="m55.93,45.11c-.52,0-.95.36-.95.8v.13c0,.44.43.8.95.8h6.53v-1.73h-6.53Z"
    fill="#999"
    stroke-width="0"
  />
  <path
    d="m55.93,47.49c-.52,0-.95.36-.95.8v.13c0,.44.43.8.95.8h6.53v-1.73h-6.53Z"
    fill="#999"
    stroke-width="0"
  />
  <path
    d="m64.6,45.11h-2.13v1.73h2.13c.52,0,.95-.36.95-.8v-.13c0-.44-.43-.8-.95-.8Z"
    fill="#666"
    stroke-width="0"
  />
  <path
    d="m64.6,47.49h-2.13v1.73h2.13c.52,0,.95-.36.95-.8v-.13c0-.44-.43-.8-.95-.8Z"
    fill="#666"
    stroke-width="0"
  />
  <path
    d="m55.93,40.26c-.52,0-.95.36-.95.8v.13c0,.44.43.8.95.8h6.53v-1.73h-6.53Z"
    fill="#999"
    stroke-width="0"
  />
  <path
    d="m55.93,42.64c-.52,0-.95.36-.95.8v.13c0,.44.43.8.95.8h6.53v-1.73h-6.53Z"
    fill="#999"
    stroke-width="0"
  />
  <path
    d="m64.6,40.26h-2.13v1.73h2.13c.52,0,.95-.36.95-.8v-.13c0-.44-.43-.8-.95-.8Z"
    fill="#666"
    stroke-width="0"
  />
  <path
    d="m64.6,42.64h-2.13v1.73h2.13c.52,0,.95-.36.95-.8v-.13c0-.44-.43-.8-.95-.8Z"
    fill="#666"
    stroke-width="0"
  />
  <path
    d="m33.31,15.77c.3,0,.58-.2.66-.51.1-.36-.12-.74-.48-.84-3.59-.97-7.39-.97-10.99,0-.37.1-.58.47-.48.84.1.36.47.58.84.48,3.37-.9,6.92-.9,10.28,0,.06.02.12.02.18.02Z"
    fill="#c26666"
    stroke-width="0"
  />
</svg>
