import { Injectable } from '@angular/core';
import {
  CategoryMenuItemName,
  Menu,
  MenuItem,
  MenuItemType,
  SubMenuItem,
  SubMenuItemName
} from './menu.model';

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  private readonly menu = new Menu();

  setSelectedMenuItem(url: string) {
    this.menu.setSelectedMenuAndSubMenuByUrl(url);
  }

  getSavings(): MenuItem {
    return this.menu.getSavings();
  }

  getAdvantages(): MenuItem {
    return this.menu.getAdvantages();
  }

  getInsurance() {
    return this.menu.getInsurance();
  }

  getProfile() {
    return this.menu.getProfile();
  }

  getMessages() {
    return this.menu.getMessages();
  }

  getNewBusinessAdvisory() {
    return this.menu.getNewBusinessAdvisory();
  }

  getMobileMenu() {
    return this.menu.getMobileMenu();
  }

  setQuantityOnName(
    menuItem: MenuItem,
    subMenuItem: SubMenuItemName,
    value: number
  ) {
    this.menu.setQuantityOnName(menuItem, subMenuItem, value);
  }

  showSubMenuItem(menuItem: MenuItem, subMenuItemName: SubMenuItemName) {
    this.menu.showSubMenuItem(menuItem, subMenuItemName);
  }

  showMenuItem(menuItem: MenuItem, categoryMenuItemName: CategoryMenuItemName) {
    this.menu.showMenuItem(menuItem, categoryMenuItemName);
  }

  closeMenuItems() {
    this.menu.closeMenuItems();
  }

  openCloseMenu(menuItemType: MenuItemType) {
    this.menu.openCloseMenu(menuItemType);
  }

  isMenuSelected(menuItemType: MenuItemType) {
    return this.menu.selectedMenuItem === menuItemType;
  }

  getSelectedMenuItem(): SubMenuItem | undefined {
    return this.menu.selectedSubMenuItem;
  }

  getMenuItemOpen(): MenuItemType {
    return this.menu.getMenuItemOpen();
  }

  hideSubMenuItem(
    menuItemType: MenuItemType,
    subMenuItemName: SubMenuItemName
  ) {
    this.menu.hideSubMenuItem(menuItemType, subMenuItemName);
  }
}
