/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SprogType } from './sprogType';
import { SprogTypeSchema } from './sprogType';
import * as JoiObj from "joi";


    export const ReminderEmailSchema = JoiObj.object({
        date:
            
                    JoiObj.object()
                


.required()


        ,
        email:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        languageCode:
            
        JoiObj.link('#SprogTypeSchema')


.required()


        ,
        paymentRequestTimestamp:
            
                    JoiObj.object()
                


.required()


        
    })
                .shared(SprogTypeSchema)
.id('ReminderEmailSchema')

export interface ReminderEmail { 
    date: object;
    email: string;
    languageCode: SprogType;
    paymentRequestTimestamp: object;
}
export namespace ReminderEmail {
}


