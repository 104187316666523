/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { StatusOrder } from './statusOrder';
import { StatusOrderSchema } from './statusOrder';
import * as JoiObj from "joi";


    export const PFAPensionProductOfferOrderElementSchema = JoiObj.object({
        guid:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        statusDate:
            
            JoiObj.date()


.required()


        ,
        statusOrder:
            
        JoiObj.link('#StatusOrderSchema')


.required()


        
    })
                .shared(StatusOrderSchema)
.id('PFAPensionProductOfferOrderElementSchema')

export interface PFAPensionProductOfferOrderElement { 
    guid: string;
    statusDate: Date;
    statusOrder: StatusOrder;
}
export namespace PFAPensionProductOfferOrderElement {
}


