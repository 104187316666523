<div class="title-wrapper">
  <h4 class="title" coContent="DL.KO01.C298"></h4>
  <p>{{ brokerInfo.navn }}</p>
</div>

<div class="contact">
  <h4 class="title">&nbsp;</h4>
  <a
    *ngIf="brokerInfo.telefon"
    [href]="'tel:' + brokerInfo.telefon"
    class="action phone"
    (click)="phoneClicked()"
  >
    <co-icon-phone class="icon"></co-icon-phone>
    <span>{{ brokerInfo.telefon }}</span>
  </a>
  <a
    *ngIf="brokerInfo.email"
    [href]="'mailto:' + brokerInfo.email"
    class="action"
    (click)="writeClicked()"
  >
    <co-icon-envelope class="icon"></co-icon-envelope>
    <span coContent="DL.KO01.C332"></span>
  </a>

  <a
    [routerLink]="'/kontakt'"
    coContent="DL.MF.C26"
    class="action link"
    (click)="contactClicked()"
  ></a>
</div>
<div class="address">
  <h4 class="title" coContent="DL.KO01.C327"></h4>
  <p coContent="DL.KO01.C328"></p>
</div>
