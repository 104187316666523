<svg
  version="1.1"
  id="Layer_1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  x="0px"
  y="0px"
  viewBox="0 0 214 245"
  style="enable-background: new 0 0 214 245"
  xml:space="preserve"
>
  <style type="text/css">
    .st0 {
      fill: #e5e5e5;
    }
    .st1 {
      fill: #5b0000;
    }
    .st2 {
      fill: #990000;
    }
    .st3 {
      fill: #7a0000;
    }
  </style>
  <g id="Layer_1_00000150785689127341000350000014166226121493475727_"></g>
  <g id="Layer_2_00000000205424232591444520000007470696260619132039_">
    <path
      class="st0"
      d="M173.1,240.5c0,1.4-48.4,2.5-85,2.5s-47.7-1.1-47.7-2.5s29.7-2.5,66.3-2.5S173.1,239.1,173.1,240.5
		L173.1,240.5z"
    />
    <g>
      <path
        class="st1"
        d="M110.7,211.5l-3.2,3.2c-0.4,0.4-1.1,0.4-1.5,0l-3.2-3.2"
      />
      <path
        class="st1"
        d="M102.9,218.1c-0.5-1.3,3.9-3.9,3.9-3.9s4.4,2.6,3.9,3.9"
      />
    </g>
    <circle class="st2" cx="106.8" cy="106.5" r="105.5" />
    <path
      class="st3"
      d="M106.8,195.9C51.2,195.9,5.7,153,1.6,98.5c-0.2,2.7-0.3,5.3-0.3,8C1.3,165.1,48.5,212,106.8,212
		s105.5-47.3,105.5-105.5c0-2.7-0.1-5.4-0.3-8C207.9,153,162.4,196,106.8,195.9L106.8,195.9z"
    />
  </g>
</svg>
