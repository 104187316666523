<mitpfa-onboarding-header
  *ngIf="initialized"
  class="header"
  [levelReached]="levelReached"
>
</mitpfa-onboarding-header>

<co-alert *ngIf="error" class="full-page alert-center">
  <h3 [coContent]="'DL.AP02.C19'"></h3>
  <p [coContent]="'DL.AP02.C20'"></p>
  <button
    [routerLink]="'/'"
    coContent="DL.VK01.C45"
    mat-raised-button
    class="button-secondary spacing-unit--over"
  ></button>
</co-alert>
<div
  *ngIf="initialized && !error"
  class="spacing-unit--over wrapper"
  [ngClass]="{
    'fill-all':
      levelReached.level === 'DONE' ||
      levelReached.level === 'EXTERNAL_TRANSFER_ACCEPT' ||
      levelReached.level === 'EXTERNAL_TRANSFER_CONSENT' ||
      levelReached.level === 'INVESTMENT' ||
      levelReached.level === 'INSURANCE',
    welcome: levelReached.level === 'WELCOME'
  }"
>
  <mitpfa-onboarding-welcome
    [@fadeInOut]
    *ngIf="levelReached.level === 'WELCOME'"
    [pensionCustomer]="pensionCustomer"
    [resumeFlow]="isFlowResumed"
    [isUpdateError]="isUpdateError"
    (start)="saveStep()"
  >
  </mitpfa-onboarding-welcome>
  <mitpfa-onboarding-introduction-carousel
    [@fadeInOut]
    *ngIf="levelReached.level === 'INTRODUCTION'"
    (goTo)="saveStep()"
  >
  </mitpfa-onboarding-introduction-carousel>
  <mitpfa-onboarding-step-contact
    [@fadeInOut]
    *ngIf="levelReached.level === 'STEP_CONTACT'"
    (goTo)="saveStep()"
  >
  </mitpfa-onboarding-step-contact>
  <mitpfa-onboarding-contact
    [@fadeInOut]
    *ngIf="levelReached.level === 'CONTACT_DATA'"
    [pensionCustomer]="pensionCustomer"
    [isUpdateError]="isUpdateError"
    (form)="setFooterForm($event)"
    (goTo)="saveStep()"
  >
  </mitpfa-onboarding-contact>
  <mitpfa-onboarding-consent
    [@fadeInOut]
    *ngIf="levelReached.level === 'CONSENT'"
    [pensionCustomer]="pensionCustomer"
    [isUpdateError]="isUpdateError"
    (goTo)="saveStep()"
  >
  </mitpfa-onboarding-consent>
  <mitpfa-onboarding-step-finances
    [@fadeInOut]
    *ngIf="levelReached.level === 'STEP_FINANCES'"
    (goTo)="saveStep()"
  >
  </mitpfa-onboarding-step-finances>
  <mitpfa-ayr-pensionsinfo
    [@fadeInOut]
    *ngIf="levelReached.level === 'PI_DATA'"
    [pensionCustomer]="pensionCustomer"
    [isUpdateError]="isUpdateError"
    (goTo)="saveStep()"
  >
  </mitpfa-ayr-pensionsinfo>
  <mitpfa-ayr-external-transfer
    [@fadeInOut]
    *ngIf="levelReached.level === 'EXTERNAL_TRANSFER'"
    [pensionCustomer]="pensionCustomer"
    (goTo)="combineSavingsNextStep(undefined)"
  >
  </mitpfa-ayr-external-transfer>
  <mitpfa-combine-savings-intro
    [@fadeInOut]
    *ngIf="levelReached.level === 'EXTERNAL_TRANSFER_INTRO'"
    [welcomeflow]="true"
    (next)="combineSavingsNextStep(undefined)"
  >
  </mitpfa-combine-savings-intro>
  <mitpfa-combine-savings-accept-transfer
    [@fadeInOut]
    *ngIf="levelReached.level === 'EXTERNAL_TRANSFER_ACCEPT'"
    (next)="combineSavingsNextStep($event)"
    [combineSavingsFlowType]="'ACTIVATE_YOUR_RECOMMENDATION_FLOW'"
    [externalTransferData]="combineSavingService.externalTransfer"
    [pensionCustomer]="pensionCustomer"
  ></mitpfa-combine-savings-accept-transfer>
  <mitpfa-combine-savings-consent
    [@fadeInOut]
    *ngIf="levelReached.level === 'EXTERNAL_TRANSFER_CONSENT'"
    (next)="combineSavingsNextStep($event)"
    [broker]="combineSavingService.broker"
    [combineSavingsFlowType]="'ACTIVATE_YOUR_RECOMMENDATION_FLOW'"
    [externalTransferData]="combineSavingService.externalTransfer"
  >
  </mitpfa-combine-savings-consent>
  <mitpfa-combine-savings-transfer-lead
    [@fadeInOut]
    *ngIf="levelReached.level === 'EXTERNAL_TRANSFER_LEAD'"
    (next)="combineSavingsNextStep($event)"
    [hideBackButton]="true"
    [isOnboarding]="true"
    [broker]="combineSavingService.broker"
    [externalTransferLeadType]="'ADP_EKSTOVERFOERSEL'"
  ></mitpfa-combine-savings-transfer-lead>
  <mitpfa-combine-savings-summary
    [@fadeInOut]
    *ngIf="levelReached.level === 'EXTERNAL_TRANSFER_SUMMARY'"
    [combineSavingsSummary]="currentCombineSavingsSummaryType"
    [externalTransferData]="combineSavingService.externalTransfer"
    (goToNextPage)="combineSavingsNextStep($event)"
  ></mitpfa-combine-savings-summary>
  <mitpfa-onboarding-step-investment
    [@fadeInOut]
    *ngIf="levelReached.level === 'STEP_INVESTMENT'"
    (goTo)="saveStep()"
  >
  </mitpfa-onboarding-step-investment>
  <mitpfa-onboarding-investment
    [@fadeInOut]
    class="limitScreen"
    *ngIf="levelReached.level === 'INVESTMENT'"
    [pensionCustomer]="pensionCustomer"
    (percentCompleted)="investmentStepperUpdate($event)"
    (goTo)="saveStep()"
  >
  </mitpfa-onboarding-investment>
  <mitpfa-onboarding-step-insurance
    [@fadeInOut]
    *ngIf="levelReached.level === 'STEP_INSURANCE'"
    (goTo)="saveStep()"
  >
  </mitpfa-onboarding-step-insurance>
  <mitpfa-onboarding-insurance
    [@fadeInOut]
    class="limitScreen"
    *ngIf="levelReached.level === 'INSURANCE'"
    (percentCompleted)="insuranceStepperUpdate($event)"
    (goTo)="saveStep()"
    (updateFooter)="showJumpOver()"
  >
  </mitpfa-onboarding-insurance>
  <mitpfa-onboarding-step-end
    [@fadeInOut]
    *ngIf="levelReached.level === 'STEP_END'"
    (goTo)="saveStep()"
  >
  </mitpfa-onboarding-step-end>
  <mitpfa-onboarding-done
    [@fadeInOut]
    *ngIf="levelReached.level === 'DONE'"
    (goTo)="done()"
  >
  </mitpfa-onboarding-done>
</div>

<mitpfa-onboarding-footer
  *ngIf="
    initialized &&
    (levelReached.showBack || levelReached.showJumpover) &&
    !error
  "
  class="footer"
  [showBack]="levelReached.showBack"
  [showJumpover]="levelReached.showJumpover"
  [note]="levelReached.note"
  [form]="footerForm"
  (back)="goBack()"
  (jumpover)="jumpOver()"
>
</mitpfa-onboarding-footer>

<div
  *ngIf="initialized && levelReached.showLanguage"
  class="language--container"
>
  <mitpfa-change-language></mitpfa-change-language>
</div>
