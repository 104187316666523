<div class="customer-survey-summary-container customer-survey-container">
  <p class="text--manchet-light align--center">
    <span [coContent]="'DL.TH01.C86'"></span>
  </p>
  <div class="spacing-unit--over align--center">
    <button
      mat-raised-button
      class="button-secondary"
      type="button"
      (click)="close.emit()"
    >
      <span [coContent]="'DL.TH01.C87'"></span>
    </button>
  </div>
</div>
