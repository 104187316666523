import { combineLatest, Observable, of } from 'rxjs';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { Injectable } from '@angular/core';
import { catchError, filter, first, map, switchMap } from 'rxjs/operators';
import { LoginService } from '../login.service';
import { OnboardingService } from '../../app/onboarding/onboarding.service';
import { CookieService } from '@pfaform';
import { BrugerStore, SystemadministrationStore } from '@pfa/gen';

@Injectable()
export class DashboardGuard implements CanActivate {
  constructor(
    private readonly router: Router,
    private readonly systemadministrationStore: SystemadministrationStore,
    private readonly onboardingService: OnboardingService,
    private readonly cookieService: CookieService,
    private readonly brugerStore: BrugerStore
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | boolean {
    return combineLatest({
      user: this.brugerStore.brugerGet(),
      redirectToActivateYourRecommendation:
        this.handleActivateYourRecommendationRedirect()
    }).pipe(
      map(result => {
        const navigationState =
          this.router.getCurrentNavigation()?.extras.state;
        if (
          !navigationState?.[LoginService.InitialLoginRouteState] &&
          this.cookieService.getCookie('adaShown') &&
          !result.user?.isRaadgiver
        ) {
          return true;
        } else {
          if (
            result.redirectToActivateYourRecommendation &&
            !result.user?.isRaadgiver
          ) {
            return this.router.createUrlTree(['velkomst'], {
              queryParams: route.queryParams
            });
          } else {
            return true;
          }
        }
      })
    );
  }

  private handleActivateYourRecommendationRedirect(): Observable<boolean> {
    return this.systemadministrationStore
      .systemadministrationHentkillswitchesadminGet()
      .pipe(
        first(),
        filter(res => res.killswitchDetailsActivateYourRecommendation?.open),
        switchMap(() => this.onboardingService.getOnboardingStepsList()),
        map(activePensionList => {
          let allStepsButExternalTransferAreDone = true;
          activePensionList.items.forEach(item => {
            if (!item.checked && item.name !== 'EXTERNAL_TRANSFER') {
              allStepsButExternalTransferAreDone = false;
            }
          });
          return !allStepsButExternalTransferAreDone;
        }),
        catchError(err => of(false))
      );
  }
}
