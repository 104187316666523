export const MitIdLoginErrorMap = {
  // technical ref, page 14
  // https://broker.signaturgruppen.dk/application/files/7016/3852/2907/Nets_eID_Broker_Technical_Reference_v._1.2.2.pdf
  internal_error: 'DL.LI01.C15',
  user_aborted: '',
  no_ctx: 'DL.LI01.C15',
  // identity providers, page 11
  // https://broker.signaturgruppen.dk/application/files/1116/3878/9109/Nets_eID_Broker_Identity_Providers_v.1.2.1.pdf
  mitid_user_aborted: '',
  mitid_no_ctx: 'DL.LI01.C15',
  mitid_internal_error: 'DL.LI01.C15',
  mitid_unexpected_error: 'DL.LI01.C15',
  mitid_core_client_error: 'DL.LI01.C15',
  mitid_cpr_match_failed: 'DL.MI01.C02',
  mitid_timeout: 'DL.LI01.C28',
  mitid_auth_code_already_used: 'DL.LI01.C15',
  mitid_transfer_token_exchange_code_already_used: 'DL.LI01.C15',
  mitid_transaction_text_invalid: 'DL.LI01.C15',
  mitid_transaction_signing_flow_limited_to_signed_request: 'DL.LI01.C15',
  mitid_anti_forgery_validation_error: 'DL.LI01.C15',
  mitid_transaction_text_missing: 'DL.LI01.C15'
  // identity providers, page 22
  // https://broker.signaturgruppen.dk/application/files/1116/3878/9109/Nets_eID_Broker_Identity_Providers_v.1.2.1.pdf
  //   FBTUPD101E: 'DL.LI01.C117',
  //   FBTUPD102E: 'DL.LI01.C114',
  //   FBTUPD119E: 'DL.LI01.C138',
  //   FBTUPD119E2: 'DL.LI01.C116',
  //   FBTUPD117E: 'DL.LI01.C137',
  //   EXPIRED: 'DL.LI01.C87',
  //   'OTP-MAX-TRIES': 'DL.LI01.C128',
  //   'OTP-BAD': 'DL.LI01.C135',
  //   OTP_MOBILE_NUMBER_MISSING: 'DL.LI01.C134',
  //  'authentication-blocked': 'DL.LI01.C138'
};
