/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const FoundAllYouWantedSchema = JoiObj.string().valid(
        'YES'
        ,
        'PARTIALLY'
        ,
        'NO'
        ,
        'DIDNT_SEARCH_ANYTHING_SPECIFIC'
        
    )
.id('FoundAllYouWantedSchema')

export type FoundAllYouWanted = 'YES' | 'PARTIALLY' | 'NO' | 'DIDNT_SEARCH_ANYTHING_SPECIFIC';

export const FoundAllYouWanted = {
    Yes: 'YES' as FoundAllYouWanted,
    Partially: 'PARTIALLY' as FoundAllYouWanted,
    No: 'NO' as FoundAllYouWanted,
    DidntSearchAnythingSpecific: 'DIDNT_SEARCH_ANYTHING_SPECIFIC' as FoundAllYouWanted
};

