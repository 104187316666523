import {
  AfterViewInit,
  Component,
  DestroyRef,
  ElementRef,
  inject,
  OnInit,
  ViewChild
} from '@angular/core';
import { combineLatest } from 'rxjs';
import { Router } from '@angular/router';
import { pulse } from '@pfa/animations';
import { PensionskundeStore, SystemadministrationStore } from '@pfa/gen';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const $: any;

@Component({
  selector: 'mitpfa-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
  animations: [pulse]
})
export class ChatComponent implements OnInit, AfterViewInit {
  @ViewChild('someID', { static: false }) myDiv: ElementRef;

  private readonly pensionskundeStore: PensionskundeStore =
    inject(PensionskundeStore);
  private readonly systemadministrationStore: SystemadministrationStore =
    inject(SystemadministrationStore);
  private readonly router: Router = inject(Router);
  private readonly destroyRef: DestroyRef = inject(DestroyRef);

  private advisorQueue = {
    queueKey: 'q_chat',
    configurationId: '72006922-f415-44cf-aec0-8e9326e3900f',
    timeId: '29013_time_chat',
    visualQueueId: 48340
  };
  private healthQueue = {
    queueKey: 'q_chat_sundhed',
    configurationId: '97d38925-3ccd-4ac5-aa8d-80f446c6a4dd',
    timeId: '29979_time_chat_sundhed',
    visualQueueId: 48341
  };
  public activeQueue = this.advisorQueue;
  initialized: boolean;

  ngOnInit() {
    this.chooseQueue(this.router.url);
  }

  ngAfterViewInit() {
    combineLatest({
      killswitch:
        this.systemadministrationStore.systemadministrationHentkillswitchesadminGet(),
      pensionCustomer: this.pensionskundeStore.pensionskundeGet()
    })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: results => {
          if (results.killswitch.killswitchDetailsChat.open) {
            this.loadChat(
              results.pensionCustomer.navn,
              results.pensionCustomer.cpr
            );
            setTimeout(() => {
              this.initialized = true;
            });
          }
        }
      });
  }

  private chooseQueue(url: string) {
    if (url === '/mineforsikringer/skadesanmeldelse/pfahelbredssikring') {
      this.activeQueue.queueKey = this.healthQueue.queueKey;
    } else {
      this.activeQueue.queueKey = this.advisorQueue.queueKey;
    }
  }

  private loadChat(name: string, cpr: string) {
    $(this.myDiv.nativeElement).intelecomChat({
      /*
      mandatory parameters
      */
      customerKey: '12820',
      queueKey: this.activeQueue.queueKey,
      configurationId: this.activeQueue.configurationId,

      /*
      non-mandatory parameters
      */
      timeId: this.activeQueue.timeId, //check opening hours
      visualQueueId: this.activeQueue.visualQueueId, //checks queue,closes if no agents are logged on
      onChatStart: function () {
        $(this).intelecomChat('reloadOptions', {
          // we only change the queue for now not the configurations for time queue and so on
          queueKey: document.getElementById('queueKey').innerHTML,
          variables: {
            Navn: name,
            cpr: cpr,
            url: window.location.href
          }
        });
      }, //runs when clicking the chat button
      visualQueueExpression: function (q) {
        return q && q.AgentsLoggedIn > 0 && q.QueueSize < 5;
      },
      showStarter: false, //displays the chat starter circle
      showStarterWhenMinimized: false, //displays the chat starter circle
      draggable: false, //enables user to move window
      resizable: false, //enables user to resize window
      languageCode: 'DK', //language settings (EN, NO, SE, DK, FI, HU, BG)
      emailLogoUrl: 'http://mitpfa.dk/assets/images/logo.png',
      emailTopBackgroundColor: '#ececec'
    });
  }
}
