import {
  AttachmentType,
  ContactData,
  DynamicForm,
  FormField
} from './dynamic-form.model';
import {
  Component,
  OnInit,
  Input,
  OnChanges,
  ChangeDetectorRef,
  AfterViewInit,
  inject,
  DestroyRef
} from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
  UntypedFormBuilder,
  FormControlOptions
} from '@angular/forms';
import { ScrollToService } from '@pfaform';
import * as moment from 'moment';
import { DynamicFormService } from './dynamic-form.service';
import { DynamicFormUtilsComponent } from './dynamic-form-utils.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'co-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss']
})
export class DynamicFormComponent implements OnChanges, OnInit, AfterViewInit {
  @Input() channel: string;
  @Input() endpointHost: string;
  @Input() hideSendButton: boolean;
  @Input() formId: string;
  @Input() language: 'da' | 'en';
  @Input() title: string;
  @Input() category: string;
  @Input() contactData: ContactData;

  private cdr: ChangeDetectorRef = inject(ChangeDetectorRef);
  private readonly dynamicFormService: DynamicFormService =
    inject(DynamicFormService);
  private readonly scrollToService: ScrollToService = inject(ScrollToService);
  readonly formBuilder: UntypedFormBuilder = inject(UntypedFormBuilder);
  private readonly dynamicFormUtilsComponent: DynamicFormUtilsComponent =
    inject(DynamicFormUtilsComponent);
  private readonly destroyRef: DestroyRef = inject(DestroyRef);

  attachments: any[];
  dynamicForm: DynamicForm;
  form: UntypedFormGroup;
  contactInfoFormGroup: UntypedFormGroup;
  tekster: Map<string, string>;
  datetimepickerTexts: Map<string, string>;
  attachmentTexts: Map<string, string>;
  enableAllValidationErrors: boolean;
  submitButtonDisabled: boolean;
  formSendInProgress: boolean;
  formSentSuccess: boolean;
  formSentFailed: boolean;
  formLoadFailed: boolean;
  requiredMark = '(*)';
  private channelMyPfa: boolean;
  public showAttachment: boolean;
  public documentNumberValid = true;

  public allowedToAddFormFieldByKey = new Map<string, boolean>();
  public allowedToRemoveFormFieldByKey = new Map<string, boolean>();

  ngOnInit(): void {
    if (this.channel === 'mitpfa') {
      this.channelMyPfa = true;
    }
    this.attachments = [];
    this.setupExternalSubmitListener();
  }

  ngOnChanges(): void {
    if (this.channel !== undefined && this.form === undefined) {
      if (!this.dynamicFormService.getChannel()) {
        this.dynamicFormService.setChannel(this.channel);
      }
      if (!this.dynamicFormService.getEndpointHost()) {
        this.dynamicFormService.setEndpointHost(this.endpointHost);
      }
      this.loadFormFields();
    }
  }

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }

  contactInfoFormInit(form: UntypedFormGroup): void {
    this.contactInfoFormGroup = form;
  }

  addAttachment(file): void {
    this.attachments.push(file);
    if (this.attachments.length < 4) {
      setTimeout(() => {
        this.scrollToService.scrollTo('#attachment-scroll-id');
      }, 100);
    }
    this.documentNumberValid = this.isDocumentNumberValid();
  }

  removeAttachment(file): void {
    this.attachments.splice(this.attachments.indexOf(file), 1);
    this.documentNumberValid = this.isDocumentNumberValid();
  }

  private loadFormFields(): void {
    this.dynamicFormService
      .getForm(this.formId, this.language, this.category)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: data => {
          if (data) {
            this.tekster = data.tekster;
            this.datetimepickerTexts = data.datetimepickerTexts;
            this.attachmentTexts = data.attachmentTexts;
            if (data.fejlet) {
              this.formLoadFailed = true;
            } else {
              this.dynamicForm = data.dynamicFormConfig;
              if (
                this.dynamicForm.AttachmentOptions !== AttachmentType.NotAllowed
              ) {
                this.showAttachment = true;
                if (
                  this.dynamicForm.AttachmentOptions ===
                    AttachmentType.Required &&
                  this.dynamicForm.MinAttachmentsNumber < 1
                ) {
                  this.dynamicForm.MinAttachmentsNumber = 1;
                }
              }
              this.initializeAllowedToAddRemove();
              this.buildFormGroup();
              this.onFormChanges();
              this.evalHideExpression();
            }
          } else {
            this.formLoadFailed = true;
          }
        },
        error: () => {
          this.formLoadFailed = true;
        }
      });
  }

  private buildFormGroup(): void {
    const group: any = {};
    this.dynamicForm.FormFields.forEach((field: FormField) =>
      this.prepareFormGroup(field, group)
    );
    this.form = new UntypedFormGroup(group);
    this.cdr.detectChanges();
  }

  private prepareFormGroup(field: FormField, group?: any): void {
    if (
      field.TemplateOptions &&
      (field.Type === 'input' ||
        field.Type === 'numeric' ||
        field.Type === 'amount' ||
        field.Type === 'cpr' ||
        field.Type === 'select' ||
        field.Type === 'classification' ||
        field.Type === 'textarea' ||
        field.Type === 'datepicker' ||
        field.Type === 'datetimepicker' ||
        field.Type === 'checkbox' ||
        field.Type === 'radio' ||
        field.Type === 'cvr')
    ) {
      field.Value = field.Type === 'datepicker' ? undefined : '';
      const validators = this.buildValidators(field);
      //Don't add amount field type to validateOnBlur.
      //The amount field use the coAmount directive and blur is handled there.
      const validateOnBlur =
        field.Type === 'input' ||
        field.Type === 'numeric' ||
        field.Type === 'cpr' ||
        field.Type === 'textarea' ||
        field.Type === 'datepicker';

      if (group) {
        group[field.Key] = new UntypedFormControl(field.Value, {
          validators,
          updateOn: validateOnBlur ? 'blur' : null
        } as FormControlOptions);
      } else {
        this.form.addControl(
          field.Key,
          new UntypedFormControl(field.Value, {
            validators,
            updateOn: validateOnBlur ? 'blur' : null
          } as FormControlOptions)
        );
      }
    }
  }

  private buildValidators(field: FormField): Validators[] {
    const validators: Validators[] = [];
    if (field.TemplateOptions?.Required) {
      validators.push(Validators.required);
    }
    if (field.TemplateOptions?.Min) {
      validators.push(Validators.min(field.TemplateOptions.Min));
    }
    if (field.TemplateOptions?.Max) {
      validators.push(Validators.max(field.TemplateOptions.Max));
    }
    if (field.TemplateOptions?.MinLength) {
      validators.push(Validators.minLength(field.TemplateOptions.MinLength));
    }
    if (field.TemplateOptions?.MaxLength) {
      validators.push(Validators.maxLength(field.TemplateOptions.MaxLength));
    }
    //Don't use pattern from SKO on amount field type.
    //The amount field use the coAmount directive which changes the format of the value before the validator is inworked.
    if (field.Type === 'amount') {
      validators.push(Validators.pattern('^[-]?[0-9]*([.][0-9]{0,2})?$'));
    } else {
      if (field.TemplateOptions?.Pattern) {
        validators.push(Validators.pattern(field.TemplateOptions.Pattern));
      }
    }
    return validators;
  }

  private onFormChanges(): void {
    this.form.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.evalHideExpression();
        this.cdr.detectChanges();
      });
  }

  public evalHideExpression(): void {
    if (
      this.dynamicForm &&
      this.dynamicForm.FormFields &&
      this.form &&
      this.form.controls
    ) {
      const argNames = Object.keys(this.form.controls);
      const argVal: any[] = [];
      argNames.forEach(key => {
        argVal.push(this.form.get(key)?.value);
      });
      this.dynamicForm.FormFields.forEach(formField => {
        if (formField.HideExpression) {
          try {
            const func = Function(
              ...argNames,
              `return ${formField.HideExpression.replace(';', '')};`
            );
            const result = func.apply(this.form, argVal);
            formField.Hide = result;
          } catch {}
        }
      });
    }
  }

  public doSubmitForm(): void {
    this.enableAllValidationErrors = true;
    this.documentNumberValid = this.isDocumentNumberValid();
    if (
      this.form.valid &&
      !this.formSendInProgress &&
      this.documentNumberValid &&
      this.isContactInfoValid()
    ) {
      this.form.disable();
      this.formSendInProgress = true;
      this.submitButtonDisabled = true;
      this.formSentSuccess = false;
      this.formSentFailed = false;
      this.populateDynamicFormFieldValues();

      const dynamicFormFields = this.dynamicForm.FormFields.filter(
        formField => formField.Key !== 'Title' && formField.Key !== 'Paragraph'
      );
      this.dynamicFormService
        .sendDynamicForm(
          dynamicFormFields,
          this.attachments,
          this.getFormId(),
          this.title,
          this.category,
          this.contactInfoFormGroup ? this.contactInfoFormGroup.value : null
        )
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe({
          next: data => {
            this.formSendInProgress = false;
            if (!data.fejlet) {
              this.formSentSuccess = true;
            } else {
              this.formSentFailed = true;
              this.form.enable();
            }
            this.dispatchSubmitFinishedEvent(data);
          },
          error: () => {
            this.formSendInProgress = false;
            this.formSentFailed = true;
            this.form.enable();
            this.dispatchSubmitFinishedEvent(null);
          }
        });
    } else {
      this.focusError();
      this.dispatchSubmitFinishedEvent({ valideringsFejl: true });
    }
  }

  private populateDynamicFormFieldValues(): void {
    Object.keys(this.form.controls).forEach(key => {
      const correspondingDynamicFormField: FormField[] =
        this.dynamicForm.FormFields.filter(
          (formField: FormField) => formField.Key === key
        );
      if (
        correspondingDynamicFormField &&
        correspondingDynamicFormField.length > 0
      ) {
        correspondingDynamicFormField[0].Value =
          this.form.controls[key].value &&
          moment.isMoment(this.form.controls[key].value)
            ? this.form.controls[key].value.format('DD.MM.YYYY')
            : String(this.form.controls[key].value);
      }
    });

    this.dynamicForm.FormFields.forEach((formField: FormField) => {
      formField.Children = [];
    });
  }

  private getFormId(): string {
    if (this.channelMyPfa) {
      return this.formId;
    } else {
      return this.dynamicForm.FormTemplateVersionId
        ? this.dynamicForm.FormTemplateVersionId
        : this.formId;
    }
  }

  isContactInfoValid(): boolean {
    if (this.contactInfoFormGroup) {
      this.contactInfoFormGroup.updateValueAndValidity();
      return this.contactInfoFormGroup.valid;
    } else {
      return true;
    }
  }

  isDocumentNumberValid(): boolean {
    if (this.dynamicForm.AttachmentOptions !== AttachmentType.NotAllowed) {
      if (
        this.dynamicForm.MinAttachmentsNumber &&
        this.attachments.length < this.dynamicForm.MinAttachmentsNumber
      ) {
        return false;
      }
      if (
        this.dynamicForm.MaxAttachmentsNumber &&
        this.attachments.length > this.dynamicForm.MaxAttachmentsNumber
      ) {
        return false;
      }
    }
    return true;
  }

  focusError(): void {
    const invalidElements: any = document.querySelectorAll(
      'input.ng-invalid, textarea.ng-invalid, select.ng-invalid'
    );
    if (invalidElements.length > 0) {
      invalidElements[0].focus();
    }
  }

  /*The dynamic form is a web element which can be exposed externally.
  Setup a submit listener which can be invoked from an external component*/
  setupExternalSubmitListener(): void {
    this.getDynamicFormElement()?.addEventListener('dynamic-form-submit', () =>
      this.onExternalSubmitDynamicForm()
    );
  }

  dispatchSubmitFinishedEvent(data): void {
    this.getDynamicFormElement()?.dispatchEvent(
      new CustomEvent('dynamic-form-submit-finished', {
        detail: { data: data }
      })
    );
  }

  onExternalSubmitDynamicForm(): void {
    this.doSubmitForm();
  }

  getDynamicFormElement(): Element | null {
    return document.querySelector('dynamic-form')
      ? document.querySelector('dynamic-form')
      : document.querySelector('co-dynamic-form');
  }

  private initializeAllowedToAddRemove(): void {
    this.dynamicForm.FormFields.forEach((formField: FormField) => {
      formField.ParentKey = formField.Key;
      formField.Children = [];
      const isSupportedType = this.dynamicFormUtilsComponent.isSupportedType(
        formField.Type
      );

      this.allowedToAddFormFieldByKey.set(
        formField.Key,
        this.isAllowedToAddFormField(formField) && isSupportedType
      );
      this.allowedToRemoveFormFieldByKey.set(
        formField.Key,
        this.isNotParentFormField(formField) && isSupportedType
      );
    });
  }

  private isAllowedToAddFormField(formField: FormField): boolean {
    //In case it is not the parent form field, add field should never be allowed.
    if (!formField.TemplateOptions?.AddField || formField.Id) {
      return false;
    }

    const numFormFieldChildren = formField.Children
      ? formField.Children.length
      : 0;

    return (
      !formField.TemplateOptions.MaxAdd ||
      numFormFieldChildren < formField.TemplateOptions.MaxAdd
    );
  }

  private isNotParentFormField(formField: FormField): boolean {
    return formField.Id !== undefined && formField.Key !== formField.ParentKey;
  }

  public addNewFormFieldOfSameType(originalFormFieldToCopy: FormField): void {
    let newInputFormField: FormField;
    try {
      newInputFormField = this.getNewFormFieldWithLatestId(
        originalFormFieldToCopy
      );
    } catch (err) {
      //When latest no FormField is found in getNewFormFieldWithLatestId, skip the remaining logic.
      return;
    }

    const newFormFieldIndex =
      this.dynamicForm.FormFields.indexOf(originalFormFieldToCopy) + 1;
    this.dynamicForm.FormFields.splice(newFormFieldIndex, 0, newInputFormField);
    //Enable validators etc. for new form field
    this.prepareFormGroup(newInputFormField);
    this.cdr.detectChanges();

    //Update children related to allowed to add/remove form field permissions
    originalFormFieldToCopy.Children.push(newInputFormField.Key);

    //Update maps related to allowed to add/remove form field permissions for both old and new form field
    this.allowedToAddFormFieldByKey.set(
      originalFormFieldToCopy.Key,
      this.isAllowedToAddFormField(originalFormFieldToCopy)
    );
    this.allowedToAddFormFieldByKey.set(newInputFormField.Key, false);
    this.allowedToRemoveFormFieldByKey.set(
      newInputFormField.Key,
      this.isNotParentFormField(newInputFormField)
    );
  }

  private getNewFormFieldWithLatestId(
    parentFormFieldToCopy: FormField
  ): FormField {
    const formField: FormField = Object.assign({}, parentFormFieldToCopy);
    formField.TemplateOptions = Object.assign(
      {},
      parentFormFieldToCopy.TemplateOptions
    );

    const latestFormField = this.getFormFieldWithLatestId(
      parentFormFieldToCopy
    );
    if (!latestFormField) {
      throw new Error('No latestFormField found');
    }

    //case where form field with id for this already exists
    if (latestFormField && latestFormField.Id) {
      const keyWithoutId =
        this.dynamicFormUtilsComponent.getKeyNameWithoutId(latestFormField);
      formField.Id = String(Number.parseInt(latestFormField.Id, 10) + 1);
      formField.Key = keyWithoutId + formField.Id;
      formField.ParentKey = parentFormFieldToCopy.Key;

      //case where form field with key involving id for this doesn't exist
    } else if (latestFormField && !latestFormField.Id) {
      formField.Id = 2 + '';
      formField.Key = formField.Key + formField.Id;
      formField.ParentKey = parentFormFieldToCopy.Key;
    }
    return formField;
  }

  private getFormFieldWithLatestId(
    formField: FormField
  ): FormField | undefined {
    let filteredFields = this.dynamicForm.FormFields.filter(
      (field: FormField) => field.Type === formField.Type
    );
    if (formField.ParentKey) {
      filteredFields = filteredFields.filter(
        (field: FormField) => field.ParentKey === formField.ParentKey
      );
    }
    return this.dynamicFormUtilsComponent.getLatestFormField(filteredFields);
  }

  public removeFormField(formFieldToRemove: FormField): void {
    const indexOfFormField = this.dynamicForm.FormFields.findIndex(
      (formField: FormField) => formField.Key === formFieldToRemove.Key
    );

    if (indexOfFormField !== -1) {
      this.dynamicForm.FormFields.splice(indexOfFormField, 1);
      if (this.form.contains(formFieldToRemove.Key)) {
        this.form.removeControl(formFieldToRemove.Key);
      }

      const parentFormField = this.getParentFormField(formFieldToRemove);
      if (parentFormField) {
        const childIndexOfFormField = parentFormField.Children.findIndex(
          (childKey: string) => childKey === formFieldToRemove.Key
        );
        if (childIndexOfFormField !== -1) {
          parentFormField.Children.splice(childIndexOfFormField, 1);
        }
        this.allowedToAddFormFieldByKey.set(
          parentFormField.Key,
          this.isAllowedToAddFormField(parentFormField)
        );
      }

      if (this.allowedToAddFormFieldByKey.has(formFieldToRemove.Key)) {
        this.allowedToAddFormFieldByKey.delete(formFieldToRemove.Key);
      }
      if (this.allowedToRemoveFormFieldByKey.has(formFieldToRemove.Key)) {
        this.allowedToRemoveFormFieldByKey.delete(formFieldToRemove.Key);
      }
    }
  }

  private getParentFormField(formField: FormField): FormField | undefined {
    //In case the form field has is no parent, it is itself the parent
    if (!formField.Id) {
      return formField;
    }
    return this.dynamicForm.FormFields.find(
      (ff: FormField) => ff.Key === formField.ParentKey
    );
  }
}
