import { Component, OnInit } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { DynamicModel } from './dynamic-counseling.model';
import { InvestmentApiService } from '@pfa/api';
import { DynamicCounselingService } from './dynamic-counseling.service';
import { MiscTrackingService, TrackingName } from '@pfa/core';
import { PensionCheckService } from '../pension-check/pension-check.service';
import { NextBestActionService, ProgressbarService } from '@mitpfa/shared';
import {
  DynamicAdvisingOffer,
  DynamicAdvisingOfferGroupType,
  DynamicadvisingStore,
  PensionskundeStore
} from '@pfa/gen';
import { DynamicAdvisingFilterService } from '@mitpfa/shared/services/dynamic-advising-filter.service';

@Component({
  selector: 'mitpfa-dynamic-counseling',
  templateUrl: './dynamic-counseling.component.html',
  styleUrls: ['./dynamic-counseling.component.scss']
})
export class DynamicCounselingComponent implements OnInit {
  public topics: Array<DynamicAdvisingOffer>;
  public dynamicModel$: Observable<DynamicModel[]>;
  public progressPercentage$: Observable<number>;
  public nbaId: string;
  public isError = false;
  public loading = true;
  loc80Allowed: boolean;

  constructor(
    private progressbarService: ProgressbarService,
    private pensionskundeStore: PensionskundeStore,
    private investmentApiService: InvestmentApiService,
    private dynamicCounselingService: DynamicCounselingService,
    private readonly dynamicAdvisingFilterService: DynamicAdvisingFilterService,
    private dynamicadvisingStore: DynamicadvisingStore,
    private pensionCheckService: PensionCheckService,
    private readonly nextBestActionService: NextBestActionService,
    private trackingService: MiscTrackingService
  ) {}

  ngOnInit() {
    this.progressPercentage$ = this.progressbarService.getTotalPercent();
    this.dynamicModel$ = combineLatest([
      this.pensionskundeStore.pensionskundeGet(),
      this.dynamicadvisingStore.dynamicadvisingGet(),
      this.investmentApiService.get()
    ]).pipe(
      catchError(err => {
        this.isError = true;
        this.loading = false;
        return err;
      }),
      map(([pensionCustomer, dynamicAdvisingCalculation, investment]) => {
        this.loc80Allowed =
          dynamicAdvisingCalculation.basisValues.automaticAdjustmentAllowed.includes(
            'LOC_80'
          );
        this.nbaId = dynamicAdvisingCalculation.nbaId;
        const dynamiccounselings =
          this.dynamicCounselingService.getPrioritizeCounseling(
            this.dynamicAdvisingFilterService.filterOfferGroupsByType(
              dynamicAdvisingCalculation.offerGroups,
              DynamicAdvisingOfferGroupType.All
            ).offers
          );
        const dynamicCounseling: DynamicModel[] = [];
        dynamiccounselings.forEach(dynamiccounseling => {
          dynamicCounseling.push(
            new DynamicModel(
              dynamiccounseling,
              pensionCustomer.alder,
              investment.detaljer.primaerPolice
            )
          );
        });
        this.pensionCheckService.trackNbaOffersList(
          TrackingName.DYNAMIC_COUNSELING,
          dynamicAdvisingCalculation.nbaId,
          dynamiccounselings
        );
        this.loading = false;
        return dynamicCounseling;
      })
    );
  }

  adobeTrackingYourInformationClick() {
    this.trackingService.trackNavigationCard(
      TrackingName.DYNAMIC_COUNSELING,
      'Your information',
      '/mine-oplysninger'
    );
  }

  adobeTrackingClick(index: number, dynamiccounseling: DynamicAdvisingOffer) {
    this.nextBestActionService.trackNbaOfferClick(
      TrackingName.DYNAMIC_COUNSELING,
      this.nbaId,
      dynamiccounseling.offerId,
      dynamiccounseling.offerHash,
      index,
      dynamiccounseling.offerSeverity
    );
  }
}
