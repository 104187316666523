<form [formGroup]="realEstateForm">
  <div class="row spacing-section--over">
    <div class="col-sm-5">
      <p class="text--manchet" coContent="DL.MD01.C309"></p>
      <p class="text--info" coContent="DL.MD01.C310"></p>
    </div>
    <div class="col-sm">
      <mat-radio-group formControlName="isAnyRealEstate">
        <mat-radio-button
          data-test-id="financial-situation-real-estate-selected"
          [value]="true"
        >
          {{ 'DL.MD01.C29' | content }}
        </mat-radio-button>
        <mat-radio-button
          data-test-id="financial-situation-real-estate-not-selected"
          [value]="false"
        >
          {{ 'DL.MD01.C30' | content }}
        </mat-radio-button>
      </mat-radio-group>
    </div>
  </div>

  <ng-container
    *ngIf="realEstateForm.controls?.isAnyRealEstate.value"
    formArrayName="realEstates"
  >
    <div class="section-form spacing-unit--over">
      <div class="button-add align--right">
        <button
          mat-raised-button
          class="button-primary--level3"
          (click)="addNewRealEstate()"
        >
          <co-icon-add class="icon"></co-icon-add>
          <span coContent="DL.INV.C160"></span>
        </button>
      </div>

      <div
        class="housing-form"
        *ngFor="
          let realEstate of realEstates.controls;
          let i = index;
          trackBy: trackByFn
        "
      >
        <co-accordion
          [title]="
            !realEstate.value.isNew && !realEstate.value.name
              ? ('DL.MD01.C440' | content)
              : realEstate.value.name
          "
          [initExpanded]="realEstate.value.isNew || realEstate.invalid"
          #realEstateAccordion
        >
          <mitpfa-financial-situation-real-estate-form
            [withAdvisorMenu]="withAdvisorMenu"
            [formGroup]="realEstate"
            [pensionCustomer]="pensionCustomer"
            [isInInsuranceGuide]="isInInsuranceGuide"
          ></mitpfa-financial-situation-real-estate-form>
          <div class="spacing-unit--double-over align--center">
            <button
              mat-raised-button
              (click)="realEstateAccordion.expanded = false"
              [disabled]="realEstate.invalid"
              coContent="DL.MD01.C448"
              class="button-secondary"
            >
              Add
            </button>
          </div>
        </co-accordion>

        <div class="button-remove align--right">
          <button
            mat-raised-button
            class="button-primary--level3"
            (click)="removeRealEstate(i)"
          >
            <co-icon-delete class="icon"></co-icon-delete>
            <span coContent="DL.MD01.C148"></span>
          </button>
        </div>
      </div>
    </div>

    <mitpfa-financial-situation-advisor-other-financial-conditions
      *ngIf="withAdvisorMenu"
      [financialSituation]="financialSituation"
      [pensionCustomer]="pensionCustomer"
      [form]="form"
    ></mitpfa-financial-situation-advisor-other-financial-conditions>
  </ng-container>
</form>
