/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CheckListItem } from './checkListItem';
import { CheckListItemSchema } from './checkListItem';
import { ChecklistType } from './checklistType';
import { ChecklistTypeSchema } from './checklistType';
import * as JoiObj from "joi";


    export const PFAPensionProductOfferCheckListSchema = JoiObj.object({
        checklistType:
            
        JoiObj.link('#ChecklistTypeSchema')


.required()


        ,
        items:
                JoiObj.array().items(
        
        JoiObj.link('#CheckListItemSchema')

        )
        

.required()


        
    })
                .shared(ChecklistTypeSchema)
                .shared(CheckListItemSchema)
.id('PFAPensionProductOfferCheckListSchema')

export interface PFAPensionProductOfferCheckList { 
    checklistType: ChecklistType;
    items: Array<CheckListItem>;
}
export namespace PFAPensionProductOfferCheckList {
}


