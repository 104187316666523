/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ServiceInfo } from './serviceInfo';
import { ServiceInfoSchema } from './serviceInfo';
import { Type } from './type';
import { TypeSchema } from './type';
import { Tilstand } from './tilstand';
import { TilstandSchema } from './tilstand';
import { ResourceModel } from './resourceModel';
import { ResourceModelSchema } from './resourceModel';
import * as JoiObj from "joi";


    export const PoliceSchema = JoiObj.object({
        serviceInfo:
            
        JoiObj.link('#ServiceInfoSchema')

.allow(null)



        ,
        detaljeMulig:
            
                    JoiObj.boolean()
                


.required()


        ,
        harProfilG:
            
                    JoiObj.boolean()
                


.required()


        ,
        id:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        livnetAftale:
            
                    JoiObj.boolean()
                


.required()


        ,
        opsparing:
            
                    JoiObj.number()
                

.allow(null)



        ,
        orlovSlutDato:
            
            JoiObj.date()

.allow(null)



        ,
        tilstand:
            
        JoiObj.link('#TilstandSchema')


.required()


        ,
        tooltipContentId:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        type:
            
        JoiObj.link('#TypeSchema')


.required()


        ,
        typeTekstId:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        
    })
                .shared(ServiceInfoSchema)
                .shared(TilstandSchema)
                .shared(TypeSchema)
.id('PoliceSchema')

export interface Police extends ResourceModel { 
    detaljeMulig: boolean;
    harProfilG: boolean;
    id: string;
    livnetAftale: boolean;
    opsparing?: number;
    orlovSlutDato?: Date;
    tilstand: Tilstand;
    tooltipContentId?: string;
    type: Type;
    typeTekstId: string;
}
export namespace Police {
}


