import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { OekonomiDetaljer } from '@pfa/gen';
import { Utils } from '@pfa/utils';

export class FinancialSituationConfigurationService {
  readonly age = 50;

  propertyTypes = [
    { value: 'Ejerlejlighed', translation: 'DL.MD01.C287' },
    { value: 'Fritidsejendom', translation: 'DL.MD01.C288' }
  ];

  ownershipTypes = [
    { value: 'alone', translation: 'DL.MD01.C411' },
    { value: 'two', translation: 'DL.MD01.C412' },
    { value: 'more', translation: 'DL.MD01.C413' }
  ];

  coOwnerTypes = [
    { value: 3, translation: 'DL.MD01.C421' },
    { value: 4, translation: 'DL.MD01.C422' },
    { value: 5, translation: 'DL.MD01.C423' },
    { value: 6, translation: 'DL.MD01.C424' },
    { value: 7, translation: 'DL.MD01.C425' },
    { value: 8, translation: 'DL.MD01.C426' },
    { value: 9, translation: 'DL.MD01.C427' },
    { value: 10, translation: 'DL.MD01.C428' },
    { value: 11, translation: 'DL.MD01.C429' },
    { value: 12, translation: 'DL.MD01.C430' }
  ];

  interestFormTypes = [
    { value: 'RENTETILPASNING_KORT', translation: 'DL.PP01.C89' },
    { value: 'RENTETILPASNING_LANG', translation: 'DL.PP01.C90' },
    { value: 'FAST_RENTE', translation: 'DL.PP01.C91' },
    { value: 'EN_KOMBINATION', translation: 'DL.PP01.C92' },
    { value: 'VED_IKKE', translation: 'DL.PP01.C93' }
  ];

  loanRepaymentsTypes = [
    { value: 'JA', translation: 'DL.PP01.C53' },
    { value: 'NEJ', translation: 'DL.PP01.C54' },
    { value: 'DELVIST', translation: 'DL.PP01.C88' },
    { value: 'VED_IKKE', translation: 'DL.PP01.C93' }
  ];

  interestFormDefaultType = 'VED_IKKE';
  loanRepaymentsDefaultType = 'VED_IKKE';

  getMappedFormsToUpdateFormat(
    form: UntypedFormGroup,
    financialSituation: OekonomiDetaljer
  ): OekonomiDetaljer {
    if (form.value.savings) {
      financialSituation.frieMidler.opsparingIBank =
        form.value.savings.bankSavings;
      if (
        form.value.savings.bankSavings &&
        form.value.savings?.totalValue?.wealthFormGroup
      ) {
        financialSituation.frieMidler.aktierSamletVaerdi = parseInt(
          form.value.savings.totalValue.wealthFormGroup.equities,
          10
        );
        financialSituation.frieMidler.obligationerSamletVaerdi = parseInt(
          form.value.savings.totalValue.wealthFormGroup.bonds,
          10
        );
        financialSituation.frieMidler.kontanterSamletVaerdi = parseInt(
          form.value.savings.totalValue.wealthFormGroup.cash,
          10
        );

        financialSituation.frieMidler.andelFormue = parseInt(
          form.value.savings.shareOfWealth,
          10
        );

        if (
          form.value.savings.regularAnnualSavings &&
          form.value.savings?.annualSavings?.wealthFormGroup
        ) {
          financialSituation.frieMidler.aktierLoebendeOpsparing = parseInt(
            form.value.savings.annualSavings.wealthFormGroup.equities,
            10
          );
          financialSituation.frieMidler.obligationerLoebendeOpsparing =
            parseInt(
              form.value.savings.annualSavings.wealthFormGroup.bonds,
              10
            );
          financialSituation.frieMidler.kontanterLoebendeOpsparing = parseInt(
            form.value.savings.annualSavings.wealthFormGroup.cash,
            10
          );
        } else {
          financialSituation.frieMidler.aktierLoebendeOpsparing = 0;
          financialSituation.frieMidler.obligationerLoebendeOpsparing = 0;
          financialSituation.frieMidler.kontanterLoebendeOpsparing = 0;
        }
      } else {
        financialSituation.frieMidler.aktierSamletVaerdi = 0;
        financialSituation.frieMidler.obligationerSamletVaerdi = 0;
        financialSituation.frieMidler.kontanterSamletVaerdi = 0;
        financialSituation.frieMidler.aktierLoebendeOpsparing = 0;
        financialSituation.frieMidler.obligationerLoebendeOpsparing = 0;
        financialSituation.frieMidler.kontanterLoebendeOpsparing = 0;
      }
    }

    if (form.value.realEstate) {
      financialSituation.frieMidler.boligValgt =
        form.value.realEstate.isAnyRealEstate;
      financialSituation.frieMidler.boliger = form.value.realEstate
        .isAnyRealEstate
        ? form.value.realEstate.realEstates.map((realEstate, index) => {
            const realEstateAdvisorControl = (
              form.controls.realEstate.get('realEstates') as UntypedFormArray
            ).controls[index].get('advisor');
            return {
              ejendomArt: realEstate.typeOfProperty,
              ejendomNavn: realEstate.name,
              salgsvaerdi: parseInt(realEstate.saleValueToday, 10),
              gaeldIdag: parseInt(realEstate.presentDebt, 10),
              gaeldVedPension: Utils.isNullOrUndefined(
                realEstate.debtAtTimeOfRetirement
              )
                ? financialSituation.frieMidler?.boliger[index]
                    ?.gaeldVedPension || 0
                : parseInt(realEstate.debtAtTimeOfRetirement, 10),
              ejerAndel: parseInt(realEstate.yourShareOfResidence, 10),
              andelFrivaerdi: Utils.isNullOrUndefined(
                realEstate.includedInRetirement
              )
                ? financialSituation.frieMidler?.boliger[index]
                    ?.andelFrivaerdi || 0
                : parseInt(realEstate.includedInRetirement, 10),
              renteForm: realEstateAdvisorControl.get('interestForm').value,
              afdrag: realEstateAdvisorControl.get('loanRepayments').value
            };
          })
        : [];
    }

    if (form.value.debt) {
      financialSituation.frieMidler.gaeldIBankIdag = form.value.debt.isBankDebt;
      financialSituation.frieMidler.bankgaeldIdag = form.value.debt.isBankDebt
        ? parseInt(form.value.debt.totalBankDebt, 10)
        : 0;
      financialSituation.frieMidler.gaeldIBank =
        form.value.debt.isBankDebtOnPension;
      financialSituation.frieMidler.bankgaeld =
        form.value.debt.isBankDebt && form.value.debt.isBankDebtOnPension
          ? parseInt(form.value.debt.bankDebtOnPension, 10)
          : 0;
    }

    if (form.value.salary) {
      financialSituation.beregneAndenLoen = parseInt(
        form.value.salary.presentAnnualSalary,
        10
      );
    }

    if (form.value.otherFinancialConditions) {
      financialSituation.frieMidler.investeringsejendomme =
        form.value.otherFinancialConditions.investmentProperties;
      financialSituation.frieMidler.egenVirksomhed =
        form.value.otherFinancialConditions.ownBusiness;
    }

    if (form.value.taxDeduction) {
      financialSituation.fradrager = form.value.taxDeduction.map(item => ({
        aar: item.calendarYear,
        beloeb: parseInt(item.taxDeduction, 10)
      }));
    }

    if (form.value.myExtraCoverage !== undefined) {
      financialSituation.frieMidler.gaeldSikres = true;
      financialSituation.frieMidler.gaeldDaekkes = parseInt(
        form.value.myExtraCoverage,
        10
      );
    }

    return financialSituation;
  }
}
