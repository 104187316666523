/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { StatusOffer } from './statusOffer';
import { StatusOfferSchema } from './statusOffer';
import * as JoiObj from "joi";


    export const PFAPensionProductOfferOfferElementSchema = JoiObj.object({
        dueDate:
            
            JoiObj.date()

.allow(null)



        ,
        expired:
            
                    JoiObj.boolean()
                


.required()


        ,
        guid:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        statusDate:
            
            JoiObj.date()


.required()


        ,
        statusOffer:
            
        JoiObj.link('#StatusOfferSchema')


.required()


        
    })
                .shared(StatusOfferSchema)
.id('PFAPensionProductOfferOfferElementSchema')

export interface PFAPensionProductOfferOfferElement { 
    dueDate?: Date;
    expired: boolean;
    guid: string;
    statusDate: Date;
    statusOffer: StatusOffer;
}
export namespace PFAPensionProductOfferOfferElement {
}


