import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, retry } from 'rxjs/operators';
import {
  AsyncSubject,
  combineLatest,
  Observable,
  of,
  ReplaySubject
} from 'rxjs';
import {
  InsuranceGuideApiService,
  InvestmentGuideApiService,
  EconomyApiService
} from '@pfa/api';
import { MiscTrackingService } from '@pfa/core';
import {
  ConsentInfo,
  PensionsKundeGenerelleData,
  PensionskundeStore,
  StamdataDetaljer
} from '@pfa/gen';
import { InvestmentGuideInfo } from '@pfa/models';
import { Utils } from '@pfa/utils';

export class ProgressbarItem {
  constructor(
    private id: string,
    private percentage: number,
    private checked?: boolean
  ) {}

  public get Id() {
    return this.id;
  }

  public get Percentage() {
    return this.percentage;
  }

  public get Checked() {
    return this.checked;
  }

  public set Checked(value: boolean) {
    this.checked = value;
  }
}

export class ProgressbarModel {
  private items = [];

  public addItem(id: string, percentage: number, checked?: boolean) {
    this.items.push(new ProgressbarItem(id, percentage, checked));
  }

  public get Items() {
    return this.items;
  }
}

@Injectable({
  providedIn: 'root'
})
export class ProgressbarService {
  private myInformation = new ProgressbarModel();
  private total = new ReplaySubject<number>();
  private promise: Promise<unknown>;
  private initialTotal = new AsyncSubject<number>();
  private initialTotalValue: number;
  private pensionCustomer: PensionsKundeGenerelleData;

  constructor(
    private http: HttpClient,
    private pensionskundeStore: PensionskundeStore,
    private insuranceGuideApiService: InsuranceGuideApiService,
    private investmentGuideService: InvestmentGuideApiService,
    private economyApiService: EconomyApiService,
    private trackingService: MiscTrackingService
  ) {
    this.myInformation.addItem('family', 20);
    this.myInformation.addItem('family.status', 10, false);
    this.myInformation.addItem('family.children', 10, false);
    this.myInformation.addItem('pensioninfo', 20, false);
    this.myInformation.addItem('fortune', 40);
    this.myInformation.addItem('fortune.cash', 8, false);
    this.myInformation.addItem('fortune.residence', 8, false);
    this.myInformation.addItem('fortune.residencesecondary', 8, false);
    this.myInformation.addItem('fortune.debt', 8, false);
    this.myInformation.addItem('fortune.futuredebt', 8, false);
    this.myInformation.addItem('contact', 20);
    this.myInformation.addItem('contact.info', 10, false);
    this.myInformation.addItem('contact.consent', 10, false);
    this.myInformation.addItem('insuranceguide', 0, false);
    this.myInformation.addItem('investmentguide', 0, false);
  }

  public MyInformation(): ProgressbarModel {
    return this.myInformation;
  }

  // get checkmark
  public myInformationChecked(id: string): boolean {
    return this.myInformation.Items.find(item => item.id === id).checked;
  }

  // get percentage
  public myInformationPercentage(id?: string): number {
    let percentage = 0;
    this.myInformation.Items.forEach(item => {
      if (item.Checked && this.isContactInformationValid(item.Id)) {
        // total percentage
        if (id === undefined) {
          percentage += item.Percentage;
        }
        // percentage group or specific item
        if (id && item.Id.includes(id)) {
          percentage += item.Percentage;
        }
      }
    });
    return percentage;
  }

  // get completed
  public myInformationCompleted(id?: string): boolean {
    let percentage = 0;
    let group: number;
    this.myInformation.Items.forEach(item => {
      // save group total
      if (id && item.Id === id) {
        group = item.Percentage;
      }
      if (item.Checked) {
        // total
        if (id === undefined) {
          percentage += item.Percentage;
        }
        // percentage group or specific item
        if (id && item.Id.includes(id)) {
          percentage += item.Percentage;
        }
      }
    });
    if (id === undefined) {
      if (percentage === 100) {
        return true;
      } else {
        return false;
      }
    } else {
      if (percentage === group) {
        return true;
      } else {
        return false;
      }
    }
  }

  public getTotalPercent(): Observable<number> {
    this.loadMyInformation();
    return this.total.asObservable();
  }

  public getInitialTotalPercentObservable(): Observable<number> {
    return this.initialTotal.asObservable();
  }

  public getInitialTotalPercentValue(): number {
    return this.initialTotalValue;
  }

  public getMyInformation() {
    return {
      total: this.myInformationPercentage(),
      family: this.myInformationCompleted('family'),
      pensioninfo: this.myInformationCompleted('pensioninfo'),
      fortune: this.myInformationCompleted('fortune'),
      contact: this.myInformationCompleted('contact'),
      investmentguide: this.myInformationChecked('investmentguide'),
      insuranceguide: this.myInformationChecked('insuranceguide')
    };
  }

  // change status
  public myInformationCheck(ids: string[], sendTrackMessage?: boolean) {
    let changed = false;

    this.myInformation.Items.forEach(item => {
      if (ids.includes(item.Id)) {
        if (item.Checked !== undefined) {
          if (this.isContactInformationValid(item.Id)) {
            changed = !item.Checked;
            item.Checked = true;
          }
        } else {
          throw new Error('cannot change group');
        }
      }
    });
    this.trackMyPercentageChange(
      this.getCategory(ids[0]),
      changed,
      sendTrackMessage
    );
    // publish updated total to subscribers
    this.total.next(this.myInformationPercentage());
  }

  private isContactInformationValid(id: string): boolean {
    return (
      id !== 'contact.info' ||
      (this.pensionCustomer?.customerEmailValid &&
        this.pensionCustomer?.customerMobilePhoneValid)
    );
  }

  public myInformationUnCheck(id: string) {
    let changed = false;
    this.myInformation.Items.forEach(item => {
      if (item.Id === id) {
        if (item.Checked !== undefined) {
          changed = item.Checked;
          item.Checked = false;
        } else {
          throw new Error('cannot change group');
        }
      }
    });
    this.trackMyPercentageChange(id, changed, true);
    // publish updated total to subscribers
    this.total.next(this.myInformationPercentage());
  }

  public myInformationSwitch(id: string) {
    this.myInformation.Items.forEach(item => {
      if (item.Id === id) {
        if (item.Checked !== undefined) {
          item.Checked = !item.Checked;
        } else {
          throw new Error('cannot change group');
        }
      }
    });
    this.trackMyPercentageChange(id, true, true);
    // publish updated total to subscribers
    this.total.next(this.myInformationPercentage());
  }

  private trackMyPercentageChange(
    eventName: string,
    changed: boolean,
    sendTrackMessage?: boolean
  ) {
    if (sendTrackMessage === undefined) {
      sendTrackMessage = true;
    }

    if (sendTrackMessage && changed) {
      this.trackingService.trackMyPercentageChange(
        this.myInformationPercentage(),
        eventName
      );
    }
  }

  public loadMyInformation() {
    if (this.promise) {
      return this.promise;
    } else {
      this.pensionskundeStore
        .pensionskundeGet()
        .subscribe((pensionCustomer: PensionsKundeGenerelleData) => {
          if (pensionCustomer) {
            this.pensionCustomer = pensionCustomer;
            if (pensionCustomer.harPensionsinfo) {
              this.myInformationCheck(['pensioninfo'], false);
            }
            if (
              pensionCustomer.kundeEmail !== null ||
              pensionCustomer.mobil !== null
            ) {
              this.myInformationCheck(['contact.info'], false);
            }
          }

          this.promise = new Promise((resolve, reject) => {
            const stamdata =
              this.http.get<StamdataDetaljer>('/ds/api/stamdata');
            const insuranceGuide =
              this.insuranceGuideApiService.getInsuranceGuideData();
            const investmentGuide = this.investmentGuideService
              .get(pensionCustomer.globalId)
              .pipe(catchError(() => of(<InvestmentGuideInfo>undefined)));

            const consents = this.pensionskundeStore.pensionskundeConsentsGet();
            const economyDetails = this.economyApiService.getEconomyDetails();

            combineLatest([
              stamdata,
              insuranceGuide,
              investmentGuide,
              consents,
              economyDetails
            ])
              .pipe(retry(1))
              .subscribe(
                data => {
                  if (data[0]) {
                    if (data[0].civilstand !== null) {
                      this.myInformationCheck(['family.status'], false);
                    }
                    if (data[0].forsoergerpligt !== null) {
                      this.myInformationCheck(['family.children'], false);
                    }
                  }
                  if (data[1]) {
                    if (data[1].timeAsMs ? true : false) {
                      this.myInformationCheck(['insuranceguide'], false);
                    }
                  }
                  if (data[2]) {
                    if (data[2].isGuideCompleted) {
                      this.myInformationCheck(['investmentguide'], false);
                    }
                  }
                  if (data[3]) {
                    if (this.getMarketingConsentConsidered(data[3])) {
                      this.myInformationCheck(['contact.consent'], false);
                    }
                  }
                  if (data[4]) {
                    if (
                      !Utils.isNullOrUndefined(
                        data[4].frieMidler?.opsparingIBank
                      )
                    ) {
                      this.myInformationCheck(['fortune.cash'], false);
                    }
                    if (
                      !Utils.isNullOrUndefined(data[4].frieMidler?.boligValgt)
                    ) {
                      // let both of them count if customer has checked option
                      this.myInformationCheck(['fortune.residence'], false);
                      this.myInformationCheck(
                        ['fortune.residencesecondary'],
                        false
                      );
                    }
                    if (
                      !Utils.isNullOrUndefined(data[4].frieMidler?.gaeldIBank)
                    ) {
                      this.myInformationCheck(['fortune.futuredebt'], false);
                    }
                    if (
                      !Utils.isNullOrUndefined(
                        data[4].frieMidler?.gaeldIBankIdag
                      )
                    ) {
                      this.myInformationCheck(['fortune.debt'], false);
                    }
                  }

                  this.initialTotalValue = this.myInformationPercentage();
                  this.total.next(this.initialTotalValue);
                  this.initialTotal.next(this.initialTotalValue);
                  this.trackingService.trackMyLogin(this.initialTotalValue);
                  this.initialTotal.complete();
                  resolve(this.myInformation);
                },
                error => {
                  reject(error);
                }
              );
          });
        });
      // always return 'undefined' because this.promise is set in subscribe() so assigning to this.promise is deferred
      return this.promise;
    }
  }

  public getMarketingConsentConsidered(consents: ConsentInfo[]) {
    let considered = false;
    if (consents) {
      consents.forEach(v => {
        if (
          (v.type === 'Marketing' && v.state !== null) ||
          (v.type === 'MarketingPensionOnly' && v.state !== null)
        ) {
          considered = true;
        }
      });
    }
    return considered;
  }

  public updateContactInformation() {
    this.pensionskundeStore
      .pensionskundeGet()
      .subscribe((pensionCustomer: PensionsKundeGenerelleData) => {
        if (pensionCustomer) {
          this.pensionCustomer = pensionCustomer;
          this.myInformationCheck(['contact.info'], false);
        }
      });
  }

  private getCategory(id: string): string {
    return id.split('.')[0];
  }
}
