import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MyCommonModule } from '@pfa/common';
import { CoreModule } from '@pfa/core';
import { PfaFormModule } from '@pfaform';
import { HeaderComponent } from './header.component';
import { MenuModule } from './menu/menu.module';

@NgModule({
  imports: [
    CommonModule,
    MyCommonModule,
    CoreModule,
    PfaFormModule,
    MenuModule
  ],
  declarations: [HeaderComponent],
  exports: [HeaderComponent]
})
export class HeaderModule {}
