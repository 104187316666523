<co-accordion [title]="'DL.OS01.C382'">
  <div [coContent]="'DL.OS01.C423'" class="intro">her kan du se...</div>
  <table
    class="pfa-table table-responsive align-left--column-1 align-right--column-2"
    [attr.aria-label]="'DL.OS01.C423' | content"
  >
    <thead class="hide--palm">
      <tr>
        <th scope="col" [coContent]="'DL.OS01.C425'">produkt</th>
        <th scope="col" [coContent]="'DL.OS01.C426'">beløb</th>
      </tr>
    </thead>
    <tbody>
      <tr
        *ngFor="
          let product of savingsPolicyDetails.vaerdiAfOpsparingUltimo
            .opsparingFordeling.produkter
        "
      >
        <td
          [attr.data-label]="'DL.OS01.C425' | content"
          [coContent]="product.contentId"
          [coContentSubst]="product.contentParameter"
        ></td>
        <td [attr.data-label]="'DL.OS01.C426' | content">
          {{ product.value | numberFormat: 2 }}
          <span *ngIf="product.value" [coContent]="'Global.kroner'"></span>
        </td>
      </tr>
      <tr class="border-top--thick">
        <td
          [attr.data-label]="'DL.OS01.C425' | content"
          [coContent]="'DL.OS01.C209'"
        >
          Ialt
        </td>
        <td [attr.data-label]="'DL.OS01.C426' | content">
          <co-primary-number
            [amount]="productAndConceptData.value"
            [showDecimals]="true"
            class="size-sm"
          ></co-primary-number>
        </td>
      </tr>
    </tbody>
  </table>
</co-accordion>
