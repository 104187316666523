import { Component, DestroyRef, inject, Inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PopupTrackingService } from '@pfa/core';
import { PensionsKundeGenerelleData } from '@pfa/gen';

const popupName = 'contact information: reminder';

@Component({
  templateUrl: './contact-remind.component.html'
})
export class ContactRemindComponent {
  private readonly popupTrackingService: PopupTrackingService =
    inject(PopupTrackingService);
  private readonly dialogRef: MatDialogRef<ContactRemindComponent> = inject(
    MatDialogRef<ContactRemindComponent>
  );
  private readonly destroyRef: DestroyRef = inject(DestroyRef);

  public pensionCustomer: PensionsKundeGenerelleData;

  constructor(@Inject(MAT_DIALOG_DATA) data) {
    this.pensionCustomer = data;

    this.dialogRef
      .afterOpened()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.popupTrackingService.trackPopupViewed(popupName);
      });
  }

  public trackPopupCta(): void {
    this.popupTrackingService.trackPopupCallToAction(popupName);
  }

  public close(): void {
    this.dialogRef.close();

    this.dialogRef
      .afterClosed()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.popupTrackingService.trackPopupClosed(popupName);
      });
  }
}
