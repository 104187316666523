/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const ChecklistStatusItemSchema = JoiObj.string().valid(
        'OPEN'
        ,
        'INPROCESS'
        ,
        'COMPLETED'
        ,
        'NOT_APPLICABLE'
        
    )
.id('ChecklistStatusItemSchema')

export type ChecklistStatusItem = 'OPEN' | 'INPROCESS' | 'COMPLETED' | 'NOT_APPLICABLE';

export const ChecklistStatusItem = {
    Open: 'OPEN' as ChecklistStatusItem,
    Inprocess: 'INPROCESS' as ChecklistStatusItem,
    Completed: 'COMPLETED' as ChecklistStatusItem,
    NotApplicable: 'NOT_APPLICABLE' as ChecklistStatusItem
};

