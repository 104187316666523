import { Component, Input } from '@angular/core';

@Component({
  selector: 'mitpfa-advantage-header',
  templateUrl: './advantage-header.component.html',
  styleUrls: ['./advantage-header.component.scss']
})
export class AdvantageHeaderComponent {
  @Input() title: string;
  @Input() subTitle: string;
  @Input() text: string;
  @Input() imgUrl: string;
}
