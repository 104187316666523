/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const TransaktionTypeSchema = JoiObj.string().valid(
        'Indtraeden'
        ,
        'Udtraeden'
        ,
        'OverfoerselUdAMPEksternPFA'
        ,
        'OverfoerselUdOevrEksternPFA'
        ,
        'OverfoerselUdAMPInternIJobPFA'
        ,
        'OverfoerselUdOevrInternIJobPFA'
        ,
        'OverfoerselUdAMPInternUJobPFA'
        ,
        'OverfoerselUdOevrInternUJobPFA'
        ,
        'TilbagekoebPFA'
        ,
        'AendrOpsProfilSelv'
        ,
        'AendrOpsProfilPFA'
        ,
        'AendrOpsGarantiSelv'
        ,
        'AendrOpsGarantiPFA'
        ,
        'AendrOpsFordPFA'
        ,
        'AendrOpsFordSelv'
        ,
        'AendrInvProfilSelvvalgPFA'
        ,
        'AendrInvProfilSelvvalgSelv'
        ,
        'AendrInvPenAlderPFA'
        ,
        'AendrInvPenAlderSelv'
        ,
        'AendrKonceptAllPFA'
        ,
        'AendrKonceptAllSelv'
        ,
        'AendrIndProfilPFA'
        ,
        'AendrIndProfilSelv'
        ,
        'AendrUdbForloebPFA'
        ,
        'AendrUdbForloebSelv'
        ,
        'ValgUdbForloebSelv'
        ,
        'ValgUdbForloebPFA'
        ,
        'AendrSelvProfilPFA'
        ,
        'AendrSelvProfilSelv'
        ,
        'AendrKKPFA'
        ,
        'AendrKKSelv'
        ,
        'TelefonRaadgivning'
        ,
        'PersonligRaadgivning'
        ,
        'SpecialRaadgivning'
        ,
        'InvstRestDepot'
        ,
        'Vederlag'
        ,
        'AendrDepotsikringPFA'
        ,
        'AendrDepotsikringSelv'
        ,
        'AendrPALPFA'
        ,
        'AendrPALSelv'
        ,
        'TilpasUdbetalingsPlanPFA'
        ,
        'AendreFastlaastYdelse'
        ,
        'AendreUnderUdbetalingPFA'
        ,
        'LoebendeUdbetaling'
        ,
        'EngangsUdbetaling'
        ,
        'TilpasIUdbetalingsPlanSelv'
        ,
        'AendreUnderUdbetalingSelv'
        ,
        'Afgiftberigtigelse'
        ,
        'Henstandsforlaengelse'
        ,
        'AendrProfilkonceptPFA'
        ,
        'AendrProfilkonceptSelv'
        ,
        'AendrKlimaAndelSelv'
        ,
        'AendrKlimaAndelPFA'
        
    )
.id('TransaktionTypeSchema')

export type TransaktionType = 'Indtraeden' | 'Udtraeden' | 'OverfoerselUdAMPEksternPFA' | 'OverfoerselUdOevrEksternPFA' | 'OverfoerselUdAMPInternIJobPFA' | 'OverfoerselUdOevrInternIJobPFA' | 'OverfoerselUdAMPInternUJobPFA' | 'OverfoerselUdOevrInternUJobPFA' | 'TilbagekoebPFA' | 'AendrOpsProfilSelv' | 'AendrOpsProfilPFA' | 'AendrOpsGarantiSelv' | 'AendrOpsGarantiPFA' | 'AendrOpsFordPFA' | 'AendrOpsFordSelv' | 'AendrInvProfilSelvvalgPFA' | 'AendrInvProfilSelvvalgSelv' | 'AendrInvPenAlderPFA' | 'AendrInvPenAlderSelv' | 'AendrKonceptAllPFA' | 'AendrKonceptAllSelv' | 'AendrIndProfilPFA' | 'AendrIndProfilSelv' | 'AendrUdbForloebPFA' | 'AendrUdbForloebSelv' | 'ValgUdbForloebSelv' | 'ValgUdbForloebPFA' | 'AendrSelvProfilPFA' | 'AendrSelvProfilSelv' | 'AendrKKPFA' | 'AendrKKSelv' | 'TelefonRaadgivning' | 'PersonligRaadgivning' | 'SpecialRaadgivning' | 'InvstRestDepot' | 'Vederlag' | 'AendrDepotsikringPFA' | 'AendrDepotsikringSelv' | 'AendrPALPFA' | 'AendrPALSelv' | 'TilpasUdbetalingsPlanPFA' | 'AendreFastlaastYdelse' | 'AendreUnderUdbetalingPFA' | 'LoebendeUdbetaling' | 'EngangsUdbetaling' | 'TilpasIUdbetalingsPlanSelv' | 'AendreUnderUdbetalingSelv' | 'Afgiftberigtigelse' | 'Henstandsforlaengelse' | 'AendrProfilkonceptPFA' | 'AendrProfilkonceptSelv' | 'AendrKlimaAndelSelv' | 'AendrKlimaAndelPFA';

export const TransaktionType = {
    Indtraeden: 'Indtraeden' as TransaktionType,
    Udtraeden: 'Udtraeden' as TransaktionType,
    OverfoerselUdAmpEksternPfa: 'OverfoerselUdAMPEksternPFA' as TransaktionType,
    OverfoerselUdOevrEksternPfa: 'OverfoerselUdOevrEksternPFA' as TransaktionType,
    OverfoerselUdAmpInternIJobPfa: 'OverfoerselUdAMPInternIJobPFA' as TransaktionType,
    OverfoerselUdOevrInternIJobPfa: 'OverfoerselUdOevrInternIJobPFA' as TransaktionType,
    OverfoerselUdAmpInternUJobPfa: 'OverfoerselUdAMPInternUJobPFA' as TransaktionType,
    OverfoerselUdOevrInternUJobPfa: 'OverfoerselUdOevrInternUJobPFA' as TransaktionType,
    TilbagekoebPfa: 'TilbagekoebPFA' as TransaktionType,
    AendrOpsProfilSelv: 'AendrOpsProfilSelv' as TransaktionType,
    AendrOpsProfilPfa: 'AendrOpsProfilPFA' as TransaktionType,
    AendrOpsGarantiSelv: 'AendrOpsGarantiSelv' as TransaktionType,
    AendrOpsGarantiPfa: 'AendrOpsGarantiPFA' as TransaktionType,
    AendrOpsFordPfa: 'AendrOpsFordPFA' as TransaktionType,
    AendrOpsFordSelv: 'AendrOpsFordSelv' as TransaktionType,
    AendrInvProfilSelvvalgPfa: 'AendrInvProfilSelvvalgPFA' as TransaktionType,
    AendrInvProfilSelvvalgSelv: 'AendrInvProfilSelvvalgSelv' as TransaktionType,
    AendrInvPenAlderPfa: 'AendrInvPenAlderPFA' as TransaktionType,
    AendrInvPenAlderSelv: 'AendrInvPenAlderSelv' as TransaktionType,
    AendrKonceptAllPfa: 'AendrKonceptAllPFA' as TransaktionType,
    AendrKonceptAllSelv: 'AendrKonceptAllSelv' as TransaktionType,
    AendrIndProfilPfa: 'AendrIndProfilPFA' as TransaktionType,
    AendrIndProfilSelv: 'AendrIndProfilSelv' as TransaktionType,
    AendrUdbForloebPfa: 'AendrUdbForloebPFA' as TransaktionType,
    AendrUdbForloebSelv: 'AendrUdbForloebSelv' as TransaktionType,
    ValgUdbForloebSelv: 'ValgUdbForloebSelv' as TransaktionType,
    ValgUdbForloebPfa: 'ValgUdbForloebPFA' as TransaktionType,
    AendrSelvProfilPfa: 'AendrSelvProfilPFA' as TransaktionType,
    AendrSelvProfilSelv: 'AendrSelvProfilSelv' as TransaktionType,
    AendrKkpfa: 'AendrKKPFA' as TransaktionType,
    AendrKkSelv: 'AendrKKSelv' as TransaktionType,
    TelefonRaadgivning: 'TelefonRaadgivning' as TransaktionType,
    PersonligRaadgivning: 'PersonligRaadgivning' as TransaktionType,
    SpecialRaadgivning: 'SpecialRaadgivning' as TransaktionType,
    InvstRestDepot: 'InvstRestDepot' as TransaktionType,
    Vederlag: 'Vederlag' as TransaktionType,
    AendrDepotsikringPfa: 'AendrDepotsikringPFA' as TransaktionType,
    AendrDepotsikringSelv: 'AendrDepotsikringSelv' as TransaktionType,
    AendrPalpfa: 'AendrPALPFA' as TransaktionType,
    AendrPalSelv: 'AendrPALSelv' as TransaktionType,
    TilpasUdbetalingsPlanPfa: 'TilpasUdbetalingsPlanPFA' as TransaktionType,
    AendreFastlaastYdelse: 'AendreFastlaastYdelse' as TransaktionType,
    AendreUnderUdbetalingPfa: 'AendreUnderUdbetalingPFA' as TransaktionType,
    LoebendeUdbetaling: 'LoebendeUdbetaling' as TransaktionType,
    EngangsUdbetaling: 'EngangsUdbetaling' as TransaktionType,
    TilpasIUdbetalingsPlanSelv: 'TilpasIUdbetalingsPlanSelv' as TransaktionType,
    AendreUnderUdbetalingSelv: 'AendreUnderUdbetalingSelv' as TransaktionType,
    Afgiftberigtigelse: 'Afgiftberigtigelse' as TransaktionType,
    Henstandsforlaengelse: 'Henstandsforlaengelse' as TransaktionType,
    AendrProfilkonceptPfa: 'AendrProfilkonceptPFA' as TransaktionType,
    AendrProfilkonceptSelv: 'AendrProfilkonceptSelv' as TransaktionType,
    AendrKlimaAndelSelv: 'AendrKlimaAndelSelv' as TransaktionType,
    AendrKlimaAndelPfa: 'AendrKlimaAndelPFA' as TransaktionType
};

