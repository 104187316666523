/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DropRegel } from './dropRegel';
import { DropRegelSchema } from './dropRegel';
import * as JoiObj from "joi";


    export const DropReglerSchema = JoiObj.object({
        aktiv:
            
                    JoiObj.boolean()
                


.required()


        ,
        dato:
            
                    JoiObj.object()
                


.required()


        ,
        dropType:
            
        JoiObj.link('#DropRegelSchema')


.required()


        ,
        id:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        initialer:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        kommentar:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        
    })
                .shared(DropRegelSchema)
.id('DropReglerSchema')

export interface DropRegler { 
    aktiv: boolean;
    dato: object;
    dropType: DropRegel;
    id: string;
    initialer: string;
    kommentar: string;
}
export namespace DropRegler {
}


