import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import {
  AuthHttpErrorResponse,
  PincodeError,
  PincodeErrorText
} from './pincode-errors';

@Injectable({
  providedIn: 'root'
})
export class PincodeLoginErrorService {
  get otpMaxRetries(): boolean {
    return this._otpMaxRetries;
  }

  private _otpMaxRetries: boolean;

  handlePincodeResponse(
    response: HttpResponse<{ message; errorCode }> | AuthHttpErrorResponse
  ): string | undefined {
    const { status } = response;

    if (response instanceof HttpResponse) {
      const { body } = response;
      if (
        status === 200 &&
        body?.message?.includes(PincodeError.PincodeInactive)
      ) {
        return PincodeErrorText[PincodeError.PincodeInactive];
      } else {
        return body?.errorCode;
      }
    } else if (response instanceof HttpErrorResponse) {
      const { error } = response;
      const { message, exceptionMsg } = error || {};

      if (status === 401) {
        return this.handleIncorrectPin(exceptionMsg);
      } else if (status === 400) {
        return this.handleCriticalPincodeError(message, exceptionMsg);
      } else {
        return PincodeErrorText.General;
      }
    }
  }

  public handleSMSError(response: HttpErrorResponse) {
    const { status, error } = response;

    if (this._otpMaxRetries) {
      // If we gotten max tries from ISAM then the next submit to ISAM goes wrong - continue to show max tries.
      return PincodeErrorText[PincodeError.SMSTooManyFailedAttempts];
    }
    if (status === 401) {
      if (error?.mappingRuleData === 'Retry limit exceeded.') {
        this._otpMaxRetries = true;
        return PincodeErrorText[PincodeError.SMSTooManyFailedAttempts];
      } else if (error?.mechanism?.includes('macotp')) {
        return PincodeErrorText[PincodeError.InvalidSMSCode];
      } else {
        return PincodeErrorText.General;
      }
    } else {
      return PincodeErrorText.General;
    }
  }

  private handleIncorrectPin(exceptionMsg: string | undefined): string {
    if (exceptionMsg?.includes(PincodeError.IncorrectPin)) {
      return PincodeErrorText[PincodeError.IncorrectPin];
    } else {
      return PincodeErrorText.General;
    }
  }

  private handleCriticalPincodeError(
    message: string,
    exceptionMsg: string
  ): string {
    if (message?.includes(PincodeError.PincodeTooManyFailedAttempts)) {
      return PincodeErrorText[PincodeError.PincodeTooManyFailedAttempts];
    } else if (
      exceptionMsg?.includes(PincodeError.PincodeTooManyFailedAttemptsRetried)
    ) {
      return PincodeErrorText[PincodeError.PincodeTooManyFailedAttempts];
    } else if (message?.includes(PincodeError.LockedInPro)) {
      return PincodeErrorText[PincodeError.LockedInPro];
    } else if (exceptionMsg === '+null') {
      return PincodeErrorText[PincodeError.MobileMissing];
    } else {
      return PincodeErrorText.General;
    }
  }
}
