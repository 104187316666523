<co-card
  [showErrorMessage]="paymentError"
  class="card--small"
  [ngClass]="{ 'cursor-pointer': !paymentError }"
  (click)="goToLatestPayment()"
  data-test-id="frontpage-savings-payments"
>
  <co-loading [show]="!paymentInit"></co-loading>

  <div *ngIf="paymentInit || paymentError">
    <mitpfa-dashboard-savings-header
      [content]="payoutsStarted ? 'DL.FS03.C54' : 'DL.FS03.C08'"
      [hideArrow]="paymentError"
    ></mitpfa-dashboard-savings-header>

    <div *ngIf="!paymentError" class="payments-number-spacer">
      <co-primary-number
        *ngIf="paymentOverview || payoutsStarted"
        class="size-sm"
        [amount]="
          payoutsStarted
            ? latestPayout
            : paymentOverview.totalLatestPaymentsForYear
        "
        [testId]="'frontpage-savings-payments-total'"
      ></co-primary-number>

      <div
        [coContent]="payoutsStarted ? 'DL.FS03.C55' : 'DL.FS03.C11'"
        [coContentSubst]="
          payoutsStarted
            ? (latestPayoutGross | numberFormat)
            : paymentOverview.totalLatestPaymentsForMonth
        "
      ></div>
    </div>
  </div>

  <div *ngIf="paymentError" card-additional class="error-overlay">
    <co-icon-error></co-icon-error>
    <span [coContent]="'DL.FS03.C15' | content"></span>
  </div>
</co-card>
