import {
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
  Validator,
  AbstractControl
} from '@angular/forms';
import { NumberFormatPipe } from './../number-format.pipe';
import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { MAT_INPUT_VALUE_ACCESSOR } from '@angular/material/input';

@Directive({
  selector: 'input[coAmount]',
  providers: [
    {
      provide: MAT_INPUT_VALUE_ACCESSOR,
      useExisting: AmountDirective
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: AmountDirective,
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: AmountDirective,
      multi: true
    }
  ]
})
export class AmountDirective implements Validator {
  @Input() coAmountDecimals: number;
  @Input() coAmountUnit = 'kr.';
  @Input() coAmountAllowNull = false;
  readonly regexDots = /\./g;
  readonly decimalSeparator = ',';
  readonly thousandSeparator = '.';

  private _value: number | string;

  constructor(
    private elementRef: ElementRef<HTMLInputElement>,
    private numberFormatPipe: NumberFormatPipe
  ) {}

  get value(): number | string {
    return this._value;
  }

  @Input()
  set value(value: number | string) {
    this._value = value;
    this.formatValue(String(value));
  }

  @HostListener('input', ['$event.target.value'])
  onInput(value: string) {
    const parsedInput = this.parseInput(value);
    this._value =
      parsedInput && !isNaN(Number(parsedInput))
        ? Number(Number(parsedInput).toFixed(this.coAmountDecimals))
        : value;
    this._onChange(this._value);
  }

  @HostListener('blur')
  _onBlur() {
    this.formatValue(this._value);
  }

  @HostListener('focus')
  onFocus() {
    this.unFormatValue();
  }

  formatValue(value: string | number) {
    if (value !== '' && value !== null && !isNaN(Number(value))) {
      this.elementRef.nativeElement.value =
        this.numberFormatPipe.transform(value, this.coAmountDecimals) +
        ' ' +
        this.coAmountUnit;
    } else if (!value) {
      this.elementRef.nativeElement.value = '';
    }
    if (this.coAmountAllowNull && value === null) {
      this.elementRef.nativeElement.value = '';
    }
  }

  _onChange(value: any): void {}

  writeValue(value: string | number) {
    this._value = value;
    this.formatValue(this._value);
  }

  registerOnChange(fn: (value: any) => void) {
    this._onChange = fn;
  }

  registerOnTouched() {}

  unFormatValue() {
    const value = this.elementRef.nativeElement.value;
    if (value) {
      this.elementRef.nativeElement.value = this.parseValue(value);
    } else {
      this.elementRef.nativeElement.value = '';
    }
  }

  parseInput(value: string): string {
    return String(value)
      .replace(this.regexDots, '')
      .replace(this.decimalSeparator, this.thousandSeparator)
      .trim();
  }

  parseValue(value: string): string {
    return String(value)
      .replace(this.coAmountUnit, '')
      .replace(this.regexDots, '')
      .trim();
  }

  validate(control: AbstractControl): { [key: string]: any } | null {
    return isNaN(Number(control.value)) ? { numberFormat: {} } : null;
  }
}
