import { LocScale } from '@pfa/gen';

export enum RECOMMENDATION_DIRECTION {
  KEEP = 'KEEP',
  INCREASE = 'INCREASE',
  DECREASE = 'DECREASE'
}

export enum RECOMMENDATION_TYPE {
  CRITICAL_ILLNESS = 'CRITICAL_ILLNESS',
  LIFE = 'LIFE',
  OCCUPATIONAL_CAPACITY = 'OCCUPATIONAL_CAPACITY'
}

export interface RecommendationDescription {
  direction?: RECOMMENDATION_DIRECTION;
  type: RECOMMENDATION_TYPE;
  text: string;
  description?: string;
  scale80allowed?: boolean;
  current?: LocScale;
  recommendation?: LocScale;
  isAuto?: boolean;
  isAutoChange?: boolean;
  isCurrentAuto?: boolean;
}

export const recommendationDescriptionsForScale: RecommendationDescription[] = [
  {
    direction: undefined,
    type: RECOMMENDATION_TYPE.OCCUPATIONAL_CAPACITY,
    text: 'DL.FG01.C1941',
    description: 'DL.FG01.C1940',
    scale80allowed: true,
    current: 'MANUAL',
    recommendation: 'LOC_80'
  },
  {
    direction: undefined,
    type: RECOMMENDATION_TYPE.OCCUPATIONAL_CAPACITY,
    text: 'DL.FG01.C1942',
    description: 'DL.FG01.C1940',
    scale80allowed: true,
    current: 'LOC_80',
    recommendation: 'LOC_80'
  },
  {
    direction: undefined,
    type: RECOMMENDATION_TYPE.OCCUPATIONAL_CAPACITY,
    text: 'DL.FG01.C1941',
    description: 'DL.FG01.C1940',
    scale80allowed: true,
    current: 'LOC_100',
    recommendation: 'LOC_80'
  },
  {
    direction: undefined,
    type: RECOMMENDATION_TYPE.OCCUPATIONAL_CAPACITY,
    text: 'DL.FG01.C1945',
    description: 'DL.FG01.C1940',
    scale80allowed: false,
    current: 'MANUAL',
    recommendation: 'LOC_100'
  },
  {
    direction: undefined,
    type: RECOMMENDATION_TYPE.OCCUPATIONAL_CAPACITY,
    text: 'DL.FG01.C1945',
    description: 'DL.FG01.C1940',
    scale80allowed: false,
    current: 'LOC_80',
    recommendation: 'LOC_100'
  },
  {
    direction: undefined,
    type: RECOMMENDATION_TYPE.OCCUPATIONAL_CAPACITY,
    text: 'DL.FG01.C1943',
    description: 'DL.FG01.C1940',
    scale80allowed: true,
    current: 'MANUAL',
    recommendation: 'LOC_100'
  },
  {
    direction: undefined,
    type: RECOMMENDATION_TYPE.OCCUPATIONAL_CAPACITY,
    text: 'DL.FG01.C1943',
    description: 'DL.FG01.C1940',
    scale80allowed: true,
    current: 'LOC_80',
    recommendation: 'LOC_100'
  },
  {
    direction: undefined,
    type: RECOMMENDATION_TYPE.OCCUPATIONAL_CAPACITY,
    text: 'DL.FG01.C1946',
    description: 'DL.FG01.C1940',
    scale80allowed: false,
    current: 'LOC_100',
    recommendation: 'LOC_100'
  },
  {
    direction: undefined,
    type: RECOMMENDATION_TYPE.OCCUPATIONAL_CAPACITY,
    text: 'DL.FG01.C1944',
    description: 'DL.FG01.C1940',
    scale80allowed: true,
    current: 'LOC_100',
    recommendation: 'LOC_100'
  },
  {
    direction: RECOMMENDATION_DIRECTION.INCREASE,
    type: RECOMMENDATION_TYPE.OCCUPATIONAL_CAPACITY,
    text: 'DL.FG01.C1951',
    description: 'DL.FG01.C1940',
    scale80allowed: false,
    current: 'LOC_100',
    recommendation: 'MANUAL'
  },
  {
    direction: RECOMMENDATION_DIRECTION.DECREASE,
    type: RECOMMENDATION_TYPE.OCCUPATIONAL_CAPACITY,
    text: 'DL.FG01.C1952',
    description: 'DL.FG01.C1940',
    scale80allowed: false,
    current: 'LOC_100',
    recommendation: 'MANUAL'
  },
  {
    direction: RECOMMENDATION_DIRECTION.INCREASE,
    type: RECOMMENDATION_TYPE.OCCUPATIONAL_CAPACITY,
    text: 'DL.FG01.C1953',
    description: 'DL.FG01.C1940',
    scale80allowed: true,
    current: 'LOC_100',
    recommendation: 'MANUAL'
  },
  {
    direction: RECOMMENDATION_DIRECTION.DECREASE,
    type: RECOMMENDATION_TYPE.OCCUPATIONAL_CAPACITY,
    text: 'DL.FG01.C1954',
    description: 'DL.FG01.C1940',
    scale80allowed: true,
    current: 'LOC_100',
    recommendation: 'MANUAL'
  },
  {
    direction: RECOMMENDATION_DIRECTION.KEEP,
    type: RECOMMENDATION_TYPE.OCCUPATIONAL_CAPACITY,
    text: 'DL.FG01.C1946',
    description: 'DL.FG01.C1940',
    scale80allowed: false,
    current: 'LOC_100',
    recommendation: 'MANUAL'
  }
];

export const recommendationDescriptions: RecommendationDescription[] = [
  {
    direction: RECOMMENDATION_DIRECTION.KEEP,
    type: RECOMMENDATION_TYPE.OCCUPATIONAL_CAPACITY,
    text: 'DL.FG01.C1894',
    description: 'DL.FG01.C1904'
  },
  {
    direction: RECOMMENDATION_DIRECTION.INCREASE,
    type: RECOMMENDATION_TYPE.OCCUPATIONAL_CAPACITY,
    text: 'DL.FG01.C1899',
    description: 'DL.FG01.C1900'
  },
  {
    direction: RECOMMENDATION_DIRECTION.DECREASE,
    type: RECOMMENDATION_TYPE.OCCUPATIONAL_CAPACITY,
    text: 'DL.FG01.C1851',
    description: 'DL.FG01.C1853'
  },
  {
    direction: RECOMMENDATION_DIRECTION.KEEP,
    type: RECOMMENDATION_TYPE.LIFE,
    text: 'DL.FG01.C1903',
    description: 'DL.FG01.C1895'
  },
  {
    direction: RECOMMENDATION_DIRECTION.INCREASE,
    type: RECOMMENDATION_TYPE.LIFE,
    text: 'DL.FG01.C1902',
    description: 'DL.FG01.C1905'
  },
  {
    direction: RECOMMENDATION_DIRECTION.DECREASE,
    type: RECOMMENDATION_TYPE.LIFE,
    text: 'DL.FG01.C1901',
    description: 'DL.FG01.C1906'
  },
  {
    direction: RECOMMENDATION_DIRECTION.KEEP,
    type: RECOMMENDATION_TYPE.CRITICAL_ILLNESS,
    text: 'DL.FG01.C1896',
    description: 'DL.FG01.C1908'
  },
  {
    direction: RECOMMENDATION_DIRECTION.INCREASE,
    type: RECOMMENDATION_TYPE.CRITICAL_ILLNESS,
    text: 'DL.FG01.C1892',
    description: 'DL.FG01.C1893'
  },
  {
    direction: RECOMMENDATION_DIRECTION.DECREASE,
    type: RECOMMENDATION_TYPE.CRITICAL_ILLNESS,
    text: 'DL.FG01.C1859',
    description: 'DL.FG01.C1907'
  }
];
