<div mat-dialog-title>
  <h3 coContent="DL.HE01.C72"></h3>
</div>
<mat-dialog-content>
  <div class="text--uppercase text--bold" coContent="DL.HE01.C73"></div>
  <p coContent="DL.HE01.C74"></p>
  <p coContent="DL.HE01.C75"></p>
  <p coContent="DL.HE01.C76"></p>

  <div
    class="text--bold spacing-unit--half-under"
    coContent="DL.HE01.C77"
  ></div>

  <mat-radio-group [(ngModel)]="pensionCustomer.aarsagTilBrug">
    <mat-radio-button
      value="MOEDE"
      name="raadgiverAarsag"
      data-test-id="HE01-input-moede"
      ><span coContent="DL.HE01.C84"></span
    ></mat-radio-button>
    <mat-radio-button
      value="WEB"
      name="raadgiverAarsag"
      data-test-id="HE01-input-web"
      ><span coContent="DL.HE01.C85"></span
    ></mat-radio-button>
    <mat-radio-button
      value="TELEFON"
      name="raadgiverAarsag"
      data-test-id="HE01-input-telefon"
      ><span coContent="DL.HE01.C86"></span
    ></mat-radio-button>
    <mat-radio-button
      value="SUPPORT"
      name="raadgiverAarsag"
      data-test-id="MD01-input-support"
      id="support"
      ><span coContent="DL.HE01.C87"></span
    ></mat-radio-button>
  </mat-radio-group>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="modal-buttons">
    <button
      mat-raised-button
      (click)="close()"
      [disabled]="isDisabled()"
      data-test-id="raadgiverPopupButton"
    >
      {{ 'DL.NT01.C47' | content }}
    </button>
  </div>
</mat-dialog-actions>
