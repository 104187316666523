<div mat-dialog-title>
  <h3 [coContent]="'DL.FF01.C01'"></h3>
  <a
    (click)="closeButtonClicked()"
    class="popup-close-button"
    data-test-id="ST01-consentPopup-close"
  ></a>
</div>
<mat-dialog-content>
  <co-preconditions
    [selectedMenu]="selected"
    [showModal]="true"
    (click)="onTextClick($event.target)"
  ></co-preconditions>
</mat-dialog-content>
<mat-dialog-actions> </mat-dialog-actions>
