/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ServiceInfo } from './serviceInfo';
import { ServiceInfoSchema } from './serviceInfo';
import { MinUdbPlan } from './minUdbPlan';
import { MinUdbPlanSchema } from './minUdbPlan';
import { IndbetalingEfterOensketPensionsalder } from './indbetalingEfterOensketPensionsalder';
import { IndbetalingEfterOensketPensionsalderSchema } from './indbetalingEfterOensketPensionsalder';
import { RetirementAge } from './retirementAge';
import { RetirementAgeSchema } from './retirementAge';
import { UdbetalingsplanDetaljer } from './udbetalingsplanDetaljer';
import { UdbetalingsplanDetaljerSchema } from './udbetalingsplanDetaljer';
import { ResourceModel } from './resourceModel';
import { ResourceModelSchema } from './resourceModel';
import { AdvisorCorrectionsDetails } from './advisorCorrectionsDetails';
import { AdvisorCorrectionsDetailsSchema } from './advisorCorrectionsDetails';
import { MaeglerInfo } from './maeglerInfo';
import { MaeglerInfoSchema } from './maeglerInfo';
import { NuvaerendeIndbetalinger } from './nuvaerendeIndbetalinger';
import { NuvaerendeIndbetalingerSchema } from './nuvaerendeIndbetalinger';
import * as JoiObj from "joi";


    export const PPUdbetalingsplanDetaljerSchema = JoiObj.object({
        serviceInfo:
            
        JoiObj.link('#ServiceInfoSchema')

.allow(null)



        ,
        advisorCorrectionsDetails:
            
        JoiObj.link('#AdvisorCorrectionsDetailsSchema')


.required()


        ,
        dateOfBirth:
            
            JoiObj.date()


.required()


        ,
        erPrimaerPoliceLivnet:
            
                    JoiObj.boolean()
                


.required()


        ,
        fastLaast:
            
                    JoiObj.boolean()
                


.required()


        ,
        fradragUdfyldt:
            
                    JoiObj.boolean()
                


.required()


        ,
        harGipp:
            
                    JoiObj.boolean()
                


.required()


        ,
        harKontaktoplysningerUdenKommunekode:
            
                    JoiObj.boolean()
                


.required()


        ,
        indbetalingEfterOensketPensionsalder:
                JoiObj.array().items(
        
        JoiObj.link('#IndbetalingEfterOensketPensionsalderSchema')

        )
        

.required()


        ,
        kanOptimeres:
            
                    JoiObj.boolean()
                


.required()


        ,
        kunForsikringer:
            
                    JoiObj.boolean()
                


.required()


        ,
        livnetUnderudbetaling:
            
                    JoiObj.boolean()
                


.required()


        ,
        loebendeIndbetaling:
            
                    JoiObj.boolean()
                


.required()


        ,
        loenAendret:
            
                    JoiObj.boolean()
                


.required()


        ,
        maeglerBetjent:
            
                    JoiObj.boolean()
                


.required()


        ,
        maeglerInfo:
            
        JoiObj.link('#MaeglerInfoSchema')


.required()


        ,
        minUdPlan:
            
        JoiObj.link('#MinUdbPlanSchema')


.required()


        ,
        nuvaerendeIndbetalinger:
            
        JoiObj.link('#NuvaerendeIndbetalingerSchema')


.required()


        ,
        paragraf39:
            
                    JoiObj.boolean()
                


.required()


        ,
        retirementAge:
            
        JoiObj.link('#RetirementAgeSchema')


.required()


        ,
        simuleringFejler:
            
                    JoiObj.boolean()
                


.required()


        ,
        skjulRingMigOp:
            
                    JoiObj.boolean()
                


.required()


        ,
        tidligstePensionsalder:
            
                    JoiObj.number()
                


.required()


        ,
        udbetalingsplanDetaljer:
            
        JoiObj.link('#UdbetalingsplanDetaljerSchema')


.required()


        ,
        udenOpsparing:
            
                    JoiObj.boolean()
                


.required()


        
    })
                .shared(ServiceInfoSchema)
                .shared(AdvisorCorrectionsDetailsSchema)
                .shared(IndbetalingEfterOensketPensionsalderSchema)
                .shared(MaeglerInfoSchema)
                .shared(MinUdbPlanSchema)
                .shared(NuvaerendeIndbetalingerSchema)
                .shared(RetirementAgeSchema)
                .shared(UdbetalingsplanDetaljerSchema)
.id('PPUdbetalingsplanDetaljerSchema')

export interface PPUdbetalingsplanDetaljer extends ResourceModel { 
    advisorCorrectionsDetails: AdvisorCorrectionsDetails;
    dateOfBirth: Date;
    erPrimaerPoliceLivnet: boolean;
    fastLaast: boolean;
    fradragUdfyldt: boolean;
    harGipp: boolean;
    harKontaktoplysningerUdenKommunekode: boolean;
    indbetalingEfterOensketPensionsalder: Array<IndbetalingEfterOensketPensionsalder>;
    kanOptimeres: boolean;
    kunForsikringer: boolean;
    livnetUnderudbetaling: boolean;
    loebendeIndbetaling: boolean;
    loenAendret: boolean;
    maeglerBetjent: boolean;
    maeglerInfo: MaeglerInfo;
    minUdPlan: MinUdbPlan;
    nuvaerendeIndbetalinger: NuvaerendeIndbetalinger;
    paragraf39: boolean;
    retirementAge: RetirementAge;
    simuleringFejler: boolean;
    skjulRingMigOp: boolean;
    tidligstePensionsalder: number;
    udbetalingsplanDetaljer: UdbetalingsplanDetaljer;
    udenOpsparing: boolean;
}

