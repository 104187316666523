/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ServiceInfo } from './serviceInfo';
import { ServiceInfoSchema } from './serviceInfo';
import { ResourceModel } from './resourceModel';
import { ResourceModelSchema } from './resourceModel';
import * as JoiObj from "joi";


    export const IndbetalingSchema = JoiObj.object({
        serviceInfo:
            
        JoiObj.link('#ServiceInfoSchema')

.allow(null)



        ,
        beloeb:
            
                    JoiObj.number()
                


.required()


        ,
        eksternoverfoersel:
            
                    JoiObj.boolean()
                


.required()


        ,
        firmaNavn:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        fraDato:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        gippContentId:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        gippKrPolicenummer:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        gippLinkContentId:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        indbetalerTekstContentId:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        indbetalingsDato:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        indregnetDato:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        indregnetDatoContentId:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        periodeTekstContentId:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        tilDato:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        
    })
                .shared(ServiceInfoSchema)
.id('IndbetalingSchema')

export interface Indbetaling extends ResourceModel { 
    beloeb: number;
    eksternoverfoersel: boolean;
    firmaNavn: string;
    fraDato: string;
    gippContentId: string;
    gippKrPolicenummer: string;
    gippLinkContentId: string;
    indbetalerTekstContentId: string;
    indbetalingsDato: string;
    indregnetDato: string;
    indregnetDatoContentId: string;
    periodeTekstContentId: string;
    tilDato: string;
}

