<!-- v1 -->
<svg
  id="Layer_1"
  data-name="Layer 1"
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 32 32"
>
  <g id="Canvas">
    <circle
      id="Large_circle"
      data-name="Large circle"
      cx="16"
      cy="16"
      r="16"
      fill="none"
    />
    <circle
      id="Small_circle"
      data-name="Small circle"
      cx="16"
      cy="16"
      r="12"
      fill="none"
    />
  </g>
  <g id="Icon">
    <line
      x1="9.5"
      y1="16.9"
      x2="9.5"
      y2="22.5"
      fill="none"
      stroke="#000"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.2"
    />
    <path
      d="M9.5,21.57h9.33A1.87,1.87,0,0,0,17,19.7H14.63a1.86,1.86,0,0,0-1.86-1.86H9.5"
      fill="none"
      stroke="#000"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.2"
    />
    <line
      x1="12.77"
      y1="19.7"
      x2="14.63"
      y2="19.7"
      fill="none"
      stroke="#000"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.2"
    />
    <path
      d="M19.57,8.8l1.25,2.46H23a.55.55,0,0,1,.39.94L21.4,14.11l1.08,2.49a.55.55,0,0,1-.28.72.56.56,0,0,1-.49,0l-2.63-1.48L16.45,17.3a.56.56,0,0,1-.75-.21.56.56,0,0,1,0-.49l1.08-2.49L14.82,12.2a.55.55,0,0,1,0-.78.59.59,0,0,1,.4-.16h2.13L18.58,8.8a.56.56,0,0,1,.74-.25A.55.55,0,0,1,19.57,8.8Z"
      fill="none"
      stroke="#000"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.2"
    />
  </g>
</svg>
