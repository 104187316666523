/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const BeneficiaryDocumentViewSchema = JoiObj.object({
        beneficiary:
            
                    JoiObj.boolean()
                


.required()


        ,
        registrationTime:
            
                    JoiObj.number()
                


.required()


        ,
        separateProperties:
            
                    JoiObj.boolean()
                


.required()


        ,
        tiedUpFunds:
            
                    JoiObj.boolean()
                


.required()


        ,
        token:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        will:
            
                    JoiObj.boolean()
                


.required()


        
    })
.id('BeneficiaryDocumentViewSchema')

export interface BeneficiaryDocumentView { 
    beneficiary: boolean;
    registrationTime: number;
    separateProperties: boolean;
    tiedUpFunds: boolean;
    token: string;
    will: boolean;
}

