import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Moment } from 'moment';
import * as _moment from 'moment';

const moment = _moment;

@Component({
  selector: 'co-datetimepicker',
  templateUrl: './datetimepicker.component.html',
  styleUrls: ['./datetimepicker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatetimepickerComponent),
      multi: true
    }
  ]
})
export class DatetimepickerComponent implements ControlValueAccessor {
  @Input() _dateValue: Moment;
  @Input() required: boolean;
  @Input() description: string;
  @Input() dateLabel: string;
  @Input() hourLabel: string;
  @Input() minuteLabel: string;

  get dateValue() {
    return this._dateValue;
  }

  set dateValue(val) {
    this._dateValue = val;
    this.propagateChange(this.formatDate());
  }

  public hoursValue: number;
  public minutesValue: number;

  writeValue(value: Moment): void {
    if (value !== undefined) {
      this.dateValue = value;
    }
  }

  propagateChange = (_: any) => {};

  registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    // possible required by mat-datepicker
  }

  formatDate(): string {
    if (
      this.dateValue &&
      moment.isMoment(this.dateValue) &&
      this.hoursValue &&
      this.minutesValue
    ) {
      return `${this.dateValue.format('DD.MM.YYYY')} ${this.hoursValue}:${
        this.minutesValue
      }:00`;
    } else {
      return null;
    }
  }

  timepickerChanged() {
    if (this.hoursValue || this.hoursValue === 0) {
      if (this.hoursValue < 0) {
        this.hoursValue = 0;
      } else if (this.hoursValue > 23) {
        this.hoursValue = 23;
      }
      this.hoursValue = this.padWithZero(this.hoursValue);
    }
    if (this.minutesValue || this.minutesValue === 0) {
      if (this.minutesValue < 0) {
        this.minutesValue = 0;
      } else if (this.minutesValue > 59) {
        this.minutesValue = 59;
      }
      this.minutesValue = this.padWithZero(this.minutesValue);
    }
    this.propagateChange(this.formatDate());
  }
  padWithZero(val) {
    if (val.toString().length === 1) {
      return '0' + val;
    } else {
      return val;
    }
  }
}
