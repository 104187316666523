/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const EksternOverfoerselJobSkifteSchema = JoiObj.string().valid(
        'MINDRE_END_3_AAR'
        ,
        'INTERN'
        ,
        'SMALL_PAIDUP_POLICY'
        
    )
.id('EksternOverfoerselJobSkifteSchema')

export type EksternOverfoerselJobSkifte = 'MINDRE_END_3_AAR' | 'INTERN' | 'SMALL_PAIDUP_POLICY';

export const EksternOverfoerselJobSkifte = {
    MindreEnd3Aar: 'MINDRE_END_3_AAR' as EksternOverfoerselJobSkifte,
    Intern: 'INTERN' as EksternOverfoerselJobSkifte,
    SmallPaidupPolicy: 'SMALL_PAIDUP_POLICY' as EksternOverfoerselJobSkifte
};

