import { Inject, Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PensionskundeStore } from '@pfa/gen';
import { CustomerSurveyConsentComponent } from './customer-survey-consent/customer-survey-consent.component';
import { CookieService } from '@pfaform';
import * as moment from 'moment';
import { NavigationEnd, Router } from '@angular/router';
import { of, timer } from 'rxjs';
import { filter, switchMap, take } from 'rxjs/operators';
import { MitPFAEnvironment, PopupStyle } from '@pfa/models';
import { SurveyTrackingService, SurveyAction } from '@pfa/core';

@Injectable()
export class CustomerSurveyService {
  private consentDialog: MatDialogRef<CustomerSurveyConsentComponent>;

  constructor(
    private readonly dialog: MatDialog,
    private readonly cookieService: CookieService,
    private readonly pensionskundeStore: PensionskundeStore,
    private readonly surveyTrackingService: SurveyTrackingService,
    private readonly router: Router,
    @Inject('environment') private readonly environment: MitPFAEnvironment
  ) {}

  initConsentDialog() {
    const userAlreadyDecided =
      this.cookieService.getCookie('noToSurvey') ||
      this.cookieService.getCookie('yesToSurvey');

    const onceUserIsOnMainPage = () => {
      const isMainPage = currentRoute =>
        ['/mitoverblik'].some(route => currentRoute.startsWith(route));
      if (isMainPage(this.router.routerState.snapshot.url)) {
        return of({});
      } else {
        return this.router.events.pipe(
          filter(event => event instanceof NavigationEnd),
          filter((event: NavigationEnd) => isMainPage(event.urlAfterRedirects)),
          take(1)
        );
      }
    };

    if (userAlreadyDecided) {
      return;
    }

    timer(this.environment.customerSurveyDelay)
      .pipe(switchMap(onceUserIsOnMainPage))
      .subscribe(() => {
        this.openConsentDialog();
      });
  }

  private openConsentDialog() {
    this.pensionskundeStore
      .pensionskundeGet()
      .pipe(filter(data => !data.isRaadgiver))
      .subscribe(data => {
        this.consentDialog = this.dialog.open(CustomerSurveyConsentComponent, {
          panelClass: PopupStyle.PopupLarge
        });

        this.consentDialog.afterOpened().subscribe(() => {
          this.surveyTrackingService.trackSurveyTake(
            data.surveyUuid,
            SurveyAction.INIT
          );
        });

        this.consentDialog.afterClosed().subscribe(accepted => {
          let surveyAction: SurveyAction;
          if (accepted) {
            this.acceptSurvey();
            surveyAction = SurveyAction.ACCEPT;
          } else if (accepted === false) {
            this.declineSurvey();
            surveyAction = SurveyAction.DECLINE;
          } else {
            surveyAction = SurveyAction.CLOSE;
          }
          this.surveyTrackingService.trackSurveyTake(
            data.surveyUuid,
            surveyAction
          );
        });
      });
  }

  private acceptSurvey() {
    this.cookieService.setCookie({
      name: 'yesToSurvey',
      value: '1',
      expires: moment().add(1, 'days').toDate()
    });
  }

  private declineSurvey() {
    this.cookieService.setCookie({
      name: 'noToSurvey',
      value: '1',
      expires: moment().add(90, 'days').toDate()
    });
  }

  setPostSurveyCookie() {
    this.cookieService.removeCookie('yesToSurvey');
    this.declineSurvey();
  }
}
