import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MyCommonModule } from '@pfa/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MessagingService } from './messaging.service';
import { MessagingComponent } from './messaging.component';
import { MessagingHeaderComponent } from './messaging-header/messaging-header.component';
import { ThreadlistComponent } from './threadlist/threadlist.component';
import { ThreadlistItemComponent } from './threadlist/threadlistItem.component';
import { ThreadComponent } from './thread/thread.component';
import { NewMessageComponent } from './new-message/new-message.component';
import { BlurItDirective } from './directives/blurIt.directive';
import { GrowItDirective } from './directives/growIt.directive';
import { CoreModule } from '@pfa/core';
import { IconModule } from '@pfa/icons';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { PfaFormModule } from '@pfaform';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatButtonModule } from '@angular/material/button';
import { DynamicFormComponent } from './dynamic-form/dynamic-form.component';
import { DynamicFormService } from './dynamic-form/dynamic-form.service';
import { DynamicFormUtilsComponent } from './dynamic-form/dynamic-form-utils.component';

@NgModule({
  imports: [
    CommonModule,
    MyCommonModule,
    FormsModule,
    ReactiveFormsModule,
    CoreModule,
    IconModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    PfaFormModule,
    MatSelectModule,
    MatRadioModule,
    MatDatepickerModule,
    MatButtonModule
  ],
  providers: [MessagingService, DynamicFormService, DynamicFormUtilsComponent],
  declarations: [
    MessagingComponent,
    MessagingHeaderComponent,
    ThreadlistComponent,
    ThreadlistItemComponent,
    ThreadComponent,
    NewMessageComponent,
    BlurItDirective,
    GrowItDirective,
    DynamicFormComponent
  ],
  exports: [MessagingComponent, DynamicFormComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class MessagingModule {}
