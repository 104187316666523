<div *ngIf="coverages?.coverages.length > 0">
  <div *ngIf="!noHeader">
    <h2 [coContent]="coverages.header"></h2>
    <p *ngIf="coverages.subHeader" [coContent]="coverages.subHeader"></p>
  </div>

  <co-accordion
    [hideControls]="noAccordion"
    [initExpanded]="initExpanded"
    [title]="coverages.closedText"
    [titleOpen]="coverages.openText"
    [ngClass]="{ 'accordion--transparent accordion--flat': noAccordionShadow }"
  >
    <table
      class="pfa-table table-responsive align-center--column-2 align-right--column-3 align-right--column-4 thead-align--center"
    >
      <caption class="align--center">
        {{
          coverages.headerNote | content
        }}
      </caption>
      <thead>
        <tr>
          <th
            *ngFor="let header of coverages.headers; index as idx"
            id="header-{{ idx }}"
          >
            {{ header | content }}
          </th>
        </tr>
      </thead>
      <tbody>
        <ng-container
          *ngFor="let coverage of coverages.coverages; let index = index"
        >
          <tr [ngClass]="{ 'uneven-row': index % 2 !== 0 }">
            <td [attr.data-label]="coverages.headers[0] | content">
              <div>
                <span class="text--bold" [coContent]="coverage.company"></span>
                <co-tooltip
                  *ngIf="coverage.workAndLeisureNote"
                  [text]="coverage.workAndLeisureNote"
                ></co-tooltip>
              </div>
              <div class="coverages-note">{{ coverage.policyNote }}</div>
            </td>

            <td
              *ngIf="coverage.workAndLeisureChecked !== undefined"
              [attr.data-label]="coverages.headers[1] | content"
            >
              <co-icon-circle-checkmark
                *ngIf="coverage.workAndLeisureChecked"
              ></co-icon-circle-checkmark>
              <co-icon-doubt
                *ngIf="!coverage.workAndLeisureChecked"
              ></co-icon-doubt>
            </td>
            <td
              *ngIf="coverage.workAndLeisureChecked !== undefined"
              [attr.data-label]="coverages.headers[2] | content"
            >
              <co-icon-circle-checkmark
                *ngIf="coverage.workAndLeisureChecked"
              ></co-icon-circle-checkmark>
              <co-icon-doubt
                *ngIf="!coverage.workAndLeisureChecked"
              ></co-icon-doubt>
            </td>
            <td
              *ngIf="
                coverage.amount === undefined &&
                coverage.type !== 'GIPP_KUNDE_KAPITAL'
              "
              [attr.data-label]="coverages.headers[3] | content"
            >
              <co-icon-circle-checkmark></co-icon-circle-checkmark>
            </td>
            <td
              *ngIf="coverage.formOfPayment !== undefined"
              [attr.data-label]="coverages.headers[1] | content"
            >
              {{ coverage.formOfPayment | content }}
              <div class="coverages-note">
                {{ coverage.formOfPaymentNote }}
              </div>
            </td>
            <td
              *ngIf="coverage.type === 'GIPP_KUNDE_KAPITAL'"
              [attr.data-label]="coverages.headers[2] | content"
              style="text-align: center !important"
            >
              -
            </td>
            <td
              *ngIf="
                coverage.amount !== undefined &&
                coverage.type !== 'GIPP_KUNDE_KAPITAL'
              "
              [ngClass]="{ emphasize: coverage.emphasize }"
              class="text--bold"
              [attr.data-label]="coverages.headers[2] | content"
            >
              {{ coverage.amount | number: '1.0-0' }}
              <span [coContent]="'Global.kroner'"></span> <br />
              <div class="coverages-note">
                {{ coverage.amountNote | content }}
              </div>
            </td>
            <td
              *ngIf="coverage.amount2 !== undefined"
              [ngClass]="{ emphasize: coverage.emphasize2 }"
              [attr.data-label]="coverages.headers[3] | content"
              class="text--bold"
            >
              {{ coverage.amount2 | number: '1.0-0' }}
              <span [coContent]="'Global.kroner'"></span> <br />
              <div class="coverages-note">
                {{ coverage.amount2Note | content }}
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="4" class="spacer-row">&nbsp;</td>
          </tr>
        </ng-container>
      </tbody>
      <tfoot>
        <tr class="result-row" *ngIf="coverages.results?.length === 0">
          <td [colSpan]="coverages.headers.length"></td>
        </tr>
        <ng-container *ngFor="let result of coverages.results">
          <tr class="result-row">
            <td class="text--bold">
              <div>
                {{ result.text | content }}
                <co-tooltip
                  *ngIf="result.textInfo"
                  [text]="result.textInfo"
                ></co-tooltip>
              </div>
            </td>
            <td
              class="result-row"
              [ngClass]="{ 'cell-hide': !result.formOfPayment }"
            >
              <div>
                {{ result.formOfPayment | content }}
              </div>
            </td>
            <td
              [ngClass]="{ emphasize: result.emphasize }"
              class="result-row"
              [attr.data-label]="coverages.headers[2] | content"
            >
              <div>
                <co-primary-number
                  class="size-sm"
                  *ngIf="result.emphasizeAmount"
                  [amount]="result.amount"
                  [unit]="'Global.kroner'"
                ></co-primary-number>
                <div *ngIf="!result.emphasizeAmount">
                  {{ result.amount | number: '1.0-0' }}
                  <span [coContent]="'Global.kroner'"></span> <br />
                </div>
                <div class="coverages-note">
                  {{ result.amountNote | content }}
                </div>
              </div>
            </td>
            <td
              [ngClass]="{ emphasize: result.emphasize2 }"
              *ngIf="result.amount2 !== undefined"
              class="result-row"
              [attr.data-label]="coverages.headers[3] | content"
            >
              <div>
                <co-primary-number
                  class="size-sm"
                  *ngIf="result.emphasizeAmount2"
                  [amount]="result.amount2"
                  [unit]="'Global.kroner'"
                ></co-primary-number>
                <div *ngIf="!result.emphasizeAmount2">
                  {{ result.amount2 | number: '1.0-0' }}
                  <span [coContent]="'Global.kroner'"></span> <br />
                </div>
                <div class="coverages-note">
                  {{ result.amount2Note | content }}
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="4" class="spacer-row">&nbsp;</td>
          </tr>
        </ng-container>
      </tfoot>
    </table>
    <div *ngIf="coverages.footer" class="bottom-note--wrapper">
      <co-info class="bottom-note">
        <span [coContent]="coverages.footer"></span>
      </co-info>
    </div>
  </co-accordion>
  <ng-template #content></ng-template>
</div>
