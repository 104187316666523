<svg
  id="Layer_1"
  data-name="Layer 1"
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 32 32"
>
  <g id="Canvas">
    <circle
      id="Large_circle"
      data-name="Large circle"
      cx="16"
      cy="16"
      r="16"
      fill="none"
    />
    <circle
      id="Small_circle"
      data-name="Small circle"
      cx="16"
      cy="16"
      r="12"
      fill="none"
    />
  </g>
  <g id="Icon">
    <circle
      id="Circle"
      cx="16"
      cy="16"
      r="7.25"
      fill="none"
      stroke="#000"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.2"
    />
    <path
      id="Line"
      d="M16,17.1a.91.91,0,0,1-.9-.84l-.3-3.1a1.13,1.13,0,0,1,.29-.86,1.28,1.28,0,0,1,1.8,0l0,0a1.13,1.13,0,0,1,.29.86l-.3,3.1A.91.91,0,0,1,16,17.1Z"
    />
    <circle id="Dot" cx="16" cy="19" r="1.1" />
  </g>
</svg>
