<div class="text--container bottom-space">
  <div class="header text--manchet">
    <div
      *ngIf="!changeHeadline && showInsurance"
      [coContent]="'DL.VK01.C129'"
    ></div>
    <div
      *ngIf="!changeHeadline && showInvestment && !showInsurance"
      [coContent]="'DL.VK01.C121'"
    ></div>
    <div
      *ngIf="!changeHeadline && !showInvestment && !showInsurance"
      [coContent]="'DL.VK01.C38'"
    ></div>
    <div *ngIf="changeHeadline" [coContent]="'DL.VK01.C143'"></div>
  </div>
</div>

<mitpfa-onboarding-step-animation
  [firstCard]="firstCard"
  [doneCards]="doneCards"
>
  <ng-template #firstCard>
    <ng-container *ngIf="showInsurance">
      <mitpfa-svg-insurance class="mobile--step1"></mitpfa-svg-insurance>
      <div class="wrapper">
        <div>
          <div class="headline">
            <div
              class="text--grey text--bold"
              [coContent]="'DL.VK01.C130'"
            ></div>
            <div class="number--icon">{{ stepNumber }}</div>
          </div>
          <div class="text--grey body-text" [coContent]="'DL.VK01.C131'"></div>
          <button
            mat-raised-button
            class="mat-focus-indicator mat-button-bas move-on"
            disabled="true"
            [coContent]="'DL.VK01.C132'"
          ></button>
        </div>
        <div class="container--desktop">
          <mitpfa-svg-insurance class="image--desktop"></mitpfa-svg-insurance>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="!showInsurance && showInvestment">
      <mitpfa-svg-investmentguide
        class="mobile--step1"
      ></mitpfa-svg-investmentguide>
      <div class="wrapper">
        <div>
          <div class="headline">
            <div
              class="text--grey text--bold"
              [coContent]="'DL.VK01.C122'"
            ></div>
            <div class="number--icon">{{ stepNumber }}</div>
          </div>
          <div class="text--grey body-text" [coContent]="'DL.VK01.C123'"></div>
          <button
            mat-raised-button
            class="mat-focus-indicator mat-button-bas move-on"
            disabled="true"
            [coContent]="'DL.VK01.C124'"
          ></button>
        </div>
        <div class="container--desktop">
          <mitpfa-svg-investmentguide
            class="image--desktop"
          ></mitpfa-svg-investmentguide>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="!showInsurance && !showInvestment">
      <mitpfa-svg-savings class="mobile--step1"></mitpfa-svg-savings>
      <div class="wrapper">
        <div>
          <div class="headline">
            <div
              class="text--grey text--bold"
              [coContent]="'DL.VK01.C39'"
            ></div>
            <div class="number--icon disabled">{{ stepNumber }}</div>
          </div>
          <div class="text--grey body-text" [coContent]="'DL.VK01.C40'"></div>
          <button
            mat-raised-button
            class="mat-focus-indicator mat-button-bas move-on"
            disabled="true"
            [coContent]="'DL.VK01.C41'"
          ></button>
        </div>
        <div class="container--desktop">
          <mitpfa-svg-savings class="image--desktop"></mitpfa-svg-savings>
        </div>
      </div>
    </ng-container>
  </ng-template>
</mitpfa-onboarding-step-animation>
