import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Debt } from '@pfa/gen';

@Injectable()
export class InvestmentGuideHasDebtService {
  shouldPickDetails(debt: Debt): boolean {
    const result = debt.hasDebt;
    return result;
  }

  setDebt(hasDebt: boolean, formGroup: UntypedFormGroup): Debt {
    const debt = {} as Debt;
    debt.hasDebt = hasDebt;
    if (!hasDebt) {
      debt.currentDebt = 0;
      return debt;
    }

    debt.currentDebt = formGroup.value['currentDebt'];
    return debt;
  }

  clearDebt(formGroup: UntypedFormGroup) {
    formGroup.value['currentDebt'] = 0;
  }
}
