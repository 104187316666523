/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const FondeFordelingEnumSchema = JoiObj.string().valid(
        'HOEJ_RISIKO'
        ,
        'LAV_RISIKO'
        ,
        'VARIGHEDSFOND'
        
    )
.id('FondeFordelingEnumSchema')

export type FondeFordelingEnum = 'HOEJ_RISIKO' | 'LAV_RISIKO' | 'VARIGHEDSFOND';

export const FondeFordelingEnum = {
    HoejRisiko: 'HOEJ_RISIKO' as FondeFordelingEnum,
    LavRisiko: 'LAV_RISIKO' as FondeFordelingEnum,
    Varighedsfond: 'VARIGHEDSFOND' as FondeFordelingEnum
};

