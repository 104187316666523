import { Component, Input } from '@angular/core';
import {
  GebyrLinieElement,
  LinieElement,
  OpsparingPoliceDetaljer
} from '@pfa/gen';

@Component({
  selector: 'mitpfa-savings-policy-details-marketrate-subheader-block',
  templateUrl: './marketrate-subheader-block.component.html'
})
export class MarketrateSubheaderBlockComponent {
  @Input() savingsPolicyDetails: OpsparingPoliceDetaljer;
  @Input() headerContentId: string;
  @Input() lineElements: Array<LinieElement>;
  @Input() feeLineElements: Array<GebyrLinieElement>;
  @Input() isCommissionAndRemuneration: boolean;

  expanded: boolean;

  toggleExpanded(expanded: boolean): void {
    this.expanded = expanded;
  }
}
