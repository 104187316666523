/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const BeneficiaryAdvisoryBeneficiariesSchema = JoiObj.string().valid(
        'OTHER'
        ,
        'NEXT_OF_KIN'
        
    )
.id('BeneficiaryAdvisoryBeneficiariesSchema')

export type BeneficiaryAdvisoryBeneficiaries = 'OTHER' | 'NEXT_OF_KIN';

export const BeneficiaryAdvisoryBeneficiaries = {
    Other: 'OTHER' as BeneficiaryAdvisoryBeneficiaries,
    NextOfKin: 'NEXT_OF_KIN' as BeneficiaryAdvisoryBeneficiaries
};

