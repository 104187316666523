import {
  Component,
  Input,
  OnChanges,
  ChangeDetectionStrategy,
  Optional,
  Self,
  HostBinding,
  OnInit,
  OnDestroy,
  Output,
  EventEmitter,
  SimpleChanges
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  NgControl,
  Validators
} from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { Options } from 'ngx-slider-v2';
import { Observable, Subscription } from 'rxjs';
import { startWith, map, tap } from 'rxjs/operators';
import { numberAsStringValidator, Utils } from '@pfa/utils';
import { fadeInOut } from '@pfa/animations';

@Component({
  selector: 'co-slider-form',
  templateUrl: './slider-form.component.html',
  styleUrls: ['./slider-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: MatFormFieldControl, useExisting: SliderFormComponent }
  ],
  animations: [fadeInOut]
})
export class SliderFormComponent implements OnInit, OnChanges, OnDestroy {
  @Input() label?: string;
  @Input() valueNoteAfterTax?: number;
  @Input() valueNoteBeforeTax?: number;
  @Input() unit?: string = '';
  @Input() decimals?: number;
  @Input() validateInputMax?: boolean;
  @Input() type: 'number' | 'currency' = 'number';
  @Input() deactivate?: boolean = false;
  @Input() sliderOptions?: Options;
  @Output() sliderEnd? = new EventEmitter<number>();
  @Output() inputUpdated? = new EventEmitter<number>();
  @HostBinding('attr.aria-describedby') describedBy = '';

  public displayTypeItForm = false;
  public activeSliderOptions: Options;
  public formControlName: string;
  public value: number;
  public displayedValue$: Observable<string>;
  private sliderPreviousValue = 0;
  private subscriptions: { [subscribtionKey: string]: Subscription } = {};

  private typingTimer;
  private readonly doneTypingInterval = 1000;

  public form: UntypedFormGroup;

  constructor(@Optional() @Self() public ngControl: NgControl) {
    if (this.ngControl !== null) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit(): void {
    if (!this.ngControl) {
      return;
    }

    if (this.decimals === undefined) {
      this.decimals = 0;
    }

    this.form = new UntypedFormGroup({
      slider: new UntypedFormControl(this.value),
      input: new UntypedFormControl(0, [
        Validators.maxLength(9),
        numberAsStringValidator()
      ])
    });
    if (this.sliderOptions?.disabled) {
      this.deactivate = true;
    }
    this.updateSliderOptions();

    if (this.value > this.activeSliderOptions.ceil) {
      this.form.controls.slider.setValue(0);
      this.form.controls.input.setValue(this.value);
      this.displayTypeItForm = true;
      this.updateSliderOptions();
    }

    this.displayedValue$ = this.ngControl.control.valueChanges.pipe(
      tap(value => this.onChangeModelValue(value)),
      map(value => this.translateLabels(value)),
      startWith(this.translateLabels(this.value))
    );

    this.subscriptions.onSliderValueChange =
      this.form.controls.slider.valueChanges.subscribe(
        value =>
          !this.displayTypeItForm &&
          this.validateAndSetValue(value, this.form.controls.slider)
      );

    this.subscriptions.onInputValueChange =
      this.form.controls.input.valueChanges.subscribe(value => {
        this.validateAndSetValue(value, this.form.controls.input);
        return this.displayTypeItForm;
      });
  }

  ngOnDestroy(): void {
    Object.keys(this.subscriptions).forEach(
      key => this.subscriptions[key] && this.subscriptions[key].unsubscribe()
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    const previousOptions = changes['sliderOptions']?.previousValue;
    const currentOptions = changes['sliderOptions']?.currentValue;
    if (previousOptions !== undefined) {
      // workaround for updating both selectedValue and sliderOptions
      if (currentOptions !== undefined) {
        // update activeSliderOptions
        this.updateSliderOptions();
        this.updateDisplayTypeItFormAndControlValue(
          this.value,
          !this.form.dirty && !this.ngControl.control.dirty
        );
      }
      // update activeSliderOptions again
      this.updateSliderOptions();
    }

    if (
      changes['deactivate'] &&
      changes['deactivate'].currentValue !== changes['deactivate'].previousValue
    ) {
      this.updateSliderOptions();
    }
  }

  private onChangeModelValue(value: any): void {
    if (
      (this.displayTypeItForm && value === this.form.controls.input.value) ||
      (!this.displayTypeItForm && value === this.form.controls.slider.value)
    ) {
      return;
    }
    this.updateDisplayTypeItFormAndControlValue(value);
    this.updateSliderOptions();
  }

  private updateDisplayTypeItFormAndControlValue(
    value: any,
    markAsPristine = false
  ): void {
    let adjustedValue = value;
    if (adjustedValue > this.activeSliderOptions.ceil) {
      adjustedValue = this.activeSliderOptions.ceil;
      this.displayTypeItForm = true;
    } else if (adjustedValue < this.activeSliderOptions.floor) {
      adjustedValue = this.activeSliderOptions.floor;
      this.displayTypeItForm = true;
    }
    this.form.controls.slider.setValue(adjustedValue);
    this.form.controls.input.setValue(adjustedValue);
    // setValue set up form as dirty, to avoid it, use markAsPristine
    if (markAsPristine) {
      this.form.markAsPristine();
      this.ngControl.control.markAsPristine();
    }
  }

  setDescribedByIds(ids: string[]): void {
    this.describedBy = ids.join(' ');
  }

  private validateAndSetValue(
    newValue: string | number,
    formControl: AbstractControl
  ): void {
    if (formControl.invalid) {
      this.ngControl.control.setErrors({ invalidSliderFormInput: true });
      return;
    } else {
      this.ngControl.control.setErrors(null);
    }
    this.onChange(newValue);
  }

  public onSliderValueChange(newValue: number): void {
    if (this.displayTypeItForm) {
      return;
    }

    const currentSliderValue = this.form.value.slider;

    //Bugfix: Handle issue with slider value updates for some reason being performed on load of slider-form for original decimal value only
    const isRoundedSliderValDiffFromNewValue =
      Math.round(currentSliderValue) !== newValue;
    if (isRoundedSliderValDiffFromNewValue) {
      this.form.controls.slider.setValue(newValue);
    }
  }

  public toggleTypeIt(): void {
    this.displayTypeItForm = !this.displayTypeItForm;

    if (this.displayTypeItForm) {
      this.sliderPreviousValue = this.form.value.slider;
      this.form.controls.input.setValue(this.form.value.slider);
      if (this.form.controls.input.value !== this.ngControl.control.value) {
        this.onChange(this.form.controls.input.value);
      }
    } else {
      this.form.controls.input.setValue(0);
      this.form.controls.slider.setValue(this.sliderPreviousValue);
      if (this.sliderEnd) {
        this.onSliderEnd(this.sliderPreviousValue);
      }
    }

    this.updateSliderOptions();
  }

  private updateSliderOptions(): void {
    this.activeSliderOptions = {
      floor: 0,
      hidePointerLabels: true,
      translate: this.translateLabels.bind(this),
      ceil: 100,
      ...this.sliderOptions,
      disabled:
        this.deactivate ||
        this.sliderOptions?.disabled ||
        this.displayTypeItForm
    };

    this.form?.controls.slider.setValidators([
      Validators.min(this.activeSliderOptions.floor),
      Validators.max(this.activeSliderOptions.ceil)
    ]);

    if (this.validateInputMax) {
      this.form?.controls.input.setValidators([
        Validators.min(this.activeSliderOptions.floor),
        Validators.max(this.activeSliderOptions.ceil),
        Validators.maxLength(9),
        numberAsStringValidator(),
        Validators.required
      ]);
    } else {
      this.form?.controls.input.setValidators([
        Validators.min(this.activeSliderOptions.floor),
        Validators.maxLength(9),
        numberAsStringValidator(),
        Validators.required
      ]);
    }
  }

  private translateLabels(value: number): string {
    const valueToDisplay = isNaN(value)
      ? this.activeSliderOptions.floor
      : value;
    return Utils.isNullOrUndefined(valueToDisplay)
      ? '0'
      : valueToDisplay.toString();
  }

  writeValue(value: number | string): void {
    this.value = typeof value === 'string' ? +value || 0 : value;
  }

  onContainerClick(): void {}

  registerOnChange(fn): void {
    this.onChange = fn;
  }

  registerOnTouched(): void {}

  onChange: any = () => {};

  onSliderEnd($event: number) {
    if (this.sliderEnd) {
      this.sliderEnd.emit($event);
    }
  }

  private updateInput(): void {
    if (this.form.controls.input.valid && this.inputUpdated) {
      if (this.decimals === undefined) {
        const value = parseInt(this.form.controls.input.value, 10);
        this.inputUpdated.emit(value);
      } else {
        this.inputUpdated.emit(this.form.controls.input.value);
      }
    }
  }

  inputKey() {
    clearTimeout(this.typingTimer);
    this.typingTimer = setTimeout(
      this.doneTyping(this),
      this.doneTypingInterval
    );
  }

  private doneTyping(myObject: this) {
    return function () {
      myObject.updateInput();
    };
  }
}
