import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MaeglerInfo } from '@pfa/gen';
import { InvestmentGuideInfo } from '@pfa/models';
import { ClimatePriorityChoice } from '../../investment-guide-climate-step.model';

@Component({
  selector: 'mitpfa-investment-guide-climate-priority',
  templateUrl: './investment-guide-climate-step-priority.component.html',
  styleUrls: ['./investment-guide-climate-step-priority.component.scss']
})
export class InvestmentGuideClimatePriorityComponent implements OnInit {
  @Input() investmentGuide: InvestmentGuideInfo;
  @Input() broker: MaeglerInfo;
  @Output() guideComplete = new EventEmitter();

  answer: UntypedFormControl;
  choices: ClimatePriorityChoice[] = [
    {
      text: 'DL.INVGU01.C553',
      value: 'HIGH_RETURN'
    },
    {
      text: 'DL.INVGU01.C554',
      value: 'CLIMATE_FRIENDLY'
    },
    {
      text: 'DL.INVGU01.C555',
      value: 'EQUALLY'
    }
  ];

  ngOnInit(): void {
    this.answer = new UntypedFormControl(
      this.investmentGuide.recommendationBasis.climatePriority,
      Validators.required
    );
  }

  choose(): void {
    this.investmentGuide.recommendationBasis.climatePriority =
      this.answer.value;
    this.guideComplete.emit();
  }
}
