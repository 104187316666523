<div *ngIf="pensionPlan">
  <div
    [coContent]="'DL.FG01.C456'"
    class="payment-form-text align--center"
  ></div>
  <table
    class="pfa-table table-responsive align-right--column-3 align-right--column-4 thead-align--center focus--column-4"
  >
    <thead>
      <tr>
        <th [coContent]="'DL.PO01.C96'" scope="col"></th>
        <th [coContent]="'DL.MD01.C374'" scope="col"></th>
        <th [coContent]="'DL.FG02.C325'" scope="col"></th>
        <th [coContent]="'DL.PO01.C87'" scope="col"></th>
      </tr>
    </thead>
    <tbody>
      <ng-container
        *ngFor="
          let coverage of pensionPlan.forsikring.livsDaekninger;
          let index = index
        "
      >
        <tr [ngClass]="{ 'uneven-row': index % 2 !== 0 }">
          <td [attr.data-label]="'DL.PO01.C96' | content">
            <div [coContent]="coverage.selskabsNr" class="text--bold"></div>
            <div class="text--note">
              <span [coContent]="'DL.OV01.C03'"></span>:
              <span>{{ coverage.aftalenummer }}</span>
            </div>
          </td>
          <td [attr.data-label]="'DL.MD01.C374' | content">
            <div
              [coContent]="coverage.textId"
              [coContentSubst]="coverage.textIdValues.join('|')"
            ></div>
            <div *ngIf="coverage.pawned" class="tag" coContent="DL.FG01.C1965">
              Pantsat ved oprettelse
            </div>
          </td>
          <td [attr.data-label]="'DL.FG02.C325' | content">
            <ng-container *ngIf="!coverage.hideAmountDetails">
              {{ coverage.forsikringsTal / 100 | numberFormat: 2 }}
              <span [coContent]="'DL.FG02.C326'"></span>
            </ng-container>
          </td>
          <td
            [attr.data-label]="'DL.PO01.C87' | content"
            class="cell-number cell-focus"
          >
            <ng-container *ngIf="!coverage.hideAmountDetails">
              <co-tooltip
                *ngIf="coverage.nettoBeloeb"
                [text]="coverage.netAmountText"
              ></co-tooltip>

              {{ coverage.beloeb | currencyFormat }}
              <div
                class="text--note"
                [coContent]="
                  coverage.omregningLoebendePension || coverage.skattefritBeloeb
                    ? 'DL.FG02.C327'
                    : 'DL.FG02.C328'
                "
              ></div>
            </ng-container>
          </td>
        </tr>
        <tr>
          <td colspan="4" class="spacer-row">&nbsp;</td>
        </tr>
      </ng-container>
    </tbody>
    <tfoot>
      <tr>
        <td [coContent]="'DL.FG01.C447'"></td>
        <td class="cell-hide"></td>
        <td [attr.data-label]="'DL.FG02.C325' | content">
          <co-tooltip
            *ngIf="pensionPlan.forsikring.livsTalAfrundet"
            [text]="'DL.FG01.I439'"
          ></co-tooltip>

          {{ pensionPlan.forsikring.livsTal / 100 | numberFormat: 2 }}
          <span [coContent]="'DL.FG02.C326'"></span>
        </td>
        <td [attr.data-label]="'DL.PO01.C87' | content">
          <co-primary-number
            class="size-sm"
            [amount]="pensionPlan.forsikring.ialtLivsforsikring"
          ></co-primary-number>
        </td>
      </tr>
      <tr>
        <td colspan="4" class="spacer-row">&nbsp;</td>
      </tr>
      <tr>
        <td>
          <span [coContent]="'DL.FG01.C438'"></span>
          <co-tooltip [text]="'DL.FG01.I438'"></co-tooltip>
        </td>
        <td class="cell-hide"></td>
        <td class="cell-hide"></td>
        <td [attr.data-label]="'DL.PO01.C87' | content">
          {{ pensionPlan.forsikring.beregnetSkatLiv | numberFormat }}
          <span [coContent]="'Global.kroner'"></span>
        </td>
      </tr>
      <tr>
        <td colspan="4" class="spacer-row">&nbsp;</td>
      </tr>
      <tr>
        <td [coContent]="'DL.FG01.C437'"></td>
        <td class="cell-hide"></td>
        <td class="cell-hide"></td>
        <td [attr.data-label]="'DL.PO01.C87' | content">
          <co-primary-number
            class="size-sm"
            [amount]="pensionPlan.forsikring.estimeretUdbetalingEfterSkatLiv"
          ></co-primary-number>
        </td>
      </tr>
    </tfoot>
  </table>

  <div
    *ngIf="pensionPlan.forsikring.aegtefaelleDaekninger.length > 0"
    class="row justify-content-center"
  >
    <div class="col-md-8 col-lg-6">
      <co-info class="insurance-note">
        <div>
          <div [coContent]="'DL.FG01.C477'"></div>
          <div class="note-numbers">
            <span
              *ngFor="
                let coverage of pensionPlan.forsikring.aegtefaelleDaekninger;
                last as isLast
              "
            >
              {{ coverage.aftalenummer }}<span *ngIf="!isLast">,</span>
            </span>
          </div>
        </div>
      </co-info>
    </div>
  </div>

  <div
    *ngIf="pensionPlan.forsikring.ugiftDaekninger.length > 0"
    class="row justify-content-center"
  >
    <div class="col-md-8 col-lg-6">
      <co-info class="insurance-note">
        <div>
          <div [coContent]="'DL.FG01.C478'"></div>
          <div class="note-numbers">
            <span
              *ngFor="
                let coverage of pensionPlan.forsikring.ugiftDaekninger;
                last as isLast
              "
            >
              {{ coverage.aftalenummer }}<span *ngIf="!isLast">,</span>
            </span>
          </div>
        </div>
      </co-info>
    </div>
  </div>

  <div
    *ngIf="pensionPlan.forsikring.livsBoerneDaekninger.length > 0"
    class="row justify-content-center"
  >
    <div class="col-md-8 col-lg-6">
      <co-info class="insurance-note">
        <div>
          <div [coContent]="'DL.FG01.C479'"></div>
          <div class="note-numbers">
            <span
              *ngFor="
                let coverage of pensionPlan.forsikring.livsBoerneDaekninger;
                last as isLast
              "
            >
              {{ coverage.aftalenummer }}<span *ngIf="!isLast">,</span>
            </span>
          </div>
        </div>
      </co-info>
    </div>
  </div>

  <div
    *ngIf="pensionPlan.forsikring.livsForaeldreloeseDaekninger.length > 0"
    class="row justify-content-center"
  >
    <div class="col-md-8 col-lg-6">
      <co-info class="insurance-note">
        <div>
          <div [coContent]="'DL.FG01.C480'"></div>
          <div class="note-numbers">
            <span
              *ngFor="
                let coverage of pensionPlan.forsikring
                  .livsForaeldreloeseDaekninger;
                last as isLast
              "
            >
              {{ coverage.aftalenummer }}<span *ngIf="!isLast">,</span>
            </span>
          </div>
        </div>
      </co-info>
    </div>
  </div>

  <div
    *ngIf="pensionPlan.forsikring.livsIkkeDefineretDaekninger.length > 0"
    class="row justify-content-center"
  >
    <div class="col-md-8 col-lg-6">
      <co-info class="insurance-note">
        <div>
          <div [coContent]="'DL.FG01.C481'"></div>
          <div class="note-numbers">
            <span
              *ngFor="
                let coverage of pensionPlan.forsikring
                  .livsIkkeDefineretDaekninger;
                last as isLast
              "
            >
              {{ coverage.aftalenummer }}<span *ngIf="!isLast">,</span>
            </span>
          </div>
        </div>
      </co-info>
    </div>
  </div>

  <div
    *ngIf="
      pensionPlan.forsikring.livsIkkeDefineretAegtefaelleDaekninger.length > 0
    "
    class="row justify-content-center"
  >
    <div class="col-md-8 col-lg-6">
      <co-info class="insurance-note">
        <div>
          <div [coContent]="'DL.FG01.C1223'"></div>
          <div class="note-numbers">
            <span
              *ngFor="
                let coverage of pensionPlan.forsikring
                  .livsIkkeDefineretDaekninger;
                last as isLast
              "
            >
              {{ coverage.aftalenummer }}<span *ngIf="!isLast">,</span>
            </span>
          </div>
        </div>
      </co-info>
    </div>
  </div>
</div>
