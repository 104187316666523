/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ServiceInfo } from './serviceInfo';
import { ServiceInfoSchema } from './serviceInfo';
import { MinOpPlan } from './minOpPlan';
import { MinOpPlanSchema } from './minOpPlan';
import { ResourceModel } from './resourceModel';
import { ResourceModelSchema } from './resourceModel';
import { AdvisorCorrectionsDetails } from './advisorCorrectionsDetails';
import { AdvisorCorrectionsDetailsSchema } from './advisorCorrectionsDetails';
import { MaeglerInfo } from './maeglerInfo';
import { MaeglerInfoSchema } from './maeglerInfo';
import { OpsparingsformPolice } from './opsparingsformPolice';
import { OpsparingsformPoliceSchema } from './opsparingsformPolice';
import { PensionsInfoAftale } from './pensionsInfoAftale';
import { PensionsInfoAftaleSchema } from './pensionsInfoAftale';
import { NuvaerendeIndbetalinger } from './nuvaerendeIndbetalinger';
import { NuvaerendeIndbetalingerSchema } from './nuvaerendeIndbetalinger';
import * as JoiObj from "joi";


    export const OpsparingsplanDetaljerSchema = JoiObj.object({
        serviceInfo:
            
        JoiObj.link('#ServiceInfoSchema')

.allow(null)



        ,
        advisorCorrectionsDetails:
            
        JoiObj.link('#AdvisorCorrectionsDetailsSchema')

.allow(null)



        ,
        alder:
            
                    JoiObj.number()
                .integer()


.required()


        ,
        ejerbolig:
            
                    JoiObj.number()
                


.required()


        ,
        erPrimaerPoliceLivnet:
            
                    JoiObj.boolean()
                


.required()


        ,
        fejledeAftaler:
                JoiObj.array().items(
        
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

        )
        

.required()


        ,
        friemidler:
            
                    JoiObj.number()
                

.allow(null)



        ,
        harForventetIndbetaling:
            
                    JoiObj.boolean()
                


.required()


        ,
        harGipp:
            
                    JoiObj.boolean()
                


.required()


        ,
        ikkeMuligt:
            
                    JoiObj.boolean()
                


.required()


        ,
        kunForsikringer:
            
                    JoiObj.boolean()
                


.required()


        ,
        livnetUnderudbetaling:
            
                    JoiObj.boolean()
                


.required()


        ,
        loebendeIndbetaling:
            
                    JoiObj.boolean()
                


.required()


        ,
        loebendeOpsparing:
            
                    JoiObj.number()
                

.allow(null)



        ,
        loen:
            
                    JoiObj.number()
                

.allow(null)



        ,
        maeglerBetjent:
            
                    JoiObj.boolean()
                


.required()


        ,
        maeglerInfo:
            
        JoiObj.link('#MaeglerInfoSchema')

.allow(null)



        ,
        minOpPlan:
            
        JoiObj.link('#MinOpPlanSchema')


.required()


        ,
        nuvaerendeIndbetalinger:
            
        JoiObj.link('#NuvaerendeIndbetalingerSchema')


.required()


        ,
        opsparingsformPolicer:
                JoiObj.array().items(
        
        JoiObj.link('#OpsparingsformPoliceSchema')

        )
        
.allow(null)



        ,
        paragraf39:
            
                    JoiObj.boolean()
                


.required()


        ,
        pensionsOpsparing:
            
                    JoiObj.number()
                

.allow(null)



        ,
        pensionsalder:
            
                    JoiObj.number()
                .integer()


.required()


        ,
        pensionstal:
            
                    JoiObj.number()
                .integer()


.required()


        ,
        raadgiverAendring:
            
                    JoiObj.boolean()
                

.allow(null)



        ,
        samletIndbetalingProcent:
            
                    JoiObj.number()
                

.allow(null)



        ,
        selectedAgeEarlierThanEarliestPayoutage:
            
                    JoiObj.boolean()
                


.required()


        ,
        tidligstePensionsalder:
            
                    JoiObj.number()
                .integer()


.required()


        ,
        tjenestemandspension:
                JoiObj.array().items(
        
        JoiObj.link('#PensionsInfoAftaleSchema')

        )
        

.required()


        ,
        udbetalingDoedId:
                JoiObj.array().items(
        
                    JoiObj.number()
                .integer()

        )
        

.required()


        ,
        udenOpsparing:
            
                    JoiObj.boolean()
                


.required()


        ,
        ydelseOver10000UdenDepot:
                JoiObj.array().items(
        
        JoiObj.link('#PensionsInfoAftaleSchema')

        )
        

.required()


        
    })
                .shared(ServiceInfoSchema)
                .shared(AdvisorCorrectionsDetailsSchema)
                .shared(MaeglerInfoSchema)
                .shared(MinOpPlanSchema)
                .shared(NuvaerendeIndbetalingerSchema)
                .shared(OpsparingsformPoliceSchema)
                .shared(PensionsInfoAftaleSchema)
                .shared(PensionsInfoAftaleSchema)
.id('OpsparingsplanDetaljerSchema')

export interface OpsparingsplanDetaljer extends ResourceModel { 
    advisorCorrectionsDetails?: AdvisorCorrectionsDetails;
    alder: number;
    ejerbolig: number;
    erPrimaerPoliceLivnet: boolean;
    fejledeAftaler: Array<string>;
    friemidler?: number;
    harForventetIndbetaling: boolean;
    harGipp: boolean;
    ikkeMuligt: boolean;
    kunForsikringer: boolean;
    livnetUnderudbetaling: boolean;
    loebendeIndbetaling: boolean;
    loebendeOpsparing?: number;
    loen?: number;
    maeglerBetjent: boolean;
    maeglerInfo?: MaeglerInfo;
    minOpPlan: MinOpPlan;
    nuvaerendeIndbetalinger: NuvaerendeIndbetalinger;
    opsparingsformPolicer?: Array<OpsparingsformPolice>;
    paragraf39: boolean;
    pensionsOpsparing?: number;
    pensionsalder: number;
    pensionstal: number;
    raadgiverAendring?: boolean;
    samletIndbetalingProcent?: number;
    selectedAgeEarlierThanEarliestPayoutage: boolean;
    tidligstePensionsalder: number;
    tjenestemandspension: Array<PensionsInfoAftale>;
    udbetalingDoedId: Array<number>;
    udenOpsparing: boolean;
    ydelseOver10000UdenDepot: Array<PensionsInfoAftale>;
}

