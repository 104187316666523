/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const DokumentTypeSchema = JoiObj.string().valid(
        'AftalePensionsordning'
        ,
        'UnderskrevetAftaleOmPensionsordning'
        ,
        'BilagTilPensionsordning'
        ,
        'DepotregnskabOverfoersler'
        ,
        'Kvittering'
        ,
        'EksternOverfoersel'
        ,
        'Overfoerselsoplysninger'
        ,
        'Pensionsbevis'
        ,
        'Pensionsoversigt'
        ,
        'Pensionsrapport'
        ,
        'FORSIKRINGSGUIDERAPPORT'
        ,
        'AUTOMATISK_ANTAGELSE_KVITTERING'
        ,
        'AUTOMATISK_ANTAGELSE_SAMTYKKEERKLAERING'
        ,
        'Pensionsvilkaar'
        ,
        'Udbetaling'
        ,
        'FormuePraesentation'
        ,
        'FormueReferat'
        ,
        'UdbetalingsplanRapport'
        ,
        'SkattteOgKontooplysninger'
        ,
        'LifeStepUdbetaling'
        ,
        'Pensionsoversigter'
        ,
        'Depotoversigter'
        ,
        'Udbetalingsoversigter'
        ,
        'Oevrige'
        ,
        'Pensionsoversigter30Sep'
        ,
        'Depotoversigter30Sep'
        ,
        'OevrigeOevrige'
        ,
        'LifeStepUdbetalingMedPartner'
        ,
        'GIPPdepotOpgoerelse'
        ,
        'INVESTERINGSGUIDERAPPORT'
        ,
        'BegunstigelseserklÃ¦ring'
        ,
        'PDF'
        ,
        'Ukendt'
        
    )
.id('DokumentTypeSchema')

export type DokumentType = 'AftalePensionsordning' | 'UnderskrevetAftaleOmPensionsordning' | 'BilagTilPensionsordning' | 'DepotregnskabOverfoersler' | 'Kvittering' | 'EksternOverfoersel' | 'Overfoerselsoplysninger' | 'Pensionsbevis' | 'Pensionsoversigt' | 'Pensionsrapport' | 'FORSIKRINGSGUIDERAPPORT' | 'AUTOMATISK_ANTAGELSE_KVITTERING' | 'AUTOMATISK_ANTAGELSE_SAMTYKKEERKLAERING' | 'Pensionsvilkaar' | 'Udbetaling' | 'FormuePraesentation' | 'FormueReferat' | 'UdbetalingsplanRapport' | 'SkattteOgKontooplysninger' | 'LifeStepUdbetaling' | 'Pensionsoversigter' | 'Depotoversigter' | 'Udbetalingsoversigter' | 'Oevrige' | 'Pensionsoversigter30Sep' | 'Depotoversigter30Sep' | 'OevrigeOevrige' | 'LifeStepUdbetalingMedPartner' | 'GIPPdepotOpgoerelse' | 'INVESTERINGSGUIDERAPPORT' | 'BegunstigelseserklÃ¦ring' | 'PDF' | 'Ukendt';

export const DokumentType = {
    AftalePensionsordning: 'AftalePensionsordning' as DokumentType,
    UnderskrevetAftaleOmPensionsordning: 'UnderskrevetAftaleOmPensionsordning' as DokumentType,
    BilagTilPensionsordning: 'BilagTilPensionsordning' as DokumentType,
    DepotregnskabOverfoersler: 'DepotregnskabOverfoersler' as DokumentType,
    Kvittering: 'Kvittering' as DokumentType,
    EksternOverfoersel: 'EksternOverfoersel' as DokumentType,
    Overfoerselsoplysninger: 'Overfoerselsoplysninger' as DokumentType,
    Pensionsbevis: 'Pensionsbevis' as DokumentType,
    Pensionsoversigt: 'Pensionsoversigt' as DokumentType,
    Pensionsrapport: 'Pensionsrapport' as DokumentType,
    Forsikringsguiderapport: 'FORSIKRINGSGUIDERAPPORT' as DokumentType,
    AutomatiskAntagelseKvittering: 'AUTOMATISK_ANTAGELSE_KVITTERING' as DokumentType,
    AutomatiskAntagelseSamtykkeerklaering: 'AUTOMATISK_ANTAGELSE_SAMTYKKEERKLAERING' as DokumentType,
    Pensionsvilkaar: 'Pensionsvilkaar' as DokumentType,
    Udbetaling: 'Udbetaling' as DokumentType,
    FormuePraesentation: 'FormuePraesentation' as DokumentType,
    FormueReferat: 'FormueReferat' as DokumentType,
    UdbetalingsplanRapport: 'UdbetalingsplanRapport' as DokumentType,
    SkattteOgKontooplysninger: 'SkattteOgKontooplysninger' as DokumentType,
    LifeStepUdbetaling: 'LifeStepUdbetaling' as DokumentType,
    Pensionsoversigter: 'Pensionsoversigter' as DokumentType,
    Depotoversigter: 'Depotoversigter' as DokumentType,
    Udbetalingsoversigter: 'Udbetalingsoversigter' as DokumentType,
    Oevrige: 'Oevrige' as DokumentType,
    Pensionsoversigter30Sep: 'Pensionsoversigter30Sep' as DokumentType,
    Depotoversigter30Sep: 'Depotoversigter30Sep' as DokumentType,
    OevrigeOevrige: 'OevrigeOevrige' as DokumentType,
    LifeStepUdbetalingMedPartner: 'LifeStepUdbetalingMedPartner' as DokumentType,
    GipPdepotOpgoerelse: 'GIPPdepotOpgoerelse' as DokumentType,
    Investeringsguiderapport: 'INVESTERINGSGUIDERAPPORT' as DokumentType,
    Begunstigelseserklring: 'BegunstigelseserklÃ¦ring' as DokumentType,
    Pdf: 'PDF' as DokumentType,
    Ukendt: 'Ukendt' as DokumentType
};

