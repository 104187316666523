<!-- v6 -->
<svg
  id="Layer_1"
  data-name="Layer 1"
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 32 32"
>
  <g id="Canvas">
    <circle
      id="Large_circle"
      data-name="Large circle"
      cx="16"
      cy="16"
      r="16"
      fill="none"
    />
    <circle
      id="Small_circle"
      data-name="Small circle"
      cx="16"
      cy="16"
      r="12"
      fill="none"
    />
  </g>
  <g id="Icon">
    <path
      id="Body"
      d="M20,22.05V20.58a1.35,1.35,0,0,0-1.33-1.33h-.34c-1,0-1.33.33-2.33.33s-1.33-.33-2.33-.33h-.34A1.35,1.35,0,0,0,12,20.58v1.47"
      fill="none"
      stroke="#000"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.2"
    />
    <path
      id="Head"
      d="M16,11.75h0A2.25,2.25,0,0,1,18.25,14v1.25A2.25,2.25,0,0,1,16,17.5h0a2.25,2.25,0,0,1-2.25-2.25V14A2.25,2.25,0,0,1,16,11.75Z"
      fill="none"
      stroke="#000"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.2"
    />
    <polyline
      id="Arrow"
      points="20.85 8.89 21.13 10.87 19.15 11.15"
      fill="none"
      stroke="#000"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.2"
    />
    <circle id="Dot" cx="22.68" cy="13.18" r="1" />
    <path
      id="Circle"
      d="M22.68,13.18A7.18,7.18,0,0,1,23.25,16a7.26,7.26,0,1,1-2.15-5.15"
      fill="none"
      stroke="#000"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.2"
    />
  </g>
</svg>
