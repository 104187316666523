<div mat-dialog-title><h3 coContent="DL.LE01.C01"></h3></div>
<mat-dialog-content class="conent" #conent>
  <ng-container
    *ngIf="
      !isUploadComplete && !isUploadError && !isVirusError;
      else uploadCompleted
    "
  >
    <p coContent="DL.LE01.C02"></p>
    <p class="text--bold" coContent="DL.LE01.C06"></p>
    <p
      *ngIf="
        !isIdentified && legitimation.status === LegitimationStatus.ONE_REQUIRED
      "
      coContent="DL.LE01.C26"
    ></p>
    <p
      *ngIf="
        !isIdentified && legitimation.status === LegitimationStatus.TWO_REQUIRED
      "
      coContent="DL.LE01.C28"
    ></p>
    <p
      *ngIf="
        legitimation.nemIdLegitimeret &&
        legitimation.status === LegitimationStatus.ONE_REQUIRED
      "
      coContent="DL.LE01.C29"
    ></p>
    <p
      *ngIf="
        legitimation.nemIdLegitimeret &&
        !isIdentified &&
        legitimation.status === LegitimationStatus.ONE_REQUIRED
      "
      coContent="DL.LE01.C30"
    ></p>

    <p
      *ngIf="legitimation.status === LegitimationStatus.TWO_REQUIRED"
      coContent="DL.LE01.C08"
    ></p>
    <p class="text--bold" coContent="DL.LE01.C09"></p>
    <p coContent="DL.LE01.C010"></p>
    <p coContent="DL.LE01.C24"></p>
    <co-attachment
      class="attachment--static"
      [acceptFiles]="supportedFiles"
      (attachmentsChange)="handleAttachmentsChange($event)"
    ></co-attachment>
  </ng-container>
  <ng-template #uploadCompleted>
    <ng-container *ngIf="!isUploadError && !isVirusError">
      <p *ngIf="selectedFiles.length === 1" coContent="DL.LE01.C15"></p>
      <p *ngIf="selectedFiles.length > 1" coContent="DL.LE01.C16"></p>
      <div coContent="DL.LE01.C17"></div>
    </ng-container>
    <ng-container *ngIf="isUploadError && !isVirusError">
      <p coContent="DL.LE01.C20"></p>
    </ng-container>
    <ng-container *ngIf="isVirusError">
      <p
        *ngIf="selectedFiles.length === 1"
        coContent="Global.virusifilenadvar"
      ></p>
      <p
        *ngIf="selectedFiles.length > 1"
        coContent="Global.virusifilenadvar1"
      ></p>
    </ng-container>
  </ng-template>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="modal-buttons">
    <button
      *ngIf="!isUploadComplete"
      mat-raised-button
      [disabled]="!isValid"
      (click)="upload()"
      coContent="DL.LE01.C13"
    ></button>
    <button
      mat-raised-button
      [coContent]="isUploadComplete ? 'DL.LI01.C104' : 'DL.LE01.C14'"
      (click)="cancel()"
    ></button>
  </div>
</mat-dialog-actions>
