/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const BrugerundersoegelseSchema = JoiObj.object({
        indstillingType:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        surveyId:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        toBeShown:
            
                    JoiObj.boolean()
                


.required()


        
    })
.id('BrugerundersoegelseSchema')

export interface Brugerundersoegelse { 
    indstillingType: string;
    surveyId: string;
    toBeShown: boolean;
}

