/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const AlternativePolicyChoicePartiallyAcceptedSchema = JoiObj.string().valid(
        'PartialHealth'
        ,
        'CancelOffer'
        
    )
.id('AlternativePolicyChoicePartiallyAcceptedSchema')

export type AlternativePolicyChoicePartiallyAccepted = 'PartialHealth' | 'CancelOffer';

export const AlternativePolicyChoicePartiallyAccepted = {
    PartialHealth: 'PartialHealth' as AlternativePolicyChoicePartiallyAccepted,
    CancelOffer: 'CancelOffer' as AlternativePolicyChoicePartiallyAccepted
};

