import { Component, Input } from '@angular/core';

@Component({
  selector: 'mitpfa-dashboard-savings-return-card',
  templateUrl: './dashboard-savings-return-card.component.html',
  styleUrls: ['./dashboard-savings-return-card.component.scss']
})
export class DashboardSavingsReturnCardComponent {
  @Input()
  public readonly headingText: string;

  @Input() hideHeaderArrow: boolean;
}
