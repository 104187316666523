<div class="row hide-on-mobile">
  <div class="col-md-12 d-flex justify-content-end">
    <mitpfa-investment-guide-help [broker]="broker" [isInsideCard]="true">
    </mitpfa-investment-guide-help>
  </div>
</div>
<div class="row">
  <div class="col-md-8 offset-md-2">
    <div class="description spacing-unit--double-under">
      <h3 class="title" coContent="DL.INVGU01.C561"></h3>
      <p class="text--info" coContent="DL.INVGU01.C562"></p>
      <div class="show-on-mobile">
        <mitpfa-investment-guide-help [broker]="broker">
        </mitpfa-investment-guide-help>
      </div>
    </div>
  </div>
</div>

<mat-radio-group class="radio-group" [formControl]="answer">
  <co-selection-wrapper *ngFor="let choice of choices">
    <mat-radio-button [value]="choice.value">
      <div [coContent]="choice.text"></div>
    </mat-radio-button>
    <div
      [ngClass]="{
        hidden:
          choice.value !==
          investmentGuide.recommendationBasis.climateExtendedFocus
      }"
      class="radio-button_selection"
      sublabel
      coContent="DL.INVGU01.C646"
    ></div>
  </co-selection-wrapper>
</mat-radio-group>
<div class="align--center">
  <button
    class="button-next button-secondary"
    mat-raised-button
    coContent="DL.INVGU01.C647"
    [disabled]="answer.invalid"
    (click)="choose()"
  ></button>
</div>
