/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CompanyOrPrivate } from './companyOrPrivate';
import { CompanyOrPrivateSchema } from './companyOrPrivate';
import * as JoiObj from "joi";


    export const PartialSchemeElementSchema = JoiObj.object({
        addHealthReqAcceptanceTag:
            
                    JoiObj.boolean()
                


.required()


        ,
        companyOrPrivate:
            
        JoiObj.link('#CompanyOrPrivateSchema')


.required()


        ,
        healthInsuranceProduct:
            
                    JoiObj.number()
                


.required()


        ,
        healthRequirementCode:
            
                    JoiObj.number()
                


.required()


        ,
        minimumYearlyPremiumAmount:
            
                    JoiObj.number()
                


.required()


        ,
        partialSchemeCompanyName:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        partialSchemeEndDate:
            
                    JoiObj.object()
                


.required()


        ,
        partialSchemeId:
            
                    JoiObj.number()
                


.required()


        ,
        partialSchemeLabelText:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        partialSchemeStartDate:
            
                    JoiObj.object()
                


.required()


        ,
        partialSchemeType:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        sieblingGroupName:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        variantCode:
            
                    JoiObj.number()
                


.required()


        
    })
                .shared(CompanyOrPrivateSchema)
.id('PartialSchemeElementSchema')

export interface PartialSchemeElement { 
    addHealthReqAcceptanceTag: boolean;
    companyOrPrivate: CompanyOrPrivate;
    healthInsuranceProduct: number;
    healthRequirementCode: number;
    minimumYearlyPremiumAmount: number;
    partialSchemeCompanyName: string;
    partialSchemeEndDate: object;
    partialSchemeId: number;
    partialSchemeLabelText: string;
    partialSchemeStartDate: object;
    partialSchemeType: string;
    sieblingGroupName: string;
    variantCode: number;
}
export namespace PartialSchemeElement {
}


