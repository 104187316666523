/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const LossAbilitySchema = JoiObj.string().valid(
        'MINIMAL_LOSS'
        ,
        'AVOID_LOSS'
        ,
        'ACCEPT_LOSS'
        
    )
.id('LossAbilitySchema')

export type LossAbility = 'MINIMAL_LOSS' | 'AVOID_LOSS' | 'ACCEPT_LOSS';

export const LossAbility = {
    MinimalLoss: 'MINIMAL_LOSS' as LossAbility,
    AvoidLoss: 'AVOID_LOSS' as LossAbility,
    AcceptLoss: 'ACCEPT_LOSS' as LossAbility
};

