import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as moment from 'moment';
import 'moment/locale/da';
import { Content } from './content';
import { BehaviorSubject } from 'rxjs';
import { ExtendedWindow } from '../models/extended-window';

export class Language {
  constructor(private sprogType: 'DA' | 'EN') {}
}

@Injectable({
  providedIn: 'root'
})
export class ContentService {
  /**
   * Shared content service.
   */
  public static contentKeys = '=DL.,Global,TransaktionsGebyr.,LE.,MAIL.,TLF.';
  private content: Content;
  private current: any;
  private languageSubject = new BehaviorSubject<string>('da');
  private headerContentTypeJSON = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  public languageState = this.languageSubject.asObservable();
  public isLanguageChangeDisabled = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient) {
    this.content = new Content();
  }

  private loadContent(language: string) {
    // no content exist - get new from backend
    const content = this.content;
    return this.http
      .get(
        '/ds/content/tekster/' +
          language +
          '?content' +
          ContentService.contentKeys,
        {}
      )
      .toPromise()
      .then(function (result) {
        content[language] = result;
        return result;
      });
  }

  setLanguage(lang: 'da' | 'en') {
    if ((window as unknown as ExtendedWindow)?.digitalData) {
      (window as unknown as ExtendedWindow).digitalData.languageCode =
        lang.toUpperCase();
    }
    this.content.language = lang; // We are Happy
    this.current = this.content[lang];
    moment.locale(this.content.language); // set locale on momentjs
    const htmlElement = document.querySelector('html');
    htmlElement?.setAttribute('lang', lang);
    this.languageSubject.next(lang);
  }

  setLanguageFromSaved() {
    this.loadLanguage().subscribe(res => {
      this.setLanguage(res === 'EN' ? 'en' : 'da');
    });
  }

  public disableLanguageChange(): void {
    if (this.isLanguageChangeDisabled.value) {
      return;
    }
    this.isLanguageChangeDisabled.next(true);
  }

  public enableLanguageChange(): void {
    if (!this.isLanguageChangeDisabled.value) {
      return;
    }
    this.isLanguageChangeDisabled.next(false);
  }

  public loadLanguage() {
    return this.http.get('/ds/api/pensionskunde/kontaktoplysninger/sprogvalg');
  }

  public saveLanguage() {
    const language = new Language(this.content.upperCaseLanguage());
    return this.http.put<Language>(
      '/ds/api/pensionskunde/kontaktoplysninger/sprogvalg',
      language,
      this.headerContentTypeJSON
    );
  }

  getContent(): any {
    return this.current;
  }

  loadAllContent() {
    // lintfixme: fix error and enable rule
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const service = this;
    return Promise.all([this.loadContent('da'), this.loadContent('en')]).then(
      function () {
        service.setLanguage('da');
      }
    );
  }

  getLanguage() {
    return this.content.language;
  }

  getLanguageUpper() {
    return this.content.upperCaseLanguage();
  }

  isContentEnglish() {
    return this.content.language === 'en';
  }
}
