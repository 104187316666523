/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HelbredssikringDetaljer } from './helbredssikringDetaljer';
import { HelbredssikringDetaljerSchema } from './helbredssikringDetaljer';
import { LocScale } from './locScale';
import { LocScaleSchema } from './locScale';
import { AutoAdjustment } from './autoAdjustment';
import { AutoAdjustmentSchema } from './autoAdjustment';
import { BetalingFrekvens } from './betalingFrekvens';
import { BetalingFrekvensSchema } from './betalingFrekvens';
import { InsuranceNumberLoc } from './insuranceNumberLoc';
import { InsuranceNumberLocSchema } from './insuranceNumberLoc';
import { ForsikringstalSlider } from './forsikringstalSlider';
import { ForsikringstalSliderSchema } from './forsikringstalSlider';
import { IndregningDetaljer } from './indregningDetaljer';
import { IndregningDetaljerSchema } from './indregningDetaljer';
import { Aegtefaellepension } from './aegtefaellepension';
import { AegtefaellepensionSchema } from './aegtefaellepension';
import { DaekningAendring } from './daekningAendring';
import { DaekningAendringSchema } from './daekningAendring';
import * as JoiObj from "joi";


    export const TilpasForsikringstalSchema = JoiObj.object({
        aarsloen:
            
                    JoiObj.number()
                


.required()


        ,
        aegtefaelle:
            
        JoiObj.link('#AegtefaellepensionSchema')

.allow(null)



        ,
        aegtefaelleHSBlanket:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        anbefalFravaelgBoernepension:
            
                    JoiObj.boolean()
                


.required()


        ,
        anbefalOpretBoernepension:
            
                    JoiObj.boolean()
                


.required()


        ,
        antagelsesstatus:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        autoAdjustment:
            
        JoiObj.link('#AutoAdjustmentSchema')

.allow(null)



        ,
        bruttoTalLivIkkeVises:
            
                    JoiObj.boolean()
                


.required()


        ,
        bruttoTalTaeloebendeIkkeVises:
            
                    JoiObj.boolean()
                


.required()


        ,
        currentForsikringstalLiv:
            
                    JoiObj.number()
                


.required()


        ,
        currentForsikringstalTAE:
            
                    JoiObj.number()
                


.required()


        ,
        currentForsikringstalTAEBrutto:
            
                    JoiObj.number()
                


.required()


        ,
        daekningAendringBoernepension:
            
        JoiObj.link('#DaekningAendringSchema')


.required()


        ,
        daekningAendringKritiskSygdomBoern:
            
        JoiObj.link('#DaekningAendringSchema')


.required()


        ,
        daekningAendringKritiskSygdomSelv:
            
        JoiObj.link('#DaekningAendringSchema')


.required()


        ,
        daekningAendringTAEengangsudbetaling:
            
        JoiObj.link('#DaekningAendringSchema')


.required()


        ,
        erFejlet:
            
                    JoiObj.boolean()
                


.required()


        ,
        helbredssikringBarn:
            
        JoiObj.link('#HelbredssikringDetaljerSchema')


.required()


        ,
        helbredssikringVoksen:
            
        JoiObj.link('#HelbredssikringDetaljerSchema')


.required()


        ,
        indregningDetaljer:
            
        JoiObj.link('#IndregningDetaljerSchema')


.required()


        ,
        kraevesHS:
            
                    JoiObj.boolean()
                


.required()


        ,
        locScale:
            
        JoiObj.link('#LocScaleSchema')

.allow(null)



        ,
        loenFraMineOplysninger:
            
                    JoiObj.number()
                


.required()


        ,
        maegler:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        maeglerTLF:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        maeglerbetjentLivnetVises:
            
                    JoiObj.boolean()
                


.required()


        ,
        paragraf53A:
            
                    JoiObj.boolean()
                

.allow(null)



        ,
        partnerInsurancePayFrequency:
            
        JoiObj.link('#BetalingFrekvensSchema')

.allow(null)



        ,
        partnerInsurancePrice:
            
                    JoiObj.number()
                

.allow(null)



        ,
        pfaAnbefalerKS:
            
                    JoiObj.number()
                


.required()


        ,
        pfaAnbefalerLiv:
            
                    JoiObj.number()
                


.required()


        ,
        pfaAnbefalerTAE:
            
                    JoiObj.number()
                


.required()


        ,
        policenummer:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        showForsikringsAdvarsel:
            
                    JoiObj.boolean()
                


.required()


        ,
        skalAegtefaelleVises:
            
                    JoiObj.boolean()
                


.required()


        ,
        skalBoernepensionVises:
            
                    JoiObj.boolean()
                


.required()


        ,
        skalHelbredssikringBoernVises:
            
                    JoiObj.boolean()
                


.required()


        ,
        skalHelbredssikringPartnerVises:
            
                    JoiObj.boolean()
                


.required()


        ,
        skalHelbredssikringSelvVises:
            
                    JoiObj.boolean()
                


.required()


        ,
        skalKritiskSygdomBoernVises:
            
                    JoiObj.boolean()
                


.required()


        ,
        skalKritiskSygdomSelvVises:
            
                    JoiObj.boolean()
                


.required()


        ,
        skalTAEengangsudbetalingVises:
            
                    JoiObj.boolean()
                


.required()


        ,
        skattekodevalgLivMulig:
            
                    JoiObj.boolean()
                


.required()


        ,
        sliderLiv:
            
        JoiObj.link('#ForsikringstalSliderSchema')


.required()


        ,
        sliderTAEloebende:
            
        JoiObj.link('#InsuranceNumberLocSchema')


.required()


        ,
        superRiskLife:
            
                    JoiObj.boolean()
                


.required()


        ,
        superRiskTae:
            
                    JoiObj.boolean()
                


.required()


        ,
        tilstand:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        udenOpsparing:
            
                    JoiObj.boolean()
                

.allow(null)



        ,
        visAUAforbehold:
            
                    JoiObj.boolean()
                


.required()


        
    })
                .shared(AegtefaellepensionSchema)
                .shared(AutoAdjustmentSchema)
                .shared(DaekningAendringSchema)
                .shared(DaekningAendringSchema)
                .shared(DaekningAendringSchema)
                .shared(DaekningAendringSchema)
                .shared(HelbredssikringDetaljerSchema)
                .shared(HelbredssikringDetaljerSchema)
                .shared(IndregningDetaljerSchema)
                .shared(LocScaleSchema)
                .shared(BetalingFrekvensSchema)
                .shared(ForsikringstalSliderSchema)
                .shared(InsuranceNumberLocSchema)
.id('TilpasForsikringstalSchema')

export interface TilpasForsikringstal { 
    aarsloen: number;
    aegtefaelle?: Aegtefaellepension;
    aegtefaelleHSBlanket?: string;
    anbefalFravaelgBoernepension: boolean;
    anbefalOpretBoernepension: boolean;
    antagelsesstatus?: string;
    autoAdjustment?: AutoAdjustment;
    bruttoTalLivIkkeVises: boolean;
    bruttoTalTaeloebendeIkkeVises: boolean;
    currentForsikringstalLiv: number;
    currentForsikringstalTAE: number;
    currentForsikringstalTAEBrutto: number;
    daekningAendringBoernepension: DaekningAendring;
    daekningAendringKritiskSygdomBoern: DaekningAendring;
    daekningAendringKritiskSygdomSelv: DaekningAendring;
    daekningAendringTAEengangsudbetaling: DaekningAendring;
    erFejlet: boolean;
    helbredssikringBarn: HelbredssikringDetaljer;
    helbredssikringVoksen: HelbredssikringDetaljer;
    indregningDetaljer: IndregningDetaljer;
    kraevesHS: boolean;
    locScale?: LocScale;
    loenFraMineOplysninger: number;
    maegler?: string;
    maeglerTLF?: string;
    maeglerbetjentLivnetVises: boolean;
    paragraf53A?: boolean;
    partnerInsurancePayFrequency?: BetalingFrekvens;
    partnerInsurancePrice?: number;
    pfaAnbefalerKS: number;
    pfaAnbefalerLiv: number;
    pfaAnbefalerTAE: number;
    policenummer: string;
    showForsikringsAdvarsel: boolean;
    skalAegtefaelleVises: boolean;
    skalBoernepensionVises: boolean;
    skalHelbredssikringBoernVises: boolean;
    skalHelbredssikringPartnerVises: boolean;
    skalHelbredssikringSelvVises: boolean;
    skalKritiskSygdomBoernVises: boolean;
    skalKritiskSygdomSelvVises: boolean;
    skalTAEengangsudbetalingVises: boolean;
    skattekodevalgLivMulig: boolean;
    sliderLiv: ForsikringstalSlider;
    sliderTAEloebende: InsuranceNumberLoc;
    superRiskLife: boolean;
    superRiskTae: boolean;
    tilstand?: string;
    udenOpsparing?: boolean;
    visAUAforbehold: boolean;
}
export namespace TilpasForsikringstal {
}


