import { Component, Input, OnChanges, OnInit } from '@angular/core';
import {
  Koncepter,
  LinieElementOpsparing,
  OpsparingPoliceDetaljer
} from '@pfa/gen';
import {
  CONCEPT_DETAILS_TYPE,
  ConceptDetailsExpanded
} from './savings-policy-per-concept.model';

@Component({
  selector: 'mitpfa-savings-policy-per-concept',
  templateUrl: './savings-policy-per-concept.component.html',
  styleUrls: ['./savings-policy-per-concept.component.scss']
})
export class SavingsPolicyPerConceptComponent implements OnInit, OnChanges {
  @Input() savingsPolicyDetails: OpsparingPoliceDetaljer;
  @Input() productAndConceptData: LinieElementOpsparing;

  concepts: Koncepter;
  expanded: ConceptDetailsExpanded;
  showCustomerCapitalDetails: boolean;

  ngOnInit(): void {
    this.expanded = new ConceptDetailsExpanded();
  }

  ngOnChanges(): void {
    this.concepts =
      this.savingsPolicyDetails.vaerdiAfOpsparingUltimo.opsparingFordeling.koncepter;
    this.showCustomerCapitalDetails = !!(
      (this.concepts.pfaInvesterer || this.concepts.duInvesterer) &&
      this.concepts.gennemsnitsrente?.total
    );
  }

  conceptDetailsType(): typeof CONCEPT_DETAILS_TYPE {
    return CONCEPT_DETAILS_TYPE;
  }

  toggleExpanded(expanded: boolean, type: CONCEPT_DETAILS_TYPE): void {
    switch (type) {
      case CONCEPT_DETAILS_TYPE.PFA_INVEST:
        this.expanded.pfaInvest = expanded;
        break;
      case CONCEPT_DETAILS_TYPE.AVERAGE_RATE:
        this.expanded.averageRate = expanded;
        break;
      case CONCEPT_DETAILS_TYPE.CUSTOMER_CAPITAL:
        this.expanded.customerCapital = expanded;
        break;
    }
  }
}
