/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ServiceInfo } from './serviceInfo';
import { ServiceInfoSchema } from './serviceInfo';
import { RealEstate } from './realEstate';
import { RealEstateSchema } from './realEstate';
import { ResourceModel } from './resourceModel';
import { ResourceModelSchema } from './resourceModel';
import { OtherAssets } from './otherAssets';
import { OtherAssetsSchema } from './otherAssets';
import { PensionOther } from './pensionOther';
import { PensionOtherSchema } from './pensionOther';
import { PensionInPFA } from './pensionInPFA';
import { PensionInPFASchema } from './pensionInPFA';
import * as JoiObj from "joi";


    export const PensionEstimateDetailsOverviewSchema = JoiObj.object({
        serviceInfo:
            
        JoiObj.link('#ServiceInfoSchema')

.allow(null)



        ,
        otherAssets:
            
        JoiObj.link('#OtherAssetsSchema')


.required()


        ,
        pensionInPFA:
            
        JoiObj.link('#PensionInPFASchema')


.required()


        ,
        pensionsOther:
                JoiObj.array().items(
        
        JoiObj.link('#PensionOtherSchema')

        )
        

.required()


        ,
        realEstates:
                JoiObj.array().items(
        
        JoiObj.link('#RealEstateSchema')

        )
        

.required()


        
    })
                .shared(ServiceInfoSchema)
                .shared(OtherAssetsSchema)
                .shared(PensionInPFASchema)
                .shared(PensionOtherSchema)
                .shared(RealEstateSchema)
.id('PensionEstimateDetailsOverviewSchema')

export interface PensionEstimateDetailsOverview extends ResourceModel { 
    otherAssets: OtherAssets;
    pensionInPFA: PensionInPFA;
    pensionsOther: Array<PensionOther>;
    realEstates: Array<RealEstate>;
}

