import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'co-modal-show-pdf',
  templateUrl: './pdfPopup.component.html',
  styleUrls: ['./show-pdf-modal.component.scss']
})
export class ShowPdfModalComponent {
  restoreState: any;
  public pdfLink: string;

  constructor(public popup: MatDialogRef<ShowPdfModalComponent>) {
    // TODO make this with router
    this.restoreState = {
      stateName: 'stateName',
      params: {},
      title: 'title'
    };
  }

  public setPdfLink(pdfLink: string) {
    this.pdfLink = pdfLink;
  }

  closePopup() {
    this.popup.close();
  }
}
