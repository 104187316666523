/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const LeadTypeSchema = JoiObj.string().valid(
        'ADP_EKSTOVERFOERSEL'
        ,
        'EKSTOVERFOERSEL'
        
    )
.id('LeadTypeSchema')

export type LeadType = 'ADP_EKSTOVERFOERSEL' | 'EKSTOVERFOERSEL';

export const LeadType = {
    AdpEkstoverfoersel: 'ADP_EKSTOVERFOERSEL' as LeadType,
    Ekstoverfoersel: 'EKSTOVERFOERSEL' as LeadType
};

