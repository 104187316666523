<div class="color--primary text--bold" coContent="DL.INV.C696"></div>

<ng-container *ngIf="isProfileChanged || isClimateChanged">
  <div class="change-wrap">
    <div
      class="change-header text--bold color--black"
      coContent="DL.INV.C671"
    ></div>

    <div *ngIf="isProfileChanged" class="change-item">
      <ng-container
        *ngIf="
          !detail.recommendation.recommendYouInvest &&
          !detail.current.hasYouInvest
        "
      >
        {{ detail.recommendation.conceptName | content }}
        {{ detail.recommendation.riskText | content }}
        {{ detail.recommendation.profileText | content }}

        <div *ngIf="detail.unboundSource">
          {{ detail.recommendation.conceptName | content }}
          {{ detail.unboundSource.riskText | content }}
          {{ detail.unboundSource.profileText | content }}
        </div>
      </ng-container>

      <span
        *ngIf="
          detail.current.hasYouInvest &&
          (detail.current.hasPfaInvest || detail.current.hasPfaOptional)
        "
        coContent="DL.INV.C668"
        [coContentSubst]="
          (detail.current.youInvestPercent | number: '1.2-2') +
          '|' +
          (detail.current.pfaInvestPercent | number: '1.2-2') +
          '|' +
          (detail.recommendation.profileText | content)
        "
      ></span>
    </div>

    <div *ngIf="isClimateChanged" class="change-item">
      <ng-container *ngIf="!detail.manualClimatePercent; else manual">
        {{ detail.recommendation.climatePercent }}
      </ng-container>
      <ng-template #manual>
        {{ detail.manualClimatePercent }}
      </ng-template>
      % {{ 'DL.INV.C661' | content }}
    </div>
  </div>
</ng-container>

<ng-container *ngIf="!isProfileChanged || !isClimateChanged">
  <div class="change-wrap">
    <div
      class="change-header text--bold color--black"
      coContent="DL.INV.C695"
    ></div>

    <div *ngIf="!isProfileChanged" class="change-item">
      <ng-container
        *ngIf="detail.current.hasPfaInvest && !detail.current.hasYouInvest"
      >
        {{ detail.current.conceptName | content }}
        {{ detail.current.riskText | content }}
        {{ detail.current.profileText | content }}

        <div *ngIf="detail.unboundSource">
          {{ detail.current.conceptName | content }}
          {{ detail.unboundSource.riskText | content }}
          {{ detail.unboundSource.profileText | content }}
        </div>
      </ng-container>

      <ng-container
        *ngIf="detail.current.hasPfaOptional && !detail.current.hasYouInvest"
      >
        <div>{{ detail.current.conceptName | content }}</div>
        <div
          *ngIf="detail.current.hasPfaOptional && !detail.current.hasYouInvest"
          [coContent]="detail.current.riskText"
          [coContentSubst]="
            (detail.current.highRisk | number: '1.2-2') +
            '|' +
            (detail.current.lowRisk | number: '1.2-2')
          "
        ></div>
      </ng-container>

      <span
        *ngIf="
          detail.current.hasYouInvest &&
          (detail.current.hasPfaInvest || detail.current.hasPfaOptional)
        "
        [coContent]="detail.current.riskText"
        [coContentSubst]="
          (detail.current.youInvestPercent | number: '1.2-2') +
          '|' +
          (detail.current.pfaInvestPercent | number: '1.2-2') +
          '|' +
          (detail.current.profileText | content)
        "
      ></span>

      <ng-container *ngIf="detail.current.onlyYouInvest">
        <div>{{ detail.current.conceptName | content }}</div>
        <div
          [coContent]="detail.current.riskText"
          [coContentSubst]="
            (detail.current.allocation.bonds | number: '1.2-2') +
            '|' +
            (detail.current.allocation.shares | number: '1.2-2') +
            '|' +
            (detail.current.allocation.mixed | number: '1.2-2')
          "
        ></div>
      </ng-container>
    </div>

    <div
      *ngIf="!isClimateChanged && !detail.current.onlyYouInvest"
      class="change-item"
    >
      {{ detail.current.climatePercent }} % {{ 'DL.INV.C661' | content }}
    </div>
  </div>
</ng-container>

<div class="align--center spacing-unit--double-over">
  <button
    *ngIf="isProfileChanged || isClimateChanged"
    mat-raised-button
    (click)="approveChanges()"
  >
    <span coContent="DL.INVGU01.C186"></span>
    <co-icon-arrow-right class="icon icon--arrow-right"></co-icon-arrow-right>
  </button>

  <button
    *ngIf="!isProfileChanged && !isClimateChanged"
    mat-raised-button
    coContent="Global.Afslut"
    (click)="completeAndExit()"
  ></button>
</div>
