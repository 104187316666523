/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { InvestmentQuestionaire } from './investmentQuestionaire';
import { InvestmentQuestionaireSchema } from './investmentQuestionaire';
import { InvestmentRecommendation } from './investmentRecommendation';
import { InvestmentRecommendationSchema } from './investmentRecommendation';
import { QuestionReached } from './questionReached';
import { QuestionReachedSchema } from './questionReached';
import * as JoiObj from "joi";


    export const InvestmentGuideSchema = JoiObj.object({
        advisorSkipFlow:
            
                    JoiObj.boolean()
                


.required()


        ,
        currentQuestion:
            
        JoiObj.link('#QuestionReachedSchema')


.required()


        ,
        recommendation:
            
        JoiObj.link('#InvestmentRecommendationSchema')


.required()


        ,
        recommendationBasis:
            
        JoiObj.link('#InvestmentQuestionaireSchema')


.required()


        
    })
                .shared(QuestionReachedSchema)
                .shared(InvestmentRecommendationSchema)
                .shared(InvestmentQuestionaireSchema)
.id('InvestmentGuideSchema')

export interface InvestmentGuide { 
    advisorSkipFlow: boolean;
    currentQuestion: QuestionReached;
    recommendation: InvestmentRecommendation;
    recommendationBasis: InvestmentQuestionaire;
}
export namespace InvestmentGuide {
}


