import { Component, Input } from '@angular/core';

@Component({
  selector: 'mitpfa-pension-check-header-section',
  templateUrl: './pension-check-header-section.component.html',
  styleUrls: ['./pension-check-header-section.component.scss']
})
export class PensionCheckHeaderSectionComponent {
  @Input() headline: string;
  @Input() explanation: string;
}
