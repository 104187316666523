import { Component, ContentChild } from '@angular/core';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatRadioButton } from '@angular/material/radio';

@Component({
  selector: 'co-selection-wrapper',
  templateUrl: 'selection-wrapper.component.html',
  styleUrls: ['./selection-wrapper.component.scss']
})
export class SelectionWrapperComponent {
  @ContentChild(MatCheckbox) checkbox: MatCheckbox;
  @ContentChild(MatRadioButton) radiobutton: MatRadioButton;
}
