/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ServiceInfo } from './serviceInfo';
import { ServiceInfoSchema } from './serviceInfo';
import { KonceptFordeling } from './konceptFordeling';
import { KonceptFordelingSchema } from './konceptFordeling';
import * as JoiObj from "joi";


    export const OpsparingKonceptFordelingSchema = JoiObj.object({
        serviceInfo:
            
        JoiObj.link('#ServiceInfoSchema')

.allow(null)



        ,
        duInvesterer:
            
                    JoiObj.number()
                


.required()


        ,
        duInvestererMax:
            
                    JoiObj.number()
                


.required()


        ,
        duInvestererMin:
            
                    JoiObj.number()
                


.required()


        ,
        origDuInvesterer:
            
                    JoiObj.number()
                


.required()


        ,
        origPfaInvesterer:
            
                    JoiObj.number()
                


.required()


        ,
        pfaInvesterer:
            
                    JoiObj.number()
                


.required()


        ,
        pfaInvestererMax:
            
                    JoiObj.number()
                


.required()


        ,
        pfaInvestererMin:
            
                    JoiObj.number()
                


.required()


        ,
        beloeb:
            
                    JoiObj.number()
                


.required()


        
    })
                .shared(ServiceInfoSchema)
.id('OpsparingKonceptFordelingSchema')

export interface OpsparingKonceptFordeling extends KonceptFordeling { 
    beloeb: number;
}

