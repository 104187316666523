/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const TilstandSchema = JoiObj.string().valid(
        'LoebendeIndbetaling'
        ,
        'Fripolice'
        ,
        'Henstand'
        ,
        'Orlov'
        ,
        'Annulleret'
        ,
        'UnderLukning'
        ,
        'Tilbagekoebt'
        ,
        'OverfoertUd'
        ,
        'Aktuel'
        ,
        'Lukket'
        ,
        'Stop'
        ,
        'MidlertidigIndbetalingssikring'
        ,
        'OverfoertSammenlaegning'
        ,
        'Depotbetalt'
        ,
        'Udbetalingspolicedoed'
        ,
        'TabAfErhvervsevne'
        
    )
.id('TilstandSchema')

export type Tilstand = 'LoebendeIndbetaling' | 'Fripolice' | 'Henstand' | 'Orlov' | 'Annulleret' | 'UnderLukning' | 'Tilbagekoebt' | 'OverfoertUd' | 'Aktuel' | 'Lukket' | 'Stop' | 'MidlertidigIndbetalingssikring' | 'OverfoertSammenlaegning' | 'Depotbetalt' | 'Udbetalingspolicedoed' | 'TabAfErhvervsevne';

export const Tilstand = {
    LoebendeIndbetaling: 'LoebendeIndbetaling' as Tilstand,
    Fripolice: 'Fripolice' as Tilstand,
    Henstand: 'Henstand' as Tilstand,
    Orlov: 'Orlov' as Tilstand,
    Annulleret: 'Annulleret' as Tilstand,
    UnderLukning: 'UnderLukning' as Tilstand,
    Tilbagekoebt: 'Tilbagekoebt' as Tilstand,
    OverfoertUd: 'OverfoertUd' as Tilstand,
    Aktuel: 'Aktuel' as Tilstand,
    Lukket: 'Lukket' as Tilstand,
    Stop: 'Stop' as Tilstand,
    MidlertidigIndbetalingssikring: 'MidlertidigIndbetalingssikring' as Tilstand,
    OverfoertSammenlaegning: 'OverfoertSammenlaegning' as Tilstand,
    Depotbetalt: 'Depotbetalt' as Tilstand,
    Udbetalingspolicedoed: 'Udbetalingspolicedoed' as Tilstand,
    TabAfErhvervsevne: 'TabAfErhvervsevne' as Tilstand
};

