import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { HttpClient, HttpResponse } from '@angular/common/http';
import {
  AdvisorOptions,
  ByggeSimulationKmdlHtmlKontaktRequest,
  SwitchHsRequest,
  TilbudOverblik,
  ByggeKmdlHtmlKontraktSvar,
  SuccessResponse,
  CreateOfferReq,
  MinObligatoriskIndbetaling,
  ProcentMinMax,
  PolicyOfferPolicyType,
  DigitalSalgContext,
  AendreIndbetalingsSikringRequest,
  IndbetalingSikring,
  DigitalsalgService
} from '@pfa/gen';

@Injectable({
  providedIn: 'root'
})
export class NewBusinessAdvisoryService {
  private readonly isNewBusinessAdvisory = new BehaviorSubject<boolean>(false);
  isNewBusinessAdvisory$ = this.isNewBusinessAdvisory.asObservable();

  private readonly newBusinessAdvisory = new ReplaySubject<DigitalSalgContext>(
    1
  );
  newBusinessAdvisory$ = this.newBusinessAdvisory.asObservable();
  private readonly digitalSalgService: DigitalsalgService =
    inject(DigitalsalgService);

  private readonly http: HttpClient = inject(HttpClient);

  getOverview(): Observable<TilbudOverblik> {
    const requestUrl = '/ds/api/digitalsalg/tilbudoverblik';
    return this.http.get<TilbudOverblik>(requestUrl);
  }

  getNewBusinessAdvisory(): Observable<DigitalSalgContext> {
    return this.digitalSalgService.digitalsalgRaadgivningGet('response').pipe(
      map((data: HttpResponse<DigitalSalgContext>) => {
        this.isNewBusinessAdvisory.next(!!data.body.digitalSalgRaadgivning);
        this.newBusinessAdvisory.next(data.body);
        return data.body;
      })
    );
  }

  getAdvisorOptions(type: PolicyOfferPolicyType): Observable<AdvisorOptions> {
    const url = `/ds/api/individualcustomeradvisory/advisoroptions/${type}`;
    return this.http.get<AdvisorOptions>(url).pipe(shareReplay(1));
  }

  savePayments(
    indbetaling: MinObligatoriskIndbetaling,
    payments: { employer: number; employee: number }
  ): Observable<Response> {
    const requestUrl = '/ds/api/digitalsalg/obligatoriskindbetaling';

    let arbejdsgiverProcentMinMax: ProcentMinMax;
    if (indbetaling.arbejdesgiverIndbetalingEffektueringMuligt) {
      arbejdsgiverProcentMinMax = {
        vaerdi: payments.employer,
        min: indbetaling.arbejdsgiverProcentMinMax.min,
        max: indbetaling.arbejdsgiverProcentMinMax.max
      };
    }

    let medarbejderProcentMinMax: ProcentMinMax;
    if (indbetaling.medarbejderIndbetalingEffektueringMuligt) {
      medarbejderProcentMinMax = {
        vaerdi: payments.employee,
        min: indbetaling.medarbejderProcentMinMax.min,
        max: indbetaling.medarbejderProcentMinMax.max
      };
    }

    const request: MinObligatoriskIndbetaling = {
      procentOrdning: indbetaling.procentOrdning,
      arbejdesgiverIndbetalingEffektueringMuligt:
        indbetaling.arbejdesgiverIndbetalingEffektueringMuligt,
      medarbejderIndbetalingEffektueringMuligt:
        indbetaling.medarbejderIndbetalingEffektueringMuligt,
      arbejdsgiverProcentMinMax: arbejdsgiverProcentMinMax,
      medarbejderProcentMinMax: medarbejderProcentMinMax,
      serviceInfo: undefined
    };
    return this.http.post<Response>(requestUrl, request);
  }

  saveVoluntaryPayment(payment: number): Observable<Response> {
    const requestUrl = '/ds/api/digitalsalg/frivilligindbetaling';
    return this.http.post<Response>(requestUrl, payment);
  }

  saveIndbetalingSikring(
    indbetalingSikring: IndbetalingSikring
  ): Observable<Response> {
    const requestUrl = '/ds/api/digitalsalg/indbetalingsikring';
    const aendreIndbetalingsSikringRequest: AendreIndbetalingsSikringRequest = {
      indbetalingSikring
    };
    return this.http.post<Response>(
      requestUrl,
      aendreIndbetalingsSikringRequest
    );
  }

  switchHs(partialSchemeId: number): Observable<SuccessResponse> {
    const requestUrl = '/ds/api/digitalsalg/switchhs';
    const request: SwitchHsRequest = {
      partialSchemeID: partialSchemeId
    };
    return this.http.post<SuccessResponse>(requestUrl, request);
  }

  createOffer(request: CreateOfferReq): Observable<Response> {
    const requestUrl = '/ds/api/digitalsalg/createoffer';
    return this.http.post<Response>(requestUrl, request);
  }

  buildAgreement(
    request: ByggeSimulationKmdlHtmlKontaktRequest
  ): Observable<ByggeKmdlHtmlKontraktSvar> {
    const requestUrl = '/ds/api/digitalsalg/byggeSimulationKmdlHtmlKontrakt';
    return this.http.post<ByggeKmdlHtmlKontraktSvar>(requestUrl, request);
  }

  afslutRaadgivning(): Observable<Response> {
    const requestUrl = '/ds/api/digitalsalg/afslutraadgivning';
    return this.http.post<Response>(requestUrl, {});
  }

  savePayerId(): Observable<Response> {
    const requestUrl = '/ds/api/digitalsalg/savepayerid';
    return this.http.post<Response>(requestUrl, {});
  }
}
