<svg
  id="Layer_1"
  data-name="Layer 1"
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 32 32"
>
  <g id="Canvas">
    <circle
      id="Large_circle"
      data-name="Large circle"
      cx="16"
      cy="16"
      r="16"
      fill="none"
    />
    <circle
      id="Small_circle"
      data-name="Small circle"
      cx="16"
      cy="16"
      r="12"
      fill="none"
    />
  </g>
  <g id="Icon">
    <circle
      id="Center"
      cx="16"
      cy="17.25"
      r="0.6"
      fill="none"
      stroke="#000"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.2"
    />
    <line
      id="Minute_arm"
      data-name="Minute arm"
      x1="16"
      y1="17.25"
      x2="11"
      y2="17.25"
      stroke-width="1.2"
      stroke="#000"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
    />
    <line
      id="Hour_arm"
      data-name="Hour arm"
      x1="15.99"
      y1="17.24"
      x2="15.99"
      y2="13.24"
      stroke-width="1.2"
      stroke="#000"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
    />
    <line
      id="_55_min"
      data-name=" 55 min"
      x1="12.32"
      y1="11"
      x2="12.33"
      y2="11"
      stroke-width="1.2"
      stroke="#000"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
    />
    <line
      id="_50_min"
      data-name=" 50 min"
      x1="9.72"
      y1="13.62"
      x2="9.73"
      y2="13.62"
      stroke-width="1.2"
      stroke="#000"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
    />
    <path
      id="Time"
      d="M16,10a7.54,7.54,0,0,1,1.89.25,7,7,0,0,1,1.74.72,7.48,7.48,0,0,1,1.48,1.14,7.06,7.06,0,0,1,1.14,1.47,6.93,6.93,0,0,1,.75,1.8,7.25,7.25,0,0,1-3.32,8.12,7.22,7.22,0,0,1-1.79.75A7.54,7.54,0,0,1,16,24.5a7.38,7.38,0,0,1-1.87-.24A7.3,7.3,0,0,1,10.9,22.4a7.47,7.47,0,0,1-1.15-1.47A7.29,7.29,0,0,1,9,19.13a7.47,7.47,0,0,1-.25-1.88"
      fill="none"
      stroke="#000"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.2"
    />
    <line
      id="Pin"
      x1="16"
      y1="7"
      x2="16"
      y2="8.2"
      stroke-width="1.2"
      stroke="#000"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
    />
    <line
      id="Button"
      x1="14"
      y1="7"
      x2="18"
      y2="7"
      stroke-width="1.2"
      stroke="#000"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
    />
  </g>
</svg>
