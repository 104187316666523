<!-- v9 -->
<svg
  id="Layer_1"
  data-name="Layer 1"
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 32 32"
>
  <g id="Canvas">
    <circle
      id="Large_circle"
      data-name="Large circle"
      cx="16"
      cy="16"
      r="16"
      fill="none"
    />
    <circle
      id="Small_circle"
      data-name="Small circle"
      cx="16"
      cy="16"
      r="12"
      fill="none"
    />
  </g>
  <g id="Icon">
    <line
      id="Third_dash"
      data-name="Third dash"
      x1="18.5"
      y1="16"
      x2="19.5"
      y2="16"
      stroke-width="1.2"
      stroke="#000"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
    />
    <line
      id="Second_dash"
      data-name="Second dash"
      x1="15.5"
      y1="16"
      x2="16.5"
      y2="16"
      stroke-width="1.2"
      stroke="#000"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
    />
    <line
      id="First_dash"
      data-name="First dash"
      x1="12.5"
      y1="16"
      x2="13.5"
      y2="16"
      stroke-width="1.2"
      stroke="#000"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
    />
    <circle id="Dot" cx="22.68" cy="13.18" r="1" />
    <polyline
      id="Arrow"
      points="20.68 7.9 21.1 10.87 18.13 11.29"
      fill="none"
      stroke="#000"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.2"
    />
    <path
      id="Circle"
      d="M22.68,13.18A7.18,7.18,0,0,1,23.25,16a7.26,7.26,0,1,1-2.15-5.15"
      fill="none"
      stroke="#000"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.2"
    />
  </g>
</svg>
