<div *ngIf="!showModal" class="spacing-unit--double-under">
  <div class="row">
    <div class="col-12 col-md-10 col-lg-8">
      <h1 [coContent]="'DL.FF01.C01'"></h1>
      <a
        href
        class="forbehold-submenu"
        [ngClass]="{
          active: !selectedMenu || selectedMenu === 'forudsaetning'
        }"
        [routerLink]="'/forbehold'"
        [coContent]="'DL.FF01.C05'"
      >
      </a>
      <a
        href
        class="forbehold-submenu"
        [ngClass]="{ active: selectedMenu === 'pensionsplan' }"
        [routerLink]="'/forbehold/pensionsplan'"
        [coContent]="'DL.FF01.C395'"
      >
      </a>
      <a
        href
        class="forbehold-submenu"
        [ngClass]="{ active: selectedMenu === 'loebende' }"
        [routerLink]="'/forbehold/loebende'"
        [coContent]="'DL.FF01.C74'"
      >
      </a>
      <a
        href
        class="forbehold-submenu"
        [ngClass]="{ active: selectedMenu === 'prognose' }"
        [routerLink]="'/forbehold/prognose'"
        [coContent]="'DL.FF01.C49'"
      >
      </a>
      <a
        href
        class="forbehold-submenu"
        [ngClass]="{ active: selectedMenu === 'forsikring' }"
        [routerLink]="'/forbehold/forsikring'"
        [coContent]="'DL.FF01.C66'"
      >
      </a>
      <a
        href
        class="forbehold-submenu"
        [ngClass]="{ active: selectedMenu === 'forsikringsguide' }"
        [routerLink]="'/forbehold/forsikringsguide'"
        [coContent]="'DL.FF01.C248'"
      >
      </a>
      <a
        href
        class="forbehold-submenu"
        [ngClass]="{ active: selectedMenu === 'genkoeb' }"
        [routerLink]="'/forbehold/genkoeb'"
        [coContent]="'DL.FF01.C384'"
      >
      </a>
    </div>
  </div>
</div>

<div *ngIf="!selectedMenu || selectedMenu === 'forudsaetning'">
  <div class="row">
    <div class="col-12 col-md-10 col-lg-8">
      <span id="forudsaetning_overskrift"></span>
      <h2 [coContent]="'DL.FF01.C05'"></h2>
      <p class="text--manchet" [coContent]="'DL.FF01.C08'"></p>
      <h3 [coContent]="'DL.FF01.C09'"></h3>
      <p [coContent]="'DL.FF01.C10'"></p>
      <h3 [coContent]="'DL.FF01.C11'"></h3>
      <p [coContent]="'DL.FF01.C12'"></p>
      <h3 [coContent]="'DL.FF01.C13'"></h3>
      <p [coContent]="'DL.FF01.C14'"></p>
      <h3 [coContent]="'DL.FF01.C17'"></h3>
      <p [coContent]="'DL.FF01.C18'"></p>
      <h3 [coContent]="'DL.FF01.C19'"></h3>
      <p [coContent]="'DL.FF01.C20'"></p>
      <h3 [coContent]="'DL.FF01.C21'"></h3>
      <p [coContent]="'DL.FF01.C22'"></p>
      <h3 [coContent]="'DL.FF01.C23'"></h3>
      <p [coContent]="'DL.FF01.C24'"></p>
      <h3 [coContent]="'DL.FF01.C25'"></h3>
      <p [coContent]="'DL.FF01.C26'"></p>
      <h3 [coContent]="'DL.FF01.C249'"></h3>
      <p [coContent]="'DL.FF01.C281'"></p>
      <p [coContent]="'DL.FF01.C282'"></p>
    </div>
  </div>
</div>

<div *ngIf="selectedMenu === 'prognose'">
  <div class="row">
    <div class="col-12 col-md-10 col-lg-8">
      <span id="prognose_overskrift"></span>
      <h2 [coContent]="'DL.FF01.C49'"></h2>
      <p [coContent]="'DL.FF01.C50'"></p>
      <div class="spacing-unit--double-over">
        <h3 [coContent]="'DL.FF01.C51'"></h3>
      </div>
      <p [coContent]="'DL.FF01.C52'"></p>
      <div class="spacing-unit--double-over">
        <h3 [coContent]="'DL.FF01.C53'"></h3>
      </div>
      <p [coContent]="'DL.FF01.C54'"></p>
      <div class="spacing-unit--double-over">
        <h3 [coContent]="'DL.FF01.C55'"></h3>
      </div>
      <h3 [coContent]="'DL.FF01.C570'"></h3>
      <p [coContent]="'DL.FF01.C56'"></p>
      <p [coContent]="'DL.FF01.C57'"></p>
      <div class="spacing-unit--double-over">
        <h3 [coContent]="'DL.FF01.C58'"></h3>
      </div>
      <p [coContent]="'DL.FF01.C59'"></p>
      <div class="spacing-unit--double-over">
        <h3 [coContent]="'DL.FF01.C571'"></h3>
      </div>
      <p [coContent]="'DL.FF01.C573'"></p>
    </div>
  </div>
  <table
    *ngIf="pensionForecastPfa"
    class="pfa-table pfa-table--compact align-left--column-1 align-right--column-2 align-right--column-3 align-right--column-4"
  >
    <thead>
      <tr>
        <th [coContent]="'DL.FF01.C192'"></th>
        <th *ngFor="let period of pensionForecastPfa.periods">
          {{ period }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let row of pensionForecastPfa.rows">
        <td>
          {{ row.fundType }}
        </td>
        <td *ngFor="let forecast of row.forecasts; let index = index">
          {{ forecast | content | numberFormat: 2 }}
        </td>
      </tr>
    </tbody>
  </table>

  <div class="row">
    <div class="col-12 col-md-10 col-lg-8">
      <p [coContent]="'DL.FF01.C60'"></p>
      <div class="spacing-unit--double-over">
        <h3 [coContent]="'DL.FF01.C580'"></h3>
      </div>
      <p [coContent]="'DL.FF01.C581'"></p>
    </div>
  </div>
  <table *ngIf="pensionForecastYou" class="prognosis-table">
    <thead>
      <tr>
        <th colspan="2">
          <span [coContent]="'DL.FF01.C582'"></span>
        </th>
        <th colspan="2" *ngFor="let period of pensionForecastYou.periods">
          {{ period }}<br />
          <span [coContent]="'DL.FF01.C583'"></span>
        </th>
      </tr>
      <tr>
        <th>
          <div class="text--bold" [coContent]="'DL.FF01.C584'"></div>
          <br />
          (<span [coContent]="'DL.FF01.C585'"></span>)
        </th>
        <th>
          <div class="text--bold" [coContent]="'DL.FF01.C586'"></div>
          <br />
          (<span [coContent]="'DL.FF01.C587'"></span>)
        </th>
        <ng-container *ngFor="let period of pensionForecastYou.periods">
          <th>
            <div class="text--bold" [coContent]="'DL.FF01.C588'"></div>
          </th>
          <th>
            <div class="text--bold" [coContent]="'DL.FF01.C589'"></div>
            <br />
            <span [coContent]="'DL.FF01.C590'"></span>
          </th>
        </ng-container>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let row of pensionForecastYou.rows">
        <td>{{ row.fundName }}<br /></td>
        <td>
          {{ row.assetClass }}
        </td>
        <ng-container *ngFor="let forecast of row.forecasts">
          <td>
            {{ safeFormatNumber(forecast.costs) }}
          </td>
          <td>
            {{ safeFormatNumber(forecast.returnAfterCosts) }}
          </td>
        </ng-container>
      </tr>
    </tbody>
  </table>

  <div class="row spacing-unit--half-over">
    <div class="col-12 col-md-10 col-lg-8">
      <p [coContent]="'DL.FF01.C591'"></p>
      <p [coContent]="'DL.FF01.C592'"></p>
      <p [coContent]="'DL.FF01.C593'"></p>

      <div class="spacing-unit--double-over">
        <h3 [coContent]="'DL.FF01.C250'"></h3>
      </div>

      <p [coContent]="'DL.FF01.C251'"></p>
    </div>
  </div>
  <table
    *ngIf="pensionForecastAverage"
    class="pfa-table pfa-table--compact align-left--column-1 align-right--column-2 align-right--column-3 align-right--column-4"
  >
    <thead>
      <tr>
        <th [coContent]="'DL.FF01.C252'"></th>
        <ng-container
          *ngFor="
            let avgForecast of pensionForecastAverage.forecastInPeriods;
            let i = index
          "
        >
          <th *ngIf="i < 3">
            {{ avgForecast.period }}
          </th>
        </ng-container>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td [coContent]="'DL.FF01.C253'"></td>
        <ng-container
          *ngFor="
            let avgForecast of pensionForecastAverage.forecastInPeriods;
            let i = index
          "
        >
          <td *ngIf="i < 3">
            {{ avgForecast.forecast | content | numberFormat: 2 }}
          </td>
        </ng-container>
      </tr>
      <tr>
        <td [coContent]="'DL.FF01.C603'"></td>
        <ng-container
          *ngFor="
            let avgForecast of pensionForecastAverage.forecastInPeriods;
            let i = index
          "
        >
          <td *ngIf="i >= 3 && i < 6">
            {{ avgForecast.forecast | content | numberFormat: 2 }}
          </td>
        </ng-container>
      </tr>

      <tr>
        <td [coContent]="'DL.FF01.C604'"></td>
        <ng-container
          *ngFor="
            let avgForecast of pensionForecastAverage.forecastInPeriods;
            let i = index
          "
        >
          <td *ngIf="i >= 6 && i < 9">
            {{ avgForecast.forecast | content | numberFormat: 2 }}
          </td>
        </ng-container>
      </tr>

      <tr>
        <td [coContent]="'DL.FF01.C605'"></td>
        <ng-container
          *ngFor="
            let avgForecast of pensionForecastAverage.forecastInPeriods;
            let i = index
          "
        >
          <td *ngIf="i >= 9 && i < 12">
            {{ avgForecast.forecast | content | numberFormat: 2 }}
          </td>
        </ng-container>
      </tr>
    </tbody>
  </table>

  <div class="row">
    <div class="col-12 col-md-10 col-lg-8">
      <div class="spacing-unit--double-over">
        <h3 [coContent]="'DL.FF01.C61'"></h3>
      </div>
      <p [coContent]="'DL.FF01.C62'"></p>
    </div>
  </div>

  <table
    *ngIf="pensionForecastIndividual"
    class="pfa-table pfa-table--compact align-left--column-1 align-right--column-2 align-right--column-3 align-right--column-4"
  >
    <thead>
      <tr>
        <th [coContent]="'DL.FF01.C227'"></th>
        <th
          *ngFor="
            let indivForecast of pensionForecastIndividual.forecastInPeriods
          "
        >
          {{ indivForecast.period }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td [coContent]="'DL.FF01.C234'"></td>
        <td
          *ngFor="
            let indivForecast of pensionForecastIndividual.forecastInPeriods
          "
        >
          {{ safeFormatNumber(indivForecast.forecast) }}
        </td>
      </tr>
    </tbody>
  </table>

  <div class="row">
    <div class="col-12 col-md-10 col-lg-8">
      <p [coContent]="'DL.FF01.C63'"></p>
      <div [coContent]="'DL.FF01.C283'"></div>
      <ul class="list--unnumbered">
        <li [coContent]="'DL.FF01.C285'"></li>
      </ul>
      <div class="spacing-unit--double-over">
        <h3 [coContent]="'DL.FF01.C64'"></h3>
      </div>
      <p [coContent]="'DL.FF01.C65'"></p>
    </div>
  </div>
</div>

<div *ngIf="selectedMenu === 'forsikring'">
  <div class="row">
    <div class="col-12 col-md-10 col-lg-8">
      <span id="forsikring_overskrift"></span>
      <h2 [coContent]="'DL.FF01.C66'"></h2>
      <p [coContent]="'DL.FF01.C67'"></p>
      <h3 [coContent]="'DL.FF01.C68'"></h3>
      <p [coContent]="'DL.FF01.C69'"></p>
      <h3 [coContent]="'DL.FF01.C70'"></h3>
      <p [coContent]="'DL.FF01.C71'"></p>
      <h3 [coContent]="'DL.FF01.C72'"></h3>
      <p [coContent]="'DL.FF01.C73'"></p>
    </div>
  </div>
</div>

<div *ngIf="selectedMenu === 'loebende'">
  <div class="row">
    <div class="col-12 col-md-10 col-lg-8">
      <span id="loebende_overskrift"></span>
      <h2 [coContent]="'DL.FF01.C74'"></h2>
      <p [coContent]="'DL.FF01.C75'"></p>
    </div>
  </div>

  <table
    *ngIf="ratesMarketRate"
    class="pfa-table pfa-table--compact table-responsive spacing-unit--double-under align-right--column-2 align-right--column-3"
  >
    <thead class="hide--palm">
      <tr>
        <th [coContent]="'DL.FF01.C241'"></th>
        <th [coContent]="'DL.FF01.C242'"></th>
        <th [coContent]="'DL.FF01.C243'"></th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td coContent="DL.FF01.C244"></td>
        <td [attr.data-label]="tableHeaders.loebendepension_med">
          {{
            ratesMarketRate.profileA.payoutProtection === null
              ? '-'
              : (ratesMarketRate.profileA.payoutProtection | numberFormat: 2)
          }}
        </td>
        <td [attr.data-label]="tableHeaders.loebendepension_uden">
          {{
            ratesMarketRate.profileA.withoutPayoutProtection === null
              ? '-'
              : (ratesMarketRate.profileA.withoutPayoutProtection
                | numberFormat: 2)
          }}
        </td>
      </tr>
      <tr>
        <td coContent="DL.FF01.C606"></td>
        <td [attr.data-label]="tableHeaders.loebendepension_med">
          {{
            ratesMarketRate.profileB.payoutProtection === null
              ? '-'
              : (ratesMarketRate.profileB.payoutProtection | numberFormat: 2)
          }}
        </td>
        <td [attr.data-label]="tableHeaders.loebendepension_uden">
          {{
            ratesMarketRate.profileB.withoutPayoutProtection === null
              ? '-'
              : (ratesMarketRate.profileB.withoutPayoutProtection
                | numberFormat: 2)
          }}
        </td>
      </tr>
      <tr>
        <td coContent="DL.FF01.C607"></td>
        <td [attr.data-label]="tableHeaders.loebendepension_med">
          {{
            ratesMarketRate.profileC.payoutProtection === null
              ? '-'
              : (ratesMarketRate.profileC.payoutProtection | numberFormat: 2)
          }}
        </td>
        <td [attr.data-label]="tableHeaders.loebendepension_uden">
          {{
            ratesMarketRate.profileC.withoutPayoutProtection === null
              ? '-'
              : (ratesMarketRate.profileC.withoutPayoutProtection
                | numberFormat: 2)
          }}
        </td>
      </tr>
      <tr>
        <td coContent="DL.FF01.C608"></td>
        <td [attr.data-label]="tableHeaders.loebendepension_med">
          {{
            ratesMarketRate.profileD.payoutProtection === null
              ? '-'
              : (ratesMarketRate.profileD.payoutProtection | numberFormat: 2)
          }}
        </td>
        <td [attr.data-label]="tableHeaders.loebendepension_uden">
          {{
            ratesMarketRate.profileD.withoutPayoutProtection === null
              ? '-'
              : (ratesMarketRate.profileD.withoutPayoutProtection
                | numberFormat: 2)
          }}
        </td>
      </tr>
    </tbody>
  </table>

  <div class="row">
    <div class="col-12 col-md-10 col-lg-8">
      <p [coContent]="'DL.FF01.C556'"></p>
      <p [coContent]="'DL.FF01.C557'"></p>
    </div>
  </div>

  <table class="pfa-table pfa-table--compact spacing-unit--double-under">
    <tbody>
      <tr>
        <td [coContent]="'DL.FF01.C558'"></td>
        <td [coContent]="'DL.FF01.C560'"></td>
        <td [coContent]="'DL.FF01.C561'"></td>
        <td [coContent]="'DL.FF01.C562'"></td>
        <td [coContent]="'DL.FF01.C563'"></td>
        <td [coContent]="'DL.FF01.C564'"></td>
      </tr>
      <tr>
        <td [coContent]="'DL.FF01.C559'"></td>
        <td [coContent]="'DL.FF01.C565'"></td>
        <td [coContent]="'DL.FF01.C566'"></td>
        <td [coContent]="'DL.FF01.C567'"></td>
        <td [coContent]="'DL.FF01.C568'"></td>
        <td [coContent]="'DL.FF01.C569'"></td>
      </tr>
    </tbody>
  </table>

  <div class="row">
    <div class="col-12 col-md-10 col-lg-8">
      <p [coContent]="'DL.FF01.C76'"></p>
      <h3 [coContent]="'DL.FF01.C77'"></h3>
      <p [coContent]="'DL.FF01.C78'"></p>
      <h3 [coContent]="'DL.FF01.C79'"></h3>
      <p [coContent]="'DL.FF01.C80'"></p>
      <h3 [coContent]="'DL.FF01.C81'"></h3>
      <p [coContent]="'DL.FF01.C82'"></p>
      <h3 [coContent]="'DL.FF01.C83'"></h3>
      <p [coContent]="'DL.FF01.C84'"></p>
      <h3 [coContent]="'DL.FF01.C85'"></h3>
      <p [coContent]="'DL.FF01.C86'"></p>
    </div>
  </div>
</div>

<div *ngIf="selectedMenu === 'pensionstal'">
  <div class="row">
    <div class="col-12 col-md-10 col-lg-8">
      <span id="pensionstal_overskrift"></span>
      <h2 [coContent]="'DL.FF01.C247'"></h2>
      <p [coContent]="'DL.FF01.C267'"></p>
      <p [coContent]="'DL.FF01.C268'"></p>
      <p [coContent]="'DL.FF01.C269'"></p>
      <p [coContent]="'DL.FF01.C270'"></p>
      <p [coContent]="'DL.FF01.C271'"></p>
      <p [coContent]="'DL.FF01.C272'"></p>

      <h3 [coContent]="'DL.FF01.C273'"></h3>
      <p [coContent]="'DL.FF01.C289'"></p>

      <h3 [coContent]="'DL.FF01.C290'"></h3>
      <div [coContent]="'DL.FF01.C291'"></div>
      <ul class="list--unnumbered">
        <li [coContent]="'DL.FF01.C292'"></li>
        <li [coContent]="'DL.FF01.C293'"></li>
        <li [coContent]="'DL.FF01.C294'"></li>
        <li [coContent]="'DL.FF01.C295'"></li>
        <li [coContent]="'DL.FF01.C296'"></li>
      </ul>

      <h3 [coContent]="'DL.FF01.C297'"></h3>
      <p [coContent]="'DL.FF01.C298'"></p>
      <div [coContent]="'DL.FF01.C299'"></div>
      <ul class="list--unnumbered">
        <li [coContent]="'DL.FF01.C300'"></li>
        <li [coContent]="'DL.FF01.C301'"></li>
        <li [coContent]="'DL.FF01.C302'"></li>
        <li [coContent]="'DL.FF01.C303'"></li>
        <li [coContent]="'DL.FF01.C304'"></li>
        <li [coContent]="'DL.FF01.C305'"></li>
      </ul>
      <p [coContent]="'DL.FF01.C306'"></p>

      <h3 [coContent]="'DL.FF01.C307'"></h3>
      <p [coContent]="'DL.FF01.C308'"></p>
      <div [coContent]="'DL.FF01.C309'"></div>
      <ul class="list--unnumbered">
        <li [coContent]="'DL.FF01.C310'"></li>
        <li [coContent]="'DL.FF01.C311'"></li>
        <li [coContent]="'DL.FF01.C312'"></li>
        <li [coContent]="'DL.FF01.C313'"></li>
        <li [coContent]="'DL.FF01.C314'"></li>
        <li [coContent]="'DL.FF01.C315'"></li>
        <li [coContent]="'DL.FF01.C316'"></li>
        <li [coContent]="'DL.FF01.C317'"></li>
        <li [coContent]="'DL.FF01.C318'"></li>
        <li [coContent]="'DL.FF01.C319'"></li>
        <li [coContent]="'DL.FF01.C320'"></li>
        <li [coContent]="'DL.FF01.C321'"></li>
        <li [coContent]="'DL.FF01.C322'"></li>
        <li [coContent]="'DL.FF01.C323'"></li>
      </ul>
    </div>
  </div>
</div>

<div *ngIf="selectedMenu === 'forsikringsguide'">
  <div class="row">
    <div class="col-12 col-md-10 col-lg-8">
      <span id="forsikringsguide_overskrift"></span>
      <h2 [coContent]="'DL.FF01.C248'"></h2>
      <p [coContent]="'DL.FF01.C274'"></p>
      <p [coContent]="'DL.FF01.C549'"></p>
      <p [coContent]="'DL.FF01.C553'"></p>
      <p [coContent]="'DL.FF01.C275'"></p>
      <div [coContent]="'DL.FF01.C276'"></div>
      <ul class="list--unnumbered">
        <li [coContent]="'DL.FF01.C277'"></li>
        <li [coContent]="'DL.FF01.C278'"></li>
        <li [coContent]="'DL.FF01.C279'"></li>
        <li [coContent]="'DL.FF01.C280'"></li>
        <li [coContent]="'DL.FF01.C326'"></li>
        <li [coContent]="'DL.FF01.C327'"></li>
        <li [coContent]="'DL.FF01.C545'"></li>
        <li [coContent]="'DL.FF01.C546'"></li>
        <li [coContent]="'DL.FF01.C547'"></li>
      </ul>
      <p [coContent]="'DL.FF01.C551'"></p>
      <p [coContent]="'DL.FF01.C473'"></p>
      <p [coContent]="'DL.FF01.C329'"></p>
      <p [coContent]="'DL.FF01.C331'" *ngIf="!hasTAEAllInclusive"></p>
      <p [coContent]="'DL.FF01.C575'" *ngIf="hasTAEAllInclusive"></p>
      <p [coContent]="'DL.FF01.C332'"></p>
      <p [coContent]="'DL.FF01.C552'"></p>
      <p [coContent]="'DL.FF01.C550'"></p>
      <p [coContent]="'DL.FF01.C554'"></p>

      <h2 [coContent]="'DL.FF01.C548'" class="spacing-unit--over"></h2>

      <h3 [coContent]="'DL.FF01.C333'"></h3>
      <p [coContent]="'DL.FF01.C334'"></p>
      <p [coContent]="'DL.FF01.C335'"></p>
      <p [coContent]="'DL.FF01.C336'"></p>
      <p [coContent]="'DL.FF01.C337'"></p>
      <div [coContent]="'DL.FF01.C338'"></div>
      <ul class="list--unnumbered">
        <li [coContent]="'DL.FF01.C339'"></li>
        <li [coContent]="'DL.FF01.C341'"></li>
      </ul>
      <p [coContent]="'DL.FF01.C342'"></p>

      <h3 [coContent]="'DL.FF01.C343'"></h3>
      <p [coContent]="'DL.FF01.C344'"></p>
      <p [coContent]="'DL.FF01.C345'"></p>
      <p [coContent]="'DL.FF01.C346'"></p>
      <p [coContent]="'DL.FF01.C347'"></p>
      <p [coContent]="'DL.FF01.C348'"></p>
      <p [coContent]="'DL.FF01.C349'"></p>
      <div [coContent]="'DL.FF01.C350'"></div>
      <ul class="list--unnumbered">
        <li [coContent]="'DL.FF01.C351'"></li>
        <li [coContent]="'DL.FF01.C352'"></li>
        <li [coContent]="'DL.FF01.C353'"></li>
        <li [coContent]="'DL.FF01.C354'"></li>
        <li [coContent]="'DL.FF01.C355'"></li>
      </ul>
      <p [coContent]="'DL.FF01.C356'"></p>
    </div>
  </div>
</div>

<div *ngIf="selectedMenu === 'investering'">
  <div class="row">
    <div class="col-12 col-md-10 col-lg-8">
      <span id="investering_overskrift"></span>
      <h2 [coContent]="'DL.FF01.C357'"></h2>

      <h3 class="text--uppercase" [coContent]="'DL.FF01.C358'"></h3>
      <p [coContent]="'DL.FF01.C359'"></p>
      <h3 [coContent]="'DL.FF01.C360'"></h3>
      <p [coContent]="'DL.FF01.C361'"></p>
      <h3 [coContent]="'DL.FF01.C362'"></h3>
      <p [coContent]="'DL.FF01.C363'"></p>
      <p [coContent]="'DL.FF01.C364'"></p>
      <h3 [coContent]="'DL.FF01.C365'"></h3>
      <p [coContent]="'DL.FF01.C366'"></p>
      <h3 [coContent]="'DL.FF01.C367'"></h3>
      <img
        src="/assets/images/eksempel_paa_aendring.png"
        style="width: 100%; max-width: 475px"
      />
      <br />
      <h3 [coContent]="'DL.FF01.C368'"></h3>
      <p [coContent]="'DL.FF01.C369'"></p>
      <h3 class="text--uppercase" [coContent]="'DL.FF01.C370'"></h3>
      <p [coContent]="'DL.FF01.C371'"></p>
      <h3 [coContent]="'DL.FF01.C372'"></h3>
      <p [coContent]="'DL.FF01.C373'"></p>
      <p [coContent]="'DL.FF01.C374'"></p>
      <p [coContent]="'DL.FF01.C375'"></p>
      <p [coContent]="'DL.FF01.C376'"></p>
      <p [coContent]="'DL.FF01.C377'"></p>
      <h3 [coContent]="'DL.FF01.C378'"></h3>
      <p [coContent]="'DL.FF01.C379'"></p>
      <p [coContent]="'DL.FF01.C380'"></p>
      <p [coContent]="'DL.FF01.C381'"></p>
      <p [coContent]="'DL.FF01.C382'"></p>
      <p [coContent]="'DL.FF01.C383'"></p>
    </div>
  </div>
</div>

<div *ngIf="selectedMenu === 'genkoeb'">
  <div class="row">
    <div class="col-12 col-md-10 col-lg-8">
      <span id="genkoeb_overskrift"></span>
      <h2 [coContent]="'DL.FF01.C384'"></h2>
      <h3 [coContent]="'DL.FF01.C390'"></h3>
      <p [coContent]="'DL.FF01.C391'"></p>
      <h3 [coContent]="'DL.FF01.C394'"></h3>
      <p [coContent]="'DL.FF01.C385'"></p>
      <h3 [coContent]="'DL.FF01.C386'"></h3>
      <p *ngIf="!flags.paragraf39" [coContent]="'DL.FF01.C387'"></p>
      <p *ngIf="flags.paragraf39" [coContent]="'DL.FF01.C555'"></p>
      <h3 [coContent]="'DL.FF01.C388'"></h3>
      <p [coContent]="'DL.FF01.C389'"></p>
      <h3 [coContent]="'DL.FF01.C392'"></h3>
      <p [coContent]="'DL.FF01.C393'"></p>
    </div>
  </div>
</div>

<div *ngIf="selectedMenu === 'pensionsplan' && forbeholdInitialized">
  <div class="row">
    <div class="col-12 col-md-10 col-lg-8">
      <span id="pensionsplan_overskrift"></span>
      <h2 [coContent]="'DL.FF01.C395'"></h2>
      <p [coContent]="'DL.FF01.C476'"></p>
      <p [coContent]="'DL.FF01.C477'"></p>
      <p [coContent]="'DL.FF01.C478'"></p>
      <p [coContent]="'DL.FF01.C479'"></p>
      <p [coContent]="'DL.FF01.C480'"></p>
      <br />
      <h3 class="text--uppercase" [coContent]="'DL.FF01.C481'"></h3>
      <p [coContent]="'DL.FF01.C482'"></p>
      <p [coContent]="'DL.FF01.C483'"></p>
      <p [coContent]="'DL.FF01.C484'"></p>

      <h3 [coContent]="'DL.FF01.C485'"></h3>
      <p [coContent]="'DL.FF01.C486'"></p>

      <h3 [coContent]="'DL.FF01.C487'"></h3>
      <p [coContent]="'DL.FF01.C488'"></p>
      <ul class="list--unnumbered">
        <li [coContent]="'DL.FF01.C489'"></li>
        <li [coContent]="'DL.FF01.C490'"></li>
        <li [coContent]="'DL.FF01.C491'"></li>
        <li [coContent]="'DL.FF01.C492'"></li>
        <li [coContent]="'DL.FF01.C493'"></li>
        <li [coContent]="'DL.FF01.C494'"></li>
        <li [coContent]="'DL.FF01.C495'"></li>
        <li [coContent]="'DL.FF01.C496'"></li>
      </ul>

      <h3 [coContent]="'DL.FF01.C497'"></h3>
      <p [coContent]="'DL.FF01.C499'"></p>

      <ul class="list--unnumbered">
        <li [coContent]="'DL.FF01.C500'"></li>
        <li [coContent]="'DL.FF01.C501'"></li>
        <li
          [coContent]="'DL.FF01.C426'"
          [coContentSubst]="
            pensionsplanForbehold.beskatningAfAfkast | numberFormat: 1
          "
        ></li>
        <li
          [coContent]="'DL.FF01.C429'"
          [coContentSubst]="
            pensionsplanForbehold.arbejdsmarkedsbidrag | numberFormat: 1
          "
        ></li>
        <li
          [coContent]="'DL.FF01.C430'"
          [coContentSubst]="pensionsplanForbehold.inflation | numberFormat: 1"
        ></li>
        <li
          [coContent]="'DL.FF01.C431'"
          [coContentSubst]="
            (pensionsplanForbehold.folkepension | numberFormat) +
            '|' +
            (pensionsplanForbehold.atp | numberFormat)
          "
        ></li>
        <li
          [coContent]="'DL.FF01.C432'"
          [coContentSubst]="
            (pensionsplanForbehold.indbetalingsandele +
              pensionsplanForbehold.nedsaettesFradraget | numberFormat: 1) +
            '|' +
            (pensionsplanForbehold.indbetalingsandele | numberFormat: 1) +
            '|' +
            (pensionsplanForbehold.nedsaettesFradraget | numberFormat: 1)
          "
        ></li>
        <li [coContent]="'DL.FF01.C504'"></li>
        <li [coContent]="'DL.FF01.C505'"></li>
        <li [coContent]="'DL.FF01.C506'"></li>
        <li [coContent]="'DL.FF01.C507'"></li>
        <li [coContent]="'DL.FF01.C508'"></li>
        <li [coContent]="'DL.FF01.C509'"></li>
      </ul>
      <p [coContent]="'DL.FF01.C510'"></p>

      <h3 class="text--uppercase" [coContent]="'DL.FF01.C511'"></h3>
      <p [coContent]="'DL.FF01.C512'"></p>
      <p [coContent]="'DL.FF01.C513'"></p>
      <p [coContent]="'DL.FF01.C516'"></p>
      <p [coContent]="'DL.FF01.C517'"></p>
      <p [coContent]="'DL.FF01.C518'"></p>
      <p [coContent]="'DL.FF01.C519'"></p>
      <p
        [coContent]="'DL.FF01.C520'"
        [coContentSubst]="
          pensionsplanForbehold.partnerIndkomstVedPensionering | numberFormat: 1
        "
      ></p>
      <p [coContent]="'DL.FF01.C521'"></p>

      <h3 [coContent]="'DL.FF01.C522'"></h3>
      <p [coContent]="'DL.FF01.C523'"></p>

      <h3 class="text--uppercase" [coContent]="'DL.FF01.C524'"></h3>
      <br />
      <h3 [coContent]="'DL.FF01.C525'"></h3>
      <p [coContent]="'DL.FF01.C526'"></p>

      <ul class="list--unnumbered">
        <li [coContent]="'DL.FF01.C527'"></li>
        <li [coContent]="'DL.FF01.C528'"></li>
        <li [coContent]="'DL.FF01.C529'"></li>
        <li [coContent]="'DL.FF01.C530'"></li>
        <li [coContent]="'DL.FF01.C531'"></li>
        <li [coContent]="'DL.FF01.C532'"></li>
        <li [coContent]="'DL.FF01.C533'"></li>
      </ul>

      <h3 [coContent]="'DL.FF01.C534'"></h3>
      <p [coContent]="'DL.FF01.C535'"></p>
      <p [coContent]="'DL.FF01.C536'"></p>
      <p [coContent]="'DL.FF01.C537'"></p>
      <p [coContent]="'DL.FF01.C538'"></p>
      <p [coContent]="'DL.FF01.C539'"></p>
      <p [coContent]="'DL.FF01.C540'"></p>
      <p [coContent]="'DL.FF01.C541'"></p>
    </div>
  </div>
  <table
    class="pfa-table pfa-table--compact table-responsive align-right--column-2 align-right--column-3 align-right--column-4 align-right--column-5 align-right--column-6"
  >
    <thead class="hide--palm">
      <tr>
        <th></th>
        <th [coContent]="'DL.FF01.C451'"></th>
        <th [coContent]="'DL.FF01.C452'"></th>
        <th [coContent]="'DL.FF01.C453'"></th>
        <th [coContent]="'DL.FF01.C454'"></th>
        <th [coContent]="'DL.FF01.C455'"></th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td [coContent]="'DL.FF01.C456'"></td>
        <td [attr.data-label]="tableHeaders.mobil_samfund">
          {{
            pensionsplanForbehold.aktierSamfundsforudsaetning | numberFormat: 2
          }}
        </td>
        <td [attr.data-label]="tableHeaders.mobil_omkostninger">
          {{ pensionsplanForbehold.aktierOmkostning | numberFormat: 2 }}
        </td>
        <td [attr.data-label]="tableHeaders.mobil_skat">
          {{ pensionsplanForbehold.aktierSkat | numberFormat: 2 }}
        </td>
        <td [attr.data-label]="tableHeaders.mobil_inflation">
          {{ pensionsplanForbehold.aktierInflation | numberFormat: 2 }}
        </td>
        <td [attr.data-label]="tableHeaders.mobil_nettorente">
          {{ nettorenter.aktier | numberFormat: 2 }}
        </td>
      </tr>
      <tr>
        <td [coContent]="'DL.FF01.C457'"></td>
        <td [attr.data-label]="tableHeaders.mobil_samfund">
          {{
            pensionsplanForbehold.obligationerSamfundsforudsaetning
              | numberFormat: 2
          }}
        </td>
        <td [attr.data-label]="tableHeaders.mobil_omkostninger">
          {{ pensionsplanForbehold.obligationerOmkostning | numberFormat: 2 }}
        </td>
        <td [attr.data-label]="tableHeaders.mobil_skat">
          {{ pensionsplanForbehold.obligationerSkat | numberFormat: 2 }}
        </td>
        <td [attr.data-label]="tableHeaders.mobil_inflation">
          {{ pensionsplanForbehold.obligationerInflation | numberFormat: 2 }}
        </td>
        <td [attr.data-label]="tableHeaders.mobil_nettorente">
          {{ nettorenter.obligationer | numberFormat: 2 }}
        </td>
      </tr>
      <tr>
        <td [coContent]="'DL.FF01.C458'"></td>
        <td [attr.data-label]="tableHeaders.mobil_samfund">
          {{
            pensionsplanForbehold.kontanterSamfundsforudsaetning
              | numberFormat: 2
          }}
        </td>
        <td [attr.data-label]="tableHeaders.mobil_omkostninger">
          {{ pensionsplanForbehold.kontanterOmkostning | numberFormat: 2 }}
        </td>
        <td [attr.data-label]="tableHeaders.mobil_skat">
          {{ pensionsplanForbehold.kontanterSkat | numberFormat: 2 }}
        </td>
        <td [attr.data-label]="tableHeaders.mobil_inflation">
          {{ pensionsplanForbehold.kontanterInflation | numberFormat: 2 }}
        </td>
        <td [attr.data-label]="tableHeaders.mobil_nettorente">
          {{ nettorenter.pengemarked | numberFormat: 2 }}
        </td>
      </tr>
      <tr>
        <td [coContent]="'DL.FF01.C459'"></td>
        <td [attr.data-label]="tableHeaders.mobil_samfund">
          {{
            pensionsplanForbehold.boligSamfundsforudsaetning | numberFormat: 2
          }}
        </td>
        <td [attr.data-label]="tableHeaders.mobil_omkostninger">
          {{ pensionsplanForbehold.boligOmkostning | numberFormat: 2 }}
        </td>
        <td [attr.data-label]="tableHeaders.mobil_skat">
          {{ pensionsplanForbehold.boligSkat | numberFormat: 2 }}
        </td>
        <td [attr.data-label]="tableHeaders.mobil_inflation">
          {{ pensionsplanForbehold.boligInflation | numberFormat: 2 }}
        </td>
        <td [attr.data-label]="tableHeaders.mobil_nettorente">
          {{ nettorenter.fastejendom | numberFormat: 2 }}
        </td>
      </tr>
    </tbody>
  </table>

  <div class="row">
    <div class="col-12 col-md-10 col-lg-8">
      <div class="footer--print">
        <span class="footer--info" [coContent]="'DL.PR01.C04'"></span>
        <span
          class="footer--info"
          [coContent]="'DL.PR01.C05'"
          [coContentSubst]="currentDate + '|' + currentTime"
        ></span>
      </div>

      <h3 [coContent]="'DL.FF01.C595'"></h3>
      <p [coContent]="'DL.FF01.C594'"></p>
    </div>
  </div>
</div>
