/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const SimulatorTileEnumSchema = JoiObj.string().valid(
        'MINE_BESTILLINGER'
        ,
        'KUNDE_KAPITAL'
        ,
        'INVESTERING'
        ,
        'PP_OPSPARINGSPLAN'
        ,
        'PP_UDBETALINGSPLAN'
        ,
        'FORSIKRING'
        ,
        'DIGITALSALG_TILBUDOVERBLIK'
        
    )
.id('SimulatorTileEnumSchema')

export type SimulatorTileEnum = 'MINE_BESTILLINGER' | 'KUNDE_KAPITAL' | 'INVESTERING' | 'PP_OPSPARINGSPLAN' | 'PP_UDBETALINGSPLAN' | 'FORSIKRING' | 'DIGITALSALG_TILBUDOVERBLIK';

export const SimulatorTileEnum = {
    MineBestillinger: 'MINE_BESTILLINGER' as SimulatorTileEnum,
    KundeKapital: 'KUNDE_KAPITAL' as SimulatorTileEnum,
    Investering: 'INVESTERING' as SimulatorTileEnum,
    PpOpsparingsplan: 'PP_OPSPARINGSPLAN' as SimulatorTileEnum,
    PpUdbetalingsplan: 'PP_UDBETALINGSPLAN' as SimulatorTileEnum,
    Forsikring: 'FORSIKRING' as SimulatorTileEnum,
    DigitalsalgTilbudoverblik: 'DIGITALSALG_TILBUDOVERBLIK' as SimulatorTileEnum
};

