/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { LanguageCode } from './languageCode';
import { LanguageCodeSchema } from './languageCode';
import { StatusDato } from './statusDato';
import { StatusDatoSchema } from './statusDato';
import { SpoergeskemaTypeName } from './spoergeskemaTypeName';
import { SpoergeskemaTypeNameSchema } from './spoergeskemaTypeName';
import * as JoiObj from "joi";


    export const AdminRowSchema = JoiObj.object({
        languageCode:
            
        JoiObj.link('#LanguageCodeSchema')


.required()


        ,
        navn:
            
        JoiObj.link('#SpoergeskemaTypeNameSchema')


.required()


        ,
        senestPaabegyndt:
            
            JoiObj.date()

.allow(null)



        ,
        statusDato:
            
        JoiObj.link('#StatusDatoSchema')


.required()


        ,
        uuid:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        version:
            
                    JoiObj.number()
                


.required()


        
    })
                .shared(LanguageCodeSchema)
                .shared(SpoergeskemaTypeNameSchema)
                .shared(StatusDatoSchema)
.id('AdminRowSchema')

export interface AdminRow { 
    languageCode: LanguageCode;
    navn: SpoergeskemaTypeName;
    senestPaabegyndt?: Date;
    statusDato: StatusDato;
    uuid: string;
    version: number;
}
export namespace AdminRow {
}


