/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const ProfilEnumSchema = JoiObj.string().valid(
        'A_U'
        ,
        'B_U'
        ,
        'A'
        ,
        'B'
        ,
        'C'
        ,
        'D'
        
    )
.id('ProfilEnumSchema')

export type ProfilEnum = 'A_U' | 'B_U' | 'A' | 'B' | 'C' | 'D';

export const ProfilEnum = {
    AU: 'A_U' as ProfilEnum,
    BU: 'B_U' as ProfilEnum,
    A: 'A' as ProfilEnum,
    B: 'B' as ProfilEnum,
    C: 'C' as ProfilEnum,
    D: 'D' as ProfilEnum
};

