/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ForsikringDaekningerUnderUdbetalingGruppe } from './forsikringDaekningerUnderUdbetalingGruppe';
import { ForsikringDaekningerUnderUdbetalingGruppeSchema } from './forsikringDaekningerUnderUdbetalingGruppe';
import { KritisksygdomForsikring } from './kritisksygdomForsikring';
import { KritisksygdomForsikringSchema } from './kritisksygdomForsikring';
import { ErhvervsevneForsikring } from './erhvervsevneForsikring';
import { ErhvervsevneForsikringSchema } from './erhvervsevneForsikring';
import { SundhedsForsikring } from './sundhedsForsikring';
import { SundhedsForsikringSchema } from './sundhedsForsikring';
import { HelbredsSikringModul } from './helbredsSikringModul';
import { HelbredsSikringModulSchema } from './helbredsSikringModul';
import { LivsForsikring } from './livsForsikring';
import { LivsForsikringSchema } from './livsForsikring';
import { ForebyggerForsikring } from './forebyggerForsikring';
import { ForebyggerForsikringSchema } from './forebyggerForsikring';
import * as JoiObj from "joi";


    export const ForsikringsoversigtSchema = JoiObj.object({
        fejledePolicenumre:
                JoiObj.array().items(
        
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

        )
        

.required()


        ,
        forebyggerForsikring:
            
        JoiObj.link('#ForebyggerForsikringSchema')


.required()


        ,
        sundhedsForsikring:
            
        JoiObj.link('#SundhedsForsikringSchema')


.required()


        ,
        kritisksygdomForsikring:
            
        JoiObj.link('#KritisksygdomForsikringSchema')


.required()


        ,
        erhvervsevneForsikring:
            
        JoiObj.link('#ErhvervsevneForsikringSchema')


.required()


        ,
        livsForsikring:
            
        JoiObj.link('#LivsForsikringSchema')


.required()


        ,
        krydslivsForsikring:
            
        JoiObj.link('#LivsForsikringSchema')


.required()


        ,
        pfaHelbredsForsikring:
            
        JoiObj.link('#SundhedsForsikringSchema')


.required()


        ,
        pfaDiagnoseForsikring:
            
        JoiObj.link('#SundhedsForsikringSchema')


.required()


        ,
        pfaHelbredsForsikringModuler:
                JoiObj.array().items(
        
        JoiObj.link('#HelbredsSikringModulSchema')

        )
        

.required()


        ,
        udbetalingDoedId:
                JoiObj.array().items(
        
                    JoiObj.number()
                

        )
        

.required()


        ,
        forsikringDaekningerUnderUdbetalingGruppe:
                JoiObj.array().items(
        
        JoiObj.link('#ForsikringDaekningerUnderUdbetalingGruppeSchema')

        )
        

.required()


        ,
        letpension:
            
        JoiObj.link('#ForsikringsoversigtSchema')

.allow(null)



        ,
        pureLetpensionCustomer:
            
                    JoiObj.boolean()
                


.required()


        ,
        dakningsoversigtDocTokens:
            
        JoiObj.object().pattern(/./,     
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)


)


.required()


        ,
        harForebygger:
            
                    JoiObj.boolean()
                


.required()


        ,
        harDiagnose:
            
                    JoiObj.boolean()
                


.required()


        ,
        harHelbred:
            
                    JoiObj.boolean()
                


.required()


        ,
        harEarlycare:
            
                    JoiObj.boolean()
                


.required()


        ,
        piDate:
            
            JoiObj.date()

.allow(null)



        ,
        hasGippDepositEnsurance:
            
                    JoiObj.boolean()
                


.required()


        ,
        hasOnlineDoctor:
            
                    JoiObj.boolean()
                


.required()


        
    })
                .shared(ForebyggerForsikringSchema)
                .shared(SundhedsForsikringSchema)
                .shared(KritisksygdomForsikringSchema)
                .shared(ErhvervsevneForsikringSchema)
                .shared(LivsForsikringSchema)
                .shared(LivsForsikringSchema)
                .shared(SundhedsForsikringSchema)
                .shared(SundhedsForsikringSchema)
                .shared(HelbredsSikringModulSchema)
                .shared(ForsikringDaekningerUnderUdbetalingGruppeSchema)
.id('ForsikringsoversigtSchema')

export interface Forsikringsoversigt { 
    fejledePolicenumre: Array<string>;
    forebyggerForsikring: ForebyggerForsikring;
    sundhedsForsikring: SundhedsForsikring;
    kritisksygdomForsikring: KritisksygdomForsikring;
    erhvervsevneForsikring: ErhvervsevneForsikring;
    livsForsikring: LivsForsikring;
    krydslivsForsikring: LivsForsikring;
    pfaHelbredsForsikring: SundhedsForsikring;
    pfaDiagnoseForsikring: SundhedsForsikring;
    pfaHelbredsForsikringModuler: Array<HelbredsSikringModul>;
    udbetalingDoedId: Array<number>;
    forsikringDaekningerUnderUdbetalingGruppe: Array<ForsikringDaekningerUnderUdbetalingGruppe>;
    letpension?: Forsikringsoversigt;
    pureLetpensionCustomer: boolean;
    dakningsoversigtDocTokens: { [key: string]: string; };
    harForebygger: boolean;
    harDiagnose: boolean;
    harHelbred: boolean;
    harEarlycare: boolean;
    piDate?: Date;
    hasGippDepositEnsurance: boolean;
    hasOnlineDoctor: boolean;
}

