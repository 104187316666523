/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ServiceInfo } from './serviceInfo';
import { ServiceInfoSchema } from './serviceInfo';
import { ResourceModel } from './resourceModel';
import { ResourceModelSchema } from './resourceModel';
import { AdvancedPayoutChoice } from './advancedPayoutChoice';
import { AdvancedPayoutChoiceSchema } from './advancedPayoutChoice';
import * as JoiObj from "joi";


    export const MinUdbetalingsplanSchema = JoiObj.object({
        serviceInfo:
            
        JoiObj.link('#ServiceInfoSchema')

.allow(null)



        ,
        advancedPayoutChoice:
            
        JoiObj.link('#AdvancedPayoutChoiceSchema')


.required()


        ,
        atp:
            
                    JoiObj.boolean()
                


.required()


        ,
        bolignedsparingsPeriode:
            
                    JoiObj.number()
                


.required()


        ,
        efterloen:
            
                    JoiObj.boolean()
                


.required()


        ,
        efterloenFraAlder:
            
                    JoiObj.number()
                


.required()


        ,
        efterloenTilAlder:
            
                    JoiObj.number()
                


.required()


        ,
        efterloensudbetaling:
            
                    JoiObj.number()
                


.required()


        ,
        ekstraordinaerIndtaegtBeloeb:
            
                    JoiObj.number()
                


.required()


        ,
        engangsbeloebUdbetalingsPeriode:
            
                    JoiObj.number()
                


.required()


        ,
        fillUpNeeds:
            
                    JoiObj.boolean()
                


.required()


        ,
        folkepension:
            
                    JoiObj.boolean()
                


.required()


        ,
        folkepensionalder:
            
                    JoiObj.number()
                


.required()


        ,
        formueNedsparingsPeriode:
            
                    JoiObj.number()
                


.required()


        ,
        partnerIndkomstLaast:
            
                    JoiObj.boolean()
                


.required()


        ,
        partnerIndkomstVedPensionering:
            
                    JoiObj.number()
                


.required()


        ,
        partnerIndkomstVises:
            
                    JoiObj.boolean()
                


.required()


        ,
        pensionAgeModified:
            
                    JoiObj.boolean()
                


.required()


        ,
        pensionNeed:
            
                    JoiObj.number()
                


.required()


        ,
        pensionsalder:
            
                    JoiObj.number()
                


.required()


        ,
        ratepensionUdbetalingsPeriode:
            
                    JoiObj.number()
                


.required()


        
    })
                .shared(ServiceInfoSchema)
                .shared(AdvancedPayoutChoiceSchema)
.id('MinUdbetalingsplanSchema')

export interface MinUdbetalingsplan extends ResourceModel { 
    advancedPayoutChoice: AdvancedPayoutChoice;
    atp: boolean;
    bolignedsparingsPeriode: number;
    efterloen: boolean;
    efterloenFraAlder: number;
    efterloenTilAlder: number;
    efterloensudbetaling: number;
    ekstraordinaerIndtaegtBeloeb: number;
    engangsbeloebUdbetalingsPeriode: number;
    fillUpNeeds: boolean;
    folkepension: boolean;
    folkepensionalder: number;
    formueNedsparingsPeriode: number;
    partnerIndkomstLaast: boolean;
    partnerIndkomstVedPensionering: number;
    partnerIndkomstVises: boolean;
    pensionAgeModified: boolean;
    pensionNeed: number;
    pensionsalder: number;
    ratepensionUdbetalingsPeriode: number;
}

