<div [ngSwitch]="daekning.efterviderefoerelse.type">
  <div
    class="riskincreased"
    *ngIf="daekning.efterviderefoerelse.risikoForhoejet"
  >
    <co-icon-error></co-icon-error>
    <span coContent="DL.TR01.C490"></span>
  </div>
  <div *ngSwitchCase="'BELOEB'">
    <div>
      {{ daekning.efterviderefoerelse.beloeb | numberFormat }}
      <span [coContent]="'Global.kroner'"></span>
    </div>
    <div
      *ngIf="daekning.efterviderefoerelse.skattekode === 'SK5'"
      class="text--note"
      [coContent]="'DL.FG01.C54'"
    ></div>
  </div>
  <div *ngSwitchCase="'PROCENT'">
    {{ daekning.efterviderefoerelse.procent | numberFormat }} %
  </div>
  <div *ngSwitchCase="'TJEK'"><co-icon-checkmark></co-icon-checkmark></div>
  <div *ngSwitchCase="'KRYDS'"><co-icon-times></co-icon-times></div>
</div>
