import {BehaviorSubject, Observable} from "rxjs";


export class RestCacheEntry<T> {
    public observable: Observable<T>;
        public subject: BehaviorSubject<0>;

        constructor(observable: Observable<T>, subject: BehaviorSubject<0>) {
            this.observable = observable;
            this.subject = subject;
        }
}
