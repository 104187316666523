/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const PrivatIndskudSignRequestObjSchema = JoiObj.object({
        amount:
            
                    JoiObj.number()
                


.required()


        ,
        language:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        policyId:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        quotationGUID:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        
    })
.id('PrivatIndskudSignRequestObjSchema')

export interface PrivatIndskudSignRequestObj { 
    amount: number;
    language: string;
    policyId: string;
    quotationGUID: string;
}

