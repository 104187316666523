/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AdvancedPayoutChoiceLivnetPolicyCoverage } from './advancedPayoutChoiceLivnetPolicyCoverage';
import { AdvancedPayoutChoiceLivnetPolicyCoverageSchema } from './advancedPayoutChoiceLivnetPolicyCoverage';
import { AdvancedPayoutWarnings } from './advancedPayoutWarnings';
import { AdvancedPayoutWarningsSchema } from './advancedPayoutWarnings';
import * as JoiObj from "joi";


    export const AdvancedPayoutChoiceLivnetPolicySchema = JoiObj.object({
        advancedPayoutChoicePolicyCoverage:
                JoiObj.array().items(
        
        JoiObj.link('#AdvancedPayoutChoiceLivnetPolicyCoverageSchema')

        )
        

.required()


        ,
        gipp:
            
                    JoiObj.boolean()
                


.required()


        ,
        lifelongLifePensionType:
            
                    JoiObj.boolean()
                


.required()


        ,
        policyId:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        securityForDependents:
            
                    JoiObj.boolean()
                


.required()


        ,
        spousesCpr:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        spousesPension:
            
                    JoiObj.boolean()
                


.required()


        ,
        depositEndDate:
            
            JoiObj.date()

.allow(null)



        ,
        startPayoutAllowed:
            
                    JoiObj.boolean()
                

.allow(null)



        ,
        regularPayment:
            
                    JoiObj.boolean()
                

.allow(null)



        ,
        advancedPayoutWarnings:
            
        JoiObj.link('#AdvancedPayoutWarningsSchema')


.required()


        
    })
                .shared(AdvancedPayoutChoiceLivnetPolicyCoverageSchema)
                .shared(AdvancedPayoutWarningsSchema)
.id('AdvancedPayoutChoiceLivnetPolicySchema')

export interface AdvancedPayoutChoiceLivnetPolicy { 
    advancedPayoutChoicePolicyCoverage: Array<AdvancedPayoutChoiceLivnetPolicyCoverage>;
    gipp: boolean;
    lifelongLifePensionType: boolean;
    policyId: string;
    securityForDependents: boolean;
    spousesCpr: string;
    spousesPension: boolean;
    depositEndDate?: Date;
    startPayoutAllowed?: boolean;
    regularPayment?: boolean;
    advancedPayoutWarnings: AdvancedPayoutWarnings;
}

