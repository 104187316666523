/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const QuestionReachedSchema = JoiObj.string().valid(
        'WELCOME'
        ,
        'INVOLVEMENT'
        ,
        'INVESTMENT_KNOWLEDGE'
        ,
        'TRADING_EXPERIENCE'
        ,
        'NUMBER_OF_TRADES'
        ,
        'RETRIEVE_PENSIONINFO'
        ,
        'OTHER_SAVINGS'
        ,
        'REAL_ESTATE'
        ,
        'SECONDARY_RESIDENCE'
        ,
        'HAS_DEBT'
        ,
        'ECONOMICAL_IMPACT'
        ,
        'RISK_WILINGNESS'
        ,
        'RISK_PROFILE'
        ,
        'LOSSABILITY'
        ,
        'CLIMATE_RELEVANCE'
        ,
        'CLIMATE_PRIORITY'
        ,
        'CLIMATE_WILINGNESS'
        ,
        'CLIMATE_EXTENDED_FOCUS'
        
    )
.id('QuestionReachedSchema')

export type QuestionReached = 'WELCOME' | 'INVOLVEMENT' | 'INVESTMENT_KNOWLEDGE' | 'TRADING_EXPERIENCE' | 'NUMBER_OF_TRADES' | 'RETRIEVE_PENSIONINFO' | 'OTHER_SAVINGS' | 'REAL_ESTATE' | 'SECONDARY_RESIDENCE' | 'HAS_DEBT' | 'ECONOMICAL_IMPACT' | 'RISK_WILINGNESS' | 'RISK_PROFILE' | 'LOSSABILITY' | 'CLIMATE_RELEVANCE' | 'CLIMATE_PRIORITY' | 'CLIMATE_WILINGNESS' | 'CLIMATE_EXTENDED_FOCUS';

export const QuestionReached = {
    Welcome: 'WELCOME' as QuestionReached,
    Involvement: 'INVOLVEMENT' as QuestionReached,
    InvestmentKnowledge: 'INVESTMENT_KNOWLEDGE' as QuestionReached,
    TradingExperience: 'TRADING_EXPERIENCE' as QuestionReached,
    NumberOfTrades: 'NUMBER_OF_TRADES' as QuestionReached,
    RetrievePensioninfo: 'RETRIEVE_PENSIONINFO' as QuestionReached,
    OtherSavings: 'OTHER_SAVINGS' as QuestionReached,
    RealEstate: 'REAL_ESTATE' as QuestionReached,
    SecondaryResidence: 'SECONDARY_RESIDENCE' as QuestionReached,
    HasDebt: 'HAS_DEBT' as QuestionReached,
    EconomicalImpact: 'ECONOMICAL_IMPACT' as QuestionReached,
    RiskWilingness: 'RISK_WILINGNESS' as QuestionReached,
    RiskProfile: 'RISK_PROFILE' as QuestionReached,
    Lossability: 'LOSSABILITY' as QuestionReached,
    ClimateRelevance: 'CLIMATE_RELEVANCE' as QuestionReached,
    ClimatePriority: 'CLIMATE_PRIORITY' as QuestionReached,
    ClimateWilingness: 'CLIMATE_WILINGNESS' as QuestionReached,
    ClimateExtendedFocus: 'CLIMATE_EXTENDED_FOCUS' as QuestionReached
};

