import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {
  IndbetalingOversigt,
  IndbetalingPolice,
  IndbetalingStore
} from '@pfa/gen';

@Injectable({
  providedIn: 'root'
})
export class DepositsApiService {
  constructor(
    private http: HttpClient,
    private indbetalingServiceStore: IndbetalingStore
  ) {}

  getDeposits(): Observable<IndbetalingOversigt> {
    return this.indbetalingServiceStore.indbetalingGet();
  }

  getDepositsPolicyYear(
    policy: string,
    year: string
  ): Observable<IndbetalingPolice> {
    const url = `/ds/api/indbetaling/${policy}/${year}`;
    return this.http.get<IndbetalingPolice>(url);
  }

  getDepositsPolicyFromDate(
    policy: string,
    fromDate: string
  ): Observable<IndbetalingPolice> {
    const url = `/ds/api/indbetaling/${policy}/from/${fromDate}`;
    return this.http.get<IndbetalingPolice>(url);
  }

  getDepositsFormOfSavings(): Observable<IndbetalingOversigt> {
    const url = '/ds/api/indbetaling/opsparingsform';
    return this.http.get<IndbetalingOversigt>(url);
  }
}
