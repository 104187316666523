import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export enum BroadcastMessageType {
  LANGUAGE_DA,
  LANGUAGE_EN,
  LOGGED_IN_SUCCESS,
  LOGGED_OUT_SUCCESS,
  PENSIONCUSTOMER_LOADED,
  CLEAR_CACHE,
  ADD_TO_BASKET,
  APPROVED_CHANGES,
  EXPIRATION_DATE_UPDATED,
  UNREAD_THREAD_MESSAGES,
  UNREAD_SERVICE_MESSAGES,
  VIEWED_SERVICE_MESSAGE,
  DATA_UPDATE_ANGULARJS,
  DELETE_FROM_BASKET,
  BENEFICIARY_DONE,
  HEALTH_CASE_ADDED_TO_BASKET
}

@Injectable({
  providedIn: 'root'
})
export class BroadcastService {
  private readonly broadcastSubject: Subject<BroadcastMessageType> =
    new Subject<BroadcastMessageType>();
  private readonly broadcastWithNumberSubject: Subject<{
    message: BroadcastMessageType;
    value: number;
  }> = new Subject<{ message: BroadcastMessageType; value: number }>();

  private readonly broadcastWithStringSubject: Subject<{
    message: BroadcastMessageType;
    value: string;
  }> = new Subject<{ message: BroadcastMessageType; value: string }>();

  broadcastMessage(message: BroadcastMessageType) {
    this.broadcastSubject.next(message);
  }

  broadcastMessageWithNumber(message: BroadcastMessageType, value: number) {
    this.broadcastWithNumberSubject.next({
      message,
      value
    });
  }

  broadcastMessageWithString(message: BroadcastMessageType, value: string) {
    this.broadcastWithStringSubject.next({
      message,
      value
    });
  }

  get BroadcastSubject(): Subject<BroadcastMessageType> {
    return this.broadcastSubject;
  }

  get BroadcastWithNumberSubject(): Subject<{
    message: BroadcastMessageType;
    value: number;
  }> {
    return this.broadcastWithNumberSubject;
  }

  get BroadcastWithStringSubject(): Subject<{
    message: BroadcastMessageType;
    value: string;
  }> {
    return this.broadcastWithStringSubject;
  }
}
