/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { FondeFordelingEnum } from './fondeFordelingEnum';
import { FondeFordelingEnumSchema } from './fondeFordelingEnum';
import * as JoiObj from "joi";


    export const AktivSammensaetningFordelingSchema = JoiObj.object({
        fond:
            
        JoiObj.link('#FondeFordelingEnumSchema')


.required()


        ,
        vaegt:
            
                    JoiObj.number()
                


.required()


        
    })
                .shared(FondeFordelingEnumSchema)
.id('AktivSammensaetningFordelingSchema')

export interface AktivSammensaetningFordeling { 
    fond: FondeFordelingEnum;
    vaegt: number;
}
export namespace AktivSammensaetningFordeling {
}


