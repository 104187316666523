/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ServiceInfo } from './serviceInfo';
import { ServiceInfoSchema } from './serviceInfo';
import { Aendringsdatatype } from './aendringsdatatype';
import { AendringsdatatypeSchema } from './aendringsdatatype';
import { ResourceModel } from './resourceModel';
import { ResourceModelSchema } from './resourceModel';
import { Anbefalingsomraade } from './anbefalingsomraade';
import { AnbefalingsomraadeSchema } from './anbefalingsomraade';
import * as JoiObj from "joi";


    export const AendringslinjeSchema = JoiObj.object({
        serviceInfo:
            
        JoiObj.link('#ServiceInfoSchema')

.allow(null)



        ,
        aendringsdatatype:
            
        JoiObj.link('#AendringsdatatypeSchema')


.required()


        ,
        anbefalingsomraade:
            
        JoiObj.link('#AnbefalingsomraadeSchema')


.required()


        ,
        nuvaerendeCTekst:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        nuvaerendeFletteParameter:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        oenskeCTekst:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        oenskeFletteParameter:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        skjulLinie:
            
                    JoiObj.boolean()
                


.required()


        
    })
                .shared(ServiceInfoSchema)
                .shared(AendringsdatatypeSchema)
                .shared(AnbefalingsomraadeSchema)
.id('AendringslinjeSchema')

export interface Aendringslinje extends ResourceModel { 
    aendringsdatatype: Aendringsdatatype;
    anbefalingsomraade: Anbefalingsomraade;
    nuvaerendeCTekst: string;
    nuvaerendeFletteParameter: string;
    oenskeCTekst: string;
    oenskeFletteParameter: string;
    skjulLinie: boolean;
}
export namespace Aendringslinje {
}


