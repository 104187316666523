/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const DigitalSalgTypeSchema = JoiObj.string().valid(
        'Nytegning'
        ,
        'VidereFoerelse'
        ,
        'Genoptag'
        
    )
.id('DigitalSalgTypeSchema')

export type DigitalSalgType = 'Nytegning' | 'VidereFoerelse' | 'Genoptag';

export const DigitalSalgType = {
    Nytegning: 'Nytegning' as DigitalSalgType,
    VidereFoerelse: 'VidereFoerelse' as DigitalSalgType,
    Genoptag: 'Genoptag' as DigitalSalgType
};

