import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input
} from '@angular/core';
import { MaeglerInfo } from '@pfa/gen';
import { FOOTER_NAVIGATION_TRACKING } from '../../footer.model';
import { PageTrackingService } from '@pfa/core';

@Component({
  selector: 'co-footer-contact-broker',
  templateUrl: './footer-contact-broker.component.html',
  styleUrls: ['./footer-contact-broker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterContactBrokerComponent {
  @Input() brokerInfo: MaeglerInfo;

  private readonly pageTrackingService: PageTrackingService =
    inject(PageTrackingService);

  phoneClicked(): void {
    this.pageTrackingService.trackNavigation(
      FOOTER_NAVIGATION_TRACKING.name,
      FOOTER_NAVIGATION_TRACKING.action.callBroker,
      ''
    );
  }

  writeClicked(): void {
    this.pageTrackingService.trackNavigation(
      FOOTER_NAVIGATION_TRACKING.name,
      FOOTER_NAVIGATION_TRACKING.action.writeBroker,
      ''
    );
  }

  contactClicked(): void {
    this.pageTrackingService.trackNavigation(
      FOOTER_NAVIGATION_TRACKING.name,
      FOOTER_NAVIGATION_TRACKING.action.contact,
      FOOTER_NAVIGATION_TRACKING.destination.contact
    );
  }
}
