import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MyCommonModule } from '@pfa/common';
import { CoreModule } from '@pfa/core';
import { PfaFormModule } from '@pfaform';
import { PincodeLoginComponent } from './pincode-login/pincode-login.component';
import { LogonComponent } from './logon.component';
import { LogonFooterComponent } from './logon-footer/logon-footer.component';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import { IconModule } from '@pfa/icons';
import { LoggedOutInactiveComponent } from './logged-out-inactive/logged-out-inactive.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MitIDLoginComponent } from './mitid-login/mitid-login.component';
import { MitidLoggedInComponent } from './mitid-logged-in.component';
import { MitidLegitimationComponent } from './mitid-legitimation.component';

@NgModule({
  imports: [
    CommonModule,
    MyCommonModule,
    CoreModule,
    IconModule,
    PfaFormModule,
    MatInputModule,
    MatButtonModule,
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    MatDialogModule
  ],
  declarations: [
    LogonComponent,
    LogonFooterComponent,
    PincodeLoginComponent,
    LoggedOutInactiveComponent,
    MitIDLoginComponent,
    MitidLoggedInComponent,
    MitidLegitimationComponent
  ],
  exports: [LogonFooterComponent]
})
export class LogonModule {}
