/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ServiceInfo } from './serviceInfo';
import { ServiceInfoSchema } from './serviceInfo';
import { LinieElement } from './linieElement';
import { LinieElementSchema } from './linieElement';
import { ResourceModel } from './resourceModel';
import { ResourceModelSchema } from './resourceModel';
import * as JoiObj from "joi";


    export const RenteSchema = JoiObj.object({
        serviceInfo:
            
        JoiObj.link('#ServiceInfoSchema')

.allow(null)



        ,
        kundekapitalAar:
            
                    JoiObj.number()
                


.required()


        ,
        linieElementer:
                JoiObj.array().items(
        
        JoiObj.link('#LinieElementSchema')

        )
        

.required()


        ,
        total:
            
                    JoiObj.number()
                


.required()


        
    })
                .shared(ServiceInfoSchema)
                .shared(LinieElementSchema)
.id('RenteSchema')

export interface Rente extends ResourceModel { 
    kundekapitalAar: number;
    linieElementer: Array<LinieElement>;
    total: number;
}

