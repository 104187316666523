/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const AktiefordelingSchema = JoiObj.object({
        a:
            
                    JoiObj.number()
                


.required()


        ,
        aar:
            
                    JoiObj.number()
                


.required()


        ,
        b:
            
                    JoiObj.number()
                


.required()


        ,
        c:
            
                    JoiObj.number()
                


.required()


        ,
        d:
            
                    JoiObj.number()
                


.required()


        
    })
.id('AktiefordelingSchema')

export interface Aktiefordeling { 
    a: number;
    aar: number;
    b: number;
    c: number;
    d: number;
}

