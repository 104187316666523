/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OversigtForsikring } from './oversigtForsikring';
import { OversigtForsikringSchema } from './oversigtForsikring';
import * as JoiObj from "joi";


    export const HelbredsSikringModulSchema = JoiObj.object({
        aftaleNummer:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        helbredsDaekninger:
                JoiObj.array().items(
        
        JoiObj.link('#OversigtForsikringSchema')

        )
        

.required()


        ,
        helbredsDaekningerBoern:
                JoiObj.array().items(
        
        JoiObj.link('#OversigtForsikringSchema')

        )
        

.required()


        ,
        skatteopdelt:
            
                    JoiObj.boolean()
                


.required()


        ,
        visHelbredSikring:
            
                    JoiObj.boolean()
                


.required()


        ,
        visKieropraktor:
            
                    JoiObj.boolean()
                


.required()


        ,
        visKieropraktorFritValg:
            
                    JoiObj.boolean()
                


.required()


        ,
        visMisbrug:
            
                    JoiObj.boolean()
                


.required()


        ,
        visOperation:
            
                    JoiObj.boolean()
                


.required()


        ,
        visPsykiater:
            
                    JoiObj.boolean()
                


.required()


        ,
        visPsykolog:
            
                    JoiObj.boolean()
                


.required()


        ,
        visUdredning:
            
                    JoiObj.boolean()
                


.required()


        
    })
                .shared(OversigtForsikringSchema)
                .shared(OversigtForsikringSchema)
.id('HelbredsSikringModulSchema')

export interface HelbredsSikringModul { 
    aftaleNummer?: string;
    helbredsDaekninger: Array<OversigtForsikring>;
    helbredsDaekningerBoern: Array<OversigtForsikring>;
    skatteopdelt: boolean;
    visHelbredSikring: boolean;
    visKieropraktor: boolean;
    visKieropraktorFritValg: boolean;
    visMisbrug: boolean;
    visOperation: boolean;
    visPsykiater: boolean;
    visPsykolog: boolean;
    visUdredning: boolean;
}

