/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AlderProcentBeloebSkatteKode } from './alderProcentBeloebSkatteKode';
import { AlderProcentBeloebSkatteKodeSchema } from './alderProcentBeloebSkatteKode';
import { PolicyOfferPolicyType } from './policyOfferPolicyType';
import { PolicyOfferPolicyTypeSchema } from './policyOfferPolicyType';
import { InvesteringProfil } from './investeringProfil';
import { InvesteringProfilSchema } from './investeringProfil';
import { InvesteringKilde } from './investeringKilde';
import { InvesteringKildeSchema } from './investeringKilde';
import { MinObligatoriskIndbetaling } from './minObligatoriskIndbetaling';
import { MinObligatoriskIndbetalingSchema } from './minObligatoriskIndbetaling';
import { IndbetalingSikring } from './indbetalingSikring';
import { IndbetalingSikringSchema } from './indbetalingSikring';
import { OpsparingFormFordeling } from './opsparingFormFordeling';
import { OpsparingFormFordelingSchema } from './opsparingFormFordeling';
import { Tilstand } from './tilstand';
import { TilstandSchema } from './tilstand';
import { CompanyOrPrivate } from './companyOrPrivate';
import { CompanyOrPrivateSchema } from './companyOrPrivate';
import { TAEPakkeTilkendelseskriterie } from './tAEPakkeTilkendelseskriterie';
import { TAEPakkeTilkendelseskriterieSchema } from './tAEPakkeTilkendelseskriterie';
import { DigitalSalgPrisListe } from './digitalSalgPrisListe';
import { DigitalSalgPrisListeSchema } from './digitalSalgPrisListe';
import { ViderefoerelseSammenligning } from './viderefoerelseSammenligning';
import { ViderefoerelseSammenligningSchema } from './viderefoerelseSammenligning';
import * as JoiObj from "joi";


    export const TilbudOverblikSchema = JoiObj.object({
        aftaltPensionsAlder:
            
                    JoiObj.number()
                


.required()


        ,
        aftaltPensionsDato:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        alder898:
            
                    JoiObj.number()
                


.required()


        ,
        alder899:
            
                    JoiObj.number()
                


.required()


        ,
        alderopsparing:
            
                    JoiObj.number()
                


.required()


        ,
        alderopsparingFast:
            
                    JoiObj.number()
                


.required()


        ,
        anbefaletAktiePct:
            
                    JoiObj.number()
                


.required()


        ,
        anbefaletLivPct:
            
                    JoiObj.number()
                


.required()


        ,
        anbefaletObligationPct:
            
                    JoiObj.number()
                


.required()


        ,
        anbefaletProfil:
            
        JoiObj.link('#InvesteringProfilSchema')


.required()


        ,
        anbefaletTaePct:
            
                    JoiObj.number()
                


.required()


        ,
        arbejdsgiverCvr:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        arbejdsgiverObligatoriskIndbetalingBeloeb:
            
                    JoiObj.number()
                


.required()


        ,
        arbejdsgiverObligatoriskIndbetalingPct:
            
                    JoiObj.number()
                


.required()


        ,
        boernepension:
                JoiObj.array().items(
        
        JoiObj.link('#AlderProcentBeloebSkatteKodeSchema')

        )
        

.required()


        ,
        companyOrPrivate:
            
        JoiObj.link('#CompanyOrPrivateSchema')


.required()


        ,
        digitalSalgPrisListe:
            
        JoiObj.link('#DigitalSalgPrisListeSchema')


.required()


        ,
        forsikringsGuideDato:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        forsikringsGuideTaget:
            
                    JoiObj.boolean()
                


.required()


        ,
        forsirkingDaekningIder:
                JoiObj.array().items(
        
                    JoiObj.number()
                

        )
        

.required()


        ,
        fripolice:
            
                    JoiObj.boolean()
                


.required()


        ,
        frivilligIndbetalingBeloeb:
            
                    JoiObj.number()
                


.required()


        ,
        frivilligIndbetalingPct:
            
                    JoiObj.number()
                


.required()


        ,
        harDaekning801:
            
                    JoiObj.boolean()
                


.required()


        ,
        harDaekning802:
            
                    JoiObj.boolean()
                


.required()


        ,
        harDaekning807:
            
                    JoiObj.boolean()
                


.required()


        ,
        harDaekning808:
            
                    JoiObj.boolean()
                


.required()


        ,
        harDaekning811:
            
                    JoiObj.boolean()
                


.required()


        ,
        harDaekning814:
            
                    JoiObj.boolean()
                


.required()


        ,
        harDaekning818:
            
                    JoiObj.boolean()
                


.required()


        ,
        harFlereProfil:
            
                    JoiObj.boolean()
                


.required()


        ,
        harHealthCase:
            
                    JoiObj.boolean()
                


.required()


        ,
        healthInsuranceProduct:
            
                    JoiObj.number()
                


.required()


        ,
        healthRequirementCode:
            
                    JoiObj.number()
                


.required()


        ,
        helbredssirking:
            
                    JoiObj.boolean()
                


.required()


        ,
        helbredssirkingBoerne:
            
                    JoiObj.boolean()
                


.required()


        ,
        helbredssirkingBoerneAlder:
            
                    JoiObj.number()
                


.required()


        ,
        henstand:
            
                    JoiObj.boolean()
                


.required()


        ,
        indbetalingSikring:
            
        JoiObj.link('#IndbetalingSikringSchema')


.required()


        ,
        indbetalingSikringMulige:
                JoiObj.array().items(
        
        JoiObj.link('#IndbetalingSikringSchema')

        )
        

.required()


        ,
        indbetalingTilOpsparing:
            
                    JoiObj.number()
                


.required()


        ,
        indbetalingssikringBeloeb:
            
                    JoiObj.number()
                


.required()


        ,
        indbetalingssikringOphoerAlder:
            
                    JoiObj.number()
                


.required()


        ,
        indregningFejl:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        investeringsguideDatoString:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        investeringsguideTaget:
            
                    JoiObj.boolean()
                


.required()


        ,
        klimaplusAndel:
            
                    JoiObj.number()
                


.required()


        ,
        kritisksygdom:
                JoiObj.array().items(
        
        JoiObj.link('#AlderProcentBeloebSkatteKodeSchema')

        )
        

.required()


        ,
        kritisksygdomBoerne:
                JoiObj.array().items(
        
        JoiObj.link('#AlderProcentBeloebSkatteKodeSchema')

        )
        

.required()


        ,
        kundekapitalValgt:
            
                    JoiObj.boolean()
                


.required()


        ,
        livEngangUdenRisikoOphoer:
                JoiObj.array().items(
        
        JoiObj.link('#AlderProcentBeloebSkatteKodeSchema')

        )
        

.required()


        ,
        livEnganger:
                JoiObj.array().items(
        
        JoiObj.link('#AlderProcentBeloebSkatteKodeSchema')

        )
        

.required()


        ,
        livForsikringstal:
            
                    JoiObj.number()
                


.required()


        ,
        livIkkeAnbefalet:
            
                    JoiObj.boolean()
                


.required()


        ,
        loen:
            
                    JoiObj.number()
                


.required()


        ,
        medarbejderObligatoriskIndbetalingBeloeb:
            
                    JoiObj.number()
                


.required()


        ,
        medarbejderObligatoriskIndbetalingPct:
            
                    JoiObj.number()
                


.required()


        ,
        minObligatoriskIndbetaling:
            
        JoiObj.link('#MinObligatoriskIndbetalingSchema')


.required()


        ,
        navn:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        opsparingFormFordeling:
            
        JoiObj.link('#OpsparingFormFordelingSchema')


.required()


        ,
        partialSchemeCompanyName:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        partialSchemeId:
            
                    JoiObj.number()
                


.required()


        ,
        partialSchemeType:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        pfaInvesterAnbefalet:
            
                    JoiObj.boolean()
                


.required()


        ,
        pfaValgfriHoejRisikoAndel:
            
                    JoiObj.number()
                


.required()


        ,
        pfaValgfriLavRisikoAndel:
            
                    JoiObj.number()
                


.required()


        ,
        pfaValgfriValgt:
            
                    JoiObj.boolean()
                


.required()


        ,
        pfadiagnose:
            
                    JoiObj.boolean()
                


.required()


        ,
        pfaearlycare:
            
                    JoiObj.boolean()
                


.required()


        ,
        pfaforebygger:
            
                    JoiObj.boolean()
                


.required()


        ,
        pfasundhedslinje:
            
                    JoiObj.boolean()
                


.required()


        ,
        policeNr:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        policeStatus:
            
        JoiObj.link('#TilstandSchema')


.required()


        ,
        policyType:
            
        JoiObj.link('#PolicyOfferPolicyTypeSchema')


.required()


        ,
        privateIndbetaling:
            
                    JoiObj.boolean()
                


.required()


        ,
        procentOrdning:
            
                    JoiObj.boolean()
                


.required()


        ,
        profilAlle:
            
        JoiObj.link('#InvesteringProfilSchema')


.required()


        ,
        profilFrie:
            
        JoiObj.link('#InvesteringProfilSchema')


.required()


        ,
        profilValgIkkeAnbefalet:
            
                    JoiObj.boolean()
                


.required()


        ,
        profileAlleKilder:
                JoiObj.array().items(
        
        JoiObj.link('#InvesteringKildeSchema')

        )
        

.required()


        ,
        profileFrieKilder:
                JoiObj.array().items(
        
        JoiObj.link('#InvesteringKildeSchema')

        )
        

.required()


        ,
        quotationGuid:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        ratepension:
            
                    JoiObj.number()
                


.required()


        ,
        ratepensionFast:
            
                    JoiObj.number()
                


.required()


        ,
        recommendedClimatePercent:
            
                    JoiObj.number()
                


.required()


        ,
        sieblingGroupName:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        skalPfaInvesterVises:
            
                    JoiObj.boolean()
                


.required()


        ,
        taeEnganger:
                JoiObj.array().items(
        
        JoiObj.link('#AlderProcentBeloebSkatteKodeSchema')

        )
        

.required()


        ,
        taeForsikringstal:
            
                    JoiObj.number()
                


.required()


        ,
        taeLoebendeIkkeAnbefalet:
            
                    JoiObj.boolean()
                


.required()


        ,
        taeLoebender:
                JoiObj.array().items(
        
        JoiObj.link('#AlderProcentBeloebSkatteKodeSchema')

        )
        

.required()


        ,
        taePakkeTilkendelseskriterie:
            
        JoiObj.link('#TAEPakkeTilkendelseskriterieSchema')


.required()


        ,
        tidligstIkafttraedelseDato:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        totalIndbetalingBeloeb:
            
                    JoiObj.number()
                


.required()


        ,
        totalIndbetalingPct:
            
                    JoiObj.number()
                


.required()


        ,
        udbetalingssikring:
            
                    JoiObj.boolean()
                


.required()


        ,
        variantCode:
            
                    JoiObj.number()
                


.required()


        ,
        viderefoerelseSammenligning:
            
        JoiObj.link('#ViderefoerelseSammenligningSchema')


.required()


        ,
        policyNumberReused:
            
                    JoiObj.boolean()
                


.required()


        ,
        policyNumberReserved:
            
                    JoiObj.number()
                

.allow(null)



        
    })
                .shared(InvesteringProfilSchema)
                .shared(AlderProcentBeloebSkatteKodeSchema)
                .shared(CompanyOrPrivateSchema)
                .shared(DigitalSalgPrisListeSchema)
                .shared(IndbetalingSikringSchema)
                .shared(IndbetalingSikringSchema)
                .shared(AlderProcentBeloebSkatteKodeSchema)
                .shared(AlderProcentBeloebSkatteKodeSchema)
                .shared(AlderProcentBeloebSkatteKodeSchema)
                .shared(AlderProcentBeloebSkatteKodeSchema)
                .shared(MinObligatoriskIndbetalingSchema)
                .shared(OpsparingFormFordelingSchema)
                .shared(TilstandSchema)
                .shared(PolicyOfferPolicyTypeSchema)
                .shared(InvesteringProfilSchema)
                .shared(InvesteringProfilSchema)
                .shared(InvesteringKildeSchema)
                .shared(InvesteringKildeSchema)
                .shared(AlderProcentBeloebSkatteKodeSchema)
                .shared(AlderProcentBeloebSkatteKodeSchema)
                .shared(TAEPakkeTilkendelseskriterieSchema)
                .shared(ViderefoerelseSammenligningSchema)
.id('TilbudOverblikSchema')

export interface TilbudOverblik { 
    aftaltPensionsAlder: number;
    aftaltPensionsDato: string;
    alder898: number;
    alder899: number;
    alderopsparing: number;
    alderopsparingFast: number;
    anbefaletAktiePct: number;
    anbefaletLivPct: number;
    anbefaletObligationPct: number;
    anbefaletProfil: InvesteringProfil;
    anbefaletTaePct: number;
    arbejdsgiverCvr: string;
    arbejdsgiverObligatoriskIndbetalingBeloeb: number;
    arbejdsgiverObligatoriskIndbetalingPct: number;
    boernepension: Array<AlderProcentBeloebSkatteKode>;
    companyOrPrivate: CompanyOrPrivate;
    digitalSalgPrisListe: DigitalSalgPrisListe;
    forsikringsGuideDato: string;
    forsikringsGuideTaget: boolean;
    forsirkingDaekningIder: Array<number>;
    fripolice: boolean;
    frivilligIndbetalingBeloeb: number;
    frivilligIndbetalingPct: number;
    harDaekning801: boolean;
    harDaekning802: boolean;
    harDaekning807: boolean;
    harDaekning808: boolean;
    harDaekning811: boolean;
    harDaekning814: boolean;
    harDaekning818: boolean;
    harFlereProfil: boolean;
    harHealthCase: boolean;
    healthInsuranceProduct: number;
    healthRequirementCode: number;
    helbredssirking: boolean;
    helbredssirkingBoerne: boolean;
    helbredssirkingBoerneAlder: number;
    henstand: boolean;
    indbetalingSikring: IndbetalingSikring;
    indbetalingSikringMulige: Array<IndbetalingSikring>;
    indbetalingTilOpsparing: number;
    indbetalingssikringBeloeb: number;
    indbetalingssikringOphoerAlder: number;
    indregningFejl: string;
    investeringsguideDatoString: string;
    investeringsguideTaget: boolean;
    klimaplusAndel: number;
    kritisksygdom: Array<AlderProcentBeloebSkatteKode>;
    kritisksygdomBoerne: Array<AlderProcentBeloebSkatteKode>;
    kundekapitalValgt: boolean;
    livEngangUdenRisikoOphoer: Array<AlderProcentBeloebSkatteKode>;
    livEnganger: Array<AlderProcentBeloebSkatteKode>;
    livForsikringstal: number;
    livIkkeAnbefalet: boolean;
    loen: number;
    medarbejderObligatoriskIndbetalingBeloeb: number;
    medarbejderObligatoriskIndbetalingPct: number;
    minObligatoriskIndbetaling: MinObligatoriskIndbetaling;
    navn: string;
    opsparingFormFordeling: OpsparingFormFordeling;
    partialSchemeCompanyName: string;
    partialSchemeId: number;
    partialSchemeType: string;
    pfaInvesterAnbefalet: boolean;
    pfaValgfriHoejRisikoAndel: number;
    pfaValgfriLavRisikoAndel: number;
    pfaValgfriValgt: boolean;
    pfadiagnose: boolean;
    pfaearlycare: boolean;
    pfaforebygger: boolean;
    pfasundhedslinje: boolean;
    policeNr: string;
    policeStatus: Tilstand;
    policyType: PolicyOfferPolicyType;
    privateIndbetaling: boolean;
    procentOrdning: boolean;
    profilAlle: InvesteringProfil;
    profilFrie: InvesteringProfil;
    profilValgIkkeAnbefalet: boolean;
    profileAlleKilder: Array<InvesteringKilde>;
    profileFrieKilder: Array<InvesteringKilde>;
    quotationGuid: string;
    ratepension: number;
    ratepensionFast: number;
    recommendedClimatePercent: number;
    sieblingGroupName: string;
    skalPfaInvesterVises: boolean;
    taeEnganger: Array<AlderProcentBeloebSkatteKode>;
    taeForsikringstal: number;
    taeLoebendeIkkeAnbefalet: boolean;
    taeLoebender: Array<AlderProcentBeloebSkatteKode>;
    taePakkeTilkendelseskriterie: TAEPakkeTilkendelseskriterie;
    tidligstIkafttraedelseDato: string;
    totalIndbetalingBeloeb: number;
    totalIndbetalingPct: number;
    udbetalingssikring: boolean;
    variantCode: number;
    viderefoerelseSammenligning: ViderefoerelseSammenligning;
    /**
     * True if existing policy is to be reused for this offer - see policyNumberReserved for policy number
     */
    policyNumberReused: boolean;
    /**
     * Number of existing policy being reused
     */
    policyNumberReserved?: number;
}
export namespace TilbudOverblik {
}


