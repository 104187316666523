import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MyCommonModule } from '@pfa/common';
import { CoreModule } from '@pfa/core';
import { PfaFormModule } from '@pfaform';
import { AdvisorMenuComponent } from './advisor-menu.component';
import { AdvisorMenuService } from './advisor-menu.service';
import { IconModule } from '@pfa/icons';
import { RouterModule } from '@angular/router';
import { AdvisorMenuProgressService } from './advisor-menu-progress.service';

@NgModule({
  imports: [
    CommonModule,
    MyCommonModule,
    CoreModule,
    PfaFormModule,
    IconModule,
    RouterModule
  ],
  declarations: [AdvisorMenuComponent],
  exports: [AdvisorMenuComponent],
  providers: [AdvisorMenuService, AdvisorMenuProgressService]
})
export class AdvisorMenuModule {}
