import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { CoreModule } from '@pfa/core';
import { PfaFormModule } from '@pfaform';
import { IconModule } from '@pfa/icons';
import { PensionInfoComponent } from './pension-info.component';
import { PensionInfoExternalTransfersComponent } from './pension-info-variants/pension-info-external-transfers/pension-info-external-transfers.component';
import { PensionInfoRetrieveInfoComponent } from './pension-info-variants/pension-info-retrieve-info/pension-info-retrieve-info.component';

@NgModule({
  imports: [
    CommonModule,
    PfaFormModule,
    CoreModule,
    IconModule,
    MatDialogModule,
    MatButtonModule
  ],
  declarations: [
    PensionInfoComponent,
    PensionInfoExternalTransfersComponent,
    PensionInfoRetrieveInfoComponent
  ],
  exports: [PensionInfoComponent]
})
export class PensionInfoModule {}
