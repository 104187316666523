/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ServiceInfo } from './serviceInfo';
import { ServiceInfoSchema } from './serviceInfo';
import { InvesteringProfil } from './investeringProfil';
import { InvesteringProfilSchema } from './investeringProfil';
import { Fordelinger } from './fordelinger';
import { FordelingerSchema } from './fordelinger';
import { ResourceModel } from './resourceModel';
import { ResourceModelSchema } from './resourceModel';
import { Kilde } from './kilde';
import { KildeSchema } from './kilde';
import * as JoiObj from "joi";


    export const ProfilSchema = JoiObj.object({
        serviceInfo:
            
        JoiObj.link('#ServiceInfoSchema')

.allow(null)



        ,
        anbefalet:
            
                    JoiObj.boolean()
                


.required()


        ,
        fordeling:
            
        JoiObj.link('#FordelingerSchema')


.required()


        ,
        id:
            
        JoiObj.link('#InvesteringProfilSchema')


.required()


        ,
        kilde:
            
        JoiObj.link('#KildeSchema')


.required()


        ,
        mulig:
            
                    JoiObj.boolean()
                


.required()


        ,
        udbetalingssikring:
            
                    JoiObj.boolean()
                


.required()


        ,
        udbetalingssikringMulig:
            
                    JoiObj.boolean()
                


.required()


        ,
        underIndfasningTilProfil:
            
                    JoiObj.boolean()
                


.required()


        ,
        valgt:
            
                    JoiObj.boolean()
                


.required()


        
    })
                .shared(ServiceInfoSchema)
                .shared(FordelingerSchema)
                .shared(InvesteringProfilSchema)
                .shared(KildeSchema)
.id('ProfilSchema')

export interface Profil extends ResourceModel { 
    anbefalet: boolean;
    fordeling: Fordelinger;
    id: InvesteringProfil;
    kilde: Kilde;
    mulig: boolean;
    udbetalingssikring: boolean;
    udbetalingssikringMulig: boolean;
    underIndfasningTilProfil: boolean;
    valgt: boolean;
}
export namespace Profil {
}


