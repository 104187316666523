import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import { CoreModule } from '@pfa/core';
import { PfaFormModule } from '@pfaform';
import { IconModule } from '@pfa/icons';
import { PictoBaloonComponent } from './climate-footprint-picto/picto-baloon/picto-baloon.component';
import { PictoCarComponent } from './climate-footprint-picto/picto-car/picto-car.component';
import { PictoFamilyComponent } from './climate-footprint-picto/picto-family/picto-family.component';
import { PictoPersonComponent } from './climate-footprint-picto/picto-person/picto-person.component';
import { ClimateFootprintComponent } from './climate-footprint.component';
import { ClimateFootprintDisclaimerComponent } from './climate-footprint-disclaimer/climate-footprint-disclaimer.component';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    CoreModule,
    MatButtonModule,
    MatDialogModule,
    PfaFormModule,
    IconModule
  ],
  declarations: [
    ClimateFootprintComponent,
    PictoBaloonComponent,
    PictoCarComponent,
    PictoFamilyComponent,
    PictoPersonComponent,
    ClimateFootprintDisclaimerComponent
  ],
  exports: [ClimateFootprintComponent]
})
export class ClimateFootprintModule {}
