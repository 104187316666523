import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { SaveLanguageModalComponent } from '@pfaform';
import { PopupStyle } from '@pfa/models';
import { ContentService } from '@pfa/core';
import { MatDialog } from '@angular/material/dialog';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'mitpfa-change-language',
  templateUrl: './change-language.component.html',
  styleUrls: ['./change-language.component.scss']
})
export class ChangeLanguageComponent implements OnInit {
  private readonly contentService: ContentService = inject(ContentService);

  private readonly dialog: MatDialog = inject(MatDialog);
  private readonly destroyRef: DestroyRef = inject(DestroyRef);

  public testId: string;

  ngOnInit(): void {
    this.setTestId();
  }

  private setTestId(): void {
    this.testId = this.isDanish()
      ? 'FO01-language-mobile-EN'
      : 'FO01-language--mobile-DA';
  }

  private isDanish(): boolean {
    return this.contentService.getLanguage() === 'da';
  }

  public changeLanguage() {
    const language = this.isDanish() ? 'en' : 'da';
    this.contentService.setLanguage(language);
    this.setTestId();
    this.contentService
      .loadLanguage()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(res => {
        if (res !== language.toUpperCase()) {
          this.dialog.open(SaveLanguageModalComponent, {
            disableClose: true,
            panelClass: PopupStyle.PopupSmall
          });
        }
      });
  }
}
