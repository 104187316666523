import { Injectable } from '@angular/core';
import { TrackingService } from './tracking.service';
import { CustomerSatisfaction, FoundAllYouWanted } from '@pfa/gen';

const surveyName = 'mitpfa satisfaction';

enum SurveyEventName {
  IMPRESSION = 'track-survey-impression',
  COMPLETE = 'track-survey-complete'
}

enum SurveyPopupName {
  TAKE_SURVEY = 'take survey',
  START_SURVEY = 'start survey'
}

export enum SurveyAction {
  INIT = '',
  ACCEPT = 'accept',
  DECLINE = 'decline',
  CLOSE = 'close',
  START = 'start',
  DELAY = 'delay'
}

@Injectable()
export class SurveyTrackingService extends TrackingService {
  public trackSurveyTake(surveyUuid: string, surveyAction: SurveyAction): void {
    this.trackSurveyImpression(
      surveyUuid,
      SurveyPopupName.TAKE_SURVEY,
      surveyAction
    );
  }

  public trackSurvey(surveyUuid: string, surveyAction: SurveyAction): void {
    this.trackSurveyImpression(
      surveyUuid,
      SurveyPopupName.START_SURVEY,
      surveyAction
    );
  }

  private trackSurveyImpression(
    surveyUuid: string,
    surveyPopupName: SurveyPopupName,
    surveyAction: SurveyAction
  ): void {
    this.dispatchCustomEventTracking(SurveyEventName.IMPRESSION, {
      surveyName,
      surveyId: surveyUuid,
      surveyPopupName,
      surveyAction
    });
  }

  public trackSurveyAnswers(
    surveyUuid: string,
    customerSatisfaction: CustomerSatisfaction
  ): void {
    this.dispatchCustomEventTracking(SurveyEventName.COMPLETE, {
      surveyName,
      surveyId: surveyUuid,
      surveyResponseNavigation: customerSatisfaction.navigation,
      surveyResponseUnderstanding: customerSatisfaction.understandable,
      surveyResponseInviting: customerSatisfaction.design,
      surveyResponseError: customerSatisfaction.smoothness,
      surveyResponseSatisfaction: customerSatisfaction.satisfactionLevel,
      surveyResponsePurpose: this.getPurposeAnswer(
        customerSatisfaction.purposeForVisitFulfilled
      ),
      surveyResponseInformation: this.getInformationAnswer(
        customerSatisfaction.foundAllYouWanted
      ),
      surveyResponseMissingInformation: this.getMissingInformationAnswer(
        customerSatisfaction.missingInfo,
        customerSatisfaction.foundAllYouWanted
      ),
      surveyResponseImprovement: this.getImprovementAnswer(
        customerSatisfaction.suggestionForImprovement
      )
    });
  }

  private getPurposeAnswer(purposeForVisitFulfilled: boolean): string {
    return purposeForVisitFulfilled ? 'yes' : 'no';
  }

  private getInformationAnswer(foundAllYouWanted: FoundAllYouWanted): string {
    switch (foundAllYouWanted) {
      case 'YES':
        return 'yes';
      case 'PARTIALLY':
        return 'some';
      case 'NO':
        return 'no';
      case 'DIDNT_SEARCH_ANYTHING_SPECIFIC':
        return 'no search';
      default:
        return '';
    }
  }

  private getMissingInformationAnswer(
    missingInfo: string,
    foundAllYouWanted: FoundAllYouWanted
  ): string {
    if (
      foundAllYouWanted === 'YES' ||
      foundAllYouWanted === 'DIDNT_SEARCH_ANYTHING_SPECIFIC'
    ) {
      return '';
    } else {
      return missingInfo ? 'true' : 'false';
    }
  }

  private getImprovementAnswer(suggestionForImprovement: string): string {
    return suggestionForImprovement ? 'true' : 'false';
  }
}
