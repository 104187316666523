/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const PersonStatusSchema = JoiObj.string().valid(
        'Customer'
        ,
        'TerminatedCustomer'
        ,
        'NonCustomer'
        ,
        'Prospect'
        
    )
.id('PersonStatusSchema')

export type PersonStatus = 'Customer' | 'TerminatedCustomer' | 'NonCustomer' | 'Prospect';

export const PersonStatus = {
    Customer: 'Customer' as PersonStatus,
    TerminatedCustomer: 'TerminatedCustomer' as PersonStatus,
    NonCustomer: 'NonCustomer' as PersonStatus,
    Prospect: 'Prospect' as PersonStatus
};

