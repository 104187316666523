<div
  [routerLink]="uri"
  (click)="tracking()"
  class="topic--wrapper"
  [ngClass]="{
    'your--information--topic': progressPercent$
  }"
>
  <span
    *ngIf="progressPercent$ | async as percent"
    class="topic--item percent"
    [coContent]="'DL.DR01.C27'"
    [coContentSubst]="percent"
  ></span>
  <div *ngIf="severity !== undefined" class="topic--item">
    <div
      class="topic--status"
      [ngClass]="{
        'color--grey': severity === 0,
        'color--green': severity === 1,
        'color--yellow': severity === 2,
        'color--red': severity === 3
      }"
    ></div>
  </div>
  <span class="topic--item" [coContent]="topicText"></span>
  <div class="topic--item right">
    <span [coContent]="action"></span>
    <co-icon-arrow-right></co-icon-arrow-right>
  </div>
</div>
