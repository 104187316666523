/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ServiceInfo } from './serviceInfo';
import { ServiceInfoSchema } from './serviceInfo';
import { EnhedType } from './enhedType';
import { EnhedTypeSchema } from './enhedType';
import { ResourceModel } from './resourceModel';
import { ResourceModelSchema } from './resourceModel';
import * as JoiObj from "joi";


    export const PensionInPFASchema = JoiObj.object({
        serviceInfo:
            
        JoiObj.link('#ServiceInfoSchema')

.allow(null)



        ,
        annualSalaryBeforeTax:
            
                    JoiObj.number()
                


.required()


        ,
        employerMandatoryPayment:
            
                    JoiObj.number()
                


.required()


        ,
        gipp:
            
                    JoiObj.boolean()
                


.required()


        ,
        mandatoryPaymentUnitType:
            
        JoiObj.link('#EnhedTypeSchema')


.required()


        ,
        pensionSavings:
            
                    JoiObj.number()
                


.required()


        ,
        yourMandatoryPayment:
            
                    JoiObj.number()
                


.required()


        
    })
                .shared(ServiceInfoSchema)
                .shared(EnhedTypeSchema)
.id('PensionInPFASchema')

export interface PensionInPFA extends ResourceModel { 
    annualSalaryBeforeTax: number;
    employerMandatoryPayment: number;
    gipp: boolean;
    mandatoryPaymentUnitType: EnhedType;
    pensionSavings: number;
    yourMandatoryPayment: number;
}
export namespace PensionInPFA {
}


