<div class="align--center spacing-unit--half-over">
  <button
    mat-raised-button
    class="button-primary--level3 with-icon icon__on-primary--stroke"
    (click)="back.emit()"
  >
    <co-icon-arrow-left></co-icon-arrow-left>
    <span [coContent]="'DL.EO01.C92'"></span>
  </button>
</div>
