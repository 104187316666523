/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const KundeLegitimationStatusSchema = JoiObj.string().valid(
        'KundeLegitimationIkkeKraevet'
        ,
        'KundeEnkeltLegitimationKraevet'
        ,
        'KundeDobbeltLegitimationKraevet'
        
    )
.id('KundeLegitimationStatusSchema')

export type KundeLegitimationStatus = 'KundeLegitimationIkkeKraevet' | 'KundeEnkeltLegitimationKraevet' | 'KundeDobbeltLegitimationKraevet';

export const KundeLegitimationStatus = {
    KundeLegitimationIkkeKraevet: 'KundeLegitimationIkkeKraevet' as KundeLegitimationStatus,
    KundeEnkeltLegitimationKraevet: 'KundeEnkeltLegitimationKraevet' as KundeLegitimationStatus,
    KundeDobbeltLegitimationKraevet: 'KundeDobbeltLegitimationKraevet' as KundeLegitimationStatus
};

