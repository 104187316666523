import { Component, Input } from '@angular/core';
import { CoverageDetail } from './insurance-details-coverages-detail.model';

@Component({
  selector: 'co-insurance-details-coverages-detail',
  templateUrl: './insurance-details-coverages-detail.component.html',
  styleUrls: ['./insurance-details-coverages-detail.component.scss']
})
export class InsuranceDetailsCoveragesDetailComponent {
  @Input() coverages: CoverageDetail;
  @Input() noAccordion: boolean;
  @Input() noAccordionShadow: boolean;
  @Input() noHeader: boolean;
  @Input() initExpanded = true;
}
