<h4 [coContent]="headerText" *ngIf="!!headerText"></h4>
<div class="body--text" [coContent]="bodyText" *ngIf="!!bodyText"></div>

<mat-progress-bar
  class="progress-bar"
  [mode]="mode"
  value="{{ progressValue }}"
  color="accent"
></mat-progress-bar>

<ng-content></ng-content>
