import { InsuranceApiService } from '@pfa/api';
import {
  ConfirmChoiceFollowingRecommendation,
  ConfirmChoiceStatus,
  ConfirmChoiceTrackingItem,
  ConfirmChoiceType,
  ContentUtilService,
  MiscTrackingService,
  TrackingCategory,
  TrackingName
} from '@pfa/core';
import {
  InForceSelection,
  InForceSelectionForsikring,
  OekonomiDetaljer,
  PensionsKundeGenerelleData
} from '@pfa/gen';
import {
  ConfirmChoice,
  InputConfigCi,
  InputConfigLife,
  InputConfigLoc,
  InsuranceAdjustmentState
} from '@mitpfa/shared';

export class InsuranceAdjustmentConfirmChoiceService {
  constructor(
    private readonly miscTrackingService: MiscTrackingService,
    private readonly contentUtilService: ContentUtilService,
    private readonly insuranceApiService: InsuranceApiService,
    private readonly insuranceState: InsuranceAdjustmentState,
    private readonly locInputConfig: InputConfigLoc,
    private readonly ciInputConfig: InputConfigCi,
    private readonly lifeInputConfig: InputConfigLife,
    private readonly confirmChoice: ConfirmChoice,
    private readonly isOnboarding: boolean
  ) {}

  confirmChoiceForType(
    type: string,
    trackingName: TrackingName,
    pensionCustomer: PensionsKundeGenerelleData
  ): void {
    const inForceSelections = this.confirmData(type);
    this.logChoice(type, inForceSelections, trackingName, pensionCustomer);
  }

  private confirmData(type: string): InForceSelectionForsikring[] {
    const inForceSelections: InForceSelectionForsikring[] = [];
    switch (type) {
      case 'LossOfOccupationalCapacity':
        inForceSelections.push({
          inForceSelectionType: 'LossOfOccupationalCapacity',
          passiveAcceptance: false,
          selection: this.locInputConfig.regularPayout.amount,
          defaultSelection: 80,
          recommendation:
            this.insuranceState.insurance.originalTilpasForsikringstal
              .pfaAnbefalerTAE
        });
        break;
      case 'LifeInsurance':
        inForceSelections.push({
          inForceSelectionType: 'LifeInsurance',
          passiveAcceptance: false,
          selection: this.lifeInputConfig.self.amount,
          defaultSelection:
            this.insuranceState.insurance.originalTilpasForsikringstal
              .currentForsikringstalLiv,
          recommendation:
            this.insuranceState.insurance.originalTilpasForsikringstal
              .pfaAnbefalerLiv
        });
        break;
      case 'CriticalIllness':
        inForceSelections.push({
          inForceSelectionType: 'CriticalIllness',
          passiveAcceptance: false,
          selection: this.ciInputConfig.self.amount,
          defaultSelection:
            this.insuranceState.insurance.originalTilpasForsikringstal
              .daekningAendringKritiskSygdomSelv.daekningsBeloeb,
          recommendation: this.recommendationCi()
        });
        break;
    }
    return inForceSelections;
  }

  private logChoice(
    type: string,
    inForceSelections: InForceSelectionForsikring[],
    trackingName: TrackingName,
    pensionCustomer: PensionsKundeGenerelleData
  ): void {
    this.clearConfirmErrors();
    this.logConfirmation(
      inForceSelections,
      type,
      trackingName,
      pensionCustomer
    );
  }

  private clearConfirmErrors(): void {
    this.confirmChoice.loc.error = false;
    this.confirmChoice.ci.error = false;
    this.confirmChoice.life.error = false;
  }

  private logConfirmation(
    inForceSelections: InForceSelectionForsikring[],
    type: string,
    trackingName: TrackingName,
    pensionCustomer: PensionsKundeGenerelleData
  ): void {
    this.insuranceApiService
      .logProfileSelection(inForceSelections, false)
      .subscribe({
        next: res => {
          if (res.success) {
            const inforce: InForceSelection =
              pensionCustomer.inForceSelections.find(
                i => i.inForceSelectionType === type
              );

            if (type === 'LossOfOccupationalCapacity') {
              this.trackLOOCConfirmChoice(
                trackingName,
                inforce,
                inForceSelections
              );
            }
            if (type === 'LifeInsurance') {
              this.trackLIConfirmChoice(
                trackingName,
                inforce,
                inForceSelections
              );
            }
            if (type === 'CriticalIllness') {
              this.trackCIConfirmChoice(
                trackingName,
                inforce,
                inForceSelections
              );
            }
            this.setChoiceSuccess(type);
          } else {
            this.setChoiceError(type);
          }
        },
        error: () => {
          this.setChoiceError(type);
        }
      });
  }

  private trackLOOCConfirmChoice(
    trackingName: TrackingName,
    inforce: InForceSelection,
    inForceSelections: InForceSelectionForsikring[]
  ) {
    this.confirmChoice.loc.confirmedDate = new Date().getTime();

    this.trackConfirmChoice(
      trackingName,
      TrackingCategory.LOSS_OF_OCCUPATIONAL_CAPACITY,
      this.followingRecommendation(
        inForceSelections[0].recommendation ?? 0,
        inForceSelections[0].selection ?? 0,
        5
      ),
      inforce?.timestamp ? ConfirmChoiceType.RECONFIRM : ConfirmChoiceType.FIRST
    );
  }

  private trackLIConfirmChoice(
    trackingName: TrackingName,
    inforce: InForceSelection,
    inForceSelections: InForceSelectionForsikring[]
  ) {
    this.confirmChoice.life.confirmedDate = new Date().getTime();
    this.trackConfirmChoice(
      trackingName,
      TrackingCategory.LIFE,
      this.followingRecommendation(
        inForceSelections[0].recommendation ?? 0,
        inForceSelections[0].selection ?? 0,
        50
      ),
      inforce?.timestamp ? ConfirmChoiceType.RECONFIRM : ConfirmChoiceType.FIRST
    );
  }

  private trackCIConfirmChoice(
    trackingName: TrackingName,
    inforce: InForceSelection,
    inForceSelections: InForceSelectionForsikring[]
  ): void {
    this.confirmChoice.ci.confirmedDate = new Date().getTime();
    this.trackConfirmChoice(
      trackingName,
      TrackingCategory.CRITICAL_ILLNESS,
      inForceSelections[0].recommendation === inForceSelections[0].selection,
      inforce?.timestamp ? ConfirmChoiceType.RECONFIRM : ConfirmChoiceType.FIRST
    );
  }

  private followingRecommendation(
    recommendation: number,
    actual: number,
    variation: number
  ): boolean {
    const diff = Math.abs(recommendation - actual);
    return diff <= variation;
  }

  trackConfirmChoiceAllStates(
    confirmChoice: ConfirmChoice,
    policyBlocked: boolean
  ): void {
    const confirmChoiceTrackingItems = new Array<ConfirmChoiceTrackingItem>();

    const inForceSelectionsLoc = this.confirmData('LossOfOccupationalCapacity');
    const inForceSelectionsLife = this.confirmData('LifeInsurance');
    const inForceSelectionsCi = this.confirmData('CriticalIllness');

    let itemStatusLoc: ConfirmChoiceStatus = ConfirmChoiceStatus.TAKE_GUIDE;
    if (confirmChoice.loc.confirmedDate) {
      itemStatusLoc = ConfirmChoiceStatus.CONFIRMED;
    } else if (confirmChoice.loc.confirmPossible) {
      itemStatusLoc = ConfirmChoiceStatus.WAITING;
    } else if (policyBlocked) {
      itemStatusLoc = ConfirmChoiceStatus.DISABLED;
    }
    confirmChoiceTrackingItems.push({
      itemRecommendedName: this.asTrackingFollowingRecommendation(
        this.followingRecommendation(
          inForceSelectionsLoc[0].recommendation ?? 0,
          inForceSelectionsLoc[0].selection ?? 0,
          5
        )
      ),
      itemConfirmName: TrackingCategory.LOSS_OF_OCCUPATIONAL_CAPACITY,
      itemStatus: itemStatusLoc
    });

    let itemStatusLife: ConfirmChoiceStatus = ConfirmChoiceStatus.TAKE_GUIDE;
    if (confirmChoice.life.confirmedDate) {
      itemStatusLife = ConfirmChoiceStatus.CONFIRMED;
    } else if (confirmChoice.life.confirmPossible) {
      itemStatusLife = ConfirmChoiceStatus.WAITING;
    } else if (policyBlocked) {
      itemStatusLife = ConfirmChoiceStatus.DISABLED;
    }
    confirmChoiceTrackingItems.push({
      itemRecommendedName: this.asTrackingFollowingRecommendation(
        this.followingRecommendation(
          inForceSelectionsLife[0].recommendation ?? 0,
          inForceSelectionsLife[0].selection ?? 0,
          50
        )
      ),
      itemConfirmName: TrackingCategory.LIFE,
      itemStatus: itemStatusLife
    });

    let itemStatusCi: ConfirmChoiceStatus = ConfirmChoiceStatus.TAKE_GUIDE;
    if (confirmChoice.ci.confirmedDate) {
      itemStatusCi = ConfirmChoiceStatus.CONFIRMED;
    } else if (confirmChoice.ci.confirmPossible) {
      itemStatusCi = ConfirmChoiceStatus.WAITING;
    } else if (policyBlocked) {
      itemStatusCi = ConfirmChoiceStatus.DISABLED;
    }
    confirmChoiceTrackingItems.push({
      itemRecommendedName: this.asTrackingFollowingRecommendation(
        inForceSelectionsCi[0].recommendation ===
          inForceSelectionsCi[0].selection
      ),
      itemConfirmName: TrackingCategory.CRITICAL_ILLNESS,
      itemStatus: itemStatusCi
    });

    this.miscTrackingService.trackConfirmChoiceStatus(
      this.isOnboarding
        ? TrackingName.INSURANCE_ONBOARDING
        : TrackingName.INSURANCE,
      confirmChoiceTrackingItems
    );
  }

  private trackConfirmChoice(
    trackingName: TrackingName,
    trackingCategory: TrackingCategory,
    followingRecommendation: boolean,
    confirmType: ConfirmChoiceType
  ): void {
    this.miscTrackingService.trackConfirmChoiceClick(trackingName, [
      {
        itemRecommendedName: this.asTrackingFollowingRecommendation(
          followingRecommendation
        ),
        itemConfirmName: trackingCategory,
        itemConfirmType: confirmType
      }
    ]);
  }

  private asTrackingFollowingRecommendation(
    value: boolean
  ): ConfirmChoiceFollowingRecommendation {
    return value
      ? ConfirmChoiceFollowingRecommendation.FOLLOWS
      : ConfirmChoiceFollowingRecommendation.DEVIATES;
  }

  private setChoiceError(type: string): void {
    if (type === 'LossOfOccupationalCapacity') {
      this.confirmChoice.loc.error = true;
    }
    if (type === 'LifeInsurance') {
      this.confirmChoice.life.error = true;
    }
    if (type === 'CriticalIllness') {
      this.confirmChoice.ci.error = true;
    }
  }

  private setChoiceSuccess(type: string): void {
    if (type === 'LossOfOccupationalCapacity') {
      this.confirmChoice.loc.success = true;
    }
    if (type === 'LifeInsurance') {
      this.confirmChoice.life.success = true;
    }
    if (type === 'CriticalIllness') {
      this.confirmChoice.ci.success = true;
    }
  }

  private recommendationCi(): number {
    let recommendation;
    if (
      this.pay(this.insuranceState.financialSituation) <
      this.insuranceState.taxData.topskatGraense
    ) {
      recommendation = this.contentUtilService.getContent('DL.FG01.C1421');
    } else {
      recommendation = this.contentUtilService.getContent('DL.FG01.C1420');
    }
    return parseFloat(recommendation.replace('.', ''));
  }

  private pay(financialSituation: OekonomiDetaljer): number {
    if (financialSituation.beregneAndenLoen > 0) {
      return financialSituation.beregneAndenLoen;
    } else {
      return financialSituation.registreretLoen;
    }
  }
}
