<mat-dialog-content>
  <strong [coContent]="header" *ngIf="header"></strong>
  <p [coContent]="body1" *ngIf="body1"></p>
  <p [coContent]="body2" *ngIf="body2"></p>
  <p [coContent]="body3" *ngIf="body3"></p>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="modal-buttons">
    <button
      *ngIf="!finishButton"
      mat-raised-button
      class="capitalize"
      (click)="accept()"
      [coContent]="'Global.ja'"
    ></button>
    <button
      *ngIf="!finishButton"
      mat-raised-button
      class="capitalize"
      (click)="cancel()"
      [coContent]="'Global.nej'"
    ></button>
    <button
      *ngIf="finishButton"
      mat-raised-button
      class="capitalize"
      (click)="finish()"
      [coContent]="'Global.Afslut'"
    ></button>
  </div>
</mat-dialog-actions>
