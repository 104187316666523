import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { BasketApiService, BusinessDomain } from '@pfa/api';
import { Observable } from 'rxjs';
import { BestiltAendringer } from '@pfa/gen';

@Injectable({
  providedIn: 'root'
})
export class InvestmentBasketResolver implements Resolve<BestiltAendringer> {
  constructor(private basketApiService: BasketApiService) {}

  resolve(
    route: ActivatedRouteSnapshot
  ): BestiltAendringer | Observable<BestiltAendringer> {
    const policyId = route.paramMap.get('policyId');
    return this.basketApiService.get(BusinessDomain.Investment, policyId);
  }
}
