/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const OptionSchema = JoiObj.object({
        id:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        introText:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        key:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        title:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        type:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        
    })
.id('OptionSchema')

export interface Option { 
    id: string;
    introText?: string;
    key?: string;
    title: string;
    type: string;
}

