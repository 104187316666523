import { PensionsKundeGenerelleData } from '@pfa/gen';
import { Component, OnInit, Input, ElementRef, inject } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import * as moment from 'moment';
@Component({
  selector: 'mitpfa-family-partner-info',
  templateUrl: './partner-info.component.html',
  styleUrls: ['./partner-info.component.scss']
})
export class PartnerInfoComponent implements OnInit {
  private readonly elementRef: ElementRef = inject(ElementRef);

  @Input() partnerInfoForm: UntypedFormGroup;
  @Input() pensionCustomer: PensionsKundeGenerelleData;
  @Input() hasSpouse: boolean;

  public minDate: Date;
  public currentDate = moment.utc().toDate();

  public ngOnInit(): void {
    const minYear = moment.utc().subtract(99, 'years');
    this.minDate = minYear.startOf('year').toDate();
  }

  public datepickerHover(hover: boolean): void {
    const element = this.elementRef.nativeElement.querySelector(
      '.datepicker-wrap mat-form-field'
    );

    if (hover) {
      element.classList.add('mat-focused');
    } else {
      element.classList.remove('mat-focused');
    }
  }
}
