import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { globalCacheBusterNotifier } from 'ts-cacheable';

/**
 * Interceptor that will bust the cache when any changes are made
 */
@Injectable()
export class CacheBusterInterceptorService implements HttpInterceptor {
  intercept(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    request: HttpRequest<any>,
    next: HttpHandler
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(() => {
        const bustCacheMethods = ['POST', 'PUT', 'DELETE'];
        if (bustCacheMethods.includes(request.method)) {
          globalCacheBusterNotifier.next();
        }
      })
    );
  }
}
