import { Component, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MitIDConst } from './logon.model';
import { LoginService } from '@mitpfa/shared';
import { PensionskundeStore } from '@pfa/gen';

@Component({
  selector: 'mitpfa-mitid-logged-in',
  template: '<co-loading [show]="true" class="absolute-center"></co-loading>'
})
export class MitidLoggedInComponent {
  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly loginService: LoginService,
    private readonly pensionskundeStore: PensionskundeStore
  ) {
    // example: /mitid-logged-in?returnUrl=%2Fmitoverblik%3Flang%3Den
    // results in returnUrl: "/mitoverblik?lang=en"
    const returnURLString = this.activatedRoute.snapshot.queryParams.returnUrl;
    const { returnURL, returnQueryParams } =
      this.parseReturnURLString(returnURLString);
    this.loginService.setMitIdLogin();
    this.pensionskundeStore.invalidatepensionskundeIndex();
    if (
      returnURLString &&
      returnURLString.includes(MitIDConst.CloseTabAndReturnToCallerWindow)
    ) {
      window.opener.postMessage(
        {
          action: MitIDConst.RefreshPensionsInfoPageAction
        },
        '*'
      );
      // focus pensionsinfo page
      // https://www.devasking.com/issue/windowopenerfocus-doesnt-work , Answer by Jessica Randolph
      window.open('', window.opener.name);
      window.opener.focus();
    } else {
      this.router.navigate([returnURL.pathname], {
        queryParams: returnQueryParams,
        state: { [LoginService.InitialLoginRouteState]: true }
      });
    }
  }

  @HostListener('window:message', ['$event'])
  receiveMessage(event) {
    const key = event.message ? 'message' : 'data';
    const data = event[key];
    // close window after when confirmed that main MitPFA window received massage and refreshed button
    if (data.action === MitIDConst.CloseReAuthWindowAction) {
      window.close();
    }
  }

  private parseReturnURLString(returnURLString: string): {
    returnURL: URL;
    returnQueryParams: {};
  } {
    const returnURL = new URL(
      returnURLString || 'mitoverblik',
      window.location.origin
    );
    const returnQueryParams = {};
    returnURL.searchParams.forEach(
      (value, key) => (returnQueryParams[key] = value)
    );

    return { returnURL, returnQueryParams };
  }
}
