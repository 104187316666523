import { Component, Input } from '@angular/core';

@Component({
  selector: 'mitpfa-savings-policy-details-simple',
  templateUrl: './savings-policy-details-simple.component.html'
})
export class SavingsPolicyDetailsSimpleComponent {
  @Input() headerContentId: string;
  @Input() headerSubst: string;
  @Input() tooltipContentId: string;
  @Input() total: number;
  @Input() resultLine: boolean;
}
