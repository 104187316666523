import { Component, ComponentRef, Input } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { CheckboxGroupContentComponent } from '@pfaform';

@Component({
  templateUrl: './label-input.component.html',
  styleUrls: ['./label-input.component.scss']
})
export class LabelInputComponent
  implements CheckboxGroupContentComponent<LabelInputComponent>
{
  @Input() data: LabelInputComponentData;
  set self(value: ComponentRef<LabelInputComponent>) {
    this.data.componentRef = value;
  }

  set selected(value: boolean) {
    if (value) {
      this.comment.enable();
    } else {
      this.comment.disable();
    }
  }

  form = new UntypedFormGroup({
    comment: new UntypedFormControl({ value: '', disabled: true })
  });

  get comment(): UntypedFormControl {
    return <UntypedFormControl>this.form.get('comment');
  }
}

export class LabelInputComponentData {
  label: string;
  hint: string;
  componentRef: ComponentRef<LabelInputComponent>;

  constructor(label: string, hint: string) {
    this.label = label;
    this.hint = hint;
  }
}
