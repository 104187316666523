/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ServiceInfo } from './serviceInfo';
import { ServiceInfoSchema } from './serviceInfo';
import { ResourceModel } from './resourceModel';
import { ResourceModelSchema } from './resourceModel';
import * as JoiObj from "joi";


    export const AnnualPaymentsSchema = JoiObj.object({
        serviceInfo:
            
        JoiObj.link('#ServiceInfoSchema')

.allow(null)



        ,
        age:
            
                    JoiObj.number()
                


.required()


        ,
        installmentPension:
            
                    JoiObj.number()
                


.required()


        ,
        lifelongPension:
            
                    JoiObj.number()
                


.required()


        ,
        lumpSum:
            
                    JoiObj.number()
                


.required()


        ,
        publicPension:
            
                    JoiObj.number()
                


.required()


        ,
        salary:
            
                    JoiObj.number()
                


.required()


        ,
        tax:
            
                    JoiObj.number()
                


.required()


        ,
        totalIncomeWithoutLumpSumAndWealth:
            
                    JoiObj.number()
                


.required()


        ,
        useOfWealth:
            
                    JoiObj.number()
                


.required()


        ,
        wealth:
            
                    JoiObj.number()
                


.required()


        ,
        year:
            
                    JoiObj.number()
                


.required()


        
    })
                .shared(ServiceInfoSchema)
.id('AnnualPaymentsSchema')

export interface AnnualPayments extends ResourceModel { 
    age: number;
    installmentPension: number;
    lifelongPension: number;
    lumpSum: number;
    publicPension: number;
    salary: number;
    tax: number;
    totalIncomeWithoutLumpSumAndWealth: number;
    useOfWealth: number;
    wealth: number;
    year: number;
}

