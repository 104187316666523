import { Component, Input } from '@angular/core';
import { openClose } from '@pfa/animations';

@Component({
  selector: 'co-introbox',
  templateUrl: './introbox.component.html',
  styleUrls: ['./introbox.component.scss'],
  animations: [openClose]
})
export class IntroboxComponent {
  @Input() title: string;
  @Input() description: string;

  /**
   * Component will use accordion to show content by default,
   * otherwise content will be added inline
   */
  @Input() isContentInline = false;

  expanded: boolean;
}
