/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const BeneficiaryRelationSchema = JoiObj.string().valid(
        'SPOUSE'
        ,
        'COHABITANT_WITH_COMMON_RESIDENCE'
        ,
        'COHABITANT_CHILD_OR_DESCENDANT'
        ,
        'CHILD_OR_DESCENDANT'
        ,
        'GRAND_CHILD_OR_DESCENDANT'
        ,
        'DIVORCED_SPOUSE'
        ,
        'STEP_CHILD_OR_DESCENDANT'
        ,
        'ROOM_MATE'
        ,
        'MOTHER'
        ,
        'FATHER'
        ,
        'SISTER'
        ,
        'BROTHER'
        ,
        'COUSIN'
        ,
        'NIECE'
        ,
        'NEPHEW'
        ,
        'FRIEND'
        ,
        'SWEETHEART'
        ,
        'COHABITANT_WITHOUT_COMMON_RESIDENCE'
        ,
        'OTHER'
        
    )
.id('BeneficiaryRelationSchema')

export type BeneficiaryRelation = 'SPOUSE' | 'COHABITANT_WITH_COMMON_RESIDENCE' | 'COHABITANT_CHILD_OR_DESCENDANT' | 'CHILD_OR_DESCENDANT' | 'GRAND_CHILD_OR_DESCENDANT' | 'DIVORCED_SPOUSE' | 'STEP_CHILD_OR_DESCENDANT' | 'ROOM_MATE' | 'MOTHER' | 'FATHER' | 'SISTER' | 'BROTHER' | 'COUSIN' | 'NIECE' | 'NEPHEW' | 'FRIEND' | 'SWEETHEART' | 'COHABITANT_WITHOUT_COMMON_RESIDENCE' | 'OTHER';

export const BeneficiaryRelation = {
    Spouse: 'SPOUSE' as BeneficiaryRelation,
    CohabitantWithCommonResidence: 'COHABITANT_WITH_COMMON_RESIDENCE' as BeneficiaryRelation,
    CohabitantChildOrDescendant: 'COHABITANT_CHILD_OR_DESCENDANT' as BeneficiaryRelation,
    ChildOrDescendant: 'CHILD_OR_DESCENDANT' as BeneficiaryRelation,
    GrandChildOrDescendant: 'GRAND_CHILD_OR_DESCENDANT' as BeneficiaryRelation,
    DivorcedSpouse: 'DIVORCED_SPOUSE' as BeneficiaryRelation,
    StepChildOrDescendant: 'STEP_CHILD_OR_DESCENDANT' as BeneficiaryRelation,
    RoomMate: 'ROOM_MATE' as BeneficiaryRelation,
    Mother: 'MOTHER' as BeneficiaryRelation,
    Father: 'FATHER' as BeneficiaryRelation,
    Sister: 'SISTER' as BeneficiaryRelation,
    Brother: 'BROTHER' as BeneficiaryRelation,
    Cousin: 'COUSIN' as BeneficiaryRelation,
    Niece: 'NIECE' as BeneficiaryRelation,
    Nephew: 'NEPHEW' as BeneficiaryRelation,
    Friend: 'FRIEND' as BeneficiaryRelation,
    Sweetheart: 'SWEETHEART' as BeneficiaryRelation,
    CohabitantWithoutCommonResidence: 'COHABITANT_WITHOUT_COMMON_RESIDENCE' as BeneficiaryRelation,
    Other: 'OTHER' as BeneficiaryRelation
};

