/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ExtentType } from './extentType';
import { ExtentTypeSchema } from './extentType';
import { BeneficiaryPersonView } from './beneficiaryPersonView';
import { BeneficiaryPersonViewSchema } from './beneficiaryPersonView';
import * as JoiObj from "joi";


    export const BeneficiaryPolicyViewSchema = JoiObj.object({
        extentType:
            
        JoiObj.link('#ExtentTypeSchema')


.required()


        ,
        id:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        persons:
                JoiObj.array().items(
        
        JoiObj.link('#BeneficiaryPersonViewSchema')

        )
        

.required()


        ,
        separateProperties:
            
                    JoiObj.boolean()
                


.required()


        ,
        tiedUpFunds:
            
                    JoiObj.boolean()
                


.required()


        
    })
                .shared(ExtentTypeSchema)
                .shared(BeneficiaryPersonViewSchema)
.id('BeneficiaryPolicyViewSchema')

export interface BeneficiaryPolicyView { 
    extentType: ExtentType;
    id: string;
    persons: Array<BeneficiaryPersonView>;
    separateProperties: boolean;
    tiedUpFunds: boolean;
}
export namespace BeneficiaryPolicyView {
}


