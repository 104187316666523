/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const Rentgruppe3og4Schema = JoiObj.object({
        kortvarighed:
            
                    JoiObj.number()
                


.required()


        ,
        langvarighed:
            
                    JoiObj.number()
                


.required()


        ,
        minimumsregl:
            
                    JoiObj.number()
                


.required()


        ,
        selvvalgkoncept:
            
                    JoiObj.number()
                


.required()


        
    })
.id('Rentgruppe3og4Schema')

export interface Rentgruppe3og4 { 
    kortvarighed: number;
    langvarighed: number;
    minimumsregl: number;
    selvvalgkoncept: number;
}

