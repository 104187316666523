/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CustomerStatus } from './customerStatus';
import { CustomerStatusSchema } from './customerStatus';
import * as JoiObj from "joi";


    export const SpecialAgreementSchema = JoiObj.object({
        customerStatus:
            
        JoiObj.link('#CustomerStatusSchema')


.required()


        ,
        specialAgreementName:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        
    })
                .shared(CustomerStatusSchema)
.id('SpecialAgreementSchema')

export interface SpecialAgreement { 
    customerStatus: CustomerStatus;
    specialAgreementName: string;
}
export namespace SpecialAgreement {
}


