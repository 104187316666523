import { Injectable } from '@angular/core';
import { DetachedRouteHandle } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class MitPFARouteReuseStrategyService {
  private storedRoutes = new Map<string, DetachedRouteHandle>();
  private skipNextSetDetachedRouteHandle: boolean;

  /**
   *
   * @param skipNextSetDetachedRouteHandle
   * Set to true if clearing from a route that is cached to skip caching of the route when leaving.
   */
  clearStoredRoutes(skipNextSetDetachedRouteHandle?: boolean): void {
    this.skipNextSetDetachedRouteHandle = skipNextSetDetachedRouteHandle;
    this.storedRoutes.clear();
  }

  getDetachedRouteHandle(key: string): DetachedRouteHandle {
    return this.storedRoutes.get(key);
  }

  setDetachedRouteHandle(
    key: string,
    detachedRouteHandle: DetachedRouteHandle
  ): DetachedRouteHandle {
    if (this.skipNextSetDetachedRouteHandle) {
      this.skipNextSetDetachedRouteHandle = false;
      this.storedRoutes.set(key, null);
      return null;
    } else {
      return this.storedRoutes.set(key, detachedRouteHandle);
    }
  }
}
