/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ServiceInfo } from './serviceInfo';
import { ServiceInfoSchema } from './serviceInfo';
import { ResourceModel } from './resourceModel';
import { ResourceModelSchema } from './resourceModel';
import { Fond } from './fond';
import { FondSchema } from './fond';
import * as JoiObj from "joi";


    export const FondFordelingSchema = JoiObj.object({
        serviceInfo:
            
        JoiObj.link('#ServiceInfoSchema')

.allow(null)



        ,
        aktieFonde:
                JoiObj.array().items(
        
        JoiObj.link('#FondSchema')

        )
        

.required()


        ,
        obligationFonde:
                JoiObj.array().items(
        
        JoiObj.link('#FondSchema')

        )
        

.required()


        ,
        oevrigeFonde:
                JoiObj.array().items(
        
        JoiObj.link('#FondSchema')

        )
        

.required()


        ,
        profilFondeOgBlandetKategoriFonde:
                JoiObj.array().items(
        
        JoiObj.link('#FondSchema')

        )
        

.required()


        
    })
                .shared(ServiceInfoSchema)
                .shared(FondSchema)
                .shared(FondSchema)
                .shared(FondSchema)
                .shared(FondSchema)
.id('FondFordelingSchema')

export interface FondFordeling extends ResourceModel { 
    aktieFonde: Array<Fond>;
    obligationFonde: Array<Fond>;
    oevrigeFonde: Array<Fond>;
    profilFondeOgBlandetKategoriFonde: Array<Fond>;
}

