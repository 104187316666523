<div
  class="text--manchet header spacing-unit--double-under"
  coContent="DL.VK01.C103"
></div>
<co-card class="combine-savings-summary-card">
  <div class="text--bold spacing-unit--under" coContent="DL.VK01.C104"></div>
  <p coContent="DL.EO01.C651"></p>
  <div class="spacing-unit--double-over align--center">
    <button
      mat-raised-button
      class="button-secondary move-on"
      coContent="DL.EO01.C601"
      (click)="goToNextPage.emit()"
    ></button>
  </div>
</co-card>
