<co-loading [show]="!threadInitialized || !thread"></co-loading>

<div *ngIf="threadInitialized && thread" class="messaging-thread" coBlurIt>
  <div (click)="emitCloseThread()" class="messaging-close-button">
    <co-icon-times class="icons"></co-icon-times>
  </div>

  <co-warning *ngIf="thread === 'failed'" class="thread-failed">
    <span [coContent]="'DL.BE02.C23'"></span>
  </co-warning>

  <div
    *ngFor="let message of thread.messages; let first = first"
    class="message"
    [ngClass]="{ first: first, 'from-customer': message.fromCustomer }"
  >
    <div class="message-body">
      <co-loading [show]="message.sending"></co-loading>
      <div class="message-body-text" [innerHtml]="message.formattedBody"></div>

      <div
        *ngFor="let attachment of message.attachments"
        class="message-attachment"
      >
        <co-icon-paperclip></co-icon-paperclip>

        <a
          (click)="openAttachment(attachment)"
          [ngClass]="{
            disabled: attachment.disabled || attachment.limitExceeded
          }"
          class="link link-external"
        >
          {{ attachment.name }}
        </a>

        <co-warning *ngIf="attachment.limitExceeded">
          <span [coContent]="'DL.BE02.C50'"></span>
        </co-warning>
      </div>
    </div>

    <div class="message-time">
      {{ message.creationDate | date: 'dd.MM.yyyy' }}
      {{ message.creationDate | date: 'HH:mm' }}
    </div>

    <co-warning *ngIf="message.replyFailed">
      <span [coContent]="'DL.BE02.C09'">FEJL!</span>
    </co-warning>
  </div>

  <div class="cf"></div>
  <div id="thread-end"></div>
</div>

<div
  *ngIf="threadInitialized && thread && !thread.noReply"
  class="message-reply"
  [ngClass]="{ 'no-messages': !thread.messages?.length }"
>
  <div class="message-input">
    <div class="messaging-attach-icon" (click)="toggleAttachmentArea()">
      <co-icon-paperclip></co-icon-paperclip>
    </div>

    <div class="input-area">
      <mat-form-field class="mat-form-field--compact" color="accent">
        <textarea
          matInput
          ondrop="return false;"
          id="text"
          name=""
          maxlength="2000"
          coGrowIt
          [resizeOnBlur]="true"
          [(ngModel)]="messageBody"
        >
        </textarea>
      </mat-form-field>
      <div class="messaging-attach-area" [hidden]="!showAttachmentArea">
        <co-attachment
          class="message-reply-attachments"
          [attachments]="attachments"
          (addAttachment)="addAttachment($event)"
          (removeAttachment)="removeAttachment($event)"
          (showWarnings)="setShowWarnings($event)"
          [channel]="channel"
          [limitSize]="20 * 1024 * 1024"
        >
        </co-attachment>
      </div>
    </div>
  </div>

  <button
    mat-raised-button
    class="button-secondary message-send-button"
    [disabled]="!messageBody || messageBody.trim() === '' || sendingMessage"
    (click)="sendMessage()"
    [coContent]="'DL.BE02.C06'"
  ></button>
</div>
<co-info *ngIf="threadInitialized && thread" class="message-warning">
  <span [coContent]="'DL.BE02.C1049'"></span>
</co-info>
