<div class="row">
  <div class="col-md-6 col-image">
    <div class="image"></div>
  </div>

  <div class="col-md-6">
    <p class="text--color-primary text--bold" coContent="DL.FT01.C03"></p>
    <h1 coContent="DL.FG01.C1377"></h1>
    <p
      class="spacing-unit--over text--manchet text--info"
      coContent="DL.FG01.C1379"
    ></p>
    <ul class="spacing-unit--double-under text--info">
      <li>
        <div>
          <co-icon-circle-checkmark color="white"></co-icon-circle-checkmark>
        </div>
        <span coContent="DL.FG01.C1760"></span>
      </li>
      <li>
        <div>
          <co-icon-circle-checkmark color="white"></co-icon-circle-checkmark>
        </div>
        <span coContent="DL.FG01.C1761"></span>
      </li>
      <li>
        <div>
          <co-icon-circle-checkmark color="white"></co-icon-circle-checkmark>
        </div>
        <span coContent="DL.FG01.C1762"></span>
      </li>
    </ul>

    <co-advisor-box class="small" *ngIf="isAdvisor">
      <div class="advisor-section">
        <div
          [coContent]="
            dataValidForShortFlow ? 'DL.FG01.C1912' : 'DL.FG01.C1913'
          "
        ></div>

        <form [formGroup]="advisorShortFlowForm">
          <mat-radio-group formControlName="isShortFlow" class="radio-section">
            <mat-radio-button [value]="true">
              {{ 'DL.MD01.C330' | content }}
            </mat-radio-button>
            <mat-radio-button [value]="false">
              {{ 'DL.MD01.C331' | content }}
            </mat-radio-button>
          </mat-radio-group>
        </form>
      </div>
    </co-advisor-box>
    <div class="button-section">
      <div class="col-button">
        <button
          class="button-secondary"
          mat-raised-button
          coContent="DL.FG01.C1383"
          (click)="onStartGuide()"
          data-test-id="insurance-guide-start-flow-btn"
        ></button>
      </div>
      <div class="text--bold col-info">
        <span coContent="DL.FG01.C1763"></span>
      </div>
    </div>
  </div>
</div>
