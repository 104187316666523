import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { MaeglerInfo, Residences } from '@pfa/gen';
import { InvestmentGuideInfo } from '@pfa/models';
import { generateSliderStepsArray } from '@pfaform';
import { currencyValidator, numberAsStringValidator } from '@pfa/utils';
import { Options } from 'ngx-slider-v2';
import { InvestmentGuideQuestion } from '../../../investment-guide-configuration.service';
import { InvestmentGuideRealEstateService } from '../../investment-guide-real-estate.service';
import { GuideTrackingService } from '@pfa/core';
import { InvestmentGuideService } from '@mitpfa/shared';

@Component({
  selector: 'mitpfa-investment-guide-real-estate',
  templateUrl: './investment-guide-real-estate.component.html',
  styleUrls: ['./investment-guide-real-estate.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InvestmentGuideRealEstateComponent
  implements OnInit, AfterViewInit
{
  @Input() investmentGuide: InvestmentGuideInfo;
  @Input() broker: MaeglerInfo;

  form = new UntypedFormGroup({});
  primaryResidences: Residences;
  hasRealEstate = false;
  activeIndex = 0;
  sliderOptions: Options = {
    stepsArray: [
      ...generateSliderStepsArray(0, 300000, 10000),
      ...generateSliderStepsArray(350000, 10000000, 50000)
    ],
    ceil: 10000000
  };

  constructor(
    readonly investmentGuideRealEstateService: InvestmentGuideRealEstateService,
    readonly trackingService: GuideTrackingService,
    private readonly investmentGuideService: InvestmentGuideService
  ) {}

  ngOnInit() {
    if (this.investmentGuide?.recommendationBasis?.primaryResidences) {
      this.primaryResidences =
        this.investmentGuide.recommendationBasis.primaryResidences;
    } else {
      this.primaryResidences = {
        realEstateAnswer: false,
        realEstates: []
      } as Residences;
    }

    this.initializeForm();
  }

  ngAfterViewInit(): void {
    window.scroll(0, 0);
  }

  declined() {
    this.investmentGuideRealEstateService.clearResidences(this.form);
    this.goToNextPage();
  }

  goBack() {
    this.investmentGuideService.updateCurrentQuestion({
      value: InvestmentGuideQuestion.otherSavings,
      update: false,
      back: true
    });
  }

  goToNextPage() {
    this.investmentGuide.recommendationBasis.primaryResidences =
      this.investmentGuideRealEstateService.setResidences(
        this.investmentGuide.recommendationBasis.primaryResidences,
        this.hasRealEstate,
        this.form
      );
    this.investmentGuideService.updateCurrentQuestion({
      value: InvestmentGuideQuestion.secondaryResidence,
      update: true
    });
  }

  displayDetails() {
    this.hasRealEstate = true;
    this.investmentGuideRealEstateService.initializeRealEstates(
      this.primaryResidences
    );
    this.activeIndex = 1;
  }

  closeDetails() {
    this.activeIndex = 0;
  }

  private initializeForm() {
    const currencyValidators = currencyValidator();

    this.form = new UntypedFormGroup({
      realEstateName: new UntypedFormControl(
        this.primaryResidences.realEstates[0]?.realEstateName || '',
        {
          validators: [Validators.required]
        }
      ),
      currentMarketValue: new UntypedFormControl(
        this.primaryResidences.realEstates[0]?.currentMarketValue || 0,
        currencyValidators
      ),
      estateDebt: new UntypedFormControl(
        this.primaryResidences.realEstates[0]?.estateDebt || 0,
        currencyValidators
      ),
      percentageOwned: new UntypedFormControl(
        this.primaryResidences.realEstates[0]?.percentageOwned || 100,
        [
          Validators.max(100),
          Validators.min(0),
          numberAsStringValidator(),
          Validators.required
        ]
      )
    });
  }
}
