/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ContactData } from './contactData';
import { ContactDataSchema } from './contactData';
import * as JoiObj from "joi";


    export const ProspectRequestSchema = JoiObj.object({
        contactData:
            
        JoiObj.link('#ContactDataSchema')


.required()


        ,
        cpr:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        
    })
                .shared(ContactDataSchema)
.id('ProspectRequestSchema')

export interface ProspectRequest { 
    contactData: ContactData;
    cpr: string;
}

