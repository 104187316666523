<mitpfa-investment-guide-pension-info
  *ngIf="(currentQuestion$ | async)?.value === question.retrievePensioninfo"
  [pensionCustomer]="pensionCustomer"
  [investmentGuide]="investmentGuide"
>
</mitpfa-investment-guide-pension-info>

<mitpfa-investment-guide-savings
  *ngIf="(currentQuestion$ | async)?.value === question.otherSavings"
  [investmentGuide]="investmentGuide"
  [broker]="broker"
  [pensionInfoSavedValues]="pensionInfoSavedValues"
  [pensionCustomer]="pensionCustomer"
>
</mitpfa-investment-guide-savings>

<mitpfa-investment-guide-real-estate
  *ngIf="(currentQuestion$ | async)?.value === question.realEstate"
  [investmentGuide]="investmentGuide"
  [broker]="broker"
>
</mitpfa-investment-guide-real-estate>

<mitpfa-investment-guide-secondary-residence
  *ngIf="(currentQuestion$ | async)?.value === question.secondaryResidence"
  [investmentGuide]="investmentGuide"
  [broker]="broker"
>
</mitpfa-investment-guide-secondary-residence>

<mitpfa-investment-guide-has-debt
  *ngIf="(currentQuestion$ | async)?.value === question.hasDebt"
  [investmentGuide]="investmentGuide"
  [broker]="broker"
>
</mitpfa-investment-guide-has-debt>

<mitpfa-investment-guide-economical-impact
  *ngIf="(currentQuestion$ | async)?.value === question.economicalImpact"
  [investmentGuide]="investmentGuide"
  [broker]="broker"
  [pensionCustomer]="pensionCustomer"
>
</mitpfa-investment-guide-economical-impact>
