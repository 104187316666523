import { Injectable } from '@angular/core';
import { ContentUtilService, NumberFormatPipe } from '@pfa/core';
import { TilpasForsikringstal } from '@pfa/gen';
import { COMPANY_PFA, InsuranceUnit } from '@pfa/models';
import { InsuranceUtilService } from '@pfaform';
import { Subject } from 'rxjs';
import { RecommendationDescription } from '@mitpfa/shared/insurance-guide/recommendation-step/insurance-guide.recommendation.model';
import {
  InsuranceAdjustmentState,
  InsuranceAdjustmentType,
  InsuranceAdjustmentValues,
  InsuranceGuideChecks,
  PayscaleConfigLoc
} from '../models/insurance-adjustment.model';
import {
  InputConfigItemLoc,
  InputConfigLoc
} from '@mitpfa/shared/models/insurance-adjustment-input-loc.model';
import { InsuranceAdjustmentAdvisorInfo } from '@mitpfa/shared/models/insurance-adjustment-advisor-info.model';

@Injectable({
  providedIn: 'root'
})
export class InsuranceAdjustmentLocService {
  followOurRecommendation: Subject<void>;
  adjustInsurance: Subject<void>;

  constructor(
    private readonly contentUtilService: ContentUtilService,
    private readonly numberFormat: NumberFormatPipe
  ) {
    this.followOurRecommendation = new Subject<void>();
    this.adjustInsurance = new Subject<void>();
  }

  resetAdjustInsuranceSubject(): void {
    this.adjustInsurance.complete();
    this.adjustInsurance = new Subject<void>();
  }

  resetFollowOurRecommendationSubject(): void {
    this.followOurRecommendation.complete();
    this.followOurRecommendation = new Subject<void>();
  }

  getLocPayscale(insuranceState: InsuranceAdjustmentState): PayscaleConfigLoc {
    const autoAdjustmentType =
      insuranceState.insurance.tilpasForsikringstal.autoAdjustment?.type;
    const autoAdjustment =
      insuranceState.insurance.tilpasForsikringstal.autoAdjustment;
    return {
      selected: autoAdjustmentType === 'TAE',
      none: autoAdjustmentType === 'NONE',
      possible:
        autoAdjustment?.customerSelection === 'YES' ||
        autoAdjustment?.customerSelection === 'NO'
    };
  }

  createInputConfig(
    insuranceState: InsuranceAdjustmentState,
    insuranceAdjustmentChangesOriginal: InsuranceAdjustmentValues,
    locPayscale: PayscaleConfigLoc,
    insuranceGuideChecks: InsuranceGuideChecks,
    recommendationDescription: RecommendationDescription
  ): InputConfigLoc {
    const result = {
      headerContentId: 'DL.TF01.C28'
    } as InputConfigLoc;
    const data = insuranceState.insurance.tilpasForsikringstal;

    result.regularPayout = this.createRegularPayouts(
      insuranceState,
      data,
      insuranceAdjustmentChangesOriginal,
      locPayscale
    );

    result.recommendation =
      insuranceState.insurance.pfaAnbefalerHelbred.procent;
    let recommendationValid = true;
    let followingRecommendation = false;
    if (
      recommendationDescription.isAuto &&
      recommendationDescription.recommendation !== 'MANUAL'
    ) {
      followingRecommendation =
        insuranceState.insurance.tilpasForsikringstal.sliderTAEloebende
          .locScale === recommendationDescription.recommendation;
    } else {
      recommendationValid =
        result.recommendation >= (result.regularPayout.amountMin ?? 0) &&
        result.recommendation <= (result.regularPayout.amountMax ?? 0);
      const recommendationDiff = Math.abs(
        result.recommendation - insuranceState.insurance.helbredsTalBrutto
      );
      followingRecommendation =
        recommendationDiff <= InsuranceUtilService.HEALTH_VARIATION;
    }
    result.showRecommendationButton =
      !insuranceGuideChecks.noGuide &&
      !insuranceGuideChecks.guideTooOld &&
      recommendationValid &&
      !followingRecommendation;

    result.followsRecommendation = followingRecommendation;

    result.lumpSum = this.createLumpSum(
      data,
      insuranceAdjustmentChangesOriginal
    );

    return result;
  }

  locAdjustmentAdvisorInfo(
    insuranceState: InsuranceAdjustmentState
  ): InsuranceAdjustmentAdvisorInfo {
    return {
      type: InsuranceAdjustmentType.LOC,
      taxCode:
        insuranceState.insurance.tilpasForsikringstal
          .daekningAendringTAEengangsudbetaling.valgtSkattekode,
      advisorData: {
        raadgiverAnbefaling:
          insuranceState.insurance.taeRaadgiverAnbefaling?.raadgiverAnbefaling,
        raadgiverKommentar:
          insuranceState.insurance.taeRaadgiverAnbefaling?.raadgiverKommentar,
        raadgiverAnbefalingOprettet:
          insuranceState.insurance.taeRaadgiverAnbefaling
            ?.raadgiverAnbefalingOprettet,
        aktuelForsikringstal:
          insuranceState.insurance.originalTilpasForsikringstal
            .currentForsikringstalTAE,
        pfaAnbefaling:
          insuranceState.insurance.originalTilpasForsikringstal.pfaAnbefalerTAE,
        validAdviserRecommendation: true
      }
    };
  }

  private getNoteText(contentId: string, value: number): string {
    return this.contentUtilService.getContent(
      contentId,
      this.numberFormat.transform(value)
    );
  }

  private createRegularPayouts(
    insuranceState: InsuranceAdjustmentState,
    data: TilpasForsikringstal,
    insuranceAdjustmentChangesOriginal: InsuranceAdjustmentValues,
    locPayscale: PayscaleConfigLoc
  ): InputConfigItemLoc {
    const dataOriginal = insuranceState.insurance.originalTilpasForsikringstal;
    const specialRecommendation =
      insuranceState.insurance.pfaAnbefalerHelbred.saerligAnbefaling;
    const locScaleInformation = insuranceState.insurance.locScaleInformation;
    const locScale = insuranceState.insurance.tilpasForsikringstal.locScale;

    let header = 'DL.TF01.C29';
    const note = 'DL.TF01.C35';
    if (locPayscale.selected) {
      header = 'DL.TF01.C89';
    }

    let noteBeforeTax, noteBeforeTaxOriginal;
    const noteBeforeTaxContentId = this.taxFree(insuranceState)
      ? 'DL.TF01.C195'
      : 'DL.TF01.C71';
    if (!data.bruttoTalTaeloebendeIkkeVises) {
      noteBeforeTax = this.getNoteText(
        noteBeforeTaxContentId,
        data.sliderTAEloebende.forsikringstalBruttobeloeb
      );
    }
    if (!dataOriginal.bruttoTalTaeloebendeIkkeVises) {
      noteBeforeTaxOriginal = this.getNoteText(
        noteBeforeTaxContentId,
        dataOriginal.sliderTAEloebende.forsikringstalBruttobeloeb
      );
    }

    let noteAfterTax, noteAfterTaxOriginal;
    if (!this.taxFree(insuranceState)) {
      noteAfterTax = this.getNoteText(
        'DL.TF01.C33',
        data.sliderTAEloebende.forsikringstalNettobeloeb
      );
      noteAfterTaxOriginal = this.getNoteText(
        'DL.TF01.C33',
        dataOriginal.sliderTAEloebende.forsikringstalNettobeloeb
      );
    }

    const notePrice = this.getNoteText(
      'DL.TF01.C32',
      data.sliderTAEloebende.pris / 12
    );
    const notePriceOriginal = this.getNoteText(
      'DL.TF01.C32',
      dataOriginal.sliderTAEloebende.pris / 12
    );

    const warningSpecialCoverage =
      data.sliderTAEloebende.valgtSkattekode === 'SK5'
        ? this.contentUtilService.getContent('DL.TF01.C78')
        : '';
    const warning80Percent =
      data.currentForsikringstalTAE >
      data.sliderTAEloebende.advarselGraenseForsikringstal
        ? this.contentUtilService.getContent('DL.TF01.C77')
        : '';
    const warningRecommendLow =
      specialRecommendation &&
      data.pfaAnbefalerTAE < data.currentForsikringstalTAE
        ? this.contentUtilService.getContent('DL.TF01.C76')
        : '';

    let special = false;
    if (
      data.sliderTAEloebende.daekningsId === 813 &&
      data.sliderTAEloebende.daekningsspaendEnhed === 'Procent'
    ) {
      special = true;
    }

    return {
      type: InsuranceAdjustmentType.LOC,
      textContentId: header,
      tooltipContentId: this.contentUtilService.getContent(
        data.sliderTAEloebende.infoTekst,
        this.getInfoTextValues(data)
      ),
      noteContentId: note,
      amountMin: Math.ceil(data.sliderTAEloebende.minimumsdaekning),
      amountMax: Math.floor(data.sliderTAEloebende.forsikringstalMax),
      labelContentId: 'DL.TF01.C31',
      amount: data.sliderTAEloebende.currentForsikringstal,
      amountOriginal: insuranceAdjustmentChangesOriginal.locRegularPayoutAmount,
      unit: InsuranceUnit.PERCENT,
      noteBeforeTax,
      noteAfterTax,
      notePrice,
      noteBeforeTaxOriginal,
      noteAfterTaxOriginal,
      notePriceOriginal,
      payoutStopAge: this.paymentEndsAtAge(insuranceState),
      warningSpecialCoverage,
      warning80Percent,
      warningRecommendLow,
      special,
      locScaleInformation,
      locScale
    } as InputConfigItemLoc;
  }

  private getInfoTextValues(data: TilpasForsikringstal): Array<string> {
    const locSalary =
      data.sliderTAEloebende.infoTekst === 'DL.FG01.I604' ||
      data.sliderTAEloebende.infoTekst === 'DL.FG01.I605'
        ? this.numberFormat.transform(data.loenFraMineOplysninger)
        : this.numberFormat.transform(data.aarsloen);
    return [
      this.numberFormat.transform(data.sliderTAEloebende.minimumsdaekning),
      this.numberFormat.transform(data.sliderTAEloebende.forsikringstalMax),
      locSalary
    ];
  }

  private createLumpSum(
    data: TilpasForsikringstal,
    insuranceAdjustmentChangesOriginal: InsuranceAdjustmentValues
  ): InputConfigItemLoc | undefined {
    if (data.daekningAendringTAEengangsudbetaling.skalVises) {
      const isPercent =
        data.daekningAendringTAEengangsudbetaling.enhedType === 'Procent';
      const notePercentAsAmount = isPercent
        ? this.contentUtilService.getContent(
            'DL.TF01.C74',
            this.numberFormat.transform(
              data.daekningAendringTAEengangsudbetaling.bruttobeloeb
            )
          )
        : '';
      const notePrice = this.contentUtilService.getContent(
        'DL.TF01.C32',
        this.numberFormat.transform(
          data.daekningAendringTAEengangsudbetaling.pris / 12
        )
      );
      return {
        type: InsuranceAdjustmentType.LOC_LUMPSUM,
        textContentId: 'DL.TF01.C75',
        noteContentId: isPercent ? 'DL.TF01.C35' : 'DL.TF01.C30',
        amountMin: Math.ceil(
          data.daekningAendringTAEengangsudbetaling.daekningsspaend.startValue
        ),
        amountMax: Math.floor(
          data.daekningAendringTAEengangsudbetaling.daekningsspaend.endValue ??
            data.daekningAendringTAEengangsudbetaling.daekningsspaend.startValue
        ),
        labelContentId: 'DL.TF01.C31',
        amount: data.daekningAendringTAEengangsudbetaling.daekningsBeloeb,
        amountOriginal: insuranceAdjustmentChangesOriginal.locLumpSumAmount,
        unit: isPercent ? InsuranceUnit.PERCENT : InsuranceUnit.KR,
        notePercentAsAmount,
        notePrice
      } as InputConfigItemLoc;
    }
    return undefined;
  }

  private taxFree(insuranceAdjustmentState: InsuranceAdjustmentState): boolean {
    return insuranceAdjustmentState.insuranceOverview.erhvervsevneForsikring?.erhvervsevneForsikringer
      .filter(insurance => insurance.formOfPayment === 'REGULAR_PAYMENT')
      .every(insurance => InsuranceUtilService.taxFree(insurance));
  }

  private paymentEndsAtAge(
    insuranceAdjustmentState: InsuranceAdjustmentState
  ): number | undefined {
    return insuranceAdjustmentState.insuranceOverview.erhvervsevneForsikring?.erhvervsevneForsikringer
      .filter(
        insurance =>
          insurance.formOfPayment === 'REGULAR_PAYMENT' &&
          insurance.selskab === COMPANY_PFA
      )
      .map(insurance => insurance.risikoOphoerAlder)[0];
  }
}
