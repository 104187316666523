/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const LivnetAllowedRangesSingleSchema = JoiObj.object({
        payoutAgeMax:
            
                    JoiObj.number()
                


.required()


        ,
        payoutAgeMin:
            
                    JoiObj.number()
                


.required()


        ,
        payoutDateMax:
            
            JoiObj.date()


.required()


        ,
        payoutDateMin:
            
            JoiObj.date()


.required()


        ,
        payoutPeriodMax:
            
                    JoiObj.number()
                

.allow(null)



        ,
        payoutPeriodMin:
            
                    JoiObj.number()
                

.allow(null)



        
    })
.id('LivnetAllowedRangesSingleSchema')

export interface LivnetAllowedRangesSingle { 
    payoutAgeMax: number;
    payoutAgeMin: number;
    payoutDateMax: Date;
    payoutDateMin: Date;
    payoutPeriodMax?: number;
    payoutPeriodMin?: number;
}

