import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy
} from '@angular/core';
import { OekonomiDetaljer, PensionsKundeGenerelleData } from '@pfa/gen';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'mitpfa-financial-situation-advisor-other-financial-conditions',
  templateUrl:
    './financial-situation-advisor-other-financial-conditions.component.html',
  styleUrls: [
    './financial-situation-advisor-other-financial-conditions.component.scss'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FinancialSituationAdvisorOtherFinancialConditionsComponent
  implements OnInit
{
  @Input() financialSituation: OekonomiDetaljer;
  @Input() pensionCustomer: PensionsKundeGenerelleData;
  @Input() form: UntypedFormGroup;

  public otherFinancialConditionsForm: UntypedFormGroup;

  ngOnInit(): void {
    this.initializeForm();
  }

  private initializeForm(): void {
    if (!this.pensionCustomer.isRaadgiver) {
      return;
    }

    this.otherFinancialConditionsForm = new UntypedFormGroup({
      investmentProperties: new UntypedFormControl(
        this.financialSituation.frieMidler.investeringsejendomme || false
      ),
      ownBusiness: new UntypedFormControl(
        this.financialSituation.frieMidler.egenVirksomhed || false
      )
    });

    if (this.form) {
      this.form.addControl(
        'otherFinancialConditions',
        this.otherFinancialConditionsForm
      );
    }
  }
}
