import { TaeNytte } from '@pfa/gen';

export class Choice {
  public previouslySelected = false;

  constructor(
    public value: TaeNytte,
    public header: string,
    public description: string
  ) {}
}
