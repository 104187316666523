/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const ServiceInfoSchema = JoiObj.object({
        hasBusinessFault:
            
                    JoiObj.boolean()
                


.required()


        ,
        hasCommunicationFault:
            
                    JoiObj.boolean()
                


.required()


        ,
        hasTimeoutFaultMitPfa:
            
                    JoiObj.boolean()
                


.required()


        ,
        hasTimeoutFaultPsb:
            
                    JoiObj.boolean()
                


.required()


        ,
        hasValidationFault:
            
                    JoiObj.boolean()
                


.required()


        
    })
.id('ServiceInfoSchema')

export interface ServiceInfo { 
    hasBusinessFault: boolean;
    hasCommunicationFault: boolean;
    hasTimeoutFaultMitPfa: boolean;
    hasTimeoutFaultPsb: boolean;
    hasValidationFault: boolean;
}

