import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard.component';
import { MyCommonModule } from '@pfa/common';
import { DashboardInsuranceComponent } from './dashboard-insurance/dashboard-insurance.component';
import { IconModule } from '@pfa/icons';
import { CoreModule } from '@pfa/core';
import { DashboardAdvantagesComponent } from './dashboard-advantages/dashboard-advantages.component';
import { DashboardSavingsComponent } from './dashboard-savings/dashboard-savings.component';
import { DashboardSavingsHeaderComponent } from './dashboard-savings/dashboard-savings-header/dashboard-savings-header.component';
import { DashboardSavingsPensionInComponent } from './dashboard-savings/dashboard-savings-pension-in/dashboard-savings-pension-in.component';
import { DashboardSavingsPayoutsComponent } from './dashboard-savings/dashboard-savings-payouts/dashboard-savings-payouts.component';
import { DashboardSavingsReturnComponent } from './dashboard-savings/dashboard-savings-return/dashboard-savings-return.component';
import { DashboardSavingsPaymentsComponent } from './dashboard-savings/dashboard-savings-payments/dashboard-savings-payments.component';
import { DashboardSavingsInvestmentComponent } from './dashboard-savings/dashboard-savings-investment/dashboard-savings-investment.component';
import { DashboardSavingsCostsComponent } from './dashboard-savings/dashboard-savings-costs/dashboard-savings-costs.component';
import { PfaFormModule } from '@pfaform';
import { FormsModule } from '@angular/forms';
import { DashboardSavingsReturnCardComponent } from './dashboard-savings/dashboard-savings-return/dashboard-savings-return-card/dashboard-savings-return-card.component';
import { PensionCheckModule } from '../pension-check/pension-check.module';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    MyCommonModule,
    CoreModule,
    RouterModule,
    IconModule,
    PfaFormModule,
    PensionCheckModule,
    MatButtonModule,
    MatDialogModule
  ],
  declarations: [
    DashboardComponent,
    DashboardInsuranceComponent,
    DashboardAdvantagesComponent,
    DashboardSavingsComponent,
    DashboardSavingsHeaderComponent,
    DashboardSavingsPensionInComponent,
    DashboardSavingsPayoutsComponent,
    DashboardSavingsReturnComponent,
    DashboardSavingsPaymentsComponent,
    DashboardSavingsInvestmentComponent,
    DashboardSavingsCostsComponent,
    DashboardSavingsReturnCardComponent
  ],
  exports: [DashboardComponent, DashboardInsuranceComponent],
  providers: []
})
export class DashboardModule {}
