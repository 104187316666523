import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CoreModule } from '@pfa/core';
import { PfaFormModule } from '@pfaform';
import { IconModule } from '@pfa/icons';
import { SigningCompletedPopupComponent } from '../app/signing-completed-popup/signing-completed-popup.component';
import { FinancialSituationAdvisorOtherFinancialConditionsComponent } from './financial-situation-advisor-other-financial-conditions/financial-situation-advisor-other-financial-conditions.component';
import { FinancialSituationDebtComponent } from './financial-situation-debt/financial-situation-debt.component';
import { FinancialSituationRealEstateAdvisorComponent } from './financial-situation-real-estate/financial-situation-real-estate-advisor/financial-situation-real-estate-advisor.component';
import { FinancialSituationRealEstateFormComponent } from './financial-situation-real-estate/financial-situation-real-estate-form/financial-situation-real-estate-form.component';
import { FinancialSituationRealEstateComponent } from './financial-situation-real-estate/financial-situation-real-estate.component';
import { FinancialSituationSavingsComponent } from './financial-situation-savings/financial-situation-savings.component';
import { FinancialSituationConfigurationService } from './services/financial-situation-configuration.service';
import { WealthPickerComponent } from './wealth-picker/wealth-picker.component';
import { PartnerInfoComponent } from './family/partner-info/partner-info.component';
import { ChildrenComponent } from './family/children/children.component';
import { FinancialSituationSalaryComponent } from './financial-situation-salary/financial-situation-salary.component';
import { FamilyInfoComponent } from './family/family-info.component';
import { PolicyCompanyNamePipe } from './pipes/policy-company-name.pipe';
import { PolicySurrenderChargePipe } from './pipes/policy-surrender-charge.pipe';
import { CombineSavingsSummaryCombinedComponent } from './combine-savings/summary/summary-variants/combine-savings-summary-combined/combine-savings-summary-combined.component';
import { CombineSavingsSummaryTransferableByAdvisorComponent } from './combine-savings/summary/summary-variants/combine-savings-summary-transferable-by-advisor/combine-savings-summary-transferable-by-advisor.component';
import { CombineSavingsSummaryErrorOccurredComponent } from './combine-savings/summary/summary-variants/combine-savings-summary-error-occurred/combine-savings-summary-error-occurred.component';
import { CombineSavingsTransferLeadComponent } from './combine-savings/transfer-lead/combine-savings-transfer-lead.component';
import { CombineSavingsAcceptTransferComponent } from './combine-savings/accept-transfer/combine-savings-accept-transfer.component';
import { CombineSavingsAcceptTransferService } from './combine-savings/accept-transfer/combine-savings-accept-transfer.service';
import { CombineSavingsNavigationService } from './combine-savings/combine-savings-navigation.service';
import { CombineSavingsBackButtonComponent } from './combine-savings/back-button/combine-savings-back-button.component';
import { ProofOfIdentityModule } from '../app/proof-of-identity/proof-of-identity.module';
import { RouterModule } from '@angular/router';
import { PfaClimateProfilesComponent } from './investment/concepts/pfa-climate-profiles/pfa-climate-profiles.component';
import { ClimatePercentageChooserComponent } from './investment/concepts/pfa-climate-profiles/climate-percentage-chooser/climate-percentage-chooser.component';
import { PfaInvestmentCardComponent } from './investment/concepts/pfa-investment-card/pfa-investment-card.component';
import { TradingStatusComponent } from './investment/trading-status/trading-status.component';
import { ChangeLanguageComponent } from '@mitpfa/shared/change-language/change-language.component';
import { InvestmentRecommendationComponent } from './investment/recommendation/investment-recommendation.component';
import { InvestmentRecommendationApproveComponent } from './investment/recommendation/recommendation-approve/recommendation-approve.component';
import { InvestmentRecommendationBasedComponent } from './investment/recommendation/recommendation-based/recommendation-based.component';
import { InvestmentRecommendationClimateComponent } from './investment/recommendation/recommendation-climate/recommendation-climate.component';
import { InvestmentRecommendationProfileComponent } from './investment/recommendation/recommendation-profile/recommendation-profile.component';
import { WaitComponent } from './wait/wait.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { CombineSavingsIntroComponent } from './combine-savings/intro/combine-savings-intro.component';
import { CombineSavingsConsentComponent } from '@mitpfa/shared/combine-savings/consent/combine-savings-consent.component';
import { CombineSavingsAcceptTransferFormService } from '@mitpfa/shared/combine-savings/accept-transfer/combine-savings-accept-transfer-form.service';
import { CombineSavingsSummaryComponent } from '@mitpfa/shared/combine-savings/summary/combine-savings-summary.component';
import { CombineSavingsSummaryNotCombinedComponent } from '@mitpfa/shared/combine-savings/summary/summary-variants/combine-savings-summary-not-combined/combine-savings-summary-not-combined.component';
import { CombineSavingsSummaryNotTransferableComponent } from '@mitpfa/shared/combine-savings/summary/summary-variants/combine-savings-summary-not-transferable/combine-savings-summary-not-transferable.component';
import { CombineSavingsSummaryService } from '@mitpfa/shared/combine-savings/summary/combine-savings-summary.service';
import { InvestmentRecommendationIntroComponent } from './investment/recommendation/recommendation-intro/recommendation-intro.component';
import { InvestmentRecommendationOverviewComponent } from './investment/recommendation/recommendation-overview/recommendation-overview.component';
import { InvestmentRecommendationOverviewSectionComponent } from './investment/recommendation/recommendation-overview/section/recommendation-overview-section.component';
import { InvestmentRecommendationProfileDetailComponent } from './investment/recommendation/recommendation-profile/profile-detail/recommendation-profile-detail.component';
import { InvestmentRecommendationClimateDetailComponent } from './investment/recommendation/recommendation-climate/climate-detail/recommendation-climate-detail.component';
import { InvestmentClimateModalComponent } from './investment/climate-modal/climate-modal.component';
import { InvestmentReadMoreComponent } from './investment/read-more/read-more.component';
import { InvestmentReadMorePfaInvestComponent } from './investment/read-more/pfa-invest/read-more-pfa-invest.component';
import { InvestmentReadMoreYouInvestComponent } from './investment/read-more/you-invest/read-more-you-invest.component';
import { InvestmentReadMoreClimateComponent } from './investment/read-more/climate/read-more-climate.component';
import { InvestmentService } from './investment/investment.service';
import { InvestmentRecommendationService } from './investment/recommendation/investment-recommendation.service';
import { SvgInformationComponent } from './svg/svg-information/svg-information.component';
import { SvgInsuranceComponent } from './svg/svg-insurance/svg-insurance.component';
import { SvgInvestmentguideComponent } from './svg/svg-investmentguide/svg-investmentguide.component';
import { SvgSavingsComponent } from './svg/svg-savings/svg-savings.component';
import { SvgCityComponent } from '@mitpfa/shared/svg/svg-city/svg-city.component';
import { SvgTrafficLightYellowComponent } from '@mitpfa/shared/svg/svg-traffic-light-yellow/svg-traffic-lights-yellow.component';
import { UpdateEmailComponent } from './updateemail/update-email.component';
import { UpdateEmailPopupComponent } from './updateemail/updateemail-popup.component';
import { InsuranceGuideRecommendationCriticalIllnessComponent } from '@mitpfa/shared/insurance-guide/recommendation-step/critical-illness/insurance-guide-recommendation-critical-illness.component';
import { InsuranceGuideComponent } from '@mitpfa/shared/insurance-guide/insurance-guide.component';
import { InsuranceGuideIntroComponent } from '@mitpfa/shared/insurance-guide/intro/insurance-guide-intro.component';
import { InsuranceGuideMyInformationStepComponent } from '@mitpfa/shared/insurance-guide/my-information-step/insurance-guide-my-information-step.component';
import { InsuranceGuideFinancialSituationComponent } from '@mitpfa/shared/insurance-guide/my-information-step/financial-situation/insurance-guide-financial-situation.component';
import { InsuranceGuideRiskStepComponent } from '@mitpfa/shared/insurance-guide/risk-step/risk/insurance-guide-risk-step.component';
import { InsuranceGuideInsuranceCoverStepComponent } from '@mitpfa/shared/insurance-guide/insurance-cover-step/insurance-guide-insurance-cover-step.component';
import { InsuranceGuidePensionPlanComponent } from '@mitpfa/shared/insurance-guide/my-information-step/pension-plan/insurance-guide-pension-plan.component';
import { InsuranceGuideYourFamilyComponent } from '@mitpfa/shared/insurance-guide/my-information-step/your-family/insurance-guide-your-family.component';
import { InsuranceGuideBackButtonComponent } from '@mitpfa/shared/insurance-guide/back-button/insurance-guide-back-button.component';
import { InsuranceGuideRiskGroupStepComponent } from '@mitpfa/shared/insurance-guide/risk-step/insurance-guide-risk-group-step.component';
import { InsuranceGuideLocStepComponent } from '@mitpfa/shared/insurance-guide/risk-step/loc/insurance-guide-loc-step.component';
import { InsuranceGuideDebtStepComponent } from '@mitpfa/shared/insurance-guide/risk-step/debt/insurance-guide-debt-step.component';
import { InsuranceGuideRecommendationCardComponent } from '@mitpfa/shared/insurance-guide/recommendation-step/card/insurance-guide-recommendation-card.component';
import { InsuranceGuideRecommendationOccupationalCapacityComponent } from '@mitpfa/shared/insurance-guide/recommendation-step/occupational-capacity/insurance-guide-recommendation-occupational-capacity.component';
import { InsuranceGuideRecommendationLifeComponent } from '@mitpfa/shared/insurance-guide/recommendation-step/life/insurance-guide-recommendation-life.component';
import { InsuranceGuideConfigurationService } from '@mitpfa/shared/insurance-guide/insurance-guide-configuration.service';
import { PensionInfoModule } from '@mitpfa/shared/pension-info/pension-info.module';
import { InvestmentGuideService } from './investment/guide/investment-guide.service';
import { InvestmentGuideConfigurationService } from './investment/guide/investment-guide-configuration.service';
import { InvestmentGuideSavingsService } from './investment/guide/economy-step/questions/savings/investment-guide-savings.service';
import { InvestmentGuideRealEstateService } from './investment/guide/economy-step/investment-guide-real-estate.service';
import { InvestmentGuideHasDebtService } from './investment/guide/economy-step/questions/has-debt/investment-guide-has-debt.service';
import { InsuranceAdjustmentComponent } from '@mitpfa/shared/insurance-adjustment/insurance-adjustment.component';
import { InsuranceAdjustmentInputCiComponent } from '@mitpfa/shared/insurance-adjustment/insurance-adjustment-input-ci/insurance-adjustment-input-ci.component';
import { InsuranceAdjustmentInputLocComponent } from '@mitpfa/shared/insurance-adjustment/insurance-adjustment-input-loc/insurance-adjustment-input-loc.component';
import { InsuranceAdjustmentInputLifeComponent } from '@mitpfa/shared/insurance-adjustment/insurance-adjustment-input-life/insurance-adjustment-input-life.component';
import { InsuranceAdjustmentHealthInformationComponent } from '@mitpfa/shared/insurance-adjustment/insurance-adjustment-health-information/insurance-adjustment-health-information.component';
import { InsuranceAdjustmentReceiptComponent } from '@mitpfa/shared/insurance-adjustment/insurance-adjustment-receipt/insurance-adjustment-receipt.component';
import { InsuranceAdjustmentSuperRiskPopupComponent } from '@mitpfa/shared/insurance-adjustment/insurance-adjustment-super-risk-popup/insurance-adjustment-super-risk-popup.component';
import { InsuranceAdjustmentAtypicalChoicePopupComponent } from '@mitpfa/shared/insurance-adjustment/insurance-adjustment-atypical-choice-popup/insurance-adjustment-atypical-choice-popup.component';
import { InsuranceAdjustmentAdvisorInfoComponent } from '@mitpfa/shared/insurance-adjustment/insurance-adjustment-advisor-info/insurance-adjustment-advisor-info.component';
import { InsuranceAdjustmentDeleteAdvisorRecPopupComponent } from '@mitpfa/shared/insurance-adjustment/insurance-adjustment-delete-advisor-rec-popup/insurance-adjustment-delete-advisor-rec-popup.component';
import { SvgTrafficLightsGreenComponent } from '@mitpfa/shared/svg/svg-traffic-light-green/svg-traffic-lights-green.component';
import { BasketOtherItemComponent } from '@mitpfa/shared/basket-other-item/basket-other-item.component';
import { PensionEstimateFormOfSavingsComponent } from '@mitpfa/shared/pension-estimate/pension-estimate-form-of-savings/pension-estimate-form-of-savings.component';
import { PensionEstimateFormOfSavingsPopupComponent } from './pension-estimate/pension-estimate-form-of-savings-popup/pension-estimate-form-of-savings-popup.component';
import { PensionEstimateSelectComponent } from '@mitpfa/shared/pension-estimate-select/pension-estimate-select.component';

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    PfaFormModule,
    IconModule,
    MatTooltipModule,
    MatRadioModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatDialogModule,
    MatSelectModule,
    MatCheckboxModule,
    ProofOfIdentityModule,
    MatAutocompleteModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatProgressBarModule,
    RouterModule,
    PensionInfoModule
  ],
  exports: [
    CommonModule,
    CoreModule,
    PfaFormModule,
    IconModule,
    MatTooltipModule,
    MatRadioModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatDialogModule,
    MatSelectModule,
    MatCheckboxModule,
    ProofOfIdentityModule,
    MatAutocompleteModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    WealthPickerComponent,
    FinancialSituationSavingsComponent,
    FinancialSituationDebtComponent,
    FinancialSituationRealEstateAdvisorComponent,
    FinancialSituationRealEstateFormComponent,
    FinancialSituationRealEstateComponent,
    FinancialSituationAdvisorOtherFinancialConditionsComponent,
    CombineSavingsSummaryComponent,
    CombineSavingsSummaryCombinedComponent,
    CombineSavingsSummaryTransferableByAdvisorComponent,
    CombineSavingsSummaryErrorOccurredComponent,
    CombineSavingsSummaryNotCombinedComponent,
    CombineSavingsSummaryNotTransferableComponent,
    CombineSavingsTransferLeadComponent,
    CombineSavingsIntroComponent,
    CombineSavingsAcceptTransferComponent,
    FinancialSituationSalaryComponent,
    CombineSavingsBackButtonComponent,
    PartnerInfoComponent,
    ChildrenComponent,
    PolicyCompanyNamePipe,
    FamilyInfoComponent,
    PolicySurrenderChargePipe,
    PfaClimateProfilesComponent,
    ClimatePercentageChooserComponent,
    PfaInvestmentCardComponent,
    TradingStatusComponent,
    InvestmentRecommendationComponent,
    ChangeLanguageComponent,
    WaitComponent,
    CombineSavingsConsentComponent,
    SvgInformationComponent,
    SvgInsuranceComponent,
    SvgInvestmentguideComponent,
    SvgSavingsComponent,
    UpdateEmailComponent,
    UpdateEmailPopupComponent,
    SvgCityComponent,
    SvgTrafficLightYellowComponent,
    SvgTrafficLightsGreenComponent,
    InsuranceGuideComponent,
    InsuranceGuideRecommendationOccupationalCapacityComponent,
    InsuranceGuideRecommendationCriticalIllnessComponent,
    InsuranceGuideRecommendationLifeComponent,
    InsuranceAdjustmentComponent,
    BasketOtherItemComponent,
    PensionEstimateFormOfSavingsComponent,
    PensionEstimateSelectComponent
  ],
  declarations: [
    WealthPickerComponent,
    FinancialSituationSavingsComponent,
    FinancialSituationDebtComponent,
    FinancialSituationRealEstateAdvisorComponent,
    FinancialSituationRealEstateFormComponent,
    FinancialSituationRealEstateComponent,
    FinancialSituationAdvisorOtherFinancialConditionsComponent,
    FinancialSituationSalaryComponent,
    PartnerInfoComponent,
    ChildrenComponent,
    CombineSavingsSummaryComponent,
    CombineSavingsSummaryCombinedComponent,
    CombineSavingsSummaryTransferableByAdvisorComponent,
    CombineSavingsSummaryErrorOccurredComponent,
    CombineSavingsSummaryNotCombinedComponent,
    CombineSavingsSummaryNotTransferableComponent,
    CombineSavingsTransferLeadComponent,
    CombineSavingsIntroComponent,
    CombineSavingsAcceptTransferComponent,
    CombineSavingsBackButtonComponent,
    PolicyCompanyNamePipe,
    FamilyInfoComponent,
    PolicySurrenderChargePipe,
    PfaClimateProfilesComponent,
    ClimatePercentageChooserComponent,
    PfaInvestmentCardComponent,
    TradingStatusComponent,
    ChangeLanguageComponent,
    InvestmentRecommendationComponent,
    InvestmentRecommendationApproveComponent,
    InvestmentRecommendationBasedComponent,
    InvestmentRecommendationClimateComponent,
    InvestmentRecommendationProfileComponent,
    WaitComponent,
    CombineSavingsConsentComponent,
    InvestmentRecommendationIntroComponent,
    InvestmentRecommendationOverviewComponent,
    InvestmentRecommendationOverviewSectionComponent,
    InvestmentRecommendationProfileDetailComponent,
    InvestmentRecommendationClimateDetailComponent,
    InvestmentClimateModalComponent,
    InvestmentReadMoreComponent,
    InvestmentReadMorePfaInvestComponent,
    InvestmentReadMoreYouInvestComponent,
    InvestmentReadMoreClimateComponent,
    SvgInformationComponent,
    SvgInsuranceComponent,
    SvgInvestmentguideComponent,
    SvgSavingsComponent,
    UpdateEmailComponent,
    UpdateEmailPopupComponent,
    SigningCompletedPopupComponent,
    SvgCityComponent,
    SvgTrafficLightYellowComponent,
    SvgTrafficLightsGreenComponent,
    InsuranceGuideComponent,
    InsuranceGuideIntroComponent,
    InsuranceGuideMyInformationStepComponent,
    InsuranceGuideFinancialSituationComponent,
    InsuranceGuideRiskStepComponent,
    InsuranceGuideInsuranceCoverStepComponent,
    InsuranceGuidePensionPlanComponent,
    InsuranceGuideYourFamilyComponent,
    InsuranceGuideBackButtonComponent,
    InsuranceGuideRiskGroupStepComponent,
    InsuranceGuideLocStepComponent,
    InsuranceGuideDebtStepComponent,
    InsuranceGuideRecommendationCardComponent,
    InsuranceGuideRecommendationOccupationalCapacityComponent,
    InsuranceGuideRecommendationCriticalIllnessComponent,
    InsuranceGuideRecommendationLifeComponent,
    InsuranceAdjustmentComponent,
    InsuranceAdjustmentInputCiComponent,
    InsuranceAdjustmentInputLocComponent,
    InsuranceAdjustmentInputLifeComponent,
    InsuranceAdjustmentHealthInformationComponent,
    InsuranceAdjustmentReceiptComponent,
    InsuranceAdjustmentSuperRiskPopupComponent,
    InsuranceAdjustmentAtypicalChoicePopupComponent,
    InsuranceAdjustmentSuperRiskPopupComponent,
    InsuranceAdjustmentAdvisorInfoComponent,
    InsuranceAdjustmentDeleteAdvisorRecPopupComponent,
    BasketOtherItemComponent,
    PensionEstimateFormOfSavingsComponent,
    PensionEstimateFormOfSavingsPopupComponent,
    PensionEstimateSelectComponent
  ],
  providers: [
    FinancialSituationConfigurationService,
    CombineSavingsAcceptTransferService,
    CombineSavingsNavigationService,
    CombineSavingsAcceptTransferFormService,
    CombineSavingsSummaryService,
    InvestmentService,
    InvestmentRecommendationService,
    InsuranceGuideConfigurationService,
    InvestmentGuideService,
    InvestmentGuideConfigurationService,
    InvestmentGuideSavingsService,
    InvestmentGuideRealEstateService,
    InvestmentGuideHasDebtService
  ]
})
export class SharedModule {}
