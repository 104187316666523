/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ServiceInfo } from './serviceInfo';
import { ServiceInfoSchema } from './serviceInfo';
import { RapportAfsnitValg } from './rapportAfsnitValg';
import { RapportAfsnitValgSchema } from './rapportAfsnitValg';
import { Aendring } from './aendring';
import { AendringSchema } from './aendring';
import { ResourceModel } from './resourceModel';
import { ResourceModelSchema } from './resourceModel';
import * as JoiObj from "joi";


    export const MineAendringerSchema = JoiObj.object({
        serviceInfo:
            
        JoiObj.link('#ServiceInfoSchema')

.allow(null)



        ,
        aendringer:
                JoiObj.array().items(
        
        JoiObj.link('#AendringSchema')

        )
        

.required()


        ,
        afgivesHelbredsoplysinger:
            
                    JoiObj.boolean()
                


.required()


        ,
        antalAendredeOmraaderIalt:
            
                    JoiObj.number()
                


.required()


        ,
        dannet:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        dannetTid:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        harGipp:
            
                    JoiObj.boolean()
                


.required()


        ,
        maxAntalUdloebsdage:
            
                    JoiObj.number()
                


.required()


        ,
        nuvaerendeAendringerPersisteret:
            
                    JoiObj.boolean()
                


.required()


        ,
        rapportAfsnitValg:
            
        JoiObj.link('#RapportAfsnitValgSchema')


.required()


        ,
        senesteUdloebsdato:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        spoergeskema:
            
                    JoiObj.boolean()
                


.required()


        ,
        success:
            
                    JoiObj.boolean()
                


.required()


        ,
        udloeber:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        udloeberTid:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        
    })
                .shared(ServiceInfoSchema)
                .shared(AendringSchema)
                .shared(RapportAfsnitValgSchema)
.id('MineAendringerSchema')

export interface MineAendringer extends ResourceModel { 
    aendringer: Array<Aendring>;
    afgivesHelbredsoplysinger: boolean;
    antalAendredeOmraaderIalt: number;
    dannet: string;
    dannetTid: string;
    harGipp: boolean;
    maxAntalUdloebsdage: number;
    nuvaerendeAendringerPersisteret: boolean;
    rapportAfsnitValg: RapportAfsnitValg;
    senesteUdloebsdato: string;
    spoergeskema: boolean;
    success: boolean;
    udloeber: string;
    udloeberTid: string;
}

