import { Component, Input, OnInit } from '@angular/core';
import { MaeglerInfo } from '@pfa/gen';
import {
  ContentUtilService,
  GuideTrackingService,
  GuidePlacement
} from '@pfa/core';
import { BehaviorSubject } from 'rxjs';
import {
  InvestmentGuideName,
  InvestmentGuideTrackName
} from '../investment-guide-configuration.service';
import { InvestmentGuideService } from '@mitpfa/shared';

@Component({
  selector: 'mitpfa-investment-guide-help',
  templateUrl: 'investment-guide-help.component.html',
  styleUrls: ['investment-guide-help.component.scss']
})
export class InvestmentGuideHelpComponent implements OnInit {
  @Input() broker: MaeglerInfo;
  @Input() isInsideCard = false;

  public tooltipText$ = new BehaviorSubject<string>('');

  constructor(
    private readonly contentUtil: ContentUtilService,
    private readonly trackingService: GuideTrackingService,
    private readonly investmentGuideService: InvestmentGuideService
  ) {}

  public ngOnInit(): void {
    if (
      this.broker?.maeglerBetjent &&
      this.broker?.navn &&
      this.broker?.telefon
    ) {
      const tooltipText = this.contentUtil.getContent('DL.EO01.C505', [
        this.broker.navn,
        this.broker.telefon
      ]);
      this.tooltipText$.next(tooltipText);
    } else {
      this.investmentGuideService.getCurrentQuestion$().subscribe(question => {
        if (!question) return;

        const texts = this.getTexts(question.value);
        let tooltipText = '';
        texts.forEach(text => {
          tooltipText += ` ${this.contentUtil.getContent(text)}`;
        });

        this.tooltipText$.next(tooltipText);
      });
    }
  }

  public handleTooltipShow(): void {
    this.trackingService.trackGuideHelp(
      InvestmentGuideName.NAME,
      InvestmentGuideTrackName[
        this.investmentGuideService.getQuestionReached()
      ],
      GuidePlacement.INVESTMENT
    );
  }

  public getTexts(step: string): string[] {
    switch (step) {
      case 'WELCOME':
        return [];
      case 'INVOLVEMENT':
        return [
          'DL.INVGU01.C364',
          'DL.INVGU01.C365',
          'DL.INVGU01.C366',
          'DL.INVGU01.C217',
          'DL.INVGU01.C218',
          'DL.INVGU01.C219',
          'DL.INVGU01.C614'
        ];
      case 'INVESTMENT_KNOWLEDGE':
      case 'TRADING_EXPERIENCE':
      case 'NUMBER_OF_TRADES':
      case 'RETRIEVE_PENSIONINFO':
      case 'SECONDARY_RESIDENCE':
      case 'RECOMENDATION':
        return [
          'DL.INVGU01.C217',
          'DL.INVGU01.C218',
          'DL.INVGU01.C219',
          'DL.INVGU01.C614'
        ];
      case 'OTHER_SAVINGS':
      case 'REAL_ESTATE':
        return [
          'DL.INVGU01.C355',
          'DL.INVGU01.C356',
          'DL.INVGU01.C357',
          'DL.INVGU01.C217',
          'DL.INVGU01.C218',
          'DL.INVGU01.C219',
          'DL.INVGU01.C614'
        ];
      case 'HAS_DEBT':
        return [
          'DL.INVGU01.C358',
          'DL.INVGU01.C359',
          'DL.INVGU01.C360',
          'DL.INVGU01.C217',
          'DL.INVGU01.C218',
          'DL.INVGU01.C219',
          'DL.INVGU01.C614'
        ];
      case 'ECONOMICAL_IMPACT':
        return [
          'DL.INVGU01.C352',
          'DL.INVGU01.C353',
          'DL.INVGU01.C354',
          'DL.INVGU01.C217',
          'DL.INVGU01.C218',
          'DL.INVGU01.C219',
          'DL.INVGU01.C614'
        ];
      case 'RISK_PROFILE':
        return [
          'DL.INVGU01.C345',
          'DL.INVGU01.C346',
          'DL.INVGU01.C347',
          'DL.INVGU01.C217',
          'DL.INVGU01.C218',
          'DL.INVGU01.C219',
          'DL.INVGU01.C614'
        ];
      case 'RISK_WILINGNESS':
        return [
          'DL.INVGU01.C345',
          'DL.INVGU01.C348',
          'DL.INVGU01.C349',
          'DL.INVGU01.C217',
          'DL.INVGU01.C218',
          'DL.INVGU01.C219',
          'DL.INVGU01.C614'
        ];
      case 'LOSSABILITY':
        return [
          'DL.INVGU01.C351',
          'DL.INVGU01.C217',
          'DL.INVGU01.C218',
          'DL.INVGU01.C219',
          'DL.INVGU01.C614'
        ];
      case 'CLIMATE_RELEVANCE':
        return ['DL.INVGU01.C674'];
      case 'CLIMATE_EXTENDED_FOCUS':
        return [
          'DL.INVGU01.C580',
          'DL.INVGU01.C581',
          'DL.INVGU01.C582',
          'DL.INVGU01.C583',
          'DL.INVGU01.C217',
          'DL.INVGU01.C218',
          'DL.INVGU01.C219',
          'DL.INVGU01.C614'
        ];
      case 'CLIMATE_WILINGNESS':
        return [
          'DL.INVGU01.C576',
          'DL.INVGU01.C577',
          'DL.INVGU01.C578',
          'DL.INVGU01.C579',
          'DL.INVGU01.C217',
          'DL.INVGU01.C218',
          'DL.INVGU01.C219',
          'DL.INVGU01.C614'
        ];
      case 'CLIMATE_PRIORITY':
        return [
          'DL.INVGU01.C572',
          'DL.INVGU01.C573',
          'DL.INVGU01.C574',
          'DL.INVGU01.C575',
          'DL.INVGU01.C217',
          'DL.INVGU01.C218',
          'DL.INVGU01.C219',
          'DL.INVGU01.C614'
        ];
      default:
        return [];
    }
  }
}
