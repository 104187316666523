/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const PoliceTypeSchema = JoiObj.string().valid(
        'Gennemsnit'
        ,
        'Marked'
        ,
        'MarkedGennemsnit'
        ,
        'Forsikring'
        
    )
.id('PoliceTypeSchema')

export type PoliceType = 'Gennemsnit' | 'Marked' | 'MarkedGennemsnit' | 'Forsikring';

export const PoliceType = {
    Gennemsnit: 'Gennemsnit' as PoliceType,
    Marked: 'Marked' as PoliceType,
    MarkedGennemsnit: 'MarkedGennemsnit' as PoliceType,
    Forsikring: 'Forsikring' as PoliceType
};

