/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const DynamicAdvisingDeviationTypeSchema = JoiObj.string().valid(
        'none'
        ,
        'customer_confirmed'
        ,
        'advisor_confirmed'
        
    )
.id('DynamicAdvisingDeviationTypeSchema')

export type DynamicAdvisingDeviationType = 'none' | 'customer_confirmed' | 'advisor_confirmed';

export const DynamicAdvisingDeviationType = {
    None: 'none' as DynamicAdvisingDeviationType,
    CustomerConfirmed: 'customer_confirmed' as DynamicAdvisingDeviationType,
    AdvisorConfirmed: 'advisor_confirmed' as DynamicAdvisingDeviationType
};

