import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { CoreModule } from '@pfa/core';
import { PfaFormModule } from '@pfaform';
import { IconModule } from '@pfa/icons';
import { OrderPincodeComponent } from './order-pincode.component';
import { MyCommonModule } from '@pfa/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
  imports: [
    CommonModule,
    MyCommonModule,
    CoreModule,
    IconModule,
    PfaFormModule,
    MatInputModule,
    MatButtonModule,
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule
  ],
  declarations: [OrderPincodeComponent],
  exports: [OrderPincodeComponent]
})
export class OrderPincodeModule {}
