/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Prognose } from './prognose';
import { PrognoseSchema } from './prognose';
import { Udbetalingskategori } from './udbetalingskategori';
import { UdbetalingskategoriSchema } from './udbetalingskategori';
import { FritidArbejdeDaekning } from './fritidArbejdeDaekning';
import { FritidArbejdeDaekningSchema } from './fritidArbejdeDaekning';
import { Skattekode } from './skattekode';
import { SkattekodeSchema } from './skattekode';
import { FormOfPayment } from './formOfPayment';
import { FormOfPaymentSchema } from './formOfPayment';
import { DaekningsType } from './daekningsType';
import { DaekningsTypeSchema } from './daekningsType';
import { VariantId } from './variantId';
import { VariantIdSchema } from './variantId';
import * as JoiObj from "joi";


    export const DaekningSchema = JoiObj.object({
        aegtefaelleCpr:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        antalUdbetalingsAar:
            
                    JoiObj.number()
                .integer()


.required()


        ,
        bankpension:
            
        JoiObj.link('#UdbetalingskategoriSchema')


.required()


        ,
        beloeb:
            
                    JoiObj.number()
                


.required()


        ,
        betingelseParameter:
            
                    JoiObj.number()
                .integer()


.required()


        ,
        boerneUdloebAlder:
            
                    JoiObj.number()
                .integer()


.required()


        ,
        daekningType:
            
        JoiObj.link('#DaekningsTypeSchema')


.required()


        ,
        formOfPayment:
            
        JoiObj.link('#FormOfPaymentSchema')


.required()


        ,
        forsikringSlutDato:
            
                    JoiObj.object()
                


.required()


        ,
        fritidArbejdeDaekning:
            
        JoiObj.link('#FritidArbejdeDaekningSchema')


.required()


        ,
        fromPI:
            
                    JoiObj.boolean()
                


.required()


        ,
        futurePrognosis:
            
                    JoiObj.number()
                

.allow(null)



        ,
        gennemsnitsrenteLivnetPoliceId:
            
                    JoiObj.number()
                .integer()


.required()


        ,
        gennemsnitsrentePolicenr:
            
                    JoiObj.number()
                .integer()


.required()


        ,
        id:
            
                    JoiObj.number()
                .integer()


.required()


        ,
        indbetaltAarTilDato:
            
                    JoiObj.number()
                


.required()


        ,
        laengestTilUdbetalingAlder:
            
                    JoiObj.number()
                .integer()


.required()


        ,
        laengestTilUdbetalingDato:
            
                    JoiObj.object()
                


.required()


        ,
        prognose:
            
        JoiObj.link('#PrognoseSchema')


.required()


        ,
        risikoOphoerAlder:
            
                    JoiObj.number()
                .integer()


.required()


        ,
        samletAegtefaelleBeloeb:
            
                    JoiObj.number()
                


.required()


        ,
        skattekode:
            
        JoiObj.link('#SkattekodeSchema')


.required()


        ,
        tarif:
            
                    JoiObj.number()
                


.required()


        ,
        tidligstUdbetalingFraAlder:
            
                    JoiObj.number()
                .integer()


.required()


        ,
        udbetalingBeskatning51:
            
                    JoiObj.boolean()
                


.required()


        ,
        udbetalingSlutAlder:
            
                    JoiObj.number()
                .integer()


.required()


        ,
        udbetalingSlutDato:
            
                    JoiObj.object()
                


.required()


        ,
        udbetalingStartAlder:
            
                    JoiObj.number()
                .integer()

.allow(null)



        ,
        udbetalingStartDato:
            
                    JoiObj.object()
                


.required()


        ,
        udbetalingsPeriodeTilAegtefaelle:
            
                    JoiObj.number()
                .integer()


.required()


        ,
        udbetalingsforloebId:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        udbetalingsrestperiodeAar:
            
                    JoiObj.number()
                .integer()


.required()


        ,
        underUdbetaling:
            
                    JoiObj.boolean()
                


.required()


        ,
        valgfriFysioterapeutKiropraktor:
            
                    JoiObj.boolean()
                


.required()


        ,
        valgtDaekningBeloeb:
            
                    JoiObj.number()
                


.required()


        ,
        variantId:
            
        JoiObj.link('#VariantIdSchema')


.required()


        ,
        virkemaade:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        
    })
                .shared(UdbetalingskategoriSchema)
                .shared(DaekningsTypeSchema)
                .shared(FormOfPaymentSchema)
                .shared(FritidArbejdeDaekningSchema)
                .shared(PrognoseSchema)
                .shared(SkattekodeSchema)
                .shared(VariantIdSchema)
.id('DaekningSchema')

export interface Daekning { 
    aegtefaelleCpr: string;
    antalUdbetalingsAar: number;
    bankpension: Udbetalingskategori;
    beloeb: number;
    betingelseParameter: number;
    boerneUdloebAlder: number;
    daekningType: DaekningsType;
    formOfPayment: FormOfPayment;
    forsikringSlutDato: object;
    fritidArbejdeDaekning: FritidArbejdeDaekning;
    fromPI: boolean;
    futurePrognosis?: number;
    gennemsnitsrenteLivnetPoliceId: number;
    gennemsnitsrentePolicenr: number;
    id: number;
    indbetaltAarTilDato: number;
    laengestTilUdbetalingAlder: number;
    laengestTilUdbetalingDato: object;
    prognose: Prognose;
    risikoOphoerAlder: number;
    samletAegtefaelleBeloeb: number;
    skattekode: Skattekode;
    tarif: number;
    tidligstUdbetalingFraAlder: number;
    udbetalingBeskatning51: boolean;
    udbetalingSlutAlder: number;
    udbetalingSlutDato: object;
    udbetalingStartAlder?: number | null;
    udbetalingStartDato: object;
    udbetalingsPeriodeTilAegtefaelle: number;
    udbetalingsforloebId?: string | null;
    udbetalingsrestperiodeAar: number;
    underUdbetaling: boolean;
    valgfriFysioterapeutKiropraktor: boolean;
    valgtDaekningBeloeb: number;
    variantId: VariantId;
    virkemaade: string;
}
export namespace Daekning {
}


