/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const UseExternalPolicyTypeSchema = JoiObj.string().valid(
        'SYSTEM_CHOICE_YES'
        ,
        'CUSTOMER_ANSWER_YES'
        ,
        'CUSTOMER_ANSWER_NO'
        ,
        'CUSTOMER_ANSWER_MISSING'
        
    )
.id('UseExternalPolicyTypeSchema')

export type UseExternalPolicyType = 'SYSTEM_CHOICE_YES' | 'CUSTOMER_ANSWER_YES' | 'CUSTOMER_ANSWER_NO' | 'CUSTOMER_ANSWER_MISSING';

export const UseExternalPolicyType = {
    SystemChoiceYes: 'SYSTEM_CHOICE_YES' as UseExternalPolicyType,
    CustomerAnswerYes: 'CUSTOMER_ANSWER_YES' as UseExternalPolicyType,
    CustomerAnswerNo: 'CUSTOMER_ANSWER_NO' as UseExternalPolicyType,
    CustomerAnswerMissing: 'CUSTOMER_ANSWER_MISSING' as UseExternalPolicyType
};

