import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit
} from '@angular/core';
import { Options } from 'ngx-slider-v2';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { currencyValidator, Utils } from '@pfa/utils';

@Component({
  selector: 'mitpfa-wealth-picker',
  templateUrl: './wealth-picker.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WealthPickerComponent implements OnInit {
  @Input() formName: string;
  @Input() form: UntypedFormGroup;
  @Input() equities?: number;
  @Input() bonds?: number;
  @Input() cash?: number;
  @Input() equitiesLabel: string;
  @Input() bondsLabel: string;
  @Input() cashLabel: string;
  @Input() totalLabel: string;
  @Input() sliderOptions: Options;

  wealthPickerForm: UntypedFormGroup;
  totalSavings$: Observable<number>;

  ngOnInit(): void {
    this.initializeForm();
  }

  private initializeForm(): void {
    const currencyValidators = currencyValidator();

    this.wealthPickerForm = new UntypedFormGroup({
      wealthFormGroup: new UntypedFormGroup({
        equities: new UntypedFormControl(
          Math.round(this.equities || 0),
          currencyValidators
        ),
        bonds: new UntypedFormControl(
          Math.round(this.bonds || 0),
          currencyValidators
        ),
        cash: new UntypedFormControl(
          Math.round(this.cash || 0),
          currencyValidators
        )
      })
    });

    this.totalSavings$ =
      this.wealthPickerForm.controls.wealthFormGroup.valueChanges.pipe(
        map(Utils.sumAllObjectValues),
        startWith(
          Utils.sumAllObjectValues(
            this.wealthPickerForm.controls.wealthFormGroup.value
          )
        )
      );

    if (this.form) {
      this.form.addControl(this.formName, this.wealthPickerForm);
    }
  }
}
