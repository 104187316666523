/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const CaseStatusSchema = JoiObj.string().valid(
        'NEW'
        ,
        'IN_PROGRESS'
        ,
        'COMPLETED'
        
    )
.id('CaseStatusSchema')

export type CaseStatus = 'NEW' | 'IN_PROGRESS' | 'COMPLETED';

export const CaseStatus = {
    New: 'NEW' as CaseStatus,
    InProgress: 'IN_PROGRESS' as CaseStatus,
    Completed: 'COMPLETED' as CaseStatus
};

