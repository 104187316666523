import { Injectable } from '@angular/core';
import { ContentUtilService } from '@pfa/core';
import { Utils } from '@pfa/utils';
import { formatNumber } from '@angular/common';
import { isNil } from 'lodash';
import { PensionPlanExtend } from '@pfa/models';

@Injectable({
  providedIn: 'root'
})
export class PensionPlanInfoService {
  constructor(private contentUtil: ContentUtilService) {}

  convertSavings(pensionPlan: PensionPlanExtend) {
    pensionPlan.pension.totalDeposit = 0;
    pensionPlan.pension.totalDepositOwn = 0;

    pensionPlan.pension.policer.forEach(police => {
      if (police.valgt) {
        pensionPlan.pension.totalDeposit += police.aarligIndbetaling;
        pensionPlan.pension.totalDepositOwn += police.egenIndbetaling;
      }
    });

    pensionPlan.pension.depositPercent = !isNil(
      pensionPlan.pension.indbetalingPensionProcent
    )
      ? Utils.formatNumber(pensionPlan.pension.indbetalingPensionProcent, 2)
      : '0';

    const formatRegisterSalery = !isNil(pensionPlan.pension.registreretLoen)
      ? formatNumber(Math.round(pensionPlan.pension.registreretLoen), 'da-DK')
      : '0';
    const formatSecondSalery = !isNil(pensionPlan.pension.beregneAndenLoen)
      ? formatNumber(Math.round(pensionPlan.pension.beregneAndenLoen), 'da-DK')
      : '0';

    pensionPlan.pension.depositAmount =
      formatRegisterSalery !== '0' ? formatRegisterSalery : formatSecondSalery;

    return pensionPlan;
  }

  convertInsurance(pensionPlan: PensionPlanExtend) {
    pensionPlan.forsikring.hasTaxCode5 =
      pensionPlan.forsikring.helbredsDaekninger.some(
        insurance => insurance.forsikringsTalBrutto !== insurance.forsikringsTal
      );

    pensionPlan.forsikring.yearlyAmount = !isNil(pensionPlan.forsikring.ialtTAE)
      ? formatNumber(Math.round(pensionPlan.forsikring.ialtTAE), 'da-DK')
      : '0';
    pensionPlan.forsikring.yearlyPercent = !isNil(
      pensionPlan.forsikring.helbredsTal
    )
      ? Utils.formatNumber(pensionPlan.forsikring.helbredsTal, 0)
      : '0';

    pensionPlan.forsikring.LifePercent = !isNil(pensionPlan.forsikring.livsTal)
      ? Utils.formatNumber(pensionPlan.forsikring.livsTal / 100, 2)
      : '0';

    return pensionPlan;
  }

  translateTooltips(pensionPlan: PensionPlanExtend) {
    pensionPlan.forsikring.livsDaekninger.forEach(insurance => {
      const formatNetAmount = !isNil(insurance.nettoBeloeb)
        ? formatNumber(Math.round(insurance.nettoBeloeb), 'da-DK')
        : '0';
      insurance.netAmountText = this.contentUtil.getContent(
        insurance.nettoBeloebTextId,
        [formatNetAmount]
      );
    });

    return pensionPlan;
  }

  mapPensionData(pensionPlan: PensionPlanExtend): PensionPlanExtend {
    pensionPlan = this.convertSavings(pensionPlan);
    pensionPlan = this.convertInsurance(pensionPlan);
    pensionPlan = this.translateTooltips(pensionPlan);
    return pensionPlan;
  }
}
