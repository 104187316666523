import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PensionsKundeGenerelleData } from '@pfa/gen';

@Component({
  selector: 'mitpfa-pension-check-pensionsinfo',
  templateUrl: './pension-check-pensionsinfo.component.html',
  styleUrls: ['./pension-check-pensionsinfo.component.scss']
})
export class PensionCheckPensionsinfoComponent {
  @Output() close = new EventEmitter<void>();
  @Input() pensionCustomer: PensionsKundeGenerelleData;

  closePensionInfo() {
    this.close.emit();
  }
}
