/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AaopAaok } from './aaopAaok';
import { AaopAaokSchema } from './aaopAaok';
import { Indbetalinger } from './indbetalinger';
import { IndbetalingerSchema } from './indbetalinger';
import { AfregnetPensionsafkastskat } from './afregnetPensionsafkastskat';
import { AfregnetPensionsafkastskatSchema } from './afregnetPensionsafkastskat';
import { VaerdiAfOpsparingUltimo } from './vaerdiAfOpsparingUltimo';
import { VaerdiAfOpsparingUltimoSchema } from './vaerdiAfOpsparingUltimo';
import { Overfoersel } from './overfoersel';
import { OverfoerselSchema } from './overfoersel';
import { ServiceInfo } from './serviceInfo';
import { ServiceInfoSchema } from './serviceInfo';
import { BetalingForsikringer } from './betalingForsikringer';
import { BetalingForsikringerSchema } from './betalingForsikringer';
import { LinieElement } from './linieElement';
import { LinieElementSchema } from './linieElement';
import { EksternDaekning } from './eksternDaekning';
import { EksternDaekningSchema } from './eksternDaekning';
import { BetalingForAdministrationOgService } from './betalingForAdministrationOgService';
import { BetalingForAdministrationOgServiceSchema } from './betalingForAdministrationOgService';
import { VaerdiAfOpsparingPrimo } from './vaerdiAfOpsparingPrimo';
import { VaerdiAfOpsparingPrimoSchema } from './vaerdiAfOpsparingPrimo';
import { Udbetalinger } from './udbetalinger';
import { UdbetalingerSchema } from './udbetalinger';
import { ResourceModel } from './resourceModel';
import { ResourceModelSchema } from './resourceModel';
import { OevrigePoster } from './oevrigePoster';
import { OevrigePosterSchema } from './oevrigePoster';
import { Rente } from './rente';
import { RenteSchema } from './rente';
import * as JoiObj from "joi";


    export const OpsparingPoliceDetaljerSchema = JoiObj.object({
        serviceInfo:
            
        JoiObj.link('#ServiceInfoSchema')

.allow(null)



        ,
        aaopAaok:
            
        JoiObj.link('#AaopAaokSchema')


.required()


        ,
        acontoAskat:
            
                    JoiObj.number()
                

.allow(null)



        ,
        aendringPgaAegtefaellesDoed:
            
                    JoiObj.number()
                

.allow(null)



        ,
        afkast:
            
                    JoiObj.number()
                


.required()


        ,
        afregnetPensionsafkastskat:
            
        JoiObj.link('#AfregnetPensionsafkastskatSchema')

.allow(null)



        ,
        alderspensionInvalidAdvarsel:
            
                    JoiObj.boolean()
                


.required()


        ,
        arbejdsmarkedsbidrag:
            
                    JoiObj.number()
                

.allow(null)



        ,
        betalingForAdministrationOgService:
            
        JoiObj.link('#BetalingForAdministrationOgServiceSchema')


.required()


        ,
        betalingForsikringer:
            
        JoiObj.link('#BetalingForsikringerSchema')


.required()


        ,
        depotaendringVedInvaliditet:
            
                    JoiObj.number()
                

.allow(null)



        ,
        eksternDaekninger:
                JoiObj.array().items(
        
        JoiObj.link('#EksternDaekningSchema')

        )
        

.required()


        ,
        forudbetaltIndbetaling:
            
                    JoiObj.number()
                

.allow(null)



        ,
        forudbetaltIndbetalingTilDato:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        fraAfkastTilDepotrenteUrl:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        fraAfkastTilDepotrenteUrlEngelsk:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        fraDato:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        fremrykketAfgiftAfKapitalpension:
            
                    JoiObj.number()
                

.allow(null)



        ,
        harKundekapitalOpsparing:
            
                    JoiObj.boolean()
                


.required()


        ,
        ikkeAfregnetPensionsafkast:
            
                    JoiObj.number()
                

.allow(null)



        ,
        ikkeBetaltUnderskudsPAL:
            
                    JoiObj.number()
                

.allow(null)



        ,
        indbetalingViaIndbetalingsSikring:
            
                    JoiObj.number()
                

.allow(null)



        ,
        indbetalinger:
            
        JoiObj.link('#IndbetalingerSchema')


.required()


        ,
        individuelAegtefaellepension:
            
                    JoiObj.boolean()
                


.required()


        ,
        kundekapitalOpsparing:
            
                    JoiObj.number()
                


.required()


        ,
        markedgennemsnitsrente:
            
                    JoiObj.boolean()
                


.required()


        ,
        oevrigePoster:
            
        JoiObj.link('#OevrigePosterSchema')


.required()


        ,
        opsparing:
            
                    JoiObj.number()
                


.required()


        ,
        opsparingFritagetForPAL:
            
                    JoiObj.number()
                

.allow(null)



        ,
        opsparinger:
                JoiObj.array().items(
        
        JoiObj.link('#LinieElementSchema')

        )
        

.required()


        ,
        overfoersler:
                JoiObj.array().items(
        
        JoiObj.link('#OverfoerselSchema')

        )
        

.required()


        ,
        overfoertFraKundekap:
            
                    JoiObj.number()
                

.allow(null)



        ,
        policeId:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        rateUdbetalingAdvarsel:
            
                    JoiObj.boolean()
                


.required()


        ,
        realtidForKontoRegnskabTidspunkt:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        rente:
            
        JoiObj.link('#RenteSchema')

.allow(null)



        ,
        reservespringVedInvaliditet:
            
                    JoiObj.number()
                


.required()


        ,
        reservespringVedReaktivering:
            
                    JoiObj.number()
                


.required()


        ,
        risikoOverskudFraKR:
            
                    JoiObj.number()
                

.allow(null)



        ,
        samletVaerdiOpsparingInklusivForudbetaltIndbetaling:
            
                    JoiObj.number()
                

.allow(null)



        ,
        samletVaerdiOpsparingInklusivTilbageFoerselInvalidedepotVedOphoerAfInvaliditet:
            
                    JoiObj.number()
                

.allow(null)



        ,
        tabAfErhvervsevneAdvarsel:
            
                    JoiObj.boolean()
                


.required()


        ,
        tilDato:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        tilbageFoerselInvalidedepotVedOphoerAfInvaliditet:
            
                    JoiObj.number()
                

.allow(null)



        ,
        udbetaling:
            
                    JoiObj.number()
                

.allow(null)



        ,
        udbetalingAegtfaelleEllerSamlever:
            
                    JoiObj.number()
                

.allow(null)



        ,
        udbetalingDoedId:
                JoiObj.array().items(
        
                    JoiObj.number()
                .integer()

        )
        

.required()


        ,
        udbetalinger:
            
        JoiObj.link('#UdbetalingerSchema')

.allow(null)



        ,
        udbetlingOphoerAlder:
            
                    JoiObj.number()
                .integer()

.allow(null)



        ,
        uddybendeBeskrivelseAarstal:
            
                    JoiObj.number()
                .integer()

.allow(null)



        ,
        uddybendeBeskrivelseNovo:
            
                    JoiObj.boolean()
                

.allow(null)



        ,
        vaerdi83:
            
                    JoiObj.number()
                

.allow(null)



        ,
        vaerdiAfOpsparingPrimo:
            
        JoiObj.link('#VaerdiAfOpsparingPrimoSchema')


.required()


        ,
        vaerdiAfOpsparingUltimo:
            
        JoiObj.link('#VaerdiAfOpsparingUltimoSchema')

.allow(null)



        ,
        varighedsFonde:
            
                    JoiObj.number()
                


.required()


        
    })
                .shared(ServiceInfoSchema)
                .shared(AaopAaokSchema)
                .shared(AfregnetPensionsafkastskatSchema)
                .shared(BetalingForAdministrationOgServiceSchema)
                .shared(BetalingForsikringerSchema)
                .shared(EksternDaekningSchema)
                .shared(IndbetalingerSchema)
                .shared(OevrigePosterSchema)
                .shared(LinieElementSchema)
                .shared(OverfoerselSchema)
                .shared(RenteSchema)
                .shared(UdbetalingerSchema)
                .shared(VaerdiAfOpsparingPrimoSchema)
                .shared(VaerdiAfOpsparingUltimoSchema)
.id('OpsparingPoliceDetaljerSchema')

export interface OpsparingPoliceDetaljer extends ResourceModel { 
    aaopAaok: AaopAaok;
    acontoAskat?: number;
    aendringPgaAegtefaellesDoed?: number;
    afkast: number;
    afregnetPensionsafkastskat?: AfregnetPensionsafkastskat;
    alderspensionInvalidAdvarsel: boolean;
    arbejdsmarkedsbidrag?: number;
    betalingForAdministrationOgService: BetalingForAdministrationOgService;
    betalingForsikringer: BetalingForsikringer;
    depotaendringVedInvaliditet?: number;
    eksternDaekninger: Array<EksternDaekning>;
    forudbetaltIndbetaling?: number;
    forudbetaltIndbetalingTilDato?: string;
    fraAfkastTilDepotrenteUrl?: string;
    fraAfkastTilDepotrenteUrlEngelsk?: string;
    fraDato: string;
    fremrykketAfgiftAfKapitalpension?: number;
    harKundekapitalOpsparing: boolean;
    ikkeAfregnetPensionsafkast?: number;
    ikkeBetaltUnderskudsPAL?: number;
    indbetalingViaIndbetalingsSikring?: number;
    indbetalinger: Indbetalinger;
    individuelAegtefaellepension: boolean;
    kundekapitalOpsparing: number;
    markedgennemsnitsrente: boolean;
    oevrigePoster: OevrigePoster;
    opsparing: number;
    opsparingFritagetForPAL?: number;
    opsparinger: Array<LinieElement>;
    overfoersler: Array<Overfoersel>;
    overfoertFraKundekap?: number;
    policeId: string;
    rateUdbetalingAdvarsel: boolean;
    realtidForKontoRegnskabTidspunkt: string;
    rente?: Rente;
    reservespringVedInvaliditet: number;
    reservespringVedReaktivering: number;
    risikoOverskudFraKR?: number;
    samletVaerdiOpsparingInklusivForudbetaltIndbetaling?: number;
    samletVaerdiOpsparingInklusivTilbageFoerselInvalidedepotVedOphoerAfInvaliditet?: number;
    tabAfErhvervsevneAdvarsel: boolean;
    tilDato: string;
    tilbageFoerselInvalidedepotVedOphoerAfInvaliditet?: number;
    udbetaling?: number;
    udbetalingAegtfaelleEllerSamlever?: number;
    udbetalingDoedId: Array<number>;
    udbetalinger?: Udbetalinger;
    udbetlingOphoerAlder?: number;
    uddybendeBeskrivelseAarstal?: number;
    uddybendeBeskrivelseNovo?: boolean;
    vaerdi83?: number;
    vaerdiAfOpsparingPrimo: VaerdiAfOpsparingPrimo;
    vaerdiAfOpsparingUltimo?: VaerdiAfOpsparingUltimo;
    varighedsFonde: number;
}

