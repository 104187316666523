import { Component, Input } from '@angular/core';
import { PensionPlanExtend } from '@pfa/models';

@Component({
  selector: 'co-life-insurance-table',
  templateUrl: 'life-insurance-table.component.html',
  styleUrls: ['./life-insurance-table.component.scss']
})
export class LifeInsuranceTableComponent {
  @Input() pensionPlan: PensionPlanExtend;
}
