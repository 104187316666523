/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const CustomerClassificationSchema = JoiObj.object({
        eclKey:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        eclText:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        lifeCycleKey:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        lifeCycleText:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        serviceProgramKey:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        serviceProgramText:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        serviceSegmentKey:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        serviceSegmentText:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        
    })
.id('CustomerClassificationSchema')

export interface CustomerClassification { 
    eclKey?: string;
    eclText?: string;
    lifeCycleKey?: string;
    lifeCycleText?: string;
    serviceProgramKey?: string;
    serviceProgramText?: string;
    serviceSegmentKey?: string;
    serviceSegmentText?: string;
}

