/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { EksternOverfoerselJobSkifte } from './eksternOverfoerselJobSkifte';
import { EksternOverfoerselJobSkifteSchema } from './eksternOverfoerselJobSkifte';
import { Tegningsmaade } from './tegningsmaade';
import { TegningsmaadeSchema } from './tegningsmaade';
import { OverfoerselBestilt } from './overfoerselBestilt';
import { OverfoerselBestiltSchema } from './overfoerselBestilt';
import { AnbefalingsType } from './anbefalingsType';
import { AnbefalingsTypeSchema } from './anbefalingsType';
import { BooleanType } from './booleanType';
import { BooleanTypeSchema } from './booleanType';
import { EksternKursvaern } from './eksternKursvaern';
import { EksternKursvaernSchema } from './eksternKursvaern';
import { ExternalTransferJobChangeSelection } from './externalTransferJobChangeSelection';
import { ExternalTransferJobChangeSelectionSchema } from './externalTransferJobChangeSelection';
import { TransferableCategoryType } from './transferableCategoryType';
import { TransferableCategoryTypeSchema } from './transferableCategoryType';
import { OpsparingType } from './opsparingType';
import { OpsparingTypeSchema } from './opsparingType';
import { EksternOverfoerselAdvarsel } from './eksternOverfoerselAdvarsel';
import { EksternOverfoerselAdvarselSchema } from './eksternOverfoerselAdvarsel';
import { DelOverfoersel } from './delOverfoersel';
import { DelOverfoerselSchema } from './delOverfoersel';
import * as JoiObj from "joi";


    export const AftaleAnbefalingSchema = JoiObj.object({
        advarsler:
                JoiObj.array().items(
        
        JoiObj.link('#EksternOverfoerselAdvarselSchema')

        )
        

.required()


        ,
        aftaleAnbefalingNy:
            
        JoiObj.link('#AnbefalingsTypeSchema')

.allow(null)



        ,
        anbefaling:
            
        JoiObj.link('#AnbefalingsTypeSchema')


.required()


        ,
        bankoplysninger:
            
        JoiObj.link('#DelOverfoerselSchema')

.allow(null)



        ,
        behold60Ret:
            
        JoiObj.link('#BooleanTypeSchema')

.allow(null)



        ,
        eksternKursvaern:
                JoiObj.array().items(
        
        JoiObj.link('#EksternKursvaernSchema')

        )
        

.required()


        ,
        fravalgAfForsikring:
            
        JoiObj.link('#BooleanTypeSchema')

.allow(null)



        ,
        iKurv:
            
        JoiObj.link('#BooleanTypeSchema')

.allow(null)



        ,
        validerKurv:
            
                    JoiObj.boolean()
                

.allow(null)



        ,
        jobChangeSelection:
            
        JoiObj.link('#ExternalTransferJobChangeSelectionSchema')


.required()


        ,
        jobSkifte:
            
        JoiObj.link('#EksternOverfoerselJobSkifteSchema')

.allow(null)



        ,
        kanOverfoeres:
            
                    JoiObj.boolean()
                


.required()


        ,
        kursvaernaccept:
            
        JoiObj.link('#BooleanTypeSchema')

.allow(null)



        ,
        leverandoerSkifte:
            
        JoiObj.link('#BooleanTypeSchema')

.allow(null)



        ,
        manglerInput:
            
                    JoiObj.boolean()
                


.required()


        ,
        manual:
            
                    JoiObj.boolean()
                


.required()


        ,
        modtagetRaadgivning:
            
        JoiObj.link('#BooleanTypeSchema')

.allow(null)



        ,
        opsparing:
            
                    JoiObj.number()
                


.required()


        ,
        overfoerselBestilt:
            
        JoiObj.link('#OverfoerselBestiltSchema')

.allow(null)



        ,
        overwrittenProdukttype:
            
        JoiObj.link('#OpsparingTypeSchema')

.allow(null)



        ,
        pensionsordning:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        produkttype:
            
        JoiObj.link('#OpsparingTypeSchema')


.required()


        ,
        selskab:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        tegningsmaade:
            
        JoiObj.link('#TegningsmaadeSchema')


.required()


        ,
        tilladValg:
            
                    JoiObj.boolean()
                


.required()


        ,
        transferableCategory:
            
        JoiObj.link('#TransferableCategoryTypeSchema')


.required()


        ,
        uid:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        undertrykAdvarsel:
            
                    JoiObj.boolean()
                


.required()


        ,
        valgt:
            
                    JoiObj.boolean()
                


.required()


        ,
        visFravalgAfForsikring:
            
                    JoiObj.boolean()
                


.required()


        ,
        visJobskifte:
            
                    JoiObj.boolean()
                


.required()


        ,
        visLeverandoerskifte:
            
                    JoiObj.boolean()
                


.required()


        
    })
                .shared(EksternOverfoerselAdvarselSchema)
                .shared(AnbefalingsTypeSchema)
                .shared(AnbefalingsTypeSchema)
                .shared(DelOverfoerselSchema)
                .shared(BooleanTypeSchema)
                .shared(EksternKursvaernSchema)
                .shared(BooleanTypeSchema)
                .shared(BooleanTypeSchema)
                .shared(ExternalTransferJobChangeSelectionSchema)
                .shared(EksternOverfoerselJobSkifteSchema)
                .shared(BooleanTypeSchema)
                .shared(BooleanTypeSchema)
                .shared(BooleanTypeSchema)
                .shared(OverfoerselBestiltSchema)
                .shared(OpsparingTypeSchema)
                .shared(OpsparingTypeSchema)
                .shared(TegningsmaadeSchema)
                .shared(TransferableCategoryTypeSchema)
.id('AftaleAnbefalingSchema')

export interface AftaleAnbefaling { 
    advarsler: Array<EksternOverfoerselAdvarsel>;
    aftaleAnbefalingNy?: AnbefalingsType;
    anbefaling: AnbefalingsType;
    bankoplysninger?: DelOverfoersel;
    behold60Ret?: BooleanType;
    eksternKursvaern: Array<EksternKursvaern>;
    fravalgAfForsikring?: BooleanType;
    iKurv?: BooleanType;
    validerKurv?: boolean;
    jobChangeSelection: ExternalTransferJobChangeSelection;
    jobSkifte?: EksternOverfoerselJobSkifte;
    kanOverfoeres: boolean;
    kursvaernaccept?: BooleanType;
    leverandoerSkifte?: BooleanType;
    manglerInput: boolean;
    manual: boolean;
    modtagetRaadgivning?: BooleanType;
    opsparing: number;
    overfoerselBestilt?: OverfoerselBestilt;
    overwrittenProdukttype?: OpsparingType;
    pensionsordning: string;
    produkttype: OpsparingType;
    selskab: string;
    tegningsmaade: Tegningsmaade;
    tilladValg: boolean;
    transferableCategory: TransferableCategoryType;
    uid?: string;
    undertrykAdvarsel: boolean;
    valgt: boolean;
    visFravalgAfForsikring: boolean;
    visJobskifte: boolean;
    visLeverandoerskifte: boolean;
}
export namespace AftaleAnbefaling {
}


