/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AdvisorCorrectionsOverfoersel } from './advisorCorrectionsOverfoersel';
import { AdvisorCorrectionsOverfoerselSchema } from './advisorCorrectionsOverfoersel';
import * as JoiObj from "joi";


    export const Paragraf41Schema = JoiObj.object({
        aldersopsparing:
            
                    JoiObj.number()
                

.allow(null)



        ,
        indskudArbejdsgiver:
            
                    JoiObj.number()
                

.allow(null)



        ,
        kapitalpension:
            
                    JoiObj.number()
                

.allow(null)



        ,
        livsvarigLivrente:
            
                    JoiObj.number()
                

.allow(null)



        ,
        primaerPolicenr:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        ratepension:
            
                    JoiObj.number()
                

.allow(null)



        ,
        tidsbegraensetLivspension:
            
                    JoiObj.number()
                

.allow(null)



        ,
        aldersopsparingMulige:
            
                    JoiObj.boolean()
                


.required()


        ,
        ialt:
            
                    JoiObj.number()
                

.allow(null)



        ,
        indskudArbejdsgiverMulige:
            
                    JoiObj.boolean()
                


.required()


        ,
        kanSimulereOverfoersel:
            
                    JoiObj.boolean()
                


.required()


        ,
        kapitalpensionMulige:
            
                    JoiObj.boolean()
                


.required()


        ,
        livsvarigLivrenteMulige:
            
                    JoiObj.boolean()
                


.required()


        ,
        ratepensionMulige:
            
                    JoiObj.boolean()
                


.required()


        ,
        tidsbegraensetLivspensionMulige:
            
                    JoiObj.boolean()
                


.required()


        
    })
.id('Paragraf41Schema')

export interface Paragraf41 extends AdvisorCorrectionsOverfoersel { 
    aldersopsparingMulige: boolean;
    ialt?: number;
    indskudArbejdsgiverMulige: boolean;
    kanSimulereOverfoersel: boolean;
    kapitalpensionMulige: boolean;
    livsvarigLivrenteMulige: boolean;
    ratepensionMulige: boolean;
    tidsbegraensetLivspensionMulige: boolean;
}

