<div class="advisor">
  <div
    class="advisor-photo"
    [ngStyle]="{
      'background-image': 'url(' + personalAdvisor?.imageUrl + ')'
    }"
  ></div>
  <div class="advisor-details">
    <h4 class="title" coContent="DL.KO01.C329"></h4>
    <div *ngIf="advantages.programId !== 2">{{ personalAdvisor?.name }}</div>
    <div
      *ngIf="advantages.programId === 2"
      [coContent]="'DL.MF.C14'"
      [coContentSubst]="personalAdvisor?.name"
    ></div>
  </div>
</div>

<div class="contact">
  <h4 class="title advisor-contact-title">&nbsp;</h4>
  <a
    [href]="'tel:' + personalAdvisor?.phone"
    class="action phone"
    (click)="phoneClicked()"
  >
    <co-icon-phone class="icon"></co-icon-phone>
    <span>{{ personalAdvisor?.phone }}</span>
  </a>
  <a class="action" [routerLink]="'/beskeder'" (click)="writeClicked()">
    <co-icon-envelope class="icon"></co-icon-envelope>
    <span coContent="DL.KO01.C319"></span>
  </a>

  <a
    *ngIf="showContactNavLink"
    [routerLink]="'/personligeradgivere'"
    coContent="DL.MF.C26"
    class="action link"
    (click)="contactClicked()"
  ></a>
</div>
<div class="address">
  <h4 class="title" coContent="DL.KO01.C327"></h4>
  <p coContent="DL.KO01.C328"></p>
</div>
