import { Injectable } from '@angular/core';
import { CustomerSurveyComponent } from '../../app/customer-survey/customer-survey/customer-survey.component';
import { PopupStyle } from '@pfa/models';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SurveyAction, SurveyTrackingService } from '@pfa/core';

@Injectable({
  providedIn: 'root'
})
export class CustomerSurveySharedService {
  private surveyDialog: MatDialogRef<CustomerSurveyComponent>;

  constructor(
    private readonly dialog: MatDialog,
    private readonly surveyTrackingService: SurveyTrackingService
  ) {}

  openSurveyDialog(surveyUuid: string): MatDialogRef<CustomerSurveyComponent> {
    this.surveyDialog = this.dialog.open(CustomerSurveyComponent, {
      data: surveyUuid,
      panelClass: PopupStyle.PopupMedium
    });

    this.surveyDialog.afterOpened().subscribe(() => {
      this.surveyTrackingService.trackSurvey(surveyUuid, SurveyAction.INIT);
    });

    this.surveyDialog.afterClosed().subscribe((delay: boolean) => {
      if (delay) {
        this.surveyTrackingService.trackSurvey(surveyUuid, SurveyAction.DELAY);
      } else {
        this.surveyTrackingService.trackSurvey(surveyUuid, SurveyAction.CLOSE);
      }
    });

    return this.surveyDialog;
  }
}
