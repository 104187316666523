import { Component, Input } from '@angular/core';

@Component({
  selector: 'co-notification',
  templateUrl: 'notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent {
  @Input() public amount: number;
}
