<div class="row">
  <div class="col-md-8 offset-md-2">
    <div class="description">
      <mitpfa-pension-info
        *ngIf="pensionCustomer"
        [pensionCustomer]="pensionCustomer"
        [mitIDReauthenticateMethod]="'Redirect'"
        [redirectStepName]="'PensionsInfo'"
        [placement]="'insurance guide'"
        (updatePI)="emitInformationStep.emit()"
      >
      </mitpfa-pension-info>
    </div>
  </div>
</div>

<div class="spacing-unit--double-over align--center">
  <mitpfa-insurance-guide-back-button
    (back)="goBack()"
  ></mitpfa-insurance-guide-back-button>
  <button
    mat-raised-button
    class="button-primary--level3 next-button"
    (click)="goNext()"
    data-test-id="insurance-guide-pension-plan-btn"
  >
    <span>{{ 'DL.FG01.C1393' | content }}</span>
    <co-icon-arrow-right class="icon icon--arrow-right"></co-icon-arrow-right>
  </button>
</div>
