import { Injectable } from '@angular/core';
import { TrackingService } from './tracking.service';

@Injectable()
export class ConsentTrackingService extends TrackingService {
  trackConsentSubscribed(consentName: string): void {
    this.trackConsent(consentName, 'subscribed');
  }

  trackConsentUnsubscribed(consentName: string): void {
    this.trackConsent(consentName, 'unsubscribed');
  }

  private trackConsent(consentName: string, consentAction: string): void {
    const options = {
      consentItems: [
        {
          name: consentName,
          action: consentAction
        }
      ]
    };

    this.dispatchCustomEventTracking('track-consent', options);
  }
}
