/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const AendringsdatatypeSchema = JoiObj.string().valid(
        'TAE_AUTOMATISK_TILPASNING'
        ,
        'TAE_LOEBENDE'
        ,
        'TAE_LOEBENDE_TILLAEG'
        ,
        'TAE_ENGANG'
        ,
        'TAE_ENGANG_TILLAEG'
        ,
        'TAE_SKATTEKODE'
        ,
        'LIV_ENGANG'
        ,
        'LIV_ENGANG_TILLAEG'
        ,
        'LIV_SKATTEKODE'
        ,
        'LIV_BOERN_OBLIGATORISK'
        ,
        'LIV_BOERN_OBLIGATORISK_TILLAEG'
        ,
        'LIV_BOERN_FRIVILLIG'
        ,
        'LIV_BOERN_FRIVILLIG_TILLAEG'
        ,
        'KS_EGEN'
        ,
        'KS_EGEN_TILLAEG'
        ,
        'KS_FORSIKRINGSTAGER'
        ,
        'KS_BOERN_OBLIGATORISK'
        ,
        'KS_BOERN_OBLIGATORISK_TILLAEG'
        ,
        'KS_BOERN_FRIVILLIG'
        ,
        'KS_BOERN_FRIVILLIG_TILLAEG'
        ,
        'KS_BOERN'
        ,
        'HS_EGEN'
        ,
        'HS_BOERN'
        ,
        'AEGTEFAELLE_PENSION'
        ,
        'LIVSVARIG_AEP_UNITLINK'
        ,
        'LIVSVARIG_AEP_FORSIKRING'
        ,
        'LIVSVARIG_AEP_SAMLET'
        ,
        'OPHOER_AEP_UNITLINK'
        ,
        'OPHOER_AEP_FORSIKRING'
        ,
        'OPHOER_AEP_SAMLET'
        ,
        'OPSPARINGSSIKRING'
        ,
        'AEGTEFAELLE_CPR'
        ,
        'VIDEREFOERELSE'
        ,
        'FIRMASKIFT'
        ,
        'KUNDEKAPITAL'
        ,
        'PFA_INVESTERER_PROFILVALGALLE'
        ,
        'PFA_INVESTERER_PROFILVALGFRIE'
        ,
        'PFA_INVESTERER_UDBETALINGSSIKRING'
        ,
        'PFA_INVESTERER_KONCEPTFORDELING_INDBETALING'
        ,
        'DU_INVESTERER_KONCEPTFORDELING_INDBETALING'
        ,
        'PFA_INVESTERER_KONCEPTFORDELING_OPSPARING'
        ,
        'DU_INVESTERER_KONCEPTFORDELING_OPSPARING'
        ,
        'DU_INVESTERER_AUTOMATIK'
        ,
        'DU_INVESTERER_INDBETALING_FONDSVALG'
        ,
        'DU_INVESTERER_OPSPARING_FONDSVALG'
        ,
        'PFA_VALGFRI'
        ,
        'KLIMA_PLUS_ANDEL'
        ,
        'MERINDBETALING'
        ,
        'INDBETALINGSSIKRING'
        ,
        'OPSPARINGSFORM'
        ,
        'INDBETALING_ANDRE_POLICER_RATE'
        ,
        'MAX_INDBETALING_RATE'
        ,
        'INDBETALING_ANDRE_POLICER_ALDERSOPSPARING'
        ,
        'MAX_INDBETALING_ALDERSOPSPARING'
        ,
        'OBLIGATORISKINDBETALING'
        ,
        'ARBEJDSGIVERINDBETALING'
        ,
        'RENOPSPARING_INDBETALINGSSIKRING'
        ,
        'PENSIONSALDER'
        ,
        'INDBETALERID'
        ,
        'RATE_PERIODE'
        ,
        'PARAGRAF41_OVERFOERSEL'
        ,
        'OENSKES_SIKRING_AF_EFTERLADTE'
        ,
        'UDBETALINGS_SCENARIER'
        ,
        'INDBETALING_STOP_DATO'
        ,
        'NUVARENDE_ALDER'
        ,
        'PRAEMIEOPHOER_DATO'
        ,
        'AFKAST_SCENARIER_OENSKES'
        
    )
.id('AendringsdatatypeSchema')

export type Aendringsdatatype = 'TAE_AUTOMATISK_TILPASNING' | 'TAE_LOEBENDE' | 'TAE_LOEBENDE_TILLAEG' | 'TAE_ENGANG' | 'TAE_ENGANG_TILLAEG' | 'TAE_SKATTEKODE' | 'LIV_ENGANG' | 'LIV_ENGANG_TILLAEG' | 'LIV_SKATTEKODE' | 'LIV_BOERN_OBLIGATORISK' | 'LIV_BOERN_OBLIGATORISK_TILLAEG' | 'LIV_BOERN_FRIVILLIG' | 'LIV_BOERN_FRIVILLIG_TILLAEG' | 'KS_EGEN' | 'KS_EGEN_TILLAEG' | 'KS_FORSIKRINGSTAGER' | 'KS_BOERN_OBLIGATORISK' | 'KS_BOERN_OBLIGATORISK_TILLAEG' | 'KS_BOERN_FRIVILLIG' | 'KS_BOERN_FRIVILLIG_TILLAEG' | 'KS_BOERN' | 'HS_EGEN' | 'HS_BOERN' | 'AEGTEFAELLE_PENSION' | 'LIVSVARIG_AEP_UNITLINK' | 'LIVSVARIG_AEP_FORSIKRING' | 'LIVSVARIG_AEP_SAMLET' | 'OPHOER_AEP_UNITLINK' | 'OPHOER_AEP_FORSIKRING' | 'OPHOER_AEP_SAMLET' | 'OPSPARINGSSIKRING' | 'AEGTEFAELLE_CPR' | 'VIDEREFOERELSE' | 'FIRMASKIFT' | 'KUNDEKAPITAL' | 'PFA_INVESTERER_PROFILVALGALLE' | 'PFA_INVESTERER_PROFILVALGFRIE' | 'PFA_INVESTERER_UDBETALINGSSIKRING' | 'PFA_INVESTERER_KONCEPTFORDELING_INDBETALING' | 'DU_INVESTERER_KONCEPTFORDELING_INDBETALING' | 'PFA_INVESTERER_KONCEPTFORDELING_OPSPARING' | 'DU_INVESTERER_KONCEPTFORDELING_OPSPARING' | 'DU_INVESTERER_AUTOMATIK' | 'DU_INVESTERER_INDBETALING_FONDSVALG' | 'DU_INVESTERER_OPSPARING_FONDSVALG' | 'PFA_VALGFRI' | 'KLIMA_PLUS_ANDEL' | 'MERINDBETALING' | 'INDBETALINGSSIKRING' | 'OPSPARINGSFORM' | 'INDBETALING_ANDRE_POLICER_RATE' | 'MAX_INDBETALING_RATE' | 'INDBETALING_ANDRE_POLICER_ALDERSOPSPARING' | 'MAX_INDBETALING_ALDERSOPSPARING' | 'OBLIGATORISKINDBETALING' | 'ARBEJDSGIVERINDBETALING' | 'RENOPSPARING_INDBETALINGSSIKRING' | 'PENSIONSALDER' | 'INDBETALERID' | 'RATE_PERIODE' | 'PARAGRAF41_OVERFOERSEL' | 'OENSKES_SIKRING_AF_EFTERLADTE' | 'UDBETALINGS_SCENARIER' | 'INDBETALING_STOP_DATO' | 'NUVARENDE_ALDER' | 'PRAEMIEOPHOER_DATO' | 'AFKAST_SCENARIER_OENSKES';

export const Aendringsdatatype = {
    TaeAutomatiskTilpasning: 'TAE_AUTOMATISK_TILPASNING' as Aendringsdatatype,
    TaeLoebende: 'TAE_LOEBENDE' as Aendringsdatatype,
    TaeLoebendeTillaeg: 'TAE_LOEBENDE_TILLAEG' as Aendringsdatatype,
    TaeEngang: 'TAE_ENGANG' as Aendringsdatatype,
    TaeEngangTillaeg: 'TAE_ENGANG_TILLAEG' as Aendringsdatatype,
    TaeSkattekode: 'TAE_SKATTEKODE' as Aendringsdatatype,
    LivEngang: 'LIV_ENGANG' as Aendringsdatatype,
    LivEngangTillaeg: 'LIV_ENGANG_TILLAEG' as Aendringsdatatype,
    LivSkattekode: 'LIV_SKATTEKODE' as Aendringsdatatype,
    LivBoernObligatorisk: 'LIV_BOERN_OBLIGATORISK' as Aendringsdatatype,
    LivBoernObligatoriskTillaeg: 'LIV_BOERN_OBLIGATORISK_TILLAEG' as Aendringsdatatype,
    LivBoernFrivillig: 'LIV_BOERN_FRIVILLIG' as Aendringsdatatype,
    LivBoernFrivilligTillaeg: 'LIV_BOERN_FRIVILLIG_TILLAEG' as Aendringsdatatype,
    KsEgen: 'KS_EGEN' as Aendringsdatatype,
    KsEgenTillaeg: 'KS_EGEN_TILLAEG' as Aendringsdatatype,
    KsForsikringstager: 'KS_FORSIKRINGSTAGER' as Aendringsdatatype,
    KsBoernObligatorisk: 'KS_BOERN_OBLIGATORISK' as Aendringsdatatype,
    KsBoernObligatoriskTillaeg: 'KS_BOERN_OBLIGATORISK_TILLAEG' as Aendringsdatatype,
    KsBoernFrivillig: 'KS_BOERN_FRIVILLIG' as Aendringsdatatype,
    KsBoernFrivilligTillaeg: 'KS_BOERN_FRIVILLIG_TILLAEG' as Aendringsdatatype,
    KsBoern: 'KS_BOERN' as Aendringsdatatype,
    HsEgen: 'HS_EGEN' as Aendringsdatatype,
    HsBoern: 'HS_BOERN' as Aendringsdatatype,
    AegtefaellePension: 'AEGTEFAELLE_PENSION' as Aendringsdatatype,
    LivsvarigAepUnitlink: 'LIVSVARIG_AEP_UNITLINK' as Aendringsdatatype,
    LivsvarigAepForsikring: 'LIVSVARIG_AEP_FORSIKRING' as Aendringsdatatype,
    LivsvarigAepSamlet: 'LIVSVARIG_AEP_SAMLET' as Aendringsdatatype,
    OphoerAepUnitlink: 'OPHOER_AEP_UNITLINK' as Aendringsdatatype,
    OphoerAepForsikring: 'OPHOER_AEP_FORSIKRING' as Aendringsdatatype,
    OphoerAepSamlet: 'OPHOER_AEP_SAMLET' as Aendringsdatatype,
    Opsparingssikring: 'OPSPARINGSSIKRING' as Aendringsdatatype,
    AegtefaelleCpr: 'AEGTEFAELLE_CPR' as Aendringsdatatype,
    Viderefoerelse: 'VIDEREFOERELSE' as Aendringsdatatype,
    Firmaskift: 'FIRMASKIFT' as Aendringsdatatype,
    Kundekapital: 'KUNDEKAPITAL' as Aendringsdatatype,
    PfaInvestererProfilvalgalle: 'PFA_INVESTERER_PROFILVALGALLE' as Aendringsdatatype,
    PfaInvestererProfilvalgfrie: 'PFA_INVESTERER_PROFILVALGFRIE' as Aendringsdatatype,
    PfaInvestererUdbetalingssikring: 'PFA_INVESTERER_UDBETALINGSSIKRING' as Aendringsdatatype,
    PfaInvestererKonceptfordelingIndbetaling: 'PFA_INVESTERER_KONCEPTFORDELING_INDBETALING' as Aendringsdatatype,
    DuInvestererKonceptfordelingIndbetaling: 'DU_INVESTERER_KONCEPTFORDELING_INDBETALING' as Aendringsdatatype,
    PfaInvestererKonceptfordelingOpsparing: 'PFA_INVESTERER_KONCEPTFORDELING_OPSPARING' as Aendringsdatatype,
    DuInvestererKonceptfordelingOpsparing: 'DU_INVESTERER_KONCEPTFORDELING_OPSPARING' as Aendringsdatatype,
    DuInvestererAutomatik: 'DU_INVESTERER_AUTOMATIK' as Aendringsdatatype,
    DuInvestererIndbetalingFondsvalg: 'DU_INVESTERER_INDBETALING_FONDSVALG' as Aendringsdatatype,
    DuInvestererOpsparingFondsvalg: 'DU_INVESTERER_OPSPARING_FONDSVALG' as Aendringsdatatype,
    PfaValgfri: 'PFA_VALGFRI' as Aendringsdatatype,
    KlimaPlusAndel: 'KLIMA_PLUS_ANDEL' as Aendringsdatatype,
    Merindbetaling: 'MERINDBETALING' as Aendringsdatatype,
    Indbetalingssikring: 'INDBETALINGSSIKRING' as Aendringsdatatype,
    Opsparingsform: 'OPSPARINGSFORM' as Aendringsdatatype,
    IndbetalingAndrePolicerRate: 'INDBETALING_ANDRE_POLICER_RATE' as Aendringsdatatype,
    MaxIndbetalingRate: 'MAX_INDBETALING_RATE' as Aendringsdatatype,
    IndbetalingAndrePolicerAldersopsparing: 'INDBETALING_ANDRE_POLICER_ALDERSOPSPARING' as Aendringsdatatype,
    MaxIndbetalingAldersopsparing: 'MAX_INDBETALING_ALDERSOPSPARING' as Aendringsdatatype,
    Obligatoriskindbetaling: 'OBLIGATORISKINDBETALING' as Aendringsdatatype,
    Arbejdsgiverindbetaling: 'ARBEJDSGIVERINDBETALING' as Aendringsdatatype,
    RenopsparingIndbetalingssikring: 'RENOPSPARING_INDBETALINGSSIKRING' as Aendringsdatatype,
    Pensionsalder: 'PENSIONSALDER' as Aendringsdatatype,
    Indbetalerid: 'INDBETALERID' as Aendringsdatatype,
    RatePeriode: 'RATE_PERIODE' as Aendringsdatatype,
    Paragraf41Overfoersel: 'PARAGRAF41_OVERFOERSEL' as Aendringsdatatype,
    OenskesSikringAfEfterladte: 'OENSKES_SIKRING_AF_EFTERLADTE' as Aendringsdatatype,
    UdbetalingsScenarier: 'UDBETALINGS_SCENARIER' as Aendringsdatatype,
    IndbetalingStopDato: 'INDBETALING_STOP_DATO' as Aendringsdatatype,
    NuvarendeAlder: 'NUVARENDE_ALDER' as Aendringsdatatype,
    PraemieophoerDato: 'PRAEMIEOPHOER_DATO' as Aendringsdatatype,
    AfkastScenarierOenskes: 'AFKAST_SCENARIER_OENSKES' as Aendringsdatatype
};

