import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {
  OpsparetVaerdi,
  PartnerDateOfBirthConsistencyCheck,
  PensionsInfoOrdninger,
  PensionsplanStore,
  PensionsplanTile
} from '@pfa/gen';

@Injectable({
  providedIn: 'root'
})
export class PensionPlanApiService {
  constructor(
    private http: HttpClient,
    private pensionsplanStore: PensionsplanStore
  ) {}

  getPensionPlanTile(): Observable<PensionsplanTile> {
    return this.pensionsplanStore.pensionsplanTileGet();
  }

  getPolice(): Observable<PensionsInfoOrdninger> {
    const url = '/ds/api/pensionsinfo/aftaler';
    return this.http.get<PensionsInfoOrdninger>(url);
  }

  wealth(): Observable<any> {
    const url = '/ds/api/pensionsinfo/wealth';
    return this.http.get(url);
  }

  getPIUpdate(): Observable<string> {
    const url = '/ds/api/pensionsinfo/opdaterPensionsInfo';
    return this.http.get(url, { responseType: 'text' });
  }

  getPartnerDateOfBirthConsistencyCheck(): Observable<PartnerDateOfBirthConsistencyCheck> {
    const url = '/ds/api/pensionsinfo/partnerdateofbirthconsistencycheck';
    return this.http.get<PartnerDateOfBirthConsistencyCheck>(url);
  }

  getPensionInfoSavedValues(): Observable<OpsparetVaerdi> {
    const url = '/ds/api/pensionsinfo/opsparetvaerdi';
    return this.http.get<OpsparetVaerdi>(url);
  }

  orderChange(mobileNumberUpdate: any) {
    return this.pensionsplanStore.pensionsplanKontaktPost(mobileNumberUpdate);
  }
}
