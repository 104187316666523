/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const SignatureIframeSchema = JoiObj.object({
        iframesrc:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        revisionKey:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    .max(36)


.required()


        ,
        script:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        
    })
.id('SignatureIframeSchema')

export interface SignatureIframe { 
    iframesrc: string;
    revisionKey: string;
    script: string;
}

