import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import {RestCacheEntry} from "./RestCacheEntry";
import {GenericStore} from "./GenericStore";
import * as JoiObj from "joi";

import {
        DynamicAdvisingCalculation,
        DynamicAdvisingCalculationSchema,
        PensionPlanInfoResponse,
        PensionPlanInfoResponseSchema
} from '../model/models';

import { DynamicadvisingService } from './dynamicadvising.service';


@Injectable({
    providedIn: 'root'
})
export class DynamicadvisingStore extends GenericStore {

            private dynamicadvisingpensionestimateIndexCache: Map<string, RestCacheEntry<PensionPlanInfoResponse>> = new Map<string, RestCacheEntry<PensionPlanInfoResponse>>();
            private dynamicadvisingIndexCache: Map<string, RestCacheEntry<DynamicAdvisingCalculation>> = new Map<string, RestCacheEntry<DynamicAdvisingCalculation>>();

    constructor(private dynamicadvisingService: DynamicadvisingService) {
        super();
    }

    invalidateAll(): void {
                
                this.invalidatedynamicadvisingIndex();
                
                this.invalidatedynamicadvisingpensionestimateIndex();
    }


                public dynamicadvisingGet() : Observable<DynamicAdvisingCalculation> {
                    let hash = "0";
                    if (this.dynamicadvisingIndexCache.has(hash)) {
                        return this.dynamicadvisingIndexCache.get(hash)!.observable;
                    } else {
                        let dynamicadvisingIndexSubject: BehaviorSubject<0> = new BehaviorSubject(0);
                        let dynamicadvisingIndex = this.initiateStream(dynamicadvisingIndexSubject, this.dynamicadvisingService.dynamicadvisingGet(), DynamicAdvisingCalculationSchema);
                        this.dynamicadvisingIndexCache.set(hash, new RestCacheEntry<DynamicAdvisingCalculation>(dynamicadvisingIndex, dynamicadvisingIndexSubject));
                        return dynamicadvisingIndex;
                    }
                }

                public invalidatedynamicadvisingIndex() {
                    this.dynamicadvisingIndexCache.forEach((cacheEntry) => {
                        cacheEntry.subject.next(0);
                    })
                }






                public dynamicadvisingPensionestimateGet() : Observable<PensionPlanInfoResponse> {
                    let hash = "0";
                    if (this.dynamicadvisingpensionestimateIndexCache.has(hash)) {
                        return this.dynamicadvisingpensionestimateIndexCache.get(hash)!.observable;
                    } else {
                        let dynamicadvisingpensionestimateIndexSubject: BehaviorSubject<0> = new BehaviorSubject(0);
                        let dynamicadvisingpensionestimateIndex = this.initiateStream(dynamicadvisingpensionestimateIndexSubject, this.dynamicadvisingService.dynamicadvisingPensionestimateGet(), PensionPlanInfoResponseSchema);
                        this.dynamicadvisingpensionestimateIndexCache.set(hash, new RestCacheEntry<PensionPlanInfoResponse>(dynamicadvisingpensionestimateIndex, dynamicadvisingpensionestimateIndexSubject));
                        return dynamicadvisingpensionestimateIndex;
                    }
                }

                public invalidatedynamicadvisingpensionestimateIndex() {
                    this.dynamicadvisingpensionestimateIndexCache.forEach((cacheEntry) => {
                        cacheEntry.subject.next(0);
                    })
                }






}
