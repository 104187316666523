/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ServiceInfo } from './serviceInfo';
import { ServiceInfoSchema } from './serviceInfo';
import { Aktiv } from './aktiv';
import { AktivSchema } from './aktiv';
import { ResourceModel } from './resourceModel';
import { ResourceModelSchema } from './resourceModel';
import { Bolig } from './bolig';
import { BoligSchema } from './bolig';
import * as JoiObj from "joi";


    export const FrieMidlerSchema = JoiObj.object({
        serviceInfo:
            
        JoiObj.link('#ServiceInfoSchema')

.allow(null)



        ,
        aktierLoebendeOpsparing:
            
                    JoiObj.number()
                


.required()


        ,
        aktierSamletVaerdi:
            
                    JoiObj.number()
                


.required()


        ,
        aktivList:
                JoiObj.array().items(
        
        JoiObj.link('#AktivSchema')

        )
        

.required()


        ,
        andelFormue:
            
                    JoiObj.number()
                


.required()


        ,
        bankgaeld:
            
                    JoiObj.number()
                


.required()


        ,
        bankgaeldIdag:
            
                    JoiObj.number()
                


.required()


        ,
        boligValgt:
            
                    JoiObj.boolean()
                


.required()


        ,
        boliger:
                JoiObj.array().items(
        
        JoiObj.link('#BoligSchema')

        )
        

.required()


        ,
        egenVirksomhed:
            
                    JoiObj.boolean()
                


.required()


        ,
        formueVedPension:
            
                    JoiObj.boolean()
                


.required()


        ,
        gaeldDaekkes:
            
                    JoiObj.number()
                


.required()


        ,
        gaeldIBank:
            
                    JoiObj.boolean()
                


.required()


        ,
        gaeldIBankIdag:
            
                    JoiObj.boolean()
                


.required()


        ,
        gaeldSikres:
            
                    JoiObj.boolean()
                


.required()


        ,
        investeringsejendomme:
            
                    JoiObj.boolean()
                


.required()


        ,
        kontanterLoebendeOpsparing:
            
                    JoiObj.number()
                


.required()


        ,
        kontanterSamletVaerdi:
            
                    JoiObj.number()
                


.required()


        ,
        obligationerLoebendeOpsparing:
            
                    JoiObj.number()
                


.required()


        ,
        obligationerSamletVaerdi:
            
                    JoiObj.number()
                


.required()


        ,
        opsparingIBank:
            
                    JoiObj.boolean()
                


.required()


        ,
        partnersGaeldDaekkes:
            
                    JoiObj.number()
                


.required()


        ,
        partnersGaeldSikres:
            
                    JoiObj.boolean()
                


.required()


        
    })
                .shared(ServiceInfoSchema)
                .shared(AktivSchema)
                .shared(BoligSchema)
.id('FrieMidlerSchema')

export interface FrieMidler extends ResourceModel { 
    aktierLoebendeOpsparing: number;
    aktierSamletVaerdi: number;
    aktivList: Array<Aktiv>;
    andelFormue: number;
    bankgaeld: number;
    bankgaeldIdag: number;
    boligValgt: boolean;
    boliger: Array<Bolig>;
    egenVirksomhed: boolean;
    formueVedPension: boolean;
    gaeldDaekkes: number;
    gaeldIBank: boolean;
    gaeldIBankIdag: boolean;
    gaeldSikres: boolean;
    investeringsejendomme: boolean;
    kontanterLoebendeOpsparing: number;
    kontanterSamletVaerdi: number;
    obligationerLoebendeOpsparing: number;
    obligationerSamletVaerdi: number;
    opsparingIBank: boolean;
    partnersGaeldDaekkes: number;
    partnersGaeldSikres: boolean;
}

