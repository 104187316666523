import { Component, Input, OnInit } from '@angular/core';
import { PensionsKundeGenerelleData } from '@pfa/gen';
import { PensionInfoRetrieveInfoService } from './pension-info-retrieve-info.service';

@Component({
  selector: 'mitpfa-pension-info-retrieve-info',
  templateUrl: './pension-info-retrieve-info.component.html',
  styleUrls: ['./pension-info-retrieve-info.component.scss']
})
export class PensionInfoRetrieveInfoComponent implements OnInit {
  @Input() pensionCustomer: PensionsKundeGenerelleData;
  public piTextId: string;

  constructor(
    private readonly pensionInfoRetrieveInfoService: PensionInfoRetrieveInfoService
  ) {}

  ngOnInit() {
    this.piTextId = this.pensionInfoRetrieveInfoService.checkPIText(
      this.pensionCustomer
    );
  }
}
