import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { LightUtils } from '../utils/light-utils';
import { CustomerprofileStore } from '@pfa/gen';

@Injectable({
  providedIn: 'root'
})
export class UserAppProfileService {
  constructor(private readonly customerprofileStore: CustomerprofileStore) {}

  isLightProfile() {
    return this.customerprofileStore
      .customerprofileGet()
      .pipe(map(profile => LightUtils.isLightProfile(profile)));
  }
}
