import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stripHtml'
})
export class StripHtmlPipe implements PipeTransform {
  transform(value: string, ...args: unknown[]): unknown {
    return this.stripHtml(value);
  }

  private stripHtml(text: string): string {
    return text ? String(text).replace(/<[^>]+>/gm, '') : '';
  }
}
