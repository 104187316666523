/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ServiceInfo } from './serviceInfo';
import { ServiceInfoSchema } from './serviceInfo';
import { FrieMidler } from './frieMidler';
import { FrieMidlerSchema } from './frieMidler';
import { ESkat } from './eSkat';
import { ESkatSchema } from './eSkat';
import { ResourceModel } from './resourceModel';
import { ResourceModelSchema } from './resourceModel';
import { Fradrag } from './fradrag';
import { FradragSchema } from './fradrag';
import { TaeNytte } from './taeNytte';
import { TaeNytteSchema } from './taeNytte';
import * as JoiObj from "joi";


    export const OekonomiDetaljerSchema = JoiObj.object({
        serviceInfo:
            
        JoiObj.link('#ServiceInfoSchema')

.allow(null)



        ,
        beregneAndenLoen:
            
                    JoiObj.number()
                


.required()


        ,
        eSkat:
            
        JoiObj.link('#ESkatSchema')


.required()


        ,
        fradrager:
                JoiObj.array().items(
        
        JoiObj.link('#FradragSchema')

        )
        

.required()


        ,
        frieMidler:
            
        JoiObj.link('#FrieMidlerSchema')


.required()


        ,
        loenTilIndbetaling:
            
                    JoiObj.number()
                


.required()


        ,
        registreretLoen:
            
                    JoiObj.number()
                


.required()


        ,
        taeBetalingsvillig:
            
                    JoiObj.boolean()
                


.required()


        ,
        taeNytte:
            
        JoiObj.link('#TaeNytteSchema')


.required()


        
    })
                .shared(ServiceInfoSchema)
                .shared(ESkatSchema)
                .shared(FradragSchema)
                .shared(FrieMidlerSchema)
                .shared(TaeNytteSchema)
.id('OekonomiDetaljerSchema')

export interface OekonomiDetaljer extends ResourceModel { 
    beregneAndenLoen: number;
    eSkat: ESkat;
    fradrager: Array<Fradrag>;
    frieMidler: FrieMidler;
    loenTilIndbetaling: number;
    registreretLoen: number;
    taeBetalingsvillig: boolean;
    taeNytte: TaeNytte;
}
export namespace OekonomiDetaljer {
}


