/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const QuotationUpdateSchema = JoiObj.object({
        amount:
            
                    JoiObj.number()
                


.required()


        ,
        comment:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        currencyCode:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        mlDocExpectedDate:
            
                    JoiObj.object()
                


.required()


        ,
        mlDocMissingComment:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        originOfMoneyComment:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        originOfMoneySelectedList:
                JoiObj.array().items(
        
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

        )
        

.required()


        ,
        policyId:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        quotationGUID:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        
    })
.id('QuotationUpdateSchema')

export interface QuotationUpdate { 
    amount: number;
    comment: string;
    currencyCode: string;
    mlDocExpectedDate: object;
    mlDocMissingComment: string;
    originOfMoneyComment: string;
    originOfMoneySelectedList: Array<string>;
    policyId: string;
    quotationGUID: string;
}

