<div class="wrapper">
  <co-card-animated-stack [activeIndex]="activeCard">
    <co-card-animated-stack-item class="small">
      <co-card [allowOverflow]="true">
        <div class="container">
          <ng-container *ngTemplateOutlet="firstCard"> </ng-container>
        </div>
      </co-card>
    </co-card-animated-stack-item>
    <co-card-animated-stack-item class="small">
      <co-card [allowOverflow]="true">
        <ng-container *ngTemplateOutlet="secondCard"> </ng-container>
      </co-card>
    </co-card-animated-stack-item>

    <co-card-animated-stack-item
      *ngFor="let backgroundCard of backgroundCards"
      class="small"
    >
      <co-card></co-card>
    </co-card-animated-stack-item>
  </co-card-animated-stack>

  <div class="done-box-wrapper" *ngIf="initialized">
    <div
      class="done-box"
      *ngFor="let doneCard of backgroundDoneCards"
      [ngStyle]="{ width: width }"
    >
      <div class="done-checkmark"></div>
    </div>
  </div>

  <div
    class="overlay-static"
    [ngClass]="{
      'zero--done-card': doneCards === 0,
      'one--done-card': doneCards === 1,
      'two--done-card': doneCards === 2,
      'tree--done-card': doneCards === 3
    }"
  >
    <div class="overlay"></div>
    <div class="overlay-ok"></div>
  </div>

  <div class="fadebox"></div>
</div>
