import { HttpErrorResponse } from '@angular/common/http';

export const PincodeError = {
  PincodeInactive: 'FBTUPD117E',
  IncorrectPin: 'FBTUPD101E',
  PincodeTooManyFailedAttempts: 'FBTUPD102E',
  PincodeTooManyFailedAttemptsRetried: 'FBTAUT017E',
  SMSTooManyFailedAttempts: 'OTP-MAX-TRIES',
  LockedInPro: 'FBTUPD119E',
  MobileMissing: 'OTP_MOBILE_NUMBER_MISSING',
  InvalidSMSCode: 'OTP-BAD'
};

export const PincodeErrorText = {
  [PincodeError.IncorrectPin]: 'DL.LI01.C117',
  [PincodeError.PincodeTooManyFailedAttempts]: 'DL.LI01.C114',
  [PincodeError.LockedInPro]: 'DL.LI01.C138',
  [PincodeError.PincodeInactive]: 'DL.LI01.C137',
  [PincodeError.SMSTooManyFailedAttempts]: 'DL.LI01.C128',
  [PincodeError.InvalidSMSCode]: 'DL.LI01.C135',
  [PincodeError.MobileMissing]: 'DL.LI01.C134',
  General: 'DL.LI01.C15'
};

export interface AuthHttpErrorResponse extends HttpErrorResponse {
  exceptionMsg?: string;
}
