<form
  *ngIf="contactInfoForm"
  [formGroup]="contactInfoForm"
  class="form form--contact-info"
>
  <div class="form-row"><h2 [coContent]="'DL.BE02.C1019'"></h2></div>
  <div
    class="form-row"
    [ngClass]="{
      'field-error':
        contactInfoForm.controls['phoneCountryCode']?.invalid &&
        (!contactInfoForm.controls['phoneCountryCode']?.pristine ||
          enableAllValidationErrors)
    }"
  >
    <div class="form-row--phone">
      <div class="input__title">
        <label for="phoneCountryCode" [coContent]="'DL.BE02.C1020'"></label>
      </div>
      <div>
        <input
          size="6"
          maxlength="6"
          [formControlName]="'phoneCountryCode'"
          type="text"
          placeholder=""
        />
      </div>
    </div>
    <div
      class="form-row--phone margin-left"
      [ngClass]="{
        'field-error':
          contactInfoForm.controls['phoneNumber']?.invalid &&
          (!contactInfoForm.controls['phoneNumber']?.pristine ||
            enableAllValidationErrors)
      }"
    >
      <div class="input__title">
        <label for="phoneNumber" [coContent]="'DL.BE02.C1021'"></label>
      </div>
      <div>
        <input
          size="19"
          [formControlName]="'phoneNumber'"
          type="text"
          placeholder="Telefonnummer"
        />
      </div>
    </div>
    <div
      class="field-error"
      *ngIf="
        (contactInfoForm.controls['phoneCountryCode']?.invalid ||
          contactInfoForm.controls['phoneNumber']?.invalid) &&
        (!contactInfoForm.controls['phoneCountryCode']?.pristine ||
          !contactInfoForm.controls['phoneNumber']?.pristine ||
          enableAllValidationErrors)
      "
    >
      <span coContent="DL.BE02.C1013"></span>
    </div>
  </div>
  <div
    class="form-row"
    [ngClass]="{
      'field-error':
        contactInfoForm.controls['email']?.invalid &&
        (!contactInfoForm.controls['email']?.pristine ||
          enableAllValidationErrors)
    }"
  >
    <div class="input__title">
      <label for="email" [coContent]="'DL.BE02.C1022'"></label><span>(*)</span>
    </div>
    <div>
      <input
        size="30"
        maxlength="64"
        [formControlName]="'email'"
        type="text"
        placeholder="E-mail"
      />
    </div>
    <div
      *ngIf="
        contactInfoForm.controls['email']?.errors?.email &&
        (!contactInfoForm.controls['email']?.pristine ||
          enableAllValidationErrors)
      "
    >
      <span coContent="DL.BE02.C1013"></span>
    </div>
    <div
      *ngIf="
        contactInfoForm.controls['email']?.errors?.required &&
        (!contactInfoForm.controls['email']?.pristine ||
          enableAllValidationErrors)
      "
    >
      <span coContent="DL.BE02.C1008"></span>
    </div>
  </div>
</form>
