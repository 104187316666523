<div *ngIf="isError" class="dynamic-counseling--wrapper error-message">
  <div>
    <h1 [coContent]="'DL.NB01.C145'"></h1>
    <span class="text--manchet text--smoky" [coContent]="'DL.NB01.C146'"></span>
  </div>
</div>

<div *ngIf="!isError" class="dynamic-counseling--wrapper">
  <div class="dynamic-counseling--header">
    <div class="header--item">
      <h3 class="text" [coContent]="'DL.DR01.C46'">PFA</h3>
      <h3 class="text" [coContent]="'DL.DR01.C01'">360&#176;</h3>
    </div>
    <h3 class="header--item text" [coContent]="'DL.DR01.C47'">
      -et individuelt pensionstjek.
    </h3>
  </div>
  <div class="dynamic-counseling--topics--wrapper">
    <mitpfa-dynamic-counseling-topic
      [progressPercent$]="progressPercentage$"
      [topicText]="'DL.DR01.C09'"
      [action]="'DL.DR01.C26'"
      [uri]="'/mine-oplysninger/familie'"
      [nbaScore]="1"
      (click)="adobeTrackingYourInformationClick()"
    >
    </mitpfa-dynamic-counseling-topic>
    <co-loading [show]="loading"></co-loading>
    <mitpfa-dynamic-counseling-topic
      *ngFor="let topic of dynamicModel$ | async; let index = index"
      [severity]="topic.offerSeverity"
      [topicText]="topic.categoryText"
      [action]="topic.getOfferUrlAndText(loc80Allowed).text"
      [nbaScore]="topic.offerNbaValue"
      [uri]="topic.getOfferUrlAndText(loc80Allowed).uri"
      (adobeTrackingClick)="
        adobeTrackingClick(index, topic.getDynamiccounseling)
      "
    >
    </mitpfa-dynamic-counseling-topic>
  </div>

  <p class="explanation--text--note" [coContent]="'DL.DR01.C45'">
    Baseret på de oplysninger vi har om dig, viser farven om du følger vores
    anbefaling.
  </p>
  <div class="explanation--wrapper">
    <div class="explanation--topic">
      <div><div class="explanation color--red"></div></div>
      <span class="explanation--text" [coContent]="'DL.DR01.C41'"
        >Du følger ikke PFA’s anbefaling, og du bør rette til</span
      >
    </div>
    <div class="explanation--topic">
      <div><div class="explanation color--yellow"></div></div>
      <span class="explanation--text" [coContent]="'DL.DR01.C42'"
        >Du ligger tæt på PFA’s anbefaling, og du kan rette til</span
      >
    </div>
    <div class="explanation--topic">
      <div><div class="explanation color--green"></div></div>
      <span class="explanation--text" [coContent]="'DL.DR01.C43'"
        >Du følger PFA’s anbefaling</span
      >
    </div>
  </div>
  <p class="explanation--text" [coContent]="'DL.DR01.C44'">
    Det er OK at fravige fra PFA’s anbefaling, så længe man har taget aktivt
    stilling til det og er komfortabel med sit valg/ forstår det.
  </p>
</div>
