/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ServiceInfo } from './serviceInfo';
import { ServiceInfoSchema } from './serviceInfo';
import { SuccessResponse } from './successResponse';
import { SuccessResponseSchema } from './successResponse';
import * as JoiObj from "joi";


    export const OpdaterOekonomiResponseSchema = JoiObj.object({
        serviceInfo:
            
        JoiObj.link('#ServiceInfoSchema')

.allow(null)



        ,
        success:
            
                    JoiObj.boolean()
                


.required()


        ,
        skalAendringerKasseres:
            
                    JoiObj.boolean()
                


.required()


        
    })
                .shared(ServiceInfoSchema)
.id('OpdaterOekonomiResponseSchema')

export interface OpdaterOekonomiResponse extends SuccessResponse { 
    skalAendringerKasseres: boolean;
}

