<mitpfa-pension-check-header-section
  [headline]="'DL.NB01.C309'"
  [explanation]="'DL.NB01.C310'"
>
</mitpfa-pension-check-header-section>

<mitpfa-family-children *ngIf="familyInformation" [childForms]="childForms">
</mitpfa-family-children>

<div class="next-button">
  <button
    [disabled]="childForms.invalid"
    mat-raised-button
    (click)="complete()"
    [coContent]="'DL.NB01.C316'"
  ></button>
</div>
