/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const PrisListePakkeTypeSchema = JoiObj.string().valid(
        'GrundPakke'
        ,
        'SamletPakke'
        ,
        'TelefonRaadgivning'
        ,
        'SpecialRaadgivning'
        ,
        'PersonligRaadgivning'
        ,
        'VirksomhedRaadgivning'
        ,
        'Ukendt'
        
    )
.id('PrisListePakkeTypeSchema')

export type PrisListePakkeType = 'GrundPakke' | 'SamletPakke' | 'TelefonRaadgivning' | 'SpecialRaadgivning' | 'PersonligRaadgivning' | 'VirksomhedRaadgivning' | 'Ukendt';

export const PrisListePakkeType = {
    GrundPakke: 'GrundPakke' as PrisListePakkeType,
    SamletPakke: 'SamletPakke' as PrisListePakkeType,
    TelefonRaadgivning: 'TelefonRaadgivning' as PrisListePakkeType,
    SpecialRaadgivning: 'SpecialRaadgivning' as PrisListePakkeType,
    PersonligRaadgivning: 'PersonligRaadgivning' as PrisListePakkeType,
    VirksomhedRaadgivning: 'VirksomhedRaadgivning' as PrisListePakkeType,
    Ukendt: 'Ukendt' as PrisListePakkeType
};

