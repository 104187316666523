export class NemLogin3Response {
  BodySerialized?: string;
  BodyTypeName: NemLogin3ResponseBodyType;
  Body?: NemLogin3ResponseBody;
  ClientIdentifier: string;
  ClientInfo: { client: string; version: string; language: string };
  EncryptedKey: string | null;
  Signature: string | null;
  Timestamp: string;
  TransactionIdentifier: string;

  static parse(result: string): NemLogin3Response {
    const parsedResponse: NemLogin3Response = JSON.parse(atob(result));
    if (parsedResponse.BodyTypeName !== 'Frame.Nl3.Nl3SignFlowResult') {
      parsedResponse.Body = NemLogin3ResponseBody.parse(
        parsedResponse.BodySerialized
      );
    }
    return parsedResponse;
  }
}

export class NemLogin3ResponseBody {
  ClientFlow: string;
  FlowErrorCode: string | null;
  IsSuccess: boolean;
  Message: string;
  Status: string;
  Timestamp: string | null;
  TransactionIdentifier: string | null;
  UserMessage: string | null;

  static parse(body: string): NemLogin3ResponseBody {
    return JSON.parse(body);
  }
}

export type NemLogin3ResponseBodyType = 'Frame.Nl3.Nl3SignFlowResult';
