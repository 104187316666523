import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import {
  ConfirmChoiceComponentTexts,
  GlobalWorkingService,
  InsuranceUtilService
} from '@pfaform';
import { Observable, Subscription } from 'rxjs';
import { Utils } from '@pfa/utils';
import { AutoAdjustmentType, LocScale, LocScaleInformation } from '@pfa/gen';
import { InsuranceTag, NullableNumber } from '@pfa/models';
import { openClose } from '@pfa/animations';
import {
  ConfirmChoice,
  CoverageOption,
  CoverageOptions,
  InputConfigLoc,
  INSURANCE_SCROLL_TIMERS,
  InsuranceAdjustmentInputEmitter,
  InsuranceAdjustmentLocService,
  InsuranceAdjustmentType,
  InsuranceGuideChecks,
  ResetDataLoc
} from '@mitpfa/shared';
import { InsuranceGuideService } from '@mitpfa/shared/insurance-guide/insurance-guide.service';
import { InsuranceAdjustmentConfirmChoiceConfigurationService } from '../insurance-adjustment-confirm-choice-configuration.service';

@Component({
  selector: 'mitpfa-insurance-adjustment-input-loc',
  templateUrl: './insurance-adjustment-input-loc.component.html',
  styleUrls: ['./insurance-adjustment-input-loc.component.scss'],
  animations: [openClose]
})
export class InsuranceAdjustmentInputLocComponent
  implements OnInit, OnDestroy, OnChanges
{
  @Input() inputConfig: InputConfigLoc;
  @Input() reset: Observable<ResetDataLoc>;
  @Input() disabled: boolean;
  @Input() autoAdjustmentType: AutoAdjustmentType;
  @Input() tags: InsuranceTag[];
  @Input() confirmChoice: ConfirmChoice;
  @Input() insuranceGuideChecks: InsuranceGuideChecks;
  @Input() calculatedByUser: boolean;
  @Input() showAuaConditions: boolean;
  @Input() hideActions: boolean;

  @Output() changes: EventEmitter<InsuranceAdjustmentInputEmitter> =
    new EventEmitter<InsuranceAdjustmentInputEmitter>();
  @Output() confirmChoiceEmitter: EventEmitter<void> = new EventEmitter();

  form: UntypedFormGroup;
  private subscription: Subscription;
  expanded: boolean;
  expandedHealthWarning: boolean;
  isManual: boolean;
  isLoC80: boolean;
  isLoC100: boolean;
  loc80Allowed: boolean;
  locAllowed: boolean;
  coverageOptions: CoverageOptions;
  selectedCoverageOption: CoverageOption | undefined;
  selectedCoverageTagTooltip: string;
  selectedCoverageTagText: string;
  locCalculatedFor: LocScale | undefined;
  specialSelected: boolean;
  specialSelectedValue: number | undefined;
  specialCalculatedFor: number | undefined;
  showWarningSection: boolean | undefined;
  initialized: boolean;
  pricesUpdated: boolean;
  overrideRecommendationKeepCurrent: boolean;

  private readonly globalWorkingService: GlobalWorkingService =
    inject(GlobalWorkingService);
  private readonly insuranceUtilService: InsuranceUtilService =
    inject(InsuranceUtilService);
  private readonly insuranceAdjustmentLocService: InsuranceAdjustmentLocService =
    inject(InsuranceAdjustmentLocService);
  private readonly insuranceAdjustmentConfirmChoiceConfigurationService: InsuranceAdjustmentConfirmChoiceConfigurationService =
    inject(InsuranceAdjustmentConfirmChoiceConfigurationService);

  public confirmChoiceComponentTexts: ConfirmChoiceComponentTexts =
    this.insuranceAdjustmentConfirmChoiceConfigurationService
      .confirmChoiceComponentTexts;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.inputConfig?.currentValue) {
      const inputConfigUpdate = changes.inputConfig
        .currentValue as InputConfigLoc;
      if (
        this.calculatedByUser ||
        inputConfigUpdate.regularPayout.notePriceOriginal !==
          inputConfigUpdate.regularPayout.notePrice ||
        inputConfigUpdate.regularPayout.noteBeforeTaxOriginal !==
          inputConfigUpdate.regularPayout.noteBeforeTax ||
        inputConfigUpdate.regularPayout.noteAfterTaxOriginal !==
          inputConfigUpdate.regularPayout.noteAfterTax
      ) {
        this.pricesUpdated = true;
      }
    }
  }

  ngOnInit(): void {
    const locScaleInformation =
      this.inputConfig.regularPayout.locScaleInformation;
    this.isManual = locScaleInformation?.current === 'MANUAL';
    this.isLoC80 = locScaleInformation?.current === 'LOC_80';
    this.isLoC100 = locScaleInformation?.current === 'LOC_100';
    this.loc80Allowed =
      locScaleInformation?.allowed.includes('LOC_80') ?? false;
    this.locAllowed =
      this.loc80Allowed ||
      (locScaleInformation?.allowed.includes('LOC_100') ?? false);

    this.overrideRecommendationKeepCurrent =
      InsuranceGuideService.calculateShouldKeepRecommendation(
        this.inputConfig.regularPayout.amountOriginal,
        this.inputConfig.recommendation,
        InsuranceUtilService.HEALTH_VARIATION
      );

    this.handleLocScaleRecommendation(locScaleInformation);

    this.coverageOptions = {
      LOC_80: {
        show: this.loc80Allowed,
        value: 'LOC_80',
        recommended: this.overrideRecommendationKeepCurrent
          ? locScaleInformation?.current === 'LOC_80'
          : locScaleInformation?.recommendation === 'LOC_80'
      },
      LOC_100: {
        show:
          this.inputConfig.regularPayout?.locScaleInformation?.allowed.includes(
            'LOC_100'
          ) ?? false,
        value: 'LOC_100',
        recommended: this.overrideRecommendationKeepCurrent
          ? locScaleInformation?.current === 'LOC_100'
          : locScaleInformation?.recommendation === 'LOC_100'
      },
      OPT_OUT: {
        show:
          this.inputConfig.regularPayout?.locScaleInformation?.allowed.includes(
            'MANUAL'
          ) ?? false,
        value: 'MANUAL',
        recommended: this.overrideRecommendationKeepCurrent
          ? locScaleInformation?.current === 'MANUAL'
          : locScaleInformation?.recommendation === 'MANUAL'
      }
    };
    this.form = new UntypedFormGroup({});
    this.form.addControl(
      'coverage',
      new UntypedFormControl({ disabled: this.disabled })
    );
    this.form.addControl(
      'regularPayout',
      new UntypedFormControl(
        {
          value: '',
          disabled: this.disabled
        },
        [
          Validators.min(this.inputConfig?.regularPayout?.amountMin ?? 0),
          Validators.max(this.inputConfig?.regularPayout?.amountMax ?? 0)
        ]
      )
    );
    if (this.inputConfig.lumpSum) {
      this.form.addControl(
        'lumpSum',
        new UntypedFormControl(
          {
            value: this.inputConfig.lumpSum.amount,
            disabled: this.disabled
          },
          [
            Validators.min(this.inputConfig?.lumpSum?.amountMin ?? 0),
            Validators.max(this.inputConfig?.lumpSum?.amountMax ?? 0)
          ]
        )
      );
    }

    this.initSelectedCoverageOption();
    this.handleWarningSection();
    this.subscribeToReset();
    this.subscribeToFollowOurRecommendation();
    this.initialized = true;
  }

  initSelectedCoverageOption(): void {
    if (this.inputConfig.regularPayout.special) {
      this.form.controls['coverage']?.setValue(
        this.inputConfig.regularPayout.amountOriginal
      );
    } else {
      if (this.isLoC80) {
        this.selectedCoverageOption = undefined;
        this.form.controls['coverage']?.setValue(
          this.coverageOptions['LOC_80'].value
        );
      } else if (this.isLoC100) {
        this.selectedCoverageOption = undefined;
        this.form.controls['coverage']?.setValue(
          this.coverageOptions['LOC_100'].value
        );
      } else {
        this.selectedCoverageOption = this.coverageOptions['OPT_OUT'];
        if (this.locAllowed) {
          this.form.controls['coverage']?.setValue(
            this.selectedCoverageOption.value
          );
        }
      }
    }
  }

  private handleLocScaleRecommendation(
    locScaleInformation: LocScaleInformation
  ): void {
    const changeInvolvingATRecommended =
      (locScaleInformation.recommendation === 'MANUAL' &&
        locScaleInformation.current !== 'MANUAL') ||
      (locScaleInformation.recommendation !== 'MANUAL' &&
        locScaleInformation.current === 'MANUAL');

    if (changeInvolvingATRecommended) {
      this.overrideRecommendationKeepCurrent = false;
    }
  }

  handleWarningSection(): void {
    const regularPayout = this.inputConfig.regularPayout;
    this.showWarningSection =
      !!regularPayout.warningSpecialCoverage ||
      !!regularPayout.warning80Percent ||
      !!regularPayout.warningRecommendLow ||
      regularPayout.special;
  }

  updateRadio(
    value: number,
    formControl: AbstractControl<NullableNumber, NullableNumber> | null
  ): void {
    if (value !== this.inputConfig.regularPayout.amount) {
      this.pricesUpdated = false;
      this.specialSelected = true;
      this.specialSelectedValue = value;
      formControl?.markAsPristine();
    }
  }

  update(
    type: InsuranceAdjustmentType,
    formControl: AbstractControl<NullableNumber, NullableNumber> | null
  ): void {
    this.pricesUpdated = false;
    this.globalWorkingService.show();
    this.mapChanges(type);
    this.changes.emit({
      inputConfig: this.inputConfig,
      type
    });
    formControl?.markAsPristine();
    this.form.get('coverage')?.markAsPristine();
  }

  mapChanges(type: InsuranceAdjustmentType): void {
    if (type === InsuranceAdjustmentType.LOC) {
      if (this.specialSelected) {
        this.specialCalculatedFor = this.specialSelectedValue;
        this.inputConfig.regularPayout.amount = this.specialSelectedValue;
      } else {
        this.locCalculatedFor = this.selectedCoverageOption?.value;
        if (this.locCalculatedFor === 'MANUAL') {
          this.inputConfig.regularPayout.locScale = 'MANUAL';
          this.inputConfig.regularPayout.amount = this.form.value.regularPayout;
        } else {
          // AT
          this.inputConfig.regularPayout.locScale = this.locCalculatedFor;
        }
      }
    } else if (
      type === InsuranceAdjustmentType.LOC_LUMPSUM &&
      this.inputConfig.lumpSum
    ) {
      this.inputConfig.lumpSum.amount = this.form.value.lumpSum;
    }
  }

  checkForEmpty(
    formControl: AbstractControl<NullableNumber, NullableNumber> | null,
    defaultValue: number
  ) {
    if (Utils.isEmpty(formControl?.value)) {
      formControl?.setValue(defaultValue);
    }
  }

  isDisabled(fieldName: string): boolean {
    if (fieldName === 'regularPayout') {
      // Special (Novo)
      if (this.inputConfig.regularPayout.special && this.specialSelected) {
        return !!(
          this.specialSelectedValue ===
            this.inputConfig.regularPayout.amountOriginal ||
          this.form.get('coverage')?.pristine
        );
      }

      // AT
      if (
        this.selectedCoverageOption &&
        this.selectedCoverageOption.value !== 'MANUAL'
      ) {
        return !!(
          this.selectedCoverageOption.value ===
            this.inputConfig.regularPayout.locScaleInformation?.current ||
          this.form.get('coverage')?.pristine
        );
      }
    }

    // Manual
    return (
      this.disabled ||
      (this.form.get(fieldName)?.pristine ?? true) ||
      (this.form.get(fieldName)?.invalid ?? false)
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private subscribeToReset(): void {
    this.subscription = this.reset.subscribe(data => {
      this.form.controls['regularPayout']?.setValue('');
      this.expanded = false;
      this.initSelectedCoverageOption();
      this.selectedCoverageTagTooltip = '';
      this.selectedCoverageTagText = '';
      this.locCalculatedFor = undefined;
      this.specialSelected = false;
      this.specialSelectedValue = undefined;
      this.specialCalculatedFor = undefined;
      this.calculatedByUser = false;
      this.pricesUpdated = false;

      if (this.inputConfig.lumpSum) {
        this.form.patchValue({
          lumpSum: data.inputConfig.lumpSum?.amountOriginal
        });
      }
      this.subscribeToFollowOurRecommendation();
    });
  }

  private subscribeToFollowOurRecommendation(): void {
    this.insuranceAdjustmentLocService.followOurRecommendation.subscribe({
      next: () => {
        setTimeout(() => {
          const locScaleInformation =
            this.inputConfig.regularPayout.locScaleInformation;
          const loc80Allowed =
            locScaleInformation?.allowed.includes('LOC_80') ?? false;
          const locAllowed =
            loc80Allowed ||
            (locScaleInformation?.allowed.includes('LOC_100') ?? false);
          if (this.inputConfig.regularPayout.special) {
            this.setRecommendationSpecial(
              InsuranceAdjustmentType.LOC,
              this.form.get('regularPayout')
            );
          } else if (locAllowed) {
            this.setRecommendationAT(
              InsuranceAdjustmentType.LOC,
              this.form.get('regularPayout')
            );
          } else {
            this.setRecommendation(
              InsuranceAdjustmentType.LOC,
              this.form.get('regularPayout')
            );
          }
        }, INSURANCE_SCROLL_TIMERS.recommendationCalculationDelay);
      }
    });
  }

  setRecommendation(
    type: InsuranceAdjustmentType,
    formControl: AbstractControl<NullableNumber, NullableNumber> | null
  ): void {
    this.form.controls['regularPayout'].setValue(
      this.inputConfig.recommendation
    );
    this.update(type, formControl);
  }

  setRecommendationSpecial(
    type: InsuranceAdjustmentType,
    formControl: AbstractControl<NullableNumber, NullableNumber> | null
  ): void {
    const amount = this.inputConfig.recommendation;
    this.form.controls['coverage']?.setValue(amount);
    this.updateRadio(amount, formControl);
    this.update(type, formControl);
  }

  setRecommendationAT(
    type: InsuranceAdjustmentType,
    formControl: AbstractControl<NullableNumber, NullableNumber> | null
  ): void {
    const recommended = this.findRecommendedAtOption();
    this.form.controls['coverage']?.setValue(recommended.value);
    if (recommended.value === 'MANUAL') {
      this.form.controls['regularPayout'].setValue(
        this.inputConfig.recommendation
      );
    }
    this.choseCoverage(recommended);
    this.update(type, formControl);
  }

  private findRecommendedAtOption(): CoverageOption {
    if (this.coverageOptions.LOC_80.recommended) {
      return this.coverageOptions.LOC_80;
    } else if (this.coverageOptions.LOC_100.recommended) {
      return this.coverageOptions.LOC_100;
    } else {
      return this.coverageOptions.OPT_OUT;
    }
  }

  choseCoverage(coverageOption: CoverageOption): void {
    this.selectedCoverageOption = coverageOption;
    if (coverageOption.value === 'MANUAL') {
      this.selectedCoverageTagText = '';
      this.selectedCoverageTagTooltip = '';
    } else {
      const hasScale80 = coverageOption.value === 'LOC_80';
      const hasScale100 = coverageOption.value === 'LOC_100';
      const scale80allowed =
        this.inputConfig.regularPayout.locScaleInformation?.allowed.includes(
          'LOC_80'
        ) ?? false;
      this.selectedCoverageTagText =
        this.insuranceUtilService.getLocScaleTagText(
          hasScale80,
          hasScale100,
          scale80allowed
        );
      this.selectedCoverageTagTooltip =
        this.insuranceUtilService.getLocScaleTooltip(
          coverageOption.value === 'LOC_100'
        );
    }
  }

  onConfirmChoice() {
    this.confirmChoiceEmitter.emit();
  }
}
