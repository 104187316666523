import { Injectable, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

interface AuthRequestQueryParams {
  response_type: string;
  client_id: string;
  scope: string;
  redirect_uri: string;
  code_challenge_method: 'S256';
  code_challenge: string;
  state: string;
}

export interface AuthorizationRequestData {
  uri: string;
  data: AuthRequestQueryParams;
}

export interface MobileBridge {
  getAuthorizationRequest(): AuthorizationRequestData;
  setAuthorizationCode(code: string, state: string): void;
}

@Injectable()
export class WindowWrapper extends Window {
  console: Console;
  MobileBridge: MobileBridge;
}

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [{ provide: WindowWrapper, useValue: window }]
})
export class MobileBridgeModule {}
