import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';

@Component({
  selector: 'co-threadlist-item',
  templateUrl: './threadlistItem.component.html',
  styleUrls: ['./threadlist.component.scss']
})
export class ThreadlistItemComponent implements OnInit {
  @Output() openThread = new EventEmitter<any>();
  @Input() threadOpen: boolean;
  @Input() priorityClassRed: boolean;
  @Input() threadItem: any;
  @Input() isRaadgiver: boolean;
  @Input() first: boolean;

  ngOnInit() {
    this.threadItem.highPriority =
      this.threadItem.urgent ||
      this.threadItem.correspondenceImportance === 'HIGH';
  }

  emitOpenThread(threadItem) {
    this.openThread.emit(threadItem);
  }
}
