import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { DocumentsService } from './documents.service';
import { DocumentCacheItem, DocumentType } from './documents.model';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import {
  ContentService,
  ContentUtilService,
  PageTrackingService
} from '@pfa/core';
import { UntypedFormControl } from '@angular/forms';
import * as _ from 'lodash';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'co-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss']
})
export class DocumentsComponent implements OnInit {
  private readonly documentsService: DocumentsService =
    inject(DocumentsService);
  private readonly contentService: ContentService = inject(ContentService);
  private readonly contentUtilService: ContentUtilService =
    inject(ContentUtilService);
  private readonly destroyRef: DestroyRef = inject(DestroyRef);
  private readonly pageTrackingService: PageTrackingService =
    inject(PageTrackingService);

  public documentData$: Observable<DocumentCacheItem>;
  public documentTypeControl = new UntypedFormControl('');
  public documentTypes: DocumentType[];
  public sortedBy = 'text-desc';
  public title: string;
  public description: string;

  public ngOnInit(): void {
    this.title = 'DL.DO01.C01';
    this.description = 'DL.DO01.C02';
    const isLightApp = window.location.pathname.indexOf('/light') === 0;
    if (isLightApp) {
      this.description = 'DL.DO01.C99';
    }
    this.getDocuments();
    this.documentTypeControl.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(selectedDocumentType => {
        this.getDocuments(selectedDocumentType.key, false);
      });
    this.contentService.languageState
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.getDocuments();
      });
  }

  public getDocuments(
    valgt: string = 'seneste',
    initialCall: boolean = true
  ): void {
    this.documentData$ = this.documentsService.getDocuments(valgt).pipe(
      map(documentCacheItem => {
        if (initialCall) {
          this.pageTrackingService.trackContentLoaded();
          this.documentTypes = new Array<DocumentType>();
          if (documentCacheItem.dropDownElementer) {
            documentCacheItem.dropDownElementer.forEach(elem => {
              if (elem === 'DL.DO01.C55') {
                this.documentTypes.push({
                  key: 'seneste',
                  text: this.contentUtilService.getContent(elem, null)
                });
              } else if (elem === 'DL.DO01.C56') {
                this.documentTypes.push({
                  key: 'alle',
                  text: this.contentUtilService.getContent(elem, null)
                });
              } else if (elem === 'DL.DO01.C78') {
                this.documentTypes.push({
                  key: 'andre',
                  text: this.contentUtilService.getContent(elem, null)
                });
              } else if (elem === 'DL.DO01.C77') {
                this.documentTypes.push({
                  key: 'oevrige',
                  text: this.contentUtilService.getContent(elem, null)
                });
              }
            });

            if (
              valgt === 'seneste' &&
              documentCacheItem.documents.length === 0
            ) {
              this.documentTypeControl.setValue(this.documentTypes[1]);
            } else {
              this.documentTypeControl.setValue(this.documentTypes[0]);
            }
          }
        }

        return documentCacheItem;
      })
    );
  }

  public sortBy(field: string, documentCacheItem: DocumentCacheItem): void {
    this.sortedBy = documentCacheItem.sortBy(field);
    documentCacheItem.documents =
      this.sortedBy.indexOf('asc') > -1
        ? _.sortBy(documentCacheItem.documents, field).reverse()
        : _.sortBy(documentCacheItem.documents, field);
  }
}
