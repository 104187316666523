/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const PayoutTypeSchema = JoiObj.string().valid(
        'HEADER'
        ,
        'SALARY'
        ,
        'LUMP_SUM'
        ,
        'LUMP_SUM_TAX'
        ,
        'LIFE_PENSION'
        ,
        'INSTALLMENT_PENSION'
        ,
        'PUBLIC'
        ,
        'INCOME_BEFORE_TAX'
        ,
        'INCOME_TAX'
        ,
        'INCOME_AFTER_TAX'
        ,
        'TRANSFERED_LUMP_SUM_TO_WEALTH'
        ,
        'USE_OF_WEALTH'
        ,
        'TOTAL_PAYOUT_AFTER_TAX'
        ,
        'FREE_ASSETS'
        ,
        'PROPERTY'
        ,
        'TOTAL_WEALTH'
        ,
        'WITHOUT_PAYOUT'
        
    )
.id('PayoutTypeSchema')

export type PayoutType = 'HEADER' | 'SALARY' | 'LUMP_SUM' | 'LUMP_SUM_TAX' | 'LIFE_PENSION' | 'INSTALLMENT_PENSION' | 'PUBLIC' | 'INCOME_BEFORE_TAX' | 'INCOME_TAX' | 'INCOME_AFTER_TAX' | 'TRANSFERED_LUMP_SUM_TO_WEALTH' | 'USE_OF_WEALTH' | 'TOTAL_PAYOUT_AFTER_TAX' | 'FREE_ASSETS' | 'PROPERTY' | 'TOTAL_WEALTH' | 'WITHOUT_PAYOUT';

export const PayoutType = {
    Header: 'HEADER' as PayoutType,
    Salary: 'SALARY' as PayoutType,
    LumpSum: 'LUMP_SUM' as PayoutType,
    LumpSumTax: 'LUMP_SUM_TAX' as PayoutType,
    LifePension: 'LIFE_PENSION' as PayoutType,
    InstallmentPension: 'INSTALLMENT_PENSION' as PayoutType,
    Public: 'PUBLIC' as PayoutType,
    IncomeBeforeTax: 'INCOME_BEFORE_TAX' as PayoutType,
    IncomeTax: 'INCOME_TAX' as PayoutType,
    IncomeAfterTax: 'INCOME_AFTER_TAX' as PayoutType,
    TransferedLumpSumToWealth: 'TRANSFERED_LUMP_SUM_TO_WEALTH' as PayoutType,
    UseOfWealth: 'USE_OF_WEALTH' as PayoutType,
    TotalPayoutAfterTax: 'TOTAL_PAYOUT_AFTER_TAX' as PayoutType,
    FreeAssets: 'FREE_ASSETS' as PayoutType,
    Property: 'PROPERTY' as PayoutType,
    TotalWealth: 'TOTAL_WEALTH' as PayoutType,
    WithoutPayout: 'WITHOUT_PAYOUT' as PayoutType
};

