import {
  Output,
  EventEmitter,
  Input,
  Component,
  ChangeDetectionStrategy,
  inject
} from '@angular/core';
import { AftaleAnbefaling, ExternaltransferStore } from '@pfa/gen';

@Component({
  selector: 'mitpfa-combine-savings-summary-combined',
  templateUrl: './combine-savings-summary-combined.component.html',
  styleUrls: ['./combine-savings-summary-combined.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CombineSavingsSummaryCombinedComponent {
  private readonly externaltransferStore: ExternaltransferStore = inject(
    ExternaltransferStore
  );

  @Input() policies: AftaleAnbefaling[] = [];
  @Output() goToNextPage = new EventEmitter<void>();

  public nextPage(): void {
    this.externaltransferStore.invalidateexternaltransferIndex();
    this.externaltransferStore.invalidateexternaltransferrecommendationShortInfoIndex();
    this.goToNextPage.emit();
  }
}
