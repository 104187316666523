import { Component, Input } from '@angular/core';

@Component({
  selector: 'co-loading',
  templateUrl: 'loading.component.html',
  styleUrls: ['loading.component.scss']
})
export class LoadingComponent {
  @Input() show: boolean;
}
