<ng-container *ngIf="overview.policyType !== 'CONTINUATION'; else continuation">
  <table class="align-right--column-2 new-business-advisory-table">
    <thead>
      <tr>
        <th colspan="2" [coContent]="'DL.TR01.C342'">PFA Anbefaler</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngIf="overview.investeringsguideTaget">
        <tr *ngIf="overview.anbefaletProfil">
          <td
            colspan="2"
            [coContent]="'DL.TR01.C62'"
            [coContentSubst]="
              overview.anbefaletProfil +
              '|' +
              overview.investeringsguideDatoString
            "
          ></td>
        </tr>
        <tr *ngIf="overview.anbefaletAktiePct !== null">
          <td
            colspan="2"
            [coContent]="'DL.TR01.C63'"
            [coContentSubst]="
              (overview.anbefaletAktiePct | number: '1.0-0') +
              '|' +
              (overview.anbefaletObligationPct | number: '1.0-0') +
              '|' +
              overview.investeringsguideDatoString
            "
          ></td>
        </tr>
        <tr *ngIf="overview.recommendedClimatePercent !== null">
          <td
            colspan="2"
            [coContent]="'DL.TR01.C288'"
            [coContentSubst]="
              (overview.recommendedClimatePercent | number: '1.0-0') +
              '|' +
              overview.investeringsguideDatoString
            "
          ></td>
        </tr>
      </ng-container>
      <tr *ngIf="!overview.investeringsguideTaget">
        <td colspan="2" [coContent]="'DL.TR01.C64'"></td>
      </tr>
    </tbody>
  </table>

  <table
    *ngIf="overview.skalPfaInvesterVises"
    class="align-right--column-2 new-business-advisory-table"
  >
    <thead>
      <tr>
        <th colspan="2" [coContent]="'DL.TR01.C50'">PFA Investerer</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngIf="!overview.harFlereProfil">
        <td [coContent]="'DL.TR01.C51'"></td>
        <td
          class="text--bold"
          [coContent]="'DL.TR01.C52'"
          [coContentSubst]="overview.profilAlle"
        ></td>
      </tr>
      <ng-container *ngIf="overview.harFlereProfil">
        <tr>
          <td [coContent]="'DL.TR01.C51'"></td>
          <td
            class="text--bold"
            [coContent]="'DL.TR01.C52'"
            [coContentSubst]="overview.profilAlle"
          ></td>
        </tr>
        <tr>
          <td></td>
          <td class="text--bold">
            {{ profileSources(overview.profileAlleKilder) }}
          </td>
        </tr>

        <tr>
          <td></td>
          <td
            class="text--bold"
            [coContent]="'DL.TR01.C52'"
            [coContentSubst]="overview.profilFrie"
          ></td>
        </tr>
        <tr>
          <td></td>
          <td class="text--bold">
            {{ profileSources(overview.profileFrieKilder) }}
          </td>
        </tr>
      </ng-container>

      <tr>
        <td></td>
        <td
          class="text--bold"
          [coContent]="
            overview.udbetalingssikring ? 'DL.TR01.C60' : 'DL.TR01.C61'
          "
        ></td>
      </tr>

      <tr
        *ngIf="
          overview.investeringsguideTaget &&
          (!overview.pfaInvesterAnbefalet || overview.profilValgIkkeAnbefalet)
        "
      >
        <td colspan="2" [coContent]="'DL.TR01.C65'"></td>
      </tr>
    </tbody>
  </table>

  <table
    *ngIf="overview.skalPfaInvesterVises"
    class="new-business-advisory-table"
  >
    <thead>
      <tr>
        <th [coContent]="'DL.TR01.C244'"></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngIf="overview.klimaplusAndel > 0">
        <td [coContent]="'DL.TR01.C245'"></td>
      </tr>
      <tr>
        <td
          [coContent]="'DL.TR01.C246'"
          [coContentSubst]="overview.klimaplusAndel | number: '1.0-0'"
        ></td>
      </tr>
    </tbody>
  </table>

  <table
    *ngIf="overview.pfaValgfriValgt"
    class="align-right--column-2 new-business-advisory-table"
  >
    <thead>
      <tr>
        <th colspan="2" [coContent]="'DL.TR01.C66'"></th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td [coContent]="'DL.TR01.C67'"></td>
        <td>
          <div
            class="text--bold"
            [coContent]="'DL.TR01.C68'"
            [coContentSubst]="
              overview.pfaValgfriLavRisikoAndel | number: '1.0-0'
            "
          ></div>
          <div
            class="text--bold"
            [coContent]="'DL.TR01.C69'"
            [coContentSubst]="
              overview.pfaValgfriHoejRisikoAndel | number: '1.0-0'
            "
          ></div>
        </td>
      </tr>
      <tr>
        <td colspan="2" [coContent]="'DL.TR01.C70'"></td>
      </tr>
      <tr *ngIf="overview.pfaInvesterAnbefalet">
        <td colspan="2" [coContent]="'DL.TR01.C71'"></td>
      </tr>
    </tbody>
  </table>

  <table *ngIf="overview.pfaValgfriValgt" class="new-business-advisory-table">
    <thead>
      <tr>
        <th [coContent]="'DL.TR01.C247'"></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngIf="overview.klimaplusAndel > 0">
        <td [coContent]="'DL.TR01.C248'"></td>
      </tr>
      <tr>
        <td
          [coContent]="'DL.TR01.C249'"
          [coContentSubst]="overview.klimaplusAndel | number: '1.0-0'"
        ></td>
      </tr>
    </tbody>
  </table>
</ng-container>
<ng-template #continuation>
  <p [coContent]="'DL.TR01.C392'"></p>
</ng-template>

<table class="new-business-advisory-table">
  <thead>
    <tr>
      <th [coContent]="'DL.TR01.C72'"></th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <ng-container *ngIf="overview.policyType !== 'CONTINUATION'">
        <td *ngIf="overview.kundekapitalValgt" [coContent]="'DL.TR01.C73'"></td>
        <td
          *ngIf="!overview.kundekapitalValgt"
          [coContent]="'DL.TR01.C74'"
        ></td>
      </ng-container>
      <td
        *ngIf="overview.policyType === 'CONTINUATION'"
        [coContent]="'DL.TR01.C393'"
      ></td>
    </tr>
  </tbody>
</table>
