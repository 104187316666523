import { Component, inject, OnInit } from '@angular/core';
import { GlobalWorkingService } from '@pfaform';
import { Router } from '@angular/router';
import { ContentUtilService } from '@pfa/core';
import { AdvisorOptions, IndbetalingSikring, TilbudOverblik } from '@pfa/gen';
import { NewBusinessAdvisoryService } from '@pfa/services';

@Component({
  selector: 'mitpfa-new-business-advisory',
  templateUrl: './new-business-advisory.component.html',
  styleUrls: ['./new-business-advisory.component.scss']
})
export class NewBusinessAdvisoryComponent implements OnInit {
  private readonly advisingService: NewBusinessAdvisoryService = inject(
    NewBusinessAdvisoryService
  );
  private readonly router: Router = inject(Router);
  private readonly globalWorking: GlobalWorkingService =
    inject(GlobalWorkingService);
  private readonly contentUtilService: ContentUtilService =
    inject(ContentUtilService);

  overview$ = this.advisingService.getOverview();
  advisorOptions$ = this.advisingService.getAdvisorOptions('NEW');
  pricelistUrl: string;
  savePaymentsFail = false;
  switchHsFail = false;
  saveIndbetalingSikringFail = false;
  errorMessage = '';

  ngOnInit() {
    this.advisingService.newBusinessAdvisory$.subscribe(data => {
      this.pricelistUrl = `/prisliste/${data.digitalSalgPoliceNr}`;
    });

    // Tell subscribers to update CustomerProfile in case 'initraadgivning' updated it
    this.notifyProfileUpdated();
  }

  onSavePayments(
    overview: TilbudOverblik,
    payments: { employer: number; employee: number }
  ) {
    this.savePaymentsFail = false;
    this.globalWorking.show();
    this.advisingService
      .savePayments(overview.minObligatoriskIndbetaling, payments)
      .subscribe(
        () => this.onActionDone(),
        () => {
          this.globalWorking.hide();
          this.savePaymentsFail = true;
        }
      );
  }

  onSaveVoluntaryPayment(payment: number) {
    this.savePaymentsFail = false;
    this.globalWorking.show();
    this.advisingService.saveVoluntaryPayment(payment).subscribe(
      () => this.onActionDone(),
      () => {
        this.globalWorking.hide();
        this.savePaymentsFail = true;
      }
    );
  }

  private onActionDone() {
    this.globalWorking.hide();
    this.overview$ = this.advisingService.getOverview();
  }

  onIndbetalingSikringSelected(indbetalingSikring: IndbetalingSikring) {
    this.saveIndbetalingSikringFail = false;
    this.globalWorking.show();
    this.advisingService.saveIndbetalingSikring(indbetalingSikring).subscribe(
      () => this.onActionDone(),
      () => {
        this.globalWorking.hide();
        this.saveIndbetalingSikringFail = true;
      }
    );
  }

  onPartialSchemeSelected(partialSchemeId: number) {
    this.switchHsFail = false;
    this.globalWorking.show();
    this.advisingService.switchHs(partialSchemeId).subscribe(
      result => {
        this.globalWorking.hide();
        if (result.success) {
          this.notifyProfileUpdated();
          this.overview$ = this.advisingService.getOverview();
        } else {
          this.switchHsFail = true;
        }
      },
      () => {
        this.globalWorking.hide();
        this.switchHsFail = true;
      }
    );
  }

  private notifyProfileUpdated() {
    const currentValue = window.localStorage.getItem('updateCustomerProfile');
    window.localStorage.setItem(
      'updateCustomerProfile',
      currentValue ? '' : '1'
    );
  }

  close() {
    this.globalWorking.show();
    this.errorMessage = null;
    this.advisingService.afslutRaadgivning().subscribe(
      () => {
        this.globalWorking.hide();
        window.close();
      },
      () => {
        this.globalWorking.hide();
        this.errorMessage = this.contentUtilService.getContent('DL.TR01.396');
      }
    );
  }

  navigateToReleaseOffer(overview: TilbudOverblik) {
    if (overview.companyOrPrivate === 'Privat') {
      this.router.navigate(['/advisor/new-business/release-offer'], {
        state: { data: overview }
      });
    } else {
      this.globalWorking.show();
      this.errorMessage = null;
      this.advisingService.savePayerId().subscribe(
        () => {
          this.globalWorking.hide();
          this.router.navigate(['/advisor/new-business/release-offer'], {
            state: { data: overview }
          });
        },
        () => {
          this.globalWorking.hide();
          this.errorMessage =
            this.contentUtilService.getContent('DL.TR01.C397');
        }
      );
    }
  }

  checkMinIndbetalingTilOpsparing(
    overview: TilbudOverblik,
    advisorOptions: AdvisorOptions
  ) {
    return !(
      overview.policyType === 'CONTINUATION' &&
      overview.variantCode === 1 &&
      overview.indbetalingTilOpsparing <
        advisorOptions.minIndbetalingTilOpsparing
    );
  }
}
