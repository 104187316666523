import { ConsentInfo } from '@pfa/gen';

export interface RefinedConsentInfo extends ConsentInfo {
  requirementsMet?: boolean;
  retiredConsent?: ConsentInfo;
}

export enum ConsentType {
  Marketing = 'Marketing',
  MarketingPensionOnly = 'MarketingPensionOnly'
}

export enum ConsentChannelType {
  EMail = 'EMail',
  SMS = 'SMS',
  Phone = 'Phone',
  EBoks = 'EBoks',
  Mail = 'Mail',
  SocialMedia = 'SocialMedia',
  Web = 'Web',
  App = 'App'
}

export enum ConsentState {
  GIVEN = 'GIVEN',
  REVOKED = 'REVOKED'
}

export interface ConsentConfiguration {
  typeTextId?: string;
  englishOnlyTextId?: string;
  detailsTextId?: string;
  detailsHeaderTextId?: string;
  signUpTextId?: string;
  signOutTextId?: string;
  signOutRetiredTextId?: string;
  requiresTextId?: string;
  requires?: ConsentType[];
  revokeModal: {
    header: string;
    contents: string[];
    confirm: string;
    cancel: string;
  };
}

export type ChannelsConfigDictionary = { [key in ConsentChannelType]: string };
export type ConsentsConfigDictionary = {
  [key in ConsentType]: ConsentConfiguration;
};
