/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ServiceInfo } from './serviceInfo';
import { ServiceInfoSchema } from './serviceInfo';
import { ResourceModel } from './resourceModel';
import { ResourceModelSchema } from './resourceModel';
import { BarnType } from './barnType';
import { BarnTypeSchema } from './barnType';
import * as JoiObj from "joi";


    export const FamilieRelationBarnSchema = JoiObj.object({
        serviceInfo:
            
        JoiObj.link('#ServiceInfoSchema')

.allow(null)



        ,
        arveandel:
            
                    JoiObj.number()
                


.required()


        ,
        barnType:
            
        JoiObj.link('#BarnTypeSchema')


.required()


        ,
        efternavn:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        familieRelationId:
            
                    JoiObj.number()
                


.required()


        ,
        foedselsDato:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        fornavn:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        
    })
                .shared(ServiceInfoSchema)
                .shared(BarnTypeSchema)
.id('FamilieRelationBarnSchema')

export interface FamilieRelationBarn extends ResourceModel { 
    arveandel: number;
    barnType: BarnType;
    efternavn: string;
    familieRelationId: number;
    foedselsDato: string;
    fornavn: string;
}
export namespace FamilieRelationBarn {
}


