import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconModule } from '@pfa/icons';
import { MyCommonModule } from '@pfa/common';
import { CoreModule } from '@pfa/core';
import { RouterModule } from '@angular/router';
import { PensionCheckIndicationComponent } from './pension-check-indicator/pension-check-indicator.component';
import { PensionCheckComponent } from './pension-check.component';
import { MatButtonModule } from '@angular/material/button';
import { PfaFormModule } from '@pfaform';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PensionCheckCardViewerModule } from './pension-check-card-viewer/pension-check-card-viewer.module';

@NgModule({
  imports: [
    CommonModule,
    MyCommonModule,
    IconModule,
    CoreModule,
    RouterModule,
    MatButtonModule,
    PfaFormModule,
    MatTooltipModule,
    PensionCheckCardViewerModule
  ],
  declarations: [PensionCheckIndicationComponent, PensionCheckComponent],
  exports: [PensionCheckIndicationComponent, PensionCheckComponent],
  providers: []
})
export class PensionCheckModule {}
