import { Component } from '@angular/core';

@Component({
  selector: 'co-disable-overlay',
  templateUrl: './disable-overlay.component.html',
  styleUrls: ['./disable-overlay.component.scss']
})
export class DisableOverlayComponent {
  /*
    Use this component, if you need to block a whole page with blurring page content.
    For the correct usage and styling, use below example:

    <host.component.html>
      <co-disable-overlay>INSERT WHY PAGE IS DISABLED</co-disable-overlay>
      <div class="wrapper">INSERT PAGE CONTENT HERE</div>
    </host.component.html>

    host.component.scss
    :host { @include full-screen-width(); position: relative; }
    .wrapper { @include limit-screen-width(); }
  */
}
