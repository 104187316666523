import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import {
  MatSlideToggle,
  MatSlideToggleModule
} from '@angular/material/slide-toggle';
import { RouterModule } from '@angular/router';
import { CoreModule } from '@pfa/core';
import { PfaFormModule } from '@pfaform';
import { IconModule } from '@pfa/icons';
import { ChangePincodeComponent } from './change-pincode/change-pincode.component';
import { PincodeComponent } from './pincode.component';

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    PfaFormModule,
    MatSlideToggleModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    IconModule,
    MatButtonModule,
    RouterModule
  ],
  declarations: [PincodeComponent, ChangePincodeComponent],
  exports: [PincodeComponent, ChangePincodeComponent],
  providers: [MatSlideToggle]
})
export class PincodeModule {}
