/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Value } from './value';
import { ValueSchema } from './value';
import { Option } from './option';
import { OptionSchema } from './option';
import * as JoiObj from "joi";


    export const SurveyQuestionSchema = JoiObj.object({
        dependsOn:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        footnote:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        hidden:
            
                    JoiObj.boolean()
                

.allow(null)



        ,
        hideFromTargetGroups:
                JoiObj.array().items(
        
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

        )
        
.allow(null)



        ,
        id:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        introText:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        level:
            
                    JoiObj.number()
                


.required()


        ,
        options:
                JoiObj.array().items(
        
        JoiObj.link('#OptionSchema')

        )
        

.required()


        ,
        showToTargetGroups:
                JoiObj.array().items(
        
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

        )
        
.allow(null)



        ,
        title:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        tooltip:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        type:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        value:
            
        JoiObj.link('#ValueSchema')

.allow(null)



        
    })
                .shared(OptionSchema)
                .shared(ValueSchema)
.id('SurveyQuestionSchema')

export interface SurveyQuestion { 
    dependsOn?: string;
    footnote?: string;
    hidden?: boolean;
    hideFromTargetGroups?: Array<string>;
    id: string;
    introText?: string;
    level: number;
    options: Array<Option>;
    showToTargetGroups?: Array<string>;
    title: string;
    tooltip?: string;
    type: string;
    value?: Value;
}

