<form
  [formGroup]="loginForm"
  (ngSubmit)="logon()"
  *ngIf="isPassword()"
  autocomplete="off"
>
  <h4 [coContent]="'DL.MI01.C12'"></h4>
  <div class="password--wrapper">
    <mat-form-field color="accent">
      <mat-label>{{ 'DL.MI01.C13' | content }}</mat-label>
      <input
        matInput
        type="text"
        maxlength="10"
        [placeholder]="'DL.MI01.C13' | content"
        formControlName="username"
      />
      <mat-error
        *ngIf="
          loginForm.get('username').errors?.required &&
          loginForm.get('username').touched
        "
        [coContent]="'DL.MD01.C12'"
      ></mat-error>
      <mat-error
        *ngIf="loginForm.get('username').errors?.pattern"
        [coContent]="'DL.LI01.C132127'"
      ></mat-error>
    </mat-form-field>
    <mat-form-field color="accent">
      <mat-label>{{ 'DL.MI01.C14' | content }}</mat-label>
      <input
        type="password"
        matInput
        [placeholder]="'DL.MI01.C14' | content"
        formControlName="password"
      />
      <mat-error
        *ngIf="
          loginForm.get('password').errors?.required &&
          loginForm.get('password').touched
        "
        [coContent]="'DL.MD01.C12'"
      ></mat-error>
    </mat-form-field>
    <co-warning class="pincode-error" *ngIf="passwordError">
      <p [coContent]="passwordError"></p>
    </co-warning>
    <button
      type="submit"
      mat-raised-button
      class="spacing-unit--over"
      [ngClass]="{ 'button--disabled': !loginForm.valid }"
      [disabled]="!loginForm.valid"
      [coContent]="'DL.MI01.C18'"
    ></button>
  </div>
</form>
<!-- ONE TIME CODE  -->
<form
  [formGroup]="pinForm"
  (ngSubmit)="logon()"
  *ngIf="mechanism && mechanism.indexOf('macotp') > -1"
>
  <h2 [coContent]="'DL.LI01.C126'"></h2>
  <div class="spacing-unit--over">
    <mat-form-field class="mat-form-field--baseline pin--login" color="accent">
      <mat-label>{{ 'DL.LI01.C127' | content }}</mat-label>
      <input
        type="text"
        matInput
        maxlength="6"
        formControlName="otp"
        [placeholder]="'DL.LI01.C127' | content"
      />
      <span matPrefix class="otp-hint">{{ hint }} - </span>
      <mat-error
        *ngIf="
          pinForm.get('otp').errors?.required && pinForm.get('otp').touched
        "
        [coContent]="'DL.MD01.C12'"
      ></mat-error>
    </mat-form-field>
  </div>
  <co-warning class="pincode-error" *ngIf="otpError">
    <p [coContent]="otpError"></p>
  </co-warning>
  <button
    type="submit"
    mat-raised-button
    [ngClass]="{ 'button--disabled': !pinForm.valid || otpMaxRetries }"
    class="spacing-unit--over"
    [disabled]="!pinForm.valid || otpMaxRetries"
    [coContent]="'DL.LI01.C99'"
  ></button>
</form>
