<ng-container *ngIf="paragraf41Form && aftaler">
  <div mat-dialog-title>
    <h3>Korrektioner</h3>
    <co-icon-times (click)="closePopup()"></co-icon-times>
  </div>

  <mat-dialog-content>
    <ng-container *ngIf="advisorCorrections">
      <p
        *ngIf="advisorCorrections.changeAt"
        class="text--manchet"
        [coContent]="'DL.PP01.C767'"
        [coContentSubst]="
          (advisorCorrections.changeAt | date: 'dd.MM.yyyy') +
          '|' +
          advisorCorrections.aktor.employeeNumber
        "
      ></p>
      <form [formGroup]="paragraf41Form" autocomplete="off">
        <div
          *ngIf="advisorCorrections.fraValgtPolicerInvalid"
          [coContent]="'DL.PP01.C769'"
        ></div>
        <p [coContent]="'DL.PP01.I101'"></p>

        <co-info
          *ngIf="
            !pensionCustomer?.harPensionsinfo ||
            pensionCustomer?.pensionInfoDataInvalid
          "
        >
          <span [coContent]="'DL.PI01.C154'"></span>
        </co-info>

        <table class="pfa-table spacing-unit--under">
          <thead class="table--hide-header">
            <tr>
              <th
                id="pensionInfoPoliceChecked"
                class="checkbox-cell align--center"
              ></th>
              <th id="pension-info-police" [coContent]="'DL.PP01.C101'"></th>
              <th
                id="pension-info-police-aarlig-indbetaling"
                [coContent]="'DL.PP01.C102'"
              ></th>
              <th
                id="pension-info-police-egen-indbetaling"
                [coContent]="'DL.PP01.C103'"
                *ngIf="!isPartner"
              ></th>
              <th
                id="pension-info-police-opsparing"
                [coContent]="'DL.PP01.C29'"
              ></th>
              <th
                id="pension-info-police-opsparing"
                [coContent]="'DL.PP01.C30'"
              ></th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="let pensionInfoPlanForm of pensionInfoPlansForm.controls"
              [formGroup]="pensionInfoPlanForm"
            >
              <td>
                <mat-checkbox
                  [formControlName]="'selected'"
                  (change)="
                    checkParagraph41PrimaryPolice(
                      pensionInfoPlanForm.get('pensionPlan').value
                        .pensionsordning
                    )
                  "
                >
                </mat-checkbox>
              </td>
              <td>
                <div
                  [coContent]="
                    pensionInfoPlanForm.get('pensionPlan').value.selskab
                  "
                ></div>
                <div class="text--note">
                  {{
                    pensionInfoPlanForm.get('pensionPlan').value.pensionsordning
                  }}
                </div>
              </td>
              <td>
                <span>{{
                  pensionInfoPlanForm.get('pensionPlan').value.aarligIndbetaling
                    | currencyFormat
                }}</span>
              </td>
              <td class="number-cell" *ngIf="!isPartner">
                <span>{{
                  pensionInfoPlanForm.get('pensionPlan').value.egenIndbetaling
                    | currencyFormat
                }}</span>
              </td>
              <td>
                <div>
                  <span>{{
                    pensionInfoPlanForm.get('pensionPlan').value.opsparing
                      | currencyFormat
                  }}</span>
                </div>
                <div>
                  <span
                    *ngIf="
                      pensionInfoPlanForm.get('pensionPlan').value
                        .primaerPolice && advisorCorrections.paragraf41.ialt > 0
                    "
                    >+
                    {{
                      advisorCorrections.paragraf41.ialt | currencyFormat
                    }}</span
                  >
                </div>
              </td>
              <td>
                <span>{{
                  pensionInfoPlanForm.get('pensionPlan').value.oprettet
                }}</span>
              </td>
            </tr>
          </tbody>
        </table>
        <co-accordion
          *ngIf="advisorCorrections?.paragraf41?.kanSimulereOverfoersel"
        >
          <div
            customHeader
            class="text--manchet"
            [coContent]="'DL.PP01.C109'"
            [coContentSubst]="advisorCorrections?.paragraf41?.primaerPolicenr"
          ></div>

          <div [formGroup]="paragraph41TransferForm">
            <div
              *ngIf="advisorCorrections.paragraf41Invalid"
              [coContent]="'DL.PP01.C770'"
            ></div>

            <p class="text--manchet" [coContent]="'DL.PP01.C110'"></p>

            <mat-form-field color="accent">
              <mat-label>{{ 'DL.PP01.C111' | content }}</mat-label>
              <input
                matInput
                type="text"
                [placeholder]="'DL.PP01.C111' | content"
                [formControlName]="'kapitalpension'"
              />
            </mat-form-field>

            <mat-form-field color="accent">
              <mat-label>{{ 'DL.PP01.C112' | content }}</mat-label>
              <input
                matInput
                type="text"
                [placeholder]="'DL.PP01.C112' | content"
                [formControlName]="'aldersopsparing'"
              />
            </mat-form-field>

            <mat-form-field color="accent">
              <mat-label>{{ 'DL.PP01.C113' | content }}</mat-label>
              <input
                matInput
                type="text"
                [placeholder]="'DL.PP01.C113' | content"
                [formControlName]="'ratepension'"
              />
            </mat-form-field>

            <mat-form-field color="accent">
              <mat-label>{{ 'DL.PP01.C114' | content }}</mat-label>
              <input
                matInput
                type="text"
                [placeholder]="'DL.PP01.C114' | content"
                [formControlName]="'tidsbegraensetLivspension'"
              />
            </mat-form-field>

            <mat-form-field color="accent">
              <mat-label>{{ 'DL.PP01.C115' | content }}</mat-label>
              <input
                matInput
                type="text"
                [placeholder]="'DL.PP01.C115' | content"
                [formControlName]="'livsvarigLivrente'"
              />
            </mat-form-field>

            <div class="text--manchet" [coContent]="'DL.PP01.C116'"></div>

            <mat-form-field color="accent">
              <mat-label>{{ 'DL.PP01.C117' | content }}</mat-label>
              <input
                matInput
                type="text"
                [placeholder]="'DL.PP01.C117' | content"
                [formControlName]="'indskudArbejdsgiver'"
              />
            </mat-form-field>
          </div>
        </co-accordion>

        <ng-container *ngFor="let police of advisorCorrections.policer">
          <div
            *ngIf="police.orlov"
            class="spacing-unit--half-over"
            [coContent]="'DL.PP01.C781'"
            [coContentSubst]="
              police.pensionsordning +
              '|' +
              (police.orlovSlutDato | date: 'dd.MM.yyyy')
            "
          ></div>
        </ng-container>

        <div class="text--bold" [coContent]="'DL.PP01.C1156'"></div>
        <div
          *ngIf="advisorCorrections.manuelPolicerInvalid"
          [coContent]="'DL.PP01.C771'"
        ></div>

        <div *ngFor="let aftale of aftaler.controls; let index = index">
          <div [formGroup]="aftale">
            <div class="row">
              <div class="col-sm-3">
                <mat-autocomplete
                  #auto="matAutocomplete"
                  [displayWith]="displaySelskab.bind(this)"
                >
                  <mat-option
                    *ngFor="let selskab of filterSelskaber(aftale)"
                    [value]="selskab.id"
                  >
                    {{ selskab.name }}
                  </mat-option>
                </mat-autocomplete>

                <mat-form-field color="accent">
                  <mat-label>{{ 'DL.PP01.C453' | content }}</mat-label>
                  <input
                    matInput
                    [required]="true"
                    type="text"
                    [placeholder]="'DL.PP01.C453' | content"
                    [formControlName]="'selskab'"
                    [matAutocomplete]="auto"
                  />
                  <mat-error
                    *ngIf="aftale.get('selskab').errors"
                    [coContent]="'DL.PP01.C476'"
                  ></mat-error>
                </mat-form-field>
              </div>
              <div class="col-sm-3">
                <mat-form-field color="accent">
                  <mat-label>{{ 'DL.PP01.C455' | content }}</mat-label>
                  <input
                    matInput
                    [required]="true"
                    type="text"
                    [placeholder]="'DL.PP01.C455' | content"
                    [formControlName]="'id'"
                  />
                  <mat-error
                    *ngIf="aftale.get('id').errors"
                    [coContent]="'DL.PP01.C476'"
                  ></mat-error>
                </mat-form-field>
              </div>
              <div class="col-sm-3">
                <mat-form-field color="accent">
                  <mat-label>{{ 'DL.PP01.C720' | content }}</mat-label>
                  <mat-select
                    [formControlName]="'investeringsform'"
                    [required]="true"
                  >
                    <mat-option
                      *ngFor="let option of investeringsformer"
                      [value]="option.id"
                    >
                      {{ option.name | content }}
                    </mat-option>
                  </mat-select>

                  <mat-error
                    *ngIf="aftale.get('investeringsform').errors?.required"
                    [coContent]="'DL.PP01.C476'"
                  ></mat-error>
                </mat-form-field>
              </div>
              <div class="col-sm-3">
                <button
                  mat-raised-button
                  class="button-primary--level3"
                  (click)="removeManualPensionPlan(index)"
                >
                  Fjern
                </button>
                <button
                  mat-raised-button
                  class="button-primary--level3"
                  [coContent]="'DL.PP01.C462'"
                  (click)="addManualCoverage(aftale)"
                ></button>
              </div>
            </div>
          </div>

          <div
            class="row"
            *ngFor="
              let daekning of aftale.get('daekninger').controls;
              let ix = index
            "
          >
            <co-accordion class="col-sm-9" [title]="'DL.PP01.C486'">
              <div [formGroup]="daekning">
                <mat-form-field color="accent">
                  <mat-label>{{ 'DL.PP01.C456' | content }}</mat-label>
                  <mat-select
                    [formControlName]="'type'"
                    (selectionChange)="validate(daekning)"
                  >
                    <ng-container
                      *ngIf="aftale.get('investeringsform').value === 'C785'"
                    >
                      <mat-option
                        *ngFor="let option of banktyper"
                        [value]="option.id"
                      >
                        {{ option.name | content }}
                      </mat-option>
                    </ng-container>
                    <ng-container
                      *ngIf="aftale.get('investeringsform').value !== 'C785'"
                    >
                      <mat-option
                        *ngFor="let option of pensionstyper"
                        [value]="option.id"
                      >
                        {{ option.name | content }}
                      </mat-option>
                    </ng-container>
                  </mat-select>
                  <mat-error
                    *ngIf="daekning.get('type').errors?.required"
                    [coContent]="'DL.PP01.C476'"
                  ></mat-error>
                </mat-form-field>

                <div
                  *ngIf="
                    showInput(
                      daekning.get('type').value,
                      'pensionsalder',
                      aftale.get('investeringsform').value
                    )
                  "
                >
                  <mat-error
                    *ngIf="isPartner && !partnerExpectedPensionAge"
                    [coContent]="'DL.PP01.C476'"
                  ></mat-error>
                  <mat-form-field color="accent">
                    <mat-label>{{ 'DL.PP01.C457' | content }}</mat-label>
                    <input
                      matInput
                      [placeholder]="'DL.PP01.C457' | content"
                      type="text"
                      [formControlName]="'pensionsalder'"
                      name="pensionsalder"
                      id="pensionsalder"
                    />
                    <mat-error
                      *ngIf="daekning.get('pensionsalder').errors?.required"
                      [coContent]="'DL.PP01.C476'"
                    ></mat-error>
                    <mat-error
                      *ngIf="daekning.get('pensionsalder').errors?.min"
                      [coContent]="'DL.MD01.C21a'"
                      [coContentSubst]="
                        (daekning.get('pensionsalder').errors?.min?.min
                          | numberFormat) +
                        '|' +
                        ('Global.aar' | content)
                      "
                    >
                    </mat-error>
                    <mat-error
                      *ngIf="daekning.get('pensionsalder').errors?.max"
                      [coContent]="'DL.MD01.C19a'"
                      [coContentSubst]="
                        (daekning.get('pensionsalder').errors?.max?.max
                          | numberFormat) +
                        '|' +
                        ('Global.aar' | content)
                      "
                    >
                    </mat-error>
                    <mat-error
                      *ngIf="daekning.get('pensionsalder').errors?.pattern"
                      [coContent]="'DL.PP01.C475'"
                    >
                    </mat-error>
                  </mat-form-field>
                </div>
                <div
                  *ngIf="
                    showInput(
                      daekning.get('type').value,
                      'beskatning',
                      aftale.get('investeringsform').value
                    )
                  "
                >
                  <mat-form-field color="accent">
                    <mat-label>{{ 'DL.PP01.C458' | content }}</mat-label>
                    <mat-select [formControlName]="'beskatning'">
                      <mat-option
                        *ngFor="let option of beskatningstyper"
                        [value]="option.id"
                      >
                        {{ option.name | content }}
                      </mat-option>
                    </mat-select>
                    <mat-error
                      *ngIf="daekning.get('beskatning').errors"
                      [coContent]="'DL.PP01.C476'"
                    ></mat-error>
                  </mat-form-field>
                </div>

                <div
                  *ngIf="
                    showInput(
                      daekning.get('type').value,
                      'periode',
                      aftale.get('investeringsform').value
                    )
                  "
                >
                  <mat-form-field color="accent">
                    <mat-label>{{ 'DL.PP01.C459' | content }}</mat-label>
                    <input
                      matInput
                      [placeholder]="'DL.PP01.C459' | content"
                      type="text"
                      [formControlName]="'periode'"
                      name="periodeValid"
                      id="periodeValid"
                    />
                    <mat-error
                      *ngIf="
                        daekning.get('periode').errors?.required ||
                        daekning.get('periode').errors?.pattern
                      "
                      [coContent]="'DL.PP01.C477'"
                    ></mat-error>
                    <mat-error
                      *ngIf="
                        daekning.get('periode').errors?.min ||
                        daekning.get('periode').errors?.max
                      "
                      >{{ 'DL.PP01.C477' | content }} [{{
                        daekning.get('periode').errors.min
                      }}
                      - {{ daekning.get('periode').errors.max }}]</mat-error
                    >
                  </mat-form-field>
                </div>

                <div
                  *ngIf="
                    showInput(
                      daekning.get('type').value,
                      'slutdato',
                      aftale.get('investeringsform').value
                    )
                  "
                >
                  <mat-form-field color="accent">
                    <mat-label>{{ 'DL.PP01.C460' | content }}</mat-label>
                    <input
                      matInput
                      [matDatepicker]="slutdato"
                      [formControlName]="'slutdato'"
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="slutdato"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #slutdato></mat-datepicker>
                    <mat-error
                      *ngIf="daekning.get('slutdato').errors"
                      [coContent]="'DL.PP01.C476'"
                    ></mat-error>
                  </mat-form-field>
                </div>
                <div
                  *ngIf="
                    showInput(
                      daekning.get('type').value,
                      'udbetaling',
                      aftale.get('investeringsform').value
                    )
                  "
                >
                  <mat-form-field color="accent">
                    <mat-label>{{ 'DL.PP01.C461' | content }}</mat-label>
                    <input
                      matInput
                      coAmount
                      type="text"
                      [placeholder]="'DL.PP01.C461' | content"
                      [formControlName]="'udbetaling'"
                      name="udbetalingValid"
                      id="udbetalingValid"
                    />
                    <mat-hint [coContent]="'DL.PP01.I461'"></mat-hint>
                    <mat-error
                      *ngIf="daekning.get('udbetaling').errors"
                      [coContent]="'DL.PP01.C476'"
                    ></mat-error>
                  </mat-form-field>
                </div>
              </div>
            </co-accordion>
            <div class="col-sm-3">
              <button
                mat-raised-button
                class="button-primary--level3"
                (click)="removeManualCoverage(aftale, ix)"
              >
                Slet
              </button>
            </div>
          </div>
        </div>

        <button
          mat-raised-button
          class="button-primary--level3"
          [coContent]="'DL.PP01.C452'"
          (click)="addManualPensionPlan()"
        ></button>

        <hr class="hr--light spacing-unit--over spacing-unit--under" />

        <div class="text--bold" [coContent]="'DL.PP01.C1142'"></div>

        <div
          *ngFor="let forsikring of forsikringer.controls; let index = index"
        >
          <div [formGroup]="forsikring">
            <div class="row">
              <div class="col-sm-3">
                <mat-autocomplete
                  #auto="matAutocomplete"
                  [displayWith]="displaySelskab.bind(this)"
                >
                  <mat-option
                    *ngFor="let selskab of filterSelskaber(forsikring)"
                    [value]="selskab.id"
                  >
                    {{ selskab.name }}
                  </mat-option>
                </mat-autocomplete>

                <mat-form-field color="accent">
                  <mat-label>{{ 'DL.PP01.C1143' | content }}</mat-label>
                  <input
                    matInput
                    [required]="true"
                    type="text"
                    [placeholder]="'DL.PP01.C453' | content"
                    [formControlName]="'selskab'"
                    [matAutocomplete]="auto"
                  />
                  <mat-error
                    *ngIf="forsikring.get('selskab').errors"
                    [coContent]="'DL.PP01.C476'"
                  ></mat-error>
                </mat-form-field>
              </div>
              <div class="col-sm-3">
                <mat-form-field color="accent">
                  <mat-label>{{ 'DL.PP01.C1144' | content }}</mat-label>
                  <input
                    matInput
                    [required]="true"
                    type="text"
                    [placeholder]="'DL.PP01.C1144' | content"
                    [formControlName]="'id'"
                  />
                  <mat-error
                    *ngIf="forsikring.get('id').errors"
                    [coContent]="'DL.PP01.C476'"
                  ></mat-error>
                </mat-form-field>
              </div>

              <div class="col-sm-2">
                <mat-form-field color="accent">
                  <mat-label>{{ 'DL.PP01.C1145' | content }}</mat-label>
                  <mat-select
                    [formControlName]="'beskatningsform'"
                    [required]="true"
                  >
                    <mat-option
                      *ngFor="let option of beskatningstyper"
                      [value]="option.id"
                    >
                      {{ option.name | content }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-sm-2">
                <mat-form-field color="accent">
                  <mat-label>{{ 'DL.PP01.C1146' | content }}</mat-label>
                  <input
                    matInput
                    type="number"
                    [required]="true"
                    [placeholder]="'DL.PP01.C1146' | content"
                    [formControlName]="'livssikringsbeloeb'"
                  />
                  <mat-error
                    *ngIf="forsikring.get('id').errors"
                    [coContent]="'DL.PP01.C476'"
                  ></mat-error>
                </mat-form-field>
              </div>
              <div class="col-sm-2">
                <button
                  mat-raised-button
                  class="button-primary--level3"
                  [coContent]="'DL.PP01.C1148'"
                  (click)="removeManualInsurance(index)"
                ></button>
              </div>
            </div>
          </div>
        </div>

        <button
          mat-raised-button
          class="button-primary--level3"
          [coContent]="'DL.PP01.C1147'"
          (click)="addManualInsurance()"
        ></button>

        <div
          class="text--bold spacing-unit--over"
          [coContent]="'DL.PP01.C768'"
        ></div>

        <mat-form-field color="accent">
          <textarea
            matInput
            [formControlName]="'kommentar'"
            maxlength="2000"
            class="align--left"
          >
          </textarea>
        </mat-form-field>
      </form>
    </ng-container>
    <co-warning *ngIf="saveCorrectionsFailed">
      <span [coContent]="'DL.PP01.C940'"></span>
    </co-warning>
  </mat-dialog-content>
  <mat-dialog-actions>
    <div class="align--right">
      <button
        mat-raised-button
        class="button-secondary"
        (click)="closePopup()"
        [coContent]="'Global.Luk'"
      ></button>
    </div>

    <div class="float--right">
      <button
        mat-raised-button
        [disabled]="
          paragraf41Form.invalid ||
          paragraph41TransferForm.invalid ||
          (paragraf41Form.pristine &&
            pensionInfoPlansForm.pristine &&
            paragraph41TransferForm.pristine)
        "
        (click)="saveCorrections()"
      >
        <span>Gem</span>
      </button>
    </div>
  </mat-dialog-actions>
</ng-container>
