/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const SamtykkeSchema = JoiObj.object({
        consentId:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        returnCode:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        returnCodeText:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        statusCode:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        statusCodeText:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        statusDateTime:
            
                    JoiObj.object()
                


.required()


        
    })
.id('SamtykkeSchema')

export interface Samtykke { 
    consentId: string;
    returnCode: string;
    returnCodeText: string;
    statusCode: string;
    statusCodeText: string;
    statusDateTime: object;
}

