/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const QuotationStatusSchema = JoiObj.string().valid(
        'SIMULATION'
        ,
        'OFFER'
        ,
        'ACCEPTED'
        ,
        'REJECTED'
        ,
        'CANCELLED'
        ,
        'EXPIRED'
        ,
        'COMPLETED'
        
    )
.id('QuotationStatusSchema')

export type QuotationStatus = 'SIMULATION' | 'OFFER' | 'ACCEPTED' | 'REJECTED' | 'CANCELLED' | 'EXPIRED' | 'COMPLETED';

export const QuotationStatus = {
    Simulation: 'SIMULATION' as QuotationStatus,
    Offer: 'OFFER' as QuotationStatus,
    Accepted: 'ACCEPTED' as QuotationStatus,
    Rejected: 'REJECTED' as QuotationStatus,
    Cancelled: 'CANCELLED' as QuotationStatus,
    Expired: 'EXPIRED' as QuotationStatus,
    Completed: 'COMPLETED' as QuotationStatus
};

