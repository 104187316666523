<div mat-dialog-title>
  <co-icon-times (click)="closePopup()"></co-icon-times>
</div>
<mat-dialog-content class="sign-content" [ngClass]="{ loading: !initialized }">
  <co-loading [show]="!initialized"></co-loading>
  <ng-container *ngIf="initialized">
    <co-signing-mitid
      *ngIf="!useMobile && signingIframeResponse"
      [mitidSignObject]="signingIframeResponse"
      (signingCompleted)="handleSigningResponse($event)"
    ></co-signing-mitid>
    <co-signing-mitid-mobile
      *ngIf="useMobile && signingIframeResponse"
      [mitidSignObject]="signingIframeResponse"
      (signingCompleted)="handleSigningResponse($event)"
    ></co-signing-mitid-mobile>
  </ng-container>
</mat-dialog-content>
