/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const ViderefoerelseSammenligningRaekkeVaerdiTypeSchema = JoiObj.string().valid(
        'BELOEB'
        ,
        'PROCENT'
        ,
        'TJEK'
        ,
        'KRYDS'
        
    )
.id('ViderefoerelseSammenligningRaekkeVaerdiTypeSchema')

export type ViderefoerelseSammenligningRaekkeVaerdiType = 'BELOEB' | 'PROCENT' | 'TJEK' | 'KRYDS';

export const ViderefoerelseSammenligningRaekkeVaerdiType = {
    Beloeb: 'BELOEB' as ViderefoerelseSammenligningRaekkeVaerdiType,
    Procent: 'PROCENT' as ViderefoerelseSammenligningRaekkeVaerdiType,
    Tjek: 'TJEK' as ViderefoerelseSammenligningRaekkeVaerdiType,
    Kryds: 'KRYDS' as ViderefoerelseSammenligningRaekkeVaerdiType
};

