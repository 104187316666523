/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const GodkendSvarSchema = JoiObj.object({
        byggepdffejl:
            
                    JoiObj.boolean()
                


.required()


        ,
        transaktionfejl:
            
                    JoiObj.boolean()
                


.required()


        ,
        foersttransaktionfejl:
            
                    JoiObj.boolean()
                


.required()


        ,
        gemkvitteringfejl:
            
                    JoiObj.boolean()
                


.required()


        ,
        spoergeskema:
            
                    JoiObj.boolean()
                


.required()


        ,
        fejl:
            
                    JoiObj.boolean()
                


.required()


        ,
        fejlkode:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        fejlTekst:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        cancelled:
            
                    JoiObj.boolean()
                


.required()


        
    })
.id('GodkendSvarSchema')

export interface GodkendSvar { 
    byggepdffejl: boolean;
    transaktionfejl: boolean;
    foersttransaktionfejl: boolean;
    gemkvitteringfejl: boolean;
    spoergeskema: boolean;
    fejl: boolean;
    fejlkode?: string;
    fejlTekst?: string;
    cancelled: boolean;
}

