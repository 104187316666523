<div class="mat-typography">
  <co-loading
    [show]="(!form || formSendInProgress) && !formLoadFailed"
  ></co-loading>
  <ng-container *ngIf="formLoadFailed">
    <div class="form-row">
      <h1 *ngIf="tekster">{{ tekster['DL.BE02.C1014'] }}</h1>
      <h1 *ngIf="!tekster && language === 'da'">
        Der er desværre sket en teknisk fejl. Forsøg igen senere.
      </h1>
      <h1 *ngIf="!tekster && language === 'en'">
        Unfortunately, a technical error occurred. Try again later.
      </h1>
    </div>
  </ng-container>
  <ng-container *ngIf="form">
    <form (ngSubmit)="doSubmitForm()" [formGroup]="form">
      <ng-container *ngIf="!formSentSuccess">
        <div
          *ngFor="let formField of dynamicForm.FormFields; let i = index"
          class="display-flex"
        >
          <div
            class="message--container"
            *ngIf="!formField.Hide"
            [ngClass]="{
              'form-row':
                formField.Type !== 'subtitle' &&
                formField.Type !== 'sectionheader'
            }"
          >
            <div class="message--column">
              <h1 *ngIf="formField.Type === 'title'">
                {{ formField.TemplateOptions?.Label }}
              </h1>
              <p
                class="text--manchet"
                *ngIf="formField.Type === 'leadparagraph'"
              >
                {{ formField.TemplateOptions?.Label }}
              </p>
              <h2
                *ngIf="
                  formField.Type === 'subtitle' ||
                  formField.Type === 'sectionheader'
                "
              >
                {{ formField.TemplateOptions?.Label }}
              </h2>
              <p *ngIf="formField.Type === 'paragraph'">
                {{ formField.TemplateOptions?.Label }}
              </p>
              <div
                *ngIf="
                  (formField.Type === 'datetimepicker' ||
                    formField.Type === 'radio') &&
                  formField.TemplateOptions?.Label
                "
              >
                <label [attr.for]="formField.Key"
                  >{{ formField.TemplateOptions.Label
                  }}<span *ngIf="formField.TemplateOptions?.Required">{{
                    requiredMark
                  }}</span>
                </label>
              </div>

              <mat-form-field
                *ngIf="
                  formField.Type === 'input' ||
                  formField.Type === 'numeric' ||
                  formField.Type === 'amount' ||
                  formField.Type === 'cpr' ||
                  formField.Type === 'textarea' ||
                  formField.Type === 'select' ||
                  formField.Type === 'classification' ||
                  formField.Type === 'cvr'
                "
                [ngClass]="{
                  'display-inline-block': formField.Type === 'select'
                }"
                color="accent"
              >
                <mat-label *ngIf="formField.TemplateOptions?.Label">{{
                  formField.TemplateOptions.Label
                }}</mat-label>

                <!-- TEXT INPUT / CPR / CVR / NUMERIC -->
                <input
                  matInput
                  *ngIf="
                    formField.Type === 'input' ||
                    formField.Type === 'numeric' ||
                    formField.Type === 'cpr' ||
                    formField.Type === 'cvr'
                  "
                  [required]="formField.TemplateOptions?.Required"
                  [formControlName]="formField.Key"
                  [maxlength]="formField.TemplateOptions.MaxLength"
                  [attr.name]="formField.Key"
                  [placeholder]="
                    formField.TemplateOptions?.Placeholder
                      ? formField.TemplateOptions?.Placeholder
                      : ''
                  "
                />

                <!-- AMOUNT -->
                <input
                  matInput
                  *ngIf="formField.Type === 'amount'"
                  coAmount
                  [coAmountDecimals]="
                    formField.TemplateOptions?.ShowDecimals ? 2 : 0
                  "
                  [coAmountUnit]="
                    formField.TemplateOptions?.ShowCurrency ? 'kr.' : ''
                  "
                  [required]="formField.TemplateOptions?.Required"
                  [formControlName]="formField.Key"
                  [placeholder]="
                    formField.TemplateOptions?.Placeholder
                      ? formField.TemplateOptions?.Placeholder
                      : ''
                  "
                />

                <!-- TEXTAREA -->
                <textarea
                  matInput
                  *ngIf="formField.Type === 'textarea'"
                  [formControlName]="formField.Key"
                  rows="6"
                ></textarea>

                <!-- DROPDOWN -->
                <mat-select
                  [required]="formField.TemplateOptions?.Required"
                  *ngIf="formField.Type === 'select'"
                  [formControlName]="formField.Key"
                >
                  <mat-option
                    *ngFor="let option of formField.TemplateOptions.Options"
                    [value]="option.Value"
                  >
                    {{ option.Label }}
                  </mat-option>
                </mat-select>

                <!-- CLASSIFICATION -->
                <mat-select
                  [required]="formField.TemplateOptions?.Required"
                  *ngIf="formField.Type === 'classification'"
                  [formControlName]="formField.Key"
                >
                  <mat-option
                    *ngFor="let option of formField.TemplateOptions.Options"
                    [value]="option.ValueToDisplay"
                  >
                    {{ option.Label }}
                  </mat-option>
                </mat-select>

                <mat-hint *ngIf="formField.TemplateOptions?.Description">
                  {{ formField.TemplateOptions.Description }}
                </mat-hint>
              </mat-form-field>

              <!-- DATEPICKER -->
              <mat-form-field
                *ngIf="formField.Type === 'datepicker'"
                color="accent"
                (click)="i.open()"
              >
                <mat-label>{{ formField.TemplateOptions?.Label }}</mat-label>
                <input
                  matInput
                  [matDatepicker]="i"
                  [formControlName]="formField.Key"
                  [required]="formField.TemplateOptions?.Required"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="i"
                ></mat-datepicker-toggle>
                <mat-datepicker #i></mat-datepicker>
                <mat-hint *ngIf="formField.TemplateOptions?.Description">
                  {{ formField.TemplateOptions.Description }}
                </mat-hint>
              </mat-form-field>

              <!-- CHEKCBOX -->
              <mat-checkbox
                *ngIf="formField.Type === 'checkbox'"
                [formControlName]="formField.Key"
                [required]="formField.TemplateOptions?.Required"
              >
                <span class="checkbox-label">{{
                  formField.TemplateOptions?.Label
                }}</span>
                <span *ngIf="formField.TemplateOptions?.Required">{{
                  requiredMark
                }}</span>
              </mat-checkbox>

              <!-- RADIO BUTTONS -->
              <mat-radio-group
                *ngIf="formField.Type === 'radio'"
                [formControlName]="formField.Key"
              >
                <mat-radio-button
                  *ngFor="let option of formField.TemplateOptions.Options"
                  value="{{ option.Value }}"
                >
                  {{ option.Label }}
                </mat-radio-button>
              </mat-radio-group>

              <div *ngIf="formField.Type === 'datetimepicker'">
                <co-datetimepicker
                  [formControlName]="formField.Key"
                  [dateLabel]="datetimepickerTexts['DL.BE02.C1023']"
                  [hourLabel]="datetimepickerTexts['DL.BE02.C1025']"
                  [minuteLabel]="datetimepickerTexts['DL.BE02.C1027']"
                  [required]="formField.TemplateOptions?.Required"
                  [description]="formField.TemplateOptions?.Description"
                ></co-datetimepicker>
              </div>

              <div
                *ngIf="
                  formField.TemplateOptions?.Description &&
                  (formField.Type === 'datetimepicker' ||
                    formField.Type === 'radio')
                "
              >
                <span>{{ formField.TemplateOptions.Description }}</span>
              </div>
              <div
                *ngIf="
                  form.controls[formField.Key]?.errors?.required &&
                  (!form.controls[formField.Key]?.pristine ||
                    enableAllValidationErrors)
                "
              >
                {{ tekster['DL.BE02.C1008'] }}
              </div>
              <div
                *ngIf="
                  form.controls[formField.Key]?.errors?.min &&
                  (!form.controls[formField.Key]?.pristine ||
                    enableAllValidationErrors)
                "
              >
                {{
                  tekster['DL.BE02.C1009'].replace(
                    '{0}',
                    formField.TemplateOptions.Min
                  )
                }}
              </div>
              <div
                *ngIf="
                  form.controls[formField.Key]?.errors?.max &&
                  (!form.controls[formField.Key]?.pristine ||
                    enableAllValidationErrors)
                "
              >
                {{
                  tekster['DL.BE02.C1010'].replace(
                    '{0}',
                    formField.TemplateOptions.Max
                  )
                }}
              </div>
              <div
                *ngIf="
                  form.controls[formField.Key]?.errors?.minlength &&
                  (!form.controls[formField.Key]?.pristine ||
                    enableAllValidationErrors)
                "
              >
                {{
                  tekster['DL.BE02.C1011'].replace(
                    '{0}',
                    formField.TemplateOptions.MinLength
                  )
                }}
              </div>
              <div
                *ngIf="
                  form.controls[formField.Key]?.errors?.maxlength &&
                  (!form.controls[formField.Key]?.pristine ||
                    enableAllValidationErrors)
                "
              >
                {{
                  tekster['DL.BE02.C1012'].replace(
                    '{0}',
                    formField.TemplateOptions.MaxLength
                  )
                }}
              </div>
              <div
                *ngIf="
                  form.controls[formField.Key]?.errors?.pattern &&
                  (!form.controls[formField.Key]?.pristine ||
                    enableAllValidationErrors)
                "
              >
                {{ tekster['DL.BE02.C1013'] }}
              </div>
            </div>
          </div>
          <div class="message--add--remove--items">
            <button
              *ngIf="allowedToAddFormFieldByKey.get(formField.Key)"
              mat-raised-button
              class="button-primary--level3"
              (click)="addNewFormFieldOfSameType(formField); (false)"
            >
              <co-icon-add class="icon"></co-icon-add>
              <span>{{ tekster['DL.BE02.C1050'] }}</span>
            </button>
            <button
              *ngIf="allowedToRemoveFormFieldByKey.get(formField.Key)"
              mat-raised-button
              class="button-primary--level3"
              (click)="removeFormField(formField); (false)"
            >
              <co-icon-delete class="icon"></co-icon-delete>
              <span>{{ tekster['DL.BE02.C1051'] }}</span>
            </button>
          </div>
        </div>
        <co-contact-info
          *ngIf="contactData"
          (contactInfoRef)="contactInfoFormInit($event)"
          [enableAllValidationErrors]="enableAllValidationErrors"
          [contactData]="contactData"
        ></co-contact-info>
        <co-attachment
          *ngIf="showAttachment"
          [attachments]="attachments"
          (addAttachment)="addAttachment($event)"
          (removeAttachment)="removeAttachment($event)"
          [text]="attachmentTexts"
          [channel]="channel"
          [minAttachmentsNumber]="dynamicForm.MinAttachmentsNumber"
          [maxAttachmentsNumber]="dynamicForm.MaxAttachmentsNumber"
          [limitSize]="20 * 1024 * 1024"
        >
        </co-attachment>
        <co-info *ngIf="showAttachment">
          {{ tekster['DL.BE02.C1049'] }}
        </co-info>
        <co-alert
          *ngIf="!documentNumberValid"
          class="alert-warning alert-inline"
        >
          {{ tekster['DL.BE02.C1048'] }}
        </co-alert>
        <div class="form-row submit-row" *ngIf="!hideSendButton">
          <button
            type="submit"
            [disabled]="submitButtonDisabled && !formSentFailed"
            mat-raised-button
            color="primary"
            class="mat-raised-button mat-primary"
          >
            {{ tekster['DL.BE02.C1017'] }}
          </button>
        </div>
        <div id="attachment-scroll-id"></div>
        <co-alert *ngIf="formSentFailed" class="alert-warning alert-inline">
          {{ tekster['DL.BE02.C1018'] }}
        </co-alert>
      </ng-container>
      <ng-container *ngIf="formSentSuccess">
        <ng-container *ngFor="let formField of dynamicForm.FormFields">
          <h2 *ngIf="formField.Type?.toLowerCase() === 'onsuccessmessage'">
            {{ formField.TemplateOptions?.Label }}
          </h2>
        </ng-container>
      </ng-container>
    </form>
  </ng-container>
</div>
