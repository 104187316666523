/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const KursvaernSchema = JoiObj.object({
        gyldigFraDato:
            
                    JoiObj.object()
                


.required()


        ,
        gyldigTilDato:
            
                    JoiObj.object()
                


.required()


        ,
        procentsats:
            
                    JoiObj.number()
                


.required()


        ,
        rentegruppe:
            
                    JoiObj.number()
                


.required()


        
    })
.id('KursvaernSchema')

export interface Kursvaern { 
    gyldigFraDato: object;
    gyldigTilDato: object;
    procentsats: number;
    rentegruppe: number;
}

