/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ServiceInfo } from './serviceInfo';
import { ServiceInfoSchema } from './serviceInfo';
import { ResourceModel } from './resourceModel';
import { ResourceModelSchema } from './resourceModel';
import { DokumentMeta } from './dokumentMeta';
import { DokumentMetaSchema } from './dokumentMeta';
import { ProcesseringsStatus } from './processeringsStatus';
import { ProcesseringsStatusSchema } from './processeringsStatus';
import * as JoiObj from "joi";


    export const DokumenterInfoDetailsSchema = JoiObj.object({
        serviceInfo:
            
        JoiObj.link('#ServiceInfoSchema')

.allow(null)



        ,
        dokumentinfo:
                JoiObj.array().items(
        
        JoiObj.link('#DokumentMetaSchema')

        )
        

.required()


        ,
        dropDownElementer:
                JoiObj.array().items(
        
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

        )
        

.required()


        ,
        statusListe:
                JoiObj.array().items(
        
        JoiObj.link('#ProcesseringsStatusSchema')

        )
        

.required()


        
    })
                .shared(ServiceInfoSchema)
                .shared(DokumentMetaSchema)
                .shared(ProcesseringsStatusSchema)
.id('DokumenterInfoDetailsSchema')

export interface DokumenterInfoDetails extends ResourceModel { 
    dokumentinfo: Array<DokumentMeta>;
    dropDownElementer: Array<string>;
    statusListe: Array<ProcesseringsStatus>;
}

