import {
  UntypedFormArray,
  UntypedFormGroup,
  UntypedFormControl,
  Validators
} from '@angular/forms';
import { Injectable, OnDestroy } from '@angular/core';
import {
  DuInvesterer,
  FondFordeling,
  KonceptFordeling,
  InvesteringPolice
} from '@pfa/gen';
import { InvestmentGuideInfo } from '@pfa/models';
import {
  AllocationType,
  DepositFundAllocation,
  Fund,
  FundAllocation,
  SavingFundAllocation,
  ConceptAllocation,
  DepositAllocation,
  SavingAllocation,
  YouInvest,
  PolicePossiblesAnswer,
  ExtendedConcepts,
  InvestmentPolice
} from '../../../app/investment/concepts/you-invest/you-invest.model';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class YouInvestService implements OnDestroy {
  private readonly subs = new Subscription();

  public ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  public setConceptProperties(
    policy: PolicePossiblesAnswer,
    extendedConcepts: ExtendedConcepts,
    conceptKeys: string[]
  ): PolicePossiblesAnswer {
    const youInvest = policy.police.duInvesterer;

    youInvest.simpelIndbetaling.type = AllocationType.DEPOSIT;
    youInvest.arbejdsgiverObligatoriskIndbetaling.type = AllocationType.DEPOSIT;
    youInvest.medarbejderObligatoriskIndbetaling.type = AllocationType.DEPOSIT;
    youInvest.medarbejderFrivilligIndbetaling.type = AllocationType.DEPOSIT;
    youInvest.blandetIndbetaling.type = AllocationType.DEPOSIT;

    youInvest.simpelOpsparing.type = AllocationType.SAVING;
    youInvest.arbejdsgiverObligatoriskOpsparing.type = AllocationType.SAVING;
    youInvest.medarbejderObligatoriskOpsparing.type = AllocationType.SAVING;
    youInvest.medarbejderFrivilligOpsparing.type = AllocationType.SAVING;
    youInvest.blandetOpsparing.type = AllocationType.SAVING;

    youInvest.simpelIndbetaling.beloeb =
      Math.round(youInvest.simpelIndbetaling.beloeb * 100) / 100;

    youInvest.simpelIndbetaling.duInvesterer =
      Math.round(youInvest.simpelIndbetaling.duInvesterer * 100) / 100;

    youInvest.simpelIndbetaling.origDuInvesterer =
      Math.round(youInvest.simpelIndbetaling.origDuInvesterer * 100) / 100;

    youInvest.simpelIndbetaling.origPfaInvesterer =
      Math.round(youInvest.simpelIndbetaling.origPfaInvesterer * 100) / 100;

    youInvest.simpelOpsparing.beloeb =
      Math.round(youInvest.simpelOpsparing.beloeb * 100) / 100;

    youInvest.simpelOpsparing.duInvesterer =
      Math.round(youInvest.simpelOpsparing.duInvesterer * 100) / 100;

    youInvest.simpelOpsparing.origDuInvesterer =
      Math.round(youInvest.simpelOpsparing.origDuInvesterer * 100) / 100;

    youInvest.simpelOpsparing.origPfaInvesterer =
      Math.round(youInvest.simpelOpsparing.origPfaInvesterer * 100) / 100;

    youInvest.simpelIndbetaling.disabled = this.setConceptDisabled(
      youInvest.simpelIndbetaling
    );

    youInvest.simpelOpsparing.disabled = this.setConceptDisabled(
      youInvest.simpelOpsparing
    );

    conceptKeys.forEach(key => {
      const concept = extendedConcepts[key];

      concept.disabled = this.setConceptDisabled(concept);
      concept.beloeb = Math.round(concept.beloeb * 100) / 100;
      concept.duInvesterer = Math.round(concept.duInvesterer * 100) / 100;
      concept.origDuInvesterer =
        Math.round(concept.origDuInvesterer * 100) / 100;
      concept.origPfaInvesterer =
        Math.round(concept.origPfaInvesterer * 100) / 100;
    });

    policy.police.duInvesterer = youInvest;
    return policy;
  }

  public initExtendedConcepts(youInvest: YouInvest): ExtendedConcepts {
    const concepts: ExtendedConcepts = {
      arbejdsgiverObligatoriskIndbetaling:
        youInvest.arbejdsgiverObligatoriskIndbetaling,
      medarbejderObligatoriskIndbetaling:
        youInvest.medarbejderObligatoriskIndbetaling,
      medarbejderFrivilligIndbetaling:
        youInvest.medarbejderFrivilligIndbetaling,
      blandetIndbetaling: youInvest.blandetIndbetaling,
      arbejdsgiverObligatoriskOpsparing:
        youInvest.arbejdsgiverObligatoriskOpsparing,
      medarbejderObligatoriskOpsparing:
        youInvest.medarbejderObligatoriskOpsparing,
      medarbejderFrivilligOpsparing: youInvest.medarbejderFrivilligOpsparing,
      blandetOpsparing: youInvest.blandetOpsparing
    };

    return concepts;
  }

  public initConceptTexts(
    police: InvestmentPolice,
    savingFundsAllocation: SavingFundAllocation,
    conceptKeys: string[],
    extendedConcepts: ExtendedConcepts
  ): YouInvest {
    const youInvest = police.duInvesterer;
    const isGageRelated = youInvest.simpelIndbetaling.beloeb ? true : false;

    this.buildSimplePayment(youInvest, isGageRelated);
    this.buildSimpleSavings(youInvest, police, savingFundsAllocation);
    this.buildExtendedConcepts(conceptKeys, extendedConcepts, isGageRelated);

    return youInvest;
  }

  public initDepositFundAllocationExtras(
    fundAllocation: DepositFundAllocation
  ): DepositFundAllocation {
    fundAllocation = this.initFundAllocationExtras(fundAllocation);
    fundAllocation.origManuelInvestering = fundAllocation.manuelInvestering;

    const funds = this.concatFunds(fundAllocation.mulige);
    const items = this.concatFunds(fundAllocation.fordeling);
    funds.forEach(fund => {
      if (fund?.blandet) {
        fundAllocation.hasMixedFunds = true;
      }

      const exists = items.map(item => item.id).includes(fund.id);
      if (exists) {
        fund.add = true;
      }
    });
    fundAllocation.mulige = this.setFundsOrder(fundAllocation.mulige);
    fundAllocation.mulige = this.filterAddedFunds(fundAllocation.mulige);
    return fundAllocation;
  }

  public initSavingFundAllocationExtras(
    fundAllocation: SavingFundAllocation
  ): SavingFundAllocation {
    fundAllocation = this.initFundAllocationExtras(fundAllocation);
    fundAllocation.disabled = fundAllocation.hasClosedFunds;
    fundAllocation.buyAndSell = fundAllocation.hasRestrictedFunds
      ? fundAllocation.hasRestrictedFunds
      : false;

    let funds: Fund[];
    let possible: FundAllocation;

    if (fundAllocation.buyAndSell) {
      funds = this.concatFunds(fundAllocation.muligeKoebOgSalg);
      possible = fundAllocation.muligeKoebOgSalg;
    } else {
      funds = this.concatFunds(fundAllocation.muligeFordeling);
      possible = fundAllocation.muligeFordeling;
    }

    const items = this.concatFunds(fundAllocation.fordeling);
    funds.forEach(fund => {
      if (fund?.blandet) {
        fundAllocation.hasMixedFunds = true;
      }

      const exists = items.map(item => item.id).includes(fund.id);
      if (exists) {
        fund.add = true;
      }
    });

    possible = this.setFundsOrder(possible);
    this.filterAddedFunds(possible);

    return fundAllocation;
  }

  public initFundAllocationExtras<
    T extends SavingFundAllocation | DepositFundAllocation
  >(fundAllocation: T): T {
    let totalShare = 0;
    let totalSavings = 0;

    fundAllocation.fundsValid = false;
    fundAllocation.hasClosedFunds = false;
    fundAllocation.buyAndSell = false;

    const funds = this.concatFunds(fundAllocation.fordeling);
    funds.forEach(fund => {
      if (fund) {
        if (fund.status !== 'Aaben') {
          fundAllocation.hasClosedFunds = true;
        }
        if (!fund.kanKoebes) {
          fundAllocation.hasRestrictedFunds = true;
        }
        totalShare += fund.origAndel;
        if (fund.opsparing) {
          totalSavings += fund.opsparing;
        }
      }
    });

    fundAllocation.totalShare = totalShare;
    fundAllocation.totalSavings = totalSavings;
    return fundAllocation;
  }

  public buildNewYouInvest(): DuInvesterer {
    return {
      harDuInvesterer: null,
      harOpsparingDuInvesterer: null,
      muligt: null,
      opsparing: null,
      kundekapitalBeloeb: null,
      fordeling: null,
      simpelIndbetaling: null,
      arbejdsgiverObligatoriskIndbetaling: null,
      medarbejderObligatoriskIndbetaling: null,
      medarbejderFrivilligIndbetaling: null,
      blandetIndbetaling: null,
      simpelOpsparing: null,
      arbejdsgiverObligatoriskOpsparing: null,
      medarbejderObligatoriskOpsparing: null,
      medarbejderFrivilligOpsparing: null,
      blandetOpsparing: null,
      indbetalingFondFordeling: null,
      opsparingFondFordeling: null,
      serviceInfo: null
    };
  }

  public concatFunds(allocation: FundAllocation): Fund[] {
    return allocation.aktieFonde.concat(
      allocation.profilFondeOgBlandetKategoriFonde,
      allocation.obligationFonde,
      allocation.oevrigeFonde
    );
  }

  public filterAddedFunds(allocation: FundAllocation): FundAllocation {
    allocation.aktieFonde = allocation.aktieFonde.filter(fund => !fund.add);
    allocation.obligationFonde = allocation.obligationFonde.filter(
      fund => !fund.add
    );
    allocation.oevrigeFonde = allocation.oevrigeFonde.filter(fund => !fund.add);
    allocation.profilFondeOgBlandetKategoriFonde =
      allocation.profilFondeOgBlandetKategoriFonde?.filter(fund => !fund.add);

    return allocation;
  }

  public buildNewConceptAllocation(
    originalConceptAllocation: KonceptFordeling,
    newAllocation: number
  ): ConceptAllocation {
    return {
      pfaInvesterer:
        newAllocation === originalConceptAllocation.duInvestererMax
          ? originalConceptAllocation.pfaInvestererMin
          : Math.round((100 - newAllocation + Number.EPSILON) * 100) / 100,
      origPfaInvesterer: originalConceptAllocation.origPfaInvesterer,
      duInvesterer: Math.round((newAllocation + Number.EPSILON) * 100) / 100,
      origDuInvesterer: originalConceptAllocation.origDuInvesterer,
      pfaInvestererMin: null,
      pfaInvestererMax: null,
      duInvestererMin: null,
      duInvestererMax: null,
      procent: null,
      beloeb: null,
      serviceInfo: null
    };
  }

  public buildNewConceptAllocationIfChanged(
    originalConceptAllocation: KonceptFordeling,
    newAllocation: number
  ): ConceptAllocation {
    return this.buildNewConceptAllocationCheckChanged(
      originalConceptAllocation,
      newAllocation,
      false
    );
  }

  public buildNewConceptAllocationCheckChanged(
    originalConceptAllocation: KonceptFordeling,
    newAllocation: number,
    skipChangedCheck: boolean
  ): ConceptAllocation {
    return skipChangedCheck ||
      originalConceptAllocation?.origDuInvesterer !== newAllocation
      ? this.buildNewConceptAllocation(originalConceptAllocation, newAllocation)
      : null;
  }

  public buildNewFondFordeling(): FondFordeling {
    return {
      aktieFonde: [],
      obligationFonde: [],
      profilFondeOgBlandetKategoriFonde: [],
      oevrigeFonde: [],
      serviceInfo: null
    };
  }

  public buildFundsAllocationWrapperForm(
    fundAllocation: DepositFundAllocation | SavingFundAllocation,
    investmentGuideInfo: InvestmentGuideInfo,
    skipFunds?: boolean
  ): UntypedFormArray {
    const formWrapper = new UntypedFormArray([
      new UntypedFormGroup({
        headerText: new UntypedFormControl('DL.INV.C133'),
        type: new UntypedFormControl('SHARES'),
        funds: this.buildFundsAllocationForm(
          skipFunds ? [] : fundAllocation.fordeling.aktieFonde,
          investmentGuideInfo,
          fundAllocation
        )
      }),
      new UntypedFormGroup({
        headerText: new UntypedFormControl('DL.INV.C135'),
        type: new UntypedFormControl('BONDS'),
        funds: this.buildFundsAllocationForm(
          skipFunds ? [] : fundAllocation.fordeling.obligationFonde,
          investmentGuideInfo,
          fundAllocation
        )
      }),
      new UntypedFormGroup({
        headerText: new UntypedFormControl('DL.INV.C334'),
        type: new UntypedFormControl('PROFILE_MIX'),
        funds: this.buildFundsAllocationForm(
          skipFunds
            ? []
            : fundAllocation.fordeling.profilFondeOgBlandetKategoriFonde,
          investmentGuideInfo,
          fundAllocation
        )
      }),
      new UntypedFormGroup({
        headerText: new UntypedFormControl('DL.INV.C136'),
        type: new UntypedFormControl('OTHERS'),
        funds: this.buildFundsAllocationForm(
          skipFunds ? [] : fundAllocation.fordeling.oevrigeFonde,
          investmentGuideInfo,
          fundAllocation
        )
      })
    ]);
    fundAllocation.newTotalSharePercentage = null;
    fundAllocation.newTotalBuySharePercentage = null;
    fundAllocation.newTotalSellSharePercentage = null;
    fundAllocation.hasFundChange = false;
    fundAllocation.fundsValid = false;
    this.sumAndValidateFundInput(formWrapper, fundAllocation);

    const valueChange = formWrapper.valueChanges.subscribe(() => {
      this.sumAndValidateFundInput(formWrapper, fundAllocation);
    });

    this.subs.add(valueChange);

    return formWrapper;
  }

  public buildFundsAllocationForm(
    funds: Fund[],
    investmentGuideInfo: InvestmentGuideInfo,
    fundAllocation: DepositFundAllocation | SavingFundAllocation
  ): UntypedFormArray {
    const fundsFormArray = new UntypedFormArray([]);
    funds.forEach(fund => {
      const isFundInputDisabled =
        !investmentGuideInfo.isGuideCompleted || fund.status !== 'Aaben';
      fundsFormArray.push(
        this.buildNewFundFormGroup(
          fund,
          isFundInputDisabled,
          null,
          fundAllocation
        )
      );
    });
    return fundsFormArray;
  }

  public buildNewFundFormGroup(
    fund: Fund,
    isFundInputDisabled: boolean,
    andel: number | null,
    fundAllocation: DepositFundAllocation | SavingFundAllocation
  ): UntypedFormGroup {
    return new UntypedFormGroup({
      id: new UntypedFormControl(fund.id),
      andel: new UntypedFormControl(
        {
          value: andel,
          disabled: isFundInputDisabled
        },
        !fundAllocation.buyAndSell
          ? {
              validators: [
                Validators.required,
                Validators.min(0),
                Validators.max(100)
              ]
            }
          : null
      ),
      koebsAndel: new UntypedFormControl(
        {
          value: 0,
          disabled: isFundInputDisabled || !fund.kanKoebes
        },
        {
          validators: [
            Validators.required,
            Validators.min(0),
            Validators.max(100)
          ]
        }
      ),
      salgsAndel: new UntypedFormControl(
        {
          value: 0,
          disabled: isFundInputDisabled
        },
        {
          validators: [
            Validators.required,
            Validators.min(0),
            Validators.max(100)
          ]
        }
      ),
      expanded: new UntypedFormControl(false),
      risikoklasse: new UntypedFormControl(fund.risikoklasse),
      opsparing: new UntypedFormControl(fund.opsparing),
      data: new UntypedFormControl(fund)
    });
  }

  public toFundAllocation(fundForm: UntypedFormArray): FondFordeling {
    const fondFordeling = this.buildNewFondFordeling();
    fundForm.controls.forEach(formGroup => {
      switch (formGroup.get('type').value) {
        case 'SHARES':
          {
            fondFordeling.aktieFonde = fondFordeling.aktieFonde.concat(
              formGroup.get('funds').value
            );
          }
          break;
        case 'BONDS':
          {
            fondFordeling.obligationFonde =
              fondFordeling.obligationFonde.concat(
                formGroup.get('funds').value
              );
          }
          break;
        default:
          {
            fondFordeling.oevrigeFonde = fondFordeling.oevrigeFonde.concat(
              formGroup.get('funds').value
            );
          }
          break;
      }
    });
    return fondFordeling;
  }

  public sumAndValidateFundInput(
    formArray: UntypedFormArray,
    fundsAllocation: DepositFundAllocation | SavingFundAllocation
  ): void {
    let newTotalSharePercentage = 0;
    let newTotalBuySharePercentage = 0;
    let newTotalSellSharePercentage = 0;
    let hasSelectedAnyFunds = false;
    let hasFundChange = false;
    fundsAllocation.hasMissingFundInput = false;
    formArray.controls.forEach(fundFormArray => {
      (fundFormArray.get('funds') as UntypedFormArray).controls.forEach(
        fundFormGroup => {
          newTotalSharePercentage += isNaN(fundFormGroup.value.andel)
            ? 0
            : fundFormGroup.value.andel;
          newTotalBuySharePercentage += isNaN(fundFormGroup.value.koebsAndel)
            ? 0
            : fundFormGroup.value.koebsAndel;
          newTotalSellSharePercentage += isNaN(fundFormGroup.value.salgsAndel)
            ? 0
            : fundFormGroup.value.salgsAndel;
          hasSelectedAnyFunds = true;

          hasFundChange =
            hasFundChange ||
            fundFormGroup.value.data.add ||
            (fundFormGroup.value.andel > 0 &&
              fundFormGroup.value.andel !== fundFormGroup.value.data.andel);

          if (
            !fundsAllocation.buyAndSell &&
            (isNaN(fundFormGroup.value.andel) ||
              fundFormGroup.value.andel === '' ||
              fundFormGroup.value.andel === null)
          ) {
            fundsAllocation.hasMissingFundInput = true;
          }
        }
      );
    });
    fundsAllocation.newTotalSharePercentage =
      newTotalSharePercentage !== 0
        ? Math.round((newTotalSharePercentage + Number.EPSILON) * 100) / 100
        : newTotalSharePercentage;
    fundsAllocation.newTotalBuySharePercentage =
      newTotalBuySharePercentage !== 0
        ? Math.round((newTotalBuySharePercentage + Number.EPSILON) * 100) / 100
        : newTotalBuySharePercentage;
    fundsAllocation.newTotalSellSharePercentage =
      newTotalSellSharePercentage !== 0
        ? Math.round((newTotalSellSharePercentage + Number.EPSILON) * 100) / 100
        : newTotalSellSharePercentage;
    fundsAllocation.hasSelectedAnyFunds = hasSelectedAnyFunds;
    fundsAllocation.hasFundChange = hasFundChange;
    this.validateFundInput(formArray, fundsAllocation);
  }

  public validateFundInput(
    formArray: UntypedFormArray,
    fundsAllocation: DepositFundAllocation | SavingFundAllocation
  ): void {
    fundsAllocation.fundsValid =
      formArray.valid &&
      (fundsAllocation.buyAndSell
        ? fundsAllocation.newTotalBuySharePercentage === 100 &&
          fundsAllocation.newTotalSellSharePercentage > 0
        : fundsAllocation.newTotalSharePercentage === 100);
  }

  hasExtendedConceptAllocation(
    concepts: ExtendedConcepts,
    keys: string[],
    type: string
  ): boolean {
    let bool = false;
    keys.forEach(key => {
      const concept = concepts[key];
      if (concept.type === type && concept.duInvesterer > 0) {
        bool = true;
      }
    });

    return bool;
  }

  public hasExtendedConceptChange(
    concepts: ExtendedConcepts,
    keys: string[],
    type: string
  ): boolean {
    let bool = false;
    keys.forEach(key => {
      const concept = concepts[key];
      if (
        concept.type === type &&
        concept.duInvesterer !== concept.origDuInvesterer
      ) {
        bool = true;
      }
    });

    return bool;
  }

  public addCashMarketFund(
    depositFundsAllocation: DepositFundAllocation,
    depositFundsForm: UntypedFormArray
  ): void {
    let cashFund = this.findCashFund(
      depositFundsAllocation.mulige,
      depositFundsAllocation.manuelInvesteringFondId
    );

    if (!cashFund) {
      // Might already be selected
      cashFund = this.findCashFund(
        depositFundsAllocation.fordeling,
        depositFundsAllocation.manuelInvesteringFondId
      );
    }

    if (cashFund) {
      if (cashFund.aktieType) {
        this.getFundTypeFormArray(depositFundsForm, 'SHARES').push(
          this.buildNewFundFormGroup(
            cashFund,
            false,
            100,
            depositFundsAllocation
          )
        );
      } else if (cashFund.obligationType) {
        this.getFundTypeFormArray(depositFundsForm, 'BONDS').push(
          this.buildNewFundFormGroup(
            cashFund,
            false,
            100,
            depositFundsAllocation
          )
        );
      } else if (cashFund.oevrigeType) {
        this.getFundTypeFormArray(depositFundsForm, 'OTHERS').push(
          this.buildNewFundFormGroup(
            cashFund,
            false,
            100,
            depositFundsAllocation
          )
        );
      }
    }
  }

  public findCashFund(
    fundAllocation: FundAllocation,
    cashFundId: string
  ): Fund {
    const fonde = fundAllocation.aktieFonde.concat(
      fundAllocation.profilFondeOgBlandetKategoriFonde,
      fundAllocation.obligationFonde,
      fundAllocation.oevrigeFonde
    );
    return fonde.find(fund => fund.id === cashFundId);
  }

  public getFundTypeFormArray(
    fundsForm: UntypedFormArray,
    type: string
  ): UntypedFormArray {
    return fundsForm.controls
      .find(formArray => formArray.get('type').value === type)
      ?.get('funds') as UntypedFormArray;
  }

  public resetNewAddedFund(
    fund: Fund,
    allocation: FundAllocation,
    possible: FundAllocation
  ): void {
    fund.add = false;

    if (fund.profilFond || fund.blandetKategoriFond) {
      allocation.profilFondeOgBlandetKategoriFonde.forEach((item, i) => {
        if (item.id === fund.id) {
          allocation.profilFondeOgBlandetKategoriFonde.splice(i, 1);
        }
      });

      possible.profilFondeOgBlandetKategoriFonde.push(fund);
      possible.profilFondeOgBlandetKategoriFonde = _.sortBy(
        possible.profilFondeOgBlandetKategoriFonde,
        'order'
      );
    } else {
      if (fund.aktieType) {
        allocation.aktieFonde.forEach((item, i) => {
          if (item.id === fund.id) {
            allocation.aktieFonde.splice(i, 1);
          }
        });

        possible.aktieFonde.push(fund);
        possible.aktieFonde = _.sortBy(possible.aktieFonde, 'order');
      }

      if (fund.obligationType) {
        allocation.obligationFonde.forEach((item, i) => {
          if (item.id === fund.id) {
            allocation.obligationFonde.splice(i, 1);
          }
        });

        possible.obligationFonde.push(fund);
        possible.obligationFonde = _.sortBy(possible.obligationFonde, 'order');
      }

      if (fund.oevrigeType) {
        allocation.oevrigeFonde.forEach((item, i) => {
          if (item.id === fund.id) {
            allocation.oevrigeFonde.splice(i, 1);
          }
        });

        possible.oevrigeFonde.push(fund);
        possible.oevrigeFonde = _.sortBy(possible.oevrigeFonde, 'order');
      }
    }
  }

  public check100YouInvest(police: InvesteringPolice): boolean {
    return (
      police?.duInvesterer.simpelOpsparing?.origDuInvesterer === 100 &&
      ((police?.tilstand === 'LoebendeIndbetaling' &&
        police?.duInvesterer.simpelIndbetaling?.origDuInvesterer === 100) ||
        police?.tilstand === 'Fripolice')
    );
  }

  public buildConceptForm(concept: DepositAllocation | SavingAllocation): {
    percent: UntypedFormControl;
  } {
    return {
      percent: new UntypedFormControl(
        {
          value: concept.duInvesterer,
          disabled: concept.disabled
        },
        [
          Validators.min(concept.duInvestererMin),
          Validators.max(concept.duInvestererMax)
        ]
      )
    };
  }

  private setFundsOrder(allocation: FundAllocation): FundAllocation {
    allocation.aktieFonde.map((fund, i) => (fund.order = i));
    allocation.obligationFonde.map((fund, i) => (fund.order = i));
    allocation.oevrigeFonde.map((fund, i) => (fund.order = i));
    allocation.profilFondeOgBlandetKategoriFonde.map(
      (fund, i) => (fund.order = i)
    );

    return allocation;
  }

  private setConceptDisabled(
    concept: DepositAllocation | SavingAllocation
  ): boolean {
    let disabled = false;

    if (concept.duInvestererMax === 0 || concept.pfaInvestererMax === 0) {
      if (concept.duInvestererMax === 0 && concept.duInvesterer > 0) {
        disabled = false;
      } else {
        disabled = true;
      }
    }

    if (concept.type === AllocationType.SAVING && !concept.beloeb) {
      disabled = true;
    }

    return disabled;
  }

  private buildSimplePayment(
    youInvest: YouInvest,
    isGageRelated: boolean
  ): void {
    youInvest.simpelIndbetaling.texts = [];
    youInvest.simpelIndbetaling.texts.push({
      id: isGageRelated ? 'DL.INV.C116' : 'DL.INV.C115',
      substId: isGageRelated
        ? youInvest.simpelIndbetaling.beloeb || 0
        : youInvest.simpelIndbetaling.procent || 0
    });
  }

  private buildSimpleSavings(
    youInvest: YouInvest,
    police: InvestmentPolice,
    savingFundsAllocation: SavingFundAllocation
  ): void {
    youInvest.simpelOpsparing.texts = [];

    if (
      savingFundsAllocation.buyAndSell &&
      !(
        savingFundsAllocation.hasClosedFunds ||
        savingFundsAllocation.hasRestrictedFunds
      )
    ) {
      youInvest.simpelOpsparing.texts.push({
        id: 'DL.INV.C285'
      });
    }

    if (
      savingFundsAllocation.hasClosedFunds ||
      savingFundsAllocation.hasRestrictedFunds
    ) {
      youInvest.simpelOpsparing.texts.push({
        id: 'DL.INV.C284'
      });
    }

    youInvest.simpelOpsparing.texts.push({
      id: 'DL.INV.C121',
      substId: youInvest.simpelOpsparing.beloeb || 0
    });

    if (police.fordeling.kundekapitalBeloeb) {
      youInvest.simpelOpsparing.texts.push({
        id: 'DL.INV.C123',
        substId: police.fordeling.kundekapitalBeloeb || 0
      });
    }

    if (police.fordeling.lavGennemsnitsrenteBeloeb) {
      youInvest.simpelOpsparing.texts.push({
        id: 'DL.INV.C560',
        substId: police.fordeling.lavGennemsnitsrenteBeloeb || 0
      });
    }

    const profileG = police.pfaInvesterer
      ? police.pfaInvesterer.profiler.find(profile => profile.id === 'G')
      : undefined;

    if (police.kursvaern && profileG?.valgt) {
      youInvest.simpelOpsparing.texts.push({
        id: 'DL.INV.C125'
      });
    }
  }

  private buildExtendedConcepts(
    conceptKeys: string[],
    extendedConcepts: ExtendedConcepts,
    isGageRelated: boolean
  ): void {
    conceptKeys.forEach(key => {
      const concept = extendedConcepts[key];
      concept.texts = [];

      if (concept.type === AllocationType.DEPOSIT) {
        if (key !== 'blandetIndbetaling') {
          concept.texts.push({
            id: isGageRelated ? 'DL.INV.C116' : 'DL.INV.C115',
            substId: isGageRelated ? concept.beloeb || 0 : concept.procent || 0
          });
        }

        if (!concept.disabled) {
          concept.texts.push({
            id: 'DL.INV.C117',
            substId: concept.duInvestererMax
          });
        }
      }

      if (concept.type === AllocationType.SAVING) {
        concept.texts.push({
          id: 'DL.INV.C121',
          substId: concept.beloeb || 0
        });

        if (!concept.disabled) {
          concept.texts.push({
            id: 'DL.INV.C122',
            substId: (concept.beloeb * concept.duInvestererMax) / 100
          });
        }
      }
    });
  }
}
