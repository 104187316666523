/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Skattekode } from './skattekode';
import { SkattekodeSchema } from './skattekode';
import * as JoiObj from "joi";


    export const DaekningMedUdbetalingssikringSchema = JoiObj.object({
        skattekode:
            
        JoiObj.link('#SkattekodeSchema')


.required()


        ,
        udbetalingsforloebId:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        udbetalingssikringsYdelse:
            
                    JoiObj.number()
                

.allow(null)



        
    })
                .shared(SkattekodeSchema)
.id('DaekningMedUdbetalingssikringSchema')

export interface DaekningMedUdbetalingssikring { 
    skattekode: Skattekode;
    udbetalingsforloebId?: string;
    udbetalingssikringsYdelse?: number;
}
export namespace DaekningMedUdbetalingssikring {
}


