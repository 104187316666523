/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SamleverBoernType } from './samleverBoernType';
import { SamleverBoernTypeSchema } from './samleverBoernType';
import { ServiceInfo } from './serviceInfo';
import { ServiceInfoSchema } from './serviceInfo';
import { ResourceModel } from './resourceModel';
import { ResourceModelSchema } from './resourceModel';
import * as JoiObj from "joi";


    export const RaadgiverInfoSchema = JoiObj.object({
        serviceInfo:
            
        JoiObj.link('#ServiceInfoSchema')

.allow(null)



        ,
        aegtepagt:
            
                    JoiObj.boolean()
                


.required()


        ,
        samleverBoern:
            
                    JoiObj.boolean()
                


.required()


        ,
        samleverBoernType:
            
        JoiObj.link('#SamleverBoernTypeSchema')


.required()


        ,
        testamente:
            
                    JoiObj.boolean()
                


.required()


        ,
        testamenteAgtefaelle:
            
                    JoiObj.boolean()
                


.required()


        ,
        testamenteAndre:
            
                    JoiObj.boolean()
                


.required()


        ,
        testamenteBoern:
            
                    JoiObj.boolean()
                


.required()


        ,
        testamenteSamlever:
            
                    JoiObj.boolean()
                


.required()


        ,
        testamenteStedBoern:
            
                    JoiObj.boolean()
                


.required()


        
    })
                .shared(ServiceInfoSchema)
                .shared(SamleverBoernTypeSchema)
.id('RaadgiverInfoSchema')

export interface RaadgiverInfo extends ResourceModel { 
    aegtepagt: boolean;
    samleverBoern: boolean;
    samleverBoernType: SamleverBoernType;
    testamente: boolean;
    testamenteAgtefaelle: boolean;
    testamenteAndre: boolean;
    testamenteBoern: boolean;
    testamenteSamlever: boolean;
    testamenteStedBoern: boolean;
}
export namespace RaadgiverInfo {
}


