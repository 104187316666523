/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { EnhedType } from './enhedType';
import { EnhedTypeSchema } from './enhedType';
import * as JoiObj from "joi";


    export const AegtefaellepensionSchema = JoiObj.object({
        cprnummer:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        cprnummerFiktivt:
            
                    JoiObj.boolean()
                


.required()


        ,
        kanAegtefaelleAendres:
            
                    JoiObj.boolean()
                


.required()


        ,
        livsvarigMuligt:
            
                    JoiObj.boolean()
                


.required()


        ,
        livsvarigValgt:
            
                    JoiObj.boolean()
                


.required()


        ,
        ophoerendeMuligt:
            
                    JoiObj.boolean()
                


.required()


        ,
        ophoerendeValgt:
            
                    JoiObj.boolean()
                


.required()


        ,
        pris:
            
                    JoiObj.number()
                


.required()


        ,
        prisIProcent:
            
                    JoiObj.number()
                


.required()


        ,
        yderligereAarligPris:
            
                    JoiObj.number()
                


.required()


        ,
        yderligereBeloeb:
            
                    JoiObj.number()
                


.required()


        ,
        yderligereMax:
            
                    JoiObj.number()
                


.required()


        ,
        yderligereMin:
            
                    JoiObj.number()
                


.required()


        ,
        yderligerePrisKroner:
            
                    JoiObj.number()
                


.required()


        ,
        yderligereType:
            
        JoiObj.link('#EnhedTypeSchema')


.required()


        
    })
                .shared(EnhedTypeSchema)
.id('AegtefaellepensionSchema')

export interface Aegtefaellepension { 
    cprnummer: string;
    cprnummerFiktivt: boolean;
    kanAegtefaelleAendres: boolean;
    livsvarigMuligt: boolean;
    livsvarigValgt: boolean;
    ophoerendeMuligt: boolean;
    ophoerendeValgt: boolean;
    pris: number;
    prisIProcent: number;
    yderligereAarligPris: number;
    yderligereBeloeb: number;
    yderligereMax: number;
    yderligereMin: number;
    yderligerePrisKroner: number;
    yderligereType: EnhedType;
}
export namespace Aegtefaellepension {
}


