/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Skattekode } from './skattekode';
import { SkattekodeSchema } from './skattekode';
import * as JoiObj from "joi";


    export const AdvancedPayoutChoiceLivnetPolicyCoverageSchema = JoiObj.object({
        hasPayoutPeriod:
            
                    JoiObj.boolean()
                


.required()


        ,
        payoutAge:
            
                    JoiObj.number()
                


.required()


        ,
        payoutDate:
            
            JoiObj.date()


.required()


        ,
        payoutPeriod:
            
                    JoiObj.number()
                


.required()


        ,
        payoutProgressId:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        startPayout:
            
                    JoiObj.boolean()
                


.required()


        ,
        taxcode:
            
        JoiObj.link('#SkattekodeSchema')


.required()


        ,
        underPayout:
            
                    JoiObj.boolean()
                


.required()


        ,
        payoutApproved:
            
                    JoiObj.boolean()
                


.required()


        
    })
                .shared(SkattekodeSchema)
.id('AdvancedPayoutChoiceLivnetPolicyCoverageSchema')

export interface AdvancedPayoutChoiceLivnetPolicyCoverage { 
    hasPayoutPeriod: boolean;
    payoutAge: number;
    payoutDate: Date;
    payoutPeriod: number;
    payoutProgressId: string;
    startPayout: boolean;
    taxcode: Skattekode;
    underPayout: boolean;
    payoutApproved: boolean;
}
export namespace AdvancedPayoutChoiceLivnetPolicyCoverage {
}


