/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const DynamicAdvisingRecommendationCategoryTypeSchema = JoiObj.string().valid(
        'DATA_COLLECTION'
        ,
        'GIPP_TO_PLUS'
        ,
        'BENEFICIARY'
        ,
        'EXTERNAL_FUNDS'
        ,
        'INVESTMENT_PROFILE'
        ,
        'LIFE_INSURANCE_ESTIMATE'
        ,
        'LOC_INSURANCE_ESTIMATE'
        ,
        'MERGE_POLICIES'
        ,
        'PENSION_ESTIMATE'
        
    )
.id('DynamicAdvisingRecommendationCategoryTypeSchema')

export type DynamicAdvisingRecommendationCategoryType = 'DATA_COLLECTION' | 'GIPP_TO_PLUS' | 'BENEFICIARY' | 'EXTERNAL_FUNDS' | 'INVESTMENT_PROFILE' | 'LIFE_INSURANCE_ESTIMATE' | 'LOC_INSURANCE_ESTIMATE' | 'MERGE_POLICIES' | 'PENSION_ESTIMATE';

export const DynamicAdvisingRecommendationCategoryType = {
    DataCollection: 'DATA_COLLECTION' as DynamicAdvisingRecommendationCategoryType,
    GippToPlus: 'GIPP_TO_PLUS' as DynamicAdvisingRecommendationCategoryType,
    Beneficiary: 'BENEFICIARY' as DynamicAdvisingRecommendationCategoryType,
    ExternalFunds: 'EXTERNAL_FUNDS' as DynamicAdvisingRecommendationCategoryType,
    InvestmentProfile: 'INVESTMENT_PROFILE' as DynamicAdvisingRecommendationCategoryType,
    LifeInsuranceEstimate: 'LIFE_INSURANCE_ESTIMATE' as DynamicAdvisingRecommendationCategoryType,
    LocInsuranceEstimate: 'LOC_INSURANCE_ESTIMATE' as DynamicAdvisingRecommendationCategoryType,
    MergePolicies: 'MERGE_POLICIES' as DynamicAdvisingRecommendationCategoryType,
    PensionEstimate: 'PENSION_ESTIMATE' as DynamicAdvisingRecommendationCategoryType
};

