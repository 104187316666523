<co-loading [show]="!(brokerInfo && pensionCustomer)"></co-loading>

<ng-container *ngIf="brokerInfo && pensionCustomer">
  <h1 coContent="DL.KO01.C01"></h1>
  <div class="row">
    <co-card-split-horizontal class="col-12 col-lg-4">
      <div header class="header-text text--bold">
        <p coContent="DL.KO01.C259"></p>
      </div>
      <div body>
        <p coContent="DL.KO01.C260" class="text--note"></p>
        <ng-container *ngIf="!brokerInfo.maeglerBetjent">
          <div class="spacing-unit--half-over" coContent="DL.KO01.C261"></div>
          <div class="text--note" coContent="DL.KO01.C05"></div>
        </ng-container>
        <ng-container *ngIf="brokerInfo.maeglerBetjent">
          <div class="spacing-unit--half-over" *ngIf="brokerInfo.livnetAftale">
            <span coContent="DL.KO01.C335"></span> {{ brokerInfo.navn }}
          </div>
          <div
            *ngIf="!brokerInfo.livnetAftale"
            class="text--note"
            coContent="DL.KO01.C298"
          ></div>
        </ng-container>

        <div
          class="spacing-unit--half-over"
          *ngIf="
            !brokerInfo.maeglerBetjent && !pensionCustomer.specialMessagingRules
          "
          coContent="DL.KO01.C04"
        ></div>
        <div
          class="spacing-unit--half-over"
          *ngIf="
            !brokerInfo.maeglerBetjent && pensionCustomer.specialMessagingRules
          "
          coContent="DL.KO01.C302"
        ></div>
        <div
          class="spacing-unit--half-over"
          *ngIf="brokerInfo.maeglerBetjent && brokerInfo.livnetAftale"
        >
          <a href="tel:{{ brokerInfo.telefon }}" class="link link-telephone">{{
            brokerInfo.telefon
          }}</a>
        </div>

        <div
          class="spacing-unit--half-over"
          *ngIf="!brokerInfo.maeglerBetjent"
          coContent="DL.KO01.C316"
        ></div>
        <div
          class="spacing-unit--half-over"
          *ngIf="brokerInfo.maeglerBetjent && brokerInfo.livnetAftale"
        >
          <a href="mailto:{{ brokerInfo.email }}" class="link link-email">{{
            brokerInfo.email
          }}</a>
        </div>
      </div>
    </co-card-split-horizontal>

    <!--Advisory services-->
    <co-card-split-horizontal class="col-12 col-lg-4">
      <div header class="header-text text--bold">
        <p coContent="DL.KO01.C265"></p>
      </div>
      <div body>
        <p coContent="DL.KO01.C266" class="text--note"></p>
        <ng-container *ngIf="!brokerInfo.maeglerBetjent">
          <div class="spacing-unit--half-over" coContent="DL.KO01.C267"></div>
          <div class="text--note" coContent="DL.KO01.C268"></div>
        </ng-container>

        <ng-container *ngIf="brokerInfo.maeglerBetjent">
          <div *ngIf="brokerInfo.livnetAftale">
            <span coContent="DL.KO01.C335"></span> {{ brokerInfo.navn }}
          </div>
          <div
            *ngIf="!brokerInfo.livnetAftale"
            class="text--note"
            coContent="DL.KO01.C298"
          ></div>
        </ng-container>

        <div
          *ngIf="
            !brokerInfo.maeglerBetjent && !pensionCustomer.specialMessagingRules
          "
          coContent="DL.KO01.C269"
          class="spacing-unit--half-over"
        ></div>
        <div
          class="spacing-unit--half-over"
          *ngIf="
            brokerInfo.maeglerBetjent &&
            !pensionCustomer.specialMessagingRules &&
            brokerInfo.livnetAftale
          "
        >
          <a href="tel:{{ brokerInfo.telefon }}" class="link link-telephone">{{
            brokerInfo.telefon
          }}</a>
        </div>
        <div
          class="spacing-unit--half-over"
          *ngIf="pensionCustomer.specialMessagingRules"
          coContent="DL.KO01.C302"
        ></div>

        <div
          *ngIf="brokerInfo.maeglerBetjent && brokerInfo.livnetAftale"
          class="spacing-unit--half-over"
        >
          <a href="mailto:{{ brokerInfo.email }}" class="link link-email">{{
            brokerInfo.email
          }}</a>
        </div>
      </div>
    </co-card-split-horizontal>

    <!--health-->
    <co-card-split-horizontal class="col-12 col-lg-4">
      <div header class="header-text text--bold">
        <p coContent="DL.KO01.C271"></p>
      </div>
      <div body>
        <p coContent="DL.KO01.C272" class="text--note"></p>
        <div class="spacing-unit--half-over" coContent="DL.KO01.C273"></div>
        <div
          class="spacing-unit--half-over"
          *ngIf="!pensionCustomer.specialMessagingRules"
          coContent="DL.KO01.C08"
        ></div>
        <div
          class="spacing-unit--half-over"
          *ngIf="pensionCustomer.specialMessagingRules"
          coContent="DL.KO01.C302"
        ></div>
        <div class="spacing-unit--half-over" coContent="DL.KO01.C316"></div>
      </div>
    </co-card-split-horizontal>

    <!--To dependants-->
    <co-card-split-horizontal class="col-12 col-lg-4">
      <div header class="header-text text--bold">
        <p coContent="DL.KO01.C276"></p>
      </div>
      <div body>
        <p coContent="DL.KO01.C277" class="text--note"></p>
        <div class="spacing-unit--half-over" coContent="DL.KO01.C278"></div>
        <div class="spacing-unit--half-over" coContent="DL.KO01.C279"></div>
      </div>
    </co-card-split-horizontal>

    <!--  Family Law-->
    <co-card-split-horizontal class="col-12 col-lg-4">
      <div header class="header-text text--bold">
        <p coContent="DL.KO01.C280"></p>
      </div>
      <div body>
        <p coContent="DL.KO01.C281" class="text--note"></p>
        <div class="spacing-unit--half-over" coContent="DL.KO01.C282"></div>
        <div class="spacing-unit--half-over" coContent="DL.KO01.C283"></div>
      </div>
    </co-card-split-horizontal>

    <!-- Complaints -->
    <co-card-split-horizontal class="col-12 col-lg-4">
      <div header class="header-text text--bold">
        <p coContent="DL.KO01.C284"></p>
      </div>
      <div body>
        <p coContent="DL.KO01.C285" class="text--note"></p>
        <div class="spacing-unit--half-over" coContent="DL.KO01.C286"></div>
        <div class="text--note" coContent="DL.KO01.C09a"></div>
        <div class="text--note" coContent="DL.KO01.C09b"></div>

        <div class="spacing-unit--half-over" coContent="DL.KO01.C288"></div>
      </div>
    </co-card-split-horizontal>

    <!-- Bolig -->
    <co-card-split-horizontal class="col-12 col-lg-4">
      <div header class="header-text text--bold">
        <p coContent="DL.KO01.C310"></p>
      </div>
      <div body>
        <p coContent="DL.KO01.C311" class="text--note"></p>
        <div class="spacing-unit--half-over" coContent="DL.KO01.C312"></div>
        <div class="text--note" coContent="DL.KO01.C313"></div>
        <div class="text--note" coContent="DL.KO01.C314"></div>
        <div class="spacing-unit--half-over" coContent="DL.KO01.C334"></div>
      </div>
    </co-card-split-horizontal>
    <!-- Head office -->
    <co-card-split-horizontal class="col-12 col-lg-4">
      <div header class="header-text text--bold">
        <p coContent="DL.KO01.C290"></p>
      </div>
      <div body>
        <p coContent="DL.KO01.C291" class="text--note"></p>
        <div class="spacing-unit--half-over" coContent="DL.KO01.C292"></div>
        <div class="text--note" coContent="DL.KO01.C09a"></div>
        <div class="text--note" coContent="DL.KO01.C09b"></div>
        <div class="text--note" coContent="DL.KO01.C09c"></div>
        <div class="spacing-unit--half-over" coContent="DL.KO01.C10"></div>
      </div>
    </co-card-split-horizontal>
  </div>
</ng-container>
