<div class="logon--container">
  <co-icon-logo class="header--logo"></co-icon-logo>
  <div class="login--wrapper">
    <h2 class="headline" [coContent]="'DL.LI01.C107'"></h2>
    <p [coContent]="'DL.LI01.C108'"></p>
    <form [formGroup]="orderPincodeForm" (ngSubmit)="submit()" color="accent">
      <mat-form-field>
        <input
          matInput
          type="text"
          maxlength="10"
          [placeholder]="'DL.LI01.C109' | content"
          formControlName="cpr"
        />
        <mat-error
          *ngIf="
            orderPincodeForm.get('cpr')?.errors?.required &&
            orderPincodeForm.get('cpr')?.touched
          "
          [coContent]="'DL.MD01.C12'"
        ></mat-error>
        <mat-error
          *ngIf="orderPincodeForm.get('cpr')?.errors?.pattern"
          [coContent]="'DL.LI01.C132128'"
          [coContentSubst]="10"
        ></mat-error>
      </mat-form-field>
      <p class="spacing-unit--over" [coContent]="'DL.LI01.C110'"></p>
      <mat-form-field color="accent">
        <input
          type="password"
          matInput
          maxlength="4"
          [placeholder]="'DL.LI01.C111' | content"
          formControlName="pincode"
        />
        <mat-error
          *ngIf="
            orderPincodeForm.get('pincode')?.errors?.required &&
            orderPincodeForm.get('pincode')?.touched
          "
          [coContent]="'DL.MD01.C12'"
        ></mat-error>
        <mat-error
          *ngIf="orderPincodeForm.get('pincode')?.errors?.pattern"
          [coContent]="'DL.LI01.C132128'"
          [coContentSubst]="4"
        ></mat-error>
        <mat-error
          *ngIf="orderPincodeForm.get('pincode')?.errors?.patternMatch"
          [coContent]="'DL.LI01.C121'"
        ></mat-error>
      </mat-form-field>
      <mat-form-field color="accent">
        <input
          type="password"
          matInput
          maxlength="4"
          [placeholder]="'DL.LI01.C112' | content"
          formControlName="repeatPincode"
        />
        <mat-error
          *ngIf="
            orderPincodeForm.get('repeatPincode')?.errors?.required &&
            orderPincodeForm.get('repeatPincode')?.touched
          "
          [coContent]="'DL.MD01.C12'"
        ></mat-error>
        <mat-error
          *ngIf="orderPincodeForm.get('repeatPincode')?.errors?.pattern"
          [coContent]="'DL.LI01.C132128'"
          [coContentSubst]="4"
        ></mat-error>
        <mat-error
          *ngIf="orderPincodeForm.get('repeatPincode')?.errors?.patternMatch"
          [coContent]="'DL.LI01.C121'"
        ></mat-error>
        <mat-error
          *ngIf="orderPincodeForm.get('repeatPincode')?.errors?.pincodeMatch"
          [coContent]="'DL.LI01.C119'"
        ></mat-error>
      </mat-form-field>
      <button
        type="submit"
        mat-raised-button
        [coContent]="'DL.LI01.C113'"
        [ngClass]="{ 'button--disabled': orderPincodeForm.invalid }"
        [disabled]="orderPincodeForm.invalid"
      ></button>
    </form>
    <co-warning class="pincode-error" *ngIf="errorMessage">
      <p [coContent]="errorMessage ?? ''"></p>
    </co-warning>
  </div>
</div>
