import { Component, Input, inject } from '@angular/core';
import { InvestmentQuestionaire, InvestmentRecommendation } from '@pfa/gen';

import { InvestmentRecommendationOverviewService } from './recommendation-overview.service';
import { OverviewContent } from './recommendation-overview.model';
import { overviewTexts } from './recommendation-overview.texts';

@Component({
  providers: [InvestmentRecommendationOverviewService],
  selector: 'mitpfa-investment-recommendation-overview',
  templateUrl: './recommendation-overview.component.html',
  styleUrls: ['./recommendation-overview.component.scss']
})
export class InvestmentRecommendationOverviewComponent {
  private readonly service: InvestmentRecommendationOverviewService = inject(
    InvestmentRecommendationOverviewService
  );

  @Input() showClimate: boolean;
  @Input()
  public set data(value: {
    recommendation: InvestmentRecommendation;
    recommendationBasis: InvestmentQuestionaire;
  }) {
    this._data = value;
    if (!value) {
      return;
    }
    this.content = this.service.getOverviewContent(value);
  }

  public get data(): {
    recommendation: InvestmentRecommendation;
    recommendationBasis: InvestmentQuestionaire;
  } {
    return this._data;
  }

  public displayLossAbilityIndicator: boolean;
  public displayRiskWillingnessIndicator: boolean;

  public content: OverviewContent;
  public texts = overviewTexts;

  private _data: {
    recommendation: InvestmentRecommendation;
    recommendationBasis: InvestmentQuestionaire;
  };
}
