import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output
} from '@angular/core';

@Component({
  selector: 'mitpfa-combine-savings-summary-transferable-by-advisor',
  templateUrl:
    './combine-savings-summary-transferable-by-advisor.component.html',
  styleUrls: [
    './combine-savings-summary-transferable-by-advisor.component.scss'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CombineSavingsSummaryTransferableByAdvisorComponent {
  @Output() goToNextPage = new EventEmitter<void>();
}
