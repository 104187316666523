/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AlternativePolicyChoicePartiallyAccepted } from './alternativePolicyChoicePartiallyAccepted';
import { AlternativePolicyChoicePartiallyAcceptedSchema } from './alternativePolicyChoicePartiallyAccepted';
import { OriginOfMoneySelectedList } from './originOfMoneySelectedList';
import { OriginOfMoneySelectedListSchema } from './originOfMoneySelectedList';
import { ServiceInfo } from './serviceInfo';
import { ServiceInfoSchema } from './serviceInfo';
import { PurposeOfPolicySelectedList } from './purposeOfPolicySelectedList';
import { PurposeOfPolicySelectedListSchema } from './purposeOfPolicySelectedList';
import { ResourceModel } from './resourceModel';
import { ResourceModelSchema } from './resourceModel';
import { AlternativePolicyChoiceDeclined } from './alternativePolicyChoiceDeclined';
import { AlternativePolicyChoiceDeclinedSchema } from './alternativePolicyChoiceDeclined';
import { AlternativePolicyChoice } from './alternativePolicyChoice';
import { AlternativePolicyChoiceSchema } from './alternativePolicyChoice';
import * as JoiObj from "joi";


    export const ByggeSimulationKmdlHtmlKontaktRequestSchema = JoiObj.object({
        serviceInfo:
            
        JoiObj.link('#ServiceInfoSchema')

.allow(null)



        ,
        alternativePolicyChoice:
            
        JoiObj.link('#AlternativePolicyChoiceSchema')


.required()


        ,
        alternativePolicyChoiceDeclined:
            
        JoiObj.link('#AlternativePolicyChoiceDeclinedSchema')


.required()


        ,
        alternativePolicyChoicePartiallyAccepted:
            
        JoiObj.link('#AlternativePolicyChoicePartiallyAcceptedSchema')


.required()


        ,
        originOfMoneyComment:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        originOfMoneySelectedList:
            
        JoiObj.link('#OriginOfMoneySelectedListSchema')


.required()


        ,
        purposeOfPolicyComment:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        purposeOfPolicySelectedList:
            
        JoiObj.link('#PurposeOfPolicySelectedListSchema')


.required()


        ,
        sprog:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        
    })
                .shared(ServiceInfoSchema)
                .shared(AlternativePolicyChoiceSchema)
                .shared(AlternativePolicyChoiceDeclinedSchema)
                .shared(AlternativePolicyChoicePartiallyAcceptedSchema)
                .shared(OriginOfMoneySelectedListSchema)
                .shared(PurposeOfPolicySelectedListSchema)
.id('ByggeSimulationKmdlHtmlKontaktRequestSchema')

export interface ByggeSimulationKmdlHtmlKontaktRequest extends ResourceModel { 
    alternativePolicyChoice: AlternativePolicyChoice;
    alternativePolicyChoiceDeclined: AlternativePolicyChoiceDeclined;
    alternativePolicyChoicePartiallyAccepted: AlternativePolicyChoicePartiallyAccepted;
    originOfMoneyComment: string;
    originOfMoneySelectedList: OriginOfMoneySelectedList;
    purposeOfPolicyComment: string;
    purposeOfPolicySelectedList: PurposeOfPolicySelectedList;
    sprog: string;
}
export namespace ByggeSimulationKmdlHtmlKontaktRequest {
}


