import { Injectable } from '@angular/core';
import { LoggedOutInactiveComponent } from './logged-out-inactive.component';
import { MatDialog } from '@angular/material/dialog';
import { PopupStyle } from '@pfa/models';

@Injectable({
  providedIn: 'root'
})
export class LoggedOutInactiveService {
  private isDialogOpen: boolean;

  constructor(private readonly dialog: MatDialog) {}

  handleTimeoutExplanationPopup(): void {
    if (!this.isDialogOpen && sessionStorage.getItem('userTimedOut')) {
      const isAdvisor = !!sessionStorage.getItem('userTimedOutRaadgiver');
      const popup = this.dialog.open(LoggedOutInactiveComponent, {
        data: {
          isAdvisor
        },
        disableClose: true,
        panelClass: PopupStyle.PopupSmall
      });
      this.isDialogOpen = true;
      popup.afterClosed().subscribe(() => {
        this.isDialogOpen = false;
        sessionStorage.removeItem('userTimedOut');
        sessionStorage.removeItem('userTimedOutRaadgiver');
      });
    }
  }
}
