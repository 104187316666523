/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const UploadedFileSchema = JoiObj.object({
        id:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        category:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        type:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        fileName:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        metadata:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        
    })
.id('UploadedFileSchema')

export interface UploadedFile { 
    id: string;
    category: string;
    type: string;
    fileName: string;
    metadata: string;
}

