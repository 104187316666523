import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { PensionEstimateDetailsOverview } from '@pfa/gen';

@Injectable({
  providedIn: 'root'
})
export class PensionEstimateApiService {
  constructor(private http: HttpClient) {}

  getDetailOverview(): Observable<PensionEstimateDetailsOverview> {
    const url = '/ds/api/pensionestimate/detailsoverview';
    return this.http.get<PensionEstimateDetailsOverview>(url);
  }
}
