/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const DokumentFilFormatSchema = JoiObj.string().valid(
        'PDF'
        ,
        'XML'
        ,
        'UKENDT'
        
    )
.id('DokumentFilFormatSchema')

export type DokumentFilFormat = 'PDF' | 'XML' | 'UKENDT';

export const DokumentFilFormat = {
    Pdf: 'PDF' as DokumentFilFormat,
    Xml: 'XML' as DokumentFilFormat,
    Ukendt: 'UKENDT' as DokumentFilFormat
};

