import { inject, Injectable } from '@angular/core';
import { ContentService } from '@pfa/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {
  ForsikringDetaljer,
  ForsikringsguideStore,
  Forsikringsoversigt,
  ForsikringsoversigtStore,
  ForsikringStore,
  InForceSelectionForsikring,
  LanguageCode,
  PensionsAftaleId,
  PensionsInfoOrdninger,
  RaadgiverAnbefaling,
  SprogType,
  SsoToken,
  SuccessResponse,
  TilpasForsikringstal,
  VariantDetail
} from '@pfa/gen';
import * as moment from 'moment/moment';

@Injectable({
  providedIn: 'root'
})
export class InsuranceApiService {
  insuranceOverviewStore: ForsikringsoversigtStore = inject(
    ForsikringsoversigtStore
  );
  forsikringStore: ForsikringStore = inject(ForsikringStore);
  forsikringsguideStore: ForsikringsguideStore = inject(ForsikringsguideStore);
  contentService: ContentService = inject(ContentService);

  constructor(private http: HttpClient) {}

  getInsuranceOverviewDetails(): Observable<Forsikringsoversigt> {
    return this.insuranceOverviewStore.forsikringsoversigtDetaljerGet();
  }

  public clearInsuranceOverviewDetailsCache(): void {
    this.insuranceOverviewStore.invalidateforsikringsoversigtdetaljerIndex();
  }

  getOnlineDoctorUrl(): Observable<string> {
    return this.http.get<string>('/ds/api/forsikringsoversigt/onlinedoktorurl');
  }

  productVariants(
    variantIds: string[],
    hasCoverages: boolean,
    hasTerms: boolean
  ): Observable<VariantDetail[]> {
    const languageCode = this.contentService
      .getLanguage()
      .toUpperCase() as LanguageCode;

    return this.insuranceOverviewStore.forsikringsoversigtProductVariantsGet(
      languageCode,
      variantIds,
      hasTerms,
      hasCoverages
    );
  }

  initInsuranceAdjustment(): Observable<SuccessResponse> {
    return this.http.post<SuccessResponse>(
      '/ds/api/tilpasforsikringstal/initInsuranceAdjustment',
      {}
    );
  }

  getInsuranceDetails(): Observable<ForsikringDetaljer> {
    return this.forsikringStore.forsikringDetaljerGet();
  }

  getPensionInfo(): Observable<PensionsInfoOrdninger> {
    return this.http.get<PensionsInfoOrdninger>('/ds/api/pensionsinfo/aftaler');
  }

  updateInsuranceAdjustmentCiSelf(
    insuranceAdjustment: TilpasForsikringstal
  ): Observable<TilpasForsikringstal> {
    return this.http.post<TilpasForsikringstal>(
      '/ds/api/tilpasforsikringstal/opdaterkritisksygdom',
      this.formatNoHealthRequiredUntilDate(insuranceAdjustment)
    );
  }

  updateInsuranceAdjustmentCiChild(
    insuranceAdjustment: TilpasForsikringstal
  ): Observable<TilpasForsikringstal> {
    return this.http.post<TilpasForsikringstal>(
      '/ds/api/tilpasforsikringstal/opdaterkritisksygdomboern',
      this.formatNoHealthRequiredUntilDate(insuranceAdjustment)
    );
  }

  private formatNoHealthRequiredUntilDate(
    insuranceAdjustment: TilpasForsikringstal
  ) {
    if (insuranceAdjustment.autoAdjustment?.noHealthRequiredUntil) {
      const insuranceAdjustmentCopy = structuredClone(insuranceAdjustment);
      const currentNoHealthRequiredUntil =
        insuranceAdjustmentCopy.autoAdjustment.noHealthRequiredUntil;
      // @ts-expect-error we have to send date as string - temporary fix
      insuranceAdjustmentCopy.autoAdjustment.noHealthRequiredUntil = moment(
        currentNoHealthRequiredUntil
      ).format('DD.MM.YYYY');
      return insuranceAdjustmentCopy;
    }
    return insuranceAdjustment;
  }

  updateInsuranceAdjustmentLifeSelf(
    insuranceAdjustment: TilpasForsikringstal
  ): Observable<TilpasForsikringstal> {
    return this.http.post<TilpasForsikringstal>(
      '/ds/api/tilpasforsikringstal/opdaterliv',
      this.formatNoHealthRequiredUntilDate(insuranceAdjustment)
    );
  }

  updateInsuranceAdjustmentLifeChild(
    insuranceAdjustment: TilpasForsikringstal
  ): Observable<TilpasForsikringstal> {
    return this.http.post<TilpasForsikringstal>(
      '/ds/api/tilpasforsikringstal/opdaterboernepension',
      this.formatNoHealthRequiredUntilDate(insuranceAdjustment)
    );
  }

  updateInsuranceAdjustmentLocAdvisorInfo(
    advisorRecommendation: RaadgiverAnbefaling
  ): Observable<SuccessResponse> {
    return this.http.post<SuccessResponse>(
      '/ds/api/tilpasforsikringstal/tae/raadgiveranbefaling',
      advisorRecommendation
    );
  }

  updateInsuranceAdjustmentLifeAdvisorInfo(
    advisorRecommendation: RaadgiverAnbefaling
  ): Observable<SuccessResponse> {
    return this.http.post<SuccessResponse>(
      '/ds/api/tilpasforsikringstal/liv/raadgiveranbefaling',
      advisorRecommendation
    );
  }

  deleteInsuranceAdjustmentLocAdvisorInfo(): Observable<SuccessResponse> {
    return this.http.delete<SuccessResponse>(
      '/ds/api/tilpasforsikringstal/tae/raadgiveranbefaling'
    );
  }

  deleteInsuranceAdjustmentLifeAdvisorInfo(): Observable<SuccessResponse> {
    return this.http.delete<SuccessResponse>(
      '/ds/api/tilpasforsikringstal/liv/raadgiveranbefaling'
    );
  }

  updateInsuranceAdjustmentLocTaxCodeAdvisorInfo(
    adjustInsuranceNumber: TilpasForsikringstal
  ): Observable<any> {
    const url = '/ds/api/tilpasforsikringstal/opdatertaeengangskattekode';
    return this.http.post<any>(
      url,
      this.formatNoHealthRequiredUntilDate(adjustInsuranceNumber)
    );
  }

  updateInsuranceAdjustmentLifeTaxCodeAdvisorInfo(
    adjustInsuranceNumber: TilpasForsikringstal
  ): Observable<ForsikringDetaljer> {
    const url = '/ds/api/tilpasforsikringstal/opdaterlivskattekode';
    return this.http.post<any>(
      url,
      this.formatNoHealthRequiredUntilDate(adjustInsuranceNumber)
    );
  }

  updateInsuranceAdjustmentLocRegularPayouts(
    insuranceAdjustment: TilpasForsikringstal
  ): Observable<TilpasForsikringstal> {
    return this.http.post<TilpasForsikringstal>(
      '/ds/api/tilpasforsikringstal/opdatertaeloebende',
      this.formatNoHealthRequiredUntilDate(insuranceAdjustment)
    );
  }

  updateInsuranceAdjustmentLocLumpSum(
    insuranceAdjustment: TilpasForsikringstal
  ): Observable<TilpasForsikringstal> {
    return this.http.post<TilpasForsikringstal>(
      '/ds/api/tilpasforsikringstal/opdatertaeengang',
      this.formatNoHealthRequiredUntilDate(insuranceAdjustment)
    );
  }

  adjustInsuranceUpdateTAE(
    adjustInsurance: TilpasForsikringstal
  ): Observable<TilpasForsikringstal> {
    return this.http.post<TilpasForsikringstal>(
      '/ds/api/tilpasforsikringstal/dynamicadvising/opdatertaeloebende',
      this.formatNoHealthRequiredUntilDate(adjustInsurance)
    );
  }

  generateInsuranceDocumentReport(
    lang: string,
    advisorShortFlow: boolean,
    insuranceDetails: ForsikringDetaljer
  ): Observable<undefined> {
    const sprogType = lang.toUpperCase() === 'DA' ? SprogType.Da : SprogType.En;

    return this.forsikringsguideStore.forsikringsguideRapportPost(
      sprogType,
      advisorShortFlow,
      insuranceDetails
    );
  }

  markRecommendationDisplayed(): Observable<SuccessResponse> {
    return this.http.put<SuccessResponse>(
      '/ds/api/forsikring/markrecommendationdisplayed',
      {}
    );
  }

  updateLifeInsurance(
    body: TilpasForsikringstal
  ): Observable<TilpasForsikringstal> {
    return this.http.post<TilpasForsikringstal>(
      '/ds/api/tilpasforsikringstal/dynamicadvising/opdaterliv',
      body
    );
  }

  getSSOToken(): Observable<SsoToken> {
    return this.http.get<SsoToken>('/ds/api/forebygger/ssotoken');
  }

  logProfileSelection(
    profileSelection: InForceSelectionForsikring[],
    delayLogProfileSelection: boolean
  ): Observable<SuccessResponse> {
    const url = delayLogProfileSelection
      ? '/ds/api/forsikring/logprofileselection?basket=true'
      : '/ds/api/forsikring/logprofileselection';
    return this.http.post<SuccessResponse>(url, profileSelection);
  }

  getPrimaryPolicy(): Observable<PensionsAftaleId> {
    return this.http.get<PensionsAftaleId>(
      '/ds/api/forsikringsoversigt/primaeraftale/id'
    );
  }

  acceptSuperRiskLocLumpSum(
    adjustInsuranceNumber: TilpasForsikringstal
  ): Observable<any> {
    const url = '/ds/api/tilpasforsikringstal/superrisiko/taeengang';
    return this.http.post<any>(url, adjustInsuranceNumber);
  }

  acceptSuperRiskLocRegular(
    adjustInsuranceNumber: TilpasForsikringstal
  ): Observable<any> {
    const url = '/ds/api/tilpasforsikringstal/superrisiko/taeloebende';
    return this.http.post<any>(url, adjustInsuranceNumber);
  }

  acceptSuperRiskLife(
    adjustInsuranceNumber: TilpasForsikringstal
  ): Observable<any> {
    const url = '/ds/api/tilpasforsikringstal/superrisiko/liv';
    return this.http.post<any>(url, adjustInsuranceNumber);
  }

  acceptSuperRiskChild(
    adjustInsuranceNumber: TilpasForsikringstal
  ): Observable<any> {
    const url = '/ds/api/tilpasforsikringstal/superrisiko/boernepension';
    return this.http.post<any>(url, adjustInsuranceNumber);
  }

  updateSpouse(
    adjustInsurance: TilpasForsikringstal
  ): Observable<TilpasForsikringstal> {
    return this.http.post<TilpasForsikringstal>(
      '/ds/api/tilpasforsikringstal/opdaterAegtefaelle',
      adjustInsurance
    );
  }

  updateSpouseExtraAmount(
    adjustInsurance: TilpasForsikringstal
  ): Observable<TilpasForsikringstal> {
    return this.http.post<TilpasForsikringstal>(
      '/ds/api/tilpasforsikringstal/opdaterAegtefaelleBeloeb',
      adjustInsurance
    );
  }

  public clearCache() {
    this.forsikringsguideStore.invalidateAll();
    this.forsikringStore.invalidateAll();
    this.insuranceOverviewStore.invalidateAll();
  }
}
