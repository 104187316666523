/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { LinieElement } from './linieElement';
import { LinieElementSchema } from './linieElement';
import { FordelingValg } from './fordelingValg';
import { FordelingValgSchema } from './fordelingValg';
import * as JoiObj from "joi";


    export const IndbetalingRatepensionSchema = JoiObj.object({
        fordelingValg:
            
        JoiObj.link('#FordelingValgSchema')

.allow(null)



        ,
        indbetalingAndreOrdninger:
            
                    JoiObj.number()
                

.allow(null)



        ,
        indbetalingIntetLoftMax:
            
                    JoiObj.number()
                

.allow(null)



        ,
        indbetalingIntetLoftMin:
            
                    JoiObj.number()
                

.allow(null)



        ,
        indbetalingLoftMax:
            
                    JoiObj.number()
                

.allow(null)



        ,
        indbetalingLoftMin:
            
                    JoiObj.number()
                

.allow(null)



        ,
        indbetalingsloftBeloeb:
            
                    JoiObj.number()
                

.allow(null)



        ,
        linieElementer:
                JoiObj.array().items(
        
        JoiObj.link('#LinieElementSchema')

        )
        

.required()


        ,
        linieElementerOpsparingsForm:
                JoiObj.array().items(
        
        JoiObj.link('#LinieElementSchema')

        )
        

.required()


        ,
        oensketIndbetaling:
            
                    JoiObj.number()
                

.allow(null)



        ,
        skatteAar:
            
                    JoiObj.number()
                


.required()


        
    })
                .shared(FordelingValgSchema)
                .shared(LinieElementSchema)
                .shared(LinieElementSchema)
.id('IndbetalingRatepensionSchema')

export interface IndbetalingRatepension { 
    fordelingValg?: FordelingValg;
    indbetalingAndreOrdninger?: number;
    indbetalingIntetLoftMax?: number;
    indbetalingIntetLoftMin?: number;
    indbetalingLoftMax?: number;
    indbetalingLoftMin?: number;
    indbetalingsloftBeloeb?: number;
    linieElementer: Array<LinieElement>;
    linieElementerOpsparingsForm: Array<LinieElement>;
    oensketIndbetaling?: number;
    skatteAar: number;
}
export namespace IndbetalingRatepension {
}


