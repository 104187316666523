import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output
} from '@angular/core';
import {
  ForsikringDetaljer,
  Forsikringsoversigt,
  OekonomiDetaljer,
  PensionsInfoOrdninger,
  RaadgiverAnbefaling,
  PensionsKundeGenerelleData
} from '@pfa/gen';
import { InsuranceTag } from '@pfa/models';
import { InsuranceGuideConfigurationService } from '../../insurance-guide-configuration.service';
import {
  RECOMMENDATION_DIRECTION,
  RECOMMENDATION_TYPE,
  RecommendationDescription
} from '../insurance-guide.recommendation.model';
import { InsuranceUtilService } from '@pfaform';
import { InsuranceGuideService } from '../../insurance-guide.service';
import { ConfirmChoice } from '@mitpfa/shared';

@Component({
  selector: 'mitpfa-insurance-guide-recommendation-occupational-capacity',
  templateUrl:
    './insurance-guide-recommendation-occupational-capacity.component.html',
  styleUrls: [
    './insurance-guide-recommendation-occupational-capacity.component.scss'
  ]
})
export class InsuranceGuideRecommendationOccupationalCapacityComponent
  implements OnInit, OnChanges
{
  @Input() pensionInfo: PensionsInfoOrdninger;
  @Input() pensionCustomer: PensionsKundeGenerelleData;
  @Input() salary: number;
  @Input() insuranceDetails: ForsikringDetaljer;
  @Input() insuranceOverview: Forsikringsoversigt;
  @Input() financialSituation: OekonomiDetaljer;
  @Input() advisorData: RaadgiverAnbefaling;
  @Input() recommendationHeaderTooltip: string;
  @Input() showFollowOurRecommendationButton: boolean;
  @Input() confirmChoice: ConfirmChoice;
  @Input() hideActions: boolean;

  @Output() confirmChoiceEmitter: EventEmitter<void> = new EventEmitter<void>();

  coverageId: number;
  recommendationDirection: RECOMMENDATION_DIRECTION;
  recommendationDescription: RecommendationDescription | undefined;
  taxFree: boolean;
  isDisabled: boolean;
  tags: InsuranceTag[];
  overrideRecommendationKeepCurrent: boolean;

  supplementaryRecommendation: number | undefined;
  manualSupplementaryRecommendation: number | undefined;
  recommendedCoverageTagTooltip: string;
  recommendedCoverageTagText: string;
  recommendedCoverageTagTooltipSupplementary: string;
  recommendedCoverageTagTextSupplementary: string;
  reccomendOptOut: boolean;

  riskText: string;

  constructor(
    private readonly insuranceGuideConfigurationService: InsuranceGuideConfigurationService,
    private readonly insuranceGuideService: InsuranceGuideService,
    private readonly insuranceUtilService: InsuranceUtilService
  ) {}

  ngOnInit(): void {
    this.insuranceGuideService.trackRecommendation([
      {
        name: this.insuranceGuideService.getRecommendationTrackingTextLoc(
          this.pensionInfo,
          this.recommendationDescription,
          this.insuranceDetails.locScaleInformation
        ),
        index: 1
      }
    ]);
  }

  ngOnChanges(): void {
    this.coverageId =
      this.insuranceDetails?.tilpasForsikringstal?.sliderTAEloebende
        ?.daekningsId;
    this.recommendationDirection =
      this.insuranceGuideService.getRecommendationLoc(
        this.pensionInfo,
        this.insuranceDetails.locScaleInformation
      );
    this.recommendationDescription =
      InsuranceGuideService.getRecommendationDescription(
        this.recommendationDirection,
        RECOMMENDATION_TYPE.OCCUPATIONAL_CAPACITY,
        this.insuranceDetails.locScaleInformation
      );

    this.reccomendOptOut =
      this.recommendationDescription.current === 'LOC_100' &&
      !this.recommendationDescription.scale80allowed &&
      this.recommendationDescription.recommendation === 'MANUAL';

    this.setShouldKeepRecommendation();

    this.supplementaryRecommendation = this.getSupplementaryRecommendation();
    this.manualSupplementaryRecommendation =
      this.getManualSupplementaryRecommendation();

    this.taxFree = InsuranceUtilService.allTaxFree(
      this.insuranceOverview.erhvervsevneForsikring?.erhvervsevneForsikringer
    );
    this.isDisabled =
      this.insuranceGuideConfigurationService.getInsuranceGuideDisabled(
        this.insuranceDetails
      ).looc;
    this.tags = this.insuranceUtilService.scaleTags(this.insuranceDetails);

    if (this.recommendationDescription) {
      const scale80allowed =
        this.insuranceDetails?.locScaleInformation?.allowed.includes(
          'LOC_80'
        ) ?? false;
      this.recommendedCoverageTagText =
        this.insuranceUtilService.getLocScaleTagText(
          this.recommendationDescription?.recommendation === 'LOC_80',
          this.recommendationDescription?.recommendation === 'LOC_100',
          scale80allowed
        );
      this.recommendedCoverageTagTooltip =
        this.insuranceUtilService.getLocScaleTooltip(
          this.recommendationDescription?.recommendation === 'LOC_100'
        );
    }

    this.setRiskText();
  }

  public setRiskText(): void {
    this.riskText =
      this.insuranceGuideConfigurationService.getRecommendationRiskText(
        this.financialSituation.taeNytte
      );
  }

  hasAdvisorRecommendation(): boolean {
    return this.advisorData?.raadgiverAnbefaling > 0;
  }

  private setShouldKeepRecommendation(): void {
    if (
      !this.recommendationDescription?.isAuto ||
      this.recommendationDescription?.recommendation === 'MANUAL'
    ) {
      this.overrideRecommendationKeepCurrent =
        InsuranceGuideService.calculateShouldKeepRecommendation(
          this.pensionInfo.forsikring.helbredsTalBrutto,
          this.pensionInfo.forsikring.pfaAnbefalerHelbred.procent,
          InsuranceUtilService.HEALTH_VARIATION
        );
    }
  }

  getSupplementaryRecommendation(): number | undefined {
    const overrideRecommendationKeepCurrent =
      InsuranceGuideService.calculateShouldKeepRecommendation(
        this.pensionInfo.forsikring.helbredsTalBrutto,
        this.pensionInfo.forsikring.pfaAnbefalerHelbred.procent,
        InsuranceUtilService.HEALTH_VARIATION
      );

    if (
      !(
        overrideRecommendationKeepCurrent &&
        this.insuranceDetails?.locScaleInformation?.current === 'LOC_80'
      ) &&
      this.insuranceDetails?.showRiskLocStep &&
      this.insuranceDetails?.locScaleInformation?.recommendation ===
        'LOC_100' &&
      this.financialSituation?.taeNytte === 'HOEJ' &&
      !this.financialSituation?.taeBetalingsvillig &&
      this.recommendationDescription?.scale80allowed
    ) {
      return 80;
    }
  }

  getManualSupplementaryRecommendation(): number | undefined {
    const recommendation: number =
      this.pensionInfo.forsikring.pfaAnbefalerHelbred.procent;

    const manualSupplementaryRecommendation: number =
      this.pensionInfo.forsikring.pfaAnbefalerHelbred.locScaleGroupValues
        ?.standard;

    const overrideRecommendationKeepCurrent =
      InsuranceGuideService.calculateShouldKeepRecommendation(
        this.pensionInfo.forsikring.helbredsTalBrutto,
        manualSupplementaryRecommendation,
        InsuranceUtilService.HEALTH_VARIATION
      );

    if (
      !overrideRecommendationKeepCurrent &&
      this.isWithinCoverageRange(manualSupplementaryRecommendation) &&
      !this.pensionCustomer?.specialCustomerRules?.type_1 &&
      this.insuranceDetails?.showRiskLocStep &&
      this.financialSituation?.taeNytte === 'HOEJ' &&
      this.insuranceDetails.tilpasForsikringstal?.autoAdjustment
        ?.customerSelection === 'NOT_POSSIBLE' &&
      !this.financialSituation?.taeBetalingsvillig &&
      !!this.pensionInfo.forsikring.pfaAnbefalerHelbred?.locScaleGroupValues &&
      manualSupplementaryRecommendation < recommendation
    ) {
      return manualSupplementaryRecommendation;
    }
  }

  private isWithinCoverageRange(recommendation: number) {
    const range = this.insuranceDetails.tilpasForsikringstal?.sliderTAEloebende;
    return (
      !!range &&
      recommendation >= range.minimumsdaekning &&
      recommendation <= range.forsikringstalMax
    );
  }

  onConfirmChoice() {
    this.confirmChoiceEmitter.emit();
  }
}
