/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const HealthCaseStatusSchema = JoiObj.string().valid(
        'CLOSED'
        ,
        'CANCELLED'
        ,
        'MANUAL'
        ,
        'IN_PROGRESS'
        ,
        'DRAFT_MITPFA'
        
    )
.id('HealthCaseStatusSchema')

export type HealthCaseStatus = 'CLOSED' | 'CANCELLED' | 'MANUAL' | 'IN_PROGRESS' | 'DRAFT_MITPFA';

export const HealthCaseStatus = {
    Closed: 'CLOSED' as HealthCaseStatus,
    Cancelled: 'CANCELLED' as HealthCaseStatus,
    Manual: 'MANUAL' as HealthCaseStatus,
    InProgress: 'IN_PROGRESS' as HealthCaseStatus,
    DraftMitpfa: 'DRAFT_MITPFA' as HealthCaseStatus
};

