import {
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { of } from 'rxjs';
import { CombineSavingsAcceptTransferService } from './combine-savings-accept-transfer.service';
import { CombineSavingsAcceptTransferFormService } from './combine-savings-accept-transfer-form.service';
import { catchError, tap } from 'rxjs/operators';
import {
  AftaleAnbefaling,
  EksternOverfoerselAdvarsel,
  EksternOverfoerselAnbefaling,
  ExternaltransferStore,
  PensionsKundeGenerelleData,
  TransferableCategoryType
} from '@pfa/gen';
import {
  bankPensionType,
  CombineSavingsFlowType,
  CombineSavingsNavigationEvent,
  CombineSavingsStep
} from '@mitpfa/shared/combine-savings/combine-savings.model';
import { MatDialog } from '@angular/material/dialog';
import {
  ConfirmationModalComponent,
  ConfirmationModalConfiguration
} from '@pfaform';
import { MiscTrackingService } from '@pfa/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'mitpfa-combine-savings-accept-transfer',
  templateUrl: './combine-savings-accept-transfer.component.html',
  styleUrls: ['./combine-savings-accept-transfer.component.scss']
})
export class CombineSavingsAcceptTransferComponent
  implements OnDestroy, OnInit
{
  private _externalTransferData: EksternOverfoerselAnbefaling;

  @Input() combineSavingsFlowType: CombineSavingsFlowType;

  @Input() guideCompletedDate: Date;

  @Input() pensionCustomer: PensionsKundeGenerelleData;

  get externalTransferData(): EksternOverfoerselAnbefaling {
    return this._externalTransferData;
  }

  @Input() set externalTransferData(value: EksternOverfoerselAnbefaling) {
    this._externalTransferData = value;
  }

  @Output() next: EventEmitter<CombineSavingsNavigationEvent> =
    new EventEmitter();

  @Output() prev: EventEmitter<CombineSavingsNavigationEvent> =
    new EventEmitter();

  @Output() goToNextPageAfterSummary: EventEmitter<void> = new EventEmitter();

  private readonly trackingService: MiscTrackingService =
    inject(MiscTrackingService);
  private readonly combineSavingsAcceptTransferService: CombineSavingsAcceptTransferService =
    inject(CombineSavingsAcceptTransferService);
  private readonly formService: CombineSavingsAcceptTransferFormService =
    inject(CombineSavingsAcceptTransferFormService);
  private readonly externaltransferStore: ExternaltransferStore = inject(
    ExternaltransferStore
  );
  private readonly dialog: MatDialog = inject(MatDialog);
  private readonly destroyRef: DestroyRef = inject(DestroyRef);

  public transferablePolicies: AftaleAnbefaling[];
  public bankPensionType = bankPensionType;
  public showDeclineTransfer = true;
  public allPoliciesAlreadyOrdered = false;

  get form(): UntypedFormGroup {
    return this.formService.form;
  }

  ngOnInit(): void {
    if (this.externalTransferData) {
      if (this.externalTransferData.policer) {
        this.preparePolicies();
      }
      this.formService.initializeForm(this.transferablePolicies);
    }
  }

  private preparePolicies(): void {
    const greenPolicies = this.externalTransferData.policer.filter(
      policy =>
        policy.transferableCategory ===
        TransferableCategoryType.TransferableByCustomer
    );

    const yellowPolicies = this.externalTransferData.policer.filter(
      policy =>
        policy.transferableCategory ===
          TransferableCategoryType.TransferableByAdvisor &&
        !policy.advarsler.includes(EksternOverfoerselAdvarsel.HarIndbetaling)
    );

    const yellowSuppressPolicies = this.externalTransferData.policer.filter(
      policy =>
        policy.transferableCategory ===
          TransferableCategoryType.TransferableByAdvisor &&
        policy.advarsler.includes(EksternOverfoerselAdvarsel.HarIndbetaling)
    );

    const redPolicies = this.externalTransferData.policer.filter(
      policy =>
        policy.transferableCategory ===
          TransferableCategoryType.NotTransferable &&
        (this.pensionCustomer.isRaadgiver || policy.selskab !== '1') //Company (selskab) number 1 is ATP
    );

    if (redPolicies.length > 0) {
      this.transferablePolicies = [];
    } else {
      this.transferablePolicies = greenPolicies.concat(yellowSuppressPolicies);

      if (this.pensionCustomer.isRaadgiver) {
        this.transferablePolicies =
          this.transferablePolicies.concat(yellowPolicies);
      }
    }

    this.showDeclineTransfer =
      this.combineSavingsAcceptTransferService.showDeclineTransfer(
        this.transferablePolicies,
        this.guideCompletedDate
      );
    this.allPoliciesAlreadyOrdered = !this.externalTransferData.policer.some(
      policy => !policy.overfoerselBestilt
    );
  }

  ngOnDestroy(): void {
    this.formService.ngOnDestroy();
  }

  public submitExternalTransferForm(): void {
    if (
      this.combineSavingsFlowType &&
      this.combineSavingsFlowType ===
        CombineSavingsFlowType.activate_your_recommendation_flow
    ) {
      this.trackNavigation('RG CYS select savings', 'continue to approve');
    } else {
      this.trackNavigation('CYS select savings', 'continue to approve');
    }
    this.goNext();
  }

  public goBack(): void {
    this.prev.emit({ from: CombineSavingsStep.AcceptTransfer });
  }

  public getPolicyFormGroup(policyId: string): UntypedFormGroup {
    return this.form.get(`policies.${policyId}`) as UntypedFormGroup;
  }

  public declineTransfer(): void {
    this.trackNavigation('CYS select savings', 'skip step');

    this.externaltransferStore
      .externaltransferConfirmchoicePost()
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        catchError(() => of({})),
        tap(() => this.goToNextPageAfterSummary.emit())
      )
      .subscribe();
  }

  private trackNavigation(navigationName: string, actionName: string) {
    this.trackingService.trackNavigationCard(navigationName, actionName, '');
  }

  private goNext(): void {
    this.next.emit({
      from: CombineSavingsStep.AcceptTransfer,
      transferredPoliciesUids: this.formService.getPoliciesToBeTransferred()
    });
  }

  public showAreYouSure(): void {
    this.trackNavigation('RG CYS select savings', 'skip step');
    this.dialog
      .open(ConfirmationModalComponent, {
        data: this.getDialogConfigurationForDeleteSingleChange(),
        maxWidth: 500
      })
      .afterClosed()
      .subscribe(result => {
        if (result) {
          this.trackNavigation('RG CYS decline transfer', 'cta clicked');
          this.next.emit({
            from: CombineSavingsStep.AcceptTransfer,
            transferredPoliciesUids: [],
            wontTransfer: true
          });
        } else {
          this.trackNavigation('RG CYS decline transfer', 'closed');
        }
      });
  }

  private getDialogConfigurationForDeleteSingleChange(): ConfirmationModalConfiguration {
    return {
      title: 'DL.VK01.C105',
      contents: ['DL.VK01.C106', 'DL.VK01.C54'],
      confirmButtonLabel: 'DL.VK01.C108',
      cancelButtonLabel: 'DL.VK01.C107'
    };
  }

  public showSuppressWarningCheckbox(policy: AftaleAnbefaling): boolean {
    return policy.advarsler.includes(EksternOverfoerselAdvarsel.HarIndbetaling);
  }

  public jumpover(): void {
    this.trackNavigation('CYS select savings', 'next step');
    this.goToNextPageAfterSummary.emit();
  }
}
