import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
  OnInit
} from '@angular/core';
import { Router } from '@angular/router';
import { Fordele, FordeleKontakt } from '@pfa/gen';
import { PageTrackingService } from '@pfa/core';
import { FOOTER_NAVIGATION_TRACKING } from '../../footer.model';

@Component({
  selector: 'co-footer-contact-advisor',
  templateUrl: './footer-contact-advisor.component.html',
  styleUrls: ['./footer-contact-advisor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterContactAdvisorComponent implements OnInit {
  @Input() advantages: Fordele;

  private readonly router: Router = inject(Router);
  private readonly pageTrackingService: PageTrackingService =
    inject(PageTrackingService);

  public showContactNavLink: boolean;
  public personalAdvisor: FordeleKontakt;

  ngOnInit(): void {
    this.showContactNavLink =
      !(this.personalAdvisor && this.advantages.kontakter.length === 1) &&
      !this.router.url.includes('/personligeradgivere');

    if (
      this.advantages.kontakter &&
      (this.advantages.programId === 1 || this.advantages.programId === 2)
    ) {
      this.personalAdvisor = this.advantages.kontakter.find(
        fordeleKontakt => fordeleKontakt.type === 'Pension'
      );
    }
  }

  phoneClicked(): void {
    this.pageTrackingService.trackNavigation(
      FOOTER_NAVIGATION_TRACKING.name,
      FOOTER_NAVIGATION_TRACKING.action.callAdvisor,
      ''
    );
  }

  writeClicked(): void {
    this.pageTrackingService.trackNavigation(
      FOOTER_NAVIGATION_TRACKING.name,
      FOOTER_NAVIGATION_TRACKING.action.writePfa,
      FOOTER_NAVIGATION_TRACKING.destination.messaging
    );
  }

  contactClicked(): void {
    this.pageTrackingService.trackNavigation(
      FOOTER_NAVIGATION_TRACKING.name,
      FOOTER_NAVIGATION_TRACKING.action.contact,
      FOOTER_NAVIGATION_TRACKING.destination.contact
    );
  }
}
