import { Injectable } from '@angular/core';
import { ConsentInfo } from '@pfa/gen';
import {
  ChannelsConfigDictionary,
  ConsentConfiguration,
  ConsentsConfigDictionary,
  ConsentState,
  RefinedConsentInfo
} from './consent.model';

const unsubscribeId = 'DL.ST01.C123';
const confirmId = 'DL.ST01.C133';
const cancelId = 'DL.ST01.C134';

@Injectable()
export class ConsentsConfigurationService {
  consentsConfig: ConsentsConfigDictionary = {
    Marketing: {
      typeTextId: 'DL.ST01.C91',
      englishOnlyTextId: 'DL.ST01.C160',
      detailsHeaderTextId: 'DL.ST01.C234',
      detailsTextId: 'DL.ST01.C92',
      signUpTextId: 'DL.ST01.C105',
      signOutTextId: 'DL.ST01.C128',
      signOutRetiredTextId: unsubscribeId,
      revokeModal: {
        header: 'DL.ST01.C116',
        contents: ['DL.ST01.C117', 'DL.ST01.C118', 'DL.ST01.C132'],
        confirm: confirmId,
        cancel: cancelId
      }
    },
    MarketingPensionOnly: {
      typeTextId: 'DL.ST01.C193',
      englishOnlyTextId: 'DL.ST01.C160',
      detailsHeaderTextId: 'DL.ST01.C234',
      detailsTextId: 'DL.ST01.C195',
      signUpTextId: 'DL.ST01.C202',
      signOutTextId: 'DL.ST01.C128',
      signOutRetiredTextId: unsubscribeId,
      revokeModal: {
        header: 'DL.ST01.C116',
        contents: ['DL.ST01.C117', 'DL.ST01.C118', 'DL.ST01.C132'],
        confirm: confirmId,
        cancel: cancelId
      }
    }
  };

  channelsConfig: ChannelsConfigDictionary = {
    EMail: 'DL.ST01.C95',
    SMS: 'DL.ST01.C96',
    Phone: 'DL.ST01.C97',
    EBoks: 'DL.ST01.C98',
    Mail: 'DL.ST01.C99',
    SocialMedia: 'DL.ST01.C100',
    Web: 'DL.ST01.C101',
    App: 'DL.ST01.C102'
  };

  refineConsents(consents: ConsentInfo[]): RefinedConsentInfo[] {
    const latestConsents = this.consentsToLatestConsents(consents);
    return latestConsents.map(consent => ({
      ...consent,
      requirementsMet: this.isRequirementsMet(consent, latestConsents),
      channels: this.limitChannels(consent)
    }));
  }

  private limitChannels(consent: RefinedConsentInfo) {
    const allowedChannels = ['EMail'];
    return consent.channels?.filter(ch =>
      allowedChannels.includes(ch.channelKey)
    );
  }

  private consentsToLatestConsents(
    consents: ConsentInfo[]
  ): RefinedConsentInfo[] {
    const groupedConsents: {
      [key: string]: ConsentInfo[];
    } = this.groupConsentsByType(consents);
    return Object.values(groupedConsents).map(consentsGroupedByType => {
      if (consentsGroupedByType.length > 1) {
        const retired = consentsGroupedByType.find(
          consent => consent.state === ConsentState.GIVEN
        );
        const latest = consentsGroupedByType.find(
          consent => consent.state === null
        );
        return { ...latest, retiredConsent: retired };
      } else {
        return consentsGroupedByType[0];
      }
    });
  }

  private groupConsentsByType(consents: ConsentInfo[]): {
    [key: string]: ConsentInfo[];
  } {
    return consents.reduce(
      (acc: { [key: string]: ConsentInfo[] }, current: ConsentInfo) => {
        if (acc[current.type]) {
          acc[current.type].push(current);
        } else {
          acc[current.type] = [current];
        }
        return acc;
      },
      {}
    );
  }

  private isRequirementsMet(
    consent: ConsentInfo,
    consents: ConsentInfo[]
  ): boolean {
    const consentConfig = this.consentsConfig[
      consent.type
    ] as ConsentConfiguration;
    if (consentConfig?.requires) {
      return consentConfig?.requires.every(requirement => {
        const requiredConsent = consents.find(con => con.type === requirement);
        return requiredConsent
          ? requiredConsent.state === ConsentState.GIVEN
          : true;
      });
    }
    return true;
  }
}
