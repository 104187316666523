/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { LocScaleInformation } from './locScaleInformation';
import { LocScaleInformationSchema } from './locScaleInformation';
import { RaadgiverAnbefaling } from './raadgiverAnbefaling';
import { RaadgiverAnbefalingSchema } from './raadgiverAnbefaling';
import { MinSituationForsikring } from './minSituationForsikring';
import { MinSituationForsikringSchema } from './minSituationForsikring';
import { TilpasForsikringstal } from './tilpasForsikringstal';
import { TilpasForsikringstalSchema } from './tilpasForsikringstal';
import { KoenKode } from './koenKode';
import { KoenKodeSchema } from './koenKode';
import { AftaleDaekning } from './aftaleDaekning';
import { AftaleDaekningSchema } from './aftaleDaekning';
import { HelbredsPlan } from './helbredsPlan';
import { HelbredsPlanSchema } from './helbredsPlan';
import { KsPlan } from './ksPlan';
import { KsPlanSchema } from './ksPlan';
import { ForsikringstalAendring } from './forsikringstalAendring';
import { ForsikringstalAendringSchema } from './forsikringstalAendring';
import { LivsPlan } from './livsPlan';
import { LivsPlanSchema } from './livsPlan';
import { ProcesseringsStatus } from './processeringsStatus';
import { ProcesseringsStatusSchema } from './processeringsStatus';
import { TaeNytte } from './taeNytte';
import { TaeNytteSchema } from './taeNytte';
import * as JoiObj from "joi";


    export const ForsikringDetaljerSchema = JoiObj.object({
        aegtefaelleDaekninger:
                JoiObj.array().items(
        
        JoiObj.link('#AftaleDaekningSchema')

        )
        

.required()


        ,
        aendretHelbred:
            
        JoiObj.link('#ForsikringstalAendringSchema')


.required()


        ,
        aendretLiv:
            
        JoiObj.link('#ForsikringstalAendringSchema')


.required()


        ,
        beregnetSkatHelbred:
            
                    JoiObj.number()
                


.required()


        ,
        beregnetSkatHelbredPerMaaned:
            
                    JoiObj.number()
                

.allow(null)



        ,
        beregnetSkatLiv:
            
                    JoiObj.number()
                


.required()


        ,
        bruttoTalLivIkkeVises:
            
                    JoiObj.boolean()
                


.required()


        ,
        bruttoTalTaeloebendeIkkeVises:
            
                    JoiObj.boolean()
                


.required()


        ,
        estimeretUdbetalingEfterSkatHelbred:
            
                    JoiObj.number()
                


.required()


        ,
        estimeretUdbetalingEfterSkatHelbredPerMaaned:
            
                    JoiObj.number()
                

.allow(null)



        ,
        estimeretUdbetalingEfterSkatLiv:
            
                    JoiObj.number()
                


.required()


        ,
        fejledeAftaler:
                JoiObj.array().items(
        
        JoiObj.link('#AftaleDaekningSchema')

        )
        
.allow(null)



        ,
        fejledePolicenumre:
                JoiObj.array().items(
        
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

        )
        

.required()


        ,
        foedselsDato:
            
                    JoiObj.object()
                

.allow(null)



        ,
        forsoergerAndel:
            
                    JoiObj.number()
                

.allow(null)



        ,
        gammelNuvaerendeFormue:
            
                    JoiObj.number()
                

.allow(null)



        ,
        grundlag:
            
                    JoiObj.boolean()
                


.required()


        ,
        harGipp:
            
                    JoiObj.boolean()
                


.required()


        ,
        hasExternalTaeAgreement:
            
                    JoiObj.boolean()
                


.required()


        ,
        helbredSkattepligtigtBeloebForIkkeFravalgtPolicer:
            
                    JoiObj.number()
                


.required()


        ,
        helbredsBoerneDaekninger:
                JoiObj.array().items(
        
        JoiObj.link('#AftaleDaekningSchema')

        )
        

.required()


        ,
        helbredsDaekninger:
                JoiObj.array().items(
        
        JoiObj.link('#AftaleDaekningSchema')

        )
        

.required()


        ,
        helbredsIkkeDefineretDaekninger:
                JoiObj.array().items(
        
        JoiObj.link('#AftaleDaekningSchema')

        )
        

.required()


        ,
        helbredsMaanedligPris:
            
                    JoiObj.number()
                

.allow(null)



        ,
        helbredsSumDaekninger:
                JoiObj.array().items(
        
        JoiObj.link('#AftaleDaekningSchema')

        )
        

.required()


        ,
        helbredsTal:
            
                    JoiObj.number()
                


.required()


        ,
        helbredsTalAfrundet:
            
                    JoiObj.boolean()
                


.required()


        ,
        helbredsTalBrutto:
            
                    JoiObj.number()
                


.required()


        ,
        helbredsYdelsesBeloeb:
            
                    JoiObj.number()
                

.allow(null)



        ,
        ialtLivsforsikring:
            
                    JoiObj.number()
                


.required()


        ,
        ialtTAE:
            
                    JoiObj.number()
                


.required()


        ,
        ialtTAEPerMaaned:
            
                    JoiObj.number()
                

.allow(null)



        ,
        koen:
            
        JoiObj.link('#KoenKodeSchema')

.allow(null)



        ,
        kontaktMobil:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        landekode:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        livRaadgiverAnbefaling:
            
        JoiObj.link('#RaadgiverAnbefalingSchema')

.allow(null)



        ,
        livsBoerneDaekninger:
                JoiObj.array().items(
        
        JoiObj.link('#AftaleDaekningSchema')

        )
        

.required()


        ,
        livsDaekninger:
                JoiObj.array().items(
        
        JoiObj.link('#AftaleDaekningSchema')

        )
        

.required()


        ,
        livsForaeldreloeseDaekninger:
                JoiObj.array().items(
        
        JoiObj.link('#AftaleDaekningSchema')

        )
        

.required()


        ,
        livsIkkeDefineretAegtefaelleDaekninger:
                JoiObj.array().items(
        
        JoiObj.link('#AftaleDaekningSchema')

        )
        

.required()


        ,
        livsIkkeDefineretDaekninger:
                JoiObj.array().items(
        
        JoiObj.link('#AftaleDaekningSchema')

        )
        

.required()


        ,
        livsMaanedligPris:
            
                    JoiObj.number()
                

.allow(null)



        ,
        livsTal:
            
                    JoiObj.number()
                


.required()


        ,
        livsTalAfrundet:
            
                    JoiObj.boolean()
                


.required()


        ,
        livsYdelsesBeloeb:
            
                    JoiObj.number()
                

.allow(null)



        ,
        locScaleInformation:
            
        JoiObj.link('#LocScaleInformationSchema')

.allow(null)



        ,
        maeglerBetjent:
            
                    JoiObj.boolean()
                


.required()


        ,
        minSituationForsikring:
            
        JoiObj.link('#MinSituationForsikringSchema')


.required()


        ,
        nuvaerendeFormue:
            
                    JoiObj.number()
                

.allow(null)



        ,
        nuvaerendeGaeld:
            
                    JoiObj.number()
                

.allow(null)



        ,
        opdaterMinFamilieFejler:
            
                    JoiObj.boolean()
                


.required()


        ,
        opdaterMinForsikringFejler:
            
                    JoiObj.boolean()
                


.required()


        ,
        opdaterMinOekonomiFejler:
            
                    JoiObj.boolean()
                


.required()


        ,
        originalTilpasForsikringstal:
            
        JoiObj.link('#TilpasForsikringstalSchema')


.required()


        ,
        pensionsnumre:
                JoiObj.array().items(
        
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

        )
        

.required()


        ,
        pfaAnbefalerHelbred:
            
        JoiObj.link('#HelbredsPlanSchema')


.required()


        ,
        pfaAnbefalerKS:
            
        JoiObj.link('#KsPlanSchema')


.required()


        ,
        pfaAnbefalerLivs:
            
        JoiObj.link('#LivsPlanSchema')


.required()


        ,
        sapOpdateringFejlet:
            
                    JoiObj.boolean()
                


.required()


        ,
        showRiskLocStep:
            
                    JoiObj.boolean()
                


.required()


        ,
        statusListe:
                JoiObj.array().items(
        
        JoiObj.link('#ProcesseringsStatusSchema')

        )
        

.required()


        ,
        superRiskLife:
            
                    JoiObj.boolean()
                

.allow(null)



        ,
        superRiskTae:
            
                    JoiObj.boolean()
                

.allow(null)



        ,
        taeNytte:
            
        JoiObj.link('#TaeNytteSchema')

.allow(null)



        ,
        taeRaadgiverAnbefaling:
            
        JoiObj.link('#RaadgiverAnbefalingSchema')

.allow(null)



        ,
        tilpasForsikringstal:
            
        JoiObj.link('#TilpasForsikringstalSchema')


.required()


        ,
        ugiftDaekninger:
                JoiObj.array().items(
        
        JoiObj.link('#AftaleDaekningSchema')

        )
        

.required()


        
    })
                .shared(AftaleDaekningSchema)
                .shared(ForsikringstalAendringSchema)
                .shared(ForsikringstalAendringSchema)
                .shared(AftaleDaekningSchema)
                .shared(AftaleDaekningSchema)
                .shared(AftaleDaekningSchema)
                .shared(AftaleDaekningSchema)
                .shared(AftaleDaekningSchema)
                .shared(KoenKodeSchema)
                .shared(RaadgiverAnbefalingSchema)
                .shared(AftaleDaekningSchema)
                .shared(AftaleDaekningSchema)
                .shared(AftaleDaekningSchema)
                .shared(AftaleDaekningSchema)
                .shared(AftaleDaekningSchema)
                .shared(LocScaleInformationSchema)
                .shared(MinSituationForsikringSchema)
                .shared(TilpasForsikringstalSchema)
                .shared(HelbredsPlanSchema)
                .shared(KsPlanSchema)
                .shared(LivsPlanSchema)
                .shared(ProcesseringsStatusSchema)
                .shared(TaeNytteSchema)
                .shared(RaadgiverAnbefalingSchema)
                .shared(TilpasForsikringstalSchema)
                .shared(AftaleDaekningSchema)
.id('ForsikringDetaljerSchema')

export interface ForsikringDetaljer { 
    aegtefaelleDaekninger: Array<AftaleDaekning>;
    aendretHelbred: ForsikringstalAendring;
    aendretLiv: ForsikringstalAendring;
    beregnetSkatHelbred: number;
    beregnetSkatHelbredPerMaaned?: number;
    beregnetSkatLiv: number;
    bruttoTalLivIkkeVises: boolean;
    bruttoTalTaeloebendeIkkeVises: boolean;
    estimeretUdbetalingEfterSkatHelbred: number;
    estimeretUdbetalingEfterSkatHelbredPerMaaned?: number;
    estimeretUdbetalingEfterSkatLiv: number;
    fejledeAftaler?: Array<AftaleDaekning>;
    fejledePolicenumre: Array<string>;
    foedselsDato?: object;
    forsoergerAndel?: number;
    gammelNuvaerendeFormue?: number;
    grundlag: boolean;
    harGipp: boolean;
    hasExternalTaeAgreement: boolean;
    helbredSkattepligtigtBeloebForIkkeFravalgtPolicer: number;
    helbredsBoerneDaekninger: Array<AftaleDaekning>;
    helbredsDaekninger: Array<AftaleDaekning>;
    helbredsIkkeDefineretDaekninger: Array<AftaleDaekning>;
    helbredsMaanedligPris?: number;
    helbredsSumDaekninger: Array<AftaleDaekning>;
    helbredsTal: number;
    helbredsTalAfrundet: boolean;
    helbredsTalBrutto: number;
    helbredsYdelsesBeloeb?: number;
    ialtLivsforsikring: number;
    ialtTAE: number;
    ialtTAEPerMaaned?: number;
    koen?: KoenKode;
    kontaktMobil?: string;
    landekode?: string;
    livRaadgiverAnbefaling?: RaadgiverAnbefaling;
    livsBoerneDaekninger: Array<AftaleDaekning>;
    livsDaekninger: Array<AftaleDaekning>;
    livsForaeldreloeseDaekninger: Array<AftaleDaekning>;
    livsIkkeDefineretAegtefaelleDaekninger: Array<AftaleDaekning>;
    livsIkkeDefineretDaekninger: Array<AftaleDaekning>;
    livsMaanedligPris?: number;
    livsTal: number;
    livsTalAfrundet: boolean;
    livsYdelsesBeloeb?: number;
    locScaleInformation?: LocScaleInformation;
    maeglerBetjent: boolean;
    minSituationForsikring: MinSituationForsikring;
    nuvaerendeFormue?: number;
    nuvaerendeGaeld?: number;
    opdaterMinFamilieFejler: boolean;
    opdaterMinForsikringFejler: boolean;
    opdaterMinOekonomiFejler: boolean;
    originalTilpasForsikringstal: TilpasForsikringstal;
    pensionsnumre: Array<string>;
    pfaAnbefalerHelbred: HelbredsPlan;
    pfaAnbefalerKS: KsPlan;
    pfaAnbefalerLivs: LivsPlan;
    sapOpdateringFejlet: boolean;
    showRiskLocStep: boolean;
    statusListe: Array<ProcesseringsStatus>;
    superRiskLife?: boolean;
    superRiskTae?: boolean;
    taeNytte?: TaeNytte;
    taeRaadgiverAnbefaling?: RaadgiverAnbefaling;
    tilpasForsikringstal: TilpasForsikringstal;
    ugiftDaekninger: Array<AftaleDaekning>;
}
export namespace ForsikringDetaljer {
}


