<co-introbox [title]="'DL.HE01.C106'" [description]="'DL.OS01.C28a'">
  <p [coContent]="'DL.OS01.C29'" class="bullet-list"></p>
  <div [coContent]="'DL.OS01.C30'" class="text--bold"></div>
  <p [coContent]="'DL.OS01.C33'"></p>
</co-introbox>

<div class="savings spacing-unit--double-under">
  <div
    class="headline-title"
    [coContent]="
      savingsOverview.harKundekapitalOpsparing ? 'DL.OS01.C343' : 'DL.OS01.C350'
    "
  >
    Samlet Indbetaling år til dato
  </div>
  <co-primary-number
    class="size-lg"
    [amount]="
      savingsOverview.totalOpsparing !== null
        ? savingsOverview.totalOpsparing
        : 0
    "
  ></co-primary-number>
  <co-info *ngIf="policiesWithoutSavingsIds?.length > 0">
    <span [coContent]="'DL.OS01.C410'"></span>
    {{ policiesWithoutSavingsIds.join(', ') }}
  </co-info>
</div>

<co-info
  *ngIf="savingsOverview.udbetalingDoedId?.length > 0"
  class="spacing-unit--double-under"
>
  <div
    [coContent]="'DL.PP01.C724'"
    [coContentSubst]="savingsOverview.udbetalingDoedId"
  ></div>
</co-info>

<div *ngIf="showAdjust" class="spacing-unit--double-under">
  <h3 [coContent]="'DL.OS01.C398'"></h3>
  <p [coContent]="'DL.OS01.C399'"></p>
</div>

<co-pensionplan-card-list
  [pensionPlanCardList]="pensionPlanCardList"
  (pensionPlanCardSelectedEvent)="pensionPlanCardSelected($event)"
>
</co-pensionplan-card-list>
