/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ServiceInfo } from './serviceInfo';
import { ServiceInfoSchema } from './serviceInfo';
import { DuInvestererFordeling } from './duInvestererFordeling';
import { DuInvestererFordelingSchema } from './duInvestererFordeling';
import { OpsparingFondFordeling } from './opsparingFondFordeling';
import { OpsparingFondFordelingSchema } from './opsparingFondFordeling';
import { ResourceModel } from './resourceModel';
import { ResourceModelSchema } from './resourceModel';
import { IndbetalingFondFordeling } from './indbetalingFondFordeling';
import { IndbetalingFondFordelingSchema } from './indbetalingFondFordeling';
import { IndbetalingKonceptFordeling } from './indbetalingKonceptFordeling';
import { IndbetalingKonceptFordelingSchema } from './indbetalingKonceptFordeling';
import { OpsparingKonceptFordeling } from './opsparingKonceptFordeling';
import { OpsparingKonceptFordelingSchema } from './opsparingKonceptFordeling';
import * as JoiObj from "joi";


    export const DuInvestererSchema = JoiObj.object({
        serviceInfo:
            
        JoiObj.link('#ServiceInfoSchema')

.allow(null)



        ,
        arbejdsgiverObligatoriskIndbetaling:
            
        JoiObj.link('#IndbetalingKonceptFordelingSchema')


.required()


        ,
        arbejdsgiverObligatoriskOpsparing:
            
        JoiObj.link('#OpsparingKonceptFordelingSchema')


.required()


        ,
        blandetIndbetaling:
            
        JoiObj.link('#IndbetalingKonceptFordelingSchema')


.required()


        ,
        blandetOpsparing:
            
        JoiObj.link('#OpsparingKonceptFordelingSchema')


.required()


        ,
        fordeling:
            
        JoiObj.link('#DuInvestererFordelingSchema')


.required()


        ,
        harDuInvesterer:
            
                    JoiObj.boolean()
                


.required()


        ,
        harOpsparingDuInvesterer:
            
                    JoiObj.boolean()
                


.required()


        ,
        indbetalingFondFordeling:
            
        JoiObj.link('#IndbetalingFondFordelingSchema')


.required()


        ,
        kundekapitalBeloeb:
            
                    JoiObj.number()
                


.required()


        ,
        medarbejderFrivilligIndbetaling:
            
        JoiObj.link('#IndbetalingKonceptFordelingSchema')


.required()


        ,
        medarbejderFrivilligOpsparing:
            
        JoiObj.link('#OpsparingKonceptFordelingSchema')


.required()


        ,
        medarbejderObligatoriskIndbetaling:
            
        JoiObj.link('#IndbetalingKonceptFordelingSchema')


.required()


        ,
        medarbejderObligatoriskOpsparing:
            
        JoiObj.link('#OpsparingKonceptFordelingSchema')


.required()


        ,
        muligt:
            
                    JoiObj.boolean()
                


.required()


        ,
        opsparing:
            
                    JoiObj.number()
                


.required()


        ,
        opsparingFondFordeling:
            
        JoiObj.link('#OpsparingFondFordelingSchema')


.required()


        ,
        simpelIndbetaling:
            
        JoiObj.link('#IndbetalingKonceptFordelingSchema')


.required()


        ,
        simpelOpsparing:
            
        JoiObj.link('#OpsparingKonceptFordelingSchema')


.required()


        
    })
                .shared(ServiceInfoSchema)
                .shared(IndbetalingKonceptFordelingSchema)
                .shared(OpsparingKonceptFordelingSchema)
                .shared(IndbetalingKonceptFordelingSchema)
                .shared(OpsparingKonceptFordelingSchema)
                .shared(DuInvestererFordelingSchema)
                .shared(IndbetalingFondFordelingSchema)
                .shared(IndbetalingKonceptFordelingSchema)
                .shared(OpsparingKonceptFordelingSchema)
                .shared(IndbetalingKonceptFordelingSchema)
                .shared(OpsparingKonceptFordelingSchema)
                .shared(OpsparingFondFordelingSchema)
                .shared(IndbetalingKonceptFordelingSchema)
                .shared(OpsparingKonceptFordelingSchema)
.id('DuInvestererSchema')

export interface DuInvesterer extends ResourceModel { 
    arbejdsgiverObligatoriskIndbetaling: IndbetalingKonceptFordeling;
    arbejdsgiverObligatoriskOpsparing: OpsparingKonceptFordeling;
    blandetIndbetaling: IndbetalingKonceptFordeling;
    blandetOpsparing: OpsparingKonceptFordeling;
    fordeling: DuInvestererFordeling;
    harDuInvesterer: boolean;
    harOpsparingDuInvesterer: boolean;
    indbetalingFondFordeling: IndbetalingFondFordeling;
    kundekapitalBeloeb: number;
    medarbejderFrivilligIndbetaling: IndbetalingKonceptFordeling;
    medarbejderFrivilligOpsparing: OpsparingKonceptFordeling;
    medarbejderObligatoriskIndbetaling: IndbetalingKonceptFordeling;
    medarbejderObligatoriskOpsparing: OpsparingKonceptFordeling;
    muligt: boolean;
    opsparing: number;
    opsparingFondFordeling: OpsparingFondFordeling;
    simpelIndbetaling: IndbetalingKonceptFordeling;
    simpelOpsparing: OpsparingKonceptFordeling;
}

