<form [formGroup]="savingsForm">
  <div class="row spacing-section--over">
    <div class="col-sm-5">
      <p class="text--manchet" coContent="DL.MD01.C382"></p>
      <p
        *ngIf="!isInInsuranceGuide"
        class="text--info"
        coContent="DL.MD01.C394"
      ></p>
    </div>
    <div class="col-sm">
      <mat-radio-group ngDefaultControl formControlName="bankSavings">
        <mat-radio-button
          data-test-id="financial-situation-savings-banksavings-selected"
          [value]="true"
        >
          {{ 'DL.MD01.C29' | content }}
        </mat-radio-button>
        <mat-radio-button
          data-test-id="financial-situation-savings-banksavings-not-selected"
          [value]="false"
        >
          {{ 'DL.MD01.C30' | content }}
        </mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
  <p
    class="text--info"
    *ngIf="financialSituation.frieMidler.formueVedPension"
    coContent="DL.PP01.C404"
  ></p>

  <co-card [hidden]="!savingsForm.controls.bankSavings.value">
    <div class="row">
      <div class="col-xl-4 col-lg-8">
        <p class="text--manchet" coContent="DL.MD01.C384"></p>
        <p class="text--info" coContent="DL.MD01.C385"></p>
        <p class="text--note" coContent="DL.MD01.C386"></p>
      </div>
      <div class="col-xl-8">
        <mitpfa-wealth-picker
          [formName]="'totalValue'"
          [form]="savingsForm"
          [equities]="financialSituation.frieMidler.aktierSamletVaerdi"
          [bonds]="financialSituation.frieMidler.obligationerSamletVaerdi"
          [cash]="financialSituation.frieMidler.kontanterSamletVaerdi"
          [equitiesLabel]="'DL.MD01.C387'"
          [bondsLabel]="'DL.MD01.C388'"
          [cashLabel]="'DL.MD01.C389'"
          [totalLabel]="'DL.MD01.C392'"
          [sliderOptions]="totalValueSliderOptions"
        >
        </mitpfa-wealth-picker>
      </div>
    </div>

    <ng-container *ngIf="annualSavings">
      <div class="row spacing-section--over">
        <div class="col-sm-6">
          <p class="text--manchet" coContent="DL.MD01.C393"></p>
          <p class="text--info" coContent="DL.MD01.C383"></p>
        </div>
        <div class="col-sm">
          <mat-radio-group
            ngDefaultControl
            formControlName="regularAnnualSavings"
          >
            <mat-radio-button [value]="true">
              {{ 'DL.MD01.C29' | content }}
            </mat-radio-button>
            <mat-radio-button [value]="false">
              {{ 'DL.MD01.C30' | content }}
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>

      <div
        class="row spacing-section--over"
        *ngIf="savingsForm.controls?.regularAnnualSavings.value"
      >
        <div class="col-xl-4 col-lg-8">
          <p class="text--manchet" coContent="DL.MD01.C396"></p>
          <p class="text--info" coContent="DL.MD01.C397"></p>
        </div>
        <div class="col-xl-8">
          <mitpfa-wealth-picker
            [formName]="'annualSavings'"
            [form]="savingsForm"
            [equities]="financialSituation.frieMidler.aktierLoebendeOpsparing"
            [bonds]="
              financialSituation.frieMidler.obligationerLoebendeOpsparing
            "
            [cash]="financialSituation.frieMidler.kontanterLoebendeOpsparing"
            [equitiesLabel]="'DL.MD01.C387'"
            [bondsLabel]="'DL.MD01.C388'"
            [cashLabel]="'DL.MD01.C389'"
            [totalLabel]="'DL.MD01.C440'"
            [sliderOptions]="annualSavingsSliderOptions"
          >
          </mitpfa-wealth-picker>
        </div>
      </div>
    </ng-container>

    <div class="row spacing-section--over" *ngIf="shareOfWealth">
      <div class="col-sm-6">
        <p class="text--manchet" coContent="DL.MD01.C454"></p>
        <p class="text--info" coContent="DL.MD01.C455"></p>
      </div>

      <div class="col-lg-6">
        <mat-radio-group
          ngDefaultControl
          formControlName="shareOfWealthOption"
          class="group-breakpoint"
        >
          <mat-radio-button [value]="0">
            {{ 'DL.MD01.C451' | content }}
          </mat-radio-button>
          <mat-radio-button [value]="1">
            {{ 'DL.MD01.C452' | content }}
          </mat-radio-button>
          <mat-radio-button [value]="2">
            {{ 'DL.MD01.C453' | content }}
          </mat-radio-button>
        </mat-radio-group>

        <div>
          <ng-container *ngIf="shareOfWealthInputVisible">
            <div class="row">
              <div class="col-sm-6 offset-sm-6">
                <mat-form-field color="accent">
                  <mat-label>{{ 'DL.MD01.C457' | content }}</mat-label>

                  <input
                    class="small-input"
                    matInput
                    formControlName="shareOfWealth"
                  />
                </mat-form-field>
                <mat-error
                  *ngIf="
                    savingsForm.controls.shareOfWealth.touched &&
                      savingsForm.controls.shareOfWealth.errors?.required;
                    else notEmpty
                  "
                  coContent="DL.MD01.C12"
                ></mat-error>

                <ng-template #notEmpty>
                  <mat-error
                    *ngIf="
                      savingsForm.get('shareOfWealth').touched &&
                        (savingsForm.controls.shareOfWealth.errors?.notNumber ||
                          savingsForm.controls.shareOfWealth.errors?.pattern);
                      else correctFormat
                    "
                    coContent="DL.MD01.C13"
                  ></mat-error>

                  <ng-template #correctFormat>
                    <mat-error
                      *ngIf="
                        savingsForm.get('shareOfWealth').touched &&
                        savingsForm.controls.shareOfWealth.errors?.max
                      "
                      coContent="DL.MD01.C458"
                      [coContentSubst]="100"
                    ></mat-error>

                    <mat-error
                      *ngIf="
                        savingsForm.get('shareOfWealth').touched &&
                        savingsForm.controls.shareOfWealth.errors?.min
                      "
                      coContent="DL.MD01.C459"
                      coContentSubst="0|%"
                    ></mat-error>
                  </ng-template>
                </ng-template>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </co-card>
</form>
