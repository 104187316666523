import { Injectable } from '@angular/core';
import { TrackingService } from './tracking.service';

const eventName = 'track-pension-info';

export type PensionInfoPlacement =
  | 'registration guide'
  | 'investment guide'
  | 'insurance guide'
  | 'your information'
  | 'combine savings';

@Injectable({
  providedIn: 'root'
})
export class PensionInfoTrackingService extends TrackingService {
  trackInitiated(placement: PensionInfoPlacement): void {
    this.dispatchCustomEventTracking(eventName, {
      pensionInfoPlacement: placement,
      pensionInfoAction: 'initiated'
    });
  }

  trackFailed(placement: PensionInfoPlacement): void {
    this.dispatchCustomEventTracking(eventName, {
      pensionInfoPlacement: placement,
      pensionInfoAction: 'failed'
    });
  }

  trackCompleted(placement: PensionInfoPlacement): void {
    this.dispatchCustomEventTracking(eventName, {
      pensionInfoPlacement: placement,
      pensionInfoAction: 'completed'
    });
  }
}
