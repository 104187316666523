import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
  WindowWrapper,
  MobileBridge,
  AuthorizationRequestData
} from './mobile-bridge.module';

@Injectable({
  providedIn: 'root'
})
export class MobileBridgeService {
  private bridgeCompatible: boolean;
  private bridgeAvailable: boolean;
  private bridge: MobileBridge;

  constructor(
    window: WindowWrapper,
    private httpClient: HttpClient
  ) {
    Logger.console = window.console;

    this.bridge = window['MobileBridge'];
    this.bridgeAvailable = typeof this.bridge === 'object';
    this.bridgeCompatible =
      this.bridgeAvailable &&
      typeof this.bridge.getAuthorizationRequest === 'function' &&
      typeof this.bridge.setAuthorizationCode === 'function';
    Logger.info(
      'available (%s) and compatible (%s).',
      this.bridgeAvailable,
      this.bridgeCompatible
    );
  }

  authenticationCompleted() {
    if (this.bridgeCompatible) {
      this.handleAuthorizationRequest();
    }
  }

  public isMobileBridgeAvailable(): boolean {
    return this.bridgeAvailable;
  }

  private handleAuthorizationRequest() {
    const authReqObj = this.bridge.getAuthorizationRequest();
    Logger.debug('recieved AuthorizationRequest %s', authReqObj);
    const authReq = AuthorizationRequest.create(authReqObj);
    if (authReq && authReq.Valid) {
      Logger.debug('posting...', authReq);
      this.httpClient.post(authReq.Uri, authReq.HttpParams).subscribe(
        (res: AuthorizationResponse) => {
          Logger.debug('receved authorization response.', res);
          if (res.code) {
            Logger.debug('calling setAuthorizationCode.', res.code);
            this.bridge.setAuthorizationCode(res.code, res.state);
          } else {
            Logger.warn('NO authorization code was returned.');
          }
        },
        err => Logger.error('call to authorization endpoint failed.', err)
      );
    } else {
      Logger.warn(
        'the authorization request was invalid. No further action will be performed.'
      );
    }
  }
}

class Logger {
  private static LOG_PREFIX = 'Mobile bridge ';
  static console: Console;

  static info(msg: string, ...args: unknown[]): void {
    Logger.console.info(Logger.LOG_PREFIX + msg, ...args);
  }

  static debug(msg: string, ...args: unknown[]): void {
    Logger.console.debug(Logger.LOG_PREFIX + msg, ...args);
  }

  static warn(msg: string, ...args: unknown[]): void {
    Logger.console.warn(Logger.LOG_PREFIX + msg, ...args);
  }

  static error(msg: string, ...args: unknown[]) {
    Logger.console.error(Logger.LOG_PREFIX + msg, ...args);
  }
}

class AuthorizationRequest {
  private constructor(private request: AuthorizationRequestData) {}

  static create(authReq: object | string): AuthorizationRequest {
    if (!authReq) {
      return undefined;
    }
    if (typeof authReq === 'object') {
      return new AuthorizationRequest(authReq as AuthorizationRequestData);
    } else {
      try {
        return new AuthorizationRequest(JSON.parse(authReq));
      } catch (e) {
        Logger.error('failed to parse AuthorizationRequest.', e);
      }
    }
  }

  get Valid() {
    const isUrlRegex = new RegExp(
      '((?:https?://|wwwd{0,3}[.]|[a-z0-9.-]+[.][a-z]{2,4}/)(?:[^s()<>]+|(([^s()<>]+|(([^s()<>]+)))*))+(?:(([^s()<>]+|(([^s()<>]+)))*)|[^s`!()[]{};:\'".,<>?«»“”‘’]))',
      'gi'
    );
    return (
      typeof this === 'object' &&
      typeof this.request.uri === 'string' &&
      this.request.uri.charAt(0) === '/' &&
      typeof this.request.data === 'object' &&
      typeof this.request.data.redirect_uri === 'string' &&
      isUrlRegex.test(this.request.data.redirect_uri) &&
      typeof this.request.data.response_type === 'string' &&
      typeof this.request.data.client_id === 'string' &&
      typeof this.request.data.scope === 'string' &&
      typeof this.request.data.code_challenge_method === 'string' &&
      typeof this.request.data.code_challenge === 'string' &&
      typeof this.request.data.state === 'string' &&
      Object.keys(this.request.data).length === 7
    );
  }

  get Uri(): string {
    return this.request.uri;
  }

  get HttpParams(): HttpParams {
    const params = new HttpParams()
      .set('response_type', this.request.data.response_type)
      .set('client_id', this.request.data.client_id)
      .set('scope', this.request.data.scope)
      .set('redirect_uri', this.request.data.redirect_uri)
      .set('code_challenge_method', this.request.data.code_challenge_method)
      .set('code_challenge', this.request.data.code_challenge)
      .set('state', this.request.data.state);
    return params;
  }
}

interface AuthorizationResponse {
  code: string;
  state: string;
}
