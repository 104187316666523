import { Observable, Subscription } from 'rxjs';
import {
  SigningIframeResponse,
  SigningPopupResponse
} from './signing-popup.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { GlobalWorkingService } from '../global-working/global-working.service';
import { delay } from 'rxjs/operators';

@Component({
  templateUrl: './signing-popup.component.html',
  styleUrls: ['signing-popup.component.scss']
})
export class SigningPopupComponent implements OnInit, OnDestroy {
  signingIframeObservable: Observable<SigningIframeResponse>;
  signingIframeResponse: SigningIframeResponse;
  signIframeSubscription: Subscription;
  initialized: boolean;
  useMobile: boolean;

  constructor(
    private readonly globalWorkingService: GlobalWorkingService,
    private readonly dialogRef: MatDialogRef<
      SigningPopupComponent,
      SigningPopupResponse
    >,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.signingIframeObservable = data.signingIframeObservable;
    this.dialogRef.disableClose = true;
  }

  ngOnInit() {
    this.initialized = false;
    this.fetchSigningIframe();
    this.useMobile = !!window.navigator.userAgent.match(
      /(MitPFA-iOS|MitPFA-Android)/m
    );
  }

  fetchSigningIframe() {
    this.signIframeSubscription = this.signingIframeObservable
      .pipe(delay(0)) // puts subscribe in next cycle, so globalWorkingService is in sync
      .subscribe({
        next: response => {
          this.initialized = true;
          this.globalWorkingService.hide();
          if (!response.fejl) {
            this.signingIframeResponse = response;
          } else {
            this.dialogRef.close({
              success: false,
              hasSignatureIframeError: true,
              error: `${response.fejlkode}: ${response.fejlTekst}`
            });
          }
        },
        error: error => {
          this.initialized = true;
          this.globalWorkingService.hide();
          this.dialogRef.close({
            success: false,
            error: 'network error ' + JSON.stringify(error)
          });
        },
        complete: () => {
          this.initialized = true;
          this.globalWorkingService.hide();
        }
      });
  }

  ngOnDestroy() {
    this.signIframeSubscription.unsubscribe();
  }

  handleSigningResponse(result: SigningPopupResponse): void {
    this.dialogRef.close(result);
  }

  closePopup(): void {
    this.dialogRef.close({
      success: false,
      cancelled: true
    } as SigningPopupResponse);
  }
}
