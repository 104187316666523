import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { Router } from '@angular/router';
import {
  FinancialSituationApiService,
  InvestmentApiService,
  InvestmentGuideApiService
} from '@pfa/api';
import {
  GuidePlacement,
  GuideStepOther,
  GuideStepType,
  GuideTrackingService
} from '@pfa/core';
import { GlobalWorkingService, Step } from '@pfaform';
import { BehaviorSubject, Subject } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import {
  InvestmentGuideConfigurationService,
  InvestmentGuideName,
  InvestmentGuideTrackName
} from './investment-guide-configuration.service';
import {
  CustomerprofileStore,
  InvesteringDetaljerSvar,
  InvestmentGuide,
  InvestmentQuestionaire,
  InvestmentRecommendation,
  MaeglerInfo,
  OekonomiDetaljer,
  OpsparetVaerdi,
  PensionsKundeGenerelleData,
  QuestionReached
} from '@pfa/gen';
import { InvestmentGuideInfo } from '@pfa/models';
import { InvestmentGuideService } from '@mitpfa/shared';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

export type CurrentQuestionSubject =
  BehaviorSubject<CurrentQuestionSubjectValue>;

export interface CurrentQuestionSubjectValue {
  value: QuestionReached;
  update?: boolean;
  back?: boolean;
}

@Component({
  selector: 'mitpfa-investment-guide',
  templateUrl: './investment-guide.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InvestmentGuideComponent implements OnInit {
  private readonly investmentGuideConfigurationService: InvestmentGuideConfigurationService =
    inject(InvestmentGuideConfigurationService);
  private readonly investmentGuideApiService: InvestmentGuideApiService =
    inject(InvestmentGuideApiService);
  private readonly cdRef: ChangeDetectorRef = inject(ChangeDetectorRef);
  private readonly globalWorkingService: GlobalWorkingService =
    inject(GlobalWorkingService);
  private readonly trackingService: GuideTrackingService =
    inject(GuideTrackingService);
  private readonly investmentApiService: InvestmentApiService =
    inject(InvestmentApiService);
  private readonly financialSituationApiService: FinancialSituationApiService =
    inject(FinancialSituationApiService);
  private readonly investmentGuideService: InvestmentGuideService = inject(
    InvestmentGuideService
  );
  private readonly customerprofileStore: CustomerprofileStore =
    inject(CustomerprofileStore);
  private readonly destroyRef: DestroyRef = inject(DestroyRef);
  private readonly router: Router = inject(Router);

  @Input() hideStepperOnAllSteps: boolean;
  @Input() skipIntro: boolean;
  @Input() isOnboardingFlow: boolean;
  @Input() pensionCustomer: PensionsKundeGenerelleData;
  @Input() investmentGuide: InvestmentGuideInfo;
  @Input() broker: MaeglerInfo;
  @Input() pensionInfoSavedValues: OpsparetVaerdi;
  @Output() percentCompleted = new EventEmitter<number>();
  @Output() guideEnd = new EventEmitter();

  public financialSituation: OekonomiDetaljer;
  public recommendations: {
    recommendation: InvestmentRecommendation;
    recommendationBasis: InvestmentQuestionaire;
    investments: InvesteringDetaljerSvar;
  };
  public displayStepper: boolean = false;
  public currentStepIndex: number;
  public recommendationCalculated$ = new Subject<void>();
  public isInitialized: boolean = false;
  public steps: Step[] = this.investmentGuideConfigurationService.steps;
  public stepUpdateError: boolean;
  public showWaitForCalculation: boolean = false;
  private globalId: string;

  public ngOnInit(): void {
    this.customerprofileStore.customerprofileGet().subscribe({
      next: response => {
        this.globalId = response.personDetail.globalId;
      },
      error: () => {
        this.globalId = '';
      }
    });

    if (
      this.pensionCustomer.isRaadgiver &&
      this.investmentGuideService.isAgeExceeded(this.pensionCustomer.alder)
    ) {
      this.globalWorkingService.show();
      this.financialSituationApiService
        .get()
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(financialSituation => {
          this.globalWorkingService.hide();
          this.financialSituation = financialSituation;
          this.init();
        });
    } else {
      this.init();
    }

    this.handleRecommendationCalculationCompleted();
  }

  public guideCompleted(): void {
    this.showWaitForCalculation =
      this.investmentGuideService.showWaitForCalculation = true;

    this.trackingService.trackGuideComplete(
      InvestmentGuideName.NAME,
      InvestmentGuideTrackName.LOADING_RECOMMENDATION,
      GuideStepType.MANDATORY,
      GuideStepOther.UNDEFINED,
      this.isOnboardingFlow
        ? GuidePlacement.ONBOARDING
        : GuidePlacement.INVESTMENT
    );

    const createRecommendation = true;
    this.investmentGuideApiService
      .update(
        this.globalId,
        this.investmentGuideData(createRecommendation),
        createRecommendation
      )
      .pipe(
        concatMap((response: InvestmentGuideInfo) => {
          this.investmentGuide.recommendation = response.recommendation;
          this.recommendations.recommendation = response.recommendation;
          this.recommendations.recommendationBasis =
            response.recommendationBasis;

          return this.investmentApiService.get();
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe({
        next: investments => {
          this.recommendations.investments = investments;
          this.updateStepProgress();
          this.cdRef.detectChanges();

          if (this.isOnboardingFlow) {
            this.guideEnd.emit();
          } else {
            this.recommendationCalculated$.next();
          }
        },
        error: () => {
          this.showWaitForCalculation =
            this.investmentGuideService.showWaitForCalculation = false;
          this.stepUpdateError = true;
          this.cdRef.detectChanges();
        }
      });
  }

  private init(): void {
    this.recommendations = {
      investments: null,
      recommendation: this.investmentGuide.recommendation,
      recommendationBasis: this.investmentGuide.recommendationBasis
    };

    if (!this.investmentGuide.recommendationBasis) {
      this.investmentGuide.recommendationBasis = {
        age: undefined,
        retirementAge: undefined,
        conceptChoice: undefined,
        investmentKnowledge: undefined,
        tradingExperience: undefined,
        moreThan10Trades: undefined,
        savings: undefined,
        primaryResidences: undefined,
        secondaryResidences: undefined,
        commercialResidences: undefined,
        debt: undefined,
        economicImpact: undefined,
        riskWillingness: undefined,
        chosenRiskProfile: undefined,
        lossAbility: undefined,
        climateRelevance: undefined,
        climateExtendedFocus: undefined,
        climateWillingness: undefined,
        climatePriority: undefined
      };
    }

    this.broker = this.broker ?? ({ maeglerBetjent: false } as MaeglerInfo);
    this.pensionInfoSavedValues =
      this.pensionInfoSavedValues ?? ({} as OpsparetVaerdi);
    this.investmentGuideService.initCurrentQuestion(
      this.pensionCustomer.isRaadgiver,
      this.investmentGuide.currentQuestion
    );
    this.updateStepProgress();

    if (this.investmentGuide.currentQuestion !== 'WELCOME') {
      this.trackingService.trackGuideResume(
        InvestmentGuideName.NAME,
        InvestmentGuideTrackName[this.investmentGuide.currentQuestion],
        this.investmentGuideService.checkStepType(
          this.investmentGuide.currentQuestion
        ),
        this.isOnboardingFlow
          ? GuidePlacement.ONBOARDING
          : GuidePlacement.INVESTMENT
      );
    }

    this.investmentGuideService
      .getCurrentQuestion$()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(question => {
        this.onQuestionUpdate(question);
      });
    if (this.skipIntro) {
      this.onStartGuide();
    }
    this.isInitialized = true;
    this.cdRef.detectChanges();
  }

  private onQuestionUpdate(question: CurrentQuestionSubjectValue): void {
    if (
      question.value !== QuestionReached.Welcome &&
      question.value !== QuestionReached.Involvement
    ) {
      this.trackingService.trackGuideStep(
        InvestmentGuideName.NAME,
        InvestmentGuideTrackName[question.value],
        this.investmentGuideService.checkStepType(question.value),
        GuideStepOther.UNDEFINED,
        this.isOnboardingFlow
          ? GuidePlacement.ONBOARDING
          : GuidePlacement.INVESTMENT
      );
    }

    if (question.update) {
      this.stepUpdateError = false;
      this.globalWorkingService.show();

      const createRecommendation = false;
      this.investmentGuideApiService
        .update(
          this.globalId,
          this.investmentGuideData(createRecommendation),
          createRecommendation
        )
        .subscribe({
          next: () => {
            this.globalWorkingService.hide();
            this.setQuestion(question.value);
            this.cdRef.detectChanges();
          },
          error: () => {
            //Stay on the current step and show error
            this.globalWorkingService.hide();
            this.stepUpdateError = true;
            this.investmentGuideService.updateCurrentQuestion({
              value: this.investmentGuide.currentQuestion
            });
          }
        });
    } else {
      if (question.back) {
        this.stepUpdateError = false;
      }

      if (this.investmentGuide.currentQuestion === question.value) {
        return;
      }

      this.setQuestion(question.value);
    }
  }

  public onStartGuide(): void {
    this.currentStepIndex = 0;
    this.displayStepper = true;
    this.investmentGuideService.updateCurrentQuestion({
      value: this.getQuestions(this.steps[0])[0]
    });
  }

  public stepChanged(newStep: Step): void {
    this.currentStepIndex = this.steps.findIndex(step => newStep === step);
  }

  private updateStepProgress(): void {
    this.currentStepIndex = null;
    this.displayStepper = false;

    for (let i = this.steps.length - 1; i > -1; i--) {
      const step = this.steps[i];
      const index = this.getQuestions(step).indexOf(
        this.investmentGuide.currentQuestion
      );

      if (index > -1) {
        step.progress = Math.round(
          (index * 100) / this.getQuestions(step).length
        );
        this.currentStepIndex = i;
        this.displayStepper = true;
      } else {
        step.progress = this.currentStepIndex !== null ? 100 : 0;
      }
    }

    this.calculateTotalProgress();

    this.cdRef.detectChanges();
  }

  private calculateTotalProgress(): void {
    let calculatedPercentCompleted = 0;
    const questionsUsedInCalculation: QuestionReached[] = [];

    this.steps.forEach(step => {
      const questionsInStep: QuestionReached[] = this.getQuestions(step);
      questionsInStep.forEach(question => {
        if (this.useQuestionInCalculation(question)) {
          questionsUsedInCalculation.push(question);
        }
      });
    });

    const indexCurrentQuestion = questionsUsedInCalculation.indexOf(
      this.investmentGuide.currentQuestion
    );
    if (indexCurrentQuestion > -1) {
      calculatedPercentCompleted = Math.round(
        (indexCurrentQuestion * 100) / questionsUsedInCalculation.length
      );
    }
    if (this.investmentGuideService.showWaitForCalculation) {
      calculatedPercentCompleted = 100;
    }

    this.percentCompleted?.emit(calculatedPercentCompleted);
  }

  private useQuestionInCalculation(question: QuestionReached): boolean {
    const shortFlow = !this.investmentGuideService.isAgeExceeded(
      this.pensionCustomer.alder
    );
    if (shortFlow && this.isStepInShortFlow(question)) {
      return false;
    }

    return true;
  }

  private isStepInShortFlow(question: QuestionReached): boolean {
    return (
      question === QuestionReached.RetrievePensioninfo ||
      question === QuestionReached.OtherSavings ||
      question === QuestionReached.RealEstate ||
      question === QuestionReached.SecondaryResidence ||
      question === QuestionReached.HasDebt
    );
  }

  private getQuestions(step: Step): QuestionReached[] {
    return this.investmentGuideConfigurationService.questions[step.id];
  }

  private setQuestion(question: QuestionReached): void {
    if (question === null) {
      this.displayStepper = false;
      return;
    }

    this.investmentGuide.currentQuestion = question;
    const stepId =
      this.investmentGuideConfigurationService.findQuestionStepId(question);

    if (stepId !== this.steps[this.currentStepIndex].id) {
      this.currentStepIndex = this.steps.findIndex(step => step.id === stepId);
    }

    this.updateStepProgress();
  }

  private investmentGuideData(createRecommendation: boolean): InvestmentGuide {
    const data: InvestmentGuide = {
      currentQuestion: this.investmentGuide.currentQuestion,
      recommendation: this.investmentGuide.recommendation,
      recommendationBasis: this.investmentGuide.recommendationBasis,
      advisorSkipFlow: this.investmentGuideService.isShortFlowRunning()
    };

    if (createRecommendation) {
      if (!data.recommendationBasis.age) {
        data.recommendationBasis.age = this.pensionCustomer.alder;
      }
    }

    return data;
  }

  private handleRecommendationCalculationCompleted(): void {
    this.recommendationCalculated$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        const primaryPolicyId =
          this.recommendations.investments.detaljer.primaerPolice;
        const isPrimaryPolicyInsurance =
          this.recommendations.investments.detaljer.policer.some(
            p =>
              p.id === primaryPolicyId &&
              p.type !== 'Forsikring' &&
              p.fejlet === false
          );
        const path = isPrimaryPolicyInsurance
          ? `/mineinvesteringer/${primaryPolicyId}`
          : '/mineinvesteringer';
        this.router.navigateByUrl(path);
      });
  }
}
