<p
  *ngIf="overview.policyType === 'CONTINUATION'"
  [coContent]="'DL.TR01.C414'"
  [coContentSubst]="overview.policeNr"
></p>
<p class="text--manchet text--bold" [coContent]="'DL.TR01.C369'"></p>
<p
  *ngIf="overview.policyType === 'CONTINUATION'"
  [coContent]="'DL.TR01.C415'"
  [coContentSubst]="overview.partialSchemeType + '|' + prices.ordningDatoStreng"
></p>
<p
  *ngIf="overview.policyType === 'NEW'"
  [coContent]="'DL.TR01.C428'"
  [coContentSubst]="overview.partialSchemeType + '|' + prices.ordningDatoStreng"
></p>
<p
  *ngIf="overview.policyType === 'CONTINUATION'"
  [coContent]="'DL.TR01.C416'"
></p>
<p [coContent]="'DL.TR01.C417'"></p>

<table
  class="new-business-advisory-table align-right--column-2 align-right--column-3"
>
  <colgroup>
    <col style="width: 64%" />
    <col style="width: 18%" />
    <col style="width: 18%" />
  </colgroup>
  <thead>
    <tr>
      <th></th>
      <th>
        <div class="text--bold" [coContent]="'DL.TR01.C418'"></div>
        <div
          *ngIf="overview.policyType === 'CONTINUATION'"
          [coContent]="'DL.TR01.C419'"
        ></div>
      </th>
      <th>
        <div class="text--bold" [coContent]="'DL.TR01.C420'"></div>
        <div
          *ngIf="overview.policyType === 'CONTINUATION'"
          [coContent]="'DL.TR01.C419'"
        ></div>
      </th>
    </tr>
  </thead>
  <tbody>
    <!-- Erhvervsevne -->
    <tr
      *ngIf="
        prices.tae.length > 0 ||
        prices.resourceforloeb.length > 0 ||
        prices.taeEngang.length > 0 ||
        prices.indbetalingssikring.length > 0
      "
      class="no-border-row"
    >
      <td colspan="3" class="text--bold" [coContent]="'DL.TR01.C76'"></td>
    </tr>

    <tr
      *ngFor="let taePrice of prices.tae; last as isLast"
      class="no-border-row"
    >
      <td>
        <div
          [coContent]="'DL.FG01.C56'"
          [coContentSubst]="taePrice.alder"
        ></div>
        <div class="text--note" [ngSwitch]="taePrice.daekningId">
          <div
            *ngSwitchCase="801"
            class="text--note"
            [coContent]="'DL.TR01.C194'"
          ></div>
          <div
            *ngSwitchCase="802"
            class="text--note"
            [coContent]="'DL.TR01.C195'"
          ></div>
          <div
            *ngSwitchCase="807"
            class="text--note"
            [coContent]="'DL.TR01.C192'"
          ></div>
          <div
            *ngSwitchCase="808"
            class="text--note"
            [coContent]="'DL.TR01.C290'"
          ></div>
          <div
            *ngSwitchCase="811"
            class="text--note"
            [coContent]="'DL.TR01.C399'"
          ></div>
          <div
            *ngSwitchCase="812"
            class="text--note"
            [coContent]="'DL.TR01.C386'"
          ></div>
          <div
            *ngSwitchCase="813"
            class="text--note"
            [coContent]="'DL.TR01.C387'"
          ></div>
          <div
            *ngSwitchCase="814"
            class="text--note"
            [coContent]="'DL.TR01.C406'"
          ></div>
          <div
            *ngSwitchCase="815"
            class="text--note"
            [coContent]="'DL.TR01.C388'"
          ></div>
          <div
            *ngSwitchCase="816"
            class="text--note"
            [coContent]="'DL.TR01.C398'"
          ></div>
        </div>
      </td>
      <td>
        <co-coverage-payout [coverage]="taePrice"></co-coverage-payout>
      </td>
      <td>
        <co-coverage-price
          [coverage]="taePrice"
          [digits]="2"
        ></co-coverage-price>
      </td>
    </tr>

    <!-- Resourceforloeb -->
    <tr
      *ngFor="let forloebPrice of prices.resourceforloeb; last as isLast"
      class="no-border-row"
    >
      <td>
        <div
          [coContent]="'DL.FG01.C1030'"
          [coContentSubst]="forloebPrice.alder"
        ></div>

        <div class="text--note" [coContent]="'DL.TR01.C196'"></div>
      </td>
      <td>
        <co-coverage-payout [coverage]="forloebPrice"></co-coverage-payout>
      </td>
      <td>
        <co-coverage-price
          [coverage]="forloebPrice"
          [digits]="2"
        ></co-coverage-price>
      </td>
    </tr>

    <!-- Erhvervsevne Boerne -->
    <tr
      *ngFor="let boernePensionPrice of prices.taeBoerne; last as isLast"
      class="no-border-row"
    >
      <td>
        <div
          [coContent]="'DL.FG01.C58'"
          [coContentSubst]="boernePensionPrice.alder"
        ></div>
      </td>
      <td>
        <co-coverage-payout
          [coverage]="boernePensionPrice"
        ></co-coverage-payout>
      </td>
      <td>
        <co-coverage-price
          [coverage]="boernePensionPrice"
          [digits]="2"
        ></co-coverage-price>
      </td>
    </tr>

    <!-- Erhvervsevne engang -->
    <tr
      *ngFor="let engang of prices.taeEngang; last as isLast"
      class="no-border-row"
    >
      <td>
        <div [coContent]="'DL.FG01.C57'" [coContentSubst]="engang.alder"></div>

        <div class="text--note" [coContent]="'DL.TR01.C197'"></div>
      </td>
      <td>
        <co-coverage-payout [coverage]="engang"></co-coverage-payout>
      </td>
      <td>
        <co-coverage-price [coverage]="engang" [digits]="2"></co-coverage-price>
      </td>
    </tr>

    <!-- Indbetalingssikring -->
    <tr
      *ngFor="let sikring of prices.indbetalingssikring; last as isLast"
      class="no-border-row"
    >
      <td>
        <div
          [coContent]="'DL.TR01.C373'"
          [coContentSubst]="sikring.alder"
        ></div>
        <div
          class="text--note"
          [coContent]="'DL.TR01.C204'"
          *ngIf="
            overview.taePakkeTilkendelseskriterie ===
            'HALV_VED_HALV_OG_HEL_VED_TO_TREDJEDELE'
          "
        ></div>
        <div
          class="text--note"
          [coContent]="'DL.TR01.C518'"
          *ngIf="
            overview.taePakkeTilkendelseskriterie ===
            'HALV_VED_HALV_MED_HEL_PRAEMIEFRITAGELSE'
          "
        ></div>
        <div
          class="text--note"
          [coContent]="'DL.TR01.C451'"
          *ngIf="
            overview.taePakkeTilkendelseskriterie ===
            ('HEL_VED_TO_TREDJEDELE_BASIS' ||
              'HEL_VED_TO_TREDJEDELE_GRUND' ||
              'HEL_VED_TO_TREDJEDELE')
          "
        ></div>
        <div
          class="text--note"
          [coContent]="'DL.TR01.C452'"
          *ngIf="
            overview.taePakkeTilkendelseskriterie ===
            ('HEL_VED_HALV_50_PROCENT_SELVST' ||
              'HEL_VED_HALV_30_PROCENT_MELLEM' ||
              'HEL_VED_HALV_10_PROCENT_FULD' ||
              'HEL_VED_HALV_10_PROCENT_TOP' ||
              'HEL_VED_HALV_50_PROCENT' ||
              'HEL_VED_HALV_25_PROCENT')
          "
        ></div>
      </td>
      <td>
        <co-coverage-payout [coverage]="sikring"></co-coverage-payout>
      </td>
      <td>
        <co-coverage-price
          [coverage]="sikring"
          [digits]="2"
        ></co-coverage-price>
      </td>
    </tr>

    <!-- Kritisk sygdom -->
    <tr
      *ngIf="prices.ks.length > 0 || prices.ksBoerne.length > 0"
      class="no-border-row border-top"
    >
      <td colspan="3" class="text--bold" [coContent]="'DL.TR01.C92'"></td>
    </tr>

    <tr *ngFor="let ks of prices.ks; last as isLast" class="no-border-row">
      <td>
        <div [coContent]="'DL.FG01.C53'" [coContentSubst]="ks.alder"></div>
      </td>
      <td>
        <co-coverage-payout [coverage]="ks"></co-coverage-payout>
      </td>
      <td>
        <co-coverage-price [coverage]="ks" [digits]="2"></co-coverage-price>
      </td>
    </tr>

    <!-- Kritisk sygdom boern -->
    <tr
      *ngFor="let ksBoern of prices.ksBoerne; last as isLast"
      class="no-border-row"
    >
      <td>
        <div
          [coContent]="'DL.FG01.C279'"
          [coContentSubst]="ksBoern.alder"
        ></div>
      </td>
      <td>
        <co-coverage-payout [coverage]="ksBoern"></co-coverage-payout>
      </td>
      <td>
        <co-coverage-price
          [coverage]="ksBoern"
          [digits]="2"
        ></co-coverage-price>
      </td>
    </tr>

    <!-- Helbredssikring -->
    <tr
      *ngIf="prices.hs.length > 0 || prices.hsBoerne.length > 0"
      class="no-border-row border-top"
    >
      <td colspan="3" class="text--bold" [coContent]="'DL.TR01.C93'"></td>
    </tr>

    <tr *ngFor="let hs of prices.hs; last as isLast" class="no-border-row">
      <td>
        <div [coContent]="'DL.FG01.C855'"></div>
      </td>
      <td>
        <co-coverage-payout [coverage]="hs"></co-coverage-payout>
      </td>
      <td>
        <co-coverage-price [coverage]="hs" [digits]="2"></co-coverage-price>
      </td>
    </tr>

    <!-- Helbredssikring boern -->
    <tr
      *ngFor="let hsBoern of prices.hsBoerne; last as isLast"
      class="no-border-row"
    >
      <td>
        <div
          [coContent]="'DL.FG01.C856'"
          [coContentSubst]="hsBoern.alder"
        ></div>
      </td>
      <td>
        <co-coverage-payout [coverage]="hsBoern"></co-coverage-payout>
      </td>
      <td>
        <co-coverage-price
          [coverage]="hsBoern"
          [digits]="2"
        ></co-coverage-price>
      </td>
    </tr>

    <!-- PFA forebygger -->
    <tr *ngIf="prices.forebygger.length > 0" class="no-border-row border-top">
      <td colspan="3" class="text--bold" [coContent]="'DL.TR01.C97'"></td>
    </tr>

    <tr
      *ngFor="let forebygger of prices.forebygger; last as isLast"
      class="no-border-row"
    >
      <td>
        <div [coContent]="'DL.FG01.C48'"></div>
      </td>
      <td>
        <co-coverage-payout [coverage]="forebygger"></co-coverage-payout>
      </td>
      <td>
        <co-coverage-price
          [coverage]="forebygger"
          [digits]="2"
        ></co-coverage-price>
      </td>
    </tr>

    <!-- PFA diagnose -->
    <tr *ngIf="prices.diagnose.length > 0" class="no-border-row border-top">
      <td colspan="3" class="text--bold" [coContent]="'DL.TR01.C98'"></td>
    </tr>

    <tr
      *ngFor="let diagnose of prices.diagnose; last as isLast"
      class="no-border-row"
    >
      <td>
        <div [coContent]="'DL.FG01.C228'"></div>
      </td>
      <td>
        <co-coverage-payout [coverage]="diagnose"></co-coverage-payout>
      </td>
      <td>
        <co-coverage-price
          [coverage]="diagnose"
          [digits]="2"
        ></co-coverage-price>
      </td>
    </tr>

    <!-- PFA Early care -->
    <tr *ngIf="prices.earlycare.length > 0" class="no-border-row border-top">
      <td colspan="3" class="text--bold" [coContent]="'DL.TR01.C285'"></td>
    </tr>

    <tr
      *ngFor="let earlycare of prices.earlycare; last as isLast"
      class="no-border-row"
    >
      <td>
        <div [coContent]="'DL.TR01.C286'"></div>
      </td>
      <td>
        <co-coverage-payout [coverage]="earlycare"></co-coverage-payout>
      </td>
      <td>
        <co-coverage-price
          [coverage]="earlycare"
          [digits]="2"
        ></co-coverage-price>
      </td>
    </tr>

    <!-- PFA sundhedslinje -->
    <tr
      *ngIf="prices.sundhedslinje.length > 0"
      class="no-border-row border-top"
    >
      <td colspan="3" class="text--bold" [coContent]="'DL.FG01.C1605'"></td>
    </tr>

    <tr
      *ngFor="let sundhedslinje of prices.sundhedslinje; last as isLast"
      class="no-border-row"
    >
      <td>
        <div [coContent]="'DL.FG01.C1606'"></div>
      </td>
      <td>
        <co-coverage-payout [coverage]="sundhedslinje"></co-coverage-payout>
      </td>
      <td>
        <co-coverage-price
          [coverage]="sundhedslinje"
          [digits]="2"
        ></co-coverage-price>
      </td>
    </tr>

    <!-- PFA liv -->
    <tr
      *ngIf="
        prices.liv.length > 0 ||
        prices.livEngang.length > 0 ||
        prices.livEngangHskode_4_5.length > 0 ||
        prices.livBoernePension.length > 0
      "
      class="no-border-row border-top"
    >
      <td colspan="3" class="text--bold" [coContent]="'DL.TR01.C86'"></td>
    </tr>

    <tr
      *ngFor="let livRate of prices.liv; last as isLast"
      class="no-border-row"
    >
      <td>
        <div [coContent]="'DL.FG01.C61'" [coContentSubst]="livRate.alder"></div>
        <div class="text--note" [coContent]="'DL.TR01.C233'"></div>
      </td>
      <td>
        <co-coverage-payout [coverage]="livRate"></co-coverage-payout>
      </td>
      <td>
        <co-coverage-price
          [coverage]="livRate"
          [digits]="2"
        ></co-coverage-price>
      </td>
    </tr>

    <tr
      *ngFor="let livEngang of prices.livEngang; last as isLast"
      class="no-border-row"
    >
      <td>
        <div [coContent]="'DL.FG01.C62'"></div>
      </td>
      <td>
        <co-coverage-payout [coverage]="livEngang"></co-coverage-payout>
      </td>
      <td>
        <co-coverage-price
          [coverage]="livEngang"
          [digits]="2"
        ></co-coverage-price>
      </td>
    </tr>

    <tr
      *ngFor="
        let livEngangHskode_4_5 of prices.livEngangHskode_4_5;
        last as isLast
      "
      class="no-border-row"
    >
      <td>
        <div [coContent]="'DL.TR01.C364'"></div>
      </td>
      <td>
        <co-coverage-payout
          [coverage]="livEngangHskode_4_5"
        ></co-coverage-payout>
      </td>
      <td>
        <co-coverage-price
          [coverage]="livEngangHskode_4_5"
          [digits]="2"
        ></co-coverage-price>
      </td>
    </tr>

    <tr
      *ngFor="let livBoernepension of prices.livBoernePension; last as isLast"
      class="no-border-row"
    >
      <td>
        <div
          [coContent]="'DL.FG01.C69'"
          [coContentSubst]="livBoernepension.alder"
        ></div>
      </td>
      <td>
        <co-coverage-payout [coverage]="livBoernepension"></co-coverage-payout>
      </td>
      <td>
        <co-coverage-price
          [coverage]="livBoernepension"
          [digits]="2"
        ></co-coverage-price>
      </td>
    </tr>
  </tbody>
</table>

<div *ngIf="showCoveragePriceNotFoundText">
  <co-icon-info></co-icon-info>
  <span class="text--note" [coContent]="'DL.TR01.C424'"></span>&nbsp;
  <span class="text--note" [coContent]="'DL.TR01.C425'"></span>
</div>

<co-spacer-section *ngIf="showServicePricesHeader"></co-spacer-section>

<p
  *ngIf="showServicePricesHeader"
  class="text--manchet text--bold"
  [coContent]="'DL.TR01.C431'"
></p>

<table class="new-business-advisory-table align-right--column-2">
  <caption hidden [coContent]="'DL.TR01.C431'"></caption>
  <thead>
    <tr>
      <th></th>
      <th [coContent]="'DL.PL01.C34'"></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let priceLine of prices.serviceOgRaadgivning">
      <td
        [coContent]="priceLine.cTekstId"
        [coContentSubst]="
          (priceLine.param1 | numberFormat) +
          '|' +
          (priceLine.param2 | numberFormat)
        "
      ></td>

      <td *ngIf="priceLine.procent">{{ priceLine.procent | number }} %</td>
      <td *ngIf="priceLine.beloeb">
        {{ priceLine.beloeb | numberFormat: 2 }}
        <span [coContent]="'Global.kroner'"></span>
      </td>
    </tr>
  </tbody>
</table>

<co-spacer-section></co-spacer-section>

<p class="text--manchet text--bold" [coContent]="'DL.TR01.C433'"></p>
<p [coContent]="'DL.TR01.C434'"></p>
