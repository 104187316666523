/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const IrrevocabilityTypeSchema = JoiObj.string().valid(
        'REVOCABLE'
        ,
        'UNLIMITED_IRREVOCABLE'
        ,
        'TEMPORARY_UNTIL_DATE'
        ,
        'TEMPORARY_UNTIL_NOT_COMMON_RESIDENCE'
        ,
        'TEMPORARY_UNTIL_NOT_MARRIED'
        
    )
.id('IrrevocabilityTypeSchema')

export type IrrevocabilityType = 'REVOCABLE' | 'UNLIMITED_IRREVOCABLE' | 'TEMPORARY_UNTIL_DATE' | 'TEMPORARY_UNTIL_NOT_COMMON_RESIDENCE' | 'TEMPORARY_UNTIL_NOT_MARRIED';

export const IrrevocabilityType = {
    Revocable: 'REVOCABLE' as IrrevocabilityType,
    UnlimitedIrrevocable: 'UNLIMITED_IRREVOCABLE' as IrrevocabilityType,
    TemporaryUntilDate: 'TEMPORARY_UNTIL_DATE' as IrrevocabilityType,
    TemporaryUntilNotCommonResidence: 'TEMPORARY_UNTIL_NOT_COMMON_RESIDENCE' as IrrevocabilityType,
    TemporaryUntilNotMarried: 'TEMPORARY_UNTIL_NOT_MARRIED' as IrrevocabilityType
};

