import { Injectable } from '@angular/core';
import { ShowPdfModalComponent } from './show-pdf-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { PopupStyle } from '@pfa/models';

@Injectable()
export class PdfPopupService {
  constructor(public dialog: MatDialog) {}

  showPdfPopup(pdfUrl: string) {
    const modalRef = this.dialog.open(ShowPdfModalComponent, {
      panelClass: PopupStyle.PopupExtraLarge
    });

    modalRef.componentInstance.setPdfLink(pdfUrl);
  }
}
