/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TreatmentType } from './treatmentType';
import { TreatmentTypeSchema } from './treatmentType';
import * as JoiObj from "joi";


    export const TreatmentTypeHierarchySchema = JoiObj.object({
        treatmentTypes:
                JoiObj.array().items(
        
        JoiObj.link('#TreatmentTypeSchema')

        )
        

.required()


        
    })
                .shared(TreatmentTypeSchema)
.id('TreatmentTypeHierarchySchema')

export interface TreatmentTypeHierarchy { 
    treatmentTypes: Array<TreatmentType>;
}

