<div
  class="pensionestimate-form-of-savings no_top_buttom_padding"
  *ngIf="depositsFormOfSavings.policer.length > 1 && !isNewBusinessAdvisory"
>
  <div class="policypicker--container">
    <mat-form-field class="policypicker" color="accent">
      <mat-select
        [value]="selectedPolicy.id"
        (selectionChange)="selectPolicy($event)"
      >
        <mat-option
          *ngFor="let policy of depositsFormOfSavings.policer"
          [value]="policy.id"
        >
          <span>{{ policy.id }}</span>
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>
<div *ngIf="selectedPolicy.detaljer?.paragraf53A" class="info-text">
  <co-icon-info></co-icon-info>
  <span [coContent]="'DL.PP01.C535'"></span>
</div>
<co-warning *ngIf="isBlockedPolicy(selectedPolicy)">
  <span [coContent]="'DL.PP01.C1085'"></span>
</co-warning>
<div
  *ngIf="
    selectedPolicy.detaljer?.forsikringsOrdning ||
    selectedPolicy.type === 'Forsikring'
  "
  class="info-text"
>
  <co-icon-info></co-icon-info>
  <span [coContent]="'DL.PP01.C536'"></span>
</div>
<div
  *ngIf="selectedPolicy.detaljer?.ingenIndbetalingAdvarsel"
  class="info-text"
>
  <co-icon-info></co-icon-info>
  <span [coContent]="'DL.PP01.C538'"></span>
</div>
<div *ngIf="selectedPolicy.detaljer && selectedPolicy.type !== 'Forsikring'">
  <div
    class="pensionestimate-form-of-savings border-line"
    *ngIf="isSavingsFormKnown()"
  >
    <div>
      <div [coContent]="'DL.PP01.C575'"></div>
      <mat-radio-group
        [(ngModel)]="selectedPolicy.detaljer.valgtOpsparingFormFordeling"
        (change)="updatePolicyDataChanged()"
      >
        <div *ngFor="let savingsFormType of savingsFormTypes">
          <div
            *ngIf="
              !savingsFormType.disabled ||
              savingsFormType.type ===
                originSelectedPolicy.detaljer.valgtOpsparingFormFordeling
            "
          >
            <mat-radio-button
              class="radio-button"
              value="{{ savingsFormType.type }}"
              [disabled]="!isEditable()"
            >
              <span coContent="{{ savingsFormType.contentId }}"></span>
            </mat-radio-button>
            <span
              class="pensionestimate-form-of-savings-current-selection-text"
              *ngIf="
                savingsFormType.type ===
                originSelectedPolicy.detaljer.valgtOpsparingFormFordeling
              "
              [coContent]="'DL.PP01.C539'"
            ></span>
          </div>
        </div>
      </mat-radio-group>
    </div>
  </div>
  <div class="sub-header">
    <div [coContent]="'DL.PP01.C545'"></div>
    <div *ngFor="let editablePensionType of editablePensionTypes">
      <div
        class="border-line"
        *ngIf="
          isPensionTypeEditable(editablePensionType.pensionType) ||
          selectedPolicy.detaljer[
            'indbetaling' + editablePensionType.pensionType
          ]?.linieElementerOpsparingsForm.length > 0
        "
      >
        <div
          class="pensionestimate-form-of-savings pensiontype_sub_header"
          [coContent]="editablePensionType.contentId"
        ></div>
        <div
          *ngFor="
            let lineElement of selectedPolicy.detaljer[
              'indbetaling' + editablePensionType.pensionType
            ].linieElementerOpsparingsForm
          "
        >
          <div
            *ngIf="lineElement.contentId === 'DL.PP01.C557'"
            class="pensionestimate-form-of-savings savings-deposit-detail-line"
          >
            <div [coContent]="lineElement.contentId"></div>
            <div class="pensionestimate-form-of-savings-value">
              {{ lineElement.value | currencyFormat }}
            </div>
          </div>
        </div>
        <div
          *ngIf="
            selectedPolicy.detaljer[
              'indbetaling' + editablePensionType.pensionType
            ].indbetalingLoftMin !== undefined
          "
          class="pensionestimate-form-of-savings savings-deposit-detail-line"
        >
          <div [coContent]="'DL.PP01.C547'"></div>
          <div class="pensionestimate-form-of-savings-value">
            {{
              selectedPolicy.detaljer[
                'indbetaling' + editablePensionType.pensionType
              ].indbetalingLoftMin | currencyFormat
            }}
          </div>
        </div>
        <div *ngIf="selectedPolicyChanged">
          <mat-radio-group
            [(ngModel)]="
              selectedPolicy.detaljer[
                'indbetaling' + editablePensionType.pensionType
              ].fordelingValg
            "
            (change)="updatePolicyDataChanged()"
          >
            <mat-radio-button
              class="radio-button"
              value="MestMuligt"
              [disabled]="
                !isEditable() ||
                !isPensionTypeEditable(editablePensionType.pensionType)
              "
            >
              <span [coContent]="'DL.PP01.C551'"></span>
            </mat-radio-button>
            <div
              *ngIf="
                selectedPolicy.detaljer[
                  'indbetaling' + editablePensionType.pensionType
                ].fordelingValg === 'MestMuligt'
              "
              [coContent]="'DL.PP01.C552'"
            ></div>
            <div
              *ngIf="
                selectedPolicy.detaljer[
                  'indbetaling' + editablePensionType.pensionType
                ].fordelingValg === 'MestMuligt'
              "
              class="amount_slider"
            >
              <mitpfa-pension-estimate-select
                [disabled]="
                  !isEditable() ||
                  !isPensionTypeEditable(editablePensionType.pensionType)
                "
                [min]="
                  selectedPolicy.detaljer[
                    'indbetaling' + editablePensionType.pensionType
                  ].indbetalingIntetLoftMin
                "
                [max]="
                  selectedPolicy.detaljer[
                    'indbetaling' + editablePensionType.pensionType
                  ].indbetalingIntetLoftMax
                "
                [step]="1"
                [value]="
                  selectedPolicy.detaljer[
                    'indbetaling' + editablePensionType.pensionType
                  ].indbetalingAndreOrdninger
                "
                [validateMaxInput]="true"
                [unit]="'Global.kroner'"
                (update)="
                  updateSavingsDepoistOther(
                    selectedPolicy.detaljer[
                      'indbetaling' + editablePensionType.pensionType
                    ],
                    $event
                  )
                "
              >
              </mitpfa-pension-estimate-select>
            </div>
            <mat-radio-button
              class="radio-button"
              value="FastAarligt"
              [disabled]="
                !isEditable() ||
                !isPensionTypeEditable(editablePensionType.pensionType)
              "
            >
              <span [coContent]="'DL.PP01.C553'"></span>
            </mat-radio-button>
            <div
              *ngIf="
                selectedPolicy.detaljer[
                  'indbetaling' + editablePensionType.pensionType
                ].fordelingValg === 'FastAarligt'
              "
              class="amount_slider"
            >
              <mitpfa-pension-estimate-select
                [disabled]="
                  !isEditable() ||
                  !isPensionTypeEditable(editablePensionType.pensionType)
                "
                [min]="
                  selectedPolicy.detaljer[
                    'indbetaling' + editablePensionType.pensionType
                  ].indbetalingLoftMin
                "
                [max]="
                  selectedPolicy.detaljer[
                    'indbetaling' + editablePensionType.pensionType
                  ].indbetalingLoftMax
                "
                [step]="1"
                [value]="
                  selectedPolicy.detaljer[
                    'indbetaling' + editablePensionType.pensionType
                  ].oensketIndbetaling
                "
                [validateMaxInput]="true"
                [unit]="'Global.kroner'"
                (update)="
                  updateSavingsDepoist(
                    selectedPolicy.detaljer[
                      'indbetaling' + editablePensionType.pensionType
                    ],
                    $event
                  )
                "
              >
              </mitpfa-pension-estimate-select>
            </div>
          </mat-radio-group>
        </div>
        <div
          *ngFor="
            let lineElement of selectedPolicy.detaljer[
              'indbetaling' + editablePensionType.pensionType
            ].linieElementerOpsparingsForm
          "
        >
          <div
            *ngIf="
              lineElement.contentId === 'DL.PP01.C549' ||
              lineElement.contentId === 'DL.PP01.C550'
            "
            class="pensionestimate-form-of-savings savings-deposit-detail-line"
          >
            <div [coContent]="lineElement.contentId"></div>
            <div class="pensionestimate-form-of-savings-value">
              {{ lineElement.value | currencyFormat }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      *ngFor="
        let unmodifiableDepositPensionType of unmodifiableDepositPensionTypes
      "
    >
      <div
        class="border-line"
        *ngIf="
          selectedPolicy.detaljer[
            unmodifiableDepositPensionType.depositPensionType
          ]?.linieElementerOpsparingsForm.length > 0
        "
      >
        <div
          class="pensionestimate-form-of-savings pensiontype_sub_header"
          [coContent]="unmodifiableDepositPensionType.contentId"
        ></div>
        <div
          class="unmodifiable-pension"
          *ngFor="
            let lineElement of selectedPolicy.detaljer[
              unmodifiableDepositPensionType.depositPensionType
            ].linieElementerOpsparingsForm
          "
        >
          <div
            class="pensionestimate-form-of-savings savings-deposit-detail-line"
          >
            <div [coContent]="lineElement.contentId"></div>
            <div class="pensionestimate-form-of-savings-value">
              {{ lineElement.value | currencyFormat }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="buttonspanel">
    <button
      [disabled]="!selectedPolicyDataChanged || persistedContentInBasket"
      mat-raised-button
      [coContent]="'DL.PP01.C493'"
      class="button-secondary"
      (click)="approvePrepare('Basket')"
    ></button>
    <button
      *ngIf="!isNewBusinessAdvisory"
      [disabled]="!selectedPolicyDataChanged || persistedContentInBasket"
      mat-raised-button
      [coContent]="'DL.PP01.C492'"
      class="button-margin-left"
      (click)="approvePrepare('ApproveNow')"
    ></button>
    <co-bodytext>
      <co-warning *ngIf="persistedContentInBasket" class="spacing-unit--over">
        <span [coContent]="'DL.PP01.C498'"></span>
      </co-warning>
      <co-warning
        *ngIf="nonPersistedContentInBasket"
        class="spacing-unit--over"
      >
        <span [coContent]="'DL.PP01.C570'"></span>
      </co-warning>
      <div
        *ngIf="addedToBasket && !isNewBusinessAdvisory"
        class="form__info spacing-unit--over"
        [coContent]="'DL.MD01.C159'"
      ></div>
      <co-warning *ngIf="addedToBasketFailed" class="spacing-unit--over">
        <span [coContent]="'DL.MD01.C160'"></span>
      </co-warning>
      <co-warning *ngIf="approveNowFailed" class="spacing-unit--over">
        <span [coContent]="'DL.MD01.C161'"></span>
      </co-warning>
    </co-bodytext>
  </div>
</div>
