/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const DaekningGruppeSchema = JoiObj.string().valid(
        'PFAForebygger'
        ,
        'Diagnose'
        ,
        'Helbredssikkring'
        ,
        'KritiskSygdom'
        ,
        'NedsatErhversevne'
        ,
        'Liv'
        ,
        'Efterladte'
        
    )
.id('DaekningGruppeSchema')

export type DaekningGruppe = 'PFAForebygger' | 'Diagnose' | 'Helbredssikkring' | 'KritiskSygdom' | 'NedsatErhversevne' | 'Liv' | 'Efterladte';

export const DaekningGruppe = {
    PfaForebygger: 'PFAForebygger' as DaekningGruppe,
    Diagnose: 'Diagnose' as DaekningGruppe,
    Helbredssikkring: 'Helbredssikkring' as DaekningGruppe,
    KritiskSygdom: 'KritiskSygdom' as DaekningGruppe,
    NedsatErhversevne: 'NedsatErhversevne' as DaekningGruppe,
    Liv: 'Liv' as DaekningGruppe,
    Efterladte: 'Efterladte' as DaekningGruppe
};

