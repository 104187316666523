import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MaeglerInfo } from '@pfa/gen';
import { InvestmentGuideInfo } from '@pfa/models';
import { InvestmentGuideQuestion } from '../../../investment-guide-configuration.service';
import { InvestmentGuideService } from '@mitpfa/shared';
import { ClimateRelevanceChoice } from '../../investment-guide-climate-step.model';

@Component({
  selector: 'mitpfa-investment-guide-climate-relevance',
  templateUrl: './investment-guide-climate-step-relevance.component.html',
  styleUrls: ['./investment-guide-climate-step-relevance.component.scss']
})
export class InvestmentGuideClimateRelevanceComponent implements OnInit {
  @Input() investmentGuide: InvestmentGuideInfo;
  @Input() broker: MaeglerInfo;
  @Output() guideComplete = new EventEmitter();

  answer: UntypedFormControl;
  choices: ClimateRelevanceChoice[] = [
    {
      text: 'DL.INVGU01.C671',
      value: 'LOW'
    },
    {
      text: 'DL.INVGU01.C672',
      value: 'MEDIUM'
    },
    {
      text: 'DL.INVGU01.C673',
      value: 'HIGH'
    }
  ];

  constructor(
    private readonly investmentGuideService: InvestmentGuideService
  ) {}

  ngOnInit(): void {
    this.answer = new UntypedFormControl(
      this.investmentGuide.recommendationBasis.climateRelevance,
      Validators.required
    );
  }

  choose(): void {
    this.investmentGuide.recommendationBasis.climateRelevance =
      this.answer.value;
    if (this.answer.value === 'LOW') {
      this.investmentGuide.recommendationBasis.climateExtendedFocus = null;
      this.investmentGuide.recommendationBasis.climateWillingness = null;
      this.investmentGuide.recommendationBasis.climatePriority = null;
      this.investmentGuideService.setClimateRelevance(false);
      this.guideComplete.emit();
    } else {
      this.investmentGuideService.setClimateRelevance(true);
      this.investmentGuideService.updateCurrentQuestion({
        value: InvestmentGuideQuestion.climateExtendedFocus,
        update: true
      });
    }
  }
}
