/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ServiceInfo } from './serviceInfo';
import { ServiceInfoSchema } from './serviceInfo';
import { BasisSvar } from './basisSvar';
import { BasisSvarSchema } from './basisSvar';
import * as JoiObj from "joi";


    export const HentLoadtidSvarSchema = JoiObj.object({
        serviceInfo:
            
        JoiObj.link('#ServiceInfoSchema')

.allow(null)



        ,
        fejlet:
            
                    JoiObj.boolean()
                


.required()


        ,
        aktivsammesaetningloadtid:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        pfaafkastloadtid:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        
    })
                .shared(ServiceInfoSchema)
.id('HentLoadtidSvarSchema')

export interface HentLoadtidSvar extends BasisSvar { 
    aktivsammesaetningloadtid: string;
    pfaafkastloadtid: string;
}

