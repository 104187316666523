/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { KillswitchDetails } from './killswitchDetails';
import { KillswitchDetailsSchema } from './killswitchDetails';
import * as JoiObj from "joi";


    export const KillswitchesAdminResponseSchema = JoiObj.object({
        killswitchDetailsPensionskunde:
            
        JoiObj.link('#KillswitchDetailsSchema')


.required()


        ,
        killswitchDetailsRaadgiver:
            
        JoiObj.link('#KillswitchDetailsSchema')


.required()


        ,
        killswitchDetailsPensionsinfo:
            
        JoiObj.link('#KillswitchDetailsSchema')


.required()


        ,
        killswitchDetailsPensionsinfoPartner:
            
        JoiObj.link('#KillswitchDetailsSchema')


.required()


        ,
        killswitchDetailsEksternOverKunde:
            
        JoiObj.link('#KillswitchDetailsSchema')


.required()


        ,
        killswitchDetailsEksternOverRaadgiver:
            
        JoiObj.link('#KillswitchDetailsSchema')


.required()


        ,
        killswitchDetailsBeneficiary:
            
        JoiObj.link('#KillswitchDetailsSchema')


.required()


        ,
        killswitchDetailsNytegning:
            
        JoiObj.link('#KillswitchDetailsSchema')


.required()


        ,
        killswitchDetailsChat:
            
        JoiObj.link('#KillswitchDetailsSchema')


.required()


        ,
        killswitchDetailsDynamicAdvising:
            
        JoiObj.link('#KillswitchDetailsSchema')


.required()


        ,
        killswitchDetailsPensionCheck:
            
        JoiObj.link('#KillswitchDetailsSchema')


.required()


        ,
        killswitchDetailsLetpension:
            
        JoiObj.link('#KillswitchDetailsSchema')


.required()


        ,
        killswitchDetailsLetpensionSk:
            
        JoiObj.link('#KillswitchDetailsSchema')


.required()


        ,
        killswitchDetailsLetpensionBeneficiary:
            
        JoiObj.link('#KillswitchDetailsSchema')


.required()


        ,
        killswitchDetailsActivateYourRecommendation:
            
        JoiObj.link('#KillswitchDetailsSchema')


.required()


        ,
        killswitchDetailsBereaved:
            
        JoiObj.link('#KillswitchDetailsSchema')


.required()


        ,
        killswitchOnlineDoctor:
            
        JoiObj.link('#KillswitchDetailsSchema')


.required()


        ,
        killswitchDigitisation:
            
        JoiObj.link('#KillswitchDetailsSchema')


.required()


        ,
        killswitchOnlinePhystherapist:
            
        JoiObj.link('#KillswitchDetailsSchema')


.required()


        ,
        killswitchOnlinePsychologist:
            
        JoiObj.link('#KillswitchDetailsSchema')


.required()


        ,
        killswitchOnlineCoach:
            
        JoiObj.link('#KillswitchDetailsSchema')


.required()


        ,
        killswitchOnlineFamTherapist:
            
        JoiObj.link('#KillswitchDetailsSchema')


.required()


        ,
        killswitchPensionestimateCallSharedServices:
            
        JoiObj.link('#KillswitchDetailsSchema')


.required()


        ,
        killswitchPensionestimateUseSharedServicesResult:
            
        JoiObj.link('#KillswitchDetailsSchema')


.required()


        ,
        killswitchHealthInsuranceExternal:
            
        JoiObj.link('#KillswitchDetailsSchema')


.required()


        
    })
                .shared(KillswitchDetailsSchema)
                .shared(KillswitchDetailsSchema)
                .shared(KillswitchDetailsSchema)
                .shared(KillswitchDetailsSchema)
                .shared(KillswitchDetailsSchema)
                .shared(KillswitchDetailsSchema)
                .shared(KillswitchDetailsSchema)
                .shared(KillswitchDetailsSchema)
                .shared(KillswitchDetailsSchema)
                .shared(KillswitchDetailsSchema)
                .shared(KillswitchDetailsSchema)
                .shared(KillswitchDetailsSchema)
                .shared(KillswitchDetailsSchema)
                .shared(KillswitchDetailsSchema)
                .shared(KillswitchDetailsSchema)
                .shared(KillswitchDetailsSchema)
                .shared(KillswitchDetailsSchema)
                .shared(KillswitchDetailsSchema)
                .shared(KillswitchDetailsSchema)
                .shared(KillswitchDetailsSchema)
                .shared(KillswitchDetailsSchema)
                .shared(KillswitchDetailsSchema)
                .shared(KillswitchDetailsSchema)
                .shared(KillswitchDetailsSchema)
                .shared(KillswitchDetailsSchema)
.id('KillswitchesAdminResponseSchema')

export interface KillswitchesAdminResponse { 
    killswitchDetailsPensionskunde: KillswitchDetails;
    killswitchDetailsRaadgiver: KillswitchDetails;
    killswitchDetailsPensionsinfo: KillswitchDetails;
    killswitchDetailsPensionsinfoPartner: KillswitchDetails;
    killswitchDetailsEksternOverKunde: KillswitchDetails;
    killswitchDetailsEksternOverRaadgiver: KillswitchDetails;
    killswitchDetailsBeneficiary: KillswitchDetails;
    killswitchDetailsNytegning: KillswitchDetails;
    killswitchDetailsChat: KillswitchDetails;
    killswitchDetailsDynamicAdvising: KillswitchDetails;
    killswitchDetailsPensionCheck: KillswitchDetails;
    killswitchDetailsLetpension: KillswitchDetails;
    killswitchDetailsLetpensionSk: KillswitchDetails;
    killswitchDetailsLetpensionBeneficiary: KillswitchDetails;
    killswitchDetailsActivateYourRecommendation: KillswitchDetails;
    killswitchDetailsBereaved: KillswitchDetails;
    killswitchOnlineDoctor: KillswitchDetails;
    killswitchDigitisation: KillswitchDetails;
    killswitchOnlinePhystherapist: KillswitchDetails;
    killswitchOnlinePsychologist: KillswitchDetails;
    killswitchOnlineCoach: KillswitchDetails;
    killswitchOnlineFamTherapist: KillswitchDetails;
    killswitchPensionestimateCallSharedServices: KillswitchDetails;
    killswitchPensionestimateUseSharedServicesResult: KillswitchDetails;
    killswitchHealthInsuranceExternal: KillswitchDetails;
}

