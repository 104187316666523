/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { EnhedType } from './enhedType';
import { EnhedTypeSchema } from './enhedType';
import { IndbetalingSikring } from './indbetalingSikring';
import { IndbetalingSikringSchema } from './indbetalingSikring';
import * as JoiObj from "joi";


    export const FrivilligIndbetalingSchema = JoiObj.object({
        beloeb:
            
                    JoiObj.number()
                


.required()


        ,
        beloebTotal:
            
                    JoiObj.number()
                


.required()


        ,
        copy:
            
        JoiObj.link('#FrivilligIndbetalingSchema')


.required()


        ,
        enhedType:
            
        JoiObj.link('#EnhedTypeSchema')


.required()


        ,
        indbetalingSikring:
            
        JoiObj.link('#IndbetalingSikringSchema')


.required()


        ,
        kroneBeloeb:
            
                    JoiObj.boolean()
                


.required()


        ,
        loen:
            
                    JoiObj.number()
                


.required()


        ,
        praemiefritagelseOpsparingFrivilligBeloeb:
            
                    JoiObj.number()
                


.required()


        ,
        procent:
            
                    JoiObj.number()
                


.required()


        
    })
                .shared(EnhedTypeSchema)
                .shared(IndbetalingSikringSchema)
.id('FrivilligIndbetalingSchema')

export interface FrivilligIndbetaling { 
    beloeb: number;
    beloebTotal: number;
    copy: FrivilligIndbetaling;
    enhedType: EnhedType;
    indbetalingSikring: IndbetalingSikring;
    kroneBeloeb: boolean;
    loen: number;
    praemiefritagelseOpsparingFrivilligBeloeb: number;
    procent: number;
}
export namespace FrivilligIndbetaling {
}


