import {
  Component,
  HostBinding,
  Input,
  OnDestroy,
  Optional,
  Self,
  ElementRef,
  ViewChild,
  OnInit
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { Subject } from 'rxjs';
import { countries, Country } from './countries';
import { FocusMonitor } from '@angular/cdk/a11y';
import { MatSelectChange, MatSelect } from '@angular/material/select';
import { ContentService } from '@pfa/core';

@Component({
  selector: 'co-country-code-select',
  templateUrl: './country-code-select.component.html',
  styleUrls: ['./country-code-select.component.scss'],
  providers: [
    { provide: MatFormFieldControl, useExisting: CountryCodeSelectComponent }
  ]
})
export class CountryCodeSelectComponent
  implements
    ControlValueAccessor,
    MatFormFieldControl<string>,
    OnInit,
    OnDestroy
{
  static nextId = 0;

  @ViewChild(MatSelect, { static: true }) matSelectRef!: MatSelect;

  @HostBinding()
  id = `pfa-country-code-select-${CountryCodeSelectComponent.nextId++}`;
  @HostBinding('attr.aria-describedby') describedBy = '';

  @Input()
  get placeholder() {
    return this._placeholder;
  }

  set placeholder(plh) {
    this._placeholder = plh;
    this.stateChanges.next();
  }

  get value() {
    return this.selectedCountry.dialCode;
  }

  set value(val: string) {
    if (!val.startsWith('+')) {
      val = `+${val}`;
    }
    this.selectedCountry =
      this.sortedCountries.find(country => country.dialCode === val) || null;
    this.stateChanges.next();
  }

  get empty() {
    return !this.selectedCountry;
  }

  get shouldLabelFloat() {
    return this.selectedCountry !== null;
  }

  stateChanges: Subject<void> = new Subject<void>();
  focused: boolean;
  required: boolean;
  disabled: boolean;
  errorState: boolean;
  controlType = 'pfa-country-code-select';
  customDropdownClass = 'pfa-country-code-select-dropdown';
  autofilled?: boolean;

  sortedCountries = [...countries];
  selectedCountry: Country;
  activeLanguage: string;

  private _placeholder: string;

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private readonly fm: FocusMonitor,
    private readonly cs: ContentService,
    private readonly elRef: ElementRef<HTMLElement>
  ) {
    if (this.ngControl) {
      // Setting the value accessor directly (instead of using
      // the providers) to avoid running into a circular import.
      this.ngControl.valueAccessor = this;

      fm.monitor(elRef.nativeElement, true).subscribe(origin => {
        this.focused = !!origin;
        this.stateChanges.next();
      });
    }
  }

  ngOnInit(): void {
    this.activeLanguage = this.cs.getLanguage();
    this.sortCountries();
    this.cs.languageState.subscribe(lang => {
      this.activeLanguage = lang;
      this.sortCountries();
    });
  }

  setDescribedByIds(ids: string[]): void {
    this.describedBy = ids.join(' ');
  }

  onContainerClick(): void {
    this.matSelectRef.focus();
    this.matSelectRef.open();
  }

  writeValue(val: string): void {
    this.value = val;
  }

  registerOnChange(fn): void {
    this.onChange = fn;
  }

  registerOnTouched(fn): void {
    this.onTouch = fn;
  }

  setDisabledState?(): void {}

  ngOnDestroy(): void {
    this.stateChanges.complete();
    this.fm.stopMonitoring(this.elRef.nativeElement);
  }

  selectCountry(event: MatSelectChange) {
    this.selectedCountry = event.value;
    this.onChange(this.value);
  }

  getLocalizedCountryName(c: Country): string {
    return c[this.activeLanguage] || c.en;
  }

  sortCountries(): void {
    this.sortedCountries.sort((c1: Country, c2: Country) =>
      this.getLocalizedCountryName(c1) > this.getLocalizedCountryName(c2)
        ? 1
        : -1
    );
  }

  onChange: any = () => {};
  onTouch: any = () => {};
}
