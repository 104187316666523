/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Policy } from './policy';
import { PolicySchema } from './policy';
import * as JoiObj from "joi";


    export const PersonDetailsSchema = JoiObj.object({
        adress:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        cpr:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        externalCustomerId:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        globalId:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        personFirstName:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        personLastName:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        policies:
                JoiObj.array().items(
        
        JoiObj.link('#PolicySchema')

        )
        

.required()


        
    })
                .shared(PolicySchema)
.id('PersonDetailsSchema')

export interface PersonDetails { 
    adress: string;
    cpr: string;
    externalCustomerId: string;
    globalId: string;
    personFirstName: string;
    personLastName: string;
    policies: Array<Policy>;
}

