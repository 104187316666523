/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SitecoreUrls } from './sitecoreUrls';
import { SitecoreUrlsSchema } from './sitecoreUrls';
import * as JoiObj from "joi";


    export const UrlForLanguageSchema = JoiObj.object({
        da:
            
        JoiObj.link('#SitecoreUrlsSchema')


.required()


        ,
        en:
            
        JoiObj.link('#SitecoreUrlsSchema')


.required()


        
    })
                .shared(SitecoreUrlsSchema)
                .shared(SitecoreUrlsSchema)
.id('UrlForLanguageSchema')

export interface UrlForLanguage { 
    da: SitecoreUrls;
    en: SitecoreUrls;
}

