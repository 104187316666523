/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const VisningsordenTypeSchema = JoiObj.string().valid(
        'LIVNET_PRIMAER'
        ,
        'LIVNET'
        ,
        'ANDRE'
        
    )
.id('VisningsordenTypeSchema')

export type VisningsordenType = 'LIVNET_PRIMAER' | 'LIVNET' | 'ANDRE';

export const VisningsordenType = {
    LivnetPrimaer: 'LIVNET_PRIMAER' as VisningsordenType,
    Livnet: 'LIVNET' as VisningsordenType,
    Andre: 'ANDRE' as VisningsordenType
};

