/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const ExternalTransferJobChangeSelectionSchema = JoiObj.string().valid(
        'NOT_YET_SELECTED'
        ,
        'WITHIN_JOB_CHANGE'
        ,
        'WITHOUT_JOB_CHANGE'
        
    )
.id('ExternalTransferJobChangeSelectionSchema')

export type ExternalTransferJobChangeSelection = 'NOT_YET_SELECTED' | 'WITHIN_JOB_CHANGE' | 'WITHOUT_JOB_CHANGE';

export const ExternalTransferJobChangeSelection = {
    NotYetSelected: 'NOT_YET_SELECTED' as ExternalTransferJobChangeSelection,
    WithinJobChange: 'WITHIN_JOB_CHANGE' as ExternalTransferJobChangeSelection,
    WithoutJobChange: 'WITHOUT_JOB_CHANGE' as ExternalTransferJobChangeSelection
};

