<div class="text--container spacing-unit--double-under">
  <div class="header text--manchet">
    <div [coContent]="'DL.VK01.C24'"></div>
  </div>
</div>

<co-card-animated-stack [activeIndex]="0">
  <co-card-animated-stack-item class="small">
    <co-card [allowOverflow]="true">
      <mitpfa-svg-information class="image--mobile"></mitpfa-svg-information>
      <div class="wrapper">
        <div>
          <div class="container">
            <div
              class="text--color-primary text--bold"
              [coContent]="'DL.VK01.C25'"
            ></div>
            <div class="number--icon">1</div>
          </div>
          <div class="body-text" [coContent]="'DL.VK01.C26'"></div>
          <button
            mat-raised-button
            class="mat-focus-indicator mat-button-bas move-on"
            (click)="next()"
            [coContent]="'DL.VK01.C27'"
          ></button>
        </div>
        <div class="container--desktop">
          <mitpfa-svg-information
            class="image--desktop"
          ></mitpfa-svg-information>
        </div>
      </div>
    </co-card>
  </co-card-animated-stack-item>
  <co-card-animated-stack-item class="small">
    <co-card></co-card>
  </co-card-animated-stack-item>
  <co-card-animated-stack-item class="small">
    <co-card></co-card>
  </co-card-animated-stack-item>
  <co-card-animated-stack-item class="small">
    <co-card></co-card>
  </co-card-animated-stack-item>
</co-card-animated-stack>
