/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const AlternativePolicyChoiceSchema = JoiObj.string().valid(
        'SavingsOnly'
        ,
        'PartialHealth'
        ,
        'CancelOffer'
        
    )
.id('AlternativePolicyChoiceSchema')

export type AlternativePolicyChoice = 'SavingsOnly' | 'PartialHealth' | 'CancelOffer';

export const AlternativePolicyChoice = {
    SavingsOnly: 'SavingsOnly' as AlternativePolicyChoice,
    PartialHealth: 'PartialHealth' as AlternativePolicyChoice,
    CancelOffer: 'CancelOffer' as AlternativePolicyChoice
};

