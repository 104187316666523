<svg
  id="Layer_1"
  data-name="Layer 1"
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 32 32"
>
  <g id="Canvas">
    <circle
      id="Large_circle"
      data-name="Large circle"
      cx="16"
      cy="16"
      r="16"
      fill="none"
    />
    <circle
      id="Small_circle"
      data-name="Small circle"
      cx="16"
      cy="16"
      r="12"
      fill="none"
    />
  </g>
  <g id="Icon">
    <line
      id="Bottom_line"
      data-name="Bottom line"
      x1="13.5"
      y1="18.8"
      x2="16.5"
      y2="18.8"
      stroke-width="1.2"
      stroke="#000"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
    />
    <line
      id="Top_line"
      data-name="Top line"
      x1="13.5"
      y1="17.2"
      x2="18.5"
      y2="17.2"
      stroke-width="1.2"
      stroke="#000"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
    />
    <rect
      id="Text_field"
      data-name="Text field"
      x="12"
      y="15.5"
      width="8"
      height="5"
      fill="none"
      stroke="#000"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.2"
    />
    <line
      id="Dash"
      x1="17"
      y1="10.5"
      x2="17"
      y2="11.5"
      stroke-width="1.2"
      stroke="#000"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
    />
    <rect
      id="Rectangle"
      x="13"
      y="9"
      width="6"
      height="4"
      stroke-width="1.2"
      stroke="#000"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
    />
    <path
      id="Right_body-part"
      data-name="Right body-part"
      d="M21,9l2,2V22a1,1,0,0,1-1,1H10a1,1,0,0,1-1-1"
      fill="none"
      stroke="#000"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.2"
    />
    <path
      id="Left_body-part"
      data-name="Left body-part"
      d="M9,20V10a1,1,0,0,1,1-1h9"
      fill="none"
      stroke="#000"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.2"
    />
  </g>
</svg>
