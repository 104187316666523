/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PensionsInfoAllowedRanges } from './pensionsInfoAllowedRanges';
import { PensionsInfoAllowedRangesSchema } from './pensionsInfoAllowedRanges';
import { InForceSelection } from './inForceSelection';
import { InForceSelectionSchema } from './inForceSelection';
import { RetirementAge } from './retirementAge';
import { RetirementAgeSchema } from './retirementAge';
import { AarsagTilBrug } from './aarsagTilBrug';
import { AarsagTilBrugSchema } from './aarsagTilBrug';
import { SpecialCustomerRules } from './specialCustomerRules';
import { SpecialCustomerRulesSchema } from './specialCustomerRules';
import { ServiceInfo } from './serviceInfo';
import { ServiceInfoSchema } from './serviceInfo';
import { SprogType } from './sprogType';
import { SprogTypeSchema } from './sprogType';
import { LivnetAllowedRanges } from './livnetAllowedRanges';
import { LivnetAllowedRangesSchema } from './livnetAllowedRanges';
import { LoginMethod } from './loginMethod';
import { LoginMethodSchema } from './loginMethod';
import { ConsentInfo } from './consentInfo';
import { ConsentInfoSchema } from './consentInfo';
import { Civilstand } from './civilstand';
import { CivilstandSchema } from './civilstand';
import { NavnOgStatusData } from './navnOgStatusData';
import { NavnOgStatusDataSchema } from './navnOgStatusData';
import { ServiceProgram } from './serviceProgram';
import { ServiceProgramSchema } from './serviceProgram';
import * as JoiObj from "joi";


    export const PensionsKundeGenerelleDataSchema = JoiObj.object({
        serviceInfo:
            
        JoiObj.link('#ServiceInfoSchema')

.allow(null)



        ,
        isAdministrator:
            
                    JoiObj.boolean()
                


.required()


        ,
        isMaegler:
            
                    JoiObj.boolean()
                


.required()


        ,
        isPfaUser:
            
                    JoiObj.boolean()
                


.required()


        ,
        isPrivat:
            
                    JoiObj.boolean()
                


.required()


        ,
        isRaadgiver:
            
                    JoiObj.boolean()
                


.required()


        ,
        isSkadesanmeldelseAdmin:
            
                    JoiObj.boolean()
                


.required()


        ,
        isSomPrivat:
            
                    JoiObj.boolean()
                


.required()


        ,
        isVirksomhedMedarbejde:
            
                    JoiObj.boolean()
                


.required()


        ,
        isPensionskundeIkkeValgt:
            
                    JoiObj.boolean()
                


.required()


        ,
        messagingOpen:
            
                    JoiObj.boolean()
                


.required()


        ,
        navn:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        pensionskundeIkkeValgt:
            
                    JoiObj.boolean()
                


.required()


        ,
        privateBusinessLogin:
            
                    JoiObj.boolean()
                


.required()


        ,
        sidsteBesoeg:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        specialMessagingRules:
            
                    JoiObj.boolean()
                


.required()


        ,
        aarsagTilBrug:
            
        JoiObj.link('#AarsagTilBrugSchema')

.allow(null)



        ,
        alder:
            
                    JoiObj.number()
                


.required()


        ,
        beneficiaryClosed:
            
                    JoiObj.boolean()
                


.required()


        ,
        civilstand:
            
        JoiObj.link('#CivilstandSchema')


.required()


        ,
        consentDataIkkeTilgaengeligePgaSystemFejl:
            
                    JoiObj.boolean()
                


.required()


        ,
        consents:
                JoiObj.array().items(
        
        JoiObj.link('#ConsentInfoSchema')

        )
        
.allow(null)



        ,
        cpr:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        customerEmailValid:
            
                    JoiObj.boolean()
                


.required()


        ,
        customerMobilePhoneValid:
            
                    JoiObj.boolean()
                


.required()


        ,
        efternavn:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        egenIndtastningerDataIkkeTilgaengeligePgaSystemFejl:
            
                    JoiObj.boolean()
                


.required()


        ,
        eksternoverfoerselLukketForKunde:
            
                    JoiObj.boolean()
                


.required()


        ,
        fk:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        folkepension:
            
                    JoiObj.boolean()
                


.required()


        ,
        formateretAdresse:
                JoiObj.array().items(
        
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

        )
        

.required()


        ,
        formue:
            
                    JoiObj.number()
                

.allow(null)



        ,
        fornavn:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        forsoergerpligt:
            
                    JoiObj.boolean()
                


.required()


        ,
        forventetFormue:
            
                    JoiObj.number()
                

.allow(null)



        ,
        forventetPensionsalder:
            
                    JoiObj.number()
                

.allow(null)



        ,
        gaeld:
            
                    JoiObj.number()
                

.allow(null)



        ,
        globalId:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        harInkonsistentFoedtDatoForPartner:
            
                    JoiObj.boolean()
                


.required()


        ,
        harNavnAdresseBeskyttelse:
            
                    JoiObj.boolean()
                


.required()


        ,
        harPensionsinfo:
            
                    JoiObj.boolean()
                


.required()


        ,
        harPensionsinfoPartner:
            
                    JoiObj.boolean()
                


.required()


        ,
        harReklameBeskyttelse:
            
                    JoiObj.boolean()
                


.required()


        ,
        harUdenlandskAdresse:
            
                    JoiObj.boolean()
                


.required()


        ,
        inForceSelections:
                JoiObj.array().items(
        
        JoiObj.link('#InForceSelectionSchema')

        )
        

.required()


        ,
        koen:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        kundeEmail:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        landekode:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        letpensionBeneficiaryClosed:
            
                    JoiObj.boolean()
                


.required()


        ,
        livnetAllowedRanges:
                JoiObj.array().items(
        
        JoiObj.link('#LivnetAllowedRangesSchema')

        )
        
.allow(null)



        ,
        pensionsInfoAllowedRanges:
                JoiObj.array().items(
        
        JoiObj.link('#PensionsInfoAllowedRangesSchema')

        )
        
.allow(null)



        ,
        livnetPolicyCount:
            
                    JoiObj.number()
                


.required()


        ,
        livnetUnderUdbetaling:
            
                    JoiObj.boolean()
                


.required()


        ,
        loginMethod:
            
        JoiObj.link('#LoginMethodSchema')


.required()


        ,
        mobil:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        onboardingEnabled:
            
                    JoiObj.boolean()
                


.required()


        ,
        partnerAarligIndkomst:
            
                    JoiObj.number()
                

.allow(null)



        ,
        pensionInfoCustomerAnswerMissing:
            
                    JoiObj.boolean()
                


.required()


        ,
        pensionInfoDataInvalid:
            
                    JoiObj.boolean()
                


.required()


        ,
        pensionInfoExcludedPolicies:
            
                    JoiObj.boolean()
                


.required()


        ,
        pensionsinfoDato:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        pensionsinfoForPartnerDataHentningLukket:
            
                    JoiObj.boolean()
                


.required()


        ,
        pensionsinfoIncomplete:
            
                    JoiObj.boolean()
                


.required()


        ,
        pensionsinfoLukket:
            
                    JoiObj.boolean()
                


.required()


        ,
        pensionsinfoPartnerDato:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        pensionsinfoUpdated:
            
                    JoiObj.boolean()
                


.required()


        ,
        invalidPensionReport:
            
                    JoiObj.boolean()
                

.allow(null)



        ,
        invalidPensionReportVersion:
            
                    JoiObj.boolean()
                

.allow(null)



        ,
        pfaMarketingSamtykkeGivet:
            
                    JoiObj.boolean()
                

.allow(null)



        ,
        postNr:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        privateDepositAllowed:
            
                    JoiObj.boolean()
                


.required()


        ,
        raadgiverBrugerId:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        raadgiverNavn:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        raadgiverRekvirentNummer:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        retirementAge:
            
        JoiObj.link('#RetirementAgeSchema')


.required()


        ,
        samlevende:
            
                    JoiObj.boolean()
                


.required()


        ,
        serviceProgram:
            
        JoiObj.link('#ServiceProgramSchema')

.allow(null)



        ,
        specialCustomerRules:
            
        JoiObj.link('#SpecialCustomerRulesSchema')


.required()


        ,
        sprogValg:
            
        JoiObj.link('#SprogTypeSchema')


.required()


        ,
        vejnavn:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        surveyUuid:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        
    })
                .shared(ServiceInfoSchema)
                .shared(AarsagTilBrugSchema)
                .shared(CivilstandSchema)
                .shared(ConsentInfoSchema)
                .shared(InForceSelectionSchema)
                .shared(LivnetAllowedRangesSchema)
                .shared(PensionsInfoAllowedRangesSchema)
                .shared(LoginMethodSchema)
                .shared(RetirementAgeSchema)
                .shared(ServiceProgramSchema)
                .shared(SpecialCustomerRulesSchema)
                .shared(SprogTypeSchema)
.id('PensionsKundeGenerelleDataSchema')

export interface PensionsKundeGenerelleData extends NavnOgStatusData { 
    aarsagTilBrug?: AarsagTilBrug;
    alder: number;
    beneficiaryClosed: boolean;
    civilstand: Civilstand;
    consentDataIkkeTilgaengeligePgaSystemFejl: boolean;
    consents?: Array<ConsentInfo>;
    cpr: string;
    customerEmailValid: boolean;
    customerMobilePhoneValid: boolean;
    efternavn?: string;
    egenIndtastningerDataIkkeTilgaengeligePgaSystemFejl: boolean;
    eksternoverfoerselLukketForKunde: boolean;
    fk?: string;
    folkepension: boolean;
    formateretAdresse: Array<string>;
    formue?: number;
    fornavn?: string;
    forsoergerpligt: boolean;
    forventetFormue?: number;
    forventetPensionsalder?: number;
    gaeld?: number;
    globalId: string;
    harInkonsistentFoedtDatoForPartner: boolean;
    harNavnAdresseBeskyttelse: boolean;
    harPensionsinfo: boolean;
    harPensionsinfoPartner: boolean;
    harReklameBeskyttelse: boolean;
    harUdenlandskAdresse: boolean;
    inForceSelections: Array<InForceSelection>;
    koen?: string;
    kundeEmail?: string;
    landekode: string;
    letpensionBeneficiaryClosed: boolean;
    livnetAllowedRanges?: Array<LivnetAllowedRanges>;
    pensionsInfoAllowedRanges?: Array<PensionsInfoAllowedRanges>;
    livnetPolicyCount: number;
    livnetUnderUdbetaling: boolean;
    loginMethod: LoginMethod;
    mobil?: string;
    onboardingEnabled: boolean;
    partnerAarligIndkomst?: number;
    pensionInfoCustomerAnswerMissing: boolean;
    pensionInfoDataInvalid: boolean;
    pensionInfoExcludedPolicies: boolean;
    pensionsinfoDato?: string;
    pensionsinfoForPartnerDataHentningLukket: boolean;
    pensionsinfoIncomplete: boolean;
    pensionsinfoLukket: boolean;
    pensionsinfoPartnerDato?: string;
    pensionsinfoUpdated: boolean;
    invalidPensionReport?: boolean;
    invalidPensionReportVersion?: boolean;
    pfaMarketingSamtykkeGivet?: boolean;
    postNr?: string;
    privateDepositAllowed: boolean;
    raadgiverBrugerId?: string;
    raadgiverNavn?: string;
    raadgiverRekvirentNummer?: string;
    retirementAge: RetirementAge;
    samlevende: boolean;
    serviceProgram?: ServiceProgram;
    specialCustomerRules: SpecialCustomerRules;
    sprogValg: SprogType;
    vejnavn?: string;
    surveyUuid: string;
}
export namespace PensionsKundeGenerelleData {
}


