import { Injectable } from '@angular/core';
import { TrackingService } from './tracking.service';

@Injectable()
export class AccordionTrackingService extends TrackingService {
  trackAccordion(
    accordionName: string,
    accordionInitator: string = 'self'
  ): void {
    this.dispatchCustomEventTracking('track-accordion', {
      accordionName,
      accordionAction: 'opened',
      accordionInitator
    });
  }
}
