/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ServiceInfo } from './serviceInfo';
import { ServiceInfoSchema } from './serviceInfo';
import { ResourceModel } from './resourceModel';
import { ResourceModelSchema } from './resourceModel';
import { UdbetalingsformType } from './udbetalingsformType';
import { UdbetalingsformTypeSchema } from './udbetalingsformType';
import { UdbetalingsartType } from './udbetalingsartType';
import { UdbetalingsartTypeSchema } from './udbetalingsartType';
import * as JoiObj from "joi";


    export const UdbetalingSchema = JoiObj.object({
        serviceInfo:
            
        JoiObj.link('#ServiceInfoSchema')

.allow(null)



        ,
        art:
            
        JoiObj.link('#UdbetalingsartTypeSchema')


.required()


        ,
        bruttoBeloeb:
            
                    JoiObj.number()
                


.required()


        ,
        dato:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        form:
            
        JoiObj.link('#UdbetalingsformTypeSchema')


.required()


        ,
        fraDato:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        tilDato:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        
    })
                .shared(ServiceInfoSchema)
                .shared(UdbetalingsartTypeSchema)
                .shared(UdbetalingsformTypeSchema)
.id('UdbetalingSchema')

export interface Udbetaling extends ResourceModel { 
    art: UdbetalingsartType;
    bruttoBeloeb: number;
    dato: string;
    form: UdbetalingsformType;
    fraDato: string;
    tilDato: string;
}
export namespace Udbetaling {
}


