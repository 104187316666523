/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Indbetaling } from './indbetaling';
import { IndbetalingSchema } from './indbetaling';
import { ServiceInfo } from './serviceInfo';
import { ServiceInfoSchema } from './serviceInfo';
import { ResourceModel } from './resourceModel';
import { ResourceModelSchema } from './resourceModel';
import { DepositsByMonth } from './depositsByMonth';
import { DepositsByMonthSchema } from './depositsByMonth';
import * as JoiObj from "joi";


    export const IndbetalingPoliceSchema = JoiObj.object({
        serviceInfo:
            
        JoiObj.link('#ServiceInfoSchema')

.allow(null)



        ,
        betaltForEksternDaekning:
            
                    JoiObj.number()
                


.required()


        ,
        depositsByMonths:
                JoiObj.array().items(
        
        JoiObj.link('#DepositsByMonthSchema')

        )
        

.required()


        ,
        fraDato:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        indbetalinger:
                JoiObj.array().items(
        
        JoiObj.link('#IndbetalingSchema')

        )
        

.required()


        ,
        indbetalingerPrMaaned:
                JoiObj.array().items(
        
                    JoiObj.number()
                

        )
        

.required()


        ,
        policeId:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        tilDato:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        totalIndbetalingForAar:
            
                    JoiObj.number()
                


.required()


        
    })
                .shared(ServiceInfoSchema)
                .shared(DepositsByMonthSchema)
                .shared(IndbetalingSchema)
.id('IndbetalingPoliceSchema')

export interface IndbetalingPolice extends ResourceModel { 
    betaltForEksternDaekning: number;
    depositsByMonths: Array<DepositsByMonth>;
    fraDato: string;
    indbetalinger: Array<Indbetaling>;
    indbetalingerPrMaaned: Array<number>;
    policeId: string;
    tilDato: string;
    totalIndbetalingForAar: number;
}

