/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const InvesteringKildeSchema = JoiObj.string().valid(
        'ArbejdsgiverObligatorisk'
        ,
        'MedarbejderObligatorisk'
        ,
        'MedarbejderFrivillig'
        ,
        'Blandet'
        
    )
.id('InvesteringKildeSchema')

export type InvesteringKilde = 'ArbejdsgiverObligatorisk' | 'MedarbejderObligatorisk' | 'MedarbejderFrivillig' | 'Blandet';

export const InvesteringKilde = {
    ArbejdsgiverObligatorisk: 'ArbejdsgiverObligatorisk' as InvesteringKilde,
    MedarbejderObligatorisk: 'MedarbejderObligatorisk' as InvesteringKilde,
    MedarbejderFrivillig: 'MedarbejderFrivillig' as InvesteringKilde,
    Blandet: 'Blandet' as InvesteringKilde
};

