<div
  class="header text--manchet"
  [coContent]="welcomeflow ? 'DL.VK01.C46' : 'DL.VK01.C120'"
></div>
<div class="wrapper">
  <div class="container">
    <div class="image"></div>
    <p coContent="DL.VK01.C47" class="text body"></p>
    <p coContent="DL.VK01.C48" class="text spacing-unit--under"></p>
    <button
      (click)="goToNextPage()"
      mat-raised-button
      class="mat-focus-indicator button-secondary"
      coContent="DL.VK01.C49"
      data-test-id="combine-savings-next"
    ></button>
  </div>
</div>
