import { Component, Input } from '@angular/core';
import { ViderefoerelseSammenligningRaekke } from '@pfa/gen';

@Component({
  selector: 'co-daekning-after',
  templateUrl: './daekning-after.component.html',
  styleUrls: ['../../new-business-offer-overview.component.scss']
})
export class DaekningAfterComponent {
  @Input() daekning: ViderefoerelseSammenligningRaekke;
}
