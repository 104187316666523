export class InsuranceListItem {
  link: string;
  headerContent: string;
  headerNoteContent: string;
  navigationAction: string;
  testId: string;

  constructor(
    headerContent: string,
    headerNoteContent: string,
    navigationAction: string,
    testId: string,
    link: string = '/mineforsikringer'
  ) {
    this.headerContent = headerContent;
    this.headerNoteContent = headerNoteContent;
    this.navigationAction = navigationAction;
    this.testId = testId;
    this.link = link;
  }
}
