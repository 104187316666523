import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { CoreModule } from '@pfa/core';
import { IconModule } from '@pfa/icons';
import { InvestmentGuideBackButtonComponent } from './investment-guide-back-button.component';

@NgModule({
  declarations: [InvestmentGuideBackButtonComponent],
  imports: [IconModule, CoreModule, MatButtonModule],
  exports: [InvestmentGuideBackButtonComponent]
})
export class InvestmentGuideBackButtonModule {}
