import { Component, OnInit } from '@angular/core';
import { first, switchMap } from 'rxjs/operators';
import { PensionsKundeGenerelleData } from '@pfa/gen';
import { ActivatedRoute } from '@angular/router';
import { LoginRedirectService } from '@pfa/handler';
import { Observable } from 'rxjs';
import { LogoutMitpfaService } from '@mitpfa/shared';
import { LogoutService } from '@pfa/services';

@Component({
  selector: 'mitpfa-logout',
  template: ` <co-loading [show]="true" class="absolute-center"></co-loading> `
})
export class LogoutComponent implements OnInit {
  readyToLogout$: Observable<boolean>;
  private pensionCustomer: PensionsKundeGenerelleData;

  constructor(
    private readonly logoutMitpfaService: LogoutMitpfaService,
    private readonly logoutService: LogoutService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly loginRedirectService: LoginRedirectService
  ) {}

  ngOnInit() {
    this.readyToLogout$ = this.activatedRoute.data.pipe(
      first(),
      switchMap((data: { pensionCustomer: PensionsKundeGenerelleData }) => {
        this.pensionCustomer = data.pensionCustomer;
        this.loginRedirectService.loggedInSuccessRoute = {
          url: '',
          params: {}
        };
        return this.logoutMitpfaService.readyToLogout(
          this.pensionCustomer.isRaadgiver,
          this.pensionCustomer.surveyUuid
        );
      })
    );

    this.readyToLogout$.subscribe((readyToLogout: boolean) => {
      if (readyToLogout) {
        this.logoutService.logout().subscribe(() => {
          window.location.href = '/';
        });
      }
    });
  }
}
