/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Sats } from './sats';
import { SatsSchema } from './sats';
import * as JoiObj from "joi";


    export const SinglePaymentSchema = JoiObj.object({
        activeNow:
            
                    JoiObj.boolean()
                


.required()


        ,
        name:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        updatedAt:
            
            JoiObj.date()


.required()


        ,
        updatedBy:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        uuid:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        validFrom:
            
            JoiObj.date()

.allow(null)



        ,
        validFromFormatted:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        year:
            
                    JoiObj.number()
                


.required()


        ,
        minAmount:
            
                    JoiObj.number()
                


.required()


        
    })
.id('SinglePaymentSchema')

export interface SinglePayment extends Sats { 
    minAmount: number;
}

