<!-- v4 -->
<svg
  id="Layer_1"
  data-name="Layer 1"
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 32 32"
>
  <g id="Canvas">
    <circle
      id="Large_circle"
      data-name="Large circle"
      cx="16"
      cy="16"
      r="16"
      fill="none"
    />
    <circle
      id="Small_circle"
      data-name="Small circle"
      cx="16"
      cy="16"
      r="12"
      fill="none"
    />
  </g>
  <g id="Icon">
    <path
      id="Wrench"
      d="M19.5,15v2l-1,.5-1-.5V15a2.39,2.39,0,0,0-1.39,1.54,2.52,2.52,0,0,0,1,2.8,1,1,0,0,1,.41.79V23h2V20.15a1,1,0,0,1,.42-.81,2.48,2.48,0,0,0,.63-3.46A2.42,2.42,0,0,0,19.5,15Z"
      fill="none"
      stroke="#000"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.2"
    />
    <path
      id="Screwdriver_handle"
      data-name="Screwdriver handle"
      d="M13,21h0a1,1,0,0,1,1,1v1H12V22A1,1,0,0,1,13,21Z"
      fill="none"
      stroke="#000"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.2"
    />
    <line
      id="Screwdriver_bar"
      data-name="Screwdriver bar"
      x1="11.49"
      y1="21"
      x2="14.49"
      y2="21"
      stroke-width="1.2"
      stroke="#000"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
    />
    <line
      id="Screwdriver_arm"
      data-name="Screwdriver arm"
      x1="13"
      y1="17"
      x2="13"
      y2="21"
      stroke-width="1.2"
      stroke="#000"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
    />
    <path
      id="Screwdriver_head"
      data-name="Screwdriver head"
      d="M12,15h2v1a1,1,0,0,1-1,1h0a1,1,0,0,1-1-1V15Z"
      fill="none"
      stroke="#000"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.2"
    />
    <line
      id="Frame_line"
      data-name="Frame line"
      x1="9"
      y1="13"
      x2="23"
      y2="13"
      stroke-width="1.2"
      stroke="#000"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
    />
    <line
      id="Right_dot"
      data-name="Right dot"
      x1="20.99"
      y1="11"
      x2="21.01"
      y2="11"
      stroke-width="1.2"
      stroke="#000"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
    />
    <line
      id="Left_dot"
      data-name="Left dot"
      x1="18.99"
      y1="11"
      x2="19"
      y2="11"
      stroke-width="1.2"
      stroke="#000"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
    />
    <polyline
      id="Frame"
      points="23 15 23 15 23 23 9 23 9 9 23 9 23 13"
      fill="none"
      stroke="#000"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.2"
    />
  </g>
</svg>
