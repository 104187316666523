import { Injectable } from '@angular/core';
import { IndbetalingOversigt, IndbetalingStore } from '@pfa/gen';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PaymentApiService {
  constructor(private indbetalingServiceStore: IndbetalingStore) {}

  getPaymentOverview(): Observable<IndbetalingOversigt> {
    return this.indbetalingServiceStore.indbetalingGet();
  }
}
