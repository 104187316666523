/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AcceptanceStatus } from './acceptanceStatus';
import { AcceptanceStatusSchema } from './acceptanceStatus';
import * as JoiObj from "joi";


    export const EvaluationResultSchema = JoiObj.object({
        acceptanceStatus:
            
        JoiObj.link('#AcceptanceStatusSchema')


.required()


        ,
        consentRequired:
            
                    JoiObj.boolean()
                


.required()


        ,
        locale:
            
                    JoiObj.object()
                


.required()


        ,
        timeout:
            
                    JoiObj.boolean()
                


.required()


        ,
        cancelled:
            
                    JoiObj.boolean()
                


.required()


        
    })
                .shared(AcceptanceStatusSchema)
.id('EvaluationResultSchema')

export interface EvaluationResult { 
    acceptanceStatus: AcceptanceStatus;
    consentRequired: boolean;
    locale: object;
    timeout: boolean;
    cancelled: boolean;
}
export namespace EvaluationResult {
}


