import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BeneficiaryExistingResponse, BeneficiaryView } from '@pfa/gen';
import { AllowedAccess } from '@pfa/models';
import { InheritanceandbeneficiaryService } from '../../services/inheritanceandbeneficiary.service';

@Component({
  selector: 'co-beneficiary-nav-content',
  templateUrl: './beneficiary-nav-content.component.html',
  styleUrls: ['./beneficiary-nav-content.component.scss']
})
export class BeneficiaryNavContentComponent {
  @Input() allowedAccess: AllowedAccess;
  @Input() beneficiaryData: BeneficiaryExistingResponse;
  @Input() fallbackNumber?: string;
  @Input() isLetpension?: boolean;
  @Output() goTo = new EventEmitter();

  constructor(
    private readonly inheritanceandbeneficiaryService: InheritanceandbeneficiaryService
  ) {}

  getBeneficiaryTypeText(benficiary: BeneficiaryView): string {
    return this.inheritanceandbeneficiaryService.getBeneficiaryTypeText(
      benficiary
    );
  }

  gotoBeneficiary(): void {
    this.goTo.emit();
  }
}
