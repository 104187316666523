<div class="info-box-header text--manchet">
  <div [coContent]="'DL.PI01.C140'" class="color--primary text--bold"></div>
  <div
    [coContent]="'DL.PI01.C141'"
    class="spacing-unit--double-under spacing-unit--over"
  ></div>
</div>
<div class="header-bottom">
  <button
    class="button-secondary"
    mat-raised-button
    [coContent]="'DL.PI01.C146'"
    (click)="saveIncludeExternalPolicies(true)"
  ></button>
  <button
    mat-raised-button
    class="mat-focus-indicator mat-button-bas"
    [coContent]="'DL.PI01.C145'"
    (click)="saveIncludeExternalPolicies(false)"
  ></button>
</div>

<co-warning *ngIf="saveError" class="warning">
  <span [coContent]="'DL.PI01.C153'"></span>
</co-warning>
