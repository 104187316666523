import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MaeglerInfo } from '@pfa/gen';
import { InvestmentGuideInfo } from '@pfa/models';
import { LossAbilityChoice } from '../../investment-guide-risk-step.model';
import { InvestmentGuideService } from '@mitpfa/shared';

@Component({
  selector: 'mitpfa-investment-guide-lossability',
  templateUrl: './investment-guide-risk-step-lossability.component.html',
  styleUrls: ['./investment-guide-risk-step-lossability.component.scss']
})
export class InvestmentGuideLossAbilityComponent implements OnInit {
  @Input() investmentGuide: InvestmentGuideInfo;
  @Input() broker: MaeglerInfo;

  answer: UntypedFormControl;
  choices: LossAbilityChoice[] = [
    {
      text: 'DL.INVGU01.C626',
      value: 'AVOID_LOSS'
    },
    {
      text: 'DL.INVGU01.C627',
      value: 'MINIMAL_LOSS'
    },
    {
      text: 'DL.INVGU01.C628',
      value: 'ACCEPT_LOSS'
    }
  ];

  constructor(
    private readonly investmentGuideService: InvestmentGuideService
  ) {}

  ngOnInit(): void {
    this.answer = new UntypedFormControl(
      this.investmentGuide.recommendationBasis.lossAbility,
      Validators.required
    );
  }

  choose(): void {
    this.investmentGuide.recommendationBasis.lossAbility = this.answer.value;
    this.investmentGuideService.updateCurrentQuestion({
      value: 'CLIMATE_RELEVANCE',
      update: true
    });
  }
}
