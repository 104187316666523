/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const KursAendringSchema = JoiObj.string().valid(
        'Faldet'
        ,
        'Uaendret'
        ,
        'Ukendt'
        ,
        'Steget'
        
    )
.id('KursAendringSchema')

export type KursAendring = 'Faldet' | 'Uaendret' | 'Ukendt' | 'Steget';

export const KursAendring = {
    Faldet: 'Faldet' as KursAendring,
    Uaendret: 'Uaendret' as KursAendring,
    Ukendt: 'Ukendt' as KursAendring,
    Steget: 'Steget' as KursAendring
};

