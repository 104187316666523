/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Skattekode } from './skattekode';
import { SkattekodeSchema } from './skattekode';
import { EnhedType } from './enhedType';
import { EnhedTypeSchema } from './enhedType';
import { ValueInterval } from './valueInterval';
import { ValueIntervalSchema } from './valueInterval';
import * as JoiObj from "joi";


    export const DaekningAendringSchema = JoiObj.object({
        bruttobeloeb:
            
                    JoiObj.number()
                


.required()


        ,
        daekningsBeloeb:
            
                    JoiObj.number()
                


.required()


        ,
        daekningsIds:
                JoiObj.array().items(
        
                    JoiObj.number()
                

        )
        

.required()


        ,
        daekningsspaend:
            
        JoiObj.link('#ValueIntervalSchema')

.allow(null)



        ,
        daekningsspaendBeloeb:
            
        JoiObj.link('#ValueIntervalSchema')

.allow(null)



        ,
        disableEditing:
            
                    JoiObj.boolean()
                


.required()


        ,
        enhedType:
            
        JoiObj.link('#EnhedTypeSchema')

.allow(null)



        ,
        externalCoverageAmount:
            
                    JoiObj.number()
                


.required()


        ,
        muligeSkattekoder:
                JoiObj.array().items(
        
        JoiObj.link('#SkattekodeSchema')

        )
        

.required()


        ,
        pris:
            
                    JoiObj.number()
                


.required()


        ,
        skalVises:
            
                    JoiObj.boolean()
                


.required()


        ,
        tillaegsDaekningBeloeb:
            
                    JoiObj.number()
                


.required()


        ,
        valgtSkattekode:
            
        JoiObj.link('#SkattekodeSchema')

.allow(null)



        ,
        visTvungenSkattekodeText:
            
                    JoiObj.boolean()
                


.required()


        
    })
                .shared(ValueIntervalSchema)
                .shared(ValueIntervalSchema)
                .shared(EnhedTypeSchema)
                .shared(SkattekodeSchema)
                .shared(SkattekodeSchema)
.id('DaekningAendringSchema')

export interface DaekningAendring { 
    bruttobeloeb: number;
    daekningsBeloeb: number;
    daekningsIds: Array<number>;
    daekningsspaend?: ValueInterval;
    daekningsspaendBeloeb?: ValueInterval;
    disableEditing: boolean;
    enhedType?: EnhedType;
    externalCoverageAmount: number;
    muligeSkattekoder: Array<Skattekode>;
    pris: number;
    skalVises: boolean;
    tillaegsDaekningBeloeb: number;
    valgtSkattekode?: Skattekode;
    visTvungenSkattekodeText: boolean;
}
export namespace DaekningAendring {
}


