import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  OekonomiDetaljer,
  PensionsKundeGenerelleData,
  StamdataDetaljer
} from '@pfa/gen';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Utils } from '@pfa/utils';

@Component({
  selector: 'mitpfa-insurance-guide-intro',
  templateUrl: './insurance-guide-intro.component.html',
  styleUrls: ['./insurance-guide-intro.component.scss']
})
export class InsuranceGuideIntroComponent implements OnInit {
  @Output() startGuide = new EventEmitter<boolean>();
  @Input() pensionCustomer: PensionsKundeGenerelleData;
  @Input() familyInformation: StamdataDetaljer;
  @Input() financialSituation: OekonomiDetaljer;
  @Input() shortFlowRunning: boolean;

  advisorShortFlowForm: UntypedFormGroup;

  isAdvisor: boolean;
  dataValidForShortFlow: boolean;

  ngOnInit() {
    this.isAdvisor = this.pensionCustomer.isRaadgiver;
    this.dataValidForShortFlow = this.validateDataForShortFlow();

    this.advisorShortFlowForm = new UntypedFormGroup({
      isShortFlow: new UntypedFormControl({
        value: this.shortFlowRunning ? this.shortFlowRunning : false,
        disabled: !this.dataValidForShortFlow
      })
    });
  }

  validateDataForShortFlow(): boolean {
    const validFamilyData: boolean = this.validateFamilyData(
      this.familyInformation
    );
    const validEconomyData: boolean = this.validateEconomyData(
      this.financialSituation
    );

    return validEconomyData && validFamilyData;
  }

  validateEconomyData(financialSituation: OekonomiDetaljer) {
    let validData = true;
    //home
    if (Utils.isNullOrUndefined(financialSituation.frieMidler.boligValgt)) {
      //no answer about home
      validData = false;
    } else if (financialSituation.frieMidler.boligValgt) {
      for (const property of financialSituation.frieMidler.boliger) {
        if (!property.ejendomNavn) {
          validData = false;
        }
      }
    }

    //secrities or cash
    if (Utils.isNullOrUndefined(financialSituation.frieMidler.opsparingIBank)) {
      validData = false;
    }
    //debt
    if (Utils.isNullOrUndefined(financialSituation.frieMidler.gaeldIBankIdag)) {
      validData = false;
    }
    return validData;
  }

  validateFamilyData(familyInformation: StamdataDetaljer) {
    const validPartnerData = this.validatePartnerData(familyInformation);
    const validChildrenData = this.validateChildren(familyInformation);
    return validChildrenData && validPartnerData;
  }

  private validatePartnerData(familyInformation: StamdataDetaljer) {
    let validData = true;
    //is married or has a partner
    if (
      familyInformation.civilstand === 'Gift' ||
      familyInformation.samlevende
    ) {
      if (Utils.isNullOrUndefined(familyInformation.partnerFornavn)) {
        validData = false;
      }

      if (Utils.isNullOrUndefined(familyInformation.partnerFoedselsDato)) {
        validData = false;
      }

      if (Utils.isNullOrUndefined(familyInformation.partnerAarsloen)) {
        validData = false;
      }
    } else if (Utils.isNullOrUndefined(familyInformation.samlevende)) {
      // no answer about partner
      validData = false;
    }

    return validData;
  }

  private validateChildren(familyInformation: StamdataDetaljer) {
    let validData = true;
    if (Utils.isNullOrUndefined(familyInformation.forsoergerpligt)) {
      //no answer about children
      validData = false;
    } else if (familyInformation.forsoergerpligt) {
      //has children
      for (const child of familyInformation.familieRelationBoern) {
        //validate child
        if (!child.fornavn || !child.foedselsDato || !child.barnType) {
          validData = false;
        }
      }
    }

    return validData;
  }

  onStartGuide() {
    if (this.advisorShortFlowForm.get('isShortFlow')?.value && this.isAdvisor) {
      this.startGuide.emit(true);
    } else {
      this.startGuide.emit(false);
    }
  }
}
