import { Component, OnInit, inject, DestroyRef } from '@angular/core';
import { Fordele, FordeleStore } from '@pfa/gen';
import { PageTrackingService } from '@pfa/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'mitpfa-advantage-vip',
  templateUrl: './advantage-vip.component.html',
  styleUrls: ['./advantage-vip.component.scss']
})
export class AdvantageVipComponent implements OnInit {
  private readonly fordeleStore: FordeleStore = inject(FordeleStore);
  private readonly pageTrackingService: PageTrackingService =
    inject(PageTrackingService);
  private readonly destroyRef: DestroyRef = inject(DestroyRef);

  public advantagesData: Fordele;

  public ngOnInit(): void {
    this.fordeleStore
      .fordeleGet()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(result => {
        this.advantagesData = result;
        this.pageTrackingService.trackContentLoaded();
      });
  }
}
