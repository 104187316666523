import { DynamicAdvisingOffer } from '@pfa/gen';

export interface DynamicCounselingLink {
  uri: string;
  text: string;
}

export class DynamicModel {
  constructor(
    private dynamiccounseling: DynamicAdvisingOffer,
    private customerAge: number,
    private primaryId: string
  ) {}

  get categoryText(): string {
    switch (this.dynamiccounseling.offerRecommendationCategory) {
      case 'BENEFICIARY':
        return 'DL.DR01.C05';
      case 'EXTERNAL_FUNDS':
        return 'DL.DR01.C08';
      case 'INVESTMENT_PROFILE':
        return 'DL.DR01.C03';
      case 'GIPP_TO_PLUS':
        return 'DL.DR01.C33';
      case 'LIFE_INSURANCE_ESTIMATE':
        return 'DL.DR01.C06';
      case 'LOC_INSURANCE_ESTIMATE':
        return 'DL.DR01.C04';
      case 'MERGE_POLICIES':
        return 'DL.DR01.C33';
      case 'PENSION_ESTIMATE':
        return 'DL.DR01.C07';
      case 'DATA_COLLECTION':
        return 'DL.DR01.C77';
    }
  }

  private value0OfferUrlAndText(): DynamicCounselingLink {
    switch (this.dynamiccounseling.offerRecommendationCategory) {
      case 'BENEFICIARY':
        return {
          uri: '/mineforsikringer/begunstigelse',
          text: 'DL.DR01.C49'
        };
      case 'EXTERNAL_FUNDS':
        return {
          uri: '/overfoersel',
          text: 'DL.DR01.C34'
        };
      case 'INVESTMENT_PROFILE':
        return {
          uri: '/mineinvesteringer/' + this.primaryId,
          text: 'DL.DR01.C50'
        };
      case 'GIPP_TO_PLUS':
        return {
          uri: '/konvertertilpfaplus',
          text: 'DL.DR01.C34'
        };
      case 'LIFE_INSURANCE_ESTIMATE':
        return {
          uri: '/mineforsikringer/tilpas',
          text: 'DL.DR01.C51'
        };
      case 'LOC_INSURANCE_ESTIMATE':
        return {
          uri: '/mineforsikringer/tilpas',
          text: 'DL.DR01.C51'
        };
      case 'MERGE_POLICIES':
        return {
          uri: '/konvertertilpfaplus',
          text: 'DL.DR01.C34'
        };
      case 'PENSION_ESTIMATE':
        return {
          uri: '/pensionstal',
          text: 'DL.DR01.C52'
        };
      case 'DATA_COLLECTION':
        return {
          uri: '/mine-oplysninger/familie',
          text: 'DL.DR01.C78'
        };
    }
  }

  getOfferUrlAndText(loc80Allowed: boolean): DynamicCounselingLink | undefined {
    if (this.dynamiccounseling.offerNbaValue === 0 || this.isOfferAllOk()) {
      return this.value0OfferUrlAndText();
    }
    switch (this.dynamiccounseling.offerId) {
      case 'BENEFICIARY_FILL_OUT_BENEFICIARY_DECLARATION':
        return {
          uri: '/mineforsikringer/begunstigelse',
          text: 'DL.DR01.C28'
        };
      case 'EXTERNAL_FUNDS_TRANSFER':
        return {
          uri: '/overfoersel',
          text: 'DL.DR01.C31'
        };
      case 'INVESTMENT_PROFILE_INCREASE_RISK':
        return {
          uri: '/mineinvesteringer/' + this.primaryId,
          text: 'DL.DR01.C23'
        };
      case 'INVESTMENT_PROFILE_LOWER_RISK':
        return {
          uri: '/mineinvesteringer/' + this.primaryId,
          text: 'DL.DR01.C23'
        };
      case 'TAKE_INVESTMENT_GUIDE':
        return {
          uri: '/mineinvesteringer/guide',
          text: 'DL.DR01.C79'
        };
      case 'GIPP_TO_PLUS_TRANSFER_DEPOSIT':
        return {
          uri: '/konvertertilpfaplus',
          text: 'DL.DR01.C34'
        };
      case 'LIFE_INSURANCE_INCREASE_COVERAGE':
        return {
          uri: '/mineforsikringer/tilpas',
          text: 'DL.DR01.C11'
        };
      case 'LIFE_INSURANCE_LOWER_COVERAGE':
        return {
          uri: '/mineforsikringer/tilpas',
          text: 'DL.DR01.C35'
        };
      case 'ENTER_MARITAL_STATUS':
        return {
          uri: '/mine-oplysninger/familie',
          text: 'DL.DR01.C30'
        };
      case 'ENTER_DEPENDENTS':
        return {
          uri: '/mine-oplysninger/familie',
          text: 'DL.DR01.C29'
        };
      case 'ENTER_WEALTH':
        return {
          uri: '/mine-oplysninger/oekonomi',
          text: 'DL.DR01.C36'
        };
      case 'ENTER_DEBT':
        return {
          uri: '/mine-oplysninger/oekonomi',
          text: 'DL.DR01.C37'
        };
      case 'ENTER_PARTNER_SALARY':
        return {
          uri: '/mine-oplysninger/familie',
          text: 'DL.DR01.C38'
        };
      case 'RETRIEVE_PENSION_INFO_REPORT':
        return {
          uri: '/mine-oplysninger/pensionsinfo',
          text: 'DL.DR01.C39'
        };
      case 'LOC_AUTOMATIC_ADJUSTMENT_INCREASE_TO_80':
      case 'LOC_AUTOMATIC_ADJUSTMENT_LOWER_TO_80':
        return {
          uri: '/mineforsikringer/tilpas',
          text: 'DL.DR01.C71'
        };
      case 'LOC_AUTOMATIC_ADJUSTMENT_INCREASE_TO_100':
      case 'LOC_AUTOMATIC_ADJUSTMENT_LOWER_TO_100':
        return {
          uri: '/mineforsikringer/tilpas',
          text: loc80Allowed ? 'DL.DR01.C72' : 'DL.DR01.C73'
        };
      case 'LOC_AUTOMATIC_ADJUSTMENT_OUT_LOWER':
        return {
          uri: '/mineforsikringer/tilpas',
          text: 'DL.DR01.C74'
        };
      case 'LOC_AUTOMATIC_ADJUSTMENT_OUT_INCREASE':
        return {
          uri: '/mineforsikringer/tilpas',
          text: 'DL.DR01.C75'
        };
      case 'LOC_INSURANCE_INCREASE_COVERAGE':
        return {
          uri: '/mineforsikringer/tilpas',
          text: 'DL.DR01.C11'
        };
      case 'LOC_INSURANCE_LOWER_COVERAGE':
        return {
          uri: '/mineforsikringer/tilpas',
          text: 'DL.DR01.C35'
        };
      case 'TAKE_INSURANCE_GUIDE':
        return {
          uri: '/mineforsikringer/forsikringsguide',
          text: 'DL.DR01.C80'
        };
      case 'MERGE_POLICIES_MERGE':
        return {
          uri: '/konvertertilpfaplus',
          text: 'DL.DR01.C34'
        };
      case 'PENSION_ESTIMATE_INCREASE_VOLUNTARY_DEPOSIT':
        // lintfixme: fix error and enable rule
        // eslint-disable-next-line no-case-declarations
        let uri: string;
        if (this.customerAge >= 55) {
          uri = '/pensionsplan/minudbetalingsplan';
        } else {
          uri = '/pensionstal';
        }
        return {
          uri: uri,
          text: 'DL.DR01.C40'
        };
      case 'INVESTMENT_CLIMATE_PLUS_INCREASE_SHARE':
        return {
          uri: '/mineinvesteringer/' + this.primaryId,
          text: 'DL.DR01.C62'
        };
      case 'INVESTMENT_CLIMATE_PLUS_LOWER_SHARE':
        return {
          uri: '/mineinvesteringer/' + this.primaryId,
          text: 'DL.DR01.C63'
        };
      case 'INVESTMENT_CONCEPT_CHANGE_CONCEPT_PFA_INVEST':
      case 'INVESTMENT_CONCEPT_CHANGE_CONCEPT_YOU_INVEST':
        return {
          uri: '/mineinvesteringer/' + this.primaryId,
          text: 'DL.DR01.C64'
        };
    }
  }

  get offerSeverity(): number {
    return this.dynamiccounseling.offerSeverity;
  }

  get offerNbaValue(): number {
    return this.dynamiccounseling.offerNbaValue;
  }

  get getDynamiccounseling(): DynamicAdvisingOffer {
    return this.dynamiccounseling;
  }

  private isOfferAllOk(): boolean {
    return (
      this.dynamiccounseling.offerId === 'DATA_COLLECTION_ALL_OK' ||
      this.dynamiccounseling.offerId === 'BENEFICIARY_ALL_OK' ||
      this.dynamiccounseling.offerId === 'LIFE_INSURANCE_ALL_OK' ||
      this.dynamiccounseling.offerId === 'LOC_INSURANCE_ALL_OK' ||
      this.dynamiccounseling.offerId === 'PENSION_ESTIMATE_ALL_OK' ||
      this.dynamiccounseling.offerId === 'EXTERNAL_FUNDS_ALL_OK' ||
      this.dynamiccounseling.offerId === 'GIPP_TO_PLUS_ALL_OK' ||
      this.dynamiccounseling.offerId === 'MERGE_POLICIES_ALL_OK' ||
      this.dynamiccounseling.offerId === 'INVESTMENT_PROFILE_ALL_OK'
    );
  }
}
