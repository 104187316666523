import {
  ForsikringDetaljer,
  Forsikringsoversigt,
  PensionsInfoOrdninger,
  PensionsKundeGenerelleData,
  OpenForLanguage
} from '@pfa/gen';

export const COMPANY_PFA = 'DL.SEL.C10';

export enum QuickactionIcon {
  DOCUMENTS = 'documents',
  COMPASS = 'compass',
  CROSS = 'cross',
  BENEFICIARY = 'beneficiary'
}

export enum InsuranceUnit {
  KR = 'Global.kroner',
  KR_MD = 'Global.kroner-md',
  KR_YEAR = 'Global.kroner-aar',
  YEARLY_SALERY = 'DL.FG02.C310', // x årsløn (eller: gange årsløn)
  PERCENT = '%'
}

export interface OnlineServicesData {
  serviceName: string;
  providerName: string;
  detailsUrl: string;
  externalUrl: string;
  imageUrl: string;
  openingHoursTagText: string;
  trackingNavigationName: string;
  trackingNavigationAction: string;
}

export enum OnlineServiceProvider {
  HEJ_DOCTOR = 'HEJ_DOCTOR',
  PRESCRIBA = 'PRESCRIBA'
}

export class Insurances {
  preventiveCare: boolean;
  healthHotline: boolean;
  pfaHealthLetpension: boolean;
  mentalWellbeing: boolean;
  abuseHotline: boolean;
  pfaHealth: boolean;
  diagnosis: boolean;
  health: boolean;
  onlineDoctor: boolean;
  earlyCare: boolean;
  criticalIllnessOtherOnly: boolean;
  criticalIllnessPfa: boolean;
  criticalIllnessLetpension: boolean;
  lossOfOccupationalCapacityPfa: boolean;
  lossOfOccupationalCapacityLetpension: boolean;
  life: boolean;
  lifeForChildren: boolean;
  crossLife: boolean;
}

export class InsuranceKeyNumbers {
  lossOfOccupationalCapacityPercent: number;
  lossOfOccupationalCapacityAmount: number;
  criticalIllnessAmount: number | undefined;
  lifePercent: number;
  lifeAmount: number;
  lifeForChildrenAmount: number;
  lifeForChildrenAge: number;
}

export class InsuranceTexts {
  lossOfOccupationalCapacity: string;
  lossOfOccupationalCapacityHeaderNote: string;
  criticalIllness: string;
  criticalIllnessHeaderNote: string;
  life: string;
  lifeForChildren: string;
  healthHeader: string;
  healthHeaderNote: string;
  health: string;
  onlineServicesNote: string;
}

export class InsuranceClaimable {
  pfaHealth: boolean;
  criticalIllness: boolean;
  lossOfOccupationalCapacity: boolean;
}

export interface InsuranceState {
  insurance: ForsikringDetaljer;
  insuranceOverview: Forsikringsoversigt;
  openForBusiness: OpenForLanguage;
  pensionCustomer: PensionsKundeGenerelleData;
  pensionInfo: PensionsInfoOrdninger;
}

export interface QuickAction {
  contentId: string;
  url?: string;
  phoneNumber?: string;
  icon?: QuickactionIcon;
  disabled?: boolean;
  disableTooltipContentId?: string;
  note?: string;
  showAsButton?: boolean;
}

export interface Coverage {
  headerText: string;
  infoText?: string;
  infoTextOpen?: string;
  details: string[];
  quickAction?: QuickAction;
  quickActionLink?: string;
}

export interface InsuranceTag {
  text: string;
  tooltip?: string;
}
