/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { LivnetAllowedRangesSingle } from './livnetAllowedRangesSingle';
import { LivnetAllowedRangesSingleSchema } from './livnetAllowedRangesSingle';
import * as JoiObj from "joi";


    export const LivnetAllowedRangesSchema = JoiObj.object({
        installment:
            
        JoiObj.link('#LivnetAllowedRangesSingleSchema')

.allow(null)



        ,
        lifeInsuranceWithoutTax:
            
        JoiObj.link('#LivnetAllowedRangesSingleSchema')

.allow(null)



        ,
        lifelong:
            
        JoiObj.link('#LivnetAllowedRangesSingleSchema')

.allow(null)



        ,
        lumpSum:
            
        JoiObj.link('#LivnetAllowedRangesSingleSchema')

.allow(null)



        ,
        maxPayoutAge:
            
                    JoiObj.number()
                

.allow(null)



        ,
        oldAge:
            
        JoiObj.link('#LivnetAllowedRangesSingleSchema')

.allow(null)



        ,
        policyId:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        temporary:
            
        JoiObj.link('#LivnetAllowedRangesSingleSchema')

.allow(null)



        
    })
                .shared(LivnetAllowedRangesSingleSchema)
                .shared(LivnetAllowedRangesSingleSchema)
                .shared(LivnetAllowedRangesSingleSchema)
                .shared(LivnetAllowedRangesSingleSchema)
                .shared(LivnetAllowedRangesSingleSchema)
                .shared(LivnetAllowedRangesSingleSchema)
.id('LivnetAllowedRangesSchema')

export interface LivnetAllowedRanges { 
    installment?: LivnetAllowedRangesSingle;
    lifeInsuranceWithoutTax?: LivnetAllowedRangesSingle;
    lifelong?: LivnetAllowedRangesSingle;
    lumpSum?: LivnetAllowedRangesSingle;
    maxPayoutAge?: number;
    oldAge?: LivnetAllowedRangesSingle;
    policyId: string;
    temporary?: LivnetAllowedRangesSingle;
}

