import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ForbeholdModalService {
  constructor(private http: HttpClient) {}

  harTAEAllInclusive() {
    return this.http.get('/ds/api/pensionskunde/taeAllInclusive');
  }

  hentPensionsplanForbehold() {
    return this.http.get('/ds/api/forbehold/pensionsplan');
  }
}
