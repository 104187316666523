/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const AfkastProfilSchema = JoiObj.object({
        a05:
            
                    JoiObj.number()
                


.required()


        ,
        a15:
            
                    JoiObj.number()
                


.required()


        ,
        a30:
            
                    JoiObj.number()
                


.required()


        ,
        a_5:
            
                    JoiObj.number()
                


.required()


        
    })
.id('AfkastProfilSchema')

export interface AfkastProfil { 
    a05: number;
    a15: number;
    a30: number;
    a_5: number;
}

