import { Component, OnInit, NgZone } from '@angular/core';
import { expansion } from '@pfa/animations';
import { ProgressbarService } from '@mitpfa/shared';

@Component({
  selector: 'mitpfa-percent-filled',
  templateUrl: './percent-filled.component.html',
  styleUrls: ['./percent-filled.component.scss'],
  animations: [expansion]
})
export class PercentFilledComponent implements OnInit {
  public value: number;
  constructor(
    private progressbarService: ProgressbarService,
    private zone: NgZone
  ) {}

  ngOnInit(): void {
    this.progressbarService.getTotalPercent().subscribe(total => {
      this.zone.run(() => {
        this.value = total;
      });
    });
  }
}
