import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { Router } from '@angular/router';
import { ForsikringStore, GodkendSvar, PensionskundeStore } from '@pfa/gen';
import { DocumentsService } from '@pfa/feature/documents';

@Component({
  selector: 'mitpfa-insurance-adjustment-receipt',
  templateUrl: './insurance-adjustment-receipt.component.html',
  styleUrls: ['./insurance-adjustment-receipt.component.scss']
})
export class InsuranceAdjustmentReceiptComponent implements OnInit, OnDestroy {
  @Input() healthQuestionnaire: boolean;
  @Input() adviser: boolean;
  @Input() godkendSvar: GodkendSvar;
  @Input() isOnboarding: boolean;
  @Output() exit = new EventEmitter<void>();

  // INJECTIONS
  private readonly pensionskundeStore: PensionskundeStore =
    inject(PensionskundeStore);
  private readonly forsikringStore: ForsikringStore = inject(ForsikringStore);
  private readonly router: Router = inject(Router);
  private readonly documentsService: DocumentsService =
    inject(DocumentsService);

  ngOnInit(): void {
    if (!this.godkendSvar.gemkvitteringfejl) {
      this.documentsService.clearCache();
    }
  }

  fillIn(): void {
    this.router.navigateByUrl('/mineforsikringer/automatiskantagelse/intro');
  }

  close(): void {
    if (this.isOnboarding) {
      this.exit.emit();
    } else {
      this.navigateToFrontpage();
    }
  }

  private navigateToFrontpage(): void {
    this.router.navigateByUrl('/mitoverblik');
  }

  ngOnDestroy(): void {
    // make sure confirmed choice is updated
    this.pensionskundeStore.invalidatepensionskundeIndex();
    this.forsikringStore.invalidateforsikringdetaljerIndex();
  }
}
