import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { PfaFormModule } from '@pfaform';
import { IconModule } from '@pfa/icons';
import { PensionInfoModule } from '@mitpfa/shared/pension-info/pension-info.module';
import { SharedModule } from '@mitpfa/shared';
import { InvestmentGuideBackButtonModule } from './back-button/investment-guide-back-button.module';
import { InvestmentGuideClimateStepComponent } from './climate-step/investment-guide-climate-step.component';
import { InvestmentGuideClimateExtendedFocusComponent } from './climate-step/questions/climate-extended-focus/investment-guide-climate-step-extended-focus.component';
import { InvestmentGuideClimatePriorityComponent } from './climate-step/questions/climate-priority/investment-guide-climate-step-priority.component';
import { InvestmentGuideClimateWillingnessComponent } from './climate-step/questions/climate-willingness/investment-guide-climate-step-willingness.component';
import { InvestmentGuideEconomicalImpactComponent } from './economy-step/questions/economical-impact/investment-guide-economical-impact.component';
import { InvestmentGuideEconomyStepComponent } from './economy-step/investment-guide-economy-step.component';
import { InvestmentGuideHasDebtComponent } from './economy-step/questions/has-debt/investment-guide-has-debt.component';
import { InvestmentGuidePensionInfoComponent } from './economy-step/questions/pension-info/investment-guide-pension-info.component';
import { InvestmentGuideRealEstateComponent } from './economy-step/questions/real-estate/investment-guide-real-estate.component';
import { InvestmentGuideSavingsComponent } from './economy-step/questions/savings/investment-guide-savings.component';
import { InvestmentGuideSecondaryResidenceComponent } from './economy-step/questions/secondary-residence/investment-guide-secondary-residence.component';
import { InvestmentGuideHelpComponent } from './help/investment-guide-help.component';
import { InvestmentGuideIntroComponent } from './intro/investment-guide-intro.component';
import { InvestmentGuideComponent } from './investment-guide.component';
import { InvestmentGuideInvolvementStepComponent } from './involvement-step/investment-guide-involvement-step.component';
import { InvestmentGuideRiskStepComponent } from './risk-step/investment-guide-risk-step.component';
import { InvestmentGuideLossAbilityComponent } from './risk-step/questions/lossability/investment-guide-risk-step-lossability.component';
import { InvestmentGuideRiskProfileComponent } from './risk-step/questions/risk-profile/investment-guide-risk-step-risk-profile.component';
import { InvestmentGuideRiskWillingnessComponent } from './risk-step/questions/risk-willingness/investment-guide-risk-step-risk-willingness.component';
import { InvestmentGuideClimateRelevanceComponent } from './climate-step/questions/climate-relevance/investment-guide-climate-step-relevance.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';

@NgModule({
  declarations: [
    InvestmentGuideComponent,
    InvestmentGuideIntroComponent,
    InvestmentGuideInvolvementStepComponent,
    InvestmentGuideHelpComponent,
    InvestmentGuideEconomyStepComponent,
    InvestmentGuidePensionInfoComponent,
    InvestmentGuideSavingsComponent,
    InvestmentGuideRealEstateComponent,
    InvestmentGuideSecondaryResidenceComponent,
    InvestmentGuideHasDebtComponent,
    InvestmentGuideRiskStepComponent,
    InvestmentGuideLossAbilityComponent,
    InvestmentGuideRiskWillingnessComponent,
    InvestmentGuideRiskProfileComponent,
    InvestmentGuideClimateStepComponent,
    InvestmentGuideClimateExtendedFocusComponent,
    InvestmentGuideClimatePriorityComponent,
    InvestmentGuideClimateWillingnessComponent,
    InvestmentGuideRiskProfileComponent,
    InvestmentGuideEconomicalImpactComponent,
    InvestmentGuideClimateRelevanceComponent
  ],
  imports: [
    PensionInfoModule,
    InvestmentGuideBackButtonModule,
    SharedModule,
    PfaFormModule,
    RouterModule,
    IconModule,
    MatProgressBarModule
  ],
  exports: [
    InvestmentGuideComponent,
    InvestmentGuideIntroComponent,
    InvestmentGuideInvolvementStepComponent,
    InvestmentGuideHelpComponent,
    InvestmentGuideEconomyStepComponent,
    InvestmentGuidePensionInfoComponent,
    InvestmentGuideSavingsComponent,
    InvestmentGuideRealEstateComponent,
    InvestmentGuideSecondaryResidenceComponent,
    InvestmentGuideHasDebtComponent,
    InvestmentGuideRiskStepComponent,
    InvestmentGuideLossAbilityComponent,
    InvestmentGuideRiskWillingnessComponent,
    InvestmentGuideRiskProfileComponent,
    InvestmentGuideClimateStepComponent,
    InvestmentGuideClimateExtendedFocusComponent,
    InvestmentGuideClimatePriorityComponent,
    InvestmentGuideClimateWillingnessComponent,
    InvestmentGuideRiskProfileComponent,
    InvestmentGuideEconomicalImpactComponent,
    InvestmentGuideClimateRelevanceComponent
  ]
})
export class InvestmentGuideModule {}
