import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { OekonomiDetaljer, OpdaterOekonomiResponse } from '@pfa/gen';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FinancialSituationApiService {
  constructor(readonly http: HttpClient) {}

  get(): Observable<OekonomiDetaljer> {
    const url = '/ds/api/oekonomi';
    return this.http.get<OekonomiDetaljer>(url);
  }

  updateFinancialSituation(
    oekonomiDetaljer: OekonomiDetaljer
  ): Observable<OpdaterOekonomiResponse> {
    return this.http
      .post<OpdaterOekonomiResponse>(
        '/ds/api/oekonomi/opdater',
        oekonomiDetaljer
      )
      .pipe(
        map(data => {
          if (data?.success) {
            return data;
          } else {
            // lintfixme: fix error and enable rule
            // eslint-disable-next-line @typescript-eslint/no-throw-literal
            throw data;
          }
        })
      );
  }
}
