/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Group } from './group';
import { GroupSchema } from './group';
import * as JoiObj from "joi";


    export const SurveyCategorySchema = JoiObj.object({
        groups:
                JoiObj.array().items(
        
        JoiObj.link('#GroupSchema')

        )
        
.allow(null)



        ,
        hideFromTargetGroups:
                JoiObj.array().items(
        
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

        )
        
.allow(null)



        ,
        id:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        introText:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        showToTargetGroups:
                JoiObj.array().items(
        
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

        )
        
.allow(null)



        ,
        title:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        
    })
                .shared(GroupSchema)
.id('SurveyCategorySchema')

export interface SurveyCategory { 
    groups?: Array<Group>;
    hideFromTargetGroups?: Array<string>;
    id: string;
    introText?: string;
    showToTargetGroups?: Array<string>;
    title: string;
}

