import {
  transition,
  trigger,
  animate,
  style,
  keyframes,
  state
} from '@angular/animations';

export const OPEN_CLOSE_ANIMATION_DELAY = 300;

export const noInitialAnimation = trigger('no-initial-animation', [
  transition(':enter', [])
]);
export const slideInRight = trigger('slideInRight', [
  transition(':enter', [
    style({ transform: 'translateX(100%)' }),
    animate('300ms', style({ transform: 'translateX(0)' }))
  ])
]);

export const slideInRightWithDelay = trigger('slideInRightWithDelay', [
  transition(':enter', [
    style({ transform: 'translateX(100%)' }),
    animate('300ms {{delay}}ms ease-in', style({ transform: 'translateX(0)' }))
  ])
]);

export const slideInLeft = trigger('slideInLeft', [
  transition(':enter', [
    style({ transform: 'translateX(-100%)' }),
    animate('300ms', style({ transform: 'translateX(0)' }))
  ])
]);

export const slideOutLeft = trigger('slideOutLeft', [
  transition(':leave', [
    style({ transform: 'translateX(0)' }),
    animate('300ms', style({ transform: 'translateX(-100%)' }))
  ])
]);

export const slideOutBottom = trigger('slideOutBottom', [
  transition(':leave', [
    style({ transform: 'translateY(0)' }),
    animate(
      '300ms  {{delay}}ms ease-in',
      style({ transform: 'translateY(30%)' })
    )
  ])
]);

export const slideOutBottomWhole = trigger('slideOutBottomWhole', [
  transition(':leave', [
    style({ transform: 'translateY(0)' }),
    animate(
      '{{duration}}ms  {{delay}}ms ease-in',
      style({ transform: 'translateY(100%)' })
    )
  ])
]);

export const slideInBottom = trigger('slideInBottom', [
  transition(':enter', [
    style({ transform: 'translateY(100%)' }),
    animate(
      '{{duration}}ms {{delay}}ms ease-in',
      style({ transform: 'translateY(0)' })
    )
  ])
]);

export const openClose = trigger('openClose', [
  transition(
    ':enter',
    [
      style({
        overflow: 'hidden',
        height: '0px',
        transition: `height ${OPEN_CLOSE_ANIMATION_DELAY}ms ease-in`
      }),
      animate(
        `${OPEN_CLOSE_ANIMATION_DELAY}ms {{delay}}ms`,
        style({ height: '*' })
      )
    ],
    {
      params: {
        delay: 0
      }
    }
  ),
  transition(':leave', [
    style({ overflow: 'hidden' }),
    animate(
      `${OPEN_CLOSE_ANIMATION_DELAY}ms ease-out`,
      style({ transform: 'translateY(5px)', height: '0' })
    )
  ])
]);

export const collapse = trigger('collapse', [
  state('collapseAnimated', style({ overflow: 'hidden', height: '0px' })),
  state('expandAnimated', style({ height: '*' })),
  state('expand', style({ height: '*' })),
  state('collapse', style({ overflow: 'hidden', height: '0px' })),
  transition('* => collapse', animate('0ms', style({ height: '0' }))),
  transition('* => expand', animate('0ms', style({ height: '*' }))),
  transition(
    '* => collapseAnimated',
    animate('300ms ease-out', style({ height: '0' }))
  ),
  transition(
    '* => expandAnimated',
    animate('300ms ease-out', style({ height: '*' }))
  )
]);

export const fadeInOut = trigger('fadeInOut', [
  transition(':enter', [
    style({
      opacity: 0
    }),
    animate('300ms ease-in', style({ opacity: 1 }))
  ]),
  transition(':leave', [
    style({
      opacity: 1
    }),
    animate('300ms ease-out', style({ opacity: 0 }))
  ])
]);

export const fadeHalfInOut = trigger('fadeHalfInOut', [
  transition(':enter', [
    style({
      opacity: 0
    }),
    animate('400ms ease-in', style({ opacity: 0.5 }))
  ]),
  transition(':leave', [
    style({
      opacity: 0.5
    }),
    animate('400ms ease-out', style({ opacity: 0 }))
  ])
]);

export const slideOutRight = trigger('slideOutRight', [
  transition(':leave', [
    style({ transform: 'translateX(0)' }),
    animate('300ms', style({ transform: 'translateX(100%)' }))
  ])
]);

export const slideOutRightWithDelay = trigger('slideOutRightWithDelay', [
  transition(':leave', [
    style({ transform: 'translateX(0)' }),
    animate(
      '300ms {{delay}}ms ease-in',
      style({ transform: 'translateX(100%)' })
    )
  ])
]);

export const expansion = trigger('expansion', [
  transition(
    ':enter',
    [
      style({ overflow: 'hidden', 'max-height': '0' }),
      animate('300ms ease-in', style({ 'max-height': '{{ maxHeight }}' }))
    ],
    { params: { maxHeight: '500px' } }
  ),
  transition(
    ':leave',
    [
      style({ overflow: 'hidden', 'max-height': '{{ maxHeight }}' }),
      animate('300ms ease-out', style({ 'max-height': '0' }))
    ],
    { params: { maxHeight: '500px' } }
  )
]);

export const pulse = trigger('pulse', [
  transition(':enter', [
    animate(
      '4.5s 3s ease',
      keyframes([
        style({
          transform: 'scale(0.91)',
          'animation-timing-function': 'ease-in',
          offset: 0.1
        }),
        style({
          transform: 'scale(0.98)',
          'animation-timing-function': 'ease-out',
          offset: 0.17
        }),
        style({
          transform: 'scale(0.87)',
          'animation-timing-function': 'ease-in',
          offset: 0.33
        }),
        style({
          transform: 'scale(1.00)',
          'animation-timing-function': 'ease-out',
          offset: 0.45
        })
      ])
    )
  ])
]);

export const opacity = trigger('opacity', [
  state('hide', style({ opacity: '0' })),
  state('show', style({ opacity: '1' })),
  transition('* => hide', animate('300ms ease-out', style({ opacity: '0' }))),
  transition('* => show', animate('300ms ease-out', style({ opacity: '1' })))
]);
