/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ServiceInfo } from './serviceInfo';
import { ServiceInfoSchema } from './serviceInfo';
import { ExcludedPoliciesDetails } from './excludedPoliciesDetails';
import { ExcludedPoliciesDetailsSchema } from './excludedPoliciesDetails';
import { ForsikringDetaljer } from './forsikringDetaljer';
import { ForsikringDetaljerSchema } from './forsikringDetaljer';
import { ResourceModel } from './resourceModel';
import { ResourceModelSchema } from './resourceModel';
import { AdvisorCorrectionsDetails } from './advisorCorrectionsDetails';
import { AdvisorCorrectionsDetailsSchema } from './advisorCorrectionsDetails';
import { PensionsplanPension } from './pensionsplanPension';
import { PensionsplanPensionSchema } from './pensionsplanPension';
import * as JoiObj from "joi";


    export const PensionsInfoOrdningerSchema = JoiObj.object({
        serviceInfo:
            
        JoiObj.link('#ServiceInfoSchema')

.allow(null)



        ,
        advisorCorrectionsDetails:
            
        JoiObj.link('#AdvisorCorrectionsDetailsSchema')


.required()


        ,
        excludedPoliciesDetails:
            
        JoiObj.link('#ExcludedPoliciesDetailsSchema')


.required()


        ,
        forsikring:
            
        JoiObj.link('#ForsikringDetaljerSchema')


.required()


        ,
        pension:
            
        JoiObj.link('#PensionsplanPensionSchema')


.required()


        ,
        ssoTilPensionsInfoTilladt:
            
                    JoiObj.boolean()
                


.required()


        
    })
                .shared(ServiceInfoSchema)
                .shared(AdvisorCorrectionsDetailsSchema)
                .shared(ExcludedPoliciesDetailsSchema)
                .shared(ForsikringDetaljerSchema)
                .shared(PensionsplanPensionSchema)
.id('PensionsInfoOrdningerSchema')

export interface PensionsInfoOrdninger extends ResourceModel { 
    advisorCorrectionsDetails: AdvisorCorrectionsDetails;
    excludedPoliciesDetails: ExcludedPoliciesDetails;
    forsikring: ForsikringDetaljer;
    pension: PensionsplanPension;
    ssoTilPensionsInfoTilladt: boolean;
}

