<div class="messaging-threadlist" [ngClass]="{ 'thread-open': threadOpen }">
  <co-threadlist-item
    *ngFor="let threadItem of threads; let first = first"
    [threadItem]="threadItem"
    (openThread)="delegateOpenThread($event)"
    [threadOpen]="threadOpen"
    [priorityClassRed]="priorityClassRed"
    [first]="first"
  >
  </co-threadlist-item>
  <div
    *ngIf="!searched && threads.length === 0"
    class="no-messages"
    [coContent]="'DL.BE02.C04'"
  >
    Du har ingen beskeder fra pfa
  </div>
  <div
    *ngIf="searched && threads.length === 0"
    class="no-messages align--center"
    [coContent]="'DL.BE02.C1002'"
  >
    fandt ingen beskeder
  </div>
</div>
