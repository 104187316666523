<div class="width-100">
  <form [formGroup]="pensionEstimateSelectForm">
    <div>
      <co-slider-form
        class="pension-estimate"
        [label]="label"
        [sliderOptions]="sliderOptions"
        [valueNoteAfterTax]="valueNoteAfterTax"
        [valueNoteBeforeTax]="valueNoteBeforeTax"
        [validateInputMax]="validateMaxInput"
        [unit]="unit"
        [decimals]="decimal"
        [deactivate]="disabled"
        ngDefaultControl
        formControlName="pensionEstimateSelectControl"
        type="number"
        (sliderEnd)="sliderStop($event)"
        (inputUpdated)="inputUpdated($event)"
      ></co-slider-form>
    </div>
  </form>
  <ng-content select="[ekstra]"></ng-content>
</div>
