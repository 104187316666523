<form
  [formGroup]="salaryForm"
  *ngIf="showSalaryForm"
  class="spacing-section--over"
>
  <co-card>
    <div class="row">
      <div class="col-sm-6">
        <p class="text--manchet" coContent="DL.PP01.C35"></p>
        <p class="text--info" coContent="DL.PP01.I35"></p>
      </div>

      <div class="col-sm-3 offset-sm-1">
        <mat-form-field color="accent">
          <input
            matInput
            coAmount
            ngDefaultControl
            formControlName="recordedAnnualSalary"
            [placeholder]="'DL.MD01.C390' | content"
          />
        </mat-form-field>
      </div>
    </div>

    <div class="row spacing-unit--double-over">
      <div class="col-sm-6">
        <p class="text--manchet" coContent="DL.PP01.C36"></p>
        <p class="text--info" coContent="DL.PP01.I36"></p>
      </div>
      <div class="col-sm-3 offset-sm-1">
        <mat-form-field color="accent">
          <input
            matInput
            coAmount
            ngDefaultControl
            formControlName="presentAnnualSalary"
            [placeholder]="'DL.MD01.C390' | content"
          />
        </mat-form-field>
      </div>
    </div>
  </co-card>
</form>
