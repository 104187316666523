/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const InvesteringProfilSchema = JoiObj.string().valid(
        'A'
        ,
        'B'
        ,
        'C'
        ,
        'D'
        ,
        'G'
        ,
        'PFAValgfri1'
        ,
        'PFAValgfri2'
        ,
        'PFAValgfri3'
        ,
        'PFAValgfri4'
        ,
        'PFAValgfri'
        
    )
.id('InvesteringProfilSchema')

export type InvesteringProfil = 'A' | 'B' | 'C' | 'D' | 'G' | 'PFAValgfri1' | 'PFAValgfri2' | 'PFAValgfri3' | 'PFAValgfri4' | 'PFAValgfri';

export const InvesteringProfil = {
    A: 'A' as InvesteringProfil,
    B: 'B' as InvesteringProfil,
    C: 'C' as InvesteringProfil,
    D: 'D' as InvesteringProfil,
    G: 'G' as InvesteringProfil,
    PfaValgfri1: 'PFAValgfri1' as InvesteringProfil,
    PfaValgfri2: 'PFAValgfri2' as InvesteringProfil,
    PfaValgfri3: 'PFAValgfri3' as InvesteringProfil,
    PfaValgfri4: 'PFAValgfri4' as InvesteringProfil,
    PfaValgfri: 'PFAValgfri' as InvesteringProfil
};

