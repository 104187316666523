<div [ngSwitch]="coverage.maanedligpristype">
  <div *ngSwitchCase="'BELOEB'">
    <div>
      {{ coverage.maanedligPris | numberFormat: digits }}
      <span [coContent]="'Global.kroner'"></span>
    </div>
  </div>
  <div *ngSwitchCase="'TEKST'">
    <co-icon-info
      *ngIf="coverage.maanedligPrisTekstId === 'DL.TR01.C423'"
    ></co-icon-info>
    <span class="text--note" [coContent]="coverage.maanedligPrisTekstId"></span>
  </div>
</div>
