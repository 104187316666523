import { Component, EventEmitter, inject, OnInit, Output } from '@angular/core';
import { OnboardingService } from '../onboarding.service';

@Component({
  selector: 'mitpfa-onboarding-step-investment',
  templateUrl: './step-investment.component.html',
  styleUrls: ['./step-investment.component.scss']
})
export class StepInvestmentComponent implements OnInit {
  @Output() goTo = new EventEmitter();
  public showInvestment: boolean;
  public showInsurance: boolean;
  public changeHeadline = false;
  public extraCards = 0;

  private readonly onboardingService: OnboardingService =
    inject(OnboardingService);

  public ngOnInit(): void {
    this.showInvestment = this.onboardingService.getShowInvestment();
    this.showInsurance = this.onboardingService.getShowInsurance();
    if (this.showInvestment && this.showInsurance) {
      this.extraCards = 1;
    }
    setTimeout(() => {
      this.changeHeadline = true;
    }, 4000);
  }

  public next(): void {
    this.goTo.emit();
  }
}
