<div class="slider-form" [formGroup]="form">
  <div class="label text-container">
    <label
      *ngIf="label"
      [coContent]="label"
      class="text--bold"
      [ngClass]="{ 'text-disabled': deactivate }"
    ></label>
  </div>

  <co-slider
    class="ngx-slider-form"
    [model]="form.controls.slider.value"
    [options]="activeSliderOptions"
    (modelChange)="onSliderValueChange($event)"
    (modelChangeEnd)="onSliderEnd($event)"
    enableMaxLimitStyling="true"
  >
  </co-slider>

  <div class="type-it">
    <div
      class="type-it-form ngx-slider-form"
      *ngIf="displayTypeItForm; else typeItButton"
      [@fadeInOut]
    >
      <button type="button" mat-icon-button (click)="toggleTypeIt()">
        <co-icon-reject></co-icon-reject>
      </button>
      <mat-form-field class="small-input" color="accent">
        <input
          *ngIf="type === 'currency'; else number"
          (keyup)="inputKey()"
          matInput
          coAmount
          formControlName="input"
          [placeholder]="'DL.MD01.C390' | content"
          required
          data-test-id="slider-form-type-it-input"
        />
        <ng-template #number>
          <input
            matInput
            coAmount
            coAmountDecimals=" {{ decimals }}"
            coAmountUnit=" {{ unit | content }}"
            (keyup)="inputKey()"
            formControlName="input"
            [placeholder]="'DL.MD01.C390' | content"
            required
          />
        </ng-template>

        <mat-error
          *ngIf="form.get('input').errors?.required; else notEmpty"
          coContent="DL.MD01.C12"
        ></mat-error>

        <ng-template #notEmpty>
          <mat-error
            *ngIf="form.get('input').errors?.notNumber; else correctFormat"
            coContent="DL.MD01.C13"
          ></mat-error>

          <ng-template #correctFormat>
            <mat-error
              *ngIf="form.get('input').errors?.maxlength"
              coContent="DL.MD01.C14"
              [coContentSubst]="9"
            ></mat-error>

            <mat-error
              *ngIf="form.get('input').errors?.min"
              coContent="DL.MD01.C21a"
              [coContentSubst]="
                (activeSliderOptions.floor || 0) +
                '|' +
                (this.type === 'currency' ? ('Global.kroner' | content) : ' ')
              "
            ></mat-error>
            <mat-error
              *ngIf="form.get('input').errors?.max"
              [coContent]="'DL.PP01.C961'"
              [coContentSubst]="
                (activeSliderOptions.ceil || 0) +
                '|' +
                (this.type === 'currency' ? ('Global.kroner' | content) : ' ')
              "
            ></mat-error>
          </ng-template>
        </ng-template>
      </mat-form-field>
    </div>

    <ng-template #typeItButton>
      <button
        type="button"
        mat-button
        color="primary"
        class="text--bold"
        [disabled]="deactivate"
        (click)="toggleTypeIt()"
        data-test-id="slider-form-type-it-btn"
      >
        <co-icon-type-it class="icon"></co-icon-type-it>
        <span coContent="DL.MD01.C391"></span>
      </button>
    </ng-template>
  </div>
  <div class="align--right value text-container">
    <co-primary-number
      class="size-sm"
      [unit]="unit"
      [amount]="displayedValue$ | async"
      [showDecimals]="decimals"
      [disabled]="deactivate"
    ></co-primary-number>
    <div
      *ngIf="valueNoteBeforeTax !== undefined"
      class="size-sm text--note"
      [coContent]="'DL.PP01.C965'"
      [coContentSubst]="valueNoteBeforeTax | number: '1.0-0'"
    ></div>
    <div
      *ngIf="valueNoteAfterTax !== undefined"
      class="size-sm text--note focus-text"
      [coContent]="'DL.PP01.C966'"
      [coContentSubst]="valueNoteAfterTax | number: '1.0-0'"
    ></div>
  </div>
</div>
