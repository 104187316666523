/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { IdNameMap } from './idNameMap';
import { IdNameMapSchema } from './idNameMap';
import { DatePeriod } from './datePeriod';
import { DatePeriodSchema } from './datePeriod';
import { Period } from './period';
import { PeriodSchema } from './period';
import * as JoiObj from "joi";


    export const ValueSchema = JoiObj.object({
        city:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        datePeriod:
            
        JoiObj.link('#DatePeriodSchema')

.allow(null)



        ,
        map:
            
        JoiObj.link('#IdNameMapSchema')

.allow(null)



        ,
        periods:
                JoiObj.array().items(
        
        JoiObj.link('#PeriodSchema')

        )
        
.allow(null)



        ,
        text:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        zipcode:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        
    })
                .shared(DatePeriodSchema)
                .shared(IdNameMapSchema)
                .shared(PeriodSchema)
.id('ValueSchema')

export interface Value { 
    city?: string;
    datePeriod?: DatePeriod;
    map?: IdNameMap;
    periods?: Array<Period>;
    text?: string;
    zipcode?: string;
}

