import { FileLikeObject } from 'ng2-file-upload';

export interface FileLikeObjectExtended extends FileLikeObject {
  trackId?: number;
}

export type LimitType = 'file' | 'total';

export enum FileType {
  JPG = 'jpg',
  JPEG = 'jpeg',
  PNG = 'png',
  GIF = 'gif',
  BMP = 'bmp',
  TIFF = 'tiff',
  PDF = 'pdf',
  DOC = 'doc',
  DOCX = 'docx',
  XLS = 'xls',
  XLSX = 'xlsx',
  PPT = 'ppt',
  PPTX = 'pptx',
  CSV = 'csv'
}

export const fileTypes: { [key in FileType]: string } = {
  jpg: '.jpg',
  jpeg: '.jpeg',
  png: '.png',
  gif: '.gif',
  bmp: '.bmp',
  tiff: '.tiff',
  pdf: '.pdf',
  doc: '.doc',
  docx: '.docx',
  xls: '.xls',
  xlsx: '.xlsx',
  ppt: '.ppt',
  pptx: '.pptx',
  csv: '.csv'
};

export const fileMimeTypes: { [key in FileType]: string } = {
  jpg: 'image/jpg',
  jpeg: 'image/jpeg',
  png: 'image/png',
  gif: 'image/gif',
  bmp: 'image/bmp',
  tiff: 'image/tiff',
  pdf: 'application/pdf',
  doc: 'application/msword',
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  xls: 'vnd.ms-excel',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  ppt: 'application/vnd.ms-powerpoint',
  pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  csv: 'text/csv'
};
