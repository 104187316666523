import { Component, Inject } from '@angular/core';
import { CustomerSurveyNavigationService } from './customer-survey-navigation.service';
import { CustomerSurveyStep } from './customer-survey.enum';
import { CustomerSurveyNavigationEvent } from './customer-survey.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'mitpfa-customer-survey',
  templateUrl: './customer-survey.component.html',
  styleUrls: ['./customer-survey.component.scss']
})
export class CustomerSurveyComponent {
  constructor(
    readonly navigationService: CustomerSurveyNavigationService,
    private readonly dialogRef: MatDialogRef<CustomerSurveyComponent>,
    @Inject(MAT_DIALOG_DATA) public surveyUuid: string
  ) {}

  step = CustomerSurveyStep;

  next($event: CustomerSurveyNavigationEvent) {
    this.navigationService.navigateNext({ from: $event.from });
  }

  close($event: boolean) {
    this.dialogRef.close($event);
  }
}
