import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MaeglerInfo } from '@pfa/gen';
import { InvestmentGuideInfo } from '@pfa/models';
import { ContentUtilService } from '@pfa/core';
import { DataPoint, LineChartArea } from '@pfaform';
import { Subscription } from 'rxjs';
import { RiskProfileChoice } from '../../investment-guide-risk-step.model';
import { IncestmentGuideRiskStepRiskProfileDataService } from './investment-guide-risk-step-risk-profile.service';
import { InvestmentGuideService } from '@mitpfa/shared';

@Component({
  selector: 'mitpfa-investment-guide-risk-profile',
  templateUrl: './investment-guide-risk-step-risk-profile.component.html',
  styleUrls: ['./investment-guide-risk-step-risk-profile.component.scss'],
  providers: [IncestmentGuideRiskStepRiskProfileDataService]
})
export class InvestmentGuideRiskProfileComponent implements OnInit, OnDestroy {
  @Input() investmentGuide: InvestmentGuideInfo;
  @Input() broker: MaeglerInfo;

  riskProfilesData: DataPoint[][];
  riskProfilesAreaData: LineChartArea[];
  previouslySelectedIndex: number;
  activeProfileIndex = 0;
  choices: RiskProfileChoice[] = [
    {
      text: 'DL.INVGU01.C423',
      value: 'LOW',
      label: 'DL.INVGU01.C420'
    },
    {
      text: 'DL.INVGU01.C427',
      value: 'AVERAGE_LOW',
      label: 'DL.INVGU01.C230'
    },
    {
      text: 'DL.INVGU01.C431',
      value: 'AVERAGE',
      label: 'DL.INVGU01.C428'
    }
  ];

  get activeProfileLabel(): string {
    return this.contentUtilService.getContent(
      this.choices[this.activeProfileIndex].label
    );
  }

  private subs: Subscription = new Subscription();

  constructor(
    private riskProfileDataService: IncestmentGuideRiskStepRiskProfileDataService,
    private readonly investmentGuideService: InvestmentGuideService,
    private contentUtilService: ContentUtilService
  ) {}

  ngOnInit(): void {
    this.riskProfilesData = [
      this.riskProfileDataService.lowRisk,
      this.riskProfileDataService.mediumRisk,
      this.riskProfileDataService.highRisk
    ];
    this.riskProfilesAreaData = [
      this.riskProfileDataService.lowRiskArea,
      this.riskProfileDataService.mediumRiskArea,
      this.riskProfileDataService.highRiskArea
    ];
    const sub = this.investmentGuideService
      .getCurrentQuestion$()
      .subscribe(() => {
        this.previouslySelectedIndex = this.choices.findIndex(
          choice =>
            choice.value ===
            this.investmentGuide.recommendationBasis.chosenRiskProfile
        );

        this.activeProfileIndex =
          this.previouslySelectedIndex === -1
            ? 1
            : this.previouslySelectedIndex;
      });
    this.subs.add(sub);
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  selectProfile(index: number): void {
    this.activeProfileIndex = index;
  }

  choose(): void {
    this.investmentGuide.recommendationBasis.chosenRiskProfile =
      this.choices[this.activeProfileIndex].value;
    this.investmentGuideService.updateCurrentQuestion({
      value: 'LOSSABILITY',
      update: true
    });
  }

  goBack(): void {
    this.investmentGuideService.updateCurrentQuestion({
      value: 'RISK_WILINGNESS',
      update: false
    });
  }
}
