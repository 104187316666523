import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { InsuranceGuideQuestionValue } from '../insurance-guide-configuration.service';
import { BehaviorSubject } from 'rxjs';
import {
  ForsikringDetaljer,
  OekonomiDetaljer,
  StamdataDetaljer,
  PensionsKundeGenerelleData
} from '@pfa/gen';
@Component({
  selector: 'mitpfa-insurance-guide-risk-group-step',
  templateUrl: './insurance-guide-risk-group-step.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InsuranceGuideRiskGroupStepComponent {
  @Input() currentQuestion: BehaviorSubject<InsuranceGuideQuestionValue>;
  @Input() financialSituation: OekonomiDetaljer;
  @Input() familyInformation: StamdataDetaljer;
  @Input() pensionCustomer: PensionsKundeGenerelleData;
  @Input() insuranceDetails: ForsikringDetaljer;
}
