import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'mitpfa-onboarding-step-finances',
  templateUrl: './step-finances.component.html',
  styleUrls: ['./step-finances.component.scss']
})
export class StepFinancesComponent implements OnInit {
  @Output() goTo = new EventEmitter();
  public changeHeadline = false;

  public next(): void {
    this.goTo.emit();
  }

  public ngOnInit(): void {
    setTimeout(() => {
      this.changeHeadline = true;
    }, 6000);
  }
}
