/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const SalesOrganizationNameSchema = JoiObj.string().valid(
        'PFAPension'
        ,
        'LetPension'
        ,
        'Unknown'
        
    )
.id('SalesOrganizationNameSchema')

export type SalesOrganizationName = 'PFAPension' | 'LetPension' | 'Unknown';

export const SalesOrganizationName = {
    PfaPension: 'PFAPension' as SalesOrganizationName,
    LetPension: 'LetPension' as SalesOrganizationName,
    Unknown: 'Unknown' as SalesOrganizationName
};

