import {
  Directive,
  ElementRef,
  HostListener,
  HostBinding
} from '@angular/core';

@Directive({
  selector: '[coBlurIt]'
})
export class BlurItDirective {
  constructor(private el: ElementRef) {}

  @HostBinding('class.with-blur') public isBlurred: boolean;

  @HostListener('scroll')
  onScroll() {
    this.isBlurred = this.el.nativeElement.scrollTop > 0 ? true : false;
  }
}
