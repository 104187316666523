import { BasePaths, Configuration, ConfigurationParameters } from '@pfa/gen';

/* NOTE!
  Further down there's a function pensionInfoUrl which returns a hardcoded url for pension info.
  We needed the path in the components but couldn't call dsApiConfigFactory().basePathOverride('pensionsinfo').base
  Reason is that dsApiConfigFactory().basePathOverride could be undefined.
  Handling undefined would result in hardcoded url in any case.
*/
export function dsApiConfigFactory(): Configuration {
  const params: ConfigurationParameters = {
    basePathOverride: function (serviceName: string) {
      const DS_API = '/ds/api';
      const DS_OPEN = '/ds/open';
      const DS_OVERBLIK = '/ds/overblik'; // from PRO
      const SK_API = '/sk/api/api';
      const SK_OPEN = '/sk/open/open';
      const TEMP = '/temp';

      const paths = { base: DS_API, exceptions: undefined } as BasePaths;

      // Map contains store methods to which base prefix path they should use for the http call
      // const exampleExceptions = new Map<string, string>([
      //   ['exampleUuidDelete', '/ds/kage']
      // ]);
      // Path object contains base prefix path and exceptions. If an exception path is not defined
      // then the base prefix path will be used for the http call

      const dsApiPaths = {
        base: DS_API + TEMP,
        exceptions: undefined
      } as BasePaths;

      const skApiPaths = {
        base: SK_API + TEMP,
        exceptions: undefined
      } as BasePaths;

      const skOpenPaths = {
        base: SK_OPEN + TEMP,
        exceptions: undefined
      } as BasePaths;

      const brugerExceptions = new Map<string, string>();
      brugerExceptions.set('brugerLogoutGet', DS_OPEN + TEMP);
      brugerExceptions.set('brugerMitidGet', DS_OPEN + TEMP);
      brugerExceptions.set('brugerMitidPut', DS_OPEN + TEMP);
      brugerExceptions.set('brugerMitpfaAccessMaxreturnedGet', DS_OVERBLIK);

      const mobilesigningPaths = {
        base: DS_API + TEMP,
        exceptions: null
      } as BasePaths;

      const brugerPaths = {
        base: DS_API + TEMP,
        exceptions: brugerExceptions
      } as BasePaths;

      const systemadministrationExceptions = new Map<string, string>();
      systemadministrationExceptions.set(
        'systemadministrationHentbrugerundersoegelseadminGet',
        DS_API
      );
      systemadministrationExceptions.set(
        'systemadministrationHentlogningafdatacsvStartDatoSlutDatoGet',
        DS_API
      );
      systemadministrationExceptions.set(
        'systemadministrationLocpayscaleGet',
        DS_API
      );
      systemadministrationExceptions.set(
        'systemadministrationLocpayscalePost',
        DS_API
      );
      systemadministrationExceptions.set(
        'systemadministrationLocpayscaleScaleActiveFromGet',
        DS_API
      );
      systemadministrationExceptions.set(
        'systemadministrationOpdaterbrugerundersoegelseadminPost',
        DS_API
      );
      systemadministrationExceptions.set(
        'systemadministrationRatelimitGet',
        DS_API
      );
      systemadministrationExceptions.set(
        'systemadministrationRatelimitPut',
        DS_API
      );
      const systemadministrationPaths = {
        base: DS_API + TEMP,
        exceptions: systemadministrationExceptions
      } as BasePaths;

      const basePaths = {
        beneficiary: dsApiPaths,
        individualcustomerdossier: dsApiPaths,
        individualcustomerprofile: dsApiPaths,
        mobilesigning: mobilesigningPaths,
        skadesanmeldelse: skApiPaths,
        openforbusiness: skApiPaths,
        logout: skOpenPaths,
        bruger: brugerPaths,
        uploadskadesanmeldelsedocument: skApiPaths,
        investering: dsApiPaths,
        kundekapital: dsApiPaths,
        climatefootprint: dsApiPaths,
        dynamicadvising: dsApiPaths,
        systemadministration: systemadministrationPaths,
        pensionskunde: dsApiPaths,
        fordele: dsApiPaths,
        pensioninfo: dsApiPaths,
        onboarding: dsApiPaths,
        spoergeskema: skApiPaths,
        prisliste: dsApiPaths,
        brugerundersoegelse: dsApiPaths,
        externaltransfer: dsApiPaths,
        customerprofile: dsApiPaths,
        pensionsrapport: dsApiPaths,
        overfoersel: dsApiPaths,
        savings: dsApiPaths,
        savingsplan: dsApiPaths,
        savingsreturn: dsApiPaths,
        indbetalingsaftaler: dsApiPaths,
        microappconfiguration: dsApiPaths,
        onlineaccount: dsApiPaths,
        customerdigitalidentity: dsApiPaths,
        mineaendringer: dsApiPaths,
        udbetalingsplan: dsApiPaths,
        forsikring: dsApiPaths,
        forsikringsoversigt: dsApiPaths,
        pensionsplan: dsApiPaths,
        indbetaling: dsApiPaths,
        forsikringsguide: dsApiPaths,
        digitalsalg: dsApiPaths
      };

      // if custom prefix path have been defined for the service, then return them. Otherwise, just return
      // a default custom path.
      return basePaths[serviceName] || paths;
    },
    throwOnValidationError: false
  };
  return new Configuration(params);
}

export function pensionInfoUrl(): string {
  return '/ds/api/temp/pensioninfo';
}
