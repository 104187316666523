<co-accordion [title]="'DL.OS01.C270'" [initExpanded]="true">
  <table
    class="pfa-table pfa-table--compact table-responsive align-left--column-1 align-right--column-2"
  >
    <thead class="hide--palm">
      <tr>
        <th scope="col" [coContent]="'DL.OS01.C425'">produkt</th>
        <th scope="col" [coContent]="'DL.OS01.C426'">beløb</th>
      </tr>
    </thead>
    <tbody>
      <!-- SAMLET VAERDI-->
      <mitpfa-savings-policy-details-simple
        [headerContentId]="'DL.OS01.C273'"
        [headerSubst]="savingsPolicyDetails.fraDato"
        [tooltipContentId]="'DL.OS01.I273'"
        [total]="savingsPolicyDetails.vaerdiAfOpsparingPrimo.total"
      >
      </mitpfa-savings-policy-details-simple>

      <!-- INDBETALING -->
      <mitpfa-savings-policy-details-line-element
        *ngIf="savingsPolicyDetails.indbetalinger?.linieElementer?.length > 0"
        [headerContentId]="'DL.OS01.C345'"
        [total]="savingsPolicyDetails.indbetalinger.total"
        [lineElements]="savingsPolicyDetails.indbetalinger.linieElementer"
      >
      </mitpfa-savings-policy-details-line-element>

      <!-- ARBEJDSMARKEDSBIDRAG -->
      <mitpfa-savings-policy-details-simple
        *ngIf="savingsPolicyDetails.arbejdsmarkedsbidrag !== 0"
        [headerContentId]="'DL.OS01.C276'"
        [tooltipContentId]="'DL.OS01.I276'"
        [total]="savingsPolicyDetails.arbejdsmarkedsbidrag"
      >
      </mitpfa-savings-policy-details-simple>

      <!-- BETALING FOR FORSIKRINGER-->
      <mitpfa-savings-policy-details-line-element
        *ngIf="
          savingsPolicyDetails.betalingForsikringer?.linieElementer?.length > 0
        "
        [headerContentId]="'DL.OS01.C277'"
        [tooltipContentId]="'DL.OS01.I277'"
        [total]="savingsPolicyDetails.betalingForsikringer.total"
        [lineElements]="
          savingsPolicyDetails.betalingForsikringer?.linieElementer
        "
      >
      </mitpfa-savings-policy-details-line-element>

      <!-- BETALING FOR ADM-->
      <mitpfa-savings-policy-details-line-element
        *ngIf="
          savingsPolicyDetails.betalingForAdministrationOgService
            ?.administration?.length > 0
        "
        [headerContentId]="'DL.OS01.C283'"
        [total]="savingsPolicyDetails.betalingForAdministrationOgService.total"
        [lineElements]="
          savingsPolicyDetails.betalingForAdministrationOgService.administration
        "
      >
      </mitpfa-savings-policy-details-line-element>

      <!-- ØVRIGE POSTER -->
      <mitpfa-savings-policy-details-line-element
        *ngIf="
          savingsPolicyDetails.oevrigePoster?.posteringerDerSkalBetalesPalSkatAf
            ?.length > 0
        "
        [headerContentId]="'DL.OS01.C289'"
        [total]="savingsPolicyDetails.oevrigePoster.total"
        [lineElements]="
          savingsPolicyDetails.oevrigePoster.posteringerDerSkalBetalesPalSkatAf
        "
      >
      </mitpfa-savings-policy-details-line-element>

      <!-- ÆNDRING PGA ÆGTEFÆLLENS DØD -->
      <mitpfa-savings-policy-details-simple
        *ngIf="savingsPolicyDetails.aendringPgaAegtefaellesDoed !== 0"
        [headerContentId]="'DL.OS01.C309'"
        [headerSubst]="savingsPolicyDetails.fraDato"
        [tooltipContentId]="'DL.OS01.I309'"
        [total]="savingsPolicyDetails.aendringPgaAegtefaellesDoed"
      >
      </mitpfa-savings-policy-details-simple>

      <!-- UDBETALINGER -->
      <mitpfa-savings-policy-details-line-element
        *ngIf="savingsPolicyDetails.udbetalinger?.linieElementer?.length > 0"
        [headerContentId]="'DL.OS01.C310'"
        [tooltipContentId]="'DL.OS01.I310'"
        [total]="savingsPolicyDetails.udbetalinger.total"
        [lineElements]="savingsPolicyDetails.udbetalinger.linieElementer"
      >
      </mitpfa-savings-policy-details-line-element>

      <!-- RENTE -->
      <mitpfa-savings-policy-details-line-element
        *ngIf="savingsPolicyDetails.rente?.linieElementer?.length > 0"
        [headerContentId]="'DL.OS01.C305'"
        [total]="savingsPolicyDetails.rente.total"
        [lineElements]="savingsPolicyDetails.rente.linieElementer"
        [lineElementSubst]="savingsPolicyDetails.rente.kundekapitalAar"
      >
      </mitpfa-savings-policy-details-line-element>

      <!-- PENSIONSAFKASTSKAT -->
      <mitpfa-savings-policy-details-simple
        *ngIf="
          savingsPolicyDetails.afregnetPensionsafkastskat?.total !== 0 &&
          !savingsPolicyDetails.afregnetPensionsafkastskat?.groenland
        "
        [headerContentId]="'DL.OS01.C316'"
        [headerSubst]="savingsPolicyDetails.fraDato"
        [tooltipContentId]="palTooltip"
        [total]="savingsPolicyDetails.afregnetPensionsafkastskat.total"
      >
      </mitpfa-savings-policy-details-simple>
      <mitpfa-savings-policy-details-line-element
        *ngIf="savingsPolicyDetails.afregnetPensionsafkastskat?.groenland"
        [headerContentId]="'DL.OS01.C407'"
        [tooltipContentId]="unpaidPALTooltip"
        [lineElements]="
          savingsPolicyDetails.afregnetPensionsafkastskat.linieElementer
        "
      >
      </mitpfa-savings-policy-details-line-element>

      <!--DEPOTFORØGELSE VED INVALIDITET -->
      <mitpfa-savings-policy-details-simple
        *ngIf="savingsPolicyDetails.depotaendringVedInvaliditet !== 0"
        [headerContentId]="'DL.OS01.C317'"
        [headerSubst]="savingsPolicyDetails.fraDato"
        [tooltipContentId]="'DL.OS01.I317'"
        [total]="savingsPolicyDetails.depotaendringVedInvaliditet"
      >
      </mitpfa-savings-policy-details-simple>

      <!-- SAMLET VÆRDI-->
      <mitpfa-savings-policy-details-simple
        [resultLine]="true"
        [headerContentId]="'DL.OS01.C318'"
        [headerSubst]="savingsPolicyDetails.tilDato"
        [total]="savingsPolicyDetails.vaerdiAfOpsparingUltimo.total"
      >
      </mitpfa-savings-policy-details-simple>

      <!-- TILBAGEFØRSEL -->
      <mitpfa-savings-policy-details-transfer-back
        *ngIf="
          savingsPolicyDetails.tilbageFoerselInvalidedepotVedOphoerAfInvaliditet !==
          0
        "
        [savingsPolicyDetails]="savingsPolicyDetails"
      >
      </mitpfa-savings-policy-details-transfer-back>

      <!--FORUDBETALT INDBETALING -->
      <ng-container *ngIf="savingsPolicyDetails.forudbetaltIndbetaling > 0">
        <tr class="forced-thin-border">
          <td
            [attr.data-label]="'DL.OS01.C425' | content"
            class="responsiv-top-padding"
          >
            <co-table-accordion-control
              [contentId]="'DL.OS01.C320'"
              (expandedChange)="toggleExpandedPrepaid($event)"
            ></co-table-accordion-control>
          </td>
          <td
            class="align--right text--bold"
            [attr.data-label]="'DL.OS01.C426' | content"
          >
            {{
              savingsPolicyDetails.samletVaerdiOpsparingInklusivForudbetaltIndbetaling
                | numberFormat: 2
            }}
            <span
              *ngIf="
                savingsPolicyDetails.samletVaerdiOpsparingInklusivForudbetaltIndbetaling
              "
              [coContent]="'Global.kroner'"
            ></span>
          </td>
        </tr>
        <ng-container *ngIf="expandedPrepaid">
          <tr class="forced-thin-border">
            <td [attr.data-label]="'DL.OS01.C425' | content">
              <span
                [coContent]="'DL.OS01.C319'"
                [coContentSubst]="
                  savingsPolicyDetails.forudbetaltIndbetalingTilDato
                "
              ></span>
              <co-tooltip [text]="'DL.OS01.I319'"></co-tooltip>
            </td>
            <td
              class="align--right"
              [attr.data-label]="'DL.OS01.C426' | content"
            >
              {{
                savingsPolicyDetails.forudbetaltIndbetaling | numberFormat: 2
              }}
              <span [coContent]="'Global.kroner'"></span>
            </td>
          </tr>
        </ng-container>
      </ng-container>
    </tbody>
  </table>
</co-accordion>

<co-bodytext>
  <!-- OPSPARING FRITAGET FRA PAL -->
  <div
    *ngIf="savingsPolicyDetails.opsparingFritagetForPAL > 0"
    class="spacing-unit--over"
  >
    <div class="text--bold" [coContent]="'DL.OS01.C332'"></div>
    <div
      [coContent]="'DL.OS01.C323'"
      [coContentSubst]="
        savingsPolicyDetails.opsparingFritagetForPAL | numberFormat: 2
      "
    ></div>
  </div>

  <!-- OVERFØRT FRA KUNDEKAPITAL -->
  <div
    *ngIf="savingsPolicyDetails.overfoertFraKundekap > 0"
    class="spacing-unit--over"
  >
    <div class="text--bold" [coContent]="'DL.OS01.C333'"></div>
    <div
      [coContent]="'DL.OS01.C324'"
      [coContentSubst]="
        savingsPolicyDetails.overfoertFraKundekap | numberFormat: 2
      "
    ></div>
  </div>

  <!-- Kundekapital-OPSPARING -->
  <div
    *ngIf="savingsPolicyDetails.kundekapitalOpsparing > 0"
    class="spacing-unit--over"
  >
    <div class="text--bold" [coContent]="'DL.OS01.C418'"></div>
    <div
      [coContent]="'DL.OS01.C419'"
      [coContentSubst]="
        savingsPolicyDetails.tilDato +
        '|' +
        (savingsPolicyDetails.vaerdiAfOpsparingUltimo.total | numberFormat: 2) +
        '|' +
        (savingsPolicyDetails.kundekapitalOpsparing | numberFormat: 2)
      "
    ></div>
  </div>

  <!-- INDIVIDUEL ÆGTEFÆLLEPENSION -->
  <div
    *ngIf="savingsPolicyDetails.individuelAegtefaellepension"
    class="spacing-unit--over"
  >
    <div class="text--bold" [coContent]="'DL.OS01.C334'"></div>
    <div [coContent]="'DL.OS01.C325'"></div>
  </div>
</co-bodytext>
