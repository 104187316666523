/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Skattekode } from './skattekode';
import { SkattekodeSchema } from './skattekode';
import { EnhedType } from './enhedType';
import { EnhedTypeSchema } from './enhedType';
import { LocScale } from './locScale';
import { LocScaleSchema } from './locScale';
import { ForsikringstalSlider } from './forsikringstalSlider';
import { ForsikringstalSliderSchema } from './forsikringstalSlider';
import * as JoiObj from "joi";


    export const InsuranceNumberLocSchema = JoiObj.object({
        forsikringstalType:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        advarselGraenseForsikringstal:
            
                    JoiObj.number()
                


.required()


        ,
        atypiskValg:
            
                    JoiObj.boolean()
                


.required()


        ,
        currentForsikringstal:
            
                    JoiObj.number()
                


.required()


        ,
        currentForsikringstalTAEBrutto:
            
                    JoiObj.number()
                


.required()


        ,
        daekningBeloebPrPolice:
            
                    JoiObj.number()
                

.allow(null)



        ,
        daekningProcentPrPolice:
            
                    JoiObj.number()
                

.allow(null)



        ,
        daekningsId:
            
                    JoiObj.number()
                


.required()


        ,
        daekningsspaendEnhed:
            
        JoiObj.link('#EnhedTypeSchema')


.required()


        ,
        forsikringstalBruttobeloeb:
            
                    JoiObj.number()
                


.required()


        ,
        forsikringstalMax:
            
                    JoiObj.number()
                


.required()


        ,
        forsikringstalNettobeloeb:
            
                    JoiObj.number()
                


.required()


        ,
        frivilligdaekning:
            
                    JoiObj.number()
                


.required()


        ,
        infoTekst:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        locked:
            
                    JoiObj.boolean()
                


.required()


        ,
        minimumsdaekning:
            
                    JoiObj.number()
                


.required()


        ,
        muligeSkattekoder:
                JoiObj.array().items(
        
        JoiObj.link('#SkattekodeSchema')

        )
        

.required()


        ,
        pris:
            
                    JoiObj.number()
                


.required()


        ,
        tillaegsDaekningBeloeb:
            
                    JoiObj.number()
                


.required()


        ,
        valgtSkattekode:
            
        JoiObj.link('#SkattekodeSchema')

.allow(null)



        ,
        visTvungenSkattekodeText:
            
                    JoiObj.boolean()
                


.required()


        ,
        locScale:
            
        JoiObj.link('#LocScaleSchema')

.allow(null)



        
    })
                .shared(EnhedTypeSchema)
                .shared(SkattekodeSchema)
                .shared(SkattekodeSchema)
                .shared(LocScaleSchema)
.id('InsuranceNumberLocSchema')

export interface InsuranceNumberLoc extends ForsikringstalSlider { 
    forsikringstalType: string;
    locScale?: LocScale;
}
export namespace InsuranceNumberLoc {
}


