/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ServiceInfo } from './serviceInfo';
import { ServiceInfoSchema } from './serviceInfo';
import { EngagementEnhedType } from './engagementEnhedType';
import { EngagementEnhedTypeSchema } from './engagementEnhedType';
import { Aendringsdatatype } from './aendringsdatatype';
import { AendringsdatatypeSchema } from './aendringsdatatype';
import { Aendringslinje } from './aendringslinje';
import { AendringslinjeSchema } from './aendringslinje';
import { ResourceModel } from './resourceModel';
import { ResourceModelSchema } from './resourceModel';
import { DaekningsKlausul } from './daekningsKlausul';
import { DaekningsKlausulSchema } from './daekningsKlausul';
import * as JoiObj from "joi";


    export const AendringSchema = JoiObj.object({
        serviceInfo:
            
        JoiObj.link('#ServiceInfoSchema')

.allow(null)



        ,
        aendringBestillingDato:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        aendringsdatatyper:
                JoiObj.array().items(
        
        JoiObj.link('#AendringsdatatypeSchema')

        )
        

.required()


        ,
        antagelsesgruppe:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        daekningKlausuler:
                JoiObj.array().items(
        
        JoiObj.link('#DaekningsKlausulSchema')

        )
        

.required()


        ,
        daekningsgivendeLoen:
            
                    JoiObj.number()
                


.required()


        ,
        engagementEnhed:
            
        JoiObj.link('#EngagementEnhedTypeSchema')


.required()


        ,
        engagementEnhedAendringId:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        forsikring:
                JoiObj.array().items(
        
        JoiObj.link('#AendringslinjeSchema')

        )
        

.required()


        ,
        helbredsblanketId:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        investering:
                JoiObj.array().items(
        
        JoiObj.link('#AendringslinjeSchema')

        )
        

.required()


        ,
        kundekapital:
                JoiObj.array().items(
        
        JoiObj.link('#AendringslinjeSchema')

        )
        

.required()


        ,
        opsparing:
                JoiObj.array().items(
        
        JoiObj.link('#AendringslinjeSchema')

        )
        

.required()


        ,
        pensionsaftaleId:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        
    })
                .shared(ServiceInfoSchema)
                .shared(AendringsdatatypeSchema)
                .shared(DaekningsKlausulSchema)
                .shared(EngagementEnhedTypeSchema)
                .shared(AendringslinjeSchema)
                .shared(AendringslinjeSchema)
                .shared(AendringslinjeSchema)
                .shared(AendringslinjeSchema)
.id('AendringSchema')

export interface Aendring extends ResourceModel { 
    aendringBestillingDato: string;
    aendringsdatatyper: Array<Aendringsdatatype>;
    antagelsesgruppe: string;
    daekningKlausuler: Array<DaekningsKlausul>;
    daekningsgivendeLoen: number;
    engagementEnhed: EngagementEnhedType;
    engagementEnhedAendringId: string;
    forsikring: Array<Aendringslinje>;
    helbredsblanketId: string;
    investering: Array<Aendringslinje>;
    kundekapital: Array<Aendringslinje>;
    opsparing: Array<Aendringslinje>;
    pensionsaftaleId: string;
}
export namespace Aendring {
}


