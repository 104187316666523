/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const DynamicAdvisingAutoAdjustmentStatusSchema = JoiObj.string().valid(
        'NONE'
        ,
        'AUTO_ADJUSTMENT'
        ,
        'LOC_80'
        ,
        'LOC_100'
        
    )
.id('DynamicAdvisingAutoAdjustmentStatusSchema')

export type DynamicAdvisingAutoAdjustmentStatus = 'NONE' | 'AUTO_ADJUSTMENT' | 'LOC_80' | 'LOC_100';

export const DynamicAdvisingAutoAdjustmentStatus = {
    None: 'NONE' as DynamicAdvisingAutoAdjustmentStatus,
    AutoAdjustment: 'AUTO_ADJUSTMENT' as DynamicAdvisingAutoAdjustmentStatus,
    Loc80: 'LOC_80' as DynamicAdvisingAutoAdjustmentStatus,
    Loc100: 'LOC_100' as DynamicAdvisingAutoAdjustmentStatus
};

