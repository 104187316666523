import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  UrlTree,
  RouterStateSnapshot
} from '@angular/router';
import { BrugerStore, SavingsPlanStore } from '@pfa/gen';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class PensionEstimateGuard implements CanActivate {
  constructor(
    private readonly router: Router,
    private readonly savingsPlanStore: SavingsPlanStore,
    private readonly brugerStore: BrugerStore
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    return combineLatest({
      sp: this.savingsPlanStore.opsparingsplanGet(),
      user: this.brugerStore.brugerGet()
    }).pipe(
      map(data => {
        if (data.user.isPrivat && data.sp.alder >= 60) {
          return this.router.parseUrl('/pensionsplan/minudbetalingsplan');
        } else if (data.user.isRaadgiver && data.sp.alder >= 65) {
          return this.router.parseUrl('/pensionsplan/minudbetalingsplan');
        } else {
          return true;
        }
      })
    );
  }
}
