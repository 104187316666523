import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit
} from '@angular/core';
import { FinancialSituationApiService } from '@pfa/api';
import {
  InsuranceGuideConfigurationService,
  InsuranceGuideQuestion,
  InsuranceGuideQuestionValue
} from '../../insurance-guide-configuration.service';
import { GlobalWorkingService } from '@pfaform';
import { BehaviorSubject } from 'rxjs';
import { OekonomiDetaljer } from '@pfa/gen';

@Component({
  selector: 'mitpfa-insurance-guide-loc-step',
  templateUrl: './insurance-guide-loc-step.component.html',
  styleUrls: ['./insurance-guide-loc-step.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InsuranceGuideLocStepComponent implements OnInit {
  @Input() financialSituation: OekonomiDetaljer;
  @Input() currentQuestion: BehaviorSubject<InsuranceGuideQuestionValue>;

  constructor(
    private readonly globalWorkingService: GlobalWorkingService,
    private readonly financialSituationApiService: FinancialSituationApiService,
    private readonly insuranceGuideConfigurationService: InsuranceGuideConfigurationService
  ) {}

  ngOnInit(): void {
    this.globalWorkingService.hide();
  }

  public choose(willing: boolean): void {
    this.globalWorkingService.show();
    this.financialSituation.taeBetalingsvillig = willing;
    this.financialSituationApiService
      .updateFinancialSituation(this.financialSituation)
      .subscribe(() => {
        this.currentQuestion.next({
          value: this.insuranceGuideConfigurationService.showDebtStep
            ? InsuranceGuideQuestion.debt
            : InsuranceGuideQuestion.recommendation
        });
        this.globalWorkingService.hide();
        window.scroll(0, 0);
      });
  }

  public goBack(): void {
    this.currentQuestion.next({ value: InsuranceGuideQuestion.risk });
    window.scroll(0, 0);
  }
}
