import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { PensionPlanApiService } from '@pfa/api';
import { Observable } from 'rxjs';
import { PensionsInfoOrdninger } from '@pfa/gen';

@Injectable({
  providedIn: 'root'
})
export class PensionPlanResolver implements Resolve<PensionsInfoOrdninger> {
  constructor(private pensionPlanApiService: PensionPlanApiService) {}

  resolve():
    | PensionsInfoOrdninger
    | Observable<PensionsInfoOrdninger>
    | Promise<PensionsInfoOrdninger> {
    return this.pensionPlanApiService.getPolice();
  }
}
