import { MockDirective } from './test-utils';
import {
  Directive,
  ElementRef,
  Input,
  OnInit,
  Pipe,
  PipeTransform
} from '@angular/core';
import * as data from '../../../../../../mock/gets/ds/content/tekster/en.json';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[coContent]'
})
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class ContentDirectiveForStorybookMock implements OnInit {
  @Input() coContent: string;
  @Input() coContentSubst: string;

  constructor(private el: ElementRef) {}

  ngOnInit() {
    const texts = (data as any).default;
    let text = texts[this.coContent] || this.coContent;
    const values = this.coContentSubst
      ? this.coContentSubst.toString().split('|')
      : null;
    if (values) {
      values.forEach(
        (value, index) => (text = text.replace('{' + index + '}', value))
      );
    }
    this.el.nativeElement.innerHTML = texts[this.coContent] || this.coContent;
  }
}

export class ContentUtilServiceMock {
  getContent(contentId: string): string {
    return contentId;
  }
}

export const ContentDirectiveMock = MockDirective({
  selector: '[coContent]',
  inputs: ['coContent', 'coContentSubst']
});

export const AmountDirectiveMock = MockDirective({
  selector: '[coAmount]',
  inputs: ['coAmountDecimals', 'coAmountUnit']
});

export const PfaIntroBoxComponentMock = MockDirective({
  selector: 'co-introbox',
  inputs: ['title', 'description', 'isContentInline', 'noBackground']
});

export const BodytextComponentMock = MockDirective({
  selector: 'co-bodytext',
  inputs: ['alignCenter']
});

export const PfaAccordionComponentMock = MockDirective({
  selector: 'co-accordion',
  inputs: [
    'title',
    'titleOpen',
    'titleNote',
    'titleNoteOpen',
    'titleRight',
    'titleRightOpen',
    'initExpanded',
    'hideControls',
    'expanded',
    'accordionName'
  ]
});

export const LevelBarComponentMock = MockDirective({
  selector: 'co-level-bar',
  inputs: ['value', 'color', 'labelMin', 'labelMax']
});

export const AttachmentComponentMock = MockDirective({
  selector: 'co-attachment',
  inputs: ['attachments', 'channel', 'acceptFiles']
});

export const SliderFormComponentMock = MockDirective({
  selector: 'co-slider-form',
  inputs: ['label', 'type', 'sliderOptions']
});

export const SliderComponentMock = MockDirective({
  selector: 'co-slider',
  inputs: ['options', 'model', 'enableMaxLimitStyling', 'enableGreenColor']
});

export const CardComponentMock = MockDirective({
  selector: 'co-card',
  inputs: [
    'flat',
    'rounded',
    'showErrorMessage',
    'hideErrorBlocks',
    'errorMessage'
  ]
});

export const CardSpliHorizontalComponentMock = MockDirective({
  selector: 'co-card-split-horizontal',
  inputs: ['invert', 'emphasized', 'label']
});

export const AdvisorBoxComponentMock = MockDirective({
  selector: 'co-advisor-box'
});

export const SelectionWrapperComponentMock = MockDirective({
  selector: 'co-selection-wrapper'
});

export const PfaStepperComponentMock = MockDirective({
  selector: 'co-stepper',
  inputs: ['steps', 'selectedStepIndex', 'isNavigationEnabled']
});

export const LoadingComponentMock = MockDirective({
  selector: 'co-loading',
  inputs: ['show']
});

export const CardAnimatedStackItemComponentMock = MockDirective({
  selector: 'co-card-animated-stack-item',
  inputs: ['maxWidth', 'scrollToTopOnActivate']
});

export const CardAnimatedStackComponentMock = MockDirective({
  selector: 'co-card-animated-stack',
  inputs: ['activeIndex']
});

export const PrimaryNumberComponentMock = MockDirective({
  selector: 'co-primary-number',
  inputs: ['amount', 'unit', 'testId', 'showDecimals', 'disabled']
});

export const TooltipComponentMock = MockDirective({
  selector: 'co-tooltip',
  inputs: ['text']
});

export const PdfJSViewerMock = MockDirective({
  selector: 'ng2-pdfjs-viewer',
  inputs: ['pdfSrc', 'viewBookmark', 'pagemode']
});

export const PFASliderComponentMock = MockDirective({
  selector: 'co-slider',
  inputs: ['options', 'model', 'enableMaxLimitStyling', 'enableGreenColor']
});

export const WarningComponentMock = MockDirective({
  selector: 'co-warning'
});

export const InfoComponentMock = MockDirective({
  selector: 'co-info'
});

export const IconWithTextComponentMock = MockDirective({
  selector: 'co-icon-with-text'
});

export const globalWorkingServiceMock: any = {
  hide: () => null,
  show: () => null
};

@Pipe({
  name: 'content',
  pure: false
})
export class ContentPipeMock implements PipeTransform {
  transform(value: any) {
    return value;
  }
}

@Pipe({
  name: 'content',
  pure: false
})
export class ContentPipeForStorybookMock implements PipeTransform {
  transform(value: any) {
    const texts = (data as any).default;
    return texts[value] || value;
  }
}

@Pipe({ name: 'currencyFormat' })
export class CurrencyFormatPipeMock implements PipeTransform {
  transform(value: string): string {
    return value;
  }
}

@Pipe({ name: 'numberFormat' })
export class NumberFormatPipeMock implements PipeTransform {
  transform(value: string): string {
    return value;
  }
}

@Pipe({
  name: 'policyCompanyName'
})
export class PolicyCompanyNamePipeMock implements PipeTransform {
  transform(value: unknown) {
    return value;
  }
}

@Pipe({
  name: 'policyOrderMethod'
})
export class PolicyOrderMethodPipeMock implements PipeTransform {
  transform(value: unknown) {
    return value;
  }
}

@Pipe({
  name: 'policyPreconditionsWarning'
})
export class PolicyPreconditionsWarningPipeMock implements PipeTransform {
  transform(value: unknown) {
    return value;
  }
}

@Pipe({
  name: 'policyProductType'
})
export class PolicyProductTypePipeMock implements PipeTransform {
  transform(value: unknown) {
    return value;
  }
}

@Pipe({
  name: 'policyRecommendationInfo'
})
export class PolicyRecommendationInfoPipeMock implements PipeTransform {
  transform(value: unknown) {
    return value;
  }
}

@Pipe({
  name: 'policyWarning'
})
export class PolicyWarningPipeMock implements PipeTransform {
  transform(value: unknown) {
    return value;
  }
}

@Pipe({
  name: 'showWarrantiesForWarnings'
})
export class ShowWarrantiesForWarningsPipeMock implements PipeTransform {
  transform(value: unknown) {
    return value;
  }
}
