/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ServiceInfo } from './serviceInfo';
import { ServiceInfoSchema } from './serviceInfo';
import { SenesteIndbetaling } from './senesteIndbetaling';
import { SenesteIndbetalingSchema } from './senesteIndbetaling';
import { IndbetalingOversigtPolice } from './indbetalingOversigtPolice';
import { IndbetalingOversigtPoliceSchema } from './indbetalingOversigtPolice';
import { ResourceModel } from './resourceModel';
import { ResourceModelSchema } from './resourceModel';
import * as JoiObj from "joi";


    export const IndbetalingOversigtSchema = JoiObj.object({
        serviceInfo:
            
        JoiObj.link('#ServiceInfoSchema')

.allow(null)



        ,
        opgoerelsesdatoOpsparingKoloneTooltipContentId:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        policer:
                JoiObj.array().items(
        
        JoiObj.link('#IndbetalingOversigtPoliceSchema')

        )
        

.required()


        ,
        primaerAftaleId:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        senesteIndbetalinger:
                JoiObj.array().items(
        
        JoiObj.link('#SenesteIndbetalingSchema')

        )
        

.required()


        ,
        totalLatestPaymentsForYear:
            
                    JoiObj.number()
                


.required()


        ,
        totalSenesteIndbetalinger:
            
                    JoiObj.number()
                


.required()


        ,
        totalSenesteIndbetalingerSenesteMaaned:
            
                    JoiObj.number()
                


.required()


        
    })
                .shared(ServiceInfoSchema)
                .shared(IndbetalingOversigtPoliceSchema)
                .shared(SenesteIndbetalingSchema)
.id('IndbetalingOversigtSchema')

export interface IndbetalingOversigt extends ResourceModel { 
    opgoerelsesdatoOpsparingKoloneTooltipContentId: string;
    policer: Array<IndbetalingOversigtPolice>;
    primaerAftaleId?: string;
    senesteIndbetalinger: Array<SenesteIndbetaling>;
    totalLatestPaymentsForYear: number;
    totalSenesteIndbetalinger: number;
    totalSenesteIndbetalingerSenesteMaaned: number;
}

