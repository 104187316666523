/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const OptimeringFejlKodeSchema = JoiObj.string().valid(
        'KUN_FRIVILILIG_INDBETALING_AENDRING_TILLADT'
        ,
        'SKATTEFRI_LOEBENDE_UDBETALING'
        ,
        'DINE_OPLYSNING_MANGLENDE'
        ,
        'IKKE_FAERDIGBEHANDLET_AENDRING'
        ,
        'LSS_FEJLKODE'
        ,
        'ANDRE_DATA_FEJL'
        ,
        'UDBETALING_FORLOEB_ID_FINDES_IKKE'
        ,
        'PENSIONSALDER_IKKE_TILLADT'
        ,
        'SERVICE_FEJL'
        ,
        'AENDRING_IKKE_GEMT'
        
    )
.id('OptimeringFejlKodeSchema')

export type OptimeringFejlKode = 'KUN_FRIVILILIG_INDBETALING_AENDRING_TILLADT' | 'SKATTEFRI_LOEBENDE_UDBETALING' | 'DINE_OPLYSNING_MANGLENDE' | 'IKKE_FAERDIGBEHANDLET_AENDRING' | 'LSS_FEJLKODE' | 'ANDRE_DATA_FEJL' | 'UDBETALING_FORLOEB_ID_FINDES_IKKE' | 'PENSIONSALDER_IKKE_TILLADT' | 'SERVICE_FEJL' | 'AENDRING_IKKE_GEMT';

export const OptimeringFejlKode = {
    KunFrivililigIndbetalingAendringTilladt: 'KUN_FRIVILILIG_INDBETALING_AENDRING_TILLADT' as OptimeringFejlKode,
    SkattefriLoebendeUdbetaling: 'SKATTEFRI_LOEBENDE_UDBETALING' as OptimeringFejlKode,
    DineOplysningManglende: 'DINE_OPLYSNING_MANGLENDE' as OptimeringFejlKode,
    IkkeFaerdigbehandletAendring: 'IKKE_FAERDIGBEHANDLET_AENDRING' as OptimeringFejlKode,
    LssFejlkode: 'LSS_FEJLKODE' as OptimeringFejlKode,
    AndreDataFejl: 'ANDRE_DATA_FEJL' as OptimeringFejlKode,
    UdbetalingForloebIdFindesIkke: 'UDBETALING_FORLOEB_ID_FINDES_IKKE' as OptimeringFejlKode,
    PensionsalderIkkeTilladt: 'PENSIONSALDER_IKKE_TILLADT' as OptimeringFejlKode,
    ServiceFejl: 'SERVICE_FEJL' as OptimeringFejlKode,
    AendringIkkeGemt: 'AENDRING_IKKE_GEMT' as OptimeringFejlKode
};

