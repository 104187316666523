/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const OpsparingOptimeringFejlKodeSchema = JoiObj.string().valid(
        'LOEN_FINDES_IKKE'
        ,
        'BEREGNING_FEJL'
        ,
        'HAR_IKKE_ADGANG'
        
    )
.id('OpsparingOptimeringFejlKodeSchema')

export type OpsparingOptimeringFejlKode = 'LOEN_FINDES_IKKE' | 'BEREGNING_FEJL' | 'HAR_IKKE_ADGANG';

export const OpsparingOptimeringFejlKode = {
    LoenFindesIkke: 'LOEN_FINDES_IKKE' as OpsparingOptimeringFejlKode,
    BeregningFejl: 'BEREGNING_FEJL' as OpsparingOptimeringFejlKode,
    HarIkkeAdgang: 'HAR_IKKE_ADGANG' as OpsparingOptimeringFejlKode
};

