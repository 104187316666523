import { Injectable } from '@angular/core';
import {
  InputConfigCi,
  InputConfigItemCi,
  InsuranceAdjustmentState,
  InsuranceAdjustmentType,
  InsuranceAdjustmentValues,
  InsuranceGuideChecks
} from '@mitpfa/shared';
import { ContentUtilService, NumberFormatPipe } from '@pfa/core';
import { DaekningAendring, Forsikringsoversigt } from '@pfa/gen';
import { InsuranceUnit } from '@pfa/models';
import { InsuranceUtilService } from '@pfaform';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InsuranceAdjustmentCiService {
  followOurRecommendation: Subject<void>;
  adjustInsurance: Subject<void>;

  constructor(
    private readonly contentUtilService: ContentUtilService,
    private readonly numberFormat: NumberFormatPipe,
    private readonly insuranceUtilService: InsuranceUtilService
  ) {
    this.followOurRecommendation = new Subject<void>();
    this.adjustInsurance = new Subject<void>();
  }

  resetAdjustInsuranceSubject(): void {
    this.adjustInsurance.complete();
    this.adjustInsurance = new Subject<void>();
  }

  resetFollowOurRecommendationSubject(): void {
    this.followOurRecommendation.complete();
    this.followOurRecommendation = new Subject<void>();
  }

  private info2ContentId(ownCoverage: DaekningAendring): string {
    return ownCoverage.enhedType === 'Procent'
      ? this.contentUtilService.getContent('DL.TF01.C174', [
          this.numberFormat.transform(ownCoverage.daekningsBeloeb, 0),
          this.numberFormat.transform(
            ownCoverage.daekningsspaendBeloeb?.endValue,
            0
          )
        ])
      : 'DL.TF01.C010';
  }

  createInputConfig(
    insuranceState: InsuranceAdjustmentState,
    insuranceAdjustmentChangesOriginal: InsuranceAdjustmentValues,
    insuranceGuideChecks: InsuranceGuideChecks,
    insuranceStateOriginal: InsuranceAdjustmentState
  ): InputConfigCi {
    const result = {
      headerContentId: 'DL.TF01.C28',
      self: this.createInputConfigItem(
        insuranceState,
        insuranceStateOriginal.insuranceOverview,
        InsuranceAdjustmentType.CI,
        'DL.TF01.C29',
        insuranceAdjustmentChangesOriginal.ciSelfAmount
      )
    } as InputConfigCi;

    const ciDataChild =
      insuranceState.insurance.tilpasForsikringstal
        .daekningAendringKritiskSygdomBoern;
    if (ciDataChild.skalVises) {
      result.child = this.createInputConfigItem(
        insuranceState,
        insuranceStateOriginal.insuranceOverview,
        InsuranceAdjustmentType.CI_CHILD,
        'DL.TF01.C34',
        insuranceAdjustmentChangesOriginal.ciChildAmount,
        'DL.TF01.I34'
      );
    }

    result.recommendation = insuranceState.insurance.pfaAnbefalerKS.value;
    const recommendationDiff = Math.abs(
      result.recommendation -
        insuranceState.insurance.tilpasForsikringstal
          .daekningAendringKritiskSygdomSelv.daekningsBeloeb
    );
    const followingRecommendation =
      recommendationDiff <= InsuranceUtilService.CI_VARIATION;

    const ciDataSelf =
      insuranceState.insurance.tilpasForsikringstal
        .daekningAendringKritiskSygdomSelv;

    result.showRecommendationButton =
      insuranceState.insurance.originalTilpasForsikringstal
        .daekningAendringKritiskSygdomSelv.enhedType === 'Beloeb' &&
      !insuranceGuideChecks.noGuide &&
      !insuranceGuideChecks.guideTooOld &&
      result.recommendation >= (result.self.amountMin ?? 0) &&
      result.recommendation <= (result.self.amountMax ?? 0) &&
      ciDataSelf.bruttobeloeb + ciDataSelf.externalCoverageAmount !==
        result.recommendation &&
      !followingRecommendation &&
      !ciDataSelf.disableEditing;

    result.followsRecommendation = followingRecommendation;

    return result;
  }

  private createInputConfigItem(
    insuranceState: InsuranceAdjustmentState,
    insuranceOverviewOriginal: Forsikringsoversigt,
    type: InsuranceAdjustmentType,
    headerContentId: string,
    amountOriginal: number | undefined,
    tooltipContentId?: string
  ): InputConfigItemCi {
    const insuranceOverview: Forsikringsoversigt =
      insuranceState.insuranceOverview;
    let coverageChange: DaekningAendring;
    let coverageChangeOriginal: DaekningAendring;
    if (type === InsuranceAdjustmentType.CI) {
      coverageChange =
        insuranceState.insurance.tilpasForsikringstal
          .daekningAendringKritiskSygdomSelv;
      coverageChangeOriginal =
        insuranceState.insurance.originalTilpasForsikringstal
          .daekningAendringKritiskSygdomSelv;
    } else {
      coverageChange =
        insuranceState.insurance.tilpasForsikringstal
          .daekningAendringKritiskSygdomBoern;
      coverageChangeOriginal =
        insuranceState.insurance.originalTilpasForsikringstal
          .daekningAendringKritiskSygdomBoern;
    }
    const isPercent = coverageChange.enhedType === 'Procent';
    let amountNote1 = '',
      amountNote1Original = '';
    if (isPercent) {
      let amount: number | undefined;
      if (type === InsuranceAdjustmentType.CI) {
        amount = this.insuranceUtilService.criticalIllnessTotalAmount(
          insuranceOverview,
          true
        );
        amountOriginal = this.insuranceUtilService.criticalIllnessTotalAmount(
          insuranceOverviewOriginal,
          true
        );
      } else {
        amount = coverageChange.bruttobeloeb;
      }
      amountNote1 = this.contentUtilService.getContent('DL.TF01.C172', [
        this.numberFormat.transform(amount, 0)
      ]);
      amountNote1Original = this.contentUtilService.getContent('DL.TF01.C172', [
        this.numberFormat.transform(amountOriginal, 0)
      ]);
    }
    const amountPrice = this.contentUtilService.getContent(
      'DL.TF01.C32',
      this.numberFormat.transform(coverageChange.pris / 12, 0)
    );
    const amountPriceOriginal = this.contentUtilService.getContent(
      'DL.TF01.C32',
      this.numberFormat.transform(coverageChangeOriginal.pris / 12, 0)
    );

    return {
      type,
      amountOriginal: coverageChangeOriginal.daekningsBeloeb,
      textContentId: headerContentId,
      tooltipContentId: tooltipContentId ? tooltipContentId : undefined,
      noteContentId: isPercent ? 'DL.TF01.C173' : 'DL.TF01.C30',
      amountMin: Math.ceil(coverageChange.daekningsspaend.startValue),
      amountMax: Math.floor(
        coverageChange.daekningsspaend.endValue ??
          coverageChange.daekningsspaend.startValue
      ),
      amount2Max: coverageChange.daekningsspaendBeloeb
        ? Math.floor(
            coverageChange.daekningsspaendBeloeb.endValue ??
              coverageChange.daekningsspaend.startValue
          )
        : undefined,
      amount: coverageChange.daekningsBeloeb,
      labelContentId: 'DL.TF01.C31',
      unit: isPercent ? InsuranceUnit.PERCENT : InsuranceUnit.KR,
      amountNote1ContentId: amountNote1,
      amountNote1ContentIdOriginal: amountNote1Original,
      amountNote2ContentId: this.info2ContentId(coverageChangeOriginal),
      amountPriceContentId: amountPrice,
      amountPriceContentIdOriginal: amountPriceOriginal,
      disableEditing: coverageChange.disableEditing
    } as InputConfigItemCi;
  }
}
