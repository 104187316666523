/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const HSSikringSchema = JoiObj.string().valid(
        'INGEN'
        ,
        'BASIS'
        ,
        'BASISOGUDVIDET'
        
    )
.id('HSSikringSchema')

export type HSSikring = 'INGEN' | 'BASIS' | 'BASISOGUDVIDET';

export const HSSikring = {
    Ingen: 'INGEN' as HSSikring,
    Basis: 'BASIS' as HSSikring,
    Basisogudvidet: 'BASISOGUDVIDET' as HSSikring
};

