/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { MaanedligAfkast } from './maanedligAfkast';
import { MaanedligAfkastSchema } from './maanedligAfkast';
import * as JoiObj from "joi";


    export const MaanedligAfkasterSchema = JoiObj.object({
        aar:
            
                    JoiObj.number()
                


.required()


        ,
        maaneder:
                JoiObj.array().items(
        
        JoiObj.link('#MaanedligAfkastSchema')

        )
        

.required()


        
    })
                .shared(MaanedligAfkastSchema)
.id('MaanedligAfkasterSchema')

export interface MaanedligAfkaster { 
    aar: number;
    maaneder: Array<MaanedligAfkast>;
}

