<co-card-animated-stack [activeIndex]="activeQuestionIndex">
  <co-card-animated-stack-item [scrollToTopOnActivate]="true" maxWidth="1024px">
    <co-card>
      <mitpfa-investment-guide-climate-relevance
        [investmentGuide]="investmentGuide"
        [broker]="broker"
        (guideComplete)="climateComplete.emit()"
      ></mitpfa-investment-guide-climate-relevance>
    </co-card>
  </co-card-animated-stack-item>
  <co-card-animated-stack-item [scrollToTopOnActivate]="true" maxWidth="1024px">
    <co-card>
      <mitpfa-investment-guide-climate-extended-focus
        [investmentGuide]="investmentGuide"
        [broker]="broker"
      ></mitpfa-investment-guide-climate-extended-focus>
    </co-card>
  </co-card-animated-stack-item>
  <co-card-animated-stack-item [scrollToTopOnActivate]="true" maxWidth="1024px">
    <co-card>
      <mitpfa-investment-guide-climate-willingness
        [investmentGuide]="investmentGuide"
        [broker]="broker"
      ></mitpfa-investment-guide-climate-willingness>
    </co-card>
  </co-card-animated-stack-item>
  <co-card-animated-stack-item [scrollToTopOnActivate]="true" maxWidth="1024px">
    <co-card>
      <mitpfa-investment-guide-climate-priority
        [investmentGuide]="investmentGuide"
        [broker]="broker"
        (guideComplete)="climateComplete.emit()"
      ></mitpfa-investment-guide-climate-priority>
    </co-card>
  </co-card-animated-stack-item>
</co-card-animated-stack>
<mitpfa-investment-guide-back-button
  (back)="goBack()"
></mitpfa-investment-guide-back-button>
