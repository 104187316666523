import { Component, Inject } from '@angular/core';
import {
  MAT_BOTTOM_SHEET_DATA,
  MatBottomSheetRef
} from '@angular/material/bottom-sheet';

@Component({
  selector: 'co-pdf-preview',
  templateUrl: 'pdf-preview.component.html',
  styleUrls: ['pdf-preview.component.scss']
})
export class PdfPreviewComponent {
  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA)
    public readonly data: {
      documentUrl: string;
      headline: string;
      documentName?: string;
    },
    private readonly bottomSheetRef: MatBottomSheetRef<PdfPreviewComponent>
  ) {}

  close() {
    this.bottomSheetRef.dismiss();
  }
}
