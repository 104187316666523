/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const ESkatDataSchema = JoiObj.object({
        aar:
            
                    JoiObj.number()
                


.required()


        ,
        aktievaerdi:
            
                    JoiObj.number()
                


.required()


        ,
        antalEjendomme:
            
                    JoiObj.number()
                


.required()


        ,
        consentId:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        ejendomsvaerdi:
            
                    JoiObj.number()
                


.required()


        ,
        ejerandelEjendom:
            
                    JoiObj.number()
                


.required()


        ,
        foraeldet:
            
                    JoiObj.boolean()
                


.required()


        ,
        gaeld:
            
                    JoiObj.number()
                


.required()


        ,
        indestaaendePengeinstitut:
            
                    JoiObj.number()
                


.required()


        
    })
.id('ESkatDataSchema')

export interface ESkatData { 
    aar: number;
    aktievaerdi: number;
    antalEjendomme: number;
    consentId: string;
    ejendomsvaerdi: number;
    ejerandelEjendom: number;
    foraeldet: boolean;
    gaeld: number;
    indestaaendePengeinstitut: number;
}

