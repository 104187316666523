<div class="savings-pension-in--container">
  <co-loading [show]="!savingsInitialized"></co-loading>

  <div *ngIf="savingsInitialized">
    <mitpfa-dashboard-savings-header
      class="spacing-unit--half-under"
      [content]="'DL.FS03.C05'"
      [hideArrow]="savingsServiceError"
    ></mitpfa-dashboard-savings-header>

    <div
      *ngIf="!savingsServiceError"
      class="clickable"
      (click)="goToMySavings()"
      data-test-id="frontpage-savings-pensionIn"
    >
      <div
        *ngIf="!allPoliciesWithError"
        data-test-id="frontpage-savings-combinedsavings"
      >
        <div
          class="amount--savings-pension-in"
          *ngIf="savings.totalOpsparing > 0"
        >
          <co-primary-number
            [amount]="savings.totalOpsparing"
          ></co-primary-number>
        </div>
        <div
          class="amount--savings-pension-in spacing-unit--under"
          *ngIf="savings.totalOpsparing <= 0"
        >
          <co-primary-number [amount]="0"></co-primary-number>
        </div>
        <div
          class="text--body"
          [coContent]="'DL.FS03.C06'"
          *ngIf="atLeastOnePoliceWithoutSavings"
        >
          Du har pensionsopsparing, som ikke er indregnet
        </div>
        <div
          class="text--body"
          [coContent]="'DL.FS03.C07'"
          *ngIf="savings.harKundekapitalOpsparing"
        >
          Opsparingsbeløb er inklusive KundeKapital
        </div>
      </div>
      <div *ngIf="allPoliciesWithError" class="blocking-error">
        <span class="error-grey-area"></span>
        <co-error>
          <span [coContent]="'DL.FS03.C12'"></span>
        </co-error>
      </div>
    </div>

    <div *ngIf="savingsServiceError; else normalError" class="blocking-error">
      <span class="error-grey-area"></span>
      <co-error>
        <span [coContent]="'DL.FS03.C12'"></span>
      </co-error>
    </div>
    <ng-template #normalError>
      <div
        class="policiesWithError"
        *ngIf="policiesWithError && !allPoliciesWithError"
      >
        <co-error>
          <span [coContent]="'DL.FS03.C40'"> </span>
        </co-error>
      </div>
    </ng-template>
  </div>
</div>
