import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'co-modal-content-preconditions',
  templateUrl: './preconditions-modal.component.html'
})
export class PreconditionsModalComponent {
  public selected: string;
  constructor(
    public popup: MatDialogRef<PreconditionsModalComponent>,
    private router: Router
  ) {}

  public closeButtonClicked() {
    this.popup.close('cancel');
  }

  public onTextClick(target): void {
    if (target instanceof Element) {
      while (target instanceof HTMLSpanElement) {
        target = target.parentElement;
      }
      const node = target.attributes['href'];
      if (node) {
        this.router.navigate([node.nodeValue]).finally(() => {
          this.popup.close('cancel');
        });
      }
    }
  }
}
