/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DropRegler } from './dropRegler';
import { DropReglerSchema } from './dropRegler';
import { ServiceInfo } from './serviceInfo';
import { ServiceInfoSchema } from './serviceInfo';
import { ResourceModel } from './resourceModel';
import { ResourceModelSchema } from './resourceModel';
import * as JoiObj from "joi";


    export const SletDropReglerRequestSchema = JoiObj.object({
        serviceInfo:
            
        JoiObj.link('#ServiceInfoSchema')

.allow(null)



        ,
        dropregler:
                JoiObj.array().items(
        
        JoiObj.link('#DropReglerSchema')

        )
        

.required()


        
    })
                .shared(ServiceInfoSchema)
                .shared(DropReglerSchema)
.id('SletDropReglerRequestSchema')

export interface SletDropReglerRequest extends ResourceModel { 
    dropregler: Array<DropRegler>;
}

