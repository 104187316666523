<svg
  id="Layer_1"
  data-name="Layer 1"
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 32 32"
>
  <g id="Canvas">
    <circle
      id="Large_circle"
      data-name="Large circle"
      cx="16"
      cy="16"
      r="16"
      fill="none"
    />
    <circle
      id="Small_circle"
      data-name="Small circle"
      cx="16"
      cy="16"
      r="12"
      fill="none"
    />
  </g>
  <g id="Icon">
    <ellipse
      cx="12.03"
      cy="9.77"
      rx="4.53"
      ry="2.27"
      fill="none"
      stroke="#000"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.13"
    />
    <path
      d="M16.57,12c0,1.26-2,2.27-4.54,2.27S7.5,13.29,7.5,12"
      fill="none"
      stroke="#000"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.13"
    />
    <line
      x1="16.57"
      y1="14.3"
      x2="16.57"
      y2="9.77"
      fill="none"
      stroke="#000"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.13"
    />
    <path
      d="M7.5,9.77V21.1c0,1.25,2,2.27,4.53,2.27a8.83,8.83,0,0,0,1.7-.17"
      fill="none"
      stroke="#000"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.13"
    />
    <path
      d="M7.5,18.83c0,1.26,2,2.27,4.53,2.27a8.83,8.83,0,0,0,1.7-.17"
      fill="none"
      stroke="#000"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.13"
    />
    <path
      d="M7.5,16.57c0,1.25,2,2.26,4.53,2.26a8.1,8.1,0,0,0,1.7-.17"
      fill="none"
      stroke="#000"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.13"
    />
    <path
      d="M7.5,14.3c0,1.25,2,2.27,4.53,2.27a8.83,8.83,0,0,0,1.7-.17"
      fill="none"
      stroke="#000"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.13"
    />
    <ellipse
      cx="19.97"
      cy="17.7"
      rx="4.53"
      ry="2.27"
      fill="none"
      stroke="#000"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.13"
    />
    <path
      d="M15.43,17.7v4.53c0,1.26,2,2.27,4.54,2.27s4.53-1,4.53-2.27V17.7"
      fill="none"
      stroke="#000"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.13"
    />
    <path
      d="M24.5,20c0,1.25-2,2.26-4.53,2.26s-4.54-1-4.54-2.26"
      fill="none"
      stroke="#000"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.13"
    />
  </g>
</svg>
