import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'mitpfa-insurance-guide-back-button',
  templateUrl: 'insurance-guide-back-button.component.html'
})
export class InsuranceGuideBackButtonComponent {
  @Output() back = new EventEmitter();

  public onClickBack(): void {
    this.back.emit();
    window.scroll(0, 0);
  }
}
