import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

export interface Pincode {
  pin: string;
  gentagPin: string;
}

export interface PinState {
  state: string;
}
@Injectable({
  providedIn: 'root'
})
export class PincodeApiService {
  constructor(private http: HttpClient) {}

  getPinState(): Observable<PinState> {
    const url = '/logon/haandtering/pin/state';
    return this.http.get<PinState>(url);
  }

  updatePin(pincode: Pincode): Observable<Pincode> {
    const url = '/logon/haandtering/pin';
    return this.http.post<Pincode>(url, pincode);
  }

  updatePinState(pinState: PinState): Observable<PinState> {
    const url = '/logon/haandtering/pin/state';
    return this.http.put<PinState>(url, pinState);
  }
}
