import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SystemadministrationStore } from '@pfa/gen';

@Injectable()
export class PensioncheckGuard implements CanActivate {
  constructor(
    private systemadministrationStore: SystemadministrationStore,
    private router: Router
  ) {}

  canActivate(): Observable<boolean | UrlTree> {
    return this.systemadministrationStore
      .systemadministrationHentkillswitchesadminGet()
      .pipe(
        map(ks => {
          if (ks.killswitchDetailsPensionCheck.open) {
            return true;
          } else {
            return this.router.parseUrl('/');
          }
        })
      );
  }
}
