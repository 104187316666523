import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  inject
} from '@angular/core';
import { AftaleAnbefaling, EksternOverfoerselAnbefaling } from '@pfa/gen';
import {
  CombineSavingsSummary,
  CombineSavingsSummaryType
} from '@mitpfa/shared/combine-savings/combine-savings.model';
import { CombineSavingsAcceptTransferService } from '@mitpfa/shared/combine-savings/accept-transfer/combine-savings-accept-transfer.service';

@Component({
  selector: 'mitpfa-combine-savings-summary',
  templateUrl: './combine-savings-summary.component.html'
})
export class CombineSavingsSummaryComponent implements OnInit {
  private readonly combineSavingsAcceptTransferService: CombineSavingsAcceptTransferService =
    inject(CombineSavingsAcceptTransferService);

  @Input() externalTransferData: EksternOverfoerselAnbefaling | null;
  @Input() combineSavingsSummary: CombineSavingsSummary;
  @Output() goToNextPage: EventEmitter<void> = new EventEmitter();

  public policies: AftaleAnbefaling[] = [];
  public summaryType = CombineSavingsSummaryType;

  public ngOnInit(): void {
    if (this.externalTransferData?.policer) {
      this.policies =
        this.combineSavingsAcceptTransferService.getTransferredPolicies(
          this.externalTransferData.policer,
          this.combineSavingsSummary?.transferredPoliciesUids
        );
    }
  }
}
