/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const SignedDocumentFormatSchema = JoiObj.string().valid(
        'XAdES'
        ,
        'PAdES'
        
    )
.id('SignedDocumentFormatSchema')

export type SignedDocumentFormat = 'XAdES' | 'PAdES';

export const SignedDocumentFormat = {
    XadEs: 'XAdES' as SignedDocumentFormat,
    PadEs: 'PAdES' as SignedDocumentFormat
};

