/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ProcentInterval } from './procentInterval';
import { ProcentIntervalSchema } from './procentInterval';
import { PrisListePakkeType } from './prisListePakkeType';
import { PrisListePakkeTypeSchema } from './prisListePakkeType';
import * as JoiObj from "joi";


    export const PrisGruppeSchema = JoiObj.object({
        fastPris:
            
                    JoiObj.number()
                


.required()


        ,
        indbetalingListe:
                JoiObj.array().items(
        
        JoiObj.link('#ProcentIntervalSchema')

        )
        

.required()


        ,
        opsparingListe:
                JoiObj.array().items(
        
        JoiObj.link('#ProcentIntervalSchema')

        )
        

.required()


        ,
        pakkeNavnType:
            
        JoiObj.link('#PrisListePakkeTypeSchema')


.required()


        
    })
                .shared(ProcentIntervalSchema)
                .shared(ProcentIntervalSchema)
                .shared(PrisListePakkeTypeSchema)
.id('PrisGruppeSchema')

export interface PrisGruppe { 
    fastPris: number;
    indbetalingListe: Array<ProcentInterval>;
    opsparingListe: Array<ProcentInterval>;
    pakkeNavnType: PrisListePakkeType;
}
export namespace PrisGruppe {
}


