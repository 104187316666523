import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit
} from '@angular/core';
import {
  MaeglerInfo,
  OpsparetVaerdi,
  QuestionReached,
  PensionsKundeGenerelleData
} from '@pfa/gen';
import { InvestmentGuideInfo } from '@pfa/models';
import { BehaviorSubject, Subscription } from 'rxjs';
import { pairwise, startWith } from 'rxjs/operators';
import {
  InvestmentGuideConfigurationService,
  InvestmentGuideQuestion,
  InvestmentGuideTradingExperience
} from '../investment-guide-configuration.service';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { InvestmentGuideService } from '@mitpfa/shared';
import { Choice } from './investment-guide-involvement-step.model';

@Component({
  selector: 'mitpfa-investment-guide-involvement-step',
  templateUrl: './investment-guide-involvement-step.component.html',
  styleUrls: ['./investment-guide-involvement-step.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InvestmentGuideInvolvementStepComponent
  implements OnInit, AfterViewInit
{
  @Input() investmentGuide: InvestmentGuideInfo;
  @Input() broker: MaeglerInfo;
  @Input() pensionInfoSavedValues: OpsparetVaerdi;
  @Input() pensionCustomer: PensionsKundeGenerelleData;

  public question = InvestmentGuideQuestion;
  public tradingExperience: {
    form: UntypedFormGroup;
    isDisabled$: BehaviorSubject<boolean>;
    previouslySelected: { [key: string]: boolean };
  } = {
    form: null,
    isDisabled$: new BehaviorSubject(true),
    previouslySelected: {}
  };
  public activeQuestionIndex = 0;
  public displayedQuestion: QuestionReached;

  public choices: Choice[] = [
    new Choice(['PFA_INVEST'], 'DL.INVGU01.C177', ['DL.INVGU01.C182'], true),
    new Choice(['YOU_INVEST'], 'DL.INVGU01.C179', ['DL.INVGU01.C333'])
  ];
  private subscriptions: { [subscribtionKey: string]: Subscription } = {};

  constructor(
    readonly investmentGuideConfigurationService: InvestmentGuideConfigurationService,
    readonly investmentGuideService: InvestmentGuideService,
    readonly cdRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.updatePreviouslySelected();
    this.displayedQuestion =
      this.investmentGuideService.getQuestionReached() ||
      InvestmentGuideQuestion.involvement;
    if (
      this.investmentGuideService.getQuestionReached() ===
      InvestmentGuideQuestion.tradingExperience
    ) {
      this.initTradingExperienceForm();
      this.activeQuestionIndex = 1;
    } else if (
      this.investmentGuideService.getQuestionReached() ===
      InvestmentGuideQuestion.numberOfTrades
    ) {
      this.activeQuestionIndex = 2;
    }

    this.subscriptions.displayedQuestion = this.investmentGuideService
      .getCurrentQuestion$()
      .subscribe(currentQuestion => {
        if (
          currentQuestion.value === InvestmentGuideQuestion.involvement ||
          currentQuestion.value ===
            InvestmentGuideQuestion.investmentKnowledge ||
          currentQuestion.value === InvestmentGuideQuestion.tradingExperience ||
          currentQuestion.value === InvestmentGuideQuestion.numberOfTrades
        ) {
          this.displayedQuestion = currentQuestion.value;
          this.cdRef.detectChanges();
        }
      });
  }

  ngAfterViewInit(): void {
    window.scroll(0, 0);
  }

  public choose(choice: Choice): void {
    this.investmentGuide.recommendationBasis.conceptChoice = choice.value;
    this.investmentGuideService.updateCurrentQuestion({
      value:
        choice.value.indexOf('YOU_INVEST') > -1
          ? InvestmentGuideQuestion.investmentKnowledge
          : this.investmentGuideService.getFirstEconomyQuestion(
              this.pensionCustomer,
              this.pensionInfoSavedValues,
              new Date()
            ),
      update: true
    });
    this.cdRef.detectChanges();
  }

  private updatePreviouslySelected(): void {
    if (!this.investmentGuide.recommendationBasis?.conceptChoice) {
      return;
    }

    const conceptChoice =
      this.investmentGuide.recommendationBasis.conceptChoice.join();

    this.choices.forEach(choice => {
      choice.previouslySelected = choice.value.join() === conceptChoice;
    });
  }

  public goToLegalPage(): void {
    this.investmentGuideService.updateCurrentQuestion({
      value: null
    });
    this.cdRef.detectChanges();
  }

  public goBack(): void {
    this.updatePreviouslySelected();
    switch (this.investmentGuideService.getQuestionReached()) {
      case InvestmentGuideQuestion.investmentKnowledge:
        this.investmentGuideService.updateCurrentQuestion({
          value: InvestmentGuideQuestion.involvement,
          back: true
        });
        break;
      case InvestmentGuideQuestion.tradingExperience:
        this.investmentGuideService.updateCurrentQuestion({
          value: InvestmentGuideQuestion.investmentKnowledge,
          back: true
        });
        this.activeQuestionIndex = 0;
        if (this.subscriptions.tradingExperienceFormChange) {
          this.subscriptions.tradingExperienceFormChange.unsubscribe();
        }
        break;
      case InvestmentGuideQuestion.numberOfTrades:
        this.investmentGuideService.updateCurrentQuestion({
          value: InvestmentGuideQuestion.tradingExperience,
          back: true
        });
        this.initTradingExperienceForm();
        this.activeQuestionIndex = 1;
        break;
    }
    this.cdRef.detectChanges();
  }

  public trackChoiceByFn(index: number): number {
    return index;
  }

  public setInvestmentKnowledge(value: boolean): void {
    this.investmentGuide.recommendationBasis.investmentKnowledge = value;
    this.initTradingExperienceForm();
    this.investmentGuideService.updateCurrentQuestion({
      value: InvestmentGuideQuestion.tradingExperience,
      update: true
    });
    this.activeQuestionIndex = 1;
    this.cdRef.detectChanges();
  }

  public setMoreThan10Trades(value: boolean): void {
    this.investmentGuide.recommendationBasis.moreThan10Trades = value;

    this.investmentGuideService.updateCurrentQuestion({
      value: this.investmentGuideService.getFirstEconomyQuestion(
        this.pensionCustomer,
        this.pensionInfoSavedValues,
        new Date()
      ),
      update: true
    });
    this.cdRef.detectChanges();
  }

  private initTradingExperienceForm(): void {
    this.tradingExperience.isDisabled$.next(true);

    this.tradingExperience.previouslySelected = {
      investmentFunds:
        this.investmentGuide.recommendationBasis?.tradingExperience?.indexOf(
          InvestmentGuideTradingExperience.investmentFunds
        ) > -1,
      equities:
        this.investmentGuide.recommendationBasis?.tradingExperience?.indexOf(
          InvestmentGuideTradingExperience.equities
        ) > -1,
      bonds:
        this.investmentGuide.recommendationBasis?.tradingExperience?.indexOf(
          InvestmentGuideTradingExperience.bonds
        ) > -1,
      others:
        this.investmentGuide.recommendationBasis?.tradingExperience?.indexOf(
          InvestmentGuideTradingExperience.others
        ) > -1,
      noExperience:
        this.investmentGuide.recommendationBasis?.tradingExperience?.length ===
        0
    };

    this.tradingExperience.form = new UntypedFormGroup({
      investmentFunds: new UntypedFormControl(
        this.tradingExperience.previouslySelected.investmentFunds
      ),
      equities: new UntypedFormControl(
        this.tradingExperience.previouslySelected.equities
      ),
      bonds: new UntypedFormControl(
        this.tradingExperience.previouslySelected.bonds
      ),
      others: new UntypedFormControl(
        this.tradingExperience.previouslySelected.others
      ),
      noExperience: new UntypedFormControl(
        this.tradingExperience.previouslySelected.noExperience
      )
    });

    if (
      this.tradingExperience.form.value.investmentFunds ||
      this.tradingExperience.form.value.equities ||
      this.tradingExperience.form.value.bonds ||
      this.tradingExperience.form.value.others ||
      this.tradingExperience.form.value.noExperience
    ) {
      this.tradingExperience.isDisabled$.next(false);
    }

    if (this.subscriptions.tradingExperienceFormChange) {
      this.subscriptions.tradingExperienceFormChange.unsubscribe();
    }

    this.subscriptions.tradingExperienceFormChange =
      this.tradingExperience.form.valueChanges
        .pipe(startWith(this.tradingExperience.form.value), pairwise())
        .subscribe(([previous, next]) => {
          this.tradingExperience.isDisabled$.next(
            !next.investmentFunds &&
              !next.equities &&
              !next.bonds &&
              !next.others &&
              !next.noExperience
          );

          if (!previous.noExperience && next.noExperience) {
            this.tradingExperience.form.patchValue({
              investmentFunds: false,
              equities: false,
              bonds: false,
              others: false
            });
          } else if (
            previous.noExperience &&
            (next.investmentFunds || next.equities || next.bonds || next.others)
          ) {
            this.tradingExperience.form.patchValue({
              noExperience: false
            });
          }
        });
  }

  public setTradingExperience(): void {
    const values = this.tradingExperience.form.value;
    const valueToSet = [];

    if (!values.noExperience) {
      Object.keys(values).forEach(key => {
        if (values[key]) {
          valueToSet.push(InvestmentGuideTradingExperience[key]);
        }
      });
    }

    this.investmentGuide.recommendationBasis.tradingExperience = valueToSet;
    this.investmentGuideService.updateCurrentQuestion({
      value: InvestmentGuideQuestion.numberOfTrades,
      update: true
    });
    this.activeQuestionIndex = 2;
    if (this.subscriptions.tradingExperienceFormChange) {
      this.subscriptions.tradingExperienceFormChange.unsubscribe();
    }
    this.cdRef.detectChanges();
  }
}
