/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DynamicAdvisingRecommendationCategoryType } from './dynamicAdvisingRecommendationCategoryType';
import { DynamicAdvisingRecommendationCategoryTypeSchema } from './dynamicAdvisingRecommendationCategoryType';
import { DynamicAdvisingDeviationType } from './dynamicAdvisingDeviationType';
import { DynamicAdvisingDeviationTypeSchema } from './dynamicAdvisingDeviationType';
import { DynamicAdvisingBasisType } from './dynamicAdvisingBasisType';
import { DynamicAdvisingBasisTypeSchema } from './dynamicAdvisingBasisType';
import { DynamicAdvisingOfferidType } from './dynamicAdvisingOfferidType';
import { DynamicAdvisingOfferidTypeSchema } from './dynamicAdvisingOfferidType';
import * as JoiObj from "joi";


    export const DynamicAdvisingOfferSchema = JoiObj.object({
        offerDeviationConsent:
            
        JoiObj.link('#DynamicAdvisingDeviationTypeSchema')


.required()


        ,
        offerHash:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        offerId:
            
        JoiObj.link('#DynamicAdvisingOfferidTypeSchema')


.required()


        ,
        offerNbaValue:
            
                    JoiObj.number()
                


.required()


        ,
        offerNbaValueQuality:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        offerRecommendationBasis:
            
        JoiObj.link('#DynamicAdvisingBasisTypeSchema')


.required()


        ,
        offerRecommendationCategory:
            
        JoiObj.link('#DynamicAdvisingRecommendationCategoryTypeSchema')


.required()


        ,
        offerSeverity:
            
                    JoiObj.number()
                .integer()


.required()


        
    })
                .shared(DynamicAdvisingDeviationTypeSchema)
                .shared(DynamicAdvisingOfferidTypeSchema)
                .shared(DynamicAdvisingBasisTypeSchema)
                .shared(DynamicAdvisingRecommendationCategoryTypeSchema)
.id('DynamicAdvisingOfferSchema')

export interface DynamicAdvisingOffer { 
    offerDeviationConsent: DynamicAdvisingDeviationType;
    offerHash: string;
    offerId: DynamicAdvisingOfferidType;
    offerNbaValue: number;
    offerNbaValueQuality: string;
    offerRecommendationBasis: DynamicAdvisingBasisType;
    offerRecommendationCategory: DynamicAdvisingRecommendationCategoryType;
    offerSeverity: number;
}
export namespace DynamicAdvisingOffer {
}


