/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ServiceInfo } from './serviceInfo';
import { ServiceInfoSchema } from './serviceInfo';
import { PayoutType } from './payoutType';
import { PayoutTypeSchema } from './payoutType';
import { PayoutTableRowText } from './payoutTableRowText';
import { PayoutTableRowTextSchema } from './payoutTableRowText';
import { ResourceModel } from './resourceModel';
import { ResourceModelSchema } from './resourceModel';
import * as JoiObj from "joi";


    export const PayoutTableRowSchema = JoiObj.object({
        serviceInfo:
            
        JoiObj.link('#ServiceInfoSchema')

.allow(null)



        ,
        agreementId:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        amounts:
            
        JoiObj.object().pattern(/./,     
                    JoiObj.number()
                

.allow(null)


)


.required()


        ,
        companyText:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        dataType:
            
        JoiObj.link('#PayoutTypeSchema')


.required()


        ,
        subRows:
                JoiObj.array().items(
        
        JoiObj.link('#PayoutTableRowSchema')

        )
        

.required()


        ,
        text:
            
        JoiObj.link('#PayoutTableRowTextSchema')


.required()


        
    })
                .shared(ServiceInfoSchema)
                .shared(PayoutTypeSchema)
                .shared(PayoutTableRowTextSchema)
.id('PayoutTableRowSchema')

export interface PayoutTableRow extends ResourceModel { 
    agreementId: string;
    amounts: { [key: string]: number; };
    companyText: string;
    dataType: PayoutType;
    subRows: Array<PayoutTableRow>;
    text: PayoutTableRowText;
}
export namespace PayoutTableRow {
}


