/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const DigitalSalgPrisListeRaekkeUdbetalingTypeSchema = JoiObj.string().valid(
        'BELOEB'
        ,
        'TJEK'
        ,
        'TEKST'
        
    )
.id('DigitalSalgPrisListeRaekkeUdbetalingTypeSchema')

export type DigitalSalgPrisListeRaekkeUdbetalingType = 'BELOEB' | 'TJEK' | 'TEKST';

export const DigitalSalgPrisListeRaekkeUdbetalingType = {
    Beloeb: 'BELOEB' as DigitalSalgPrisListeRaekkeUdbetalingType,
    Tjek: 'TJEK' as DigitalSalgPrisListeRaekkeUdbetalingType,
    Tekst: 'TEKST' as DigitalSalgPrisListeRaekkeUdbetalingType
};

