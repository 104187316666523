/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const FondStatusSchema = JoiObj.string().valid(
        'Aaben'
        ,
        'Lukket'
        ,
        'UnderAfvikling'
        ,
        'IkkeHandelsBar'
        
    )
.id('FondStatusSchema')

export type FondStatus = 'Aaben' | 'Lukket' | 'UnderAfvikling' | 'IkkeHandelsBar';

export const FondStatus = {
    Aaben: 'Aaben' as FondStatus,
    Lukket: 'Lukket' as FondStatus,
    UnderAfvikling: 'UnderAfvikling' as FondStatus,
    IkkeHandelsBar: 'IkkeHandelsBar' as FondStatus
};

