import { Injectable } from '@angular/core';
import { LoginMethod } from '@pfa/core';
import * as moment from 'moment';

type CookieType =
  | 'lastLoginMethod'
  | 'okToCookies'
  | 'loginmethod'
  | 'yesToSurvey'
  | 'noToSurvey'
  | 'adaShown';

@Injectable({
  providedIn: 'root'
})
export class CookieService {
  setCookieForOneYear(name: CookieType, value: string, path: string = '') {
    const expires = moment().add(1, 'year').toDate();
    const cpath: string = path ? `; path=${path}` : '';
    document.cookie = `${name}=${value}; expires=${expires}  ${cpath}`;
  }

  setCookieOnlyInSession(name: CookieType, value: string, path: string = '') {
    const cpath: string = path ? `; path=${path}` : '';
    document.cookie = `${name}=${value} ${cpath}`;
  }

  setCookie({
    name,
    value,
    expires
  }: {
    name: CookieType;
    value: string;
    expires?: Date;
  }) {
    let cookie = `${name}=${value};`;
    if (expires) {
      cookie = `${cookie} expires=${expires}`;
    }

    document.cookie = cookie;
  }

  getCookie(cname: CookieType | string) {
    const name = cname + '=';
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (const c of ca) {
      const cookie = c.trimStart();
      if (cookie.indexOf(name) === 0) {
        return cookie.substring(name.length, cookie.length);
      }
    }
    return null;
  }

  getAllCookieNames(): string[] {
    return document.cookie
      .split('; ')
      .map((cookie: string) => cookie.split('=')[0]);
  }

  removeCookie(name: CookieType | string) {
    if (this.getCookie(name)) {
      document.cookie =
        encodeURIComponent(name) +
        '=; expires=Thu, 01 Jan 1970 00:00:00 GMT' +
        '' +
        '';
    }
  }

  setMitIdLogin() {
    this.setCookieOnlyInSession('loginmethod', LoginMethod.MitID);
  }

  setPasswordLogin() {
    this.setCookieOnlyInSession('loginmethod', LoginMethod.Password);
  }
}
