<mat-select
  [value]="selectedCountry"
  (selectionChange)="selectCountry($event)"
  [panelClass]="customDropdownClass"
>
  <mat-select-trigger>
    <span
      [ngStyle]="{
        'background-image':
          'url(/assets/images/flags/' +
          selectedCountry?.code.toLowerCase() +
          '.svg)'
      }"
      class="fi fi-squared"
    ></span>
    {{ selectedCountry?.dialCode }}
  </mat-select-trigger>
  <mat-option *ngFor="let country of sortedCountries" [value]="country"
    ><span
      [ngStyle]="{
        'background-image':
          'url(/assets/images/flags/' + country?.code.toLowerCase() + '.svg)'
      }"
      class="fi fi-squared mr-2"
    ></span>
    <span class="mr-2">{{ getLocalizedCountryName(country) }}</span>
    <span>{{ country.dialCode }}</span></mat-option
  >
</mat-select>
