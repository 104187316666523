import { Component } from '@angular/core';
import { MiscTrackingService } from '@pfa/core';
import { Router } from '@angular/router';

@Component({
  selector: 'mitpfa-dashboard-savings-costs',
  templateUrl: './dashboard-savings-costs.component.html',
  styleUrls: ['./dashboard-savings-costs.component.scss']
})
export class DashboardSavingsCostsComponent {
  constructor(
    private trackingService: MiscTrackingService,
    private router: Router
  ) {}

  goToPricelist() {
    this.trackingService.trackNavigationCard(
      'Savings section',
      'Expenses',
      '/prisliste'
    );
    this.router.navigate(['/prisliste']);
  }
}
