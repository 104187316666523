import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import {
  DynamicAdvisingOfferidType,
  OekonomiDetaljer,
  PensionsKundeGenerelleData,
  PensionskundeStore,
  StamdataDetaljer
} from '@pfa/gen';
import {
  slideInRight,
  slideInRightWithDelay,
  slideOutRight,
  slideOutRightWithDelay
} from '@pfa/animations';
import { finalize, takeUntil } from 'rxjs/operators';
import {
  FamilyInformationApiService,
  FinancialSituationApiService
} from '@pfa/api';
import { combineLatest, Subject } from 'rxjs';
import { UntypedFormGroup } from '@angular/forms';
import { GlobalWorkingService } from '@pfaform';
import { FinancialSituationConfigurationService } from '@mitpfa/shared';

@Component({
  selector: 'mitpfa-pension-check-your-information',
  templateUrl: './pension-check-your-information.component.html',
  styleUrls: ['./pension-check-your-information.component.scss'],
  animations: [
    slideInRight,
    slideOutRight,
    slideOutRightWithDelay,
    slideInRightWithDelay
  ]
})
export class PensionCheckYourInformationComponent implements OnInit, OnDestroy {
  @Input() selectedCardId: DynamicAdvisingOfferidType;
  @Input() selectedCardType: string;
  @Input() selectedCardIdList: DynamicAdvisingOfferidType[];
  @Output() complete = new EventEmitter<void>();
  @Output() scrollToTop = new EventEmitter<void>();

  public pensionCustomer: PensionsKundeGenerelleData;
  public financialSituationForm = new UntypedFormGroup({});
  public financialSituation: OekonomiDetaljer;
  public familyInformation: StamdataDetaljer;
  public showDoneCard = false;
  public showError = false;
  private unsubscribe = new Subject<void>();
  private copySelectedCardIdList: DynamicAdvisingOfferidType[];
  private updateFamily = false;
  private updateEconomy = false;

  constructor(
    private readonly globalWorkingService: GlobalWorkingService,
    private readonly financialSituationApiService: FinancialSituationApiService,
    private readonly familyInformationApiService: FamilyInformationApiService,
    private readonly financialSituationConfigurationService: FinancialSituationConfigurationService,
    private readonly pensionskundeStore: PensionskundeStore
  ) {}

  ngOnInit() {
    this.copySelectedCardIdList = structuredClone(this.selectedCardIdList);
    this.globalWorkingService.show();
    combineLatest({
      pensionCustomer: this.pensionskundeStore.pensionskundeGet(),
      financialSituation: this.financialSituationApiService.get(),
      familyInformation: this.familyInformationApiService.getFamilyInformation()
    })
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        data => {
          this.pensionCustomer = data.pensionCustomer;
          this.financialSituation = data.financialSituation;
          this.familyInformation = data.familyInformation;
          this.globalWorkingService.hide();
        },
        () => {
          this.showError = true;
          this.globalWorkingService.hide();
        }
      );
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public closeViewer(): void {
    this.complete.emit();
  }

  public next(
    selectedCardId: DynamicAdvisingOfferidType,
    updateEconomic: boolean,
    updateFamilyInformation: boolean
  ) {
    if (updateEconomic) {
      this.updateEconomy = true;
    } else if (updateFamilyInformation) {
      this.updateFamily = true;
    }
    this.goToNextCard(selectedCardId);
  }

  private goToNextCard(selectedCardId: DynamicAdvisingOfferidType) {
    this.scrollToTop.emit();
    if (this.copySelectedCardIdList && this.copySelectedCardIdList.length > 1) {
      this.copySelectedCardIdList.splice(
        this.copySelectedCardIdList.indexOf(selectedCardId),
        1
      );
      this.selectedCardId = this.copySelectedCardIdList[0];
    } else {
      this.selectedCardId = undefined;
      if (this.updateFamily) {
        this.updateFamilyInformation();
      }
      if (this.updateEconomy) {
        this.updateEconomics();
      }
      this.showDoneCard = true;
    }
  }

  private updateFamilyInformation(): void {
    this.globalWorkingService.show();
    this.familyInformationApiService
      .updateFamilyInformation(this.familyInformation)
      .subscribe(
        familyInformation => {
          this.familyInformation = familyInformation;
        },
        () => {},
        () => {
          this.globalWorkingService.hide();
        }
      );
  }

  private updateEconomics(): void {
    this.globalWorkingService.show();
    const financialSituation =
      this.financialSituationConfigurationService.getMappedFormsToUpdateFormat(
        this.financialSituationForm,
        this.financialSituation
      );

    this.financialSituationApiService
      .updateFinancialSituation(financialSituation)
      .pipe(finalize(() => this.globalWorkingService.hide()))
      .subscribe(
        () => {
          Object.keys(financialSituation).forEach(
            key => (this.financialSituation[key] = financialSituation[key])
          );
          this.financialSituationForm.markAsPristine();
        },
        () => {},
        () => {
          this.globalWorkingService.hide();
        }
      );
  }
}
