/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const PensionPayoutConstraintsSchema = JoiObj.object({
        maxDaysAfterCurrentDateAllowedToStartPayout:
            
                    JoiObj.number()
                .integer()


.required()


        ,
        latestDayInMonthAllowedToStartPayout:
            
                    JoiObj.number()
                .integer()


.required()


        
    })
.id('PensionPayoutConstraintsSchema')

export interface PensionPayoutConstraints { 
    /**
     * Maximum number of days after now where it should be allowed to start payout
     */
    maxDaysAfterCurrentDateAllowedToStartPayout: number;
    /**
     * Latest day in the month you are allowed to start payout in order to start payout in the next month. Default is set to the 15.
     */
    latestDayInMonthAllowedToStartPayout: number;
}

