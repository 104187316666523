<div class="align--center spacing-unit--over">
  <button
    mat-raised-button
    class="button-primary--level3"
    (click)="back.emit()"
  >
    <co-icon-arrow-left class="icon icon--arrow-left"></co-icon-arrow-left>
    <span>{{ 'DL.INVGU01.C287' | content }}</span>
  </button>
</div>
