import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';
import { MaeglerInfo } from '@pfa/gen';
import { InvestmentGuideInfo } from '@pfa/models';
import { Subscription } from 'rxjs';
import { InvestmentGuideQuestion } from '../investment-guide-configuration.service';
import { InvestmentGuideService } from '@mitpfa/shared';

@Component({
  selector: 'mitpfa-investment-guide-risk-step',
  templateUrl: './investment-guide-risk-step.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InvestmentGuideRiskStepComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() investmentGuide: InvestmentGuideInfo;
  @Input() broker: MaeglerInfo;

  public question = InvestmentGuideQuestion;

  private subs: Subscription = new Subscription();

  public activeQuestionIndex = 0;

  constructor(
    private readonly investmentGuideService: InvestmentGuideService
  ) {}

  public ngOnInit(): void {
    const sub = this.investmentGuideService
      .getCurrentQuestion$()
      .subscribe(currentQuestion => {
        switch (currentQuestion.value) {
          case 'RISK_WILINGNESS':
            this.activeQuestionIndex = 0;
            break;
          case 'RISK_PROFILE':
            this.activeQuestionIndex = 1;
            break;
          case 'LOSSABILITY':
            this.activeQuestionIndex = 2;
            break;
        }
      });
    this.subs.add(sub);
  }

  ngAfterViewInit(): void {
    window.scroll(0, 0);
  }

  public ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  public goBack() {
    switch (this.investmentGuideService.getQuestionReached()) {
      case 'RISK_WILINGNESS':
        this.investmentGuideService.updateCurrentQuestion({
          value: 'ECONOMICAL_IMPACT',
          update: false,
          back: true
        });
        break;
      case 'RISK_PROFILE':
        this.investmentGuideService.updateCurrentQuestion({
          value: 'RISK_WILINGNESS',
          update: false,
          back: true
        });
        break;
      case 'LOSSABILITY':
        this.investmentGuideService.updateCurrentQuestion({
          value: 'RISK_PROFILE',
          update: false,
          back: true
        });
        break;
    }
  }
}
