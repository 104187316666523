import {
  Component,
  DestroyRef,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { combineLatest, forkJoin, Observable, Subject, take } from 'rxjs';
import { first, map, switchMap, takeUntil } from 'rxjs/operators';
import {
  AccordionTrackingService,
  ContentService,
  ContentUtilService,
  MiscTrackingService,
  NumberFormatPipe,
  OrderItem,
  OrderTrackingService,
  PageTrackingService,
  PopupTrackingService,
  TrackingName
} from '@pfa/core';
import {
  ConfirmChoice,
  Considered,
  DefaultChoice,
  DisabledElements,
  Errors,
  InputConfigCi,
  InputConfigItemLife,
  InputConfigLife,
  InputConfigLoc,
  InputConfigs,
  InputNotification,
  INSURANCE_SCROLL_TIMERS,
  InsuranceAdjustmentAdvisorInfo,
  InsuranceAdjustmentCiService,
  InsuranceAdjustmentInputEmitter,
  InsuranceAdjustmentLifeService,
  InsuranceAdjustmentLocService,
  InsuranceAdjustmentState,
  InsuranceAdjustmentType,
  InsuranceAdjustmentValues,
  InsuranceGuideChecks,
  Overlay,
  PageStates,
  PayscaleConfigLoc,
  SigningService,
  SuperRiskTexts,
  UnsavedChangesTypes
} from '@mitpfa/shared';
import { Router } from '@angular/router';
import {
  AdvisorCorrectionsApiService,
  AutomaticHealthAcceptanceApiService,
  BasketApiService,
  BusinessDomain,
  FamilyInformationApiService,
  FinancialSituationApiService,
  InsuranceApiService,
  InsuranceGuideApiService
} from '@pfa/api';
import {
  BroadcastMessageType,
  BroadcastService,
  GlobalWorkingService,
  InsuranceUtilService,
  PensionPlanInfoService,
  UnsavedChangesWarningComponent,
  UnsavedChangesWarningResponse
} from '@pfaform';
import * as moment from 'moment';
import { InsuranceAdjustmentDefaultChoiceService } from './insurance-adjustment-default-choice.service';
import { globalCacheBusterNotifier } from 'ts-cacheable';
import { InsuranceAdjustmentResetService } from './insurance-adjustment-reset.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ComponentCanDeactivate } from '@pfa/handler';
import { InsuranceAdjustmentConfirmChoiceService } from './insurance-adjustment-confirm-choice.service';
import { InsuranceAdjustmentSuperRiskPopupComponent } from './insurance-adjustment-super-risk-popup/insurance-adjustment-super-risk-popup.component';
import { InsuranceAdjustmentSuperRiskResponse } from './insurance-adjustment-super-risk-popup/insurance-adjustment-super-risk-popup.model';
import { InsuranceAdjustmentAtypicalChoicePopupComponent } from './insurance-adjustment-atypical-choice-popup/insurance-adjustment-atypical-choice-popup.component';
import { InsuranceAdjustmentAtypicalChoiceResponse } from './insurance-adjustment-atypical-choice-popup/insurance-adjustment-atypical-choice-popup.model';
import { InsuranceAdjustmentDeleteAdvisorRecPopupComponent } from './insurance-adjustment-delete-advisor-rec-popup/insurance-adjustment-delete-advisor-rec-popup.component';
import { InsuranceAdjustmentDeleteAdvisorRecommendationResponse } from './insurance-adjustment-delete-advisor-rec-popup/insurance-adjustment-delete-advisor-rec-popup.model';
import { InsuranceAdjustmentUpdateService } from './insurance-adjustment-update.service';
import {
  BestiltAendringer,
  Forsikringsoversigt,
  ForsikringStore,
  GodkendSvar,
  MicroappconfigurationStore,
  PensionsInfoOrdninger,
  PensionsKundeGenerelleData,
  PensionskundeStore,
  RaadgiverAnbefaling,
  SignatureIframeResponse,
  SimulatorTileEnum,
  Skattekode,
  TilpasForsikringstal
} from '@pfa/gen';
import {
  COMPANY_PFA,
  InsuranceTag,
  PensionPlanExtend,
  PopupStyle
} from '@pfa/models';
import { InsuranceAdjustmentTrackingService } from './insurance-adjustment-tracking.service';

import { Utils } from '@pfa/utils';
import { InsuranceGuideService } from '@mitpfa/shared/insurance-guide/insurance-guide.service';
import { RECOMMENDATION_TYPE } from '@mitpfa/shared/insurance-guide/recommendation-step/insurance-guide.recommendation.model';
import { NewBusinessAdvisoryService } from '@pfa/services';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'mitpfa-insurance-adjustment',
  templateUrl: './insurance-adjustment.component.html',
  styleUrls: ['./insurance-adjustment.component.scss']
})
export class InsuranceAdjustmentComponent
  implements OnInit, OnDestroy, ComponentCanDeactivate
{
  @ViewChild('locInputAccordion') locInputElement: ElementRef<HTMLElement>;
  @ViewChild('ciInputAccordion') ciInputElement: ElementRef<HTMLElement>;
  @ViewChild('lifeInputAccordion') lifeInputElement: ElementRef<HTMLElement>;
  @ViewChild('scrollTargetLoc') scrollTargetLocElement: ElementRef<HTMLElement>;
  @ViewChild('scrollTargetCi') scrollTargetCiElement: ElementRef<HTMLElement>;
  @ViewChild('scrollTargetLife')
  scrollTargetLifeElement: ElementRef<HTMLElement>;

  @Input() isOnboarding: boolean;
  @Output() exit = new EventEmitter<void>();

  calculatedByUser: boolean;
  considered: Considered;
  notConsideredTypeOne: string;
  notConsideredTypeOneScrollTo: InsuranceAdjustmentType;
  notConsideredTypeTwo: string;
  notConsideredTypeTwoScrollTo: InsuranceAdjustmentType;
  insuranceGuideChecks: InsuranceGuideChecks;
  showAuaConditions: boolean;
  defaultChoice: DefaultChoice;
  errors: Errors;
  disabledElements: DisabledElements;
  overlay = new Overlay();
  pageStates: PageStates;
  addedToBasket: boolean;
  orderedChanges: BestiltAendringer;
  failedPolicies: string;
  locAdjustmentAdvisorInfo: InsuranceAdjustmentAdvisorInfo;
  lifeAdjustmentAdvisorInfo: InsuranceAdjustmentAdvisorInfo;
  advisorDataError: boolean;
  godkendSvar: GodkendSvar;

  locInputConfig: InputConfigLoc;
  locPayscaleConfig: PayscaleConfigLoc;

  ciInputConfig: InputConfigCi;

  lifeInputConfig: InputConfigLife;
  lifeInputNotification: InputNotification;

  confirmChoice: ConfirmChoice;
  choiceConfirmed: boolean;
  insuranceState: InsuranceAdjustmentState;
  insuranceStateOriginal: InsuranceAdjustmentState;
  salary: number;
  unsavedChanges: boolean;
  unsavedChangesTypes: UnsavedChangesTypes;
  pensionPlanPolice: PensionPlanExtend;
  public pensionCustomer: PensionsKundeGenerelleData;
  confirmChoiceInitialized: boolean;
  inputExpandedLoc = true;
  inputExpandedCi = true;
  inputExpandedLife = true;
  hasLocOnMultiplePolicies: boolean;

  lifeUpdateNote: string | undefined;
  tags: InsuranceTag[];
  private readonly unsubscribe = new Subject<void>();
  private insuranceAdjustmentValues: InsuranceAdjustmentValues;
  private insuranceAdjustmentValuesOriginal: InsuranceAdjustmentValues;
  private readonly DATE_FORMAT = 'DD.MM.YYYY';

  private readonly RECOMMENDATION_VARIANCE_LOC = 5;
  private readonly RECOMMENDATION_VARIANCE_LIFE = 50;
  private readonly RECOMMENDATION_VARIANCE_CI = 40_000;

  public fromGuide: boolean;

  // INJECTIONS
  readonly insuranceAdjustmentResetService: InsuranceAdjustmentResetService =
    inject(InsuranceAdjustmentResetService);
  readonly insuranceAdjustmentUpdateService: InsuranceAdjustmentUpdateService =
    inject(InsuranceAdjustmentUpdateService);
  private readonly pensionPlanInfoService: PensionPlanInfoService = inject(
    PensionPlanInfoService
  );
  private readonly numberFormat: NumberFormatPipe = inject(NumberFormatPipe);
  private readonly globalWorkingService: GlobalWorkingService =
    inject(GlobalWorkingService);
  private readonly trackingService: MiscTrackingService =
    inject(MiscTrackingService);
  private readonly router: Router = inject(Router);
  private readonly insuranceApiService: InsuranceApiService =
    inject(InsuranceApiService);
  private readonly advisorCorrectionsApiService: AdvisorCorrectionsApiService =
    inject(AdvisorCorrectionsApiService);
  private readonly insuranceGuideApiService: InsuranceGuideApiService = inject(
    InsuranceGuideApiService
  );
  private readonly insuranceGuideService: InsuranceGuideService = inject(
    InsuranceGuideService
  );
  private readonly pensionskundeStore: PensionskundeStore =
    inject(PensionskundeStore);
  private readonly insuranceUtilService: InsuranceUtilService =
    inject(InsuranceUtilService);
  private readonly contentService: ContentService = inject(ContentService);
  private readonly contentUtilService: ContentUtilService =
    inject(ContentUtilService);
  private readonly insuranceAdjustmentCiService: InsuranceAdjustmentCiService =
    inject(InsuranceAdjustmentCiService);
  private readonly insuranceAdjustmentLocService: InsuranceAdjustmentLocService =
    inject(InsuranceAdjustmentLocService);
  private readonly insuranceAdjustmentLifeService: InsuranceAdjustmentLifeService =
    inject(InsuranceAdjustmentLifeService);
  private readonly financialSituationApiService: FinancialSituationApiService =
    inject(FinancialSituationApiService);
  private readonly familyInformationApiService: FamilyInformationApiService =
    inject(FamilyInformationApiService);
  private readonly automaticHealthAcceptanceApiService: AutomaticHealthAcceptanceApiService =
    inject(AutomaticHealthAcceptanceApiService);
  private readonly microappconfigurationStore: MicroappconfigurationStore =
    inject(MicroappconfigurationStore);
  private readonly basketApiService: BasketApiService =
    inject(BasketApiService);
  private readonly insuranceAdjustmentDefaultChoiceService: InsuranceAdjustmentDefaultChoiceService =
    inject(InsuranceAdjustmentDefaultChoiceService);
  private readonly broadcastService: BroadcastService =
    inject(BroadcastService);
  private readonly dialog: MatDialog = inject(MatDialog);
  private readonly newBusinessAdvisoryService: NewBusinessAdvisoryService =
    inject(NewBusinessAdvisoryService);
  private readonly orderTrackingService: OrderTrackingService =
    inject(OrderTrackingService);
  private readonly popupTrackingService: PopupTrackingService =
    inject(PopupTrackingService);
  private readonly insuranceAdjustmentTrackingService: InsuranceAdjustmentTrackingService =
    inject(InsuranceAdjustmentTrackingService);
  private readonly signingService: SigningService = inject(SigningService);
  private readonly accordionTrackingService: AccordionTrackingService = inject(
    AccordionTrackingService
  );
  private readonly pageTrackingService: PageTrackingService =
    inject(PageTrackingService);
  private readonly insuranceStore: ForsikringStore = inject(ForsikringStore);
  private readonly destroyRef: DestroyRef = inject(DestroyRef);

  constructor() {
    this.errors = {} as Errors;
    this.disabledElements = new DisabledElements();
    this.pageStates = {} as PageStates;
  }

  ngOnInit(): void {
    this.fromGuide =
      this.isOnboarding ||
      this.router.getCurrentNavigation()?.extras?.state?.fromGuide;
    this.insuranceGuideChecks = {} as InsuranceGuideChecks;

    // if you refresh the page you need to clear everything
    this.basketApiService
      .clearBasket()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: () => {
          this.insuranceApiService.clearCache();
          this.loadDataAndInit(false);
        },
        error: () => {
          this.loadDataAndInit(false);
        }
      });

    this.loadDataAndInit(false);

    this.contentService.languageState
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        if (this.pageStates?.insuranceInitialized) {
          this.initData();
        }
      });
  }

  loadDataAndInit(resetInputFields: boolean): void {
    this.considered = new Considered();
    this.globalWorkingService.show();
    this.lifeUpdateNote = undefined;
    this.unsavedChangesTypes = {
      loc: false,
      ci: false,
      life: false
    };
    this.insuranceApiService
      .initInsuranceAdjustment()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next: () => {
          globalCacheBusterNotifier.next();
          combineLatest({
            insurance: this.insuranceApiService.getInsuranceDetails(),
            insuranceOverview:
              this.insuranceApiService.getInsuranceOverviewDetails(),
            pensionCustomer: this.pensionskundeStore.pensionskundeGet(),
            pensionInfo: this.insuranceApiService.getPensionInfo(),
            insuranceRecommendation:
              this.insuranceGuideApiService.getInsuranceGuideData(),
            financialSituation: this.financialSituationApiService.get(),
            familyInformation:
              this.familyInformationApiService.getFamilyInformation(),
            taxData: this.microappconfigurationStore.microappconfigurationGet(
              'skat',
              'statsatser',
              moment().year()
            ),
            healthAcceptanceCases:
              this.automaticHealthAcceptanceApiService.getHealthAcceptanceCases(),
            newBusinessAdvisory:
              this.newBusinessAdvisoryService.getNewBusinessAdvisory()
          })
            .pipe(takeUntil(this.unsubscribe), take(1))
            .subscribe((responses: InsuranceAdjustmentState) => {
              this.insuranceState = responses;
              this.insuranceStateOriginal = { ...this.insuranceState };
              this.salary = this.insuranceUtilService.getSalary(
                this.insuranceState.financialSituation
              );
              this.pensionCustomer = responses.pensionCustomer;

              this.setHasLocOnMultiplePolicies(
                responses.insuranceOverview,
                responses.pensionInfo
              );

              if (this.fromGuide && !this.pensionCustomer.isRaadgiver) {
                this.inputExpandedLoc = false;
                this.inputExpandedCi = false;
                this.inputExpandedLife = false;
              }

              this.insuranceAdjustmentValues = {
                lifeSelfAmount:
                  this.insuranceState.insurance.tilpasForsikringstal.sliderLiv
                    .currentForsikringstal,
                lifeChildAmount:
                  this.insuranceState.insurance.tilpasForsikringstal
                    .daekningAendringBoernepension.daekningsBeloeb,
                ciSelfAmount:
                  this.insuranceState.insurance.tilpasForsikringstal
                    .daekningAendringKritiskSygdomSelv.daekningsBeloeb,
                ciChildAmount:
                  this.insuranceState.insurance.tilpasForsikringstal
                    .daekningAendringKritiskSygdomBoern.daekningsBeloeb,
                locRegularPayoutAmount:
                  this.insuranceState.insurance.tilpasForsikringstal
                    .sliderTAEloebende.currentForsikringstal,
                locLumpSumAmount:
                  this.insuranceState.insurance.tilpasForsikringstal
                    .daekningAendringTAEengangsudbetaling.daekningsBeloeb
              };
              this.insuranceAdjustmentValuesOriginal = {
                ...this.insuranceAdjustmentValues
              };
              this.tags = this.insuranceUtilService.scaleTags(
                this.insuranceState.insurance
              );

              this.handleBlockedPolicy();
              this.locAdjustmentAdvisorInfo =
                this.insuranceAdjustmentLocService.locAdjustmentAdvisorInfo(
                  this.insuranceState
                );
              this.lifeAdjustmentAdvisorInfo =
                this.insuranceAdjustmentLifeService.lifeAdjustmentAdvisorInfo(
                  this.insuranceState
                );

              this.basketApiService
                .get(
                  BusinessDomain.Insurance,
                  this.insuranceState.insurance.tilpasForsikringstal
                    .policenummer
                )
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((orderedChanges: BestiltAendringer) => {
                  this.orderedChanges = orderedChanges;
                  this.initData();
                  this.notifyOnreset(resetInputFields);
                });
            });
          this.unsavedChanges = false;
          this.resetUnsavedChangesTypes();
        },
        error: () => {
          this.globalWorkingService.hide();
        }
      });
  }

  private setHasLocOnMultiplePolicies(
    insuranceOverview: Forsikringsoversigt,
    pensionInfo: PensionsInfoOrdninger
  ): void {
    const payouts =
      insuranceOverview.forsikringDaekningerUnderUdbetalingGruppe.map(
        insurance => insurance.pensionsAftaleId.toString()
      );
    const uniqePfaPoliciesWithLoc = pensionInfo.forsikring.helbredsDaekninger
      .filter(insurance => insurance.selskabsNr === COMPANY_PFA)
      .filter(insurance => !payouts.includes(insurance.aftalenummer));
    this.hasLocOnMultiplePolicies = uniqePfaPoliciesWithLoc?.length > 1;
  }

  confirmChoiceForType(type: string, fromguide: boolean): void {
    this.choiceConfirmed = true;
    this.createConfirmService().confirmChoiceForType(
      type,
      this.findTrackingName(fromguide),
      this.pensionCustomer
    );
  }

  private findTrackingName(fromguide: boolean): TrackingName {
    if (this.isOnboarding) {
      if (fromguide) {
        return TrackingName.INSURANCE_RECOMMENDATION_ONBOARDING;
      } else {
        return TrackingName.INSURANCE_CONFIGURATION_ONBOARDING;
      }
    } else {
      if (fromguide) {
        return TrackingName.INSURANCE_RECOMMENDATION;
      } else {
        return TrackingName.INSURANCE_CONFIGURATION;
      }
    }
  }

  public onConfirmChoiceLoc(fromguide: boolean): void {
    this.considered.consider(InsuranceAdjustmentType.LOC);
    this.checkConsidered();
    this.confirmChoiceForType('LossOfOccupationalCapacity', fromguide);
  }

  public onConfirmChoiceLife(fromguide: boolean): void {
    this.considered.consider(InsuranceAdjustmentType.LIFE);
    this.checkConsidered();
    this.confirmChoiceForType('LifeInsurance', fromguide);
  }

  public onConfirmChoiceCi(fromguide: boolean): void {
    this.considered.consider(InsuranceAdjustmentType.CI);
    this.checkConsidered();
    this.confirmChoiceForType('CriticalIllness', fromguide);
  }

  hasChangesInBasket(): boolean {
    return (
      this.orderedChanges.harPersisteretIndhold ||
      this.orderedChanges.harIkkePersisteretIndhold ||
      this.orderedChanges.harIkkePersisteretIndholdIKurv
    );
  }

  onReset(): void {
    this.basketApiService.clearBasket().subscribe({
      next: () => {
        this.insuranceApiService.clearCache();
        globalCacheBusterNotifier.next();
        this.globalWorkingService.hide();
        this.calculatedByUser = false;
        this.subCleanup();
        this.insuranceStore.invalidateforsikringdetaljerIndex();
        this.insuranceApiService.clearInsuranceOverviewDetailsCache();
        this.loadDataAndInit(true);
      },
      error: () => {
        this.globalWorkingService.hide();
      }
    });
  }

  private subCleanup(): void {
    this.insuranceAdjustmentLocService.resetFollowOurRecommendationSubject();
    this.insuranceAdjustmentLocService.resetAdjustInsuranceSubject();
    this.insuranceAdjustmentCiService.resetFollowOurRecommendationSubject();
    this.insuranceAdjustmentCiService.resetAdjustInsuranceSubject();
    this.insuranceAdjustmentLifeService.resetFollowOurRecommendationSubject();
    this.insuranceAdjustmentLifeService.resetAdjustInsuranceSubject();
  }

  onApprove(): void {
    this.checkAtypicalChoice(this.doApprove.bind(this));
  }

  onSigningCompleted(godkendSvar: GodkendSvar): void {
    this.godkendSvar = godkendSvar;
    this.orderTrackingService.trackOrderCompleted(
      this.getOrderItems(),
      this.isOnboarding
        ? TrackingName.INSURANCE_ONBOARDING
        : TrackingName.INSURANCE
    );
    this.unsavedChanges = false;
    this.resetUnsavedChangesTypes();
    this.pageStates.receipt = true;
  }

  onAddToBasket(): void {
    this.checkAtypicalChoice(this.doAddToBasket.bind(this));
  }

  initInsuranceGuideChecks(): void {
    this.insuranceGuideChecks = {} as InsuranceGuideChecks;
    if (this.insuranceState.insuranceRecommendation.timeAsMs) {
      const recommendationDate = moment(
        this.insuranceState.insuranceRecommendation.timeAsMs
      );
      this.insuranceGuideChecks.guideDate = this.contentUtilService.getContent(
        'DL.TF01.C50',
        recommendationDate.format(this.DATE_FORMAT)
      );
      this.insuranceGuideChecks.guideTooOld = this.insuranceGuideTooOld();
      this.insuranceGuideChecks.guideNotQuiteTooOld =
        this.insuranceGuideNotQuiteTooOld();
    } else {
      this.insuranceGuideChecks.noGuide = true;
    }
    const bypassGuideWarning = this.bypassGuideWarning();
    this.insuranceGuideChecks.showGuideWarning =
      !bypassGuideWarning &&
      (this.insuranceGuideChecks.noGuide ||
        this.insuranceGuideChecks.guideTooOld ||
        this.insuranceGuideChecks.guideNotQuiteTooOld);
    this.disabledElements.page =
      !bypassGuideWarning &&
      (this.insuranceGuideChecks.noGuide ||
        this.insuranceGuideChecks.guideTooOld);
  }

  private bypassGuideWarning(): boolean {
    return this.insuranceState.pensionCustomer.alder > 65;
  }

  private insuranceGuideTooOld(): boolean {
    const recommendationDate = moment(
      this.insuranceState.insuranceRecommendation.timeAsMs
    );
    const tooOld = this.tooOldDate();
    return (
      recommendationDate.isBefore(tooOld) || recommendationDate.isSame(tooOld)
    );
  }

  private insuranceGuideNotQuiteTooOld(): boolean {
    const recommendationDate = moment(
      this.insuranceState.insuranceRecommendation.timeAsMs
    );
    const tooOld = this.tooOldDate();
    const isOk = moment().subtract(1, 'days');
    return (
      recommendationDate.isAfter(tooOld) &&
      (recommendationDate.isBefore(isOk) || recommendationDate.isSame(isOk))
    );
  }

  private tooOldDate(): moment.Moment {
    return moment().subtract(3, 'years');
  }

  ngOnDestroy(): void {
    if (this.choiceConfirmed) {
      this.pensionskundeStore.invalidatepensionskundeIndex();
    }
    this.unsubscribe.next();
    this.unsubscribe.complete();

    this.subCleanup();
  }

  healthInformationBack(): void {
    this.pageStates.showHealthInformation = false;
  }

  gotoOverview(): void {
    this.router.navigateByUrl('/mineforsikringer');
  }

  gotoInsuranceGuide(): void {
    this.router.navigateByUrl('/mineforsikringer/forsikringsguide');
  }

  canDeactivate(): boolean | Observable<boolean> {
    if (this.unsavedChanges) {
      // user changed something
      return this.dialog
        .open(UnsavedChangesWarningComponent, {
          panelClass: PopupStyle.PopupSmall
        })
        .afterClosed()
        .pipe(
          map((result: UnsavedChangesWarningResponse) => {
            const isLeavingPage = result && result.result === 'ACCEPT';
            if (isLeavingPage) {
              this.globalWorkingService.show();
              this.basketApiService.clearBasket().subscribe({
                next: () => {
                  this.insuranceApiService.clearCache();
                  globalCacheBusterNotifier.next();
                  this.globalWorkingService.hide();
                },
                error: () => {
                  this.globalWorkingService.hide();
                }
              });
            }
            return isLeavingPage;
          }),
          first()
        );
    }
    return true;
  }

  onUserInput(
    insuranceAdjustmentInputEmitter: InsuranceAdjustmentInputEmitter
  ): void {
    this.calculatedByUser = true;
    this.enableButtons();
    switch (insuranceAdjustmentInputEmitter.type) {
      case InsuranceAdjustmentType.CI:
      case InsuranceAdjustmentType.CI_CHILD: {
        this.updateCiModel(insuranceAdjustmentInputEmitter);
        break;
      }
      case InsuranceAdjustmentType.LOC:
      case InsuranceAdjustmentType.LOC_LUMPSUM: {
        this.updateLocModel(insuranceAdjustmentInputEmitter);
        break;
      }
      case InsuranceAdjustmentType.LIFE:
      case InsuranceAdjustmentType.LIFE_CHILD:
        this.updateLifeModel(insuranceAdjustmentInputEmitter);
        break;
      case InsuranceAdjustmentType.LIFE_SPOUSE:
      case InsuranceAdjustmentType.LIFE_SPOUSE_EXTRA:
        this.updateLifeSpouse(insuranceAdjustmentInputEmitter);
        break;
    }
    this.checkConsidered();
  }

  private checkConsidered(): void {
    this.notConsideredTypeOne = '';
    this.notConsideredTypeTwo = '';
    if (!this.considered.isAll()) {
      this.handleConsider(
        this.considered.isLoc(),
        this.contentUtilService.getContent('DL.TF01.C203'),
        InsuranceAdjustmentType.LOC
      );
      if (
        this.insuranceState.insurance.tilpasForsikringstal
          .daekningAendringKritiskSygdomSelv.skalVises
      ) {
        this.handleConsider(
          this.considered.isCi(),
          this.contentUtilService.getContent('DL.TF01.C204'),
          InsuranceAdjustmentType.CI
        );
      }
      this.handleConsider(
        this.considered.isLife(),
        this.contentUtilService.getContent('DL.TF01.C205'),
        InsuranceAdjustmentType.LIFE
      );
    }
  }

  scrollToSection(insuranceAdjustmentType: InsuranceAdjustmentType): void {
    let element: HTMLElement | undefined;
    if (insuranceAdjustmentType === InsuranceAdjustmentType.LOC) {
      element = this.scrollTargetLocElement.nativeElement;
    } else if (insuranceAdjustmentType === InsuranceAdjustmentType.CI) {
      element = this.scrollTargetCiElement.nativeElement;
    } else if (insuranceAdjustmentType === InsuranceAdjustmentType.LIFE) {
      element = this.scrollTargetLifeElement.nativeElement;
    }
    if (element) {
      Utils.scrollTo(
        element,
        INSURANCE_SCROLL_TIMERS.animationDuraction,
        INSURANCE_SCROLL_TIMERS.animationStartDelay,
        -20
      );
    }
  }

  private handleConsider(
    isConsidered: boolean,
    text: string,
    insuranceAdjustmentType: InsuranceAdjustmentType
  ): void {
    if (!isConsidered) {
      if (this.notConsideredTypeOne) {
        this.notConsideredTypeTwo = text;
        this.notConsideredTypeTwoScrollTo = insuranceAdjustmentType;
      } else {
        this.notConsideredTypeOne = text;
        this.notConsideredTypeOneScrollTo = insuranceAdjustmentType;
      }
    }
  }

  private enableButtons(): void {
    this.disabledElements.buttonAddToBasket = false;
    this.disabledElements.buttonReset = false;
    if (!this.hasChangesInBasket()) {
      this.disabledElements.buttonApprove = false;
    }
  }

  updateLocTaxCodeAdvisorInfo(taxCode: Skattekode) {
    this.insuranceState.insurance.tilpasForsikringstal.daekningAendringTAEengangsudbetaling.valgtSkattekode =
      taxCode;
    this.insuranceApiService
      .updateInsuranceAdjustmentLocTaxCodeAdvisorInfo(
        this.insuranceState.insurance.tilpasForsikringstal
      )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next: () => {
          this.locAdjustmentAdvisorInfo.taxCode = taxCode;
          this.unsavedChanges = true;
          this.enableButtons();
          this.globalWorkingService.hide();
        },
        error: () => {
          this.advisorDataError = true;
          this.globalWorkingService.hide();
        }
      });
  }

  updateLifeTaxCodeAdvisorInfo(taxCode: Skattekode) {
    this.insuranceState.insurance.tilpasForsikringstal.sliderLiv.valgtSkattekode =
      taxCode;
    this.insuranceApiService
      .updateInsuranceAdjustmentLifeTaxCodeAdvisorInfo(
        this.insuranceState.insurance.tilpasForsikringstal
      )
      .pipe(
        switchMap(resp => {
          this.updateInsuranceLifeValues(resp.tilpasForsikringstal);
          this.lifeAdjustmentAdvisorInfo.taxCode = taxCode;
          this.insuranceAdjustmentUpdateService.lifeTaxCodeUpdated({
            amountMin: resp.tilpasForsikringstal.sliderLiv.minimumsdaekning,
            amountMax: resp.tilpasForsikringstal.sliderLiv.forsikringstalMax,
            amount: resp.tilpasForsikringstal.sliderLiv.currentForsikringstal,
            beforeTax:
              resp.tilpasForsikringstal.sliderLiv.forsikringstalBruttobeloeb,
            afterTax:
              resp.tilpasForsikringstal.sliderLiv.forsikringstalNettobeloeb,
            showBeforeTax: !resp.tilpasForsikringstal.bruttoTalLivIkkeVises
          });
          return this.insuranceApiService.getPensionInfo();
        })
      )
      .subscribe({
        next: infoResponse => {
          this.insuranceState.pensionInfo = infoResponse;
          this.pensionPlanPolice = this.pensionPlanInfoService.mapPensionData(
            this.insuranceState.pensionInfo
          );
          this.unsavedChanges = true;
          this.enableButtons();
          this.globalWorkingService.hide();
        },
        error: () => {
          this.advisorDataError = true;
          this.globalWorkingService.hide();
        }
      });
  }

  applyAdvisorCorrections(useCorrections: boolean): void {
    this.advisorCorrectionsApiService
      .applyAdvisorCorrections(useCorrections)
      .subscribe(() => {
        this.onReset();
      });
  }

  applyManualAdvisorCorrections(): void {
    this.onReset();
  }

  updateLocAdvisorInfo(advisorRecommendation: RaadgiverAnbefaling) {
    this.insuranceApiService
      .updateInsuranceAdjustmentLocAdvisorInfo(advisorRecommendation)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next: successResponse => {
          this.advisorDataError = !successResponse.success;
          if (!this.advisorDataError) {
            this.locAdjustmentAdvisorInfo.advisorData.raadgiverAnbefaling =
              advisorRecommendation.raadgiverAnbefaling;
            this.locAdjustmentAdvisorInfo.advisorData.raadgiverKommentar =
              advisorRecommendation.raadgiverKommentar;
            this.locAdjustmentAdvisorInfo.advisorData.raadgiverAnbefalingOprettet =
              moment().format(this.DATE_FORMAT);
          }
        },
        error: () => {
          this.advisorDataError = true;
          this.globalWorkingService.hide();
        }
      });
    this.globalWorkingService.hide();
  }

  updateLifeAdvisorInfo(advisorRecommendation: RaadgiverAnbefaling) {
    this.insuranceApiService
      .updateInsuranceAdjustmentLifeAdvisorInfo(advisorRecommendation)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next: successResponse => {
          this.advisorDataError = !successResponse.success;
          if (!this.advisorDataError) {
            this.lifeAdjustmentAdvisorInfo.advisorData.raadgiverAnbefaling =
              advisorRecommendation.raadgiverAnbefaling;
            this.lifeAdjustmentAdvisorInfo.advisorData.raadgiverKommentar =
              advisorRecommendation.raadgiverKommentar;
            this.lifeAdjustmentAdvisorInfo.advisorData.raadgiverAnbefalingOprettet =
              moment().format(this.DATE_FORMAT);
          }
        },
        error: () => {
          this.advisorDataError = true;
          this.globalWorkingService.hide();
        }
      });
    this.globalWorkingService.hide();
  }

  deleteLocAdvisorInfo() {
    const acceptDialogRef: MatDialogRef<InsuranceAdjustmentDeleteAdvisorRecPopupComponent> =
      this.dialog.open(InsuranceAdjustmentDeleteAdvisorRecPopupComponent, {
        panelClass: PopupStyle.PopupSmall
      });
    const acceptDialogResult = acceptDialogRef.afterClosed().pipe(
      map(
        (result: InsuranceAdjustmentDeleteAdvisorRecommendationResponse) =>
          result && result.result === 'ACCEPT'
      ),
      first()
    );
    acceptDialogResult.subscribe(accept => {
      if (accept) {
        this.insuranceApiService
          .deleteInsuranceAdjustmentLocAdvisorInfo()
          .pipe(takeUntil(this.unsubscribe))
          .subscribe({
            next: successResponse => {
              this.advisorDataError = !successResponse.success;
              if (!this.advisorDataError) {
                this.insuranceAdjustmentUpdateService.notifyAdvisorInfoDeleted(
                  InsuranceAdjustmentType.LOC
                );
                this.locAdjustmentAdvisorInfo = {
                  type: InsuranceAdjustmentType.LOC,
                  taxCode:
                    this.insuranceState.insurance.tilpasForsikringstal
                      .daekningAendringTAEengangsudbetaling.valgtSkattekode,
                  advisorData: {
                    raadgiverAnbefaling: 0,
                    raadgiverKommentar: '',
                    raadgiverAnbefalingOprettet: '',
                    aktuelForsikringstal:
                      this.insuranceState.insurance.originalTilpasForsikringstal
                        .currentForsikringstalTAE,
                    pfaAnbefaling:
                      this.insuranceState.insurance.originalTilpasForsikringstal
                        .pfaAnbefalerTAE,
                    validAdviserRecommendation: false
                  }
                };
              }
              this.globalWorkingService.hide();
            },
            error: () => {
              this.advisorDataError = true;
              this.globalWorkingService.hide();
            }
          });
      } else {
        this.globalWorkingService.hide();
      }
    });
  }

  deleteLifeAdvisorInfo() {
    const acceptDialogRef: MatDialogRef<InsuranceAdjustmentDeleteAdvisorRecPopupComponent> =
      this.dialog.open(InsuranceAdjustmentDeleteAdvisorRecPopupComponent, {
        panelClass: PopupStyle.PopupSmall
      });
    const acceptDialogResult = acceptDialogRef.afterClosed().pipe(
      map(
        (result: InsuranceAdjustmentDeleteAdvisorRecommendationResponse) =>
          result && result.result === 'ACCEPT'
      ),
      first()
    );
    acceptDialogResult.subscribe(accept => {
      if (accept) {
        this.insuranceApiService
          .deleteInsuranceAdjustmentLifeAdvisorInfo()
          .pipe(takeUntil(this.unsubscribe))
          .subscribe({
            next: successResponses => {
              this.advisorDataError = !successResponses.success;
              if (!this.advisorDataError) {
                this.insuranceAdjustmentUpdateService.notifyAdvisorInfoDeleted(
                  InsuranceAdjustmentType.LIFE
                );
                this.lifeAdjustmentAdvisorInfo = {
                  type: InsuranceAdjustmentType.LIFE,
                  taxCode:
                    this.insuranceState.insurance.tilpasForsikringstal.sliderLiv
                      .valgtSkattekode,
                  advisorData: {
                    raadgiverAnbefaling: 0,
                    raadgiverKommentar: '',
                    raadgiverAnbefalingOprettet: '',
                    aktuelForsikringstal:
                      this.insuranceState.insurance.originalTilpasForsikringstal
                        .currentForsikringstalLiv,
                    pfaAnbefaling:
                      this.insuranceState.insurance.originalTilpasForsikringstal
                        .pfaAnbefalerLiv,
                    validAdviserRecommendation: false
                  }
                };
              }
              this.globalWorkingService.hide();
            },
            error: () => {
              this.advisorDataError = true;
              this.globalWorkingService.hide();
            }
          });
      } else {
        this.globalWorkingService.hide();
      }
    });
  }

  private doApprove(): void {
    this.globalWorkingService.show();
    this.orderTrackingService.trackApproveOrder(
      this.getOrderItems(),
      this.isOnboarding ? 'insurance onboarding' : 'insurance'
    );
    this.errors.approve = false;
    this.disabledElements.disableAddToBasketButton();
    this.basketApiService
      .buildContract(this.contentService.getLanguage(), 'FORSIKRING')
      .subscribe({
        next: res => {
          if (res.fejl) {
            this.errors.approve = true;
          } else {
            this.showSigningPopup();
          }
          this.globalWorkingService.hide();
        },
        error: () => {
          this.errors.approve = true;
          this.globalWorkingService.hide();
        }
      });
  }

  private showSigningPopup(): void {
    this.pageStates.showHealthInformation =
      this.pageStates.showHealthInformation ||
      this.insuranceState.insurance.tilpasForsikringstal.kraevesHS;
    if (!this.pageStates.showHealthInformation) {
      this.signingService
        .showSigningPopup(
          this.getSigningFunction(),
          this.getOrderItems(),
          this.isOnboarding
            ? TrackingName.INSURANCE_ONBOARDING
            : TrackingName.INSURANCE
        )
        .subscribe({
          next: signingPopupResponse => {
            this.signingService
              .handleSigningResponse(
                signingPopupResponse,
                this.getOrderItems(),
                'FORSIKRING',
                this.isOnboarding
                  ? TrackingName.INSURANCE_ONBOARDING
                  : TrackingName.INSURANCE
              )
              .subscribe({
                next: godkendSvar => {
                  this.onSigningCompleted(godkendSvar);
                },
                error: () => {
                  this.signingService.showSigningError();
                }
              });
          },
          error: signingPopupResponse => {
            this.signingService.showSigningError();
          }
        });
    }
  }

  public getSigningFunction(): () => Observable<SignatureIframeResponse> {
    return this.signingService.getBasketSigningFunction(
      SimulatorTileEnum.Forsikring
    );
  }

  private doAddToBasket(): void {
    this.globalWorkingService.show();
    this.orderTrackingService.trackAddOrderWithPlacement(
      this.getOrderItems(),
      this.isOnboarding ? 'insurance onboarding' : 'insurance'
    );
    this.errors.addToBasket = false;
    this.addedToBasket = false;
    this.disabledElements.disableAllButtonsButReset();
    this.basketApiService.addToBasket().subscribe({
      next: res => {
        if (res.success) {
          this.unsavedChanges = false;
          this.resetUnsavedChangesTypes();
          this.addedToBasket = true;
          this.broadcastService.broadcastMessage(
            BroadcastMessageType.ADD_TO_BASKET
          );

          this.insuranceAdjustmentDefaultChoiceService.updateDefaultChoice(
            this.insuranceState.insuranceRecommendation,
            this.getInputconfigs(),
            this.defaultChoice,
            true
          );
        } else {
          this.errors.addToBasket = true;
        }
        this.globalWorkingService.hide();
      },
      error: () => {
        this.errors.addToBasket = true;
        this.globalWorkingService.hide();
      }
    });
  }

  private notifyOnreset(resetInputFields: boolean): void {
    if (resetInputFields) {
      this.insuranceAdjustmentResetService.ciNotify({
        inputConfig: this.ciInputConfig,
        advisorInfo: undefined
      });
      this.insuranceAdjustmentUpdateService.ciNotify(
        this.insuranceStateOriginal.insuranceOverview
      );
      this.insuranceAdjustmentResetService.locNotify({
        inputConfig: this.locInputConfig,
        advisorInfo: this.locAdjustmentAdvisorInfo
      });
      this.insuranceAdjustmentResetService.lifeNotify({
        inputConfig: this.lifeInputConfig,
        advisorInfo: this.lifeAdjustmentAdvisorInfo
      });
    }
  }

  private createConfirmService(): InsuranceAdjustmentConfirmChoiceService {
    return new InsuranceAdjustmentConfirmChoiceService(
      this.trackingService,
      this.contentUtilService,
      this.insuranceApiService,
      this.insuranceState,
      this.locInputConfig,
      this.ciInputConfig,
      this.lifeInputConfig,
      this.confirmChoice,
      this.isOnboarding
    );
  }

  private initData(): void {
    this.initInsuranceGuideChecks();
    this.initInputConfig();
    this.initInputNotification();
    this.initDefaultChoice();
    this.initOverlay();
    this.initRecommendationControl();

    this.showAuaConditions =
      this.insuranceState.insurance.tilpasForsikringstal.visAUAforbehold;
    this.failedPolicies =
      this.insuranceState.insuranceOverview.fejledePolicenumre
        .map(policy => policy)
        .join(', ');

    this.disabledElements.disableAllButtonsButReset();
    this.pageStates.showHealthInformation = false;
    this.initConfirmedChoice();
    this.pageStates.insuranceInitialized = true;
    this.pageTrackingService.trackContentLoaded();
    this.globalWorkingService.hide();
  }

  private initInputConfig(): void {
    this.ciInputConfig = this.insuranceAdjustmentCiService.createInputConfig(
      this.insuranceState,
      this.insuranceAdjustmentValuesOriginal,
      this.insuranceGuideChecks,
      this.insuranceStateOriginal
    );

    this.locPayscaleConfig = this.insuranceAdjustmentLocService.getLocPayscale(
      this.insuranceState
    );

    this.createLocInputConfig();
    this.lifeInputConfig =
      this.insuranceAdjustmentLifeService.createInputConfig(
        this.insuranceState,
        this.insuranceAdjustmentValuesOriginal,
        this.insuranceGuideChecks
      );
    this.pensionPlanPolice = this.pensionPlanInfoService.mapPensionData(
      this.insuranceState.pensionInfo
    );
  }

  private initInputNotification(): void {
    const types = [
      'OPSAT_LIVSVARIG_AEGTEFAELLEPENSION',
      'OPSAT_OPHOERENDE_AEGTEFAELLEPENSION',
      'AEGTEFAELLEPENSION_OPHOERENDE_RISIKO',
      'LIVSVARIG_AEGTEFAELLLEPENSION',
      'OPHOERENDE_AEGTEFAELLEPENSION',
      'LIVSVARIG_AEGTEFAELLE_UNITLINK',
      'OPHOERENDE_AEGTEFAELLE_UNITLINK',
      'LIVSVARIG_AEGTEFAELLE_FORSIKRING',
      'OPHOERENDE_AEGTEFAELLE_FORSIKRING'
    ];
    const sum =
      this.insuranceState.insuranceOverview.livsForsikring?.livsForsikringer
        .filter(insurance => types.includes(insurance.daekningsType))
        .map(insurance => insurance.beloeb)
        .reduce((accumulator, amount) => accumulator + amount, 0);
    if (sum > 0) {
      this.lifeInputNotification = {
        contentId: 'DL.TF01.C151',
        amount: sum
      } as InputNotification;
    }
  }

  private initDefaultChoice(): void {
    this.defaultChoice =
      this.insuranceAdjustmentDefaultChoiceService.createDefaultChoice(
        this.insuranceState.pensionCustomer
      );
  }

  private initConfirmedChoice() {
    const showRecommendation =
      !this.insuranceGuideChecks.guideTooOld &&
      !this.insuranceGuideChecks.noGuide &&
      this.confirmChoiceInitialized;
    const showInput = !this.overlay.policyBlocked;
    const hideIf = this.overlay.policyBlocked || this.disabledElements.page;
    const confirmPossible = !hideIf && (showRecommendation || showInput);
    this.confirmChoice = {
      loc: {
        error: false,
        confirmedDate: this.confirmedDate('LossOfOccupationalCapacity'),
        confirmPossible
      },
      ci: {
        error: false,
        confirmedDate: this.confirmedDate('CriticalIllness'),
        confirmPossible
      },
      life: {
        error: false,
        confirmedDate: this.confirmedDate('LifeInsurance'),
        confirmPossible
      }
    };

    if (this.confirmChoice.ci.confirmedDate) {
      this.considered.consider(InsuranceAdjustmentType.CI);
    }
    if (this.confirmChoice.life.confirmedDate) {
      this.considered.consider(InsuranceAdjustmentType.LIFE);
    }
    if (this.confirmChoice.loc.confirmedDate) {
      this.considered.consider(InsuranceAdjustmentType.LOC);
    }
    this.checkConsidered();

    this.createConfirmService().trackConfirmChoiceAllStates(
      this.confirmChoice,
      this.overlay.policyBlocked
    );
    this.confirmChoiceInitialized = true;
  }

  private confirmedDate(inForceSelectionType: string): any | undefined {
    return this.insuranceState.pensionCustomer.inForceSelections
      .filter(
        selectionType =>
          selectionType.inForceSelectionType === inForceSelectionType
      )
      .map(type => type.timestamp)
      .find(() => true);
  }

  private initOverlay(): void {
    this.initOverlayHealthAcceptance();
    this.initOverlayPersistentContent();
    this.initOverlayChangesInBasket();
    this.initOverlayPayoutInProgress();
    this.initOverlayInsuranceGuide();
  }

  private initRecommendationControl(): void {
    this.insuranceAdjustmentLocService.followOurRecommendation.subscribe({
      next: () => {
        this.accordionTrackingService.trackAccordion(
          this.isOnboarding
            ? 'pfaloc configuration onboarding'
            : 'pfaloc configuration',
          'pfaloc follow recommendation'
        );
        this.scrollToInput(InsuranceAdjustmentType.LOC);
      }
    });
    this.insuranceAdjustmentLocService.adjustInsurance.subscribe({
      next: () => {
        this.accordionTrackingService.trackAccordion(
          this.isOnboarding
            ? 'pfaloc configuration onboarding'
            : 'pfaloc configuration',
          'pfaloc choose cover'
        );
        this.scrollToInput(InsuranceAdjustmentType.LOC);
      }
    });
    this.insuranceAdjustmentCiService.followOurRecommendation.subscribe({
      next: () => {
        this.accordionTrackingService.trackAccordion(
          this.isOnboarding
            ? 'pfaci configuration onboarding'
            : 'pfaci configuration',
          'pfaci follow recommendation'
        );
        this.scrollToInput(InsuranceAdjustmentType.CI);
      }
    });
    this.insuranceAdjustmentCiService.adjustInsurance.subscribe({
      next: () => {
        this.accordionTrackingService.trackAccordion(
          this.isOnboarding
            ? 'pfaci configuration onboarding'
            : 'pfaci configuration',
          'pfaci choose cover'
        );
        this.scrollToInput(InsuranceAdjustmentType.CI);
      }
    });
    this.insuranceAdjustmentLifeService.followOurRecommendation.subscribe({
      next: () => {
        this.accordionTrackingService.trackAccordion(
          this.isOnboarding
            ? 'pfalife configuration onboarding'
            : 'pfalife configuration',
          'pfalife follow recommendation'
        );
        this.scrollToInput(InsuranceAdjustmentType.LIFE);
      }
    });
    this.insuranceAdjustmentLifeService.adjustInsurance.subscribe({
      next: () => {
        this.accordionTrackingService.trackAccordion(
          this.isOnboarding
            ? 'pfalife configuration onboarding'
            : 'pfalife configuration',
          'pfalife choose cover'
        );
        this.scrollToInput(InsuranceAdjustmentType.LIFE);
      }
    });
  }

  private scrollToInput(
    insuranceAdjustmentType: InsuranceAdjustmentType
  ): void {
    let element: HTMLElement | undefined;
    if (insuranceAdjustmentType === InsuranceAdjustmentType.LOC) {
      this.inputExpandedLoc = true;
      element = this.locInputElement.nativeElement;
    } else if (insuranceAdjustmentType === InsuranceAdjustmentType.CI) {
      this.inputExpandedCi = true;
      element = this.ciInputElement.nativeElement;
    } else if (insuranceAdjustmentType === InsuranceAdjustmentType.LIFE) {
      this.inputExpandedLife = true;
      element = this.lifeInputElement.nativeElement;
    }
    if (element) {
      Utils.scrollTo(
        element,
        INSURANCE_SCROLL_TIMERS.animationDuraction,
        INSURANCE_SCROLL_TIMERS.animationStartDelay,
        -20
      );
    }
  }

  private initOverlayHealthAcceptance(): void {
    if (this.insuranceState.healthAcceptanceCases?.length > 0) {
      this.disabledElements.disableAll();
      switch (this.insuranceState.healthAcceptanceCases[0].healthCaseStatus) {
        case 'MANUAL':
        case 'IN_PROGRESS':
          this.overlay.healthPending = true;
          break;
        case 'DRAFT_MITPFA':
          this.overlay.healthMissing = true;
          break;
      }
    }
  }

  private initOverlayPersistentContent(): void {
    if (this.orderedChanges.harPersisteretIndhold) {
      this.disabledElements.disableAll();
    }
  }

  private initOverlayPayoutInProgress(): void {
    if (
      this.insuranceUtilService.payoutsInProgress(
        this.insuranceState.insuranceOverview
      )
    ) {
      this.disabledElements.disableAll();
      this.overlay.payoutInProgress = true;
      if (!this.pensionCustomer.isRaadgiver) {
        this.overlay.show = true;
      }
    }
  }

  private initOverlayInsuranceGuide(): void {
    if (this.insuranceGuideChecks.noGuide) {
      return;
    }

    if (this.insuranceGuideTooOld() && !this.bypassGuideWarning()) {
      this.disabledElements.disableAll();
      this.overlay.insuranceGuideTooOld = true;
      if (!this.insuranceState.pensionCustomer.isRaadgiver) {
        this.overlay.show = true;
      }
      return;
    }

    const impactHigh =
      this.insuranceState.financialSituation.taeNytte === 'HOEJ';
    const willPayForExtraLocUnAnswered =
      this.insuranceState.financialSituation.taeBetalingsvillig === undefined;
    const recommendationDate = moment(
      this.insuranceState.insuranceRecommendation.timeAsMs
    );
    const triggerDate = moment('2023-01-01');
    const recommendationDateBefore = recommendationDate.isBefore(triggerDate);
    const guideCompleted =
      !!this.insuranceState.insuranceRecommendation.timeAsMs;
    if (
      !guideCompleted ||
      (this.insuranceState.insurance.showRiskLocStep &&
        (recommendationDateBefore ||
          (impactHigh && willPayForExtraLocUnAnswered)))
    ) {
      this.overlay.locScale = true;
      if (!this.pensionCustomer.isRaadgiver) {
        this.overlay.show = true;
      }
    }
  }

  private initOverlayChangesInBasket(): void {
    if (this.hasChangesInBasket()) {
      this.disabledElements.buttonApprove = true;
    }
  }

  private updateLocModel(
    insuranceAdjustmentInputEmitter: InsuranceAdjustmentInputEmitter
  ): void {
    const regularLoc =
      insuranceAdjustmentInputEmitter.type === InsuranceAdjustmentType.LOC;

    this.getLocUpdateCall(regularLoc, insuranceAdjustmentInputEmitter)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((insuranceAdjustment: TilpasForsikringstal) => {
        if (insuranceAdjustment.erFejlet) {
          this.locUpdateFailed(
            regularLoc,
            insuranceAdjustmentInputEmitter,
            insuranceAdjustment
          );
        } else {
          this.unsavedChanges = true;
          if (regularLoc) {
            this.unsavedChangesTypes.loc = true;
          }
          if (insuranceAdjustment.superRiskTae) {
            this.globalWorkingService.hide();
            this.showSuperRiskLocPopup(insuranceAdjustmentInputEmitter.type);
          } else {
            if (regularLoc) {
              this.insuranceState.insurance.tilpasForsikringstal.sliderTAEloebende.pris =
                insuranceAdjustment.sliderTAEloebende.pris;
              this.insuranceState.insurance.tilpasForsikringstal.sliderTAEloebende.forsikringstalBruttobeloeb =
                insuranceAdjustment.sliderTAEloebende.forsikringstalBruttobeloeb;
              this.insuranceState.insurance.tilpasForsikringstal.sliderTAEloebende.forsikringstalNettobeloeb =
                insuranceAdjustment.sliderTAEloebende.forsikringstalNettobeloeb;
              this.insuranceState.insurance.tilpasForsikringstal.sliderTAEloebende.daekningBeloebPrPolice =
                insuranceAdjustment.sliderTAEloebende.daekningBeloebPrPolice;
              this.insuranceState.insurance.tilpasForsikringstal.sliderTAEloebende.daekningProcentPrPolice =
                insuranceAdjustment.sliderTAEloebende.daekningProcentPrPolice;
              this.insuranceState.insurance.tilpasForsikringstal.sliderTAEloebende.currentForsikringstal =
                insuranceAdjustment.sliderTAEloebende.currentForsikringstal;
              this.insuranceState.insurance.helbredsTalBrutto =
                insuranceAdjustment.sliderTAEloebende.currentForsikringstalTAEBrutto;
              if (insuranceAdjustment.sliderTAEloebende.locScale) {
                this.insuranceState.insurance.tilpasForsikringstal.locScale =
                  insuranceAdjustment.sliderTAEloebende.locScale;
              }
            } else {
              this.insuranceState.insurance.tilpasForsikringstal.daekningAendringTAEengangsudbetaling.pris =
                insuranceAdjustment.daekningAendringTAEengangsudbetaling.pris;
              this.insuranceState.insurance.tilpasForsikringstal.daekningAendringTAEengangsudbetaling.bruttobeloeb =
                insuranceAdjustment.daekningAendringTAEengangsudbetaling.bruttobeloeb;
              this.insuranceState.insurance.tilpasForsikringstal.daekningAendringTAEengangsudbetaling.valgtSkattekode =
                insuranceAdjustment.daekningAendringTAEengangsudbetaling.valgtSkattekode;
              this.insuranceAdjustmentUpdateService.locNotifyLumpSumpUpdated(
                insuranceAdjustment.daekningAendringTAEengangsudbetaling
                  .valgtSkattekode
              );
            }
            this.insuranceState.insurance.tilpasForsikringstal.indregningDetaljer =
              insuranceAdjustment.indregningDetaljer;
            this.insuranceState.insurance.tilpasForsikringstal.kraevesHS =
              insuranceAdjustment.kraevesHS;

            this.createLocInputConfig();
            this.updateLoc(insuranceAdjustment, regularLoc);
          }
        }
      });
  }

  locUpdateFailed(
    regularLoc: boolean,
    insuranceAdjustmentInputEmitter: InsuranceAdjustmentInputEmitter,
    insuranceAdjustment: TilpasForsikringstal
  ): void {
    if (regularLoc && this.locInputConfig) {
      this.locInputConfig.regularPayout.updateFailed = true;
    } else {
      if (this.locInputConfig.lumpSum) {
        this.locInputConfig.lumpSum.updateFailed = true;
      }
    }
    this.globalWorkingService.hide();

    if (insuranceAdjustment.superRiskTae) {
      this.showSuperRiskLocPopup(insuranceAdjustmentInputEmitter.type);
    }
  }

  private getLocUpdateCall(
    regularLoc: boolean,
    insuranceAdjustmentInputEmitter: InsuranceAdjustmentInputEmitter
  ): Observable<TilpasForsikringstal> {
    const inputConfig =
      insuranceAdjustmentInputEmitter.inputConfig as InputConfigLoc;
    const backendInput = {
      ...this.insuranceState.insurance.tilpasForsikringstal
    };
    let updateFunction: Observable<TilpasForsikringstal>;
    if (regularLoc) {
      this.considered.consider(InsuranceAdjustmentType.LOC);
      const slider = backendInput.sliderTAEloebende;
      if (slider) {
        if (inputConfig.regularPayout.amount !== undefined) {
          slider.currentForsikringstal = inputConfig.regularPayout.amount;
        }
        slider.locScale = inputConfig.regularPayout.locScale;
      }

      backendInput.locScale =
        this.insuranceState.insurance.originalTilpasForsikringstal.locScale;
      updateFunction =
        this.insuranceApiService.updateInsuranceAdjustmentLocRegularPayouts(
          backendInput
        );
    } else {
      backendInput.daekningAendringTAEengangsudbetaling.daekningsBeloeb =
        inputConfig.lumpSum?.amount ?? 0;
      updateFunction =
        this.insuranceApiService.updateInsuranceAdjustmentLocLumpSum(
          backendInput
        );
    }
    return updateFunction;
  }

  private createLocInputConfig(): void {
    const recommendationDirection =
      this.insuranceGuideService.getRecommendationLoc(
        this.insuranceState.pensionInfo,
        this.insuranceState.insurance.locScaleInformation
      );
    const recommendationDescription =
      InsuranceGuideService.getRecommendationDescription(
        recommendationDirection,
        RECOMMENDATION_TYPE.OCCUPATIONAL_CAPACITY,
        this.insuranceState.insurance.locScaleInformation
      );
    this.locInputConfig = this.insuranceAdjustmentLocService.createInputConfig(
      this.insuranceState,
      this.insuranceAdjustmentValuesOriginal,
      this.locPayscaleConfig,
      this.insuranceGuideChecks,
      recommendationDescription
    );
  }

  private updateLoc(
    insuranceAdjustment: TilpasForsikringstal,
    regularLoc: boolean
  ): void {
    if (regularLoc) {
      this.updateLocAmount();
    } else {
      this.updateLocLumpSum(insuranceAdjustment);
    }
  }

  private updateLocAmount() {
    this.insuranceApiService.getPensionInfo().subscribe(pensionInfo => {
      this.insuranceState.pensionInfo = pensionInfo;
      this.globalWorkingService.hide();
    });
  }

  private updateLocLumpSum(insuranceAdjustment: TilpasForsikringstal): void {
    this.insuranceState.insuranceOverview.erhvervsevneForsikring?.erhvervsevneForsikringer
      .filter(
        insurance =>
          insurance.formOfPayment === 'LUMP_SUM_PAYMENT' &&
          insurance.selskab === COMPANY_PFA &&
          insurance.aftaleNummer === insuranceAdjustment.policenummer
      )
      .forEach(
        insurance =>
          (insurance.beloeb =
            insuranceAdjustment.daekningAendringTAEengangsudbetaling.bruttobeloeb)
      );
    this.globalWorkingService.hide();
  }

  private checkAtypicalChoice(next: () => void): void {
    if (
      this.hasLocDeviation() ||
      this.hasLifeDeviation() ||
      this.hasCiDeviation()
    ) {
      this.showAtypicalChoicePopup(next);
    } else {
      next();
    }
  }

  private hasLocDeviation(): boolean {
    return this.hasAtypicalChoiceForType(
      this.locInputConfig.regularPayout.amount ?? 0,
      this.insuranceState.insurance.tilpasForsikringstal.pfaAnbefalerTAE,
      this.RECOMMENDATION_VARIANCE_LOC
    );
  }

  private hasLifeDeviation(): boolean {
    return this.hasAtypicalChoiceForType(
      this.lifeInputConfig.self.amount ?? 0,
      this.insuranceState.insurance.tilpasForsikringstal.pfaAnbefalerLiv,
      this.RECOMMENDATION_VARIANCE_LIFE
    );
  }

  private hasCiDeviation(): boolean {
    return this.hasAtypicalChoiceForType(
      this.insuranceUtilService.criticalIllnessTotalAmount(
        this.insuranceState.insuranceOverview
      ) ?? 0,
      this.insuranceState.insurance.tilpasForsikringstal.pfaAnbefalerKS,
      this.RECOMMENDATION_VARIANCE_CI
    );
  }

  private showAtypicalChoicePopup(next: () => void): void {
    const locDeviation = this.hasLocDeviation();
    const lifeDeviation = this.hasLifeDeviation();
    const ciDeviation = this.hasCiDeviation();

    this.popupTrackingService.trackPopupViewed(
      this.insuranceAdjustmentTrackingService.getInsuranceDeviationTrackingName(
        locDeviation,
        lifeDeviation,
        ciDeviation
      )
    );
    const reasons = new Array<InsuranceAdjustmentType>();
    if (locDeviation) {
      reasons.push(InsuranceAdjustmentType.LOC);
    }
    if (lifeDeviation) {
      reasons.push(InsuranceAdjustmentType.LIFE);
    }
    if (ciDeviation) {
      reasons.push(InsuranceAdjustmentType.CI);
    }
    const acceptDialogRef: MatDialogRef<InsuranceAdjustmentAtypicalChoicePopupComponent> =
      this.dialog.open(InsuranceAdjustmentAtypicalChoicePopupComponent, {
        panelClass: PopupStyle.PopupMedium,
        data: {
          reasons: reasons
        }
      });
    const acceptDialogResult = acceptDialogRef.afterClosed().pipe(
      map(
        (result: InsuranceAdjustmentAtypicalChoiceResponse) =>
          result && result.result === 'ACCEPT'
      ),
      first()
    );
    acceptDialogResult.subscribe(accept => {
      if (accept) {
        this.popupTrackingService.trackPopupCallToAction(
          this.insuranceAdjustmentTrackingService.getInsuranceDeviationTrackingName(
            locDeviation,
            lifeDeviation,
            ciDeviation
          )
        );
        next();
      } else {
        this.popupTrackingService.trackPopupClosed(
          this.insuranceAdjustmentTrackingService.getInsuranceDeviationTrackingName(
            locDeviation,
            lifeDeviation,
            ciDeviation
          )
        );
      }
    });
  }

  private hasAtypicalChoiceForType(
    amount: number,
    recommendedAmount: number,
    variance: number
  ): boolean {
    return this.exceedsLimit(amount, recommendedAmount, variance);
  }

  private exceedsLimit(
    regularAmount: number,
    recommendedValue: number,
    limit: number
  ): boolean {
    return Math.abs(recommendedValue - regularAmount) > limit;
  }

  private showSuperRiskLifePopup(
    insuranceAdjustmentType: InsuranceAdjustmentType
  ) {
    const data: SuperRiskTexts = {
      header: this.contentUtilService.getContent('DL.TF01.C79'),
      body1: this.contentUtilService.getContent('DL.TF01.C87'),
      body2: this.contentUtilService.getContent('DL.TF01.C81'),
      body3: this.contentUtilService.getContent('DL.TF01.C82'),
      finishButton: false
    };
    this.showSuperRiskPopup(
      insuranceAdjustmentType,
      this.superRiskAcceptedLife.bind(this),
      data
    );
  }

  private showSuperRiskLocPopup(
    insuranceAdjustmentType: InsuranceAdjustmentType
  ): void {
    const data: SuperRiskTexts = {
      header: this.contentUtilService.getContent('DL.TF01.C79'),
      body1: this.contentUtilService.getContent('DL.TF01.C80'),
      body2: this.contentUtilService.getContent('DL.TF01.C81'),
      body3: this.contentUtilService.getContent('DL.TF01.C82'),
      finishButton: false
    };
    this.showSuperRiskPopup(
      insuranceAdjustmentType,
      this.superRiskAcceptedLoc.bind(this),
      data
    );
  }

  private showSuperRiskPopup(
    insuranceAdjustmentType: InsuranceAdjustmentType,
    acceptFunction: (insuranceAdjustmentType: InsuranceAdjustmentType) => void,
    data: SuperRiskTexts
  ): void {
    const acceptDialogRef: MatDialogRef<InsuranceAdjustmentSuperRiskPopupComponent> =
      this.dialog.open(InsuranceAdjustmentSuperRiskPopupComponent, {
        disableClose: true,
        panelClass: PopupStyle.PopupLarge,
        data
      });
    const acceptDialogResult = acceptDialogRef.afterClosed().pipe(
      map(
        (result: InsuranceAdjustmentSuperRiskResponse) =>
          result && result.result === 'ACCEPT'
      ),
      first()
    );
    acceptDialogResult.subscribe(accept => {
      if (accept) {
        this.globalWorkingService.show();
        acceptFunction(insuranceAdjustmentType);
      } else {
        this.onReset();
      }
    });
  }

  private superRiskAcceptedLoc(
    insuranceAdjustmentType: InsuranceAdjustmentType
  ): void {
    this.sendSuperRiskLoc(insuranceAdjustmentType).subscribe({
      next: () => {
        this.onReset();
        this.showSuperRiskLocPopup2();
      },
      error: () => {
        this.onReset();
      }
    });
  }

  private superRiskAcceptedLife(
    insuranceAdjustmentType: InsuranceAdjustmentType
  ): void {
    this.sendSuperRiskLife(insuranceAdjustmentType).subscribe({
      next: () => {
        this.onReset();
        this.showSuperRiskLifePopup2();
      },
      error: () => {
        this.onReset();
      }
    });
  }

  private showSuperRiskLifePopup2(): void {
    this.dialog.open(InsuranceAdjustmentSuperRiskPopupComponent, {
      panelClass: PopupStyle.PopupLarge,
      data: {
        header: this.contentUtilService.getContent('DL.TF01.C86'),
        body1: this.contentUtilService.getContent('DL.TF01.C84'),
        body2: this.contentUtilService.getContent('DL.TF01.C81'),
        body3: this.contentUtilService.getContent('DL.TF01.C88'),
        finishButton: true
      }
    });
  }

  private showSuperRiskLocPopup2(): void {
    this.dialog.open(InsuranceAdjustmentSuperRiskPopupComponent, {
      panelClass: PopupStyle.PopupLarge,
      data: {
        header: this.contentUtilService.getContent('DL.TF01.C83'),
        body1: this.contentUtilService.getContent('DL.TF01.C84'),
        body2: this.contentUtilService.getContent('DL.TF01.C81'),
        body3: this.contentUtilService.getContent('DL.TF01.C85'),
        finishButton: true
      }
    });
  }

  private sendSuperRiskLoc(
    insuranceAdjustmentType: InsuranceAdjustmentType
  ): Observable<InsuranceAdjustmentType> {
    if (insuranceAdjustmentType === InsuranceAdjustmentType.LOC_LUMPSUM) {
      return this.insuranceApiService.acceptSuperRiskLocLumpSum(
        this.insuranceState.insurance.tilpasForsikringstal
      );
    } else {
      return this.insuranceApiService.acceptSuperRiskLocRegular(
        this.insuranceState.insurance.tilpasForsikringstal
      );
    }
  }

  private sendSuperRiskLife(
    insuranceAdjustmentType: InsuranceAdjustmentType
  ): Observable<InsuranceAdjustmentType> {
    if (insuranceAdjustmentType === InsuranceAdjustmentType.LIFE) {
      return this.insuranceApiService.acceptSuperRiskLife(
        this.insuranceState.insurance.tilpasForsikringstal
      );
    } else {
      return this.insuranceApiService.acceptSuperRiskChild(
        this.insuranceState.insurance.tilpasForsikringstal
      );
    }
  }

  private updateCiModel(
    insuranceAdjustmentInputEmitter: InsuranceAdjustmentInputEmitter
  ): void {
    const isSelf =
      insuranceAdjustmentInputEmitter.type === InsuranceAdjustmentType.CI;
    const ciInputConfigName = isSelf ? 'self' : 'child';
    const adjustName = isSelf
      ? 'daekningAendringKritiskSygdomSelv'
      : 'daekningAendringKritiskSygdomBoern';
    const backendInput = {
      ...this.insuranceState.insurance.tilpasForsikringstal
    };
    if (isSelf) {
      this.considered.consider(InsuranceAdjustmentType.CI);
    }
    backendInput[adjustName].daekningsBeloeb =
      insuranceAdjustmentInputEmitter.inputConfig[ciInputConfigName].amount;
    const updateFunction = isSelf
      ? this.insuranceApiService.updateInsuranceAdjustmentCiSelf(backendInput)
      : this.insuranceApiService.updateInsuranceAdjustmentCiChild(backendInput);
    updateFunction
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((insuranceAdjustment: TilpasForsikringstal) => {
        if (insuranceAdjustment.erFejlet) {
          const cfg = this.ciInputConfig[ciInputConfigName];
          if (cfg) {
            cfg.updateFailed = true;
          }
          this.globalWorkingService.hide();
        } else {
          this.unsavedChanges = true;
          if (isSelf) {
            this.unsavedChangesTypes.ci = true;
          }
          this.insuranceApiService.clearInsuranceOverviewDetailsCache();
          this.insuranceApiService
            .getInsuranceOverviewDetails()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(insuranceOverview => {
              this.insuranceState.insuranceOverview = insuranceOverview;

              this.insuranceState.insurance.tilpasForsikringstal[
                adjustName
              ].pris = insuranceAdjustment[adjustName].pris;
              this.insuranceState.insurance.tilpasForsikringstal[
                adjustName
              ].daekningsBeloeb =
                insuranceAdjustment[adjustName].daekningsBeloeb;
              this.insuranceState.insurance.tilpasForsikringstal[
                adjustName
              ].bruttobeloeb = insuranceAdjustment[adjustName].bruttobeloeb;
              this.insuranceState.insurance.tilpasForsikringstal.indregningDetaljer =
                insuranceAdjustment.indregningDetaljer;
              this.insuranceState.insurance.tilpasForsikringstal.kraevesHS =
                insuranceAdjustment.kraevesHS;

              this.ciInputConfig =
                this.insuranceAdjustmentCiService.createInputConfig(
                  this.insuranceState,
                  this.insuranceAdjustmentValuesOriginal,
                  this.insuranceGuideChecks,
                  this.insuranceStateOriginal
                );
              this.insuranceAdjustmentUpdateService.ciNotify(insuranceOverview);
              this.globalWorkingService.hide();
            });
        }
      });
  }

  private updateLifeModel(
    insuranceAdjustmentInputEmitter: InsuranceAdjustmentInputEmitter
  ): void {
    const isSelf =
      insuranceAdjustmentInputEmitter.type === InsuranceAdjustmentType.LIFE;
    const lifeInputConfigName = isSelf ? 'self' : 'child';
    this.getLifeUpdateCall(insuranceAdjustmentInputEmitter, isSelf)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((insuranceAdjustment: TilpasForsikringstal) => {
        const cfg = this.lifeInputConfig[lifeInputConfigName];

        if (insuranceAdjustment.erFejlet) {
          this.lifeUpdateFailed(
            insuranceAdjustmentInputEmitter,
            cfg,
            insuranceAdjustment
          );
        } else {
          if (cfg) {
            cfg.updateFailed = false;
          }
          this.unsavedChanges = true;
          if (isSelf) {
            this.unsavedChangesTypes.life = true;
          }
          if (insuranceAdjustment.superRiskLife) {
            this.globalWorkingService.hide();
            this.showSuperRiskLifePopup(insuranceAdjustmentInputEmitter.type);
          } else {
            this.updateLifeInsuranceState(isSelf, insuranceAdjustment);
            this.updateLifeDetails();
          }
        }
      });
  }

  private lifeUpdateFailed(
    insuranceAdjustmentInputEmitter: InsuranceAdjustmentInputEmitter,
    cfg: InputConfigItemLife | undefined,
    insuranceAdjustment: TilpasForsikringstal
  ): void {
    if (cfg) {
      cfg.updateFailed = true;
    }
    this.globalWorkingService.hide();
    if (insuranceAdjustment.superRiskLife) {
      this.showSuperRiskLifePopup(insuranceAdjustmentInputEmitter.type);
    }
  }

  getLifeUpdateCall(
    insuranceAdjustmentInputEmitter: InsuranceAdjustmentInputEmitter,
    isSelf: boolean
  ): Observable<TilpasForsikringstal> {
    const backendInput = {
      ...this.insuranceState.insurance.tilpasForsikringstal
    };
    const inputConfigLife =
      insuranceAdjustmentInputEmitter.inputConfig as InputConfigLife;
    if (isSelf) {
      this.considered.consider(InsuranceAdjustmentType.LIFE);
      if (inputConfigLife.self.amount !== undefined) {
        backendInput.sliderLiv.currentForsikringstal =
          this.useRangeBoundsWithDecimals(inputConfigLife.self.amount);
      }
    } else if (inputConfigLife.child?.amount !== undefined) {
      backendInput.daekningAendringBoernepension.daekningsBeloeb =
        inputConfigLife.child?.amount;
    }
    return isSelf
      ? this.insuranceApiService.updateInsuranceAdjustmentLifeSelf(backendInput)
      : this.insuranceApiService.updateInsuranceAdjustmentLifeChild(
          backendInput
        );
  }

  updateLifeInsuranceState(
    isSelf: boolean,
    insuranceAdjustment: TilpasForsikringstal
  ): void {
    if (isSelf) {
      this.updateInsuranceLifeValues(insuranceAdjustment);
    } else {
      this.insuranceState.insurance.tilpasForsikringstal.daekningAendringBoernepension.pris =
        insuranceAdjustment.daekningAendringBoernepension.pris;
      this.insuranceState.insurance.tilpasForsikringstal.daekningAendringBoernepension.bruttobeloeb =
        insuranceAdjustment.daekningAendringBoernepension.bruttobeloeb;
      this.insuranceState.insurance.tilpasForsikringstal.daekningAendringBoernepension.daekningsBeloeb =
        insuranceAdjustment.daekningAendringBoernepension.daekningsBeloeb;
    }
    this.insuranceState.insurance.tilpasForsikringstal.indregningDetaljer =
      insuranceAdjustment.indregningDetaljer;
    this.insuranceState.insurance.tilpasForsikringstal.kraevesHS =
      insuranceAdjustment.kraevesHS;
    this.lifeInputConfig =
      this.insuranceAdjustmentLifeService.createInputConfig(
        this.insuranceState,
        this.insuranceAdjustmentValuesOriginal,
        this.insuranceGuideChecks
      );
  }

  private updateLifeSpouse(
    insuranceAdjustmentInputEmitter: InsuranceAdjustmentInputEmitter
  ): void {
    const backendInput = {
      ...this.insuranceState.insurance.tilpasForsikringstal
    };
    const inputConfigLife =
      insuranceAdjustmentInputEmitter.inputConfig as InputConfigLife;
    backendInput.aegtefaelle = inputConfigLife.spousePension;
    const isSpouse =
      insuranceAdjustmentInputEmitter.type ===
      InsuranceAdjustmentType.LIFE_SPOUSE;
    const updateFunction = isSpouse
      ? this.insuranceApiService.updateSpouse(backendInput)
      : this.insuranceApiService.updateSpouseExtraAmount(backendInput);
    updateFunction
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((insuranceAdjustment: TilpasForsikringstal) => {
        if (insuranceAdjustment.erFejlet) {
          this.setSpouseError(isSpouse, true);
          this.globalWorkingService.hide();
        } else {
          this.setSpouseError(isSpouse, false);
          this.updateInsuranceLifeValues(insuranceAdjustment);
          this.insuranceState.insurance.tilpasForsikringstal.sliderLiv.minimumsdaekning =
            insuranceAdjustment.sliderLiv.minimumsdaekning;
          this.insuranceState.insurance.tilpasForsikringstal.sliderLiv.forsikringstalMax =
            insuranceAdjustment.sliderLiv.forsikringstalMax;
          this.insuranceState.insurance.tilpasForsikringstal.indregningDetaljer =
            insuranceAdjustment.indregningDetaljer;
          this.insuranceState.insurance.tilpasForsikringstal.kraevesHS =
            insuranceAdjustment.kraevesHS;
          this.insuranceState.insurance.tilpasForsikringstal.aegtefaelle =
            insuranceAdjustment.aegtefaelle;
          this.lifeInputConfig =
            this.insuranceAdjustmentLifeService.createInputConfig(
              this.insuranceState,
              this.insuranceAdjustmentValuesOriginal,
              this.insuranceGuideChecks
            );
          this.insuranceAdjustmentUpdateService.lifeCprUpdated(
            this.lifeInputConfig
          );
          this.globalWorkingService.hide();
        }
      });
  }

  private setSpouseError(isSpouse: boolean, value: boolean): void {
    if (isSpouse) {
      this.lifeInputConfig.spousePensionUpdateError = value;
    } else {
      this.lifeInputConfig.spousePensionUpdateExtraError = value;
    }
  }

  private updateInsuranceLifeValues(
    insuranceAdjustment: TilpasForsikringstal
  ): void {
    this.insuranceState.insurance.tilpasForsikringstal.sliderLiv.minimumsdaekning =
      insuranceAdjustment.sliderLiv.minimumsdaekning;
    this.insuranceState.insurance.tilpasForsikringstal.sliderLiv.pris =
      insuranceAdjustment.sliderLiv.pris;
    this.insuranceState.insurance.tilpasForsikringstal.skattekodevalgLivMulig =
      insuranceAdjustment.skattekodevalgLivMulig;
    this.insuranceState.insurance.tilpasForsikringstal.sliderLiv.forsikringstalBruttobeloeb =
      insuranceAdjustment.sliderLiv.forsikringstalBruttobeloeb;
    this.insuranceState.insurance.tilpasForsikringstal.sliderLiv.forsikringstalNettobeloeb =
      insuranceAdjustment.sliderLiv.forsikringstalNettobeloeb;
    this.insuranceState.insurance.tilpasForsikringstal.sliderLiv.daekningBeloebPrPolice =
      insuranceAdjustment.sliderLiv.daekningBeloebPrPolice;
    this.insuranceState.insurance.tilpasForsikringstal.sliderLiv.daekningProcentPrPolice =
      insuranceAdjustment.sliderLiv.daekningProcentPrPolice;
    this.insuranceState.insurance.tilpasForsikringstal.sliderLiv.currentForsikringstal =
      insuranceAdjustment.sliderLiv.currentForsikringstal;
  }

  private updateLifeDetails(): void {
    forkJoin({
      basket: this.basketApiService.accumulatedChanges(
        this.contentService.getLanguage().toUpperCase()
      ),
      pensionInfo: this.insuranceApiService.getPensionInfo()
    })
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(responses => {
        this.pensionPlanPolice = this.pensionPlanInfoService.mapPensionData(
          responses.pensionInfo
        );
        const policy =
          this.insuranceState.insurance.originalTilpasForsikringstal
            .policenummer;
        const insurancePredicate = insurance =>
          insurance.anbefalingsomraade === 'FORSIKRING' &&
          insurance.aendringsdatatype === 'LIV_ENGANG';
        const changes = responses.basket.aendringer
          .filter(change => change.pensionsaftaleId === policy)
          .filter(change => change.forsikring.some(insurancePredicate));
        if (changes[0]) {
          const lifeValue =
            changes[0].forsikring.find(insurancePredicate)
              ?.oenskeFletteParameter;
          if (lifeValue) {
            let lifeValueAsNumber = parseInt(lifeValue.replace(/\.*/g, ''), 10);
            if (
              this.insuranceState.insurance.tilpasForsikringstal.sliderLiv
                ?.daekningsspaendEnhed === 'Beloeb'
            ) {
              // krone-ordning
              lifeValueAsNumber = this.salary
                ? (lifeValueAsNumber / this.salary) * 100
                : lifeValueAsNumber;
            }
            this.lifeUpdateNote = this.contentUtilService.getContent(
              'DL.TF01.C145',
              [
                policy,
                this.numberFormat.transform(lifeValueAsNumber / 100, 2),
                this.numberFormat.transform(lifeValueAsNumber)
              ]
            );
          }
        }
        this.globalWorkingService.hide();
      });
  }

  private useRangeBoundsWithDecimals(amount: number): number {
    const min =
      this.insuranceState.insurance.tilpasForsikringstal.sliderLiv
        .minimumsdaekning;
    const max =
      this.insuranceState.insurance.tilpasForsikringstal.sliderLiv
        .forsikringstalMax;
    if (amount.toFixed(0) === min.toFixed(0)) {
      return min;
    }
    if (amount.toFixed(0) === max.toFixed(0)) {
      return max;
    }
    return amount;
  }

  handleBlockedPolicy(): void {
    this.overlay.policyBlocked = this.insuranceUtilService.isPolicyBlocked(
      this.insuranceState.insurance.tilpasForsikringstal
    );
    if (this.overlay.policyBlocked && !this.fromGuide) {
      this.overlay.show = true;
    }
  }

  private resetUnsavedChangesTypes(): void {
    this.unsavedChangesTypes.ci = false;
    this.unsavedChangesTypes.loc = false;
    this.unsavedChangesTypes.life = false;
  }

  getInputconfigs(): InputConfigs {
    return {
      ci: this.ciInputConfig,
      loc: this.locInputConfig,
      life: this.lifeInputConfig
    } as InputConfigs;
  }

  getOrderItems(): OrderItem[] {
    return this.insuranceAdjustmentTrackingService.getOrderItems(
      this.unsavedChangesTypes,
      this.getInputconfigs()
    );
  }

  public finish(): void {
    this.exit.emit();
  }
}
