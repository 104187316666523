import {
  Component,
  EventEmitter,
  HostListener,
  inject,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {
  expansion,
  openClose,
  slideInBottom,
  slideInRight,
  slideInRightWithDelay,
  slideOutBottom,
  slideOutRightWithDelay
} from '@pfa/animations';
import { InvestmentApiService } from '@pfa/api';
import {
  ConfirmChoiceStatus,
  ConfirmChoiceType,
  ContentUtilService,
  MiscTrackingService,
  TrackingName
} from '@pfa/core';
import {
  ClimatePlus,
  InForceSelection,
  InForceSelectionClimate,
  InvesteringPolice
} from '@pfa/gen';
import { InvestmentGuideInfo } from '@pfa/models';
import { InvestmentGuideService } from '../../guide/investment-guide.service';
import { YouInvestService } from '../../you-invest/you-invest.service';
import {
  getClimatePlusPercentage,
  getClimatePlusType,
  PfaClimateProfile
} from './pfa-climate-profile.model';
import {
  ConfirmChoiceComponentTexts,
  ConfirmChoiceTextsConfiguration,
  GlobalWorkingService
} from '@pfaform';

@Component({
  selector: 'mitpfa-pfa-climate-profiles',
  templateUrl: './pfa-climate-profiles.component.html',
  styleUrls: ['./pfa-climate-profiles.component.scss'],
  animations: [
    slideInBottom,
    slideOutBottom,
    slideOutRightWithDelay,
    slideInRightWithDelay,
    slideInRight,
    expansion,
    openClose
  ]
})
export class PfaClimateProfilesComponent implements OnInit {
  @Input() policy: InvesteringPolice;
  @Input() investmentGuideInfo: InvestmentGuideInfo;
  @Input() inForceSelections: InForceSelectionClimate[];

  @Input() originalPolice: InvesteringPolice;
  @Output() profileSelected = new EventEmitter<number>();
  @Output() choiceConfirmed = new EventEmitter<void>();
  @Input() policeId: string;

  private readonly trackingService: MiscTrackingService =
    inject(MiscTrackingService);
  private readonly contentUtilService: ContentUtilService =
    inject(ContentUtilService);
  private readonly investmentApiService: InvestmentApiService =
    inject(InvestmentApiService);
  private readonly youInvestService: YouInvestService =
    inject(YouInvestService);
  private readonly investmentGuideService: InvestmentGuideService = inject(
    InvestmentGuideService
  );
  private readonly globalWorkingService: GlobalWorkingService =
    inject(GlobalWorkingService);

  public confirmChoiceTextConfiguration: ConfirmChoiceTextsConfiguration = {
    confirm: {
      headline: 'DL.INV.C715',
      body: 'DL.INV.C565',
      button: 'DL.INV.C564'
    },
    reconfirm: {
      headline: 'DL.INV.C716',
      body: 'DL.INV.C720',
      button: 'DL.INV.C717'
    },
    thankYou: {
      headline: 'DL.INV.C714',
      body: 'DL.INV.C566',
      button: undefined
    }
  };

  climateProfiles: Array<PfaClimateProfile>;
  isClimateQuestionsAnswered: boolean;
  private readonly RESPONSIVE_BREAK = 1239;
  public showPercentageChooser = false;
  public selecedProfile: PfaClimateProfile;
  public responsiveMobile: boolean;
  public showCheckmark: boolean;
  public confirmClimateError: boolean;
  public confirmClimateDate: Date;
  public inForceSelection: InForceSelection;

  private recommendedProfile: PfaClimateProfile;
  private profileToConfirm: PfaClimateProfile;

  public confirmClimateSuccess: boolean;

  public confirmChoiceComponentTexts: ConfirmChoiceComponentTexts = {
    bodyContentId: this.confirmChoiceTextConfiguration.confirm.body,
    headlineContentId: this.confirmChoiceTextConfiguration.confirm.headline,
    buttonContentId: this.confirmChoiceTextConfiguration.confirm.button
  };

  public ngOnInit(): void {
    this.initClimateProfiles();

    this.responsiveMobile = window.innerWidth <= this.RESPONSIVE_BREAK;
    this.inForceSelection = this.getClimateInforceSelection();
    if (this.inForceSelection) {
      this.showCheckmark = true;
      this.confirmClimateDate = this.inForceSelection.timestamp;
      this.setReconfirmTexts();
    }
    this.profileToConfirm = this.climateProfiles.find(profile =>
      this.findClimateProfil(profile)
    );
    this.recommendedProfile = this.climateProfiles.find(
      profile => profile.isRecommended
    );
    if (this.profileToConfirm) {
      let status: ConfirmChoiceStatus;
      if (this.profileToConfirm.isDisabled) {
        status = ConfirmChoiceStatus.TAKE_GUIDE;
      } else if (this.confirmClimateDate) {
        status = ConfirmChoiceStatus.CONFIRMED;
      } else {
        status = ConfirmChoiceStatus.WAITING;
      }
      this.trackingService.trackConfirmChoiceStatus(TrackingName.INVESTMENT, [
        {
          itemConfirmName: `pfainvest: climate ${this.profileToConfirm.climatePlusPercentage}`,
          itemRecommendedName: `pfainvest: climate ${
            this.recommendedProfile?.climatePlusPercentage || 0
          }`,
          itemStatus: status
        }
      ]);
    }
  }

  private setReconfirmTexts() {
    this.confirmChoiceComponentTexts.bodyContentId =
      this.confirmChoiceTextConfiguration.reconfirm.body;
    this.confirmChoiceComponentTexts.headlineContentId =
      this.confirmChoiceTextConfiguration.reconfirm.headline;
    this.confirmChoiceComponentTexts.buttonContentId =
      this.confirmChoiceTextConfiguration.reconfirm.button;
  }

  private getClimateInforceSelection(): InForceSelection {
    return (
      this.inForceSelections &&
      this.inForceSelections.find(ifs => {
        if (
          ifs.inForceSelectionType === 'Climate' &&
          ifs.policyId === this.policeId
        ) {
          return ifs;
        }
        return null;
      })
    );
  }

  public profileClicked(profile: PfaClimateProfile): void {
    if (
      this.investmentGuideInfo &&
      this.investmentGuideInfo.isGuideCompleted &&
      this.isClimateQuestionsAnswered
    ) {
      if (profile.type === 'CLIMATE_PLUS_HIGH') {
        this.showPercentageChooser = true;
        this.selecedProfile = profile;
      } else {
        this.updateProfileSelected(profile);
      }
    }
  }

  @HostListener('window:resize', ['$event'])
  public onResize(event): void {
    this.responsiveMobile = event.target.innerWidth <= this.RESPONSIVE_BREAK;
  }

  private updateProfileSelected(profile: PfaClimateProfile) {
    if (profile.type === 'CLIMATE_PLUS_HIGH') {
      profile.title = profile.climatePlusPercentage
        ? this.contentUtilService.getContent('DL.INV.C488') +
          ' ' +
          profile.climatePlusPercentage +
          ' %'
        : 'DL.INV.C488';
    }
    this.climateProfiles.forEach(p => (p.isSelected = false));
    profile.isSelected = true;
    this.profileSelected.emit(profile.climatePlusPercentage);
  }

  public initClimateProfiles(): void {
    this.initClimateQuestionsAnswered();
    this.climateProfiles = [
      {
        title: 'DL.INV.C479',
        isSelected:
          getClimatePlusType(this.policy.klimaPlusAndel) ===
          'CLIMATE_PLUS_NONE',
        isCurrent:
          getClimatePlusType(this.policy.klimaPlusAndel) ===
          'CLIMATE_PLUS_NONE',
        isDisabled: this.isDisabled(),
        isRecommended: this.isRecommended('CLIMATE_PLUS_NONE'),
        benefitItems: [
          { text: 'DL.INV.C480', bulletColor: 'RED' },
          { text: 'DL.INV.C481', bulletColor: 'RED' }
        ],
        type: 'CLIMATE_PLUS_NONE',
        climatePlusPercentage: getClimatePlusPercentage('CLIMATE_PLUS_NONE')
      },
      {
        title: 'DL.INV.C482',
        isSelected:
          getClimatePlusType(this.policy.klimaPlusAndel) === 'CLIMATE_PLUS_LOW',
        isCurrent:
          getClimatePlusType(this.policy.klimaPlusAndel) === 'CLIMATE_PLUS_LOW',
        isDisabled: this.isDisabled(),
        isRecommended: this.isRecommended('CLIMATE_PLUS_LOW'),
        benefitItems: [
          { text: 'DL.INV.C483', bulletColor: 'RED' },
          { text: 'DL.INV.C484', bulletColor: 'RED' }
        ],
        type: 'CLIMATE_PLUS_LOW',
        climatePlusPercentage: getClimatePlusPercentage('CLIMATE_PLUS_LOW')
      },
      {
        title: 'DL.INV.C485',
        isSelected:
          getClimatePlusType(this.policy.klimaPlusAndel) ===
          'CLIMATE_PLUS_MIDDLE',
        isCurrent:
          getClimatePlusType(this.policy.klimaPlusAndel) ===
          'CLIMATE_PLUS_MIDDLE',
        isDisabled: this.isDisabled(),
        isRecommended: this.isRecommended('CLIMATE_PLUS_MIDDLE'),
        benefitItems: [
          { text: 'DL.INV.C486', bulletColor: 'RED' },
          { text: 'DL.INV.C487', bulletColor: 'RED' }
        ],
        type: 'CLIMATE_PLUS_MIDDLE',
        climatePlusPercentage: getClimatePlusPercentage('CLIMATE_PLUS_MIDDLE')
      },
      {
        title:
          this.policy.klimaPlusAndel &&
          getClimatePlusType(this.policy.klimaPlusAndel) === 'CLIMATE_PLUS_HIGH'
            ? this.contentUtilService.getContent('DL.INV.C488') +
              ' ' +
              this.policy.klimaPlusAndel +
              ' %'
            : 'DL.INV.C488',
        isSelected:
          getClimatePlusType(this.policy.klimaPlusAndel) ===
          'CLIMATE_PLUS_HIGH',
        isCurrent:
          getClimatePlusType(this.policy.klimaPlusAndel) ===
          'CLIMATE_PLUS_HIGH',
        isDisabled: this.isDisabled(),
        isRecommended: this.isRecommended('CLIMATE_PLUS_HIGH'),
        benefitItems: [
          { text: 'DL.INV.C489', bulletColor: 'RED' },
          { text: 'DL.INV.C490', bulletColor: 'RED' }
        ],
        type: 'CLIMATE_PLUS_HIGH',
        climatePlusPercentage: this.policy.klimaPlusAndel
      }
    ];
  }

  public initClimateQuestionsAnswered(): void {
    this.isClimateQuestionsAnswered = false;
    const climateRelevance = this.investmentGuideService.getClimateRelevance();

    if (
      this.investmentGuideInfo &&
      this.investmentGuideInfo.recommendation &&
      this.investmentGuideInfo.recommendation.investmentQuestionaire
    ) {
      if (
        !climateRelevance ||
        (climateRelevance &&
          this.investmentGuideInfo.recommendation.investmentQuestionaire
            .climateExtendedFocus &&
          this.investmentGuideInfo.recommendation.investmentQuestionaire
            .climateWillingness &&
          this.investmentGuideInfo.recommendation.investmentQuestionaire
            .climatePriority)
      ) {
        this.isClimateQuestionsAnswered = true;
      }
    }
  }

  isRecommended(type: ClimatePlus) {
    return (
      this.investmentGuideInfo &&
      this.investmentGuideInfo.recommendation &&
      type === this.investmentGuideInfo.recommendation.calculatedClimatePlus &&
      this.isClimateQuestionsAnswered
    );
  }

  isDisabled() {
    return (
      !this.investmentGuideInfo ||
      !this.investmentGuideInfo.isGuideCompleted ||
      !this.isClimateQuestionsAnswered ||
      !this.policy.klimaPlusMuligt ||
      this.youInvestService.check100YouInvest(this.policy) ||
      this.investmentGuideInfo.isGuideMoreThan3YearOld
    );
  }

  percentageChosen(isPercentChosen: boolean) {
    if (isPercentChosen) {
      this.updateProfileSelected(this.selecedProfile);
    }
    this.showPercentageChooser = false;
  }

  showKeepProfile(profile: PfaClimateProfile): boolean {
    return this.findClimateProfil(profile);
  }

  showKeepProfiles(): boolean {
    return this.climateProfiles.some(profile =>
      this.findClimateProfil(profile)
    );
  }

  confirmProfile() {
    this.globalWorkingService.show();
    this.confirmClimateError = false;

    const selectionProfile = {
      inForceSelectionType: 'Climate',
      timestamp: new Date(),
      policyId: this.policeId,
      passiveAcceptance: false,
      selection: this.profileToConfirm.climatePlusPercentage,
      defaultSelection: this.profileToConfirm.climatePlusPercentage,
      recommendation: this.getClimateRecommendation(),
      employeeNumber: ''
    };

    this.investmentApiService
      .saveDefaultClimateProfileChoice(selectionProfile)
      .subscribe({
        next: () => {
          this.confirmChoiceComponentTexts.bodyContentId =
            this.confirmChoiceTextConfiguration.thankYou.body;
          this.confirmChoiceComponentTexts.headlineContentId =
            this.confirmChoiceTextConfiguration.thankYou.headline;
          this.showCheckmark = true;
          this.trackingService.trackConfirmChoiceClick(
            TrackingName.INVESTMENT,
            [
              {
                itemConfirmName: `pfainvest: climate ${this.profileToConfirm.climatePlusPercentage}`,
                itemConfirmType: this.confirmClimateDate
                  ? ConfirmChoiceType.RECONFIRM
                  : ConfirmChoiceType.FIRST,
                itemRecommendedName: `pfainvest: climate ${!(
                  !this.recommendedProfile?.climatePlusPercentage && !0
                )}`
              }
            ]
          );

          this.choiceConfirmed.emit();
          this.confirmClimateSuccess = true;
          this.globalWorkingService.hide();
        },
        error: () => {
          this.confirmClimateError = true;
          this.globalWorkingService.hide();
        }
      });
  }

  isGuideTaken(): boolean {
    return (
      this.investmentGuideInfo && this.investmentGuideInfo.isGuideCompleted
    );
  }

  private getClimateRecommendation(): number {
    const recommendationClimatepercent =
      this.investmentGuideInfo.recommendation.recommendedClimatePercent;
    if (
      recommendationClimatepercent === null ||
      recommendationClimatepercent === undefined
    ) {
      return null;
    } else {
      return recommendationClimatepercent;
    }
  }

  private findClimateProfil(profile: PfaClimateProfile): boolean {
    const klimaplusAndel = this.originalPolice
      ? this.originalPolice.klimaPlusAndel
      : null;
    return (
      this.getClimatePlusType(klimaplusAndel) === profile.type ||
      (klimaplusAndel === 75 &&
        this.getClimatePlusType(klimaplusAndel) === 'CLIMATE_PLUS_HIGH')
    );
  }

  private getClimatePlusType(percentage: number): ClimatePlus {
    switch (percentage) {
      case 0:
      case null:
      case undefined:
        return 'CLIMATE_PLUS_NONE';
      case 15:
        return 'CLIMATE_PLUS_LOW';
      case 30:
        return 'CLIMATE_PLUS_MIDDLE';
      default:
        return 'CLIMATE_PLUS_HIGH';
    }
  }
}
