/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const ResidenceSchema = JoiObj.object({
        currentMarketValue:
            
                    JoiObj.number()
                


.required()


        ,
        estateDebt:
            
                    JoiObj.number()
                


.required()


        ,
        estimatedDebtAtPension:
            
                    JoiObj.number()
                


.required()


        ,
        interestType:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        percentageOwned:
            
                    JoiObj.number()
                


.required()


        ,
        personalValueFromResidence:
            
                    JoiObj.number()
                


.required()


        ,
        realEstateName:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        repaymentType:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        reverseMortgagePercent:
            
                    JoiObj.number()
                


.required()


        
    })
.id('ResidenceSchema')

export interface Residence { 
    currentMarketValue: number;
    estateDebt: number;
    estimatedDebtAtPension: number;
    interestType: string;
    percentageOwned: number;
    personalValueFromResidence: number;
    realEstateName: string;
    repaymentType: string;
    reverseMortgagePercent: number;
}

