import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges
} from '@angular/core';
import * as d3 from 'd3';
import { Subject } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { DataPoint, LineChartArea } from './line-chart.model';

@Component({
  selector: 'co-linechart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss']
})
export class LineChartComponent implements OnChanges, AfterViewInit, OnDestroy {
  @Input() data: DataPoint[];
  @Input() background: LineChartArea;
  @Input() minValue;
  @Input() maxValue;
  @Input() margin = 16;

  private width: number;
  private height: number;

  private svg: d3.Selection<SVGSVGElement, {}, Element, any>;
  private svgInner: d3.Selection<SVGElement, {}, Element, any>;
  private yScale: d3.ScaleLinear<number, {}, any>;
  private xScale: d3.ScaleLinear<number, {}, any>;
  private xAxis: d3.Selection<SVGElement, {}, Element, any>;
  private lineGroup: d3.Selection<SVGPathElement, {}, Element, any>;

  private readonly onChanges = new Subject<SimpleChanges>();

  constructor(private readonly el: ElementRef) {}

  ngAfterViewInit() {
    // forces fisrt draw when width and height are known
    this.onChanges.pipe(startWith({})).subscribe(() => {
      if (this.data) {
        this.initializeChart();
        this.drawChart();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.onChanges.next(changes);
  }

  ngOnDestroy() {
    this.onChanges.complete();
  }

  private initializeChart(): void {
    this.width = this.el.nativeElement.getBoundingClientRect().width;
    this.height = this.el.nativeElement.getBoundingClientRect().height;

    d3.select(this.el.nativeElement)
      .select('.linechart')
      .selectAll('svg')
      .remove();

    this.svg = d3
      .select(this.el.nativeElement)
      .select('.linechart')
      .append('svg')
      .attr('height', this.height)
      .attr('width', this.width);

    this.svgInner = this.svg
      .append('g')
      .style('transform', `translate(${this.margin}px, ${this.margin}px)`);

    this.yScale = d3
      .scaleLinear([0, this.height - 2 * this.margin])
      .domain([
        this.maxValue || d3.max(this.data, d => d.y) + 50,
        this.minValue || d3.min(this.data, d => d.y) - 50
      ]);

    this.xScale = d3
      .scaleLinear([this.margin, this.width - 2 * this.margin])
      .domain([d3.min(this.data, d => d.x), d3.max(this.data, d => d.x)]);

    this.xAxis = this.svgInner
      .append('g')
      .attr('id', 'x-axis')
      .style('transform', `translate(0, ${this.yScale(0)}px)`)
      .style('stroke-dasharray', '2 3');

    this.lineGroup = this.svgInner
      .append('g')
      .append('path')
      .attr('id', 'line')
      .style('fill', 'none')
      .style('stroke', 'url(#svgGradient)')
      .style('stroke-width', '4px');

    this.createGradient();
  }

  private drawChart(): void {
    this.drawAxis();
    this.drawLine();
    if (this.background) {
      this.drawArea();
    }
  }

  private drawAxis(): void {
    const xAxis = d3.axisBottom(this.xScale).tickValues([]).tickSize(0);
    this.xAxis.call(xAxis);
  }

  private drawLine() {
    const line = d3
      .line()
      .x(d => d[0])
      .y(d => d[1])
      .curve(d3.curveMonotoneX);

    const points: [number, number][] = this.data.map(d => [
      this.xScale(d.x),
      this.yScale(d.y)
    ]);

    this.lineGroup.attr('d', line(points));

    const totalLength = this.lineGroup.node().getTotalLength();
    this.lineGroup
      .attr('stroke-dasharray', totalLength + ' ' + totalLength)
      .attr('stroke-dashoffset', totalLength)
      .transition()
      .duration(2000)
      .ease(d3.easeLinear)
      .attr('stroke-dashoffset', 0);
  }

  private drawArea(): void {
    const area = d3
      .area()
      .x(d => d[0])
      .y0(this.yScale(0))
      .y1(d => d[1])
      .curve(d3.curveMonotoneX);

    const topBorderPoints: [number, number][] = this.background.topBorder.map(
      d => [this.xScale(d.x), this.yScale(d.y)]
    );
    this.svgInner
      .append('path')
      .datum(topBorderPoints)
      .attr('class', 'area')
      .attr('d', area)
      .attr('id', 'top')
      .style('opacity', '0.1');

    const bottomBorderPoints: [number, number][] =
      this.background.bottomBorder.map(d => [
        this.xScale(d.x),
        this.yScale(d.y)
      ]);
    this.svgInner
      .append('path')
      .datum(bottomBorderPoints)
      .attr('class', 'area')
      .attr('d', area)
      .attr('id', 'bottom')
      .style('opacity', '0.1');
  }

  private createGradient(): void {
    const defs = this.svg.append('defs');

    const minValue = d3.min(this.data, d => d.y);
    const maxValue = d3.max(this.data, d => d.y);
    const valueRange = maxValue - minValue;
    const xAxisYPositionAsPercentage =
      minValue < 0 ? (maxValue / valueRange) * 100 : 100;

    const gradient = defs
      .append('linearGradient')
      .attr('id', 'svgGradient')
      .attr('x1', '0')
      .attr('x2', '0')
      .attr('y1', `${xAxisYPositionAsPercentage}%`)
      .attr('y2', '100%');

    gradient.append('stop').attr('offset', '0%').attr('stop-color', '#338484');

    gradient.append('stop').attr('offset', '0%').attr('stop-color', '#338484');

    gradient.append('stop').attr('offset', '0%').attr('stop-color', 'black');

    gradient.append('stop').attr('offset', '100%').attr('stop-color', 'black');
  }
}
