import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  HttpClient,
  HttpErrorResponse,
  HttpResponse
} from '@angular/common/http';
import { GlobalWorkingService, CookieService } from '@pfaform';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { switchMap, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { PinCodeLoginStep } from '../logon.model';
import { AuthHttpErrorResponse } from './pincode-errors';
import { PincodeLoginErrorService } from './pincode-login-error.service';
import { LoginService } from '@mitpfa/shared';
import { CustomerprofileStore } from '@pfa/gen';

@Component({
  selector: 'mitpfa-pincode-login',
  templateUrl: './pincode-login.component.html',
  styleUrls: ['./pincode-login.component.scss']
})
export class PincodeLoginComponent implements OnInit, OnDestroy {
  @Output() step = new EventEmitter<PinCodeLoginStep>();
  public mechanism: string;
  public loginForm = new UntypedFormGroup({
    username: new UntypedFormControl('', [
      Validators.required,
      Validators.pattern('^[0-9]*')
    ]),
    password: new UntypedFormControl('', Validators.required)
  });
  public pinForm = new UntypedFormGroup({
    otp: new UntypedFormControl('', [
      Validators.required,
      Validators.pattern('^[0-9]*')
    ])
  });

  public otpError: string | null;
  public passwordError: string | undefined;
  public hint: string;

  public get otpMaxRetries(): boolean {
    return this.loginErrorService.otpMaxRetries;
  }

  private location: string;
  private readonly unsubscribe = new Subject<void>();

  constructor(
    private readonly globalWorkingService: GlobalWorkingService,
    private readonly route: ActivatedRoute,
    private readonly customerprofileStore: CustomerprofileStore,
    private readonly cookieService: CookieService,
    private readonly loginService: LoginService,
    private readonly loginErrorService: PincodeLoginErrorService,
    private readonly http: HttpClient
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.mechanism = params.mechanism;
      this.location = params.location;
    });
    this.step.emit(PinCodeLoginStep.PIN);
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public logon(): void {
    this.globalWorkingService.show();

    const logonRequest = this.isPassword()
      ? this.getPinStepRequest()
      : this.getOtpStepRequest();

    // if redirected directly to pin login then submit location has not been established.
    if (this.location) {
      this.submitLogin(logonRequest);
    } else {
      this.customerprofileStore
        .customerprofileGet()
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(
          () => {},
          error => {
            this.location = error.error.location;
            this.submitLogin(logonRequest);
          }
        );
    }
  }

  public isPassword(): boolean {
    return !this.mechanism || this.mechanism.indexOf('macotp') === -1;
  }

  public handlePincodeResponse(
    response: HttpResponse<any> | AuthHttpErrorResponse
  ) {
    if (
      response instanceof HttpErrorResponse &&
      this.isPincodeSuccess(response)
    ) {
      this.switchToSMSStep(
        response.error.location,
        response.error['otp.user.otp-hint']
      );
      return;
    }
    this.passwordError = this.loginErrorService.handlePincodeResponse(response);
  }

  private getPinStepRequest() {
    return {
      params: {
        PolicyId: 'urn:ibm:security:authentication:asf:password_smsotp'
      },
      entity: {
        username: this.loginForm.value.username,
        password: this.loginForm.value.password
      },
      ispwd: true
    };
  }

  private getOtpStepRequest() {
    return {
      params: {},
      entity: {
        'otp.user.otp': this.pinForm.value.otp,
        'otp.user.otp-hint': this.hint
      },
      ispwd: false
    };
  }

  private submitLogin(logonRequest): void {
    this.http
      .put(this.location, logonRequest.entity, {
        params: logonRequest.params,
        observe: 'response'
      })
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (response: HttpResponse<Object>) => {
          if (response.status === 204) {
            this.loginService.setCprLogin();
            this.passwordError = undefined;
            this.cookieService.setCookieForOneYear(
              'lastLoginMethod',
              'password'
            );

            this.customerprofileStore
              .customerprofileGet()
              .pipe(
                takeUntil(this.unsubscribe),
                switchMap(() => this.loginService.handleInitialLogin())
              )
              .subscribe();
          } else {
            this.handlePincodeResponse(response);
          }
        },
        error => {
          this.globalWorkingService.hide();
          if (error.status === 500) {
            return;
          }
          if (error.error.location) {
            // stateID kan skifte på fejl.
            this.location = error.error.location;
          }
          if (logonRequest.ispwd) {
            this.handlePincodeResponse(error);
          } else {
            this.otpError = this.loginErrorService.handleSMSError(error);
          }
        }
      );
  }

  private isPincodeSuccess(response: AuthHttpErrorResponse): boolean {
    return (
      response.error?.mechanism?.includes('macotp') &&
      !response.error?.exceptionMsg?.includes('+null')
    );
  }

  private switchToSMSStep(location: string, hint: string) {
    this.location = location;
    this.mechanism = 'macotp';
    this.hint = hint;
    this.step.emit(PinCodeLoginStep.OTP);
  }
}
