<span
  class="amount"
  attr.data-test-id="{{ testId }}"
  *ngIf="amount !== undefined && isNumeric(amount)"
  [ngClass]="{ 'amount-disabled': disabled }"
>
  {{ amount | number: format : 'da-DK' }}
</span>
<span
  class="amount"
  attr.data-test-id="{{ testId }}"
  *ngIf="amount !== undefined && !isNumeric(amount)"
  [ngClass]="{ 'amount-disabled': disabled }"
>
  {{ amount | content }}
</span>
<span
  class="unit"
  [coContent]="unit"
  [coContentSubst]="unitSubst"
  [ngClass]="{ 'unit-disabled': disabled }"
></span>
