/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PraemieFritagelseTilvalg } from './praemieFritagelseTilvalg';
import { PraemieFritagelseTilvalgSchema } from './praemieFritagelseTilvalg';
import * as JoiObj from "joi";


    export const HenstandPoliceSchema = JoiObj.object({
        arbejdsgiver:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        novo:
            
                    JoiObj.boolean()
                


.required()


        ,
        ophoerdato:
            
            JoiObj.date()


.required()


        ,
        opsparing:
            
                    JoiObj.number()
                


.required()


        ,
        paragraf53a:
            
                    JoiObj.boolean()
                


.required()


        ,
        policenr:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        praemieFritagelseTilvalg:
            
        JoiObj.link('#PraemieFritagelseTilvalgSchema')


.required()


        
    })
                .shared(PraemieFritagelseTilvalgSchema)
.id('HenstandPoliceSchema')

export interface HenstandPolice { 
    arbejdsgiver: string;
    novo: boolean;
    ophoerdato: Date;
    opsparing: number;
    paragraf53a: boolean;
    policenr: string;
    praemieFritagelseTilvalg: PraemieFritagelseTilvalg;
}
export namespace HenstandPolice {
}


