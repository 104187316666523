import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { RecommendationComponent } from './components/recommendation/recommendation.component';
import { Injectable, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MatFormFieldModule,
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldDefaultOptions
} from '@angular/material/form-field';
import { CoreModule, ForbeholdModalService } from '@pfa/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { IntroboxComponent } from './components/introbox/introbox.component';
import { BodytextComponent } from './components/bodytext/bodytext.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { IconModule } from '@pfa/icons';
import { AccordionComponent } from './components/accordion/accordion.component';
import { DATE_FORMAT } from './material/date-format';
import {
  ErrorStateMatcher,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  ShowOnDirtyErrorStateMatcher
} from '@angular/material/core';
import { SliderComponent } from './components/slider/slider.component';
import { QuickActionComponent } from './components/quick-action/quick-action.component';
import { CountryCodeSelectComponent } from './components/country-code-select/country-code-select.component';
import { MatSelectModule } from '@angular/material/select';
import { StepperComponent } from './components/stepper/stepper.component';
import { SliderFormComponent } from './components/slider-form/slider-form.component';
import { MatButtonModule } from '@angular/material/button';
import { SnackBarComponent } from './components/snackbar/snackbar.component';
import { PrimaryNumberComponent } from './components/primary-number/primary-number.component';
import {
  CarouselComponent,
  CarouselItemDirective
} from './components/carousel/carousel.component';
import {
  HammerGestureConfig,
  HammerModule,
  HAMMER_GESTURE_CONFIG
} from '@angular/platform-browser';
import * as Hammer from 'hammerjs';
import { SelectionWrapperComponent } from './components/selection-wrapper/selection-wrapper.component';
import { TooltipComponent } from './components/tooltip/tooltip.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { QuickActionBarComponent } from './components/quick-action-bar/quick-action-bar.component';
import { BulletListCheckmarkComponent } from './components/bullet-list-checkmark/bullet-list-checkmark.component';
import { QuestionnaireAccordionComponent } from './components/questionnaire-accordion/questionnaire-accordion.component';
import { TableAccordionControlComponent } from './components/table-accordion-control/table-accordion-control.component';
import { NotificationComponent } from './components/notification/notification.component';
import { PensionPlanCardListComponent } from './components/pensionplan-card-list/pensionplan-card-list.component';
import { PdfPreviewComponent } from './components/pdf-preview/pdf-preview.component';
import { NotifyComponent } from './components/notify/notify.component';
import { NavigationCardComponent } from './components/navigation-card/navigation-card.component';
import { NewBusinessOfferOverviewComponent } from './components/new-business-offer-overview/new-business-offer-overview.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { DaekningCurrentComponent } from './components/new-business-offer-overview/continuation-comparison/daekning-current/daekning-current.component';
import { SaveLanguageModalComponent } from './components/save-language/save-language-modal.component';
import { InvestmentComponent } from './components/new-business-offer-overview/investment/investment.component';
import { CoveragePayoutComponent } from './components/new-business-offer-overview/prices-new-offer/coverage-payout/coverage-payout.component';
import { CoveragePriceComponent } from './components/new-business-offer-overview/prices-new-offer/coverage-price/coverage-price.component';
import { ContinuationComparisonComponent } from './components/new-business-offer-overview/continuation-comparison/continuation-comparison.component';
import { DaekningAfterComponent } from './components/new-business-offer-overview/continuation-comparison/daekning-after/daekning-after.component';
import { InsuranceComponent } from './components/new-business-offer-overview/insurance/insurance.component';
import { PricesNewOfferComponent } from './components/new-business-offer-overview/prices-new-offer/prices-new-offer.component';
import { AgreementAndPaymentComponent } from './components/new-business-offer-overview/agreement-and-payment/agreement-and-payment.component';
import { FooterContactStandardComponent } from './components/footer/contact/standard/footer-contact-standard.component';
import { ContactMeComponent } from './components/contact-me/contact-me.component';
import {
  CheckboxGroupComponent,
  CheckboxGroupContentDirective
} from './components/checkbox-group/checkbox-group.component';
import { InsuranceDetailsCoveragesDetailComponent } from './components/insurance-details-coverages-detail/insurance-details-coverages-detail.component';
import { AttachmentComponent } from './components/attachment/attachment.component';
import { CloseWarningComponent } from './components/close-warning/close-warning.component';
import { CardArrowComponent } from './components/card-arrow/card-arrow.component';
import { CardSplitVerticalComponent } from './components/card-split-vertical/card-split-vertical.component';
import { CardComponent } from './components/card/card.component';
import { GlobalWorkingComponent } from './components/global-working/global-working.component';
import { ConfirmationModalComponent } from './components/consent/confirmation-modal/confirmation-modal.component';
import { BeneficiaryNavContentComponent } from './components/beneficiary-nav-content/beneficiary-nav-content.component';
import { FooterContactAdvisorComponent } from './components/footer/contact/advisor/footer-contact-advisor.component';
import { LifeInsuranceTableComponent } from './components/life-insurance-table/life-insurance-table.component';
import { LoadingComponent } from './components/loading/loading.component';
import { CustomerContactDetailsComponent } from './components/customer-contact-details/customer-contact-details.component';
import { CardSplitHorizontalComponent } from './components/card-split-horizontal/card-split-horizontal.component';
import { LineChartComponent } from './components/linechart/line-chart.component';
import { CardQuestionComponent } from './components/card-question/card-question.component';
import { LevelBarComponent } from './components/level-bar/level-bar.component';
import { FusionchartsPieLegendComponent } from './components/FusionchartsPieLegend/fusioncharts-pie-legend.component';
import { InsuranceCardHeaderComponent } from './components/insurance-card-header/insurance-card-header.component';
import { FooterContactComponent } from './components/footer/contact/footer-contact.component';
import { AdvisorBoxComponent } from './components/advisor-box/advisor-box.component';
import { CheckboxGroupLabelComponent } from './components/checkbox-group/label/checkbox-group-label.component';
import { ConfirmChoiceComponent } from './components/confirm-choice/confirm-choice.component';
import { DatetimepickerComponent } from './components/datetimepicker/datetimepicker.component';
import { FooterComponent } from './components/footer/footer.component';
import { FooterContactBrokerComponent } from './components/footer/contact/broker/footer-contact-broker.component';
import { CardAnimatedStackItemComponent } from './components/card-animated-stack/card-animated-stack-item/card-animated-stack-item.component';
import { BeneficiaryIconComponent } from './components/beneficiary-icon/beneficiary-icon.component';
import { FusionchartsBarLegendComponent } from './components/FusionchartsBarLegend/fusioncharts-bar-legend.component';
import { ErrorComponent } from './components/error/error.component';
import { AdvisorCorrectionComponent } from './components/advisor-correction/advisor-correction.component';
import { CustomerAdvisorCorrectionsComponent } from './components/customer-advisor-corrections/customer-advisor-corrections.component';
import { InsuranceCardBodyComponent } from './components/insurance-card-body/insurance-card-body.component';
import { CountdownComponent } from './components/countdown/countdown.component';
import { IconWithTextComponent } from './components/icon-with-text/icon-with-text.component';
import { ChangeIndicatorComponent } from './components/change-indicator/change-indicator.component';
import { CardAnimatedStackComponent } from './components/card-animated-stack/card-animated-stack.component';
import { ConsentComponent } from './components/consent/consent.component';
import { NgbDateParserFormatter, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ConsentsConfigurationService } from './components/consent/consents-configuration.service';
import { NgbMomentDateParserFormatter } from './components/datetimepicker/datepicker-formatter';
import { CardSplitVerticalService } from './components/card-split-vertical/card-split-vertical.service';
import { EditFieldDateComponent } from './components/edit-field-date/edit-field-date.component';

import {
  FileDropDirective,
  FileSelectDirective,
  FileUploadModule
} from 'ng2-file-upload';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { WarningComponent } from './components/warning/warning.component';
import { SpacerSectionComponent } from './components/spacer/spacer-section.component';
import { ShowPdfModalComponent } from './components/show-pdf/show-pdf-modal.component';
import { SpacerPageEndComponent } from './components/spacer/spacer-page-end.component';
import { UnsavedChangesWarningComponent } from './components/unsaved-changes-warning/unsaved-changes-warning.component';
import { SigningPopupComponent } from './components/signing-popup/signing-popup.component';
import { StickyBarComponent } from './components/sticky-bar/sticky-bar.component';
import { ShowPdfSheetComponent } from './components/show-pdf-sheet/show-pdf-sheet.component';
import { ToggleComponent } from './components/toggle/toggle.component';
import { RouterModule } from '@angular/router';
import { PdfPopupService } from './components/show-pdf/pdfPopup.service';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { InfoComponent } from './components/info/info.component';
import { ScrollToService } from './services/scroll-to.service';
import { PriserService } from './services/priser.service';
import { ContactInfoComponent } from './components/contact-info/contact-info.component';
import { SigningMitIDComponent } from './components/signing-mitid/signing-mitid.component';
import { SigningMitIDMobileComponent } from './components/signing-mitid-mobile/signing-mitid-mobile.component';
import { PopupComponent } from './components/popup/popup.component';
import { FocusDirective } from './directives/focus.directive';
import { MatTabsModule } from '@angular/material/tabs';
import { InsuranceLocAutoadjustmentInfoComponent } from './components/insurance-loc-autoadjustment-info/insurance-loc-autoadjustment-info.component';
import { EditFieldSelectComponent } from './components/edit-field-select/edit-field-select.component';
import { NgxSliderModule } from 'ngx-slider-v2';
import { AlertComponent } from './components/alert/alert.component';
import { DisableOverlayComponent } from './components/disable-overlay/disable-overlay.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ContactRemindComponent } from './components/contact-remind/contact-remind.component';

const appearance: MatFormFieldDefaultOptions = {
  appearance: 'fill'
};

@Injectable({
  providedIn: 'root'
})
class HammerConfig extends HammerGestureConfig {
  overrides = {
    swipe: { enable: true, direction: Hammer.DIRECTION_HORIZONTAL },
    pan: { enable: true, direction: Hammer.DIRECTION_HORIZONTAL },
    pinch: { enable: false },
    rotate: { enable: false }
  };
}
@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    FileUploadModule,
    FormsModule,
    HammerModule,
    IconModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatTooltipModule,
    NgxSliderModule,
    NgbModule,
    PdfJsViewerModule,
    ReactiveFormsModule,
    RouterModule,
    MatTabsModule,
    MatSlideToggleModule
  ],
  exports: [
    CustomerContactDetailsComponent,
    ContactRemindComponent,
    ToggleComponent,
    PopupComponent,
    ShowPdfSheetComponent,
    StickyBarComponent,
    SigningPopupComponent,
    SigningMitIDComponent,
    SigningMitIDMobileComponent,
    SpacerPageEndComponent,
    FooterComponent,
    ConfirmChoiceComponent,
    SpacerSectionComponent,
    WarningComponent,
    SpinnerComponent,
    IntroboxComponent,
    BodytextComponent,
    AccordionComponent,
    TableAccordionControlComponent,
    SliderComponent,
    RecommendationComponent,
    QuickActionComponent,
    CountryCodeSelectComponent,
    StepperComponent,
    SliderFormComponent,
    SnackBarComponent,
    PrimaryNumberComponent,
    CarouselItemDirective,
    CarouselComponent,
    SelectionWrapperComponent,
    TooltipComponent,
    QuickActionBarComponent,
    QuestionnaireAccordionComponent,
    BulletListCheckmarkComponent,
    NotificationComponent,
    NavigationCardComponent,
    PensionPlanCardListComponent,
    NewBusinessOfferOverviewComponent,
    NotifyComponent,
    ProgressBarComponent,
    PdfPreviewComponent,
    GlobalWorkingComponent,
    CardComponent,
    CardSplitHorizontalComponent,
    CardSplitVerticalComponent,
    InsuranceCardHeaderComponent,
    InsuranceCardBodyComponent,
    CardArrowComponent,
    AdvisorBoxComponent,
    CheckboxGroupComponent,
    CheckboxGroupContentDirective,
    LoadingComponent,
    FusionchartsPieLegendComponent,
    FusionchartsBarLegendComponent,
    AttachmentComponent,
    DatetimepickerComponent,
    FileDropDirective,
    FileSelectDirective,
    ConsentComponent,
    AgreementAndPaymentComponent,
    InvestmentComponent,
    InsuranceComponent,
    ContinuationComparisonComponent,
    DaekningCurrentComponent,
    DaekningAfterComponent,
    PricesNewOfferComponent,
    CoveragePayoutComponent,
    CoveragePriceComponent,
    CheckboxGroupLabelComponent,
    CardQuestionComponent,
    LineChartComponent,
    ChangeIndicatorComponent,
    CountdownComponent,
    CardAnimatedStackComponent,
    CardAnimatedStackItemComponent,
    ErrorComponent,
    LevelBarComponent,
    FooterContactComponent,
    FooterContactStandardComponent,
    FooterContactAdvisorComponent,
    FooterContactBrokerComponent,
    IconWithTextComponent,
    BeneficiaryIconComponent,
    AdvisorCorrectionComponent,
    CustomerAdvisorCorrectionsComponent,
    LifeInsuranceTableComponent,
    InsuranceDetailsCoveragesDetailComponent,
    BeneficiaryNavContentComponent,
    InfoComponent,
    ContactInfoComponent,
    FocusDirective,
    InsuranceLocAutoadjustmentInfoComponent,
    EditFieldDateComponent,
    EditFieldSelectComponent,
    AlertComponent,
    DisableOverlayComponent
  ],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMAT },
    { provide: MAT_DATE_LOCALE, useValue: 'da-DK' },
    { provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: appearance
    },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: HammerConfig
    },
    CardSplitVerticalService,
    NgbMomentDateParserFormatter,
    ConsentsConfigurationService,
    ScrollToService,
    PriserService,
    ForbeholdModalService,
    PdfPopupService,
    { provide: NgbDateParserFormatter, useClass: NgbMomentDateParserFormatter }
  ],
  declarations: [
    ToggleComponent,
    PopupComponent,
    ShowPdfSheetComponent,
    StickyBarComponent,
    SigningPopupComponent,
    UnsavedChangesWarningComponent,
    SpacerPageEndComponent,
    ShowPdfModalComponent,
    ConfirmChoiceComponent,
    SpacerSectionComponent,
    WarningComponent,
    SpinnerComponent,
    IntroboxComponent,
    BodytextComponent,
    AccordionComponent,
    SliderComponent,
    RecommendationComponent,
    QuickActionComponent,
    CountryCodeSelectComponent,
    StepperComponent,
    SliderFormComponent,
    SnackBarComponent,
    PrimaryNumberComponent,
    CarouselItemDirective,
    CarouselComponent,
    SelectionWrapperComponent,
    TooltipComponent,
    QuickActionBarComponent,
    QuestionnaireAccordionComponent,
    BulletListCheckmarkComponent,
    TableAccordionControlComponent,
    SaveLanguageModalComponent,
    NotificationComponent,
    NavigationCardComponent,
    PensionPlanCardListComponent,
    NewBusinessOfferOverviewComponent,
    AgreementAndPaymentComponent,
    InvestmentComponent,
    InsuranceComponent,
    ContinuationComparisonComponent,
    DaekningCurrentComponent,
    DaekningAfterComponent,
    PricesNewOfferComponent,
    CoveragePayoutComponent,
    CoveragePriceComponent,
    NotifyComponent,
    ProgressBarComponent,
    PdfPreviewComponent,
    GlobalWorkingComponent,
    CardComponent,
    CardSplitHorizontalComponent,
    CardSplitVerticalComponent,
    InsuranceCardHeaderComponent,
    InsuranceCardBodyComponent,
    CardArrowComponent,
    AdvisorBoxComponent,
    LoadingComponent,
    FusionchartsPieLegendComponent,
    FusionchartsBarLegendComponent,
    DatetimepickerComponent,
    CheckboxGroupComponent,
    CheckboxGroupContentDirective,
    CheckboxGroupLabelComponent,
    AttachmentComponent,
    ConsentComponent,
    ConfirmationModalComponent,
    CardQuestionComponent,
    LineChartComponent,
    CloseWarningComponent,
    CustomerContactDetailsComponent,
    ContactRemindComponent,
    ChangeIndicatorComponent,
    CountdownComponent,
    CardAnimatedStackComponent,
    CardAnimatedStackItemComponent,
    ErrorComponent,
    LevelBarComponent,
    FooterComponent,
    FooterContactComponent,
    FooterContactStandardComponent,
    FooterContactAdvisorComponent,
    FooterContactBrokerComponent,
    IconWithTextComponent,
    BeneficiaryIconComponent,
    AdvisorCorrectionComponent,
    CustomerAdvisorCorrectionsComponent,
    LifeInsuranceTableComponent,
    InsuranceDetailsCoveragesDetailComponent,
    ContactMeComponent,
    BeneficiaryNavContentComponent,
    InfoComponent,
    SigningMitIDComponent,
    SigningMitIDMobileComponent,
    ContactInfoComponent,
    FocusDirective,
    InsuranceLocAutoadjustmentInfoComponent,
    EditFieldDateComponent,
    EditFieldSelectComponent,
    AlertComponent,
    DisableOverlayComponent
  ]
})
export class PfaFormModule {}
