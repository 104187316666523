/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const DynamicAdvisingInvestmentProfileSchema = JoiObj.string().valid(
        'A'
        ,
        'B'
        ,
        'C'
        ,
        'D'
        ,
        'G'
        ,
        'GIPP'
        
    )
.id('DynamicAdvisingInvestmentProfileSchema')

export type DynamicAdvisingInvestmentProfile = 'A' | 'B' | 'C' | 'D' | 'G' | 'GIPP';

export const DynamicAdvisingInvestmentProfile = {
    A: 'A' as DynamicAdvisingInvestmentProfile,
    B: 'B' as DynamicAdvisingInvestmentProfile,
    C: 'C' as DynamicAdvisingInvestmentProfile,
    D: 'D' as DynamicAdvisingInvestmentProfile,
    G: 'G' as DynamicAdvisingInvestmentProfile,
    Gipp: 'GIPP' as DynamicAdvisingInvestmentProfile
};

