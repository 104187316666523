/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ApplyFor } from './applyFor';
import { ApplyForSchema } from './applyFor';
import * as JoiObj from "joi";


    export const TreatmentTypeSchema = JoiObj.object({
        applyFors:
                JoiObj.array().items(
        
        JoiObj.link('#ApplyForSchema')

        )
        

.required()


        ,
        id:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        label:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        
    })
                .shared(ApplyForSchema)
.id('TreatmentTypeSchema')

export interface TreatmentType { 
    applyFors: Array<ApplyFor>;
    id: string;
    label: string;
}

