<label
  class="co-selection-wrapper"
  [class.active]="checkbox?.checked || radiobutton?.checked"
  [for]="(checkbox?.id || radiobutton?.id) + '-input'"
>
  <ng-content></ng-content>
  <div class="additional-content">
    <ng-content select="[sublabel]"></ng-content>
  </div>
</label>
