/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const CivilstandSchema = JoiObj.string().valid(
        'Gift'
        ,
        'Sammenlevende'
        ,
        'SammenlevendeUnder2Aar'
        ,
        'SammenlevendeOver2Aar'
        ,
        'Single'
        
    )
.id('CivilstandSchema')

export type Civilstand = 'Gift' | 'Sammenlevende' | 'SammenlevendeUnder2Aar' | 'SammenlevendeOver2Aar' | 'Single';

export const Civilstand = {
    Gift: 'Gift' as Civilstand,
    Sammenlevende: 'Sammenlevende' as Civilstand,
    SammenlevendeUnder2Aar: 'SammenlevendeUnder2Aar' as Civilstand,
    SammenlevendeOver2Aar: 'SammenlevendeOver2Aar' as Civilstand,
    Single: 'Single' as Civilstand
};

