<ng-container
  *ngIf="displayedQuestion === question.involvement; else investmentDetails"
>
  <div class="row">
    <div class="col-md-8 offset-md-2">
      <div class="description spacing-unit--double-under">
        <h3 class="align--center" coContent="DL.INVGU01.C495"></h3>
        <p
          *ngIf="!pensionCustomer.isRaadgiver"
          class="text--info"
          coContent="DL.INVGU01.C487"
        ></p>
      </div>
    </div>
    <div
      class="col-md-2 spacing-unit--double-under d-flex justify-content-end pl-2"
    >
      <mitpfa-investment-guide-help
        [broker]="broker"
      ></mitpfa-investment-guide-help>
    </div>
  </div>

  <div class="container">
    <div class="row justify-content-center choice-row">
      <div class="col-md-4" *ngFor="let choice of choices">
        <co-card class="card--small">
          <div class="card-content">
            <div class="card-description">
              <div
                class="text--manchet spacing-unit--under choice-header"
                [coContent]="choice.header"
              ></div>
              <div
                class="details spacing-unit--under"
                *ngIf="!pensionCustomer.isRaadgiver"
              >
                <div
                  *ngFor="
                    let option of choice.options;
                    trackBy: trackChoiceByFn
                  "
                  [coContent]="option"
                ></div>
              </div>
            </div>

            <button
              class="button-secondary"
              mat-raised-button
              coContent="DL.INVGU01.C418"
              (click)="choose(choice)"
            ></button>

            <div
              class="previously-selected align--center text--note text--bold"
            >
              {{
                choice.previouslySelected
                  ? ('DL.INVGU01.C519' | content)
                  : '&nbsp;'
              }}
            </div>
          </div>
        </co-card>
      </div>
    </div>
  </div>

  <co-carousel slidePercentageWidth="80" slideScale="true">
    <ng-container *ngFor="let choice of choices">
      <ng-container *pfaCarouselItem>
        <co-card class="item">
          <div class="card-content">
            <div class="card-description">
              <div
                class="text--manchet spacing-unit--under"
                [class.not-recommended]="!choice.isRecommended"
                [coContent]="choice.header"
              ></div>
            </div>

            <div class="align--center">
              <button
                class="button-secondary"
                mat-raised-button
                coContent="DL.INVGU01.C418"
                (click)="choose(choice)"
              ></button>
            </div>

            <div class="spacing-unit--over align--center text--note text--bold">
              {{
                choice.previouslySelected
                  ? ('DL.INVGU01.C519' | content)
                  : '&nbsp;'
              }}
            </div>
          </div>

          <co-accordion
            *ngIf="!pensionCustomer.isRaadgiver"
            card-additional
            class="stick-bottom"
            title="DL.INVGU01.C612"
          >
            <div
              *ngFor="let option of choice.options; trackBy: trackChoiceByFn"
              [coContent]="option"
            ></div>
          </co-accordion>
        </co-card>
      </ng-container>
    </ng-container>
  </co-carousel>

  <mitpfa-investment-guide-back-button
    (back)="goToLegalPage()"
  ></mitpfa-investment-guide-back-button>
</ng-container>

<ng-template #investmentDetails>
  <co-card-animated-stack [activeIndex]="activeQuestionIndex">
    <co-card-animated-stack-item [scrollToTopOnActivate]="true">
      <co-card-question
        (agreeClick)="setInvestmentKnowledge(true)"
        (declineClick)="setInvestmentKnowledge(false)"
      >
        <div
          class="text--manchet align--center"
          coContent="DL.INVGU01.C173"
        ></div>

        <div
          under-button-mobile
          class="question-previously-selected align--center text--note"
          *ngIf="
            investmentGuide.recommendationBasis?.investmentKnowledge !==
            undefined
          "
          coContent="DL.INVGU01.C434"
          [coContentSubst]="
            '<strong>' +
            ((investmentGuide.recommendationBasis.investmentKnowledge
              ? 'DL.MD01.C29'
              : 'DL.MD01.C30'
            ) | content) +
            '</strong>'
          "
        ></div>
      </co-card-question>
    </co-card-animated-stack-item>

    <co-card-animated-stack-item
      [scrollToTopOnActivate]="true"
      maxWidth="1024px"
    >
      <co-card>
        <h3 class="align--center selection-title" coContent="DL.VG01.C78"></h3>

        <form
          class="selection-form"
          *ngIf="tradingExperience.form"
          [formGroup]="tradingExperience.form"
        >
          <co-selection-wrapper>
            <mat-checkbox formControlName="investmentFunds">
              <div coContent="DL.INVGU01.C312"></div>
            </mat-checkbox>
            <div
              class="align--right text--bold text--note"
              *ngIf="tradingExperience.previouslySelected.investmentFunds"
              sublabel
            >
              {{ 'DL.INVGU01.C519' | content }}
            </div>
          </co-selection-wrapper>
          <co-selection-wrapper>
            <mat-checkbox formControlName="equities">
              <div coContent="DL.INVGU01.C203"></div>
            </mat-checkbox>
            <div
              class="align--right text--bold text--note"
              *ngIf="tradingExperience.previouslySelected.equities"
              sublabel
            >
              {{ 'DL.INVGU01.C519' | content }}
            </div>
          </co-selection-wrapper>
          <co-selection-wrapper>
            <mat-checkbox formControlName="bonds">
              <div coContent="DL.INVGU01.C205"></div>
            </mat-checkbox>
            <div
              class="align--right text--bold text--note"
              *ngIf="tradingExperience.previouslySelected.bonds"
              sublabel
            >
              {{ 'DL.INVGU01.C519' | content }}
            </div>
          </co-selection-wrapper>
          <co-selection-wrapper>
            <mat-checkbox formControlName="others">
              <div class="others-row">
                <div coContent="DL.INVGU01.C340"></div>
                <div
                  class="text--note additional-info"
                  coContent="DL.INVGU01.C615"
                ></div>
              </div>
            </mat-checkbox>
            <div
              class="align--right text--bold text--note"
              *ngIf="tradingExperience.previouslySelected.others"
              sublabel
            >
              {{ 'DL.INVGU01.C519' | content }}
            </div>
          </co-selection-wrapper>
          <co-selection-wrapper>
            <mat-radio-group formControlName="noExperience">
              <mat-radio-button [value]="true">
                <div coContent="DL.INVGU01.C184"></div>
              </mat-radio-button>
            </mat-radio-group>
            <div
              class="align--right text--bold text--note"
              *ngIf="tradingExperience.previouslySelected.noExperience"
              sublabel
            >
              {{ 'DL.INVGU01.C519' | content }}
            </div>
          </co-selection-wrapper>
        </form>
        <div class="align--center spacing-unit--double-over">
          <button
            class="button-secondary"
            mat-raised-button
            (click)="setTradingExperience()"
            [disabled]="tradingExperience.isDisabled$ | async"
          >
            <span [coContent]="'DL.INVGU01.C186'"></span>
          </button>
        </div>
      </co-card>
    </co-card-animated-stack-item>

    <co-card-animated-stack-item [scrollToTopOnActivate]="true">
      <co-card-question
        (agreeClick)="setMoreThan10Trades(true)"
        (declineClick)="setMoreThan10Trades(false)"
      >
        <div
          class="text--manchet align--center"
          coContent="DL.INVGU01.C212"
        ></div>

        <div
          under-button-mobile
          class="question-previously-selected text--note align--center"
          *ngIf="
            investmentGuide.recommendationBasis?.moreThan10Trades !== undefined
          "
          coContent="DL.INVGU01.C434"
          [coContentSubst]="
            '<strong>' +
            ((investmentGuide.recommendationBasis.moreThan10Trades
              ? 'DL.MD01.C29'
              : 'DL.MD01.C30'
            ) | content) +
            '</strong>'
          "
        ></div>
      </co-card-question>
    </co-card-animated-stack-item>
  </co-card-animated-stack>

  <mitpfa-investment-guide-back-button
    (back)="goBack()"
  ></mitpfa-investment-guide-back-button>
</ng-template>
