import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SavingsGuard } from '@pfa/handler';
import { SavingsComponent } from './savings.component';
import { SavingsPolicyComponent } from './savings-policy/savings-policy.component';
import { SavingsPolicyDetailsComponent } from './savings-policy/savings-policy-details/savings-policy-details.component';
import {
  SavingsPolicyCurrentyearResolver,
  SavingsResolver
} from '@mitpfa/shared';

const routes: Routes = [
  {
    path: 'minopsparing',
    canActivate: [SavingsGuard],
    resolve: {
      savingsOverview: SavingsResolver
    },
    component: SavingsComponent
  },
  {
    path: 'minopsparing/:policyId',
    resolve: {
      savingsOverview: SavingsResolver,
      savingsPolicyDetails: SavingsPolicyCurrentyearResolver
    },
    component: SavingsPolicyComponent
  },
  {
    path: 'minopsparing/:policyId/detaljer',
    resolve: {
      savingsOverview: SavingsResolver,
      savingsPolicyDetails: SavingsPolicyCurrentyearResolver
    },
    component: SavingsPolicyDetailsComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SavingsRoutingModule {}
