/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ServiceInfo } from './serviceInfo';
import { ServiceInfoSchema } from './serviceInfo';
import { AkkAfkastLinie } from './akkAfkastLinie';
import { AkkAfkastLinieSchema } from './akkAfkastLinie';
import { PfaInvestererAfkast } from './pfaInvestererAfkast';
import { PfaInvestererAfkastSchema } from './pfaInvestererAfkast';
import { ResourceModel } from './resourceModel';
import { ResourceModelSchema } from './resourceModel';
import * as JoiObj from "joi";


    export const AkkAfkastDetaljerSchema = JoiObj.object({
        serviceInfo:
            
        JoiObj.link('#ServiceInfoSchema')

.allow(null)



        ,
        aarsAfkastChartLinier:
                JoiObj.array().items(
        
        JoiObj.link('#AkkAfkastLinieSchema')

        )
        

.required()


        ,
        afkastDatoer:
                JoiObj.array().items(
        
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

        )
        

.required()


        ,
        antalAfKoncepterMedData:
            
                    JoiObj.number()
                .integer()


.required()


        ,
        detaljerSlutDato:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        detaljerStartDato:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        empty:
            
                    JoiObj.boolean()
                


.required()


        ,
        fejlet:
            
                    JoiObj.boolean()
                


.required()


        ,
        gennemsnitsrenteAfkastLinier:
                JoiObj.array().items(
        
        JoiObj.link('#AkkAfkastLinieSchema')

        )
        

.required()


        ,
        kkAfkastLinier:
                JoiObj.array().items(
        
        JoiObj.link('#AkkAfkastLinieSchema')

        )
        

.required()


        ,
        kundeKapitalNotZero:
            
                    JoiObj.boolean()
                


.required()


        ,
        noOfMonths:
            
                    JoiObj.number()
                .integer()


.required()


        ,
        opsparingsDato:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        pfaInvesterer:
            
        JoiObj.link('#PfaInvestererAfkastSchema')

.allow(null)



        ,
        profilAfkastLinier:
                JoiObj.array().items(
        
        JoiObj.link('#AkkAfkastLinieSchema')

        )
        

.required()


        ,
        selvValgAfkastLinier:
                JoiObj.array().items(
        
        JoiObj.link('#AkkAfkastLinieSchema')

        )
        

.required()


        ,
        totalAfkastForAar:
            
                    JoiObj.number()
                


.required()


        ,
        totalAfkastLinier:
                JoiObj.array().items(
        
        JoiObj.link('#AkkAfkastLinieSchema')

        )
        

.required()


        ,
        valgtPeriode:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        valgtPolicenummer:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        
    })
                .shared(ServiceInfoSchema)
                .shared(AkkAfkastLinieSchema)
                .shared(AkkAfkastLinieSchema)
                .shared(AkkAfkastLinieSchema)
                .shared(PfaInvestererAfkastSchema)
                .shared(AkkAfkastLinieSchema)
                .shared(AkkAfkastLinieSchema)
                .shared(AkkAfkastLinieSchema)
.id('AkkAfkastDetaljerSchema')

export interface AkkAfkastDetaljer extends ResourceModel { 
    aarsAfkastChartLinier: Array<AkkAfkastLinie>;
    afkastDatoer: Array<string>;
    antalAfKoncepterMedData: number;
    detaljerSlutDato?: string;
    detaljerStartDato?: string;
    empty: boolean;
    fejlet: boolean;
    gennemsnitsrenteAfkastLinier: Array<AkkAfkastLinie>;
    kkAfkastLinier: Array<AkkAfkastLinie>;
    kundeKapitalNotZero: boolean;
    noOfMonths: number;
    opsparingsDato?: string;
    pfaInvesterer?: PfaInvestererAfkast;
    profilAfkastLinier: Array<AkkAfkastLinie>;
    selvValgAfkastLinier: Array<AkkAfkastLinie>;
    totalAfkastForAar: number;
    totalAfkastLinier: Array<AkkAfkastLinie>;
    valgtPeriode: string;
    valgtPolicenummer: string;
}

