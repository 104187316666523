/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ServiceInfo } from './serviceInfo';
import { ServiceInfoSchema } from './serviceInfo';
import { Profil } from './profil';
import { ProfilSchema } from './profil';
import { InvesteringKilde } from './investeringKilde';
import { InvesteringKildeSchema } from './investeringKilde';
import { ResourceModel } from './resourceModel';
import { ResourceModelSchema } from './resourceModel';
import * as JoiObj from "joi";


    export const PfaInvestererSchema = JoiObj.object({
        serviceInfo:
            
        JoiObj.link('#ServiceInfoSchema')

.allow(null)



        ,
        harPfaInvesterer:
            
                    JoiObj.boolean()
                


.required()


        ,
        hoejFaktiskTotalFordelingProcent:
            
                    JoiObj.number()
                


.required()


        ,
        indbetalingKilderAlle:
                JoiObj.array().items(
        
        JoiObj.link('#InvesteringKildeSchema')

        )
        

.required()


        ,
        indbetalingKilderFrie:
                JoiObj.array().items(
        
        JoiObj.link('#InvesteringKildeSchema')

        )
        

.required()


        ,
        lavFaktiskTotalFordelingProcent:
            
                    JoiObj.number()
                


.required()


        ,
        muligt:
            
                    JoiObj.boolean()
                


.required()


        ,
        opsparing:
            
                    JoiObj.number()
                


.required()


        ,
        profiler:
                JoiObj.array().items(
        
        JoiObj.link('#ProfilSchema')

        )
        

.required()


        ,
        success:
            
                    JoiObj.boolean()
                


.required()


        
    })
                .shared(ServiceInfoSchema)
                .shared(InvesteringKildeSchema)
                .shared(InvesteringKildeSchema)
                .shared(ProfilSchema)
.id('PfaInvestererSchema')

export interface PfaInvesterer extends ResourceModel { 
    harPfaInvesterer: boolean;
    hoejFaktiskTotalFordelingProcent: number;
    indbetalingKilderAlle: Array<InvesteringKilde>;
    indbetalingKilderFrie: Array<InvesteringKilde>;
    lavFaktiskTotalFordelingProcent: number;
    muligt: boolean;
    opsparing: number;
    profiler: Array<Profil>;
    success: boolean;
}

