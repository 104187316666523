<tr class="forced-thin-border">
  <td
    [attr.data-label]="'DL.OS01.C425' | content"
    class="responsiv-top-padding"
  >
    <co-table-accordion-control
      [contentId]="'DL.OS01.C241'"
      (expandedChange)="toggleExpanded($event)"
    ></co-table-accordion-control>
  </td>
  <td
    class="align--right text--bold"
    [attr.data-label]="'DL.OS01.C426' | content"
  >
    <div>
      {{ savingsPolicyDetails.oevrigePoster.total | numberFormat: 2 }}
      <span [coContent]="'Global.kroner'"></span>
    </div>
  </td>
</tr>

<ng-container *ngIf="expanded">
  <!-- pal -->
  <mitpfa-savings-policy-details-marketrate-subheader-block
    *ngIf="
      savingsPolicyDetails.oevrigePoster.posteringerDerSkalBetalesPalSkatAf
        .length > 0
    "
    [savingsPolicyDetails]="savingsPolicyDetails"
    [headerContentId]="'DL.OS01.C242'"
    [lineElements]="
      savingsPolicyDetails.oevrigePoster.posteringerDerSkalBetalesPalSkatAf
    "
  >
  </mitpfa-savings-policy-details-marketrate-subheader-block>

  <!-- ikke-pal-->
  <mitpfa-savings-policy-details-marketrate-subheader-block
    *ngIf="
      savingsPolicyDetails.oevrigePoster.posteringerDerIkkeSkalBetalesPalSkatAf
        .length > 0
    "
    [savingsPolicyDetails]="savingsPolicyDetails"
    [headerContentId]="'DL.OS01.C249'"
    [lineElements]="
      savingsPolicyDetails.oevrigePoster.posteringerDerIkkeSkalBetalesPalSkatAf
    "
  >
  </mitpfa-savings-policy-details-marketrate-subheader-block>
</ng-container>
