/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SalaryElement } from './salaryElement';
import { SalaryElementSchema } from './salaryElement';
import * as JoiObj from "joi";


    export const SimulationElementSchema = JoiObj.object({
        inForceDate:
            
            JoiObj.date()


.required()


        ,
        partialSchemeId:
            
                    JoiObj.number()
                


.required()


        ,
        salaryElement:
            
        JoiObj.link('#SalaryElementSchema')


.required()


        
    })
                .shared(SalaryElementSchema)
.id('SimulationElementSchema')

export interface SimulationElement { 
    inForceDate: Date;
    partialSchemeId: number;
    salaryElement: SalaryElement;
}

