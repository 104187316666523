import { Injectable } from '@angular/core';
import { tap, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Channel, ConsentInfo, PensionskundeStore } from '@pfa/gen';

export interface SaveConsentDTO {
  type: string;
  state: string;
  version: string;
  channels: Channel[];
}

export interface SaveConsentResponseDTO {
  consentDataGemIkkeGennemfoert: boolean; // save not successfull
  consents: ConsentInfo[];
}

@Injectable({
  providedIn: 'root'
})
export class ConsentsApiService {
  constructor(
    private readonly http: HttpClient,
    private readonly pensionskundeStore: PensionskundeStore
  ) {}

  updateConsent(data: SaveConsentDTO): Observable<SaveConsentResponseDTO> {
    return this.http
      .post<SaveConsentResponseDTO>('/ds/api/pensionskunde/saveConsent', data)
      .pipe(
        tap(_ => {
          this.pensionskundeStore.invalidatepensionskundeIndex();
          this.pensionskundeStore.invalidatepensionskundeconsentsIndex();
        })
      );
  }
}
