import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  NgForm,
  Validators
} from '@angular/forms';
import { AdvisorCorrectionComponent, GlobalWorkingService } from '@pfaform';
import {
  OekonomiDetaljer,
  RaadgiverAnbefaling,
  Skattekode,
  PensionsKundeGenerelleData
} from '@pfa/gen';
import { MultilineTextValidator, Utils } from '@pfa/utils';
import { MatDialog } from '@angular/material/dialog';
import {
  InputConfig,
  InsuranceAdjustmentState,
  InsuranceAdjustmentType,
  TaxCodeUpdateResponse,
  InsuranceAdjustmentAdvisorInfo
} from '@mitpfa/shared';
import { InsuranceAdjustmentUpdateService } from '../insurance-adjustment-update.service';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'mitpfa-insurance-adjustment-advisor-info',
  templateUrl: './insurance-adjustment-advisor-info.component.html',
  styleUrls: ['./insurance-adjustment-advisor-info.component.scss']
})
export class InsuranceAdjustmentAdvisorInfoComponent
  implements OnInit, OnChanges, OnDestroy
{
  @ViewChild('formDirective') private readonly formDirective: NgForm;
  @Input() insuranceState: InsuranceAdjustmentState;
  @Input() adjustmentAdvisorInfo: InsuranceAdjustmentAdvisorInfo;
  @Input() error: boolean;
  @Input() reset: Observable<InputConfig>;
  @Input() pensionCustomer: PensionsKundeGenerelleData;
  @Output() updated = new EventEmitter<RaadgiverAnbefaling>();
  @Output() deleted = new EventEmitter();
  @Output() applyManualCorrections = new EventEmitter();
  @Output() applyCorrections = new EventEmitter<boolean>();
  @Output() taxCodeUpdated = new EventEmitter<Skattekode>();

  recommendationForm: UntypedFormGroup;
  taxForm: UntypedFormGroup;
  correctionsForm: UntypedFormGroup;
  netCoverageForm: UntypedFormGroup;
  TEXT_AREA_SIZE = 500;
  count: number;
  netAmountMin: number;
  netAmountMax: number;
  maxRecommendationLength: number;
  taxCodeSectionVisible: boolean;
  disableTaxCodeChoiceSk2: boolean;
  disableTaxCodeChoiceSk5: boolean;
  taxCodeInfo: string | undefined;

  private readonly unsubscribe = new Subject<void>();

  constructor(
    private readonly globalWorkingService: GlobalWorkingService,
    private readonly insuranceAdjustmentUpdateService: InsuranceAdjustmentUpdateService,
    private readonly dialog: MatDialog
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.error) {
      this.recommendationForm.markAsDirty();
    }
    if (
      changes.advisorData?.previousValue &&
      changes.advisorData?.currentValue.raadgiverAnbefaling === null
    ) {
      this.recommendationForm.reset({
        recommendation: null,
        reason: ''
      });
    }
  }

  ngOnInit(): void {
    this.recommendationForm = this.createRecommendationForm();
    this.taxForm = this.createTaxForm();
    this.correctionsForm = this.createCorrectionsForm(
      this.insuranceState.pensionInfo.advisorCorrectionsDetails
        ? this.insuranceState.pensionInfo.advisorCorrectionsDetails.anvendt
        : false
    );

    this.calcCount();
    this.maxRecommendationLength = this.calcMaxRecommendationLength();
    this.netAmountMin = this.netAmount(
      this.insuranceState.insurance.tilpasForsikringstal.sliderLiv
        .minimumsdaekning,
      this.pay(this.insuranceState.financialSituation)
    );
    this.netAmountMax = this.netAmount(
      this.insuranceState.insurance.tilpasForsikringstal.sliderLiv
        .forsikringstalMax,
      this.pay(this.insuranceState.financialSituation)
    );
    this.netCoverageForm = this.createNetCoverageForm();
    this.insuranceAdjustmentUpdateService.locLumpSumUpdateAnnounced$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(taxCode => {
        this.adjustmentAdvisorInfo.locLumpSumpUpdated = true;
        this.updateTaxForm(taxCode);
        this.adjustmentAdvisorInfo.taxCode = taxCode;
        this.updateFieldVisibility();
      });

    this.insuranceAdjustmentUpdateService.advisorInfoDeleteAnnounced$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(type => {
        this.resetARecommendationForm(type);
      });
    this.insuranceAdjustmentUpdateService.lifeTaxCodeUpdateAnnounced$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(type => {
        this.updateNetAmount(type);
      });
    this.taxCodeInfo = this.taxCodeInfoText();
    this.updateFieldVisibility();
    this.subscribeToReset();
  }

  private updateFieldVisibility(): void {
    this.taxCodeSectionVisible = this.showTaxCodeSection();
    this.disableTaxCodeChoiceSk2 = this.disableTaxCodeChoice('SK2');
    this.disableTaxCodeChoiceSk5 = this.disableTaxCodeChoice('SK5');
  }

  pay(financialSituation: OekonomiDetaljer): number {
    if (financialSituation.beregneAndenLoen > 0) {
      return financialSituation.beregneAndenLoen;
    } else {
      return financialSituation.registreretLoen;
    }
  }

  private grossPercent(netAmount: number, pay: number): number {
    return (netAmount * 100) / (0.6 * pay);
  }

  private netAmount(grossPercent, pay): number {
    return (grossPercent * 0.6 * pay) / 100;
  }

  private createRecommendationForm(): UntypedFormGroup {
    const recommendationForm = new UntypedFormGroup({});
    recommendationForm.addControl(
      'recommendation',
      new UntypedFormControl(
        this.adjustmentAdvisorInfo?.advisorData.raadgiverAnbefaling,
        [
          Validators.required,
          Validators.min(0),
          Validators.max(this.maxValue())
        ]
      )
    );
    recommendationForm.addControl(
      'reason',
      new UntypedFormControl(
        this.adjustmentAdvisorInfo?.advisorData.raadgiverKommentar,
        [Validators.required, MultilineTextValidator]
      )
    );
    return recommendationForm;
  }

  private maxValue(): number {
    if (this.adjustmentAdvisorInfo.type === InsuranceAdjustmentType.LOC) {
      return 99;
    }
    return 9999;
  }

  private calcMaxRecommendationLength(): number {
    if (this.adjustmentAdvisorInfo.type === InsuranceAdjustmentType.LOC) {
      return 2;
    }
    return 4;
  }

  private resetARecommendationForm(type: InsuranceAdjustmentType): void {
    if (type === this.adjustmentAdvisorInfo.type) {
      this.formDirective.resetForm();
      this.calcCount();
    }
  }

  private updateNetAmount(taxCodeUpdateResponse: TaxCodeUpdateResponse): void {
    const pay = this.pay(this.insuranceState.financialSituation);
    this.netAmountMin = this.netAmount(taxCodeUpdateResponse.amountMin, pay);
    this.netAmountMax = this.netAmount(taxCodeUpdateResponse.amountMax, pay);
    const netAmount = this.netAmount(taxCodeUpdateResponse.amount, pay);
    this.netCoverageForm.get('netAmount')?.clearValidators();
    this.netCoverageForm.patchValue({ netAmount });
    this.netCoverageForm
      ?.get('netAmount')
      ?.setValidators([
        Validators.required,
        Validators.min(this.netAmountMin),
        Validators.max(this.netAmountMax)
      ]);
    this.netCoverageForm?.get('netAmount')?.updateValueAndValidity();
  }

  private createTaxForm(): UntypedFormGroup {
    const taxForm = new UntypedFormGroup({});
    taxForm.addControl(
      'taxCode',
      new UntypedFormControl(this.adjustmentAdvisorInfo.taxCode, [
        Validators.required
      ])
    );
    return taxForm;
  }

  private updateTaxForm(taxCode: Skattekode): void {
    this.taxForm.patchValue({
      taxCode
    });
  }

  private createCorrectionsForm(
    hasManualInsuranceChanges: boolean
  ): UntypedFormGroup {
    const correctionsForm = new UntypedFormGroup({});
    correctionsForm.addControl(
      'useCorrections',
      new UntypedFormControl(hasManualInsuranceChanges)
    );
    return correctionsForm;
  }

  private createNetCoverageForm(): UntypedFormGroup {
    const netCoverageForm = new UntypedFormGroup({});
    netCoverageForm.addControl(
      'netAmount',
      new UntypedFormControl(
        this.netAmount(
          this.insuranceState.insurance.tilpasForsikringstal.sliderLiv
            .currentForsikringstal,
          this.pay(this.insuranceState.financialSituation)
        ),
        [
          Validators.required,
          Validators.min(this.netAmountMin),
          Validators.max(this.netAmountMax)
        ]
      )
    );
    return netCoverageForm;
  }

  calcCount(): void {
    if (this.recommendationForm.value.reason) {
      this.count =
        this.TEXT_AREA_SIZE - this.recommendationForm.value.reason?.length;
    } else {
      this.count = this.TEXT_AREA_SIZE;
    }
  }

  onUpdate(): void {
    this.globalWorkingService.show();
    const advisorData = Utils.jsonDeepClone(
      this.adjustmentAdvisorInfo.advisorData
    );
    advisorData.raadgiverAnbefaling =
      this.recommendationForm.value.recommendation;
    advisorData.raadgiverKommentar = this.recommendationForm.value.reason;
    advisorData.raadgiverAnbefalingOprettet = undefined;
    advisorData.validAdviserRecommendation = undefined;
    this.updated.emit(advisorData);
    this.recommendationForm.markAsPristine();
  }

  onDelete(): void {
    this.globalWorkingService.show();
    this.deleted.emit();
    this.recommendationForm.markAsPristine();
  }

  onUpdateTaxCode(): void {
    this.globalWorkingService.show();
    this.taxCodeUpdated.emit(this.taxForm.value.taxCode);
    this.taxForm.markAsPristine();
  }

  private taxCodeInfoText(): string | undefined {
    if (
      this.adjustmentAdvisorInfo.type === InsuranceAdjustmentType.LOC_LUMPSUM &&
      this.insuranceState.insurance.tilpasForsikringstal
        .daekningAendringTAEengangsudbetaling.visTvungenSkattekodeText
    ) {
      return 'DL.TF01.C123';
    }
    if (
      this.adjustmentAdvisorInfo.type === InsuranceAdjustmentType.LIFE &&
      this.insuranceState.insurance.tilpasForsikringstal.sliderLiv
        .visTvungenSkattekodeText
    ) {
      return 'DL.TF01.C127';
    }
    return undefined;
  }

  isUpdateTaxCodeDisabled(): boolean {
    return !this.taxForm.value.taxCode || this.taxForm.pristine;
  }

  private showTaxCodeSection(): boolean {
    const taxCodes = ['SK2', 'SK5'] as Skattekode[];
    if (
      !this.taxForm.value.taxCode &&
      taxCodes.some(taxCode => this.disableTaxCodeChoice(taxCode))
    ) {
      return false;
    }
    if (this.adjustmentAdvisorInfo.type === InsuranceAdjustmentType.LOC) {
      return this.insuranceState.insurance.tilpasForsikringstal
        .daekningAendringTAEengangsudbetaling.skalVises;
    }
    return !this.insuranceState.insurance.tilpasForsikringstal.sliderLiv.locked;
  }

  private disableTaxCodeChoice(taxCode: Skattekode): boolean {
    if (this.adjustmentAdvisorInfo.type === InsuranceAdjustmentType.LOC) {
      return (
        !this.insuranceState.insurance.tilpasForsikringstal.daekningAendringTAEengangsudbetaling.muligeSkattekoder.includes(
          taxCode
        ) ||
        (this.insuranceState.insurance.tilpasForsikringstal
          .daekningAendringTAEengangsudbetaling.daekningsBeloeb < 1 &&
          this.adjustmentAdvisorInfo.locLumpSumpUpdated) ||
        (this.insuranceState.insurance.originalTilpasForsikringstal
          .daekningAendringTAEengangsudbetaling.daekningsBeloeb < 1 &&
          !this.adjustmentAdvisorInfo.locLumpSumpUpdated)
      );
    }
    return (
      !this.insuranceState.insurance.tilpasForsikringstal.sliderLiv.muligeSkattekoder.includes(
        taxCode
      ) ||
      !this.insuranceState.insurance.tilpasForsikringstal.skattekodevalgLivMulig
    );
  }

  toggleCorrections(): void {
    this.applyCorrections.emit(this.correctionsForm.value.useCorrections);
  }

  onShowManuelCorrections(): void {
    this.dialog
      .open(AdvisorCorrectionComponent, {
        autoFocus: false,
        data: {
          advisorCorrections:
            this.insuranceState.pensionInfo.advisorCorrectionsDetails,
          partnerExpectedPensionAge:
            this.insuranceState.pensionCustomer.forventetPensionsalder,
          isPartner: false,
          retirementAge: this.insuranceState.pensionCustomer.retirementAge,
          pensionCustomer: this.pensionCustomer
        },
        minWidth: '90vw',
        height: '90vh'
      })
      .afterClosed()
      .subscribe((saved: boolean) => {
        if (saved) {
          this.applyManualCorrections.emit();
        }
      });
  }

  allowNetAmountUpdate(): boolean {
    const not40PercentTax = [
      'GARANTI_FOR_UDBETALING',
      'LIVSVARIG_AEGTEFAELLLEPENSION',
      'OPHOERENDE_AEGTEFAELLEPENSION',
      'LIVSVARIG_AEGTEFAELLE_UNITLINK',
      'LIVSVARIG_AEGTEFAELLE_FORSIKRING',
      'OPHOERENDE_AEGTEFAELLE_UNITLINK',
      'OPHOERENDE_AEGTEFAELLE_FORSIKRING',
      'OPSAT_LIVSVARIG_AEGTEFAELLEPENSION',
      'OPSAT_OPHOERENDE_AEGTEFAELLEPENSION',
      'GARANTI_FOR_LAENGSTE_LIV_SK1',
      'GARANTI_FOR_LAENGSTE_LIV_SK9'
    ];

    return !this.insuranceState.insuranceOverview.livsForsikring.livsForsikringer.some(
      insurance => not40PercentTax.includes(insurance.daekningsType)
    );
  }

  onUpdateNetAmount(): void {
    const grossPercent = this.grossPercent(
      this.netCoverageForm.value.netAmount,
      this.pay(this.insuranceState.financialSituation)
    );
    this.insuranceAdjustmentUpdateService.lifeNotify(grossPercent);
  }

  isLife(): boolean {
    return this.adjustmentAdvisorInfo.type === InsuranceAdjustmentType.LIFE;
  }

  private subscribeToReset(): void {
    this.reset.pipe(takeUntil(this.unsubscribe)).subscribe((data: any) => {
      if (data.inputConfig.self?.type === InsuranceAdjustmentType.LIFE) {
        const netAmount = this.netAmount(
          this.insuranceState.insurance.originalTilpasForsikringstal.sliderLiv
            .currentForsikringstal,
          this.pay(this.insuranceState.financialSituation)
        );
        this.netCoverageForm.patchValue({
          netAmount
        });
      }
      this.taxForm.patchValue({
        taxCode: data.advisorInfo.taxCode
      });
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
