<div class="display-inline-block">
  <div class="flex">
    <div class="expandable-details" *ngIf="show">
      <co-icon-arrow-up
        *ngIf="expanded; else arrowDown"
        class="icon-fill__small--grey"
        (click)="toggleExpanded()"
      ></co-icon-arrow-up>
      <ng-template #arrowDown>
        <co-icon-arrow-down
          class="icon-fill__small--grey"
          (click)="toggleExpanded()"
        ></co-icon-arrow-down>
      </ng-template>
    </div>
    <div>
      <span
        class="text--bold"
        [coContent]="contentId"
        [coContentSubst]="contentIdSubst"
      ></span>
      <co-tooltip *ngIf="toolTip" [text]="toolTip"></co-tooltip>
    </div>
  </div>
</div>
