/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ServiceInfo } from './serviceInfo';
import { ServiceInfoSchema } from './serviceInfo';
import { VedPensionering } from './vedPensionering';
import { VedPensioneringSchema } from './vedPensionering';
import { OversigtForsikring } from './oversigtForsikring';
import { OversigtForsikringSchema } from './oversigtForsikring';
import { ResourceModel } from './resourceModel';
import { ResourceModelSchema } from './resourceModel';
import * as JoiObj from "joi";


    export const TidligereOrdningSchema = JoiObj.object({
        serviceInfo:
            
        JoiObj.link('#ServiceInfoSchema')

.allow(null)



        ,
        daekningUnderUdbetaling:
            
                    JoiObj.boolean()
                


.required()


        ,
        id:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        indregningsdato:
            
                    JoiObj.object()
                


.required()


        ,
        livsforsikring:
                JoiObj.array().items(
        
        JoiObj.link('#OversigtForsikringSchema')

        )
        

.required()


        ,
        opsparingssikring:
            
                    JoiObj.boolean()
                


.required()


        ,
        taeforsikring:
                JoiObj.array().items(
        
        JoiObj.link('#OversigtForsikringSchema')

        )
        

.required()


        ,
        vedPensionering:
            
        JoiObj.link('#VedPensioneringSchema')


.required()


        
    })
                .shared(ServiceInfoSchema)
                .shared(OversigtForsikringSchema)
                .shared(OversigtForsikringSchema)
                .shared(VedPensioneringSchema)
.id('TidligereOrdningSchema')

export interface TidligereOrdning extends ResourceModel { 
    daekningUnderUdbetaling: boolean;
    id: string;
    indregningsdato: object;
    livsforsikring: Array<OversigtForsikring>;
    opsparingssikring: boolean;
    taeforsikring: Array<OversigtForsikring>;
    vedPensionering: VedPensionering;
}

