<div
  class="customer-survey-container spacing-unit--double-over spacing-unit--double-under"
>
  <p class="text--manchet-light align--center">
    <span [coContent]="'DL.TH01.C64'"></span>
  </p>
  <div class="spacing-unit--double-over align--center">
    <button mat-raised-button type="button" (click)="goToNextPage()">
      <span [coContent]="'DL.TH01.C66'"></span>
    </button>
  </div>
  <div
    class="text--color-primary text--bold spacing-unit--over align--center cursor-pointer"
    (click)="delay()"
  >
    <div class="logout-on-intro">
      <co-icon-clock-45min></co-icon-clock-45min>
      <span [coContent]="'DL.TH01.C67'"></span>
    </div>
  </div>
</div>
