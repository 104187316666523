/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OpsparingOptimeringFejlKode } from './opsparingOptimeringFejlKode';
import { OpsparingOptimeringFejlKodeSchema } from './opsparingOptimeringFejlKode';
import { FPTModregningStatus } from './fPTModregningStatus';
import { FPTModregningStatusSchema } from './fPTModregningStatus';
import * as JoiObj from "joi";


    export const OpsparingOptimeringSvarSchema = JoiObj.object({
        aarTilFolkepension:
            
                    JoiObj.number()
                .integer()

.allow(null)



        ,
        betaleTopskatIdag:
            
                    JoiObj.boolean()
                


.required()


        ,
        betaleTopskatVedPensionering:
            
                    JoiObj.boolean()
                


.required()


        ,
        fpTillaegHoej:
            
                    JoiObj.number()
                

.allow(null)



        ,
        fpTillaegLav:
            
                    JoiObj.number()
                

.allow(null)



        ,
        fptModregningStatus:
            
        JoiObj.link('#FPTModregningStatusSchema')

.allow(null)



        ,
        opsparingOptimeringFejlKode:
            
        JoiObj.link('#OpsparingOptimeringFejlKodeSchema')

.allow(null)



        ,
        prioritetACtekstId:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        prioritetAParametre:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        prioritetBCtekstId:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        prioritetBParametre:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        prioritetCCtekstId:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        prioritetCParametre:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        prioritetDCtekstId:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        prioritetDParametre:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        topskat:
            
                    JoiObj.number()
                

.allow(null)



        
    })
                .shared(FPTModregningStatusSchema)
                .shared(OpsparingOptimeringFejlKodeSchema)
.id('OpsparingOptimeringSvarSchema')

export interface OpsparingOptimeringSvar { 
    aarTilFolkepension?: number;
    betaleTopskatIdag: boolean;
    betaleTopskatVedPensionering: boolean;
    fpTillaegHoej?: number;
    fpTillaegLav?: number;
    fptModregningStatus?: FPTModregningStatus;
    opsparingOptimeringFejlKode?: OpsparingOptimeringFejlKode;
    prioritetACtekstId?: string;
    prioritetAParametre?: string;
    prioritetBCtekstId?: string;
    prioritetBParametre?: string;
    prioritetCCtekstId?: string;
    prioritetCParametre?: string;
    prioritetDCtekstId?: string;
    prioritetDParametre?: string;
    topskat?: number;
}
export namespace OpsparingOptimeringSvar {
}


