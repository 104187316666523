/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Daekning } from './daekning';
import { DaekningSchema } from './daekning';
import * as JoiObj from "joi";


    export const PensionSchema = JoiObj.object({
        aldersopsparing:
                JoiObj.array().items(
        
        JoiObj.link('#DaekningSchema')

        )
        

.required()


        ,
        engangsbeloebOeverige:
                JoiObj.array().items(
        
        JoiObj.link('#DaekningSchema')

        )
        

.required()


        ,
        ikkeVisteDaekninger:
                JoiObj.array().items(
        
        JoiObj.link('#DaekningSchema')

        )
        

.required()


        ,
        kapitalpension:
                JoiObj.array().items(
        
        JoiObj.link('#DaekningSchema')

        )
        

.required()


        ,
        livsvarigLivspension:
                JoiObj.array().items(
        
        JoiObj.link('#DaekningSchema')

        )
        

.required()


        ,
        ophoerendeLivspensioner:
                JoiObj.array().items(
        
        JoiObj.link('#DaekningSchema')

        )
        

.required()


        ,
        prognoseKanIkkeBeregnes:
                JoiObj.array().items(
        
        JoiObj.link('#DaekningSchema')

        )
        

.required()


        ,
        ratepension:
                JoiObj.array().items(
        
        JoiObj.link('#DaekningSchema')

        )
        

.required()


        ,
        selvpensioneringDaekninger:
                JoiObj.array().items(
        
        JoiObj.link('#DaekningSchema')

        )
        

.required()


        ,
        tidsbegraensetLivspension:
                JoiObj.array().items(
        
        JoiObj.link('#DaekningSchema')

        )
        

.required()


        
    })
                .shared(DaekningSchema)
                .shared(DaekningSchema)
                .shared(DaekningSchema)
                .shared(DaekningSchema)
                .shared(DaekningSchema)
                .shared(DaekningSchema)
                .shared(DaekningSchema)
                .shared(DaekningSchema)
                .shared(DaekningSchema)
                .shared(DaekningSchema)
.id('PensionSchema')

export interface Pension { 
    aldersopsparing: Array<Daekning>;
    engangsbeloebOeverige: Array<Daekning>;
    ikkeVisteDaekninger: Array<Daekning>;
    kapitalpension: Array<Daekning>;
    livsvarigLivspension: Array<Daekning>;
    ophoerendeLivspensioner: Array<Daekning>;
    prognoseKanIkkeBeregnes: Array<Daekning>;
    ratepension: Array<Daekning>;
    selvpensioneringDaekninger: Array<Daekning>;
    tidsbegraensetLivspension: Array<Daekning>;
}

