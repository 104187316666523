/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const GenerelEksternOverfoerselAdvarselSchema = JoiObj.string().valid(
        'KAN_IKKE_MODTAGE'
        ,
        'HAR_KUNDEKAPITAL'
        ,
        'RAADGIVNING_ANBEFALET'
        ,
        'ALDERS_ADVARSEL'
        ,
        'RING_MIG_OP'
        ,
        'FRASORTERET_RING_MIG_OP'
        
    )
.id('GenerelEksternOverfoerselAdvarselSchema')

export type GenerelEksternOverfoerselAdvarsel = 'KAN_IKKE_MODTAGE' | 'HAR_KUNDEKAPITAL' | 'RAADGIVNING_ANBEFALET' | 'ALDERS_ADVARSEL' | 'RING_MIG_OP' | 'FRASORTERET_RING_MIG_OP';

export const GenerelEksternOverfoerselAdvarsel = {
    KanIkkeModtage: 'KAN_IKKE_MODTAGE' as GenerelEksternOverfoerselAdvarsel,
    HarKundekapital: 'HAR_KUNDEKAPITAL' as GenerelEksternOverfoerselAdvarsel,
    RaadgivningAnbefalet: 'RAADGIVNING_ANBEFALET' as GenerelEksternOverfoerselAdvarsel,
    AldersAdvarsel: 'ALDERS_ADVARSEL' as GenerelEksternOverfoerselAdvarsel,
    RingMigOp: 'RING_MIG_OP' as GenerelEksternOverfoerselAdvarsel,
    FrasorteretRingMigOp: 'FRASORTERET_RING_MIG_OP' as GenerelEksternOverfoerselAdvarsel
};

