/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ServiceInfo } from './serviceInfo';
import { ServiceInfoSchema } from './serviceInfo';
import { ResourceModel } from './resourceModel';
import { ResourceModelSchema } from './resourceModel';
import * as JoiObj from "joi";


    export const OenskerTilRaadgivningSchema = JoiObj.object({
        serviceInfo:
            
        JoiObj.link('#ServiceInfoSchema')

.allow(null)



        ,
        arvOgBegunstigelse:
            
                    JoiObj.boolean()
                


.required()


        ,
        generelGennemgangAfPensionsordning:
            
                    JoiObj.boolean()
                


.required()


        ,
        hvordanErFamilienSikretHvisJegDoer:
            
                    JoiObj.boolean()
                


.required()


        ,
        hvordanErJegSikretVedSygdom:
            
                    JoiObj.boolean()
                


.required()


        ,
        investeringAfMinPension:
            
                    JoiObj.boolean()
                


.required()


        ,
        investeringViaPFABank:
            
                    JoiObj.boolean()
                


.required()


        ,
        planlaegningAfMinPensionsudbetaling:
            
                    JoiObj.boolean()
                


.required()


        ,
        samlingAfPolicer:
            
                    JoiObj.boolean()
                


.required()


        ,
        sparerJegNokOp:
            
                    JoiObj.boolean()
                


.required()


        ,
        tidspunktForSenesteRettelse:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        
    })
                .shared(ServiceInfoSchema)
.id('OenskerTilRaadgivningSchema')

export interface OenskerTilRaadgivning extends ResourceModel { 
    arvOgBegunstigelse: boolean;
    generelGennemgangAfPensionsordning: boolean;
    hvordanErFamilienSikretHvisJegDoer: boolean;
    hvordanErJegSikretVedSygdom: boolean;
    investeringAfMinPension: boolean;
    investeringViaPFABank: boolean;
    planlaegningAfMinPensionsudbetaling: boolean;
    samlingAfPolicer: boolean;
    sparerJegNokOp: boolean;
    tidspunktForSenesteRettelse: string;
}

