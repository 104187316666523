import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import {
  ActivatedRoute,
  NavigationExtras,
  Params,
  Router
} from '@angular/router';
import {
  FamilyInformationApiService,
  FinancialSituationApiService,
  InsuranceApiService,
  PensionPlanApiService
} from '@pfa/api';
import {
  GlobalWorkingService,
  InsuranceUtilService,
  Step,
  UnsavedChangesWarningComponent,
  UnsavedChangesWarningResponse
} from '@pfaform';
import * as moment from 'moment/moment';
import {
  BehaviorSubject,
  combineLatest,
  Observable,
  Subscription,
  timer
} from 'rxjs';
import {
  InsuranceGuideConfigurationService,
  InsuranceGuideQuestion,
  InsuranceGuideQuestionValue,
  QuestionIndexMap,
  QuestionTrackingMap
} from './insurance-guide-configuration.service';
import {
  ContentService,
  GuideExitAction,
  GuidePlacement,
  GuideStepType,
  GuideTrackingService,
  PageTrackingService
} from '@pfa/core';
import { MatDialog } from '@angular/material/dialog';
import { finalize, map, switchMap, take, tap } from 'rxjs/operators';
import { ComponentCanDeactivate } from '@pfa/handler';
import { InsuranceGuideService } from './insurance-guide.service';
import {
  ForsikringDetaljer,
  ForsikringStore,
  OekonomiDetaljer,
  PensionsInfoOrdninger,
  PensionsKundeGenerelleData,
  PensionskundeStore,
  StamdataDetaljer
} from '@pfa/gen';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'mitpfa-insurance-guide',
  templateUrl: './insurance-guide.component.html',
  styleUrls: ['./insurance-guide.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InsuranceGuideComponent
  implements OnInit, OnDestroy, ComponentCanDeactivate
{
  private readonly activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  private readonly insuranceGuideConfigurationService: InsuranceGuideConfigurationService =
    inject(InsuranceGuideConfigurationService);
  private readonly cdRef: ChangeDetectorRef = inject(ChangeDetectorRef);
  private readonly globalWorkingService: GlobalWorkingService =
    inject(GlobalWorkingService);
  private readonly dialog: MatDialog = inject(MatDialog);
  private readonly pensionPlanApiService: PensionPlanApiService = inject(
    PensionPlanApiService
  );
  private readonly insuranceApiService: InsuranceApiService =
    inject(InsuranceApiService);
  private readonly contentService: ContentService = inject(ContentService);
  private readonly insuranceGuideService: InsuranceGuideService = inject(
    InsuranceGuideService
  );
  private readonly insuranceUtilService: InsuranceUtilService =
    inject(InsuranceUtilService);
  private readonly router: Router = inject(Router);
  private readonly guideTrackingService: GuideTrackingService =
    inject(GuideTrackingService);
  private readonly familyInformationApiService: FamilyInformationApiService =
    inject(FamilyInformationApiService);
  private readonly financialSituationApiService: FinancialSituationApiService =
    inject(FinancialSituationApiService);
  private readonly pensionskundeStore: PensionskundeStore =
    inject(PensionskundeStore);
  private readonly pageTrackingService: PageTrackingService =
    inject(PageTrackingService);
  private readonly destroyRef: DestroyRef = inject(DestroyRef);
  private readonly forsikringStore: ForsikringStore = inject(ForsikringStore);

  @Input() isOnboarding: boolean;
  @Output() finished = new EventEmitter<void>();
  @Output() percentFinished = new EventEmitter<number>();
  @Output() error = new EventEmitter<void>();

  public familyInformation: StamdataDetaljer;
  public financialSituation: OekonomiDetaljer;
  public insuranceDetails: ForsikringDetaljer;
  public pensionInfo: PensionsInfoOrdninger;
  public pensionCustomer: PensionsKundeGenerelleData;

  private LOADING_TIME: number = 6;
  public LOADING_ANIMATION_TIME: number = 5.5;

  private readonly subscriptions: { [subscribtionKey: string]: Subscription } =
    {};
  public displayStepper: boolean;
  public currentStepIndex: number = -1;
  public steps: Step[] = this.insuranceGuideConfigurationService.steps;
  public currentQuestion$: BehaviorSubject<
    InsuranceGuideQuestionValue | undefined
  >;
  public isCurrentFlowShort: boolean;
  public initialized: boolean;

  constructor() {
    this.currentQuestion$ = new BehaviorSubject(undefined);
  }

  public ngOnInit(): void {
    combineLatest({
      familyInformation:
        this.familyInformationApiService.getFamilyInformation(),
      financialSituation: this.financialSituationApiService.get(),
      insuranceDetails: this.insuranceApiService.getInsuranceDetails(),
      insuranceOverview: this.insuranceApiService.getInsuranceOverviewDetails(),
      pensionInfo: this.pensionPlanApiService.getPolice(),
      pensionCustomer: this.pensionskundeStore.pensionskundeGet()
    })
      .pipe(
        take(1),
        switchMap(data => {
          if (data) {
            this.familyInformation = data.familyInformation;
            this.financialSituation = data.financialSituation;
            this.insuranceDetails = data.insuranceDetails;
            this.pensionInfo = this.insuranceUtilService.convertPensionInfo(
              data.pensionInfo
            );
            this.pensionCustomer = data.pensionCustomer;

            const debtCovered = this.financialSituation.frieMidler.gaeldDaekkes;
            const debtCoveredPartner =
              this.familyInformation.partnersGaeldDaekkes;
            this.insuranceGuideConfigurationService.setShowDebtStep(
              !!debtCovered || !!debtCoveredPartner
            );

            this.isCurrentFlowShort =
              this.insuranceGuideService.isShortFlowRunning();

            return this.activatedRoute.queryParams;
          }
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe({
        next: (params: Params) => {
          const step = params['step'];
          if (step === 'PensionsInfo') {
            const startShortFlow = false; // false because it is not suppose to be available in advisor flow
            this.currentStepIndex = 1;
            this.displayStepper = true;
            this.insuranceGuideService.markFlowAsShort(startShortFlow);
            this.isCurrentFlowShort = startShortFlow;
            this.currentQuestion$?.next({
              value: InsuranceGuideQuestion.pensionInfo
            });
          }

          if (this.isOnboarding) {
            this.onStartGuide(this.isCurrentFlowShort);
          } else {
            this.pageTrackingService.trackContentLoaded();
          }

          this.initialized = true;
          this.cdRef.detectChanges();
        },
        error: err => {
          if (this.isOnboarding) {
            this.error.emit();
          } else {
            throw new Error(err);
          }
        }
      });

    this.currentQuestion$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(question => {
        this.setQuestion(question);
        if (question && QuestionTrackingMap.get(question.value)) {
          if (question.value === InsuranceGuideQuestion.intro) {
            this.insuranceGuideService.trackStart(this.isOnboarding);
          } else if (question.value === InsuranceGuideQuestion.recommendation) {
            // tracking is done in individual components
          } else {
            this.insuranceGuideService.trackStep(
              question.value,
              this.isOnboarding
            );
          }
        }
      });
  }

  public ngOnDestroy(): void {
    Object.keys(this.subscriptions).forEach(
      key => this.subscriptions[key]?.unsubscribe()
    );
    this.insuranceGuideService.clearShortFlowState();
  }

  public canDeactivate(): boolean | Observable<boolean> {
    // Disabling safeguard on intropage and currentStepIndex 0
    if (!this.currentStepIndex || this.currentStepIndex < 1) {
      return true;
    }
    //Ok to leave if this is the recommendation page
    if (this.currentStepIndex === 3) {
      return true;
    }
    const currentQuestion: InsuranceGuideQuestion | undefined =
      this.currentQuestion$.getValue()?.value;
    if (currentQuestion) {
      this.insuranceGuideService.trackExit(
        currentQuestion,
        GuideExitAction.INITIATED,
        this.isOnboarding
      );
    }

    return this.dialog
      .open(UnsavedChangesWarningComponent)
      .afterClosed()
      .pipe(
        map(
          (result: UnsavedChangesWarningResponse) =>
            result && result.result === 'ACCEPT'
        ),
        tap(isAccepted => {
          if (currentQuestion) {
            if (isAccepted) {
              this.insuranceGuideService.trackExit(
                currentQuestion,
                GuideExitAction.COMPLETED,
                this.isOnboarding
              );
            } else {
              this.insuranceGuideService.trackExit(
                currentQuestion,
                GuideExitAction.CANCELLED,
                this.isOnboarding
              );
            }
          }
        })
      );
  }

  public getUpdatePI(): void {
    this.globalWorkingService.show();

    this.pensionPlanApiService
      .getPolice()
      .pipe(
        finalize(() => {
          this.globalWorkingService.hide();
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe({
        next: pensionInfo => {
          this.pensionInfo =
            this.insuranceUtilService.convertPensionInfo(pensionInfo);
        },
        error: () => {
          this.globalWorkingService.hide();
        }
      });
  }

  public onStartGuide(startShortFlow: boolean): void {
    this.currentStepIndex = 0;
    this.displayStepper = false;
    this.insuranceGuideService.markFlowAsShort(startShortFlow);
    this.isCurrentFlowShort = startShortFlow;
    this.currentQuestion$.next({ value: InsuranceGuideQuestion.intro });
  }

  public stepChanged(newStep: Step): void {
    this.currentStepIndex = this.steps.findIndex(step => newStep === step);
  }

  private updateStepProgress(): void {
    this.currentStepIndex = -1;
    this.displayStepper = false;

    const currentQuestion = this.currentQuestion$.getValue()?.value;
    for (let i = this.steps.length - 1; i > -1; i--) {
      const step = this.steps[i];

      const index = currentQuestion
        ? this.getQuestions(step).indexOf(currentQuestion)
        : -1;

      if (index > -1) {
        if (currentQuestion === InsuranceGuideQuestion.recommendation) {
          step.progress = 0;
        } else {
          step.progress = Math.round(
            (index * 100) / this.getQuestions(step).length
          );
        }
        this.currentStepIndex = i;
        this.displayStepper =
          currentQuestion !== InsuranceGuideQuestion.intro &&
          currentQuestion !== InsuranceGuideQuestion.recommendation;
      } else {
        step.progress = this.currentStepIndex !== null ? 100 : 0;
      }
    }

    this.handleWelcomflowEvents(currentQuestion);

    this.cdRef.detectChanges();
  }

  private handleWelcomflowEvents(
    currentQuestion: InsuranceGuideQuestion
  ): void {
    if (this.isOnboarding && currentQuestion !== InsuranceGuideQuestion.intro) {
      let numberOfSteps =
        this.insuranceGuideConfigurationService.numberOfQuestions;
      if (currentQuestion === InsuranceGuideQuestion.loc) {
        numberOfSteps++;
      }
      let questionIndex = QuestionIndexMap.get(currentQuestion);
      if (
        currentQuestion === InsuranceGuideQuestion.loc &&
        !this.insuranceGuideConfigurationService.showDebtStep
      ) {
        questionIndex--;
      }
      if (currentQuestion === InsuranceGuideQuestion.recommendation) {
        questionIndex = numberOfSteps + 1;
      }
      this.percentFinished.emit(
        Math.round(((questionIndex - 1) * 100) / numberOfSteps)
      );
    }
  }

  private setQuestion(question: InsuranceGuideQuestionValue | undefined): void {
    if (!question) {
      this.displayStepper = false;
      this.currentStepIndex = -1;
      return;
    }

    const previousStepIndex = this.currentStepIndex;
    const stepId = this.insuranceGuideConfigurationService.findQuestionStepId(
      question.value
    );

    if (stepId !== this.steps[this.currentStepIndex ?? 0]?.id) {
      this.currentStepIndex = this.steps.findIndex(step => step.id === stepId);
    }

    if (
      question.value === InsuranceGuideQuestion.recommendation &&
      previousStepIndex === 2
    ) {
      this.globalWorkingService.hide();
      this.handleRecommendation();
    }

    this.updateStepProgress();
  }

  private getQuestions(step: Step): InsuranceGuideQuestion[] {
    if (step.id) {
      return this.insuranceGuideConfigurationService.getQuestions(step.id);
    }
    return [];
  }

  private handleRecommendation(): void {
    const start = moment();
    this.forsikringStore.invalidateforsikringdetaljerIndex();
    combineLatest({
      insuranceDetails: this.insuranceApiService.getInsuranceDetails(),
      markRecommendationDisplayed:
        this.insuranceApiService.markRecommendationDisplayed()
    })
      .pipe(
        take(1),
        switchMap(data => {
          this.guideTrackingService.trackGuideComplete(
            'insurance guide',
            `ISG loading ${QuestionTrackingMap.get(
              InsuranceGuideQuestion.recommendation
            )}`,
            GuideStepType.MANDATORY,
            undefined,
            this.isOnboarding ? GuidePlacement.ONBOARDING : undefined
          );

          if (!data.insuranceDetails.taeNytte) {
            data.insuranceDetails.taeNytte = this.financialSituation.taeNytte;
          }

          return this.insuranceApiService.generateInsuranceDocumentReport(
            this.contentService.getLanguage().toUpperCase(),
            this.insuranceGuideService.isShortFlowRunning(),
            data.insuranceDetails
          );
        }),
        switchMap(() => {
          const waitFor: number = Math.max(
            this.LOADING_TIME * 1000 - moment().diff(start),
            0
          );

          return timer(waitFor);
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(() => {
        const navigationExtras: NavigationExtras = {
          state: { fromGuide: true }
        };
        if (this.isOnboarding) {
          this.finished.emit();
        } else {
          this.router.navigate(['/mineforsikringer/tilpas'], navigationExtras);
        }
      });
  }
}
