/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { JsErrorPageNavigation } from './jsErrorPageNavigation';
import { JsErrorPageNavigationSchema } from './jsErrorPageNavigation';
import * as JoiObj from "joi";


    export const JsErrorMessageSchema = JoiObj.object({
        errorCode:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        page:
            
        JoiObj.link('#JsErrorPageNavigationSchema')

.allow(null)



        ,
        cause:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        errorDescription:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        errorUrl:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        stackTrace:
                JoiObj.array().items(
        
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

        )
        

.required()


        
    })
                .shared(JsErrorPageNavigationSchema)
.id('JsErrorMessageSchema')

export interface JsErrorMessage { 
    errorCode?: string;
    page?: JsErrorPageNavigation;
    cause: string;
    errorDescription: string;
    errorUrl: string;
    stackTrace: Array<string>;
}

