/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const DynamicAdvisingAutoAdjustmentRecommendationSchema = JoiObj.string().valid(
        'LOC_80'
        ,
        'LOC_100'
        ,
        'MANUAL'
        
    )
.id('DynamicAdvisingAutoAdjustmentRecommendationSchema')

export type DynamicAdvisingAutoAdjustmentRecommendation = 'LOC_80' | 'LOC_100' | 'MANUAL';

export const DynamicAdvisingAutoAdjustmentRecommendation = {
    Loc80: 'LOC_80' as DynamicAdvisingAutoAdjustmentRecommendation,
    Loc100: 'LOC_100' as DynamicAdvisingAutoAdjustmentRecommendation,
    Manual: 'MANUAL' as DynamicAdvisingAutoAdjustmentRecommendation
};

