<div class="messaging-header" [ngClass]="{ 'thread-open': threadOpen }">
  <div class="messaging-read-wrap">
    <div class="messaging-read-count">
      <span [coContent]="'DL.BE02.C02'"></span>
      <span [ngClass]="{ 'unread-messages': unreadCount > 0 }">
        ({{ unreadCount }})
      </span>
    </div>

    <a (click)="emitRefresh()">
      <co-icon-refresh></co-icon-refresh>
    </a>
  </div>

  <div>
    <button
      *ngIf="isNonCustomer"
      mat-raised-button
      class="button-primary--level3"
      [disabled]="isRaadgiver"
      (click)="emitCompose()"
    >
      <co-icon-paperclip class="icon"></co-icon-paperclip>
      <span [coContent]="'DL.TK01.C111'"></span>
    </button>

    <button
      mat-raised-button
      class="button-primary--level3"
      [disabled]="isRaadgiver"
      (click)="emitCompose()"
    >
      <span [coContent]="'DL.BE02.C03'"></span>
      <co-icon-new-message class="icon"></co-icon-new-message>
    </button>
  </div>
</div>
