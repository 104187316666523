import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  ResolveFn,
  RouterStateSnapshot
} from '@angular/router';
import { ExternaltransferStore } from '@pfa/gen';

export const ReceivedAgreementStatusResolver: ResolveFn<{
  modtagendeAftalegyldig: boolean;
}> = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
  inject(ExternaltransferStore).externaltransferReceivingAgreementStatusGet();
