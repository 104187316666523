<span id="queueKey" [hidden]="true">{{ activeQueue.queueKey }}</span>
<span id="configurationId" [hidden]="true">{{
  activeQueue.configurationId
}}</span>
<span id="timeId" [hidden]="true">{{ activeQueue.timeId }}</span>
<span id="visualQueueId" [hidden]="true">{{ activeQueue.visualQueueId }}</span>
<form *ngIf="initialized" id="pfaChat" class="chat--wrapper">
  <div class="chatstarter chat--starter" name="startChat" [@pulse]>
    <co-icon-chat></co-icon-chat>
  </div>
</form>
<div #someID class="intelecomchat chat--tofront"></div>
