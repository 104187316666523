import { Injectable } from '@angular/core';
import {
  AftaleAnbefaling,
  EksternOverfoerselSignereResponseObj,
  SignatureIframeResponse
} from '@pfa/gen';

@Injectable()
export class CombineSavingsAcceptTransferService {
  public getSignaturIframeSvar(
    response: EksternOverfoerselSignereResponseObj
  ): SignatureIframeResponse {
    return {
      fejl: response.signatureIframeResponse.fejl,
      fejlkode: response.signatureIframeResponse.fejlkode,
      fejlTekst: response.signatureIframeResponse.fejlTekst,
      iframesrc: response.signatureIframeResponse.iframesrc,
      revisionKey: response.signatureIframeResponse.revisionKey,
      script: response.signatureIframeResponse.script
    } as SignatureIframeResponse;
  }

  public getTransferredPolicies(
    policies: AftaleAnbefaling[],
    transferredPoliciesUids: string[]
  ): AftaleAnbefaling[] {
    return policies.filter(policy =>
      policy.uid ? transferredPoliciesUids?.indexOf(policy.uid) !== -1 : false
    );
  }

  public showDeclineTransfer(
    transferablePolicies: AftaleAnbefaling[] | undefined,
    guideCompletedDate: Date
  ): boolean {
    if (!transferablePolicies) {
      return false;
    }
    return !(
      transferablePolicies.every(policy => policy.overfoerselBestilt) ||
      guideCompletedDate
    );
  }
}
