import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'co-messaging-header',
  templateUrl: './messaging-header.component.html',
  styleUrls: ['./messaging-header.component.scss']
})
export class MessagingHeaderComponent {
  @Output() composeMessage = new EventEmitter<any>();
  @Output() refresh = new EventEmitter<any>();
  @Input() threadOpen: boolean;
  @Input() unreadCount: number;
  @Input() isRaadgiver: boolean;
  @Input() isNonCustomer: boolean;

  public emitRefresh(): void {
    this.refresh.emit();
  }

  public emitCompose(): void {
    this.composeMessage.emit();
  }
}
