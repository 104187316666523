/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HelbredssikringOption } from './helbredssikringOption';
import { HelbredssikringOptionSchema } from './helbredssikringOption';
import * as JoiObj from "joi";


    export const HelbredssikringDetaljerSchema = JoiObj.object({
        daekningsIds:
                JoiObj.array().items(
        
                    JoiObj.number()
                

        )
        

.required()


        ,
        hsOptions:
                JoiObj.array().items(
        
        JoiObj.link('#HelbredssikringOptionSchema')

        )
        

.required()


        ,
        muligeDaekningsIds:
                JoiObj.array().items(
        
                    JoiObj.number()
                

        )
        

.required()


        ,
        origValgt:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        pris:
            
                    JoiObj.number()
                


.required()


        ,
        valgt:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        
    })
                .shared(HelbredssikringOptionSchema)
.id('HelbredssikringDetaljerSchema')

export interface HelbredssikringDetaljer { 
    daekningsIds: Array<number>;
    hsOptions: Array<HelbredssikringOption>;
    muligeDaekningsIds: Array<number>;
    origValgt: string;
    pris: number;
    valgt: string;
}

