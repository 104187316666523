import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'mitpfa-dynamic-counseling-topic',
  templateUrl: './topic.component.html',
  styleUrls: ['./topic.component.scss']
})
export class TopicComponent {
  @Input() progressPercent$: Observable<number>;
  @Input() severity: number;
  @Input() topicText: string;
  @Input() nbaScore: number;
  @Input() action: string;
  @Input() uri: string;
  @Output() adobeTrackingClick = new EventEmitter<void>();

  tracking() {
    if (this.adobeTrackingClick) {
      this.adobeTrackingClick.emit();
    }
  }
}
