/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PolicyOfferPolicyType } from './policyOfferPolicyType';
import { PolicyOfferPolicyTypeSchema } from './policyOfferPolicyType';
import * as JoiObj from "joi";


    export const PrepareSimulationRequestSchema = JoiObj.object({
        cpr:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        cvr:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        cvrPayer:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        globalId:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        healthRequirementCode:
            
                    JoiObj.number()
                


.required()


        ,
        inForceDate:
            
                    JoiObj.object()
                


.required()


        ,
        partialSchemeId:
            
                    JoiObj.number()
                


.required()


        ,
        policeNr:
            
                    JoiObj.number()
                


.required()


        ,
        policyType:
            
        JoiObj.link('#PolicyOfferPolicyTypeSchema')


.required()


        ,
        salary:
            
                    JoiObj.number()
                


.required()


        ,
        policyNumberReserved:
            
                    JoiObj.number()
                

.allow(null)



        
    })
                .shared(PolicyOfferPolicyTypeSchema)
.id('PrepareSimulationRequestSchema')

export interface PrepareSimulationRequest { 
    cpr: string;
    cvr: string;
    cvrPayer: string;
    globalId: string;
    healthRequirementCode: number;
    inForceDate: object;
    partialSchemeId: number;
    policeNr: number;
    policyType: PolicyOfferPolicyType;
    salary: number;
    /**
     * ID of policy to reuse for new business offer. Null or not present if backend should create a new policy.
     */
    policyNumberReserved?: number;
}
export namespace PrepareSimulationRequest {
}


