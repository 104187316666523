import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import {RestCacheEntry} from "./RestCacheEntry";
import {GenericStore} from "./GenericStore";
import * as JoiObj from "joi";

import {
        OnboardingActivatePensionStep,
        OnboardingActivatePensionStepSchema,
        OnboardingActivatePensionSteps,
        OnboardingActivatePensionStepsSchema
} from '../model/models';

import { OnboardingService } from './onboarding.service';


@Injectable({
    providedIn: 'root'
})
export class OnboardingStore extends GenericStore {

            private onboardingIndexCache: Map<string, RestCacheEntry<OnboardingActivatePensionSteps>> = new Map<string, RestCacheEntry<OnboardingActivatePensionSteps>>();

    constructor(private onboardingService: OnboardingService) {
        super();
    }

    invalidateAll(): void {
                
                this.invalidateonboardingIndex();
                
                
    }


                public onboardingGet() : Observable<OnboardingActivatePensionSteps> {
                    let hash = "0";
                    if (this.onboardingIndexCache.has(hash)) {
                        return this.onboardingIndexCache.get(hash)!.observable;
                    } else {
                        let onboardingIndexSubject: BehaviorSubject<0> = new BehaviorSubject(0);
                        let onboardingIndex = this.initiateStream(onboardingIndexSubject, this.onboardingService.onboardingGet(), OnboardingActivatePensionStepsSchema);
                        this.onboardingIndexCache.set(hash, new RestCacheEntry<OnboardingActivatePensionSteps>(onboardingIndex, onboardingIndexSubject));
                        return onboardingIndex;
                    }
                }

                public invalidateonboardingIndex() {
                    this.onboardingIndexCache.forEach((cacheEntry) => {
                        cacheEntry.subject.next(0);
                    })
                }









                    public onboardingRegisterCompletedStepPut(onboardingActivatePensionStep?: OnboardingActivatePensionStep) : Observable<any> {
                        return this.onboardingService.onboardingRegisterCompletedStepPut(onboardingActivatePensionStep).pipe(
                            map(value => {
                                
                                
                                return value;
                            })
                        );
                    }



}
