/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const AnbefalingsTypeSchema = JoiObj.string().valid(
        'JA'
        ,
        'NEJ'
        ,
        'MAASKE'
        ,
        'FRASORTERET'
        
    )
.id('AnbefalingsTypeSchema')

export type AnbefalingsType = 'JA' | 'NEJ' | 'MAASKE' | 'FRASORTERET';

export const AnbefalingsType = {
    Ja: 'JA' as AnbefalingsType,
    Nej: 'NEJ' as AnbefalingsType,
    Maaske: 'MAASKE' as AnbefalingsType,
    Frasorteret: 'FRASORTERET' as AnbefalingsType
};

