import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { AutomaticHealthAcceptanceApiService } from '@pfa/api';
import { HealthCase } from '@pfa/gen';

@Injectable({
  providedIn: 'root'
})
export class HealthCasesResolver implements Resolve<Array<HealthCase>> {
  constructor(
    private readonly automaticHealthAcceptanceApiService: AutomaticHealthAcceptanceApiService
  ) {}

  resolve():
    | Array<HealthCase>
    | Observable<Array<HealthCase>>
    | Promise<Array<HealthCase>> {
    return this.automaticHealthAcceptanceApiService.getHealthAcceptanceCases();
  }
}
