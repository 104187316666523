import {
  Validators,
  ValidatorFn,
  AbstractControl,
  ValidationErrors
} from '@angular/forms';
import * as moment from 'moment';
import * as _ from 'lodash';

// TODO error codes could be an enum or dictionary, this way they could be used when checking for specific error without risk of a typo
// TODO unit tests
export const PhoneCountryCodeValidator = customCodePatternValidator(
  '^[+]?[0-9]{1,3}$',
  'invalidCountryCode'
);
export const PhoneNumberValidator = customCodePatternValidator(
  '^\\+?\\d{6,30}$',
  'invalidPhoneNumber'
);
export const EmailValidator = customCodePatternValidator(
  // lintfixme: fix error and enable rule
  // eslint-disable-next-line no-useless-escape
  /([^>\(\)\[\]\\,;:@\s]{0,191}@[^>\(\)\[\]\\,;:@\s]{1,64})/,
  'invalidEmail'
);

export const PersonNameValidator = Validators.pattern(
  "^[a-zzipA-Z\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u00ff '´`.-]+$"
);
export const OnlyNumbersValidator = customCodePatternValidator(
  /^[0-9]*$/,
  'invalid'
);
export const NoConsecutiveValidator = customCodePatternValidator(
  /^(?!.*(\d)\1{2}).*$/,
  'invalid'
);

export const FutureDateValidator = futureDateValidator();

export const IllegalLeadingOrTrailingWhiteSpacesValidator =
  illegalLeadingOrTrailingWhiteSpacesValidator();

export function GeneralTextValidator(): ValidatorFn {
  const regex =
    /[^a-åA-Å0-9\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u00ff \\/&'´`"()!,.-]+/g;
  return TextValidator(regex);
}

export function MultilineTextValidator(): ValidatorFn {
  const regex =
    /[^a-åA-Å0-9\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u00ff \\/&'´`"()!,.-<\s]+/g;
  return TextValidator(regex);
}

export function TextValidator(regex: RegExp): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const errorObject: any = {};
    if (control.value && regex.test(control.value)) {
      const illegalCharsMatchArray = (control.value as string).match(regex);
      let illegalChars = '';
      if (illegalCharsMatchArray?.length > 0) {
        // remove multi-char matches:
        const illegalCharsArray = illegalCharsMatchArray.join('').split('');
        // remove duplicates and convert to string:
        illegalChars = [...new Set(illegalCharsArray)].join('');
      }
      errorObject.pattern = { value: illegalChars };
    }
    return errorObject;
  };
}

const DECIMAL_SEPERATOR = ',';
const THOUSAND_SEPERATOR = '.';

export function numberFormatValidator(
  control: AbstractControl
): { [key: string]: any } | null {
  return isNaN(Number(control.value)) ? { numberFormat: {} } : null;
}

/**
 * Validates if the input value is a valid decimal number (with , as decimal
 * seperator), and have no more decimals than the "decimals" parameter specifies
 * @param decimals Defaults to 2 if omitted (use null to allow any decimals)
 * @param minValue if specified validates the input value is greater than or equal to the this parameter
 * @param maxValue if specified validates the input value is less than or equal to the this parameter
 */
export function decimalNumberValidator(
  decimals: number = 2,
  minValue?: number,
  maxValue?: number
): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const errorObject: any = {}; // default: all is valid
    if (control.value) {
      const value = '' + control.value;
      const isValidDecimalNumber = /^\d+(,?\d*)?$/.test(value);

      if (!isValidDecimalNumber) {
        errorObject.numberFormat = {};
      } else {
        if (decimals !== null && value.indexOf(DECIMAL_SEPERATOR) > -1) {
          const decimalString = control.value.substr(
            value.indexOf(DECIMAL_SEPERATOR) + 1
          );
          if (decimalString.length > decimals) {
            errorObject.decimals = { value: decimals };
          }
        }

        const valueAsFloat = DecimalNumberParser.parse(value);
        if (
          minValue !== null &&
          minValue !== undefined &&
          valueAsFloat < minValue
        ) {
          errorObject.min = { min: minValue, actual: valueAsFloat };
        }
        if (
          maxValue !== null &&
          maxValue !== undefined &&
          valueAsFloat > maxValue
        ) {
          errorObject.max = { max: maxValue, actual: valueAsFloat };
        }
      }
    }

    return _.isEmpty(errorObject) ? null : errorObject;
  };
}

/**
 * Validates if the input value is a valid decimal number (with , as decimal
 * seperator), and are less than or equal to the maxValue parameter
 * @param maxValue
 */
export function numberAsStringMaxValidator(maxValue: number): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    let errorObject; // default: all is valid
    if (control.value) {
      const value = '' + control.value;
      const isValidDecimalNumber = /^\d+(,?\d*)?$/.test(value);
      let maxOk = true;
      if (isValidDecimalNumber) {
        const valueAsFloat = parseFloat(
          value.replace(DECIMAL_SEPERATOR, THOUSAND_SEPERATOR)
        );
        maxOk = valueAsFloat <= maxValue;
      }

      if (!isValidDecimalNumber) {
        errorObject = { numberFormat: {} };
      } else if (!maxOk) {
        errorObject = { max: { value: maxValue } };
      }
    }

    return errorObject;
  };
}

/**
 * Parse a string validated by {@link #decimalNumberValidator} into a javascript {@link Number}
 */
export class DecimalNumberParser {
  static parse(number: string): number {
    return +number
      .trim()
      .replace(THOUSAND_SEPERATOR, '')
      .replace(DECIMAL_SEPERATOR, '.');
  }
}

/**
 * Validates that the input is a 10-digit number where the first 6 digits can
 * be converted to a date.
 */
export function cprValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    let valid = true;
    let validLength;
    let validNumber;
    if (control.value) {
      const val = control.value.toString();
      validNumber = /^\d+$/.test(val);
      const validDate = moment(val.substr(0, 6), 'DDMMYY').isValid();
      validLength = control.value.length === 10;
      valid = validNumber && validLength && validDate;
    }
    return valid
      ? null
      : {
          cpr: {
            invalidLength: validLength ? null : {},
            invalidOnlyDigits: validNumber ? null : {}
          }
        };
  };
}

/**
 * Helper method for creating pattern validators which return
 * custom error code.
 */
function customCodePatternValidator(
  pattern: string | RegExp,
  code: string
): ValidatorFn {
  return (control: AbstractControl) => {
    const errors: ValidationErrors | null =
      Validators.pattern(pattern)(control);
    return errors ? { forbiddenValue: { [code]: control.value } } : null;
  };
}

/**
 * Conditional validator to control related values in simpler way
 */
export function conditionalValidator(
  validator: ValidatorFn,
  conditionFn: () => boolean
): ValidatorFn {
  return (control: AbstractControl) =>
    conditionFn() ? validator(control) : null;
}

/**
 * Validate that string numeric value is real numeric value
 */
export function numberAsStringValidator() {
  return (control: AbstractControl) =>
    isNaN(control?.value) ? { notNumber: {} } : null;
}

/**
 * Validate that string numeric value is currency
 */
export function currencyValidator(): any {
  return [
    Validators.min(0),
    Validators.maxLength(9),
    Validators.required,
    numberAsStringValidator()
  ];
}

/**
 * Validate that string numeric value is bank number
 */
export function bankNumberValidator() {
  return [Validators.required, Validators.pattern('[0-9]{4}')];
}

/**
 * Validate that string numeric value is account number with exactly 10 digits
 */
export function accountNumberStrictMatchValidator() {
  return [Validators.required, Validators.pattern('[0-9]{10}')];
}

/**
 * Validates that the input date is in future.
 */
export function futureDateValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: unknown } | null => {
    let valid = true;
    if (control.value) {
      const now = moment();
      valid = moment(control.value, 'YYYYMMDD').isAfter(now);
    }
    return valid ? null : { notInFuture: true };
  };
}

/**
 * Validates that the input date doesn't exceed max date.
 */
export function maxDateValidator(date: string, format: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: unknown } | null => {
    let valid = true;
    if (control.value) {
      const maxDate = moment(date, format);
      valid = !moment(control.value, 'YYYYMMDD').isAfter(maxDate);
    }
    return valid ? null : { maxDate: true };
  };
}
/**
 * Validates that the input date doesn't exceed min date.
 */
export function minDateValidator(date: string, format: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: unknown } | null => {
    let valid = true;
    if (control.value) {
      const minDate = moment(date, format);
      valid = !moment(control.value, 'YYYYMMDD').isBefore(minDate);
    }
    return valid ? null : { minDate: true };
  };
}

/**
 * Validates that the input does not have leading or trailing whitespaces
 */
export function illegalLeadingOrTrailingWhiteSpacesValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    let valid = true;
    if (
      control.value &&
      (control.value.startsWith(' ') || control.value.endsWith(' '))
    ) {
      valid = false;
    }
    return valid ? null : { illegalLeadingOrTrailingWhiteSpaces: true };
  };
}
