/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const UdbetalingskategoriSchema = JoiObj.string().valid(
        'INVALID'
        ,
        'ALDERSPENSION_TIL_INVALID'
        ,
        'AEGTEFAELLE'
        ,
        'BARN'
        
    )
.id('UdbetalingskategoriSchema')

export type Udbetalingskategori = 'INVALID' | 'ALDERSPENSION_TIL_INVALID' | 'AEGTEFAELLE' | 'BARN';

export const Udbetalingskategori = {
    Invalid: 'INVALID' as Udbetalingskategori,
    AlderspensionTilInvalid: 'ALDERSPENSION_TIL_INVALID' as Udbetalingskategori,
    Aegtefaelle: 'AEGTEFAELLE' as Udbetalingskategori,
    Barn: 'BARN' as Udbetalingskategori
};

