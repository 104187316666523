<div mat-dialog-title>
  <h4 coContent="DL.INV.C661"></h4>
  <co-icon-times [mat-dialog-close]="null"></co-icon-times>
</div>

<mat-dialog-content>
  <co-card>
    <div class="ribbon" coContent="DL.INV.C665"></div>

    <h4 coContent="DL.INV.C697"></h4>
    <p coContent="DL.INV.C698"></p>

    <co-alert class="alert-info alert-inline alert-center">
      <span
        coContent="DL.INV.C699"
        [coContentSubst]="recommendationPercent"
      ></span>
    </co-alert>

    <div class="form-wrap spacing-unit--over">
      <mat-form-field
        color="accent"
        [formGroup]="form"
        class="mat-form-field--compact"
      >
        <mat-label coContent="DL.INV.C120"></mat-label>
        <input
          matInput
          coAmount
          coAmountUnit="%"
          [formControl]="form.controls.percent"
          (input)="setThumb()"
          (blur)="setValue()"
        />

        <mat-error
          *ngIf="form.controls.percent.errors?.numberFormat"
          coContent="DL.INV.C561"
        ></mat-error>
        <mat-error
          *ngIf="form.controls.percent.errors?.min"
          coContent="DL.INV.C220"
        ></mat-error>
        <mat-error
          *ngIf="form.controls.percent.errors?.max"
          coContent="DL.INV.C117"
          coContentSubst="100"
        ></mat-error>
      </mat-form-field>

      <div class="progress-wrap">
        <div class="progress-thumb" [ngStyle]="{ left: thumb + '%' }">
          {{ thumb }} %
        </div>

        <mat-progress-bar color="accent" [value]="thumb"></mat-progress-bar>

        <div class="progress-label text--note">
          <span>0 %</span>
          <span>100 %</span>
        </div>
      </div>
    </div>

    <div class="align--center spacing-unit--over">
      <button
        mat-raised-button
        class="button-secondary"
        coContent="DL.INV.C700"
        [disabled]="form.controls.percent.errors"
        [mat-dialog-close]="thumb"
      ></button>
    </div>
  </co-card>
</mat-dialog-content>
