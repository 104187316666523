/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { IdAndName } from './idAndName';
import { IdAndNameSchema } from './idAndName';
import * as JoiObj from "joi";


    export const IndtastetforsikringSchema = JoiObj.object({
        beskatningsform:
            
        JoiObj.link('#IdAndNameSchema')


.required()


        ,
        id:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        livssikringsbeloeb:
            
                    JoiObj.number()
                


.required()


        ,
        selskab:
            
        JoiObj.link('#IdAndNameSchema')


.required()


        
    })
                .shared(IdAndNameSchema)
                .shared(IdAndNameSchema)
.id('IndtastetforsikringSchema')

export interface Indtastetforsikring { 
    beskatningsform: IdAndName;
    id: string;
    livssikringsbeloeb: number;
    selskab: IdAndName;
}

