<svg
  id="Illustration"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  viewBox="0 0 90.74 46.01"
>
  <defs>
    <clipPath id="clippath">
      <path
        d="M53.16,12.06l-1.3,1.3-1.3-1.3c-3.56-3.52-9.3-3.49-12.82.07-3.49,3.53-3.49,9.21,0,12.74l12.83,12.83c.71.69,1.83.69,2.54,0l2.99-2.99.19-.15,9.65-9.65c3.57-3.51,3.63-9.24.12-12.82s-9.24-3.63-12.82-.12h-.07v.07Z"
        fill="none"
        stroke-width="0"
      />
    </clipPath>
  </defs>
  <g id="Layer_1" data-name="Layer 1">
    <g id="box">
      <polygon
        points="26.61 28.28 64.37 28.28 68.37 46 22.37 46 26.61 28.28"
        fill="#338484"
        fill-rule="evenodd"
        stroke-width="0"
      />
      <polygon
        points="29.37 16 26.61 28.28 64.37 28.28 61.37 16 29.37 16"
        fill="#066"
        fill-rule="evenodd"
        stroke-width="0"
      />
      <polygon
        points="29.37 16 61.37 16 59.37 8 31.37 8 29.37 16"
        fill="#338484"
        fill-rule="evenodd"
        stroke-width="0"
      />
      <polygon
        points="31.37 8 32.37 2.14 58.37 2.14 59.37 8 31.37 8"
        fill="#66a3a3"
        fill-rule="evenodd"
        stroke-width="0"
      />
      <polygon
        points="61.37 16 83.05 16 90.74 28.27 64.37 28.27 61.37 16"
        fill="#338484"
        fill-rule="evenodd"
        stroke-width="0"
      />
      <polygon
        points="29.37 16 7.69 16 0 28.28 26.6 28.28 29.37 16"
        fill="#338484"
        fill-rule="evenodd"
        stroke-width="0"
      />
    </g>
    <g id="Stetoscope">
      <path
        d="M37.49,2.58l-.28-.28c-.25-.82-1.04-1.36-1.9-1.3-.94-.1-1.8.56-1.94,1.5.15.94,1,1.59,1.94,1.5.37.03.74-.04,1.08-.19.3.34.48.78.51,1.24,0,7.27-3.42,13.21-7.64,13.28-4.22.07-7.64-5.98-7.64-13.28.01-.43.17-.84.43-1.17.31.13.65.21.99.21.94.1,1.8-.56,1.94-1.5-.15-.94-1-1.59-1.94-1.5-.94-.09-1.8.56-1.96,1.5h0c-.66.66-1.05,1.55-1.06,2.48-.08,3.66.82,7.28,2.62,10.47,1.5,2.54,5.79,4.49,5.79,4.49v14.21c0,3.34-1.29,5.14-2.48,5.14-.65-.09-1.21-.5-1.5-1.09-.66-1.24-.97-2.65-.9-4.05,0-8.15,0-8.97-4.04-8.97v1.73c1.67,0,1.82,0,1.99.48.39,2.23.5,4.5.34,6.76-.07,1.7.32,3.39,1.14,4.89.6,1.11,1.73,1.82,2.99,1.87,1.97,0,4.1-2.12,4.1-6.76v-14.28s4.49-1.87,5.98-4.49c1.8-3.19,2.7-6.81,2.62-10.47-.02-.93-.39-1.82-1.05-2.48"
        fill="#333"
        fill-rule="evenodd"
        stroke-width="0"
      />
      <ellipse
        cx="15.65"
        cy="25.97"
        rx="3.8"
        ry="3.81"
        fill="none"
        stroke="#900"
        stroke-miterlimit="10"
        stroke-width="1.09"
      />
      <path
        d="M12.98,25.97c0-1.48,1.2-2.68,2.68-2.68s2.68,1.2,2.68,2.68-1.2,2.68-2.68,2.68h0c-1.48,0-2.67-1.2-2.68-2.68"
        fill="#d69999"
        fill-rule="evenodd"
        stroke-width="0"
      />
    </g>
    <g id="Heart-2">
      <path
        id="Heart-3"
        d="M53.16,12.06l-1.3,1.3-1.3-1.3c-3.56-3.52-9.3-3.49-12.82.07-3.49,3.53-3.49,9.21,0,12.74l12.83,12.83c.71.69,1.83.69,2.54,0l2.99-2.99.19-.15,9.65-9.65c3.57-3.51,3.63-9.24.12-12.82s-9.24-3.63-12.82-.12h-.07v.07Z"
        fill="#900"
        stroke-width="0"
      />
      <g id="Pulse">
        <g clip-path="url(#clippath)">
          <polyline
            id="Pulse-2"
            points="39.21 27.47 44.13 27.48 47.58 19.57 51.07 31.37 52.86 25.52 57.26 25.52"
            fill="none"
            stroke="#f2f2f2"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.36"
          />
        </g>
      </g>
      <path
        d="M52.59,36.17l-13.53-13.46c-2.4-2.42-3.32-5.93-2.42-9.21-2.41,3.59-1.94,8.39,1.11,11.46l12.83,12.83c.7.7,1.84.7,2.54,0,0,0,0,0,0,0l1.02-1.03c-.58.06-1.15-.16-1.54-.58Z"
        isolation="isolate"
        opacity=".2"
        stroke-width="0"
      />
      <ellipse
        cx="63.12"
        cy="13.17"
        rx="1.45"
        ry="3.29"
        transform="translate(20.15 61.25) rotate(-60)"
        fill="#ecc7c1"
        stroke-width="0"
      />
    </g>
    <g id="Money">
      <path
        d="M70.91,25.15l1.39-2.42c.17-.31.05-.69-.25-.85-.13-.07-.29-.09-.43-.06l-.64.13c-.08.01-.17.01-.25,0l-2.6-.45h-.22l-2.6.45c-.08.02-.17.02-.25,0l-.64-.13c-.34-.07-.67.14-.75.48-.03.15-.01.3.06.43l1.39,2.42c.18.3.08.68-.22.86-.07.04-.16.07-.24.08-2.06.23-3.57,2.03-3.44,4.1v9.5c0,.35.28.63.63.63h12.37c.35,0,.63-.28.63-.63v-9.5c.15-2.07-1.37-3.88-3.44-4.1-.34-.05-.58-.37-.53-.71.01-.08.04-.16.08-.23"
        fill="#c8c8c8"
        fill-rule="evenodd"
        stroke-width="0"
      />
      <path
        d="M71.84,33.3c.02,2.12-1.68,3.86-3.8,3.89-2.12.02-3.86-1.68-3.89-3.8-.02-2.12,1.68-3.86,3.8-3.89.01,0,.03,0,.04,0,2.11-.02,3.83,1.68,3.84,3.78,0,0,0,.01,0,.02"
        fill="#900"
        fill-rule="evenodd"
        stroke-width="0"
      />
      <g id="kr">
        <path
          d="M66.08,32.21v.96l1.08-1.12s.09-.1.13-.12c.05-.02.1-.02.15,0,.07,0,.14.02.18.07.03.05.03.11,0,.16,0,.09-.05.18-.12.24l-.67.63.78,1.11.12.19c.01.05.01.11,0,.16.02.05.02.11,0,.16-.06.02-.13.02-.19,0-.06.02-.13.02-.19,0-.05-.04-.09-.08-.12-.13l-.09-.15-.64-.99-.45.42v.57c.03.08.03.17,0,.25-.06.05-.13.07-.21.07h-.13l-.09-.1s0-.08,0-.12c0-.06,0-.13,0-.19v-2.08c-.03-.08-.03-.17,0-.25.05-.05.12-.08.19-.07.08,0,.15.03.21.07.05.07.08.16.07.25Z"
          fill="#f2f2f2"
          stroke-width="0"
        />
        <path
          d="M69.07,33.49h-.19v.87c0,.09-.02.18-.07.25-.05.05-.12.07-.19.07-.08,0-.15-.02-.21-.07-.05-.07-.08-.16-.07-.25v-2.08c-.04-.15.06-.31.21-.34.04-.01.09-.01.14,0h1.44c.09.04.16.09.22.16.07.06.12.14.15.22.02.09.02.19,0,.28,0,.18-.06.35-.18.48-.14.14-.32.23-.51.25.1.07.19.15.27.24.09.1.17.2.24.31.06.1.12.21.16.31.01.06.01.13,0,.19,0,.03,0,.07,0,.1-.03.03-.06.06-.1.07h-.13s-.1.01-.15,0c-.04-.03-.08-.06-.1-.1l-.13-.19-.13-.31-.21-.3c-.06-.06-.12-.11-.19-.15l-.22-.03ZM69.39,32.36h-.51v.73h.49c.11.02.22.02.33,0,.08-.01.16-.05.21-.12.03-.07.03-.15,0-.22.02-.06.02-.13,0-.19-.04-.06-.1-.11-.16-.13-.12-.04-.24-.06-.36-.06Z"
          fill="#f2f2f2"
          stroke-width="0"
        />
      </g>
      <path
        id="band"
        d="M70.97,26h-5.98c-.31-.05-.52-.34-.47-.65.04-.24.23-.43.47-.47h5.98c.31.05.52.34.47.65-.04.24-.23.43-.47.47"
        fill="#333"
        fill-rule="evenodd"
        stroke-width="0"
      />
    </g>
    <g id="Bandaid">
      <rect
        x="33.45"
        y="34.61"
        width="19.92"
        height="6.28"
        rx="2.74"
        ry="2.74"
        fill="#d69999"
        stroke-width="0"
      />
      <rect
        x="40.46"
        y="34.9"
        width="5.89"
        height="5.71"
        rx="1.09"
        ry="1.09"
        fill="#eacccc"
        stroke-width="0"
      />
      <ellipse
        cx="44.53"
        cy="36.75"
        rx=".7"
        ry=".72"
        fill="#f2f2f2"
        stroke-width="0"
      />
      <ellipse
        cx="42.29"
        cy="36.75"
        rx=".7"
        ry=".72"
        fill="#f2f2f2"
        stroke-width="0"
      />
      <ellipse
        cx="42.29"
        cy="38.74"
        rx=".7"
        ry=".72"
        fill="#f2f2f2"
        stroke-width="0"
      />
      <ellipse
        cx="44.53"
        cy="38.74"
        rx=".7"
        ry=".72"
        fill="#f2f2f2"
        stroke-width="0"
      />
    </g>
  </g>
</svg>
