/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Preselect } from './preselect';
import { PreselectSchema } from './preselect';
import * as JoiObj from "joi";


    export const TaeSchema = JoiObj.object({
        ekstra:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        gruppe:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        pgaPrognosen:
            
        JoiObj.link('#PreselectSchema')

.allow(null)



        ,
        stress:
            
        JoiObj.link('#PreselectSchema')

.allow(null)



        ,
        tilbageArbejdsmarked:
            
        JoiObj.link('#PreselectSchema')

.allow(null)



        ,
        tilbageHidtigeHvornaar:
            
        JoiObj.link('#PreselectSchema')

.allow(null)



        ,
        valgtSygdomsKategori:
            
        JoiObj.link('#PreselectSchema')


.required()


        ,
        whiplash:
            
        JoiObj.link('#PreselectSchema')

.allow(null)



        
    })
                .shared(PreselectSchema)
                .shared(PreselectSchema)
                .shared(PreselectSchema)
                .shared(PreselectSchema)
                .shared(PreselectSchema)
                .shared(PreselectSchema)
.id('TaeSchema')

export interface Tae { 
    ekstra: string;
    gruppe: string;
    pgaPrognosen?: Preselect;
    stress?: Preselect;
    tilbageArbejdsmarked?: Preselect;
    tilbageHidtigeHvornaar?: Preselect;
    valgtSygdomsKategori: Preselect;
    whiplash?: Preselect;
}

