/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ServiceInfo } from './serviceInfo';
import { ServiceInfoSchema } from './serviceInfo';
import { ResourceModel } from './resourceModel';
import { ResourceModelSchema } from './resourceModel';
import { CorrespondenceListData } from './correspondenceListData';
import { CorrespondenceListDataSchema } from './correspondenceListData';
import * as JoiObj from "joi";


    export const HentKorrespondenceListSvarSchema = JoiObj.object({
        serviceInfo:
            
        JoiObj.link('#ServiceInfoSchema')

.allow(null)



        ,
        fejlet:
            
                    JoiObj.boolean()
                


.required()


        ,
        hasHighImportance:
            
                    JoiObj.boolean()
                


.required()


        ,
        hasUrgent:
            
                    JoiObj.boolean()
                


.required()


        ,
        privateBusinessLogin:
            
                    JoiObj.boolean()
                


.required()


        ,
        threads:
                JoiObj.array().items(
        
        JoiObj.link('#CorrespondenceListDataSchema')

        )
        

.required()


        ,
        unreadCount:
            
                    JoiObj.number()
                


.required()


        
    })
                .shared(ServiceInfoSchema)
                .shared(CorrespondenceListDataSchema)
.id('HentKorrespondenceListSvarSchema')

export interface HentKorrespondenceListSvar extends ResourceModel { 
    fejlet: boolean;
    hasHighImportance: boolean;
    hasUrgent: boolean;
    privateBusinessLogin: boolean;
    threads: Array<CorrespondenceListData>;
    unreadCount: number;
}

