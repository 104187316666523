/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HenstandPolice } from './henstandPolice';
import { HenstandPoliceSchema } from './henstandPolice';
import * as JoiObj from "joi";


    export const GetHenstandPolicesResponseSchema = JoiObj.object({
        henstandPolices:
                JoiObj.array().items(
        
        JoiObj.link('#HenstandPoliceSchema')

        )
        

.required()


        
    })
                .shared(HenstandPoliceSchema)
.id('GetHenstandPolicesResponseSchema')

export interface GetHenstandPolicesResponse { 
    henstandPolices: Array<HenstandPolice>;
}

