/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Status } from './status';
import { StatusSchema } from './status';
import * as JoiObj from "joi";


    export const ProcesseringsStatusSchema = JoiObj.object({
        fejlKode:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        fejlTekst:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        processeringFejlet:
            
                    JoiObj.boolean()
                


.required()


        ,
        referenceId:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        status:
            
        JoiObj.link('#StatusSchema')


.required()


        
    })
                .shared(StatusSchema)
.id('ProcesseringsStatusSchema')

export interface ProcesseringsStatus { 
    fejlKode?: string;
    fejlTekst?: string;
    processeringFejlet: boolean;
    referenceId?: string;
    status: Status;
}
export namespace ProcesseringsStatus {
}


