/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { LeadType } from './leadType';
import { LeadTypeSchema } from './leadType';
import * as JoiObj from "joi";


    export const LeadSchema = JoiObj.object({
        note:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        leadType:
            
        JoiObj.link('#LeadTypeSchema')


.required()


        
    })
                .shared(LeadTypeSchema)
.id('LeadSchema')

export interface Lead { 
    note?: string;
    leadType: LeadType;
}
export namespace Lead {
}


