import { Injectable } from '@angular/core';
import { Case, IndividualcustomerdossierStore } from '@pfa/gen';

@Injectable({
  providedIn: 'root'
})
export class IndividualcustomerdossierService extends IndividualcustomerdossierStore {
  hasOpenCase(cases: Case[]): boolean {
    return cases.some(
      c => c.systemStatus === 'NEW' || c.systemStatus === 'IN_PROGRESS'
    );
  }

  hasOpenCaseCriticalIllness(cases: Case[]): boolean {
    return cases.some(
      c =>
        (c.systemStatus === 'NEW' && c.type === 'CriticalIllness') ||
        (c.systemStatus === 'IN_PROGRESS' && c.type === 'CriticalIllness')
    );
  }

  hasOpenCaseLossOfOccupationalCapacity(cases: Case[]): boolean {
    return cases.some(
      c =>
        (c.systemStatus === 'NEW' && c.type === 'OccupationalCapacity') ||
        (c.systemStatus === 'IN_PROGRESS' && c.type === 'OccupationalCapacity')
    );
  }

  hasOpenCaseHealthInsurance(cases: Case[]): boolean {
    return cases.some(
      c =>
        (c.systemStatus === 'NEW' && c.type === 'HealthInsurance') ||
        (c.systemStatus === 'IN_PROGRESS' && c.type === 'HealthInsurance')
    );
  }
}
