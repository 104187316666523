/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { LocScale } from './locScale';
import { LocScaleSchema } from './locScale';
import { LocScaleGroup } from './locScaleGroup';
import { LocScaleGroupSchema } from './locScaleGroup';
import * as JoiObj from "joi";


    export const LocScaleInformationSchema = JoiObj.object({
        allowed:
                JoiObj.array().items(
        
        JoiObj.link('#LocScaleSchema')

        )
        

.required()


        ,
        current:
            
        JoiObj.link('#LocScaleSchema')


.required()


        ,
        recommendation:
            
        JoiObj.link('#LocScaleSchema')


.required()


        ,
        scaleGroup:
            
        JoiObj.link('#LocScaleGroupSchema')


.required()


        
    })
                .shared(LocScaleSchema)
                .shared(LocScaleSchema)
                .shared(LocScaleSchema)
                .shared(LocScaleGroupSchema)
.id('LocScaleInformationSchema')

export interface LocScaleInformation { 
    allowed: Array<LocScale>;
    current: LocScale;
    recommendation: LocScale;
    scaleGroup: LocScaleGroup;
}
export namespace LocScaleInformation {
}


