/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BeneficiaryPolicyRule } from './beneficiaryPolicyRule';
import { BeneficiaryPolicyRuleSchema } from './beneficiaryPolicyRule';
import * as JoiObj from "joi";


    export const BeneficiaryRulesSchema = JoiObj.object({
        policies:
                JoiObj.array().items(
        
        JoiObj.link('#BeneficiaryPolicyRuleSchema')

        )
        

.required()


        
    })
                .shared(BeneficiaryPolicyRuleSchema)
.id('BeneficiaryRulesSchema')

export interface BeneficiaryRules { 
    policies: Array<BeneficiaryPolicyRule>;
}

