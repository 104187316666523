/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BaseResourceResponse } from './baseResourceResponse';
import { BaseResourceResponseSchema } from './baseResourceResponse';
import { MitIdSigningIframe } from './mitIdSigningIframe';
import { MitIdSigningIframeSchema } from './mitIdSigningIframe';
import * as JoiObj from "joi";


    export const SignResponseSchema = JoiObj.object({
        failed:
            
                    JoiObj.boolean()
                


.required()


        ,
        failureCode:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        failureText:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        mitIdSigningIframe:
            
        JoiObj.link('#MitIdSigningIframeSchema')


.required()


        ,
        revisionKey:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        
    })
                .shared(MitIdSigningIframeSchema)
.id('SignResponseSchema')

export interface SignResponse extends BaseResourceResponse { 
    mitIdSigningIframe: MitIdSigningIframe;
    revisionKey: string;
}

