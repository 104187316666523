import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  inject
} from '@angular/core';
import { Router } from '@angular/router';
import { BasketApiService } from '@pfa/api';
import { PageTrackingService, TrackingName } from '@pfa/core';
import {
  fadeHalfInOut,
  slideInBottom,
  slideInRight,
  slideOutBottomWhole,
  slideOutRight
} from '@pfa/animations';
import { GlobalWorkingService } from '@pfaform';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { globalCacheBusterNotifier } from 'ts-cacheable';
import { NbaCard } from './nba-card.model';
import { NbaCardService } from './nba-card.service';
import { PensionCheckService } from './pension-check.service';
import { NextBestActionService, RoutingService } from '@mitpfa/shared';
import {
  DynamicAdvisingCalculation,
  DynamicAdvisingOffer,
  DynamicAdvisingOfferGroup,
  DynamicAdvisingOfferGroupType,
  MineAendringer
} from '@pfa/gen';
import { DynamicAdvisingFilterService } from '@mitpfa/shared/services/dynamic-advising-filter.service';

interface NbaCardGroup {
  nbaCards: NbaCard[];
  groupType: DynamicAdvisingOfferGroupType;
  groupHeader: string;
}

@Component({
  selector: 'mitpfa-pension-check',
  templateUrl: './pension-check.component.html',
  styleUrls: ['./pension-check.component.scss'],
  animations: [
    slideInBottom,
    slideOutBottomWhole,
    fadeHalfInOut,
    slideInRight,
    slideOutRight
  ]
})
export class PensionCheckComponent implements OnInit, OnDestroy {
  private readonly pensionCheckService: PensionCheckService =
    inject(PensionCheckService);
  private readonly nextBestActionService: NextBestActionService = inject(
    NextBestActionService
  );
  private readonly nbaCardService: NbaCardService = inject(NbaCardService);
  private readonly router: Router = inject(Router);
  private readonly workingService: GlobalWorkingService =
    inject(GlobalWorkingService);
  private readonly basketApiService: BasketApiService =
    inject(BasketApiService);
  private readonly routingService: RoutingService = inject(RoutingService);
  private readonly dynamicAdvisingFilterService: DynamicAdvisingFilterService =
    inject(DynamicAdvisingFilterService);
  private readonly pageTrackingService: PageTrackingService =
    inject(PageTrackingService);

  @Output() private readonly close = new EventEmitter<unknown>();
  public myChanges$: Observable<MineAendringer>;

  private unsubscribe = new Subject<void>();
  public nbaCardGroups: NbaCardGroup[];
  public nbaId: string;
  public sigmaFailed: boolean;
  public showCardViewer = false;
  public selectedCard: NbaCard;
  public numberOfNotOkCards = 0;
  public introTextContentId: string;

  public ngOnInit(): void {
    setTimeout(() => {
      this.basketApiService
        .clearBasket()
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(() => {});
      this.myChanges$ = this.basketApiService
        .getAll()
        .pipe(takeUntil(this.unsubscribe));
      this.setupPensionsCheck();
    }, 500);
  }

  private createNbaCardFromOffers(
    dynamicAdvisingCalculation: DynamicAdvisingCalculation,
    groupType: DynamicAdvisingOfferGroupType
  ): NbaCard[] {
    const offersWithOnlyOneOfferInEachCategory =
      this.nextBestActionService.ensureOnlyOneDataOffer(
        this.dynamicAdvisingFilterService.filterOfferGroupsByType(
          dynamicAdvisingCalculation.offerGroups,
          groupType
        ).offers
      );
    const nbaCards = this.nbaCardService.buildNbaCard(
      offersWithOnlyOneOfferInEachCategory
    );
    nbaCards.forEach((nbaCard: NbaCard) => {
      this.nbaCardService.fillContentInCards(
        nbaCard,
        dynamicAdvisingCalculation
      );
      if (nbaCard.severity >= 2) {
        this.numberOfNotOkCards++;
      }
    });
    return nbaCards;
  }

  private getOfferGroupName(groupType: DynamicAdvisingOfferGroupType): string {
    switch (groupType) {
      case 'INSURANCE':
        return 'DL.NB01.C334';
      case 'INVESTMENT':
        return 'DL.NB01.C335';
      case 'MISSING_DATA':
        return 'DL.NB01.C336';
    }
    return '';
  }

  private setupPensionsCheck(): void {
    this.workingService.show();
    this.nextBestActionService
      .getAllSortedNbaCards()
      .pipe(
        map((dynamicAdvisingCalculation: DynamicAdvisingCalculation) => {
          document.querySelector('body').classList.add('modal-open');
          this.nbaId = dynamicAdvisingCalculation.nbaId;
          const offers: DynamicAdvisingOffer[] =
            this.dynamicAdvisingFilterService.getAllOffers(
              dynamicAdvisingCalculation.offerGroups
            );
          if (offers) {
            this.nbaCardGroups = new Array<NbaCardGroup>();
            dynamicAdvisingCalculation.offerGroups.forEach(
              (offerGroup: DynamicAdvisingOfferGroup) => {
                if (
                  this.dynamicAdvisingFilterService.showOfferGroup(offerGroup)
                ) {
                  this.nbaCardGroups.push({
                    groupType: offerGroup.groupType,
                    groupHeader: this.getOfferGroupName(offerGroup.groupType),
                    nbaCards: this.createNbaCardFromOffers(
                      dynamicAdvisingCalculation,
                      offerGroup.groupType
                    )
                  });
                }
              }
            );

            this.introTextContentId = 'DL.FS03.C63';
            if (this.numberOfNotOkCards === 1) {
              this.introTextContentId = 'DL.DR01.C76';
            } else if (this.numberOfNotOkCards > 1) {
              this.introTextContentId = 'DL.DR01.C66';
            }

            this.pensionCheckService.trackNbaOffersList(
              TrackingName.PENSION_CHECK,
              dynamicAdvisingCalculation.nbaId,
              offers
            );

            return offers;
          } else {
            return [];
          }
        })
      )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next: (offers: DynamicAdvisingOffer[]) => {
          this.pageTrackingService.trackContentLoaded();
          this.workingService.hide();
        },
        error: () => {
          this.sigmaFailed = true;
          this.workingService.hide();
        }
      });
  }

  public closePensionCheck(): void {
    this.basketApiService
      .clearBasket()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {});
    const url = this.routingService.getPreviousUrl();
    if (url && url !== '/velkomst' && url !== '/handlingscenter') {
      this.router.navigate([url]);
    } else {
      this.router.navigate(['/mitoverblik']);
    }
  }

  public ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
    this.basketApiService
      .clearBasket()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {});
    document.querySelector('body').classList.remove('modal-open');
    globalCacheBusterNotifier.next();
  }

  public closeViewer(): void {
    this.showCardViewer = false;
  }

  public goTo(nbaCard: NbaCard): void {
    this.adobeTrackingClick(nbaCard);
    if (nbaCard.gotoUrl) {
      this.router.navigateByUrl(nbaCard.gotoUrl);
    } else {
      this.showCardViewer = true;
      this.selectedCard = nbaCard;
    }
  }

  private adobeTrackingClick(nbaCard: NbaCard): void {
    this.nextBestActionService.trackNbaOfferClick(
      TrackingName.PENSION_CHECK,
      this.nbaId,
      nbaCard.id,
      nbaCard.hash,
      nbaCard.index,
      nbaCard.severity
    );
  }
}
