<div class="card" [ngClass]="{ invert: invert, simple: simple }">
  <div class="card-container">
    <div
      [@collapse]="collapseState"
      [@opacity]="opacityState"
      [style.minHeight]="minHeight"
      class="content-container"
      #content
    >
      <div class="header" [ngClass]="{ emphasized: emphasized }">
        <span class="header-label" *ngIf="label">{{ label }}</span>
        <span class="header-text"
          ><ng-content select="[header]"></ng-content
        ></span>
      </div>
      <div *ngIf="middle" class="middle">
        <ng-content select="[middle]"></ng-content>
      </div>
      <div
        class="body"
        *ngIf="!noBottom"
        [ngClass]="{ 'with-bottom-warning': bottomWarning }"
      >
        <div class="bottom-warning" *ngIf="bottomWarning"></div>
        <div [ngClass]="{ 'bottom-warning-content': bottomWarning }">
          <co-icon-doubt *ngIf="bottomWarning" class="warning"></co-icon-doubt>
          <div [ngClass]="{ 'bottom-warning-content--inner': bottomWarning }">
            <ng-content select="[body]"> </ng-content>
          </div>
        </div>
        <co-icon-arrow-right
          *ngIf="interactiveCardIndicator"
          class="interactive-card-indicator"
        ></co-icon-arrow-right>
      </div>
    </div>
  </div>
</div>
