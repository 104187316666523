import {
  Component,
  Input,
  ChangeDetectionStrategy,
  OnDestroy,
  OnInit
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { PensionsKundeGenerelleData } from '@pfa/gen';
import { generateSliderStepsArray } from '@pfaform';
import { Options } from 'ngx-slider-v2';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { FinancialSituationConfigurationService } from '../../services/financial-situation-configuration.service';

@Component({
  selector: 'mitpfa-financial-situation-real-estate-form',
  templateUrl: './financial-situation-real-estate-form.component.html',
  styleUrls: ['./financial-situation-real-estate-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FinancialSituationRealEstateFormComponent
  implements OnInit, OnDestroy
{
  @Input() formGroup: UntypedFormGroup;
  @Input() pensionCustomer: PensionsKundeGenerelleData;
  @Input() withAdvisorMenu = true;
  @Input() isInInsuranceGuide = false;

  public propertyTypes: { value: string; translation: string }[];
  public ownershipTypes: { value: string; translation: string }[];
  public coOwnerTypes: { value: number; translation: string }[];
  public displaySharingSection = false;
  public displayOwnershipDetailsForm = false;
  public displayCoOwnersForm = false;
  public ownershipForm: UntypedFormGroup;
  public sliderOptions: Options;
  private subscriptions: { [subscribtionKey: string]: Subscription } = {};
  public isAdvisor = false;

  constructor(
    readonly financialSituationConfigurationService: FinancialSituationConfigurationService
  ) {
    this.propertyTypes =
      this.financialSituationConfigurationService.propertyTypes;
    this.ownershipTypes =
      this.financialSituationConfigurationService.ownershipTypes;
    this.coOwnerTypes =
      this.financialSituationConfigurationService.coOwnerTypes;
  }

  ngOnInit(): void {
    this.isAdvisor = this.pensionCustomer.isRaadgiver;
    if (!this.isAdvisor) {
      this.sliderOptions = {
        stepsArray: [
          ...generateSliderStepsArray(0, 3000000, 100000),
          ...generateSliderStepsArray(3500000, 10000000, 500000)
        ],
        ceil: 10000000
      };
    }
  }

  ngOnDestroy(): void {
    Object.keys(this.subscriptions).forEach(
      key => this.subscriptions[key] && this.subscriptions[key].unsubscribe()
    );
  }

  private updateYourShareOfResidence(newValues: {
    type: string;
    isEquallyDivided: boolean;
    coOwners: number;
  }): void {
    if (
      newValues.type === this.ownershipTypes[1].value &&
      newValues.isEquallyDivided
    ) {
      this.formGroup.controls.yourShareOfResidence.setValue(50, {
        emitEvent: false
      });
      this.formGroup.markAsDirty();
      this.formGroup.markAllAsTouched();
    }

    if (
      newValues.type === this.ownershipTypes[2].value &&
      newValues.isEquallyDivided
    ) {
      this.formGroup.controls.yourShareOfResidence.setValue(
        Math.floor(100 / newValues.coOwners),
        {
          emitEvent: false
        }
      );
      this.formGroup.markAsDirty();
      this.formGroup.markAllAsTouched();
    }
  }

  public toggleDisplayOwnershipDetailsForm(): void {
    this.displayOwnershipDetailsForm = !this.displayOwnershipDetailsForm;
    if (this.displayOwnershipDetailsForm) {
      this.createOwnershipForm();
    }
  }

  private createOwnershipForm(): void {
    this.formGroup.controls.yourShareOfResidence.setValue(100);
    this.formGroup.markAsDirty();
    this.formGroup.markAllAsTouched();
    this.ownershipForm = new UntypedFormGroup({
      type: new UntypedFormControl(
        this.ownershipTypes[0].value,
        Validators.required
      ),
      coOwners: new UntypedFormControl(
        this.coOwnerTypes[0].value,
        Validators.required
      ),
      isEquallyDivided: new UntypedFormControl(true, Validators.required)
    });

    this.subscriptions.onOwnershipChange = this.ownershipForm.valueChanges
      .pipe(tap(this.updateYourShareOfResidence.bind(this)))
      .subscribe();

    this.subscriptions.onOwnershipTypeChange =
      this.ownershipForm.controls.type.valueChanges
        .pipe(
          tap((value: string) => {
            this.displayCoOwnersForm = value === this.ownershipTypes[2].value;
            this.displaySharingSection = value !== this.ownershipTypes[0].value;
          })
        )
        .subscribe();
  }
}
