/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const EksternOverfoerselAdvarselSchema = JoiObj.string().valid(
        'TJENESTEMANDSPENSION'
        ,
        'HAR_INDEKSKONTRAKT'
        ,
        'HAR_SELVPENSIONERING'
        ,
        'HAR_INDBETALING'
        ,
        'UNDER_UDBETALING'
        ,
        'PARAGRAF_53A'
        ,
        'SKATTEFRI_DAEKNING'
        ,
        'UDEN_OPSPARING'
        ,
        'SAERLIGE_VILKAAR'
        ,
        'PI_SELSKAB_KAN_IKKE_OVERFOERE'
        ,
        'UKENDT_PENSIONSUDBYDER'
        ,
        'HAR_MARKEDSRENTE'
        ,
        'HAR_GENNEMSNITSRENTE'
        ,
        'POPUP_EFTERLOEN'
        ,
        'HAR_LIVRENTE'
        ,
        'PI_OMKOSTNINGER'
        ,
        'GENNEMSNITSRENTE_LIVSVARIG_LIMIT'
        ,
        'INDENFOR_JOBSKIFTE'
        ,
        'LILLE_FRIPOLICE'
        ,
        'ANBEFALET_VELFAERD'
        ,
        'HAR_FORSIKRINGER'
        ,
        'ANBEFALET_FORSIKRINGER'
        ,
        'IKKE_ANBEFALET_FORSIKRINGER'
        ,
        'IKKE_ANBEFALET_VELFAERD'
        ,
        'ANBEFALET_RAADGIVER'
        ,
        'IKKE_ANBEFALET_RAADGIVER'
        ,
        'MAASKE_MODTAGET_JA'
        ,
        'MAASKE_MODTAGET_NEJ'
        ,
        'MAASKE_ORDNING_BESTILT'
        ,
        'HAR_INDBETALING_UNDERTRYKT'
        ,
        'LIMIT_EXCEEDED'
        
    )
.id('EksternOverfoerselAdvarselSchema')

export type EksternOverfoerselAdvarsel = 'TJENESTEMANDSPENSION' | 'HAR_INDEKSKONTRAKT' | 'HAR_SELVPENSIONERING' | 'HAR_INDBETALING' | 'UNDER_UDBETALING' | 'PARAGRAF_53A' | 'SKATTEFRI_DAEKNING' | 'UDEN_OPSPARING' | 'SAERLIGE_VILKAAR' | 'PI_SELSKAB_KAN_IKKE_OVERFOERE' | 'UKENDT_PENSIONSUDBYDER' | 'HAR_MARKEDSRENTE' | 'HAR_GENNEMSNITSRENTE' | 'POPUP_EFTERLOEN' | 'HAR_LIVRENTE' | 'PI_OMKOSTNINGER' | 'GENNEMSNITSRENTE_LIVSVARIG_LIMIT' | 'INDENFOR_JOBSKIFTE' | 'LILLE_FRIPOLICE' | 'ANBEFALET_VELFAERD' | 'HAR_FORSIKRINGER' | 'ANBEFALET_FORSIKRINGER' | 'IKKE_ANBEFALET_FORSIKRINGER' | 'IKKE_ANBEFALET_VELFAERD' | 'ANBEFALET_RAADGIVER' | 'IKKE_ANBEFALET_RAADGIVER' | 'MAASKE_MODTAGET_JA' | 'MAASKE_MODTAGET_NEJ' | 'MAASKE_ORDNING_BESTILT' | 'HAR_INDBETALING_UNDERTRYKT' | 'LIMIT_EXCEEDED';

export const EksternOverfoerselAdvarsel = {
    Tjenestemandspension: 'TJENESTEMANDSPENSION' as EksternOverfoerselAdvarsel,
    HarIndekskontrakt: 'HAR_INDEKSKONTRAKT' as EksternOverfoerselAdvarsel,
    HarSelvpensionering: 'HAR_SELVPENSIONERING' as EksternOverfoerselAdvarsel,
    HarIndbetaling: 'HAR_INDBETALING' as EksternOverfoerselAdvarsel,
    UnderUdbetaling: 'UNDER_UDBETALING' as EksternOverfoerselAdvarsel,
    Paragraf53A: 'PARAGRAF_53A' as EksternOverfoerselAdvarsel,
    SkattefriDaekning: 'SKATTEFRI_DAEKNING' as EksternOverfoerselAdvarsel,
    UdenOpsparing: 'UDEN_OPSPARING' as EksternOverfoerselAdvarsel,
    SaerligeVilkaar: 'SAERLIGE_VILKAAR' as EksternOverfoerselAdvarsel,
    PiSelskabKanIkkeOverfoere: 'PI_SELSKAB_KAN_IKKE_OVERFOERE' as EksternOverfoerselAdvarsel,
    UkendtPensionsudbyder: 'UKENDT_PENSIONSUDBYDER' as EksternOverfoerselAdvarsel,
    HarMarkedsrente: 'HAR_MARKEDSRENTE' as EksternOverfoerselAdvarsel,
    HarGennemsnitsrente: 'HAR_GENNEMSNITSRENTE' as EksternOverfoerselAdvarsel,
    PopupEfterloen: 'POPUP_EFTERLOEN' as EksternOverfoerselAdvarsel,
    HarLivrente: 'HAR_LIVRENTE' as EksternOverfoerselAdvarsel,
    PiOmkostninger: 'PI_OMKOSTNINGER' as EksternOverfoerselAdvarsel,
    GennemsnitsrenteLivsvarigLimit: 'GENNEMSNITSRENTE_LIVSVARIG_LIMIT' as EksternOverfoerselAdvarsel,
    IndenforJobskifte: 'INDENFOR_JOBSKIFTE' as EksternOverfoerselAdvarsel,
    LilleFripolice: 'LILLE_FRIPOLICE' as EksternOverfoerselAdvarsel,
    AnbefaletVelfaerd: 'ANBEFALET_VELFAERD' as EksternOverfoerselAdvarsel,
    HarForsikringer: 'HAR_FORSIKRINGER' as EksternOverfoerselAdvarsel,
    AnbefaletForsikringer: 'ANBEFALET_FORSIKRINGER' as EksternOverfoerselAdvarsel,
    IkkeAnbefaletForsikringer: 'IKKE_ANBEFALET_FORSIKRINGER' as EksternOverfoerselAdvarsel,
    IkkeAnbefaletVelfaerd: 'IKKE_ANBEFALET_VELFAERD' as EksternOverfoerselAdvarsel,
    AnbefaletRaadgiver: 'ANBEFALET_RAADGIVER' as EksternOverfoerselAdvarsel,
    IkkeAnbefaletRaadgiver: 'IKKE_ANBEFALET_RAADGIVER' as EksternOverfoerselAdvarsel,
    MaaskeModtagetJa: 'MAASKE_MODTAGET_JA' as EksternOverfoerselAdvarsel,
    MaaskeModtagetNej: 'MAASKE_MODTAGET_NEJ' as EksternOverfoerselAdvarsel,
    MaaskeOrdningBestilt: 'MAASKE_ORDNING_BESTILT' as EksternOverfoerselAdvarsel,
    HarIndbetalingUndertrykt: 'HAR_INDBETALING_UNDERTRYKT' as EksternOverfoerselAdvarsel,
    LimitExceeded: 'LIMIT_EXCEEDED' as EksternOverfoerselAdvarsel
};

