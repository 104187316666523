import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {
  InsuranceGuideQuestion,
  InsuranceGuideQuestionValue
} from '../../insurance-guide-configuration.service';
import { FamilyInformationApiService } from '@pfa/api';
import {
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { PersonNameValidator } from '@pfa/utils';
import * as moment from 'moment';
import {
  FamilieRelationBarn,
  StamdataDetaljer,
  PensionsKundeGenerelleData
} from '@pfa/gen';
import {
  NotificationService,
  NotificationType,
  GlobalWorkingService
} from '@pfaform';

@Component({
  selector: 'mitpfa-insurance-guide-your-family',
  templateUrl: './insurance-guide-your-family.component.html',
  styleUrls: ['./insurance-guide-your-family.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InsuranceGuideYourFamilyComponent implements OnInit {
  @Input() currentQuestion: BehaviorSubject<InsuranceGuideQuestionValue>;
  @Input() familyInformation: StamdataDetaljer;
  @Input() pensionCustomer: PensionsKundeGenerelleData;

  public partnerInfoForm: UntypedFormGroup;
  hasSpouse: boolean;
  showError: boolean;
  public childForms: UntypedFormGroup;

  constructor(
    private familyInformationApiService: FamilyInformationApiService,
    private globalWorking: GlobalWorkingService,
    private readonly notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    this.hasSpouse = this.familyInformation.civilstand === 'Gift';
    this.buildPartnerInfoForm();
    this.buildChildForms();
  }

  buildPartnerInfoForm(): void {
    const isMarried = this.familyInformation.civilstand === 'Gift';

    this.partnerInfoForm = new UntypedFormGroup({
      hasPartner: new UntypedFormControl(this.familyInformation.samlevende, {
        validators: !isMarried ? Validators.required : null
      }),
      hasPartner2Years: new UntypedFormControl(
        this.familyInformation.samlevendeOver2aar,
        {
          validators: !isMarried ? Validators.required : null
        }
      ),
      partnerName: new UntypedFormControl(
        this.getFullName(
          this.familyInformation.partnerFornavn,
          this.familyInformation.partnerEfternavn
        ),
        {
          validators: [Validators.required, PersonNameValidator]
        }
      ),
      partnerLastName: new UntypedFormControl(
        this.familyInformation.partnerEfternavn
      ),
      partnerBirthday: new UntypedFormControl(
        moment(this.familyInformation.partnerFoedselsDato, 'DD.MM.YYYY')
      ),
      partnerSalary: new UntypedFormControl(
        this.familyInformation.partnerAarsloen,
        {
          validators: [Validators.maxLength(10)]
        }
      )
    });
  }

  getFullName(firstName: string, lastName: string): string {
    return (firstName ? firstName : '') + (lastName ? ' ' + lastName : '');
  }

  public goNext(): void {
    this.save();
    this.currentQuestion.next({ value: InsuranceGuideQuestion.assets });
    window.scroll(0, 0);
  }

  public goBack(): void {
    this.keepFamilyDataState();
    this.currentQuestion.next({
      value: InsuranceGuideQuestion.pensionInfo,
      back: true
    });
  }

  isInputValid(): boolean {
    return (
      (this.partnerInfoForm.valid ||
        this.partnerInfoForm.get('hasPartner')?.value === false) &&
      this.childForms.valid
    );
  }

  buildChildForms(): void {
    const childs = new UntypedFormArray([]);
    if (this.familyInformation.familieRelationBoern) {
      this.familyInformation.familieRelationBoern.forEach(child => {
        childs.push(
          new UntypedFormGroup({
            familyRelationId: new UntypedFormControl(child.familieRelationId),
            childName: new UntypedFormControl(
              this.getFullName(child.fornavn, child.efternavn),
              {
                validators: [Validators.required, PersonNameValidator]
              }
            ),
            birthDate: new UntypedFormControl(
              moment(child.foedselsDato, 'DD.MM.YYYY'),
              {
                validators: Validators.required
              }
            ),
            relationType: new UntypedFormControl(child.barnType, {
              validators: Validators.required
            })
          })
        );
      });
    }
    this.childForms = new UntypedFormGroup({
      isLegalProvider: new UntypedFormControl(
        this.familyInformation.forsoergerpligt,
        {
          validators: Validators.required
        }
      ),
      childs: childs
    });
  }

  toFamilyRelationArray(): FamilieRelationBarn[] {
    const childArray: FamilieRelationBarn[] = [];
    (this.childForms.get('childs') as UntypedFormArray).controls.forEach(
      childForm => {
        childArray.push({
          familieRelationId: childForm.get('familyRelationId')?.value,
          fornavn: this.getFirstNameFromFullName(
            childForm.get('childName')?.value
          ),
          efternavn: this.getLastNameFromFullName(
            childForm.get('childName')?.value
          ),
          foedselsDato: moment(childForm.get('birthDate')?.value).format(
            'DD.MM.YYYY'
          ),
          barnType: childForm.get('relationType')?.value,
          arveandel: 0,
          serviceInfo: undefined
        });
      }
    );
    return childArray;
  }

  getFirstNameFromFullName(fullName: string): string {
    if (fullName) {
      const split = fullName.split(' ');
      if (split.length > 1) {
        split.pop();
      }
      return split.join(' ');
    } else {
      return '';
    }
  }

  getLastNameFromFullName(fullName: string) {
    let result: string | undefined;
    if (fullName) {
      const split = fullName.split(' ');
      result = split.length > 1 ? split.pop() : '';
    }
    return result ? result : '';
  }

  save(): void {
    this.globalWorking.show();
    this.showError = false;
    this.keepFamilyDataState();
    this.familyInformationApiService
      .updateFamilyInformation(this.familyInformation)
      .subscribe({
        next: familyInformation => {
          this.familyInformation = familyInformation;
          this.childForms.markAsPristine();
          this.partnerInfoForm.markAsPristine();
          this.globalWorking.hide();
          this.notificationService.showNotification({
            message: 'DL.FG01.C1970',
            type: NotificationType.SUCCESS
          });
        },
        error: () => {
          this.showError = true;
          this.globalWorking.hide();
        }
      });
  }

  private keepFamilyDataState() {
    this.familyInformation.samlevende =
      this.partnerInfoForm.get('hasPartner')?.value;
    this.familyInformation.partnerFornavn = this.getFirstNameFromFullName(
      this.partnerInfoForm.get('partnerName')?.value
    );
    this.familyInformation.partnerEfternavn = this.getLastNameFromFullName(
      this.partnerInfoForm.get('partnerName')?.value
    );
    this.familyInformation.samlevendeOver2aar =
      this.partnerInfoForm.get('hasPartner2Years')?.value;
    this.familyInformation.partnerFoedselsDato = moment(
      this.partnerInfoForm.get('partnerBirthday')?.value
    ).format('DD.MM.YYYY');
    this.familyInformation.partnerAarsloen =
      this.partnerInfoForm.get('partnerSalary')?.value;
    this.familyInformation.samlevendeOver2aar =
      this.partnerInfoForm.get('hasPartner2Years')?.value;
    this.familyInformation.forsoergerpligt =
      this.childForms.get('isLegalProvider')?.value;
    this.familyInformation.familieRelationBoern = this.toFamilyRelationArray();
  }
}
