/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { FejledeValideringerPolice } from './fejledeValideringerPolice';
import { FejledeValideringerPoliceSchema } from './fejledeValideringerPolice';
import { MuligOverfoerselPolice } from './muligOverfoerselPolice';
import { MuligOverfoerselPoliceSchema } from './muligOverfoerselPolice';
import * as JoiObj from "joi";


    export const HentInternOverfoerselResponseSchema = JoiObj.object({
        aiaPolice:
            
                    JoiObj.boolean()
                


.required()


        ,
        anbefaletLivnetPolicenummer:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        fejledeValideringerPolicer:
                JoiObj.array().items(
        
        JoiObj.link('#FejledeValideringerPoliceSchema')

        )
        
.allow(null)



        ,
        ingenAnbefaletOrdninger:
            
                    JoiObj.boolean()
                


.required()


        ,
        livnetKundekapital:
            
                    JoiObj.boolean()
                


.required()


        ,
        muligOverfoerselPolicer:
                JoiObj.array().items(
        
        JoiObj.link('#MuligOverfoerselPoliceSchema')

        )
        

.required()


        ,
        mulighedForLivnetKK:
            
                    JoiObj.boolean()
                


.required()


        ,
        overfoereNaestMaaned:
            
                    JoiObj.boolean()
                


.required()


        ,
        overfoeresTilFripolice:
            
                    JoiObj.boolean()
                


.required()


        ,
        overfoerselIkkeFaerdigBehandlet:
            
                    JoiObj.boolean()
                


.required()


        ,
        overfoerseltillaegdato:
            
            JoiObj.date()

.allow(null)



        ,
        profilValgt:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        revisionKey:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        tekniskeProblemer:
            
                    JoiObj.boolean()
                


.required()


        ,
        viseDoedEllerInvalidBetingelse:
            
                    JoiObj.boolean()
                


.required()


        ,
        viseEfterloenBetingelse:
            
                    JoiObj.boolean()
                


.required()


        ,
        viseFlerePolicerKanIkkeOverfoerses:
            
                    JoiObj.boolean()
                


.required()


        ,
        visefejlside:
            
                    JoiObj.boolean()
                


.required()


        
    })
                .shared(FejledeValideringerPoliceSchema)
                .shared(MuligOverfoerselPoliceSchema)
.id('HentInternOverfoerselResponseSchema')

export interface HentInternOverfoerselResponse { 
    aiaPolice: boolean;
    anbefaletLivnetPolicenummer?: string;
    /**
     * List of policies thet cannot be transferred
     */
    fejledeValideringerPolicer?: Array<FejledeValideringerPolice>;
    ingenAnbefaletOrdninger: boolean;
    livnetKundekapital: boolean;
    muligOverfoerselPolicer: Array<MuligOverfoerselPolice>;
    mulighedForLivnetKK: boolean;
    overfoereNaestMaaned: boolean;
    overfoeresTilFripolice: boolean;
    overfoerselIkkeFaerdigBehandlet: boolean;
    overfoerseltillaegdato?: Date;
    profilValgt?: string;
    revisionKey: string;
    tekniskeProblemer: boolean;
    viseDoedEllerInvalidBetingelse: boolean;
    viseEfterloenBetingelse: boolean;
    viseFlerePolicerKanIkkeOverfoerses: boolean;
    visefejlside: boolean;
}

