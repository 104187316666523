/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const PolicyStatusSchema = JoiObj.string().valid(
        'CANCELLED'
        ,
        'DEPARTURE_EXTENSION'
        ,
        'EXPIRED'
        ,
        'INFORCE'
        ,
        'MATURED'
        ,
        'NOT_TAKEN_OUT'
        ,
        'PAID_UP'
        ,
        'PREMIUM_HOLIDAY'
        ,
        'STOP'
        ,
        'SURRENDED'
        ,
        'TRANSFERRED'
        ,
        'TRANSFERRED_GIPP'
        ,
        'UDBETALINGSPOLICE_DOED'
        ,
        'WAIVER'
        ,
        'NOT_ISSUED'
        ,
        'NOT_TAKEN_UP'
        
    )
.id('PolicyStatusSchema')

export type PolicyStatus = 'CANCELLED' | 'DEPARTURE_EXTENSION' | 'EXPIRED' | 'INFORCE' | 'MATURED' | 'NOT_TAKEN_OUT' | 'PAID_UP' | 'PREMIUM_HOLIDAY' | 'STOP' | 'SURRENDED' | 'TRANSFERRED' | 'TRANSFERRED_GIPP' | 'UDBETALINGSPOLICE_DOED' | 'WAIVER' | 'NOT_ISSUED' | 'NOT_TAKEN_UP';

export const PolicyStatus = {
    Cancelled: 'CANCELLED' as PolicyStatus,
    DepartureExtension: 'DEPARTURE_EXTENSION' as PolicyStatus,
    Expired: 'EXPIRED' as PolicyStatus,
    Inforce: 'INFORCE' as PolicyStatus,
    Matured: 'MATURED' as PolicyStatus,
    NotTakenOut: 'NOT_TAKEN_OUT' as PolicyStatus,
    PaidUp: 'PAID_UP' as PolicyStatus,
    PremiumHoliday: 'PREMIUM_HOLIDAY' as PolicyStatus,
    Stop: 'STOP' as PolicyStatus,
    Surrended: 'SURRENDED' as PolicyStatus,
    Transferred: 'TRANSFERRED' as PolicyStatus,
    TransferredGipp: 'TRANSFERRED_GIPP' as PolicyStatus,
    UdbetalingspoliceDoed: 'UDBETALINGSPOLICE_DOED' as PolicyStatus,
    Waiver: 'WAIVER' as PolicyStatus,
    NotIssued: 'NOT_ISSUED' as PolicyStatus,
    NotTakenUp: 'NOT_TAKEN_UP' as PolicyStatus
};

