/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const AnswerSchema = JoiObj.object({
        answersIds:
                JoiObj.array().items(
        
                    JoiObj.number()
                

        )
        

.required()


        ,
        integer:
            
                    JoiObj.number()
                


.required()


        ,
        questionId:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        text:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        
    })
.id('AnswerSchema')

export interface Answer { 
    answersIds: Array<number>;
    integer: number;
    questionId: string;
    text: string;
}

