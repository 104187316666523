<co-loading [show]="!healthInformationInitialized"></co-loading>

<co-card class="basket-card" *ngIf="healthInformationInitialized">
  <h3 [coContent]="'DL.MA01.C164'"></h3>
  <div *ngFor="let changes of accumulatedChanges.aendringer">
    <p
      class="text--bold"
      [coContent]="'DL.MA01.C06'"
      [coContentSubst]="changes.pensionsaftaleId"
    ></p>
    <div *ngIf="changes.forsikring.length > 0">
      <mitpfa-basket-other-item
        [items]="changes.forsikring"
      ></mitpfa-basket-other-item>
    </div>
    <hr class="hr--light" />
  </div>

  <div class="spacing-unit--over">
    <co-icon-with-text
      *ngIf="
        pensionCustomer.isPrivat && accumulatedChanges.afgivesHelbredsoplysinger
      "
    >
      <co-icon-error icon></co-icon-error>
      <div class="icon-text" body [coContent]="'DL.MA01.C50'"></div>
    </co-icon-with-text>
    <co-icon-with-text
      *ngIf="
        pensionCustomer.isRaadgiver &&
        accumulatedChanges.afgivesHelbredsoplysinger
      "
    >
      <co-icon-error icon></co-icon-error>
      <div body [coContent]="'DL.MA01.C51'"></div>
    </co-icon-with-text>
    <co-icon-with-text
      *ngIf="
        pensionCustomer.isRaadgiver &&
        !accumulatedChanges.afgivesHelbredsoplysinger
      "
    >
      <co-icon-error icon></co-icon-error>
      <div body [coContent]="'DL.MA01.C52'"></div>
    </co-icon-with-text>
  </div>

  <div class="buttons">
    <button
      mat-raised-button
      (click)="cancel()"
      [coContent]="'DL.MA01.C101'"
    ></button>
    <button
      mat-raised-button
      (click)="accept()"
      [coContent]="'DL.MA01.C54'"
    ></button>
  </div>
  <div
    *ngIf="errorUpdating"
    class="align--right color--primary"
    [coContent]="'DL.MD01.C161'"
  ></div>
</co-card>
