/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Question } from './question';
import { QuestionSchema } from './question';
import * as JoiObj from "joi";


    export const PossibleAnswerSchema = JoiObj.object({
        hint:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        id:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        questions:
                JoiObj.array().items(
        
        JoiObj.link('#QuestionSchema')

        )
        

.required()


        ,
        text:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        
    })
.id('PossibleAnswerSchema')

export interface PossibleAnswer { 
    hint: string;
    id: string;
    questions: Array<Question>;
    text: string;
}

