import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'mitpfa-onboarding-introduction-card',
  templateUrl: './introduction-card.component.html',
  styleUrls: ['./introduction-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IntroductionCardComponent {
  @Input() number: number;
  @Input() image: 'INFORMATION' | 'INSURANCE' | 'INVESTMENT_GUIDE' | 'PENSION';
  @Input() headerText: string;
  @Input() bodyText: string;
}
