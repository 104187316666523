import { Component } from '@angular/core';

@Component({
  selector: 'co-icon-advantage-lb-insurance',
  templateUrl: 'icon-advantage-lb-insurance.component.html',
  styleUrls: [
    '../icon-base.scss',
    './icon-advantage-lb-insurance.component.scss'
  ]
})
export class IconAdvantageLBInsuraneComponent {}
