/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Sats } from './sats';
import { SatsSchema } from './sats';
import * as JoiObj from "joi";


    export const PensionsplanSatserSchema = JoiObj.object({
        activeNow:
            
                    JoiObj.boolean()
                


.required()


        ,
        name:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        updatedAt:
            
            JoiObj.date()


.required()


        ,
        updatedBy:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        uuid:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        validFrom:
            
            JoiObj.date()

.allow(null)



        ,
        validFromFormatted:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        year:
            
                    JoiObj.number()
                


.required()


        ,
        aktierInflation:
            
                    JoiObj.number()
                


.required()


        ,
        aktierOmkostning:
            
                    JoiObj.number()
                


.required()


        ,
        aktierSamfundsforudsaetning:
            
                    JoiObj.number()
                


.required()


        ,
        aktierSkat:
            
                    JoiObj.number()
                


.required()


        ,
        amb:
            
                    JoiObj.number()
                


.required()


        ,
        anbefaletMaxPensionstal:
            
                    JoiObj.number()
                


.required()


        ,
        anbefaletMinPensionstal:
            
                    JoiObj.number()
                


.required()


        ,
        anbefalingstaerskelMellem:
            
                    JoiObj.number()
                


.required()


        ,
        anbefalingstaerskelNedre:
            
                    JoiObj.number()
                


.required()


        ,
        anbefalingstaerskelOevre:
            
                    JoiObj.number()
                


.required()


        ,
        boligInflation:
            
                    JoiObj.number()
                


.required()


        ,
        boligOmkostning:
            
                    JoiObj.number()
                


.required()


        ,
        boligSamfundsforudsaetning:
            
                    JoiObj.number()
                


.required()


        ,
        boligSkat:
            
                    JoiObj.number()
                


.required()


        ,
        copy:
            
        JoiObj.link('#PensionsplanSatserSchema')


.required()


        ,
        depotrenteAarligt:
            
                    JoiObj.number()
                


.required()


        ,
        faktorDepot:
            
                    JoiObj.number()
                


.required()


        ,
        forsikringsomkostningerMaanedligt:
            
                    JoiObj.number()
                


.required()


        ,
        inflationAarligt:
            
                    JoiObj.number()
                


.required()


        ,
        kkRenteAarligt:
            
                    JoiObj.number()
                


.required()


        ,
        kontanterInflation:
            
                    JoiObj.number()
                


.required()


        ,
        kontanterOmkostning:
            
                    JoiObj.number()
                


.required()


        ,
        kontanterSamfundsforudsaetning:
            
                    JoiObj.number()
                


.required()


        ,
        kontanterSkat:
            
                    JoiObj.number()
                


.required()


        ,
        korrigeretSkatTilAlderopsparing:
            
                    JoiObj.number()
                


.required()


        ,
        maanedligMinOmkostning:
            
                    JoiObj.number()
                


.required()


        ,
        maxIndbetalingTilForsikringMaanedligt:
            
                    JoiObj.number()
                


.required()


        ,
        merindbetalingMaxPct:
            
                    JoiObj.number()
                


.required()


        ,
        merindbetalingTaerskelFoersteIndbetalingDage:
            
                    JoiObj.number()
                


.required()


        ,
        merindbetalingTaerskelLoenPct:
            
                    JoiObj.number()
                


.required()


        ,
        obligationerInflation:
            
                    JoiObj.number()
                


.required()


        ,
        obligationerOmkostning:
            
                    JoiObj.number()
                


.required()


        ,
        obligationerSamfundsforudsaetning:
            
                    JoiObj.number()
                


.required()


        ,
        obligationerSkat:
            
                    JoiObj.number()
                


.required()


        ,
        omkostningerMaanedligt:
            
                    JoiObj.number()
                


.required()


        ,
        pal:
            
                    JoiObj.number()
                


.required()


        ,
        pensionsalderDefault:
            
                    JoiObj.number()
                


.required()


        ,
        pensionsalderMax:
            
                    JoiObj.number()
                


.required()


        ,
        pensionsalderMin:
            
                    JoiObj.number()
                


.required()


        ,
        proformaSkat:
            
                    JoiObj.number()
                


.required()


        ,
        rateAar:
            
                    JoiObj.number()
                


.required()


        ,
        rateRent:
            
                    JoiObj.number()
                


.required()


        
    })
.id('PensionsplanSatserSchema')

export interface PensionsplanSatser extends Sats { 
    aktierInflation: number;
    aktierOmkostning: number;
    aktierSamfundsforudsaetning: number;
    aktierSkat: number;
    amb: number;
    anbefaletMaxPensionstal: number;
    anbefaletMinPensionstal: number;
    anbefalingstaerskelMellem: number;
    anbefalingstaerskelNedre: number;
    anbefalingstaerskelOevre: number;
    boligInflation: number;
    boligOmkostning: number;
    boligSamfundsforudsaetning: number;
    boligSkat: number;
    copy: PensionsplanSatser;
    depotrenteAarligt: number;
    faktorDepot: number;
    forsikringsomkostningerMaanedligt: number;
    inflationAarligt: number;
    kkRenteAarligt: number;
    kontanterInflation: number;
    kontanterOmkostning: number;
    kontanterSamfundsforudsaetning: number;
    kontanterSkat: number;
    korrigeretSkatTilAlderopsparing: number;
    maanedligMinOmkostning: number;
    maxIndbetalingTilForsikringMaanedligt: number;
    merindbetalingMaxPct: number;
    merindbetalingTaerskelFoersteIndbetalingDage: number;
    merindbetalingTaerskelLoenPct: number;
    obligationerInflation: number;
    obligationerOmkostning: number;
    obligationerSamfundsforudsaetning: number;
    obligationerSkat: number;
    omkostningerMaanedligt: number;
    pal: number;
    pensionsalderDefault: number;
    pensionsalderMax: number;
    pensionsalderMin: number;
    proformaSkat: number;
    rateAar: number;
    rateRent: number;
}

