/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const DeviceTypeSchema = JoiObj.string().valid(
        'PC'
        ,
        'TABLET'
        ,
        'MOBIL'
        
    )
.id('DeviceTypeSchema')

export type DeviceType = 'PC' | 'TABLET' | 'MOBIL';

export const DeviceType = {
    Pc: 'PC' as DeviceType,
    Tablet: 'TABLET' as DeviceType,
    Mobil: 'MOBIL' as DeviceType
};

