/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { FolkepensionAlderInterval } from './folkepensionAlderInterval';
import { FolkepensionAlderIntervalSchema } from './folkepensionAlderInterval';
import { Sats } from './sats';
import { SatsSchema } from './sats';
import * as JoiObj from "joi";


    export const FolkepensionSatserSchema = JoiObj.object({
        activeNow:
            
                    JoiObj.boolean()
                


.required()


        ,
        name:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        updatedAt:
            
            JoiObj.date()


.required()


        ,
        updatedBy:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        uuid:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        validFrom:
            
            JoiObj.date()

.allow(null)



        ,
        validFromFormatted:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        year:
            
                    JoiObj.number()
                


.required()


        ,
        alderIntervalList:
                JoiObj.array().items(
        
        JoiObj.link('#FolkepensionAlderIntervalSchema')

        )
        

.required()


        ,
        fradragEnlig:
            
                    JoiObj.number()
                


.required()


        ,
        fradragGift:
            
                    JoiObj.number()
                


.required()


        ,
        fradragSamlevende:
            
                    JoiObj.number()
                


.required()


        ,
        grundbeloeb:
            
                    JoiObj.number()
                


.required()


        ,
        reduktionEnlig:
            
                    JoiObj.number()
                


.required()


        ,
        reduktionGift:
            
                    JoiObj.number()
                


.required()


        ,
        reduktionSamlevende:
            
                    JoiObj.number()
                


.required()


        ,
        tillaegEnlig:
            
                    JoiObj.number()
                


.required()


        ,
        tillaegGift:
            
                    JoiObj.number()
                


.required()


        ,
        tillaegSamlevende:
            
                    JoiObj.number()
                


.required()


        
    })
                .shared(FolkepensionAlderIntervalSchema)
.id('FolkepensionSatserSchema')

export interface FolkepensionSatser extends Sats { 
    alderIntervalList: Array<FolkepensionAlderInterval>;
    fradragEnlig: number;
    fradragGift: number;
    fradragSamlevende: number;
    grundbeloeb: number;
    reduktionEnlig: number;
    reduktionGift: number;
    reduktionSamlevende: number;
    tillaegEnlig: number;
    tillaegGift: number;
    tillaegSamlevende: number;
}

