import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Options } from 'ngx-slider-v2';
import { ContentUtilService } from '@pfa/core';
import { generateSliderStepsArray } from '@pfaform';

@Component({
  selector: 'mitpfa-pension-estimate-select',
  templateUrl: './pension-estimate-select.component.html'
})
export class PensionEstimateSelectComponent implements OnInit, OnChanges {
  @Input() validateMaxInput: boolean;
  @Input() label: string;
  @Input() min: number;
  @Input() max: number;
  @Input() step: number;
  @Input() value: number;
  @Input() decimal: number;
  @Input() disabled?: boolean = false;
  @Input() valueNoteBeforeTax: number;
  @Input() valueNoteAfterTax: number;
  @Input() unit: string;
  @Output() update = new EventEmitter<number>();

  public sliderOptions: Options;
  public pensionEstimateSelectForm: UntypedFormGroup;

  constructor(private contentUtilService: ContentUtilService) {}

  ngOnInit(): void {
    this.pensionEstimateSelectForm = new UntypedFormGroup({
      pensionEstimateSelectControl: new UntypedFormControl(this.value)
    });
    this.sliderOptions = this.createInputConfig();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes?.value &&
      this.pensionEstimateSelectForm?.controls?.pensionEstimateSelectControl
        ?.value !== changes.value.currentValue
    ) {
      this.pensionEstimateSelectForm?.controls?.pensionEstimateSelectControl.setValue(
        changes.value.currentValue
      );
    }
  }

  createInputConfig(): Options {
    return {
      stepsArray: [...generateSliderStepsArray(this.min, this.max, this.step)],
      ceil: this.max,
      floor: this.min,
      translate: (value: number): string =>
        value +
        (this.unit ? ' ' + this.contentUtilService.getContent(this.unit) : '')
    };
  }

  public sliderStop($event: number) {
    this.update.emit($event);
  }

  public inputUpdated($event: number) {
    this.update.emit($event);
  }
}
