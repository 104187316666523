import { inject, Injectable } from '@angular/core';
import {
  OnboardingGuideName,
  OnboardingLevel,
  OnboardingWelcomeTrackingName
} from './onboarding.model';
import {
  GuideExitAction,
  GuidePlacement,
  GuideStepOther,
  GuideStepType,
  GuideTrackingService
} from '@pfa/core';
import { CombineSavingsSummary } from '@mitpfa/shared/combine-savings/combine-savings.model';
import { ExternalTransferService } from './external-transfer/external-transfer.service';

@Injectable()
export class OnboardingTrackingService {
  private externalTransferService: ExternalTransferService = inject(
    ExternalTransferService
  );

  constructor(private readonly guideTrackingService: GuideTrackingService) {}

  public trackWelcome(isFlowResumed: boolean) {
    if (isFlowResumed) {
      this.guideTrackingService.trackFlowWelcome(
        OnboardingGuideName.ONBOARDING_GUIDE,
        OnboardingWelcomeTrackingName.RESUME,
        GuidePlacement.ONBOARDING
      );
    } else {
      this.guideTrackingService.trackFlowWelcome(
        OnboardingGuideName.ONBOARDING_GUIDE,
        OnboardingWelcomeTrackingName.WELCOME,
        GuidePlacement.ONBOARDING
      );
    }
  }

  public trackFlow(
    levelReached: OnboardingLevel,
    isFlowResumed: boolean,
    combineSavingsSummary: CombineSavingsSummary
  ) {
    if (levelReached.level === 'WELCOME') {
      this.trackFlowStartOrResume(levelReached, isFlowResumed);
    } else if (levelReached.level === 'CONTACT_DATA') {
      this.trackFlowStartOrResume(levelReached, false);
    } else {
      if (levelReached.level === 'EXTERNAL_TRANSFER_SUMMARY') {
        this.trackExternalTransfer(
          levelReached.trackingName,
          this.externalTransferService.findGuideCompleteOther(
            combineSavingsSummary
          )
        );
      } else if (levelReached.level === 'DONE') {
        this.trackFlowComplete(levelReached);
      } else {
        if (levelReached.level !== 'EXTERNAL_TRANSFER') {
          this.trackFlowStep(levelReached);
        }
      }
    }
  }

  public trackFlowStartOrResume(
    levelReached: OnboardingLevel,
    isFlowResumed: boolean
  ) {
    if (isFlowResumed) {
      this.guideTrackingService.trackGuideResume(
        levelReached.guideStepName,
        levelReached.trackingName,
        levelReached.guideStepType,
        GuidePlacement.ONBOARDING
      );
    } else {
      this.guideTrackingService.trackGuideStart(
        levelReached.guideStepName,
        levelReached.trackingName,
        levelReached.guideStepType,
        GuidePlacement.ONBOARDING
      );
    }
  }

  public trackFlowStep(levelReached: OnboardingLevel) {
    this.guideTrackingService.trackGuideStep(
      levelReached.guideStepName,
      levelReached.trackingName,
      levelReached.guideStepType,
      null,
      GuidePlacement.ONBOARDING
    );
  }

  public trackFlowComplete(levelReached: OnboardingLevel) {
    this.guideTrackingService.trackGuideComplete(
      levelReached.guideStepName,
      levelReached.trackingName,
      levelReached.guideStepType
    );
  }

  public trackFlowExit(
    levelReached: OnboardingLevel,
    guideExitAction: GuideExitAction
  ) {
    this.guideTrackingService.trackGuideWithPlacementExit(
      levelReached.guideStepName,
      levelReached.trackingName,
      levelReached.guideStepType,
      guideExitAction,
      GuidePlacement.ONBOARDING
    );
  }

  public trackExternalTransfer(
    guideStepName: string,
    guideStepOther?: GuideStepOther
  ) {
    this.guideTrackingService.trackGuideStep(
      OnboardingGuideName.REGISTRATION_GUIDE,
      guideStepName,
      GuideStepType.MANDATORY,
      guideStepOther,
      GuidePlacement.ONBOARDING
    );
  }
}
