/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const KillswitchDetailsSchema = JoiObj.object({
        id:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        open:
            
                    JoiObj.boolean()
                


.required()


        ,
        killswitchName:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        description:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        
    })
.id('KillswitchDetailsSchema')

export interface KillswitchDetails { 
    id: string;
    open: boolean;
    killswitchName: string;
    description: string;
}

