<form
  [formGroup]="form"
  (ngSubmit)="submitCustomerSurveyForm()"
  class="customer-survey-questions-container"
  #questions
>
  <p class="text--bold">
    <span [coContent]="'DL.TH01.C68'"></span>
  </p>
  <div class="select-row spacing-unit--double-over">
    <div class="select-label-cell">
      <span [coContent]="'DL.TH01.C69'"></span>
    </div>
    <div class="select-value-cell">
      <mat-form-field class="float--right" color="accent">
        <mat-label>
          <span [coContent]="'DL.TH01.C40'"></span>
        </mat-label>
        <mat-select [formControlName]="fields.navigation" [required]="true">
          <mat-option
            *ngFor="let select of selectValues"
            [value]="select.value"
          >
            {{ select.text }}
          </mat-option>
        </mat-select>
        <mat-error
          *ngIf="form.get(fields.navigation)?.errors?.required"
          [coContent]="'DL.TH01.C65'"
        ></mat-error>
      </mat-form-field>
    </div>
  </div>
  <div class="select-row spacing-unit--over" id="TH01-understandable">
    <div class="select-label-cell">
      <span [coContent]="'DL.TH01.C70'"></span>
    </div>
    <div class="select-value-cell">
      <mat-form-field class="float--right" color="accent">
        <mat-label>
          <span [coContent]="'DL.TH01.C40'"></span>
        </mat-label>
        <mat-select [formControlName]="fields.understandable" [required]="true">
          <mat-option
            *ngFor="let select of selectValues"
            [value]="select.value"
          >
            {{ select.text }}
          </mat-option>
        </mat-select>
        <mat-error
          *ngIf="form.get(fields.understandable)?.errors?.required"
          [coContent]="'DL.TH01.C65'"
        ></mat-error>
      </mat-form-field>
    </div>
  </div>
  <div class="select-row spacing-unit--over" id="TH01-design">
    <div class="select-label-cell">
      <span [coContent]="'DL.TH01.C71'"></span>
    </div>
    <div class="select-value-cell">
      <mat-form-field class="float--right" color="accent">
        <mat-label>
          <span [coContent]="'DL.TH01.C40'"></span>
        </mat-label>
        <mat-select [formControlName]="fields.design" [required]="true">
          <mat-option
            *ngFor="let select of selectValues"
            [value]="select.value"
          >
            {{ select.text }}
          </mat-option>
        </mat-select>
        <mat-error
          *ngIf="form.get(fields.design)?.errors?.required"
          [coContent]="'DL.TH01.C65'"
        ></mat-error>
      </mat-form-field>
    </div>
  </div>
  <div class="select-row spacing-unit--over" id="TH01-smoothness">
    <div class="select-label-cell">
      <span [coContent]="'DL.TH01.C72'"></span>
    </div>
    <div class="select-value-cell">
      <mat-form-field class="float--right" color="accent">
        <mat-label>
          <span [coContent]="'DL.TH01.C40'"></span>
        </mat-label>
        <mat-select [formControlName]="fields.smoothness" [required]="true">
          <mat-option
            *ngFor="let select of selectValues"
            [value]="select.value"
          >
            {{ select.text }}
          </mat-option>
        </mat-select>
        <mat-error
          *ngIf="form.get(fields.smoothness)?.errors?.required"
          [coContent]="'DL.TH01.C65'"
        ></mat-error>
      </mat-form-field>
    </div>
  </div>
  <div class="select-row spacing-unit--over" id="TH01-satisfactionLevel">
    <div class="select-label-cell">
      <span [coContent]="'DL.TH01.C73'"></span>
    </div>
    <div class="select-value-cell">
      <mat-form-field class="float--right" color="accent">
        <mat-label>
          <span [coContent]="'DL.TH01.C40'"></span>
        </mat-label>
        <mat-select
          [formControlName]="fields.satisfactionLevel"
          [required]="true"
        >
          <mat-option
            *ngFor="let select of selectValues"
            [value]="select.value"
          >
            {{ select.text }}
          </mat-option>
        </mat-select>
        <mat-error
          *ngIf="form.get(fields.satisfactionLevel)?.errors?.required"
          [coContent]="'DL.TH01.C65'"
        ></mat-error>
      </mat-form-field>
    </div>
  </div>

  <div class="section-container" id="TH01-purposeForVisitFulfilled">
    <p class="text--bold">
      <span [coContent]="'DL.TH01.C74'"></span>
    </p>
    <mat-radio-group [formControlName]="fields.purposeForVisitFulfilled">
      <mat-radio-button [value]="true">
        <span [coContent]="'DL.TH01.C21'"></span>
      </mat-radio-button>
      <mat-radio-button [value]="false">
        <span [coContent]="'DL.TH01.C76'"></span>
      </mat-radio-button>
    </mat-radio-group>
    <mat-error
      *ngIf="form.get(fields.purposeForVisitFulfilled)?.errors?.required"
      [coContent]="'DL.TH01.C65'"
    ></mat-error>
  </div>

  <div class="section-container" id="TH01-foundAllYouWanted">
    <p class="text--bold"><span [coContent]="'DL.TH01.C77'"></span></p>
    <mat-radio-group [formControlName]="fields.foundAllYouWanted">
      <mat-radio-button [value]="foundAllYouWanted.Yes">
        <span [coContent]="'DL.TH01.C78'"></span>
      </mat-radio-button>
      <mat-radio-button [value]="foundAllYouWanted.Partially">
        <span [coContent]="'DL.TH01.C79'"></span>
      </mat-radio-button>
      <mat-radio-button [value]="foundAllYouWanted.No">
        <span [coContent]="'DL.TH01.C80'"></span>
      </mat-radio-button>
      <mat-radio-button [value]="foundAllYouWanted.DidntSearchAnythingSpecific">
        <span [coContent]="'DL.TH01.C81'"></span>
      </mat-radio-button>
    </mat-radio-group>
    <mat-error
      *ngIf="form.get(fields.foundAllYouWanted)?.errors?.required"
      [coContent]="'DL.TH01.C65'"
    ></mat-error>
  </div>

  <div
    id="TH01-missingInfo"
    class="section-container section-container--related-question"
    *ngIf="
      form.get(fields.foundAllYouWanted)?.value
        | customerSurveyMissingInfoVisibility
    "
  >
    <p class="text--bold">
      <span [coContent]="'DL.TH01.C82'"></span>
    </p>
    <mat-form-field color="accent">
      <mat-label><span [coContent]="'DL.TH01.C83'"></span></mat-label>
      <textarea
        matInput
        [formControlName]="fields.missingInfo"
        maxlength="2000"
      >
      </textarea>
      <mat-hint align="end"
        >{{ form.get(fields.missingInfo)?.value?.length || 0 }}/2000</mat-hint
      >
    </mat-form-field>
  </div>

  <div id="TH01-suggestionForImprovement" class="section-container">
    <p class="text--bold">
      <span [coContent]="'DL.TH01.C84'"></span>
    </p>
    <mat-form-field color="accent">
      <mat-label><span [coContent]="'DL.TH01.C83'"></span></mat-label>
      <textarea
        matInput
        [formControlName]="fields.suggestionForImprovement"
        maxlength="2000"
      >
      </textarea>
      <mat-hint align="end"
        >{{
          form.get(fields.suggestionForImprovement)?.value?.length || 0
        }}/2000</mat-hint
      >
    </mat-form-field>
  </div>

  <div class="align--center submit-container">
    <button mat-raised-button type="submit" [disabled]="form.invalid">
      <span [coContent]="'DL.TH01.C85'"></span>
    </button>
  </div>
</form>
