<form novalidate [formGroup]="form">
  <co-card-split-horizontal
    [simple]="true"
    [middle]="!!inputNotification"
    class="header-fill-all body-fill-all"
  >
    <div header class="top-section">
      <div class="first">
        <div class="simple-coverage">
          <div class="simple-coverage-top">
            <p class="text--bold" coContent="DL.TF01.C179"></p>
            <div [ngClass]="{ today: inputConfig.self.unit === '%' }">
              <co-primary-number
                [amount]="
                  inputConfig.self.amountOriginal / 100 | numberFormat: 2
                "
                [unit]="unitWithAmountOriginal"
              ></co-primary-number>
              <div>
                <div
                  [coContent]="inputConfig.self.noteBeforeTaxOriginal"
                  *ngIf="inputConfig.self.noteBeforeTaxOriginal"
                  class="text--note"
                ></div>
                <div
                  [coContent]="inputConfig.self.noteAfterTaxOriginal"
                  *ngIf="inputConfig.self.noteAfterTaxOriginal"
                  class="text--note"
                ></div>
                <div
                  [coContent]="inputConfig.self.notePriceOriginal"
                  *ngIf="inputConfig.self.notePriceOriginal"
                  class="text--bold"
                ></div>
              </div>
            </div>
          </div>

          <!--confirm choice -->
          <co-confirm-choice
            *ngIf="!hideActions"
            [simple]="true"
            [confirmedDate]="confirmChoice?.life.confirmedDate"
            [error]="confirmChoice.life.error"
            (confirmed)="onConfirmChoice()"
            [success]="confirmChoice?.life.success"
            [texts]="confirmChoiceComponentTexts"
          >
          </co-confirm-choice>
        </div>
      </div>
      <div>
        <p coContent="DL.TF01.C191" class="text--bold color--secondary"></p>
        <div class="simple-coverage">
          <div class="simple-coverage-top">
            <div
              *ngIf="
                insuranceGuideChecks.guideTooOld || insuranceGuideChecks.noGuide
              "
            >
              <co-icon-with-text>
                <co-icon-doubt icon></co-icon-doubt>
                <div body coContent="DL.FG01.C1466"></div>
              </co-icon-with-text>
            </div>
            <ng-container
              *ngIf="
                !insuranceGuideChecks.guideTooOld &&
                !insuranceGuideChecks.noGuide
              "
            >
              <div
                *ngIf="
                  overrideRecommendationKeepCurrent ||
                  (inputConfig.self.amountOriginal / 100 | numberFormat: 2) ===
                    (inputConfig.recommendation / 100 | numberFormat: 2)
                "
                coContent="DL.TF01.C197"
                [coContentSubst]="
                  (inputConfig.self.amountOriginal / 100 | numberFormat: 2) +
                  '|' +
                  unitWithAmount
                "
              ></div>
              <div
                *ngIf="
                  !overrideRecommendationKeepCurrent &&
                  (inputConfig.self.amountOriginal / 100 | numberFormat: 2) !==
                    (inputConfig.recommendation / 100 | numberFormat: 2)
                "
                coContent="DL.TF01.C198"
                [coContentSubst]="
                  (inputConfig.recommendation / 100 | numberFormat: 2) +
                  '|' +
                  unitWithRecommendation
                "
              ></div>
            </ng-container>
          </div>

          <div *ngIf="!pricesUpdated">
            <button
              *ngIf="!hideActions && inputConfig.showRecommendationButton"
              mat-raised-button
              class="button-secondary"
              coContent="DL.TF01.C153"
              (click)="
                setRecommendation(inputConfig.self.type, form.get('self'))
              "
            ></button>
          </div>
          <div *ngIf="pricesUpdated && !inputConfig.self.updateFailed">
            <co-alert class="spacing-unit--over">
              <span
                coContent="DL.TF01.C211"
                *ngIf="!inputConfig.followsRecommendation"
              ></span>
              <span
                coContent="DL.TF01.C210"
                *ngIf="inputConfig.followsRecommendation"
              ></span>
            </co-alert>
          </div>
        </div>
      </div>
      <div>
        <ng-container
          *ngIf="
            !insuranceGuideChecks.guideTooOld && !insuranceGuideChecks.noGuide
          "
        >
          <div class="spacing-unit--half-under">
            <span
              coContent="DL.TF01.C183"
              class="text--bold color--primary"
            ></span>
            <co-tooltip
              *ngIf="inputConfig.self.tooltipContentId"
              [text]="inputConfig.self.tooltipContentId"
            ></co-tooltip>
          </div>
          <div class="new-coverage">
            <div class="new-coverage-top">
              <mat-form-field color="accent">
                <mat-label
                  [coContent]="inputConfig.self.labelContentId"
                ></mat-label>
                <input
                  class="amount-input"
                  matInput
                  coAmount
                  formControlName="self"
                  [coAmountUnit]="inputConfig.self.unit | content"
                  (blur)="
                    checkForEmpty(form.get('self'), inputConfig.self.amountMin)
                  "
                />
                <mat-hint
                  align="end"
                  [coContent]="inputConfig.self.noteContentId"
                  [coContentSubst]="
                    (inputConfig.self.amountMin | numberFormat) +
                    '|' +
                    (inputConfig.self.amountMax | numberFormat) +
                    '|' +
                    (inputConfig.self.amount2Max | numberFormat)
                  "
                  *ngIf="inputConfig.self.noteContentId"
                  class="note"
                ></mat-hint>
                <mat-error
                  *ngIf="form.get('self').errors?.numberFormat"
                  [coContent]="'DL.MD01.C13'"
                  align="end"
                ></mat-error>
                <mat-error
                  *ngIf="
                    !form.get('self').errors?.numberFormat &&
                    form.get('self').errors?.min
                  "
                  [coContent]="'DL.MD01.C21a'"
                  [coContentSubst]="
                    (inputConfig.self.amountMin | numberFormat) +
                    '|' +
                    (inputConfig.self.unit | content)
                  "
                  align="end"
                ></mat-error>
                <mat-error
                  *ngIf="
                    !form.get('self').errors?.numberFormat &&
                    form.get('self').errors?.max
                  "
                  [coContent]="'DL.MD01.C19a'"
                  [coContentSubst]="
                    (inputConfig.self.amountMax | numberFormat) +
                    '|' +
                    (inputConfig.self.unit | content)
                  "
                  align="end"
                ></mat-error>
              </mat-form-field>

              <div *ngIf="pricesUpdated">
                <div *ngIf="inputConfig.self.noteBeforeTax" class="text--note">
                  {{ inputConfig.self.noteBeforeTax }}
                </div>
                <div *ngIf="inputConfig.self.noteAfterTax" class="text--note">
                  {{ inputConfig.self.noteAfterTax }}
                </div>
                <div *ngIf="inputConfig.self.notePrice" class="text--bold">
                  {{ inputConfig.self.notePrice }}
                </div>
              </div>
            </div>
            <div class="new-coverage-bottom">
              <button
                mat-raised-button
                [disabled]="isDisabled('self')"
                (click)="update(inputConfig.self.type, form.get('self'))"
                coContent="DL.TF01.C188"
              ></button>
              <co-info *ngIf="lifeUpdateNote" class="align--left">
                <div [coContent]="lifeUpdateNote" class="text--note"></div>
              </co-info>
              <co-warning *ngIf="inputConfig.self.updateFailed">
                <span [coContent]="'DL.TF01.C37'"></span>
              </co-warning>
            </div>
          </div>
        </ng-container>
      </div>
    </div>

    <div middle class="middle-section">
      <co-info *ngIf="inputNotification">
        <span
          [coContent]="inputNotification.contentId"
          [coContentSubst]="inputNotification.amount | numberFormat"
        ></span>
      </co-info>
    </div>

    <div body>
      <co-accordion
        [title]="'DL.FG02.C324'"
        class="accordion--transparent accordion--flat stacked accordion-normal-padding"
      >
        <co-loading [show]="!initialized"></co-loading>
        <co-life-insurance-table
          *ngIf="initialized"
          [pensionPlan]="pensionPlanPolice"
        >
        </co-life-insurance-table>
      </co-accordion>
      <co-accordion
        [title]="'DL.TF01.C201'"
        class="accordion--transparent accordion--flat stacked accordion-normal-padding"
        *ngIf="inputConfig.child"
      >
        <co-loading [show]="!initialized"></co-loading>
        <div class="row" *ngIf="inputConfig.child">
          <div class="col-12 col-lg-4 col-xl-6">
            <span
              class="color--black text--bold"
              [coContent]="inputConfig.child.textContentId"
            ></span>
            <co-tooltip
              *ngIf="inputConfig.child.tooltipContentId"
              [text]="inputConfig.child.tooltipContentId"
            ></co-tooltip>
            <div
              [coContent]="inputConfig.child.noteContentId"
              [coContentSubst]="
                (inputConfig.child.amountMin
                  | numberFormat: inputConfig.child.decimals) +
                '|' +
                (inputConfig.child.amountMax
                  | numberFormat: inputConfig.child.decimals)
              "
            ></div>
            <co-info
              *ngIf="inputConfig.child.recommendCreate"
              class="spacing-unit--half-over"
            >
              <span coContent="DL.TF01.C143"></span>
            </co-info>
            <co-info
              *ngIf="inputConfig.child.recommendRemove"
              class="spacing-unit--half-over"
            >
              <span
                [coContent]="
                  inputConfig.child.amountMin > 0
                    ? 'DL.TF01.C146'
                    : 'DL.TF01.C144'
                "
              ></span>
            </co-info>
            <co-warning *ngIf="inputConfig.child.updateFailed">
              <span [coContent]="'DL.TF01.C37'"></span>
            </co-warning>
          </div>
          <div class="col-12 col-lg-8 col-xl-6 column-2">
            <button
              mat-raised-button
              class="button-primary--level3 update-button"
              [style.visibility]="isDisabled('child') ? 'hidden' : 'visible'"
              [disabled]="isDisabled('child')"
              (click)="update(inputConfig.child.type, form.get('child'))"
            >
              <span [coContent]="'Global.Opdater'"></span>
              <co-icon-refresh class="icon"></co-icon-refresh>
            </button>

            <mat-form-field color="accent">
              <mat-label
                [coContent]="inputConfig.child.labelContentId"
              ></mat-label>
              <input
                class="amount-input"
                matInput
                coAmount
                formControlName="child"
                [coAmountDecimals]="inputConfig.child.decimals"
                [coAmountUnit]="inputConfig.child.unit | content"
                (blur)="
                  checkForEmpty(form.get('child'), inputConfig.child.amountMin)
                "
              />
              <mat-hint align="end">
                <div *ngIf="inputConfig.child.noteBeforeTax" class="note">
                  {{ inputConfig.child.noteBeforeTax }}
                </div>
                <div *ngIf="inputConfig.child.noteAfterTax" class="note">
                  {{ inputConfig.child.noteAfterTax }}
                </div>
                <div *ngIf="inputConfig.child.notePrice" class="note">
                  {{ inputConfig.child.notePrice }}
                </div>
              </mat-hint>
              <mat-error
                *ngIf="form.get('child').errors?.numberFormat"
                [coContent]="'DL.MD01.C13'"
                align="end"
              ></mat-error>
              <mat-error
                *ngIf="
                  !form.get('child').errors?.numberFormat &&
                  form.get('child').errors?.min
                "
                [coContent]="'DL.MD01.C21a'"
                [coContentSubst]="
                  (inputConfig.child.amountMin | numberFormat) +
                  '|' +
                  (inputConfig.child.unit | content)
                "
                align="end"
              ></mat-error>
              <mat-error
                *ngIf="
                  !form.get('child').errors?.numberFormat &&
                  form.get('child').errors?.max
                "
                [coContent]="'DL.MD01.C19a'"
                [coContentSubst]="
                  (inputConfig.child.amountMax | numberFormat) +
                  '|' +
                  (inputConfig.child.unit | content)
                "
                align="end"
              ></mat-error>
            </mat-form-field>
          </div>
        </div>
      </co-accordion>
      <co-accordion
        [title]="'DL.TF01.C202'"
        *ngIf="inputConfig.showSpousePension"
        class="accordion--transparent accordion--flat stacked accordion-normal-padding"
      >
        <co-loading [show]="!initialized"></co-loading>
        <div class="row">
          <div
            class="col-12"
            *ngIf="!inputConfig.canChangeSpousePension"
            coContent="DL.TF01.C154"
          ></div>
          <ng-container *ngIf="inputConfig.canChangeSpousePension">
            <div class="col-12 col-lg-6">
              <span class="color--black text--bold" coContent="DL.TF01.C155"
                >ægtefælle P</span
              >
              <co-tooltip [text]="'DL.TF01.I155'"></co-tooltip>

              <div class="spouse-toggle-block">
                <mat-slide-toggle
                  [checked]="spousePensionSelected"
                  (change)="toggleSpousePension($event, form.get('spouseCpr'))"
                >
                  <span
                    [coContent]="
                      inputConfig.spousePension.ophoerendeMuligt
                        ? 'DL.TF01.C157'
                        : 'DL.TF01.C158'
                    "
                  ></span>
                  <co-tooltip
                    *ngIf="inputConfig.spousePension.ophoerendeMuligt"
                    [text]="'DL.TF01.I158'"
                  ></co-tooltip>
                </mat-slide-toggle>
              </div>
            </div>
            <div
              class="col-12 col-lg-6 spouse-info"
              *ngIf="spousePensionRemoved && !spousePensionSelected"
            >
              <span coContent="DL.TF01.C156"></span>
              <co-tooltip [text]="'DL.TF01.I156'"></co-tooltip>
            </div>
            <div
              class="col-12 col-lg-6 spouse-info"
              *ngIf="spousePensionSelected"
              coContent="DL.TF01.C159"
              [coContentSubst]="
                (inputConfig.spousePension.pris | numberFormat) +
                '|' +
                (inputConfig.spousePension.prisIProcent | numberFormat: 1)
              "
            ></div>
          </ng-container>
        </div>

        <ng-container *ngIf="inputConfig.canChangeSpousePension">
          <div
            *ngIf="
              !spousePensionSelected && inputConfig.spousePensionUpdateError
            "
          >
            <co-warning class="spacing-unit--under">
              <div [coContent]="'DL.TF01.C160'"></div>
            </co-warning>
          </div>

          <div
            class="spouse-edit spacing-unit--over"
            *ngIf="spousePensionSelected"
          >
            <div class="row">
              <div class="col-12 col-lg-4 col-xl-6">
                <span
                  class="color--black text--bold"
                  coContent="DL.TF01.C161"
                ></span>
                <p
                  class="text--note"
                  *ngIf="
                    form.get('spouseCpr').errors?.required ||
                    form.get('spouseCpr').errors?.cpr?.invalidOnlyDigits ||
                    form.get('spouseCpr').errors?.cpr?.invalidLength
                  "
                  coContent="DL.TF01.C162"
                ></p>
                <co-warning
                  *ngIf="inputConfig.spousePensionUpdateError"
                  class="spacing-unit--under"
                >
                  <div [coContent]="'DL.TF01.C160'"></div>
                </co-warning>
                <co-info *ngIf="inputConfig.spousePension?.cprnummerFiktivt">
                  <div
                    class="icon-text"
                    body
                    [coContent]="'DL.TF01.C163'"
                  ></div>
                </co-info>
                <div *ngIf="spousePensionAdded">
                  <span coContent="DL.TF01.C164"></span>
                  <co-tooltip [text]="'DL.TF01.I164'"></co-tooltip>
                </div>
              </div>
              <div class="col-12 col-lg-8 col-xl-6 column-2">
                <button
                  mat-raised-button
                  class="button-primary--level3 update-button"
                  [style.visibility]="
                    isDisabledSpouse('spouseCpr') ? 'hidden' : 'visible'
                  "
                  [disabled]="isDisabledSpouse('spouseCpr')"
                  (click)="updateSpouseCpr(form.get('spouseCpr'))"
                >
                  <span [coContent]="'Global.Gem'"></span>
                  <co-icon-refresh class="icon"></co-icon-refresh>
                </button>

                <mat-form-field color="accent">
                  <mat-label [coContent]="'DL.TF01.C165'"></mat-label>
                  <input
                    class="amount-input"
                    matInput
                    formControlName="spouseCpr"
                    maxlength="10"
                  />
                  <mat-error
                    *ngIf="form.get('spouseCpr').errors?.required"
                    [coContent]="'DL.MD01.C12'"
                    align="end"
                  ></mat-error>
                  <mat-error
                    *ngIf="
                      !form.get('spouseCpr').errors?.required &&
                      form.get('spouseCpr').errors?.cpr?.invalidOnlyDigits
                    "
                    [coContent]="'DL.MD01.C120'"
                    align="end"
                  ></mat-error>
                  <mat-error
                    *ngIf="
                      !form.get('spouseCpr').errors?.required &&
                      !form.get('spouseCpr').errors?.cpr?.invalidOnlyDigits &&
                      form.get('spouseCpr').errors?.cpr?.invalidLength
                    "
                    [coContent]="'DL.TF01.C166'"
                    align="end"
                  ></mat-error>
                  <mat-error
                    *ngIf="
                      !form.get('spouseCpr').errors?.required &&
                      !form.get('spouseCpr').errors?.cpr?.invalidOnlyDigits &&
                      !form.get('spouseCpr').errors?.cpr?.invalidLength &&
                      form.get('spouseCpr').errors?.cpr
                    "
                    [coContent]="'DL.MD01.C286'"
                    align="end"
                  ></mat-error>
                </mat-form-field>
              </div>
            </div>

            <div class="row spouse-amount-row" *ngIf="showSpousePensionExtra">
              <div class="col-12 col-lg-4 col-xl-6">
                <span
                  class="color--black text--bold"
                  coContent="DL.TF01.C167"
                ></span>
                <co-tooltip [text]="'DL.TF01.I167'"></co-tooltip>
                <p
                  [coContent]="
                    spouseExtraIsAmount ? 'DL.TF01.C168' : 'DL.TF01.C169'
                  "
                  [coContentSubst]="
                    (inputConfig.spousePension.yderligereMin
                      | numberFormat: (spouseExtraIsAmount ? 0 : 1)) +
                    '|' +
                    (inputConfig.spousePension.yderligereMax
                      | numberFormat: (spouseExtraIsAmount ? 0 : 1))
                  "
                ></p>
                <co-warning
                  *ngIf="inputConfig.spousePensionUpdateExtraError"
                  class="spacing-unit--under"
                >
                  <div
                    class="icon-text"
                    body
                    [coContent]="'DL.TF01.C160'"
                  ></div>
                </co-warning>
              </div>
              <div class="col-12 col-lg-8 col-xl-6 column-2">
                <button
                  mat-raised-button
                  class="button-primary--level3 update-button"
                  [style.visibility]="
                    isDisabledSpouse('spouseExtraAmount') ? 'hidden' : 'visible'
                  "
                  [disabled]="isDisabledSpouse('spouseExtraAmount')"
                  (click)="
                    updateSpouseExtraAmount(form.get('spouseExtraAmount'))
                  "
                >
                  <span [coContent]="'Global.Gem'"></span>
                  <co-icon-refresh class="icon"></co-icon-refresh>
                </button>

                <mat-form-field color="accent">
                  <mat-label [coContent]="'DL.TF01.C170'"></mat-label>
                  <input
                    class="amount-input"
                    matInput
                    coAmount
                    coAmountDecimals="{{ spouseExtraIsAmount ? 0 : 1 }}"
                    coAmountUnit="{{ spouseExtraIsAmount ? 'kr.' : '%' }}"
                    formControlName="spouseExtraAmount"
                    maxlength="10"
                  />
                  <mat-hint align="end">
                    <div
                      class="note"
                      [coContent]="'DL.TF01.C171'"
                      [coContentSubst]="
                        inputConfig.spousePension.yderligerePrisKroner
                          | numberFormat
                      "
                    ></div>
                    <div
                      class="note spacing-unit--under"
                      [coContent]="'DL.TF01.C32'"
                      [coContentSubst]="
                        inputConfig.spousePension.yderligereAarligPris / 12
                          | numberFormat
                      "
                    ></div>
                  </mat-hint>
                  <mat-error
                    *ngIf="form.get('spouseExtraAmount').errors?.required"
                    [coContent]="'DL.MD01.C12'"
                    align="end"
                  ></mat-error>
                  <mat-error
                    *ngIf="
                      !form.get('spouseExtraAmount').errors?.required &&
                      form.get('spouseExtraAmount').errors?.numberFormat
                    "
                    [coContent]="'DL.MD01.C13'"
                    align="end"
                  ></mat-error>
                  <mat-error
                    *ngIf="
                      !form.get('spouseExtraAmount').errors?.required &&
                      !form.get('spouseExtraAmount').errors?.numberFormat &&
                      form.get('spouseExtraAmount').errors?.min
                    "
                    [coContent]="'DL.MD01.C21a'"
                    [coContentSubst]="
                      (inputConfig.spousePension.yderligereMin | numberFormat) +
                      '|' +
                      (spouseExtraIsAmount ? ('Global.kroner' | content) : '%')
                    "
                    align="end"
                  ></mat-error>
                  <mat-error
                    *ngIf="
                      !form.get('spouseExtraAmount').errors?.required &&
                      !form.get('spouseExtraAmount').errors?.numberFormat &&
                      form.get('spouseExtraAmount').errors?.max
                    "
                    [coContent]="'DL.MD01.C19a'"
                    [coContentSubst]="
                      (inputConfig.spousePension.yderligereMax | numberFormat) +
                      '|' +
                      (spouseExtraIsAmount ? ('Global.kroner' | content) : '%')
                    "
                    align="end"
                  ></mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
        </ng-container>
      </co-accordion>
      <co-accordion
        [title]="'DL.TF01.C98'"
        class="accordion--transparent accordion--flat stacked accordion-normal-padding"
      >
        <p [coContent]="'DL.TF01.C99'"></p>
        <p [coContent]="'DL.TF01.C100'"></p>
        <div *ngIf="showAuaConditions">
          <div [coContent]="'DL.TF01.C113'" class="text--bold"></div>
          <p [coContent]="'DL.TF01.C111'"></p>
          <ul class="bullet-list bullet-list-with-tooltip">
            <li>
              <span [coContent]="'DL.TF01.C101'"></span>
              <co-tooltip [text]="'DL.TF01.C102'"></co-tooltip>
            </li>
            <li>
              <span [coContent]="'DL.TF01.C103'"></span>
              <co-tooltip [text]="'DL.TF01.C110'"></co-tooltip>
            </li>
            <li>
              <span [coContent]="'DL.TF01.C104'"></span>
              <co-tooltip [text]="'DL.TF01.C105'"></co-tooltip>
            </li>
            <li>
              <span [coContent]="'DL.TF01.C106'"></span>
              <span class="dummy-icon--vertical-only"></span>
            </li>
            <li>
              <span [coContent]="'DL.TF01.C107'"></span>
              <span class="dummy-icon--vertical-only"></span>
            </li>
            <li>
              <span [coContent]="'DL.TF01.C108'"></span>
              <span class="dummy-icon--vertical-only"></span>
            </li>
            <li>
              <span [coContent]="'DL.TF01.C109'"></span>
              <span class="dummy-icon--vertical-only"></span>
            </li>
          </ul>
        </div>
      </co-accordion>
    </div>
  </co-card-split-horizontal>
</form>
