<section class="header">
  <div class="header-recommendation">
    <header class="text--bold" coContent="DL.FG01.C1926"></header>
  </div>
  <div class="text--manchet" [coContent]="title"></div>
</section>

<section class="synopsis">
  <div class="left">
    <div class="main-section">
      <div class="type-header" coContent="DL.TF01.C207"></div>
      <div class="right-subheader">
        <ng-template *ngTemplateOutlet="currentValue"></ng-template>
      </div>
      <div class="right-description">
        <ng-template *ngTemplateOutlet="currentDescription"></ng-template>
      </div>
    </div>

    <co-confirm-choice
      *ngIf="!hideActions"
      [simple]="true"
      [confirmedDate]="confirmChoiceData?.confirmedDate"
      [error]="confirmChoiceData.error"
      (confirmed)="onConfirmChoice()"
      [success]="confirmChoiceData.success"
      [texts]="confirmChoiceComponentTexts"
    >
    </co-confirm-choice>
  </div>

  <div class="right">
    <div class="main-section">
      <div
        class="type-header type-header-recommendation"
        coContent="DL.TF01.C208"
      ></div>

      <div *ngIf="hasAdvisorRecommendation" class="header-advisor-recommend">
        <div class="header-advisor-heading">
          <header
            class="right-header text--bold color--primary"
            coContent="DL.TF01.C115"
          ></header>
          <co-tooltip [text]="'DL.TF01.C117'"></co-tooltip>
        </div>
        <div class="right-subheader">
          <ng-template *ngTemplateOutlet="advisorValue"></ng-template>
        </div>
        <div class="right-description">
          <ng-template *ngTemplateOutlet="advisorDescription"></ng-template>
        </div>
      </div>

      <div class="header-recommend">
        <header
          *ngIf="hasAdvisorRecommendation"
          class="right-header text--bold"
          [coContent]="recommendationHeader"
        ></header>
        <div class="right-subheader">
          <ng-template *ngTemplateOutlet="recommendedValue"></ng-template>
        </div>
        <div class="right-description">
          <ng-template *ngTemplateOutlet="recommendedDescription"></ng-template>
        </div>
      </div>

      <ng-template
        *ngTemplateOutlet="supplementaryRecommendation"
      ></ng-template>
    </div>

    <co-alert *ngIf="showNoCoverageWarning" class="alert-info header-recommend">
      <span coContent="DL.FG01.C1928"></span>
    </co-alert>

    <div
      *ngIf="!hideActions && showFollowOurRecommendationButton"
      class="align--center"
    >
      <button
        mat-raised-button
        (click)="followOurRecommendation()"
        coContent="DL.TF01.C153"
      ></button>
    </div>
  </div>
</section>

<section class="synopsis cta" *ngIf="!hideActions">
  <button
    mat-raised-button
    (click)="adjustInsurance()"
    class="button-primary--level3"
  >
    <co-icon-slider class="icon"></co-icon-slider>
    <span coContent="DL.TF01.C209"></span>
  </button>
</section>

<co-accordion
  [title]="'DL.FG01.C1897'"
  [initExpanded]="false"
  [accordionName]="accordionTrackingName"
  class="overview accordion--contrast accordion--light-grey accordion--mobile-space-xl read-more"
>
  <section>
    <header class="overview-header">
      <div class="read-more-container">
        <div class="icon-readmore">
          <co-icon-eventlog></co-icon-eventlog>
        </div>
        <div>
          <span class="text--bold list-header" coContent="DL.FG01.C1843"></span>
          <ng-content select="[readMoreList]"></ng-content>
        </div>
      </div>
      <div
        *ngIf="recommendationHeaderTooltip"
        [coContent]="recommendationHeaderTooltip"
        class="spacing-unit--over"
      ></div>
    </header>
  </section>
</co-accordion>

<ng-template #advisorValue>
  <ng-content select="[advisorValue]"></ng-content>
</ng-template>

<ng-template #recommendedValue>
  <ng-content select="[recommendedValue]"></ng-content>
</ng-template>

<ng-template #currentValue>
  <ng-content select="[currentValue]"></ng-content>
</ng-template>

<ng-template #advisorDescription>
  <ng-content select="[advisorDescription]"></ng-content>
</ng-template>

<ng-template #recommendedDescription>
  <ng-content select="[recommendedDescription]"></ng-content>
</ng-template>

<ng-template #supplementaryRecommendation>
  <ng-content select="[supplementaryRecommendation]"></ng-content>
</ng-template>

<ng-template #currentDescription>
  <ng-content select="[currentDescription]"></ng-content>
</ng-template>
