import {
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {
  BestiltAendringer,
  CustomerProfile,
  CustomerprofileStore,
  InvesteringDetaljerSvar,
  MaeglerInfo,
  OpsparetVaerdi,
  PensionsKundeGenerelleData,
  PoliceMulighederSvar
} from '@pfa/gen';
import { InvestmentGuideInfo } from '@pfa/models';
import {
  BasketApiService,
  BusinessDomain,
  InvestmentApiService,
  InvestmentGuideApiService,
  PensionPlanApiService
} from '@pfa/api';
import { concatMap, finalize, take } from 'rxjs/operators';
import { combineLatest, of } from 'rxjs';
import { GlobalWorkingService, PolicyType } from '@pfaform';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'mitpfa-onboarding-investment',
  templateUrl: './investment.component.html',
  styleUrls: ['./investment.component.scss']
})
export class InvestmentComponent implements OnInit {
  @Input() pensionCustomer: PensionsKundeGenerelleData;
  @Output() percentCompleted = new EventEmitter<number>();
  @Output() goTo = new EventEmitter<void>();

  private readonly investmentGuideService: InvestmentGuideApiService = inject(
    InvestmentGuideApiService
  );
  private readonly pensionPlanApiService: PensionPlanApiService = inject(
    PensionPlanApiService
  );
  private readonly globalWorkingService: GlobalWorkingService =
    inject(GlobalWorkingService);
  private readonly customerprofileStore: CustomerprofileStore =
    inject(CustomerprofileStore);
  private readonly investmentApiService: InvestmentApiService =
    inject(InvestmentApiService);
  private readonly basketApiService: BasketApiService =
    inject(BasketApiService);
  private readonly destroyRef: DestroyRef = inject(DestroyRef);

  public investmentGuide: InvestmentGuideInfo;
  public policy: PoliceMulighederSvar;
  public broker: MaeglerInfo;
  public pensionInfoSavedValues: OpsparetVaerdi;
  public isInitialized = false;
  public error = false;
  public isRecommendation = false;
  public basket: BestiltAendringer;
  public onlyInsurance: boolean;

  public ngOnInit(): void {
    this.customerprofileStore
      .customerprofileGet()
      .pipe(
        concatMap(customerProfile =>
          combineLatest([
            this.investmentGuideService.get(
              customerProfile.personDetail.globalId
            ),
            this.pensionPlanApiService.getPensionInfoSavedValues()
          ]).pipe(take(1))
        ),
        takeUntilDestroyed(this.destroyRef),
        finalize(() => {
          this.globalWorkingService.hide();
        })
      )
      .subscribe({
        next: response => {
          this.investmentGuide = response[0];
          this.pensionInfoSavedValues = response[1];
          this.isInitialized = true;
        },
        error: () => {
          this.error = true;
        }
      });
  }

  private getRecommendation(): void {
    this.investmentApiService
      .get()
      .pipe(
        concatMap((response: InvesteringDetaljerSvar) => {
          this.onlyInsurance = this.hasOnlyInsurance(response);
          return combineLatest({
            customerProfile: this.customerprofileStore.customerprofileGet(),
            investment: of(response)
          }).pipe(take(1));
        }),
        concatMap(
          (response: {
            customerProfile: CustomerProfile;
            investment: InvesteringDetaljerSvar;
          }) =>
            combineLatest([
              this.investmentGuideService.get(
                response.customerProfile.personDetail.globalId
              ),
              this.investmentApiService.getPolicy(
                response.investment.detaljer.primaerPolice
              ),
              this.basketApiService.get(
                BusinessDomain.Investment,
                response.investment.detaljer.primaerPolice
              )
            ]).pipe(take(1))
        ),
        takeUntilDestroyed(this.destroyRef),
        finalize(() => {
          this.globalWorkingService.hide();
        })
      )
      .subscribe({
        next: response => {
          this.investmentGuide = response[0];
          this.policy = response[1];
          this.basket = response[2];
          this.isRecommendation = true;
        },
        error: () => {
          this.error = true;
        }
      });
  }

  private hasOnlyInsurance(investments: InvesteringDetaljerSvar) {
    return (
      investments.detaljer.policer.length === 1 &&
      investments.detaljer.policer[0].type === PolicyType.Insurance
    );
  }

  public done(): void {
    this.getRecommendation();
  }

  public percentDone(percent: number): void {
    this.percentCompleted.emit(percent);
  }

  public investmentDone(): void {
    this.goTo.emit();
  }
}
