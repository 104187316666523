import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'co-table-accordion-control',
  templateUrl: './table-accordion-control.component.html',
  styleUrls: ['./table-accordion-control.component.scss']
})
export class TableAccordionControlComponent {
  @Input() show = true;
  @Input() expandedFromStart: boolean;
  @Input() contentId: string;
  @Input() contentIdSubst: string | number | boolean;
  @Input() toolTip: string;
  @Output() expandedChange = new EventEmitter<boolean>();

  expanded: boolean;

  toggleExpanded(): void {
    this.expanded = !this.expanded;
    this.expandedChange.emit(this.expanded);
  }
}
