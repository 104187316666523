import { Component, inject, Input } from '@angular/core';
import { RecommendationDetail } from '../../investment-recommendation.model';
import { MatDialog } from '@angular/material/dialog';
import { InvestmentReadMoreComponent } from '@mitpfa/shared/investment/read-more/read-more.component';
import { SideBarStyle } from '@pfa/models';
import { PopupTrackingService } from '@pfa/core';

@Component({
  selector: 'mitpfa-investment-recommendation-climate-detail',
  templateUrl: './recommendation-climate-detail.component.html',
  styleUrls: ['../../investment-recommendation.component.scss']
})
export class InvestmentRecommendationClimateDetailComponent {
  private popupNameForTracking = 'IG recommendation climate explained';

  private readonly dialog: MatDialog = inject(MatDialog);
  private readonly popupTrackingService: PopupTrackingService =
    inject(PopupTrackingService);

  @Input() detail: RecommendationDetail;
  @Input() recommendation: boolean;

  public openReadMore(): void {
    const matDialogRef = this.dialog.open(InvestmentReadMoreComponent, {
      ...SideBarStyle,
      data: 'CLIMATE'
    });

    matDialogRef.afterOpened().subscribe(() => {
      this.popupTrackingService.trackPopupViewed(this.popupNameForTracking);
    });

    matDialogRef.afterClosed().subscribe(() => {
      this.popupTrackingService.trackPopupClosed(this.popupNameForTracking);
    });
  }
}
