/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const DynamicAdvisingOfferGroupTypeSchema = JoiObj.string().valid(
        'ALL'
        ,
        'MISSING_DATA'
        ,
        'INVESTMENT'
        ,
        'INSURANCE'
        
    )
.id('DynamicAdvisingOfferGroupTypeSchema')

export type DynamicAdvisingOfferGroupType = 'ALL' | 'MISSING_DATA' | 'INVESTMENT' | 'INSURANCE';

export const DynamicAdvisingOfferGroupType = {
    All: 'ALL' as DynamicAdvisingOfferGroupType,
    MissingData: 'MISSING_DATA' as DynamicAdvisingOfferGroupType,
    Investment: 'INVESTMENT' as DynamicAdvisingOfferGroupType,
    Insurance: 'INSURANCE' as DynamicAdvisingOfferGroupType
};

