<div class="align--center">
  <h4 coContent="DL.INV.C650"></h4>
  <p coContent="DL.INV.C651"></p>

  <co-alert
    *ngIf="investmentGuideInfo.isGuideMoreThan1YearOld"
    class="alert-info alert-inline alert-center"
  >
    <span coContent="DL.INV.C350"></span>
  </co-alert>

  <co-alert
    *ngIf="
      basket.harPersisteretIndhold || basket.harIkkePersisteretIndholdIKurv
    "
    class="alert-inline alert-center"
  >
    <span coContent="DL.INV.C512"></span>
  </co-alert>

  <div class="spacing-unit--over">
    <button
      mat-raised-button
      [disabled]="
        basket.harPersisteretIndhold || basket.harIkkePersisteretIndholdIKurv
      "
      (click)="emitNextCard.emit(false)"
      coContent="DL.INV.C652"
    ></button>
  </div>
</div>
