/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const LocScaleSchema = JoiObj.string().valid(
        'MANUAL'
        ,
        'LOC_80'
        ,
        'LOC_100'
        ,
        'LOC_100_V2'
        ,
        'UNDEFINED'
        
    )
.id('LocScaleSchema')

export type LocScale = 'MANUAL' | 'LOC_80' | 'LOC_100' | 'LOC_100_V2' | 'UNDEFINED';

export const LocScale = {
    Manual: 'MANUAL' as LocScale,
    Loc80: 'LOC_80' as LocScale,
    Loc100: 'LOC_100' as LocScale,
    Loc100V2: 'LOC_100_V2' as LocScale,
    Undefined: 'UNDEFINED' as LocScale
};

