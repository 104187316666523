import { Component, OnInit } from '@angular/core';

import { Observable } from 'rxjs';
import { GlobalWorkingService } from './global-working.service';

@Component({
  selector: 'co-global-working',
  templateUrl: 'global-working.component.html',
  styleUrls: ['global-working.component.scss']
})
export class GlobalWorkingComponent implements OnInit {
  hide: Observable<boolean>;

  constructor(private readonly workingService: GlobalWorkingService) {}

  ngOnInit() {
    this.hide = this.workingService.loaderState;
  }
}
