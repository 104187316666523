<h4 [coContent]="'DL.MI01.C08'"></h4>
<div class="login--wrapper">
  <p class="text" [coContent]="'DL.MI01.C21'"></p>
  <button
    data-test-id="MI01-go-to-mitid-button"
    class="mitId-action"
    (click)="goToMitID()"
    mat-raised-button
    [coContent]="'DL.MI01.C11'"
  ></button>
</div>

<p *ngIf="mitidErrorText" class="text--error">
  <co-icon-error></co-icon-error>
  <span [coContent]="mitidErrorText"></span>
</p>
