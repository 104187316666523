<div class="text--bold" [coContent]="'DL.OS01.C335'"></div>
<div
  [coContent]="'DL.OS01.C336'"
  *ngIf="!savingsPolicyDetails.fraAfkastTilDepotrenteUrl"
></div>
<ul class="bullet-list" *ngIf="savingsPolicyDetails.fraAfkastTilDepotrenteUrl">
  <li>
    <a
      class="link link-external"
      [href]="
        language === 'da'
          ? savingsPolicyDetails.fraAfkastTilDepotrenteUrl
          : savingsPolicyDetails.fraAfkastTilDepotrenteUrlEngelsk
      "
      target="_blank"
    >
      <span
        *ngIf="!savingsPolicyDetails.uddybendeBeskrivelseNovo"
        [coContent]="'DL.OS01.C338'"
        [coContentSubst]="savingsPolicyDetails.uddybendeBeskrivelseAarstal"
      ></span>
      <span
        *ngIf="savingsPolicyDetails.uddybendeBeskrivelseNovo"
        [coContent]="'DL.OS01.C339'"
        [coContentSubst]="savingsPolicyDetails.uddybendeBeskrivelseAarstal"
      ></span>
    </a>
  </li>
</ul>
