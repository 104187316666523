import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import {
  MaeglerInfo,
  OpsparetVaerdi,
  Savings,
  PensionsKundeGenerelleData
} from '@pfa/gen';
import { InvestmentGuideInfo } from '@pfa/models';
import { generateSliderStepsArray } from '@pfaform';
import { Options } from 'ngx-slider-v2';
import { InvestmentGuideQuestion } from '../../../investment-guide-configuration.service';
import { InvestmentGuideService } from '@mitpfa/shared';
import { InvestmentGuideSavingsService } from './investment-guide-savings.service';
import { GuideTrackingService } from '@pfa/core';

@Component({
  selector: 'mitpfa-investment-guide-savings',
  templateUrl: './investment-guide-savings.component.html',
  styleUrls: ['./investment-guide-savings.component.scss']
})
export class InvestmentGuideSavingsComponent implements OnInit, AfterViewInit {
  @Input() investmentGuide: InvestmentGuideInfo;
  @Input() broker: MaeglerInfo;
  @Input() pensionInfoSavedValues: OpsparetVaerdi;
  @Input() pensionCustomer: PensionsKundeGenerelleData;

  form = new UntypedFormGroup({});
  savings: Savings;
  showQuestion = true;
  activeIndex = 0;
  wealthSliderOptions: Options = {
    stepsArray: [...generateSliderStepsArray(0, 1000000, 1000)],
    ceil: 1000000
  };

  constructor(
    readonly investmentGuideSavingsService: InvestmentGuideSavingsService,
    readonly investmentGuideService: InvestmentGuideService,
    readonly trackingService: GuideTrackingService
  ) {}

  ngOnInit() {
    if (this.investmentGuide?.recommendationBasis?.savings) {
      this.savings = this.investmentGuide.recommendationBasis.savings;
    } else {
      this.savings = {} as Savings;
    }
  }

  ngAfterViewInit(): void {
    window.scroll(0, 0);
  }

  declined() {
    this.investmentGuideSavingsService.clearSavings(
      this.form.value.wealthForm.wealthFormGroup
    );
    this.goToNextPage();
  }

  goBack() {
    this.investmentGuideService.updateCurrentQuestion({
      value: this.investmentGuideService.getQuestionBeforeOtherSavings(
        this.investmentGuide.recommendationBasis.conceptChoice,
        this.pensionCustomer,
        this.pensionInfoSavedValues,
        new Date()
      ),
      update: false,
      back: true
    });
  }

  goToNextPage() {
    this.investmentGuide.recommendationBasis.savings =
      this.investmentGuideSavingsService.setSavings(
        this.showQuestion,
        this.form.value.wealthForm.wealthFormGroup
      );
    this.investmentGuideService.updateCurrentQuestion({
      value: InvestmentGuideQuestion.realEstate,
      update: true
    });
  }

  displayDetails() {
    this.showQuestion = false;
    this.activeIndex = 1;
  }

  closeDetails() {
    this.showQuestion = true;
    this.activeIndex = 0;
  }
}
