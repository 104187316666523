import { InvestmentConcept } from '@pfa/gen';

export class Choice {
  public previouslySelected = false;

  constructor(
    public value: InvestmentConcept[],
    public header: string,
    public options: string[],
    public isRecommended = false
  ) {}
}
