import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { SinglePaymentApiService } from '@pfa/api';
import { Observable } from 'rxjs';
import { AmountLimits } from '@pfa/gen';

@Injectable({
  providedIn: 'root'
})
export class SinglePaymentAmountLimitsResolver
  implements Resolve<AmountLimits>
{
  constructor(private readonly singlePaymentService: SinglePaymentApiService) {}

  resolve(): AmountLimits | Observable<AmountLimits> | Promise<AmountLimits> {
    return this.singlePaymentService.getAmountLimits();
  }
}
