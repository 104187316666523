import {
  DestroyRef,
  ErrorHandler,
  inject,
  Injectable,
  Injector,
  NgZone
} from '@angular/core';
import { FORBIDDEN, UNAUTHORIZED } from 'http-status-codes';
import {
  NavigationError,
  NavigationExtras,
  NavigationStart,
  Router
} from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandlerApiService } from '@pfa/api';
import { ErrorPageInfo, JsErrorPageNavigation } from '@pfa/gen';
import { ValidationError } from 'joi';
import ErrorTypeEnum = ErrorPageInfo.ErrorTypeEnum;
import { DtrumApi } from '@dynatrace/dtrum-api-types';
import { DomainService } from './domain.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Injectable({
  providedIn: 'root'
})
export class SharedErrorHandler implements ErrorHandler {
  private static readonly HTTP_UNKNOWN_ERROR = 0;

  private readonly ngZone: NgZone = inject(NgZone);
  private readonly injector: Injector = inject(Injector);
  private readonly errorHandlerApiService: ErrorHandlerApiService = inject(
    ErrorHandlerApiService
  );
  private readonly router: Router = inject(Router);
  private readonly destroyRef: DestroyRef = inject(DestroyRef);

  private suppressErrorNavigation: boolean;
  private readonly dummy: DtrumApi = window?.dtrum;
  private readonly domainService: DomainService = inject(DomainService);

  public lastError: ErrorPageInfo = {
    errorType: ErrorTypeEnum.Frontend,
    errorCode: '',
    errorURL: '',
    errorDescription: '',
    errorStackTrace: ''
  };

  constructor() {
    this.errorHandlerApiService.suppressErrorNavigationAnnounced$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((next: boolean) => {
        this.suppressErrorNavigation = next;
      });
  }

  public handleError(error: any) {
    const chunkFailedMessage = /Loading chunk (\d+|[\w-]+) failed/;
    if (error instanceof Error && chunkFailedMessage.test(error.message)) {
      const chunkedErrorMessage = this.getChunkedErrorMessage(error.message);
      window.dtrum?.sendSessionProperties(
        undefined,
        undefined,
        {
          chunk_load_fail: { value: chunkedErrorMessage }
        },
        undefined
      );
      location.reload();
    } else {
      if (
        error instanceof HttpErrorResponse ||
        error['rejection'] instanceof HttpErrorResponse
      ) {
        this.handleHttpErrorResponse(error);
      } else if (error instanceof NavigationError) {
        this.handleNavigationError(error);
      } else if (error instanceof NavigationStart) {
        this.handleNavigationStartError();
      } else {
        this.handleOtherErrors(error);
      }
    }
  }

  public handleNavigationError(event: NavigationError): void {
    this.errorHandlerApiService.saveErrorToLog({
      cause: 'NAVIGATION_HTTP_ERROR',
      errorCode: `${event.error.status}: ${event.error.statusText}`,
      errorDescription: event.error.message,
      errorUrl: event.error.url,
      page: {
        from:
          this.router
            .getCurrentNavigation()
            ?.previousNavigation?.extractedUrl?.toString() || 'unknown',
        to: event.url
      },
      stackTrace: event.error.stack || []
    });
  }

  public handleNavigationStartError(): void {
    this.router.navigateByUrl(this.router.url);
    this.errorHandlerApiService.saveErrorToLog({
      cause: 'DIRECT_NAVIGATION',
      errorCode: null,
      errorDescription: null,
      errorUrl: null,
      page: null,
      stackTrace: []
    });

    return;
  }

  public getChunkedErrorMessage(errorMessage: string) {
    return errorMessage.substring(0, 100);
  }
  private logHttpError(httpErrorResponse: HttpErrorResponse): void {
    const errorType = httpErrorResponse.error
      ? httpErrorResponse.error.type
      : 'Unknown Event';

    window.dtrum?.reportCustomError(
      'HTTP Error',
      httpErrorResponse?.status.toString(),
      null,
      true
    );

    const headers = {};
    for (const lcName of httpErrorResponse.headers.keys()) {
      const headerValues = httpErrorResponse.headers.getAll(lcName);
      headers[lcName] = headerValues.toString();
    }
    const headersJSON = JSON.stringify(headers);
    let detailError = '';
    if (httpErrorResponse.error) {
      detailError = JSON.stringify(httpErrorResponse);
    }

    this.errorHandlerApiService.saveErrorToLog({
      cause: 'HTTP_ERROR',
      errorCode: `${httpErrorResponse.status}: ${httpErrorResponse.statusText} (${errorType})`,
      errorDescription: httpErrorResponse.message,
      errorUrl: httpErrorResponse.url ?? '',
      page:
        this.router.url !== '/error'
          ? ({
              from: this.router.url,
              to: ''
            } as JsErrorPageNavigation)
          : undefined,
      stackTrace: [headersJSON, detailError]
    });
  }

  private navigateToError(
    errorType: ErrorTypeEnum,
    errorCode: string,
    errorDescription: string,
    errorURL: string,
    errorStackTrace: string
  ): void {
    const errorPageInfo: ErrorPageInfo = {
      errorType,
      errorCode,
      errorDescription,
      errorURL,
      errorStackTrace
    };
    this.lastError = errorPageInfo;

    this.navigate('error', {
      state: errorPageInfo,
      skipLocationChange: true
    });
  }

  private handleHttpErrorResponse(error: any): void {
    const obj = error instanceof HttpErrorResponse ? error : error['rejection'];

    switch (obj.status) {
      case UNAUTHORIZED:
        this.navigateToLogin(error);
        break;
      case FORBIDDEN:
        this.navigate('forbidden');
        break;
      case SharedErrorHandler.HTTP_UNKNOWN_ERROR:
        // case for all errors which occurs because the user is breaking in-flight requests,
        // by refreshing or navigating away. If it turns out that all error types going into
        // this case are 'abort', then the logging should not be done because it is not an error.
        // This should be checked after next release.
        if (error instanceof HttpErrorResponse) {
          this.logHttpError(error);

          if (error.error.type !== 'abort') {
            this.navigateToError(
              ErrorTypeEnum.Backend,
              `${obj.status}: ${obj.statusText}`,
              obj.message,
              obj.url,
              ''
            );
          }
        }
        break;
      default:
        if (this.suppressErrorNavigation) {
          this.navigate('/');
        } else {
          this.navigateToError(
            ErrorTypeEnum.Backend,
            `${obj.status}: ${obj.statusText}`,
            obj.message,
            obj.url,
            ''
          );

          if (error instanceof HttpErrorResponse) {
            this.logHttpError(error);
          }
        }
    }
  }

  private handleOtherErrors(error: any): void {
    this.errorHandlerApiService.saveValidationOrJavascriptErrorToLog(error);
    const isValidationError = error instanceof ValidationError;

    if (isValidationError) {
      window.dtrum?.reportCustomError(
        'Validation Error',
        error.message,
        null,
        true
      );
    } else {
      window.dtrum?.reportError(error);
    }

    // if we are browsing an internal domain and the error is a validation error,
    // then we should navigate to the error page, showing the error page. If not, just return
    if (this.domainService.isExternalDomain() && isValidationError) {
      return;
    }

    this.navigateToError(
      ErrorTypeEnum.Frontend,
      '',
      (error as Error).message,
      '',
      (error as Error).stack
    );
  }

  private navigateToLogin(error: any): void {
    const httpErrorResponse =
      error instanceof HttpErrorResponse ? error : error['rejection'];
    this.navigate('/logind', {
      queryParams: {
        location: httpErrorResponse.error.location,
        mechanism: httpErrorResponse.error.mechanism
      }
    });
  }

  private navigate(routeName: string, extras?: NavigationExtras) {
    // navigation in async might break change detection, so running in zone
    // https://github.com/angular/angular/issues/37223
    this.ngZone.run(() =>
      this.injector.get(Router).navigate([routeName], extras)
    );
  }
}
