<div
  *ngIf="threadItem"
  class="messaging-threadlist-item row"
  (click)="emitOpenThread(threadItem)"
  [ngClass]="{
    first: first,
    selected: threadItem.selected,
    unread: threadItem.unread,
    'thread-open': threadOpen,
    raadgiver: isRaadgiver,
    'high-priority': threadItem.highPriority,
    'high-priority--red': priorityClassRed
  }"
>
  <div
    class="col-12 col-sm-10 col-md-6 threadlist-item-main"
    [ngClass]="{ 'thread-open': threadOpen }"
  >
    <div class="message-title">
      <div class="message-icon">
        <co-icon-envelope
          *ngIf="threadItem.unread"
          class="messaging-envelope unread"
        ></co-icon-envelope>
        <co-icon-envelope-read
          *ngIf="!threadItem.unread"
          class="messaging-envelope"
        ></co-icon-envelope-read>
      </div>
      <div class="truncate">
        <div class="truncate">{{ threadItem.title }}</div>
        <div
          class="message-time inline"
          [ngClass]="{ 'thread-open': threadOpen }"
        >
          {{ threadItem.modificationDate | date: 'dd.MM.yyyy' }}
        </div>
      </div>
    </div>
  </div>
  <div [hidden]="threadOpen" class="col-4 hide--below-lappalm">
    <div [hidden]="isRaadgiver" class="message-headline">
      {{ threadItem.headline }}
    </div>
  </div>
  <div [hidden]="threadOpen" class="col-2 hide--below-palm">
    <div class="message-time align--right">
      {{ threadItem.modificationDate | date: 'dd.MM.yyyy' }}
    </div>
  </div>
</div>
