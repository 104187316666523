import { Injectable } from '@angular/core';
import { CustomerSurveyStep } from './customer-survey.enum';

@Injectable()
export class CustomerSurveyNavigationService {
  steps = [
    CustomerSurveyStep.Intro,
    CustomerSurveyStep.Questions,
    CustomerSurveyStep.Summary
  ];

  currentStep: CustomerSurveyStep = this.steps[0];

  navigateNext({ from }: { from: CustomerSurveyStep }) {
    const currentIndex = this.findStepIndex(from);
    this.currentStep = this.steps[currentIndex + 1];
  }

  private findStepIndex(step: CustomerSurveyStep): number {
    const currentIndex = this.steps.indexOf(step);
    if (currentIndex === -1) {
      throw new Error('Customer survey step not found');
    }
    return currentIndex;
  }
}
