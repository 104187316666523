import { Injectable } from '@angular/core';
import { ContentUtilService } from '@pfa/core';
import {
  BeneficiaryView,
  BeneficiaryExistingResponse,
  BeneficiaryRules,
  BeneficiarySimple,
  BeneficiaryRelation,
  BeneficiaryType,
  BeneficiaryPolicyRule
} from '@pfa/gen';
import { Relation, AllowedAccess } from '@pfa/models';

@Injectable({
  // because it is used in basket-beneficiary
  providedIn: 'root'
})
export class InheritanceandbeneficiaryService {
  constructor(private readonly contentUtilService: ContentUtilService) {}

  beneficiaryDataFromBasket: BeneficiarySimple | undefined;

  getBeneficiaryTypeText(beneficiary: BeneficiaryView): string {
    if (beneficiary.specialBeneficiary) {
      return this.contentUtilService.getContent('DL.BG01.C168', null);
    }
    return this.getBeneficiaryTypeTextIgnoreSpecial(beneficiary.type);
  }

  getBeneficiaryTypeTextIgnoreSpecial(type: BeneficiaryType): string {
    switch (type) {
      case 'NEXT_OF_KIN':
        return this.contentUtilService.getContent('DL.BG01.C16', null);
      case 'HEIR_OF_THE_BODY':
        return this.contentUtilService.getContent('DL.BG01.C18', null);
      case 'ESTATE':
        return this.contentUtilService.getContent('DL.BG01.C20', null);
      case 'NAMED':
        return this.contentUtilService.getContent('DL.BG01.C22', null);
    }
    return this.contentUtilService.getContent('DL.BG01.C168', null);
  }

  getRelations(): Array<Relation> {
    return [
      {
        value: 'SPOUSE',
        text: this.contentUtilService.getContent('DL.BG01.C59', null),
        active: true
      },
      {
        value: 'COHABITANT_WITH_COMMON_RESIDENCE',
        text: this.contentUtilService.getContent('DL.BG01.C60', null),
        active: true
      },
      {
        value: 'COHABITANT_CHILD_OR_DESCENDANT',
        text: this.contentUtilService.getContent('DL.BG01.C61', null),
        active: true
      },
      {
        value: 'CHILD_OR_DESCENDANT',
        text: this.contentUtilService.getContent('DL.BG01.C62', null),
        active: true
      },
      {
        value: 'GRAND_CHILD_OR_DESCENDANT',
        text: this.contentUtilService.getContent('DL.BG01.C63', null),
        active: true
      },
      {
        value: 'DIVORCED_SPOUSE',
        text: this.contentUtilService.getContent('DL.BG01.C64', null),
        active: true
      },
      {
        value: 'STEP_CHILD_OR_DESCENDANT',
        text: this.contentUtilService.getContent('DL.BG01.C65', null),
        active: true
      },
      {
        value: 'ROOM_MATE',
        text: this.contentUtilService.getContent('DL.BG01.C273', null)
      },
      {
        value: 'MOTHER',
        text: this.contentUtilService.getContent('DL.BG01.C274', null)
      },
      {
        value: 'FATHER',
        text: this.contentUtilService.getContent('DL.BG01.C275', null)
      },
      {
        value: 'SISTER',
        text: this.contentUtilService.getContent('DL.BG01.C276', null)
      },
      {
        value: 'BROTHER',
        text: this.contentUtilService.getContent('DL.BG01.C277', null)
      },
      {
        value: 'COUSIN',
        text: this.contentUtilService.getContent('DL.BG01.C278', null)
      },
      {
        value: 'NIECE',
        text: this.contentUtilService.getContent('DL.BG01.C279', null)
      },
      {
        value: 'NEPHEW',
        text: this.contentUtilService.getContent('DL.BG01.C280', null)
      },
      {
        value: 'FRIEND',
        text: this.contentUtilService.getContent('DL.BG01.C281', null)
      },
      {
        value: 'SWEETHEART',
        text: this.contentUtilService.getContent('DL.BG01.C282', null)
      },
      {
        value: 'COHABITANT_WITHOUT_COMMON_RESIDENCE',
        text: this.contentUtilService.getContent('DL.BG01.C283', null)
      },
      {
        value: 'OTHER',
        text: this.contentUtilService.getContent('DL.BG01.C284', null)
      }
    ];
  }

  getRelationText(beneficiaryRelation: BeneficiaryRelation): string {
    return (
      this.getRelations().find(
        relation => relation.value === beneficiaryRelation
      )?.text || beneficiaryRelation
    );
  }

  private findBeneficiaryMatch(
    beneficiaries: BeneficiaryView[],
    policyId: string
  ): boolean {
    return beneficiaries.some(
      (beneficiaryView: BeneficiaryView) =>
        beneficiaryView.policies.findIndex(policy => policy.id === policyId) >
        -1
    );
  }

  public getAllowedAccess(
    beneficiaryData: BeneficiaryExistingResponse,
    beneficiaryRules: BeneficiaryRules
  ): AllowedAccess {
    const hasBeneficiaries = this.hasBeneficiaries(beneficiaryData);
    const policyStatusKnown = this.isPolicyStatusKnown(beneficiaryRules);
    return {
      changeBeneficiary:
        this.hasChangeableBeneficiary(beneficiaryRules, hasBeneficiaries) &&
        !this.hasChangeBlockingBeneficiary(
          beneficiaryRules,
          beneficiaryData,
          hasBeneficiaries
        ) &&
        policyStatusKnown,
      viewDetails: hasBeneficiaries && policyStatusKnown,
      policiesWithoutData: this.initializePoliciesWithoutData(
        beneficiaryData,
        beneficiaryRules
      ),
      unknownBeneficiary: this.hasUnknownBeneficiary(beneficiaryRules)
    };
  }

  private hasBeneficiaries(
    beneficiaryData: BeneficiaryExistingResponse
  ): boolean {
    return !!(
      beneficiaryData &&
      beneficiaryData.beneficiaries &&
      Array.isArray(beneficiaryData.beneficiaries) &&
      beneficiaryData.beneficiaries.length > 0
    );
  }

  private isPolicyStatusKnown(beneficiaryRules: BeneficiaryRules): boolean {
    return beneficiaryRules.policies.every(
      (policy: BeneficiaryPolicyRule) => policy.policyStatusKnown
    );
  }

  private hasChangeBlockingBeneficiary(
    beneficiaryRules: BeneficiaryRules,
    beneficiaryData: BeneficiaryExistingResponse,
    hasBeneficiaries: boolean
  ): boolean {
    return beneficiaryRules.policies.some((policy: BeneficiaryPolicyRule) => {
      const beneficiaryMatch = this.findBeneficiaryMatch(
        beneficiaryData.beneficiaries,
        policy.id
      );
      return (
        policy.beneficiaryAllowed === 'NO' &&
        hasBeneficiaries &&
        beneficiaryMatch
      );
    });
  }

  private hasChangeableBeneficiary(
    beneficiaryRules: BeneficiaryRules,
    hasBeneficiaries: boolean
  ): boolean {
    return beneficiaryRules.policies.some(
      (policy: BeneficiaryPolicyRule) =>
        policy.beneficiaryAllowed === 'YES' && hasBeneficiaries
    );
  }

  private hasUnknownBeneficiary(beneficiaryRules: BeneficiaryRules): boolean {
    return beneficiaryRules.policies.some(
      (policy: BeneficiaryPolicyRule) => policy.beneficiaryAllowed === 'UNKNOWN'
    );
  }

  private initializePoliciesWithoutData(
    beneficiaryData: BeneficiaryExistingResponse,
    beneficiaryRules: BeneficiaryRules
  ): Array<string> {
    const policiesWithoutData: Array<string> = [];
    beneficiaryRules.policies.forEach((policy: BeneficiaryPolicyRule) => {
      const beneficiaryMatch = this.findBeneficiaryMatch(
        beneficiaryData.beneficiaries,
        policy.id
      );
      if (!beneficiaryMatch && policy.policyStatusKnown) {
        policiesWithoutData.push(policy.id);
      }
    });
    return policiesWithoutData;
  }

  getFallbackNumber(
    beneficiaryData: BeneficiaryExistingResponse,
    type0: boolean
  ): string {
    let fallbackNumber: string;
    if (beneficiaryData.broker) {
      fallbackNumber = beneficiaryData.broker.phone;
    } else if (type0) {
      fallbackNumber = this.contentUtilService.getContent('DL.AB01.C28', null);
    } else {
      fallbackNumber = this.contentUtilService.getContent('DL.AB01.C27', null);
    }
    return fallbackNumber;
  }
}
