/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ServiceInfo } from './serviceInfo';
import { ServiceInfoSchema } from './serviceInfo';
import { ResourceModel } from './resourceModel';
import { ResourceModelSchema } from './resourceModel';
import * as JoiObj from "joi";


    export const OverfoerselDetaljerSchema = JoiObj.object({
        serviceInfo:
            
        JoiObj.link('#ServiceInfoSchema')

.allow(null)



        ,
        administrationsPris:
            
                    JoiObj.number()
                


.required()


        ,
        afkast:
            
                    JoiObj.number()
                


.required()


        ,
        afregnetPensionsAfkastSkat:
            
                    JoiObj.number()
                


.required()


        ,
        arbejdsMarkedsBidrag:
            
                    JoiObj.number()
                


.required()


        ,
        forsikringsPris:
            
                    JoiObj.number()
                


.required()


        ,
        fremrykketAfgiftKapitalPension:
            
                    JoiObj.number()
                


.required()


        ,
        indbetalingViaIndbetalingsSikring:
            
                    JoiObj.number()
                


.required()


        ,
        oevrigePoster:
            
                    JoiObj.number()
                


.required()


        ,
        samletIndbetaling:
            
                    JoiObj.number()
                


.required()


        ,
        samletVaerdiPrimoTotal:
            
                    JoiObj.number()
                


.required()


        ,
        samletVaerdiPrimoTotalDato:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        samletVaerdiUltimoTotal:
            
                    JoiObj.number()
                


.required()


        ,
        samletVaerdiUltimoTotalDato:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        udbetaling:
            
                    JoiObj.number()
                


.required()


        ,
        udbetalingTilAegtefaelleSamlever:
            
                    JoiObj.number()
                


.required()


        
    })
                .shared(ServiceInfoSchema)
.id('OverfoerselDetaljerSchema')

export interface OverfoerselDetaljer extends ResourceModel { 
    administrationsPris: number;
    afkast: number;
    afregnetPensionsAfkastSkat: number;
    arbejdsMarkedsBidrag: number;
    forsikringsPris: number;
    fremrykketAfgiftKapitalPension: number;
    indbetalingViaIndbetalingsSikring: number;
    oevrigePoster: number;
    samletIndbetaling: number;
    samletVaerdiPrimoTotal: number;
    samletVaerdiPrimoTotalDato: string;
    samletVaerdiUltimoTotal: number;
    samletVaerdiUltimoTotalDato: string;
    udbetaling: number;
    udbetalingTilAegtefaelleSamlever: number;
}

