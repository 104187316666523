/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const LocPayScaleEntrySchema = JoiObj.object({
        high:
            
                    JoiObj.number()
                


.required()


        ,
        low:
            
                    JoiObj.number()
                


.required()


        ,
        payHigh:
            
                    JoiObj.number()
                


.required()


        ,
        payLow:
            
                    JoiObj.number()
                


.required()


        ,
        standard:
            
                    JoiObj.number()
                


.required()


        
    })
.id('LocPayScaleEntrySchema')

export interface LocPayScaleEntry { 
    high: number;
    low: number;
    payHigh: number;
    payLow: number;
    standard: number;
}

