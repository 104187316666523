<div class="contact-card-wrap">
  <header>
    <div
      class="photo"
      [ngStyle]="{ 'background-image': 'url(' + contactDetails.imageUrl + ')' }"
    ></div>

    <div class="title-container">
      <div class="contact-type" [coContent]="contactTypeContentId"></div>
      <div class="name">{{ contactDetails.name }}</div>
    </div>
  </header>

  <div class="content">
    <span
      *ngIf="contactDetails.type !== 'Direktion'"
      [coContent]="descriptionContentId"
      [coContentSubst]="contactDetails.name"
    ></span>
    <span *ngIf="contactDetails.type === 'Direktion'">{{
      descriptionTextForBoardContact
    }}</span>
  </div>

  <footer>
    <div *ngIf="contactDetails.phone" class="phone">
      <a [href]="'tel:' + contactDetails.phone" target="_blank">
        <co-icon-phone class="icon"></co-icon-phone>
        {{ contactDetails.phone }}
      </a>
    </div>

    <div *ngIf="contactDetails.email" class="email">
      <a [href]="'mailto:' + contactDetails.email" target="_blank">
        <co-icon-envelope class="icon"></co-icon-envelope>
        {{ contactDetails.email }}
      </a>
    </div>
  </footer>
</div>
