import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import {
  CustomerProfile,
  CustomerprofileStore,
  ErrorPageInfo,
  PensionskundeStore
} from '@pfa/gen';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { combineLatest, fromEvent } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { Event, NavigationEnd, Router } from '@angular/router';
import {
  DigitalDataTrackingService,
  ExtendedWindow,
  PageTrackingService
} from '@pfa/core';
import { LightUtils, LoginService } from '@mitpfa/shared';

@Component({
  selector: 'mitpfa-tracking',
  template: ''
})
export class TrackingComponent implements OnInit {
  private readonly pensionskundeStore: PensionskundeStore =
    inject(PensionskundeStore);
  private readonly pageTrackingService: PageTrackingService =
    inject(PageTrackingService);
  private readonly customerprofileStore: CustomerprofileStore =
    inject(CustomerprofileStore);
  private readonly digitalDataTrackingService: DigitalDataTrackingService =
    inject(DigitalDataTrackingService);
  private readonly loginService: LoginService = inject(LoginService);
  private readonly router: Router = inject(Router);
  private readonly destroyRef: DestroyRef = inject(DestroyRef);

  ngOnInit(): void {
    this.loginService.authenticationEvent
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.addTracking();
      });

    this.handleRouterEvents();
    this.handleCookieBotEvents();
  }

  private addTracking(): void {
    this.customerprofileStore
      .customerprofileGet()
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        map((profile: CustomerProfile) => {
          if (profile) {
            this.digitalDataTrackingService.trackCustomerType(profile);
          }
          return profile;
        }),
        filter(
          (profile: CustomerProfile) =>
            profile && !LightUtils.isLightProfile(profile)
        ),
        switchMap(() =>
          combineLatest({
            pensionCustomer: this.pensionskundeStore.pensionskundeGet(),
            brookerInfo: this.pensionskundeStore.pensionskundeMaeglerinfoGet()
          }).pipe(take(1))
        )
      )
      .subscribe({
        next: data => {
          this.digitalDataTrackingService.trackLoginType(data.pensionCustomer);
          this.handleBrokerTracking(data?.brookerInfo?.maeglerBetjent);
        },
        error: () => {}
      });
  }

  private handleBrokerTracking(brokerManaged: boolean): void {
    if (brokerManaged) {
      (window as any).digitalData.customerType = `Broker-managed, ${
        (window as any).digitalData.customerType
      }`;
    }
  }

  private handleRouterEvents(): void {
    this.router.events
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(event => {
        this.handleNavigationEnd(event);
      });
  }

  private handleNavigationEnd(event: Event): void {
    if (event instanceof NavigationEnd) {
      if (this.loginService.isLoggedIn()) {
        this.pageTrackingService.trackPage(event.url);

        if (this.router.url === '/error') {
          const errorPageInfo: ErrorPageInfo =
            this.router.getCurrentNavigation().extras?.state as ErrorPageInfo;
          this.pageTrackingService.trackErrorPage(event.url, errorPageInfo);
        }
      } else {
        this.pageTrackingService.trackPageAnonymous(event.url);

        if (this.router.url === '/error') {
          const errorPageInfo: ErrorPageInfo =
            this.router.getCurrentNavigation().extras?.state as ErrorPageInfo;
          this.pageTrackingService.trackErrorPageAnonymous(
            event.url,
            errorPageInfo
          );
        }
      }
    }
  }
  private handleCookieBotEvents(): void {
    let cookiePopupFirstTime = true;

    fromEvent(window, 'CookiebotOnAccept')
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        const extendedWindow = window as unknown as ExtendedWindow;
        if (
          cookiePopupFirstTime &&
          extendedWindow.Cookiebot.consent?.statistics
        ) {
          this.pageTrackingService.trackPageAnonymous(this.router.url);
        }
        cookiePopupFirstTime = false;
      });

    fromEvent(window, 'CookiebotOnDecline')
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        if (!cookiePopupFirstTime) {
          location.reload();
        }
        cookiePopupFirstTime = false;
      });
  }
}
