/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ServiceInfo } from './serviceInfo';
import { ServiceInfoSchema } from './serviceInfo';
import { Type } from './type';
import { TypeSchema } from './type';
import { Tilstand } from './tilstand';
import { TilstandSchema } from './tilstand';
import { Police } from './police';
import { PoliceSchema } from './police';
import * as JoiObj from "joi";


    export const CustomerCapitalPoliceSchema = JoiObj.object({
        serviceInfo:
            
        JoiObj.link('#ServiceInfoSchema')

.allow(null)



        ,
        detaljeMulig:
            
                    JoiObj.boolean()
                


.required()


        ,
        harProfilG:
            
                    JoiObj.boolean()
                


.required()


        ,
        id:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        livnetAftale:
            
                    JoiObj.boolean()
                


.required()


        ,
        opsparing:
            
                    JoiObj.number()
                

.allow(null)



        ,
        orlovSlutDato:
            
            JoiObj.date()

.allow(null)



        ,
        tilstand:
            
        JoiObj.link('#TilstandSchema')


.required()


        ,
        tooltipContentId:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        type:
            
        JoiObj.link('#TypeSchema')


.required()


        ,
        typeTekstId:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        aldersopsparing:
            
                    JoiObj.boolean()
                

.allow(null)



        ,
        duInvesterer:
            
                    JoiObj.boolean()
                


.required()


        ,
        forrentningSidsteAar:
            
                    JoiObj.number()
                

.allow(null)



        ,
        harKundekapital:
            
                    JoiObj.boolean()
                


.required()


        ,
        ingenIndbetaling:
            
                    JoiObj.boolean()
                


.required()


        ,
        kundeKapitalKroner:
            
                    JoiObj.number()
                


.required()


        ,
        kundeKapitalKronerUltimoOpsparing:
            
                    JoiObj.number()
                


.required()


        ,
        kundeKapitalProcent:
            
                    JoiObj.number()
                


.required()


        ,
        kundekapitalModelCurrent:
            
                    JoiObj.number()
                .integer()


.required()


        ,
        kundekapitalModelPrevious:
            
                    JoiObj.number()
                .integer()


.required()


        ,
        kundekapitalMulig:
            
                    JoiObj.boolean()
                


.required()


        ,
        maegler:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        maeglerTLF:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        maeglerbetjentLivnetVises:
            
                    JoiObj.boolean()
                


.required()


        ,
        optjentIAar:
            
                    JoiObj.number()
                

.allow(null)



        ,
        tegningsDatoEfterFoersteJanuarIndevaerendeAar:
            
                    JoiObj.boolean()
                


.required()


        ,
        udbetaltIAar:
            
                    JoiObj.number()
                

.allow(null)



        
    })
                .shared(ServiceInfoSchema)
                .shared(TilstandSchema)
                .shared(TypeSchema)
.id('CustomerCapitalPoliceSchema')

export interface CustomerCapitalPolice extends Police { 
    aldersopsparing?: boolean;
    duInvesterer: boolean;
    forrentningSidsteAar?: number;
    harKundekapital: boolean;
    ingenIndbetaling: boolean;
    kundeKapitalKroner: number;
    kundeKapitalKronerUltimoOpsparing: number;
    kundeKapitalProcent: number;
    kundekapitalModelCurrent: number;
    kundekapitalModelPrevious: number;
    kundekapitalMulig: boolean;
    maegler?: string;
    maeglerTLF?: string;
    maeglerbetjentLivnetVises: boolean;
    optjentIAar?: number;
    tegningsDatoEfterFoersteJanuarIndevaerendeAar: boolean;
    udbetaltIAar?: number;
}
export namespace CustomerCapitalPolice {
}


