/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const EjendomArtSchema = JoiObj.string().valid(
        'AndenEjendom'
        ,
        'Ejerlejlighed'
        ,
        'Andelslejlighed'
        ,
        'Villa'
        ,
        'Raekkehus'
        ,
        'Parcelhus'
        ,
        'Erhvervsejendom'
        ,
        'Fritidsejendom'
        
    )
.id('EjendomArtSchema')

export type EjendomArt = 'AndenEjendom' | 'Ejerlejlighed' | 'Andelslejlighed' | 'Villa' | 'Raekkehus' | 'Parcelhus' | 'Erhvervsejendom' | 'Fritidsejendom';

export const EjendomArt = {
    AndenEjendom: 'AndenEjendom' as EjendomArt,
    Ejerlejlighed: 'Ejerlejlighed' as EjendomArt,
    Andelslejlighed: 'Andelslejlighed' as EjendomArt,
    Villa: 'Villa' as EjendomArt,
    Raekkehus: 'Raekkehus' as EjendomArt,
    Parcelhus: 'Parcelhus' as EjendomArt,
    Erhvervsejendom: 'Erhvervsejendom' as EjendomArt,
    Fritidsejendom: 'Fritidsejendom' as EjendomArt
};

