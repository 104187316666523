import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { MatSelectChange } from '@angular/material/select';
import { GlobalWorkingService } from '@pfaform';
import {
  ContentService,
  ContentUtilService,
  NumberFormatPipe
} from '@pfa/core';
import { Utils } from '@pfa/utils';
import {
  OpsparingOversigt,
  OpsparingPolice,
  OpsparingPoliceDetaljer,
  SavingsStore
} from '@pfa/gen';

@Component({
  selector: 'mitpfa-savings-policy-details',
  templateUrl: './savings-policy-details.component.html',
  styleUrls: ['./savings-policy-details.component.scss']
})
export class SavingsPolicyDetailsComponent implements OnInit, OnDestroy {
  initialized: boolean;
  savingsPolicyDetails: OpsparingPoliceDetaljer;
  savingsPolicy: OpsparingPolice | undefined;
  policyId: string;
  chosenYear: number;
  years: number[] = [];

  texts: Map<string, string>;
  palTooltip: string;
  unpaidPALTooltip: string;
  showDisclaimerDeath: boolean;
  showDisclaimerSubsidy: boolean;
  language: 'da' | 'en';

  private readonly unsubscribe = new Subject<void>();

  constructor(
    private readonly contentUtilService: ContentUtilService,
    private readonly contentService: ContentService,
    private readonly globalWorkingService: GlobalWorkingService,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly savingsStore: SavingsStore,
    private readonly numberFormat: NumberFormatPipe
  ) {}

  ngOnInit(): void {
    window.scroll(0, 0);
    this.activatedRoute.data
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (data: {
          savingsOverview: OpsparingOversigt;
          savingsPolicyDetails: OpsparingPoliceDetaljer;
        }) => {
          this.savingsPolicyDetails = data.savingsPolicyDetails;
          this.policyId = this.savingsPolicyDetails.policeId;
          this.savingsPolicy = data.savingsOverview.policer.find(
            policy => policy.id === this.policyId
          );

          if (this.savingsPolicy) {
            this.chosenYear = this.savingsPolicy.aarSpaend[0];
            this.years = this.savingsPolicy.aarSpaend.map(
              (value: number) => value
            );
          }
          this.handlePAL();
          this.handleDisclaimer();
          this.language = this.contentService.getLanguage();
          this.initialized = true;

          this.contentService.languageState
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => {
              this.handlePAL();
              this.language = this.contentService.getLanguage();
            });
        }
      );
  }

  handlePAL() {
    this.palTooltip = '';
    if (
      this.savingsPolicyDetails.afregnetPensionsafkastskat
        .saerligPALtekstBeloeb === 0 &&
      this.chosenYear < 2018
    ) {
      this.palTooltip = 'DL.OS01.I316a';
    }
    if (
      this.savingsPolicyDetails.afregnetPensionsafkastskat
        .saerligPALtekstBeloeb !== 0 &&
      this.chosenYear < 2018
    ) {
      this.palTooltip = this.contentUtilService.getContent('DL.OS01.I316b', [
        this.numberFormat.transform(
          this.savingsPolicyDetails.afregnetPensionsafkastskat
            .saerligPALtekstBeloeb,
          2
        )
      ]);
    }
    if (this.chosenYear > 2017) {
      this.palTooltip = 'DL.OS01.I318';
    }

    const unpaidPAL = this.savingsPolicyDetails.ikkeBetaltUnderskudsPAL;
    const isGreenland = this.savingsPolicyDetails.afregnetPensionsafkastskat
      ? this.savingsPolicyDetails.afregnetPensionsafkastskat.groenland
      : false;
    if (isGreenland) {
      this.unpaidPALTooltip = this.contentUtilService.getContent(
        'DL.OS01.I408',
        []
      );
    } else if (this.chosenYear < 2016) {
      this.unpaidPALTooltip = this.contentUtilService.getContent(
        'DL.OS01.I258',
        []
      );
    } else if (unpaidPAL === undefined || unpaidPAL === null) {
      this.unpaidPALTooltip = this.contentUtilService.getContent(
        'DL.OS01.I260',
        (this.chosenYear + 1).toString()
      );
    } else {
      this.unpaidPALTooltip = this.contentUtilService.getContent(
        'DL.OS01.I259',
        this.numberFormat.transform(unpaidPAL, 2)
      );
    }
  }

  handleDisclaimer() {
    const postLineElement =
      this.savingsPolicyDetails.oevrigePoster.posteringerDerSkalBetalesPalSkatAf.find(
        post => post.contentId === 'DL.OS01.C296' // depottillæg
      );
    this.showDisclaimerDeath = postLineElement
      ? postLineElement.value > 0
      : false;
    this.showDisclaimerSubsidy = !Utils.isEmptyOrZero(
      this.savingsPolicyDetails
        .tilbageFoerselInvalidedepotVedOphoerAfInvaliditet
    );
  }

  selectYear(event: MatSelectChange) {
    this.globalWorkingService.show();
    this.chosenYear = event.value;
    this.savingsStore
      .opsparingValgtPolicenummerValgtAarGet(this.policyId, this.chosenYear)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((savingsPolicyDetails: OpsparingPoliceDetaljer) => {
        this.savingsPolicyDetails = savingsPolicyDetails;
        this.globalWorkingService.hide();
      });
  }

  public goBack(): void {
    this.router.navigate(['minopsparing', this.savingsPolicy.id]);
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
