<!-- v5 -->
<svg
  id="Layer_1"
  data-name="Layer 1"
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 32 32"
>
  <g id="Canvas">
    <circle
      id="Large_circle"
      data-name="Large circle"
      cx="16"
      cy="16"
      r="16"
      fill="none"
    />
    <circle
      id="Small_circle"
      data-name="Small circle"
      cx="16"
      cy="16"
      r="12"
      fill="none"
    />
  </g>
  <g id="Icon">
    <path
      id="Envelope"
      d="M21.3,9.9c1.22.95,2.7,1.82,2.7,3.1v8a2,2,0,0,1-2,2H10a2,2,0,0,1-2-2V13c0-1.34,1.28-2,2.7-3.1"
      fill="none"
      stroke="#000"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.2"
    />
    <line
      id="Right_diagonal"
      data-name="Right diagonal"
      x1="23.5"
      y1="22.2"
      x2="19.2"
      y2="18.5"
      stroke-width="1.2"
      stroke="#000"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
    />
    <line
      id="Left_diagonal"
      data-name="Left diagonal"
      x1="12.8"
      y1="18.5"
      x2="8.5"
      y2="22.2"
      stroke-width="1.2"
      stroke="#000"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
    />
    <polyline
      id="Flap"
      points="8.5 11.8 16 18.2 23.5 11.8"
      fill="none"
      stroke="#000"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.2"
    />
    <path
      id="Line_2"
      data-name="Line 2"
      d="M13.2,13h5.5"
      fill="none"
      stroke="#000"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.2"
    />
    <path
      id="Line_1"
      data-name="Line 1"
      d="M13.3,10.6h5.5"
      fill="none"
      stroke="#000"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.2"
    />
    <polyline
      id="Letter"
      points="10.7 13.6 10.7 7.8 21.3 7.8 21.3 13.3"
      fill="none"
      stroke="#000"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.2"
    />
  </g>
</svg>
