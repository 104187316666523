/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ServiceInfo } from './serviceInfo';
import { ServiceInfoSchema } from './serviceInfo';
import { ResourceModel } from './resourceModel';
import { ResourceModelSchema } from './resourceModel';
import * as JoiObj from "joi";


    export const MaeglerInfoSchema = JoiObj.object({
        serviceInfo:
            
        JoiObj.link('#ServiceInfoSchema')

.allow(null)



        ,
        email:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        livnetAftale:
            
                    JoiObj.boolean()
                


.required()


        ,
        maeglerBetjent:
            
                    JoiObj.boolean()
                


.required()


        ,
        navn:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        telefon:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        
    })
                .shared(ServiceInfoSchema)
.id('MaeglerInfoSchema')

export interface MaeglerInfo extends ResourceModel { 
    email?: string;
    livnetAftale: boolean;
    maeglerBetjent: boolean;
    navn?: string;
    telefon?: string;
}

