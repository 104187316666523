<div *ngIf="showError">
  <mitpfa-pension-check-header-section
    [headline]="'DL.NB01.C313'"
    [explanation]="'DL.NB01.C314'"
  >
  </mitpfa-pension-check-header-section>
  <button
    class="error--back"
    mat-raised-button
    (click)="closeViewer()"
    [coContent]="'DL.NB01.C315'"
  ></button>
</div>
<div *ngIf="!showError">
  <div
    [@slideInRightWithDelay]="{ value: '', params: { delay: 400 } }"
    [@slideOutRight]
    *ngIf="pensionCustomer && selectedCardId === 'RETRIEVE_PENSION_INFO_REPORT'"
  >
    <mitpfa-pension-check-pensionsinfo
      [pensionCustomer]="pensionCustomer"
      (close)="next(selectedCardId, false, false)"
    >
    </mitpfa-pension-check-pensionsinfo>
  </div>
  <div
    [@slideInRightWithDelay]="{ value: '', params: { delay: 400 } }"
    [@slideOutRight]
    *ngIf="pensionCustomer && selectedCardId === 'ENTER_DEBT'"
  >
    <mitpfa-pension-check-debt
      [financialSituationForm]="financialSituationForm"
      [financialSituation]="financialSituation"
      [pensionCustomer]="pensionCustomer"
      (close)="next(selectedCardId, true, false)"
    >
    </mitpfa-pension-check-debt>
  </div>
  <div
    [@slideInRightWithDelay]="{ value: '', params: { delay: 400 } }"
    [@slideOutRight]
    *ngIf="pensionCustomer && selectedCardId === 'ENTER_WEALTH'"
  >
    <mitpfa-pension-check-wealth
      [financialSituationForm]="financialSituationForm"
      [financialSituation]="financialSituation"
      [pensionCustomer]="pensionCustomer"
      (close)="next(selectedCardId, true, false)"
    >
    </mitpfa-pension-check-wealth>
  </div>
  <div
    [@slideInRightWithDelay]="{ value: '', params: { delay: 400 } }"
    [@slideOutRight]
    *ngIf="familyInformation && selectedCardId === 'ENTER_DEPENDENTS'"
  >
    <mitpfa-pension-check-kids
      [familyInformation]="familyInformation"
      (close)="next(selectedCardId, false, true)"
    >
    </mitpfa-pension-check-kids>
  </div>
  <div
    [@slideInRightWithDelay]="{ value: '', params: { delay: 400 } }"
    [@slideOutRight]
    *ngIf="
      familyInformation &&
      (selectedCardId === 'ENTER_MARITAL_STATUS' ||
        selectedCardId === 'ENTER_PARTNER_SALARY')
    "
  >
    <mitpfa-pension-check-partner
      [familyInformation]="familyInformation"
      [pensionCustomer]="pensionCustomer"
      (close)="next(selectedCardId, false, true)"
    >
    </mitpfa-pension-check-partner>
  </div>
  <div
    [@slideInRightWithDelay]="{ value: '', params: { delay: 400 } }"
    [@slideOutRight]
    *ngIf="showDoneCard"
  >
    <mitpfa-pension-check-done (complete)="closeViewer()">
    </mitpfa-pension-check-done>
  </div>
</div>
