/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ServiceInfo } from './serviceInfo';
import { ServiceInfoSchema } from './serviceInfo';
import { BeskedLinieAftaleNummer } from './beskedLinieAftaleNummer';
import { BeskedLinieAftaleNummerSchema } from './beskedLinieAftaleNummer';
import { BeskedLinieModtagerNummer } from './beskedLinieModtagerNummer';
import { BeskedLinieModtagerNummerSchema } from './beskedLinieModtagerNummer';
import { BeskedFileUpload } from './beskedFileUpload';
import { BeskedFileUploadSchema } from './beskedFileUpload';
import { ResourceModel } from './resourceModel';
import { ResourceModelSchema } from './resourceModel';
import * as JoiObj from "joi";


    export const BeskedLinieSchema = JoiObj.object({
        serviceInfo:
            
        JoiObj.link('#ServiceInfoSchema')

.allow(null)



        ,
        aftaleNrList:
                JoiObj.array().items(
        
        JoiObj.link('#BeskedLinieAftaleNummerSchema')

        )
        

.required()


        ,
        antalRecords:
            
                    JoiObj.number()
                


.required()


        ,
        beskedFileUpload:
            
        JoiObj.link('#BeskedFileUploadSchema')


.required()


        ,
        cprNrList:
                JoiObj.array().items(
        
        JoiObj.link('#BeskedLinieModtagerNummerSchema')

        )
        

.required()


        ,
        felt:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        filAftaleType:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        fileInputMode:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        filnavn:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        id:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        indlejreSamtykkeModul:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        kanSlettes:
            
                    JoiObj.boolean()
                


.required()


        ,
        overskriftDansk:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        overskriftEngelsk:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        policeNrList:
                JoiObj.array().items(
        
        JoiObj.link('#BeskedLinieModtagerNummerSchema')

        )
        

.required()


        ,
        portal:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        prioritet:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        regel:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        rettetAf:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        rettetTid:
            
                    JoiObj.object()
                


.required()


        ,
        slutdato:
            
                    JoiObj.object()
                


.required()


        ,
        startdato:
            
                    JoiObj.object()
                


.required()


        ,
        status:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        tekstDansk:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        tekstEngelsk:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        uuid:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        visning:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        
    })
                .shared(ServiceInfoSchema)
                .shared(BeskedLinieAftaleNummerSchema)
                .shared(BeskedFileUploadSchema)
                .shared(BeskedLinieModtagerNummerSchema)
                .shared(BeskedLinieModtagerNummerSchema)
.id('BeskedLinieSchema')

export interface BeskedLinie extends ResourceModel { 
    aftaleNrList: Array<BeskedLinieAftaleNummer>;
    antalRecords: number;
    beskedFileUpload: BeskedFileUpload;
    cprNrList: Array<BeskedLinieModtagerNummer>;
    felt: string;
    filAftaleType: string;
    fileInputMode: string;
    filnavn: string;
    id: string;
    indlejreSamtykkeModul: string;
    kanSlettes: boolean;
    overskriftDansk: string;
    overskriftEngelsk: string;
    policeNrList: Array<BeskedLinieModtagerNummer>;
    portal: string;
    prioritet: string;
    regel: string;
    rettetAf: string;
    rettetTid: object;
    slutdato: object;
    startdato: object;
    status: string;
    tekstDansk: string;
    tekstEngelsk: string;
    uuid: string;
    visning: string;
}

