<div
  *ngIf="advisorCorrectionsForm"
  [formGroup]="advisorCorrectionsForm"
  class="row justify-content-center spacing-section--over"
>
  <div class="col-lg-12 col-xl-8 text--manchet">
    <co-advisor-box class="align--center">
      <div
        *ngIf="
          pensionPlan.advisorCorrectionsDetails.changeAt &&
          pensionPlan.advisorCorrectionsDetails.kanStraksAndvendes
        "
        class="spacing-unit--under"
      >
        <mat-checkbox
          [formControlName]="'correctionDataUsed'"
          (change)="
            correctionsSaved(
              advisorCorrectionsForm.get('correctionDataUsed').value
            )
          "
        >
          <mat-label>{{
            'DL.PP01.C775' | content: correctionDateFormatted
          }}</mat-label>
        </mat-checkbox>
      </div>
      <p
        *ngIf="
          pensionPlan.advisorCorrectionsDetails.changeAt &&
          !pensionPlan.advisorCorrectionsDetails.kanStraksAndvendes
        "
        [coContent]="'DL.PP01.C774'"
      ></p>
      <p
        *ngIf="!pensionPlan.advisorCorrectionsDetails.changeAt"
        [coContent]="'DL.PP01.C773'"
      ></p>
      <button
        mat-raised-button
        class="mat-raised-button"
        (click)="showCorrectionsModal()"
        [coContent]="'DL.PP01.C776'"
      ></button>
      <co-warning *ngIf="saveCorrectionsFailed">
        <span [coContent]="'DL.PP01.C940'"></span>
      </co-warning>
    </co-advisor-box>
  </div>
</div>
