/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CaseStatus } from './caseStatus';
import { CaseStatusSchema } from './caseStatus';
import * as JoiObj from "joi";


    export const CaseSchema = JoiObj.object({
        completedAt:
            
            JoiObj.date()

.allow(null)



        ,
        createdAt:
            
            JoiObj.date()


.required()


        ,
        description:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        id:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        status:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        statusDescriptionLong:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        statusDescriptionShort:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        systemStatus:
            
        JoiObj.link('#CaseStatusSchema')


.required()


        ,
        systemStatusDescription:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        type:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        typeDescription:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        
    })
                .shared(CaseStatusSchema)
.id('CaseSchema')

export interface Case { 
    completedAt?: Date;
    createdAt: Date;
    description?: string;
    id: string;
    status: string;
    statusDescriptionLong: string;
    statusDescriptionShort: string;
    systemStatus: CaseStatus;
    systemStatusDescription?: string;
    type: string;
    typeDescription: string;
}
export namespace Case {
}


