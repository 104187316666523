import { PfaInvestmentCard } from './pfa-investment-card.model';
import { Component, Input } from '@angular/core';
import { slideInBottom } from '@pfa/animations';

@Component({
  selector: 'mitpfa-pfa-investment-card',
  templateUrl: './pfa-investment-card.component.html',
  styleUrls: ['./pfa-investment-card.component.scss'],
  animations: [slideInBottom]
})
export class PfaInvestmentCardComponent {
  @Input() card: PfaInvestmentCard;
  @Input() showCheckmark: boolean;
}
