import {
  Component,
  EventEmitter,
  Input,
  Output,
  OnInit,
  OnChanges
} from '@angular/core';
import { FileUploader, FileLikeObject } from 'ng2-file-upload';
import { slideInRight, slideOutLeft } from '@pfa/animations';
import {
  FileType,
  fileTypes,
  fileMimeTypes,
  FileLikeObjectExtended
} from './attachment.model';
import { ContentUtilService } from '@pfa/core';

@Component({
  selector: 'co-attachment',
  templateUrl: './attachment.component.html',
  styleUrls: ['attachment.component.scss'],
  animations: [slideInRight, slideOutLeft]
})
export class AttachmentComponent implements OnInit, OnChanges {
  @Input() attachments: FileLikeObjectExtended[] = [];
  @Input() channel: string;
  @Input() note: string;
  @Input() acceptFiles: FileType[] = [
    FileType.JPG,
    FileType.JPEG,
    FileType.PNG,
    FileType.GIF,
    FileType.BMP,
    FileType.PDF,
    FileType.DOC,
    FileType.DOCX,
    FileType.XLS,
    FileType.XLSX,
    FileType.PPT,
    FileType.PPTX
  ];
  @Input() showFiles = true;
  @Input() limitType = 'total';
  @Input() limitSize: number;
  @Input() limitErrorText: string;
  @Input() minAttachmentsNumber: number;
  @Input() maxAttachmentsNumber: number;
  @Input() text = new Map<string, string>();

  @Output() showWarnings = new EventEmitter<any>();
  @Output() addAttachment = new EventEmitter<FileLikeObject>();
  @Output() removeAttachment = new EventEmitter<any>();
  @Output() attachmentsChange = new EventEmitter<FileLikeObject[]>();

  hasBaseDropZoneOver: boolean;
  uploader: FileUploader;
  addedAttachments: FileLikeObjectExtended[];
  trackId: number;
  attachmentMaxTotalSize: number;
  attachmentMaxTotalSizeText: string;
  fileInvalidTexts: string[];

  constructor(private readonly contentService: ContentUtilService) {}

  ngOnInit() {
    if (Object.keys(this.text).length === 0) {
      this.setText();
    }
    this.fileInvalidTexts = [];
    this.addedAttachments = [];
    this.trackId = 0;

    if (this.limitSize) {
      this.attachmentMaxTotalSize = this.limitSize;
    } else {
      this.attachmentMaxTotalSize = 10485760; // 10MB
    }

    if (this.limitErrorText) {
      this.attachmentMaxTotalSizeText = this.limitErrorText;
    } else {
      this.attachmentMaxTotalSizeText = this.text['DL.BE02.C22'];
    }

    this.uploader = new FileUploader({
      url: '',
      allowedMimeType: this.allowedMimeTypes
    });

    this.uploader.onAfterAddingFile = ev => {
      this.clearWarnings();
      const size = this.getTotalSize(this.addedAttachments, ev.file);
      if (this.containsFile(this.addedAttachments, ev.file)) {
        // duplicate file do nothing
        return;
      }
      if (this.addedAttachments?.length === this.maxAttachmentsNumber) {
        // already has max number of attachments
        return;
      }
      if (
        (this.limitType === 'total' && size <= this.attachmentMaxTotalSize) ||
        (this.limitType === 'file' &&
          ev.file.size <= this.attachmentMaxTotalSize)
      ) {
        this.emitAddAttachment(ev.file);
      } else {
        this.addInvalidText(this.attachmentMaxTotalSizeText);
      }
      this.emitShowWarnings(this.fileInvalidTexts);
    };

    this.uploader.onWhenAddingFileFailed = () => {
      this.addInvalidText(
        this.text['DL.BE02.C21'] + this.formattedAcceptFiles.replace(/\./g, ' ')
      );
      this.emitShowWarnings(this.fileInvalidTexts);
    };
  }

  get formattedAcceptFiles(): string {
    return this.acceptFiles.map(type => fileTypes[type]).join(',');
  }

  get allowedMimeTypes(): string[] {
    return this.acceptFiles.map(type => fileMimeTypes[type]);
  }

  ngOnChanges(changes) {
    if (
      changes.attachments?.currentValue?.length === 0 &&
      changes.attachments?.previousValue?.length > 0
    ) {
      // message sent
      this.addedAttachments = [];
      this.trackId = 0;
      this.clearWarnings();
    }
  }

  private setText() {
    this.text['DL.BE02.C07'] = this.contentService.getContent('DL.BE02.C07');
    this.text['DL.BE02.C17'] = this.contentService.getContent('DL.BE02.C17');
    this.text['DL.BE02.C18'] = this.contentService.getContent('DL.BE02.C18');
    this.text['DL.BE02.C21'] = this.contentService.getContent('DL.BE02.C21');
    this.text['DL.BE02.C22'] = this.contentService.getContent('DL.BE02.C22');
    this.text['DL.BE02.C1046'] = this.contentService.getContent(
      'DL.BE02.C1046',
      String(this.minAttachmentsNumber)
    );
    this.text['DL.BE02.C1047'] = this.contentService.getContent(
      'DL.BE02.C1047',
      String(this.maxAttachmentsNumber)
    );
  }

  containsFile(addedFiles, file) {
    return addedFiles.some(
      element => file.name === element.name && file.size === element.size
    );
  }

  emitAddAttachment(file: FileLikeObjectExtended) {
    file.trackId = ++this.trackId;
    this.addedAttachments.unshift(file);
    this.emitShowWarnings(this.fileInvalidTexts);
    this.addAttachment.emit(file);
    this.attachmentsChange.emit(this.addedAttachments);
  }

  emitRemoveAttachment(file: FileLikeObjectExtended) {
    this.clearWarnings();
    this.addedAttachments.splice(this.addedAttachments.indexOf(file), 1);
    this.removeAttachment.emit(file);
    this.attachmentsChange.emit(this.addedAttachments);
  }

  removeAllAttachments() {
    this.addedAttachments.forEach(attachment =>
      this.emitRemoveAttachment(attachment)
    );
  }

  fileOver(e: any) {
    this.hasBaseDropZoneOver = e;
  }

  emitShowWarnings(warnings: string[]) {
    this.showWarnings.emit(warnings.slice(0));
  }

  clearWarnings() {
    this.fileInvalidTexts = [];
    this.emitShowWarnings([]);
  }

  getTotalSize(addedFiles, newFile) {
    const files = newFile ? addedFiles.concat(newFile) : addedFiles;
    return files.reduce((acc, file) => acc + file.size, 0);
  }

  addInvalidText(contentId) {
    if (this.fileInvalidTexts.indexOf(contentId) === -1) {
      this.fileInvalidTexts.push(contentId);
    }
  }
}
