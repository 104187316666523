<div class="steps">
  <div
    class="step"
    *ngFor="let step of steps; let i = index; trackBy: trackByFn"
    [ngClass]="{ 'step--active': i === selectedStepIndex }"
  >
    <div class="step__label-container">
      <div
        class="back-button"
        *ngIf="selectedStepIndex !== 0 && showNavigation"
        (click)="handleBackButton()"
      >
        <co-icon-arrow-left class="back-button__icon"></co-icon-arrow-left>
        <span coContent="DL.IO01.C102"> Back </span>
      </div>
      <div
        class="step__label"
        [ngClass]="{ disabled: !isNavigationEnabled }"
        (click)="handleSelectStep(i)"
      >
        <span [coContent]="step.label"></span>&nbsp;<span class="counter"
          >{{ selectedStepIndex + 1 }}/{{ steps.length }}</span
        >
      </div>
      <div
        class="next-button"
        *ngIf="selectedStepIndex < steps.length - 1 && showNavigation"
        (click)="handleNextButton()"
      >
        <span coContent="DL.IO01.C315"> Next </span>
        <co-icon-arrow-right class="back-button__icon"></co-icon-arrow-right>
      </div>
    </div>
    <div class="step__bars-container">
      <div class="step__background-bar">
        <co-icon-circle-checkmark
          *ngIf="step.progress === 100"
          fill="secondary80"
          color="white"
          [border]="false"
          class="step__completed-icon"
        ></co-icon-circle-checkmark>
      </div>
      <div
        class="step__progress-bar"
        [ngClass]="{ 'step__progress-bar--completed': step.progress === 100 }"
        [ngStyle]="{ width: step.progress + '%' }"
      ></div>
    </div>
  </div>
</div>
