/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const LookupFieldTypeSchema = JoiObj.string().valid(
        'CPR'
        ,
        'FIRST_NAME'
        ,
        'LAST_NAME'
        ,
        'POLICY_NUMBER'
        ,
        'PARTNER_NO'
        ,
        'PHONE_NUMBER'
        ,
        'EMAIL'
        
    )
.id('LookupFieldTypeSchema')

export type LookupFieldType = 'CPR' | 'FIRST_NAME' | 'LAST_NAME' | 'POLICY_NUMBER' | 'PARTNER_NO' | 'PHONE_NUMBER' | 'EMAIL';

export const LookupFieldType = {
    Cpr: 'CPR' as LookupFieldType,
    FirstName: 'FIRST_NAME' as LookupFieldType,
    LastName: 'LAST_NAME' as LookupFieldType,
    PolicyNumber: 'POLICY_NUMBER' as LookupFieldType,
    PartnerNo: 'PARTNER_NO' as LookupFieldType,
    PhoneNumber: 'PHONE_NUMBER' as LookupFieldType,
    Email: 'EMAIL' as LookupFieldType
};

