import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatSelectChange } from '@angular/material/select';
import { GlobalWorkingService } from '@pfaform';
import {
  LinieElementOpsparing,
  OpsparingOversigt,
  OpsparingPolice,
  OpsparingPoliceDetaljer,
  SavingsStore
} from '@pfa/gen';
import { PageTrackingService } from '@pfa/core';

@Component({
  selector: 'mitpfa-savings-policy',
  templateUrl: './savings-policy.component.html',
  styleUrls: ['./savings-policy.component.scss']
})
export class SavingsPolicyComponent implements OnInit, OnDestroy {
  private readonly globalWorkingService: GlobalWorkingService =
    inject(GlobalWorkingService);
  private readonly router: Router = inject(Router);
  private readonly activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  private readonly savingsStore: SavingsStore = inject(SavingsStore);
  private readonly pageTrackingService: PageTrackingService =
    inject(PageTrackingService);

  public initialized: boolean;
  public savingsOverview: OpsparingOversigt;
  public savingsPolicyDetails: OpsparingPoliceDetaljer;
  public policyId: string;
  public policy: OpsparingPolice | undefined;
  public chosenYear: number;
  public years: number[] = [];
  public productAndConceptData: LinieElementOpsparing | undefined;
  private readonly unsubscribe = new Subject<void>();

  public ngOnInit(): void {
    window.scroll(0, 0);
    this.activatedRoute.data
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (data: {
          savingsOverview: OpsparingOversigt;
          savingsPolicyDetails: OpsparingPoliceDetaljer;
        }) => {
          this.savingsOverview = data.savingsOverview;
          this.savingsPolicyDetails = data.savingsPolicyDetails;
          this.policyId = data.savingsPolicyDetails.policeId;
          this.policy = this.savingsOverview.policer.find(
            policy => policy.id === this.policyId
          );
          this.handleProductAndConceptData();
          this.chosenYear = this.policy.aarSpaend[0];
          this.years = this.policy.aarSpaend.map(function (value) {
            return value;
          });
          this.initialized = true;
          this.pageTrackingService.trackContentLoaded();
        }
      );
  }

  public selectYear(event: MatSelectChange): void {
    this.globalWorkingService.show();
    this.chosenYear = event.value;
    this.savingsStore
      .opsparingValgtPolicenummerValgtAarGet(this.policyId, this.chosenYear)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((savingsPolicyDetails: OpsparingPoliceDetaljer) => {
        this.savingsPolicyDetails = savingsPolicyDetails;
        this.handleProductAndConceptData();
        this.globalWorkingService.hide();
      });
  }

  public gotoDetils(policyId: string): void {
    this.router.navigate(['minopsparing', policyId, 'detaljer']);
  }

  public goBack(): void {
    this.router.navigate(['minopsparing']);
  }

  public ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private handleProductAndConceptData(): void {
    // Average rate with inflated deposit
    if (
      !this.policy.livnetAftale &&
      this.savingsPolicyDetails
        .tilbageFoerselInvalidedepotVedOphoerAfInvaliditet !== 0
    ) {
      return;
    }
    this.productAndConceptData =
      this.savingsPolicyDetails.vaerdiAfOpsparingUltimo?.linieElementer?.find(
        lineElement =>
          lineElement.contentId === 'DL.OS01.C204' && lineElement.value > 0
      );
  }
}
