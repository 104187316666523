export type FooterContactType = 'standard' | 'broker' | 'advisor';

export const FOOTER_NAVIGATION_TRACKING = {
  name: 'footer section',
  action: {
    callPfa: 'call pfa',
    callAdvisor: 'call advisor',
    callBroker: 'call broker',
    writePfa: 'write pfa',
    writeBroker: 'write broker',
    contact: 'contact details'
  },
  destination: {
    messaging: '/beskeder',
    contact: '/kontakt'
  }
};
