import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DocumentCache, DocumentCacheItem } from './documents.model';
import { DokumenterInfoDetails } from '@pfa/gen';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ContentService, ContentUtilService } from '@pfa/core';
import * as moment from 'moment';
import { PdfPopupService } from '@pfaform';

@Injectable({
  providedIn: 'root'
})
export class DocumentsService {
  private documentCache = new DocumentCache();
  private englishDocument: string;

  constructor(
    private http: HttpClient,
    private contentService: ContentService,
    private contentUtilService: ContentUtilService,
    private pdfPopupService: PdfPopupService
  ) {
    this.englishDocument = this.contentUtilService.getContent('DL.DO01.C57');
    this.contentService.languageState.subscribe(() => {
      this.updateContent();
    });
  }

  getDocuments(valgt: string = 'seneste'): Observable<DocumentCacheItem> {
    if (this.documentCache[valgt].documents === null) {
      return this.http
        .get<DokumenterInfoDetails>('/ds/api/dokumenter/' + valgt)
        .pipe(
          map(dokumenterInfoDetails => {
            this.mapDocumentCacheItem(valgt, dokumenterInfoDetails);
            return this.documentCache[valgt];
          })
        );
    } else {
      return of(this.documentCache[valgt]);
    }
  }

  showPdfById(token: string) {
    const documentUrl =
      encodeURIComponent('/ds/open/dokumenter/dokument?token=') +
      encodeURIComponent(encodeURIComponent(token));
    this.pdfPopupService.showPdfPopup(documentUrl);
  }

  clearCache() {
    this.documentCache.seneste.clearCache();
    this.documentCache.alle.clearCache();
  }

  private mapDocumentCacheItem(
    valgt: string,
    dokumenterInfoDetails: DokumenterInfoDetails
  ): void {
    this.documentCache[valgt].documents = dokumenterInfoDetails.dokumentinfo;
    this.documentCache[valgt].dropDownElementer =
      dokumenterInfoDetails.dropDownElementer;

    const documentTypes = this.getDocumentTypes();
    // lintfixme: fix error and enable rule
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    this.documentCache[valgt].documents.forEach(document => {
      document.text = documentTypes.getText(
        document.dokumentType,
        document.sprogkode
      );

      if (document.krDokument && document.fremsendtPrPost) {
        document.text =
          document.text +
          (' ' + this.contentUtilService.getContent('DL.DO01.C22'));
      }

      document.dato = moment(document.dato);
      document.datoFormateret = document.dato.format('DD.MM.YYYY');
      document.showPdf = function () {
        self.showPdfById(document.token);
      };

      if (document.krDokument) {
        if (document.gaeldendeFraDato === null) {
          document.toolTipText = this.contentUtilService.getContent(
            'DL.DO01.C23',
            null
          );
        } else {
          const gaeldendeFraDatoFormateret = moment(document.gaeldendeFraDato);
          document.toolTipText = this.contentUtilService.getContent(
            'DL.DO01.C21',
            gaeldendeFraDatoFormateret.format('DD.MM.YYYY')
          );
        }
        this.documentCache[valgt].krLinkExists = true;
      } else {
        // there are at least one plus doc
        this.documentCache[valgt].plusLinkExists = true;
      }
    });
    if (this.documentCache[valgt].startOrder) {
      this.documentCache[valgt].documents = this.documentCache[
        valgt
      ].startOrder(this.documentCache[valgt].documents);
    }
  }

  private getDocumentTypes() {
    // lintfixme: fix error and enable rule
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    const documentTypes = {
      AftalePensionsordning: this.contentUtilService.getContent('DL.DO01.C10'),
      BilagTilPensionsordning:
        this.contentUtilService.getContent('DL.DO01.C11'),
      DepotregnskabOverfoersler:
        this.contentUtilService.getContent('DL.DO01.C12'),
      Kvittering: this.contentUtilService.getContent('DL.DO01.C13'),
      Overfoerselsoplysninger:
        this.contentUtilService.getContent('DL.DO01.C14'),
      Pensionsbevis: this.contentUtilService.getContent('DL.DO01.C15'),
      Pensionsoversigt: this.contentUtilService.getContent('DL.DO01.C16'),
      Pensionsrapport: this.contentUtilService.getContent('DL.DO01.C17'),
      Pensionsvilkaar: this.contentUtilService.getContent('DL.DO01.C18'),
      Udbetaling: this.contentUtilService.getContent('DL.DO01.C19'),
      FormuePraesentation: this.contentUtilService.getContent('DL.DO01.C61'),
      FormueReferat: this.contentUtilService.getContent('DL.DO01.C62'),
      UdbetalingsplanRapport: this.contentUtilService.getContent('DL.DO01.C63'),
      LifeStepUdbetaling: this.contentUtilService.getContent('DL.DO01.C75'),
      LifeStepUdbetalingMedPartner:
        this.contentUtilService.getContent('DL.DO01.C76'),
      Pensionsoversigter: this.contentUtilService.getContent('DL.DO01.C79'),
      Depotoversigter: this.contentUtilService.getContent('DL.DO01.C80'),
      Udbetalingsoversigter: this.contentUtilService.getContent('DL.DO01.C81'),
      Oevrige: this.contentUtilService.getContent('DL.DO01.C82'),
      OevrigeOevrige: this.contentUtilService.getContent('DL.DO01.C77'),
      Pensionsoversigter30Sep:
        this.contentUtilService.getContent('DL.DO01.C83'),
      Depotoversigter30Sep: this.contentUtilService.getContent('DL.DO01.C84'),
      GIPPdepotOpgoerelse: this.contentUtilService.getContent('DL.DO01.C87'),
      INVESTERINGSGUIDERAPPORT:
        this.contentUtilService.getContent('DL.DO01.C88'),
      FORSIKRINGSGUIDERAPPORT:
        this.contentUtilService.getContent('DL.DO01.C89'),
      AUTOMATISK_ANTAGELSE_KVITTERING:
        this.contentUtilService.getContent('DL.DO01.C90'),
      BENEFICIARY_DECLARATION:
        this.contentUtilService.getContent('DL.DO01.C96'),
      BENEFICIARY_SEPARATE_PROPERTIES:
        this.contentUtilService.getContent('DL.DO01.C97'),
      BENEFICIARY_TIED_UP_FUNDS:
        this.contentUtilService.getContent('DL.DO01.C98'),
      getText: function (type: string, language: string): string {
        if (documentTypes[type]) {
          return (
            documentTypes[type] +
            (language === 'en' ? self.englishDocument : '')
          );
        }
        throw new Error('Unknown document type: ' + type);
      }
    };
    return documentTypes;
  }

  private updateContent() {
    const documentTypes = this.getDocumentTypes();

    if (this.documentCache.seneste.documents) {
      this.documentCache.seneste.documents.forEach(document => {
        document.text = documentTypes.getText(
          document.dokumentType,
          document.sprogkode
        );
      });
    }

    if (this.documentCache.alle.documents) {
      this.documentCache.alle.documents.forEach(document => {
        document.text = documentTypes.getText(
          document.dokumentType,
          document.sprogkode
        );
      });
    }
  }
}
