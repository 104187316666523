/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const LocPayScaleDateResponseSchema = JoiObj.object({
        locPayScaleDates:
            
        JoiObj.object().pattern(/./,         JoiObj.array().items(
        
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

        )
        
.allow(null)


)


.required()


        
    })
.id('LocPayScaleDateResponseSchema')

export interface LocPayScaleDateResponse { 
    locPayScaleDates: { [key: string]: Array<string>; };
}

