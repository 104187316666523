/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ServiceInfo } from './serviceInfo';
import { ServiceInfoSchema } from './serviceInfo';
import { ResourceModel } from './resourceModel';
import { ResourceModelSchema } from './resourceModel';
import * as JoiObj from "joi";


    export const AktivFordelingSchema = JoiObj.object({
        serviceInfo:
            
        JoiObj.link('#ServiceInfoSchema')

.allow(null)



        ,
        hoejBeloeb:
            
                    JoiObj.number()
                


.required()


        ,
        hoejProcent:
            
                    JoiObj.number()
                


.required()


        ,
        kundekapitalBeloeb:
            
                    JoiObj.number()
                


.required()


        ,
        lavBeloeb:
            
                    JoiObj.number()
                


.required()


        ,
        lavGennemsnitsrenteBeloeb:
            
                    JoiObj.number()
                


.required()


        ,
        lavGennemsnitsrenteProcent:
            
                    JoiObj.number()
                


.required()


        ,
        lavProcent:
            
                    JoiObj.number()
                


.required()


        ,
        oevrigeBeloeb:
            
                    JoiObj.number()
                


.required()


        ,
        oevrigeProcent:
            
                    JoiObj.number()
                


.required()


        ,
        udbetalingssikringBeloeb:
            
                    JoiObj.number()
                


.required()


        ,
        udbetalingssikringProcent:
            
                    JoiObj.number()
                


.required()


        
    })
                .shared(ServiceInfoSchema)
.id('AktivFordelingSchema')

export interface AktivFordeling extends ResourceModel { 
    hoejBeloeb: number;
    hoejProcent: number;
    kundekapitalBeloeb: number;
    lavBeloeb: number;
    lavGennemsnitsrenteBeloeb: number;
    lavGennemsnitsrenteProcent: number;
    lavProcent: number;
    oevrigeBeloeb: number;
    oevrigeProcent: number;
    udbetalingssikringBeloeb: number;
    udbetalingssikringProcent: number;
}

