<mitpfa-advantage-header
  title="{{ 'DL.LB01.C14' | content }}"
  subTitle="{{ 'DL.LB01.C15' | content }}"
  text="{{ 'DL.LB01.C16' | content }}"
  imgUrl="{{ 'DL.LB01.C17' | content }}"
>
</mitpfa-advantage-header>

<div class="insurance-advantages">
  <h3 [coContent]="'DL.LB01.C18'"></h3>
  <div class="row">
    <div class="col-lg-6 col-sm-5">
      <mitpfa-advantage-insurance-collage></mitpfa-advantage-insurance-collage>
    </div>
    <div class="col-sm-6 advantage-list">
      <div class="row">
        <div class="col-sm-12"><p [coContent]="'DL.LB01.C19'"></p></div>
        <div *ngFor="let advantage of advantages" class="advantage col-sm-5">
          <co-icon-circle-checkmark
            [border]="false"
            color="white"
            class="advantage-icon"
          ></co-icon-circle-checkmark>
          <span [coContent]="advantage"></span>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="bank-advisor-contact">
  <div class="row align-items-center">
    <div class="col-md-6 offset-md-1 col-sm-12">
      <h3 class="contact-title" [coContent]="'DL.LB01.C32'"></h3>
      <div class="contact-text" [coContent]="'DL.LB01.C33'"></div>
    </div>

    <div class="col-md-4 col-sm-12 button-align">
      <a
        [coContent]="'DL.LB01.C34'"
        mat-raised-button
        class="button-primary"
        [href]="'DL.LB01.C13' | content"
        target="_blank"
        (click)="trackReadMoreAboutInsurance()"
      >
      </a>
    </div>
  </div>
</div>
