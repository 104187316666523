/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ServiceInfo } from './serviceInfo';
import { ServiceInfoSchema } from './serviceInfo';
import { FrieMidler } from './frieMidler';
import { FrieMidlerSchema } from './frieMidler';
import { WealthSummary } from './wealthSummary';
import { WealthSummarySchema } from './wealthSummary';
import { PensionsplanPolice } from './pensionsplanPolice';
import { PensionsplanPoliceSchema } from './pensionsplanPolice';
import { ResourceModel } from './resourceModel';
import { ResourceModelSchema } from './resourceModel';
import { WealthResidencesSummary } from './wealthResidencesSummary';
import { WealthResidencesSummarySchema } from './wealthResidencesSummary';
import * as JoiObj from "joi";


    export const WealthSchema = JoiObj.object({
        serviceInfo:
            
        JoiObj.link('#ServiceInfoSchema')

.allow(null)



        ,
        externalAssets:
            
        JoiObj.link('#FrieMidlerSchema')


.required()


        ,
        failedPolicies:
                JoiObj.array().items(
        
        JoiObj.link('#PensionsplanPoliceSchema')

        )
        

.required()


        ,
        otherWealthSummary:
            
        JoiObj.link('#WealthSummarySchema')


.required()


        ,
        pfaWealthSummary:
            
        JoiObj.link('#WealthSummarySchema')


.required()


        ,
        residencesSummary:
            
        JoiObj.link('#WealthResidencesSummarySchema')


.required()


        
    })
                .shared(ServiceInfoSchema)
                .shared(FrieMidlerSchema)
                .shared(PensionsplanPoliceSchema)
                .shared(WealthSummarySchema)
                .shared(WealthSummarySchema)
                .shared(WealthResidencesSummarySchema)
.id('WealthSchema')

export interface Wealth extends ResourceModel { 
    externalAssets: FrieMidler;
    failedPolicies: Array<PensionsplanPolice>;
    otherWealthSummary: WealthSummary;
    pfaWealthSummary: WealthSummary;
    residencesSummary: WealthResidencesSummary;
}

