export function backendServiceFactory(i) {
  return i.get('backendService');
}

export const backendServiceProvider = {
  provide: 'backendService',
  useFactory: backendServiceFactory,
  deps: ['$injector']
};

export function opsparingServiceFactory(i) {
  return i.get('OpsparingService');
}

export const opsparingServiceProvider = {
  provide: 'opsparingService',
  useFactory: opsparingServiceFactory,
  deps: ['$injector']
};

export function tjeklisteServiceFactory(i) {
  return i.get('TjeklisteService');
}

export const tjeklisteServiceProvider = {
  provide: 'TjeklisteService',
  useFactory: tjeklisteServiceFactory,
  deps: ['$injector']
};

export function mineaendringerServiceFactory(i) {
  return i.get('MineaendringerService');
}

export const mineaendringerServiceProvider = {
  provide: 'MineaendringerService',
  useFactory: mineaendringerServiceFactory,
  deps: ['$injector']
};
