import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'mitpfa-onboarding-step-insurance',
  templateUrl: './step-insurance.component.html',
  styleUrls: ['./step-insurance.component.scss']
})
export class StepInsuranceComponent implements OnInit {
  @Output() goTo = new EventEmitter();
  public changeHeadline = false;

  public ngOnInit(): void {
    setTimeout(() => {
      this.changeHeadline = true;
    }, 4000);
  }

  public next(): void {
    this.goTo.emit();
  }
}
