/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Indtastetaftale } from './indtastetaftale';
import { IndtastetaftaleSchema } from './indtastetaftale';
import { Indtastetforsikring } from './indtastetforsikring';
import { IndtastetforsikringSchema } from './indtastetforsikring';
import { Paragraf41 } from './paragraf41';
import { Paragraf41Schema } from './paragraf41';
import { PensionsplanPolice } from './pensionsplanPolice';
import { PensionsplanPoliceSchema } from './pensionsplanPolice';
import { Aktor } from './aktor';
import { AktorSchema } from './aktor';
import * as JoiObj from "joi";


    export const AdvisorCorrectionsDetailsSchema = JoiObj.object({
        aktor:
            
        JoiObj.link('#AktorSchema')

.allow(null)



        ,
        anvendt:
            
                    JoiObj.boolean()
                


.required()


        ,
        changeAt:
            
            JoiObj.date()

.allow(null)



        ,
        fraValgtPolicerInvalid:
            
                    JoiObj.boolean()
                


.required()


        ,
        invalidFraValgtPolicer:
                JoiObj.array().items(
        
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

        )
        
.allow(null)



        ,
        kanRedigeres:
            
                    JoiObj.boolean()
                


.required()


        ,
        kanStraksAndvendes:
            
                    JoiObj.boolean()
                


.required()


        ,
        kommentar:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        manuelForsikringer:
                JoiObj.array().items(
        
        JoiObj.link('#IndtastetforsikringSchema')

        )
        

.required()


        ,
        manuelPolicer:
                JoiObj.array().items(
        
        JoiObj.link('#IndtastetaftaleSchema')

        )
        

.required()


        ,
        manuelPolicerInvalid:
            
                    JoiObj.boolean()
                


.required()


        ,
        paragraf41:
            
        JoiObj.link('#Paragraf41Schema')


.required()


        ,
        paragraf41Invalid:
            
                    JoiObj.boolean()
                


.required()


        ,
        policer:
                JoiObj.array().items(
        
        JoiObj.link('#PensionsplanPoliceSchema')

        )
        

.required()


        
    })
                .shared(AktorSchema)
                .shared(IndtastetforsikringSchema)
                .shared(IndtastetaftaleSchema)
                .shared(Paragraf41Schema)
                .shared(PensionsplanPoliceSchema)
.id('AdvisorCorrectionsDetailsSchema')

export interface AdvisorCorrectionsDetails { 
    aktor?: Aktor;
    anvendt: boolean;
    changeAt?: Date;
    fraValgtPolicerInvalid: boolean;
    invalidFraValgtPolicer?: Array<string>;
    kanRedigeres: boolean;
    kanStraksAndvendes: boolean;
    kommentar?: string;
    manuelForsikringer: Array<Indtastetforsikring>;
    manuelPolicer: Array<Indtastetaftale>;
    manuelPolicerInvalid: boolean;
    paragraf41: Paragraf41;
    paragraf41Invalid: boolean;
    policer: Array<PensionsplanPolice>;
}

