import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  AdvisorCorrectionsDetails,
  AdvisorCorrectionsRequest,
  SuccessResponse
} from '@pfa/gen';

@Injectable({
  providedIn: 'root'
})
export class AdvisorCorrectionsApiService {
  constructor(private http: HttpClient) {}

  saveCorrections(
    advisorCorrectionsRequest: AdvisorCorrectionsRequest
  ): Observable<SuccessResponse> {
    return this.http.post<SuccessResponse>(
      '/ds/api/advisorcorrections',
      advisorCorrectionsRequest
    );
  }

  applyCorrections(use: boolean): Observable<SuccessResponse> {
    return this.http.post<SuccessResponse>('/ds/api/advisorcorrections/use', {
      booleanValue: use
    });
  }

  saveCorrectionsPartner(
    advisorCorrectionsRequest: AdvisorCorrectionsRequest
  ): Observable<SuccessResponse> {
    return this.http.post<SuccessResponse>(
      '/ds/api/advisorcorrections/partner',
      advisorCorrectionsRequest
    );
  }

  getCorrectionsPartner(): Observable<AdvisorCorrectionsDetails> {
    return this.http.get<AdvisorCorrectionsDetails>(
      '/ds/api/advisorcorrections/partner'
    );
  }

  applyCorrectionsPartner(use: boolean): Observable<AdvisorCorrectionsDetails> {
    return this.http.post<AdvisorCorrectionsDetails>(
      '/ds/api/advisorcorrections/partner/use',
      { booleanValue: use }
    );
  }

  applyAdvisorCorrections(useCorrection: boolean): Observable<boolean> {
    const url = '/ds/api/advisorcorrections/use';
    return this.http.post<boolean>(url, { booleanValue: useCorrection });
  }
}
