/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Plan } from './plan';
import { PlanSchema } from './plan';
import * as JoiObj from "joi";


    export const LivsPlanSchema = JoiObj.object({
        planType:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        daekningsgivendeLoen:
            
                    JoiObj.number()
                


.required()


        ,
        forsoergerAndel:
            
                    JoiObj.number()
                


.required()


        ,
        giftSamlevende:
            
                    JoiObj.boolean()
                


.required()


        ,
        harBoern:
            
                    JoiObj.boolean()
                


.required()


        ,
        procent:
            
                    JoiObj.number()
                


.required()


        ,
        recommendationAdjustedToMinimum:
            
                    JoiObj.boolean()
                


.required()


        ,
        saerligAnbefaling:
            
                    JoiObj.boolean()
                


.required()


        ,
        depotSikring:
            
                    JoiObj.number()
                

.allow(null)



        ,
        formue:
            
                    JoiObj.number()
                

.allow(null)



        ,
        fraDepot:
            
                    JoiObj.boolean()
                


.required()


        
    })
.id('LivsPlanSchema')

export interface LivsPlan extends Plan { 
    planType: string;
    depotSikring?: number;
    formue?: number;
    fraDepot: boolean;
}

