import {
  AftaleAnbefaling,
  GenerelEksternOverfoerselAdvarsel,
  OpsparingType
} from '@pfa/gen';

export interface CombineSavingsAdvisorStep {
  name: CombineSavingsAdvisorRoute;
}

export interface CombineSavingsAdvisorInternalState {
  ill: boolean | undefined;
  policies: AftaleAnbefaling[];
  hasMarketRate: boolean;
  hasAverageRate: boolean;
  hasPoliciesThatCanBeTransferred: boolean;
  hasPoliciesThatCannotBeTransferred: boolean;
  hasNoPolicies: boolean;
  receivingPolicyId: string;
  hasReceivingPolicy: boolean;
  policyRemovedFromBasket: boolean;
  showAgeWarning: boolean;
  brokerOfficer: boolean;
  companies: Company[];
  manualAgreementAlreadyExists: boolean;
  topWarnings: GenerelEksternOverfoerselAdvarsel[];
  transferAddedToBasket: boolean;
  contractId: string;
}

export interface Company {
  id: string;
  name: string;
}

export interface ProductType {
  name: string;
  value: OpsparingType;
  selectable: boolean;
}

export const clearIdBanks = ['55', '57'];

export enum CombineSavingsAdvisorRoute {
  PolicyChoice = 'PolicyChoice',
  Brief = 'Brief',
  Preconditions = 'Preconditions',
  AcceptTransfer = 'AcceptTransfer',
  Summary = 'Summary'
}

export enum CombineSavingsAdvisorFlowType {
  FullFlow = 'FullFlow',
  SummaryFlow = 'SummaryFlow'
}
