/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const BarnTypeSchema = JoiObj.string().valid(
        'Barn'
        ,
        'PartnersBarn'
        ,
        'Begunstiget'
        ,
        'Ukendt'
        
    )
.id('BarnTypeSchema')

export type BarnType = 'Barn' | 'PartnersBarn' | 'Begunstiget' | 'Ukendt';

export const BarnType = {
    Barn: 'Barn' as BarnType,
    PartnersBarn: 'PartnersBarn' as BarnType,
    Begunstiget: 'Begunstiget' as BarnType,
    Ukendt: 'Ukendt' as BarnType
};

