<tr
  [ngClass]="{
    'forced-thick-border': resultLine,
    'forced-thin-border': !resultLine
  }"
>
  <td
    [attr.data-label]="'DL.OS01.C425' | content"
    class="responsiv-top-padding"
  >
    <co-table-accordion-control
      [contentId]="headerContentId"
      [contentIdSubst]="headerSubst"
      [toolTip]="tooltipContentId"
      (expandedChange)="toggleExpanded($event)"
      [show]="lineElements.length > 0"
    ></co-table-accordion-control>
  </td>
  <td
    class="align--right text--bold"
    [attr.data-label]="'DL.OS01.C426' | content"
  >
    <div *ngIf="!resultLine">
      {{ total | numberFormat: 2 }}
      <span *ngIf="total" [coContent]="'Global.kroner'"></span>
    </div>

    <co-primary-number
      *ngIf="resultLine"
      [amount]="total"
      [showDecimals]="true"
      class="size-sm"
    ></co-primary-number>
  </td>
</tr>

<ng-container *ngIf="expanded">
  <tr
    class="forced-thin-border table-emphasized"
    *ngFor="let lineElement of lineElements"
  >
    <td
      [attr.data-label]="'DL.OS01.C425' | content"
      [coContent]="lineElement.contentId"
      [coContentSubst]="lineElementSubst"
    ></td>
    <td class="align--right" [attr.data-label]="'DL.OS01.C426' | content">
      {{ lineElement.value | numberFormat: 2 }}
      <span [coContent]="'Global.kroner'"></span>
    </td>
  </tr>
</ng-container>
