/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Skattekode } from './skattekode';
import { SkattekodeSchema } from './skattekode';
import { DaekningMedUdbetalingssikring } from './daekningMedUdbetalingssikring';
import { DaekningMedUdbetalingssikringSchema } from './daekningMedUdbetalingssikring';
import * as JoiObj from "joi";


    export const DaekningMedUdbetalingssikringMedIndfasningSchema = JoiObj.object({
        skattekode:
            
        JoiObj.link('#SkattekodeSchema')


.required()


        ,
        udbetalingsforloebId:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        udbetalingssikringsYdelse:
            
                    JoiObj.number()
                

.allow(null)



        ,
        forventetSikretAndel:
            
                    JoiObj.number()
                

.allow(null)



        ,
        udbetalingStartDato:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        
    })
                .shared(SkattekodeSchema)
.id('DaekningMedUdbetalingssikringMedIndfasningSchema')

export interface DaekningMedUdbetalingssikringMedIndfasning extends DaekningMedUdbetalingssikring { 
    forventetSikretAndel?: number;
    udbetalingStartDato: string;
}
export namespace DaekningMedUdbetalingssikringMedIndfasning {
}


