/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Skattekode } from './skattekode';
import { SkattekodeSchema } from './skattekode';
import { DigitalSalgPrisListeRaekkeMaanedligprisType } from './digitalSalgPrisListeRaekkeMaanedligprisType';
import { DigitalSalgPrisListeRaekkeMaanedligprisTypeSchema } from './digitalSalgPrisListeRaekkeMaanedligprisType';
import { DigitalSalgPrisListeRaekkeUdbetalingType } from './digitalSalgPrisListeRaekkeUdbetalingType';
import { DigitalSalgPrisListeRaekkeUdbetalingTypeSchema } from './digitalSalgPrisListeRaekkeUdbetalingType';
import * as JoiObj from "joi";


    export const DigitalSalgPrisListeRaekkeSchema = JoiObj.object({
        alder:
            
                    JoiObj.number()
                


.required()


        ,
        daekningId:
            
                    JoiObj.number()
                


.required()


        ,
        maanedligPris:
            
                    JoiObj.number()
                


.required()


        ,
        maanedligPrisTekstId:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        maanedligpristype:
            
        JoiObj.link('#DigitalSalgPrisListeRaekkeMaanedligprisTypeSchema')


.required()


        ,
        skattekode:
            
        JoiObj.link('#SkattekodeSchema')


.required()


        ,
        udbetaling:
            
                    JoiObj.number()
                


.required()


        ,
        udbetalingTekstId:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        udbetalingtype:
            
        JoiObj.link('#DigitalSalgPrisListeRaekkeUdbetalingTypeSchema')


.required()


        
    })
                .shared(DigitalSalgPrisListeRaekkeMaanedligprisTypeSchema)
                .shared(SkattekodeSchema)
                .shared(DigitalSalgPrisListeRaekkeUdbetalingTypeSchema)
.id('DigitalSalgPrisListeRaekkeSchema')

export interface DigitalSalgPrisListeRaekke { 
    alder: number;
    daekningId: number;
    maanedligPris: number;
    maanedligPrisTekstId: string;
    maanedligpristype: DigitalSalgPrisListeRaekkeMaanedligprisType;
    skattekode: Skattekode;
    udbetaling: number;
    udbetalingTekstId: string;
    udbetalingtype: DigitalSalgPrisListeRaekkeUdbetalingType;
}
export namespace DigitalSalgPrisListeRaekke {
}


