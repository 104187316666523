/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const PrivatIndskudApproveRequestObjSchema = JoiObj.object({
        mitIdSig:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        revisionKey:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        
    })
.id('PrivatIndskudApproveRequestObjSchema')

export interface PrivatIndskudApproveRequestObj { 
    mitIdSig: string;
    revisionKey: string;
}

