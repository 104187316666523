import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup
} from '@angular/forms';
import { ConsentsApiService, SaveConsentDTO } from '@pfa/api';
import { ConsentTrackingService, ContentService } from '@pfa/core';
import { cloneDeep } from 'lodash';
import { Observable, Subscription } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { ConsentsConfigurationService } from './consents-configuration.service';
import {
  ChannelsConfigDictionary,
  ConsentConfiguration,
  ConsentState,
  RefinedConsentInfo
} from './consent.model';
import { MatDialog } from '@angular/material/dialog';
import {
  ConfirmationModalComponent,
  ConfirmationModalConfiguration
} from './confirmation-modal/confirmation-modal.component';
import { GlobalWorkingService } from '../global-working/global-working.service';
import { ConsentInfo } from '@pfa/gen';
import { PdfPopupService } from '../show-pdf/pdfPopup.service';
import {
  NotificationService,
  NotificationType
} from '../../services/notification.service';

@Component({
  selector: 'co-consent',
  templateUrl: './consent.component.html',
  styleUrls: ['./consent.component.scss']
})
export class ConsentComponent implements OnInit, OnDestroy {
  @Input()
  set consent(value: RefinedConsentInfo) {
    this._consent = value;
    this.consentConfig =
      this.consentsConfigurationService.consentsConfig[this.consent.type];
    this.isGiven = value.state === ConsentState.GIVEN;
    this.isRetiredConsentGiven = this.consent.retiredConsent
      ? this.consent.retiredConsent.state === ConsentState.GIVEN
      : false;
    this.saveButtonTextId =
      this.consent.state === ConsentState.GIVEN
        ? 'DL.ST01.C104'
        : 'DL.ST01.C103';

    this.initializeConsentsForm(value);
  }

  get consent(): RefinedConsentInfo {
    return this._consent;
  }

  @Input() customerEmail: string;
  @Input() customerMobile: string;
  @Input() useWrapper = true;

  @Output() consentValueChange: EventEmitter<ConsentInfo[]> = new EventEmitter<
    ConsentInfo[]
  >();

  allChecked$: Observable<boolean>;
  someChecked$: Observable<boolean>;
  isSaveButtonActive$: Observable<boolean>;

  consentForm: UntypedFormGroup;
  saveButtonTextId: string;
  isGiven: boolean;
  isEnglish: boolean;
  isRetiredConsentGiven: boolean;
  channelControls: { [key: string]: AbstractControl };
  channelLabels: ChannelsConfigDictionary;
  consentConfig: ConsentConfiguration;

  private _consent: RefinedConsentInfo;
  private originalData = null;

  private readonly subs: Subscription = new Subscription();

  constructor(
    private readonly consentsConfigurationService: ConsentsConfigurationService,
    private readonly contentService: ContentService,
    private readonly consentsApiService: ConsentsApiService,
    private readonly globalWorkingService: GlobalWorkingService,
    private readonly pdfPopupService: PdfPopupService,
    private readonly trackingService: ConsentTrackingService,
    private readonly dialog: MatDialog,
    private readonly notificationService: NotificationService
  ) {}

  ngOnInit() {
    this.isEnglish = this.contentService.getLanguage() === 'en';
    this.channelLabels = this.consentsConfigurationService.channelsConfig;
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  get isSaveButtonActive(): boolean {
    if (this.consent.state !== ConsentState.GIVEN) {
      return true;
    } else {
      return false;
    }
  }

  get allChecked(): boolean {
    return Object.values(this.channelControls).every(consent => consent.value);
  }

  get someChecked(): boolean {
    return (
      Object.values(this.channelControls).some(consent => consent.value) &&
      !this.allChecked
    );
  }

  get channelsKeys(): string[] {
    return Object.keys(this.channelControls);
  }

  toggleAll() {
    const newValue = !this.allChecked;
    Object.values(this.channelControls).forEach(control => {
      control.setValue(newValue);
      control.markAsDirty();
    });
  }

  save() {
    this.globalWorkingService.show();
    const isSignup = this.consent.state !== ConsentState.GIVEN;
    const saveConsentData: SaveConsentDTO = {
      type: this.consent.type,
      state: 'GIVEN',
      version: this.consent.version,
      channels: Object.entries(this.channelControls).map(entry => ({
        channelKey: entry[0],
        given: isSignup ? true : entry[1].value
      }))
    };

    this.trackingService.trackConsentSubscribed(this.consent.type);
    this.consentsApiService.updateConsent(saveConsentData).subscribe({
      next: response => {
        this.globalWorkingService.hide();
        this.consentValueChange.emit(response.consents);
      },
      error: () => {
        this.globalWorkingService.hide();
        this.notificationService.showNotification({
          message: undefined,
          type: NotificationType.ERROR
        });
      }
    });
  }

  revoke(isRevokeRetired: boolean = false) {
    const revokeModalConfig = this.consentConfig.revokeModal;
    const data: ConfirmationModalConfiguration = {
      title: revokeModalConfig.header,
      contents: revokeModalConfig.contents,
      confirmButtonLabel: revokeModalConfig.confirm,
      cancelButtonLabel: revokeModalConfig.cancel
    };
    const sub = this.dialog
      .open(ConfirmationModalComponent, { data, maxWidth: 500 })
      .afterClosed()
      .pipe(
        filter(result => result),
        switchMap(() => {
          this.globalWorkingService.show();
          const saveConsentData: SaveConsentDTO = {
            type: this.consent.type,
            state: 'REVOKED',
            version: isRevokeRetired
              ? this.consent.retiredConsent.version
              : this.consent.version,
            channels: []
          };
          this.trackingService.trackConsentUnsubscribed(this.consent.type);
          return this.consentsApiService.updateConsent(saveConsentData);
        })
      )
      .subscribe({
        next: response => {
          this.globalWorkingService.hide();
          this.consentValueChange.emit(response.consents);
        },
        error: () => {
          this.globalWorkingService.hide();
          this.notificationService.showNotification({
            message: undefined,
            type: NotificationType.ERROR
          });
        }
      });
    this.subs.add(sub);
  }

  public showRetiredConsentPdf() {
    this.pdfPopupService.showPdfPopup(
      '/ds/api/pensionskunde/consentreceipt/' + this.consent.retiredConsent.guid
    );
  }

  private initializeConsentsForm(consent: ConsentInfo): void {
    this.consentForm = new UntypedFormGroup({
      channels: consent.channels.reduce((form, channel) => {
        form.addControl(
          channel.channelKey,
          new UntypedFormControl(channel.given)
        );
        return form;
      }, new UntypedFormGroup({}))
    });
    this.channelControls = (
      this.consentForm.controls.channels as UntypedFormGroup
    ).controls;
    this.originalData = cloneDeep(this.consentForm.getRawValue());
  }
}
