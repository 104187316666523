/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const StatusOrderSchema = JoiObj.string().valid(
        'CANCELLED'
        ,
        'COMPLETED'
        ,
        'ERROR'
        ,
        'REJECTED'
        ,
        'OPEN'
        ,
        'CLOSED'
        
    )
.id('StatusOrderSchema')

export type StatusOrder = 'CANCELLED' | 'COMPLETED' | 'ERROR' | 'REJECTED' | 'OPEN' | 'CLOSED';

export const StatusOrder = {
    Cancelled: 'CANCELLED' as StatusOrder,
    Completed: 'COMPLETED' as StatusOrder,
    Error: 'ERROR' as StatusOrder,
    Rejected: 'REJECTED' as StatusOrder,
    Open: 'OPEN' as StatusOrder,
    Closed: 'CLOSED' as StatusOrder
};

