/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RisikoYdelse } from './risikoYdelse';
import { RisikoYdelseSchema } from './risikoYdelse';
import { ServiceInfo } from './serviceInfo';
import { ServiceInfoSchema } from './serviceInfo';
import { ResourceModel } from './resourceModel';
import { ResourceModelSchema } from './resourceModel';
import { DaekningsType } from './daekningsType';
import { DaekningsTypeSchema } from './daekningsType';
import * as JoiObj from "joi";


    export const RisikoTabelLinieSchema = JoiObj.object({
        serviceInfo:
            
        JoiObj.link('#ServiceInfoSchema')

.allow(null)



        ,
        antalUdbetalingsAar:
            
                    JoiObj.number()
                


.required()


        ,
        daekningsType:
            
        JoiObj.link('#DaekningsTypeSchema')


.required()


        ,
        skattefri:
            
                    JoiObj.boolean()
                


.required()


        ,
        udbetalingSlutAlder:
            
                    JoiObj.number()
                


.required()


        ,
        udbetalingSlutDato:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        udbetalingStartAlder:
            
                    JoiObj.number()
                


.required()


        ,
        udbetalingStartDato:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        underUdbetaling:
            
                    JoiObj.boolean()
                


.required()


        ,
        ydelser:
            
        JoiObj.object().pattern(/./,     
        JoiObj.link('#RisikoYdelseSchema')

.allow(null)


)


.required()


        
    })
                .shared(ServiceInfoSchema)
                .shared(DaekningsTypeSchema)
                .shared(RisikoYdelseSchema)
.id('RisikoTabelLinieSchema')

export interface RisikoTabelLinie extends ResourceModel { 
    antalUdbetalingsAar: number;
    daekningsType: DaekningsType;
    skattefri: boolean;
    udbetalingSlutAlder: number;
    udbetalingSlutDato: string;
    udbetalingStartAlder: number;
    udbetalingStartDato: string;
    underUdbetaling: boolean;
    ydelser: { [key: string]: RisikoYdelse; };
}
export namespace RisikoTabelLinie {
}


