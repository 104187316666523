<div class="logon--container">
  <div class="logo-wrap">
    <co-icon-logo class="header--logo"></co-icon-logo>
  </div>
  <div
    *ngIf="showDoorman !== undefined && showDoorman"
    class="login--wrapper doorman"
  >
    <div class="doorman--section">
      <h2 [coContent]="'DL.LI01.C124'"></h2>
      <p [coContent]="'DL.LI01.C125'"></p>
    </div>
    <hr class="doorman--line" />
    <div class="doorman--section">
      <h2 [coContent]="'DL.LI01.C132'"></h2>
      <p [coContent]="'DL.LI01.C133'"></p>
    </div>
    <div class="shadow--box"></div>
  </div>
  <div *ngIf="showDoorman !== undefined && !showDoorman">
    <div class="menu--item--container">
      <div
        class="menu--item"
        [coContent]="'DL.MI01.C19'"
        [ngClass]="{ selected: displayedLoginMethod === LoginMethod.MIT_ID }"
        (click)="switchTab(LoginMethod.MIT_ID)"
      ></div>
      <div
        class="menu--item"
        [coContent]="'DL.LI01.C95'"
        [ngClass]="{ selected: displayedLoginMethod === LoginMethod.PIN_CODE }"
        (click)="switchTab(LoginMethod.PIN_CODE)"
      ></div>
    </div>
    <div
      class="login--wrapper"
      [ngClass]="{
        'mitid-wrapper': displayedLoginMethod === LoginMethod.MIT_ID
      }"
      [ngSwitch]="displayedLoginMethod"
    >
      <div
        class="login"
        [ngClass]="{
          'mitid-center': displayedLoginMethod === LoginMethod.MIT_ID
        }"
        [attr.data-test-id]="displayedLoginMethodTestId"
        [class.login--mitid]="displayedLoginMethod === LoginMethod.MIT_ID"
      >
        <mitpfa-mitid-login
          *ngSwitchCase="LoginMethod.MIT_ID"
          [mitidError]="mitidError"
          [mitidErrorDescription]="mitidErrorDescription"
        ></mitpfa-mitid-login>
        <mitpfa-pincode-login
          *ngSwitchCase="LoginMethod.PIN_CODE"
          (step)="updatePinCodeLoginStep($event)"
        >
        </mitpfa-pincode-login>
        <div class="shadow--box"></div>
      </div>
      <div class="mobile-language-switch hidden-desktop">
        <div>
          <a
            class="language-selector__link"
            coContent="DL.FO01.C422"
            (click)="switchLanguage()"
            [attr.data-test-id]="
              isDanish ? 'FO01-language-mobile-EN' : 'FO01-language--mobile-DA'
            "
          ></a>
        </div>
      </div>
      <div class="library-warning" *ngSwitchCase="LoginMethod.MIT_ID">
        <p><b [coContent]="'DL.MI01.C22'"></b></p>
        <p [coContent]="'DL.MI01.C23'"></p>
      </div>

      <ng-container *ngSwitchCase="LoginMethod.PIN_CODE">
        <div class="info" *ngIf="isPinStep">
          <div class="text_box">
            <p><b [coContent]="'DL.MI01.C15'"></b></p>
            <div
              class="text spacing-unit--half-over"
              [coContent]="'DL.MI01.C16'"
            ></div>
            <div
              class="text spacing-unit--half-over"
              [coContent]="'DL.MI01.C17'"
            ></div>
            <div class="text spacing-unit--over">
              <p><b [coContent]="'DL.MI01.C22'"></b></p>
              <p [coContent]="'DL.MI01.C23'"></p>
            </div>
          </div>
        </div>
        <div class="info" *ngIf="isOtpStep">
          <div class="text_box">
            <p><b [coContent]="'DL.LI01.C130'"></b></p>
            <p class="text" [coContent]="'DL.LI01.C131'"></p>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
<div class="footer--wrapper">
  <mitpfa-logon-footer
    class="limited-width footer--spacing"
    (switchLanguage)="setLanguage($event)"
    [isDanish]="isDanish"
  >
  </mitpfa-logon-footer>
</div>
