/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ServiceInfo } from './serviceInfo';
import { ServiceInfoSchema } from './serviceInfo';
import { ResourceModel } from './resourceModel';
import { ResourceModelSchema } from './resourceModel';
import * as JoiObj from "joi";


    export const AkkAfkastLinieSchema = JoiObj.object({
        serviceInfo:
            
        JoiObj.link('#ServiceInfoSchema')

.allow(null)



        ,
        afkastBeloeb:
            
                    JoiObj.number()
                


.required()


        ,
        colorCode:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        contentId:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        dato:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        kundekapitalAar:
            
                    JoiObj.number()
                .integer()

.allow(null)



        ,
        noteKode:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        noteText:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        notes:
                JoiObj.array().items(
        
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

        )
        

.required()


        ,
        opsparing:
            
                    JoiObj.number()
                


.required()


        ,
        toolTip:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        
    })
                .shared(ServiceInfoSchema)
.id('AkkAfkastLinieSchema')

export interface AkkAfkastLinie extends ResourceModel { 
    afkastBeloeb: number;
    colorCode?: string;
    contentId?: string;
    dato?: string;
    kundekapitalAar?: number;
    noteKode?: string;
    noteText?: string;
    notes: Array<string>;
    opsparing: number;
    toolTip?: string;
}

