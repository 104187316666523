/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DynamicAdvisingBasisValues } from './dynamicAdvisingBasisValues';
import { DynamicAdvisingBasisValuesSchema } from './dynamicAdvisingBasisValues';
import { DynamicAdvisingOfferGroup } from './dynamicAdvisingOfferGroup';
import { DynamicAdvisingOfferGroupSchema } from './dynamicAdvisingOfferGroup';
import * as JoiObj from "joi";


    export const DynamicAdvisingCalculationSchema = JoiObj.object({
        basisValues:
            
        JoiObj.link('#DynamicAdvisingBasisValuesSchema')


.required()


        ,
        nbaId:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        offerGroups:
                JoiObj.array().items(
        
        JoiObj.link('#DynamicAdvisingOfferGroupSchema')

        )
        

.required()


        
    })
                .shared(DynamicAdvisingBasisValuesSchema)
                .shared(DynamicAdvisingOfferGroupSchema)
.id('DynamicAdvisingCalculationSchema')

export interface DynamicAdvisingCalculation { 
    basisValues: DynamicAdvisingBasisValues;
    nbaId: string;
    offerGroups: Array<DynamicAdvisingOfferGroup>;
}

