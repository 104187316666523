/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Skattekode } from './skattekode';
import { SkattekodeSchema } from './skattekode';
import * as JoiObj from "joi";


    export const AdvancedPayoutChoicePensionsInfoPolicySchema = JoiObj.object({
        companyText:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        payoutAge:
            
                    JoiObj.number()
                


.required()


        ,
        payoutDate:
            
            JoiObj.date()


.required()


        ,
        payoutPeriod:
            
                    JoiObj.number()
                


.required()


        ,
        policyId:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        taxCode:
            
        JoiObj.link('#SkattekodeSchema')


.required()


        
    })
                .shared(SkattekodeSchema)
.id('AdvancedPayoutChoicePensionsInfoPolicySchema')

export interface AdvancedPayoutChoicePensionsInfoPolicy { 
    companyText: string;
    payoutAge: number;
    payoutDate: Date;
    payoutPeriod: number;
    policyId: string;
    taxCode: Skattekode;
}
export namespace AdvancedPayoutChoicePensionsInfoPolicy {
}


