<mat-dialog-content>
  <h4 coContent="DL.ST01.C237"></h4>
  <p coContent="DL.ST01.C238"></p>

  <co-customer-contact-details
    #contactDetailsComponent
    [phoneRequired]="true"
    [emailRequired]="true"
    [pensionCustomer]="pensionCustomer"
    (contactDetailsUpdate)="close()"
    class="show-form-only spacing-unit--over device-mode"
  ></co-customer-contact-details>

  <button
    mat-raised-button
    class="button-secondary spacing-unit--over"
    (click)="contactDetailsComponent.saveContactDetails(); trackPopupCta()"
    [disabled]="contactDetailsComponent.contactDetailsForm?.invalid"
    coContent="DL.ST01.C239"
  ></button>
</mat-dialog-content>
