<div
  class="card-head text--bold"
  [coContent]="!detail.current.onlyYouInvest ? 'DL.INV.C669' : 'DL.INV.C701'"
></div>

<div class="ribbon" coContent="DL.INV.C665"></div>

<div class="recommend">
  <div *ngIf="!detail.current.onlyYouInvest" class="recommend-current">
    <div class="recommend-body">
      <div class="recommend-head text--bold" coContent="DL.INV.C654"></div>

      <mitpfa-investment-recommendation-climate-detail
        [detail]="detail"
      ></mitpfa-investment-recommendation-climate-detail>
    </div>

    <div class="recommend-btn">
      <div *ngIf="confirmedDate" class="justify-center-flex text--note">
        {{ 'DL.INV.C718' | content }} {{ confirmedDate | date: 'dd.MM.yyyy' }}
      </div>
      <button
        mat-raised-button
        class="button-secondary"
        coContent="DL.INV.C658"
        (click)="keepClimate()"
      ></button>
    </div>
  </div>

  <div class="recommend-new">
    <div class="recommend-body">
      <div class="recommend-head text--bold" coContent="DL.INV.C656"></div>

      <mitpfa-investment-recommendation-climate-detail
        [detail]="detail"
        [recommendation]="true"
      ></mitpfa-investment-recommendation-climate-detail>
    </div>

    <div
      *ngIf="!detail.current.onlyYouInvest"
      class="align--center spacing-unit--over"
    >
      <button
        mat-raised-button
        coContent="DL.INV.C659"
        [disabled]="
          detail.current.climatePercent === detail.recommendation.climatePercent
        "
        (click)="followRecommendation()"
      ></button>
    </div>
  </div>
</div>

<div class="btn-choose align--center">
  <button
    mat-raised-button
    class="button-primary--level3"
    (click)="
      !detail.current.onlyYouInvest ? changeClimate() : emitNextCard.emit()
    "
  >
    <co-icon-slider
      *ngIf="!detail.current.onlyYouInvest"
      class="icon"
    ></co-icon-slider>
    <span
      [coContent]="
        !detail.current.onlyYouInvest ? 'DL.INV.C664' : 'DL.INVGU01.C186'
      "
    ></span>
    <co-icon-arrow-right
      *ngIf="detail.current.onlyYouInvest"
      class="icon icon--arrow-right"
    ></co-icon-arrow-right>
  </button>
</div>
