<co-loading [show]="!initialized"></co-loading>

<div class="row">
  <div class="col col-md-6">
    <div class="policiesWithError" *ngIf="policiesWithError">
      <co-error>
        <span [coContent]="'DL.FS03.C43'">
          Pga. tekniske problemer kan der være fejl i visning...
        </span>
      </co-error>
    </div>
  </div>
</div>
<div class="row" *ngIf="initialized" data-test-id="frontpage-insurance">
  <co-card
    *ngFor="let item of insuranceList"
    (click)="goToInsurance(item.navigationAction)"
    attr.data-test-id="{{ 'frontpage-insurance-' + item.testId }}"
    class="col-12 col-md-6 cursor-pointer max-width card--slim horizontal-align-center"
  >
    <co-insurance-card-header
      [headerContent]="item.headerContent"
      [headerNoteContent]="item.headerNoteContent"
      [iconForward]="true"
      class="slim"
    ></co-insurance-card-header>
  </co-card>
</div>
