import { Injectable } from '@angular/core';
import { TrackingService } from './tracking.service';

@Injectable()
export class PensionEstimateTrackingService extends TrackingService {
  private readonly trackPensionEstimateAction = 'track-pension-estimate-action';

  public trackChangeRetirementAge(): void {
    this.dispatchCustomEventTracking(this.trackPensionEstimateAction, {
      pensionEstimateAction: 'change retirement age'
    });
  }

  public trackChangeMonthlyAmount(): void {
    this.dispatchCustomEventTracking(this.trackPensionEstimateAction, {
      pensionEstimateAction: 'change monthly amount'
    });
  }

  public trackSaveChanges(): void {
    this.dispatchCustomEventTracking(this.trackPensionEstimateAction, {
      pensionEstimateAction: 'save changes'
    });
  }

  public trackToggleAnnualPaymentEquities(): void {
    this.dispatchCustomEventTracking(this.trackPensionEstimateAction, {
      pensionEstimateAction: 'change annual payment - equities'
    });
  }

  public trackToggleAnnualPaymentInvestedInBonds(): void {
    this.dispatchCustomEventTracking(this.trackPensionEstimateAction, {
      pensionEstimateAction: 'change annual payment - bonds'
    });
  }

  public trackToggleAnnualPaymentInCash(): void {
    this.dispatchCustomEventTracking(this.trackPensionEstimateAction, {
      pensionEstimateAction: 'change annual payment - cash'
    });
  }

  public trackToggleLumpSumBenefits(): void {
    this.dispatchCustomEventTracking(this.trackPensionEstimateAction, {
      pensionEstimateAction: 'change lump sum benefit'
    });
  }

  public trackSaveChangesExtended(): void {
    this.dispatchCustomEventTracking(this.trackPensionEstimateAction, {
      pensionEstimateAction: 'save changes - extended'
    });
  }
}
