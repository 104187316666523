import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
  OnDestroy
} from '@angular/core';
import { OekonomiDetaljer, Bolig, PensionsKundeGenerelleData } from '@pfa/gen';
import {
  UntypedFormGroup,
  UntypedFormControl,
  UntypedFormArray,
  Validators,
  UntypedFormBuilder
} from '@angular/forms';
import { FinancialSituationConfigurationService } from '../services/financial-situation-configuration.service';
import { numberAsStringValidator } from '@pfa/utils';
import { Subscription } from 'rxjs';

@Component({
  selector: 'mitpfa-financial-situation-real-estate',
  templateUrl: './financial-situation-real-estate.component.html',
  styleUrls: ['./financial-situation-real-estate.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FinancialSituationRealEstateComponent
  implements OnInit, OnDestroy
{
  @Input() financialSituation: OekonomiDetaljer;
  @Input() pensionCustomer: PensionsKundeGenerelleData;
  @Input() withAdvisorMenu = true;
  @Input() withOlderCustomerSection = true;
  @Input() form: UntypedFormGroup;
  @Input() isInInsuranceGuide = false;

  public realEstateForm: UntypedFormGroup;
  private subscriptions: { [subscribtionKey: string]: Subscription } = {};

  get realEstates(): UntypedFormArray {
    return this.realEstateForm.get('realEstates') as UntypedFormArray;
  }

  constructor(
    readonly financialSituationConfigurationService: FinancialSituationConfigurationService,
    readonly formBuilder: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    this.initializeForm();
  }

  ngOnDestroy(): void {
    Object.keys(this.subscriptions).forEach(
      key => this.subscriptions[key]?.unsubscribe()
    );
  }

  private initializeForm(): void {
    this.realEstateForm = this.formBuilder.group({
      isAnyRealEstate: [
        this.financialSituation.frieMidler.boligValgt,
        Validators.required
      ],
      realEstates: this.formBuilder.array(
        this.financialSituation.frieMidler.boliger?.length
          ? this.financialSituation.frieMidler.boliger.map(
              this.getNewRealEstateFormGroup.bind(this)
            )
          : this.financialSituation.frieMidler.boligValgt
            ? [this.getNewRealEstateFormGroup()]
            : []
      )
    });

    if (!this.realEstateForm.controls.isAnyRealEstate.value) {
      this.realEstates.disable();
    }

    this.subscriptions.onIsAnyRealEstateChange =
      this.realEstateForm.controls.isAnyRealEstate.valueChanges.subscribe(
        newValue => {
          if (newValue) {
            this.realEstates.enable();
            if (!this.realEstates.controls.length) {
              this.addNewRealEstate();
            }
          } else {
            this.realEstates.disable();
          }
        }
      );

    if (this.form) {
      this.form.addControl('realEstate', this.realEstateForm);
    }
  }

  private getNewRealEstateFormGroup(data?: Bolig): UntypedFormGroup {
    const currencyValidators = [
      Validators.min(0),
      Validators.maxLength(9),
      Validators.required,
      numberAsStringValidator()
    ];
    const formGroup = this.formBuilder.group({
      typeOfProperty: [
        data?.ejendomArt ||
          this.financialSituationConfigurationService.propertyTypes[0].value,
        Validators.required
      ],
      name: [
        data?.ejendomNavn || '',
        [Validators.maxLength(50), Validators.required]
      ],
      saleValueToday: [Math.round(data?.salgsvaerdi || 0), currencyValidators],
      presentDebt: [Math.round(data?.gaeldIdag || 0), currencyValidators],
      yourShareOfResidence: [
        data?.ejerAndel ?? 100,
        [
          Validators.max(100),
          Validators.min(0),
          numberAsStringValidator(),
          Validators.required
        ]
      ],
      isNew: [!data],
      advisor: this.formBuilder.group({
        interestForm: [
          data?.renteForm ||
            this.financialSituationConfigurationService.interestFormDefaultType
        ],
        loanRepayments: [
          data?.afdrag ||
            this.financialSituationConfigurationService
              .loanRepaymentsDefaultType
        ]
      })
    });

    if (
      !this.isInInsuranceGuide &&
      (this.pensionCustomer.alder >=
        this.financialSituationConfigurationService.age ||
        this.withOlderCustomerSection)
    ) {
      formGroup.addControl(
        'debtAtTimeOfRetirement',
        new UntypedFormControl(
          Math.round(data?.gaeldVedPension || 0),
          currencyValidators
        )
      );

      formGroup.addControl(
        'includedInRetirement',
        new UntypedFormControl(data?.andelFrivaerdi ?? 50, Validators.required)
      );
    }

    if (!this.pensionCustomer.isRaadgiver) {
      formGroup.controls.advisor.disable();
    }

    if (formGroup.get('name').invalid) {
      formGroup.get('name').updateValueAndValidity();
      formGroup.get('name').markAsDirty();
    }

    return formGroup;
  }

  public removeRealEstate(index: number): void {
    this.realEstates.removeAt(index);
    if (this.realEstates.controls.length === 0) {
      this.realEstateForm.controls.isAnyRealEstate.setValue(false);
    }
    this.realEstates.markAsDirty();
    this.realEstates.markAsTouched();
  }

  public addNewRealEstate(): void {
    this.realEstates.push(this.getNewRealEstateFormGroup());
  }

  public trackByFn(index: number): number {
    return index;
  }
}
