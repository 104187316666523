/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const AdvisorCorrectionsOverfoerselSchema = JoiObj.object({
        aldersopsparing:
            
                    JoiObj.number()
                

.allow(null)



        ,
        indskudArbejdsgiver:
            
                    JoiObj.number()
                

.allow(null)



        ,
        kapitalpension:
            
                    JoiObj.number()
                

.allow(null)



        ,
        livsvarigLivrente:
            
                    JoiObj.number()
                

.allow(null)



        ,
        primaerPolicenr:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        ratepension:
            
                    JoiObj.number()
                

.allow(null)



        ,
        tidsbegraensetLivspension:
            
                    JoiObj.number()
                

.allow(null)



        
    })
.id('AdvisorCorrectionsOverfoerselSchema')

export interface AdvisorCorrectionsOverfoersel { 
    aldersopsparing?: number;
    indskudArbejdsgiver?: number;
    kapitalpension?: number;
    livsvarigLivrente?: number;
    primaerPolicenr?: string;
    ratepension?: number;
    tidsbegraensetLivspension?: number;
}

