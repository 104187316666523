import { Injectable, inject } from '@angular/core';
import {
  InvestmentConcept,
  TradeType,
  LossAbility,
  Savings,
  Residences,
  EconomicImpact,
  RiskWillingness,
  RiskProfile,
  ClimatePlus,
  ClimateExtendedFocus,
  ClimateWillingness,
  ClimatePriority,
  ClimateRelevance,
  Debt
} from '@pfa/gen';
import { ContentPipe, CurrencyFormatPipe } from '@pfa/core';
import { overviewTexts } from './recommendation-overview.texts';
import {
  OverviewContent,
  InvestmentGuideOverviewQuestion
} from './recommendation-overview.model';

@Injectable()
export class InvestmentRecommendationOverviewService {
  private readonly currencyPipe: CurrencyFormatPipe =
    inject(CurrencyFormatPipe);
  private readonly contentPipe: ContentPipe = inject(ContentPipe);

  public getOverviewContent({
    recommendation,
    recommendationBasis
  }): OverviewContent {
    const {
      calculatedClimatePlus,
      calculatedInvestmentConcept,
      calculatedLossAbility,
      calculatedRiskWilingness
    } = recommendation;

    const {
      conceptChoice,
      investmentKnowledge,
      tradingExperience,
      moreThan10Trades,
      secondaryResidences,
      primaryResidences,
      savings,
      debt,
      economicImpact,
      riskWillingness,
      lossAbility,
      chosenRiskProfile,
      climateRelevance,
      climateExtendedFocus,
      climatePriority,
      climateWillingness
    } = recommendationBasis;

    return {
      involvement: {
        title: overviewTexts.involvement.title,
        description: this.getInvolvementDescriptionContent(
          calculatedInvestmentConcept
        ),
        questions: [
          this.getConceptChoiceQuestion(conceptChoice),
          this.getInvestmentKnowledgeQuestion(investmentKnowledge),
          this.getTradingExperienceQuestion(tradingExperience),
          this.getPastTradesQuestion(moreThan10Trades, tradingExperience)
        ].filter(this.getQuestionDisplay)
      },
      finances: {
        title: overviewTexts.finances.title,
        description: this.getFinancesDescriptionContent(calculatedLossAbility),
        questions: [
          this.getAllYearResidenceQuestion(primaryResidences),
          this.getHolidayResidenceQuestion(secondaryResidences),
          this.getOtherSavingsQuestion(savings),
          this.getLoansQuestion(debt),

          this.getEconomicImpactQuestion(economicImpact)
        ].filter(this.getQuestionDisplay),
        indicator: {
          display: !!calculatedLossAbility,
          label: overviewTexts.finances.indicator.label,
          labelMin: overviewTexts.finances.indicator.labelMin,
          labelMax: overviewTexts.finances.indicator.labelMax,
          level: {
            MINIMAL_LOSS: 20,
            AVOID_LOSS: 50,
            ACCEPT_LOSS: 100
          }[calculatedLossAbility]
        }
      },
      risk: {
        title: overviewTexts.risk.title,
        description: this.getRiskDescriptionContent(riskWillingness),
        questions: [
          this.getRiskWillingnessQuestion(riskWillingness),
          this.getLossAbilityQuestion(lossAbility),
          this.getRiskProfileQuestion(chosenRiskProfile)
        ].filter(this.getQuestionDisplay),
        indicator: {
          display: !!calculatedRiskWilingness,
          label: overviewTexts.risk.indicator.label,
          labelMin: overviewTexts.risk.indicator.labelMin,
          labelMax: overviewTexts.risk.indicator.labelMax,
          level: {
            LOW: 20,
            AVERAGE: 50,
            HIGH: 100
          }[calculatedRiskWilingness]
        }
      },
      climate: {
        title: overviewTexts.climate.title,
        description: this.getClimateDescriptionContent(calculatedClimatePlus),
        questions: [
          this.getClimateRelevanceQuestion(climateRelevance),
          this.getClimateExtendedFocusQuestion(climateExtendedFocus),
          this.getClimateWillingnessQuestion(climateWillingness),
          this.getClimatePriorityQuestion(climatePriority)
        ].filter(this.getQuestionDisplay)
      }
    };
  }

  public getLoansQuestion(
    debt: Debt | undefined
  ): InvestmentGuideOverviewQuestion {
    const answer = [debt?.hasDebt ? overviewTexts.yes : overviewTexts.no];

    if (debt?.hasDebt) {
      answer.push(
        `${this.contentPipe.transform(
          overviewTexts.finances.questions.loans.answers.currentDebt
        )}: ${this.currencyPipe.transform(debt.currentDebt)}`
      );
    }

    return {
      question: overviewTexts.finances.questions.loans.question,
      answer,
      display: debt !== undefined
    };
  }

  public getInvolvementDescriptionContent(investmentConcept: string): string {
    const involvementDescriptions: { [key in InvestmentConcept]: string } = {
      PFA_INVEST: overviewTexts.involvement.description.pfaInvest,
      YOU_INVEST: overviewTexts.involvement.description.youInvest
    };

    return involvementDescriptions[investmentConcept];
  }

  public getConceptChoiceQuestion(
    conceptChoice: InvestmentConcept[]
  ): InvestmentGuideOverviewQuestion {
    const pfaInvest = conceptChoice.includes('PFA_INVEST');
    const youInvest = conceptChoice.includes('YOU_INVEST');

    let answer;

    const possibleAnswers = overviewTexts.involvement.conceptChoice.answers;

    if (pfaInvest && youInvest) {
      answer = possibleAnswers.both;
    } else if (pfaInvest) {
      answer = possibleAnswers.pfaInvest;
    } else if (youInvest) {
      answer = possibleAnswers.youInvest;
    }

    return {
      answer: [answer],
      display: true,
      question: overviewTexts.involvement.conceptChoice.question
    };
  }

  public getInvestmentKnowledgeQuestion(
    investmentKnowledge: boolean
  ): InvestmentGuideOverviewQuestion {
    const text = overviewTexts.involvement.investmentKnowledge;
    return {
      answer: [investmentKnowledge ? overviewTexts.yes : overviewTexts.no],
      question: text.question,
      display: investmentKnowledge
    };
  }

  public getTradingExperienceQuestion(
    tradeTypes: TradeType[]
  ): InvestmentGuideOverviewQuestion {
    const text = overviewTexts.involvement.tradingExperience;

    let answer: string[];
    if (!tradeTypes || !tradeTypes.length) {
      answer = [text.answers.noExperience];
    } else {
      const tradeTypeNames: { [key in TradeType]: string } = {
        INVESTMENTCERTIFICATES: text.answers.investmentCertificates,
        SHARES: text.answers.shares,
        BONDS: text.answers.bonds,
        OTHERS: text.answers.others
      };
      answer = tradeTypes.map(
        (tradeType: TradeType) => tradeTypeNames[tradeType]
      );
    }

    return {
      answer,
      display: true,
      question: text.question
    };
  }

  public getPastTradesQuestion(
    moreThan10Trades: boolean,
    tradingExperience: TradeType[]
  ): InvestmentGuideOverviewQuestion {
    const text = overviewTexts.involvement.pastTrades;
    return {
      answer: [moreThan10Trades ? overviewTexts.yes : overviewTexts.no],
      display: tradingExperience?.length > 0,
      question: text.question
    };
  }

  public getFinancesDescriptionContent(
    calculatedLossAbility: LossAbility
  ): string {
    const financesDescriptions: { [key in LossAbility]: string } = {
      MINIMAL_LOSS: overviewTexts.finances.description.minimalLoss,
      AVOID_LOSS: overviewTexts.finances.description.avoidLoss,
      ACCEPT_LOSS: overviewTexts.finances.description.acceptLoss
    };
    return financesDescriptions[calculatedLossAbility];
  }

  public getOtherSavingsQuestion(
    savings: Savings | undefined
  ): InvestmentGuideOverviewQuestion {
    const text = overviewTexts.finances.questions.otherSavings;
    let answer = [overviewTexts.no];
    if (savings?.otherSavingsThanPension) {
      answer = [
        overviewTexts.yes,
        `${this.contentPipe.transform(
          text.answers.shares
        )}: ${this.currencyPipe.transform(savings.shares)}`,
        `${this.contentPipe.transform(
          text.answers.bonds
        )}: ${this.currencyPipe.transform(savings.bonds)}`,
        `${this.contentPipe.transform(
          text.answers.cash
        )}: ${this.currencyPipe.transform(savings.cash)}`
      ];
    }
    return {
      answer,
      display: savings !== undefined,
      question: text.question
    };
  }

  public getAllYearResidenceQuestion(
    residences?: Residences
  ): InvestmentGuideOverviewQuestion {
    const text = overviewTexts.finances.questions.allYearResidence;
    return this.getResidenceQuestion(text.question, residences);
  }

  public getHolidayResidenceQuestion(
    residences: Residences
  ): InvestmentGuideOverviewQuestion {
    const text = overviewTexts.finances.questions.holidayHouse;
    return this.getResidenceQuestion(text.question, residences);
  }

  public getEconomicImpactQuestion(
    economicImpact?: EconomicImpact
  ): InvestmentGuideOverviewQuestion {
    const economicImpactAnswers: { [key in EconomicImpact]: string } = {
      MINOR: overviewTexts.finances.questions.economicImpact.answers.minor,
      LARGE: overviewTexts.finances.questions.economicImpact.answers.large,
      VERY_LARGE:
        overviewTexts.finances.questions.economicImpact.answers.veryLarge
    };

    return {
      answer: economicImpact
        ? [economicImpactAnswers[economicImpact]]
        : undefined,
      display: economicImpact !== undefined,
      question: overviewTexts.finances.questions.economicImpact.question
    };
  }

  public getRiskDescriptionContent(riskWillingness: RiskWillingness): string {
    const riskDescriptions: { [key in RiskWillingness]: string } = {
      LOW: overviewTexts.risk.description.low,
      AVERAGE: overviewTexts.risk.description.average,
      HIGH: overviewTexts.risk.description.high
    };
    return riskDescriptions[riskWillingness];
  }

  public getRiskWillingnessQuestion(
    riskWillingness: RiskWillingness
  ): InvestmentGuideOverviewQuestion {
    const text = overviewTexts.risk.questions.willingness;
    const riskWillingnessAnswers: { [key in RiskWillingness]: string } = {
      LOW: text.answers.low,
      AVERAGE: text.answers.average,
      HIGH: text.answers.high
    };
    return {
      question: text.question,
      answer: [riskWillingnessAnswers[riskWillingness]],
      display: riskWillingness !== undefined
    };
  }

  public getRiskProfileQuestion(
    chosenRiskProfile: RiskProfile
  ): InvestmentGuideOverviewQuestion {
    const text = overviewTexts.risk.questions.profile;
    const riskProfileAnswers: { [key in RiskProfile]: string } = {
      LOW: text.answers.lowRisk,
      AVERAGE_LOW: text.answers.mediumRisk,
      AVERAGE: text.answers.highRisk
    };
    return {
      question: text.question,
      answer: [riskProfileAnswers[chosenRiskProfile]],
      display: chosenRiskProfile !== undefined
    };
  }

  public getLossAbilityQuestion(
    lossAbility: LossAbility
  ): InvestmentGuideOverviewQuestion {
    const text = overviewTexts.risk.questions.lossAbility;
    return {
      question: text.question,
      answer: [
        {
          ACCEPT_LOSS: text.answers.accept,
          MINIMAL_LOSS: text.answers.minimal,
          AVOID_LOSS: text.answers.avoid
        }[lossAbility]
      ],
      display: lossAbility !== undefined
    };
  }

  public getClimateDescriptionContent(
    calculatedClimatePlus: ClimatePlus
  ): string {
    const climateDescriptions: { [key in ClimatePlus]: string } = {
      CLIMATE_PLUS_NONE: overviewTexts.climate.description.none,
      CLIMATE_PLUS_LOW: overviewTexts.climate.description.low,
      CLIMATE_PLUS_MIDDLE: overviewTexts.climate.description.middle,
      CLIMATE_PLUS_HIGH: overviewTexts.climate.description.high
    };
    return climateDescriptions[calculatedClimatePlus];
  }

  public getClimateRelevanceQuestion(
    climateRelevance: ClimateRelevance
  ): InvestmentGuideOverviewQuestion {
    const text = overviewTexts.climate.questions.relevance;
    const climateRelevanceAnswers: { [key in ClimateRelevance]: string } = {
      LOW: text.answers.low,
      MEDIUM: text.answers.medium,
      HIGH: text.answers.high
    };
    return {
      question: text.question,
      answer: [climateRelevanceAnswers[climateRelevance]],
      display: climateRelevance !== undefined
    };
  }

  public getClimateExtendedFocusQuestion(
    climateExtendedFocus: ClimateExtendedFocus
  ): InvestmentGuideOverviewQuestion {
    const text = overviewTexts.climate.questions.extendedFocus;
    const climateFocusAnswers: { [key in ClimateExtendedFocus]: string } = {
      MINOR: text.answers.minor,
      AVERAGE: text.answers.average,
      HIGH: text.answers.high
    };
    return {
      question: text.question,
      answer: [climateFocusAnswers[climateExtendedFocus]],
      display: climateExtendedFocus !== undefined
    };
  }

  public getClimateWillingnessQuestion(
    climateWillingness: ClimateWillingness
  ): InvestmentGuideOverviewQuestion {
    const text = overviewTexts.climate.questions.willingness;
    const answers: { [key in ClimateWillingness]: string } = {
      LIMIT: text.answers.limit,
      MINOR: text.answers.minor,
      NEUTRAL: text.answers.neutral
    };
    return {
      question: text.question,
      answer: [answers[climateWillingness]],
      display: climateWillingness !== undefined
    };
  }

  public getClimatePriorityQuestion(
    climatePriority: ClimatePriority
  ): InvestmentGuideOverviewQuestion {
    const text = overviewTexts.climate.questions.priority;
    const answers: { [key in ClimatePriority]: string } = {
      HIGH_RETURN: text.answers.highReturn,
      CLIMATE_FRIENDLY: text.answers.climateFriendly,
      EQUALLY: text.answers.equally
    };
    return {
      question: text.question,
      answer: [answers[climatePriority]],
      display: climatePriority !== undefined
    };
  }

  private getResidenceQuestion(
    question: string,
    residences?: Residences
  ): InvestmentGuideOverviewQuestion {
    let answer: string[] = [overviewTexts.no];
    if (
      residences &&
      residences.realEstateAnswer &&
      residences.realEstates.length
    ) {
      const residence = residences.realEstates[0];
      answer = [
        overviewTexts.yes,
        `${this.contentPipe.transform(
          overviewTexts.finances.questions.allYearResidence.answers.salesValue
        )}: ${this.currencyPipe.transform(residence.currentMarketValue)}`,
        `${this.contentPipe.transform(
          overviewTexts.finances.questions.allYearResidence.answers.debt
        )}: ${this.currencyPipe.transform(residence.estateDebt)}`,
        `${this.contentPipe.transform(
          overviewTexts.finances.questions.allYearResidence.answers.ownersShare
        )}: ${residence.percentageOwned}%`
      ];
    }
    return {
      answer,
      display: residences !== undefined,
      question
    };
  }

  private getQuestionDisplay(
    question: InvestmentGuideOverviewQuestion
  ): boolean {
    return question.display;
  }
}
