import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MaeglerInfo } from '@pfa/gen';
import { InvestmentGuideInfo } from '@pfa/models';
import { InvestmentGuideQuestion } from '../../../investment-guide-configuration.service';
import { ClimateWillingnessChoice } from '../../investment-guide-climate-step.model';
import { InvestmentGuideService } from '@mitpfa/shared';

@Component({
  selector: 'mitpfa-investment-guide-climate-willingness',
  templateUrl: './investment-guide-climate-step-willingness.component.html',
  styleUrls: ['./investment-guide-climate-step-willingness.component.scss']
})
export class InvestmentGuideClimateWillingnessComponent implements OnInit {
  @Input() investmentGuide: InvestmentGuideInfo;
  @Input() broker: MaeglerInfo;

  answer: UntypedFormControl;
  choices: ClimateWillingnessChoice[] = [
    {
      text: 'DL.INVGU01.C558',
      value: 'LIMIT'
    },
    {
      text: 'DL.INVGU01.C559',
      value: 'MINOR'
    },
    {
      text: 'DL.INVGU01.C560',
      value: 'NEUTRAL'
    }
  ];

  constructor(readonly investmentGuideService: InvestmentGuideService) {}

  ngOnInit(): void {
    this.answer = new UntypedFormControl(
      this.investmentGuide.recommendationBasis.climateWillingness,
      Validators.required
    );
  }

  choose(): void {
    this.investmentGuide.recommendationBasis.climateWillingness =
      this.answer.value;
    this.investmentGuideService.updateCurrentQuestion({
      value: InvestmentGuideQuestion.climatePriority,
      update: true
    });
  }
}
