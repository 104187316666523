import {
  EksternOverfoerselsType,
  OpsparingType,
  TransferableCategoryType
} from '@pfa/gen';

export interface CombineSavingsNavigationEvent {
  from: CombineSavingsStep;
  transferredPoliciesUids?: string[];
  showErrorOnSummary?: boolean;
  declineCreateLead?: boolean;
  wontTransfer?: boolean;
  brokerHandled?: boolean;
}

export enum CombineSavingsStep {
  Intro,
  PensionInfo,
  AcceptTransfer,
  TransferLead,
  Summary,
  Consent
}

export enum CombineSavingsAbility {
  TransferableByCustomer = 'TRANSFERABLE_BY_CUSTOMER',
  TransferableByAdvisor = 'TRANSFERABLE_BY_ADVISOR',
  NotTransferable = 'NOT_TRANSFERABLE'
}

export enum CombineSavingsSummaryType {
  policiesCombined = 1,
  policiesNotCombined = 2,
  policiesTransferableByAdvisor = 3,
  policiesNotTransferable = 4,
  errorOccurred = 5
}

export enum CombineSavingsFlowType {
  external_transfer_flow = 'EXTERNAL_TRANSFER_FLOW',
  activate_your_recommendation_flow = 'ACTIVATE_YOUR_RECOMMENDATION_FLOW'
}

export enum TrackingStepType {
  None,
  Start,
  StepAcceptTransfer,
  StepTransferLead,
  Complete,
  Consent
}

export interface CombineSavingsSummary {
  combineSavingsResult: CombineSavingsSummaryType;
  transferredPoliciesUids: string[];
}

export interface CombineSavingsStepData {
  step: CombineSavingsStep;
  name: string;
  trackingStepType: TrackingStepType;
  trackingName: string;
}

export interface CombineSavingsSummaryTypeArgs {
  overallCategory: TransferableCategoryType;
  transferredPoliciesUids: string[];
  showErrorOnSummary: boolean;
  declineCreateLead: boolean;
}

export const stepsData: CombineSavingsStepData[] = [
  {
    step: CombineSavingsStep.Intro,
    name: 'eksternOverfoersel.start',
    trackingStepType: TrackingStepType.None,
    trackingName: ''
  } as CombineSavingsStepData,
  {
    step: CombineSavingsStep.PensionInfo,
    name: 'pensionsinfo.hent',
    trackingStepType: TrackingStepType.Start,
    trackingName: 'CYS pensionsinfo'
  } as CombineSavingsStepData,
  {
    step: CombineSavingsStep.AcceptTransfer,
    name: 'godkendt',
    trackingStepType: TrackingStepType.StepAcceptTransfer,
    trackingName: 'CYS select savings'
  } as CombineSavingsStepData,
  {
    step: CombineSavingsStep.TransferLead,
    name: 'transfer lead',
    trackingStepType: TrackingStepType.StepTransferLead,
    trackingName: 'CYS advisory needed'
  } as CombineSavingsStepData,
  {
    step: CombineSavingsStep.Consent,
    name: 'consent',
    trackingStepType: TrackingStepType.Consent,
    trackingName: 'CYS accept consent'
  } as CombineSavingsStepData,
  {
    step: CombineSavingsStep.Summary,
    name: 'kvittering',
    trackingStepType: TrackingStepType.Complete,
    trackingName: 'CYS receipt'
  } as CombineSavingsStepData
];

export const combineSavingsGuideName = 'combine your savings';
export const ctaContactMe = 'Ring mig op';
export const bankPensionType: OpsparingType = 'BANKOPSPARING';
export const chooseEntireAmount: EksternOverfoerselsType = 'HELE_BELOEBET';
