import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DigitalDataTrackingService } from '@pfa/core';
import { LoginRedirectService } from '@pfa/handler';
import { CookieService } from '@pfaform';
import { BehaviorSubject, merge, Observable, of, Subject } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  first,
  map,
  shareReplay,
  switchMap,
  tap
} from 'rxjs/operators';
import { PincodeMethod } from '../app/order-pincode/order-pincode.model';
import { LoginMethod } from '../app/logon/logon.model';
import { BrugerStore, CustomerprofileStore, MitID } from '@pfa/gen';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  public static InitialLoginRouteState = 'INITIAL_LOGIN';
  public loggedIn$: Observable<boolean>;
  private loggedIn: boolean;
  public mitIdUrl$: BehaviorSubject<string> = new BehaviorSubject('');
  public mitIdMobilUrl$: BehaviorSubject<string> = new BehaviorSubject('');
  private readonly loginSubject = new Subject<boolean>();
  private readonly authenticationSubject = new Subject<LoginMethod>();

  constructor(
    private readonly brugerStore: BrugerStore,
    private readonly cookieService: CookieService,
    private readonly http: HttpClient,
    private readonly loginRedirectService: LoginRedirectService,
    private readonly router: Router,
    private readonly customerprofileStore: CustomerprofileStore,
    private readonly digitalDataTrackingService: DigitalDataTrackingService
  ) {
    const hasServerSession = brugerStore.brugerGet().pipe(
      map(user => !!user && !user.pensionskundeIkkeValgt),
      catchError(err => {
        if (err?.error?.mitIdUrl) {
          // If user is not logged in then call to /ds/api/bruger is redirected to /mgaapi/sps/apiauthsvc.
          // Such call returns MitID broker URL configured in Auth module
          this.setMitIdUrl(err?.error?.mitIdUrl);
          this.setMitIdMobilUrl(err?.error?.mitIdMobilUrl);
          // store into backend session so deep links can use that value when customer is already logged-in
          brugerStore
            .brugerMitidPut({
              mitIdUrl: err?.error?.mitIdUrl,
              mitIdProUrl: ''
            } as MitID)
            .subscribe();
        }

        return of(false);
      }),
      first()
    );
    this.loggedIn$ = merge(hasServerSession, this.loginSubject).pipe(
      distinctUntilChanged(),
      shareReplay(1),
      tap(loggedIn => {
        this.loggedIn = loggedIn;
      })
    );
  }

  public get authenticationEvent(): Observable<LoginMethod> {
    return this.authenticationSubject.asObservable();
  }

  setMitIdLogin(): void {
    this.cookieService.setMitIdLogin();
    this.authenticationSubject.next(LoginMethod.MIT_ID);
  }

  setCprLogin() {
    this.cookieService.setPasswordLogin();
    this.authenticationSubject.next(LoginMethod.PIN_CODE);
  }

  setLogin(): void {
    this.loginSubject.next(true);
  }

  isLoggedIn(): boolean {
    return this.loggedIn;
  }

  handleInitialLogin(): Observable<boolean> {
    return this.customerprofileStore.customerprofileGet().pipe(
      tap(profile => {
        this.digitalDataTrackingService.trackCustomerType(profile);
        this.setLogin();
      }),
      tap(() => this.determineRedirectionAfterLogin()),
      map(() => true)
    );
  }

  private setMitIdUrl(url: string): void {
    this.mitIdUrl$.next(url);
  }

  private setMitIdMobilUrl(url: string): void {
    this.mitIdMobilUrl$.next(url);
  }

  public getRateLimit(): Observable<any> {
    return this.http.get<any>('/ds/open/ratelimit');
  }

  public createPinCode(pincodeMethoed: PincodeMethod): Observable<any> {
    return this.http.post('/logon/haandtering/pinkode', pincodeMethoed);
  }

  private determineRedirectionAfterLogin(): void {
    const initialLoginParam = {
      [LoginService.InitialLoginRouteState]: true
    };

    if (!this.loginRedirectService.loggedInSuccessRoute?.url) {
      this.router.navigate(['mitoverblik'], { state: initialLoginParam });
      return;
    }

    this.router.navigate([this.loginRedirectService.loggedInSuccessRoute.url], {
      queryParams: this.loginRedirectService.loggedInSuccessRoute.params,
      state: initialLoginParam
    });
  }
}
