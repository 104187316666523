/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ServiceInfo } from './serviceInfo';
import { ServiceInfoSchema } from './serviceInfo';
import { ResourceModel } from './resourceModel';
import { ResourceModelSchema } from './resourceModel';
import * as JoiObj from "joi";


    export const IndbetalingEfterOensketPensionsalderSchema = JoiObj.object({
        serviceInfo:
            
        JoiObj.link('#ServiceInfoSchema')

.allow(null)



        ,
        aarligIndbetaling:
            
                    JoiObj.number()
                


.required()


        ,
        oensketPensionsalder:
            
                    JoiObj.number()
                .integer()


.required()


        ,
        pensionsalder:
            
                    JoiObj.number()
                .integer()


.required()


        ,
        policeNr:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        
    })
                .shared(ServiceInfoSchema)
.id('IndbetalingEfterOensketPensionsalderSchema')

export interface IndbetalingEfterOensketPensionsalder extends ResourceModel { 
    aarligIndbetaling: number;
    oensketPensionsalder: number;
    pensionsalder: number;
    policeNr: string;
}

