/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const StatusSalesOrderSchema = JoiObj.string().valid(
        'OPEN'
        ,
        'PAID'
        ,
        'WAITINGFORMONEY'
        ,
        'MONEYRECEIVED'
        ,
        'COMPLETED'
        ,
        'COMPLETEDMLDOMISSING'
        ,
        'CANCELLED'
        
    )
.id('StatusSalesOrderSchema')

export type StatusSalesOrder = 'OPEN' | 'PAID' | 'WAITINGFORMONEY' | 'MONEYRECEIVED' | 'COMPLETED' | 'COMPLETEDMLDOMISSING' | 'CANCELLED';

export const StatusSalesOrder = {
    Open: 'OPEN' as StatusSalesOrder,
    Paid: 'PAID' as StatusSalesOrder,
    Waitingformoney: 'WAITINGFORMONEY' as StatusSalesOrder,
    Moneyreceived: 'MONEYRECEIVED' as StatusSalesOrder,
    Completed: 'COMPLETED' as StatusSalesOrder,
    Completedmldomissing: 'COMPLETEDMLDOMISSING' as StatusSalesOrder,
    Cancelled: 'CANCELLED' as StatusSalesOrder
};

