<tr class="forced-thin-border table-emphasized text--bold">
  <td [coContent]="headerContentId" colspan="2"></td>
</tr>
<ng-container *ngIf="lineElements?.length > 0">
  <tr
    class="forced-thin-border table-emphasized"
    *ngFor="let lineElement of lineElements"
  >
    <td [attr.data-label]="'DL.OS01.C425' | content">
      <span [coContent]="lineElement.contentId"></span>
      <co-tooltip
        *ngIf="
          lineElement.contentId === 'DL.OS01.C224' &&
          isCommissionAndRemuneration
        "
        [text]="'DL.OS01.C416'"
      ></co-tooltip>
    </td>
    <td class="align--right" [attr.data-label]="'DL.OS01.C426' | content">
      {{ lineElement.value | numberFormat: 2 }}
      <span [coContent]="'Global.kroner'"></span>
    </td>
  </tr>
</ng-container>
<ng-container *ngIf="feeLineElements?.length > 0">
  <tr
    class="forced-thin-border table-emphasized"
    *ngFor="let feeLineElements of feeLineElements"
  >
    <td [attr.data-label]="'DL.OS01.C425' | content">
      <span
        [coContent]="feeLineElements.contentId"
        [coContentSubst]="feeLineElements.valoerDato | date: 'dd.MM.yyyy'"
      ></span>
    </td>
    <td class="align--right" [attr.data-label]="'DL.OS01.C426' | content">
      {{ feeLineElements.value | numberFormat: 2 }}
      <span [coContent]="'Global.kroner'"></span>
    </td>
  </tr>
</ng-container>
