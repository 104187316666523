/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const ClimatePlusSchema = JoiObj.string().valid(
        'CLIMATE_PLUS_NONE'
        ,
        'CLIMATE_PLUS_LOW'
        ,
        'CLIMATE_PLUS_MIDDLE'
        ,
        'CLIMATE_PLUS_HIGH'
        
    )
.id('ClimatePlusSchema')

export type ClimatePlus = 'CLIMATE_PLUS_NONE' | 'CLIMATE_PLUS_LOW' | 'CLIMATE_PLUS_MIDDLE' | 'CLIMATE_PLUS_HIGH';

export const ClimatePlus = {
    None: 'CLIMATE_PLUS_NONE' as ClimatePlus,
    Low: 'CLIMATE_PLUS_LOW' as ClimatePlus,
    Middle: 'CLIMATE_PLUS_MIDDLE' as ClimatePlus,
    High: 'CLIMATE_PLUS_HIGH' as ClimatePlus
};

