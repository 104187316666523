import { Pipe, PipeTransform } from '@angular/core';
import { FoundAllYouWantedOptions } from '../customer-survey.enum';

@Pipe({
  name: 'customerSurveyMissingInfoVisibility'
})
export class CustomerSurveyMissingInfoVisibilityPipe implements PipeTransform {
  foundAllYouWanted = FoundAllYouWantedOptions;

  transform(value: FoundAllYouWantedOptions): boolean {
    return (
      [this.foundAllYouWanted.Partially, this.foundAllYouWanted.No].indexOf(
        value
      ) !== -1
    );
  }
}
