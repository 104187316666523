import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input
} from '@angular/core';
import {
  InsuranceGuideQuestion,
  InsuranceGuideQuestionValue
} from '../../insurance-guide-configuration.service';
import { BehaviorSubject } from 'rxjs';
import { FinancialSituationApiService } from '@pfa/api';
import { UntypedFormGroup } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { FinancialSituationConfigurationService } from '@mitpfa/shared';
import { OekonomiDetaljer, PensionsKundeGenerelleData } from '@pfa/gen';
import {
  NotificationService,
  NotificationType,
  GlobalWorkingService
} from '@pfaform';

@Component({
  selector: 'mitpfa-insurance-guide-financial-situation',
  templateUrl: './insurance-guide-financial-situation.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InsuranceGuideFinancialSituationComponent {
  @Input() currentQuestion: BehaviorSubject<InsuranceGuideQuestionValue>;
  @Input() financialSituation: OekonomiDetaljer;
  @Input() pensionCustomer: PensionsKundeGenerelleData;
  updateError: boolean;

  constructor(
    private readonly globalWorkingService: GlobalWorkingService,
    private readonly financialSituationApiService: FinancialSituationApiService,
    private readonly financialSituationConfigurationService: FinancialSituationConfigurationService,
    private readonly cdRef: ChangeDetectorRef,
    private readonly notificationService: NotificationService
  ) {}

  public financialSituationForm = new UntypedFormGroup({});

  public goBack(): void {
    this.currentQuestion.next({
      value: InsuranceGuideQuestion.family,
      back: true
    });
  }

  public goNext(): void {
    this.globalWorkingService.show();
    this.updateError = false;
    const financialSituation =
      this.financialSituationConfigurationService.getMappedFormsToUpdateFormat(
        this.financialSituationForm,
        this.financialSituation
      );

    this.financialSituationApiService
      .updateFinancialSituation(financialSituation)
      .pipe(finalize(() => this.globalWorkingService.hide()))
      .subscribe({
        next: () => {
          this.notificationService.showNotification({
            message: 'DL.FG01.C1970',
            type: NotificationType.SUCCESS
          });
          Object.keys(financialSituation).forEach(
            key => (this.financialSituation[key] = financialSituation[key])
          );
          this.financialSituationForm.markAsPristine();
          this.currentQuestion.next({ value: InsuranceGuideQuestion.risk });
          this.cdRef.detectChanges();
          window.scroll(0, 0);
        },
        error: () => {
          this.updateError = true;
          this.cdRef.detectChanges();
        }
      });
  }
}
