import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  inject
} from '@angular/core';
import { PaymentApiService } from '@pfa/api';
import { MiscTrackingService } from '@pfa/core';
import { isNil } from 'lodash';
import { Router } from '@angular/router';
import { formatNumber } from '@angular/common';
import { DaekningsDetaljer, IndbetalingOversigt } from '@pfa/gen';
import { forkJoin } from 'rxjs';
import { PayoutPlanService } from '@mitpfa/shared/services/payout-plan.service';

interface PaymentOverviewExtend extends IndbetalingOversigt {
  totalLatestPaymentsForMonth?: string;
}
@Component({
  selector: 'mitpfa-dashboard-savings-payments',
  templateUrl: './dashboard-savings-payments.component.html',
  styleUrls: ['./dashboard-savings-payments.component.scss']
})
export class DashboardSavingsPaymentsComponent implements OnInit {
  private readonly paymentApiService: PaymentApiService =
    inject(PaymentApiService);
  private readonly payoutPlanService: PayoutPlanService =
    inject(PayoutPlanService);
  private readonly trackingService: MiscTrackingService =
    inject(MiscTrackingService);
  private readonly router: Router = inject(Router);

  @Output() contentLoaded = new EventEmitter<void>();
  @Input() payoutsStarted: boolean;
  @Input() coverages: DaekningsDetaljer[];

  paymentInit: boolean;
  paymentOverview: PaymentOverviewExtend;
  latestPayout = 0;
  latestPayoutGross = 0;
  paymentError: boolean;

  constructor() {
    this.paymentError = false;
  }

  ngOnInit(): void {
    this.paymentApiService.getPaymentOverview().subscribe({
      next: response => {
        this.paymentOverview = response;
        this.paymentOverview.totalLatestPaymentsForYear = !isNil(
          this.paymentOverview.totalLatestPaymentsForYear
        )
          ? this.paymentOverview.totalLatestPaymentsForYear
          : 0;

        this.paymentOverview.totalLatestPaymentsForMonth = !isNil(
          this.paymentOverview.totalSenesteIndbetalingerSenesteMaaned
        )
          ? formatNumber(
              Math.round(
                this.paymentOverview.totalSenesteIndbetalingerSenesteMaaned
              ),
              'da-DK'
            )
          : '0';

        this.handlePayouts();
      },
      error: () => {
        this.paymentInit = true;
        this.paymentError = true;
        this.contentLoaded.emit();
      }
    });
  }

  private handlePayouts(): void {
    if (this.payoutsStarted) {
      const year = new Date().getFullYear();
      const filteredCoverageIds = new Array<string>();
      this.coverages.forEach(coverage => {
        if (!filteredCoverageIds.includes(coverage.aftaleNummer)) {
          filteredCoverageIds.push(coverage.aftaleNummer);
        }
      });
      filteredCoverageIds.forEach(coverageId => {
        const backendCalls = [
          this.payoutPlanService.getHistoricalPayout(
            coverageId,
            year.toString()
          )
        ];
        if (new Date().getMonth() === 0) {
          backendCalls.push(
            this.payoutPlanService.getHistoricalPayout(
              coverageId,
              (year - 1).toString()
            )
          );
        }
        forkJoin(backendCalls).subscribe(response => {
          if (response.length > 0) {
            let responseIndex = 0;
            if (response.length > 1 && response[0].udbetalinger?.length === 0) {
              responseIndex = 1;
            }
            const payoutDetails = response[responseIndex];
            this.latestPayout = payoutDetails.senesteUdbetaling;
            const lastPayoutDate =
              payoutDetails.udbetalinger[payoutDetails.udbetalinger.length - 1]
                ?.dato;
            payoutDetails.udbetalinger.forEach(payout => {
              if (payout.dato === lastPayoutDate) {
                this.latestPayoutGross += payout.bruttoBeloeb;
              }
            });

            this.paymentInit = true;
            this.paymentError = false;
            this.contentLoaded.emit();
          }
        });
      });
    } else {
      this.paymentInit = true;
      this.paymentError = false;
      this.contentLoaded.emit();
    }
  }

  goToLatestPayment() {
    if (this.paymentError) {
      return;
    }
    if (this.payoutsStarted) {
      this.router.navigateByUrl('/pensionsplan/udbetalinger', {
        state: { gotoPayments: true }
      });
    } else {
      this.trackingService.trackNavigationCard(
        'Savings section',
        'Payments YTD',
        '/senesteindbetaling'
      );
      this.router.navigate(['/senesteindbetaling']);
    }
  }
}
