/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Aktiefordeling } from './aktiefordeling';
import { AktiefordelingSchema } from './aktiefordeling';
import { Sats } from './sats';
import { SatsSchema } from './sats';
import * as JoiObj from "joi";


    export const InvesteringsfordelingSatserSchema = JoiObj.object({
        activeNow:
            
                    JoiObj.boolean()
                


.required()


        ,
        name:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        updatedAt:
            
            JoiObj.date()


.required()


        ,
        updatedBy:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        uuid:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        validFrom:
            
            JoiObj.date()

.allow(null)



        ,
        validFromFormatted:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        year:
            
                    JoiObj.number()
                


.required()


        ,
        aktier:
                JoiObj.array().items(
        
        JoiObj.link('#AktiefordelingSchema')

        )
        

.required()


        ,
        max:
            
                    JoiObj.number()
                


.required()


        ,
        min:
            
                    JoiObj.number()
                


.required()


        
    })
                .shared(AktiefordelingSchema)
.id('InvesteringsfordelingSatserSchema')

export interface InvesteringsfordelingSatser extends Sats { 
    aktier: Array<Aktiefordeling>;
    max: number;
    min: number;
}

