import { Injectable } from '@angular/core';
import * as forge from 'node-forge';
import { PensionsKundeGenerelleData } from '@pfa/gen';
import { HttpClient } from '@angular/common/http';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PensionInfoService {
  // "010" identifies PFA
  private pensionProviderIdAtPensionsInfo = 10;

  constructor(private readonly http: HttpClient) {}

  getRedirectLink(
    user: PensionsKundeGenerelleData,
    isPartner: boolean
  ): Observable<string> {
    const base64UrlTicket = this.createBase64UrlTicket(user, isPartner);
    const ctTransferTokenText = this.getBase64Hash(base64UrlTicket);
    return this.getExchangeCode(ctTransferTokenText).pipe(
      map(
        (token: ControlledTransferTokenExchange) =>
          token.pensionsinfoLoginMitIdSsoUrl +
          '?ct_exchangeCode=' +
          token.transferTokenExchangeCode +
          '&ct_transferTokenText=' +
          ctTransferTokenText +
          '&ticket=' +
          base64UrlTicket
      )
    );
  }

  private getBase64Hash(value: string) {
    const md = forge.md.sha512.create();
    md.update(value);
    return this.customPensionInfoUrlEncoding(md.digest().getBytes());
  }

  private customPensionInfoUrlEncoding(input: string): string {
    return window
      .btoa(input)
      .replace(/\+/g, '-')
      .replace(/\//g, '_')
      .replace(/=/g, '');
  }

  private createBase64UrlTicket(
    user: PensionsKundeGenerelleData,
    isPartner: boolean
  ): string {
    // ssoType 3 - "Send for self-service"
    let type = 3;
    if (isPartner) {
      // ssoType 5 - "Send to self-service for two"
      type = 5;
    }
    const ssoTicket = {
      pensionProviderId: this.pensionProviderIdAtPensionsInfo,
      customerName: window.btoa(user.navn),
      ssoType: type
    };

    return this.customPensionInfoUrlEncoding(JSON.stringify(ssoTicket));
  }

  private getExchangeCode(
    ctTransferTokenText: string
  ): Observable<ControlledTransferTokenExchange> {
    return this.http.get<ControlledTransferTokenExchange>(
      '/mgaapi/sps/authsvc?PolicyId=urn:ibm:security:authentication:asf:tokenExchangeCode&transferTokenText=' +
        encodeURIComponent(ctTransferTokenText)
    );
  }
}

/**
 * defined in
 * - repository: ISAM\MitID\common\template_files\tokenExchangeCode.json.js
 * - ISVA Template Page: /authsvc/tokenExchangeCode.json
 *
 */
interface ControlledTransferTokenExchange {
  transferTokenExchangeCode: string;
  pensionsinfoLoginMitIdSsoUrl: string;
  error: string;
}
