/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const InForceSelectionProfileSchema = JoiObj.object({
        employeeNumber:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        inForceSelectionType:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        passiveAcceptance:
            
                    JoiObj.boolean()
                

.allow(null)



        ,
        policyId:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        timestamp:
            
            JoiObj.date()

.allow(null)



        ,
        selection:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        recommendation:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        
    })
.id('InForceSelectionProfileSchema')

export interface InForceSelectionProfile { 
    employeeNumber?: string;
    inForceSelectionType?: string;
    passiveAcceptance?: boolean;
    policyId?: string;
    timestamp?: Date;
    selection?: string | null;
    recommendation?: string;
}

