<div class="info-box-header text--manchet">
  <div [coContent]="'DL.PI01.C136'" class="color--primary text--bold"></div>
  <div
    [coContent]="piTextId"
    style="margin-top: 30px; margin-bottom: 30px"
  ></div>

  <div
    *ngIf="
      pensionCustomer.harPensionsinfo && !pensionCustomer.pensionsinfoIncomplete
    "
    class="text--emphasized color--black"
  >
    <span [coContent]="'DL.PI01.C70'"></span>
    {{ pensionCustomer.pensionsinfoDato }}
  </div>

  <div
    *ngIf="
      !pensionCustomer.harPensionsinfo &&
      !pensionCustomer.pensionsinfoIncomplete
    "
    class="text--emphasized color--black"
    [coContent]="'DL.PI01.C71'"
  ></div>
</div>
