import { NgModule } from '@angular/core';
import { OperationStatusComponent } from './operation-status.component';
import { CommonModule } from '@angular/common';
import { MyCommonModule } from '@pfa/common';
import { IconModule } from '@pfa/icons';

@NgModule({
  imports: [CommonModule, MyCommonModule, IconModule],
  declarations: [OperationStatusComponent],
  exports: [OperationStatusComponent]
})
export class OperationStatusModule {}
