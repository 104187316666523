import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  ElementRef
} from '@angular/core';
import { PfaColors } from '@pfa/core';
import { CustomStepDefinition, Options } from 'ngx-slider-v2';
declare const PFAColors: PfaColors;

// For more options see https://angular-slider.github.io/ngx-slider/docs/classes/_options_.options.html
// NOTE: options that makes graphical changes might require some new styling
export function generateSliderStepsArray(
  from: number,
  to: number,
  interval: number
): CustomStepDefinition[] {
  const array = [];
  while (from <= to) {
    array.push({ value: from });
    from += interval;
  }

  return array;
}

@Component({
  selector: 'co-slider',
  templateUrl: './slider.component.html'
})
export class SliderComponent implements OnInit, OnChanges {
  @Input() options: Options;
  @Input() model: number;
  @Input() enableMaxLimitStyling: boolean;
  @Input() enableGreenColor?: boolean;
  @Output() modelChange = new EventEmitter<number>();
  @Output() modelChangeEnd = new EventEmitter<number>();

  optionsInternal: Options;
  nativeElement: any;

  constructor(private readonly hostElement: ElementRef) {
    this.nativeElement = this.hostElement.nativeElement;
  }

  ngOnInit() {
    if (
      !this.options ||
      this.options.floor === undefined ||
      this.options.ceil === undefined
    ) {
      throw new Error('Options with floor and ceil is mandatory!');
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.options) {
      // new Options object to trick sliders ngOnChanges into detecting deep changes in Options
      let optionsInternalTemp = new Options();

      // copy chacges to new object
      optionsInternalTemp = Object.assign(
        optionsInternalTemp,
        changes.options.currentValue
      );

      // Default styling stuff
      optionsInternalTemp.animate = false;
      optionsInternalTemp.showSelectionBar = true;

      this.optionsInternal = optionsInternalTemp;

      if (this.enableMaxLimitStyling) {
        const sliderBarWrapper = this.nativeElement.getElementsByClassName(
          'ngx-slider-full-bar'
        )[0];
        if (sliderBarWrapper) {
          const sliderBar =
            sliderBarWrapper.getElementsByClassName('ngx-slider-bar')[0];

          if (sliderBar) {
            // prettier-ignore
            sliderBar.style.backgroundImage =
              `linear-gradient(
                to right,
                ${PFAColors.primary_100} 0%,
                ${PFAColors.secondary_80} 0%,
                ${PFAColors.secondary_80} ${this.optionsInternal.maxLimit}%,
                ${PFAColors.grey_10} ${this.optionsInternal.maxLimit}%,
                ${PFAColors.grey_10} 100%
              )`;
          }
        }
      }
    }
  }

  sliderChange() {
    this.modelChange.emit(this.model);
  }

  sliderChangeEnd() {
    this.modelChangeEnd.emit(this.model);
  }
}
