/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ForecastInPeriod } from './forecastInPeriod';
import { ForecastInPeriodSchema } from './forecastInPeriod';
import { PensionForecastSimple } from './pensionForecastSimple';
import { PensionForecastSimpleSchema } from './pensionForecastSimple';
import * as JoiObj from "joi";


    export const PensionForecastIndividualSatserSchema = JoiObj.object({
        activeNow:
            
                    JoiObj.boolean()
                


.required()


        ,
        name:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        updatedAt:
            
            JoiObj.date()


.required()


        ,
        updatedBy:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        uuid:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        validFrom:
            
            JoiObj.date()

.allow(null)



        ,
        validFromFormatted:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        year:
            
                    JoiObj.number()
                


.required()


        ,
        forecastInPeriods:
                JoiObj.array().items(
        
        JoiObj.link('#ForecastInPeriodSchema')

        )
        

.required()


        
    })
                .shared(ForecastInPeriodSchema)
.id('PensionForecastIndividualSatserSchema')

export interface PensionForecastIndividualSatser extends PensionForecastSimple { 
}

