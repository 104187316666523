/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { KundeLegitimationStatus } from './kundeLegitimationStatus';
import { KundeLegitimationStatusSchema } from './kundeLegitimationStatus';
import * as JoiObj from "joi";


    export const LegitimationSchema = JoiObj.object({
        andenLegitimeret:
            
                    JoiObj.number()
                


.required()


        ,
        cprLegitimeret:
            
                    JoiObj.boolean()
                


.required()


        ,
        koerekortLegitimeret:
            
                    JoiObj.boolean()
                


.required()


        ,
        nemIdLegitimeret:
            
                    JoiObj.boolean()
                


.required()


        ,
        pasLegitimeret:
            
                    JoiObj.boolean()
                


.required()


        ,
        status:
            
        JoiObj.link('#KundeLegitimationStatusSchema')


.required()


        ,
        sygesikringLegitimeret:
            
                    JoiObj.boolean()
                


.required()


        
    })
                .shared(KundeLegitimationStatusSchema)
.id('LegitimationSchema')

export interface Legitimation { 
    andenLegitimeret: number;
    cprLegitimeret: boolean;
    koerekortLegitimeret: boolean;
    nemIdLegitimeret: boolean;
    pasLegitimeret: boolean;
    status: KundeLegitimationStatus;
    sygesikringLegitimeret: boolean;
}
export namespace Legitimation {
}


