import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { interval } from 'rxjs';
import { takeWhile } from 'rxjs/operators';

@Component({
  selector: 'mitpfa-pension-check-done',
  templateUrl: './pension-check-done.component.html',
  styleUrls: ['./pension-check-done.component.scss']
})
export class PensionCheckDoneComponent implements OnInit {
  public progressValue = 0;
  @Output() complete = new EventEmitter();

  ngOnInit() {
    interval(100)
      .pipe(takeWhile(() => this.progressValue <= 100))
      .subscribe(() => {
        this.progressValue = this.progressValue + 2;
        if (this.progressValue === 100) {
          this.complete.emit();
        }
      });
  }
}
