/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ServiceInfo } from './serviceInfo';
import { ServiceInfoSchema } from './serviceInfo';
import { RolleData } from './rolleData';
import { RolleDataSchema } from './rolleData';
import * as JoiObj from "joi";


    export const NavnOgStatusDataSchema = JoiObj.object({
        serviceInfo:
            
        JoiObj.link('#ServiceInfoSchema')

.allow(null)



        ,
        isAdministrator:
            
                    JoiObj.boolean()
                


.required()


        ,
        isMaegler:
            
                    JoiObj.boolean()
                


.required()


        ,
        isPfaUser:
            
                    JoiObj.boolean()
                


.required()


        ,
        isPrivat:
            
                    JoiObj.boolean()
                


.required()


        ,
        isRaadgiver:
            
                    JoiObj.boolean()
                


.required()


        ,
        isSkadesanmeldelseAdmin:
            
                    JoiObj.boolean()
                


.required()


        ,
        isSomPrivat:
            
                    JoiObj.boolean()
                


.required()


        ,
        isVirksomhedMedarbejde:
            
                    JoiObj.boolean()
                


.required()


        ,
        isPensionskundeIkkeValgt:
            
                    JoiObj.boolean()
                


.required()


        ,
        messagingOpen:
            
                    JoiObj.boolean()
                


.required()


        ,
        navn:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        pensionskundeIkkeValgt:
            
                    JoiObj.boolean()
                


.required()


        ,
        privateBusinessLogin:
            
                    JoiObj.boolean()
                


.required()


        ,
        sidsteBesoeg:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    

.allow(null)



        ,
        specialMessagingRules:
            
                    JoiObj.boolean()
                


.required()


        
    })
                .shared(ServiceInfoSchema)
.id('NavnOgStatusDataSchema')

export interface NavnOgStatusData extends RolleData { 
    isPensionskundeIkkeValgt: boolean;
    messagingOpen: boolean;
    navn: string;
    pensionskundeIkkeValgt: boolean;
    privateBusinessLogin: boolean;
    sidsteBesoeg?: string;
    specialMessagingRules: boolean;
}

