import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ExtendedWindow } from '@pfa/core';

@Component({
  selector: 'mitpfa-logon-footer',
  templateUrl: './logon-footer.component.html',
  styleUrls: ['./logon-footer.component.scss']
})
export class LogonFooterComponent {
  @Input()
  isDanish: boolean;

  @Output()
  switchLanguage: EventEmitter<'da' | 'en'> = new EventEmitter();

  openCookiePopup() {
    const extendedWindow = window as unknown as ExtendedWindow;
    const cookieBot = extendedWindow.Cookiebot;
    cookieBot?.show();
  }

  switchedLanguage() {
    this.switchLanguage.emit(this.isDanish ? 'en' : 'da');
  }
}
