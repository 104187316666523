import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {
  AccordionComponent,
  ConfirmChoiceComponentTexts,
  ConfirmChoiceTextsConfiguration
} from '@pfaform';
import {
  RECOMMENDATION_DIRECTION,
  RECOMMENDATION_TYPE,
  RecommendationDescription
} from '../insurance-guide.recommendation.model';
import {
  ConfirmChoiceData,
  InsuranceAdjustmentCiService,
  InsuranceAdjustmentLifeService,
  InsuranceAdjustmentLocService
} from '@mitpfa/shared';

@Component({
  selector: 'mitpfa-insurance-guide-recommendation-card',
  templateUrl: './insurance-guide-recommendation-card.component.html',
  styleUrls: ['./insurance-guide-recommendation-card.component.scss']
})
export class InsuranceGuideRecommendationCardComponent implements OnInit {
  @Input() title: string;
  @Input() recommendationDirection: RECOMMENDATION_DIRECTION;
  @Input() hasAdvisorRecommendation: boolean;
  @Input() recommendationDescription: RecommendationDescription | undefined;
  @Input() recommendationHeaderTooltip: string;
  @Input() showNoCoverageWarning: boolean;
  @Input() accordionTrackingName: string;
  @Input() showFollowOurRecommendationButton: boolean;
  @Input() hideActions: boolean;
  @Input() confirmChoiceData: ConfirmChoiceData;
  @Output() confirmChoiceEmitter: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild(AccordionComponent) accordion: AccordionComponent;

  recommendationHeader: string;
  currentHeader: string;
  showCurrent: boolean;

  private isLossOfOccupationalCapacity: boolean;
  private isCriticalIllness: boolean;
  private isLife: boolean;

  private readonly insuranceAdjustmentLocService: InsuranceAdjustmentLocService =
    inject(InsuranceAdjustmentLocService);
  private readonly insuranceAdjustmentCiService: InsuranceAdjustmentCiService =
    inject(InsuranceAdjustmentCiService);
  private readonly insuranceAdjustmentLifeService: InsuranceAdjustmentLifeService =
    inject(InsuranceAdjustmentLifeService);

  private confirmChoiceTextConfiguration: ConfirmChoiceTextsConfiguration = {
    confirm: {
      headline: undefined,
      body: undefined,
      button: 'DL.TF01.C181'
    },
    reconfirm: {
      headline: 'DL.INV.reconfirmheadline',
      body: undefined,
      button: 'DL.INV.textonreconfirmbuttton'
    },
    thankYou: {
      headline: 'DL.INV.thankyou',
      body: undefined,
      button: undefined
    }
  };

  public confirmChoiceComponentTexts: ConfirmChoiceComponentTexts = {
    bodyContentId: this.confirmChoiceTextConfiguration.confirm.body,
    headlineContentId: this.confirmChoiceTextConfiguration.confirm.headline,
    buttonContentId: this.confirmChoiceTextConfiguration.confirm.button
  };

  ngOnInit(): void {
    this.isLossOfOccupationalCapacity =
      this.recommendationDescription?.type ===
      RECOMMENDATION_TYPE.OCCUPATIONAL_CAPACITY;
    this.isCriticalIllness =
      this.recommendationDescription?.type ===
      RECOMMENDATION_TYPE.CRITICAL_ILLNESS;
    this.isLife =
      this.recommendationDescription?.type === RECOMMENDATION_TYPE.LIFE;

    this.initRecommendation();
  }

  private initRecommendation(): void {
    this.showCurrent =
      !this.recommendationDescription?.isAuto &&
      this.recommendationDirection !== 'KEEP';

    if (this.recommendationDirection === 'KEEP') {
      this.recommendationHeader = 'DL.FG01.C1898';
    } else {
      this.recommendationHeader = this.hasAdvisorRecommendation
        ? 'DL.TF01.C133'
        : 'DL.FG01.C1834';
    }

    // AUtomatic Adjustment
    if (this.recommendationDescription?.isAuto) {
      if (this.recommendationDescription.isAutoChange) {
        this.recommendationHeader = this.hasAdvisorRecommendation
          ? 'DL.TF01.C133'
          : 'DL.FG01.C1834';
      } else {
        this.recommendationHeader = 'DL.FG01.C1898';
      }
    }

    this.currentHeader =
      this.recommendationDirection === 'KEEP'
        ? 'DL.FG01.C1898'
        : 'DL.FG01.C1837';
  }

  adjustInsurance(): void {
    if (this.isLossOfOccupationalCapacity) {
      this.insuranceAdjustmentLocService.adjustInsurance.next();
    } else if (this.isCriticalIllness) {
      this.insuranceAdjustmentCiService.adjustInsurance.next();
    } else if (this.isLife) {
      this.insuranceAdjustmentLifeService.adjustInsurance.next();
    }
  }

  followOurRecommendation(): void {
    if (this.isLossOfOccupationalCapacity) {
      this.insuranceAdjustmentLocService.followOurRecommendation.next();
    } else if (this.isCriticalIllness) {
      this.insuranceAdjustmentCiService.followOurRecommendation.next();
    } else if (this.isLife) {
      this.insuranceAdjustmentLifeService.followOurRecommendation.next();
    }
  }

  onConfirmChoice(): void {
    this.confirmChoiceEmitter.emit();
  }
}
