/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AfkastProfil } from './afkastProfil';
import { AfkastProfilSchema } from './afkastProfil';
import * as JoiObj from "joi";


    export const AarligAfkastSchema = JoiObj.object({
        a:
            
        JoiObj.link('#AfkastProfilSchema')


.required()


        ,
        aar:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        b:
            
        JoiObj.link('#AfkastProfilSchema')


.required()


        ,
        c:
            
        JoiObj.link('#AfkastProfilSchema')


.required()


        ,
        d:
            
        JoiObj.link('#AfkastProfilSchema')


.required()


        
    })
                .shared(AfkastProfilSchema)
                .shared(AfkastProfilSchema)
                .shared(AfkastProfilSchema)
                .shared(AfkastProfilSchema)
.id('AarligAfkastSchema')

export interface AarligAfkast { 
    a: AfkastProfil;
    aar: string;
    b: AfkastProfil;
    c: AfkastProfil;
    d: AfkastProfil;
}

