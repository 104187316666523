<div class="spacing-unit--double-over header-with-info">
  <h4
    class="display-inline-block"
    [coContent]="'DL.OS01.C108'"
    [coContentSubst]="
      savingsPolicyDetails.fraDato + '|' + savingsPolicyDetails.tilDato
    "
  >
    ÅoK
  </h4>
  <co-tooltip [text]="'DL.OS01.I108'"></co-tooltip>
</div>
<co-card>
  <table
    class="pfa-table table-responsive pfa-table--compact align-left--column-1 align-right--column-2 align-right--column-3"
  >
    <thead class="hide--palm">
      <tr>
        <th [coContent]="'DL.OS01.C114'"></th>
        <th [coContent]="'DL.OS01.C117'"></th>
        <th>
          <span [coContent]="'DL.OS01.C116'"></span>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>
          <div class="flex">
            <div class="expandable-details" *ngIf="!policy.livnetAftale">
              <co-icon-arrow-up
                *ngIf="expandedDetailsAdmin; else arrowDown"
                class="icon-fill__small--grey"
                (click)="expandedDetailsAdmin = !expandedDetailsAdmin"
              ></co-icon-arrow-up>

              <ng-template #arrowDown>
                <co-icon-arrow-down
                  class="icon-fill__small--grey"
                  (click)="expandedDetailsAdmin = !expandedDetailsAdmin"
                ></co-icon-arrow-down>
              </ng-template>
            </div>
            <div>
              <span class="text--bold" [coContent]="'DL.OS01.C111'"></span>
              <co-tooltip
                *ngIf="policy.livnetAftale"
                [text]="'DL.OS01.I111a'"
              ></co-tooltip>
              <co-tooltip
                *ngIf="!policy.livnetAftale"
                [text]="'DL.OS01.I111b'"
              ></co-tooltip>
            </div>
          </div>
        </td>
        <td [attr.data-label]="'DL.OS01.C117' | content">
          <span
            *ngIf="
              savingsPolicyDetails.aaopAaok.adminOmkostningerProcent >= 0.005
            "
          >
            {{
              savingsPolicyDetails.aaopAaok.adminOmkostningerProcent
                | numberFormat: 2
            }}
            %
          </span>
          <span
            *ngIf="
              savingsPolicyDetails.aaopAaok.adminOmkostningerProcent < 0.005
            "
            >-</span
          >
        </td>
        <td [attr.data-label]="'DL.OS01.C116' | content" class="emphasized">
          {{
            savingsPolicyDetails.aaopAaok.adminOmkostningerKroner | numberFormat
          }}
          <span [coContent]="'Global.kroner'"></span>
        </td>
      </tr>
      <tr *ngIf="expandedDetailsAdmin && !policy.livnetAftale" class="sub-row">
        <td
          class="text--bold sub-cell emphasized"
          [coContent]="'DL.OS01.I111c'"
        ></td>
        <td class="emphasized cell-hide"></td>
        <td [attr.data-label]="'DL.OS01.C116' | content" class="emphasized">
          {{
            savingsPolicyDetails.aaopAaok.adminDetaljerAdminOmkostninger === 0
              ? '-'
              : (savingsPolicyDetails.aaopAaok.adminDetaljerAdminOmkostninger
                | numberFormat)
          }}
          <span
            *ngIf="
              savingsPolicyDetails.aaopAaok.adminDetaljerAdminOmkostninger !== 0
            "
            [coContent]="'Global.kroner'"
          ></span>
        </td>
      </tr>
      <tr *ngIf="expandedDetailsAdmin && !policy.livnetAftale" class="sub-row">
        <td
          class="text--bold sub-cell emphasized"
          [coContent]="'DL.OS01.I111d'"
          [attr.data-label]="'DL.OS01.C114' | content"
        ></td>
        <td class="emphasized cell-hide"></td>
        <td [attr.data-label]="'DL.OS01.C116' | content" class="emphasized">
          {{
            savingsPolicyDetails.aaopAaok
              .adminDetaljerForsikringsOmkostninger === 0
              ? '-'
              : (savingsPolicyDetails.aaopAaok
                  .adminDetaljerForsikringsOmkostninger | numberFormat)
          }}
          <span
            *ngIf="
              savingsPolicyDetails.aaopAaok
                .adminDetaljerForsikringsOmkostninger !== 0
            "
            [coContent]="'Global.kroner'"
          ></span>
        </td>
      </tr>

      <tr>
        <td>
          <div class="flex">
            <div class="expandable-details" *ngIf="showExpandedDetails">
              <co-icon-arrow-up
                *ngIf="expandedDetails; else arrowDown"
                class="icon-fill__small--grey"
                (click)="expandedDetails = !expandedDetails"
              ></co-icon-arrow-up>

              <ng-template #arrowDown>
                <co-icon-arrow-down
                  class="icon-fill__small--grey"
                  (click)="expandedDetails = !expandedDetails"
                ></co-icon-arrow-down>
              </ng-template>
            </div>
            <div>
              <span class="text--bold">{{ indirectExpenses }}</span>
              <co-tooltip [text]="'DL.OS01.I344'"></co-tooltip>
            </div>
          </div>
        </td>
        <td [attr.data-label]="'DL.OS01.C117' | content">
          <span
            *ngIf="
              savingsPolicyDetails.aaopAaok
                .indirekteForeloebligOmkostningerProcent >= 0.005 ||
              savingsPolicyDetails.aaopAaok
                .indirekteForeloebligOmkostningerProcent < 0
            "
          >
            {{
              savingsPolicyDetails.aaopAaok
                .indirekteForeloebligOmkostningerProcent | numberFormat: 2
            }}
            %
          </span>
          <span
            *ngIf="
              savingsPolicyDetails.aaopAaok
                .indirekteForeloebligOmkostningerProcent < 0.005 &&
              savingsPolicyDetails.aaopAaok
                .indirekteForeloebligOmkostningerProcent >= 0
            "
            >-</span
          >
        </td>
        <td [attr.data-label]="'DL.OS01.C116' | content" class="emphasized">
          {{
            savingsPolicyDetails.aaopAaok.indirekteForeloebligOmkostningerKroner
              | numberFormat
          }}
          <span [coContent]="'Global.kroner'"></span>
        </td>
      </tr>
      <tr
        *ngIf="
          expandedDetails &&
          savingsPolicyDetails.aaopAaok
            .indirekteDetaljerInvesteringsomkostninger !== null
        "
        class="sub-row"
      >
        <td
          class="text--bold sub-cell emphasized"
          [coContent]="'DL.OS01.I344b'"
        ></td>
        <td class="emphasized cell-hide"></td>
        <td [attr.data-label]="'DL.OS01.C116' | content" class="emphasized">
          {{
            savingsPolicyDetails.aaopAaok
              .indirekteDetaljerInvesteringsomkostninger | numberFormat
          }}
          <span [coContent]="'Global.kroner'"></span>
        </td>
      </tr>
      <tr
        *ngIf="
          expandedDetails &&
          savingsPolicyDetails.aaopAaok.indirekteDetaljerDriftsherretillaeg !==
            null
        "
        class="sub-row"
      >
        <td
          class="text--bold sub-cell emphasized"
          [coContent]="'DL.OS01.I344c'"
        ></td>
        <td class="emphasized cell-hide"></td>
        <td [attr.data-label]="'DL.OS01.C116' | content" class="emphasized">
          {{
            savingsPolicyDetails.aaopAaok.indirekteDetaljerDriftsherretillaeg
              | numberFormat
          }}
          <span [coContent]="'Global.kroner'"></span>
        </td>
      </tr>
      <tr
        *ngIf="
          expandedDetails &&
          savingsPolicyDetails.aaopAaok
            .indirekteDetaljerOmkostningsrisikoresultat !== null
        "
        class="sub-row"
      >
        <td
          class="text--bold sub-cell emphasized"
          [coContent]="'DL.OS01.I344d'"
        ></td>
        <td class="emphasized cell-hide"></td>
        <td [attr.data-label]="'DL.OS01.C116' | content" class="emphasized">
          {{
            savingsPolicyDetails.aaopAaok
              .indirekteDetaljerOmkostningsrisikoresultat | numberFormat
          }}
          <span [coContent]="'Global.kroner'"></span>
        </td>
      </tr>

      <tr class="border-top--thick">
        <td [attr.data-label]="'DL.OS01.C114' | content">
          <div
            class="text--bold"
            [coContent]="
              savingsPolicyDetails.markedgennemsnitsrente
                ? 'DL.OS01.C443'
                : 'DL.OS01.C440'
            "
          ></div>
        </td>
        <td [attr.data-label]="'DL.OS01.C117' | content">
          <span
            *ngIf="
              savingsPolicyDetails.aaopAaok.aaokaaopForeloebligSumProcent >=
                0.005 ||
              savingsPolicyDetails.aaopAaok.aaokaaopForeloebligSumProcent < 0
            "
          >
            {{
              savingsPolicyDetails.aaopAaok.aaokaaopForeloebligSumProcent
                | numberFormat: 2
            }}
            %
          </span>
          <span
            *ngIf="
              savingsPolicyDetails.aaopAaok.aaokaaopForeloebligSumProcent <
                0.005 &&
              savingsPolicyDetails.aaopAaok.aaokaaopForeloebligSumProcent >= 0
            "
            >-</span
          >
        </td>
        <td [attr.data-label]="'DL.OS01.C116' | content" class="emphasized">
          {{
            savingsPolicyDetails.aaopAaok.aaokaaopForeloebligSumKroner
              | numberFormat
          }}
          <span [coContent]="'Global.kroner'"></span>
        </td>
      </tr>

      <tr *ngIf="savingsPolicyDetails.aaopAaok.aaokaaopDifferenceSumKroner">
        <td>
          <span class="text--bold" [coContent]="'DL.OS01.C438'"></span>
        </td>
        <td [attr.data-label]="'DL.OS01.C117' | content">
          <span
            *ngIf="
              savingsPolicyDetails.aaopAaok.aaokaaopDifferenceSumProcent >=
                0.005 ||
              savingsPolicyDetails.aaopAaok.aaokaaopDifferenceSumProcent < 0
            "
          >
            {{
              savingsPolicyDetails.aaopAaok.aaokaaopDifferenceSumProcent
                | numberFormat: 2
            }}
            %
          </span>
          <span
            *ngIf="
              savingsPolicyDetails.aaopAaok.aaokaaopDifferenceSumProcent <
                0.005 &&
              savingsPolicyDetails.aaopAaok.aaokaaopDifferenceSumProcent >= 0
            "
            >-</span
          >
        </td>
        <td [attr.data-label]="'DL.OS01.C116' | content" class="emphasized">
          {{
            savingsPolicyDetails.aaopAaok.aaokaaopDifferenceSumKroner
              | numberFormat
          }}
          <span [coContent]="'Global.kroner'"></span>
        </td>
      </tr>

      <tr
        class="border-top--thick"
        *ngIf="savingsPolicyDetails.aaopAaok.aaokaaopEndeligSumKroner"
      >
        <td>
          <span class="text--bold" [coContent]="'DL.OS01.C439'"></span>
          <co-tooltip [text]="'DL.OS01.C441'"></co-tooltip>
        </td>
        <td [attr.data-label]="'DL.OS01.C117' | content">
          <co-primary-number
            [amount]="
              savingsPolicyDetails.aaopAaok.aaokaaopEndeligSumProcent >=
                0.005 ||
              savingsPolicyDetails.aaopAaok.aaokaaopEndeligSumProcent < 0
                ? savingsPolicyDetails.aaopAaok.aaokaaopEndeligSumProcent
                : '-'
            "
            [unit]="
              savingsPolicyDetails.aaopAaok.aaokaaopEndeligSumProcent >=
                0.005 ||
              savingsPolicyDetails.aaopAaok.aaokaaopEndeligSumProcent < 0
                ? '%'
                : ' '
            "
            [showDecimals]="true"
            class="size-sm"
          ></co-primary-number>
        </td>
        <td [attr.data-label]="'DL.OS01.C116' | content" class="emphasized">
          <co-primary-number
            [amount]="savingsPolicyDetails.aaopAaok.aaokaaopEndeligSumKroner"
            class="size-sm"
          ></co-primary-number>
        </td>
      </tr>
      <tr *ngIf="savingsPolicyDetails.aaopAaok.samletRabatOgTilskudBeloeb">
        <td class="text--bold" colspan="2">
          <span coContent="DL.OS01.C444"></span
          ><co-tooltip [text]="'DL.OS01.C447'"></co-tooltip>
        </td>
        <td class="text--bold no-divider">
          {{
            savingsPolicyDetails.aaopAaok.samletRabatOgTilskudBeloeb
              | currencyFormat
          }}
        </td>
      </tr>
    </tbody>
  </table>

  <div
    class="row justify-content-center spacing-unit--over"
    *ngIf="
      savingsPolicyDetails.aaopAaok.indirekteForeloebligOmkostningerKroner <
        0 || savingsPolicyDetails.aaopAaok.aaokaaopForeloebligSumKroner < 0
    "
  >
    <div class="col-md-8 col-lg-6">
      <co-info>
        <span [coContent]="'DL.OS01.C436'"></span>
      </co-info>
    </div>
  </div>
</co-card>
