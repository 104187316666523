<div
  class="menu"
  [ngClass]="{
    'selected--menu':
      (isMenuSelected && menuOpen === 'UNDEFINED') ||
      menuOpen === menuItem.name,
    'icon--background': specialMenuItem,
    'out--text': !menuItem.contentId,
    'profile--menu': menuItem.name === 'PROFILE'
  }"
  (click)="openClose(menuItem)"
  attr.data-test-id="{{ dataTestId }}"
>
  <ng-content select="[icon]"></ng-content>
  <ng-content select="[percentfilled]"></ng-content>
  <span
    *ngIf="menuItem.contentId"
    [coContent]="menuItem.contentId"
    [ngClass]="{ profile: menuItem.name === 'PROFILE' }"
    class="menu-item-hover"
  ></span>
</div>
<mitpfa-selected-menu-item
  [selectedSubMenu]="selectedSubMenu"
  *ngIf="isMenuSelected && menuOpen === 'UNDEFINED' && !specialMenuItem"
  (click)="openClose(menuItem)"
>
</mitpfa-selected-menu-item>
<div class="menu--container" *ngIf="menuOpen === menuItem.name" [@openClose]>
  <div (click)="stopPropagation($event)" class="submenu--container">
    <div
      *ngFor="let categoryMenuitem of menuItem.categories"
      class="submenu-item"
    >
      <div
        class="header--text"
        *ngIf="!categoryMenuitem.dontShow"
        [coContent]="categoryMenuitem.contentId"
      ></div>
      <div
        class="submenu"
        [ngClass]="{ 'submenu--logout': subMenuItem.name === 'LOGOUT' }"
        *ngFor="let subMenuItem of categoryMenuitem.items"
        (click)="openClose(undefined); select(subMenuItem)"
      >
        <co-notification
          class="notification"
          *ngIf="subMenuItem.notification"
          [amount]="subMenuItem.notification"
        ></co-notification>
        <a *ngIf="!subMenuItem.dontShow" class="menu-item">
          <co-icon-locked
            class="icon--lock"
            *ngIf="subMenuItem.name === 'LOGOUT'"
          ></co-icon-locked>
          <span
            class="text"
            [ngClass]="{
              selected: selectedSubMenu?.name === subMenuItem.name,
              lastLogin: subMenuItem.name === 'SIDSTE_LOGIN',
              'color--primary': subMenuItem.name === 'LOGOUT'
            }"
            [coContent]="subMenuItem.contentId"
            attr.data-test-id="{{ subMenuItem.dataTestId }}"
          >
          </span>
          <div
            class="lastLogin"
            *ngIf="subMenuItem.name === 'SIDSTE_LOGIN' && lastVisit"
          >
            {{ lastVisit | date: 'dd.MM.yyyy kl. HH:mm' }}
          </div>
        </a>
      </div>
    </div>
  </div>
</div>
