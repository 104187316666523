import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { PensionPlanApiService } from '@pfa/api';
import { Observable } from 'rxjs';
import { OpsparetVaerdi } from '@pfa/gen';

@Injectable({
  providedIn: 'root'
})
export class PensionInfoSavedValuesResolver implements Resolve<OpsparetVaerdi> {
  constructor(private pensionPlanApiService: PensionPlanApiService) {}

  resolve():
    | OpsparetVaerdi
    | Observable<OpsparetVaerdi>
    | Promise<OpsparetVaerdi> {
    return this.pensionPlanApiService.getPensionInfoSavedValues();
  }
}
