/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ProductPortfolio } from './productPortfolio';
import { ProductPortfolioSchema } from './productPortfolio';
import { CustomerRelations } from './customerRelations';
import { CustomerRelationsSchema } from './customerRelations';
import { ContactData } from './contactData';
import { ContactDataSchema } from './contactData';
import { PersonDetail } from './personDetail';
import { PersonDetailSchema } from './personDetail';
import * as JoiObj from "joi";


    export const CustomerProfileSchema = JoiObj.object({
        contactData:
            
        JoiObj.link('#ContactDataSchema')

.allow(null)



        ,
        cpr:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        customerRelations:
            
        JoiObj.link('#CustomerRelationsSchema')

.allow(null)



        ,
        mitPfaLightVersion:
            
                    JoiObj.boolean()
                


.required()


        ,
        mitPfaLightVersionNonCustomer:
            
                    JoiObj.boolean()
                


.required()


        ,
        newBusinessAdvisory:
            
                    JoiObj.boolean()
                


.required()


        ,
        newBusinessOpen:
            
                    JoiObj.boolean()
                


.required()


        ,
        overrideLight:
            
                    JoiObj.boolean()
                


.required()


        ,
        personDetail:
            
        JoiObj.link('#PersonDetailSchema')


.required()


        ,
        productPortfolio:
            
        JoiObj.link('#ProductPortfolioSchema')


.required()


        
    })
                .shared(ContactDataSchema)
                .shared(CustomerRelationsSchema)
                .shared(PersonDetailSchema)
                .shared(ProductPortfolioSchema)
.id('CustomerProfileSchema')

export interface CustomerProfile { 
    contactData?: ContactData;
    cpr: string;
    customerRelations?: CustomerRelations;
    mitPfaLightVersion: boolean;
    mitPfaLightVersionNonCustomer: boolean;
    newBusinessAdvisory: boolean;
    newBusinessOpen: boolean;
    overrideLight: boolean;
    personDetail: PersonDetail;
    productPortfolio: ProductPortfolio;
}

