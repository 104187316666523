/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const PeriodSchema = JoiObj.object({
        from:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        hours:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        id:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        partTime:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        to:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        
    })
.id('PeriodSchema')

export interface Period { 
    from: string;
    hours: string;
    id: string;
    partTime: string;
    to: string;
}

