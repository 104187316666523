import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BestiltAendringer } from '@pfa/gen';
import { InvestmentGuideInfo } from '@pfa/models';

@Component({
  selector: 'mitpfa-investment-recommendation-intro',
  templateUrl: './recommendation-intro.component.html'
})
export class InvestmentRecommendationIntroComponent {
  @Input() investmentGuideInfo: InvestmentGuideInfo;
  @Input() basket: BestiltAendringer;
  @Output() emitNextCard = new EventEmitter();
}
