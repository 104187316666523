import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DocumentsComponent } from './documents.component';
import { PfaFormModule } from '@pfaform';
import { CoreModule } from '@pfa/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { ReactiveFormsModule } from '@angular/forms';
import { IconModule } from '@pfa/icons';

@NgModule({
  imports: [
    CommonModule,
    PfaFormModule,
    CoreModule,
    MatFormFieldModule,
    MatSelectModule,
    ReactiveFormsModule,
    IconModule
  ],
  declarations: [DocumentsComponent],
  exports: [DocumentsComponent]
})
export class DocumentsModule {}
