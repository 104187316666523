<mitpfa-insurance-guide-pension-plan
  *ngIf="(currentQuestion | async)?.value === 'PENSION_INFO'"
  [currentQuestion]="currentQuestion"
  [pensionCustomer]="pensionCustomer"
  (emitInformationStep)="emitUpdatePI.emit()"
>
</mitpfa-insurance-guide-pension-plan>

<mitpfa-insurance-guide-your-family
  *ngIf="(currentQuestion | async)?.value === 'FAMILY'"
  [currentQuestion]="currentQuestion"
  [familyInformation]="familyInformation"
  [pensionCustomer]="pensionCustomer"
>
</mitpfa-insurance-guide-your-family>

<mitpfa-insurance-guide-financial-situation
  *ngIf="(currentQuestion | async)?.value === 'ASSETS'"
  [financialSituation]="financialSituation"
  [pensionCustomer]="pensionCustomer"
  [currentQuestion]="currentQuestion"
>
</mitpfa-insurance-guide-financial-situation>
