import { LoginRedirectService } from '../service/login-redirect.service';
import { BrugerStore, NavnOgStatusData } from '@pfa/gen';
import { Role } from '@pfa/models';
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Params,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class UserGuard implements CanActivate {
  constructor(
    private readonly brugerStore: BrugerStore,
    private readonly router: Router,
    private readonly loginRedirectService: LoginRedirectService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    if (this.isAdvisorTimedOut()) {
      return of(this.redirectToTimeout());
    }

    const url = state.url.split('?')[0];
    this.setLoginSuccessRoute(url, route.queryParams);
    return this.brugerStore.brugerGet().pipe(
      map(navnOgStatusData => {
        const roles = this.mapUserData(navnOgStatusData);
        const authorized = roles.filter(role =>
          route.data.roles.includes(role)
        );

        if (authorized.length > 0) {
          return true;
        } else {
          return this.getRejectionUrl(roles, route);
        }
      }),
      catchError(error => {
        if (
          error instanceof HttpErrorResponse &&
          error.status === 401 &&
          error.error.location
        ) {
          return of(
            this.redirectToLogin(error.error.location, error.error.mechanism)
          );
        }
        return throwError(error);
      })
    );
  }

  isAdvisorTimedOut() {
    if (sessionStorage.getItem('userTimedOut')) {
      return !!sessionStorage.getItem('userTimedOutRaadgiver');
    }
    return false;
  }
  getRejectionUrl(
    roles: Role[],
    route: ActivatedRouteSnapshot
  ): boolean | UrlTree {
    if (roles.indexOf(Role.Anonymous) > -1) {
      return this.router.parseUrl(
        route.data.rejectionRedirectPath
          ? route.data.rejectionRedirectPath
          : '/logind'
      );
    } else if (route.data.rejectionRedirectPath) {
      return this.router.parseUrl(route.data.rejectionRedirectPath);
    } else {
      return this.router.parseUrl('/forbidden');
    }
  }

  mapUserData(navnOgStatusData: NavnOgStatusData) {
    const roles = new Array<Role>();
    if (navnOgStatusData.isAdministrator) {
      roles.push(Role.Admin);
    }
    if (navnOgStatusData.isRaadgiver) {
      roles.push(Role.Advisor);
    }
    if (navnOgStatusData.isPrivat) {
      roles.push(Role.Customer);
    }
    if (navnOgStatusData.isSomPrivat) {
      roles.push(Role.PfaUser);
    }
    if (navnOgStatusData.isMaegler) {
      roles.push(Role.Broker);
    }
    if (navnOgStatusData.isVirksomhedMedarbejde) {
      roles.push(Role.B2BUser);
    }
    if (roles.length === 0) {
      roles.push(Role.Anonymous);
    }
    return roles;
  }

  private setLoginSuccessRoute(url: string, queryParams: Params) {
    const excludedUrls = ['/error', '/logout'];
    if (!excludedUrls.some(excludedUrl => excludedUrl === url)) {
      this.loginRedirectService.loggedInSuccessRoute = {
        url,
        params: queryParams
      };
    }
  }

  private redirectToLogin(location: string, mechanism: string): UrlTree {
    const langParam =
      this.loginRedirectService.loggedInSuccessRoute?.params?.lang;
    const loginPageParams = {
      ...{
        location: location,
        mechanism: mechanism
      },
      ...(langParam ? { lang: langParam } : {})
    };
    return this.router.createUrlTree(['/logind'], {
      queryParams: loginPageParams
    });
  }

  private redirectToTimeout(): UrlTree {
    return this.router.createUrlTree(['/raadgiver-timeout'], {});
  }
}
