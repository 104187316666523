/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { LocScaleGroupValues } from './locScaleGroupValues';
import { LocScaleGroupValuesSchema } from './locScaleGroupValues';
import { Plan } from './plan';
import { PlanSchema } from './plan';
import * as JoiObj from "joi";


    export const HelbredsPlanSchema = JoiObj.object({
        planType:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        daekningsgivendeLoen:
            
                    JoiObj.number()
                


.required()


        ,
        forsoergerAndel:
            
                    JoiObj.number()
                


.required()


        ,
        giftSamlevende:
            
                    JoiObj.boolean()
                


.required()


        ,
        harBoern:
            
                    JoiObj.boolean()
                


.required()


        ,
        procent:
            
                    JoiObj.number()
                


.required()


        ,
        recommendationAdjustedToMinimum:
            
                    JoiObj.boolean()
                


.required()


        ,
        saerligAnbefaling:
            
                    JoiObj.boolean()
                


.required()


        ,
        gaeld:
            
                    JoiObj.boolean()
                


.required()


        ,
        locScaleGroupValues:
            
        JoiObj.link('#LocScaleGroupValuesSchema')


.required()


        ,
        storFormue:
            
                    JoiObj.boolean()
                


.required()


        
    })
                .shared(LocScaleGroupValuesSchema)
.id('HelbredsPlanSchema')

export interface HelbredsPlan extends Plan { 
    planType: string;
    gaeld: boolean;
    locScaleGroupValues: LocScaleGroupValues;
    storFormue: boolean;
}

