import { inject, Injectable } from '@angular/core';
import {
  OnboardingFlow,
  OnboardingLevel,
  OnboardingLevelStep,
  OnboardingStepperLevel,
  OnboardingStepTrackingName
} from './onboarding.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { Step } from '@pfaform';
import {
  OnboardingActivatePensionStep,
  OnboardingActivatePensionSteps,
  OnboardingActivatePensionStepType,
  OnboardingStore
} from '@pfa/gen';

@Injectable({
  providedIn: 'root'
})
export class OnboardingService {
  private progressSteps: { [key: string]: Step };
  private subjectStepReached: BehaviorSubject<Step[]>;
  private nextLevel: OnboardingLevel;
  private onboardingStepsList: OnboardingActivatePensionSteps;
  private onboardingLevels = new OnboardingFlow().levels;
  private readonly subjectLevelReached = new BehaviorSubject<OnboardingLevel>(
    this.onboardingLevels[0]
  );
  private levelReached: OnboardingLevel = this.onboardingLevels[0];
  private _isPensionsInfoUpdated: boolean;

  private readonly onboardingStore: OnboardingStore = inject(OnboardingStore);
  private showInsurance: boolean;
  private onboardingFlow: OnboardingFlow;
  private showInvestment: boolean;

  constructor() {
    this.onboardingFlow = new OnboardingFlow();
  }

  public getLevelReached(): Observable<OnboardingLevel> {
    return this.subjectLevelReached.asObservable();
  }

  public getStepReached(): Observable<Step[]> {
    return this.subjectStepReached.asObservable();
  }

  get isPensionsInfoUpdated(): boolean {
    return this._isPensionsInfoUpdated;
  }

  set isPensionsInfoUpdated(value: boolean) {
    this._isPensionsInfoUpdated = value;
  }

  public getOnboardingStepsList(): Observable<OnboardingActivatePensionSteps> {
    return this.onboardingStore.onboardingGet();
  }

  public updateOnboardingStatusList(
    onboardingActivatePensionSteps: OnboardingActivatePensionSteps
  ): void {
    this.onboardingStepsList = onboardingActivatePensionSteps;
    onboardingActivatePensionSteps.items.forEach(
      onboardingActivatePensionStep => {
        if (
          onboardingActivatePensionStep.name ===
            OnboardingActivatePensionStepType.Introduction &&
          onboardingActivatePensionStep.checked
        ) {
          this.updateOnboardingLevelStatus(['WELCOME', 'INTRODUCTION']);
        } else if (
          onboardingActivatePensionStep.name ===
            OnboardingActivatePensionStepType.ContactData &&
          onboardingActivatePensionStep.checked
        ) {
          this.updateOnboardingLevelStatus(['STEP_CONTACT', 'CONTACT_DATA']);
        } else if (
          onboardingActivatePensionStep.name ===
            OnboardingActivatePensionStepType.Consent &&
          onboardingActivatePensionStep.checked
        ) {
          this.updateOnboardingLevelStatus(['CONSENT']);
        } else if (
          onboardingActivatePensionStep.name ===
            OnboardingActivatePensionStepType.PiData &&
          onboardingActivatePensionStep.checked
        ) {
          this.updateOnboardingLevelStatus(['STEP_FINANCES', 'PI_DATA']);
        } else if (
          onboardingActivatePensionStep.name ===
            OnboardingActivatePensionStepType.ExternalTransfer &&
          onboardingActivatePensionStep.checked
        ) {
          this.updateOnboardingLevelStatus(['EXTERNAL_TRANSFER']);
        }
      }
    );
  }

  private updateOnboardingLevelStatus(steplevels: OnboardingLevelStep[]): void {
    steplevels.forEach(level => {
      this.onboardingLevels.forEach(onboardinglevel => {
        if (onboardinglevel.level === level) {
          onboardinglevel.isChecked = true;
        }
      });
    });
  }

  public findOnboardingStepItem(): OnboardingActivatePensionStep {
    return this.onboardingStepsList.items.find(
      item => item.name === this.levelReached.level
    );
  }

  public saveOnboardingStep(
    onboardingStepItem: OnboardingActivatePensionStep
  ): Observable<boolean> {
    onboardingStepItem.checked = true;
    return this.onboardingStore.onboardingRegisterCompletedStepPut(
      onboardingStepItem
    );
  }

  public isOnboardingResumed(): boolean {
    return this.onboardingLevels.some(
      onboardingLevel =>
        onboardingLevel.isChecked === true &&
        onboardingLevel.introductionPage === false
    );
  }

  private updateLevelReached() {
    this.subjectLevelReached.next(this.levelReached);
  }

  private updateStepReached() {
    this.subjectStepReached.next(Object.values(this.progressSteps));
  }

  private findNextLevel(level: OnboardingLevel): void {
    this.onboardingLevels.forEach(onboardingLevel => {
      if (level.level === onboardingLevel.level) {
        if (level.isChecked) {
          this.findNextLevel(this.findSpecificLevel(level.nextLevel));
        } else {
          this.nextLevel = level;
        }
      }
    });
  }

  private findSpecificLevel(
    onboardingLevelStep: OnboardingLevelStep
  ): OnboardingLevel {
    return this.onboardingLevels.find(onboardingLevel => {
      if (onboardingLevel.level === onboardingLevelStep) {
        return onboardingLevel;
      }
    });
  }

  public calculateProgress(): number {
    let progress = 0;
    this.onboardingLevels.forEach(level => {
      if (
        this.levelReached.stepperLevel === level.stepperLevel &&
        level.isChecked
      ) {
        progress += level.stepperProgress;
      }
    });
    return progress;
  }

  public updateProgressForStep(
    step: OnboardingStepperLevel,
    progress: number
  ): void {
    this.progressSteps[step].progress = progress;
    this.updateStepReached();
  }

  public goToNextStep(): void {
    this.updateProgressForStep(
      this.levelReached.stepperLevel,
      this.calculateProgress()
    );
    this.findNextLevel(this.levelReached);
    this.levelReached = this.nextLevel;
    this.updateLevelReached();
  }

  public goToPreviousStep(): void {
    this.updateProgressForStep(
      this.levelReached.stepperLevel,
      this.calculateProgress()
    );
    this.levelReached = this.findSpecificLevel(this.levelReached.previousLevel);
    this.updateLevelReached();
  }

  public showContactFooter(): void {
    const contactLevel = this.onboardingLevels.find(
      level => level.level === 'CONTACT_DATA'
    );
    contactLevel.showJumpover = true;
  }

  public setShowInsurance(showInsurance: boolean): void {
    this.showInsurance = showInsurance;
  }

  public getShowInsurance(): boolean {
    return this.showInsurance;
  }

  public setupSteps(): void {
    this.onboardingFlow.setupSteps(this.showInsurance, this.showInvestment);
    this.progressSteps = this.onboardingFlow.progressSteps;
    this.subjectStepReached = new BehaviorSubject<Step[]>(
      Object.values(this.progressSteps)
    );
  }

  public setShowInvestment(showInvestmentStep: boolean): void {
    this.showInvestment = showInvestmentStep;
  }

  public getShowInvestment(): boolean {
    return this.showInvestment;
  }

  public skipInvestment(): void {
    const levelSummary = this.onboardingLevels.find(level => {
      if (level.level === 'EXTERNAL_TRANSFER_SUMMARY') {
        return level;
      }
    });
    levelSummary.nextLevel = 'STEP_INSURANCE';
  }

  public skipInsurance(): void {
    const levelInvest = this.onboardingLevels.find(level => {
      if (level.level === 'INVESTMENT') {
        return level;
      }
    });
    levelInvest.nextLevel = 'STEP_END';
  }

  public skipInsuranceAndInvestment(): void {
    const levelExternal = this.onboardingLevels.find(level => {
      if (level.level === 'EXTERNAL_TRANSFER_SUMMARY') {
        return level;
      }
    });
    levelExternal.nextLevel = 'STEP_END';
  }

  public changeStepperForInvestmentStep(): void {
    const levelStepInvest = this.onboardingLevels.find(level => {
      if (level.level === 'STEP_INVESTMENT') {
        return level;
      }
    });
    levelStepInvest.stepperLevel = OnboardingStepperLevel.INSURANCE;
    levelStepInvest.nextLevel = 'INSURANCE';
  }

  public setupInsuranceAndInvestmentTracking(): void {
    const levelStepInvest = this.onboardingLevels.find(level => {
      if (level.level === 'STEP_INVESTMENT') {
        return level;
      }
    });
    if (!this.getShowInsurance() && this.getShowInvestment()) {
      levelStepInvest.trackingName = OnboardingStepTrackingName.STEP_3_IG;
    } else if (this.getShowInsurance() && !this.getShowInvestment()) {
      levelStepInvest.trackingName = OnboardingStepTrackingName.STEP_3_ISG;
    }
  }
}
