/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const HealthCaseDecisionSchema = JoiObj.string().valid(
        'AFSLAG'
        ,
        'ANDEN_AFGORELSE'
        ,
        'ANNULLERET'
        ,
        'ANTAGET'
        ,
        'ANTAGET_FORBEHOLD'
        ,
        'DELVIST_ANTAGET'
        
    )
.id('HealthCaseDecisionSchema')

export type HealthCaseDecision = 'AFSLAG' | 'ANDEN_AFGORELSE' | 'ANNULLERET' | 'ANTAGET' | 'ANTAGET_FORBEHOLD' | 'DELVIST_ANTAGET';

export const HealthCaseDecision = {
    Afslag: 'AFSLAG' as HealthCaseDecision,
    AndenAfgorelse: 'ANDEN_AFGORELSE' as HealthCaseDecision,
    Annulleret: 'ANNULLERET' as HealthCaseDecision,
    Antaget: 'ANTAGET' as HealthCaseDecision,
    AntagetForbehold: 'ANTAGET_FORBEHOLD' as HealthCaseDecision,
    DelvistAntaget: 'DELVIST_ANTAGET' as HealthCaseDecision
};

