import { LoadScript } from './script-loader.model';

export const adobeScriptDevelopment = 'adobeScriptDevelopment';
export const adobeScriptStaging = 'adobeScriptStaging';
export const adobeScriptProd = 'adobeScriptProd';

export const AdobeScriptStore: LoadScript[] = [
  {
    name: adobeScriptDevelopment,
    src: 'https://assets.adobedtm.com/launch-EN2ddb853c900e4601aa550324ec96a484-development.min.js'
  },
  {
    name: adobeScriptStaging,
    src: 'https://assets.adobedtm.com/launch-ENf28946e758d149ddb25f282be687e771-staging.min.js'
  },
  {
    name: adobeScriptProd,
    src: 'https://assets.adobedtm.com/launch-EN7c5571b004594eb88d08f8b90cf0c8a3.min.js'
  }
];
