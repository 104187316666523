<div class="card-question" [class.hide-content]="isHideContent">
  <div class="card-content">
    <div
      class="content-container"
      [ngStyle]="{
        minHeight: minHeight,
        maxHeight: maxHeight,
        overflow: maxHeight ? 'hidden' : null
      }"
      #content
    >
      <ng-container *ngTemplateOutlet="ngContent"></ng-container>
    </div>
  </div>

  <div class="cf">
    <div class="btn-answer btn-no float--left">
      <button
        mat-raised-button
        class="button-secondary"
        [coContent]="declineText"
        (click)="declineClick.emit()"
      ></button>
    </div>
    <div class="btn-answer btn-yes float--right">
      <button
        mat-raised-button
        class="button-primary"
        [coContent]="agreeText"
        (click)="agreeClick.emit()"
      ></button>
    </div>
  </div>

  <div class="answer-label">
    <ng-content select="[under-button-mobile]"></ng-content>
  </div>
</div>

<ng-template #ngContent>
  <ng-content></ng-content>
</ng-template>
