import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  OnInit,
  Output
} from '@angular/core';
import { OnboardingService } from '../onboarding.service';

export interface Carousel {
  image: 'INFORMATION' | 'INSURANCE' | 'INVESTMENT_GUIDE' | 'PENSION';
  headerText: string;
  bodyText: string;
  number: number;
}

@Component({
  selector: 'mitpfa-onboarding-introduction-carousel',
  templateUrl: './introduction-carousel.component.html',
  styleUrls: ['./introduction-carousel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IntroductionCarouselComponent implements OnInit {
  @Output() goTo = new EventEmitter();
  public carousels: Carousel[];
  public showInsurance = false;
  public showInvestment = false;
  private readonly onboardingService: OnboardingService =
    inject(OnboardingService);

  public next(): void {
    this.goTo.emit();
  }

  public ngOnInit(): void {
    this.showInvestment = this.onboardingService.getShowInvestment();
    this.showInsurance = this.onboardingService.getShowInsurance();
    this.carousels = [
      {
        image: 'INFORMATION',
        number: 1,
        headerText: 'DL.VK01.C14',
        bodyText: 'DL.VK01.C15'
      },
      {
        image: 'PENSION',
        number: 2,
        headerText: 'DL.VK01.C16',
        bodyText: 'DL.VK01.C17'
      }
    ];
    if (this.showInvestment) {
      this.carousels.push({
        image: 'INVESTMENT_GUIDE',
        number: 3,
        headerText: 'DL.VK01.C20',
        bodyText: 'DL.VK01.C21'
      });
    }
    if (this.showInsurance) {
      this.carousels.push({
        image: 'INSURANCE',
        number: this.showInvestment ? 4 : 3,
        headerText: 'DL.VK01.C18',
        bodyText: 'DL.VK01.C19'
      });
    }
  }
}
