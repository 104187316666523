/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AlternativePolicyChoicePartiallyAccepted } from './alternativePolicyChoicePartiallyAccepted';
import { AlternativePolicyChoicePartiallyAcceptedSchema } from './alternativePolicyChoicePartiallyAccepted';
import { OriginOfMoneySelectedList } from './originOfMoneySelectedList';
import { OriginOfMoneySelectedListSchema } from './originOfMoneySelectedList';
import { PolicyOfferPolicyType } from './policyOfferPolicyType';
import { PolicyOfferPolicyTypeSchema } from './policyOfferPolicyType';
import { QuotationStatus } from './quotationStatus';
import { QuotationStatusSchema } from './quotationStatus';
import { SimulationElement } from './simulationElement';
import { SimulationElementSchema } from './simulationElement';
import { PurposeOfPolicySelectedList } from './purposeOfPolicySelectedList';
import { PurposeOfPolicySelectedListSchema } from './purposeOfPolicySelectedList';
import { PartialSchemeElement } from './partialSchemeElement';
import { PartialSchemeElementSchema } from './partialSchemeElement';
import { AlternativePolicyChoiceDeclined } from './alternativePolicyChoiceDeclined';
import { AlternativePolicyChoiceDeclinedSchema } from './alternativePolicyChoiceDeclined';
import { AlternativePolicyChoice } from './alternativePolicyChoice';
import { AlternativePolicyChoiceSchema } from './alternativePolicyChoice';
import { BusinessContactElement } from './businessContactElement';
import { BusinessContactElementSchema } from './businessContactElement';
import { PolicyOfferAttachedFileList } from './policyOfferAttachedFileList';
import { PolicyOfferAttachedFileListSchema } from './policyOfferAttachedFileList';
import * as JoiObj from "joi";


    export const QuotationElementSchema = JoiObj.object({
        advisor:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        alternativePolicyChoice:
            
        JoiObj.link('#AlternativePolicyChoiceSchema')


.required()


        ,
        alternativePolicyChoiceDeclined:
            
        JoiObj.link('#AlternativePolicyChoiceDeclinedSchema')


.required()


        ,
        alternativePolicyChoicePartiallyAccepted:
            
        JoiObj.link('#AlternativePolicyChoicePartiallyAcceptedSchema')


.required()


        ,
        attachedFileList:
            
        JoiObj.link('#PolicyOfferAttachedFileListSchema')


.required()


        ,
        businessContactElement:
            
        JoiObj.link('#BusinessContactElementSchema')


.required()


        ,
        civilRegistrationNumber:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        cvrNumber:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        originOfMoneyComment:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        originOfMoneySelectedList:
            
        JoiObj.link('#OriginOfMoneySelectedListSchema')


.required()


        ,
        partialSchemeElement:
            
        JoiObj.link('#PartialSchemeElementSchema')


.required()


        ,
        payerCvr:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        policyNumberReserved:
            
                    JoiObj.number()
                


.required()


        ,
        policyNumberSimulated:
            
                    JoiObj.number()
                


.required()


        ,
        policyType:
            
        JoiObj.link('#PolicyOfferPolicyTypeSchema')


.required()


        ,
        purposeOfPolicyComment:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        purposeOfPolicySelectedList:
            
        JoiObj.link('#PurposeOfPolicySelectedListSchema')


.required()


        ,
        quotationComment:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        quotationGuid:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        quotationId:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        quotationStatus:
            
        JoiObj.link('#QuotationStatusSchema')


.required()


        ,
        rejectedComment:
            
                    JoiObj.string()
                
                    .allow('')
                    
                    
                    


.required()


        ,
        simulationElement:
            
        JoiObj.link('#SimulationElementSchema')


.required()


        ,
        statusDate:
            
                    JoiObj.object()
                


.required()


        ,
        validFromDate:
            
                    JoiObj.object()
                


.required()


        ,
        validToDate:
            
                    JoiObj.object()
                


.required()


        
    })
                .shared(AlternativePolicyChoiceSchema)
                .shared(AlternativePolicyChoiceDeclinedSchema)
                .shared(AlternativePolicyChoicePartiallyAcceptedSchema)
                .shared(PolicyOfferAttachedFileListSchema)
                .shared(BusinessContactElementSchema)
                .shared(OriginOfMoneySelectedListSchema)
                .shared(PartialSchemeElementSchema)
                .shared(PolicyOfferPolicyTypeSchema)
                .shared(PurposeOfPolicySelectedListSchema)
                .shared(QuotationStatusSchema)
                .shared(SimulationElementSchema)
.id('QuotationElementSchema')

export interface QuotationElement { 
    advisor: string;
    alternativePolicyChoice: AlternativePolicyChoice;
    alternativePolicyChoiceDeclined: AlternativePolicyChoiceDeclined;
    alternativePolicyChoicePartiallyAccepted: AlternativePolicyChoicePartiallyAccepted;
    attachedFileList: PolicyOfferAttachedFileList;
    businessContactElement: BusinessContactElement;
    civilRegistrationNumber: string;
    cvrNumber: string;
    originOfMoneyComment: string;
    originOfMoneySelectedList: OriginOfMoneySelectedList;
    partialSchemeElement: PartialSchemeElement;
    payerCvr: string;
    policyNumberReserved: number;
    policyNumberSimulated: number;
    policyType: PolicyOfferPolicyType;
    purposeOfPolicyComment: string;
    purposeOfPolicySelectedList: PurposeOfPolicySelectedList;
    quotationComment: string;
    quotationGuid: string;
    quotationId: string;
    quotationStatus: QuotationStatus;
    rejectedComment: string;
    simulationElement: SimulationElement;
    statusDate: object;
    validFromDate: object;
    validToDate: object;
}
export namespace QuotationElement {
}


