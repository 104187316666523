import { OpsparingFormFordeling } from '@pfa/gen';

export type PensionType = 'Ratepension' | 'Aldersopsparing' | 'Livspension';

export type DepositPensionType = 'indbetalingLivsvarigLivspension';

export type ApproveType = 'Basket' | 'ApproveNow';

export class SavingsFormType {
  type: OpsparingFormFordeling;
  contentId: string;
  pensionTypes: PensionType[];
  disabled: boolean;
}

export class EditablePensionType {
  pensionType: PensionType;
  contentId: string;
}

export class UnmodifiableDepositPensionType {
  depositPensionType: DepositPensionType;
  contentId: string;
}
