import { NgModule } from '@angular/core';
import { LogoutComponent } from './logout.component';
import { PfaFormModule } from '@pfaform';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [CommonModule, PfaFormModule],
  declarations: [LogoutComponent]
})
export class LogoutModule {}
