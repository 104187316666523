<!-- v9 -->
<svg
  id="Layer_1"
  data-name="Layer 1"
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 32 32"
>
  <g id="Canvas">
    <circle
      id="Large_circle"
      data-name="Large circle"
      cx="16"
      cy="16"
      r="16"
      fill="none"
    />
    <circle
      id="Small_circle"
      data-name="Small circle"
      cx="16"
      cy="16"
      r="12"
      fill="none"
    />
  </g>
  <g id="Icon">
    <rect
      id="Body"
      x="10"
      y="12.5"
      width="12"
      height="12"
      rx="3"
      stroke-width="1.2"
      stroke="#000"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
    />
    <path
      id="Shackle"
      d="M20,12.5V11a3.52,3.52,0,0,0-3.5-3.5h-1A3.52,3.52,0,0,0,12,11v1.5"
      fill="none"
      stroke="#000"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.2"
    />
    <path
      id="Keyhole"
      d="M17,17.78V16.5a1,1,0,0,0-2,0v1.28a2,2,0,1,0,2,0Z"
      fill="none"
      stroke="#000"
      stroke-linejoin="round"
      stroke-width="1.2"
    />
  </g>
</svg>
