export interface Country {
  en: string;
  dialCode: string;
  code: string;
  da?: string;
}

export const countries: Country[] = [
  {
    en: 'Israel',
    dialCode: '+972',
    code: 'IL',
    da: 'Israel'
  },
  {
    en: 'Afghanistan',
    dialCode: '+93',
    code: 'AF',
    da: 'Afghanistan'
  },
  {
    en: 'Albania',
    dialCode: '+355',
    code: 'AL',
    da: 'Albanien'
  },
  {
    en: 'Algeria',
    dialCode: '+213',
    code: 'DZ',
    da: 'Algeriet'
  },
  {
    en: 'AmericanSamoa',
    dialCode: '+1 684',
    code: 'AS'
  },
  {
    en: 'Andorra',
    dialCode: '+376',
    code: 'AD',
    da: 'Andorra'
  },
  {
    en: 'Angola',
    dialCode: '+244',
    code: 'AO',
    da: 'Angola'
  },
  {
    en: 'Anguilla',
    dialCode: '+1 264',
    code: 'AI'
  },
  {
    en: 'Antigua and Barbuda',
    dialCode: '+1268',
    code: 'AG',
    da: 'Antigua og Barbuda'
  },
  {
    en: 'Argentina',
    dialCode: '+54',
    code: 'AR',
    da: 'Argentina'
  },
  {
    en: 'Armenia',
    dialCode: '+374',
    code: 'AM',
    da: 'Armenien'
  },
  {
    en: 'Aruba',
    dialCode: '+297',
    code: 'AW'
  },
  {
    en: 'Australia',
    dialCode: '+61',
    code: 'AU',
    da: 'Australien'
  },
  {
    en: 'Austria',
    dialCode: '+43',
    code: 'AT',
    da: 'Østrig'
  },
  {
    en: 'Azerbaijan',
    dialCode: '+994',
    code: 'AZ',
    da: 'Aserbajdsjan'
  },
  {
    en: 'Bahamas',
    dialCode: '+1 242',
    code: 'BS',
    da: 'Bahamas'
  },
  {
    en: 'Bahrain',
    dialCode: '+973',
    code: 'BH',
    da: 'Bahrain'
  },
  {
    en: 'Bangladesh',
    dialCode: '+880',
    code: 'BD',
    da: 'Bangladesh'
  },
  {
    en: 'Barbados',
    dialCode: '+1 246',
    code: 'BB',
    da: 'Barbados'
  },
  {
    en: 'Belarus',
    dialCode: '+375',
    code: 'BY',
    da: 'Hviderusland'
  },
  {
    en: 'Belgium',
    dialCode: '+32',
    code: 'BE',
    da: 'Belgien'
  },
  {
    en: 'Belize',
    dialCode: '+501',
    code: 'BZ',
    da: 'Belize'
  },
  {
    en: 'Benin',
    dialCode: '+229',
    code: 'BJ',
    da: 'Benin'
  },
  {
    en: 'Bermuda',
    dialCode: '+1 441',
    code: 'BM'
  },
  {
    en: 'Bhutan',
    dialCode: '+975',
    code: 'BT',
    da: 'Bhutan'
  },
  {
    en: 'Bosnia and Herzegovina',
    dialCode: '+387',
    code: 'BA',
    da: 'Bosnien-Hercegovina'
  },
  {
    en: 'Botswana',
    dialCode: '+267',
    code: 'BW',
    da: 'Botswana'
  },
  {
    en: 'Brazil',
    dialCode: '+55',
    code: 'BR',
    da: 'Brasilien'
  },
  {
    en: 'British Indian Ocean Territory',
    dialCode: '+246',
    code: 'IO'
  },
  {
    en: 'Bulgaria',
    dialCode: '+359',
    code: 'BG',
    da: 'Bulgarien'
  },
  {
    en: 'Burkina Faso',
    dialCode: '+226',
    code: 'BF',
    da: 'Burkina Faso'
  },
  {
    en: 'Burundi',
    dialCode: '+257',
    code: 'BI',
    da: 'Burundi'
  },
  {
    en: 'Cambodia',
    dialCode: '+855',
    code: 'KH',
    da: 'Cambodja'
  },
  {
    en: 'Cameroon',
    dialCode: '+237',
    code: 'CM',
    da: 'Cameroun'
  },
  {
    en: 'Canada',
    dialCode: '+1',
    code: 'CA',
    da: 'Canada'
  },
  {
    en: 'Cape Verde',
    dialCode: '+238',
    code: 'CV',
    da: 'Kap Verde'
  },
  {
    en: 'Cayman Islands',
    dialCode: '+ 345',
    code: 'KY'
  },
  {
    en: 'Central African Republic',
    dialCode: '+236',
    code: 'CF',
    da: 'Centralafrikanske Republik'
  },
  {
    en: 'Chad',
    dialCode: '+235',
    code: 'TD',
    da: 'Tchad'
  },
  {
    en: 'Chile',
    dialCode: '+56',
    code: 'CL',
    da: 'Chile'
  },
  {
    en: 'China',
    dialCode: '+86',
    code: 'CN',
    da: 'Kina'
  },
  {
    en: 'Christmas Island',
    dialCode: '+61',
    code: 'CX'
  },
  {
    en: 'Colombia',
    dialCode: '+57',
    code: 'CO',
    da: 'Colombia'
  },
  {
    en: 'Comoros',
    dialCode: '+269',
    code: 'KM',
    da: 'Comorerne'
  },
  {
    en: 'Congo',
    dialCode: '+242',
    code: 'CG',
    da: 'Congo'
  },
  {
    en: 'Cook Islands',
    dialCode: '+682',
    code: 'CK'
  },
  {
    en: 'Costa Rica',
    dialCode: '+506',
    code: 'CR',
    da: 'Costa Rica'
  },
  {
    en: 'Croatia',
    dialCode: '+385',
    code: 'HR',
    da: 'Kroatien'
  },
  {
    en: 'Cuba',
    dialCode: '+53',
    code: 'CU',
    da: 'Cuba'
  },
  {
    en: 'Cyprus',
    dialCode: '+357',
    code: 'CY',
    da: 'Cypern'
  },
  {
    en: 'Czech Republic',
    dialCode: '+420',
    code: 'CZ',
    da: 'Tjekkiet'
  },
  {
    en: 'Denmark',
    dialCode: '+45',
    code: 'DK',
    da: 'Danmark'
  },
  {
    en: 'Djibouti',
    dialCode: '+253',
    code: 'DJ',
    da: 'Djibouti'
  },
  {
    en: 'Dominica',
    dialCode: '+1 767',
    code: 'DM',
    da: 'Dominica'
  },
  {
    en: 'Dominican Republic',
    dialCode: '+1 849',
    code: 'DO',
    da: 'Dominikanske Republik'
  },
  {
    en: 'Ecuador',
    dialCode: '+593',
    code: 'EC',
    da: 'Ecuador'
  },
  {
    en: 'Egypt',
    dialCode: '+20',
    code: 'EG',
    da: 'Egypten'
  },
  {
    en: 'El Salvador',
    dialCode: '+503',
    code: 'SV',
    da: 'El Salvador'
  },
  {
    en: 'Equatorial Guinea',
    dialCode: '+240',
    code: 'GQ',
    da: 'Ækvatorialguinea'
  },
  {
    en: 'Eritrea',
    dialCode: '+291',
    code: 'ER',
    da: 'Eritrea'
  },
  {
    en: 'Estonia',
    dialCode: '+372',
    code: 'EE',
    da: 'Estland'
  },
  {
    en: 'Ethiopia',
    dialCode: '+251',
    code: 'ET',
    da: 'Etiopien'
  },
  {
    en: 'Faroe Islands',
    dialCode: '+298',
    code: 'FO'
  },
  {
    en: 'Fiji',
    dialCode: '+679',
    code: 'FJ',
    da: 'Fiji'
  },
  {
    en: 'Finland',
    dialCode: '+358',
    code: 'FI',
    da: 'Finland'
  },
  {
    en: 'France',
    dialCode: '+33',
    code: 'FR',
    da: 'Frankrig'
  },
  {
    en: 'French Guiana',
    dialCode: '+594',
    code: 'GF'
  },
  {
    en: 'French Polynesia',
    dialCode: '+689',
    code: 'PF'
  },
  {
    en: 'Gabon',
    dialCode: '+241',
    code: 'GA',
    da: 'Gabon'
  },
  {
    en: 'Gambia',
    dialCode: '+220',
    code: 'GM',
    da: 'Gambia'
  },
  {
    en: 'Georgia',
    dialCode: '+995',
    code: 'GE',
    da: 'Georgien'
  },
  {
    en: 'Germany',
    dialCode: '+49',
    code: 'DE',
    da: 'Tyskland'
  },
  {
    en: 'Ghana',
    dialCode: '+233',
    code: 'GH',
    da: 'Ghana'
  },
  {
    en: 'Gibraltar',
    dialCode: '+350',
    code: 'GI'
  },
  {
    en: 'Greece',
    dialCode: '+30',
    code: 'GR',
    da: 'Grækenland'
  },
  {
    en: 'Greenland',
    dialCode: '+299',
    code: 'GL'
  },
  {
    en: 'Grenada',
    dialCode: '+1 473',
    code: 'GD',
    da: 'Grenada'
  },
  {
    en: 'Guadeloupe',
    dialCode: '+590',
    code: 'GP'
  },
  {
    en: 'Guam',
    dialCode: '+1 671',
    code: 'GU'
  },
  {
    en: 'Guatemala',
    dialCode: '+502',
    code: 'GT',
    da: 'Guatemala'
  },
  {
    en: 'Guinea',
    dialCode: '+224',
    code: 'GN',
    da: 'Guinea'
  },
  {
    en: 'Guinea-Bissau',
    dialCode: '+245',
    code: 'GW',
    da: 'Guinea-Bissau'
  },
  {
    en: 'Guyana',
    dialCode: '+595',
    code: 'GY',
    da: 'Guyana'
  },
  {
    en: 'Haiti',
    dialCode: '+509',
    code: 'HT',
    da: 'Haiti'
  },
  {
    en: 'Honduras',
    dialCode: '+504',
    code: 'HN',
    da: 'Honduras'
  },
  {
    en: 'Hungary',
    dialCode: '+36',
    code: 'HU',
    da: 'Ungarn'
  },
  {
    en: 'Iceland',
    dialCode: '+354',
    code: 'IS',
    da: 'Island'
  },
  {
    en: 'India',
    dialCode: '+91',
    code: 'IN',
    da: 'Indien'
  },
  {
    en: 'Indonesia',
    dialCode: '+62',
    code: 'ID',
    da: 'Indonesien'
  },
  {
    en: 'Iraq',
    dialCode: '+964',
    code: 'IQ',
    da: 'Irak'
  },
  {
    en: 'Ireland',
    dialCode: '+353',
    code: 'IE',
    da: 'Irland'
  },
  {
    en: 'Israel',
    dialCode: '+972',
    code: 'IL',
    da: 'Israel'
  },
  {
    en: 'Italy',
    dialCode: '+39',
    code: 'IT',
    da: 'Italien'
  },
  {
    en: 'Jamaica',
    dialCode: '+1 876',
    code: 'JM',
    da: 'Jamaica'
  },
  {
    en: 'Japan',
    dialCode: '+81',
    code: 'JP',
    da: 'Japan'
  },
  {
    en: 'Jordan',
    dialCode: '+962',
    code: 'JO',
    da: 'Jordan'
  },
  {
    en: 'Kazakhstan',
    dialCode: '+7 7',
    code: 'KZ',
    da: 'Kasakhstan'
  },
  {
    en: 'Kenya',
    dialCode: '+254',
    code: 'KE',
    da: 'Kenya'
  },
  {
    en: 'Kiribati',
    dialCode: '+686',
    code: 'KI',
    da: 'Kiribati'
  },
  {
    en: 'Kuwait',
    dialCode: '+965',
    code: 'KW',
    da: 'Kuwait'
  },
  {
    en: 'Kyrgyzstan',
    dialCode: '+996',
    code: 'KG',
    da: 'Kirgisistan'
  },
  {
    en: 'Latvia',
    dialCode: '+371',
    code: 'LV',
    da: 'Letland'
  },
  {
    en: 'Lebanon',
    dialCode: '+961',
    code: 'LB',
    da: 'Libanon'
  },
  {
    en: 'Lesotho',
    dialCode: '+266',
    code: 'LS',
    da: 'Lesotho'
  },
  {
    en: 'Liberia',
    dialCode: '+231',
    code: 'LR',
    da: 'Liberia'
  },
  {
    en: 'Liechtenstein',
    dialCode: '+423',
    code: 'LI',
    da: 'Liechtenstein'
  },
  {
    en: 'Lithuania',
    dialCode: '+370',
    code: 'LT',
    da: 'Litauen'
  },
  {
    en: 'Luxembourg',
    dialCode: '+352',
    code: 'LU',
    da: 'Luxembourg'
  },
  {
    en: 'Madagascar',
    dialCode: '+261',
    code: 'MG',
    da: 'Madagaskar'
  },
  {
    en: 'Malawi',
    dialCode: '+265',
    code: 'MW',
    da: 'Malawi'
  },
  {
    en: 'Malaysia',
    dialCode: '+60',
    code: 'MY',
    da: 'Malaysia'
  },
  {
    en: 'Maldives',
    dialCode: '+960',
    code: 'MV',
    da: 'Maldiverne'
  },
  {
    en: 'Mali',
    dialCode: '+223',
    code: 'ML',
    da: 'Mali'
  },
  {
    en: 'Malta',
    dialCode: '+356',
    code: 'MT',
    da: 'Malta'
  },
  {
    en: 'Marshall Islands',
    dialCode: '+692',
    code: 'MH',
    da: 'Marshalløerne'
  },
  {
    en: 'Martinique',
    dialCode: '+596',
    code: 'MQ'
  },
  {
    en: 'Mauritania',
    dialCode: '+222',
    code: 'MR',
    da: 'Mauretanien'
  },
  {
    en: 'Mauritius',
    dialCode: '+230',
    code: 'MU',
    da: 'Mauritius'
  },
  {
    en: 'Mayotte',
    dialCode: '+262',
    code: 'YT'
  },
  {
    en: 'Mexico',
    dialCode: '+52',
    code: 'MX',
    da: 'Mexico'
  },
  {
    en: 'Monaco',
    dialCode: '+377',
    code: 'MC',
    da: 'Monaco'
  },
  {
    en: 'Mongolia',
    dialCode: '+976',
    code: 'MN',
    da: 'Mongoliet'
  },
  {
    en: 'Montenegro',
    dialCode: '+382',
    code: 'ME',
    da: 'Montenegro'
  },
  {
    en: 'Montserrat',
    dialCode: '+1664',
    code: 'MS'
  },
  {
    en: 'Morocco',
    dialCode: '+212',
    code: 'MA',
    da: 'Marokko'
  },
  {
    en: 'Myanmar',
    dialCode: '+95',
    code: 'MM',
    da: 'Burma'
  },
  {
    en: 'Namibia',
    dialCode: '+264',
    code: 'NA',
    da: 'Namibia'
  },
  {
    en: 'Nauru',
    dialCode: '+674',
    code: 'NR',
    da: 'Nauru'
  },
  {
    en: 'Nepal',
    dialCode: '+977',
    code: 'NP',
    da: 'Nepal'
  },
  {
    en: 'Netherlands',
    dialCode: '+31',
    code: 'NL',
    da: 'Holland'
  },
  {
    en: 'Netherlands Antilles',
    dialCode: '+599',
    code: 'NL',
    da: 'Holland'
  },
  {
    en: 'New Caledonia',
    dialCode: '+687',
    code: 'NC'
  },
  {
    en: 'New Zealand',
    dialCode: '+64',
    code: 'NZ',
    da: 'New Zealand'
  },
  {
    en: 'Nicaragua',
    dialCode: '+505',
    code: 'NI',
    da: 'Nicaragua'
  },
  {
    en: 'Niger',
    dialCode: '+227',
    code: 'NE',
    da: 'Niger'
  },
  {
    en: 'Nigeria',
    dialCode: '+234',
    code: 'NG',
    da: 'Nigeria'
  },
  {
    en: 'Niue',
    dialCode: '+683',
    code: 'NU'
  },
  {
    en: 'Norfolk Island',
    dialCode: '+672',
    code: 'NF'
  },
  {
    en: 'Northern Mariana Islands',
    dialCode: '+1 670',
    code: 'MP'
  },
  {
    en: 'Norway',
    dialCode: '+47',
    code: 'NO',
    da: 'Norge'
  },
  {
    en: 'Oman',
    dialCode: '+968',
    code: 'OM',
    da: 'Oman'
  },
  {
    en: 'Pakistan',
    dialCode: '+92',
    code: 'PK',
    da: 'Pakistan'
  },
  {
    en: 'Palau',
    dialCode: '+680',
    code: 'PW',
    da: 'Palau'
  },
  {
    en: 'Panama',
    dialCode: '+507',
    code: 'PA',
    da: 'Panama'
  },
  {
    en: 'Papua New Guinea',
    dialCode: '+675',
    code: 'PG',
    da: 'Papua Ny Guinea'
  },
  {
    en: 'Paraguay',
    dialCode: '+595',
    code: 'PY',
    da: 'Paraguay'
  },
  {
    en: 'Peru',
    dialCode: '+51',
    code: 'PE',
    da: 'Peru'
  },
  {
    en: 'Philippines',
    dialCode: '+63',
    code: 'PH',
    da: 'Filippinerne'
  },
  {
    en: 'Poland',
    dialCode: '+48',
    code: 'PL',
    da: 'Polen'
  },
  {
    en: 'Portugal',
    dialCode: '+351',
    code: 'PT',
    da: 'Portugal'
  },
  {
    en: 'Puerto Rico',
    dialCode: '+1 939',
    code: 'PR'
  },
  {
    en: 'Qatar',
    dialCode: '+974',
    code: 'QA',
    da: 'Qatar'
  },
  {
    en: 'Romania',
    dialCode: '+40',
    code: 'RO',
    da: 'Rumænien'
  },
  {
    en: 'Rwanda',
    dialCode: '+250',
    code: 'RW',
    da: 'Rwanda'
  },
  {
    en: 'Samoa',
    dialCode: '+685',
    code: 'WS',
    da: 'Samoa'
  },
  {
    en: 'San Marino',
    dialCode: '+378',
    code: 'SM',
    da: 'San Marino'
  },
  {
    en: 'Saudi Arabia',
    dialCode: '+966',
    code: 'SA',
    da: 'Saudi-Arabien'
  },
  {
    en: 'Senegal',
    dialCode: '+221',
    code: 'SN',
    da: 'Senegal'
  },
  {
    en: 'Serbia',
    dialCode: '+381',
    code: 'RS',
    da: 'Serbien'
  },
  {
    en: 'Seychelles',
    dialCode: '+248',
    code: 'SC',
    da: 'Seychellerne'
  },
  {
    en: 'Sierra Leone',
    dialCode: '+232',
    code: 'SL',
    da: 'Sierra Leone'
  },
  {
    en: 'Singapore',
    dialCode: '+65',
    code: 'SG',
    da: 'Singapore'
  },
  {
    en: 'Slovakia',
    dialCode: '+421',
    code: 'SK',
    da: 'Slovakiet'
  },
  {
    en: 'Slovenia',
    dialCode: '+386',
    code: 'SI',
    da: 'Slovenien'
  },
  {
    en: 'Solomon Islands',
    dialCode: '+677',
    code: 'SB',
    da: 'Salomonøerne'
  },
  {
    en: 'South Africa',
    dialCode: '+27',
    code: 'ZA',
    da: 'Sydafrika'
  },
  {
    en: 'South Georgia and the South Sandwich Islands',
    dialCode: '+500',
    code: 'GS'
  },
  {
    en: 'Spain',
    dialCode: '+34',
    code: 'ES',
    da: 'Spanien'
  },
  {
    en: 'Sri Lanka',
    dialCode: '+94',
    code: 'LK',
    da: 'Sri Lanka'
  },
  {
    en: 'Sudan',
    dialCode: '+249',
    code: 'SD',
    da: 'Sudan'
  },
  {
    en: 'Suriname',
    dialCode: '+597',
    code: 'SR',
    da: 'Surinam'
  },
  {
    en: 'Swaziland',
    dialCode: '+268',
    code: 'SZ',
    da: 'Swaziland'
  },
  {
    en: 'Sweden',
    dialCode: '+46',
    code: 'SE',
    da: 'Sverige'
  },
  {
    en: 'Switzerland',
    dialCode: '+41',
    code: 'CH',
    da: 'Schweiz'
  },
  {
    en: 'Tajikistan',
    dialCode: '+992',
    code: 'TJ',
    da: 'Tadsjikistan'
  },
  {
    en: 'Thailand',
    dialCode: '+66',
    code: 'TH',
    da: 'Thailand'
  },
  {
    en: 'Togo',
    dialCode: '+228',
    code: 'TG',
    da: 'Togo'
  },
  {
    en: 'Tokelau',
    dialCode: '+690',
    code: 'TK'
  },
  {
    en: 'Tonga',
    dialCode: '+676',
    code: 'TO',
    da: 'Tonga'
  },
  {
    en: 'Trinidad and Tobago',
    dialCode: '+1 868',
    code: 'TT',
    da: 'Trinidad og Tobago'
  },
  {
    en: 'Tunisia',
    dialCode: '+216',
    code: 'TN',
    da: 'Tunesien'
  },
  {
    en: 'Turkey',
    dialCode: '+90',
    code: 'TR',
    da: 'Tyrkiet'
  },
  {
    en: 'Turkmenistan',
    dialCode: '+993',
    code: 'TM',
    da: 'Turkmenistan'
  },
  {
    en: 'Turks and Caicos Islands',
    dialCode: '+1 649',
    code: 'TC'
  },
  {
    en: 'Tuvalu',
    dialCode: '+688',
    code: 'TV',
    da: 'Tuvalu'
  },
  {
    en: 'Uganda',
    dialCode: '+256',
    code: 'UG',
    da: 'Uganda'
  },
  {
    en: 'Ukraine',
    dialCode: '+380',
    code: 'UA',
    da: 'Ukraine'
  },
  {
    en: 'United Arab Emirates',
    dialCode: '+971',
    code: 'AE',
    da: 'Forenede Arabiske Emirater'
  },
  {
    en: 'United Kingdom',
    dialCode: '+44',
    code: 'GB',
    da: 'Storbritannien'
  },
  {
    en: 'United States',
    dialCode: '+1',
    code: 'US',
    da: 'USA'
  },
  {
    en: 'Uruguay',
    dialCode: '+598',
    code: 'UY',
    da: 'Uruguay'
  },
  {
    en: 'Uzbekistan',
    dialCode: '+998',
    code: 'UZ',
    da: 'Usbekistan'
  },
  {
    en: 'Vanuatu',
    dialCode: '+678',
    code: 'VU',
    da: 'Vanuatu'
  },
  {
    en: 'Wallis and Futuna',
    dialCode: '+681',
    code: 'WF'
  },
  {
    en: 'Yemen',
    dialCode: '+967',
    code: 'YE',
    da: 'Yemen'
  },
  {
    en: 'Zambia',
    dialCode: '+260',
    code: 'ZM',
    da: 'Zambia'
  },
  {
    en: 'Zimbabwe',
    dialCode: '+263',
    code: 'ZW',
    da: 'Zimbabwe'
  },
  {
    en: 'Bolivia, Plurinational State of',
    dialCode: '+591',
    code: 'BO',
    da: 'Bolivia'
  },
  {
    en: 'Brunei Darussalam',
    dialCode: '+673',
    code: 'BN',
    da: 'Brunei'
  },
  {
    en: 'Cocos (Keeling) Islands',
    dialCode: '+61',
    code: 'CC'
  },
  {
    en: 'Congo, The Democratic Republic of the',
    dialCode: '+243',
    code: 'CD',
    da: 'Demokratiske Republik Congo'
  },
  {
    en: "Cote d'Ivoire",
    dialCode: '+225',
    code: 'CI',
    da: 'Elfenbenskysten'
  },
  {
    en: 'Falkland Islands (Malvinas)',
    dialCode: '+500',
    code: 'FK'
  },
  {
    en: 'Guernsey',
    dialCode: '+44',
    code: 'GG'
  },
  {
    en: 'Holy See (Vatican City State)',
    dialCode: '+379',
    code: 'VA'
  },
  {
    en: 'Hong Kong',
    dialCode: '+852',
    code: 'HK'
  },
  {
    en: 'Iran, Islamic Republic of',
    dialCode: '+98',
    code: 'IR',
    da: 'Iran'
  },
  {
    en: 'Isle of Man',
    dialCode: '+44',
    code: 'IM'
  },
  {
    en: 'Jersey',
    dialCode: '+44',
    code: 'JE'
  },
  {
    en: "Korea, Democratic People's Republic of",
    dialCode: '+850',
    code: 'KP',
    da: 'Nordkorea'
  },
  {
    en: 'Korea, Republic of',
    dialCode: '+82',
    code: 'KR',
    da: 'Sydkorea'
  },
  {
    en: "Lao People's Democratic Republic",
    dialCode: '+856',
    code: 'LA',
    da: 'Laos'
  },
  {
    en: 'Libyan Arab Jamahiriya',
    dialCode: '+218',
    code: 'LY',
    da: 'Libyen'
  },
  {
    en: 'Macao',
    dialCode: '+853',
    code: 'MO'
  },
  {
    en: 'Macedonia, The Former Yugoslav Republic of',
    dialCode: '+389',
    code: 'MK',
    da: 'Nordmakedonien'
  },
  {
    en: 'Micronesia, Federated States of',
    dialCode: '+691',
    code: 'FM',
    da: 'Mikronesien'
  },
  {
    en: 'Moldova, Republic of',
    dialCode: '+373',
    code: 'MD',
    da: 'Moldova'
  },
  {
    en: 'Mozambique',
    dialCode: '+258',
    code: 'MZ',
    da: 'Mozambique'
  },
  {
    en: 'Palestinian Territory, Occupied',
    dialCode: '+970',
    code: 'PS'
  },
  {
    en: 'Pitcairn',
    dialCode: '+872',
    code: 'PN'
  },
  {
    en: 'Réunion',
    dialCode: '+262',
    code: 'RE'
  },
  {
    en: 'Russia',
    dialCode: '+7',
    code: 'RU',
    da: 'Rusland'
  },
  {
    en: 'Saint Barthélemy',
    dialCode: '+590',
    code: 'BL'
  },
  {
    en: 'Saint Helena, Ascension and Tristan Da Cunha',
    dialCode: '+290',
    code: 'SH'
  },
  {
    en: 'Saint Kitts and Nevis',
    dialCode: '+1 869',
    code: 'KN',
    da: 'Saint Kitts og Nevis'
  },
  {
    en: 'Saint Lucia',
    dialCode: '+1 758',
    code: 'LC',
    da: 'Saint Lucia'
  },
  {
    en: 'Saint Martin',
    dialCode: '+590',
    code: 'MF'
  },
  {
    en: 'Saint Pierre and Miquelon',
    dialCode: '+508',
    code: 'PM'
  },
  {
    en: 'Saint Vincent and the Grenadines',
    dialCode: '+1 784',
    code: 'VC',
    da: 'Saint Vincent og Grenadinerne'
  },
  {
    en: 'Sao Tome and Principe',
    dialCode: '+239',
    code: 'ST',
    da: 'São Tomé og Príncipe'
  },
  {
    en: 'Somalia',
    dialCode: '+252',
    code: 'SO',
    da: 'Somalia'
  },
  {
    en: 'Svalbard and Jan Mayen',
    dialCode: '+47',
    code: 'SJ'
  },
  {
    en: 'Syrian Arab Republic',
    dialCode: '+963',
    code: 'SY',
    da: 'Syrien'
  },
  {
    en: 'Taiwan, Province of China',
    dialCode: '+886',
    code: 'TW'
  },
  {
    en: 'Tanzania, United Republic of',
    dialCode: '+255',
    code: 'TZ',
    da: 'Tanzania'
  },
  {
    en: 'Timor-Leste',
    dialCode: '+670',
    code: 'TL',
    da: 'Østtimor'
  },
  {
    en: 'Venezuela, Bolivarian Republic of',
    dialCode: '+58',
    code: 'VE',
    da: 'Venezuela'
  },
  {
    en: 'Viet Nam',
    dialCode: '+84',
    code: 'VN',
    da: 'Vietnam'
  },
  {
    en: 'Virgin Islands, British',
    dialCode: '+1 284',
    code: 'VG'
  },
  {
    en: 'Virgin Islands, U.S.',
    dialCode: '+1 340',
    code: 'VI'
  }
];
