/**
 * Ds OpenAPI Specification
 * Specification of the domain model representing all services used in Ds
 *
 * The version of the OpenAPI document: 24m7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as JoiObj from "joi";


    export const PolicyOfferPolicyTypeSchema = JoiObj.string().valid(
        'NEW'
        ,
        'CONTINUATION'
        ,
        'RESUME'
        
    )
.id('PolicyOfferPolicyTypeSchema')

export type PolicyOfferPolicyType = 'NEW' | 'CONTINUATION' | 'RESUME';

export const PolicyOfferPolicyType = {
    New: 'NEW' as PolicyOfferPolicyType,
    Continuation: 'CONTINUATION' as PolicyOfferPolicyType,
    Resume: 'RESUME' as PolicyOfferPolicyType
};

