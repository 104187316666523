import {
  DigitalSalgContext,
  ForsikringDetaljer,
  Forsikringsoversigt,
  HealthCase,
  InsuranceRecommendation,
  OekonomiDetaljer,
  PensionsInfoOrdninger,
  PensionsKundeGenerelleData,
  SkatStatSatser,
  StamdataDetaljer
} from '@pfa/gen';
import { InsuranceUnit } from '@pfa/models';
import { InputConfigLoc } from './insurance-adjustment-input-loc.model';
import { InputConfigCi } from '@mitpfa/shared/models/insurance-adjustment-input-ci.model';
import { InputConfigLife } from '@mitpfa/shared/models/insurance-adjustment-input-life.model';
import { InsuranceAdjustmentAdvisorInfo } from '@mitpfa/shared/models/insurance-adjustment-advisor-info.model';

export const INSURANCE_SCROLL_TIMERS = {
  animationDuraction: 900,
  animationStartDelay: 100,
  recommendationCalculationDelay: 1200
};

export interface ConfirmChoice {
  loc: ConfirmChoiceData;
  ci: ConfirmChoiceData;
  life: ConfirmChoiceData;
}

export interface ConfirmChoiceData {
  error: boolean;
  success?: boolean;
  confirmedDate: number;
  confirmPossible: boolean;
}

export class Considered {
  private loc: boolean;
  private ci: boolean;
  private life: boolean;
  private all: boolean;

  consider(type: InsuranceAdjustmentType): void {
    switch (type) {
      case InsuranceAdjustmentType.CI:
        this.ci = true;
        break;
      case InsuranceAdjustmentType.LIFE:
        this.life = true;
        break;
      case InsuranceAdjustmentType.LOC:
        this.loc = true;
        break;
    }
    this.all = this.ci && this.life && this.loc;
  }

  isAll(): boolean {
    return this.all;
  }

  isCi(): boolean {
    return this.ci;
  }

  isLife(): boolean {
    return this.life;
  }

  isLoc(): boolean {
    return this.loc;
  }
}

export interface InsuranceGuideChecks {
  showGuideWarning: boolean;
  guideNotQuiteTooOld: boolean;
  guideTooOld: boolean;
  noGuide: boolean;
  guideDate: string;
}

export interface DefaultChoice {
  loggedCi: boolean;
  loggedLife: boolean;
  loggedLoc: boolean;
  logNowCi: boolean;
  logNowLife: boolean;
  logNowLoc: boolean;
  errorLogging: boolean;
}

export interface InputConfigs {
  ci: InputConfigCi;
  loc: InputConfigLoc;
  life: InputConfigLife;
}

export interface UnsavedChangesTypes {
  loc: boolean;
  ci: boolean;
  life: boolean;
}

export interface Errors {
  addToBasket: boolean;
  approve: boolean;
}

export class DisabledElements {
  page: boolean;
  buttonReset: boolean;
  buttonApprove: boolean;
  buttonAddToBasket: boolean;

  disableAllButtonsButReset(): void {
    this.buttonApprove = true;
    this.buttonAddToBasket = true;
  }

  disableAddToBasketButton(): void {
    this.buttonAddToBasket = true;
  }

  enableButtonsAll(): void {
    this.buttonApprove = false;
    this.buttonAddToBasket = false;
    this.buttonReset = false;
  }

  disableAll(): void {
    this.page = true;
    this.buttonApprove = true;
    this.buttonReset = true;
    this.buttonAddToBasket = true;
  }
}

export interface PageStates {
  insuranceInitialized: boolean;
  receipt: boolean;
  showHealthInformation: boolean;
}

export interface InsuranceAdjustmentState {
  insurance: ForsikringDetaljer;
  insuranceOverview: Forsikringsoversigt;
  pensionCustomer: PensionsKundeGenerelleData;
  pensionInfo: PensionsInfoOrdninger;
  healthAcceptanceCases: Array<HealthCase>;
  insuranceRecommendation: InsuranceRecommendation;
  financialSituation: OekonomiDetaljer;
  familyInformation: StamdataDetaljer;
  taxData: SkatStatSatser;
  newBusinessAdvisory: DigitalSalgContext;
}

export interface InsuranceAdjustmentValues {
  ciSelfAmount: number;
  ciChildAmount: number;
  locRegularPayoutAmount: number;
  locLumpSumAmount: number;
  lifeSelfAmount: number;
  lifeChildAmount: number;
}

export class Overlay {
  show = false;
  healthMissing = false;
  healthPending = false;
  payoutInProgress = false;
  insuranceGuideTooOld = false;
  policyBlocked = false;
  locScale = false;
}

export class InputConfig {
  headerContentId: string;
  recommendation: number;
  showRecommendationButton?: boolean;
  followsRecommendation?: boolean;
}

export class InputNotification {
  contentId: string;
  amount: number;
}

export class InputConfigItem {
  type: InsuranceAdjustmentType;
  textContentId: string;
  tooltipContentId?: string;
  noteContentId?: string;
  amount?: number;
  amountOriginal: number;
  amountMin?: number;
  amountMax?: number;
  amount2Max?: number;
  unit: InsuranceUnit;
  decimals: number;
  labelContentId: string;
  updateFailed?: boolean;
}

export enum InsuranceAdjustmentType {
  CI,
  CI_CHILD,
  LOC,
  LOC_LUMPSUM,
  LIFE,
  LIFE_CHILD,
  LIFE_SPOUSE,
  LIFE_SPOUSE_EXTRA
}

export interface InsuranceAdjustmentInputEmitter {
  inputConfig: InputConfig;
  type: InsuranceAdjustmentType;
}

export interface PayscaleConfigLoc {
  selected: boolean;
  none: boolean;
  possible: boolean;
}

export interface SuperRiskTexts {
  header: string;
  body1: string;
  body2: string;
  body3: string;
  finishButton: boolean;
}

export interface TaxCodeUpdateResponse {
  amount: number;
  amountMin: number;
  amountMax: number;
  beforeTax: number;
  afterTax: number;
  showBeforeTax: boolean;
}

export class ResetData {
  advisorInfo?: InsuranceAdjustmentAdvisorInfo;
}
